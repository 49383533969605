import React from "react";

import Attributes from "./Attributes";
import Context from "../../../context/Context";
import InfoPanelHeader from "./InfoPanelHeader";

const CSS = {
  main: "i-content",
  infopanelContent: "i-infopanel-content",
  featureContent: "i-infopanel-content-feature",
  tabContent: "i-infopanel-feature-tabcontent",
  nonAiim: "i-non-aiim"
};

export default class NonAiimFeature extends React.Component {

  getCaption() {
    let layer = this.getLayer();
    if (layer && layer.title) return layer.title;
    return "";
  }

  getFeature() {
    return (this.props.searchResult && this.props.searchResult.feature);
  }

  getLayer() {
    let feature = this.getFeature();
    if (feature) return feature.layer;
    return null;
  }

  render() {
    return (
      <div className="i-infopanel-container">
        <div className={CSS.main}>
          {this.renderHeader()}
          <div className={CSS.nonAiim}>{this.props.backNextButtons}</div>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    const i18n = Context.getInstance().i18n;
    const feature = this.getFeature();
    const layer = this.getLayer();
    if (!feature || !feature.geometry) {
      return (
        <div className="i-padded">
          <p>{i18n.infoPanel.messages.noGeometry}</p>
        </div>
      );
    }
    return (
      <div key="i-infopanel-content" className={CSS.infopanelContent}>
        <div className={CSS.featureContent}>
          <div className={CSS.tabContent}>
            <Attributes active={true} feature={feature} layer={layer} />
          </div>
        </div>
      </div>
    );
  }

  renderHeader() {
    const caption = this.getCaption();
    return <InfoPanelHeader caption={caption} infoPanelKey={this.props.infoPanelKey}/>
  }

}
