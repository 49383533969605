import React from "react";
import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import Toggle from "../../common/Toggle/Toggle";
import TextField from 'calcite-react/TextField';
import * as component from "../../util/component";
import { Topic } from "../../../util/tsUtil";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label"
};

export default class AboutSection extends React.Component {

  constructor(props) {
    super(props);
    const aboutSection = this.props.cfg.getConfigurable("aboutSection");
    this.state = component.newState({
      show: aboutSection.enabled,
      defaultValue: aboutSection.htmlElement
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeShow = (checked) => {
    const cfg = this.props.cfg;
    cfg.getConfigurable("aboutSection").enabled = checked;
    if(checked === false) {
      this.setState({
        htmlElement: null,
        show: checked
      })
    }else{
      this.setState({
        htmlElement:cfg.getConfigurable("aboutSection").htmlElement,
        show: checked
      })
    }
    Topic.publish(Topic.AboutSection, {
      htmlElement: this.props.cfg.getConfigurable("aboutSection").htmlElement,
      show: checked
    })
  }

  onTextChange =(event)=>{
    this.setState({
        defaultValue: event.target.value
    },function(){
        Topic.publish(Topic.AboutSection, {htmlParam: this.state.defaultValue, show: true})
        let prop = "aboutSection";
          this.props.cfg.setConfigurable(prop,{
            enabled: true,
            htmlElement: Context.sanitizeHtml(this.state.defaultValue)
          });
    })
  }

  render() {
    const i18n = Context.instance.i18n;
    const show = this.state.show;

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.aboutSection.caption}</span>
          <HelpTip text={i18n.configurator.aboutSection.helpTip}/>
        </div>

        <div key="show" className={CSS.formGroup}>
          <label id={"cfg-aboutSec-show-label"} htmlFor={"cfg-aboutSec-show"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.aboutSection.subCaption}</div>
            <Toggle id={"cfg-aboutSec-show"} checked={show}
              onChange={this.onChangeShow}/>
          </label>
        </div>

        {this.state.show && (
        <div key="aboutSec" className={CSS.formGroup}>
            <TextField id="aboutSec-appid"  
                rows={3}
                defaultValue={this.state.defaultValue}
                type="textarea"
                fullWidth={true}
                placeholder={i18n.configurator.aboutSection.placeholder}
                onChange={this.onTextChange} />
            </div>
        )}
      </div>
    );
  }
}
