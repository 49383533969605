import { IFeature } from '@esri/arcgis-rest-types';
import moment from 'moment';

// aiim.base
import ItemReference from '../aiim/base/ItemReference';
import Dataset from '../aiim/datasets/Dataset';
import Events from '../aiim/datasets/Events';
import FieldNames from '../aiim/datasets/FieldNames';
import Reservations from '../aiim/datasets/Reservations';

// aiim.util
import { getAttributeValue, getGlobalIdField } from '../aiim/util/aiimUtil';

// components.main.InfoPanel
import { generateShareUrlAsync } from '../components/main/InfoPanel/shareUtil';
import { RecurrenceType } from '../components/main/More/Actions/BookWorkspace/RecurrenceDropdown';

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import { IEventICS, IEventInfo, IReservationICS, IReservationInfo, makeReservationICS } from '../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

// context
import Context from '../context/Context';
export type BookingType = "single" | RecurrenceType;
export type CalendarType = "google" | "outlook-app" | "generic";
export type BookingOperation = "addBooking" | "removeBooking" | "updateBooking";
export const CALENDAR_TYPES = {
  google: "google",
  outlookApp: "outlook-app",
  // outlookOnline: "outlook-online",
  generic: "generic"
};

/**
 * Add a booking/event to your calendar of choice
 *
 * @param {"google" | "outlook-app" | "outlook-online" | "generic"} type The calendar type to target
 * @param {any} params Settings and params
 */
export function addToCalendar(type, params) {
  switch (type) {
    case CALENDAR_TYPES.google:
      _addToGoogle(params)
      break;
    // case CALENDAR_TYPES.outlookApp:
    //   _addToOutlookApp(params);
    //   break;
    // case CALENDAR_TYPES.outlookOnline:
    //   _addToOutlookOnline(params);
    //   break;
    // case CALENDAR_TYPES.generic:
    //   _genericDownload(params);
    //   break;
    default:
      return;
  }
}

export function removeFromCalendar(type, params) {
  switch (type) {
    case CALENDAR_TYPES.google:
      _removeFromCalendar(params)
      break;
    default:
      return;
  }
}

export function updateInCalendar(type, params, orginResAttr) {
  switch (type) {
    case CALENDAR_TYPES.google:
      _updateInCalendar(params, orginResAttr)
      break;
    default:
      return;
  }
}

function _removeFromCalendar(params) {
  
  const baseUrl = "https://calendar.google.com/calendar/u/0/r/search";

  const { googleData } = params;
  if (!googleData) {
    return;
  }
  const { title, description, startDate, endDate } = googleData;

  // Dates
  const djLocale = Context.getInstance().lib.dojo.locale;
  const formatStartDate = djLocale.format(moment(startDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
  const formatEndDate = djLocale.format(moment(endDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});

  // Timezone
  const ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const urlParams = [
    `q=${encodeURIComponent(title)}`,
    `details=${encodeURIComponent(description)}`,
    `start=${formatStartDate}`,
    `end=${formatEndDate}`,
    `ctz=${ctz}`
  ];
  const url = `${baseUrl}?${urlParams.join('&')}`;
  if (url) window.open(url);
}

function _updateInCalendar(params, origResAttr) {

  const baseUrl = "https://calendar.google.com/calendar/u/0/r/search";

  const { googleData } = params;
  if (!googleData) {
    return;
  }

  if (!origResAttr) return;

  const { title, description} = googleData;

  const startDate = getAttributeValue(origResAttr, FieldNames.START_TIME);
  const endDate = getAttributeValue(origResAttr, FieldNames.END_TIME);

  // Dates
  const djLocale = Context.getInstance().lib.dojo.locale;
  const formatStartDate = djLocale.format(moment(startDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
  const formatEndDate = djLocale.format(moment(endDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
  const ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const urlParams = [
    `q=${encodeURIComponent(title)}`,
    `details=${encodeURIComponent(description)}`,
    `start=${formatStartDate}`,
    `end=${formatEndDate}`,
    `ctz=${ctz}`
  ];

  const url = `${baseUrl}?${urlParams.join('&')}`;
  if (url) window.open(url);
}


/**
 * Add the event to your Google calendar
 *
 * @param {any} params Data to give to Google
 */
 function _addToGoogle(params) {
  // global id of reservation; if number use objectid
  // find event; search api 

  const baseUrl = "https://calendar.google.com/calendar/u/0/r/eventedit";

  const { googleData } = params;
  if (!googleData) {
    return;
  }
  const { title, description, startDate, endDate, allDay, location } = googleData;

  // Dates
  const djLocale = Context.getInstance().lib.dojo.locale;
  const formatStartDate = djLocale.format(moment(startDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
  const formatEndDate = djLocale.format(moment(endDate).toDate(),{datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
  
  let dates;
  if (allDay && allDay === 1) {
    dates = `${formatStartDate}/${formatEndDate}`;
  } else {
    const formatStartTime = djLocale.format(moment(startDate).toDate(),{timePattern:"HHmmss", formatLength:"short", selector: "time"});
    const formatEndTime = djLocale.format(moment(endDate).toDate(),{timePattern:"HHmmss", formatLength:"short", selector: "time"});
    dates = `${formatStartDate}T${formatStartTime}/${formatEndDate}T${formatEndTime}`;
  }

  // Timezone
  const ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let urlParams;
  if(location) {
    urlParams = [
      `text=${encodeURIComponent(title)}`,
      `details=${encodeURIComponent(description)}`,
      `dates=${dates}`,
      `ctz=${ctz}`,
      `location=${encodeURIComponent(location)}`
    ];
  } else {
    urlParams = [
      `text=${encodeURIComponent(title)}`,
      `details=${encodeURIComponent(description)}`,
      `dates=${dates}`,
      `ctz=${ctz}`
    ];
  }

  const url = `${baseUrl}?${urlParams.join('&')}`;
  window.open(url);

  // setTimeout(()=> {_authenticate(params)}, 500);
}



/**
 * Download a file to be opened in Outlook Desktop
 *
 * @param {any} params Data for the ICS file
 */
// function _addToOutlookApp(params) {
//   _genericDownload(params);
// }

// async function _addToOutlookOnline(params) {
//   const { type, googleData } = params;
//   if (!googleData) {
//     return;
//   }
//   const { title, description, startDate, endDate, allDay, location } = googleData;

//   const office365 = Office365.getInstance();
//   await office365.login();
//   const token = await office365.getAccessToken();
//   const client = _getAuthenticatedClient(token);

//   let content = description;
//   if (type === "reservations") {
//     const i18n = Context.getInstance().i18n;
//     content = `<div><a href="${description}">${i18n.more.bookWorkspace.locationLinkPattern}</a></div>`;
//   }

//   const timeZone = await office365.getTimeZone(token);

//   const locale = Context.getInstance().lib.dojo.kernel.locale;
//   let use12Hours = false;
//   let format = "H:mm";
//   if (locale === "en" || locale === "en-us") {
//     use12Hours = true
//     format = "h:mm A"
//   }

//   const sd = Context.getInstance().lib.dojo.locale.format(new Date(startDate), {
//     selector: "date",
//     datePattern: "yyyy/MM/dd"
//   });
//   const st = Context.getInstance().lib.dojo.locale.format(new Date(startDate), {
//     selector: "time",
//     timePattern: "HH:mm:ss"
//   });
//   const ed = Context.getInstance().lib.dojo.locale.format(new Date(endDate), {
//     selector: "date",
//     datePattern: "yyyy/MM/dd"
//   });
//   const et = Context.getInstance().lib.dojo.locale.format(new Date(endDate), {
//     selector: "time",
//     timePattern: "HH:mm:ss"
//   });

//   const formattedStartDate = `${sd}T${st}`;
//   const formattedEndDate = `${ed}T${et}`;

//   const event = {
//     subject: escSqlQuote(title),
//     body: {
//       contentType: 'HTML',
//       content: escSqlQuote(content)
//     },
//     start: {
//       dateTime: formattedStartDate,
//       timeZone: timeZone.value
//     },
//     end: {
//       dateTime: formattedEndDate,
//       timeZone: timeZone.value
//     },
//     location: {
//       displayName: escSqlQuote(location)
//     },
//     isOrganizer: true,
//     isReminderOn: true,
//     reminderMinutesBeforeStart: 30,
//     showAs: "free",
//   };

//   console.log(event);

//   const response = await client.api('/me/events').post(event);

//   if (response.webLink) {
//     window.open(response.webLink);
//   }
//   console.log(response);
// }

/**
 * A generic ICS download with the event info
 *
 * @param {any} params Data for the ICS file
 */
// async function _genericDownload(params) {
//   const { eventICS } = params;
//   const { info, filename } = eventICS;

//   const icsInfo = await makeIcsInfo(info, filename);

//   // Download file
//   const a = document.createElement("a");
//   a.style = "display: none";
//   a.href = icsInfo.href;
//   a.download = icsInfo.filename;
//   a.click();
// }
export interface ICalendarICS {
  info: IReservationInfo | IEventInfo,
  filename: string
}
export interface ICalendarEventInfo {
  googleData: unknown,
  eventICS: ICalendarICS
  type: "reservations" | "events"
}
/**
 * Generate the event data needed for all calendars
 *
 * @param dataset Reservations or Events dataset
 * @param feature The reservation or event feature
 * @param [operation]
 * @returns Event data for all calendars
 */
export async function makeEventInfo(
  featureDataset: Reservations | Events,
  feature: __esri.Graphic | IFeature,
  itemDataset: Dataset,
  itemFeature: __esri.Graphic,
  operation?: BookingOperation,
  original?: Record<string, any>
): Promise<ICalendarEventInfo> {
  const type = featureDataset.name as ICalendarEventInfo["type"];
  // Download, Outlook App
  const title = getAttributeValue(feature.attributes, featureDataset.titleField);
  const item = _makeItem(itemDataset, itemFeature);
  const objectIdField = featureDataset.getObjectIdField();
  const globalIdField = getGlobalIdField(featureDataset.layer2D);
  const objectId = operation === "updateBooking" ? original?.[objectIdField] : feature.attributes[objectIdField];
  const globalId = operation === "updateBooking" ? original?.[globalIdField] : feature.attributes[globalIdField];
  const params = { item, title, objectId, globalId };
  const eventICS: ICalendarICS = { info: null, filename: null };
  if (type === "reservations") {
    let { reservationInfo, filename } = (await makeReservationICS(feature, params, type)) as IReservationICS;
    let icsFilename = filename;
    const i18n = Context.getInstance().i18n;
    if (operation === "removeBooking")icsFilename = i18n.calendars.removeIcs;
    else if (operation === "updateBooking") icsFilename = i18n.calendars.updateIcs;
    if (operation !== "addBooking") icsFilename = icsFilename.replace("{meetingTitle}", filename);
    eventICS.info = reservationInfo;
    eventICS.filename = icsFilename;
  } else {
    let { eventInfo, filename } = (await makeReservationICS(feature, params, type, featureDataset as Events) as IEventICS);
    eventICS.info = eventInfo;
    eventICS.filename = filename;
  }

  // Google
  const googleData = _makeGoogleInfo(featureDataset, feature);
  const i18n = Context.getInstance().i18n;
  const locationUrl = await generateShareUrlAsync(item); 
  const linkPattern = i18n.more.bookWorkspace.locationLinkPattern;
  // No encoding necessary. Won't work if you do.
  const htmlDescription = `<a href="${locationUrl}">${linkPattern}</a>`;

  if (type === "reservations") {
    if (googleData.description) googleData.description = `${googleData.description} \n\n${linkPattern} - ${locationUrl}`;
    else googleData.description = `${linkPattern} - ${locationUrl}`;
  }

  if (type === "events") {
    googleData.description = googleData.description
      ? `${googleData.description}\n\n${htmlDescription}`
      : htmlDescription;

  }
  let result = { type, eventICS, googleData };
  return result;
}

/**
 * Make an item reference
 *
 * @param dataset Reservations or Events dataset
 * @param feature The reservation or event feature
 * @returns The item reference
 */
function _makeItem(dataset, feature) {
  const source = dataset.getSource();

  const item = new ItemReference();
  item.fromSearchResult(source.key, { feature, name: source.getTitle(feature) });
  return item;
}

/**
 * Google Event Format
 *
 * @param dataset Reservations or Events dataset
 * @param feature The reservation or event feature
 *
 * URL: https://calendar.google.com/calendar/u/0/r/eventedit
 * URL Params:
 *
 * Title = text
 *
 * Start/End dates = dates (format: yyyymmddThhmmss/yyyymmddThhmmss)
 *    - If all day, omit the times
 *
 * Timezone = ctz (format: Continent/City_Name)
 *
 * Description = details
 *
 * Location name = location
 */
function _makeGoogleInfo(dataset, feature) {
  return dataset.getGoogleData(feature);
}
