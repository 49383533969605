import React from "react";
import { connect } from "react-redux";
import { getActiveTab, setActiveTab } from "../../redux";
import {
  getAttributeEditorEnabled,
  setAttributeEditorEnabled
} from "../../components/Editor/redux";
import Context from "../../../context/Context";
import ChevronLeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import * as component from "../../../components/util/component";

import CloseIcon from "calcite-ui-icons-react/XIcon";

interface Props {
  activeTabKey: string;
  icon?: any;
  label: string;
  goBackEnabled?: boolean;
  goBack?: () => void;
  setActiveTab? : (tab: string) => void;
}

interface State {
}

class SidebarHeader extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { goBackEnabled, goBack } = this.props;
    return (
      <div className="i-editor-sidebar-header">
        {goBackEnabled && 
          <button
            type="button"
            title={i18n.general.back}
            style={{ paddingRight: 0 }}
            onClick={() => goBackEnabled && goBack()}>
              <ChevronLeftIcon size={24} />
          </button>
        }
        {this.props.icon}
        <h4>{this.props.label}</h4>
        <button 
          type="button"
          className="i--close"
          title={i18n.general.close}
          onClick={() => this.props.setActiveTab(null)}>  
          <CloseIcon size={24} />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  const activeTab = getActiveTab(state,ownProps.activeTabKey);
  return {
    goBackEnabled: activeTab === "editor" && getAttributeEditorEnabled(state)
  };
};
const mapDispatchToProps = (dispatch,ownProps) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(ownProps.activeTabKey,tab)),
  goBack: () => dispatch(setAttributeEditorEnabled(false))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);

