import React from "react";

import Carousel from "../../common/Carousel/Carousel";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import InfoPanelHeader from "../InfoPanel/InfoPanelHeader";
import ShareDropdown from "../InfoPanel/ShareDropdown";
import ShareUrl from "../InfoPanel/ShareUrl";
import * as Actions from "../InfoPanel/Actions";
import * as component from "../../util/component";
import * as ics from "./ics";
import * as shareUtil from "../InfoPanel/shareUtil";
import * as appLaunchUtil from "../../../aiim/util/appLaunchUtil";
import Topic from "../../../context/Topic";
import CalendarSelector from "../../../util/CalendarSelector";
import { makeEventInfo } from "../../../util/calendarUtil";

const CSS = {
  main: "i-eventdetails",
  headerText: "i-header-text",
  sectionLeft: "i-section__left",
  sectionRight: "i-section__right",
  sectionLine: "i-section__line",
  sectionImage: "i-section__image",
  sectionName: "i-section__name i-section__nowrap",
  sectionTimePeriod: "i-section__timeperiod i-section__nowrap",
  sectionCalendar: "i-section__calendar i-section__nowrap",
  sectionLocation: "i-section__location i-section__nowrap",
  sectionDetails: "i-section__details",
  groupCaption: "i-group-caption",
  groupContent: "i-group-content",
  actionBar: "i-actionbar",
  actionButton: "i-actionbar-button",
  actionButtonCaption: "i-actionbar-button-caption"
};

export default class EventDetails extends React.Component {

  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      eventInfo: null,
      appLaunchUrls: [],
      popoverOpen: false
    });
    this.msAddToCalendarClicked = this.msAddToCalendarClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this._init();
    this.generateAppLaunchUrls()
    component.own(this, [
      Topic.subscribe(Topic.AppLaunchURLsChanged, _ => {
        this.generateAppLaunchUrls()
      })
    ])
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  generateAppLaunchUrls() {
    appLaunchUtil.generateUrlsCategoriesAndLayers(this.props.item).then(urls => {
      this.setState({
        appLaunchUrls: urls
      });
    }).catch(ex => {
       console.error("Error generating app launch urls",ex);
    });
  }

  getCaption() {
    const v = this.props.item.getTitle();
    if (typeof v === "string") return v;
    return "";
  }

  getFeature() {
    return (this.props.item.getFeature());
  }

  getSource() {
    return (this.props.item.getSource());
  }

  _init() {
    const isKiosk = Context.getInstance().isKiosk;
    const dataset = Context.getInstance().aiim.datasets.events;
    const feature = this.getFeature();
    if (dataset && feature) {
      const eventInfo = dataset.getEventInfo({forDetails: true},feature);
      //console.log("EventDetails::eventInfo",eventInfo);
      if (!this._mounted) return;
      this.setState(state => {
        return {
          eventInfo: eventInfo
        };
      });
      if (!isKiosk) {
        ics.makeIcsInfo(eventInfo).then(icsInfo => {
          //console.log("icsInfo",icsInfo);
          if (!this._mounted) return;
          this.setState(state => {
            return {
              icsInfo: icsInfo
            };
          });
        }).catch(ex => {
          console.error("Error generating event .ics file:",ex);
        });
      }

      dataset.loadAttachments(eventInfo).then(attachments => {
        if (!this._mounted) return;
        component.refresh(this);
      }).catch(ex => {
        console.error("Error loading attachments:",ex);
      });
    }
  }

  msAddToCalendarClicked(event) {
    const icsInfo = this.state.icsInfo;
    if (icsInfo && icsInfo.blob) {
      window.navigator.msSaveOrOpenBlob(icsInfo.blob,icsInfo.filename);
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const feature = this.getFeature();
    if (!feature) {
      return (
        <div className={CSS.main}>
          {this.renderHeader()}
          <div className="i-padded">
            <p>{i18n.infoPanel.messages.noGeometry}</p>
          </div>
        </div>
      );
    }
    return (
      <div className={CSS.main}>
        {this.renderHeader()}
        {this.renderCarousel()}
        {this.renderHeaderText()}
        {this.renderActions()}
        {this.props.backNextButtons}
        {this.renderDetails()}
      </div>
    );
  }

  _togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  renderCalendarButton() {
    const i18n = Context.getInstance().i18n;
    const { popoverOpen } = this.state;
    const feature = this.getFeature();
    const dataset = Context.getInstance().aiim.datasets.events;
    const calendarParams = makeEventInfo(dataset, feature, dataset, feature);
    const hashref = "#";

    const addToCalendarButton = (
      <a
        className={CSS.actionButton}
        href={hashref}
        role="button"
        onClick={this._togglePopover}
      >
        {Icons.calendar()}
        <span className={CSS.actionButtonCaption}>{i18n.events.details.addToCalendar}</span>
      </a>
    );

    return (
      <CalendarSelector
        onRequestClose={this._togglePopover}
        open={popoverOpen}
        targetEl={addToCalendarButton}
        params={calendarParams}
      />
    );
  }

  renderActions() {
    const i18n = Context.getInstance().i18n;
    const isKiosk = Context.getInstance().isKiosk;
    const item = this.props.item;
    const emailSubject = this.props.item && this.props.item.name;
    const icsInfo = this.state.icsInfo;
    const listItems = [];
    const appLaunchUrls = this.state.appLaunchUrls

    if (item && item.isValid()) {
      if (Context.getInstance().config.networkServiceUrl) {
        listItems.push(
          <li key="directions"><Actions.Directions item={item}/></li>
        );
      }
      if (!isKiosk) {
        listItems.push(
          <li key = "share">
            <ShareUrl type="eventDetails" item={this.props.item}/>
          </li>
        )
        //#region
        // if (icsInfo && typeof icsInfo.href === "string") {
        //   let calNode;
        //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     if (icsInfo.blob) {
        //       calNode = (
        //         <li key="addToCalendar">
        //           <a className={CSS.actionButton} href={hashref} role="button"
        //              onClick={this.msAddToCalendarClicked}>
        //             {Icons.calendar()}
        //             <span class={CSS.actionButtonCaption}
        //               >{i18n.events.details.addToCalendar}</span>
        //           </a>
        //         </li>
        //       );
        //     }
        //   } else {
        //     calNode = (
        //       <li key="addToCalendar">
        //         <a className={CSS.actionButton} href={icsInfo.href} role="button"
        //           download={icsInfo.filename} rel="noopener noreferrer" target="_blank">
        //           {Icons.calendar()}
        //           <span className={CSS.actionButtonCaption}
        //             >{i18n.events.details.addToCalendar}</span>
        //         </a>
        //       </li>
        //     );
        //   }
        //   if (calNode) listItems.push(calNode);
        // }
        //#endregion

        listItems.push(
          <li key="addToCalendar">
            {this.renderCalendarButton()}
          </li>
        );

        if (appLaunchUrls && appLaunchUrls.length > 0) {
          listItems.push(
            <li key="appLaunch"><Actions.AppLaunch urls={appLaunchUrls}/></li>
          );
        }
      }
    }

    if (listItems.length === 0) return null;
    return (
      <ul className={CSS.actionBar}>
        {listItems}
      </ul>
    );
  }

  renderCarousel() {
    const eventInfo = this.state.eventInfo;
    const attachments = eventInfo && eventInfo.attachments;
    return <Carousel items={attachments}/>
  }

  renderDetails() {
    const i18n = Context.getInstance().i18n;
    const eventInfo = this.state.eventInfo;
    if (!eventInfo) return null;
    const node = (
      <div key="details" className={CSS.sectionDetails}>
        <div key="d-caption" className={CSS.groupCaption}>{i18n.events.details.caption}</div>
        <div key="d-desc" className={CSS.groupContent}>{eventInfo.description}</div>
      </div>
    );
    return node;
  }

  renderHeader() {
    let caption = this.getCaption();
    return <InfoPanelHeader caption={caption} infoPanelKey={this.props.infoPanelKey}/>;
  }

  renderHeaderText() {
    if (!this.state.eventInfo) return null;
    const i18n = Context.getInstance().i18n;
    const eventInfo = this.state.eventInfo;
    const location = eventInfo.location ?? i18n.infoPanel.realTime.noLocation;

    const timePeriodInfo = (
      <div key="h-tp" className={CSS.sectionTimePeriod}>
        <div className={CSS.sectionLeft}>
          {Icons.timePeriod()}
        </div>
        <div className={CSS.sectionRight}>
          <span className={CSS.sectionLine}>{eventInfo.timePeriod}</span>
        </div>
      </div>
    );

    const dateInfo = (
      <div key="h-dt" className={CSS.sectionCalendar}>
        <div className={CSS.sectionLeft}>
          {Icons.calendar()}
        </div>
        <div className={CSS.sectionRight}>
          <span className={CSS.sectionLine}>{eventInfo.longDate}</span>
        </div>
      </div>
    );

    const locationInfo = (
      <div key="h-loc" className={CSS.sectionLocation}>
        <div className={CSS.sectionLeft}>
          {Icons.location()}
        </div>
        <div className={CSS.sectionRight}>
          <span className={CSS.sectionLine}>{location}</span>
        </div>
      </div>
    );

    const node = (
      <div key="h-text" className={CSS.headerText}>
        {timePeriodInfo}
        {dateInfo}
        {locationInfo}
      </div>
    );
    return node;
  }

}
