import React from "react";
import { connect } from "react-redux";

import MapButton, { IMapButtonProps } from "./MapButton";
import RotateAngle from "./RotateAngle";
import * as component from "../../../../components/util/component";

interface Props {
  disabledTools: IMapButtonProps["tool"][],
  // mode is the only way to configure the buttons for now
  mode: "duplicate-edit-delete" | "flipV-rotate-flipH" | "duplicate-flipV-rotate-flipH-delete",
  graphic: __esri.Graphic,
  onEdit?: () => void,
  onDelete?: () => void,
  onDuplicate?: () => void,
  onFlipH?: () => void,
  onFlipV?: () => void,
  onRotate?: (angle: number, geom: __esri.Geometry) => void,
  onRotateOpen?: (comp: RotateAngle) => void,
  onRotateClose?: (angle: number) => void,
  supportsReflect?: boolean,
  updateState: __esri.SketchViewModelUpdateEvent["state"]
}

interface State {
}

class MapButtons extends React.Component<Props,State> {

  constructor(props: Props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { graphic, mode, supportsReflect, updateState, disabledTools } = this.props;
    if (!graphic) return null;

    const scale = "l";

    // when MapButton scale="l"
    // const angles = [150,110,70,30];
    // const angles = [150,120,90,60,30];
    // const angles = [160,125,90,55,20]; // **
    // const angles = [170,130,90,50,10];

    // when MapButton scale="m"
    // const angles = [150,120,90,60,30];
    // const angles = [140,115,90,65,40]; // **
    
    const angles = [160,125,90,55,20];

    let toolAngles = {
      "duplicate": angles[0],
      "flipV": angles[1],
      "edit": angles[2],
      "rotate": angles[2],
      "flipH": angles[3],
      "delete": angles[4]
    }

    let tools = [];
    if (mode === "duplicate-edit-delete") {
      tools = ["duplicate","edit","delete"]
      toolAngles["duplicate"] = angles[1];
      toolAngles["delete"] = angles[3];
    } else if (mode === "flipV-rotate-flipH") {
      tools = ["flipV","rotate","flipH"]
    } else if (mode === "duplicate-flipV-rotate-flipH-delete") {
      tools = ["duplicate","flipV","rotate","flipH","delete"]
    }
    if (tools.length === 0) return null;

    const hasTool = name => {
      if (tools.includes(name)) return true;
      return undefined;
    }

    return (
      <div className="i-editor-map-buttons">
        {hasTool("duplicate") &&
          <MapButton 
            angle={toolAngles["duplicate"]}
            disabled={disabledTools?.includes("duplicate") || undefined}
            tool="duplicate"
            scale={scale}
            graphic={graphic}
            updateState={updateState}
            onClick={this.props.onDuplicate}
          />
        }
        {hasTool("edit") &&
          <MapButton 
            angle={toolAngles["edit"]}
            disabled={disabledTools?.includes("edit")}
            tool={"edit"}
            scale={scale}
            graphic={graphic}
            updateState={updateState}
            onClick={this.props.onEdit}
          />
        }
        {hasTool("flipV") &&
          <MapButton 
            angle={toolAngles["flipV"]}
            disabled={supportsReflect
              ? disabledTools?.includes("reflect")
              : disabledTools?.includes("flipV")
            }
            tool={supportsReflect ? "reflect" : "flipV"}
            scale={scale}
            graphic={graphic}
            updateState={updateState}
            onClick={this.props.onFlipV}
          />
        }
        {hasTool("flipH") &&
          <MapButton 
            angle={toolAngles["flipH"]}
            disabled={supportsReflect
              ? disabledTools?.includes("flip")
              : disabledTools?.includes("flipH")
            }
            tool={supportsReflect ? "flip" : "flipH"}
            scale={scale}
            graphic={graphic}
            updateState={updateState}
            onClick={this.props.onFlipH}
          />
        }
        {hasTool("delete") &&
          <MapButton 
            angle={toolAngles["delete"]}
            disabled={disabledTools?.includes("delete")}
            tool="delete"
            scale={scale}
            graphic={graphic} 
            updateState={updateState}
            onClick={this.props.onDelete}
          />
        }
        {hasTool("rotate") &&
          // last so that the rotate popover will be above the other buttons
          <MapButton 
            angle={toolAngles["rotate"]} 
            disabled={disabledTools?.includes("rotate")}
            tool="rotate"
            scale={scale}
            graphic={graphic}
            updateState={updateState}
            onRotate={this.props.onRotate}
            onRotateOpen={this.props.onRotateOpen}
            onRotateClose={this.props.onRotateClose}
          />
        }
      </div>
    )

  }

}

const mapStateToProps = (state,ownProps) => {
  return {
  }
}

export default connect(mapStateToProps)(MapButtons);