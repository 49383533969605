import React from "react";

import { StyledModal } from "../../styles/Modal/modal";

export default class Modal extends React.Component {
  elModal;

  constructor(props) {
    super(props);
    this.elModal = document.createElement("div");
    this.state = {
      open: true
    };
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    if (typeof this.props._onMount === "function") {
      this.props._onMount(this);
    } else if (typeof this.props.onMount === "function") {
      this.props.onMount(this);
    }
  }

  close() {
    if (typeof this.props._onClose === "function") {
      this.props._onClose(this);
    } else if (typeof this.props.onClose === "function") {
      this.props.onClose(this);
    }
    this.setState({
      open: false
    });
  }

  render() {
    if (!this.state.open) return null;
    return (
      <StyledModal
        {...this.props}
        open={this.state.open}
        showClose={true}
        onRequestClose={this.close}
      >
        {this.props.children}
      </StyledModal>
    );
  }
}
