import Context from "../../context/Context";
import Dataset from "./Dataset";
import * as aiimUtil from "../util/aiimUtil";
import * as appLaunchUtil from "../util/appLaunchUtil";

export default class IndoorsConfig extends Dataset {

  kvps;
  type = "table";
  url;

  get311Url(source?,feature?) {
    let url = Context.getInstance().config._311Url;
    //url = "http://www.example.com?sid={sites.site_id}&fid={facilities.facility_id}&lid={levels.level_id}";
    //url = "http://www.example.com?fn1={location_facility_name}&fn2={facility_name}&ln2={level_name}";
    //url = "http://www.example.com?z={shape.z}";
    if (typeof url === "string" && url.length > 0) {
      url = Context.checkMixedContent(url);
      return url;
    }
    return null;
  }

  has311App() {
    return !!this.get311Url();
  }

  load(url) {
    const isStr = (v) => {
      return (typeof v === "string" && v.length > 0) ;
    };
    url = this.url = Context.checkMixedContent(url);
    let kvps = this.kvps = {};
    if (typeof url !== "string" || url.length === 0) {
      //console.log("No INDOORSCONFIG table.");
      return Promise.resolve();
    }

    const config = Context.getInstance().config;
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.where = "1=1";
    return task.execute(query).then(result => {
      if (result && result.features && result.features.length > 0) {
        const keyField = aiimUtil.findFieldName(result.fields,"config_key");
        const valueField = aiimUtil.findFieldName(result.fields,"config_value");
        result.features.forEach(feature => {
          //console.log("indoorsConfig.feature",feature);
          const key = feature.attributes[keyField];
          const value = feature.attributes[valueField];
          kvps[key] = value;
        });
        //console.log("IndoorsConfig.kvps",this.kvps);

        let v = this.kvps["NETWORK_URL"];
        if (isStr(v)) {
          if (!isStr(config.networkServiceUrl)) {
            if (typeof v === "string" && v.length > 0) {
              const idx = v.lastIndexOf("/Route");
              if (idx === -1 || idx !== (url.length - 6)) {
                v += "/Route";
              }
            }
            config.networkServiceUrl = Context.checkMixedContent(v);
          }
        }

        v = this.kvps["CLOSESTFACILITY_URL"];
        if (isStr(v)) {
          if (!isStr(config.closestFacilityServiceUrl)) {
            config.closestFacilityServiceUrl = Context.checkMixedContent(v);
          }
        }

        const configuration = Context.instance.configuration;
        if (!configuration.srcInfo.appDataHadAppLaunch &&
            !configuration.srcInfo.appLaunchFromIndoorsConfig) {
          let appLaunch;
          v = this.kvps["APP_LAUNCH_ACTIONS"];
          if (isStr(v) && v.startsWith("{") && v.endsWith("}")) {
            try {
              let obj = JSON.parse(v);
              if (obj && Array.isArray(obj.actions) && obj.actions.length > 0) {
                appLaunchUtil.checkActions(obj.actions);
                appLaunch = obj;
                console.log("IndoorsConfig: appLaunch from APP_LAUNCH_ACTIONS")
              }
            } catch (ex) {
              console.warn("Error parsing IndoorsConfig.APP_LAUNCH_ACTIONS json",v);
              console.error(ex);
            }
          }
          if (!appLaunch && !configuration.srcInfo.appDataHad311) {
            v = this.kvps["311_URL"];
            if (v === undefined) v = this.kvps["MOBILE_311_URL"];
            if (isStr(v) && v.trim().length > 0) {
              appLaunch = appLaunchUtil.three11AsAppLaunch(v);
              console.log("IndoorsConfig: appLaunch from 311",appLaunch)
            }
          }
          if (appLaunch) {
            if (!config.appLaunch.actions || (config.appLaunch.actions && config.appLaunch.actions.length === 0)) {
              config.appLaunch = appLaunch
              configuration.srcInfo.appLaunchFromIndoorsConfig = true;
            }
          }
          
        }

      }

    }).catch(ex => {
      console.warn("Error querying INDOORSCONFIG table:");
      console.error(ex);
    });
  }

}
