import FieldNames from "../../../aiim/datasets/FieldNames";
//import * as aiimUtil from "../../../aiim/util/aiimUtil";
//import Context from "../../../context/Context";
import OfficePlan from "../OfficePlan";
import { addAttributeEdit, queryPeopleAssignedToUnit } from "../officePlanUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

export function prepare(task) {
  //console.log("deletePersonTransaction..................")
  return new Promise((resolve, reject) => {
    const transactionInfo = transaction.makeTransactionInfo()
    const people = task.peopleFeatures
    const peopleFeatures = people && people.map(p => {
      if (p.person) return p.person
      else return p
    })
    const associatedUnitFeatures = people && people.map(p => p.associatedUnit)
    //const associatedAreaFeatures = people && people.map(p => p.associatedArea)
    const isRemoveAll = task.isRemoveAll

    const hasUnits = () => {
      if (!associatedUnitFeatures) return false
      let hasUnits = true
      associatedUnitFeatures.forEach(unit => {
        if (!unit) hasUnits = false
      })
      return hasUnits
    }

    const makePeopleEdits = () => {
      const layer = sourceUtil.getPeopleLayer()
      //const fields = layer.fields
      const keyField = layer.globalIdField

      const deletes = []
      const undoAdds = []
      peopleFeatures.forEach(person => {
        const personFeature = person.feature || person
        const key = personFeature.attributes[keyField]
        //const undo = {attributes: Object.assign({}, personFeature.attributes)}
        const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
        deletes.push(key)
        undoAdds.push(undo)
      })
      transactionInfo.peopleEdits = {deletes: deletes}
      transactionInfo.undo.peopleEdits = {adds: undoAdds}
      transactionInfo.edits.push({
        id: layer.layerId,
        deletes: deletes
      })
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        adds: undoAdds
      })
    }

    const makeUnitEdits = () => {
      if (!hasUnits()) return Promise.resolve()
      const updates = [], undos = [], promises = []
      const layer = sourceUtil.getUnitsLayer()
      const fields = layer.fields
      const keyField = layer.globalIdField

      if (isRemoveAll) {
        const unit = associatedUnitFeatures && associatedUnitFeatures.length > 0 && associatedUnitFeatures[0]
        if (!unit) return Promise.resolve()
        const key = unit.feature && unit.feature.attributes[keyField]
        const asnType = OfficePlan.SpaceAssignmentTypes.none
        undos.push(transactionUtil.cloneUnitFeature(task, unit.feature, "no-geometry"))
        const update = {attributes: {}}
        update.attributes[keyField] = key
        addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
        updates.push(update)
      } else {
        associatedUnitFeatures.forEach(unit => {
          // unit.feature.attributes
          const promise = queryPeopleAssignedToUnit(unit).then((result) => {
            const count = result.length
            if (count === 1) {
              const key = unit.feature && unit.feature.attributes[keyField]
              const asnType = OfficePlan.SpaceAssignmentTypes.none
              undos.push(transactionUtil.cloneUnitFeature(task, unit.feature, "no-geometry"))
              const update = {attributes: {}}
              update.attributes[keyField] = key
              addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
              updates.push(update)
            }
          }).catch(e => {
            console.error("Error querying people assigned to unit", e);
          });
          promises.push(promise)
        })
      }

      return Promise.all(promises).then(() => {
        transactionInfo.unitEdits = {updates: updates}
        transactionInfo.undo.unitEdits = {updates: undos}
        transactionInfo.edits.push({
          id: layer.layerId,
          updates: updates
        })
        transactionInfo.undo.edits.push({
          id: layer.layerId,
          updates: undos
        })
      }).catch(e => {
        console.error("Couldn't update units", e)
      })
    }

    Promise.resolve().then(() => {
      makePeopleEdits()
    }).then(() => {
      return makeUnitEdits()
    }).then(() => {
      resolve(transactionInfo)
    }).catch(e => {
      reject(e)
    })
  })
}
