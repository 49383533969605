import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Context from "../../../../context/Context";
import { IStencil } from "../../../miniapps/common/types";
import PlaceDetailsVM from "./PlaceDetailsVM";
import PanelHeaderTools, { HeaderToolType, IHeaderToolInfo } from "../support/PanelHeaderTools";
import MapButtons from "../support/MapButtons";
import RotateAngle from "../support/RotateAngle";
import StencilButton from "./StencilButton";
import StencilLoader, { UseTypeIssues } from "../support/StencilLoader";
import { FpeType } from "../support/StencilLoader";
import Topic from "../../../../context/Topic";
import * as component from "../../../../components/util/component";
import * as editorUtil from "../support/editorUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import { confirm } from "../../../miniapps/common/components/modalUtil";
import Issues from "../../../miniapps/configurator/Issues";
import Warning from "../../../miniapps/configurator/Warning";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import {
  CalciteButton,
  CalciteLabel,
  CalciteNotice,
  CalcitePanel,
  CalciteCheckbox
} from "@esri/calcite-components-react";

import { getActiveHelpTip, HelpTip, setHelpTip } from "../redux";

interface Props {
  fpeType: FpeType,
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}

interface State {
  activeGraphic?: __esri.Graphic,
  activeStencil: IStencil,
  activeTool: any,
  paletteInfo: any,
  updateState: any,
  cutWalls: boolean,
  hasIntersections: boolean
}

class PlaceDetailsPanel extends React.Component<Props, State> {

  viewModel: PlaceDetailsVM;

  _mounted = false;

  constructor(props:Props) {
    super(props);

    this.viewModel = new PlaceDetailsVM({
      fpeType: this.props.fpeType,
      onIntersectingWalls: (hasIntersections: boolean) => this.setState({ hasIntersections })
    });
    this.viewModel.onToolActivated = (tool,graphic) => {
      this.setState({activeTool: tool, activeGraphic: graphic})
    }
    this.viewModel.onUpdateStateChanged = (updateState) => {
      this.setState({updateState: updateState})
    }
    this.state = component.newState({
      activeGraphic: null,
      activeStencil: null,
      activeTool: "select",
      paletteInfo: null,
      updateState: null,
      cutWalls: this.viewModel.cutWalls,
      hasIntersections: false
    });
  }

  async componentDidMount() {
    this._mounted = true;
    this.loadPalette();
    sourceUtil.getGrid().activate();

    component.own(this, [
      Topic.subscribe(Topic.FpePaletteRefreshed, params => {
        this.loadPalette();
      })
    ])

    if (this.hasPlan()) {
      const lib = Context.instance.lib;
      const view = Context.instance.views.mapView;
      component.own(this,lib.esri.reactiveUtils.watch(
        () => view.floors,
        () => {
          if (this.state.activeTool === "update") {
            this.activateTool("select");
            this.setState({updateState: null})
          }
        })
      )
    }

    component.own(this,[
      Topic.subscribe(Topic.PlanModified,params => {
        try {
          if (this._mounted && params && (params.wasUndoRedo || params.wasReconciled)) {
            this.viewModel.activateTool("select", null);
            this.setState({activeTool: "select", activeGraphic: null})
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    ]);
  }

  componentWillUnmount() {
    this._mounted = false;
    sourceUtil.getGrid().deactivate();
    this.viewModel.setActiveFeatureVisibility(true)
    this.viewModel.clear()
    this.viewModel.destroy();
    component.componentWillUnmount(this);
  }

  activateTool = (tool) => {
    this.setState({
      activeTool: tool
    });
    this.viewModel.activateTool(tool,this.state.activeStencil);
  };

  hasPlan = () => {
    const planner = Context.instance.spaceplanner.planner;
    return planner && planner.hasValidPlan(); 
  };

  loadPalette = async () => {
    if (this.hasPlan()) {
      const paletteInfo = await this.viewModel.loadPalette();
      this.setState({paletteInfo})
      this.viewModel.activateTool("select", null);
    }
  }

  onHeaderToolClick = (e:React.MouseEvent, toolInfo:IHeaderToolInfo) => {
    const { activeGraphic } = this.state;
    const hasStencil = !!(this.viewModel.activeItem && this.viewModel.activeItem.stencil);
    const supportsReflect = this.viewModel.supportsReflect();
    const tool = toolInfo && toolInfo.name;
    if (tool==="grid") {
      sourceUtil.getGrid().setVisible(!sourceUtil.getGrid().isVisible());
      component.refresh(this);
    }
    if (tool && activeGraphic) {
      if (tool === "duplicate") {
        this.viewModel.executeDuplicate();
      } else if (tool === "flipV") {
        if (supportsReflect) {
          this.viewModel.executeReflect(false);
        } else {
          this.viewModel.executeAction(tool);
        }
      } else if (tool === "flipH") {
        if (supportsReflect) {
          this.viewModel.executeReflect(true);
        } else {
          this.viewModel.executeAction(tool);
        }
      } else if (tool === "delete") {
        this.viewModel.executeDelete();
      } else if (tool === "zoomIn") {
        editorUtil.onZoomToFeature(activeGraphic);
      }
    }
  }
  async removeWalls() {
    const { i18n } = Context.getInstance();
    const ok = await confirm({
      title: i18n.editor.walls.cutWallsTitle,
      message: i18n.editor.walls.cutWallsConfirmation,
      okLabel: i18n.editor.walls.cutWalls
    });
    if (ok) {
      this.viewModel.removeIntersectingWalls();
    }
  }

  renderCutWalls() {
    if (["entryway", "window"].includes(this.props.fpeType)) {
      const i18n = Context.getInstance().i18n;
      return (
        <div className="i-editor-cut-walls">
          <CalciteLabel layout="inline">
            <CalciteCheckbox checked={this.state.cutWalls || undefined} onCalciteCheckboxChange={e => {
              const checked = (e.currentTarget as HTMLCalciteCheckboxElement).checked;
              this.setState({ cutWalls: checked });
              this.viewModel.cutWalls = checked;
              this.viewModel.showIntersectingWalls();
            }} />{i18n.editor.walls.cutIntersectingWalls}
          </CalciteLabel>
          {this.state.cutWalls &&
            <CalciteButton appearance="outline" scale="s" disabled={this.state.hasIntersections !== true || undefined}
              onClick={() => this.removeWalls()}>
              {i18n.editor.walls.cutWalls}
            </CalciteButton>
          }
        </div>
      )
    }
  }
  renderHeaderToolBar() {
    const i18n = Context.instance.i18n;
    const { activeGraphic } = this.state;
    const tools:HeaderToolType[] = ["grid", "separator", "duplicate", "rotate", "flipV", "flipH", "delete", "separator", "zoomIn"]
    let disabledTools:HeaderToolType[] = ["duplicate", "rotate", "flipV", "flipH", "delete", "zoomIn"];
    if (activeGraphic) disabledTools = [];
    const activeTools = [];
    if (sourceUtil.getGrid().isVisible()) activeTools.push("grid")
    const rotatePopover = activeGraphic && (
      <RotateAngle
        geometry={activeGraphic.geometry}
        onClose={e => {}}
        onOpen={comp => {
          comp && (comp.geometry = activeGraphic.geometry);
        }}
        onRotate={(angle, geometry) => {
          this.viewModel.executeAction("update", geometry);
        }}
      />
    ); 
    return (
      <PanelHeaderTools 
        tools={tools} 
        disabledTools={disabledTools}
        activeTools={activeTools}
        toolInfos={{"rotate": {name: "rotate", icon: "rotate", text: i18n.editor.headerTools.rotate, jsx: rotatePopover}}}
        onButtonClick={this.onHeaderToolClick}
      />
    );
  }

  render() {
    const i18n = Context.instance.i18n;
    const { paletteInfo } = this.state;
    const { fpeType, tip } = this.props;
    const hasIssues = (paletteInfo && paletteInfo.issues &&  paletteInfo.issues.length > 0);
    const useTypeIssues = StencilLoader.checkUseTypes(paletteInfo);
    let prompt:string;
    if (fpeType === FpeType.entryway) {
      prompt = i18n.editor.entryways.placeInstruction;
    } else if (fpeType === FpeType.window) {
      prompt = i18n.editor.windows.placeInstruction;
    } else if (fpeType === FpeType.furniture) {
      prompt = i18n.editor.furniture.placeInstruction;
    } else if (fpeType === FpeType.transition) {
      prompt = i18n.editor.transitions.placeInstruction;
    }

    return (
      <div className="i-editor-sidebar-container i-editor-place-container">
        <div className="i-editor-sidebar-toolbar">
          {this.renderHeaderToolBar()}
        </div>
        <div className="i-editor-sidebar-content">
          <CalcitePanel>
            <CalciteNotice open={!(tip && tip.visible) ? true : undefined} scale="s" closable icon="lightbulb"
              onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: prompt })}>
              <div slot="message">{prompt}</div>
            </CalciteNotice>
            {!hasIssues && this.renderUseTypeWarning(useTypeIssues)}
            {!hasIssues && this.renderCutWalls()}
            {!hasIssues &&
              <div className="i-editor-stencil-buttons">
                {this.renderStencils(useTypeIssues)}
              </div>
            }
            {hasIssues && 
              <div>
                <Issues key="main" issues={paletteInfo.issues} />
                <Issues key="contact" issues={[{
                  key: "contactAdmin",
                  //@todo i18n move to resources.js
                  message: "Contact your portal administrator"                  
                }]} />
              </div>
            }
          </CalcitePanel>
        </div>
        {this.renderMapButtons()}
      </div>
    );
  }

  renderMapButtons() {
    const { activeGraphic, updateState } = this.state;
    const supportsReflect = this.viewModel.supportsReflect();
    if (!activeGraphic) return null;
    return (
      <MapButtons
        mode="duplicate-flipV-rotate-flipH-delete"
        graphic={activeGraphic}
        updateState={updateState}
        supportsReflect={supportsReflect}
        onDuplicate={() => {
          this.viewModel.executeDuplicate();
        }}
        onRotate={(angle,geometry) => {
          this.viewModel.executeAction("update",geometry);
        }}
        onRotateOpen={comp => {
          comp && (comp.geometry = activeGraphic.geometry);
        }}
        onFlipV={() => {
          if (supportsReflect) {
            this.viewModel.executeReflect(false);
          } else {
            this.viewModel.executeAction("flipV");
          }
        }}
        onFlipH={() => {
          if (supportsReflect) {
            this.viewModel.executeReflect(true);
          } else {
            this.viewModel.executeAction("flipH");
          }
        }}
        onDelete={() => {
          this.viewModel.executeDelete();
        }}
      />
    )
  }

  renderStencils(useTypeIssues) {
    const { activeStencil, activeTool, paletteInfo } = this.state;
    const stencils = paletteInfo && paletteInfo.stencils;
    if (stencils && stencils.length > 0) {
      const list = stencils.map(stencil => {
        const active = (activeTool=== "create") && (activeStencil && activeStencil.key === stencil.key);
        return (
          <StencilButton
            key={stencil.key} 
            stencil={stencil}
            active={active}
            disabled={!useTypeIssues.stencilOK(stencil)}
            onClick={() => {
              let newStencil = stencil, newTool = "create"
              if (active) {
                newStencil = null;
                newTool = "select";
              }
              this.setState({
                activeStencil: newStencil
              }, () => {
                this.activateTool(newTool);
              })
            }}
          />
        )
      })
      return list;
    }
  }

  renderUseTypeWarning(useTypeIssues) {
    const i18n = Context.instance.i18n;
    const { paletteInfo } = this.state;
    const { fpeType } = this.props;
    const stencils = paletteInfo && paletteInfo.stencils;
    if (stencils && (stencils.length > 0) && !useTypeIssues.fpeTypeOK(fpeType)) {
      return (
        <div style={{marginBottom: "0.5rem"}}>
          <Warning 
            status="idle"
            message={i18n.miniapps.configurator.palette.issues.symbologyMismatch}
          />
        </div>
      )
    }
    return null;
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state),
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps: Pick<Props, "fpeType">) => ({
  setTip: (tip: HelpTip) => {
    const tab = ownProps.fpeType === "entryway"
      ? "entryways"
      : ownProps.fpeType === "window"
        ? "windows"
        : ownProps.fpeType === "transition"
          ? "transitions"
          : ownProps.fpeType;
    dispatch(setHelpTip(tab, tip));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDetailsPanel);