import React from 'react'
import FieldNames from '../../../../aiim/datasets/FieldNames';
import * as aiimUtil from '../../../../aiim/util/aiimUtil';
import * as localStore from "../../../../util/localStore";
import { ModalController } from '../../../../common/components/Modal';
import * as component from '../../../../components/util/component'
import Context from '../../../../context/Context';
import * as multipleAssignmentsUtil from "./multipleAssignmentsUtil";

import Form, {
  Fieldset,
  FormControl
} from "calcite-react/Form";
import Checkbox from "calcite-react/Checkbox";
import { CalciteP } from 'calcite-react/Elements/Elements-styled';


export default class AssignmentsPromptPeople extends React.Component {
  constructor(props) {
    super(props)
    this.state = component.newState({
      // keepAssignment: false,
      keepAssignment: true,
      unassignAssignments: false,
      unassignOthers: false,
      names: [],
      enablePopups: true
    })
  }

  componentDidMount() {
    const { assignments, currentAssignment } = this.props;
    const names = multipleAssignmentsUtil.getAssignmentNames(assignments, currentAssignment);
    this.setState({
      names: names
    })
  }

  componentWillUnmount() {
    component.componentWillUnmount(this)
  }

  onUnassignCheckChange = (evt) => {
    const checked = !!evt.target.checked
    this.setState({
      unassignAssignments: checked
    })
    if (this.props.onUnassignChanged) {
      this.props.onUnassignChanged(checked)
    }
  }

  onCurrentCheckChange = (evt) => {
    const checked = !!evt.target.checked
    this.setState({
      keepAssignment: checked
    })
    if (this.props.onCurrentChanged) {
      this.props.onCurrentChanged(checked)
    }
  }

  onUnassignOthersChanged = (evt) => {
    const checked = !!evt.target.checked;
    this.setState({
      unassignOthers: checked
    });
    if (this.props.onUnassignOthersChanged) {
      this.props.onUnassignOthersChanged(checked);
    }
  }

  onDontShowChanged = (evt) => {
    const checked = !!!evt.target.checked;
    this.setState({
      enablePopups: checked
    });
    const session = Context.getInstance().session;
    const userSettings = session && session.userSettings;
    if (!userSettings) {
      return;
    }
    // Apply the setting
    userSettings.enablePopups = checked;
    const key = localStore.userSettingKeys.enablePopups;
    localStore.setUserSetting(key, checked);
  }

  makeText = (person, assignTo) => {
    const i18n = Context.instance.i18n
    const baseString = i18n.spaceplanner.multipleAssignments.modalText
    const personAttributes = person.feature && person.feature.attributes
    const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
    const assignToAttributes = assignTo.attributes || assignTo.feature.attributes
    const areaName = aiimUtil.getAttributeValue(assignToAttributes, FieldNames.AREA_NAME)
    let assignToName = null
    if (areaName) {
      assignToName = areaName
    } else {
      const unitField = Context.instance.aiim.getUnitName()
      assignToName = aiimUtil.getAttributeValue(assignToAttributes, unitField);
    }
    if (personName && assignToName) {
      return baseString.replace("{name}", personName).replace("{assignToName}", assignToName)
    } else return null
  }

  renderItemTag(text) {
    // Make button eventually
    const key = component.nextId("item-tag-");
    return (
      <div key={key} className="i-modal-tag">
        {text}
      </div>
    )
  }

  render() {
    const i18n = Context.instance.i18n
    const formControlStyle = { margin: '0' };
    const { assignToFeature, currentAssignment, dragInfo } = this.props;
    const { enablePopups } = this.state;
    const draggedFromUnitPopup = dragInfo && dragInfo.subjectParams && dragInfo.subjectParams.fromUnitPopup;
    //#region
    // const currentAssignments = null
    // const otherOccupants = null
    // const assignToFeature = this.props.assignToFeature
    // const assignToAttributes = assignToFeature.attributes || (assignToFeature.feature && assignToFeature.feature.attributes)
    // const areaType = aiimUtil.getAttributeValue(assignToAttributes, FieldNames.AREA_TYPE)
    // const useType = aiimUtil.getAttributeValue(assignToAttributes, FieldNames.UNITS_USE_TYPE)
    // let description =
    //   areaType === "hotdesk" ? i18n.spaceplanner.multipleAssignments.assignTo.hotdesk :
    //   areaType === "hotel" ? i18n.spaceplanner.multipleAssignments.assignTo.hotel :
    //   useType
    // const assignToName = areaType ?
    //   aiimUtil.getAttributeValue(assignToAttributes, FieldNames.AREA_NAME) :
    //   aiimUtil.getAttributeValue(assignToAttributes, FieldNames.NAME)
    // const assignToTitle = i18n.spaceplanner.multipleAssignments.assignTo.title
    //   .replace("{name}", assignToName)
    //   .replace("{type}", description)
    // const personFeature = this.props.personFeature
    // const personName = aiimUtil.getAttributeValue(personFeature.feature.attributes, FieldNames.PEOPLE_FULLNAME)
    // const personEmail = aiimUtil.getAttributeValue(personFeature.feature.attributes, FieldNames.PEOPLE_EMAIL)
    // const assignmentIconStyle = Context.instance.uiMode.isRtl ?
    //   {transform: "rotate(270deg)"} :
    //   {transform: "rotate(90deg)"}
    //#endregion

    const otherChoiceNode = this.state.names.length > 0 ? (
      <Form>
        <FormControl style={formControlStyle}>
          <Fieldset name="assignmentOption">
            <Checkbox
              checked={this.state.unassignAssignments}
              onChange={this.onUnassignCheckChange}>
              {i18n.spaceplanner.multipleAssignments.unassignAssignmentsFrom}
            </Checkbox>
          </Fieldset>
        </FormControl>
      </Form>
    ) : null

    const style = this.props.dnd ? {marginBottom: '0.75rem'} : {}
    const otherNamesNode = this.state.names.length > 0 ? (
      <div className={"i-assignment-names"} style={style}>
        {this.state.names.map(n => this.renderItemTag(n))}
      </div>
    ) : null

    const currentChoiceNode =
      this.props.currentAssignment &&
      this.props.showKeepCurrentAssignment &&
      currentAssignment !== Context.instance.i18n.spaceplanner.people.noAssignment &&
      !draggedFromUnitPopup ?
      (
        <Form>
          <FormControl style={formControlStyle}>
            <Fieldset name="assignmentOption">
              <Checkbox
                checked={this.state.keepAssignment}
                onChange={this.onCurrentCheckChange}>
                {i18n.spaceplanner.multipleAssignments.unassignAssignmentsFrom}
              </Checkbox>
            </Fieldset>
          </FormControl>
        </Form>
      ) : null

    const currentNamesNode =
      this.props.currentAssignment &&
      this.props.showKeepCurrentAssignment &&
      currentAssignment !== Context.instance.i18n.spaceplanner.people.noAssignment &&
      !draggedFromUnitPopup ?
      (
        <div className={"i-assignment-names"}>
          {this.renderItemTag(this.props.currentAssignment)}
        </div>
      ) : null

    const text = this.makeText(this.props.personFeature, this.props.assignToFeature)
    const textNode = text ? (<CalciteP>{text}</CalciteP>) : null

    const showDivider =
      (currentChoiceNode || otherChoiceNode) &&
      this.props.showKeepCurrentAssignment &&
      !!this.props.hasPeopleAssigned
    const divider = showDivider ? (<span style={{borderBottom: '1px solid #eaeaea', width: '100%'}}></span>) : null

    // Unassigning other occupants
    let targetOfficeName = ""
    if (assignToFeature && typeof assignToFeature !== undefined) {
      targetOfficeName = aiimUtil.getAttributeValue(
        multipleAssignmentsUtil.getAttributes(assignToFeature),
        Context.instance.aiim.getUnitName()
      );
    }

    const unassignOthersStyle = showDivider ? { marginTop: '0.75rem' } : {};
    const unassignOthersLabel =
      i18n.spaceplanner.people.assignTo.unit.unassignOthersFrom
      .replace("{unit}", targetOfficeName);

    let unassignOthersNode = null;
    let unassignOthersNames = null;
    if (this.props.peopleNames && this.props.peopleNames.length > 0 && assignToFeature) {
      unassignOthersNode = (
        <Form style={unassignOthersStyle}>
          <FormControl style={formControlStyle}>
            <Fieldset name="assignmentOption">
              <Checkbox
                checked={this.state.unassignOthers}
                onChange={this.onUnassignOthersChanged}>
                {unassignOthersLabel}
              </Checkbox>
            </Fieldset>
          </FormControl>
        </Form>
      );
      unassignOthersNames = (
        <div className={"i-assignment-names"}>
          {this.props.peopleNames.map(n => this.renderItemTag(n))}
        </div>
      );
    }

    const dontShowFormStyle = {
      margin: '1rem 0 0'
    };
    const dontShowTextStyle = {
      margin: '0',
      fontSize: '0.85rem'
    };
    const dontShowNode =
      !currentChoiceNode && !otherChoiceNode && !unassignOthersNode ? (
        <Form>
          <FormControl style={dontShowFormStyle}>
            <Fieldset name="dontShow">
              <Checkbox checked={!enablePopups} onChange={this.onDontShowChanged}>
                {/* {i18n.spaceplanner.dontShowPopupAgain} */}
                <p style={dontShowTextStyle}>{i18n.spaceplanner.popup.dontShowDialog}</p>
              </Checkbox>
            </Fieldset>
          </FormControl>
        </Form>
      ) : null;

    if (this.props.dnd) {
      return (
        <>
          {currentChoiceNode}
          {currentNamesNode}
          {otherChoiceNode}
          {otherNamesNode}
          {divider}
        </>
      )
    }

    return (
      <div className="i-unassign-assignments">
        {textNode}
        {currentChoiceNode}
        {currentNamesNode}
        {otherChoiceNode}
        {otherNamesNode}
        {divider}
        {unassignOthersNode}
        {unassignOthersNames}
        {dontShowNode}
      </div>
    )
    //#region
    // return (
    //   <Container>
    //     <div className="i-multiple-assignments">
    //       <div className="i-people-features">
    //         <h4 className="i-people-names">{personName}</h4>
    //         <div className="i-people-actions">
    //           <AssignmentIcon style={assignmentIconStyle} />
    //           <h5>{assignToTitle}</h5>
    //           <Button className={"i-apply-button"}>Apply</Button>
    //         </div>
    //       </div>
    //       <div className="i-current-assignments">
    //         <div className="i-keep-remove"></div>
    //         <ul className="i-current-assignments-list">
    //           {currentAssignments}
    //         </ul>
    //       </div>
    //       <div className="i-other-occupants">
    //         <div className="i-keep-remove"></div>
    //         <ul className="i-other-occupants-list">
    //           {otherOccupants}
    //         </ul>
    //       </div>
    //     </div>
    //   </Container>
    // )
    //#endregion
  }

  static showModal(props) {
    let content
    const i18n = Context.instance.i18n
    const personFeature = props.personFeature
    const assignToFeature = props.assignTo
    const assignments = props.assignments
    const currentAssignment = props.currentAssignment
    const showKeepCurrentAssignment = !!props.showKeepCurrentAssignment
    const peopleNames = props.peopleNames

    let unassignAssignments = false
    const onUnassignChanged = (checked) => {
      unassignAssignments = !!checked
    }

    let keepCurrentAssignment = showKeepCurrentAssignment ? false : "not-shown"
    const onCurrentChanged = (checked) => {
      keepCurrentAssignment = !!!checked
    }

    let unassignOthers = false;
    const onUnassignOthersChanged = checked => {
      unassignOthers = !!checked;
    };

    const onOK = () => {
      if (props && props.onOK) {
        props.onOK(unassignAssignments, keepCurrentAssignment, unassignOthers)
      }
    }

    let selectProps = {
      personFeature: personFeature,
      assignToFeature: assignToFeature,
      assignments: assignments,
      currentAssignment: currentAssignment,
      showKeepCurrentAssignment: showKeepCurrentAssignment,
      peopleNames: peopleNames,
      hasPeopleAssigned: peopleNames.length > 0,
      onUnassignChanged: onUnassignChanged,
      onCurrentChanged: onCurrentChanged,
      onUnassignOthersChanged: onUnassignOthersChanged
    }

    const personString = props.plural ? i18n.spaceplanner.multipleAssignments.people : i18n.spaceplanner.multipleAssignments.person
    const title = i18n.spaceplanner.multipleAssignments.modalTitle
      .replace("{person}", personString)
      .replace("{to}", props.to)
    content = <AssignmentsPromptPeople {...selectProps} />
    let modalOptions = {
      title: title,
      className: "i-assign-people-modal",
      content: content,
      okLabel: i18n.spaceplanner.buttonLabel.assign
    }
    ModalController.confirm(modalOptions).then(result => {
      if (result.ok) {
        onOK()
      }
    })
  }
}
