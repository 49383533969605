import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Context from "../../../../context/Context";
import {
  setActiveTab,
  getHitTestResults,
  setHitTestResults,
  setSelectedFeatureIndex,
  getSelectedFeatureIndex,
  getAttributeEditorEnabled,
  setAttributeEditorEnabled,
  setSelectionVisibility,
  getActiveFeatureType,
  setUndoRedoDisabled,
  setDimensionLabelInfo, 
  getDimensionLabelInfo
} from "../redux";
import Attributes, { FeatureToUpdate, IAttributesHandle } from "./Attributes";
import Savebar, { ISavebar } from "../support/Savebar";
import PanelHeaderTools, { HeaderToolType, IHeaderToolInfo } from "./PanelHeaderTools";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { FeatureSelector } from "./MapSelection";
import EditorVM from "./EditorVM";
import MapButtons from "../support/MapButtons";
import * as transactions from "../../../base/transaction/transactions";
import TransactionGuard from "../../../base/TransactionGuard";
import Topic from "../../../../context/Topic";
import { IAlertOptions } from "../../../miniapps/common/components/Modal";
import { ModalController } from "../../Modal";
import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import "@esri/calcite-components/dist/components/calcite-input"
import {
  CalciteAlert,
  CalciteBlock,
  CalciteInputNumber,
  CalciteLabel,
  CalciteRadioButton,
  CalciteRadioButtonGroup
} from "@esri/calcite-components-react";
import { IApplyEditsResults } from "../../../base/transaction/transaction";
import { IGeometry, IPolygon, IPolyline } from "@esri/arcgis-rest-types";
import * as duplicateUtil from "./duplicateUtil";
import * as wallTypesUtil from "../../../miniapps/configurator/wallTypesUtil";
import RotateAngle from "./RotateAngle";
import { CutType, onZoomToFeature, updateZ } from "../support/editorUtil";
import { useForceUpdate, useHandles } from "../../../miniapps/common/hooks";
import { CalciteRadioButtonCustomEvent, CalciteRadioButtonGroupCustomEvent } from "@esri/calcite-components";
import { getLayer } from "../../../base/mapUtil";
import { IFormOptions } from "../../../../util/interfaces";
import { getCapitalizedType, getIdField } from "./formUtil";
import * as sourceUtil from "../../../base/sourceUtil";

const EditorPanel = () => {
  const results = useSelector(getHitTestResults);
  const isInEditMode = useSelector(getAttributeEditorEnabled);
  const selectedFeatureIndex = useSelector(getSelectedFeatureIndex);
  const layerType = useSelector(getActiveFeatureType);
  const dimensionLabelInfo = useSelector(getDimensionLabelInfo);
  const forceUpdate = useForceUpdate();
  const setHandles = useHandles()[1];

  const ctx = Context.getInstance();
  const { i18n } = ctx;
  const dispatch = useDispatch();

  // set the current hit test result
  const current = results && results.length ? results[selectedFeatureIndex] : null;
  const feature: __esri.Graphic = current ? ctx.lib.esri.Graphic.fromJSON(current.feature) : null;
  const isDuplicate = results?.[0]?.duplicateObj?.isDuplicate;
  const layer: __esri.FeatureLayer = getLayer(layerType);

  const attributesRef = useRef<IAttributesHandle>();
  const formRef = useRef<__esri.FeatureForm>();
  const groupExpanded = useRef<boolean>(false);
  const viewModel = useRef<EditorVM>(null);
  const origLyrDef = useRef<string>(layer?.definitionExpression);
  const canSave = useRef<boolean>(false);
  const saveBarRef = useRef<ISavebar>();
  const [cutType, setCutType] = useState<CutType>(CutType.none);  
  const [alertOptions, setAlertOptions] = useState<IAlertOptions>({ open: false });
  const [disabledTools, setDisabledTools] = useState<HeaderToolType[]>([]);
  const [svmUpdateState, setSvmUpdateState] = useState<string>(null);
  const [zOption, setZOption] = useState<{ open: boolean, value: string }>({
    value: null,
    open: true
  });
  const [gridVisible, setGridVisible] = useState<boolean>(sourceUtil.getGrid().isVisible());

  useEffect(() => {
    const handles = [
      Topic.subscribe(Topic.PlanModified, params => {
        try {
          if (params && (params.wasUndoRedo || params.wasReconciled)) {
            // @todo , we should switch out of edit mode and refresh the features in this panel
            dispatch(setActiveTab(null));
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    ];
    setHandles(handles);

    return () => {
      sourceUtil.getGrid().deactivate();
      cleanup();
      dispatch(setUndoRedoDisabled(false));
    }
  }, []);

  useEffect(() => {
    if (viewModel.current && layerType === "unit") {
      viewModel.current.dimensionAnnotation.setDimensionLabelInfo(dimensionLabelInfo);
      if (isInEditMode) {
        if (dimensionLabelInfo.visible) viewModel.current.updateDimensionAnnotation();
      }
    }
  }, [dimensionLabelInfo])

  useEffect(() => {
    if(!isInEditMode) {
      sourceUtil.getGrid().deactivate();
      const dupObj = results?.[0]?.duplicateObj;
      if(dupObj) {
        const { originalFt, isDuplicate, originalOid, originalType, isSaved } = dupObj;
        if(isDuplicate && !isSaved) {
          let selectedFtIndex = 0;
          if (originalFt && originalFt.length > 0) {
            for(let i=0;i< originalFt.length; i++) {
              const oid = originalFt[i].oid;
              if((originalFt[i].key === originalType) && (originalOid === oid)) {
                selectedFtIndex = i;
                break;
              }
            }
            dispatch(setSelectedFeatureIndex(selectedFtIndex))
            dispatch(setHitTestResults(originalFt))
          }
        }
      }
    } else {
      sourceUtil.getGrid().activate();
    }
    dispatch(setUndoRedoDisabled(isInEditMode));
  }, [isInEditMode])

  useEffect(() => {
    if (isInEditMode) {
      if (!viewModel.current) {
        const vm = viewModel.current = new EditorVM({
          onSiteChange: (siteInfo) => {
            if (layerType === "facility") {
              setIsGroupExpanded();
              setAttributes();
              const { floorFilter } = Context.getInstance().views;
              floorFilter.setLevel(null);
              floorFilter.setFacility(null);
              floorFilter.setSite(siteInfo.id);
            }
          },
          onValidityChange: () => {
            setIsGroupExpanded();
            setCanSave(validate());
            setAttributes();
          }
        });
        dispatch(setSelectionVisibility(false));
        vm.isDuplicate = isDuplicate;
        vm.activateUpdateFeature(feature, layerType, cutType).then(() =>
          layerType === "level" && setZOption({ ...zOption, value: vm.z?.toString() }));
        vm.onDrawComplete = (tool) => setCanSave(validate());
        vm.onDrawUpdate = (updateType) => {
          setIsGroupExpanded();
          setCanSave(validate());
        };
        vm.onUpdateStateChanged = (eventState, toolEventType, graphic) => {
          setIsGroupExpanded();
          const a = ["move-stop","reshape-stop","rotate-stop","scale-stop","vertex-remove","vertex-add"];
          if (a.includes(toolEventType)) {
            setSvmUpdateState("start");
          } else {
            setSvmUpdateState(eventState);
          }
        }
        
        const oid = feature.attributes[layer.objectIdField];
        origLyrDef.current = layer.definitionExpression;
        if (oid != null) {
          const newExp = `${layer.objectIdField} <> ${oid}`;
          layer.definitionExpression = origLyrDef.current ? `(${origLyrDef.current}) AND (${newExp})` : newExp;
        }
      }
      viewModel.current.cutType = cutType;
      const svm = viewModel.current && viewModel.current._activeSketchViewModel;
      const disabledHeaderTools = [!svm || !svm.canUndo() ? "undo" : null, !svm || !svm.canRedo() ? "redo" : null].filter(x=>!!x);
      if (disabledTools.sort().join()!==disabledHeaderTools.sort().join()) {
        setDisabledTools(disabledHeaderTools as HeaderToolType[]);
      }
      if (layerType === "unit") {
        viewModel.current.dimensionAnnotation.setDimensionLabelInfo(dimensionLabelInfo);
      }
    } else {
      cleanup();
    }
  }, [isInEditMode, cutType, layerType, feature]);

  const cleanup = () => {
    setCanSave(false);
    setCutType(CutType.none);
    if (viewModel.current) {
      viewModel.current.onDrawComplete = null;
      viewModel.current.onDrawUpdate = null;
      viewModel.current.destroy();
      viewModel.current = null;
    }
    if (layer) {
      layer.definitionExpression = origLyrDef.current;
    }
    origLyrDef.current = null;
    ctx.views.mapClickDisabled = false;
    if (!isDuplicate) {
      dispatch(setAttributeEditorEnabled(false));
    } 
    dispatch(setSelectionVisibility(true));
  }

  const onValueChange = ({ layer, fieldName, value }) => {
    const vm = viewModel.current;
    setCanSave(validate());
    if (vm?.activeFeature?.attributes) {
      vm.activeFeature.attributes[fieldName] = value;
      const fld = layer.fields.find(f => f.name === fieldName);
      const customDomains = [FieldNames.UNITS_USE_TYPE, FieldNames.DETAILS_USE_TYPE];
      if (fld && (fld.domain || customDomains.includes(fld.name.toLowerCase()))) {
        vm.updateSketch({ [fieldName]: value }, fieldName);
      }
    }
  };

  const onApplyEdit = async (updated:FeatureToUpdate) => {
    if (!current) {
      return false;
    }
    const ae = attributesRef.current;
    const vm = viewModel.current;
    let newGeom: IGeometry;
    let newAttr = { ...updated.feature.attributes };  
    let hasErr = false;

    if (ae && vm && ae.isValid() && vm.isValid()) {
      // check for modified geometry as a result of an attribute update (i.e. Level Id)
      if (updated.feature.geometry && updated.feature.geometry.hasZ) {
        const ringsOrPaths = "rings" in updated.feature.geometry
          ? (updated.feature.geometry as IPolygon).rings
          : (updated.feature.geometry as IPolyline).paths;
        updateZ(vm.activeFeature.geometry, ringsOrPaths[0][0][2]);
        newGeom = vm.activeFeature.geometry.toJSON();
      } else if (vm.isGeometryModified()) {
        newGeom = vm.activeFeature.geometry.toJSON(); 
      }
      newAttr = { ...vm.activeFeature.attributes, ...newAttr };
      vm.activeFeature.attributes = newAttr;
      const guard = new TransactionGuard({force:true, minimal:true});
      try {
        if (results?.[0]?.duplicateObj) {
          results[0].duplicateObj.isSaved = true;
        }
        // reset definitionExpression (will be set again when entering edit mode)
        layer.definitionExpression = origLyrDef.current;
        await vm.save(vm.activeFeature.attributes, +zOption.value);
        vm.reset();
        //TODO publish this event in the future ??
        //Topic.publish(Topic.PlanModified, { action: OfficePlan.Action_AssignmentsUpdated });
      } catch(ex) {
        hasErr = true;
        console.error(ex);
        Topic.publishErrorUpdatingData(ex.message);
      } finally {
        guard && guard.close();
      }
    } else {
      console.warn("Unable to save");
    }

    //TODO requery from service and re-populate the edit panel? 
    if (!hasErr) {
      dispatch(setAttributeEditorEnabled(false));
      const attributes = { ...current.feature.attributes, ...newAttr };
      const geometry = newGeom || { ...current.feature.geometry, ...updated.feature.geometry };
      const newFeature = { ...current.feature, attributes, geometry };
      if (layerType === "level") {
        updateZ(newFeature.geometry, +zOption.value);
      }
      const idx = results.findIndex(r =>
        r.feature.attributes[updated.idField] === updated.feature.attributes[updated.idField]);

      const newResults = [...results];
      newResults.splice(idx, 1, {
        feature: newFeature,
        key: results[idx].key,
        oid: newFeature.attributes[layer.objectIdField]
      });
      dispatch(setHitTestResults(newResults));
      dispatch(setSelectionVisibility(true));
    }
    return !hasErr;
  }

  const closeAlert = () => {
    alertOptions.open && setAlertOptions({ open: false });    
  }

  const confirmDelete = async () => {
    const confirmResult = await ModalController.confirm({
      title: i18n.general.warning,
      message: i18n.editor.attributes.deleteWarningMsg,
      okLabel: i18n.general.del,
      cancelLabel: i18n.general.cancel,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    });
    return confirmResult.ok
  }

  const duplicateFeature = async(feature: __esri.Graphic): Promise<void> => {
    let dupFt = await duplicateUtil.createDuplicateFeature(feature, layerType);
    const originalOid =  feature && feature.attributes[layer.objectIdField];

    dupFt.duplicateObj = {
      isDuplicate: true,
      originalFt: results,
      originalOid: originalOid,
      originalType: layerType,
      isSaved: false
    }
    dispatch(setHitTestResults([dupFt]));  
    dispatch(setSelectedFeatureIndex(0));
    dispatch(setAttributeEditorEnabled(true));
  }

  const deleteFeature = async (f: __esri.Graphic): Promise<boolean> =>  {
    let editResults: IApplyEditsResults;
    const ok = await confirmDelete();
    if (!ok)
      return;
    let err:Error;
    const guard = new TransactionGuard({force:true, minimal:true});
    try {    
      if (layerType === "unit") {
        editResults = await transactions.fpeEditUnit({ deletedUnits: [feature] });
      } else if (layerType === "detail") {
        editResults = await transactions.editDetail({ action: "delete", feature });
      } else if (layerType === "pathway") {
        // @todo pathways
        //editResults = await transactions.editDetail({ action: "delete", feature });
        throw new Error("editing pathways not yet enabled.")
      } else {
        editResults = await transactions.editFootprint({ deletes: [feature], type: layerType });
      }
      console.debug("Edit results:", editResults);
      if (editResults) {
        viewModel.current && viewModel.current.reset();
        dispatch(setAttributeEditorEnabled(false));
        dispatch(setSelectionVisibility(true));
        const idField= layer && layer.objectIdField;
        const newResults = results.filter(r => r.feature.attributes[idField] !== feature.attributes[idField]);        
        if (newResults.length) {
          dispatch(setHitTestResults(newResults));
        } else {
          dispatch(setActiveTab(null));
        }
      }
      //Topic.publish(Topic.PlanModified, { action: OfficePlan.Action_AssignmentsUpdated });
    } catch (ex) {
      err = ex;
      console.error("Error deleting feature:", err);
      //Topic.publishErrorUpdatingData(err.message);
    } finally {
      const isOk = !err && !!editResults;
      guard && guard.close();
      setAlertOptions({
        isError: !isOk,
        message: isOk
          ? i18n.editor.deleteSuccess.replace("{type}", getCapitalizedType(layerType))
          : i18n.editor.deleteFail.replace("{type}", layerType) + (err ? `: ${err.message}` : ""),
        open: true,
      });
      return isOk;
    }
  }
  const setDisTools = (checkFuncNm:"canUndo"|"canRedo", tool:HeaderToolType) => {
    const svm = viewModel.current && viewModel.current._activeSketchViewModel;
    if (!svm || !svm[checkFuncNm]()) {
      setDisabledTools(prevDisTools => !prevDisTools.includes(tool) ? [...prevDisTools] : [ ...prevDisTools, tool]);
    } else {
      setDisabledTools(prevDisTools => prevDisTools.filter(x => x!==tool));
    }
  };

  const onHeaderToolClick = (e:React.MouseEvent, toolInfo:IHeaderToolInfo) => {
    const tool = toolInfo && toolInfo.name;
    const vm = viewModel.current;
    const svm = vm && vm._activeSketchViewModel;
    if (tool==="zoomIn") {
      onZoomToFeature(feature);
    } else if (tool==="reset") {
      attributesRef.current.reset()
    } else if (tool==="undo") {
      svm && svm.undo();
      setDisTools("canUndo", "undo");
    } else if (tool==="redo") {
      svm && svm.redo();
      setDisTools("canRedo", "redo");
    } else if (tool==="flipV") {
      vm.flip("v");
    } else if (tool==="flipH") {
      vm.flip("h");
    } else if (tool === "rotate") {
    } else if (tool==="dimensionLabels") {
      const info = Object.assign({},dimensionLabelInfo);
      info.visible = !info.visible;
      dispatch(setDimensionLabelInfo(info));
    } else if (tool==="grid") {
      const grid = sourceUtil.getGrid();
      grid.setVisible(!grid.isVisible());
      setGridVisible(grid.isVisible());
    } else {
      //console.warn(`Tool [${tool}] not implemented`, toolInfo);
    }
  }

  const renderHeaderTools = () => {
    const vm = viewModel.current;
    const feat = vm && vm.getActiveFeature() || feature;
    const rotatePopover = (<RotateAngle
      geometry={feat.geometry}
      onRotate={(angle, geom) => viewModel.current && viewModel.current.updateActiveFeatureGeom(geom)}
      onOpen={comp => {
        const ft = viewModel.current && viewModel.current.getActiveFeature();
        comp && ft && (comp.geometry = ft.geometry);
      }}
      onClose={e => {}}
    />);
    //disabled undo-redo for now #6632
    const tools: HeaderToolType[] = ["rotate", "flipV", "flipH", "separator", "zoomIn"] //"undo", "redo"
    const activeTools = [];
    let className = null;
    const gridEnabled = sourceUtil.getGrid().gridSettings.enabled;
    if (layerType === "unit") {
      tools.unshift("separator");
      if (gridEnabled) tools.unshift("grid");
      tools.unshift("dimensionLabels");
      className = "i-flex-between";
      if (dimensionLabelInfo.visible) activeTools.push("dimensionLabels")
    } else if (gridEnabled) {
      tools.unshift("separator");
      tools.unshift("grid");
      className = "i-flex-between";
    }
    if (sourceUtil.getGrid().isVisible()) activeTools.push("grid")
    return (
      <PanelHeaderTools 
        className={className}
        activeTools={activeTools}
        tools={tools}
        toolInfos={{"rotate": {name: "rotate", icon: "rotate", text: i18n.editor.headerTools.rotate, jsx: rotatePopover}}}
        disabledTools={disabledTools}
        onButtonClick={onHeaderToolClick}/>
    )
  }

  const renderMapButtons = () => {
    const vm = viewModel.current;
    const feat = vm && vm.getActiveFeature() || feature;
    const updateState = svmUpdateState;
    if (!feat) return null;
    return (
      <MapButtons
        mode="flipV-rotate-flipH"
        graphic={feat}
        updateState={updateState}
        supportsReflect={false}
        onRotate={(angle,geometry) => {
          vm && vm.updateActiveFeatureGeom(geometry)
        }}
        onRotateOpen={comp => {;
          const ft = vm && vm.getActiveFeature();
          comp && ft && (comp.geometry = ft.geometry);
        }}
        onFlipV={() => {
          vm.flip("v");
        }}
        onFlipH={() => {
          vm.flip("h");
        }}
      />
    )
  }

  const renderAttributes = () => {
    if (feature && layer) {
      const formOptions: IFormOptions = {
        layer,
        type: layerType,
        feature: new ctx.lib.esri.Graphic({
          geometry: viewModel.current?.activeFeature?.geometry || feature.geometry,
          attributes: viewModel.current?.activeFeature?.attributes || feature.attributes
        })
      };
      // TODO Migrate unit and detail form options to utilize form template
      if (layerType === "unit" || layerType === "detail") {
        const wallTypeValues = wallTypesUtil.getAllTypes(layer);
        const nonNullableFields = layerType === "unit"
        ? ["use_type", "name", "unit_id", "level_id"]
        : ["use_type", "level_id"];
        formOptions.readOnlyFields = ["assignment_type", "area_id"];
        formOptions.nonVisibleFields = [];
        formOptions.nonNullableFields = nonNullableFields;
        formOptions.topFields = ["use_type", "name", "name_long", "schedule_email", "capacity"];
        formOptions.bottomFields = ["assignment_type", "unit_id", "level_id", "area_id"];
        formOptions.showAsterisk = true;
        formOptions.useGroup = true;
        formOptions.useTypes = wallTypeValues;
      } else {
        const template = EditorVM.initFormTemplate(layerType, groupExpanded.current);
        formOptions.formTemplate = template;
        formOptions.readOnlyDisplayType = "label";
      }
      return (
        <Attributes
          footer={false}
          formOptions={formOptions}
          header={null}  //{ctx.i18n.editor.walls.unitAttributes}
          headerDisplayField={FieldNames.UNITS_USE_TYPE}
          idField={getIdField(layerType)}
          isEditing={isInEditMode}
          onApplyEdit={onApplyEdit}
          onDelete={deleteFeature}
          onDuplicate={duplicateFeature}
          onReset={() => {
            setCanSave(false);
            setZOption({ ...zOption, value: viewModel.current?.z?.toString() });
            setCutType(CutType.none);
            viewModel.current && viewModel.current.activateUpdateFeature(feature, layerType, CutType.none);
          }}
          onValueChange={onValueChange}
          ref={attributesRef}
          sendUpdates={false}
          showDelete={true}
          onCreateForm={form => formRef.current = form}
        />
      )
    }
  }

  const renderUnitOverlapOptions = () => {
    const _onBtnChange = (e:CalciteRadioButtonCustomEvent<void>) => setCutType(e.target.value);
    return (isInEditMode && layerType === "unit" &&
      <div className="i-editor-overlap-options">
      <h4>{i18n.editor.units.overlap}</h4>
      <CalciteRadioButtonGroup name="reShapeUnitOptions" layout="vertical" onCalciteRadioButtonGroupChange={
        async (e:CalciteRadioButtonGroupCustomEvent<void>) => {
          const prevCT = viewModel.current.cutType;
          viewModel.current.cutType = cutType;
          if (cutType === CutType.cutUnderlying) {
            await viewModel.current.cutUnderlyingUnits(prevCT===CutType.cutThis);
          } else if (cutType === CutType.cutThis) {
            await viewModel.current.cutNewUnit();
          } else if (cutType === CutType.none) {
            viewModel.current.cutNothing();
          }
          viewModel.current.onDrawUpdate && viewModel.current.onDrawUpdate(null);
        }}>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
              checked={cutType === CutType.cutUnderlying ? true : undefined}
              value={CutType.cutUnderlying}
              onCalciteRadioButtonChange={_onBtnChange}/>
            {i18n.editor.units.overlapPreferNew}
          </CalciteLabel>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
            checked={cutType === CutType.cutThis ? true : undefined}
            value={CutType.cutThis}
            onCalciteRadioButtonChange={_onBtnChange} />
            {i18n.editor.units.overlapPreferExisting}
          </CalciteLabel>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
            checked={cutType === CutType.none ? true : undefined}
            value={CutType.none}
            onCalciteRadioButtonChange={_onBtnChange} />
            {i18n.editor.units.overlapDoNothing}
          </CalciteLabel>
        </CalciteRadioButtonGroup>
      </div>
    )
  }
  const renderZValue = () => {
    if (layerType !== "level" || !isInEditMode)
      return;
    const zValue = zOption.value;
    const missingZValue = zValue == null;
    const showInvalid = missingZValue;
    const heading = i18n.editor.footprints.zHasValueUpdated
      .replace("{value}", viewModel.current?.z)
      .replace("{value}", zValue);
    return (
      <CalciteBlock
        className="i-editor-footprint-z-value"
        heading={heading}
        status={showInvalid ? "invalid" : missingZValue ? "idle" : "valid"}
        open={zOption.open || undefined}
        collapsible={!missingZValue || undefined}>
        <CalciteInputNumber value={zValue?.toString()} status={showInvalid ? "invalid" : "valid"} required onCalciteInputNumberInput={(e) => {
          const value = (e.currentTarget as HTMLCalciteInputNumberElement).value;
          const zValue = value != null && value.length > 0 && !isNaN(+value) ? +value : null;
          setIsGroupExpanded();
          if (zValue != null) {
            const isValid = attributesRef.current?.isValid() && viewModel.current.isValid() && zValue != null;            
            setCanSave(isValid);
          } else {
            setCanSave(false);
          }
          setZOption({ value, open: true });
        }}/>
      </CalciteBlock>
    )
  }
  const setAttributes = () => {
    if (attributesRef.current) {
      attributesRef.current.setFormValues(viewModel.current.activeFeature.attributes);
    }
  }
  const setCanSave = (enabled: boolean, force?: boolean) => {
    canSave.current = enabled;
    saveBarRef.current && saveBarRef.current.setSaveEnabled(enabled);
    if (force) {
      forceUpdate();
    }
  }
  const setIsGroupExpanded = () => {
    if (formRef.current) {
      const groups = formRef.current.viewModel.inputs.filter(i => i.type === "group") as __esri.GroupInput[];
      const expanded = groups.some(g => g.state === "expanded");
      groupExpanded.current = expanded;
    }
  }
  const validate = () => {
    const vm = viewModel.current;
    const ae = attributesRef.current;
    const canSave = vm.isValid() && ae.isValid()
      && (vm.isGeometryModified() || vm.isOtherUnitsModified() || ae.hasValidChanges());
    return canSave;
  }

  let activeFt = viewModel && viewModel.current && viewModel.current.getActiveFeature();
  if (!activeFt) activeFt = feature;

  return (
    <div className="i-editor-sidebar-container i-editor-attributes-container">
      {!isInEditMode && <FeatureSelector />}
      {isInEditMode &&
        <div className="i-editor-sidebar-toolbar">
          {renderHeaderTools()}
          {renderMapButtons()}
        </div>
      }
      <div className="i-editor-sidebar-content">
        {renderUnitOverlapOptions()}
        {renderAttributes()}
        {renderZValue()}
      </div>
      {isInEditMode &&
        <div className="i-editor-sidebar-footer">
          <Savebar
            parentPanelKey={`editorPanel_${layerType}`}
            resetDisabled={false}
            saveDisabled={!canSave.current}
            onReset={attributesRef.current.reset}
            onSave={attributesRef.current.save}
            ref={saveBarRef}
          />
        </div>}
        {alertOptions && alertOptions.open &&
          <CalciteAlert
            autoClose={!alertOptions.isError}
            autoCloseDuration="medium"
            kind={alertOptions.isError ? "danger" : "success"}
            icon={alertOptions.isError ? "exclamation-mark-triangle" : "check-circle"}
            label="Editor"
            onCalciteAlertClose={closeAlert}
            placement="bottom-end"
            scale="m"
            {...alertOptions}>
            <div slot="title">{alertOptions.title}</div>
            <div slot="message">{alertOptions.message}</div>
          </CalciteAlert>
        }
    </div>
  );
}

export default EditorPanel;