import React from "react";
import {connect} from "react-redux";

import Rdx from "../../../redux/Rdx";

class TabPanel extends React.Component {

  render() {
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);

    if (!active) {
      return null;
    }
    let cls = "i-tab-panel";
    if (this.props && typeof this.props.className === "string" &&
      this.props.className.length > 0) {
      cls += " "+this.props.className;
    }

    return (
      <div className={cls}>
        {this.props.children}
      </div>
    );
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state,ownProps.navKey)
  }
}

export default connect(mapStateToProps)(TabPanel);
