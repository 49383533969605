import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import { ensureGraphicsLayer, getLayer, removeAllGraphics } from "../../../base/mapUtil";
import { findField, findFieldName } from "../../../../aiim/util/aiimUtil";
import { generateRandomUuid } from "../../../../util/val";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import TransactionGuard from "../../../base/TransactionGuard";
import { editFootprint } from "../../../base/transaction/transactions";
import { updateGrossArea, updateZ, WarningMsg } from "../support/editorUtil";
import Topic from "../../../../context/Topic";
import { FootprintType, getFieldObjects, hasLayer, getFormTemplate, getLevelData, getSite } from "../support/formUtil";
import { validateFacilities, validateSites } from "../../../base/validationUtil";

export type PolygonGraphic = __esri.Graphic & { geometry: __esri.Polygon };
export interface FootprintInfo { geometry: __esri.Polygon, id: string, name: string };

export interface IFootprintVMProps {
  addOption?: "duplicate_facility" | "duplicate_level" | "draw",
  fpeType: FootprintType,
  onDrawComplete?: (tool: "create" | "update" | "delete" | "undo" | "redo") => void,
  onDrawUpdate?: (tool: string) => void,
  onSiteChange?: (siteInfo: FootprintInfo) => void,
  onValidityChange?: (isValid: boolean) => void
}
const svmUpdateOptions: __esri.SketchViewModelDefaultUpdateOptions = {
  tool: "reshape", // "transform"|"reshape"|"move"
  enableRotation: true,
  enableScaling: true,
  preserveAspectRatio: false,
  toggleToolOnClick: true,
  enableZ: true
};
const warnZAttrs = "Unable to set floor/level/z, rel height for new footprint, either feature is drawn over an invalid area or nothing selected in the FloorFilter widget.";

export default class FootprintVM extends BaseVM implements IFootprintVMProps {
  activeFeature: PolygonGraphic;
  addOption: IFootprintVMProps["addOption"];
  private activeSketchViewModel: __esri.SketchViewModel;
  private facilityHighlightSym = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [222, 222, 222, 0.9],
    style: "solid",
    outline: {
      color: [0, 255, 255, 0.9],
      width: 4,
      style: "none"
    }
  });
  facility: FootprintInfo;
  fldFacilityId: __esri.Field;
  fldLevelId: __esri.Field;
  fldSiteId: __esri.Field;
  fldsToLog: string[];
  fpeType: IFootprintVMProps["fpeType"];
  private invalidSymbol: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [255, 0, 0, 1],
    style: "diagonal-cross",
    outline: {
      color: [75, 0, 0, 0.5],
      width: 3,
      style: "dash"
    }
  });
  private isValidGeometry = false;
  private layerIdResults = "indoors-footprints-panel-result";
  original: __esri.Graphic;
  private selectedLineSymbol: __esri.SimpleLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol({
    style: "solid",
    color: [0, 0, 0, .8]
  });
  private selectedFillSymbol: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    style: "solid",
    color: [0, 0, 0, .4],
    outline: this.selectedLineSymbol
  });
  site: FootprintInfo;
  private warnings: WarningMsg[];
  onDrawUpdate: (tool: string) => void;
  onDrawComplete: (tool: "create" | "update" | "delete" | "undo" | "redo") => void;
  onSiteChange: (siteInfo: FootprintInfo) => void;
  onValidityChange: (isValid: boolean) => void;
  zDefault: number = null;

  constructor(props: IFootprintVMProps) {
    super(props);
    this.mixinProps(props);
    this.warnings = [];
    this.ensureLayersAndFields();
  }
  /** Activates sketch mode based on a provided geometry or an empty polygon. Activation will set 
   * the `activeFeature` using prototypical attributes based on `fpeType` or the provided graphic 
   * (if `existing` is a graphic). 
   * @param [existing] - Either a polygon geometry or a graphic representing an existing footprint.
   * If no geometry is provided, an empty polygon will be initialized and sketching will provide 
   * the final polygon.
   */
  async activate(existing?: __esri.Geometry | __esri.Graphic) {
    const { lib: { esri } } = Context.getInstance();
    const SketchViewModel: typeof __esri.SketchViewModel = esri.SketchViewModel;
    const Polygon: typeof __esri.Polygon = esri.Polygon;
    const view = this.getView();
    const layer = getLayer(this.fpeType);
    this.cancelSketch();
    this.clearGraphics();
    this.clearHandles();
    if (!layer) {
      return;
    }
    const feature = !existing || existing instanceof Polygon
      ? this.initNewFeature(existing as __esri.Polygon)
      : existing.clone() as PolygonGraphic;
    this.original = feature.clone();
    this.activeFeature = feature;
    const gl = ensureGraphicsLayer(view, this.layerIdResults);
    const activeFillSymbol = this.activeFeature.symbol = await this.getSymbol(this.activeFeature);
    if (this.activeFeature.geometry.rings[0]?.length > 3) {
      await updateGrossArea(this.activeFeature);
    }

    if (this.activeSketchViewModel) {
      this.activeSketchViewModel.destroy();
    }
    this.activeSketchViewModel = new SketchViewModel({
      view,
      layer: gl,
      activeFillSymbol,
      //@ts-ignore
      activeLineSymbol: activeFillSymbol.outline || this.selectedLineSymbol,
      defaultCreateOptions: { defaultZ: 0, mode: "click" },
      defaultUpdateOptions: svmUpdateOptions,
      updateOnGraphicClick: true,
      snappingOptions: {
        enabled: true,
        selfEnabled: true,
        featureEnabled: true,
        featureSources: this.fpeType === "level"
          ? [{ layer, enabled: true }, { layer: getLayer("facility"), enabled: true }]
          : [{ layer, enabled: true }]
      }
    });
    this.own([
      this.activeSketchViewModel.on("create", this.onCreate.bind(this)),
      this.activeSketchViewModel.on("update", this.onUpdate.bind(this)),
      this.activeSketchViewModel.on("delete", this.onDelete.bind(this)),
      this.activeSketchViewModel.on("undo", event => this.processDuringDrawUpdate(event)),
      this.activeSketchViewModel.on("redo", event => this.processDuringDrawUpdate(event))
    ]);
    if (this.fpeType === "site" || this.fpeType === "facility" || this.addOption === "draw") {
      this.isValidGeometry = false;
      this.activeSketchViewModel.create("polygon", { defaultZ: 0, hasZ: true });
    } else if (this.addOption) {
      await this.validateGeometry(this.activeFeature);
      if (this.isValidGeometry) {
        gl.add(this.activeFeature);
        this.activeSketchViewModel.update(this.activeFeature, svmUpdateOptions);
        view.focus();
      }
    }
  }
  cancelSketch() {
    const view = this.getView();
    if (this.activeSketchViewModel) {
      this.activeSketchViewModel.cancel();
    }
    if (view) {
      view.container.style.cursor = "default";
    }
  }
  clearGraphics() {
    removeAllGraphics(this.getView(), this.layerIdResults);
  }
  override destroy() {
    this.reset();
    super.destroy();
  }
  ensureLayersAndFields() {
    const view = this.getView();
    const layer = getLayer(this.fpeType);
    if (!layer) {
      return;
    }
    const required = this.getRequiredFields();
    const invalids = required.filter(x => !x);
    if (invalids && invalids.length > 0) {
      console.warn("Unable to find required field/s:", required.map(f => f?.name));
    }
    this.fldsToLog = required.map(f => f?.name).concat([layer.objectIdField]);
    this.zDefault = layer.sourceJSON.zDefault;

    ensureGraphicsLayer(view, this.layerIdResults);
  }
  getRequiredFields() {
    const fields = getFieldObjects(this.fpeType);
    const layer = getLayer(this.fpeType);
    if (!layer) {
      return [];
    }
    const required = fields.filter(f => f.required).map(f => {
      const field = findField(layer.fields, f.name);
      if (field?.name?.toLowerCase() === FieldNames.SITE_ID) {
        this.fldSiteId = field;
      } else if (field?.name?.toLowerCase() === FieldNames.FACILITY_ID) {
        this.fldFacilityId = field;
      } else if (field?.name?.toLowerCase() === FieldNames.LEVEL_ID) {
        this.fldLevelId = field;
      }
      return field;
    });

    return required;
  }
  getSites(): { id: string, name: string }[] {
    const { aiim: { datasets: { levels: { _data: { sitesById = {} } } } } } = Context.getInstance();
    return Object.values(sitesById || {});
  }
  private async getSymbol(graphic?: __esri.Graphic): Promise<__esri.SimpleFillSymbol> {
    const { renderer } = getLayer(this.fpeType);
    if (!graphic && this.activeFeature && this.activeFeature.attributes)
      graphic = this.activeFeature;
    if (this.fpeType === "level" && this.addOption !== "draw")
      return this.facilityHighlightSym.clone();

    if (renderer.type === "simple") {
      return <__esri.SimpleFillSymbol>(renderer as __esri.SimpleRenderer).symbol;
    } else if (renderer.type === "unique-value") {
      const info = await (renderer as __esri.UniqueValueRenderer).getUniqueValueInfo(graphic);
      return <__esri.SimpleFillSymbol>(info?.symbol || (renderer as __esri.UniqueValueRenderer).defaultSymbol);
    } else {
      return this.selectedFillSymbol;
    }
  }
  private initAttributes(attributes: Record<string, any>) {
    const {
      i18n,
      aiim: { datasets: { levels } },
      views: { floorFilter: { activeWidget } } } = Context.getInstance();

    const levelData = levels.getLevelData(activeWidget.level);
    const layer = getLayer(this.fpeType);
    if (!layer) return;

    const fldNm = findField(layer.fields, FieldNames.NAME);
    const currentSiteId = activeWidget.site;
    const currentFacilityId = activeWidget.facility;

    if (this.fpeType === "site") {
      const fldNmLong = findField(layer.fields, FieldNames.NAME_LONG);
      if (fldNm && attributes.hasOwnProperty(fldNm.name))
        attributes[fldNm.name] = i18n.editor.newSite;
      if (fldNmLong && attributes.hasOwnProperty(fldNmLong.name))
        attributes[fldNmLong.name] = i18n.editor.newSite;
      if (this.fldSiteId && attributes.hasOwnProperty(this.fldSiteId.name))
        attributes[this.fldSiteId.name] = generateRandomUuid();
    } else if (this.fpeType === "facility") {
      const fldNmLong = findField(layer.fields, FieldNames.NAME_LONG);
      if (fldNm && attributes.hasOwnProperty(fldNm.name))
        attributes[fldNm.name] = i18n.editor.newFacility;
      if (fldNmLong && attributes.hasOwnProperty(fldNmLong.name))
        attributes[fldNmLong.name] = i18n.editor.newFacility;
      if (this.fldSiteId && attributes.hasOwnProperty(this.fldSiteId.name))
        attributes[this.fldSiteId.name] = currentSiteId;
      if (this.fldFacilityId && attributes.hasOwnProperty(this.fldFacilityId.name))
        attributes[this.fldFacilityId.name] = generateRandomUuid();
    } else {
      // @ts-ignore
      const currentFacilityName = levelData?.facilityName || activeWidget.viewModel.getFacility(activeWidget.facility)?.name;
      const currentLevelName = levelData?.levelName;
      const fldNmShort = findField(layer.fields, FieldNames.NAME_SHORT);
      const fldLvlNum = findField(layer.fields, FieldNames.LEVEL_NUMBER);
      const fldVO = findField(layer.fields, FieldNames.VERTICAL_ORDER);
      const fldRelH = findField(layer.fields, FieldNames.HEIGHT_RELATIVE);
      const name: string = this.addOption === "duplicate_facility"
        ? currentFacilityName
        : this.addOption === "duplicate_level"
          ? currentLevelName
          : i18n.editor.newLevel;
      if (fldNm && this.addOption && attributes.hasOwnProperty(fldNm.name))
        attributes[fldNm.name] = this.addOption === "draw" ? name : `${name} - ${i18n.editor.levels.copy}`;
      if (fldNmShort && name && attributes.hasOwnProperty(fldNmShort.name))
        delete attributes[fldNmShort.name];
      if (fldLvlNum && attributes.hasOwnProperty(fldLvlNum.name))
        delete attributes[fldLvlNum.name];
      if (fldVO && attributes.hasOwnProperty(fldVO.name))
        delete attributes[fldVO.name];
      if (fldRelH && attributes.hasOwnProperty(fldRelH.name))
        delete attributes[fldRelH.name];
      if (this.fldFacilityId && attributes.hasOwnProperty(this.fldFacilityId.name))
        attributes[this.fldFacilityId.name] = currentFacilityId;
      if (this.fldLevelId && attributes.hasOwnProperty(this.fldLevelId.name))
        attributes[this.fldLevelId.name] = generateRandomUuid();
    }
  }
  initFormTemplate(groupExpanded: boolean = false) {
    const layer = getLayer(this.fpeType);
    if (!layer) {
      return;
    }
    const fieldConfigs = getFieldObjects(this.fpeType);
    const siteIdField = fieldConfigs.find(f => f.name === FieldNames.SITE_ID);
    if (siteIdField) {
      siteIdField.visible = getLayer("site") != null;
    }
    const template = getFormTemplate(this.fpeType, layer.fields, fieldConfigs, groupExpanded);
    return template;
  }
  initNewFeature(existingGeometry?: __esri.Polygon) {
    const { lib } = Context.getInstance();
    const layer = getLayer(this.fpeType);
    if (!layer) {
      return;
    }

    const prototype = layer.templates?.[0]?.prototype ?? {};
    const attributes = prototype.attributes ? { ...prototype.attributes } : {};
    this.initAttributes(attributes);
    const newFeature: PolygonGraphic = new lib.esri.Graphic({
      attributes,
      geometry: existingGeometry
        ? existingGeometry.clone()
        : new lib.esri.Polygon({
          spatialReference: layer.spatialReference.clone()
        })
    });
    this.getSymbol(newFeature).then(symbol => newFeature.symbol = symbol);
    return newFeature;
  }

  isGeometrySimple(): boolean {
    if (!this.activeFeature?.geometry || this.activeFeature.geometry.isSelfIntersecting)
      return false;
    const ge: typeof __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    this.simplify(this.activeFeature);
    return ge.isSimple(this.activeFeature.geometry);
  }

  isGeometryEqual(geometry: __esri.Geometry, otherGeometry: __esri.Geometry): boolean {
    if (!(geometry && otherGeometry && geometry.type === otherGeometry.type)) {
      return false;
    }
    const ge: typeof __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    return ge.equals(geometry, otherGeometry);
  }

  isSketchActive() {
    return this.activeSketchViewModel && this.activeSketchViewModel.state === "active";
  }
  isValid(): boolean {
    return this.isValidGeometry;
  }
  private async onCreate(event: __esri.SketchViewModelCreateEvent) {
    if (event.state === "start" && this.fpeType === "facility") {
      this.site = getSite((event.graphic as PolygonGraphic).geometry.centroid);
      if (!this.site) {
        this.processDuringDrawUpdate(event);
      } else {
        this.activeFeature.attributes[this.fldSiteId.name] = this.site.id;
        this.onSiteChange && this.onSiteChange(this.site);
      }
    } else if (event.state === "active" && this.fpeType !== "level") {
      if (this.fpeType === "facility") {
        this.site = getSite(event.graphic.geometry);
      }
      await this.validateGeometry(event.graphic as PolygonGraphic, false);
    } else if (event.state === "complete") {
      this.cancelSketch();
      this.simplify(event.graphic);
      await this.processDuringDrawUpdate(event);
      this.activeSketchViewModel.update(event.graphic);
    } else if (event.state === "cancel"
      && (this.fpeType === "site" || this.fpeType === "facility" || this.addOption === "draw")) {
      this.isValidGeometry = false;
      this.activeSketchViewModel.create("polygon", { defaultZ: 0, hasZ: true });
      this.onValidityChange && this.onValidityChange(this.isValid());
    }
  }
  private async onDelete() {
    if (this.fpeType === "site" || this.fpeType === "facility" || this.addOption === "draw") {
      this.isValidGeometry = false;
      this.activeSketchViewModel.create("polygon", { defaultZ: 0, hasZ: true });
    } else if (this.addOption) {
      await this.validateGeometry(this.activeFeature);
      if (this.isValidGeometry) {
        this.activeSketchViewModel.layer.add(this.activeFeature);
        this.activeSketchViewModel.update(this.activeFeature, svmUpdateOptions);
      }
    }
    this.onValidityChange && this.onValidityChange(this.isValid());
  }
  private async onUpdate(event: __esri.SketchViewModelUpdateEvent) {
    const graphic = event.graphics?.[0] as PolygonGraphic;
    const geometry = graphic?.geometry;

    const type = event.toolEventInfo?.type;
    const handledTypes = ["move-stop", "reshape-stop", "rotate-stop", "scale-stop", "vertex-remove", "vertex-add"];
    const activeTypes = ["move", "reshape"];

    if (handledTypes.includes(type)) {
      await this.processDuringDrawUpdate(event);
    } else if (activeTypes.includes(type) && this.fpeType !== "level") {
      if (this.fpeType === "facility") {
        this.site = getSite(geometry);
        if (this.site) {
          this.activeFeature.attributes[this.fldSiteId.name] = this.site.id;
          this.onSiteChange && this.onSiteChange(this.site);
        }
      }
      await this.validateGeometry(graphic, false);
      this.onValidityChange && this.onValidityChange(this.isValid());
    }
    if (event.state === "complete") {
      this.simplify(graphic);
    }
  }
  private async processDuringDrawUpdate(event: __esri.SketchViewModelUpdateEvent
    | __esri.SketchViewModelCreateEvent
    | __esri.SketchViewModelUndoEvent
    | __esri.SketchViewModelRedoEvent) {

    const gfx = "graphics" in event ? event.graphics && event.graphics[0] : (event as __esri.SketchViewModelCreateEvent).graphic;
    const geometry = gfx && gfx.geometry;
    if (geometry) {
      this.activeFeature.geometry = geometry as __esri.Polygon;
    }

    await this.validateGeometry(this.activeFeature);
    gfx.symbol = this.activeFeature.symbol;

    //TODO allow option to clip geometry to facility
    if (this.activeFeature.geometry.rings[0]?.length > 3) {
      await updateGrossArea(this.activeFeature);
    }

    this.onValidityChange && this.onValidityChange(this.isValid());
    return null;
  }
  reset() {
    this.warnings.length = 0;
    this.isValidGeometry = false;
    this.clearHandles();
    this.clearGraphics();
    if (this.activeSketchViewModel) {
      this.activeSketchViewModel.cancel();
      this.activeSketchViewModel.destroy();
      this.activeSketchViewModel = null;
    }
  }
  async save({ attributes, zValue }: { attributes: Record<string, any>, zValue: number}) {
    const { lib } = Context.getInstance();
    const graphic = this.activeFeature;
    if (!graphic)
      throw new Error("Unable to get geometry or attributes to save");

    const error = (error) => {
      console.error("Error updating feature", error);
      throw error;
    }
    if (this.fpeType === "facility" || this.fpeType === "site") {
      const geometry = this.activeFeature.geometry.clone();
      updateZ(geometry, 0);
      const features: __esri.Graphic[] = [new lib.esri.Graphic({
        attributes: { ...attributes },
        geometry
      })];

      const guard = new TransactionGuard({ force: true });
      return editFootprint({ type: this.fpeType, adds: features })
        .catch(error)
        .finally(() => guard.close());
    } else {
      return this.validateFields(attributes).then(() => {
        updateZ(graphic.geometry, this.fpeType === "level" ? zValue : 0);
      }).then(() => {
        const fldGrossArea = findFieldName(getLayer(this.fpeType).fields, FieldNames.UNITS_AREA_GROSS);
        if (!attributes[fldGrossArea] || isNaN(attributes[fldGrossArea])) {
          return updateGrossArea(graphic);
        }
      }).then(() => {
        const guard = new TransactionGuard({ force: true });
        return editFootprint({
          type: this.fpeType,
          adds: [graphic]
        }).catch(error)
          .finally(() => guard.close());
      });
    }
  }
  simplify(graphic: __esri.Graphic) {
    if (graphic?.geometry) {
      const ge: typeof __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
      const isSimple = ge.isSimple(graphic.geometry);
      if (!isSimple) {
        graphic.geometry = ge.simplify(graphic.geometry) as __esri.Polygon;
      }
    }
  }
  private async validateFacility(graphic: PolygonGraphic, showError = true) {
    const { i18n: { editor } } = Context.getInstance();
    const [valid, overlaps] = await validateFacilities(graphic);
    this.isValidGeometry = valid;
    if (!this.isValidGeometry && showError) {
      const error: WarningMsg = {
        type: "error",
        message: editor.warnLocationInvalid,
        submessage: overlaps
          ? editor.facilities.errOverlapsOtherFootprint
          : editor.facilities.errNotWithinSite
      };
      Topic.publish(Topic.ShowToast, error);
    }
  }
  private async validateFields(attr: Record<string, any>): Promise<void> {
    const { i18n } = Context.getInstance();
    const layer = getLayer(this.fpeType);

    const required = this.getRequiredFields();
    const fldLvlId = findField(layer.fields, FieldNames.LEVEL_ID);
    const fldNmShort = findField(layer.fields, FieldNames.NAME_SHORT);
    const fldNm = findField(layer.fields, FieldNames.NAME);
    const fldLvlNum = findField(layer.fields, FieldNames.LEVEL_NUMBER);
    const fldVO = findField(layer.fields, FieldNames.VERTICAL_ORDER);
    const fldFacId = findField(layer.fields, FieldNames.FACILITY_ID);

    // get vertical order, query for VO-1, if exists then use that zval + rel ht, else zVal=rel ht
    const invalidFlds = required.filter(f => {
      return [null, undefined, "",].includes(attr[f.name])
        || (["small-integer", "integer", "single", "double", "long"].includes(f.type) && isNaN(attr[f.name]))
    });
    if (invalidFlds.length > 0) {
      throw new Error(i18n.editor.levels.invalidField.replace("{fields}", invalidFlds.join(", ")));
    }

    const where = `${fldFacId.name}='${attr[fldFacId.name]}'`;
    const uniqFlds = [fldLvlId, fldNmShort, fldNm, fldLvlNum, fldVO];
    const fsAll = await layer.queryFeatures({
      where,
      returnGeometry: false,
      outFields: uniqFlds.map(f => f.name),
      gdbVersion: this.getVersionName()
    });
    const fldsToPrint = this.fldsToLog;
    const ftToStr = function (attr: Record<string, any>) {
      return `[${attr ? fldsToPrint.map(u => attr[u]).filter(x => !!x).join(",") : ''}]`;
    }
    const prevSavedLvlId = this.original.attributes?.[fldLvlId.name];
    if (fsAll?.features?.length > 0) {
      const notUniqFlds = uniqFlds.filter((f: __esri.Field) => {
        const sameVals = fsAll.features.filter(ft => {
          const isSame = prevSavedLvlId && ft.attributes[fldLvlId.name] === prevSavedLvlId;
          return !isSame && ft.attributes[f.name] === attr[f.name];
        });
        if (sameVals.length > 0) {
          console.warn(`Value[${attr[f.name]}] for field[${f.name}] in new level is NOT unique, attributes of new level:`,
            ftToStr(attr), "Existing feature/s:", sameVals.map(ft => ftToStr(ft.attributes)));
          return true;
        }
        return false;
      });
      if (notUniqFlds.length > 0) {
        throw new Error(i18n.editor.levels.nonUniqueField.replace("{fields}", notUniqFlds.map(f => f.name).join(", ")));
      }
    }
  }
  private async validateLevel(graphic: PolygonGraphic, showError = true) {
    const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
    const { error } = await getLevelData(graphic.geometry);
    const within = graphic?.geometry && this.facility?.geometry
      ? engine.within(graphic.geometry, this.facility.geometry)
      : false;
    this.isValidGeometry = !error || within ? true : false;
    if (!this.isValidGeometry && this.facility != null && showError) {
      Topic.publish(Topic.ShowToast, error);
    }
  }
  private async validateGeometry(graphic: PolygonGraphic, showError = true) {
    this.warnings.length = 0;
    Topic.publish(Topic.ClearToast, {});

    if (this.fpeType === "level") {
      await this.validateLevel(graphic, showError);
    } else if (this.fpeType === "site") {
      this.validateSite(graphic, showError);
    } else if (this.fpeType === "facility") {
      this.validateFacility(graphic, showError);
    } else {
      this.isValidGeometry = graphic?.geometry.rings[0]?.length > 3;
    }
    graphic.symbol = this.isValidGeometry ? await this.getSymbol(graphic) : this.invalidSymbol;
  }
  private async validateSite(graphic: PolygonGraphic, showError = true) {
    const { i18n } = Context.getInstance();
    const valid = await validateSites(graphic);
    this.isValidGeometry = valid;
    if (!this.isValidGeometry && showError) {
      const error: WarningMsg = {
        type: "error",
        message: i18n.editor.warnLocationInvalid,
        submessage: i18n.editor.sites.errOverlapsOtherFootprint
      };
      Topic.publish(Topic.ShowToast, error);
    }
  }
}
