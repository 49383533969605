// TODO: Update to @reduxjs/toolkit
import { combineReducers, createStore, Store } from "redux";
import Rdx from "./Rdx";

let store: Store;

export function getStore() {
  return store;
}

export function initStore() {
  if (store) return store;
  const rootReducer = combineReducers(Rdx.Reducers);
  const initialState = Rdx.getInitialState();
  store = createStore(rootReducer,initialState);
  Rdx.store = store;
  //window.rdxStore = store;
  return store;
}
