import React, { CSSProperties } from 'react';

// Calcite
import Panel from "calcite-react/Panel";
import Popover from 'calcite-react/Popover';
import Tooltip from "calcite-react/Tooltip";

// Calcite Icons
import DownloadToIcon from 'calcite-ui-icons-react/DownloadToIcon';
import WebIcon from 'calcite-ui-icons-react/WebIcon';
import QuestionIcon from "calcite-ui-icons-react/QuestionFIcon";


// components.main.Events
import { IICSData, makeIcsInfo } from '../components/main/Events/ics';

// context
import Context from '../context/Context';

// util
import { addToCalendar, removeFromCalendar, updateInCalendar, CALENDAR_TYPES, ICalendarEventInfo, CalendarType, ICalendarICS, BookingType } from './calendarUtil';
import { getRecurrencePattern } from '../aiim/util/aiimUtil';
import { IFeature } from '@esri/arcgis-rest-types';

const CSS = {
  main: "i-calendar-selector",
  logo: "i-calendar-logo",
  logoGoogle: "i-calendar-logo--google",
  logoOutlook: "i-calendar-logo--outlook",
  genericButton: "i-generic-button"
};
interface ICalendarSelectorProps {
  bookingType?: BookingType,
  onRequestClose: () => void,
  open: boolean,
  origResAttr?: Record<string, any>,
  params: Promise<ICalendarEventInfo>,
  removeBooking?: boolean,
  reservation?: __esri.Graphic | IFeature,
  targetEl: JSX.Element,
  updateBooking?: boolean,
  _updateICSfile?: boolean
}
export default class CalendarSelector extends React.Component<ICalendarSelectorProps> {
  icsInfo: IICSData;
  icsInfoOccurrence: IICSData;
  icsInfoSeries: IICSData;

  // constructor(props) {
  //   super(props);

  //   // this.state = {
  //   //   icsInfo: null
  //   // };
  // }

  override componentDidMount() {
    this._init();
  }

  override componentDidUpdate(prevProps: ICalendarSelectorProps) {
    if (this.props._updateICSfile !== prevProps._updateICSfile) {
      if (this.props._updateICSfile) {
        this._init();
      }
    }
  }
  private _init() {
    this.props.params.then((params)=> {
      const { eventICS } = params;

      if (!eventICS) {
        return;
      }
      this.updateICSInfo(eventICS);
    });
  }

  isRecurringBooking = () => {
    if (this.props?.reservation) {
      return getRecurrencePattern(this.props.reservation.attributes) != null;
    }
    return false;
  }

  async updateICSInfo(eventICS: ICalendarICS) {
    const { info, filename } = eventICS;
    let bookingOperation;
    if (this.props && this.props.removeBooking) bookingOperation = "removeBooking";
    else if (this.props && this.props.updateBooking) bookingOperation = "updateBooking";
    else bookingOperation = "addBooking";

    const isRecurringBooking = "recurrenceOptions" in info ? info.recurrenceOptions != null : false;
    if (isRecurringBooking) {
      if (bookingOperation === "addBooking") {
        this.icsInfoSeries = await makeIcsInfo({ ...info }, filename, bookingOperation, "series");
      } else {
        this.icsInfoOccurrence = await makeIcsInfo({ ...info }, filename, bookingOperation, this.props.bookingType);
      }
    } else {
      this.icsInfo = await makeIcsInfo(info, filename, bookingOperation);
    }
  }

  render() {
    const { onRequestClose, open, targetEl } = this.props;

    return (
      <Popover
        onRequestClose={onRequestClose}
        open={open}
        targetEl={targetEl}
        appendToBody
      >
        <Panel white>
          {this.renderContent()}
        </Panel>
      </Popover>
    );
  }

  renderContent() {
    let items = [];
    const workspaceReservation = Context.instance.config.workspaceReservation;
    const google = typeof workspaceReservation.enableGoogleCalendar == 'boolean' ? workspaceReservation.enableGoogleCalendar : null;

    if(google && this.props.updateBooking && !this.isRecurringBooking()) items.push(this.renderGoogle("addGoogleBkg"));
    items.push(...Object.values(CALENDAR_TYPES).map((type: CalendarType) => this.renderOption(type)));

    return (
      <div className={CSS.main}>
        <ul>
          {items}
        </ul>
      </div>
    );
  }

  renderOption(type: CalendarType) {
    const workspaceReservation = Context.instance.config.workspaceReservation;
    const google = workspaceReservation.enableGoogleCalendar;
    const outlook = workspaceReservation.enableOutlookCalendar;
    const isRecurringBooking = this.isRecurringBooking();
    const options = [];
    switch(type) {
      case CALENDAR_TYPES.google:
        if (google && !isRecurringBooking) options.push(this.renderGoogle());
        break;
      case CALENDAR_TYPES.outlookApp:
        if (outlook && !isRecurringBooking) options.push(...this.renderOutlookApp(isRecurringBooking));
        break;
      // case CALENDAR_TYPES.outlookOnline:
      //   if (!outlook) return null;
      //   else return this.renderOutlookOnline(isRecurringBooking);
      case CALENDAR_TYPES.generic:
        options.push(...this.renderDownload(isRecurringBooking));
        break; 
    }
    return options;
  }

  onClick = (event, info?) => {
    event.stopPropagation();
    const type = event.target.getAttribute("data-caltype");
    const promise = this.props.params;
    promise.then((params)=> {
      if (type) {
        if(this.props && this.props.removeBooking) removeFromCalendar(type, params);
        else if (this.props && this.props.updateBooking) {
          const origResAttr = this.props && this.props.origResAttr;
          if (info && info === "addGoogleBkg") {
            addToCalendar(type, params);
          }
          else updateInCalendar(type, params, origResAttr);
        }
        else addToCalendar(type, params);
      }
    })
    const { onRequestClose } = this.props; 
    if (typeof onRequestClose === "function") {
      onRequestClose();
    }
  }

  renderGoogle(info?: string) {
    const i18n = Context.getInstance().i18n;
    const isRemoveBkg = this.props && this.props.removeBooking;
    const isUpdateBkg = this.props && this.props.updateBooking
    const isRtl = Context.instance.uiMode.isRtl;
    const qstyle: CSSProperties = {
      marginTop: "0.8rem",
      fill: "#6e6e6e"
    }

    if (isRtl) qstyle.marginRight = "0.5rem"
    else qstyle.marginLeft = "0.5rem"

    let textAlign = "left";
    if(isRtl) textAlign = "right"

    let title = i18n.calendars.google;
    let infoTitle = i18n.calendars.googleInfoUpdate;
    if (isUpdateBkg) {
      title = i18n.calendars.googleUpdate;
      if (info === "addGoogleBkg") {
        title = i18n.calendars.googleNew;
        infoTitle = i18n.calendars.googleInfoAdd;
      }
    }
   
    return (
      <li key={CALENDAR_TYPES.google} className="i-calendar-tooltip" style={{display: "flex"}}>
        <button 
          data-caltype={"google"}
          onClick={(event)=>this.onClick(event,info)}>
          <img 
            data-caltype={"google"}
            className={CSS.logoGoogle} 
            src="assets/Google.png"
            alt={i18n.general.image} 
          />
          <h3 data-caltype={"google"}>{title}</h3>
        </button>
        {isRemoveBkg &&
            <Tooltip style={{textAlign: textAlign}} title={i18n.calendars.googleInfo}>
          <QuestionIcon size={16} style={qstyle}/>
          </Tooltip>
        }
        {isUpdateBkg && 
            <Tooltip style={{textAlign: textAlign}} title={infoTitle}>
          <QuestionIcon size={16} style={qstyle}/>
          </Tooltip>
        }
      </li>
    );
  }

  renderOutlookApp(isRecurringBooking: boolean) {
    const i18n = Context.getInstance().i18n;
    const isRemoveBkg = this.props && this.props.removeBooking;
    const isUpdateBkg = this.props && this.props.updateBooking
    const isRtl = Context.instance.uiMode.isRtl;
    const qstyle: CSSProperties = {
      marginTop: "0.8rem",
      fill: "#6e6e6e"
    }
    
    if (isRtl) qstyle.marginRight = "0.5rem"
    else qstyle.marginLeft = "0.5rem"

    let textAlign = "left";
    if(isRtl) textAlign = "right"

    if (!this.icsInfo) {
      return [];
    }

    return [
      <li key={CALENDAR_TYPES.outlookApp} className="i-calendar-tooltip" style={{display: 'flex'}}>
        <a 
          onClick={this.onClick}
          href={this.icsInfo.href} 
          role="button"
          download={this.icsInfo.filename} 
          rel="noopener noreferrer" 
          target="_blank"
        >
          <img 
            data-caltype={"outlook-app"}
            className={CSS.logoOutlook} 
            src="assets/Outlook_64x64.svg"
            alt={i18n.general.image} 
          />
          <h3 data-caltype={"outlook-app"}>{i18n.calendars.outlookApp}</h3>
        </a>
          {isRemoveBkg && 
            <Tooltip style={{textAlign: textAlign}} title={i18n.calendars.outlookInfo}>
          <QuestionIcon size={16} style={qstyle}/>
            </Tooltip>}
          {isUpdateBkg && 
            <Tooltip style={{textAlign: textAlign}} title={i18n.calendars.outlookInfoUpdate}>
          <QuestionIcon size={16} style={qstyle}/>
          </Tooltip>}
      </li>
    ];
  }

  renderOutlookOnline() {
    const i18n = Context.getInstance().i18n;

    return (
      // @ts-ignore
      <li key={CALENDAR_TYPES.outlookOnline}>
        <button 
          data-caltype={"outlook-online"}
          onClick={this.onClick}
        >
          <img 
            data-caltype={"outlook-online"}
            className={CSS.logoOutlook} 
            src="assets/Outlook_64x64.svg"
            alt={i18n.general.image} 
          />
          <h3 data-caltype={"outlook-online"}>{i18n.calendars.outlookOnline}</h3>
          <WebIcon />
        </button>
      </li>
    );
  }

  renderDownload(isRecurringBooking: boolean) {
    const i18n = Context.getInstance().i18n;
    const infos = isRecurringBooking ? [this.icsInfoOccurrence, this.icsInfoSeries] : [this.icsInfo];
    if (infos.every(i => !i?.href)) {
      return [];
    }
    const isAddBkg = !this.props.updateBooking && !this.props.removeBooking;
    return infos.filter(info => !!info).map(info => {
      let str = i18n.calendars.generic;
      if (isAddBkg && this.props.bookingType == null && (info.type === "series" || info.type === "occurrence")) {
        str = info.type === "occurrence" ? i18n.calendars.downloadThisOccurance : i18n.calendars.downloadEntireSeries;
      }
      if (isRecurringBooking && isAddBkg && info.type === "occurrence") {
        return null
      }

      return (
        <li key={info.type} style={{marginInlineEnd:'auto'}}>
          <a 
            className={CSS.genericButton}
            onClick={this.onClick}
            href={info.href} 
            role="button"
            download={info.filename} 
            rel="noopener noreferrer" 
            target="_blank"
          >
            <DownloadToIcon data-caltype={info.type} />
            <h3 data-caltype={info.type}>{str}</h3>
          </a>
        </li>
      );
    });
  }
}