import BaseVM from "../support/BaseVM";
import TransactionGuard from "../../../base/TransactionGuard";
import { ISymbolParams } from "../support/MapSelection";
import { editFeatures } from "../../../base/transaction/transactions";
import { IFeatureItem } from "../copypaste/featureItemUtil";
import { LayerType } from "../../../../util/interfaces";

export default class DeleteVM extends BaseVM {
  symbolParams: ISymbolParams

  constructor() {
    super();
    const lineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 2, color: [0, 255, 255, 1] };
    const activeLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [0, 255, 255, 1] };
    const hoverLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [70, 130, 180, 1] };
    const fillSymbol: __esri.SimpleFillSymbolProperties = { style: "solid", color: [0, 255, 255, .25], outline: { ...lineSymbol } };
    const activeFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [0, 255, 255, .75], outline: { ...lineSymbol } };
    const hoverFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [70, 130, 180, .75], outline: { ...lineSymbol } };
    
    this.symbolParams = { lineSymbol, fillSymbol, activeLineSymbol, activeFillSymbol };
  }

  override destroy() {
    this.reset();
    super.destroy();
  }

  reset() {
    this.clearHandles();
  }
  save(deletes: IFeatureItem[]) {
    const guard = new TransactionGuard({ force: true });
    const deletesByType = deletes.reduce((acc, curr) => {
      if (acc.has(curr.layerType)) {
        acc.get(curr.layerType).push(curr);
      } else {
        acc.set(curr.layerType, [curr]);
      }
      return acc;
    }, new Map<LayerType, IFeatureItem[]>());
    return editFeatures([...deletesByType.entries()].map(([type, items]) => ({
      type,
      deletes: items.map(i => i.graphic)
    }))).catch(e => {
      console.error("Error updating feature(s)", e);
      throw e;
    }).finally(() => guard.close());
  }
}