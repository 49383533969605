// Framework and third-party non-ui
import React, { Component } from "react";
// Redux operations and local helpers/utils/modules
import Topic from "../../../context/Topic";
import Context from "../../../context/Context";
// Component specific modules (Component-styled, etc.)
import { EmptyContainer } from "../../styles/commentsStyles";
// App components
import Comment from "./Comment";
import InputComment from "./InputComment";
import * as CrudComment from "../../base/transaction/comment";
import * as CrudReply from "../../base/transaction/reply";
import * as commentReplyUtil from "./commentReplyUtil";
import * as component from "../../../components/util/component";
import * as SketchViewModelUtility from "./SketchViewModelUtility";
// Third-party components (buttons, icons, etc.)
import { ContentWrapper } from "../../styles/Work/workBar";
import { Panel } from "../../styles/Common/panel";
import { Container, TitleRow, Title } from "../../styles/Common/listHeader";
import Button from "../../../common/components/Button";
import Icons from "./Icons";
import OfficePlan from "../../base/OfficePlan";
import PlusIcon from "calcite-ui-icons-react/PlusIcon";

export default class CommentsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      comments : [],
      totalCount : null,
      inputComment: false
    });
  }

  addComment=()=> {
    Comment.cancelSketch();
    this.setState({
      inputComment: true
    })
  }

  closeInputComment=()=>{
    this.setState({
      inputComment: false
    })
  }

  componentDidMount(){
    this.loadComments();
    component.own(this,[
      Topic.subscribe(Topic.LoadComments,() => {
        this.loadComments();
      }),
      Topic.publish(Topic.CloseItemPopup, {})
    ]);
  }

  componentWillUnmount() {
    Topic.publish(Topic.CloseItemPopup, {})
    const activeSketchHandle = Comment.getActiveSketchHandle();
    if(activeSketchHandle) {
      SketchViewModelUtility.cancelSketch(activeSketchHandle);
    }
    component.componentWillUnmount(this);
  }

  loadComments=()=>{
    const planId = Context.instance.spaceplanner.planId;
    const url = commentReplyUtil.getCommentUrl();
    const commentIds = [], comments = [], replies = [];
    CrudComment.showAllComments(planId, url).then((result)=>{
      let temp = [];
      const createdDate = commentReplyUtil.getAuthorDateField();
      const createdUser = commentReplyUtil.getAuthorNameField();
      const objectid = commentReplyUtil.getObjectIdComment();
      const comment = commentReplyUtil.getCommentFieldName();
      for(let i=0;i<result.features.length;i++){
        commentIds.push(result.features[i].attributes[objectid]);
        temp.push({
          comment : result.features[i].attributes[comment],
          id : result.features[i].attributes[objectid],
          createdDate : result.features[i].attributes[createdDate],
          geometry : result.features[i].geometry,
          created_user : result.features[i].attributes[createdUser],
          feature: result.features[i]
        });
      }
      comments.push(temp);
    })
    .then(()=> {
      if(commentIds && commentIds.length > 0) {
          return CrudReply.showAllReplies(commentIds, url).then((allReplies)=>{
            replies.push(allReplies);
        })
      }
    })
    .then(()=> {
      this.setState({
        comments: comments[0],
        totalCount: comments[0].length,
        replies: replies
      })
      const lyr = OfficePlan.getActive().commentsLayer;
      if(lyr) lyr.refresh();
    })
    .catch(error => {
      console.error(error);
      Topic.publishErrorAccessingData();
    })
  }

  render() {
    const i18n = Context.instance.i18n;
    let totalCount;
    let caption = i18n.spaceplanner.comments.panelCaption;
    totalCount = this.state.totalCount || '0';
    caption = caption.replace("{count}", totalCount);
    return (
      <Panel>
        <Container>
          <TitleRow>
            <Title>{caption}</Title>
            <Button type="button" onClick={this.addComment}>
            <PlusIcon size={16}></PlusIcon> {i18n.spaceplanner.comments.general.add} </Button>
          </TitleRow>
        </Container>
        {this.state.inputComment &&
        <Container style={{flexShrink:0}}>
          <TitleRow>
            <InputComment closeInputComment={this.closeInputComment}/>
          </TitleRow>
        </Container>}
        <ContentWrapper>
          {this.renderComments()}
        </ContentWrapper>
      </Panel>
    );
  }

  renderComments(){
    const commentObjs = this.state.comments;
    const allRepliesObjs = this.state.replies;
    return commentObjs.length > 0 ?
    commentObjs.map((comment) => {
      return (<Comment key={comment.id} commentObj={comment} replyObj={allRepliesObjs}/>)
    }) : (<EmptyContainer>{Icons.commentsIcon()}</EmptyContainer>);
  }
}
