import styled from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import { CalciteH3 } from "calcite-react/Elements";

const AssignmentsWrapper = styled.div`
  margin: ${props => unitCalc(props.theme.baseline, 2, '/')} 0 0;
  flex-grow: 1;
`;

const AssignmentsTitle = styled(CalciteH3)`
  font-size: 1rem;
  margin-bottom: 0;
`;

const AssignmentsContent = styled.div`
  flex-grow: 1;
  max-height: 10rem;
  overflow-y: auto;
`;

const PromptText = styled.p`
  font-size: 0.833rem;
  font-weight: 400;
  color: #6a6a6a;
`;

export { AssignmentsWrapper, AssignmentsTitle, AssignmentsContent, PromptText };
