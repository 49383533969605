import React from "react";

import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import {ModalController} from "../../../common/components/Modal";
import SaveAs from "./SaveAs";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

export default class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.saveAsClicked = this.saveAsClicked.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const user = Context.instance.user;
    const appItem = Context.instance.configuration.appItem;
    const saveDisabled =  !Context.instance.views.mapView; // pass in props?;
    const canSave = user.canEditPortalItem(appItem);
    const canSaveAs = user.canCreateAppItem(appItem);
    let saveButton = null, saveAsButton = null;
    // className={"i-button i-button-clear"}
    if (canSave) {
      saveButton = (
        <Button key="s" className="i-margin-lr"
          disabled={saveDisabled} onClick={this.saveClicked}
          >{i18n.configurator.save}</Button>
      );
    }
    if (canSaveAs) {
      saveAsButton = (
        <Button key="sa" className="i-margin-lr" clear={canSave}
          disabled={saveDisabled} onClick={this.saveAsClicked}
          >{i18n.configurator.saveAs}</Button>
      );
    }

    return (
      <div key="actions" className="i-configurator-actions i-text-right i-margin-tb">
        {saveAsButton}
        {saveButton}
      </div>
    );
  }

  saveAsClicked() {
    const i18n = Context.instance.i18n;
    SaveAs.showModal({
      cfg: this.cfg,
      onSaveCliked: (metaInfo,folderId,sharingInfo,modalController) => {
        const configuration = Context.instance.configuration;
        configuration.saveAppItem(metaInfo,folderId,sharingInfo,true).then(response => {
          //console.log("Configurator.saveAsClicked.response",response);
          //component.refresh(this);
          this.cfg.initialConfigurables = configuration.extractConfigurables();
          modalController.close();
        }).catch(ex => {
          modalController.close();
          ModalController.showMessage(i18n.messages.errorSavingItem,
            metaInfo.title,"i-configurator-modal");
          console.error("Error saving item",ex);
        });

        // // *** can't get the ownerFolder from a search
        // const title = metaInfo.title;
        // const owner = Context.instance.user.getUsername();
        // portalUtil.checkForExistingTitle(title,owner,folderId).then(response => {
        //   console.log("Configurator.checkForExistingTitle.response",response);
        //   //return configuration.saveAppItem(metaInfo,folderId,true);
        // }).then((response) => {
        //   console.log("Configurator.saveAsClicked.response",response);
        //   component.refresh(this);
        //   modalController.close();
        // }).catch(ex => {
        //   // TODO show error
        //   console.error("Error saving item",ex);
        //   modalController.close();
        // });
      }
    });
  }

  saveClicked() {
    const metaInfo  = {};
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    const appItem = configuration.appItem;
    const itemId = appItem && appItem.id;
    if (!itemId) return;

    configuration.saveAppItem(metaInfo).then(response => {
      //console.log("Configurator.saveClicked.response",response);
      this.props.cfg.initialConfigurables = configuration.extractConfigurables();
      component.refresh(this);
      Topic.publish(Topic.ShowToast,{
        message: i18n.configurator.configurationSaved
      });

      // Context.instance.uiMode.toggleConfigurator(false);
      // Topic.publish(Topic.ConfiguratorClosed,{});
      // configUtil.removeEditFromUrl();
      Topic.publish(Topic.ShowBackstage,{
        forceRefresh: true,
        tabKey: "newPlan"
      });

    }).catch(ex => {
      ModalController.showMessage(i18n.messages.errorSavingItem,
        appItem.title,"i-configurator-modal");
      console.error("Error saving item",ex);
    });
  }

}
