import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as queryUtil from "../../base/queryUtil";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as sourceUtil from "../../base/sourceUtil";

export default class MoveOccupantsModel extends BaseClass {

  activeSourceKey;
  expanded;
  view;

  _activePromise;
  _activeSketchViewModel;
  _peopleGfxLayerId = "indoors-moveOccupants-people";

  // constructor(props) {
  //   super(props);
  // }

  activate(unitFeatureItem,peopleAssigned,personOid) {
    const task = {
      unitFeatureItem: unitFeatureItem,
      peopleAssigned: peopleAssigned
    }
    this.view = Context.instance.views.mapView;
    if (peopleAssigned && peopleAssigned.length > 0) {
      let item;
      if (typeof personOid === "number") {
        item = this.findPersonFeatureItem(peopleAssigned,personOid);
      }
      if (!item) item = peopleAssigned[0];
      task.personFeatureItem = item;
      this.activateOccupant(task);
      return item;
    } else {
      this.clearGraphics();
      this.cancelSketch();
    }
  }

  activateOccupant(task) {
    const {peopleAssigned,personFeatureItem} = task;
    const activeOid = personFeatureItem && personFeatureItem.objectId;
    task.graphic = this.drawGraphics(peopleAssigned,activeOid);
    if (task.graphic) {
      this.activateSketch(task)
    } else {
      this.cancelSketch();
    }
  }

  activateSketch(task) {
    const {unitFeatureItem,personFeatureItem,graphic} = task;
    this.cancelSketch();
    if (!graphic) return;

    const makeDndEvent = (view, toolEventInfo) => {
      const mapPoint = toolEventInfo.mover.geometry;
      const screenPoint = view.toScreen(mapPoint)
      return {
        mapPoint: mapPoint,
        screenPoint: screenPoint,
        useScreenPoint: true
      }
    }

    const endDnd = () => {
      Topic.publish(Topic.DragItemEnd, {
        sourceKey: sourceUtil.getPeopleSourceKey(),
        featureItem: personFeatureItem,
        isMoveOccupantPoint: true
      });
    }

    const lib = Context.instance.lib;
    const lyr = this.ensureGraphicsLayer(this.view,this._peopleGfxLayerId);
    const view = this.view;
    let svm = this._activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: lyr,
      updateOnGraphicClick: false
    });

    let wasStarted = false, wasStopped = false;
    svm.on("update",event => {
      graphic.xtnState = event.state;
      const toolEventInfo = event.toolEventInfo;
      const toolType = toolEventInfo && toolEventInfo.type;
      const dnd = Context.instance.spaceplanner.dnd;
      if (toolType === "move-start") {
        wasStarted = true;
        wasStopped = false;
        const f = unitFeatureItem.feature;
        Topic.publish(Topic.DragItemStart, {
          sourceKey: sourceUtil.getPeopleSourceKey(),
          featureItem: personFeatureItem,
          isMoveOccupantPoint: true,
          fromUnitOid: f.attributes[sourceUtil.getUnitsLayer().objectIdField],
          onConfirmingDrop: (di) => {
            wasStopped = true;
            endDnd()
          },
          onEditsApplied: (di) => {
            // n/a
          },
          onInvalidDrop: (di) => {
            wasStopped = true;
            endDnd()
            this.activateOccupant(task) // reset
          }
        });
      } else if (toolType === "move") {
        const dndEvent = makeDndEvent(view,toolEventInfo)
        dnd.whenDragOverMapContainer(dndEvent);
      } else if (toolType === "move-stop") {
        wasStopped = true;
        const dndEvent = makeDndEvent(view,toolEventInfo)
        dnd.whenDropOntoMapContainer(dndEvent);
      }

      // event.state: start active complete
      if (event.state === "complete") {
        //console.log("updateComplete...","toolType",toolType,"aborted",event.aborted)
        if (wasStarted && !wasStopped) endDnd();
        if (!wasStarted && !event.aborted) {
          //console.log("      updateComplete reactivating occupant")
          this.activateOccupant(task) // reset
        }
      }
    });

    svm.update([graphic], {
      tool: "move",
      toggleToolOnClick: false,
      enableZ: false
    });
  }

  cancelSketch() {
    const svm = this._activeSketchViewModel;
    if (svm) {
      svm.cancel();
      svm.destroy();
      this._activeSketchViewModel = null;
    }
  }

  clearGraphics() {
    const lyr = this.getGraphicsLayer(this.view,this._peopleGfxLayerId);
    if (lyr) lyr.graphics.removeAll()
  }

  deactivate() {
    this.cancelSketch();
    this.clearGraphics();
  }

  drawGraphics(peopleAssigned,activeOid) {
    const lyr = this.ensureGraphicsLayer(this.view,this._peopleGfxLayerId);
    let activeGraphic, gfx = [];
    if (lyr) {
      const chkActive = (typeof activeOid === "number");
      if (peopleAssigned && peopleAssigned.length > 0) {
        peopleAssigned.forEach(item => {
          const isActive = (chkActive && activeOid === item.objectId)
          const graphic = this.makeGraphic(item,isActive);
          if (graphic) {
            if (isActive) {
              activeGraphic = graphic;
            } else {
              gfx.push(graphic);
            }
          }
        })
      }
      if (activeGraphic) gfx.push(activeGraphic);
      lyr.graphics.removeAll();
      if (gfx.length > 0) lyr.graphics.addMany(gfx);
    }
    return activeGraphic;
  }

  ensureGraphicsLayer(view,layerId) {
    let layer = view.map.findLayerById(layerId);
    if (!layer) {
      const lib = Context.instance.lib;
      layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: Context.instance.config.graphicElevationOffset,
        unit: "meters"
      };
      view.map.add(layer);
    }
    return layer;
  }

  findClosest(peopleAssigned,mapPoint) {
    let d, personFeatureItem;
    if (mapPoint && peopleAssigned && peopleAssigned.length > 0) {
      const x = mapPoint.x, y = mapPoint.y;
      if (typeof x === "number" && typeof y === "number") {
        peopleAssigned.forEach(item => {
          const g = item.feature.geometry;
          if (g) {
            const x2 = g.x, y2 = g.y;
            if (typeof x2 === "number" && typeof y2 === "number") {
              const a = Math.abs(x2 - x);
              const b = Math.abs(y2 - y);
              const c = Math.sqrt(Math.pow(a,2) + Math.pow(b,2));
              if ((d === undefined) || (c < d)) {
                d = c;
                personFeatureItem = item;
              }
            }
          }
        })
      }
    }
    return personFeatureItem;
  }

  findPersonFeatureItem(peopleAssigned,oid) {
    let personFeatureItem;
    if (peopleAssigned && peopleAssigned.length > 0 && typeof oid === "number") {
      peopleAssigned.some(item => {
        if (item.objectId === oid) {
          personFeatureItem = item;
        }
        return !!personFeatureItem;
      })
    }
    return personFeatureItem;
  }

  getGraphicsLayer(view,layerId) {
    return view && view.map.findLayerById(layerId);
  }

  makeGraphic(personFeatureItem,active) {
    let graphic, f = personFeatureItem.feature;
    const lib = Context.instance.lib;
    const symbol = this.makeSymbol(active)
    try {
      if (f && f.geometry) {
        const gu = Context.instance.lib.esri.geometryJsonUtils;
        let geom;
        if (typeof f.geometry.clone === "function") {
          geom = f.geometry.clone()
        } else {
          geom = gu.fromJSON(f.geometry);
        }
        graphic = new lib.esri.Graphic({
          attributes: {name: "moveOccupant", oid: personFeatureItem.objectId},
          geometry: geom,
          symbol: symbol
        });
        if (active) graphic.xtnActive = true;
      } else {
        console.log("No geometry for:",personFeatureItem)
      }
    } catch(ex) {
      console.error("Error creating highlight graphic",ex);
    }
    return graphic;
  }

  makeSymbol(active) {
    let symbol;
    if (active) {
      symbol = {
        type: "simple-marker",
        color: [0, 255, 255],
        size: "16px",
        outline: {
          color: [76, 76, 76],
          width: 1
        }
      };
    } else {
      symbol = {
        type: "simple-marker",
        color: [255, 255, 255, 0],
        size: "16px",
        outline: {
          color: [76, 76, 76, 0.5],
          width: 1
        }
      };
    }
    return symbol;
  }

  async refreshData(unitFeatureItem,peopleAssigned) {
    const result = {
      unitFeatureItem: null,
      peopleAssigned: null
    }
    let f = await officePlanUtil.queryUnitByObjectId(unitFeatureItem.objectId)
    if (f) {
      const asnType = aiimUtil.getAttributeValue(f.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
      if (asnType === OfficePlan.SpaceAssignmentTypes.office) {
        result.peopleAssigned = await officePlanUtil.queryPeopleAssignedToUnit(unitFeatureItem);
        result.unitFeatureItem = queryUtil.makeFeatureItem(sourceUtil.getUnitsSource(),f);
      }
    }
    return result;
  }

}
