import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import Context from "../../../../context/Context";
import FileBrowser from "./FileBrowser";
import MediaItem from "./MediaItem";
import MediaPanelVM from "./MediaPanelVM";
import Topic from "../../../../context/Topic";
import * as component from "../../../../components/util/component";
import * as mediaUtil from "./mediaUtil";

import { HelpTip, getActiveHelpTip, setHelpTip } from "../redux";
import { IRootState } from "../../../../redux/Rdx";
import { IFileInfo, IMediaItem } from "../../../miniapps/common/types";

import "@esri/calcite-components/dist/components/calcite-list";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import {
  CalciteList,
  CalciteNotice,
  CalcitePanel
} from "@esri/calcite-components-react";


interface Props {
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}

interface State {
  activeMediaItem?: IMediaItem,
  canEditMediaLayers: boolean,
  disabled: boolean,
  mediaItems: IMediaItem[]
}

class MediaPanel extends React.Component<Props, State> {

  requiresSaveTS: number = 0;
  saving: boolean;
  viewModel: MediaPanelVM;
  private _mounted = false;

  constructor(props) {
    super(props);

    this.viewModel = new MediaPanelVM();
    this.viewModel.onGeoreferenceChange = this.onGeoreferenceChange;

    this.state = component.newState({
      activeMediaItem: null,
      canEditMediaLayers: Context.instance.user.canEditMediaLayers(),
      mediaItems: [],
      disabled: false
    })
  }

  componentDidMount() {
    this._mounted = true;
    this.initItems();
    const view = this.viewModel.getView();

    component.own(this, [
      Topic.subscribe(Topic.ViewsReloaded, params => {
        if (this._mounted) this.initItems();
      })
    ]);
    if (view) {
      component.own([
        view.on("click", event => {
          if (!this._mounted || event.button !== 0) {
            return;
          }
          this.handleViewClick({event});
        })
      ]);
    }

  }

  componentWillUnmount(): void {
    this.viewModel.cancelSketch();
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  autoSave = async (force?: boolean) => {
    const { canEditMediaLayers } = this.state;
    if (!canEditMediaLayers) return;
    if (this.saving && !force) {
      this.requiresSaveTS = Date.now();
      return;
    }
    try {
      this.saving = true;
      this.requiresSaveTS = 0;
      const timestamp = Date.now();
      await this.viewModel.save(this.state.mediaItems);
      setTimeout(() => {
        if (this.requiresSaveTS > timestamp) {
          this.autoSave(true);
        } else {
          this.saving = false;
        }
      },2000);
    } catch(ex) {
      this.saving = false;
      console.error(ex);
    }
  }

  handleViewClick = async (clickParams) => {
    const mediaItem = await mediaUtil.hitTest(clickParams,this.state.mediaItems);
    if (this._mounted && (mediaItem !== this.state.activeMediaItem)) {
      this.onSelectItem(mediaItem);
    }
  }

  initItems = () => {
    this.setState({
      activeMediaItem: null,
      mediaItems: mediaUtil.getMediaItemsFromMap()
    })
  }

  isActive = (mediaItem: IMediaItem) => {
    return !!(mediaItem && (mediaItem === this.state.activeMediaItem));
  }

  onAspectRatioChange = (mediaItem: IMediaItem) => {
    const { canEditMediaLayers } = this.state;
    if (this.isActive(mediaItem) && canEditMediaLayers) {
      this.viewModel.activateSketch(mediaItem);
    }
  }

  onGeoreferenceChange = (mediaItem: IMediaItem) => {
    this.autoSave();
  }

  onOpacityChange = (mediaItem: IMediaItem) => {
    this.autoSave();
  }

  onRemoveItem = async (mediaItem: IMediaItem) => {
    const { canEditMediaLayers } = this.state;
    if (!canEditMediaLayers) return;
    try {
      this.setState({disabled: true})
      mediaItem.removed = true;
      if (this.isActive(mediaItem)) {
        if (mediaItem === this.state.activeMediaItem) {
          this.setState({
            activeMediaItem: null
          })
          this.viewModel.cancelSketch();
        }
      }
      Context.instance.views.activeView.map.remove(mediaItem.mediaLayer);
      await this.viewModel.removeResource(mediaItem);
      await this.viewModel.save(this.state.mediaItems);
      this.setState({disabled: false})
    } catch(ex) {
      console.error(ex);
      this.setState({disabled: false});
      Topic.publishErrorUpdatingData(ex.message);
    }
  }

  onSelectFile = async (fileInfo: IFileInfo) => {
    const { canEditMediaLayers } = this.state;
    if (!canEditMediaLayers) return;
    try {
      this.setState({disabled: true})
      this.viewModel.cancelSketch();
      const mediaItem = await mediaUtil.addFileToMap(fileInfo);
      const mediaItems = this.state.mediaItems;
      mediaItems.unshift(mediaItem);
      await this.viewModel.addResource(mediaItem);
      await this.viewModel.save(mediaItems);
      this.viewModel.activateSketch(mediaItem);
      this.setState({
        activeMediaItem: mediaItem,
        mediaItems: mediaItems.slice(),
        disabled: false
      })
    } catch(ex) {
      console.error(ex);
      this.setState({disabled: false});
      Topic.publishErrorUpdatingData(ex.message);
    }
  }

  onSelectItem = (mediaItem: IMediaItem) => {
    const { canEditMediaLayers } = this.state;
    this.setState({
      activeMediaItem: mediaItem
    })
    if (mediaItem && canEditMediaLayers) {
      this.viewModel.activateSketch(mediaItem);
    } else {
      this.viewModel.cancelSketch();
      if (mediaItem) this.viewModel.highlightItem(mediaItem);
    }
  }

  onVisibilityChange = (mediaItem: IMediaItem) => {
    this.autoSave();
  }

  render() {
    const { disabled } = this.state;
    return (
      <div className="i-editor-sidebar-container i-editor-split-container">
        <div className="i-editor-sidebar-content">
          <CalcitePanel disabled={disabled || undefined}>
            {this.renderInstruction()}
            {this.renderFileBrowser()}
            {this.renderItems()}
          </CalcitePanel>
        </div>
      </div>
    )
  }

  renderFileBrowser() {
    const { canEditMediaLayers } = this.state;
    if (canEditMediaLayers) {
      return (
        <FileBrowser onSelect={this.onSelectFile} />
      )
    }
    return null
  }

  renderInstruction() {
    const i18n = Context.instance.i18n;
    const { tip } = this.props;
    const { canEditMediaLayers } = this.state;
    const instruction = canEditMediaLayers ? i18n.editor.media.instruction : i18n.editor.media.instruction2;
    return (
      <CalciteNotice
        closable 
        icon="lightbulb" 
        scale="s" 
        open={!(tip && tip.visible) ? true : undefined} 
        onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: "" })}
      >
        <div slot="message">{instruction}</div>
      </CalciteNotice>
    )
  }

  renderItems() {
    const { activeMediaItem, canEditMediaLayers, mediaItems } = this.state;
    const items = mediaItems.map(mediaItem => {
      const selected = (activeMediaItem === mediaItem);
      if (!mediaItem.removed) {
        return (
          <MediaItem 
            key={mediaItem.mediaLayer.id} 
            mediaItem={mediaItem}
            canEditMediaLayers={canEditMediaLayers}
            selected={selected}
            onAspectRatioChange={this.onAspectRatioChange}
            onOpacityChange={this.onOpacityChange}
            onRemove={this.onRemoveItem}
            onSelect={this.onSelectItem}
            onVisibilityChange={this.onVisibilityChange}
          />
        )
      }
    })
    return (
      <CalciteList 
        className="i-media-items"
        selectionMode="single" 
        selectionAppearance="border"
      >
        {items}
      </CalciteList>      
    )
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("media", tip))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaPanel);
