
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as sourceUtil from "../../base/sourceUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Context from "../../../context/Context";

export function getAllTypes(layer: __esri.FeatureLayer): { value: string | number, name: string }[] {
  const list = [];
  const allFields = layer && layer.fields;
  if (!allFields) return list;    
  const fldUseType = aiimUtil.findField(allFields, FieldNames.DETAILS_USE_TYPE);
  if (!fldUseType) return list;
  const typeIdField = layer.typeIdField;
  if (typeIdField && (typeIdField.toLowerCase() === fldUseType.name.toLowerCase())) {
    const types = layer.types;
    for (let i=0;i<types.length;i++) {
      list.push({
        value: types[i].id,
        name: types[i].name
      })
    }
  }
  return list;
}

export function filterTypes(allTypes, values) {
  const list = [];
  if (allTypes) {
    allTypes.forEach((type)=> {
      if (values && values.length > 0) {
        values.some((value)=> {
          if(type.value === value) {
            list.push(type);
            return true;
          }
          return false;
        })
      } else {
        list.push(type);
      }
    }) 
  }
  return list;
}

export function getWallTypes(): string[] {
  const detailsLayer = sourceUtil.getDetailsLayer();
  const wallTypes = Context.instance.config.spaceplanner.wallTypeValues;
  const allTypes = getAllTypes(detailsLayer);
  const types = filterTypes(allTypes, wallTypes);
  if (types && types.length > 0) return types;
  return null;
}

export function validateWallTypes() {
  const detailsLayer = sourceUtil.getDetailsLayer();
  const allTypes = getAllTypes(detailsLayer);
  const wallTypeValues = Context.instance.config.spaceplanner.wallTypeValues;
  let list = [];
  if (allTypes && wallTypeValues) {
    allTypes.forEach((type) => {
      if(wallTypeValues.includes(type.value)) {
        list.push(type.value);
      }
    })
  }
  Context.instance.config.spaceplanner.wallTypeValues = list;
}
