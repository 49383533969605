import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';
import {connect} from "react-redux";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../../../redux/Rdx";

// 3rd party
import Loader from "calcite-react/Loader";
import SearchIcon from "calcite-ui-icons-react/SearchIcon";
import SortAscIcon from 'calcite-ui-icons-react/SortAscendingArrowIcon';
import SortDescIcon from 'calcite-ui-icons-react/SortDescendingArrowIcon';
import UserIcon from 'calcite-ui-icons-react/UserIcon';
import XIcon from "calcite-ui-icons-react/XIcon";
import PencilIcon from "calcite-ui-icons-react/PencilIcon";
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';
import RecurrenceIcon from "calcite-ui-icons-react/RecurrenceIcon";

// aiim.base
import ItemReference from '../../../../../aiim/base/ItemReference';

// aiim.datasets
import FieldNames from '../../../../../aiim/datasets/FieldNames';

// aiim.util
import { getAttributeValue, getRecurrencePattern } from '../../../../../aiim/util/aiimUtil';

// common.components.Modal
import { ModalController }  from "../../../../../../src/common/components/Modal/index";

// components.common.Dropdown
import DropdownButton from '../../../../common/Dropdown/DropdownButton';

// components.main.Actions.More.BookWorkspace.WorkspaceReservation
import { cancelBooking } from "./WorkspaceReservation/OfficeHotelingInterface";
import { formatDate, getHotelBookingSystem, getHotelBookingSystemType, makeWhere } from './WorkspaceReservation/workspaceReservationUtil';

// components.util
import * as component from '../../../../util/component';
import * as checkInOutUtil from "./CheckInOutUtil";
import * as workspaceReservationUtil from "./WorkspaceReservation/workspaceReservationUtil";
import * as dateUtil from '../../../Events/dateUtil';

// context
import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';

// util
import { stringFormatter } from '../../../../../util/formatUtil';
import Icons from '../../../../util/Icons';
import { isNetworkError } from '../../../../../util/networkUtil';
import CheckIn from './CheckIn';
import CheckOut from "./CheckOut";
import BookingConfirmed from './BookingConfirmed';
import BookingCancel from './BookingCancel';
import RecurrenceDropdown from './RecurrenceDropdown';
import { IBookingData, IBookingsProps, IBookingsState } from './MyBookings';
import { IBookingTask } from './WorkspaceReservation/BookingSystem';
import { IRecurrenceOptions, IRecurrenceSeries } from './BookingRecurrence';
import { BOOKING_STATE } from './WorkspaceReservation/EsriReservationSchema';
import { BookingType } from '../../../../../util/calendarUtil';
import { ICheckAvailabilityOptions } from './BookingDetails';
import { findDuplicateRecords } from '../../../../../util/multipleAssignmentsUtil';

const CSS = {
  main: "i-other-bookings",
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  locationDescription: "i-booking-location-description",
  button: "i-book-button",
  buttonCheckIn: "i-book-card-button-checkIn",
  buttonCheckOut: "i-book-card-button-checkOut",
  actionGroup: "i-action-group",
  actionSubGroup: "i-action-subgroup",
  addToCalendar: "i-booking-add-calendar",
  cancel: "i-booking-cancel",
  edit: "i-booking-edit",
  descriptionContent: "i-booking-description",
  checkedIn: "i-checked-in",
  peopleLabel: "i-people-label",
  search: "i-search-input",
  filterSort: "i-filter-sort",
  bookingInfoLine: "i-booking-info-line"
};

const MAX_USERS_LABEL = 2;

const APPROVED = 1;
const CHECKED_IN = 4;
const CANCELED = 3;
const CHECKED_OUT = 5;

interface IOtherBookingsState extends IBookingsState { 
  searchTerm: string,
  sortBy: string,
  sortDir: "asc" | "desc"
}
class OtherBookings extends React.Component<IBookingsProps & IRdxProps, IOtherBookingsState> {

  listReminders = {
    checkIn: [],
    checkOut: []
  };

  where;

  remindersShown = {
    checkIn: false,
    checkOut: false
  };

  constructor(props) {
    super(props);

    this.state = component.newState({
      bookings: [],
      isWorking: true,
      sortDir: "asc",
      sortBy: "date",
      searchTerm: null,
      checkedIn: false,
      checkedOut: false
    });
  }

  componentDidMount() {
    this._getUserReservations();
    component.own(this, [
      Topic.subscribe(Topic.RenderBookingList, async(info) => {
        this.setState({isWorking: true})
        await this._getUserReservations();
      })
    ])
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingDateFilter !== this.props.bookingDateFilter) {
      this.setState({isWorking: true});
      this._getUserReservations();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION,'any');
  }

  _getUserReservations = async () => {
    checkInOutUtil.getUserReservations("otherbookings", this.props.bookingDateFilter)
    .then((res)=> {
      this.setState({
        bookings : res && res.bookings,
        isWorking: res && res.isWorking,
        series: res?.series
      })
    })
    .catch(error => {
      console.error(error);
    })
  }

  sortSearchClusters = (bookings: IClusteredBookings[]) => {
    const sorted = this.sortClusters(bookings);
    return this.searchClusters(sorted);
  }

  sortClusters = (clusters: IClusteredBookings[]) => {
    const { sortBy, sortDir } = this.state;
    
    const applyDir = (i) => {
      return sortDir === "desc" ? i - (i * 2) : i;
    }

    switch (sortBy) {
      case "unitName":
        return clusters.sort((a, b) => {
          const nameA = getAttributeValue(a.unit.attributes, FieldNames.NAME);
          const nameB = getAttributeValue(b.unit.attributes, FieldNames.NAME);
          return applyDir(nameA > nameB ? 1 : nameA < nameB ? -1 : 0);
        });
      case "date":
      default:
        return clusters.sort((a, b) => {
          const startTimeA = moment(a.startTime);
          const startTimeB = moment(b.startTime);
          return applyDir(startTimeA.isAfter(startTimeB) ? 1 : startTimeA.isBefore(startTimeB) ? -1 : 0);
        });
    }
  }

  searchClusters = (clusters: IClusteredBookings[]) => {
    const { searchTerm } = this.state;
    if (!searchTerm || searchTerm.trim() === "") {
      return clusters;
    }

    return clusters.filter((cluster) => {
      const { names, unit } = cluster;
      const unitName = getAttributeValue(unit.attributes, FieldNames.NAME);
      const matchesUnit = unitName && unitName.toLowerCase().includes(searchTerm);
      const matchesNames = names.some((name) => name.toLowerCase().includes(searchTerm));
      return matchesUnit || matchesNames;
    });
  }

  filterCurrentBookings = (bookings: IBookingData[]) => {
    return bookings.filter((reservation) => {
      const attrs = reservation.booking.attributes;
      const endTime = getAttributeValue(attrs, FieldNames.END_TIME);
      const state = getAttributeValue(attrs, FieldNames.STATE);

      const now = moment();
      const mEndTime = moment(endTime);

      if(state === CANCELED || state === CHECKED_OUT) return false;
      return state === CHECKED_IN ? now < mEndTime.endOf("day").add(1, "day") : true;
    });
  }

  /**
   * Cluster Object:
   * startTime: Date,
   * endTime: Date,
   * names: string[],
   * reservations: any[],
   * unit: any
   * 
   * @param {any[]} bookings Booking and unit data
   * @returns Clusters of bookings
   */
  clusterBookings = (bookings: IBookingData[]) => {
    const clusters: IClusteredBookings[] = [];
    bookings.forEach((reservation) => {
      const { booking, occupant, unit } = reservation;
      const startTime = getAttributeValue(booking.attributes, FieldNames.START_TIME);
      const endTime = getAttributeValue(booking.attributes, FieldNames.END_TIME);
      const unitId = getAttributeValue(booking.attributes, FieldNames.UNIT_ID);
      const reservedForFullName = getAttributeValue(booking.attributes, FieldNames.RESERVED_FOR_FULL_NAME);
      
      const existingClusterIndex = this.findMatchingCluster(clusters, { startTime, endTime, unitId });
      if (existingClusterIndex > -1) {
        clusters[existingClusterIndex].names.push(reservedForFullName);
        clusters[existingClusterIndex].reservations.push(booking);
        clusters[existingClusterIndex].occupants.push(occupant);
      } else {
        clusters.push({
          startTime,
          endTime,
          names: [reservedForFullName],
          occupants: [occupant],
          reservations: [booking],
          unit
        });
      }
    });

    return clusters;
  }

  /**
   * Find the matching index of a cluster that is for the same start time,
   * end time and unit
   * 
   * @param {any[]} clusters Current clusters
   * @param {any} data Data to compare with: start time, end time, unit ID
   * @returns Index of identified cluster
   */
  findMatchingCluster = (clusters, data) => {
    const { startTime, endTime, unitId } = data;
    return clusters.findIndex((cluster) => {
      const { startTime: cStartTime, endTime: cEndTime, unit: cUnit } = cluster;
      const cUnitId = getAttributeValue(cUnit.attributes, FieldNames.UNIT_ID);
      return moment(startTime).isSame(cStartTime) && moment(endTime).isSame(cEndTime) && unitId === cUnitId;
    });
  }

  getFirstReservation = (cluster) => {
    const { reservations } = cluster;
    return reservations && reservations.length > 0 && reservations[0];
  }

  makeBookingList = () => {
    const { bookings, series } = this.state;
    const reservations = Context.getInstance().aiim.datasets?.reservations;
    if (!reservations) {
      return [];
    }
    const filteredBookings = this.filterCurrentBookings(bookings);
    const clusters = this.clusterBookings(filteredBookings);
    const sortedClusters = this.sortSearchClusters(clusters);

    /* Disabled
    // Do not show check in prompt when Booked tab is activated #8191
    this.listReminders = checkInOutUtil.getRemindersList(sortedClusters, "otherbookings");
    if (this.listReminders && this.listReminders.checkIn && this.listReminders.checkIn.length > 0) {
      if (!this.remindersShown["checkIn"]) {
        checkInOutUtil.showReminderPopup("checkIn", this.listReminders, "otherbookings");
        this.remindersShown["checkIn"] = true;
      }
    } else if (this.listReminders && this.listReminders.checkOut && this.listReminders.checkOut.length > 0) {
      if (!this.remindersShown["checkOut"]) {
        checkInOutUtil.showReminderPopup("checkOut", this.listReminders, "otherbookings");
        this.remindersShown["checkOut"] = true;
      }
    }
    */

    const objectIdField = reservations && reservations.getObjectIdField();
    const clusteredBookingsList: JSX.Element[] = [];
    sortedClusters.forEach((cluster) => {
      const reservation = this.getFirstReservation(cluster);
      const key = reservation.attributes[objectIdField];
      const state = getAttributeValue(reservation.attributes, FieldNames.STATE);
      const recurrenceId = getAttributeValue(reservation.attributes, FieldNames.RECURRENCE_ID);
      const isRecurring = series?.has(recurrenceId);
      const recurrenceConfig = getRecurrencePattern(reservation.attributes);
      const isOccurrenceModified = recurrenceConfig?.modified === "occurrence";
      const showButton = state === CHECKED_IN ? "checkOut" : state === APPROVED ? "checkIn" : undefined;
      if (state === APPROVED || state === CHECKED_IN) {
        clusteredBookingsList.push(
          <ClusteredBookingsCard
            key={key}
            cluster={cluster}
            showButton={showButton}
            isRecurring={isRecurring}
            isOccurrenceModified={isOccurrenceModified}
            series={series?.get(recurrenceId) }/>);
      }
    });

    return clusteredBookingsList;
  }

  renderBookingCards() {
    const i18n = Context.getInstance().i18n;
    const bookingList = this.makeBookingList();

    return bookingList && bookingList.length > 0 ? (
      <ul className={CSS.bookingCardList}>
        {bookingList}
      </ul>
    ) : (
      <div style={{ padding: '0.75rem 0.75rem' }}>{i18n.more.bookWorkspace.noBookingsFound}</div>
    );
  }

  // Not using search yet, but is working and available for when we do
  renderSearch() {
    const i18n = Context.getInstance().i18n;

    const onInputChange = (e) => {
      const input = e.target;
      if (input.value.trim() === "") {
        this.setState({ searchTerm: null });
      } else if (input.value && this.state.searchTerm !== input.value.toLowerCase().trim()) {
        this.setState({ searchTerm: input.value.toLowerCase().trim() });
      }
    }

    return (
      <div className={CSS.search}>
        <SearchIcon />
        <input
          type="text"
          aria-label={i18n.general.search}
          placeholder={i18n.general.search}
          onInput={onInputChange}
          onPaste={onInputChange}
        />
      </div>
    );
  }

  renderSort = (disabled) => {
    const i18n = Context.instance.i18n;
    const { sortDir, sortBy } = this.state;

    const getSortDirClass = (opt) => {
      let cn = "i-sorting-button";
      if (sortDir === opt) {
        cn += " i--active";
      }
      return cn;
    }

    const getSortIcon = () => {
      if (sortDir === "desc") {
        return <SortDescIcon size={16} />
      } else {
        return <SortAscIcon size={16} />
      }
    }

    const getIcon = (opt) => {
      let chk;
      if (sortBy === opt) {
        chk = (<span key="icon">{Icons.checkMark()}</span>);
      } else {
        chk = (<span key="icon" className="svg-icon i-icon-empty"></span>);
      }
      return chk;
    };

    const setAsc = () => {
      this.setState({ sortDir: "asc" });
    }

    const setDesc = () => {
      this.setState({ sortDir: "desc" });
    }

    const setDate = () => {
      this.setState({ sortBy: "date" });
    }

    const setUnitName = () => {
      this.setState({ sortBy: "unitName" });
    }

    let sortButton = (
      <button className="i-dd-button" disabled={disabled}
        aria-label={i18n.meetingRooms.sortAriaLabel}>
        <div className="i-flex-between-centered">
          {getSortIcon()}
          <span className="i--ml">{i18n.more.bookWorkspace.sort}</span>
        </div>
      </button>
    );

    const sortOptions = [];

    sortOptions.push(
      <li key={"dir"} role="none" className="i-flex-between i-border-bottom">
        <div className="i-sortby">
          <button 
            className={`${getSortDirClass("asc")} i--focusable`}
            title={i18n.more.bookWorkspace.sortOptions.ascending}
            onClick={setAsc}
          >
            {Icons.ascendingSort()}
          </button>
          <button 
            className={`${getSortDirClass("desc")} i--focusable`}
            title={i18n.more.bookWorkspace.sortOptions.descending}
            onClick={setDesc}
          >
            {Icons.descendingSort()}
          </button>
        </div>
      </li>
    );

    sortOptions.push(
      <li key={"date"} role="none" className="i-border-bottom">
        <button 
          type="button" 
          className="i-button-link i-padded i--focusable"
          onClick={setDate}
        >
          {getIcon("date")}
          {i18n.more.bookWorkspace.sortOptions.date}
        </button>
      </li>
    );

    sortOptions.push(
      <li key={"unitName"} role="none" className="i-border-bottom">
        <button   
          type="button" 
          className="i-button-link i-padded i--focusable"
          onClick={setUnitName}
        >
          {getIcon("unitName")}
          {i18n.more.bookWorkspace.sortOptions.unitName}
        </button>
      </li>
    );

    let sortContent = (
      <ul className="i-padded" role="menu" aria-label={i18n.more.bookWorkspace.sortOptions.sort}>
        {sortOptions}
      </ul>
    );

    return (
      <DropdownButton
        closeOnSelect2={true}
        focusFirst={true}
        className={"i-other-bookings-sort"}
        buttonClassName={"i-no-padding i-no-border"}
        buttonContent={sortButton}
        dropdownContent={sortContent}
        disabled={false}
        placement={"bottom-start"}>
      </DropdownButton>
    );
  }

  renderBookings() {
    // const { bookings } = this.state;
    // const list = this.makeBookingList(bookings);
    // const filterSort = list && list.length > 0 ? (
    //   <div className={CSS.filterSort}>
    //     {/* {this.renderSearch()} */}
    //     {/* {this.renderSort()} */}
    //   </div>
    // ) : null;

    return (
      <div className={CSS.main}>
        {/* {filterSort} */}
        {this.renderBookingCards()}
      </div>
    );
  }

  renderWorking() {
    return (<Loader sizeRatio={1} style={{ marginTop: '3rem' }} />);
  }

  render() {
    const { isWorking } = this.state;
    return (isWorking ? this.renderWorking() : this.renderBookings());
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
interface IClusteredBookings {
  endTime: Date,
  names: string[],
  occupants: __esri.Graphic[],
  reservations: __esri.Graphic[],
  startTime: Date,
  unit: __esri.Graphic
}
interface IClusteredBookingsCardProps {
  cluster: IClusteredBookings,
  isRecurring: boolean,
  isOccurrenceModified: boolean
  
  series?: IRecurrenceSeries,
  showButton: "checkIn" | "checkOut"
}
interface IClusteredBookingsCardState {
  hideCheckIn: boolean,
  popoverOpen: boolean,
  popoverType?: "cancel" | "edit"
}
class ClusteredBookingsCard extends React.Component<IClusteredBookingsCardProps, IClusteredBookingsCardState> {
  _componentId;
  _itemRef;

  APPROVED = 1;
  CHECKED_IN = 4;

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this._componentId = component.nextId("booking-");
    this._itemRef = React.createRef();
    this.state = {
      hideCheckIn: true,
      popoverOpen: false
    }
  }

  componentDidMount() {
    this._startUpApp();
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus();
        }
      })
    ]);
  }

  componentWillUnmount(){
    component.componentWillUnmount(this);
  }

  _startUpApp() {
    const { cluster } = this.props;
    const reservation = this._getFirstReservation(cluster);
    const attributes = reservation.attributes;
    const hideCheckIn = checkInOutUtil.hideCheckInButton(attributes);
    this.setState({
      hideCheckIn: hideCheckIn
    })
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  _getFirstReservation = (cluster) => {
    const { reservations } = cluster;
    return reservations && reservations.length > 0 && reservations[0];
  }

  _bookingCardClicked = () => {
    const { cluster } = this.props;
    const unit = cluster.unit;
    const source = Context.instance.aiim.datasets.units.getSource();

    const item = new ItemReference();
    item.fromSearchResult(source.key, { feature: unit, name: source.getTitle(unit) });

    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      componentId: this._componentId
    });
  }

  // Cancel all reservations associated with the booking
  _cancelClicked = async (type?: BookingType) => {
    const { checkInOut: i18n } = Context.getInstance().i18n.more.bookWorkspace;
    const { cluster, isRecurring, series } = this.props;
    const baseReservation = this._getFirstReservation(cluster);
    const bookingSystem = getHotelBookingSystem();

    const baseAttributes = baseReservation.attributes;
    const unitName = getAttributeValue(baseAttributes, FieldNames.UNIT_NAME);
    const reservedForFullName = getAttributeValue(baseAttributes, FieldNames.RESERVED_FOR_FULL_NAME);
    let template = i18n.cancelBooking.messageOthers;
    if (isRecurring) {
      template = i18n.cancelBooking.seriesMessageOthers;
      if (type === "occurrence") template = i18n.cancelBooking.occurrenceMessageOthers;
    }
    const msg = unitName ? stringFormatter(template, { person: reservedForFullName, unit: unitName }) : null;

    const options = {
      title: i18n.cancelBooking.modalTitle,
      message: msg,
      okLabel: i18n.cancelBooking.yes,
      cancelLabel: i18n.cancelBooking.no,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    };

    try {
      const result = await ModalController.confirm(options);
      if (result && result.ok) {
        const bookings = (isRecurring && type!== "occurrence") 
          ? series.occurrences.map(b => b.attributes)
          : cluster.reservations.map(b => b.attributes);

        const cancelResult = await cancelBooking(bookingSystem, bookings);
        if (cancelResult?.data?.updateResults[0]?.error) {
          throw new Error(cancelResult.data.updateResults[0].error);
        } else {
          this.renderCancelConfirmed(reservedForFullName, type);
          Topic.publish(Topic.RenderBookingList, {});
        }
      }
    } catch (error) {
      console.error(error);
      if (error && isNetworkError(error.message)) {
        checkInOutUtil.showServerErrorPopup("cancel", baseAttributes);
      } else {
        Topic.publishErrorUpdatingData();
      }
    }
  }

  private _getRecurrenceIcon(mStartTime: moment.Moment, mEndTime: moment.Moment, isAllDay: boolean, 
    recurrence: IRecurrenceOptions) {    
    const { isRecurring, isOccurrenceModified } = this.props;
    
    if (isRecurring) {
      return isOccurrenceModified
          ? <span>{Icons.noRecurrence()}</span>
          : <span title={workspaceReservationUtil.formatRecurringDates(recurrence, mStartTime, mEndTime, isAllDay)}>
              <RecurrenceIcon/>
            </span>
    }
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;

    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this._bookingCardClicked();
    }
  }

  renderPeople() {
    const i18n = Context.getInstance().i18n;
    const { cluster: { names, occupants, reservations } } = this.props;
    names.sort();

    const displayNames = [];
    for (let i = 0; i < MAX_USERS_LABEL && i < names.length; i++) {
      displayNames.push(names[i]);
    }
    const leftover = names.length - displayNames.length;

    const labelMainTemplate = i18n.more.bookWorkspace.othersNamesLabel;
    const labelMain = stringFormatter(labelMainTemplate, { names: displayNames.join(", "), num: leftover });
    
    const getPerson = (name) => {
      const occupant = occupants.find(o => getAttributeValue(o?.attributes, FieldNames.PEOPLE_FULLNAME) === name);
      if (occupant) {
        const booking = reservations.find(r =>
          getAttributeValue(r.attributes, FieldNames.RESERVED_FOR_FULL_NAME) === getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME));
        return <a href="#" onClick={() => this.showOccupantInfo(occupant, booking)}>{name}</a>;
      } else {
        return name;
      }
    }
    const label = leftover > 0 
      ? (<h4>{labelMain}</h4>)
      : (<h4>{names.map(getPerson)}</h4>);

    return (
      <div className={CSS.peopleLabel}>
        <UserIcon />
        {label}
      </div>
    )
  }
  async showOccupantInfo(occupant: __esri.Graphic, booking: __esri.Graphic) {
    if (occupant) {
      const person = { feature: occupant };
      const ctx = Context.getInstance();
      const people = ctx.aiim.datasets?.people;
      const source = people?.getSource();
      const layer = people?.layer2D;
      try {
        const results = await findDuplicateRecords(layer, person.feature.attributes);
        const features = results?.features;
        Topic.publish(Topic.ShowSearchResult, {
          sourceKey: source?.key ?? "standalone",
          isNonAiimFeature: source == null,
          searchResult: { feature: occupant },
          zoom: features?.length === 1,
          highlight: true,
          trackRecent: true,
          showLocations: { person: { feature: occupant } },
          reservation: booking
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
  _togglePopover = (type?: IClusteredBookingsCardState["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }
  render() {
    const i18n = Context.getInstance().i18n;
    const { cluster , isRecurring, isOccurrenceModified} = this.props;
    const { popoverOpen, popoverType } = this.state;
    const reservation = this._getFirstReservation(cluster);
    const reservations = Context.getInstance().aiim.datasets.reservations;
    const levels = Context.getInstance().aiim.datasets.levels;
    const attributes = reservation.attributes;
    const floorField = reservations.levelIdField;

    // Field values to display
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);

    // Level/Facility info
    const levelId = getAttributeValue(attributes, floorField);
    const levelData = levels.getLevelData(levelId);
    const levelName = levelData.levelName;
    const facilityName = levelData.facilityName;
    const template = i18n.more.bookWorkspace.levelDescription;
    const description = stringFormatter(template, {
      level: levelName,
      facility: facilityName
    });

    // Format a date string from the start and end times
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    const isAllDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
    const formattedDate = formatDate(mStartTime, mEndTime, isAllDay);

    // Recurrence
    const recurrence = getRecurrencePattern(attributes);
    const noRecurrIcon = Icons.noRecurrence();

    // Show the names of the people with the same booking info
    // Also determine if the cancel button should be shown (shouldn't if
    // everyone is already checked-in)
    const allCheckedIn = cluster.reservations.every((reservation) => {
      const attrs = reservation.attributes;
      const state = getAttributeValue(attrs, FieldNames.STATE);
      return state === CHECKED_IN;
    });

    const cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("cancel") : this._cancelClicked()}
      >
        <XIcon />
        {i18n.general.cancel}
      </button>
    );

    const editButton = (
      <button
        className={CSS.edit}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("edit") : this._scheduleClicked()}
      >
      <PencilIcon size={20}/>
      {i18n.more.bookWorkspace.checkInOut.rescheduleBooking.caption}
      </button>
    );

    const hideCheckIn = checkInOutUtil.hideCheckInButton(attributes);

    return (
      <li className={CSS.bookingCard}>
        <div className="i-book-card-container">
          <div
            id={this._componentId}
            className={CSS.descriptionContent}
            ref={this._itemRef}
            role="button"
            tabIndex={0}
            onClick={this._bookingCardClicked}
            onKeyDown={this._onKeyDown}
          >
            <h3 className={CSS.bookingInfoLine}>
              <ClockIcon />
              <span style={{ flexGrow: 1 }}>{formattedDate}</span>
              {this._getRecurrenceIcon(mStartTime, mEndTime,!!isAllDay,recurrence)}
            </h3>
            <h4 className={CSS.bookingInfoLine}>
              <PinIcon />
              {unitName}
            </h4>
            <h4 className={CSS.locationDescription} style={{paddingLeft: "1.3rem", paddingRight: "1.4rem", marginTop: "0.5rem"}}>{description}</h4>
          </div>
          {this.renderPeople()}
          <div className={CSS.actionGroup}>
            {(!hideCheckIn) && (this.props && this.props.showButton === "checkIn") &&
              <CheckIn 
                type="otherbookings"
                bookingType="hotel"
                attributes={attributes}/>}
            {(this.props && this.props.showButton === "checkOut") &&
              <CheckOut 
                type="otherbookings"
                bookingType="hotel"
                attributes={attributes}
              />}
            {<RecurrenceDropdown
              type="edit"
              open={popoverOpen && popoverType === "edit"}
              targetEl={editButton}
              onRequestClose={this._togglePopover}
              onSelection={(type) => {
                this._scheduleClicked(type);
                this._togglePopover();
              }} />
            }
            {this.props.showButton === "checkOut" && <div style={{width:"33.33"}}></div>}
            {allCheckedIn ? null :
              <RecurrenceDropdown
                type="cancel"
                open={popoverOpen && popoverType === "cancel"}
                targetEl={cancelButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._cancelClicked(type);
                  this._togglePopover();
                }} />
            }
          </div>
        </div>
      </li>
    );
  }

  _getWhen =(reservation: __esri.Graphic, series: IRecurrenceSeries)=> {
    const state = getAttributeValue(reservation.attributes, FieldNames.STATE);
    const allDay = getAttributeValue(reservation.attributes, FieldNames.ALL_DAY);
    const when: dateUtil.IDuration = {
      start: { date: series.checkIn },
      end: { date: series.checkOut },
      duration: allDay ? "allDay" : null,
      recurrence: getRecurrencePattern(reservation.attributes)
    };
    let duration = when.duration === "allDay" ? when.duration : dateUtil.determineDurationKey(when);
    const selectedWhen = {
      duration: duration,
      start: { date: when.start.date },
      end: { date: when.end.date },
      isOngoingBooking: state === BOOKING_STATE.CHECKED_IN,
      originalReservation: reservation
    };
    return selectedWhen;
  }

  _scheduleClicked =async(type?: BookingType)=> {
    const { cluster, isRecurring, series } = this.props;
    const reservation = this._getFirstReservation(cluster);
    const { unit } = cluster;
    let details;

    const areaId = getAttributeValue(unit?.attributes, FieldNames.AREA_ID)
    const reservedForUsername = getAttributeValue(reservation.attributes, FieldNames.RESERVED_FOR_USERNAME);
    const reservedForFullname = getAttributeValue(reservation.attributes, FieldNames.RESERVED_FOR_FULL_NAME);
    const reserveForInfo = {
      areaIDs: [areaId],
      email: null,
      occupantFeature: null,
      username: reservedForUsername,
      fullName: reservedForFullname
    };

    if (isRecurring && type === "series") {
      const state = getAttributeValue(reservation.attributes, FieldNames.STATE);
      const selectedWhen = this._getWhen(reservation, series);
      details = {
        when: selectedWhen,
        isOngoingBooking: state === BOOKING_STATE.CHECKED_IN,
        unitAttr: unit?.attributes,
        unitFeature: unit,
        checkAvailability: this.checkAvailability,
        selfBooking: false,
        bookingType: "hotel",
        recurrenceType: "series",
        reserveForInfo,
        originalReservation: { attributes: reservation?.attributes, booking: null },
        series
      };
      workspaceReservationUtil.rescheduleBooking(details);
    } else {
      details = {
        reservation: reservation,
        rescheduleBooking: this._rescheduleBooking,
        operation: "updateBookingTime",
        bookingType: "hotel",
        recurrenceType: type == null ? "single" : "occurrence",
        reserveForInfo,
        series
      }
      workspaceReservationUtil.showScheduleViewEsri(details);
    }
  }

  checkAvailability = (options: ICheckAvailabilityOptions) => {
    const { cluster } = this.props;
    const unitFeature = cluster && cluster.unit;

    const info: IBookingTask & { unitFeature: __esri.Graphic } = {
      bookingSystem: workspaceReservationUtil.getHotelBookingSystem(),
      checkInDate: options.checkInDate,
      checkOutDate: options.checkOutDate,
      allDay: options.allDay,
      options:options,
      unitFeature: unitFeature,
      reservation: cluster && cluster.reservations && cluster.reservations[0],
      unit: unitFeature,
      bookingType: 'hotel',
      bookingForOther: true,
      renderBookingConfirmed: this.renderBookingConfirmed,
      series: this.props.series
    }
    return workspaceReservationUtil.checkAvailability(info);
  }
  renderBookingConfirmed(data, params, task) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    const origResAttr = task && task.reservation && task.reservation.attributes;
    const recurrence = params.recurrence;    
    let bookingType: BookingType = "single";
    if (recurrence?.enabled) {
      bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
    }
    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={getHotelBookingSystemType()}
        bookingType={bookingType}
        unitName={task.unitName}
        levelName={task.levelName}
        facilityName={task.facilityName}
        closePopup={onClose}
        data={data}
        unit={params && params.unit}
        updateBooking={task && task.updateBooking}
        origResAttr={origResAttr}
        params={params}
      />
    ), node);
  }
  renderCancelConfirmed(reservedForFullName, type: BookingType) {
    let msg = Context.getInstance().i18n.more.bookWorkspace.checkInOut.someoneElse.confirmationSubtitleCancel;
    msg = msg.replace("{person}", reservedForFullName);
    const node = document.createElement("div");
    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    document.body.appendChild(node);
    ReactDOM.render(
      <BookingCancel
        bookingSystemType={getHotelBookingSystemType()}
        bookingType={type}
        closePopup={onClose}
        message={msg}        
      />, node);
  }
  _rescheduleBooking = async(when, isOngoingBooking, recurrenceType)=> {
    const { cluster } = this.props;
    const unitFeature = cluster && cluster.unit;
    const unitAttr = unitFeature && unitFeature.attributes;
    const reservationAttr = cluster && cluster.reservations && cluster.reservations[0] && cluster.reservations[0].attributes;
    const areaId = getAttributeValue(unitAttr, FieldNames.AREA_ID)
    const reservedForUsername = getAttributeValue(reservationAttr, FieldNames.RESERVED_FOR_USERNAME);
    const reservedForFullname = getAttributeValue(reservationAttr, FieldNames.RESERVED_FOR_FULL_NAME);

    let reserveForInfo = {
      areaIDs: [areaId],
      email: null,
      occupantFeature: null,
      username: reservedForUsername,
      fullName: reservedForFullname
    };

    const details = {
      bookingType: "hotel",
      when: when,
      isOngoingBooking: isOngoingBooking,
      unitAttr: unitAttr,
      unitFeature: unitFeature,
      checkAvailability: this.checkAvailability,
      selfBooking: false,
      reserveForInfo: reserveForInfo,
      originalReservation: {attributes: reservationAttr, booking: null},
      recurrenceType
    }
    workspaceReservationUtil.rescheduleBooking(details);
  }
}

const mapStateToProps = (state) => {
  return {
    rdxDateOption: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_DATESELECTED)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherBookings);

