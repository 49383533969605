import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import Topic from "../../../../context/Topic";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as mapUtil from "../../../base/mapUtil";
import { HitTest } from "../redux";
import { LayerType } from "../../../../util/interfaces";
import { isLayerViewVisible } from "./editorUtil";
import { getCapitalizedType } from "./formUtil";

export interface IMapSelectionViewModelProps {
  activeFillSymbol?: __esri.SimpleFillSymbolProperties,
  activeLineSymbol?: __esri.SimpleLineSymbolProperties,
  fillSymbol?: __esri.SimpleFillSymbolProperties,
  graphics?: HitTestGraphic[],  
  lineSymbol?: __esri.SimpleLineSymbolProperties,
  alwaysHighlightActive?: boolean,
  ignoreVisibleAtScale?: boolean,
  multipleSelectionEnabled?: boolean,
  selectedFeatureIndex?: number,
  selectionTypes: LayerType[],
  onFeaturesSelected: (features: HitTest[], event: __esri.SketchViewModelCreateEvent | __esri.ViewClickEvent) => void,
  view: __esri.View,
  visible?: boolean
}
const DEFAULT_COLOR = [0, 255, 255, 1];
const ACTIVE_COLOR = [255, 255, 0, 1];
type Keys = { shiftKey: boolean, ctrlKey: boolean };
type HitTestGraphic = { graphic: __esri.Graphic, key: LayerType, oid: number };

export default class MapSelectionModel extends BaseClass implements IMapSelectionViewModelProps {

  private hitTestGraphics: HitTestGraphic[] = [];
  get graphics() {
    return this.hitTestGraphics;
  }
  set graphics(value: HitTestGraphic[]) {
    this.hitTestGraphics = value || [];
    if (this.selectionsByType) {
      this.selectionsByType.forEach((...params) => params[0].clear());
      this.hitTestGraphics.forEach(h => this.selectionsByType.get(h.key).add(h.oid));
    }
  }
  alwaysHighlightActive: boolean;
  ignoreVisibleAtScale: boolean;
  multipleSelectionEnabled: boolean;
  onFeaturesSelected: IMapSelectionViewModelProps["onFeaturesSelected"];
  selectedFeatureIndex: number;
  selectionTypes: LayerType[];
  view: __esri.View;
  visible: boolean;

  private activeSketchViewModel: __esri.SketchViewModel;
  private ctrlKey: boolean;
  private shiftKey: boolean;
  private layerId = "indoors-mapselection";
  private dragHandle: __esri.WatchHandle;
  private activeType: Exclude<__esri.SketchViewModel["activeTool"], "move" | "transform" | "reshape"> = "point";
  private selectedGraphicsLayer: __esri.GraphicsLayer;
  private selectedLineSymbol!: __esri.SimpleLineSymbol;
  private selectedFillSymbol!: __esri.SimpleFillSymbol;
  private highlightFillSymbol!: __esri.SimpleFillSymbol;
  private highlightLineSymbol!: __esri.SimpleLineSymbol;
  private selectionsByType: Map<LayerType, Set<number>> = new Map([
    ["unit", new Set<number>()],
    ["detail", new Set<number>()],
    ["site", new Set<number>()],
    ["facility", new Set<number>()],
    ["level", new Set<number>()]
  ]);
  private watchKeys = (event: KeyboardEvent) => {
    if (event.type === "keydown") {
      this.shiftKey = event.shiftKey;
      this.ctrlKey = event.ctrlKey;
    } else {
      this.shiftKey = this.ctrlKey = false;
    }
  }

  constructor(props: IMapSelectionViewModelProps) {
    super(props);
    Object.assign(this, props);
    const { esri } = Context.instance.lib;
    const defaultLine = { style: "solid", width: 2, color: DEFAULT_COLOR };
    const defaultFill = { style: "none", outline: defaultLine, color: DEFAULT_COLOR };
    this.selectedLineSymbol = new esri.SimpleLineSymbol(props.lineSymbol || defaultLine);
    this.selectedFillSymbol = new esri.SimpleFillSymbol(props.fillSymbol || defaultFill);
    this.highlightLineSymbol = new esri.SimpleLineSymbol(props.activeLineSymbol || { ...defaultLine, color: ACTIVE_COLOR });
    this.highlightFillSymbol = new esri.SimpleFillSymbol(props.activeFillSymbol || { ...defaultFill, outline: this.highlightLineSymbol });
    if (this.view && this.view.container) this.view.container.style.cursor = "default";
    this.watchKeys = this.watchKeys.bind(this);
    this.selectedGraphicsLayer = this.ensureGraphicsLayer(this.layerId);
  }

  activateSketch(
    type: MapSelectionModel["activeType"],
    createOptions?: __esri.SketchViewModel["defaultCreateOptions"]
  ) {
    this.cancelSketch();
    this.activeType = type;
    this.selectedGraphicsLayer = this.ensureGraphicsLayer(this.layerId);
    if (type === "point") {
      this.useMapClick();
    } else {
      this.useSketchModel(type, createOptions);
    }
  }

  cancelSketch() {
    window.removeEventListener("keydown", this.watchKeys);
    window.removeEventListener("keyup", this.watchKeys);
    Topic.publish(Topic.ClearToast, {});
    if (this.dragHandle) {
      this.dragHandle.remove();
      this.dragHandle = null;
    }
    this.clearHandles();
    this.shiftKey = this.ctrlKey = false;
    const svm = this.activeSketchViewModel;
    if (svm) {
      svm.cancel();
      svm.destroy();
      this.activeSketchViewModel = null;
      Context.getInstance().views.toggleClickHandlers("resume");
    }
  }
  /** Clears the entire selection set. */
  clear() {
    this.clearHighlights();
    this.hitTestGraphics.length = 0;
    this.selectionsByType.forEach((...params) => params[0].clear());
  }
  /** Clears the selected feature highlights. */
  clearHighlights() {
    this.selectedGraphicsLayer && this.selectedGraphicsLayer.removeAll();
  }

  private ensureGraphicsLayer(layerId: string) {
    let layer = this.view && this.view.map && this.view.map.findLayerById(layerId) as __esri.GraphicsLayer;
    if (!layer) {
      const { lib, config } = Context.getInstance();
      layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: config.graphicElevationOffset,
        unit: "meters"
      };
      this.view && this.view.map && this.view.map.add(layer);
    }
    layer.visible = this.visible;
    return layer;
  }
  private getLayerViews(): Map<LayerType, __esri.FeatureLayerView> {
    const layerViews = new Map<LayerType, __esri.FeatureLayerView>();
    const visibilityCheck = ({ lv }: { lv: __esri.FeatureLayerView }) =>
      // @ts-ignore
      lv && (this.ignoreVisibleAtScale || lv.visibleAtCurrentScale);

    if (this.selectionTypes == null || this.selectionTypes.length === 0) {
      const layers = ["detail", "unit", "level", "facility", "site"].map((key: LayerType) =>
        ({ key, lv: mapUtil.getLayerView(key) as __esri.FeatureLayerView }));
      layers.filter(visibilityCheck).forEach(({ key, lv }) => layerViews.set(key, lv));
    } else {
      this.selectionTypes.forEach(type => {
        const lv = mapUtil.getLayerView(type) as __esri.FeatureLayerView;
        if (visibilityCheck({ lv })) {
          layerViews.set(type, lv);
        }
      });
    }
    return layerViews;
  }
  private getQuery(lv: __esri.FeatureLayerView, geometry: __esri.Geometry) {
    const query = lv.createQuery();
    query.returnZ = true;
    query.geometry = geometry;
    if (lv.layer.geometryFieldsInfo.shapeAreaField) {
      query.orderByFields = [lv.layer.geometryFieldsInfo.shapeAreaField]
    } else if (lv.layer.geometryFieldsInfo.shapeLengthField) {
      query.orderByFields = [lv.layer.geometryFieldsInfo.shapeLengthField]
    }
    if (this.activeType === "point") {
      query.distance = .1;
      query.units = "feet";
    }
    return query;
  }
  highlightFeatures() {
    const totalLength = this.hitTestGraphics.length;
    const lengthByType = this.selectionTypes?.length > 0
      ? Object.fromEntries(this.selectionTypes.map(type =>
          [type, this.hitTestGraphics.filter(h => h.key === type).length]))
      : totalLength;
    let active: __esri.Graphic = null;
    if (this.selectedFeatureIndex != null && totalLength > 0 && this.selectedFeatureIndex < totalLength) {
      this.hitTestGraphics.forEach((h, i) => {
        if (this.selectionTypes?.length > 0 && !this.selectionTypes.includes(h.key)) {
          return;
        }
        const graphic = h.graphic.clone();
        graphic.symbol = graphic.geometry.type === "polygon"
          ? this.selectedFillSymbol.clone()
          : this.selectedLineSymbol.clone();
        const length = typeof lengthByType === "number" ? lengthByType : lengthByType[h.key];
        if (this.multipleSelectionEnabled && this.selectedFeatureIndex === i && (length > 1 || this.alwaysHighlightActive)) {
          graphic.symbol = graphic.geometry.type === "polygon"
            ? this.highlightFillSymbol.clone()
            : this.highlightLineSymbol.clone();
          active = graphic;
        }
        if (this.multipleSelectionEnabled || this.selectedFeatureIndex === i) {
          this.selectedGraphicsLayer.add(graphic);
        }
      });
      if (active) {
        this.selectedGraphicsLayer.graphics.reorder(active, this.selectedGraphicsLayer.graphics.length - 1);
      }
    }
  }

  _isMatching2DLevel(feature: __esri.Graphic) {
    if (!Context.getInstance().aiim.facilityMode) return;
    const source = sourceUtil.getPeopleSource();
    const zInfo = Context.instance.aiim.getZInfo(source, feature);
    const ld = zInfo && zInfo.levelData;
    const featureVO = ld ? ld.verticalOrder : aiimUtil.getAttributeValue(feature.attributes, FieldNames.VERTICAL_ORDER);
    const featureFacilityId = ld ? ld.facilityId : aiimUtil.getAttributeValue(feature.attributes, FieldNames.FACILITY_ID);
    const facilityInfo = Context.instance.aiim.getActiveFacilityInfo();
    const activeLevel = (facilityInfo && facilityInfo.activeLevel);
    if (activeLevel) {
      const activeVO = activeLevel.verticalOrder;
      const activeFacilityId = activeLevel.facilityId;
      if (featureFacilityId === activeFacilityId) {
        return (featureVO === activeVO);
      } else {
        return (featureVO === 0);
      }
    } else {
      return (featureVO === 0);
    }
  }

  private select(event: __esri.SketchViewModelCreateEvent | __esri.ViewClickEvent, keys: Keys) {
    Topic.publish(Topic.ClearToast, {});
    this.clearHighlights();

    const layerViews = this.getLayerViews();
    const state = "state" in event ? event.state : "complete";
    if (layerViews.size === 0 || state !== "complete") {
      this.view.container.style.cursor = "default";
      return;
    }
    const { i18n } = Context.instance;
    const counts = [...layerViews].map(([key, lv]) => {
      const query = this.getQuery(lv, "graphic" in event ? event.graphic.geometry : event.mapPoint);
      return lv.layer.queryFeatureCount(query).then(count =>
        ({ key, query, count, lv, max: +lv.layer.sourceJSON.maxRecordCount }));
    });
    Promise.all(counts).then(results => {
      const queries: Promise<{ key: LayerType, oidField: string, features: __esri.Graphic[] }>[] = [];
      // check if every count will be below max limit
      // if so, go ahead with the query; otherwise, create empty results
      if (results.every(({ max, count }) => count <= max)) {
        queries.push(...results.map(({ count, key, query, lv }) =>
          count > 0 // no need to query if there won't be any results, so return empty result
            ? lv.layer.queryFeatures(query).then(fs => ({ key, oidField: lv.layer.objectIdField, features: fs.features }))
            : Promise.resolve({ key, oidField: lv.layer.objectIdField, features: [] as __esri.Graphic[] })
        ));
      } else {
        // get max count from first result over limit
        const over = results.find(({ count, max }) => count > max);
        const maxCount = over.max;
        const isVisible = isLayerViewVisible(over.lv);
        const type = getCapitalizedType(over.key, true);
        const submessage = isVisible
          ? i18n.miniapps.mapSelection.exceedsTransferLimitMessage
            .replaceAll("{limit}", maxCount)
            .replaceAll("{type}", type)
          : i18n.miniapps.mapSelection.exceedsTransferLimitMessageWithDescription
            .replaceAll("{limit}", maxCount)
            .replaceAll("{type}", type);
        queries.push(...results.map(({ key, lv }) =>
          Promise.resolve({ key, oidField: lv.layer.objectIdField, features: [] as __esri.Graphic[] })));
        
        Topic.publish(Topic.ShowToast, {
          open: true,
          type: "warning",
          message: i18n.miniapps.mapSelection.exceedsTransferLimitTitle,
          submessage
        });
      }
      Promise.all(queries).then(results => {
        const hitTestGraphics: HitTestGraphic[] = this.multipleSelectionEnabled && keys.shiftKey
          ? [...this.hitTestGraphics]
          : [];
        results
          .filter(({ features }) => features.length)
          .forEach(({ key, features, oidField }) => features.forEach(graphic => {
            const oid = graphic.attributes[oidField];
            if (this.multipleSelectionEnabled && keys.shiftKey) {
              const isSelected = this.selectionsByType.get(key as LayerType).has(oid);
              if (!isSelected) {
                hitTestGraphics.push({ graphic, key, oid });
                this.selectionsByType.get(key as LayerType).add(oid);
              } else {
                const idx = hitTestGraphics.findIndex(h => {
                  return h.key === key && h.graphic.attributes[oidField] === oid;
                });
                hitTestGraphics.splice(idx, 1);
                this.selectionsByType.get(key as LayerType).delete(oid);
              }
            } else {
              hitTestGraphics.push({ graphic, key, oid });
              this.selectionsByType.forEach((...params) => params[0].clear());
              this.selectionsByType.get(key as LayerType).add(graphic.attributes[oidField]);
            }
          }));

        if (hitTestGraphics && hitTestGraphics.length) {
          this.hitTestGraphics = hitTestGraphics;
          this.highlightFeatures();
          this.onFeaturesSelected(this.hitTestGraphics.map(({ graphic, key, oid }) =>
            ({ feature: graphic.toJSON(), key, oid })), event);
        } else {
          this.onFeaturesSelected([], event);
        }
      });
    }).finally(() => {
      this.view.container.style.cursor = "default";
    })
  }

  private useMapClick() {
    this.clearHandles();
    this.shiftKey = this.ctrlKey = false;
    window.addEventListener("keydown", this.watchKeys);
    window.addEventListener("keyup", this.watchKeys);
    this.own([
      this.view.on("click", (event: __esri.ViewClickEvent) => {
        if (this.activeType !== "point" || Context.getInstance().views.mapClickDisabled)
          return;
        this.view.container.style.cursor = "progress";
        this.select(event, { shiftKey: this.shiftKey, ctrlKey: this.ctrlKey });
      })
    ]);
  }

  private useSketchModel(type: MapSelectionModel["activeType"], createOptions: __esri.SketchViewModelDefaultCreateOptions) {
    const { lib, views } = Context.getInstance();
    let svm: __esri.SketchViewModel = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: this.view,
      layer: this.selectedGraphicsLayer
    });
    svm.on("create", event => {
      if (event.state === "complete") {
        const keys = {
          shiftKey: this.shiftKey,
          ctrlKey: this.ctrlKey
        };

        svm.create(type, createOptions);
        this.select(event, keys);
      } else if (event.state === "cancel") {
        svm.create(type, createOptions);
      }
    });
    views.toggleClickHandlers("pause");
    svm.create(type, createOptions);
    this.shiftKey = this.ctrlKey = false;
    window.addEventListener("keydown", this.watchKeys);
    window.addEventListener("keyup", this.watchKeys);
    this.dragHandle = this.view.on("drag", ["Shift"], event => event.stopPropagation());
  }
}
