import React from "react";
import Panel from "calcite-react/Panel";
import Popover from 'calcite-react/Popover';
import Context from "../../../../../context/Context";
const CSS = {
  main: "i-recurrence-selector"
};
export type RecurrenceType = "occurrence" | "series";
interface IRecurrenceDropdownProps {
  onRequestClose: () => void,
  onSelection: (type: RecurrenceType) => void,
  open: boolean,
  targetEl: JSX.Element,
  type: "cancel" | "edit"
}

const RecurrenceDropdown = ({ onRequestClose, onSelection, open, targetEl, type }: IRecurrenceDropdownProps) => {
  const { recurrence: i18n } = Context.getInstance().i18n.more.bookWorkspace;
  
  const onClick = (event, type: RecurrenceType) => {
    event.stopPropagation();
    if (typeof onSelection === "function") {
      onSelection(type);
    }
  }
  const renderContent = () => {
    return (
      <div className={CSS.main}>
        <ul>
          <li>
            <button onClick={(event)=>onClick(event, "occurrence")}>
              <h3>{type === "cancel" ? i18n.occurrenceCancel : i18n.occurrenceEdit}</h3>
            </button>
          </li>
          <li>
            <button onClick={(event)=>onClick(event, "series")}>
              <h3>{type === "cancel" ? i18n.seriesCancel : i18n.seriesEdit}</h3>
            </button>
          </li>
        </ul>
      </div>      
    );
  }
  return (
    <Popover
      onRequestClose={onRequestClose}
      open={open}
      targetEl={targetEl}
      appendToBody>
      <Panel white>
        {renderContent()}
      </Panel>
    </Popover>
  );
}
export default RecurrenceDropdown;