import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import Toggle from "../../common/Toggle/Toggle";
import * as component from "../../util/component";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label"
};

export default class NativeApp extends React.Component {

  constructor(props) {
    super(props);
    const nativeApp = this.props.cfg.getConfigurable("nativeApp");
    this.state = component.newState({
      showIOS: nativeApp.showIOS,
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeShowIOS = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        showIOS: checked
      }
    });
    cfg.getConfigurable("nativeApp").showIOS = checked;
  }

  render() {
    const i18n = Context.instance.i18n;
    const showIOS = this.state.showIOS;

    return (
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.nativeApp.caption}</span>
          <HelpTip text={i18n.configurator.nativeApp.help}/>
        </div>

        <div key="showIOS" className={CSS.formGroup}>
          <label id={"cfg-nativeApp-showIOS-label"} htmlFor={"cfg-nativeApp-showIOS"}
            className={"i-flex-between"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.nativeApp.showIOS}</div>
            <Toggle id={"cfg-nativeApp-showIOS"} checked={showIOS}
              onChange={this.onChangeShowIOS}/>
          </label>
        </div>

      </div>
    );

  }

  updateMapScalebar() {
    Context.instance.views.updateScalebar();
  }

}
