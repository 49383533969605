import styled, { css } from "styled-components";
import CalciteButton from "calcite-react/Button";
import { unitCalc } from "calcite-react/utils/helpers";

const FooterMessage = styled.div`
`;

const FooterDate = styled.div`
    display: flex;
    margin-top: ${(props) => props.theme.panelMargin};
    margin-bottom: ${(props) => props.theme.panelMargin};
`;

const FilterRowButton = styled(CalciteButton)`
  background: #efefef;
  color: #4c4c4c;
  border-color: transparent;
  margin: 0.1rem;

  &:hover {
    background: ${props => props.theme.palette.themeColorDark};
    border-color: ${props => props.theme.palette.themeColorDark};
    color: #ffffff;
  }

  ${props =>
    props.active &&
    css`
      background: ${props => props.theme.palette.blue};
      color: ${props => props.theme.palette.white};
    `};

`;

const ThemeColorButton = styled(CalciteButton)`
background: ${props => props.theme.palette.themeColorDark};
color: #ffffff;
border-color: ${props => props.theme.palette.themeColorDark};
margin: 0.1rem;
height: 32px;

&:hover {
  background: ${props => props.theme.palette.themeColor};
  border-color: ${props => props.theme.palette.themeColor};
`;

const Table = styled.table`
  display: flex !important;
  flex-direction: column !important;
  overflow-y: hidden !important; 
  width: 100%;
  border-collapse: collapse;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const Thead = styled.thead`
  width: 100%;
  display: flex;
  border: none;

  & > tr{
    background: #d8d8d8;
    border: none;
  }
`;

const Th = styled.th`
  width: calc(100%/3);
  text-align: start;
  padding: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const Tr = styled.tr`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 0.9375rem;
  background: ${props => props.theme.palette.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background: ${props => props.theme.palette.themeColorHighlight};
  }
  ${props =>
    props.listitem &&
    css`
      transition: background ${props => props.theme.transition};
      border-left: ${props =>
        props.moreButton ? "0" : `3px solid ${props.theme.palette.themeColor}`};
    `};
  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `};
`;

const Td = styled.td`
  padding: ${props => unitCalc(props.theme.baseline, 3, "/")};
  width: calc(100%/3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  color: #323232;
  font-weight: normal;
  overflow-wrap: normal;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  & > svg{
      display: flex;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin: 0 2px;
  }
`;

export { FooterMessage, FooterDate, FilterRowButton, ThemeColorButton, Table, Tbody, Thead, Th, Tr, Td };
