import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";

function afterAddOccupant(results, undoTransaction, method) {
  const peopleLayer = sourceUtil.getPeopleLayer();
  const peopleLayerId = peopleLayer.layerId;
  const requestInfo = undoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;
  
  results.forEach(layerResult => {
    if (layerResult.id === peopleLayerId) {
      if (layerResult.addResults && layerResult.addResults.length === 1) {
        const newKey = layerResult.addResults[0].globalId;
        
        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          let found = false;
          edits.forEach(layerEdit => {
            console.log(layerEdit);
            if (layerEdit.id === peopleLayerId) {
              if (layerEdit.deletes && layerEdit.deletes.length === 1) {
                layerEdit.deletes = [newKey];
                found = true;
              }
            }
          });
          if (found) query.edits = JSON.stringify(edits);
        }
      }
    }
  });
}

export function prepare(task) {
  const promise = new Promise((resolve, reject) => {
    const adds = [];
    const layer = sourceUtil.getPeopleLayer();
    const add = {attributes: {}};

    Object.assign(add, task.attributes);
    adds.push(add);
    
    const transactionInfo = transaction.makeTransactionInfo();
    if (adds.length > 0) {
      transactionInfo.useGlobalIds = false;
      transactionInfo.peopleEdits = {adds: adds};
      transactionInfo.undo.peopleEdits = true;
      transactionInfo.edits.push({
        id: layer.layerId,
        adds: adds,
        deletes: null
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        deletes: ["?"]
      });
      transactionInfo.afterApplyEdits = afterAddOccupant;
    }
    resolve(transactionInfo);
  })
  return promise;
}