import React from "react";
import Context from "../../../context/Context";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";

const InvalidAssignment = () => {
  return (
    <div style={{ display: "flex", gap: "3px" }}>
      <ExclamationMarkTriangleIcon size={16} color="orange" style={{ alignSelf: "center" }} />
      <span>{Context.instance.i18n.spaceplanner.invalidAssignment}</span>
    </div>
  )
}
export default InvalidAssignment;