import Context from "../../../context/Context";

export const PartialKeys = {
  recentPlans: "recent-plans"
}

// this app, this user
export function getAppUserItem(partialKey: string): string {
  return getItem(makeAppUserKey(partialKey));
}

export function getItem(fullkey: string): string {
  return window.localStorage.getItem(fullkey);
}

// this user (all apps)
export function getUserItem(partialKey: string): string {
  return getItem(makeUserKey(partialKey));
}

export function getUsername(): string {
  if (Context.instance.user.isAnonymous()) {
    return "__anonymous__";
  }
  return Context.instance.user.getUsername();
}

export function makeAppUserKey(partialKey: string): string {
  const appItem = Context.instance.configuration.appItem;
  const username = getUsername();
  return "indoors-" + username + "-" + appItem.id + "-" + partialKey;
}

export function makeUserKey(partialKey: string): string {
  const username = getUsername();
  return "indoors-" + username + "-" + partialKey;
}

export function parse(value: string): any {
  if (value) {
    try {
      return JSON.parse(value);
    } catch (ex) {
      console.warn("Error parsing storage value",value);
      console.error(ex);
    }
  }
  return null;
}

// this app, this user
export function removeAppUserItem(partialKey: string): void {
  removeItem(makeAppUserKey(partialKey));
}

export function removeItem(fullkey: string): void {
  window.localStorage.removeItem(fullkey);
}

// this user (all apps)
export function removeUserItem(partialKey: string): void {
  removeItem(makeUserKey(partialKey));
}

// this app, this user
export function setAppUserItem(partialKey: string, value: string): void {
  setItem(makeAppUserKey(partialKey),value);
}

export function setItem(fullkey: string, value: string): void {
  window.localStorage.setItem(fullkey,value);
}

// this user (all apps)
export function setUserItem(partialKey: string, value: string): void {
  setItem(makeUserKey(partialKey),value);
}
