import React from "react";

import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import ItemProperties from "../common/ItemProperties";
import { ModalController } from "../../../common/components/Modal";
import * as component from "../../../components/util/component";

export default class SaveAs extends React.Component {
  _itemProperties;

  constructor(props) {
    super(props);
    this.state = component.newState({
      saving: false
    });
  }

  cancelClicked = evt => {
    if (this.props.onCancelCliked) {
      this.props.onCancelCliked();
    }
  };

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    //const saveDisabled = (this.state.title.trim().length === 0);
    const saveDisabled = !!this.state.saving;
    const actions = (
      <div key="actions" className="i-actions">
        <Button
          key="cancel"
          clear
          onClick={this.cancelClicked}
          style={{ marginInlineEnd: "1rem" }}
        >
          {i18n.general.cancel}
        </Button>
        <Button key="ok" disabled={saveDisabled} onClick={this.saveClicked}>
          {i18n.general.save}
        </Button>
      </div>
    );

    return (
      <div className={"i-cfg-saveas"}>
        <ItemProperties onMount={this.setItemProperties} disallowEveryone={true} disallowGroups={true} isModal />
        {actions}
      </div>
    );
  }

  saveClicked = evt => {
    if (this.state.saving) return;
    const itemInfo = this._itemProperties && this._itemProperties.getItemInfo();
    if (!itemInfo || !itemInfo.title) return;
    this.setState({ saving: true });

    const metaInfo = {
      title: itemInfo.title,
      tags: itemInfo.tags,
      snippet: itemInfo.summary
    };
    const sharingInfo = itemInfo.sharingInfo;
    let folderId = itemInfo.folderId;
    if (folderId === "") folderId = null;
    if (this.props.onSaveCliked) {
      this.props.onSaveCliked(metaInfo, folderId, sharingInfo);
    }
  };

  setItemProperties = itemProperties => {
    this._itemProperties = itemProperties;
  };

  static showModal(props) {
    const i18n = Context.instance.i18n;
    const controller = new ModalController();
    const onSaveCliked = (metaInfo, folderId, sharingInfo) => {
      if (props.onSaveCliked) {
        props.onSaveCliked(metaInfo, folderId, sharingInfo, controller);
      }
    };
    const onCancelCliked = () => {
      controller.close();
    };
    const content = (
      <SaveAs
        cfg={props.cfg}
        onSaveCliked={onSaveCliked}
        onCancelCliked={onCancelCliked}
      />
    );
    const modalProps = {
      title: i18n.spaceplanner.configurator.saveAsDialogTitle,
      className: "i-configurator-modal"
    };
    controller.show(modalProps, content);
  }
}
