import React from "react";

import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Grid from "../components/Editor/support/Grid";
import HostedMerge from "./HostedMerge";
import Issues from "../components/common/Issues";
import MiniappsIssues from "../miniapps/configurator/Issues";
import {ModalController, WorkingController} from "../../common/components/Modal";
import OfficePlan from "./OfficePlan";
import PlanOpener from "./PlanOpener";
import Project from "./Project";
import Topic from "../../context/Topic";
import * as mergeUtil from "./mergeUtil";
import * as modalUtil from "../miniapps/common/components/modalUtil";
import * as recentPlansUtil from "../miniapps/support/recentPlansUtil";
import * as sourceUtil from "./sourceUtil";
import * as val from "../../util/val";
import * as placeholderUtil from '../base/placeholderUtil';
import Rdx from "../../redux/Rdx";
import { EDITOR_ACTIVE_SIDEBAR_TAB } from "../components/Editor/redux";
import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";
import { hasLayer } from "../components/Editor/support/formUtil";

export default class Planner extends BaseClass {

  grid: Grid;
  hadOpenPlanError = false;
  hasConflicts: boolean = false;
  project: Project;

  static getParentAppId(planItem) {
    let appId = null;
    if (planItem.versionInfo) {
      const appItem = Context.instance.configuration.appItem;
      return appItem && appItem.id;
    } else {
      const pfx = OfficePlan.TypeKeywordParentPfx;
      const a = planItem && planItem.typeKeywords;
      if (Array.isArray(a)) {
        a.some(v => {
          if (v && v.indexOf(pfx) === 0) {
            appId = v.substring(pfx.length);
          }
          return (!!appId);
        });
      }
    }
    return appId;
  }

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
  private async _checkReconcileOnOpen(project: Project, plan: OfficePlan): Promise<boolean> {
    let defaultHasChanges = false;
    const vm = project.versionedInfo.versionManager;
    const isOwner = vm && vm.isOwner(plan.versionInfo);
    const ancestorDate = plan.versionInfo.commonAncestorDate;
    const guid = vm && vm.serviceInfo && vm.serviceInfo.defaultVersionGuid;
    const infos = vm && vm.versionInfos;
    if (isOwner && guid && infos) {
      infos.some(info => {
        if (info.versionGuid === guid) {
          const modDate = info.modifiedDate;
          if (modDate > ancestorDate) {
            defaultHasChanges = true;
          }
          return true;
        }
        return false;
      })
    }
    if (defaultHasChanges && Context.instance.isFPE()) {
      const i18n = Context.instance.i18n;
      const ok = await modalUtil.confirm({
        title: i18n.switcher.mergePlan.pullOnOpenTitle,
        message: i18n.switcher.mergePlan.pullOnOpenMessage,
        okLabel: i18n.switcher.mergePlan.pullOnOpenLabel
      });
      if (ok) {
        const workingController = new WorkingController();
        const workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
        workingController.show({}, workingContent);
        const options = {
          plan: plan,
          versionManager: vm,
          reconcile: true,
          applyEdits: true,
          post: false
        };
        try {
          const result = await mergeUtil.analyze(options);
          this.hasConflicts = result.hasConflicts;
          workingController.close();
        } catch(ex) {
          console.error(ex);
          if (workingController) workingController.close();
          modalUtil.showError(modalUtil.makeErrorOptions(
            i18n.switcher.mergePlan.mergeErrorTitle,
            i18n.switcher.mergePlan.mergeErrorMessage,
            ex
          ));
        }
        return true;
      }
    } else if (defaultHasChanges) {
      const i18n = Context.instance.i18n;
      const modalOptions = {
        className: "i-modal-confirm i-modal-small",
        title: i18n.general.information,
        message: i18n.spaceplanner.backstage.mergePlan.reconcilePromptAlt,
        okLabel: i18n.general.yes,
        cancelLabel: i18n.more.bookWorkspace.no,
        closeOnCancel: true
      }
      const options = {
        plan: plan,
        versionManager: vm,
        reconcile: true,
        applyEdits: true,
        post: false,
        cancelMerge: false
      };
      ModalController.confirm(modalOptions).then(async (result) => {
        if (result.ok) {
          const workingController = new WorkingController();
          workingController.show({}, i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.controller);
          try {
            await placeholderUtil.checkReplacePlaceholders(options, workingController);
            if (options.cancelMerge) return;
          } catch (error) {
            workingController.close();
            console.error('Error searching placeholders', error);            
          }
          const workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
          workingController.show({}, workingContent);
          mergeUtil.analyze(options).then(task => {
            //console.log("MergePlan mergeUtil::analyze",task)
            workingController.close();
          }).catch(ex => {
            let submessage = ex.submessage || ex.message;
            workingController.close();
            ModalController.alert({
              isError: true,
              title: i18n.spaceplanner.backstage.mergePlan.errorMergingTitle,
              message: i18n.spaceplanner.backstage.mergePlan.errorMergingMessage,
              submessage: submessage
            });
            console.error("Error merging plan", ex);
          });
        }
      })
    }
    return false;
  }

  _checkReconcileOnOpenHosted(project: Project, plan: OfficePlan) {
    const isOwner = Context.instance.user.isPlanOwner();
    if (!isOwner) return;
    let hm = new HostedMerge();
    hm.defaultHasChanges().then(result => {
      const defaultHasChanges = (result && result.hasChanges);
      if (defaultHasChanges) {
        const i18n = Context.instance.i18n;
        const modalOptions = {
          className: "i-modal-confirm i-modal-small",
          title: i18n.general.information,
          message: i18n.spaceplanner.backstage.mergePlan.reconcilePromptAlt,
          okLabel: i18n.general.yes,
          cancelLabel: i18n.more.bookWorkspace.no,
          closeOnCancel: true
        }
        ModalController.confirm(modalOptions).then(async (result) => {
          if (result.ok) {
            const options = {
              plan: plan,
              reconcileOnly: true,
              cancelMerge: false
            };
            const workingController = new WorkingController();
            workingController.show({}, i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.controller);
            try {
              await placeholderUtil.checkReplacePlaceholders(options, workingController);
              if (options.cancelMerge) return;
            } catch (error) {
              workingController.close()
              console.error(error);
            }
            const workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
            workingController.show({}, workingContent);
            hm = new HostedMerge();
            hm.merge(options).then(task => {
              //console.log("MergePlan mergeUtil::analyze",task)
              workingController.close();
            }).catch(ex => {
              let submessage = ex.submessage || ex.message;
              workingController.close();
              ModalController.alert({
                isError: true,
                title: i18n.spaceplanner.backstage.mergePlan.errorMergingTitle,
                message: i18n.spaceplanner.backstage.mergePlan.errorMergingMessage,
                submessage: submessage
              });
              console.error("Error merging plan", ex);
            });
          }
        })
      }
    }).catch(ex => {
      console.error(ex);
    })
  }

  getProjectDetailsLayer() {
    if (this.project && this.project.isVersioned) {
      return (this.project.versionedInfo && this.project.versionedInfo.detailsLayer)
    } else if (this.project && this.project.isHosted) {
      return (this.project.hostedInfo && this.project.hostedInfo.detailsLayer)
    }
    return null;
  }

  _handleOpenPlanError(task, project: Project, error) {
    this.hadOpenPlanError = true;
    this._showOpenPlan(task,project);
    const errorValues = Object.values(error);
    let isPlanInvalid;
    let message = error.message;
    let submessage = error.submessage;
    if(errorValues && errorValues.length >0)
    {
      if(errorValues[0] === "__invalidPlanId__") isPlanInvalid = true;
    }
    if(error.name === "identity-manager:not-authorized" || isPlanInvalid){
      const username=Context.instance.user.getUsername();
      submessage=null;
      message=Context.instance.i18n.spaceplanner.issues.noAccessToPlan;
      message=message.replace("{username}", username);
    }
    ModalController.confirm({
      isError: true,
      title: Context.instance.i18n.spaceplanner.issues.unableToOpenPlanTitle,
      message: message,
      submessage: submessage,
      hideCancel:true
    });
  }

  _handleOpenProjectError(task, project: Project, error) {
    const i18n = Context.instance.i18n;
    let message = error.message;
    let submessage = error.submessage;
    if (message.includes("Server object extension 'versionmanagementserver' not found")) {
      message = i18n.misc.noVersionManager;
    }
    ModalController.confirm({
      isError: true,
      title: i18n.spaceplanner.issues.unableToOpenProjectTitle,
      message: message,
      submessage: submessage
    }).then(() => {
      this._showOpenPlan(task,project);
    });
  }

  hasProjectDetailsLayer() {
    if (this.project.isVersioned) {
      return !!(this.project.versionedInfo && this.project.versionedInfo.detailsLayer)
    } else if (this.project.isHosted) {
      return !!(this.project.hostedInfo && this.project.hostedInfo.detailsLayer)
    }
    return false;
  }

  hasIssues() {
    const plan = Context.instance.spaceplanner.activePlan;
    const hasPlanIssues = !!(plan && plan.hasIssues());
    const hasProjectIssues = !!(this.project && this.project.hasIssues());
    return hasPlanIssues || hasProjectIssues || this.hadOpenPlanError;
  }

  hasValidPlan() {
    const plan = Context.instance.spaceplanner.activePlan;
    return !!(plan && plan.wasOpened && !plan.hasIssues());
  }

  hasValidProject() {
    return !!(this.project && !this.project.hasIssues());
  }

  isBaselinePeopleLayerValid() {
    const source = sourceUtil.getInitialPeopleSource();
    return !!(source && source.layer2D);
  }

  isBaselineUnitsLayerValid() {
    const source = sourceUtil.getInitialUnitsSource();
    return !!(source && source.layer2D);
  }

  load() {
    const promise = new Promise<void>((resolve,reject) => {
      const lib = Context.instance.lib;
      let planId = Context.instance.spaceplanner.planId
      if (!planId) {
        const url = lib.esri.urlUtils.urlToObject(window.location.href);
        if (url && url.query) {
          planId = val.trim(url.query.planId || url.query.planid);
        }
      }

      const cfgOn = (Context.instance.uiMode.isConfiguratorOn ||
                     Context.instance.session.startInConfiguratorMode);
      const task = {
        cfgOn: cfgOn
      };

      const project = new Project();
      this.project = project;
      project.interrogateMap().then(() => {
        if (!project.hasIssues()) {
          if (planId) {
            return this._openPlan(task,project,planId);
          } else {
            return this._showOpenPlan(task,project);
          }
        } else {
          this._showProjectIssues(task,project);
        }
      }).then(() => {
        resolve();
      }).catch(ex =>  {
        console.warn("Error opening project",ex);
        if (Context.instance.isFPE()) {
          if (ex) ex.xtnIsFatal = true;
          reject(ex);
        } else {
          this._handleOpenProjectError(task,project,ex);
          resolve();
        }
      });

    });
    return promise;
  }

  _openPlan(task, project: Project, planId: string) {
    const promise = new Promise<void>((resolve,reject) => {
      if (Context.instance.spaceplanner.activePlan) {
        Context.instance.spaceplanner.activePlan.destroy();
      }
      const plan = new OfficePlan();
      const opener = new PlanOpener();
      Promise.resolve().then(result => {
        if (project.isVersioned) {
          return opener.openVersionedPlan(project,plan,planId);
        } else {
          return opener.openHostedPlan(project,plan,planId);
        }
      }).then(() => {
        return plan._init();
      }).then(() => {
        if (!plan.hasIssues()) {
          plan.wasOpened = true;
          Context.instance.spaceplanner.planId = planId;
          Context.instance.spaceplanner.activePlan = plan;
          Context.instance.appMode.setBrowserTitle();
          const { views: { floorFilter } } = Context.instance;
          const floorFilterRefresh = plan.levelsLayer && floorFilter && floorFilter.refreshData();
          if (Context.instance.isFPE()) {
            this.grid = new Grid({
              view: Context.instance.views.mapView
            })
          }
          Topic.publish(Topic.PlanOpened,{
            plan: plan,
            view: Context.instance.views.mapView
          });
          Topic.publish(Topic.CloseBackstage,{});
          recentPlansUtil.onPlanOpened(plan);
          //console.log("plan",plan);
          if (project.isVersioned) {
            this._checkReconcileOnOpen(project, plan).then(needsRefresh => {
              const showEmptyPlanMessage = () => {
                if (Context.instance.isFPE() && !this.hasConflicts && (plan.isEmpty || !plan.hasLevels)) {
                  this._showEmptyPlanMessage(plan);
                }
              }
              // if user merges default plan into current plan
              if (needsRefresh) {
                floorFilter.refreshData().then(showEmptyPlanMessage);
              } else if (floorFilterRefresh) {
                floorFilterRefresh.then(showEmptyPlanMessage);
              }
            });
          } else {
            this._checkReconcileOnOpenHosted(project,plan);
          }
        } else {
          this._showPlanIssues(task,project,plan);
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.error("Error opening plan",ex); // TODO
        this._handleOpenPlanError(task,project,ex);
        resolve();
      });
    });
    return promise;
  };

  reloadApp(appId: string, planId: string) {
    const lib = Context.instance.lib;
    const urlObj = lib.esri.urlUtils.urlToObject(window.location.href);
    let href = urlObj.path;
    if (href.indexOf("#") > 0) href = href.substring(0,href.indexOf("#"));
    href += "?appid=" + encodeURIComponent(appId);
    href += "&planid=" + encodeURIComponent(planId);
    if (urlObj.query && urlObj.query.edit === "true") {
      href += "&edit=true";
    }
    if (urlObj.query && urlObj.query.locale) {
      href += "&locale=" + encodeURIComponent(urlObj.query.locale);
    }
    window.location.href = href;
  }

  _showOpenPlan(task, project: Project) {
    const promise = new Promise<void>((resolve,reject) => {
      if (Context.instance.spaceplanner.activePlan) {
        Context.instance.spaceplanner.activePlan.destroy();
      }
      const plan = new OfficePlan();
      Context.instance.spaceplanner.activePlan = plan;
      plan._init().then(() => {
        if (!task.cfgOn) {
          Topic.publish(Topic.ShowBackstage,{
            forceRefresh: true,
            tabKey: "openPlan"
          });
        }
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }
  private _showEmptyPlanMessage(plan: OfficePlan) {
    const { i18n, views: { floorFilter: { activeWidget } } } = Context.getInstance();
    const hasSitesLayer = hasLayer("site");
    let message = i18n.editor.noSitesMessage;
    let tab = "sites";
    let label = i18n.editor.sites;
    if (hasSitesLayer) {
      if (plan.isEmpty || !plan.hasSites) {
        message = i18n.editor.noSitesMessage;
        label = i18n.editor.sidebar.sites;
        tab = "sites";
      } else if (!plan.hasFacilities) {
        message = i18n.editor.noFacilitiesMessage;
        label = i18n.editor.sidebar.facilities;
        tab = "facilities";
      } else {
        message = i18n.editor.noLevelsMessage;
        label = i18n.editor.sidebar.levels;
        tab = "levels";
      }
    } else {
      if (plan.isEmpty || !plan.hasFacilities) {
        message = i18n.editor.noFacilitiesMessage;
        label = i18n.editor.sidebar.facilities;
        tab = "facilities";
      } else {
        message = i18n.editor.noLevelsMessage;
        label = i18n.editor.sidebar.levels;
        tab = "levels";
      }
    }
    const content = (
      <>
        <p>{message}</p>
        <div className="i-getting-started">
          <CalciteButton
            width="auto"
            title={label}
            onClick={() => {
              closeModal();
              Rdx.setValue(null, EDITOR_ACTIVE_SIDEBAR_TAB, tab);
            }}>
            {label}
          </CalciteButton>
        </div>
      </>
    );
    const options = {
      title: i18n.editor.getStartedTitle,
      message: content,
      hideOkCancel: true
    };
    let closeModal;
    modalUtil.confirm(options, close => closeModal = close).then(result => {
      if (result === true) {
        Rdx.setValue(null, EDITOR_ACTIVE_SIDEBAR_TAB, "sites");
      }
    });
  }
  _showPlanIssues(task,project,plan) {
    const i18n = Context.instance.i18n;
    const title = i18n.spaceplanner.issues.unableToOpenPlanTitle;
    const content = Context.instance.useCalcite() 
                    ? <MiniappsIssues issues={plan.issues} /> 
                    : <Issues issues={plan.issues} />
    ModalController.confirm({
      title: title,
      content: content,
      hideCancel: true
    }).then(result => {
      this._showOpenPlan(task,project);
    });
  }

  _showProjectIssues(task,project) {
    const i18n = Context.instance.i18n;
    //const title = i18n.spaceplanner.issues.projectIssues;
    const title = i18n.spaceplanner.issues.requiredLayersMissing;

    if (Context.instance.isFPE()) {
      if (Context.instance.uiMode.isConfiguratorOn) return;
      const item = Context.instance.configuration.appItem;
      if (item && Context.instance.user.canEditPortalItem(item)) {
        Context.instance.uiMode.toggleConfigurator(true);
        Topic.publish(Topic.ConfiguratorActivated,{});
        return;
      }
      throw new Error(title);
    }

    const content = Context.instance.useCalcite() 
                    ? <MiniappsIssues issues={project.issues} /> 
                    : <Issues issues={project.issues} />
    ModalController.confirm({
      title: title,
      content: content
    }).then(result => {
      this._showOpenPlan(task,project);
    });

  }

}
