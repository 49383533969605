import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import ItemBrowser from "../../common/ItemBrowser/ItemBrowser";
import ModalController from "../../common/Modal/ModalController";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as configUtil from "./configUtil";
import * as val from "../../../util/val";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  issues: "i-issues",
  issue: "i-issue",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  //secondaryAction: "i-button i-button-clear",
  secondaryAction: "i-button-clear-no-border i-font-brand-primary"
};

export default class WebMapSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      viewsReloading: false
    });
    this.browseClicked = this.browseClicked.bind(this);
  }

  browseClicked() {
    const controller = new ModalController();
    const props = {
      title: Context.instance.i18n.configurator.primary.webmap,
      itemType: "Web Map",
      rdxKey: "cfg-webmap",
      onSelect: (item) => {
        const cfg = this.props.cfg;
        cfg.webmapItem = item;
        cfg.setConfigurable("webmap",item.id);
        Topic.publish(Topic.ReloadViews,{type: 'webMap'});
        controller.close();
      }
    }
    const content = (
      <ItemBrowser itemType={props.itemType}
        rdxKey={props.rdxKey} onSelect={props.onSelect} />
    );
    controller.show(content,props.title,"i-configurator-modal");
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ReloadViews,params => {
        this.setState(state => {
          return {
            viewsReloading: true
          };
        });
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.setState(state => {
          return {
            viewsReloading: false
          };
        });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const cfg = this.props.cfg;
    const item = cfg.webmapItem;

    let browseLabel = i18n.configurator.add;
    let itemTitleNode = null;
    if (item) {
      browseLabel = i18n.configurator.change;
      const itemTitle = (item && item.title) || "";
      itemTitleNode = (
        <div key="itemTitle">{itemTitle}</div>
      );
    }

    const issues = this.renderIssues();
    const detailsLink = configUtil.renderDetailsLink(item);

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName+" "+CSS.required}
            >{i18n.configurator.primary.webmap}</span>
          <HelpTip text={i18n.configurator.primary.webmapHelp}/>
        </div>
        {itemTitleNode}
        {issues}
        <div key="actions" className={CSS.actions}>
          <button type="button" className={CSS.secondaryAction}
            onClick={this.browseClicked}>{browseLabel}</button>
          {detailsLink}
        </div>
      </div>
    );
  }

  renderIssues() {
    if (this.state.viewsReloading) return null;
    const i18n = Context.instance.i18n;
    const aiim = Context.instance.aiim;
    const datasets = aiim && aiim.datasets;
    let list = [];

    const append = (k,s) => {
      list.push(
        <div key={k} className={CSS.issue}>{s}</div>
      );
    };

    if (Context.instance.views.mapView) {

      const cimCategories = datasets && datasets.cimCategories;
      const categories = datasets && datasets.categories;
      const hasCIMCategories = cimCategories && cimCategories.hasData();
      if (!hasCIMCategories && (!categories || !val.isNonEmptyStr(categories.url))) {
        append("noCategories",i18n.configurator.issues.noCategories);
      } else {
        let hasCategories = false, hasSources = false;
        const sources = categories && categories.sources;
        if (sources) {
          sources.forEach(source => {
            if (!hasCategories && source.url && source.categoryLevel === 1) {
              hasCategories = true;
            }
            if (!hasSources && source.layer2D) {
              hasSources = true;
            }
          });
        }
        if (!hasCategories) {
          append("noCategories",i18n.configurator.issues.noCategories);
        }
        if (!hasSources) {
          append("noSearchSources",i18n.configurator.issues.noSearchSources);
        }
      }

      // const indoorsConfig = datasets && datasets.indoorsConfig;
      // if (!indoorsConfig || !val.isNonEmptyStr(indoorsConfig.url)) {
      //   if (!hasCIMCategories) {
      //       append("noIndoorsConfigTable",i18n.configurator.issues.noIndoorsConfigTable);
      //   }
      // }

      if (!datasets || !datasets.units) {
        append("noUnits",i18n.configurator.issues.noUnits);
      }
      if (!datasets || !datasets.levels) {
        append("noLevels",i18n.configurator.issues.noLevels);
      }
      if (!datasets || !datasets.facilities) {
        append("noFacilities",i18n.configurator.issues.noFacilities);
      }
    }

    if (list.length > 0) {
      return (
        <div key="issues" className={CSS.issues}>
          {list}
        </div>
      );
    }
    return null;
  }

}
