import React from "react";

import ChevronLeft from "calcite-ui-icons-react/ChevronLeftIcon";
import ChevronRight from "calcite-ui-icons-react/ChevronRightIcon";

import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import Icons from "../../../components/util/Icons";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

export default class StepNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.nextClicked = this.nextClicked.bind(this);
    this.prevClicked = this.prevClicked.bind(this);
    this.stepClicked = this.stepClicked.bind(this);
  }

  activeStepNumber() {
    // step keys are named "step-n"
    return Number(this.props.cfg.activeStepKey.substring(5));
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  nextClicked() {
    const num = this.activeStepNumber();
    const numSteps = this.props.numSteps;
    if (num < numSteps) {
      this.props.cfg.activeStepKey = "step-" + (num + 1);
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

  prevClicked() {
    const num = this.activeStepNumber();
    if (num > 1) {
      this.props.cfg.activeStepKey = "step-" + (num - 1);
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const numSteps =this.props.numSteps;
    const cfg = this.props.cfg;
    const activeStepKey = cfg.activeStepKey;
    const canPassStep1 = !!Context.instance.views.mapView; // pass in props?
    const isFirst = (activeStepKey === "step-1");
    const isLast = (activeStepKey === ("step-"+numSteps));

    if (numSteps < 2) return null;

    let prevIcon, nextIcon;
    if (!Context.instance.uiMode.isRtl) {
      prevIcon = <ChevronLeft />;
      nextIcon = <ChevronRight />;
    } else {
      prevIcon = <ChevronRight />;
      nextIcon = <ChevronLeft />;
    }
    const prevStyle = isFirst ? {visibility: "hidden"} : {visibility: "visible"};
    const nextStyle = isLast ? {visibility: "hidden"} : {visibility: "visible"};
    const prevDisabled = isFirst
    const nextDisabled = isLast || !canPassStep1;

    const nodes = [];
    for (let i=1; i<=numSteps; i++) {
      let key = "step-"+i;
      let disabled = (i > 1 && !canPassStep1);
      let cls = "i-button-flat";
      if (key === activeStepKey) cls += " i--active";
      let node = (
        <button key={key} type="button" className={cls} disabled={disabled}
          data-i-stepkey={key} onClick={this.stepClicked}
          ><span>{Icons.ellipse2()}</span></button>
      );
      nodes.push(node);
    }
    return (
      <div className="i-configurator-nav">
        <Button clear small style={prevStyle} disabled={prevDisabled}
          title={i18n.configurator.previous}
          onClick={this.prevClicked}>{prevIcon}</Button>
        <span className={"i--steps"}>
          {nodes}
        </span>
        <Button clear small style={nextStyle} disabled={nextDisabled}
          title={i18n.configurator.next}
          onClick={this.nextClicked}>{nextIcon}</Button>
      </div>
    );
  }

  stepClicked(evt) {
    let key;
    let target = evt && evt.target;
    while (true) {
      if (!target) break;
      let tag = target.tagName;
      if (!tag || tag.toLowerCase() === "button") break;
      target = target.parentNode;
    }
    if (target) key = target.getAttribute("data-i-stepkey");
    console.log("stepClicked.key",key)
    if (key) {
      this.props.cfg.activeStepKey = key;
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

}
