import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import QueryAll from "../../spaceplanner/base/QueryAll";
import * as aiimUtil from "../util/aiimUtil";
import * as searchUtil from "../util/searchUtil";
import * as selectionUtil from "../util/selectionUtil";
import { getMeetingBookingSystemType } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as equipmentFilterUtil from "../util/equipmentFilterUtil";
import type Source from "../base/Source";
export interface ISiteFacilityLevelStrings {
  siteName: string,
  facilityName: string,
  levelNumber: number,
  facilityLevel: string
}
export default class Units extends Dataset {

  requiresFacilityMode = true;
  type = "feature";

  areaIDField = FieldNames.UNITS_AREA_ID;
  asnTypeField = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;
  uidField = FieldNames.UNIT_ID;
  capacityField = FieldNames.UNIT_CAPACITY;
  useTypeField = FieldNames.UNITS_USE_TYPE;
  // Don't think this is set or used anywhere but MeetingRoomsModel references it (possibly erroneously)
  // In MeetingRoomsModel, consider using Units.getSource() instead.
  source?: Source;
  checkSchema(): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      if (this.layer2D) {
        let layer = this.layer2D;
        layer.when(() => {
          this.checkFieldNameProperty("areaIDField",layer.fields);
          this.checkFieldNameProperty("asnTypeField",layer.fields);
          this.checkFieldNameProperty("uidField",layer.fields);
          this.checkFieldNameProperty("capacityField",layer.fields);
          this.checkFieldNameProperty("useTypeField",layer.fields);
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  isMeetingRoom(source,feature) {
    let b = false;
    const isUnits = (source && source.key && (source.key.toLowerCase() === "units" || source.key.toLowerCase() === "_units"));
    if (source && source.layer2D && isUnits && feature) {
      const areaId = aiimUtil.getAttributeValue(feature.attributes,FieldNames.AREA_ID);
      const asnType = aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
      const isAreaMeetingRoom = (!!areaId && (asnType === "meeting room"));
      const bookingSystemType = getMeetingBookingSystemType();
      if (bookingSystemType === "office365") {
        const email = aiimUtil.getAttributeValue(feature.attributes,FieldNames.SCHEDULE_EMAIL);
        if (email && isAreaMeetingRoom) b = true;
        else if (email && !areaId && (asnType !== "office")) b = true; // backwardly compatible meeting rooms
      } else {
        const meth = aiimUtil.getAttributeValue(feature.attributes,FieldNames.RESERVATION_METHOD);
        if (isAreaMeetingRoom) b = true;
        else if (!areaId && (meth === 1 || meth === 2) && (asnType !== "office")) b = true; // backwardly compatible meeting rooms
      }
    }
    return b;
  }

  getEquipmentList(unitFeature, domain) {
    const equipmentFields = equipmentFilterUtil.getEquipmentFields(domain);
    const equipmentFiltered = [];
    equipmentFields && equipmentFields.forEach((fieldName)=> {
      const isEquipmentAvailable = aiimUtil.getAttributeValue(unitFeature.attributes, fieldName);
      if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
        if(!equipmentFiltered.includes(fieldName)) {
          const alias = equipmentFilterUtil.getAliasName(fieldName) || fieldName;
          equipmentFiltered.push(alias);
        }
      }
    })
    return equipmentFiltered;
  }

  getLevelData(unitFeature) {
    let zInfo;
    const levels = Context.instance.aiim.datasets.levels;
    const source = this.getSource();
    if (source && levels) zInfo = levels.getZInfo(source,unitFeature);
    return zInfo && zInfo.levelData;
  }

  getSiteFacilityLevelStrings(source: Source, feature: __esri.Graphic): ISiteFacilityLevelStrings {
    let siteName, facilityName, levelNumber, levelName;
    const zInfo = Context.instance.aiim.getZInfo(source,feature);
    const levelData = zInfo && zInfo.levelData
    const attributes = feature&& feature.attributes;
    const i18n = Context.instance.i18n;
    if (levelData) {
      siteName = levelData.siteName;
      facilityName = levelData.facilityName;
      levelNumber = levelData.levelNumber;
      levelName = levelData.levelShortName;  // levelData.levelName
      if (!siteName) siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      //console.log("siteName",siteName,"facilityName",facilityName,"levelNumber",levelNumber)
    } else {
      siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      facilityName = aiimUtil.getAttributeValue(attributes,FieldNames.FACILITY_NAME);
      levelNumber = aiimUtil.getAttributeValue(attributes,FieldNames.LEVEL_NUMBER);
    }

    let facilityLevel = null;
    if (facilityName && typeof levelNumber === "number") {
      const pattern = i18n.spaceplanner.units.facilityFloorPattern;
      facilityLevel = pattern.replace("{facility}", facilityName);
      if (levelName) {
        facilityLevel = facilityLevel.replace("{level}", levelName);
      } else {
        facilityLevel = facilityLevel.replace("{level}", levelNumber);
      }
    } else {
      facilityLevel = facilityName || levelNumber || null;
    }
    return {
      siteName: siteName,
      facilityName: facilityName,
      levelNumber: levelNumber,
      facilityLevel: facilityLevel
    }
  }

  getSource() {
    return Context.getInstance().aiim.datasets.categories.findSourceByKey("Units"); // TODO
  }

  queryByGeometry(geometry: __esri.Geometry, meters?: number, levelId?: string) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    query.geometry = geometry;
    if (typeof meters === "number" && meters > 0) {
      query.distance = meters;
      query.units = "meters";
    }
    if (levelId) {
      let fld = Context.instance.aiim.getLevelIdField(this.layer2D);
      if (fld) {
        let v = levelId;
        if (fld.type === "string" && typeof v === "string") {
          v = "'"+selectionUtil.escSqlQuote(v)+"'";
        }
        let levelPart = "("+fld.name+" = "+v+")";
        query.where = levelPart;
      }
    }
    return task.execute(query);
  }

  queryById(unitId) {
    let v = "'"+selectionUtil.escSqlQuote(unitId)+"'";
    let w = "("+this.uidField+" = "+v+")";
    return this.query(w);
  }

  queryAll(qaopts) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    qaopts = qaopts || {};
    if (qaopts.where) query.where = qaopts.where;
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts);
  }

  queryAllHotels(opts?) {
    let where = "("+this.asnTypeField+" = 'hotel')";
    if (opts && opts.assignedToAppUser) {
      let ids = Context.instance.aiim.getAppUserAreaIDs(true);
      if (ids.length === 0) {
        return Promise.resolve({features: []});
      }
      let values = [];
      ids.forEach(id => {
        let v = "'"+selectionUtil.escSqlQuote(id)+"'";
        values.push(v);
      });
      let w = "(" + this.areaIDField + " IN (" + values.join(",") + "))";
      where = "(" + where + " AND " + w + ")";
    }
    const qaopts = {
      where: where
    }
    return this.queryAll(qaopts);
  }

  queryPersonUnit(peopleFeature: any, peopleDataset: any): Promise<any> {
    if (!peopleFeature || !peopleDataset) return Promise.resolve();
    const source = this.getSource();
    const uid = peopleDataset.getUnitID(peopleFeature);
    return searchUtil.queryFieldValue(source,this.url,this.uidField,uid);
  }

  query(where) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"]
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = where || "1=1";
    return task.execute(query);
  }

  queryAttributes(where) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"]
    query.returnGeometry = false;
    query.returnZ = true;
    query.where = where || "1=1";
    return task.execute(query);
  }

}
