import styled from "styled-components";

import CalciteButton from "calcite-react/Button";

const Button = styled(CalciteButton)`
  padding: 0.31rem;
  color: ${props => props.theme.typeColor};

  &:hover {
    color: ${props => props.theme.palette.themeColor};
  }
`;

export { Button };
