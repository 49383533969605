import Context from "../../context/Context";
import ItemReference from "./ItemReference";
import Topic from "../../context/Topic";
import * as mapUtil from "../../util/mapUtil";
import { getAttributeValue } from "../util/aiimUtil";
import FieldNames from "../datasets/FieldNames";

export default class HomeLocation extends ItemReference {

  fromOfficeLocation = false;

  getLabel(): string {
    return Context.getInstance().i18n.yourPlaces.home;
  }

  queryLocation(autoSetLevel) {
    const context = Context.getInstance();
    const isAnonymous = context.user.isAnonymous();
    const people = context.aiim.datasets.people;
    const units = context.aiim.datasets.units;
    if (isAnonymous || !people || !units) {
      return;
    }
    Promise.resolve().then(() => {
      let info = Context.instance.aiim.appUserAssignments;
      let unitIDs = info && info.unitIDs;
      if (unitIDs && unitIDs.length === 1) {
        return units.queryById(unitIDs[0]);
      } else {
        return null;
      }
    }).then(result => {
      if (result && result.features && result.features.length === 1) {
        const view = null;
        const source = units.getSource();
        const feature = result.features[0];
        const searchResult = source.makeSearchResult(view,feature);
        const ok = this.fromSearchResult(source.key,searchResult);
        if (ok && autoSetLevel) {
          this.setLevel();
        }
        if (ok) {
          this.fromOfficeLocation = true;
          Topic.publish(Topic.HomeLocationSet,{homeLocation: this});
        }
      }
    }).catch((ex) => {
      console.warn("Error querying home location:");
      console.error(ex);
    });
  }

  setLevel() {
    if (this.isValid() && this.searchResult) {
      const view = Context.getInstance().views.activeView;
      const feature = this.searchResult.feature;
      if (view && feature) {
        mapUtil.goToFeature(view,feature);
        Context.getInstance().aiim.facilityMode.setFromHome(this,feature,view);
      }
    }
  }

}
