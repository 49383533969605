import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import * as unitUtil from "../support/unitUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as stencilUtil from "./stencilUtil";
import { debounce } from "../../../miniapps/support/debounceUtil";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";

export interface IGridSettings {
  enabled: boolean,
  fixedLowerLeft: boolean,
  rotateWithMap: boolean,
  lengthAndWidthInfo: ILengthAndWidthInfo,
  rotationAngle: number, // counter-clockwise
  offsetX: number,
  offsetY: number
}

export interface IGridConstructorProps {
  gridSettings?: IGridSettings;
  view: __esri.MapView;
}

export default class Grid extends BaseClass {

  active: boolean = false;
  anchor: __esri.Point;
  features: __esri.Graphic[];
  gridSettings: IGridSettings;
  initialSettings: IGridSettings;
  layer: __esri.GraphicsLayer;
  layerId = "indoors-grid-layer";
  minScale = 500;
  view: __esri.MapView;
  visible: boolean = false

  constructor(props: IGridConstructorProps) {
    super(props);
    Object.assign(this,props);
    this.init();
    this.initialSettings = this.cloneSettings();
  }

  activate() {
    this.active = true;
    if (this.visible) this.layer.visible = true;
    //this.checkRefresh();
  }

  private checkRefresh() {
    const view = this.view;
    const layer = this.layer;
    const graphic = layer.graphics.getItemAt(0);
    if (!this.gridSettings.fixedLowerLeft) {
      if (graphic && graphic.geometry.extent.contains(view.extent)) return;
    }
    this.refresh();
  }

  cloneSettings(): IGridSettings {
    return JSON.parse(JSON.stringify(this.gridSettings));
  }

  deactivate() {
    this.active = false;
    this.layer.visible = false;
  }

  private getCellSize(): {cellX: number, cellY: number} {
    const cellX = this.gridSettings.lengthAndWidthInfo.lengthInMapUnits;
    const cellY = this.gridSettings.lengthAndWidthInfo.lengthInMapUnits;
    return { cellX, cellY }
  }

  private getOffsets(): { 
    startX: number, startY: number, endX: number, endY: number, offsetX: number, offsetY: number, offsetX2: number, offsetY2: number 
  } {
    const { anchor, gridSettings } = this;
    const { cellX, cellY } = this.getCellSize();
    const extent = this.view.extent.clone();

    let nX = Math.round(extent.width / cellX);
    let nY = Math.round(extent.height / cellY);

    let startX = extent.xmin;
    let startY = extent.ymin;
    let endX = startX + (nX * cellX);
    let endY = startY + (nY * cellY);

    let offsetX = 0;
    let offsetY = 0;
    let offsetX2 = 0;
    let offsetY2 = 0;
    
    if (!gridSettings.fixedLowerLeft) {
      offsetX = gridSettings.offsetX;
      offsetY = gridSettings.offsetY;
      
      let dX = Math.abs(extent.xmin - anchor.x);
      let dXC = (dX / cellX);
      let rX = ((dX / cellX) % 1);
      let dx = ((dX / cellX) % 1) * cellX;
      let dY = Math.abs(extent.ymin - anchor.y);
      let dy = ((dY / cellY) % 1) * cellY;

      if (extent.xmin > anchor.x) {
        //offsetX = offsetX - dx;
        offsetX2 = -dx;
      } else if (extent.xmin < anchor.x) {
        //offsetX = offsetX + dx;
        offsetX2 = dx;
      }
      if (extent.ymin > anchor.y) {
        //offsetY = offsetY - dy;
        offsetY2 = -dy;
      } else if (extent.ymin < anchor.y) {
        //offsetY = offsetY + dy;
        offsetY2 = dy;
      }
    }

    return { startX, startY, endX, endY, offsetX, offsetY, offsetX2, offsetY2 };
  }

  private init() {
    const view = this.view;
    const layer = this.layer = mapUtil.ensureGraphicsLayer(view,this.layerId,sourceUtil.getUnitsLayer());
    layer.minScale = this.minScale;

    this.active = true;
    this.visible = false;
    layer.visible = false;
    this.anchor = view.extent.center.clone();

    if (!this.gridSettings) {
      this.gridSettings = {
        enabled: true,
        fixedLowerLeft: false,
        rotateWithMap: true,
        lengthAndWidthInfo: unitUtil.newLengthAndWidthInfo("grid"),
        rotationAngle: 0,
        offsetX: 0,
        offsetY: 0
      }
    }

    const reactiveUtils = Context.instance.lib.esri.reactiveUtils;
    this.own([
      reactiveUtils.when(() => view.stationary === true, () => {
        if (this.active) this.checkRefresh();
      }),
      // reactiveUtils.when(() => view.extent, () => {
      //   if (this.active) this.checkRefresh();
      // }) 
      reactiveUtils.when(() => view.extent, debounce(() => {
        if (this.active) this.checkRefresh();
      }, 1)) // @todo 5 ?
    ])
  }

  isVisible() {
    return this.visible;
  }

  makeGraphic(polyline) {
    return new Context.instance.lib.esri.Graphic({
      geometry: polyline,
      symbol: this.makeLineSymbol()
    });
  }

  makeLineSymbol() {
    const symbol: any = {
      type: "simple-line",
      color: [104, 104, 104, 0.1],
      style: "solid",
      width: 1
    }
    //symbol.color = "orange";
    return symbol;
  }

  makeUpdateGeometry() {
    const extent = this.view.extent.clone();
    const center = extent.center;
    const n = 6;
    const d = Math.min(extent.width,extent.height);
    const x1 = center.x - (d / n);
    const x2 = center.x + (d / n);
    const y1 = center.y - (d / n);
    const y2 = center.y + (d / n);
    const cenX = center.x;
    const cenY = center.y;

    const pathX = [[x1,cenY,0],[x2,cenY,0]];
    const pathY = [[cenX,y1,0],[cenX,y2,0]];
    const polyline = new Context.instance.lib.esri.Polyline({
      spatialReference: extent.spatialReference,
      paths: [pathX,pathY]
    })
    return polyline;
  }

  private refresh() {
    const { gridSettings, layer, view } = this;
    const { cellX, cellY } = this.getCellSize();
    const bufferCount = gridSettings.fixedLowerLeft ? 1 : 100;

    if (!this.active || !gridSettings.enabled || !layer.visible || (view.scale > layer.minScale)) {
      if (layer.graphics.length > 0) layer.removeAll();
      return;
    }

    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const mapAngle = view.rotation;
    let extent = view.extent.clone();

    let { startX, startY, endX, endY, offsetX, offsetY, offsetX2, offsetY2 } = this.getOffsets();
    startX = startX - (bufferCount * cellX);
    startY = startY - (bufferCount * cellY);
    endX = endX + (bufferCount * cellX);
    endY = endY + (bufferCount * cellY);

    const gfx = [], paths = [];
    for (let x = startX; x <= endX; x += cellX) {
      paths.push([[x,startY,0],[x,endY,0]]);
    }
    for (let y = startY; y <= endY; y += cellY) {
      paths.push([[startX,y,0],[endX,y,0]]);
    }

    let polyline = new Context.instance.lib.esri.Polyline({
      paths: paths,
      spatialReference: view.spatialReference
    });
    let angle = gridSettings.fixedLowerLeft ? 0 : gridSettings.rotationAngle;
    if (mapAngle !== 0) {
      if (gridSettings.fixedLowerLeft || !gridSettings.rotateWithMap) {
        angle = angle + mapAngle; // @todo ???
      }
    }
    if (angle !== 0) {
      polyline = ge.rotate(polyline,angle,extent.center)
    }
    if (!gridSettings.fixedLowerLeft) {
      if (offsetX2 !== 0 || offsetY2 !== 0) {
        polyline = stencilUtil.translatePolyline(polyline,offsetX2,offsetY2,0)
      }
    }
    if (!gridSettings.fixedLowerLeft) {
      if (offsetX !== 0 || offsetY !== 0) {
        polyline = stencilUtil.translatePolyline(polyline,offsetX,offsetY,0)
      }
    }

    gfx.push(this.makeGraphic(polyline));
    layer.removeAll();
    layer.addMany(gfx)
  }

  reset() {
    const enabled = this.gridSettings.enabled;
    this.gridSettings = JSON.parse(JSON.stringify(this.initialSettings));
    this.gridSettings.enabled = enabled;
    this.refresh();
  }

  setEnabled(value: boolean) {
    this.gridSettings.enabled = !!value;
    this.setVisible(!!value);
    this.refresh();
  }

  setFixedLowerLeft(value: boolean) {
    this.gridSettings.fixedLowerLeft = !!value;
    if (this.gridSettings.fixedLowerLeft) {
      this.gridSettings.rotationAngle = 0;
      this.gridSettings.offsetX = 0;
      this.gridSettings.offsetY = 0;
    }
    this.refresh();
  }

  setLengthAndWidthInfo(lengthAndWidthInfo: ILengthAndWidthInfo) {
    this.gridSettings.lengthAndWidthInfo = lengthAndWidthInfo;
    this.refresh();
  }

  setOffsets(dx: number, dy: number) {
    this.gridSettings.offsetX = dx;
    this.gridSettings.offsetY = dy;
    this.refresh();
  }

  setRotateWithMap(value: boolean) {
    this.gridSettings.rotateWithMap = !!value;
    this.refresh();
  }

  setRotationAngle(value: number) {
    this.gridSettings.rotationAngle = value;
    this.refresh();
  }

  setVisible(value: boolean) {
    this.visible = !!value;
    this.layer.visible = this.visible;
    if (this.visible && this.layer.graphics.length === 0) this.refresh();
  }

}