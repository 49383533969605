import React from "react";
import { connect } from "react-redux";
import { getActiveSection } from "../../../redux";
import Topic from "../../../../context/Topic";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as component from "../../../../components/util/component";
import OfficePlan from "../../../base/OfficePlan";
import Context from "../../../../context/Context";
import { PopupPanel } from "../../../styles/Common/panel";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemTitleIcon,
  ListItemTitleMain,
  ListItemTitleAside,
} from "../../../styles/Common/list";
import Button from "../../../../common/components/Button/index";
import Skeleton from "react-loading-skeleton";
import DeleteIcon from "calcite-ui-icons-react/XIcon";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import Popup from "./Popup";
import UnassignPrompt from "../UnassignPrompt";
import { ModalController } from "../../../../common/components/Modal";
import TransactionGuard from "../../../base/TransactionGuard";
import RemoveButton from "./RemoveButton";
import { AssignmentsContent } from "../../../styles/popupStyles";
import AreaAssignTo from "../AreaAssignTo";
import ContentLargeIcon from "calcite-ui-icons-react/ContentLargeIcon";
import MeasureAreaIcon from "calcite-ui-icons-react/MeasureAreaIcon";
import UsersIcon from "calcite-ui-icons-react/UsersIcon";
import { canDeleteDuplicate } from "../MultipleAssignments/multipleAssignmentsUtil";
import Rdx from "../../../../redux/Rdx";

import FloorPlanIcon from "calcite-ui-icons-react/FloorPlanIcon";
import HotDeskIcon from "calcite-ui-icons-react/DeskIcon";
import HotelIcon from "calcite-ui-icons-react/ServicesIcon";
import MeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";

const tabs = {
  people: "people",
  units: "units",
  properties: "properties"
}

class AreaPopup extends React.Component {
  _mounted = false

  constructor(props) {
    super(props)
    this.state = component.newState({
      spaceplannerActiveTab: this.props.activeSection,
      peopleAssigned: [],
      unitsAssigned: [],
      peopleQuerying: true,
      unitsQuerying: true,
      activeTab: tabs.people
    })
  }

  _focus = () => {
    const popupCloseBtn = document.getElementsByClassName("esri-popup__button")
    if (popupCloseBtn && popupCloseBtn.length === 1) {
      popupCloseBtn[0].focus()
    }
    const popupTitle = document.getElementsByClassName("esri-popup__header-title")
    if (popupTitle && popupTitle.length === 1) {
      popupTitle[0].setAttribute("aria-live", "polite")
    }
  }

  componentDidMount() {
    this._mounted = true
    setTimeout(this._focus, 100)
    this.queryAssignments()

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, (params) => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.setState({
            peopleQuerying: true,
            unitsQuerying: true
          })
          this.queryAssignments()
        }
      }),

      Topic.subscribe(Topic.ActiveTabChanged, (params) => {
        if (typeof params.tab === "string") {
          this.setState({
            spaceplannerActiveTab: params.tab
          })
        }
      })
    ]);
  }

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this)
  }

  isViewOnly = () => {
    const tab = this.state.spaceplannerActiveTab
    return tab === "review" || tab === "publish"
  }

  changeActiveTab(e, title) {
    this.setState({
      activeTab: title
    })
  }

  queryAssignments() {
    const areaItem = this.props.areaItem
    if (areaItem) {
      officePlanUtil
      .queryPeopleAssignedToArea(areaItem)
      .then(result => {
        if (!this._mounted) return
        this.setState({
          peopleAssigned: result,
          peopleQuerying: false
        })
      })
      .catch(e => {
        console.error("Error querying people assigned to area", e)
        this.setState({peopleQuerying: false})
      })
    officePlanUtil
      .queryUnitsAssignedToArea(areaItem)
      .then(result => {
        if (!this._mounted) return
        this.setState({
          unitsAssigned: result,
          unitsQuerying: false
        })
      })
      .catch(e => {
        console.error("Error querying units assigned to area", e)
        this.setState({unitsQuerying: false})
      })
    }
  }

  render() {
    return (
      <PopupPanel id="feature-popup" key="info">
        {this.renderLabels()}
        {this.renderContent()}
      </PopupPanel>
    );
  }

  renderLabels() {
    const lib = Context.instance.lib
    const i18n = Context.instance.i18n
    const people = this.state.peopleAssigned
    const units = this.state.unitsAssigned

    const styleLeft = {justifyContent: "flex-start"}
    const styleCenter = {justifyContent: "center"}
    const styleRight = {justifyContent: "flex-end"}

    const peopleCount = people && people.length
    // const peopleLabel = i18n.spaceplanner.popup.peopleLabel.replace("{count}", peopleCount)
    const peopleLabel = peopleCount

    let totalCapacity = 0
    let totalArea = 0
    units.forEach(unit => {
      const attributes = unit.feature && unit.feature.attributes
      let capacity = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_CAPACITY
      );
      let area = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_AREA_GROSS
      );
      if (typeof capacity !== "number") capacity = 0
      if (typeof area !== "number") area = 0
      totalCapacity += capacity
      totalArea += area
    })
    if (typeof totalArea === "number") {
      totalArea = lib.dojo.number.format(totalArea, { places: 0 })
    }
    // const capacityLabel = i18n.spaceplanner.popup.capacity.replace("{count}", totalCapacity)
    const capacityLabel = totalCapacity
    // const areaLabel = i18n.spaceplanner.popup.area
    //   .replace("{count}", totalArea)
    //   .replace("{unit}", "ft") // Open up to meters?
    const areaLabel = totalArea

    return (
      <div className={"i-popup-labels"}>
        <div className={"i-label-group"} style={styleLeft}>
          <h5 className={"i-label"}>{i18n.spaceplanner.areaStats.people}</h5>
          <div className={"i-icon"}><UsersIcon/></div>
          <h5 className={"i-label"}>{peopleLabel}</h5>
        </div>
        <div className={"i-label-group"} style={styleCenter}>
          <h5 className={"i-label"}>{i18n.spaceplanner.areaStats.capacity}</h5>
          <div className={"i-icon"}><ContentLargeIcon/></div>
          <h5 className={"i-label"}>{capacityLabel}</h5>
        </div>
        <div className={"i-label-group"} style={styleRight}>
          <h5 className={"i-label"}>{i18n.spaceplanner.areaStats.area}</h5>
          <div className={"i-icon"}><MeasureAreaIcon/></div>
          <h5 className={"i-label"}>{areaLabel}</h5>
        </div>
      </div>
    )
  }

  renderContent() {
    return (
      <div>
        <div className={"i-current-content"}>
        {this.renderPeople()}
        {this.renderUnits()}
        {this.renderProperties()}
        </div>
        {this.renderTabs()}
      </div>
    )
  }

  renderPeople() {

    if (this.state.activeTab !== tabs.people) return null
    const i18n = Context.instance.i18n
    const people = this.state.peopleAssigned
    const areaItem = this.props.areaItem
    const assignBtn = (
      <AreaAssignTo areaItem={areaItem}
        tab={tabs.people} isPopup={true} />
    )
    const buttons = !this.isViewOnly() ? (
      <div className={"i-btns"}>
        {assignBtn}
        <RemoveButton type={"workspaceArea"}
          tab={this.state.activeTab}
          peopleFeatures={this.state.peopleAssigned}
          unitFeatures={this.state.unitsAssigned}
          areaItem={areaItem}
          feature={areaItem}/>
      </div>
    ) : null
    const header = (
      <div className={"i-assignments-header"}>
        <h3>{i18n.spaceplanner.popup.peopleAssigned}</h3>
        {buttons}
      </div>
    )

    const assignments = Array.isArray(people) ? (
      people.map((item, index) => (
        this.renderPersonItem(item, index)
      ))
    ) : ( <Skeleton count={2} /> )

    let content = null
    if (((people && people.length === 0) || !people) && !this.state.peopleQuerying) {
      content = (
        <List>
          <ListItem unassigned={true}>
            <ListItemTitle>
              <ListItemTitleMain>{i18n.spaceplanner.popup.noPeopleAssigned}</ListItemTitleMain>
            </ListItemTitle>
          </ListItem>
        </List>
      )
    } else if (people && people.length > 0 && !this.state.peopleQuerying) {
      content = (
        <AssignmentsContent>
          {assignments}
        </AssignmentsContent>
      )
    }

    return (
      <div id="people" className={"i-tab-content"}>
        {header}
        {content}
      </div>
    )
  }

  renderUnits() {
    if (this.state.activeTab !== tabs.units) return null
    const i18n = Context.instance.i18n
    const units = this.state.unitsAssigned
    const areaItem = this.props.areaItem
    const assignBtn = (
      <AreaAssignTo areaItem={areaItem}
        tab={tabs.units} isPopup={true} />
    )
    const buttons = !this.isViewOnly() ? (
      <div className={"i-btns"}>
        {assignBtn}
        <RemoveButton type={"workspaceArea"}
          tab={this.state.activeTab}
          peopleFeatures={this.state.peopleAssigned}
          unitFeatures={this.state.unitsAssigned}
          areaItem={areaItem}
          feature={areaItem} />
      </div>
    ) : null
    const header = (
      <div className={"i-assignments-header"}>
        <h3>{i18n.spaceplanner.popup.unitsAssigned}</h3>
        {buttons}
      </div>
    )

    const assignments = Array.isArray(units) ? (
      units.map((item, index) => (
        this.renderUnitItem(item, index)
      ))
    ) : ( <Skeleton count={2} /> )

    let content = null
    if (((units && units.length === 0) || !units) && !this.state.unitsQuerying) {
      content = (
        <List>
          <ListItem unassigned={true}>
            <ListItemTitle>
              <ListItemTitleMain>{i18n.spaceplanner.popup.noUnitsAssigned}</ListItemTitleMain>
            </ListItemTitle>
          </ListItem>
        </List>
      )
    } else if (units && units.length > 0 && !this.state.unitsQuerying) {
      content = (
        <AssignmentsContent>
          {assignments}
        </AssignmentsContent>
      )
    }

    return (
      <div id="units" className={"i-tab-content"}>
        {header}
        {content}
      </div>
    )
  }

  confirmDelete = (subject, item, from, fromItem) => {
    const i18n = Context.instance.i18n;
    const promptOptions = {
      subject: subject,
      subjectItem: item,
      from: from,
      fromItem: fromItem,
    };
    const content = <UnassignPrompt promptOptions={promptOptions} />;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: i18n.spaceplanner.unassignPrompt.removeAssignmentTitle,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        if (subject === "person") {
          const personAttributes = item && item.feature && item.feature.attributes
          const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
          const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
          canDeleteDuplicate(personName, personEmail).then(result => {
            if (result) {
              this.deleteDuplicatePerson({person: item, associatedUnit: null, associatedArea: fromItem})
            } else {
              this.deletePerson(item)
            }
          })
        }
        if (subject === "unit") this.deleteUnit(item)
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  deletePerson = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([personItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  deleteDuplicatePerson = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .deletePeople([personItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  deleteUnit = (unitItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignUnits([unitItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          queryCriteriaKey: this.unitsQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning units", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  renderPersonItem = (item, index) => {
    const i18n = Context.instance.i18n;
    const field = FieldNames.PEOPLE_FULLNAME;
    let addClass = false;

    const dragStart = (evt) => {
      addClass = true;
      const source = officePlanUtil.getPeopleSource();
      evt.dataTransfer.dropEffect = "copy";  // copy move link
      Topic.publish(Topic.DragItemStart, {
        sourceKey: source.key,
        featureItem: item
      });
    };

    const dragging = () => {
      if (addClass) {
        document.body.classList.add("i-drag-from-popup");
        addClass = false;
      }
    }

    const dragEnd = () => {
      addClass = false;
      const source = officePlanUtil.getPeopleSource();
      Topic.publish(Topic.DragItemEnd, {
        sourceKey: source.key,
        featureItem: item
      });
      setTimeout(() => {
        document.body.classList.remove("i-drag-from-popup");
      }, 1000);
    }

    const openPersonPopup = (e, personFeature) => {
      const attributes = personFeature.feature.attributes
      const source = officePlanUtil.getPeopleSource()
      const name = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: personFeature,
        fetchFeatureItemGeometry: false,
        zoom: true,
        content: <Popup sourceKey={source.key} featureItem={personFeature} isPerson={true} />
      })
    }

    const deleteButton = !this.isViewOnly() ? (
      <ListItemTitleAside>
        <Button
          iconButton={true}
          icon={<DeleteIcon />}
          style={{ marginLeft: "10px" }}
          title={i18n.spaceplanner.unassign}
          onClick={(evt) => {
            if (evt) evt.stopPropagation();
            this.confirmDelete("person", item, this.props.type, this.props.areaItem);
          }}
        />
      </ListItemTitleAside>
    ) : null

    return this.isViewOnly() ? (
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
      onClick={e => {openPersonPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    ) : (
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
      draggable onDragStart={dragStart} onDrag={dragging} onDragEnd={dragEnd}
      onClick={e => {openPersonPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    );
  }

  renderUnitItem = (item, index) => {
    const i18n = Context.instance.i18n;
    const field = Context.instance.aiim.getUnitName();

    const asnType = aiimUtil.getAttributeValue(item.feature.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    let unitIcon = <FloorPlanIcon />
    if (asnType === OfficePlan.SpaceAssignmentTypes.hotdesk) {
      unitIcon = <HotDeskIcon />
    } else if (asnType === OfficePlan.SpaceAssignmentTypes.hotel) {
      unitIcon = <HotelIcon />
    } else if (asnType === OfficePlan.SpaceAssignmentTypes.meetingroom) {
      unitIcon = <MeetingRoomIcon />
    }

    const openUnitPopup = (e, unitFeature) => {
      const attributes = unitFeature.feature.attributes
      const source = officePlanUtil.getUnitsSource()
      const name = aiimUtil.getAttributeValue(attributes, field)
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: unitFeature,
        fetchFeatureItemGeometry: true,
        zoom: true,
        content: <Popup sourceKey={source.key} featureItem={unitFeature} isPerson={false} />
      })
    }

    const deleteButton = !this.isViewOnly() ? (
      <ListItemTitleAside>
        <Button
          iconButton={true}
          icon={<DeleteIcon />}
          style={{ marginLeft: "10px" }}
          title={i18n.spaceplanner.unassign}
          onClick={(evt) => {
            if (evt) evt.stopPropagation();
            this.confirmDelete("unit", item, this.props.type, this.props.areaItem);
          }}
        />
      </ListItemTitleAside>
    ) : null

    return(
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
        onClick={e => {openUnitPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleIcon lowerIcon brand>
          {unitIcon}
        </ListItemTitleIcon>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    );
  }

  renderProperties() {
    if (this.state.activeTab !== tabs.properties) return null
    return (
      <div id="properties" className={"i-tab-content"}>
        {this.makePopupTemplate()}
      </div>
    )
  }

  makePopupTemplate() {
    const i18n = Context.instance.i18n
    const areaItem = this.props.areaItem
    const attributes = areaItem.attributes
    const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
    const areaName = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_NAME)
    const areaType = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_TYPE)
    return (
      <table className={"i-area-table"}>
        <tbody>
          <tr>
            <th>{i18n.spaceplanner.popup.areaId}</th>
            <td>{areaId}</td>
          </tr>
          <tr>
            <th>{i18n.spaceplanner.popup.areaName}</th>
            <td>{areaName}</td>
          </tr>
          <tr>
            <th>{i18n.spaceplanner.popup.areaType}</th>
            <td>{areaType}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderTabs() {
    const i18n = Context.instance.i18n
    const activeTab = this.state.activeTab

    const clsPeople = activeTab === tabs.people ? "i-tab i-tab-active" : "i-tab"
    const clsUnits = activeTab === tabs.units ? "i-tab i-tab-active" : "i-tab"
    const clsProperties = activeTab === tabs.properties ? "i-tab i-tab-active" : "i-tab"

    const people = this.state.peopleAssigned
    const units = this.state.unitsAssigned
    let peopleLabel = i18n.spaceplanner.popup.people
    if (people && people.length > 0) {
      peopleLabel = i18n.spaceplanner.popup.peopleCount.replace("{count}", people.length)
    }
    let unitsLabel = i18n.spaceplanner.popup.units
    if (units && units.length > 0) {
      unitsLabel = i18n.spaceplanner.popup.unitsCount.replace("{count}", units.length)
    }

    const tabPeople = (
      <button id="tab-people" onClick={e => this.changeActiveTab(e, tabs.people)}
        aria-selected={activeTab === tabs.people}
        className={clsPeople} role="tab">{peopleLabel}</button>
    )
    const tabUnits = (
      <button id="tab-units" onClick={e => this.changeActiveTab(e, tabs.units)}
        aria-selected={activeTab === tabs.units}
        className={clsUnits} role="tab">{unitsLabel}</button>
    )
    const tabProperties = (
      <button id="tab-properties" onClick={e => this.changeActiveTab(e, tabs.properties)}
        aria-selected={activeTab === tabs.properties}
        className={clsProperties} role="tab">{i18n.spaceplanner.popup.properties}</button>
    )

    return (
      <nav className={"i-popup-tabs"}>
        {tabPeople}
        {tabUnits}
        {tabProperties}
      </nav>
    )
  }
}

const mapStateToProps = (state) => ({
  activeSection: getActiveSection(state)
})
export default connect(mapStateToProps)(AreaPopup);