import BaseClass from "../../../util/BaseClass"
import Context from "../../../context/Context";
import DirectionsUtil from "../../../util/DirectionsUtil";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as maneuverTypes from "./maneuverTypes";
import * as mapUtil from "../../../util/mapUtil";

/*

directionPoints
"attributes": {
  "ObjectID": 1,
  "RouteID": 1,
  "Sequence": 1,
  "ArrivalTime": 1536605920334,
  "ArrivalUTCOffset": 0,
  "Name": "Home_#10004",
  "StopID": 1,
  "DirectionPointType": 51,
  "DisplayText": "Start at Home_#10004",
  "Azimuth": 0.0,
  "Level": 1
},

directionLines
"attributes": {
  "ObjectID": 1,
  "RouteID": 1,
  "DirectionPointID": 2,
  "DirectionLineType": 1,
  "Meters": 65.139878433383174,
  "Minutes": 0.81501187453393109,
  "FromLevel": 1,
  "ToLevel": 1
},
 */

export default class RouteResult extends BaseClass {
  fullDistanceUnit: string;

  considerTransition = false;
  response;
  route;
  routeName;
  sections;
  queryDistanceMeters = 0.1;
  timeoutHandle;
  timestamp = Date.now();
  totalDistanceText;
  totalDistanceOnly;
  totalTimeText;
  util = new DirectionsUtil();
  viewModel;

  constructor(props) {
    super(props);
    this.viewModel = props.viewModel;
    this.response = props.response;
  }
  addRouteGraphics(view,graphics) {
    const lib = Context.getInstance().lib;
    graphics.removeAll();
    if (this.sections && this.sections.length > 0) {
      const is2D = (view.type === "2d");
      this.sections.forEach(section => {
        const levelInfo = {
          symbolKey: "indoorsRouteSymbol",
          section: section
        };
        const feature = section.feature.clone();
        let symbol = this.viewModel.indoorsRouteSymbol;
        if (is2D) {
          const matched = this._isMatching2DLevel(levelInfo.section);
          if (!matched) {
            symbol = this.viewModel.indoorsRouteSymbolAlternate;
            levelInfo.symbolKey = "indoorsRouteSymbolAlternate";
          }
        } else {
          symbol = this.viewModel.indoorsRouteSymbolFor3D;
          levelInfo.symbolKey = "indoorsRouteSymbolFor3D";
        }
        const graphic = new lib.esri.Graphic({
          attributes: feature.attributes,
          geometry: feature.geometry,
          symbol: symbol
        });
        graphic.xtnLevelInfo = levelInfo;
        graphics.add(graphic);
      });
      this._sortRouteGraphics(view,graphics,this._getActive2DLevelNumber());
    }
  }

  afterSetLevel(view,graphics) {
    if (graphics && graphics.length > 0) {
      graphics.forEach(graphic => {
        const levelInfo = graphic.xtnLevelInfo;
        if (levelInfo) {
          if (view.type === "3d") {
            if (levelInfo.symbolKey !== "indoorsRouteSymbolFor3D") {
              graphic.symbol = this.viewModel.indoorsRouteSymbolFor3D;
              levelInfo.symbolKey = "indoorsRouteSymbolFor3D";
            }
          } else {
            const matched = this._isMatching2DLevel(levelInfo.section);
            if (!matched) {
              if (levelInfo.symbolKey !== "indoorsRouteSymbolAlternate") {
                graphic.symbol = this.viewModel.indoorsRouteSymbolAlternate;
                levelInfo.symbolKey = "indoorsRouteSymbolAlternate";
              }
            } else {
              if (levelInfo.symbolKey !== "indoorsRouteSymbol") {
                graphic.symbol = this.viewModel.indoorsRouteSymbol;
                levelInfo.symbolKey = "indoorsRouteSymbol";
              }
            }
          }
        }
      });
      this._sortRouteGraphics(view,graphics,this._getActive2DLevelNumber());
    }
  }

  clearHighlights() {
    clearTimeout(this.timeoutHandle);
    this.viewModel.clearHighlights();
  }

  _fixName(text) {
    if (typeof text !== "string") return text;
    const digits = ["0","1","2","3","4","5","6","7","8","9"];
    const idx = text.indexOf("_#");
    if (idx !== -1) {
      let v = text;
      let left = v.substring(0,idx);
      v = v.substring(idx + 2);
      let right = "", chk = true
      for (let i=0;i<v.length;i++) {
        let c = v.charAt(i);
        if (chk) {
          if (digits.indexOf(c) === -1) {
            right += c;
            chk = false;
          }
        } else {
          right += c;
        }
      }
      text = left + right;
    }
    return text;
  }

  _getActive2DLevelNumber() {
    let levelNumber;
    const facilityInfo = Context.instance.aiim.getActiveFacilityInfo();
    if (facilityInfo) {
      if (facilityInfo.activeLevel) {
        levelNumber = facilityInfo.activeLevel.levelNumber;
      } else if (facilityInfo.zeroVOLevel) {
        levelNumber = facilityInfo.zeroVOLevel.levelNumber;
      }
    }
    if (levelNumber === undefined || levelNumber === null) {
      if (this.route) levelNumber = this.route.levelNumber;
    }
    return levelNumber;
  }

  _getDirectionLines() {
    const resp = this.response;
    if (resp && resp.data && resp.data.directionLines) {
      return resp.data.directionLines;
    }
  }

  _getDirectionPoints() {
    const resp = this.response;
    if (resp && resp.data && resp.data.directionPoints) {
      return resp.data.directionPoints;
    }
  }

  _getFirstPoint(feature) {
    let firstPoint;
    const geometry = (feature && feature.geometry);
    const type = (geometry && geometry.type)
    const paths = ((type === "polyline") && geometry.paths);
    if (paths && paths.length > 0) {
      firstPoint = geometry.getPoint(0,0);
    } else if (type === "point") {
      firstPoint = geometry;
    }
    return firstPoint;
  }

  _getLastPoint(feature) {
    let lastPoint;
    const geometry = (feature && feature.geometry);
    const type = (geometry && geometry.type)
    const paths = ((type === "polyline") && geometry.paths);
    if (paths && paths.length > 0) {
      const lastPathIdx =  paths.length - 1;
      const lastPointIdx = paths[lastPathIdx].length - 1;
      lastPoint = geometry.getPoint(lastPathIdx,lastPointIdx);
    } else if (type === "point") {
      lastPoint = geometry;
    }
    return lastPoint;
  }

  _getRoutes() {
    const resp = this.response;
    if (resp && resp.data && resp.data.routes) {
      return resp.data.routes;
    }
  }

  goToEvent(section,step) {
    try {
      this.clearHighlights();
      if (step.point && step.point.geometry) {
        const feature = step.point;
        const view = Context.getInstance().views.activeView;
        const facilityId = step.facilityId;
        const levelNumber = step.levelNumber;
        if (typeof facilityId === "string" && facilityId.length > 0 &&
            typeof levelNumber === "number") {
          const facilityMode = Context.getInstance().aiim.facilityMode;
          if (facilityMode) {
            facilityMode.activateLevelFilter(view,facilityId,levelNumber);
          }
        } else {
          console.warn("Direction event has no associated facility/level:",step);
        }
        mapUtil.goToFeature(view,feature).then(() => {});
        this.highlightEvent(step);
        const speech = Context.instance.speech;
        if (speech && speech.hasVoice() && speech.audibleDirectionsEnabled) {
          this._speakEvent(section,step);
        }
      }
    } catch(ex) {
      console.log("Error zooming to route step:");
      console.error(ex);
    }
  }

  goToRoute(changeLevel?) {
    try {
      this.clearHighlights();
      const route = this.route;
      if (route && route.geometry) {
        const feature = this.route;
        const view = Context.getInstance().views.activeView;
        const facilityId = route.facilityId;
        const levelNumber = route.levelNumber;
        if (!route.multipleFacilities) {
          if (typeof facilityId === "string" && facilityId.length > 0 &&
              typeof levelNumber === "number") {
            const facilityMode = Context.getInstance().aiim.facilityMode;
            if (facilityMode) {
              facilityMode.activateLevelFilter(view,facilityId,levelNumber);
            }
          } else {
            console.warn("Route has no associated facility/level:",route);
          }
        } else {
          console.warn("Route is associated with multiple facilities:",route);
        }
        if (!Context.instance.routeFromURL) {
          mapUtil.goToFeature(view,feature,Context.instance.routeFromURL).then(() => {});
          Context.instance.routeFromURL = false
        } else {
         Context.instance.routeFromURL = false
        }
      }
    } catch(ex) {
      console.log("Error zooming to route section:");
      console.error(ex);
    }
  }

  goToSection(section) {
    try {
      //console.log("goToSection",section)
      this.clearHighlights();
      if (section && section.feature && section.feature.geometry) {
        const feature = section.feature;
        const view = Context.getInstance().views.activeView;
        const facilityId = section.facilityId;
        const levelNumber = section.levelNumber;
        if (!section.multipleFacilities) {
          if (typeof facilityId === "string" && facilityId.length > 0 &&
              typeof levelNumber === "number") {
            const facilityMode = Context.getInstance().aiim.facilityMode;
            if (facilityMode) {
              facilityMode.activateLevelFilter(view,facilityId,levelNumber);
            }
          } else {
            console.warn("Direction section has no associated facility/level:",section);
          }
        } else {
          console.warn("Direction section is associated with multiple facilities:",section);
        }

        const speech = Context.instance.speech;
        const speak = (speech && speech.hasVoice() && speech.audibleDirectionsEnabled);
        if (section.minutes < 0.005 && section.steps.length > 0) {
          let step = section.steps[0];
          if (step.point && step.point.geometry) {
            mapUtil.goToFeature(view,step.point).then(() => {});
            this.highlightEvent(step);
            if (speak) this._speakEvent(section,step);
            return;
          }
        }

        mapUtil.goToFeature(view,feature).then(() => {});
        this.highlightSection(section);
        if (speak) this._speakSection(section);
      }
    } catch(ex) {
      console.log("Error zooming to route section:");
      console.error(ex);
    }
  }

  highlightEvent(step) {
    const feature = step.point.clone();
    const symbol = this.viewModel.indoorsEventSymbolHighlight;
    this.highlightFeature(feature,symbol);
  }

  highlightFeature(feature,symbol) {
    const lib = Context.getInstance().lib;
    this.clearHighlights();
    const graphic = new lib.esri.Graphic({
      attributes: feature.attributes,
      geometry: feature.geometry,
      symbol: symbol
    });
    const graphics = this.viewModel.get("_highlightLayer");
    if (graphics) graphics.add(graphic);
    this.timeoutHandle = setTimeout(() => {
      this.clearHighlights();
    },3000);
  }

  highlightSection(section) {
    const feature = section.feature.clone();
    const symbol = this.viewModel.indoorsRouteSymbolHighlight;
    this.highlightFeature(feature,symbol);
  }

  _isMatching2DLevel(section) {
    /*

      Ground floor: vertical order = 0

      If the floor picker is active:
        For route segments associated with the active facility:
          - segments on the same vertical order as the floor picker are solid
          - all others are dashed
        For route segments not associated with the active facility:
          If the floor picker is on the ground floor:
            - segments associated with ground floor are solid
            - all others are dashed
          If the floor picker is not on the ground floor:
            - all segments are dashed

      If the floor picker is not active:
        - all route segments associated with ground floor are solid
        - all others are dashed

    */

    const sectionVO = section.verticalOrder;
    const sectionFacilityId = section.facilityId;
    //const hasSectionFacilityId = !!sectionFacilityId;
    const facilityInfo = Context.instance.aiim.getActiveFacilityInfo();
    const activeLevel = (facilityInfo && facilityInfo.activeLevel);

    if (activeLevel) {
      const activeVO = activeLevel.verticalOrder;
      const activeFacilityId = activeLevel.facilityId;
      if (sectionFacilityId === activeFacilityId) {
        return (sectionVO === activeVO);
      } else {
        if (activeVO === 0) {
          return (sectionVO === activeVO);
        } else {
          return false;
        }
      }
    } else {
      return (sectionVO === 0);
    }
  }

  _mergeDirectionLines(points,lines) {

    const findPoint = (directionPointID) => {
      let found = null;
      if (points && points.features) {
        points.features.some(point => {
          let pointID = aiimUtil.getAttributeValue(point.attributes,"ObjectID");
          if (pointID === directionPointID) {
            found = point;
            return true;
          }
          return false;
        });
      }
      return found;
    };

    let hasMerge = false;
    if (points && points.features && lines && lines.features) {
      lines.features.forEach(line => {
        //console.log("line",line.clone,line);
        let directionPointID = aiimUtil.getAttributeValue(line.attributes,"DirectionPointID");
        let point = findPoint(directionPointID);
        if (point) {
          if (!point.xtnPrimaryLine) {
            point.xtnPrimaryLine = line;
          } else {
            let primaryLine = point.xtnPrimaryLine;
            if (!primaryLine.xtnMerged) primaryLine.xtnMerged = primaryLine.clone();
            let primary = primaryLine.xtnMerged;
            line.geometry.paths.forEach(path => {
              primary.geometry.addPath(path);
            });
            let metersA = aiimUtil.getAttributeValue(primary.attributes,"Meters");
            let minutesA = aiimUtil.getAttributeValue(primary.attributes,"Minutes");
            let toLevelA = aiimUtil.getAttributeValue(primary.attributes,"ToLevel");
            let metersB = aiimUtil.getAttributeValue(line.attributes,"Meters");
            let minutesB = aiimUtil.getAttributeValue(line.attributes,"Minutes");
            let toLevelB = aiimUtil.getAttributeValue(line.attributes,"ToLevel");
            primary.attributes["Meters"] = metersA + metersB;
            primary.attributes["Minutes"] = minutesA + minutesB;
            if (toLevelA !== toLevelB) {
              console.log("****** Merged lines have level change",primary,line);
              primary.attributes["ToLevel"] = toLevelB;
            }
            //console.log("Meters",primary.attributes["Meters"],primary.attributes);
            line.xtnWasMerged = true;
            hasMerge = true;
          }
        }
      });

      if (hasMerge) {
        let modified = [];
        lines.features.forEach(line => {
          if (!line.xtnWasMerged) {
            if (line.xtnMerged) {
              modified.push(line.xtnMerged);
            } else {
              modified.push(line);
            }
          }
        });
        lines.xtnOriginalFeatures = lines.features;
        lines.features = modified;
      }
    }
    return lines;
  }

  process() {
    //console.log("RouteResult.response",this.response);

    const findPoints = (points,line,sectionID,isLast) => {
      const found = [];
      let n = 0;
      if (points && points.features && points.features.length > 0) {
        const directionPointID = aiimUtil.getAttributeValue(line.attributes,"DirectionPointID");
        points.features.forEach((point,idx) => {
          let pointType = aiimUtil.getAttributeValue(point.attributes,"DirectionPointType");
          let isLastPoint = (idx === (points.features.length - 1));
          let isStart = (n === 0 && sectionID === 0 && pointType === 51);
          let isEnd = (isLast && isLastPoint && pointType === 50);
          //console.log(sectionID,pointType);
          if (point.xtnSectionID === sectionID) {
            if (n > 0 || (isStart || isEnd)) point.xtnIsPseudoEvent = true;
            found.push(point);
            if (!isStart) n++;
          } else {
            let pointID = aiimUtil.getAttributeValue(point.attributes,"ObjectID");
            if (pointID === directionPointID) {
              if (n > 0 || (isStart || isEnd)) point.xtnIsPseudoEvent = true;
              found.push(point);
              if (!isStart) n++;
            }
          }
        });
      }
      return found;
    };

    const lib = Context.getInstance().lib;
    const job = {
      facilityFootprints: Context.getInstance().aiim.facilityFootprints,
      levelsDataset: Context.getInstance().aiim.datasets.levels
    }
    let sections = [];
    let totalMeters = 0;
    let totalMinutes = 0;

    let du = "esriMeters"; // TODO locale based?
    let tu = "esriNAUMinutes";
    let info = {
      routeParameters: this.viewModel.routeParameters,
      serviceDescription: this.viewModel.serviceDescription
    };

    let routes = this._getRoutes();
    if (routes && routes.features && routes.features.length === 1) {
      routes = lib.esri.FeatureSet.fromJSON(routes);
      if (routes && routes.features && routes.features.length === 1) {
        this.route = routes.features[0];
        let rn = aiimUtil.getAttributeValue(this.route.attributes,"Name");
        this.routeName = this._fixName(this._fixName(rn));
      }
    }

    let points = this._getDirectionPoints();
    if (points && points.features && points.features.length > 0) {
      points = lib.esri.FeatureSet.fromJSON(points);
    }

    let lines = this._getDirectionLines();
    if (lines && lines.features && lines.features.length > 0) {
      lines = lib.esri.FeatureSet.fromJSON(lines);
      lines = this._mergeDirectionLines(points,lines);
    }

    // assign a section id to each point
    if (points && points.features && points.features.length > 0) {
      let sectionID = 0;
      points.features.forEach(point => {
        point.xtnSectionID = sectionID;
        let pointID = aiimUtil.getAttributeValue(point.attributes,"ObjectID");
        if (lines && lines.features && lines.features.length > 0) {
          lines.features.some((feature,i) => {
            let directionPointID = aiimUtil.getAttributeValue(feature.attributes,"DirectionPointID");
            if (pointID === directionPointID) {
              sectionID = i;
              point.xtnSectionID = sectionID;
              return true;
            }
            return false;
          });
        }
      });
    }

    // if (points && points.features && points.features.length > 0) {
    //   points.features.forEach((point,idx) => {
    //     console.log("point",idx,"xtnSectionID",point.xtnSectionID);
    //   });
    // }
    // if (lines && lines.features && lines.features.length > 0) {
    //   lines.features.forEach((line,idx) => {
    //     console.log("line",idx,"directionPointID",aiimUtil.getAttributeValue(line.attributes,"DirectionPointID"));
    //   });
    // }

    if (lines && lines.features && lines.features.length > 0) {
      //let sr = lines.spatialReference;
      lines.features.forEach((feature,sectionID) => {
        //console.log("directionLine",feature);
        let isLast = (sectionID === (lines.features.length - 1));
        //let directionLineType = aiimUtil.getAttributeValue(feature.attributes,"DirectionLineType");
        //let directionPointID = aiimUtil.getAttributeValue(feature.attributes,"DirectionPointID");
        //let routeID = aiimUtil.getAttributeValue(feature.attributes,"RouteID");
        let meters = aiimUtil.getAttributeValue(feature.attributes,"Meters");
        let minutes = aiimUtil.getAttributeValue(feature.attributes,"Minutes");
        let fromLevelNumber = aiimUtil.getAttributeValue(feature.attributes,"FromLevel");
        let toLevelNumber = aiimUtil.getAttributeValue(feature.attributes,"ToLevel");

        //console.log("lineIndex",sectionID,"pointOnjectId=",directionPointID);
        //console.log("lineIndex",sectionID,"meters",meters,"minutes",minutes);

        let isLevelTransition = (fromLevelNumber !== toLevelNumber);
        let toUnits;
        toUnits=this.util.getToDistanceUnits(meters);
        let distanceText = this.util.formatDistance(info,meters,false,du,toUnits);
        let timeText = this.util.formatTime(info,minutes,false,tu);
        totalMeters += meters;
        totalMinutes += minutes;

        let section = {
          id: sectionID,
          isSection: true,
          feature: feature,

          facilityId: null,
          verticalOrder: 0,
          levelNumber: null,
          zeroVOLevelNumber: null,

          fromFacilityId: null,
          fromVerticalOrder: fromLevelNumber,
          fromLevelNumber: fromLevelNumber,
          fromZeroVOLevelNumber: null,

          toFacilityId: null,
          toVerticalOrder: toLevelNumber,
          toLevelNumber: toLevelNumber,
          toZeroVOLevelNumber: null,

          isLevelTransition: isLevelTransition,
          meters: meters,
          minutes: minutes,
          distanceText: distanceText,
          timeText: timeText,
          steps: []
        };
        sections.push(section);
        this._processSection(job,section,false);

        let pts = findPoints(points,feature,sectionID,isLast);
        pts.forEach(point => {
          let stopID = aiimUtil.getAttributeValue(point.attributes,"StopID");
          let text = aiimUtil.getAttributeValue(point.attributes,"DisplayText");
          let pointType = aiimUtil.getAttributeValue(point.attributes,"DirectionPointType");
          let stepLevelNumber = aiimUtil.getAttributeValue(point.attributes,"Level");
          let maneuverType = null;
          let maneuverIconUrl = null;
          let maneuverInfo = maneuverTypes.maneuverTypesByInt[pointType];
          if (maneuverInfo) {
            maneuverType = maneuverInfo.type;
            //console.log("manerver",maneuverType,"txt",text);
            if (maneuverInfo.icon) {
              maneuverIconUrl = this.util.getIconUrl(maneuverInfo.icon);
              //console.log("manerver",pointType,maneuverInfo.type,maneuverInfo.icon);
            }
          }
          //if (point.xtnIsPseudoEvent) text += " Pseudo";
          let step = {
            point: point,
            text: text,
            facilityId: null,
            verticalOrder: stepLevelNumber,
            levelNumber: stepLevelNumber,
            maneuverType: maneuverType,
            maneuverIconUrl: maneuverIconUrl,
            isStop: (typeof stopID === "number"),
            isEvent: (pointType >= 1000),
            isPseudoEvent: !!point.xtnIsPseudoEvent
          }
          section.steps.push(step);
          this._processStep(job,step);

          if (step.isStop) {
            // the core Directions widget is appending identifiers to stop
            // names, e.g. *O3 Atrium South_#10001
            step.text = this._fixName(step.text);
          }
        });
      });
    }

    let toUnits;
    toUnits=this.util.getToDistanceUnits(totalMeters);
    this.totalDistanceText = this.util.formatDistance(info,totalMeters,false,du,toUnits);
    this.fullDistanceUnit = Context.instance.i18n.directions.units[toUnits].name;
    //console.log("Unit#######", this.fullDistanceUnit);
    this.totalTimeText = this.util.formatTime(info,totalMinutes,false,tu);
    this.sections = sections;
    this._processRoute(job,this.route,this.sections);
  }

  _processRoute(job,route,sections) {
    if (!route) return;
    if (sections.length > 0) {
      const fromSection = sections[0];
      const toSection = sections[sections.length - 1];

      route.fromFacilityId = fromSection.fromFacilityId;
      route.fromVerticalOrder = fromSection.fromVerticalOrder;
      route.fromLevelNumber = fromSection.fromLevelNumber;

      // route.toFacilityId = toSection.toFacilityId;
      // route.toVerticalOrder = toSection.toVerticalOrder;
      // route.toLevelNumber = toSection.toLevelNumber;

      route.toFacilityId = toSection.facilityId;
      route.toVerticalOrder = toSection.verticalOrder;
      route.toLevelNumber = toSection.levelNumber;

    }
    if (typeof route.toFacilityId === "string" && route.toFacilityId.length > 0 &&
        typeof route.toLevelNumber === "number") {
      route.facilityId = route.toFacilityId;
      route.verticalOrder = route.toVerticalOrder;
      route.levelNumber = route.toLevelNumber;
    } else if (typeof route.toVerticalOrder === "number") {
      route.verticalOrder = route.toVerticalOrder;
    }
  }

  _processStep(job,step) {
    if (job.facilityFootprints) {
      const geometry = step.point && step.point.geometry;
      let facilityId = job.facilityFootprints.findFacilityIdByPoint(geometry);
      if (Array.isArray(facilityId)) facilityId = facilityId[0];
      step.facilityId = facilityId;
      if (job.levelsDataset) {
        const facilityData = job.levelsDataset.getFacilityData(facilityId);
        if (facilityData) {
          this._updateLN("levelNumber",step,facilityData);
        }
      }
    }
  }

  _processSection(job,section,isRoute) {
    let facilityData, zeroVOLevel;
    const feature = isRoute ? section : section.feature;
    const firstPoint = this._getFirstPoint(feature);
    const lastPoint = this._getLastPoint(feature);

    let fromFacilityId = job.facilityFootprints.findFacilityIdByPoint(firstPoint);
    if (Array.isArray(fromFacilityId)) fromFacilityId = fromFacilityId[0];
    section.fromFacilityId = fromFacilityId;
    if (job.levelsDataset) {
      facilityData = job.levelsDataset.getFacilityData(fromFacilityId);
      if (facilityData) {
        zeroVOLevel = job.levelsDataset.getZeroVOLevel(facilityData);
        if (zeroVOLevel) {
          section.fromZeroVOLevelNumber = zeroVOLevel.levelNumber;
        }
        this._updateLN("fromLevelNumber",section,facilityData);
      }
    }

    let toFacilityId = job.facilityFootprints.findFacilityIdByPoint(lastPoint);
    if (Array.isArray(toFacilityId)) toFacilityId = toFacilityId[0];
    section.toFacilityId = toFacilityId;
    if (job.levelsDataset) {
      facilityData = job.levelsDataset.getFacilityData(toFacilityId);
      if (facilityData) {
        zeroVOLevel = job.levelsDataset.getZeroVOLevel(facilityData);
        if (zeroVOLevel) {
          section.toZeroVOLevelNumber = zeroVOLevel.levelNumber;
        }
        this._updateLN("toLevelNumber",section,facilityData);
      }
    }

    if (typeof toFacilityId === "string" && toFacilityId.length > 0 &&
        typeof section.toLevelNumber === "number") {
      section.facilityId = toFacilityId;
      section.verticalOrder = section.toVerticalOrder;
      section.levelNumber = section.toLevelNumber;
      section.zeroVOLevelNumber = section.toZeroVOLevelNumber;
    } else {
      let useFrom = false;
      const hasFrom = (typeof fromFacilityId === "string" && fromFacilityId.length > 0 &&
                       typeof section.fromLevelNumber === "number");
      if (hasFrom && section.fromVerticalOrder === section.toVerticalOrder) {
        useFrom = true;
      }
      if (useFrom) {
        section.facilityId = fromFacilityId;
        section.verticalOrder = section.fromVerticalOrder;
        section.levelNumber = section.fromLevelNumber;
        section.zeroVOLevelNumber = section.fromZeroVOLevelNumber;
      } else if (typeof section.toVerticalOrder === "number") {
        section.verticalOrder = section.toVerticalOrder;
      }
    }
  }

  _sortRouteGraphics(view,graphics,levelNumber) {
    if (view.type !== "2d") return;
    graphics.sort((graphicA,graphicB) => {
      if (graphicA.xtnLevelInfo && graphicB.xtnLevelInfo) {
        let a = graphicA.xtnLevelInfo.section.levelNumber;
        let b = graphicB.xtnLevelInfo.section.levelNumber;
        if (a === levelNumber && b !== levelNumber) {
          return 1;
        } else if (a !== levelNumber && b === levelNumber) {
          return -1;
        }
      }
      return 0;
    });
    // graphics.forEach(graphic => {
    //   console.log("***",level,"=",graphic.xtnLevelInfo);
    // });
  }

  _speakEvent(section,step) {
    const speech = Context.instance.speech;
    if (speech && speech.hasVoice() && speech.audibleDirectionsEnabled) {
      speech.speak(step.text);
    }
  }

  _speakSection(section) {
    const speech = Context.instance.speech;
    if (speech && speech.hasVoice() && speech.audibleDirectionsEnabled) {
      let text = "";
      section.steps.forEach(step => {
        if (text.length > 0) text += " ";
        text += step.text;
      });
      text += " "+section.distanceText;
      speech.speak(text);
    }
  }

  _updateLN(propertyName,info,facilityData) {
    // the level numbers returned by the network service are vertical orders,
    // switch them the levels numbers (i.e. from VERTICAL_ORDER to LEVEL_NUMBER)
    if (facilityData && typeof info[propertyName] === "number") {
      const vo = info[propertyName];
      if (typeof vo === "number") {
        const ln = facilityData.levelNumbersByVO[vo];
        //console.log("_voToLn",vo,ln,name);
        if (typeof ln === "number") {
          info[propertyName] = ln;
        } else {
          console.error("Facility vertical_order to level_number inconsistency",
            propertyName,info,facilityData);
        }
      }
    }
  }

}
