import React, {createRef} from "react";

import Attributes from "./Attributes";
import Context from "../../../../context/Context";
import SplitVM from "./SplitVM";
import * as component from "../../../../components/util/component";
import * as sourceUtil from "../../../base/sourceUtil";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-tab";
import "@esri/calcite-components/dist/components/calcite-tab-nav";
import "@esri/calcite-components/dist/components/calcite-tab-title";
import "@esri/calcite-components/dist/components/calcite-tabs";
import {
  CalciteButton,
  CalciteTab,
  CalciteTabNav,
  CalciteTabTitle,
  CalciteTabs
} from "@esri/calcite-components-react";
import * as wallTypesUtil from "../../../miniapps/configurator/wallTypesUtil";
import { getFieldTooltip } from "../support/formUtil";

interface Props {
  onMount?: (splitAttributes: SplitAttributes) => void,
  onUnmount?: (splitAttributes: SplitAttributes) => void,
  splitKey?: string,
  viewModel: SplitVM
}

interface State {
  activeTab?: number
}

export default class SplitAttributes extends React.Component<Props, State> {

  modifiedUnitForm;
  newUnitForm;
  newWallForm;

  constructor(props) {
    super(props);
    this.state = component.newState({
      activeTab: null
    });
  }

  componentDidMount () {
    if (this.props.onMount) this.props.onMount(this);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    if (this.props.onUnmount) this.props.onUnmount(this);
  }

  applyValues = (feature,values) => {
    if (!feature || !values) return;
    const attributes = feature.attributes;
    Object.keys(values).forEach(name => {
      if (attributes[name] !== values[name]) {
        attributes[name] = values[name];
      }
    });
  }

  hasInvalidInput = (ff) => {
    const result = {hasInvalid: false, fieldName: null, inputField: null}
    if (!ff) return result;
    const inputs = ff.viewModel.allFieldInputs;
    inputs.some(inputField => {
      if (inputField.element && !inputField.valid) {
        result.hasInvalid = true;
        result.fieldName = inputField.element.fieldName;
        result.inputField = inputField;
      }
      return result.hasInvalid;
    });
    return result;
  }

  render() {

    const i18n = Context.instance.i18n;
    let unitsLayer = sourceUtil.getUnitsLayer();
    let detailsLayer = sourceUtil.getDetailsLayer();
    let modifiedUnitOptions, newUnitOptions, newWallOptions;
    let modifiedUnitForm, newUnitForm, newWallForm;

    const info = this.props.viewModel.activeSplit;

    if (info && info.modifiedUnit) {
      modifiedUnitOptions = {
        title: i18n.editor.modifiedUnit,
        layer: unitsLayer,
        type: "unit",
        allowName: true,
        forSplit: true,
        feature: new Context.instance.lib.esri.Graphic({
          attributes: info.modifiedUnit.attributes,
          layer: unitsLayer
        }),
        getTooltip: getFieldTooltip
      }
    }

    if (info && info.modifiedUnit && info.newUnit) {
      newUnitOptions = {
        title: i18n.editor.newUnit,
        layer: unitsLayer,
        type: "unit",
        allowName: true,
        forSplit: true,
        feature: new Context.instance.lib.esri.Graphic({
          attributes: info.newUnit.attributes,
          layer: unitsLayer
        }),
        getTooltip: getFieldTooltip
      }
    }

    if (info && info.newWall) {
      const wallTypeValues = wallTypesUtil.getWallTypes();
      newWallOptions = {
        title: i18n.editor.newWall,
        layer: detailsLayer,
        type: "detail",
        allowName: true,
        forSplit: true,
        feature: new Context.instance.lib.esri.Graphic({
          attributes: info.newWall.attributes,
          layer: detailsLayer
        }),
        useTypes: wallTypeValues,
        getTooltip: getFieldTooltip
      }
    }

    if (!modifiedUnitOptions && !newWallOptions) {
      return null;
    }

    const handleTabChange = (index) => {
      this.setState({activeTab: index})
    }

    let activeTabIndex = 0, modifiedUnitDisplay = "none", newUnitDisplay = "none", newWallDisplay = "none";


    if (newUnitOptions && this.state.activeTab === 1) {
      activeTabIndex = 1;
    } else if (newWallOptions && this.state.activeTab === 2) {
      activeTabIndex = 2;
    } else {
      if (modifiedUnitOptions) {
        activeTabIndex = 0;
      } else if (newWallOptions) {
        activeTabIndex = 2;
      }
    }

    if (activeTabIndex === 0) {
      modifiedUnitDisplay = "block";
    } else if (activeTabIndex === 1) {
      newUnitDisplay = "block";
    } else if (activeTabIndex === 2) {
      newWallDisplay = "block";
    }

    const mp0 = {margin: "0", padding: "0"}

    return (
      <div className="i-editor-split-attributes">

        <CalciteTabs layout="inline" style={{marginTop: "0.5rem", marginBottom: "1rem"}}>
          <CalciteTabNav slot="title-group" >
            {modifiedUnitOptions &&
              <CalciteTabTitle 
                tab="modifiedUnit"
                selected={activeTabIndex === 0 ? true : undefined}
                onCalciteTabsActivate={e => handleTabChange(0)}
              >
                {modifiedUnitOptions.title}
              </CalciteTabTitle>
        
            }
            {newUnitOptions &&
              <CalciteTabTitle 
                tab="newUnit"
                selected={activeTabIndex === 1 ? true : undefined}
                onCalciteTabsActivate={e => handleTabChange(1)}
              >
                {newUnitOptions.title}
              </CalciteTabTitle>
            }
            {newWallOptions &&
              <CalciteTabTitle 
                tab="newWall"
                selected={activeTabIndex === 2 ? true : undefined}
                onCalciteTabsActivate={e => handleTabChange(2)}
              >
                {newWallOptions.title}
              </CalciteTabTitle>
            }
          </CalciteTabNav>
        </CalciteTabs>

        <div className="i-editor-split-attributes-forms">

          {modifiedUnitOptions &&
            <div style={{display: modifiedUnitDisplay, height: '100%'}}>
              <Attributes 
                feature={modifiedUnitOptions.feature} 
                options={modifiedUnitOptions}
                onCreateForm={(ae,ff) => {
                  this.modifiedUnitForm = ff;
                  ff.on("value-change",info => {
                    if (info.valid && info.fieldName.toLowerCase() === "name") {
                      this.props.viewModel.nameChanged("modifiedUnit",info.value)
                    } else if (info.valid && info.fieldName.toLowerCase() === "use_type") {
                      const f = {attributes: Object.assign({},info.feature.attributes)}
                      f.attributes[info.fieldName] = info.value;
                      this.props.viewModel.useTypeChanged(f,"modifiedUnit");
                    }
                  });
                }}
              />
            </div>
          }

          {newUnitOptions &&
            <div style={{display: newUnitDisplay, height: '100%'}}>
              <Attributes 
                feature={newUnitOptions.feature} 
                options={newUnitOptions}
                onCreateForm={(ae,ff) => {
                  this.newUnitForm = ff;
                  ff.on("value-change",info => {
                    if (info.valid && info.fieldName.toLowerCase() === "name") {
                      this.props.viewModel.nameChanged("newUnit",info.value)
                    } else if (info.valid && info.fieldName.toLowerCase() === "use_type") {
                      const f = {attributes: Object.assign({},info.feature.attributes)}
                      f.attributes[info.fieldName] = info.value;
                      this.props.viewModel.useTypeChanged(f,"newUnit");
                    }
                  });
                }}
              />
            </div>
          }

          {newWallOptions &&
            <div style={{display: newWallDisplay, height: '100%'}}>
              <Attributes 
                feature={newWallOptions.feature} 
                options={newWallOptions}
                onCreateForm={(ae,ff) => {
                  this.newWallForm = ff;
                  ff.on("value-change",info => {
                    if (info.valid && info.fieldName.toLowerCase() === "use_type") {
                      const f = {attributes: Object.assign({},info.feature.attributes)}
                      f.attributes[info.fieldName] = info.value;
                      this.props.viewModel.useTypeChanged(f,"newWall");
                    }
                  });
                }}
              />
            </div>
          }

        </div>

      </div>
    )
  }

  validate(options) {
    const info = this.props.viewModel.activeSplit || {};
    const { modifiedUnitForm, newUnitForm, newWallForm } = this;
    const { modifiedUnit, newUnit, newWall } = info;
    let modifiedUnitValues, newUnitValues, newWallValues;
    let hasInvalid = false;
    if (!modifiedUnit && !newWall) return false;

    const activeTab = this.state.activeTab || 0;
    let forms = [modifiedUnitForm, newUnitForm, newWallForm];
    if (activeTab=== 1) {
      forms = [newUnitForm, modifiedUnitForm, newWallForm];
    } else if (activeTab=== 2) {
      forms = [newWallForm, modifiedUnitForm, newUnitForm];
    }
    forms.some((ff,i) => {
      const result = this.hasInvalidInput(ff);
      if (result.hasInvalid) hasInvalid = true;
      if (result.hasInvalid) {
        let tabIndex;
        if (ff === this.modifiedUnitForm) {
          tabIndex = 0;
        } else if (ff === this.newUnitForm) {
          tabIndex = 1;
        } else if (ff === this.newWallForm) {
          tabIndex = 2;
        }

        ff.submit();
        ff.viewModel.allFieldInputs.forEach(f => {
          if (f.groupElement && f.state === "collapsed") {
            const found = f.groupElement.elements.some(el => {
              if (el.type === "field" && el.fieldName === result.fieldName) {
                return true;
              }
              return false;
            })
            if (found) f.state = "expanded";
          }
        })

        this.setState({activeTab: tabIndex}, () => {
          setTimeout(() => {
            if (ff.domNode && result.fieldName) {
              const nd = ff.domNode.querySelector("[data-field-name='"+result.fieldName+"']");
              if (nd && typeof nd.scrollIntoView === "function") {
                nd.scrollIntoView({block: "center", inline: "nearest"});
              }
            }
          },100);
        });

      }
      return hasInvalid;
    })
    if (!hasInvalid && options && options.apply) {
      if (modifiedUnitForm) modifiedUnitValues = modifiedUnitForm.getValues();
      if (newUnitForm) newUnitValues = newUnitForm.getValues();
      if (newWallForm) newWallValues = newWallForm.getValues();
      const modifiedUnitOK = !modifiedUnit || modifiedUnitValues;
      const newUnitOK = !newUnit || newUnitValues;
      const newWallOK = !newWall || newWallValues;
      if (modifiedUnitOK && newUnitOK && newWallOK) {
        this.applyValues(modifiedUnit,modifiedUnitValues);
        this.applyValues(newUnit,newUnitValues);
        this.applyValues(newWall,newWallValues);
      }
    }
    return !hasInvalid;
  }

}
