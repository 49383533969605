import Context from "../../context/Context";
import FieldNames from "../datasets/FieldNames";
import * as aiimUtil from "../util/aiimUtil";

export function escSqlQuote(value) {
  if (typeof value === "string" ) {
    return value.replace("'","''");
  }
  return value;
}

export function getBaseDefinitionExpression(layer) {
  let expr = layer && layer.xtnOriginalDefinitionExpression;
  if (expr) {
    if (expr === "__none__") expr = "";
  } else {
    expr = layer && layer.definitionExpression;
  }
  if (typeof expr !== "string") expr = "";
  return expr;
}

function isFacilitiesLayer(layer) {
  const facilities = Context.getInstance().aiim.datasets.facilities;
  return (facilities && layer && layer.xtnAiim && layer.xtnAiim.dataset === facilities);
}

export function makeLevelWhere(levelData, targetLayer) {
  if (levelData && targetLayer && targetLayer.fields) {
    const criteria = {levelData: levelData};
    const parts = makeWhereParts(targetLayer,targetLayer.fields,criteria,false);
    if (parts.levelPart) return parts.levelPart;
  }
  return null;
}

function makePart(field,operator,value) {
  if (field.type === "string") {
    if (typeof value === "string" ) {
      value = "'" + escSqlQuote(value) + "'";
    }
  }
  return "(" + field.name + " " + operator + " " + value + ")";
}

function makeWhere_2D(layer,fields,criteria,aboveFacilities) {
  let where = null;
  const parts = makeWhereParts(layer,fields,criteria,true,aboveFacilities);
  if (parts.hasFacilityField && parts.hasLevelField) {
    const requiresFacilityMode = (layer && layer.xtnAiim &&
      (layer.xtnAiim.requiresFacilityMode ||
      (layer.xtnAiim.dataset && layer.xtnAiim.dataset.requiresFacilityMode)));
    const p1 = parts.facilityPart;
    const p2 = parts.levelPart;
    const p3 = parts.notFacilityPart;
    const p4 = parts.outdoorsPart;
    const p5 = parts.groundFloorPart;
    if (p1 && p2) {
      where = "(" + p1 + " AND " + p2 + ")";
      if (aboveFacilities && !requiresFacilityMode) {
        where = "(" + where + " OR " + p3 + ")";
      } else {
        if (p5) {
          const v = "(" + p3 + " AND " + p5 + ")";
          where = "(" + where + " OR " + v + ")";
        }
      }
      if (p4) {
        where = "(" + where + " OR " + p4 + ")";
      }
    } else {
      where = makeWhere_2DDefault(layer,fields,aboveFacilities);
    }
  }
  return where;
}

function makeWhere_2DDefault(layer,fields,aboveFacilities) {
  let where = null;
  const parts = makeWhereParts(layer,fields,{},true,aboveFacilities);
  if (parts.hasFacilityField && parts.hasLevelField) {
    const requiresFacilityMode = (layer && layer.xtnAiim &&
      (layer.xtnAiim.requiresFacilityMode ||
      (layer.xtnAiim.dataset && layer.xtnAiim.dataset.requiresFacilityMode)));
    if (requiresFacilityMode) {
      where = "1=2";
      if (parts.groundFloorPart) {
        where = parts.groundFloorPart;
      }
    } else if (!aboveFacilities && parts.groundFloorPart) {
      where = parts.groundFloorPart;
      if (parts.outdoorsPart) {
        where = "(" + where + " OR " + parts.outdoorsPart + ")";
      }
    } else if (!aboveFacilities && parts.outdoorsPart) {
      where = parts.outdoorsPart;
    } else {
      where = "";
    }
  }
  return where;
}

function makeWhere_3D(layer,fields,criteria) {
  let where = null;
  const parts = makeWhereParts(layer,fields,criteria,false);
  if (parts.hasFacilityField && parts.hasLevelField) {
    const p1 = parts.facilityPart;
    const p2 = parts.levelPart;
    const p3 = parts.notFacilityPart;
    if (p1 && p2) {
      where = "(" + p1 + " AND " + p2 + ") OR " + p3;
    } else {
      where = "";
    }
  }
  return where;
}

function makeWhereParts(layer,fields,criteria,is2D,aboveFacilities) {
  let source = (layer.xtnAiim && layer.xtnAiim.source) ||
               (layer.xtnAiim && layer.xtnAiim.cimSource);

  const chkNum = (v) => {
    return (typeof v === "number" && isFinite(v));
  };

  const chkStr = (v) => {
    return (typeof v === "string" && v.length > 0);
  };

  const getField = (name,defaultValue) => {
    let fieldName = defaultValue;
    if (source && source.mappings &&
        source.mappings.hasOwnProperty(name) && source.mappings[name]) {
      fieldName = source.mappings[name];
    }
    if (chkStr(fieldName)) return aiimUtil.findField(fields,fieldName);
    return;
  };

  const hasField = (pairs) => {
    return pairs.some(pair => {
      return !!pair.field;
    });
  };

  const evaluatePairs = (pairs,operator) => {
    let part = null;
    pairs.some(pair => {
      const field = pair.field;
      let value = pair.value;
      let ok = false;
      if (field) {
        if (field.type === "string") {
          if (chkNum(value)) value = "" + value;
          ok = chkStr(value);
        } else {
          ok = chkNum(value);
        }
      }
      //console.log(layer.title,ok,field && field.name,value);
      if (ok) {
        part = makePart(field,operator,value);
        return true;
      }
      return false;
    });
    return part;
  };

  const makeFacilityPairs = (levelData) => {
    let pairs = [{
      field: getField("facilityIdField",FieldNames.FACILITY_ID),
      value: levelData && levelData.facilityId
    }, {
      field: getField("facilityNameField",FieldNames.FACILITY_NAME),
      value: levelData && levelData.facilityName
    }];
    return pairs;
  }

  const makeLevelPairs = (levelData) => {
    let pairs = [{
      field: getField("levelNumberField",FieldNames.LEVEL_NUMBER),
      value: levelData && levelData.levelNumber
    }, {
      field: getField("levelIdField",FieldNames.LEVEL_ID),
      value: levelData && levelData.levelId
    }, {
      field: getField("levelNameField",FieldNames.LEVEL_NAME),
      value: levelData && levelData.levelName,
    }, {
      field: getField("verticalOrderField",FieldNames.VERTICAL_ORDER),
      value: levelData && levelData.verticalOrder,
    }, {
      field: getField("levelShortNameField",null),
      value: levelData && levelData.levelShortName
    }];
    return pairs;
  }

  const parts = {
    hasFacilityField: false,
    hasLevelField: false,
    facilityPart: null,
    levelPart: null,
    notFacilityPart: null,
    groundFloorPart: null,
    outdoorsPart: null
  };

  let pairs;
  let facilityId = criteria.facilityId;
  let levelData = criteria.levelData;

  if (!levelData && chkStr(facilityId) && is2D) {
    const levels = Context.getInstance().aiim.datasets.levels;
    if (levels) {
      levelData = levels.getZeroVOLevel(levels.getFacilityData(facilityId));
    }
  }

  // let facilityPairs = pairs = [{
  //   field: getField("facilityIdField",FieldNames.FACILITY_ID),
  //   value: levelData && levelData.facilityId
  // }, {
  //   field: getField("facilityNameField",FieldNames.FACILITY_NAME),
  //   value: levelData && levelData.facilityName
  // }];
  pairs = makeFacilityPairs(levelData);
  parts.hasFacilityField = hasField(pairs);
  if (levelData) {
    parts.facilityPart = evaluatePairs(pairs,"=");
    parts.notFacilityPart = evaluatePairs(pairs,"<>");
  }

  // let levelPairs = pairs = [{
  //   field: getField("levelNumberField",FieldNames.LEVEL_NUMBER),
  //   value: levelData && levelData.levelNumber
  // }, {
  //   field: getField("levelIdField",FieldNames.LEVEL_ID),
  //   value: levelData && levelData.levelId
  // }, {
  //   field: getField("levelNameField",FieldNames.LEVEL_NAME),
  //   value: levelData && levelData.levelName,
  // }, {
  //   field: getField("verticalOrderField",FieldNames.VERTICAL_ORDER),
  //   value: levelData && levelData.verticalOrder,
  // }, {
  //   field: getField("levelShortNameField",null),
  //   value: levelData && levelData.levelShortName
  // }];

  pairs = makeLevelPairs(levelData);
  parts.hasLevelField = hasField(pairs);
  if (levelData) {
    parts.levelPart = evaluatePairs(pairs,"=");
  }

  let groundVO = 0;
  const showAllFloorPlans = Context.instance.config.showAllFloorPlans2D;
  if (showAllFloorPlans) {
    pairs = [{
      field: getField("verticalOrderField",FieldNames.VERTICAL_ORDER),
      value: groundVO
    }];
    parts.groundFloorPart = evaluatePairs(pairs,"=");
    if (!parts.groundFloorPart) {
      if (parts.hasFacilityField && parts.hasLevelField) {
        //console.log("_________________",aboveFacilities,layer.title)
        if (!aboveFacilities) {
          parts.groundFloorPart = "(1=2)";

          const considerAllFacilities = true;
          const exp = [];
          const levels = Context.instance.aiim.datasets.levels;
          const facilities = levels && levels.getFacilities();
          if (considerAllFacilities && facilities) {
            facilities.forEach(facilityData => {
              let data = facilityData.levelsByVO[groundVO];
              let ok = !!data;
              if (data && levelData && data.facilityId === levelData.facilityId) {
                ok = false;
              }
              if (data && ok) {
                let pairs1 = makeFacilityPairs(data);
                let pairs2 = makeLevelPairs(data);
                let part1 = evaluatePairs(pairs1,"=");
                let part2 = evaluatePairs(pairs2,"=");
                if (part1 && part2) {
                  let p = part1 + " AND " + part2;
                  exp.push(p);
                  //console.log(layer.title,p)
                }
              }
            });
          }
          if (exp.length > 0) {
            let p = "(" + exp.join(" OR ") + ")";
            parts.groundFloorPart = p;
            //console.log(layer.title,p)
          }

        }
      }
    }
  }

  pairs = [{
    field: getField("locTypeField",FieldNames.LOCATION_TYPE),
    value: 0
  }];
  parts.outdoorsPart = evaluatePairs(pairs,"=");

  // console.log(layer.title,"parts",parts);
  // console.log(layer.title,"facilityPart",parts.facilityPart);
  // console.log(layer.title,"notFacilityPart",parts.notFacilityPart);
  // console.log(layer.title,"levelPart",parts.levelPart);
  // console.log(layer.title,"outdoorsPart",parts.outdoorsPart);

  return parts;
}

export function perLayer(layers, callback) {
  layers.forEach(layer => {
    if (layer.declaredClass === "esri.layers.support.Sublayer") {
      if (layer.xtnFeatureLayer) {
        layer.xtnFeatureLayer.when(() => {
          callback(layer,layer.xtnFeatureLayer.fields);
        });
      }
    } else {
      layer.when(() => {
        callback(layer,layer.fields);
      });
    }
  });
}

export function reset2D(view) {
  let layers = aiimUtil.getLayers(view);
  let aboveFacilities = false, found = false;
  perLayer(layers,(layer,fields) => {
    if (!found && isFacilitiesLayer(layer)) found = true;
    let expression = makeWhere_2DDefault(layer,fields,aboveFacilities);
    setDefinitionExpression(layer,expression);
    if (found) aboveFacilities = true;
  });
}

export function resetAll(view) {
  if (view && view.type === "2d") {
    reset2D(view)
  } else if (view && view.type === "3d") {
    resetExpressions(view);
  }
}

function resetExpressions(view) {
  const layers = aiimUtil.getLayers(view);
  layers.forEach((layer) => {
    if (layer.definitionExpression) {
      if (layer.xtnOriginalDefinitionExpression) {
        if (layer.xtnOriginalDefinitionExpression === "__none__") {
          layer.definitionExpression = null;
        } else {
          layer.definitionExpression = layer.xtnOriginalDefinitionExpression;
        }
      }
    }
  });
}

function setDefinitionExpression(layer, expression) {
  if (expression === null) return; // use "" to clear
  if (Context.instance.aiim.isFloorAwareMap()) return;

  if (!layer.xtnOriginalDefinitionExpression) {
    if (layer.definitionExpression) {
      layer.xtnOriginalDefinitionExpression = layer.definitionExpression;
    } else {
      layer.xtnOriginalDefinitionExpression = "__none__";
    }
  }

  const current = layer.definitionExpression;
  const original = layer.xtnOriginalDefinitionExpression;
  //const hasCurrent =  (typeof current === "string" && current.length > 0);
  const hasOriginal =  (typeof original === "string" && original.length > 0 && original !== "__none__");
  const hasExpression =  (typeof expression === "string" && expression.length > 0 && expression !== "1=1");

  if (hasExpression) {
    if (hasOriginal) {
      expression = original + " AND (" + expression + ")";
    }
  } else if (hasOriginal) {
    expression = original;
  } else {
    expression = null;
  }

  if (expression !== current) {
    layer.definitionExpression = expression;
    //console.log("setDefExpr",layer.title,expression);
  }

  /*
  if (layer.type === "feature" && typeof layer.refresh === "function") {
    // TODO 6/1/18 JSAPI 4.8 issue, changing definitionExpression doesn't always update the layer
    layer.refresh();
  }
  */
}

export function selectFacility(view, criteria) {
  let is2D = (view && view.type === "2d");
  let layers = aiimUtil.getLayers(view);
  let aboveFacilities2D = false, found2D = false;
  perLayer(layers,(layer,fields) => {
    try {
      let expression;
      if (is2D) {
        if (!found2D && isFacilitiesLayer(layer)) found2D = true;
        expression = makeWhere_2D(layer,fields,criteria,aboveFacilities2D);
        if (found2D) aboveFacilities2D = true;
      } else {
        expression = makeWhere_3D(layer,fields,criteria);
      }
      setDefinitionExpression(layer,expression);
    } catch(ex) {
      console.error("Error setting definitionExpression",ex);
    }
  });
}
