import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import QueryAll from "../../spaceplanner/base/QueryAll";
import * as aiimUtil from "../util/aiimUtil";
import { IFloorAwareInfo } from "./Datasets";
import { getLevelsLayer } from "../../spaceplanner/base/sourceUtil";
import { CustomQueryTask } from "../../context/EsriLib";
import { escSqlQuote } from "../util/selectionUtil";
export default class Facilities extends Dataset {
  floorAwareInfo: IFloorAwareInfo;

  facilityIdField = FieldNames.FACILITY_ID;
  nameField = FieldNames.NAME;
  nameLongField = FieldNames.NAME_LONG;
  objectIdField = "objectid";
  type = "feature";

  constructor(props?: { floorAwareInfo?: IFloorAwareInfo }) {
    super(props)
    this.mixinProps(props);
  }

  checkSchema(): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const fi = this.floorAwareInfo;
      if (fi && fi.facilityInfo) {
        if (fi.facilityInfo.facilityIdField) {
          this.facilityIdField = fi.facilityInfo.facilityIdField;
        }
        if (fi.facilityInfo.nameField) {
          this.nameField = fi.facilityInfo.nameField;
        }
      }
      if (this.layer2D) {
        const layer = this.layer2D;
        this.objectIdField = layer.objectIdField;
        layer.when(() => {
          this.checkFieldNameProperty("facilityIdField",layer.fields);
          this.checkFieldNameProperty("nameField",layer.fields);
          this.checkFieldNameProperty("nameLongField",layer.fields);
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  /*
  getFacilityIdField(layer,fields) {
    return aiimUtil.findField(fields,FieldNames.FACILITY_ID);
  }
  */
  async containsLevels(id: string) {
    const layer = getLevelsLayer();
    if (!layer) return Promise.resolve(false);

    const url = Context.checkMixedContent(`${layer.url.replace(/\/+$/g, "")}/${layer.layerId}`);
    const queryTask = new CustomQueryTask({ url });
    const query: __esri.Query = new Context.instance.lib.esri.Query();
    const field = aiimUtil.findFieldName(layer.fields, this.facilityIdField);
    query.outFields = [field];
    query.returnGeometry = false;
    query.where = `(${field} = '${escSqlQuote(id)}')`;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    return (await queryTask.executeForCount(query)) > 0;   
  }
  getLabelText(feature) {
    let text;
    if (this.layer2D && this.layer2D.displayField) {
      text = aiimUtil.getAttributeValue(feature.attributes,this.layer2D.displayField);
    }
    // if (typeof text !== "string" || text.length === 0) {
    //   text = aiimUtil.getAttributeValue(feature.attributes,this.nameLongField);
    // }
    if (typeof text !== "string" || text.length === 0) {
      text = aiimUtil.getAttributeValue(feature.attributes,this.nameField);
    }
    return text;
  }

  getSource() {
    return Context.getInstance().aiim.datasets.categories.findSourceByKey("Facilities"); // TODO
  }

  isVisibleAtScale2D(view) {
    let layer = this.layer2D;
    if (this.subLayer) layer = this.subLayer;
    if (layer && layer.visible && view) {
      const scale = view.get("scale");
      const minOk = layer.minScale === 0 || scale <= layer.minScale;
      const maxOk = layer.maxScale === 0 || scale >= layer.maxScale;
      return layer.visible && minOk && maxOk;
    }
    return false;
  }

  queryAll(qaopts?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    qaopts = qaopts || {};
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts);
  }

  queryByGeometry(geometry,meters?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    query.geometry = geometry;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    if (typeof meters === "number" && meters > 0) {
      query.distance = meters;
      query.units = "meters";
    }
    return task.execute(query);
  }

  getVersionName(): string {
    return this.layer2D?.gdbVersion;
  }
  /*
  queryFacilityIdsByGeometry(geometry,meters?) {
    return this.queryByGeometry(geometry,meters).then(result => {
      //console.log("queryFacilityIdByGeometry.result",result);
      if (result && result.features) {
        const field = aiimUtil.findField(result.fields,FieldNames.FACILITY_ID);
        if (field) {
          const ids = [];
          result.features.forEach(feature => {
            const id = aiimUtil.getAttributeValue(feature.attributes,field.name);
            ids.push(id);
          });
          return ids;
        }
      }
      return null;
    });
  }
  */

}
