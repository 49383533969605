import React from "react";
import defaultThumbnail from "./../../../assets/defaultThumbnail.png";

import Context from "../../../context/Context";

const CSS = {
  card: "i-card",
  descriptor: "i--descriptor",
  left: "i--left",
  right: "i--right",
  thumbnail: "i--thumbnail",
  title: "i--title",
  info: "i--info",
  description: "i--description",
  actions: "i-actions",
  button: "i-button",
  link: "i-button-clear-no-border i-font-brand-primary"
};

export default class Card extends React.Component {

  constructor(props) {
    super(props);
    this.selectClicked = this.selectClicked.bind(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const item = this.props.item;

    //let thumbnailName = item.title;
    let thumbnailName = i18n.portalItemBrowser.thumbnail;
    let thumbnailUrl = item.thumbnailUrl;
    if (!thumbnailUrl) {
      thumbnailUrl = defaultThumbnail;
    }
    const thumbnail = (
      <img className={CSS.thumbnail} alt={thumbnailName} src={thumbnailUrl} />
    );

    const dt = item.modified || item.created;
    const date = lib.dojo.locale.format(dt,{selector:"date",formatLength:"short"});
    let infoText = i18n.portalItemBrowser.itemTypeOwnerDate;
    infoText = infoText.replace("{type}",item.displayName);
    infoText = infoText.replace("{owner}",item.owner);
    infoText = infoText.replace("{date}",date);
    const info = (
      <div key="info" className={CSS.info}>
        <span>{infoText}</span>
      </div>
    );

    let description = null, text;
    //text = item.description;
    if (!text) text = item.snippet;
    if (text) {
      description = (
        <div key="desc" className={CSS.description}
          dangerouslySetInnerHTML={{ __html: Context.sanitizeHtml(text)}}></div>
      );
    }

    // TODO should the token be appended to the url?
    //console.log("item",item);
    let href = item.portal.url + "/home/item.html?id=" + encodeURIComponent(item.id);
    href = Context.checkMixedContent(href);
    //href = aiimUtil.appendTokenToUrl(href);
    const actions = (
      <div key="actions" className={CSS.actions}>
        <a className={CSS.link} target="_blank" rel="noopener noreferrer"
          href={href}>{i18n.portalItemBrowser.details}</a>
        <button type="button" className={CSS.button} onClick={this.selectClicked}
          >{i18n.portalItemBrowser.select}</button>
      </div>
    );

    return (
      <div className={CSS.card}>
        <div key="descriptor" className={CSS.descriptor}>
          <div key="left" className={CSS.left}>
            {thumbnail}
          </div>
          <div key ="right" className={CSS.right}>
            <div key="title" className={CSS.title}>{item.title}</div>
            {info}
            {description}
          </div>
        </div>
        {actions}
      </div>
    );
  }

  selectClicked() {
    //console.log("item",this.props.item);
    if (this.props.onSelect) {
      this.props.onSelect(this.props.item);
    }
  }

}
