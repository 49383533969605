import styled from "styled-components";

import CalciteButton from "calcite-react/Button";
import { MenuItem as CalciteMenuItem } from "calcite-react/Menu";

const LabelGroup = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const ClearButton = styled(CalciteButton)`
  padding: 0.1rem;
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
`;

const MenuItem = styled(CalciteMenuItem)`
  white-space: normal;
`;

export { LabelGroup, ClearButton, SelectGroup, MenuItem };
