export function applyValues(feature,values) {
  if (!feature || !values) return;
  const attributes = feature.attributes;
  Object.keys(values).forEach(name => {
    if (attributes[name] !== values[name]) {
      attributes[name] = values[name];
    }
  });
}

export function expandGroup(ff,fieldName)  {
  ff.viewModel.inputs.forEach(f => {
    if (f.groupElement && f.state === "collapsed") {
      const found = f.groupElement.elements.some(el => {
        if (el.type === "field" && el.fieldName === fieldName) {
          return true;
        }
        return false;
      })
      if (found) f.state = "expanded";
    }
  })
}

export function hasInvalidInput(ff) {
  const result = {hasInvalid: false, fieldName: null, inputField: null}
  if (!ff) return result;
  const inputs = ff.viewModel.allFieldInputs;
  inputs.some(inputField => {
    if (inputField.element && !inputField.valid) {
      result.hasInvalid = true;
      result.fieldName = inputField.element.fieldName;
      result.inputField = inputField;
    }
    return result.hasInvalid;
  });
  return result;
}

export function scrollIntoView(ff,fieldName)  {
  setTimeout(() => {
    if (ff.container && fieldName && typeof ff.container.querySelector === "function") {
      const nd = ff.container.querySelector("[data-field-name='"+fieldName+"']");
      if (nd && typeof nd.scrollIntoView === "function") {
        nd.scrollIntoView({block: "center", inline: "nearest"});
      }
    }
  },100);
}
