// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import { SharingLevels } from "../../../common/components/Sharing";
import * as component from "../../../components/util/component";
import * as groupsUtil from "../../base/groupsUtil";

// Component specific modules (Component-styled, etc.)
import {
  ContentWrapper,
  HeaderContainer,
  Header,
  ContentContainer,
  InfoCardHeader,
  InfoCard,
  InfoGroup,
  InfoHeader,
  InfoItem
} from "../../styles/backstageStyles";

// App components

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

export default class Summary extends Component {
  _itemProperties;
  _versionProperties;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: false,
      chips: null
    });
  }
  
  componentDidMount() {
    groupsUtil.getGroups().then(res => {
      if (res) {
        const [groups, chips] = res;
        this.setState({chips: chips});
      }
    })
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const plan = Context.instance.spaceplanner.activePlan;
    const appName = Context.instance.configuration.appItem.title;
    const info = (plan && plan.getMetaInfo()) || {};
    const { chips } = this.state;

    let access, versionAccess, hasSharedItems = false;
    if (plan && plan.isVersioned) {
      access = plan.supportInfo && plan.supportInfo.serviceItem && plan.supportInfo.serviceItem.access;
      versionAccess = plan.versionInfo && plan.versionInfo.access;
    } else {
      access = plan && plan.planServiceItem && plan.planServiceItem.access;
    }
    if (access === SharingLevels.PRIVATE) {
      access = i18n.configurator.appItem.shareWith.private;
    } else if (access === SharingLevels.ORGANIZATION) {
      access = i18n.configurator.appItem.shareWith.organization;
    } else if (access === SharingLevels.SHARED) {
      access = i18n.configurator.appItem.shareWith.shared;
      hasSharedItems = chips && chips.length > 0;
    } else if (access === SharingLevels.PUBLIC) {
      access = i18n.configurator.appItem.shareWith.everyone;
    }
    if (versionAccess === SharingLevels.PRIVATE) {
      versionAccess = i18n.configurator.appItem.shareWith.private;
    } else if (versionAccess === SharingLevels.PUBLIC) {
      versionAccess = i18n.configurator.appItem.shareWith.public;
    }

    return (
      <ContentWrapper>
        <HeaderContainer>
          <Header>{i18n.spaceplanner.backstage.summary.caption}</Header>
        </HeaderContainer>
        <ContentContainer>
          <InfoCardHeader>
            {i18n.spaceplanner.backstage.summary.cards.general.label}
          </InfoCardHeader>
          <InfoCard>
            <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.appName}
              </InfoHeader>
              <InfoItem>{appName}</InfoItem>
            </InfoGroup>
            <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.planName}
              </InfoHeader>
              <InfoItem>{info.title}</InfoItem>
            </InfoGroup>
            <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.owner}
              </InfoHeader>
              <InfoItem>{info.owner}</InfoItem>
            </InfoGroup>
            <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.created}
              </InfoHeader>
              <InfoItem>{info.created}</InfoItem>
            </InfoGroup>

            <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.modified}
              </InfoHeader>
              <InfoItem>{info.modified}</InfoItem>
            </InfoGroup>

            {info.summary && <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.description}
              </InfoHeader>
              <InfoItem>{info.summary}</InfoItem>
            </InfoGroup>}

            {false && versionAccess && <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.branchVersionAccessLevel}
              </InfoHeader>
              <InfoItem>{versionAccess}</InfoItem>
            </InfoGroup>}
            
            {access && <InfoGroup>
              <InfoHeader>
                {i18n.spaceplanner.backstage.summary.cards.general.planSharingLevel}
              </InfoHeader>
              <InfoItem>{hasSharedItems
                ? i18n.spaceplanner.backstage.summary.cards.general.groups
                : access}
              </InfoItem>
            </InfoGroup>}
            
          </InfoCard>
        </ContentContainer>
      </ContentWrapper>
    );
  }
}
