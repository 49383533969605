import BaseClass from "../util/BaseClass";
import Context from "./Context";
import Topic from "./Topic";
import Views from "./Views";
import * as idleTimeOut from "../../src/util/idleTimeOut";
import * as localStore from "../../src/util/localStore";
import { ModalController } from "../common/components/Modal";
import React from "react";
import Office365 from "../components/main/More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import {
  getLoggedInUser,
  logout
} from "../components/main/More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";

export default class Starter extends BaseClass {
  considerNativeApp;
  showMsg;

  _checkBrowser() {
    const promise = new Promise<void>((resolve,reject) => {
      const context = Context.getInstance();
      try {
        const result = context.lib.esri.WebGLRequirements.check("3d");
        if (result) {
          context.supports3D = false;
          if (result.name === "webgl:major-performance-caveat-detected") {
            console.warn("Your browser isn't using hardware acceleration for rendering");
          } else {
            console.warn("Your browser doesn't seem to support WebGL");
          }
        }
      } catch(err) {
        context.supports3D = false;
        console.error("Error checking WebGLRequirements",err);
      }
      resolve();
    });
    return promise;
  }

  _checkLicense(context,portal) {

    const i18n = context.i18n;
    const checkOrgStatus =(capability)=> {
      const status = capability && capability.status;
      const id = capability && capability.id;
      let expMsg, cancelMsg;
      if (typeof status === "string") {
        if (status.toLowerCase() === "expired") {
          if (id === "indoors") expMsg = i18n.messages.noIndoorsSubscription_Expired;
          else if (id === "indoorsspaces") expMsg = i18n.messages.noIndoorsSpacesSubscription_Expired;
          else if (id === "indoorsmaps") expMsg = i18n.messages.noIndoorsMapsSubscription_Expired;
          return ({
            ok: false,
            expired: true,
            uiMsg: expMsg
          })
        } else if (status.toLowerCase() === "cancelled") {
          if (id === "indoors") cancelMsg = i18n.messages.noIndoorsSubscription_Cancelled;
          else if (id === "indoorsspaces") cancelMsg = i18n.messages.noIndoorsSpacesSubscription_Cancelled;
          else if (id === "indoorsmaps") cancelMsg = i18n.messages.noIndoorsMapsSubscription_Cancelled;
          return ({
            ok: false,
            cancelled: true,
            uiMsg: cancelMsg
          })
        }
      }
      return {
        ok: true,
        uiMsg: null
      }
    }

    const promise = new Promise((resolve,reject) => {
      const msg = "__noIndoorsSubscription__";
      let uiMsg = null;
      let id = encodeURIComponent(portal.id);
      if (!portal.id) id = encodeURIComponent("0123456789ABCDEF");
      let url = portal.restUrl;
      url += "/portals/"+ id + "/subscriptionInfo";
      const query = {f: "json"};
      const options = {query: query, method: "get", responseType: "json"};
      let indoorsMapsInfo, indoorsSpacesInfo, legacyInfo, orgLicenseType;

      Context.instance.lib.esri.esriRequest(url,options).then(result => {
        let ok = false;
        if (result && result.data && result.data.orgCapabilities && result.data.orgCapabilities.length > 0) {
          result.data.orgCapabilities.forEach(capability => {
            if (capability && capability.id === "indoors") {
              legacyInfo = checkOrgStatus(capability);
            } else if (capability && capability.id === "indoorsspaces") {
              indoorsSpacesInfo = checkOrgStatus(capability);
            } else if (capability && capability.id === "indoorsmaps") {
              indoorsMapsInfo = checkOrgStatus(capability);
            }
          });
        }

        if (legacyInfo && legacyInfo.ok) {
          ok = legacyInfo.ok;
          uiMsg = legacyInfo.uiMsg;
          orgLicenseType = "indoors";
        } else if (indoorsSpacesInfo && indoorsSpacesInfo.ok) {
          ok = indoorsSpacesInfo.ok;
          uiMsg = indoorsSpacesInfo.uiMsg;
          orgLicenseType = "indoorsspaces";
        } else if (indoorsMapsInfo && indoorsMapsInfo.ok) {
          if (Context.instance.isSP_or_FPE()) {
            ok = false;
          } else {
            ok = indoorsMapsInfo.ok;
            uiMsg = indoorsMapsInfo.uiMsg;
            orgLicenseType = "indoorsmaps";
          }
        } else if (indoorsSpacesInfo && !indoorsSpacesInfo.ok) {
          ok = indoorsSpacesInfo.ok;
          uiMsg = indoorsSpacesInfo.uiMsg;
          orgLicenseType = "indoorsspaces";

          // Licensing: Incorrect message displays when opening Viewer with an expired Indoors Maps license #5344
          if (indoorsMapsInfo && (context.config.appType === "indoors") &&
             (indoorsSpacesInfo.expired || indoorsSpacesInfo.cancelled)) {
            if (indoorsMapsInfo.expired) {
              uiMsg = i18n.messages.noIndoorsMapsSubscription_Expired;
              orgLicenseType = "indoorsmaps";
            } else if (indoorsMapsInfo.cancelled) {
              uiMsg = i18n.messages.noIndoorsMapsSubscription_Cancelled;
              orgLicenseType = "indoorsmaps";
            }
          }


        } else if (indoorsMapsInfo && !indoorsMapsInfo.ok) {
          ok = indoorsMapsInfo.ok;
          uiMsg = indoorsMapsInfo.uiMsg;
          orgLicenseType = "indoorsmaps";
        } else if (legacyInfo && !legacyInfo.ok) {
          ok = legacyInfo.ok;
          uiMsg = legacyInfo.uiMsg;
          orgLicenseType = "indoors";
        }

        if (!ok) {
          Context.instance.noIndoorsSubscriptionMsg = uiMsg;
          reject(msg)
        } else {
          Context.instance.orgLicenseType = orgLicenseType;
          resolve(ok)
        }
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _checkSignIn() {
    const promise = new Promise<void>((resolve,reject) => {
      const context = Context.getInstance();
      //const portalUrl = context.config.portalUrl;
      const sharingUrl = context.getSharingUrl();
      const esriId = context.lib.esri.esriId;

      const openApp = (authMode?) => {
        if (!authMode) {
          const urlUtils = context.lib.esri.urlUtils;
          const urlObject = urlUtils.urlToObject(window.location.href);
          if (urlObject && urlObject.query) {
            // force a sign-in if starting in configurator mode
            if (urlObject.query.configure === "true" ||
                urlObject.query.edit === "true") {
              authMode = "immediate"
            }
          }
          if (context.appMode.requiresImmediateAuthentication()) {
            authMode = "immediate";
          }
        }
        this._openApp(authMode).then(() => {
          resolve();
        }).catch(ex => {
          reject(ex);
        });
      };

      const oauthInfo = new context.lib.esri.OAuthInfo({
        appId: context.config.oauthAppId,
        portalUrl: context.config.portalUrl,
        // Uncomment the next line and update if using your own portal
        // portalUrl: "https://<host>:<port>/arcgis"
        // Uncomment the next line to prevent the user's signed in state from being shared
        //   with other apps on the same domain with the same authNamespace value.
        // authNamespace: "portal_oauth_inline",
        popup: false
      });
      esriId.registerOAuthInfos([oauthInfo]);

      esriId.checkSignInStatus(sharingUrl).then(cred => {
        // if (context.config.allowAnonymousAccess) {
        //   openApp();
        // } else {
        //   openApp("immediate");
        // }
        openApp("immediate");
      }).catch((ex) =>{
        const url = window.location.href;
        var match = url.match(/#.*[?&]error_description=([^&]+)(&|$)/);
        if(match) {
          let errorMessage = decodeURIComponent(match[1]);
          let isPortal = Context.instance.isLocalPortal;
          if(url.includes("//indoorsbld.esri.com/") && url.includes("/indoorsportal3/")) {
            isPortal = true;
          }
          const i18n = Context.instance.i18n;
          if(isPortal) errorMessage = i18n.messages.errorLicensePortal;
          else errorMessage = i18n.messages.errorLicenseOnline;
          const error = new Error(errorMessage);
          //@ts-ignore
          error.xtnCode = "__invalidUser__";
          reject(error);
          return;
        }
        if (context.config.allowAnonymousAccess) {
          //oauthInfo.popup = true;
          /*
          if (oauthInfo.popup) {
            esriId.on("credential-create",result => {
              if (result && result.credential) {
                const sameUrl = (result.credential.server === portalUrl);
                const sameOAuthInfo = (result.credential._oAuthCred &&
                  result.credential._oAuthCred.oAuthInfo === oauthInfo);
                if (sameUrl && sameOAuthInfo) {
                  this._reloadPortal();
                }
              }
            });
          }
          */
          openApp();
        } else {
          esriId.getCredential(sharingUrl);
          reject();
        }
      });

    });
    return promise;
  }

  _checkUser(context,portal) {
    if (context.appMode.isSP_or_FPE()) {
      const user = context.user;
      const isPortal = Context.instance.getPortal().isPortal;
      let ok = false;

      if(isPortal){
        ok = (user && (user.isAdmin() || user.isPublisher() || user.isFullDataEdit()|| user.isDataEdit()));
      } else {
        ok = (user && (user.isAdmin() || user.isPublisher()) || user.isFullDataEdit()|| user.isDataEdit());
      }

      if (!ok) {
        let errorMessage;
        if(isPortal) errorMessage= context.i18n.spaceplanner.application.higherPrivilegesRequiredEnterprise;
        else errorMessage = context.i18n.spaceplanner.application.editorRequiredOnline;
        const error = new Error(errorMessage);
        //@ts-ignore
        error.xtnCode = "__invalidUser__";
        return Promise.reject(error)
      }

      if(ok) {
        let key = localStore.makeUserKey(localStore.keys.toolsUnavailable, true);
        let val = localStore.getItem(key, true);
        let showMsg = true;
        if(val) showMsg = false;

        if (!user.canCreatePlan() && showMsg) {
          localStore.setItem(key, "yes", true);
          let msg = context.i18n.spaceplanner.application.someToolsUnavailable;
          let errorMessage = context.i18n.spaceplanner.application.higherPrivilegesRequiredPlans;
          ModalController.confirm({
            title: msg,
            hideCancel: true,
            content: (
              <div>{errorMessage}</div>
            )
          })
        }
      }

    return Promise.resolve();
  }
}

  _fix() {

    // TODO this should be url based, only reset maxAllowableOffset for AIIM layers
    const interceptor = {
      before: function(params) {
        //console.log("interceptor.before",params);

        // JSAPI 4.11 -> can't set esriDOTFeatureSets in RouteParameters
        if (params && params.requestOptions && params.requestOptions.query &&
            params.requestOptions.query.directionsOutputType) {
          if (params.requestOptions.query.directionsOutputType === "esriDOTComplete") {
            params.requestOptions.query.directionsOutputType = "esriDOTFeatureSets";
          }
          delete params.requestOptions.query.accumulateAttributeNames;
        }

        let wasUpdated = false;
        if (!wasUpdated && params && params.requestOptions && params.requestOptions.query &&
            params.requestOptions.query.maxAllowableOffset) {
          //console.log("****** interceptor.before.query.maxAllowableOffset",params.requestOptions.query);
          //console.warn("esriRequest:resetting maxAllowableOffset",params);
          params.requestOptions.query.maxAllowableOffset = 0;
          wasUpdated = true;
        }
        if (!wasUpdated && params && typeof params.url === "string" &&
            params.url.toLowerCase().indexOf("maxAllowableOffset") !== -1) {
          //console.log("*** interceptor.before.url.maxAllowableOffset",params.url,params.requestOptions.query);
          if (params.requestOptions && params.requestOptions.query) {
            console.warn("esriRequest:resetting maxAllowableOffset",params);
            params.requestOptions.query.maxAllowableOffset = 0;
            wasUpdated = true;
          }
        }

        if (params && typeof params.url === "string" &&
            params.url.indexOf("/applyEdits") !== -1) {
          //console.log("*** interceptor.before.url.applyEdits",params.url,params);
          if (params.requestOptions) {
            params.requestOptions.timeout = (60000 * 30);
          }
        }
      }
    };
    const requestConfig = Context.getInstance().lib.esri.esriConfig.request;
    if (Array.isArray(requestConfig.interceptors)) {
      requestConfig.interceptors.push(interceptor);
    } else {
      requestConfig.interceptors = [interceptor];
    }

  }

  displayToast(){
    let key = null;
    if(Context.instance.user.isAnonymous()) {
      const isSpacePlanner = Context.instance.appMode.isSP_or_FPE();
      if(!isSpacePlanner) key = localStore.keys.viewerSignOut;
      const value = localStore.getItem(key, "toastmsg");
      if(value) {
        const applicationReset = Context.instance.config.applicationReset;
        const timeoutInMilliseconds = applicationReset.timeoutMillis/1000;
        const i18n = Context.getInstance().i18n;
        let msg = i18n.configurator.idleTimeout.appReset.toastMessage;
        msg = msg.replace("{Idle Time}", timeoutInMilliseconds);
        localStore.removeItem(key,"toastmsg");
        Topic.publish(Topic.ShowToast,{message: msg});
      }
    }
  }

  _listen() {

    Topic.subscribe(Topic.AppStarted,(params) => {
      const config = Context.instance.config;
      if (config.workspaceReservation && config.workspaceReservation.enableHotel) {
        Context.getInstance()._loadHotelingUnits()
        Context.getInstance()._loadAreas()
      }
    });

    Topic.subscribe(Topic.ViewsReloaded,(params) => {
      const config = Context.instance.config;
      if (config.workspaceReservation && config.workspaceReservation.enableHotel) {
        Context.getInstance()._loadHotelingUnits()
        Context.getInstance()._loadAreas()
      }
    });

    Topic.subscribe(Topic.ReloadWorkspace,(params) => {
      const config = Context.instance.config;
      if (config.workspaceReservation && config.workspaceReservation.enableHotel) {
        Context.getInstance()._loadHotelingUnits();
        Context.getInstance()._loadAreas();
      }
    });

    Topic.subscribe(Topic.SignInClicked,() => {
      const lib = Context.getInstance().lib;
      const sharingUrl = Context.getInstance().getSharingUrl();
      const options = {
        oAuthPopupConfirmation: false
      };
      lib.esri.esriId.getCredential(sharingUrl,options).then(cred => {
      }).catch((ex) =>{
        // TODO need to inform user?
        console.log("Signin failed");
        console.error(ex);
      });
    });

    Topic.subscribe(Topic.SignOutClicked,() => {
      let bookingSystem = Office365.getInstance()
      const user = getLoggedInUser(bookingSystem)
      // Check configurator
      const configuration = Context.instance.configuration
      const configurables = configuration.extractConfigurables()
      const hotelingEnabled = configurables.workspaceReservation 
      // && configurables.workspaceReservation.enabled

      const lib = Context.getInstance().lib;
      const esriId = lib.esri.esriId;
      const useRevokeOnly = false;
      let url = null;
      Promise.resolve().then(() => {
        if (useRevokeOnly) {
          esriId.destroyCredentials();
          window.location.reload();
        } else {
          esriId.destroyCredentials();
          const redirectUri = window.location.href;
          const clientId = Context.getInstance().config.oauthAppId;
          const sharingUrl = Context.getInstance().getSharingUrl();
          url = sharingUrl + "/rest/oauth2/signout"
          url += "?client_id=" + encodeURIComponent(clientId);
          url += "&redirect_uri=" + encodeURIComponent(redirectUri);
          //console.log("signout url",url);
          if (user && hotelingEnabled) {
            return Promise.resolve(url)
          } else {
            window.location.href = url;
          }
        }
      }).then((url) => {
        if (user && hotelingEnabled) {
          logout(bookingSystem, url)
        }
      }).catch(e => {
        console.error("Problem logging out of Indoors", e);
      })
    });

  }

  _openApp(authMode?) {
    const context = Context.getInstance();
    const lib = context.lib;
    const portalUrl = context.config.portalUrl;
    lib.esri.esriConfig.portalUrl = portalUrl;
    const portal = new lib.esri.Portal();
    if (authMode) portal.authMode = "immediate";
    let loadData = true;
    return portal.load().then(result => {
      //console.log("loadPortal:result", result);
      context.portal = portal;
      context.user.setPortal(portal);
    }).then(result => {
      return this._checkLicense(context,portal);
    }).then(result => {
      return this._checkUser(context,portal);
    }).then(() => {

      // for web-tier authentication (pki,iwa,ldap,saml),
      // we need to acquire a token that can be used for
      // requests to the admin endpoint for hosted services
      try {
        if (portal && portal.user &&
            portal.sourceJSON && portal.sourceJSON.isWebTierAuth &&
            context.appMode.isSP_or_FPE()) {
          const esriId = context.lib.esri.esriId;
          const appid = context.config.wtappid || "indoorsweb";
          //console.log("pre.getPlatformSelf",appid,portalUrl)
          esriId._getPlatformSelf(portalUrl,appid).then(result => {
            //console.log("getPlatformSelf.result",result)
            if (result && result.token) {
              Context.instance._platformSelfTKN = result.token;
            }
            //console.log("getPlatformSelf.context",context)
          }).catch(exx => {
            console.error("getPlatformSelf.error",exx)
          })
        }
      } catch(ex2) {
        console.error("checking.getPlatformSelf.error",ex2);
      }

    }).then(result => {
      return context._load();
    }).then(result => {
      if (this.considerNativeApp) {
        if (context.config.nativeApp && context.config.nativeApp.showIOS) {
          loadData = false;
        }
      }
      if (loadData) {
        context.views = new Views();
        return context.views.load();
      }
    }).then(result => {
      if (loadData) return context.aiim.load();
    }).then(result => {
      if (loadData) {
        idleTimeOut.start()
        Topic.publish(Topic.AppStarted,{});
      }
    });
  }

  reloadViews(params) {
    const context = Context.getInstance();
    Context.getInstance().views.destroy();
    Context.getInstance().aiim.reset();
    Context.getInstance().session.viewsReloading();
    context.views = new Views({
      startIn3D: params && params.startIn3D
    });
    return context.views.load().then(() => {
      return context.aiim.load();
    }).then(() => {
      Topic.publish(Topic.ViewsReloaded, params || {});
    });
  }

  reloadPortal=()=> {
    const context = Context.getInstance();
    const portal = new context.lib.esri.Portal();
    console.log("Reload portal", portal);
    portal.authMode = "immediate";

    portal.load().then(function(result){
      //console.log("loadPortal.result:",result);
      context.portal = portal;
      context.user.setPortal(portal);
      Topic.publish(Topic.SignedIn,{});
    }).catch(function(ex2){
      // TODO need to inform user?
      console.log("Error loading portal:");
      console.error(ex2);
    });
  }

  _showMessage(msg) {
    try {
      const el = document.createElement("div");
      el.style.padding = "20px";
      el.innerHTML = Context.sanitizeHtml(msg);
      document.body.appendChild(el);
    } catch (ex) {
      console.error("Startup: error showing message:",msg,ex);
    }
  }

  startupApp() {
    const promise = new Promise<void>((resolve,reject) => {
      Office365.appStarting();
      const context = Context.getInstance();

      /*
      context._init().then(() => {
        this._listen();
        this._fix();
      }).then(() => {
        return this._checkBrowser();
       */

      this._listen();
      this._fix();
      this._checkBrowser().then(() => {
        return this._checkSignIn();
      }).then(() => {
        context.uiMode.hideSkeleton();
        resolve();
      }).catch(ex => {
        if (context.uiMode) {
          context.uiMode.hideSkeleton();
        } else {
          document.body.classList.remove("i-skeleton-on");
          document.getElementById("skeleton").style.display = "none";
        }
        reject(ex);
      });
    });
    return promise;
  }

}
