import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";

/*
  transactions

    setPeopleMoveDates

*/

export function prepare(task) {
  const promise = new Promise((resolve,reject) => {
    const updates = [], undos = [];
    const layer = sourceUtil.getPeopleLayer();
    const fields = layer.fields;
    const keyField = layer.globalIdField;
    const dateField = aiimUtil.findField(fields,FieldNames.PEOPLE_MOVE_DATE).name;
    const dt = task.moveDate.getTime();

    task.globalIds.forEach(gid => {
      let f = task.changes.people.featuresByGlobalId[gid];
      const update = {attributes: {}};
      update.attributes[keyField] = f.attributes[keyField];
      update.attributes[dateField] = dt;
      updates.push(update);
      const undo = {attributes: {}};
      undo.attributes[keyField] = f.attributes[keyField];
      undo.attributes[dateField] = f.attributes[dateField];
      undos.push(undo);
    });

    const transactionInfo = transaction.makeTransactionInfo();
    if (updates.length > 0) {
      transactionInfo.peopleEdits = {updates: updates};
      transactionInfo.undo.peopleEdits = {updates: undos};
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undos
      });
    }

    resolve(transactionInfo);
  });
  return promise;
}
