import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import * as groupsUtil from "../../base/groupsUtil";

import Form, {
  FormControl,
  FormControlLabel
} from "calcite-react/Form";
import TextField from "calcite-react/TextField";
import ShareWith from "./ShareWith";
import { SharingLevels } from "../../../common/components/Sharing";

export default class VersionProperties extends React.Component {
  componentId;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      title: "",
      summary: "",
      groups: [],
      groupIds: [],
      access: SharingLevels.PRIVATE
    });
    this.onChangeSummary = this.onChangeSummary.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.onMount === "function") this.props.onMount(this);
    groupsUtil.getGroups().then(res => {
      if (res){
        const [groups, chips] = res;
        this.setState({groups: groups});
      }
    }).catch(err => console.log(err));
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getItemInfo() {
    const state = this.state;

    let groupIds = null;
    if (state.groupIds && state.groupIds.length > 0) {
      if (state.access !== SharingLevels.ORGANIZATION) {
        groupIds = state.groupIds;
      }
    }

    let accessPermission = SharingLevels.PRIVATE;
    if (state.access !== SharingLevels.PRIVATE) {
      accessPermission = SharingLevels.PUBLIC;
    } else if (groupIds && groupIds.length > 0) {
      accessPermission = SharingLevels.PUBLIC;
    }
    return {
      title: state.title.trim(),
      description: state.summary.trim(),
      access: state.access,
      accessPermission: accessPermission,
      groupIds: groupIds
    };
  }
  
  onChangeSharing(access, groupIds) {
    const state = { access };
    if (access === SharingLevels.ORGANIZATION) {
      state.groupIds = [];
    } else if (groupIds) {
      state.groupIds = groupIds;
    }
    this.setState(state);
  }

  onChangeSummary(evt) {
    this.setState({ summary: evt.target.value });
  }

  onChangeTitle = evt => {
    this.setState({ title: evt.target.value });
  };

  onSubmit = (evt) => {
    if (evt) evt.preventDefault();
    if (evt) evt.stopPropagation();
    if (this.props.submitHandle) {
      const title = this.state.title.trim();
      if (title.length > 0) {
        this.props.submitHandle();
      }
    }
  };

  render() {
    const i18n = Context.instance.i18n;
    const id = this.componentId;
    const horizontal = true;
    const cls = horizontal ? "i--form i--form-horizontal" : "i--form";
    const title = this.state.title;
    const summary = this.state.summary;
    const access = this.state.access;

    /*
    if (showAccess) {
      accessNode = (
        <FormControl horizontal={horizontal}>
          <Fieldset name="sharingOptions">
            <Legend className="i--form-label">
              {i18n.configurator.appItem.shareWith.caption}
            </Legend>
            <Radio
              value="private"
              defaultChecked={access === "private"}
              onChange={this.onChangeAccess_Private}
            >
              {i18n.configurator.appItem.shareWith.private}
            </Radio>
            <Radio
              value="protected"
              defaultChecked={access === "protected"}
              onChange={this.onChangeAccess_Protected}
            >
              {i18n.configurator.appItem.shareWith.organization}
            </Radio>
            <Radio
              value="public"
              defaultChecked={access === "public"}
              onChange={this.onChangeAccess_Public}
            >
              {i18n.configurator.appItem.shareWith.everyone}
            </Radio>
          </Fieldset>
        </FormControl>
      );
    }
    */

    return (
      <Form className={cls} onSubmit={this.onSubmit}>
        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-title-label"}
            htmlFor="title"
            className="i-new-plan i--form-label"
          >
            <span className="i--required">
              {i18n.configurator.appItem.title}
            </span>
          </FormControlLabel>
          <TextField
            id={id + "-title"}
            defaultValue={title}
            type="text"
            fullWidth={true}
            maxLength="62"
            placeholder={i18n.spaceplanner.backstage.newPlan.placeholder}
            onChange={this.onChangeTitle}
          />
        </FormControl>

        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-summary-label"}
            htmlFor="summary"
            className="i-new-plan i--form-label"
          >
            {i18n.configurator.appItem.summary}
          </FormControlLabel>
          <TextField
            id={id + "-summary"}
            defaultValue={summary}
            type="textarea"
            fullWidth={true}
            maxLength="62"
            placeholder={i18n.spaceplanner.backstage.newPlan.placeholder}
            onChange={this.onChangeSummary}
          />
        </FormControl>

        <ShareWith 
          horizontal={horizontal}
          shareWith={access} 
          disallowEveryone={true}
          assignedGroups={[]}
          groups={this.state.groups}
          onChange={this.onChangeSharing.bind(this)} />        

        
      </Form>
    );
  }
}
