import { CalciteP } from 'calcite-react/Elements/Elements-styled';
import React from 'react'
import { ModalController } from '../../../../common/components/Modal';
import Button from "../../../../common/components/Button";
import Context from '../../../../context/Context';
import { Container } from '../../../styles/Common/listHeader';
import CalciteSearch from "calcite-react/Search";
import Search from '../Search';
import * as component from "../../../../components/util/component";
import * as reviewersUtil from "../../../base/ReviewerManagement/reviewersUtil";

// Styled Components
import { Panel } from "../../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemSubtitle,
  EmptyAlert
} from "../../../styles/Common/list";
import Items from '../Items';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

import { CalciteH5 } from "calcite-react/Elements";
import Loader from 'calcite-react/Loader';


export default class AddReviewer extends React.Component {
  constructor(props) {
    super(props)
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 120,
    });
    this.state = component.newState({
      searchText: '',
      users: [],
      selectedUser: null,
      queryStart: 0,
      isQuerying: true
    });
  }

  componentDidMount() {
    this.queryUsers(null, 0);
  }

  getPlanReviewers = () => {
    return this.props.planReviewers
  }

  queryUsers = async (text, start) => {
    const { users } = this.state;

    try {
      const response = await reviewersUtil.getPortalUsers({ text, start });
      
      let list = [];
      let nextStart = 0;
      if (response && response.data && response.data.results) {
        list = response.data.results;
        nextStart = response.data.nextStart;
      }

      if (start > 0 && users.length > 0) {
        users.forEach((user) => {
          if (!list.includes(user)) {
            list.push(user);
          }
        });
      }

      if (list.length > 0) {
        list.sort((a, b) => {
          let aFullName = a.fullName;
          if (!aFullName && a.firstName && a.lastName) {
            const { firstName, lastName } = a;
            aFullName = `${firstName} ${lastName}`;
          }
          if (!aFullName) {
            aFullName = "";
          }

          let bFullName = b.fullName;
          if (!bFullName && b.firstName && b.lastName) {
            const { firstName, lastName } = b;
            bFullName = `${firstName} ${lastName}`;
          }
          if (!bFullName) {
            bFullName = "";
          }
          
          return (
            aFullName.toLowerCase() > bFullName.toLowerCase() 
            ? 1
            : aFullName.toLowerCase() < bFullName.toLowerCase() 
              ? -1
              : 0
          );
        });
      }

      
      this.setState({ 
        users: list || [],
        isQuerying: false,
        queryStart: nextStart
      });
    } catch (e) {
      console.error(e);
      this.setState({ 
        users: [],
        isQuerying: false,
        nextStart: 0
      });
    }
  }

  searchTextChanged = (text) => {
    text = text || ''
    this.setState({
      selectedUser: null,
      searchText: ''
    })
    this.queryUsers(text, 0)
  }

  handleSelection = (user) => {
    if (typeof this.props.onSelectionChanged === "function") this.props.onSelectionChanged(user)
    this.setState({
      selectedUser: user
    })
  }

  render() {
    const i18n = Context.instance.i18n
    const placeholder = i18n.spaceplanner.reviewers.searchPlaceholder
    return (
      <Container className="i-no-outerscroll">
        <Panel bordered>
          <Search
            fullWidth
            minimal
            autoFocus
            placeholder={placeholder}
            searchTextChanged={this.searchTextChanged}
            style={{marginTop: '0.1rem'}}
          />
          {this.renderUsers()}
        </Panel>
      </Container>
    );
  }

  renderUsers() {
    const { isQuerying, users } = this.state;

    if (isQuerying) {
      return (<Loader color="#004d4c" sizeRatio={1} style={{ marginTop: '3rem' }} />);
    }
    if (!users || users.length === 0) {
      const i18n = Context.instance.i18n;
      return (
        <EmptyAlert key="noResults">
          <CalciteH5>{i18n.messages.noResults}</CalciteH5>
        </EmptyAlert>
      );
    }

    const userCards = users.map((user) => {
      return this.renderUser(user)
    });

    return (
      <div style={{flexGrow: 1, maxHeight: '24rem', overflow: 'auto'}}>
        {userCards}
        {this.renderMoreButton()}
      </div>
    )
  }

  renderUser(user) {
    // Try fullName first
    let fullName = user.fullName;

    // Otherwise... try to combine first and last names
    if (!fullName && user.firstName && user.lastName) {
      const { firstName, lastName } = user;
      fullName = `${firstName} ${lastName}`;
    }

    // If still nothing, make it blank?
    if (!fullName) {
      fullName = "";
    }

    const selected = user === this.state.selectedUser
    const planReviewers = this.getPlanReviewers()
    const portalUser = Context.instance.user && Context.instance.user.portalUser
    const loggedInUsername = portalUser && portalUser.username
    let isUnassigned = true
    for (let i = 0; i < planReviewers.length; i++) {
      const pr = planReviewers[i]
      if (pr.username === user.username) {
        isUnassigned = false
        break
      }
    }
    if (loggedInUsername === user.username) isUnassigned = false
    return isUnassigned ? (
      <ListItem 
        key={user.id}
        selected={selected}
        addReviewer
        onClick={() => this.handleSelection(user)}>
        <ListItemTitle>{fullName}</ListItemTitle>
        <ListItemSubtitle>{user.username}</ListItemSubtitle>
      </ListItem>
    ) : null
  }

  renderMoreButton() {
    const { queryStart } = this.state;
    if (queryStart < 0) {
      return null;
    }

    const i18n = Context.getInstance().i18n;
    return (
      <ListItem key={"more"} moreButton>
        <Button onClick={this.moreClicked}>{i18n.general.more}</Button>
      </ListItem>
    );
  }

  moreClicked = () => {
    const { queryStart: start, searchText } = this.state;

    const text = typeof searchText === "string" && searchText.trim().length > 0 
      ? searchText 
      : null;
    this.queryUsers(text, start);
  }

  static showModal(props) {
    let content, controller, okButton, selected;

    const onOK = obj => {
      if (props && props.onOK) {
        props.onOK(selected);
      }
    };

    let modalProps = {
      title: props && props.title,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: Context.instance.i18n.spaceplanner.reviewers.addReviewerLabel,
      onOK: onOK,
      dialogStyle: { height: "65vh", width: "35vw" }
    };
    modalProps.onMountOKButton = btn => {
      okButton = btn;
      okButton.disable();
    };

    const selectProps = {
      planReviewers: props.planReviewers,
      onSelectionChanged: (sel) => {
        if (okButton) {
          if (sel) okButton.enable()
          else okButton.disable()
        } 
        selected = sel
      }
    }

    content = <AddReviewer {...selectProps} />;
    controller = new ModalController({});
    controller.show(modalProps, content);
  }
}