import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import CalciteSideNav from "calcite-react/SideNav";
import { CalciteH3, CalciteH4 } from "calcite-react/Elements";
import CalciteButton from "calcite-react/Button";
import Card, { CardContent } from "calcite-react/Card";

const Container = styled.div`
  z-index: ${props => props.theme.levels.top};
  display: flex;
  height: 100%;
`;

const SideNav = styled(CalciteSideNav)`
  position: relative;
  z-index: 10;
  width: ${props => unitCalc(props.theme.baseline, 4, "*")};
  border: 0;
  background: ${props => props.theme.palette.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${props => props.theme.palette.lightestGray};
`;

const ButtonSection = styled.div`
  border-bottom: 1px solid ${props => props.theme.palette.lightestGray};
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled(CalciteH4)`
  align-content: stretch;
  padding-inline-start: ${props => unitCalc(props.theme.baseline, 3, "/")};
  padding-inline-end: ${props => unitCalc(props.theme.baseline, 3, "/")};
  margin: 0.7rem auto;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 0.9rem;
`;

const ButtonLabel = styled.div`
  margin-block-start: .375rem;
  max-width: 5rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  white-space: normal;
  line-height: 1.2;
`;

const CustomButton = styled(CalciteButton)`
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  padding-inline-start: ${props => unitCalc(props.theme.baseline, 3, "/")};
  padding-inline-end: ${props => unitCalc(props.theme.baseline, 3, "/")};
`;

const Stat = styled.div`
  font-size: ${props => props.theme.baseline};
  text-align: center;
`;

const CenteredWrapper = styled.div`
  text-align: center;
  margin-bottom: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const ContentWrapper = styled.div`
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background: ${props => props.theme.palette.background};
  display: flex;
  flex-direction: column;

  ${props =>
    props.areas &&
    css`
      max-height: 53rem;
    `};
`;

const StyledCard = styled(Card)`
  margin: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const StyledCardContent = styled(CardContent)`
  margin: ${props => unitCalc(props.theme.baseline, 2, "/")};
  padding: 0;
`;

const HeaderContainer = styled.div`
  position: relative;
  background: ${props => props.theme.palette.white};
  border-bottom: 1px solid ${props => props.theme.palette.lightestGray};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const Title = styled(CalciteH3)`
  margin-bottom: 0;
  font-size: ${props => props.theme.baseline};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

export {
  Container,
  SideNav,
  ButtonSection,
  ButtonContainer,
  SubTitle,
  ButtonLabel,
  CustomButton,
  Stat,
  CenteredWrapper,
  ContentWrapper,
  StyledCard,
  StyledCardContent,
  HeaderContainer,
  TitleRow,
  Title
};
