import React from "react";
import {connect} from "react-redux";

import Context from "../../../context/Context";
import EventDetails from "../Events/EventDetails";
import GraphicFeature from "./GraphicFeature";
import ItemDetails from "./ItemDetails";
import ItemReference from "../../../aiim/base/ItemReference";
import NonAiimFeature from "./NonAiimFeature";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as hitTest from "../../../aiim/util/hitTest";
import * as itemUtil from "../../../aiim/util/itemUtil";
import * as mapUtil from "../../../util/mapUtil";
import * as shareUtil from "./shareUtil";
import QRCode from "./QRCode";

let COUNTER = 0;

const CSS = {
  main: "i-infopanel",
  expanded: "i-expanded",
  infoPanelOpen: "i-infopanel-open",
  backnext: "i-infopanel-backnext",
  linkDisabled: "disabled",
  getQR: "i-getQR-btn",
  infopanelContainer: "i-infopanel-container"
};

class InfoPanel extends React.Component {

  stack = [];
  stackPosition = -1;
  activeItem = null
  realTimeLocationPinActive = false;

  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.state = component.newState({
      showFooter: true,
      showQRPopup: false
    });
    this.realTimeToggled = this.realTimeToggled.bind(this);
    this.stackStepBack = this.stackStepBack.bind(this);
    this.stackStepNext = this.stackStepNext.bind(this);
  }

  componentDidMount() {
    this._listen();
  }

  componentWillUnmount() {
    //console.log("InfoPanel::componentWillUnmount");
    component.componentWillUnmount(this);
  }

  fixZ(stackItem) {
    const datasets = Context.getInstance().aiim.datasets
    const categories = datasets.categories;
    const levels = datasets.levels;
    const sourceKey = stackItem && stackItem.sourceKey;
    const searchResult = stackItem && stackItem.searchResult;
    const source = sourceKey && categories.findSourceByKey(sourceKey);
    const feature = searchResult && searchResult.feature;
    if (levels && source && feature) {
      levels.fixZ(source,feature);
    }
  }

  hide(ignoreGraphic) {
    if (!this.props.rdxInfoPanelOpen) return;
    Rdx.setValue(this,Rdx.Keys.INFOPANEL_OPEN,null);
    document.body.classList.remove(CSS.infoPanelOpen);
    if (ignoreGraphic) return;
    const views = Context.getInstance().views;
    mapUtil.clearLocationGraphic(views && views.activeView);
    mapUtil.clearLocationGraphic(views && views.getInactiveView());
    Topic.publish(Topic.InfoPanelClosed, this.activeItem)
  }

  _listen() {
    component.own(this,[

      Topic.subscribe(Topic.CloseInfoPanel, params => {
        const siteChanged = params.siteChanged;
        const aiim = Context.getInstance().aiim;
        const dataset = aiim && aiim.datasets && aiim.datasets.facilities;
        const facilitiesSource = dataset && dataset.getSource();
        const facilitiesSourceKey = facilitiesSource && facilitiesSource.key
        const activeSourceKey = this.activeItem && this.activeItem.sourceKey;
        if (siteChanged && activeSourceKey && activeSourceKey !== facilitiesSourceKey) return;
        this.hide();
      }),

      Topic.subscribe(Topic.DirectionsClicked,params => {
        if (!this.props.rdxInfoPanelOpen) return;
        const uiMode = Context.getInstance().uiMode;
        if (uiMode.modeIsHorizontalSplit() || uiMode.modeIsVerticalSplit()) {
          this.hide();
        }
      }),

      Topic.subscribe(Topic.ReloadViews,params => {
        this.hide(true);
      }),

      Topic.subscribe(Topic.ShowSearchResult,params => {
        this.showSearchResult(params);
      }),

      Topic.subscribe(Topic.ShowInfoPanelGraphic,() => {
        if (!this.props.rdxInfoPanelOpen) return;
        const stackItem = this.stack[this.stackPosition];
        if (stackItem) {
          this.zoomAndHighlight(stackItem,{
            zoom: true,
            highlight: true,
            trackRecent: false
          });
        }
      }),

      Topic.subscribe(Topic.ViewClicked,params => {
        const context = Context.getInstance();
        const allowNonAiim = context.config.allowNonAiimIdentify;
        hitTest.search(params.view,params.event,allowNonAiim).then(hits => {
          if (hits && hits.length > 0) {
            const hit = hits[0];
            const zoom = !!(hit.source && hit.source.isAiimFacilities());
            this.showSearchResult({
              sourceKey: hit.sourceKey,
              searchResult: hit.searchResult,
              isNonAiimFeature: !!hit.isNonAiimFeature,
              zoom: zoom,
              highlight: true,
              trackRecent: true
            });
            Topic.publish(Topic.HideHotelGraphic, { searchResult: hit.searchResult });
          } else {
            let item = new ItemReference();
            let pointGeometry = null;
            if(params.event && params.event.mapPoint){
              pointGeometry = params.event.mapPoint;
            } else if(params.event && params.event.screenPoint){
              pointGeometry = params.view.toMap(params.event.screenPoint);
            }
            if(pointGeometry){
              item.fromGeometry(pointGeometry);
              this.showSearchResult({
                sourceKey: item.sourceKey,
                searchResult: item.searchResult,
                zoom: true,
                highlight: true,
                trackRecent: true
              });
              Topic.publish(Topic.HideHotelGraphic, { searchResult: item.searchResult });
            }
          }

        }).catch(ex => {
          console.log("Mapclick error:");
          console.error(ex);
        });
      }),

      // original floor-filter
      Topic.subscribe(Topic.LevelSelected, info => {
        this.levelSelected(info);
      }),

      // core floor-filter
      Topic.subscribe(Topic.LevelSelected2, info => {
        this.levelSelected(info);
      })

    ]);
  }

  levelSelected(info) {
    Topic.publish(Topic.ClearToast,{});
    const stackItem = this.stack[this.stackPosition];
    if (!this.props.rdxInfoPanelOpen) return;
    if(!stackItem) return;
    if(stackItem.isNonAiimFeature) return;
    const view = Context.getInstance().views.activeView;
    const inactiveView = Context.getInstance().views.getInactiveView();
    const searchResult = stackItem.searchResult;
    let feature = searchResult && searchResult.feature;
    if (stackItem.trackingInfo) feature = stackItem.trackingInfo.feature;
    try {
      if(stackItem && feature && feature.geometry){
        const isSameFacility = (stackItem && stackItem.levelData && stackItem.levelData.facilityId) ===
          (info && info.levelData && info.levelData.facilityId);
        let isSameLevel= (stackItem && stackItem.levelData && stackItem.levelData.levelId) ===
          (info && info.levelData && info.levelData.levelId);
        let options= {
          text:stackItem.title,
          levelData: stackItem.levelData,
          isDifferentLevel: !isSameLevel && isSameFacility
        }

        if (stackItem.trackingInfo) {
          options.type = "realtime";
          options.z = feature.geometry.z;
          options.horizontalAccuracy = stackItem.trackingInfo.horizontalAccuracy;
          options.verticalAccuracy = stackItem.trackingInfo.verticalAccuracy;
        }

        if(view && view.type ==="2d"){
          if(options && options.isDifferentLevel){
            const i18n = Context.getInstance().i18n;
            let msg= i18n.map.nameIsOnFloorPattern;
            msg=msg.replace("{name}",options.text).replace("{floor}",options.levelData.levelNumber);
            Topic.publish(Topic.ShowToast,{
              message: msg
            });
            options.makeTransparent = true;
          } else if (this.realTimeLocationPinActive) {
            options.makeSolid = true
          }
          mapUtil.addLocationGraphic(view,feature.geometry,options)
        }
        if(inactiveView && inactiveView.type ==="2d") {
          if (!isSameLevel && isSameFacility) {
            options.makeTransparent = true
          } else if (this.realTimeLocationPinActive) {
            options.makeSolid = true;
          }
          mapUtil.addLocationGraphic(inactiveView,feature.geometry,options)
        }
      }
    } catch(ex) {
      console.error("ViewMode::Topic.LevelSelected",ex);
    }

  }

  setRealTimeLocationPinActive = (active) => {
    this.realTimeLocationPinActive = active;
  }

  makeContent(stackItem) {
    // TODO the feature may have changed since it was last viewed,
    // when should it be refreshed?
    try {
      const isNonAiimFeature = stackItem.isNonAiimFeature;
      const infoPanelKey = stackItem.infoPanelKey;
      const sourceKey = stackItem.sourceKey;
      const searchResult = stackItem.searchResult;
      const backNextButtons = this.makeBackNextButtons();
      const isBookWorkspacePanel = stackItem.isBookWorkspacePanel;
      const isMeetingRoomsPanel = stackItem.isMeetingRoomsPanel;
      const { onlyRealTime, trackingInfo, showLocations } = stackItem;
      // const isReservation = sourceKey === "Reservations";

      // if (isReservation) {
      //   // const { reservation } = stackItem;
      //   const item = new ItemReference();
      //   item.fromSearchResult(sourceKey, searchResult);
      //   return (
      //     <div className={CSS.infopanelContainer}>
      //       <ReservationDetails item={item} />
      //     </div>
      //   );
      // } else if (isNonAiimFeature) {
      if (isNonAiimFeature) {
        return (
          <NonAiimFeature key={infoPanelKey} infoPanelKey={infoPanelKey}
            searchResult={searchResult} backNextButtons={backNextButtons} />
        );
      }
      else if (sourceKey === "standalone") {
        const item = new ItemReference();
        item.fromGeometry(stackItem.searchResult.feature.geometry,{
          uniqueId: stackItem.searchResult.key,
          name: stackItem.searchResult.name,
          standaloneNumber: stackItem.searchResult.standaloneNumber
        });
        return (
          <GraphicFeature key={infoPanelKey} infoPanelKey={infoPanelKey}
            searchResult={searchResult} item={item} backNextButtons={backNextButtons} />
        );
      }
      else {
        const item = new ItemReference();
        item.fromSearchResult(sourceKey,searchResult);
        stackItem.title=item.getTitle();

        if (sourceKey === "Events") {
          return (
            <div className={CSS.infopanelContainer}>
              <EventDetails key={infoPanelKey} infoPanelKey={infoPanelKey} item={item} backNextButtons={backNextButtons} />
            </div>
          );
        } else {
          return (
            <div className={CSS.infopanelContainer}>
              <ItemDetails 
                key={infoPanelKey} 
                infoPanelKey={infoPanelKey} 
                item={item}
                showLocations={showLocations}
                onlyRealTime={onlyRealTime}
                trackingInfo={trackingInfo}
                realTimeToggled={this.realTimeToggled} 
                realTimePinActive={this.setRealTimeLocationPinActive} 
                backNextButtons={backNextButtons}
                isBookWorkspacePanel={isBookWorkspacePanel} 
                isMeetingRoomsPanel={isMeetingRoomsPanel}/>
            </div>
          );
        }
      }
    } catch(ex) {
      console.error("Error creating InfoPanel content:",ex);
    }
    return null;
  }

  newInfoPanelKey() {
    return "InfoPanel-" + COUNTER++;
  }

  realTimeToggled(mode) {
    this.setState(state => {
      return {
        showFooter: (mode !== "realtime")
      };
    });
  }

  getTitle() {
    if (this.stack.length === 0) return null;
    const stackItem = this.stack[this.stackPosition];
    try {
      const sourceKey = stackItem.sourceKey;
      const searchResult = stackItem.searchResult;
      if (sourceKey === "standalone") {
        return stackItem.searchResult.name
      }
      else {
        const item = new ItemReference();
        item.fromSearchResult(sourceKey,searchResult);
        return item.getTitle();
      }
    } catch (ex) {
      console.error("Error getting title")
    }
    return null
  }

  render() {
    const style = {};
    if (!this.props.rdxInfoPanelOpen) return null;
    //if (!this.props.rdxInfoPanelOpen) style.display = "none";
    const content = this.renderContent();
    if (!content) return null;
    //if (!content) style.display = "none";
    const expanded = this.props.rdxSidebarPanelsExpanded;
    const className = expanded ? CSS.main + " " + CSS.expanded : CSS.main;

    // const stackItem = this.stack[this.stackPosition];
    // let headerLabelId;
    // if (stackItem) headerLabelId = stackItem.infoPanelKey+"-header-label";
    // aria-describedby={headerLabelId}

    const i18n = Context.instance.i18n
    const ariaLabel = i18n.infoPanel.itemDetails.replace("{caption}", this.getTitle())
    return (
      <section className={className} style={style} aria-live="polite"
        role="complementary" aria-label={ariaLabel} tabIndex="-1" ref={this.sectionRef}>
        {content}
        <QRCode stack={this.stack} stackPosition={this.stackPosition} />
      </section>
    );
  }

  renderContent() {
    if (this.stack.length === 0) return null;
    const stackItem = this.stack[this.stackPosition];
    return this.makeContent(stackItem)
  }

  makeBackNextButtons() {
    const i18n = Context.getInstance().i18n;
    const hashref = "#";
    let backClass = "i-back", nextClass = "i-next";
    let isAriaBack = false, isAriaNext = false;
    if (!this.stackCanStepBack()) {
      backClass += " " + CSS.linkDisabled;
      isAriaBack = true;
    }
    if (!this.stackCanStepNext()) {
      nextClass += " " + CSS.linkDisabled
      isAriaNext = true;
    }

    const backStyle = {transform: "rotateY(180deg)"};
    let backIcon = (
      <svg className="icon" style={backStyle}>
        <use href="libs/calcite-ui-icons/icons/sprite-16.svg#chevron-16-f"></use>
      </svg>
    );
    let nextIcon = (
      <svg className="icon">
        <use href="libs/calcite-ui-icons/icons/sprite-16.svg#chevron-16-f"></use>
      </svg>
    );
    if (Context.getInstance().uiMode.isRtl) {
      let tmp = backIcon
      backIcon = nextIcon;
      nextIcon = tmp;
    }

    if (Context.getInstance().uiMode.isRtl) {
      return (
        <div className={CSS.backnext}>
          <a className={nextClass} href={hashref} aria-disabled = {isAriaNext}
            role="button" tabIndex="0" onClick={this.stackStepNext}
            aria-label={i18n.infoPanel.ariaLabelNextButton}>
              {i18n.general.next}
              {nextIcon}
          </a>
          <a className={backClass} href={hashref} aria-disabled = {isAriaBack}
            role="button" tabIndex="0" onClick={this.stackStepBack}
            aria-label={i18n.infoPanel.ariaLabelBackButton}>
              {backIcon}
              {i18n.general.back}
          </a>
        </div>
      );
    } else {
      return (
        <div className={CSS.backnext}>
          <a className={backClass} href={hashref} aria-disabled = {isAriaBack}
            role="button" tabIndex="0" onClick={this.stackStepBack}
            aria-label={i18n.infoPanel.ariaLabelBackButton}>
              {backIcon}
              {i18n.general.back}
          </a>
          <a className={nextClass} href={hashref} aria-disabled = {isAriaNext}
            role="button" tabIndex="0" onClick={this.stackStepNext}
            aria-label={i18n.infoPanel.ariaLabelNextButton}>
              {i18n.general.next}
              {nextIcon}
          </a>
        </div>
      );
    }
  }

  showSearchResult(params) {
    try {
      const stackItem = params;
      this.fixZ(stackItem);
      //console.log("InfoPanel::showSearchResult.stackItem",stackItem);
      if (stackItem) {
        stackItem.infoPanelKey = this.newInfoPanelKey();
        this.stackAdd(stackItem);
        //Rdx.setValue(this,Rdx.Keys.INFOPANEL_ACTIVE_KEY,key);
        if (!this.props.rdxInfoPanelOpen) {
          Rdx.setValue(this,Rdx.Keys.INFOPANEL_OPEN,true);
          this.updateBodyClass(true);
        } else {
          component.refresh(this);
        }
        this.realTimeToggled();
        this.zoomAndHighlight(stackItem,params);
        this.activeItem = stackItem
        setTimeout(() => {
          if (this.sectionRef && this.sectionRef.current && this.sectionRef.current.focus) {
            this.sectionRef.current.focus();
          }
        }, 100)
      }
    } catch(ex) {
      console.error("Error showing InfoPanel:",ex);
    }
  }

  stackAdd(stackItem) {
    const len = this.stack.length;
    const pos = this.stackPosition;
    if ((pos + 1) < len) {
      this.stack.splice(pos + 1);
    }
    this.stack.push(stackItem);
    this.stackPosition++;
  }

  stackCanStepBack() {
    return (this.stackPosition > 0);
  }

  stackCanStepNext() {
    const len = this.stack.length;
    const pos = this.stackPosition;
    return (len > 0 && (pos + 1) < len);
  }

  stackStepBack() {
    if (this.stackCanStepBack()) {
      this.stackPosition--;
      const stackItem = this.stack[this.stackPosition];
      //Rdx.setValue(this,Rdx.Keys.INFOPANEL_ACTIVE_KEY,content.props.infoPanelKey);
      this.realTimeToggled();
      component.refresh(this);
      this.zoomAndHighlight(stackItem,{
        zoom: true,
        highlight: true,
        trackRecent: true
      });
    }
  }

  stackStepNext() {
    if (this.stackCanStepNext()) {
      this.stackPosition++;
      const stackItem = this.stack[this.stackPosition];
      //Rdx.setValue(this,Rdx.Keys.INFOPANEL_ACTIVE_KEY,stackItem.infoPanelKey);
      this.realTimeToggled();
      component.refresh(this);
      this.zoomAndHighlight(stackItem,{
        zoom: true,
        highlight: true,
        trackRecent: true
      });
    }
  }

  updateBodyClass(add) {
    const classname = CSS.infoPanelOpen;
    if (add) {
      document.body.classList.add(classname);
    } else {
      document.body.classList.remove(classname);
    }
  }

  zoomAndHighlight(stackItem,params) {
    const sourceKey = stackItem && stackItem.sourceKey;
    const searchResult = stackItem && stackItem.searchResult;
    const feature = searchResult && searchResult.feature;
    const hasGeometry = itemUtil.hasGeometry(sourceKey,feature);
    const view = Context.getInstance().views.activeView;
    const lastCamera = (view && view.camera) ? view.camera.clone() : null;
    const lastExtent2D = (view && view.type === "2d" && view.extent) ? view.extent.clone() : null;
    //console.log("InfoPanel::content.props.searchResult.feature",feature);

    if (!params.publishedFromLevelFilter) {
      const categories = Context.getInstance().aiim.datasets.categories;
      const levels = Context.getInstance().aiim.datasets.levels;
      const source = sourceKey && categories.findSourceByKey(sourceKey);
      if(!stackItem.levelData){
        if (stackItem.trackingInfo) {
          let levelData;
          /*
          if (stackItem.trackingInfo.feature && stackItem.trackingInfo.feature.attributes) {
            // level_id: "1000.US01.MAIN.O3" // level_ids in the tracking data appear to be incorrect
            const levelId = aiimUtil.getAttributeValue(stackItem.trackingInfo.feature.attributes,"level_id");
            levelData = levelId && levels && levels.getLevelData(levelId);
          }
          */
          if (!levelData && levels) {
            const facilityId = stackItem.trackingInfo.facilityId;
            const verticalOrder = stackItem.trackingInfo.floor;
            if (typeof facilityId === "string" && facilityId.length > 0 && typeof verticalOrder === "number") {
              const facilityData = levels.getFacilityData(facilityId);
              levelData = facilityData && facilityData.levelsByVO[verticalOrder];
            }
          }
          if (levelData) stackItem.levelData = levelData;
        } else {
          const zInfo = levels && levels.getZInfo(source,feature);
          stackItem.levelData = zInfo && zInfo.levelData;
        }
      }
      Topic.publish(Topic.ItemOpened,{
        source: source,
        feature: feature,
        lastCamera: lastCamera,
        lastExtent2D: lastExtent2D,
        view: view
      });
    }

    if (view && params.zoom && hasGeometry) {
      mapUtil.goToFeature(view,feature);
    }
    if (view && params.highlight) {
      const inactiveView = Context.getInstance().views.getInactiveView();

      let text = stackItem.title;
      if (!text && stackItem.searchResult && stackItem.searchResult.name) {
        text = stackItem.searchResult.name;
      }
      const options={
        text:text,
        levelData: stackItem.levelData
      }

      if (hasGeometry) {
        mapUtil.addLocationGraphic(view,feature.geometry,options);
        if (inactiveView) mapUtil.addLocationGraphic(inactiveView,feature.geometry,options);
      } else {
        mapUtil.clearLocationGraphic(view);
        if (inactiveView) mapUtil.clearLocationGraphic(inactiveView);
      }
    }

    if (params.trackRecent && hasGeometry && sourceKey && searchResult) {
      const referenceLayer = Context.getInstance().session.referenceLayer;
      referenceLayer.addRecent(sourceKey,searchResult);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    rdxInfoPanelActiveKey: Rdx.getValue(state,Rdx.Keys.INFOPANEL_ACTIVE_KEY),
    rdxInfoPanelOpen: Rdx.getValue(state,Rdx.Keys.INFOPANEL_OPEN),
    rdxSidebarPanelsExpanded: Rdx.getValue(state,Rdx.Keys.SIDEBAR_PANELS_EXPANDED)
  }
}

export default connect(mapStateToProps)(InfoPanel);
