import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Source from "./Source";
import * as aiimUtil from "../util/aiimUtil";
import * as searchUtil from "../util/searchUtil";
import * as val from "../../util/val";

export default class ItemReference extends BaseClass {

  name;
  searchResult;
  sourceKey;
  uniqueId;
  uniqueIdField;
  userLabel;

  _source;
  _standaloneNumber;

  constructor(props){
    super(props);
    this._standaloneNumber = -1;
  }

  _fromFeature(sourceKey,feature) {
    let uniqueId = null, uniqueIdField = null, ok = false;
    const datasets = Context.getInstance().aiim.datasets;
    const source = datasets.categories.findSourceByKey(sourceKey);
    const info = this.getReferenceProperties(source,feature.attributes);
    const searchResult = source.makeSearchResult(null, feature)
    if (info) {
      uniqueIdField = info.uniqueIdField;
      uniqueId = info.uniqueId;
    }
    if (uniqueId === undefined || uniqueId === null) {
      console.warn("Unable to set ReferenceFeature from feature",sourceKey,feature);
    } else {
      //console.log("fromFeature",uniqueIdField,"=",uniqueId);
      ok = true;
      this.uniqueId = uniqueId;
      this.uniqueIdField = uniqueIdField;
      this.sourceKey = source.key;
      this._source = source;
      this.searchResult = searchResult
    }
    return ok;
  }

  fromGeometry(geometry, options){
    options = options || {};

    let uniqueId = options.uniqueId;
    if (!uniqueId) uniqueId = val.generateRandomId();

    let standaloneNumber = options.standaloneNumber;
    if (typeof standaloneNumber !== "number") {
      standaloneNumber = 1;
      const numbers = [];
      const refLayer = Context.instance.session.referenceLayer;
      if (refLayer) {
        const lists = [[refLayer.homeLocation],refLayer.favorites,refLayer.recent];
        lists.forEach(list => {
          if (Array.isArray(list)) {
            list.forEach(itm => {
              if (typeof itm._standaloneNumber ==="number" && itm._standaloneNumber >= 1) {
                numbers.push(itm._standaloneNumber);
              }
            })
          }
        });
        numbers.sort();
        for (var i=1;i<=10000;i++) {
          if (numbers.indexOf(i) === -1) {
            standaloneNumber = i;
            break;
          }
        }
      }
    }

    let name = options.name;
    if (!name) {
      name = Context.getInstance().i18n.yourPlaces.standAlone;
      name = name.replace("{number}",standaloneNumber);
    }

    const feature = new Context.instance.lib.esri.Graphic({
      geometry: geometry,
      attributes: {
        name: name
      }
    });

    const searchResult = {
      extent: null,
      feature: feature,
      name: name,
      key: uniqueId,
      sourceIndex: 0,
      standaloneNumber: standaloneNumber
    };
    this._source = new Source();
    this._source.key = "standalone";
    this._source.uniqueIdField = null;
    this._source.subTitleField = null;
    this.name = searchResult.name;
    this.searchResult = searchResult;
    this.sourceKey = this._source.key;
    this.uniqueId = uniqueId;
    this._standaloneNumber = standaloneNumber;
    return true;
  }

  fromJson(json,opts) {
    const promise = new Promise((resolve,reject) => {

      if (this.isStandAloneSourceKey(json.sourceKey) ){
        let util = Context.instance.lib.esri.geometryJsonUtils;
        let geometry = util.fromJSON(json.geometry);
        this.fromGeometry(geometry, {
          uniqueId: json.uniqueId,
          name: json.name,
          standaloneNumber: json.standaloneNumber
        });
        resolve(true);
        return;
      }

      const datasets = Context.getInstance().aiim.datasets;
      const levelsDataset = datasets.levels;
      const source = datasets.categories.findSourceByKey(json.sourceKey);
      if (source && source.url && json.uniqueIdField &&
          json.uniqueId !== undefined && json.uniqueId !== null) {
        const url = source.url;
        //console.log("fromJson.url",url);
        searchUtil.queryFieldValue(source,url,json.uniqueIdField,json.uniqueId,null,null,opts).then(result => {
          //console.log("fromJson.queryFieldValue",result);
          let ok = false, feature;
          const chooseFirst = (opts && opts.chooseFirst) || (source.key === "People")
          const features = (result && result.features) || [];
          if (features.length === 1) {
            feature = result.features[0];
          } else if (features.length > 0 && chooseFirst) {
            feature = result.features[0];
          }
          if (feature) {
            if (levelsDataset) levelsDataset.fixZ(source,feature);
            const searchResult = source.makeSearchResult(null,feature);
            searchResult.key = json.uniqueId;
            ok = this.fromSearchResult(source.key,searchResult);
            if (ok) {
              if (typeof json.userLabel === "string" && json.userLabel.length > 0) {
                this.userLabel = json.userLabel;
              }
              this._source = source;
            }
          }
          resolve(ok);
        }).catch((ex) => {
          resolve(null);
          console.warn("Error restoring reference feature:", json);
          console.error(ex);
        });
      } else {
        console.warn("Error restoring reference feature, invalid source", json);
        resolve(null);
      }
    });
    return promise;
  }

  fromSearchResult(sourceKey,searchResult) {
    if (this.isStandAloneSourceKey(sourceKey)) {
      const geometry = searchResult.feature && searchResult.feature.geometry;
      return this.fromGeometry(geometry, {
        uniqueId: searchResult.key,
        name: searchResult.name,
        standaloneNumber: searchResult.standaloneNumber
      });
    } else {
      const ok = (sourceKey && searchResult && searchResult.feature) ?
        this._fromFeature(sourceKey,searchResult.feature) : false;
      if (ok) {
        this.name = searchResult.name;
        this.searchResult = searchResult;
      }
      return ok;
    }
  }

  getFeature() {
    if (this.searchResult) return this.searchResult.feature;
  }

  getLabel() {
    if (typeof this.userLabel === "string" && this.userLabel.length > 0) {
      return this.userLabel;
    } else {
      return this.getTitle();
    }
  }

  getReferenceProperties(source,attributes) {
    let uniqueId = null, uniqueIdField = null;
    if (source) {
      uniqueIdField = source.uniqueIdField;
      uniqueId = aiimUtil.getAttributeValue(attributes,uniqueIdField);
      if (typeof uniqueId === "string" && uniqueId.length === 0) {
        uniqueId = null;
      }
    }
    if (uniqueId !== undefined && uniqueId !== null) {
      return {
        sourceKey: source.key,
        uniqueIdField: uniqueIdField,
        uniqueId: uniqueId
      };
    } else {
      console.warn("getReferenceProperties.notOk",source,attributes);
      return null;
    }
  }

  getSearchResult() {
    return this.searchResult;
  }

  getSource() {
    if (this._source) return this._source;
    const datasets = Context.getInstance().aiim.datasets;
    this._source = datasets.categories.findSourceByKey(this.sourceKey);
    return this._source;
  }

  getSourceKey() {
    const source = this.getSource();
    if (source) return source.key;
    return null;
  }

  getSubTitle() {
    if (this.isStandAlone()) return null;
    if (this._source) return this._source.getSubTitle(this.getFeature());
  }

  getSubType() {
    let source = this.getSource();
    if (source && source.subCategoryField) {
      let feature = this.getFeature();
      let attributes = feature && feature.attributes;
      if (attributes) {
        return aiimUtil.getAttributeValue(attributes,source.subCategoryField);
      }
    }
    return null;
  }

  getTitle() {
    if (this.isStandAlone()) return this.name;
    if (this._source) return this._source.getTitle(this.getFeature());
  }

  isStandAlone(){
    return this.isStandAloneSourceKey(this.sourceKey);
  }

  isStandAloneSourceKey(sourceKey){
    return sourceKey === "standalone";
  }

  isValid() {
    if (this.uniqueId !== undefined && this.uniqueId !== null && this.searchResult) {
      return true;
    }
    return false;
  }

  toJson() {
    let json = {};
    if (this.isStandAlone()) {
      json = {
        geometry: this.searchResult.feature.geometry.toJSON(),
        sourceKey: this.sourceKey,
        uniqueId: this.uniqueId,
        name: this.name,
        standaloneNumber: this._standaloneNumber
      }
    } else {
      json = {
        sourceKey: this.sourceKey,
        uniqueIdField: this.uniqueIdField,
        uniqueId: this.uniqueId
      };
    }

    // data to be saved in local storage
    // check for standalone data and write json data( geometry, label)
    // standalone, geometry
    if (typeof this.userLabel === "string" && this.userLabel.length > 0) {
      json.userLabel = this.userLabel;
    }
    return json;
  }

}
