import React from "react";
import { connect } from "react-redux";

//import Button from "../../../common/components/Button";
import Rdx from "../../../redux/Rdx";
import { ButtonContainer, CustomButton } from "../../styles/Work/workBar";

class TabButton extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const rdxKey = this.props.navKey;
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);
    const panelOpenClassName = this.props.panelOpenClassName;
    if (active) {
      Rdx.setValue(this, rdxKey, null);
      document.body.classList.remove(panelOpenClassName);
    } else {
      Rdx.setValue(this, rdxKey, this.props.tabKey);
      document.body.classList.add(panelOpenClassName);
    }
  }

  render() {
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);
    const clear = active ? false : true;
    let cls = "i-tab-button";
    if (this.props && typeof this.props.className === "string" &&
      this.props.className.length > 0) {
      cls += " " + this.props.className;
    }
    return (
      <ButtonContainer>
        <CustomButton {...this.props} className={cls} clear={clear} onClick={this.clicked}>
          {this.props.children}
        </CustomButton>
      </ButtonContainer>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
  }
}

export default connect(mapStateToProps)(TabButton);
