import React from "react";

import Context from "../../../context/Context";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";

import defaultIcon from "../../../assets/category-default.svg";

const CSS = {
  categories: "i-categories",
  categoryIcon: "i-category-icon"
};

export default class Categories extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.sourceClicked = this.sourceClicked.bind(this);
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const list = [];
    const sourceKeys = this.props.sourceKeys;
    if (sourceKeys && sourceKeys.length > 0) {
      const categories = Context.getInstance().aiim.datasets.categories;
      sourceKeys.forEach(key => {
        const source = categories.findSourceByKey(key);
        if (source && source.url && source.categoryLevel === 1) {
          list.push(this.renderSource(source));
        }
      });
    };
    if (list.length > 0) {
      return (
        <ul className={CSS.categories} aria-label={i18n.explore.categoriesAriaLabel}>
          {list}
        </ul>
      );
    } else {
      return (
        <p>{i18n.messages.noSearchCategories}</p>
      )
    }
  }

  renderSource(source) {
    return (
      <SourceIcon key={source.key} sourceKey={source.key}
        clickHandler={this.sourceClicked} />
    );
  }

  sourceClicked(sourceKey) {
    if (this.props.clickHandler) this.props.clickHandler(sourceKey);
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class SourceIcon extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    if (this.props.clickHandler) {
      this.props.clickHandler(this.props.sourceKey);
    }
  }

  render() {
    const source = aiimUtil.getSource(this.props.sourceKey);
    const name = source.name;
    const hashref = "#";

    let icon = null, iconSrc = null;
    if (source.iconUrl) {
      iconSrc = aiimUtil.appendTokenToUrl(source.iconUrl);
    }
    if (!iconSrc) iconSrc = defaultIcon;
    if (iconSrc) {
      icon = <img className={CSS.categoryIcon} src={iconSrc} alt=""/>
    }
    return (
      <li>
        <a href={hashref} onClick={this.clicked} role="button" tabIndex="0">
          {icon}
          <p>{name}</p>
        </a>
      </li>
    );
  }

}
