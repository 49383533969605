import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Topic from "../../context/Topic";
import * as geoUtil from "../../util/geoUtil";
import * as mapUtil from "../../util/mapUtil";

/*

needs to be https?
https://devtopia.esri.com/WebGIS/arcgis-js-api/blob/4master/esri/core/geolocationUtils.ts

//const timestamp = position && position.timestamp;

Coordinates.floorLevel - looks to be Apple IPS specific

Coordinates.latitude in decimal degrees
Coordinates.longitude in decimal degrees
Coordinates.altitude in meters, relative to sea level
Coordinates.accuracy in meters
Coordinates.altitudeAccuracy in meters
Coordinates.heading
Coordinates.speed in meters per second

Coordinates.altitude
Returns a double representing the position's altitude in meters, relative to sea level.
This value can be null if the implementation cannot provide the data.

Coordinates.heading
Returns a double representing the direction in which the device is traveling.
This value, specified in degrees, indicates how far off from heading true north the device is.
0 degrees represents true north, and the direction is determined clockwise
(which means that east is 90 degrees and west is 270 degrees).
If speed is 0, heading is NaN.
If the device is unable to provide heading information, this value is null.

*/

//let COUNTER = 0;

export default class Bluedot extends BaseClass {

  enabled = false;
  following = false;
  followingPartially = false;
  graphic;
  layerId = "indoors-bluedot";

  _appStarted;
  _lastFollow = {timestamp: Date.now()};
  _lastLevelActivated = null;
  _lastPosition;
  _lastTask;
  _lastOnPosition = {timestamp: Date.now()};
  _wasDenied = false;
  _wasStarted = false;
  _watchID = null;

  constructor(props) {
    super(props);
    this._onPosition = this._onPosition.bind(this);
    this._onPositionError = this._onPositionError.bind(this);

    const lib = Context.instance.lib;
    let url = lib.esri.urlUtils.urlToObject(window.location.href);
    if (url && url.query && url.query.follow === "true") {
      this.following = true;
    }

    this.setFollowingPartially(this.followingPartially);

    Topic.subscribe(Topic.AppStarted,params => {
      this._appStarted = true;
      if (this.enabled) this._watchPosition();
    });

    Topic.subscribe(Topic.FacilityModeUpdated,params => {
      try {
        if (!this._isFollowingPartially()) return;
        let decouple = true;
        const last = this._lastLevelActivated;
        const info = params && params.facilityMode && params.facilityMode.activeFacilityInfo;
        if (info && info.activeLevel && last) {
          if (info.facilityId === last.facilityId && info.activeLevel.levelId === last.levelId) {
            decouple = false;
          }
        }
        if (decouple) {
          this.setFollowingPartially(false);
        }
      } catch(ex) {
        console.error(ex);
      }
    });

    Topic.subscribe(Topic.ViewActivated,params => {
      if (this._appStarted && this.enabled) this._viewActivated();
    });

    Topic.subscribe(Topic.WebMapLoaded,params => {
      if (!this._wasStarted) this._startup();
    });
  }

  activateLevel(task) {
    const view = Context.instance.views.activeView;
    if (view && task.facilityData && task.levelData) {
      let info = Context.instance.aiim.getActiveFacilityInfo();
      if (info && info.activeLevel &&
          info.facilityId === task.facilityData.facilityId &&
          info.activeLevel.levelId === task.levelData.levelId) {
        return;
      }
      this._lastLevelActivated = {
        facilityId: task.facilityData.facilityId,
        levelId: task.levelData.levelId
      };
      Topic.publish(Topic.ActivateLevel,{
        view: view,
        facilityData: task.facilityData,
        levelData: task.levelData
      });
    }
  }

  _addGraphics(view,graphics) {
    if (!view || !graphics || graphics.length === 0) return;
    let layer = this._ensureLayer(view);
    if (layer) {
      layer.graphics.removeAll();
      layer.graphics.addMany(graphics);
    }
  }

  _clearWatch() {
    if (this.enabled && typeof this._watchID === "number") {
      navigator.geolocation.clearWatch(this._watchID);
      this._watchID = null;
    }
  }

  _ensureLayer(view) {
    if (!view) return null;
    let id = this.layerId;
    let layer = view.map.findLayerById(id);
    if (!layer) {
      const lib = Context.instance.lib;
      layer = new lib.esri.GraphicsLayer({
        id: id,
        title: id,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: Context.instance.config.graphicElevationOffset,
        unit: "meters"
      };
      view.map.add(layer);
    }
    return layer;
  }

  _follow(task) {
    const promise = new Promise((resolve,reject) => {
      this.activateLevel(task);
      const view = Context.instance.views.activeView;
      let graphic = task._2D.graphic;
      if (view && view.type === "3d") graphic = task._3D.graphic;
      if (view && graphic) {
        let millis = Date.now() - this._lastFollow.timestamp;
        if (millis > 1) {
          mapUtil.goToFeature(view,graphic).then(() => {
            this._lastFollow.timestamp = Date.now();
            resolve();
          }).catch(ex => {
            console.error("Error following Bluedot",ex);
            resolve();
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
    return promise;
  }

  _followPartially(task) {
    const promise = new Promise((resolve,reject) => {
      this.activateLevel(task);
      resolve();
    });
    return promise;
  }

  generate() {
    if (!this.enabled) return Promise.resolve();
    const promise = new Promise((resolve,reject) => {
      //const task = {};
      this._getCurrentPosition().then(position => {
        return this._generateFromPosition(position);
      }).then(task => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise;
  }

  _generateFromPosition(position) {
    const promise = new Promise((resolve,reject) => {
      this._lastPosition = position;
      let section, sceneView;
      const timestamp = position && position.timestamp;
      const task = this._newTask(position);
      const mapView = Context.instance.views.mapView;
      this._makePointWGS84(task,mapView);
      if (mapView && task.pointWGS84) {
        //console.log("Bluedot: generating from position...");
        geoUtil.projectToView(task.pointWGS84,mapView).then(point => {
          section = task._2D;
          section.point = point;
          if (point) {
            const datasets = Context.instance.aiim.datasets;
            const footprints = Context.instance.aiim.facilityFootprints;
            const levels = datasets && datasets.levels;
            if (footprints) {
              const facilityId = footprints.findFacilityIdByPoint(point);
              const floorLevel = task.position.coords.floorLevel;
              if (levels) {
                task.facilityData = levels.getFacilityData(facilityId);
                if (task.facilityData && typeof floorLevel === "number") {
                  task.levelData = task.facilityData.levelsByVO[floorLevel];
                  if (task.levelData) point.z = task.levelData.z;
                }
              }
            }
          }
          this._makeGraphics(task,section,mapView);
          sceneView = Context.instance.views.sceneView;
          if (sceneView) {
            return geoUtil.projectToView(point,sceneView);
          }
        }).then(point3D => {
          sceneView = Context.instance.views.sceneView;
          if (sceneView && point3D) {
            section = task._3D;
            section.point = point3D;
            this._makeGraphics(task,section,sceneView);
          }
        }).then(() => {
          let apply = true;
          const last = this._lastTask;
          const ts0 = last && last.position && last.position.timestamp;
          if (ts0 && ts0 > timestamp) apply = false;
          if (apply) {
            this._addGraphics(mapView,task._2D.graphics);
            if (task._2D.graphic) {
              this.graphic = task._2D.graphic;
              this._lastTask = task;
            }
            sceneView = Context.instance.views.sceneView;
            this._addGraphics(sceneView,task._3D.graphics);

            if (this._isFollowing()) {
              return this._follow(task);
            } else if (this._isFollowingPartially()) {
              return this._followPartially(task);
            }

          }
        }).then(() => {
          resolve(task);
        }).catch(ex => {
          reject(ex);
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  _getCurrentPosition() {
    const promise = new Promise((resolve,reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (positionError) => {
          this._onPositionError(positionError);
          reject(positionError);
        },
        this._newPositionOptions()
      );
    });
    return promise;
  }

  _getLevels() {
    const aiim = Context.instance.aiim;
    const levels = aiim.datasets && aiim.datasets.levels;
    return levels;
  }

  getLastTask() {
    return this._lastTask;
  }

  hasLocation() {
    return !!this.graphic;
  }

  _isFollowing() {
    return !!this.following;
  }

  _isFollowingPartially() {
    return !this.following && !!this.followingPartially;
  }

  setFollowingPartially(followPartially) {
    if (this._isFollowing()) followPartially = false;
    if (followPartially) {
      this.followingPartially = true;
      //document.documentElement.style.setProperty("--i-theme-color-brand","green");
      document.body.classList.add("i-fbd-on");
    } else {
      this.followingPartially = false;
      this._lastLevelActivated = null;
      //document.documentElement.style.setProperty("--i-theme-color-brand","red");
      document.body.classList.remove("i-fbd-on");
    }
  }

  _makeGraphics(task,section,view) {
    if (!view || !section.point) return;
    section.graphics = [];
    this._makePointGraphic(task,section,view);
    if (section.graphic) {
      section.graphics.push(section.graphic);
      this._makeUncertaintyGraphic(task,section,view);
      if (section.uncertaintyGraphic) {
        section.graphics.push(section.uncertaintyGraphic);
      }
    }
  }

  _makePointGraphic(task,section,view) {
    if (!section.point) return;
    const lib = Context.instance.lib;
    const markerSymbol = {
      type: "simple-marker",
      color: [0, 94, 149], // #005e95 [0, 94, 149], #003E62 [0, 62, 98]
      size: "20px",
      outline: {
        color: [255, 255, 255],
        width: 2
      }
    };
    section.graphic = new lib.esri.Graphic({
      geometry: section.point.clone(),
      symbol: markerSymbol
    });
    if (task.levelData) {
      section.graphic.xtnVerticalOrder = task.levelData.verticalOrder;
    }
  }

  _makePointWGS84(task,view) {
    if (!view) return;
    const coords = (task.position && task.position.coords);
    if (coords && typeof coords.latitude === "number" &&
        typeof coords.longitude === "number") {

      let z = 0;
      // // altitude is typically sea level based, leave z set to zero,
      // // Apple IPS has a floorLevel property, base z on that
      // if (typeof coords.altitude === "number") {
      //   z = coords.altitude;
      // }

      const lib = Context.instance.lib;
      task.pointWGS84 = new lib.esri.Point({
        latitude: coords.latitude,
        longitude: coords.longitude,
        z: z,
        spatialReference: lib.esri.SpatialReference.WGS84
      });

      // task.pointWGS84  = new lib.esri.Point({
      //   x: -13046328.92993522,
      //   y: 4036455.8565572137,
      //   z: 8.5,
      //   spatialReference: view.spatialReference.clone()
      // });
      // task.position.coords.floorLevel = 1;

      //console.log("Bluedot point",point);
    }
  }

  _makeUncertaintyGraphic(task,section,view) {
    if (!section.point) return;
    const lib = Context.instance.lib;
    const coords = (task.position && task.position.coords);
    if (coords && typeof coords.accuracy === "number" && coords.accuracy > 0) {
      const circle = new lib.esri.Circle({
        center: section.point.clone(),
        radius: coords.accuracy,
        radiusUnits: "meters"
      });
      const outlineColor = [0, 94, 149, 0.2];
      const outlineWidth = "2px";
      const symbol = {
        type: "simple-fill",
        color: [0, 94, 149, 0.1],
        outline: {
          color: outlineColor,
          width: outlineWidth
        }
      };
      section.uncertaintyGraphic = new lib.esri.Graphic({
        geometry: circle,
        symbol: symbol
      });
    }
  }

  _newPositionOptions() {
    return {
      enableHighAccuracy: true,
      maximumAge: 0, // for a cached position
      timeout: 15000 //Number.POSITIVE_INFINITY  // time to return a position
    };
  }

  _newTask(position) {
    const task = {
      position: position,
      pointWGS84: null,
      facilityData: null,
      levelData: null,
      _2D: {
        point: null,
        graphic: null,
        uncertaintyGraphic: null,
        graphics: []
      },
      _3D: {
        point: null,
        graphic: null,
        uncertaintyGraphic: null,
        graphics: []
      }
    };
    return task;
  }

  _onPosition(position) {
    //console.log("Bluedot::_onPosition",position,new Date());

    // let sb = {v: ""};
    // const qqq = (obj) => {
    //   for (let k in obj) {
    //     let obj2 = obj[k];
    //     if (obj2 !== null && typeof obj2 === "object") {
    //       qqq(obj2);
    //     } else {
    //       if (sb.v.length > 0) sb.v += ", ";
    //       sb.v += k+": "+obj2;
    //     }
    //   }
    // };
    // qqq(position);
    // Context.logToTextarea(sb.v)
    //

    // COUNTER++;
    // Context.logToTextarea(""+COUNTER);

    const positionA = this._lastTask && this._lastTask.position;
    const same = this._samePosition(positionA,position);
    if (!same) {
      //console.log("Bluedot::_onPosition",position);
      let millis = Date.now() - this._lastOnPosition.timestamp;
      if (millis > 1000) {
        this._lastOnPosition.timestamp = Date.now();
        this._generateFromPosition(position).then(() => {
          //this._lastOnPosition.timestamp = Date.now();
        }).catch(ex => {
          console.error("Bluedot - Error",ex);
        });
      }
    }
  }

  _onPositionError(positionError) {
    console.warn("Bluedot::_onPositionError",positionError);
    const code = (positionError && positionError.code);
    if (code === 1) {
      // PERMISSION_DENIED
      this._clearWatch();
      this._wasDenied = true;
    } else if (code === 2) {
      // POSITION_UNAVAILABLE
    } else if (code === 3) {
      // TIMEOUT
    } else {
      // other
    }
  }

  _samePosition(positionA,positionB) {
    if (positionA === positionB) {
      return true;
    } else if (positionA && positionB) {
      const coordsA = positionA.coords;
      const coordsB = positionB.coords;
      if (coordsA && coordsB) {
        if (coordsA.latitude === coordsB.latitude &&
            coordsA.longitude === coordsB.longitude &&
            coordsA.altitude === coordsB.altitude &&
            coordsA.heading === coordsB.heading &&
            coordsA.accuracy === coordsB.accuracy &&
            coordsA.altitudeAccuracy === coordsB.altitudeAccuracy) {
          return true;
        }
      }
    }
    return false;
  }

  _startup() {
    this._wasStarted = true;
    let useGeolocation = false;
    const isKiosk = Context.instance.uiMode.isKiosk;
    const configOn = !!Context.instance.config.indoorPositioning.iOS;
    const deviceOk = /iPhone|iPad/i.test(navigator.userAgent);
    const isSecure = (window.location.href.indexOf("https://") === 0);
    if (configOn && deviceOk && isSecure && !isKiosk) {
      if (navigator && navigator.geolocation) {
        useGeolocation = true;
      }
    }
    //useGeolocation = (navigator && navigator.geolocation);

    if (useGeolocation) this.enabled = true;
  }

  _viewActivated() {
    if (this.enabled && !this._wasDenied){
      if (this._lastPosition) {
        this._generateFromPosition(this._lastPosition).then(() => {
        }).catch(ex => {
          console.error("Bluedot - Error",ex);
        });
      }
      this.generate().then(() => {
      }).catch(ex => {
        console.error("Bluedot - Error",ex);
      });
    }
  }

  _watchPosition() {
    if (!this.enabled || this._wasDenied) return;
    this._clearWatch();
    console.log("Geolocation.watch ...");
    this._watchID = navigator.geolocation.watchPosition(
      this._onPosition,
      this._onPositionError,
      this._newPositionOptions()
    );
  }

}
