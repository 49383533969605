import React from "react";
import Context from "../../../context/Context";

import * as component from "../../util/component";
import Topic from "../../../context/Topic";
import HelpTip from '../../common/HelpTip/HelpTip';

const CSS = {
  main: "i-cfg-logo"
};

export default class Logo extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      flag:false,
      name:this.props.cfg.getConfigurable("logo").name,
      selectedOption: this.props.cfg.getConfigurable("logo").choice,
      uploadNewFile: false
    });
  }

  uploadFile=()=>{
    this.setState({
      flag:true
    })
  }

  readFile=(event)=>{
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) =>{
      this.props.cfg.getConfigurable("logo").url= e.target.result;
      this.props.cfg.getConfigurable("logo").choice = "custom";
      component.refresh(this);
      Topic.publish(Topic.LogoChanged, {});
      this.setState({
        uploadNewFile: false
      })
    };
    if (file) {
      reader.readAsDataURL(file);
   }
  }

  changeLogoSize=(event)=>{
    this.props.cfg.getConfigurable("logo").size=event.target.value;
    Topic.publish(Topic.LogoChanged, {});
    component.refresh(this);
  }

  changeName=(event)=>{
    this.setState({
      name:event.target.value
    })
    this.props.cfg.getConfigurable("logo").name=event.target.value;
    Topic.publish(Topic.LogonameChanged, {});
  }

  changeToPortalLogo =(event)=>{
    this.setState({
      selectedOption: event.target.value
    });

    let portal=Context.instance.portal;
    let portalLogo = portal.portalProperties && portal.portalProperties.sharedTheme ;
    let indoorsLogo=Context.getInstance().configuration._configurables.logo;
    if(portalLogo){
      if(portalLogo.logo.small){
        this.props.cfg.getConfigurable("logo").url= portalLogo.logo.small;
      }else{
        this.props.cfg.getConfigurable("logo").url= indoorsLogo;
      }
      this.props.cfg.getConfigurable("logo").choice= event.target.value;
      Topic.publish(Topic.LogoChanged, {});
    }
  }

  changeToDefaultLogo =(event)=>{
    let indoorsLogo=Context.getInstance().configuration._configurables.logo;
    this.props.cfg.getConfigurable("logo").url= indoorsLogo.url;
    this.props.cfg.getConfigurable("logo").name= indoorsLogo.name;
    this.props.cfg.getConfigurable("logo").size= indoorsLogo.size;
    this.props.cfg.getConfigurable("logo").choice= event.target.value;
    this.setState({
      selectedOption: event.target.value
    });
    Topic.publish(Topic.LogoChanged, {});
  }

  uploadFileChoice =(event)=>{
    this.setState({
      selectedOption: event.target.value
    })
  }

  changeUploadedFile =()=>{
    this.setState({
      uploadNewFile : true
    })
  }

  componentDidMount() {
    // component.own(this,[
    //   Topic.subscribe(Topic.ConfigurationDiscarded,() => {
    //     this.setState({
    //       name:this.props.cfg.getConfigurable("logo").name,
    //       selectedOption: this.props.cfg.getConfigurable("logo").choice
    //     })
    //   }),
    // ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const logo = this.props.cfg.getConfigurable("logo");
    const i18n=Context.instance.i18n;
    const i18nLogo=i18n.configurator.logo;
    let customOption, toolTip='';

    // div creation for file upload option
    if(this.state.selectedOption ==="custom" &&  this.props.cfg.getConfigurable("logo").choice !== "custom" || this.state.uploadNewFile){
      customOption= <div className="i-custom-logo-input">
                        <input type="file" onChange={this.readFile} accept ="image\*" />
                    </div>
    }else if(this.state.selectedOption === "custom" && this.props.cfg.getConfigurable("logo").choice === "custom"){
      customOption= <div className="i-custom-logo-option">
                        {i18nLogo.customLogoAppliedNotification}
                        <button type="button" className="i-button" onClick={this.changeUploadedFile}>{i18nLogo.change}</button>
                    </div>;
    }else{
      customOption='';
    }

    //div creation for tooltip text area
    if(this.state.selectedOption === "portal" || this.state.selectedOption === "custom"){
      toolTip= <div className="i-form-group">
                  <div className="i-margin-top-1">{i18nLogo.toolTip}</div>
                  <input type="text" value={this.state.name} placeholder={i18nLogo.tooltipPlaceholder} onChange={this.changeName}/>
               </div>
    }else{
      toolTip= <div className="i-form-group">
                  <div className="i-margin-top-1">{i18nLogo.toolTip}</div>
                  <input type="text" value={this.state.name} placeholder={i18nLogo.tooltipPlaceholder} onChange={this.changeName} disabled/>
               </div>
    }

    let logoSizeDisabled= (this.state.selectedOption === "default");
    let logoSize = (
      <div className="i-form-group">
        <div className="i-margin-top-1">{i18nLogo.size}</div>
        <select disabled={logoSizeDisabled} value={logo.size}
          onChange={this.changeLogoSize}>
          <option value="1">1</option>
          <option value="1.5">1.5</option>
          <option value="2">2</option>
          <option value="2.5">2.5</option>
          <option value="3">3</option>
        </select>
      </div>
    )

    return (
      <div className={CSS.main}>
        <div className="i-form-group-header">
          <span className="i-form-group-name">{i18nLogo.logo}</span>
            <HelpTip text={i18nLogo.helpMarkTooltip}/>
        </div>
        <label>
            <input
            type="radio"
            value="default"
            checked={this.state.selectedOption === "default"}
            onChange={this.changeToDefaultLogo}
            />
            {i18nLogo.defaultLogo}
        </label>
        <label>
            <input
            type="radio"
            value="portal"
            checked={this.state.selectedOption === "portal"}
            onChange={this.changeToPortalLogo}
            />
            {i18nLogo.portalLogo}
        </label>
        <label>
            <input
            type="radio"
            value="custom"
            checked={this.state.selectedOption === "custom"}
            onChange={this.uploadFileChoice}
            />
            {i18nLogo.customLogo}
        </label>
        {customOption}
        {toolTip}
        {logoSize}
        </div>
    );
  }
}
