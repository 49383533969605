import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import { SpaceAssignmentTypes } from "./OfficePlan";
import Topic from "../../context/Topic";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as transactions from "./transaction/transactions";
import * as val from "../../util/val";
import { ILayerDefinition, IFeature } from "@esri/arcgis-rest-types";
import type { CustomQueryTask } from "../../context/EsriLib";
import { AreaTypes } from "../../util/interfaces";
import { IWorkspaceAreaConfig } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";

/*
  Transactions

    addArea
    deleteArea
    updateArea

 */
export const HomeOfficeName = "Home Office";
export const HomeOfficeId = "0";

export const HomeOfficeJSON: IFeature = Object.freeze({
  attributes: Object.freeze({
    [FieldNames.AREA_ID]: HomeOfficeId,
    [FieldNames.AREA_NAME]: HomeOfficeName,
    [FieldNames.AREA_TYPE]: SpaceAssignmentTypes.home
  })
})
export default class AreasTable extends BaseClass {

  table: __esri.FeatureLayer;
  hotDeskAreas: __esri.Graphic[];
  hotelAreas: __esri.Graphic[];
  workspaceAreas: __esri.Graphic[];

  private _areasById: { [props: string]: __esri.Graphic };

  readonly HomeOffice!: __esri.Graphic;

  constructor() {
    super();
    this.HomeOffice = Context.getInstance().lib.esri.Graphic.fromJSON(HomeOfficeJSON);
  }

  static getConfig(feature: __esri.Graphic) {
    const defaultHotelConfig: IWorkspaceAreaConfig["hotel"] = {
      maxBookingsPerPerson: null,
      maxDaysInAdvance: null,
      maxDaysPerBooking: null
    };
    const { maxDaysPerBooking, ...wsBaseConfig } = {...defaultHotelConfig };
    const wsConfig: IWorkspaceAreaConfig = {
      hotel: { ...defaultHotelConfig },
      meetingRoom: { ...wsBaseConfig, maxHoursPerBooking: null }
    };
    const chk = (v) => {
      if (typeof v !== "number" || v < 1) return null;
      v = Math.floor(v);
      return v;
    }
    const j = aiimUtil.getAttributeValue(feature.attributes, FieldNames.AREAS_CONFIG);
    if (typeof j === "string" && j.startsWith("{") && j.endsWith("}")) {
      try {
        const json = JSON.parse(j);
        // determine if config contains a single configuration or multiple
        const keys = Object.keys(json);
        if (keys.includes("hotel") || keys.includes("meetingRoom")) {
          // multiple
          wsConfig.hotel.maxBookingsPerPerson = chk(json?.hotel?.maxBookingsPerPerson);
          wsConfig.hotel.maxDaysPerBooking = chk(json?.hotel?.maxDaysPerBooking);
          wsConfig.hotel.maxDaysInAdvance = chk(json?.hotel?.maxDaysInAdvance);
          wsConfig.meetingRoom.maxBookingsPerPerson = chk(json?.meetingRoom?.maxBookingsPerPerson);
          wsConfig.meetingRoom.maxHoursPerBooking = chk(json?.meetingRoom?.maxHoursPerBooking);
          wsConfig.meetingRoom.maxDaysInAdvance = chk(json?.meetingRoom?.maxDaysInAdvance);
        } else {
          wsConfig.hotel.maxBookingsPerPerson = chk(json?.maxBookingsPerPerson);
          wsConfig.hotel.maxDaysPerBooking = chk(json?.maxDaysPerBooking);
          wsConfig.hotel.maxDaysInAdvance = chk(json?.maxDaysInAdvance);
        }        
      } catch(ex) {
        console.error("Error area CONFIG",ex)
      }
    }
    return wsConfig;
  }

  addArea(areaName: string, areaType: AreaTypes, data) {
    const areaId = val.generateRandomId();
    return transactions.addArea(areaType, areaId, areaName, data).then(() => {
      return this._refresh();
    });
  }

  deleteArea(areaItem: __esri.Graphic, deletePeopleData) {
    const f = aiimUtil.findField(this.table.fields,FieldNames.AREA_TYPE);
    const areaType = aiimUtil.getAttributeValue(areaItem.attributes,f.name);
    return transactions.deleteArea(areaItem, areaType, deletePeopleData).then(() => {
      return this._refresh();
    });
  }

  renameArea(areaItem: __esri.Graphic, areaName: string) {
    const f = aiimUtil.findField(this.table.fields,FieldNames.AREA_TYPE);
    const areaType = aiimUtil.getAttributeValue(areaItem.attributes,f.name);
    return transactions.renameArea(areaItem, areaType, areaName).then(() => {
      return this._refresh();
    });
  }

  updateArea(areaItem: __esri.Graphic, areaName: string,data) {
    const f = aiimUtil.findField(this.table.fields,FieldNames.AREA_TYPE);
    const areaType = aiimUtil.getAttributeValue(areaItem.attributes,f.name);
    return transactions.updateArea(areaItem, areaType, areaName, data).then(() => {
      return this._refresh();
    });
  }

  updateAreaRestriction(areaItem: __esri.Graphic, restricted: boolean) {
    const f = aiimUtil.findField(this.table.fields,FieldNames.AREA_TYPE);
    const areaType = aiimUtil.getAttributeValue(areaItem.attributes,f.name);
    return transactions.updateAreaRestriction(areaItem,areaType,restricted).then(() => {
      return this._refresh();
    });
  }

  updateAreaRoles(areaItem: __esri.Graphic, roleData) {
    return transactions.updateAreaRoles(areaItem, roleData);
  }

  getArea(areaId: string) {
    return (this._areasById && this._areasById[areaId]);
  }

  getAreaName(areaId: string): string {
    const area = (this._areasById && this._areasById[areaId]);
    if (area) {
      return aiimUtil.getAttributeValue(area.attributes,FieldNames.AREA_NAME);
    }
    return "";
  }

  getAreaType(areaId: string): AreaTypes {
    const area = (this._areasById && this._areasById[areaId]);
    if (area) {
      return aiimUtil.getAttributeValue(area.attributes,FieldNames.AREA_TYPE);
    }
  }

  getRestrictedField(): __esri.Field | null {
    if (this.table && this.table.fields) {
      let f = aiimUtil.findField(this.table.fields,FieldNames.AREAS_RESTRICTED);
      return f;
    }
    return null;
  }

  getBookingLimits(feature: __esri.Graphic) {
    return AreasTable.getConfig(feature);
  }

  hasBookingLimitFields() {
    if (this.table && this.table.fields) {
      let fields = this.table.fields;
      return !!aiimUtil.findField(fields,FieldNames.AREAS_CONFIG);
    }
    return false;
  }

  load(table: __esri.FeatureLayer) {
    const promise = new Promise<void>((resolve,reject) => {
      this.table = table;
      table.when().then(() => {
        if (table.loadStatus === "failed") {
          console.error("AreasTable: load failed");
        }
        return this._refresh();
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.error("Error loading AREAS table",ex);
        reject(ex);
      });
      table.load();
    });
    return promise;
  }

  makeDefinition() {
    const definition: ILayerDefinition = {
      "name": "AREAS",
      "type": "Table",
      "capabilities": "Query,Editing,Create,Update,Delete,Sync",
      "fields": [
        {
          "alias": "OBJECTID",
          "defaultValue": null,
          "domain": null,
          "editable": false,
          "name": "OBJECTID",
          "nullable": false,
          // @ts-ignore
          "sqlType": "sqlTypeOther",
          "type": "esriFieldTypeOID"
        },
        {
          "alias": "Area Name",
          "defaultValue": "",
          "domain": null,
          "editable": true,
          "length": 500,
          "name": "area_name",
          "nullable": true,
          // @ts-ignore
          "sqlType": "sqlTypeNVarchar",
          "type": "esriFieldTypeString"
        },
        {
          "alias": "Area Type",
          "defaultValue": "",
          "domain": null,
          "editable": true,
          "length": 500,
          "name": "area_type",
          "nullable": true,
          // @ts-ignore
          "sqlType": "sqlTypeNVarchar",
          "type": "esriFieldTypeString"
        },
        {
          "alias": "Area ID",
          "defaultValue": "",
          "domain": null,
          "editable": true,
          "length": 500,
          "name": "area_id",
          "nullable": true,
          // @ts-ignore
          "sqlType": "sqlTypeNVarchar",
          "type": "esriFieldTypeString"
        }
      ],
      "htmlPopupType": "esriServerHTMLPopupTypeNone",
      "allowGeometryUpdates": true,
      "hasMetadata": false,
      "hasStaticData": false,
      "defaultVisibility": true,
      "isDataVersioned": false,
      "globalIdField": "",
      "hasZ": false,
      "copyrightText": "",
      "hasM": false,
      "displayField": "area_name",
      "standardMaxRecordCount": 32000,
      "hasAttachments": false,
      "typeIdField": "",
      "templates": [],
      "uniqueIdField": {
        "name": "OBJECTID",
        "isSystemMaintained": true
      },
      "maxRecordCountFactor": 1,
      "maxRecordCount": 2000,
      "description": "Area related attributes",
      "types": [],
      "tileMaxRecordCount": 8000,
      "objectIdField": "OBJECTID",
      "syncEnabled": true
    };
    return definition;
  }

  queryWorkspaceAreas(...types: AreaTypes[]) {
    return this._refresh(...types).then(() => {
      return this.workspaceAreas;
    });
  }

  refresh(...types: AreaTypes[]) {
    if (!this.table) return Promise.resolve();
    const areasById = {
      [HomeOfficeId]: this.HomeOffice
    };
    const table = this.table;
    const idField = aiimUtil.findField(table.fields, FieldNames.AREA_ID);
    const nameField = aiimUtil.findField(table.fields, FieldNames.AREA_NAME);
    const typeField = aiimUtil.findField(table.fields, FieldNames.AREA_TYPE);
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(table.url+"/"+table.layerId);
    const queryTask: CustomQueryTask = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.where = types?.length > 0
      ? `${typeField.name} IN (${types.map(t => `'${t}'`).join(",")})`
      : "1=1";
    if (table.gdbVersion) query.gdbVersion = table.gdbVersion;
    return queryTask.execute(query).then(result => {
      const hotDeskAreas = [], hotelAreas = [], workspaceAreas = [];
      result.features.forEach(row => {
        const areaId: string = aiimUtil.getAttributeValue(row.attributes,idField.name);
        const areaType: AreaTypes = aiimUtil.getAttributeValue(row.attributes,typeField.name);

        areasById[areaId] = row;
        if (areaType === "hotdesk") {
          hotDeskAreas.push(row);
        } else if (areaType === "hotel") {
          hotelAreas.push(row);
        }
        if (areaType === "hotdesk" || areaType === "hotel" || areaType === "workspace") {
          workspaceAreas.push(row);
        }
      });
      this._sort(hotDeskAreas,nameField.name);
      this._sort(hotelAreas, nameField.name);
      this._sort(workspaceAreas, nameField.name);
      this._areasById = areasById;
      this.hotDeskAreas = hotDeskAreas;
      this.hotelAreas = hotelAreas;
      this.workspaceAreas = workspaceAreas;
      Topic.publish(Topic.AreasRefreshed,{areasById: areasById});
    }).catch(ex => {
      console.error("Error querying AREAS table",ex);
      throw ex;
    });
  }

  _refresh(...types: AreaTypes[]) {
    return this.refresh(...types);
  }

  _sort(areas: __esri.Graphic[], typeFieldName: string) {
    areas.sort((a,b) => {
      const aName = aiimUtil.getAttributeValue(a.attributes,typeFieldName) || "";
      const bName = aiimUtil.getAttributeValue(b.attributes,typeFieldName) || "";
      return aName.localeCompare(bName);
    })
  }

}
