import React, { RefObject } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Attributes, { IAttributesHandle } from "./Attributes";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import OfficePlan from "../../../base/OfficePlan";
import PanelHeaderTools, { HeaderToolType } from "../support/PanelHeaderTools";
import Savebar from "../support/Savebar";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";
import UnitsVM from "./UnitsVM";
import * as component from "../../../../components/util/component";
import * as editorUtil from "../support/editorUtil";
import * as sourceUtil from "../../../base/sourceUtil";

import { WarningMsg, CutType } from "../support/editorUtil";
import { debounce } from "../../../miniapps/support/debounceUtil";
import { findFieldName, getAttributeValue } from "../../../../aiim/util/aiimUtil";
import { 
  HelpTip, getActiveHelpTip, setHelpTip,
  DimensionLabelInfo, setDimensionLabelInfo, getDimensionLabelInfo
} from "../redux";
import { ModalController } from "../../Modal";

import LengthAndWidth from "../support/LengthAndWidth";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";

import Button from "calcite-react/Button";
import TopNav, { TopNavTitle, TopNavList, TopNavLink, TopNavActionsList } from 'calcite-react/TopNav';
import ChevronLeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import ChevronRightIcon from "calcite-ui-icons-react/ChevronRightIcon";
import {
  CalciteAction,
  CalciteAlert,
  CalciteButton,
  CalciteCheckbox,
  CalciteIcon,
  CalciteLabel,
  CalciteNotice,
  CalciteRadioButton, 
  CalciteRadioButtonGroup,
} from "@esri/calcite-components-react";
import RotateAngle from "../support/RotateAngle";
import { getAllTypes } from "../../../miniapps/configurator/wallTypesUtil";

type WorkFlowStep = "draw" | "attributes" | "reTemplate" | "reDraw";
type DrawToolType = "polygon" | "rectangle" | "point";

//copy non-null and non-undefined and non-empty string props from newAttrs to existingAttrs
const mergeNewAttributeValues = function(newAttrs:Record<string, any>, existingAttrs:Record<string, any>) {
  if (!existingAttrs)
    return newAttrs;   
  existingAttrs = existingAttrs && JSON.parse(JSON.stringify(existingAttrs));
  if (!newAttrs)  
    return existingAttrs;
  Object.keys(newAttrs).forEach(k => {
    const val = newAttrs[k];
    if (val!==undefined && val!==null) {
      if (typeof val==='string')
        val.trim() && (existingAttrs[k] = val.trim());
      else 
        existingAttrs[k] = val;
    }
  });
  return existingAttrs;  
}
let numInstances = 0;

interface IUnitsPanelProps {
  tip: HelpTip,
  setTip: (tip: HelpTip) => void,
  dimensionLabelInfo: DimensionLabelInfo,
  setDimensionLabelInfo: (info: DimensionLabelInfo) => void
}
interface IUnitsPanelState {
  cutType: CutType,  
  drawTool: DrawToolType,
  isAttributesValid: boolean,
  isVmValid: boolean,
  isShowTip: boolean,
  isWorkflowActive: boolean,
  workflowStep: WorkFlowStep,
  isBusy: boolean
}

class UnitsPanel extends React.Component<IUnitsPanelProps, IUnitsPanelState> {
  attributesEditorRef:RefObject<IAttributesHandle>;
  viewModel:UnitsVM;
  divFeatureTemplates:RefObject<HTMLDivElement>;
  _coreWidget:__esri.FeatureTemplates; //featureTemplates;
  isHideWarningSelfInt:boolean = false;
  selectedTemplateItem:__esri.TemplateItem; //esri/widgets/FeatureTemplates/TemplateItem

  _mounted = false;

  constructor(props) {
    super(props);
    this.divFeatureTemplates = React.createRef<HTMLDivElement>();
    this.attributesEditorRef = React.createRef<IAttributesHandle>();
    this.state = component.newState({
      drawTool: "rectangle",
      cutType: CutType.none,
      isShowTip: true,
      workflowStep: "draw", 
      isWorkflowActive: false,
      isAttributesValid: false,
      isVmValid: false,
      isBusy: false
    });
    this.viewModel = new UnitsVM();
    this.viewModel.cutType = this.state.cutType;
    this.viewModel.onValidityChange = (isValid) => this.setState({isVmValid: isValid});
    this.viewModel.onDrawComplete   = this.onAfterDraw;
    this.viewModel.onDrawUpdate     = this.onAfterDraw;
    this.viewModel.onBusyChange     = (isBz) => { this.setState({isBusy: isBz}) };
  }

  componentDidMount() {
    this._mounted = true;
    this._initTemplatePicker();
    this.viewModel.dimensionAnnotation.setDimensionLabelInfo(this.props.dimensionLabelInfo);
    sourceUtil.getGrid().activate();

    component.own(this,[
      Topic.subscribe(Topic.PlanModified,params => {
        try {
          if (this._mounted && params && (params.wasUndoRedo || params.wasReconciled)) {
            this.reset(false);
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    ]);
  }

  componentDidUpdate(prevProps) {
    const { dimensionLabelInfo } = this.props;
    if (dimensionLabelInfo !== prevProps.dimensionLabelInfo) {
      this.viewModel.dimensionAnnotation.setDimensionLabelInfo(dimensionLabelInfo);
      if (dimensionLabelInfo.visible) this.viewModel.updateDimensionAnnotation();
    }
  }

  // const { drawTool, workflowStep, isWorkflowActive }= this.state;

  _initTemplatePicker = () => {
    //If app/plan/layers haven't loaded, wait and keep checking ...
    const unitsLayer= sourceUtil.getUnitsLayer();
    if (!this.isLoaded() || !unitsLayer) {
      console.log("Units Layer not yet ready...");
      setTimeout(this._initTemplatePicker, 500);
      return;
    }

    if (!unitsLayer.templates || unitsLayer.templates.length===0) {
      const defAttr = {};
      unitsLayer.fields.forEach((f:__esri.Field) => {
        const gfi = unitsLayer.geometryFieldsInfo; // as __esri.GeometryFieldsInfo;
        const sfs = [gfi.shapeAreaField, gfi.shapeLengthField];
        if (f.editable && !sfs.includes(f.name)
            && (!["geometry"].includes(f.type) || !f.name.toUpperCase().startsWith("SHAPE."))) 
        {
          defAttr[f.name] = null;
        }
      });
      const emptyTemplate = new Context.instance.lib.esri.FeatureTemplate({
        drawingTool: "esriFeatureEditToolPolygon",
        name: "Default Empty Unit",
        description: "",
        prototype: { "attributes": defAttr },
      });
      if (!Array.isArray(unitsLayer.templates))
        unitsLayer.templates = [];
      unitsLayer.templates.splice(0, 0, emptyTemplate);
    }

    this._coreWidget = new Context.instance.lib.esri.FeatureTemplates({
      container: this.divFeatureTemplates.current,
      selectionMode: "single",
      layers: [ unitsLayer ],
      visibleElements: { filter: false }
    });
    const ftVm = this._coreWidget.viewModel;
    const onFtVmReady = () => {
      if (ftVm.items.length > 0 && "items" in ftVm.items[0]) {
        if ((ftVm.items[0] as __esri.TemplateItemGroup).items.length > 0) {
        let firstTemplateItem = ftVm.items[0].items[0];
        if (firstTemplateItem) {
            ftVm.select(firstTemplateItem);
          //firstTemplateItem = firstTemplateItem.clone();
          //ftVm._set("selectedItem", firstTemplateItem);
          //this.selectedTemplateItem = firstTemplateItem;
          //this.onTemplateSelected(firstTemplateItem);
        }
      }
      }
    };
    if (ftVm.state==="ready") {
      setTimeout(onFtVmReady, 1000);
    } else { 
      const tpStateWatcher = ftVm.watch("state", (vmState:string) => {
        if (vmState==="ready") {
          tpStateWatcher.remove();
          setTimeout(onFtVmReady, 1000);
        }
      });
    }

    component.own(this, this._coreWidget.on("select", (evtTemplate:__esri.FeatureTemplatesViewModelSelectEvent) => {
      this.selectedTemplateItem = evtTemplate.item;
      this.onTemplateSelected(evtTemplate.item, this.state.drawTool);
    }));
  }  

  componentWillUnmount() {
    this._mounted = false;
    sourceUtil.getGrid().deactivate();
    this.viewModel.onDrawComplete = null;
    this.viewModel.onDrawUpdate = null;
    this.viewModel.destroy();
    //this.viewModel = null;
    component.componentWillUnmount(this);
    this._coreWidget = null;
    Context.getInstance().views.mapClickDisabled = false;
  }

  isLoaded = () => {
    const planner = Context.instance.spaceplanner.planner;
    return planner && planner.hasValidPlan(); 
  };

  onFormValueChange = debounce(({layer, feature, fieldName, value, valid}) => {
    if (this.viewModel.activeFeature)
      this.viewModel.activeFeature.attributes[fieldName] = value;
  }, 500)

  onTemplateSelected(templateItem:__esri.TemplateItem, toolDrawType?: DrawToolType) {
    const i18n = Context.instance.i18n;
    if (!toolDrawType) { 
      toolDrawType = this.state.drawTool;
    }

    const floorFilter = Context.getInstance().views.floorFilter;
    const levelId = floorFilter.activeWidget.level;
    if (!levelId && !!0) {
      ModalController.showMessage(i18n.editor.units.noFloorMessage, i18n.editor.units.noFloorTitle);
      return;
    }

    let attributes = (templateItem || this.selectedTemplateItem).template.prototype.attributes;
    attributes = attributes && Object.assign({}, attributes);

    const isModifying = this.state.workflowStep==="reTemplate" && this.viewModel.isValid();
    if (isModifying) {
      //if already editing, then merge valid template props with existing attrs
      attributes = mergeNewAttributeValues(attributes, this.viewModel.activeFeature.attributes);
      
      this.viewModel.activeSketchViewModel.polygonSymbol = this.viewModel.getSymbol({attributes}) as __esri.SimpleFillSymbol;
      this.viewModel.updateAttributes(attributes);
      //this.setState({workflowStep: "attributes"});
    } else {
      this.setState({ drawTool: toolDrawType, cutType: CutType.none });
      this.viewModel.cutType = CutType.none;
      this.viewModel.activateDrawUnit(attributes, toolDrawType, isModifying);
    }
  }

  onAfterDraw = debounce((tool:"update"|"create"|"undo"|"redo") => {
    const ae = this.attributesEditorRef && this.attributesEditorRef.current;
    const vmAttrs = this.viewModel.activeFeature && this.viewModel.activeFeature.attributes;
    console.debug('onAfterDraw', vmAttrs);
    if (ae && vmAttrs) {
      const lyr = sourceUtil.getUnitsLayer();
      [FieldNames.UNITS_AREA_GROSS, FieldNames.HEIGHT_RELATIVE].forEach(fldNm => {
        fldNm = findFieldName(lyr.fields, fldNm);
        console.debug("Setting value on AttributeEditor:", fldNm, "=", fldNm && vmAttrs[fldNm]);
        fldNm && ae.setFormValue(fldNm, vmAttrs[fldNm]);
      });
      setTimeout(()=>this.setState({isVmValid: this.viewModel.isValid(), isAttributesValid: ae.isValid()}), 300);
    } else {
      this.setState({isAttributesValid: false});
    }

    if (tool==="create") {
      this.setState({workflowStep: "attributes", isWorkflowActive: true});
    }
    
  }, 500);

  onClickDrawType(toolDrawType: DrawToolType) {
    const i18n = Context.instance.i18n;
    if (!this.selectedTemplateItem) {
      this.setState({ drawTool: toolDrawType });
      ModalController.showMessage(i18n.editor.units.noTemplateSelectedMsg);
    } else {
      this.setState({ workflowStep: "draw", drawTool: toolDrawType }, () => {
        this.onTemplateSelected(this.selectedTemplateItem, toolDrawType);
      });
    }
  }

  onHeaderToolClick = (e, toolInfo) => {
    const tool = toolInfo && toolInfo.name;
    if (tool==="rectDraw") {
      this.onClickDrawType("rectangle");
    } else if (tool==="polyDraw") {
      this.onClickDrawType("polygon");
    } else if (tool==="placeUnit") {
      this.onClickDrawType("point");
    } else if (tool==="zoomIn") {
      editorUtil.onZoomToFeature(this.viewModel.getActiveFeature());
    } else if (tool==="rotate") {
      //TODO
    } else if (tool==="flipV") {
      this.viewModel.flip("v");
    } else if (tool==="flipH") {
      this.viewModel.flip("h");
    } else if (tool==="dimensionLabels") {
      const info = Object.assign({},this.props.dimensionLabelInfo);
      info.visible = !info.visible;
      this.props.setDimensionLabelInfo(info);
    } else if (tool==="grid") {
      sourceUtil.getGrid().setVisible(!sourceUtil.getGrid().isVisible());
      component.refresh(this);
    } else {
      //console.warn(`Tool [${tool}] not implemented`, toolInfo);
    }
  }

  renderHeaderTools = () => {
    const i18n = Context.instance.i18n;

    const { drawTool, workflowStep, isWorkflowActive } = this.state;
    const isDrawMode = ["draw", "reTemplate"].includes(workflowStep);
    const isAttrMode = "attributes"===workflowStep;

    if (!isDrawMode && !isAttrMode) {
      return undefined;
    }    

    let selTool = null;
    if (drawTool==="rectangle") selTool = "rectDraw";
    else if (drawTool==="polygon") selTool = "polyDraw";
    else if (drawTool==="point") selTool = "placeUnit";
    const tools: HeaderToolType[] = isDrawMode 
          ? ["dimensionLabels", "grid", "separator", "rectDraw", "polyDraw", "placeUnit"]
          : ["dimensionLabels", "grid", "separator", "rotate", "flipV", "flipH", "separator", "zoomIn"];
    const feature = this.viewModel.getActiveFeature();
    const rotatePopover = feature && (<RotateAngle
      geometry={feature.geometry}
      onRotate={(angle, geom) => this.viewModel.updateActiveFeatureGeom(geom)}
      onOpen={comp => {
        comp && (comp.geometry = this.viewModel.getActiveFeature().geometry);
      }}
    />);

    const activeTools = [];
    if (this.props.dimensionLabelInfo.visible) activeTools.push("dimensionLabels")
    if (sourceUtil.getGrid().isVisible()) activeTools.push("grid")

    return (
      <PanelHeaderTools 
        className="i-flex-between"
        key={"pht_"+numInstances}
        tools={tools}
        selectedTool={selTool}
        activeTools={activeTools}
        toolInfos={
          {"rotate": {name: "rotate", icon: "rotate", text: i18n.editor.headerTools.rotate, jsx: rotatePopover}}
        }
        disabledTools={!feature ? ["rotate"]:[]}
        onButtonClick={this.onHeaderToolClick}/>
    );
  }


  render() {
    const i18n = Context.instance.i18n;
    const isRtl = Context.instance.uiMode.isRtl;
    const { tip } = this.props;
    const { drawTool, workflowStep, isWorkflowActive }= this.state;

    const isPanelInit = !!this._coreWidget;
    const isDrawMode = ["draw", "reTemplate"].includes(workflowStep);
    const isAttrMode = workflowStep==="attributes";
    //const isFormValid = this.attributesEditorRef && this.attributesEditorRef.current && !this.attributesEditorRef.current.hasInvalidInput().hasInvalid;
    //const canSave = this.canSave(); //this.viewModel.isValid() && isAttrMode && this.state.isAttributesValid;

    const unitsLayer = sourceUtil.getUnitsLayer();
    const feature = this.viewModel.getActiveFeature();
    console.debug("render() UnitsVM.activeFeature: ", feature, "isWorkflowActive:", isWorkflowActive, "workflowStep:", workflowStep);

    const headerText = feature && feature.attributes && getAttributeValue(feature.attributes, FieldNames.UNITS_USE_TYPE);
    
    let prompt = "";
    if (drawTool === "polygon") {
      prompt = i18n.editor.units.tipDrawPoly;
    } else if (drawTool === "rectangle") {
      prompt = i18n.editor.units.tipDrawRect;
    } else if (drawTool === "point") {
      prompt = i18n.editor.units.tipEnterDimensions;
    } else {
      prompt = i18n.editor.units.tipSelectTemplate;
    }
    const cssDisabled = {pointerEvents:"none", userSelect:"none", filter: "blur(4px)"};
    const isShowToolTip = isDrawMode && !(tip && tip.visible) && prompt;

    let cssNm = isShowToolTip
      ? "i-editor-feature-templates"
      : "i-editor-feature-templates i-close";

    if(isDrawMode && feature) {
      if (isShowToolTip) cssNm = "i-editor-feature-templates i-nav";
      else cssNm =  "i-editor-feature-templates i-nav i-close"
    }

    return (
      <div className={`i-editor-sidebar-container i-editor-units-container${!isPanelInit ? " i-editor-sidebar-disabled" : ""}`}>
      {isDrawMode && feature && this.renderTopNav({label: i18n.editor.units.panelFields, isShowNext:true, nextStep:"attributes"})}
      {isAttrMode && this.renderTopNav({label: i18n.editor.units.panelDraw, isShowPrev:true, prevStep:"reTemplate"})}
        <div className="i-editor-sidebar-toolbar">
          {this.renderHeaderTools()}
        </div>
        <div className="i-editor-sidebar-content">
          <CalciteNotice icon="lightbulb" scale="s" closable width="full" open={isShowToolTip ? true : undefined}
            onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: prompt })}>
            <div slot="message">{prompt}</div>
          </CalciteNotice>

          {workflowStep==="draw" && drawTool==="point" && 
            <LengthAndWidth showLabel showLength showWidth
              lengthAndWidthInfo={this.viewModel.lengthAndWidthInfo}
              onChange={(lengthAndWidthInfo: ILengthAndWidthInfo) => {
                this.viewModel.lengthAndWidthInfo = lengthAndWidthInfo;
                this.viewModel.activateDrawUnit(this.viewModel.activeFeature.attributes,drawTool,false);
              }}
            />
          }
          
          <div style={isPanelInit && isDrawMode ? { margin:"0.5rem 0 0 0.25rem", overflowY: "auto" } : {}}>
            <div ref={this.divFeatureTemplates}
              style={{display:isPanelInit && isDrawMode?'block':'none'}}
              className={cssNm}>
            </div>
            {!this._coreWidget && <div><h4>Loading...</h4></div>}
          </div>
          {isAttrMode && this.renderUnitOverlapOptions()}
          {isAttrMode && 
            <div className="i-editor-units-attributes">
              {feature && <Attributes
                  ref={this.attributesEditorRef}
                  isEditMode={true}
                  idField={"unit_id"}
                  headerText={headerText}
                  usePopupFields={true}
                  formOptions={{ 
                    layer: unitsLayer,
                    feature,
                    type: "unit",
                    allowName: true,
                    readOnlyFields: ["area_id","level_id","assignment_type"],
                    nonVisibleFields: ["site_id","site_name","facility_id",
                      "facility_name","level_id","level_name",
                      "level_number","vertical_order","section_id",
                      "section_name","height_relative"],
                    nonNullableFields: ["use_type","name","unit_id"],
                    topFields: ["use_type", "name", "name_long", "schedule_email", "capacity"],
                    bottomFields: ["unit_id"],
                    showAsterisk: true,
                    useGroup: true,
                    useTypes: getAllTypes(unitsLayer)
                  }}
                  onMount={(cff:__esri.FeatureForm) => cff && this.setState({isAttributesValid: cff.viewModel.valid}) }
                  onValueChange={this.onFormValueChange}
                  onDomainValueChange={({layer, feature, fieldName, value, valid}) => {
                    this.viewModel.activeFeature.attributes[fieldName] = value;
                    this.viewModel.updateUnitSketch();
                  }}
                  onFormValidityChange={(isValid)=>{
                    console.debug("FeatureForm isValid:", isValid);
                    if (this.state.isAttributesValid!==isValid)
                      this.setState({isAttributesValid: isValid});
                  }}
              />}
            </div>
          }
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderSavebar()}
        </div>
      </div>
    );
  }

  renderSavebar() {
    const editIsActive = (this.state.workflowStep === "attributes");
    return (
      <Savebar
        parentPanelKey="units"
        resetDisabled={false}
        saveDisabled={!this.canSave()}
        editIsActive={editIsActive}
        onReset={()=>this.reset(true)} 
        onSave={this.saveClicked}
      />
    )
  }

  renderUnitOverlapOptions() {
    const i18n = Context.instance.i18n;
    const { cutType } = this.state;
    const setCutType = e => this.setState({cutType: e.currentTarget.value});
    return (
      <div className="i-editor-overlap-options">
        <h4>{i18n.editor.units.newUnitOverlap}</h4>
        <CalciteRadioButtonGroup name="reShapeUnitOptions" layout="vertical" 
            onCalciteRadioButtonGroupChange={async e => {
              const prevCT = this.viewModel.cutType;
              const ct = this.viewModel.cutType = this.state.cutType;
              if (ct === CutType.cutUnderlying) {
                await this.viewModel.cutUnderlyingUnits(prevCT===CutType.cutThis);
              } else if (ct === CutType.cutThis) {
                await this.viewModel.cutNewUnit();
              } else if (ct === CutType.none) {
                this.viewModel.cutNothing();
              }
              this.viewModel.onDrawUpdate && this.viewModel.onDrawUpdate(null);
            }}>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
                checked={cutType === CutType.cutUnderlying ? true : undefined}
                value={CutType.cutUnderlying}
                onCalciteRadioButtonChange={setCutType} />
            {i18n.editor.units.newUnitOverlapPreferNew}
          </CalciteLabel>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
              checked={cutType === CutType.cutThis ? true : undefined}
              value={CutType.cutThis}
              onCalciteRadioButtonChange={setCutType} />
            {i18n.editor.units.newUnitOverlapPreferExisting}
          </CalciteLabel>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
              checked={cutType === CutType.none ? true : undefined}
              value={CutType.none}
              onCalciteRadioButtonChange={setCutType} />
            {i18n.editor.units.overlapDoNothing}
          </CalciteLabel>
        </CalciteRadioButtonGroup>
      </div>
    )
  }  

  renderTopNav({label, isShowPrev=false, isShowNext=false, prevStep="draw", nextStep="attributes"}:
    {label:string, isShowPrev?:boolean, isShowNext?:boolean, prevStep?:WorkFlowStep, nextStep?:WorkFlowStep}
  ) {
    const i18n = Context.instance.i18n;
    const styleAttrMode = {position:"absolute", width:"100%", top:"-3.3rem"};
    return (
      <TopNav className="i-editor-units-panel-header-nav" style={{...(isShowPrev ? styleAttrMode : {})}}>
        <TopNavTitle style={{padding: "0rem" }}>
          {isShowPrev && <Button key="back" title={i18n.general.back}
                className="i-editor-units-panel-header-nav-btn"
                onClick={async (e) => {
                  prevStep && this.setState({workflowStep:prevStep});
                  this.viewModel.cancelSketch();
                }}
                iconButton={true} icon={<ChevronLeftIcon/>} />}
        </TopNavTitle>
        <span className="i-editor-units-attributes-title">{label}</span>
        <TopNavActionsList>
          {isShowNext && <Button key="forward" title={i18n.general.next}
                  className="i-editor-units-panel-header-nav-btn"
                  onClick={(e) => {
                    nextStep && this.setState({workflowStep:nextStep});
                  }}
                  iconButton={true} icon={<ChevronRightIcon />} />}
        </TopNavActionsList>
      </TopNav>
    )
  }

  warnSelfInt = async () => {
    const i18n = Context.instance.i18n;
    if (this.isHideWarningSelfInt)
      return true;
    const confirmResult = await ModalController.confirm({
      title: i18n.general.warning,
      message: i18n.editor.units.shapeWarningMsg,
      okLabel: i18n.general.save,
      cancelLabel: i18n.general.cancel,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    });
    return confirmResult.ok
  }

  reset = async (isConfirm=false) => {
    const i18n = Context.instance.i18n;
    let isReset = true; 
    if (isConfirm) {
      const confirmResult = await ModalController.confirm({
        title: i18n.editor.units.resetModalTitle,
        message: i18n.editor.units.resetModalMessage,
        okLabel: i18n.general.reset,
        cancelLabel: i18n.general.cancel,
        showOKCancel: true,
        closeOnOK: true,
        closeOnCancel: true
      });
      isReset = confirmResult.ok;
    }
    if (isReset) {
      this.viewModel.reset();
      this.setState({
        workflowStep: "draw", 
        isWorkflowActive: false
      }, () => {
        if (this.selectedTemplateItem && this.state.drawTool) {
          this.onTemplateSelected(this.selectedTemplateItem, this.state.drawTool); 
        }     
      });
    }
  }

  canSave = () => {
    const isAttrMode = this.state.workflowStep==="attributes";
    const ae:IAttributesHandle = this.attributesEditorRef && this.attributesEditorRef.current;
    if (ae) {
      const isFormValid = ae && ae.isValid();
      //console.debug(isFormValid, this.state.isAttributesValid, ae.validate({apply:true}));
    }
    return this.viewModel.isValid() 
      && isAttrMode 
      && this.state.isAttributesValid 
      && this.state.isVmValid 
      && !this.state.isBusy;
  }

  save = async () => {
    const guard = new TransactionGuard({force:true, minimal:true});
    this.setState({isAttributesValid: false});
    try {
      guard.close();
      await this.viewModel.save();
      this.reset(false);
      Topic.publish(Topic.PlanModified, { action: OfficePlan.Action_AssignmentsUpdated });
    } catch(ex) {
      console.error(ex);
      guard.close();
      Topic.publishErrorUpdatingData(ex.message);
    }
  }

  saveClicked = async () => {
    const ae:IAttributesHandle = this.attributesEditorRef && this.attributesEditorRef.current;
    if (ae && ae.isValid() && this.viewModel.isValid()) {
      if (this.viewModel.isGeometrySimple())
        this.save();
      else
        await this.warnSelfInt() && this.save();
    }
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state),
  dimensionLabelInfo: getDimensionLabelInfo(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("units", tip)),
  setDimensionLabelInfo: (info: DimensionLabelInfo) => dispatch(setDimensionLabelInfo(info))
})
export default connect(mapStateToProps, mapDispatchToProps)(UnitsPanel);