import React, { Component } from "react";

import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

import { ListItem, ListItemTitle } from "../../styles/Common/list";

class AreaListItem extends Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getAreaId() {
    const areaItem = this.getAreaItem();
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  }

  getAreaItem() {
    return this.props.areaItem;
  }

  getAreaName() {
    const areaItem = this.getAreaItem();
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_NAME);
  }

  render() {
    const areaItem = this.getAreaItem();
    const areaName = this.getAreaName();
    return (
      <ListItem
        bar="blue"
        disabled={!!this.props.disabled}
        onClick={() => this.props.selectHandle(areaItem)}
        selected={this.props.selected}
      >
        <ListItemTitle>{areaName}</ListItemTitle>
      </ListItem>
    );
  }
}

export default AreaListItem;
