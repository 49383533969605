import Context from "../../context/Context"

export function findFeatureLayers() {
  const context = Context.getInstance()
  const activeView = context.views && context.views.activeView
  const map = activeView && activeView.map

  let allLayers = [];
  if (map && map.allLayers) {
    allLayers = map.allLayers.toArray().slice();
  }

  // const allLayers = []
  // const findAllLayers = (parent) => {
  //   if (!parent) return
  //   if (!parent.layers || !parent.layers.items || parent.layers.items.length < 1) {
  //     allLayers.push(parent)
  //   } else {
  //     const layers = parent.layers && parent.layers.items
  //     layers.forEach(lyr => {
  //       findAllLayers(lyr)
  //     })
  //   }
  //
  // }
  // findAllLayers(map)
  //   allLayers.map(lyr => {
  //     return {title: lyr.title, layerId: lyr.layerId, id: lyr.id, type: lyr.type, layer: lyr}
  //   })

  if (allLayers && allLayers.length > 0) return allLayers
  else return []
}

export function changeLayerVisibility(layerids) {
  let states = [];
  const featureLayers = findFeatureLayers();
  if(!layerids) {
    featureLayers.forEach(lyr => {
      lyr.visible = false;
    })
    return;
  }
  featureLayers.forEach(lyr => {
    states.push({layer: lyr, visible: false});
  })

  let layerIds;
  try {
    layerIds = JSON.parse(layerids);
  } catch (error) {
    console.warn("Error parsing fields for URL layer visibility");
    console.error(error);
  }

  if(!Array.isArray(layerIds)) layerIds = [];

  layerIds.forEach((lyrId)=> {
    let id = lyrId.layerId, subLyrIds, hasSubLyr = false;
    if(lyrId.subLayerIds){
      hasSubLyr = true;
      subLyrIds = lyrId.subLayerIds;
    }
    else id = lyrId.layerId;
    states.forEach(state => {
      const lyr = state.layer;
      const ftrLyrId = lyr.id;
      if(ftrLyrId.includes(id)) {
        state.visible = true;
        if(hasSubLyr === true) {
          lyr.when(()=> {
            const items = lyr.allSublayers.items;
            if(lyr.allSublayers && (lyr.allSublayers.length>0)){
              for(let i=0;i<items.length;i++){
                if(Array.isArray(subLyrIds) && subLyrIds.includes(items[i].id)) {
                  items[i].visible = true;
                }else{
                  items[i].visible = false;
                }
              }
            }
          })
        }
      }
    })
  })
  states.forEach((state)=> {
    state.layer.visible = state.visible;
  })
}

export function getLayerVisibilityStates() {
  const featureLayers = findFeatureLayers()
  const layerIds = [];
  featureLayers.forEach(lyr => {
      if (isLayerVisible(lyr) && lyr.type!=="graphics") {
        let isMapService = false;
        let id = lyr.id;
        let sublayerIds = [];
        sublayerIds.push(lyr.id);
        if(lyr.type === "map-image") isMapService = true;
        if(lyr.type === "map-image" && lyr.allSublayers && lyr.allSublayers.items && lyr.allSublayers.items.length>0){
          const items = lyr.allSublayers.items;
          items.forEach((item)=> {
            if(item.visible) sublayerIds.push(item.id);
          })
        }
        if (id) layerIds.push({sublayerIds,isMapService})
      }
  })
  return layerIds;
}

function isLayerVisible(layer) {
  return layer && layer.visible
}
