import React from "react";

import * as Actions from "./Actions";
import Context from "../../../context/Context";
import InfoPanelHeader from "./InfoPanelHeader";
import NetworkService from "../../../aiim/base/NetworkService";
import Topic from "../../../context/Topic";
import * as appLaunchUtil from "../../../aiim/util/appLaunchUtil";
import * as component from "../../util/component";
import ShareUrl from "./ShareUrl";

const CSS = {
  actionBar: "i-actionbar",
  main: "i-content",
  infopanelContent: "i-infopanel-content",
  featureContent: "i-infopanel-content-feature",
  tabContent: "i-infopanel-feature-tabcontent",
  graphicFeature: "i-graphic-feature"
};

export default class GraphicFeature extends React.Component {

  _mounted = false;

  constructor(props){
    super(props);
    this.state = component.newState({
      appLaunchUrls: [],
      isRoutable : true
    });
  }

  componentDidMount() {
    this._mounted = true;

    const networkservice = new NetworkService();
    let promise = networkservice.isRoutable(this.getFeature());
    promise.then((isRoutable)=>{
      if (!this._mounted) return;
      this.setState({
        isRoutable : !!isRoutable
      })
    }).catch((error)=>{
      console.log("Error", error);
    });

    this.generateAppLaunchUrls();

    component.own(this,[
      Topic.subscribe(Topic.AppLaunchURLsChanged,params => {
        this.generateAppLaunchUrls();
      }),
      Topic.subscribe(Topic.ReferenceLayerUpdated,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  generateAppLaunchUrls() {
    appLaunchUtil.generateUrlsCategoriesAndLayers(this.props.item).then(urls => {
      if (!this._mounted) return;
      this.setState(state => {
        return {
          appLaunchUrls: urls
        };
      });
    }).catch(ex => {
       console.error("Error generating app launch urls",ex);
    });
  }

  getCaption() {
     return this.props.item.getLabel();
  }

  getFeature() {
    return (this.props.searchResult && this.props.searchResult.feature);
  }

  getLayer() {
    let feature = this.getFeature();
    if (feature) return feature.layer;
    return null;
  }

  render() {
    return (
      <div className={CSS.main}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }

  renderActions() {
    const item = this.props.item;
    const appLaunchUrls = this.state.appLaunchUrls;
    const isKiosk = Context.getInstance().isKiosk;

    return (
      <div key="i-infopanel-content" className={CSS.infopanelContent}>
        <div className={CSS.featureContent}>
          <div className={CSS.tabContent}>
              <ul className={CSS.actionBar}>
              <li key="directions"><Actions.Directions disabled={!this.state.isRoutable}
                  item={item}/></li>
              {!isKiosk && 
              ( <>
                <li key="saveFavorite"><Actions.SaveFavorite item={item}/></li>
                <li key="share"><ShareUrl item={this.props.item}/></li>
                <li key="appLaunch"><Actions.AppLaunch urls={appLaunchUrls}/></li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderContent(){
    let node = null;
    let coordinates =Context.instance.i18n.infoPanel.coordinates;
    let xCoordinate = Context.instance.i18n.infoPanel.xCoordinate;
    let yCoordinate= Context.instance.i18n.infoPanel.yCoordinate;
    if(this.props.searchResult.feature){
      const item = this.props.item
      const feature = item.getFeature();
      const geometry = feature.geometry
      let x = geometry && geometry.x;
      let y = geometry && geometry.y;
      if (geometry && typeof geometry.latitude === "number" && typeof geometry.longitude === "number") {
        x = geometry.longitude;
        y = geometry.latitude;
      }
      if(geometry && geometry.type === "point") {
        node = (
          <div key="value" className="i-font-size-s">
            <div key="x">
              <span className="i-margin-all-small">{xCoordinate}</span>
              <span>{x}</span>
            </div>
            <div key="y">
              <span className="i-margin-all-small">{yCoordinate}</span>
              <span>{y}</span>
            </div>
          </div>
        );
      }
    }

    let outdoorCoordiantes = (
        <div key="i-feature-tabcontent" className={CSS.tabContent}>
          <div  className ="i-padded">
            <div key="key" className="i-font-size-xs">{coordinates}</div>
                {node}
          </div>
        </div>
    )

    return (
        <div key="i-infopanel-content" className={CSS.infopanelContent}>
          <div key="i-feature-content" className={CSS.featureContent}>
            {this.renderActions()}
            <div className={CSS.graphicFeature}>{this.props.backNextButtons}</div>
            {outdoorCoordiantes}
          </div>
        </div>
      );
  }

  renderHeader() {
    const caption = this.getCaption();
    return <InfoPanelHeader caption={caption}/>
  }
}
