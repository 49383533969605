import React from "react";
import { connect } from "react-redux";
import { EditorTabs, getActiveTab, setActiveTab } from "./redux";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import { IRootState } from "../../../redux/Rdx";
import { Dispatch } from "redux";

interface ISidebarButtonProps {
  activeSidebarTab: EditorTabs,
  className?: string,
  expanded: boolean,
  icon: JSX.Element,
  label: string | JSX.Element,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  setActiveTab: (tab: EditorTabs) => void,
  tab?: EditorTabs
}

class SidebarButton extends React.Component<ISidebarButtonProps> {
  constructor(props: ISidebarButtonProps) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ConfiguratorStarted, params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.PlanOpened,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { expanded, label, tab, activeSidebarTab } = this.props;
    const active = tab && (tab === activeSidebarTab);

    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    const planner = Context.instance.spaceplanner.planner;
    let disabled = !planner || !planner.hasValidProject() || !planner.hasValidPlan(); 
    if (tab === "app-switcher" && cfgOn && disabled && planner && planner.hasValidProject()) disabled = false;

    return (
      <div className={this.props.className}>
        <button 
          type="button"
          title={!expanded ? label as string: undefined}
          className={active ? "i--active": undefined}
          style={{cursor: "pointer"}}
          disabled={disabled ? true : undefined}
          onClick={(e) => {
            if (tab !== "app-switcher") {
              if (tab === activeSidebarTab)
                this.props.setActiveTab(null);
              else if (tab)
                this.props.setActiveTab(tab);
            }
            if (this.props.onClick) 
              this.props.onClick(e);
          }}
        >  
          {this.props.icon}
          {expanded &&
            <span className="i--label">{label}</span>
          }
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  activeSidebarTab: getActiveTab(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setActiveTab: (tab: EditorTabs) => dispatch(setActiveTab(tab))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarButton);
