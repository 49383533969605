import { IApplyEditsOptions } from "@esri/arcgis-rest-feature-layer";
import { IFeature } from "@esri/arcgis-rest-types";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findFieldName, getAttributeValue } from "../../../aiim/util/aiimUtil";
import { getAttributes } from "../../components/common/MultipleAssignments/multipleAssignmentsUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import { addAttributeEdit } from "./transactionUtil";
export type UpdateAttributesTask = {
  type: "unit" | "person",
  attributes: Record<string, any>,
  feature: __esri.Graphic,
  includeGeometry?: boolean
}
export function prepare(task: UpdateAttributesTask) {
  const promise = new Promise<transaction.ITransactionInfo>((resolve,reject) => {
    const updates: IFeature[] = [], undos: IFeature[] = [];
    let layer: __esri.FeatureLayer, hasPeopleEdits = false, hasUnitEdits = false;
    if (task.type === "unit") {
      layer = sourceUtil.getUnitsLayer();
      hasUnitEdits = true;
    } else if (task.type === "person") {
      layer = sourceUtil.getPeopleLayer();
      hasPeopleEdits = true;
    }
    // @ts-ignore
    const keyField = layer.globalIdField;
    let fetchPromise = Promise.resolve<__esri.Graphic[]>([]);

    if (hasPeopleEdits && layer) {
      const feature = task.feature;
      const attributes = getAttributes(feature);
      const email = getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL);
      fetchPromise = _findDuplicatePeople(layer, email);
    } else {
      const update: IFeature = { attributes: task.attributes };
      update.attributes[keyField] = task.feature.attributes[keyField];
      if (task.includeGeometry) {
        update.geometry = task.feature.geometry.toJSON();
      }
      updates.push(update);
  
      const undo = {attributes: Object.assign({},task.feature.attributes)};
      undos.push(undo);
    }

    // Apply updates to all duplicate occupant records
    fetchPromise.then((features) => {
      if (features && features.length > 0 && hasPeopleEdits) {
        features.forEach((record) => {
          const update = { attributes: {} };
          update.attributes[keyField] = record.attributes[keyField];
          Object.keys(task.attributes).forEach((field) => {
            const value = task.attributes[field];
            addAttributeEdit(layer.fields, update.attributes, field, value);
          })
          updates.push(update);
      
          const undo = {attributes: Object.assign({}, record.attributes)};
          undos.push(undo);
        })
      }

      const transactionInfo = transaction.makeTransactionInfo();
      transactionInfo.peopleEdits = hasPeopleEdits;
      transactionInfo.unitEdits = hasUnitEdits;
      transactionInfo.undo.peopleEdits = hasPeopleEdits;
      transactionInfo.undo.unitEdits = hasUnitEdits;
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undos
      });
  
      resolve(transactionInfo);
    })
  });
  return promise;
}

function _findDuplicatePeople(layer, email) {
  return new Promise<__esri.Graphic[]>((resolve, reject) => {
    const emailField = findFieldName(layer.fields, FieldNames.PEOPLE_EMAIL);
    const params = layer.createQuery();
    params.where = `${emailField} = '${email}'`;

    layer.queryFeatures(params).then((result) => {
      const features = (result && result.features) || []
      resolve(features);
    }).catch(e => {
      console.error("Couldn't fetch duplicate people", e);
      resolve([]);
    })
  })
}
