import moment from "moment";

// aiim.datasets
import FieldNames from "../../../../../../aiim/datasets/FieldNames";

// aiim.util
import { getAttributeValue } from "../../../../../../aiim/util/aiimUtil";

import Context from "../../../../../../context/Context";
import Topic from "../../../../../../context/Topic";
import { findDuplicateRecords } from '../../../../../../util/multipleAssignmentsUtil';

// main.Events
import { getZulu } from "../../../../Events/dateUtil";

// main.More.BookWorkspace.WorkspaceReservation
import HotelPinsLayer from "./HotelPinsLayer";

/**
 * Set the visibility of the Reservations layer (Not used yet in Map Hotels)
 * @param {boolean} visible
 */
 export function changeReservationsVisibility(visible) {
  const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }

  const layer = reservations.layer2D;
  if (layer.visible !== visible) {
    layer.visible = visible;
  }
}

/**
 * Set the definitionExpression on the Reservations layer to filter
 * features by time
 * @param {Date} start
 * @param {Date} end
 * @param {string} type Meeting rooms or hotels
 */
export function setReservationsDefExp(start, end, type?) {
  const reservations = Context.getInstance().aiim.datasets && 
                        Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }

  const where = makeDefExpClause(start, end, type);
  const layer = reservations.layer2D;
  layer.definitionExpression = where;
}

/**
 * Start or restart Reservations current time interval
 */
export function setReservationsInterval() {
  const reservations = Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }
  reservations.setCurrentTimeInterval();
}

/**
 * Clear the Reservations current time interval
 */
export function clearReservationsInterval() {
  const reservations = Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }
  reservations.clearCurrentTimeInterval();
}

/**
 * Clear the interval and show the Reservations layer if needed
 */
export function showReservationsByFilter() {
  const reservations = Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }

  // Removed logic from Map Hotels story (to show Reservations layer when hoteling active)
  // -------------------------------------------------------
  // const layer = reservations && reservations.layer2D;
  // const previousVisibility = layer.visible;
  // reservations.previousVisibility = previousVisibility;
  // changeReservationsVisibility(true);
  // -------------------------------------------------------

  clearReservationsInterval();
}

/**
 * Return Reservations layer visibility to its previous state,
 * restart Reservations update interval
 */
export async function hideReservationsByFilter() {
  clearUnitPins();

  const reservations = Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }

  // Removed logic from Map Hotels story (to revert Reservations layer to previous visibility state
  // when deactivating hoteling panel)
  // -------------------------------------------------------
  // changeReservationsVisibility(reservations.previousVisibility);
  // -------------------------------------------------------

  const layer = reservations && reservations.layer2D;
  if (layer) {
    const { start, end } = makeCurrentTimeRange();
    setReservationsDefExp(start, end);
  }
  setReservationsInterval();
}

/**
 *
 * @param {Date} start
 * @param {Date} end
 * @param {string} type Meeting rooms or hotels
 * @returns WHERE clause for the Reservations layer
 */
export function makeDefExpClause(start, end, type?): string {
  const reservations = Context.getInstance().aiim.datasets.reservations;
  if (!reservations) {
    return;
  }

  const startTimeField = reservations.startTimeField;
  const endTimeField = reservations.endTimeField;
  const stateField = reservations.stateField;

  // Status domains
  const PENDING_APPROVAL = 0;
  const APPROVED = 1;
  const CHECKED_IN = 4;

  const dtStart = getZulu(start);
  const dtEnd = getZulu(end);

  const clauses = [];
  const stateCheck = `(${stateField} IN (${PENDING_APPROVAL}, ${APPROVED}, ${CHECKED_IN}))`;
  const timeChecks = [
    `(${startTimeField} < TIMESTAMP '${dtStart}' AND ${endTimeField} > TIMESTAMP '${dtStart}')`,
    `(${startTimeField} >= TIMESTAMP '${dtStart}' AND ${startTimeField} < TIMESTAMP '${dtEnd}')`
  ];

  clauses.push(stateCheck);
  clauses.push(timeChecks.join(" OR "));

  // Filter meeting room reservations vs hotel reservations (NOT IN USE)
  // if (type) {
  //   const hotelingUnits = await Context.getInstance().hotelingUnits;
  //   if (hotelingUnits) {
  //     const hotelingUnitIds = hotelingUnits.map((unit) => {
  //       return `'${getAttributeValue(unit.attributes, FieldNames.UNIT_ID)}'`;
  //     });
  //     const operator = type === "hotels" ? "IN" : type === "meetingRooms" ? "NOT IN" : "";
  //     const hotelsCheck = `${unitIdField} ${operator} (${hotelingUnitIds.join(", ")})`;

  //     clauses.push(hotelsCheck);
  //   }
  // }

  return `${clauses.join(" AND ")}`;
}

/**
 * Gets a range which is the current time and 1 second later
 * @returns A start and end time
 */
export function makeCurrentTimeRange() {
  const start = new Date();
  const end = moment(start).add(1, 's').toDate();
  return { start, end };
}

/**
 * Drops pins on the available hoteling units upon search via a graphics layer
 * which will get activated
 * @param {Object[]} unitFeatures
 */
export function dropUnitPins(unitFeatures) {
  const layer = HotelPinsLayer.getInstance();
  layer.activate();
  layer.dropUnitPins(unitFeatures);
}

/**
 * Clears the pins on the graphics layer used to show available hotel
 * units and deactivates the layer
 */
export function clearUnitPins() {
  const layer = HotelPinsLayer.getInstance();
  layer.deactivate();
  layer.clearUnitPins();
}

export async function showOccupantInfo(booking: __esri.Graphic) {
  const people = Context.instance.aiim.datasets?.people;
  const reservations = Context.instance.aiim.datasets?.reservations;
  const attributes = booking?.attributes;
  if (people && reservations && attributes) {
    const username = getAttributeValue(attributes,reservations.reservedForUsernameField);
    const fullName = getAttributeValue(attributes,reservations.reservedForFullNameField);
    if (username && fullName) {
      let occupant;
      try {
        occupant = await people.getOccupantByFullName(username,fullName);
      } catch(ex) {
        console.error(ex);
      }
      if (occupant) {
        try {
          const person = { feature: occupant };
          const source = people.getSource();
          const layer = people.layer2D;
          const results = await findDuplicateRecords(layer, person.feature.attributes);
          const features = results?.features;
          Topic.publish(Topic.ShowSearchResult, {
            sourceKey: source?.key ?? "standalone",
            isNonAiimFeature: source == null,
            searchResult: { feature: occupant },
            zoom: features?.length === 1,
            highlight: true,
            trackRecent: true,
            showLocations: { person: { feature: occupant } },
            reservation: booking
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        // @todo popup a message?
        console.warn("showOccupantInfo: unable to match reservation to occupant");
      }
    }
  }
}
