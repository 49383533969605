import React from "react";
import moment from "moment";

import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import { PopupPanel } from "../../styles/Common/panel";
import PeopleAssignTo from "./PeopleAssignTo";
import Topic from "../../../context/Topic";
import UnitPopup from "./UnitPopup";
import * as component from "../../../components/util/component";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as selectionUtil from "../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../base/sourceUtil";

import {
  // ListItem,
  // ListItemTitle,
  // ListItemSubtitle,
  ListItemAttribute
} from "../../styles/Common/list";

export default class PersonPopup extends React.Component {

  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    this._mounted = true;
    console.log("PersonPopup.featureItemx",this.getFeatureItem());
    component.own(this,[
      Topic.subscribe(Topic.PlanModified,params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.refreshFeatureItem();
        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  getFeatureItem() {
    return this.state.featureItem || this.props.featureItem;
  }

  getSource() {
    return officePlanUtil.getPeopleSource();
  }

  isUnassigned() {
    return officePlanUtil.isPersonUnassigned(this.getFeatureItem());
  }

  popupUnit = (evt) => {
    const featureItem = this.getFeatureItem();
    const attributes = featureItem.feature.attributes;
    const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_UNIT_ID);
    if (!unitId) return;

    const source = sourceUtil.getUnitsSource();
    const unitIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNIT_ID);
    const v = selectionUtil.escSqlQuote(unitId);
    const where = "("+unitIdField.name+" = '"+v+"')";
    const options = {
      source: source,
      where: where,
      returnGeometry: false
    }
    queryUtil.queryFeatures(options).then(result => {
      if (result && result.features) {
        const resultInfo = queryUtil.makeResultInfo(source,result);
        if (this._mounted && resultInfo && resultInfo.featureItems &&
            resultInfo.featureItems.length > 0) {
          const unitFeatureItem = resultInfo.featureItems[0];
          const unitFeature = unitFeatureItem.feature;
          const unitName = aiimUtil.getAttributeValue(
            unitFeature.attributes,FieldNames.NAME);
          Topic.publish(Topic.ShowItemPopup, {
            title: unitName,
            source: source,
            featureItem: unitFeatureItem,
            fetchFeatureItemGeometry: true,
            zoom: false,
            content: <UnitPopup sourceKey={source.key} featureItem={unitFeatureItem} />,
          });
        }
      }
    });
  }

  refreshFeatureItem() {
    const source = this.getSource();
    const f1 = this.getFeatureItem();
    queryUtil.refreshFeatureItem(source,f1).then(f2 => {
      if (f2 && this._mounted) {
        this.setState({featureItem: f2});
        let close = false;
        const g1 = f1.geometry || (f1.feature && f1.feature.geometry);
        const g2 = f2.geometry || (f2.feature && f2.feature.geometry);
        if (g1 && g2) {
          if ((g1.x !== g2.x) || (g1.y !== g2.y)) {
            close = true;
          }
        } else if (g1) {
          close = true;
        } else if (g2) {
          // was assigned to an area or unassigned
          const a2 = (f2.feature && f2.feature.attributes);
          const unitId = aiimUtil.getAttributeValue(a2,FieldNames.UNIT_ID);
          //const areaId = aiimUtil.getAttributeValue(a2,FieldNames.PEOPLE_AREA_ID);
          if (unitId) close = true;
        }
        if (close) Topic.publish(Topic.CloseItemPopup,{});
      }
    }).catch(ex => {
      console.error("PersonPopup: Error refreshing feature",ex);
    });
  }

  render() {
    const featureItem = this.getFeatureItem();
    const unassigned = this.isUnassigned();
    const info = this.renderInfo(unassigned);
    const assignTo = <PeopleAssignTo disableUnassign={unassigned} featureItems={[featureItem]}/>

    return (
      <div>
        {info}
        <div key="actions" className="i-margin-tb">
          {assignTo}
        </div>
      </div>
    );
  }

  renderInfo(isUnassigned) {
    const featureItem = this.getFeatureItem();
    const attributes = featureItem.feature.attributes;
    const nodes = [];
    let v;

    const asnName = officePlanUtil.getPersonAssignmentName(featureItem);
    if (typeof asnName === "string" && asnName.length > 0) {
      nodes.push(<div key="asnName">{asnName}</div>);
    }

    v = null;
    //let jobType = aiimUtil.getAttributeValue(attributes,"type");
    let jobType = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_JOB_TITLE);
    if (typeof jobType !== "string") jobType = "";
    const startedOn = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_START_DATE);
    if (typeof startedOn === "number") {
      try {
        const timeAgo = moment(startedOn).fromNow(true);
        const pattern = "{jobType} ({tenure})";
        v = pattern.replace("{jobType}",jobType).replace("{tenure}",timeAgo);
      } catch(ex) {
        console.warn("Error converting start date",startedOn);
        console.error(ex);
      }
    } else {
      v = jobType;
    }
    if (typeof v === "string" && v.length > 0) {
      nodes.push(<ListItemAttribute key="jobType">{v}</ListItemAttribute>);
    }

    // v = aiimUtil.getAttributeValue(attributes,"job_description");
    // if (typeof v === "string" && v.length > 0) {
    //   nodes.push(<ListItemAttribute key="jobDesc">{v}</ListItemAttribute>);
    // }

    v = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_ORG_LEVEL_1);
    if (typeof v === "string" && v.length > 0) {
      nodes.push(<ListItemAttribute key="org_level_1">{v}</ListItemAttribute>);
    }

    v = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_ORG_LEVEL_2);
    if (typeof v === "string" && v.length > 0) {
      nodes.push(<ListItemAttribute key="org_level_2">{v}</ListItemAttribute>);
    }

    v = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_UNIT_ID);
    const hasUnit = (typeof v === "string" && v.length > 0);

    if (hasUnit) {
      return (
        <PopupPanel key="info" style={{cursor: "pointer"}} onClick={this.popupUnit}>
          {nodes}
        </PopupPanel>
      );
    } else {
      return (
        <PopupPanel key="info">
          {nodes}
        </PopupPanel>
      );
    }
  }

}
