import React from "react";
import { connect } from "react-redux";
import { EditorTabs, getActiveTab } from "./redux";
import SidebarHeader from "./SidebarHeader";
import * as component from "../../../components/util/component";

interface ISidebarPanelProps {
  activeSidebarTab: EditorTabs,
  children?: JSX.Element | JSX.Element[],
  icon?: JSX.Element,
  label: JSX.Element | string,
  tab: EditorTabs
}
class SidebarPanel extends React.Component<ISidebarPanelProps> {
  constructor(props: ISidebarPanelProps) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { tab, activeSidebarTab } = this.props;
    const active = tab && (tab === activeSidebarTab);
    if (!active) return null;
    return (
      <div className="i-editor-sidebar-panel">
        <SidebarHeader label={this.props.label} icon={this.props.icon} tab={tab} />
        <div className="i-editor-sidebar-panel-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSidebarTab: getActiveTab(state)
});

export default connect(mapStateToProps)(SidebarPanel);
