import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import * as aiimUtil from "../util/aiimUtil";

export default class Events extends Dataset {

  name = "events";
  titleField = FieldNames.NAME;
  dateEndField = FieldNames.EVENT_DATE_END;
  dateStartField = FieldNames.EVENT_DATE_START;
  imageUrlField = FieldNames.IMAGE_URL;
  objectIdField = "objectid";
  supportsAttachment = false;
  type = "feature";
  typeField = FieldNames.EVENT_TYPE;

  checkSchema() {
    const promise = new Promise((resolve,reject) => {
      if (this.layer2D) {
        const layer = this.layer2D;
        layer.when(() => {
          //console.log("Events.layer2D",this.layer2D);
          this.objectIdField = this.layer2D.objectIdField;
          if (this.layer2D.capabilities && this.layer2D.capabilities.data) {
            this.supportsAttachment = this.layer2D.capabilities.data.supportsAttachment;
          }
          this.checkFieldNameProperty("dateStartField",layer.fields);
          this.checkFieldNameProperty("dateEndField",layer.fields);
          this.checkFieldNameProperty("imageUrlField",layer.fields);
          this.checkFieldNameProperty("typeField",layer.fields);
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  getEventInfo(job,feature, params) {
    const djLocale = Context.getInstance().lib.dojo.locale;
    const attributes = feature.attributes;
    //console.log("Events::getEventInfo feature",feature);
    //console.log("Events::getEventInfo attributes",attributes);
    if (!job.hasOwnProperty("_events_fieldnames")) {
      job._events_fieldnames = {
        name: aiimUtil.findAttributeName(attributes,FieldNames.NAME),
        description: aiimUtil.findAttributeName(attributes,FieldNames.DESCRIPTION),
        date_start: aiimUtil.findAttributeName(attributes,FieldNames.EVENT_DATE_START),
        date_end: aiimUtil.findAttributeName(attributes,FieldNames.EVENT_DATE_END),
        unit_name: aiimUtil.findAttributeName(attributes,FieldNames.UNIT_NAME),
        image_url: aiimUtil.findAttributeName(attributes,FieldNames.IMAGE_URL),
      }
      if (!job._events_fieldnames.unit_name) {
        job._events_fieldnames.unit_name = aiimUtil.findAttributeName(attributes,FieldNames.UNIT_ID)
      }
    }
    let geo = "";
    if (feature.geometry) {
      if (typeof feature.geometry.latitude === "number" &&
          typeof feature.geometry.longitude === "number") {
        geo = feature.geometry.latitude + ";" + feature.geometry.longitude;
      }
    }
    const fieldnames = job._events_fieldnames;
    const eventInfo = {
      objectId: aiimUtil.getAttributeValue(attributes,this.objectIdField),
      name: aiimUtil.getAttributeValue(attributes,fieldnames.name),
      description: aiimUtil.getAttributeValue(attributes,fieldnames.description),
      hyperlink: null,
      location: this.getUnitName(feature),
      image_url: aiimUtil.getAttributeValue(attributes,fieldnames.image_url),
      date_start: aiimUtil.getAttributeValue(attributes,fieldnames.date_start),
      date_end: aiimUtil.getAttributeValue(attributes,fieldnames.date_end),
      shortDate: "",
      longDate: "",
      mmm: "",
      d: "",
      yyyy: "",
      timeZone: "",
      timePeriod: "",
      geo: geo,
      attachments: []
    }
    //console.log("Events-",eventInfo);
    if (typeof eventInfo.date_start === "number") {
      const dt = new Date(eventInfo.date_start);
      if (dt instanceof Date && !isNaN(dt.getTime())) {
        //console.log("Events- date_start",dt);
        const i18n = Context.getInstance().i18n;
        const time = djLocale.format(dt,{selector:"time",formatLength:"short"});
        const timeZone = djLocale.format(dt,{selector:"time",timePattern:"z"});
        let pattern = i18n.events.timePattern
        eventInfo.timePeriod = pattern.replace("{time}",time).replace("{timeZone}",timeZone);
        if (job.forDetails) {
          //eventInfo.shortDate = djLocale.format(dt,{selector:"date",formatLength:"short"});
          eventInfo.longDate = djLocale.format(dt,{selector:"date",formatLength:"long"});
          //console.log("LondDate", eventInfo.longDate);
        } else {
          eventInfo.mmm = djLocale.format(dt,{selector:"date",datePattern:"MMM"});
          eventInfo.mmmm = djLocale.format(dt, {selector:"date",datePattern:"MMMM"});
          eventInfo.d = djLocale.format(dt,{selector:"date",datePattern:"d"});
          //eventInfo.yyyy = djLocale.format(dt,{selector:"date",datePattern:"yyyy"});
        }
        if (typeof eventInfo.date_end === "number") {
          const dt2 = new Date(eventInfo.date_end);
          if (dt2 instanceof Date && !isNaN(dt2.getTime())) {
            pattern = i18n.events.timePeriodPattern;
            const time2 = djLocale.format(dt2,{selector:"time",formatLength:"short"});
            const v = pattern.replace("{fromTime}",time).replace("{toTime}",time2).replace("{timeZone}",timeZone);
            eventInfo.timePeriod = v;
          }
        }
        //console.log("Events::getEventInfo",eventInfo);
      }
    }
    return eventInfo;
  }

  getSource() {
    if(Context.getInstance().aiim.datasets.categories) return Context.getInstance().aiim.datasets.categories.findSourceByKey("Events"); // TODO
  }

  getUnitName(feature) {
    let name, id = aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_ID);
    const unc = Context.instance.session.unitNameCache;
    if (unc && unc.namesPerUnitId && id) {
      name = unc.namesPerUnitId[id];
      if (name) return name;
    }
    name = aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_NAME)
    if (name) return name;
    return id;
  }

  loadAttachments(eventInfo) {

    //eventInfo.image_url = "https://www.esri.com/content/dam/esrisites/landing-pages/artificial-intelligence/assets/machine-learning.jpg";

    const attachments = [];
    if (typeof eventInfo.image_url === "string" &&
       (eventInfo.image_url.indexOf("http://") === 0 ||
        eventInfo.image_url.indexOf("https://") === 0)) {
      const attachment = {href: eventInfo.image_url};
      attachments.push(attachment);
      eventInfo.attachments = [attachment];
    }

    let url = this.url + "/" + eventInfo.objectId + "/attachments";
    const options = {query: {f: "json"}, responseType: "json"};

    if (!this.supportsAttachment) return Promise.resolve();
    //url = "https://indoorsportal2.esri.com/arcgis/rest/services/Hosted/EsriRedlands_miniAIIM_fs_webmap_041_WFL1/FeatureServer/6/151/attachments";

    url = Context.checkMixedContent(url);
    const lib = Context.getInstance().lib;
    const promise = new Promise((resolve,reject) => {
      lib.esri.esriRequest(url,options).then(result => {
        //console.log("loadAttachments.result",result);
        if (result && result.data && result.data.attachmentInfos) {
          result.data.attachmentInfos.forEach(info => {
            if (typeof info.contentType === "string" &&
                info.contentType.substr(0,5) === "image") {
              info.href = aiimUtil.appendTokenToUrl(url + "/" + info.id);
              attachments.push(info);
            }
          });
        }
        eventInfo.attachments = attachments;
        resolve();
      }).catch(ex => {
        console.warn("Error loading attachments:",url);
        console.error(ex);
        resolve();
      });
    });
    return promise
  }

  queryTypes() {
    const url = Context.checkMixedContent(this.url);
    const typeField = this.typeField;
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = [typeField];
    //query.orderByFields = [typeField]; // TODO is this working?
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = "1=1";
    return task.execute(query).then(result => {
      //console.log("Events:queryTypes.result",result);
      const types = [];
      if (result && result.features) {
        result.features.forEach(feature => {
          if (feature && feature.attributes) {
            const type = aiimUtil.getAttributeValue(feature.attributes,typeField);
            if (typeof type === "string" && type.length > 0 && types.indexOf(type) === -1) {
              types.push(type);
            }
          }
        });
      }
      //console.log("Events:queryTypes.types",types);
      return types;
    });
  }

  getGoogleData(event) {
    const { attributes } = event;

    const title = aiimUtil.getAttributeValue(attributes, FieldNames.NAME);
    const description = aiimUtil.getAttributeValue(attributes, FieldNames.DESCRIPTION);
    const startDate = aiimUtil.getAttributeValue(attributes, FieldNames.EVENT_DATE_START);
    const endDate = aiimUtil.getAttributeValue(attributes, FieldNames.EVENT_DATE_END);
    const location =  this.getUnitName(event);

    return { title, description, startDate, endDate, location };
  }

}
