import BaseClass from "../util/BaseClass";
import Dnd from "../spaceplanner/base/Dnd"
import Context from "./Context";
import Planner from "../spaceplanner/base/Planner";
import * as val from "../util/val";
import { IConfiguration } from "./Configuration";

export default class AppMode extends BaseClass {

  appType: IConfiguration["appType"] = "indoors";
  mapDivId: string = "view-container";

  private _appTypeKeyword = "IndoorsApp";
  private _requiresImmediateAuthentication = false;
  private _supports3D = true;
  private _supportsBasemapGallery = true;
  private _supportsCategories = true;
  private _supportsHome = true;
  private _supportsInfoPanel = true;
  private _supportsMyPlaces = true;

  getAppTypeKeyword() {
    return (this._appTypeKeyword);
  }

  init() {
    let urlAppType = val.getUrlParamValue("apptype");
    let appType = urlAppType || Context.instance.config.appType;

    if (appType === "indoors-spaceplanner" || appType === "indoors-floorplaneditor") {
      this.appType = appType;
      if (appType === "indoors-spaceplanner") {
        this._appTypeKeyword = "IndoorsSpaceplannerApp";
      } else {
        this._appTypeKeyword = "IndoorsFloorPlanEditorApp";
      }

      this.mapDivId = "sp-view-container";
      this._requiresImmediateAuthentication = true;
      this._supports3D = false;
      this._supportsBasemapGallery = false;
      this._supportsCategories = false;
      this._supportsHome = false;
      this._supportsInfoPanel = false;
      this._supportsMyPlaces = false;
      Context.instance.config.highlightFacilityOnHover2D = false;
      Context.instance.spaceplanner.planner = new Planner();
      Context.instance.spaceplanner.dnd = new Dnd();
    } else {
      this.appType = "indoors";
      this._appTypeKeyword = "IndoorsApp";
      this._requiresImmediateAuthentication = false;
      this._supports3D = true;
      this._supportsBasemapGallery = true;
      this._supportsCategories = true;
      this._supportsHome = true;
      this._supportsInfoPanel= true;
      this._supportsMyPlaces = true;
    }
  }

  isFPE(): boolean {
    return (this.appType === "indoors-floorplaneditor");
  }

  isSP(): boolean {
    return (this.appType === "indoors-spaceplanner");
  }

  isSP_or_FPE(): boolean {
    return (this.isSP() || this.isFPE());
  }

  isSpacePlanner() {
    // @todo still in user in a couple of ploaces
    return (this.appType === "indoors-spaceplanner");
  }

  isViewer(): boolean {
    return (this.appType === "indoors");
  }

  requiresImmediateAuthentication() {
    return this._requiresImmediateAuthentication;
  }

  setBrowserTitle() {
    const appItem = Context.instance.configuration.appItem;
    const appTitle = appItem && appItem.title;
    if (this.isSP_or_FPE()) {
      const plan = Context.instance.spaceplanner.activePlan;
      const planTitle = plan && plan.title;
      if (planTitle && appTitle) {
        let title = Context.instance.i18n.miniapps.titlePattern;
        title = title.replace("{planTitle}",planTitle).replace("{appTitle}",appTitle);
        document.title = title;
      } else if (appTitle) {
        document.title = appTitle;
      } else {
        if (this.isFPE()) {
          document.title = "Floor Plan Editor";
        } else {
          document.title = "Indoor Space Planner";
        }
      }
    } else {
      document.title = "Indoor Viewer";
    }
  }

  supports3D() {
    return this._supportsHome;
  }

  supportsBasemapGallery() {
    return this._supportsBasemapGallery;
  }

  supportsCategories() {
    return this._supportsCategories;
  }

  supportsHome() {
    return this._supportsHome;
  }

  supportsInfoPanel() {
    return this._supportsInfoPanel;
  }

  supportsMyPlaces() {
    return this._supportsMyPlaces;
  }

  useCalcite(): boolean {
    return this.isFPE();
  }

}
