import React from "react";
import ReactTooltip from "react-tooltip";
import Context from "../../../context/Context";

const CSS = {
  main: "i-helptip"
};

export default class HelpTip extends React.Component {

  constructor(props){
    super(props);
    this.state={
      position: "left"
    }
  }

  componentDidMount(){
    const isRtl = Context.getInstance().uiMode.isRtl;
    if(isRtl){
      this.setState({
        position: "right"
      })
    }else{
      this.setState({
        position: "left"
      })
    }
  }

  render() {
    // data-event="mouseenter click"
    const text = this.props.text;
    const helpUrl = this.props.helpUrl;
    if (typeof text === "string" && text.length > 0) {
      if(helpUrl){
        return (
          <>
            <a className={CSS.main} target="_blank" data-tip={text} rel="noopener noreferrer"
              href={helpUrl}>
              <span className={CSS.main}data-tip={text} data-place={this.state.position}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 .15A7.85 7.85 0 1 0 15.85 8 7.85 7.85 0 0 0 8 .15zM9 14H7v-2h2zm1.158-5.566C9.468 9.09 9 9.488 9 10.337V11H7v-1c0-2.022 2.93-2.428 2.93-4.413a1.501 1.501 0 0 0-1.58-1.624c-.832 0-1.238.323-1.449 1.315A3.624 3.624 0 0 0 6.838 6H5.105l.039-.803c.213-1.568 1.388-2.472 3.223-2.472a2.721 2.721 0 0 1 2.985 2.816 3.203 3.203 0 0 1-1.194 2.893z"/></svg>
              </span>
              <ReactTooltip/>
            </a>
          </>
        );
      }else{
        return (
          <>
            <span className={CSS.main} tabIndex="0" role="button"
              data-tip={text} data-place={this.state.position}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 .15A7.85 7.85 0 1 0 15.85 8 7.85 7.85 0 0 0 8 .15zM9 14H7v-2h2zm1.158-5.566C9.468 9.09 9 9.488 9 10.337V11H7v-1c0-2.022 2.93-2.428 2.93-4.413a1.501 1.501 0 0 0-1.58-1.624c-.832 0-1.238.323-1.449 1.315A3.624 3.624 0 0 0 6.838 6H5.105l.039-.803c.213-1.568 1.388-2.472 3.223-2.472a2.721 2.721 0 0 1 2.985 2.816 3.203 3.203 0 0 1-1.194 2.893z"/></svg>
            </span>
            <ReactTooltip multiline={true}/>
          </>
        );
      }
    }
    return null;
  }

}
