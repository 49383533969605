import React from "react";

import AppSection from "./AppSection";
import Context from "../../../context/Context";
import Footer from "./Footer";
import Header from "./Header";
import {ModalController} from "../../../common/components/Modal";
import Step from "./Step";
import StepNavigation from "./StepNavigation";
import Topic from "../../../context/Topic";
import WebMapSection from "./WebMapSection";
import MergePermission from "./MergePermission";
import FilterColumns from "./FilterColumns";
import UnitName from "./UnitName";
import IdleTime from "../../../components/main/Configurator/IdleTime";
import * as component from "../../../components/util/component";
import WorkspaceAreas from "./WorkspaceAreas";

let wasStartupShown = false;

export default class Configurator extends React.Component {

  cfg;

  constructor(props) {
    super(props);
    this.state = component.newState({});

    const context = Context.instance;
    const configuration = context.configuration
    const initialConfigurables = configuration.extractConfigurables();
    const views = context.views;
    const webmapItem = views && views.mapView &&
      views.mapView.map && views.mapView.map.portalItem;

    this.cfg = {
      activeStepKey: "step-1",
      initialConfigurables: initialConfigurables,
      appItem: configuration.appItem,
      webmapItem: webmapItem,
      getConfigurable: (name) => {
        return context.config[name]
      },
      setConfigurable: (name,value) => {
        context.config[name] = value;
      }
    };
  }

  componentDidMount() {
    if (!wasStartupShown) {
      wasStartupShown = true;
      if (!this.cfg.appItem && !this.cfg.webmapItem) {
        this.showGettingStarted();
      }
    }
    component.own(this,[
      Topic.subscribe(Topic.Configurator_RenderSteps,() => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const cfg = this.cfg;
    const numSteps = 3; // change if the number of steps is modified below
    return (
      <div className="i-configurator">
        <div key="top">
          <Header cfg={cfg} />
          <StepNavigation cfg={cfg} numSteps={numSteps}/>
        </div>
        <div key="steps" className="i-configurator-steps">
          <Step stepKey="step-1" cfg={cfg}>
            <AppSection cfg={cfg} />
            <WebMapSection cfg={cfg} />
            <MergePermission cfg={cfg} />
            <IdleTime cfg={cfg} />
          </Step>
          <Step stepKey="step-2" cfg={cfg}>
            <FilterColumns cfg={cfg} />
          </Step>
          <Step stepKey="step-3" cfg={cfg}>
            <UnitName cfg={cfg} />
            <WorkspaceAreas cfg={cfg} />
          </Step>         
        </div>
        <div key="bottom">
          <Footer cfg={cfg} />
        </div>
      </div>
    );
  }

  showGettingStarted() {
    const i18n = Context.instance.i18n;
    const props = {
      title: i18n.spaceplanner.application.title,
      message: i18n.spaceplanner.configurator.startupPrompt
    }
    ModalController.confirm(props).then(result => {
      if (result.ok) {
        Topic.publish(Topic.Configurator_BrowseForMap,{});
      }
    });
  }

}
