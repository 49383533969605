// Framework and third-party non-ui
import React, { Component } from "react";
import {connect} from "react-redux";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import Rdx from "../../../redux/Rdx";
import { setActiveSection, getActiveSection } from "../../redux";
import * as component from "../../../components/util/component";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import OfficePlan from "../../base/OfficePlan";

// Component specific modules (Component-styled, etc.)
import {
  LeftContent, RightContent, ButtonWrapper, StyledNav, StyledList, StyledLink
} from "../../styles/bannerStyles";

// App components
import BurgerButton from "../Work/BurgerButton";
import Info from "../Info/Info";
import SharingLevel from "../common/SharingLevel";

// Third-party components (buttons, icons, etc.)
import HamburgerIcon from "calcite-ui-icons-react/HamburgerIcon";
import ShareIcon from "calcite-ui-icons-react/ShareIcon";
import Button from "calcite-react/Button";
import Skeleton from "react-loading-skeleton";

const sections = {
  PLAN: 'plan',
  REVIEW: 'review',
  PUBLISH: 'publish'
}

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      wasAppStarted: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.AppStarted, params => {
        this.setState({ wasAppStarted: true });
      }),
      Topic.subscribe(Topic.ViewsReloaded, params => {
        this.setState({ wasAppStarted: true });
      }),
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  isSectionSelected = section => {
    const { rdxActiveAppSection } = this.props;
    return (section === rdxActiveAppSection);
  };

  sectionClicked = v => {
    this.props.setActiveSection(v);
    Topic.publish(Topic.ActiveTabChanged, { tab: v })
  };

  render() {
    const i18n = Context.instance.i18n;
    const navKey = "i-spaceplanner-workbar-nav";
    const panelOpenClassName = "i--workbar-panel-open";
    const portal = Context.getInstance().getPortal();

    if(Context.instance.views && Context.instance.views.mapView) {
      if(OfficePlan.getActive() && OfficePlan.getActive().commentsLayer) {
        const commentsLayer = OfficePlan.getActive().commentsLayer;
        let title;
        if(commentsLayer) title = commentsLayer.title;
        const layers = aiimUtil.getLayers(Context.instance.views.mapView);
        if(layers && layers.items && layers.items.length > 0) {
          const items = layers.items;
          items.forEach(item => {
            if(item.title === title) {
              if(this.props.rdxActiveAppSection === "plan" || this.props.rdxActiveAppSection === "publish") item.visible = false;
              else item.visible = true;
            }
          });
        }
      }
    }

    return (
      <StyledNav contentWidth="100%" contentMaxWidth="100%" style={{ padding: "0" }}>
        <LeftContent>
          <ButtonWrapper key="burger">
            <BurgerButton
              key="burger"
              tabKey="burger"
              navKey={navKey}
              panelOpenClassName={panelOpenClassName}
              title={i18n.spaceplanner.menu.burger.label}
              disabled={!this.state.wasAppStarted}
              >
                <HamburgerIcon size="24"/>
              </BurgerButton>
            </ButtonWrapper>
          <StyledList>
            <StyledLink
              active={this.isSectionSelected(sections.PLAN)}
              onClick={() => this.sectionClicked(sections.PLAN)}
            >{i18n.spaceplanner.header.plan}</StyledLink>
            <StyledLink
              active={this.isSectionSelected(sections.REVIEW)}
              onClick={() => this.sectionClicked(sections.REVIEW)}
            >{i18n.spaceplanner.header.review}</StyledLink>
            {/*
            <StyledLink
              active={this.isSectionSelected(sections.PUBLISH)}
              onClick={() => this.sectionClicked(sections.PUBLISH)}
            >{i18n.spaceplanner.header.publish}</StyledLink>
            */}
          </StyledList>
        </LeftContent>
        <Info />
        <RightContent>
          <Button icon={<ShareIcon size={16} />} iconPosition="before">{i18n.infoPanel.actions.share.caption}</Button>
        </RightContent>
        {portal && OfficePlan.getActive() ? <SharingLevel /> : <Skeleton width={16} style={{marginRight: '1rem'}} />}
      </StyledNav>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  rdxActiveAppSection: getActiveSection(state),
  rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
})
const mapDispatchToProps = (dispatch) => ({
  setActiveSection: (section) => dispatch(setActiveSection(section))
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);
