import React, {createRef} from "react";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import * as tsUtil from "../../../../util/tsUtil";
import { getFieldTooltip } from "../support/formUtil";

export default class Attributes extends React.Component {

  _coreNodeRef = createRef();
  _coreWidget;

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount () {
    this._initWidget();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _initWidget() {
    const context = Context.instance;
    const node = this._coreNodeRef && this._coreNodeRef.current;
    if (node) {
      this._coreWidget = new context.lib.indoors.CustomFeatureForm({
        indoorsContext: context,
        tsUtil: tsUtil,
        container: node,
        feature: this.props.feature,
        indoorsOptions: {
          ...this.props.options,
          getTooltip: getFieldTooltip
        }
      });
      if (this.props.onCreateForm) {
        this.props.onCreateForm(this,this._coreWidget);
      }
    }
  }

  render() {
    return (
      <div>
        <div ref={this._coreNodeRef}></div>
      </div>
    );
  }

}
