import React from 'react';
import Context from "../../../context/Context";
import ColorPicker from "../../common/ColorPicker/ColorPicker";
import ModalController from '../../common/Modal/ModalController';
import Topic from "../../../context/Topic";
//import Pass from '../ColorContrast/Pass';
//import Fail from "../ColorContrast/Fail";
import * as component from "../../util/component";

/*
   library "get-contrast" was removed from package.json

   colorContrastTooltip: "Checks the color contrast between the text and its background for web accessibility.",

 */

export default class ThemeTextColor extends React.Component {

  //contrast = require('get-contrast');

  constructor(props){
    super(props);
    this.state=component.newState({})
  }

  static showModal=()=> {
    let controller = new ModalController();
    controller.show(
      <ThemeTextColor/>
    );
  }

  componentDidMount() {
    // this.getColorContrast();
    // component.own(this,[
    //   Topic.subscribe(Topic.ApplyTheme,() => {
    //     this.getColorContrast();
    //   })
    // ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

   showColorPicker=()=> {
     let color = this.getForeground();
     let controller = new ModalController();
     controller.show(
       <ColorPicker color= {color}
                    handleChangeComplete= {this.handleChangeComplete}
       />
     ,Context.instance.i18n.configurator.theme.colorPickerCaption
     ,"i-configurator-modal i-colorpicker-modal");
   }

   getBackground=()=>{
    return (this.props.cfg.theme[this.props.themeBackgroundProperty]);
   }

   getForeground =()=>{
    return (this.props.cfg.theme[this.props.themeProperty]);
   }

   getBackgroundVar =()=>{
    return ("var(--i-"+this.props.themeBackgroundProperty+")");
   }

   getForegroundVar =()=>{
    return ("var(--i-"+this.props.themeProperty+")");
   }

   // getColorContrast=()=>{
   //  let backgroundColor= this.getBackground();
   //  let foregroundColor= this.getForeground();
   //  let contrastRatio=this.contrast.ratio(backgroundColor,foregroundColor);
   //  if(contrastRatio> 4.5){
   //    this.setState({
   //      isContrastValid: true
   //    })
   //  }else{
   //    this.setState({
   //      isContrastValid: false
   //    })
   //  }
   // }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
    this.props.cfg.theme[this.props.themeProperty]=color.hex;
    Topic.publish(Topic.ApplyTheme,{
      theme:{
        [this.props.themeProperty]:color.hex
      }
    })
    //this.getColorContrast();
  };

  render(){
      var colorVariable={
              background:this.getForegroundVar(),
              border:"#959595 1px solid"
      }
      var backgroundColorVariable={
              color:this.getForegroundVar(),
              background:this.getBackgroundVar()
      }

      // let colorContrastHdr = (
      //   <section key="colorContrast">
      //     <span className="i-form-group-name">{this.props.heading}
      //       <span>{this.props.colorContrast}</span>
      //     </span>
      //   </section>
      // );

      let nameAndExample = (
        <div key="name" className="i--a">
          <span className="i-form-group-name">{this.props.title}</span>
          <p>{this.props.example}</p>
        </div>
      );

      let colorButton = (
        <div key="button" className="i--b">
          <button className="i-color" style={colorVariable}
                  onClick={this.showColorPicker}>
          </button>
          <span className="i--sample" style={backgroundColorVariable}
            >{this.props.sampleText}</span>
        </div>
      );

      // let contrast= (
      //   <div key="contrast" className="i--c">
      //     {this.state.isContrastValid ===true ? <Pass /> : <Fail/> }
      //   </div>
      // )

      return(
        <div>
          <section key="body" className="i-theme-row">
            {nameAndExample}
            {colorButton}
          </section>
        </div>
      );
    }
  }
