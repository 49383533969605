import React from "react";

import Icons from "../../util/Icons";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  configuratorMinView: "i-configurator-min-view"
};

export default class ConfiguratorMinView extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onClick = (evt) => {
    Topic.publish(Topic.Configurator_SetExpanded,{});
    const btn = document.querySelector("button[data-i-configurator-minimize-button='true']");
    if (btn && btn.focus) btn.focus();
  }

  render() {
    return (
      <button className={CSS.configuratorMinView}
        onClick={this.onClick}
        data-i-configurator-minview-button="true">
        {Icons.edit()}
      </button>
    );
  }

}
