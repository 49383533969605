import Context from "../../context/Context";
import OfficePlan from "./OfficePlan";
import * as portalUtil from "../../util/portalUtil";
import { SharingLevels } from "../../common/components/Sharing";
import { IGroup } from "@esri/arcgis-rest-types";

export const hasSharedGroupIds = (task) => {
  const idx = {};
  if (task.assignedGroups && task.assignedGroups.length > 0) {
    task.assignedGroups.forEach(group => idx[group.id] = true);
  }
  if (task.shareIds && task.shareIds.length > 0) {
    task.shareIds.forEach(id => idx[id] = true);
  }
  if (task.unshareIds && task.unshareIds.length > 0) {
    task.unshareIds.forEach(id => idx[id] = false);
  }
  const hasIds = Object.keys(idx).some(id => idx[id]);
  console.log("hasSharedGroupIds",hasIds)
  return !!hasIds;
}

/**
 * Share/unshare plan with groups for online organizations
 * @param {string[]} share list of groups ids plan is shared with
 * @param {string[]} unshare list of groups id plan is unshared with
 * @param {string} access access level for the feature service
 */
export const updateSharing = async (share, unshare, access, id, portalItem) => {
  try {
    const esriRequest = Context.instance.lib.esri.esriRequest;
    const portal = Context.getInstance().getPortal();
    const plan = OfficePlan.getActive();
    const user = portal.user;
    const unshareUrl = `${portal.restUrl}/content/users/${user.username}/unshareItems`;
    const shareUrl = `${portal.restUrl}/content/users/${user.username}/shareItems`;
    const itemId = id;
    const shareOptions: __esri.RequestOptions = {
      query: {
        f: 'json',
        everyone: false,
        items: itemId,
        groups: ''
      },
      method: 'post',
    }

    if (unshare.length > 0) {
      shareOptions.query.groups = unshare.join(',');
      await esriRequest(unshareUrl, shareOptions)
    }

    if (share.length > 0) {
      shareOptions.query.confirmItemControl = true;
      shareOptions.query.groups = share.join(',');
      shareOptions.query.org = false;
      await esriRequest(shareUrl, shareOptions);
    } else if (access) {
      delete shareOptions.query.confirmItemControl;
      delete shareOptions.query.groups;
      shareOptions.query.org = access === SharingLevels.ORGANIZATION;
      await esriRequest(shareUrl, shareOptions);
    }

    if (portalItem) {
      const result = await portalUtil.readItem(portalItem.id);
      if (result && result.data && result.data.access) {
        portalItem.access = result.data.access;
        if (plan && plan.planServiceItem && 
            plan.planServiceItem.id === portalItem.id) {
          plan.planServiceItem.access = result.data.access;
        }
      }
    }

  } catch (error) {
    console.error(error);
  }
}

/**
 * Change version access permission and share/unshare support service with groups
 * @param {string[]} share list of groups ids plan is shared with
 * @param {string[]} unshare list of groups id plan is unshared with
 * @param {string} access access level for the feature service
 */
export const updateSharingVersioned = async (share, unshare, access, id, portalItem, versionInfo) => {
  try {
    const esriRequest = Context.instance.lib.esri.esriRequest;
    const portal = Context.getInstance().getPortal();
    const user = portal.user;
    const plan = OfficePlan.getActive();
    
    // Change version access permission
    if (plan && plan.project && plan.project.versionedInfo) {
      const versionGuid = versionInfo.versionGuid.replace(/[{}]/g, '');
      const versionUrl = `${plan.project.versionedInfo.versionManager.url}/versions/${versionGuid}/alter`; 
      let versionAccess = access;
      //if (share.length > 0 && versionAccess === 'private') versionAccess = 'org';
      if (share.length > 0) versionAccess = SharingLevels.ORGANIZATION;
      const permission = versionAccess === SharingLevels.ORGANIZATION
        ? SharingLevels.PUBLIC
        : SharingLevels.PRIVATE;
      //console.log("permission",permission)
      if (permission) {
        const versionParams = {query: {f: 'json', accessPermission: permission }};
        await esriRequest(versionUrl, versionParams);
        if (versionInfo.versionGuid === plan.versionInfo.versionGuid) {
          plan.versionInfo.access = permission;
        }
      }
    } 
    
    if (portalItem) {
      // share feature service with groups
      const unshareUrl = `${portal.restUrl}/content/users/${user.username}/unshareItems`;
      const shareUrl = `${portal.restUrl}/content/users/${user.username}/shareItems`; 
      const itemId = id;
      const shareOptions: __esri.RequestOptions = {
        query: {
          f: 'json',
          everyone: false,
          items: itemId,
          groups: ''
        },
        method: 'post',
      }
      
      if (unshare.length > 0) {
        shareOptions.query.groups = unshare.join(',');
        await esriRequest(unshareUrl, shareOptions)
      }
      if (share.length > 0) {
        shareOptions.query.confirmItemControl = true;
        shareOptions.query.groups = share.join(',');
        shareOptions.query.org = false;
        await esriRequest(shareUrl, shareOptions);
      } else if (access) {
        delete shareOptions.query.confirmItemControl;
        delete shareOptions.query.groups;
        shareOptions.query.org = access === SharingLevels.ORGANIZATION;
        await esriRequest(shareUrl, shareOptions);
      }
    }

    if (portalItem) {
      const result = await portalUtil.readItem(portalItem.id);
      if (result && result.data && result.data.access) {
        portalItem.access = result.data.access;
        if (plan && plan.supportInfo && plan.supportInfo.serviceItem && 
            plan.supportInfo.serviceItem.id === portalItem.id) {
          plan.supportInfo.serviceItem.access = result.data.access;
        }
      }
    }
    
  } catch (error) {
    console.error(error);
  } 
}

/**
 * Get available groups for active user and get groups that item is shared with if any.
 * @param {string} id portal item id.
 * @returns {Array[]} two lists one with available groups another with shared groups.
 */
export const getGroups = async (id?: string): Promise<[IGroup[], IGroup[]]> => {
  try {
    const portal = Context.getInstance().getPortal();
    const plan = OfficePlan.getActive();
    const esriRequest = Context.instance.lib.esri.esriRequest;
    
    const selfUrl = `${portal.restUrl}/community/self`;
    const options = {query: {f: 'json'}, responseType: 'json'};
    
    const adminGroups = [];
    const allChips = [];
    const groupsRes = await esriRequest(selfUrl, options);
    groupsRes.data.groups.forEach(group => {
      if (!group.isViewOnly || group.userMembership.memberType === 'owner' || group.userMembership.memberType === 'admin') {
        adminGroups.push(group);
      }
    });
    if (!id) {
      if (plan && plan.isVersioned) {
        id = plan.supportInfo && plan.supportInfo.serviceItem && plan.supportInfo.serviceItem.id;
      } else {
        id = plan && plan.planServiceItem && plan.planServiceItem.id;
      }
    }
    
    if (id) {
      const itemGroupsUrl = `${portal.restUrl}/content/items/${id}/groups`;
      const res = await esriRequest(itemGroupsUrl, options);
      allChips.push(...res.data.admin);
      res.data.member.forEach(group => {
        if (!group.isViewOnly) allChips.push(group);
      })
    }
    return [adminGroups, allChips];
  } catch (error) {
    console.log(error);
  }
}