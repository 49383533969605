import styled from "styled-components";
import { ModalActions } from 'calcite-react/Modal';
import { StyledModalContent } from 'calcite-react/Modal/Modal-styled';

const StyledModalActions = styled(ModalActions)``;

const StyledModalWrapper = styled.div`
  ${StyledModalContent} {
    padding: 0;
  }
`;

export { StyledModalActions, StyledModalWrapper };
