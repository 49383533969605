import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";

interface Props {
}

interface State {
}

export default class Map extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    // Tell React to never update this component, that's up to us
    return false;
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onDragOver = evt => {
    const dnd = Context.instance.spaceplanner.dnd;
    dnd.whenDragOverMapContainer(evt);
  };

  onDrop = evt => {
    const dnd = Context.instance.spaceplanner.dnd;
    dnd.whenDropOntoMapContainer(evt);
  };

  render() {
    return (
      <div style={{width: "100%", height: "100%", position: "relative", overflow: "hidden"}}
        id="sp-view-container"
        className="sp-view-container"
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      />
    );
  }
}
