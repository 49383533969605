import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Categories from "./Categories";
import CIMCategories from "./CIMCategories";
import Details from "./Details";
import Events from "./Events";
import Facilities from "./Facilities";
import IndoorsConfig from "./IndoorsConfig";
//import Landmarks from "./Landmarks";
import Levels from "./Levels";
//import Pathways from "./Pathways";
import People from "./People";
import Reservations from "./Reservations";
//import Sections from "./Sections";
import Sites from "./Sites";
//import Transitions from "./Transitions";
import Units from "./Units";
//import Zones from "./Zones";
import * as aiimUtil from "../util/aiimUtil";

// Tables
import Areas from "./Areas";
import FieldNames from "./FieldNames";
import moment from "moment";
import { makeCurrentTimeRange, makeDefExpClause } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import AreaRoles from "./AreaRoles";
export interface IFloorAwareInfo extends __esri.MapFloorInfoProperties {
  facilityInfo: __esri.FacilityLayerInfoProperties,
  levelInfo: __esri.LevelLayerInfoProperties,
  siteInfo: __esri.SiteLayerInfoProperties,
}
export default class Datasets extends BaseClass {

  categories: Categories;
  cimCategories: CIMCategories;
  indoorsConfig: IndoorsConfig;
  details: Details;
  events: Events;
  facilities: Facilities;
  landmarks;
  levels: Levels;
  pathways;
  people: People;
  reservations: Reservations;
  sections;
  sites: Sites;
  transitions;
  units: Units;
  zones;

  areas: Areas;
  areaRoles: AreaRoles;

  _categoriesUrl: string;
  _datasets = [];
  _indoorsConfigUrl;


  _checkSchemas() {
    const promises = [];
    this._datasets.forEach(dataset => {
      const p = dataset.checkSchema();
      if (p) promises.push(p);
    });
    if (promises.length > 0) {
      const lib = Context.getInstance().lib;
      // TODO use Promise.all?
      return lib.esri.promiseUtils.eachAlways(promises).then((results) => {
        //console.log("datasets =.=.=.=.=.=",this); // TODO temporary
      });
    } else {
      return Promise.resolve();
    }
  }

  isPeopleLayer(layer,fields) {
    if (this.people) return this.people.isPeopleLayer(layer,fields);
    return false;
  }

  _hasMinimumReservationSchema() {
    if (!this.units && !this.reservations) {
      return { office365: false, esriSchema: false };
    }

    const checkField = (fields, fieldName) => {
      return !!aiimUtil.findField(fields, fieldName);
    }

    const checkUnitFields = (fieldName) => {
      const layer = this.units && this.units.layer2D;
      const fields = layer && layer.fields;
      if (!fields) {
        return false;
      }
      return checkField(fields, fieldName);
    }

    const checkReservationsFields = () => {
      const layer = this.reservations && this.reservations.layer2D;
      const fields = layer && layer.fields;
      if (!fields) {
        return false;
      }
      let floorField = FieldNames.LEVEL_ID;
      if (layer.floorInfo && layer.floorInfo.floorField) {
        floorField = layer.floorInfo.floorField;
      }

      return (
        // checkField(fields, FieldNames.RESERVED_BY_USERNAME) &&
        checkField(fields, FieldNames.RESERVED_FOR_USERNAME) &&
        checkField(fields, FieldNames.RESERVED_FOR_FULL_NAME) &&
        checkField(fields, FieldNames.START_TIME) &&
        checkField(fields, FieldNames.END_TIME) &&
        checkField(fields, FieldNames.CHECK_IN_TIME) &&
        checkField(fields, FieldNames.CHECK_OUT_TIME) &&
        checkField(fields, FieldNames.TITLE) &&
        checkField(fields, FieldNames.DESCRIPTION) &&
        checkField(fields, FieldNames.STATE) &&
        checkField(fields, FieldNames.UNIT_ID) &&
        checkField(fields, FieldNames.UNIT_NAME) &&
        checkField(fields, FieldNames.ALL_DAY) &&
        checkField(fields, floorField)
      );
    }

    const hasReservationFields = checkReservationsFields();
    const office365 = !hasReservationFields && checkUnitFields(FieldNames.SCHEDULE_EMAIL);
    const esriSchema =
      hasReservationFields &&
      this.reservations.canUserMakeReservations();
    return { office365, esriSchema };
  }

  /**
   * If no AREAS table in map, find the AREAS table via the PEOPLE Feature Service
   */
  _fetchAreasTable()  {
    return new Promise<void>((resolve) => {
      const fsUrl = this.people.layer2D && this.people.layer2D.url;
      if (!fsUrl) {
        resolve();
      }
      const url = Context.checkMixedContent(`${fsUrl}/layers`);
      const lib = Context.getInstance().lib;
      const options = {
        query: {
          f: "json"
        },
        method: "get",
        responseType: "json"
      };
      lib.esri.esriRequest(url, options)
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.tables &&
            result.data.tables.length > 0
          ) {
            const tables = result.data.tables;
            tables.forEach((table) => {
              const uc = table.name && table.name.toUpperCase();
              if (uc && uc === "AREAS" && !this.areas) {
                this.areas = new Areas();
                this.areas.url = `${fsUrl}/${table.id}`;
                const params = { url: this.areas.url };
                this.areas.table = new Context.instance.lib.esri.FeatureLayer(params);
                this.areas.table.load();
                this._datasets.push(this.areas);
              }
            });
          }
          resolve();
        });
    })
  }

  /*
    * If no AREA_ROLES table in map, find the AREA_ROLES table via the PEOPLE Feature Service
    */
  _fetchAreaRolesTable() {
    return new Promise<void>((resolve) => {
      const fsUrl = this.people.layer2D && this.people.layer2D.url;
      if (!fsUrl) {
        resolve();
      }
      const url = Context.checkMixedContent(`${fsUrl}/layers`);
      const lib = Context.getInstance().lib;
      const options = {
        query: {
          f: "json"
        },
        method: "get",
        responseType: "json"
      };
      lib.esri.esriRequest(url, options)
        .then((result) => {
          if (result && result.data && result.data.tables && result.data.tables.length > 0 ) {
            const tables = result.data.tables;
            tables.forEach((table) => {
              const uc = table.name && table.name.toUpperCase();
              if (uc && (uc === "AREA_ROLES" || uc === "AREAROLES") && !this.areaRoles) {
                this.areaRoles = new AreaRoles();
                this.areaRoles.url = `${fsUrl}/${table.id}`;
                const params = { url: this.areaRoles.url };
                this.areaRoles.table = new Context.instance.lib.esri.FeatureLayer(params);
                this.areaRoles.table.load();
                this._datasets.push(this.areaRoles);
              }
            });
          }
          resolve();
        });
    })
  }

  _findHotelingUnits() {
    return new Promise<void>((resolve) => {
      this.units.queryAllHotels()
        .then((result) => {
          const hotels = result.features || [];
          if (hotels.length < 1) {
            console.warn("There are no available hoteling units in the UNITS layer");
          }
          resolve(result);
        })
        .catch((e) => {
          console.error("Couldn't query for hotels", e);
          resolve();
        });
    });
  }

  load() {
    const mapView = Context.getInstance().views.mapView;
    const sceneView = Context.getInstance().views.sceneView;
    const promise = this._waitForMapService(mapView).then(() => {
      //return aiimUtil.waitForLayers(mapView);
    }).then(() => {
      //return aiimUtil.waitForLayers(sceneView);
    }).then(() => {
      //console.log("mapView***************",mapView)
      this._processMapView(mapView);
      this._processSceneView(sceneView);
    }).then(() => {
      if (this.people && !this.areas) {
        return this._fetchAreasTable();
      }
    }).then(() => {
      if (this.people && !this.areaRoles) {
        return this._fetchAreaRolesTable();
      }
    }).then(() => {
      return this._checkSchemas();
    }).then(() => {
      const configuration = Context.getInstance().configuration
      const configurables = configuration.extractConfigurables()
      const hotelingEnabled =
        configurables.workspaceReservation &&
        configurables.workspaceReservation.enableHotel &&
        !Context.instance.uiMode.isKiosk &&
        !Context.instance.user.isAnonymous();
      if (this.units && hotelingEnabled) {
        return this._findHotelingUnits();
      }
    }).then(() => {
      if (this.levels) return this.levels.load();
    }).then(() => {
      this.cimCategories = new CIMCategories();
      return this.cimCategories.load();
    }).then(() => {
      return this._loadCategories();
    }).then(() => {
      return this._loadIndoorsConfig();
    });
    return promise;
  }

  _loadIndoorsConfig() {
    this.indoorsConfig = new IndoorsConfig();
    return this.indoorsConfig.load(this._indoorsConfigUrl);
  }

  _loadCategories() {
    this.categories = new Categories();
    return this.categories.load(this._categoriesUrl,this.cimCategories);
  }

  getReservationsLayer(view) {
    if (Context.instance.appMode.isSP_or_FPE()) {
    } else {
      const configuration = Context.getInstance().configuration;
      const configurables = configuration.extractConfigurables();
      const config = configurables;
      let lid;    
      if(config.workspaceReservation 
        && ((config.workspaceReservation.enableHotel && config.workspaceReservation.reservationTypeHotel === "esri")
        || (config.workspaceReservation.enableMeeting && config.workspaceReservation.reservationTypeMeeting === "esri"))) {
        lid = config.workspaceReservation.reservationLayerId;
      }
      return lid && view.map.findLayerById(lid);
    }    
  }

  _processMapView(view: __esri.View) {
    if (!view) return;
    const reservationLyr = this.getReservationsLayer(view);
    if (reservationLyr && !this.reservations) {
      this.reservations = new Reservations(reservationLyr.refreshInterval);
      this._datasets.push(this.reservations);
      // Properties for time-awareness, and disabling identify on the layer
      const { start, end } = makeCurrentTimeRange();
      reservationLyr.definitionExpression = makeDefExpClause(start, end);
      reservationLyr.popupEnabled = false;
      reservationLyr.xtnHitTestDisabled = true;
      reservationLyr.useViewTime = false;
      this._setDatasetLayer(this.reservations, view, reservationLyr);
    }

    let map = view.map as __esri.WebMap;
    let fi = (map && map.floorInfo);
    let siteLayer, facilityLayer, levelLayer;
    let siteInfo = fi && fi.siteLayer;

    // map-image layer; sitelayer
    if(siteInfo && typeof siteInfo.sublayerId === "number") {
      const parentLayer = map.findLayerById(siteInfo.layerId) as __esri.MapImageLayer;
      const sublayerId = siteInfo.sublayerId;
      if(parentLayer) {
        const subLayer = parentLayer.findSublayerById(sublayerId);
        // @ts-ignore
        if (subLayer && subLayer.xtnFeatureLayer) {
          // @ts-ignore
          siteLayer = subLayer.xtnFeatureLayer;
        }
      }
    } else {
      // feature layer; sitelayer
      siteLayer = siteInfo && map.findLayerById(siteInfo.layerId);
    }

    // map-image layer;facilityLayer
    let facilityInfo = fi && fi.facilityLayer;
    if(facilityInfo && typeof facilityInfo.sublayerId === "number") {
      const parentLayer = map.findLayerById(facilityInfo.layerId) as __esri.MapImageLayer;
      const sublayerId = facilityInfo.sublayerId;
      if(parentLayer) {
        const subLayer = parentLayer.findSublayerById(sublayerId);
        // @ts-ignore
        if (subLayer && subLayer.xtnFeatureLayer) {
          // @ts-ignore
          facilityLayer = subLayer.xtnFeatureLayer;
        }
      }
    } else {
      // feature layer;facilityLayer
      facilityLayer = facilityInfo && map.findLayerById(facilityInfo.layerId);
    }

    // map-image layer;levelsLayer
    let levelInfo = fi && fi.levelLayer;
    if(levelInfo && typeof levelInfo.sublayerId === "number") {
      const parentLayer = map.findLayerById(levelInfo.layerId) as __esri.MapImageLayer;
      const sublayerId = levelInfo.sublayerId;
      if(parentLayer) {
        const subLayer = parentLayer.findSublayerById(sublayerId);
        // @ts-ignore
        if (subLayer && subLayer.xtnFeatureLayer) {
          // @ts-ignore
          levelLayer = subLayer.xtnFeatureLayer;
        }
      }
    } else {
      // feature layer;levelsLayer
      levelLayer = levelInfo && map.findLayerById(levelInfo.layerId);
    }

    let floorAwareInfo: IFloorAwareInfo = {
      siteInfo: siteInfo,
      siteLayer: siteLayer,
      facilityInfo: facilityInfo,
      facilityLayer: facilityLayer,
      levelInfo: levelInfo,
      levelLayer: levelLayer
    }
    if (fi) console.log("floorAwareInfo",floorAwareInfo)

    if (siteLayer) {
      this.sites = new Sites({
        floorAwareInfo: floorAwareInfo
      });
      this.sites.floorAwareInfo = floorAwareInfo;
      this._datasets.push(this.sites);
      this._setDatasetLayer(this.sites,view,siteLayer);
    }
    if (facilityLayer) {
      this.facilities = new Facilities({
        floorAwareInfo: floorAwareInfo
      });
      this.facilities.floorAwareInfo = floorAwareInfo;
      this._datasets.push(this.facilities);
      this._setDatasetLayer(this.facilities,view,facilityLayer);
    }
    if (levelLayer) {
      this.levels = new Levels({
        floorAwareInfo: floorAwareInfo
      });
      this.levels.floorAwareInfo = floorAwareInfo;
      this._datasets.push(this.levels);
      this._setDatasetLayer(this.levels,view,levelLayer);
    }

    const layers = aiimUtil.getLayers(view);
    layers.forEach(layer => {

      if (!siteLayer && layer.title === "Sites") {
        if (!this.sites) {
          this.sites = new Sites();
          this._datasets.push(this.sites);
          this._setDatasetLayer(this.sites,view,layer);
        }
      } else if (!facilityLayer && layer.title === "Facilities") {
        if (!this.facilities) {
          this.facilities = new Facilities();
          this._datasets.push(this.facilities);
          this._setDatasetLayer(this.facilities,view,layer);
        }
      } else if (!levelLayer && layer.title === "Levels") {
        if (!this.levels) {
          this.levels = new Levels();
          this._datasets.push(this.levels);
          this._setDatasetLayer(this.levels,view,layer);
        }
      } else if (layer.title === "Units") {
        if (!this.units) {
          this.units = new Units();
          this._datasets.push(this.units);
          if (layer.declaredClass === "esri.layers.FeatureLayer") {
            layer.outFields = ["*"];
          }
          this._setDatasetLayer(this.units,view,layer);
        }
      } else if (layer.title === "People" || layer.title === "Occupants") {
        if (!this.people) {
          this.people = new People();
          this._datasets.push(this.people);
          this._setDatasetLayer(this.people,view,layer);
        }
      } else if (layer.title === "Events") {
        if (!this.events) {
          this.events = new Events();
          this._datasets.push(this.events);
          this._setDatasetLayer(this.events,view,layer);
        }
      } else if (layer.title === "Details") {
        if (!this.details) this.details = new Details();
        this._datasets.push(this.details);
        this._setDatasetLayer(this.details,view,layer);
      }
      // else if (layer.title === "Reservations") {
      //   if (!this.reservations) {
      //     this.reservations = new Reservations();
      //     this._datasets.push(this.reservations);
      //     this._setDatasetLayer(this.reservations, view, layer);
      //   }
      // }

      /*
      if (layer.title === "Details") {
        if (!this.details) this.details = new Details();
        this._datasets.push(this.details);
        this._setDatasetLayer(this.details,view,layer);
      } else if (layer.title === "Events") {
        if (!this.events) this.events = new Events();
        this._datasets.push(this.events);
        this._setDatasetLayer(this.events,view,layer);
      } else if (!facilityLayer && layer.title === "Facilities") {
        if (!this.facilities) this.facilities = new Facilities();
        this._datasets.push(this.facilities);
        this._setDatasetLayer(this.facilities,view,layer);
      } else if (layer.title === "Landmarks") {
        if (!this.landmarks) this.landmarks = new Landmarks();
        this._datasets.push(this.landmarks);
        this._setDatasetLayer(this.landmarks,view,layer);
      } else if (layer.title === "Levels") {
        if (!this.levels) this.levels = new Levels();
        this._datasets.push(this.levels);
        this._setDatasetLayer(this.levels,view,layer);
      } else if (layer.title === "Pathways") {
        if (!this.pathways) this.pathways = new Pathways();
        this._datasets.push(this.pathways);
        this._setDatasetLayer(this.pathways,view,layer);
      } else if (layer.title === "People") {
        if (!this.people) this.people = new People();
        this._datasets.push(this.people);
        this._setDatasetLayer(this.people,view,layer);
      } else if (layer.title === "Sections") {
        if (!this.sections) this.sections = new Sections();
        this._datasets.push(this.sections);
        this._setDatasetLayer(this.sections,view,layer);
      } else if (layer.title === "Sites") {
        if (!this.sites) this.sites = new Sites();
        this._datasets.push(this.sites);
        this._setDatasetLayer(this.sites,view,layer);
      } else if (layer.title === "Transitions") {
        if (!this.transitions) this.transitions = new Transitions();
        this._datasets.push(this.transitions);
        this._setDatasetLayer(this.transitions,view,layer);
      } else if (layer.title === "Units") {
        if (!this.units) this.units = new Units();
        this._datasets.push(this.units);
        this._setDatasetLayer(this.units,view,layer);
      } else if (layer.title === "Zones") {
        if (!this.zones) this.zones = new Zones();
        this._datasets.push(this.zones);
        this._setDatasetLayer(this.zones,view,layer);
      }
      */

    });

    const tables = map.tables;
    if (tables && tables.length > 0) {
      tables.forEach((table: __esri.FeatureLayer) => {
        let uc = "";
        if (typeof table.title === "string") uc = table.title.toUpperCase();
        if (uc === "CATEGORIES") {
          // console.log("CATEGORIES",table.title,table);
          this._categoriesUrl = table.url + "/" + table.layerId;
        } else if (uc === "INDOORS CONFIGURATION" || uc === "INDOORSCONFIG") {
          this._indoorsConfigUrl = table.url + "/" + table.layerId;
          //console.log("INDOORSCONFIG",table.title);
          // network service url?
        } else if (uc === "EMPLOYEEINFO") {
          //console.log("EMPLOYEEINFO",table.title);
        } else if (uc === "DISPLAYLODS") {
          //console.log("DISPLAYLODS",table.title);
        } else if (uc === "DISPLAYSCALES") {
          //console.log("DISPLAYSCALES",table.title);
        } else if (uc === "AREAS") {
          if (!this.areas && table.url) {
            this.areas = new Areas()
            this.areas.url = table.url + "/" + table.layerId;
            const params = { url: this.areas.url };
            this.areas.table = new Context.instance.lib.esri.FeatureLayer(params);
            this.areas.table.load();
            this._datasets.push(this.areas)
          }
        } else if (uc === "AREA ROLES") {
          if (!this.areaRoles && table.url) {
            this.areaRoles = new AreaRoles()
            this.areaRoles.url = table.url + "/" + table.layerId;
            const params = { url: this.areaRoles.url };
            this.areaRoles.table = new Context.instance.lib.esri.FeatureLayer(params);
            this.areaRoles.table.load();
            this._datasets.push(this.areaRoles)
          }
        }
      });
    }

    if (this.facilities) {
      let found = false, aboveFacilities = false;
      layers.forEach(layer => {
        if (layer === this.facilities.layer2D || layer === this.facilities.subLayer) {
          found = true;
        }
        if (aboveFacilities) {
          layer.xtnAboveFacilities = true;
        }
        if (found) aboveFacilities = true;
        //console.log(layer.title,layer.xtnAboveFacilities);
      });
    }

  }

  _processSceneView(view) {
    if (!view) return;

    const get2DTitle = (dataset) => {
      return (dataset && dataset.layer2D && dataset.layer2D.title) || "___none___";
    };

    let titleSites = get2DTitle(this.sites);
    let titleFacilities = get2DTitle(this.facilities);
    let titleLevels = get2DTitle(this.levels);
    let titleUnits = get2DTitle(this.units);
    let titlePeople = get2DTitle(this.people);
    let titleEvents = get2DTitle(this.events);

    let map = view.map;
    let fi = (map && map.floorInfo);
    if (fi) {
      let siteInfo = fi && fi.siteLayer;
      let siteLayer = siteInfo && map.findLayerById(siteInfo.layerId);
      if (siteLayer) titleSites = siteLayer.title;
      let facilityInfo = fi && fi.facilityLayer;
      let facilityLayer = facilityInfo && map.findLayerById(facilityInfo.layerId);
      if (facilityLayer) titleFacilities = facilityLayer.title;
      let levelInfo = fi && fi.levelLayer;
      let levelLayer = levelInfo && map.findLayerById(levelInfo.layerId);
      if (levelLayer) titleLevels = levelLayer.title;
    }

    const layers = aiimUtil.getLayers(view);
    layers.forEach(layer => {
      if (layer.title === titleSites ||
          layer.title === "Sites" || layer.title === "Sites3D" || layer.title === "Sites 3D") {
        this._setDatasetLayer(this.sites,view,layer);
      } else if (layer.title === titleFacilities ||
                 layer.title === "Facilities" || layer.title === "Facilities Textured" || layer.title === "Facilities3D" || layer.title === "Facilities 3D") {
        this._setDatasetLayer(this.facilities,view,layer);
      } else if (layer.title === titleLevels ||
                 layer.title === "Levels" || layer.title === "Levels3D" || layer.title === "Levels 3D") {
        this._setDatasetLayer(this.levels,view,layer);
      } else if (layer.title === titleUnits ||
                 layer.title === "Units" || layer.title === "Units3D" || layer.title === "Units 3D") {
        this._setDatasetLayer(this.units,view,layer);
      } else if (layer.title === titlePeople ||
                 layer.title === "People" || layer.title === "People3D" || layer.title === "People 3D" || 
                 layer.title === "Occupants" || layer.title === "Occupants3D" || layer.title === "Occupants 3D") {
        this._setDatasetLayer(this.people,view,layer);
      } else if (layer.title === titleEvents ||
                 layer.title === "Events" || layer.title === "Events3D" || layer.title === "Events 3D") {
        this._setDatasetLayer(this.events,view,layer);
      }
    });

  }

  _setDatasetLayer(dataset, view, layer) {
    if (!dataset) return;
    layer.xtnAiim = {
      dataset: dataset
    };
    if (view.type === "2d") {
      if (layer && layer.declaredClass === "esri.layers.support.Sublayer") {
        let layer2D = layer.xtnFeatureLayer;
        if (!layer2D) {
          try {
            layer2D = layer.createFeatureLayer();
            layer2D.load();
          } catch(ex) {
            console.error("Error creating feature layer for sublayer",layer);
          }
        }
        dataset.subLayer = layer;
        dataset.url = layer.url
        dataset.layer2D = layer2D;
        if (layer2D) dataset.layer2D.xtnAiim = layer.xtnAiim;
      } else {
        dataset.url = layer.url+"/"+layer.layerId;
        dataset.layer2D = layer;
      }
    } else if (view.type === "3d") {
      dataset.layer3D = layer;
    }
  }

  _waitForMapService(view) {
    const lib = Context.getInstance().lib;
    const promise = new Promise<void>((resolve,reject) => {
      const promises = [], promises2 = [];
      if (view && view.type === "2d") {
        view.map.allLayers.forEach((layer) => {
          if (layer.type === "map-image") {
            if (typeof layer.when === "function") {
              promises.push(layer.when().then());
            }
          }
        });
      }
      if (promises.length > 0) {
        lib.esri.promiseUtils.eachAlways(promises).then(results => {
          //console.log("waitForMapService =.=.=.=.=.=",results); // TODO temporary
          const layers = aiimUtil.getLayers(view);
          layers.forEach((layer) => {
            if (layer && layer.declaredClass === "esri.layers.support.Sublayer" &&
                !layer.xtnFeatureLayer && !layer.sublayers) {
              let promise2 = layer.createFeatureLayer();
              promise2.then(featureLayer => {
                try {
                  layer.xtnFeatureLayer = featureLayer;
                  featureLayer.xtnSubLayer = layer;
                  featureLayer.load();
                } catch(exload) {
                  console.warn("Error loading FeatureLayer",featureLayer,"for Sublayer",layer);
                  console.error(exload);
                }
              }).catch((error)=> {
                console.error("Error creating FeatureLayer", error)
              })
              promises2.push(promise2);
            }
          });

          if (promises2.length > 0) {
            lib.esri.promiseUtils.eachAlways(promises2).then(results2 => {

              // Issue #1077 , must be a JSAPI 4.10 issue, map service layers are set to visible by default
              /*
              try {
                layers.forEach(layer => {
                  //console.log(layer.title,layer.declaredClass,layer);
                  if (layer && layer.declaredClass === "esri.layers.support.Sublayer") {
                    if (layer.visible && layer.xtnFeatureLayer) {
                      let lyr = layer.xtnFeatureLayer;
                      lyr.when(() => {
                        //if (lyr.source && lyr.source.layerDefinition) {
                        if (lyr.sourceJSON) {
                          //let defaultVisibility = lyr.source.layerDefinition.defaultVisibility;
                          let defaultVisibility = lyr.sourceJSON.defaultVisibility;
                          if (typeof defaultVisibility === "boolean") {
                            if (layer.visible && !defaultVisibility) {
                              console.log("Resetting defaultVisibility for",layer.title);
                              // layer.visible = false;
                            }
                          }
                        }
                      })
                    }
                  }
                });
              } catch(ex2) {
                console.log("Error restoring defaultVisibility",ex2);
              }
              */

              resolve();
            }).catch(ex2 => {
              reject(ex2);
            });
          } else {
            resolve();
          }
        }).catch(ex => {
          reject(ex);
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

}
