import Context from "../../../../context/Context";
import { IFilterCriteria } from "../../common/types";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as filterUtil from "./filterUtil";
import { escSqlQuote } from "../../../../aiim/util/selectionUtil";

export function makeFieldWhere(layerType,fieldName,values) {
  let where = null, parts = [];
  const layer = filterUtil.getLayer(layerType);
  const field = aiimUtil.findField(layer && layer.fields,fieldName);
  const fn = field && field.name;
  const isString = (field.type === "string");
  if (values && values.length > 0) {
    values.forEach(v => {
      let part;
      if (isString) {
        if (v === "__novalue" || v === null || v === "") {
          part = `((${fn} IS NULL) OR (${fn} = ''))`;
        } else {
          part = `(${fn} = '${escSqlQuote(v)}')`;
        }
      } else {
        if (v === "__novalue" || v === null) {
          part = `(${fn} = ${v})`;
        }
      }
      if (part) parts.push(part)
    })
  }
  if (parts.length === 1) where = parts[0]
  else if (parts.length > 1) where = "(" + parts.join(" OR ") + ")";
  return where;
}

export function makeFilterWhere(filterCriteria: IFilterCriteria, excludeFieldName?: string) {
  let where = null, parts = [];

  const sfl = filterCriteria && filterCriteria.siteFacilityLevel;
  if (sfl && sfl.where) {
    parts.push(sfl.where)
  }

  const asn = filterCriteria && filterCriteria.assignmentType;
  if (asn && asn.where) {
    parts.push(asn.where)
  }

  const fields = filterCriteria && filterCriteria.fields;
  if (fields && fields.length > 0) {
    const lc = excludeFieldName? excludeFieldName.toLowerCase(): null;
    fields.forEach(f => {
      if (f.where) {
        if (!lc || (lc !== f.field.toLowerCase())) {
          parts.push(f.where)
        }
      }
    })
  }

  if (parts.length === 1) where = parts[0]
  else if (parts.length > 1) where = "(" + parts.join(" AND ") + ")";
  return where;
}

export function makeSiteFacilityLevelWhere(layerType, filterCriteria: IFilterCriteria) {
  let where = null, parts = [];
  const sfl = filterCriteria && filterCriteria.siteFacilityLevel;
  if (sfl) {
    const sid = sfl.siteId;
    const fid = sfl.facilityId;
    const lid = sfl.levelId;
    let levelIds = [];
    if (lid) {
      levelIds.push(lid);
    } else if (sid || fid) {
      const levelsDataset = filterUtil.getLevelsDataset();
      const facilities = levelsDataset && levelsDataset.getFacilities();
      if (facilities && facilities.length > 0) {
        facilities.forEach(f => {
          let ok = true;
          if (sid) ok = (sid === f.siteId); 
          if (fid) ok = (fid === f.facilityId); 
          if (ok) {
            f.levels.forEach(l => levelIds.push(l.levelId))
          }
        })
      }
    }
    if (levelIds.length > 0) {
      const layer = filterUtil.getLayer(layerType);
      const field = Context.instance.aiim.getLevelIdField(layer);
      const fn = field.name;
      levelIds.forEach(v => {
        const part = `(${fn} = '${escSqlQuote(v)}')`;
        parts.push(part);
      })
    }
  }
  if (parts.length === 1) where = parts[0]
  else if (parts.length > 1) where = "(" + parts.join(" OR ") + ")";
  return where;
}
