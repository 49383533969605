import Context from "../../context/Context";
import OfficePlan from "./OfficePlan";
import * as portalUtil from "../../util/portalUtil";

export function deleteVersionSupportService(versionInfo,lenient) {
  const promise = new Promise((resolve,reject) => {
    findVersionSupportService(versionInfo).then(item => {
      if (item) {
        return portalUtil.deleteItems(item.id,item.owner).then(r2 => {
          console.log("deleteVersionSupportService",r2)
        });
      }
    }).then(result => {
      resolve();
    }).catch(ex => {
      if (lenient) {
        console.error("Unable to delete version suppot service",ex);
        resolve();
      } else {
        reject(ex);
      }
    });
  });
  return promise;
}

export function findVersionSupportService(versionInfo) {
  const promise = new Promise((resolve,reject) => {
    const portal = Context.instance.getPortal();
    const versionGuid = versionInfo.versionGuid;
    const guid = versionGuid.replace("{","").replace("}","").replace(/-/g,"");
    const appItem = Context.instance.configuration.appItem;
    const tkParent = OfficePlan.VersionedTypeKeywordParentPfx + appItem.id;
    const tkVersion = OfficePlan.VersionedTypeKeywordVersionPfx + guid;
    //let query = '(typekeywords: "' + tkVersion + '")';
    const query = `((typekeywords: "${tkParent}") AND (typekeywords: "${tkVersion}"))`;
    const queryParams = {
      query: query
    };
    portal.queryItems(queryParams).then(result => {
      if (result && result.results && result.results.length === 1) {
        resolve(result.results[0]);
      } else {
        resolve()
      }
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

export function normalizeGuid(guid) {
  return guid.replace("{","").replace("}","").replace(/-/g,"");
}
