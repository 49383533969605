import React from "react";
import Icons from "../../util/Icons";
import Context from "../../../context/Context";
import ModalController from "../../common/Modal/ModalController";

export default class Reset extends React.Component {
  constructor(props){
    super(props)
    let controller = new ModalController();
    this.state={
      controllerObj:controller
    }
  }

  resetApplication=()=>{
    window.location.reload();
  }

  cancelReset=()=>{
    this.state.controllerObj.close();
  }

  confirmationPopup=()=>{
    const i18n=Context.getInstance().i18n;
    let content=
    <div><h2>{i18n.resetButton.popupTitle}</h2>
      <h6>{i18n.resetButton.prompt}</h6>
      <div className="i-text-right i-margin-top-1">
        <button className="i-button i-margin-all-small" onClick={this.cancelReset}>{i18n.general.cancel}</button>
        <button className="i-button i-button-clear" onClick={this.resetApplication}>{i18n.general.reload}</button>
      </div>
    </div>
    this.state.controllerObj.show(content);
  }

  render() {
    return (
      <div>
      <button className="i-button-icon-large i-button-gray" onClick={this.confirmationPopup}>
          {Icons.reload()}
      </button>
      </div>
    );
  }
}
