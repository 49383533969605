import Context from "../context/Context";

let _watch2DCursorHandle;
let _watch3DCursorHandle;

export function watchForViewClick(tag,view,callback) {

  // pointerType: "mouse", timestamp, x, y

  const clickTolerance = 999;
  const doubleClickTolerance = 500;
  const holdTolerance = 1000;
  const pixelTolerance = 20;

  const lib = Context.instance.lib;
  let aspectHandle, downHandle, moveHandle, upHandle, holdTimeout;
  let origin, lastDownTimestamp = 0;
  let hadDouble = false, paused = false, waitingForDouble = false;

  const ensureScreenPoint = (event) => {
    if (!event.screenPoint) {
      event = Object.assign({
        screenPoint: {
          x: event.x,
          y: event.y
        }
      },event);
    }
    if (!event.mapPoint && view.type === "2d") {
      event = Object.assign({
        mapPoint: view.toMap(event.screenPoint)
      },event);
    }
    return event;
  }

  const xyWithinTolerance = () => {
    if (!origin) return false;
    const x = origin.xMove;
    const y = origin.yMove;
    return (x < pixelTolerance && y < pixelTolerance);
  }

  const remove = () => {
    if (downHandle) downHandle.remove();
    if (moveHandle) moveHandle.remove();
    if (upHandle) upHandle.remove();
    if (aspectHandle) aspectHandle.remove();
  };

  aspectHandle = lib.dojo.aspect.after(view,"destroy",() => {
    remove();
  });

  downHandle = view.on("pointer-down",event => {
    if (paused) return;
    let downMillis = (event.timestamp - lastDownTimestamp);
    lastDownTimestamp = event.timestamp;
    //console.log("pointer-down",downMillis,event)
    if (downMillis <= 125) {
      // seems to be a core bug, sometimes we get 2 down events with only on down action
      return;
    }
    if (waitingForDouble) {
      hadDouble = true;
      return;
    }
    clearTimeout(holdTimeout);
    hadDouble = waitingForDouble = false;
    origin = {
      timestamp: event.timestamp,
      x: event.x,
      y: event.y,
      xMove: 0,
      yMove: 0
    }
    let originTimestamp = origin.timestamp;
    holdTimeout = setTimeout(() => {
      if (!origin || waitingForDouble || paused) return;
      if (originTimestamp !== origin.timestamp) return;
      if (xyWithinTolerance(pixelTolerance)) {
        const wasLongPress = true;
        if (callback) callback(ensureScreenPoint(event),wasLongPress);
      }
      origin = null;
    },holdTolerance);
  });

  moveHandle = view.on("pointer-move",event => {
    if (!origin || paused) return;
    const x = Math.abs(event.x - origin.x);
    const y = Math.abs(event.y - origin.y);
    if (x > origin.xMove) origin.xMove = x;
    if (y > origin.yMove) origin.yMove = y;
  });

  upHandle = view.on("pointer-up",event => {
    if (!origin || paused) return;
    if (waitingForDouble) return;
    clearTimeout(clearTimeout);
    let originTimestamp = origin.timestamp;
    let millis = event.timestamp - originTimestamp;
    if (millis < clickTolerance) {
      waitingForDouble = true;
      setTimeout(() => {
        if (!origin || paused) return;
        if (originTimestamp !== origin.timestamp) return;
        clearTimeout(clearTimeout);
        if (hadDouble) {
          waitingForDouble = hadDouble = false;
          origin = null;
        } else {
          if (xyWithinTolerance(pixelTolerance)) {
            if (callback) callback(ensureScreenPoint(event));
          }
          waitingForDouble = hadDouble = false;
          origin = null;
        }
      },doubleClickTolerance);
    } else {
      origin = null;
    }
  });

  let pausable = {
    remove: () => {
      paused = true;
      remove();
    },
    pause: () => {
      paused = true;
    },
    resume: () => {
      paused = false;
    }
  };

  return pausable;
}

export function watchCursors(mapView,sceneView) {

  const removeHandle = (h) => {
    try {
      if (h) h.remove();
    } catch(ex) {
      console.error(ex);
    }
  }

  const watchCursor = (view) => {
    let h = null;
    if (view) {
      h = view.watch("cursor",(newVal,oldVal,property,object) => {
        if (newVal === "copy") {
          view.cursor = "crosshair";
        }
      });
    }
    return h;
  }

  removeHandle(_watch2DCursorHandle);
  _watch2DCursorHandle = null;
  removeHandle(_watch3DCursorHandle);
  _watch3DCursorHandle = null;

  _watch2DCursorHandle = watchCursor(mapView);
  _watch3DCursorHandle = watchCursor(sceneView);
}
