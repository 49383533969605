import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";

import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "../support/editorUtil";
import * as featureItemUtil from "./featureItemUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as splitUtil from "../support/splitUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as stencilUtil from "../support/stencilUtil";
import * as transactions from "../../../base/transaction/transactions";

import { IFeatureItem } from "./featureItemUtil";
import { IEditFeatureResult } from "@esri/arcgis-rest-feature-layer";
import { ensureGraphicsLayer } from "../../Comments/SketchViewModelUtility";
import { ISymbolParams } from "../support/MapSelection";

export interface IActiveFeatureItem extends IEditFeatureResult {
  feature: __esri.Graphic
}
export interface ICopyPastVMProps {
  fpeType?: "entryway" | "window" | "furniture",
  onIntersectingWalls?: (hasIntersections: boolean) => void
}

export default class CopyPasteVM extends BaseVM {

  private activeSketchViewModel;
  pastedFeatureItems: IFeatureItem[] = null;

  private detailsGraphicLayerId = "indoors-copypaste-details";
  private unitsGraphicLayerId = "indoors-copypaste-units";
  private sitesGraphicLayerId = "indoors-copypaste-sites";
  private facilitiesGraphicLayerId = "indoors-copypaste-facilities";
  private levelsGraphicLayerId = "indoors-copypaste-levels";
  private hoverGraphicLayerId = "indoors-copypaste-hover";
  private svmGraphicLayerId = "indoors-copypaste-svm";

  lineSymbol: __esri.SimpleLineSymbol;
  fillSymbol: __esri.SimpleFillSymbol;
  activeLineSymbol: __esri.SimpleLineSymbol;
  activeFillSymbol: __esri.SimpleFillSymbol;
  hoverLineSymbol: __esri.SimpleLineSymbol;
  hoverFillSymbol: __esri.SimpleFillSymbol;

  symbolParams: ISymbolParams;

  constructor(props: ICopyPastVMProps) {
    super(props);
    this.mixinProps(props);

    // const lineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 3, color: [0, 255, 255, 1] };
    // const activeLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 2, color: [255, 255, 0, 1] };
    // const hoverLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 2, color: [0, 0, 255, 1] };
    const lineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 2, color: [0, 255, 255, 1] };
    const activeLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [0, 255, 255, 1] };
    const hoverLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [70, 130, 180, 1] };
    const fillSymbol: __esri.SimpleFillSymbolProperties = { style: "solid", color: [0, 255, 255, .25], outline: { ...lineSymbol } };
    const activeFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [0, 255, 255, .75], outline: { ...lineSymbol } };
    const hoverFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [70, 130, 180, .75], outline: { ...lineSymbol } };
 
   
    this.symbolParams = {lineSymbol,fillSymbol,activeLineSymbol,activeFillSymbol};
    this.lineSymbol = new Context.instance.lib.esri.SimpleLineSymbol(lineSymbol);
    this.fillSymbol = new Context.instance.lib.esri.SimpleFillSymbol(fillSymbol);
    this.activeLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol(activeLineSymbol);
    this.activeFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol(activeFillSymbol);
    this.hoverLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol(hoverLineSymbol);
    this.hoverFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol(hoverFillSymbol);
  }

  activateAdjust() {
    this.cancelSketch();
    const pastedFeatureItems = this.pastedFeatureItems;
    const extent = pastedFeatureItems && featureItemUtil.getExtent(pastedFeatureItems);
    if (!extent) return;

    const lib = Context.instance.lib;
    const view = this.getView();
    const ge = lib.esri.geometryEngine;
    const itemsByKey = featureItemUtil.makeItemsByKey(pastedFeatureItems);

    let polygon = new lib.esri.Polygon({
      rings:[
        [extent.xmin,extent.ymin,extent.zmin],
        [extent.xmin,extent.ymax,extent.zmin],
        [extent.xmax,extent.ymax,extent.zmin],
        [extent.xmax,extent.ymin,extent.zmin],
        [extent.xmin,extent.ymin,extent.zmin]
      ],
      spatialReference: extent.spatialReference
    })

    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this.svmGraphicLayerId);
    const graphic = new lib.esri.Graphic({
      geometry: polygon,
      symbol: new lib.esri.SimpleFillSymbol({
        style: "solid", 
        color: [255, 255, 255, 0],
        outline: {
          style: "solid", 
          width: 1, 
          color: [0, 255, 255, 1]
        }
      })
    })
    graphicsLayer.graphics.removeAll();
    graphicsLayer.graphics.add(graphic);

    const graphicLayers = [
      mapUtil.ensureGraphicsLayer(view,this.unitsGraphicLayerId,sourceUtil.getUnitsLayer()),
      mapUtil.ensureGraphicsLayer(view,this.detailsGraphicLayerId,sourceUtil.getDetailsLayer())
    ]

    let svm = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer
    });

    const updateOptions = {
      tool: "transform", // "transform"|"reshape"|"move"
      enableRotation: true,
      enableScaling: false,
      preserveAspectRatio: false,
      toggleToolOnClick: false,
      enableZ: false
    };
    //svm.defaultUpdateOptions = updateOptions;

    const task = {
      anchor: polygon.extent.center.clone(),
      lastAngle: null,
      lastRotateMillis: null,
      lastTranslateMillis: null,
      debounceMillis: 50
    };
    if (pastedFeatureItems.length <= 20) task.debounceMillis = 10;

    const resetLevels = async () => {
      await featureItemUtil.resetLevels(pastedFeatureItems);
      this.onLevelsReset();
    }

    const rotate = (event,graphic,teiType) => {
      const millis = Date.now();
      const isStart = teiType === "rotate-start";
      const isStop = teiType === "rotate-stop";
      if (isStart) task.lastAngle = null;
      const ok = isStart || isStop || (task.lastRotateMillis === null) || ((millis - task.lastRotateMillis) > task.debounceMillis);
      if (ok) {
        task.lastRotateMillis = millis;
        const tei = event.toolEventInfo;
        const center = graphic.geometry.extent.center.clone();
        let angle = tei.angle;
        if (task.lastAngle !== null) {
          angle = angle - task.lastAngle;
        }
        graphicLayers.forEach(l => {
          l.graphics.forEach(g => {
            if (g.geometry.type === "polygon" || g.geometry.type === "polyline") {
              g.geometry = ge.rotate(g.geometry,angle,center);
              updateItemGeometry(g);
            }
          })
        })
        task.lastAngle = tei.angle;
        pastedFeatureItems.forEach(itm => {
          if (itm.layerType === "unit") {
            this.nameChanged(itm,itm.description);
          }
        })
        if (isStop) resetLevels();
      }
      if (isStop) {
        task.anchor = graphic.geometry.extent.center.clone();
      }
    }

    const translate = (event,graphic,teiType) => {
      const millis = Date.now();
      const isStart = teiType === "move-start";
      const isStop = teiType === "move-stop";
      const ok = isStart || isStop || (task.lastTranslateMillis === null) || ((millis - task.lastTranslateMillis) > task.debounceMillis);
      if (ok) {
        task.lastTranslateMillis = millis;
        const center = graphic.geometry.extent.center.clone();
        const dx = (center.x - task.anchor.x);
        const dy = (center.y - task.anchor.y);
        const z = 0;
        graphicLayers.forEach(l => {
          l.graphics.forEach(g => {
            g.geometry = stencilUtil.translateGeometry(g.geometry,dx,dy,z);
            updateItemGeometry(g);
          })
        })
        if (isStop) resetLevels();
        task.anchor = graphic.geometry.extent.center.clone();
      }
    }

    const updateItemGeometry = (graphic) => {
      // @ts-ignore
      const key = graphic.xtnKey;
      const itm = key && itemsByKey[key];
      if (itm) {
        const z = itm.graphic.geometry.extent.center.z;
        itm.graphic.geometry = graphic.geometry.clone();
        editorUtil.updateZ(itm.graphic.geometry,z);
      }
    }

    this.own([
      svm.on("update",event => {
        const tei = event.toolEventInfo;
        const teiType = tei && tei.type;
        const graphic = event.graphics && event.graphics[0];
        if (teiType === "move-start" || teiType === "move" || teiType === "move-stop") {
          translate(event,graphic,teiType);
        } else if (teiType === "rotate-start" || teiType === "rotate" || teiType === "rotate-stop") {
          rotate(event,graphic,teiType);
        }
        if (event.state === "complete") {
          if (!svm.xtnCanceling) this.activateAdjust();
        }
        if (event.state === "cancel") {
          if (!svm.xtnCanceling) this.activateAdjust();
        }
      })
    ]);
    
    Context.instance.views.toggleClickHandlers("pause");
    svm.update([graphic],updateOptions);
  }

  allGraphicLayers(): __esri.GraphicsLayer[] {
    const view = this.getView();
    const graphicLayers = [];
    const add = layer => {
      if (layer) graphicLayers.push(layer);
    }
    add(mapUtil.ensureGraphicsLayer(view,this.unitsGraphicLayerId,sourceUtil.getUnitsLayer()));
    add(mapUtil.ensureGraphicsLayer(view,this.detailsGraphicLayerId,sourceUtil.getDetailsLayer()));
    return graphicLayers;
  }

  cancelSketch() {
    const svm = this.activeSketchViewModel;
    if (svm) {
      svm.xtnCanceling = true;
      svm.cancel();
      svm.destroy();
      this.activeSketchViewModel = null;
      Context.instance.views.toggleClickHandlers("resume");
    }
    mapUtil.removeAllGraphics(this.getView(),this.svmGraphicLayerId);
  }

  clear() {
    this.pastedFeatureItems = null;
    this.cancelSketch();
    this.clearGraphics();
  }

  clearGraphics() {
    const view = this.getView();
    mapUtil.removeAllGraphics(view,this.detailsGraphicLayerId);
    mapUtil.removeAllGraphics(view,this.unitsGraphicLayerId);
    mapUtil.removeAllGraphics(view,this.sitesGraphicLayerId);
    mapUtil.removeAllGraphics(view,this.facilitiesGraphicLayerId);
    mapUtil.removeAllGraphics(view,this.levelsGraphicLayerId);
    this.clearHoverGraphic();
  }

  clearHoverGraphic() {
    mapUtil.removeAllGraphics(this.getView(),this.hoverGraphicLayerId);
  }

  destroy() {
    super.destroy();
  }

  executeCopy(selectedFeatureItems: IFeatureItem[]) {
    return featureItemUtil.copyFeatureItems(selectedFeatureItems);
  }

  async executePaste(copiedFeatureItems: IFeatureItem[]) {
    const view = this.getView();
    const graphicLayers = this.getGraphicLayersIndex();
    const graphics = {
      "unit": [],
      "unit-text": [],
      "detail": [],
    }

    const featureItems = featureItemUtil.cloneFeatureItems(copiedFeatureItems);
    const center = featureItemUtil.getCenter(featureItems);
    const viewCenter = view.extent.center.clone();
    const dx = (viewCenter.x - center.x);
    const dy = (viewCenter.y - center.y);
    let z = null;

    featureItems.forEach(featureItem => {
      // @todo x,y offset
      // @todo new level_id
      // @todo new z value
      // @todo text-symbol ?
      const layerType = featureItem.layerType;
      const graphic: __esri.Graphic = featureItem.graphic;
      const layer: __esri.FeatureLayer = graphic.layer as __esri.FeatureLayer;
      // @ts-ignore
      if (z === null) z = graphic.geometry.z || graphic.geometry.extent.center.z;
      graphic.geometry = stencilUtil.translateGeometry(graphic.geometry,dx,dy,z);

      const symbol = this.makeSymbol(graphic.geometry,"standard");
      const graphic2 = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},graphic.attributes),
        geometry: graphic.geometry.clone(),
        symbol: symbol
      })
      graphic2.xtnKey = featureItem.key;
      graphics[layerType].push(graphic2);
      if (layerType === "unit") {
        const key = featureItem.key;
        const name = aiimUtil.getAttributeValue(graphic.attributes,FieldNames.NAME) 
        const graphic3 = name && splitUtil.makeUnitNameGraphic(key,graphic,name);
        if (graphic3) {
          graphics["unit-text"].push(graphic3);
        }
      }
    });

    await featureItemUtil.resetLevels(featureItems);

    this.clearGraphics();
    graphicLayers["unit"].addMany(graphics["unit"]);
    graphicLayers["unit"].addMany(graphics["unit-text"]);
    graphicLayers["detail"].addMany(graphics["detail"]);

    this.pastedFeatureItems = featureItems;
    this.activateAdjust();
    return featureItems;
  }

  getGraphicLayersIndex() {
    const view = this.getView();
    const graphicLayers = {
      "unit": mapUtil.ensureGraphicsLayer(view,this.unitsGraphicLayerId,sourceUtil.getUnitsLayer()),
      "detail": mapUtil.ensureGraphicsLayer(view,this.detailsGraphicLayerId,sourceUtil.getDetailsLayer()),
    }
    return graphicLayers;
  }

  highlightActiveFeature(featureItem: IFeatureItem) {
    const updateGraphic = (graphic,symbol) => {
      if (graphic && symbol) {
        graphic.symbol = symbol;
        graphic.geometry = graphic.geometry.clone(); // to refresh
      }
    }
    const key = featureItem.key;
    const graphicLayers = this.getGraphicLayersIndex();
    const layer = graphicLayers[featureItem.layerType];
    let graphic, prevGraphic;
    this.allGraphicLayers().some(l => {
      l.graphics && l.graphics.some(g => {
        // @ts-ignore
        if (g.xtnActive) prevGraphic = g;
        return !!prevGraphic;
      })
      return !!prevGraphic;
    })
    layer && layer.graphics.forEach(g => {
      // @ts-ignore
      if (g.xtnKey === key) graphic = g;
    })
    if (graphic && graphic === prevGraphic) return;
    if (graphic) {
      const symbol = this.makeSymbol(graphic.geometry,"active");
      graphic.xtnActive = true;
      updateGraphic(graphic,symbol);
    }
    if (prevGraphic) {
      const symbol = this.makeSymbol(prevGraphic.geometry,"standard");
      prevGraphic.xtnActive = false;
      updateGraphic(prevGraphic,symbol);
    }
  }

  makeSymbol(geometry: __esri.Geometry, type: "standard" | "active" | "hover"): __esri.Symbol {
    let symbol = null;
    if (type === "standard" && geometry.type === "polygon") {
      symbol = this.fillSymbol.clone();
    } else if (type === "standard" && geometry.type === "polyline") {
      symbol = this.lineSymbol.clone();
    } else if (type === "active" && geometry.type === "polygon") {
      symbol = this.activeFillSymbol.clone();
    } else if (type === "active" && geometry.type === "polyline") {
      symbol = this.activeLineSymbol.clone();
    } else if (type === "hover" && geometry.type === "polygon") {
      symbol = this.hoverFillSymbol.clone();
    } else if (type === "hover" && geometry.type === "polyline") {
      symbol = this.hoverLineSymbol.clone();
    }
    return symbol;
  }

  nameChanged(featureItem: IFeatureItem, name: string | number) {
    if (featureItem.layerType !== "unit") return;
    if (!name) return;
    featureItem.description = ""+name;
    const key = featureItem.key;
    const lid = this.unitsGraphicLayerId;
    const view = this.getView();
    const layer = mapUtil.ensureGraphicsLayer(view,lid,sourceUtil.getUnitsLayer());
    const graphic = splitUtil.makeUnitNameGraphic(key,featureItem.graphic,name);
    layer.graphics.some(g => {
      // @ts-ignore
      if (g.xtnNameType === key) {
        layer.graphics.remove(g);
        return true;
      }
      return false;
    })
    layer.graphics.add(graphic);
  }

  onLevelsReset() {}

  removeGraphic(featureItem: IFeatureItem, activateAdjust: boolean) {
    const key = featureItem.key;
    this.allGraphicLayers().some(l => {
      l.graphics && l.graphics.forEach(g => {
        // @ts-ignore
        if (g.xtnKey === key || g.xtnNameType === key) {
          l.remove(g);
        }
      })
    })
    if (activateAdjust) this.activateAdjust();
  }

  showHoverGraphic(featureItem: IFeatureItem) {
    const graphic = featureItem.graphic;
    const symbol = this.makeSymbol(graphic.geometry,"hover");
    const graphic2 = new Context.instance.lib.esri.Graphic({
      attributes: Object.assign({},graphic.attributes),
      geometry: graphic.geometry.clone(),
      symbol: symbol
    })
    graphic2.xtnKey = featureItem.key;
    const graphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(),this.hoverGraphicLayerId);
    this.clearHoverGraphic();
    graphicsLayer.add(graphic2);
  }

  async save(pastedFeatureItems: IFeatureItem[]) {
    const featuresByLayerId = {};
    const layerIds = [];
    pastedFeatureItems.forEach(featureItem => {
      const layerId = featureItem.layerId;
      const feature = featureItem.graphic;
      let features = featuresByLayerId[layerId];
      if (!features) {
        features = [];
        featuresByLayerId[layerId] = features;
        layerIds.push(layerId);
      }
      features.push(feature);
    })
    const info = {featuresByLayerId, layerIds}
    await transactions.paste(info);
  }

}
