import React from 'react';
import moment from "moment";

// aiim.base
import ItemReference from '../aiim/base/ItemReference';

// aiim.datasets
import FieldNames from "../aiim/datasets/FieldNames";
import { HomeOfficeId, HomeOfficeJSON } from '../spaceplanner/base/AreasTable';

// aiim.tracking
import TrackingLocation from '../aiim/tracking/TrackingLocation';

// aiim.util
import { findFieldName, getAttributeValue } from "../aiim/util/aiimUtil";
import { escSqlQuote } from '../aiim/util/selectionUtil';

// components.common.Modal
import { ModalController } from "../common/components/Modal";
import MultipleAssignments, { IMultipleAssignmentsProps } from "../common/components/Modal/MultipleAssignments";
import { getZulu, toStartOfDay } from '../components/main/Events/dateUtil';

// context
import Context from "../context/Context";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from '../spaceplanner/base/ReviewerManagement/reviewersUtil';
export interface PersonAssignment {
  person: PersonFeature,
  area?: __esri.Graphic,
  unit?: __esri.Graphic,
  type: "workspaceArea" | "unit" | "homeoffice"
}
export interface PersonAssignments {
  person?: __esri.Graphic,
  workspaceAreas: (PersonAssignment & { type: "workspaceArea" })[],
  units: (PersonAssignment & { type: "unit" })[],
  homeOffice?: PersonAssignment & { type: "homeoffice" }
}
export interface PersonFeature extends __esri.Graphic {
  feature?: __esri.Graphic,
  name?: string
};

export type ReservationType = "lkl" | "unit" | "hotel" | "hotdesk" | "workspaceArea" | "homeoffice" | "ongoing" | "upcoming" | "past";
export interface Assignment {  
  area?: __esri.Graphic,
  areaId?: string,
  person: PersonFeature,
  unit?: __esri.Graphic,
  reservation?: __esri.Graphic,
  type?: ReservationType
}
/**
 * Find the duplicate person records
 *
 * @param {object} layer People layer
 * @param {object} personAttributes Attributes of the person feature
 * @returns Duplicate person records for the provided feature
 */
export async function findDuplicateRecords(layer, personAttributes) {
  const fields = layer && layer.fields;
  if (!personAttributes || !fields) {
    return Promise.reject("No fields found on layer");
  }

  // Get the layer field
  const emailField = findFieldName(fields, FieldNames.PEOPLE_EMAIL);

  if (!emailField) {
    return Promise.reject("No email field found on layer");
  }

  const email = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL);

  if (!email) {
    return Promise.reject("Feature does not have an email value");
  }

  // Check for duplicates
  const query = layer.createQuery();
  query.where = `${emailField} = '${escSqlQuote(email)}'`;
  query.returnGeometry = true;
  query.outFields = ["*"];

  return layer.queryFeatures(query);
}

/**
 * Get all of the person's unit and area assignments
 *
 * @param {any} personRecords Person record's for a specific person
 * @returns {object} An object containing the unit and area assignments
 */
export async function getAssignments(personRecords: __esri.Graphic[]) {
  // Object to track all assignments
  const assignments: PersonAssignments = {
    units: [],
    workspaceAreas: []
  };

  if (!personRecords || personRecords.length === 0) {
    return assignments;
  }

  const promises = personRecords.map((person) => _getPersonAssignment(person, assignments));

  try {
    await Promise.all(promises);
  } catch (e) {
    console.error(e);
  }

  return assignments;
}

/**
 * Check if the assignments object is populated
 *
 * @param {object} assignments The object containing the unit and area assignments for a person
 * @returns {boolean} Whether assignments is populated or not
 */
export function assignmentsFound(assignments: PersonAssignments) {
  if (!assignments) {
    return false;
  }

  const { units, workspaceAreas, homeOffice } = assignments;

  const hasUnits = units && units.length > 0;
  const hasWorkspaceAreas = workspaceAreas && workspaceAreas.length > 0;
  const hasHomeOffice = homeOffice != null;

  return hasUnits || hasWorkspaceAreas || hasHomeOffice;
}

/**
 * Check if assignments are only for areas
 *
 * @param {*} assignments The object containing the unit and area assignments for a person
 * @returns {boolean} Whether the person has only area assignments
 */
export function hasOnlyAreaAssignments(assignments: PersonAssignments) {
  if (!assignments) {
    return false;
  }

  const { units, workspaceAreas, homeOffice } = assignments;

  const hasUnits = units && units.length > 0;
  const hasWorkspaceAreas = workspaceAreas && workspaceAreas.length > 0;
  const hasHomeOffice = homeOffice != null;

  return !hasUnits && (hasWorkspaceAreas || hasHomeOffice);
}

export function showMultipleAssignmentsModal(personFeatures, pastReservation, upcomingReservations, lkl, directions) {
  // if (
  //   (!personFeatures || personFeatures.length === 0) &&
  //   (!upcomingReservations || upcomingReservations.length === 0)
  // ) {
  //   return false;
  // }

  if (!!directions) {
    const modalItems = [];

    // Add last shared location
    if (lkl) {
      modalItems.push(lkl);
    }

    if (pastReservation) {
      pastReservation.forEach((reservation) => modalItems.push(reservation));
    }

    // Add all reservations
    upcomingReservations.forEach((reservation) => modalItems.push(reservation));

    personFeatures.forEach((feature) => {
      // Don't count non-unit assignments
      const attributes = feature.attributes;
      const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID);
      if (unitId) {
        modalItems.push(feature);
      }
    });

    return modalItems.length > 1;
  } else {
    const modalItems = [];

    // Add last shared location
    if (lkl) {
      modalItems.push(lkl);
    }

    if (pastReservation) {
      pastReservation.forEach((reservation) => modalItems.push(reservation));
    }

    // Add all reservations
    upcomingReservations.forEach((reservation) => modalItems.push(reservation));

    personFeatures.forEach((feature) => {
      // Don't count workspace if there is a reservation for that workspace area. Add the rest
      const attributes = feature.attributes;
      const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);
      const existingAreaBooking = upcomingReservations.some((r) => r.areaId === areaId);
      if ((!areaId || (areaId && !existingAreaBooking)) && hasAssignment(feature)) {
        modalItems.push(feature);
      }
    });
    return modalItems.length > 1;
  }
}

/**
 * Flattens the assignments object into an array
 *
 * @param {object} assignments The object containing the unit and area assignments for a person
 * @returns {Array} Flattened array of assignments
 */
export function flattenAssignments(assignments: PersonAssignments) {
  // @ts-ignore
  if (!assignments || assignments.length === 0) {
    return [];
  }

  const flattened = [];
  if (assignments.units && assignments.units.length > 0) {
    assignments.units.forEach((unit) => flattened.push(unit));
  }
  if (assignments.workspaceAreas && assignments.workspaceAreas.length > 0) {
    assignments.workspaceAreas.forEach((w) => flattened.push(w));
  }
  if (assignments.homeOffice) {
    flattened.push(assignments.homeOffice);
  }
  return flattened;
}

/**
 * Show the multiple assignments modal
 *
 * @param {object} assignments Assignments for a person
 * @param {object[]} personFeatures Person records
 * @param {string} title Popup title
 */
export async function showMultipleAssignments(options: IMultipleAssignmentsProps & { title: string }) {
  const {
    assignments,
    primary,
    personFeatures,
    title,
    pastReservation,
    upcomingReservations,
    directions,
    directionsCallback,
    noRoutableLocations,
    hasLKL
  } = options;

  const content = (
    <MultipleAssignments
      primary={primary}
      assignments={assignments}
      personFeatures={personFeatures}
      pastReservation={pastReservation}
      upcomingReservations={upcomingReservations}
      directions={directions}
      directionsCallback={directionsCallback}
      noRoutableLocations={noRoutableLocations}
      hasLKL={hasLKL}
    />
  );
  ModalController.showBrandedPopup({ title, content, needsCloseProp: true });
}

async function _getPersonAssignment(person: PersonFeature, assignments: PersonAssignments) {
  let attributes = person && "attributes" in person
    ? person.attributes
    : person && person.feature
      ? person.feature.attributes
      : null;
  if (!attributes) {
    return;
  }

  const unitId = getAttributeValue(attributes, FieldNames.PEOPLE_UNIT_ID);
  const areaId = getAttributeValue(attributes, FieldNames.PEOPLE_AREA_ID);

  // Person should either have a unit id or an area id
  if (unitId && !areaId) {
    const unit = await _getUnitAssignment(unitId);
    if (!unit) {
      return;
    }

    assignments.units.push({ person, unit, type: "unit" });
  } else if (areaId && !unitId) {
    const area = await _getAreaAssignment(areaId);
    if (!area) {
      return;
    }

    if (areaId === HomeOfficeId) {
      assignments.homeOffice = { person, area, type: "homeoffice" };
    } else {
      const areaType = getAttributeValue(area.attributes, FieldNames.AREA_TYPE);
      if (areaType === "hotel" || areaType === "hotdesk" || areaType === "workspace") {
        assignments.workspaceAreas.push({ person, area, type: "workspaceArea" });
      }
    }
  }
}

async function _getUnitAssignment(unitId: string): Promise<__esri.Graphic> {
  const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
  const unitsLayer: __esri.FeatureLayer = units && units.layer2D;

  if (!unitsLayer) {
    return;
  }

  // Get the unit based off of the provided ID
  const unitIdField = findFieldName(unitsLayer.fields, FieldNames.UNIT_ID);
  const query = unitsLayer.createQuery();
  query.where = `${unitIdField} = '${escSqlQuote(unitId)}'`;
  query.outFields = ["*"];
  query.returnGeometry = true;
  query.returnZ = true;

  const result = await unitsLayer.queryFeatures(query);
  return result && result.features && result.features.length === 1 ? result.features[0] : null;
}

async function _getAreaAssignment(areaId: string): Promise<__esri.Graphic> {
  if (areaId === HomeOfficeId) {
    return Context.getInstance().lib.esri.Graphic.fromJSON(HomeOfficeJSON);
  }
  const areas = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.areas;
  const areasTable: __esri.FeatureLayer = areas && areas.table;

  let result = null;
  // Get the area based off of the provided ID
  if (!areasTable) {
    // If the table didn't load and the URL is needed...
    const lib = Context.getInstance().lib;
    const url = areas.url;
    const task = new lib.esri.QueryTask({ url });
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.where = `${FieldNames.AREA_ID} = '${escSqlQuote(areaId)}'`;

    result = await task.execute(query);
  } else {
    // This is ideal so that we can verify the field name
    const areaIdField = findFieldName(areasTable.fields, FieldNames.AREA_ID);
    const query = areasTable.createQuery();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.where = `${areaIdField} = '${escSqlQuote(areaId)}'`;

    result = await areasTable.queryFeatures(query);
  }

  return result && result.features && result.features.length === 1 ? result.features[0] : null;
}

/**
 * Check if a particular feature has a last known location
 */
function _hasLKL(feature: __esri.Graphic) {
  const isAnonymous = Context.getInstance().user.isAnonymous();
  const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
  const peopleSource = people && people.getSource();
  if (isAnonymous || !feature || !people || !peopleSource || !peopleSource.isPeopleLayer()) {
    return false;
  }

  const trackingViews = Context.getInstance().user.trackingViews;
  if (!trackingViews) {
    return false;
  }

  return trackingViews.canObserve(peopleSource, feature);
}

/**
 * Find a person with a last known location
 *
 * @param {Array} personFeatures
 * @returns Feature with a last known location
 */
export function getLKL(personFeatures: __esri.Graphic[]) {
  const lklFeature = personFeatures.find((person) => _hasLKL(person));
  return lklFeature;
}

/**
 * Find the tracking info for a person with a last known location
 *
 * @param {Array} personFeatures
 * @returns The tracking info object for a person
 */
export async function queryTrackingInfo(personFeatures: __esri.Graphic[]) {
  const trackingLocation = new TrackingLocation();
  const lklFeature = getLKL(personFeatures);
  const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
  const source = people && people.getSource();

  if (!lklFeature || !source) {
    return null;
  }

  const item = new ItemReference();
  item._fromFeature(source.key, lklFeature);
  try {
    const trackingInfo = await trackingLocation.query(item);

    // Find intersecting facility so we can accurately change floors
    const feature = trackingInfo && trackingInfo.feature;
    if (feature && feature.geometry && feature.geometry.type === "point") {
      const footprints = Context.getInstance().aiim.facilityFootprints;
      const levels = Context.getInstance().aiim.datasets.levels;
      if (footprints && levels) {
        let facilityId = footprints.findFacilityIdByPoint(feature.geometry);
        if (Array.isArray(facilityId)) {
          facilityId = facilityId[0];
        }
        trackingInfo.facilityId = facilityId;
      }
    }

    return trackingInfo;
  } catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * Check if a person has an assignment
 *
 * @param {object} personFeature
 * @returns If the person has an assignment
 */
export function hasAssignment(personFeature: __esri.Graphic) {
  if (!personFeature || !personFeature.attributes) {
    return false;
  }

  const attributes = personFeature.attributes;
  const unitId = getAttributeValue(attributes, FieldNames.PEOPLE_UNIT_ID);
  const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);
  return !!unitId || !!areaId;
}

/**
 * Find the user's soonest reservations per workspace area
 *
 * @param {string} username
 * @returns The upcoming reservation
 */
export async function findReservations(username: string, reservationType: string) {
  const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
  const reservedForUsernameField = reservations && reservations.reservedForUsernameField;
  if (!reservedForUsernameField || !username) {
    return [];
  }

  const startTimeField = reservations.startTimeField;
  const endTimeField = reservations.endTimeField;
  const stateField = reservations.stateField;

  // Status domains
  const APPROVED = 1;
  const CHECKED_IN = 4;

  const dtStart = getZulu(new Date());


  const clauses = [];
  let options;

  if (reservationType === "upcoming") {
    const stateCheck = `${stateField} IN (${APPROVED},${CHECKED_IN})`;
    const timeCheck = `(${endTimeField} >= TIMESTAMP '${dtStart}')`;
    const usernameCheck = `${reservedForUsernameField} = '${escSqlQuote(username)}'`;
    clauses.push(stateCheck);
    clauses.push(timeCheck);
    clauses.push(usernameCheck);
  } else if (reservationType === "past") {
      const current = new Date(Date.now());
      const dtToday = getZulu(toStartOfDay(current));
      const timeCheck = `(${endTimeField} < TIMESTAMP '${dtStart}')`;
      const timeCheck2 = `(${startTimeField} >= TIMESTAMP '${dtToday}')`;
      const usernameCheck = `${reservedForUsernameField} = '${escSqlQuote(username)}'`;
      clauses.push(timeCheck);
      clauses.push(timeCheck2);
      clauses.push(usernameCheck);
      options = {
        orderByField : [endTimeField + " DESC"],
        num: 1
      }
  }

  const where = clauses.join(" AND ");
  if (!where) {
    return [];
  }

  try {
    const personReservations = await reservations.query(where, options);
    const features = personReservations && personReservations.features;
    const sortedFeatures = features && features.sort((a, b) => {
      const stA = a.attributes[startTimeField];
      const stB = b.attributes[endTimeField];
      const mmA = moment(stA);
      const mmB = moment(stB);
      return (mmA && mmB)
        ? mmA.isAfter(mmB)
          ? 1
          : mmA.isBefore(mmB)
            ? -1
            : 0
        : 0;
    });
    return sortedFeatures || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function queryReservationUnit(reservation) {
  const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
  const unitId = getAttributeValue(reservation.attributes, FieldNames.UNIT_ID);
  if (!units || !unitId) {
    return null;
  }

  const result = await units.queryById(unitId);
  if (!result || !result.features || result.features.length === 0) {
    return null;
  }
  return result.features[0];
}

export async function findUserReservations(person, reservationType) {
  const reservationsArray = [];

  const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
  const areas = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.areas;

  if (!areas || !reservations || Context.instance.user.isAnonymous()) {
    return reservationsArray;
  }

  const personAttributes = person && person.feature && person.feature.attributes;
  const email = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL);

  try {
    const portalResult = await getPortalUserByEmail({ email });
    const username = getUsernameFromPortalUsers(portalResult, person.feature);

    if (areas && reservations && username) {
      // Find soonest upcoming reservations per area
      const reservations = await findReservations(username, reservationType);
      if(!reservations || reservations.length === 0) return;

      if (reservationType === "past") {
        const reservation = reservations[0];
        const unit = await queryReservationUnit(reservation);
        const areaId = unit && getAttributeValue(unit.attributes, FieldNames.AREA_ID);
        const type = "past";

        // Check if we've already added an upcoming booking for this area
        const existingAreaBooking = reservationsArray.some((r) => {
          const reservationAreaId = getAttributeValue(r.unit.attributes, FieldNames.AREA_ID);
          return reservationAreaId === areaId;
        });

        if (!!!existingAreaBooking) {
          const area = await areas.queryAreaById(areaId);
          reservationsArray.push({ reservation, unit, area, person, type, areaId });
        }
      } else {
        for (let i = 0; i < reservations.length; i++) {
          const reservation = reservations[i];
          const unit = await queryReservationUnit(reservation);
          const areaId = unit && getAttributeValue(unit.attributes, FieldNames.AREA_ID);

          // Check if we've already added an upcoming booking for this area
          const existingAreaBooking = reservationsArray.some((r) => {
            const reservationAreaId = getAttributeValue(r.unit.attributes, FieldNames.AREA_ID);
            return reservationAreaId === areaId;
          });

          if (!!!existingAreaBooking) {
            const area = await areas.queryAreaById(areaId);
            const startTime = getAttributeValue(reservation.attributes, FieldNames.START_TIME);
            const type = startTime ? moment().isAfter(moment(startTime)) ? "ongoing" : "upcoming" : null;
            if ((!reservationsArray || reservationsArray.length === 0) || type === "ongoing")
                reservationsArray.push({ reservation, unit, area, person, type, areaId })
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    return reservationsArray;
  }
}
