import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import CalciteThemeProvider from "calcite-react/CalciteThemeProvider";
import { Theme } from "../../../theme";

import BaseClass from "../../../util/BaseClass";
import Button from "../Button";
import Context from "../../../context/Context";
import Modal from "./Modal";
import Rdx from "../../../redux/Rdx";

import ErrorDetails from "./ErrorDetails";
import { StyledModalWrapper } from "./ModalController_styled";
import Warning from "../Warning/Warning";
import BrandedPopup from "./BrandedPopup";

import * as modalUtil from "../../../spaceplanner/miniapps/common/components/modalUtil";

export default class ModalController extends BaseClass {
  _modal;
  _promise;

  close() {
    if (this._modal) this._modal.close();
  }

  show(props, content) {
    const promise = (this._promise = new Promise((resolve, reject) => {
      let wasResolved = false;
      const i18n = Context.instance.i18n;
      const appElement = document.getElementById("root");
      const node = document.createElement("div");

      const _onMount = obj => {
        this._modal = obj;
        if (typeof props.onMount === "function") {
          props.onMount(this);
        }
      };

      const _onMountOK = obj => {
        if (typeof props.onMountOKButton === "function") {
          props.onMountOKButton(obj);
        }
      };

      const _onMountWarning = obj => {
        if (typeof props.onMountWarning === "function") {
          props.onMountWarning(obj)
        }
      }

      const _onOK = obj => {
        if (typeof props.onOK === "function") {
          props.onOK(this);
        }
        if (typeof props.closeOnOK === "boolean" && !props.closeOnOK) {
          return;
        }
        if (!wasResolved) {
          wasResolved = true;
          resolve(false);
          this.close();
        } else {
          this.close();
        }
      };

      const _onClose = obj => {
        if (typeof props.onClose === "function") {
          props.onClose(this);
        }
        if (!wasResolved) {
          wasResolved = true;
          resolve(false);
        }
      };

      const _onCancel = obj => {
        if (typeof props.onCancel === "function") {
          props.onCancel(this);
        }
        if (typeof props.closeOnCancel === "boolean" && !props.closeOnCancel) {
          return;
        }
        this.close();
      };

      let cls = "";
      if (typeof props.className === "string" && props.className.length > 0) {
        cls += " " + props.className;
      }

      let okCancel = null;
      if (props.showOKCancel) {
        const okLabel = props.okLabel || i18n.general.ok;
        const cancelLabel = props.cancelLabel || i18n.general.cancel;
        const hideOK = props.hideOK;
        const hideCancel = props.hideCancel;
        okCancel = [
          (!hideCancel && <Button
            key="modal-cancel"
            className="i-cancel-button"
            clear
            onClick={_onCancel}>
            {cancelLabel}
          </Button>),
          props.extraButton,
          (!hideOK && <Button
            key="modal-assign"
            className="i-ok-button"
            onClick={_onOK}
            _onMount={_onMountOK}
            gapRight
          >
            {okLabel}
          </Button>)
        ];
        if (props.reverseOKCancel) okCancel.reverse();

      }

      let capacityWarning = (
          <Warning key="capacity-warning" _onMount={_onMountWarning}></Warning>
        )

      ReactDOM.render(
        <Provider store={Rdx.store}>
          <CalciteThemeProvider theme={Theme}>
            <StyledModalWrapper>
              <Modal
                {...props}
                className={cls}
                appElement={appElement}
                showClose={true}
                _onClose={_onClose}
                _onMount={_onMount}
                actions={okCancel || props.actionBarContent}
              >
                {content}
                {capacityWarning}
              </Modal>
            </StyledModalWrapper>
          </CalciteThemeProvider>
        </Provider>,
        node
      );
    }));
    return promise;
  }

  // {isError, title, message/submessage or content, className, ariaLabel}
  static alert(options) {

    if (Context.instance.useCalcite()) {
      options.hideOkCancel = true;
      ModalController.confirm(options);
      return;
    }

    let content = null;
    if (options.message && options.submessage) {
      content = (
        <div>
          <div key="msg">{options.message}</div>
          <div key="submsg">{options.submessage}</div>
        </div>
      );
    } else {
      content = options.message || options.content;
    }
    const props = {
      title: options.title,
      className: options.className,
      ariaLabel: options.ariaLabel,
      dialogStyle: options.dialogStyle
    };
    const controller = new ModalController();
    controller.show(props, content);
  }

  static async confirm(options) {

    if (Context.instance.useCalcite()) {
      if (options.content) options.message = options.content;
      options.onOk = (options.onOk || options.onOK);
      const result = await modalUtil.confirm(options);
      return {ok: !!result};
    }

    const promise = new Promise((resolve, reject) => {
      const controller = new ModalController();
      let wasResolved = false;
      const result = {
        controller: controller
      };
      const onOK = obj => {
        if (!wasResolved) {
          wasResolved = true;
          result.ok = true;
          resolve(result);
          if (typeof options.closeOnOK !== "boolean" || options.closeOnOK) {
            controller.close();
          }
        }
      };
      const onClose = obj => {
        if (!wasResolved) {
          wasResolved = true;
          result.ok = false;
          resolve(result);
          if (
            typeof options.closeOnCancel !== "boolean" ||
            options.closeOnCancel
          ) {
            controller.close();
          }
        }
      };
      const content = options.message || options.content;
      const props = {
        title: options.title,
        className: options.className || "i-modal-confirm",
        ariaLabel: options.ariaLabel,
        showOKCancel: true,
        cancelLabel: options.cancelLabel,
        hideCancel: options.hideCancel || false,
        extraButton: options.extraButton,
        closeOnOK: false,
        closeOnCancel: false,
        okLabel: options.okLabel,
        onOK: onOK,
        onCancel: onClose,
        onClose: onClose,
        onMountOKButton: options.onMountOKButton,
        onMount: options.onMount
      };
      controller.show(props, content);
    });
    return promise;
  }

  static showMessage(message, title, className, ariaLabel) {

    if (Context.instance.useCalcite()) {
      ModalController.confirm({
        title,
        message,
        className,
        hideOkCancel: true
      });
      return;
    }

    const controller = new ModalController();
    const content = message;
    const props = {
      title: title,
      className: className,
      ariaLabel: ariaLabel
    };
    controller.show(props, content);
  }

  static showError(options) {
    const { title, errors, className, ariaLabel } = options;
    const controller = new ModalController();
    const content = (<ErrorDetails errors={errors} />);
    const props = {
      title,
      className,
      ariaLabel,
      dialogStyle: { width: "30vw" }
    };
    controller.show(props, content);
  }

  static showBrandedPopup(options) {
    const { title, content, needsCloseProp } = options;

    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node);
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <BrandedPopup
          title={title}
          content={content}
          closePopup={onClose}
          needsCloseProp={needsCloseProp}
        />
      </Provider>
    ), node);
  }
}
