// context
import Context from "../../context/Context";
import Topic from "../../context/Topic";

// util
import BaseClass from "../../util/BaseClass";
import * as localStore from "../../util/localStore";


export default class UserSettings extends BaseClass {
  enablePopups = true;

  constructor() {
    super();
    
    // Restore user settings from local storage
    this.own([
      Topic.subscribe(Topic.AppStarted, () => {
        this.restore();
      }),

      Topic.subscribe(Topic.SignedIn, () => {
        this.restore();
      }),
    ])
  }

  restore() {
    if (!Context.getInstance().appMode.isSP()) {
      return;
    }

    const keys = localStore.userSettingKeys;
    Object.keys(keys).forEach((property) => {
      const key = keys[property];
      let value = localStore.getUserSetting(key);
      if (value) {
        this[property] = value === "true" ? true : false;
      }
    });
  }
}