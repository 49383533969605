import React from 'react';

// Icons
import ChevronDownIcon from "calcite-ui-icons-react/ChevronDownIcon";
import ChevronUpIcon from "calcite-ui-icons-react/ChevronUpIcon";

// components.util
import * as component from '../../../components/util/component';

// context
import Context from '../../../context/Context';

const CSS = {
  main: "i-error-details"
};

export default class ErrorDetails extends React.Component {
  listId;

  constructor(props) {
    super(props);
    this.state = component.newState({
      expanded: false
    });
    this.listId = component.nextId("error-list-");
  }

  toggleExpanded = (e) => {
    this.setState({
      expanded: !this.state.expanded
    });

    // Trigger expand/collapse animation
    const list = document.getElementById(this.listId);
    list.classList.toggle("active");
  }

  renderErrors(errors) {
    const list = errors && errors.map((error) => (<li>{error}</li>));

    return (
      <ul id={this.listId}>
        {list}
      </ul>
    );
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { errors } = this.props;
    const { expanded } = this.state;
    const icon = expanded ? <ChevronUpIcon /> : <ChevronDownIcon />;

    // Errors to show
    const { generic, detailed } = errors;

    return (
      <div className={CSS.main}>
        <h3>{generic}</h3>
        <button onClick={this.toggleExpanded}>
          {i18n.more.bookWorkspace.errors.moreDetails}
          {icon}
        </button>
        {this.renderErrors(detailed)}
      </div>
    );
  }
}