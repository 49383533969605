import React from "react";
import Context from "../../../context/Context";

import "@esri/calcite-components/dist/components/calcite-link";
import {
  CalciteLink
} from "@esri/calcite-components-react";

export async function queryGroups() {
  const orgId = Context.instance.user.portalUser.orgId;
  const portal = Context.instance.getPortal();
  const query = "orgid:" + orgId;
  const queryParams = {
    query: query,
    sortField: "title",
    sortOrder: "asc",
    num: 100
  };
  // @ts-ignore
  return portal.queryGroups(queryParams).then((response)=>{
    return response && response.results;
  })
}

export function removeEditFromUrl() {
  const lib = Context.instance.lib;
  const url = lib.esri.urlUtils.urlToObject(window.location.href);
  if (url && url.query && url.query.edit === "true") {
    delete url.query.edit;
    const href = lib.esri.urlUtils.addQueryParameters(url.path,url.query);
    window.history.replaceState({},"",href);
  }
}

export function renderDetailsLink(item) {
  if (!item) return null;
  const i18n = Context.instance.i18n;
  const portalUrl = Context.checkMixedContent(Context.instance.getPortalUrl());
  const href = portalUrl + "/home/item.html?id=" + encodeURIComponent(item.id);
  return (
    <CalciteLink href={href} target="_blank" rel="noopener noreferrer">
      {i18n.portalItemBrowser.details}
    </CalciteLink>
  )
}

