import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";

const TableContainer = styled.div`
  margin: 0 ${props => props.theme.baseline};
  padding: ${props => props.theme.baseline} 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody`
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  text-align: start;
  padding: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const Tr = styled.tr`
  background: ${props => props.theme.palette.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background: ${props => props.theme.palette.themeColorHighlight};
  }
  ${props =>
    props.listitem &&
    css`
      transition: background ${props => props.theme.transition};
      border-left: ${props =>
        props.moreButton ? "0" : `3px solid ${props.theme.palette.themeColor}`};
    `};
  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `};
`;

const Td = styled.td`
  padding: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

export { TableContainer, Table, Thead, Tbody, Th, Tr, Td };
