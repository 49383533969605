import styled from "styled-components";

import CalciteModal from "calcite-react/Modal";

const StyledModal = styled(CalciteModal)`
  & [class*="StyledModalContent"] {
    display: flex;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export { StyledModal, ContentContainer };
