import React from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";
import CalciteThemeProvider from "calcite-react/CalciteThemeProvider";
import { Theme } from "../../../theme";

import { getActiveSection } from "../../redux";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import PersonPopup from "./PersonPopup";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import UnitPopup from "./UnitPopup";
import Popup from "./Popup/Popup";
import * as component from "../../../components/util/component";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as hitTest from "../../../aiim/util/hitTest";
import * as mapUtil from "../../base/mapUtil";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as highlightUtil from "../../base/highlightUtil";
import * as sourceUtil from "../../base/sourceUtil";

let _corePopup2D, _node2D;
let _corePopup3D, _node3D;
let highlightHandle;

class ItemPopup extends React.Component {

  _fetchPromise;

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  _activateLevel(options) {
    if (options.zInfo) {
      const levels = Context.instance.aiim.datasets.levels;
      const facilityData = levels.getFacilityData(options.zInfo.facilityId);
      const levelData = options.zInfo.levelData;
      if (facilityData && levelData) {
        Topic.publish(Topic.ActivateLevel,{
          view: options.view,
          facilityData: facilityData,
          levelData: levelData
        });
      }
    }
  }

  componentDidMount() {
    this._init();
    component.own(this,[
      Topic.subscribe(Topic.RefreshUnitName,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    //console.log("ItemPopup.componentWillUnmount......................")
    component.componentWillUnmount(this);
  }

  _fetchThenShow(options) {
    const unitsSource = sourceUtil.getUnitsSource();
    const source = options.source;
    const featureItem = options.featureItem;
    const isUnit = source && source === unitsSource;
    let promise = this._fetchPromise = queryUtil.queryGeometry(source,featureItem);
    promise.then(result => {
      if (promise !== this._fetchPromise) return;
      this._fetchPromise = null;
      //console.log("queryGeometry.result",result);
      let geometry;
      if (result && result.features && result.features.length > 0) {
        geometry = result.features[0].geometry;
        if (geometry) {
          const graphic = Context.instance.lib.esri.Graphic.fromJSON({
             attributes: featureItem.feature.attributes,
             geometry: geometry
          });
          geometry = graphic.geometry;
          if (!options.zInfo) {
            const levels = Context.instance.aiim.datasets.levels;
            const zInfo = levels && levels.getZInfo(source,graphic);
            options.zInfo = zInfo;
          }
        }
      }
      if (!options.featureItem.geometry) options.featureItem.geometry = geometry;
      if (geometry) {
        options.location = geometry;
        if (isUnit) highlightUtil.highlightUnit("itempopup",options.featureItem.feature, options.view);
        else highlightUtil.removeHighlight();
        this._show(options);
      } else {
        //console.warn("Error querying geometry for",source.url,featureItem);
        //Topic.publishErrorAccessingData();
        this._show(options);
      }
    }).catch(ex => {
      if (promise !== this._fetchPromise) return;
      this._fetchPromise = null;
      console.error("Error querying geometry",ex);
      Topic.publishErrorAccessingData();
    });
  }

  _init() {
    component.own(this,[

      Topic.subscribe(Topic.CloseItemPopup,params => {
        if (_corePopup2D) {
          _corePopup2D.set("visible",false);
        }
        if (_corePopup3D) {
          _corePopup3D.set("visible",false);
        }
      }),

      Topic.subscribe(Topic.ShowItemPopup,options => {
        if (!options.view) {
          options.view = Context.instance.views.activeView;
        }
        highlightUtil.removeHighlight();
        if (options.fetchFeatureItemGeometry) {
          this._fetchThenShow(options)
        } else {
          this._show(options);
        }
        const backBtn = document.getElementById("sp-popup-back-btn")
        if (backBtn) {
          setTimeout(() => {backBtn.remove()}, 500)
        }
      }),

      Topic.subscribe(Topic.ViewClicked, params => {
        const plan = OfficePlan.getActive();
        const peopleOK = plan && plan.isPeopleLayerValid();
        const unitsOK = plan && plan.isUnitsLayerValid();
        if (!peopleOK || !unitsOK) return;
        const allowNonAiim = false;

        const dnd = Context.instance.spaceplanner && Context.instance.spaceplanner.dnd;
        const dndsp = dnd && dnd._dragInfo && dnd._dragInfo.subjectParams;
        if (dndsp && dndsp.isMoveOccupantPoint) return;

        hitTest.search(params.view,params.event,allowNonAiim).then(async hits => {
          //console.log("hitTest.................",hits)
          if (hits && hits.length > 0) {
            const hit = hits[0];
            // highlightUtil.highlightUnit(hits[0].feature, params.view);
            const layer = hit.source && hit.source.layer2D;
            const isPlanLayer = layer && layer.xtnAiim && layer.xtnAiim.isPlanLayer;
            const isUnit = !!(isPlanLayer&& hit.source && hit.source.isAiimUnits());
            const isPerson = !!(isPlanLayer && hit.source && hit.source.isAiimPeople());
            //console.log("hit",isUnit,isPerson,hit);
            //console.log("hitTest isUnit",isUnit,hit)

            // a MoveOccupants graphic
            if (hit.isMoveOccupantsLayer) {
              Topic.publish(Topic.MoveOccupants_GraphicClicked, {
                graphic: hit.feature,
                wasLongPress: params.wasLongPress
              })
              return;
            }

            if (isUnit || isPerson) {
              const attributes = hit.feature.attributes;
              const oidField = hit.source.layer2D.objectIdField;
              const oid = aiimUtil.getAttributeValue(attributes,oidField);
              const key = hit.source.key + "_" + oid;
              const featureItem = {
                key: key,
                objectId: oid,
                feature: hit.feature
              }
              let popup, name;
              if (isUnit) {
                const unitField = Context.instance.aiim.getUnitName();
                // code for unit popup on map click
                name = aiimUtil.getAttributeValue(attributes, unitField);

                // check for MoveOccupants
                const ismvo = (typeof oid === "number" && oid === Context.instance.session.activeMoveOccupantsUnitOID)
                if (ismvo) {
                  return;
                } else if (params.wasLongPress) {
                  const tab = this.props.activeSection;
                  const isViewOnly = (tab === "review" || tab === "publish")
                  const unitId = aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_ID);
                  const asnType = aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
                  if (!isViewOnly && unitId && asnType === OfficePlan.SpaceAssignmentTypes.office) {
                    const peopleAssigned = await officePlanUtil.queryPeopleAssignedToUnit(featureItem);
                    if (peopleAssigned && peopleAssigned.length > 0) {
                      Topic.publish(Topic.MoveOccupants_Show, {
                        unitFeatureItem: featureItem,
                        peopleAssigned: peopleAssigned,
                        mapEvent: hit.event
                      })
                      Topic.publish(Topic.CloseItemPopup, {})
                      return
                    }
                  }
                }

              } else {
                name = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_FULLNAME);
              }
              popup = <Popup sourceKey={hit.source.key} featureItem={featureItem} isPerson={isPerson} 
                onTitleChange={title => {
                  if (title && _corePopup2D) _corePopup2D.title = title;
                }}
              />
              const options = {
                view: Context.instance.views.activeView,
                title: name,
                source: hit.source,
                featureItem: featureItem,
                fetchFeatureItemGeometry: true,
                zoom: false,
                content: popup
              }
              this._fetchThenShow(options);
            }
          }
        }).catch(ex => {
          console.log("Mapclick error:");
          console.error(ex);
        });
      }),

    ]);
  }

  render() {
    return (<div></div>);
  }

  _show(options) {
    this._fetchPromise = null;
    options = options|| {};
    const lib = Context.instance.lib;
    const view = options.view;
    const viewType = view.type;

    // location: event.mapPoint view.center
    let location = options.location;
    //let location = view.center;
    //

    this._unmount(viewType);

    const node = document.createElement("div");
    node.setAttribute("class","i-itempopup");
    if (options.content) {
      ReactDOM.render((
        <Provider store={Rdx.store}>
          <CalciteThemeProvider theme={Theme}>
            {options.content}
          </CalciteThemeProvider>
        </Provider>
      ), node);
    }

    let popup;
    if (view.type === "3d") {
      popup = _corePopup3D;
      _node3D = node;
    } else {
      popup = _corePopup2D;
      _node2D = node;
    }
    if (!popup) {
      popup = new lib.esri.Popup({
        container:"item-popup",
        view: view
      });

      // Remove "zoom to" on info card for unit. occupant, workspace area #8216
      popup.viewModel.includeDefaultActions = false;

      if (view.type === "3d") _corePopup3D = popup;
      else _corePopup2D = popup;

      popup.watch("visible",(newVal,oldVal,property,object) => {
        //console.log("popup.watch.visible",newVal)
        if (newVal === false) {
          this._unmount(viewType);
          highlightUtil.removeHighlight();
        }
      });
    }

    /*
    dockEnabled: true,
    docOptions: {
      position: "top-center"
    },
     */

    // let prop = "_corePopup"+view.type.toUpperCase();
    // if (!this[prop]) {
    //   this[prop] = new lib.esri.Popup({
    //     container:"item-popup",
    //     dockEnabled: true,
    //     docOptions: {
    //       position: "top-center"
    //     },
    //     view: view
    //   });
    // }
    // const popup = this[prop];

    // ReactDOM.unmountComponentAtNode(container)

    // let is3D = view.type === "3d";
    // let popup =
    // if (!this._corePopup) {
    //   this._corePopup = new lib.esri.Popup({
    //     container:"item-popup",
    //     view: view
    //   });
    // }
    // const popup = this._corePopup;
    //if (popup.view !== view) popup.view = view;
    //
    //
    /*
    dockOptions: {
      breakpoint: false,
      buttonEnabled: false,
      position: "bottom-right"
    }
     */

    // if (!location) {
    //   popup.set("docEnabled",false);
    //   popup.set("dockOptions", {
    //     breakpoint: false,
    //     buttonEnabled: false,
    //     position: "top-center"
    //   });
    // } else {
    //   popup.set("docEnabled",true);
    //   popup.set("dockOptions", {
    //     breakpoint: false,
    //     buttonEnabled: true,
    //     position: "auto"
    //   });
    // }

    let pointLocation = location;
    if (location && location.centroid) {
      pointLocation = location.centroid;
    }
    let popupOptions = {
      title: options.title || "",
      content: node,
      location: pointLocation,
      visible: true
    };
    popup.autoOpenEnabled = false;
    popup.collapseEnabled = false;
    popupOptions.actions = [];


    if (location) {
      popup.set("dockEnabled",false);
      popup.set("dockOptions", {
        breakpoint: false,
        buttonEnabled: false,
        position: "top-center"
      });
    } else {
      popup.set("dockEnabled",true);
      popup.set("dockOptions", {
        breakpoint: false,
        buttonEnabled: false,
        position: "top-center"
      });
    }

    //console.log("location.................",location)
    Topic.publish(Topic.ItemPopupOpening, {})
    popup.open(popupOptions);

    // popup.goToOverride = (view, goToParams) => {
    //   //goToParams.options.duration = updatedDuration;
    //   //console.log("goToParams",goToParams)
    //   return view.goTo(goToParams.target, goToParams.options);
    // };
    // shouldn't have to do this, 4.13 core bug?
    popup.on("trigger-action",event => {
      console.log("trigger-action",event)
      popup.viewModel.zoomToLocation(); //
    });

    if (options.zoom) mapUtil.goToGeometry(view,location,"unit_level");

    setTimeout(() => {
      this._activateLevel(options);
    },300);

    console.log("popup", options);

    // setTimeout(() => {
    //   console.log("popoppppppppppppp",popup)
    // },5000);
    //

  }

  _unmount(viewType) {
    if (viewType === "2d") {
      if (_node2D) {
        ReactDOM.unmountComponentAtNode(_node2D);
        _node2D = null;
      }
    }
    if (viewType === "3d") {
      if (_node3D) {
        ReactDOM.unmountComponentAtNode(_node3D);
        _node3D = null;
      }
    }
  }

  // static _init() {
  //   Topic.subscribe(Topic.CloseItemPopup,params => {
  //     if (_corePopup2D) {
  //       _corePopup2D.set("visible",false);
  //     }
  //     if (this._corePopup3D) {
  //       _corePopup3D.set("visible",false);
  //     }
  //   })
  // }

  // static show(options) {
  //   options = options|| {};
  //   const lib = Context.instance.lib;
  //   const view = Context.instance.views.activeView;
  //
  //   let popup;
  //   if (view.type === "3d") popup = _corePopup3D;
  //   else popup = _corePopup2D;
  //   if (!popup) {
  //     popup = new lib.esri.Popup({
  //       container:"item-popup",
  //       dockEnabled: true,
  //       docOptions: {
  //         position: "top-center"
  //       },
  //       view: view
  //     });
  //     if (view.type === "3d") _corePopup3D = popup;
  //     else _corePopup2D = popup;
  //   }
  //
  //   // let prop = "_corePopup"+view.type.toUpperCase();
  //   // if (!this[prop]) {
  //   //   this[prop] = new lib.esri.Popup({
  //   //     container:"item-popup",
  //   //     dockEnabled: true,
  //   //     docOptions: {
  //   //       position: "top-center"
  //   //     },
  //   //     view: view
  //   //   });
  //   // }
  //   // const popup = this[prop];
  //
  //   const node = document.createElement("div");
  //   node.setAttribute("class","i-itempopup");
  //   if (options.content) {
  //     ReactDOM.render(options.content,node);
  //   }
  //
  //   // let is3D = view.type === "3d";
  //   // let popup =
  //   // if (!this._corePopup) {
  //   //   this._corePopup = new lib.esri.Popup({
  //   //     container:"item-popup",
  //   //     view: view
  //   //   });
  //   // }
  //   // const popup = this._corePopup;
  //   //if (popup.view !== view) popup.view = view;
  //   //
  //   //
  //   /*
  //   dockOptions: {
  //     breakpoint: false,
  //     buttonEnabled: false,
  //     position: "bottom-right"
  //   }
  //    */
  //
  //   // location: event.mapPoint view.center
  //
  //   let location = options.location;
  //   //let location = view.center;
  //
  //   popup.autoOpenEnabled = false;
  //   popup.set("dockOptions", {
  //     breakpoint: true,
  //     buttonEnabled: true,
  //     position: "top-center"
  //   });
  //   popup.open({
  //     title: options.title || "",
  //     content: node,
  //     location: location,
  //     visible: true
  //   });
  //   console.log("qqq",popup)
  //   // popup.zoomToLocation = () => {
  //   //   console.log("qqqqqqqqqqqqzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz")
  //   //   return Promise.resolve();
  //   // };
  //   popup.goToOverride = (view, goToParams) => {
  //     //goToParams.options.duration = updatedDuration;
  //     console.log("goToParams",goToParams)
  //     return view.goTo(goToParams.target, goToParams.options);
  //   };
  //   popup.on("trigger-action",event => {
  //     console.log("trigger-action",event)
  //     mapUtil.goToGeometry(view,location)
  //     //popup.viewModel.zoomToLocation();
  //   });
  //   mapUtil.goToGeometry(view,location)
  //
  // }

}

const mapStateToProps = (state) => ({
  activeSection: getActiveSection(state)
})
export default connect(mapStateToProps)(ItemPopup);