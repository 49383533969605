import React from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";

import Context from "../../../context/Context";
import NewPlan from "./NewPlan/NewPlan";
import OpenPlan from "../../miniapps/Switcher/OpenPlan/OpenPlan";
import RecentPlans from "../../miniapps/Switcher/OpenPlan/RecentPlans";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

import {
  setMiniApp,
  getMiniAppSwitcherActive,
  setMiniAppSwitcherActive,
  ISpacePlannerState
} from "../../redux";
import Rdx from "../../../redux/Rdx";

import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-panel";
import { CalciteLabel, CalciteButton, CalcitePanel } from "@esri/calcite-components-react";

interface Props {
  switcherIsActive: boolean;
  setMiniApp?: (app: ISpacePlannerState["SPACEPLANNER_MINI_APP"]) => void;
  setSwitcherActive?: (active: boolean) => void;
}

interface State {
  newPlanOpen: boolean;
}

class Switcher extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      newPlanOpen: false
    };
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.ShowBackstage, (params) => {
        if (params && params.tabKey === "openPlan") {
          this.openPlanClicked(null,true);
        }
      }),
    ]);
  }

  deactivate = () => {
    this.props.setSwitcherActive(false);
  };

  newPlanClicked = () => {
    this.setState({ newPlanOpen: true }, this.deactivate);
  };

  openPlanClicked = (e?,isStartup?: boolean) => {
    this.deactivate();
    const node = document.createElement("div");
    document.body.appendChild(node);
    ReactDOM.render(
      <Provider store={Rdx.store}>
        <div className="i-modal-working-overlay i--minimal i-intermediate i-open-plan">
          <OpenPlan
            isStartup={!!isStartup}
            onClose={() => {
              if (node && node.parentNode) {
                node.parentNode.removeChild(node);
                ReactDOM.unmountComponentAtNode(node);
              }
            }}
          />
        </div>
      </Provider>,
      node
    );
  };

  render() {
    const i18n = Context.instance.i18n;
    const active = !!this.props.switcherIsActive;
    const plan = Context.instance.spaceplanner.activePlan;
    let editorDisabled = !plan || !plan.detailsLayer;
    const assignmentsDisabled = true; // for beta

    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    const planner = Context.instance.spaceplanner.planner;
    if (cfgOn && editorDisabled && planner && planner.hasValidProject() && planner.hasProjectDetailsLayer()) editorDisabled = false;

    let editorStyle = {
      padding: "0 0.5rem",
      cursor: "pointer"
    };

    if (editorDisabled) editorStyle.cursor = "default";
    const modalClass = active
      ? "i-modal-working-overlay i-intermediate"
      : "i-modal-working-overlay i-intermediate i-modal-working-overlay-closed";
    const switcherClass = active
      ? "i-miniapps-switcher i-miniapps-switcher-open"
      : "i-miniapps-switcher";
    return (
      <>
        <div className={modalClass}></div>
        <div className={switcherClass}>
          <div className="i-miniapps-switcher-top">
            {this.renderHeader()}
            <CalcitePanel>
              <div style={{borderTop: "1px solid #DFDFDF", padding: "1rem"}}>
                <div className="i-flex-between">
                  <CalciteLabel>
                    <span className="i-medium-weight-switcher">
                      {i18n.switcher.recentPlans.caption}
                    </span>
                  </CalciteLabel>
                  {Context.instance.user.canCreatePlan() &&
                    <CalciteButton
                      appearance="outline"
                      icon-start="plus"
                      style={{width:"82px", height:"32px"}}
                      scale="m"
                      disabled={!Context.instance.user.canCreatePlan() ? true : undefined}
                      onClick={this.newPlanClicked}
                      icon-flip-rtl="both"
                      >
                      <span>{i18n.switcher.newPlan.label}</span>
                    </CalciteButton>
                  }
                </div>
              </div>
              <div style={{paddingLeft: "0.25rem", paddingRight: "0.25rem"}}>{active && <RecentPlans minimal={true} />}</div>
            </CalcitePanel>
          </div>
          <div className="i-all-plans-switcher-div">
            <div className="i-all-plans-switcher-btn">
              <div></div>
              <CalciteButton
                appearance="outline"
                onClick={this.openPlanClicked}
              >
                <span>{i18n.switcher.allPlans}</span>
              </CalciteButton>
            </div>
          </div>
        </div>
        {this.state.newPlanOpen && this.renderNewPlan()}
      </>
    );
  }

  renderHeader = () => {
    const activePlan = Context.instance.spaceplanner.activePlan;
    const planTitle = (activePlan && activePlan.title) || Context.instance.i18n.switcher.spaceplanner;
    return this.props.switcherIsActive ? (
      <CalcitePanel className="i-title-container"
        onCalcitePanelClose={this.deactivate}
        closable={true}
      >
        <div className="i--plan--title" slot="header-content" title={planTitle}>
          <h3>{planTitle}</h3>
        </div>
      </CalcitePanel>
    ) : null;
  }

  renderNewPlan() {
    return <NewPlan isSaveAs={false} onClose={() => this.setState({ newPlanOpen: false })} />;
  }

  setApp = (app: ISpacePlannerState["SPACEPLANNER_MINI_APP"]) => {
    this.props.setMiniApp(app);
    this.deactivate();
  };
}

const mapStateToProps = (state) => ({
  switcherIsActive: getMiniAppSwitcherActive(state)
});

const mapDispatchToProps = (dispatch) => ({
  setMiniApp: (app: ISpacePlannerState["SPACEPLANNER_MINI_APP"]) => dispatch(setMiniApp(app)),
  setSwitcherActive: (active: boolean) => dispatch(setMiniAppSwitcherActive(active))
});

export default connect(mapStateToProps, mapDispatchToProps)(Switcher);
