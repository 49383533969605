import React from "react";

import { connect } from "react-redux";
import { mapDispatchToProps } from "../../../redux/Rdx";

import AreaStatsModel, { IAreaStats } from "./AreaStatsModel";
import Context from "../../../context/Context";
import GraphicStat from "./GraphicStat";
import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import { getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled } from "../../redux";

import {
  StatsWrapper,
  GroupIcon,
  HotDeskIcon,
  MeetingRoomIcon,
  PersonIcon
} from "../../styles/statsStyles";

interface IAreaStatsProps {
  areaItem: __esri.Graphic,
  rdxHotdesksEnabled: boolean,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean
}

interface IAreaStatsState {
  areaStats: IAreaStats
}

class AreaStats extends React.Component<IAreaStatsProps, IAreaStatsState> {
  model: AreaStatsModel;
  _mounted = false;

  constructor(props: IAreaStatsProps) {
    super(props);
    this.state = component.newState({
      areaStats: null
    });
    this.model = new AreaStatsModel({});
  }

  componentDidMount() {
    this._mounted = true;
    this.refreshStats();
    component.own(this, [
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.refreshStats();
        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  refreshStats = () => {
    const areaItem = this.props.areaItem;
    this.model.queryStats(areaItem).then((areaStats: IAreaStats) => {
      if (!this._mounted) return;
      this.setState({areaStats});
    }).catch(ex => {
      console.warn("Error refreshing area stats for", areaItem);
      console.error(ex);
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const { areaStats } = this.state;
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;

    const peopleCount = areaStats ? areaStats.peopleCount : 0;
    const hotDeskCount = areaStats ? areaStats.hotDeskCount : 0;
    const meetingRoomCount = areaStats ? areaStats.meetingRoomCount : 0;
    let hotelCapacity: any = areaStats ? areaStats.hotelCapacity : 0;

    let peopleDanger = false;
    if (typeof hotelCapacity === "number") {
      if ((hotelCapacity > 0) && (peopleCount > hotelCapacity)) peopleDanger = true;
    } else {
      hotelCapacity = 0;
    }

    return (
      <StatsWrapper key="a" className="i-areastats i-areastats-graphics">
        {rdxHotdesksEnabled &&
          <GraphicStat
            key="hotDesks"
            icon={<HotDeskIcon />}
            label={i18n.spaceplanner.areaStats.hotDesks}
            value={hotDeskCount}
          />
        }
        {rdxHotelsEnabled &&
          <GraphicStat
            key="hotelCapacity"
            icon={<GroupIcon />}
            label={i18n.spaceplanner.areaStats.hotelCapacity}
            value={hotelCapacity}
          />
        }
        {rdxMeetingRoomsEnabled &&
          <GraphicStat
            key="meetingRooms"
            icon={<MeetingRoomIcon />}
            label={i18n.spaceplanner.areaStats.meetingRooms}
            value={meetingRoomCount}
          />
        }
        <GraphicStat
          key="people"
          icon={<PersonIcon />}
          label={i18n.spaceplanner.areaStats.people}
          value={peopleCount}
          danger={peopleDanger}
        />
      </StatsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdxHotdesksEnabled: getHotdesksEnabled(state),
    rdxHotelsEnabled: getHotelsEnabled(state),
    rdxMeetingRoomsEnabled: getMeetingRoomsEnabled(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaStats);
