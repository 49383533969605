import React from "react";

import AssetBar from "../Assets/AssetBar";
import Configurator from "../Configurator/Configurator";
import Context from "../../../context/Context";
import Header from "./Header";
import ItemPopup from "../common/ItemPopup";
import Map from "./Map";
import MoveOccupants from "../MoveOccupants/MoveOccupants";
import Topic from "../../../context/Topic";
import WorkBar from "../Work/WorkBar";
import * as component from "../../../components/util/component";

import { Container } from "../../styles/App/layout";
import ReactTooltip from "react-tooltip";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.ConfiguratorActivated, params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ConfiguratorClosed, params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    let configurator = null;
    if (cfgOn) {
      configurator = <Configurator />;
    }
    return (
      <div style={{width:"100%",height:"100%",display:"flex"}}>
        {configurator}
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
          <Header />
          <Container cfgOn={cfgOn}>
            <WorkBar />
            <div style={{position:"relative",width:"100%",height:"100%"}}>
              <Map />
              <MoveOccupants />
            </div>
            <AssetBar />
            <ReactTooltip id="genericTooltip" place="bottom" type="dark" effect="solid" />
          </Container>
          <ItemPopup />
        </div>
      </div>
    );
  }
}
