import Context from "../../context/Context";
import * as aiimUtil from "./aiimUtil";

export function queryFieldValue(source, url, field, value, isObjectId, gdbVersion, opts) {
  if (typeof value === undefined || value === null) {
    return Promise.resolve(null);
  }

  let isNumericField, isStringField;
  if (source && source.layer2D && field) {
    const fld = aiimUtil.findField(source.layer2D.fields,field)
    if (fld) {
      const ft = fld.type;
      if (ft === "small-integer" || ft === "integer"|| ft === "long" ||
          ft === "single" || ft === "double" || ft === "oid") {
        isNumericField = true;
      } else if (ft === "string" || ft === "guid"|| ft === "global-id") {
        isStringField = true;
      }
      // others: date geometry blob raster xml
    }
  }

  const lib = Context.getInstance().lib;
  url = Context.checkMixedContent(url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.outFields = ["*"];
  const view = Context.getInstance().views.mapView;
  if (view) query.outSpatialReference = view.spatialReference;
  query.returnGeometry = true;
  query.returnZ = true;
  query.multipatchOption = "xyFootprint";
  if (isObjectId) {
    query.objectIds = [value];
  } else {
    if (isNumericField) {
      query.where = field + " = " + value;
    } else if (isStringField && typeof value === "string") {
      value = value.replace("'","''");
      if (opts && opts.caseInsensitive) {
        query.where = "UPPER(" + field + ") = '" + value.toUpperCase() + "'";
      } else {
        query.where = field + " = '" + value + "'";
      }
    } else if (typeof field === "string" && field.toLowerCase() === "objectid") {
      query.where = field + " = " + value;
    } else if (typeof value === "string" ) {
      value = value.replace("'","''");
      query.where = field + " = '" + value + "'";
    } else {
      query.where = field + " = " + value;
    }
  }
  if (gdbVersion) query.gdbVersion = gdbVersion;
  //console.log("*********************",field,isNumericField,isStringField)
  //console.log("query.whereeeeeeeeeeeee",query.where)
  const promise = task.execute(query);
  promise.then(result => {
    if (result && result.features && result.features.length > 0) {
      const feature = result.features[0];
      feature.xtnFields = result.fields;
      if (source) {
        feature.xtnSourceUrl = url;
        feature.xtnSource = source;
      }
    }
  });
  return promise;
}

// export function queryFieldValue(source, url, field, value, isObjectId, gdbVersion) {
//   if (typeof value === undefined || value === null) {
//     return Promise.resolve(null);
//   }
//   const lib = Context.getInstance().lib;
//   url = Context.checkMixedContent(url);
//   const task = new lib.esri.QueryTask({url: url});
//   const query = new lib.esri.Query();
//   query.outFields = ["*"];
//   const view = Context.getInstance().views.mapView;
//   if (view) query.outSpatialReference = view.spatialReference;
//   query.returnGeometry = true;
//   query.returnZ = true;
//   query.multipatchOption = "xyFootprint";
//   if (isObjectId) {
//     query.objectIds = [value];
//   } else {
//     if (typeof field === "string" && field.toLowerCase() === "objectid") {
//       query.where = field + " = " + value;
//     } else if (typeof value === "string" ) {
//       value = value.replace("'","''");
//       query.where = field + " = '" + value + "'";
//     } else {
//       query.where = field + " = " + value;
//     }
//   }
//   if (gdbVersion) query.gdbVersion = gdbVersion;
//   const promise = task.execute(query);
//   promise.then(result => {
//     if (result && result.features && result.features.length === 1) {
//       const feature: any = result.features[0];
//       feature.xtnFields = result.fields;
//       if (source) {
//         feature.xtnSourceUrl = url;
//         feature.xtnSource = source;
//       }
//     }
//   });
//   return promise;
// }
