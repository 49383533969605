
export function findTarget(nodes,targetNode,isTabShift,upKey) {
  let isUpKey = false, isDownKey = false;
  if(upKey === "previous") isUpKey = true;
  const info = {
    index: -1,
    nextIndex: -1,
    previousIndex: -1,
    node: null
  };
  if (nodes && nodes.length > 0 && targetNode) {
    let last = (nodes.length - 1);
    for (let i=0; i<nodes.length; i++) {
      let node = nodes[i];
      if (node === targetNode) {
        info.node = node;
        if(!isTabShift && !isUpKey && i === last) break;
        if(isTabShift && i === 0) break;
        if(isUpKey && i === 0) break;
        info.index = i;
        info.previousIndex = (i === 0)? last: i - 1;
        info.nextIndex = (i === last)? 0: i + 1;
        break;
      }
    };
  }
  return info;
}

export function focusFirst(nodes) {
  focusIndex(nodes,0);
}

export function focusIndex(nodes,index) {
  if (!nodes) return;
  focusNode(nodes[index]);
}

export function focusNode(node) {
  if (node && typeof node.focus === "function") {
    node.focus();
    if (node.tagName.toUpperCase() === "INPUT") node.select();
  }
}
