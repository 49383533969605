import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { IFilterCriteria } from "../../common/types";
import UniqueValues from "./UniqueValues";
import * as component from "../../../../components/util/component";
import * as filterUtil from "./filterUtil";

interface Props {
  filterCriteria: IFilterCriteria;
  layerType: string;
  levelIdSubset?: any;
  onChange: (value?: string) => void;
}

interface State {
  uniqueValues: string[];
}

class SiteFilter extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const fc = this.props.filterCriteria;
    const uniqueValues = filterUtil.getSites(fc,this.props.levelIdSubset);
    this.state = component.newState({
      uniqueValues
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    const fc = this.props.filterCriteria;
    const subset = this.props.levelIdSubset;
    if (prevProps.filterCriteria !== fc || prevProps.levelIdSubset !== subset) {
      const uniqueValues = filterUtil.getSites(fc,subset);
      this.setState({uniqueValues});
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { uniqueValues } = this.state;
    const sid = this.props.filterCriteria.siteFacilityLevel.siteId;
    const values = sid ? [sid] : [];
    if (uniqueValues) {
      return (
        <UniqueValues 
          label={i18n.miniapps.assignments.filter.site}
          uniqueValues={uniqueValues} 
          values={values}
          onChange={values => {
            this.props.onChange(values[0] || null);
          }}
        />
      )
    }
    return null;
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(SiteFilter);
