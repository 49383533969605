import styled from "styled-components";

const Container = styled.div`
  position: relative;
  z-index: ${props => props.theme.levels.top};
  width: ${props => (props.active ? props.theme.sidePanelWidth : "0")};
  height: 100%;
  box-shadow: ${props => props.theme.boxShadow};
`;

export { Container };
