import React, {createRef} from "react";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as tsUtil from "../../../util/tsUtil";
import { findFieldName, getAttributeValue } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { ModalController } from "../../../common/components/Modal";
import {
  assignmentsFound,
  findDuplicateRecords,
  findUserReservations,
  getAssignments,
  getLKL,
  queryTrackingInfo,
  showMultipleAssignmentsModal,
  showMultipleAssignments
} from "../../../util/multipleAssignmentsUtil";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from "../../../spaceplanner/base/ReviewerManagement/reviewersUtil";

const CSS = {
  main: "i-searchbox",
};

export default class Searchbox extends React.Component {

  _coreNode;
  _coreWidget;
  nodeRef = createRef();
  _componentId;

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.setCoreNode = this.setCoreNode.bind(this);
    this.globalListener= this.globalListener.bind(this);
    this._componentId = component.nextId("search-item-");
  }

  componentDidMount () {
    try {
      this._initWidget();
      const node = this.nodeRef && this.nodeRef.current;
      const context = Context.getInstance();
      if(node){
        let searchNode = node.querySelector(".esri-search__sources-button.esri-widget--button");
        if (searchNode) {
          searchNode.setAttribute("aria-label", context.i18n.search.ariaLabel);
        }
      }
      component.own(this,[
        Topic.subscribe(Topic.ViewActivated,params => {
          if (this._coreWidget) {
            this._coreWidget.view = params.view;
          }
        }),

        Topic.subscribe(Topic.InfoPanelClosed, params => {
          if (this._componentId === params.componentId) {
            try {
              this._coreWidget.focus();
            } catch(ex) {
              console.error("Searchbox - focus error:",ex);
            }
          }
        })
      ]);
    } catch(ex) {
      console.error("Error initializing Searchbox",ex);
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.globalListener);
    component.componentWillUnmount(this);
  }

  globalListener(event){
    let widget = this._coreWidget;
    if(!widget) return;
    if(event && event.target && event.target.className === "esri-input esri-search__input"){
    }else{
      if(widget.activeMenu === "suggestion") widget.activeMenu= "none";
    }
  }

   _initWidget () {
    const context = Context.getInstance();
    const view = (context.views && context.views.activeView);
    const aiim = context.aiim;
    const categories = aiim && aiim.datasets && aiim.datasets.categories;
    const sources = categories && categories.makeSearchSources();
    if (this._coreNode && view && sources && sources.length > 0) {
      this._coreWidget = new context.lib.indoors.CustomSearch({
        indoorsContext: context,
        tsUtil: tsUtil,
        container: this._coreNode,
        view: view,
        allPlaceholder: context.i18n.search.allPlaceholder,
        sources: sources,
        includeDefaultSources: false,
        locationEnabled: false,
        mainSearchbox: true
      });

      component.own(this,this._coreWidget.on("select-result",event => {
        const source = context.aiim.datasets && context.aiim.datasets.categories.findSourceByKey(event.source.aiimKey);
        if (source) {
          if (source.isPeopleLayer()) {
            this.onPersonSelected(source, event.result);
          } else {
            this.showSearchResult(source, event.result);
          }
          try {
            if (context.uiMode.isTouch) {
              this._coreWidget.blur();
            }
          } catch(ex) {
            console.error("Searchbox - blur error:",ex);
          }
        } else {
          console.warn("Search - Unable to find AIIM source by name for:",event);
        }
      }));

      try {
        // Search: drop-down goes behind the map in Safari. #1763
        // switch the dropdown to position: fixed
        let fix = false, fixMinWidth = "200px";
        let uiMode = Context.instance.uiMode;
        let isMac = (navigator.platform && /MacIntel/.test(navigator.platform));
        if (!uiMode.isKiosk && (uiMode.isIOS || isMac)) {
          fix = true;
        }
        // let ua = navigator.userAgent.toLowerCase();
        // let isSafari = (ua.indexOf("safari") !== -1);
        // if (ua.indexOf("safari") !== -1) {
        //   let isMac = (navigator.platform && /MacIntel/.test(navigator.platform));
        //   if (!uiMode.isKiosk && (isMac || uiMode.isIOS)) {
        //     fix = true;
        //   }
        // }
        if (fix) {
          component.own(this,this._coreWidget.on("suggest-complete",event => {
            setTimeout(() => {
              try {
                let nd = document.body.querySelector(".i-searchbox .esri-search__suggestions-menu");
                let nd2 = document.body.querySelector(".i-searchbox .esri-search__form");
                let nd3 = document.body.querySelector(".i-searchbox .esri-search.esri-widget");

                if (nd && nd2 && nd3) {
                  let rect2 = nd2.getBoundingClientRect();
                  let rect3 = nd3.getBoundingClientRect();
                  nd.style.position = "fixed";
                  nd.style.width = "auto";
                  nd.style.minWidth = fixMinWidth;
                  nd.style.left = rect3.left+"px";
                  if (context.uiMode.isRtl) {
                    let r = window.innerWidth - rect3.right;
                    nd.style.right = r+"px";
                  }
                  nd.style.top = (rect2.top+rect2.height)+"px"
                  nd.style.zIndex = "4";
                }
              } catch(ex2) {
                console.warn("Shifting suggestions menu...");
                console.error(ex2);
              }
            },500);
          }));
        }
      } catch(ex) {
        console.error(ex);
      }
    }

    if(this._coreWidget){
      document.body.addEventListener("click",this.globalListener);
    }
  }

  /**
   * Determine whether to show the multiple assignments experience or not
   *
   * @param {Source} source Dataset source
   * @param {any} person Selected person
   */
  onPersonSelected = async (source, person) => {
    const layer = source && source.layer2D;
    const personAttributes = person && person.feature && person.feature.attributes;

    const knownas = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME);

    // See if the user has ongoing and/or upcoming reservations
    const upcomingReservations = await findUserReservations(person, "upcoming");
    const pastReservation = await findUserReservations(person, "past");

    try {
      const results = await findDuplicateRecords(layer, personAttributes);
      const features = results && results.features;
      let lkl = null;
      const lklFeature = getLKL(features);
      if (lklFeature) {
        const ti = await queryTrackingInfo(features);
        if (ti && ti.feature) {
          lkl = lklFeature;
        }
      }

      const showModal = showMultipleAssignmentsModal(features, pastReservation, upcomingReservations, lkl, false);

      if (!showModal) {
        this.showSearchResult(source, person, upcomingReservations);
      } else {
        const assignments = await getAssignments(features);
        if (assignmentsFound(assignments) || upcomingReservations.length > 0) {
          showMultipleAssignments({
            assignments,
            personFeatures: features,
            title: knownas,
            pastReservation,
            upcomingReservations,
            hasLKL: !!lkl
          });
        } else {
          this.showSearchResult(source, person, upcomingReservations);
        }
      }
    } catch (e) {
      console.error(e);
      this.showSearchResult(source, person, upcomingReservations);
    }
  }

  showSearchResult = (source, person, upcomingReservations) => {
    // If the only location is a reservation, zoom to the unit but show the person info panel
    if (upcomingReservations && upcomingReservations.length === 1) {
      const reservation = upcomingReservations[0];
      const unit = reservation && reservation.unit;
      if (unit && unit.geometry && person && person.feature) {
        const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
        const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;

        const unitsFloorField = units && units.layer2D && units.layer2D.floorInfo && units.layer2D.floorInfo.floorField;
        const levelId = unitsFloorField && getAttributeValue(unit.attributes, unitsFloorField);
        const peopleFloorField = people && people.layer2D && people.layer2D.floorInfo && people.layer2D.floorInfo.floorField;

        // Set the geometry and level id
        person.feature.geometry = unit.geometry;
        if (levelId && peopleFloorField) {
          person.feature.attributes[peopleFloorField] = levelId;
        }
      }
    }

    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: person,
      zoom: true,
      highlight: true,
      trackRecent: true,
      componentId: this._componentId
    });
  }

  render(){
    return (
      <div ref={this.nodeRef} className={CSS.main} >
        <div ref={this.setCoreNode}></div>
      </div>
    );
  }

  setCoreNode(node) {
    this._coreNode = node;
  }

}
