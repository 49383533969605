import Context from "../../../../context/Context";
import { updateGrossArea, updateZ } from "../support/editorUtil";
import { getDetailsLayer } from "../../../base/sourceUtil";
import { createPolygon, getExteriorRings, getDetailsLayerView } from "../../../base/mapUtil";
import { mergeFeatures } from "../../../base/transaction/transactions";
import { buffer, calculateLength, getGeometryServiceUrl } from "../../../../util/geoUtil";
import TransactionGuard from "../../../base/TransactionGuard";
import Topic from "../../../../context/Topic";
import BaseVM from "../support/BaseVM";
import { getAttributeValue } from "../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { LayerType } from "../../../../util/interfaces";

const PREVIEW_COLOR: [number, number, number, number] = [0, 255, 255, 1];
export default class MergeVM extends BaseVM {

  private layerId = "merge-outcome";
  private layer: __esri.GraphicsLayer;
  private selectedLineSymbol: __esri.SimpleLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol({
    style: "solid",
    width: 2,
    color: [0, 255, 255, 1]
  });
  private selectedFillSymbol: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    style: "solid",
    color: PREVIEW_COLOR,
    outline: this.selectedLineSymbol
  });
  private length: number = 0;
  private gapSymbol: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    style: "solid",
    color: [0, 128, 128, .5],
    outline: { style: "solid", color: [0, 128, 128, 1], width: 2 }
  });

  constructor() {
    super();
    const view = this.getView();
    if (view != null) {
      this.layer = this.ensureGraphicsLayer(this.layerId);
    } else {
      const loaded: __esri.WatchHandle = Topic.subscribe(Topic.ViewActivated, ({ view }: { view: __esri.MapView | __esri.SceneView }) => {
        this.layer = this.ensureGraphicsLayer(this.layerId);
        loaded.remove();
      });
    }
  }

  private createPolygonFromPoints(points: __esri.Point[], sr: __esri.SpatialReference): __esri.Polygon {
    const { lib } = Context.getInstance();
    if (points.length >= 3) {
      return new lib.esri.Polygon({
        rings: [points.concat(points[0]).map(point => ([point.x, point.y]))],
        spatialReference: sr.clone()
      })
    } else {
      return null;
    }
  }
  private createPolylineFromPoints(points: __esri.Point[], sr: __esri.SpatialReference): __esri.Polyline {
    const { lib } = Context.getInstance();
    return new lib.esri.Polyline({
      spatialReference: sr.clone(),
      paths: points.map(point => ([point.x, point.y, point.z]))
    });
  }
  private createPolylineFromPolygon(polygon: __esri.Polygon): __esri.Polyline {
    const { lib } = Context.getInstance();
    return new lib.esri.Polyline({
      spatialReference: polygon.spatialReference.clone(),
      paths: polygon.rings
    });
  }

  // generates an array of points that connect two polygon rings together
  // given a max tolerance for the distance between the rings.
  private async createSegmentPoints(
    ring: number[][],
    otherRing: number[][],
    spatialReference: __esri.SpatialReference,
    tolerance: number,
    units: __esri.LinearUnits
  ) {
    const { lib } = Context.getInstance();
    const engine: typeof __esri.geometryEngine = lib.esri.geometryEngine;
    const segments: __esri.Point[] = [];

    // check each vertex against the other ring
    for await (const coordinates of ring.slice(0, -1)) {
      const vertex: __esri.Point = new lib.esri.Point({
        x: coordinates[0],
        y: coordinates[1],
        spatialReference: spatialReference.clone()
      });
      const nearest = engine.nearestCoordinate(createPolygon([otherRing], spatialReference.wkid), vertex);
      if (nearest && nearest.coordinate) {
        // construct a polyline and calculate length
        const segment = [vertex, nearest.coordinate];
        const line = this.createPolylineFromPoints(segment, spatialReference);
        const length = await calculateLength(line, units);
        if (length <= tolerance) {
          segments.push(...segment);
        }
      }
    }
    return segments;
  }
  private async dissolveWalls(newUnit: __esri.Graphic, gaps: __esri.Polygon, originalUnits: __esri.Graphic[]) {
    const { lib } = Context.getInstance();
    const engine: __esri.geometryEngine = lib.esri.geometryEngine;
    const newWalls: __esri.Graphic[] = [];
    const originalWalls: __esri.Graphic[] = [];
    const z = (newUnit.geometry as __esri.Polygon).rings[0][0][2];

    // check for gaps
    if (gaps) {
      // step 1. buffer the new gap to handle cases where walls are not snapped to units
      const bufferPolygon = await buffer(gaps, .1);
      const gapBuffer = engine.intersect(bufferPolygon, newUnit.geometry) as __esri.Polygon;
      // step 2. query for walls
      return this.getWalls(gaps).then(gapWalls => {

        // Undo Redo: undoing edit which involves merging unit and remove walls does not add the wall back #6871
        gapWalls && gapWalls.forEach(g => originalWalls.push(g.clone()));

        // step 3. intersect existing walls with the gap buffer and subtract intersection from original wall
        gapWalls.forEach((wall, i) => {
          const intersecting = engine.intersect(gapBuffer, wall.geometry) as __esri.Polyline;
          if (intersecting) {
            wall.geometry = engine.difference(wall.geometry, intersecting) as __esri.Geometry;
            if (wall.geometry) {
              updateZ(wall.geometry, z);
            }
            newWalls.push(wall);
          }
        });
        return [newWalls, originalWalls];
      });
    } else {
      // step 1. get shared edges
      const lines = originalUnits.map(f => this.createPolylineFromPolygon(f.geometry as __esri.Polygon));
      const shared = lines.map(feature => {
        const others = lines.filter(f => f !== feature);
        const intersections = engine.intersect(others, feature) as __esri.Geometry[];
        if (intersections && intersections.length) {
          return intersections;
        }
      }).flat().filter(x => !!x);
      if (shared.length) {
        // step 2. buffer the shared edges to handle cases where walls are not snapped to units
        const bufferPolygon = await buffer(engine.union(shared), .1, "feet") as __esri.Polygon;
        // step 3. query for walls
        const walls = await this.getWalls(bufferPolygon);

        // Undo Redo: undoing edit which involves merging unit and remove walls does not add the wall back #6871
        walls && walls.forEach(g => originalWalls.push(g.clone()));
        
        // step 4. intersect existing walls with the gap buffer and subtract intersection from original wall
        walls.forEach((wall, i) => {
          const intersecting = engine.intersect(bufferPolygon, wall.geometry) as __esri.Polyline;
          if (intersecting) {
            wall.geometry = engine.difference(wall.geometry, intersecting) as __esri.Geometry;
            if (wall.geometry) {
              updateZ(wall.geometry, z);
            }
            newWalls.push(wall);
          }
        });
      }
      return [newWalls, originalWalls];
    }
  }
  private ensureGraphicsLayer(layerId: string) {
    const view = this.getView();
    if (view == null)
      return;

    let layer = this.getView().map.findLayerById(layerId) as __esri.GraphicsLayer;
    if (!layer) {
      const { lib, config } = Context.getInstance();
      layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: config.graphicElevationOffset,
        unit: "meters"
      };
      this.getView().map.add(layer);
    }
    return layer;
  }

  private async generateGaps(geometries: __esri.Polygon[], tolerance: number, units: __esri.LinearUnits): Promise<__esri.Polygon> {
    if (geometries.length === 0) {
      return null;
    }
    const { lib } = Context.getInstance();
    const engine: __esri.geometryEngine = lib.esri.geometryEngine;
    const service: __esri.geometryService = lib.esri.geometryService;
    const url = getGeometryServiceUrl();

    // the points that connect units together
    const allSegmentPoints: __esri.Point[] = [];
    const spatialReference = geometries[0].spatialReference;

    /*************** Algorithm for filling gaps **************/

    // 1. Obtain all exterior rings of all included units (exterior ring === clockwise rings not contained within any other ring)
    // 2. Loop through rings and for each unit/ring vertex, find the nearest coordinate of each of the other units/rings.
    // 2. Calculate the distance between those two points and check against the tolerance.
    // 3. If within tolerance, retain those points and continue.
    // 6. After all units are checked against all other units, create a convex hull polygon around all of the points
    // 7. Convert hull to a polyline and submit to the auto-complete geometry service operation.

    // get all exterior rings
    const exteriorRings = geometries.map(g => getExteriorRings(g as __esri.Polygon)).flat();
    // find all connecting segments/points from each unit ring to all other unit rings
    for await (const part of exteriorRings) {
      const others = exteriorRings.filter(ring => ring !== part);
      for await (const otherPart of others) {
        allSegmentPoints.push(...await this.createSegmentPoints(part, otherPart, spatialReference, tolerance, units));
      }
    }

    // create a convex hull of all of the connecting points
    // When using an array of points and the merge option set to `true` for convexHull,
    // the result is an array with a single polygon
    // https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-geometryEngine.html#convexHull
    const hulls = allSegmentPoints.length >= 4
      ? engine.convexHull(allSegmentPoints, true) as __esri.Polygon[]
      : [];
    const completionLines = hulls.map(polygon => this.createPolylineFromPolygon(polygon));

    // to fill gaps between units we'll use the autoComplete operation on GeometryService
    const gaps = completionLines.length > 0
      // Submitted feedback to the JSAPI documentation team about an error in the return types (should be an array of polygons)
      // @ts-ignore
      ? await service.autoComplete(url, geometries as __esri.Polygon[], completionLines) as __esri.Polygon[]
      : null;
    return gaps && gaps.length ? engine.union(gaps) as __esri.Polygon : null;
  }

  private getWalls(geometry: __esri.Geometry) {
    const detailsLv = getDetailsLayerView();
    const details = getDetailsLayer();
    const query = detailsLv.createQuery();
    query.returnZ = true;
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.geometry = geometry;
    return details.queryFeatures(query).then(fs => {
      const walls = fs.features.filter(f => {
        const useType: string = getAttributeValue(f.attributes, FieldNames.DETAILS_USE_TYPE);
        // TODO: incorporate upcoming wall configuration values
        if (useType && this.getWallTypes().includes(useType)) {
          return true;
        } else {
          return false;
        }
      });
      return walls;
    });
  }

  // FIXME Link up with configurator
  /** Default wall types based on configured use_type field values. */
  private getWallTypes() {
    const wallTypeValues = Context.instance.config.spaceplanner.wallTypeValues;
    if(!wallTypeValues || (wallTypeValues && wallTypeValues.length === 0)) return [];
    return wallTypeValues;
  }

  /** Submits the merged results to the server.
   * @param type - The type of features to merge.
   * @param features - The original graphics to merge.
   * @param attributes - Updated attributes from the preserved feature.
   * @param originalAttributes - The original attributes of the feature to preserve.
   * @param removeGaps - Whether to fill gaps between units.
   * @param tolerance - Maximum distance (in feet) between two units to fill gaps.
   * @param units - The unit of measure for determining distance.
   */
  async merge(
    type: Extract<LayerType, "unit" | "detail">,
    features: __esri.Graphic[],
    attributes: Record<string, any>,
    originalAttributes: Record<string, any>,
    removeGaps: boolean = true,
    tolerance: number = .4,
    units: __esri.LinearUnits = "feet",
    dissolveWalls: boolean = false
  ) {
    const { lib } = Context.getInstance();
    const engine: __esri.geometryEngine = lib.esri.geometryEngine;
    const gaps = removeGaps && features.every(g => g.geometry.type === "polygon")
      ? await this.generateGaps(features.map(g => g.geometry) as __esri.Polygon[], tolerance, units)
      : null;
    const allPolygons = features.map(g => g.geometry);
    if (gaps) {
      allPolygons.push(gaps);
    }
    const geometry = engine.union(allPolygons) as __esri.Polygon;
    const merged = new lib.esri.Graphic({
      geometry,
      attributes,
      symbol: geometry.type === "polygon" ? this.selectedFillSymbol : this.selectedLineSymbol
    });
    this.layer.add(merged);

    // dissolve walls if requested
    const dissolved: {
      features: __esri.Graphic[],
      original: __esri.Graphic[]
    } = type === "unit" && dissolveWalls === true
      ? { features: null, original: null }
        : null;
    if (dissolved) {
      const [newWalls, originalWalls] = await this.dissolveWalls(merged, gaps, features);
      dissolved.features = newWalls;
      dissolved.original = originalWalls;
    }
    
    if (originalAttributes[FieldNames.UNITS_AREA_GROSS] === attributes[FieldNames.UNITS_AREA_GROSS]) {
      await updateGrossArea(merged);
    }

    const guard = new TransactionGuard({ force: true });
    return mergeFeatures({ type, features, merged, dissolved }).then(() => {
      guard.close();
    }).catch((error) => {
      guard.close();
      console.error("Error updating feature", error);
      throw error;
    });
  }

  /** Previews the result by displaying the merged geometries on the map.
   * @param geometries - The original geometries to merge.
   * @param removeGaps - Whether to fill gaps between units.
   * @param tolerance - Maximum distance (in feet) between two units to fill gaps.
  */
  preview(geometries: __esri.Geometry[], removeGaps: boolean = true, tolerance: number = .4, units: __esri.LinearUnits = "feet") {
    const { lib } = Context.getInstance();
    this.layer.graphics.removeAll();
    this.length = geometries.length;
    if (geometries.length <= 1) {
      return;
    }

    if (removeGaps && geometries.every(g => g.type === "polygon")) {
      this.generateGaps(geometries as __esri.Polygon[], tolerance, units).then(gaps => {
        if (this.length) {
          this.layer.graphics.removeAll();
          this.layer.graphics.add(new lib.esri.Graphic({
            geometry: gaps,
            symbol: this.gapSymbol
          }))
        }
      });
    }
  }

  reset() {
    this.layer && this.layer.graphics.removeAll();
    this.length = 0;
  }
}
