import React from 'react';

// components.common.SearchableMultiSelect
import SearchableMultiSelectViewModel from './SearchableMultiSelectViewModel';

// context
import Context from '../../../context/Context';

// util
import { newState, nextId, refresh } from '../../util/component';
import { stringFormatter } from '../../../util/formatUtil';

// 3rd party, Calcite
import Popover from 'calcite-react/Popover';
import CheckIcon from "calcite-ui-icons-react/CheckSquareFIcon";
import SquareIcon from "calcite-ui-icons-react/SquareIcon";
import Panel from 'calcite-react/Panel';
import XIcon from "calcite-ui-icons-react/XIcon";


const CSS = {
  main: "i-searchable-ms",
  search: "i-search-input",
  subtitle: "i-subtitle",
  list: "i-searchable-ms--i-list",
  item: "i-searchable-ms--i-list-item",
  singleSelection: "i-single-selection",
  labelGroup: "i-searchable-ms--i-label-group",
  displayLabel: "i-searchable-ms--i-label-display",
  subtitleLabel: "i-searchable-ms--i-label-subtitle",
  itemTags: "i-item-tags",
  itemTag: "i-item-tag",
  noResults: "i-searchable-ms--i-no-results"
};

export default class SearchableMultiSelect extends React.Component {
  //----------------------------------
  //
  //  Properties
  //
  //----------------------------------

  //----------------------------------
  //  id
  //----------------------------------

  /**
   * ID of this control
   */
  id = nextId();

  //----------------------------------
  //  maxItems
  //----------------------------------

  /**
   * The max amount of items you can select
   */
  maxItems = null;

  //----------------------------------
  //  searchIcon
  //----------------------------------

  /**
   * Icon that will precede the search box
   */
  searchIcon = null;

  //----------------------------------
  //  searchPlaceholder
  //----------------------------------

  /**
   * Placeholder text for search box
   */
  searchPlaceholder = null;

  //----------------------------------
  //  singleSelection
  //----------------------------------

  /**
   * Only one item is meant to be selected
   */
  singleSelection = false;

  //----------------------------------
  //  viewModel
  //----------------------------------

  /**
   * SearchableMultiSelectViewModel
   */
  viewModel = null;

  //----------------------------------
  //
  //  Private Properties
  //
  //----------------------------------

  _showSelection = false;

  //----------------------------------
  //
  //  Lifecycle
  //
  //----------------------------------

  constructor(props) {
    super(props);

    const {
      allowSelf,
      dataset,
      displayField,
      initialClause,
      maxItems,
      postQueryProcessing,
      searchFields,
      searchIcon,
      searchPlaceholder,
      singleSelection,
      sortField,
      subtitleField
    } = this.props;

    this.viewModel = new SearchableMultiSelectViewModel({
      allowSelf,
      dataset,
      displayField,
      initialClause,
      maxItems,
      postQueryProcessing,
      searchFields,
      singleSelection,
      sortField,
      subtitleField,
      view: this
    });

    this.maxItems = maxItems;
    this.searchIcon = searchIcon;
    this.searchPlaceholder = searchPlaceholder;
    this.singleSelection = !!singleSelection;

    this.state = newState({
      items: [],
      listOpen: false
    });

  }

  async componentDidMount() {
    const { preSelectedItems } = this.props;
    this.viewModel.setPreSelectedItems(preSelectedItems);
    await this.viewModel.initialize();
    if (typeof this.props.initialObjectId === "number") {
      this._showSelection = true;
      this.viewModel.selectFeatureById(this.props.initialObjectId,true);
      refresh(this);
    }
  }

  renderInput() {
    const { searchPlaceholder, searchIcon, singleSelection } = this;

    const singleProps = singleSelection ? { onBlur: this._onInputBlur } : {};
    const selectedItems = this.viewModel.getSelectedItems();
    if (singleSelection && selectedItems.length === 1 && this._showSelection) {
      singleProps["value"] = selectedItems[0].display;
      this._showSelection = false;
    }

    return (
      <div className={CSS.search}>
        {searchIcon}
        <input
          id={`searchinput-${this.id}`}
          type="text"
          aria-label={searchPlaceholder}
          placeholder={searchPlaceholder}
          onInput={this._onInputChange}
          onPaste={this._onInputChange}
          onFocus={this._onInputFocus}
          autoComplete="off"
          {...singleProps}
        />
      </div>
    );
  }

  renderSubtitle() {
    const i18n = Context.getInstance().i18n;
    const { maxItems, singleSelection } = this;
    if (singleSelection) {
      return null;
    }

    const selectedItems = this.viewModel.getSelectedItems();
    const template = i18n.search.selected;
    const label = stringFormatter(template, { current: selectedItems.length, max: maxItems });

    return maxItems && (<div className={CSS.subtitle}>{label}</div>);
  }

  renderItemTags() {
    const { singleSelection } = this;
    if (singleSelection) {
      return null;
    }

    const selectedItems = this.viewModel.getSelectedItems();
    const tags = selectedItems.map((item) => this.renderItemTag(item));

    return (<div className={CSS.itemTags}>{tags}</div>);
  }

  renderItemTag(item) {
    const { display, id } = item;

    return (
      <button
        key={`tag-${id}`}
        data-id={id}
        className={CSS.itemTag}
        onClick={this._onItemClick}
      >
        {display}
        <XIcon />
      </button>
    );
  }

  renderListContent() {
    const i18n = Context.getInstance().i18n;
    const { items } = this.state;
    const listItems = [];
    items.some((item,i) => {
      if (i < 100) {
        listItems.push(this.renderListItem(item));
        return false;
      }
      return true;
    });
    const searchTerm = this.viewModel.searchTerm;
    const noMatchingItems = searchTerm && searchTerm.trim().length > 0 && items.length === 0;

    const content = noMatchingItems
      ? <div className={CSS.noResults}>{i18n.messages.noResults}</div>
      : <ul className={CSS.list}>{listItems}</ul>;

    return (
      <Panel white noPadding>
        {content}
      </Panel>
    );
  }

  renderListItem(item) {
    const { singleSelection } = this;
    const { display, id, subtitle, checked } = item;
    const key = `item-${id}`;

    const icon = !singleSelection ? checked ? <CheckIcon /> : <SquareIcon /> : null;
    let className = checked ? `${CSS.item}--checked` : CSS.item;
    if (singleSelection) {
      className = [className, CSS.singleSelection].join(" ");
    }

    return (
      <li key={key}>
        <button
          data-id={id}
          className={className}
          onClick={this._onItemClick}
        >
          {icon}
          <div className={CSS.labelGroup}>
            <p className={CSS.displayLabel}>{display}</p>
            <p className={CSS.subtitleLabel}>{subtitle}</p>
          </div>
        </button>
      </li>
    );
  }

  render() {
    const { singleSelection } = this;
    const { listOpen } = this.state;
    const input = this.renderInput();
    const isNotResManager = !!!(this.props && this.props.isReservationManager);

    return (
      <div className={CSS.main}>
        <Popover
          closeOnSelect={!!singleSelection}
          open={listOpen}
          targetEl={input}
          onRequestClose={this._onClose}
          appendToBody
          style={{zIndex: 9999}} // @todo Urban
        >
          {this.renderListContent()}
        </Popover>
        {isNotResManager && this.renderSubtitle()}
        {this.renderItemTags()}
      </div>
    );
  }

  //----------------------------------
  //
  //  Public Methods
  //
  //----------------------------------

  /**
   * When the items in the list have updated (search, etc) propagate changes to parent
   *
   * @param {any[]} items
   */
  onItemsUpdated = (items) => {
    const { onItemsUpdated } = this.props;
    if (onItemsUpdated && typeof onItemsUpdated === "function") {
      onItemsUpdated(items);
    }
  }

  //----------------------------------
  //
  //  Private Methods
  //
  //----------------------------------

  _onInputChange = (e) => {
    const input = e.target;
    if (input.value.trim() === "") {
      this.viewModel.setSearchTerm(null);
    } else if (input.value && this.viewModel.searchTerm !== input.value.toLowerCase().trim()) {
      this.viewModel.setSearchTerm(input.value.toLowerCase().trim());
    }
  }

  _onInputFocus = () => {
    this.setState({ listOpen: true });
  }

  _onInputBlur = (e) => {
    const input = e.target;

    // Give the VM some time to update on a click
    setTimeout(() => {
      const selectedItems = this.viewModel.getSelectedItems();
      if (selectedItems.length === 1) {
        input.value = selectedItems[0].display;
      }
    }, 100);
  }

  _onClose = () => {
    if (document.activeElement.id !== `searchinput-${this.id}`) {
      this.setState({ listOpen: false });
    }
  }

  _onItemClick = (e) => {
    const { singleSelection } = this;

    const node = e.currentTarget;
    const objectId = node.getAttribute("data-id");
    if (objectId) {
      if (singleSelection) {
        this._onClose();
      }
      this._showSelection = true;
      this.viewModel.selectFeatureById(objectId);
    }
  }
}
