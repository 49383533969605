import { IFeature } from "@esri/arcgis-rest-feature-layer";
import { getDetailsLayer } from "../sourceUtil";
import { makeTransactionInfo } from "./transaction";
import { cloneFeature } from "./transactionUtil";

export type UpdateDetailsTask = {
  info: {
    action: "add" | "update" | "delete",
    feature?: IFeature | __esri.Graphic,
    prevFeature?: IFeature | __esri.Graphic,
    undoUpdates?: __esri.Graphic[],
    updates?: __esri.Graphic[]
  }
}
export async function prepare(task: UpdateDetailsTask) {
  const transactionInfo = makeTransactionInfo();
  const info = task.info;
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  const makeDetailEdits = () => {
    const layer = getDetailsLayer();
    const keyField = layer.objectIdField;
    let layerEdits, undoLayerEdits;

    if (layer && info.action === "add") {
      const add = cloneFeature(info.feature);
      // remove read-only fields for the add?
      layerEdits = {
        id: layer.layerId,
        adds: [add]
      }
      undoLayerEdits = {
        id: layer.layerId,
        deletes: ["?"] // needs to be populated following add
      }

    } else if (layer && info.action === "update") {
      layerEdits = { id: layer.layerId };
      undoLayerEdits = { id: layer.layerId };

      if (info.updates && info.undoUpdates) {
        // check for null geometries
        const toUpdate = info.updates.filter(f => f.geometry != null);
        const toDelete = info.updates.filter(f => f.geometry == null);
        const updateIds = toUpdate.map(f => f.attributes[keyField]);
        const deleteIds = toDelete.map(f => f.attributes[keyField]);
        const undoUpdated = info.undoUpdates.filter(f => updateIds.includes(f.attributes[keyField]));
        const undoDeletes = info.undoUpdates.filter(f => deleteIds.includes(f.attributes[keyField]));
        layerEdits.updates = toUpdate.map(f => cloneFeature(f));
        layerEdits.deletes = deleteIds;
        undoLayerEdits.updates = undoUpdated.map(f => cloneFeature(f));
        undoLayerEdits.adds = undoDeletes.map(f => cloneFeature(f));
      } else {
        // remove read-only fields for the update and undo?
        layerEdits.updates = [cloneFeature(info.feature)];
        undoLayerEdits.updates = [cloneFeature(info.prevFeature)];
      }

    } else if (layer && info.action === "delete") {
      const key = info.feature.attributes[keyField]
      const undo = cloneFeature(info.feature);
      // remove read-only fields for the undo?
      layerEdits = {
        id: layer.layerId,
        deletes: [key]
      }
      undoLayerEdits = {
        id: layer.layerId,
        adds: [undo]
      }
    }

    if (layerEdits) {
      transactionInfo.detailEdits = true;
      transactionInfo.undo.detailEdits = true;
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  makeDetailEdits();
  console.log("details.transactionInfo",transactionInfo)
  //if (true) throw new Error("tmp.split");
  //if (info.action === "update") throw new Error("tmp.err");

  return transactionInfo;
}