import React from "react";
import { connect } from "react-redux";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";

import Book from "./Book";
import Context from "../../../context/Context";
import DropdownButton from "../../../components/common/Dropdown/DropdownButton";
import Button from "../../../common/components/Button/Button";
import Filter from "./Filter";
import Icons from "../../util/Icons";
import MeetingRoomsModel, { IMeetingRoom, IMeetingRoomCriteria } from "./MeetingRoomsModel";
import ModalController from "../../../common/components/Modal/ModalController";
import SidebarHeader from "../Sidebar/SidebarHeader";
import Topic from "../../../context/Topic";
import When from "./When";

import * as component from "../../util/component";
import * as dateUtil from "../Events/dateUtil";
import { hideReservationsByFilter } from "../More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";

import Loader from "calcite-react/Loader/Loader";
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import FilterIcon from "calcite-ui-icons-react/FilterIcon";
import SortAscIcon from "calcite-ui-icons-react/SortAscendingArrowIcon";
import SortDescIcon from "calcite-ui-icons-react/SortDescendingArrowIcon";
import ChevronRightIcon from "calcite-ui-icons-react/ChevronRightIcon";
import ChevronLeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import MyMeetingRooms from "./MyMeetingRooms";
import MyMeetingRoomsOffice365 from "./MyMeetingRoomsOffice365";
import { getMeetingBookingSystem, getMeetingBookingSystemType } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as validateUtil from "../More/Actions/BookWorkspace/validateUtil";
import BookingDateFilter, { IBookingDateFilter } from "../Events/BookingDateFilter";
import { IBookingTask } from "../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import { IDateCriteria } from "../More/Actions/BookWorkspace/BookWorkspace";
import { makeWhere } from "../More/Actions/BookWorkspace/whereUtil";
import moment from "moment";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

import ReserveFor from "../More/Actions/BookWorkspace/ReserveFor";
import { IReserveForInfo } from "../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";

const CSS = {
  meetingRoomScroll: "i-scroll-meeting-rooms",
  optionsContainer: "i-events-options-container",
  options: "i-events-options",
  meetingTabs: "i-meeting-tabs",
  meetingTab: "i-meeting-tab",
  filterButton: "i-filter-button",
  ddButton: "i-dd-button",
  date: "i-events-date",
  dateLabel: "i-events-date-label",
  dateRange: "i-events-date-range",
  dateRangeBtn: "i-events-date-range-button"
}

const TABS = {
  available: "available",
  booked: "booked"
};
interface IMeetingRoomsProps extends IRdxProps {
  morePanel: boolean,
  panelIsActive: boolean,
  sidebarKey: string
}
export interface IMeetingRoomsState {
  abandon?: boolean,
  bookingDateFilter?: IBookingDateFilter
  criteriaString?: string,
  items?: IMeetingRoom[],
  filteredItems?: IMeetingRoom[],
  reservedUnits?: IMeetingRoom[],
  working?: boolean,
  wasSiteInit?: boolean,
  activeTab?: "available" | "booked",
  areasWithMeetingRooms?: __esri.Graphic[],
  reserveForEmail: string,
  reserveForInfo: IReserveForInfo,
  reserveForOn: boolean,
  reserveForToggling: boolean,
  listingType: "self" | "others"
}
export class _MeetingRooms extends React.Component<IMeetingRoomsProps, IMeetingRoomsState> {

  model: MeetingRoomsModel;
  mounted: boolean = false;
  private _componentId: string;
  private countWeek = 0;
  private countMonth = 1;
  private where: string;
  private _cache: CellMeasurerCache;
  private _list: List;

  constructor(props: IMeetingRoomsProps) {
    super(props);
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 120,
    });
    this._componentId = component.nextId("meeting-rooms-");
    this.model = new MeetingRoomsModel({
      onUnitsQueried: (info) => {
        if (!this.mounted) return;
        this.setState({
          criteriaString: info.criteriaString,
          items: info.items,
          areasWithMeetingRooms: info.areasWithMeetingRooms
        })
      },
      onUnitsSorted: (info) => {
        if (!this.mounted) return;
        this.setState({
          filteredItems: info.filteredItems || [],
        })
      },
      onReservationsQueried: (info) => {
        if (!this.mounted) return;
        this.setState({
          criteriaString: info.criteriaString,
          filteredItems: info.filteredItems || [],
          reservedUnits: info.reservedUnits,
        })
      },
      onComplete: (info) => {
        if (!this.mounted) return;
        this.setState({ working: info.working });
        if (!info.working) {
          this._cache.clearAll();
          if (this._list) this._list.forceUpdateGrid();
        }
      }
    });

    const isReservationManager = Context.instance.aiim?.isReservationManager();
    this.state = component.newState({
      criteriaString: null,
      items: null,
      filteredItems: null,
      reservedUnits: null,
      working: true,
      wasSiteInit: false,
      activeTab: TABS.available,
      bookingDateFilter: null,
      areasWithMeetingRooms: [],
      reserveForOn: false,
      reserveForToggling: false,
      reserveForEmail: null,
      reserveForInfo: null,
      listingType: isReservationManager ? "others" : "self"
    });

    // Reset default duration and filters every time hotel/meetings panel opens #4652
    Context.instance.session.meetingRoomsCriteria = null

    this.getCriteria();
  }

  componentDidMount() {
    this.mounted = true;
    this.model.queryUnits();
    const type = getMeetingBookingSystemType();
    if (type === "office365") {      
      this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION, 'thisweek');
      this.setBookingDateFilter({ dateOption: "thisweek", dateSelected: null });
    }
    component.own(this,[

      Topic.subscribe(Topic.ReloadViews,params => {
        try {
          Context.instance.session.meetingRoomsCriteria = null;
          this.setState(state => {
            return {
              abandon: true
            };
          });
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ReservationsUpdated,params => {
        this.executeTask("queryReservations");
      }),

      Topic.subscribe(Topic.MeetingRoomsFilterClear, ()=> {
        this.filterClicked();
      })

    ]);
  }

  componentWillUnmount() {
    if (Context.instance.aiim.reserveForInfo) {
      Context.instance.aiim.reserveForInfo = null;
    }
    component.componentWillUnmount(this);
    this.mounted = false;
    Context.instance.session.sharedMeetingRoomWhen = null;
    this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION,'any');
    this.props.setRdxValue(Rdx.Keys.AREA_FILTERS_MEETING_ROOM,[]);
    this.props.setRdxValue(Rdx.Keys.RESERVE_FOR_OTHERS, false);
  }

  backClicked = () => {
    hideReservationsByFilter();
    this.props.setRdxValue(Rdx.Keys.SIDEBAR_ACTIVE_KEY, "more");
    document.body.classList.add("i-sidebar-panel-open");
    Topic.publish(Topic.SidebarButtonClicked,{sidebarKey: "more"});
    Context.instance.views.toggleClickHandlers("resume");
  }

  processBookClick = true;

  bookClicked = async(item: IMeetingRoom) => {
    if (!this.processBookClick) return;
    try {
      this.processBookClick = false;
      let criteria: IMeetingRoomCriteria; 
      criteria = JSON.parse(JSON.stringify(this.getCriteria()), (k, v) =>
        k === "endDate" || k === "date" ? new Date(v) : v);

      const when = criteria.when;
      const bookingSystem = getMeetingBookingSystem();
      const task: IBookingTask = {
        bookingType: 'meetingRooms',
        checkInDate: new Date(when.start.date),
        checkOutDate: new Date(when.end.date),
        allDay: (when.duration === "allDay"),
        unit: item.feature,
        unitName: item.name,
        scheduleEmail: item.scheduleEmail,
        bookingSystem: bookingSystem,
        options: {
          recurrence: when.recurrence,
          reserveForInfo: this.state.reserveForInfo
        }
      }
      if (task.allDay) {
        task.checkInDate = dateUtil.toStartOfDay(new Date(task.checkInDate));
        task.checkOutDate = dateUtil.toEndOfDay(new Date(task.checkOutDate));
      }
       
      const response = await validateUtil.verify(task);
      if (response && response.ok) {
        let showInfo = {
          bookItem: item,
          criteria: criteria,
          reserveForInfo: this.state.reserveForInfo
        }
        Book.show(showInfo);
      }
    } finally {
      setTimeout(()=> {
        this.processBookClick = true;
      },200)
    }
  }

  executeTask(type) {
    const info = {
      items: this.state.items,
      filteredItems: this.state.filteredItems,
      reservedUnits: this.state.reservedUnits
    }
    this.model.executeTask(type, info);
  }

  actionBar =()=> {
    let info;
    info = {
      applyButton: null,
      applyButtonClicked: null,
      clearAllButton: null,
      clearAllButtonClicked: null,
    }
    const i18n = Context.instance.i18n;

    let btnApply = (
      <Button key="modal-assign" className="i-apply-button" gapRight
        onClick={evt => {
          if (info.applyButtonClicked) info.applyButtonClicked(evt)
        }}
        _onMount={obj => {
          info.applyButton = obj
        }}>
        {i18n.more.bookWorkspace.apply}
      </Button>
    )
    let btnClearAll = (
      <Button key="modal-cancel" className="i-clearAll-button" clear gapRight
        onClick={evt => {
          if (info.clearAllButtonClicked) info.clearAllButtonClicked(evt)
        }}
        _onMount={obj => {
          info.clearAllButton = obj
        }}>
        {i18n.more.bookWorkspace.clearAll}
      </Button>
    )

    info.content = (
      <div style={{width: "100%"}}>
        {btnClearAll}
        {btnApply}
      </div>
    )
    return info;
  }

  filterClicked = () => {
    const i18n = Context.instance.i18n;
    let criteria = this.getCriteria();
    let actionBarInfo = this.actionBar();
    const actionBarContent = actionBarInfo.content;
    let filterComponent;
    const controller = new ModalController();

    actionBarInfo.applyButtonClicked = () => {
      let newCriteria: Partial<IMeetingRoomCriteria> = {};
      let ok = filterComponent.validate(newCriteria)
      if (ok) {
        criteria.filter = newCriteria.filter;
        this.executeTask("filterUnits");
        controller.close();
      }
    }
    actionBarInfo.clearAllButtonClicked = () => {
      criteria.filter = {
        siteId: null,
        facilityId: null,
        levelId: null,
        capacity: null,
        equipments: [],
        areaIds: []
      };
      controller.close();
      Rdx.setValue(null,Rdx.Keys.AREA_FILTERS_MEETING_ROOM,[]);
      Topic.publish(Topic.MeetingRoomsFilterClear, {});
    }

    const modalProps = {
      className: 'i-modal-confirm',
      title: i18n.meetingRooms.filter,
      showOKCancel: false,
      actionBarContent: actionBarContent
    };

    const content = (
      <Filter criteria={criteria} items={this.state.items} areasWithMeetingRooms={this.state.areasWithMeetingRooms}
        onMount={c => filterComponent = c} />
    )
    controller.show(modalProps,content);
  }
  formatWeekDateRange(bookingDateFilter) {
    return `${moment(bookingDateFilter.dateStart).locale("en-us").format("MM/DD")} - ${moment(bookingDateFilter.dateEnd).locale("en-us").format("MM/DD")}`;
  }

  formatMonth() {
    const { intl } = Context.getInstance().lib.esri;
    const a = intl.formatDate;
    return a(moment().add((this.countMonth-1), "month"), { month: "short", year: "numeric"});
  }
  getCriteria() {
    return this.model.getCriteria();
  }
  getDateCriteria() {
    const { bookingDateFilter } = this.state;
    const criteria = {
      dateOption: bookingDateFilter?.criteria.dateOption,
      dateSelected: bookingDateFilter?.criteria.dateSelected,
      countWeek: this.countWeek,
      countMonth: this.countMonth
    }
    return criteria;
  }
  itemClicked = (item) => {
    const source = Context.instance.aiim.datasets.units.getSource();
    const feature = item.feature;
    const searchResult = source.makeSearchResult(null, feature);
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      isMeetingRoomsPanel: true,
      componentId: this._componentId
    })
  }

  tabClicked = (event) => {
    const type = event.target.getAttribute("data-tabtype");
    this.setState({
      activeTab: type
    });
  }

  renderTabs() {
    const i18n = Context.instance.i18n;
    return (
      <div className={CSS.meetingTabs}>
        {this.renderTab(i18n.more.bookWorkspace.tabs.available, TABS.available)}
        {this.renderTab(i18n.more.bookWorkspace.tabs.booked, TABS.booked)}
      </div>
    );
  }

  renderTab(title, type) {
    const { activeTab } = this.state;
    const active = type === activeTab;
    const cls = active ? `${CSS.meetingTab} i--active` : `${CSS.meetingTab} i--inactive`;
    return (
      <button
        className={cls}
        role="tab"
        aria-selected={active ? "true" : "false"}
        data-tabtype={type}
        onClick={this.tabClicked}
      >
        {title}
      </button>
    );
  }

  renderWorking() {
    const crit = this.getCriteria();
    const i18n = Context.instance.i18n;

    const task = {
      criteria: {
        when: crit?.when
      },
      criteriaString: null
    }
    this.model.makeCriteriaString(task);
    return ( 
      <div className="i-search">
        <Loader sizeRatio={1} />
        <div className="i-search-label">{i18n.meetingRooms.search}</div>
        <div className="i-search-msg">
          {i18n.meetingRooms.searchMsg}
        </div>
      </div>
    )
  }

  render() {
    if (this.state.abandon) return null;
    return (
      <div className={"i-meeting-rooms"}>
        {this.renderHeader()}
        {this.renderTabs()}
        <div className={"i-meeting-rooms-content"}>
          <div className={"i-scroll-meeting-rooms i--tabs"}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { activeTab } = this.state;

    switch (activeTab) {
      case TABS.booked:
        return this.renderBooked();
      case TABS.available:
      default:
        return this.renderAvailable();
    }
  }

  renderAvailable() {
    const options = this.renderOptions();
    const cs = this.renderCriteriaString();
    const items = this.renderList();
    const { reserveForToggling, reserveForOn, reserveForEmail } = this.state;
    const waitForRF = (reserveForToggling || (reserveForOn && !reserveForEmail))
    return (
      <>
        {options}
        {this.renderReserveFor()}
        {!waitForRF && cs}
        {!waitForRF && items}
      </>
    );
  }
  renderNext(dateOption: string) {
    const icon = Context.getInstance().uiMode.isRtl
      ? <ChevronLeftIcon size={16} />
      : <ChevronRightIcon size={16} />;

    if (dateOption === "thisweek") { 
      return (
        <Button
          className={CSS.dateRangeBtn}
          small={true}
          icon={icon}
          transparent={true}
          onClick={this.nextWeek}>
        </Button> 
      )
    } else if (dateOption === "thismonth") {
      return (
        <Button
          className={CSS.dateRangeBtn}
          small={true}
          icon={icon}
          transparent={true}
          onClick={this.nextMonth}>
        </Button> 
      )
    }
  }

  renderPrev(dateOption: string) {
    const icon = Context.getInstance().uiMode.isRtl
      ? <ChevronRightIcon size={16} />
      : <ChevronLeftIcon size={16} />;
    const emptyDiv = <div className={CSS.dateRangeBtn}></div>;
    if (dateOption === "thisweek") {
      return this.countWeek === 0
        ? emptyDiv
        : <Button
            className={CSS.dateRangeBtn}
            small={true}
            icon={icon}
            transparent={true}
            onClick={this.prevWeek}>
          </Button>
    } else if (dateOption === "thismonth") {
      return this.countMonth === 1
        ? emptyDiv
        : <Button
            className={CSS.dateRangeBtn}
            small={true}
            icon={icon}
            transparent={true}
            onClick={this.prevMonth}>
          </Button>      
    }
  }

  nextMonth=()=> {
    const criteria = this.getDateCriteria();
    this.countMonth = this.countMonth + 1;
    criteria.countMonth = this.countMonth;
    this.setBookingDateFilter(criteria);
  }

  nextWeek=()=> {
    const criteria = this.getDateCriteria();
    this.countWeek = this.countWeek + 1;
    criteria.countWeek = this.countWeek;
    this.setBookingDateFilter(criteria);
  }

  prevMonth=()=> {
    const criteria = this.getDateCriteria();
    this.countMonth = this.countMonth - 1;
    criteria.countMonth = this.countMonth;
    this.setBookingDateFilter(criteria);
  }

  prevWeek=()=> {
    const criteria = this.getDateCriteria();
    this.countWeek = this.countWeek - 1;
    criteria.countWeek = this.countWeek;
    this.setBookingDateFilter(criteria);
  }
  renderDateLabel() {
    const { bookingDateFilter } = this.state;
    const { criteria } = bookingDateFilter || {};
    if (!(criteria && criteria.dateOption)) {
      return null;
    }
    let date = dateUtil.formatDateFilter(bookingDateFilter);
    const type = getMeetingBookingSystemType();
    const dateOption = criteria && criteria.dateOption;
    if (type === "office365" && dateOption === "thisweek") {
      date = this.formatWeekDateRange(bookingDateFilter);
    } else if (type === "office365" && dateOption === "thismonth") {
      date = this.formatMonth();
    }
    return <div className={CSS.dateLabel}>{date}</div>;
  }
  renderBooked() {
    const i18n = Context.instance.i18n;
    const bookingSysType = getMeetingBookingSystemType();
    const bookingSystem = getMeetingBookingSystem();
    const dateOption = this.state.bookingDateFilter?.criteria.dateOption;
    const isReservationManager = Context.instance.aiim?.isReservationManager();
    const { listingType } = this.state;
    
    const dateFilter = (
      <div className={CSS.optionsContainer}>
        <BookingDateFilter type='bookings' onFilterChange={data =>
          this.setState({ bookingDateFilter: data })} 
        />
        {isReservationManager && 
          <>
            <button type="button"
              className={`i-booktab-button-clear${listingType === "self" ? " i--active" : ""}`}
              onClick={() => {
                this.setState({listingType: "self"})
              }}
            >
              <span className="i-font-size-bookingtab">{i18n.more.bookWorkspace.newTabs.forYou}</span>
            </button>
            <button type="button"
              className={`i-booktab-button-clear${listingType !== "self" ? " i--active" : ""}`}
              onClick={() => {
                this.setState({listingType: "others"})
              }}
            >
              <span className="i-font-size-bookingtab">{i18n.more.bookWorkspace.newTabs.forOthers}</span>
            </button>
          </>
        }
        {this.renderRefresh()}
        {this.renderDateLabel()}
      </div>
    )

    if (bookingSysType === "office365") {
      const isRange = dateOption === "thisweek" || dateOption === "thismonth";
      return (
        <>
          <div className={CSS.optionsContainer}>
            <BookingDateFilter type='bookings' isOffice365={true} onFilterChange={(data) => {
              this.countWeek = 0;
              this.countMonth = 1;
              this.setState({ bookingDateFilter: data })}} />
            {this.renderRefresh()}
            <div className={isRange ? CSS.dateRange : CSS.date}>   
              {isRange && this.renderPrev(dateOption)}
              {this.renderDateLabel()}
              {isRange && this.renderNext(dateOption)}
            </div>
          </div>
          <MyMeetingRoomsOffice365 bookingDateFilter={this.state.bookingDateFilter} />
        </>
      )
    } else {
      return (
        <>
          {dateFilter}
          <MyMeetingRooms bookingDateFilter={this.state.bookingDateFilter} listingType={listingType} />
        </>
      );
    }
  }

  renderCriteriaString() {
    const crit = this.getCriteria();
    const task = {
      criteria: {
        when: crit?.when
      },
      criteriaString: null
    }
    this.model.makeCriteriaString(task);
    let criteriaString = this.state.criteriaString || task?.criteriaString || " ";
    return (
      <div className={"i-separated-left-right i-font-size-s"}>
       {criteriaString}
      </div>
    );
  }

  renderHeader() {
    const i18n = Context.instance.i18n;
    return (
      <SidebarHeader
        caption={Context.instance.aiim.getMeetingButtonLabel()}
        closeAriaLabel={i18n.events.closeAriaLabel}
        sidebarKey={this.props.sidebarKey}
        showBack={!!this.props.morePanel}
        backHandler={this.backClicked}
      />
    );
  }

  renderList() {
    const listItems = this.state.filteredItems;
    const i18n = Context.instance.i18n;
    let working = !!this.state.working;
    let content;
    if (working) {
      content = this.renderWorking();
      return content
    } else if (!listItems || listItems.length === 0) {
      let msg = "";
      if (listItems && listItems.length === 0) {
        msg = i18n.meetingRooms.noMatch;
        content = (
          <div className="i-separated-left-right">
            {msg}
          </div>
        )
      } else {
        content = this.renderWorking();
      }
      return content;
    } else {
      return (
        <div style={{ flexGrow: 1 }}>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <List 
                  ref={this._setRef}
                  estimatedRowSize={120}
                  width={width}
                  height={height}
                  rowRenderer={this.renderRow}
                  rowCount={listItems.length}
                  deferredMeasurementCache={this._cache}
                  rowHeight={this._cache.rowHeight}
                  overscanRowCount={3}
                />
              );
            }}
          </AutoSizer>
        </div>
      );
    }
  }

  renderRow = ({ index, key, style, parent, isScrolling }) => {

    let working = !!this.state.working;
    let content, row;
    let items = this.state.filteredItems;
    const item = (items && items[index]) || null;

    style = {
      ...style,
      margin: "4px 0",
    };

    if (working) {
      content = this.renderWorking();
    } else {
      row = this.renderItem(item, index);
      return (
        <CellMeasurer
          key={key}
          cache={this._cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <div style={style}>{row}</div>
        </CellMeasurer>
      );
    }
    return content;
  };

  renderItem(item: IMeetingRoom, idx: number) {
    const clicked = () => {
      this.itemClicked(item);
    };
    const bookClicked = (evt) => {
      evt.stopPropagation(true);
      this.bookClicked(item);
    };
    const pinIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#pin-16";
    const groupIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#group-16";
    const i18n = Context.instance.i18n;
    let cap;
    if (typeof item.capacity === "number") {
      cap = i18n.meetingRooms.capacityPattern.replace("{num}",item.capacity);
    }
    return (
      <div key={"oid" + item.oid} className={"i-available-unit"} tabIndex={idx}
        onClick={clicked}>
        <div className="i-flex-between">
          <div>
            <div className={"i-title i-overflow-wrap-anywhere"}>{item.name}</div>
            <div key="fl" className={"i-unit-info"}>
              <svg><use href={pinIcon}></use></svg>
              <div>{item.sfl && item.sfl.facilityLevel}</div>
            </div>
            {typeof item.capacity === "number" && (
              <div key="cap" className={"i-unit-info"}>
                <svg><use href={groupIcon}></use></svg>
                <div>{cap}</div>
              </div>
            )}
          </div>
          <div>
            <button className="i-button i-button-clear i-nowrap i-fill-brand-color"
              onClick={bookClicked}>
              {Icons.conferenceRoom()}
              <span>{i18n.meetingRooms.book.label}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderItems() {
    const i18n = Context.instance.i18n;
    let working = !!this.state.working;
    let content;
    let items = this.state.filteredItems;
    if (working) {
      content = this.renderWorking();
    } else if (!items || items.length === 0) {
      content = (
        <div className="i-separated-left-right">
          {i18n.meetingRooms.noMatch}
        </div>
      )
    } else {
      content = items.map((item, idx) => {
        return this.renderItem(item, idx);
      });
    }
    return (
      <div className={"i-section i-scroll-items"}>
        {content}
      </div>
    );
  }

  renderOptions() {
    const i18n = Context.instance.i18n;
    const disabled = false;

    const setDuration = dur => {
      let c = this.getCriteria();
      c.when.duration = dur;
      if (dur !== "allDay") {
        let dtStart = new Date(c.when.start.date);
        let dtEnd = dateUtil.addDuration(dtStart,dur);
        c.when.end.date = dtEnd;
      }
      const task = {
        criteria: { when: c.when },
        criteriaString: null
      }
      this.model.makeCriteriaString(task);
      this.setState({ criteriaString: task.criteriaString });
      this.executeTask("queryReservations");
    };

    let criteria = this.getCriteria();
    let duration = criteria.when.duration;

    const getDurationClass = dur => {
      let cn = "i-dd-button";
      if (dur === duration) {
        cn += " i-highlighted";
      }
      return cn;
    }

    const refresh = () => {
      this.setState({
        working: true
      })
      this.model.queryUnits();
    }

    return (
      <div className="i-separated-top">
        <div className="i-events-options i-separated-left-right i-separated-bottom">
          <div className="i-filter-button">
            <button className="i-dd-button" onClick={this.whenClicked}
              disabled={disabled}
              aria-label={i18n.meetingRooms.whenAriaLabel}>
              <div className="i-flex-between-centered">
                <ClockIcon size={16}/>
                <span className="i--ml">{i18n.meetingRooms.when}</span>
              </div>
            </button>
          </div>
          <div className="i-filter-button">
            <button className="i-dd-button" onClick={this.filterClicked}
              disabled={disabled}
              aria-label={i18n.meetingRooms.filterAriaLabel}>
              <div className="i-flex-between-centered">
                <FilterIcon size={16}/>
                <span className="i--ml">{i18n.meetingRooms.filter}</span>
              </div>
            </button>
          </div>
          <div className="i-filter-button">
            {this.renderSort(disabled)}
          </div>
          <div className={CSS.filterButton + " i-refresh"}>
            <button title={i18n.more.bookWorkspace.refresh} disabled={disabled}
              aria-label={i18n.more.bookWorkspace.refreshAriaLabel} className={CSS.ddButton}
              onClick={refresh}>
              {Icons.reload()}
            </button>
          </div>
        </div>
        <div className="i-events-options i-separated-left-right i-separated-bottom">
          <div className="i-filter-button">
            <button className={getDurationClass("thirtyMin")}
              disabled={disabled}
              onClick={() => {
                setDuration("thirtyMin");
              }}
              aria-label={i18n.meetingRooms.durations.thirtyMinAriaLabel}>
              {i18n.meetingRooms.durations.thirtyMin}
            </button>
          </div>
          <div className="i-filter-button">
            <button className={getDurationClass("oneHour")}
              disabled={disabled}
              onClick={() => {
                setDuration("oneHour");
              }}
              aria-label={i18n.meetingRooms.durations.oneHourAriaLabel}>
              {i18n.meetingRooms.durations.oneHour}
            </button>
          </div>
          <div className="i-filter-button">
            <button className={getDurationClass("twoHours")}
              disabled={disabled}
              onClick={() => {
                setDuration("twoHours");
              }}
              aria-label={i18n.meetingRooms.durations.twoHoursAriaLabel}>
              {i18n.meetingRooms.durations.twoHours}
            </button>
          </div>
          <div className="i-filter-button">
            <button className={getDurationClass("allDay")}
              disabled={disabled}
              onClick={() => {
                setDuration("allDay");
              }}
              aria-label={i18n.meetingRooms.durations.allDayAriaLabel}>
              {i18n.meetingRooms.durations.allDay}
            </button>
          </div>
        </div>
      </div>
    );
  }

  refreshClicked = () => {
    const type = getMeetingBookingSystemType();
    if (type === "office365") Topic.publish(Topic.RenderMeetingListOffice365, {});
    else Topic.publish(Topic.RenderMeetingRooms, {});
  }

  renderRefresh() {
    const i18n = Context.instance.i18n;
    const disabled = !!this.state.working;

    return (
      <div key="datetime" className={CSS.options}>
        <div className={CSS.filterButton + " i-refresh"}>
          <button title={i18n.more.bookWorkspace.refresh} disabled={disabled}
            aria-label={i18n.more.bookWorkspace.refreshAriaLabel} className={CSS.ddButton}
            onClick={this.refreshClicked}>
            {Icons.reload()}
          </button>
        </div>
      </div>
    )
  }

  renderReserveFor() {

    const onReserveForChange = (info) => {
      const reserveForOn = (info && info.areaIDs);
      const reserveForEmail = (info && info.email);
      const reserveForInfo = reserveForEmail && info;
      setTimeout(() => {
        this.setState({
          reserveForToggling: true
        }, async () => {
          this.setState({
            reserveForOn,
            reserveForEmail,
            reserveForInfo,
            reserveForToggling: false
          })
          const waitForRF = (reserveForOn && !reserveForEmail);
          if (!waitForRF) {
            const criteria = this.getCriteria();
            let ids = [], names = [];
            if (reserveForInfo) {
              if (criteria.filter?.areaIds && reserveForInfo?.areaIDs) {
                if (criteria.filter.areaIds.length > 0) {
                  ids = criteria.filter.areaIds.filter(id => reserveForInfo.areaIDs.includes(id));
                }
              }
              if (ids.length > 0 && Context.instance.areas) {
                const namesById = {};
                const areas = await Context.instance.areas;
                areas.forEach(area => {
                  const id = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_ID);
                  const name = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_NAME);
                  namesById[id] = name;
                })
                ids.forEach(id => {
                  names.push(namesById[id])
                })
              }
              criteria.filter.areaIds = ids;
              this.props.setRdxValue(Rdx.Keys.AREA_FILTERS_MEETING_ROOM,names);
            }
            this.model.reserveForInfo = reserveForInfo;
            this.executeTask("filterUnits")
          }
        })
      },10)
    }

    const feature = this.state.reserveForInfo?.occupantFeature;
    return <ReserveFor bookingType="meetingRoom" onChange={onReserveForChange} initialOccupantFeature={feature} />
  }

  renderSort(disabled) {
    const i18n = Context.instance.i18n;

    const sort = () => {
      setTimeout(() => {
        component.refresh(this);
        this.executeTask("sortUnits");
      },1);
    };

    const setSortBy = opt => {
      const c = this.getCriteria();
      if (c.sortBy !== opt) {
        c.sortDir = "asc";
      }
      c.sortBy = opt;
      sort();
    };

    const setSortDir = opt => {
      const c = this.getCriteria()
      c.sortDir = opt;
      sort();
    };

    let criteria = this.getCriteria();
    let sortBy = criteria.sortBy;
    let sortDir = criteria.sortDir;

    const getSortDirClass = opt => {
      let cn ="i-sorting-button";
      if (sortDir === opt) {
        cn += " i--active";
      }
      return cn;
    }

    const getSortIcon = () => {
      if (sortDir === "desc") {
        return <SortDescIcon size={16} />
      } else {
        return <SortAscIcon size={16} />
      }
    }

    const getIcon = opt => {
      let chk;
      if (sortBy === opt) {
        chk = (<span key="icon">{Icons.checkMark()}</span>);
      } else {
        chk = (<span key="icon" className="svg-icon i-icon-empty"></span>);
      }
      return chk;
    };

    let sortButton = (
      <div className="i-dd-button"
        aria-label={i18n.meetingRooms.sortAriaLabel}>
        <div className="i-flex-between-centered">
          {getSortIcon()}
          <span className="i--ml">{i18n.meetingRooms.sort}</span>
        </div>
      </div>
    );

    const sortOptions = [];

    sortOptions.push(
      <li key={"dir"} role="none" className="i-flex-between i-border-bottom">
        <div></div>
        <div className="i-sortby">
          <button type="button" className={getSortDirClass("asc")+" i--focusable"}
            title={i18n.meetingRooms.sortOptions.ascending}
            onClick={() => {
              setSortDir("asc");
            }}>
            {Icons.ascendingSort()}
          </button>
          <button type="button" className={getSortDirClass("desc")+" i--focusable"}
            disabled={sortBy === "distanceHome" || sortBy === "distanceBluedot"}
            title={i18n.meetingRooms.sortOptions.descending}
            onClick={() => {
              setSortDir("desc");
            }}>
            {Icons.descendingSort()}
          </button>
        </div>
      </li>
    );

    sortOptions.push(
      <li key={"name"} role="none" className="i-border-bottom">
        <button type="button" className="i-button-link i-padded i--focusable"
          onClick={() => {
            setSortBy("name");
          }}>
          {getIcon("name")}
          {i18n.meetingRooms.sortOptions.name}
        </button>
      </li>
    );
    if (this.model.canSortByCapacity()) {
      sortOptions.push(
        <li key={"capacity"} role="none" className="i-border-bottom">
          <button type="button" className="i-button-link i-padded i--focusable"
            onClick={() => {
              setSortBy("capacity");
            }}>
            {getIcon("capacity")}
            {i18n.meetingRooms.sortOptions.capacity}
          </button>
        </li>
      );
    }
    if (this.model.canSortByBluedotDistance()) {
      sortOptions.push(
        <li key={"distanceBluedot"} role="none">
          <button type="button" className="i-button-link i-padded i--focusable"
            onClick={() => {
              setSortBy("distanceBluedot");
            }}>
            {getIcon("distanceBluedot")}
            {i18n.meetingRooms.sortOptions.distanceBluedot}
          </button>
        </li>
      );
    }
    if (this.model.canSortByHomeDistance()) {
      sortOptions.push(
        <li key={"distanceHome"} role="none">
          <button type="button" className="i-button-link i-padded i--focusable"
            onClick={() => {
              setSortBy("distanceHome");
            }}>
            {getIcon("distanceHome")}
            {i18n.meetingRooms.sortOptions.distanceHome}
          </button>
        </li>
      );
    }

    let sortContent = (
      <ul className="i-padded" role="menu">{sortOptions}</ul>
    );

    if (disabled) sortContent = null;

    return (
      <DropdownButton
        closeOnSelect2={true}
        focusFirst={true}
        buttonClassName={"i-no-padding i-no-border"}
        buttonContent={sortButton}
        dropdownContent={sortContent}
        disabled={disabled}
        placement={"bottom-start"}>
      </DropdownButton>
    );
  }
  
  private _setRef = (ref) => {
    this._list = ref;
  };

  setBookingDateFilter = (criteria: IDateCriteria) => {
    const filterDataChanges = {
      dtStart: null,
      dtEnd: null
    };
    const where = makeWhere(criteria, filterDataChanges);
    const data = {
      where: where,
      criteria: criteria,
      dateStart: filterDataChanges.dtStart,
      dateEnd: filterDataChanges.dtEnd
    };
    this.where = where;
    this.setState({ bookingDateFilter: data });
  }

  whenClicked = () => {
    const i18n = Context.instance.i18n;
    let criteria = this.getCriteria();
    let whenComponent, okButton;
    const controller = new ModalController();
    const modalProps = {
      className: "i-modal-confirm",
      title: i18n.meetingRooms.when,
      okLabel: i18n.general.done,
      showOKCancel: true,
      hideCancel: true,
      closeOnOK: false,
      onMountOKButton: btn => okButton = btn,
      onOK: () => {
        let newCriteria: Partial<IMeetingRoomCriteria> = {};
        let ok = whenComponent.validate(newCriteria)
        if (ok) {
          criteria.when = newCriteria.when;
          const task = {
            criteria: { when: criteria.when },
            criteriaString: null
          }
          this.model.makeCriteriaString(task);
          this.setState({ criteriaString: task.criteriaString });
          this.executeTask("queryReservations");
          controller.close();
        }
      }
    };
    const content = (
      <When
        criteria={criteria}
        onMount={c => whenComponent = c}
        onUpdate={ok => {
          if (!okButton) return;
          ok ? okButton.enable() : okButton.disable();
        }}
        />
    )
    controller.show(modalProps,content);
  }

}

const mapStateToProps = (state) => {
  return {
    //rdxDateOption: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_OPTION),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_MeetingRooms);
