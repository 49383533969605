
export default class FieldNames {

  static CATEGORY_SUBTYPE = "category_subtype";

  static CATEGORY_TYPE = "category_type";

  static DESCRIPTION = "description";

  static DETAIL_ID = "detail_id";

  static FACILITY_ID = "facility_id";

  static FACILITY_NAME = "facility_name"; // Facilities: use NAME

  static FACILITY_LEVELS_ABOVE_GROUND = "levels_above_ground";

  static VALIDATION_STATUS = "validationstatus";

  static HEIGHT_RELATIVE = "height_relative";

  static IMAGE_URL = "image_url";

  static LEVEL_NUMBER = "level_number"; // this field is facility specific

  static LEVEL_ID = "level_id";

  static LEVEL_NAME = "level_name"; // Levels: use NAME

  static LOCATION_TYPE = "location_type"; // TODO was a string now an int

  static NAME = "name";

  static NAME_LONG = "name_long";

  static NAME_SHORT = "name_short";

  static NAME_SUBTITLE = "name_subtitle";

  static POINT_OF_INTEREST_ID = "point_of_interest_id";

  static SCHEDULE_EMAIL = "schedule_email";

  static SECTION_ID = "section_id";

  static SECTION_NAME = "section_name"; // Sections: use NAME

  static SITE_ID = "site_id";

  static SITE_NAME = "site_name"; // Sites: use NAME

  static UNIT_CAPACITY = "capacity";

  static UNIT_ID = "unit_id";

  static UNIT_NAME = "unit_name"; // Units: use NAME

  static UTILIZATION = "utilization";

  static VERTICAL_ORDER = "vertical_order";

  static ZONE_ID = "zone_id";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static UNITS_AREA_ID = "area_id";

  static UNITS_AREA_GROSS = "area_gross";

  static UNITS_AREA_NET = "area_net";

  static UNITS_CAPACITY = "capacity";

  static UNITS_SPACE_ASSIGNMENT_TYPE =  "assignment_type";

  static UNITS_USE_TYPE =  "use_type";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static PEOPLE_AREA_ID = "area_id";

  static PEOPLE_EMAIL = "email";

  //static PEOPLE_FIRSTNAME = "firstname";

  static PEOPLE_EMPLOYMENT_TYPE = "employment_type";

  static PEOPLE_FULLNAME = "knownas";

  static PEOPLE_JOB_TITLE = "job_title";

  //static PEOPLE_LASTNAME = "lastname";

  //static PEOPLE_LEVELNUMBER = "levelnumber";

  static PEOPLE_MOVE_DATE = "move_date";

  static PEOPLE_ORG_LEVEL_1 = "org_level_1";

  static PEOPLE_ORG_LEVEL_2 = "org_level_2";

  //static PEOPLE_SECTION_NAME = "sectionname"; // section_name has null values

  //static PEOPLE_SPACE_ASSIGNMENT_TYPE =  "space_assignment";

  static PEOPLE_START_DATE = "start_date";

  static PEOPLE_UNIT_ID = "unit_id"; // TODO?

   /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static DETAILS_USE_TYPE =  "use_type";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static EVENT_DATE_END = "date_end";

  static EVENT_DATE_START = "date_start";

  static EVENT_ID = "event_id";

  static EVENT_TYPE = "use_type";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static AREA_ID = "area_id";

  static AREA_NAME = "area_name";

  static AREA_TYPE = "area_type";

  static AREAS_RESTRICTED = "restricted";

  static AREAS_CONFIG = "config";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static AREA_ROLES_AREA_ID = "area_id";

  static AREA_ROLES_EMAIL = "email";

  static AREA_ROLES_ROLE = "role";

  static AREA_ROLES_USERNAME = "username";

  /* . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . */

  static USERNAME = "username";

  static PORTAL_GROUP_ID = "portal_group_id";

  static REVIEW_STATUS = "review_status";

  static PLAN_ID = "plan_id";

  // static OBJECT_ID = "object_id";

  // static READY = "ready";
  static COMMENT_PLAN_ID = "plan_id";

  static COMMENT_PARENT_CID = "parent_cid";

  static COMMENT_COMMENT = "comment";

  static COMMENT_AUTHOR = "author";

  static COMMENT_AUTHOR_DATE = "author_date";

  /* . . . . . . . . . . . . Reservations . . . . . . . . . . . . . . */

  static RESERVED_BY_USERNAME = "reserved_by_username";

  static RESERVED_BY_FULL_NAME = "reserved_by_full_name";

  static RESERVED_FOR_USERNAME = "reserved_for_username";

  static RESERVED_FOR_FULL_NAME = "reserved_for_full_name";

  static STATE = "state";

  static START_TIME = "start_time";

  static END_TIME = "end_time";

  static CHECK_IN_TIME = "check_in_time";

  static CHECK_OUT_TIME = "check_out_time";

  static SHAPE = "shape";

  static TITLE = "title";

  static DESCRIPTION = "description";

  static ALL_DAY = "all_day";

  static RECURRENCE_ID = "recurrence_id";

  static RECURRENCE_CONFIG = "recurrence_config";

  // For Units Layer
  static RESERVATION_METHOD = "reservation_method";

  /* . . . . . . . . . . . . ReservationPrivileges . . . . . . . . . . . . . . */

  static PRIVILEGE = "privilege";

  /* . . . . . . . . . . . . Routing Barriers . . . . . . . . . . . . . . */

  static BARRIER_START_TIME = "start_time";

  static BARRIER_END_TIME = "end_time";

  /* . . . . . . . . . . . . Configurable Filter Columns . . . . . . . . . . . . . . */

  static SHAPE_AREA = "shape__area";

  static SHAPE_LENGTH = "shape__length";

}
