import styled from "styled-components";

import CalciteSideNav from "calcite-react/SideNav";

const Container = styled.div`
  z-index: ${props => props.theme.levels.top};
  display: flex;
  height: 100%;
`;

const SideNav = styled(CalciteSideNav)`
  width: ${props => props.theme.sideNavWidth};
  border: 0;
  background: ${props => props.theme.palette.sectionGrey};
`;

const TabPanelContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
`;

export { Container, SideNav, TabPanelContainer };
