import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import QueryAll from "../../base/QueryAll";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as queryUtil from "../../base/queryUtil";
import * as selectionUtil from "../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../base/sourceUtil";
import { HomeOfficeId, HomeOfficeName } from "../../base/AreasTable";
export default class ChangeReader extends BaseClass {

  queryPeople(queryCriteria,changes) {
    const layer = sourceUtil.getPeopleLayer();
    return this._querySubset(layer,queryCriteria,changes);
  }

  readFeatures(options) {
    const promise = new Promise((resolve,reject) => {
      const project = Context.instance.spaceplanner.planner.project;
      const plan = Context.instance.spaceplanner.activePlan;
      const task = {
        options: options || {},
        plan: plan,
        chunks: [],
        features: [],
        noMatch: false,
        people: {
          isPeople: true,
          layer: sourceUtil.getPeopleLayer(),
          objectIds: [],
          globalIds: [],
          featuresByGlobalId: {},
          itemsByGlobalId: {},
          baseline: {
            layer: null,
            featuresByGlobalId: {}
          }
        },
        units: {
          isUnits: true,
          layer: sourceUtil.getUnitsLayer(),
          objectIds: [],
          globalIds: [],
          featuresByGlobalId: {},
          itemsByGlobalId: {},
          baseline: {
            layer: null,
            featuresByGlobalId: {}
          }
        },
        areas: {
          table: plan.areasTable.table,
          areasByAreaId: {
            [HomeOfficeId]: {
              areaId: HomeOfficeId,
              areaName: HomeOfficeName,
              areaType: OfficePlan.SpaceAssignmentTypes.home
            } 
          },
          baseline: {
            serviceUrl: null,
            tableInfo: null,
            areasByAreaId: {
              [HomeOfficeId]: {
                areaId: HomeOfficeId,
                areaName: HomeOfficeName,
                areaType: OfficePlan.SpaceAssignmentTypes.home
              }
            },
          }
        }
      };
      if (project.isHosted) {
        task.people.baseline.layer = project.hostedInfo.peopleLayer;
        task.units.baseline.layer = project.hostedInfo.unitsLayer;
        task.areas.baseline.serviceUrl = project.hostedInfo.sourceInfo.serviceUrl;
        task.areas.baseline.tableInfo = project.hostedInfo.sourceInfo.areasTableInfo;
      } else {
        const tbl = task.plan.areasTable.table;
        task.people.baseline.layer = task.people.layer;
        task.units.baseline.layer = task.units.layer;
        task.areas.baseline.serviceUrl = task.units.layer.url;
        task.areas.baseline.tableInfo = {
          id: tbl.layerId,
          globalIdField: tbl.globalIdField,
          fields: tbl.fields
        }
      }

      let fieldsSection = task.people;
      let layer = task.people.layer;
      let fields = layer.fields;
      this._setFieldProperty(fieldsSection,"nameField",fields,FieldNames.PEOPLE_FULLNAME,layer.globalId);
      this._setFieldProperty(fieldsSection,"unitIdField",fields,FieldNames.PEOPLE_UNIT_ID);
      this._setFieldProperty(fieldsSection,"unitNameField",fields,FieldNames.UNIT_NAME);
      this._setFieldProperty(fieldsSection,"areaIdField",fields,FieldNames.PEOPLE_AREA_ID);
      this._setFieldProperty(fieldsSection,"moveDateField",fields,FieldNames.PEOPLE_MOVE_DATE);
      task.people.orderBy = task.people.nameField;

      fieldsSection = task.people.baseline;
      layer = task.people.baseline.layer;
      fields = layer.fields;
      this._setFieldProperty(fieldsSection,"nameField",fields,FieldNames.PEOPLE_FULLNAME,layer.globalId);
      this._setFieldProperty(fieldsSection,"unitIdField",fields,FieldNames.PEOPLE_UNIT_ID);
      this._setFieldProperty(fieldsSection,"unitNameField",fields,FieldNames.UNIT_NAME);
      this._setFieldProperty(fieldsSection,"areaIdField",fields,FieldNames.PEOPLE_AREA_ID);
      this._setFieldProperty(fieldsSection,"moveDateField",fields,FieldNames.PEOPLE_MOVE_DATE);

      Promise.resolve().then(() => {
        if (task.options.objectIds) {
          task.people.objectIds = task.options.objectIds;
        } else {
          return this._extractIds(task,task.people).then(() => {
            if (task.options.queryCriteria) {
              task.options.queryCriteria.objectIds = task.people.objectIds;
              //console.log("task.options.queryCriteria",task.options.queryCriteria)
            }
          });
        }
      }).then(() => {
        return this._readAllAreas(task,task.areas);
      }).then(result => {
        return this._readPeople(task);
      }).then(result => {
        this._removeUnchangedPeople(task);
      }).then(result => {
        console.log("ChangeReader.readFeatures.task",task)
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _extractIds(task,section) {
    const promise = new Promise((resolve,reject) => {
      const layer = section.layer;
      const url = Context.checkMixedContent(layer.url+"/extractChanges");
      const layerId = layer.layerId;
      const layers = [layerId];
      let serverGen = 0;
      if (!task.plan.isVersioned) {
        const item = task.plan.planServiceItem;
        const props = item && item.properties && item.properties.indoors;
        const lastServerGens = props && props.planServerGens;
        serverGen = lastServerGens[0].serverGen;
      }

      //console.log("servergennnnnnnnnnnnnnnnnnnnn",serverGen);
      const layerServerGens = [{"id": layerId, "serverGen": serverGen}];

      /*
      const layerQueries = {
        [peopleLayerInfo.id]: {queryOption: "all",},
        [unitsLayerInfo.id]: {queryOption: "all"},
        [areasTableInfo.id]: {queryOption: "all"}
      };
      let layerServerGens = [];
      if (section.lastServerGens) {
        layerServerGens = section.lastServerGens;
      } else {
        changeTrackingInfo.layerServerGens.forEach(l => {
          if ((l.id === peopleLayerInfo.id) ||
              (l.id === unitsLayerInfo.id) ||
              (l.id === areasTableInfo.id)) {
            layerServerGens.push(l);
          }
        });
      }
       */

      const params = {
        f: "json",
        layers: JSON.stringify(layers),
        layerServerGens: JSON.stringify(layerServerGens),
        gdbVersion: layer.gdbVersion,
        returnIdsOnly: true,
        returnInserts: true,
        returnUpdates: true,
        returnDeletes: true,
        returnExtentOnly: false,
        returnAttachments: false,
        dataFormat: "json",
        transportType: "esriTransportTypeEmbedded",
      };
      const esriRequest = Context.instance.lib.esri.esriRequest;
      const options = {query: params, method: "post", responseType: "json"};
      esriRequest(url,options).then(result => {
        const edits = result && result.data && result.data.edits;
        //console.log("edits",edits)
        if (Array.isArray(edits)) {
          edits.forEach(edit => {
            if (edit.id === layerId) {
              const adds = (edit.objectIds && edit.objectIds.adds) || [];
              const updates = (edit.objectIds && edit.objectIds.updates) || [];
              const deletes = (edit.objectIds && edit.objectIds.deletes) || [];
              const ids = updates.concat(adds).concat(deletes);
              section.objectIds = ids;
              section.deletedIds = deletes;
            }
          })
        }
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _makePersonItem(task,section,feature) {
    const layer = section.layer;
    const v = aiimUtil.getAttributeValue(feature.attributes,section.nameField);
    const item = {
      name: v,
      inbound: {
        isUnassigned: false,
        asnName: null,
        asnType: null,
        asnTypeLabel: null,
        moveDate: null,
      },
      outbound: null
    };
    this._setPersonAssignmentInfo(task,section,layer,feature,item.inbound);
    return item;
  }

  _makeUnitItem(task,section,feature) {
    const layer = section.layer;
    const v = aiimUtil.getAttributeValue(feature.attributes,section.nameField);
    const item = {
      name: v,
      inbound: {
        isUnassigned: false,
        asnName: null,
        asnTypeLabel: null
      },
      outbound: null
    };
    this._setUnitAssignmentInfo(task,section,layer,feature,item.inbound);
    return item;
  }

  _queryBaselineFeatures(task,section) {
    const globalIds = section.globalIds || [];
    const deletedIds = section.deletedIds || [];
    if (globalIds.length === 0 && deletedIds.length === 0) {
      return Promise.resolve();
    }
    const promise = new Promise((resolve,reject) => {
      const lib = Context.instance.lib;
      const layer = section.baseline.layer;
      const globalIdField = layer.globalIdField;
      const objectIdField = layer.objectIdField;
      const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);

      let where;
      if (globalIds.length > 0) {
        const values = [];
        globalIds.forEach(id => {
          let v = "'" + selectionUtil.escSqlQuote(id) + "'";
          values.push(v);
        });
        where = globalIdField + " IN (" + values.join(",") + ")";
      }

      if (deletedIds.length > 0) {
        let where2 = objectIdField + " IN (" + deletedIds.join(",") + ")";
        if (!where) {
          where = where2;
        } else {
          where = "("+where+") OR ("+where2+")";
        }
      }
      //console.log("where",where)

      const query = new lib.esri.Query();
      query.where = where;
      query.outFields = ["*"];
      query.returnGeometry = false;
      query.returnZ = false;

      //queryUtil.applyGdbVersion(layer,query);

      const featuresByGlobalId = section.baseline.featuresByGlobalId;
      const itemsByGlobalId = section.itemsByGlobalId;
      const qaopts = {
        layer: layer,
        perFeatureCallback: feature => {
          const gid = feature.attributes[globalIdField];
          featuresByGlobalId[gid] = feature;
          let item = itemsByGlobalId[gid];
          if (item) {
            item.outbound = {
              isBaseline: true,
              isUnassigned: false,
              asnName: null,
              asnTypeLabel: null
            };
            if (section.isPeople) {
              this._setPersonAssignmentInfo(task,section,layer,feature,item.outbound);
            } else if (section.isUnits) {
              this._setUnitAssignmentInfo(task,section,layer,feature,item.outbound);
            }
          } else if (section.isPeople) {
            const oid = feature.attributes[objectIdField];
            if (deletedIds.indexOf(oid) !==  -1) {
              //console.log("handle deloid",oid)
              let v = aiimUtil.getAttributeValue(feature.attributes,section.nameField);
              item = {
                name: v,
                inbound: {
                  wasDeleted: true,
                },
                outbound: {
                  isBaseline: true,
                  isUnassigned: false,
                  asnName: null,
                  asnTypeLabel: null
                }
              };
              this._setPersonAssignmentInfo(task,section,layer,feature,item.outbound);
              itemsByGlobalId[gid] = item;
              section.globalIds.push(gid);
            }
          }
        }
      };

      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        //console.log("_queryBaselineFeatures.queryAll.result",result)
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _queryPlanFeatures(task,section) {
    const objectIds = section.objectIds;
    if (!objectIds || objectIds.length === 0) {
      return Promise.resolve();
    }
    const promise = new Promise((resolve,reject) => {
      const lib = Context.instance.lib;
      const layer = section.layer;
      const globalIdField = layer.globalIdField;
      const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);

      const qc = task.options.queryCriteria;
      const query = new lib.esri.Query();
      query.objectIds = objectIds;
      query.outFields = ["*"];
      query.returnGeometry = true;
      query.returnZ = true;

      if(task.options.noFilter) {
        let base = selectionUtil.getBaseDefinitionExpression(layer);
        if(base) query.where = base;
      }else{
        if (qc && qc.where && qc.where !== "1=1") {
          query.where = qc.where;
        }
      }
      if (section.orderBy) query.orderByFields = [section.orderBy];
      if (qc) {
        let orderByFields = qc.makeOrderByFields();
        if (orderByFields) query.orderByFields = orderByFields;
      }

      queryUtil.applyGdbVersion(layer,query);

      const globalIds = section.globalIds;
      const featuresByGlobalId = section.featuresByGlobalId;
      const itemsByGlobalId = section.itemsByGlobalId;
      const qaopts = {
        layer: layer,
        perFeatureCallback: feature => {
          const gid = feature.attributes[globalIdField];
          globalIds.push(gid);
          featuresByGlobalId[gid] = feature;
          if (section.isPeople) {
            itemsByGlobalId[gid] = this._makePersonItem(task,section,feature);
          } else if (section.isUnits) {
            itemsByGlobalId[gid] = this._makeUnitItem(task,section,feature);
          }
        }
      };

      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        //console.log("_queryPlanFeatures.queryAll.result",result,qaopts)
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _querySubset(layer,queryCriteria,changes) {
    let objectIds = queryCriteria.objectIds;
    let filteredIds = changes.people.globalIds || [];
    let deletedIds = changes && changes.people && changes.people.deletedIds;
    if (!objectIds || objectIds.length === 0) {
      return Promise.resolve([]);
    }
    const promise = new Promise((resolve,reject) => {
      const lib = Context.instance.lib;
      const globalIdField = layer.globalIdField;
      let url = Context.checkMixedContent(layer.url+"/"+layer.layerId);

      const query = new lib.esri.Query();
      query.objectIds = objectIds;
      query.outFields = [layer.globalIdField];
      query.returnGeometry = false;
      query.returnZ = false;
      if (queryCriteria && queryCriteria.where && queryCriteria.where !== "1=1") {
        query.where = queryCriteria.where;
      }
      if (queryCriteria) {
        let orderByFields = queryCriteria.makeOrderByFields();
        if (orderByFields) query.orderByFields = orderByFields;
      }

      queryUtil.applyGdbVersion(layer,query);

      const globalIds = [], idx = {};
      const qaopts = {
        layer: layer,
        perFeatureCallback: feature => {
          const gid = feature.attributes[globalIdField];
          if (!idx[gid] && filteredIds.includes(gid)) {
            idx[gid] = true;
            globalIds.push(gid);
          }
        }
      };

      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        //console.log("_querySubset.queryAll.result",result)
        if (deletedIds && deletedIds.length > 0) {
          query.gdbVersion = null;
          query.objectIds = deletedIds;
          layer = changes.people.baseline.layer;
          url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
          return qa.execute(url,query,qaopts);
        }
      }).then(() => {
        resolve(globalIds);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _readAllAreas(task,section) {
    var promises = [];
    promises.push(this._readAreas(task,section,false));
    promises.push(this._readAreas(task,section,true));
    return Promise.all(promises);
  }

  _readAreas(task,section,forBaseline) {
    const promise = new Promise((resolve,reject) => {
      const lib = Context.instance.lib;

      let url, layer, numPerPage, globalIdField, fields, areasByAreaId;
      if (!forBaseline) {
        areasByAreaId = section.areasByAreaId;
        layer = section.table;
        globalIdField = layer.globalIdField;
        fields = layer.fields;
        url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
      } else {
        areasByAreaId = section.baseline.areasByAreaId;
        const serviceUrl = section.baseline.serviceUrl;
        const tableInfo = section.baseline.tableInfo;
        globalIdField = tableInfo.globalIdField;
        fields = tableInfo.fields;
        url = Context.checkMixedContent(serviceUrl+"/"+tableInfo.id);
      }

      const areaIdField = aiimUtil.findField(fields,FieldNames.AREA_ID).name;
      const areaNameField = aiimUtil.findField(fields,FieldNames.AREA_NAME).name;
      const areaTypeField = aiimUtil.findField(fields,FieldNames.AREA_TYPE).name;

      const query = new lib.esri.Query();
      query.where = "1=1";
      query.outFields = ["*"];
      query.returnGeometry = false;
      query.returnZ = false;

      if (!forBaseline) queryUtil.applyGdbVersion(layer,query);

      const qaopts = {
        layer: layer,
        numPerPage: numPerPage,
        perFeatureCallback: feature => {
          const aid = feature.attributes[areaIdField];
          areasByAreaId[aid] = {
            globalId: feature.attributes[globalIdField],
            areaId: aid,
            areaName: feature.attributes[areaNameField],
            areaType: feature.attributes[areaTypeField]
          }
        }
      };

      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        //console.log("_readAreas.queryAll.result",forBaseline,result)
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _readPeople(task) {
    const promise = new Promise((resolve,reject) => {
      this._queryPlanFeatures(task,task.people).then(result => {
        return this._queryBaselineFeatures(task,task.people);
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _removeUnchangedPeople(task) {
    const globalIds = task.people.globalIds;
    const itemsByGlobalId = task.people.itemsByGlobalId;
    if (globalIds && globalIds.length > 0) {
      let ids = [];
      globalIds.forEach(gid => {
        let item = itemsByGlobalId[gid];
        let rm = false;
        if (item && item.inbound && item.outbound) {
          if (item.inbound.hasUnitId && item.outbound.hasUnitId) {
            if (item.inbound.unitId === item.outbound.unitId) {
              rm = true;
            }
          } else if (item.inbound.hasAreaId && item.outbound.hasAreaId) {
            if (item.inbound.areaId === item.outbound.areaId) {
              rm = true;
            }
          } else if (!item.inbound.hasUnitId && !item.outbound.hasUnitId &&
                     !item.inbound.hasAreaId && !item.outbound.hasAreaId) {
            rm = true;
          }
        } else if (item && item.inbound && !item.outbound) {
          if (item.inbound.isUnassigned) {
            rm = true; // Issue #3949
          }
        }
        if (!rm) ids.push(gid);
        //if (rm) console.log("unchanged:",item.outbound.name);
      });
      task.people.globalIds = ids;
    }
  }

  _setFieldProperty(section,property,fields,fieldName,def) {
    var v = def;
    var fld = aiimUtil.findField(fields,fieldName);
    if (fld) v = fld.name;
    if (v) section[property] = v;
  }

  _setPersonAssignmentInfo(task,section,layer,feature,info) {
    let v;
    let unitIdField = section.unitIdField;
    let areaIdField = section.areaIdField;
    let unitNameField = section.unitNameField;
    let moveDateField = section.moveDateField;
    let areasByAreaId = task.areas.areasByAreaId;
    const i18n = Context.instance.i18n;
    if (info.isBaseline) {
      unitIdField = section.baseline.unitIdField;
      areaIdField = section.baseline.areaIdField;
      unitNameField = section.baseline.unitNameField;
      moveDateField = section.baseline.moveDateField;
      areasByAreaId = task.areas.baseline.areasByAreaId;
    }
    // if (!section.unitIdField) {
    //   fld = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_UNIT_ID);
    //   if (fld) section.unitIdField = fld.name;
    //   else section.unitIdField = FieldNames.PEOPLE_UNIT_ID;
    //   unitIdField = section.unitIdField
    // }
    // if (!section.areaIdField) {
    //   fld = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_AREA_ID);
    //   if (fld) section.areaIdField = fld.name;
    //   else section.areaIdField = FieldNames.PEOPLE_AREA_ID;
    //   areaIdField = section.areaIdField
    // }
    // if (!section.unitNameField) {
    //   fld = aiimUtil.findField(layer.fields,FieldNames.UNIT_NAME);
    //   if (fld) section.unitNameField = fld.name;
    //   else section.unitNameField = FieldNames.UNIT_NAME;
    //   unitNameField = section.unitNameField
    // }
    const unitId = aiimUtil.getAttributeValue(feature.attributes,unitIdField);
    const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
    if (hasUnitId) {
      info.unitId = unitId;
      info.hasUnitId = hasUnitId;
      v = aiimUtil.getAttributeValue(feature.attributes,unitNameField);
      if (!v) v = unitId;
      info.asnName = v;
      info.asnType = OfficePlan.SpaceAssignmentTypes.office;
      info.asnTypeLabel = i18n.spaceplanner.assignmentType.office;
      const plan = task.plan;
      if (plan && plan.unitNameCache && plan.unitNameCache.namesPerUnitId) {
        v = plan.unitNameCache.namesPerUnitId[unitId];
        if (v) info.asnName = v;
      }
    } else {
      const areaId = aiimUtil.getAttributeValue(feature.attributes,areaIdField);
      const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
      if (hasAreaId) {
        info.areaId = areaId;
        info.hasAreaId = hasAreaId;
        info.asnName = "";
        info.asnTypeLabel = "area";
        var area = areasByAreaId[areaId];
        if (area) {
          info.asnName = area.areaName;
          info.asnTypeLabel = area.areaType;
          if (area.areaType === "hotdesk" || area.areaType === "hotel" || area.areaType === "workspace") {
            info.asnTypeLabel = i18n.spaceplanner.people.assignTo.workspaceArea.label;
            info.asnType = "workspace";
          } else if (area.areaType === OfficePlan.SpaceAssignmentTypes.home) {
            info.asnTypeLabel = i18n.spaceplanner.assignmentType.home;
            info.asnType = OfficePlan.SpaceAssignmentTypes.home;
          }
        }
      } else {
        info.isUnassigned = true;
        info.asnName = "";
        info.asnTypeLabel = i18n.spaceplanner.people.noAssignment;
      }
    }
    if (moveDateField) {
      info.moveDate = aiimUtil.getAttributeValue(feature.attributes,moveDateField);
    }
  }

  _setUnitAssignmentInfo(task,section,layer,feature,info) {
  }

  sort(props,changes,globalIds) {
    if (props && globalIds && globalIds.length > 1) {
      let field = props.field;
      let dir = props.dir;
      globalIds.sort((a,b) => {
        let av, bv, propsA, propsB;
        if (field === "name") {
          propsA = changes.people.itemsByGlobalId[a] || {};
          propsB = changes.people.itemsByGlobalId[b] || {};
        } else if (field === "from") {
          propsA = changes.people.itemsByGlobalId[a].outbound || {};
          propsB = changes.people.itemsByGlobalId[b].outbound || {}
        } else {
          propsA = changes.people.itemsByGlobalId[a].inbound || {};
          propsB = changes.people.itemsByGlobalId[b].inbound || {};
        }
        if (field === "name") {
          av = propsA.name || "";
          bv = propsB.name || "";
          if (dir === "asc") return av.localeCompare(bv);
          else return bv.localeCompare(av);
        } else if (field === "from" || field === "to") {
          av = propsA.asnName || "";
          bv = propsB.asnName || "";
          if (dir === "asc") return av.localeCompare(bv);
          else return bv.localeCompare(av);
        } else if (field === "date") {
          av = propsA.moveDate;
          bv = propsB.moveDate;
          if (dir === "asc") {
            if (av < bv) return -1;
            else if (av > bv) return 1;
          } else {
            if (av < bv) return 1;
            else if (av > bv) return -1;
          }
        }
        return 0;
      })

    }
    return globalIds;
  }

}
