import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as transactions from "../transaction/transactions";
import Topic from "../../../context/Topic";

export default class ReviewersTable extends BaseClass {
  table
  _reviewersByPlanId
  _reviewers

  addReviewer(username, planId) {
    return new Promise((resolve, reject) => {
      transactions.addReviewer(username, planId).then(() => {
        return this.refresh()
      }).then(() => {
        Topic.publish(Topic.RefreshReviewers, {})
        resolve()
      }).catch(e => {
        reject(e)
      })
    })
  }

  addPortalGroup(portalMembers) {
    return new Promise((resolve, reject) => {
      transactions.selectPortalGroupReviewers(portalMembers).then(() => {
        return this.refresh()
      }).then(() => {
        resolve();
      }).catch(e =>  {
        reject(e);
      })
    });
  }

  updateReviewerStatus(username, status) {
    return new Promise((resolve, reject) => {
      transactions.updateReviewerStatus(username, status).then(() => {
        return this.refresh()
      }).then(() => {
        Topic.publish(Topic.RefreshReviewers, {})
        resolve()
      }).catch(e => {
        reject(e)
      })
    })
  }

  removeReviewer(username) {
    return new Promise((resolve, reject) => {
      transactions.removeReviewer(username).then(() => {
        return this.refresh()
      }).then(() => {
        Topic.publish(Topic.RefreshReviewers, {})
        resolve()
      }).catch(e => {
        reject(e)
      })
    })
  }

  removeAll(planId) {
    return new Promise((resolve, reject) => {
      const reviewers = this._reviewersByPlanId[planId] && this._reviewersByPlanId[planId].reviewers
      if (reviewers) {
        transactions.removeAllReviewers(reviewers).then(() => {
          return this.refresh()
        }).then(() => {
          Topic.publish(Topic.RefreshReviewers, {})
          resolve()
        }).catch(e => {
          reject(e)
        })
      }
    })
  }

  allReviewersAccepted(planId) {
    return new Promise((resolve, reject) => {
      this.refresh().then(() => {
        const reviewers = this._reviewersByPlanId[planId]
        let accepted = true
        reviewers.forEach(reviewer => {
          const status = aiimUtil.getAttributeValue(reviewer.attributes, FieldNames.REVIEW_STATUS)
          if (status !== "accepted") accepted = false
        })
        resolve(accepted)
      }).catch(e => {
        console.error("Couldn't query reviewers table", e)
        reject(e)
      })
    })
  }

  refresh() {
    if (!this.table) return Promise.resolve()
    return new Promise((resolve, reject) => {
      const reviewersByPlanId = {}
      const table = this.table
      const lib = Context.instance.lib
      const url = Context.checkMixedContent(table.url + "/" + table.layerId)
      const queryTask = new lib.esri.QueryTask({url: url})
      const query = new lib.esri.Query()
      query.outFields = ["*"]
      query.where = "1=1"
      if (table.gdbVersion) query.gdbVersion = table.gdbVersion
      queryTask.execute(query).then(result => {
        let fields = result.fields || table.fields
        let reviewers = []
        let nameField = aiimUtil.findField(fields, FieldNames.USERNAME)
        let planIdField = aiimUtil.findField(fields, FieldNames.PLAN_ID)
        if (!nameField || !planIdField) {
          console.error("USERNAME or PLAN_ID fields not found")
          resolve()
        } else {
          result.features.forEach(row => {
            const planId = aiimUtil.getAttributeValue(row.attributes, planIdField.name)
            reviewers.push(row)
  
            if (!reviewersByPlanId[planId]) {
              reviewersByPlanId[planId] = {reviewers: [row]}
            } else {
              reviewersByPlanId[planId].reviewers.push(row)
            }
          })
          this._sort(reviewers, nameField.name)
          Object.keys(reviewersByPlanId).forEach(planId => {
            this._sort(reviewersByPlanId[planId].reviewers, nameField.name)
          })
          this._reviewersByPlanId = reviewersByPlanId
          this._reviewers = reviewers
          resolve()
        }
      }).catch(e => {
        console.error("Error querying REVIEWERS table", e)
        reject(e)
      })
    })
  }

  _sort(reviewers, typeFieldName) {
    reviewers.sort((a, b) => {
      const aName = aiimUtil.getAttributeValue(a.attributes, typeFieldName) || ""
      const bName = aiimUtil.getAttributeValue(b.attributes, typeFieldName) || ""
      return aName.localeCompare(bName)
    })
  }

  load(table) {
    const promise = new Promise((resolve,reject) => {
      this.table = table;
      table.when().then(() => {
        if (table.loadStatus === "failed") {
          console.error("ReviewersTable: load failed");
        }
        return this.refresh()
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.error("Error loading REVIEWERS table", ex);
        reject(ex);
      });
      table.load();
    });
    return promise;
  }

  getReviewersByPlanId(planId) {
    return this._reviewersByPlanId && this._reviewersByPlanId[planId]
  }

  queryReviewersByPlanId(planId) {
    return this.refresh().then(() => {
      return this._reviewersByPlanId[planId]
    })
  }

  queryReviewers() {
    return this.refresh().then(() => {
      return this._reviewers
    })
  }
}