import Context from "../context/Context";
import Topic from "../context/Topic";

// // *** can't get the ownerFolder from a search
// export function checkForExistingTitle(title,owner,folder) {
//   const promise = new Promise((resolve,reject) => {
//     const esriRequest = Context.instance.lib.esri.esriRequest;
//     const t = val.escapeForLucene(title);
//     const params = {f: "json"};
//     // if (props.title.indexOf("\"" === 1)) {
//     //   params.q = "(title:\""+t+"\")";
//     // } else {
//     //   params.q = "("+t+")";
//     // }
//     params.q = "(title:("+t+"))";
//     const o = val.escapeForLucene(owner);
//     params.q += " AND (owner:("+o+"))";
//     params.num = 100;
//     const url = Context.instance.getSharingUrl()+"/rest/search";
//     const options = {query: params, responseType: "json"};
//     esriRequest(url,options).then(result => {
//       console.log("portalUtil::checkForExistingTitle.result",result)
//       let found = false;
//       const results = result && result.data && result.data.results;
//       if (Array.isArray(results)) {
//         results.some(item => {
//           return false;
//         });
//       }
//       resolve(found);
//     }).catch(ex => {
//       console.error("Error searching portal",ex);
//       reject(ex);
//     });
//   });
//   return promise;
// }

export function applyPortalTheme(portal){
  let theme=null;
  let sharedTheme = portal.portalProperties && portal.portalProperties.sharedTheme ;
  let indoorsTheme=Context.getInstance().configuration._configurables.theme;
  if(sharedTheme && sharedTheme.body && sharedTheme.header && sharedTheme.button){
    theme = {
      "theme-color-brand": sharedTheme.header.background || indoorsTheme["theme-color-brand"],
      "theme-color-brand-text": sharedTheme.header.text || indoorsTheme["theme-color-brand-text"],
      "theme-color-background": sharedTheme.body.background || indoorsTheme["theme-color-background"],
      "theme-color-text": sharedTheme.body.text || indoorsTheme["theme-color-text"],
      "theme-color-link": sharedTheme.body.link || indoorsTheme["theme-color-link"],
      "theme-color-button": sharedTheme.button.background || indoorsTheme["theme-color-button"],
      "theme-color-button-text": sharedTheme.button.text || indoorsTheme["theme-color-button-text"]
    }
  }else{
      theme= indoorsTheme;
  }
    const cfg = Context.getInstance().config;
    Object.assign(cfg.theme, theme);
    // cfg.theme["theme-color-background"]=theme["theme-color-background"];
    // cfg.theme["theme-color-brand"]= theme["theme-color-brand"];
    // cfg.theme["theme-color-brand-text"]="#ffffff";
    // cfg.theme["theme-color-button"]="#005e95";
    // cfg.theme["theme-color-button-text"]="#ffffff";
    // cfg.theme["theme-color-link"]="#005e95";
    // cfg.theme["theme-color-text"]="#4c4c4c";
    //console.log(Context.instance.config.theme );
    Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme})
}

export function createFolder(title,owner) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  const url = getUserContentUrl(owner)+"/createFolder";
  const params = {
    f: "json",
    title: title
  };
  const options = {query: params, method: "post", responseType: "json"};
  return esriRequest(url,options);
}

export function deleteItems(itemIds,owner) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  const url = getUserContentUrl(owner)+"/deleteItems";
  if (Array.isArray(itemIds)) itemIds = itemIds.join(",");
  const params = {
    f: "json",
    items: itemIds,
    permanentDelete: true
  };
  const options = {query: params, method: "post", responseType: "json"};
  return esriRequest(url,options);
}

function ensureArrayValue(a,value) {
  let modified = false;
  if (!Array.isArray(a)) {
    a = [];
    modified = true;
  }
  const b = [], lc = value.toLowerCase();
  let found = false;
  a.forEach(v => {
    if (typeof v === "string" && v.toLowerCase() === lc) {
      if (!found) {
        if (v === value) {
          b.push(v);
        } else {
          b.push(value);
          modified = true;
        }
      } else {
        found = true;
      }
      found = true;
    } else {
      b.push(v);
    }
  });
  if (!found) {
    b.push(value);
    modified = true;
  }
  return {
    values: modified ? b : a,
    modified: modified
  };
}

export function ensureTags(item,values) {
  if (!item.tags) item.tags = [];
  if (typeof values === "string") values = [values];
  if (Array.isArray(values)) {
    values.forEach(v => {
      let result = ensureArrayValue(item.tags,v);
      if (result.modified) {
        item.tags = result.values;
      }
    });
  }
}

export function ensureTypeKeywords(item,values) {
  if (!item.typeKeywords) item.typeKeywords = [];
  if (typeof values === "string") values = [values];
  if (Array.isArray(values)) {
    values.forEach(v => {
      let result = ensureArrayValue(item.typeKeywords,v);
      if (result.modified) {
        item.typeKeywords = result.values;
      }
    });
  }
}

function getSaveItemUrl(itemId,folderId,owner) {
  let url = getUserContentUrl(owner);
  if (itemId) {
    if (folderId) url += "/" + encodeURIComponent(folderId);
    url += "/items/" + encodeURIComponent(itemId)+"/update";
  } else {
    if (folderId) url += "/" + encodeURIComponent(folderId);
    url += "/addItem";
    //console.log("getSaveItemUrl",url);
  }
  return url;
}

export function getUserContentUrl(owner) {
  let username = Context.instance.user.getUsername();
  let url = Context.instance.getSharingUrl();
  if (typeof owner === "string" && owner.length > 0) {
    username = owner;
  }
  url += "/rest/content/users/" + encodeURIComponent(username);
  return url;
}

export function getUserItemUrl(itemId,folderId,owner) {
  let url = getUserContentUrl(owner);
  if (folderId) url += "/" + encodeURIComponent(folderId);
  url += "/items/" + encodeURIComponent(itemId);
  return url;
}

export function moveItem(itemId,fromFolderId,toFolderId,owner) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  const url = getUserItemUrl(itemId,fromFolderId,owner)+"/move";
  const params = {
    f: "json",
    folder: toFolderId
  };
  const options = {query: params, method: "post", responseType: "json"};
  return esriRequest(url,options);
}

export function readItem(id) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  let url = Context.instance.getSharingUrl();
  url += "/rest/content/items/" + encodeURIComponent(id);
  const options = {query: {f: "json"}, responseType: "json"};
  return esriRequest(url,options);
}

export function readItemJsonData(id) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  let url = Context.instance.getSharingUrl();
  url += "/rest/content/items/" + encodeURIComponent(id) + "/data";
  const options = {query: {f: "json"}, responseType: "json"};
  return esriRequest(url,options);
}

export function saveItem(item,itemId,folderId,owner) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  const url = getSaveItemUrl(itemId,folderId,owner);
  item = Object.assign(item,{f: "json"});
  const options = {query: item, method: "post", responseType: "json"};
  return esriRequest(url,options);
}

export function shareItem(itemId,owner,sharingInfo) {
  const esriRequest = Context.instance.lib.esri.esriRequest;
  let url = getUserContentUrl(owner) + "/shareItems";
  const data = {
    f: "json",
    items: encodeURIComponent(itemId),
    everyone: sharingInfo.everyone,
    org: sharingInfo.org,
    groups: sharingInfo.groups,
    confirmItemControl: true
  }
  const options = {query: data, method: "post", responseType: "json"};
  return esriRequest(url,options);
}
