import AreaRolesTable from "./AreaRolesTable";
import AreasTable from "./AreasTable";
import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import IdCache from "./IdCache";
import Source from "../../aiim/base/Source";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as portalUtil from "../../util/portalUtil";
import * as serviceUtil from "./serviceUtil";
import * as sourceUtil from "./sourceUtil";
import * as selectionUtil from "../../../src/aiim/util/selectionUtil";
import * as versionUtil from "./versionUtil";
import ReviewersTable from "./nonAiimTables/ReviewersTable";
import { getCommentsMarkerPath } from "../../util/symbolUtil";
// import * as planGroupUtil from "./ReviewerManagement/planGroupUtil";
import OfficePlan from "./OfficePlan";
import type Project from "./Project";
import { ISourceInfo, ISupportInfo, IOfficePlan } from "./OfficePlan";
import { IIndoorsAppData } from "../../context/Configuration";
export interface xtnAiim {
  isPlanLayer: boolean,
  requiresFacilityMode: boolean,
  source: Source  
}
export interface IAiimFeatureLayer extends __esri.FeatureLayer {
  xtnAiim?: xtnAiim
}
export interface IPlanTask {
  planId: string,
  plan: Partial<IOfficePlan & ISourceInfo & ISupportInfo> & {
    serviceItemId?: string,
    serviceItemData?: IIndoorsAppData,
    peopleSource?: Source,
    peopleLayer?: IAiimFeatureLayer,
    unitsSource?: Source,
    unitsLayer?: IAiimFeatureLayer
  }
}
export default class PlanOpener extends BaseClass {

  openHostedPlan(project: Project, plan: OfficePlan, planServiceItemId: string): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const i18n = Context.instance.i18n;
      plan.issues = [];
      const task: IPlanTask = {
        planId: planServiceItemId,
        plan: {
          serviceItemId: planServiceItemId
        }
      };
      portalUtil.readItem(task.plan.serviceItemId).then(result => {
        const item = result && result.data;
        task.plan.serviceItem = item;
        task.plan.serviceUrl = Context.checkMixedContent(item && item.url);
      }).then(result => {
        return portalUtil.readItemJsonData(task.plan.serviceItemId).then(result => {
          task.plan.serviceItemData = result && result.data;
        });
      }).then(result => {
        return serviceUtil.readServiceJson(task.plan.serviceUrl,task.plan);
      }).then(result => {
        const cap = task.plan.serviceInfo.capabilities.split(",");
        if (cap.indexOf("Editing") === -1) {
          plan.issues.push({
            key: "editingNotEnabled",
            message: i18n.spaceplanner.issues.editingNotEnabled
          });
        }
        if (!task.plan.peopleLayerInfo || !task.plan.unitsLayerInfo ||
            !task.plan.areasTableInfo) {
          plan.issues.push({
            key: "requiresDataset",
            message: i18n.spaceplanner.issues.requiresDataset
          });
        }
      }).then(result => {
        if (!plan.hasIssues()) return this._loadLayers(task);
      }).then(result => {
        if (!plan.hasIssues()) return this._loadSupportTables(task)
      }).then(result => {
        if (!plan.hasIssues()) return this._makeSources(task);
      }).then(result => {
        plan.title = task.plan.serviceItem.title;
        plan.planPeopleSource = task.plan.peopleSource;
        plan.planUnitsSource = task.plan.unitsSource;
        plan.planServiceItem = task.plan.serviceItem;
        plan.areasTable = task.plan.areasTable;
        plan.areaRolesTable = task.plan.areaRolesTable;
        // plan.reviewersTable = task.plan.reviewersTable;
        plan.commentsLayer = task.plan.commentsLayer;
        plan.unitsLayer = task.plan.unitsLayer;
        plan.detailsLayer = task.plan.detailsLayer;

        // JSAPI 4.22 the path needed to be formatted differently. This will fix any broken
        // paths from previous plans
        const renderer = plan.commentsLayer.renderer as __esri.SimpleRenderer;
        const symbol = renderer && renderer.symbol as __esri.SimpleMarkerSymbol;
        if (
          renderer &&
          symbol &&
          symbol.path &&
          symbol.path !== getCommentsMarkerPath()
        ) {
          symbol.path = getCommentsMarkerPath();
        }

        plan.sourceInfo = {
          serviceUrl: task.plan.serviceUrl,
          serviceInfo: task.plan.serviceInfo,
          peopleLayerInfo: task.plan.peopleLayerInfo,
          unitsLayerInfo: task.plan.unitsLayerInfo,
          detailsLayerInfo: task.plan.detailsLayerInfo,
          areasTableInfo: task.plan.areasTableInfo,
          areaRolesTableInfo: task.plan.areaRolesTableInfo
        };
        plan.supportInfo = {
          serviceItem: task.plan.serviceItem,
          serviceUrl: task.plan.serviceUrl,
          serviceInfo: task.plan.serviceInfo,
          //mergeInfoTableInfo: task.plan.mergeInfoTableInfo,
          commentsLayerInfo: task.plan.commentsLayerInfo,
          //repliesTableInfo: task.plan.repliesTableInfo,
          reviewersTableInfo: task.plan.reviewersTableInfo,
          reviewersTable: task.plan.reviewersTable
        }
      }).then(result => {
        if (!plan.hasIssues()) return this._addLayersToMap(task);
      }).then(result => {
        //console.log("PlanOpener.task",task)
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  openVersionedPlan(project: Project, plan: OfficePlan, planId: string): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const versionedInfo = project.versionedInfo;
      const task: IPlanTask = {
        planId: planId,
        plan: {
          serviceUrl: versionedInfo.sourceInfo.serviceUrl,
          serviceItem: versionedInfo.sourceInfo.serviceItem,
          peopleLayer: versionedInfo.peopleLayer,
          unitsLayer: versionedInfo.unitsLayer,
          detailsLayer: versionedInfo.detailsLayer,
          sitesLayer: versionedInfo.sitesLayer,
          facilitiesLayer: versionedInfo.facilitiesLayer,
          levelsLayer: versionedInfo.levelsLayer,
          areasTableInfo: versionedInfo.sourceInfo.areasTableInfo,
          areaRolesTableInfo: versionedInfo.sourceInfo.areaRolesTableInfo,
          supportInfo: {}
        }
      };
      const supportInfo = task.plan.supportInfo;
      const versionManager = project.versionedInfo.versionManager;

      const versionInfo = versionManager.findVersionInfo(planId);
      const gdbVersion = versionInfo && versionInfo.versionName;
      Promise.resolve().then(() => {
        if (!versionInfo) {
          let err = new Error("The plan id is invalid");
          // @ts-ignore
          err.code = "__invalidPlanId__";
          throw err;
        }
      }).then(() => {
        return this._loadAreasTable(task,gdbVersion);
      }).then(() => {
        return this._loadAreaRolesTable(task,gdbVersion);
      }).then(() => {
        // return this._loadReviewersTable(task, gdbVersion)
      }).then(() => {
        this._applyVersionToLayer(task.plan.peopleLayer, gdbVersion);
        this._applyVersionToLayer(task.plan.unitsLayer, gdbVersion);
        this._applyVersionToLayer(task.plan.detailsLayer, gdbVersion);
        this._applyVersionToLayer(task.plan.sitesLayer, gdbVersion);
        this._applyVersionToLayer(task.plan.facilitiesLayer, gdbVersion);
        this._applyVersionToLayer(task.plan.levelsLayer, gdbVersion);
      }).then(() => {
        return sourceUtil.makePlanSources(task);
      }).then(() => {
        if (versionManager.featureServicePerPlan) {
          return versionUtil.findVersionSupportService(versionInfo).then(item => {
            if (item) {
              supportInfo.serviceItem = item;
              supportInfo.serviceUrl = Context.checkMixedContent(item && item.url);
            }
          });
        }
      }).then(() => {
        if (versionManager.featureServicePerPlan) {
          if (supportInfo.serviceUrl) {
            //console.log("fsperplanfsurl",supportInfo.serviceUrl)
            return serviceUtil.readServiceJson(supportInfo.serviceUrl,supportInfo);
          }
        }
      }).then(() => {
        if (versionManager.featureServicePerPlan) {
          return this._loadCommentsLayer(task,supportInfo);
        }
      }).then(() => {
        return this._loadSupportTables(task, gdbVersion)
      }).then(() => {
        plan.isVersioned = true;
        plan.versionInfo = versionInfo;
        plan.project = project;
        plan.title = versionManager.getVersionTitle(versionInfo,true);
        plan.planPeopleSource = task.plan.peopleSource;
        plan.planUnitsSource = task.plan.unitsSource;
        plan.planServiceItem = task.plan.serviceItem;
        plan.areasTable = task.plan.areasTable;
        plan.areaRolesTable = task.plan.areaRolesTable;
        plan.commentsLayer = task.plan.commentsLayer;
        plan.unitsLayer = task.plan.unitsLayer;
        plan.detailsLayer = task.plan.detailsLayer;
        plan.sitesLayer = task.plan.sitesLayer;
        plan.facilitiesLayer = task.plan.facilitiesLayer;
        plan.levelsLayer = task.plan.levelsLayer;
        plan.supportInfo = supportInfo;

      }).then(() => {
        if (Context.instance.isFPE() && !supportInfo.serviceItem) {
          // if you own the plan or the plan is owned by a data editor and is Public you can open the plan
          if (versionManager.hasStandaloneAccess(plan.versionInfo)) {
            return;
          }
        }
        const i18n = Context.instance.i18n;
        const typeKeywords = supportInfo.serviceItem && supportInfo.serviceItem.typeKeywords;
        const isPlan = !!(typeKeywords && typeKeywords.includes(OfficePlan.VersionedTypeKeywordFS));
        const isFpePlan = !!(typeKeywords && typeKeywords.includes(OfficePlan.TypeKeywordFloorPlanEditorPlan));
        if (!typeKeywords) {
          throw new Error(i18n.spaceplanner.issues.noPlanSupportItem);
        } else if (Context.instance.isSP() && (!isPlan || isFpePlan)) {
          throw new Error(i18n.spaceplanner.issues.notASpacePlannerPlan);
        } else if (Context.instance.isFPE() && (!isPlan || !isFpePlan)) {
          throw new Error(i18n.spaceplanner.issues.notAFloorPlanEditorPlan);
        }

      }).then(() => {

        // this is to support VersionManager.restoreRows (to undo a delete)
        // https://developers.arcgis.com/rest/services-reference/enterprise/restore-rows.htm
        // we'll need a fix from core before we can implement
        
        // plan.idCache = new IdCache();
        // return plan.idCache.load(plan);

      }).then(() => {

        // this is to support VersionManager.restoreRows (to undo a delete)
        // https://developers.arcgis.com/rest/services-reference/enterprise/restore-rows.htm
        // we'll need a fix from core before we can implement
        
        // plan.idCache = new IdCache();
        // return plan.idCache.load(plan);

      }).then(() => {
        //console.log("openVersionedPlan",task)
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  setCommentsRenderer(commentsLayer, task){

    const labelClass = {
      labelExpressionInfo: {
        "expression": "$feature.OBJECTID"
      },
      labelPlacement: "center-center",
      deconflictionStrategy: "static",
      allowOverlapOfFeatureInterior: "avoid",
      stackLabel: true,
      stackRowLength: 24,
      stackAlignment: "dynamic",
      removeDuplicates: "none",
      useCodedValues: true,
      maxScale: 0,
      minScale: 0,
      name: "Class 1",
      priority: -1,
      symbol: {
        type: "text",
        color: [
          255,
          255,
          255,
          255
        ],
        backgroundColor: null,
        borderLineColor: null,
        borderLineSize: null,
        verticalAlignment: "bottom",
        horizontalAlignment: "left",
        rightToLeft: false,
        angle: 0,
        xoffset: 0,
        yoffset: 0,
        kerning: true,
        haloColor: null,
        haloSize: null,
        font: {
          family: "Tahoma",
          size: 10,
          style: "normal",
          weight: "normal",
          decoration: "none"
        }
      }
    }

    const renderJson = {
      "type": "simple",
      "symbol": {
        "type": "esriSMS",
        "color": [0, 77, 76, 255],
        "angle": 0,
        "xoffset": 0,
        "yoffset": 8,
        "size": 32,
        "style": "esriSMSPath",
        "path": "M26.35,0H5A5,5,0,0,0,5,10h8.26l2.42,1.88L18.09,10h8.26a5,5,0,0,0,0-10Z",
        "outline": {
          "type": "esriSLS",
          "color": [255, 255, 255, 255],
          "width": 1,
          "style": "esriSLSSolid"
        }
      }
    }
    const flRenderer = Context.instance.lib.esri.rendererJsonUtils.fromJSON(renderJson);
    commentsLayer.renderer = flRenderer;
    commentsLayer.labelingInfo = [labelClass];

    const field = aiimUtil.findField(commentsLayer.fields,FieldNames.COMMENT_PLAN_ID);
    const expression = field.name + " = '" + selectionUtil.escSqlQuote(task.planId) +"'";
    commentsLayer.definitionExpression = expression;
  };


  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

  _addLayersToMap(task: IPlanTask) {
    const i18n = Context.instance.i18n;
    const view = Context.instance.views.mapView;
    const peopleLayer = task.plan.peopleLayer;
    const unitsLayer = task.plan.unitsLayer;
    const project = Context.instance.spaceplanner.planner.project;
    const baselinePeopleLayer = project && project.hostedInfo && project.hostedInfo.peopleLayer;
    const baselineUnitsLayer = project && project.hostedInfo && project.hostedInfo.unitsLayer;
    if (unitsLayer && baselineUnitsLayer && baselineUnitsLayer.renderer &&
      unitsLayer.renderer !== baselineUnitsLayer.renderer) {
      // TODO cast to a renderer with a clone method
      // @ts-ignore
      unitsLayer.renderer = baselineUnitsLayer.renderer.clone()
    }
    try {
      if (unitsLayer && baselineUnitsLayer && baselineUnitsLayer.popupTemplate &&
          unitsLayer.popupTemplate !== baselineUnitsLayer.popupTemplate) {
        unitsLayer.popupTemplate = baselineUnitsLayer.popupTemplate.clone()
      }
    } catch(e) {
      console.error(e, "Couldn't copy popup template")
    }

    try {
      if (unitsLayer && baselineUnitsLayer && baselineUnitsLayer.floorInfo) {
        //console.log("copying units.floorInfo >>>>>>>>>>>>>>>",baselineUnitsLayer.floorInfo)
        //unitsLayer.floorInfo = baselineUnitsLayer.floorInfo.clone();
        const fi = baselineUnitsLayer.floorInfo;
        unitsLayer.floorInfo = new Context.instance.lib.esri.LayerFloorInfo({
          floorField: fi.floorField,
          // @ts-ignore
          viewAllLevelIds: fi.viewAllLevelIds && fi.viewAllLevelIds.clone(),
          // @ts-ignore
          viewAllMode: fi.viewAllMode
        })
        //console.log("copied units.floorInfo <<<<<<<<<<<<<<<<",unitsLayer.floorInfo)
      }
    } catch(e) {
      console.error(e, "Couldn't copy floor info (units)")
    }

    try {
      if (peopleLayer && baselinePeopleLayer && baselinePeopleLayer.popupTemplate &&
          peopleLayer.popupTemplate !== baselinePeopleLayer.popupTemplate) {
        peopleLayer.popupTemplate = baselinePeopleLayer.popupTemplate.clone()
      }
    } catch(e) {
      console.error(e, "Couldn't copy popup template")
    }

    try {
      if (peopleLayer && baselinePeopleLayer && baselinePeopleLayer.floorInfo) {
        //console.log("copying people.floorInfo >>>>>>>>>>>>>>>",baselinePeopleLayer.floorInfo)
        //peopleLayer.floorInfo = baselinePeopleLayer.floorInfo.clone();
        const fi = baselinePeopleLayer.floorInfo;
        peopleLayer.floorInfo = new Context.instance.lib.esri.LayerFloorInfo({
          floorField: fi.floorField,
          // @ts-ignore
          viewAllLevelIds: fi.viewAllLevelIds && fi.viewAllLevelIds.clone(),
          // @ts-ignore
          viewAllMode: fi.viewAllMode
        })
        //console.log("copied people.floorInfo <<<<<<<<<<<<<<<<",peopleLayer.floorInfo)
      }
    } catch(e) {
      console.error(e, "Couldn't copy floor info (people)")
    }

    if (view && unitsLayer) {
      let layers = view.map.allLayers;
      let index = null;
      layers.some((layer: IAiimFeatureLayer, idx) => {
        if ((layer === baselineUnitsLayer) || (layer.xtnAiim && layer.xtnAiim.source && layer.xtnAiim.source.isAiimUnits())) {
          index = idx;
          layer.visible = false;
          layer.listMode = "hide";
          return true;
        }
        return false;
      });
      unitsLayer.title = i18n.spaceplanner.units.planLayerTitle;
      view.map.add(unitsLayer,index);
    }

    const detailsLayer = task.plan.detailsLayer;
    const baselineDetailsLayer = project && project.hostedInfo && project.hostedInfo.detailsLayer;
    if (detailsLayer && baselineDetailsLayer) {
      if (baselineDetailsLayer.popupTemplate) {
        detailsLayer.popupTemplate = baselineDetailsLayer.popupTemplate.clone()
      }
      if (baselineDetailsLayer.renderer) {
        // @ts-ignore
        detailsLayer.renderer = baselineDetailsLayer.renderer.clone()
      }
      if (baselineDetailsLayer.floorInfo) {
        const fi = baselineDetailsLayer.floorInfo;
        detailsLayer.floorInfo = new Context.instance.lib.esri.LayerFloorInfo({
          floorField: fi.floorField,
          // @ts-ignore
          viewAllLevelIds: fi.viewAllLevelIds && fi.viewAllLevelIds.clone(),
          // @ts-ignore
          viewAllMode: fi.viewAllMode
        })       
      }
      if (view) {
        let layers = view.map.allLayers;
        let index = null;
        layers.some((layer,idx) => {
          if (layer === baselineDetailsLayer) {
            index = idx;
            layer.visible = false;
            layer.listMode = "hide";
            return true;
          }
          return false;
        });
        detailsLayer.title = i18n.spaceplanner.details.planLayerTitle;
        view.map.add(detailsLayer,index);
      }
    }

    if (view && peopleLayer) {
      let layers = view.map.allLayers;
      let index = null;
      layers.some((layer,idx) => {
        // @ts-ignore
        if ((layer === baselinePeopleLayer) || (layer.xtnAiim && layer.xtnAiim.source && layer.xtnAiim.source.isAiimPeople())) {
          index = idx;
          layer.visible = false;
          layer.listMode = "hide";
          return true;
        }
        return false;
      });
      peopleLayer.title = i18n.spaceplanner.people.planLayerTitle;;
      view.map.add(peopleLayer,index);
    }

    /*
    const commentsLayer = task.plan.commentsLayer;
    const baselineCommentsLayer = project && project.hostedInfo && project.hostedInfo.commentsLayer;
    if (view && commentsLayer) {
      let layers = view.map.allLayers;
      let index = null;
      layers.some((layer,idx) => {
        if (layer === baselineCommentsLayer) {
          index = idx;
          layer.visible = false;
          layer.listMode = "hide";
          return true;
        }
        return false;
      });
      // if (baselineCommentsLayer && baselineCommentsLayer.renderer) {
        // commentsLayer.renderer = baselineCommentsLayer.renderer.clone()
        this.setCommentsRenderer(commentsLayer, task);
      // }
      if (baselineCommentsLayer && baselineCommentsLayer.popupTemplate) {
        commentsLayer.popupTemplate = baselineCommentsLayer.popupTemplate.clone()
      }
      commentsLayer.title = i18n.spaceplanner.planCommentsLayerTitle;
      view.map.add(commentsLayer,index);
    }
    */

  }

  _applyVersionToLayer(layer,gdbVersion) {
    if (layer && gdbVersion) {
      layer.outFields = ["*"];
      layer.gdbVersion = gdbVersion;
      layer.refresh();
    }
  }

  _loadLayers(task) {
    const p1 = this._loadPeopleLayer(task);
    const p2 = this._loadUnitsLayer(task);
    const p3 = this._loadAreasTable(task);
    const p4 = this._loadAreaRolesTable(task);
    const p5 = this._loadCommentsLayer(task);
    const p6 = this._loadDetailsLayer(task);
    return Promise.all([p1,p2,p3,p4,p5,p6]);
  }

  _loadSupportTables(task, gdbVersion?) {
    const loadReviewers = this._loadReviewersTable(task, gdbVersion)
    // const loadPlanReview = this._loadPlanReviewTable(task, gdbVersion)
    return Promise.all([loadReviewers])
  }

  _loadReviewersTable(task, gdbVersion?): Promise<void> {
    const lib = Context.instance.lib
    const supportInfo = gdbVersion ? task.plan.supportInfo : task.plan
    const tableInfo = supportInfo.reviewersTableInfo
    supportInfo.reviewersTable = new ReviewersTable()
    if (tableInfo) {
        let options
        if (gdbVersion) {
          let url = supportInfo.serviceUrl + "/" + tableInfo.id
          options = {
            url: url,
            gdbVersion: gdbVersion
          }
        } else {
          options = {
            portalItem: {
              id: task.plan.serviceItem.id
            },
            layerId: tableInfo.id
          }
        }
        const table = supportInfo.reviewersTable.table = new lib.esri.FeatureLayer(options)
        return supportInfo.reviewersTable.load(table)
    } else {
      return Promise.resolve()
    }
  }

  _loadAreaRolesTable(task,gdbVersion?): Promise<void> {
    const lib = Context.instance.lib;
    const tableInfo = task.plan.areaRolesTableInfo;
    const areaRolesTable = new AreaRolesTable();
    if (tableInfo) {
      let options;
      if (gdbVersion) {
        let url = task.plan.serviceUrl + "/" + tableInfo.id;
        options = {
          url: url,
          gdbVersion: gdbVersion
        }
      } else {
        options = {
          portalItem: {
            id: task.plan.serviceItemId
          },
          layerId: tableInfo.id
        }
      }
      task.plan.areaRolesTable = areaRolesTable;
      const table = task.plan.areaRolesTable.table = new lib.esri.FeatureLayer(options);
      return task.plan.areaRolesTable.load(table);
    } else {
      return Promise.resolve();
    }
  }

  _loadAreasTable(task: IPlanTask, gdbVersion?): Promise<void> {
    const lib = Context.instance.lib;
    const tableInfo = task.plan.areasTableInfo;
    if (tableInfo) {
      task.plan.areasTable = new AreasTable();
      let options;
      if (gdbVersion) {
        let url = task.plan.serviceUrl + "/" + tableInfo.id;
        options = {
          url: url,
          gdbVersion: gdbVersion
        }
      } else {
        options = {
          portalItem: {
            id: task.plan.serviceItemId
          },
          layerId: tableInfo.id
        }
      }
      const table = task.plan.areasTable.table = new lib.esri.FeatureLayer(options);
      return task.plan.areasTable.load(table);
    } else {
      return Promise.resolve();
    }
  }

  _loadCommentsLayer(task,supportInfo?): Promise<void> {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const layerInfo = (supportInfo && supportInfo.commentsLayerInfo) || task.plan.commentsLayerInfo;
    if (layerInfo) {
      let serviceItemId = (supportInfo && supportInfo.serviceItem && supportInfo.serviceItem.id) ||
        task.plan.serviceItemId
      let layer = task.plan.commentsLayer = new lib.esri.FeatureLayer({
        portalItem: {
          id: serviceItemId
        },
        layerId: layerInfo.id
      });
      layer.when().then(() => {
        //console.log("comments layer loaded by opener ..............")
        const view = Context.instance.views.mapView;
        if (view) {
          layer.title = i18n.spaceplanner.planCommentsLayerTitle;
          const f = aiimUtil.findField(layer.fields,"level_id");
          if (f && Context.instance.aiim.isFloorAwareMap()) {
            layer.floorInfo = new Context.instance.lib.esri.LayerFloorInfo({
              floorField: f.name,
              viewAllMode: false,
              //viewAllLevelIds: fi.viewAllLevelIds && fi.viewAllLevelIds.clone(),
            })
            //console.log("commentsLayer.floorInfo",layer.floorInfo)
          }
          view.map.add(layer);
        }
      });
      return layer.load();
    } else {
      return Promise.resolve();
    }
  }

  _loadDetailsLayer(task): Promise<void> {
    const lib = Context.instance.lib;
    const layerInfo = task.plan.detailsLayerInfo;
    if (layerInfo) {
      let layer = task.plan.detailsLayer = new lib.esri.FeatureLayer({
        portalItem: {
          id: task.plan.serviceItemId
        },
        layerId: layerInfo.id,
        outFields: ["*"]
      });
      layer.when().then(() => {
      });
      return layer.load();
    } else {
      return Promise.resolve();
    }
  }

  _loadPeopleLayer(task): Promise<void> {
    const lib = Context.instance.lib;
    const layerInfo = task.plan.peopleLayerInfo;
    if (layerInfo) {
      let layer = task.plan.peopleLayer = new lib.esri.FeatureLayer({
        portalItem: {
          id: task.plan.serviceItemId
        },
        layerId: layerInfo.id,
        outFields: ["*"]
      });
      layer.when().then(() => {
      });
      return layer.load();
    } else {
      return Promise.resolve();
    }
  }

  _loadUnitsLayer(task): Promise<void> {
    const lib = Context.instance.lib;
    const layerInfo = task.plan.unitsLayerInfo;
    if (layerInfo) {
      let layer = task.plan.unitsLayer = new lib.esri.FeatureLayer({
        portalItem: {
          id: task.plan.serviceItemId
        },
        layerId: layerInfo.id,
        outFields: ["*"]
      });
      layer.when().then(() => {
      });
      return layer.load();
    } else {
      return Promise.resolve();
    }
  }

  _makeSources(task: IPlanTask) {
    const promises = [];
    const peopleLayer = task.plan.peopleLayer;
    const unitsLayer = task.plan.unitsLayer;
    if (peopleLayer) {
      let layer = peopleLayer;
      task.plan.peopleSource = new Source({
        key: sourceUtil.getPeopleSourceKey(),
        name: layer.title,
        url: layer.url+"/"+layer.layerId,
        layer2D: layer
      });
      let v = FieldNames.PEOPLE_FULLNAME;
      if (layer && aiimUtil.findField(layer.fields,v)) {
        task.plan.peopleSource.displayField = v;
      }
      layer.xtnAiim = {
        isPlanLayer: true,
        requiresFacilityMode: true,
        source: task.plan.peopleSource
      };
      promises.push(task.plan.peopleSource.checkSchema());
    }
    if (unitsLayer) {
      let layer = unitsLayer;
      task.plan.unitsSource = new Source({
        key: sourceUtil.getUnitsSourceKey(),
        name: layer.title,
        url: layer.url+"/"+layer.layerId,
        layer2D: layer
      });
      layer.xtnAiim = {
        isPlanLayer: true,
        requiresFacilityMode: true,
        source: task.plan.unitsSource
      };
      promises.push(task.plan.unitsSource.checkSchema());
    }
    return Promise.all(promises);
  }

}
