import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findFieldName, getAttributeValue } from "../../../aiim/util/aiimUtil";
import { getReservationsLayer } from "../../base/sourceUtil";
import Topic from "../../../context/Topic";
import { AreaTypes } from "../../../util/interfaces";
import HotelAreaUsageAnalytics from "./HotelAreaUsageAnalytics";
import OfficePlan from "../../base/OfficePlan";
import PeopleAndUnits from "./PeopleAndUnits";
import { useHandles } from "../../miniapps/common/hooks";
import { TitleRow } from '../../styles/Work/workBar';
import AreaHeader from "./AreaHeader";
import { 
  getActiveWorkspaceAreaId, getActiveWorkspaceAreaType, getActiveAssetbarSection, setActiveAssetbarSection, 
  getHotelsEnabled, getHotelReservationMethod 
} from "../../redux";

interface IWorkspaceAreaDetailsProps {
  areaItem: __esri.Graphic,  
  onIsExpanded: (expanded: boolean) => void
}
const WorkspaceAreaDetails = ({ areaItem, onIsExpanded }: IWorkspaceAreaDetailsProps) => {
  const dispatch = useDispatch();

  const rdxActiveAreaId = useSelector(getActiveWorkspaceAreaId);
  const rdxActiveAreaType = useSelector(getActiveWorkspaceAreaType);
  const setHandles = useHandles()[1];

  const [isAnalyticsDisabled, setIsAnalyticsDisabled] = useState<boolean>(true);
  const [isUsageOpen, setIsUsageOpen] = useState<boolean>(false);
  const [requiredUsageFields, setRequiredUsageFields] = useState<any>(null);
  const rdxActiveAssetbarSection = useSelector(getActiveAssetbarSection);

  useEffect(() => {
    const handles = [];
    handles.push(
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          // TODO: force refresh
        }
      })
    );

    setHandles(handles);
  }, [rdxActiveAreaId, rdxActiveAreaType, areaItem]);

  useEffect(() => {
    initUsage();
  }, []);

  const getAreaId = () => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  }

  const getAreaType = (): AreaTypes => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_TYPE);
  }

  const hasActive = () => {
    return !!(rdxActiveAreaId && rdxActiveAreaType);
  }

  const initUsage = () => {
    const layer = getReservationsLayer();
    if (!layer) {
      setIsAnalyticsDisabled(true);
    } else {
      const fields = {
        FIELD_START_TIME: findFieldName(layer.fields, FieldNames.START_TIME),
        FIELD_END_TIME: findFieldName(layer.fields, FieldNames.END_TIME),
        FIELD_ALL_DAY: findFieldName(layer.fields, FieldNames.ALL_DAY),
        FIELD_UNIT_ID: findFieldName(layer.fields, FieldNames.UNIT_ID),
        FIELD_STATE: findFieldName(layer.fields, FieldNames.STATE),
        FIELD_CHECK_IN_TIME: findFieldName(layer.fields, FieldNames.CHECK_IN_TIME),
        FIELD_CHECK_OUT_TIME:  findFieldName(layer.fields, FieldNames.CHECK_OUT_TIME)
      }
      const vals = Object.values(fields);
      if (vals.includes(null)) {
        console.warn("Reservations Layer does not have all required fields. Charts/Analytics disabled", vals);
        setIsAnalyticsDisabled(true);
      } else {
        setRequiredUsageFields(fields)
        setIsAnalyticsDisabled(false);
      }
    }
  }

  const isActive = () => {
    return (hasActive()
      && rdxActiveAreaId === getAreaId()
      && rdxActiveAreaType === getAreaType()
    );
  }

  const hotelsEnabled = useSelector(getHotelsEnabled);
  const hotelReservationMethod = useSelector(getHotelReservationMethod);
  const showUsageButton = (!isAnalyticsDisabled && hotelsEnabled && hotelReservationMethod === "esri");

  return (
    <div style={{ ...((isUsageOpen && !rdxActiveAssetbarSection) ? {
      //width:"calc(100vw - 11rem)", height:"calc(100vh - 180px)"}:{}), display:"flex"
      width:"calc(100vw - 9.75rem)", height:"calc(100vh - 200px)"}:{}), display:"flex"
    }}>
      <div style={{minWidth: "19.595rem"}}>
        <TitleRow>
          <AreaHeader areaItem={areaItem} areaType={getAreaType()} />
        </TitleRow>
        <PeopleAndUnits areaItem={areaItem}
          showUsageButton={showUsageButton}
          disableUsageButton={!showUsageButton}
          onShowUsageClicked={e => {
            if (!isUsageOpen || rdxActiveAssetbarSection) {
              setIsUsageOpen(true);
              onIsExpanded(true);
              dispatch(setActiveAssetbarSection(null));
            } else {
              setIsUsageOpen(false);
              onIsExpanded(false);              
            }
          }}
        />
      </div>
      <HotelAreaUsageAnalytics 
        areaItem={areaItem}
        activeAreaId={rdxActiveAreaId}
        isDisabled={!showUsageButton}
        isShowUsagePanel={isUsageOpen && !rdxActiveAssetbarSection}
        requiredFieldsObj={requiredUsageFields}
        onCloseClick={() => {
          setIsUsageOpen(false);
          onIsExpanded(false);
        }}
      />
    </div>
  )
}

export default WorkspaceAreaDetails;