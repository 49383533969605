import React from "react";
import { connect } from "react-redux";
import { getActiveSection } from "../../../redux";

import * as component from "../../../../components/util/component";
import { PopupPanel } from "../../../styles/Common/panel";
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemTitleMain,
  ListItemTitleIcon,
  ListItemTitleAside,
} from "../../../styles/Common/list";
import { AssignmentsContent, PromptText } from "../../../styles/popupStyles";
import ContentLargeIcon from "calcite-ui-icons-react/ContentLargeIcon";
import CreditsIcon from "calcite-ui-icons-react/CreditsIcon";
import CubeIcon from "calcite-ui-icons-react/CubeIcon";
import DeskIcon from "calcite-ui-icons-react/DeskIcon";
import HomeIcon from "calcite-ui-icons-react/HomeIcon";
import ServicesIcon from "calcite-ui-icons-react/ServicesIcon";
import MeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";
import WorkspaceAreaIcon from "calcite-ui-icons-react/AppsIcon";
import WidgetsSourceIcon from "calcite-ui-icons-react/WidgetsSourceIcon";
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import MeasureAreaIcon from "calcite-ui-icons-react/MeasureAreaIcon";
import UnassignedIcon from "calcite-ui-icons-react/MinusSquareIcon";
import Topic from "../../../../context/Topic";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as queryUtil from "../../../base/queryUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as selectionUtil from "../../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import Context from "../../../../context/Context";
import Button from "../../../../common/components/Button/index";
import Skeleton from "react-loading-skeleton";
import Rdx from "../../../../redux/Rdx";
import OfficePlan from "../../../base/OfficePlan";
import ItemReference from "../../../../aiim/base/ItemReference";
import AttributeEditor from "./AttributeEditor";
import Attributes from "../../../../components/main/InfoPanel/Attributes";
import RemoveButton from "./RemoveButton";
import UnitsAssignTo from "../UnitsAssignTo";
import UnassignPrompt from "../UnassignPrompt";
import { ModalController } from "../../../../common/components/Modal";
import TransactionGuard from "../../../base/TransactionGuard";
import PeopleAssignTo from "../PeopleAssignTo";
import AreaPopup from "./AreaPopup";
import RelatedFeatures from "./RelatedFeatures";
import * as multipleAssignmentsUtil from "../MultipleAssignments/multipleAssignmentsUtil";
import { stringFormatter } from "../../../../util/formatUtil";
import InvalidAssignment from "../InvalidAssignment";

// List Item Icons
import DeleteIcon from "calcite-ui-icons-react/XIcon";
import EditIcon from "calcite-ui-icons-react/PencilIcon"; // EditAttributesIcon
import FloorPlanIcon from "calcite-ui-icons-react/FloorPlanIcon";
import GearIcon from "calcite-ui-icons-react/GearIcon";
import MoveIcon from "calcite-ui-icons-react/MoveIcon";


import Popover from "calcite-react/Popover";
import moment from "moment";
import { HomeOfficeId, HomeOfficeJSON } from "../../../base/AreasTable";

const tabs = {
  assignments: "assignments",
  properties: "properties",
  related: "related"
}

class _Popup extends React.Component {
  _mounted = false

  constructor(props) {
    super(props)
    const type = this.props.isPerson ? "person" : null
    this.state = component.newState({
      spaceplannerActiveTab: this.props.activeSection,
      activeTab: tabs.assignments,
      assignmentsDisabled: false,
      peopleAssigned: [],
      unitsAssigned: [],
      additionalAssignments: {
        units: [],
        areas: []
      },
      relatedItems: 0,
      workspaceAreas: [],
      unitUseType: null,
      type: type
    })
  }

  enableDisableAssignments = (enable) => {
    const layer = sourceUtil.getUnitsLayer();
    const feature = this.getFeatureItem().feature;
    const field = aiimUtil.findField(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE).name;
    const value = enable ? "none" : "not assignable";
    const attributes = {
      [field]: value
    };
    const guard = new TransactionGuard({force: true});
    Promise.resolve().then(() => {
      return transactions.updateAttributes("unit",feature,attributes);
    }).then(() => {
      guard.close();
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated,
      });
    }).catch((error) => {
      guard.close();
      console.error("Error updating feature", error);
      Topic.publishErrorUpdatingData(error.submessage);
    });
  }

  updateTypes = (init) => {
    const attributes = this.getFeatureItem().feature.attributes;
    let useType = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE
    );
    useType = typeof useType === "string" && useType.toLowerCase()
    let type = "unit"
    if (useType === "not assignable") {
      let props = {
        assignmentsDisabled: false, // allow Enable Assignments from the Assignments tab
        type: type,
        unitUseType: useType
      }
      if (init) props.activeTab = tabs.properties;
      this.setState(props)
    } else {
      this.setState({
        type: type,
        unitUseType: useType
      })
    }
    return { type: type, useType: useType }
  }

  _focus = () => {
    const popupCloseBtn = document.getElementsByClassName("esri-popup__button")
    if (popupCloseBtn && popupCloseBtn.length === 1) {
      popupCloseBtn[0].focus()
    }
    const popupTitle = document.getElementsByClassName("esri-popup__header-title")
    if (popupTitle && popupTitle.length === 1) {
      popupTitle[0].setAttribute("aria-live", "polite")
    }
  }

  componentDidMount() {
    this._mounted = true
    const attributes = this.getFeatureItem().feature.attributes;
    setTimeout(this._focus, 100)

    if (!this.props.isPerson) {
      const unitName = aiimUtil.getAttributeValue(attributes, FieldNames.NAME);
      Rdx.setValue(null, OfficePlan.Rdx_ActiveUnitName, unitName);
      const types = this.updateTypes(true)
      this.queryAssignments(types.type, types.useType);
    } else {
      this.queryAssignments("person")
    }

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, (params) => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.refreshFeatureItem();
        }
      }),
      Topic.subscribe(Topic.ActiveTabChanged, (params) => {
        if (typeof params.tab === "string") {
          this.setState({
            spaceplannerActiveTab: params.tab
          })
        }
      }),
      Topic.subscribe(Topic.RefreshUnitName,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this)
  }

  isViewOnly = () => {
    const tab = this.state.spaceplannerActiveTab
    return tab === "review" || tab === "publish"
  }

  changeActiveTab(e, title) {
    this.setState({
      activeTab: title
    })
  }

  getFeatureItem() {
    return this.state.featureItem || this.props.featureItem;
  }

  getSource(type) {
    if (type === "unit" || type === "hotdesk" || type === "hotel" || type === "meeting room") {
      return officePlanUtil.getUnitsSource()
    } else if (type === "person") {
      return officePlanUtil.getPeopleSource()
    } else {
      return null
    }
  }
  getAreaIcon(areaType) {
    return (areaType === "hotel" || areaType === "hotdesk" || areaType === "workspace")
      ? <WorkspaceAreaIcon />
      : areaType === "home"
        ? <HomeIcon />
        : null;
  }

  async queryWorkspaceAreas() {
    try {
      const result = await OfficePlan.getActive().queryWorkspaceAreas();
      if (this._mounted) {
        this.setState({
          workspaceAreas: result
        });
      }
    } catch(ex) {
      console.warn("Error querying workspace areas:");
      console.error(ex);
      if (this._mounted) throw(ex);
      Topic.publishErrorAccessingData();      
    }
  }

  queryAreas() {
    return this.queryWorkspaceAreas()
  }

  async queryAssignments(type, asnType) {
    if (asnType === "hotel" || asnType === "hotdesk" || asnType === "meeting room") {
      await this.queryAreas()
      this.setState({ peopleAssigned: [] })
    } else if (type === "unit") {
      await this.queryAreas()
      officePlanUtil
      .queryPeopleAssignedToUnit(this.getFeatureItem())
      .then((result) => {
        if (!this._mounted) return;
        this.setState({
          peopleAssigned: result,
        });
      })
      .catch((ex) => {
        console.error("Error querying people assigned to unit", ex);
      });
    } else if (type === "person") {
      this.queryAdditionalAssignments()
    }
  }

  queryAdditionalAssignments() {
    let initPromise = Promise.resolve()
    const featureItem = this.getFeatureItem();
    const attributes = featureItem.feature.attributes;
    const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_UNIT_ID);
    if (!unitId) {
      this.setState({
        unitsAssigned: []
      })
    } else {
      const source = sourceUtil.getUnitsSource();
      const unitIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNIT_ID);
      const v = selectionUtil.escSqlQuote(unitId);
      const where = "(" + unitIdField.name + " = '" + v + "')";
      const options = {
        source: source,
        where: where,
        returnGeometry: false
      }
      initPromise = queryUtil.queryFeatures(options).then(result => {
        if (result && result.features) {
          const resultInfo = queryUtil.makeResultInfo(source,result);
          if (this._mounted && resultInfo && resultInfo.featureItems &&
              resultInfo.featureItems.length > 0) {
            const unitFeatureItems = resultInfo.featureItems;
            this.setState({
              unitsAssigned: unitFeatureItems
            })
          }
        }
      })
    }

    initPromise.then(() => {
      return this.queryAreas()
    }).then(() => {
      const personName = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
      return multipleAssignmentsUtil.getPersonAssignments(personName, personEmail)
    }).then(assignments => {
      const areas = assignments.areas
      const units = assignments.units
      const personArea = this.findMatchingArea(attributes)
      const personUnit = this.state.unitsAssigned.length === 1 ? this.state.unitsAssigned[0].feature : null
      const additionalAreas = areas.filter(area => {
        return !multipleAssignmentsUtil.compareAreas(area, personArea)
      })
      const additionalUnits = units.filter(unit => {
        return !multipleAssignmentsUtil.compareUnits(unit.feature, personUnit)
      })
      this.setState({
        additionalAssignments: {
          areas: additionalAreas,
          units: additionalUnits
        }
      })
    }).catch(e => {
      console.error("Error querying units/areas/additonal assignments", e)
    })
  }

  findMatchingArea(attributes) {
    const { workspaceAreas } = this.state;
    const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
    if (areaId === HomeOfficeId) return { ...HomeOfficeJSON };
    if (areaId && workspaceAreas && workspaceAreas.length > 0) {
      const idField = aiimUtil.findField(OfficePlan.getActive().areasTable.table.fields, FieldNames.AREA_ID);
      for (let i = 0; i < workspaceAreas.length; i++) {
        const area = workspaceAreas[i];
        if (areaId === area.attributes[idField.name]) return area;
      }
    }
    return null
  }

  refreshFeatureItem() {
    const source = this.getSource(this.state.type);
    const featureItem = this.getFeatureItem();
    const initGeometry = featureItem.geometry;
    queryUtil
      .refreshFeatureItem(source, featureItem)
      .then((result) => {
        if (!result) {
          Topic.publish(Topic.CloseItemPopup, {});
        }

        if (result && this._mounted) {
          // Check if the person popup needs to re-anchor
          this.reanchorPersonPopup(initGeometry, result);

          this.setState({ featureItem: result });
          if (!this.props.isPerson) {
            const types = this.updateTypes()
            this.queryAssignments(types.type, types.useType);
          } else {
            this.queryAssignments("person")
          }
          this.refreshPopupTitle(this.state.type);
        }
      })
      .catch((ex) => {
        console.error("Popup: Error refreshing feature", ex);
      });
  }

  reanchorPersonPopup(initGeometry, refreshedFeature) {
    if (this.state.type !== "person") {
      return;
    }

    if (refreshedFeature.geometry !== initGeometry) {
      const source = this.getSource("person");
      const attributes = multipleAssignmentsUtil.getAttributes(refreshedFeature);
      const name = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME);

      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: refreshedFeature,
        fetchFeatureItemGeometry: !this.isNullGeometry(refreshedFeature.geometry),
        zoom: true,
        content: (
          <Popup
            sourceKey={source.key}
            featureItem={refreshedFeature}
            isPerson={true}
          />
        )
      });
    }
  }

  refreshPopupTitle(type) {
    if (type !== "person" && type !== "unit") {
      return;
    }
    const feature = this.getFeatureItem();
    const attributes = multipleAssignmentsUtil.getAttributes(feature);
    const field = type === "person" ? FieldNames.PEOPLE_FULLNAME : FieldNames.NAME;
    const title = aiimUtil.getAttributeValue(attributes, field);
    if (!title) {
      return;
    }
    if (this.props.onTitleChange) this.props.onTitleChange(title);
  }

  confirmDelete = (subject, item, from, fromItem) => {
    const i18n = Context.instance.i18n;
    const promptOptions = {
      subject: subject,
      subjectItem: item,
      from: from,
      fromItem: fromItem,
    };
    if (from === "person") {
      const personAttributes = fromItem.attributes || (fromItem.feature && fromItem.feature.attributes)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      promptOptions.fromName = personName
    }
    const content = <UnassignPrompt promptOptions={promptOptions} />;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: i18n.spaceplanner.unassignPrompt.removeAssignmentTitle,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        if (subject === "person") {
          if (from === "unit") {
            const personAttributes = item && item.feature && item.feature.attributes
            const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
            const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
            multipleAssignmentsUtil.canDeleteDuplicate(personName, personEmail).then(result => {
              if (result) {
                this.deleteDuplicatePerson({person: item, associatedUnit: fromItem, associatedArea: null})
              } else {
                this.deletePerson(item)
              }
            })
          }
        } else if (subject === "unit") {
          if (from === "person") {
            this.deleteDuplicatePersonFromUnit(item, fromItem)
          } else if (from === "workspace") {
            this.deleteUnit(item)
          }
        } else if (subject === "hotdesk" || subject === "hotel" || subject === "workspace" || subject === "home") {
          if (from === "person") {
            this.deleteDuplicatePersonFromArea(item, fromItem)
          }
        }
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  deletePerson = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([personItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  deleteDuplicatePerson = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .deletePeople([personItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  hasAdditionalAssignments = () => {
    const units = this.state.additionalAssignments.units
    const areas = this.state.additionalAssignments.areas
    return units.length > 0 || areas.length > 0
  }

  isAdditionalUnitAssignment = (unitItem) => {
    const additionalUnits = this.state.additionalAssignments.units
    return additionalUnits.includes(unitItem)
  }

  isAdditionalAreaAssignment = (areaItem)  => {
    const additionalAreas = this.state.additionalAssignments.areas
    return additionalAreas.includes(areaItem)
  }

  deleteDuplicatePersonFromUnit = (unitItem, personItem) => {
    if (this.hasAdditionalAssignments()) {
      const isAdditional = this.isAdditionalUnitAssignment(unitItem)
      const personAttributes = personItem && personItem.feature && personItem.feature.attributes
      const unitAttributes = unitItem && unitItem.feature && unitItem.feature.attributes
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const unitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
      multipleAssignmentsUtil.getDuplicateByUnit(personName, personEmail, unitId, {unit: unitItem}).then(result => {
        if (result && result.length === 1) {
          const person = result[0]
          multipleAssignmentsUtil.deleteDuplicatePeople([person], isAdditional)
        } else {
          throw new Error("No occupants returned")
        }
      }).catch(e => {
        console.error("Couldn't get duplicate occupant feature", e)
      })
    } else {
      this.deletePerson(personItem)
    }
  }

  deleteDuplicatePersonFromArea = (areaItem, personItem) => {
    if (this.hasAdditionalAssignments()) {
      const isAdditional = this.isAdditionalAreaAssignment(areaItem)
      const personAttributes = personItem && personItem.feature && personItem.feature.attributes
      const areaAttributes = areaItem.attributes || (areaItem.feature && areaItem.feature.attributes)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const areaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
      multipleAssignmentsUtil.getDuplicateByArea(personName, personEmail, areaId, {unit: areaItem}).then(result => {
        if (result && result.length === 1) {
          const person = result[0]
          multipleAssignmentsUtil.deleteDuplicatePeople([person], isAdditional)
        } else {
          throw new Error("No occupants returned")
        }
      }).catch(e => {
        console.error("Couldn't get duplicate occupant feature", e)
      })
    } else {
      this.deletePerson(personItem)
    }
  }

  deleteUnit = (unitItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignUnits([unitItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          queryCriteriaKey: this.unitsQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning units", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  render() {
    return (
      <PopupPanel id="feature-popup" key="info">
        {this.renderLabels(this.state.type)}
        {this.renderContent(this.state.type)}
      </PopupPanel>
    );
  }

  renderLabels(type) {
    const lib = Context.instance.lib
    const i18n = Context.instance.i18n
    const featureItem = this.getFeatureItem()
    const attributes = featureItem && featureItem.feature && featureItem.feature.attributes
    const styleLeft = {justifyContent: "flex-start"}
    const styleCenter = {justifyContent: "center"}
    const styleRight = {justifyContent: "flex-end"}
    if (type === "unit") {
      let useType = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE
      );
      let capacity = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_CAPACITY
      );
      let area = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_AREA_GROSS
      );
      if (typeof capacity !== "number") capacity = 0;
      if (typeof area !== "number") area = 0;
      if (typeof area === "number") {
        area = lib.dojo.number.format(area, { places: 0 });
      }

      let useTypeCompare = useType && useType.toLowerCase()
      let useTypeLabel = null, useTypeIcon = null
      if (useTypeCompare === "office") {
        useTypeLabel = i18n.spaceplanner.assignmentType.officeLabel
        useTypeIcon = (<FloorPlanIcon/>)
      } else if (useTypeCompare === "hotdesk") {
        useTypeLabel = i18n.spaceplanner.assignmentType.hotdesk
        useTypeIcon = (<DeskIcon/>)
      } else if (useTypeCompare === "hotel") {
        useTypeLabel = i18n.spaceplanner.assignmentType.hotel
        useTypeIcon = (<ServicesIcon/>)
      } else if (useTypeCompare === "meeting room") {
        useTypeLabel = i18n.spaceplanner.assignmentType.meetingroom
        useTypeIcon = (<MeetingRoomIcon/>)
      } else if (useTypeCompare === "not assignable") {
        useTypeLabel = i18n.spaceplanner.assignmentType.notAssignable
        useTypeIcon = (<CubeIcon/>)
      } else if (useTypeCompare === "none") {
        useTypeLabel = i18n.spaceplanner.unassigned
        useTypeIcon = (<UnassignedIcon/>)
      }
      // let capacityLabel = i18n.spaceplanner.popup.capacity.replace("{count}", capacity)
      // let areaLabel = i18n.spaceplanner.popup.area
      //   .replace("{count}", area)
      //   .replace("{unit}", "ft") // Open up to meters?
      let capacityLabel = capacity
      let areaLabel = area
      const useTypeGroup = useTypeLabel && useTypeIcon && (
        <div className={"i-label-group"} style={styleLeft}>
          <h5 className={"i-label"}>{useTypeLabel}</h5>
          <div className={"i-icon"}>{useTypeIcon}</div>
        </div>
      );

      return (
        <div className={"i-popup-labels"}>
          {useTypeGroup}
          <div className={"i-label-group"} style={styleCenter}>
            <h5 className={"i-label"}>{i18n.spaceplanner.areaStats.capacity}</h5>
            <div className={"i-icon"}><ContentLargeIcon/></div>
            <h5 className={"i-label"}>{capacityLabel}</h5>
          </div>
          <div className={"i-label-group"} style={styleRight}>
            <h5 className={"i-label"}>{i18n.spaceplanner.areaStats.area}</h5>
            <div className={"i-icon"}><MeasureAreaIcon/></div>
            <h5 className={"i-label"}>{areaLabel}</h5>
          </div>
        </div>
      )
    } else if (type === "person") {
      let orgLevel2 = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.PEOPLE_ORG_LEVEL_2
      )
      let jobTitle = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.PEOPLE_JOB_TITLE
      )
      let startDate = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.PEOPLE_START_DATE
      )
      let years = 0, months = 0;
      if (startDate) {
        const date1 = new Date(startDate);
        const date2 = new Date();
        const diffTime = Math.abs(date2.getTime() - date1.getTime())
        const diffYears = diffTime / (1000 * 3600 * 24 * 365)
        years = Math.floor(diffYears)
        months = (diffYears - years) * 12;
        months = Math.round(months,1);
      }

      const orgLevel2Label = orgLevel2 || "---"
      const jobTitleLabel = jobTitle || "---"

      let template = i18n.spaceplanner.popup.timeEmployed;
      if (startDate) {
        const now = moment();
        const mStartDate = moment(startDate);
        if (mStartDate.isAfter(now)) {
          template = i18n.spaceplanner.popup.timeToEmployment;
        }
      }
      let timeEmployedLabel = stringFormatter(template, { months, years });

      if (months === 0 && startDate) {
        // if less than a month; show the days from start date
        const date1 = new Date(startDate);
        const mStartedOn = moment(date1);
        const now = moment();
        timeEmployedLabel = mStartedOn.isAfter(now) ? mStartedOn.from(now) : mStartedOn.to(now, true);
      }

      return (
        <div className={"i-popup-labels"}>
          <div className={"i-label-group"} style={styleLeft}>
            <div className={"i-icon"}><WidgetsSourceIcon/></div>
            <h5 className={"i-label"}>{orgLevel2Label}</h5>
          </div>
          <div className={"i-label-group"} style={styleCenter}>
            <div className={"i-icon"}><CreditsIcon/></div>
            <h5 className={"i-label"}>{jobTitleLabel}</h5>
          </div>
          <div className={"i-label-group"} style={styleRight}>
            <div className={"i-icon"}><ClockIcon/></div>
            <h5 className={"i-label"}>{timeEmployedLabel}</h5>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  renderContent(type) {
    return (
      <div>
        <div className={"i-current-content"}>
        {this.renderAssignments(type)}
        {this.renderProperties(type)}
        {this.renderRelated(type)}
        </div>
        {this.renderTabs(type)}
      </div>
    )
  }

  isNullGeometry(geometry) {
    return !geometry || (geometry.x === 0 && geometry.y === 0)
  }

  onPersonAssignmentChanged = () => {
    Topic.publish(Topic.PlanModified, {
      action: OfficePlan.Action_AssignmentsUpdated,
    });
  }

  renderAssignments(type) {
    if (this.state.activeTab !== tabs.assignments) return null
    const useType = this.state.unitUseType
    const i18n = Context.instance.i18n

    if (useType === "not assignable") {
      let style = {
        height: "100%",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }
      return (
        <div style={style}>
          <Button style={{height:"1.8rem", fontSize: "0.694rem", fontWeight:500}} clear
            onClick={evt => {
              this.enableDisableAssignments(true);
            }}
          >{i18n.spaceplanner.popup.enableAssignments.label}</Button>
        </div>
      )
    }

    let showDisableAssignments = false;
    let disableAssignments;

    let assignBtn, title
    if (type === "unit") {
      let useRm = true;
      if (useType === "hotdesk" || useType === "hotel" || useType === "meeting room") {
        title = i18n.spaceplanner.popup.assignedTo
      } else {
        title = i18n.spaceplanner.popup.peopleAssigned
        if (this.state.peopleAssigned && this.state.peopleAssigned.length === 0) {
          useRm = false;
        }
        if (useType === "none" || useType === null) {
          if (!this.state.peopleAssigned || this.state.peopleAssigned.length === 0) {
            // Enabling assignments for a unit should not label the unit an office in the pop up #8081
            title = "";
          }
        };
      }
      assignBtn = (
        <UnitsAssignTo type={type} tab={this.state.activeTab}
            featureItems={[this.getFeatureItem()]} isPopup={true} />
      );

      showDisableAssignments = true;
      let disableButton;
      if (useRm) {
        disableButton = (
          <div className="i-disable-assignments-header">
            <RemoveButton type={type} unitUseType={useType}
              tab={this.state.activeTab}
              peopleFeatures={this.state.peopleAssigned}
              unitFeatures={this.state.unitsAssigned}
              additionalAssignments={this.state.additionalAssignments}
              onUnassignment={this.onPersonAssignmentChanged}
              feature={this.getFeatureItem()}
              label={i18n.spaceplanner.popup.disableAssignments.label}
              dialogTitle={i18n.spaceplanner.popup.disableAssignments.label}
              disableAssignments={true}
              extraSmall={true} />
          </div>
        );
      } else {
        disableButton = (
          <div className="i-disable-assignments-header">
            <Button className="i-red-btn"
              onClick={evt => {
                this.enableDisableAssignments(false);
                this.setState({
                  disableAssignmentsOpen: false
                });
              }}
              style={{height:"1.8rem", fontSize:"0.694rem", fontWeight:500}}
            >{i18n.spaceplanner.popup.disableAssignments.label}</Button>
          </div>
        );
      }
      disableAssignments = (
        <Popover
          targetEl={
            <Button extraSmall
              iconButton
              className={"i-assign-setting-btn"}
              icon={<GearIcon size={16} color="#004d4c"/>}
              iconPosition="before"
              title= {i18n.general.setting}
              onClick={() => {
                this.setState({
                  disableAssignmentsOpen: !this.state.disableAssignmentsOpen
                });
              }}
            ><span className="i-caret"></span></Button>
          }
          open={!!this.state.disableAssignmentsOpen}
          placement="bottom-end"
          appendToBody
          onRequestClose={() => {
            this.setState({
              disableAssignmentsOpen: false
            });
          }}
        >
          <div style={{
              display: "flex",
              flexDirection: "column",
              color: "000000",
              backgroundColor: "#ffffff",
              border: "1px solid #cccccc",
              padding: "12px"
            }}
          >
            {disableButton}
          </div>
        </Popover>
      );

    } else if (type === "person") {
      title = i18n.spaceplanner.popup.assignedTo
      assignBtn = (
        // <PeopleAssignTo type={type} tab={this.state.activeTab} isComments={isComments}
        //     featureItems={[this.getFeatureItem()]} isPopup={true} />
        <PeopleAssignTo type={type} tab={this.state.activeTab}
            featureItems={[this.getFeatureItem()]} isPopup={true}
            onAssignment={this.onPersonAssignmentChanged}/>
      )
    }
    const buttons = !this.isViewOnly() ? (
      <div className={"i-btns"}>
        {assignBtn}
        <RemoveButton type={type} unitUseType={useType}
          tab={this.state.activeTab}
          peopleFeatures={this.state.peopleAssigned}
          unitFeatures={this.state.unitsAssigned}
          additionalAssignments={this.state.additionalAssignments}
          onUnassignment={this.onPersonAssignmentChanged}
          feature={this.getFeatureItem()} />
        {showDisableAssignments && disableAssignments}
      </div>
    ) : null

    let headerClass = "i-assignments-header";
    let moveOccupantsBtn;
    if (type === "unit" && Array.isArray(this.state.peopleAssigned) &&
        this.state.peopleAssigned.length > 0 && !this.isViewOnly()) {
      const asnType = officePlanUtil.getUnitAssignmentType(this.getFeatureItem());
      if (asnType === "office") {
        headerClass += " i--move-occupants-on";

        moveOccupantsBtn = (
          <span>
            <Button
              clear
              small={true}
              iconButton={true}
              className={"i-assign-setting-btn"}
              icon={<MoveIcon size={18}/>}
              title={i18n.spaceplanner.moveOccupants.caption}
              onClick={() => {
                Topic.publish(Topic.MoveOccupants_Show, {
                  unitFeatureItem: this.getFeatureItem(),
                  peopleAssigned: this.state.peopleAssigned
                })
                Topic.publish(Topic.CloseItemPopup, {})
              }}/>
          </span>
        )
      }
    }

    const header = (
      <div className={headerClass} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
        {buttons}
        <div
          style={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",marginTop:"1rem"}}>
          <h3 style={{fontWeight:500}}>{title}</h3>{moveOccupantsBtn}
        </div>
      </div>
    )

    if (type === "unit") {
      const peopleAssignments = this.state.peopleAssigned
      const assignments = Array.isArray(peopleAssignments) ? (
        peopleAssignments.map((item, index) => (
          this.renderPersonItem(item, index)
        ))
      ) : (
        <Skeleton count={2} />
      );

      const featureItem = this.getFeatureItem()
      const attributes = featureItem.feature.attributes
      let asnName = officePlanUtil.getUnitAssignmentName(featureItem);
      if (asnName === undefined) {
        console.log("Data problem detected with item: ", featureItem);
        asnName = i18n.spaceplanner.unassigned;
      }
      const asnType = officePlanUtil.getUnitAssignmentType(featureItem);

      let asnAside = "";
      let areaItem = null
      if (asnType === "hotdesk" || asnType === "hotel" || asnType === "meeting room") {
        areaItem = this.findMatchingArea(attributes);
        asnAside = !this.isViewOnly() ? (
          <Button
            iconButton={true}
            icon={<DeleteIcon />}
            style={{ marginLeft: "10px" }}
            title={i18n.spaceplanner.unassign}
            onClick={(evt) => {
              if (evt) evt.stopPropagation();
              this.confirmDelete("unit", this.getFeatureItem(), "workspace", areaItem);
            }}
          />
        ) : null
      }

      const isUnassigned = officePlanUtil.isUnitUnassigned(featureItem);
      const hasPeopleAssigned = peopleAssignments && peopleAssignments.length > 0 &&
        asnType !== "hotdesk" && asnType !== "hotel" && asnType !== "meeting room"

      const popupArea = () => {
        if (asnType !== "hotdesk" && asnType !== "hotel" && asnType !== "meeting room" && !areaItem) return
        Topic.publish(Topic.ShowItemPopup, {
          view: Context.instance.views.activeView,
          title: asnName,
          featureItem: areaItem,
          fetchFeatureItemGeometry: false,
          zoom: false,
          content: <AreaPopup areaItem={areaItem} type={asnType} />
        })
      }

      const noCloseButton = (!asnAside || typeof asnAside === "string")
      let listItem = noCloseButton ? (
        <ListItem noMargin unassigned={isUnassigned} onClick={popupArea}>
          <ListItemTitle>
            <ListItemTitleMain>{asnName}</ListItemTitleMain>
            <ListItemTitleAside>{asnAside}</ListItemTitleAside>
          </ListItemTitle>
        </ListItem>
      ) : (
        <ListItem isRtl={Context.instance.uiMode.isRtl} isPopup={true} isViewOnly={this.isViewOnly()} noMargin
          unassigned={isUnassigned} onClick={popupArea}>
          <ListItemTitle>
            <ListItemTitleMain>{asnName}</ListItemTitleMain>
            <ListItemTitleAside>{asnAside}</ListItemTitleAside>
          </ListItemTitle>
        </ListItem>
      )

      // Enabling assignments for a unit should not label the unit an office in the pop up #8081
      if ((asnType === "none" || asnType === null) && !hasPeopleAssigned) {
        listItem = null;
      } else if ((asnType === "office") && !hasPeopleAssigned) {
        listItem = null;
      }

      return (
        <div id="assignments" className={"i-tab-content"}>
          {header}
          <AssignmentsContent>
            {hasPeopleAssigned ? (
              assignments
            ) : (
              <List>
                {listItem}
              </List>
            )}
          </AssignmentsContent>
        </div>
      )
    } else if (type === "person") {
      const featureItem = this.getFeatureItem()
      let asnName = officePlanUtil.getPersonAssignmentName(featureItem);
      const attributes = featureItem.feature.attributes
      const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
      let hasUnitAssigned = this.state.unitsAssigned && this.state.unitsAssigned.length > 0 && !areaId
      let areaType = null
      let asnAside = null
      let areaItem = null

      if (asnName === undefined || asnName.length === 0) {
        console.log("Data problem detected with item: ", featureItem);
        asnName = <InvalidAssignment />
      }

      if (areaId === HomeOfficeId) {
        areaType = "home";
        areaItem = { ...HomeOfficeJSON };
        asnAside = !this.isViewOnly() ? (
          <Button
            iconButton={true}
            icon={<DeleteIcon />}
            style={{ marginLeft: "10px" }}
            title={i18n.spaceplanner.unassign}
            onClick={(evt) => {
              if (evt) evt.stopPropagation();
              this.confirmDelete("home", areaItem, "person", this.getFeatureItem());
            }}
          />
        ) : null
      } else if (areaId) {
        areaItem = this.findMatchingArea(attributes);
        if (areaItem) {
          areaType = "workspace";
          asnAside = !this.isViewOnly() ? (
            <Button
              iconButton={true}
              icon={<DeleteIcon />}
              style={{ marginLeft: "10px" }}
              title={i18n.spaceplanner.unassign}
              onClick={(evt) => {
                if (evt) evt.stopPropagation();
                this.confirmDelete("workspace", areaItem, "person", this.getFeatureItem());
              }}
            />
          ) : null          
        }
      }

      const unitAssignments = this.state.unitsAssigned
      const assignments = hasUnitAssigned ? (
        unitAssignments.map((item, index) => (
          this.renderUnitItem(item, index)
        ))
      ) : (
        <Skeleton count={2} />
      );

      const isUnassigned = officePlanUtil.isPersonUnassigned(featureItem);

      const popupArea = () => {
        if ((areaType !== "workspace" && !areaItem) || (areaType === "home")) return
        Topic.publish(Topic.ShowItemPopup, {
          view: Context.instance.views.activeView,
          title: asnName,
          featureItem: areaItem,
          fetchFeatureItemGeometry: false,
          zoom: false,
          content: <AreaPopup areaItem={areaItem} type={areaType} />
        })
      }

      const noCloseButton = (!asnAside || typeof asnAside === "string")
      const icon = this.getAreaIcon(areaType);
      const listItem = noCloseButton ? (
        <ListItem noMargin unassigned={isUnassigned} onClick={popupArea}>
          <ListItemTitle>
            <ListItemTitleIcon>{icon}</ListItemTitleIcon>
            <ListItemTitleMain>{asnName}</ListItemTitleMain>
            <ListItemTitleAside>{asnAside}</ListItemTitleAside>
          </ListItemTitle>
        </ListItem>
      ) : (
        <ListItem isRtl={Context.instance.uiMode.isRtl} isPopup={true} isViewOnly={this.isViewOnly()} noMargin
          unassigned={isUnassigned} onClick={popupArea}>
          <ListItemTitle>
            <ListItemTitleIcon>{icon}</ListItemTitleIcon>
            <ListItemTitleMain>{asnName}</ListItemTitleMain>
            <ListItemTitleAside>{asnAside}</ListItemTitleAside>
          </ListItemTitle>
        </ListItem>
      )
      const additionalAssignments = this.renderAdditionalAssignments(type)
      return (
        <div id="assignments" className={"i-tab-content"}>
          {header}
          <AssignmentsContent>
            {hasUnitAssigned ? (
              assignments
            ) : (
              <List>
                {listItem}
              </List>
            )}
            {additionalAssignments}
          </AssignmentsContent>
        </div>
      )
    } else {
      return null
    }
  }

  renderAdditionalAssignments = (type) => {
    const { additionalAssignments } = this.state
    const { areas, units } = additionalAssignments
    if (type !== "person" || (areas.length === 0 && units.length === 0)) return null
    const i18n = Context.instance.i18n
    const title = i18n.spaceplanner.multipleAssignments.popupTitle
    const renderAreas = areas.map(area => this.renderAdditionalAreaItem(area))
    const renderUnits = units.map(unit => this.renderAdditionalUnitItem(unit))
    return (
      <div className="i-additional-assignments">
        <h4>{title}</h4>
        <ul>
          {renderAreas}
          {renderUnits}
        </ul>
      </div>
    )
  }
  // This is a temporary workflow to address this comment:
  // https://github.com/EsriPS/indoors-web/issues/6000#issuecomment-1397337739
  //
  // @TODO Remove (or modify) this function after UI redesign
  showHomeOfficePopup() {
    const feature = this.getFeatureItem().feature;
    const email = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
    const { assignments } = this.props;
    const personFeature = assignments && assignments.find(a => 
      aiimUtil.getAttributeValue(a.feature.attributes, FieldNames.PEOPLE_EMAIL) === email &&
      aiimUtil.getAttributeValue(a.feature.attributes, FieldNames.AREA_ID) === HomeOfficeId)
    personFeature && Topic.publish(Topic.ShowItemPopup, {
      title: aiimUtil.getAttributeValue(personFeature.feature.attributes, FieldNames.PEOPLE_FULLNAME),
      source: officePlanUtil.getPeopleSource(),
      featureItem: personFeature,
      fetchFeatureItemGeometry: false,
      zoom: false,
      content: <Popup sourceKey="People" featureItem={personFeature} isPerson={true} />
    });
  }
  renderAdditionalAreaItem = (areaItem) => {
    const i18n = Context.instance.i18n
    const isAdditionalAssignment = true
    const areaName = aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_NAME)
    const areaId = aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_ID)
    const areaType = aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_TYPE)
    const asnAside = !this.isViewOnly() ? (
      <Button
        iconButton={true}
        icon={<DeleteIcon />}
        style={{ marginLeft: "10px" }}
        title={i18n.spaceplanner.unassign}
        onClick={(evt) => {
          if (evt) evt.stopPropagation();
          this.confirmDelete(areaType, areaItem, "person", this.getFeatureItem());
        }}
      />
    ) : null
    const popupArea = () => {
      if (areaId === HomeOfficeId) {
        this.showHomeOfficePopup();
      } else {
        Topic.publish(Topic.ShowItemPopup, {
          view: Context.instance.views.activeView,
          title: areaName,
          featureItem: areaItem,
          fetchFeatureItemGeometry: false,
          zoom: false,
          content: <AreaPopup areaItem={areaItem} type={areaType} />
        })
      }
    }
    const icon = this.getAreaIcon(areaType);
    return (
      <ListItem
        isViewOnly={this.isViewOnly()}
        key={areaId}
        isRtl={Context.instance.uiMode.isRtl}
        isPopup={true} noMargin
        unassigned={false}
        onClick={popupArea}>
        <ListItemTitle>
          <ListItemTitleIcon>{icon}</ListItemTitleIcon>
          <ListItemTitleMain>{areaName}</ListItemTitleMain>
          <ListItemTitleAside>{asnAside}</ListItemTitleAside>
        </ListItemTitle>
      </ListItem>
    )
  }

  renderAdditionalUnitItem = (unitItem) => {
    const unitId = aiimUtil.getAttributeValue(unitItem.feature.attributes, FieldNames.UNIT_ID)
    return this.renderUnitItem(unitItem, unitId, true)
  }

  renderPersonItem = (item, index) => {
    const i18n = Context.instance.i18n;
    const field = FieldNames.PEOPLE_FULLNAME;
    let addClass = false;

    const dragStart = (evt) => {
      addClass = true;
      const source = officePlanUtil.getPeopleSource();
      evt.dataTransfer.dropEffect = "copy";  // copy move link
      Topic.publish(Topic.DragItemStart, {
        sourceKey: source.key,
        featureItem: item,
        fromUnitPopup: true
      });
    };

    const dragging = () => {
      if (addClass) {
        document.body.classList.add("i-drag-from-popup");
        addClass = false;
      }
    }

    const dragEnd = () => {
      addClass = false;
      const source = officePlanUtil.getPeopleSource();
      Topic.publish(Topic.DragItemEnd, {
        sourceKey: source.key,
        featureItem: item
      });
      setTimeout(() => {
        document.body.classList.remove("i-drag-from-popup");
      }, 1000);
    }

    const openPersonPopup = (e, personFeature) => {
      const attributes = personFeature.feature.attributes
      const source = officePlanUtil.getPeopleSource()
      const name = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: personFeature,
        fetchFeatureItemGeometry: true,
        zoom: false,
        content: <Popup sourceKey={source.key} featureItem={personFeature} isPerson={true} />
      })
    }

    const deleteButton = !this.isViewOnly() ? (
      <ListItemTitleAside>
        <Button
          iconButton={true}
          icon={<DeleteIcon />}
          style={{ marginLeft: "10px" }}
          title={i18n.spaceplanner.unassign}
          onClick={(evt) => {
            if (evt) evt.stopPropagation();
            this.confirmDelete("person", item, "unit", this.getFeatureItem());
          }}
        />
      </ListItemTitleAside>
    ) : null

    return this.isViewOnly() ? (
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
      onClick={e => {openPersonPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    ) : (
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
      draggable onDragStart={dragStart} onDrag={dragging} onDragEnd={dragEnd}
      onClick={e => {openPersonPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    );
  }

  renderUnitItem = (item, index, isAdditionalAssignment) => {
    const i18n = Context.instance.i18n;
    const unitField = Context.instance.aiim.getUnitName();

    const openUnitPopup = (e, unitFeature) => {
      const attributes = unitFeature.feature.attributes
      const source = officePlanUtil.getUnitsSource()
      const name = aiimUtil.getAttributeValue(attributes, unitField)
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: unitFeature,
        fetchFeatureItemGeometry: true,
        zoom: !!isAdditionalAssignment,
        content: <Popup sourceKey={source.key} featureItem={unitFeature} isPerson={false} />
      })
    }

    const deleteButton = !this.isViewOnly() ? (
      <ListItemTitleAside>
        <Button
          iconButton={true}
          icon={<DeleteIcon />}
          style={{ marginLeft: "10px" }}
          title={i18n.spaceplanner.unassign}
          onClick={(evt) => {
            if (evt) evt.stopPropagation();
            this.confirmDelete("unit", item, "person", this.getFeatureItem());
          }}
        />
      </ListItemTitleAside>
    ) : null

    return(
      <ListItem key={index} isPopup={true} isViewOnly={this.isViewOnly()} isRtl={Context.instance.uiMode.isRtl}
        onClick={e => {openUnitPopup(e, item)}}
      >
      <ListItemTitle>
        <ListItemTitleIcon lowerIcon>
          <FloorPlanIcon/>
        </ListItemTitleIcon>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, unitField)}
        </ListItemTitleMain>
        {deleteButton}
        </ListItemTitle>
      </ListItem>
    );
  }

  renderProperties(type) {
    if (!type) return null
    if (this.state.activeTab !== tabs.properties) return null
    const featureItem = this.getFeatureItem();
    const item = new ItemReference()
    const source = this.getSource(type)
    if (!source) return null
    item._fromFeature(source.key, featureItem.feature)

    const isPlaceholder = officePlanUtil.isPersonPlaceholder(featureItem)

    const i18n = Context.instance.i18n;
    let edit = null;
    if (type === "unit" || type === "person") {
      let feature = featureItem.feature;
      let style = {
        float: "right",
        marginTop: "4px",
        minWidth: "4rem",
        height: "1.5rem"
      };
      if (Context.instance.uiMode.isRtl) {
        style.float = "left";
      }
      edit = (
        <Button
          extraSmall
          style={style}
          icon={<EditIcon size={16}/>}
          iconPosition="before"
          onClick={evt => {
            AttributeEditor.show({
              title: type === "person" && i18n.spaceplanner.editAttributes.occupantTitle,
              type: type,
              feature: feature,
              occupantType: 'edit',
              isPlaceholder: isPlaceholder
            });
          }}
        >{i18n.general.edit}</Button>
      );
    }

    return (
      <div id="properties" className={"i-tab-content"}>
        {edit}
        <Attributes item={item} active={true} source={source} popup={true} />
      </div>
    )
  }

  renderRelated(type) {
    if (!type) return null
    const style = {}
    if (this.state.activeTab !== tabs.related) style.display = "none"
    const featureItem = this.getFeatureItem();
    const item = new ItemReference()
    const source = this.getSource(type)
    if (!source) return null
    item._fromFeature(source.key, featureItem.feature)
    return (
      <div id="related" className={"i-tab-content"} style={style}>
        <RelatedFeatures item={item} feature={featureItem}
          type={type} setRelatedItems={this.setRelatedItems} />
      </div>
    )
  }

  setRelatedItems = (count) => {
    this.setState({
      relatedItems: count
    })
  }

  renderTabs(type) {
    const i18n = Context.instance.i18n
    const activeTab = this.state.activeTab
    let assignmentsCount
    if (type === "unit") {
      assignmentsCount = this.state.peopleAssigned && this.state.peopleAssigned.length
    } else if (type === "person") {
      assignmentsCount = this.state.unitsAssigned && this.state.unitsAssigned.length
    }
    let assignmentsTabLabel = i18n.spaceplanner.popup.assignments
    if (assignmentsCount) {
      assignmentsTabLabel = i18n.spaceplanner.popup.assignmentsCount
      assignmentsTabLabel = assignmentsTabLabel.replace("{count}", assignmentsCount)
    }
    let relatedCount = this.state.relatedItems
    let relatedTabLabel = i18n.spaceplanner.popup.related
    if (relatedCount > 0) {
      relatedTabLabel = i18n.spaceplanner.popup.relatedCount
        .replace("{count}", relatedCount)
    }

    const clsAssignments = activeTab === tabs.assignments ? "i-tab i-tab-active" : "i-tab"
    const clsProperties = activeTab === tabs.properties ? "i-tab i-tab-active" : "i-tab"
    const clsRelated = activeTab === tabs.related ? "i-tab i-tab-active" : "i-tab"

    let tabAssignments
    if (this.state.assignmentsDisabled) {
      tabAssignments = (
        <button id="tab-assignments" disabled
          aria-selected={activeTab === tabs.assignments}
          className={"i-tab i-tab-disabled"} role="tab">{assignmentsTabLabel}</button>
      )
    } else {
      tabAssignments = (
        <button id="tab-assignments" onClick={e => this.changeActiveTab(e, tabs.assignments)}
          aria-selected={activeTab === tabs.assignments}
          className={clsAssignments} role="tab">{assignmentsTabLabel}</button>
      )
    }
    const tabProperties = (
      <button id="tab-properties" onClick={e => this.changeActiveTab(e, tabs.properties)}
        aria-selected={activeTab === tabs.properties}
        className={clsProperties} role="tab">{i18n.spaceplanner.popup.properties}</button>
    )
    // CHANGE TO RELATEDCOUNT
    const tabRelated = (
      <button id="tab-related" onClick={e => this.changeActiveTab(e, tabs.related)}
        aria-selected={activeTab === tabs.related}
        className={clsRelated} role="tab">{relatedTabLabel}</button>
    )

    // Need people and units tabs

    if (type === "unit" || type === "person") {
      return (
        <nav className={"i-popup-tabs"}>
          {tabAssignments}
          {tabProperties}
          {tabRelated}
        </nav>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  activeSection: getActiveSection(state),
  assignments: Rdx.getValue(state, "spaceplanner/assetbar/PeoplePanel_FeatureItems")
});

const Popup = connect(mapStateToProps)(_Popup);
export default Popup;
