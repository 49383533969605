import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import CalciteList, {
  ListHeader as CalciteListHeader,
  ListItem as CalciteListItem,
  ListItemSubtitle as CalciteListItemSubtitle
} from "calcite-react/List";
import { CalciteH4 } from "calcite-react/Elements";
import Context from "../../../context/Context";

const List = styled(CalciteList)`
  flex: 1;
  overflow-y: auto;
  background: ${(props) => props.theme.palette.background};
  border: 0;
  ${(props) =>
    props.simple &&
    css`
      background: inherit;
      padding: 0;
    `};
`;

const ListHeader = styled(CalciteListHeader)`

`;

const ListItem = styled(CalciteListItem)`

  & > div {
    overflow: hidden;
  }

  &[draggable] {
    cursor: move;
  }

  margin: 5px 0;
  background: ${props => props.theme.palette.white};
  border-top: 0;
  box-shadow: ${props => props.theme.boxShadow};
  transition: background ${props => props.theme.transition};
  position: relative;
  border-left: ${(props) => (props.moreButton || props.reviewers || props.addReviewer) ? "0 !important" : `3px solid ${props.theme.palette.themeColor} !important`};

  ${(props) =>
    props.unassigned &&
    css`
      border-left: 3px solid ${props.theme.palette.semantic.noAssignment} !important;
    `};

  ${(props) =>
    props.unassigned &&
    props.isPlaceholder && 
    css`
      border-left: 3px dashed ${props.theme.palette.semantic.noAssignment} !important;
    `};

  ${(props) => 
    props.unassigned &&
    Context.instance.uiMode.isRtl &&
    css`
      border-right: 3px solid ${props.theme.palette.semantic.noAssignment} !important;
      border-left: 0 !important;
    `}
  
  ${(props) => 
    props.unassigned &&
    Context.instance.uiMode.isRtl &&
    props.isPlaceholder && 
    css`
      border-right: 3px dashed ${props.theme.palette.semantic.noAssignment} !important;
      border-left: 0 !important;
    `}

  ${(props) => 
    !props.unassigned &&
    props.isPlaceholder &&
    css`
      border-left: 3px dashed ${props.theme.palette.themeColor} !important;
    `}

  ${(props) => 
    !props.unassigned &&
    Context.instance.uiMode.isRtl &&
    css`
      border-right: 3px solid ${props.theme.palette.themeColor} !important;
      border-left: 0 !important;
    `}

  ${(props) => 
    !props.unassigned &&
    Context.instance.uiMode.isRtl &&
    props.isPlaceholder &&
    css`
      border-right: 3px dashed ${props.theme.palette.themeColor} !important;
      border-left: 0 !important;
    `}

  /* Popup */
  ${props =>
    props.isPopup && !props.isViewOnly &&
    css`
      padding: 0 0 0 0.5rem !important;
    `}

  ${props =>
    props.isPopup && props.isViewOnly &&
    css`
      padding: 0.5rem 0 0.5rem 0.5rem !important;
    `}

  /* RTL */
  ${props =>
    props.isPopup && props.isRtl &&
    css`
      padding-right: 0.5rem !important;
      padding-left: 0 !important;
    `}

  /* More Button Variant */
  ${props =>
    props.moreButton &&
    css`
      background: transparent;
      border-left: 0;
      box-shadow: none;

      &:hover {
        background: transparent;
      }
    `};

  /* Reviewer item */
  ${props =>
    props.reviewers &&
    css`
      border-left: 0;
      cursor: default;
      margin: 5px ${props => unitCalc(props.theme.baseline, 3, "/")};

      &:hover {
        background: transparent;
      }
    `};

    ${(props) => (props.reviewers || props.addReviewer) && Context.instance.uiMode.isRtl &&
      css`
      border-right: 0 !important;
    `}


  /* No Margin Override */
  ${props =>
    props.noMargin &&
    css`
      margin: 0 0 ${props => unitCalc(props.theme.baseline, 5, "/")} 0;
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        ${'' /* margin-bottom: 0; */}
      }
    `};

  /* Selected status overrides */
  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.palette.blue};
      color: ${props => props.theme.palette.white};

      div {
        color: ${props => props.theme.palette.white};
      }

      &:hover,
      &:focus {
        text-indent: inherit;
        background: ${props => props.theme.palette.blue};
        color: ${props => props.theme.palette.white};
      }
    `};
`;

const ListItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.833rem;
  overflow: hidden;
  flex-grow: 1;
  ${props =>
    props.markAsReady &&
    css`
      margin-bottom: 0.75rem;
      justify-content: center;
      align-items: center;
    `
  }
`;

const ListItemTitleIcon = styled.div`
  margin-right: 0.3rem;
  svg {
    padding: 0.2rem;
  }
  ${props => 
    props.lowerIcon && css`
      svg { 
        position: relative;
        top: 0.1rem; 
      }
    `
  };
  ${props => 
    props.brand && css`
      color: ${props => props.theme.palette.themeColor}
    `
  };
`;


const ListItemTitleMain = styled.span`
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: auto;
  margin-right: auto;
  ${props => 
    Context.instance.uiMode.isRtl && css`
      margin-left: auto;
      margin-right: 0;
    `}
`;


const ListItemTitleAside = styled.div`
  align-items: center;
  display: flex;
`;


const ListItemColumn = styled.div`
  margin: 0 ${props => unitCalc(props.theme.baseline, 2, "/")};
  text-align: right;
`;


const ListItemTitleHint = styled.div`
  margin: 0 ${props => unitCalc(props.theme.baseline, 2, "/")};
  font-size: 0.9rem;
  color: ${(props) => props.theme.palette.gray};
`;

const ListItemSubtitle = styled(CalciteListItemSubtitle)`
  border-top: 1px solid ${props => props.theme.palette.lightestGray};
  padding-top: 6px;
  margin-top: 6px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const ListItemAttribute = styled.div``;

const ListItemOverlay = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.backgroundColor};
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0s ease;
  ${ListItem}:hover & {
    background-color: #ffffff;
    bottom: 0;
    height: 100%;
    border-right: 3px solid transparent;
    ${'' /* box-shadow: -2px 2px 6px 0 ${(props) => props.theme.palette.themeColor}; */}
    box-shadow: -2px 2px 6px 0 transparent;
  }
`;

const ListItemOverlayContent = styled.div`
  position: relative;
  top: 42%;
  transform: translate(0, -50%);
  text-align: center;
`;

const ListItemOverlayHeader = styled(CalciteH4)`
  margin: 8px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItemButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  button {
    padding: 4px 1.2rem;
    .calcite-icon {
      margin: 0 !important;
    }
  }
`;

const EmptyAlert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  text-align: center;
`;

export {
  List,
  ListHeader,
  ListItem,
  ListItemTitle,
  ListItemTitleMain,
  ListItemTitleIcon,
  ListItemTitleAside,
  ListItemColumn,
  ListItemTitleHint,
  ListItemSubtitle,
  ListItemAttribute,
  ListItemOverlay,
  ListItemOverlayContent,
  ListItemOverlayHeader,
  ListItemButtons,
  EmptyAlert
};
