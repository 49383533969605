import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as projectionUtil from "../../../util/projectionUtil";
import * as queryUtil from "../../base/queryUtil";
import * as sourceUtil from "../../base/sourceUtil";
import * as officePlanUtil from "../../base/officePlanUtil";

export default class MapSelectionModel extends BaseClass {

  activeSourceKey;
  expanded;
  view;

  _activePromise;
  _activeSketchViewModel;
  _ctrlKey;
  _highlightHandle;
  _layerId = "indoors-mapselection";
  _peopleGfxLayerId = "indoors-mapselection-people";
  _peopleGfxLayer;
  _shiftKey;

  constructor(props) {
    super(props);
    this.watchKeys = this.watchKeys.bind(this);
  }

  activateSketch(type,createOptions) {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const graphicsLayer = this.ensureGraphicsLayer(this.view,this._layerId);
    if (!this._peopleGfxLayer) {
      this._peopleGfxLayer = this.ensureGraphicsLayer(this.view,this._peopleGfxLayerId);
    }
    let color = "#e6e6e6";
    let svm = this._activeSketchViewModel = new lib.esri.SketchViewModel({
      view: this.view,
      layer: graphicsLayer,
      pointSymbol: new Context.instance.lib.esri.SimpleMarkerSymbol({
        style: "circle",
        size: 6,
        color: color,
        outline: { color: color, width: 1 }
    })
    });
    svm.on("create",event => {
      if (event.state === "complete") {
        const keys = {
          shiftKey: this._shiftKey,
          ctrlKey: this._ctrlKey
        }
        graphicsLayer.remove(event.graphic);
        //this.cancelSketch();
        //this.activateSketch(type,createOptions) ;
        svm.create(type,createOptions);
        this.queryFeatures(event,keys,event.graphic.geometry)
      }
    });
    Context.instance.views.toggleClickHandlers("pause");
    svm.create(type,createOptions);
    this._shiftKey = this._ctrlKey = false;
    window.addEventListener("keydown",this.watchKeys);
    window.addEventListener("keyup",this.watchKeys);
    this._dragHandle=
      this.view.on("drag", ["Shift"], function(event) {
        event.stopPropagation();
      });
  }

  cancelSketch() {
    window.removeEventListener("keydown",this.watchKeys);
    window.removeEventListener("keyup",this.watchKeys);
    Topic.publish(Topic.ClearToast,{});
    if(this._dragHandle) {
      this._dragHandle.remove();
      this._dragHandle=null;
    }
    this._shiftKey = this._ctrlKey = false;
    const svm = this._activeSketchViewModel;
    if (svm) {
      svm.cancel();
      svm.destroy();
      this._activeSketchViewModel = null;
      Context.instance.views.toggleClickHandlers("resume");
    }
  }

  clearHighlight() {
    const h = this._highlightHandle;
    if (h) {
      h.remove();
      this._highlightHandle = null;
    }
    if (this._peopleGfxLayer) {
      this._peopleFeatures= null;
      this._peopleGfxLayer.graphics.removeAll();
    }
  }

  ensureGraphicsLayer(view,layerId) {
    let layer = view.map.findLayerById(layerId);
    if (!layer) {
      const lib = Context.instance.lib;
      layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: Context.instance.config.graphicElevationOffset,
        unit: "meters"
      };
      view.map.add(layer);
    }
    return layer;
  }

  // will be overridden by MapSelection.js
  getActiveFeatures(source) {
    return [];
  }

  getActiveSource() {
    return sourceUtil.getSourceByKey(this.activeSourceKey);
  }

  getPeople(result,keys){
    const source=sourceUtil.getPeopleSource();
    const promise = this._activePromise =new Promise((resolve, reject)=>{
      let featureItems=[];
      officePlanUtil.getPeople(result.features)
      .then(result=>{
        if(promise!== this._activePromise) {
          resolve(0);
          return;
        }
        if (!this.expanded) {
          resolve(0);
          return;
        }

        const featuresA = this.getActiveFeatures(source);
        const featuresB = result && result.features;
        const mergeFeatures = this.mergeFeatures(source,keys,featuresA,featuresB);

        const featureItems = [];
        mergeFeatures.forEach(feature => {
          featureItems.push(queryUtil.makeFeatureItem(source,feature));
        })

        this.view.whenLayerView(source.layer2D)
        .then(layerView => {
          this.clearHighlight();
          if (layerView && mergeFeatures.length > 0) {
            // Update JSAPI 4.22 - Need source layer to derive object ids
            mergeFeatures.forEach((feature) => feature.sourceLayer = source.layer2D);
            this._highlightHandle = layerView.highlight(mergeFeatures);
            this._highlightPeople(mergeFeatures);
          }
          resolve(0);
        })
        .catch(error=>{
          reject(error);
        })

        if (this.activeSourceKey === source.key) {
          this.onFeaturesSelected(source,mergeFeatures,featureItems);
          if(result.features.length === 0) {
            const i18n = Context.getInstance().i18n;
            let msg = i18n.spaceplanner.mapSelection.unassignablePeople;
            Topic.publish(Topic.ShowToast,{
              message: msg
            });
          }
          resolve(0);
        }
      })
      .catch(error=>{
        reject(error);
      })
    })
    return promise;
  }

  _isMatching2DLevel(feature) {
    if(!Context.instance.aiim.facilityMode) return;
    const source = sourceUtil.getPeopleSource();
    let featureVO, featureFacilityId;

    const zInfo = Context.instance.aiim.getZInfo(source,feature);
    const ld = zInfo && zInfo.levelData;
    if (ld) {
      featureVO = ld.verticalOrder;
      featureFacilityId = ld.facilityId;
    } else {
      featureVO = aiimUtil.getAttributeValue(feature.attributes,FieldNames.VERTICAL_ORDER);
      featureFacilityId = aiimUtil.getAttributeValue(feature.attributes,FieldNames.FACILITY_ID);
    }

    const facilityInfo = Context.instance.aiim.getActiveFacilityInfo();
    const activeLevel = (facilityInfo && facilityInfo.activeLevel);
    if (activeLevel) {
      const activeVO = activeLevel.verticalOrder;
      const activeFacilityId = activeLevel.facilityId;
      if (featureFacilityId === activeFacilityId) {
        return (featureVO === activeVO);
      } else {
        return (featureVO === 0);
      }
    } else {
      return (featureVO === 0);
    }

  }

  _highlightPeople(features) {
    let gfxLayer = this._peopleGfxLayer;
    if (!gfxLayer) return;
    this._peopleFeatures= features;
    const lib = Context.instance.lib;
    const gfx = [];
    const symbol = {
      type: "simple-marker",
      color: [0, 255, 1255], // [0, 94, 149]
      size: "14px",
      outline: {
        color: [0, 0, 0],
        width: 1
      }
    };
    features.forEach((f,i) => {
      try {
        if (f.geometry) {
          if(this._isMatching2DLevel(f)){
            let g = new lib.esri.Graphic({
              attributes: {name: ""+i},
              geometry: f.geometry.clone(),
              symbol: symbol
            });
            gfx.push(g);
          }
        }
      } catch(ex) {
        console.error("Error creating highlight graphic",ex);
      }
    });
    gfxLayer.graphics.removeAll();
    if (gfx.length > 0) {
      gfxLayer.graphics.addMany(gfx);
    }
  };

  mergeFeatures(source,keys,featuresA,featuresB) {
    let combined = [];
    featuresA = featuresA || [];
    featuresB = featuresB || [];
    if (keys.shiftKey) {
      let oidField = source.layer2D.objectIdField;
      let oids = [];
      featuresA.forEach(f => {
        oids.push(f.attributes[oidField]);
        combined.push(f);
      });
      featuresB.forEach(f => {
        if (oids.indexOf(f.attributes[oidField]) === -1) {
          combined.push(f);
        }
      });
    } else if (keys.ctrlKey) {
      let oidField = source.layer2D.objectIdField;
      let oids = [];
      featuresB.forEach(f => {
        oids.push(f.attributes[oidField]);
      });
      featuresA.forEach(f => {
        if (oids.indexOf(f.attributes[oidField]) === -1) {
          combined.push(f);
        }
      });
    } else {
      combined = featuresB;
    }
    return combined;
  }

  // will be overridden by MapSelection.js
  onFeaturesSelected(source,features,featureItems) {}

  queryLayer(keys,geometry){
    const source = this.getActiveSource();
    if (!source) return;

    const isPeopleLayer = source.isAiimPeople();
    const isUnitsLayer = source.isAiimUnits();

    if(isPeopleLayer && geometry && geometry.type==="point"){
      const lib = Context.instance.lib;
      const bufferMeters = 1;
      geometry = projectionUtil.geodesicBuffer(geometry, bufferMeters);
    }

    const queryCriteria = new QueryCriteria({
      sourceKey: source.key
    });

    let reqexp = queryCriteria.makeLevelFilter(source);
    if (isUnitsLayer) {
      let asnFieldName = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;
      if (source && source.layer2D) {
        const fields = source.layer2D.fields;
        let f = aiimUtil.findField(fields, asnFieldName);
        if (f) asnFieldName = f.name;
      }
      const wh = `${asnFieldName} <> 'not assignable' OR ${asnFieldName} IS NULL`;
      if (reqexp) {
        reqexp = "(("+wh+") AND ("+reqexp+"))";
      } else {
        reqexp = wh;
      }
    }

    queryCriteria.requiredExpression = reqexp;
    queryCriteria.where = queryCriteria.makeWhere(source);
    let options = {source,geometry};
    options.where = queryCriteria.getWhere();
    options.returnGeometry = true;

    const promise = this._activePromise = queryUtil.queryFeatures(options);
    promise.then(result => {
      if (!this.expanded) return;
      if (promise !== this._activePromise) return;
      const featuresA = this.getActiveFeatures(source);
      const featuresB = result && result.features;
      const features = this.mergeFeatures(source,keys,featuresA,featuresB);
      const featureItems = [];
      features.forEach(feature => {
        featureItems.push(queryUtil.makeFeatureItem(source,feature));
      })
      this.view.whenLayerView(source.layer2D).then(layerView => {
        if (this.activeSourceKey === source.key) {
          this.clearHighlight();
          if (layerView && features.length > 0) {
            // Update JSAPI 4.22 - Need source layer to derive object ids
            features.forEach((feature) => feature.sourceLayer = source.layer2D);
            this._highlightHandle = layerView.highlight(features);
            if (isPeopleLayer) this._highlightPeople(features);
          }
        }
      });
      if (this.activeSourceKey === source.key) {
        this.onFeaturesSelected(source,features,featureItems);
        if(result.features.length === 0) {
          const i18n = Context.getInstance().i18n;
          let msg;
          if(isUnitsLayer) msg = i18n.spaceplanner.mapSelection.unassignableUnits;
          else if(isPeopleLayer) msg = i18n.spaceplanner.mapSelection.unassignablePeople;
          Topic.publish(Topic.ShowToast,{
            message: msg
          });
        }
      }
    }).catch(error=>{
      console.log("Error in querying features", error);
    })
  }

  queryFeatures(event,keys,geometry) {
    Topic.publish(Topic.ClearToast,{});
    const source = this.getActiveSource();
    if (!source) return;
    const isPeopleLayer = source.isAiimPeople();
    if(isPeopleLayer){
      //queryLayers contains changed code
      this.queryLayers(keys,geometry)
      .then(value=>{
        if(value === "countExcedesLimit") {
          //queryLayer contains previous unchanged code
          this.queryLayer(keys, geometry);
        }
      })
      .catch(error=>{
        console.error("Error in querying features", error);
      })
    }else{
      this.queryLayer(keys,geometry);
    }
  }

  queryLayers(keys,geometry) {
    return new Promise((resolve, reject)=>{
      const source=sourceUtil.getUnitsSource();
      if (!source) return;
      const queryCriteria = new QueryCriteria({
        sourceKey: source.key
      });

      let reqexp = queryCriteria.makeLevelFilter(source);
      let asnFieldName = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;
      if (source && source.layer2D) {
        const fields = source.layer2D.fields;
        let f = aiimUtil.findField(fields, asnFieldName);
        if (f) asnFieldName = f.name;
      }
      const wh = `${asnFieldName} <> 'not assignable' OR ${asnFieldName} IS NULL`;
      if (reqexp) {
        reqexp = "(("+wh+") AND ("+reqexp+"))";
      } else {
        reqexp = wh;
      }

      queryCriteria.requiredExpression = reqexp;
      queryCriteria.where = queryCriteria.makeWhere(source);
      let options = {source,geometry};
      options.where = queryCriteria.getWhere();
      options.returnGeometry = true;
      let countExcedesLimit=false, isActive=true;
      const promise = this._activePromise = queryUtil.queryFeatures(options);
      promise
      .then(result => {
        if (!this.expanded) {
          isActive=false;
        }
        if (promise !== this._activePromise)
        {
          isActive=false;
        }
        if((isActive && result.features.length > 100) || (isActive && result.features.length === 0)){
          countExcedesLimit=true;
        }
        return result;
      }).then((result)=>{
        // if(!result || result.features.length === 0) {
        //   resolve(0);
        // }
        if(isActive && !countExcedesLimit){
          return this.getPeople(result,keys);
        }
      }).then(() =>{
        if(countExcedesLimit) resolve("countExcedesLimit");
        else resolve(0);
      }).catch(error=>{
        reject(error);
      })
    })
  }

  rehighlightPeople(){
    if(this._peopleFeatures){
      this._highlightPeople(this._peopleFeatures);
    }
  }

  setActiveSourceKey(sourceKey) {
    this.activeSourceKey = sourceKey;
  }

  watchKeys=(event)=> {
    if (event.type === "keydown") {
      this._shiftKey = event.shiftKey;
      this._ctrlKey = event.ctrlKey;
    } else {
      this._shiftKey = this._ctrlKey = false;
    }
  }
}
