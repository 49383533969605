import React from "react";
import { connect } from "react-redux";

import Context from "../../../context/Context";
import Issues from "./Issues";
import ItemBrowser from "../common/components/browser/ItemBrowser";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-button";
import {
  CalciteBlock,
  CalciteButton
} from "@esri/calcite-components-react";

interface Props {
  onChange: ({isValid}) => void;
}

interface State {
  itemBrowserOpen: boolean,
  selectedItem: any,
  viewsReloading: boolean
}

class WebMap extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({
      itemBrowserOpen: false,
      selectedItem: null,
      viewsReloading: false
    });
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.Configurator_BrowseForMap,params => {
        this.setState({itemBrowserOpen: true})
      }),
      Topic.subscribe(Topic.ReloadViews,params => {
        this.setState({viewsReloading: true})
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.setState({viewsReloading: false}, this.onChange)
      })
    ]);
    this.onChange();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getMap() {
    const views = Context.instance.views;
    return (views && views.mapView && views.mapView.map);
  }

  onChange() {
    const map = this.getMap();
    const project = Context.instance.spaceplanner.planner && Context.instance.spaceplanner.planner.project;
    const hasIssues = !map || (project && project.hasIssues());
    this.props.onChange({isValid: !hasIssues});
  }

  render() {
    const i18n = Context.instance.i18n;
    const map = this.getMap();
    const { selectedItem, viewsReloading } = this.state
    // @ts-ignore
    const item = (map && map.portalItem) || selectedItem;
    return (
      <CalciteBlock 
        open 
        heading={i18n.miniapps.configurator.webmap.caption}
        description={item && item.title}
        loading={viewsReloading ? true : undefined}
      >
        <div className={viewsReloading ? "i-min-block" : undefined}>
          {this.renderIssues()}
          <div className="i-button-bar-right">
            {item && configUtil.renderDetailsLink(item)}
            <CalciteButton 
              onClick={() => {
                this.setState({itemBrowserOpen: true})
              }}
            > 
              {item ? i18n.configurator.change: i18n.general.select}
            </CalciteButton>
            {this.renderItemBrowser()}
          </div>
        </div>
      </CalciteBlock>
    )
  }

  renderIssues() {
    if (this.state.viewsReloading) return null;
    const i18n = Context.instance.i18n;
    const map = this.getMap();
    const project = Context.instance.spaceplanner.planner && Context.instance.spaceplanner.planner.project;
    let issues = project && project.issues;
    if (!map && !(project && project.hasIssues())) {
      issues = [{
        key: "selectAWebMap",
        message: i18n.miniapps.configurator.webmap.selectAWebMap
      }]
    }
    if (issues && issues.length > 0) {
      return <Issues issues={issues} />
    }
    return null;
  }

  renderItemBrowser() {
    const { itemBrowserOpen } = this.state;
    if (itemBrowserOpen) {
      return (
        <ItemBrowser
          open={itemBrowserOpen}
          title={Context.instance.i18n.configurator.primary.webmap}
          itemType="Web Map"
          rdxKey="cfg-webmap"
          onClose={() => {
            this.setState({itemBrowserOpen: false})
          }}
          onSelect={(item) => {
            Context.instance.config.webmap = item.id;
            Topic.publish(Topic.ReloadViews,{});
            this.setState({
              itemBrowserOpen: false,
              selectedItem: item
            })
          }}
        />
      )
    }
    return null;
  }

}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WebMap);