import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import { getAttributes } from "./MultipleAssignments/multipleAssignmentsUtil";

export function getPrompt(promptOptions,targetItem) {
  const i18n = Context.instance.i18n;
  const prompt = {};
  const subject = promptOptions.subject;
  const target = promptOptions.target;
  const subjectItems = promptOptions.subjectItems;
  let count = (subjectItems && subjectItems.length) || 0;
  if (subject === "people") {
    const uniquePeople = []
    const features = promptOptions.subjectItems;
    if (features) {
      features.forEach(person => {
        const personAttributes = getAttributes(person)
        const uniqueId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
        if (!uniquePeople.includes(uniqueId)) uniquePeople.push(uniqueId)
      })
    }
    count = uniquePeople.length
  }

  let pattern, subjectItem, subjectName;
  if (count === 1) subjectItem = subjectItems[0];

  if (subject === "people") {
    if (count === 1) {
      subjectName = getPersonName(subjectItem);
    }
    if (target === "unit") {
      if (count === 1) {
        pattern = i18n.spaceplanner.people.assignTo.unit.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.people.assignTo.unit.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    } else if (target === "hotdesk") {
      if (count === 1) {
        pattern = i18n.spaceplanner.people.assignTo.hotdesk.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.people.assignTo.hotdesk.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    } else if (target === "hotel") {
      if (count === 1) {
        pattern = i18n.spaceplanner.people.assignTo.hotel.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.people.assignTo.hotel.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    } else if (target === "workspaceArea") {
      if (count === 1) {
        pattern = i18n.spaceplanner.people.assignTo.workspaceArea.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.people.assignTo.workspaceArea.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    }

  } else if (subject === "units") {
    if (count === 1) {
      subjectName = getUnitName(subjectItem);
    }
    if (target === "person") {
      pattern = i18n.spaceplanner.units.assignTo.person.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    } else if (target === "hotdesk") {
      if (count === 1) {
        pattern = i18n.spaceplanner.units.assignTo.hotdesk.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.units.assignTo.hotdesk.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    } else if (target === "hotel") {
      if (count === 1) {
        pattern = i18n.spaceplanner.units.assignTo.hotel.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.units.assignTo.hotel.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    } else if (target === "meeting room") {
      if (count === 1) {
        pattern = i18n.spaceplanner.units.assignTo.meetingroom.promptSingle;
        prompt.text = pattern.replace("{name}",subjectName);
      } else {
        pattern = i18n.spaceplanner.units.assignTo.meetingroom.promptMultiple;
        prompt.text = pattern.replace("{count}",count);
      }
    }

  } else if (subject === "hotdesk") {
    subjectName = getAreaName(subjectItem);
    if (target === "person") {
      pattern = i18n.spaceplanner.hotdeskAreas.assignTo.person.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    } else if (target === "unit") {
      pattern = i18n.spaceplanner.hotdeskAreas.assignTo.unit.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    }

  } else if (subject === "hotel") {
    subjectName = getAreaName(subjectItem);
    if (target === "person") {
      pattern = i18n.spaceplanner.hotelAreas.assignTo.person.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    } else if (target === "unit") {
      pattern = i18n.spaceplanner.hotelAreas.assignTo.unit.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    }

  } else if (subject === "workspace") {
    subjectName = getAreaName(subjectItem);
    if (target === "person") {
      pattern = i18n.spaceplanner.workspaceAreas.assignTo.person.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    } else if (target === "unit") {
      pattern = i18n.spaceplanner.workspaceAreas.assignTo.unit.prompt;
      prompt.text = pattern.replace("{name}",subjectName);
    }

  }

  return prompt;
}

export function getUnassignPrompt(promptOptions) {
  //console.log("getPrompt.promptOptions",promptOptions)
  const i18n = Context.instance.i18n;
  const prompt = {};
  let count, pattern, subjectName, fromName;

  const subject = promptOptions.subject;
  let subjectItem = promptOptions.subjectItem;
  const subjectItems = promptOptions.subjectItems;
  const disableAssignments = promptOptions.disableAssignments;
  if (!subjectItem && (subjectItems && subjectItems.length === 1)) {
    subjectItem = subjectItems[0];
  }
  if (subjectItem) {
    count = 1;
  } else  {
    count = (subjectItems && subjectItems.length) || 0;
  }

  const from = promptOptions.from;
  const fromItem = promptOptions.fromItem;

  if (disableAssignments && fromItem) {
    if (subject === "people") {
      const unitName = getUnitName(fromItem);
      let personName = getPersonName(subjectItem);
      // Singular person vs multiple people
      if (count === 1) {
        pattern = i18n.spaceplanner.popup.disableAssignments.unassignPerson;
      } else {
        pattern = i18n.spaceplanner.popup.disableAssignments.unassignPeople;
        pattern = replaceCount(pattern, count);
      }
      prompt.text = replaceSubjectTarget(pattern, personName, unitName);
    } else if (subject === "unit") {
      const unitName = getUnitName(subjectItem);
      const areaName = getAreaName(fromItem);
      pattern = i18n.spaceplanner.popup.disableAssignments.unassignFromArea;
      prompt.text = replaceSubjectTarget(pattern, unitName, areaName);
    }

    return prompt;
  }

  if (subject === "person") {
    pattern = i18n.spaceplanner.unassignPrompt.unassignFrom;
    subjectName = getPersonName(subjectItem);
    fromName = promptOptions.fromName;
    if (!fromName && fromItem) {
      if (from === "unit") {
        fromName = getUnitName(fromItem);
      } else if (from === "hotdesk") {
        fromName = getAreaName(fromItem);
      } else if (from === "hotel") {
        fromName = getAreaName(fromItem);
      } else if (from === "workspace" || from === "workspaceArea") {
        fromName = getAreaName(fromItem);
        // } else if (from === "home") {
        //   fromName = getAreaName(fromItem);
      }
    }
    prompt.text = replaceSubjectTarget(pattern,subjectName,fromName);

  } else if (subject === "unit") {
    pattern = i18n.spaceplanner.unassignPrompt.unassignFrom;
    subjectName = getUnitName(subjectItem);
    fromName = promptOptions.fromName;
    if (!fromName && fromItem) {
      if (from === "hotdesk") {
        fromName = getAreaName(fromItem);
      } else if (from === "hotel") {
        fromName = getAreaName(fromItem);
      } else if (from === "workspace" || from === "workspaceArea") {
        fromName = getAreaName(fromItem);
      }
    }
    prompt.text = replaceSubjectTarget(pattern,subjectName,fromName);

  } else if (subject === "people") {
    if (fromItem && from) {
      if (from === "unit") {
        fromName = getUnitName(fromItem);
      } else if (from === "hotdesk") {
        fromName = getAreaName(fromItem);
      } else if (from === "hotel") {
        fromName = getAreaName(fromItem);
      } else if (from === "workspace" || from === "workspaceArea") {
        fromName = getAreaName(fromItem);
      }
    }

    if(count === 1) {
      pattern = i18n.spaceplanner.unassignPrompt.unassignFrom;
      subjectName = getPersonName(subjectItem);
      prompt.text = replaceSubjectTarget(pattern,subjectName,fromName);
    } else {
      if (fromItem) {
        pattern = i18n.spaceplanner.unassignPrompt.unassignPeopleUnit;
        let text = replaceCount(pattern,count);
        prompt.text = text.replace("{unitName}", fromName);
      } else {
        pattern = i18n.spaceplanner.unassignPrompt.unassignPeople;
        prompt.text = replaceCount(pattern,count);
      }
    }
  } else if (subject === "units") {
    if (count === 1) {
      pattern = i18n.spaceplanner.unassignPrompt.unassignUnit;
      subjectName = getUnitName(subjectItem);
      prompt.text = replaceName(pattern,subjectName);
    } else {
      pattern = i18n.spaceplanner.unassignPrompt.unassignUnits;
      prompt.text = replaceCount(pattern,count);
    }

  } else if (subject === "hotdesk" || subject === "hotel" || subject === "workspace" || subject === "workspaceArea" || subject === "home") {
    subjectName = getAreaName(subjectItem);
    fromName = promptOptions.fromName
    if (from === "person") {
      pattern = i18n.spaceplanner.unassignPrompt.unassignAreaFromPerson
      prompt.text = pattern.replace("{area}", subjectName).replace("{person}", fromName)
    } else {
      pattern = i18n.spaceplanner.unassignPrompt.unassignArea;
      prompt.text = replaceTarget(pattern,subjectName);
    }
  } else if (subject === "areas") {
    pattern = i18n.spaceplanner.unassignPrompt.unassignAreas
    prompt.text = replaceCount(pattern, count)
  } else if (subject === "units and areas") {
    pattern = i18n.spaceplanner.unassignPrompt.unassignUnitsAndAreas
    prompt.text = replaceCount(pattern, count)
  } else if (subject === "reviewer") {
    subjectName = subjectItem
    pattern = i18n.spaceplanner.unassignPrompt.unassignReviewer
    prompt.text = replaceName(pattern, subjectName)
  }
  return prompt;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

function getAreaName(areaItem) {
  return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_NAME);
}

function getPersonName(featureItem) {
  const attr = featureItem && featureItem.feature && featureItem.feature.attributes;
  return aiimUtil.getAttributeValue(attr,FieldNames.PEOPLE_FULLNAME);
}

function getUnitName(featureItem) {
  const unitField = Context.instance.aiim.getUnitName();  
  const attr = featureItem && featureItem.feature && featureItem.feature.attributes;
  return (aiimUtil.getAttributeValue(attr,unitField));
}

function replaceCount(pattern,count) {
  return pattern.replace("{count}",count);
}

function replaceName(pattern,name) {
  return pattern.replace("{name}",name);
}

function replaceSubjectTarget(pattern,subjectName,targetName) {
  return pattern.replaceAll("{subject}",subjectName).replaceAll("{target}",targetName);
}

function replaceTarget(pattern,targetName) {
  return pattern.replace("{target}",targetName);
}
