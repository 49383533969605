import React from "react";

import Context from "../../../context/Context";
import Warning from "./Warning";
import * as component from "../../../components/util/component";
import * as idleTimeOut from "../../../util/idleTimeOut";
import * as val from "../../../util/val";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-switch";
import {
  CalciteBlock,
  CalciteInputNumber,
  CalciteLabel,
  CalciteSwitch,
} from "@esri/calcite-components-react";

interface Props {
  onChange: ({isValid}) => void;
}

interface State {
  enabled: boolean,
  popupMillis: number,
  popupValue: string,
  popupWarning: string,
  timeoutMillis: number,
  timeoutValue: string,
  timeoutWarning: string
}

export default class IdleTime extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const { enabled, popupMillis, timeoutMillis} = Context.instance.config.applicationReset;
    this.state = component.newState({
      enabled: enabled,
      popupMillis: popupMillis,
      popupValue: ""+(popupMillis / 1000),
      popupWarning: null,
      timeoutMillis: timeoutMillis,
      timeoutValue: ""+(timeoutMillis / 1000),
      timeoutWarning: null
    });
  }

  apply = () => {
    const { enabled, popupMillis, popupWarning, timeoutMillis, timeoutWarning} = this.state;
    const config = Context.instance.config;
    if (!enabled) {
      config.applicationReset.enabled = false;
      config.applicationReset.popupMillis = 60000;
      config.applicationReset.timeoutMillis = 900000;
      idleTimeOut.stop();
    } else if (enabled && !popupWarning && !timeoutWarning) {
      config.applicationReset.enabled = true;
      config.applicationReset.popupMillis = popupMillis;
      config.applicationReset.timeoutMillis = timeoutMillis;
      idleTimeOut.start();
    } else {
      idleTimeOut.stop();
    }
    this.onChange()
  }

  onChange() {
    const { enabled, popupWarning, timeoutWarning} = this.state;
    const isValid = (!enabled || (!popupWarning && !timeoutWarning));
    this.props.onChange({isValid: isValid});
  }

  onChangePopupSeconds = (text) => {
    const i18n = Context.instance.i18n;
    let v = (text.trim().length > 0) ? Number(text.trim()) : null;
    if (val.isFiniteNum(v) && (v >= 1)) {
      v = Math.round(v * 1000);
      this.setState({
        popupMillis: v,
        popupValue: text,
        popupWarning: null
      }, this.apply);
    } else {
      this.setState({
        popupValue: text,
        popupWarning: i18n.miniapps.configurator.idleTimeout.invalidNumber
      }, this.apply);
    }
  }

  onChangeTimeoutSeconds = (text) => {
    const i18n = Context.instance.i18n;
    let v = (text.trim().length > 0) ? Number(text.trim()) : null;
    if (val.isFiniteNum(v) && (v >= 1)) {
      v = Math.round(v * 1000);
      this.setState({
        timeoutMillis: v,
        timeoutValue: text,
        timeoutWarning: null
      }, this.apply);
    } else {
      this.setState({
        timeoutValue: text,
        timeoutWarning: i18n.miniapps.configurator.idleTimeout.invalidNumber
      }, this.apply);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { enabled } = this.state;
    return (
      <CalciteBlock 
        open 
        heading={i18n.miniapps.configurator.idleTimeout.caption}
        description={i18n.miniapps.configurator.idleTimeout.description}
      >
        <div style={{display: "flex"}}>
          <CalciteLabel layout="inline" className="i-cursor-pointer" scale="s">
            {i18n.miniapps.configurator.idleTimeout.enable}
            <CalciteSwitch 
              scale="s"
              checked={enabled ? true : undefined}
              onCalciteSwitchChange={e => {
                const v = !!e.target.checked;           
                this.setState({enabled: v}, this.apply);
              }}
            />
          </CalciteLabel>
        </div>
        {enabled && this.renderContent()}
      </CalciteBlock>
    )
  }

  renderContent() {
    const i18n = Context.instance.i18n;
    const { popupValue, popupWarning, timeoutValue, timeoutWarning} = this.state;
    return (
      <div className="i-toggled-content2">
        <CalciteLabel>
          {i18n.miniapps.configurator.idleTimeout.idleTime}
          <CalciteInputNumber 
            scale="s"
            min={1}
            required={true}
            value={timeoutValue}
            status={!!timeoutWarning ? "invalid" : "valid"}
            onCalciteInputNumberInput={e => {
              const v = e.target.value;
              this.onChangeTimeoutSeconds(v);
            }}
          />
        </CalciteLabel>
        {timeoutWarning && <Warning message={timeoutWarning} />}  
        <CalciteLabel>
          {i18n.miniapps.configurator.idleTimeout.popupTime}
          <CalciteInputNumber 
            scale="s"
            min={1}
            required={true}
            value={popupValue}
            status={!!popupWarning ? "invalid" : "valid"}
            onCalciteInputNumberInput={e => {
              const v = e.target.value;
              this.onChangePopupSeconds(v);
            }}
          />
        </CalciteLabel>
        {popupWarning && <Warning message={popupWarning} />}   
      </div>
    )
  }

}
