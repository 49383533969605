import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-combobox";
import "@esri/calcite-components/dist/components/calcite-combobox-item";
import {
  CalciteAction,
  CalciteCombobox,
  CalciteComboboxItem
} from "@esri/calcite-components-react";

interface Props {
  label: string;
  onChange: (values: string[]) => void;
  uniqueValues: any;
  values: string[]
}

interface State {
}

class UniqueValues extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { label, uniqueValues, values } = this.props;
    const disabled = (!uniqueValues || uniqueValues.length === 0);
    const hasValues = (values && values.length > 0);
    const actionStyle = {visibility: "visible"};
    if (!hasValues) actionStyle.visibility = "hidden";

    const valueChanged = (e) => {
      const newValues = e.target.selectedItems.map(item => item.value);
      let changed = false;
      if (values.length !== newValues.length) {
        changed = true;
      } else {
        const idx = {};
        values.forEach(v => idx[v] = true);
        newValues.some(v => {
          if (!idx[v]) changed = true;
          return changed;
        });
      }
      if (changed) {
        this.props.onChange(newValues);
      }
    }

    let selectedValue = null;
    if (values && values.length > 0) selectedValue = values[0];

    const items = uniqueValues && uniqueValues.map(uv => {
      const v = uv.value, l = uv.label;
      const sel = !!(values && values.indexOf(v) !== -1);
      return (<CalciteComboboxItem key={v} textLabel={l} value={v} selected={sel} />);
    })

    return (
      <div className="i-margin-tb">
        <div className="i-flex-between i-align-items-center">
          <div>{label}</div>
          <CalciteAction icon="x-circle" scale="s" text="Clear" style={actionStyle}
            onClick={() => {
              //this.setState({values: []});
              this.props.onChange([]);
            }}
          />
        </div>
        <CalciteCombobox 
          label={label} 
          placeholder={i18n.miniapps.assignments.filter.select}
          selectionMode="single"
          value={selectedValue}
          disabled={disabled? true: undefined}
          onCalciteComboboxChange={valueChanged}
        >
          {items}
        </CalciteCombobox>
        {disabled &&
          <div className="i-filter-description">{i18n.filter.noSelectableValues}</div>
        }
      </div>
    )
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(UniqueValues);
