import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import Filter from "./Filter";
import OccupantsList from "./OccupantsList";
import OccupantsVM from "./OccupantsVM";

import { debounce } from "../../support/debounceUtil";
import { IOccupantsCriteria } from "../../common/types";
import * as component from "../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-input-text";
import {
  CalciteAction,
  CalciteButton,
  CalciteInputText
} from "@esri/calcite-components-react";

interface Props {
}

interface State {
  criteria: IOccupantsCriteria,
  filterActive: boolean
}

class OccupantsPanel extends React.Component<Props, State> {

  viewModel: OccupantsVM;

  constructor(props) {
    super(props);
    this.viewModel = new OccupantsVM();
    this.state = component.newState({
      criteria: this.viewModel.getCriteria(),
      filterActive: false
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { criteria } = this.state;
    return (
      <div className="i-sb-main-panel">
        <div style={{padding: "0.25rem"}}>
          {this.renderSearchRow(criteria)}
          {this.renderFilterRow(criteria)}
        </div>
        <div style={{overflow: "hidden", height: "100%"}}>
          <OccupantsList 
            criteria={criteria} 
            onCriteriaChange={c => this.setState({criteria: c})} 
          />
        </div>
      </div>
    )
  }

  renderFilterRow(criteria) {
    const i18n = Context.instance.i18n;
    const { filterActive } = this.state;
    const hasFilter = !!(criteria && criteria.filter && criteria.filter.where);
    const filterIcon = hasFilter ? "selected-items-filter" : "filter";
    return (
      <div className="i-flex-between">
        <div>
          <CalciteButton appearance="transparent" kind="neutral" iconStart={filterIcon}
            onClick={() => this.setState({filterActive: true})}
          >
            {i18n.miniapps.assignments.filter.label}
          </CalciteButton>
          {filterActive && 
            <Filter active={filterActive} criteria={criteria} viewModel={this.viewModel}
              onClose={() => this.setState({filterActive: false})}
              onCriteriaChange={c => this.setState({criteria: c})} 
            />
          }
        </div>
        {this.renderSort(criteria)}
      </div>
    )
  }

  renderSearchRow(criteria) {
    const i18n = Context.instance.i18n;

    const addPlaceholderClicked = (e) => {
      // @todo
    }

    const onTextChange = (e) => {
      const v = (e && e.target && e.target.value) || "";
      const c = JSON.parse(JSON.stringify(criteria));
      c.searchText = v;
      c.start = 0;
      this.setState({criteria: c});
    }

    return (
      <div className="i-flex-between">
        <div>
          <CalciteInputText 
            className="i--clear"
            icon={"search"} 
            clearable={true}
            value={criteria.searchText || ""} 
            placeholder={i18n.miniapps.assignments.occupants.searchByName} 
            onCalciteInputTextInput={debounce((e) => onTextChange(e), 200)}
          />
        </div>
        {false && 
          <div>
            <CalciteAction 
              appearance="transparent"
              icon="plus-circle" 
              scale="s"
              text={i18n.miniapps.assignments.occupants.addPlaceholder}
              title={i18n.miniapps.assignments.occupants.addPlaceholder}
              onClick={addPlaceholderClicked}
            />
          </div>
        }
      </div>
    )
  }

  renderSort(criteria) {
    const i18n = Context.instance.i18n;
    let icon = "sort-ascending";
    let tip = i18n.sort.descSortTitle;
    if (criteria.sortDir === "desc") {
      icon = "sort-descending"
      tip = i18n.sort.ascSortTitle;
    }
    return (
      <CalciteButton appearance="transparent" kind="neutral"
        iconStart={icon} title={tip} 
        onClick={e => {
          const v = criteria.sortDir === "desc" ? "asc" : "desc";
          const c = JSON.parse(JSON.stringify(criteria));
          c.sortDir = v;
          c.start = 0;
          this.setState({criteria: c});
        }}
      />      
    )
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(OccupantsPanel);
