import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import QueryAll from "./QueryAll";
import Topic from "../../context/Topic";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import * as sourceUtil from "./sourceUtil";

export default class UnitNameCache extends BaseClass {

  namesPerUnitId;
  defaultNamesPerUnitId;
  uidsPerObjectId;
  plan;
  _timestamp;

  constructor(props) {
    super(props);

    this.own([

      Topic.subscribe(Topic.PlanModified, params => {
        if (params && params.wasReconciled) {
          this.load();
        } else if (params && params.attributeEditorLayerType === "unit") {
          this.load();
        }
      }),
      Topic.subscribe(Topic.PlanOpened, params => {
        if (params && params.plan === this.plan) {
          this.load();
        }
      }),
      Topic.subscribe(Topic.RefreshUnitName, params => {
        this.load();
      })

    ])
  }

  async load(forViewer) {
    //console.log("UnitNameCache.load........",Date.now());
    let layer;
    if (forViewer) {
      const datasets = Context.instance.aiim.datasets;
      layer = datasets && datasets.units && datasets.units.layer2D;
    } else {
      layer = sourceUtil.getUnitsLayer();
    }
    if (!layer) return;

    const unitIdField = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
    const defaultUnitNameField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
    const nameField = aiimUtil.findFieldName(layer.fields, Context.instance.aiim.getUnitNameFieldName());
    const oidField = layer.objectIdField;
    const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"]
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = "1=1";
    queryUtil.applyGdbVersion(layer, query);

    const idx = {}, uidsPerObjectId = {}, defaultNamesPerUnitId = {};
    const qaopts = {
      layer: layer,
      perFeatureCallback: f => {
        const unitId = f.attributes[unitIdField];
        const name = f.attributes[nameField];
        const defaultUnitName = f.attributes[defaultUnitNameField];
        idx[unitId] = name;
        defaultNamesPerUnitId[unitId] = defaultUnitName;
        const oid = f.attributes[oidField];
        uidsPerObjectId[oid] = unitId;
      }
    }

    let stamp = this._timestamp = Date.now();
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts).then(result => {
      if (stamp === this._timestamp) {
        this.namesPerUnitId = idx;
        this.defaultNamesPerUnitId = defaultNamesPerUnitId;
        this.uidsPerObjectId = uidsPerObjectId;
        Topic.publish(Topic.UnitNameCacheUpdated,{});
      }
    });
  }

}
