import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { HitTest } from "../redux";
import { getAttributeValue } from "../../../../aiim/util/aiimUtil";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "../support/editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as val from "../../../../util/val";
import { LayerType } from "../../../../util/interfaces";

export interface IFeatureItem {
  layerId: number,
  layerType: LayerType,
  title: string,
  description: string,
  key: string,
  oid: number,
  hitIndex: number,
  selected: boolean,
  graphic: __esri.Graphic,
  levelInfo?: {
    levelId: string,
    z: number
  }
};

export function appendCopyToName(name: string): string {
  return name +" - Copy";
}

export function clearSelected(featureItems: IFeatureItem[]): void {
  featureItems && featureItems.forEach(featureItem => {
    featureItem.selected = false;
  })
}

export function cloneFeatureItems(featureItems: IFeatureItem[]): IFeatureItem[] {
  const items: IFeatureItem[] = featureItems && featureItems.map(featureItem => {
    const item = Object.assign({},featureItem);
    item.graphic = featureItem.graphic.clone();
    return item;
  })
  return items;
}

export function copyFeatureItems(featureItems: IFeatureItem[]): IFeatureItem[] {
  const items: IFeatureItem[] = featureItems && featureItems.map(featureItem => {
    const item = Object.assign({},featureItem);
    item.graphic = featureItem.graphic.clone();
    initCopy(item);
    return item;
  })
  return items;
}

export function countMissingLevelInfo(featureItems: IFeatureItem[]): number {
  let num = 0;
  featureItems.forEach(featureItem => {
    if (!featureItem.levelInfo) num++;
  })
  return num;
}

export function getCenter(featureItems: IFeatureItem[]): __esri.Point {
  const extent = getExtent(featureItems);
  return extent && extent.center;
}

export function getConvexHull(featureItems: IFeatureItem[]): __esri.Geometry {
  const ge = Context.instance.lib.esri.geometryEngine;
  const geometries = featureItems.map(featureItem => featureItem.graphic.geometry);
  return ge.convexHull(geometries,true);
}

export function getExtent(featureItems: IFeatureItem[]): __esri.Extent {
  // @todo currently ignoring point features
  let extent;
  featureItems.forEach(featureItem => {
    const geometry = featureItem.graphic.geometry;
    const extent2 = geometry.extent; // @todo currently ignoring point features
    if (!extent) {
      if (extent2) extent = extent2.clone();
    } else {
      if (extent2) extent.union(extent2);
    }
  })
  return extent;
}

export function hasMultipleLevels(featureItems: IFeatureItem[]): boolean {
  const levelIds = [];
  return featureItems.some(featureItem => {
    const attributes = featureItem.graphic.attributes;
    const levelId = aiimUtil.getAttributeValue(attributes,FieldNames.LEVEL_ID);
    if (!levelIds.includes(levelId)) {
      if (levelIds.length === 0) {
        levelIds.push(levelId);
      } else {
        return true;
      }
    }
    return false;
  })
}

export function hasUnitsOrDetails(featureItems: IFeatureItem[]): boolean {
  return featureItems.some(featureItem => {
    const layerType = featureItem.layerType;
    if (layerType === "unit" || layerType === "detail") return true;
    return false;
  })
}

export function hitsToFeatureItems(hits: HitTest[], selectedFeatureIndex?: number): IFeatureItem[] {
  const featureItems = hits && hits.map((h, hitIndex) => {
    const layerType = h.key;
    const graphic: __esri.Graphic = Context.instance.lib.esri.Graphic.fromJSON(h.feature);
    const layer: __esri.FeatureLayer = mapUtil.getLayer(layerType);
    graphic.layer = layer;
    const layerId = layer.layerId;
    const oid = getAttributeValue(graphic.attributes, layer.objectIdField);
    const key = layerType+"_"+oid;
    const attributes = graphic.attributes;
    let title, description, selected = false;
    if (layerType === "unit") {
      title = getAttributeValue(attributes,FieldNames.UNITS_USE_TYPE); // @todo
      description = getAttributeValue(attributes,FieldNames.NAME); // @todo
    } else if (layerType === "detail") {
      title = getAttributeValue(attributes,FieldNames.DETAILS_USE_TYPE); // @todo
      description = getAttributeValue(attributes,FieldNames.DETAIL_ID); // @todo
    }
    if (typeof selectedFeatureIndex === "number" && selectedFeatureIndex === hitIndex) selected = true;
    const featureItem: IFeatureItem = {
      layerId,
      layerType,
      title,
      description,
      oid,
      key,
      hitIndex,
      selected,
      graphic
    }
    return featureItem;
  });
  return featureItems;
}

function initCopy(featureItem: IFeatureItem): void {
  const layerType = featureItem.layerType;
  const graphic: __esri.Graphic = featureItem.graphic;
  const layer: __esri.FeatureLayer = graphic.layer as __esri.FeatureLayer;
  const attributes = graphic.attributes;
  if (layerType === "unit") {
    const unitIdField = aiimUtil.findFieldName(layer.fields,FieldNames.UNIT_ID);
    const areaIdField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_AREA_ID);
    const asnField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const nameField = aiimUtil.findFieldName(layer.fields,FieldNames.NAME);
    const nameLongField = aiimUtil.findFieldName(layer.fields,FieldNames.NAME_LONG);
    const scheduleEmailField = aiimUtil.findFieldName(layer.fields,FieldNames.SCHEDULE_EMAIL);
    const name = nameField && attributes[nameField];
    const newName = appendCopyToName(name);
    const newLongName = newName;
    const unitId = val.generateRandomId();
    //const asnType = asnField && attributes[asnField];
    delete attributes[layer.objectIdField];
    // @ts-ignore
    delete attributes[layer.globalIdField];
    attributes[unitIdField] = unitId;
    attributes[nameField] = newName;
    if (nameLongField) attributes[nameLongField] = newLongName;
    attributes[areaIdField] = null;
    attributes[asnField] = "none";  
    if (scheduleEmailField) attributes[scheduleEmailField] = null;
    featureItem.description = newName;
  } else if (layerType === "detail") {
    const detailIdField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAIL_ID);
    const detailId = val.generateRandomId();
    delete attributes[layer.objectIdField];
    // @ts-ignore
    delete attributes[layer.globalIdField];
    attributes[detailIdField] = detailId;
    featureItem.description = detailId;
  }
}

export function makeItemsByKey(featureItems: IFeatureItem[]) {
  const itemsByKey = {};
  featureItems && featureItems.forEach(featureItem => {
    itemsByKey[featureItem.key] = featureItem;
  })
  return itemsByKey;
}

export async function resetLevels(featureItems: IFeatureItem[]) {
  const ge = Context.instance.lib.esri.geometryEngine;
  const levelsDataset = Context.instance.aiim.datasets.levels;
  const extent = getExtent(featureItems);
  const result = await levelsDataset.queryByGeometry(extent,extent.spatialReference);
  const features = (result && result.features) || [];
  featureItems.forEach(featureItem => {
    const layerType = featureItem.layerType;
    // TODO: Implement for remaining types
    if (layerType === "unit" || layerType === "detail") {
      let containedBy = null;
      let intersectedBy = null;
      const geometry = featureItem.graphic.geometry;
      const layer: any = featureItem.graphic.layer;
      const levelIdField = aiimUtil.findFieldName(layer.fields,FieldNames.LEVEL_ID)
      const heightRelField = aiimUtil.findFieldName(layer.fields, FieldNames.HEIGHT_RELATIVE)
      features.some(f => {
        const levelId: string = aiimUtil.getAttributeValue(f.attributes,FieldNames.LEVEL_ID);
        if (levelId) {
          // TODO: Implement for remaining types
          if (layerType === "unit") {
            if (ge.contains(f.geometry,geometry)) {
              containedBy = f;
              return true;
            }
          } else if (layerType === "detail") {
            if (ge.intersects(f.geometry,geometry)) {
              intersectedBy = f;
              return true;
            }
          }
          return false;
        }
      })
      const levelFeature = containedBy || intersectedBy;
      if (levelFeature) {
        const levelId: string = aiimUtil.getAttributeValue(levelFeature.attributes, FieldNames.LEVEL_ID);
        const relHeight: number = aiimUtil.getAttributeValue(levelFeature.attributes, FieldNames.HEIGHT_RELATIVE);
        const z: number = levelFeature.geometry.extent.center.z;
        featureItem.levelInfo = {levelId,z}
        if (levelIdField) featureItem.graphic.attributes[levelIdField] = levelId;
        if (heightRelField) featureItem.graphic.attributes[heightRelField] = relHeight;
        editorUtil.updateZ(geometry,z);
      } else {
        featureItem.levelInfo = null;
      }
    }
  })
}
