import React from "react";

// Components
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Items from "../common/Items";
import QueryCriteria from "../../base/QueryCriteria";
import Search from "../common/Search";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as sourceUtil from "../../base/sourceUtil";

// Styled Components
import { Panel } from "../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAttribute
} from "../../styles/Common/list";

export default class PeopleList extends React.Component {

  componentId;

  constructor(props) {
    super(props);
    this.componentId = component.nextId("qc");

    const source = sourceUtil.getPeopleSource();
    const fields = source.layer2D.fields;
    let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    const nameField = aiimUtil.findField(fields,nameFieldName);
    if (nameField) nameFieldName = nameField.name;

    const queryCriteriaKey = this.getQueryCriteriaKey();
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      source.key
    );
    queryCriteria.num = 30;
    //queryCriteria.requiresGeometry = !!this.props.returnGeometry;
    queryCriteria.requiresGeometry = true;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = nameFieldName;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc"
      };
    }

    this.state = component.newState({
      selectedItem: null
    });
  }

  componentDidMount() {}

  componentWillUnmount() {
    const session = Context.instance.session;
    const queryCriteriaKey = this.getQueryCriteriaKey();
    if (session.queryCriteriaByKey) {
      delete session.queryCriteriaByKey[queryCriteriaKey];
    }
    component.componentWillUnmount(this);
  }

  getQueryCriteriaKey() {
    return this.props.queryCriteriaKey || this.componentId;
  }

  handleSelection = featureItem => {
    if (this.props.handleSelection) {
      this.props.handleSelection(featureItem);
    }
    this.setState({
      selectedItem: featureItem
    });
  };

  render() {
    
    const i18n = Context.instance.i18n;
    const queryCriteriaKey = this.getQueryCriteriaKey();
    const rdxFeatureItemsKey = null;
    const placeholder = i18n.spaceplanner.people.listHeaderPlaceholder;

    return (
      <Panel bordered>
        <Search
          fullWidth
          minimal
          queryCriteriaKey={queryCriteriaKey}
          placeholder={placeholder}
          searchTextChanged={this.searchTextChanged}
        />
        <Items
          queryCriteriaKey={queryCriteriaKey}
          rdxFeatureItemsKey={rdxFeatureItemsKey}
          renderItem={this.renderItem}
          isShowAllButton={false}
        />
      </Panel>
    );
  }

  renderItem = (source, featureItem, index) => {
    const key = featureItem.key;
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME);
    const email = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL);
    const assignmentName = officePlanUtil.getPersonAssignmentName(featureItem);
    const isPlaceholder = email && email.startsWith('placeholder');
    let selected = false;
    if (this.state.selectedItem) {
      selected = (key === this.state.selectedItem.key);
    }
    const isUnassigned = officePlanUtil.isPersonUnassigned(featureItem);

    return (
      <ListItem
        unassigned={isUnassigned}
        key={key}
        selected={selected}
        onClick={() => this.handleSelection(featureItem)}
        isPlaceholder={isPlaceholder}
      >
        <ListItemTitle>{name}</ListItemTitle>
        <ListItemSubtitle>
          <ListItemAttribute>{assignmentName}</ListItemAttribute>
        </ListItemSubtitle>
      </ListItem>
    );
  };

  searchTextChanged = (text) => {
    this.handleSelection(null);
  }

}
