import styled from "styled-components";
import { CalciteH3 } from "calcite-react/Elements";

const HeaderContainer = styled.div`
  position: relative;
  padding: 1rem;
  box-shadow: ${props => props.theme.boxShadow};
  background: ${props => props.theme.palette.offWhite};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(CalciteH3)`
  text-transform: uppercase;
  margin-bottom: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
`;

const FooterContainer = styled.div`
  padding: ${props => props.theme.panelMargin};
  border-top: 1px solid ${props => props.theme.palette.lightestGray};
`;

export { HeaderContainer, TitleRow, Title, FilterRow, FooterContainer };
