import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import DimensionAnnotation from "../annotation/DimensionAnnotation";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as transactions from "../../../base/transaction/transactions";
import { UpdateDetailsTask } from "../../../base/transaction/details";
import { IFacilityData, ILevelData } from "../../../../aiim/datasets/Levels";
import { ModalController } from "../../Modal";
import { Topic } from "../../../../util/tsUtil";
import { LayerType } from "../../../../util/interfaces";
import { IApplyEditsResults } from "../../../base/transaction/transaction";
import { WarningMsg, CutType, updateGrossArea, updateZ, cutThisOrUnderlyingUnits } from "../support/editorUtil";
import { getFieldObjects, hasSites, getFormTemplate, isFootprint, getLevelData, getSite } from "../support/formUtil";
import { UpdateFootprintTask } from "../../../base/transaction/footprints";
import { FootprintInfo } from "../footprints/FootprintVM";

const svmUpdateOptions:__esri.SketchViewModelUpdateUpdateOptions = {
  tool: "transform",
  enableRotation: true,
  enableScaling: true,
  preserveAspectRatio: false,
  toggleToolOnClick: true,
  enableZ: true,
};
const cn = "EditorVM";
//@ts-ignore
const isDebug = () => typeof window._debug !== 'undefined' && window._debug === true;

interface IActiveFeature extends Partial<__esri.Graphic> {
  isModifying: boolean,
  modifiedUnits: __esri.Graphic[],
  originalUnits: __esri.Graphic[],  
  warnings: WarningMsg[]
}
export interface IEditorVMProps {
  onDrawComplete?: (tool: "create" | "update" | "delete" | "undo" | "redo") => void,
  onDrawUpdate?: (tool: string) => void,
  onSiteChange?: (siteInfo: FootprintInfo) => void,
  onValidityChange?: (isValid: boolean) => void
}
export default class EditorVM extends BaseVM implements IEditorVMProps {

  activeFeature: IActiveFeature = {
    isModifying: false,
    modifiedUnits: [],
    originalUnits: [],
    geometry: null,
    attributes: null,
    warnings: [],
  };
  cutType: CutType;
  layerType: LayerType = "unit";
  isDuplicate: boolean = false;

  dimensionAnnotation: DimensionAnnotation = new DimensionAnnotation({ layerType: "unit" });

  levelData: ILevelData;
  levelGeom: __esri.Polygon;
  facilityData: IFacilityData;
  facility: FootprintInfo;
  site: FootprintInfo;
  
  highlightOutline: __esri.SimpleLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol({
    color: [224,294,0, 0.5], 
    width: 3,
    style: "dash"
  });
  highlightSym: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [0,0,0,0],
    style: "solid",
    outline: this.highlightOutline,
  });
  activeEditLineSym: __esri.SimpleLineSymbol = new Context.instance.lib.esri.SimpleLineSymbol({
    color: [0,255,250, 0.5], 
    width: 3,
    style: "dash"
  });
  reshapedUnitSym: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [0,255,0,1],
    style: "diagonal-cross",
    outline: {
      color: [0,111,0,0.75],
      width: 3,
      style: "short-dash"
    },
  });
  invalidSym: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [255,0,0,1],
    style: "diagonal-cross",
    outline: {
      color: [75,0,0,0.5],
      width: 3,
      style: "dash"
    },
  });
  removedUnitSym: __esri.SimpleFillSymbol = new Context.instance.lib.esri.SimpleFillSymbol({
    color: [230,163,0,.85],
    style: "diagonal-cross",
    outline: {
      color: [230,122,0,0.5],
      width: 3,
      style: "dash"
    },
  });
  processing: boolean = false;

  _activeSketchViewModel: __esri.SketchViewModel;
  
  private _isMapClickEnabled: boolean = false;
  //to be used by SVM only
  private _svmLyrId: string = "indoors-editor-panel-svm";
  //to show overlapping units that have been modified and their labels or reshaped active unit etc.
  private _resultLayerId: string = "indoors-editor-panel-cuts";
  //to show info/drawings for active feature like labels, boundaries etc 
  private _unitLayerId: string = "indoors-editor-panel-units";
  private _originalGeom: __esri.Geometry;
  private _origAttributes: Record<string, any> = null;
  private isValidGeometry = true;
  z: number = null;

  private fldFacilityId: __esri.Field;
  private fldLevelId: __esri.Field;
  private fldUnitName: __esri.Field;
  private fldUnitId: __esri.Field;
  private fldUnitUseType: __esri.Field;
  private fldDetailId: __esri.Field;
  private fldDetailUseType: __esri.Field;
  private fldSiteId: __esri.Field;
  private fldsToLog: string[];
  
  onDrawComplete: (tool: string) => void;
  onDrawUpdate: (updateType: string) => void;
  onSiteChange: (siteInfo: FootprintInfo) => void;
  onValidityChange: (isValid: boolean) => void;
  
  constructor(props: IEditorVMProps) {
    super(props);
    this.mixinProps(props);
  }

  private async _init(feature:__esri.Graphic, lyrType:LayerType, cutType:CutType):Promise<boolean> {
    this.cancelAndClearSketch();
    this.dimensionAnnotation.initialize(lyrType);

    this.layerType = lyrType;
    this.cutType = cutType;
    this.z = null;
    this.levelData = null;
    this.levelGeom = null;
    this.facilityData = null;
    this.facility = null;
    this.isValidGeometry = true;

    const ctx = Context.getInstance();
    const lyr = mapUtil.getLayer(this.layerType);
    if (!this._activeSketchViewModel) {
      isDebug() && console.debug(cn, "._init() creating svm");
      
      const graphicsLayer = this.ensureLayersAndFields();
      const svm = this._activeSketchViewModel = new ctx.lib.esri.SketchViewModel({
        view: this.getView(),
        layer: graphicsLayer,
        defaultCreateOptions: { defaultZ: 0, mode: "click" },
        defaultUpdateOptions: svmUpdateOptions,
        snappingOptions: this.makeSnappingOptions()
      });
      this.own([
        svm.on("create", this._onSvmCreate), 
        svm.on("update", this._onSvmUpdate), 
        svm.on("undo",   this._onSvmUndo), 
        svm.on("redo",   this._onSvmRedo),
        this.getView().on("click", this._onActiveCutUnitClick),
      ]);
    }
    if (feature.geometry.hasZ) {
      const ringsOrPaths = "rings" in feature.geometry
        ? (feature.geometry as __esri.Polygon).rings
        : (feature.geometry as __esri.Polyline).paths;
      const firstCoord: number[] = ringsOrPaths[0][0];
      this.z = firstCoord && firstCoord.length === 3 ? firstCoord[2] : null;
    }
    const { views: { floorFilter: { activeWidget } } } = ctx;

    if (["unit", "detail"].includes(this.layerType)) {
      const fldLevelId = ctx.aiim.getLevelIdField(lyr);
      const levelId = fldLevelId && feature.attributes[fldLevelId.name];
      if (!levelId) {
        console.warn("Feature to be edited is missing the LEVEL ID field", fldLevelId.name, feature.attributes);
        return false;
      }
      this.levelData = ctx.aiim.datasets.levels.getLevelData(levelId);
      this.levelGeom = this.levelData?.feature?.geometry as __esri.Polygon;
      if (!this.levelGeom) {
        console.warn("Unable to find Level with ID:", levelId);
        return false;
      }
      if (this.levelData) {
        if (this.z == null) {
          this.z = this.levelData.z;
          console.warn("Unable to get z-value, edits to geometry may not work", feature);
        }
      } else {
        console.warn("Invalid Level ID:", levelId);
        return false;
      }
    
      const facId = this.levelData.facilityId;
      // @ts-ignore
      this.facility = activeWidget.viewModel.getFacility(facId);
      this.facilityData = ctx.aiim.datasets.levels.getFacilityData(facId);
      if (this.facility == null) {
        console.warn("Unable to find Facility with ID:", facId);
        return false;
      }
    } else if (this.layerType === "level") {
      const fldFacId = ctx.aiim.datasets.levels.facilityIdField;
      const facId = fldFacId && feature.attributes[fldFacId];
      if (!facId) {
        console.warn("Feature to be edited is missing the FACILITY ID field", fldFacId, feature.attributes);
        return false;
      }
      // @ts-ignore
      this.facility = activeWidget.viewModel.getFacility(facId);
      this.facilityData = ctx.aiim.datasets.levels.getFacilityData(facId);
      if (this.facility == null) {
        console.warn("Unable to find Facility with ID:", facId);
        return false;
      }
      if (this.z == null) {
        this.z = lyr.sourceJSON.zDefault || 0;
        console.warn("Unable to get z-value, edits to geometry may not work", feature);
      }
    } else if (this.layerType === "facility") {
      const fldSiteId = ctx.aiim.datasets.levels.siteIdField;
      const siteId = fldSiteId && aiimUtil.getAttributeValue(feature.attributes, fldSiteId);
      if (!siteId) {
        console.warn("Feature to be edited is missing the SITE ID field", this.fldsToLog, feature.attributes);
        return false;
      }
      this.site = getSite(feature.geometry);
    }
    return true;
  }

  ensureLayersAndFields() {
    const view = this.getView();
    const layer = mapUtil.getLayer(this.layerType);
    if (!layer) {
      return;
    }
    const required: __esri.Field[] = [], invalids: __esri.Field[] = [];
    
    if (isFootprint(this.layerType)) {
      required.push(...this.getRequiredFields());
      invalids.push(...required.filter(x => !x));
      this.fldsToLog = required.map(f => f?.name).concat([layer.objectIdField]);
    } else if (this.layerType === "unit") {
      this.fldUnitId = aiimUtil.findField(layer.fields, FieldNames.UNIT_ID);
      this.fldUnitName = aiimUtil.findField(layer.fields, FieldNames.NAME);
      this.fldUnitUseType = aiimUtil.findField(layer.fields, FieldNames.UNITS_USE_TYPE);
      required.push(this.fldUnitId, this.fldUnitName, this.fldUnitUseType, this.fldDetailId, this.fldDetailUseType);
      invalids.push(...required.filter(x => !x));
      this.fldsToLog = [this.fldUnitName.name, this.fldUnitId.name, layer.objectIdField];
    } else if (this.layerType === "detail") {
      this.fldDetailId = aiimUtil.findField(layer.fields, FieldNames.DETAIL_ID);
      this.fldDetailUseType = aiimUtil.findField(layer.fields, FieldNames.DETAILS_USE_TYPE);
      this.fldsToLog = [];
    }
    
    if (invalids && invalids.length > 0) {
      console.warn("Unable to find required field/s:", required.map(f => f?.name));
    }

    //order is important
    mapUtil.ensureGraphicsLayer(view, this._unitLayerId);
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view, this._svmLyrId);
    mapUtil.ensureGraphicsLayer(view, this._resultLayerId);
    return graphicsLayer;
  }

  updateSketch(newAttrs: Record<string, any>, fieldName: string) {
    // changes for: Edit unit: changing USE TYPE should not change fill to dark grey #7356
    if (this.activeFeature.geometry) {
      if (this.layerType === "detail" || this.layerType === "unit") {
        const fldUseType = this.layerType === "detail" ? this.fldDetailUseType : this.fldUnitUseType;
        if (fldUseType && (fldUseType.name === fieldName)) {
          const svmGra = this.getMatchingGraphic(this._svmLyrId);
          if (svmGra) {
            const attributes = { ...svmGra.attributes, ...newAttrs };
            const sym = this.getSymbol(attributes);
            if (sym) {
              svmGra.symbol = sym;
              svmGra.attributes = attributes;
            }
          }
        }
      }
    }
  }

  flip(type:"v" | "h") {
    if (!"v,h".includes(type))
      return;
    const ge = Context.instance.lib.esri.geometryEngine;
    const geom = ge[type==="h" ? "flipHorizontal" : "flipVertical"](this.activeFeature.geometry);
    this.updateActiveFeatureGeom(geom);
  }

  rotate(angle:number) {
    if(isNaN(angle))
      return;
    const ge = Context.instance.lib.esri.geometryEngine;
    const geom = ge.rotate(this.activeFeature.geometry, -angle);
    this.updateActiveFeatureGeom(geom);
  }

  updateActiveFeatureGeom(geom:__esri.Geometry) {
    if (!geom || !this.activeFeature)
      return;
    
    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    if (geom) {
      this.activeFeature.geometry = geom;
      svmGra && (svmGra.geometry = this.activeFeature.geometry.clone());
      //@ts-ignore
      const opHandle = this._activeSketchViewModel._operationHandle;
      opHandle && opHandle.refreshComponent();
      this.updateDimensionAnnotation();
    }

    if (geom.type !== "polygon") {
      return;
    }
    
    if (!this._activeSketchViewModel.activeTool && geom.type==="polygon") {
      this.addHighlightBorderToMap(geom as __esri.Polygon);
    }

    this._processDuringDrawUpdate({
      graphics: [this.getActiveFeature()], 
      tool: "transform", 
      type: "update",
      state: "complete",
      toolEventInfo: null,
      aborted: false,
    }).then(() => {
      svmGra && (svmGra.symbol = this.activeFeature.symbol);
    });
  }

  updateDimensionAnnotation() {
    if (this.activeFeature && this.activeFeature.geometry) {
      this.dimensionAnnotation.updateDimensions([
        new Context.instance.lib.esri.Graphic({
          geometry: this.activeFeature.geometry.clone()
        })
      ])
    }
  }

  async activateUpdateFeature(feature: __esri.Graphic, lyrType: LayerType, cutType: CutType) {
    isDebug() && console.debug(cn, ".activateUpdateFeature()");
    const ctx = Context.getInstance();
    if (!feature || !feature.geometry || !feature.attributes) {
      console.warn("Unable to edit shape, invalid feature");
      ctx.views.toggleClickHandlers("resume");
      ctx.views.mapClickDisabled = false;
      return;
    }

    //TODO get the detailed feature geometry with z-value from service
    
    const isValid = await this._init(feature, lyrType, cutType);
    if (!isValid) {
      ModalController.showMessage("Unable to edit geometry", "Error");
      return;
    }

    const lyr = mapUtil.getLayer(this.layerType);
    const oidFld = lyr.objectIdField;
    const svmGL:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), this._svmLyrId);
    const isInGL = svmGL.graphics.some(f=> f.attributes[oidFld] === feature.attributes[oidFld]);
    this._originalGeom = feature.geometry.clone();
    this._origAttributes = { ...feature.attributes };
    this.activeFeature = {
      isModifying: true,
      geometry: feature.geometry,
      attributes: feature.attributes,
      modifiedUnits: null,
      originalUnits: null,
      warnings: [],
    }
    const graphic = this.getActiveFeature();
    !isInGL && svmGL.add(graphic);
    ctx.views.toggleClickHandlers("pause");
    ctx.views.mapClickDisabled = true;

    this._activeSketchViewModel.update([graphic], svmUpdateOptions);
  }

  onUpdateStateChanged(updateState,toolEventType?,graphic?) {}

  private _onSvmCreate = (event:__esri.SketchCreateEvent) => {
    if (!event.toolEventInfo || event.toolEventInfo.type!=="cursor-update")
      console.debug("Sketch.create", "|", event.toolEventInfo && event.toolEventInfo.type, "|", event.state, "|",  event);
  }

  private _onSvmUpdate = async (event:__esri.SketchUpdateEvent) => {
    this._isMapClickEnabled = false;
    this.activeFeature.isModifying = true;
    const ctx = Context.getInstance();
    this.clearGraphics(this._unitLayerId);
    
    const graphic = event.graphics?.[0];
    const geometry = graphic?.geometry;
    const tei = event.toolEventInfo; 
    // MoveEventInfo | ReshapeEventInfo | RotateEventInfo | ScaleEventInfo | SelectionChangeEventInfo | VertexAddEventInfo | VertexRemoveEventInfo
    const teiType = tei && tei.type;
    const handledTypes = ["move-stop", "reshape-stop", "rotate-stop", "scale-stop", "vertex-remove", "vertex-add"];
    const activeTypes = ["move", "reshape"];

    if (handledTypes.includes(teiType)) {
      await this._processDuringDrawUpdate(event);
    } else if (activeTypes.includes(teiType) && ["site", "facility"].includes(this.layerType)) {
      if (this.layerType === "facility") {
        this.site = getSite(geometry);
        if (this.site) {
          this.activeFeature.attributes[this.fldSiteId.name] = this.site.id;
          this.onSiteChange && this.onSiteChange(this.site);
        }
      }
      await this.validateGeometry(graphic, false);
    }

    if (event.state === "complete") {
      if (geometry) {
        this.activeFeature.geometry = geometry;
      }

      if (geometry.type === "polygon") {
        this.addHighlightBorderToMap(geometry as __esri.Polygon);
      }

      this.activeFeature.isModifying = false;
      this.onDrawComplete && this.onDrawComplete("update");
      this._isMapClickEnabled = this.cutType===CutType.cutThis;
    }
    this.dimensionAnnotation.handleUpdateEvent(event);
    this.onUpdateStateChanged(event.state,event.toolEventInfo && event.toolEventInfo.type,event.graphics && event.graphics[0]);
  }

  private _onSvmUndo = async (event:__esri.SketchViewModelUndoEvent) => {
    this._processDuringDrawUpdate(event);
    this.dimensionAnnotation.handleUndoEvent(event);
  }

  private _onSvmRedo = async (event:__esri.SketchViewModelRedoEvent) => {
    this._processDuringDrawUpdate(event);
    this.dimensionAnnotation.handleRedoEvent(event);
  }

  private _processDuringDrawUpdate = async (event:__esri.SketchUpdateEvent 
      | __esri.SketchViewModelUndoEvent 
      | __esri.SketchViewModelRedoEvent) => {
    const ctx = Context.getInstance();
    const gfx = event.graphics && event.graphics[0];
    const gfxGeom = gfx && gfx.geometry;
    if (gfxGeom)
      this.activeFeature.geometry = gfxGeom;
    const activeFeature = this.getActiveFeature();
    await this.validateGeometry(activeFeature);
    gfx.symbol = this.activeFeature.symbol = activeFeature.symbol;

    //point | multipoint | polyline | polygon | rectangle | circle | move | transform | reshape
    const opType = event.tool; //event.toolEventInfo;  //reshape-stop, move-stop
    if (this.layerType === "detail") {
      this.onDrawUpdate && this.onDrawUpdate(opType);
      return;
    }

    if (["unit", "level"].includes(this.layerType)
      && (this.activeFeature.geometry as __esri.Polygon).rings[0]?.length > 3) {
      await updateGrossArea(activeFeature);
    }
    if (this.isValidGeometry) {
      this.activeFeature.warnings = this.activeFeature.warnings.filter(w => w.type !=="error");
      // TODO: Determine if cutting is supported for sfl
      if (this.layerType === "unit") {
        if (this.cutType===CutType.cutUnderlying) {
          await this.cutUnderlyingUnits();
        } else if (this.cutType===CutType.cutThis) {
          await this.cutNewUnit();
        }
      }          
    }

    this.onDrawUpdate && this.onDrawUpdate(opType);
    this.onValidityChange && this.onValidityChange(this.isValid());
  }

  _onActiveCutUnitClick = async (e:__esri.ViewClickEvent) => {
    if (!this._isMapClickEnabled || this.layerType !== "unit" || this.isSketchActive())
      return;
    isDebug() && console.debug(cn, "map clicked", e);
    const hitTestResult = await (this.getView() as __esri.MapView).hitTest(e);
    let vwHits = hitTestResult.results;
    vwHits = Context.instance.aiim.fixHitTestResult(vwHits); 

    const oidFld = mapUtil.getLayer(this.layerType).objectIdField;
    const activeAttr = this.activeFeature.attributes;
    if (!activeAttr)
      return;

    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    if (!svmGra)
      return;

    const gfx = vwHits && vwHits.find(vwHit => {
      if (vwHit.type==="graphic" 
        && vwHit.graphic.layer?.id===this._resultLayerId
        && this.cutType === CutType.cutThis
      ) {
        return vwHit.graphic.attributes[oidFld] === svmGra.attributes[oidFld];
      }
      return false;
    });
    if (gfx) {
      isDebug() && console.debug('matched gra from resultsLayer to svmLayer, and ready to update');
      this._activeSketchViewModel.update([svmGra], svmUpdateOptions);
      this._isMapClickEnabled = false;
    }
  }

  cancelSketch() {
    const view = this.getView();
    const svm = this._activeSketchViewModel;
    svm && svm.cancel();
    if (view) {
      // `default`, `crosshair`, `help`, `move`, `pointer`, `progress`, `grab`, `grabbing`
      view.container.style.cursor = "default";
    } 
  }

  cancelAndClearSketch() {
    this.cancelSketch();
    this.dimensionAnnotation.clear();
    const view = this.getView();    
    [this._svmLyrId, this._unitLayerId, this._resultLayerId].forEach(id => mapUtil.removeAllGraphics(view, id));
    Context.instance.views.toggleClickHandlers("resume");
  }

  reset() {
    isDebug() && console.debug(cn, ".reset()");
    this.cancelAndClearSketch();
    this.onDrawComplete = null; //TODO
    this.activeFeature = null;
    this._originalGeom = null;
    this._origAttributes = null;

    this.z = null;
    this.layerType = null;
    this.cutType = null;
    this.levelData = null;
    this.levelGeom = null;
    this.facilityData = null;
    this.facility = null;
    this.isValidGeometry = true;
    this._isMapClickEnabled = false;
  }

  //call this when unmounting
  destroy() {
    super.destroy();
    this._handles.length = 0; //possibly a bug with BaseClass.ts
    isDebug() && console.debug(cn, ".destroy()");
    this.cancelAndClearSketch();
    const view = this.getView();
    [this._svmLyrId, this._unitLayerId, this._resultLayerId].forEach(id => {
      const gl = mapUtil.findLayerById(view, id);
      gl && view.map.remove(gl);
    });
    this._activeSketchViewModel && this._activeSketchViewModel.destroy();
    this.reset();
    this.dimensionAnnotation.destroy();
  }

  addModifiedUnitsToMap() {
    this.activeFeature.modifiedUnits.forEach(g => { 
      g.symbol = g.geometry.type==="polygon" ? this.highlightSym : this.highlightOutline;
    });
    this._addGraphics(this.activeFeature.modifiedUnits, this._resultLayerId);
  }

  addHighlightBorderToMap(geom:__esri.Polygon) {    
    this.clearGraphics(this._unitLayerId);
    if (!geom)
      return;
    const ctx = Context.getInstance();
    const paths = [];
    (geom.clone() as __esri.Polygon).rings.forEach((r:number[][]) => paths.push(r));
    const line:__esri.Polyline = new ctx.lib.esri.Polyline({
      paths: paths,
      spatialReference: geom.spatialReference,
    });
    const highlightGra = new Context.instance.lib.esri.Graphic({
      attributes: this.activeFeature.attributes, 
      geometry: line,
      symbol: this.activeEditLineSym,
    });
    this._addGraphics([highlightGra], this._unitLayerId);
  }

  private _addGraphics(gras:__esri.Graphic[], lyrId:string) {
    const gl:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), lyrId);
    Array.isArray(gras) && gras.length>0 && gl && gl.addMany(gras);
  }

  private getMatchingGraphic(lyrId:string):__esri.Graphic {
    const gl:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), lyrId);
    const fldOid = mapUtil.getLayer(this.layerType).objectIdField;
    const gra = this.getActiveFeature();
    return gra && gl.graphics.find(g => g.attributes[fldOid]===gra.attributes[fldOid]);
  }

  clearGraphics(lyrId:string) {
    mapUtil.removeAllGraphics(this.getView(), lyrId);
  }  

  getLayerView(): __esri.FeatureLayerView {
    return mapUtil.getLayerView(this.layerType) as __esri.FeatureLayerView;
  }

  getView() {
    return Context.getInstance().views.activeView;
  }  

  isSketchActive() {
    return this._activeSketchViewModel && this._activeSketchViewModel.state==="active";
  }

  // queryFeatures = async (gra:__esri.Graphic, cutType?:CutType):Promise<__esri.Graphic[]> => {
  //   const lyr = this.getLayer();
  //   if (!lyr || !gra || !gra.geometry) {
  //     return null;
  //   }
    
  //   const flds = this.layerType === "detail" 
  //     ? [this.fldDetailUseType, this.fldDetailId, ] 
  //     : [this.fldUnitName, this.fldUnitId];
  //   if (lyr.capabilities.editing.supportsGlobalId) {
  //     //@ts-ignore
  //     flds.push(lyr.globalIdField);
  //   }

  //   const ctx = Context.getInstance();
  //   const fldLevel:__esri.Field = ctx.aiim.getLevelIdField(lyr);
  //   const fldLevelId:string = fldLevel && fldLevel.name;
  //   const levelId:string = fldLevelId && gra.attributes[fldLevelId];
  //   const fldOid:string = lyr.objectIdField;
  //   const oid = gra.attributes ? gra.attributes[fldOid] : null;

  //   if (!levelId || !oid) {
  //     console.warn("Invalid attributes on input graphic, must have fields:", fldOid, fldLevelId);
  //     return null;
  //   }    

  //   //TODO maybe treat areas like hallway, atrium, corridor as empty ??
  //   //+ (cutType===CutType.cutThis ? ` AND ${this.fldUnitUseType} <> 'Hallway' ` : '');
  //   const where = `${fldLevelId} = '${levelId}'`;
  //   const fs = await lyr.queryFeatures({
  //     geometry: gra.geometry,
  //     returnGeometry: true,
  //     returnZ: true,
  //     where,
  //     spatialRelationship: "intersects",
  //     outFields: [fldOid, ...flds],
  //   });
  //   if (Array.isArray(fs.features) && fs.features.length>0) {
  //     const levelData = ctx.aiim.datasets.levels.getLevelData(levelId);
  //     return fs.features.filter(f => f.attributes[fldOid] !== oid).map(f => {
  //       if (f.geometry && !f.geometry.hasZ) {
  //         editorUtil.updateZ(f.geometry, levelData.z);
  //       }
  //       return f;
  //     });
  //   }
  //   return null;
  // }

  cutUnderlyingUnits = async (isSync?:boolean) => {
    this.clearGraphics(this._resultLayerId);
    Topic.publish(Topic.ClearToast,{});
    this.activeFeature.warnings.length=0;
    this.activeFeature.modifiedUnits = null;
    this.activeFeature.originalUnits = null;
    
    const gra = this.getActiveFeature();
    if (!gra || !gra.geometry || !gra.attributes) {
      return; 
    }

    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    svmGra && isSync && (gra.geometry = this.activeFeature.geometry = svmGra.geometry.clone());
    
    const i18nEU = Context.instance.i18n.editor.units;
    const cutResult = await cutThisOrUnderlyingUnits(gra, 
      CutType.cutUnderlying, !this.isDuplicate, i18nEU.warnBadGeomPreferExisting);
    if (!cutResult || !Array.isArray(cutResult.cutFeatures) || cutResult.cutFeatures.length===0) {
      return;
    }
    const { cutFeatures, engulfedFeatures, warnings, originalFeatures } = cutResult;
    cutFeatures.forEach(g => g.geometry && (g.symbol = g.geometry.type==="polygon" ? this.highlightSym : this.highlightOutline));
    engulfedFeatures.forEach(gfx => gfx.symbol = this.removedUnitSym);

    this.activeFeature.modifiedUnits = cutFeatures;
    this.activeFeature.originalUnits = originalFeatures;
    this.activeFeature.warnings = warnings;
    this._addGraphics([...cutFeatures.filter(f=>f.geometry), ...engulfedFeatures], this._resultLayerId);
    Array.isArray(warnings) && warnings.forEach(w => Topic.publish(Topic.ShowToast, w));
    this.updateActiveFeatureGrossArea();
  }

  cutNothing = () => {
    this.clearGraphics(this._resultLayerId);
    this.activeFeature.warnings.length=0;
    this.activeFeature.modifiedUnits = null;
    this.activeFeature.originalUnits = null;
    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    if (svmGra) {
      this.activeFeature.geometry = svmGra.geometry;
    }
    this.updateActiveFeatureGrossArea();
  }

  cutNewUnit = async () => {
    this.clearGraphics(this._resultLayerId);
    Topic.publish(Topic.ClearToast,{});
    this.activeFeature.warnings.length=0;
    this.activeFeature.modifiedUnits = null;
    this.activeFeature.originalUnits = null;

    const graphic = this.getActiveFeature();
    if (!graphic || !graphic.geometry || !graphic.attributes) {
      return null; 
    }

    let resultGeom:__esri.Geometry = null;
    const i18nEU = Context.instance.i18n.editor.units;
    const cutResult = await cutThisOrUnderlyingUnits(graphic, 
      CutType.cutThis, !this.isDuplicate, i18nEU.warnBadGeomPreferNew, false);
    if (cutResult && Array.isArray(cutResult.cutFeatures) && cutResult.cutFeatures.length>0) {
      const { cutFeatures, warnings } = cutResult;
      const thisCut = cutFeatures[0];
      const fldOid = mapUtil.getLayer(this.layerType).objectIdField;
      const svmGraLyr:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), this._svmLyrId);
      const svmGra = svmGraLyr.graphics.find(g => g.attributes[fldOid] === graphic.attributes[fldOid]);

      //if featuresToCut is currently being edited (cutThis), keep geom as-is, don't allow the new invalid geom
      //active unit could be entirely cut out by other existing units
      if (!this.isGeometryEqual(svmGra.geometry, thisCut.geometry)) {
        thisCut.symbol = this.reshapedUnitSym;
        if (!this.isValidGeometry) thisCut.symbol = this.invalidSym;
        this.activeFeature.geometry = thisCut.geometry;
        this.activeFeature.warnings = warnings;
        this._isMapClickEnabled = true;
        this._addGraphics([thisCut], this._resultLayerId);
        resultGeom = thisCut.geometry;
      }

      Array.isArray(warnings) && warnings.forEach(w => Topic.publish(Topic.ShowToast, w));
    }
    this.updateActiveFeatureGrossArea();

    if (this.isSketchActive() && this.layerType === "unit" && this.cutType === CutType.cutThis) {
      this._isMapClickEnabled = true;
    }
    return resultGeom;
  }

  async updateActiveFeatureGrossArea() {
    const g = this.getActiveFeature();
    if ((g.geometry as __esri.Polygon).rings[0]?.length > 3) {
      await updateGrossArea(g);
      this.onDrawUpdate && this.onDrawUpdate("transform");
      this.onValidityChange && this.onValidityChange(this.isValid());
    }
  }

  getSymbol(attributes: Record<string, any>): __esri.Symbol {
    if (!attributes)
      return null; 
    const lyr = mapUtil.getLayer(this.layerType);
    if (!lyr || !lyr.renderer)
      return null;
    //@ts-ignore
    let sym = lyr.renderer.getSymbol({ attributes }) || lyr.renderer.defaultSymbol || lyr.defaultSymbol;
    return sym;
  }

  getActiveFeature(): __esri.Graphic {
    if (this.activeFeature && this.activeFeature.attributes && this.activeFeature.geometry) 
      return new Context.instance.lib.esri.Graphic({
        geometry: this.activeFeature.geometry, 
        attributes: this.activeFeature.attributes,
        symbol: this.getSymbol(this.activeFeature.attributes)
      });
    return null;  
  }

  getRequiredFields() {
    const fields = getFieldObjects(this.layerType);
    const layer = mapUtil.getLayer(this.layerType);
    if (!layer) {
      return [];
    }
    const required = fields.filter(f => f.required).map(f => {
      const field = aiimUtil.findField(layer.fields, f.name);
      if (field?.name?.toLowerCase() === FieldNames.SITE_ID) {
        this.fldSiteId = field;
      } else if (field?.name?.toLowerCase() === FieldNames.FACILITY_ID) {
        this.fldFacilityId = field;
      } else if (field?.name?.toLowerCase() === FieldNames.LEVEL_ID) {
        this.fldLevelId = field;
      }
      return field;
    });

    return required;
  }
  isValid(): boolean {
    return !!(this.activeFeature 
      && this.activeFeature.geometry 
      && this.activeFeature.attributes
      && this.isValidGeometry);
  }

  async save(vmAttr: Record<string, any>, z?: number) {
    if (z != null) {
      this.z = z;
    }
    const origFeat = new Context.instance.lib.esri.Graphic({
      attributes: this._origAttributes,
      geometry: this._originalGeom
    });
    const modifiedFeat = this.getActiveFeature();
    if (!modifiedFeat) {
      throw new Error("Unable to get geometry or attributes to save");
    }

    const lyr = mapUtil.getLayer(this.layerType);

    const _copyFrom1stAdd = (results: IApplyEditsResults, attr: Record<string, any>) => {
      const addResult = results && results[0] && results[0].addResults && results[0].addResults[0];
      if (addResult && attr && lyr) {
        const globalIdField = lyr.fields.find(f => f.type === "global-id").name;
        const objectIdField = lyr && lyr.objectIdField;
        attr[globalIdField] = addResult.globalId;
        attr[objectIdField] = addResult.objectId;
      } else {
        console.warn("Unable to copy attributes from addResult");
      }
    }

    if (this.layerType === "unit") {
      const cutGra = this.getMatchingGraphic(this._resultLayerId);
      if (this.cutType===CutType.cutThis && cutGra && cutGra.geometry) {
        this.activeFeature.geometry = modifiedFeat.geometry = cutGra.geometry;
      }

      updateZ(modifiedFeat.geometry, this.z);
      
      const info = { 
        newUnits: null, 
        modifiedUnits: [modifiedFeat],
        originalUnits: [origFeat],
      };

      if(this.isDuplicate) {
        info.newUnits = [modifiedFeat]
        info.originalUnits = []
        info.modifiedUnits = []
      }

      if (this.activeFeature.modifiedUnits && this.cutType===CutType.cutUnderlying) {
        info.modifiedUnits.push(...this.activeFeature.modifiedUnits);
        info.originalUnits.push(...this.activeFeature.originalUnits);
      }

      const fldArea = aiimUtil.findFieldName(lyr.fields, FieldNames.UNITS_AREA_GROSS);
      await Promise.all(info.modifiedUnits.map(async (f:__esri.Graphic, i:number) => {
        // User should be able to change GROSS AREA and value honored after save #7325
        if (i > 0) {
          await updateGrossArea(f, fldArea);
        }
      }));
      
      // User should be able to change GROSS AREA and value honored after save #7325
      // if(this.isDuplicate) {
      //   for (const f of info.newUnits) {
      //     await updateGrossArea(f, fldArea);
      //   }
      // }

      isDebug() && console.debug(cn, ".save()", info);
      const results = await transactions.fpeEditUnit(info);
      this.isDuplicate && _copyFrom1stAdd(results, vmAttr);
      isDebug() && console.debug("fpeEditUnit.save() results:", results);
    } else if (this.layerType === "detail") {
      updateZ(modifiedFeat.geometry, this.z);
      const info: UpdateDetailsTask["info"] = {
        action: this.isDuplicate ? "add" : "update",
        feature: modifiedFeat,
        prevFeature: origFeat,
      }
      const results = await transactions.editDetail(info);
      this.isDuplicate && _copyFrom1stAdd(results, vmAttr);
      isDebug() && console.debug("editDetails.save() results:", results);
    } else if (this.layerType === "pathway") {
      // @todo pathways
    } else if (this.layerType === "level") {
      updateZ(modifiedFeat.geometry, this.z);
      await this.validateFields(modifiedFeat.attributes);
      const fldGrossArea = aiimUtil.findFieldName(mapUtil.getLayer(this.layerType).fields, FieldNames.UNITS_AREA_GROSS);
      if (!modifiedFeat.attributes[fldGrossArea] || isNaN(modifiedFeat.attributes[fldGrossArea])) {
        await updateGrossArea(modifiedFeat);
      }
      const info: UpdateFootprintTask["info"] = this.isDuplicate
        ? {
          type: this.layerType,
          adds: [modifiedFeat]
        }
        : {
          type: this.layerType,
          updates: [modifiedFeat],
          undoUpdates: [origFeat]
        };
      const results = await transactions.editFootprint(info);
      isDebug() && console.debug("editDetails.save() results:", results);
    } else {
      updateZ(modifiedFeat.geometry, 0);
      const info: UpdateFootprintTask["info"] = {
        type: this.layerType,
        updates: [modifiedFeat],
        undoUpdates: [origFeat]
      }
      const results = await transactions.editFootprint(info);
      isDebug() && console.debug("editDetails.save() results:", results);
    }
    this.cancelAndClearSketch();
  }

  /** Creates a form template for the attributes form. Currently only support footprints. */
  static initFormTemplate(layerType: LayerType, groupExpanded: boolean) {
    const layer = mapUtil.getLayer(layerType);
    if (!layer) {
      return;
    }
    
    const fieldConfigs = getFieldObjects(layerType);
    const siteIdField = fieldConfigs.find(f => f.name === FieldNames.SITE_ID);
    if (siteIdField) {
      siteIdField.visible = mapUtil.getLayer("site") != null;
    }  
    const template = getFormTemplate(layerType, layer.fields, fieldConfigs, groupExpanded);
    return template;
  }
  isGeometrySimple(geom:__esri.Geometry): boolean {
    const thisGeom:__esri.Geometry = geom ? geom : this.activeFeature && this.activeFeature.geometry;
    if (thisGeom.type === "polygon") {
      //TODO not sure if more than 1 ring is considered invalid for Indoors model (probably depends on type... e.g. level vs unit)
      const polygon = thisGeom as __esri.Polygon;
      if (!polygon || polygon.isSelfIntersecting || polygon.rings.length>1) {
        return false;
      }
    }
    const ge = Context.instance.lib.esri.geometryEngine;
    return ge.isSimple(thisGeom);
  }

  isGeometryModified(): boolean {
    return !this.isGeometryEqual(this.activeFeature && this.activeFeature.geometry, this._originalGeom);
  }

  isOtherUnitsModified(): boolean {
    return this.activeFeature?.modifiedUnits?.length>0;
  }

  isGeometryEqual(geometry:__esri.Geometry, otherGeometry:__esri.Geometry): boolean {
    if (!(geometry && otherGeometry && geometry.type === otherGeometry.type)) {
      return false;
    }
    const ge: typeof __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    return ge.equals(geometry, otherGeometry);
  }
  private async validateFacility(graphic: __esri.Graphic, showError = true) {
    const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
    const { i18n: { editor }, views: { floorFilter: { activeWidget: { viewModel } } } } = Context.getInstance();
    const geometry = graphic?.geometry as __esri.Polygon;
    const features: { facilities: { facilitiesInfo: { geometry: __esri.Geometry, id: string }[] } } = viewModel.get("filterFeatures");
    const allFacilities = features.facilities.facilitiesInfo
      .filter(s => s.id !== graphic.attributes[this.fldFacilityId.name])
      .map(f => f.geometry);
    const overlaps = geometry && allFacilities.length
      ? allFacilities.some(g => engine.overlaps(graphic.geometry, g))
      : false;
    // sites exist in the data
    if (hasSites()) {
      const within = geometry && this.site?.geometry
        ? engine.within(geometry, this.site.geometry)
        : false;
      this.isValidGeometry = within && !overlaps && geometry.rings[0]?.length > 3 ? true : false;
    } else {
      this.isValidGeometry = !overlaps && geometry.rings[0]?.length > 3 ? true : false;
    }
    if (!this.isValidGeometry && showError) {
      const error: WarningMsg = {
        type: "error",
        message: editor.warnLocationInvalid,
        submessage: overlaps
          ? editor.facilities.errOverlapsOtherFootprint
          : editor.facilities.errNotWithinSite
      };
      Topic.publish(Topic.ShowToast, error);
    }
  }
  private async validateFields(attr: Record<string, any>): Promise<void> {
    const { i18n } = Context.getInstance();
    const layer = mapUtil.getLayer(this.layerType);

    const required = this.getRequiredFields();
    const fldLvlId = aiimUtil.findField(layer.fields, FieldNames.LEVEL_ID);
    const fldNmShort = aiimUtil.findField(layer.fields, FieldNames.NAME_SHORT);
    const fldNm = aiimUtil.findField(layer.fields, FieldNames.NAME);
    const fldLvlNum = aiimUtil.findField(layer.fields, FieldNames.LEVEL_NUMBER);
    const fldVO = aiimUtil.findField(layer.fields, FieldNames.VERTICAL_ORDER);
    const fldFacId = aiimUtil.findField(layer.fields, FieldNames.FACILITY_ID);

    // get vertical order, query for VO-1, if exists then use that zval + rel ht, else zVal=rel ht
    const invalidFlds = required.filter(f => {
      return [null, undefined, "",].includes(attr[f.name])
        || (["small-integer", "integer", "single", "double", "long"].includes(f.type) && isNaN(attr[f.name]))
    });
    if (invalidFlds.length > 0) {
      throw new Error(i18n.editor.levels.invalidField.replace("{fields}", invalidFlds.join(", ")));
    }

    const where = `${fldFacId.name}='${attr[fldFacId.name]}'`;
    const uniqFlds = [fldLvlId, fldNmShort, fldNm, fldLvlNum, fldVO];
    const fsAll = await layer.queryFeatures({
      where,
      returnGeometry: false,
      outFields: uniqFlds.map(f => f.name),
      gdbVersion: this.getVersionName()
    });
    const fldsToPrint = this.fldsToLog;
    const ftToStr = function (attr: Record<string, any>) {
      return `[${attr ? fldsToPrint.map(u => attr[u]).filter(x => !!x).join(",") : ''}]`;
    }
    const prevSavedLvlId = this._origAttributes?.[fldLvlId.name];
    if (fsAll?.features?.length > 0) {
      const notUniqFlds = uniqFlds.filter((f: __esri.Field) => {
        const sameVals = fsAll.features.filter(ft => {
          const isSame = prevSavedLvlId && ft.attributes[fldLvlId.name] === prevSavedLvlId;
          return !isSame && ft.attributes[f.name] === attr[f.name];
        });
        if (sameVals.length > 0) {
          console.warn(`Value[${attr[f.name]}] for field[${f.name}] in new level is NOT unique, attributes of new level:`,
            ftToStr(attr), "Existing feature/s:", sameVals.map(ft => ftToStr(ft.attributes)));
          return true;
        }
        return false;
      });
      if (notUniqFlds.length > 0) {
        throw new Error(i18n.editor.levels.nonUniqueField.replace("{fields}", notUniqFlds.map(f => f.name).join(", ")));
      }
    }
  }
  
  private async validateLevel(graphic: __esri.Graphic, showError = true) {
    const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
    const { error } = await getLevelData(graphic.geometry);
    const within = graphic?.geometry && this.facility?.geometry
      ? engine.within(graphic.geometry, this.facility.geometry)
      : false;
    this.isValidGeometry = !error || within ? true : false;
    if (!this.isValidGeometry && this.facility != null && showError) {
      Topic.publish(Topic.ShowToast, error);
    }
  }
  
  private async validateGeometry(graphic: __esri.Graphic, showError = true) {
    this.activeFeature.warnings.length = 0;
    Topic.publish(Topic.ClearToast, {});

    if (this.layerType === "level") {
      await this.validateLevel(graphic, showError);
    } else if (this.layerType === "site") {
      this.validateSite(graphic, showError);
    } else if (this.layerType === "facility") {
      this.validateFacility(graphic, showError);
    } else if (this.layerType === "detail" || this.layerType === "unit") {
      this.validateUnitOrDetail(graphic, showError);
    } else if (graphic?.geometry.type === "polygon") {
      this.isValidGeometry = (graphic?.geometry as __esri.Polygon).rings[0]?.length > 3;
    } else if (graphic?.geometry.type === "polyline") {
      this.isValidGeometry = (graphic?.geometry as __esri.Polyline).paths[0]?.length > 1;
    }
    graphic.symbol = this.isValidGeometry ? this.getSymbol(graphic.attributes) : this.invalidSym;
  }
  private async validateSite(graphic: __esri.Graphic, showError = true) {
    const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
    const { i18n, views: { floorFilter: { activeWidget: { viewModel } } } } = Context.getInstance();
    const features: { sites: { sitesInfo: { geometry: __esri.Geometry, id: string }[] } } = viewModel.get("filterFeatures");
    const allSites = features?.sites?.sitesInfo
      .filter(s => s.id !== graphic.attributes[this.fldSiteId.name])
      .map(f => f.geometry);
    const overlaps = graphic?.geometry && allSites
      ? allSites.some(g => engine.overlaps(graphic.geometry, g) || engine.within(graphic.geometry, g))
      : false;
    this.isValidGeometry = !overlaps && (graphic?.geometry as __esri.Polygon).rings[0]?.length > 3 ? true : false;
    if (!this.isValidGeometry && showError) {
      const error: WarningMsg = {
        type: "error",
        message: i18n.editor.warnLocationInvalid,
        submessage: i18n.editor.sites.errOverlapsOtherFootprint
      };
      Topic.publish(Topic.ShowToast, error);
    }
  }
  private async validateUnitOrDetail(graphic: __esri.Graphic, showError = true) {
    const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
    const { i18n: { editor: { units: i18nEU } }, views: { floorFilter: { activeWidget: { viewModel } } } } = Context.getInstance();
    const levelContainsGeometry = graphic?.geometry && this.levelGeom
      ? engine.contains(this.levelGeom, graphic.geometry)
      : false;
    const facilityContainsGeometry = graphic?.geometry && this.facility?.geometry
      ? engine.contains(this.facility.geometry, graphic.geometry)
      : false;
    this.isValidGeometry = levelContainsGeometry;
    if (!this.isValidGeometry && showError) {
      const errObj: WarningMsg = {
        type: "error",
        message: i18nEU.warnNotWithinFloor,
        submessage: `${this.levelData.levelName}, ${this.levelData.levelId}`,
      };
      this.activeFeature.warnings.push(errObj);
      Topic.publish(Topic.ShowToast, errObj);      
    }
    this.isValidGeometry = facilityContainsGeometry;
    if (!this.isValidGeometry && showError) {
      const errObj: WarningMsg = {
        type: "error",
        message: i18nEU.warnNotWithinFacility,
        submessage: `${this.facilityData.facilityName}, ${this.facilityData.facilityId}`,
      };
      this.activeFeature.warnings.push(errObj);
      Topic.publish(Topic.ShowToast, errObj);        
    }    
  }
}