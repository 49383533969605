import BaseVM from "../../../components/Editor/support/BaseVM";
import Context from "../../../../context/Context";
import { IItemProperties } from "../../common/types";
import Topic from "../../../../context/Topic";
import { WorkingController } from "../../../../common/components/Modal";
import Planner from "../../../base/Planner";
import PlanCreator from "../../../base/PlanCreator";
import * as portalUtil from "../../../../util/portalUtil";
import { IAlertOptions } from "../../common/components/Modal";

interface IPlanVMProps {
  isSaveAs: boolean
}
export class NewPlanVM extends BaseVM implements IPlanVMProps {
  properties: IItemProperties;
  isSaveAs: boolean;
  constructor(props: IPlanVMProps) {
    super(props);
  }

  getItemInfo() {
    return this.properties && this.properties.getItemInfo();
  }
  getProject() {
    const sp = Context.getInstance().spaceplanner;
    return sp.planner && sp.planner.project;
  }
  isLoaded() {
    return this.getProject() != null;
  }
  isVersioned() {
    const project = this.getProject();
    return project ? !!project.isVersioned : false;
  }
  validate(): true | IAlertOptions {
    const itemInfo = this.getItemInfo();
    const { i18n } = Context.getInstance();
    const { valid, validationMessage } = this.properties.validate(itemInfo && itemInfo.title);
    return valid || {
      title: i18n.spaceplanner.backstage.newPlan.errorCreatingTitle,
      message: validationMessage
    };
  }
  create(): Promise<{ planAppId: string, planId: string }> {
    const project = this.getProject();
    const isVersioned = this.isVersioned();
    const itemInfo = this.getItemInfo();
    if (!itemInfo || !itemInfo.title) return Promise.reject();

    // if(!isVersioned){
    const ctx = Context.getInstance();
    const i18n = ctx.i18n;
    const portal = ctx.getPortal();
    let isPortal = portal.isPortal;
    // const isPortal = false;
    if (isVersioned) isPortal = false;
    itemInfo.itemTitle = itemInfo.title;
    itemInfo.title = itemInfo.title.split(" ").join("_");
    const validation = this.validate();
    if (validation !== true) {
      return Promise.reject(validation);
    }

    Topic.publish(Topic.CreatingPlan, {});

    const workingController = new WorkingController();
    const workingContent = i18n.spaceplanner.backstage.newPlan.creating;
    workingController.show({}, workingContent);
    const pc = new PlanCreator();
    const options = {
      itemInfo: itemInfo,
      isSaveAs: this.isSaveAs,
      workingController: workingController
    };
    return pc.createPlan(project, options).then(result => {
      let planId: string, planAppId: string;
      const user = portal.user;

      if (itemInfo && "sharingInfo" in itemInfo && itemInfo.sharingInfo['groups'] && !!(result.plan && result.plan.serviceItemId)) {
        const sharingInfo = {
          everyone: false,
          org: false,
          groups: itemInfo.groupIds.join(',')
        }
        portalUtil.shareItem(result.plan.serviceItemId, user.username, sharingInfo);
      }

      if (isVersioned) {
        const versionInfo = result.versionInfo;
        planId = versionInfo.versionGuid;
        planAppId = Planner.getParentAppId({ versionInfo: versionInfo });
      } else {
        planId = result.plan.serviceItemId;
        planAppId = Planner.getParentAppId(result.plan.serviceItem);
      }
      return { planAppId, planId };
    }).catch(ex => {
      let title = i18n.spaceplanner.backstage.newPlan.errorCreatingTitle;
      let message = ex.message;
      let submessage = ex.submessage;
      if (ex.code === "__serviceNameAlreadyExists__") {
        submessage = null;
        message = i18n.spaceplanner.backstage.newPlan.errorPlanNameExists;
      }
      if (ex.message === "The version already exists") {
        submessage = null;
        message = i18n.spaceplanner.backstage.newPlan.errorPlanNameExists;
      }
      if (ex.message === "Invalid version name.") {
        submessage = null;
        message = i18n.spaceplanner.backstage.newPlan.errorUnsupportedCharacters;
      }
      if (ex.details) {
        submessage = ex.details.messages && ex.details.messages.length
          ? ex.details.messages
          : null;
      }
      workingController.close();
      const rejection = {
        isError: true,
        title: title,
        message: message,
        submessage: submessage
      }
      console.error("Error creating plan", ex);
      throw rejection;
    });
  }
}
