import * as dateUtil from "./dateUtil";

function makeDatePart(job) {
  let where = null;
  let method = job.criteria.dateOption;
  let startField = job.dataset.dateStartField;
  let endField = job.dataset.dateEndField;
  let dt, dtStart, dtEnd;
  let now = Date.now();

  if (method === "today") {
    dt = new Date(now);
    dtStart = dateUtil.toStartOfDay(dt);
    dtEnd = dateUtil.toEndOfDay(dt);
    where = dateUtil.getRangeClause(startField,endField,dtStart,dtEnd);
    job.refDate = dtStart;

  } else if (method === "tomorrow") {
    dt = new Date(now);
    dt.setDate(dt.getDate() + 1);
    dtStart = dateUtil.toStartOfDay(dt);
    dtEnd = dateUtil.toEndOfDay(dt);
    where = dateUtil.getRangeClause(startField,endField,dtStart,dtEnd);
    job.refDate = dtStart;
  } else if (method === "thisweek") {
    dtStart = new Date(now);
    let day = dtStart.getDay();
    dtStart.setDate(dtStart.getDate() - day);
    dtEnd = new Date(dtStart.getTime());
    dtEnd.setDate(dtEnd.getDate() + 6);
    dtStart = dateUtil.toStartOfDay(dtStart);
    dtEnd = dateUtil.toEndOfDay(dtEnd);
    where = dateUtil.getRangeClause(startField,endField,dtStart,dtEnd);
  } else if (method === "thismonth") {
    dt = new Date(now);
    dtStart = new Date(dt.getFullYear(), dt.getMonth(), 1);
    dtEnd = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    dtStart = dateUtil.toStartOfDay(dtStart);
    dtEnd = dateUtil.toEndOfDay(dtEnd);
    where = dateUtil.getRangeClause(startField,endField,dtStart,dtEnd);
  } else if (method === "pickdate") {
    let dateSelected = job.criteria.dateSelected
    if (dateSelected !== undefined && dateSelected !== null) {
      dt = new Date(dateSelected.getTime());
      dtStart = dateUtil.toStartOfDay(dt);
      dtEnd = dateUtil.toEndOfDay(dt);
      where = dateUtil.getRangeClause(startField,endField,dtStart,dtEnd);
      job.refDate = dtStart;
    }
  }
  return where;
}

export function makeWhere(criteria,dataset) {
  //console.log("eventUtil::makeWhere - criteria",criteria);
  let where = ""
  const append = (where,part) => {
    if (typeof part === "string" && part.length > 0) {
      if (where.length > 0) where += " AND ";
      where += part;
    }
    return where;
  };

  if (dataset) {
    const job = {
      criteria: criteria,
      dataset: dataset
    };
    where = append(where,makeDatePart(job));
    where = append(where,makeTimePart(job));
    where = append(where,makeTypePart(job));
  }
  if (where.length === 0) where = "1=1";
  return where;
}

function makeTimePart(job) {
  if (!job.refDate) return null;

  let selectedValue = job.criteria.timeOption;
  if (typeof selectedValue !== "number") return null;
  let where = null;
  let hour = selectedValue;
  let hoursLeftInDay = (24 - hour);
  let startField = job.dataset.dateStartField;
  //let endField = job.dataset.dateEndField;
  if (startField) {
    let refDate = job.refDate;
    if (!refDate) refDate = new Date(Date.now());
    let dt = new Date(refDate.getTime());
    dt = dateUtil.toStartOfDay(dt);
    dt.setHours(hour);

    let startHourUTC = dt.getUTCHours();
    let endHourUTC = 0;
    let eod = Math.min((startHourUTC + hoursLeftInDay),24);
    let overflow = (startHourUTC + hoursLeftInDay - 24);
    if (overflow > 0) {
      endHourUTC = overflow;
    } else if (eod > startHourUTC && eod < 24) {
      endHourUTC = eod;
    }

    //console.log("hour",hour,"startHourUTC",startHourUTC,"hoursLeftInDay",hoursLeftInDay);
    //console.log("endHourUTC",endHourUTC,"eod",eod,"overflow",overflow);

    let onOrAfter = "(EXTRACT(HOUR FROM " + startField + ") >= " + startHourUTC + ")";
    let onOrAfter2;
    if (endHourUTC > 0) {
      onOrAfter2 = "(EXTRACT(HOUR FROM " + startField + ") < " + endHourUTC + ")";
      if (endHourUTC > startHourUTC) {
        onOrAfter = "(" + onOrAfter + " AND " + onOrAfter2 + ")";
      } else {
        onOrAfter = "(" + onOrAfter + " OR " + onOrAfter2 + ")";
      }
    }

    where = onOrAfter;

    /*
      Intersects
        sameday: subjectUTC is 8 , eventStartUTC is 7, eventEndUTC is 10
        sameday: eventStartUTC < 8 and eventEndUTC > 8
        spans day: subjectUTC is 22 , eventStartUTC is 21, eventEndUTC is 2
        spans day: eventStartUTC < 22 and eventEndUTC ???
    */

    // if (endField) {
    //
    //   // startHour < endHour
    //   let intersectsA1 = "(EXTRACT(HOUR FROM " + startField + ") < EXTRACT(HOUR FROM " + endField + "))";
    //   let intersectsA2 = "((EXTRACT(HOUR FROM " + startField + ") < " + startHourUTC + ")";
    //   intersectsA2 += " AND (EXTRACT(HOUR FROM " + endField + ") > " + startHourUTC + "))";
    //   let intersectsA = "(" + intersectsA1 + " AND " + intersectsA2 + ")";
    //
    //   // startHour > endHour
    //   let intersectsB1 = "(EXTRACT(HOUR FROM " + startField + ") > EXTRACT(HOUR FROM " + endField + "))";
    //   let intersectsB2 = "(EXTRACT(HOUR FROM " + startField + ") < " + startHourUTC + ")";
    //   let intersectsB = "(" + intersectsB1 + " AND " + intersectsB2 + ")";
    //
    //   let intersects = "(" + intersectsA + " OR " + intersectsB + ")";
    //
    //   where = "(" + where + " OR " + intersects + ")";
    // }
  }

  return where;
}

function makeTypePart(job) {
  let v = job.criteria.typeOption;
  if (typeof v === "string" && v.length > 0) {
    v = v.replace("'","''");
    const field = job.dataset.typeField;
    return "(" + field + "='" + v + "')";
  }
  return null;
}
