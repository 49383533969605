import styled from "styled-components";

import CalciteButton from "calcite-react/Button";

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonGroupComment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(CalciteButton)`
  &:hover {
    color: ${props => props.theme.palette.themeColor};
  }
`;

export { ButtonGroup, ButtonGroupComment, Button };
