import Context from "../../context/Context";

export function makeSortOptions(source) {
  const i18n = Context.getInstance().i18n;
  const mappings = source.workOrderMappings;
  const options = [];
  const add = (field,label,isTitle) => {
    if (typeof field === "string" && field.length > 0) {
      options.push({
        field: field,
        label: label,
        isTitle: !!isTitle
      });
    }
  };
  add("distance",i18n.workOrders.item.distance);
  if (mappings) {
    add(mappings.titleField,i18n.workOrders.item.title,true);
    add(mappings.idField,i18n.workOrders.item.id);
    add(mappings.createdOnField,i18n.workOrders.item.createdOn);
    add(mappings.priorityField,i18n.workOrders.item.priority);
  }
  if (options.length > 1) return options;
  return null;
}
