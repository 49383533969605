import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import QueryCriteria, { IFilterPair } from "../../base/QueryCriteria";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as filterUtil from "./filterUtil";
import { HomeOfficeId } from "../../base/AreasTable";

export default class FilterModel extends BaseClass {

  _pairs;
  _siteFacilityLevel: {
    activeFacility?: string,
    activeLevel?: string,
    activeSite?: string
  } = {};

  queryCriteriaKey: string;

  isSiteFacilityLevel = false;
  isSiteFacilityLevelUnassigned = false;
  isSiteFacilityLevelPeople = false;
  isSite = false;
  isFacility = false;
  isLevel = false;
  isFacilityPeople: boolean;
  isLevelPeople: boolean;
  isSitePeople: boolean;
  isSitePeopleUnassigned: boolean;
  isSiteUnassigned: boolean;
  isFacilityUnassigned: boolean;
  isLevelUnassigned: boolean;
  isSiteFacilityLevelPeopleUnassigned: boolean = false;

  constructor(props) {
    super(props);
    this.queryCriteriaKey = props.queryCriteriaKey;
  }

  apply() {
    //let pairs;
    //if (this._pairs) pairs = JSON.parse(JSON.stringify(this._pairs));
    let pairs = this.clonePairs(this._pairs);
    const queryCriteria = this.getQueryCriteria();
    if (queryCriteria) queryCriteria.onFilterPairsChanged(pairs);
  }

  clear() {
    this._pairs = undefined;
    this._siteFacilityLevel = {};
    const queryCriteria = this.getQueryCriteria();
    if (queryCriteria) {
      queryCriteria._localPairs = this._pairs;
      queryCriteria._siteFacilityLevel = this._siteFacilityLevel;
      queryCriteria.onFilterPairsChanged();
    }
  }

  clonePairs(pairs) {
    if (!pairs) return pairs;
    let cloned = [];
    if (pairs) {
      pairs.forEach(p => {
        let p2 = Object.assign({},p);
        let gv = p.getValues;
        delete p2.getValues;
        let p3 = JSON.parse(JSON.stringify(p2));
        if (gv) p3.getValues = gv;
        cloned.push(p3);
      })
    }
    return cloned;
  }

  getQueryCriteria() {
    return QueryCriteria.get(this.queryCriteriaKey);
  }

  init() {
    let pairs;
    this._siteFacilityLevel = {};
    const queryCriteria = this.getQueryCriteria();
    if (queryCriteria) {
      pairs = queryCriteria.filterPairs;
    }
    if (pairs) {
      //this._pairs = JSON.parse(JSON.stringify(pairs));;
      this._pairs = this.clonePairs(pairs);
      if (queryCriteria && queryCriteria._siteFacilityLevel) {
        this._siteFacilityLevel = queryCriteria._siteFacilityLevel
      }
    } else {
      this._pairs = undefined;
    }
    if (queryCriteria) {
      queryCriteria._localPairs = this._pairs;
      queryCriteria._siteFacilityLevel = this._siteFacilityLevel;
    }
  }

  makePairs() {
    if (this._pairs) return this._pairs;
    const pairs = [];
    const i18n = Context.instance.i18n;
    const i18nFields = Context.instance.i18n.filter.fields;
    const queryCriteria = this.getQueryCriteria();
    const source = queryCriteria && queryCriteria.getSource();
    const layer = source && source.layer2D;
    const fields = layer && layer.fields;
    if (!fields) return [];

    const levelsDataset = Context.instance.aiim.datasets.levels;

    const addSFLDefault =()=>{
      addPair(FieldNames.SITE_NAME,i18nFields.site);
      addPair(FieldNames.FACILITY_NAME,i18nFields.facility);
      addPair(FieldNames.LEVEL_NAME,i18nFields.level);
    }

    const addSiteFacilityLevelPeople =()=> {
      if(this.isSitePeople === true) addPair(FieldNames.SITE_NAME,i18nFields.site, false);
      else if(!this.isSitePeople) addPair(FieldNames.SITE_NAME,i18nFields.site, true);
      if(this.isFacilityPeople) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, false);
      else if (!this.isFacilityPeople) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, true);
      if(this.isLevelPeople) addPair(FieldNames.LEVEL_NAME,i18nFields.level, false);
      else if(!this.isLevelPeople) addPair(FieldNames.LEVEL_NAME,i18nFields.level, true)
    }

    const addSiteFacilityLevel =()=> {
      if(this.isSite === true) addPair(FieldNames.SITE_NAME,i18nFields.site, false);
      else if(!this.isSite) addPair(FieldNames.SITE_NAME,i18nFields.site, true);
      if(this.isFacility) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, false);
      else if (!this.isFacility) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, true);
      if(this.isLevel) addPair(FieldNames.LEVEL_NAME,i18nFields.level, false);
      else if(!this.isLevel) addPair(FieldNames.LEVEL_NAME,i18nFields.level, true)
    }

    const addSiteFacilityLevelPeopleUnassigned=()=> {
      if(this.isSitePeopleUnassigned === true) addPair(FieldNames.SITE_NAME,i18nFields.site, false);
      else if(!this.isSitePeopleUnassigned) addPair(FieldNames.SITE_NAME,i18nFields.site, true);
    }

    const addSiteFacilityLevelUnassigned=()=>{
      if(this.isSiteUnassigned === true) addPair(FieldNames.SITE_NAME,i18nFields.site, false);
      else if(!this.isSiteUnassigned) addPair(FieldNames.SITE_NAME,i18nFields.site, true);
      if(this.isFacilityUnassigned) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, false);
      else if (!this.isFacilityUnassigned) addPair(FieldNames.FACILITY_NAME,i18nFields.facility, true);
      if(this.isLevelUnassigned) addPair(FieldNames.LEVEL_NAME,i18nFields.level, false);
      else if(!this.isLevelUnassigned) addPair(FieldNames.LEVEL_NAME,i18nFields.level, true)
    }

    const addPeopleDetails=()=>{
      addPair(FieldNames.PEOPLE_ORG_LEVEL_2);
      addPair(FieldNames.PEOPLE_ORG_LEVEL_1);
      addPair(FieldNames.PEOPLE_JOB_TITLE);
    }

    const addPair = (fieldName: string, fieldLabel?: string, hidden?: boolean) => {
      const field: __esri.Field = filterUtil.getField(fields, fieldName);
      if (field) {
        const label = field.alias  || fieldName || field.name;
        const pair = queryCriteria.newFilterPair({
          field: field.name,
          label: label
        });
        pair.id = field.name;
        pair.type = field.type;
        pairs.push(pair);

      } else if (fieldName === FieldNames.SITE_NAME ||
                 fieldName === FieldNames.FACILITY_NAME ||
                 fieldName === FieldNames.LEVEL_NAME) {
        const lidField = Context.instance.aiim.getLevelIdField(source);
        //console.log("lidField",lidField)
        if (lidField) {
          const facilities = levelsDataset && levelsDataset.getFacilities();
          if (facilities && facilities.length > 0) {
            if ((fieldName === FieldNames.SITE_NAME)) {
              const sites = [];
              const idx = {};
              facilities.forEach(f => {
                if (f.siteId && f.siteName) {
                  if (!idx.hasOwnProperty(f.siteId)) {
                    idx[f.siteId] = "y";
                    sites.push({
                      name:f.siteName,
                      value: f.siteId,
                      isSiteRef: true
                    });
                  }
                }
              });
              sites.sort((a,b) => {
                if (a.name && b.name) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
              });
              if (sites.length > 0) {
                const label = fieldLabel;
                const pair = queryCriteria.newFilterPair({
                  field: null,
                  label: label,
                  hidden: hidden,
                  getValues: (opts) => {
                    let sfl = this._siteFacilityLevel || {};
                    let activeFacility = sfl.activeFacility;
                    let activeLevel = sfl.activeLevel;
                    const values = [];
                    sites.forEach(site => {
                      let ok = false;
                      if (activeLevel) {
                        let ld = levelsDataset && levelsDataset.getLevelData(activeLevel);
                        if (ld && ld.siteId === site.value) {
                          ok = true;
                        }
                      } else if (activeFacility) {
                        let fd = levelsDataset && levelsDataset.getFacilityData(activeFacility);
                        if (fd && fd.siteId === site.value) {
                          ok = true;
                        }
                      } else {
                        ok = true;
                      }
                      if (ok) {
                        let flInfo = (opts && opts.filteredLevelInfo);
                        if (flInfo && flInfo.siteIdx) {
                          if (!flInfo.siteIdx[site.value]) {
                            ok = false;
                          }
                        }
                      }
                      if (ok) values.push(site);
                    })
                    return values;
                  }
                });
                pair.id = "__sites__";
                pair.type = "string";
                // pair.operatorType 
                // pair.value will be an array
                pairs.push(pair);
              }

            } else if (fieldName === FieldNames.FACILITY_NAME) {
              const label = fieldLabel;
              const pair = queryCriteria.newFilterPair({
                field: null,
                label: label,
                hidden: hidden,
                getValues: (opts) => {
                  let sfl = this._siteFacilityLevel || {};
                  let activeSite = sfl.activeSite;
                  let activeLevel = sfl.activeLevel;
                  const values = [];
                  facilities.forEach(f => {
                    let ok = false;
                    const fv = {
                      name: f.facilityName,
                      value: f.facilityId,
                      siteId: f.siteId,
                      isFacilityRef: true
                    };
                    if (activeLevel) {
                      let ld = levelsDataset && levelsDataset.getLevelData(activeLevel);
                      if (ld && ld.facilityId === fv.value) {
                        ok = true;
                      }
                    } else if (activeSite) {
                      if (f.siteId === activeSite) {
                        ok = true;
                      }
                    } else {
                      ok = true;
                    }
                    if (ok) {
                      let flInfo = (opts && opts.filteredLevelInfo);
                      if (flInfo && flInfo.facilityIdx) {
                        if (!flInfo.facilityIdx[fv.value]) {
                          ok = false;
                        }
                      }
                    }
                    if (ok) values.push(fv);
                  });
                  return values;
                }
              });
              pair.id = "__facilities__";
              pair.type = "string";
              pairs.push(pair);

            } else if (fieldName === FieldNames.LEVEL_NAME) {
              const levels = [];
              facilities.forEach(f => {
                f.levels.forEach(l => {
                  levels.push({
                    name: l.levelLongName || l.levelShortName,
                    value: l.levelId,
                    facilityId: l.facilityId,
                    siteId: l.siteId,
                    isLevelRef: true
                  })
                });
              });
              levels.sort((a,b) => {
                if (a.name && b.name) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
              });
              if (levels.length > 0) {
                const label = fieldLabel;
                const pair = queryCriteria.newFilterPair({
                  field: null,
                  label: label,
                  hidden: hidden,
                  getValues: (opts) => {
                    let sfl = this._siteFacilityLevel || {};
                    let activeSite = sfl.activeSite;
                    let activeFacility = sfl.activeFacility;
                    const values = [];
                    levels.forEach(l => {
                      let ok = false;
                      if (activeFacility) {
                        if (l.facilityId === activeFacility) {
                          ok = true;
                        }
                      } else if (activeSite) {
                        if (l.siteId === activeSite) {
                          ok = true;
                        }
                      } else {
                        ok = true;
                      }
                      if (ok) {
                        let flInfo = (opts && opts.filteredLevelInfo);
                        if (flInfo && flInfo.levelIdx) {
                          if (!flInfo.levelIdx[l.value]) {
                            ok = false;
                          }
                        }
                      }
                      if (ok) values.push(l);
                    })
                    return values;
                  }
                });
                pair.id = "__levels__";
                pair.type = "string";
                pairs.push(pair);
              }
            }

          }
        }
      }
    };

    if (queryCriteria.sourceKey === "People") {
      if (queryCriteria.forPeopleChanges || queryCriteria.forAssignedPeople) {
        const unitIdField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_UNIT_ID).name;
        const areaIdField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_AREA_ID).name;
        const pair = queryCriteria.newFilterPair({
          field: "__AssignedTo__",
          label: i18n.spaceplanner.changes.peopleAssignmentFilter.caption,
          fixedOptions: [
            {
              name: i18n.spaceplanner.changes.peopleAssignmentFilter.unit,
              value: "unit",
              expression: "(" + unitIdField + " IS NOT NULL)"
            },
            {
              name: i18n.spaceplanner.changes.peopleAssignmentFilter.area,
              value: "area",
              expression: `(${areaIdField} IS NOT NULL AND ${areaIdField} <> '' AND ${areaIdField} <> '${HomeOfficeId}')`
            },
            {
              name: i18n.spaceplanner.changes.peopleAssignmentFilter.home,
              value: "home",
              expression: `(${areaIdField} = '${HomeOfficeId}')`
            },
            {
              name: i18n.spaceplanner.changes.peopleAssignmentFilter.none,
              value: "none",
              expression: "((" + unitIdField + " IS NULL) AND (" + areaIdField + " IS NULL))"
            }
          ]
        });
        pairs.push(pair);
      }

      const filterSection = Context.instance.config.spaceplanner.filterColumns;
      if (!queryCriteria.forUnassignedPeople) {
        if(filterSection && filterSection.allOccupantsInfo && queryCriteria.key !== "movedates_people") {
          const allOccupantsInfoFields = filterSection.allOccupantsInfo;
          for (const [key, value] of Object.entries(allOccupantsInfoFields)) {
            if(value === true) {
              if(key === "__site__" || key === "__facility__" || key === "__level__") {
                if(key === "__site__") this.isSitePeople = true;
                if(key === "__level__") this.isLevelPeople = true;
                if(key === "__facility__") this.isFacilityPeople = true;
                this.isSiteFacilityLevelPeople = true;
              }
            }
          }
          if(this.isSiteFacilityLevelPeople) addSiteFacilityLevelPeople();
            for (const [key, value] of Object.entries(allOccupantsInfoFields)) {
              if(value === true) addPair(key, key);
          }
        }else {
          addSFLDefault();
          addPeopleDetails();
        }
      }else {
        if(filterSection && filterSection.unassignedOccupantsInfo) {
          const unassignedOccupantsFields = filterSection.unassignedOccupantsInfo;
            for (const [key, value] of Object.entries(unassignedOccupantsFields)) {
              if(value === true) {
                  if(key === "__site__") {
                    this.isSitePeopleUnassigned = true;
                    this.isSiteFacilityLevelPeopleUnassigned = true;
                  }
              }
            }
            if(this.isSiteFacilityLevelPeopleUnassigned) addSiteFacilityLevelPeopleUnassigned();
            for (const [key, value] of Object.entries(unassignedOccupantsFields)) {
              if(value === true) addPair(key, key);
            }
        }else{
          addPair(FieldNames.SITE_NAME, i18nFields.site);
          addPeopleDetails();
        }
      }
    } else if (queryCriteria.sourceKey === "Units") {
      const filterSection = Context.instance.config.spaceplanner.filterColumns;
      if(queryCriteria.key === "spaceplanner/assetbar/UnitsPanel") {
        if(filterSection && filterSection.allUnitsInfo) {
          const allUnitsInfoFields = filterSection.allUnitsInfo;
            for (const [key, value] of Object.entries(allUnitsInfoFields)) {
              if(value === true) {
                if(key === "__site__" || key === "__facility__" || key === "__level__") {
                  if(key === "__site__") this.isSite = true;
                  if(key === "__level__") this.isLevel = true;
                  if(key === "__facility__") this.isFacility = true;
                  this.isSiteFacilityLevel = true;
                }
              }
            }
            if(this.isSiteFacilityLevel) addSiteFacilityLevel();
            for (const [key, value] of Object.entries(allUnitsInfoFields)) {
              if(value === true) addPair(key, key);
            }
        }else addSFLDefault();
      }else{
        if(filterSection && filterSection.unassignedUnitsInfo) {
          const unassignedUnitsInfoFields = filterSection.unassignedUnitsInfo;
            for (const [key, value] of Object.entries(unassignedUnitsInfoFields)) {
              if(value === true) {
                if(key === "__site__" || key === "__facility__" || key === "__level__") {
                  if(key === "__site__") this.isSiteUnassigned = true;
                  if(key === "__level__") this.isLevelUnassigned = true;
                  if(key === "__facility__") this.isFacilityUnassigned = true;
                  this.isSiteFacilityLevelUnassigned = true;
                }
              }
            }
            if(this.isSiteFacilityLevelUnassigned) addSiteFacilityLevelUnassigned();
            for (const [key, value] of Object.entries(unassignedUnitsInfoFields)) {
              if(value === true) addPair(key, key);
            }
        }else {
          addSFLDefault();
        }
      }
    }

    const sortedPairs = this.sortPairs(pairs);
    this._pairs = sortedPairs;

    this._siteFacilityLevel = {};
    if (queryCriteria) {
      queryCriteria._localPairs = this._pairs;
      queryCriteria._siteFacilityLevel = this._siteFacilityLevel;
    }
    return sortedPairs;
  }

  onPairChanged(params: IFilterPair) {
    const pairs = this._pairs;
    if (Array.isArray(pairs)) {
      pairs.some(pair => {
        if (params && params.id && (pair.id === params.id)) {
          if(params && (params.value || params.value === undefined)) pair.value = params.value;
          if(params && params.operator) pair.operator = params.operator;
          if (pair.id === "__sites__") {
            let sfl = this._siteFacilityLevel;
            sfl.activeSite = pair.value;
          } else if (pair.id === "__facilities__") {
            let sfl = this._siteFacilityLevel;
            sfl.activeFacility = pair.value;
          } else if (pair.id === "__levels__") {
            let sfl = this._siteFacilityLevel;
            sfl.activeLevel = pair.value;
          }
          return true;
        }
        return false;
      });
    }
  }

  sortPairs(pairs: IFilterPair[]) {
    // Sort pairs alphabetically
    const alphabeticalPairs = pairs.sort((a, b) => {
      const labelA = a.label;
      const labelB = b.label;
      return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
    });

    // Filter out site/facility/level and add to front
    const filteredPairs = alphabeticalPairs.filter((pair) => {
      const { id } = pair;
      return id !== "__sites__" && id !== "__facilities__" && id !== "__levels__";
    });
    const levelPair = pairs.find((pair) => pair.id === "__levels__");
    if (levelPair) {
      filteredPairs.unshift(levelPair);
    }
    const facilityPair = pairs.find((pair) => pair.id === "__facilities__");
    if (facilityPair) {
      filteredPairs.unshift(facilityPair);
    }
    const sitePair = pairs.find((pair) => pair.id === "__sites__");
    if (sitePair) {
      filteredPairs.unshift(sitePair);
    }

    return filteredPairs;
  }

}
