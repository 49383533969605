import React from "react";

import Context from "../../../../../context/Context";
import Rdx from "../../../../../redux/Rdx";
import { connect } from "react-redux";
import { getUndoRedoDisabled } from "../../../../components/Editor/redux";
import * as component from "../../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-action";
import {
  CalciteAction
} from "@esri/calcite-components-react";

interface Props {
  canUndo: boolean,
  canRedo: boolean,
  undoRedoDisabled: boolean
}

interface State {
}

class UndoRedo extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  redo = () => {
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan && plan.undoManager) {
      plan.undoManager.redo();
    }
  }

  undo = () => {
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan && plan.undoManager) {
      plan.undoManager.undo();
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const planner = Context.instance.spaceplanner.planner;
    const project = planner && planner.project;
    const canUndo = project && !this.props.undoRedoDisabled && this.props.canUndo;
    const canRedo = project && !this.props.undoRedoDisabled && this.props.canRedo;
    return (
      <div className="i-undo-redo-container" style={{display: "flex", flexDirection: "column"}}>
        <div className="esri-widget--button">
          <CalciteAction
            icon={"undo"}
            text={i18n.spaceplanner.undo}
            title={i18n.spaceplanner.undo}
            disabled={!canUndo || undefined}
            onClick={this.undo}
          />
        </div>
        <div className="esri-widget--button i--last">
          <CalciteAction
            icon={"redo"}
            text={i18n.spaceplanner.redo}
            title={i18n.spaceplanner.redo}
            disabled={!canRedo || undefined}
            onClick={this.redo}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    canUndo: !!Rdx.getValue(state,Rdx.Keys.PLAN_CAN_UNDO),
    canRedo: !!Rdx.getValue(state,Rdx.Keys.PLAN_CAN_REDO),
    undoRedoDisabled: !!getUndoRedoDisabled(state)
  }
}

export default connect(mapStateToProps)(UndoRedo);
