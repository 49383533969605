import Context from "../../../context/Context";
import moment from "moment";
import { IRecurrenceOptions } from "../More/Actions/BookWorkspace/BookingRecurrence";
export interface IDuration {
  duration: "thirtyMin" | "oneHour" | "oneAndAHalfHours" | "twoHours" | "twoAndAHalfHours" | "threeHours" | "allDay",
  end: { date: Date },
  recurrence: IRecurrenceOptions,
  start: { date: Date }
}
export interface IDurationAsMilliseconds {
  duration: IDuration["duration"],
  end: { date: number },
  recurrence: IRecurrenceOptions,
  start: { date: number }
}
export function addDuration(dtStart: Date, durationKey: IDuration["duration"]) {
  var m = moment(dtStart);
  if (durationKey === "thirtyMin") {
    m = m.add(30,"minute");
  } else if (durationKey === "oneHour") {
    m = m.add(1,"hour");
  } else if (durationKey === "oneAndAHalfHours") {
    m = m.add(90,"minute");
  } else if (durationKey === "twoHours") {
    m = m.add(2,"hour");
  } else if (durationKey === "twoAndAHalfHours") {
    m = m.add(150,"minute");
  } else if (durationKey === "threeHours") {
    m = m.add(3,"hour");
  }
  return m.toDate();
}

export function determineDurationKey(when: IDuration | IDurationAsMilliseconds) {
  return getDurationKey(new Date(when.start.date),new Date(when.end.date))
}

export function getDurationKey(dtStart: Date, dtEnd: Date) {
  let k = null;
  let m = moment.duration(dtEnd.getTime() - dtStart.getTime()).asMinutes();
  if (m === 30) {
    k = "thirtyMin";
  } else if (m === 60) {
    k = "oneHour";
  } else if (m === 90) {
    k = "oneAndAHalfHours";
  } else if (m === 120) {
    k = "twoHours";
  } else if (m === 150) {
    k = "twoAndAHalfHours";
  } else if (m === 180) {
    k = "threeHours";
  } else {
    let v1 = moment(dtStart).startOf("day").valueOf();
    let v2 = moment(dtEnd).endOf("day").valueOf();
    if (dtStart.getTime() === v1 && dtEnd.getTime() === v2) {
      k = "allDay";
    }
  }
  return k;
}

export function getMomentHourFormat(short) {
  let timeFormat = "H";
  let locale = Context.instance.lib.dojo.kernel.locale
  if (locale === "en" || locale === "en-us") {
    timeFormat = "h A"
    if (short) timeFormat = "h"
  }
  return timeFormat;
}

export function getMomentTimeFormat() {
  let timeFormat = "H:mm";
  let locale = Context.instance.lib.dojo.kernel.locale
  if (locale === "en" || locale === "en-us") timeFormat = "h:mm A"
  return timeFormat;
}

export function getTopOfHour() {
  var m = moment(new Date());
  //var roundDown = m.startOf("hour");
  if (m.minute() || m.second() || m.millisecond()) {
    m = m.add(1,"hour").startOf("hour")
  } else {
    m = m.startOf("hour");
  }
  return m.toDate();
}

export function getRangeClause(startField: string, endField: string, 
  dtStart: Date, dtEnd: Date) {
  const start = getZulu(dtStart);
  const end = getZulu(dtEnd);
  let clause = "(" + endField + " > TIMESTAMP '" + start + "'";
  clause += " AND " + startField + " < TIMESTAMP '" + end + "')";
  return clause;
}

export function getZulu(date: Date) {
  const lib = Context.getInstance().lib;
  const opts = {zulu: true};
  let v: string = lib.dojo.stamp.toISOString(date,opts);
  v = v.replace("T"," ").replace("Z","");
  return v;
}

export function isAllDay(startMillis: number, endMillis: number) {
  const startofDay =  moment(startMillis).startOf('day').toDate();
  const startOf = startofDay.getTime();
  const endDate = moment(endMillis-1).toDate();
  const endOf = endDate.getTime();
  const endDiff = (endOf - toEndOfDay(endDate).valueOf())
  const isStartMidnight = ((startMillis-startOf) === 0) ? true: false;
  const isEndMidnight =  Math.abs(endDiff) <= 60000 ? true: false;
  return isStartMidnight && isEndMidnight;
}

export function O365toDate(startDateTime, endDateTime) {
  // @ts-ignore
  const fromDate = new Date(moment(startDateTime + "Z"));
  // @ts-ignore
  const toDate = new Date(moment(endDateTime + "Z"));

  const fromDateTime = moment(startDateTime + "Z");
  const toDateTime = moment(endDateTime + "Z");

  return {
    fromDate,
    toDate,
    fromDateTime,
    toDateTime
  }
}

//get datetime milliseconds from a moment or Date object or ms number
export function getMsFromTimeObj(momentOrDateOrMs): number {
  if (!momentOrDateOrMs) {return null}
  return typeof momentOrDateOrMs.valueOf === 'function'
    ? momentOrDateOrMs.valueOf()
    : typeof momentOrDateOrMs.getTime === 'function'
      ? momentOrDateOrMs.getTime()
      : momentOrDateOrMs;
}

//takes the day part (e.g. 10/20/2022) from first datetime object (@dayPart - moment or Date or ms) and sets the time from the 2nd arg datetime object (@timePart)
export function joinDayAndTime(dayPart, timePart) {
  if (!dayPart || !timePart)
    return null;
  dayPart = typeof dayPart.valueOf === 'function' ? new Date(dayPart.valueOf()) : new Date(dayPart);
  if (typeof timePart.valueOf === 'function')
    timePart = new Date(timePart.valueOf());
  dayPart.setMilliseconds(timePart.getMilliseconds());
  dayPart.setSeconds(timePart.getSeconds());
  dayPart.setMinutes(timePart.getMinutes());
  dayPart.setHours(timePart.getHours());
  return dayPart.getTime();
}

export function toEndOfDay(date: Date) {
  let dt = toStartOfDay(date);
  dt.setDate(dt.getDate() + 1);
  dt = new Date(dt.getTime() - 1);
  return dt;
}

export function toStartOfDay(date: Date) {
  const dt = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  return dt;
}

export function formatDateFilter(dateFilter) {  
  const { criteria } = dateFilter || {};
  const { intl } = Context.getInstance().lib.esri;
  const a = intl.formatDate;
  const format = { dateStyle: "short" };
  if (!criteria) {
    return "";
  }
  switch (criteria.dateOption) {
    case "today":
      return a(moment(), format);
    case "tomorrow":
      return a(moment().add(1, "day"), format);
    case "thisweek":
      return `${a(moment(dateFilter.dateStart), format)} - ${a(moment(dateFilter.dateEnd), format)}`;
    case "thismonth":
      return a(moment(), { month: "long", year: "numeric" });
    case "pickdate":
      return a(moment(criteria.dateSelected), format);
    default:
      return "";
  }
}