import React, { Component } from "react";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as CrudReply from "../../base/transaction/reply";
import * as commentReplyUtil from "./commentReplyUtil";
import TextareaAutosize from 'react-textarea-autosize';
import { ButtonGroupInput, CharacterCount, EmptyInput, ThemeColorButton, ReplyWrapper } from "../../styles/commentsStyles";

export default class InputReply extends Component {

    total =  commentReplyUtil.getMaxCommentLength();

    constructor(props){
        super(props);
        this.state={
            inputChange : '',
            emptyReply: false,
            characterCount: 0
        }
    }

    handleSave = () => {
        const reply = this.state.inputChange.trim();
        const author = Context.instance.user.getUsername();
        const openPlanId = Context.instance.spaceplanner.planId;
        const commentId = this.props.id;
        const geometry = this.props.geometry;
        let obj;
        if(!this.state.inputChange) {
            this.setState({
                emptyReply : true
            })
        }else{
            if(geometry) {
                obj = {
                  reply: reply,
                  author: author,
                  planId: openPlanId,
                  commentId: commentId,
                  geometry: geometry
                }
              }else{
                obj = {
                  reply: reply,
                  author: author,
                  planId: openPlanId,
                  commentId: commentId
                }
              }
              const url = commentReplyUtil.getCommentUrl();
              CrudReply.addReply(obj, url).then((resp)=>{
                  Topic.publish(Topic.LoadComments, {});
                  this.props.closeReply();
                  this.props.changeMode("expandComment");
              })
              .catch(error=>{
                console.error(error);
                Topic.publishErrorUpdatingData(error.message);
              })
        }
      }

    handleInputChange =(evt)=> {
        let replyStr = evt.target.value;
        if(replyStr.length > this.total) return;
        replyStr = Context.sanitizeHtml(replyStr);
        this.setState({
            inputChange : replyStr,
            characterCount : replyStr.length
        },function(){
            if(!this.state.inputChange) {
                this.setState({
                    emptyReply : true
                })
            }else{
                this.setState({
                    emptyReply : false
                })
            }
        })
    }

    handleCancel=()=>{
        this.props.closeReply();
        Topic.publish(Topic.LoadComments);
    }

    render(){
        const i18n = Context.instance.i18n;
        let showError;
        if(this.state.emptyReply) showError = i18n.spaceplanner.reply.emptyReply;
        let pattern = i18n.item.categoryTypePattern;
        pattern = pattern.replace("{category}", this.state.characterCount);
        pattern = pattern.replace("{type}", this.total);
        if(this.props.reply==="reply"){
            return  (
                    <div style={{display:"flex", flexDirection: "column", flexShrink:0,  alignItems: "flex-end", background:"#fff", margin: "0.5rem", border:"1px solid #cacaca", padding:"0.5rem"}}>
                        <TextareaAutosize 
                            autoFocus
                            maxRows = {10}
                            value={Context.sanitizeHtml(this.state.inputChange)}
                            style={{width:"97%", borderColor:"#cacaca", padding:"5px", fontFamily: "sans-serif", fontSize: "0.833rem", color:"#323232",}} minRows={2} onChange={this.handleInputChange}/>
                        <EmptyInput>{showError}</EmptyInput>
                        <ButtonGroupInput>
                            <CharacterCount>{pattern}</CharacterCount>
                            <div>
                                <ThemeColorButton onClick={this.handleSave}>{i18n.general.save}</ThemeColorButton>
                                <ThemeColorButton onClick={this.handleCancel}>{i18n.general.cancel}</ThemeColorButton>
                            </div>
                        </ButtonGroupInput>
                    </div>
                )
        }else{
            return  (
                <ReplyWrapper>
                    <div style={{display:"flex", flexDirection: "column", flexShrink:0,  alignItems: "flex-end", background:"#fff", margin: "0.5rem", border:"1px solid #cacaca", padding:"0.5rem"}}>
                        <TextareaAutosize 
                            autoFocus
                            maxRows = {10}
                            value={Context.sanitizeHtml(this.state.inputChange)}
                            style={{width:"97%", borderColor:"#cacaca", padding:"5px", fontFamily: "sans-serif", fontSize: "0.833rem", color:"#323232",}} minRows={2} onChange={this.handleInputChange}/>
                        <EmptyInput>{showError}</EmptyInput>
                        <ButtonGroupInput>
                            <CharacterCount>{pattern}</CharacterCount>
                            <div>
                                <ThemeColorButton onClick={this.handleSave}>{i18n.general.save}</ThemeColorButton>
                                <ThemeColorButton onClick={this.handleCancel}>{i18n.general.cancel}</ThemeColorButton>
                            </div>
                        </ButtonGroupInput>
                    </div>
                </ReplyWrapper>
            )

        }

    }
}