// Framework and third-party non-ui
import React from "react";
import ReactDOM from "react-dom";

// Redux operations and local helpers/utils/modules
import { Provider } from "react-redux";
import { initStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

// Component specific modules (Component-styled, etc.)
import CalciteThemeProvider from "calcite-react/CalciteThemeProvider";
import { Theme, updateCalciteReactTheme } from "./theme";
import { GlobalStyle } from "./globalStyle";

// App components
import App from "./components/main/App/App";
import Context from "./context/Context";
import SpacePlannerApp from "./spaceplanner/components/App/App";

// Third-party components (buttons, icons, etc.)
import { ToastContainer } from "calcite-react/Toaster";

// calicte components
import '@esri/calcite-components';
import { setAssetPath } from '@esri/calcite-components/dist/components';
import '@esri/calcite-components/dist/calcite/calcite.css';
// Local assets
// setAssetPath(window.location.href);
// CDN hosted assets
// setAssetPath('https://js.arcgis.com/calcite-components/1.3.1/assets');
let locationPath = window.location.pathname.replace(/index.html$/i,"");
locationPath = locationPath.replace(/kiosk.html$/i,"");
if (!locationPath.endsWith("/")) locationPath += "/";
const assetPath = window.location.origin + locationPath + "calcite/assets";
setAssetPath(assetPath);
//console.log("calcite asset path",assetPath);

if (!window.xtnBrowserNotSupported) {
  initContext().then(() => {
    start();
  }).catch(ex => {
    console.warn("Error starting Indoors app");
    console.error(ex); // TODO??
  });
};

function initContext() {
  const promise = new Promise((resolve,reject) => {
    const context = Context.getInstance();
    context._init().then(() => {
      if (Context.instance.appMode.isSP_or_FPE()) {
        return import("./spaceplanner/scss/app.scss");
      } else {
        return import("./index.scss")
      }
    }).then(() => {
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

function start() {
  const store = initStore();

  let app, globalStyle = null;
  if (Context.instance.appMode.isSP_or_FPE()) {
    globalStyle = <GlobalStyle/>;
    app = <SpacePlannerApp version={Context.getInstance().config.spaceplannerVersion} />;
  } else {
    app = <App store={store} />;
  }

  const isRTL = Context.getInstance().uiMode.isRtl;
  updateCalciteReactTheme(Context.instance);

  ReactDOM.render(
    <Provider store={store}>
      <CalciteThemeProvider theme={Theme}>
        {globalStyle}
        <ToastContainer rtl={isRTL}/>
        {app}
      </CalciteThemeProvider>
    </Provider>
    ,document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
