import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import Source from "../base/Source";
import * as aiimUtil from "../util/aiimUtil";
import * as selectionUtil from "../util/selectionUtil";

export default class Categories extends Dataset {

  sources = [];
  type = "table";

  catLevelField = "category_level";
  iconUrlField = "icon_url";
  layerField = "layer";
  nameField = "name";
  objectIdField = "objectid";

  _sourceKeys;
  _sourcesByKey;

  _checkSchemas() {
    const promises = [];
    this.sources.forEach(source => {
      const p = source.checkSchema();
      if (p) {
        promises.push(p);
        p.then(() => {
          this._querySubTypes(source);
        });
      }
    });
    if (promises.length > 0) {
      // TODO use Promise.all ?
      return Promise.all(promises).then((results) => {
        //console.log("sources =.=.=.=.=.=",this.sources); // TODO temporary
      });
    } else {
      return Promise.resolve();
    }
  }

  _fill(source) {

    // Units is not in the categories table

    /*
    mainLinkingField, uniqueIdField, displayFields, displayScaleField, refLocField,
    subCategoryField, outFields
    aiimType
    url
    url3D
    icon
    displayTemplate
     */

    const info = this._getFillInfo(source.name);
    if (info) {
      Object.keys(info).forEach(key => {
        source[key] = info[key];
      });
    }

  }

  _fillSources() {
    aiimUtil.makeReservationsSource(this);

    const cfg = Context.getInstance().config.categories;
    if (cfg && cfg.fill) {
      Object.keys(cfg.fill).forEach(key => {
        let mixin = false;
        let info = cfg.fill[key];
        if (info && this._isFillable(info)) {
          let source = this.findSourceByKey(key);
          if (!source) {
            mixin = true;
            source = new Source({key: key});
            this.sources.push(source);
          } else {
            mixin = true; // TODO?????
          }
          if (mixin && info) {
            source.mixin(info);
          }
        }
      });
    };
  }

  _getFillInfo(key) {
    const cfg = Context.getInstance().config.categories;
    if (cfg && cfg.fill) {
      let info = cfg.fill[key];
      if (info && this._isFillable(info)) return info;
    }
  }

  findSourceByKey(key) {
    if (this._sourcesByKey && this._sourcesByKey[key]) {
      return this._sourcesByKey[key];
    }
    let found = null;
    this.sources.some(source => {
      if (source.key === key) {
        found = source;
        return true;
      }
      return false;
    });
    return found;
  }

  findSourceByLayer(layer) {
    if (layer && layer.xtnAiim && layer.xtnAiim.source) {
      return layer.xtnAiim.source;
    }
    if (layer && layer.xtnAiim && layer.xtnAiim.cimSource) {
      return layer.xtnAiim.cimSource;
    }
    let found = null;
    this.sources.some(source => {
      if (layer && (source.layer2D === layer || source.layer3D === layer ||
          source.subLayer === layer)) {
        found = source;
        return true;
      }
      return false;
    });
    return found;
  }

  findSourceByName(name) {
    let found = null;
    const lc = name.toLowerCase();
    if (lc.length > 0) {
      this.sources.some(source => {
        if (source.name && source.name.toLowerCase() === lc) {
          found = source;
          return true;
        }
        return false;
      });
    }
    return found;
  }

  _isFillable(fillInfo) {
    if (fillInfo) {
      if (Array.isArray(fillInfo.appTypes)) {
        const appType = Context.instance.appMode.appType;
        const fillable = fillInfo.appTypes.some(v => {
          return (v === appType);
        });
        return fillable;
      } else {
        return true;
      }
    }
    return false;
  }

  _isPeopleLayer(layer) {
    let aiim = Context.instance.aiim;
    if (layer && aiim && aiim.datasets && aiim.datasets.people) {
      return (layer === aiim.datasets.people.layer2D);
    }
    return false;
  }

  load(url,cimCategories) {
    if (cimCategories && cimCategories.hasData()) {
      this.sources = cimCategories.sources;
      this._sourcesByKey = cimCategories._sourcesByKey;
      return Promise.resolve();
    }
    url = this.url = Context.checkMixedContent(url);
    return this._queryCategories().then(() => {
      this._fillSources();

      // const additional = Context.getInstance().config.additionalSources;
      // const hasAdditional = (Array.isArray(additional) && additional.length > 0);
      this.sources.forEach(source => {
        // if (source.dataLayerType && hasAdditional) {
        //   let v = source.dataLayerType;
        //   additional.some(src => {
        //     if (src.identifier) {
        //       let v2 = src.identifier.dataLayerType;
        //       if (typeof v === "string" && v.length > 0 && typeof v2 === "string") {
        //         if (v.toLowerCase() === v2.toLowerCase()) {
        //           const keep = {name: source.name, key: source.key, _usedAdditional: true};
        //           source.mixin(src).mixin(keep);
        //           return true;
        //         }
        //       }
        //     }
        //     return false;
        //   });
        // }
        if (source.workOrderMappings) {
          if (!source.iconUrl) source.iconUrl = "assets/workorder.png"
        }
      });

      this._processView(Context.getInstance().views.mapView);
      this._processView(Context.getInstance().views.sceneView);

      //console.log("sources",this.sources);
      //console.log("layers",aiimUtil.getLayers(Context.getInstance().views.mapView));

      const sourceKeys = [], sourcesByKey = {};
      this.sources.forEach((source,idx) => {
        source.index = idx;
        sourceKeys.push(source.key);
        sourcesByKey[source.key] = source;
      });
      this._sourceKeys = [];
      this._sourcesByKey = sourcesByKey;

      return this._checkSchemas();
    }).catch(ex => {
      console.warn("Error creating CATEGORIES:");
      console.error(ex);
    })
  }

  _loadIcon(objectId,source?) {
    const url = this.url + "/" + objectId + "/attachments";
    const options = {query: {f: "json"}, responseType: "json"};
    //console.log("loadIcon",objectId,url);
    const promise = Context.getInstance().lib.esri.esriRequest(url,options);
    return promise.then(result => {
      //console.log("loadIcon.result",result);
      const attachments = [];
      if (result && result.data && result.data.attachmentInfos) {
        result.data.attachmentInfos.forEach(info => {
          if (typeof info.contentType === "string" &&
              info.contentType.substr(0,5) === "image") {
            attachments.push(info);
          }
        });
      }
      // get the last attachment, there are multiple icons with different sizes
      attachments.sort((a, b) => a.name.localeCompare(b.name));
      if (attachments.length > 0) {
        //console.log(url);
        const info = attachments[attachments.length - 1];
        const iconUrl = url + "/" + info.id;
        return Promise.resolve({iconUrl: iconUrl});
      }
    }).catch(ex => {
      console.warn("Error querying category icon:",url);
      console.error(ex);
    });
  }

  loadSubTypeIcon(source,subType) {
    const layerName = source.name.replace("'","''");;
    const subTypeName = subType.name.replace("'","''");;
    const url = this.url;
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = [this.objectIdField,this.iconUrlField];
    query.where = "("+this.catLevelField+" = 2)";
    query.where += " AND ("+this.layerField+" = '"+layerName+"')";
    query.where += " AND ("+this.nameField+" = '"+subTypeName+"')";
    return task.execute(query).then(result => {
      if (result && result.features && result.features.length > 0) {
        const attributes = result.features[0].attributes
        const oid = aiimUtil.getAttributeValue(attributes,this.objectIdField);
        const iconUrl = aiimUtil.getAttributeValue(attributes,this.iconUrlField);
        if (iconUrl) {
          return Promise.resolve({iconUrl: iconUrl});
        } else {
          return this._loadIcon(oid,source).then(iconResult => {
            if (iconResult && iconResult.iconUrl) {
              return iconResult;
            }
          });
        }
      } else {
        return null;
      }
    });
  }

  _makeSearchSource(layer, source, forDirections) {
    if (!layer) return null;

    // Indoors@Esri: Search results are limited to 10 #6134
    const isPeopleLayer = !!this._isPeopleLayer(source.layer2D);
    const max = isPeopleLayer ? 25 : 10;

    const searchSource: __esri.LayerSearchSourceProperties & { 
      aiimKey: string,
      popupOpenOnSelect: boolean,
      suggestQueryParams?: Partial<__esri.Query>,
      searchQueryParams?: Partial<__esri.Query>
    } = {
      aiimKey: source.key,
      layer: layer,
      name: source.name,
      placeholder: source.name,
      outFields: source.out_fields || ["*"],
      searchFields: source.searchFields,
      displayField: source.displayField,
      suggestionTemplate: source.suggestionTemplate,
      exactMatch: false,
      maxResults: max,
      maxSuggestions: max,
      suggestionsEnabled: true,
      minSuggestCharacters: 0,
      autoNavigate: false,
      popupEnabled: false,
      popupOpenOnSelect: false,
      resultGraphicEnabled: false
    };

    let orderByFields = null;
    if (Array.isArray(source.suggestionOrderByFields) &&
        source.suggestionOrderByFields.length > 0) {
      orderByFields = source.suggestionOrderByFields;
    } else if (source.displayField && source.displayField.length > 0) {
      orderByFields = [source.displayField];
    }
    if (Array.isArray(orderByFields) && orderByFields.length > 0) {
      // *** suggestQueryParams may be deprecated in a futire release
      // https://devtopia.esri.com/WebGIS/arcgis-js-api/blob/4master/esri/widgets/Search/support/layerSearchUtils.ts
      searchSource.suggestQueryParams = {
        orderByFields: orderByFields
      };
      searchSource.searchQueryParams = {
        orderByFields: orderByFields
      }
    }

    const orig = source.layer2D && source.layer2D.xtnOriginalDefinitionExpression;
    const expr = source.layer2D && source.layer2D.definitionExpression;
    if (typeof orig === "string" && orig.length > 0) {
      if (orig !== "__none__") searchSource.filter = {where: orig};
    } else if (typeof expr === "string" && expr.length > 0) {
      searchSource.filter = {where: expr};
    }
    if (source.cimCategory && source.cimCategory.filterExpression) {
      if (!searchSource.filter) {
        searchSource.filter = {where: layer.definitionExpression};
      } else {
        searchSource.filter.where = layer.definitionExpression
      }
      if (layer.title) {
        searchSource.name = layer.title
        searchSource.placeholder = layer.title
      }
    }
    if (forDirections && source.isAiimPeople()) {
      // let fields = source.layer2D && source.layer2D.fields;
      // let fld = aiimUtil.findField(fields,FieldNames.PEOPLE_UNIT_ID);
      // if (fld) {
      //   let exp = fld.name + " IS NOT NULL";
      //   if (!searchSource.filter) {
      //     searchSource.filter = {where: exp};
      //   } else {
      //     searchSource.filter.where = "("+exp+") AND ("+searchSource.filter.where+")";
      //   }
      // }
    }

    return searchSource;
  }

  _makeSearchSourceLayer(source) {
    if (source.layer2D) {
      let url = source.layer2D.url+"/"+source.layer2D.layerId;
      url = Context.checkMixedContent(url);
      const lib = Context.getInstance().lib;
      const layer = new lib.esri.FeatureLayer({
        url: url,
        outFields: ["*"], // TODO source.out_fields ?
        returnZ: true
      });
      return layer;
    }
  }

  _makeSearchSourceLayers(sources) {
    let layers = {}
    let featureLayers = {}
    const lib = Context.getInstance().lib;
    sources.forEach(source => {
      if (source.cimCategory) {
        if (source.layer2D) {
          let url = source.layer2D.url+"/"+source.layer2D.layerId;
          url = Context.checkMixedContent(url);
          if (source.cimCategory && source.cimCategory.filterExpression) {
            let categoryGrpId = this.getCategoryGroupInfo(source.cimCategory).id;
            let categoryGrpName = this.getCategoryGroupInfo(source.cimCategory).name;
            const id = categoryGrpId+"-"+source.layer2D.url+"/"+source.layer2D.layerId;
            let exp = "(" + source.cimCategory.filterExpression + ")"
            if (!layers[id]) {
              let t = categoryGrpName || source.layer2D.title;
              if (categoryGrpName  && categoryGrpName !== source.layer2D.title) {
                t = categoryGrpName + " (" + source.layer2D.title + ")"
              }
              //console.log(t,source.cimCategory.filterExpression,source)
              layers[id] = {
                title: t,
                url: url,
                definitionExpression: exp
              }
            } else {
              let expression = layers[id].definitionExpression + " OR " + exp
              layers[id] = {
                ...layers[id],
                definitionExpression: expression
              }
            }
          }
        }
      }
    })
    Object.keys(layers).forEach(id => {
      const featureLayer = new lib.esri.FeatureLayer({
        title: layers[id].title,
        url: layers[id].url,
        definitionExpression: layers[id].definitionExpression,
        outFields: ["*"],
        returnZ: true
      })
      featureLayers[id] = featureLayer
    })
    return featureLayers
  }

  makeSearchSources(forDirections) {
    let layerSources = {};
    let searchSources = [];
    let nonCIMSearchSources = [];
    if (this.sources) {
      const layers = this._makeSearchSourceLayers(this.sources)
      this.sources.forEach(source => {
        // Grab the first source from the layer and make a searchSource for CIM (Optimization #2515)
        if (source.cimCategory) {
          const layerId = source.layer2D && source.layer2D.layerId
          const layerUrl = source.layer2D && source.layer2D.url
          let categoryGrpId = this.getCategoryGroupInfo(source.cimCategory).id;
          const sourceLayerId = categoryGrpId + "-" + layerUrl + "/" + layerId
          if (!layerSources[sourceLayerId]) {
            let layer = layers[sourceLayerId]
            if (!layer) layer = this._makeSearchSourceLayer(source)
            layerSources[sourceLayerId] = this._makeSearchSource(layer, source, forDirections)
          }
        } else if (!source.isReservations()) {
          let layer = this._makeSearchSourceLayer(source)
          const searchSource = this._makeSearchSource(layer, source, forDirections)
          if (searchSource) nonCIMSearchSources.push(searchSource)
        }
      })
      Object.keys(layerSources).forEach(key => {
        // searchSources.unshift(layerSources[key])
        searchSources.push(layerSources[key])
      })
      nonCIMSearchSources.forEach((searchSource)=> {
        searchSources.push(searchSource);
      })

    }

    const find = key => {
      let found;
      this.sources.some(source => {
        if (source.key === key) found = source;
        return !!found;
      });
      return found;
    }

    if (searchSources.length > 1) {
      // Don't duplicate sources defined in Pro (CIM) and config.js
      let keys = ["Units","Levels","Facilities","Sites"];
      let rm = [];
      searchSources.forEach(ss => {
        let key = ss.aiimKey;
        if (keys.indexOf(key) !== -1) {
          let sourceFromCIM = find("_"+key);
          if (sourceFromCIM) {
            let sourceFromConfig = find(key);
            if (sourceFromConfig &&
                sourceFromCIM.layer2D === sourceFromConfig.layer2D) {
              rm.push(key);
            }
          }
        }
      });
      if (rm.length > 0) {
        searchSources = searchSources.filter(ss => {
          return (rm.indexOf(ss.aiimKey) === -1);
        });
      }
    }

    //console.log("Categories::makeSearchSources",searchSources);
    return searchSources;
  }

  _makeSubType(source,info) {
    if (!source.subTypes) source.subTypes = [];
    if (!source.subTypesByValue) source.subTypesByValue = {};
    const subType = {
      name: info.name,
      value: info.value,
      iconPromise: null,
      iconUrl: info.iconUrl,
      subTypeIcon: null,
    }
    if (info.considerWhere) {
      // both Work
      //   "https://www.example.com/abc?where=field='abcdefg'"
      //   "https://www.example.com/abc?where=field%3D%27abcdefg%27"
      const url = info.url;
      if (typeof url === "string" && url.length > 0) {
        const lib = Context.getInstance().lib;
        const obj = lib.esri.urlUtils.urlToObject(url);
        if (obj && obj.query) {
          let where = obj.query.where;
          if (typeof where === "string" && where.length > 0) {
            if (where.indexOf("{user.username}") > 0) {
              let u = Context.instance.user.getUsername();
              if (typeof u !== "string") u = "";
              if (typeof u === "string" && u.length > 0) {
                where = where.replace("{user.username}",selectionUtil.escSqlQuote(u));
              }
            }
            //@ts-ignore
            subType.where = where;
          }
        }
      }
    }
    source.subTypes.push(subType);
    source.subTypesByValue[subType.value] = subType;
    if (!subType.iconUrl) {
      try {
        subType.iconPromise = this.loadSubTypeIcon(source,subType);
        subType.iconPromise.then(iconResult => {
          subType.iconPromise = null;
          if (iconResult && iconResult.iconUrl) {
            subType.iconUrl = iconResult.iconUrl;
            //subType.iconUrl = aiimUtil.appendTokenToUrl(subType.iconUrl);
          }
        }).catch(ex => {
          subType.iconPromise = null;
          console.warn("Error loading subtype icon:");
          console.error(ex);
        });
      } catch(ex) {
        console.warn("Error loading subtype icon:");
        console.error(ex);
      }
    }
  }

  getCategoryGroupInfo(cimCategory){
    let id = "top", name=null;
    if(cimCategory.xtn && cimCategory.xtn.parentCategory && !(cimCategory.xtn.parentCategory.isRoot)){
      id = cimCategory.xtn.parentCategory.id;
      name = cimCategory.xtn.parentCategory.name;
      if (cimCategory.filterExpression) id = "exp-"+id;
    }
    return {id:id, name:name}
  }

  _processView(view) {
    if (!view) return;
    //const is2D = (view.type === "2d");
    const is3D = (view.type === "3d");
    const layers = aiimUtil.getLayers(view);

    const potentialSources = Context.getInstance().config.additionalSources;
    const hasPotentialSources = (Array.isArray(potentialSources) && potentialSources.length > 0);
    const getPotentialSource = (layer) => {
      let source = null;
      if (hasPotentialSources && layer) {
        potentialSources.some(src => {
          if (src.identifier) {
            let vs, matched = false;
            if (!matched) {
              vs = src.identifier.startsWith;
              if (!Array.isArray(vs)) vs = [vs];
              vs.some(v => {
                if (typeof v === "string" && v.length > 0) {
                  if (typeof layer.title === "string" &&
                      layer.title.toLowerCase().indexOf(v.toLowerCase()) === 0) {
                    matched = true;
                  }
                }
                return matched;
              });
            }
            if (!matched) {
              const layerDefinition = (layer.source && layer.source.layerDefinition);
              vs = src.identifier.description;
              if (!Array.isArray(vs)) vs = [vs];
              vs.some(v => {
                if (typeof v === "string" && v.length > 0) {
                  const v2 = layerDefinition && layerDefinition.description;
                  if (typeof v2 === "string" && v.toLowerCase() === v2.toLowerCase()) {
                    matched = true;
                  }
                }
                return matched;
              });
            }
            if (matched) {
              let key = layer.title; // TODO **************
              if (layer.title === "Occupants") key = "People";
              source = new Source();
              source.mixin(src);
              source.mixin({
                key: key,
                name: layer.title
              });

              // const found = this.findSourceByKey(key) ;
              // console.log("**** matched",layer.title,found);
              // if (found) {
              //   if (is3D && found.layer2D) {
              //     source = found;
              //   }
              // } else {
              //   source = new Source();
              //   source.mixin(src);
              //   source.mixin({
              //     key: key,
              //     name: layer.title
              //   });
              // }

            }
          }
          return !!source;
        });
      }
      return source;
    };

    layers.forEach(layer => {
      let matchedSource = null;
      let potentialSource = getPotentialSource(layer);
      this.sources.some(source => {
        if (!matchedSource) {
          if (source.name === layer.title) {
            matchedSource = source;
          } else if (source.name === "Facilities" && layer.title === "Facilities Textured") {
            matchedSource = source;
          } else if (source.name === "Units" && layer.title === "Units3D") {
            matchedSource = source;
          }
        }
        return !!matchedSource;
      });
      if (matchedSource) {
        //console.log(matchedSource.name,potentialSource);
        if (potentialSource && !matchedSource._usedAdditional) {
          //console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzz",layer.title);
          const keep = {name: matchedSource.name, key: matchedSource.key};
          matchedSource.mixin(potentialSource);
          matchedSource.mixin(keep);
        }
      } else if (potentialSource) {
        const found = this.findSourceByKey(potentialSource.key);
        if (is3D && found && found.layer2D) {
          matchedSource = found;
        } else {
          matchedSource = potentialSource;
          if (!found) this.sources.push(matchedSource);
        }
        //const found = this.findSourceByKey(matchedSource.key) ;
        //if (!found) this.sources.push(matchedSource);
      }
      if (matchedSource) {
        this._setSourceLayer(matchedSource,view,layer);
        //console.log("layer set",layer.title,layer);
      }

      // this.sources.some(source => {
      //   // TODO need a better foreign key than layer.title
      //   let matched = false;
      //   // if (source.identifier) {
      //   //   let v = source.identifier.description;
      //   //   if (typeof v === "string" && v.length > 0) {
      //   //     let v2 = layerDefinition && layerDefinition.description;
      //   //     if (v === v2) matched = true;
      //   //     if (matched) console.log("**** matched",layer.title,source.name);
      //   //   }
      //   // }
      //   if (!matched) {
      //     if (source.name === layer.title) {
      //       matched = true;
      //     } else if (source.name === "Facilities" && layer.title === "Facilities Textured") {
      //       matched = true;
      //     } else if (source.name === "Units" && layer.title === "Units3D") {
      //       matched = true;
      //     }
      //   }
      //   if (matched) {
      //     if (is2D && source.layer2D) {
      //       matched = false;
      //       console.log("Denying match for",layer.title);
      //       if (source.canDuplicate) {
      //
      //       }
      //     }
      //   }
      //   if (matched) {
      //     this._setSourceLayer(source,view,layer);
      //     //console.log("layer set",layer.title,layer);
      //   }
      //   return false;
      // });


    });
  }

  _queryCategories() {
    if (!Context.instance.appMode.supportsCategories()) {
      return Promise.resolve();
    }
    const url = this.url;
    if (typeof url !== "string" || url.length === 0) {
      console.error("Error: Missing CATEGORIES table.");
      return Promise.resolve();
    }
    return aiimUtil.readServiceJson(url).then(tableInfo => {
      if (tableInfo && tableInfo.data) {
        if (tableInfo.data.objectIdField) {
          this.objectIdField = tableInfo.data.objectIdField;
        }
        if (tableInfo.data.fields) {
          const flds = tableInfo.data.fields;
          this.catLevelField = aiimUtil.findFieldName(flds,this.catLevelField);
          this.iconUrlField = aiimUtil.findFieldName(flds,this.iconUrlField);
          this.layerField = aiimUtil.findFieldName(flds,this.layerField);
          this.nameField = aiimUtil.findFieldName(flds,this.nameField);
        }
      }
    }).then(() => {
      const lib = Context.getInstance().lib;
      const task = new lib.esri.QueryTask({url: url});
      const query = new lib.esri.Query();
      query.outFields = ["*"];
      query.orderByFields = [this.nameField];
      //query.where = this.catLevelField + " = 1";
      query.where ="1=1";
      return task.execute(query).then(result => {
        //console.log("Categories::load result",result);
        const sourcesByLayerKey = {};
        if (result && result.features && result.features.length > 0) {
          //console.log("Categories::load.result",result);
          result.features.forEach(feature => {
            const attributes = feature.attributes;
            let categoryLevel = aiimUtil.getAttributeValue(attributes,"category_level");
            if (categoryLevel === 1) {
              //console.log("Categories Level 1 row",attributes);
              let layerKey = aiimUtil.getAttributeValue(attributes,"layer");
              const source: Source = new Source({
                fromCategoriesTable: true,
                name: aiimUtil.getAttributeValue(attributes,"name"), // TODO this seems to be the only forein key to a layer
                categoryLevel: aiimUtil.getAttributeValue(attributes,"category_level"),
                iconUrl: aiimUtil.getAttributeValue(attributes,"icon_url"),
                /*url: aiimUtil.getAttributeValue(attributes,"url"), */ // TODO: this is incorrect
                /*layerid: aiimUtil.getAttributeValue(attributes,"layer_id"), */
                /*location: aiimUtil.getAttributeValue(attributes,"location"),*/
                subCategoryField: aiimUtil.getAttributeValue(attributes,"child_field"),
                searchFields: aiimUtil.getAttributeValue(attributes,"search_fields"),
                suggestionTemplate: aiimUtil.getAttributeValue(attributes,"suggestion_template"),
                layer2D: null,
                layer3D: null
              });
              //console.log("source.searchFields",source.name,source.searchFields);
              //console.log("source.suggestionTemplate",source.name,source.suggestionTemplate);
              source.key = source.name; // TODO need a better key
              if (source.key === "Occupants") source.key = "People";
              if (source.name === "Events") return; // TODO is this temporary

              if (Context.instance.appMode.isSP_or_FPE()) {
                let hasKey = this.sources.some(src => {return src.key === source.key;});
                if (hasKey) return;
              }

              this.sources.push(source);
              this._fill(source);

              //console.log(source.name,layerKey,source)

              let v0 = aiimUtil.getAttributeValue(attributes,"config");
              let v1 = aiimUtil.getAttributeValue(attributes,"layer_id");
              let v2 = aiimUtil.getAttributeValue(attributes,"location");
              let v3 = aiimUtil.getAttributeValue(attributes,"url");
              let v4 = aiimUtil.getAttributeValue(attributes,"child_field");
              let v5 = aiimUtil.getAttributeValue(attributes,"parent");
              let a = [v0,v1,v2,v3,v4,v5];

              a.forEach(v => {
                if (typeof v === "string" && v.startsWith("{") && v.endsWith("}")) {
                  try {
                    //console.log("*****",source.name,v)
                    source.mixin(JSON.parse(v));
                  } catch(exj) {
                    console.warn("Error parsing Categories field json",v);
                  }
                }
              });

              if (!source.iconUrl) {
                const oid = aiimUtil.getAttributeValue(attributes,this.objectIdField);
                this._loadIcon(oid).then(iconResult => {
                  //console.log("loadIcon.result.2",iconResult);
                  if (iconResult && iconResult.iconUrl) {
                    source.iconUrl = iconResult.iconUrl;
                  }
                });
              }
              if (layerKey) {
                sourcesByLayerKey[layerKey] = source;
              }
            }
          });

          result.features.forEach(feature => {
            const attributes = feature.attributes;
            let categoryLevel = aiimUtil.getAttributeValue(attributes,"category_level");
            if (categoryLevel !== 1) {
              let layerKey = aiimUtil.getAttributeValue(attributes,"layer");
              let source = sourcesByLayerKey[layerKey];
              if (source) {
                //console.log("Categories Level 2 row",source.name,attributes);
                if (!source.subCategoryField) {
                  let name = aiimUtil.getAttributeValue(attributes,"name");
                  let url = aiimUtil.getAttributeValue(attributes,"url");
                  let iconUrl = aiimUtil.getAttributeValue(attributes,"icon_url") || null;
                  this._makeSubType(source, {
                    name: name,
                    value: name,
                    url: url,
                    iconUrl: iconUrl,
                    considerWhere: true
                  });
                };
              }
            }
          });

        } else {
          console.warn("Warning: The CATEGORIES table is empty.");
        }
      });
    }).catch(ex => {
      console.warn("Error querying CATEGORIES table:");
      console.error(ex);
    });
  }

  _querySubTypes(source): Promise<void> {
    if (!source.subCategoryField) return Promise.resolve();
    if (!source.url) return Promise.resolve();
    const field = source.subCategoryField;
    const url = Context.checkMixedContent(source.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.returnGeometry = false;
    query.outFields = [field];
    query.returnDistinctValues = true;
    query.where = "1=1";
    query.orderByFields = [field];
    source.subTypesPromise = task.execute(query);
    source.subTypesPromise.then(result => {
      if (result && result.features && result.features.length > 0) {
        result.features.forEach(feature => {
          let ok = aiimUtil.hasAttribute(feature.attributes,field);
          if (ok) {
            let name = aiimUtil.getAttributeValue(feature.attributes,field);
            if (typeof name !== "string") name = "";
            this._makeSubType(source, {
              name: name,
              value: name
            });
          }
        });
      }
    }).catch(ex => {
      console.warn("Error querying category sub-types:");
      console.error(ex);
    });
  }

  _setSourceLayer(source, view, layer) {
    if (!layer.xtnAiim) {
      layer.xtnAiim = {};
    }
    layer.xtnAiim.source = source;
    if (view.type === "2d") {
      if (layer && layer.declaredClass === "esri.layers.support.Sublayer") {
        let layer2D = layer.xtnFeatureLayer;
        if (!layer2D) {
          layer2D = layer.createFeatureLayer();
          layer2D.load();
        }
        source.subLayer = layer;
        source.url = layer.url;
        source.layer2D = layer2D;
        if (!source.layer2D.xtnAiim) {
          source.layer2D.xtnAiim = layer.xtnAiim;
        }
      } else {
        source.url = layer.url+"/"+layer.layerId;
        source.layer2D = layer;
      }
    } else if (view.type === "3d") {
      source.layer3D = layer;
    }
  }

}
