import styled from "styled-components";

import { MenuItem as CalciteMenuItem } from "calcite-react/Menu";

const Item = styled(CalciteMenuItem)`
  pointer-events: ${props => (props.checked ? "none" : "auto")};
  background: ${props =>
    props.checked ? props.theme.palette.themeColorHighlight : "inherit"};

  & span {
    display: flex;
    align-items: center;
  }

  & svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${props =>
      props.checked
        ? props.theme.palette.themeColorHighlight
        : props.theme.palette.lightestGray};
  }
`;

export { Item };
