import React from "react";

import Context from "../../../context/Context";
import defaultIcon from "../../../assets/category-default.svg";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";

const CSS = {
  categoryIcon: "i-category-icon",
  itemIcon: "i-feature-item-icon"
};

export default class SubTypeIcon extends React.Component {

  _mounted = false;

  constructor(props) {
    super(props);
    const info = this.getIconInfo();
    this.state = component.newState(info);
  }

  componentDidMount() {
    this._mounted = true;
    if (this.state.waitForPromise) {
      const source = aiimUtil.getSource(this.props.sourceKey);
      if (source) {
        const subType = source.findSubTypeByValue(this.props.subTypeValue);
        if (subType && subType.iconUrl) {
          this.setState(state => {
            return {
              waitForPromise: false,
              iconUrl: aiimUtil.appendTokenToUrl(subType.iconUrl)
            };
          });
        } else if (subType && subType.iconPromise) {
          console.warn("SubTypeIcon waitingForPromise",subType);
          subType.iconPromise.then(result => {
            if (result && result.iconUrl && this._mounted) {
              this.setState(state => {
                return {
                  waitForPromise: false,
                  iconUrl: aiimUtil.appendTokenToUrl(result.iconUrl)
                };
              });
            }
          }).catch(ex => {
            // message is handled in app/aiim/datasets/Categories
          });
        }
      }
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  getIconInfo() {
    const info = {
      waitForPromise: false,
      iconUrl: this.props.iconUrl
    };
    if (!info.iconUrl) {
      const source = aiimUtil.getSource(this.props.sourceKey);
      if (source) {
        const subType = source.findSubTypeByValue(this.props.subTypeValue);
        if (subType && subType.iconUrl) {
          info.iconUrl = aiimUtil.appendTokenToUrl(subType.iconUrl);
        } else {
          if (source.iconUrl) {
            info.iconUrl = aiimUtil.appendTokenToUrl(source.iconUrl);
          }
          if (subType && subType.iconPromise) {
            info.waitForPromise = true;
          }
        }
      }
    }
    return info;
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const pattern = i18n.item.categoryTypePattern;
    let cls, subType, tip = "";
    const source = aiimUtil.getSource(this.props.sourceKey);
    if (source) subType = source.findSubTypeByValue(this.props.subTypeValue);

    if (this.props.forFeature) {
      cls = CSS.itemIcon;
      if (subType && subType.name) {
        tip = pattern.replace("{category}",source.name).replace("{type}",subType.name);
      } else if (source) {
        tip = source.name;
      }
    } else {
      cls = CSS.categoryIcon;
      if (subType) tip = subType.name;
    }

    let iconSrc = this.state.iconUrl;
    if (!iconSrc) iconSrc = defaultIcon;
    if (iconSrc) {
      return (
        <img className={cls} src={iconSrc} alt="" title={tip}/>
      )
    }
    return null;
  }

}
