import React, {createRef} from "react";

import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as maneuverTypes from "./maneuverTypes";

export default class Navigator extends React.Component {

  nextButtonRef = createRef();

  constructor(props){
    super(props);
    this.state = component.newState({
      currentIndex: 0,
      focusOnUpdate: false,
      items: [],
      routeResult: null,
      routeResultTimestamp: null,
      show: false
    });
  }

  audioClicked = () => {
    const speech = Context.instance.speech;
    speech.audibleDirectionsEnabled = !speech.audibleDirectionsEnabled;
    if (!speech.audibleDirectionsEnabled) speech.cancel();
    Topic.publish(Topic.AudioEnabledChanged,{});
  }

  backClicked = () => {
    if (this.state.currentIndex > 0) {
      const idx = this.state.currentIndex - 1;
      this.goToItem(idx);
      this.setState({currentIndex: idx});
    }
  }

  componentDidMount() {

    component.own(this,[

      Topic.subscribe(Topic.ActivateNavigator,params => {
        const routeResult = (params && params.routeResult);
        const sections = (routeResult && routeResult.sections);
        const show = (sections && sections.length > 0);
        if (show) {
          document.body.classList.add("i-navigator-on");
          let currentIndex = 0;
          let items;
          if (routeResult.timestamp === this.state.routeResultTimestamp) {
            currentIndex = this.state.currentIndex;
            items = this.state.items;
          } else {
            items = this.makeItems(routeResult);
          }
          this.setState({
            currentIndex: currentIndex,
            focusOnUpdate: true,
            items: items,
            routeResult: routeResult,
            routeResultTimestamp: routeResult.timestamp,
            show: true
          });
          setTimeout(() => {
            this.goToItem(currentIndex);
          },500);

        } else {
          document.body.classList.remove("i-navigator-on");
          this.setState({
            currentIndex: 0,
            focusOnUpdate: false,
            items: [],
            routeResult: null,
            routeResultTimestamp: null,
            show: false
          });
        }
      }),

      Topic.subscribe(Topic.AudioEnabledChanged,params => {
        if (this.state.show) component.refresh(this);
      })

    ]);
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.focusOnUpdate) {
      const btn = this.nextButtonRef && this.nextButtonRef.current;
      if (btn && btn.focus) {
        btn.focus();
        this.setState({focusOnUpdate: false});
      }
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  exitClicked = () => {
    document.body.classList.remove("i-navigator-on");
    this.setState({
      focusOnUpdate: false,
      routeResult: null,
      show: false
    });
    const speech = Context.instance.speech;
    if (speech) speech.cancel();

    const btn = document.querySelector("button[data-i-navigator-start-button='true']");
    if (btn && btn.focus) btn.focus();
  }

  getSection(item) {
    if (!item) return null;
    const routeResult = this.state.routeResult;
    const sections = (routeResult && routeResult.sections) || [];
    return sections[item.sectionIndex];
  }

  getStep(section,item) {
    if (!item) return null;
    const steps = (section && section.steps) || [];
    return steps[item.stepIndex];
  }

  goToItem = (itemIndex) => {
    const items = this.state.items || [];
    const item = items[itemIndex];
    const section = this.getSection(item);
    const step = this.getStep(section,item);
    if (item && section && step) {
      if (item.pointBased) {
        this.goToEvent(section,step);
      } else {
        this.goToSection(section);
      }
    }
  }

  goToEvent = (section,step) => {
    const routeResult = this.state.routeResult;
    if (routeResult && section && step) {
      routeResult.goToEvent(section,step);
    }
  }

  goToSection = (section) => {
    const routeResult = this.state.routeResult;
    if (routeResult && section) {
      routeResult.goToSection(section);
    }
  }

  makeItems(routeResult) {
    const items = [];
    const sections = (routeResult && routeResult.sections);
    let itemIndex = -1;
    if (sections && sections.length > 0) {
      sections.forEach((section,sectionIndex) => {
        section.steps.forEach((step,stepIndex) => {
          itemIndex++;
          let item = {
            sectionIndex: sectionIndex,
            stepIndex: stepIndex,
            itemIndex: itemIndex,
            pointBased: !!(section.steps.length > 1)
          };
          items.push(item);
        });
      });
    }
    return items;
  }

  nextClicked = () => {
    const items = this.state.items;
    if (items && items.length > (this.state.currentIndex + 1)) {
      const idx = this.state.currentIndex + 1;
      this.goToItem(idx);
      this.setState({currentIndex: idx});
    }
  }

  recenterClicked = () => {
    this.goToItem(this.state.currentIndex);
  }

  render() {
    const i18n = Context.instance.i18n;
    const routeResult = this.state.routeResult;
    const currentIndex = this.state.currentIndex;
    const items = this.state.items;
    const show = (this.state.show && routeResult && items && items.length > 0);
    const item = (show && items[currentIndex]);
    if (!show || !item) return (<div></div>);

    const isRtl = Context.instance.uiMode.isRtl;
    const leftIcon = isRtl ? Icons.right() : Icons.left();
    const rightIcon = isRtl ? Icons.left() : Icons.right();

    const info = this.renderInfo(item);

    let backStyle;
    if (currentIndex === 0) {
      backStyle = {visibility: "hidden"};
    }
    let backButton = (
      <button key="back" className={"i-button i-button-clear"} style={backStyle}
        title={i18n.general.back}
        onClick={this.backClicked}>{leftIcon}</button>
    );

    let nextStyle;
    if (items.length <= (this.state.currentIndex + 1)) {
      nextStyle = {visibility: "hidden"};
    }
    let nextButton = (
      <button key="next" className={"i-button i-button-clear"} style={nextStyle}
        title={i18n.general.next} ref={this.nextButtonRef}
        onClick={this.nextClicked}>{rightIcon}</button>
    );

    let summary = (
      <span key="summary" className={"i--summary"}>
        <span key="dist"
          dangerouslySetInnerHTML={{__html: routeResult.totalDistanceText}}></span>
        <span key="time"
          dangerouslySetInnerHTML={{__html: routeResult.totalTimeText}}></span>
      </span>
    );

    let audio = null;
    let speech= Context.instance.speech;
    if (speech && speech.hasVoice()) {
      let audioIcon, ariaLabel, ariaChecked;
      if (speech.audibleDirectionsEnabled) {
        audioIcon = Icons.audioOn();
        ariaLabel = i18n.audio.ariaLabelEnabled;
        ariaChecked = "true";
      }
      else {
        audioIcon = Icons.audioOff();
        ariaLabel = i18n.audio.ariaLabelDisabled;
        ariaChecked = "false";
      }
      audio = (
        <button key="audio" role= "switch"
          className={"i-button i-button-clear i--audio"} aria-label = {ariaLabel} aria-checked = {ariaChecked}
          onClick={this.audioClicked}>{audioIcon}</button>
      );
    }

    return (
      <>
        <div key="top" className={"i-navigator-top"}>
          {info}
          <span key="actions" className={"i--actions"}>
            {backButton}
            {nextButton}
          </span>
        </div>
        <div key="bottom" className={"i-navigator-bottom"}>
          {summary}
          <span key="actions" className={"i--actions"}>
            {audio}
            <button key="recenter"
              className={"i-button i-button-clear i--recenter"}
              title={i18n.infoPanel.realTime.recenter}
              onClick={this.recenterClicked}>{Icons.zoomToObject()}</button>
            <button key="exit" className={"i-button i--exit"}
              onClick={this.exitClicked}
              >{Icons.cancel()}</button>
          </span>
        </div>
      </>
    );
  }

  renderInfo(item) {
    const i18n = Context.instance.i18n;
    const section = this.getSection(item);
    const step = this.getStep(section,item);
    let node = null;
    if (step) {
      let key = component.nextTmpId("k");

      let icon = null;
      let iconSvg = maneuverTypes.maneuverSvgsByType[step.maneuverType];
      let iconUrl = step.maneuverIconUrl;
      if (iconSvg) {
        icon = iconSvg;
      } else if (iconUrl) {
        icon = <img alt={i18n.general.image} className={"i-maneuver-icon"} src={iconUrl} />;
      }

      let maneuver = null;
      if (item.pointBased) {
        maneuver = (
          <div key="m" className={"i--maneuver"}>
            <span key="i" className={"i--icon"}>{icon}</span>
          </div>
        );
      } else {
        maneuver = (
          <div key="m" className={"i--maneuver"}>
            <span key="i" className={"i--icon"}>{icon}</span>
            <span key="d" className={"i--distance"}
              dangerouslySetInnerHTML={{ __html: section.distanceText}}></span>
          </div>
        );
      }
      node = (
        <div key={key} className={"i--step"} role="alert button" tabIndex="0"
        onClick={this.recenterClicked}>
          {maneuver}
          <div key="t" className={"i--text"}>{step.text}</div>
        </div>
      );
    }
    return (
      <div key="info" className={"i--info"}>
        {node}
      </div>
    );
  }

}
