import moment from 'moment';
import React, { CSSProperties } from 'react';
import ReactDOM from "react-dom";
import BookingCancel from '../More/Actions/BookWorkspace/BookingCancel';
import RecurrenceDropdown from '../More/Actions/BookWorkspace/RecurrenceDropdown';

// aiim.datasets
import FieldNames from '../../../aiim/datasets/FieldNames';

// aiim.util
import { getAttributeValue, getRecurrencePattern } from '../../../aiim/util/aiimUtil';

// common.components
import { ModalController } from '../../../common/components/Modal';

// context
import Context from '../../../context/Context';
import Topic from '../../../context/Topic';

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import { cancelBooking, updateCheckInOut } from '../More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface';
import { formatDate, formatRecurringDates, getHotelBookingSystem, getHotelBookingSystemType } from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

// util
import CalendarSelector from '../../../util/CalendarSelector';
import Icons from '../../util/Icons';
import { BookingType, makeEventInfo } from '../../../util/calendarUtil';
import { isNetworkError } from '../../../util/networkUtil';
import { stringFormatter } from '../../../util/formatUtil';

// 3rd party
import XIcon from "calcite-ui-icons-react/XIcon";
import SignInIcon from "calcite-ui-icons-react/SignInIcon";
import SignOutIcon from "calcite-ui-icons-react/SignOutIcon";
import Button from "calcite-react/Button";
import RecurrenceIcon from "calcite-ui-icons-react/RecurrenceIcon";

import * as checkInOutUtil from "../More/Actions/BookWorkspace/CheckInOutUtil";
import * as component from "../../util/component";
import { getRecurrenceSeries, IBookingCardProps, IBookingCardState, IBookingData } from '../More/Actions/BookWorkspace/MyBookings';
import { IRecurrenceOptions, IRecurrenceSeries } from '../More/Actions/BookWorkspace/BookingRecurrence';
import { IApplyEditsResult } from '@esri/arcgis-rest-feature-layer';

const CSS = {
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  locationDescription: "i-booking-location-description",
  button: "i-book-button",
  buttonCheckIn: "i-book-card-button-checkIn",
  buttonCheckOut: "i-book-card-button-checkOut",
  actionGroup: "i-action-group",
  actionSubGroup: "i-action-subgroup",
  addToCalendar: "i-booking-add-calendar",
  cancel: "i-booking-cancel",
  descriptionContent: "i-booking-description",
  dateLabel: "i-date-label"
};

const APPROVED = 1;
const CHECKED_IN = 4;
interface IMyHotelBookingsProps {
  active: boolean,
  bookings: IBookingData[]
}
interface IMyHotelBookingsState {
  series: Map<string, IRecurrenceSeries>
}
export default class MyHotelBookings extends React.Component<IMyHotelBookingsProps, IMyHotelBookingsState> {
 
  constructor(props: IMyHotelBookingsProps) {
    super(props);
    this.state = component.newState({});
  }
  override componentDidUpdate(prevProps: IMyHotelBookingsProps, prevState: IMyHotelBookingsState) {
    if (prevProps.bookings !== this.props.bookings) {
      const series = getRecurrenceSeries(this.props.bookings);
      this.setState({ series });
    }
  }
  sortBookings = (bookings: IBookingData[]) => {
    return bookings.sort((resA, resB) => {
      const attrsA = resA.booking.attributes;
      const attrsB = resB.booking.attributes;

      const startTimeA = getAttributeValue(attrsA, FieldNames.START_TIME);
      const startTimeB = getAttributeValue(attrsB, FieldNames.START_TIME);

      if (startTimeA > startTimeB) {
        return 1;
      } else if (startTimeA < startTimeB) {
        return -1
      }
      return 0;
    });
  }

  makeBookingList = (bookings: IBookingData[]) => {
    const { series } = this.state;
    const sortedBookings = this.sortBookings(bookings);

    const myBookingsList = [];
    for (let i = 0; i < sortedBookings.length; i++) {
      const data = sortedBookings[i];
      const reservation = data.booking;
      const state = getAttributeValue(reservation.attributes, FieldNames.STATE);
      const recurrenceId = getAttributeValue(reservation.attributes, FieldNames.RECURRENCE_ID);
      const isRecurring = series?.has(recurrenceId);
      let showButton;
      if (state === CHECKED_IN) {
        showButton = "checkOut";
      } else if (state === APPROVED) {
        showButton = "checkIn";
      }

      if (state === APPROVED || state === CHECKED_IN) {
        myBookingsList.push(
          <MyHotelBookingsCard
            showButton={showButton}
            key={i}
            data={data}
            isRecurring={isRecurring}
            series={series?.get(recurrenceId)}
          />
        );
      }
    }

    return myBookingsList;
  }

  render() {
    const { active, bookings } = this.props;
    if (!bookings || bookings.length === 0) {
      return null;
    }

    const style: CSSProperties = {};
    if (!active) {
      style.display = "none";
    }

    return (
      <ul style={style}>
        {this.makeBookingList(bookings)}
      </ul>
    );
  }
}

class MyHotelBookingsCard extends React.Component<
  Omit<IBookingCardProps, "updateICSfile">,
  Omit<IBookingCardState, "unitFeature">
> {
  constructor(props) {
    super(props);
    this.state = {
      showButton: this.props.showButton,
      hideCheckIn: false,
      popoverOpen: false
    };
  }

  componentDidMount() {
    this._startupApp();
  }

  override componentDidUpdate(prevProps: IBookingCardProps) {
    if (prevProps.data !== this.props.data) {
      this._startupApp();
    }
  }

  _startupApp = () => {
    const { data } = this.props;
    const reservation = data.booking;
    const attributes = reservation.attributes;
    const state = getAttributeValue(attributes, FieldNames.STATE);
    this.disableCheckIn();

    if (state === CHECKED_IN) {
      this.setState({
        showButton : "checkOut"
      })
    } else if (state === APPROVED) {
      this.setState({
        showButton : "checkIn"
      })
    }
  }

  handleToggle = () => {
    const { data } = this.props;
    const reservation = data.booking;
    const attributes = reservation.attributes;
    const i18n = Context.getInstance().i18n;
    const reservations = Context.instance.aiim.datasets.reservations;
    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }

    const bookingSystem = getHotelBookingSystem();
    if(this.props && this.props.showButton === "checkOut") {
      if (!reservations.canUserEditFeature(attributes)) {
        const reservationManager = getAttributeValue(attributes, FieldNames.RESERVED_BY_FULL_NAME);
        checkInOutUtil.showNoEditPopup("checkOut", reservationManager);
        return;
      }

      const options = {
        title: i18n.more.bookWorkspace.checkInOut.checkOut.caption,
        message: i18n.more.bookWorkspace.checkInOut.checkOut.message,
        okLabel: i18n.more.bookWorkspace.checkInOut.checkOut.yes,
        cancelLabel: i18n.more.bookWorkspace.checkInOut.checkOut.no,
        closeOnOK: true,
        closeOnCancel: true
      };
      ModalController.confirm(options)
      .then(result => {
        if(result.ok) {
          attributes[reservations.checkOutTimeField] = new Date().getTime();
          const objectID = getAttributeValue(attributes,objectIdField);

          updateCheckInOut(bookingSystem, attributes, "checkOut")
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                Topic.publish(Topic.ShowToast,{
                  message: i18n.more.bookWorkspace.checkInOut.checkOut.toastMessage
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && error.message === "Failed to fetch") {
              this.showServerErrorPopup("checkOut");
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            console.error(error);
          })
        }
      })
      .catch(error => {
        console.error(error);
        Topic.publishErrorUpdatingData(error)
      })
    }else if( this.props && this.props.showButton === "checkIn") {
      if (!reservations.canUserEditFeature(attributes)) {
        const reservationManager = getAttributeValue(attributes, FieldNames.RESERVED_BY_FULL_NAME);
        checkInOutUtil.showNoEditPopup("checkIn", reservationManager);
        return;
      }

      const unitName = attributes && getAttributeValue(attributes, FieldNames.UNIT_NAME);
      const message = i18n.more.bookWorkspace.checkInOut.checkIn.message.replace("{hotel}", unitName);

      const options = {
        title: i18n.more.bookWorkspace.checkInOut.checkIn.caption,
        message: message,
        okLabel: i18n.more.bookWorkspace.checkInOut.checkIn.yes,
        cancelLabel: i18n.more.bookWorkspace.checkInOut.checkIn.no,
        closeOnOK: true,
        closeOnCancel: true
      };
      ModalController.confirm(options)
      .then(result => {
        if(result.ok) {
          attributes[reservations.checkInTimeField] = new Date().getTime();
          const objectID = getAttributeValue(attributes,objectIdField);

          updateCheckInOut(bookingSystem, attributes, "checkIn")
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                Topic.publish(Topic.ShowToast,{
                  message: i18n.more.bookWorkspace.checkInOut.checkIn.toastMessage
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && error.message === "Failed to fetch") {
              this.showServerErrorPopup("checkIn");
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            console.error(error);
          })
        }
      })
      .catch(error => {
        console.error(error);
        Topic.publishErrorUpdatingData(error)
      })
    }
  }

  disableCheckIn = () => {
    const { data } = this.props;
    const reservation = data.booking;
    const attributes = reservation.attributes;
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const checkInTime = getAttributeValue(attributes, FieldNames.CHECK_IN_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const currentTime = new Date().getTime();
    const startTimeM = moment(startTime);
    const currentTimeM = moment(currentTime);

    const elapsed = startTimeM.diff(currentTimeM, 'seconds');

    if(!checkInTime) {
      if((state === APPROVED) &&
          (((0 <= elapsed) && (elapsed <= 300)) || ((startTime <= currentTime) && ( currentTime <= endTime)))){
        this.setState({
          hideCheckIn : false
        })
      } else {
        this.setState({
          hideCheckIn : true
        })
      }
    }else {
      this.setState({
        hideCheckIn: false
      })
    }
  }
  private get recurrence(): IRecurrenceOptions {
    const { data: { booking: { attributes } } } = this.props;
    return getRecurrencePattern(attributes);
  }
  showServerErrorPopup =(type)=> {
    const { data } = this.props;
    const reservation = data.booking;
    const bookingSystem = getHotelBookingSystem();
    const attributes = reservation.attributes;
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);
    const reservations = Context.instance.aiim.datasets.reservations;
    const i18n = Context.getInstance().i18n;
    let message;

    if(type === "checkIn") {
      message = i18n.more.bookWorkspace.checkInOut.checkIn.serverErrorMessage;
    } else if (type === "checkOut") {
      message = i18n.more.bookWorkspace.checkInOut.checkOut.serverErrorMessage;
    } else if (type === "cancel") {
      message = i18n.more.bookWorkspace.checkInOut.cancelBooking.serverErrorMessage;
    }
    message = message.replace("{unit}", unitName);

    const options = {
      title: i18n.more.bookWorkspace.checkInOut.error,
      message: message,
      okLabel: i18n.more.bookWorkspace.checkInOut.tryAgain,
      showOkCancel: true,
      hideCancel : false,
      closeOnOK: true,
      cancelLabel: i18n.more.bookWorkspace.checkInOut.close
    };

    ModalController.confirm(options)
    .then(result => {
      if(result.ok) {
        let objectIdField;
        if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
          objectIdField = reservations.layer2D.objectIdField;
        }
        const objectID = getAttributeValue(attributes, objectIdField);
        if (type === "cancel") {
          cancelBooking(bookingSystem, attributes)
          .then(({ data }: { data: IApplyEditsResult }) => {
            if(data?.updateResults.some(r => r.success === false)) {
              Topic.publishErrorUpdatingData();
            } else {
              Topic.publish(Topic.RenderBookingList, {});
              Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
            }
          })
          .catch(error => {
            if(error && error.message === "Failed to fetch") {
              this.showServerErrorPopup("cancel");
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            //console.error(error);
          })
        } else {
          if(type === "checkOut") attributes[reservations.checkOutTimeField] = new Date().getTime();
          if(type === "checkIn") attributes[reservations.checkInTimeField] = new Date().getTime();

          updateCheckInOut(bookingSystem, attributes, type)
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] && 
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                let msg = i18n.more.bookWorkspace.checkInOut.checkOut.toastMessage;
                if(type === "checkIn") msg = i18n.more.bookWorkspace.checkInOut.checkIn.toastMessage;
                Topic.publish(Topic.ShowToast,{
                  message: msg
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && error.message === "Failed to fetch") {
              this.showServerErrorPopup(type);
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            console.error(error);
          })
        }
      }
    })
    .catch(error => {
      console.error(error);
      Topic.publishErrorUpdatingData(error)
    })
  }

  _cancelClicked = (type?: BookingType) => {
    const { data, series, isRecurring } = this.props;
    const reservation = data.booking;
    const bookingSystem = getHotelBookingSystem();
    const unitName = getAttributeValue(reservation.attributes, FieldNames.UNIT_NAME);
    const bookings = (isRecurring && type!== "occurrence") 
                      ? series.occurrences.map(s => s.attributes) : [reservation.attributes];
    const i18n = Context.getInstance().i18n;
    const reservations = Context.getInstance().aiim.datasets.reservations;
    const reservationManager = getAttributeValue(reservation.attributes, FieldNames.RESERVED_BY_FULL_NAME);

    // check if user has edit permission on the reservation(s)
    if (bookings.some(attributes => !reservations.canUserEditFeature(attributes))) {
      checkInOutUtil.showNoEditPopup("cancel", reservationManager);
      return;
    }
    
    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }
    const objectID = getAttributeValue(reservation.attributes,objectIdField);
    let msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.message;
    if (unitName) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.newMessage;
      msg = msg.replace("{unit}",unitName);
    }
    if (isRecurring) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.seriesMessage;
      if (type === "occurrence") msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.occurrenceMessage;
      msg = msg.replace("{unit}", unitName);
    }
    const options = {
      title: i18n.more.bookWorkspace.checkInOut.cancelBooking.modalTitle,
      message: msg,
      okLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.yes,
      cancelLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.no,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    };
    ModalController.confirm(options)
    .then(result => {
      if(result && result.ok) {
        cancelBooking(bookingSystem, bookings)
        .then(({ data }: { data: IApplyEditsResult }) => {
          if (data?.updateResults?.some(r => r.success === false)) {
            Topic.publishErrorUpdatingData();
          } else {
            const unitName = getAttributeValue(reservation.attributes, FieldNames.UNIT_NAME);               
            const node = document.createElement("div");
            const onClose = () => {
              if (node && node.parentNode) {
                node.parentNode.removeChild(node);
                ReactDOM.unmountComponentAtNode(node)
              }
            };

            document.body.appendChild(node);
            ReactDOM.render(
              <BookingCancel 
                bookingSystemType={getHotelBookingSystemType()}
                bookingType={type}
                unitName={unitName}
                closePopup={onClose}
                data={this.props.data}
              />, node);                
            Topic.publish(Topic.RenderBookingList, {});
            Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
          }
        })
        .catch(error => {
          if(error && error.message === "Failed to fetch") {
            this.showServerErrorPopup("cancel");
          } else {
            Topic.publishErrorUpdatingData(error);
          }
          console.error(error);
          if (error && isNetworkError(error.message)) {
            const i18n = Context.getInstance().i18n;
            const template = i18n.meetingRooms.issues.m9;
            const message = stringFormatter(template, { unit: unitName });
            Topic.publishNetworkError(message)
          } else {
            Topic.publishErrorUpdatingData(error);
          }
        })
      }
    })
    .catch(error => {
      console.error(error);
      Topic.publishErrorUpdatingData(error)
    })
  }

  _togglePopover = (type?: IBookingCardState["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { data, showButton, isRecurring } = this.props;
    const reservation = data.booking;
    const unit = data.unit;
    const { popoverOpen, popoverType } = this.state;
    const attributes = reservation.attributes;

    // Field values to display
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);

    // Format a date string from the start and end times
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    const isAllDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
    const formattedDate = formatDate(mStartTime, mEndTime, isAllDay);

    // Check state to see if cancel button is needed
    const state = getAttributeValue(attributes, FieldNames.STATE);

    let label, icon, cls;
    if (showButton && showButton === "checkIn") {
      label = i18n.more.bookWorkspace.checkInOut.checkIn.caption;
      icon = (<SignInIcon size={24}/>);
      cls = CSS.buttonCheckIn;
    } else {
      label = i18n.more.bookWorkspace.checkInOut.checkOut.caption;
      icon = (<SignOutIcon size={24}/>);
      cls = CSS.buttonCheckOut;
    }

    const addToCalendarButton = (
      <button
        className={CSS.addToCalendar}
        data-id={"calendar"}
        onClick={() => this._togglePopover("calendar")}
      >
        {Icons.calendarPlus()}
        {i18n.general.add}
      </button>
    );

    const cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("cancel") : this._cancelClicked()}
      >
        <XIcon />
        {i18n.general.cancel}
      </button>
    );

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const calendarParams = makeEventInfo(reservationsDataset, reservation, unitsDataset, unit);
    return (
      <li className={CSS.bookingCard}>
        <div className="i-book-card-container">
          <div className={CSS.dateLabel}>
            <span style={{ flexGrow: 1 }}>{formattedDate}</span>
            {isRecurring &&
              <span title={formatRecurringDates(this.recurrence, mStartTime, mEndTime, !!isAllDay)}>
              <RecurrenceIcon size={16}/>
              </span>}
          </div>
          <div className={CSS.actionGroup}>
            {!this.state.hideCheckIn &&
              <div className={cls}>
                <Button
                  icon={icon}
                  iconPosition="before"
                  onClick={this.handleToggle}>
                  {label}
                </Button>
              </div>
            }
            <CalendarSelector
              onRequestClose={this._togglePopover}
              open={popoverOpen && popoverType === "calendar"}
              targetEl={addToCalendarButton}
              params={calendarParams}
              reservation={reservation}
            />
            {state === CHECKED_IN ? null : 
              <RecurrenceDropdown
                type="cancel"
                open={popoverOpen && popoverType === "cancel"}
                targetEl={cancelButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._cancelClicked(type);
                  this._togglePopover();
                }} />
            }
          </div>
        </div>
      </li>
    );
  }
}
