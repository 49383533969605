
let COUNTER = 0;
let TMPCOUNTER = 0;

export function clearHandles(handles) {
  if (!Array.isArray(handles)) return;
  handles.forEach((h) => {
    try {
      if (h && typeof h.remove === "function") {
        // Dojo handle
        h.remove();
        //console.log("component::clearHandles - handle removed");
      }
    } catch(ex) {
      console.warn("Error component::clearHandles - error removing handle:",h);
      console.error(ex);
    }
  });
}

export function componentWillUnmount(component) {
  clearHandles(component.state && component.state.handles);
  destroyCoreWidget(component._coreWidget);
}

export function destroyCoreWidget(coreWidget) {
  try {
    if (coreWidget && typeof coreWidget.destroy === "function") {
      coreWidget.destroy();
      //console.log("component::destroyCoreWidget - core widget destroyed");
    }
  } catch(ex) {
    console.warn("Error component::destroyCoreWidget:",coreWidget);
    console.error(ex);
  }
}

export function getFirstChild(component) {
  if (component && component.props && component.props.children) {
    const children = component.props.children;
    if (Array.isArray(children) && children.length > 0) {
      return children[0];
    } else {
      return children;
    }
  }
}

export function newState(data) {
  const state = {
    rid: null,
    handles: null
  };
  if (data) return Object.assign(state,data);
  return state;
}

export function nextId(prefix) {
  if (!prefix) prefix = "id-";
  return prefix + COUNTER++;
}

export function nextTmpId(prefix) {
  if (!prefix) prefix = "rid-";
  return prefix + TMPCOUNTER++;
}

export function own(component,handles) {
  try {
    if (!handles) return;
    if (!Array.isArray(handles)) handles = [handles];
    if (handles.length === 0) return;
    handles = handles.slice();
    component.setState(state => {
      return {
        handles: state.handles ? state.handles.slice().concat(handles) : handles
      };
    });
  } catch(ex) {
    console.warn("Error tracking component handles:",component);
    console.error(ex);
  }
}

export function refresh(component) {
  try {
    component.setState(state => {
      return {
        rid: nextTmpId()
      };
    });
  } catch(ex) {
    console.warn("Error refreshing component:",component);
    console.error(ex);
  }
}
