import BaseVM from "../../../../components/Editor/support/BaseVM";
import Context from "../../../../../context/Context";
import * as val from "../../../../../util/val";

export default class ItemBrowserVM extends BaseVM {

  async queryItems(props) {
    const portal = Context.instance.getPortal();
    if (!portal) return Promise.resolve();
  
    const appendQ = (query,q) => {
      if (query.length > 0) query += " AND ";
      query += q;
      return query
    };
  
    let query = props.query || "";
    if (typeof props.itemType === "string" && props.itemType.length > 0) {
      query = appendQ(query,"(type: \"" + props.itemType + "\")");
    }
    if (typeof props.q === "string" && props.q.length > 0) {
      query = appendQ(query,"(" + props.q + ")");
    }
  
    if (props.scope === "myOrganization") {
      const user = Context.instance.user;
      let orgId = portal.id;
      if (user.portalUser) orgId = user.portalUser.orgId;
      if (typeof orgId === "string" && orgId.length > 0) {
        const qOrg = "((orgid:" + val.escapeForLucene(orgId) + ") -owner:esri_livingatlas)";
        query = appendQ(query,qOrg);
      }
    }

    if (props.scope === "myContent") {
      const username = Context.instance.user.getUsername();
      const qUser = "(owner:"+val.escapeForLucene(username)+")";
      query = appendQ(query,qUser);
    }
  
    const queryParams: Partial<__esri.PortalQueryParams> = {
      query: query,
      start: props.start,
      num: 50
    };
    if (props.sortField) {
      queryParams.sortField = props.sortField;
      if (props.sortOrder) queryParams.sortOrder = props.sortOrder;
    }
    return portal.queryItems(queryParams);
  }

}