import React, { CSSProperties, useState } from "react";
import moment, { Moment, MomentInput } from "moment";

import ReactToggle from "react-toggle";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import ModalController from "../../../common/components/Modal/ModalController";
import Loader from "calcite-react/Loader";
import Popover from "calcite-react/Popover";
import Tooltip from "calcite-react/Tooltip";
import SDPWrapper from "../../../components/common/Calendar/SDPWrapper";
import Topic from "../../../context/Topic";
import UIMode from "../../../context/UIMode";
import * as component from "../../util/component";
import * as dateUtil from "../Events/dateUtil";
import * as ics from "../Events/ics";

//import CalIcon from "calcite-ui-icons-react/CalendarIcon";
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import PinIcon from "calcite-ui-icons-react/PinIcon";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import WarningIcon from "calcite-ui-icons-react/ExclamationMarkTriangleFIcon";
import WarningIcon2 from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import { BookingType, ICalendarEventInfo, makeEventInfo } from "../../../util/calendarUtil";
import CalendarSelector from "../../../util/CalendarSelector";
import { isNetworkError } from "../../../util/networkUtil";
import { isUnitAvailable, checkLimits } from "../More/Actions/BookWorkspace/validateUtil";
import {
  formatRecurringDates,
  getHotelBookingSystem,
  getMeetingBookingSystem,
  IReservationInfo
} from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import CheckInOutPicker from "./CheckInOutPicker";
import {
  IBookingStatus,
  IBookingTask,
  ICommonBookingProps,
  IOriginalReservation,
  IScheduleItem,
  IReserveForInfo
} from "../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import BookingRecurrence, {
  displayRecurrence,
  getDefaultEndDate,
  getDefaultRecurrenceOptions,
  getRecurringDates,
  IRecurrenceOptions,
  IRecurrenceSeries
} from "../More/Actions/BookWorkspace/BookingRecurrence";
import { isSameDay } from "react-dates";
import { DateTimeTimeZone, ScheduleInformation } from "@microsoft/microsoft-graph-types";
import type Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import type Reservations from "../../../aiim/datasets/Reservations";
import type EsriReservationSchema from "../More/Actions/BookWorkspace/WorkspaceReservation/EsriReservationSchema";
import { IIndoorsEvent } from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findDuplicateRecords, findUserReservations } from "../../../util/multipleAssignmentsUtil";

const CSS = {
  dateLabel: "i-date-label",
  dayLabel: "i-day-label",
  labelGroup: "i-label-group",
  limitHotelSchedule: "i-schedule-conflict"
};
export interface ISelectedWhen extends dateUtil.IDurationAsMilliseconds {
  isOngoingBooking: boolean,
  originalReservation: IOriginalReservation
}
interface IDragInfo {
  pos?: "top" | "bottom",
  r?: {
    fromDate: MomentInput,
    toDate: MomentInput
  },
  activeDay?: number,
  initialTop?: number,
  initialHeight?: number,
  lastResize?: number  
}
interface IScheduleProps {
  bookingType: ICommonBookingProps["bookingType"],
  isOngoingBooking: boolean,
  item: {
    isHotel?: boolean,
    locationUrl?: string
    name: string,
    scheduleEmail?: string,
    unitFeature: __esri.Graphic,
    unitId: string
  }
  onBookClicked?: (when: ISelectedWhen) => void,
  onMount?: (schedule: Schedule) => void,
  onShowOccupant: () => void,
  operation?: "updateBookingTime",
  originalReservation: IOriginalReservation,
  recurrenceType?: BookingType,
  series?: IRecurrenceSeries | IIndoorsEvent[],
  start: Date | number,
  when: dateUtil.IDurationAsMilliseconds,
  reserveForInfo?: IReserveForInfo
}
interface IScheduleState {
  considerScrollIntoView: boolean | null,
  dateMoment: Moment,
  daysWithReservations: Set<unknown>,
  daysWithMonthsQueried: Set<unknown>,
  enableInteraction: boolean,
  hasConflict: boolean,
  isWeekly: boolean,
  isWorking: boolean,
  recurrenceEnabled: boolean,
  reservationInfo: {
    reserved: IScheduleItem[]
  },
  calendarOpen: boolean,
  startOfWeekMillis: number,
  warnings: { msg: string, title: string }[],
  when: dateUtil.IDurationAsMilliseconds
}
/** Calendar component for viewing office hotel and meeting room reservations. */
export default class Schedule extends React.Component<IScheduleProps, IScheduleState> {

  componentId: string;
  private _mounted = false;
  private _daysPromise: Promise<{ reserved: IScheduleItem[] }>;
  private _promise: Promise<{ reserved: IScheduleItem[] }>;

  constructor(props: IScheduleProps) {
    super(props);
    this.componentId = component.nextId("schedule-");
    let dateMoment = moment(new Date());
    if (this.props.start)
      dateMoment = moment(this.props.start);

    let isWeekly = true;
    const mode = Context.instance.uiMode.mode;
    if ([UIMode.MODE_HORIZONTAL_SPLIT, UIMode.MODE_VERTICAL_SPLIT].includes(mode)) {
      isWeekly = false;
    }
    const { bookingType, when, recurrenceType, operation } = props;
    const targetWhen: dateUtil.IDurationAsMilliseconds = {      
      ...when,
      start: { ...when.start },
      end: { ...when.end },
      recurrence: {
        ...getDefaultRecurrenceOptions({ enabled: false, type: "weekly" }, when.start.date),
        ...Object.fromEntries(Object.entries(when.recurrence || {}).filter(([_, v]) => v != null))
      }
    };
    const type = bookingType === "hotel" ? getHotelBookingSystem().type : getMeetingBookingSystem().type;
    const recurrenceEnabled = displayRecurrence({ type, operation, recurrenceType });

    this.state = component.newState({
      considerScrollIntoView: null,
      hasConflict: false,
      hasWarning: false,
      isWeekly: isWeekly,
      isWorking: false,
      reservationInfo: null,
      dateMoment: dateMoment,
      calendarOpen: false,
      startOfWeekMillis: moment(dateMoment.valueOf()).startOf("week").valueOf(),
      when: targetWhen,
      recurrenceEnabled,
      enableInteraction: targetWhen.recurrence?.enabled && props.recurrenceType !== "occurrence" ? false : true,
      warnings: []
    });
  }

  bookClicked = () => {    
    let when = this.state.when;
    let duration = when.duration === "allDay" ? when.duration : dateUtil.determineDurationKey(when);
    const selectedWhen: ISelectedWhen = {
      duration: duration,
      start: { date: when.start.date },
      end: { date: when.end.date },
      isOngoingBooking: this.props.isOngoingBooking,
      originalReservation: this.props.originalReservation,
      recurrence: when.recurrence?.enabled ? when.recurrence : null
    };
    this.props.onBookClicked && this.props.onBookClicked(selectedWhen);
  }

  override componentDidMount() {
    this._mounted = true;
    this.queryUnitSchedule();
    this.validate();
    if (this.props.onMount) this.props.onMount(this);

    component.own(this, [
      Topic.subscribe(Topic.ReservationsUpdated,params => {
        this.setState({
          daysWithReservations: null,
          daysWithMonthsQueried: null
        }, () => {
          this.queryUnitSchedule();
        });
      }),

      Topic.subscribe(Topic.UIModeSet, params => {
        component.refresh(this);
      })
    ]);
  }

  override componentDidUpdate() {
    if (this.state.considerScrollIntoView) {
      this.scrollIntoView();
      this.setState({ considerScrollIntoView: false });
    }
  }

  override componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }
  private checkAvailability() {
    const bookingSystem = this.props.bookingType === "hotel" ? getHotelBookingSystem() : getMeetingBookingSystem();
    return bookingSystem.type === "esri"
      ? this.checkAvailabilityReservations(bookingSystem as EsriReservationSchema)
      : this.checkAvailabilityOffice365(bookingSystem as Office365);    
  }
  private async checkAvailabilityOffice365(bookingSystem: Office365) {
    const scheduleEmail = this.props.item.scheduleEmail;
    let available = true;
    if (scheduleEmail?.length > 0) {
      const { when: { duration, start: { date: startMillis }, end: { date: endMillis }, recurrence } } = this.state;
      const startMoment = moment(startMillis);
      const endMoment = moment(endMillis);
      const start = duration === "allDay" ? startMoment.startOf("day").toISOString() : startMoment.toISOString();
      const end = duration === "allDay" ? endMoment.endOf("day").toISOString() : endMoment.toISOString();
      const sameDayBooking = isSameDay(startMoment, endMoment);
      const schedules = await bookingSystem.checkAvailability([scheduleEmail], start, end, null, { recurrence }) as ScheduleInformation[];
      let conflictingDate: DateTimeTimeZone;
      available = schedules.every((schedule, i) => {
        if (!schedule) return false;
        const isAvailable = isUnitAvailable(schedule, { updateBooking: true }, this.props.series as IIndoorsEvent[]);
        if (!isAvailable) {
          conflictingDate = schedule.scheduleItems?.[0]?.start;
        }
        return isAvailable;
      });
      const newState: Partial<IScheduleState> = {
        hasConflict: !available,
        isWorking: false
      };
      if (!available && conflictingDate) {
        newState.considerScrollIntoView = true;
        newState.dateMoment = moment(conflictingDate.dateTime);
        if (recurrence?.enabled && sameDayBooking) {
          newState.startOfWeekMillis = moment(conflictingDate.dateTime).startOf("week").valueOf();
        }
      }
      this.setState(newState as IScheduleState, () => {
        if (!available) {
          this.queryUnitSchedule();
        }
      });
    }
    return available;
  }
  private async checkAvailabilityReservations(bookingSystem: EsriReservationSchema) {
    const { bookingType, item: { unitId }, operation, recurrenceType, originalReservation } = this.props;
    let available = true;
    if (unitId?.length > 0) {
      const reservations: Reservations = Context.instance.aiim.datasets.reservations;
      const { when: { duration, start: { date: startMillis }, end: { date: endMillis }, recurrence } } = this.state;
      const startMoment = moment(startMillis);
      const endMoment = moment(endMillis);
      const start = duration === "allDay" ? startMoment.startOf("day").toDate() : startMoment.toDate();
      const end = duration === "allDay" ? endMoment.endOf("day").toDate() : endMoment.toDate();
      const sameDayBooking = isSameDay(startMoment, endMoment);
      const data = await reservations.queryUnitSchedule(unitId, start, recurrence?.enabled ? moment(recurrence.endDate).endOf("day").toDate() : end);
      let conflictingDate: Moment;
      if (bookingType === "hotel") {
        const unitIdCapacity = await bookingSystem.queryCapacity([unitId]);
        const capacity = unitIdCapacity[unitId];
        if (capacity > 0) {
          const series = this.props.series as IRecurrenceSeries;
          const reservations = Context.instance.aiim.datasets?.reservations;
          const objectIdField = reservations?.layer2D?.objectIdField;
          const objectIds = operation === "updateBookingTime"
            ? recurrenceType === "series"
              ? series?.occurrences.map(o => getAttributeValue(o.attributes, objectIdField))
              : [getAttributeValue(originalReservation.attributes, objectIdField)]
            : null;
          const unitReservations = data.reserved.map(f => f.feature);
          const info = bookingSystem.canReserve(unitId, objectIds, capacity, unitReservations, start.toISOString(), end.toISOString(), recurrence);
          if (info?.canReserve === false) {
            available = false;
            conflictingDate = info.conflict;
          }
        }
      } else {
        const info = this.getConflictInfo(data);
        available = !info.conflicts;
        conflictingDate = info.conflictingDate;
      }
      const newState: Partial<IScheduleState> = {
        hasConflict: !available,
        isWorking: false
      };
      if (!available && conflictingDate) {
        newState.considerScrollIntoView = true;
        newState.dateMoment = conflictingDate;
        if (recurrence?.enabled && sameDayBooking) {
          newState.startOfWeekMillis = conflictingDate.startOf("week").valueOf();
        }
      }
      this.setState(newState as IScheduleState, () => {
        if (!available) {
          this.queryUnitSchedule();
        }
      });
    }
    return available;
  }
  private async checkLimits() {
    const bookingSystem = this.props.bookingType === "hotel" ? getHotelBookingSystem() : getMeetingBookingSystem();
    const { when } = this.state;
    const { item, bookingType, operation, reserveForInfo } = this.props;
    const task: IBookingTask & IBookingStatus = {
      allDay: (when.duration === "allDay"),
      bookingSystem,
      bookingType,
      operation,
      checkInDate: new Date(when.start.date),
      checkOutDate: new Date(when.end.date),
      options: { 
        recurrence: when.recurrence,
        reserveForInfo
      },
      scheduleEmail: item.scheduleEmail,
      unit: item.unitFeature,
      unitName: item.name,
      warnings: []
    }
    await checkLimits(task);
    if (task.hasBookingConflict === true) {
      this.setState({ warnings: task.warnings });
    }
    return !task.hasBookingConflict;
  }
  private getMatchingReservation(item: IScheduleItem) {
    const { bookingType, originalReservation, recurrenceType, series } = this.props;
    const bs = bookingType === "hotel" ? getHotelBookingSystem() : getMeetingBookingSystem();
    if (recurrenceType === "occurrence" || recurrenceType === "single") {
      return bs.type === "esri" ? originalReservation?.attributes : originalReservation?.booking;
    } else if (recurrenceType === "series") {
      if (Array.isArray(series)) {
        // m365
        return series.find(event => {
          const o365toDateObj = dateUtil.O365toDate(event.start.dateTime, event.end.dateTime);
          const utc = event.start.timeZone?.toLowerCase() === "utc";
          const toLocal = utc ? moment.utc : moment;
          const start = toLocal(o365toDateObj.fromDateTime);
          const end = toLocal(o365toDateObj.toDateTime);
          return item.fromDate === start.valueOf() && item.toDate === end.valueOf();
        });
      } else {
        // esri
        return series.occurrences.find(({ attributes }) => {
          const start = getAttributeValue(attributes, FieldNames.START_TIME);
          const end = getAttributeValue(attributes, FieldNames.END_TIME);
          return item.fromDate === start && item.toDate === end;
        });
      }
    }
  }
  private hasConflict({ start, end }: { start: Moment, end: Moment }, info?: IScheduleState["reservationInfo"]) {
    const reservationInfo = info ?? this.state.reservationInfo;
    return reservationInfo?.reserved?.length > 0
      ? reservationInfo.reserved.filter(item => {
        const matching = this.getMatchingReservation(item);
        return matching == null;
      }).some(item => {
        const mReservationStart = moment(item.fromDate);
        const mReservationEnd = moment(item.toDate);
        return start.isBetween(mReservationStart, mReservationEnd)
          || end.isBetween(mReservationStart, mReservationEnd)
          || (start.isSameOrBefore(mReservationStart) && end.isAfter(mReservationStart))
          || (end.isSameOrAfter(mReservationEnd) && start.isBefore(mReservationEnd))
          ? true
          : false;
      })
      : false;
  }
  private getConflictInfo(info?: IScheduleState["reservationInfo"]) {
    const { when } = this.state;
    const reservationInfo = info ?? this.state.reservationInfo;
    const result = {
      conflicts: false,
      conflictingDate: null
    };
    if (when.recurrence?.enabled) {
      // check all dates
      const recurringDates = getRecurringDates(when.recurrence, moment(when.start.date), moment(when.end.date));
      result.conflicts = recurringDates.length > 0 && reservationInfo?.reserved?.length > 0
        ? recurringDates.some(r => {
          const conflicts = this.hasConflict({ start: r.start, end: r.end }, reservationInfo);
          if (conflicts) {
            result.conflictingDate = r.start;
          }
          return conflicts;
        })
        : false;
    } else {
      // check a single date
      const start = moment(when.start.date);
      const end = moment(when.end.date);
      result.conflicts = this.hasConflict({ start, end });
      if (result.conflicts) {
        result.conflictingDate = start;
      }
    }
    return result;
  }
  makeIcsInfo(reservation) {
    const i18n = Context.instance.i18n;
    let feature = this.props.item.unitFeature;
    let filename = "reservation.ics";
    let info: Partial<IReservationInfo> = {
      name: reservation.title,
      description: reservation.description,
      location: reservation.unitName,
      date_start: new Date(reservation.fromDate),
      date_end: new Date(reservation.toDate),
      geo: null,
      geometry: feature && feature.geometry
    };
    if (this.props.item.isHotel) {
      const locationUrl = this.props.item.locationUrl;
      info.name = i18n.more.bookWorkspace.emailSubject.replace("{workspaceName}", reservation.unitName);
      if (locationUrl) {
        info.description = `${i18n.more.bookWorkspace.locationLinkPattern} - ${locationUrl}`;
      }
    }
    ics.makeIcsInfo(info as IReservationInfo,filename).then(icsInfo => {
      reservation.icsInfo = icsInfo;
    }).catch(ex => {
      console.error("Error generating .ics file:",ex);
    });
  }

  queryDaysWithReservations(m: Moment) {
    if (!m || (typeof m.valueOf !== "function")) return;
    let millis = m.valueOf();
    let fromDate = moment(millis).startOf("month").toDate();
    let toDate = moment(fromDate).add(1,"month").endOf("month").toDate();
    let r = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    let unitId = this.props.item.unitId;
    let mq = this.state.daysWithMonthsQueried;
    if (mq) {
      let hasFrom = mq.has(moment(fromDate).format("YYYY-MM"));
      let hasTo = mq.has(moment(toDate).format("YYYY-MM"));
      if (hasFrom && hasTo) return;
    }
    let promise = this._daysPromise = this.getMonthQueryPromise(m);
    promise.then(reservationInfo => {
      if (!this._mounted) return;
      if (promise !== this._daysPromise) return;
      //console.log("queryDaysWithReservations.info",reservationInfo)
      this._daysPromise = null;
      let dw = this.state.daysWithReservations;
      let dwmq = this.state.daysWithMonthsQueried;
      let daysWith = null;
      if (reservationInfo && reservationInfo.reserved) {
        if (!daysWith) daysWith = new Set(dw);
        reservationInfo.reserved.forEach(r => {
          if (r.fromDate) daysWith.add(moment(r.fromDate).format("YYYY-MM-DD"));
          if (r.toDate) daysWith.add(moment(r.toDate).subtract(1, "second").format("YYYY-MM-DD"));
        });
        let monthsQueried = new Set(dwmq);
        monthsQueried.add(moment(fromDate).format("YYYY-MM"));
        monthsQueried.add(moment(toDate).format("YYYY-MM"))
        //console.log("monthsQueried",monthsQueried)
        this.setState({
          daysWithReservations: daysWith,
          daysWithMonthsQueried: monthsQueried
        });
      }

    }).catch(ex => {
      console.error(ex);
      if (!this._mounted) return;
      //Topic.publishErrorAccessingData();
    });
  }

  getMonthQueryPromise(ms: Moment) {
    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    const bookingSystem = this.props.bookingType === "hotel"
      ? getHotelBookingSystem()
      : getMeetingBookingSystem();
    const millis = ms.valueOf();
    const { item } = this.props;
    const unitId = item.unitId;

    if (bookingSystem.type === "esri" && reservations) {
      const fromDate = moment(millis).startOf("month").toDate();
      const toDate = moment(fromDate).add(1, "month").endOf("month").toDate();
      return reservations.queryUnitSchedule(unitId, fromDate, toDate);
    } else if (bookingSystem.type === "office365") {
      const fromDate = {
        dateTime: moment(millis).startOf("month").toDate(),
        timeZone: "UTC"
      };
      const toDate = {
        dateTime: moment(fromDate.dateTime).add(1, "month").endOf("month").toDate(),
        timeZone: "UTC"
      };
      return (bookingSystem as Office365).queryUnitSchedule(item, fromDate, toDate);
    }

    return Promise.resolve(null);
  }

  queryUnitSchedule() {
    const bookingSystem = this.props.bookingType === "hotel" ? getHotelBookingSystem() : getMeetingBookingSystem();
    return bookingSystem.type === "esri"
      ? this.queryReservationUnitSchedule()
      : this.queryOffice365UnitSchedule(bookingSystem as Office365);
  }

  queryReservationUnitSchedule() {
    let fromDate = new Date(this.state.startOfWeekMillis);
    let toDate = moment(fromDate).endOf("week").toDate();
    let r = Context.instance.aiim.datasets.reservations;
    let unitId = this.props.item.unitId;
    let promise = this._promise = r.queryUnitSchedule(unitId,fromDate,toDate);
    return promise.then(reservationInfo => {
      if (!this._mounted) return;
      if (promise !== this._promise) return;
      this._promise = null;
      if (reservationInfo && reservationInfo.reserved) {
        reservationInfo.reserved.forEach(r => {
          this.makeIcsInfo(r);
        });
      }
      let considerScrollIntoView = false;
      if (this.state.isWeekly) {
        considerScrollIntoView = true;
      }
      this.setState({ considerScrollIntoView, reservationInfo }, () => {
        this.queryDaysWithReservations(moment(fromDate));
      });
    }).catch(ex => {
      console.error(ex);
      if (!this._mounted) return;

      if (ex && isNetworkError(ex.message)) {
        const i18n = Context.getInstance().i18n;
        Topic.publishNetworkError(i18n.meetingRooms.issues.m5);
      } else {
        Topic.publishErrorAccessingData();
      }
    });
  }

  async queryOffice365UnitSchedule(bookingSystem: Office365) {
    const { item } = this.props;
    if (bookingSystem.type !== "office365") {
      return;
    }

    const fromDate = {
      dateTime: new Date(this.state.startOfWeekMillis),
      timeZone: "UTC"
    };
    const toDate = {
      dateTime: moment(fromDate.dateTime).endOf("week").toDate(),
      timeZone: "UTC"
    };

    try {
      if (!this._mounted) {
        return;
      }
      const reservationInfo = await bookingSystem.queryUnitSchedule(item, fromDate, toDate);
      const considerScrollIntoView = this.state.isWeekly;
      this.setState({ considerScrollIntoView, reservationInfo }, () => {
        this.queryDaysWithReservations(moment(fromDate.dateTime));
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const isDaily = !this.state.isWeekly;
    const style: CSSProperties = {
      display: "flex",
      flexDirection: "column",
    }
    if (isDaily) {
      style.alignItems = "center";
    }
    return (
      <div style={style}>
        {this.renderCheckInOut()}
        {this.renderHeader()}
        {this.renderSchedule()}        
        {this.state.isWorking && <Loader className="i-availability-loader" />}
      </div>
    );
  }

  renderCheckInOut() {
    const i18n = Context.getInstance().i18n;
    const { when, isWeekly } = this.state;
    const { bookingType, operation, recurrenceType } = this.props;
    const viewType = isWeekly ? "horizontal" : "vertical";
    let isOngoingBooking, disabled = false;

    if(operation === "updateBookingTime") {
      isOngoingBooking = this.props.isOngoingBooking;
      if (isOngoingBooking) when.duration = null;
      if (recurrenceType !== "single") {
        // disable button if a series/occurrence turns off recurrence
        // happens if booking changes to multi-day
        disabled = !when.recurrence.enabled || !isSameDay(moment(when.start.date), moment(when.end.date));
      }
    }

    return (
      <div className="i-check-in-out-header">
        <CheckInOutPicker 
          operation={operation}
          bookingType={bookingType}
          isOngoingBooking={isOngoingBooking}
          when={when}
          duration={when.duration}
          start={when.start.date}
          end={when.end.date}
          viewType={viewType} 
          size={"small"} 
          onDateChanged={this._onCheckInOutChanged}
        />
        <button className="i-button" disabled={disabled} onClick={this.bookClicked}>{
          this.props.operation === "updateBookingTime" ? i18n.general.update : i18n.meetingRooms.book.label
        }</button>
      </div>
    );
  }

  renderDatePicker() {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const ariaLabel = i18n.meetingRooms.dateAriaLabel;
    const dateMoment = this.state.dateMoment;
    const isOpen = !!this.state.calendarOpen;
    const calendarIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16";
    let s;
    if (dateMoment) {
      try {
        s = lib.dojo.locale.format(dateMoment.toDate(),{selector:"date",formatLength:"short"});
      } catch (ex) {
        console.log("badDateMoment",dateMoment)
        console.error(ex);
      }
    }

    const isOutsideRange = (day) => {
      //return moment().isAfter(day, 'day')
      return false;
    }
    const isDayHighlighted = (day) => {
      let daysWith = this.state.daysWithReservations;
      if (daysWith) {
        return daysWith.has(day.format("YYYY-MM-DD"))
      }
      return false;
    }

    const clicked = () => {
      this.setState({
        calendarOpen: !this.state.calendarOpen
      })
    }

    const changed = (date) => {
      if (date !== null && date !== undefined) {
        let dtMoment = moment(date);
        let v = moment(date).startOf("week").valueOf();
        this.setState({
          startOfWeekMillis: v,
          dateMoment: dtMoment,
          calendarOpen: false
        }, () => this.queryUnitSchedule());
      }
    }

    const close = () => {
      this.setState({
        calendarOpen: false
      })
    }

    const button = (
      <button aria-label={ariaLabel} className="i-date" onClick={clicked}>
        <div className="i-sidebar-icon-container i-filter-container">
          <svg className="i-more-menu-icon"><use href={calendarIcon}></use></svg>
        </div>
      </button>
    );

    return (
      <Popover
        closeOnSelect={false}
        onRequestClose={close}
        open={isOpen}
        targetEl={button}
        appendToBody>
        <SDPWrapper
          initialDate={dateMoment.toDate()}
          outsideRange={isOutsideRange}
          dayHighlighted={isDayHighlighted}
          booking={true}
          onDateChange={changed}
          forSchedule={true}
          onPrevMonthClick={m => {
            if (m) {
              this.queryDaysWithReservations(m);
            }
          }}
          onNextMonthClick={m => {
            if (m) {
              this.queryDaysWithReservations(m);
            }
          }}
        />
      </Popover>
    );
  }

  renderHeader() {
    const i18n = Context.instance.i18n;
    const { startOfWeekMillis, isWeekly, recurrenceEnabled } = this.state;
    const isDaily = !isWeekly;

    const today = () => {
      let now = Date.now();
      let v = moment(now).startOf("week").valueOf();
      this.setState({
        calendarOpen: false,
        startOfWeekMillis: v,
        dateMoment: moment(now).startOf("day")
      }, () => this.queryUnitSchedule());
    };
    const prev = () => {
      let v = moment(startOfWeekMillis).add(-1,"week").startOf("week").valueOf();
      this.setState({
        calendarOpen: false,
        startOfWeekMillis: v,
        dateMoment: moment(v)
      }, () => this.queryUnitSchedule());
    };
    const next = () => {
      let v = moment(startOfWeekMillis).add(1,"week").startOf("week").valueOf();
      this.setState({
        calendarOpen: false,
        startOfWeekMillis: v,
        dateMoment: moment(v)
      }, () => this.queryUnitSchedule());
    };
    const prevDay = () => {
      let v = moment(this.state.dateMoment.valueOf()).add(-1,"day").valueOf();
      this.setState({
        calendarOpen: false,
        startOfWeekMillis: moment(v).startOf("week").valueOf(),
        dateMoment: moment(v).startOf("day")
      }, () => this.queryUnitSchedule());
    };
    const nextDay = () => {
      let v = moment(this.state.dateMoment.valueOf()).add(1,"day").valueOf();
      this.setState({
        calendarOpen: false,
        startOfWeekMillis: moment(v).startOf("week").valueOf(),
        dateMoment: moment(v).startOf("day")
      }, () => this.queryUnitSchedule());
    };

    let leftIcon = Icons.left();
    let rightIcon = Icons.right();
    let textAlign: CSSProperties["textAlign"] = "right";
    if (Context.instance.uiMode.isRtl) {
      let tmp = leftIcon;
      leftIcon = rightIcon;
      rightIcon = tmp;
      textAlign = "left";
    }

    const when = this.state.when;
    const range = this._makeRangeLabel();
    const { operation, recurrenceType } = this.props;
    let whenNode;
    if (when) {
      const start = moment(when.start.date);
      const end = moment(when.end.date);
      const recurrenceLabel = when.recurrence?.enabled && recurrenceType !== "occurrence"
        ? formatRecurringDates(when.recurrence, start, end, when.duration === "allDay", false, true)
        : "";
      const toggleId = component.nextTmpId("scheduleRecurrenceToggle-");
      const enabled = when.recurrence.enabled;
      const sameDayBooking = isSameDay(start, end);
      const disableRecurrence = operation === "updateBookingTime" ? recurrenceType === "occurrence" : false;
      const recurrenceUI = recurrenceEnabled &&
        <div className={`i-schedule-recurrence i-recurrence-options${!sameDayBooking || operation === "updateBookingTime"
        ? " i-recurrence-disabled"
        : ""}`}>
          <div className="i-toggle-right">
            <label htmlFor={toggleId} className="i-toggle-label">{i18n.more.bookWorkspace.recurrence.toggle}</label>
            <ReactToggle
              id={toggleId}
              className="i-toggle"
              disabled={!sameDayBooking || operation === "updateBookingTime"}
              checked={enabled}
              icons={false}
              onChange={(evt: InputEvent) => {
                const checked = (evt.target as HTMLInputElement).checked;            
                if (checked) {
                  this.setState(prev => ({
                    when: {
                      ...prev.when,
                      recurrence: {
                        ...prev.when.recurrence,
                        endDate: end.isSameOrAfter(when.recurrence.endDate, "d")
                          ? getDefaultEndDate(prev.when.recurrence.type, start.toDate())
                          : prev.when.recurrence.endDate,
                        enabled: true
                      }
                    }
                  }), () => this.showRecurrenceOptions());
                } else {
                  this.setState(prev => ({
                    enableInteraction: true,
                    when: {
                      ...prev.when,
                      recurrence: {
                        ...prev.when.recurrence,
                        enabled: false
                      }
                    }
                  }), () => this.validate());
                }
              }} />
          </div>
        </div>
        
      whenNode = (
        <div className="i-schedule-when">
          {isWeekly && <span className="date-label">{range}</span>}
          {recurrenceEnabled ? !sameDayBooking
              ? <Tooltip placement="bottom-end" positionFixed title={this.props.bookingType === "meetingRooms"
                ? i18n.calendars.recurringToggleDisableMeetingRoom : i18n.calendars.recurringToggleDisable}
                targetWrapperStyle={{ display: "flex", alignItems: "center" }}>{recurrenceUI}</Tooltip>
              : <>
                  <div className="i-schedule-recurrence-label-container">
                    {when.recurrence?.enabled &&
                      <>
                        <button
                          className={`i-schedule-recurrence-label${disableRecurrence ? " i--disabled" : ""}`}
                          style={{ textAlign }}
                          title={recurrenceLabel}
                          onClick={() => this.showRecurrenceOptions()}
                        >
                          {recurrenceLabel}
                        </button>
                        {this.state.hasConflict &&
                          <span title={i18n.more.bookWorkspace.bookingConflictTooltip}>
                            <WarningIcon size={24} className="i--warn" />
                          </span>
                        }
                    </>
                  }
                </div>
                {recurrenceUI}
              </>
             : null 
          }
        </div>
      )
    }

    if (isDaily) {
      return (
        <div className="i-schedule-header">
          <div className="i-schedule-date-picker-container">
            <button className="i-button-gray"
              onClick={prevDay}
              aria-label={i18n.meetingRooms.schedule.previousDayAriaLabel}
              title={i18n.meetingRooms.schedule.previousDay}
            >{leftIcon}</button>
            <button className="i-button-gray i-schedule-today"
              onClick={today}
              aria-label={i18n.meetingRooms.schedule.todayAriaLabel}
            >{i18n.meetingRooms.schedule.today}</button>
            <div className="i--daily i-schedule-date-picker">
              {this.renderDatePicker()}
            </div>
            <button className="i-button-gray"
              onClick={nextDay}
              aria-label={i18n.meetingRooms.schedule.nextDayAriaLabel}
              title={i18n.meetingRooms.schedule.nextDay}
            >{rightIcon}</button>
            {when && <span className="date-label">{range}</span>}
          </div>
          {whenNode}
        </div>
      );
    }

    if (isWeekly) {
      return (
        <div className="i-schedule-header">
          <div className="i-schedule-date-picker-container">
            <button className="i-button-gray"
              onClick={prev}
              aria-label={i18n.meetingRooms.schedule.previousWeekAriaLabel}
              title={i18n.meetingRooms.schedule.previousWeek}
            >{leftIcon}</button>
            <button className="i-button-gray i-schedule-today"
              onClick={today}
              aria-label={i18n.meetingRooms.schedule.todayAriaLabel}
            >{i18n.meetingRooms.schedule.today}</button>
            <div className="i-schedule-date-picker">
              {this.renderDatePicker()}
            </div>
            <button className="i-button-gray"
              onClick={next}
              aria-label={i18n.meetingRooms.schedule.nextWeekAriaLabel}
              title={i18n.meetingRooms.schedule.nextWeek}
            >{rightIcon}</button>
          </div>
          {whenNode}
        </div>
      );
    }

  }

  renderSchedule() {

    const now = Date.now();
    const nowOffset = now - moment(now).startOf("day").valueOf();
    const hfmt = dateUtil.getMomentHourFormat(false);
    const { reservationInfo, startOfWeekMillis, isWeekly, recurrenceEnabled } = this.state;
    const isDaily = !isWeekly;
    const isHotel = !!this.props.item.isHotel;
    const dragInfo: IDragInfo = {};
    const isOngoing = !!(this.props.operation === "updateBookingTime" && this.props.isOngoingBooking);

    let pixelHeightPerHour = 40;
    let pixelWidthPerDay = 100;
    let pixelHeightPerDay = 24 * pixelHeightPerHour;
    let dayLabelHeight = 0;
    let hourLabelWidth = 60;
    let hourLabelAlign: CSSProperties["textAlign"] = "left";
    let minPanelWidth = (pixelWidthPerDay * 7) + hourLabelWidth + 50;
    let contentTop = 45;
    let contentHeight = "260px";
    let minPanelHeight = 300;
    let maxContentWidth = "none";
    let dragHandleSize = 16;
    let dragHandleHalfSize = 8;
    let shouldScroll = true;

    let lineColor = "#e8e8e8";
    let lineColorNow = "#005e95";
    let backgroundColor = "#f6f6f6";
    let dayBorder = "1px solid "+lineColor;
    //let busyBackgroundColor = "#d3d3d3";
    let busyTextColor = "var(--i-theme-color-brand-text)";
    let busyBackgroundColor = "var(--i-theme-color-brand)";
    let busyBorder = "1px solid var(--i-theme-color-border)";

    let whenTextColor = "var(--i-theme-color-brand-text)";
    const whenBackgroundColor = "lightgreen";
    const whenBorder = "1px solid darkgreen";
    const whenConflictBackgroundColor = "palevioletred";
    const whenConflictBorder = "1px solid mediumvioletred";
    let dragHandleColor = "transparent";

    let lrProp = "left";
    if (Context.instance.uiMode.isRtl) {
      lrProp = "right";
      hourLabelAlign = "right";
    }

    let millisDay1Start = startOfWeekMillis;
    let millisPerDay = (1000 * 60 * 60 * 24);

    if (isDaily) {
      let v = this.state.dateMoment.valueOf();
      millisDay1Start = moment(v).startOf("day").valueOf();
      dayLabelHeight = 0;
      pixelWidthPerDay = 240;
      if (Context.instance.uiMode.mode === UIMode.MODE_VERTICAL_SPLIT) {
        pixelWidthPerDay = 180;
      }
      contentTop = 0;
      contentHeight = "300px";
      minPanelWidth = (pixelWidthPerDay * 1) + hourLabelWidth + 20;
      maxContentWidth = minPanelWidth+"px";
    }

    let getTop = ((dt,millisStart,millisEnd) => {
      let t = dt.getTime();
      if (t < millisStart) t = millisStart;
      if (t > millisEnd) t = millisEnd;
      let top = ((t - millisStart) / millisPerDay) * pixelHeightPerDay;
      return top + dayLabelHeight;
    });

    let singleClick = (evt, d) => {
      if (!this.state.enableInteraction) return;
      evt.stopPropagation();
      if (isOngoing) return;
      let rect, target = evt && evt.target;
      if (target && target.getAttribute("data-i-day") === "y") {
        rect = target.getBoundingClientRect();
      }
      if (!rect) return;
      let y = evt.clientY - rect.top;
      let millis = (y / pixelHeightPerDay) * millisPerDay;
      let m = moment(millisDay1Start).add(d,"day").startOf("day").add(millis,"milliseconds");
      let prev = moment(m.toDate()).startOf("hour").valueOf();
      let next = moment(prev).add(1,"hour").valueOf();
      let vmillis = prev;
      if (prev > Date.now()) {
        reservationInfo.reserved.some(r => {
          if ((r.toDate > prev) && (r.toDate < next)) {
            vmillis = next;
            return true;
          }
          return false;
        });
      }
      if (vmillis > Date.now()) {
        let dtEnd = dateUtil.addDuration(new Date(vmillis),"oneHour");
        const when = { ...this.state.when };
        when.duration = null;
        when.start.date = vmillis;
        when.end.date = dtEnd.getTime();
        this.setState({ when });
      }
    };

    let drag = (evt,d) => {
      if (!this.state.enableInteraction) return;
      evt.preventDefault();
      let elWhen = document.getElementById(this.componentId+"when-"+d);
      let rectWhen = elWhen && elWhen.getBoundingClientRect();
      let elDay = document.getElementById(this.componentId+"day-"+d);
      let rectDay = elDay && elDay.getBoundingClientRect();
      if (!rectWhen || !rectDay) return;

      let resize = true;
      if (dragInfo.pos === "bottom") {
        let h = evt.clientY - rectWhen.top;
        if (h < rectWhen.height) {
          // don't resize when dragging from the bottom up
          resize = false;
        }
      }

      const scroll = () => {
        // TODO: Determine if this `if` block is ever run (scrollTop is a number, not a function like jQuery)
        // @ts-ignore 
        if (elDay && elDay.scrollTop === "function") elDay.scrollTop(evt.clientY);
        shouldScroll = true;
      }

      let x = evt.clientX;
      let minX = rectDay.x;
      let maxX = rectDay.x + rectDay.width;
      if (x < minX || x > maxX) return;

      if (dragInfo.pos === "top") {
        let t = evt.clientY - rectDay.top + dayLabelHeight;
        let h = rectWhen.height + (rectWhen.top - evt.clientY);
        if (resize) elWhen.style.top = (t+dragHandleHalfSize)+"px";
        if (resize) elWhen.style.height = (h-dragHandleHalfSize)+"px";
        if (h > dragInfo.initialHeight && shouldScroll) {
          shouldScroll = false;
          setTimeout(scroll, 10);
        }
      } else if (dragInfo.pos === "bottom") {
        let h = evt.clientY - rectWhen.top;
        if (resize) elWhen.style.height = h+"px";
        if (h > dragInfo.initialHeight && shouldScroll) {
          shouldScroll = false;
          setTimeout(scroll, 10);
        }
      }
    }

    let dragEnd = (evt,d) => {
      if (!this.state.enableInteraction) return;
      let elWhen = document.getElementById(this.componentId+"when-"+d);
      if (elWhen) {
        let it = dragInfo.initialTop;
        let ih = dragInfo.initialHeight;
        if (typeof it === "number") elWhen.style.top = it+"px";
        if (typeof ih === "number") elWhen.style.height = ih+"px";
      }
      dragInfo.activeDay = null;
      dragInfo.initialTop = null;
      dragInfo.initialHeight = null;
      dragInfo.pos = null;
      component.refresh(this);
    }

    let drop = (evt,d) => {
      if (!this.state.enableInteraction) return;
      evt.preventDefault();
      let elWhen = document.getElementById(this.componentId+"when-"+d);
      let rectWhen = elWhen && elWhen.getBoundingClientRect();
      let elDay = document.getElementById(this.componentId+"day-"+d);
      let rectDay = elDay && elDay.getBoundingClientRect();

      if (!rectWhen || !rectDay) {
        component.refresh(this);
        return;
      }
      let y = evt.clientY - rectDay.top;

      let millis = (y / pixelHeightPerDay) * millisPerDay;
      let mDayStart = moment(millisDay1Start).add(d,"day").startOf("day");
      let millisDayStart = mDayStart.valueOf();
      let millisDayEnd = moment(millisDayStart).endOf("day").valueOf();
      let m = moment(millisDay1Start).add(d,"day").startOf("day").add(millis,"milliseconds");
      let prev = moment(m.toDate()).startOf("hour").valueOf();
      let next = moment(prev).add(1,"hour").valueOf();

      // Allow resizing the calendar selection to 30 minutes duration #4559
      let half = moment(prev).add(30,"minutes").valueOf();
      let mcur = m.valueOf();
      let d1 = Math.abs(prev - mcur);
      let d2 = Math.abs(half - mcur);
      let d3 = Math.abs(next - mcur);
      let snapped = prev;
      if ((d1 < d2) && (d1 < d3)) {
        snapped = prev;
      } else if ((d2 < d1) && (d2 < d3)) {
        snapped = half;
      } else if ((d3 < d1) && (d3 < d2)) {
        snapped = next;
      }

      const when = { ...this.state.when };
      if (dragInfo.pos === "top") {
        let vmillis = snapped;
        if (vmillis < millisDayStart) vmillis = millisDayStart;
        if (vmillis !== dragInfo.r.fromDate && vmillis < dragInfo.r.toDate) {
          when.start.date = vmillis;
          dragInfo.initialTop = null;
          dragInfo.initialHeight = null;
          this.setState({ when });
        } else {
          dragEnd(evt,d);
        }
      } else if (dragInfo.pos === "bottom") {
        let vmillis = snapped;
        if (vmillis > millisDayEnd) vmillis = millisDayEnd;
        if (vmillis !== dragInfo.r.toDate && vmillis > dragInfo.r.fromDate) {
          when.end.date = vmillis;
          dragInfo.initialTop = null;
          dragInfo.initialHeight = null;
          this.setState({ when });
        } else {
          dragEnd(evt,d);
        }
      }
    }

    let rclicked = (evt,d,r) => {
      evt.stopPropagation();
      let target = evt && evt.target;
      if (target && target.getAttribute("data-i-res") === "y") {
        this.showMeetingInfo(r);
      }
    };

    let wclicked = (evt,d,r) => {
      if (!this.state.enableInteraction) return;
      evt.stopPropagation();
    };

    let dayLabels = [];
    if (isWeekly) {
      for (let d=0;d<7;d++) {
        const now = moment();
        let mDay = moment(millisDay1Start).add(d,"day");
        const isToday = now.isSame(mDay, 'day');
        let offsetLR = hourLabelWidth;
        let lrVal = offsetLR + (pixelWidthPerDay * d);
        let top = 0;
        const clsDate = isToday ? `${CSS.dateLabel} today` : CSS.dateLabel;
        const clsDay = isToday ? `${CSS.dayLabel} today` : CSS.dayLabel;
        let lbl = (
          <div className={CSS.labelGroup}>
            <div className={clsDate} style={{fontSize:"12px"}}>{mDay.date()}</div>
            <div className={clsDay} style={{fontSize:"12px"}}>{mDay.format("dddd")}</div>
          </div>
        )
        let nd = (
          <div key={"dl"+mDay.valueOf()}
            style={{
              fontSize: "12px",
              position: "absolute",
              [lrProp]: lrVal+"px",
              top: top+"px",
              width: pixelWidthPerDay+"px",
              height: "60px",
              lineHeight: "1rem",
              textAlign: "center",
              overflow: "hidden"
            }}
          >{lbl}</div>
        );
        dayLabels.push(nd);
      }
    }

    let hourLabels = [];
    for (let h=0;h<24;h++) {
      // Schedule view check in / check out times do not match the green box on calendar during week of daylight savings #4823
      // label the hours of the day using Monday instead of Sunday
      let m = moment(new Date(millisDay1Start)).add(1,"d").add(h,"hour");
      let lbl = m.format(hfmt);
      let top = h * pixelHeightPerHour + dayLabelHeight;
      let lrVal = 0;
      let nd = (
        <div key={"hl"+m.valueOf()}
          style={{
            fontSize: "12px",
            marginTop: "-10px",
            position: "absolute",
            [lrProp]: lrVal+"px",
            top: top+"px",
            width: hourLabelWidth+"px",
            textAlign: hourLabelAlign,
            overflow: "hidden"
          }}
        >{lbl}</div>
      );
      hourLabels.push(nd);
    };

    let days = [], separators = [], nowLine = [];
    for (let d=0;d<7;d++) {
      if (isDaily && d > 0) break;
      let mDayStart = moment(millisDay1Start).add(d,"day").startOf("day");
      let millisDayStart = mDayStart.valueOf();
      let offsetLR = hourLabelWidth;
      let lrVal = offsetLR + (pixelWidthPerDay * d);
      let nd = (
        <div id={this.componentId+"day-"+d}
          key={"d"+millisDayStart}
          role="button"
          tabIndex={0}
          data-i-day="y"
          onKeyDown={evt => {
            if (evt.key === "Enter") singleClick(evt,d)
          }}
          onClick={evt => singleClick(evt,d)}
          onDragOver={evt => {
            if (dragInfo.activeDay === d) {
              evt.preventDefault();
            }
          }}
          onDrop={evt => {
            if (dragInfo.activeDay === d) {
              drop(evt,d);
            }
          }}
          style={{
            position: "absolute",
            [lrProp]: lrVal+"px",
            top: dayLabelHeight+"px",
            height: pixelHeightPerDay+"px",
            width: pixelWidthPerDay+"px",
            backgroundColor: backgroundColor,
            border: dayBorder
          }}
        ></div>
      );
      days.push(nd);

      let dt,top;
      for (let h=1;h<24;h++) {
        dt = moment(new Date(millisDayStart)).add(h,"hour").toDate();
        top = getTop(dt,millisDayStart,millisDayStart + millisPerDay);
        nd = (
          <div key={"hs"+d+"_"+h} 
            onDragOver={evt => {
              if (dragInfo.activeDay === d) {
                evt.preventDefault();
              }
            }}
            style={{
              position: "absolute",
              [lrProp]: lrVal+"px",
              top: top+"px",
              width: pixelWidthPerDay+"px",
              height: "1px",
              backgroundColor: lineColor
            }}
          ></div>
        );
        separators.push(nd);
      }

      dt = moment(millisDayStart+nowOffset).toDate();
      top = getTop(dt,millisDayStart,millisDayStart + millisPerDay);
      nd = (
        <div
          key={"n" + d}
          data-i-res="n"
          style={{
            position: "absolute",
            [lrProp]: lrVal+"px",
            top: top+"px",
            width: pixelWidthPerDay,
            height: "2px",
            backgroundColor: lineColorNow
          }}
        ></div>
      );
      nowLine.push(nd);

    }

    let whenList = [];
    const when = this.state.when;
    if (when) {
      let r: IDragInfo["r"] = {
        fromDate: when.start.date,
        toDate: when.end.date
      }
      if (when.duration === "allDay") {
        const start = moment(when.start.date).startOf("day");
        const end = moment(when.end.date).endOf("day");
        r.fromDate = start;
        r.toDate = end;
      }
      const recurringDates = when.recurrence?.enabled
        ? getRecurringDates(when.recurrence, moment(r.fromDate), moment(r.toDate))
        : null;
      const check = (r: IDragInfo["r"], start: number, end: number) => (r.fromDate < end) && (r.toDate > start);
      
      const addBookingBlock = (d: number, r: { fromDate: moment.MomentInput, toDate: moment.MomentInput }, recurringIndex?: number) => {
        const offsetLR = hourLabelWidth;
        const lrVal = offsetLR + (pixelWidthPerDay * d);
        const mDayStart = moment(millisDay1Start).add(d,"day").startOf("day");
        const millisDayStart = mDayStart.toDate().getTime();
        const start = moment(r.fromDate);
        const end = moment(r.toDate);
        const dt1 = start.toDate();
        const dt2 = end.toDate();
        const y1 = getTop(dt1,millisDayStart,millisDayStart + millisPerDay);
        const y2 = getTop(dt2,millisDayStart,millisDayStart + millisPerDay);
        const h = y2 - y1;
        const sameStart = start.isSame(moment(mDayStart),"day");
        const sameEnd = end.isSame(moment(mDayStart), "day");
        const noConflict = !this.hasConflict({ start, end });
        let isOriginalBooking = false;
        if (this.props.operation === "updateBookingTime" && this.props.when) {
          if (r.fromDate === this.props.when.start.date && r.toDate === this.props.when.end.date) {
            isOriginalBooking = true;
          }
        }
        const recurrence = recurringIndex != null ? `_${recurringIndex}` : "";
        let nd = (
          <div id={this.componentId + "when-" + d + recurrence}
            key={"w" + d + "_when"}
            role="button"
            tabIndex={0}
            data-i-res="w"
            onKeyDown={evt => wclicked(evt, d, r)}
            onClick={evt => wclicked(evt, d, r)}
            onDragOver={evt => {
              if (dragInfo.activeDay === d) {
                evt.preventDefault();
              }
            }}
            onDrop={evt => {
              if (dragInfo.activeDay === d) {
                drop(evt, d);
              }
            }}
            style={{
              position: "absolute",
              [lrProp]: lrVal + "px",
              top: y1 + "px",
              height: h + "px",
              width: pixelWidthPerDay + "px",
              overflow: "hidden",
              color: whenTextColor,
              backgroundColor: noConflict || isOriginalBooking ? whenBackgroundColor : whenConflictBackgroundColor,
              border: noConflict || isOriginalBooking ? whenBorder : whenConflictBorder,
              borderRadius: "6px",
              opacity: "0.5"
            }}
          >
          </div>
        );
        whenList.push(nd);
        
        // no drag handles if recurrence enabled
        if (!this.state.enableInteraction) {
          return;
        }
        if (sameStart && !isOngoing) {
          let lrv = lrVal;
          nd = (
            <div id={this.componentId + "dh-top-" + d}
              key={"w" + d + "_dhTop"}
              data-i-dh-bottom="y"
              draggable
              onDragStart={evt => {
                dragInfo.r = r;
                dragInfo.activeDay = d;
                dragInfo.initialTop = y1;
                dragInfo.initialHeight = h;
                dragInfo.lastResize = 0;
                dragInfo.pos = "top";
              }}
              onDrag={evt => {
                drag(evt, d);
              }}
              onDragEnd={evt => {
                dragEnd(evt, d);
              }}
              onDragOver={evt => {
                if (dragInfo.activeDay === d) {
                  evt.preventDefault();
                }
              }}
              style={{
                content: "",
                position: "absolute",
                [lrProp]: lrv + "px",
                top: (y1 - dragHandleHalfSize) + "px",
                height: dragHandleSize + "px",
                width: (pixelWidthPerDay / 1) + "px",
                overflow: "hidden",
                backgroundColor: dragHandleColor,
                cursor: "row-resize"
              }}
            >
            </div>
          );
          whenList.push(nd);
        }

        if (sameEnd) {
          let lrv = lrVal;
          nd = (
            <div id={this.componentId + "dh-bottom-" + d}
              key={"w" + d + "_dhBottom"}
              data-i-dh-bottom="y"
              draggable
              onDragStart={evt => {
                dragInfo.r = r;
                dragInfo.activeDay = d;
                dragInfo.initialTop = y1;
                dragInfo.initialHeight = h;
                dragInfo.lastResize = 0;
                dragInfo.pos = "bottom";
              }}
              onDrag={evt => {
                drag(evt, d);
              }}
              onDragEnd={evt => {
                dragEnd(evt, d);
              }}
              onDragOver={evt => {
                if (dragInfo.activeDay === d) {
                  evt.preventDefault();
                }
              }}
              style={{
                content: "",
                position: "absolute",
                [lrProp]: lrv + "px",
                top: (y1 + h - dragHandleHalfSize) + "px",
                height: dragHandleSize + "px",
                width: (pixelWidthPerDay / 1) + "px",
                overflow: "hidden",
                backgroundColor: dragHandleColor,
                cursor: "row-resize"
              }}
            >
            </div>
          );
          whenList.push(nd);
        }
      }
      const sameDayBooking = isSameDay(moment(r.fromDate), moment(r.toDate));
      for (let d = 0; d < 7; d++) {
        if (isDaily && d > 0) break;
        const mDayStart = moment(millisDay1Start).add(d,"day").startOf("day");
        const millisDayStart = mDayStart.toDate().getTime();
        const millisDayEnd = millisDayStart + millisPerDay;
        let intersects = false;
        
        if (recurringDates?.length > 0 && sameDayBooking && this.props.recurrenceType !== "occurrence") {
          intersects = recurringDates.some(({ start, end }) =>
            check({ fromDate: start, toDate: end }, millisDayStart, millisDayEnd));
        } else {
          intersects = check(r, millisDayStart, millisDayEnd);
        }
        if (!intersects) continue;
        if (recurrenceEnabled && recurringDates?.length > 0) {
          const dateIndex = recurringDates?.findIndex(({ start }) => start.isSame(mDayStart, "day"));
          const date = recurringDates[dateIndex];
          if (date && sameDayBooking) {
            addBookingBlock(d, { fromDate: date.start, toDate: date.end }, dateIndex);
          } else {
            addBookingBlock(d, r);
          }
        } else {
          addBookingBlock(d, r);
        }
      }
    }

    let list = [];
    if (reservationInfo && reservationInfo.reserved && reservationInfo.reserved.length > 0) {
      for (let d=0;d<7;d++) {
        if (isDaily && d > 0) break;
        let mDayStart = moment(millisDay1Start).add(d,"day").startOf("day");
        let millisDayStart = mDayStart.toDate().getTime();
        let millisDayEnd = millisDayStart + millisPerDay;
        reservationInfo.reserved.forEach(r => {
          let intersects = (r.fromDate < millisDayEnd) && (r.toDate > millisDayStart);
          if (!intersects) return;
          let offsetLR = hourLabelWidth;
          let lrVal = offsetLR + (pixelWidthPerDay * d);
          let dt1 = new Date(r.fromDate);
          let dt2 = new Date(r.toDate);
          let y1 = getTop(dt1,millisDayStart,millisDayStart + millisPerDay);
          let y2 = getTop(dt2,millisDayStart,millisDayStart + millisPerDay);
          let h = y2 - y1;
          let dsc = r.title;
          if (isHotel) dsc = r.reserveeFullName;
          let dscNode;
          let isOriginalBooking = false;
          if (this.props.operation === "updateBookingTime" && this.props.when) {
            if (r.fromDate === this.props.when.start.date && r.toDate === this.props.when.end.date) {
              isOriginalBooking = true;
            }
          }

          if (dsc) {
            dscNode = (
              <div className="i-scheduled-item"
                role="button"
                tabIndex={0}
                data-i-res="y"
                onKeyDown={evt => rclicked(evt,d,r)}
                onClick={evt => rclicked(evt,d,r)}
                onDragOver={evt => {
                  if (isOriginalBooking && dragInfo.activeDay === d) {
                    evt.preventDefault();
                  }
                }}
                onDrop={evt => {
                  if (isOriginalBooking && dragInfo.activeDay === d) {
                    drop(evt,d);
                  }
                }}
                style={{
                  fontSize: "12px",
                  margin: "4px",
                  padding: "1px",
                  cursor: "default"
                }}
              >{dsc}</div>
            )
          }
          let nd = (
            <div key={"r"+d+"_"+r.oid}
              role="button"
              tabIndex={0}
              data-i-res="y"
              onKeyDown={evt => rclicked(evt,d,r)}
              onClick={evt => rclicked(evt,d,r)}
              onDragOver={evt => {
                if (isOriginalBooking && dragInfo.activeDay === d) {
                  evt.preventDefault();
                }
              }}
              onDrop={evt => {
                if (isOriginalBooking && dragInfo.activeDay === d) {
                  drop(evt,d);
                }
              }}
              style={{
                position: "absolute",
                [lrProp]: lrVal+"px",
                top: y1+"px",
                height: h+"px",
                width: pixelWidthPerDay+"px",
                overflow: "hidden",
                color: busyTextColor,
                backgroundColor: busyBackgroundColor,
                border: busyBorder,
                borderRadius: "6px"
              }}
            >
              {dscNode}
            </div>
          )
          list.push(nd);
        });
      }
    }

    return (
      <div className="i--week"
        style={{
          maxWidth: "auto",
          minWidth: minPanelWidth+"px",
          minHeight: minPanelHeight+"px"}}>
        <div style={{position: "relative", boxSizing:"border-box"}}>
          <div
            style={{
              position: "absolute",
            }}>
            <div
              style={{
                position: "relative"
              }}>
              {dayLabels}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: contentTop+"px",
              paddingTop: "10px",
              width: "100%",
              maxWidth: maxContentWidth,
              height: contentHeight,
              overflowY:"scroll",
              overflowX: "hidden"
            }}>
            <div
              style={{
                position: "relative"
              }}>
              {hourLabels}
              {days}
              {separators}
              {list}
              {whenList}
              {nowLine}
            </div>
          </div>
        </div>
      </div>
    );
  }

  scrollIntoView() {
    const when = document.querySelectorAll(`div[data-i-res="w"]`);
    const now = document.querySelectorAll(`div[data-i-res="n"]`);
    const nl = this.state.when && when.length ? when : now;
    if (nl && nl.length > 0) {
      const nd = nl[0];
      if (nd && typeof nd.scrollIntoView === "function") {
        const opts: ScrollIntoViewOptions = {block: "center", inline: "nearest"};
        nd.scrollIntoView(opts);
      }
    }
  }

  showMeetingInfo(reservation: IScheduleItem) {
    const ctx = Context.getInstance();
    const { i18n, lib } = ctx;

    const reservationsDataset = ctx.aiim.datasets && ctx.aiim.datasets.reservations;
    const unitsDataset = ctx.aiim.datasets && ctx.aiim.datasets.units;
    const reservedForUsername = reservationsDataset
      ? reservation.feature.attributes[reservationsDataset.reservedForUsernameField]
      : null;
    const currentUsername = ctx.user.getUsername();
    const calendarParams = reservationsDataset && currentUsername != null && currentUsername === reservedForUsername
      ? makeEventInfo(reservationsDataset, reservation.feature, unitsDataset, this.props.item.unitFeature)
      : null;

    const levels = ctx.aiim.datasets.levels;
    let title = i18n.meetingRooms.meetingDetails;
    let dtFrom = new Date(reservation.fromDate);
    let dtTo = new Date(reservation.toDate);
    let from = lib.dojo.locale.format(dtFrom,{formatLength:"short"});
    let to = lib.dojo.locale.format(dtTo,{formatLength:"short"});
    let range = i18n.meetingRooms.rangePattern.replace("{fromDate}",from).replace("{toDate}",to);
    let unit = reservation.unitName;
    let fl;
    let ld = levels && levels.getLevelData(reservation.levelId);
    if (ld) {
      let p = i18n.meetingRooms.levelAndFacility;
      fl = p.replace("{levelName}",ld.levelName).replace("{facilityName}",ld.facilityName);
    }
    if (this.props.item.isHotel) {
      title = i18n.meetingRooms.reservationDetails;
    }

    // let icsNode, icsInfo = reservation.icsInfo;
    // if (icsInfo && typeof icsInfo.href === "string") {
    //   icsNode = <IcsLink icsInfo={icsInfo} />
    // }

    // let content = (
    //   <div className="i-meeting-details">
    //     <div className="i-text-center"><strong>{reservation.title}</strong></div>
    //     <div className="i--row"><span><ClockIcon /></span><span>{range}</span></div>
    //     <div className="i--row"><span><PinIcon /></span><span>{unit}<br />{fl}</span></div>
    //     <div className="i--row"><span><UserIcon /></span><span>{orgBy}</span></div>
    //     {!!icsNode &&
    //       <div className="i-text-center i-separated">{icsNode}</div>
    //     }
    //   </div>
    // );

    const controller = new ModalController();
    const content = (
      <MeetingInformation
        range={range}
        unit={unit}
        fl={fl}
        isHotel={this.props.item.isHotel}
        reservation={reservation}
        calendarParams={calendarParams}
        onShowOccupant={() => {
          controller.close();
          this.props.onShowOccupant && this.props.onShowOccupant();
        }}
      />
    );

    let modalProps = {
      title: title,
      className: "i-modal-confirm i-modal-transparent",
      showOKCancel: false
    }
    controller.show(modalProps,content);
  }

  showRecurrenceOptions() {
    const i18n = Context.instance.i18n;
    const { start: { date: startDate } } = this.state.when;
    let recurrence = {
      ...this.state.when.recurrence,
      enabled: true
    };
    let okButton;
    const controller = new ModalController();
    const modalProps = {
      className: "i-modal-confirm i-schedule-recurrence",
      title: i18n.more.bookWorkspace.recurrence.toggle,
      okLabel: i18n.general.done,
      showOKCancel: true,
      hideCancel: true,
      closeOnOK: false,
      dialogStyle: { width: "320px" },
      contentStyles: { paddingBottom: "0", minHeight: "160px" },
      onMountOKButton: btn => okButton = btn,
      onOK: () => {
        this.setState(prev => ({
          enableInteraction: false,
          when: {
            ...prev.when,
            recurrence: {
              ...prev.when.recurrence,
              ...recurrence
            }
          }
        }), () => {
          this.validate();
          controller.close();
        });
      }
    };
    const content = (
      <RecurrenceOptions
        start={startDate}
        options={recurrence}
        onUpdate={options => {
          if (!okButton) return;
          recurrence = options;
          (recurrence.type === "weekly" && recurrence.days.length > 0) || recurrence.type === "daily"
            ? okButton.enable()
            : okButton.disable();
        }}
      />    
    )
    controller.show(modalProps,content);
  }
  private showWarning() {
    const { warnings } = this.state;
    if (warnings?.length > 0) {
      const i18n = Context.getInstance().i18n;
      const title = i18n.more.bookWorkspace.bookingConflict;
      const warningMsgs = warnings?.length > 1
        ? warnings.map(warning => <li>{warning.msg}</li>)
        : warnings[0].msg;
      const modalOptions = {
        title: title,
        hideCancel: true,
        okLabel: i18n.general.ok,
        content: (
          <div className={CSS.limitHotelSchedule}>
            <div style={{ justifyContent: "center" }}><WarningIcon2 className="i--warn" size={48} /></div>
            {warningMsgs.length === 1
              ? <div style={{ maxWidth: "40vw" }}>{warningMsgs[0]}</div>
              : <ul className="i-list">{warningMsgs}</ul>
            }
          </div>
        )
      };
      ModalController.confirm(modalOptions);
    }
  }
  private async validate() {
    try {
      this.setState({ isWorking: true });
      const results = await Promise.all([
        this.checkAvailability(),
        this.checkLimits()
      ]);
      this.setState({
        hasConflict: !results.every(r => !!r),
      }, () => {
        if (results[1] === false) {
          this.showWarning();
        }
      })
    } catch (ex) {
      console.error(ex);
    } finally {
      this.setState({ isWorking: false });
    }
  }
  _onCheckInOutChanged = (startDate: Date, endDate: Date, allDay: boolean) => {
    if (!startDate || !endDate) {
      return;
    }

    const when = { ...this.state.when };
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    if (endMoment.isAfter(startMoment, "minute") &&
      (!allDay || 
      !startMoment.isSame(moment(when.start.date), "day") ||
      !endMoment.isSame(moment(when.end.date), "day"))
    ) {
      when.start.date = startMoment.valueOf();
      when.end.date = endMoment.valueOf();
      // disabled recurrence if multi-day
      if (when.recurrence?.enabled && this.props.recurrenceType !== "occurrence" && !isSameDay(startMoment, endMoment)) {
        when.recurrence.enabled = false;
      } else if (!when.recurrence?.enabled && this.props.recurrenceType === "series" && isSameDay(startMoment, endMoment)) {
        when.recurrence.enabled = true;
      }
    }
    when.duration = !!allDay ? "allDay" : null;
    if (when.recurrence?.enabled
      && this.props.recurrenceType !== "occurrence"
      && endMoment.isSameOrAfter(when.recurrence.endDate, "d")) {
      when.recurrence.endDate = getDefaultEndDate(when.recurrence.type, new Date(when.end.date));
    }

    let dtMoment = moment(startDate);
    let v = moment(startDate).startOf("week").valueOf();
    
    this.setState({
      when,
      dateMoment: dtMoment,
      startOfWeekMillis: v
    }, () => {
      this.queryUnitSchedule();
      this.validate();
    });
  }

  _makeRangeLabel = () => {
    const lib = Context.getInstance().lib;
    const { startOfWeekMillis, isWeekly, dateMoment } = this.state;

    const currentDateNum = dateMoment.date();
    const startDate = new Date(startOfWeekMillis);
    const endDate = moment(startDate).add(6, "day");
    const startNum = startDate.getDate();
    const endNum = endDate.date();
    const dayOfWeek = lib.dojo.locale.format(dateMoment.toDate(), {
      formatLength: "full",
      selector: "date",
      datePattern: "EEEE"
    });

    if (isWeekly) {
      if (endDate.isSame(startDate, "month")) {
        const month = lib.dojo.locale.format(new Date(startOfWeekMillis), { selector: "date", datePattern: "MMMM" });
        return `${month} (${startNum} - ${endNum})`;
      } else {
        const month1 = lib.dojo.locale.format(startDate, { selector: "date", datePattern: "MMMM" });
        const month2 = lib.dojo.locale.format(endDate.toDate(), { selector: "date", datePattern: "MMMM" });
        return `${month1} ${startNum} - ${month2} ${endNum}`;
      }
    } else {
      return `${currentDateNum} ${dayOfWeek}`;
    }
  }

}
const RecurrenceOptions = ({ start, options, onUpdate }: {
  start: number,
  onUpdate: (options: IRecurrenceOptions) => void,
  options: IRecurrenceOptions
}) => {
  const [recurrenceOptions, setRecurrenceOptions] = useState<IRecurrenceOptions>(options.type != null
    ? { ...options }
    : getDefaultRecurrenceOptions({ enabled: true, type: "weekly" }, start)
  );
  return (
    <BookingRecurrence
      {...recurrenceOptions}
      showToggle={false}
      startDate={new Date(start)}
      onUpdate={recurrence => {
        setRecurrenceOptions(recurrence);
        onUpdate(recurrence);
      }}
    />
  )
}
/* ------------------------------------------------------------ */
interface IMeetingInformationProps {
  range: string,
  unit: string,
  fl: string,
  isHotel: boolean,
  reservation: IScheduleItem,
  calendarParams: Promise<ICalendarEventInfo>,
  onShowOccupant: () => void
}
interface IMeetingInformationState {
  occupant?: __esri.Graphic,
  popoverOpen: boolean
}
class MeetingInformation extends React.Component<IMeetingInformationProps, IMeetingInformationState> {
  private mounted: boolean = false;
  constructor(props: IMeetingInformationProps) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }
  override componentDidMount() {
    this.mounted = true;
    this.getOccupant();
  }
  override componentWillUnmount() {
    this.mounted = false;
  }
  _togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }
  private async getOccupant() {
    const { reservation: { reserveeUsername, reserveeFullName, feature } } = this.props;
    const people = Context.getInstance().aiim.datasets?.people;
    if (people) {
      const occupant = await people.getOccupantByFullName(reserveeUsername, reserveeFullName);
      this.mounted && this.setState({ occupant });
    }
  }
  async showOccupantInfo() {
    const { occupant } = this.state;
    if (occupant) {
      // FIXME: i18n - Move to resources.js next release (see #8074)
      // Need a new string for the modal elements (e.g. i18n.meetingRooms.schedule.occupant.modalTitle)
      const modalProps = {
        title: "Close Schedule",
        message: "Close the schedule and open the occupant's information card?",
        className: "i-modal-confirm i-modal-transparent"
      }
      const result = await ModalController.confirm(modalProps);
      if (result.ok) {
        const ctx = Context.getInstance();
        const person = { feature: occupant };
        const people = ctx.aiim.datasets?.people;
        const source = people?.getSource();
        const layer = people?.layer2D;
        // const knownas = getAttributeValue(person.feature.attributes, FieldNames.PEOPLE_FULLNAME);

        // // See if the user has ongoing and/or upcoming reservations
        // const upcomingReservations = await findUserReservations(person, "upcoming");
        // const pastReservation = await findUserReservations(person, "past");

        try {
          const results = await findDuplicateRecords(layer, person.feature.attributes);
          const features = results?.features;
          Topic.publish(Topic.ShowSearchResult, {
            sourceKey: source?.key ?? "standalone",
            isNonAiimFeature: source == null,
            searchResult: { feature: occupant },
            zoom: features?.length === 1,
            highlight: true,
            trackRecent: true,
            showLocations: { person: { feature: occupant } },
            reservation: this.props.reservation
          });
          this.props.onShowOccupant();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
  render() {
    const i18n = Context.getInstance().i18n;
    const { range, unit, fl, isHotel, reservation: { title, reserveeFullName }, calendarParams } = this.props;
    const { popoverOpen, occupant } = this.state;
    const template = isHotel ? i18n.meetingRooms.reservedFor : i18n.meetingRooms.organizedBy;
    let orgBy;
    if (reserveeFullName) {
      orgBy = occupant ? template.replace("{person}", "") : template.replace("{person}", reserveeFullName);
    }

    const addToCalendarButton = (
      <button
        className={"i-button"}
        onClick={this._togglePopover}
      >
        {i18n.events.details.addToCalendar}
      </button>
    );

    const calendarSelector = (
      <div className="i-text-center i-separated">
        <CalendarSelector
          onRequestClose={this._togglePopover}
          open={popoverOpen}
          targetEl={addToCalendarButton}
          params={calendarParams}
        />
      </div>
    );

    return (
      <div className="i-meeting-details">
        <div className="i-text-center"><strong>{title}</strong></div>
        <div className="i--row"><span><ClockIcon /></span><span>{range}</span></div>
        <div className="i--row"><span><PinIcon /></span><span>{unit}<br />{fl}</span></div>
        {reserveeFullName &&
          <div className="i--row">
            <span><UserIcon /></span>
            <span>{orgBy}</span>
          {occupant && 
            <a href="#" onClick={() => this.showOccupantInfo()}>&nbsp;{reserveeFullName}</a>}
          </div>
        }
        {calendarParams && calendarSelector}
      </div>
    );
  }
}
