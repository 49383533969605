import React from "react";

import DeleteIcon from "calcite-ui-icons-react/XIcon";
import Tabs, { TabContents, TabSection } from "calcite-react/Tabs";

import { ListItem, ListItemTitle, ListItemTitleIcon, ListItemTitleMain } from "../../styles/Common/list";
import { TabNav, TabTitle } from "../../styles/appStyles";

import { AreaStats } from "../stats";
import Button from "../../../common/components/Button";

import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Items from "../common/Items";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import UnassignPrompt from "../common/UnassignPrompt";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as sourceUtil from "../../base/sourceUtil";
import * as transactions from "../../base/transaction/transactions";
import Popup from "../common/Popup/Popup";
import { canDeleteDuplicate } from "../common/MultipleAssignments/multipleAssignmentsUtil";

import FloorPlanIcon from "calcite-ui-icons-react/FloorPlanIcon";
import HotDeskIcon from "calcite-ui-icons-react/DeskIcon";
import HotelIcon from "calcite-ui-icons-react/ServicesIcon";
import MeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";

interface IPeopleAndUnitsProps {
  areaItem: __esri.Graphic,
  disableUsageButton?: boolean,
  showUsageButton?: boolean,
  onShowUsageClicked?: (e) => void
}

interface IPeopleAndUnitsState {
  activeTabIndex: number,
  peopleLabel: string,
  unitsLabel: string
}

class PeopleAndUnits extends React.Component<IPeopleAndUnitsProps,IPeopleAndUnitsState> {
  componentId;
  peopleQueryCriteriaKey;
  unitsQueryCriteriaKey;

  _mounted = false;

  constructor(props) {
    super(props);
    const i18n = Context.instance.i18n;
    this.componentId = component.nextId("qc");
    this.peopleQueryCriteriaKey = this.componentId + "-people";
    this.unitsQueryCriteriaKey = this.componentId + "-units";
    this.initPeopleQueryCriteria();
    this.initUnitsQueryCriteria();

    this.state = component.newState({
      activeTabIndex: 0,
      peopleLabel: i18n.spaceplanner.people.people,
      unitsLabel: i18n.spaceplanner.units.units
    });
  }

  componentDidMount() {
    this._mounted = true;
    this.queryPeopleCount();
    this.queryUnitCount();

    component.own(this, [

      /* Disable the count update, issue #3236
      Topic.subscribe(Topic.ListCountInfo, (params) => {
        if (params && typeof params.count === "number") {
          if (this.peopleQueryCriteriaKey === params.queryCriteriaKey) {
            const i18n = Context.instance.i18n;
            let v = i18n.spaceplanner.people.peopleCountPattern;
            v = v.replace("{count}", params.count);
            if (this._mounted) this.setState({ peopleLabel: v });
          } else if (this.unitsQueryCriteriaKey === params.queryCriteriaKey) {
            const i18n = Context.instance.i18n;
            let v = i18n.spaceplanner.units.unitsCountPattern;
            v = v.replace("{count}", params.count);
            if (this._mounted) this.setState({ unitsLabel: v });
          }
        }
      }),
      */

      Topic.subscribe(Topic.PlanModified, (params) => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.queryPeopleCount();
          this.queryUnitCount();
        }
      }),
      Topic.subscribe(Topic.RefreshUnitName,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    const session = Context.instance.session;
    if (session.queryCriteriaByKey) {
      delete session.queryCriteriaByKey[this.peopleQueryCriteriaKey];
      delete session.queryCriteriaByKey[this.unitsQueryCriteriaKey];
    }
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  confirmDelete = (evt, item, type, index) => {
    if (evt) evt.stopPropagation();
    const i18n = Context.instance.i18n;
    const promptOptions = {
      subject: type, // person or unit
      subjectItem: item,
      from: "workspace",
      fromItem: this.props.areaItem,
    };
    const content = <UnassignPrompt promptOptions={promptOptions} />;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: i18n.spaceplanner.unassignPrompt.removeAssignmentTitle,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        if (type === "unit") this.deleteUnit(item, index);
        if (type === "person") {
          const personAttributes = item && item.feature && item.feature.attributes
          const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
          const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
          canDeleteDuplicate(personName, personEmail).then(result => {
            if (result) {
              this.deleteDuplicatePerson({person: item, associatedUnit: null, associatedArea: this.props.areaItem},index)
            } else {
              this.deletePerson(item, index)
            }
          })
        }
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  deletePerson = (personItem, index) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([personItem],null)
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          index: index,
          queryCriteriaKey: this.peopleQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  deleteDuplicatePerson = (personItem, index) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .deletePeople([personItem],false)
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          index: index,
          queryCriteriaKey: this.peopleQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  deleteUnit = (unitItem, index) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignUnits([unitItem],null)
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          index: index,
          queryCriteriaKey: this.unitsQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning units", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  initPeopleQueryCriteria = () => {
    const source = sourceUtil.getPeopleSource();
    const fields = source.layer2D.fields;
    let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    const nameField = aiimUtil.findField(fields, nameFieldName);
    if (nameField) nameFieldName = nameField.name;
    const queryCriteriaKey = this.peopleQueryCriteriaKey;
    const queryCriteria = QueryCriteria.get(queryCriteriaKey, source.key);
    queryCriteria.requiresGeometry = true;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = nameFieldName;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc",
      };
    }
    const areaItem = this.props.areaItem;
    const where = officePlanUtil.makeWhereForPeopleAssignedToArea(areaItem);
    queryCriteria.where = where;
  };

  initUnitsQueryCriteria = () => {
    const source = sourceUtil.getUnitsSource();
    const unitField = Context.instance.aiim.getUnitName();

    const queryCriteriaKey = this.unitsQueryCriteriaKey;
    const queryCriteria = QueryCriteria.get(queryCriteriaKey, source.key);
    queryCriteria.requiresGeometry = true;
    //queryCriteria.num = 50;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = unitField;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc",
      };
    }
    const areaItem = this.props.areaItem;
    const where = officePlanUtil.makeWhereForUnitsAssignedToArea(areaItem);
    queryCriteria.where = where;
  };

  onTabChange = (index) => {
    this.setState({ activeTabIndex: index });
  };

  popupPerson = (featureItem) => {
    const source = officePlanUtil.getPeopleSource();
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_FULLNAME
    );
    Topic.publish(Topic.ShowItemPopup, {
      title: name,
      source: source,
      featureItem: featureItem,
      fetchFeatureItemGeometry: false,
      zoom: false,
      content: <Popup sourceKey={source.key} featureItem={featureItem} isPerson={true} />,
    });
  };

  popupUnit = (featureItem) => {
    const source = officePlanUtil.getUnitsSource();
    const unitField = Context.instance.aiim.getUnitName();
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(attributes, unitField);
    Topic.publish(Topic.ShowItemPopup, {
      title: name,
      source: source,
      featureItem: featureItem,
      fetchFeatureItemGeometry: true,
      zoom: true,
      content: <Popup sourceKey={source.key} featureItem={featureItem} />,
    });
  };

  queryPeopleCount() {
    const i18n = Context.instance.i18n;
    const source = sourceUtil.getPeopleSource();
    const queryCriteria = QueryCriteria.get(this.peopleQueryCriteriaKey);
    const options = {
      source: source,
      where: queryCriteria.where,
    };
    queryUtil
      .queryCount(options)
      .then((result) => {
        if (typeof result === "number") {
          let v = i18n.spaceplanner.people.peopleCountPattern;
          v = v.replace("{count}", result);
          if (this._mounted) this.setState({ peopleLabel: v });
        }
      })
      .catch((ex) => {
        console.error("Error querying people count", ex);
      });
  }

  queryUnitCount() {
    const i18n = Context.instance.i18n;
    const source = sourceUtil.getUnitsSource();
    const queryCriteria = QueryCriteria.get(this.unitsQueryCriteriaKey);
    const options = {
      source: source,
      where: queryCriteria.where,
    };
    queryUtil
      .queryCount(options)
      .then((result) => {
        if (typeof result === "number") {
          let v = i18n.spaceplanner.units.unitsCountPattern;
          v = v.replace("{count}", result);
          if (this._mounted) this.setState({ unitsLabel: v });
        }
      })
      .catch((ex) => {
        console.error("Error querying unit count", ex);
      });
  }

  render() {
    const i18n = Context.instance.i18n;
    const { activeTabIndex, peopleLabel, unitsLabel } = this.state;
    const noPeople = i18n.spaceplanner.workspaceAreas.noPeople;
    const noUnits = i18n.spaceplanner.workspaceAreas.noUnits;

    return (
      <>
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <AreaStats areaItem={this.props.areaItem} />
        </div>
        <Button style={{margin:"8px auto", display: this.props.showUsageButton ? "block" : "none"}} 
          title={i18n.spaceplanner.usageAnalytics.showUsage}
          onClick={evt=>{ this.props.onShowUsageClicked(evt) }} 
          disbled={!!this.props.disableUsageButton}
          clear>
            {i18n.misc.showHotelUsage}
        </Button>
        <Tabs
          translucent
          activeTabIndex={activeTabIndex}
          onTabChange={this.onTabChange}
          className="i-sp-ppl-units-tabs"
          style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden", 
            height:`calc(100vh - ${this.props.showUsageButton ? 400:350 }px)`}}
        >
          <TabNav>
            <TabTitle key="people">{peopleLabel}</TabTitle>
            <TabTitle key="units">{unitsLabel}</TabTitle>
          </TabNav>
          <TabContents className="i-sp-ppl-units-tab-contents"
            style={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <TabSection
              key="people"
              style={{ padding: 0, background: "inherit", display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <Items
                queryCriteriaKey={this.peopleQueryCriteriaKey}
                noResultsMessage={noPeople}
                disableForceUpdate={false}
                rdxFeatureItemsKey={null}
                renderItem={this.renderPerson}
                requeryOnPlanActions={[OfficePlan.Action_AssignmentsUpdated]}
                isShowAllButton={true}
              />
            </TabSection>
            <TabSection
              key="units"
              style={{ padding: 0, background: "inherit", display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <Items
                queryCriteriaKey={this.unitsQueryCriteriaKey}
                noResultsMessage={noUnits}
                disableForceUpdate={false}
                rdxFeatureItemsKey={null}
                renderItem={this.renderUnit}
                requeryOnPlanActions={[OfficePlan.Action_AssignmentsUpdated]}
                isShowAllButton={true}
              />
            </TabSection>
          </TabContents>
        </Tabs>
      </>
    );
  }

  renderPerson = (source, featureItem, index) => {
    const i18n = Context.instance.i18n;
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_FULLNAME
    );
    const email = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL);
    const isPlaceholder = email && email.startsWith('placeholder');
    return (
      <ListItem
        key={featureItem.key}
        isPlaceholder={isPlaceholder}
        onClick={() => this.popupPerson(featureItem)}
      >
        <ListItemTitle>
          {name}
          <Button
            iconButton={true}
            icon={<DeleteIcon />}
            title={i18n.spaceplanner.unassign}
            onClick={(evt) => {
              this.confirmDelete(evt, featureItem, "person", index);
            }}
          />
        </ListItemTitle>
      </ListItem>
    );
  };

  renderUnit = (source, featureItem, index) => {
    const i18n = Context.instance.i18n;
    const attributes = featureItem.feature.attributes;
    const unitField = Context.instance.aiim.getUnitName();
    const name = aiimUtil.getAttributeValue(attributes, unitField);

    const asnType = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    let unitIcon = <FloorPlanIcon />
    if (asnType === OfficePlan.SpaceAssignmentTypes.hotdesk) {
      unitIcon = <HotDeskIcon />
    } else if (asnType === OfficePlan.SpaceAssignmentTypes.hotel) {
      unitIcon = <HotelIcon />
    } else if (asnType === OfficePlan.SpaceAssignmentTypes.meetingroom) {
      unitIcon = <MeetingRoomIcon />
    }

    return (
      <ListItem
        key={featureItem.key}
        onClick={() => this.popupUnit(featureItem)}
      >
        <ListItemTitle>
          <ListItemTitleIcon lowerIcon brand>
            {unitIcon}
          </ListItemTitleIcon>
          <ListItemTitleMain>
            {name}
          </ListItemTitleMain>
          <Button
            iconButton={true}
            icon={<DeleteIcon />}
            title={i18n.spaceplanner.unassign}
            onClick={(evt) => {
              this.confirmDelete(evt, featureItem, "unit", index);
            }}
          />
        </ListItemTitle>
      </ListItem>
    );
  };
}

export default PeopleAndUnits;
