import Context from "./Context";

export default class Topic {

  static publish(topicName, data) {
    try {
      const topic = Context.getInstance().lib.dojo.topic;
      topic.publish(topicName,data);
    } catch(ex) {
      console.warn("Error publishing topic",topicName);
      console.error(ex);
    }
  }

  static publishError(error: Error) {
    const message = error && error.message;
    if (message) {
      Topic.publish(Topic.ShowToast,{
        message: message,
        type: "danger"
      });
    }
  }

  static publishErrorAccessingData(submessage?) {
    const i18n = Context.instance.i18n;
    Topic.publish(Topic.ShowToast,{
      message: i18n.messages.errorAccessingData,
      submessage: submessage,
      type: "danger"
    });
  }

  static publishErrorUpdatingData(submessage?) {
    const i18n = Context.instance.i18n;
    Topic.publish(Topic.ShowToast,{
      message: i18n.messages.errorUpdatingData,
      submessage: submessage,
      type: "danger"
    });
  }

  static publishNetworkError(message) {
    if (!message) {
      return;
    }
    Topic.publish(Topic.ShowToast, {
      message,
      type: "danger"
    });
  }

  static subscribe(topicName, callback) {
    const topic = Context.getInstance().lib.dojo.topic;
    return topic.subscribe(topicName,callback);
  }

  /* ======================================================================= */

  /* params - {htmlParam: string, show: boolean} */
  static AboutSection: string = "app/AboutSection";

  /* params - {facilityData: obj, levelData: obj, view: obj} */
  static ActivateLevel: string = "app/ActivateLevel";

  /* params - {routeResult: obj} */
  static ActivateNavigator: string = "app/ActivateNavigator";

  /* params - {tab: string} */
  static ActiveTabChanged: string = "app/ActiveTabChanged";

  /* params - {} */
  static AppLaunchURLsChanged: string = "app/AppLaunchURLsChanged";

  /* params - {} */
  static AppIdUpdated: string = "app/AppIdUpdated";

  /* params - {theme: obj} */
  static ApplyTheme: string = "app/ApplyTheme";

  /* params - {} */
  static AppStarted: string = "app/AppStarted";

  /* params - {} */
  static AreasRefreshed: string = "app/AreasRefreshed";

  /* params -{} */
  static AudioEnabledChanged: string = "app/AudioEnabledChanged";

  /* params - none */
  static BookingLogin: string = "app/BookingLogin";

  /* params - none */
  static BookFiltersCleared: string = "app/BookFiltersCleared";

  /* params- {} */
  static ClearToast: string= "app/ClearToast";

  /* params - {} */
  static CloseBackstage = "app/CloseBackstage";

  /* params - {} */
  static CloseInfoPanel: string = "app/CloseInfoPanel";

  /* params - {componentId} */
  static InfoPanelClosed: string = "app/InfoPanelClosed";

  /* params - {} */
  static CloseItemPopup: string = "app/CloseItemPopup";

  /* params - {} */
  static CloseSidebarPanel: string = "app/CloseSidebarPanel";

  /* params - {} */
  static ConfigurationDiscarded: string = "app/ConfigurationDiscarded";

  /* params - {} */
  static ConfiguratorActivated: string = "app/ConfiguratorActivated";

    /* params - {} */
    static ConfiguratorStarted: string = "app/ConfiguratorStarted";

  /* params - {} */
  static ConfiguratorClosed: string = "app/ConfiguratorClosed";

  /* params - {} */
  static Configurator_BrowseForMap: string = "app/Configurator_BrowseForMap";

  /* params - {} */
  static Configurator_RenderChecks_AppLaunch: string = "app/Configurator_RenderChecks_AppLaunch";

  /* params - {} */
  static Configurator_RenderSteps: string = "app/Configurator_RenderSteps";

  /* params - {} */
  static Configurator_SetExpanded: string = "app/Configurator_SetExpanded";

  /* params -{} */
  static ChangeCommentsView: string = "app/ChangeCommentsView";

  /* params - {} */
  static CreatingPlan = "app/CreatingPlan"

  /* params - {facilityInfo: obj, view: mapView|SceneView} */
  static DeactivateFacilityClicked: string = "app/DeactivateFacilityClicked";

  /* params - {searchResult: any} */
  static DirectionsClicked: string = "app/DirectionsClicked";

  /* params - {startItem: any, endItem: any} */
  static DirectionsFromURL: string = "app/DirectionsFromURL";

  /* params - {} */
  static UpdateHotelingConfig: string = "app/UpdateHotelingConfig";

  /* params - {sourceKey: onj, featureItem: obj} */
  static DragItemEnd = "app/DragItemEnd";

  /* params - {sourceKey: onj, featureItem: obj} */
  static DragItemStart = "app/DragItemStart";

  /* params - none */
  static DurationFiltersSet = "app/DurationFiltersSet";

  /* params - {where: string} */
  static EventsFilterSet: string = "app/EventsFilterSet";

  /* params - {type: string, property: string, newVal: any, oldVal: any} */
  static ExpanderChanged: string = "app/ExpanderChanged";

  /* params - {queryCriteriaKey: string, filteredLevelInfo: object} */
  static FilteredLevelIds: string = "app/FilteredLevelIds";

  /* params - {} */
  static FpePaletteRefreshed = "app/FpePaletteRefreshed";

  /* params - {view: View, content: any} */
  static GoToHomeClicked: string = "app/GoToHomeClicked";

  /* params - {} */
  static ItemPopupOpening = "app/ItemPopupOpening";

  /* params - {facilityId: string, facilityName: string, activeLevelNumber: number,
               levels: array, view: MapView|SceneView} */
  static FacilityActivated: string = "app/FacilityActivated";

  /* params - {facilityInfo: any} */
  static SiteActivated: string = "app/SiteActivated";

  /* params - {view: mapView|SceneView} */
  static FacilityDeactivated: string = "app/FacilityDeactivated";

  /* params - {view: mapView|SceneView} */
  static SiteDeactivated: string = "app/SiteDeactivated";

  /* params = {view: mapView|SceneView, facilityInfo: any} */
  static FacilityCache: string = "app/FacilityCache";

  /* params - {facilityMode: FacilityMode, view: view} */
  static FacilityModeUpdated: string = "app/FacilityModeUpdated";

  /* params - {facility: string} */
  static FacilitySelected: string = "app/FacilitySelected";

  /* params - none */
  static FilterAreaChange: string = "app/FilterAreaChange";

  /* params - none */
  static FilterSpaceTypeChange: string = "app/FilterSpaceTypeChange";

  /* params - {queryCriteriaKey: string, fieldName: string} */
  static FilterValueSelected: string = "app/FilterValueSelected";

  /* params - none */
  static FilterSiteChange: string = "app/FilterSiteChange";

  /* params - none */
  static FiltersMeetingChange: string = "app/FiltersMeetingChange";

  /* params - {whereClause: string} */
  static BookDateFilterChange: string = "app/BookDateFilterChange";

  /* params - none */
  static BookingFiltersSet: string = "app/BookingFiltersSet";

  /* params - none */
  static BookingFiltersSubmit: string = "app/BookingFiltersSubmit";

  /* params - {homeLocation: HomeLocation} */
  static HomeLocationSet: string = "app/HomeLocationSet";

  static HotDesk: string = "app/AssignUnitToHotdesk";

  /* params - {source: obj, feature: any, view: mapView|SceneView} */
  static ItemOpened: string = "app/ItemOpened";

  /* params - none */
  static LayerVisibilityChanged: string = "app/LayerVisibilityChanged";

  /* params - {facilityId: string, levelNumber: number, view: mapView|SceneView} */
  // original floor-filter
  static LevelSelected: string = "app/LevelSelected";

  /* params - {facilityId: string, levelNumber: number, view: mapView|SceneView} */
  // core floor-filter
  static LevelSelected2: string = "app/LevelSelected2";

  /* params - {isRefreshing: string, count: number, totalCount: number} */
  static ListCountInfo: string = "app/ListCountInfo";

  /* params - {} */
  static LoadComments: string = "app/LoadComments";

  /* params - {} */
  static LogoChanged: string= "app/LogoChanged";

  /* params - {} */
  static LogonameChanged: string= "app/LogonameChanged";

  /* params - {} */
  static MergingPlan = "app/MergingPlan"

  /* params - {} */
  static MeetingRoomsFilterClear: string = "app/MeetingRoomsFilterClear";

  /* params - {graphic: obj} */
  static MoveOccupants_GraphicClicked = "app/MoveOccupants_GraphicClicked";

  /* params - {g} */
  static MoveOccupants_Hide = "app/MoveOccupants_Hide";

  /* params - {unitFeatureItem: obj, peopleAssigned: obj} */
  static MoveOccupants_Show = "app/MoveOccupants_Show";

  /* params - {action: string} */
  static PlanModified: string= "app/PlanModified";

  /* params - {plan: obj} */
  static PlanOpened: string= "app/PlanOpened";

  /* params - {key: string} */
  static QueryCriteriaChanged: string = "app/QueryCriteriaChanged";

  /* params - {key: string} */
  static QueryCriteriaCleared: string = "app/QueryCriteriaCleared";

  static RenderBookingList: string = "app/RenderBookingList";

  static RenderBookingListOffice365: string = "app/RenderBookingListOffice365";

  static RenderMeetingListOffice365: string = "app/RenderMeetingListOffice365";

  static RenderCheckIn: string = "app/RenderCheckIn";

  /* params - {} */
  static ReferenceLayerUpdated: string = "app/ReferenceLayerUpdated";

  /* params - {portalMembers: string[]} */
  static RefreshReviewers: string = "app/RefreshReviewers";

  /* params - {} */
  static ReloadViews: string = "app/ReloadViews";

  /* params - {} */
  static ReloadPortal: string = "app/ReloadPortal";

  /* params - {} */
  static ReloadWorkspace: string = "app/ReloadWorkspace";

  /* params - {} */
  static RenderSidebar: string = "app/RenderSidebar";

  static RenderMeetingRooms: string = "app/RenderMeetingRooms";

  /* params - {} */
  static ReservationsUpdated: string = "app/ReservationsUpdated";

  /* params - {} */
  static ToggleReturnClicked: string = "app/ToggleReturnClicked";

  /* params - {searchWidget: Search, result: any}
     see esri/widgest/Search "select-result" event
   */
  static SearchResultSelected: string = "app/SearchResultSelected";

  /* params - {sidebarKey: string} */
  static SetSidebarButtonFocus: string = "app/SetSidebarButtonFocus";

  /* params - {} */
  static ShowBackstage = "app/ShowBackstage";

  static ShowInfoPanelGraphic = "app/ShowInfoPanelGraphic";

  /* params - {options} */
  static ShowItemPopup: string = "app/ShowItemPopup";

  /* params - {content: any} */
  static ShowModal = "app/ShowModal";

  /* params - {srcName: string, attribs:any, view: View} */
  static ShowSearchedFloor: string = "app/ShowSearchedFloor";

  /* params - {sourceKey: string, searchResult: any, zoom: boolean, highlight:
     boolean, trackRecent: boolean} */
  static ShowSearchResult: string = "app/ShowSearchResult"

  /* params - { searchResult: any } */
  static HideHotelGraphic: string = "app/HideHotelGraphic";

  /* params - {message: string} */
  static ShowToast: string = "app/ShowToast";

  /* params - {sidebarKey: string} */
  static SidebarButtonClicked: string = "app/SidebarButtonClicked";

  /* params - none */
  static SignedIn: string = "app/SignedIn";

  /* params - none */
  static SignInClicked: string = "app/SignInClicked";

  /* params - none */
  static SignOutClicked: string = "app/SignOutClicked";

  /* params - none */
  static SpaceFiltersSubmit: string = "app/SpaceFiltersSubmit";

  /* params - {stat: obj} */
  static StatRefreshed: string = "app/StatRefreshed";

  /* params - {travelMode: obj} */
  static TravelModeChanged: string = "app/TravelModeChanged";

  /* params - {} */
  static UIModeSet: string = "app/UIModeSet";

  /* params - {} */
  static RefreshUnitName: string = "app/RefreshUnitName";

  /* params - {} */
  static RefreshWallTypes: string = "app/RefreshWallTypes";

  /* params - {taskName: string, ...other} */
  static UnitNameCacheUpdated = "app/UnitNameCacheUpdated";

  /* params - {taskName: string, ...other} */
  static UpdatePlan: string = "app/UpdatePlan";

  static UpdateHotelAreaName: string = "app/UpdateHotelAreaName";

  static UpdatePointBarriers: string = "app/UpdatePointBarriers";

  static UpdateReservationDetails: string = "app/UpdateReservationDetails";

  /* params - {view: View} */
  static ViewActivated: string = "app/ViewActivated";

  /* params - {view: View}  called just before ViewActivated */
  static ViewActivated0: string = "app/ViewActivated0";

  /* params - {view: View, event: any}
     see esri/views/View "click" event
   */
  static ViewClicked: string = "app/ViewClicked";

  /* params - {} */
  static ViewsReloaded: string = "app/ViewsReloaded";

  /* params - {} */
  static WebMapLoaded: string = "app/WebMapLoaded";

  /* params - {formKey: string, part: string, value: obj} */
  static WhenChanged: string = "app/WhenChanged";

}
