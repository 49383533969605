import BaseClass from "../../util/BaseClass";
//import Context from "../../context/Context";
import Topic from "../../context/Topic";
import * as sourceUtil from "./sourceUtil";

export default class Stat extends BaseClass {

  key;
  requeryOnPlanActions;
  sourceKey;
  value;

  constructor(props) {
    super(props);
    this.value = 0;
    this._init();
  }

  broadcastRefreshed() {
    Topic.publish(Topic.StatRefreshed,{stat: this});
  }

  getSource() {
    return sourceUtil.getSourceByKey(this.getSourceKey());
  }

  getSourceKey() {
    return this.sourceKey;
  }

  _init() {
    // this.own([
    //   Topic.subscribe(Topic.PlanModified,params => {
    //     const actions = this.requeryOnPlanActions;
    //     if (actions && actions.indexOf(params.action) !== -1) {
    //       this.query();
    //     }
    //   })
    // ]);
  }

  query() {
  }

}
