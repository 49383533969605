import Context from "../context/Context";

export function escapeForLucene(value) {
  const a = ['+', '-', '&', '!', '(', ')', '{', '}', '[', ']', '^', '"', '~', '*', '?', ':', '\\'];
  const r = new RegExp("(\\" + a.join("|\\") + ")", "g");
  return value.replace(r, "\\$1");
}

export function generateRandomId() {
  return generateRandomUuid({removeDashes: true});
  // const t = Date.now();
  // const r = (""+Math.random()).replace("0.","r");
  // return (t+""+r).replace(/-/g,"");
}

export function generateRandomUuid(options) {
  const lib = Context.instance.lib;
  let v = lib.dojox.generateRandomUuid();
  if (options && options.removeDashes) {
    v = v.replace(/-/g,"");
  }
  if (options && options.includeBraces) {
    v = "{" + v + "}";
  }
  return v;
}

export function getObject(context, name, create) {
  return !name ? context : getProp(context, name.split("."), create);
}

function getProp(context, parts, create) {
  try {
    for (let i = 0; i < parts.length; i++){
      let p = parts[i];
      if (!(p in context)) {
        if (create) {
          context[p] = {};
        } else {
          return;
        }
      }
      context = context[p];
    }
    return context;
  } catch(e){
    // "p in context" throws an exception when context is a number, boolean, etc.
    // rather than an object, so in that corner case just return undefined
    // (by having no return statement)
  }
}

export function getUrlParamValue(param,url) {
  let caseInsensitive = true;
  const lc = param.toLowerCase();
  if (!url) url = window.location.href;
  if (typeof url === "string") url = new URL(url);
  if (caseInsensitive && url && url.searchParams) {
    for (const [key, value] of url.searchParams) {
      if (typeof key === "string" && key.toLowerCase() === lc) {
        return value;
      }
    }
  }
  return url.searchParams && url.searchParams.get(param);
}


export function isFiniteNum(v) {
  return (typeof v === "number" && !isNaN(v) && isFinite(v));
}

export function isNonEmptyStr(v,trimStr) {
  if (trimStr) v = trim(v);
  return (typeof v === "string" && v.length > 0);
}

export function setObject(context, name, value) {
  const parts = name.split(".");
  const p = parts.pop();
  const obj = getProp(context, parts, true);
  return obj && p ? (obj[p] = value) : undefined;
}

export function trim(v) {
  if (typeof v === "string") return v.trim();
  return v;
}
