import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { IOccupantsCriteria } from "../../common/types";
import OfficePlan from "../../../base/OfficePlan";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import { escSqlQuote } from "../../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as queryUtil from "../../../base/queryUtil";

function makeWhere(layer, criteria: IOccupantsCriteria) {
  const knownasField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_FULLNAME);
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);
  const parts = [];

  let v = criteria.searchText;
  if (typeof v === "string" && v.length > 0) {
    v = escSqlQuote(v.toUpperCase());
    v = `(UPPER(${knownasField}) LIKE '%${v}%')`;
    parts.push(v)
  }

  const emailPart = `((${emailField} IS NOT NULL) AND (${emailField} <> ''))`;
  parts.push(emailPart)

  const fw = criteria.filter && criteria.filter.where;
  if (typeof fw === "string" && fw.length > 0) {
    parts.push(fw)
  }

  let where = parts.join(" AND ");
  if (!where) where = "1=1";
  return where;
}

export async function queryOccupants(criteria: IOccupantsCriteria): Promise<any> {
  const promise = new Promise((resolve,reject) => {
    const task = {
      criteria: criteria,
      occupants: [],
      total: 0
    }
    const promises = [];
    promises.push(_query(task));
    promises.push(_queryCount(task));
    Promise.all(promises).then(list => {
      resolve(task);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

async function _query(task: any): Promise<any> {
  
  const criteria: IOccupantsCriteria = task.criteria;
  const occupants = [];
  const occupantsByEmail = {};

  const i18n = Context.instance.i18n;
  const lib = Context.instance.lib;
  const view = Context.instance.views.activeView;
  const plan = Context.instance.spaceplanner.activePlan;
  const layer = sourceUtil.getPeopleLayer();
  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const oidField = aiimUtil.findFieldName(layer.fields,layer.objectIdField);
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);
  const knownasField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_FULLNAME);
  const unitidField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_UNIT_ID);
  const areaidField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_AREA_ID);
  const sortDir = criteria.sortDir;

  const where = makeWhere(layer,criteria);
  const orderByFields = [knownasField+" "+sortDir]; // @todo

  const qtask = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  if (view) query.outSpatialReference = view.spatialReference;
  query.returnGeometry = true;
  query.returnZ = true;
  query.outFields = ["*"];
  query.where = where;
  query.orderByFields = orderByFields;
  query.start = criteria.start;
  query.num = criteria.num;
  queryUtil.applyGdbVersion(layer,query);

  const result = await qtask.execute(query);
  //console.log("occupants.query",query)
  //console.log("queryOccupants.result",result)

  if (result && result.features) {
    result.features.forEach(f => {
      const oid = f.attributes[oidField];
      const name = f.attributes[knownasField];
      const email = f.attributes[emailField];
      const unitid = f.attributes[unitidField];
      const areaid = f.attributes[areaidField];
      if (email) {
        let assignment = {
          key: oid,
          asnName: null,
          asnType: null,
          unitid: unitid,
          areaid: areaid,
          feature: f
        }
        if (unitid) {
          let asnName = unitid;
          if (plan && plan.unitNameCache && plan.unitNameCache.namesPerUnitId) {
            asnName = plan.unitNameCache.namesPerUnitId[unitid] || unitid;
          }
          assignment.asnName = asnName;
          assignment.asnType = OfficePlan.SpaceAssignmentTypes.office;
        } else if (areaid) {
          assignment.asnName = plan.getAreaName(areaid);
          assignment.asnType = plan.getAreaType(areaid);
        } else {
          assignment.asnName = i18n.spaceplanner.unassigned;
          assignment.asnType = OfficePlan.SpaceAssignmentTypes.none;
        }

        let occupant = occupantsByEmail[email];
        if (!occupant) {
          occupant = {
            key: oid,
            name: name,
            email: email,
            assignments: [assignment]
          }
          occupantsByEmail[email] = occupant;
          occupants.push(occupant);
        } else {
          occupant.assignments.push(assignment);
        }
      }
    })
  }

  task.occupants = occupants;
}

async function _queryCount(task: any): Promise<any> {
  const criteria: IOccupantsCriteria = task.criteria;
  const lib = Context.instance.lib;
  const layer = sourceUtil.getPeopleLayer();
  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);

  const where = makeWhere(layer,criteria);
  const qtask = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.returnGeometry = false;
  query.returnZ = false;
  query.returnDistinctValues = true;
  query.outFields = [emailField];
  query.where = where;
  queryUtil.applyGdbVersion(layer,query);

  task.total = await qtask.executeForCount(query);
}