import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import IdCache from "./IdCache";
import StatPeopleUnassigned from "./StatPeopleUnassigned";
import StatUnitsUnassigned from "./StatUnitsUnassigned";
import UndoManager from "./transaction/UndoManager";
import UnitNameCache from "./UnitNameCache";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as officePlanUtil from "./officePlanUtil";
import * as sourceUtil from "./sourceUtil";
import { getAttributes } from "../components/common/MultipleAssignments/multipleAssignmentsUtil";
import { SharingLevels } from "../../common/components/Sharing";
import type AreasTable from "./AreasTable";
import type AreaRolesTable from "./AreaRolesTable";
import type Source from "../../aiim/base/Source";
import type ReviewersTable from "../base/nonAiimTables/ReviewersTable";
import type Project from "./Project";
import { ILayerDefinition, IViewServiceSource } from "@esri/arcgis-rest-types";
import { AreaTypes } from "../../util/interfaces";

export enum SpaceAssignmentTypes {
  office = "office",
  hotdesk = "hotdesk",
  hotel = "hotel",
  home = "home",
  meetingroom = "meeting room",
  none = "none",
  notAssignable = "not assignable"
};
export interface IOfficePlan {
  sourceInfo: ISourceInfo,
  supportInfo: ISupportInfo,
  areaRolesTable: AreaRolesTable;
  areasTable: AreasTable;
  commentsLayer: __esri.FeatureLayer;
  detailsLayer?: __esri.FeatureLayer;
  unitsLayer: __esri.FeatureLayer;
  sitesLayer?: __esri.FeatureLayer;
  facilitiesLayer?: __esri.FeatureLayer;
  levelsLayer?: __esri.FeatureLayer;
  idCache?: IdCache;
}
export interface IPlanInfo {
  serviceInfo?: { capabilities: string },
  serviceItem?: __esri.PortalItem,
  serviceUrl?: string,
}
export interface ISourceInfo extends IPlanInfo {
  areaRolesTableInfo?: ILayerDefinition,
  areasTableInfo?: ILayerDefinition,
  commentsLayerInfo?: ILayerDefinition,
  peopleLayerInfo?: IViewServiceSource, 
  unitsLayerInfo?: IViewServiceSource,
  detailsLayerInfo?: IViewServiceSource
}
export interface ISupportInfo extends IPlanInfo {  
  commentsLayerInfo?: ILayerDefinition,
  reviewersTableInfo?: ILayerDefinition,
  reviewersTable?: ReviewersTable
}
export default class OfficePlan extends BaseClass implements IOfficePlan {

  static Action_AssignmentsUpdated = "officeplan/Action_AssignmentsUpdated";

  static Action_PersonAssignedToUnit = "officeplan/Action_PersonAssignedToUnit";
  static Action_PersonUnassignedFromUnit = "officeplan/Action_PersonUnassignedFromUnit";
  static Action_UnitUnassigned = "officeplan/Action_UnitUnassigned";

  static EnableVersionedPlanGroupSharing = true; // is plan group sharing for enterprise branch versioned plans enabled

  static Rdx_ActiveUnit = "officeplan/Rdx_ActiveUnit";

  static Rdx_ActiveUnitName = "officeplan/Rdx_ActiveUnitName";
  static Rdx_CanAssignPersonToActiveUnit = "officeplan/Rdx_CanAssignPersonToActiveUnit";

  static Task_AssignPersonToUnit = "officeplan/Task_AssignPersonToUnit";

  static Stat_PeopleUnassigned = "officeplan/Stat_PeopleUnassigned";
  static Stat_UnitsUnassigned = "officeplan/Stat_UnitsUnassigned";

  static TagFS = "IndoorsSpaceplannerFS";
  static TypeKeywordFS = "IndoorsSpaceplannerFS";
  static TypeKeywordParentPfx = "IndoorsParentID";

  static TypeKeywordSpaceplannerPlan = "IndoorsSpaceplannerPlan";
  static TypeKeywordFloorPlanEditorPlan = "IndoorsFloorPlanEditorPlan";

  static VersionedTagFS = "IndoorsSpaceplannerVFS";
  static VersionedTypeKeywordFS = "IndoorsSpaceplannerVFS";
  static VersionedTypeKeywordParentPfx = "IndoorsParentID";
  static VersionedTypeKeywordVersionPfx = "IndoorsSpaceplannerVID";

  static SpaceAssignmentTypes = SpaceAssignmentTypes;

  areaRolesTable: AreaRolesTable;
  areasTable: AreasTable;
  commentsLayer: __esri.FeatureLayer;
  detailsLayer: __esri.FeatureLayer;
  unitsLayer: __esri.FeatureLayer;
  sitesLayer: __esri.FeatureLayer;
  facilitiesLayer: __esri.FeatureLayer;
  levelsLayer: __esri.FeatureLayer;
  idCache;
  planPeopleSource: Source;
  planUnitsSource: Source;
  planServiceItem: __esri.PortalItem;
  sourceInfo: ISourceInfo;
  supportInfo: ISupportInfo;
  
  issues;

  project: Project;

  isVersioned;
  versionInfo;

  statPeopleUnassigned;
  statUnitsUnassigned;
  title;
  undoManager: UndoManager;
  unitNameCache: UnitNameCache;
  wasOpened;

  static getActive() {
    return Context.instance.spaceplanner.activePlan;
  }

  constructor(props?) {
    super(props);
    this.undoManager = new UndoManager();
    this.unitNameCache = new UnitNameCache({plan: this});
  }

  destroy() {
    super.destroy();
    this.undoManager.destroy();
    this.unitNameCache.destroy();
  }

  _init() {
    const promise = new Promise<void>((resolve,reject) => {

      this.statPeopleUnassigned = new StatPeopleUnassigned({
        key: OfficePlan.Stat_PeopleUnassigned,
        sourceKey: "People",
        requeryOnPlanActions: [
          OfficePlan.Action_AssignmentsUpdated,
          OfficePlan.Action_PersonAssignedToUnit,
          OfficePlan.Action_PersonUnassignedFromUnit,
          OfficePlan.Action_UnitUnassigned
        ]
      });

      this.statUnitsUnassigned = new StatUnitsUnassigned({
        key: OfficePlan.Stat_UnitsUnassigned,
        sourceKey: "Units",
        requeryOnPlanActions: [
          OfficePlan.Action_AssignmentsUpdated,
          OfficePlan.Action_PersonAssignedToUnit,
          OfficePlan.Action_PersonUnassignedFromUnit,
          OfficePlan.Action_UnitUnassigned
        ]
      });

      resolve();
    });
    return promise;
  }

  getAreaName(areaId: string) {
    if (this.areasTable) return this.areasTable.getAreaName(areaId);
  }

  getAreaType(areaId) {
    if (this.areasTable) return this.areasTable.getAreaType(areaId);
  }

  getMetaInfo() {

    const djLocale = Context.instance.lib.dojo.locale;
    const makeDate = v => {
      const d = new Date(v);
      const s = djLocale.format(d, {
        formatLength: "short"
      });
      return s;
    };

    const info = {
      title: this.title,
      created: null,
      modified: null,
      summary: null,
      owner: null
    };

    if (this.wasOpened && this.isVersioned && this.versionInfo) {
      const project = Context.instance.spaceplanner.planner.project;
      const versionManager = project.versionedInfo.versionManager;
      let vi = this.versionInfo;
      info.created = makeDate(vi.creationDate);
      info.modified = makeDate(vi.modifiedDate);
      info.summary = vi.description;
      info.owner = versionManager.getVersionOwner(vi);
    } else if (this.wasOpened && this.planServiceItem) {
      let item = this.planServiceItem;
      info.created = makeDate(item.created);
      info.modified = makeDate(item.modified);
      info.summary = item.snippet;
      info.owner = item.owner;
    }

    return info;
  }

  getStat(key) {
    if (key === OfficePlan.Stat_PeopleUnassigned) {
      return this.statPeopleUnassigned;
    } else if (key === OfficePlan.Stat_UnitsUnassigned) {
      return this.statUnitsUnassigned;
    }
  }

  hasAreaRoles() {
    return !!(this.areaRolesTable && this.areaRolesTable.table);
  }
  get hasFacilities() {
    const { floorFilter: { activeWidget: { viewModel } } } = Context.getInstance().views;
    
    // @ts-ignore
    return viewModel.hasFacilities;
  }
  hasIssues() {
    return this.issues && this.issues.length > 0;
  }
  get hasLevels() {
    const { floorFilter: { activeWidget: { viewModel } } } = Context.getInstance().views;
    
    // @ts-ignore
    return viewModel.hasLevels;
  }
  get hasSites() {
    const { floorFilter: { activeWidget: { viewModel } } } = Context.getInstance().views;
    
    // @ts-ignore
    return viewModel.hasSites;
  }
  isAreasTableValid() {
    return !!(this.areasTable && this.areasTable.table);
  }

  get isEmpty() {
    const { floorFilter: { activeWidget: { viewModel } } } = Context.getInstance().views;
    // @ts-ignore
    return !viewModel.hasSites && !viewModel.hasFacilities && !viewModel.hasLevels;
  }

  isPeopleLayerValid() {
    const source = officePlanUtil.getPeopleSource();
    return !!(source && source.layer2D);
  }

  isUnitsLayerValid() {
    const source = officePlanUtil.getUnitsSource();
    return !!(source && source.layer2D);
  }

  isValid() {
    return this.isPeopleLayerValid() && this.isUnitsLayerValid()  && !this.hasIssues();
  }
  /** @deprecated Use `queryWorkspaceAreas` instead. */
  queryHotDeskAreas(): Promise<__esri.Graphic[]> {
    if (this.areasTable) {
      return this.areasTable.queryWorkspaceAreas("hotdesk");
    }
    return Promise.resolve([]);
  }
/** @deprecated Use `queryWorkspaceAreas` instead. */
  queryHotelAreas(): Promise<__esri.Graphic[]> {
    if (this.areasTable) {
      return this.areasTable.queryWorkspaceAreas("hotel");
    }
    return Promise.resolve([]);
  }

  queryWorkspaceAreas(...types: AreaTypes[]): Promise<__esri.Graphic[]> {
    if (this.areasTable) {
      return this.areasTable.queryWorkspaceAreas(...types);
    }
    return Promise.resolve([]);
  }

  refreshMetaInfo(){
    if (this.wasOpened && this.isVersioned && this.versionInfo){
      const versionGuid = this.versionInfo.versionGuid;
      const project = Context.instance.spaceplanner.planner.project;
      const versionManager = project.versionedInfo.versionManager;
      const task={};
      versionManager.readVersionInfos(task).then(result=>{
        if(result.data && versionGuid){
          for(let i=0;i<result.data.versions.length;i++){
            const resultVersionGuid = result.data.versions[i].versionGuid;
            if(resultVersionGuid=== versionGuid){
              this.versionInfo = result.data.versions[i];
              break;
            }
          }
        }
      }).catch(error=>{
        console.error(error);
      });
    }else if (this.wasOpened && this.planServiceItem) {
      let peopleLastModified, unitsLastModified, maxLastModifiedDate;
      const unitsLayer = sourceUtil.getUnitsSource();
      const peopleLayer = sourceUtil.getPeopleSource();
      const peoplePromise=sourceUtil.queryLastEditDate(peopleLayer).then(result=>{
        peopleLastModified=result;
      }).catch(error=>{
        console.log("Error checking max date", error);
      })
      const unitsPromise=sourceUtil.queryLastEditDate(unitsLayer).then(result=>{
        unitsLastModified=result;
      }).catch(error=>{
        console.log("Error checking max date", error);
      })
      Promise.all([peoplePromise, unitsPromise]).then(()=>{
        if(peopleLastModified && unitsLastModified){
          if(peopleLastModified === unitsLastModified)
            maxLastModifiedDate = unitsLastModified;
          else if(unitsLastModified> peopleLastModified)
            maxLastModifiedDate = unitsLastModified;
          else if(unitsLastModified< peopleLastModified)
            maxLastModifiedDate = peopleLastModified;
          this.planServiceItem.modified=maxLastModifiedDate;
        }
      }).catch(error=>{
        console.log("Error", error);
      })
    }
  }

  refreshStats() {
    if (this.wasOpened && !this.hasIssues()) {
      this.statPeopleUnassigned.query();
      this.statUnitsUnassigned.query();
    }
  }

  supportsBookingLimits() {
    return !!(this.areasTable && this.areasTable.hasBookingLimitFields());
  }

  supportsComment() {
    return !!(
      this.supportInfo &&
      this.supportInfo.commentsLayerInfo
    );
  }

  supportsMoveDates() {
    let ok = false;
    const layer = sourceUtil.getPeopleLayer();
    if (layer) {
      ok = !!aiimUtil.findField(layer.fields,FieldNames.PEOPLE_MOVE_DATE);
    }
    return ok;
  }

  supportsReview() {
    if (this.supportInfo && this.supportInfo.serviceItem &&
        this.supportInfo.serviceItem.access === SharingLevels.PRIVATE) {
      return false;
    }
    if (this.isVersioned && this.versionInfo && this.versionInfo.access === SharingLevels.PRIVATE) {
      return false;
    }
    let privilegeToReview = false;
    const user = Context.instance.user;
    const isPlanOwner = user.isPlanOwner();
    const isAdmin = user.isAdmin()
    if (!isPlanOwner && !isAdmin) {
      if (this.supportInfo.reviewersTable) {
        const planId = Context.instance.spaceplanner.planId
        const planReviewers = this.supportInfo.reviewersTable.getReviewersByPlanId(planId)
        if (planReviewers && planReviewers.reviewers && planReviewers.reviewers.length > 0) {
          planReviewers.reviewers.forEach(r => {
            const attributes = getAttributes(r)
            const username = aiimUtil.getAttributeValue(attributes, FieldNames.USERNAME)
            if (username && username.toLowerCase() === user.getUsername().toLowerCase()) {
              privilegeToReview = true
            }
          })
        }
      }
    } else {
      privilegeToReview = true;
    }
    return !!(
      this.supportInfo &&
      this.supportInfo.reviewersTableInfo &&
      privilegeToReview
    );
  }

}
