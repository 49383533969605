import React from "react";

import Context from "../../../context/Context";
import RelatedItems from "../../../aiim/base/RelatedItems";
import SubTypeIcon from "../../common/SubTypeIcon/SubTypeIcon";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  items: "i-feature-items",
  itemIcon: "i-feature-item-icon",
  itemText: "i-feature-item-text",
  itemTitle: "i-feature-item-title",
  itemSubTitle: "i-feature-item-subtitle",
  itemAction: "i-feature-item-action",
  workingIcon: "i-icon-working",
  noMatch: "i-note i-padded i-font-size-s"
};

export default class Related extends React.Component {

  _mounted = false;
  _wasQueried = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: true,
      results: []
    });
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  query() {
    if (this._wasQueried) return;
    this._wasQueried = true;
    const item = this.props.item;
    if (item && item.isValid()) {
      const related = new RelatedItems();
      related.query(item).then(results => {
        if (this._mounted) {
          this.setState(state => {
            return {
              isWorking: false,
              results: results
            };
          });
        }
      }).catch(ex => {
        if (this._mounted) {
          this.setState(state => {
            return {
              isWorking: false
            };
          });
        }
        console.warn("Error querying related items:");
        console.error(ex);
      });
    } else {
      if (this._mounted) {
        this.setState(state => {
          return {
            isWorking: false
          };
        });
      }
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const active = this.props.active;

    // dont't executed the query for related items unless the user has activated the related tab
    if (active && !this._wasQueried) {
      setTimeout(() => {
        this.query();
      },10);
    }

    const style = {};
    if (!active) style.display = "none";

    if (this.state.isWorking) {
      return (<div className={CSS.workingIcon} style={style}></div>);
    }

    const items = [], results = this.state.results;
    if (results && results.length > 0) {
      results.forEach((result,i) => {
        items.push(this.renderResult(result,"rel-"+i));
      });
    }

    if (items.length > 0) {
      return (
        <ul className={CSS.items} style={style}
          aria-label={i18n.infoPanel.relatedItemsAriaLabel}>
          {items}
        </ul>
      );
    } else {
      return (<p role="alert" className={CSS.noMatch} style={style}>{i18n.general.noMatch}</p>);
    }
  }

  renderItem(key,item) {
    return (
      <li key={key}>
        <RelItem item={item} />
      </li>
    );
  }

  renderResult(result,key) {
    const listItems = [];
    if (result && result.items && result.items.length > 0) {
      result.items.forEach((item,i) => {
        let k = key+"-"+i+"-"+item.sourceKey+"-"+item.uniqueId;
        listItems.push(this.renderItem(k,item));
      });
    }
    return listItems;
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class RelItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.detailsClicked = this.detailsClicked.bind(this);
  }

  detailsClicked(event) {
    const item = this.props.item;
    Topic.publish(Topic.ShowSearchResult,{
      sourceKey: item.sourceKey,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true
    });
  }

  render() {
    const item = this.props.item;
    const title = item.getTitle();
    const subTitle = item.getSubTitle();
    const subTypeValue = item && item.subType && item.subType.value;
    const hashref = "#";;

    let subTitleNode = null;
    if (subTitle) {
      subTitleNode = <span key="subtitle" className={CSS.itemSubTitle}>{subTitle}</span>;
    }

    const node = (
      <a href={hashref} onClick={this.detailsClicked}>
        <SubTypeIcon forFeature={true} sourceKey={item.sourceKey} subTypeValue={subTypeValue} />
        <span className={CSS.itemText}>
          <span key="title" className={CSS.itemTitle}>{title}</span>
          {subTitleNode}
        </span>
      </a>
    );
    return node;
  }

}
