import React, { MutableRefObject } from "react";
import Icons from "../../../../util/Icons";
import Context from "../../../../../context/Context";
import Button from "calcite-react/Button";
import { formatDate, formatRecurringDates } from "./WorkspaceReservation/workspaceReservationUtil";
import { BookingType, makeEventInfo } from "../../../../../util/calendarUtil";
import CalendarSelector from "../../../../../util/CalendarSelector";
import { stringFormatter } from "../../../../../util/formatUtil";
import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import { nextTmpId } from "../../../../util/component";
import moment from "moment";

// Calcite
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import PinIcon from "calcite-ui-icons-react/PinIcon";
import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleIcon";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import { IBookingData, IBookingParams, IBookingSystem, IBookWorkspaceResults } from "./WorkspaceReservation/BookingSystem";

const CSS = {
  popoutInner: "i-popout-inner i-booking-confirmed-popup",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  filterForm: "i-filter-form",
  toggleAllDay: "i-toggle-all-day",
  toggleLabel: "i-toggle-label",
  durationFilter: "i-duration-filter",
  durationFilterForm: "i-duration-filter-form",
  durationGroup: "i-duration-group",
  durationDate: "i-date",
  durationTime: "i-time",
  iconContainer: "i-sidebar-icon-container i-filter-container",
  icon: "i-more-menu-icon",
  spaceFilters: "i-space-filter",
  button: "i-book-button",
  dateString: "i-date-string",
  bookingConfirmedBody: "i-booking-confirmed-body",
  bookingConfirmedHeader: "i-booking-confirmed-header",
  bookingDetailsTitle: "i-booking-details-title",
  bookingOthersInfo: "i-booking-others-info",
  bookingsInfoGroup: "i-bookings-info-group",
  bookingFailed: "i-booking-failed",
  itemTags: "i-item-tags",
  itemTag: "i-item-tag",
  iconLabel: "i-icon-label",
  footer: "i-confirmed-footer"
};
interface IBookingConfirmedProps {
  bookingSystemType: IBookingSystem["type"],
  /** Specifies the type of booking confirmation for updates. New bookings don't need to specify
   * this since both new individual bookings as well as a series will show both occurrence and series options.
   */
  bookingType?: BookingType,
  closePopup: () => void,
  data: IBookWorkspaceResults | IBookingData | boolean,
  facilityName: string,
  levelName: string,
  origResAttr?: Record<string, any>,
  params: IBookingParams | boolean,
  unit: __esri.Graphic | boolean,
  unitName: string,
  updateBooking?: boolean
}
interface IBookingConfirmedState {
  popoverOpen: boolean
}
export default class BookingConfirmed extends React.Component<IBookingConfirmedProps, IBookingConfirmedState> {
  bookingSystemType = this.props && this.props.bookingSystemType;
  closeRef: MutableRefObject<HTMLButtonElement>;
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  // addToCalendarClicked = () => {
  //     const { data } = this.props;
  //     const reservation = data && data.reservation;
  //     const params = data && data.params;
  //     downloadICSFile(reservation, params);
  // }

  _togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  renderCalendarButton() {
    if (this.bookingSystemType === "office365") {
      return null;
    }

    const { data, unit } = this.props;    
    const reservations = data && typeof data !== "boolean" && "reservations" in data && data.reservations;
    // Use the first reservation in the list. Even if there is a booking for multiple
    // users from a reservation manager, the calendar item will contain the same info for all
    // and the reservation manager could share the downloaded ICS file to the person/people
    const reservation = reservations && reservations.length > 0 && reservations[0];
    if (!reservation) {
      return null;
    }

    const { popoverOpen } = this.state;
    const i18n = Context.instance.i18n;
    let btnStr = i18n.events.details.addToCalendar;
    let tooltipStr = i18n.calendars.recurringAdd;
    if (this.props && this.props.updateBooking) {
      btnStr = i18n.events.details.updateInCalendar;
      tooltipStr = i18n.calendars.recurringUpdate;
    }
    const calendarButton = (
      <Button onClick={this._togglePopover}>{btnStr}</Button>
    );

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const operation = (this.props && this.props.updateBooking) ? "updateBooking" : "addBooking";
    const calendarParams = makeEventInfo(
      reservationsDataset,
      reservation,
      unitsDataset,
      unit as __esri.Graphic,
      operation,
      this.props?.origResAttr
    );

    return (
      <CalendarSelector
        bookingType={this.props.bookingType}
        onRequestClose={this._togglePopover}
        open={popoverOpen}
        targetEl={calendarButton}
        params={calendarParams}
        reservation={reservation}
        updateBooking={this.props && this.props.updateBooking}
        origResAttr={this.props && this.props.origResAttr}
      />
    );
  }

  renderPersonTag(feature, rejected) {
    const field = rejected ? FieldNames.PEOPLE_FULLNAME : FieldNames.RESERVED_FOR_FULL_NAME;
    const fullName = getAttributeValue(feature.attributes, field);
    return (
      <li key={`${fullName}-${nextTmpId()}`} className={CSS.itemTag}>
        {fullName}
      </li>
    );
  }

  getFirstReservation = () => {
    const { data } = this.props;   
    return data && typeof data !== "boolean" && "reservations" in data && data.reservations.length > 0 && data.reservations[0];
  }

  // Style the modal for those who were found and confirmed, and for those not found
  // and not booked. If nobody on the list was booked, we also need to let the user know that.
  renderMultipleBookings() {
    const i18n = Context.instance.i18n;
    const { data, unitName, levelName, facilityName, params } = this.props;
    if (typeof data === "boolean" || typeof params === "boolean") {
      return;
    }
    const reservations = data && "reservations" in data && data.reservations;
    const rejections = data && "rejections" in data && data.rejections;
    const hasReservations = reservations && reservations.length > 0;
    const hasRejections = rejections && rejections.length > 0;

    // Person info
    const personName = hasReservations && getAttributeValue(reservations[0].attributes, FieldNames.RESERVED_FOR_FULL_NAME);
    const errorPersonName = hasRejections && getAttributeValue(rejections[0].attributes, FieldNames.PEOPLE_FULLNAME);

    let title = i18n.more.bookWorkspace.bookingDetailsTitle;
    title = title.replace("{levelName}", levelName);
    title = title.replace("{buildingName}", facilityName);

    let requestHeader =
      this.bookingSystemType === "office365"
        ? i18n.more.bookWorkspace.bookingRequested
        : this.bookingSystemType === "esri"
          ? i18n.more.bookWorkspace.bookingConfirmed
          : null;

    if (!hasReservations && hasRejections) {
      requestHeader = i18n.more.bookWorkspace.bookingUnsuccessful;
    }

    const confirmedTemplate = i18n.more.bookWorkspace.bookingRequestApprovedOthers;
    const failedTemplate = i18n.more.bookWorkspace.bookingRequestFailedOthersAlt;

    // Level/Facility info
    const template = i18n.more.bookWorkspace.unitLevelDescription;
    const description = stringFormatter(template, {
      unit: unitName,
      level: levelName,
      facility: facilityName
    });

    // Format a date string from the start and end times
    const startTime = moment(params.checkIn);
    const endTime = moment(params.checkOut);
    const isAllDay = params.allDay;
    const formattedDate = params.recurrence?.enabled
      ? formatRecurringDates(params.recurrence, startTime, endTime, isAllDay)
      : formatDate(startTime, endTime, isAllDay);

    const nameInfo = personName && (
      <div className={CSS.iconLabel}>
        <UserIcon />
        <p>{personName}</p>
      </div>
    );


    const errorInfo = errorPersonName && (
      <div className={CSS.bookingFailed}>
        {stringFormatter(failedTemplate, { person: errorPersonName })}
      </div>
    );

    const requestInfo = (
      <div className={CSS.bookingOthersInfo}>
        <div className={CSS.iconLabel}>
          <ClockIcon />
          <p>{formattedDate}</p>
        </div>
        <div className={CSS.iconLabel}>
          <PinIcon />
          <p>{description}</p>
        </div>
        {nameInfo}
        {errorInfo}
        {/* {hasReservations && <h2>{stringFormatter(confirmedTemplate, { unit: unitName })}</h2>}
                {hasReservations && <ul>{reservations.map((reservation) => this.renderPersonTag(reservation, false))}</ul>}
                {hasRejections && <h2>{stringFormatter(failedTemplate, { unit: unitName })}</h2>}
                {hasRejections && <ul>{rejections.map((person) => this.renderPersonTag(person, true))}</ul>} */}
      </div>
    );

    const footer = (
      <div className={CSS.footer}>
        {this.renderCalendarButton()}
      </div>
    );

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <div>
              <h2>{this.props.unitName}</h2>
              <h4>{title}</h4>
            </div>
            <div className={CSS.panelHeaderTools}>
              <button
                ref={this.closeRef}
                title={i18n.general.close}
                aria-label={i18n.infoPanel.closeAriaLabel}
                onClick={this.props.closePopup}
              >
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.bookingConfirmedHeader}>
            {hasReservations ? <CheckCircleIcon color="green" /> : <ExclamationMarkTriangleIcon color="#ecaf08" />}
            <h1>{requestHeader}</h1>
          </div>
          {requestInfo}
          {hasReservations && footer}
        </div>
      </div>
    );
  }

  render() {
    const i18n = Context.instance.i18n;
    const { data, unitName, levelName, facilityName, params } = this.props;
    const rejections = data && typeof data !== "boolean" && "rejections" in data && data.rejections;

    if (!!rejections) {
      return this.renderMultipleBookings();
    }

    let title = i18n.more.bookWorkspace.bookingDetailsTitle;
    title = title.replace("{levelName}", levelName);
    title = title.replace("{buildingName}", facilityName);

    let requestHeader =
      this.bookingSystemType === "office365"
        ? i18n.more.bookWorkspace.bookingRequested
        : this.bookingSystemType === "esri"
          ? i18n.more.bookWorkspace.bookingConfirmed
          : null;

    let requestInfo =
      this.bookingSystemType === "office365"
        ? i18n.more.bookWorkspace.bookingRequestedInfo
        : this.bookingSystemType === "esri"
          ? i18n.more.bookWorkspace.bookingRequestApproved
          : null;

    if (this.bookingSystemType === "esri" && (this.props && this.props.updateBooking)) {
      requestHeader = i18n.more.bookWorkspace.bookingUpdated;
      requestInfo = i18n.more.bookWorkspace.bookingRequestUpdated
    }
    requestInfo = (<h2 style={{ marginBottom: "2rem" }}>{requestInfo.replace("{unit}", unitName)}</h2>);

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <div className={CSS.bookingDetailsTitle}>
              <h2>{this.props.unitName}</h2>
              <h4>{title}</h4>
            </div>
            <div className={CSS.panelHeaderTools}>
              <button ref={this.closeRef}
                title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
                tabIndex={0} onClick={this.props.closePopup}>
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.bookingConfirmedBody}>
            <CheckCircleIcon color="green" />
            <h1>{requestHeader}</h1>
            {requestInfo}
            {this.renderCalendarButton()}
          </div>
        </div>
      </div>
    );
  }
}