import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as selectionUtil from "../../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import Topic from "../../../../context/Topic";
import * as mapUtil from "../../../base/mapUtil";
import { getAttributeValue } from "../../../../aiim/util/aiimUtil";
import { calculateArea } from "../../../../util/geoUtil";
import { LayerType } from "../../../../util/interfaces";
import { HitTest } from "../redux";
import { getIdField } from "./formUtil";
import { IGeometry, IHasZM } from "@esri/arcgis-rest-types";

export let config = {
  entryway: {
    fpeType: "entryway",
    detailUseType: "Door"
  },
  window: {
    fpeType: "window",
    detailUseType: "Window Frame"
  }
}

export type WarningMsg = { message: string, submessage?: string, type: "warning" | "error" }
export enum CutType {
  none = "none",
  cutThis = "cutThis",
  cutUnderlying = "cutUnderlying",
}

/** Determines if a footprint with the specified ID contains any features associated with it
 * (e.g. sites contain facilities, facilities contain levels, levels contain units and/or details).
 */
export async function isEmpty(id: string, layerType: Omit<LayerType, "unit" | "detail">) {
  const { levels, facilities, sites } = Context.getInstance().aiim.datasets;
  switch (layerType) {
    case "site":
      return (await sites.containsFacilities(id)) === false;
    case "facility":
      return (await facilities.containsLevels(id)) === false;
    case "level":
      return (await levels.containsUnitsOrDetails(id)) === false;
  }
  return false;
}

export function isLayerViewVisible(layerView) {
  return !!(layerView && layerView.visible && layerView.visibleAtCurrentScale);
}

export function makeSnappingOptions(mixin?: __esri.SnappingOptionsProperties) {
  const levelsLayer = sourceUtil.getLevelsLayer();
  const featureSources = [];
  featureSources.push({layer: sourceUtil.getDetailsLayer(), enabled: true});
  featureSources.push({layer: sourceUtil.getGrid().layer, enabled: true});
  featureSources.push({layer: sourceUtil.getUnitsLayer(), enabled: true});
  if (levelsLayer) featureSources.push({layer: levelsLayer, enabled: true});
  let snappingOptions: __esri.SnappingOptionsProperties = {
    enabled: true,
    selfEnabled: true,
    featureEnabled: true,
    // distance: ?,
    featureSources: featureSources
  }
  if (mixin) snappingOptions = Object.assign(snappingOptions,mixin);
  return snappingOptions;
}

/** Calculates and sets the area attribute of the input unit graphic.
 * @param fieldName - Name of the attribute field containing the area.
 */
export async function updateGrossArea(unit: __esri.Graphic, fieldName?: string) {
  const areaGrossField = fieldName || aiimUtil.findFieldName(sourceUtil.getUnitsLayer().fields, FieldNames.UNITS_AREA_GROSS);
  if (unit && unit.attributes && areaGrossField && unit.geometry.type === "polygon") {
    const area = await calculateArea(unit.geometry);
    unit.attributes[areaGrossField] = area;
  }
}

export function updateZ(geom: __esri.Geometry | (IGeometry & IHasZM), z:number) {
  if (!geom.hasZ) 
    geom.hasZ = true;

  if ("rings" in geom) {
    const poly = geom as __esri.Polygon;
    const rings = [];
    poly.rings && poly.rings.forEach(ring => {
      ring.forEach(coord => {
        coord[2] = z;
      })
      rings.push(ring);
    })
    poly.rings = rings;
  }

  if ("paths" in geom) {
    const line = geom as __esri.Polyline;
    const paths = [];
    line.paths && line.paths.forEach(path => {
      path.forEach(coord => {
        coord[2] = z;
      })
      paths.push(path);
    })
    line.paths = paths;
  }
}

export async function checkIfGeometryInFacilityAndFLoor(levelId:string, gfxGeom:__esri.Geometry, isShowToast:boolean=true) {
  const ctx = Context.getInstance();
  const i18nEU = ctx.i18n.editor.units;
  
  const levelData = ctx.aiim.datasets.levels.getLevelData(levelId);
  const levelLayer = ctx.aiim.datasets.levels.layer2D;
  const fldLevelId = ctx.aiim.getLevelIdField(levelLayer).name;
  const levelResults = await levelLayer.queryFeatures({
    where: `${fldLevelId} = '${levelId}'`,
    returnGeometry: true,
    outFields: ["*"],
    returnZ: true,
  });
  let levelGeom:__esri.Geometry;
  if (levelResults.features && levelResults.features.length===1) {
    levelGeom = levelResults.features[0].geometry;
  } else {
    console.warn("Unable to query for Level with ID:", levelId);
    return null;
  }

  const facId = levelData.facilityId;
  const facilityData = ctx.aiim.datasets.levels.getFacilityData(facId);
  const fldFacId = aiimUtil.findFieldName(ctx.aiim.datasets.facilities.layer2D.fields, FieldNames.FACILITY_ID);
  const facResults = await ctx.aiim.datasets.facilities.layer2D.queryFeatures({
    where: `${fldFacId} = '${facId}'`,
    returnGeometry: true,
    outFields: ["*"],
    returnZ: true,
  });
  let facilityFeature: __esri.Graphic;
  if (facResults.features && facResults.features.length===1) {
    facilityFeature = facResults.features[0];
  } else {
    console.warn("Unable to query for Facility with ID:", facId);
    return null;
  }  

  const warnings:WarningMsg[] = [];
  let isValidGeom = false;
  const ge:__esri.geometryEngine = ctx.lib.esri.geometryEngine;
  if (!ge.contains(facilityFeature.geometry, gfxGeom)) {
    console.warn(i18nEU.warnNotWithinFacility);
    const errObj:WarningMsg = {
      type: "error",
      message: i18nEU.warnNotWithinFacility,
      submessage: `${facilityData.facilityName}, ${facilityData.facilityId}`,
    };
    warnings.push(errObj);
    isShowToast && Topic.publish(Topic.ShowToast, errObj);
    isValidGeom = false;
  } else {
    if (!ge.contains(levelGeom, gfxGeom)) {
      console.warn(i18nEU.warnNotWithinFloor);
      const errObj:WarningMsg = {
        type: "error",
        message: i18nEU.warnNotWithinFloor,
        submessage: `${levelData.levelName}, ${levelData.levelId}`,
      };
      warnings.push(errObj);
      isShowToast && Topic.publish(Topic.ShowToast, errObj);;
      isValidGeom = false;
    } else {
      isValidGeom = true;
    }
  }

  return { isValidGeom, warnings };
}

export async function queryUnitsByGeometry(geometry:__esri.Geometry, levelId:string):Promise<__esri.Graphic[]> {
  const layer = sourceUtil.getUnitsLayer();
  if (!(geometry && levelId && layer))
    return null;
  let where = "1=1";
  if (levelId) {
    const fldLevelId = Context.instance.aiim.getLevelIdField(layer).name;
    where = `(${fldLevelId} = '${selectionUtil.escSqlQuote(levelId)}')`;
  }
  const fs = await layer.queryFeatures({
    outFields: ["*"],
    returnGeometry: true,
    returnZ: true,
    where: levelId ? where : "1=1",
    geometry: geometry,
  });
  const gras:__esri.Graphic[] = fs.features;
  if (levelId) {
    const levels = Context.instance.aiim.datasets.levels;
    const levelData = levels && levels.getLevelData(levelId);
    levelData && gras.forEach(f => {
      f.geometry && !f.geometry.hasZ && updateZ(f.geometry, levelData.z);
    }); 
  }
  return gras;
}

export async function queryUnits(
  gra:__esri.Graphic, 
  cutType?:CutType, 
  isExistingUnit=false,
) : Promise<__esri.Graphic[]> 
{
  if (!gra || !gra.geometry) {
    return null;
  }
  const lyr = sourceUtil.getUnitsLayer();
  const ctx = Context.getInstance();
  const fldLevel:__esri.Field = ctx.aiim.getLevelIdField(lyr);
  const fldLevelId:string = fldLevel && fldLevel.name;
  const levelId:string = fldLevelId && gra.attributes[fldLevelId];
  const fldOid:string = lyr.objectIdField;
  const oid = gra.attributes ? gra.attributes[fldOid] : null;

  if (!levelId || (isExistingUnit && !oid)) {
    console.warn("Invalid attributes on input graphic, must have fields:", fldOid, isExistingUnit ? fldLevelId : "");
    return null;
  }

  const where = `(${fldLevelId} = '${selectionUtil.escSqlQuote(levelId)}')`;
  const fs:__esri.FeatureSet = await lyr.queryFeatures({
    geometry: gra.geometry,
    returnGeometry: true,
    returnZ: true,
    where,
    spatialRelationship: "intersects",
    outFields: ["*"],
  });
  if (Array.isArray(fs.features) && fs.features.length>0) {
    const levelData = ctx.aiim.datasets.levels.getLevelData(levelId);
    const gras = isExistingUnit ? fs.features.filter(f => f.attributes[fldOid] !== oid) : fs.features;
    return gras.map(f => {
      if (f.geometry && !f.geometry.hasZ) {
        updateZ(f.geometry, levelData.z);
      }
      return f;
    });
  }
  return null;
}

/**
 * 
 * @param gra - a feature representing an Unit or polygonized Detail.
 * @param mode - either cut this (which is the @param gra ) or cut existing units (from the feature layer).
 * @param isExistingUnit - is the @param gra an existing Unit Feature? false if new Unit or Detail. true when editing existing Unit feature.
 * @param warnTitle - the title for the warning toast to show in UI
 * @param isShowToast - should toasts popup in UI when there's an error or warning?
 * @returns 
 */
export async function cutThisOrUnderlyingUnits(
  gra:__esri.Graphic, 
  mode:CutType,
  isExistingUnit = false,
  warnTitle:string, 
  isShowToast=false
) : Promise<{
    cutFeatures:__esri.Graphic[], 
    engulfedFeatures:__esri.Graphic[], 
    originalFeatures:__esri.Graphic[]; 
    warnings: WarningMsg[]
  }> 
{
  if (!gra || !gra.geometry || gra.geometry.type!=="polygon") {
    console.warn("Invalid input graphic, must be a polygon");
    return null;
  }
  const results = await queryUnits(gra, mode, isExistingUnit);
  if (!results || results.length===0) {
    return null;
  }

  let stampPolygons: __esri.Polygon[];
  let featuresToCut: __esri.Graphic[];
  let originalFeatures: __esri.Graphic[];
  if (mode===CutType.cutThis) {
    featuresToCut = [ gra ];
    stampPolygons = results.map(g => g.geometry as __esri.Polygon);
    originalFeatures = [ gra.clone() ];
  } else if (mode===CutType.cutUnderlying) {
    featuresToCut = results;
    stampPolygons = [ gra.geometry as __esri.Polygon ];
    originalFeatures = results;
  }

  const sps = stampPolygons && stampPolygons.filter(x=>!!x);
  if (!sps || sps.length === 0 || !featuresToCut || featuresToCut.length === 0) {
    console.warn("Unable to cut units, invalid input/s");
    return null;
  }

  const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
  const stampPoly = ge.union(sps);
  if (!stampPoly) {
    console.warn("Unable to cut units, produced invalid union");
    return null;
  }

  const cutFeatures = featuresToCut.map(f => f.clone());

  const lyr: __esri.FeatureLayer = sourceUtil.getUnitsLayer();
  const fldUnitId = aiimUtil.findFieldName(lyr.fields, FieldNames.UNIT_ID);
  const fldUnitName = aiimUtil.findFieldName(lyr.fields, FieldNames.NAME);
  const objectIdField = lyr && lyr.objectIdField;
  const flds = [fldUnitId, fldUnitName, objectIdField];

  const warnings: WarningMsg[] = [];
  warnTitle = warnTitle || "Reshaping produced an invalid geometry";

  const notifyAndLogInvalid = (graphic: __esri.Graphic, msgType: string, type: "error" | "warning") => {
    const submessage = msgType + " for " + flds.map(n => `${n}=${graphic.attributes[n]}`).join(", ");
    console.warn(warnTitle, submessage);
    const wm1:WarningMsg = { type, message: warnTitle, submessage };
    warnings.push(wm1);
    isShowToast && Topic.publish(Topic.ShowToast, wm1);
  }

  const engulfedFeatures = [];
  for (const [i, graphic] of cutFeatures.entries()) { //cutFeatures.forEach(async (graphic, i) => {
    if (!graphic.geometry)
      return;

    const polygon = ge.difference(graphic.geometry, stampPoly) as __esri.Polygon;
    if (!polygon) {
      //stamp-out/cut result can be null if feature is entirely within stampPoly
      notifyAndLogInvalid(graphic, "Empty unit", "error");
      engulfedFeatures.push(graphic);
    } else {
      const area = await calculateArea(polygon);
      if (!area || area < 0.9) {
        notifyAndLogInvalid(graphic, "Very small unit", "error");
        engulfedFeatures.push(graphic);
      } else {
        if (!ge.isSimple(polygon) || polygon.isSelfIntersecting) {
          //TODO should we still allow user to proceed with drawing
          notifyAndLogInvalid(graphic, "Not simple or self-intersecting", "error");
        } else {
          //all good
          graphic.geometry = ge.simplify(polygon);
        }
      }
    }
  }
  return { cutFeatures, warnings, engulfedFeatures, originalFeatures };
}

export function logArea(gra:{attributes:{},geometry:__esri.Geometry}, msg:string) {
  const lyr: __esri.FeatureLayer = sourceUtil.getUnitsLayer();
  const fldUnitId = aiimUtil.findFieldName(lyr.fields, FieldNames.UNIT_ID);
  const fldUnitName = aiimUtil.findFieldName(lyr.fields, FieldNames.NAME);
  const attrMsg = gra.attributes ? [fldUnitName, fldUnitId].map(n => `${n}=${gra.attributes[n]}`).join(", ") : "";
  const ge:__esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
  const area = gra.geometry ? ge.geodesicArea(<__esri.Polygon>gra.geometry, "square-feet") : '<null geometry>'; 
  console.log(msg, area, attrMsg);
}

function activateLevel(options){
  if (options.levelData) {
    const levels = Context.instance.aiim.datasets.levels;
    const facilityData = levels.getFacilityData(options.levelData.facilityId);
    const levelData = options.levelData;
    if (facilityData && levelData) {
      Topic.publish(Topic.ActivateLevel,{
        view: options.view,
        facilityData: facilityData,
        levelData: levelData
      });
    }
  }
}

export function onZoomToFeature(feature) {
  const view = Context.getInstance().views.activeView;
  const levelId = getAttributeValue(feature.attributes, FieldNames.LEVEL_ID);
  const levels = Context.instance.aiim.datasets.levels;
  const levelData = levels && levels.getLevelData(levelId);
  activateLevel({
    levelData: levelData,
    view: view
  });
  mapUtil.goToGeometry(view,feature.geometry, 'fpe_feature');
}

export function goToPointCenter(mapPoint) {
  const view = Context.getInstance().views.activeView;
  const lib = Context.getInstance().lib;
  let pt = new lib.esri.Point({
    x: mapPoint.x,
    y: mapPoint.y,
    spatialReference: mapPoint.spatialReference
  });
  view.goTo(pt);
}

export function activateFloorFilter(feature: HitTest) {
  const { views: { floorFilter }, aiim: { datasets: { levels } } } = Context.getInstance();
  const attributes = feature?.feature.attributes;
  const idField = getIdField(["site", "facility"].includes(feature?.key) ? feature?.key : "level");
  const id = getAttributeValue(attributes, idField);
  if (id?.length > 0) {
    switch (feature?.key) {
      case "detail":
      case "level":
      case "unit":
        floorFilter.setLevel(id);
        break;
      case "facility":
        const facilityData = levels.getFacilityData(id);
        const level = facilityData?.levelsByVO[facilityData.baseVO];
        if (level) {
          floorFilter.setLevel(level.levelId);
        } else {
          floorFilter.setFacility(id);
        }
        floorFilter.activeWidget.viewModel.set("filterMenuType", "facility");
        break;
      case "site":
        floorFilter.setFacility(null);
        floorFilter.setSite(id);
        floorFilter.activeWidget.viewModel.set("filterMenuType", "facility");
        break;
    }
  }
}
