// Framework
import React, { Component } from "react";

// Components
import Filter from "../Filter";
import Sort from "./Sort";
import Context from "../../../context/Context";
import * as component from "../../../components/util/component";

// calcite-react
import BackIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import Button from 'calcite-react/Button';
import Search from "./Search";

// Styled-Components
import {
  Container,
  TitleRow,
  Title,
  FilterRow,
  SearchRow
} from "../../styles/Common/listHeader";

class ListHeader extends Component {
  componentDidMount = () => { }

  componentWillUnmount = () => {
    component.componentWillUnmount(this);
  };

  renderBackButton = () => {
    const i18n = Context.instance.i18n;
    return (
      <Button
        onClick={event => this.props.backClicked(event)}
        icon={<BackIcon size={16} className="i-rtl-icon" />}
        iconPosition="before"
      >{i18n.general.backToList}</Button>
    );
  };

  render() {
    const { placeholderStringProp, backClicked } = this.props;
    const i18n = Context.instance.i18n;
    const title = this.props.title || '';
    const layerCaption = this.props.layerCaption || '';
    const queryCriteriaKey = this.props.queryCriteriaKey || '';
    const hasFilterRow = this.props.layerCaption && this.props.queryCriteriaKey;
    const button = this.props.button || null;
    const hasBackButton = this.props.backClicked || false;
    const filterTooltip = this.props.filterTooltip || "";
    let placeholderString = placeholderStringProp || '';
    const hideSortButton = !!this.props.hideSortButton;

    if(queryCriteriaKey === "spaceplanner/workbar/PeoplePanel" || queryCriteriaKey === "spaceplanner/assetbar/PeoplePanel")
      placeholderString = i18n.spaceplanner.people.listHeaderPlaceholder;
    else if(queryCriteriaKey === "spaceplanner/workbar/UnitsPanel" || queryCriteriaKey === "spaceplanner/assetbar/UnitsPanel")
      placeholderString = i18n.spaceplanner.units.listHeaderPlaceholder;

    return (
      <Container>
        <TitleRow>
          {hasBackButton ? this.renderBackButton() : null}
          <Title>{title}</Title>
          {button}
        </TitleRow>
        {hasFilterRow ? (
          <>
            <SearchRow>
              <Search
                fullWidth
                minimal
                queryCriteriaKey={queryCriteriaKey}
                placeholder={placeholderString}
                title={placeholderString}
              />
            </SearchRow>

            <FilterRow>
              <Filter
                queryCriteriaKey={queryCriteriaKey}
                layerCaption={layerCaption}
                filterTooltip={filterTooltip}
              />

              {!hideSortButton && (
                <Sort queryCriteriaKey={queryCriteriaKey} placement="bottom" />
              )}
            </FilterRow>
          </>
        ) : null}
      </Container>
    );
  }
}

export default ListHeader;
