import React from "react";

import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../../src/components/util/component";

const CSS = {
  main: "i-about",
  product: "i-product",
  productLogo: "i-product-logo",
  productInfo: "i-product-info",
  productName: "i-product-name",
  productVersion: "i-product-version",
  htmlElement: "i-product-htmlElement"
};

export default class About extends React.Component {

  constructor(props){
    super(props);
    const config = Context.getInstance().config;
    let htmlElement = null, dangerouslySetInnerHTML = false;
    if(config && config.aboutSection && config.aboutSection.enabled){
      htmlElement = config.aboutSection.htmlElement;
      dangerouslySetInnerHTML = true;
    }
    this.state = component.newState({
      htmlElement: htmlElement,
      dangerouslySetInnerHTML: dangerouslySetInnerHTML
    })
  }

  componentDidMount() { 
    component.own(this,[
      Topic.subscribe(Topic.AboutSection,(params) => {
        if (params.show) {
          this.setState({
            htmlElement: params.htmlElement,
            dangerouslySetInnerHTML: true
          })
        }else{
          this.setState({
            dangerouslySetInnerHTML:false
          })
        }
      })
    ]);
  }

  render() {
    return (
      <div className={CSS.main}>
        {this.renderProduct()}
      </div>
    );
  }

  renderProduct() {
    const i18n = Context.getInstance().i18n;
    const vinfo = Context.getInstance().versionInfo;
    let version = vinfo.version;
    if (typeof vinfo.build === "string" && vinfo.build.trim().length > 0) {
      version = vinfo.build.trim();
    }
    const vtext = i18n.about.versionPattern.replace("{version}",version);
    return (
      <div className={CSS.product}>
        <div className={CSS.productLogo}></div>
        <div className={CSS.productInfo}>
          <div className={CSS.productName}>ArcGIS Indoors</div>
          <div className={CSS.productVersion}>{vtext}</div>
        </div>
        {this.state.dangerouslySetInnerHTML && (
          <div className={CSS.htmlElement}>
            <div dangerouslySetInnerHTML={{ __html: Context.sanitizeHtml(this.state.htmlElement)}}></div>
          </div>
        )}
      </div>
    );
  }
}
