import React from "react";

export default class Icons {

  static iconBasePath = "libs/calcite-ui-icons/icons/sprite-24.svg#";
  static iconBasePath16 = "libs/calcite-ui-icons/icons/sprite-16.svg#";

  static arrowDown() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M21 6V3H11v13H2l14 14 14-14h-9z"/></svg>
    );
  };

  static arrowUp() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M21 16h9L16 2 2 16h9v13h10V16z"/></svg>
    );
  };

  static ascendingSort() {
    return (
    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4 9h11v1H4zm-3 5h14v-1H1zm6-8h8V5H7zm3-4h5V1h-5z"/></svg>
    );
  }

  static audioOff() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.172 9L12 15.828v3.306a.51.51 0 0 1-.807.414L5.67 15H2.376A1.376 1.376 0 0 1 1 13.624v-3.248A1.376 1.376 0 0 1 2.376 9zM18.93 1.411l-.73.654A14.852 14.852 0 0 1 22 11.99a14.936 14.936 0 0 1-2.323 7.98l-3.02-3.019A9.847 9.847 0 0 0 18 11.987a9.975 9.975 0 0 0-2.53-6.643l-.747.665A8.984 8.984 0 0 1 17 11.987a8.867 8.867 0 0 1-1.078 4.228L12 12.293V4.866a.51.51 0 0 0-.807-.414L7.335 7.628 2.308 2.6l-.707.708 19.091 19.091.707-.707-1.014-1.014A15.765 15.765 0 0 0 18.93 1.411z"/></svg>
    );
  }

  static audioOn() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4.866v14.268a.51.51 0 0 1-.807.414L5.67 15H2.376A1.376 1.376 0 0 1 1 13.624v-3.248A1.376 1.376 0 0 1 2.376 9h3.293l5.524-4.548a.51.51 0 0 1 .807.414zm2.723 1.143A8.984 8.984 0 0 1 17 11.987a8.872 8.872 0 0 1-2.306 5.974l.74.672A9.848 9.848 0 0 0 18 11.987a9.975 9.975 0 0 0-2.53-6.643zm3.476-3.944A14.852 14.852 0 0 1 22 11.99a14.966 14.966 0 0 1-3.77 9.925l.735.65A15.945 15.945 0 0 0 23 11.99a15.817 15.817 0 0 0-4.07-10.578z"/></svg>
    );
  }

  // static audioOff() {
  //   return (
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.12 76.99"><polygon points="48.8 1.57 26.89 19.41 48.8 41.33 48.8 1.57"/><path key="a" d="M68.47,39.28a21.52,21.52,0,0,1-6.38,15.33l3.76,3.76A26.95,26.95,0,0,0,55.07,13.61v5.66A21.61,21.61,0,0,1,68.47,39.28Z"/><path key="b" d="M63,39.28a16.15,16.15,0,0,0-7.93-13.91V47.59l3.15,3.15A16.08,16.08,0,0,0,63,39.28Z"/><path key="c" d="M4.18,0,0,4.18,20.46,24.65l-1,.79H1.8V53.12H19.48L48.8,77V53l6.29,6.3h0V65l0,0a26.36,26.36,0,0,0,4-1.65L71.94,76.12l4.18-4.18Z"/></svg>
  //   );
  // }

  // static audioOn() {
  //   return (
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.99 75.42"><polygon points="0 23.86 0 51.55 17.68 51.55 47 75.42 47 0 17.68 23.86 0 23.86"/><path key="a" d="M53.27,12V17.7a21.63,21.63,0,0,1,0,40v5.67a27,27,0,0,0,0-51.34Z"/><path key="b" d="M61.2,37.71A16.15,16.15,0,0,0,53.27,23.8V51.62A16.16,16.16,0,0,0,61.2,37.71Z"/></svg>
  //   );
  // }

  static back() {
    return (
      <svg className="svg-icon i-icon-arrow-back" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" ><path d="M10.07 12L20.072 2H13L0 15l13 13h7.07l-10-10H32v-6z"/></svg>
    );
  };

  static bell() {
    const icon = Icons.iconBasePath16 + "bell-16-f";
    return (
      <svg className="svg-icon i-icon-bell"><use href={icon}></use></svg>
    );
  };


  static bookHotel() {
    const icon = Icons.iconBasePath16 + "services-16";
    return (
      <svg className="svg-icon"><use href={icon}></use></svg>
    );
  };

  static building() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-icon"><path d="M22 4H12V2H5v2H2v18H0v1h11v-5h2v5h11v-1h-2zm-8 18v-5h-4v5H3V5h3V3h5v2h10v17zM5 11h4V7H5zm1-3h2v2H6zm4 3h4V7h-4zm1-3h2v2h-2zm4 3h4V7h-4zm1-3h2v2h-2zM5 16h4v-4H5zm1-3h2v2H6zm4 3h4v-4h-4zm1-3h2v2h-2zm4 3h4v-4h-4zm1-3h2v2h-2z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
    )
  };

  static burger() {
    const icon = Icons.iconBasePath + "hamburger-24-f";
    return (
      <svg className="svg-icon"><use href={icon}></use></svg>
    );
  };

  static calendar() {
    return (
      <svg className="svg-icon i-icon-calendar" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M2 32h26V12H2v20zm20-18h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm-6-12h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zm-6-12h4v4h-4v-4zm0 6h4v4h-4v-4zm0 6h4v4h-4v-4zM4 14h4v4H4v-4zm0 6h4v4H4v-4zm0 6h4v4H4v-4zM28 2v8H2V2h4v4h6V2h6v4h6V2h4zM10 4H8V0h2v4zm12 0h-2V0h2v4z"/></svg>
    );
  };

  static conferenceRoom() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.898 12.581a2.467 2.467 0 0 1 2.073-.538 2.38 2.38 0 0 1 1.42.871l.419-1.023a3.39 3.39 0 0 0-.707-.489 2.5 2.5 0 1 0-3.215-.006 3.454 3.454 0 0 0-.631.418A3.491 3.491 0 0 0 1 14.5V16h1v-1.5a2.492 2.492 0 0 1 .898-1.919zM3 9.5A1.5 1.5 0 1 1 4.5 11 1.502 1.502 0 0 1 3 9.5zm18.103 1.902a2.5 2.5 0 1 0-3.215-.007 3.448 3.448 0 0 0-.631.419c-.026.021-.044.05-.07.072l.412 1.006a2.407 2.407 0 0 1 2.372-.849A2.608 2.608 0 0 1 22 14.646V16h1v-1.354a3.647 3.647 0 0 0-1.897-3.244zM18 9.5a1.5 1.5 0 1 1 1.5 1.5A1.502 1.502 0 0 1 18 9.5zM10 9V8a1.99 1.99 0 0 1 .764-1.572 2.02 2.02 0 0 1 1.739-.367A2.08 2.08 0 0 1 14 8.119V9h1v-.88a3.173 3.173 0 0 0-1.445-2.678 2.5 2.5 0 1 0-3.1.009 2.956 2.956 0 0 0-.31.192A2.984 2.984 0 0 0 9 8v1zm.5-5.5A1.5 1.5 0 1 1 12 5a1.502 1.502 0 0 1-1.5-1.5zm9.463 17.81l-.926.38L14.664 11H9.336L4.963 21.69l-.926-.38L8.41 10.622A.997.997 0 0 1 9.336 10h5.328a.996.996 0 0 1 .925.62z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
    )
  }

  static bookCalendar() {
    return (
      <svg className="svg-icon i-icon-calendar" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M25 5v1h4v4H3V6h4V5H2v22h28V5zm4 21H3V11h26zM10 6V5h12v1zm13-3h1v4h-1zM8 3h1v4H8z"/><path fill="none" d="M0 0h32v32H0z"/></svg>    )
  }

  static cancel(){
    console.log("In cancel");
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8.718 8l5.303 5.303-.707.707L8.01 8.707 2.707 14.01 2 13.303 7.303 8 2 2.697l.707-.707L8.01 7.293l5.304-5.303.707.707z"/></svg>
    )
  }

  static checkMark() {
    return (
      <svg className="svg-icon i-icon-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M11.927 22l-6.882-6.883-3 3L11.927 28 31.204 8.728l-3.001-3.001z"/></svg>
    );
  };

  static chevronLeft() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="svg-icon"><path d="M18.793 25l-9-9 9-9h1.414l-9 9 9 9z"/><path fill="none" d="M0 0h32v32H0z"/></svg>    )
  }

  static chevronsLeft() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-icon"><path d="M12 3h2.766l-9 9 9 9H12l-9-9zm.766 9l9-9H19l-9 9 9 9h2.766z"/></svg>
    );
  };

  static chevronsRight() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-icon" ><path d="M21 12l-9 9H9.234l9-9-9-9H12zM2.234 21H5l9-9-9-9H2.234l9 9z"/></svg>
    );
  };

  static calendarPlus() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 24 24" style={{"enableBackground": "new 0 0 24 24"}}>
      <path style={{"fill": "none"}} d="M0.4,0.7h25.7v25.7H0.4V0.7z"/>
      <g>
        <path d="M17.7,3.7v0.9h1.7v2.5h-17V4.6h1.7V3.7H1.5V19h12.9v-0.9H2.3V8h17v6h0.8V3.7H17.7z M6.6,3.7v0.9h8.5V3.7H6.6z M4.9,2.9v2.5
          h0.8V2.9H4.9z M16,2.9v2.5h0.8V2.9H16z"/>
        <polygon points="22.8,16 22.8,16.8 17.8,16.8 17.8,21.8 17,21.8 17,16.8 12,16.8 12,16 17,16 17,11 17.8,11 17.8,16 	"/>
      </g>
      </svg>
    );
  }

  static scanQRGuide() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="103.931" height="135.748" viewBox="0 0 103.931 135.748">
        <defs>
          <linearGradient id="linear-gradient" x1="0.975" y1="-2.137" x2="0.975" y2="-1.137" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="gray" stopOpacity="0.251"/>
            <stop offset="0.54" stopColor="gray" stopOpacity="0.122"/>
            <stop offset="1" stopColor="gray" stopOpacity="0.102"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="1.541" y1="1.564" x2="1.541" y2="2.564" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-3" x1="1.105" y1="-0.09" x2="1.105" y2="0.91" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-4" x1="2.04" y1="-0.898" x2="2.04" y2="0.102" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-5" x1="1.159" y1="-13.218" x2="1.159" y2="-12.218" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-6" x1="1.803" y1="-1.66" x2="1.803" y2="-0.661" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-7" x1="2.099" y1="-3.867" x2="2.099" y2="-2.867" href="#linear-gradient"/>
          <linearGradient id="linear-gradient-8" x1="1.647" y1="0.084" x2="1.647" y2="1.084" href="#linear-gradient"/>
        </defs>
        <g id="Group_792" data-name="Group 792" transform="translate(-46)">
          <g id="QR-computer" transform="translate(51.814)">
            <path id="Path_470" data-name="Path 470" d="M204.763,343.735H180.4L181.236,333h22.713Z" transform="translate(-143.63 -263.81)" fill="#e6e6e6"/>
            <rect id="Rectangle_285" data-name="Rectangle 285" width="27.256" height="2.486" transform="translate(35.527 78.682)" fill="#e6e6e6"/>
            <path id="Path_471" data-name="Path 471" d="M104.432,10.1H11.286A2.487,2.487,0,0,0,8.8,12.586h0V69.369h98.1V12.586a2.469,2.469,0,0,0-2.464-2.486Z" transform="translate(-8.8 -10.1)" fill="#272727"/>
            <path id="Path_472" data-name="Path 472" d="M8.9,286.7V296a2.487,2.487,0,0,0,2.486,2.486H104.51A2.487,2.487,0,0,0,107,296h0v-9.3Z" transform="translate(-8.879 -227.431)" fill="#e6e6e6"/>
            <rect id="Rectangle_286" data-name="Rectangle 286" width="88.389" height="49.562" transform="translate(4.971 5.164)" fill="#e6e6e6"/>
            <circle id="Ellipse_99" data-name="Ellipse 99" cx="2.486" cy="2.486" r="2.486" transform="translate(46.669 62.569)" fill="#626263"/>
            <g id="Group_793" data-name="Group 793" transform="translate(27.042 8.957)">
              <rect id="Rectangle_287" data-name="Rectangle 287" width="43.284" height="41.612" fill="#fff"/>
              <g id="qr-code" transform="translate(12.192 12.614)">
                <g id="Group_719">
                  <g id="Group_718">
                    <path id="Path_390" d="M197.385,110.8H191.9v5.485h5.485Zm-1.093,4.371h-3.3v-3.278h3.278v3.278Z" transform="translate(-191.9 -110.779)" fill="#272727"/>
                    <rect id="Rectangle_252" width="1.136" height="1.136" transform="translate(2.143 2.164)" fill="#272727"/>
                    <path id="Path_391" d="M248.2,110.8v5.485h5.485V110.8Zm4.371,4.371h-3.278v-3.278h3.278Z" transform="translate(-236.136 -110.779)" fill="#272727"/>
                    <rect id="Rectangle_253" width="1.136" height="1.136" transform="translate(14.207 2.164)" fill="#272727"/>
                    <path id="Path_392" d="M191.9,172.485h5.485V167H191.9Zm1.093-4.393h3.278v3.278h-3.278Z" transform="translate(-191.9 -154.936)" fill="#272727"/>
                    <rect id="Rectangle_254" width="1.136" height="1.136" transform="translate(2.143 14.228)" fill="#272727"/>
                    <rect id="Rectangle_255" width="2.164" height="1.136" transform="translate(8.742)" fill="#272727"/>
                    <path id="Path_393" d="M224.786,119.178h1.093v-1.093h1.093v-1.093h-3.278V115.9H222.6v2.186h2.186Z" transform="translate(-216.022 -114.786)" fill="#272727"/>
                    <rect id="Rectangle_256" width="1.029" height="1.029" transform="translate(6.578 4.436)" fill="#272727"/>
                    <path id="Path_394" d="M226.971,136.3h-1.093v1.093H222.6v1.093h4.371Z" transform="translate(-216.022 -130.815)" fill="#272727"/>
                    <path id="Path_395" d="M192.993,144.778h1.093v-1.093h-1.093V141.5H191.9v4.371h1.093Z" transform="translate(-191.9 -134.9)" fill="#272727"/>
                    <rect id="Rectangle_257" width="1.136" height="1.029" transform="translate(2.143 6.6)" fill="#272727"/>
                    <path id="Path_396" d="M210.578,145.871h2.186v-1.093h-1.093v-1.093h-2.186V141.5h-1.093v2.186H207.3v2.186h1.093v-1.093h2.186Z" transform="translate(-204 -134.9)" fill="#272727"/>
                    <rect id="Rectangle_258" width="1.029" height="2.164" transform="translate(9.857 8.764)" fill="#272727"/>
                    <path id="Path_397" d="M225.878,167H222.6v1.093h2.186v3.278H222.6v1.093h3.278v-1.093h2.186v-1.093h-2.186Z" transform="translate(-216.022 -154.936)" fill="#272727"/>
                    <rect id="Rectangle_259" width="1.029" height="1.136" transform="translate(6.578 14.228)" fill="#272727"/>
                    <rect id="Rectangle_260" width="1.136" height="2.164" transform="translate(10.907 12.064)" fill="#272727"/>
                    <path id="Path_398" d="M256.578,177.2H253.3v3.278h1.093v-2.186h2.186Z" transform="translate(-240.143 -162.951)" fill="#272727"/>
                    <rect id="Rectangle_261" width="2.164" height="1.136" transform="translate(15.321 16.392)" fill="#272727"/>
                    <rect id="Rectangle_262" width="1.136" height="1.136" transform="translate(16.371 12.064)" fill="#272727"/>
                    <path id="Path_399" d="M249.293,160.078h1.093V156.8H248.2v1.093h1.093Z" transform="translate(-236.136 -146.922)" fill="#272727"/>
                    <path id="Path_400" d="M251.478,142.593h-1.093V141.5h-1.093v1.093H248.2v1.093h4.371v-1.093h1.093V141.5h-2.186Z" transform="translate(-236.136 -134.9)" fill="#272727"/>
                    <rect id="Rectangle_263" width="2.164" height="1.029" transform="translate(15.321 9.9)" fill="#272727"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="undraw_cloud_sync_2aph" transform="translate(46 45.874)">
            <path id="Path_450" data-name="Path 450" d="M110.164,378.562a9.66,9.66,0,0,0-19.153-1.793L61.3,375.958l.327,5.108s-4.24,9.776,18.029,12.38v9.875H105.4V386.9A9.688,9.688,0,0,0,110.164,378.562Z" transform="translate(-52.585 -317.83)" fill="url(#linear-gradient)"/>
            <path id="Path_451" data-name="Path 451" d="M68.913,426.381s-4.055,9.363,17.26,11.853V447.7h24.646V422.638L68.6,421.5Z" transform="translate(-58.846 -362.945)" fill="#ffb88b"/>
            <path id="Path_452" data-name="Path 452" d="M27.149,86.915h0A4.549,4.549,0,0,1,20.9,88.338L7.668,79.615a4.549,4.549,0,0,1-1.423-6.247h0a4.549,4.549,0,0,1,6.247-1.423l13.233,8.723A4.537,4.537,0,0,1,27.149,86.915Z" transform="translate(-4.773 -62.54)" fill="url(#linear-gradient-2)"/>
            <path id="Path_453" data-name="Path 453" d="M31.271,90.264h0a4.228,4.228,0,0,1-5.806,1.323L13.171,83.476a4.228,4.228,0,0,1-1.323-5.806h0a4.228,4.228,0,0,1,5.806-1.323l12.294,8.111A4.226,4.226,0,0,1,31.271,90.264Z" transform="translate(-9.621 -66.358)" fill="#ffb88b"/>
            <circle id="Ellipse_97" data-name="Ellipse 97" cx="9.249" cy="9.249" r="9.249" transform="translate(38.042 51.796)" fill="#ffb88b"/>
            <path id="Path_454" data-name="Path 454" d="M62.751,10h35.46a1.448,1.448,0,0,1,1.451,1.451V77.789a1.448,1.448,0,0,1-1.451,1.451H62.751A1.448,1.448,0,0,1,61.3,77.789V11.451A1.458,1.458,0,0,1,62.751,10Z" transform="translate(-52.585 -10)" fill="url(#linear-gradient-3)"/>
            <path id="Path_455" data-name="Path 455" d="M66.934,16.6h33.454a1.937,1.937,0,0,1,1.935,1.935V82.042a1.937,1.937,0,0,1-1.935,1.935H66.934A1.937,1.937,0,0,1,65,82.042V18.535A1.919,1.919,0,0,1,66.934,16.6Z" transform="translate(-55.757 -15.661)" fill="#fff"/>
            <path id="Path_456" data-name="Path 456" d="M98.521,28.414a3.029,3.029,0,0,1-2.988,2.576H82.641a3.029,3.029,0,0,1-2.988-2.576h-6.83A1.427,1.427,0,0,0,71.4,29.837V91.01a1.427,1.427,0,0,0,1.423,1.423h32.628a1.427,1.427,0,0,0,1.423-1.423V29.823a1.427,1.427,0,0,0-1.423-1.423Z" transform="translate(-61.247 -25.782)" fill="#efefef"/>
            <g id="qr-code-2" data-name="qr-code" transform="translate(14.45 18.464)">
              <g id="Group_719-2" data-name="Group_719">
                <g id="Group_718-2" data-name="Group_718">
                  <path id="Path_390-2" data-name="Path_390" d="M109.882,167.9H101.6v8.282h8.282Zm-1.651,6.617h-4.966v-4.966h4.966Z" transform="translate(-101.6 -167.886)" fill="#272727"/>
                  <rect id="Rectangle_252-2" data-name="Rectangle_252" width="1.708" height="1.708" transform="translate(3.244 3.273)" fill="#272727"/>
                  <path id="Path_391-2" data-name="Path_391" d="M229.6,167.9v8.282h8.282V167.9Zm6.631,6.617h-4.966v-4.966h4.966Z" transform="translate(-211.386 -167.886)" fill="#272727"/>
                  <rect id="Rectangle_253-2" data-name="Rectangle_253" width="1.708" height="1.708" transform="translate(21.458 3.273)" fill="#272727"/>
                  <path id="Path_392-2" data-name="Path_392" d="M101.7,304.082h8.282V295.8H101.7Zm1.651-6.617h4.966v4.966h-4.966Z" transform="translate(-101.686 -277.586)" fill="#272727"/>
                  <rect id="Rectangle_254-2" data-name="Rectangle_254" width="1.708" height="1.708" transform="translate(3.244 21.487)" fill="#272727"/>
                  <rect id="Rectangle_255-2" data-name="Rectangle_255" width="3.273" height="1.708" transform="translate(13.205)" fill="#272727"/>
                  <path id="Path_393-2" data-name="Path_393" d="M174.8,184.552h1.651V182.9h1.665v-1.651h-4.966V179.6H171.5v3.315h3.315v1.636Z" transform="translate(-161.554 -177.921)" fill="#272727"/>
                  <rect id="Rectangle_256-2" data-name="Rectangle_256" width="1.565" height="1.565" transform="translate(9.932 6.688)" fill="#272727"/>
                  <path id="Path_394-2" data-name="Path_394" d="M178.117,226.1h-1.651v1.651H171.5V229.4h6.617Z" transform="translate(-161.554 -217.804)" fill="#272727"/>
                  <path id="Path_395-2" data-name="Path_395" d="M103.351,242.666H105v-1.651h-1.651V237.7H101.7v6.617h1.651Z" transform="translate(-101.686 -227.754)" fill="#272727"/>
                  <rect id="Rectangle_257-2" data-name="Rectangle_257" width="1.708" height="1.565" transform="translate(3.244 9.961)" fill="#272727"/>
                  <path id="Path_396-2" data-name="Path_396" d="M141.652,244.317h3.316v-1.651h-1.651v-1.651H140V237.7h-1.651v3.315H136.7v3.315h1.651V242.68h3.316v1.636Z" transform="translate(-131.705 -227.754)" fill="#272727"/>
                  <rect id="Rectangle_258-2" data-name="Rectangle_258" width="1.565" height="3.273" transform="translate(14.913 13.233)" fill="#272727"/>
                  <path id="Path_397-2" data-name="Path_397" d="M176.466,295.9H171.5v1.651h3.315v4.966H171.5v1.651h4.966v-1.651h3.315v-1.651h-3.315Z" transform="translate(-161.554 -277.672)" fill="#272727"/>
                  <rect id="Rectangle_259-2" data-name="Rectangle_259" width="1.565" height="1.708" transform="translate(9.932 21.487)" fill="#272727"/>
                  <rect id="Rectangle_260-2" data-name="Rectangle_260" width="1.708" height="3.273" transform="translate(16.478 18.214)" fill="#272727"/>
                  <path id="Path_398-2" data-name="Path_398" d="M246.266,319.1H241.3v4.966h1.651v-3.315h3.315Z" transform="translate(-221.421 -297.571)" fill="#272727"/>
                  <rect id="Rectangle_261-2" data-name="Rectangle_261" width="3.273" height="1.708" transform="translate(23.166 24.759)" fill="#272727"/>
                  <rect id="Rectangle_262-2" data-name="Rectangle_262" width="1.708" height="1.708" transform="translate(24.731 18.214)" fill="#272727"/>
                  <path id="Path_399-2" data-name="Path_399" d="M231.265,277.566h1.651V272.6H229.6v1.651h1.651v3.315Z" transform="translate(-211.386 -257.687)" fill="#272727"/>
                  <path id="Path_400-2" data-name="Path_400" d="M234.652,239.351H233V237.7h-1.651v1.651H229.7v1.665h6.617v-1.651h1.651v-1.651h-3.315Z" transform="translate(-211.472 -227.754)" fill="#272727"/>
                  <rect id="Rectangle_263-2" data-name="Rectangle_263" width="3.273" height="1.565" transform="translate(23.166 14.941)" fill="#272727"/>
                </g>
              </g>
            </g>
            <g id="Group_792-2" data-name="Group 792" transform="translate(11.931 13.61)">
              <path id="Path_457" data-name="Path 457" d="M275.323,393.1v4.041H270.4" transform="translate(-243.862 -361.742)" fill="none" stroke="#272727" strokeMiterlimit="10" strokeWidth="1"/>
              <path id="Path_458" data-name="Path 458" d="M270.4,111.8h4.923v4.6" transform="translate(-243.862 -111.8)" fill="none" stroke="#272727" strokeMiterlimit="10" strokeWidth="1"/>
              <path id="Path_459" data-name="Path 459" d="M83.9,116.4v-4.6h4.838" transform="translate(-83.9 -111.8)" fill="none" stroke="#272727" strokeMiterlimit="10" strokeWidth="1"/>
              <path id="Path_460" data-name="Path 460" d="M83.9,393.1v4.041h4.838" transform="translate(-83.9 -361.742)" fill="none" stroke="#272727" strokeMiterlimit="10" strokeWidth="1"/>
            </g>
            <path id="Path_461" data-name="Path 461" d="M167.55,34.1h7.8a.255.255,0,0,1,.256.256h0a.255.255,0,0,1-.256.256h-7.8a.255.255,0,0,1-.256-.256h0A.247.247,0,0,1,167.55,34.1Z" transform="translate(-143.495 -30.671)" fill="#dbdbdb"/>
            <circle id="Ellipse_98" data-name="Ellipse 98" cx="0.313" cy="0.313" r="0.313" transform="translate(33.56 3.33)" fill="#dbdbdb"/>
            <path id="Path_462" data-name="Path 462" d="M305.454,252.47h0c-2.689.626-4.525-1.124-5.151-3.814l-6.161-22.91a5.026,5.026,0,0,1,3.757-6h0a5.019,5.019,0,0,1,6,3.757l5.037,23.493A4.337,4.337,0,0,1,305.454,252.47Z" transform="translate(-252.186 -189.789)" fill="url(#linear-gradient-4)"/>
            <path id="Path_463" data-name="Path 463" d="M303.425,227.835h0a4.207,4.207,0,0,1,5.051,3.159l5.678,24.56A4.207,4.207,0,0,1,311,260.606h0a4.207,4.207,0,0,1-5.051-3.159l-5.678-24.56A4.229,4.229,0,0,1,303.425,227.835Z" transform="translate(-257.457 -196.744)" fill="#ffb88b"/>
            <rect id="Rectangle_283" data-name="Rectangle 283" width="35.232" height="8.21" transform="translate(21.28 81.663)" fill="url(#linear-gradient-5)"/>
            <rect id="Rectangle_284" data-name="Rectangle 284" width="33.326" height="6.432" transform="translate(22.233 82.602)" fill="#272727"/>
            <path id="Path_464" data-name="Path 464" d="M17.925,278.1h0a4.49,4.49,0,0,1-6.176,1.409L2.87,273.764a4.5,4.5,0,0,1-1.394-6.176h0a4.49,4.49,0,0,1,6.176-1.409l8.879,5.749A4.5,4.5,0,0,1,17.925,278.1Z" transform="translate(-0.686 -229.14)" fill="url(#linear-gradient-6)"/>
            <path id="Path_465" data-name="Path 465" d="M22.255,373.629h0a4.545,4.545,0,0,1-6.233,1.423l-5.493-4.013a4.529,4.529,0,0,1-1.409-6.233h0a4.545,4.545,0,0,1,6.233-1.423l5.493,4.013A4.56,4.56,0,0,1,22.255,373.629Z" transform="translate(-7.236 -312.513)" fill="url(#linear-gradient-7)"/>
            <path id="Path_466" data-name="Path 466" d="M24.8,376h0a4.228,4.228,0,0,1-5.806,1.323l-5.123-3.742a4.228,4.228,0,0,1-1.323-5.806h0a4.228,4.228,0,0,1,5.806-1.323l5.123,3.742A4.231,4.231,0,0,1,24.8,376Z" transform="translate(-10.221 -315.178)" fill="#ffb88b"/>
            <path id="Path_467" data-name="Path 467" d="M20.629,280.46h0a4.228,4.228,0,0,1-5.806,1.323l-8.353-5.407a4.228,4.228,0,0,1-1.323-5.806h0a4.245,4.245,0,0,1,5.82-1.323l8.353,5.407A4.227,4.227,0,0,1,20.629,280.46Z" transform="translate(-3.874 -231.809)" fill="#ffb88b"/>
            <path id="Path_468" data-name="Path 468" d="M19.6,183.46h0a4.508,4.508,0,0,1-6.2,1.409L2.15,177.54a4.491,4.491,0,0,1-1.409-6.19h0a4.508,4.508,0,0,1,6.2-1.409l11.241,7.328A4.5,4.5,0,0,1,19.6,183.46Z" transform="translate(-0.052 -146.591)" fill="url(#linear-gradient-8)"/>
            <path id="Path_469" data-name="Path 469" d="M22.906,186.174h0A4.228,4.228,0,0,1,17.1,187.5l-10.53-6.859a4.228,4.228,0,0,1-1.323-5.806h0a4.216,4.216,0,0,1,5.806-1.323l10.53,6.859A4.228,4.228,0,0,1,22.906,186.174Z" transform="translate(-3.96 -149.689)" fill="#ffb88b"/>
            <text id="SCAN" transform="translate(27.765 58.873)" fill="#272727" fontSize="8" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight="500"><tspan x="-10.992" y="0">SCAN</tspan></text>
          </g>
        </g>
      </svg>
    )
  }

  static scanQR() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16.434" height="29.787" viewBox="0 0 16.434 29.787">
        <g id="qr-code" transform="translate(2.746 8)">
          <g id="Group_782" data-name="Group 782">
            <g id="Group_781" data-name="Group 781">
              <path id="Path_420" data-name="Path 420" d="M3.006,0H0V3.006H3.006ZM2.4,2.4H.6V.6H2.4Z" fill="#0079c1"/>
              <rect id="Rectangle_265" data-name="Rectangle 265" transform="translate(2.254 2)" fill="#0079c1"/>
              <path id="Path_421" data-name="Path 421" d="M352,0V3.006h3.006V0Zm2.4,2.4h-1.8V.6h1.8Z" transform="translate(-343.94)" fill="#0079c1"/>
              <rect id="Rectangle_266" data-name="Rectangle 266" width="0.79" transform="translate(9.351 1.581)" fill="#0079c1"/>
              <path id="Path_422" data-name="Path 422" d="M0,355.006H3.006V352H0Zm.6-2.4H2.4v1.8H.6Z" transform="translate(0 -343.94)" fill="#0079c1"/>
              <rect id="Rectangle_267" data-name="Rectangle 267" height="0.79" transform="translate(1.581 9.351)" fill="#0079c1"/>
              <rect id="Rectangle_268" data-name="Rectangle 268" width="1.581" height="0.79" transform="translate(5.596)" fill="#0079c1"/>
              <path id="Path_423" data-name="Path 423" d="M193.2,33.8h.6v-.6h.6v-.6h-1.8V32H192v1.2h1.2Z" transform="translate(-187.669 -31.399)" fill="#0079c1"/>
              <rect id="Rectangle_269" data-name="Rectangle 269" width="0.79" transform="translate(3.68 2.728)" fill="#0079c1"/>
              <path id="Path_424" data-name="Path 424" d="M194.4,160h-.6v.6H192v.6h2.4Z" transform="translate(-187.669 -156.477)" fill="#0079c1"/>
              <path id="Path_425" data-name="Path 425" d="M.6,193.8h.6v-.6H.6V192H0v2.4H.6Z" transform="translate(0 -187.669)" fill="#0079c1"/>
              <rect id="Rectangle_270" data-name="Rectangle 270" height="0.79" transform="translate(1.581 3.68)" fill="#0079c1"/>
              <path id="Path_426" data-name="Path 426" d="M97.8,194.4h1.2v-.6h-.6v-.6H97.2V192h-.6v1.2H96v1.2h.6v-.6h1.2Z" transform="translate(-93.802 -187.669)" fill="#0079c1"/>
              <rect id="Rectangle_271" data-name="Rectangle 271" width="0.79" height="1.581" transform="translate(6.44 5.596)" fill="#0079c1"/>
              <path id="Path_427" data-name="Path 427" d="M193.8,352H192v.6h1.2v1.8H192v.6h1.8v-.6h1.2v-.6h-1.2Z" transform="translate(-187.604 -343.94)" fill="#0079c1"/>
              <rect id="Rectangle_272" data-name="Rectangle 272" width="0.79" height="0.79" transform="translate(3.68 9.351)" fill="#0079c1"/>
              <rect id="Rectangle_273" data-name="Rectangle 273" width="0.79" height="0.79" transform="translate(7.36 8.28)" fill="#0079c1"/>
              <path id="Path_428" data-name="Path 428" d="M385.8,416H384v1.8h.6v-1.2h1.2Z" transform="translate(-375.339 -406.737)" fill="#0079c1"/>
              <rect id="Rectangle_274" data-name="Rectangle 274" width="0.79" height="0.79" transform="translate(10.142 10.142)" fill="#0079c1"/>
              <rect id="Rectangle_275" data-name="Rectangle 275" width="0.79" height="0.79" transform="translate(10.142 8.28)" fill="#0079c1"/>
              <path id="Path_429" data-name="Path 429" d="M352.6,289.8h.6V288H352v.6h.6Z" transform="translate(-344.25 -281.587)" fill="#0079c1"/>
              <path id="Path_430" data-name="Path 430" d="M353.8,192.6h-.6V192h-.6v.6H352v.6h2.4v-.6h.6V192h-1.2Z" transform="translate(-343.94 -187.773)" fill="#0079c1"/>
              <rect id="Rectangle_276" data-name="Rectangle 276" width="0.79" height="0.79" transform="translate(10.142 6.44)" fill="#0079c1"/>
            </g>
          </g>
          <path id="Path_447" data-name="Path 447" d="M24.434,29.814V3.972A1.973,1.973,0,0,0,22.461,2H9.972A1.973,1.973,0,0,0,8,3.973V29.815a1.973,1.973,0,0,0,1.973,1.972H22.462a1.973,1.973,0,0,0,1.972-1.973ZM9.027,3.972a.947.947,0,0,1,.946-.945H22.462a.947.947,0,0,1,.946.945v.082H9.027Zm0,1.109h14.38v22.6H9.027ZM18.271,30.76V29.733H14.163V30.76H9.973a.947.947,0,0,1-.946-.945V28.706h14.38v1.109a.947.947,0,0,1-.946.945Z" transform="translate(-10.746 -10)" fill="#0079c1"/>
        </g>
      </svg>
    )
  }

  /*
  static clockwise() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1"  x="0px" y="0px"
      	 viewBox="0 0 7.7 29.6" style="enable-background:new 0 0 7.7 29.6;" xml:space="preserve">
        <g>
        	<g id="Layer_1-2">
        		<path fill="#4C4C4C" d="M5.2,23.3L2.1,22c1.5-2.8,2.2-5.9,2.1-9C4.2,8.9,2.7,4.8,0.1,1.6L3.1,0c2.9,3.7,4.4,8.3,4.5,13
        			C7.7,16.6,6.8,20.1,5.2,23.3z"/>
        		<polygon fill="#4C4C4C" points="0,20.7 7.7,23.8 0.9,29.6 		"/>
        	</g>
        </g>
      </svg>
    );
  };
  */

  static close() {
    return (
      <span className="i-icon i-icon-close esri-icon esri-icon-close"></span>
    );
    // return (
    //   <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="svg-icon i-icon-close"><path d="M18.404 16l9.9 9.9-2.404 2.404-9.9-9.9-9.9 9.9L3.696 25.9l9.9-9.9-9.9-9.898L6.1 3.698l9.9 9.899 9.9-9.9 2.404 2.406-9.9 9.898z"/></svg>
    // );
  }

  // static close(){
  //   return (
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8.718 8l5.303 5.303-.707.707L8.01 8.707 2.707 14.01 2 13.303 7.303 8 2 2.697l.707-.707L8.01 7.293l5.304-5.303.707.707z"/></svg>
  //   )
  // }

  static colorContrast(){
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M19.634 28a3.634 3.634 0 1 1-7.268 0 3.634 3.634 0 0 1 7.268 0zm.001-24a3.635 3.635 0 1 1-7.27 0 3.635 3.635 0 0 1 7.27 0zm0 12a3.635 3.635 0 1 1-7.27 0 3.635 3.635 0 0 1 7.27 0z"/></svg>
    )
  }

  static descendingSort(){
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4 5h11v1H4zM1 2h14V1H1zm6 8h8V9H7zm3 4h5v-1h-5z"/></svg>
    );
  }

  static desk() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M0 3v1h1v9h1V6h6v6h6v1h1V4h1V3zm14 8h-2v-1h-1v1H9V9h5zm-2-3V7h-1v1H9V6h5v2zm2-3H2V4h12z"/><path fill="none" d="M0 0h16v16H0z"/></svg>
    )
  }

  static directions() {
    return (
      <svg className="svg-icon i-icon-directions" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M28 12l-8-8v6h-8c-3.3 0-6 2.7-6 6v12h4V16c0-1.084.916-2 2-2h8v6l8-8z"/></svg>
    );
  };

  static directions2() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.517 10.931L13.069.485a1.51 1.51 0 0 0-2.137-.002L.483 10.931a1.512 1.512 0 0 0 0 2.137l10.45 10.449a1.51 1.51 0 0 0 2.135 0l10.449-10.449a1.512 1.512 0 0 0 0-2.137zm-.739 1.4L12.33 22.78a.466.466 0 0 1-.66 0L1.22 12.33a.469.469 0 0 1 0-.66L11.67 1.22a.465.465 0 0 1 .658 0l10.451 10.448a.477.477 0 0 1 .137.33.483.483 0 0 1-.138.332zm-8.424-4.685l2.853 2.854-2.854 2.854-.707-.707L15.293 11h-2.58A2.717 2.717 0 0 0 10 13.714V16H9v-2.286A3.718 3.718 0 0 1 12.714 10h2.579l-1.647-1.646z"/></svg>
    );
  };

  static dropdown() {
    return (
      <svg className="svg-icon i-icon-dropdown" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M30 10L16 24 2 10h28z"/></svg>
    );
  };

  static doubleVertical() {
    return (
      <svg className="svg-icon i-icon-dropdown" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M25.035 20L16 29.1 6.965 20zm0-8L16 2.9 6.965 12z"/><path fill="none" d="M0 0h32v32H0z"/></svg>
    )
  }

  static edit() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="svg-icon"><path d="M27.443 9.439l-4.955-4.953 1.652-1.65a2.337 2.337 0 0 1 3.301 0l1.648 1.65a2.33 2.33 0 0 1 .004 3.299l-1.65 1.654zM4.924 22.195l-2.373 7.254 7.328-2.301-4.955-4.953zM20.455 6.713L7.379 19.555l4.951 4.949 13.074-12.842-4.949-4.949z"/></svg>
    );
  };

  static ellipse(){
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g><g><path d="M6,12a6,6,0,1,1,6-6A6,6,0,0,1,6,12Zm0-2a4,4,0,1,1,4-4A4,4,0,0,1,6,10Z"/></g></g></svg>
    );
  }

  static ellipse2(){
    return(
      <svg className="svg-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g><g><path d="M6,12a6,6,0,1,1,6-6A6,6,0,0,1,6,12Zm0-2a4,4,0,1,1,4-4A4,4,0,0,1,6,10Z"/></g></g></svg>
    );
  }

  static exclamationCircle() {
    return(
      <svg className="svg-icon"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M15.5 1.001A14.499 14.499 0 1 0 30 15.5 14.5 14.5 0 0 0 15.5 1.001zM14 7h3v12h-3zm3.033 16.283a.761.761 0 0 1-.761.76H14.75a.761.761 0 0 1-.76-.76V21.76a.761.761 0 0 1 .76-.761h1.522a.761.761 0 0 1 .76.76z"/></svg>
    );
  }

  static exclamationTriangle() {
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M29.733 27.06L17.734 3.191a2 2 0 0 0-3.463 0L2.268 27.061a2.003 2.003 0 0 0 0 2.001 1.941 1.941 0 0 0 1.73.938H28a1.944 1.944 0 0 0 1.731-.938 2.008 2.008 0 0 0 .002-2.002zM17 25h-2v-2h2zm0-4h-2V11h2z"/></svg>
    );
  }

  static exclamationTriangle16() {
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16"><path d="M29.733 27.06L17.734 3.191a2 2 0 0 0-3.463 0L2.268 27.061a2.003 2.003 0 0 0 0 2.001 1.941 1.941 0 0 0 1.73.938H28a1.944 1.944 0 0 0 1.731-.938 2.008 2.008 0 0 0 .002-2.002zM17 25h-2v-2h2zm0-4h-2V11h2z"/></svg>
    );
  }

  static favorites() {
    return (
      <svg className="svg-icon i-icon-favorites" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16.043.367L19.813 12H32l-9.859 7.172 3.789 11.625-9.887-7.193-9.889 7.193 3.787-11.625L0 12h12.271z"/></svg>
    );
  };

  static handleHorizontal() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="svg-icon"><path d="M31.635 16a3.635 3.635 0 1 1-7.27 0 3.635 3.635 0 0 1 7.27 0zM7.634 16a3.634 3.634 0 1 1-7.268 0 3.634 3.634 0 0 1 7.268 0zm12 0a3.634 3.634 0 1 1-7.268 0 3.634 3.634 0 0 1 7.268 0z"/></svg>
    );
  };

  static home() {
    return (
      <svg className="svg-icon i-icon-home" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M30 15.957V32H20V20h-8v12H2V16H0l6-6V0h6v4l4-4 16 15.957h-2z"/></svg>
    );
  };

  static homeNoFill() {
    const icon = Icons.iconBasePath + "home-24";
    return (
      <svg className="svg-icon"><use href={icon}></use></svg>
    );
  };

  static infoNoFill() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8.5 6.5a1 1 0 1 1 1-1 1.002 1.002 0 0 1-1 1zM8 13h1V8H8zm2-1H7v1h3zm5.8-3.5a7.3 7.3 0 1 1-7.3-7.3 7.3 7.3 0 0 1 7.3 7.3zm-1 0a6.3 6.3 0 1 0-6.3 6.3 6.307 6.307 0 0 0 6.3-6.3z"/><path fill="none" d="M0 0h16v16H0z"/></svg>
    )
  }

  static info() {
    const icon = Icons.iconBasePath + "information-24-f";
    return (
      <svg className="svg-icon i-icon-info"><use href={icon}></use></svg>
    );
  };

  static information() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M15.5 1.001A14.499 14.499 0 1 0 30 15.5 14.5 14.5 0 0 0 15.5 1.001zM14 8.75a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1-.75-.75zM19 23h-6v-1h1l1-1v-6l-1-1v-1h3v8l1 1h1z"/></svg>
    );
  };

  static key(){
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M22.088 0a9.898 9.898 0 0 0-9.018 14.008L.225 26.854l-.225.5V32h4.71l.573-.102L8 29.181V28h1.18L12 25.18V24h1.18l4.986-4.986A9.912 9.912 0 1 0 22.088 0zM2.53 30H2v-.53l13-13.001.53.531zM24 10.1A2.1 2.1 0 1 1 26.1 8a2.102 2.102 0 0 1-2.1 2.1z"/></svg>
    );
  }

  static label() {
    return(
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M29.707 12.293l-11-11a1 1 0 0 0-1.414 0l-14 14A1 1 0 0 0 3 16v7a1 1 0 0 0 .293.707l3.023 3.023c-.367.968-.812 1.52-1.275 1.52-.759 0-1.375-.938-1.549-1.299l-.903.43c.036.076.903 1.869 2.452 1.869.837 0 1.517-.596 2.041-1.754l.211.211A1 1 0 0 0 8 28h7a1 1 0 0 0 .707-.293l14-14a1 1 0 0 0 0-1.414zM18.363 6.788l.85.849-8.476 8.474-.848-.847zM9 24a1.979 1.979 0 0 1-.66-.12 4.096 4.096 0 0 1 .903-.968l-.566-.824a5.01 5.01 0 0 0-1.168 1.232A1.992 1.992 0 1 1 9 24zm3.39-6.188l8.474-8.476.85.85-8.476 8.476zm3.348 3.398l-.849-.849 8.475-8.474.848.85z"/></svg>
    );
  }

  static launch() {
    const icon = Icons.iconBasePath + "launch-24";
    return (
      <svg className="svg-icon"><use href={icon}></use></svg>
    );
  };

  static left() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M25 28h-5L8 16 20 4h5L13 16l12 12z"/></svg>
    );
  }

  static levelFilterDown() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" ><path d="M30 10L16 24 2 10h28z"/></svg>
    );
  };

  static levelFilterExit() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 .15A7.85 7.85 0 1 0 15.85 8 7.85 7.85 0 0 0 8 .15zm4.95 11.386l-1.414 1.414L8 9.414 4.464 12.95 3.05 11.536 6.586 8 3.05 4.464 4.464 3.05 8 6.586l3.536-3.536 1.414 1.414L9.414 8z"/></svg>
    );
  };

  static levelFilterInfo() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M31.297 16.047c0 8.428-6.826 15.25-15.25 15.25S.797 24.475.797 16.047c0-8.424 6.826-15.25 15.25-15.25s15.25 6.826 15.25 15.25zM18 24V12h-4v12h-2v2h8v-2h-2zm0-18h-4v4h4V6z"/></svg>
    );
  };

  static levelFilterUp() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M2 24l14-14 14 14H2z"/></svg>
    );
  };

  static location() {
    return (
      <svg className="svg-icon i-icon-location" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M15.999 0C11.214 0 8 1.805 8 6.5v17l7.999 8.5L24 23.5v-17C24 1.805 20.786 0 15.999 0zM16 14.402A4.4 4.4 0 0 1 11.601 10a4.4 4.4 0 1 1 8.798 0A4.4 4.4 0 0 1 16 14.402z"/></svg>
    );
  };

  static minus(){
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="svg-icon"><path d="M30 18H2v-4h28v4z"/></svg>
    );
  };

  static noRecurrence() {
    return (
      <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <path className="cls-1" style={{fill:"#6a6a6a"}} d="m2.7,14l-.7-.7L13.3,2l.7.7L2.7,14Zm0-8.1c-.4.4-.6,1-.7,1.6,0,.6.1,1.2.4,1.7l.1.1-.7.7q-.1-.1-.2-.2c-.4-.7-.7-1.6-.6-2.4.1-.8.4-1.6,1-2.2.4-.4.8-.7,1.3-.9.5-.2,1-.3,1.6-.3h2.2l-1.6-1.6.7-.7,2.8,2.8-2.8,2.8-.7-.7,1.6-1.6h-2.2c-.4,0-.8.1-1.2.2-.4.2-.7.4-1,.7h0Zm11.7.3c-.1-.1-.1-.2-.2-.2l-.8.7.1.1c.3.5.5,1.1.4,1.7s-.3,1.2-.7,1.6c-.3.3-.6.5-1,.6-.2.2-.6.3-1,.3h-2.3l1.6-1.6-.7-.7-2.8,2.8,2.8,2.8.7-.7-1.6-1.6h2.3c.5,0,1.1-.1,1.5-.3.5-.2.9-.5,1.3-.9.6-.6.9-1.4,1-2.2s-.1-1.7-.6-2.4h0Z"/>
        </g>
      </svg>
    )
  }
  
  static noticeRound() {
    return (
      <svg className="svg-icon i-icon-notice-round" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M14 9.2A1.2 1.2 0 0 1 15.2 8h1.6A1.2 1.2 0 0 1 18 9.2v7.6a1.2 1.2 0 0 1-1.2 1.2h-1.6a1.2 1.2 0 0 1-1.2-1.2V9.2zm4 11.999A1.2 1.2 0 0 0 16.801 20h-1.602A1.2 1.2 0 0 0 14 21.199v1.602A1.2 1.2 0 0 0 15.199 24h1.602A1.2 1.2 0 0 0 18 22.801v-1.602zM31.6 16c0 8.615-6.982 15.6-15.6 15.6C7.385 31.6.4 24.616.4 16S7.384.4 16 .4C24.617.4 31.6 7.384 31.6 16zm-2.401 0c0-7.279-5.92-13.201-13.199-13.201S2.801 8.721 2.801 16 8.721 29.199 16 29.199 29.199 23.279 29.199 16z"/></svg>
    );
  };

  static noticeTriangle() {
    return (
      <svg className="svg-icon i-icon-notice-triangle" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M28.764 30a2 2 0 0 0 1.789-2.894L17.791 1.579a2 2 0 0 0-3.578 0L1.447 27.106a1.997 1.997 0 0 0 .088 1.945c.363.59 1.008.949 1.701.949h25.527zM3.236 28L16.002 2.473 28.764 28H3.237zM14 11.2a1.2 1.2 0 0 1 1.2-1.2h1.6a1.2 1.2 0 0 1 1.2 1.2v7.6a1.2 1.2 0 0 1-1.2 1.2h-1.6a1.2 1.2 0 0 1-1.2-1.2v-7.6zm4 13.601A1.2 1.2 0 0 1 16.801 26h-1.602A1.2 1.2 0 0 1 14 24.801v-1.602A1.2 1.2 0 0 1 15.199 22h1.602A1.2 1.2 0 0 1 18 23.199v1.602z"/></svg>
    );
  };

  static panelCollapse() {
    return (
      <span className="i-icon-collapse esri-icon esri-icon-left"></span>
    );
  }

  static panelExpand() {
    return (
      <span className="i-icon-expand esri-icon esri-icon-right"></span>
    );
  }

  static personPin() {
    return (
      <svg className="svg-icon i-icon-person-pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1a8 8 0 0 0-6.745 12.303l6.324 9.895a.5.5 0 0 0 .842 0l6.324-9.895A8 8 0 0 0 12 1zm0 3.7A2.3 2.3 0 1 1 9.7 7 2.302 2.302 0 0 1 12 4.7zm4 9.3H8v-1.167A2.836 2.836 0 0 1 10.833 10h2.334A2.836 2.836 0 0 1 16 12.833z"/></svg>
    );
  }

  static pin() {
    return (
      <svg className="svg-icon i-icon-pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1C8.687 1 6 2.283 6 5.619v11.93l6 6.04 6-6.04V5.619C18 2.283 15.313 1 12 1zm0 10a3 3 0 1 1 3-3 3 3 0 0 1-3 3z"/></svg>
    );
  }

  static commentPin() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 511.999 511.999" style={{ "enableBackground": "new 0 0 511.999 511.999;" }}>
<path style="fill:#EE3840;" d="M454.848,198.848c0,159.225-179.751,306.689-179.751,306.689c-10.503,8.617-27.692,8.617-38.195,0
	c0,0-179.751-147.464-179.751-306.689C57.153,89.027,146.18,0,256,0S454.848,89.027,454.848,198.848z"/>
<path style="fill:#FFE1D6;" d="M256,298.89c-55.164,0-100.041-44.879-100.041-100.041S200.838,98.806,256,98.806
	s100.041,44.879,100.041,100.041S311.164,298.89,256,298.89z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
    );
  }

  static recenter(){
    return(
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13.708 7A5.796 5.796 0 0 0 9 2.292V0H7v2.292A5.796 5.796 0 0 0 2.292 7H0v2h2.292A5.796 5.796 0 0 0 7 13.708V16h2v-2.292A5.796 5.796 0 0 0 13.708 9H16V7zM8 12.1A4.1 4.1 0 1 1 12.1 8 4.104 4.104 0 0 1 8 12.1zm0-1.3A2.8 2.8 0 1 1 10.8 8 2.802 2.802 0 0 1 8 10.8z"/></svg>
    )
  }

  static reload(){
    return(
      <svg className="svg-icon i-icon-reload i-icon-24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M32 16.001a13.008 13.008 0 0 1-17.837 12.07l1.094-2.797a10.035 10.035 0 1 0-5.936-6.78l4.114-4.114 2.121 2.121-7.778 7.778L0 16.501l2.121-2.121 4.147 4.147A12.99 12.99 0 1 1 32 16z"/></svg>
    )
  }

  static right() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M7 4h5l12 12-12 12H7l12-12L7 4z"/></svg>
    );
  }

  static schedule() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M25 5v1h4v4H3V6h4V5H2v22h28V5zm4 21H3V11h26zM10 6V5h12v1zm13-3h1v4h-1zM8 3h1v4H8z"/><path fill="none" d="M0 0h32v32H0z"/></svg>
    );
  }

  static search() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M31.607 27.838l-6.133-6.137a1.336 1.336 0 0 0-1.887 0l-.035.035-2.533-2.533-.014.014c3.652-4.556 3.422-11.195-.803-15.42-4.529-4.527-11.875-4.531-16.404 0-4.531 4.531-4.529 11.875 0 16.406 4.205 4.204 10.811 4.455 15.365.848l.004.003-.033.033 2.541 2.54a1.33 1.33 0 0 0 .025 1.848l6.135 6.133a1.33 1.33 0 0 0 1.887 0l1.885-1.883a1.332 1.332 0 0 0 0-1.887zM17.811 17.809a8.213 8.213 0 0 1-11.619 0 8.217 8.217 0 0 1 0-11.622 8.219 8.219 0 0 1 11.619.004 8.216 8.216 0 0 1 0 11.618z"/></svg>
    );
  }

  static share() {
    const icon = Icons.iconBasePath16 + "share-16-f";
    return (
      <svg className="svg-icon i-icon-share"><use href={icon}></use></svg>
    );
  };

  static signIn() {
    return(
      <svg className="svg-icon i-icon-favorites" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6 15v-4h1v3h7V2H7v4H6V1h9v14zm4.27-6l-1.623 1.623.707.707 2.808-2.809-2.808-2.81-.707.708L10.227 8H2.063v1z"/><path fill="none" d="M0 0h16v16H0z"/></svg>
    )
  }

  static signOut() {
    return (
      <svg className="svg-icon i-icon-favorites" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6 15v-4h1v3h7V2H7v4H6V1h9v14zM2.73 9H11V8H2.773l1.58-1.581-.706-.707-2.81 2.81 2.81 2.808.706-.707z"/><path fill="none" d="M0 0h16v16H0z"/></svg>
    )
  }

  static timePeriod() {
    return (
      <svg className="svg-icon i-icon-timeperiod" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16.047.447c-8.615 0-15.6 6.982-15.6 15.6 0 8.615 6.984 15.599 15.6 15.599 8.617 0 15.6-6.984 15.6-15.599 0-8.617-6.982-15.6-15.6-15.6zm0 28.799c-7.279 0-13.199-5.92-13.199-13.199s5.92-13.2 13.199-13.2 13.199 5.921 13.199 13.2-5.92 13.199-13.199 13.199zM18 14V6h-4v12h12v-4h-8z"/></svg>
    );
  };

  static trash() {
    return (
      <svg className="svg-icon i-icon-favorites" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M12.854 4h1.003l-.79 11.071a1.004 1.004 0 0 1-.999.929H3.932a1.004 1.004 0 0 1-.998-.93L2.143 4h1.003l.786 11h8.136zm-9.78-1H1V2h4V1a1.001 1.001 0 0 1 1-1h4a1.001 1.001 0 0 1 1 1v1h4v1H3.074zM6 2h4V1H6zm3.5 11a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5zm-3 0a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5z"/><path fill="none" d="M0 0h16v16H0z"/></svg>
    )
  }

  static up() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className="svg-icon"><path d="M4 23v-5L16 6l12 12v5L16 11 4 23z"/></svg>
    );
  };

  static urban() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-icon"><path d="M18 8h5v15h-3v-1h2V9h-3v6h-1zm1 8v7h-8v-4h3v-3zm-1 1h-3v3h-3v2h6zM11 6h-1v17H1V11h2V8h4v7h2V5h1V1h3.32L15 3.578V11h1v4h-1v-3h-3v-1h2V3.875L12.777 2H11zM9 16H4v-1h2V9H4v3H2v10h7zm-3 2h2v-1H6zm0 2h2v-1H6z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
    )
  }

  static user() {
    return (
      <svg  className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16.005 15.871a5.872 5.872 0 0 0 0-11.742 5.87 5.87 0 1 0 0 11.742zm11.567 7.188C27.27 19.036 20.023 18 16 18c-4.012 0-11.271 1.039-11.573 5.059C4.203 26.11 4.068 28.18 4.02 30h23.96c-.047-1.82-.184-3.891-.407-6.941z"/></svg>
    );
  };

  static X() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M18.404 16l9.9 9.9-2.404 2.404-9.9-9.9-9.9 9.9L3.696 25.9l9.9-9.9-9.9-9.898L6.1 3.698l9.9 9.899 9.9-9.9 2.404 2.406-9.9 9.898z"/></svg>
    );
  };

  static zoomToObject() {
    return (
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 11.175A4.825 4.825 0 1 0 20.825 16 4.825 4.825 0 0 0 16 11.175zM2 22v2h4.586L.015 30.572l1.414 1.414L8 25.414V30h2v-8zm28 2v-2h-8v8h2v-4.586l6.57 6.572 1.414-1.414L25.414 24zm0-14V8h-4.586l6.571-6.57L30.571.016 24 6.586V2h-2v8zM8 6.586L1.43.016.016 1.43 6.586 8H2v2h8V2H8z"/></svg>
    );
  };

  static zoomToObject24() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-icon"><path d="M7 2h1v6H2V7h4.293L.646 1.354l.708-.708L7 6.293zm15 15v-1h-6v6h1v-4.293l5.646 5.647.708-.708L17.707 17zm-7-5a3 3 0 1 1-3-3 3 3 0 0 1 3 3zm-1 0a2 2 0 1 0-2 2 2.002 2.002 0 0 0 2-2zM2 17h4.293L.646 22.646l.708.708L7 17.707V22h1v-6H2zM17 6.293V2h-1v6h6V7h-4.293l5.647-5.646-.708-.708z"/><path fill="none" d="M0 0h24v24H0z"/></svg>    )
  }

  static userBackground() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_856" data-name="Group 856" transform="translate(-20 -92)">
          <circle id="kisspng-microsoft-office-365-information-technology-micros-remote-desktop-5b1fc695202e14.5319398015288091091318" cx="13" cy="13" r="13" transform="translate(20 92)" fill="#d9d9d9"/>
          <g id="user-24" transform="translate(26.045 97.045)">
            <path id="Path_454" data-name="Path 454" d="M8.7,1.2a2.821,2.821,0,1,0,2.821,2.821A2.821,2.821,0,0,0,8.7,1.2Zm0,5.054a2.233,2.233,0,1,1,2.233-2.233A2.233,2.233,0,0,1,8.7,6.254Zm4.7,7.169H4V10.779A3.236,3.236,0,0,1,7.232,7.547H10.17A3.236,3.236,0,0,1,13.4,10.779Zm-8.815-.588h8.227V10.779A2.647,2.647,0,0,0,10.17,8.134H7.232a2.647,2.647,0,0,0-2.644,2.644Z" transform="translate(-1.649 -0.495)" fill="#4c4c4c"/>
            <path id="Path_455" data-name="Path 455" d="M0,0H14.1V14.1H0Z" fill="none"/>
          </g>
        </g>
      </svg>
    )
  }

  static office365() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_857" data-name="Group 857" transform="translate(-20 -92)">
          <circle id="kisspng-microsoft-office-365-information-technology-micros-remote-desktop-5b1fc695202e14.5319398015288091091318" cx="13" cy="13" r="13" transform="translate(20 92)" fill="#ea3e23"/>
          <path id="Path_456" data-name="Path 456" d="M12.87,14.157h0V1.326L8.276,0,.021,3.089H0v9.267l2.831-1.1V3.73L8.288,2.425V13.511L0,12.371l8.276,3.055h0l4.595-1.269Z" transform="translate(26.056 96.869)" fill="#fff" fillRule="evenodd"/>
        </g>
      </svg>
    )
  }
}
