import React from "react";

import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as mapUtil from "../../../util/mapUtil";

const CSS = {
  button: "i-locate esri-widget--button"
};

export default class Locate extends React.Component {

  _promise;

  constructor(props) {
    super(props);
    this.state = component.newState({
      enabled: true,
      isWorking: false,
    });
    this.clicked = this.clicked.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  clicked(event) {
    this.setWorking(true);
    let bluedot = Context.instance.bluedot;
    let promise = this._promise = bluedot.generate();

    let goToFeature = (task) => {
      let view = this.props.view;
      if (task && view) {
        let graphic = view.type === "3d" ? task._3D.graphic: task._2D.graphic;
        if (graphic && promise === this._promise &&
            view === Context.instance.views.activeView) {
          bluedot.activateLevel(task);
          mapUtil.goToFeature(this.props.view,graphic);
        }
      }
    };

    promise.then(task => {
      bluedot.setFollowingPartially(true);
      goToFeature(task);
      if (promise === this._promise) this.setWorking(false);
    }).catch(ex => {
      console.error("Locate: Error getting current location",ex);
      if (ex && ex.code === 1 && ex.code === ex.PERMISSION_DENIED ) {
        // PositionError
        this.setState({enabled: false});
      } else if (ex && ex.code === 2 && ex.code === ex.POSITION_UNAVAILABLE) {
      } else if (ex && ex.code === 3 && ex.code === ex.TIMEOUT) {
      }

      if (this.props.view === Context.instance.views.activeView) {
        let task = bluedot.getLastTask();
        if (task) {
          bluedot.setFollowingPartially(true);
          goToFeature(task);
        } else {
          //Topic.publishErrorAccessingData();
          const i18n = Context.instance.i18n;
          Topic.publish(Topic.ShowToast,{
            message: i18n.infoPanel.realTime.noLocation,
            type: "danger"
          });
        }
      }
      if (promise === this._promise) this.setWorking(false);
    })
  }

  onKeyUp(event) {
    if (event && event.keyCode === 13) this.clicked(event);
  }

  render() {
    if (!this.props.view) return null;
    const disabled = !!this.state.isWorking || !this.state.enabled;
    const tip = Context.instance.i18n.map.locate.currentLocation;

    return (
      <button className={CSS.button} type="button" tabIndex="0" disabled={disabled}
        title={tip} onClick={this.clicked} onKeyUp={this.onKeyUp}>
        <span className={"esri-icon esri-icon-locate"}></span>
      </button>
    );
  }

  setWorking(isWorking) {
    this.setState({isWorking: isWorking});
  }

}
