import React from "react";

import Button from "../../../common/components/Button/Button";
import Checkbox from "calcite-react/Checkbox";
import Context from "../../../context/Context";
import {
  ModalController,
  WorkingController,
} from "../../../common/components/Modal";
import Reader from "../../base/Reader";
import * as component from "../../../components/util/component";

import { CalciteP } from "calcite-react/Elements";
import Form, {FormControl,Fieldset,Legend} from "calcite-react/Form";

import {
  ContentWrapper,
  HeaderContainer,
  Header,
  ContentContainer
} from "../../styles/backstageStyles";

export default class ExportPlan extends React.Component {

  constructor(props){
    super(props);
    this.state = component.newState({
      showExport: true,
      showDownload: false,
      unitAssignments: true,
      workspaceAreaAssignments: true,
      homeAssignments: true,
      noAssignment: true,
      downloadDataURL: null,
      downloadFilename: "People.csv"
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  cancelDownload = () => {
    this.setState({
      showExport: true,
      showDownload: false
    });
  }

  exportClicked = () => {
    if (this.state.isWorking) return;
    this.setState({ isWorking: true });
    let {
      unitAssignments,
      workspaceAreaAssignments,
      homeAssignments,
      noAssignment
    } = this.state;
    if (!unitAssignments && !workspaceAreaAssignments && !noAssignment && !homeAssignments) {
      unitAssignments = workspaceAreaAssignments = noAssignment = homeAssignments = true;
    }
    let options = {
      unitAssignments,
      workspaceAreaAssignments,
      homeAssignments,
      noAssignment
    };

    const i18n = Context.instance.i18n;
    const workingController = new WorkingController();
    const workingContent = i18n.spaceplanner.backstage.exportPlan.exportingPlan;
    workingController.show({}, workingContent);

    const reader = new Reader();
    reader.readFeatures(options).then(task => {
      workingController.close();
      if (task && task.csvInfo && task.csvInfo.href) {
        this.setState({
          showExport: false,
          showDownload: true,
          downloadDataURL: task.csvInfo.href,
          downloadFilename: this.generateFileName(),
          isWorking: false
        });
      } else {
        if (task && task.noMatch) {
          ModalController.alert({
            title: i18n.spaceplanner.backstage.exportPlan.noMatchCaption,
            message: i18n.spaceplanner.backstage.exportPlan.noMatch
          });
        }
        this.setState({ isWorking: false });
      }
    }).catch(ex => {
      let submessage = ex.submessage || ex.message;
      workingController.close();
      ModalController.alert({
        isError: true,
        title: i18n.spaceplanner.backstage.exportPlan.errorExportingTitle,
        message: i18n.spaceplanner.backstage.exportPlan.errorExportingMessage,
        submessage: submessage
      });
      this.setState({ isWorking: false });
      console.error("Error exporting plan", ex);
    });

  }

  generateFileName=()=>{
    const timestamp = new Date();
    const people = "People";
    const csv = ".csv";
    const lib = Context.instance.lib;
    const date = lib.dojo.locale.format(timestamp, {datePattern:"yyyyMMdd", formatLength:"short", selector: "date"});
    const time = lib.dojo.locale.format(timestamp, {timePattern:"HHmmss", formatLength:"short", selector: "time"});
    const getDateTime = date.concat("_").concat(time);
    const newFilename = people.concat("_").concat(getDateTime).concat(csv);
    return newFilename;
  }

  handleChange = (event)=> {
    const name = event.target.name;
    if (name === "unitAssignments") {
      this.setState({
        unitAssignments: !this.state.unitAssignments
      })
    } else if(name === "workspaceAreaAssignments") {
      this.setState({
        workspaceAreaAssignments: !this.state.workspaceAreaAssignments
      })
    } else if (name === "homeAssignments") {
      this.setState({
        homeAssignments: !this.state.homeAssignments
      });
    } else if (name === "noAssignment") {
      this.setState({
        noAssignment: !this.state.noAssignment
      });
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    return (
      <ContentWrapper>
        <HeaderContainer>
          <Header>{i18n.spaceplanner.backstage.exportPlan.caption}</Header>
        </HeaderContainer>
        <ContentContainer>
          {this.renderContent()}
        </ContentContainer>
      </ContentWrapper>
    )
  }

  renderContent() {
    const i18n = Context.instance.i18n;
    const disableButton = this.hideExportButton();
    if (this.state.showExport) {
      return (
        <div>
          <Form>
            <FormControl>
              <Fieldset name="options">
                <Legend>{i18n.spaceplanner.backstage.exportPlan.prompt}</Legend>
                <Checkbox
                  name="unitAssignments"
                  checked={this.state.unitAssignments}
                  onChange={this.handleChange}
                >
                  {i18n.spaceplanner.backstage.exportPlan.unitAssignments}
                </Checkbox>
                <Checkbox
                  name="workspaceAreaAssignments"
                  checked={this.state.workspaceAreaAssignments}
                  onChange={this.handleChange}
                >
                  {i18n.spaceplanner.backstage.exportPlan.workspaceAreaAssignments}
                </Checkbox>
                <Checkbox
                  name="homeAssignments"
                  checked={this.state.homeAssignments}
                  onChange={this.handleChange}
                >
                  {i18n.spaceplanner.backstage.exportPlan.homeAssignments}
                </Checkbox>
                <Checkbox
                  name="noAssignment"
                  checked={this.state.noAssignment}
                  onChange={this.handleChange}
                >
                  {i18n.spaceplanner.backstage.exportPlan.noAssignment}
                </Checkbox>
              </Fieldset>
            </FormControl>
          </Form>
          <Button
            disabled={disableButton || this.state.isWorking}
            onClick={this.exportClicked}>
            {i18n.spaceplanner.backstage.exportPlan.label}
          </Button>
        </div>
      );

    } else if (this.state.showDownload) {
      return (
        <div>
          <CalciteP>
            {i18n.spaceplanner.backstage.exportPlan.downloadPrompt}
          </CalciteP>
          <Button
            href={this.state.downloadDataURL}
            download={this.state.downloadFilename}>
            {i18n.spaceplanner.backstage.exportPlan.downloadLabel}
          </Button>
          <Button clear style={{marginLeft: "10px", marginRight: "10px"}}
            onClick={this.cancelDownload}>
            {i18n.general.cancel}
          </Button>
        </div>
      );
    }

    return null;
  }

  hideExportButton(){
    if (!(
      this.state.unitAssignments ||
      this.state.workspaceAreaAssignments ||
      this.state.noAssignment ||
      this.state.homeAssignments))
      return true;
    return false;
  }

}
