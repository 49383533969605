import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Context from "../../../../context/Context";
import { getActiveHelpTip, setHelpTip, setUndoRedoDisabled } from "../redux";
import { useForceUpdate, useHandles } from "../../../miniapps/common/hooks";
import FootprintVM, { IFootprintVMProps } from "./FootprintVM";
import Attributes, { IAttributesHandle } from "../support/Attributes";
import Savebar, { ISavebar } from "../support/Savebar";
import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteBlock,
  CalciteInputNumber,
  CalciteLabel,
  CalciteNotice,
  CalcitePanel,
  CalciteRadioButton,
  CalciteRadioButtonGroup,
  CalciteTooltip
} from "@esri/calcite-components-react";
import Topic from "../../../../context/Topic";
import { ModalController } from "../../Modal";
import OfficePlan from "../../../base/OfficePlan";
import { getLayer } from "../../../base/mapUtil";
import { getIdField, getCapitalizedType, hasLayer, hasLevels, hasSites } from "../support/formUtil";

interface IFootprintPanelProps {
  fpeType: IFootprintVMProps["fpeType"]
}
interface IFootprintPanelData {
  selectedSiteId: string,
  selectedFacilityId: string,
  selectedLevelId: string
}
const FootprintPanel = ({ fpeType }: IFootprintPanelProps) => {
  const dispatch = useDispatch();
  const ctx = Context.getInstance();
  const { i18n } = ctx;
  const tip = useSelector(getActiveHelpTip);
  const viewModel = useRef<FootprintVM>(new FootprintVM({
    fpeType,
    // fires from changes in sketch geometry, not floor filter
    onSiteChange: (siteInfo) => {
      if (fpeType === "facility") {
        setIsGroupExpanded();
        setData({ ...data, selectedFacilityId: null, selectedLevelId: null });
        setAttributes();
        isSiteChangeFromMap.current = true;
        const { floorFilter } = Context.getInstance().views;
        floorFilter.setLevel(null);
        floorFilter.setFacility(null);
        floorFilter.setSite(siteInfo.id);
      }
    },
    onValidityChange: () => {
      setIsGroupExpanded();
      setCanSave(validate());
      setAttributes();
      dispatch(setUndoRedoDisabled(viewModel.current.activeFeature?.geometry.rings[0]?.length > 3));
    }
  }));
  const attributesRef = useRef<IAttributesHandle>();
  const formRef = useRef<__esri.FeatureForm>();
  const groupExpanded = useRef<boolean>(false);
  const isHideWarningSelfInt = useRef<boolean>(false);
  const saveBarRef = useRef<ISavebar>();
  const canSave = useRef<boolean>(false);
  const isSiteChangeFromMap = useRef<boolean>(false);
  const [addOption, setAddOption] = useState<FootprintVM["addOption"]>();
  const [zOption, setZOption] = useState<{ open: boolean, value: string }>({
    value: viewModel.current.zDefault?.toString(),
    open: viewModel.current.zDefault == null
  });
  const [data, setData] = useState<IFootprintPanelData>({
    selectedSiteId: null,
    selectedFacilityId: null,
    selectedLevelId: null
  });
  const [layerLoaded, setLayerLoaded] = useState<boolean>(hasLayer(fpeType));
  const forceUpdate = useForceUpdate();
  const setHandles = useHandles()[1];

  useEffect(() => {
    init().then(setAttributes).then(() => setCanSave(validate()));
  }, [fpeType, addOption]);

  useEffect(() => {
    const handles = [
      Topic.subscribe(Topic.SiteActivated, () => {
        if (fpeType === "facility" || fpeType === "level") {
          setIsGroupExpanded();
          !isSiteChangeFromMap.current && reset(false);
        }
        isSiteChangeFromMap.current = false;
      }),
      Topic.subscribe(Topic.FacilitySelected, () => {
        if (fpeType === "level") {
          groupExpanded.current = false;
          viewModel.current.addOption = null;
          setAddOption(null);
          reset(false);
        }
      }),
      Topic.subscribe(Topic.LevelSelected2, () => {
        if (fpeType === "level" && !hasLevels()) {
          groupExpanded.current = false;
          viewModel.current.addOption = null;
          setAddOption(null);
        } else {
          setIsGroupExpanded();
          reset(false);
        }
      }),
      Topic.subscribe(Topic.PlanOpened, () => {
        setLayerLoaded(hasLayer(fpeType))
      }),
      Topic.subscribe(Topic.PlanModified, params => {
        try {
          if (params && (params.wasUndoRedo || params.wasReconciled)) {
            reset(false);
          }
        } catch (ex) {
          console.error(ex);
        }
      })
    ];
    setHandles(handles);

    return () => {
      viewModel.current.destroy();
      dispatch(setUndoRedoDisabled(false));
    }
  }, []);

  const closeAlert = () => {
    Topic.publish(Topic.ClearToast, null);
  }
  const getTip = (): string => {
    const { spaceplanner: { issues }, editor: { facilities, levels, sites } } = i18n;
    if (!layerLoaded) {
      return `${fpeType === "site"
        ? issues.missingSitesLayer
        : fpeType === "facility"
          ? issues.missingFacilitiesLayer
          : issues.missingLevelsLayer}`;
    } else if (fpeType === "site") {
      return sites.tip;
    } else if (fpeType === "facility") {
      return hasSites() ? facilities.tipSites : facilities.tipNoSites;
    } else if (addOption == null) {
      return levels.tipNoOption;
    } else {
      return viewModel.current.addOption === "draw"
        ? levels.tipDrawLevel
        : viewModel.current.addOption === "duplicate_facility"
          ? levels.tipDuplicateFacility
          : levels.tipDuplicateLevel;
    }
  }
  const init = async () => {
    Topic.publish(Topic.ClearToast, null);
    const {
      aiim: { datasets },
      views: { floorFilter: { activeWidget: { level: currLvlId, facility: currFacId, site: currSiteId }, activeWidget } }
    } = Context.getInstance();
    setData({ selectedFacilityId: currFacId, selectedLevelId: currLvlId, selectedSiteId: currSiteId });

    if (fpeType === "site" || fpeType === "facility") {
      if (fpeType === "facility") {
        // @ts-ignore
        const site = activeWidget.viewModel.getSite(currSiteId);
        viewModel.current.site = site;
      }
      await viewModel.current.activate();
    } else {
      const levels = datasets.levels;
      // @ts-ignore
      const facility = activeWidget.viewModel.getFacility(currFacId);
      viewModel.current.facility = facility;
      const existing: __esri.Geometry = viewModel.current.addOption === "duplicate_facility"
        ? facility?.geometry
        : viewModel.current.addOption === "duplicate_level"
          ? levels.getLevelData(currLvlId)?.feature?.geometry
          : null;
      await viewModel.current.activate(existing);
    }
    // enable undo/redo until user makes an edit
    setIsEditActive();
  }
  const onValueChange = ({ fieldName, value }) => {
    if (viewModel.current.activeFeature) {
      viewModel.current.activeFeature.attributes[fieldName] = value;
    }
    setCanSave(validate());
    setIsEditActive();
  };
  const renderAttributes = () => {
    const layer = getLayer(fpeType);
    const message = i18n.editor.footprints.attrTitle;
    const id = fpeType === "facility" ? viewModel.current.site?.name : viewModel.current.facility?.name;
    const headerText = fpeType !== "site" && id
      ? message.replace("{type}", fpeType).replace("{name}", id)
      : i18n.editor.attributes.title;
    const disabled = fpeType === "level"
      && (data.selectedFacilityId == null || addOption == null)
    return layer && viewModel.current.activeFeature && (
      <Attributes
        footer={false}
        formOptions={{
          layer,
          feature: viewModel.current.activeFeature.clone(),
          formTemplate: viewModel.current.initFormTemplate(groupExpanded.current),
          type: fpeType,
          readOnlyDisplayType: "label"
        }}
        header={headerText}
        idField={getIdField(fpeType)}
        isEditing={true}
        isDisabled={disabled}
        layout={"form"}
        ref={attributesRef}
        sendUpdates={false}
        onValueChange={onValueChange}
        onCreateForm={form => formRef.current = form}
      />
    )
  }
  const renderOptions = () => {
    const setOption = e => {
      viewModel.current.addOption = e.currentTarget.value;
      setIsGroupExpanded();
      setAddOption(e.currentTarget.value);
    }
    const layer = getLayer(fpeType);
    const disabled = data.selectedFacilityId == null || !hasLevels();
    return layer && fpeType === "level" && (
      <div className="i-editor-footprint-options">
        <CalciteRadioButtonGroup name="addOptionsRBG" layout="vertical">
          <CalciteLabel layout="inline">
            <CalciteRadioButton
              checked={addOption === "duplicate_facility" ? true : undefined}
              disabled={data.selectedFacilityId == null || undefined}
              value={"duplicate_facility"}
              onCalciteRadioButtonChange={setOption} />
            {i18n.editor.levels.duplicateFacility}
          </CalciteLabel>
          {disabled &&
            <CalciteTooltip label="no levels" referenceElement="duplicate_level" placement="top-start">
              {i18n.editor.footprints.tipNoLevels}
            </CalciteTooltip>
          }
          <CalciteLabel layout="inline">
            <CalciteRadioButton id="duplicate_level"
              checked={addOption === "duplicate_level" && !disabled ? true : undefined}
              disabled={disabled || undefined}
              value={"duplicate_level"}
              onCalciteRadioButtonChange={setOption} />
            {i18n.editor.levels.duplicateLevel}
          </CalciteLabel>
          <CalciteLabel layout="inline">
            <CalciteRadioButton
              checked={addOption === "draw" ? true : undefined}
              disabled={data.selectedFacilityId == null || undefined}
              value={"draw"}
              onCalciteRadioButtonChange={setOption} />
            {i18n.editor.levels.drawLevel}
          </CalciteLabel>
        </CalciteRadioButtonGroup>
      </div>
    )
  }
  const renderZValue = () => {
    if (fpeType !== "level")
      return;
    const zValue = zOption.value;
    const missingZValue = zValue == null;
    const showInvalid = missingZValue && viewModel.current.addOption != null && data.selectedFacilityId;
    const heading = missingZValue
      ? i18n.editor.footprints.zNoValue
      : i18n.editor.footprints.zHasValue.replace("{value}", zValue);
    const description = missingZValue
      ? i18n.editor.footprints.zDescriptionNoDefault
      : i18n.editor.footprints.zDescriptionDefault;
    return (
      <CalciteBlock
        className="i-editor-footprint-z-value"
        heading={heading}
        description={description}
        status={showInvalid ? "invalid" : missingZValue ? "idle" : "valid"}
        open={zOption.open || undefined}
        collapsible={!missingZValue || undefined}>
        <CalciteInputNumber value={zValue?.toString()} status={showInvalid ? "invalid" : "valid"} required onCalciteInputNumberInput={(e) => {
          const value = (e.currentTarget as HTMLCalciteInputNumberElement).value;
          const zValue = value != null && value.length > 0 && !isNaN(+value) ? +value : null;
          setIsGroupExpanded();
          if (zValue != null) {
            const isValid = attributesRef.current?.isValid() && viewModel.current.isValid() && zValue != null;            
            setCanSave(isValid);
          } else {
            setCanSave(false);
          }
          setZOption({ value, open: true });
        }}/>
      </CalciteBlock>
    )
  }
  const reset = async (isConfirm = true) => {
    let isReset = true;
    if (isConfirm) {
      const confirmResult = await ModalController.confirm({
        title: i18n.editor.footprints.resetTitle.replace("{type}", getCapitalizedType(fpeType)),
        message: i18n.editor.footprints.resetMessage,
        okLabel: i18n.general.reset,
        cancelLabel: i18n.general.cancel,
        showOKCancel: true,
        closeOnOK: true,
        closeOnCancel: true
      });
      isReset = confirmResult.ok;
      if (isReset) {
        viewModel.current.addOption = null;
        setAddOption(null);
      }
    }
    if (isReset) {
      viewModel.current.reset();
      if (attributesRef.current) {
        attributesRef.current.reset();
        attributesRef.current = null;
      }
      await init().then(setAttributes).then(() => setCanSave(validate(), true));
      setZOption({ ...zOption, value: viewModel.current.zDefault?.toString() });
    }
  }
  const save = async () => {
    closeAlert();
    const ae: IAttributesHandle = attributesRef.current;
    if (!(ae && ae.isValid() && viewModel.current.isValid())
      || !viewModel.current.isGeometrySimple() && !(await warnSelfInt())) {
      return;
    }

    Topic.publish(Topic.ClearToast, null);

    const attributes = attributesRef.current.getValues();
    const zValue = +zOption.value;
    viewModel.current.save({ attributes, zValue })
      .then(() => {
        viewModel.current.addOption = null;
        setAddOption(null);
      })
      .then(() => reset(false))
      .then(() => {
        Topic.publish(Topic.ShowToast, {
          open: true,
          type: "success",
          message: i18n.editor.addSuccess.replace("{type}", getCapitalizedType(fpeType)),
          submessage: i18n.editor.footprints.successMessage
            .replaceAll("{type}", fpeType)
            .replaceAll("{nextType}", fpeType === "site" ? "facility" : fpeType === "facility" ? "level" : "unit")
        });
        Topic.publish(Topic.PlanModified, { action: OfficePlan.Action_AssignmentsUpdated });
        const { views: { floorFilter } } = Context.getInstance();
        if (fpeType === "facility") {
          floorFilter.setFacility(attributes[viewModel.current.fldFacilityId.name]);
          floorFilter.activeWidget.viewModel.set("filterMenuType", "facility");
        } else if (fpeType === "site") {
          floorFilter.setFacility(null);
          floorFilter.setSite(attributes[viewModel.current.fldSiteId.name]);
          floorFilter.activeWidget.viewModel.set("filterMenuType", "facility");
        }
      }).catch((error) => {
        const details = error.details;
        const submessage = details && details.messages && details.messages.length
          ? details.messages.join("\n")
          : error.message;
        Topic.publish(Topic.ShowToast, {
          open: true,
          type: "error",
          message: i18n.messages.errorUpdatingData,
          submessage
        })
      });
  }
  const setAttributes = () => {
    if (attributesRef.current) {
      attributesRef.current.setFormValues(viewModel.current.activeFeature.attributes);
    }
  }
  const setCanSave = (enabled: boolean, force?: boolean) => {
    canSave.current = enabled;
    saveBarRef.current && saveBarRef.current.setSaveEnabled(enabled);
    if (force) {
      forceUpdate();
    }
  }
  const setIsEditActive = () => {
    dispatch(setUndoRedoDisabled(viewModel.current.activeFeature?.geometry.rings[0]?.length > 3));
  }
  const setIsGroupExpanded = () => {
    if (formRef.current) {
      const groups = formRef.current.viewModel.inputs.filter(i => i.type === "group") as __esri.GroupInput[];
      const expanded = groups.some(g => g.state === "expanded");
      groupExpanded.current = expanded;
    }
  }
  const validate = () => {
    const canSave = attributesRef.current?.isValid()
      && viewModel.current.isValid()
      && (fpeType !== "level" || zOption.value != null);
    return canSave;
  }
  const warnSelfInt = async (): Promise<boolean> => {
    const i18n = Context.instance.i18n;
    if (isHideWarningSelfInt.current)
      return true;
    const confirmResult = await ModalController.confirm({
      title: i18n.general.warning,
      message: i18n.editor.footprints.shapeWarningMsg,
      okLabel: i18n.general.save,
      cancelLabel: i18n.general.cancel,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    });
    return confirmResult.ok
  }
  const tooltip = getTip();
  const isShowToolTip = !(tip && tip.visible) && tooltip;
  return (
    <div className="i-editor-sidebar-container i-editor-footprint-container">
      <div className="i-editor-sidebar-content">
        <CalcitePanel>
          <CalciteNotice icon="lightbulb" scale="s" width={"full"} closable open={isShowToolTip ? true : undefined}
            onCalciteNoticeClose={() => dispatch(setHelpTip(fpeType === "facility"
              ? "facilities"
              : fpeType === "site"
                ? "sites"
                : "levels", {
              visible: true,
              tooltip
            }))}>
            {!layerLoaded && <div slot="title">{i18n.spaceplanner.issues.requiredLayersMissing}</div>}
            <div slot="message">{tooltip}</div>
          </CalciteNotice>
          {renderOptions()}
          {renderAttributes()}
          {renderZValue()}
        </CalcitePanel>
      </div>
      <div className="i-editor-sidebar-footer">
        <Savebar
          parentPanelKey={`addPanel_${fpeType}`}
          resetDisabled={false}
          saveDisabled={!canSave.current}
          onReset={() => reset()}
          onSave={save}
          ref={saveBarRef}
        />
      </div>
    </div>
  );
}
export default FootprintPanel;