import React from "react";

// App Components
import Context from "../../../context/Context";
import FilterGroup from "./FilterGroup";
import FilterModel from "./FilterModel";
import Topic from "../../../context/Topic";

// Calcite-React
import Popover from "calcite-react/Popover";
import Button from "calcite-react/Button";
import SlidersIcon from "calcite-ui-icons-react/SlidersIcon";
import CloseIcon from "calcite-ui-icons-react/XIcon";
import CheckIcon from "calcite-ui-icons-react/CheckIcon";

import * as queryUtil from "../../base/queryUtil";

// Syled-Components
import {
  FilterContainer,
  FilterHeader,
  FilterTitle,
  FilterFooter,
  FilterButton,
  ApplyButton,
  ClearButton,
  Indicator
} from "../../styles/Filter/filter";

import QueryCriteria from "../../base/QueryCriteria";
import * as component from "../../../components/util/component";

const CSS = {
  filterScroll : "i-scroll-filter"
};


export default class Filter extends React.Component {
  filterModel;
  _mounted = false;

  constructor(props) {
    super(props);
    this.filterModel = new FilterModel({
      queryCriteriaKey: this.props.queryCriteriaKey
    });
    this.state = component.newState({
      open: false,
      filterApplied: this.hasFilter()
    });
  }

  componentDidMount() {
    this._mounted = true
    component.own(this, [

      Topic.subscribe(Topic.PlanModified, (params) => {
        if (this._mounted) {
          if (params.allAssignedToArea) {
            this.clear()
          } else {
            this.queryFeatures().then(count => {
              if (count === 0) {
                this.clear()
              }
            })
          }
        }
      }),

      Topic.subscribe(Topic.QueryCriteriaCleared,params => {
        const key = this.props.queryCriteriaKey;
        if (key && key === params.key) {
          this.filterModel._pairs = undefined;
          this.filterModel._siteFacilityLevel = {};
          params.queryCriteria._localPairs = undefined;
          params.queryCriteria._siteFacilityLevel = this.filterModel._siteFacilityLevel;
          this.setState({
            open: false,
            filterApplied: false
          });
        }
      })

    ])
  }

  queryFeatures() {
    const source = this.getSource();
    if (!source) return
    const queryCriteria = this.getQueryCriteria();
    const orderByFields = queryCriteria.makeOrderByFields();
    const where = queryCriteria.getWhere();
    let options = {
      source,
      orderByFields,
      where,
      queryCriteria,
    };
    return queryUtil.queryCount(options)
  }

  getQueryCriteria() {
    return QueryCriteria.get(this.props.queryCriteriaKey);
  }

  getSource() {
    return this.getQueryCriteria().getSource();
  }

  canFilter() {
    return (this.filterModel._pairs && this.filterModel._pairs.length > 0);
  }

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this);
  }

  apply = () => {
    this.filterModel.apply();
    this.setState({
      open: false,
      filterApplied: this.hasFilter()
    });
  };

  clear = () => {
    this.filterModel.clear();
    this.setState({
      open: false,
      filterApplied: false
    });
    //component.refresh(this);
  };

  hasFilter() {
    const qc = QueryCriteria.get(this.props.queryCriteriaKey);
    return qc && qc.hasFilterPairs();
  }

  onPairChanged = (id, field, value, operator) => {
    let params = {
      id : id,
      field : field,
      value : value,
      operator : operator
    }

    this.filterModel.onPairChanged(params);
    Topic.publish(Topic.FilterValueSelected, {
      queryCriteriaKey: this.props.queryCriteriaKey,
      fieldName: field
    });
  };

  onRequestClose = () => {
    // TODO this is closing the Popover when a value selection is made
    //this.setState({open: false});
  };

  toggleOpen = () => {
    this.filterModel.init();
    this.setState({
      open: !this.state.open,
      filterApplied: this.hasFilter()
    });
  };

  render() {
    const label = this.props.label || Context.instance.i18n.filter.buttonLabel;
    const title = this.props.filterTooltip;
    const { filterApplied } = this.state;
    const panel = this.renderPanel();
    const disabled = !this.canFilter();
    let iconStyle;
    if(Context.getInstance().uiMode.isRtl) iconStyle = {marginLeft: "0.75rem", marginRight: "-0.25rem"};

    return (
      <Popover
        targetEl={
          <>
            <Indicator filterApplied={filterApplied}>
              <CheckIcon />
            </Indicator>
            <FilterButton
              transparent
              title={title}
              onClick={this.toggleOpen}
              icon={<SlidersIcon style={iconStyle}/>}
              iconPosition="before"
              disabled={disabled}
            >
              {label}
            </FilterButton>
          </>
        }
        open={this.state.open}
        placement="top-start"
        onRequestClose={this.onRequestClose}
        closeOnSelect={false}
      >
        {panel}
      </Popover>
    );
  }

  renderGroups() {
    const queryCriteriaKey = this.props.queryCriteriaKey;
    const pairs = this.filterModel.makePairs();
    const groups = [];
    pairs.forEach(pair => {
      groups.push(
        <FilterGroup
          key={pair.id}
          queryCriteriaKey={queryCriteriaKey}
          pair={pair}
          filterModel={this.filterModel}
          onChange={this.onPairChanged}
        />
      );
    });
    return groups;
  }

  renderPanel() {
    const i18n = Context.instance.i18n;
    const pattern = i18n.filter.dialogTitlePattern;
    const title = pattern.replace("{name}", this.props.layerCaption);
    const groups = this.renderGroups();
    return (
      <FilterContainer>
        <FilterHeader>
          <FilterTitle>{title}</FilterTitle>
          <Button className={"i-filter-close-btn"} iconButton icon={<CloseIcon />} onClick={this.toggleOpen} />
        </FilterHeader>
        <div className={CSS.filterScroll}>{groups}</div>
        <FilterFooter>
          <ApplyButton onClick={this.apply}>
            {i18n.filter.applyFilter}
          </ApplyButton>
          <ClearButton onClick={this.clear}>
            {i18n.filter.clearFilter}
          </ClearButton>
        </FilterFooter>
      </FilterContainer>
    );
  }
}
