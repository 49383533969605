import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { IFilterCriteria } from "../../common/types";
import OfficePlan from "../../../base/OfficePlan";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as queryUtil from "../../../base/queryUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as whereUtil from "./whereUtil";

export function getField(layerType,fieldName) {
  const layer = getLayer(layerType);
  return aiimUtil.findField(layer && layer.fields,fieldName);
}

export function getFieldLabel(layerType,fieldName) {
  const field = getField(layerType,fieldName);
  return (field && (field.alias || field.name)) || null;
}

export function getLayer(layerType) {
  if (layerType === "units") {
    return sourceUtil.getUnitsLayer();
  } else if (layerType === "occupants" || layerType === "people") {
    return sourceUtil.getPeopleLayer();
  } else if (layerType && layerType.url) {
    return layerType;
  }
}

export function getLevelsDataset() {
  const datasets = Context.instance.aiim && Context.instance.aiim.datasets;
  return datasets && datasets.levels;
}

export function getFacilities(filterCriteria: IFilterCriteria, levelIdSubset?: any) {
  const uniqueValues = [], idx = {};
  const levelsDataset = getLevelsDataset();
  const facilities = levelsDataset && levelsDataset.getFacilities();
  if (facilities && facilities.length > 0) {
    const sid = filterCriteria && filterCriteria.siteFacilityLevel.siteId;
    facilities.forEach(f => {
      let ok = true;
      if (sid) ok = (sid === f.siteId); 
      if (ok && levelIdSubset) {
        ok = f.levels.some(l => {
          return levelIdSubset[l.levelId];
        })
      }
      const label = f.facilityName, value = f.facilityId;
      if (typeof label === "string" && label.length > 0 && 
          typeof value === "string" && value.length > 0 &&
          !idx[value] && ok) {
        idx[value] = true;
        uniqueValues.push({label,value});
      }
    })
  }
  sortUniqueValues(uniqueValues);
  return uniqueValues;
}

export function getLevels(filterCriteria: IFilterCriteria, levelIdSubset?: any) {
  const uniqueValues = [], idx = {};
  const levelsDataset = getLevelsDataset();
  const facilities = levelsDataset && levelsDataset.getFacilities();
  const sid = filterCriteria && filterCriteria.siteFacilityLevel.siteId;
  const fid = filterCriteria && filterCriteria.siteFacilityLevel.facilityId;
  let levels = [], numFacilities = 0;
  if (facilities && facilities.length > 0) {
    facilities.forEach(f => {
      let ok = true;
      if (sid) ok = (sid === f.siteId); 
      if (fid) ok = (fid === f.facilityId); 
      if (ok) {
        numFacilities++;
        levels = levels.concat(f.levels);
      }
    })
  }
  if (levels && levels.length > 0 && numFacilities === 1) {
    levels.forEach(l => {
      let ok = true;
      if (sid) ok = (sid === l.siteId); 
      if (fid) ok = (fid === l.facilityId); 
      if (ok && levelIdSubset) {
        ok = !!levelIdSubset[l.levelId];
      }
      const label = l.levelName, value = l.levelId;
      if (typeof label === "string" && label.length > 0 && 
          typeof value === "string" && value.length > 0 &&
          !idx[value] && ok) {
        idx[value] = true;
        uniqueValues.push({label,value});
      }
    })
  }
  sortUniqueValues(uniqueValues);
  return uniqueValues;
}

export function getSites(filterCriteria: IFilterCriteria, levelIdSubset?: any) {
  const uniqueValues = [], idx = {};
  const levelsDataset = getLevelsDataset();
  const facilities = levelsDataset && levelsDataset.getFacilities();
  if (facilities && facilities.length > 0) {
    facilities.forEach(f => {
      const label = f.siteName, value = f.siteId;
      let ok = true;
      if (ok && levelIdSubset) {
        ok = f.levels.some(l => {
          return levelIdSubset[l.levelId];
        })
      }
      if (typeof label === "string" && label.length > 0 && 
          typeof value === "string" && value.length > 0 &&
          !idx[value] && ok) {
        idx[value] = true;
        uniqueValues.push({label,value});
      }
    })
  }
  sortUniqueValues(uniqueValues);
  return uniqueValues;
}

export function isFacilityInSite(siteId,facilityId) {
  let found = false
  const levelsDataset = getLevelsDataset();
  const facilities = levelsDataset && levelsDataset.getFacilities();
  if (facilities && facilities.length > 0) {
    found = facilities.some(f => {
      if (siteId === f.siteId && facilityId === f.facilityId) {
        return true;
      }
      return false;
    })
  }
  return found;
}

export function isLevelInFacility(facilityId,levelId) {
  const levelsDataset = getLevelsDataset();
  const level = levelsDataset && levelsDataset.getLevelData(levelId);
  return (level && level.facilityId === facilityId);
}

export async function queryCount(layer, where: string): Promise<number> {
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const qtask = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.returnGeometry = false;
  query.returnZ = false;
  query.outFields = ["*"];
  query.where = where;
  queryUtil.applyGdbVersion(layer,query);
  return await qtask.executeForCount(query);
}

export async function queryOccupantAssignmentTypes(where?) {
  const info = {
    hasAssigned: false,
    hasUnassigned: false,
    hasOffice: false,
    hasHotel: false,
    hasHotdesk: false,
    uniqueValues: []
  }
  const promise = new Promise((resolve,reject) => {
    const promises = [];
    const layerType = "occupants";
    const layer = getLayer(layerType);
    const unitidField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_UNIT_ID);
    const areaidField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_AREA_ID);

    const combine = (wh) => {
      if (where) return wh + " AND " + where;
      return wh;
    }

    const wh0 = `(((${unitidField} IS NOT NULL) AND (${unitidField} <> '')) OR ((${areaidField} IS NOT NULL) AND (${areaidField} <> '')))`;
    const p0 = queryCount(layer,combine(wh0));
    promises.push(p0);
    p0.then(count => info.hasAssigned = (count > 0));
    
    const wh1 = `(((${unitidField} IS NULL) OR (${unitidField} = '')) AND ((${areaidField} IS NULL) OR (${areaidField} = '')))`;
    const p1 = queryCount(layer,combine(wh1));
    promises.push(p1);
    p1.then(count => info.hasUnassigned = (count > 0));
    
    // const wh2 = `((${unitidField} IS NOT NULL) AND (${unitidField} <> ''))`;
    // const p2 = queryCount(layer,combine(wh2));
    // promises.push(p2);
    // p2.then(count => info.hasOffice = (count > 0));

    // const wh3 = `((${areaidField} IS NOT NULL) AND (${areaidField} <> ''))`;
    // const p3 = queryUniqueValues(layerType,areaidField,combine(wh3));
    // promises.push(p3);
    // p3.then(uniqueValues => {
    //   const plan = Context.instance.spaceplanner.activePlan;
    //   if (uniqueValues && uniqueValues.length > 0) {
    //     uniqueValues.some(uv => {
    //       const areaId = uv.value;
    //       const areaType = plan.getAreaType(areaId);
    //       if (areaType === OfficePlan.SpaceAssignmentTypes.hotel) {
    //         info.hasHotel = true;
    //       } else if (areaType === OfficePlan.SpaceAssignmentTypes.hotdesk) {
    //         info.hasHotdesk = true;
    //       }
    //       return (info.hasHotel && info.hasHotdesk);
    //     })
    //   }
    // })

    Promise.all(promises).then(list => {
      //console.log("queryOccupantAssignmentTypes.info",info)
      const i18n = Context.instance.i18n;
      if (info.hasAssigned) {
        info.uniqueValues.push({
          label: i18n.filter.occupantAssignmentType.assigned,
          value: "assigned",
          where: wh0
        })
      }
      if (info.hasUnassigned) {
        info.uniqueValues.push({
          label: i18n.filter.occupantAssignmentType.unassigned,
          value: "unassigned",
          where: wh1
        })
      }
      resolve(info.uniqueValues);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

export async function queryUniqueValues(layerType,fieldName,where?) {
  const i18n = Context.instance.i18n;
  const layer = getLayer(layerType);
  const field = aiimUtil.findField(layer && layer.fields,fieldName);
  const fn = field && field.name;
  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const qtask = new Context.instance.lib.esri.QueryTask({url: url});
  const query = new Context.instance.lib.esri.Query();
  query.returnGeometry = false;
  query.returnZ = false;
  query.returnDistinctValues = true;
  query.outFields = [fn];
  query.where = where || "1=1";
  query.orderByFields = [fn];
  queryUtil.applyGdbVersion(layer,query);
  //console.log("queryUniqueValues.where",fieldName,where)
  const result = await qtask.execute(query);
  const uniqueValues = [], idx = {};
  if (result && result.features) {
    result.features.forEach(f => {
      let label = f.attributes[fn];
      let value = f.attributes[fn];
      if (value !== undefined) {
        let checkNoValue = true;
        if (field.domain && field.domain.type === "coded-value") {
          if (Array.isArray(field.domain.codedValues)) {
            field.domain.codedValues.some(cv => {
              if (value === cv.code) {
                label = cv.name;
                checkNoValue = false
                return true;
              }
              return false;
            });
          }
        }
        if (checkNoValue && (value === null || value === "")) {
          label = i18n.filter.noValueLabel;
          value = "__novalue";
        }
        if (!idx[value]) {
          idx[value] = true;
          uniqueValues.push({label,value});
        }
      }
    })
  }
  return uniqueValues;
}

export function sortUniqueValues(uniqueValues) {
  if (uniqueValues && uniqueValues.length > 0) {
    uniqueValues.sort((A,B) => {
      const a = A.label;
      const b = B.label;
      if (a && b) {
        return a.localeCompare(b);
      } else if (a) {
        return -1;
      } else if (b) {
        return 1;
      }
      return 0;
    });
  }
}