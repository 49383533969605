import React, { Component } from "react";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import TextareaAutosize from 'react-textarea-autosize';
import * as CrudComment from "../../base/transaction/comment";
import * as commentReplyUtil from "./commentReplyUtil";
import * as component from "../../../../src/components/util/component";
import  { ThemeColorButton, CharacterCount, ButtonGroupInput, EmptyInput } from "../../styles/commentsStyles";

export default class EditComment extends Component {

    componentId;
    total =  commentReplyUtil.getMaxCommentLength();

    constructor(props){
        super(props);
        this.componentId = component.nextId();
        this.state={
            inputChange : this.props.comment,
            emptyComment : false,
            isCalloutClicked : false,
            characterCount: this.props.comment.length
        }
    }

    componentDidMount () {
        this._focus();
    }

    _focus=()=>{
        const input = document.getElementById(this.componentId);
        commentReplyUtil.setCursorPosition(input, this.props.comment.length);
        input.scrollTop = input.scrollHeight;
    }
    
    handleCancel=()=>{
        this.props.closeEdit();
        Topic.publish(Topic.LoadComments);
    }

    handleInputChange =(evt)=> {
        let commentStr = evt.target.value;
        if(commentStr.length > this.total) return;
        commentStr = Context.sanitizeHtml(commentStr);
        this.setState({
            inputChange : commentStr,
            characterCount : commentStr.length
        },function(){
            if(!this.state.inputChange) {
                this.setState({
                    emptyComment : true
                })
            }else{
                this.setState({
                    emptyComment : false
                })
            }
        })
    }

    render(){
        const i18n = Context.instance.i18n;
        const id = this.componentId;
        let showError;
        if(this.state.emptyComment) showError = i18n.spaceplanner.comments.emptyComment;
        let pattern = i18n.item.categoryTypePattern;
        pattern = pattern.replace("{category}", this.state.characterCount);
        pattern = pattern.replace("{type}", this.total);
        return(
            <div style={{display:"flex", flexDirection: "column", alignItems: "flex-end", margin: "0 0.5rem", paddingBottom:"0.5rem"}}>
                <TextareaAutosize 
                    id={id}
                    style={{width: "97%", borderColor:"#cacaca", padding:"5px", fontFamily: "sans-serif", fontSize: "0.833rem", color:"#323232"}}
                    minRows={2} 
                    maxRows = {10}
                    value={Context.sanitizeHtml(this.state.inputChange)}
                    onChange={this.handleInputChange}
                    />
                <EmptyInput>{showError}</EmptyInput>
                <ButtonGroupInput>
                    <CharacterCount>{pattern}</CharacterCount>
                    <div>
                        <ThemeColorButton transparent extraSmall onClick={this.saveComment}>{i18n.general.save}</ThemeColorButton>
                        <ThemeColorButton transparent extraSmall onClick={this.handleCancel}>{i18n.general.cancel}</ThemeColorButton>
                    </div>
                </ButtonGroupInput>
            </div>
        )
    }

    saveComment=()=>{
        this.setState({
            inputChange : this.state.inputChange
        },
        function(){
            if(!this.state.inputChange){
                this.setState({
                    emptyComment : true
                })
            }else{
                const commentId = this.props.commentId;
                const url = commentReplyUtil.getCommentUrl();
                const obj = {
                    updatedComment : this.state.inputChange.trim(),
                    commentId : commentId
                };
                CrudComment.editComment(obj, url).then(()=> {
                    this.props.closeEdit();
                    Topic.publish(Topic.LoadComments);
                })
                .catch(error => {
                    console.error(error);
                    Topic.publishErrorUpdatingData(error.message);
                })
            }
        })
    }
}