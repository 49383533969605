import React from "react";

import Context from "../../../context/Context";

const CSS = {
  button: "i-viewtoggle esri-widget--button"
};

export default class ViewToggle extends React.Component {

  isViewToggle = true;

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  clicked(event) {
    if (this.props.clickHandler) this.props.clickHandler(event);
  }

  onKeyUp(event) {
    if (event && event.keyCode === 13) this.clicked(event);
  }

  render() {
    if (!this.props.view) return null;
    const i18n = Context.getInstance().i18n;
    let text = "3D";
    let ariaLabel = i18n.map.viewToggle.arialLabel3D;
    //let text = i18n.map.viewToggle.label3D;
    let tip = i18n.map.viewToggle.tip3D;
    if (this.props.view.type === "3d") {
      text = "2D";
      //text = i18n.map.viewToggle.label2D;
      tip = i18n.map.viewToggle.tip2D;
      ariaLabel = i18n.map.viewToggle.arialLabel2D;
    }
    return (
      <button className={CSS.button} type="button" tabIndex="0"
        title={tip} onClick={this.clicked} onKeyUp={this.onKeyUp} aria-label={ariaLabel}>
        {text}
      </button>
    );
  }

}
