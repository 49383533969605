export function mixin(obj,props) {
  if (typeof obj === "object" && obj !== null &&
      typeof props === "object" && props !== null) {
    Object.assign(obj,props);
    // Object.keys(props).forEach(k => {
    //   if (props.hasOwnProperty(k)) {
    //     obj[k] = props[k];
    //   }
    // });
  }
}
