/*
 * This is an example of how we can use TypeScript and types to make our app code
 * smarter in terms of what it expects for inputs and outputs. This is a more involved example
 * that shows how we can make even object literals and template strings type-safe.
 */

type Pairs<T extends string> = Record<Template<T>, string | number>;
type Template<T extends string> = T extends `${string}{${infer P}}${infer R}` ? P | Template<R> : never;

/**
 * Given a template, and an object of key-value pairs, replace all occurrences of
 * each key with the associated value in the string
 *
 * @param {string} template
 * @param {object} pairs
 * @returns Formatted string
 */
export function stringFormatter<S extends string>(template: S, pairs: Pairs<S>): string {
  if (!template || !pairs) {
    return "";
  }

  let formattedString: string = template;
  for (const property in pairs) {
    const value: string = `${pairs[property as keyof Pairs<S>]}`;
    formattedString = formattedString.replaceAll(`{${property}}`, value);
  }
  return formattedString;
}

/*
Sample usage:

Error: stringFormatter("My name is {name} and I work at {company} as a(n) {job}", { 
  test: "John Smith", 
  companyy: "Esri", 
  job: "Software Developer" 
});

Error: stringFormatter("My name is {fullname} and I work at {company} as a(n) {job}", { 
  name: "John Smith", 
  company: "Esri", 
  job: "Software Developer" 
});

Good: stringFormatter("My name is {name} and I work at {company} as a(n) {job}", { 
  name: "John Smith", 
  company: "Esri", 
  job: "Software Developer" 
});

*/
