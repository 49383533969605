import {readPlaceholderIds} from './placeholderUtil';
import Context from '../../context/Context';
import * as sourceUtil from './sourceUtil';
import * as aiimUtil from '../../aiim/util/aiimUtil';
import QueryAll from './QueryAll';
import * as queryUtil from './queryUtil';
import FieldNames from '../../aiim/datasets/FieldNames';
import { escSqlQuote } from '../../aiim/util/selectionUtil'

/**
 * Get matching occupants and placeholders 
 * @param options 
 * @returns object with a list of occupants and a list of placeholders
 */
export const getPlaceholdersToReplace = async (options: any) => {
  try {
    const data = {
      occupants: [],
      placeholders: []
    }
    
    const vm = options && options.versionManager;
    let layer;
    let url;
    
    if (vm) {
      layer = sourceUtil.getPeopleLayer();
      url = Context.checkMixedContent(layer.url+'/'+layer.layerId);
    } else {
      const project = Context.instance.spaceplanner.planner.project;
      const defaultSourceInfo = project && project.hostedInfo && project.hostedInfo.sourceInfo;
      layer = defaultSourceInfo.peopleLayerInfo;
      url = Context.checkMixedContent(defaultSourceInfo.serviceUrl + "/" + layer.id);
    }
    
    const res: any = await readPlaceholderIds(options);
    const names = res && res.knownas;
    const knownasField = aiimUtil.findFieldName(layer.fields, FieldNames.PEOPLE_FULLNAME);
    const emailField = aiimUtil.findFieldName(layer.fields, FieldNames.PEOPLE_EMAIL);
    const nameList = [];
    const defaultDuplicates = {};
    const planDuplicates = {}
    
    // Query default people that match placeholders name
    if (!names) return data;
    Object.keys(names).forEach(name => nameList.push(`'${escSqlQuote(name.toLowerCase().trim())}'`));
    const where = `LOWER(${knownasField}) IN (${nameList.join(',')})`;
    const opt = {
      layer: layer,
      perFeatureCallback: (occupant: __esri.Graphic) => {
        const knownas = getName(occupant);
        const count = defaultDuplicates[knownas];
        if (!count) {
          defaultDuplicates[knownas] = 1
        } else {
          defaultDuplicates[knownas] = count + 1;
        }
        data.occupants.push(occupant);
      }
    }
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ['*'];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = where;
    const qa = new QueryAll();
    await qa.execute(url, query, opt);
    const occupants2 = [];
    data.occupants.forEach((occupant: __esri.Graphic) => {
      const knownas = occupant.attributes[knownasField];
      if (defaultDuplicates[knownas] > 1) return;
      occupants2.push(occupant);  
    });
    data.occupants = occupants2;
    
    // Query placeholder features
    const placeholderOpt = {
      layer: layer,
      perFeatureCallback: (placeholder: __esri.Graphic) => {
        if (data.occupants.length < 0) return; 
        data.occupants.forEach((occupant) => {
          const knownas = getName(placeholder);
          if (getName(occupant) === knownas && placeholder.attributes[emailField].startsWith('placeholder')) {
            if (defaultDuplicates[knownas] > 1) return;
            const count = planDuplicates[knownas];
            if (!count) {
              planDuplicates[knownas] = 1
            } else {
              planDuplicates[knownas] = count + 1;
            };
            data.placeholders.push(placeholder)
            occupant.placeholder = placeholder;
          };
        })
      } 
    }
    if (vm) {
      queryUtil.applyGdbVersion(layer, query);
      await qa.execute(url, query, placeholderOpt);
    } else {
      layer = sourceUtil.getPeopleLayer();
      url = Context.checkMixedContent(layer.url+'/'+layer.layerId);
      await qa.execute(url, query, placeholderOpt);
    }
    const placeholders2 = [];
    data.placeholders.forEach(placeholder => {
      const knownas = placeholder.attributes[knownasField];
      if (planDuplicates[knownas] > 1) return;
      placeholders2.push(placeholder);
    });
    // If the placeholder has multiple assignments or the matching occupant has multiple assignments
    // we will ignore then for now
    data.placeholders = placeholders2;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const makeReplaceRequest = (occupants: __esri.Graphic[], placeholders: __esri.Graphic[]) => {
  const deletes = [];
  const updates = [];
  
  const project = Context.instance.spaceplanner.planner.project;
  const isHosted = project.isHosted;
  
  const layer = sourceUtil.getPeopleLayer();
  const objectIdField = layer.objectIdField;
  // @ts-ignore
  const globalIdField = layer.globalIdField;
  
  const id = isHosted ? globalIdField : objectIdField;
  
  placeholders && placeholders.length > 0 && placeholders.forEach(placeholder => {
    const newOccupant = occupants.find(occupant => getName(occupant) === getName(placeholder));
    if (getName(newOccupant) === getName(placeholder)) {
      deletes.push(placeholder.attributes[id]);
    }
  });
  
  if (deletes.length > 0 || updates.length > 0) {
    return {
      edits: [{
        id: layer.layerId,
        adds: [],
        updates: updates,
        deletes: deletes
      }]
    }
  }
}

export const getName = (feature: __esri.Graphic) => {
  if (feature) {
    const knownas = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_FULLNAME);
    return knownas ? knownas.toLowerCase().trim() : knownas;
  }
}