const NETWORK_ERRORS = [
  "Failed to fetch", // Edge/Chrome
  "NetworkError when attempting to fetch resource.", // Firefox
  "A server with the specified hostname could not be found." // Safari
];

/**
 * Check for network errors, compatible with Chrome, Edge, Firefox, and Safari
 * 
 * @param {string} errorMessage 
 * @returns If the error is a network error
 */
export function isNetworkError(errorMessage) {
  return NETWORK_ERRORS.includes(errorMessage);
}