import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import { getAttributes } from "../../components/common/MultipleAssignments/multipleAssignmentsUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import {
  addAttributeEdit,
  appendPersonUnassignment,
  appendUnitUnassignment,
  clonePersonFeature,
  cloneUnitFeature,
  fetchRelatedPeople,
  makeUnassignPeopleUpdates
} from "./transactionUtil";

/*
  transactions

    assignUnitsToArea
    unassignUnits

*/

export function prepare(task) {
  const promise = new Promise((resolve,reject) => {

    const transactionInfo = transaction.makeTransactionInfo();
    const unitFeatureItems = task.unitFeatureItems;
    const unitIds = [];
    let relatedPeopleInfo;
    const peopleData = task.peopleData
    const duplicates = (peopleData && peopleData.duplicates) || []
    const singleRecords = (peopleData && peopleData.singleRecords) || []
    const disableAssignments = (peopleData && peopleData.disableAssignments);

    unitFeatureItems.forEach(featureItem => {
      const unitId = aiimUtil.getAttributeValue(featureItem.feature.attributes,FieldNames.UNIT_ID);
      if (typeof unitId === "string" && unitId.length > 0) {
        unitIds.push(unitId);
      }
    });

    const makePeopleEdits = () => {
      let updates = [], undoUpdates = [], deletes = [], undoDeletes = [];
      const layer = sourceUtil.getPeopleSource().layer2D;
      const fields = layer.fields
      const keyField = layer.globalIdField;
      const keys = (relatedPeopleInfo && relatedPeopleInfo.globalIds);
      const features = (relatedPeopleInfo && relatedPeopleInfo.features) || [];

      if (duplicates && duplicates.length > 0) {
        duplicates.forEach(person => {
          const personFeature = person.feature || person
          const key = personFeature.attributes[keyField]
          //const undo = {attributes: Object.assign({}, personFeature.attributes)}
          const undo = clonePersonFeature(task, personFeature, "ensure-geometry");
          deletes.push(key)
          undoDeletes.push(undo)
        })
      }

      if (singleRecords && singleRecords.length > 0) {
        singleRecords.forEach(person => {
          const personFeature = person.feature || person
          undoUpdates.push(clonePersonFeature(task, personFeature, "ensure-geometry"))
          const update = {attributes: {}}
          const personAttributes = getAttributes(person)
          update.attributes[keyField] = personAttributes[keyField]
          appendPersonUnassignment(task, fields, update)
          updates.push(update)
        })
      }

      if (!peopleData && (task.assignUnitsToArea || task.unassignUnits)) {
        updates = makeUnassignPeopleUpdates(task,keys,keyField);
      }
      if (updates.length > 0) {
        features.forEach(feature => {
          undoUpdates.push(clonePersonFeature(task,feature,"ensure-geometry"));
        });
      }

      if (updates.length > 0 || deletes.length > 0) {
        transactionInfo.peopleEdits = {
          updates: updates,
          deletes: deletes
        };
        transactionInfo.undo.peopleEdits = {
          updates: undoUpdates,
          adds: undoDeletes
        };
        transactionInfo.edits.push({
          id: layer.layerId,
          updates: updates,
          deletes: deletes
        });
        transactionInfo.undo.edits.push({
          id: layer.layerId,
          updates: undoUpdates,
          adds: undoDeletes
        });
      }
    };

    const makeUnitEdits = () => {
      const updates = [], undos = [];
      const layer = sourceUtil.getUnitsSource().layer2D;
      const fields = layer.fields;
      const keyField = layer.globalIdField;

      unitFeatureItems.forEach(featureItem => {
        undos.push(cloneUnitFeature(task,featureItem.feature,"no-geometry"));

        const update = {attributes: {}};
        update.attributes[keyField] = featureItem.feature.attributes[keyField];

        if (task.assignUnitsToArea) {
          const areaItem = task.assignUnitsToArea.areaItem;
          const areaType = task.assignUnitsToArea.areaType;
          const areaId = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREA_ID);
          addAttributeEdit(fields,update.attributes,FieldNames.UNITS_AREA_ID,areaId);
          addAttributeEdit(fields,update.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,areaType);
          addAttributeEdit(fields,update.attributes,FieldNames.UTILIZATION, 0)
        }

        if (task.unassignUnits) {
          appendUnitUnassignment(task,fields,update);
          if (disableAssignments) {
            addAttributeEdit(fields,update.attributes,
              FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,"not assignable");
          }
        }

        updates.push(update);
      });

      if (updates.length > 0) {
        transactionInfo.unitEdits = {updates: updates};
        transactionInfo.undo.unitEdits = {updates: undos};
        transactionInfo.edits.push({
          id: layer.layerId,
          updates: updates
        });
        transactionInfo.undo.edits.push({
          id: layer.layerId,
          updates: undos
        });
      }
    };

    Promise.resolve().then(() => {
      if (task.assignUnitsToArea || task.unassignUnits) {
        return fetchRelatedPeople(task,unitIds).then(result => {
          relatedPeopleInfo = result;
          makePeopleEdits();
        });
      }
    }).then(() => {
      makeUnitEdits();
    }).then(() => {
      resolve(transactionInfo);
    }).catch(ex => {
      reject(ex);
    });

  });
  return promise;
}
