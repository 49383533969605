import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "./editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as val from "../../../../util/val";
import * as vectorUtil from "./vectorUtil";

export let useColors = false;

export function getSymbol(layer,feature) {
  const renderer = layer && layer.renderer;
  if (feature && renderer && typeof renderer._getUniqueValueInfo === "function") {
    const info = renderer._getUniqueValueInfo(feature);
    if (info && info.symbol && typeof info.symbol.clone === "function") {
      return info.symbol.clone();
    } else {
      const symbol = renderer.defaultSymbol || renderer.symbol;
      if (symbol && typeof symbol.clone === "function") {
        return symbol.clone();
      }
    }
  } else if (feature && renderer && renderer.type === "simple") {
    const symbol = renderer.symbol;
    if (symbol && typeof symbol.clone === "function") {
      return symbol.clone();
    }
  }
}

export function getTextSymbol(layer) {
  let info = layer && layer.labelingInfo;
  if (info && info.length > 0) info = info[0];
  if (info && info.symbol && typeof info.symbol.clone === "function") {
    return info.symbol.clone();
  }
}

export async function initModifiedUnit(unit,modifiedUnit,z) {
  const attributes = Object.assign({},unit.attributes)
  aiimUtil.removeShapeAttributes(attributes);
  modifiedUnit.attributes = attributes
  await editorUtil.updateGrossArea(modifiedUnit);
  editorUtil.updateZ(modifiedUnit.geometry,z)
}

export async function initNewUnit(unit,newUnit,z) {
  const attributes = Object.assign({},unit.attributes)
  aiimUtil.removeShapeAttributes(attributes);
  const layer = sourceUtil.getUnitsLayer();
  const unitIdField = aiimUtil.findFieldName(layer.fields,FieldNames.UNIT_ID)
  const areaIdField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_AREA_ID)
  const asnField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE)
  const nameField = aiimUtil.findFieldName(layer.fields,FieldNames.NAME)
  const nameLongField = aiimUtil.findFieldName(layer.fields,FieldNames.NAME_LONG)
  const name = nameField && attributes[nameField];
  const newName = name +" - Copy";
  const newLongName = name +" - Copy";
  const unitId = val.generateRandomId();
  const asnType = asnField && attributes[asnField];
  delete attributes[layer.objectIdField];
  delete attributes[layer.globalIdField];
  attributes[unitIdField] = unitId; // @todo
  attributes[nameField] = newName; // @todo
  if (nameLongField) attributes[nameLongField] = newLongName; // @todo
  attributes[areaIdField] = null;
  attributes[asnField] = "none";  
  // if (asnType === "office") {
  //   //attributes[areaIdField] = null;
  //   attributes[asnField] = "none";
  // }
  newUnit.attributes = attributes
  await editorUtil.updateGrossArea(newUnit);
  editorUtil.updateZ(newUnit.geometry,z)
  return attributes;
}

export function initNewWall(unit,newWall,z) {
  const layer = sourceUtil.getDetailsLayer();
  if (layer) {
    const attributes = {};
    layer.fields.forEach(f => {
      if (f.editable) {
        attributes[f.name] = null;
      }
    })
    const detailIdField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAIL_ID)
    const levelIdField = aiimUtil.findFieldName(layer.fields,FieldNames.LEVEL_ID)
    const heightRelField = aiimUtil.findFieldName(layer.fields,FieldNames.HEIGHT_RELATIVE)
    const useTypeField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAILS_USE_TYPE)
    const detailId = val.generateRandomId();
    const levelId = aiimUtil.getAttributeValue(unit.attributes,FieldNames.LEVEL_ID);
    const heightRel = aiimUtil.getAttributeValue(unit.attributes,FieldNames.HEIGHT_RELATIVE);
    delete attributes[layer.objectIdField];
    delete attributes[layer.globalIdField];
    attributes[detailIdField] = detailId; // @todo
    attributes[levelIdField] = levelId;
    if (heightRelField && typeof heightRel === "number") {
      attributes[heightRelField] = heightRel;
    }
    // if (useTypeField) {
    //   attributes[useTypeField] = "Interior Wall"; // @todo
    // }
    aiimUtil.removeShapeAttributes(attributes);
    newWall.attributes = attributes;
    editorUtil.updateZ(newWall.geometry,z)
  }
}

export function makeCutUnitSymbol() {
  return {
    type: "simple-fill", 
    style: "solid",
    color: [143, 188, 143, 1],
    outline: {
      // color: [0, 255, 255, 1],
      color: [0, 0, 0, 1],
      width: 1
    }
  }
}

// export function makeCutUnitHighlightSymbol2() {
//   return {
//     type: "simple-fill", 
//     style: "solid",
//     color: [255, 255, 255, 1],
//     outline: {
//       color: [255, 255, 255, 1],
//       width: 2
//     }
//   }
// }

export function makeSelectedUnitGraphic(unitFeature) {
  if (typeof unitFeature.clone === "function") {
    unitFeature = unitFeature.clone();
  } else {
    unitFeature = JSON.parse(JSON.stringify(unitFeature));
  }
  const geometry = new Context.instance.lib.esri.Polyline({
    hasZ: true,
    paths: unitFeature.geometry.rings,
    spatialReference: unitFeature.geometry.spatialReference
  })
  const graphic = new Context.instance.lib.esri.Graphic({
    attribute: unitFeature.attributes,
    geometry: geometry,
    symbol: {
      type: "simple-line",
      color: [0, 255, 255, 1],
      width: 1
    }
  })
  // const graphic = new Context.instance.lib.esri.Graphic({
  //   attribute: unitFeature.attributes,
  //   geometry: unitFeature.geometry,
  //   symbol: makeSelectedUnitSymbol()
  // })
  return graphic;
}

export function makeSelectedUnitSymbol() {
  return {
    type: "simple-fill", 
    style: "solid",
    //color: [143, 188, 143, 1],
    color: [255, 255, 255, 0],
    outline: {
      color: [0, 255, 255, 1],
      //color: [0, 0, 0, 1],
      width: 1
    }
  }
}

export function makeUnitNameGraphic(type,feature,name) {
  const layer = sourceUtil.getUnitsLayer();
  let symbol = getTextSymbol(layer);
  if (symbol) {
    symbol.text = name;
    symbol.horizontalAlignment = "center";
  } else {
    symbol = {
      type: "text",
      color: "black",
      text: name,
      xoffset: 0,
      yoffset: 0,
      horizontalAlignment: "center",
      verticalAlignment: "bottom",
      font: {
        size: 10,
        family: "Tahoma"
      }
    }
  }
  const graphic = new Context.instance.lib.esri.Graphic({
    geometry: feature.geometry.centroid,
    symbol: symbol,
    xtnNameType: type
  });
  return graphic
}

export function makeWallSymbol() {
  return {
    type: "simple-line",
    //color: [ 0, 0, 255, 1 ],
    //color: "red",
    color: [143, 188, 143, 1],
    style: "solid",
    width: 2
  }
}

export function newSplitItem(props)  {
  let item = {
    unit: null,
    polyline: null,
    modifiedUnit: null,
    modifiedUnitName: null,
    newUnit: null,
    newUnitName: null,
    newWall: null
  }
  if (props) item = Object.assign(item,props);
  return item;
}

export function setUnitSymbol(graphic,feature,purpose) {
  const layer = sourceUtil.getUnitsLayer();
  const symbol = getSymbol(layer,feature) || makeCutUnitSymbol()
  if (purpose === "modifiedUnit") {
    if (useColors) symbol.color = "mediumseagreen";
  } else if (purpose === "newUnit") {
    if (useColors) symbol.color = "orange";
  }
  graphic.symbol = symbol;
}

export function setWallSymbol(graphic,feature,purpose) {
  const layer = sourceUtil.getDetailsLayer();
  const symbol = getSymbol(layer,feature) || makeWallSymbol()
  if (useColors) {
    symbol.color = "purple";
    symbol.width = 2;
  }
  graphic.symbol = symbol;
}
