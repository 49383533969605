import React from "react";

import Context from "../../../context/Context";
import DirectionsUtil from "../../../util/DirectionsUtil";
import Print from "./Print";
import SidebarHeader from "../Sidebar/SidebarHeader";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as mapUtil from "../../../util/mapUtil";
import * as tsUtil from "../../../util/tsUtil";

const CSS = {
  main: "i-directions",
  headerBar: "i-directions-header-bar",
  resetButton: "i-link btn",
  printButton: "i-link btn",
  padded: "i-padded",
  scrollDirections: "i-scroll-directions"
};

export default class Directions extends React.Component {

  _coreNode;
  _coreWidget;
  watchHandles = [];

  constructor(props) {
    super(props);


    let warningMessage = null;
    const context = Context.getInstance();
    const i18n = context.i18n;
    const print = new Print();
    const url = context.config.networkServiceUrl;
    if (!url || url.length === 0) {
      warningMessage = i18n.messages.noNetworkService;
    } else {
      let sources;
      if(context.aiim && context.aiim.datasets) sources = context.aiim.datasets.categories.makeSearchSources(true);
      if (!sources && sources.length > 0) {
        warningMessage = i18n.messages.noSearchCategories;
      }
    }

    this.state = component.newState({
      hasPrintTask: !!print.getPrintTaskUrl(),
      warningMessage: warningMessage
    });
    this.print = this.print.bind(this);
    this.reset = this.reset.bind(this);
    this.setCoreNode = this.setCoreNode.bind(this);
  }

  componentDidMount() {
    this.check();

    component.own(this,[

      Topic.subscribe(Topic.DirectionsFromURL, params => {
        try {
          if (params && params.showInDirections) {
            this.setupURLDirections(params)
            Context.instance.routeFromURL = true
          }
        } catch(ex) {
          console.warn("Error getting directions from URL")
          console.error(ex)
        }
      }),

      Topic.subscribe(Topic.DirectionsClicked,params => {
        try {
          if (params && params.searchResult) {
            this.setupTarget(params.searchResult);
          }
        } catch(ex) {
          console.warn("Error DirectionsPanel::setupTarget");
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.FacilityModeUpdated,params => {
        const directions = this._coreWidget;
        if (directions && directions.viewModel) {
          const vm = directions.viewModel;
          const routeLayer = vm.get("_routeGraphicsLayer");
          if (vm.view && vm.indoorsRouteResult && routeLayer) {
            vm.indoorsRouteResult.afterSetLevel(vm.view,routeLayer.graphics);
          }
        }
      }),

      Topic.subscribe(Topic.ReloadViews,params => {
        this.destroyCoreWidget();
      }),

      Topic.subscribe(Topic.ViewActivated,(params) => {
        component.refresh(this);
        const directions = this._coreWidget;
        if (directions && directions.view !== params.view) {
          this.resetOnViewChange(params);
          // let vm = directions.viewModel;
          // let routeLayer = vm.get("_routeGraphicsLayer");
          // directions.view = params.view;
          // if (params.view && vm.indoorsRouteResult && routeLayer) {
          //   vm.indoorsRouteResult.addRouteGraphics(params.view,routeLayer.graphics);
          // }
        }
      })

    ]);
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.props.panelIsActive && !prevProps.panelIsActive) {
      this.focusOnFirstStop();
    }
  }

  componentWillUnmount() {
    //console.log("Directions::componentWillUnmount");
    this.destroyCoreWidget();
    component.componentWillUnmount(this);
  }

  check(opts) {
    const context = Context.getInstance();
    if (!this._coreWidget && this._coreNode && context.views && context.views.activeView) {
      this.makeCoreWidget(opts);
      component.refresh(this);
    }
  }

  clearWatchHandles() {
    this.watchHandles.forEach((h) => {
      try {
        h.remove();
      } catch(ex) {
        console.error(ex);
      }
    });
    this.watchHandles = [];
  }

  destroyCoreWidget() {
    const widget = this._coreWidget;
    try {
      this.clearWatchHandles();
      const layers = [];
      if (widget) {
        let view = widget.view;
        let vm = widget.viewModel;
        if (vm) {
          vm.clearResults();
          layers.push(vm.get("_routeGraphicsLayer"));
          layers.push(vm.get("_highlightLayer"));
          layers.push(vm.get("_stopLayer"));
          if (view && view.map && vm.layer) view.map.remove(vm.layer)
        }
        vm = null;
        widget.destroy();
        this._coreWidget = null;
        layers.forEach(function(lyr){
          try {
            if (lyr) {
              //console.log("Removing layer",lyr);
              lyr.graphics.removeAll();
              if (view && view.map) view.map.remove(lyr);
            }
          } catch(ex) {
            console.log("Error removing directions graphics layer.")
            console.error(ex);
          }
        });
      }
    } catch(ex) {
      console.warn("Error Directions::destroyCoreWidget:",widget);
      console.error(ex);
    }
  }

  focusOnFirstStop() {
    try {
      const directions = this._coreWidget
      const stops = directions && directions.viewModel.layer.stops;
      if (stops && stops.length > 0) {
        const stop = stops.getItemAt(0);
        const input = stop && directions._stopsToSearches.get(stop);
        const inputNode = input && input._inputNode;
        if (inputNode && (typeof inputNode.focus === "function")) {
          setTimeout(() => {
            const v = inputNode.value;
            if (typeof v === "string" && v === "") {
              input._inputNode.focus();
            }
          },500)
        }
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  makeCoreWidget(opts) {
    const context = Context.getInstance();
    const lib = context.lib;
    const view = context.views.activeView;
    const directionsUtil = new DirectionsUtil();
    context.views.toggleClickHandlers("resume");

    let url = context.config.networkServiceUrl;
    if (!url || url.length === 0) return;
    let hasServiceUrl = false;
    if (typeof url === "string" && url.length > 0) {
      let idx = url.lastIndexOf("/Route");
      if (idx === -1 || idx !== (url.length - 6)) {
        // url += "/Route";
      }
      hasServiceUrl = true;
    }
    let hasSources = false;
    let sources = context.aiim.datasets.categories.makeSearchSources(true);
    if (sources && sources.length > 0) {
      hasSources = true;
    }
    if (!hasServiceUrl || !hasSources) return;

    const containerNode = document.createElement("div");
    this._coreNode.appendChild(containerNode);

    lib.esri.esriConfig.routeServiceUrl = url;

    let directions = this._coreWidget = new lib.indoors.CustomDirections({
      indoorsContext: context,
      tsUtil: tsUtil,
      container: containerNode,
      view: view,
      indoorsRouteServiceUrl: url,
      searchProperties: {
        allPlaceholder: context.i18n.directions.allPlaceholder,
        sources: sources,
        includeDefaultSources: false,
        locationEnabled: true
      }
    });
    const vm = directions.viewModel;
    vm.indoorsDirectionsPanel = this;

    vm.routeParameters.directionsLengthUnits = directionsUtil.getLocaleDistanceUnits();
    vm.routeParameters.outSpatialReference = view.spatialReference.clone();
    vm.routeParameters.directionsStyleName = "campus";
    vm.routeParameters.outputLines = "true-shape-with-measure";
    vm.routeParameters.returnDirections = true;
    vm.routeParameters.returnRoutes = true;
    vm.routeParameters.returnZ = true;

    // JSAPI 4.11 -> can't set esriDOTFeatureSets in RouteParameters, see app/context/Starter.js
    //vm.routeParameters.directionsOutputType = "esriDOTFeatureSets";
    vm.routeParameters.directionsOutputType = "complete";

    const directionsLanguage = directionsUtil.getDirectionsLanguage();
    if (directionsLanguage) vm.routeParameters.directionsLanguage = directionsLanguage;

    //vm.routeParameters.outputGeometryPrecision = 0;

    const offset = context.config.graphicElevationOffset;
    const setOffset = (layer,isHighlight,isStop) => {
      if (layer && !layer.elevationInfo) {
        layer.elevationInfo = {
          mode: "relative-to-ground",
          offset: offset,
          unit: "meters"
        }
        if (isHighlight || isStop) layer.elevationInfo.offset = offset + 0.05;
      }
    };
    //let routeSymbol = vm.get("routeSymbol");
    //const stopSymbols = vm.get("stopSymbols");
    const stopSymbols = vm.get("layer.defaultSymbols.stops");
    if (vm._routeGraphicsLayer) view.map.add(vm._routeGraphicsLayer);
    if (vm._highlightLayer) view.map.add(vm._highlightLayer);
    view.map.add(vm.layer);

    vm.indoorsRouteSymbolFor3D = new lib.esri.SimpleLineSymbol({
      color: [0, 94, 149, 1],
      width: 7,
      cap: "round",
      join: "round"
    });

    vm.indoorsRouteSymbol = new lib.esri.SimpleLineSymbol({
      color: [0, 94, 149, 1],
      width: 7,
      cap: "square",
      join: "round"
    });

    vm.indoorsRouteSymbolAlternate = new lib.esri.SimpleLineSymbol({
      color: [0, 94, 149, 1],
      width: 4,
      cap: "square",
      join: "round",
      style: "short-dash"
    });

    vm.indoorsRouteSymbolTransition = vm.indoorsRouteSymbolAlternate;
    // vm.indoorsRouteSymbolTransition = new SimpleLineSymbol({
    //   color: [0, 255, 0, 1],
    //   width: 7,
    //   cap: "round",
    //   join: "round"
    // });

    vm.indoorsRouteSymbolHighlight = vm.indoorsRouteSymbol.clone();
    vm.indoorsRouteSymbolHighlight.color = [252, 236, 52, 1];
    vm.indoorsRouteSymbolHighlight.width = 10;

    vm.indoorsEventSymbolHighlight = new lib.esri.SimpleMarkerSymbol({
      color: [252, 236, 52, 1],
      style: "circle",
      size: 28,
      outline: {color: [51, 51, 51, 1], width: 2}
    });

    vm.set("routeSymbol",new lib.esri.SimpleLineSymbol({
      color: [0, 94, 149, 1],
      width: 7,
      cap: "round",
      join: "round"
    }));

    stopSymbols.first = new lib.esri.SimpleMarkerSymbol({
      color: [255, 255, 255, 1],
      size: 19,
      outline: { color: [51, 51, 51, 1], width: 3 }
    });

    stopSymbols.middle = new lib.esri.SimpleMarkerSymbol({
      color: [255, 255, 255, 1],
      size: 12,
      outline: { color: [72, 72, 72, 1], width: 3 }
    });

    stopSymbols.last = new lib.esri.PictureMarkerSymbol({
      width: 23,
      height: 23,
      // Convert to base64 so that the JSON version will be spec compliant.
      // This is expected by PrintTask and avoids the need to URL encode for IE.
      url: `data:image/svg+xml;base64,${btoa(
        '<svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><path d="M15.15.3C6.9.3.3 6.9.3 15.15S6.9 29.7 15.15 29.7 29.7 23.1 29.7 15.15C29.7 6.9 23.25.3 15.15.3z" fill="#333"/><path d="M15 4.8C9.3 4.8 4.8 9.45 4.8 15c0 5.55 4.65 10.2 10.2 10.2 5.55 0 10.2-4.5 10.2-10.2 0-5.55-4.5-10.2-10.2-10.2z" fill="#FFFFFF"/><path fill="#333" d="M10.5 10.5h9v9h-9z"/></g></svg>'
      )}`
    });

    if (directions._setStopSymbols) directions._setStopSymbols();

    // TODO setting the offset causes a fatal error 5/24/2018
    setOffset(vm.get("_routeLayer"));
    setOffset(vm.get("_routeGraphicsLayer"));
    setOffset(vm.get("_highlightLayer"),true,false);
    setOffset(vm.get("_stopLayer"),false,true);
    setOffset(vm.layer,false,true)

    const routeLayer = vm.get("_routeGraphicsLayer");
    if (routeLayer) routeLayer.title = "indoorsRouteGraphicsLayer";
    const highlightLayer = vm.get("_highlightLayer");
    if (highlightLayer) highlightLayer.title = "indoorsHighlightGraphicsLayer";
    const stopLayer = vm.get("_stopLayer");
    if (stopLayer) stopLayer.title = "indoorsStopGraphicsLayer";

    this.clearWatchHandles();
    this.watchHandles.push(vm.watch("lastError",(newVal,oldVal,property,object) => {
      if (newVal) {
        console.warn("Directions error:");
        console.error(newVal);
      }
    }));

    this.watchHandles.push(vm.watch("lastRoute",(newVal,oldVal,property,object) => {
      try {
        if (this._coreWidget && vm.lastRoute && context.uiMode.isTouch) {
          let hasFeatures = false;
          if (this._coreWidget.viewModel.layer.stops) {
            this._coreWidget.viewModel.layer.stops.some(stop => {
              if (stop && stop.result && stop.result.feature) {
                hasFeatures = true;
                return true;
              }
              return false;
            });
          }
          if (hasFeatures > 0 && this._coreWidget._activeStop) {
            const input = this._coreWidget._stopsToSearches.get(this._coreWidget._activeStop);
            if (input) input.blur();
          }
        }
      } catch(ex) {
        console.error("DirectionsPanel:: input blur error:",ex);
      }
      component.refresh(this);
    }));

    this.watchHandles.push(vm.watch("selectedTravelMode",(newVal,oldVal,property,object) => {
      if (newVal) {
        const newID = newVal.id;
        const curID = Context.getInstance().session.selectedTravelModeID;
        if (newID !== curID) {
          Context.getInstance().session.selectedTravelModeID = newID;
          Topic.publish(Topic.TravelModeChanged,{travelMode: newVal});
        }
      }
    }));

    if (opts && opts.focusOnFirstStop) {
      let focusFirst = true;
      this.watchHandles.push(vm.watch("state",(newVal,oldVal,property,object) => {
        if (!focusFirst) return;
        if (newVal === "ready") {
          focusFirst = false;
          setTimeout(() => {
            this.focusOnFirstStop();
          },200);
        }
      }));
    }

    setTimeout(() => {
      mapUtil.reorderLocationGraphicLayers();
    },1000);
  }

  print() {
    const vm = this._coreWidget && this._coreWidget.viewModel;
    const routeResult = vm && vm.indoorsRouteResult;
    const lastRoute = vm && vm.lastRoute;
    let directions = null, stops = [];
    if (lastRoute && lastRoute.routeResults) {
      directions = lastRoute.routeResults[0].directions;
      stops = lastRoute.routeResults[0].stops.slice(0);
    }
    const print = new Print({
      lastRoute: lastRoute,
      directions: directions,
      stops: stops,
      routeResult: routeResult,
      routeParameters: vm.routeParameters,
      serviceDescription: vm.serviceDescription
    });
    print.print();
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        <div className={CSS.main}>
          <div className={CSS.scrollDirections}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const vm = this._coreWidget && this._coreWidget.viewModel;

    const warningMessage = this.state.warningMessage;
    if (warningMessage) {
      return (
        <div className={CSS.main}>
          <p className={CSS.padded}>{warningMessage}</p>
        </div>
      );
    }

    let canPrint = (this.state.hasPrintTask && vm && vm.lastRoute);
    canPrint = (canPrint && context.views.activeView && context.views.activeView.type === "2d");
    if (context.uiMode.isAndroid) canPrint = false;

    return (
      <>
        <div className={CSS.headerBar}>
          <button className={CSS.printButton} type="button"
            aria-label={i18n.directions.printTooltip}
            onClick={this.print} disabled={!canPrint}>
            {i18n.directions.print}
          </button>
          <button className={CSS.resetButton} type="button"
            aria-label={i18n.directions.resetTooltip}
            onClick={this.resetClicked}>
            {i18n.directions.reset}
          </button>
        </div>
        <div ref={this.setCoreNode}></div>
      </>
    );
  }

  renderHeader() {
    const i18n = Context.getInstance().i18n;
    return <SidebarHeader caption={i18n.directions.caption}
      closeAriaLabel={i18n.directions.closeAriaLabel}
      sidebarKey={this.props.sidebarKey}
      onCloseButtonClicked={this.reset}/>
  }

  _reset(opts) {
    Topic.publish(Topic.UpdatePointBarriers, ()=>{})
    this.destroyCoreWidget();
    this.check(opts);
  }

  reset() {
    this._reset();
  }

  resetClicked = () => {
    const opts = {focusOnFirstStop: true}
    this._reset(opts);
  }

  resetOnViewChange() {
    let cw, stops, context = Context.instance;
    cw = this._coreWidget;
    if (cw) {
      stops = cw.viewModel.layer.stops;
      stops = stops && stops.clone();
    }
    Topic.publish(Topic.UpdatePointBarriers, ()=>{})
    this.destroyCoreWidget();
    if (!this._coreWidget && this._coreNode && context.views && context.views.activeView) {
      this.makeCoreWidget();
      component.refresh(this);
      cw = this._coreWidget;
      if (cw && stops && stops.length > 0) {
        setTimeout(() => {
          cw.viewModel.layer.stops = stops;
          cw._processStops();
          cw.scheduleRender();
        },5000)
      }
    }
  }

  setCoreNode(node) {
    this._coreNode = node;
  }

  setupURLDirections(params) {
    let directions = this._coreWidget
    const hasDirections = !!directions
    if (hasDirections) this.reset()
    this.check()

    directions = this._coreWidget
    if (directions && directions.viewModel) {
      const vm = directions.viewModel;

      const setup = () => {
        setTimeout(() => {
          this.setupURLStops(params);
        }, 100);
      };

      const interval = setInterval(() => {
        // Wait until widget is ready
        if (directions && vm.state === "ready") {
          const stops = directions.viewModel.layer.stops
          const start = stops && stops.getItemAt(0)
          const end = stops && stops.getItemAt(stops.length - 1)
          // Wait until you can fill the inputs
          if (directions._stopsToSearches.has(start) && directions._stopsToSearches.has(end)) {
            setup()
            clearInterval(interval)
          }
        }
      }, 1500)

      // Stop loop after 10 minutes if directions can't be loaded
      setTimeout(() => {
        clearInterval(interval)
      }, 600000)
    }
  }

  setupURLStops(params) {
    const directions = this._coreWidget
    if (directions) {
      const setFirstStop = (params) => {
        let startSearchResult = params && params.startItem && params.startItem.searchResult
        if (startSearchResult) {
          startSearchResult = Object.assign({},startSearchResult);
          startSearchResult.feature = directions._makeStopResultFeature(startSearchResult);
        }
        const stops = directions.viewModel.layer.stops
        const start = stops && stops.getItemAt(0)
        const startInput = directions._stopsToSearches && directions._stopsToSearches.get(start)
        const startCaption = startSearchResult && startSearchResult.name
        start.result = startSearchResult
        start.result.feature.attributes.Name = startCaption
        start.name = startSearchResult.name;
        start.geometry = startSearchResult.feature.geometry;
        if (startInput) {
          startInput.searchTerm = startCaption
          startInput.viewModel._set("selectedResult", start.result)
        }
      }
      const setLastStop = (params) => {
        const stops = directions.viewModel.layer.stops
        let endSearchResult = params && params.endItem && params.endItem.searchResult
        if (endSearchResult) {
          endSearchResult = Object.assign({},endSearchResult);
          endSearchResult.feature = directions._makeStopResultFeature(endSearchResult);
        }
        const end = stops && stops.getItemAt(stops.length - 1)
        const endInput = directions._stopsToSearches && directions._stopsToSearches.get(end)
        const endCaption = endSearchResult && endSearchResult.name
        end.result = endSearchResult
        end.result.feature.attributes.Name = endCaption
        end.name = endSearchResult.name;
        end.geometry = endSearchResult.feature.geometry;
        if (endInput) {
          endInput.searchTerm = endCaption
          endInput.viewModel._set("selectedResult", end.result)
        }
      }
      if (params.startOnly && params.startItem) {
        setFirstStop(params)
        directions._processStops()
        directions.scheduleRender()
      } else if (params.endOnly && params.endItem) {
        setLastStop(params)
        directions._processStops()
        directions.scheduleRender()
      } else if (params.startItem && params.endItem) {
        setFirstStop(params)
        setLastStop(params)
        directions._processStops()
        directions.scheduleRender()
      }
    }
  }

  setupTarget(searchResult) {
    //console.log("DirectionsPanel::setupTarget",searchResult);
    let directions = this._coreWidget;
    const hasDirections = !!directions;
    if (hasDirections) {
      this.reset();
      this.check();
    } else {
      this.check();
    }

    directions = this._coreWidget;
    if (directions && directions.viewModel) {
      const vm = directions.viewModel;

      const setup = () => {
        setTimeout(() => {
          this.setupTargetStop(searchResult);
          this.focusOnFirstStop();
        },100);
      };

      // wait until the widget is ready
      if (vm.state === "ready") {
        setup();
      } else {
        let h = null;
        h = vm.watch("state",(newVal,oldVal,property,object) => {
          if (newVal === "ready") {
            h.remove();
            h = null;
            setup();
          }
        });
        setTimeout(() => {
          if (h) {
            h.remove();
            h = null;
          }
        },10000);
      }
    }
  }

  setupTargetStop(searchResult) {
    const directions = this._coreWidget;
    if (directions) {
      const stops = directions.viewModel.layer.stops;
      const stop = stops.getItemAt(1);
      const input = directions._stopsToSearches.get(stop);
      searchResult = Object.assign({},searchResult);
      searchResult.feature = directions._makeStopResultFeature(searchResult,stop);
      const caption = searchResult.name || Context.instance.i18n.general.untitled;
      stop.result = searchResult;
      stop.result.feature.attributes.Name = caption;
      input.searchTerm = caption;
      input.viewModel._set("selectedResult",stop.result);
      directions._processStops();
      directions.scheduleRender();
      this.setupHomeStop(searchResult);
    }
  }

  setupHomeStop(toSearchResult) {
    if (!Context.getInstance().uiMode.isKiosk) {
      if (!toSearchResult || !toSearchResult.isRealTime) return;
    }
    let searchResult = null;
    const referenceLayer = Context.getInstance().session.referenceLayer;
    if (referenceLayer) {
      const item = referenceLayer.homeLocation;
      if (item && item.isValid()) {
        const sr = item.searchResult;
        const sr2 = toSearchResult;
        if (sr && sr.feature && sr.feature.geometry &&
            sr2 && sr2.feature && sr2.feature.geometry) {
          const samegeo = (sr.feature.geometry === sr2.feature.geometry);
          if (!samegeo) searchResult = sr;
        }
      }
    }
    const directions = this._coreWidget;
    if (directions && searchResult) {
      //console.log("Auto setup home location",searchResult);
      const stops = directions.viewModel.layer.stops;
      const stop = stops.getItemAt(0);
      const input = directions._stopsToSearches.get(stop);
      searchResult = Object.assign({},searchResult);
      searchResult.feature = directions._makeStopResultFeature(searchResult, stop);
      const caption = searchResult.name; // TODO get the caption?
      stop.result = searchResult;
      stop.result.feature.attributes.Name = caption;
      input.searchTerm = caption;
      input.viewModel._set("selectedResult",stop.result);
      directions._processStops();
      directions.scheduleRender();
    }
  }

}
