// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import { connect } from "react-redux";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";
import Context from "../../../context/Context";
import { ModalController } from "../../../common/components/Modal";
import Topic from "../../../context/Topic";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import TransactionGuard from "../../base/TransactionGuard";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as transactions from "../../base/transaction/transactions";
import { getAttributes, getDuplicateCount, showUnassignModal, unassignAdditionalAssignments } from "./MultipleAssignments/multipleAssignmentsUtil";

// Component specific modules (Component-styled, etc.)
import { AssignToContainer } from "../../styles/Common/commonStyles";
import { ComboButton, Button } from "../../styles/Common/peopleAssignTo";

// App components
import SelectArea from "./SelectArea";
import SelectPerson from "./SelectPerson";
import UnassignPrompt from "./UnassignPrompt";

// Third-party components (buttons, icons, etc.)
import Menu, { MenuItem } from "calcite-react/Menu";
import PeopleIcon from "calcite-ui-icons-react/UsersIcon";
import HotDeskIcon from "calcite-ui-icons-react/DeskIcon";
import HotelIcon from "calcite-ui-icons-react/ServicesIcon";
import MeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";
import UnassignIcon from "calcite-ui-icons-react/MinusCircleIcon";
import ReactTooltip from "react-tooltip";
import Popover from 'calcite-react/Popover';
import { IFeatureItem } from "./PeopleAssignTo";
import { getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled } from "../../redux";

// JSON

// CSS
interface IUnitsAssignToProps extends IRdxProps {
  buttons?: boolean,
  disableUnassign?: boolean,
  enableUnassign?: boolean,
  featureItems: IFeatureItem[],
  rdxFeatureItemsKey?: string,
  rdxHotdesksEnabled: boolean,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean,
  isPopup?: boolean,
  name?: string,
  noMargin?: boolean,
  noWrap?: boolean,
  onAssignment?: () => void,
  placement?: string, // TODO: remove since unused in this component
  tab?: "assignments" | "properties" | "related", // TODO: remove since unused in this component
  type?: string // TODO: change to "unit" since that's the only input ever provided
}
interface IUnitsAssignToState {
  isButtons: boolean,
  menuOpen: boolean,
  name: string,
  personTip: string
}
class UnitsAssignTo extends Component<IUnitsAssignToProps, IUnitsAssignToState> {
  _mounted = false

  constructor(props: IUnitsAssignToProps) {
    super(props);
    this.state = {
      isButtons: this.props.buttons !== undefined ? this.props.buttons : false,
      name: this.props.name || "",
      personTip: Context.instance.i18n.spaceplanner.units.assignTo.person.label,
      menuOpen: false
    };
  }

  togglePopover = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  closePopover = () => {
    this.setState({
      menuOpen: false
    })
  }

  componentDidMount = () => {
    this._mounted = true
    const featureItems = this.getFeatureItems()
    if (featureItems && featureItems.length === 1) {
      const i18n = Context.instance.i18n
      const feature = featureItems[0]
      const attributes = feature.feature.attributes
      const capacity = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_CAPACITY);
      this.queryAssignments(feature).then(assignments => {
        const chk = (typeof capacity === "number");
        const personTip = (chk && (assignments >= capacity)) ?
          i18n.spaceplanner.capacity.personCapacityTooltip :
          i18n.spaceplanner.units.assignTo.person.label
        if (this._mounted) {
          this.setState({
            personTip: personTip
          })
        }
        ReactTooltip.rebuild()
      })
    }
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  };

  assignPersonToUnit = (personFeatureItem, unassignOthers?, duplicatePeople?) => {
    const unitFeatureItems = this.getFeatureItems();
    if (!unitFeatureItems || unitFeatureItems.length !== 1) return;
    const unitFeatureItem = unitFeatureItems[0];
    let mod = false, guard;
    this.ensureUnitGeometry(unitFeatureItem)
      .then(() => {
        if (this.canAssign(this.getFeatureItems(), "assignToPerson")) {
          mod = true;
          guard = new TransactionGuard({featureItems: [personFeatureItem]});
          return transactions.assignPeopleToUnit(
            [personFeatureItem],
            unitFeatureItem,
            unassignOthers,
            duplicatePeople
          );
        }
      })
      .then(result => {
        if (guard) guard.close();
        if (mod) {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
          this.onAssignment();
        }
      })
      .catch(error => {
        if (guard) guard.close();
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  duplicatePersonToUnit = (personFeatureItem, unassignOthers, duplicatePeople?) => {
    const unitFeatureItems = this.getFeatureItems()
    if (!unitFeatureItems || unitFeatureItems.length !== 1) return
    const unitFeatureItem = unitFeatureItems[0]
    let mod = false, guard
    this.ensureUnitGeometry(unitFeatureItem)
      .then(() => {
        if (this.canAssign(this.getFeatureItems(), "assignToPerson")) {
          mod = true;
          guard = new TransactionGuard({featureItems: [personFeatureItem]});
          // return transactions.assignPeopleToUnit(
          //   [personFeatureItem],
          //   unitFeatureItem
          // );
          return transactions.addPerson(personFeatureItem, unitFeatureItem, null, unassignOthers, duplicatePeople)
        }
      })
      .then(result => {
        if (guard) guard.close();
        if (mod) {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
          this.onAssignment();
        }
      })
      .catch(error => {
        if (guard) guard.close();
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  assignToClicked = (evt, option) => {
    const value = option.value;
    const caption = option.caption || "";
    let areaType = "";
    switch (value) {
      case "assignToPerson":
        this.assignToPersonClicked(caption);
        break;
      case "assignToHotDesk":
        areaType = OfficePlan.SpaceAssignmentTypes.hotdesk;
        this.assignToAreaClicked(caption, areaType);
        break;
      case "assignToHotel":
        areaType = OfficePlan.SpaceAssignmentTypes.hotel;
        this.assignToAreaClicked(caption, areaType);
        break;
      case "assignToMeetingRoom":
        areaType = OfficePlan.SpaceAssignmentTypes.meetingroom;
        this.assignToAreaClicked(caption, areaType);
        break;
      case "assignToNone":
        this.assignToNoneClicked(evt);
        break;
      default:
        console.log("no option for this selection available");
        break;
    }
  };

  assignToAreaClicked = (title, areaType, evt?) => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems())) return;
    SelectArea.showModal({
      title: title,
      areaType: areaType,
      promptOptions: {
        subject: "units",
        subjectItems: this.getFeatureItems(),
        target: areaType
      },
      onOK: sel => {
        const areaItem = sel;
        if (areaItem) {
          this.assignUnitsToArea(areaItem, areaType);
        }
      }
    });
  };

  queryAssignments = (featureItem): Promise<number> => {
    return officePlanUtil
      .queryPeopleAssignedToUnit(featureItem)
      .then((result) => {
        return result.length
      })
      .catch((ex) => {
        console.error("Error querying people assigned to unit", ex);
        return -1;
      });
  }

  assignToPersonClicked = (title, evt?) => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems(), "assignToPerson")) return;
    const i18n = Context.instance.i18n
    if (this.getFeatureItems().length === 1) {
      const feature = this.getFeatureItems()[0]
      const attributes = feature.feature.attributes
      const capacity = aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_CAPACITY)
      this.queryAssignments(feature).then(assignments => {
        SelectPerson.showModal({
          title: title,
          promptOptions: {
            subject: "units",
            subjectItems: this.getFeatureItems(),
            target: "person"
          },
          onOK: sel => {
            let objectId = sel && sel.objectId;
            if (typeof objectId === "number") {
              const source = officePlanUtil.getPeopleSource();
              const featureItem = queryUtil.makeFeatureItem(source, sel.feature);
              let personAssignments = null
              const setAssignments = (assignments) => {
                personAssignments = assignments
              }
              const onOK = (unassignAssignments, keepCurrentAssignment, unassignOthers) => {
                if (unassignAssignments && keepCurrentAssignment) {
                  unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
                    this.duplicatePersonToUnit(featureItem, unassignOthers, duplicatePeople)
                  }).catch(e => {
                    console.error("Couldn't remove additional assignments", e)
                  })
                } else if (unassignAssignments) {
                  unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
                    this.assignPersonToUnit(featureItem, unassignOthers, duplicatePeople)
                  }).catch(e => {
                    console.error("Couldn't remove additional assignments", e)
                  })
                } else if (keepCurrentAssignment) {
                  this.duplicatePersonToUnit(featureItem, unassignOthers)
                } else {
                  this.assignPersonToUnit(featureItem, unassignOthers)
                }
              }
              const to = i18n.spaceplanner.multipleAssignments.assignTo.unit
              showUnassignModal({
                personFeature: featureItem,
                assignToFeature: feature,
                plural: false,
                to: to,
                showKeepCurrentAssignment: true,
                onOK: onOK,
                setAssignments: setAssignments
              })
            }
          },
          capacity: capacity,
          assignments: assignments
        })
      })
    } else {
      SelectPerson.showModal({
        title: title,
        promptOptions: {
          subject: "units",
          subjectItems: this.getFeatureItems(),
          target: "person"
        },
        onOK: sel => {
          let objectId = sel && sel.objectId;
          if (typeof objectId === "number") {
            const source = officePlanUtil.getPeopleSource();
            const featureItem = queryUtil.makeFeatureItem(source, sel.feature);
            this.assignPersonToUnit(featureItem);
            // AssignPeople.showModal({
            //   assignTo: this.getFeatureItems(),
            //   personFeature: featureItem,
            //   plural: false,
            //   to: i18n.spaceplanner.multipleAssignments.assignTo.unit
            // })
            // const to = i18n.spaceplanner.multipleAssignments.assignTo.unit
            // showUnassignModal({
            //   personFeature: featureItem,
            //   assignToFeature: this.getFeatureItems(),
            //   plural: false,
            //   to: to,
            //   onOK: null
            // })
          }
        }
      });
    }
  };

  assignToNoneClicked = evt => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems())) return;
    const i18n = Context.instance.i18n;
    let title = i18n.spaceplanner.unassignPrompt.removeAssignmentsTitle;
    const featureItems = this.getFeatureItems();
    const unitFeatures = []
    if (featureItems && featureItems.length > 0) {
      featureItems.forEach(unit => {
        if (!officePlanUtil.isUnitUnassigned(unit)) unitFeatures.push(unit)
      })
    }
    let promptOptions: {
      fromName?: string,
      subject: string,
      subjectItems?: __esri.Graphic[],
      subjectItem?: __esri.Graphic
    } = {
      subject: "units",
      subjectItems: unitFeatures,
    };
    if (unitFeatures.length === 1) {
      const featureItem = unitFeatures[0];
      if (!officePlanUtil.isUnitUnassigned(featureItem)) {
        let fromName;
        const attributes = featureItem.feature.attributes;
        const areaId = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_AREA_ID);
        const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
        if (hasAreaId) {
          fromName = OfficePlan.getActive().getAreaName(areaId);
          promptOptions = {
            subject: "unit",
            subjectItem: featureItem,
            fromName: fromName
          };
        }
      }
    }

    const content = (
      <UnassignPrompt promptOptions={promptOptions} unitFeatureItems={featureItems}/>
    );
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: title,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        this.unassignUnits();
      },
      onMountOKButton: btn => {
        okButton = btn;
      }
    };
    controller.show(props, content);
  };

  assignUnitsToArea = (areaItem, areaType) => {
    const unitFeatureItems = this.getFeatureItems();
    const allAssignedToArea = unitFeatureItems.length > 1
    const guard = new TransactionGuard({featureItems: unitFeatureItems});
    const unitPromises = [], duplicatePeople = [], singleRecords = []
    unitFeatureItems.forEach(unit => {
      const unitPromise = officePlanUtil.queryPeopleAssignedToUnit(unit).then(people => {
        const peoplePromises = []
        people.forEach(person => {
          const personAttributes = getAttributes(person)
          const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
          const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
          const personPromise = getDuplicateCount(personName, personEmail).then(count => {
            if (this.canAddToDuplicates(duplicatePeople, count, personName, personEmail)) {
              duplicatePeople.push(person)
            } else {
              singleRecords.push(person)
            }
          })
          peoplePromises.push(personPromise)
        })
        return Promise.all(peoplePromises)
      })
      unitPromises.push(unitPromise)
    })
    Promise.all(unitPromises).then(() => {
      transactions
      .assignUnitsToArea(unitFeatureItems, areaItem, areaType, {
        duplicates: duplicatePeople,
        singleRecords: singleRecords
      })
      .then(result => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          allAssignedToArea: allAssignedToArea
        });
        this.onAssignment();
      })
      .catch(error => {
        guard.close();
        console.error("Error assigning unit to area", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
    }).catch(e => {
      guard.close()
      console.error("Problem querying duplicate occupants", e)
      Topic.publishErrorUpdatingData(e.submessage)
    })
  };

  canAssign = (unitFeatureItems, type?) => {
    if (type === "assignToPerson") {
      return unitFeatureItems && unitFeatureItems.length === 1;
    } else {
      return (
        unitFeatureItems &&
        unitFeatureItems.length > 0 &&
        unitFeatureItems.length <= this.getMaxFeatureItems()
      );
    }
  };

  canUnassign = featureItems => {
    if (!featureItems || featureItems.length === 0) {
      return false;
    } else if (this.props.disableUnassign) {
      return false;
    } else if (this.props.enableUnassign) {
      return true;
    }
    const nv = OfficePlan.SpaceAssignmentTypes.none;
    for (let i = 0; i < featureItems.length; i++) {
      const attributes = featureItems[i].feature.attributes;
      const areaId = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_AREA_ID
      );
      const spaceAssignment = aiimUtil.getAttributeValue(
        attributes,
        FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE
      );
      if (areaId || (spaceAssignment && spaceAssignment !== nv)) return true;
    }
    return false;
  };

  // canUnassign = (featureItems) => {
  //   if (
  //     this.props.disableUnassign !== undefined
  //   ) {
  //     return this.props.disableUnassign;
  //   } else if (
  //     this.props.enableUnassign !== undefined
  //   ) {
  //     return this.props.enableUnassign;
  //   }
  //   if (!featureItems || featureItems.length === 0) {
  //     return false;
  //   }
  //   const nv = OfficePlan.SpaceAssignmentTypes.none;
  //   for (let i = 0; i < featureItems.length; i++) {
  //     const attributes = featureItems[i].feature.attributes;
  //     const areaId = aiimUtil.getAttributeValue(
  //       attributes,
  //       FieldNames.UNITS_AREA_ID
  //     );
  //     const spaceAssignment = aiimUtil.getAttributeValue(
  //       attributes,
  //       FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE
  //     );
  //     if (areaId || spaceAssignment !== nv) return true;
  //   }
  //   return false;
  // };

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this);
  }

  ensureUnitGeometry = unitFeatureItem => {
    const source = officePlanUtil.getUnitsSource();
    return queryUtil.ensureGeometry(source, unitFeatureItem);
  };

  getFeatureItems = () => {
    return this.props.featureItems;
  };

  getMaxFeatureItems = () => {
    return 1001;
  };

  onAssignment = () => {
    if (typeof this.props.onAssignment === "function") {
      this.props.onAssignment();
    }
  };

  renderButtons = () => {
    const i18n = Context.instance.i18n;
    const featureItems = this.getFeatureItems();
    const hasUnassignButton = this.canUnassign(featureItems);
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    let captionKey = "captionSingular";
    if (featureItems && featureItems.length > 1) captionKey = "captionPlural";

    return (
      <>
        <Button
          onClick={evt =>
            this.assignToPersonClicked(
              i18n.spaceplanner.units.assignTo.person.captionSingular,
              evt
            )
          }
          icon={<PeopleIcon size={16} />}
          iconPosition="before"
          data-tip={this.state.personTip}
          data-for="genericTooltip"
        />
        {rdxHotdesksEnabled &&
          <Button
            onClick={evt =>
              this.assignToAreaClicked(
                i18n.spaceplanner.units.assignTo.hotdesk[captionKey],
                OfficePlan.SpaceAssignmentTypes.hotdesk,
                evt
              )
            }
            icon={<HotDeskIcon size={16} />}
            iconPosition="before"
            data-tip={i18n.spaceplanner.units.assignTo.hotdesk.label}
            data-for="genericTooltip"
          />
        }
        {rdxHotelsEnabled &&
          <Button
            onClick={evt =>
              this.assignToAreaClicked(
                i18n.spaceplanner.units.assignTo.hotel[captionKey],
                OfficePlan.SpaceAssignmentTypes.hotel,
                evt
              )
            }
            icon={<HotelIcon size={16} />}
            iconPosition="before"
            data-tip={i18n.spaceplanner.units.assignTo.hotel.label}
            data-for="genericTooltip"
          />
        }
        {rdxMeetingRoomsEnabled &&
          <Button
            onClick={evt =>
              this.assignToAreaClicked(
                i18n.spaceplanner.units.assignTo.meetingroom[captionKey],
                OfficePlan.SpaceAssignmentTypes.meetingroom,
                evt
              )
            }
            icon={<MeetingRoomIcon size={16} />}
            iconPosition="before"
            data-tip={i18n.spaceplanner.units.assignTo.meetingroom.label}
            data-for="genericTooltip"
          />
        }
        {hasUnassignButton ? (
          <Button
            clear
            icon={<UnassignIcon size={16} />}
            iconPosition="before"
            onClick={evt => this.assignToNoneClicked(evt)}
            data-tip={i18n.spaceplanner.unassign}
            data-for="genericTooltip"
          />
        ) : null}
      </>
    );
  };

  renderCombo = () => {
    const i18n = Context.instance.i18n;
    const featureItems = this.getFeatureItems();
    const disabled = !this.canAssign(featureItems);
    const hasUnassignButton = this.canUnassign(featureItems);
    const { rdxFeatureItemsKey, rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    let captionKey = "captionSingular";
    if (featureItems && featureItems.length > 1) captionKey = "captionPlural";

    const options = [];
    if (this.canAssign(this.getFeatureItems(), "assignToPerson")) {
      options.push({
        label: i18n.spaceplanner.units.assignTo.person.label,
        caption: i18n.spaceplanner.units.assignTo.person.captionSingular,
        value: "assignToPerson"
      });
    }
    rdxHotdesksEnabled && options.push({
      label: i18n.spaceplanner.units.assignTo.hotdesk.label,
      caption: i18n.spaceplanner.units.assignTo.hotdesk[captionKey],
      value: "assignToHotDesk"
    });
    rdxHotelsEnabled && options.push({
      label: i18n.spaceplanner.units.assignTo.hotel.label,
      caption: i18n.spaceplanner.units.assignTo.hotel[captionKey],
      value: "assignToHotel"
    });
    rdxMeetingRoomsEnabled && options.push({
      label: i18n.spaceplanner.units.assignTo.meetingroom.label,
      caption: i18n.spaceplanner.units.assignTo.meetingroom[captionKey],
      value: "assignToMeetingRoom"
    });

    const menuItems = [];
    options.forEach(option => {
      menuItems.push(
        <MenuItem
          key={option.value}
          onClick={evt => this.assignToClicked(evt, option)}
        >
          {option.label}
        </MenuItem>
      );
    });

    return (
      <AssignToContainer noWrap={this.props.noWrap} noMargin={this.props.noMargin}>
        {(rdxFeatureItemsKey == null || menuItems.length > 0) &&
          <ComboButton
            label={i18n.spaceplanner.units.assignTo.label}
            disabled={disabled || menuItems.length === 0}
            onClick={() => {
              if (this.canAssign(this.getFeatureItems(), "assignToPerson")) {
                this.assignToPersonClicked(i18n.spaceplanner.units.assignTo.person.captionSingular);
              } else {
                let type = null, msg = null;
                if (rdxHotdesksEnabled) {
                  msg = i18n.spaceplanner.units.assignTo.hotdesk[captionKey];
                  type = OfficePlan.SpaceAssignmentTypes.hotdesk;
                } else if (rdxHotelsEnabled) {
                  msg = i18n.spaceplanner.units.assignTo.hotel[captionKey];
                  type = OfficePlan.SpaceAssignmentTypes.hotel;
                } else if (rdxMeetingRoomsEnabled) {
                  msg = i18n.spaceplanner.units.assignTo.meetingroom[captionKey];
                  type = OfficePlan.SpaceAssignmentTypes.meetingroom;
                }
                if (type) {
                  this.assignToAreaClicked(msg,type);
                }
              }
            }}
          >
            <Menu>{menuItems}</Menu>
          </ComboButton>
        }
        {hasUnassignButton ? (
          <Button
            clear
            disabled={disabled}
            noWrap={this.props.noWrap}
            onClick={evt => this.assignToNoneClicked(evt)}
          >
            {i18n.spaceplanner.unassign}
          </Button>
        ) : null}
      </AssignToContainer>
    );
  };

  renderPopupAssignButton = () => {
    const i18n = Context.instance.i18n
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    const btn = (
      <Button className={"i-assign-btn"}
        onClick={this.togglePopover}>
       <span> {i18n.spaceplanner.popup.assignBtn} </span>
      </Button>
    )

    const makeMenu = () => {
      const type = this.props.type
      const tab = this.props.tab
      if (!type) return null

      const style = { maxWidth: '10rem' }
      const personCaption = i18n.spaceplanner.units.assignTo.person.captionSingular
      const hotdeskCaption = i18n.spaceplanner.units.assignTo.hotdesk.captionSingular
      const hotelCaption = i18n.spaceplanner.units.assignTo.hotel.captionSingular
      const meetingRoomCaption = i18n.spaceplanner.units.assignTo.meetingroom.captionSingular
      if (type === "unit") {
        return (
          <Menu style={style}>
            <MenuItem onClick={e => {
                this.assignToClicked(e, {value: "assignToPerson", caption: personCaption})
              }}>{i18n.spaceplanner.units.assignTo.person.label}</MenuItem>
            {rdxHotdesksEnabled &&
              <MenuItem onClick={e => {
                this.assignToClicked(e, { value: "assignToHotDesk", caption: hotdeskCaption })
              }}>{i18n.spaceplanner.units.assignTo.hotdesk.label}</MenuItem>
            }
            {rdxHotelsEnabled &&
              <MenuItem onClick={e => {
                this.assignToClicked(e, { value: "assignToHotel", caption: hotelCaption })
              }}>{i18n.spaceplanner.units.assignTo.hotel.label}</MenuItem>
            }
            {rdxMeetingRoomsEnabled &&
              <MenuItem onClick={e => {
                this.assignToClicked(e, { value: "assignToMeetingRoom", caption: meetingRoomCaption })
              }}>{i18n.spaceplanner.units.assignTo.meetingroom.label}</MenuItem>
            }
          </Menu>
        )
      }
    }

    return (
      <Popover
        targetEl={btn}
        open={this.state.menuOpen}
        onRequestClose={this.closePopover}
        positionFixed
      >
        {makeMenu()}
      </Popover>
    )
  }

  render() {
    if (!!this.props.isPopup) {
      return this.renderPopupAssignButton()
    } else {
      const { isButtons } = this.state;
      return isButtons ? this.renderButtons() : this.renderCombo();
    }
  }

  canAddToDuplicates = (duplicatePeople, totalCount, personName, personEmail) => {
    if (totalCount <= 1) return false
    let currentCount = 0
    duplicatePeople.forEach(person => {
      const personAttributes = getAttributes(person)
      const name = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const email = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      if (personName === name && personEmail === email) currentCount++
    })
    if (currentCount === (totalCount - 1)) return false
    else if (currentCount < (totalCount - 1)) return true
    else {
      console.warn("Inconsistent person assignments count")
      return false
    }
  }

  unassignUnits = () => {
    const featureItems = this.getFeatureItems();
    if (!this.canAssign(featureItems)) return;
    const guard = new TransactionGuard({featureItems: featureItems});
    const unitPromises = [], duplicatePeople = [], singleRecords = []
    featureItems.forEach(unit => {
      const unitPromise = officePlanUtil.queryPeopleAssignedToUnit(unit).then(people => {
        const peoplePromises = []
        people.forEach(person => {
          const personAttributes = getAttributes(person)
          const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
          const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
          const personPromise = getDuplicateCount(personName, personEmail).then(count => {
            if (this.canAddToDuplicates(duplicatePeople, count, personName, personEmail)) {
              duplicatePeople.push(person)
            } else {
              singleRecords.push(person)
            }
          })
          peoplePromises.push(personPromise)
        })
        return Promise.all(peoplePromises)
      })
      unitPromises.push(unitPromise)
    })
    Promise.all(unitPromises).then(() => {
      transactions
      .unassignUnits(featureItems, {duplicates: duplicatePeople, singleRecords: singleRecords})
      .then(result => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
        this.onAssignment();
      })
      .catch(error => {
        guard.close();
        console.error("Error unassigning unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
    }).catch(e => {
      guard.close()
      console.error("Problem querying duplicate occupants", e)
      Topic.publishErrorUpdatingData(e.submessage)
    })
  };
}

const mapStateToProps = (state, ownProps) => {
  const rdxFeatureItemsKey = ownProps.rdxFeatureItemsKey;
  const props = {
    rdxHotdesksEnabled: getHotdesksEnabled(state),
    rdxHotelsEnabled: getHotelsEnabled(state),
    rdxMeetingRoomsEnabled: getMeetingRoomsEnabled(state)
  };
  if (rdxFeatureItemsKey && !ownProps.featureItems) {
    return { ...props, featureItems: Rdx.getValue(state, rdxFeatureItemsKey) };
  } else {
    return { ...props };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsAssignTo);
