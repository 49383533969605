import Context from "../../context/Context";
import NetworkService from "./NetworkService";

export default class RouteService extends NetworkService {

  info = {};

  getLayerUrl() {
    return this.info.layerUrl;
  }

  load() {
    const promise = new Promise((resolve,reject) => {
      const task = {
        isRoute: true,
        itemId: null,
        item: null,
        serviceUrl: null,
        serviceInfo: null,
        layerName: null,
        layerUrl: null,
        layerInfo: null
      };
      const defaultLayerName = "Route";
      const config = Context.instance.config;

      //config.routeService.itemId = "b09068638fb146bfac3a281d918cbf05";

      if (config.routeService && config.routeService.itemId) {
        task.itemId = config.routeService.itemId;
        task.layerName = config.routeService.layerName;
      } else if (config.networkServiceUrl) {
        const info = this._parseUrl(config.networkServiceUrl,defaultLayerName);
        Object.assign(task,info);
      }

      this._load(task).then(() => {
        if (task.layerUrl) config.networkServiceUrl = task.layerUrl;
        this.info = task;
        resolve();
      }).catch(ex => {
        // TODO inform user?
        console.warn("Error loading Route service");
        resolve();
      });
    });
    return promise;
  }

}
