import React from 'react';

// components.common.Toggle
import Toggle from '../../../components/common/Toggle/Toggle';
import Context from '../../../context/Context';

// styles
import { 
  UserSettingsContainer, 
  UserSettingsHeader,
  UserSettingsLabel,
  UserSettingsDescription,
  ConfigureAppButton
} from '../../styles/userSettingsStyles';

// util
import { userSettingKeys, setUserSetting } from '../../../util/localStore';
import Topic from '../../../context/Topic';
import Button from 'calcite-react/Button';
export default class UserSettingsPopup extends React.Component {
  userSettings;

  constructor() {
    super();
    const session = Context.getInstance().session;
    const userSettings = session && session.userSettings;

    this.userSettings = userSettings;
    const { enablePopups } = userSettings;
    this.state = {
      enablePopups
    }
  }

  onSettingChange = (checked, key) => {
    switch (key) {
      case userSettingKeys.enablePopups:
        this.userSettings.enablePopups = checked;
        setUserSetting(key, checked);
        this.setState({
          enablePopups: checked
        });
        break;
      default:
        return;
    }
  }

  render() {
    const { enablePopups } = this.state;
    const i18n = Context.getInstance().i18n;
    const canConfigure = Context.instance.user.canConfigureApp();

    return (
      <UserSettingsContainer>
        <UserSettingsHeader>{i18n.spaceplanner.userSettings.title}</UserSettingsHeader>
        <UserSettingsLabel>
          <h4>{i18n.spaceplanner.userSettings.enablePopupsLabel}</h4>
          <Toggle
            checked={enablePopups}
            onChange={(e) => this.onSettingChange(e, userSettingKeys.enablePopups)}
          />
        </UserSettingsLabel>
        <UserSettingsDescription>
          {i18n.spaceplanner.userSettings.enablePopupsDescription}
        </UserSettingsDescription>
        {canConfigure && 
        <ConfigureAppButton
          onClick={()=> {
            const uiMode = Context.instance.uiMode
            setTimeout(() => {
              this.props.closeBackstage();
              uiMode.toggleConfigurator(true);
              Topic.publish(Topic.ConfiguratorActivated,{});
            },10);
        }}> {i18n.configurator.caption} </ConfigureAppButton>}
      </UserSettingsContainer>
    );
  }
}