import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import OfficePlan from "../../../base/OfficePlan";
import SelectTool from "../support/SelectTool";
import StencilLoader, { FpeType} from "../support/StencilLoader";
import StencilMover from "../support/StencilMover";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as duplicateUtil from "../support/duplicateUtil";
import * as editorUtil from "../support/editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as stencilUtil from "../support/stencilUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as val from "../../../../util/val";
import * as wallTypesUtil from "../../../miniapps/configurator/wallTypesUtil";
import { IEditFeatureResult } from "@esri/arcgis-rest-feature-layer";
import { UpdateDetailsTask } from "../../../base/transaction/details";

export interface IActiveFeatureItem extends IEditFeatureResult {
  feature: __esri.Graphic
}
export interface IPlaceDetailsVMProps {
  fpeType: FpeType,
  onIntersectingWalls?: (hasIntersections: boolean) => void
}
/** Snapping tolerance for intersecting wall segments in map units. Default is .1 meter (3.937008 inches). */
const GAP_TOLERANCE = .1;

export default class PlaceDetailsVM extends BaseVM {

  activeItem: stencilUtil.IStencilItem;
  activeFeatureItem: IEditFeatureResult & { feature?: __esri.Graphic };
  cutWalls: boolean = false;
  fpeType?: IPlaceDetailsVMProps["fpeType"];
  onIntersectingWalls: (hasIntersections: boolean) => {};
  selectTool?: SelectTool;

  activeSketchViewModel: __esri.SketchViewModel;
  private resultGraphicLayerId = "indoors-place-details-result";
  private svmGraphicLayerId = "indoors-place-details-svn";
  private wallsGraphicLayerId = "indoors-place-details-intersecting-walls";

  private _processing: boolean;

  constructor(props: IPlaceDetailsVMProps) {
    super(props);
    this.mixinProps(props);
    this.initSelectTool();
  }

  activateTool(tool,stencil) {
    if (tool === "create") {
      this.activateCreate(stencil);
    } else if (tool === "select") {
      this.activateSelect();
    }
  }

  activateCreate(stencil) {
    this.cancelSketch();
    if (!stencil) return;
    const lib = Context.instance.lib;
    const view = this.getView();
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this.svmGraphicLayerId);
    mapUtil.ensureGraphicsLayer(view,this.resultGraphicLayerId);
    this.clearGraphics();
    this.setActiveFeatureVisibility(true)

    let svm = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      snappingOptions: this.makeSnappingOptions()
    });

    const stencilMover = new StencilMover();
    stencilMover.setupCreate(svm,stencil,view);

    const createTool = "point";
    const createOptions = {
      mode: "click",
      hasZ: true,
      defaultZ: 0
    }

    const process = async (event) => {
      const point = event.graphic.geometry;
      const screenPoint = view.toScreen(point);
      const levelsDataset = Context.instance.aiim.datasets.levels;
      const levelData = await levelsDataset.queryLevelDataByPoint(point);
      if (!levelData) {
        this.showNoUnderlyingLevel();
        this.activateCreate(stencil);
        return;
      } 
        
      const z = levelData.z;
      let lastOR = stencilMover._offsetResult;
      const segments = await stencilUtil.queryHit(point,screenPoint)
      if (!lastOR || (segments && segments.length > 0)) {
        let prev;
        if (!lastOR && (!segments || segments.length == 0)) {
          const viewAngle = (view as __esri.MapView).rotation;
          if (typeof viewAngle === "number" && !isNaN(viewAngle) && isFinite(viewAngle) && viewAngle > 0) {
            prev = {
              rotationAngle: viewAngle,
              reflect: false,
              flip: false
            }
          }
        }
        lastOR = stencilUtil.offsetStencilGeometry(point,stencil,segments,z,prev);
      }
      const offsetResult = {
        anchorPoint1: lastOR.anchorPoint1,
        geometry: lastOR.geometry,
        rotationAngle: lastOR.rotationAngle,
        reflect: lastOR.reflect,
        flip: lastOR.flip
      }
      const graphic = this.highlight(offsetResult.geometry);
      this.activeItem = stencilUtil.newStencilItem({
        stencil: stencil,
        graphic: graphic,
        anchorPoint1: offsetResult.anchorPoint1,
        rotationAngle: offsetResult.rotationAngle,
        reflect: offsetResult.reflect,
        flip: offsetResult.flip,
        z: z
      });
      this.activeFeatureItem = null;

      const newFeature = new Context.instance.lib.esri.Graphic({
        geometry: offsetResult.geometry.clone()
      });
      newFeature.geometry.hasZ = true;
      this.initNewDetail(stencil,levelData,newFeature)
      await this.save("add",newFeature)
    }

    svm.on("create",event => {
      if (event.state === "complete") {
        stencilMover.clear();
        process(event);
      }
      if (event.state === "cancel") {
        stencilMover.clear();
      }
    });

    Context.instance.views.toggleClickHandlers("pause");
    svm.create(createTool,createOptions);
  }

  activateSelect() {
    this.setActiveFeatureVisibility(true);
    this.cancelSketch();
    this.clearGraphics();
    this.onIntersectingWalls(false);
    //this.activeItem = null;
    //this.activeFeatureItem = null;
  }

  activateUpdate(reuseGraphic?) {
    const activeItem = this.activeItem;
    const graphic = reuseGraphic || (activeItem && activeItem.graphic);
    if (!graphic) return;

    this.cancelSketch();
    const lib = Context.instance.lib;
    const view = this.getView();
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this.resultGraphicLayerId);
    graphicsLayer.graphics.removeAll();
    graphicsLayer.graphics.add(graphic);
    this.setActiveFeatureVisibility(false);

    let svm = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      snappingOptions: this.makeSnappingOptions()
    });

    let stencilMover;
    if (activeItem.stencil) {
      stencilMover = new StencilMover();
      svm._getGraphicMover = (graphics,options,view) => {
        return stencilMover.getGraphicMover(svm,activeItem,graphics,options,view);
      }
    }

    const updateOptions = {
      tool: "move", // "transform"|"reshape"|"move"
      enableRotation: false,
      enableScaling: false,
      preserveAspectRatio: false,
      toggleToolOnClick: false,
      enableZ: false
    }
    if (!activeItem.stencil) {
      updateOptions.tool = "transform";
      updateOptions.enableScaling = true;
      updateOptions.enableRotation = true;
      updateOptions.toggleToolOnClick = true;
      updateOptions.enableZ = true;
      //updateOptions.defaultZ = activeItem.z;
    }
    svm.defaultUpdateOptions = updateOptions;

    const activateSel = () => {
      this.setActiveFeatureVisibility(true);
      this.clear();
      this.activateSelect();
      this.onToolActivated("select",null);
    }

    let processing = false, lastState, ignoreLast = false;

    const process = async (event) => {
      const offsetResult = event.xtnOffsetResult;
      if (offsetResult) {

        const levelsDataset = Context.instance.aiim.datasets.levels;
        const levelDataList = await levelsDataset.queryLevelDataByGeometry(offsetResult.geometry);
        if (!levelDataList || levelDataList.length === 0) {
          if (stencilMover) stencilMover.clear();
          activeItem.stencil = null;
          this.showNoUnderlyingLevel();
          this.activateUpdate(event.graphic);
          return;
        }
        Topic.publish(Topic.ClearToast,{});

        this.clearGraphics();
        const stencil = activeItem.stencil;
        const graphic = this.highlight(offsetResult.geometry);
        this.activeItem = stencilUtil.newStencilItem({
          stencil: stencil,
          graphic: graphic,
          anchorPoint1: offsetResult.anchorPoint1,
          rotationAngle: offsetResult.rotationAngle,
          reflect: offsetResult.reflect,
          flip: offsetResult.flip,
          z: activeItem.z
        })

        const updateFeature = new Context.instance.lib.esri.Graphic({
          attributes: Object.assign({},this.activeFeatureItem.feature.attributes),
          geometry: graphic.geometry.clone()
        });
        updateFeature.geometry.hasZ = true;
        this.updateLevel(this.activeItem,updateFeature,levelDataList[0]);
        this.activateUpdate();
        this.onToolActivated("update",graphic);
        this.save("update",updateFeature)
        if (stencilMover) stencilMover.clear();
      }
    }

    const process2 = async () => {
      const levelsDataset = Context.instance.aiim.datasets.levels;
      const levelDataList = await levelsDataset.queryLevelDataByGeometry(graphic.geometry);
      if (!levelDataList || levelDataList.length === 0) {
        this.showNoUnderlyingLevel();
        return;
      }

      Topic.publish(Topic.ClearToast,{});
      const updateFeature = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},this.activeFeatureItem.feature.attributes),
        geometry: graphic.geometry.clone()
      });
      editorUtil.updateZ(updateFeature.geometry, activeItem.z);
      this.updateLevel(this.activeItem,updateFeature,levelDataList[0]);
      this.save("update",updateFeature)
    }

    svm.on("update",event => {
      const tei = event.toolEventInfo;
      const teiType = tei && tei.type;
      //console.log("update.event.state",event.state,"eventInfo.type",teiType,event);

      if (activeItem.stencil) {
        if (teiType === "move-stop") {
          processing = true;
          process(event);
          this.cutWalls === true && this.showIntersectingWalls();
        }
      } else {
        if (teiType === "move-stop" || teiType === "rotate-stop" || teiType === "reshape-stop" || teiType === "scale-stop") {
          ignoreLast = true;
          lastState = "start";
          this.onUpdateStateChanged("start");
          process2();
          this.cutWalls === true && this.showIntersectingWalls();
        }
      }

      if (event.state === "complete") {
        if (stencilMover) stencilMover.clear();
        if (!processing && !svm.xtnCanceling) {
          activateSel();
        }
      }
      if (event.state === "cancel") {
        if (stencilMover) stencilMover.clear();
        if (!processing && !svm.xtnCanceling) {
          activateSel();
        }
      }
      if (ignoreLast) {
        ignoreLast = false;
      } else if (event.state !== lastState && event.state !== "complete") {
        lastState = event.state;
        this.onUpdateStateChanged(event.state);
      }
    });
    
    this.cutWalls === true && this.showIntersectingWalls();
    Context.instance.views.toggleClickHandlers("pause");
    svm.update([graphic],updateOptions);
  }

  cancelSketch() {
    const view = this.getView();
    const svm = this.activeSketchViewModel;
    if (svm) {
      // @ts-ignore
      svm.xtnCanceling = true;
      svm.cancel();
      svm.destroy();
      this.activeSketchViewModel = null;
      Context.instance.views.toggleClickHandlers("resume");
    }
    mapUtil.removeAllGraphics(view,this.svmGraphicLayerId);
  }

  clear() {
    this.cancelSketch();
    this.clearGraphics();
  }

  clearGraphics() {
    const view = this.getView();
    mapUtil.removeAllGraphics(view, this.svmGraphicLayerId);
    mapUtil.removeAllGraphics(view, this.resultGraphicLayerId);
    mapUtil.removeAllGraphics(view, this.wallsGraphicLayerId);
  }

  destroy() {
    if (this.selectTool) this.selectTool.destroy();
    super.destroy();
  }

  async executeAction(tool:"flipH"|"flipV"|"update", newGeometry?:__esri.Geometry) {
    // flipH flipV rotate
    const ge = Context.instance.lib.esri.geometryEngine;
    const activeItem = this.activeItem;
    const geometry = activeItem && activeItem.graphic && activeItem.graphic.geometry;
    if (geometry && !newGeometry) {
      if (tool === "flipH") {
        newGeometry = ge.flipHorizontal(geometry);
      } else if (tool === "flipV") {
        newGeometry = ge.flipVertical(geometry);
      }
    }
    if (newGeometry) {
      const updateFeature = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},this.activeFeatureItem.feature.attributes),
        geometry: newGeometry.clone()
      });
      updateFeature.geometry.hasZ = true;
      activeItem.graphic.geometry = newGeometry;
      this.activeItem = stencilUtil.newStencilItem({
        graphic: activeItem.graphic,
        z: activeItem.z
      });
      this.activateUpdate();
      this.onToolActivated("update",activeItem.graphic);
      await this.save("update",updateFeature);
    }
  }

  async executeDelete() {
    await this.save("delete",null);
  }

  async executeDuplicate() {
    const activeGraphic = this.activeItem && this.activeItem.graphic;
    if (activeGraphic) {
      const z = this.activeItem.z;
      const g = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},this.activeFeatureItem.feature.attributes),
        geometry: activeGraphic.geometry.clone()
      });
      const dup = await duplicateUtil.createDuplicateFeature(g,"detail");
      const newGraphic = Context.instance.lib.esri.Graphic.fromJSON(dup.feature);
      newGraphic.geometry.hasZ = true;
      const newFeature = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},newGraphic.attributes),
        geometry: newGraphic.geometry.clone()
      });
      newFeature.geometry.hasZ = true;
      this.setActiveFeatureVisibility(true);
      this.activeItem = stencilUtil.newStencilItem({
        graphic: newGraphic,
        z: z
      });
      this.activeFeatureItem = null;
      await this.save("add",newFeature)
    }
  }

  async executeReflect(flip) {
    const activeItem = this.activeItem;
    const stencil = activeItem && activeItem.stencil;
    let geometry = activeItem && activeItem.graphic && activeItem.graphic.geometry;
    if (geometry && stencil && stencilUtil.supportsReflect(stencil)) {
      let graphic = activeItem.graphic.clone();
      graphic.geometry.hasZ = true;
      const stencilProps = Object.assign(activeItem,{});
      let insitu = stencilUtil.insitu(stencilProps,graphic);
      if (!flip) {
        if (typeof stencilProps.reflect === "boolean") {
          stencilProps.reflect = !stencilProps.reflect;
        } else {
          stencilProps.reflect = true;
        }
      } else {
        if (typeof stencilProps.flip === "boolean") {
          stencilProps.flip = !stencilProps.flip;
        } else {
          stencilProps.flip = true;
        }        
      }
      let insitu2 = stencilUtil.insitu(stencilProps);
      let translated = stencilUtil.translateInsitu(stencilProps,insitu2,insitu.dx,insitu.dy);
      stencilProps.anchorPoint1 = translated.anchor1;
      graphic = stencilProps.graphic = this.highlight(translated.geometry)
      this.activeItem = stencilProps;
      const updateFeature = new Context.instance.lib.esri.Graphic({
        attributes: Object.assign({},this.activeFeatureItem.feature.attributes),
        geometry: graphic.geometry.clone()
      });
      updateFeature.geometry.hasZ = true;
      this.activateUpdate();
      this.onToolActivated("update",graphic);
      await this.save("update",updateFeature)
    }
  }

  private getWallIntersections(geometry: __esri.Polyline, walls: __esri.Graphic[])
    : { intersection: __esri.Polyline, difference: __esri.Polyline, original: __esri.Graphic }[] {
    const { lib } = Context.getInstance();
    const engine: __esri.geometryEngine = lib.esri.geometryEngine;
    return walls.map(wall => {
      const result = { original: wall, intersection: null, difference: null };
      // using convexHull gives a little more precision to the clipped result than using extent
      const hull = engine.convexHull(geometry) as __esri.Polygon;
      const clipped = engine.intersect(wall.geometry, hull) as __esri.Polyline;
      result.intersection = clipped;
      
      if (clipped && clipped.paths && clipped.paths.length) {
        result.difference = engine.difference(wall.geometry, result.intersection) as __esri.Polyline;
      }
      return result;
    });
  }
  /** Returns detail features (walls) that intersect with the input geometry. */
  private getWalls(geometry: __esri.Geometry) {
    const detailsLv = mapUtil.getDetailsLayerView();
    const details = sourceUtil.getDetailsLayer();
    const query = detailsLv.createQuery();
    query.returnZ = true;
    query.returnGeometry = true;
    query.outFields = ["*"];
    query.distance = GAP_TOLERANCE;
    query.geometry = geometry.extent;
    return details.queryFeatures(query).then(fs => {
      const walls = fs.features.filter(f => {
        const useType: string = aiimUtil.getAttributeValue(f.attributes, FieldNames.DETAILS_USE_TYPE);
        if (useType && this.getWallTypes().includes(useType)) {
          return true;
        } else {
          return false;
        }
      });
      return walls;
    });
  }
  // FIXME Link up with configurator
  /** Default wall types based on configured use_type field values. */
  private getWallTypes() {
    const wallTypeValues = Context.instance.config.spaceplanner.wallTypeValues;
    if(!wallTypeValues || (wallTypeValues && wallTypeValues.length === 0)) return [];
    return wallTypeValues;
  }

  highlight(polyline) {
    const view = this.getView();
    const layer = mapUtil.ensureGraphicsLayer(view,this.resultGraphicLayerId);
    const graphic = new Context.instance.lib.esri.Graphic({
      geometry: polyline,
      symbol: stencilUtil.makeLineSymbol()
    })
    layer.graphics.add(graphic);
    return graphic;
  }

  initNewDetail = (stencil,levelData,newDetail) => {
    const layer = sourceUtil.getDetailsLayer();
    if (layer) {
      const attributes = {};
      layer.fields.forEach(f => {
        if (f.editable) {
          attributes[f.name] = null;
        }
      })
      let heightRel = 0, levelId = null;
      const detailIdField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAIL_ID)
      const levelIdField = aiimUtil.findFieldName(layer.fields,FieldNames.LEVEL_ID)
      const heightRelField = aiimUtil.findFieldName(layer.fields,FieldNames.HEIGHT_RELATIVE)
      const useTypeField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAILS_USE_TYPE)
      const detailId = val.generateRandomId();
      if (levelData) {
        levelId = levelData.levelId;
        heightRel = levelData.heightRelative;
      }
      delete attributes[layer.objectIdField];
      // @ts-ignore
      delete attributes[layer.globalIdField];
      attributes[detailIdField] = detailId; // @todo
      attributes[levelIdField] = levelId;
      if (heightRelField && typeof heightRel === "number") {
        attributes[heightRelField] = heightRel;
      }
      if (useTypeField) {
        attributes[useTypeField] = stencil.detailUseType;
      }
      aiimUtil.removeShapeAttributes(attributes);
      newDetail.attributes = attributes;
    }
  }

  initSelectTool() {
    this.selectTool = new SelectTool();
    this.selectTool.fpeType = this.fpeType;
    this.selectTool.onSelect = (featureItem) => {
      try {
        const graphic = this.highlight(featureItem.feature.geometry);
        this.activeItem = stencilUtil.newStencilItem({
          stencil: null,
          graphic: graphic,
          anchorPoint1: null,
          rotationAngle: null,
          reflect: null,
          flip: null,
          z: featureItem.feature.geometry.extent.center.z
        });
        this.activeFeatureItem = featureItem;
        //console.log("PlaceDetails.selItem",featureItem)
        setTimeout(() => {
          this.activateUpdate();
          this.onToolActivated("update",graphic); 
        },50)
        console.log("activateSel",featureItem)
      } catch(ex) {
        console.error(ex);
      }
    }
  }

  loadPalette = async () => {
    const loader = new StencilLoader();
    const paletteInfo = await loader.load(this.fpeType);
    const stencils = paletteInfo && paletteInfo.stencils;
    const detailUseTypes = [];
    stencils && stencils.forEach(stencil => {
      const v = stencil.detailUseType;
      if (v && detailUseTypes.indexOf(v) === -1) detailUseTypes.push(v);
    })
    if (this.selectTool) this.selectTool.detailUseTypes = detailUseTypes;
    return paletteInfo;
  }

  onToolActivated(tool: "update"|"select", graphic:__esri.Graphic) {}

  onUpdateStateChanged(updateState) {}

  async removeIntersectingWalls() {
    const guard = new TransactionGuard({ force: true, minimal: true });
    try {
      if (this.cutWalls && (
        (this.activeItem && this.activeItem.graphic) ||
        (this.activeFeatureItem && this.activeFeatureItem.feature))) {
        const { lib } = Context.getInstance();
        const feature = this.activeItem && this.activeItem.graphic
          ? this.activeItem.graphic
          : this.activeFeatureItem.feature;
        const wallFeatures = await this.getWalls(feature.geometry);
        const results = this.getWallIntersections(feature.geometry as __esri.Polyline, wallFeatures);
        if (results.length === 0) {
          this.onIntersectingWalls(false);
        } else {
          const updates = [];
          const previous = [];
          results.forEach(result => {
            const feature = result.original.clone();
            feature.geometry = result.difference ? result.difference.clone() : null;
            updates.push(feature);
            previous.push(result.original);
          });
          const info: UpdateDetailsTask["info"] = {
            action: "update",
            updates,
            undoUpdates: previous
          }
          await transactions.editDetail(info);
          this.activateSelect();
          this.onToolActivated("select", null);
        }
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
        guard.close();
      }
    } catch (ex) {
      console.error(ex);
      guard.close();
      Topic.publishErrorUpdatingData(ex.message);
      this.clear();
      this.activeItem = this.activeFeatureItem = null;
      this.activateSelect();
      this.onToolActivated("select", null);
    } finally {
      this.cutWalls && this.showIntersectingWalls();
    }
  }

  save = async (action: "add" | "update" | "delete", feature: __esri.Graphic) => {
    const guard = new TransactionGuard({ force: true, minimal: true });
    try {
      this._processing = true;
      if (action === "add") {
        const info: UpdateDetailsTask["info"] = {
          action: "add",
          feature: feature
        }
        const result = await transactions.editDetail(info);
        if (result && result.length === 1 && result[0].addResults && result[0].addResults.length === 1) {
          const activeFeatureItem = result[0].addResults[0] as IActiveFeatureItem;
          activeFeatureItem.feature = await officePlanUtil.queryDetailByObjectId(activeFeatureItem.objectId);
          this.activeFeatureItem = activeFeatureItem;
          this.activateUpdate();
          this.onToolActivated("update", this.activeItem.graphic);
        }
      } else if (action === "update") {
        const activeFeatureItem = this.activeFeatureItem;
        const info: UpdateDetailsTask["info"] = {
          action: "update",
          feature: feature,
          prevFeature: this.activeFeatureItem.feature
        }
        await transactions.editDetail(info);
        activeFeatureItem.feature = await officePlanUtil.queryDetailByObjectId(activeFeatureItem.objectId);
        //this.activateUpdate();
        //this.onToolActivated("update",this.activeItem.graphic);
      } else if (action === "delete") {
        const info: UpdateDetailsTask["info"] = {
          action: "delete",
          feature: this.activeFeatureItem.feature
        }
        await transactions.editDetail(info);
        this.clear();
        this.activeItem = this.activeFeatureItem = null;
        this.activateSelect();
        this.onToolActivated("select", null);
      }

      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated
      });
      guard.close();
    } catch(ex) {
      console.error(ex);
      guard.close();
      Topic.publishErrorUpdatingData(ex.message);
      this.clear();
      this.activeItem = this.activeFeatureItem = null;
      this.activateSelect();
      this.onToolActivated("select",null);
    }
  }

  setActiveFeatureVisibility(visible) {
    if (this.activeFeatureItem) {
      const oid = this.activeFeatureItem.objectId;
      const lv = mapUtil.getDetailsLayerView();
      // @ts-ignore
      lv.setVisibility(oid,visible);

    }
  }
  async showIntersectingWalls() {
    const layer = mapUtil.ensureGraphicsLayer(this.getView(), this.wallsGraphicLayerId);
    layer.removeAll();
    if (this.cutWalls && (
      (this.activeItem && this.activeItem.graphic) ||
      (this.activeFeatureItem && this.activeFeatureItem.feature))) {
      const { lib } = Context.getInstance();
      const feature = this.activeItem && this.activeItem.graphic
        ? this.activeItem.graphic
        : this.activeFeatureItem.feature;
      const wallFeatures = await this.getWalls(feature.geometry);
      const results = this.getWallIntersections(feature.geometry as __esri.Polyline, wallFeatures);
      if (results.length === 0) {
        this.onIntersectingWalls(false);
      } else {
        results.forEach(result => {
          if (result.intersection) {
            layer.add(new lib.esri.Graphic({
              geometry: result.intersection,
              symbol: lib.esri.symbolJsonUtils.fromJSON({
                type: "esriSLS",
                style: "esriSLSSolid",
                width: 2,
                color: [255, 0, 0]
              })
            }));
          }
          if (result.difference) {
            layer.add(new lib.esri.Graphic({
              geometry: result.difference,
              symbol: lib.esri.symbolJsonUtils.fromJSON({
                type: "esriSLS",
                style: "esriSLSSolid",
                width: 2,
                color: [255, 255, 0]
              })
            }));            
          }
        });
        this.onIntersectingWalls(results.some(r => r.difference));
      }
    }
  }
  showNoUnderlyingLevel() {
    Topic.publish(Topic.ClearToast,{});
    setTimeout(() => {
      Topic.publish(Topic.ShowToast,{
        message: Context.instance.i18n.editor.noUnderlyingLevel
      });
    },400)    
  }

  supportsReflect() {
    return stencilUtil.supportsReflect(this.activeItem && this.activeItem.stencil);
  }

  updateLevel(activeItem,updateFeature,levelData) {
    const attributes = updateFeature.attributes;
    const layer = sourceUtil.getDetailsLayer();
    const levelIdField = aiimUtil.findFieldName(layer.fields,FieldNames.LEVEL_ID);
    const heightRelField = aiimUtil.findFieldName(layer.fields,FieldNames.HEIGHT_RELATIVE);
    const lid = aiimUtil.getAttributeValue(attributes,levelIdField);
    if (lid !== levelData.levelId) {
      console.log("have to update level from",lid,"to",levelData.levelId)
      const heightRel = levelData.heightRelative;
      attributes[levelIdField] = levelData.levelId;
      if (heightRelField && typeof heightRel === "number") {
        attributes[heightRelField] = heightRel;
      }
      if (activeItem) activeItem.z = levelData.z;
      editorUtil.updateZ(updateFeature.geometry,levelData.z)
    }
  }
}
