import Context from "../../../context/Context";
import FieldNames from "../../../../src/aiim/datasets/FieldNames";  
import moment from "moment";
import OfficePlan from "../../../spaceplanner/base/OfficePlan";
import * as aiimUtil from "../../../../src/aiim/util/aiimUtil";

export function getCommentLayerId(){
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo){ 
        return plan.supportInfo.commentsLayerInfo.id;
    }
}

export function getCommentUrl(){
    const plan = OfficePlan.getActive();
    const layerId = "/"+getCommentLayerId();
    if(plan && plan.supportInfo && plan.supportInfo.serviceUrl) {
        return plan.supportInfo.serviceUrl+layerId;
    }
}

export function getFullName(username) {
    const promise = new Promise((resolve, reject)=> {
        let fullName = username;
        let portal=Context.instance.portal;
        var queryParams = {
            query: "username:\"" + username + "\""
        };
        portal.queryUsers(queryParams).then((result)=> {
            if(result && result.total === 1 && result.results[0].fullName) {
                fullName = result.results[0].fullName;
                resolve(fullName);
                return;
            }
        })
        .catch(()=> {
            resolve(fullName);
            return;
        })
    });
    return promise;
}

export function makeTimeAgo(timestamp) {
    if (typeof timestamp !== "number") return;
    let timeAgo = moment(timestamp).fromNow();
    const i18n = Context.getInstance().i18n;
    let pattern = i18n.spaceplanner.comments.timeAgo;
    timeAgo = pattern.replace("{timeAgo}", timeAgo);
    return timeAgo;
}

// export function getCreateDateFieldComment(){
//     const plan = OfficePlan.getActive();
//     if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo && plan.supportInfo.commentsLayerInfo.editFieldsInfo) {
//         return plan.supportInfo.commentsLayerInfo.editFieldsInfo.creationDateField;
//     }
// }

// export function getCreateUserFieldComment(){
//     const plan = OfficePlan.getActive();
//     if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo && plan.supportInfo.commentsLayerInfo.editFieldsInfo) {
//         return plan.supportInfo.commentsLayerInfo.editFieldsInfo.creatorField;
//     }
// }

export function getAuthorNameField() {
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_AUTHOR);
    if(field) return field.name;
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo && plan.supportInfo.commentsLayerInfo.editFieldsInfo) {
        return plan.supportInfo.commentsLayerInfo.editFieldsInfo.creatorField;
    }
}

export function getAuthorDateField() {
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_AUTHOR_DATE);
    if(field) return field.name;
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo && plan.supportInfo.commentsLayerInfo.editFieldsInfo) {
        return plan.supportInfo.commentsLayerInfo.editFieldsInfo.creationDateField;
    }
}

export function addAuthorFields(edit) {
    const authorField = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_AUTHOR);
    const dateField = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_AUTHOR_DATE);
    if(authorField && dateField) {
        edit.attributes[authorField.name] = Context.instance.user.getUsername();
        edit.attributes[dateField.name] = Date.now();
    }
}

export function getObjectIdComment(){
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo){
        return plan.supportInfo.commentsLayerInfo.objectIdField;
    }
}

export function getCommentFieldName(){
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_COMMENT);
    if(field) return field.name;
    return null;
}

export function getMaxCommentLength() {
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_COMMENT);
    if(field) return field.length;
    return 0;
}

export function getPlanIdFieldName(){
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_PLAN_ID);
    if(field) return field.name;
    return null;
}

export function getParentCidFieldName(){
    const field = aiimUtil.findField(getCommentsFields(),FieldNames.COMMENT_PARENT_CID);
    if(field) return field.name;
    return null;
}

export function getCommentsFields(){
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo){
        return plan.supportInfo.commentsLayerInfo.fields;
    }
}

export function getSupportInfo(){
    const plan = OfficePlan.getActive();
    if(plan && plan.supportInfo && plan.supportInfo.commentsLayerInfo) {
        return plan.supportInfo.commentsLayerInfo;
    }
}

export function makeEmptyPoint(){
    const plan = OfficePlan.getActive();
    const pt = new Context.instance.lib.esri.Point({wkid: 4326});
    pt.x = pt.y = null;
    if(plan && plan.isVersioned) {
        pt.x = pt.y = 0; pt.z = 0;
        return pt;
    }
    return pt;
}

export function setCursorPosition(ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    
    // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
}