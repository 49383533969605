import Context from "../../../../context/Context";
import * as mapUtil from "../../../base/mapUtil";
import { IFeatureItem } from "../copypaste/featureItemUtil";

export const hoverGraphicsLayerId = "indoors-hover";

export function clearHoverGraphic() {
  mapUtil.removeAllGraphics(getView(),hoverGraphicsLayerId);
}

function getView() {
  return Context.instance.views && Context.instance.views.activeView;
}

export function makeSymbol(geometry: __esri.Geometry, type: "standard" | "active" | "hover"): __esri.Symbol {
  const lineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 2, color: [0, 255, 255, 1] };
  const fillSymbol: __esri.SimpleFillSymbolProperties = { style: "solid", color: [0, 255, 255, .25], outline: { ...lineSymbol } };
  const activeLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [0, 255, 255, 1] };
  const activeFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [0, 255, 255, .75], outline: { ...lineSymbol } };
  const hoverLineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 4, color: [70, 130, 180, 1] };
  const hoverFillSymbol: __esri.SimpleFillSymbolProperties = { ...fillSymbol, style: "solid", color: [70, 130, 180, .75], outline: { ...lineSymbol } };
  let symbol = null;
  if (type === "standard" && geometry.type === "polygon") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(fillSymbol);
  } else if (type === "standard" && geometry.type === "polyline") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(lineSymbol);
  } else if (type === "active" && geometry.type === "polygon") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(activeFillSymbol);
  } else if (type === "active" && geometry.type === "polyline") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(activeLineSymbol);
  } else if (type === "hover" && geometry.type === "polygon") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(hoverFillSymbol);
  } else if (type === "hover" && geometry.type === "polyline") {
    symbol = new Context.instance.lib.esri.SimpleFillSymbol(hoverLineSymbol);
  }
  return symbol;
}

export function showHoverGraphic(featureItem: IFeatureItem) {
  const graphic = featureItem.graphic;
  const symbol = makeSymbol(graphic.geometry,"hover");
  const graphic2 = new Context.instance.lib.esri.Graphic({
    attributes: Object.assign({},graphic.attributes),
    geometry: graphic.geometry.clone(),
    symbol: symbol
  })
  graphic2.xtnKey = featureItem.key;
  const graphicsLayer = mapUtil.ensureGraphicsLayer(getView(),hoverGraphicsLayerId);
  clearHoverGraphic();
  graphicsLayer.add(graphic2);
}

