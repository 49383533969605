import React from "react";

export const maneuverSvgsByType ={
  "esriDPTManeuverStraight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 7v17h-3V7H7l4.5-6L16 7z"/></svg>),
  "esriDPTManeuverFerryOn": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6.207 3.5L3.354 6.354l-.707-.707L4.293 4H0V3h4.293L2.646 1.354l.707-.707zM11 0h-1v1h1zm-.503 4.96a.997.997 0 0 1 .37.072l2.876 1.144L13 4h-1V2H9v2h-.997l-.784 2.224 2.899-1.188a1 1 0 0 1 .38-.075zm0 1l-5.63 2.31.284.496c.817 1.42 1.893 2.96 1.938 3.024l.15.213h.26a4.033 4.033 0 0 1 2.661.928l.34.315.34-.315a4.033 4.033 0 0 1 2.66-.928h.285l.145-.243s1.002-1.674 1.819-3.094l.288-.501zm.34 7.734l-.337.214-.337-.214a3.71 3.71 0 0 0-3.935-.314l.544.838a2.706 2.706 0 0 1 2.855.321l.873.553.872-.553a2.71 2.71 0 0 1 2.856-.32l.544-.838a3.712 3.712 0 0 0-3.936.313z"/></svg>),
  "esriDPTManeuverFerryOff": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15.307 3.5l-2.854 2.854-.707-.707L13.393 4H9.1V3h4.293l-1.647-1.646.707-.707zM6 0H5v1h1zm-.497 4.96a1.005 1.005 0 0 1 .38.076L8.78 6.224 7.996 4H7V2H4v2H3l-.743 2.176 2.876-1.144a1.003 1.003 0 0 1 .37-.071zm-5.54 3.205l.288.5a243.3 243.3 0 0 0 1.82 3.095l.145.243h.283a4.033 4.033 0 0 1 2.661.928l.34.315.34-.315a4.033 4.033 0 0 1 2.661-.928h.26l.15-.213a47.057 47.057 0 0 0 1.938-3.024l.285-.496-5.631-2.31zm5.874 5.53l-.336.213-.337-.214a3.72 3.72 0 0 0-3.936-.314l.544.84a2.714 2.714 0 0 1 2.857.319l.872.553.871-.553a2.664 2.664 0 0 1 2.856-.32l.544-.84a3.715 3.715 0 0 0-3.935.316z"/></svg>),
  "esriDPTManeuverForkCentral": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13.597 6.975L10 11.414V8.24l1.952-2.42 1.637 1.15zM2.407 6.97l-.008.006L6 11.418V8.244L4.045 5.821zM8 1.088L5.25 5H7v11h2V5h1.75z"/></svg>),
  "esriDPTManeuverRoundabout": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13.91 9a6.003 6.003 0 0 1-5.658 4.987l-.347-1.992c.032.001.063.005.095.005a3.996 3.996 0 0 0 3.858-3h-1.749l2.75-3.912L15.61 9zm-8.668 1.877l-.002.002a3.945 3.945 0 0 1-.688-4.884l-1.809-.848a5.923 5.923 0 0 0 1.08 7.148L2.59 13.529l4.71.822-.82-4.711zm1.425-6.576l-.011-.067a3.97 3.97 0 0 1 4.575 1.43l1.194-1.697a5.941 5.941 0 0 0-6.119-1.718L6.018.607 2.64 3.994l4.33 2.03z"/></svg>),
  "esriDPTManeuverUTurn": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13 6v10h-2V6a3 3 0 0 0-6 0v6h1.75L4 15.912 1.25 12H3V6a5 5 0 0 1 10 0z"/></svg>),
  "esriDPTManeuverDoor": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4 1v14h9V1zm6.5 8.25a.75.75 0 1 1 .75-.75.75.75 0 0 1-.75.75zM2 11h1v2H2zm0-8h1v2H2z"/></svg>),
  "esriDPTManeuverStairs": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M1 15v-3h3v-2h2V8h2V6h2V4h2V2h3v13z"/></svg>),
  "esriDPTManeuverElevator": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5 15H0V1h5zM6 1v14h5V1zm6 6h3l-1.5-3zm3 1h-3l1.5 3z"/></svg>),
  "esriDPTManeuverEscalator": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15 6.816V15H5.121l8-8H14a2.965 2.965 0 0 0 1-.184zM3.707 15l9-9H14a2 2 0 0 0 0-4h-1.728l-9 9H2a2 2 0 0 0 0 4z"/></svg>),
  "esriDPTManeuverPedestrianRamp": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5.531 2.747a1 1 0 1 1 1.216.722.999.999 0 0 1-1.216-.722zM9.09 11.75a.498.498 0 0 0 .108-.366l-.215-2.058a.5.5 0 0 0-.153-.311L7 7.285l.002-1.477L9.516 7.05a.5.5 0 1 0 .443-.897l-2.955-1.46v-.159a.5.5 0 0 0-.498-.543H5.5a.497.497 0 0 0-.33.125L3.92 5.218a.5.5 0 0 0-.134.558l.51 1.291a.5.5 0 1 0 .93-.367l-.382-.965.845-.744H6V7.5a.5.5 0 0 0 .156.363l1.85 1.75.197 1.873a.497.497 0 0 0 .292.4l-3.113.718 1.558-2.89a.494.494 0 0 0 .025-.1L6 8.704v.648l-1.604 2.975a.494.494 0 0 0 .023.5L1.5 13.5V16h13v-5.5z"/></svg>),
  "esriDPTManeuverForkLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.676 7.07l.024.002c-.009.01-.015.023-.024.034A6.13 6.13 0 0 0 8.67 5.26l-.044-.054 2.86-3.561 1.685 1.109zm-1.79-1.184L6.067 3.628 7.5 2.624 3.002 1 3 3.098V5.78l1.422-.997 1.86 2.286-.003.003A2.981 2.981 0 0 1 7 9v7h2V9a4.988 4.988 0 0 0-1.112-3.116z"/></svg>),
  "esriDPTManeuverRampLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4.83 8.278c-.124.156-.222.327-.33.492V3.5h2v2.703L5.34 7.646l-.004-.002zm3.187-5.154L9.45 4.128 5.613 8.904l-.002-.002A4.988 4.988 0 0 0 4.5 12.018V16h2v-3.982a3.062 3.062 0 0 1 .714-1.93l3.872-4.805 1.414.997V1.5z"/></svg>),
  "esriDPTManeuverRoundaboutLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13.91 9a6.003 6.003 0 0 1-5.658 4.987l-.347-1.992c.032.001.063.005.095.005a3.996 3.996 0 0 0 3.858-3h-1.749l2.75-3.912L15.61 9zm-8.668 1.877l-.002.002a3.945 3.945 0 0 1-.688-4.884l-1.809-.848a5.923 5.923 0 0 0 1.08 7.148L2.59 13.529l4.71.822-.82-4.711zm1.425-6.576l-.011-.067a3.97 3.97 0 0 1 4.575 1.43l1.194-1.697a5.941 5.941 0 0 0-6.119-1.718L6.018.607 2.64 3.994l4.33 2.03z"/></svg>),
  "esriDPTManeuverUTurnLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13 6v10h-2V6a3 3 0 0 0-6 0v6h1.75L4 15.912 1.25 12H3V6a5 5 0 0 1 10 0z"/></svg>),
  "esriDPTManeuverBearLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M10 8v8H8V8a2.98 2.98 0 0 0-.721-1.928l.003-.002-.979-1.204L5 5.78V3.098L5.002 1 9.5 2.624 8.067 3.628l-.124.087.944 1.17h.002A4.987 4.987 0 0 1 10 8z"/></svg>),
  "esriDPTManeuverTurnLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13 8v8h-2V8a3.003 3.003 0 0 0-3-3c-.034 0-.067.004-.1.005V5H5.5v1.75L1.588 4 5.5 1.25V3H8a5 5 0 0 1 5 5z"/></svg>),
  "esriDPTManeuverSharpLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M13 8v8h-2V8a3.003 3.003 0 0 0-3-3 2.968 2.968 0 0 0-1.906.7L5.01 6.784l1.21 1.211-4.71.822.822-4.711 1.237 1.238.02.02 1.03-1.029-.005-.005A4.994 4.994 0 0 1 13 8z"/></svg>),
  "esriDPTManeuverTurnLeftLeft": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15 8v8h-2V8a3.003 3.003 0 0 0-3-3v1.75L6.088 4 10 1.25V3a5 5 0 0 1 5 5zM5 8.588a3 3 0 0 1 1.609-2.655L4.857 4.702A4.987 4.987 0 0 0 3 8.588v1.5H1.25L4 14l2.75-3.912H5z"/></svg>),
  "esriDPTManeuverTurnLeftRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15 13.5V16h-2v-2.5a2.925 2.925 0 0 0-2.906-3l-.594.005V10.5H9v1.75L5.088 9.5 9 6.75V8.5h1.088a4.926 4.926 0 0 1 4.912 5zM4 4.614V4h1.75L3 .088.25 4H2v.614A7.163 7.163 0 0 0 4.136 8.9l1.69-1.188A4.96 4.96 0 0 1 4 4.613z"/></svg>),
  "esriDPTManeuverForkRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.33 5.26a6.115 6.115 0 0 0-1.006 1.845c-.009-.01-.015-.023-.024-.033l.024-.003L2.83 2.754l1.685-1.11 2.86 3.562zm5.67.52V3.098L12.998 1 8.5 2.624l1.433 1.004-1.82 2.258-.001-.002A4.987 4.987 0 0 0 7 9v7h2V9a2.981 2.981 0 0 1 .721-1.928l-.003-.003 1.86-2.286z"/></svg>),
  "esriDPTManeuverRampRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4.83 8.278c-.124.156-.222.327-.33.492V3.5h2v2.703L5.34 7.646l-.004-.002zm3.187-5.154L9.45 4.128 5.613 8.904l-.002-.002A4.988 4.988 0 0 0 4.5 12.018V16h2v-3.982a3.062 3.062 0 0 1 .714-1.93l3.872-4.805 1.414.997V1.5z"/></svg>),
  "esriDPTManeuverRoundaboutRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M12.176 12.295l1.234 1.234-4.71.822.821-4.711 1.237 1.237.002.002a3.945 3.945 0 0 0 .688-4.884l1.809-.848a5.923 5.923 0 0 1-1.08 7.148zM8 4a3.97 3.97 0 0 1 1.344.234l-.011.067-.304 1.723 4.33-2.03L9.982.607 9.694 2.25a5.941 5.941 0 0 0-6.119 1.718L4.77 5.665A3.985 3.985 0 0 1 8 4zM4.142 9h1.749L3.14 5.088.39 9h1.7a6.003 6.003 0 0 0 5.658 4.987l.347-1.992C8.063 11.996 8.032 12 8 12a3.996 3.996 0 0 1-3.858-3z"/></svg>),
  "esriDPTManeuverUTurnRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 1a5 5 0 0 1 5 5v6h1.75L12 15.912 9.25 12H11V6a3 3 0 0 0-6 0v10H3V6a5 5 0 0 1 5-5z"/></svg>),
  "esriDPTManeuverBearRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.697 4.866l-.98 1.204.004.002A2.98 2.98 0 0 0 8 8v8H6V8a4.987 4.987 0 0 1 1.111-3.116l.002.002.944-1.17-.124-.088L6.5 2.624 10.998 1 11 3.098V5.78z"/></svg>),
  "esriDPTManeuverTurnRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M10.5 6.75V5H8.1v.005C8.067 5.004 8.034 5 8 5a3.003 3.003 0 0 0-3 3v8H3V8a5 5 0 0 1 5-5h2.5V1.25L14.412 4z"/></svg>),
  "esriDPTManeuverSharpRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M9.78 7.995l1.21-1.21L9.906 5.7A2.968 2.968 0 0 0 8 5a3.003 3.003 0 0 0-3 3v8H3V8a4.994 4.994 0 0 1 8.386-3.67l-.005.005 1.03 1.03.02-.021 1.237-1.238.822 4.71z"/></svg>),
  "esriDPTManeuverTurnRightLeft" :(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5.912 8.5H7V6.75l3.912 2.75L7 12.25V10.5h-.5v.005l-.594-.005A2.925 2.925 0 0 0 3 13.5V16H1v-2.5a4.926 4.926 0 0 1 4.912-5zM14 4.614V4h1.75L13 .088 10.25 4H12v.614a4.96 4.96 0 0 1-1.826 3.099l1.69 1.188A7.163 7.163 0 0 0 14 4.614z"/></svg>),
  "esriDPTManeuverTurnRightRight": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M6 6.75V5a3.003 3.003 0 0 0-3 3v8H1V8a5 5 0 0 1 5-5V1.25L9.912 4zm7 3.338v-1.5a4.987 4.987 0 0 0-1.857-3.886L9.39 5.932A3 3 0 0 1 11 8.589v1.5H9.25L12 14l2.75-3.912z"/></svg>),
  "esriDPTManeuverElevatorUp": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5 15H0V1h5zM6 1v14h5V1zm6 6h3l-1.5-3zm3 1h-3l1.5 3z"/></svg>),
  "esriDPTManeuverEscalatorUp": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15 6.816V15H5.121l8-8H14a2.965 2.965 0 0 0 1-.184zM3.707 15l9-9H14a2 2 0 0 0 0-4h-1.728l-9 9H2a2 2 0 0 0 0 4z"/></svg>),
  "esriDPTManeuverStairsUp" : (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M1 15v-3h3v-2h2V8h2V6h2V4h2V2h3v13z"/></svg>),
  "esriDPTManeuverElevatorDown": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M5 15H0V1h5zM6 1v14h5V1zm6 6h3l-1.5-3zm3 1h-3l1.5 3z"/></svg>),
  "esriDPTManeuverEscalatorDown": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M15 6.816V15H5.121l8-8H14a2.965 2.965 0 0 0 1-.184zM3.707 15l9-9H14a2 2 0 0 0 0-4h-1.728l-9 9H2a2 2 0 0 0 0 4z"/></svg>),
  "esriDPTManeuverStairsDown": (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M1 15v-3h3v-2h2V8h2V6h2V4h2V2h3v13z"/></svg>),
}

export const maneuverTypesByInt = {

  // group and misc maneuvers (0-49).
  0: {type: "esriDPTUnknown", icon: "unknown"},
  1: {type: "esriDPTHeader", icon: null},

  // non-directed maneuvers (50-199)
  50: {type: "esriDPTManeuverArrive", icon: null},
  51: {type: "esriDPTManeuverDepart", icon: null},
  52: {type: "esriDPTManeuverStraight", icon: "straight"},

  // driving
  100: {type: "esriDPTManeuverFerryOn", icon: "embark"},
  101: {type: "esriDPTManeuverFerryOff", icon: "disembark"},
  102: {type: "esriDPTManeuverForkCentral", icon: "fork-middle"},
  103: {type: "esriDPTManeuverRoundabout", icon: "roundabout-left"},
  104: {type: "esriDPTManeuverUTurn", icon: "u-turn-left"},

  // walking
  150: {type: "esriDPTManeuverDoor", icon: "walk-through-door"},
  151: {type: "esriDPTManeuverStairs", icon: "stairs"},
  152: {type: "esriDPTManeuverElevator", icon: "elevator"},
  153: {type: "esriDPTManeuverEscalator", icon: "escalator"},
  154: {type: "esriDPTManeuverPedestrianRamp", icon: "take-pedestrian-ramp"},

  // directed to the left (200-299)
  200: {type: "esriDPTManeuverForkLeft", icon: "fork-left"},
  201: {type: "esriDPTManeuverRampLeft", icon: "ramp-right"},
  202: {type: "esriDPTManeuverRoundaboutLeft", icon: "roundabout-left"},
  203: {type: "esriDPTManeuverUTurnLeft", icon: "u-turn-left"},
  204: {type: "esriDPTManeuverBearLeft", icon: "bear-left"},
  205: {type: "esriDPTManeuverTurnLeft", icon: "left"},
  206: {type: "esriDPTManeuverSharpLeft", icon: "sharp-left"},
  207: {type: "esriDPTManeuverTurnLeftLeft", icon: "left-left"},
  208: {type: "esriDPTManeuverTurnLeftRight", icon: "left-right"},

  // directed to the right (300-399)
  300: {type: "esriDPTManeuverForkRight", icon: "fork-right"},
  301: {type: "esriDPTManeuverRampRight", icon: "ramp-right"},
  302: {type: "esriDPTManeuverRoundaboutRight", icon: "roundabout-right"},
  303: {type: "esriDPTManeuverUTurnRight", icon: "u-turn-right"},
  304: {type: "esriDPTManeuverBearRight", icon: "bear-right"},
  305: {type: "esriDPTManeuverTurnRight", icon: "right"},
  306: {type: "esriDPTManeuverSharpRight", icon: "sharp-right"},
  307: {type: "esriDPTManeuverTurnRightLeft", icon: "right-left"},
  308: {type: "esriDPTManeuverTurnRightRight", icon: "right-right"},

  // upward (400-499)
  400: {type: "esriDPTManeuverElevatorUp", icon: "elevator"},
  401: {type: "esriDPTManeuverEscalatorUp", icon: "escalator"},
  402: {type: "esriDPTManeuverStairsUp", icon: "stairs"},
  403: {type: "esriDPTManeuverStairsUp", icon: "stairs"},

  // downward (500-599)
  500: {type: "esriDPTManeuverElevatorDown", icon: "elevator"},
  501: {type: "esriDPTManeuverEscalatorDown", icon: "escalator"},
  502: {type: "esriDPTManeuverStairsDown", icon: "stairs"},

  // events (1000-xxxx)
  1000: {type: "esriDPTEvent", icon: null},
  1001: {type: "esriDPTEventLandmark", icon: null},
  1002: {type: "esriDPTEventTimeZone", icon: null},
  1003: {type: "esriDPTEventTraffic", icon: null},
  1004: {type: "esriDPTEventBarrier", icon: null},
  1005: {type: "esriDPTEventBoundary", icon: null},
  1006: {type: "esriDPTEventRestrictionViolation", icon: null}
}

/*

From Konstantin Kutsner 9/11/2018

// group and misc maneuvers (0-49).
unknown = 0, // esriDPTUnknown
header = 1,  // esriDPTHeader

// non-directed maneuvers (50-199)
maneuver_arrive = 50,   // esriDPTManeuverArrive,
maneuver_depart = 51,   // esriDPTManeuverDepart,
maneuver_straight = 52, // esriDPTManeuverStraight,

// ...driving
maneuver_ferry_on = 100,     //esriDPTManeuverFerryOn,
maneuver_ferry_off = 101,    // esriDPTManeuverFerryOff,
maneuver_fork_central = 102, // esriDPTManeuverForkCentral,
maneuver_roundabout = 103,   // esriDPTManeuverRoundabout,
maneuver_u_turn = 104,       // esriDPTManeuverUTurn,

// ...walking
maneuver_door = 150,            // esriDPTManeuverDoor,
maneuver_stairs = 151,          // esriDPTManeuverStairs,
maneuver_elevator = 152,        // esriDPTManeuverElevator,
maneuver_escalator = 153,       // esriDPTManeuverEscalator,
maneuver_pedestrian_ramp = 154, // esriDPTManeuverPedestrianRamp,

// directed to the left (200-299)
maneuver_fork_left = 200,       // esriDPTManeuverForkLeft,
maneuver_ramp_left = 201,       // esriDPTManeuverRampLeft,
maneuver_roundabout_left = 202, // esriDPTManeuverRoundaboutLeft,
maneuver_u_turn_left = 203,     // esriDPTManeuverUTurnLeft,
maneuver_bear_left = 204,       // esriDPTManeuverBearLeft,
maneuver_turn_left = 205,       // esriDPTManeuverTurnLeft,
maneuver_sharp_left = 206,      // esriDPTManeuverSharpLeft,
maneuver_turn_left_left = 207,  // esriDPTManeuverTurnLeftLeft,
maneuver_turn_left_right = 208, // esriDPTManeuverTurnLeftRight,

// directed to the right (300-399)
maneuver_fork_right = 300,       // esriDPTManeuverForkRight,
maneuver_ramp_right = 301,       // esriDPTManeuverRampRight,
maneuver_roundabout_right = 302, // esriDPTManeuverRoundaboutRight,
maneuver_u_turn_right = 303,     // esriDPTManeuverUTurnRight,
maneuver_bear_right = 304,       // esriDPTManeuverBearRight,
maneuver_turn_right = 305,       // esriDPTManeuverTurnRight,
maneuver_sharp_right = 306,      // esriDPTManeuverSharpRight,
maneuver_turn_right_left = 307,  // esriDPTManeuverTurnRightLeft,
maneuver_turn_right_right = 308, // esriDPTManeuverTurnRightRight,

// upward (400-499)
maneuver_elevator_up = 400,  // esriDPTManeuverElevatorUp,
maneuver_escalator_up = 401, // esriDPTManeuverEscalatorUp,
maneuver_stairs_up = 403,    // esriDPTManeuverStairsUp,

// downward (500-599)
maneuver_elevator_down = 500,  // esriDPTManeuverElevatorDown,
maneuver_escalator_down = 501, // esriDPTManeuverEscalatorDown,
maneuver_stairs_down = 502,    // esriDPTManeuverStairsDown,

// events (1000-xxxx)
event = 1000,                       // esriDPTEvent
event_landmark = 1001,              // esriDPTEventLandmark
event_time_zone = 1002,             // esriDPTEventTimeZone
event_traffic = 1003,               // esriDPTEventTraffic
event_barrier = 1004,               // esriDPTEventBarrier
event_boundary = 1005,              // esriDPTEventBoundary
event_restriction_violation = 1006, // esriDPTEventRestrictionViolation

 */
