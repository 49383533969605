import React, { useState } from "react";
import { connect } from "react-redux";

import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import HelpTip from "../../../components/common/HelpTip/HelpTip";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import ReservationManagers from "./ReservationManagers";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

import Form, { FormControl, FormControlLabel, FormHelperText } from "calcite-react/Form";
import Tabs, { TabContents, TabSection, TabNav, TabTitle } from "calcite-react/Tabs";
import ReactToggle from "react-toggle";
import TextField from "calcite-react/TextField";

import { IWorkspaceAreaConfig } from "../../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import { AreaTypes, ReservationMethod } from "../../../util/interfaces";
import {
  getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled,
  getHotelReservationMethod, getMeetingRoomReservationMethod
} from "../../redux";
import { IConfigurables } from "../../../context/Configuration";

interface IAreaPropertiesProps {
  actionBarInfo: {
    canStepNext: () => boolean,
    isMultiPage: boolean,
    onPageChanged: (num: number) => void
  },
  areaItem?: __esri.Graphic,
  disableSettings: boolean,
  hideLimits: boolean,
  hideMaxBookingsHotel: boolean,
  hideMaxBookingsMeetingRoom: boolean,
  hideReservationManagers: boolean,
  hideRestricted: boolean,
  onAreaManagersUpdated: (data: never) => void,
  onChange?: (data: {
    name: string,
    restricted?: boolean,
    config?: IWorkspaceAreaConfig
  }) => void,
  rdxHotdesksEnabled: boolean,
  rdxHotelReservationMethod: ReservationMethod,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean,
  rdxMeetingRoomReservationMethod: ReservationMethod,
  supportsBookingLimits: boolean,
  supportsRestricted: boolean
}
interface IAreaPropertiesState {
  activePage: number,
  activeTabIndex: number,
  areaType: AreaTypes,
  limits: IWorkspaceAreaConfig,
  name: string,
  nameError: string,
  restricted: boolean
}
const defaultHotelConfig: IWorkspaceAreaConfig["hotel"] = {
  maxBookingsPerPerson: null,
  maxDaysInAdvance: null,
  maxDaysPerBooking: null
};
const defaultMeetingRoomConfig: IWorkspaceAreaConfig["meetingRoom"] = {
  maxBookingsPerPerson: null,
  maxDaysInAdvance: null,
  maxHoursPerBooking: null
};
class _AreaProperties extends React.Component<IAreaPropertiesProps, IAreaPropertiesState> {

  private componentId: string;

  constructor(props: IAreaPropertiesProps) {
    super(props);
    this.componentId = component.nextId();

    const areaType: AreaTypes = this.props.areaItem
      ? aiimUtil.getAttributeValue(this.props.areaItem.attributes, FieldNames.AREA_TYPE)
      : "workspace";
    let v, limits: IWorkspaceAreaConfig, name = "", restricted = false;
    
    if (this.props.areaItem) {
      const attr = this.props.areaItem.attributes;
      name = aiimUtil.getAttributeValue(attr,FieldNames.AREA_NAME) || "";
      if (this.props.supportsRestricted) {
        v = aiimUtil.getAttributeValue(attr, FieldNames.AREAS_RESTRICTED);
        // workspace areas are unrestricted by default now
        restricted = (areaType !== "hotdesk" && v === 1);
      }
      if (this.props.supportsBookingLimits) {
        const plan = Context.instance.spaceplanner.activePlan;
        limits = plan.areasTable.getBookingLimits(this.props.areaItem);
      }
    } else {
      if (this.props.supportsBookingLimits) {
        limits = Context.instance.session.newHotelConfig || {
          hotel: { ...defaultHotelConfig },
          meetingRoom: { ...defaultMeetingRoomConfig }
        };
      }
    }

    this.state = component.newState({
      activePage: 1,
      activeTabIndex: 0,
      areaType,
      name: name,
      nameError: null,
      restricted: restricted,
      limits
    });

    if (this.props.actionBarInfo) {
      this.props.actionBarInfo.onPageChanged = num => {
        this.setState({activePage: num});
      }
      this.props.actionBarInfo.canStepNext = () => {
        return !this.state.nameError
      }
    }

  }

  componentDidMount() {}

  componentDidUpdate() {
    const chk = (v) => {
      if (typeof v !== "number" || v < 1) return null;
      v = Math.floor(v);
      return v;
    }
    if (this.props.onChange) {
      let data: Parameters<IAreaPropertiesProps["onChange"]>[0] = {
        name: this.state.name.trim()
      }
      if (this.props.supportsRestricted) {
        data.restricted = this.state.restricted;
      }
      if (this.props.supportsBookingLimits) {
        const limits = this.state.limits;
        data.config = {
          hotel: {
            maxBookingsPerPerson: chk(limits?.hotel.maxBookingsPerPerson),
            maxDaysPerBooking: chk(limits?.hotel.maxDaysPerBooking),
            maxDaysInAdvance: chk(limits?.hotel.maxDaysInAdvance)
          },
          meetingRoom: {
            maxBookingsPerPerson: chk(limits?.meetingRoom.maxBookingsPerPerson),
            maxHoursPerBooking: chk(limits?.meetingRoom.maxHoursPerBooking),
            maxDaysInAdvance: chk(limits?.meetingRoom.maxDaysInAdvance)
          }
        }
      }
      this.props.onChange(data);
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  checkName(name) {
    const i18n = Context.instance.i18n;
    const v = name.trim();
    if (v.length === 0) {
      return i18n.spaceplanner.areaProperties.emptyAreaName;
    } else {
      const invalid = _AreaProperties.isInvalidPattern(name);
      if (invalid) {
        return i18n.spaceplanner.areaProperties.invalidAreaName;
      }
    }
    return null;
  }

  isWorkspaceConfig(limits?: IAreaPropertiesState["limits"]) {
    const keys = Object.keys(limits || this.state.limits);
    return keys.includes("hotel") || keys.includes("meetingRooms");
  }

  render() {
    const { areaItem, hideLimits, hideReservationManagers, hideRestricted, supportsBookingLimits, supportsRestricted } = this.props;
    const isNew = !areaItem;
    const showRestricted = !!supportsRestricted && !hideRestricted;
    const showBookingLimits = !!supportsBookingLimits && !hideLimits;
    const showAreaRoles = OfficePlan.getActive().hasAreaRoles() && !hideReservationManagers;
    const actionBarInfo = this.props.actionBarInfo;
    let cls = "i--form";
    if (actionBarInfo && actionBarInfo.isMultiPage) {
      cls += " i-pages i--active-page-"+this.state.activePage;
    }

    return (
      <Form className={cls}>
        <div className="i-flex-columns i--page-1">
          {this.renderAreaName()}
          {showRestricted && this.renderRestricted()}
          {isNew && showAreaRoles && this.renderAreaManagers()}
        </div>
        {showBookingLimits && this.renderBookingLimits()}
      </Form>
    );
  }

  renderAreaManagers() {
    return (
      <ReservationManagers onRoleDataUpdated={this.props.onAreaManagersUpdated} />
    )
  }

  renderAreaName() {
    const i18n = Context.instance.i18n;
    const id = this.componentId;
    const errmsg = this.state.nameError;
    return (
      <FormControl horizontal={false}>
        <FormControlLabel
          id={id + "-name-label"}
          htmlFor="name"
          className="i--form-label">
          <span className="i--required">
            {i18n.spaceplanner.areaProperties.name}
          </span>
        </FormControlLabel>
        <TextField
          id={id + "-name"}
          name="name"
          autoFocus
          fullWidth={true}
          value={this.state.name}
          error={!!errmsg}
          onBlur={evt => {
            this.setState({
              nameError: this.checkName(this.state.name)
            });
          }}
          onChange={evt => {
            let nameError = this.checkName(evt.target.value);
            this.setState({
              name: evt.target.value,
              nameError: nameError
            });
          }}
        />
        {errmsg && <FormHelperText error>{errmsg}</FormHelperText>}
      </FormControl>
    );
  }

  renderBookingLimits() {
    if (this.props.areaItem) {
      const { i18n } = Context.getInstance();
      const { activeTabIndex } = this.state;
      const { disableSettings, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
      let index = activeTabIndex;
      if (!(rdxHotelsEnabled && rdxMeetingRoomsEnabled)) {
        index = rdxMeetingRoomsEnabled ? 1: 0;
      }
      if (!(rdxHotelsEnabled || rdxMeetingRoomsEnabled)) {
        return null;
      }
      return (
        <Tabs
          translucent
          activeTabIndex={index}
          onTabChange={activeTabIndex => this.setState({ activeTabIndex })}
          className="i-schedule-limits"
        >
          <TabNav>
            <TabTitle 
              key="hotel" 
              className={`${!rdxHotelsEnabled || disableSettings ? "i-form-disabled": ""}`}
              style={(!rdxHotelsEnabled || disableSettings) ? {padding: "0", margin: "0"} : {}}
            >
              {rdxHotelsEnabled ? i18n.configurator.officeHoteling.workspaceOptions.newofficeHoteling : ""}
            </TabTitle>
            <TabTitle 
              key="meetingRoom" 
              className={`${!rdxMeetingRoomsEnabled || disableSettings ? "i-form-disabled": ""}`}
              style={(!rdxHotelsEnabled || disableSettings) ? {padding: "0", margin: "0"} : {}}
            >
              {rdxMeetingRoomsEnabled ? i18n.configurator.officeHoteling.workspaceOptions.meetingRooms : ""}
            </TabTitle>
          </TabNav>
          <TabContents>
            <TabSection key="hotel" className="i-flex-columns">
              {this.renderMaxBookingsPerPerson("hotel")}
              {this.renderMaxDaysPerBooking("hotel")}
              {this.renderMaxDaysInAdvance("hotel")}
            </TabSection>
            <TabSection key="meetingRoom" className="i-flex-columns">
              {this.renderMaxBookingsPerPerson("meetingRoom")}
              {this.renderMaxDaysPerBooking("meetingRoom")}
              {this.renderMaxDaysInAdvance("meetingRoom")}
            </TabSection>
          </TabContents>
        </Tabs>
      )
    } else {
      return (
        <>
          <div className="i-flex-columns i--page-2 i-schedule-limits">
            {this.renderMaxBookingsPerPerson("hotel")}
            {this.renderMaxDaysPerBooking("hotel")}
            {this.renderMaxDaysInAdvance("hotel")}
          </div>
          <div className="i-flex-columns i--page-3 i-schedule-limits">
            {this.renderMaxBookingsPerPerson("meetingRoom")}
            {this.renderMaxDaysPerBooking("meetingRoom")}
            {this.renderMaxDaysInAdvance("meetingRoom")}
          </div>
        </>
      )
    }
  }

  renderLimit(options) {
    let value = options.value;
    if (value === -1 || value === null) value = "";
    const { disableSettings } = this.props;
    const disabled = disableSettings || options.disabled;
    return (
      <FormControl horizontal={false} className="i-schedule-limit">
        <div className="i-flex-between" style={{width: "100%"}}>
          <FormControlLabel className={disabled ? "i-form-disabled": ""}>{options.label}</FormControlLabel>
          <span className={"i-toggle-spaceplanner"}>
            <ReactToggle id={this.componentId+"_"+options.key}
              icons={false}
              disabled={disabled}
              checked={options.checked}
              onChange={evt => {
                options.onToggle(!!evt.target.checked)
              }}
            />
          </span>
        </div>
        <div style={{width: "15rem", display: "block"}}>
          <div className="i-flex-row-center">
            <TextField
              fullWidth
              type="number"
              disabled={!options.checked || disabled}
              min={options.min}
              max={options.max}
              step="1"
              value={value}
              onChange={evt => {
                let v = evt.target.value;
                if (!v) v = -1;
                else v = Number(v);
                options.onValueChange(v)
              }}
            />
            {options.helpTip && <HelpTip text={options.helpTip} />}
          </div>
        </div>
      </FormControl>
    );
  }

  renderLimitProp(
    propName: keyof IWorkspaceAreaConfig["hotel"] | keyof IWorkspaceAreaConfig["meetingRoom"],
    type: "hotel" | "meetingRoom") {
    const i18n = Context.instance.i18n;
    const lbl = i18n.spaceplanner.areaProperties[propName];
    const helpTip = i18n.spaceplanner.areaProperties[propName + "Tip"];
    const limits = this.state.limits;
    const value = limits?.[type][propName];
    const checked = (typeof value === "number");
    const { rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    return this.renderLimit({
      label: lbl,
      helpTip: helpTip,
      key: propName,
      checked: checked,
      disabled: type === "hotel" ? !rdxHotelsEnabled : !rdxMeetingRoomsEnabled,
      min: 1,
      max: 1000000,
      value: value,
      onToggle: b => {
        const v = b ? -1 : null;
        this.setState(prev => {
          return {
            ...prev,
            limits: {
              ...prev.limits,
              [type]: { ...prev.limits[type], [propName]: v }
            }
          };
        });
      },
      onValueChange: v => {
        this.setState(prev => {
          return {
            ...prev,
            limits: {
              ...prev.limits,
              [type]: { ...prev.limits[type], [propName]: v }
            }
          };
        });
      }
    })
  }

  renderMaxBookingsPerPerson(type: keyof IWorkspaceAreaConfig) {
    if (type === "hotel") {
      return !this.props.hideMaxBookingsHotel && this.renderLimitProp("maxBookingsPerPerson", type);
    } else {
      return !this.props.hideMaxBookingsMeetingRoom && this.renderLimitProp("maxBookingsPerPerson", type);
    }
  }

  renderMaxDaysPerBooking(type: keyof IWorkspaceAreaConfig) {
    return this.renderLimitProp(type === "hotel" ? "maxDaysPerBooking" : "maxHoursPerBooking", type);
  }

  renderMaxDaysInAdvance(type: keyof IWorkspaceAreaConfig) {
    return this.renderLimitProp("maxDaysInAdvance", type);
  }

  renderRestricted() {
    const i18n = Context.instance.i18n;
    const lbl = i18n.spaceplanner.areaProperties.restricted;
    const { disableSettings } = this.props;
    return (
      <FormControl horizontal={false}>
        <div className="i-flex-between" style={{width: "100%"}}>
          <FormControlLabel className={disableSettings ? "i-form-disabled" : ""}>{lbl}</FormControlLabel>
          <span className={"i-toggle-spaceplanner"}>
            <ReactToggle id={this.componentId+"_restricted"}
              icons={false}
              disabled={disableSettings}
              checked={!!this.state.restricted}
              onChange={evt => {
                this.setState({restricted: !!evt.target.checked});
              }}
            />
          </span>
        </div>
      </FormControl>
    )
  }

  static async checkDuplicateName(name: string, origAreaName: string) {
    const i18n = Context.instance.i18n;
    const plan = Context.instance.spaceplanner.activePlan;
    const result = {ok: true};
    let areas, dupTitle, dupMsg;

    areas = await plan.queryWorkspaceAreas();
    dupTitle = i18n.spaceplanner.areaProperties.createDuplicateWorkspaceName;
    dupMsg = i18n.spaceplanner.areaProperties.duplicateWorkspaceNamePrompt;

    if (areas) {
      const lc = name.toLowerCase();
      const lcOrig = origAreaName.toLowerCase();
      const hasDup = areas.some(f => {
        const v = aiimUtil.getAttributeValue(f.attributes,FieldNames.AREA_NAME);
        if (typeof v === "string" && lc === v.toLowerCase()) {
          if (lcOrig && lc === lcOrig) return false;
          return true;
        }
        return false;
      })
      if (hasDup) {
        result.ok = false;
        const options = {
          title: dupTitle,
          message: dupMsg,
          okLabel: i18n.general.ok
        };
        const r = await ModalController.confirm(options);
        if (r.ok) result.ok = true;
      }
    }
    return result;
  }

  static isInvalidPattern(name) {
    const charPattern=/<[a-zA-Z0-9` !@#$%^&*()_+\-=\[\]{};':"\\|,.\/?~]*>/g;
    return name.match(charPattern);
  }

  static actionBar(options: {
    isMultiPage: boolean,
    showHotelLimits: boolean,
    showMeetingRoomLimits: boolean,
    okLabel?: string
  }) {
    const { showHotelLimits, showMeetingRoomLimits } = options;
    const pageCount = [showHotelLimits, showMeetingRoomLimits].filter(x => x).length + 1;
    const info = {
      page: 1,
      isMultiPage: options.isMultiPage,
      content: null,
      okButton: null,
      okButtonClicked: null,
      cancelButton: null,
      cancelButtonClicked: null,
      backButton: null,
      backButtonClicked: null,
      nextButton: null,
      nextButtonClicked: null,
      canStepNext: null,
      onPageChanged: null,
      onPageChangedHeader: null,
      setPage: num => {
        info.page = num;
        if (info.backButton) {
          if (info.page === 1) info.backButton.disable();
          else info.backButton.enable();
        }
        if (info.nextButton) {
          if (info.page >= pageCount) info.nextButton.disable();
          else info.nextButton.enable();
        }
        if (info.onPageChanged) info.onPageChanged(num)
        if (info.onPageChangedHeader) info.onPageChangedHeader(num);
      }
    }
    const i18n = Context.instance.i18n;
    let btnOK = (
      <Button key="modal-assign" className="i-ok-button" gapRight
        onClick={evt => {
          if (info.okButtonClicked) info.okButtonClicked(evt)
        }}
        _onMount={obj => {
          info.okButton = obj
        }}>
        {options.okLabel || i18n.general.ok}
      </Button>
    )
    let btnCancel = (
      <Button key="modal-cancel" className="i-cancel-button" clear gapRight
        onClick={evt => {
          if (info.cancelButtonClicked) info.cancelButtonClicked(evt)
        }}
        _onMount={obj => {
          info.cancelButton = obj
        }}>
        {i18n.general.cancel}
      </Button>
    )
    let btnBack = info.isMultiPage && (
      <Button key="modal-back" clear gapRight disabled
        onClick={evt => {
          if (pageCount !== 2 || showHotelLimits) {
            info.setPage(info.page - 1);
          } else if (showMeetingRoomLimits) {
            info.setPage(info.page - 2);
          }
          if (info.backButtonClicked) info.backButtonClicked(evt)
        }}
        _onMount={obj => {
          info.backButton = obj
        }}>
        {i18n.general.back}
      </Button>
    )
    let btnNext = info.isMultiPage && (
      <Button key="modal-next" clear gapRight
        onClick={evt => {
          if (info.canStepNext && !info.canStepNext()) return;
          if (pageCount !== 2 || showHotelLimits) {
            info.setPage(info.page + 1);
          } else if (showMeetingRoomLimits) {
            info.setPage(info.page + 2);
          }
          if (info.nextButtonClicked) info.nextButtonClicked(evt)
        }}
        _onMount={obj => {
          info.nextButton = obj
        }}>
        {i18n.general.next}
      </Button>
    )
    info.content = (
      <div className="i-flex-between" style={{width: "100%"}}>
        <div>
          {btnBack}
          {btnNext}
        </div>
        <div>
          {btnCancel}
          {btnOK}
        </div>
      </div>
    )
    return info;
  }

  static showModal(props: { caption: string, areaItem?: __esri.Graphic }) {
    let controller, adding = false;
    let areaName = "", areaType: AreaTypes = "workspace", invalidName, data, roleData;
    const i18n = Context.instance.i18n;
    const plan = Context.instance.spaceplanner.activePlan;
    const areasTable = plan && plan.areasTable;
    const isNew = !props.areaItem;
    if (!isNew) {
      areaType = aiimUtil.getAttributeValue(props.areaItem.attributes, FieldNames.AREA_TYPE);
      areaName = aiimUtil.getAttributeValue(props.areaItem.attributes,FieldNames.AREA_NAME) || "";
      invalidName = _AreaProperties.isInvalidPattern(areaName);
    }
    const { config } = Context.getInstance();
    const { workspaceAreas: wsa } = config["spaceplanner"] as IConfigurables["spaceplanner"];
    const isHotdeskOnlyConfigured = wsa.hotDesks.enabled && !(wsa.hotels.enabled || wsa.meetingRooms.enabled);
    const supportsRestricted = !!plan.areasTable.getRestrictedField();
    const supportsBookingLimits = !!plan.supportsBookingLimits();
    const supportsAreaRoles = plan.hasAreaRoles();
    const showHotelLimits = supportsBookingLimits && wsa.hotels.enabled;
    const showMeetingRoomLimits = supportsBookingLimits && wsa.meetingRooms.enabled;
    const isMultiPage = isNew && supportsAreaRoles && supportsBookingLimits && !isHotdeskOnlyConfigured;
    const hasAreaRoles = plan.hasAreaRoles();
    const origAreaName = areaName;

    const onChange = obj => {
      areaName = obj.name.trim();
      invalidName = _AreaProperties.isInvalidPattern(areaName);
      data = obj;
    };

    const onOK = async () => {
      let ok = false;
      if (!adding && areaName.length > 0 && !invalidName) {
        try {
          const r = await _AreaProperties.checkDuplicateName(areaName, origAreaName);
          ok  = !!r.ok;
        } catch(ex) {
          console.error(ex);
          Topic.publishErrorAccessingData();
        }
      }
      if (ok) {
        save();
      }
    }

    const save = () => {
      if (!adding && areaName.length > 0 && !invalidName) {
        if (controller) controller.close();
        const guard = new TransactionGuard({force: true});
        adding = true;
        Promise.resolve().then(() => {
          if (hasAreaRoles) {
            let areaRolesTable = OfficePlan.getActive().areaRolesTable;
            return areaRolesTable.prepUsernames(roleData);
          }
        }).then (() => {
          if (!data) data = {};
          if (roleData) data.roleData = roleData;
          const config = data.config;
          if (config && typeof config !== "string") {
            data.config = JSON.stringify(config);
          }
          if (isNew) {
            Context.instance.session.newHotelConfig = config;
            return areasTable.addArea(areaName,areaType,data);
          } else {
            return areasTable.updateArea(props.areaItem,areaName,data);
          }
        }).then (() => {
          guard.close();
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
        }).catch(error => {
          guard.close();
          console.error("Error adding area", error);
          Topic.publishErrorUpdatingData(error.submessage);
        });
      }
    };

    const onAreaManagersUpdated = (data) => {
      roleData = data;
    }

    let actionBarInfo = _AreaProperties.actionBar({
      isMultiPage: isMultiPage,
      okLabel: isNew ? i18n.spaceplanner.areaProperties.add : i18n.spaceplanner.areaProperties.save,
      showHotelLimits,
      showMeetingRoomLimits
    });
    let actionBarContent = actionBarInfo.content;
    actionBarInfo.okButtonClicked = () => {
      onOK();
    }
    actionBarInfo.cancelButtonClicked = () => {
      if (controller) controller.close();
    }

    const modalProps = {
      dialogStyle: { width: "30vw" },
      title: <AreaPropertiesHeader initialTitle={props.caption} actionBarInfo={actionBarInfo} />,
      showOKCancel: false,
      actionBarContent: actionBarContent
    };

    if (plan && plan.wasOpened && plan.isAreasTableValid() && areasTable) {
      const content = (
        <AreaProperties {...props}
          actionBarInfo={actionBarInfo}
          onChange={onChange}
          supportsRestricted={supportsRestricted}
          supportsBookingLimits={supportsBookingLimits}
          onAreaManagersUpdated={onAreaManagersUpdated} />
      );
      controller = new ModalController({});
      controller.show(modalProps, content);
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const hotdesksEnabled = getHotdesksEnabled(state);
  const hotelsEnabled = getHotelsEnabled(state);
  const meetingRoomsEnabled = getMeetingRoomsEnabled(state);
  const hotelReservationMethod = getHotelReservationMethod(state);
  const meetingRoomReservationMethod = getMeetingRoomReservationMethod(state);
  const { areaItem } = ownProps;
  const isHotdeskOnlyConfigured = hotdesksEnabled && !(hotelsEnabled || meetingRoomsEnabled);
  const methods = {
    ...hotelsEnabled && { hotels: hotelReservationMethod },
    ...meetingRoomsEnabled && { meetingrooms: meetingRoomReservationMethod }
  };

  return {
    disableSettings: areaItem ? isHotdeskOnlyConfigured : false,
    hideLimits: !areaItem ? isHotdeskOnlyConfigured : false,
    hideMaxBookingsHotel: methods.hotels === "office365",
    hideMaxBookingsMeetingRoom: methods.meetingrooms === "office365",
  
    // Hide reservation manager if M365 is set as reservation method for office hotels #7912
    hideReservationManagers: !(!areaItem && (
      (hotelsEnabled && hotelReservationMethod === "esri") || (meetingRoomsEnabled && meetingRoomReservationMethod === "esri")
    )),

    hideRestricted: !areaItem ? isHotdeskOnlyConfigured : false,
    rdxHotdesksEnabled: hotdesksEnabled,
    rdxHotelsEnabled: hotelsEnabled,
    rdxMeetingRoomsEnabled: meetingRoomsEnabled
  }
};

const AreaPropertiesHeader = ({
  initialTitle,
  actionBarInfo
}: {
  initialTitle: string,
  actionBarInfo: {
    page: number,
    onPageChangedHeader: (num: number) => void
  }
  }): JSX.Element => {
  
  const { i18n } = Context.getInstance();
  const [activePage, setActivePage] = useState<number>(actionBarInfo.page)
  
  if (actionBarInfo) {
    actionBarInfo.onPageChangedHeader = num => {
      setActivePage(num);
    }
  }
  const renderTitle = () => {
    switch (activePage) {
      case 1:
        return initialTitle;
      case 2:
        return i18n.spaceplanner.workspaceAreas.hotelSchedulingLimits;
      case 3:
        return i18n.spaceplanner.workspaceAreas.meetingRoomSchedulingLimits;
    }
  }
  return <>{renderTitle()}</>;
}
const AreaProperties = connect(mapStateToProps)(_AreaProperties);

export default AreaProperties;
