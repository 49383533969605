import { IApplyEditsOptions, IFeature } from "@esri/arcgis-rest-feature-layer";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findFieldName } from "../../../aiim/util/aiimUtil";
import { removeOccupantAssignments } from "./occupantUtil";
import Context from "../../../context/Context";
import { LayerType } from "../../../util/interfaces";
import { getLayer } from "../../base/mapUtil";
import { getCapitalizedType, getIdField } from "../../components/Editor/support/formUtil";
import { makeTransactionInfo } from "./transaction";
import { checkUniqueIds, cloneFeature } from "./transactionUtil";

const isValidArray = (arr: (IFeature | __esri.Graphic)[]) => Array.isArray(arr) && arr.length > 0;
export interface IFeatureEditInfo {
  adds?: IFeature[] | __esri.Graphic[],
  deletes?: IFeature[] | __esri.Graphic[],
  type: LayerType,
  undoUpdates?: IFeature[] | __esri.Graphic[],
  updates?: IFeature[] | __esri.Graphic[]
}
export type FeaturesTask = {
  infos: IFeatureEditInfo[]
}
export async function prepare(task: FeaturesTask) {
  const transactionInfo = makeTransactionInfo();
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  const checks = task.infos.map(info => {
    const layer = getLayer(info.type);
    if (!layer.capabilities.editing.supportsGlobalId) {
      throw new Error(`Layer does not support editing with GlobalID: ${layer.url}`);
    }

    if (isValidArray(info.adds)) {
      const fldId = findFieldName(layer.fields, getIdField(info.type));
      const uids = info.adds.map(f => f.attributes[fldId]).filter(x => !!x);
      return checkUniqueIds(uids, layer, fldId).then(dupes => {
        if (isValidArray(dupes) && dupes.length) {
          const { editor } = Context.getInstance().i18n;
          const type = getCapitalizedType(info.type);
          throw new Error(`${editor.footprints.errNonUniqueId
            .replace("{type}", type)
            .replace("{typeLower}", info.type)} [${dupes}]`);
        }
        return { info, layer };
      });  
    }
    return Promise.resolve({ info, layer });
  });

  const taskInfos = await Promise.all(checks);
  
  const makeEdits = async (task: { info: IFeatureEditInfo, layer: __esri.FeatureLayer }) => {
    const { info, layer } = task;
    const layerEdits: Omit<IApplyEditsOptions, "url"> & { id: number } = {
      id: task.layer.layerId,
      adds: [],
      updates: [],
      deletes: []
    };
    const undoLayerEdits: Omit<IApplyEditsOptions, "url"> & { id: number } = {
      id: task.layer.layerId,
      adds: [],
      updates: [],
      deletes: []
    };

    if (isValidArray(info.adds)) {
      layerEdits.adds.push(...info.adds.map(f => cloneFeature(f)));
      undoLayerEdits.deletes = ["?"];
    }
    if (isValidArray(info.updates)) {
      layerEdits.updates.push(...info.updates.map(f => cloneFeature(f)));
    }
    if (isValidArray(info.undoUpdates)) {
      undoLayerEdits.updates.push(...info.undoUpdates.map(f => cloneFeature(f)));
    }
    if (isValidArray(info.deletes)) {
      const objectIdField = layer.objectIdField;
      if (!objectIdField) {
        console.error(`Unable to find ObjectID field on layer, deletes won't be saved: ${layer.url}`);
      }
      const delUnitIds = new Set<string>();
      for (var i = 0; i < info.deletes.length; i++) {
        (layerEdits.deletes as string[]).push(info.deletes[i].attributes[objectIdField]);
        if (info.type === "unit") { // TODO: Level?, Facility?, Site?
          const unitIdFieldName = findFieldName(layer.fields, FieldNames.UNIT_ID);
          if (unitIdFieldName) {
            delUnitIds.add(info.deletes[i].attributes[unitIdFieldName]);
          }
        }
      } 
      
      if (delUnitIds.size > 0) {
        await removeOccupantAssignments(transactionInfo, [...delUnitIds]);
      }
      undoLayerEdits.adds.push(...info.deletes.map(f => cloneFeature(f)));
    }
    if (layerEdits.adds.length || layerEdits.updates.length || layerEdits.deletes.length) {
      if (info.type === "site") {
        transactionInfo.siteEdits = true;
        transactionInfo.undo.siteEdits = true;
      } else if (info.type === "facility") {
        transactionInfo.facilityEdits = true;
        transactionInfo.undo.facilityEdits = true;
      } else if (info.type === "level") {
        transactionInfo.levelEdits = true;
        transactionInfo.undo.levelEdits = true;
      } else if (info.type === "unit") {
        transactionInfo.unitEdits = true;
        transactionInfo.undo.unitEdits = true;
      } else if (info.type === "detail") {
        transactionInfo.detailEdits = true;
        transactionInfo.undo.detailEdits = true;
      } else {
        //TODO: pathways
      }
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  for (let i=0; i<taskInfos.length; i++) {
    await makeEdits(taskInfos[i]);
  }

  return transactionInfo;
}