import Context from "../../context/Context";
import * as workspaceReservationUtil from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";

export function canCheckIntoHotels() {
  const rds = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
  const issues = rds && rds.errorHandler && rds.errorHandler.getErrors();
  const hasIssues = issues && issues.length > 0;
  if (supportsHotels() && !hasIssues) {
    const wr = Context.instance.config.workspaceReservation;
    if (wr.enableHotel && (wr.reservationTypeHotel === "esri")) {
      return true;
    }
  }
  return false;
}

export function canCheckIntoMeetingRooms() {
  const rds = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
  const issues = rds && rds.errorHandlerMeetingRooms && rds.errorHandlerMeetingRooms.getErrors();
  const hasIssues = issues && issues.length > 0;
  if (supportsMeetingRooms() && !hasIssues) {
    const wr = Context.instance.config.workspaceReservation;
    if (wr.enableMeeting && wr.allowMeetingCheckIn && (wr.reservationTypeMeeting === "esri")) {
      return true;
    }
  }
  return false;
}

export function licenseOK() {
  const types = ["indoors", "indoorsspaces"];
  return types.includes(Context.instance.orgLicenseType);
}

export function supportsMeetingRooms() {

  if (!licenseOK() || Context.instance.uiMode.isKiosk || Context.instance.user.isAnonymous()) return false;

  const config = Context.getInstance().config;
  if(config && config.workspaceReservation) {
    const workspaceReservation = config.workspaceReservation;
    const reservationType = workspaceReservation.reservationTypeMeeting;
    const enableMeeting = workspaceReservation.enableMeeting;
    const info = workspaceReservationUtil.getWorkspaceInfo();
    const validationInfo = workspaceReservationUtil.getValidationInfo();
    
    if(!enableMeeting) return false;
    return validationInfo && validationInfo.isValidMeetingRooms;
  }

  return false;
}

export function supportsHotels() {  

  if (!licenseOK() || Context.instance.uiMode.isKiosk || Context.instance.user.isAnonymous()) return false;

  const info = workspaceReservationUtil.getWorkspaceInfo();
  const workspaceReservation = Context.instance.config && Context.instance.config.workspaceReservation;
  const datasets = Context.instance.aiim.datasets;
  const reservations = datasets && datasets.reservations;
  const errorHandler = reservations && reservations.errorHandler;
  const reservationErrors = errorHandler && errorHandler.getErrors();
  const hasReservationIssues = reservationErrors && reservationErrors.length > 0;
  const isHotelToggleOn = workspaceReservation.enableHotel;
  const validationInfo = workspaceReservationUtil.getValidationInfo();

  if (
    info &&
    workspaceReservation &&
    isHotelToggleOn && 
    !Context.getInstance().uiMode.isKiosk
  ) {
    return hasReservationIssues || (validationInfo && validationInfo.isValidHotel)
  }

  return false;
}
