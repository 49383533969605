import React from "react";
import Context from "../../../../context/Context";
import Topic from "../../../../context/Topic";
import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-menu";
import "@esri/calcite-components/dist/components/calcite-avatar";
import "@esri/calcite-components/dist/components/calcite-block";
import { JSX as CalciteJSX } from "@esri/calcite-components";
import {
  CalciteAction,
  CalciteActionMenu,
  CalciteAvatar,
  CalciteBlock
} from "@esri/calcite-components-react";
interface IUserControlProps {
  placement?: CalciteJSX.CalciteActionMenu["placement"]
}

const UserControl = ({
  placement = "bottom-end"
}: IUserControlProps) => {
  const { i18n, user } = Context.getInstance();
  return (
    
    <CalciteActionMenu label="User" placement={placement} overlayPositioning="fixed">
      <CalciteAction slot="trigger" text="">
        <CalciteAvatar fullName={user.getFullName()} username={user.getUsername()}></CalciteAvatar>
      </CalciteAction>
      <CalciteBlock heading={user.getFullName()} description={user.getUsername()}>
      </CalciteBlock>
      {false && <CalciteAction icon="gear" text={i18n.spaceplanner.userSettings.title} textEnabled disabled></CalciteAction>}
      <CalciteAction icon="sign-out" text={i18n.user.signout} active textEnabled onClick={() =>
        Topic.publish(Topic.SignOutClicked, {})}></CalciteAction>
    </CalciteActionMenu>
  )
}

export default UserControl;