import React from 'react';

import SignOutIcon from "calcite-ui-icons-react/SignOutIcon";
import Button from "calcite-react/Button";

import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';
import FieldNames from '../../../../../aiim/datasets/FieldNames';

import { ModalController }  from "../../../../../../src/common/components/Modal/index";
import { updateCheckInOut } from "./WorkspaceReservation/OfficeHotelingInterface";
import { isNetworkError } from '../../../../../util/networkUtil';
import { getHotelBookingSystem, getMeetingBookingSystem } from './WorkspaceReservation/workspaceReservationUtil';
import { getAttributeValue } from '../../../../../aiim/util/aiimUtil';

import * as checkInOutUtil from "./CheckInOutUtil";

const CSS = {
    buttonCheckOut: "i-book-card-button-checkOut"
}

export default class CheckIn extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            attributes: this.props.attributes
        }
    }

    updateStatus =()=> {
        const i18n = Context.getInstance().i18n;
        const attributes = this.state.attributes;
        const reservations = Context.getInstance().aiim.datasets.reservations;
        const { bookingType } = this.props;
        const bookingSystem = (bookingType === "meetingRoom") ? getMeetingBookingSystem() : getHotelBookingSystem();
        const options = {
            title: i18n.more.bookWorkspace.checkInOut.checkOut.caption,
            message: i18n.more.bookWorkspace.checkInOut.checkOut.message,
            okLabel: i18n.more.bookWorkspace.checkInOut.checkOut.yes,
            cancelLabel: i18n.more.bookWorkspace.checkInOut.checkOut.no,
            closeOnOK: true,
            closeOnCancel: true
        };
        ModalController.confirm(options)
        .then(result => {
            if(result.ok) {
                attributes[reservations.checkOutTimeField] = new Date().getTime();

                updateCheckInOut(bookingSystem, attributes, "checkOut")
                .then((res)=> {
                    if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
                        res.updateFeatureResults[0].error){
                            Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
                    } else {
                        const type = this.props.type;
                        const person = getAttributeValue(attributes, FieldNames.RESERVED_FOR_FULL_NAME);
                        let msg = i18n.more.bookWorkspace.checkInOut.checkOut.toastMessage;
                        if (type === "otherbookings") {
                            msg = i18n.more.bookWorkspace.checkInOut.someoneElse.confirmationSubtitleCheckOut;
                            msg = msg.replace("{person}", person);
                        }
                        Topic.publish(Topic.ShowToast,{
                            message: msg
                        });
                        Topic.publish(Topic.RenderBookingList, {});
                    }
                })
                .catch((error) => {
                    if(error && isNetworkError(error.message)) {
                        checkInOutUtil.showServerErrorPopup("checkOut", attributes, bookingType);
                    } else {
                        Topic.publishErrorUpdatingData(error);
                    }
                    console.error(error);
                })
            }
        })
        .catch(error => {
            console.error("error",error);
            Topic.publishErrorUpdatingData(error)
        })
    }

    render() {
        const i18n = Context.getInstance().i18n;
        const label = i18n.more.bookWorkspace.checkInOut.checkOut.caption;
        const icon = (<SignOutIcon size={20}/>);
        const cls = CSS.buttonCheckOut;
        return (
            <div className={cls}>
                <Button
                    icon={icon}
                    iconPosition="before"
                    onClick={this.updateStatus}>
                    {label}
                </Button>
            </div>
        )
    }
}
