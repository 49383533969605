import React from "react";
import * as component from "../../../../util/component";
import Icons from "../../../../util/Icons";
import DropdownButton from "../../../../common/Dropdown/DropdownButton";
import FilterOption from "./FilterOption";
import Context from "../../../../../context/Context";
import { Topic } from "../../../../../util/tsUtil";
import {connect} from "react-redux";

import * as bookWorkspaceUtil from "./BookWorkspaceUtil";
import Rdx from "../../../../../redux/Rdx";

const CSS = {
  main: "i-events-datefilter",
  optionsContainer: "i-events-options-container",
  options: "i-events-options",
  panelHeader: "i-infopanel-header i-panel-header",
  equipmentFilter: "i-equipment-filter",
  button: "i-book-button",
  filterForm: "i-filter-form i-popup",
  equipmentFilterLabel: "i-equipment-filter-label"
};

class EquipmentFilterDropdown extends React.Component {

  constructor(props) {
    super(props);
    const { initialFilter } = props;
    this.state = component.newState({
      selectedValuesMeetingRooms: this.props.checkedEquipments || [],
      anySelectedMeetingRooms: this.props.checkedEquipments && this.props.checkedEquipments.length === 0,
      selectedValues: initialFilter ? [] : this.props.rdxEquipments,
      anySelected: initialFilter ? true : this.props.rdxEquipments.length === 0
    })
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.SpaceFiltersSubmit, params => {
        Rdx.setValue(this, Rdx.Keys.EQUIPMENT_FILTERS, this.state.selectedValues)
      }),
      Topic.subscribe(Topic.FiltersMeetingChange, params => {
        this.setState({
            selectedValuesMeetingRooms: [],
            anySelectedMeetingRooms: true
        })
      }),
      Topic.subscribe(Topic.FilterAreaChange, params => {
        bookWorkspaceUtil.getEquipmentBySpaceTypeAreaSite(this.props.rdxAreas, this.props.rdxSite, this.props.rdxSpaceTypes)
        .then((result)=> {
          if(result && result.equipmentsDropdown && result.equipmentsDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, result.equipmentsDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
          console.error(error);
        })
      }),
      Topic.subscribe(Topic.FilterSiteChange, params => {
        bookWorkspaceUtil.getEquipmentBySpaceTypeAreaSite(this.props.rdxAreas, this.props.rdxSite, this.props.rdxSpaceTypes)
        .then((result)=> {
          if(result && result.equipmentsDropdown && result.equipmentsDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, result.equipmentsDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
          console.error(error);
        })
      }),
      Topic.subscribe(Topic.FilterSpaceTypeChange, params => {
        bookWorkspaceUtil.getEquipmentBySpaceTypeAreaSite(this.props.rdxAreas, this.props.rdxSite, this.props.rdxSpaceTypes)
        .then((result)=> {
          if(result && result.equipmentsDropdown && result.equipmentsDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, result.equipmentsDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
          console.error(error);
        })
      }),
    Topic.subscribe(Topic.BookFiltersCleared, params => {
        Rdx.setValue(this, Rdx.Keys.EQUIPMENT_FILTERS, []);
        bookWorkspaceUtil.getEquipmentBySpaceTypeAreaSite([], null, [])
        .then((result)=> {
          if(result && result.equipmentsDropdown && result.equipmentsDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, result.equipmentsDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
          console.error(error);
        })
      })
    ])
  }

  onClick = (option) => {
    if(this.props.type === "meetingRooms") {
        let values;
        if (option.props.checked) {
            values = this.state.selectedValuesMeetingRooms;
            const index = values.indexOf(option.props.value)
            if (index > -1) {
             values.splice(index, 1)
            }
            const anySelected = values.length === 0
            this.setState({
                selectedValuesMeetingRooms: values,
                anySelectedMeetingRooms: anySelected
            })
        } else {
            values = this.state.selectedValuesMeetingRooms;
            values.push(option.props.value)
            this.setState({
                selectedValuesMeetingRooms: values,
                anySelectedMeetingRooms: false
            })
        }
      this.props.onChange(values);
    } else {
      if (option.props.checked) {
        let values = this.state.selectedValues
        const index = values.indexOf(option.props.value)
        if (index > -1) {
          values.splice(index, 1)
        }
        const anySelected = values.length === 0
        this.setState({
          selectedValues: values,
          anySelected: anySelected
        })
      } else {
        let values = this.state.selectedValues
        values.push(option.props.value)
        this.setState({
          selectedValues: values,
          anySelected: false
        })
      }
    }
  }

  onAnyClicked = (option) => {
    if(this.props.type === "meetingRooms") {
      if (option.props.checked) {
          if (this.state.selectedValuesMeetingRooms.length !== 0) {
            this.setState({
              anySelected: false
            })
          }
        } else {
          this.setState({
            selectedValuesMeetingRooms: [],
            anySelectedMeetingRooms: true
          })
        }
        this.props.onChange([]);
    } else {
      if (option.props.checked) {
        if (this.state.selectedValues.length !== 0) {
          this.setState({
            anySelected: false
          })
        }
      } else {
        this.setState({
          selectedValues: [],
          anySelected: true
        })
      }
    }
  }

  getAliasName(fieldName) {
    const units = Context.getInstance().aiim.datasets.units;
    if(!units) return false;
    if(units && units.layer2D && units.layer2D.fields && units.layer2D.fields.length > 0) {
      const fields = units.layer2D.fields;
      for(let i=0;i<fields.length;i++){
        const field = fields[i];
        if(field.name.toLowerCase() === fieldName.toLowerCase()) {
          return field.alias || fieldName;
        }
      }
    }
    return fieldName;
  }

  getRenderItems=()=> {
    let options;
    if(this.props.type === "meetingRooms") {
      const obj = [];
      this.props.equipmentOptions.forEach(fieldName => {
        obj.push({
          alias: this.getAliasName(fieldName),
          fieldName: fieldName
        })
      })
      obj.sort((a,b) => {
        if(a.alias && b.alias) return a.alias.localeCompare(b.alias);
        return 0;
      })

      options = obj.map(item => {
          let checked = false
          if (this.state.selectedValuesMeetingRooms.includes(item.fieldName)) checked = true
          return (
            <FilterOption key={item.fieldName} label={item.alias} value={item.fieldName} 
              checked={checked} onClick={this.onClick} />
          )
      })
      
      const i18n = Context.instance.i18n
      const any = i18n.more.bookWorkspace.any
      options.unshift(
          <FilterOption key={"any"} label={any} value={any} checked={this.state.anySelectedMeetingRooms} 
            onClick={this.onAnyClicked} />
      )
    } else {
      const equipmentDropdown = this.props.rdxEquipmentDropdown;
      const obj = [];
      equipmentDropdown.forEach(fieldName => {
        obj.push({
          alias: this.getAliasName(fieldName),
          fieldName: fieldName
        });
      })
      obj.sort((a,b) => {
        if(a.alias && b.alias) return a.alias.localeCompare(b.alias);
        return 0;
      })
      options = obj.map(item => {
        let checked = false
        if (this.state.selectedValues.includes(item.fieldName)) checked = true
        return (
          <FilterOption key={item.fieldName} label={item.alias} value={item.fieldName} 
            checked={checked} onClick={this.onClick} />
        )
      })
      const i18n = Context.instance.i18n
      const any = i18n.more.bookWorkspace.any
      options.unshift(
        <FilterOption key={"any"} label={any} value={any} checked={this.state.anySelected} 
          onClick={this.onAnyClicked} />
      )
    }
    return options;
  }

  getCaption=()=> {
    let caption = ""
    const i18n = Context.instance.i18n
    const any = i18n.more.bookWorkspace.any
    let values;
    if(this.props.type === "meetingRooms") values = this.state.selectedValuesMeetingRooms;
    else values = this.state.selectedValues;

    for (var i = 0; i < values.length; i++) {
        const aliasName = this.getAliasName(values[i]);
        if (i < values.length - 1) {
          caption += aliasName + ", "
        } else {
          caption += aliasName
        }
    }
    if (caption === "") {
      caption = any
    }
    if (caption.length > 26) {
      caption = caption.substring(0, 26)
      let len = caption.length
      if (caption[len - 1] === ' ' || caption[len - 1] === ',') {
        caption = caption.replace(/.$/, '')
      }
      caption += "..."
    }
    return caption;
  }

  render() {
    const options = this.getRenderItems();
    const caption = this.getCaption();
    let icon = Icons.doubleVertical();
    const i18n = Context.instance.i18n;

    const buttonContent = (
      <div role="button" aria-label={i18n.more.bookWorkspace.equipmentListAriaLabel}>
        <span key="caption">{caption}</span>
        <span key="icon">{icon}</span>
      </div>
    );
    const dropdownContent = (
      <ul className="i-equipment-filter-list" role="menu">{options}</ul>
    );
    return (
        <div className={CSS.equipmentFilter}>
          <div className={CSS.optionsContainer}>
            <div className={CSS.options}>
              <div className={CSS.main}>
                <DropdownButton
                  closeOnSelect={false}
                  focusFirst={true}
                  buttonClassName={"i-choice-dd"}
                  buttonContent={buttonContent}
                  dropdownContent={dropdownContent}
                  disabled={false}
                  placement={"bottom-start"}>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rdxSite: Rdx.getValue(state, Rdx.Keys.SITE_FILTERS),
    rdxAreas: Rdx.getValue(state, Rdx.Keys.AREA_FILTERS),
    rdxSpaceTypes: Rdx.getValue(state, Rdx.Keys.SPACETYPE_FILTERS),
    rdxEquipments: Rdx.getValue(state, Rdx.Keys.EQUIPMENT_FILTERS),
    rdxEquipmentDropdown: Rdx.getValue(state, Rdx.Keys.EQUIPMENT_DROPDOWN)
  }
}

export default connect(mapStateToProps)(EquipmentFilterDropdown);
