import Context from "../../../context/Context";
import OfficePlan from "../OfficePlan";
import * as serviceUtil from "../serviceUtil";
import * as sourceUtil from "../sourceUtil";
import { IApplyEditsOptions, IApplyEditsResult } from "@esri/arcgis-rest-feature-layer";

export const TIMEOUT = (60000 * 30);
/** Represents the results from an `applyEdits` operation of a feature service.
 * [Read more](https://developers.arcgis.com/rest/services-reference/enterprise/apply-edits-feature-service-.htm#GUID-3AFF21CA-FB1A-42FC-A0C0-EB56EF5C9317)
 */
export type IApplyEditsResults = (IApplyEditsResult & { id?: number })[];
export interface ITransaction {
  peopleEdits: boolean,
  unitEdits: boolean,
  areasTableEdits: boolean,
  detailEdits?: boolean,
  siteEdits?: boolean,
  facilityEdits?: boolean,
  levelEdits?: boolean,
  edits: (Omit<IApplyEditsOptions, "url"> & { id: number })[],
  useGlobalIds: boolean,
  supportInfo?: ISupportInfo,
  afterApplyEdits?: (results: IApplyEditsResults, undoTransaction: IUndoRedoTransaction, action: string) => void
}
export interface ISupportInfo {
  reviewersTableEdits: IApplyEditsOptions,
  planReviewTableEdits: IApplyEditsOptions,
  serviceUrl: string
}
export interface ITransactionInfo extends ITransaction {
  supportsUndo: boolean,
  undo: ITransaction,
}
export interface IUndoRedoTransaction {
  id: string,
  wasCompleted: boolean,
  failed: boolean,
  hadPeopleEdits: boolean,
  hadUnitEdits: boolean,
  hadDetailEdits: boolean,
  hadSiteEdits: boolean,
  hadFacilityEdits: boolean,
  hadLevelEdits: boolean,
  hadAreasTableEdits: boolean,
  requestInfo: IRequestInfo,
  redoTransaction: IUndoRedoTransaction,
  afterApplyEdits?: (results: IApplyEditsResults, undoTransaction: IUndoRedoTransaction, action: string) => void
}
export interface IRequestInfo {
  requestUrl: string,
  requestOptions: __esri.RequestOptions 
}

export function applyEdits(transactionInfo: ITransactionInfo) {
  const promise = new Promise<IApplyEditsResults>((resolve,reject) => {
    beforeApply(transactionInfo);
    const edits = transactionInfo.edits;

    const supportsUndo = transactionInfo.supportsUndo;
    const undoManager = OfficePlan.getActive().undoManager;
    const undoTransaction =  supportsUndo ? makeUndoTransaction(transactionInfo) : null;
    //undoManager.addTransaction(undoTransaction);

    const reqInfo = makeApplyEditsRequestInfo(edits,transactionInfo);
    const reqUrl = reqInfo.requestUrl;
    const reqOpts: __esri.RequestOptions = reqInfo.requestOptions;

    console.log("transaction.applyEdits.transactionInfo",transactionInfo)
    console.log("transaction.applyEdits.requestInfo",reqInfo)

    //if (true) {reject(new Error("test")); return;}

    const esriRequest: typeof __esri.request = Context.instance.lib.esri.esriRequest;
    let requestResult: IApplyEditsResults;
    esriRequest(reqUrl, reqOpts).then(result => {
      console.log("transaction.applyEdits.result",result);
      serviceUtil.validateApplyEditsResponse(result);
      if (supportsUndo) {
        undoManager.addTransaction(undoTransaction);
      }
      const results: IApplyEditsResults = (result && result.data);
      requestResult = results;

      fixIdentifiers(results,undoTransaction,"edit");

      if (transactionInfo.afterApplyEdits) {
        transactionInfo.afterApplyEdits(results,undoTransaction,"edit");
      }

    }).then(() => {
      const promise = refreshLayers(transactionInfo);
      const waitForRefresh = transactionInfo.siteEdits || transactionInfo.facilityEdits || transactionInfo.levelEdits;
      return waitForRefresh ? promise : undefined;
    }).then(() => {
      if (Context.instance.isSP()) {
        if (transactionInfo.peopleEdits || transactionInfo.unitEdits) {
          setTimeout(() => {
            const peopleLayer = sourceUtil.getPeopleLayer();
            const unitsLayer = sourceUtil.getUnitsLayer();
            if (transactionInfo.peopleEdits) peopleLayer && peopleLayer.refresh();
            if (transactionInfo.unitEdits) unitsLayer && unitsLayer.refresh();
          },3000);
        }
      }
    }).then(() => {
      console.log("transaction edits applied................")
      resolve(requestResult);
    }).catch(ex => {
      reject(ex);
    });

  });
  return promise;
}

export function beforeApply(transactionInfo: ITransactionInfo) {
  const peopleLayer = sourceUtil.getPeopleLayer();
  const unitsLayer = sourceUtil.getUnitsLayer();
  const detailsLayer = sourceUtil.getDetailsLayer();
  const sitesLayer = sourceUtil.getSitesLayer();
  const facilitiesLayer = sourceUtil.getFacilitiesLayer();
  const levelsLayer = sourceUtil.getLevelsLayer();
  const areasTbl = OfficePlan.getActive().areasTable && OfficePlan.getActive().areasTable.table;
  const edits = transactionInfo.edits;
  const hasEdits = layerEdit => {
    return (layerEdit.adds && (layerEdit.adds.length > 0))
        || (layerEdit.updates && (layerEdit.updates.length > 0))
        || (layerEdit.deletes && (layerEdit.deletes.length > 0))
  }
  edits && edits.forEach(layerEdit => {
    if (layerEdit.id === (peopleLayer && peopleLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.peopleEdits = true;
    }
    if (layerEdit.id === (unitsLayer && unitsLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.unitEdits = true;
    }
    if (layerEdit.id === (detailsLayer && detailsLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.detailEdits = true;
    }
    if (layerEdit.id === (sitesLayer && sitesLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.siteEdits = true;
    }
    if (layerEdit.id === (facilitiesLayer && facilitiesLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.facilityEdits = true;
    }
    if (layerEdit.id === (levelsLayer && levelsLayer.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.levelEdits = true;
    }
    if (layerEdit.id === (areasTbl && areasTbl.layerId)) {
      if (hasEdits(layerEdit)) transactionInfo.areasTableEdits = true;
    }
  })
}

export function fixIdentifiers(
  applyEditResults: IApplyEditsResults,
  undoTransaction: IUndoRedoTransaction,
  transactionMethod: "edit" | "undo" | "redo",
  undoRedoStack?: IUndoRedoTransaction[]
) {
  // - track idendifiers created by an 'add' to support subsequent deletion (undo-redo)
  // - to undo a delete on enterprise we re-add the feature, new identifiers are created, the stack is repaired

  const unitsLayer = sourceUtil.getUnitsLayer();
  const detailsLayer = sourceUtil.getDetailsLayer();
  const occupantsLayer = sourceUtil.getPeopleLayer();
  const sitesLayer = sourceUtil.getSitesLayer();
  const facilitiesLayer = sourceUtil.getFacilitiesLayer();
  const levelsLayer = sourceUtil.getLevelsLayer();
  const areasTbl = OfficePlan.getActive().areasTable && OfficePlan.getActive().areasTable.table;

  const isUndo = (transactionMethod === "undo");
  const isRedo = (transactionMethod === "redo");
  const isNormalEdit = !isUndo && !isRedo;

  applyEditResults && applyEditResults.forEach(layerResult => {
    let layer;
    if (layerResult.id === (occupantsLayer && occupantsLayer.layerId)) layer = occupantsLayer;
    else if (layerResult.id === (unitsLayer && unitsLayer.layerId)) layer = unitsLayer;
    else if (layerResult.id === (detailsLayer && detailsLayer.layerId)) layer = detailsLayer;
    else if (layerResult.id === (sitesLayer && sitesLayer.layerId)) layer = sitesLayer;
    else if (layerResult.id === (facilitiesLayer && facilitiesLayer.layerId)) layer = facilitiesLayer;
    else if (layerResult.id === (levelsLayer && levelsLayer.layerId)) layer = levelsLayer;
    else if (layerResult.id === (areasTbl && areasTbl.layerId)) layer = areasTbl;
    if (!layer) return;

    const layerId = layer.layerId;

    const newOids = [], newGids = [];
    const oidLookup = {}, gidLookup = {};
    let layerIdentifiers;

    if (layerResult.addResults && layerResult.addResults.length > 0) {

      let requestInfo;
      if (isNormalEdit) {
        // the transaction was an add, the undoTransaction is the delete
        requestInfo = undoTransaction.requestInfo;
      } else if (isUndo) {
        // the undoTransaction was an add, the redoTransaction is the delete
        requestInfo = undoTransaction.redoTransaction.requestInfo;
      } else if (isRedo) {
        // the redoTransaction was an add, the undoTransaction is the delete
        requestInfo = undoTransaction.requestInfo;
      }

      const undoManager = OfficePlan.getActive().undoManager;
      if (!undoManager.identifiers) undoManager.identifiers= {};
      if (!undoManager.identifiers[layerId]) {
        undoManager.identifiers[layerId]= {
          id: layerId,
          oidsByGID: {},
          gidsByOID: {},
        }
      }
      layerIdentifiers = undoManager.identifiers[layerId];

      layerResult.addResults.forEach(addResult => {
        const oid = addResult.objectId;
        const gid = addResult.globalId;
        newOids.push(oid);
        newGids.push(gid);
        if (typeof layerIdentifiers.oidsByGID[gid] === "number") {
          console.warn("*** WARNING *** GlobalId has been reused:", gid);
        }
        layerIdentifiers.oidsByGID[gid] = oid;
        layerIdentifiers.gidsByOID[oid] = gid;
      })

      const query = requestInfo && requestInfo.requestOptions.query;
      let edits = query && JSON.parse(query.edits);
      let changed = false;
      if (Array.isArray(edits)) {
        edits.forEach(layerEdit => {
          if (layerEdit.id === layerId) {
            if (layerEdit.deletes && layerEdit.deletes.length > 0) {
              //console.log("newOids",newOids)
              //console.log("newGids",newGids)
              //console.log(layer.title,"deletes",layerEdit.deletes)
              //console.log(layer.title,"identifiers",layerIdentifiers)
              layerEdit.deletes.forEach((k,i) => {
                if (query.useGlobalIds && k !== "?") {
                  const oldGid = k;
                  const newGid = newGids[i];
                  if (newGid) {
                    gidLookup[oldGid] = newGid;
                  }
                  const oldOid = layerIdentifiers.oidsByGID[oldGid];
                  const newOid = newOids[i];
                  if (typeof newOid === "number") {
                    oidLookup[oldOid] = newOid;
                  }
                  if (!oldGid) console.warn("*** WARNING *** oldGid is invalid:", oldGid);
                  if (!newGid) console.warn("*** WARNING *** newGid could not be located:", newGid, newGids);
                  if (typeof oldOid !== "number") console.warn("*** WARNING *** oldOid could not be located:", oldOid, oldGid, layerIdentifiers);
                  if (typeof newOid !== "number") console.warn("*** WARNING *** newOid could not be located::", newOid, newOids);
                } else if (k !== "?") {
                  const oldOid = k;
                  const newOid = newOids[i];
                  if (typeof newOid === "number") {
                    oidLookup[oldOid] = newOid;
                  }
                  const oldGid = layerIdentifiers.gidsByOID[oldOid];
                  const newGid = newGids[i];
                  if (newGid) {
                    gidLookup[oldGid] = newGid;
                  }
                  if (typeof oldOid !== "number") console.warn("*** WARNING *** oldOid is invalid:", oldOid);
                  if (typeof newOid !== "number") console.warn("*** WARNING *** newOid could not be located::", newOid, newOids);
                  if (!oldGid) console.warn("*** WARNING *** oldGid could not be located:", oldGid, oldOid, layerIdentifiers);
                  if (!newGid) console.warn("*** WARNING *** newGid could not be located:", newGid, newGids);
                }
              })
              //console.log(layer.title,"gidLookup",gidLookup)
              //console.log(layer.title,"oidLookup",oidLookup)
              if (query.useGlobalIds) {
                layerEdit.deletes = newGids;
                changed = true;
              } else {
                layerEdit.deletes = newOids;
                changed = true;
              }
            }
          }
        })
      }
      if (changed) query.edits = JSON.stringify(edits);
    }

    (newGids.length > 0) && undoRedoStack && undoRedoStack.forEach(t => {
      if (t !== undoTransaction) {
        const requestInfos = [t.requestInfo];
        if (t.redoTransaction) requestInfos.push(t.redoTransaction.requestInfo);
        requestInfos.forEach(requestInfo => {
          const query = requestInfo.requestOptions.query;
          let edits = query && JSON.parse(query.edits);
          let changed = false;
          let lookup, idField;
          if (query.useGlobalIds) {
            lookup = gidLookup;
            idField = layer.globalIdField;
          } else {
            lookup = oidLookup;
            idField = layer.objectIdField;
          }
          if (Array.isArray(edits)) {
            edits.forEach(layerEdit => {
              if (layerEdit.id === layerId) {
                if (layerEdit.updates && layerEdit.updates.length > 0) {
                  layerEdit.updates.forEach(update => {
                    const id = update.attributes[idField];
                    const newId = lookup[id];
                    if ((newId || newId === 0) && (id !== newId)) {
                      update.attributes[idField] = newId;
                      changed = true;
                    }
                  })
                } 
                if (layerEdit.deletes && layerEdit.deletes.length > 0) {
                  layerEdit.deletes.forEach((k,i) => {
                    const newId = lookup[k];
                    if ((newId || newId === 0) && (k !== newId)) {
                      layerEdit.deletes[i] = newId;
                      changed = true;
                    }
                  })
                }
              }
            })
          }
          if (changed) query.edits = JSON.stringify(edits);
        })
      }
    })

  })
}

export function makeApplyEditsRequestInfo(edits: (Omit<IApplyEditsOptions, "url"> & { id: number })[], opts: ITransaction) {
  const unitsLayer = sourceUtil.getUnitsLayer();
  const reviewersTableEdits = opts.supportInfo && opts.supportInfo.reviewersTableEdits
  const supportInfoServiceUrl = opts.supportInfo && opts.supportInfo.serviceUrl
  const serviceUrl = (reviewersTableEdits && supportInfoServiceUrl) || unitsLayer.url;
  const editOptions: IApplyEditsOptions = {
    // @ts-ignore
    edits: JSON.stringify(edits),
    rollbackOnFailure: true,
    f: "json"
  };
  if (unitsLayer.gdbVersion) editOptions.gdbVersion = unitsLayer.gdbVersion;
  if (opts && opts.useGlobalIds) editOptions.useGlobalIds = true;
  const transaction: IRequestInfo = {
    requestUrl: serviceUrl+"/applyEdits",
    requestOptions: <__esri.RequestOptions>{
      query: editOptions,
      method: "post",
      responseType: "json",
      timeout: TIMEOUT
    }
  };
  return transaction;
}

export function makeTransactionInfo() {
  const transactionInfo: ITransactionInfo = {
    peopleEdits: null,
    unitEdits: null,
    areasTableEdits: null,
    supportInfo: {
      reviewersTableEdits: null,
      planReviewTableEdits: null,
      serviceUrl: null
    },
    edits: [],
    useGlobalIds: true,
    afterApplyEdits: null,
    supportsUndo: true,
    undo: {
      peopleEdits: null,
      unitEdits: null,
      areasTableEdits: null,
      edits: [],
      useGlobalIds: true
    }
  };
  return transactionInfo;
}

export function makeUndoTransaction(transactionInfo: ITransactionInfo) {
  const edits = transactionInfo.edits;
  const undoEdits = transactionInfo.undo.edits;
  const undoManager = OfficePlan.getActive().undoManager;
  const undoTransaction = undoManager.makeTransaction({
    hadPeopleEdits: !!transactionInfo.peopleEdits,
    hadUnitEdits: !!transactionInfo.unitEdits,
    hadDetailEdits: !!transactionInfo.detailEdits,
    hadSiteEdits: !!transactionInfo.siteEdits,
    hadFacilityEdits: !!transactionInfo.facilityEdits,
    hadLevelEdits: !!transactionInfo.levelEdits,
    hadAreasTableEdits: !!transactionInfo.areasTableEdits,
    requestInfo: makeApplyEditsRequestInfo(undoEdits,transactionInfo.undo)
  });
  undoTransaction.redoTransaction = undoManager.makeTransaction({
    hadPeopleEdits: !!transactionInfo.peopleEdits,
    hadUnitEdits: !!transactionInfo.unitEdits,
    hadDetailEdits: !!transactionInfo.detailEdits,
    hadSiteEdits: !!transactionInfo.siteEdits,
    hadFacilityEdits: !!transactionInfo.facilityEdits,
    hadLevelEdits: !!transactionInfo.levelEdits,
    hadAreasTableEdits: !!transactionInfo.areasTableEdits,
    requestInfo: makeApplyEditsRequestInfo(edits,transactionInfo)
  });
  undoTransaction.afterApplyEdits = transactionInfo.undo.afterApplyEdits;
  undoTransaction.redoTransaction.afterApplyEdits = transactionInfo.afterApplyEdits;
  console.log("undoTransaction",undoTransaction);
  return undoTransaction;
}

export async function refreshLayers(transactionInfo: ITransaction | IUndoRedoTransaction) {
  try {
    const peopleLayer = sourceUtil.getPeopleLayer();
    const unitsLayer = sourceUtil.getUnitsLayer();
    const detailsLayer = sourceUtil.getDetailsLayer();
    const sitesLayer = sourceUtil.getSitesLayer();
    const facilitiesLayer = sourceUtil.getFacilitiesLayer();
    const levelsLayer = sourceUtil.getLevelsLayer();
    const sflPromises = [];
    const lyrs = [
      ((transactionInfo as ITransaction).peopleEdits || (transactionInfo as IUndoRedoTransaction).hadPeopleEdits) && peopleLayer,
      ((transactionInfo as ITransaction).unitEdits || (transactionInfo as IUndoRedoTransaction).hadUnitEdits) && unitsLayer,
      ((transactionInfo as ITransaction).detailEdits || (transactionInfo as IUndoRedoTransaction).hadDetailEdits) && detailsLayer
    ];
    const sflLayers = [
      ((transactionInfo as ITransaction).siteEdits || (transactionInfo as IUndoRedoTransaction).hadSiteEdits) && sitesLayer,
      ((transactionInfo as ITransaction).facilityEdits || (transactionInfo as IUndoRedoTransaction).hadFacilityEdits) && facilitiesLayer,
      ((transactionInfo as ITransaction).levelEdits || (transactionInfo as IUndoRedoTransaction).hadLevelEdits) && levelsLayer
    ];
    lyrs.forEach(lyr => {
      if (lyr && typeof lyr.refresh === "function") lyr.refresh();
    })
    sflLayers.forEach(lyr => {
      if (lyr && typeof lyr.refresh === "function") sflPromises.push(lyr.refresh());
    })
    if (sflPromises.length > 0) {
      await Promise.all(sflPromises).then(() => {
        if (Context.instance.views && Context.instance.views.floorFilter) {
          return Context.instance.views.floorFilter.refreshData();
        }
      }).catch(ex => {
        console.error(ex);
      })
    }
    if ((transactionInfo as IUndoRedoTransaction).hadAreasTableEdits) {
      const areasTable = OfficePlan.getActive().areasTable;
      if (areasTable && areasTable.table) {
        return OfficePlan.getActive().areasTable._refresh();
      }
    }
  } catch(ex) {
    console.error(ex);
  }
}
