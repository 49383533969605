import React from "react";
import ReactDOM from "react-dom";
import QRCodePopup from "../QRCode/QRCodePopup";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import ItemReference from "../../../aiim/base/ItemReference";
import * as shareUtil from "./shareUtil";

const CSS = {
  expanded: "i-expanded",
  backnext: "i-infopanel-backnext",
  getQR: "i-getQR-btn"
};

export default class QRCode extends React.Component {
    
    constructor(props) {
      super(props);
      this.qrCodeRef = React.createRef();
      this.state = {
          stackItem: null,
          shareUrl: null
      }
    }

    componentDidMount(){
      this.setShareUrl();
    }

    getShareItem =()=> {
      return new Promise((resolve, reject)=> {
        if (this.props.stack.length === 0) return null;
        const stackItem = this.props.stack[this.props.stackPosition];
        const sourceKey = stackItem.sourceKey;
        const searchResult = stackItem.searchResult;
        const item = new ItemReference();
        item.fromSearchResult(sourceKey,searchResult);
        shareUtil.generateShareUrlAsync(item)
        .then((url)=> {
          resolve({stackItem: stackItem, shareUrl: url})
        })
        .catch(error => {
          console.error(error);
          reject(error);
        })
      })
    }

    setShareUrl= async()=> {
      await this.getShareItem()
      .then((item)=> {
          this.setState({
            stackItem: item.stackItem,
            shareUrl: item.shareUrl
          })
      })
      .catch(error => {
        console.error(error);
      })
    }

    showQR = async (event) => {
        const node = document.createElement("div");
        document.body.appendChild(node);
    
        const onClose = () => {
          if (node && node.parentNode) {
            node.parentNode.removeChild(node);
            ReactDOM.unmountComponentAtNode(node)
            if(this.qrCodeRef && this.qrCodeRef.current && this.qrCodeRef.current.focus){
              event.preventDefault();
              setTimeout(()=>{
                this.qrCodeRef.current.focus();
              }, 1);
            }
          }
        };
    
        const QRSize = 300;
        await this.setShareUrl();
        const stackItem = this.state.stackItem;
        const shareUrl = this.state.shareUrl;
        if (stackItem && shareUrl) {
          ReactDOM.render((
            <QRCodePopup
              caption={stackItem.searchResult.name}
              shareUrl={shareUrl}
              QRSize={QRSize}
              closePopup={onClose}
            />
          ), node);
        }
    }

    render() {
        const i18n = Context.getInstance().i18n;
        const shareUrl = this.state.shareUrl;
        let showQR = false;
        const isKiosk = Context.getInstance().uiMode.isKiosk;
        let isQRCodeEnabled;
        if(isKiosk){
          isQRCodeEnabled = Context.getInstance().config.kiosk.kioskQRCode.enabled;
          showQR = shareUrl && isQRCodeEnabled;
        }else{
          const userAgent = navigator.userAgent.toLowerCase();
          const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
          if(isTablet) showQR = shareUrl && true;
          else showQR = shareUrl && !Context.instance.uiMode.isMobile;
        }
        if(showQR) {
          return(  
              <div className={CSS.getQR}>
                  <button ref={this.qrCodeRef} onClick={this.showQR}>
                      <div>{i18n.QRCode.scanPrompt}</div>
                      <div>{Icons.scanQR()}</div>
                  </button>
              </div>
          )
        } else return null;
    }
}