import React from "react";

import Categories from "./Categories";
import Category from "./Category";
import Context from "../../../context/Context";
import Items from "./Items";
import SidebarHeader from "../Sidebar/SidebarHeader";
import * as component from "../../util/component";

const CSS = {
  main: "i-explore",
  content: "i-explore-content",
  category: "i-category",
  scrollItems: "i-scroll-items",
  scrollItemsSubcaption: "i-scroll-items-subcaption"
};

export default class Explore extends React.Component {

  constructor(props) {
    super(props);
    const aiim = Context.instance.aiim;
    const sources = (aiim && aiim.datasets && aiim.datasets.categories &&
      aiim.datasets.categories.sources) || [];
    const sourceKeys = [];
    sources.forEach(source => {
      if (source.url && source.categoryLevel === 1) {
        sourceKeys.push(source.key);
      }
    });
    this.state = component.newState({
      sourceKeys: sourceKeys,
      activeSourceKey: null,
      activeSubTypeValue: null,
      activeSubTypeName: null
    });
    this.showCategories = this.showCategories.bind(this);
    this.showCategory = this.showCategory.bind(this);
    this.showSubType = this.showSubType.bind(this);
  }

  componentWillUnmount() {
    //console.log("Explore::componentWillUnmount");
  }

  render() {
    let source;
    const sourceKeys = this.state.sourceKeys;
    const activeSourceKey = this.state.activeSourceKey;
    const activeSubTypeValue = this.state.activeSubTypeValue;
    if (activeSourceKey !== null && activeSourceKey !== undefined) {
      const categories = Context.getInstance().aiim.datasets.categories;
      source = categories.findSourceByKey(activeSourceKey);
    }

    if (!source) {
      return (
        <CategoriesPanel sourceKeys={sourceKeys}
          sidebarKey={this.props.sidebarKey}
          clickHandler={this.showCategory} />
      );
    } else {
      const hasActiveSubType = (activeSubTypeValue !== null &&
                                activeSubTypeValue !== undefined);
      const nSubTypes = (source.subTypes && source.subTypes.length);
      if ((source.subCategoryField || nSubTypes > 0) && !hasActiveSubType) {
        return (
          <CategoryPanel sourceKey={source.key} sourceName={source.name}
            sidebarKey={this.props.sidebarKey}
            backHandler={this.showCategories}
            subTypeClickHandler={this.showSubType} />
        );
      } else {
        const isNoneAvailable = (activeSubTypeValue === "__noneAvailable__");
        const hadSubTypes = ((source.subCategoryField || nSubTypes > 0) && !isNoneAvailable);
        const subCaption = this.state.activeSubTypeName;
        const subTypeValue = isNoneAvailable ? null : activeSubTypeValue;
        let backHandler = this.showCategories;
        let backArguments = null;
        if (hadSubTypes) {
          backHandler = this.showCategory;
          backArguments = source.key;
        }
        return (
          <ItemsPanel sourceKey={source.key} caption={source.name}
            sidebarKey={this.props.sidebarKey}
            subCaption={subCaption} subTypeValue={subTypeValue}
            backHandler={backHandler} backArguments={backArguments} />
        );
      }
    }
  }

  showCategories(sourceKey) {
    this.setState(state => {
      return {
        activeSourceKey: null,
        activeSubTypeValue: null,
        activeSubTypeName: null
      }
    });
  }

  showCategory(sourceKey) {
    this.setState(state => {
      return {
        activeSourceKey: sourceKey,
        activeSubTypeValue: null,
        activeSubTypeName: null
      }
    });
  }

  showSubType(sourceKey,subTypeValue,subTypeName) {
    //console.log("Explore::showSubType",sourceKey,subTypeValue,subTypeName);
    this.setState(state => {
      return {
        activeSourceKey: sourceKey,
        activeSubTypeValue: subTypeValue,
        activeSubTypeName: subTypeName
      }
    });
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class CategoriesPanel extends React.Component {
  render() {
    const i18n = Context.getInstance().i18n;
    return (
      <div>
        <SidebarHeader caption={i18n.explore.panelCaption}
          closeAriaLabel={i18n.explore.closeAriaLabel}
          sidebarKey={this.props.sidebarKey}/>
        <div className={CSS.main}>
          <div className={CSS.content}>
            <div className={CSS.scrollItems}>
              <Categories sourceKeys={this.props.sourceKeys}
                clickHandler={this.props.clickHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class CategoryPanel extends React.Component {
  render() {
    const i18n = Context.instance.i18n;
    return (
      <div>
        <SidebarHeader caption={this.props.sourceName}
          closeAriaLabel={i18n.explore.closeAriaLabel}
          sidebarKey={this.props.sidebarKey}
        showBack={true} backHandler={this.props.backHandler} />
        <div className={CSS.main}>
          <div className={CSS.category}>
            <div className={CSS.scrollItems}>
              <Category sourceKey={this.props.sourceKey}
                subTypeClickHandler={this.props.subTypeClickHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class ItemsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.backClicked = this.backClicked.bind(this);
  }

  backClicked() {
    if (this.props.backHandler) {
      this.props.backHandler(this.props.backArguments);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const rdxKey = "Items__"+this.props.sourceKey+"__"+this.props.subTypeValue;
    let scrollCSS = this.props.subCaption ? CSS.scrollItemsSubcaption : CSS.scrollItems
    return (
      <div>
        <SidebarHeader caption={this.props.caption}
          closeAriaLabel={i18n.explore.closeAriaLabel}
          sidebarKey={this.props.sidebarKey}
          subCaption={this.props.subCaption}
          showBack={true} backHandler={this.backClicked}
        />
        <div className={CSS.main}>
          <div className={CSS.category}>
            <div className={scrollCSS}>
              <Items rdxKey={rdxKey} sourceKey={this.props.sourceKey}
                subTypeValue={this.props.subTypeValue} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
