import React from "react";
import ThemeColor from "./../../theme/ThemeColor/ThemeColor";
import ThemeTextColor from "./../../theme/ThemeColor/ThemeTextColor";
import * as component from "../../util/component";
import Context from "../../../context/Context";
import ThemeDropdown from "./ThemeDropdown";

const CSS = {
  main: "i-cfg-theme"
};

export default class Theme extends React.Component {

  constructor(props){
    super(props);
    this.state=component.newState({
      showDropdownMenu:false,
    })
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  showDropdown=()=>{
   this.setState({
    showDropdownMenu: ! this.state.showDropdownMenu
   })
  }

  render() {
    const cfg=this.props.cfg;
    const i18n=Context.instance.i18n;
    const i18nTheme=i18n.configurator.theme;

    return (
      <div className={CSS.main}>
        <ThemeDropdown portalTheme={this.props}/>
        <ThemeColor
              heading={i18nTheme.branding}
              title={i18nTheme.colorBrand}
              example={i18nTheme.colorBrandExample}
              cfg={cfg}
              themeProperty="theme-color-brand"/>
        <ThemeColor
              heading=""
              title={i18nTheme.colorBrandHighlight}
              example={i18nTheme.colorBrandHighlightExample}
              cfg={cfg}
              themeProperty="theme-color-brand-highlight"/>
        <ThemeTextColor
              title={i18nTheme.colorBrandText}
              example={i18nTheme.colorBrandTextExample}
              cfg={cfg}
              themeProperty="theme-color-brand-text"
              sampleText={i18nTheme.sampleText}
              themeBackgroundProperty="theme-color-brand"/>
        <ThemeColor
              heading={i18nTheme.body}
              title={i18nTheme.colorBody}
              example={i18nTheme.colorBodyExample}
              cfg={cfg}
              themeProperty="theme-color-background"/>
        <ThemeColor
              heading=""
              title={i18nTheme.colorBodyHighlight}
              example={i18nTheme.colorBodyHighlightExample}
              cfg={cfg}
              themeProperty="theme-color-highlight"/>
        <ThemeTextColor
              title={i18nTheme.colorBodyPrimaryText}
              example={i18nTheme.colorBodyPrimaryTextExample}
              cfg={cfg}
              themeProperty="theme-color-text"
              themeBackgroundProperty="theme-color-background"
              sampleText={i18nTheme.sampleText}/>
        <ThemeTextColor
              title={i18nTheme.colorBodySecondaryText}
              example={i18nTheme.colorBodySecondaryTextExample}
              cfg={cfg}
              themeProperty="theme-color-text-secondary"
              themeBackgroundProperty="theme-color-background"
              sampleText={i18nTheme.sampleText}/>
        <ThemeTextColor
              title={i18nTheme.colorBodyLinkText}
              example={i18nTheme.colorBodyLinkTextExample}
              cfg={cfg}
              themeProperty="theme-color-link"
              themeBackgroundProperty="theme-color-background"
              sampleText={i18nTheme.sampleTextLink}/>
        <ThemeTextColor
              title={i18nTheme.colorAccent}
              example={i18nTheme.colorAccentExample}
              cfg={cfg}
              themeProperty="theme-color-accent"
              themeBackgroundProperty="theme-color-background"
              sampleText={i18nTheme.sampleTextLink}/>
        <ThemeColor
              heading={""}
              title={i18nTheme.colorButton}
              example={i18nTheme.colorButtonExample}
              cfg={cfg}
              themeProperty="theme-color-button"/>
        <ThemeColor
              heading=""
              title={i18nTheme.colorButtonHighlight}
              example={i18nTheme.colorButtonHighlightExample}
              cfg={cfg}
              themeProperty="theme-color-button-highlight"/>
        <ThemeTextColor
              title={i18nTheme.colorButtonText}
              example={i18nTheme.colorButtonTextExample}
              cfg={cfg}
              themeProperty="theme-color-button-text"
              themeBackgroundProperty="theme-color-button"
              sampleText={i18nTheme.sampleText}/>
        <ThemeColor
              heading={""}
              title={i18nTheme.colorBorder}
              example={i18nTheme.colorBorderExample}
              cfg={cfg}
              themeProperty="theme-color-border"/>
      </div>
    );
  }
}
