import React from "react";

import Context from "../../../../context/Context";
import { ModalController, WorkingController } from "../../../../common/components/Modal";
import NewPlan from "../NewPlan/NewPlan";
import OpenPlanVM from "./OpenPlanVM";
import RecentPlans from "./RecentPlans";
import ShareWith, { SharingLevels } from "../../../../common/components/Sharing";
import Topic from "../../../../context/Topic";
import * as component from "../../../../components/util/component";
import * as recentPlansUtil from "../../support/recentPlansUtil";
import { getGroups, updateSharing, updateSharingVersioned } from "../../../base/groupsUtil";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-bar";
import "@esri/calcite-components/dist/components/calcite-avatar";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import {
  CalciteAction,
  CalciteActionBar,
  CalciteAvatar,
  CalciteButton,
  CalciteChip,
  CalciteIcon,
  CalciteInputText,
  CalcitePanel,
  CalciteSegmentedControl,
  CalciteSegmentedControlItem
} from "@esri/calcite-components-react";

import CreateBanner from "../../../../assets/create-plan-banner.png";
import { ReactComponent as IndoorsIcon } from "../../../../assets/indoors-logo.svg";
import { ReactComponent as PlanningIcon } from "../../../../assets/planning.svg";

import { Space, Table, Tag } from "antd";
import UserControl from "../../common/components/UserControl";

interface Props {
  isStartup?: boolean;
  onClose?: () => void;
}

interface State {
  loading: boolean,
  newPlanOpen: boolean;
  plans: any;
  recentTimestamp: number,
  searchText: string;
  scope: string;
  sortField: string;
  sortDir: string;
  useNewPlanMode: boolean;
  working: boolean;
}

export default class OpenPlan extends React.Component<Props, State> {

  asyncStamp;
  viewModel: OpenPlanVM;

  constructor(props: any) {
    super(props);
    const vm = this.viewModel = new OpenPlanVM();
    const criteria = vm.getCriteria();
    this.state = component.newState({
      loading: true,
      newPlanOpen: false,
      plans: null,
      recentTimestamp: 0,
      searchText: criteria.searchText,
      scope: criteria.scope,
      sortField: criteria.sortField,
      sortDir: criteria.sortDir,
      useNewPlanMode: false,
      working: false
    });
  }

  componentDidMount () {
    this.query();
  }

  componentWillUnmount() { 
  }

  confirmDelete = async (event,plan) => {
    event.stopPropagation();
    const i18n = Context.instance.i18n;
    const title = plan.title;
    const message = i18n.spaceplanner.backstage.deletePlan.prompt.replace("{planName}",title);
    const options = {
      title: i18n.spaceplanner.backstage.deletePlan.caption,
      message: message,
      okLabel: i18n.spaceplanner.backstage.deletePlan.buttonCaption
    };
    let workingController;
    const result = await ModalController.confirm(options);
    if (result.ok) {
      try {
        workingController = new WorkingController();
        workingController.show({},i18n.switcher.openPlan.deletingPlan);
        await this.viewModel.deletePlan(plan);
        recentPlansUtil.onPlanDeleted(plan);
        await this.query();
        this.setState({recentTimestamp: Date.now()});
        workingController.close();
      } catch(ex) {
        console.error(ex) // @todo
        if (workingController) workingController.close();
        let submessage = ex.submessage || ex.message;
        let title = i18n.spaceplanner.backstage.deletePlan.errorDeletingTitle;
        let message = i18n.spaceplanner.backstage.deletePlan.errorDeletingMessage;
        if (ex.message === "The version could not be located") {
          title = i18n.spaceplanner.backstage.deletePlan.errorDeletePlanTitle;
          message = i18n.spaceplanner.backstage.deletePlan.planNotLocated;
          submessage = null;
        }
        ModalController.alert({
          isError: true,
          title: title,
          message: message,
          submessage: submessage
        });
      }
    }
  }

  query = async () => {
    try {
      this.setState({working: true})
      const stamp = this.asyncStamp = Date.now();
      const criteria = {
        searchText: this.state.searchText,
        scope: this.state.scope,
        sortField: this.state.sortField,
        sortDir: this.state.sortDir
      }
      const plans = await this.viewModel.query(criteria);
      if (stamp === this.asyncStamp) {
        let useNewPlanMode = false;
        if (!plans || plans.length === 0) {
          if (!criteria.searchText && criteria.scope === "all") {
            useNewPlanMode = true;
          }
        }
        //useNewPlanMode = true;
        this.setState({plans, useNewPlanMode, loading: false, working: false})
      }
    } catch(ex) {
      this.setState({loading: false, working: false})
      console.error(ex)
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const project = Context.instance.spaceplanner.planner.project;
    const planner = Context.instance.spaceplanner.planner;
    const isVersioned = !!(project && project.isVersioned);
    const hasPlan = !!(planner && planner.hasValidPlan()); 
    const { loading, newPlanOpen, recentTimestamp, useNewPlanMode } = this.state;

    let banner, banner2, content;

    // banner = (
    //   <CalcitePanel className="i--banner" style={{maxHeight: "4rem"}}
    //     closable={hasPlan}
    //     onCalcitePanelClose={() => {
    //       if (this.props.onClose) this.props.onClose();
    //     }}
    //   >
    //     <div className="i--heading" slot="header-content">
    //       <span><IndoorsIcon /></span>
    //       <h3>{"SPACE PLANNER"}</h3>
    //     </div>
    //   </CalcitePanel>    
    // )

    // banner2 = (
    //   <div className="i-create-plan-banner">
    //     <div className="i--text">
    //       <div className="i--line1">{i18n.switcher.openPlan.caption}</div>
    //     </div>
    //   </div>
    // )

    if (useNewPlanMode && Context.instance.user.canCreatePlan()) {
      banner2 = (
        <div className="i-create-plan-banner">
          <div className="i--text">
            <div className="i--line1">{i18n.switcher.openPlan.createBanner.line1}</div>
            <div className="i--line2">{i18n.switcher.openPlan.createBanner.line2}</div>
            <div className="i--line3">{i18n.switcher.openPlan.createBanner.line3}</div>
          </div>
        </div>
      )
    }

    if (loading) {
      content = <CalcitePanel heading="" loading={true} />

    } else if (useNewPlanMode) {
      content = (
        <>
          <div>
            {banner2}
            <div className="i--top-bottom">
              <div className="i--top-row">
                <h2>{i18n.switcher.openPlan.plans}</h2>
                {Context.instance.user.canCreatePlan() &&
                  <CalciteButton
                    iconStart="plus"
                    className="i--blue"
                    onClick={(e) => {
                      this.setState({ newPlanOpen: true })
                    }}
                  >
                    {i18n.switcher.newPlan.caption}
                  </CalciteButton>
                }
              </div>
              <div className="i-no-plans">
                <div className="i-plans-icon"><PlanningIcon /></div>
                <h2>{i18n.switcher.openPlan.noPlans}</h2>
                <div>{Context.instance.user.canCreatePlan() ? i18n.switcher.openPlan.noPlansMessage : i18n.switcher.openPlan.noPlansMessage2}</div>
              </div>
            </div>
            {newPlanOpen && <NewPlan isSaveAs={false} onClose={() => this.setState({ newPlanOpen: false })} />}
          </div>
          <div slot="footer-actions"></div>
        </>
      )

    } else {
      content = (
        <>
          <RecentPlans recentTimestamp={recentTimestamp} />
          {this.renderHeader()}
          {this.renderPlans(isVersioned)}
        </>
      )
    }

    /*
              closable={hasPlan}
          onCalcitePanelClose={() => {
            if (this.props.onClose) this.props.onClose();
          }}
    */

    return (
      <div className="i-miniapps-openplan i--full">
        <CalcitePanel className="i--banner"
        >
          <div className="i--heading" slot="header-content">
            {hasPlan &&
            <CalciteAction text="close" icon="chevrons-left" scale="s" 
              onClick={() => {
                if (this.props.onClose) this.props.onClose();
              }}
            />
            }
            <span><IndoorsIcon /></span>
            <h3>{"FLOOR PLAN EDITOR"}</h3>
          </div>
          <div className="i--heading-end" slot="header-actions-end">
            {this.renderPanelActions()}
          </div>
          {content}
        </CalcitePanel> 
      </div>
    )
    
  }

  renderHeader() {
    const i18n = Context.instance.i18n;
    const scope = this.state.scope;
    const checkedProps: any = {
      all: {},
      myContent: {},
      sharedContent: {}
    }
    if (scope === "myContent") {
      checkedProps.myContent.checked = true;
    } else if (scope === "sharedContent") {
      checkedProps.sharedContent.checked = true;
    } else { 
      checkedProps.all.checked = true;
    }
    return (
      <div className="i-flex-between i--padded">
        <div>
          <CalciteInputText icon={"search"} clearable={true} 
            placeholder={i18n.switcher.openPlan.placeholder} 
            onCalciteInputTextInput={(e) => {
              const v = (e.target && e.target.value) || "";
              this.setState({searchText: v},this.query);
            }}
          />
        </div>
        <CalciteSegmentedControl 
          onCalciteSegmentedControlChange={(e) => {
            const v = e.target.value;
            this.setState({scope: ""+v},this.query);
          }}
        >
          <CalciteSegmentedControlItem key="all" value="all" {...checkedProps["all"]}>
            {i18n.switcher.openPlan.scope.all}
          </CalciteSegmentedControlItem>
          <CalciteSegmentedControlItem key="myContent" value="myContent" {...checkedProps["myContent"]}>
            {i18n.switcher.openPlan.scope.myContent}
          </CalciteSegmentedControlItem>
          <CalciteSegmentedControlItem key="sharedContent" value="sharedContent" {...checkedProps["sharedContent"]}>
            {i18n.switcher.openPlan.scope.sharedContent}
          </CalciteSegmentedControlItem>
        </CalciteSegmentedControl>
      </div>
    )
  }

  renderPanelActions() {
    const { i18n, user, spaceplanner } = Context.getInstance();
    const canConfigure = user.canConfigureApp();
    const planner = spaceplanner.planner;
    const hasPlan = !!(planner && planner.hasValidPlan());
    return (
      <span className="i-button-bar-right" style={{height: "100%"}}>
        {canConfigure &&
          <CalciteButton 
            appearance="transparent"
            kind="neutral"
            iconStart="gear"
            style={{height: "3.25rem"}}
            onClick={() => {
              Context.instance.uiMode.toggleConfigurator(true);
              Topic.publish(Topic.ConfiguratorActivated,{});
              if (this.props.onClose) this.props.onClose();
            }}
          > 
            {i18n.miniapps.configurator.settings}
          </CalciteButton>
        }
        <UserControl placement="bottom-end" />
      </span>
    )
  }

  renderPlans(isVersioned) {
    const i18n = Context.instance.i18n;
    const { plans, sortField, sortDir } = this.state;
    const showLastEdited = !!isVersioned;
    const showStatus = !!isVersioned;
    const { Column } = Table;

    const makeLabel = (field,defaultDir,columnName) => {
      let icon;
      if (sortField === field) {
        if (sortDir === "asc") {
          icon = "caret-up";
        } else {
          icon = "caret-down";
        }
      }

      return (
        <CalciteButton className="i--table-column-header" appearance="transparent" kind="neutral" alignment="start" 
          iconEnd={icon} 
          onClick={evt => {
            let f = field, d = defaultDir;
            if (sortField === field) {
              d = (sortDir === "asc") ? "desc" : "asc";
            }
            this.setState({
              sortField: f,
              sortDir: d
            },this.query);
          }}
        >
          {columnName}
        </CalciteButton>      
      )
    }

    return (
      <Table className="i--table i--padded"
        dataSource={plans}
        pagination={{pageSize: 50, hideOnSinglePage: true, showSizeChanger: false}}
        scroll={{ y: 550 }}
      >
        <Column
          key="title"
          width="20rem"
          title={() => {
            return makeLabel("title","asc",i18n.switcher.openPlan.columns.title);
          }}
          render={(_, plan: any) => (
            <Space size="small" align="start">
              <CalciteButton 
                className="i-miniapps-openplan-plan-title" 
                appearance="transparent" 
                alignment="start"
                title={plan.title}
                onClick={evt => this.viewModel.openPlan(evt,plan)}
              >
                {plan.title}
              </CalciteButton>
            </Space>
          )}
        />
        <Column
          key="ownerFullname"
          title={() => {
            return makeLabel("ownerFullname","asc",i18n.switcher.openPlan.columns.owner);
          }}
          render={(_, plan: any) => (
            <Space size="small" align="start">
              <div style={{display: "flex", alignItems: "center"}}>
                <CalciteAvatar full-name={plan.ownerFullname} />
                <span style={{margin: "0 4px"}}>{plan.ownerFullname}</span>
              </div>
            </Space>
          )}
        />
        <Column
          key="created"
          title={() => {
            return makeLabel("created","desc",i18n.switcher.openPlan.columns.created);
          }}
          render={(_, plan: any) => (
            <Space size="small" align="start">
              {plan.createdString}
            </Space>
          )}
        />
        {showLastEdited && 
          <Column
            key="lastEdited"
            title={() => {
              return makeLabel("lastEdited","desc",i18n.switcher.openPlan.columns.lastEdited);
            }}
            render={(_, plan: any) => (
              <Space size="small" align="start">
                {plan.lastEditedString}
              </Space>
            )}
          />
        }
        <Column
          key="access"
          width="8rem"
          title={() => {
            return makeLabel("access","asc",i18n.switcher.openPlan.columns.sharing);
          }}
          render={(_, plan: any) => (
            <Space size="small" align="start">
              {this.renderSharing(plan)}
            </Space>
          )}
        />
        {showStatus && 
          <Column
            key="status"
            width="8rem"
            title={() => {
              return makeLabel("status","asc",i18n.switcher.openPlan.columns.status);
            }}
            render={(_, plan: any) => {
              const v = plan.status;
              const cls = (v === "_new" ? "i--green" : (v === "_modified" ? "i--red" : (v === "_merged" ? "i--blue" : "i--grey")));
              return (
              <Space size="small" align="start">
                <CalciteChip className={cls} value={v} scale="s">
                  {i18n.switcher.openPlan.status[plan.status]}
                </CalciteChip>
              </Space>
              )
            }}
          />
        }
        <Column
          key="actions"
          title=""
          width="48px"
          render={(_, plan: any) => (
            <Space size="small" align="start">
              <CalciteActionBar layout="horizontal" expandDisabled={true}>
                <CalciteAction icon="trash"
                  appearance="transparent"
                  title={i18n.general.del} text={i18n.general.del} 
                  disabled={this.viewModel.canDelete(plan) ? undefined: true}
                  onClick={evt => this.confirmDelete(evt,plan)}
                />
              </CalciteActionBar>              
            </Space>
          )}
        />
      </Table>
    )
  }

  renderSharing(plan) {
    const i18n = Context.instance.i18n;
    let icon, text;
    if (plan.access === SharingLevels.PRIVATE) {
      icon = "user";
      text = i18n.configurator.appItem.shareWith.sharingLevel.owner;
    } else if (plan.access === SharingLevels.ORGANIZATION) {
      icon = "organization";
      text = i18n.configurator.appItem.shareWith.sharingLevel.organization;
    } else if (plan.access === SharingLevels.SHARED) {
      icon = "users";
      text = i18n.configurator.appItem.shareWith.sharingLevel.groups;
    }
    if (icon) {
      return (
        <CalciteAction icon={icon} title={text} text={text} appearance="transparent"
          onClick={() => this.renderSharingModal(plan,plan.versionInfo)}
        />
      )
    }
  }

  async renderSharingModal(plan, versionInfo) {
    try {
      const project = Context.instance.spaceplanner.planner.project;
      const portal = Context.getInstance().getPortal();
      const username = portal && portal.user && portal.user.username;
      const i18n = Context.instance.i18n;

      const portalItem = (plan.portalItem || plan.supportService);
      const portalItemId = portalItem && portalItem.id;

      const info = {
        access: plan.access,
        shareIds: [],
        unshareIds: [],
        assignedGroups: null,
        portalItem: portalItem,
        versionInfo: versionInfo,
        isVersioned: project && project.isVersioned
      }
      const owner = plan && plan.owner;
      const isOwner = (username && owner && (owner.toLowerCase() === username.toLowerCase()));

      let groups = null, chips = [];
      if (portalItemId) {
        const groupInfo = await getGroups(portalItemId);
        groups = groupInfo[0];
        chips = groupInfo[1];
      }

      info.assignedGroups = chips;
      let assignedGroupIds = [];
      if (chips && chips.length > 0) assignedGroupIds = chips.map(group => group.id);

      const options: any = {
        title: i18n.configurator.appItem.shareWith.sharingLevel.title,
        className: "i-share-with-modal",
        okLabel: i18n.general.save,
        hideOkCancel: !isOwner
      }
      
      options.content = (
        <form className="i--form">
          <ShareWith
            horizontal={false}
            shareWith={info.access}
            disabled={!isOwner}
            assignedGroups={chips}
            groups={groups}
            disallowEveryone={true}
            hideShareWithHelp={!portalItem}
            onChange={(shareWith, shareIds, unShareIds) => {
              info.access = shareWith;
              if (shareIds) {
                info.shareIds = shareIds.slice();
              }
              if (unShareIds) {
                info.unshareIds = unShareIds.slice();
              }
            }}
          />
        </form>
      );

      const result = await ModalController.confirm(options);
      if (result.ok) {
        this.updateSharing({ info, assignedGroupIds, id: portalItemId })
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateSharing = async ({ info, assignedGroupIds, id }) => {
    await this.viewModel
      .updatePlanSharingLevel({ info, assignedGroupIds, id })
      .catch(error => console.error(error));
    
    setTimeout(this.query, 1000);    
  }

}