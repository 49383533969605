import Context from "../context/Context";

export const keys = {
  homeLocation: "home-location",
  favoritePlaces: "favorite-places",
  recentPlaces: "recent-places",
  viewerSignOut: "viewer-signout-toast",
  toolsUnavailable: "priv-msg-shown"
}

export const userSettingKeys = {
  enablePopups: "enable-popups"
};

export function getItem(key,keyOk) {
  if(!keyOk) key = makeUserKey(key);
  return window.localStorage.getItem(key);
}

export function getJsonItem(key) {
  const value = getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (ex) {
      console.warn("Error parsing key=",key," value=",value);
      console.error(ex);
    }
  }
  return null;
}

export function makeUserKey(key, excludeWebmap) {
  const context = Context.getInstance();
  let prefix = "indoors-";
  let username;
  if (context.user.isAnonymous()) {
    username = "__anonymous__";
  } else {
    username = context.user.getUsername();
  }
  if (typeof username === "string" && username.length > 0) {
    prefix = prefix + username + "-";
  }
  // TODO may not have a webmap?
  if (!excludeWebmap && typeof context.config.webmap === "string" && context.config.webmap.length > 0) {
    prefix = prefix + context.config.webmap + "-";
  }
  return prefix + key;
}

export function removeItem(key,keyOk) {
  if(!keyOk) key = makeUserKey(key);
  window.localStorage.removeItem(key);
}

export function setJsonItem(key,value) {
  if (value) value = JSON.stringify(value);
  setItem(key,value);
}

export function setItem(key,value,keyOk) {
  if(!keyOk) key = makeUserKey(key);
  window.localStorage.setItem(key,value);
}

export function setUserSetting(key, value, keyOk) {
  if (!keyOk) {
    key = makeUserKey(key, true);
  }
  window.localStorage.setItem(key, value);
}

export function getUserSetting(key, keyOk) {
  if (!keyOk) {
    key = makeUserKey(key, true);
  }
  return window.localStorage.getItem(key);
}
