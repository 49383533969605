import React from "react";

import Button from "../../../common/components/Button";
import CloseIcon from "calcite-ui-icons-react/XIcon";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import MoveOccupantsModel from "./MoveOccupantsModel";
import OfficePlan from "../../base/OfficePlan";
import { Panel } from "../../styles/Common/panel";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

import {
  List,
  ListHeader,
  ListItem,
  ListItemTitle,
  ListItemTitleMain,
  ListItemTitleIcon,
  ListItemTitleAside,
} from "../../styles/Common/list";

export default class MoveOccupants extends React.Component {

  mainRef;
  model;
  _refreshDataStamp;

  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
    this.model = new MoveOccupantsModel();
    this.state = component.newState({
      activeOccupant: null,
      isRefreshingData: false,
      moveOccupantsOn: false,
      moveParams: null,
      requiresScroll: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.ActiveTabChanged, params => {
        if (params && params.tab && params.tab !== "plan") {
          this.hide();
        };
      }),
      Topic.subscribe(Topic.CreatingPlan, params => {
        this.hide();
      }),
      Topic.subscribe(Topic.ExpanderChanged, params => {
        if (params && params.type === "MapSelection" && params.newVal) {
          this.hide();
        }
      }),
      Topic.subscribe(Topic.ItemPopupOpening, params => {
        this.hide();
      }),
      Topic.subscribe(Topic.MergingPlan, params => {
        this.hide();
      }),
      Topic.subscribe(Topic.MoveOccupants_GraphicClicked, params => {
        this.handleGraphicClicked(params);
      }),
      Topic.subscribe(Topic.MoveOccupants_Hide, params => {
        this.hide();
      }),
      Topic.subscribe(Topic.MoveOccupants_Show, params => {
        this.show(params);
      }),
      Topic.subscribe(Topic.PlanModified, async params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.handlePlanModified(params);
        }
      }),
      Topic.subscribe(Topic.ReloadViews, params => {
        this.hide();
      })
    ]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.requiresScroll && this.state.requiresScroll) {
      const oid = this.state.activeOccupant;
      const refNode = this.mainRef && this.mainRef.current;
      if (refNode && typeof oid === "number") {
        const key = "oid_"+oid;
        const el = refNode.querySelector("div[data-i-key='"+key+"']");
        if (el && typeof el.scrollIntoView === "function") {
          el.scrollIntoView({block: "nearest", behavior: "smooth"});
        }
      }
      this.setState({
        requiresScroll: false
      })
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  activateOccupant = item => {
    const activeOccupant = item.objectId;
    const {unitFeatureItem, peopleAssigned} = this.state.moveParams;
    this.model.activate(unitFeatureItem,peopleAssigned,activeOccupant);
    this.setState({
      activeOccupant: activeOccupant
    })
  }

  handleGraphicClicked = async (params) => {
    if (this.state.moveOccupantsOn && !this.state.isRefreshingData) {
      const graphic = params.graphic;
      if (graphic.xtnActive) {
        if (graphic.xtnState === "start" || graphic.xtnState === "active") {
          return; // don't re-activate the active graphic
        }
      }
      const oid = graphic.attributes.oid;
      const {unitFeatureItem, peopleAssigned} = this.state.moveParams;
      const item = this.model.findPersonFeatureItem(peopleAssigned,oid)
      if (item) {
        this.model.activate(unitFeatureItem,peopleAssigned,oid);
        this.setState({
          activeOccupant: oid,
          requiresScroll: true
        })
      }
    }
  }

  handlePlanModified = async (params) => {
    if (this.state.moveOccupantsOn) {
      let stamp = this._refreshDataStamp = Date.now();
      let moveParams = this.state.moveParams;
      let oid = this.state.activeOccupant;
      const isRelevant = () => {
        return (
          this.state.moveOccupantsOn &&
          moveParams === this.state.moveParams &&
          stamp === this._refreshDataStamp
        )
      }
      this.model.cancelSketch();
      this.setState({
        isRefreshingData: true
      })
      try {
        const {unitFeatureItem,peopleAssigned} = await this.model.refreshData(
          moveParams.unitFeatureItem
        );
        if (isRelevant()) {
          if (!peopleAssigned || peopleAssigned.length === 0) {
            this.hide();
          } else {
            const item = this.model.findPersonFeatureItem(peopleAssigned,oid)
            if (item) {
              this.model.activate(unitFeatureItem,peopleAssigned,oid);
            } else {
              oid = null;
              this.model.deactivate();
              this.model.drawGraphics(peopleAssigned);
            }
            this.setState({
              activeOccupant: oid,
              requiresScroll: true,
              isRefreshingData: false,
              moveParams: {
                unitFeatureItem: unitFeatureItem,
                peopleAssigned: peopleAssigned
              }
            })
          }
        }
      } catch(ex) {
        console.error(ex);
        if (isRelevant()) {
          // @todo should we show a modal message and close MoveOccupants?
          Topic.publishErrorAccessingData(ex);
        }
      } finally {
        if (isRelevant()) {
          this.setState({
            isRefreshingData: false
          })
        }
      }
    }
  }

  hide = () => {
    this.setState({
      activeOccupant: null,
      isRefreshingData: false,
      moveOccupantsOn: false,
      moveParams: null
    })
    this.model.deactivate();
    Context.instance.session.activeMoveOccupantsUnitOID = null;
    document.body.classList.remove("i-move-occupants-open");
  }

  hideClicked = (evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    Topic.publish(Topic.MoveOccupants_Hide,{})
  }

  show = (params) => {
    let activeOccupant = null, oid;
    const {unitFeatureItem, peopleAssigned} = params;
    const mapPoint = (params.mapEvent && params.mapEvent.mapPoint);
    if (mapPoint) {
      const closest = this.model.findClosest(peopleAssigned,mapPoint);
      if (closest) oid = closest.objectId;
    }
    const item = this.model.activate(unitFeatureItem,peopleAssigned,oid)
    if (item) activeOccupant = item.objectId;
    this.setState({
      activeOccupant: activeOccupant,
      isRefreshingData: false,
      moveOccupantsOn: true,
      moveParams: params,
      requiresScroll: true
    })
    Context.instance.session.activeMoveOccupantsUnitOID = unitFeatureItem.objectId;
    document.body.classList.add("i-move-occupants-open");
  }

  render() {
    if (!this.state.moveOccupantsOn) {
      return <div style={{display: "none"}}></div>
    }

    const i18n = Context.instance.i18n;
    const {unitFeatureItem, peopleAssigned} = this.state.moveParams;
    const unitName = aiimUtil.getAttributeValue(
      unitFeatureItem.feature.attributes,
      Context.instance.aiim.getUnitName()
    );

    let items;
    if (peopleAssigned && peopleAssigned.length > 0) {
      items = peopleAssigned.map(p => this.renderPerson(p));
    }

    return (
      <div className="i-move-occupants-container" ref={this.mainRef}>
        <Panel>
          <div className="i-move-occupants">
            <div className="i-move-occupants-header">
              <div>{i18n.spaceplanner.moveOccupants.caption}</div>
              <Button className={"i-filter-close-btn"}
                iconButton icon={<CloseIcon />} onClick={this.hideClicked} />
            </div>
            <div className="i-move-occupants-content">
              <div className="i-move-occupants-unit">{unitName}</div>
              <List style={{maxHeight: "50vh", padding: "0.3rem"}}>
                {items}
              </List>
            </div>
            <div className="i-move-occupants-footer">
              <Button extraSmall clear onClick={this.hideClicked}>{i18n.general.done}</Button>
            </div>
          </div>
        </Panel>
      </div>
    );
  }

  renderPerson(item) {
    //const i18n = Context.instance.i18n;
    const isRtl = Context.instance.uiMode.isRtl;
    const field = FieldNames.PEOPLE_FULLNAME;
    const isRefreshingData = this.state.isRefreshingData;
    const active = (this.state.activeOccupant === item.objectId);
    const key = "oid_"+item.objectId;
    const itemStyle = {padding: "0.2rem 0.4rem", textIndent: 0}

    // let showDelete = false, deleteButton;
    // if (showDelete) {
    //   deleteButton = (
    //     <ListItemTitleAside>
    //       <Button
    //         iconButton={true}
    //         icon={<CloseIcon />}
    //         style={{ marginLeft: "10px" }}
    //         title={i18n.spaceplanner.unassign}
    //         onClick={(evt) => {
    //           if (evt) evt.stopPropagation();
    //           this.confirmDelete(item)
    //         }}
    //       />
    //     </ListItemTitleAside>
    //   )
    //
    //   /*
    //   <ListItemTitle>
    //     <ListItemTitleMain>
    //       {aiimUtil.getAttributeValue(item.feature.attributes, field)}
    //     </ListItemTitleMain>
    //     {deleteButton}
    //   </ListItemTitle>
    //    */
    // }

    return (
      <ListItem
        key={key}
        disabled={isRefreshingData}
        selected={active}
        isRtl={isRtl}
        style={itemStyle}
        onClick={() => {this.activateOccupant(item)}}>
        <ListItemTitle
          data-i-key={key}
          style={{overflowWrap: "break-word"}}>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitle>
      </ListItem>
    )
  }

}
