//import AreasTable from "./AreasTable";
import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import HostedMerge from "./HostedMerge";
import OfficePlan from "./OfficePlan";
import * as aiimUtil from "../../aiim/util/aiimUtil";
//import * as officePlanUtil from "./officePlanUtil";
import * as portalUtil from "../../util/portalUtil";
import * as saveAsUtil from "./saveAsUtil";
import * as selectionUtil from "../../aiim/util/selectionUtil";
import * as serviceUtil from "./serviceUtil";
//import * as sourceUtil from "./sourceUtil";
import * as val from "../../util/val";
// import * as planGroupUtil from "./ReviewerManagement/planGroupUtil";
import { SharingLevels } from "../../common/components/Sharing";

export default class PlanCreator extends BaseClass {

  _tagFS = OfficePlan.TagFS;
  _typeKeywordFS = OfficePlan.TypeKeywordFS;
  _typeKeywordParentPfx = OfficePlan.TypeKeywordParentPfx;

  createPlan(project,options) {
    if (project.isVersioned) {
      return this.createPlanVersion(project,options);
    } else {
      return this.createPlanFeatureService(project,options);
    }
  }

  // options {itemInfo,isSaveAs,workingController}
  createPlanFeatureService(project,options) {
    const promise = new Promise((resolve,reject) => {
      const {itemInfo, isSaveAs, workingController} = options;
      const i18n = Context.instance.i18n;
      const appItem = Context.instance.configuration.appItem;
      const project = Context.instance.spaceplanner.planner.project;
      const title = itemInfo && itemInfo.title;
      const folderId = itemInfo && itemInfo.folderId;
      const sharingInfo = itemInfo && itemInfo.sharingInfo;

      let initialPeopleLayer, initialUnitsLayer, initialDetailsLayer;
      let initialAreasTableInfo, initialAreaRolesTableInfo;
      initialPeopleLayer = project.hostedInfo.peopleLayer;
      initialUnitsLayer = project.hostedInfo.unitsLayer;
      initialDetailsLayer = project.hostedInfo.detailsLayer;
      initialAreasTableInfo = project.hostedInfo.sourceInfo.areasTableInfo;
      initialAreaRolesTableInfo = project.hostedInfo.sourceInfo.areaRolesTableInfo;

      const supportInfo = {}

      let task = {
        isSaveAs: isSaveAs,
        itemInfo: itemInfo,
        workingController: workingController,
        username: Context.instance.user.getUsername(),
        userContentUrl: portalUtil.getUserContentUrl(),
        initial: {
          peopleLayer: initialPeopleLayer,
          unitsLayer: initialUnitsLayer,
          detailsLayer: initialDetailsLayer,
          serviceUrl: initialUnitsLayer.url,
          serviceItemId: initialUnitsLayer.portalItem && initialUnitsLayer.portalItem.id,
          areasTableInfo: initialAreasTableInfo,
          areaRolesTableInfo: initialAreaRolesTableInfo
        },
        plan: {
          title: title,
          serviceName: null,
          serviceNameAvalible: false,
          folderId: folderId,
          targetSR: null,
          typeKeywordParent: this._typeKeywordParentPfx+appItem.id,
        },
        completed: {
          export: false,
          publish: false
        }
      }

      // issue #4101
      if (!task.initial.serviceItemId) {
        let info = project.hostedInfo.sourceInfo.unitsLayerInfo;
        if (info && info.serviceItemId) {
          task.initial.serviceItemId = info.serviceItemId;
        }
      }

      // convert spaces to underscores
      // remove non alphanumeric or underscor characters
      task.plan.serviceName = task.plan.title;
      //console.log("task.plan.serviceName",task.plan.serviceName)

      this._readServiceJson(task.initial.serviceUrl,task.initial).then(() => {
        return portalUtil.readItemJsonData(task.initial.serviceItemId).then(result => {
          task.initial.serviceItemData = result && result.data;
          if (task.initial.serviceInfo) {
            task.plan.targetSR = task.initial.serviceInfo.spatialReference;
          }
        });
      }).then(result => {
        return this._isServiceNameAvailable(task);
      }).then(available => {
        //console.log("task",task);
        if (!available) {
          let err = new Error("A service by this name already exists.");
          err.code = "__serviceNameAlreadyExists__";
          throw(err);
        }
      }).then(result => {
        return this._exportAndPublish(task);
      }).then(result => {
        return this._readServiceJson(task.plan.serviceUrl,task.plan);
      }).then(result => {
        this._setWorkingText(task,i18n.spaceplanner.backstage.newPlan.updating)
        return this._updateDefinitions(task);
      }).then(result => {
        // re-read the updated definitions
        return this._readServiceJson(task.plan.serviceUrl,task.plan);
      }).then(result => {
        return serviceUtil.addSupportingTables(task.plan.serviceUrl,true,task.plan.unitsLayerInfo);
      }).then(result => {
        return portalUtil.readItem(task.plan.serviceItemId).then(result => {
          task.plan.serviceItem = result && result.data;
        });
      }).then(result => {
        return this._readServiceJson(task.plan.serviceUrl,supportInfo);
      }).then(() => {
        task.supportInfo = supportInfo
      }).then(() => {
        const hm = new HostedMerge();
        return hm.afterCreatePlan(task);
      }).then(result => {
        return this._updateNewPlanItem(task);
      }).then(result => {
        if (sharingInfo) {
          if (sharingInfo.everyone || sharingInfo.org || sharingInfo.groups) {
            const id = task.plan.serviceItem.id;
            const owner = task.plan.serviceItem.owner;
            return portalUtil.shareItem(id,owner,sharingInfo)
          }
        }

      }).then(result => {
        this._setWorkingText(task,i18n.spaceplanner.backstage.newPlan.opening)

      }).then(result => {
        // re-read the service item
        return portalUtil.readItem(task.plan.serviceItemId).then(result => {
          task.plan.serviceItem = result && result.data;
        });
      }).then(result => {
        // re-read the service item data
        return portalUtil.readItemJsonData(task.plan.serviceItemId).then(result => {
          task.plan.serviceItemData = result && result.data;
        });

      }).then(result => {
        console.log("PlanCreator.done.......", task);
        this.cleanupExportItem(task);
        resolve(task);
      }).catch(ex => {
        this.cleanupExportItem(task);
        this.cleanupServiceItem(task);
        reject(ex);
      });
    });
    return promise;
  }

  cleanupExportItem(task){
    if(task.exportedItemId){
      portalUtil.deleteItems([task.exportedItemId]);
    }
  }

  cleanupServiceItem(task){
    if(task.publishedServiceItemId){
      portalUtil.deleteItems([task.publishedServiceItemId]);
    }
    if(task.supportServiceItemId){
      portalUtil.deleteItems([task.supportServiceItemId]);
    }
  }

  createPlanVersion(project,options) {
    const promise = new Promise((resolve,reject) => {
      const {itemInfo, isSaveAs} = options;
      const task = {};
      const newVersionInfo = {
        versionName: itemInfo.title,
        description: itemInfo.description,
        accessPermission: itemInfo.accessPermission
      }
      const activePlan = Context.instance.spaceplanner.activePlan;
      const versionManager = project.versionedInfo.versionManager;
      let createVersionResult;
      let hasDifferences, differences, edits;
      const requiresSupprtService = (Context.instance.isSP() || (Context.instance.isFPE() && Context.instance.user.canCreateService()))
      if (Context.instance.isFPE() && !requiresSupprtService) {
        const v = "floorplaneditor_"+Context.instance.configuration.appItem.id;
        newVersionInfo.description = v;
      }

      Promise.resolve().then(() => {
      }).then(() => {
        if (isSaveAs) {
          const options = {
            plan: activePlan,
            resultType: "features"
          };
          return versionManager.hasDifferences(options).then(result => {
            hasDifferences = result && result.hasDifferences;
            differences = result && result.differences;
            if (hasDifferences) {
              edits = [];
              differences.forEach(d => {
                let layerEdits = {
                  id: d.layerId
                };
                let gidField;
                let sourceInfo = project.versionedInfo.sourceInfo;
                let hasPeople = !!sourceInfo.peopleLayerInfo;
                let hasAreas = !!sourceInfo.areasTableInfo;
                let hasAreaRoles = !!sourceInfo.areaRolesTableInfo;
                if (d.layerId === (hasPeople && sourceInfo.peopleLayerInfo.id)) {
                  gidField = sourceInfo.peopleLayerInfo.globalIdField;
                } else if (d.layerId === sourceInfo.unitsLayerInfo.id) {
                  gidField = sourceInfo.unitsLayerInfo.globalIdField;
                } else if (d.layerId === (hasAreas && sourceInfo.areasTableInfo.id)) {
                  gidField = sourceInfo.areasTableInfo.globalIdField;
                } else if (d.layerId === (hasAreaRoles && sourceInfo.areaRolesTableInfo.id)) {
                  gidField = sourceInfo.areaRolesTableInfo.globalIdField;
                }
                if (d.inserts && d.inserts.length > 0) {
                  // back-end branch feature service bug, supply a new global id
                  //this._resetGlobalIds(task,gidField,d.inserts);
                  layerEdits.adds = d.inserts;
                }
                if (d.updates && d.updates.length > 0) {
                  layerEdits.updates = d.updates;
                }
                if (d.deletes && d.deletes.length > 0) {
                  let gids = [];
                  d.deletes.forEach(f => {
                    const gid = aiimUtil.getAttributeValue(f.attributes,gidField);
                    gids.push(gid);
                  });
                  //console.log("gids",gids)
                  layerEdits.deletes = gids;
                }
                edits.push(layerEdits);
              })
            }
            //console.log("edits",edits)
          });
        }
      }).then(() => {
        return versionManager.createVersion(task,newVersionInfo).then(result => {
          //console.log("createVersionResult",result.data)
          createVersionResult = result.data;
        });
      }).then(() => {
        if (isSaveAs && hasDifferences) {
          let sourceInfo = project.versionedInfo.sourceInfo;
          let gdbVersion = createVersionResult.versionInfo.versionName;
          let saveAsTask = {
            sourceInfo: sourceInfo,
            gdbVersion: gdbVersion,
            edits: edits,
            newPlanVersionInfo: createVersionResult.versionInfo
          };
          return saveAsUtil.applyParentPlanEdits(saveAsTask);
        }
      }).then(() => {

        let sharingInfo;
        if (newVersionInfo.accessPermission === SharingLevels.PUBLIC) {
          sharingInfo = {
            everyone: false,
            org: true,
            groups: ''
          };
          
          if (itemInfo && itemInfo.groupIds && itemInfo.groupIds.length > 0) {
            sharingInfo.org = false;
            sharingInfo.groups = itemInfo.groupIds.join(',');
          }
        }
        
        let name = versionManager.getVersionTitle(createVersionResult.versionInfo,false);
        let guid = createVersionResult.versionInfo.versionGuid;
        let sourceInfo = project.versionedInfo.sourceInfo;
        if (requiresSupprtService) {
          return serviceUtil.createVersionSupportService(task,name,guid,sharingInfo,sourceInfo);
        }

      }).then(() => {
        resolve(createVersionResult);
      }).catch(ex => {
        this.cleanupServiceItem(task);
        reject(ex);
      });
    });
    return promise;
  }

  // _loadReviewersTable(task, gdbVersion) {
  //   const lib = Context.instance.lib
  //   const tableInfo = task.supportInfo.reviewersTableInfo
  //   task.supportInfo.reviewersTable = new ReviewersTable()
  //   if (tableInfo) {
  //       let options
  //       if (gdbVersion) {
  //         let url = task.supportServiceUrl + "/" + tableInfo.id
  //         options = {
  //           url: url,
  //           gdbVersion: gdbVersion
  //         }
  //       } else {
  //         options = {
  //           portalItem: {
  //             id: task.supportServiceItemId
  //           },
  //           layerId: tableInfo.id
  //         }
  //       }
  //       const table = task.supportInfo.reviewersTable.table = new lib.esri.FeatureLayer(options)
  //       console.log("Loaded", table)
  //       return task.supportInfo.reviewersTable.load(table)
  //   } else {
  //     return Promise.resolve()
  //   }
  // }

  _addToDefinition(task,serviceUrl,json) {
    if (!json) return Promise.resolve();
    let url = this._getServiceAdminUrl(task,serviceUrl)+"/addToDefinition ";
    let params = {
      f: "json",
      addToDefinition : JSON.stringify(json)
    };
    const token = this._getToken(task);
    if (token) params.token = token;
    const options = {query: params, method: "post", responseType: "json"};
    const esriRequest = Context.instance.lib.esri.esriRequest;
    console.log("addToDefinition",url,options)
    return esriRequest(url,options);
  }

  _addToLayerDefinition(task,serviceUrl,layerId,json) {
    if (!json) return Promise.resolve();
    let url = this._getServiceAdminUrl(task,serviceUrl)+"/"+layerId+"/addToDefinition ";
    let params = {
      f: "json",
      addToDefinition : JSON.stringify(json)
    };
    const token = this._getToken(task);
    if (token) params.token = token;
    const options = {query: params, method: "post", responseType: "json"};
    const esriRequest = Context.instance.lib.esri.esriRequest;
    console.log("addToDefinition",url,options)
    return esriRequest(url,options);
    //return Promise.resolve();
  }

  //#region
  // _calcInitialConditions(task) {
  //   const promise = new Promise((resolve,reject) => {
  //     this._calcPeopleInitialConditions(task).then(() => {
  //       return this._calcUnitsInitialConditions(task);
  //     }).then(() => {
  //       resolve();
  //     }).catch(ex => {
  //       reject(ex);
  //     });
  //   });
  //   return promise;
  // }

  // _calcPeopleInitialConditions(task) {
  //   const promise = new Promise((resolve,reject) => {
  //     const layerInfo = task && task.plan && task.plan.peopleLayerInfo;
  //     const calcExpressions = [];
  //     let s1, s2, f1, f2;
  //
  //     s1 = "INITIAL_UNIT_ID";
  //     s2 = "UNIT_ID";
  //     f1 = aiimUtil.findField(layerInfo.fields,s1);
  //     f2 = aiimUtil.findField(layerInfo.fields,s2);
  //     calcExpressions.push({
  //       field: f1.name,
  //       sqlExpression: f2.name
  //     });
  //
  //     s1 = "INITIAL_AREA_ID";
  //     s2 = "AREA_ID";
  //     f1 = aiimUtil.findField(layerInfo.fields,s1);
  //     f2 = aiimUtil.findField(layerInfo.fields,s2);
  //     calcExpressions.push({
  //       field: f1.name,
  //       sqlExpression: f2.name
  //     });
  //
  //     // s1 = "INITIAL_SPACE_ASSIGNMENT";
  //     // s2 = "SPACE_ASSIGNMENT";
  //     // f1 = aiimUtil.findField(layerInfo.fields,s1);
  //     // f2 = aiimUtil.findField(layerInfo.fields,s2);
  //     // calcExpressions.push({
  //     //   field: f1.name,
  //     //   sqlExpression: f2.name
  //     // });
  //
  //     const serviceUrl = task.plan.serviceUrl;
  //     const url = serviceUrl+"/"+layerInfo.id+"/calculate";
  //     const params = {
  //       f: "json",
  //       calcExpression: JSON.stringify(calcExpressions)
  //     }
  //     const esriRequest = Context.instance.lib.esri.esriRequest;
  //     const options = {query: params, method: "post", responseType: "json"};
  //     esriRequest(url,options).then(result => {
  //       resolve();
  //     }).catch(ex => {
  //       reject(ex);
  //     });
  //
  //   });
  //   return promise;
  // }

  // _calcUnitsInitialConditions(task) {
  //   const promise = new Promise((resolve,reject) => {
  //     const layerInfo = task && task.plan && task.plan.unitsLayerInfo;
  //     const calcExpressions = [];
  //     let s1, s2, f1, f2;
  //
  //     s1 = "INITIAL_AREA_ID";
  //     s2 = "AREA_ID";
  //     f1 = aiimUtil.findField(layerInfo.fields,s1);
  //     f2 = aiimUtil.findField(layerInfo.fields,s2);
  //     calcExpressions.push({
  //       field: f1.name,
  //       sqlExpression: f2.name
  //     });
  //
  //     s1 = "INITIAL_ASSIGNMENT_TYPE";
  //     s2 = "ASSIGNMENT_TYPE";
  //     f1 = aiimUtil.findField(layerInfo.fields,s1);
  //     f2 = aiimUtil.findField(layerInfo.fields,s2);
  //     calcExpressions.push({
  //       field: f1.name,
  //       sqlExpression: f2.name
  //     });
  //
  //     const serviceUrl = task.plan.serviceUrl;
  //     const url = serviceUrl+"/"+layerInfo.id+"/calculate";
  //     const params = {
  //       f: "json",
  //       calcExpression: JSON.stringify(calcExpressions)
  //     }
  //     const esriRequest = Context.instance.lib.esri.esriRequest;
  //     const options = {query: params, method: "post", responseType: "json"};
  //     esriRequest(url,options).then(result => {
  //       resolve();
  //     }).catch(ex => {
  //       reject(ex);
  //     });
  //
  //   });
  //   return promise;
  // }
  //#endregion

  _ensureFieldDef(planLayerInfo,newFields,defaultDef) {
    let f1 = aiimUtil.findField(planLayerInfo.fields,defaultDef.name);
    if (!f1) {
      f1 = JSON.parse(JSON.stringify(defaultDef));
      newFields.push(f1);
    };
  }

  _exportAndPublish(task) {
    const promise = new Promise((resolve,reject) => {
      const hostedMerge = new HostedMerge();
      hostedMerge.beforeCreatePlan(task).then(result => {
        return this._exportServiceItem(task);
      }).then(result => {
        if (task.completed.export) {
          return this._publishServiceItem(task)
        } else {
          // TODO something wrong
        }
      }).then(result => {
        if (task.completed.publish) {
          task.plan.serviceUrl = task.publishedServiceUrl;
          task.plan.serviceItemId = task.publishedServiceItemId;
        } else {
          // TODO something wrong
        }
      }).then(result => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _exportServiceItem(task) {
    const promise = new Promise((resolve,reject) => {

      const peopleLayer = task.initial.peopleLayer;
      const peopleWhere = selectionUtil.getBaseDefinitionExpression(peopleLayer);
      const peopleInfo = {id: peopleLayer.layerId};
      if (peopleWhere) peopleInfo.where = peopleWhere;

      const unitsLayer = task.initial.unitsLayer;
      const unitsWhere = selectionUtil.getBaseDefinitionExpression(unitsLayer);
      const unitsInfo = {id: unitsLayer.layerId};
      if (unitsWhere) unitsInfo.where = unitsWhere;

      const detailsLayer = null; // task.initial.detailsLayer;
      const detailsWhere = detailsLayer && selectionUtil.getBaseDefinitionExpression(detailsLayer);
      const detailsInfo = detailsLayer && {id: detailsLayer.layerId};
      if (detailsInfo && detailsWhere) detailsInfo.where = detailsWhere;

      const areasInfo = {id: task.initial.areasTableInfo.id};

      const url = task.userContentUrl+"/export";
      let exportParameters = {
        layers: [peopleInfo,unitsInfo,areasInfo]
      };
      if (detailsInfo) exportParameters.layers.push(detailsInfo);

      let areaRolesInfo;
      if (task.initial.areaRolesTableInfo) {
        areaRolesInfo = {id: task.initial.areaRolesTableInfo.id};
        exportParameters.layers.push(areaRolesInfo)
      }

      /*
      if (task.initial.commentsLayerInfo) {
        exportParameters.layers.push({id: task.initial.commentsLayerInfo.id})
      }
      if (task.initial.reviewersTableInfo) {
        exportParameters.layers.push({id: task.initial.reviewersTableInfo.id})
      }
      */
      if (task.plan.targetSR) {
        exportParameters.targetSR = task.plan.targetSR;
      }
      let params = {
        f: "json",
        title: task.plan.title,
        itemId: task.initial.serviceItemId,
        tags: this._tagFS,
        exportFormat: "File Geodatabase",
        exportParameters: JSON.stringify(exportParameters)
      };
      //if (task.plan.folderId) params.folderId = task.plan.folderId;

      const i18n = Context.instance.i18n;
      this._setWorkingText(task,i18n.spaceplanner.backstage.newPlan.exporting);
      const esriRequest = Context.instance.lib.esri.esriRequest;
      const options = {query: params, method: "post", responseType: "json"};
      esriRequest(url,options).then(result => {
        console.log("_exportServiceItem.result",result)
        let info = result.data; // TODO check
        if (typeof info.success === "boolean" && info.success === false) {
          reject(new Error("Export failed."));
          return;
        }
        task.exportJobId = info.jobId;
        task.exportedItemId = info.exportItemId;
        return this._pollJob(task,task.exportedItemId,task.exportJobId,"export");
      }).then(() => {
        if (task.completed.export && task.plan.folderId) {
          return portalUtil.moveItem(
            task.exportedItemId,null,task.plan.folderId,task.username);
        }
      }).then(() => {
        console.log("_exportServiceItem.resolve..................")
        resolve()
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });
    });
    return promise;
  }

  _getAreaIdFieldDef() {
    return {
      name: "area_id",
      type: "esriFieldTypeString",
      alias: "Area Assignment",
      domain: null,
      editable: true,
      nullable: true,
      length: 1000
    };
  }

  _getServiceAdminUrl(task,serviceUrl) {
    let url = serviceUrl.replace("/rest/services/","/rest/admin/services/");
    // AppContext.addCors(url); // TODO???
    return url;
  }

  _getSpaceAssignmentFieldDef() {
    return {
      name: "assignment_type",
      type: "esriFieldTypeString",
      alias: "Assignment Type",
      domain: null,
      editable: true,
      nullable: true,
      length: 255
    };
  }

  _getToken(task) {
    const esriId = Context.instance.lib.esri.esriId;
    const credential = esriId.findCredential(Context.instance.getPortalUrl());
    return credential && credential.token;
  }

  _getUnitIdFieldDef() {
    return {
      name: "unit_id",
      type: "esriFieldTypeString",
      alias: "UNIT_ID",
      domain: null,
      editable: true,
      nullable: true,
      length: 255
    };
  }

  _isServiceNameAvailable(task) {
    const promise = new Promise((resolve,reject) => {
      let id = encodeURIComponent(Context.instance.getPortal().id);
      let url = Context.instance.getSharingUrl();
      url += "/rest/portals/"+id+"/isServiceNameAvailable";
      const params = {
        f: "json",
        name: task.plan.serviceName,
        type: "Feature Service"
      };
      const options = {query: params, method: "get", responseType: "json"};
      const esriRequest = Context.instance.lib.esri.esriRequest;
      esriRequest(url,options).then(result => {
        const available = !!(result.data && result.data.available);
        console.log("_isServiceNameAvailable",available,result)
        task.plan.serviceNameAvalible = available;
        resolve(available);
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise
  }

  _pollJob(task,itemId,jobId,jobType) {
    const promise = new Promise((resolve,reject) => {
      const url = task.userContentUrl+"/items/"+encodeURIComponent(itemId)+"/status";

      const poll = () => {

        const query = {
          f: "json",
          jobId: jobId,
          jobType: jobType
        };
        const options = {query: query, method: "get", responseType: "json"};
        const esriRequest = Context.instance.lib.esri.esriRequest;

        esriRequest(url,options).then(result => {
          const status = result.data.status;
          const statusMessage = result.data.statusMessage;
          //console.log("poll.result",jobType,status,result)
          if (status === "completed") {
            task.completed[jobType] = true;
            resolve();
          } else if (status === "partial" || status === "processing") {
            setTimeout(() => {
              poll();
            },1000);
          } else if (status === "failed") {
            let msg = "Job failed";
            if (statusMessage) msg += ": "+statusMessage;
            console.warn("Job failed:",msg,result);
            reject(new Error(msg));
          } else {
            let msg = "Unknown job status type: "+status;
            if (statusMessage) msg += ", "+statusMessage;
            console.warn("Job failed:",msg,result);
            reject(new Error(msg));
          }
        }).catch(ex => {
          reject(ex);
        });
      };

      poll();
    });
    return promise;
  }

  _publishServiceItem(task) {
    const promise = new Promise((resolve,reject) => {

      const url = task.userContentUrl+"/publish";
      // name description maxRecordCount copyrightText layerInfo targetSR
      //
      let editorTrackingInfo = {
        "enableEditorTracking": true,
        "enableOwnershipAccessControl": false,
        "allowOthersToUpdate": true,
        "allowOthersToDelete": true,
        "allowOthersToQuery": true,
        "allowAnonymousToUpdate": false,
        "allowAnonymousToDelete": false
      };

      const publishParameters = {
        name: task.plan.serviceName,
        maxRecordCount: 2000,
        hasStaticData: false,
        capabilities: "Query,Editing,Create,Update,Delete",
        editorTrackingInfo: editorTrackingInfo
      }
      if (task.plan.targetSR) publishParameters.targetSR = task.plan.targetSR;
      let params = {
        f: "json",
        title: task.plan.title,
        itemId: task.exportedItemId,
        filetype: "filegeodatabase",
        publishParameters: JSON.stringify(publishParameters)
      };
      //if (task.plan.folderId) params.folderId = task.plan.folderId;

      const i18n = Context.instance.i18n;
      this._setWorkingText(task,i18n.spaceplanner.backstage.newPlan.publishing);
      const esriRequest = Context.instance.lib.esri.esriRequest;
      const options = {query: params, method: "post", responseType: "json"};
      esriRequest(url,options).then(result => {
        console.log("publishResult",result)
        let info = result.data.services[0]; // TODO check
        task.publishJobId = info.jobId;
        task.publishedServiceUrl = info.serviceurl; //encodedServiceURL?
        task.publishedServiceItemId = info.serviceItemId;
        return this._pollJob(task,task.publishedServiceItemId,task.publishJobId,"publish");
      }).then(() => {
        return aiimUtil.readServiceJson(task.publishedServiceUrl);
      }).then((serviceInfo) => {
        console.log("published serviceInfo",serviceInfo)
      }).then(() => {
        console.log("_publishService.resolve..................")
        //aiimUtil.readServiceJson(url).then(result => {
        resolve()
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _readItemDataJson(itemId,target) {
    return portalUtil.readItemJsonData(itemId).then(data => {
      target.itemData = data;
    });
  }

  // serviceUtil
  _readServiceJson(url,target) {
    return serviceUtil.readServiceJson(url,target);
  }

  //#region
  // ___readServiceJson(url,target) {
  //   const promise = new Promise((resolve,reject) => {
  //     const promises = [];
  //
  //     aiimUtil.readServiceJson(url).then(result => {
  //
  //       //console.log("_readServiceJson",result)
  //       const serviceInfo = result.data;
  //       target.serviceInfo = serviceInfo;
  //       serviceInfo.layers.forEach(layer => {
  //         const id = layer.id;
  //         //const name = layer.name;
  //         const p = aiimUtil.readServiceJson(url+"/"+id);
  //         promises.push(p);
  //         p.then(layerResult => {
  //           //console.log("layerResult",layerResult)
  //           const layerInfo = layerResult.data;
  //           if (layerInfo.name.toLowerCase() === "people") {
  //             target.peopleLayerInfo = layerInfo;
  //           } else if (layerInfo.name.toLowerCase() === "units") {
  //             target.unitsLayerInfo = layerInfo;
  //           }
  //         });
  //       });
  //       serviceInfo.tables.forEach(table => {
  //         const id = table.id;
  //         const p = aiimUtil.readServiceJson(url+"/"+id);
  //         promises.push(p);
  //         p.then(tableResult => {
  //           //console.log("tableResult",tableResult)
  //           const tableInfo = tableResult.data;
  //           if (tableInfo.name.toLowerCase() === "areas") {
  //             target.areasTableInfo = tableInfo;
  //           }
  //         });
  //       });
  //
  //       if (promises.length > 0) {
  //         return Promise.all(promises);
  //       }
  //     }).then(() => {
  //       resolve();
  //     }).catch(ex => {
  //       reject(ex);
  //     });
  //
  //   });
  //   return promise
  // }
  //
  //#endregion

  _resetGlobalIds(task,globalIdField,inserts) {
    inserts.forEach(row => {
      // {F2C9930C-75F6-46D1-B854-812B569706B2}
      let gid = val.generateRandomUuid({includeBraces: true});
      console.log("giddddddddddddddddddddddddddd",gid,row.attributes[globalIdField])
      row.attributes[globalIdField] = gid;
    });
  }

  _setWorkingText(task,text) {
    if (task.workingController) {
      task.workingController.setText(text);
    }
  }

  _updateDefinition(task,serviceUrl,json) {
    if (!json) return Promise.resolve();
    let url = this._getServiceAdminUrl(task,serviceUrl)+"/updateDefinition ";
    let params = {
      f: "json",
      updateDefinition : JSON.stringify(json)
    };
    const token = this._getToken(task);
    if (token) params.token = token;
    const options = {query: params, method: "post", responseType: "json"};
    const esriRequest = Context.instance.lib.esri.esriRequest;
    console.log("updateDefinition",url,options)
    return esriRequest(url,options);
  }

  _updateDefinitions(task) {
    const promise = new Promise((resolve,reject) => {
      this._updatePeopleDefinition(task).then(() => {
        return this._updateUnitsDefinition(task);
      }).then(() => {
        return this._updateDetailsDefinition(task);
      }).then(() => {
        const def = {capabilities: "Query,Editing,Create,Update,Delete,Extract,ChangeTracking"};
        return this._updateDefinition(task,task.plan.serviceUrl,def);
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise
  }

  _updateDetailsDefinition(task) {
    const promise = new Promise((resolve,reject) => {
      const initialLayerInfo = task && task.initial && task.initial.detailsLayerInfo;
      const planLayerInfo = task && task.plan && task.plan.layerInfo;
      if (initialLayerInfo && planLayerInfo) {
        let updateDef = null;
        if (initialLayerInfo.drawingInfo) {
          updateDef = {drawingInfo: initialLayerInfo.drawingInfo};
        }
        let serviceUrl = task.plan.serviceUrl;
        let layerId = planLayerInfo.id;
        this._updateLayerDefinition(task,serviceUrl,layerId,updateDef).then(() => {
          resolve()
        }).catch(ex => {
          reject(ex);
        });

      } else {
        resolve();
      }
    });
    return promise;
  }

  _updateLayerDefinition(task,serviceUrl,layerId,json) {
    if (!json) return Promise.resolve();
    let url = this._getServiceAdminUrl(task,serviceUrl)+"/"+layerId+"/updateDefinition";
    let params = {
      f: "json",
      updateDefinition: JSON.stringify(json)
    };
    const token = this._getToken(task);
    if (token) params.token = token;
    const options = {query: params, method: "post", responseType: "json"};
    const esriRequest = Context.instance.lib.esri.esriRequest;
    console.log("_updateLayerDefinition",url,options)
    return esriRequest(url,options);
    //return Promise.resolve();
  }

  _updateNewPlanItem(task) {
    const initial = task.initial;
    const plan = task.plan;
    const iServiceItemData = initial && initial.serviceItemData;
    const iPeopleLayerInfo = initial && initial.peopleLayerInfo;
    const iUnitsLayerInfo = initial && initial.unitsLayerInfo;
    const iDetailsLayerInfo = initial && initial.detailsLayerInfo;
    const pServiceItem = plan && plan.serviceItem;
    const pPeopleLayerInfo = plan && plan.peopleLayerInfo;
    const pUnitsLayerInfo = plan && plan.unitsLayerInfo;
    const pDetailsLayerInfo = plan && plan.detailsLayerInfo;
    const pServiceItemId = plan && plan.serviceItemId;

    const item = {};
    if (pServiceItem && pServiceItem.tags) {
      item.tags = pServiceItem.tags.slice();
    }
    if (task.itemInfo && Array.isArray(task.itemInfo.tags)) {
      const a = task.itemInfo.tags;
      if (a.length > 0) {
        if (item.tags && item.tags.length > 0) {
          item.tags = item.tags.concat(a);
        } else {
          item.tags = a.slice();
        }
      }
    }
    if (pServiceItem && pServiceItem.typeKeywords) {
      item.typeKeywords = pServiceItem.typeKeywords.slice();
    }
    portalUtil.ensureTags(item,[this._tagFS]);
    portalUtil.ensureTypeKeywords(item,[this._typeKeywordFS]);
    portalUtil.ensureTypeKeywords(item,task.plan.typeKeywordParent);
    const props = {
      title: task.itemInfo.itemTitle,
      snippet: (task.itemInfo && task.itemInfo.summary) || "",
      description: (task.itemInfo && task.itemInfo.description) || "",
      tags: item.tags.join(","),
      typeKeywords: item.typeKeywords.join(","),
      clearEmptyFields: true
    }
    if (task.newItemProperties) {
      props.properties = JSON.stringify(task.newItemProperties)
    }

    const pLayers = [];
    if (iServiceItemData && iServiceItemData.layers &&
        iPeopleLayerInfo && iUnitsLayerInfo &&
        pPeopleLayerInfo && pUnitsLayerInfo && pServiceItemId) {
      iServiceItemData.layers.forEach(l => {
        if (l.id === iPeopleLayerInfo.id) {
          pLayers.push({
            id: pPeopleLayerInfo.id,
            layerDefinition: l.layerDefinition,
            popupInfo: l.popupInfo
          });
        } else if (l.id === iUnitsLayerInfo.id) {
          pLayers.push({
            id: pUnitsLayerInfo.id,
            layerDefinition: l.layerDefinition,
            popupInfo: l.popupInfo
          });
        }
        if (iDetailsLayerInfo && pDetailsLayerInfo && l.id === iDetailsLayerInfo.id) {
          pLayers.push({
            id: pDetailsLayerInfo.id,
            layerDefinition: l.layerDefinition,
            popupInfo: l.popupInfo
          });          
        }
      });
    }

    let data;
    if (pLayers.length > 0) {
      data = {
        layers: pLayers
      };
    }
    if (data) {
      props.text = JSON.stringify(data);
    }

    const folderId = task.plan.folderId;
    const owner = task.username;
    return portalUtil.saveItem(props,pServiceItemId,folderId,owner);
  }

  _updatePeopleDefinition(task) {
    const promise = new Promise((resolve,reject) => {
      const initialLayerInfo = task && task.initial && task.initial.peopleLayerInfo;
      const planLayerInfo = task && task.plan && task.plan.peopleLayerInfo;

      if (initialLayerInfo && planLayerInfo) {
        let updateDef = null;
        if (initialLayerInfo.drawingInfo) {
          updateDef = {drawingInfo: initialLayerInfo.drawingInfo};
        }

        const initialFields = initialLayerInfo.fields;
        const updateFields = [];
        if (initialFields && initialFields.length > 0)  {
          initialFields.forEach(field => {
            if (field.type === "esriFieldTypeDate") {
              const domain = field.domain;
              if (domain && domain.range && domain.range.length > 0) {
                updateFields.push(field);
              }
            }
          });
        }

        if (updateFields.length > 0) {
          if (updateDef) {
            updateDef.fields = updateFields;
          } else {
            updateDef = {
              fields: updateFields
            };
          }
        }

        //#region
        // let s1, s2, f1, f2, f3, fDef, newFields = [], addToDef = null;

        // s1 = "INITIAL_UNIT_ID";
        // s2 = "UNIT_ID";
        // fDef = this._getUnitIdFieldDef();
        // f1 = aiimUtil.findField(planLayerInfo.fields,s1);
        // if (!f1) {
        //   f2 = aiimUtil.findField(planLayerInfo.fields,s2);
        //   if (!f2) f2 = fDef;
        //   f2 = JSON.parse(JSON.stringify(f2));
        //   f2.name = s1;
        //   f2.alias = s1;
        //   this._ensureFieldDef(planLayerInfo,newFields,f2);
        // }
        //
        // s1 = "INITIAL_AREA_ID";
        // s2 = "AREA_ID";
        // fDef = this._getAreaIdFieldDef();
        // f1 = aiimUtil.findField(planLayerInfo.fields,s1);
        // if (!f1) {
        //   f2 = aiimUtil.findField(planLayerInfo.fields,s2);
        //   if (!f2) {
        //     f2 = fDef
        //     f3 = JSON.parse(JSON.stringify(fDef));
        //     this._ensureFieldDef(planLayerInfo,newFields,f3);
        //   }
        //   f2 = JSON.parse(JSON.stringify(f2));
        //   f2.name = s1;
        //   f2.alias = s1;
        //   this._ensureFieldDef(planLayerInfo,newFields,f2);
        // }
        //
        // if (newFields.length > 0) {
        //   addToDef = {fields: newFields};
        // }
        //#endregion

        let serviceUrl = task.plan.serviceUrl;
        let layerId = planLayerInfo.id;
        this._updateLayerDefinition(task,serviceUrl,layerId,updateDef).then(() => {
          //return this._addToLayerDefinition(task,serviceUrl,layerId,addToDef);
        }).then(() => {
          resolve()
        }).catch(ex => {
          reject(ex);
        });

      } else {
        resolve();
      }
    });
    return promise;
  }

  _updateUnitsDefinition(task) {
    const promise = new Promise((resolve,reject) => {
      const initialLayerInfo = task && task.initial && task.initial.unitsLayerInfo;
      const planLayerInfo = task && task.plan && task.plan.unitsLayerInfo;

      if (initialLayerInfo && planLayerInfo) {
        let updateDef = null;
        if (initialLayerInfo.drawingInfo) {
          updateDef = {drawingInfo: initialLayerInfo.drawingInfo};
        }

        // let s1, s2, f1, f2, f3, fDef, newFields = [], addToDef = null;
        //
        // s1 = "INITIAL_AREA_ID";
        // s2 = "AREA_ID";
        // fDef = this._getAreaIdFieldDef();
        // f1 = aiimUtil.findField(planLayerInfo.fields,s1);
        // if (!f1) {
        //   f2 = aiimUtil.findField(planLayerInfo.fields,s2);
        //   if (!f2) {
        //     f2 = fDef
        //     f3 = JSON.parse(JSON.stringify(fDef));
        //     this._ensureFieldDef(planLayerInfo,newFields,f3);
        //   }
        //   f2 = JSON.parse(JSON.stringify(f2));
        //   f2.name = s1;
        //   f2.alias = s1;
        //   this._ensureFieldDef(planLayerInfo,newFields,f2);
        // }
        //
        // s1 = "INITIAL_ASSIGNMENT_TYPE";
        // s2 = "ASSIGNMENT_TYPE";
        // fDef = this._getSpaceAssignmentFieldDef();
        // f1 = aiimUtil.findField(planLayerInfo.fields,s1);
        // if (!f1) {
        //   f2 = aiimUtil.findField(planLayerInfo.fields,s2);
        //   if (!f2) {
        //     f2 = fDef
        //     f3 = JSON.parse(JSON.stringify(fDef));
        //     this._ensureFieldDef(planLayerInfo,newFields,f3);
        //   }
        //   f2 = JSON.parse(JSON.stringify(f2));
        //   f2.name = s1;
        //   f2.alias = s1;
        //   this._ensureFieldDef(planLayerInfo,newFields,f2);
        // }
        //
        // if (newFields.length > 0) {
        //   addToDef = {fields: newFields};
        // }

        let serviceUrl = task.plan.serviceUrl;
        let layerId = planLayerInfo.id;
        this._updateLayerDefinition(task,serviceUrl,layerId,updateDef).then(() => {
          //return this._addToLayerDefinition(task,serviceUrl,layerId,addToDef);
        }).then(() => {
          resolve()
        }).catch(ex => {
          reject(ex);
        });

      } else {
        resolve();
      }
    });
    return promise;
  }

}
