import React from "react";
import { connect, DispatchProp } from "react-redux";

import Context from "../../../../../context/Context";
import ItemBrowserVM from "./ItemBrowserVM";
import Rdx from "../../../../../redux/Rdx";
import * as component from "../../../../../components/util/component";
import { debounce } from "../../../support/debounceUtil";
import * as val from "../../../../../util/val";

import defaultThumbnail from "../../../../../assets/defaultThumbnail.png";

import "@esri/calcite-components/dist/components/calcite-card";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-link";
import "@esri/calcite-components/dist/components/calcite-modal";
import "@esri/calcite-components/dist/components/calcite-option";
import "@esri/calcite-components/dist/components/calcite-select";
import "@esri/calcite-components/dist/components/calcite-tab-nav";
import "@esri/calcite-components/dist/components/calcite-tab-title";
import "@esri/calcite-components/dist/components/calcite-tabs";
import {
  CalciteCard,
  CalciteButton,
  CalciteInputText,
  CalciteLink,
  CalciteModal,
  CalciteOption,
  CalciteSelect,
  CalciteTabNav,
  CalciteTabTitle,
  CalciteTabs
} from "@esri/calcite-components-react";

interface Props {
  itemType: string,
  onClose: () => void,
  onSelect: (item) => void,
  open: boolean,
  query?: string,
  dispatch: any,
  rdxKey: string,
  rdxSearchText: string,
  rdxSearchScope: string,
  rdxSortBy: string,
  rdxSortDir: string,
  title: string,
  subtitle?: string
}

interface State {
  isWorking: boolean,
  nextStart: number,
  result: any,
  resultItems: any,
  searchText: string
}

class ItemBrowser extends React.Component<Props, State> {

  orgOnly: boolean = true;
  viewModel: ItemBrowserVM;

  private _mounted: boolean = false;
  private _timestamp: number;

  constructor(props) {
    super(props);
    this.viewModel = new ItemBrowserVM();
    this.state = component.newState({
      isWorking: false,
      nextStart: -1,
      result: null,
      resultItems: null,
      searchText: ""
    });
  }

  componentDidMount() {
    this._mounted = true;
    this.query({
      searchText: this.state.searchText,
      searchScope: this.props.rdxSearchScope,
      sortBy: this.props.rdxSortBy,
      sortDir: this.props.rdxSortDir
    });
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  async query(options: any) {
    let prevItems, start = 1;
    if (options.isMore) {
      if (this.state.nextStart > 1) start = this.state.nextStart;
      prevItems = (this.state.resultItems || []).slice()
    }
    const props: any = {
      orgOnly: this.orgOnly,
      scope: options.searchScope === "myContent" ? "myContent" : "myOrganization",
      itemType: this.props.itemType,
      query: this.props.query,
      q: null,
      start: start
    };

    if (val.isNonEmptyStr(options.searchText)) {
      props.q = options.searchText;
    }
    if (!options.sortBy) options.sortBy = "title";
    if (options.sortBy) {
      if (options.sortBy === "relevance") {
        // TODO sort by date if no text?
      } else {
        props.sortField = options.sortBy;
        if (options.sortDir) {
          props.sortOrder = options.sortDir;
        }
      }
    }
    if (options.isMore && this.state.nextStart > 0) {
      props.start = this.state.nextStart;
    }

    try {
      const timestamp = this._timestamp = Date.now();
      const result: any = await this.viewModel.queryItems(props)
      if (!this._mounted) return;
      if (timestamp !== this._timestamp) return;
      

      let resultItems = ((result && result.results) || []).slice();
      if (options.isMore && start > 1 && Array.isArray(prevItems)) {
        resultItems = prevItems.concat(resultItems);
      }
      let nextStart = (result && result.nextQueryParams && result.nextQueryParams.start);
      if (typeof nextStart !== "number") nextStart = -1;
      this.setState({
        isWorking: false,
        nextStart: nextStart,
        result: result,
        resultItems: resultItems
      });
    } catch(ex) {
      console.error(ex);
    }
  }

  render() {
    const { open, title, subtitle } = this.props;
    if (open) {
      return (
        <CalciteModal 
          className="i-item-browser"
          widthScale="m"
          scale="m"
          open={open ? true : undefined}
          onCalciteModalClose={this.props.onClose}
        >
          <div slot="header">
            {title}
            {subtitle && <div className="i-modal-subtitle">{subtitle}</div>}
          </div>
          <div slot="content">{this.renderContent()}</div>
        </CalciteModal>
      )
    }
    return null;
  }

  renderCard(item) {
    const i18n = Context.instance.i18n;

    const thumbnailName = i18n.portalItemBrowser.thumbnail;
    const thumbnailUrl = item.thumbnailUrl || defaultThumbnail;

    const dt = item.modified || item.created;
    const date = dt.toLocaleDateString();
    let infoText = i18n.portalItemBrowser.itemTypeOwnerDate;
    infoText = infoText.replace("{type}",item.displayName);
    infoText = infoText.replace("{owner}",item.owner);
    infoText = infoText.replace("{date}",date);
    const info = (
      <div key="info" className="i--info">
        <span>{infoText}</span>
      </div>
    );

    let description = null, text;
    //text = item.description;
    if (!text) text = item.snippet;
     if (text) {
      description = (
        <div key="desc" className="i--description"
          dangerouslySetInnerHTML={{ __html: Context.sanitizeHtml(text)}}>
        </div>
      );
    }

    const href = Context.checkMixedContent(item.portal.url) + "/home/item.html?id=" + encodeURIComponent(item.id);

    return (
      <CalciteCard key={item.id} className="i-card" thumbnailPosition="inline-start">
        <div className="i--descriptor">
          <div className="i--left">
            <img className="i--thumbnail" alt={thumbnailName} src={thumbnailUrl} />
          </div>
          <div className="i--right">
            <h4 className="i--title">{item.title}</h4>
            {info}
            {description}
          </div>
        </div>
        <div className="i--actions">
          <CalciteLink href={href} target="_blank" rel="noopener noreferrer">
            {i18n.portalItemBrowser.details}
          </CalciteLink>
          <CalciteButton 
            onClick={() =>{
              if (this.props.onSelect) this.props.onSelect(item);
            }}
          >
            {i18n.portalItemBrowser.select}
          </CalciteButton>
        </div>
      </CalciteCard>
    )
  }

  renderCards() {
    const i18n = Context.instance.i18n;
    const { resultItems } = this.state;
    if (resultItems && resultItems.length === 0) {
      return (
        <div className="i-cards">
          <span>{i18n.portalItemBrowser.noMatch}</span>
        </div>
      );
    } 
    let items: any = [];
    if (resultItems && resultItems.length > 0) {
      resultItems.forEach(item => {
        items.push(this.renderCard(item));
      });
    }
    return (
      <div className="i-cards">
        {items}
      </div>
    )
  }

  renderContent() {
    return (
      <div>
        {this.renderCriteria()}
        {this.renderCards()}
        {this.renderMore()}
      </div>
    )
  }

  renderCriteria() {
    const i18n = Context.instance.i18n;
    const searchText = this.state.searchText || "";
    const scope = this.props.rdxSearchScope === "myContent" ? "myContent" : "myOrganization";
    const sortBy = this.props.rdxSortBy || "title";
    const sortDir = this.props.rdxSortDir || "asc";
    let sortDirIcon = "sort-ascending";
    if (sortDir === "desc") {
      sortDirIcon = "sort-descending"
    }

    const onScopeChange = (v) => {
      const searchScopeKey = this.props.rdxKey + "__searchScope";
      this.searchClicked({searchScope: v})
      Rdx.setValue(this,searchScopeKey,v);
    }

    const onSortByChange = (e) => {
      const v = e.target.value;
      let dir = "asc";
      if (v === "modified") {
        dir = "desc"
      }
      const sortByKey = this.props.rdxKey + "__sortBy";
      const sortDirKey = this.props.rdxKey + "__sortDir";
      this.searchClicked({sortBy: v, sortDir: dir})
      Rdx.setValue(this,sortByKey,v);
      Rdx.setValue(this,sortDirKey,dir);
    }

    const onSortDirClick = (e) => {
      const dir = (sortDir === "asc" ? "desc" : "asc");
      const sortDirKey = this.props.rdxKey + "__sortDir";
      this.searchClicked({sortDir: dir})
      Rdx.setValue(this,sortDirKey,dir);
    }

    const onTextChange = (e) => {
      const v = (e && e.target && e.target.value) || "";
      const searchTextKey = this.props.rdxKey + "__searchText";
      this.searchClicked({searchText: v})
      this.setState({searchText: v});
      Rdx.setValue(this,searchTextKey,v);
    }

    return (
      <div className="i-criteria i-flex-between">
        <div>
          <div style={{display: "flex", gap: "0 4px"}}>
            <CalciteInputText 
              icon={"search"} 
              clearable={true}
              //value={searchText} // not refreshing properly
              placeholder={i18n.search.allPlaceholder} 
              onCalciteInputTextInput={debounce((e) => onTextChange(e), 200)}
            />
            <CalciteSelect label="" onCalciteSelectChange={onSortByChange}>
              <CalciteOption value="relevance" selected={sortBy === "relevance" ? true : undefined}>
                {i18n.portalItemBrowser.sortBy.relevance}
              </CalciteOption>
              <CalciteOption value="title" selected={sortBy === "title" ? true : undefined}>
                {i18n.portalItemBrowser.sortBy.title}
              </CalciteOption>
              <CalciteOption value="modified" selected={sortBy === "modified" ? true : undefined}>
                {i18n.portalItemBrowser.sortBy.date}
              </CalciteOption>
            </CalciteSelect>
            {(sortBy !== "relevance") && 
              <CalciteButton 
                appearance="transparent" 
                kind="neutral"
                iconStart={sortDirIcon}
                onClick={onSortDirClick}
              /> 
            }
          </div>
        </div>
        <CalciteTabs>
          <CalciteTabNav slot="title-group" >
            <CalciteTabTitle 
              tab="myContent" 
              selected={scope === "myContent" ? true : undefined}
              onCalciteTabsActivate={e => onScopeChange("myContent")}
            >
              {i18n.portalItemBrowser.scope.myContent}
            </CalciteTabTitle>
            <CalciteTabTitle 
              tab="myOrganization" 
              selected={scope === "myOrganization" ? true : undefined}
              onCalciteTabsActivate={e => onScopeChange("myOrganization")}
            >
              {i18n.portalItemBrowser.scope.myOrganization}
            </CalciteTabTitle>
          </CalciteTabNav>
        </CalciteTabs>
      </div>
    )
  }

  renderMore() {
    const i18n = Context.instance.i18n;
    const { nextStart } = this.state;
    const vis = (nextStart > 0) ? "visible" : "hidden";
    return (
      <div className="i--more" style={{visibility: vis}}>
        <CalciteButton 
          appearance="outline"
          onClick={evt => {
            this.searchClicked({isMore: true})
          }}
        >
          {i18n.portalItemBrowser.more}
        </CalciteButton>
      </div>
    );
  }

  searchClicked = (mixin) => {
    const opts = {
      searchText: this.state.searchText,
      searchScope: this.props.rdxSearchScope,
      sortBy: this.props.rdxSortBy,
      sortDir: this.props.rdxSortDir,
      isMore: false
    };
    if (mixin) Object.assign(opts,mixin);
    this.query(opts);
  }

}

const mapStateToProps = (state,ownProps) => {
  const rdxKey = ownProps.rdxKey;
  const searchTextKey = rdxKey + "__searchText";
  const searchScopeKey = rdxKey + "__searchScope";
  const sortByKey = rdxKey + "__sortBy";
  const sortDirKey = rdxKey + "__sortDir";
  return {
    rdxSearchText: Rdx.getValue(state,searchTextKey),
    rdxSearchScope: Rdx.getValue(state,searchScopeKey),
    rdxSortBy: Rdx.getValue(state,sortByKey),
    rdxSortDir: Rdx.getValue(state,sortDirKey)
  }
}

export default connect(mapStateToProps)(ItemBrowser);