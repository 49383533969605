import React from "react";

import Context from "../../../context/Context";
import ItemProperties from "../common/components/ItemProperties";
import { IItemProperties } from "../common/types";
import * as component from "../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-modal";
import {
  CalciteButton,
  CalciteModal
} from "@esri/calcite-components-react";

interface Props {
  onClose: () => void,
  onSaveClicked: (metaInfo, folderId, sharingInfo) => void,
  open: boolean
}

interface State {
  saving: boolean
}

export default class SaveAs extends React.Component<Props, State> {

  itemProperties: IItemProperties;

  constructor(props) {
    super(props);
    this.state = component.newState({
      saving: false
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const { open } = this.props;
    const title = Context.instance.isFPE() 
      ? i18n.miniapps.createFpeApp
      : i18n.spaceplanner.configurator.saveAsDialogTitle
    return (
      <CalciteModal 
        widthScale="m"
        scale="m"
        open={open ? true : undefined}
        onCalciteModalClose={this.props.onClose}
      >
        <div slot="header">{title}</div>
        <div slot="content">{this.renderContent()}</div>
        {this.renderActions()}
      </CalciteModal>
    );
  }

  renderActions() {
    const i18n = Context.instance.i18n;
    const { saving } = this.state;
    const okClicked = () => {
      if (this.itemProperties) {
        // @ts-ignore
        this.itemProperties.onSubmit();
      }
    }
    return (
      <>
        <CalciteButton slot="primary" onClick={okClicked} disabled={saving ? true : undefined}>
          {i18n.general.save}
        </CalciteButton>
        <CalciteButton slot="secondary" appearance="outline" onClick={this.props.onClose}>
          {i18n.general.cancel}
        </CalciteButton>
      </>
    )
  }

  renderContent() {
    return (
      <ItemProperties 
        disallowEveryone={true} 
        disallowGroups={true}
        hideShareWithHelp={true}
        isAppItem={true}
        submitHandle={this.saveClicked}
        onMount={itemProperties => {
          this.itemProperties = itemProperties;
        }}
      />
    );
  }

  saveClicked = () => {
    if (this.state.saving) return;
    const itemInfo: any = this.itemProperties && this.itemProperties.getItemInfo();
    if (!itemInfo || !itemInfo.title) return;
    this.setState({ saving: true });

    const metaInfo = {
      title: itemInfo.title,
      tags: itemInfo.tags,
      snippet: itemInfo.summary
    };
    const sharingInfo = itemInfo.sharingInfo;
    let folderId = itemInfo.folderId;
    if (folderId === "") folderId = null;
    if (this.props.onSaveClicked) {
      this.props.onSaveClicked(metaInfo, folderId, sharingInfo);
    }
  }

}
