import { TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from "react-redux";

// components.common.Calendar
import SDPWrapper from '../../common/Calendar/SDPWrapper';

// components.main.More.Actions.BookWorkspace
import DayToggle from '../More/Actions/BookWorkspace/DayToggle';

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import { makeEndDateTime, makeInitialTimes, makeStartDateTime } from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

// components.util
import { componentWillUnmount, newState, own } from '../../util/component';

// context
import Context from '../../../context/Context';
import Topic from '../../../context/Topic';

// redux
import Rdx from '../../../redux/Rdx';

// Calcite React
import Popover from 'calcite-react/Popover';
import { addDuration, getTopOfHour } from '../Events/dateUtil';

const CSS = {
  popoutInner: "i-popout-inner i-booking-details-popup",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  filterForm: "i-filter-form",
  toggleAllDay: "i-toggle-all-day",
  toggleLabel: "i-toggle-label",
  durationFilter: "i-duration-filter",
  durationFilterForm: "i-duration-filter-form",
  durationGroup: "i-duration-group",
  durationDate: "i-date",
  durationTime: "i-time",
  iconContainer: "i-sidebar-icon-container i-filter-container",
  icon: "i-more-menu-icon",
  spaceFilters: "i-space-filter",
  button: "i-book-button",
  dateString: "i-date-string",
  confirmButton: "i-book-confirm",
  bookingFields: "i-booking-fields",
  bookingDetails: "i-booking-details",
  bookingDetailsTitle: "i-booking-details-title"
};

class CheckInOutPicker extends React.Component {
  constructor(props) {
    super(props);

    const { 
      rdxAllDayChecked, 
      rdxStartDateSelected, 
      rdxEndDateSelected, 
      rdxStartTimeSelected, 
      rdxEndTimeSelected, 
      when 
    } = this.props;

    let allDay = rdxAllDayChecked;
    let startDate = rdxStartDateSelected;
    let endDate = rdxEndDateSelected;
    let startTime = rdxStartTimeSelected;
    let endTime = rdxEndTimeSelected;

    if (when) {
      allDay = when.duration === "allDay";

      startDate = new Date(when.start.date);
      endDate = new Date(when.end.date);
      if (!allDay) {
        startTime = moment(startDate);
        endTime = moment(endDate);
      } else {
        const initialTimes = makeInitialTimes();
        startTime = initialTimes.startTime;
        endTime = initialTimes.endTime;
      }
    }

    this.state = newState({
      allDay,
      startDate,
      endDate,
      startTime,
      endTime,
      startCalendarOpen: false,
      endCalendarOpen: false,
      toggleFocused: false
    });
  }

  componentDidMount() {
    document.addEventListener("keyup", this._returnClicked, false)
    own(this, [
      Topic.subscribe(Topic.ToggleReturnClicked, () => {
        const { allDay } = this.state;
        if (this.state.toggleFocused) {
          this.setState({ allDay: !allDay }, () => this._onDateTimeChanged({ mAllDay: !allDay}));
        }
      })
    ]);
  }

  componentDidUpdate() {
    const clockIcons = document.getElementsByClassName("ant-picker-suffix")
    for (let i = 0; i < clockIcons.length; i++) {
      clockIcons[i].setAttribute("aria-hidden", "true")
    }
    const cells = document.getElementsByClassName("ant-picker-time-panel-cell")
    for (let i = 0; i < cells.length; i++) {
      const text = cells[i].firstChild.innerText
      let ariaLabel = ""
      if (text) ariaLabel = text
      cells[i].setAttribute("tabIndex", "0")
      cells[i].setAttribute("role", "button")
      if (ariaLabel && ariaLabel.length > 0) cells[i].setAttribute("aria-label", text)
    }
    const panel = document.getElementsByClassName("ant-picker-panel")
    for (let i = 0; i < panel.length; i++) {
      panel[i].setAttribute("tabIndex", "0")
    }
    const { start, end } = this.props;
    const { startTime, endTime } = this.state;
    if (startTime.valueOf() !== start || endTime.valueOf() !== end) {
      this.setState({
        startDate: new Date(start),
        startTime: moment(start),
        endDate: new Date(end),
        endTime: moment(end)
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this._returnClicked, false);
    componentWillUnmount(this);
  }

  getSyncedComponentState() {
    const { start, end, duration } = this.props;
    const state = { ...this.state };

    state.allDay = duration === "allDay";
    if (start) {
      state.startDate = new Date(start);
      state.startTime = moment(start);
    }
    if (end) {
      state.endDate = new Date(end);
      state.endTime = moment(end);
    }

    return state;
  }

  renderTimePicker(options) {
    const { allDay } = this.state;
    const { value, ariaLabel, onTimeChanged } = options;
    const defaultProps = this._makeDefaultTimeProps(allDay, options);

    return (
      <TimePicker 
        {...defaultProps} 
        value={value} 
        ariaLabel={ariaLabel} 
        onChange={onTimeChanged}
      />
    );
  }

  renderDatePicker(options) {
    const { 
      onClick, 
      onRequestClose, 
      ariaLabel, 
      formattedDate, 
      open,
      initialDate,
      onDateChange,
      outsideRange,
      dayHighlighted,
      type
    } = options;


    const calendarIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16";

    let disabled = false;
    let dateBtnStyle = {};

    if(this.props.operation === "updateBookingTime" && this.props.isOngoingBooking && type === "checkInDate") {
      disabled = true;
      dateBtnStyle.cursor = "not-allowed";
    }

    const dateButton = (
      <button 
        aria-label={ariaLabel}
        className={CSS.durationDate} 
        onClick={onClick}
        disabled={disabled}
      >
        <div className={CSS.iconContainer}>
          <div className={CSS.dateString}>{formattedDate}</div>
          <svg className={CSS.icon}><use href={calendarIcon}></use></svg>
        </div>
      </button>
    );

    return (
      <Popover
        closeOnSelect={false}
        onRequestClose={onRequestClose}
        open={open}
        targetEl={dateButton}
        appendToBody
        style={dateBtnStyle}
      >
        <SDPWrapper 
          initialDate={initialDate} 
          onDateChange={onDateChange}
          outsideRange={outsideRange} 
          dayHighlighted={dayHighlighted} 
          booking={true}
        />
      </Popover>
    );
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { viewType } = this.props;
    const { allDay, startTime, endTime } = this.getSyncedComponentState();

    const { format } = this._getLocaleFormats();
    const pickerStartTime = allDay ? moment('0:00', format) : moment(startTime, format);
    const pickerEndTime = allDay ? moment('23:59', format) : moment(endTime, format);
    const { checkInDateAriaLabel, checkOutDateAriaLabel } = this._createDateLabels();
    const checked = allDay || '';
    
    const dateTimeProps = {
      checkInDate: this._getStartCalendarOptions(),
      checkOutDate: this._getEndCalendarOptions()
    };

    const timePickerProps = {
      start: {
        value: pickerStartTime,
        ariaLabel: checkInDateAriaLabel,
        onTimeChanged: this._onStartTimeChanged,
        type: "checkInTime"
      },
      end: {
        value: pickerEndTime,
        ariaLabel: checkOutDateAriaLabel,
        onTimeChanged: this._onEndTimeChanged,
        type: "checkOutTime"
      }
    };

    let disabled = false;

    if(this.props.operation === "updateBookingTime" && this.props.isOngoingBooking) {
      disabled = true;
    }

    const allDayToggle = (
      <div key="allDay" className={CSS.toggleAllDay}>
        <label htmlFor="allDay" className={CSS.toggleLabel}>
          {i18n.more.bookWorkspace.allDay}
        </label>
        <DayToggle 
          id="allDay"
          disabled={disabled}
          checked={!!checked} 
          onChange={this._allDayChanged}
          ariaLabel={i18n.more.bookWorkspace.toggleAriaLabel}
          onToggleFocus={this._onToggleFocus}
          onToggleBlur={this._onToggleBlur}
        />
      </div>
    );

    const divider = (<div key="divider" className="i-time-divider"></div>);

    const checkInStr = this.props?.bookingType === "meetingRooms"
      ? i18n.meetingRooms.start
      : i18n.more.bookWorkspace.checkIn;
    const checkInLabel = (<div key="checkInLabel" className={CSS.bookingFields}>{checkInStr}</div>);
    const checkIn = (
      <div key="checkIn" className={CSS.durationGroup}>
        {this.renderDatePicker(dateTimeProps.checkInDate)}
        {this.renderTimePicker(timePickerProps.start)}
      </div>
    );

    const checkOutStr = this.props?.bookingType === "meetingRooms"
      ? i18n.meetingRooms.end
      : i18n.more.bookWorkspace.checkOut;
    const checkOutLabel = (<div key="checkOutLabel" className={CSS.bookingFields}>{checkOutStr}</div>);
    const checkOut = (
      <div key="checkOut" className={CSS.durationGroup}>
        {this.renderDatePicker(dateTimeProps.checkOutDate)}
        {this.renderTimePicker(timePickerProps.end)}
      </div>
    );

    const nodes = viewType === "horizontal" ?
      [checkInLabel, checkIn, allDayToggle, divider, checkOutLabel, checkOut] :
      [allDayToggle, checkInLabel, checkIn, checkOutLabel, checkOut];

    return (
      <div className={`${CSS.durationFilterForm} ${viewType}`}>
        {nodes}
      </div>
    );
  }

  //----------------------------------
  //
  //  Private Methods
  //
  //----------------------------------


  _makeDefaultTimeProps = (allday, options) => {
    const { allDay } = this.getSyncedComponentState();
    const { size } = this.props;
    const { use12Hours, format } = this._getLocaleFormats();

    let disabled = !!allDay;

    if(this.props.operation === "updateBookingTime" && this.props.isOngoingBooking) {
      if(options.type === "checkInTime") {
        disabled = true;
      }
      if(options.type === "checkOutTime" && (this.props.duration === "allDay")) disabled = false;
    }

    return { 
      use12Hours, 
      format,
      size: size || "medium",
      minuteStep: 5,
      showNow: false,
      inputReadOnly: true,
      onOpenChange: this._onOpenChange,
      disabled: disabled,
      allowClear: false
    };
  }

  _getStartCalendarOptions = () => {
    const { startDate, startCalendarOpen } = this.getSyncedComponentState();
    const { checkInDateLabel, checkInDateAriaLabel } = this._createDateLabels();
    const isOutsideRange = (day) => moment().isAfter(day, 'day');
    const isDayHighlightedStart = (day) => {
      const start = moment(startDate);
      return moment().isSame(day, 'day') && !start.isSame(day, 'day');
    };

    return {
      onClick: this._toggleStartCalendar, 
      onRequestClose: this._closeStartCalendar, 
      ariaLabel: checkInDateAriaLabel, 
      formattedDate: checkInDateLabel, 
      open: startCalendarOpen,
      initialDate: startDate,
      onDateChange: this._onStartDateChanged,
      outsideRange: isOutsideRange,
      dayHighlighted: isDayHighlightedStart,
      type: "checkInDate"
    };
  }

  _getEndCalendarOptions = () => {
    const { endDate, endCalendarOpen } = this.getSyncedComponentState();
    const { checkOutDateLabel, checkOutDateAriaLabel } = this._createDateLabels();
    const isOutsideRange = (day) => moment().isAfter(day, 'day');
    const isDayHighlightedEnd = (day) => {
      const end = moment(endDate);
      return moment().isSame(day, 'day') && !end.isSame(day, 'day');
    };

    return {
      onClick: this._toggleEndCalendar, 
      onRequestClose: this._closeEndCalendar, 
      ariaLabel: checkOutDateAriaLabel, 
      formattedDate: checkOutDateLabel, 
      open: endCalendarOpen,
      initialDate: endDate,
      onDateChange: this._onEndDateChanged,
      outsideRange: isOutsideRange,
      dayHighlighted: isDayHighlightedEnd,
      type: "checkOutDate"
    };
  }

  _getLocaleFormats = () => {
    const locale = Context.getInstance().lib.dojo.kernel.locale;
    const use12Hours = (locale === "en" || locale === "en-us");
    const format = (locale === "en" || locale === "en-us") ? "h:mm A" : "H:mm";

    return { use12Hours, format };
  }

  _createDateLabels = () => {
    const i18n = Context.getInstance().i18n;
    const lib = Context.getInstance().lib;
    const { startDate, endDate } = this.getSyncedComponentState();

    const formattedDates = {
      startDate: lib.dojo.locale.format(startDate, { selector: "date", formatLength: "short" }),
      endDate: lib.dojo.locale.format(endDate, { selector: "date", formatLength: "short" })
    };
    const checkInDateAriaLabel = `${formattedDates.startDate}, ${i18n.more.bookWorkspace.startCalendarAriaLabel}`;
    const checkOutDateAriaLabel = `${formattedDates.endDate}, ${i18n.more.bookWorkspace.endCalendarAriaLabel}`;

    return { 
      checkInDateLabel: formattedDates.startDate, 
      checkOutDateLabel: formattedDates.endDate, 
      checkInDateAriaLabel,
      checkOutDateAriaLabel
    };
  }

  _toggleStartCalendar = () => {
    const { startCalendarOpen } = this.getSyncedComponentState();
    this.setState({
      startCalendarOpen: !startCalendarOpen,
      endCalendarOpen: false
    });
  }

  _toggleEndCalendar = () => {
    const { endCalendarOpen } = this.getSyncedComponentState();
    this.setState({
      startCalendarOpen: false,
      endCalendarOpen: !endCalendarOpen
    });
  }

  _closeStartCalendar = () => {
    this.setState({ startCalendarOpen: false });
  }

  _closeEndCalendar = () => {
    this.setState({ endCalendarOpen: false });
  }

  _onStartDateChanged = (date) => {
    if (!date) {
      return;
    }
    const { startTime, endTime, endDate, allDay } = this.getSyncedComponentState();

    const tempDate = date;
    tempDate.setHours(0, 0, 0, 0);
    let isValid = tempDate <= endDate;
    if (isValid) {
      let tempAllDay = allDay;
      if (!tempAllDay) {
        const { format } = this._getLocaleFormats();
        const baseStart = moment("0:00", format);
        const baseEnd = moment("23:59", format);
        if (
          startTime.hour() === baseStart.hour() &&
          startTime.minute() === baseStart.minute() &&
          endTime.hour() === baseEnd.hour() &&
          endTime.minute() === baseEnd.minute()
        ) {
          tempAllDay = true;
        }
      }
      this.setState({
        startDate: date,
        allDay: tempAllDay,
        startCalendarOpen: false
      }, () => this._onDateTimeChanged({ mStartDate: date, mAllDay: tempAllDay }));
    } else {
      this.setState({
        startDate: date,
        endDate: date,
        startCalendarOpen: false
      }, () => this._onDateTimeChanged({ mStartDate: date, mEndDate: date, mAllDay: allDay }));
    }
  }

  _onEndDateChanged = (date) => {
    if (!date) {
      return;
    }
    const { startTime, endTime, startDate, allDay } = this.getSyncedComponentState();

    const tempDate = date;
    tempDate.setHours(0, 0, 0, 0);
    let isValid = tempDate >= startDate;
    if (isValid) {
      let tempAllDay = allDay
      if (!tempAllDay) {
        const { format } = this._getLocaleFormats();
        const baseStart = moment("0:00", format);
        const baseEnd = moment("23:59", format);
        if (
          startTime.hour() === baseStart.hour() &&
          startTime.minute() === baseStart.minute() &&
          endTime.hour() === baseEnd.hour() &&
          endTime.minute() === baseEnd.minute()
        ) {
          tempAllDay = true;
        }
      }
      this.setState({
        endDate: date,
        allDay: tempAllDay,
        endCalendarOpen: false
      }, () => this._onDateTimeChanged({ mEndDate: date, mAllDay: tempAllDay }));
    } else {
      this.setState({
        startDate: date,
        endDate: date,
        endCalendarOpen: false
      }, () => this._onDateTimeChanged({ mStartDate: date, mEndDate: date, mAllDay: allDay }));
    }
  }

  _onStartTimeChanged = (time) => {
    if (!time) {
      return;
    }
    const { startDate: checkIn, endDate: checkOut } = this.getSyncedComponentState();

    if (this._equalDates(checkIn, checkOut)) {
      let endTime = this.state.endTime;
      if (time.isSame(endTime)) {
        endTime = moment(time).add(1, "hour");
      } else {
        if (time.isAfter(endTime)) {
          endTime = moment(time).add(1, "hour");
        } else {
          if (time.isSame(endTime, "hour")) {
            const endMin = endTime.minute();
            const startMin = time.minute();
            const diff = endMin - startMin;
            if (diff <= 30) {
              endTime = moment(endTime).add(30 - diff, "minute");
            }
          } else if (endTime.isSame(moment(time).add(1, "hour"), "hour")) {
            const endMin = endTime.minute() + 60;
            const startMin = time.minute();
            const diff = Math.abs(endMin - startMin);
            if (diff <= 30) {
              endTime = moment(endTime).add(30 - diff, "minute");
            }
          }
        }
      }
      this.setState({
        startTime: time,
        endTime: endTime
      }, () => this._onDateTimeChanged({ mStartTime: time, mEndTime: endTime }));
    } else {
      this.setState({
        startTime: time
      }, () => this._onDateTimeChanged({ mStartTime: time }));
    }
  }

  _onEndTimeChanged = (time) => {
    if (!time)
      return;
    
    const { startDate: checkIn, endDate: checkOut } = this.getSyncedComponentState();
    let isUpdate = false;
    
    if (this._equalDates(checkIn, checkOut)) {
      if (time.isSame(this.state.startTime, "hour")) {
        const startMin = this.state.startTime.minute();
        const endMin = time.minute();
        const diff = endMin - startMin;
        if (diff > 5)
          isUpdate = true;
      } else if (time.isSame(moment(this.state.startTime).add(1, "hour"), "hour")) {
        const startMin = this.state.startTime.minute();
        const endMin = time.minute() + 60;
        const diff = endMin - startMin;
        if (diff >= 30)
          isUpdate = true;
      } else if (time.isAfter(this.state.startTime)) {
        isUpdate = true;
      }
    } else {
      isUpdate = true;
    }

    if (isUpdate)
      this.setState({ endTime: time }, () => this._onDateTimeChanged({ mEndTime: time }));
  } 

  _onDateTimeChanged = (options) => {
    const { startDate, endDate, startTime, endTime, allDay } = this.getSyncedComponentState();
    const { mStartDate, mEndDate, mStartTime, mEndTime, mAllDay } = options;
    const { onDateChanged } = this.props;

    let isAllDay = allDay;
    if (mAllDay !== null || mAllDay !== undefined) {
      isAllDay = !!mAllDay;
    }
    const sd = mStartDate || startDate;
    const st = mStartTime || startTime;
    const ed = mEndDate || endDate;
    const et = mEndTime || endTime;

    const startDateTime = makeStartDateTime(sd, moment(st), isAllDay);
    const endDateTime = makeEndDateTime(ed, moment(et), isAllDay, true);

    if (onDateChanged && typeof onDateChanged === "function") {
      onDateChanged(new Date(startDateTime), new Date(endDateTime), isAllDay);
    }
  }

  _allDayChanged = (checked) => {
    // when toggling off all-day, times should be set based on default times #7966
    const options = { mAllDay: checked };
    if (checked === false) {
      const { bookingType } = this.props;
      if (bookingType === "hotel") {
        const times = makeInitialTimes();
        options.mStartTime = times.startTime;
        options.mEndTime = times.endTime;
      } else {
        const topOfHour = getTopOfHour();
        options.mStartTime = moment(topOfHour);
        options.mEndTime = moment(addDuration(topOfHour, "oneHour"));
      }
    }
    this.setState({ allDay: checked }, () => this._onDateTimeChanged(options));
  }

  _returnClicked = (event) => {
    if (event.keyCode === 13) {
      Topic.publish(Topic.ToggleReturnClicked, {});
    }
  }

  _onToggleFocus = () => {
    this.setState({ toggleFocused: true });
  }

  _onToggleBlur = () => {
    this.setState({ toggleFocused: false });
  }

  _equalDates = (date1, date2) => {
    const matchingDays = date1.getDate() === date2.getDate();
    const matchingMonths = date1.getMonth() === date2.getMonth();
    const matchingYears = date1.getFullYear() === date2.getFullYear();

    return matchingDays && matchingMonths && matchingYears;
  }

  _onOpenChange = (open) => {
    if (open) {
      const preventScroll = () => {
        const timeColumns = document.getElementsByClassName("ant-picker-time-panel-column");
        for (let i = 0; i < timeColumns.length; i++) {
          const text = timeColumns[i].firstChild.firstChild.innerText;
          if (text === "AM" || text === "PM") {
            timeColumns[i].style.overflowY = "visible";
          }
        }
      }
      setTimeout(preventScroll, 100);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    rdxStartDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDayChecked: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY)
  };
}

export default connect(mapStateToProps)(CheckInOutPicker);