import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import ItemBrowser from "../../common/ItemBrowser/ItemBrowser";
import ModalController from "../../common/Modal/ModalController";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as configUtil from "./configUtil";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  issues: "i-issues",
  issue: "i-issue",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  //secondaryAction: "i-button i-button-clear",
  secondaryAction: "i-button-clear-no-border i-font-brand-primary"
};

export default class NetworkServiceSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      viewsReloading: false
    });
    this.browseClicked = this.browseClicked.bind(this);
    this.removeClicked = this.removeClicked.bind(this);
  }

  browseClicked() {
    const i18n = Context.instance.i18n;
    const i18nSection = this.getI18nSection();
    const controller = new ModalController();
    const props = {
      title: i18nSection.caption,
      itemType: "Network Analysis Service",
      rdxKey: "cfg-networkservice",
      onSelect: (item) => {
        aiimUtil.readServiceJson(item.url).then(result => {
          const serviceInfo = result && result.data;
          this.selectLayer(item,serviceInfo,controller);
        }).catch(ex => {
          ModalController.showMessage(i18n.messages.problemAccesingItem,
            item.title,"i-configurator-modal");
          console.warn("Error loading network service",item.url);
          console.error(ex);
          controller.close();
        });
      }
    }
    const content = (
      <ItemBrowser itemType={props.itemType}
        rdxKey={props.rdxKey} onSelect={props.onSelect} />
    );
    controller.show(content,props.title,"i-configurator-modal");
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ReloadViews,params => {
        this.setState(state => {
          return {
            viewsReloading: true
          };
        });
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.setState(state => {
          return {
            viewsReloading: false
          };
        });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getCfgSection() {
    const cfg = this.props.cfg;
    if (this.props.isClosestFacility) {
      return cfg.closestFacilityService;
    }
    return cfg.routeService;
  }

  getI18nSection() {
    const i18n = Context.instance.i18n;
    if (this.props.isClosestFacility) {
      return i18n.configurator.closestFacilityService;
    }
    return i18n.configurator.routeService;
  }

  selectLayer(item,serviceInfo,modalController) {
    let layerName;
    const i18n = Context.instance.i18n;
    const i18nSection = this.getI18nSection();
    const cfgSection = this.getCfgSection();
    const isClosestFacility = this.props.isClosestFacility;
    const routeLayers = serviceInfo && serviceInfo.routeLayers;
    const cfLayers = serviceInfo && serviceInfo.closestFacilityLayers;
    const autoSetClosestFacility = true;

    const apply = () => {
      const cfg = this.props.cfg;
      cfgSection.item = item;
      cfgSection.serviceInfo = serviceInfo;
      cfgSection.layerName = layerName;
      let prop = "routeService";
      let backwardCompatibilityProp = "networkServiceUrl";
      if (isClosestFacility) {
        prop = "closestFacilityService";
        backwardCompatibilityProp = "closestFacilityServiceUrl";
      }
      cfg.setConfigurable(prop,{
        itemId: item.id,
        layerName: layerName
      });
      cfg.setConfigurable(backwardCompatibilityProp,null);
      if (!isClosestFacility && autoSetClosestFacility &&
          cfg.closestFacilityService &&
          cfLayers && cfLayers.length === 1) {
        const cfLayerName = cfLayers[0];
        const cfSection = cfg.closestFacilityService;
        cfSection.item = item;
        cfSection.serviceInfo = serviceInfo;
        cfSection.layerName = cfLayerName;
        prop = "closestFacilityService";
        backwardCompatibilityProp = "closestFacilityServiceUrl";
        cfg.setConfigurable(prop,{
          itemId: item.id,
          layerName: cfLayerName
        });
        cfg.setConfigurable(backwardCompatibilityProp,null);
      }
      if (modalController) modalController.close();
      component.refresh(this);
      Topic.publish(Topic.ReloadViews,{});
      console.log("config",Context.instance.config)
    };

    let layers = isClosestFacility ? cfLayers : routeLayers;
    const options = [];
    if (Array.isArray(layers) && layers.length > 0) {
      layerName = layers[0];
      layers.forEach(name => {
        options.push(
          <option key={name} value={name}>{name}</option>
        );
      });
      //options.push(<option key={"Test"} value={"Test"}>{"Testing only"}</option>);
      if (options.length > 1) {
        const controller = new ModalController();
        const onChangeLayer = (evt) => {
          layerName = evt.target.value;
        };
        const okClicked = () => {
          apply();
          controller.close();
        };
        const content = (
          <div key="cfg-network-layer-popup" className={CSS.formGroup}>
            <div>{item.title}</div>
            <label id={"cfg-network-layer-label"} htmlFor={"cfg-network-layer"}>
              <div className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}
                  >{i18nSection.selectLayer}</span>
              </div>
              <select id={"cfg-network-layer"} onChange={onChangeLayer}>
                {options}
              </select>
            </label>
            <div key="actions" className={CSS.actions}>
              <button type="button" className={CSS.primaryAction}
                onClick={okClicked}>{i18n.general.ok}</button>
            </div>
          </div>
        );
        controller.show(content,i18nSection.caption);
      } else {
        apply();
      }
    } else {
      let msg = i18nSection.noLayers;
      ModalController.showMessage(msg,item.title,"i-configurator-modal");
    }
  }

  render() {
    const context = Context.instance;
    const i18n = context.i18n;
    const i18nSection = this.getI18nSection();
    const cfgSection = this.getCfgSection();
    const item = cfgSection.item;
    const hasLayer = (cfgSection.serviceInfo && !!cfgSection.layerName);
    const disabled = !Context.instance.views.mapView;

    let itemTitleNode = null;
    if (item) {
      const itemTitle = (item && item.title) || "";
      itemTitleNode = (
        <div key="itemTitle">{itemTitle}</div>
      );
    }

    let browseLabel = i18n.configurator.add;
    if (hasLayer) browseLabel = i18n.configurator.change;

    let removeButton = null;
    if (hasLayer) {
      removeButton = (
        <button type="button" className={CSS.secondaryAction}
          onClick={this.removeClicked}>{i18n.configurator.remove}</button>
      );
    }

    let layer = null;
    let layerName = cfgSection.layerName;
    if (hasLayer && layerName) {
      let pattern = i18nSection.layerPattern;
      let v = pattern.replace("{name}",layerName);
      layer = (
        <div key="layerName">{v}</div>
      );
    }

    const issues = this.renderIssues();
    const detailsLink = configUtil.renderDetailsLink(item);

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}>{i18nSection.caption}</span>
          <HelpTip text={i18nSection.help}/>
        </div>
        {itemTitleNode}
        {layer}
        {issues}
        <div key="actions" className={CSS.actions}>
          <button type="button" className={CSS.secondaryAction}
            disabled={disabled}
            onClick={this.browseClicked}>{browseLabel}</button>
          {removeButton}
          {detailsLink}
        </div>
      </div>
    );
  }

  renderIssues() {
    if (this.state.viewsReloading) return null;
    return null;
  }

  removeClicked() {
    const cfg = this.props.cfg;
    const cfgSection = this.getCfgSection();
    cfgSection.item = null;
    cfgSection.serviceInfo = null;
    cfgSection.layerName = null;
    let prop = "routeService";
    let backwardCompatibilityProp = "networkServiceUrl";
    if (this.props.isClosestFacility) {
      prop = "closestFacilityService";
      backwardCompatibilityProp = "closestFacilityServiceUrl";
    }
    cfg.setConfigurable(prop,{
      itemId: null,
      layerName: null
    });
    cfg.setConfigurable(backwardCompatibilityProp,null);
    component.refresh(this);
    Topic.publish(Topic.ReloadViews,{});
  }

}
