import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import VersionManager from "./VersionManager";
import Reservations from "../../aiim/datasets/Reservations";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as portalUtil from "../../util/portalUtil";
import * as serviceUtil from "./serviceUtil";
import * as sourceUtil from "./sourceUtil";
import { makeCurrentTimeRange, makeDefExpClause } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import { ISourceInfo } from "./OfficePlan";
import { IAiimFeatureLayer } from "./PlanOpener";
import type Source from "../../aiim/base/Source";

interface IProjectInfo {
  detailsLayer: __esri.FeatureLayer, 
  peopleLayer: IAiimFeatureLayer,
  unitsLayer: IAiimFeatureLayer,
  sourceInfo?: ISourceInfo
}
interface IBaselineInfo extends IProjectInfo {
  peopleSource: Source,
  unitsSource: Source
}
interface IHostedInfo extends IProjectInfo {
}
interface IVersionedInfo extends IProjectInfo {
  facilitiesLayer: __esri.FeatureLayer,
  levelsLayer: __esri.FeatureLayer,
  sitesLayer: __esri.FeatureLayer,
  versionManager?: VersionManager
}
interface IInterrogateMapResult {
  baseline: IBaselineInfo,
  isVersioned: boolean,
  nonVersioned: IHostedInfo & {
    featureServiceUrl: string
  },
  versioned: IVersionedInfo & {
    featureServiceUrl: string,
    versionManagerUrl: string
  }
}

export default class Project extends BaseClass {

  isHosted: boolean;
  isVersioned: boolean;
  requiresVersioning: boolean;

  issues;

  hostedInfo: IHostedInfo;
  versionedInfo: IVersionedInfo;

  getMissingLayersMessage(): string {
    const i18n = Context.instance.i18n;
    let missingLayers = i18n.spaceplanner.issues.missingLayers;
    if (Context.instance.isFPE()) {
      missingLayers = i18n.spaceplanner.issues.missingLayers2; // include Details for the beta
    }
    return missingLayers;
  }

  hasIssues() {
    return this.issues && this.issues.length > 0;
  }

  interrogateMap(map?: __esri.Map) {
    const promise = new Promise<IInterrogateMapResult>((resolve,reject) => {
      const i18n = Context.instance.i18n;
      const aiim = Context.instance.aiim;
      const datasets = aiim && aiim.datasets;
      this.issues = [];
      this.requiresVersioning = Context.instance.getPortal().isPortal;

      // TODO ensure that the user is a publisher

      if (!map) {
        const view = Context.instance.views.mapView;
        map = view && view.map;
      }
      const layers = (map && map.allLayers) || [];

      const result: IInterrogateMapResult = {
        isVersioned: false,
        baseline: {
          peopleLayer: null,
          peopleSource: null,
          unitsLayer: null,
          unitsSource: null,
          detailsLayer: null
        },
        versioned: {
          peopleLayer: null,
          unitsLayer: null,
          detailsLayer: null,
          sitesLayer: null,
          facilitiesLayer: null,
          levelsLayer: null,
          featureServiceUrl: null,
          versionManagerUrl: null
        },
        nonVersioned: {
          peopleLayer: null,
          unitsLayer: null,
          detailsLayer: null,
          featureServiceUrl: null
        }
      }

      Promise.resolve().then(() => {
        return aiimUtil.waitForLayers(null,layers);
      }).then(() => {

        const isFPE = Context.instance.isFPE();
        const levelsDataset = datasets && datasets.levels;
        const facilitiesDataset = datasets && datasets.facilities;
        const sitesDataset = datasets && datasets.sites;

        if (!levelsDataset && isFPE) {
          this.issues.push({
            key: "noLevels",
            message: i18n.configurator.issues.noLevels
          });
        }
        if (!facilitiesDataset && isFPE) {
          this.issues.push({
            key: "noFacilities",
            message: i18n.configurator.issues.noFacilities
          });
        }

        const baseline = result.baseline;
        const nonVersioned = result.nonVersioned;
        const versioned = result.versioned;
        baseline.peopleSource = sourceUtil.getInitialPeopleSource();
        baseline.peopleLayer = baseline.peopleSource && baseline.peopleSource.layer2D;
        baseline.unitsSource = sourceUtil.getInitialUnitsSource();
        baseline.unitsLayer = baseline.unitsSource && baseline.unitsSource.layer2D;

        layers.forEach((layer: __esri.FeatureLayer) => {
          let hasPeopleFields = sourceUtil.hasPeopleFields(layer,true);
          let hasUnitFields = sourceUtil.hasUnitsFields(layer,true);
          if (hasPeopleFields) {
            if (layer.capabilities && layer.capabilities.data && layer.capabilities.data.isVersioned) {
              result.isVersioned = true;
              versioned.peopleLayer = layer;
            } else {
              nonVersioned.peopleLayer = layer;
            }
          } else if (hasUnitFields) {
            if (layer.capabilities && layer.capabilities.data && layer.capabilities.data.isVersioned) {
              result.isVersioned = true;
              versioned.unitsLayer = layer;
            } else {
              nonVersioned.unitsLayer = layer;
            }
          } else if (isFPE && sourceUtil.hasDetailsFields(layer)) {
            baseline.detailsLayer = layer;
            if (layer.capabilities && layer.capabilities.data && layer.capabilities.data.isVersioned) {
              versioned.detailsLayer = layer;
            } else {
              nonVersioned.detailsLayer = layer;
            }
          } else {
            if (isFPE && layer.capabilities && layer.capabilities.data && layer.capabilities.data.isVersioned) {
              if (levelsDataset && levelsDataset.layer2D === layer) {
                versioned.levelsLayer = layer;
              } else if (facilitiesDataset && facilitiesDataset.layer2D === layer) {
                versioned.facilitiesLayer = layer;
              } else if (sitesDataset && sitesDataset.layer2D === layer) {
                versioned.sitesLayer = layer;
              }
            }
          }
        });

        if (result.isVersioned) {
          const peopleOK = (isFPE || !!versioned.peopleLayer);
          const detailsOK = (!isFPE || !!versioned.detailsLayer);
          if (versioned.unitsLayer && peopleOK && detailsOK) {
            const fsUrl = versioned.unitsLayer.url;
            const vmUrl = fsUrl.replace("/FeatureServer","/VersionManagementServer");
            versioned.featureServiceUrl = fsUrl;
            versioned.versionManagerUrl = vmUrl;
            if (baseline.unitsLayer && baseline.unitsLayer !== versioned.unitsLayer) {
              baseline.unitsLayer.visible = false;
            }
            if (versioned.peopleLayer) {
              if (baseline.peopleLayer && baseline.peopleLayer !== versioned.peopleLayer) {
                baseline.peopleLayer.visible = false;
              }
              if (versioned.peopleLayer.url.toLowerCase() !== versioned.unitsLayer.url.toLowerCase()) {
                // not in same service
                this.issues.push({
                  key: "requiresVersionedDataset",
                  message: this.getMissingLayersMessage(),
                  //message: i18n.spaceplanner.issues.requiresVersionedDataset
                });
              }
            }
          } else {
            this.issues.push({
              key: "requiresVersionedDataset",
              message: this.getMissingLayersMessage()
              //message: i18n.spaceplanner.issues.requiresVersionedDataset
            });
          }
        } else if (this.requiresVersioning){
          this.issues.push({
            key: "requiresVersionedDataset",
            message: this.getMissingLayersMessage()
            //message: i18n.spaceplanner.issues.requiresVersionedDataset
          });

        } else {
          // non versioned
          if (nonVersioned.peopleLayer && nonVersioned.unitsLayer) {
            const fsUrl = nonVersioned.unitsLayer.url;
            nonVersioned.featureServiceUrl = fsUrl;
            if (nonVersioned.peopleLayer.url.toLowerCase() !== nonVersioned.unitsLayer.url.toLowerCase()) {
              // not in same service
              this.issues.push({
                key: "requiresDataset",
                message: this.getMissingLayersMessage()
                //message: i18n.spaceplanner.issues.requiresDataset
              });
            }
          } else {
            this.issues.push({
              key: "requiresDataset",
              message: this.getMissingLayersMessage()
              //message: i18n.spaceplanner.issues.requiresDataset
            });
          }
        }

      }).then(() => {
        if (!this.hasIssues()) {
          if (result.isVersioned) {
            return this._loadVersioned(result);
          } else {
            return this._loadHosted(result);
          }
        }
      }).then(() => {
        const view = Context.instance.views.mapView;
        let reservationLyr: any = view && view.map && view.map.allLayers.find(lyr => sourceUtil.hasReservationFields(lyr, true));
        if (reservationLyr) {
          const datasets = Context.instance.aiim.datasets;
          let res = new Reservations(reservationLyr.refreshInterval);
          datasets.reservations = res;
          datasets._datasets.push(datasets.reservations);
          // Properties for time-awareness, and disabling identify on the layer
          const { start, end } = makeCurrentTimeRange();
          reservationLyr.definitionExpression = makeDefExpClause(start, end);
          reservationLyr.popupEnabled = false;
          reservationLyr.xtnHitTestDisabled = true;
          reservationLyr.useViewTime = false;
          datasets._setDatasetLayer(datasets.reservations, view, reservationLyr);
          return res.checkSchema();
        } else {
          console.warn("Reservations Layer not found");
        }
      }).then(() => {
        console.log("interrogateMap.result",result)
        resolve(result);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _loadHosted(task: IInterrogateMapResult) {
    const promise = new Promise<void>((resolve,reject) => {
      const i18n = Context.instance.i18n;
      const {nonVersioned} = task;
      const {featureServiceUrl} = nonVersioned;
      const sourceInfo: ISourceInfo = {
        serviceUrl: featureServiceUrl
      };
      Promise.resolve().then(() => {
        return serviceUtil.readServiceJson(featureServiceUrl,sourceInfo);
      }).then(() => {
        if (!sourceInfo.peopleLayerInfo || !sourceInfo.unitsLayerInfo ||
            !sourceInfo.areasTableInfo) {
          this.issues.push({
            key: "requiresDataset",
            message: this.getMissingLayersMessage()
            //message: i18n.spaceplanner.issues.requiresDataset
          });
        }
        // if (!sourceUtil.hasAreasFields(sourceInfo.areasTableInfo)) {
        //   this.issues.push({
        //     key: "requiresDataset",
        //     message: i18n.spaceplanner.issues.requiresDataset
        //   });
        // }
      }).then(() => {
        const cap = sourceInfo.serviceInfo.capabilities.split(",");
        if (cap.indexOf("Extract") === -1) {
          this.issues.push({
            key: "exportNotEnabled",
            message: i18n.spaceplanner.issues.exportNotEnabled
          });
        }
        if (cap.indexOf("ChangeTracking") === -1) {
          this.issues.push({
            key: "changeTrackingNotEnabled",
            message: i18n.spaceplanner.issues.changeTrackingNotEnabled
          });
        }
      }).then(() => {
        sourceInfo.serviceItem = (nonVersioned.peopleLayer && nonVersioned.peopleLayer.portalItem);
        this.isHosted = true;
        this.hostedInfo = {
          peopleLayer: nonVersioned.peopleLayer,
          unitsLayer: nonVersioned.unitsLayer,
          detailsLayer: nonVersioned.detailsLayer,
          //commentsLayer: nonVersioned.commentsLayer,
          sourceInfo: sourceInfo
        }
        if (!sourceInfo.serviceItem && sourceInfo.peopleLayerInfo &&
             sourceInfo.peopleLayerInfo.serviceItemId) {
          let id = sourceInfo.peopleLayerInfo.serviceItemId;
          return portalUtil.readItem(id).then(result => {
            sourceInfo.serviceItem = result && result.data;
          });
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _loadVersioned(task: IInterrogateMapResult) {
    const promise = new Promise<void>((resolve,reject) => {
      const i18n = Context.instance.i18n;
      const {versioned} = task;
      const {featureServiceUrl} = versioned;
      const versionManager = new VersionManager();
      const sourceInfo: ISourceInfo = {
        serviceUrl: featureServiceUrl
      };
      Promise.resolve().then(() => {
        return serviceUtil.readServiceJson(featureServiceUrl,sourceInfo);
      }).then(() => {
        if (!sourceUtil.hasAreasFields(sourceInfo.areasTableInfo) && Context.instance.isSP()) {
          this.issues.push({
            key: "requiresVersionedDataset",
            message: this.getMissingLayersMessage()
          });
        }
      }).then(() => {
        const cap = sourceInfo.serviceInfo.capabilities.split(",");
        if (cap.indexOf("Editing") === -1) {
          this.issues.push({
            key: "editingNotEnabled",
            message: i18n.spaceplanner.issues.editingNotEnabled
          });
        }
      }).then(() => {
        if (!this.hasIssues()) {
          return versionManager.load(versioned.versionManagerUrl);
        }
      }).then(() => {
        sourceInfo.serviceItem = (versioned.unitsLayer && versioned.unitsLayer.portalItem);
        this.isVersioned = true;
        this.versionedInfo = {
          peopleLayer: versioned.peopleLayer,
          unitsLayer: versioned.unitsLayer,
          detailsLayer: versioned.detailsLayer,
          sitesLayer: versioned.sitesLayer,
          facilitiesLayer: versioned.facilitiesLayer,
          levelsLayer: versioned.levelsLayer,
          versionManager: versionManager,
          sourceInfo: sourceInfo
        }
        //console.log(" this.versionedInfo", this.versionedInfo)
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

}
