import FieldNames from "../../../aiim/datasets/FieldNames";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";



export function getReviewerData(username, tableData) {
  return new Promise((resolve, reject) => {
    const lib = Context.instance.lib
    const portal = Context.instance.getPortal()
    const portalUrl = portal.url
    const url = portalUrl + "/sharing/rest/community/users/" + username
    const token = getToken()
    const options = {
      query: {f: "json"},
      method: "get",
      responseType: "json",
      token: token
    }
    lib.esri.esriRequest(url, options).then(result => {
      const userData = {data: result.data, tableData: tableData}
      resolve(userData)
    }).catch(e => {
      console.error(e, "Couldn't find user ", username)
      resolve()
    })
  })
}

export function getPortalUsers(options) {
  return new Promise((resolve, reject) => {
    const { start, text } = options;
    const lib = Context.instance.lib
    const portal = Context.instance.getPortal()
    const portalUrl = portal.url
    const url = `${portalUrl}/sharing/rest/portals/self/users/search`;

    const token = getToken();
    const query = typeof text === "string" && text.trim().length > 0
      ? `(${encodeURIComponent(text)})`
      : "";
    const params = {
      start: start,
      num: 30,
      f: "json",
      q: query,
      sortField: "fullname"
    };

    const reqOptions = {
      query: params,
      method: "get",
      responseType: "json",
      token: token
    }
    lib.esri.esriRequest(url, reqOptions).then(result => {
      resolve(result);
    }).catch(e => {
      console.error("Couldn't query users", e)
      resolve()
    })
  })
}

export async function getPortalUserByEmail(options) {
  const { email } = options;
  if (!email) {
    return;
  }

  const lib = Context.instance.lib;
  const portal = Context.instance.getPortal();
  const portalUrl = portal.url;
  const url = `${portalUrl}/sharing/rest/portals/self/users/search`;

  const token = getToken();

  // Filter may not be reliable, just search the username instead?
  const filter = `email:"${email}"`;
  const params = { f: "json", start: 0, filter: filter };

  // const q = email;
  // const params = { f: "json", start: 0, q };

  const reqOptions = {
    query: params,
    method: "get",
    responseType: "json",
    token: token
  }

  try {
    const result = await lib.esri.esriRequest(url, reqOptions);
    return result;
  } catch (e) {
    console.error(e);
  }
}

export function getUsernameFromPortalUsers(result, feature) {
  const results = result && result.data && result.data.results;
  const email = getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
  if (!results || results.length === 0) {
    throw new Error(`No result in your organization for user: ${email}`);
  }

  if (results.length === 1) {
    const user = results[0];
    return user.username;
  }

  let lc;
  const knownas = getAttributeValue(feature.attributes, FieldNames.PEOPLE_FULLNAME);
  if (typeof knownas === "string") lc = knownas.toLowerCase();
  const user = results.find(r => {
    if (typeof r.fullName === "string") {
      return lc === r.fullName.toLowerCase();
    }
    return false;
  });
  if (knownas && user) {
    return user.username;
  } else {
    return results[0].username;
  }
}

export function getAllPortalUsers() {
  return new Promise((resolve, reject) => {
    const users = []
    let start = 0
    const processNextBatch = () => {
      if (start === -1) {
        resolve(users)
        return
      }
      getPortalUsers(start).then(result => {
        if (result && result.data && result.data.nextStart && result.data.users) {
          start = result.data.nextStart
          result.data.users.forEach(user => {
            users.push(user)
          })
        } else {
          console.error("Problem getting users")
          reject()
          return
        }
      }).catch(e => {
        console.error("Problem getting users", e)
        reject(e)
      }).finally(() => {
        processNextBatch()
      })
    }
    processNextBatch()
  })
}

function getToken() {
  const esriId = Context.instance.lib.esri.esriId;
  const credential = esriId.findCredential(Context.instance.getPortalUrl());
  return credential && credential.token;
}
