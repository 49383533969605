import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Attributes from "../split/Attributes";
import Context from "../../../../context/Context";
import CopyPasteVM from "./CopyPasteVM";
import FeatureCard from "./FeatureCard";
import MapSelection from "../support/MapSelection";
import PanelHeaderTools, { HeaderToolType, IHeaderToolInfo } from "../support/PanelHeaderTools";
import OfficePlan from "../../../base/OfficePlan";
import Savebar from "../support/Savebar";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";

import * as component from "../../../../components/util/component";
import * as featureFormUtil from "./featureFormUtil";
import * as featureItemUtil from "./featureItemUtil";
import { IFeatureItem } from "./featureItemUtil";

import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-chip-group";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-list";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import {
  CalciteChip,
  CalciteChipGroup,
  CalciteIcon,
  CalciteList,
  CalciteNotice,
  CalcitePanel
} from "@esri/calcite-components-react";

import { getActiveHelpTip, HelpTip, setHelpTip } from "../redux";

import {
  getHitTestResults, 
  setHitTestResults, 
  getSelectedFeatureIndex, 
  setSelectedFeatureIndex,
  HitTest,
} from "../redux";

interface Props {
  dispatch: Dispatch,
  hitTestResults: HitTest[],
  selectedFeatureIndex: number,
  setTip?: (tip: HelpTip) => void,
  tip: HelpTip
}

interface State {
  step: "selecting" | "copied" | "pasted",
  initialHitTestResults?: HitTest[],
  selectedFeatureItems?: IFeatureItem[],
  selectedHasMultipleLevels: boolean,
  copiedFeatureItems?: IFeatureItem[],
  pastedFeatureItems?: IFeatureItem[],
  activeFeatureItem?: IFeatureItem,
}

class CopyPastePanel extends React.Component<Props, State> {

  activeFeatureForm;
  viewModel: CopyPasteVM;

  _mounted = false;

  constructor(props:Props) {
    super(props);

    this.viewModel = new CopyPasteVM({});
    this.viewModel.onLevelsReset = () => component.refresh(this);

    let initialHitTestResults = null;
    let selectedFeatureItems = null;
    let selectedHasMultipleLevels = false;
    let hitTestResults = this.props.hitTestResults;
    if (hitTestResults && hitTestResults.length) {
      initialHitTestResults = hitTestResults.slice();
      selectedFeatureItems = featureItemUtil.hitsToFeatureItems(hitTestResults);
      selectedHasMultipleLevels = featureItemUtil.hasMultipleLevels(selectedFeatureItems);
    }

    this.state = component.newState({
      step: "selecting",
      initialHitTestResults,
      selectedFeatureItems,
      selectedHasMultipleLevels,
      copiedFeatureItems: null,
      pastedFeatureItems: null,
      activeFeatureItem: null
    });
  }

  componentDidMount() {
    this._mounted = true;
    component.own(this,[
      Topic.subscribe(Topic.PlanModified,params => {
        try {
          if (this._mounted && params && (params.wasUndoRedo || params.wasReconciled)) {
            this.reset(true);
          }
        } catch(ex) {
          console.error(ex);
        }
      }),
      Topic.subscribe(Topic.LevelSelected2, () => {        
        const { pastedFeatureItems } = this.state;
        if (pastedFeatureItems?.length) {
          featureItemUtil.resetLevels(pastedFeatureItems);
          this.viewModel.onLevelsReset();
        }
      })
    ]);
  }

  componentDidUpdate(prevProps) {
    const hitTestResults = this.props.hitTestResults;
    const prevHitTestResults = prevProps.hitTestResults;
    if (hitTestResults !== prevHitTestResults) {
      if (this.getStep() === "selecting") {
        const selectedFeatureItems = featureItemUtil.hitsToFeatureItems(hitTestResults);
        const selectedHasMultipleLevels = featureItemUtil.hasMultipleLevels(selectedFeatureItems);
        this.setState({
          initialHitTestResults: hitTestResults.slice(),
          selectedFeatureItems,
          selectedHasMultipleLevels
        });
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.clearHits();
    this.viewModel.clear()
    this.viewModel.destroy();
    component.componentWillUnmount(this);
  }

  canSave = () => {
    const { pastedFeatureItems } = this.state;
    return ((this.getStep() === "pasted") && (pastedFeatureItems && pastedFeatureItems.length > 0));
  }

  clearHits = () => {
    const { dispatch } = this.props;
    dispatch(setHitTestResults([]));
    dispatch(setSelectedFeatureIndex(null));
  }

  countItems = (featureItems: IFeatureItem[]): number => {
    if (featureItems) return featureItems.length;
    return 0;
  }

  getInstruction = (): string => {
    const i18n = Context.instance.i18n;
    const step = this.getStep();
    if (step === "pasted") {
      return i18n.editor.copypaste.instructions.pasted;
    } else if (step === "copied") {
      return i18n.editor.copypaste.instructions.copied;
    } else {
      return i18n.editor.copypaste.instructions.selecting;
    }
  }

  getStep = (): "selecting" | "copied" | "pasted" => {
    const { pastedFeatureItems, copiedFeatureItems, selectedFeatureItems } = this.state;
    if (pastedFeatureItems && pastedFeatureItems.length > 0) {
      return "pasted";
    } else if (copiedFeatureItems && copiedFeatureItems.length > 0) {
      return "copied";
    } else {
      return "selecting";
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { selectedHasMultipleLevels } = this.state;
    const { tip } = this.props;
    const instruction = this.getInstruction();
    const step = this.getStep();
    let cls = "i-editor-sidebar-content";
    if (step === "pasted") cls += " i-attributes-on";

    return (
      <div className="i-editor-sidebar-container i-editor-copypaste-container">
        <div className="i-editor-sidebar-toolbar">
          {this.renderToolbar()}
        </div>
        <div className={cls}>
          <CalcitePanel>
            <CalciteNotice open={!(tip && tip.visible) ? true : undefined} scale="s" closable icon="lightbulb" width="full"
              onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: instruction })}>
              <div slot="message">{instruction}</div>
            </CalciteNotice>
            {this.renderSteps()}
            <div className="i-editor-copypaste-feature-list">
              {this.renderItems()}
            </div>
            {step === "selecting" && selectedHasMultipleLevels &&
              <div className="i-editor-copypaste-warning">
                <CalciteIcon className="i--warning" icon="exclamation-mark-triangle" scale="s" />
                {i18n.editor.copypaste.sameFloorRequired}
              </div>
            }
            {this.renderAttributes()}
          </CalcitePanel>
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderSavebar()}
        </div>
      </div>
    );
  }

  renderAttributes() {
    const i18n = Context.instance.i18n;
    const { activeFeatureItem } = this.state;
    if (activeFeatureItem) {
      const graphic = activeFeatureItem.graphic;
      const layerType = activeFeatureItem.layerType;
      let type: string = activeFeatureItem.layerType;
      if (type === "units") type = "unit";
      else if (type === "details") type = "detail";
      const options = {
        title: activeFeatureItem.title,
        layer: graphic.layer,
        type: type,
        allowName: true,
        feature: graphic
      }
      return (
        <div className="i-editor-copypaste-attributes">
          <h4>{i18n.editor.copypaste.attributes}</h4>
          <Attributes 
            changeable={true}
            // @ts-ignore
            feature={options.feature} 
            options={options}
            onCreateForm={(ae,ff) => {
              this.activeFeatureForm = ff;
              ff.on("value-change",info => {
                if (info.valid && info.fieldName.toLowerCase() === "name") {
                  if (info.value) {
                    this.viewModel.nameChanged(activeFeatureItem,info.value);
                    component.refresh(this); // to get the name updated in the list
                  }
                }
              });
            }}
          />
        </div>
      )
    }
    return null;
  }

  renderItems() {
    const i18n = Context.instance.i18n;
    const { pastedFeatureItems, copiedFeatureItems, selectedFeatureItems } = this.state;
    const { dispatch, hitTestResults, selectedFeatureIndex } = this.props;
    const step = this.getStep();
    const removeable = (step === "selecting" || (step === "pasted"));
    const featureItems = step === "pasted" ? pastedFeatureItems : selectedFeatureItems;
    const selectionMode = step === "pasted" ? "single" : "none"; // @todo selectionMode="single-persist" at 1.4.2

    if (featureItems && featureItems.length > 0) {
      const isPasted = (step === "pasted");
      const cards = featureItems.map((item,i) => {
        let selected = false;
        if (step === "selecting" || step === "copied") {
          selected = (selectedFeatureIndex === item.hitIndex);
        } else if (step === "pasted") {
          selected = item.selected;
        }
        const invalid = (isPasted && !item.levelInfo);
        return (
          <FeatureCard
            key={item.layerType+"_"+item.oid} 
            featureItem={item}
            removeable={removeable}
            selected={selected}
            invalid={invalid}
            invalidMessage={i18n.editor.copypaste.notOnALevel}
            onRemove={(itm: IFeatureItem) => {
              this.viewModel.clearHoverGraphic();
              if (step === "selecting") {
                const newResults = [...hitTestResults];
                newResults.splice(item.hitIndex, 1);
                dispatch(setHitTestResults(newResults));
                dispatch(setSelectedFeatureIndex(selectedFeatureIndex >= newResults.length
                  ? newResults.length - 1
                  : selectedFeatureIndex
                ))
              } else if (step === "pasted") {
                pastedFeatureItems.splice(i,1);
                if (pastedFeatureItems.length === 0) {
                  this.reset(false);
                } else {
                  this.viewModel.removeGraphic(item,true);
                  if (this.state.activeFeatureItem && this.state.activeFeatureItem.key === item.key) {
                    this.setState({activeFeatureItem: null})
                  } else {
                    component.refresh(this);
                  }
                }
              }
            }}
            onSelect={(itm: IFeatureItem) => {
              if (step === "selecting" || step === "copied") {
                dispatch(setSelectedFeatureIndex(item.hitIndex))
              } else if (step === "pasted") {
                const ok = this.validateAttributes();
                if (ok) {
                  featureItemUtil.clearSelected(pastedFeatureItems);
                  item.selected = true;
                  this.setState({activeFeatureItem: item});
                  this.viewModel.highlightActiveFeature(item);
                } else {
                  component.refresh(this);
                }
              }
            }}
            onMouseEnter={e => {
              this.viewModel.showHoverGraphic(item);
            }}
            onMouseLeave={e => {
              this.viewModel.clearHoverGraphic();
            }}
          />
        )
      })
      return (
        <CalciteList
          selectionAppearance="border"
          selectionMode={selectionMode}
          onMouseLeave={e => {
            this.viewModel.clearHoverGraphic();
          }}
        >
          {cards}
        </CalciteList>
      )
    }
    return null;
  }

  renderSavebar() {
    const i18n = Context.instance.i18n;
    const step = this.getStep();
    return (
      <Savebar
        parentPanelKey="copypaste"
        resetDisabled={false}
        saveLabel={i18n.editor.saveAll}
        saveDisabled={!this.canSave()}
        editIsActive={step === "pasted"}
        onReset={this.resetClicked} 
        onSave={this.saveClicked}
      />
    )
  }

  renderSteps() {
    const { selectedFeatureItems, copiedFeatureItems, pastedFeatureItems } = this.state;
    const i18n = Context.instance.i18n;
    const step = this.getStep();
    const nSelected = this.countItems(selectedFeatureItems);
    const nCopied = this.countItems(copiedFeatureItems);
    const nPasted = this.countItems(pastedFeatureItems);

    const appearance = (stepName,count) => {
      return (stepName === step && count > 0) ? "solid" : "outline-fill";
    }

    const kind = (stepName,count) => {
      return (stepName === step && count > 0) ? "brand" : "neutral";
    }

    return (
      <div className="i-editor-copypaste-steps">
        <CalciteChipGroup scale="s" label="steps">
          <span className="i-editor-copypaste-step-label">
            <span>{i18n.editor.copypaste.selected}</span>
          </span>
          <CalciteChip 
            value="selecting"
            appearance={appearance("selecting",nSelected)} 
            kind={kind("selecting",nSelected)}  
          >
            {nSelected}
          </CalciteChip>
          <span className="i-editor-copypaste-step-label i--gap">
            <CalciteIcon scale="s" icon="chevron-right" flipRtl={true} />
            <span>{i18n.editor.copypaste.copied}</span>
          </span>
          <CalciteChip 
            value="copied"
            appearance={appearance("copied",nCopied)} 
            kind={kind("copied",nCopied)}  
          >
            {nCopied}
          </CalciteChip>
          <span className="i-editor-copypaste-step-label i--gap">
            <CalciteIcon scale="s" icon="chevron-right" flipRtl={true} />
            <span>{i18n.editor.copypaste.pasted}</span>
          </span>
          <CalciteChip 
            value="pasted"
            appearance={appearance("pasted",nPasted)} 
            kind={kind("pasted",nPasted)}  
          >
            {nPasted}
          </CalciteChip>
        </CalciteChipGroup>
      </div>
    )
  }

  renderToolbar() {
    const { selectedFeatureItems, selectedHasMultipleLevels, copiedFeatureItems } = this.state;
    const hasSel = (selectedFeatureItems && selectedFeatureItems.length > 0);
    const step = this.getStep();

    const headerTools:HeaderToolType[] = ["copy","paste","separator"];
    let headerDisabledTools:HeaderToolType[] = ["copy","paste"];
    let mapselDisabled = false;
    if (step === "pasted") {
      mapselDisabled = true;
    } else if (step === "copied") {
      headerDisabledTools = ["copy"];
      mapselDisabled = true;
    } else {
      if (hasSel && !selectedHasMultipleLevels) {
        headerDisabledTools = ["paste"];
      }
    }

    const onHeaderToolClick = async (e:React.MouseEvent, toolInfo:IHeaderToolInfo) => {
      const tool = toolInfo && toolInfo.name;
      if (tool === "copy") {
        const items = this.viewModel.executeCopy(selectedFeatureItems);
        this.setState({
          copiedFeatureItems: items,
          step: "copied"
        });
      } else if (tool === "paste") {
        const items = await this.viewModel.executePaste(copiedFeatureItems);
        this.setState({
          pastedFeatureItems: items,
          step: "pasted"
        }, () => {
          this.clearHits();
        });
      }
    }

    return (
      <>
        <PanelHeaderTools 
          tools={headerTools} 
          disabledTools={headerDisabledTools}
          onButtonClick={onHeaderToolClick}
        />
        <MapSelection
          view={this.viewModel.getView()}
          tools={["point", "rectangle", "polygon"]}
          selectionTypes={["detail", "unit"]}
          disabled={mapselDisabled}
          alwaysHighlightActive={true}
          ignoreVisibleAtScale={true}
          activeFillSymbol={this.viewModel.symbolParams.activeFillSymbol}
          activeLineSymbol={this.viewModel.symbolParams.activeLineSymbol}
          fillSymbol={this.viewModel.symbolParams.fillSymbol}
          lineSymbol={this.viewModel.symbolParams.lineSymbol}
        />
      </>
    )
  }

  reset = (clearHits?: boolean) => {
    const { dispatch } = this.props;
    const { initialHitTestResults } = this.state;
    const step = this.getStep();
    this.viewModel.clear();
    this.setState({
      step: "selecting",
      selectedFeatureItems: null,
      copiedFeatureItems: null,
      pastedFeatureItems: null,
      activeFeatureItem: null,  
    }, () => {
      if (clearHits || step === "selecting") {
        this.clearHits();
      } else {
        const n = (initialHitTestResults && initialHitTestResults.length) ? 0 : null;
        dispatch(setHitTestResults(initialHitTestResults));
        dispatch(setSelectedFeatureIndex(n));
      }
    })
  }

  resetClicked = () => {
    this.reset();
  }

  save = async () => {
    const { pastedFeatureItems } = this.state;
    const guard = new TransactionGuard({force:true});
    try {
      this.viewModel.cancelSketch();
      await this.viewModel.save(pastedFeatureItems);
      this.reset();
      guard.close();
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated
      });
      // publishSuccess?
    } catch(ex) {
      console.error(ex);
      guard.close();
      Topic.publishErrorUpdatingData(ex.message);
    }
  }

  saveClicked = () => {
    const i18n = Context.instance.i18n;
    const { pastedFeatureItems } = this.state;
    if (pastedFeatureItems && pastedFeatureItems.length) {
      const numMissing = featureItemUtil.countMissingLevelInfo(pastedFeatureItems);
      const activeAttribuesOk = this.validateAttributes();
      Topic.publish(Topic.ClearToast,{});
      if (numMissing > 0) {
        Topic.publish(Topic.ShowToast,{
          message: i18n.editor.copypaste.misslngLevelPattern.replace("{num}",numMissing),
          type: "warning"
        });
      }
      if (numMissing === 0 && activeAttribuesOk) this.save();
    }
  }

  validateAttributes = (): boolean => {
    const ff = this.activeFeatureForm;
    const featureItem = this.state.activeFeatureItem;
    if (ff && featureItem) {
      const result = featureFormUtil.hasInvalidInput(ff);
      if (result.hasInvalid) {
        ff.submit();
        featureFormUtil.expandGroup(ff,result.fieldName);
        featureFormUtil.scrollIntoView(ff,result.fieldName);
        return false;
      } else {
        ff.submit();
        const values = ff.getValues();
        featureFormUtil.applyValues(featureItem.graphic,values);
      }
    }
    return true;
  }

}

const mapStateToProps = (state: IRootState) => ({
  hitTestResults: getHitTestResults(state).filter(h => h.key === "unit" || h.key === "detail"),
  selectedFeatureIndex: getSelectedFeatureIndex(state),
  tip: getActiveHelpTip(state),
});
const mapDispatchToProps = (dispatch: Dispatch, ownProps) => ({
  dispatch,
  setTip: (tip: HelpTip) => {
    dispatch(setHelpTip("copypaste", tip));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyPastePanel);