// Framework and third-party non-ui
import React, { Component } from "react";
// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import Comment from "./Comment";
import * as CrudReply from "../../base/transaction/reply"
import * as commentReplyUtil from "./commentReplyUtil";
// Component specific modules (Component-styled, etc.)
import { CommentWrapper, TopWrapper, Author, TimePassed, Message, ButtonContainer, Links, ThemeColorButton, RedColorButton } from "../../styles/commentsStyles";
// App components
import InputReply from "./InputReply";
import EditReply from "./EditReply";
import Topic from "../../../context/Topic";
// Third-party components (buttons, icons, etc.)
import { ContentWrapper } from "../../styles/Work/workBar";

export default class Reply extends Component {

    constructor(props){
        super(props);
        const comment = commentReplyUtil.getCommentFieldName();
        const createUserField = commentReplyUtil.getAuthorNameField();
        this.state={
            reply : this.props.reply[comment],
            authorFullName : this.props.reply[createUserField],
            mode : "view",
            hideEdit : false,
            hideDel : false,
            editClicked : false,
            replyClicked : false
        }
    }

    cancelClicked=()=>{
        this.setState({
            editClicked : false,
            mode : "view"
        })
        Topic.publish(Topic.LoadComments, {});
        this.props.changeMode("view");
    }

    checkUser=()=>{
        const user = Context.getInstance().user;
        const url = commentReplyUtil.getCommentUrl();
        const activeUser = user.getUsername().toLowerCase();
        const isAdmin = user.isAdmin();
        const replyAuthor = this.props.reply[commentReplyUtil.getAuthorNameField()];
        if(replyAuthor && activeUser === replyAuthor.toLowerCase()) {
            this.setState({
                hideEdit : false,
                hideDel : false
            })
        }else{
            if(isAdmin){
                this.setState({
                    hideDel : false,
                    hideEdit : true
                })
            }else{
                this.setState({
                    hideEdit : true,
                    hideDel : true
                });
            }
        }
    }

    closeReply=()=>{
        this.setState({
            replyClicked : false,
            mode: "view"
        })
    }
        
    componentDidMount(){
        this.checkUser();
        const createUserField = commentReplyUtil.getAuthorNameField();
        commentReplyUtil.getFullName(this.props.reply[createUserField])
        .then((fullName)=>{
            this.setState({
                authorFullName : fullName
            });
        })
        .catch((error)=> {
            console.log("Error retrieving full-name", error);
        })
    }

    deleteReply=()=>{
        Comment.cancelSketch();
        const url = commentReplyUtil.getCommentUrl();
        const objectid = commentReplyUtil.getObjectIdComment();
        CrudReply.deleteReply(this.props.reply[objectid], url);
    }

    editClicked=()=>{
        Comment.cancelSketch();
        this.setState({
            mode : "edit",
            editClicked : true
        })
    }

    render(){
        const i18n = Context.instance.i18n;
        const comment = commentReplyUtil.getCommentFieldName();
        const createUserField = commentReplyUtil.getAuthorNameField();
        const createdDate = commentReplyUtil.getAuthorDateField(); 
      
        const timeAgo = commentReplyUtil.makeTimeAgo(this.props.reply[createdDate]);
        if(this.state.editClicked) {
            const objectid = commentReplyUtil.getObjectIdComment();
            const comment = commentReplyUtil.getCommentFieldName();
            return (
                <CommentWrapper>
                    <TopWrapper>
                            <Author>
                                {this.state.authorFullName}
                            </Author>
                    </TopWrapper>
                    <EditReply  
                        replyId = {this.props.reply[objectid]}
                        commentId={this.props.commentId}
                        reply={this.props.reply[comment]}
                        cancelClicked={this.cancelClicked}
                        changeMode={this.props.changeMode}
                    />
                </CommentWrapper>
            )
        }  
        else {
            return(
                <div>
                <CommentWrapper>
                    <TopWrapper>
                        <div>
                            <Author>
                                {this.state.authorFullName}
                            </Author>
                            <Message style={{whiteSpace: "pre-line"}}>{this.props.reply[comment]}</Message>
                        </div>
                    </TopWrapper>
                    <TimePassed>{timeAgo}</TimePassed>
                    <ButtonContainer style={{justifyContent:"flex-end"}}>
                        <Links>
                            {this.props.showReply && 
                                (<ThemeColorButton onClick={this.replyClicked}>
                                {i18n.spaceplanner.reply.caption}</ThemeColorButton>)
                            }
                            {!this.state.hideEdit &&
                                (<ThemeColorButton onClick={this.editClicked} >
                                {i18n.spaceplanner.comments.edit}</ThemeColorButton>)
                            }
                            {!this.state.hideDel && 
                                (<RedColorButton transparent extraSmall onClick={this.deleteReply}>
                                {i18n.general.del}</RedColorButton>)
                            }
                        </Links>
                    </ButtonContainer>
                </CommentWrapper>
                {this.state.replyClicked && 
                    <ContentWrapper>
                        <InputReply 
                            id={this.props.commentId}
                            geometry={this.props.geometry}
                            changeMode={this.props.changeMode}
                            closeReply={this.closeReply}
                            reply="reply"
                        />
                    </ContentWrapper>
                }
                </div>
            )
        }
    }

    replyClicked=()=>{
        Comment.cancelSketch();
        this.setState({
            replyClicked : true
        })
        this.props.changeMode("expandComment");
    }
}