import React from "react";
import ReactDOM from "react-dom";
import Attributes from "../../../../components/main/InfoPanel/Attributes";
import ChevronLeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import ChevronRightIcon from "calcite-ui-icons-react/ChevronRightIcon";
import Context from "../../../../context/Context";


export default class RelatedPopup extends React.Component {
  addBackButton = () => {
    const i18n = Context.instance.i18n
    const backButtonClicked = () => {
      if (typeof this.props.backButtonClicked === "function") {
        this.props.backButtonClicked()
      }
    }
    const btn = document.createElement("button")
    btn.id = "sp-popup-back-btn"
    btn.classList.add("i-back-btn")
    btn.id = "sp-popup-back-btn"
    btn.onclick = backButtonClicked
    btn.setAttribute("aria-label", i18n.spaceplanner.popup.ariaLabelBack)

    const node = document.createElement("div")
    btn.appendChild(node)
    if (Context.instance.uiMode.isRtl) {
      ReactDOM.render(<ChevronRightIcon />, node)
    } else {
      ReactDOM.render(<ChevronLeftIcon />, node)
    }

    const header = document.getElementById("item-popup-popup-title")
    header.insertBefore(btn, header.firstChild)
  }

  _focus = () => {
    const popupCloseBtn = document.getElementsByClassName("esri-popup__button")
    if (popupCloseBtn && popupCloseBtn.length === 1) {
      popupCloseBtn[0].focus()
    }
    const popupTitle = document.getElementsByClassName("esri-popup__header-title")
    if (popupTitle && popupTitle.length === 1) {
      popupTitle[0].setAttribute("aria-live", "polite")
    }
  }

  componentDidMount() {
    setTimeout(this.addBackButton, 1)
    setTimeout(this._focus, 100)
  }

  renderAttributes() {
    const feature = this.props.feature
    const layer = this.props.layer
    
    return (
      <div>
        <div className={"i-current-content"}>
          <div id="related-properties" className={"i-tab-content"}>
            <Attributes feature={feature} layer={layer} active={true} popup={true} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id="related-popup" key="related">
        {this.renderAttributes()}
      </div>
    )
  }
}