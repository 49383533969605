// Framework and third-party non-ui
import React from "react";
import {connect} from "react-redux";
import Rdx from "../../../redux/Rdx";
// Redux operations and local helpers/utils/modules
import * as commentReplyUtil from "./commentReplyUtil";
import * as component from "../../../components/util/component";
import * as CrudComment from "../../base/transaction/comment";
import * as mapUtil from "../../base/mapUtil";
import * as SketchViewModelUtility from "./SketchViewModelUtility";
import * as sourceUtil from "../../base/sourceUtil";
// Component specific modules (Component-styled, etc.)
// App components
import ReplyPanel from "./ReplyPanel";
import EditComment from "./EditComment";
import InputReply from "./InputReply";
import Topic from "../../../context/Topic";
import Context from "../../../context/Context";
// Third-party components (buttons, icons, etc.)
import { CommentWrapper, TopWrapper, Author, TimePassed, Message, ButtonContainer, Links, ThemeColorButton, RedColorButton } from "../../styles/commentsStyles";
import { Wrapper, Button } from "../../styles/mapStyles";
import PinIcon from "calcite-ui-icons-react/PinIcon";


class Comment extends React.Component {

    static _activeSketchViewModel;
    constructor(props){
        super(props);
        this.state={
            editClicked : false,
            replyClicked : false,
            comment : this.props.commentObj.comment,
            commentId : this.props.commentObj.id,
            authorFullName : this.props.commentObj.created_user,
            mode: "view",
            hideEdit : false,
            hideDel : false,
            isCalloutClicked : false,
            replyClicked : false
        }
    }

    static getActiveSketchHandle=()=>{
        if(Comment._activeSketchViewModel) return Comment._activeSketchViewModel;
        else return null;
    }

    _activateLevel(options) {
      if (options.zInfo) {
        const levels = Context.instance.aiim.datasets.levels;
        const facilityData = levels.getFacilityData(options.zInfo.facilityId);
        const levelData = options.zInfo.levelData;
        if (facilityData && levelData) {
          Topic.publish(Topic.ActivateLevel,{
            view: options.view,
            facilityData: facilityData,
            levelData: levelData
          });
        }
      }
    }

    activateSketch=(type) =>{
        Comment.cancelSketch();
        let id = this.state.commentId;
        const url = commentReplyUtil.getCommentUrl();
        Comment._activeSketchViewModel = SketchViewModelUtility.activateSketch(type, id, url);
    }

    addCallout =(evt)=> {
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
        const isCalloutClicked = this._isCalloutClicked();
        if(isCalloutClicked) {
            Rdx.setValue(null, Rdx.Keys.COMMENTS_ACTIVE_PIN, null);
            if(Comment._activeSketchViewModel) {
                Comment.cancelSketch();
                Comment._activeSketchViewModel = null;
            }
        }else {
            this.activateSketch("point");
            Rdx.setValue(null, Rdx.Keys.COMMENTS_ACTIVE_PIN, this.props.commentObj.id);
        }
    }

    cancelClicked=()=>{
        this.setState({
            mode : "view"
        })
        Topic.publish(Topic.LoadComments, {});
    }

    static cancelSketch=()=>{
        Rdx.setValue(null, Rdx.Keys.COMMENTS_ACTIVE_PIN, null);
        const svm = Comment._activeSketchViewModel;
        if(svm) {
            SketchViewModelUtility.cancelSketch(svm);
            Comment._activeSketchViewModel = null;
        }
    }

    changeMode=(mode)=>{
        this.setState({
            mode : mode
        })
    }

    checkUser=()=>{
        const user = Context.getInstance().user;
        const activeUser = user.getUsername().toLowerCase();
        const isAdmin = user.isAdmin();
        const commentAuthor = this.props.commentObj.created_user;
        if(commentAuthor && activeUser === commentAuthor.toLowerCase()) {
            this.setState({
                hideEdit : false,
                hideDel : false
            })
        }else{
            if(isAdmin){
                this.setState({
                    hideDel : false,
                    hideEdit : true
                })
            }else{
                this.setState({
                    hideEdit : true,
                    hideDel : true
                });
            }
        }
    }

    closeComment=()=>{
        this.setState({
            mode : "view"
        })
    }

    closeEdit=()=>{
        this.setState({
            mode: "view",
            editClicked: false
        })
    }

    closeReply=()=>{
        this.setState({
            replyClicked : false
        })
    }

    componentDidMount(){
        this.checkUser();
        commentReplyUtil.getFullName(this.props.commentObj.created_user)
        .then((fullName)=>{
            this.setState({
                authorFullName : fullName
            });
        })
        .catch((error)=> {
            console.log("Error retrieving full-name", error);
        })
        if(this.props.commentObj && this.props.commentObj.geometry) {
            this.setState({
                location : this.props.commentObj.geometry
            })
        }
        component.own(this,[
            Topic.subscribe(Topic.LoadComments,() => {
              component.refresh(this);
            })
        ]);
    }

    componentWillUnmount(){
        Comment.cancelSketch();
        component.componentWillUnmount(this);
    }

    deleteClicked=(evt)=>{
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
        Comment.cancelSketch();
        const commentUrl = commentReplyUtil.getCommentUrl();
        const replyUrl = commentReplyUtil.getCommentUrl();
        CrudComment.deleteComment(this.props.commentObj.id, commentUrl, replyUrl).then(()=>{
            Topic.publish(Topic.ChangeCommentsView, {
                type: "delete",
                commentId: this.props.commentObj.id
            })
        })
        .catch(error=>{
            console.error(error);
            Topic.publishErrorUpdatingData(error.message);
        })
    }

    editClicked=(evt)=>{
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
        Comment.cancelSketch();
        this.setState({
            editClicked:true
        })
    }

    _isCalloutClicked=()=>{
        if(this.props.commentObj.id === this.props.rdxActivePin) return true;
        return false;
    }

    render(){
        const i18n = Context.instance.i18n;
        const { commentObj } = this.props;
        const isCalloutClicked = this._isCalloutClicked();

        let calloutString, activePin = false;
        let pinLabel;
        if(this.props.commentObj.geometry) {
            calloutString = i18n.spaceplanner.callout.update;
            pinLabel = i18n.spaceplanner.comments.general.update;
        }
        else {
            calloutString = i18n.spaceplanner.callout.add;
            pinLabel = i18n.spaceplanner.comments.general.add;
        }

        if(this.props.commentObj.id === this.props.rdxActivePin) {
            activePin = true;
        }

        const timeAgo = commentReplyUtil.makeTimeAgo(commentObj.createdDate);
        let commentId, showPin = false;
        if(commentObj.geometry) {
            commentId = commentObj.id;
            showPin = true;
        }
        if (this.state.mode === "edit") {
            return(
                <CommentWrapper>
                    <ReplyPanel commentId={commentId} allReplies={this.props.replyObj}/>
                </CommentWrapper>
            )
        }
        else {
            return (
                <>
                {this.state.editClicked ?
                    <div>
                        <CommentWrapper>
                            <TopWrapper>
                                <Author>
                                    {this.state.authorFullName}
                                </Author>
                            </TopWrapper>
                        <EditComment
                            comment={commentObj.comment}
                            commentId={commentObj.id}
                            closeEdit={this.closeEdit}
                        />
                    </CommentWrapper>
                    </div>:
                    <CommentWrapper hasLocation={commentObj.geometry !== null} onClick={() => this.zoomToComment()}>
                        <TopWrapper>
                            <div>
                                <Author>
                                    {this.state.authorFullName}
                                </Author>
                                <Message style={{whiteSpace: "pre-line"}}>{commentObj.comment}</Message>
                            </div>
                        </TopWrapper>
                        <TimePassed>{timeAgo}</TimePassed>
                        <ButtonContainer>
                            <Wrapper style={{padding:0, background:"transparent"}}>
                                <Button
                                    style={{padding: "0.31rem 0.5rem"}}
                                    clear={!isCalloutClicked}
                                    onClick={this.addCallout}
                                    icon={<PinIcon size={16} style={{ margin: 0 }} />}
                                    iconPosition="before"
                                    title={calloutString}>
                                        {pinLabel}
                                </Button>
                            </Wrapper>
                            <Links>
                                <ThemeColorButton  onClick={this.replyClicked}>
                                    {i18n.spaceplanner.reply.caption}</ThemeColorButton>
                                {!this.state.hideEdit && (
                                <ThemeColorButton  onClick={this.editClicked} disabled={this.state.hideEdit}>
                                    {i18n.spaceplanner.comments.edit}</ThemeColorButton>
                                )}
                                {!this.state.hideDel && (
                                    <RedColorButton  onClick={this.deleteClicked} disabled={this.state.hideDel}>
                                    {i18n.general.del}</RedColorButton>
                                )}
                            </Links>
                        </ButtonContainer>
                    </CommentWrapper>
                }

                <ReplyPanel allReplies={this.props.replyObj} commentId={this.props.commentObj.id} changeMode={this.changeMode}/>
                {this.state.replyClicked &&
                    <InputReply id={this.props.commentObj.id}
                                geometry={this.props.commentObj.geometry}
                                changeMode={this.changeMode}
                                closeReply={this.closeReply}
                    />}
                </>
            )
        }
    }

    replyClicked=(evt)=>{
        if (evt) {
          evt.stopPropagation();
          evt.preventDefault();
        }
        Comment.cancelSketch();
        this.setState({
            mode : "expandComment",
            replyClicked : true
        })
    }

    zoomToComment = () => {
        const view = Context.getInstance().views.mapView;
        const location = this.props.commentObj.geometry;
        if (location) mapUtil.goToGeometry(view, location);

        const f = this.props.commentObj && this.props.commentObj.feature;
        const source = sourceUtil.getPeopleSource();
        const levels = Context.instance.aiim.datasets.levels;
        const zInfo = levels && levels.getZInfo(source,f);
        this._activateLevel({
          zInfo: zInfo,
          view: Context.instance.views.mapView
        });
    };
}

const mapStateToProps = (state) => {
    return {
      rdxActivePin: Rdx.getValue(state, Rdx.Keys.COMMENTS_ACTIVE_PIN)
    }
}

export default connect(mapStateToProps)(Comment);
