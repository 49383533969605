import React from 'react';
import Button from "../../../../common/components/Button/index";
import Context from '../../../../context/Context';
import Topic from '../../../../context/Topic';
import UnassignPrompt from '../UnassignPrompt';
import { ModalController } from "../../../../common/components/Modal";
import TransactionGuard from '../../../base/TransactionGuard';
import OfficePlan from '../../../base/OfficePlan';
import * as transactions from "../../../base/transaction/transactions";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import FieldNames from '../../../../aiim/datasets/FieldNames';
import { 
  canDeleteDuplicate, 
  getAttributes, 
  getDuplicateByArea, 
  getDuplicateByUnit 
} from '../MultipleAssignments/multipleAssignmentsUtil';
import { HomeOfficeId, HomeOfficeJSON } from '../../../base/AreasTable';

export default class RemoveButton extends React.Component {

  confirmDelete = (type, useType) => {
    let promptOptions
    let peopleFeatures = this.props.peopleFeatures
    let unitFeatures = this.props.unitFeatures
    const { feature, disableAssignments } = this.props;
    const attributes = getAttributes(feature)
    const tab = this.props.tab
    let unassignAreaUnits = false
    let isArea = false
    if (type === "person") {
      peopleFeatures = []
      unitFeatures = []
      const subject = this.additionalAssignmentPromptSubject()
      promptOptions = {
        subject: subject,
        from: "person",
        fromItem: this.props.feature
      };
      const personName = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      if (subject === "unit" || subject === "hotdesk" || subject === "hotel" || subject === "workspaceArea" || subject === "home") {
        promptOptions.fromName = personName;
      }
      // if (subjectItems) promptOptions.subjectItems = subjectItems
      // else promptOptions.subjectItem = subjectItem
      this.additionalAssignmentSubjectItems(promptOptions)
    } else if (type === "unit") {
      if (useType === "hotdesk" || useType === "hotel" || useType === "meeting room") {
        const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
        const areaItem = OfficePlan.getActive().areasTable.getArea(areaId)
        promptOptions = {
          subject: "unit",
          subjectItem: this.props.feature,
          from: "workspaceArea",
          fromItem: areaItem
        }
      } else {
        promptOptions = {
          subject: "people",
          subjectItems: peopleFeatures,
          from: "unit",
          fromItem: this.props.feature,
        };
      }
      promptOptions.disableAssignments = disableAssignments;
    } else if (type === "hotel" || type === "hotdesk" || type === "workspace" || type === "workspaceArea") {
      isArea = true
      const areaItem = this.props.areaItem
      const people = this.props.peopleFeatures
      const units = this.props.unitFeatures
      const subjectItems = tab === "people" ? people : units
      unassignAreaUnits = tab === "units"
      promptOptions = {
        subject: tab,
        subjectItems: subjectItems,
        from: type,
        fromItem: areaItem
      }
    }

    const i18n = Context.instance.i18n;
    const content = <UnassignPrompt peopleFeatureItems={peopleFeatures}
      unitFeatureItems={unitFeatures} isArea={isArea}
      promptOptions={promptOptions} unassignAreaUnits={unassignAreaUnits} />;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: this.props.dialogTitle || i18n.spaceplanner.unassignPrompt.removeAssignmentsTitle,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: disableAssignments ? i18n.general.ok : i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        if (type === "person") {
          // this.deletePerson(this.props.feature)
          // this.deleteDuplicatePeopleFromUnits(this.props.feature)
          this.deleteAllDuplicatePeople(this.props.feature)
        } else if (type === "unit") {
          if (useType === "hotdesk" || useType === "hotel" || useType === "meeting room") {
            this.deleteUnit(this.props.feature)
          } else {
            this.unitsCheckForDuplicatePeople(peopleFeatures)
          }
        } else if (type === "hotel" || type === "hotdesk" || type === "workspace" || type === "workspaceArea") {
          if (tab === "people") {
            // TODO Change this, this function is different now
            this.areasCheckForDuplicatePeople(peopleFeatures)
          } else if (tab === "units") {
            this.deleteUnits(unitFeatures)
          }
        }
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  onUnassignment = () => {
    if (typeof this.props.onUnassignment === "function") {
      this.props.onUnassignment();
    }
  };

  hasAdditionalAssignments = () => {
    const units = this.props.additionalAssignments.units
    const areas = this.props.additionalAssignments.areas
    return units.length > 0 || areas.length > 0
  }

  additionalAssignmentPromptSubject = () => {
    const hasUnits = this.props.additionalAssignments.units.length > 0
    const hasAreas = this.props.additionalAssignments.areas.length > 0
    const personAttributes = getAttributes(this.props.feature)
    const areaId = aiimUtil.getAttributeValue(personAttributes, FieldNames.AREA_ID)
    const unitId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_UNIT_ID)
    if ((hasUnits && hasAreas) || (hasUnits && areaId) || (hasAreas && unitId)) return "units and areas"
    else if (hasUnits && !hasAreas) return "units"
    else if (!hasUnits && hasAreas) return "areas"
    else if (areaId === HomeOfficeId) return "home"
    else if (areaId) return "workspaceArea"
    else return "unit"
  }

  personHasAssignments = (personFeature) => {
    const personAttributes = getAttributes(personFeature)
    const areaId = aiimUtil.getAttributeValue(personAttributes, FieldNames.AREA_ID)
    const unitId = aiimUtil.getAttributeValue(personAttributes, FieldNames.UNIT_ID)
    const units = this.props.additionalAssignments.units
    const areas = this.props.additionalAssignments.areas
    return areaId || unitId || units.length > 0 || areas.length > 0
  }

  additionalAssignmentSubjectItems = (promptOptions) => {
    const unitFeatures = this.props.unitFeatures
    let subjectItems = [], subjectItem = null
    const personAttributes = getAttributes(this.props.feature)
    const areaId = aiimUtil.getAttributeValue(personAttributes, FieldNames.AREA_ID)

    if (areaId) {
      if (areaId === HomeOfficeId) {
        subjectItem = { ...HomeOfficeJSON };
      } else {
        subjectItem = OfficePlan.getActive().areasTable.getArea(areaId)
      }
    } else {
      subjectItem = unitFeatures.length === 1 && unitFeatures[0]
    }

    if (this.hasAdditionalAssignments()) {
      const units = this.props.additionalAssignments.units
      const areas = this.props.additionalAssignments.areas
      units.forEach(unit => subjectItems.push(unit))
      areas.forEach(area => subjectItems.push(area))
      if (subjectItem) subjectItems.push(subjectItem)
    }

    if (subjectItems && subjectItems.length > 1) promptOptions.subjectItems = subjectItems
    else promptOptions.subjectItem = subjectItem
  }

  areasCheckForDuplicatePeople = (peopleFeatures) => {
    if (!peopleFeatures) return
    this.bulkUnassignPeople(peopleFeatures)
  }

  unitsCheckForDuplicatePeople = (peopleFeatures) => {
    if (!peopleFeatures) return
    let promises = [], duplicates = [], singleRecords = []
    peopleFeatures.forEach(person => {
      const attributes = getAttributes(person)
      const personName = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
      const promise = canDeleteDuplicate(personName, personEmail).then(result => {
        if (result) duplicates.push(person)
        else singleRecords.push(person)
      })
      promises.push(promise)
    })
    Promise.all(promises).then(() => {
      this.unassignUnit(this.props.feature, duplicates, singleRecords)
    })
  }

  unassignArea = (areaItem, areaType, duplicatePeople, singleRecords) => {
    const guard = new TransactionGuard({featureItems: [areaItem]});
    transactions
      .unassignArea(areaItem, areaType, {duplicates: duplicatePeople, singleRecords: singleRecords})
      .then(result => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      })
      .catch(error => {
        guard.close();
        console.error("Error unassigning unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  unassignUnit = (unitFeature, duplicatePeople, singleRecords) => {
    const guard = new TransactionGuard({featureItems: [unitFeature]});
    transactions
      .unassignUnits([unitFeature],
        {
          duplicates: duplicatePeople,
          singleRecords: singleRecords,
          disableAssignments: !!this.props.disableAssignments
        })
      .then(result => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      })
      .catch(error => {
        guard.close();
        console.error("Error unassigning unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  deleteAllDuplicatePeople = (personFeature) => {
    if (this.hasAdditionalAssignments()) {
      const unitPromises = this.deleteDuplicatePeopleFromUnits(personFeature)
      const areaPromises = this.deleteDuplicatePeopleFromAreas(personFeature)
      const promises = []
      unitPromises.forEach(up => promises.push(up))
      areaPromises.forEach(ap => promises.push(ap))
      Promise.all(promises).then(results => {
        if (results && results.length > 0) {
          this.unassignPeopleFeatures(results, personFeature)
        } else {
          this.unassignPersonFeature(personFeature)
        }
      }).catch(e => {
        console.error("Couldn't delete all people", e)
      })
    } else {
      this.unassignPersonFeature(personFeature)
    }
  }

  deleteDuplicatePeopleFromUnits = (personFeature) => {
    // if (this.hasAdditionalAssignments()) {
      const additionalUnits = this.props.additionalAssignments.units
      const personAttributes = getAttributes(personFeature)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const promises = []
      additionalUnits.forEach(unit => {
        const unitAttributes = getAttributes(unit)
        const unitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
        const promise = getDuplicateByUnit(personName, personEmail, unitId, {unit: unit}).then(result => {
          if (result && result.length === 1) {
            const person = result[0]
            return Promise.resolve(person)
          } else {
            throw new Error("No occupants returned")
          }
        }).catch(e => {
          console.error("Couldn't get duplicate occupant feature", e)
          return Promise.reject(e)
        })
        promises.push(promise)
      })
      return promises
      // Promise.all(promises).then(results => {
      //   if (results && results.length > 0) {
      //     deleteDuplicatePeople(results, false)
      //   }
      //   this.deletePerson(personFeature)
      // }).catch(e => {
      //   console.error("Couldn't delete all people", e)
      // })
    // } else {
    //   this.deletePerson(personFeature)
    // }
  }

  deleteDuplicatePeopleFromAreas = (personFeature) => {
    // if (this.hasAdditionalAssignments()) {
      const additionalAreas = this.props.additionalAssignments.areas
      const personAttributes = getAttributes(personFeature)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const promises = []
      additionalAreas.forEach(area => {
        const areaAttributes = getAttributes(area)
        const areaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
        const promise = getDuplicateByArea(personName, personEmail, areaId, {area: area}).then(result => {
          if (result && result.length === 1) {
            const person = result[0]
            return Promise.resolve(person)
          } else {
            throw new Error("No occupants returned")
          }
        }).catch(e => {
          console.error("Couldn't get duplicate occupant feature", e)
          return Promise.reject(e)
        })
        promises.push(promise)
      })
      return promises
    // } else {
    //   this.deletePerson(personFeature)
    // }
  }

  bulkUnassignPeople = (people) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .bulkPanelUnassignPeople(people)
      .then((result) => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  unassignPeopleFeatures = (peopleItems, singleRecord) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([singleRecord], peopleItems)
      .then((result) => {
        guard.close();
        if (this.props.type !== "person") {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
          });
        } else {
          this.onUnassignment()
        }
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  unassignPersonFeature = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([personItem])
      .then((result) => {
        guard.close();
        if (this.props.type !== "person") {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
          });
        } else {
          this.onUnassignment()
        }
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  deleteUnits = (unitItems) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignUnits(unitItems)
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          queryCriteriaKey: this.unitsQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning units", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  deleteUnit = (unitItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignUnits([unitItem],{disableAssignments: !!this.props.disableAssignments})
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          queryCriteriaKey: this.unitsQueryCriteriaKey
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning units", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  removeAll = () => {
    const type = this.props.type
    const useType = this.props.unitUseType
    this.confirmDelete(type, useType)
  }

  render() {
    const i18n = Context.instance.i18n
    let cls = "i-remove-btn"
    const type = this.props.type
    const useType = this.props.unitUseType
    const tab = this.props.tab

    if (type === "unit") {
      const people = this.props.peopleFeatures
      const disabled = (!people || people.length === 0) && useType !== "hotdesk" && useType !== "hotel" && useType !== "meeting room"
      return (
        <Button className={cls} disabled={disabled} extraSmall={!!this.props.extraSmall}
          onClick={this.removeAll}><span>{this.props.label || i18n.spaceplanner.popup.removeBtn}</span></Button>
      )
    } else if (type === "person") {
      // const units = this.props.unitFeatures
      // const disabled = !units || units.length === 0
      const disabled = !this.personHasAssignments(this.props.feature)
      return (
        <Button className={cls} disabled={disabled}
          onClick={this.removeAll}><span>{i18n.spaceplanner.popup.removeBtn}</span></Button>
      )
    } else if (type === "workspace" || type === "workspaceArea") {
      const people = this.props.peopleFeatures
      const units = this.props.unitFeatures
      let disabled
      if (tab === "people") {
        disabled = !people || people.length === 0
      } else if (tab === "units") {
        disabled = !units || units.length === 0
      }
      return (
        <Button className={cls} disabled={disabled}
          onClick={this.removeAll}><span>{i18n.spaceplanner.popup.removeBtn}</span></Button>
      )
    } else {
      return null
    }
  }
}
