import React from "react";
import Toggle from "../../../../src/components/common/Toggle/Toggle";
import Context from "../../../context/Context";
import FilterableSelect from "./FilterableSelect";


const CSS = {
    section: "i-form-group i-section",
    formGroup: "i-form-group",
    formGroupHeader: "i-form-group-header",
    formGroupName: "i-form-group-name",
    required: "i--required",
    toggleLabel: "i-toggle-label",
    optionsContainer: "i--restrict-merge-groups",
};

export default class MergePermission extends React.Component {

    constructor(props){
        super(props);
        const mergePermission = Context.instance.config.spaceplanner.mergepermission;
        const restrictUsers = mergePermission.restrictUsers;
        const context = Context.getInstance();
        const i18n = context.i18n;
        const buttonLabel = i18n.spaceplanner.configurator.showPortalGroups;
        this.state=({
            restrictUsers : restrictUsers,
            showOptions : false,
            groupNames : [{}],
            buttonLabel: buttonLabel
        })
        if(restrictUsers) {
            this.getButtonLabel();
        }
    }

    getButtonLabel =()=> {
        const mergePermission = Context.instance.config.spaceplanner.mergepermission;
        const groupIds = mergePermission.groupId;
        let buttonLabel = null;
        this.getAllGroups().then((groups)=>{
            for(var i =0;i<groups.length; i++) {
                if(groupIds === groups[i].id) {
                    buttonLabel = groups[i].title;
                    this.setState({
                        buttonLabel : buttonLabel
                    })
                }
            }
        })
        .then(()=> {
            this.onChangeMergeEnabled(true);
        })
    }

    getAllGroups=()=>{
        const orgId = Context.instance.user.portalUser.orgId;
        const portal = Context.instance.getPortal();
        var query = "orgid:" + orgId;
        var queryParams = {
            query: query,
            sortField: "title",
            sortOrder: "asc",
            num: 100
        };
        return portal.queryGroups(queryParams).then((response)=>{
            return response.results;
        })
    }

    getTagMatchGroups=()=>{
        const orgId = Context.instance.user.portalUser.orgId;
        const portal = Context.instance.getPortal();
        var query = "orgid:" + orgId;        
        var queryParams = {
          query: query,
          sortField: "title",
          sortOrder: "asc",
          num: 100
        };
        return portal.queryGroups(queryParams).then((response)=>{
            return response.results;
        })
    }

    getGroupTitle=(groupId)=>{
        const portalGroups = this.state.portalGroups;
        for(var i=0;i<portalGroups.length;i++) {
            if(portalGroups[i].id === groupId) return portalGroups[i].title;
        }
    }

    storeGroupDetails=(groups)=>{
        let groupsArray =[];
        for(var i=0;i<groups.length;i++){
            const groupTitle = groups[i].title;
            const groupId = groups[i].id;
            groupsArray.push({
                title :groupTitle,
                id : groupId
            });
        }
        this.setState({
            groupNames : groupsArray,
            showOptions: true,
            restrictUsers : true
        })
    }

    getPortalGroups=()=>{
        const portal = Context.instance.getPortal();
        portal.load().then(()=>{
            return this.getTagMatchGroups();
        })
        .then((groups)=>{
            if(groups && groups.length >0) {
                return {
                    label : "matchFound",
                    groups : groups
                }
            }
            else return {
                label : "noMatch",
                groups: []
            }
        })
        .then((obj)=>{
            if(obj.label === "noMatch") return this.getAllGroups();
            else return obj.groups;
        })
        .then((groups)=> {
            this.storeGroupDetails(groups);
        })
        .catch((error)=>{ console.log(error); })
    }

    storeGroupIds=(groupId, groupTitle)=>{
        const cfg = this.props.cfg;
        cfg.getConfigurable("spaceplanner").mergepermission.restrictUsers = true; 
        cfg.getConfigurable("spaceplanner").mergepermission.groupId = groupId; 
        cfg.getConfigurable("spaceplanner").mergepermission.groupTitle = groupTitle; 
        this.getButtonLabel();
    }

    onChangeMergeEnabled=(checked)=>{
        const cfg = this.props.cfg;
        const context = Context.getInstance();
        const i18n = context.i18n;
        if(checked){
            this.getPortalGroups();
        }else{
            cfg.getConfigurable("spaceplanner").mergepermission.restrictUsers = false; 
            cfg.getConfigurable("spaceplanner").mergepermission.groupId = null; 
            this.setState({
                showOptions: false,
                restrictMerge : false, 
                buttonLabel: i18n.spaceplanner.configurator.showPortalGroups
            })
        }
    }

    render(){
        const showOptions = this.state.showOptions;
        const restrictUsers = this.state.restrictUsers;
        const context = Context.getInstance();
        const i18n = context.i18n;
        return (
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName+" "+CSS.required}>{i18n.spaceplanner.configurator.restrictMergeCaption}</span>
                </div>
                <div key="merge" className={CSS.formGroup}>
                <div className="restrict-merge">
                    <label id={"cfg-merge-enabled-label"} htmlFor={"cfg-merge-enabled"}>
                        <div className={CSS.toggleLabel}>{i18n.spaceplanner.configurator.restrictMerge}</div>
                        <Toggle id={"cfg-merge-enabled"} 
                        onChange={this.onChangeMergeEnabled} checked={restrictUsers}/>
                    </label>
                </div>
                <div className={CSS.optionsContainer}>
                    {showOptions ?  
                        <FilterableSelect
                            caption={this.state.buttonLabel}
                            portalGroups={this.state.groupNames}
                            storeGroupIds={this.storeGroupIds} 
                            cfg={this.props.cfg}
                        /> 
                        : null }
                </div>
                </div>
                </div>
        );
    }
}