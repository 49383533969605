import Context from "../../../context/Context";
import QueryAll from "../../base/QueryAll";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as queryUtil from "../../base/queryUtil";

export function collectUniqueValues(features,field,fieldName) {
  const i18n = Context.instance.i18n;
  const values = [];
  if (features) {
    features.forEach(feature => {
      const attributes = feature && feature.attributes;
      const v = aiimUtil.getAttributeValue(attributes,fieldName);
      // Should we allow nulls in the list?
      if (v !== undefined && v !== null) {
        if (values.indexOf(v) === -1) values.push(v);
      }
    })
  }
  values.sort();
  return values.map(value => {
    let name = value;
    if (value === null || (typeof value === "string" && value.length === 0)) {
      name = i18n.filter.noValueLabel;
      value = "__novalue";
    } 
    return {
      name: name,
      value: value
    }
  });
}

export function getField(fields,fieldName) {
  let found;
  if (fields && fields.length > 0 && fieldName) {
    const lc = fieldName.toLowerCase();
    fields.some(field => {
      if (lc === field.name.toLowerCase()) found = field;
      return !!found;
    });
  }
  return found;
}

export function getFilterFields(source) {
  const list = [];
  const layer = source && source.layer2D;
  const fields = layer && layer.fields;
  if (fields && fields.length > 0) {
    /*
    "small-integer"|"integer"|"single"|"double"|"long"|"string"|"date"|"oid"|
    "geometry"|"blob"|"raster"|"guid"|"global-id"|"xml"
     */
    const types = ["string"];
    //types.push("small-integer","integer","long"); // TODO too many distinct values?
    //types.push("single","double"); // TODO too many distinct values?
    fields.forEach(field => {
      //console.log("getFilterFields",field.name,field.alias,field);
      if (field.domain && field.domain.type === "coded-value") {
        list.push(field);
      } else if (types.indexOf(field.type) !== -1) {
        list.push(field);
      }
    })
    list.sort((a,b) => {
      let s1 = a.alias || a.name;
      let s2 = b.alias || b.name;
      return s1.localeCompare(s2);
    });
  }
  return list;
}

export function queryLevelIds(queryCriteria,where) {
  const promise = new Promise((resolve,reject) => {
    const source = queryCriteria.getSource();
    const layer = source && source.layer2D;
    const field = Context.instance.aiim.getLevelIdField(source);
    const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = [field.name];
    query.returnGeometry = false;
    query.where = where || "1=1";
    if (queryCriteria.objectIds) {
      query.objectIds = queryCriteria.objectIds;
    }

    const levelsDataset = Context.instance.aiim.datasets.levels;
    const sids = [], sidx = {};
    const fids = [], fidx = {};
    const lids = [], lidx = {};
    const qaopts = {
      layer: layer,
      perFeatureCallback: f => {
        const v = f.attributes[field.name];
        if (v && !lidx[v]) {
          lidx[v] = 1;
          lids.push(v);
          const ld = levelsDataset.getLevelData(v);
          if (ld) {
            const v2 = ld.facilityId;
            const v3 = ld.siteId;
            if (v2 && !fidx[v2]) {
              fidx[v2] = 1;
              fids.push(v2);
            }
            if (v3 && !sidx[v3]) {
              sidx[v3] = 1;
              sids.push(v3);
            }
          }
        }
      }
    };

    const qa = new QueryAll();
    qa.execute(url,query,qaopts).then(()=> {
      // console.log("qa.where",query.where)
      // console.log("qa.lids",lids.length,lids)
      // console.log("qa.fids",fids.length,fids)
      // console.log("qa.sids",sids.length,sids)
      Topic.publish(Topic.FilteredLevelIds,{
        queryCriteriaKey: queryCriteria.key,
        filteredLevelInfo: {
          siteIds: sids,
          siteIdx: sidx,
          facilityIds: fids,
          facilityIdx: fidx,
          levelIds: lids,
          levelIdx: lidx
        }
      })
    }).catch(ex => {
      console.error(ex);
      reject(ex)
    });
  });
  return promise;
}

export function queryAllUniqueValues(options) {
  const promise = new Promise((resolve,reject) => {
    let {source,field,fieldName,where} = options;
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(source.url);
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    // query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.returnZ = false;
    query.outFields = [fieldName];
    query.where = where || "1=1";
    if (options.objectIds) {
      query.objectIds = options.objectIds;
    }
    queryUtil.applyGdbVersion(source,query);
    const qa = new QueryAll();
    qa.execute(url,query,{layer:options.layer}).then(result => {
      const features = result && result.features;
      const features2  = [];
      const result2 = {};
      if(features) {
        let idx = [];
        features.forEach(feature => {
          const attributes = feature && feature.attributes;
          const v = aiimUtil.getAttributeValue(attributes,fieldName);
          // Should we allow nulls in the list?
          if (v !== undefined && v !== null) {
            if(!idx.hasOwnProperty(v)) {
              idx[v] = true;
              features2.push(feature);
            }
          }
        })
        if(features2.length > 0) result2.features = features2;
      }
      return result2;
    }).then(result=> {
      //console.log("queryUniqueValues",result);
      const features = result && result.features;
      //console.log("queryUniqueValues",fieldName,features && features.length);
      const values = collectUniqueValues(features,field,fieldName);
      if (field && field.domain && field.domain.type === "coded-value") {
        //console.log("field.domain",field.domain);
        if (Array.isArray(field.domain.codedValues)) {
          const namesByCode = {};
          field.domain.codedValues.forEach(cv => {
            namesByCode[cv.code] = cv.name;
          });
          values.forEach(info => {
            let name = namesByCode[info.value];
            if (name !== null && name !== undefined) info.name = name;
          });
          values.sort((a,b) => {
            if (typeof a.name === "string" && typeof (b.name === "string")) {
              return (a.name.localeCompare(b.name));
            }
            return 0;
          });
        }
      }
      resolve(values);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;

}

export function queryUniqueValues(options) {
  const promise = new Promise((resolve,reject) => {
    let {source,field,fieldName,where} = options;
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(source.url);
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.returnZ = false;
    query.outFields = [fieldName];
    query.where = where || "1=1";
    if (options.objectIds) {
      query.objectIds = options.objectIds;
    }
    queryUtil.applyGdbVersion(source,query);
    task.execute(query).then(result => {
      //console.log("queryUniqueValues",result);
      const features = result && result.features;
      //console.log("queryUniqueValues",fieldName,features && features.length);
      const values = collectUniqueValues(features,field,fieldName);
      if (field && field.domain && field.domain.type === "coded-value") {
        //console.log("field.domain",field.domain);
        if (Array.isArray(field.domain.codedValues)) {
          const namesByCode = {};
          field.domain.codedValues.forEach(cv => {
            namesByCode[cv.code] = cv.name;
          });
          values.forEach(info => {
            let name = namesByCode[info.value];
            if (name !== null && name !== undefined) info.name = name;
          });
          values.sort((a,b) => {
            if (typeof a.name === "string" && typeof (b.name === "string")) {
              return (a.name.localeCompare(b.name));
            }
            return 0;
          });
        }
      }
      resolve(values);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;

}
