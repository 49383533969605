import React from "react";
import {connect} from "react-redux";

import Context from "../../../../../context/Context";
import * as component from "../../../../util/component";
import * as bookWorkspaceUtil from "../BookWorkspace/BookWorkspaceUtil";
import Topic from "../../../../../context/Topic";
import Rdx from "../../../../../redux/Rdx";
import Icons from "../../../../util/Icons";
import DropdownButton from "../../../../common/Dropdown/DropdownButton";
import FilterOption from "./FilterOption";

const CSS = {
  main: "i-events-datefilter",
  optionsContainer: "i-events-options-container",
  options: "i-events-options",
};

class SpaceTypeDropdown extends React.Component {
  constructor(props) {
    super(props)
    const { initialFilter } = props;
    this.state = component.newState({
      selectedValues: initialFilter ? [] : this.props.rdxSpaceFilters,
      anySelected: initialFilter ? true : this.props.rdxSpaceFilters.length === 0
    })
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.SpaceFiltersSubmit, params => {
        Rdx.setValue(this, Rdx.Keys.SPACETYPE_FILTERS, this.state.selectedValues)
      }),
      Topic.subscribe(Topic.FilterSiteChange, params => {
        bookWorkspaceUtil.getSpaceTypesBySiteArea(this.props.rdxSite, this.props.rdxAreas)
        .then((result)=> {
          if(result && result.spaceTypeDropdown && result.spaceTypeDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, result.spaceTypeDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
          console.error(error);
        })
      }),
      Topic.subscribe(Topic.FilterAreaChange, ()=> {
        bookWorkspaceUtil.getSpaceTypesBySiteArea(this.props.rdxSite, this.props.rdxAreas)
        .then((result)=> {
          if(result && result.spaceTypeDropdown && result.spaceTypeDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, result.spaceTypeDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, []);
          }
          this.setState({
            selectedValues: [],
            anySelected: true
          })
        })
        .catch((error)=> {
            console.error(error);
        })
    }),
      Topic.subscribe(Topic.BookFiltersCleared, params => {
        bookWorkspaceUtil.getSpaceTypesBySiteArea(null, [])
        .then((result)=> {
          if(result && result.spaceTypeDropdown && result.spaceTypeDropdown.length > 0) {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, result.spaceTypeDropdown);
          } else {
            Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, []);
          }
        })
        this.setState({
          selectedValues: [],
          anySelected: true
        })
      })
    ])
  }

  onChange = (values, items) => {
    this.setState({
      selectedValues: values
    })
  }

  onClick = (option) => {
    if (option.props.checked) {
      let values = this.state.selectedValues
      const index = values.indexOf(option.props.value)
      if (index > -1) {
        values.splice(index, 1)
      }
      const anySelected = values.length === 0
      this.setState({
        selectedValues: values,
        anySelected: anySelected
      }, ()=> {Rdx.setValue(this, Rdx.Keys.SPACETYPE_FILTERS, this.state.selectedValues)})
    } else {
      let values = this.state.selectedValues
      values.push(option.props.value)
      this.setState({
        selectedValues: values,
        anySelected: false
      }, ()=> {Rdx.setValue(this, Rdx.Keys.SPACETYPE_FILTERS, this.state.selectedValues)})
    }
    setTimeout(()=> { 
      Topic.publish(Topic.FilterSpaceTypeChange, {})
    }, 1);
  }

  onAnyClicked = (option) => {
    if (option.props.checked) {
      if (this.state.selectedValues.length !== 0) {
        this.setState({
          anySelected: false
        }, ()=> {Rdx.setValue(this, Rdx.Keys.SPACETYPE_FILTERS, this.state.selectedValues)})
      }
    } else {
      this.setState({
        selectedValues: [],
        anySelected: true
      }, ()=> {Rdx.setValue(this, Rdx.Keys.SPACETYPE_FILTERS, this.state.selectedValues)})
    }
    setTimeout(()=> { 
      Topic.publish(Topic.FilterSpaceTypeChange, {})
    }, 1);
  }

  render() {
    const spaceTypeDropdown = this.props.rdxSpaceTypeDropdown;
    const options = spaceTypeDropdown.map(item => {
      let checked = false
      if (this.state.selectedValues.includes(item)) checked = true
      return (
        <FilterOption key={item} label={item} value={item} checked={checked} onClick={this.onClick} />
      )
    })
    const i18n = Context.instance.i18n
    const any = i18n.more.bookWorkspace.any
    options.unshift(
      <FilterOption key={"any"} label={any} value={any} checked={this.state.anySelected} onClick={this.onAnyClicked} />
    )

    let icon = Icons.doubleVertical();

    let caption = ""
    for (var i = 0; i < this.state.selectedValues.length; i++) {
      if (i < this.state.selectedValues.length - 1) {
        caption += this.state.selectedValues[i] + ", "
      } else {
        caption += this.state.selectedValues[i]
      }
    }
    if (caption === "") {
      caption = any
    }
    if (caption.length > 26) {
      caption = caption.substring(0, 26)
      let len = caption.length
      if (caption[len - 1] === ' ' || caption[len - 1] === ',') {
        caption = caption.replace(/.$/, '')
      }
      caption += "..."
      
    }

    const buttonContent = (
      <div role="button" aria-label={i18n.more.bookWorkspace.spaceListAriaLabel}>
        <span key="caption">{caption}</span>
        <span key="icon">{icon}</span>
      </div>
    );
    const dropdownContent = (
      <ul role="menu">{options}</ul>
    );
    return (
      <div className={CSS.optionsContainer}>
        <div className={CSS.options}>
          <div className={CSS.main}>
            <DropdownButton
              closeOnSelect={false}
              focusFirst={true}
              buttonClassName={"i-choice-dd"}
              buttonContent={buttonContent}
              dropdownContent={dropdownContent}
              disabled={false}
              placement={"bottom-start"}>
            </DropdownButton>
          </div>
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rdxSite: Rdx.getValue(state, Rdx.Keys.SITE_FILTERS),
    rdxAreas: Rdx.getValue(state, Rdx.Keys.AREA_FILTERS),
    rdxSpaceFilters: Rdx.getValue(state, Rdx.Keys.SPACETYPE_FILTERS),
    rdxSpaceTypeDropdown: Rdx.getValue(state, Rdx.Keys.SPACETYPES_DROPDOWN)
  }
}

export default connect(mapStateToProps)(SpaceTypeDropdown);