import React from "react";
import Context from "../../../context/Context";
import GroupSearch from "../../../spaceplanner/miniapps/common/components/GroupSearch";
import { IGroup } from "@esri/arcgis-rest-types";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteSegmentedControl,
  CalciteSegmentedControlItem,
  CalciteLabel,
  CalciteIcon,
  CalciteTooltip
} from "@esri/calcite-components-react";

/** Represents the types of access when sharing items.
 * [Read more...](https://developers.arcgis.com/rest/users-groups-and-items/share-item-as-item-owner-.htm#GUID-D145D42B-F2C6-400A-B61B-A7736A00C773).
 */
export enum SharingLevels {
  ORGANIZATION = "org",
  PRIVATE = "private",
  PUBLIC = "public",
  SHARED = "shared"
}
export interface IShareWithProps {
  assignedGroups: IGroup[],
  disabled?: boolean,
  disallowEveryone?: boolean,
  groups: IGroup[],
  hideShareWithHelp?: boolean,
  horizontal?: boolean,
  onChange: (shareWith: SharingLevels, shareIds: string[], unShareIds: string[]) => void,
  organization?: boolean,
  private?: boolean,
  public?: boolean,
  shareWith?: SharingLevels,
}
interface ISharedWithState {
  assignedGroups: IGroup[],
  shareWith: SharingLevels
}
export default class ShareWith extends React.Component<IShareWithProps, ISharedWithState> {

  static defaultProps = {
    disabled: false,
    disallowEveryone: true,
    horizontal: true,
    shareWith: SharingLevels.PRIVATE
  }

  constructor(props: IShareWithProps) {
    super(props);

    let shareWith = this.props.shareWith || SharingLevels.PRIVATE;
    if (this.props.private) {
      shareWith = SharingLevels.PRIVATE;
    } else if (this.props.organization) {
      shareWith = SharingLevels.ORGANIZATION;
    }
    this.state = {
      shareWith: shareWith,
      assignedGroups: this.props.assignedGroups
    }
  }

  _onChange(shareWith: SharingLevels, shareIds?: string[], unShareIds?: string[]) {
    const state = {
      shareWith,
      ...shareIds && unShareIds && {
        assignedGroups: this.props.groups
          .filter(g => shareIds.includes(g.id))
          .filter(g => !unShareIds.includes(g.id))
      }
    };
    this.setState(state);
    if (this.props.onChange) {
      this.props.onChange(shareWith, shareIds, unShareIds);
    }
  }
  private renderLabel() {
    const { i18n } = Context.getInstance();   
    return (
      <span className="i-share-with-group-label">
        {i18n.configurator.appItem.shareWith.caption}
        {!this.props.hideShareWithHelp &&
          <>
            <CalciteIcon icon="information" scale="s" id="share-with-info-icon" />
            <CalciteTooltip placement="top-start" referenceElement="share-with-info-icon"
              label={i18n.switcher.newPlan.warningPrivatePlan}>
              {i18n.switcher.newPlan.warningPrivatePlan}
            </CalciteTooltip>
          </>
        }
      </span>
    );
  }
  _renderGroups() {
    const { assignedGroups, shareWith } = this.state;
    const { groups, horizontal, disabled } = this.props;
    if (!groups) return null;

    if (shareWith === SharingLevels.ORGANIZATION) {
      return (
        <GroupSearch
          onShareGroup={() => { }}
          onUnshareGroup={() => { }}
          groups={[]}
          assignedGroups={assignedGroups}
          horizontal={horizontal}
          disabled={true} />
      );
    }

    return (
      <GroupSearch
        onShareGroup={(shareIds, unShareIds) =>
          this._onChange(SharingLevels.SHARED, shareIds, unShareIds)}
        onUnshareGroup={(unShareIds, shareIds) =>
          this._onChange(shareWith, shareIds, unShareIds)}
        groups={groups}
        assignedGroups={assignedGroups}
        horizontal={horizontal}
        disabled={disabled} />
    );
  }
  render() {
    const { shareWith } = this.state;
    const { horizontal, disallowEveryone } = this.props;
    const ctx = Context.getInstance();
    const i18n = ctx.i18n;
    const isVersioned = ctx.spaceplanner.planner.project && ctx.spaceplanner.planner.project.isVersioned
      ? ctx.spaceplanner.planner.project.isVersioned
      : false;
    const disabled = this.props.disabled || null;
    const privateProps = {
      ...(this.props.private || shareWith === SharingLevels.PRIVATE ||
        shareWith === SharingLevels.SHARED) && { checked: true }
    };
    const orgProps = {
      ...(this.props.organization || shareWith === SharingLevels.ORGANIZATION) && { checked: true }
    };
    const publicProps = {
      ...(this.props.public || shareWith === SharingLevels.PUBLIC) && { checked: true }
    };
    return (
      <>
        <CalciteLabel scale="l" className={horizontal ? "i-new-plan i--form-label" : ""}>
          {this.renderLabel()}
        </CalciteLabel>
        <CalciteSegmentedControl disabled={disabled} 
          onCalciteSegmentedControlChange={(e) => {
            this._onChange(e.target.value as SharingLevels)
          }}
        >
          <CalciteSegmentedControlItem value={SharingLevels.PRIVATE} {...privateProps}>
            {i18n.configurator.appItem.shareWith.private}
          </CalciteSegmentedControlItem>
          <CalciteSegmentedControlItem value={SharingLevels.ORGANIZATION} {...orgProps}>
            {i18n.configurator.appItem.shareWith.organization}
          </CalciteSegmentedControlItem>
          {!disallowEveryone && !isVersioned && (
            <CalciteSegmentedControlItem value={SharingLevels.PUBLIC} {...publicProps}>
              {i18n.configurator.appItem.shareWith.everyone}
            </CalciteSegmentedControlItem>
          )}
        </CalciteSegmentedControl>

        {this._renderGroups()}
      </>
    );
  }
}
