import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { getWallWidthUnits, setWallWidthUnits, IEditorState} from "../redux";
import * as component from "../../../../components/util/component";
import Warning from "../../../miniapps/configurator/Warning";

import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import {
  CalciteInputNumber,
  CalciteSegmentedControl,
  CalciteSegmentedControlItem
} from "@esri/calcite-components-react";

interface Props {
  min?: number,
  onChange: (widthInMapUnits: number) => void,
  setWallWidthUnits: (units: IEditorState["EDITOR_WALL_WIDTH_UNITS"]) => void,
  wallWidthUnits: IEditorState["EDITOR_WALL_WIDTH_UNITS"],
}

interface State {
  wallWidth: number
}

class WallThickness extends React.Component<Props, State> {

  defaultMM = 120;
  defaultInches = 8;

  constructor(props: Props) {
    super(props);
    let wallWidth = this.defaultInches;
    let wallWidthUnits: Props["wallWidthUnits"] = "inches";
    if (this.props.wallWidthUnits === "millimeters") {
      wallWidth = this.defaultMM;
      wallWidthUnits = this.props.wallWidthUnits;
    }
    this.state = component.newState({
      wallWidth
    });
    const widthInMapUnits = this.getWidthInMapUnits(wallWidth,wallWidthUnits);
    this.props.onChange(widthInMapUnits);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getWidthInMapUnits = (v: number, units: Props["wallWidthUnits"]) => {
    const { views } = Context.instance;

    switch (views.mapView.spatialReference.unit) {
      case "meters":
        if (units === "inches") {
          v = v * 0.0254;
        } else if (units === "millimeters") {
          v = v * 0.001;
        }
        break;
      case "us-feet":
        if (units === "inches") {
          v = v * 0.0833333;
        } else if (units === "millimeters") { 
          const m = v * 0.001;
          v = m * 3.28084;
        } 
        break;
      default:
        // TODO: support for other measurement units
        console.error(`Unsupported map unit: ${views.mapView.spatialReference.unit}`);
        break;
    }   
    return v;
  }

  render() {
    const i18n = Context.instance.i18n;
    const { wallWidth } = this.state;
    const wallWidthUnits = this.props.wallWidthUnits;
    const checkedProps: any = {
      inches: {},
      millimeters: {},
    }
    if (wallWidthUnits === "inches") {
      checkedProps.inches.checked = true;
    } else {
      checkedProps.millimeters.checked = true;
    }

    let min = 0, step = 0.1;
    if (typeof this.props.min === "number") min = this.props.min;
    const inputProps = {
      min,
      step
    }
    let valErrMsg = i18n.editor.merge.invalidInput;
    valErrMsg = valErrMsg.replace("{value}", min);

    return (
      <>
        <div style={{marginTop: "0.5rem", marginBottom: "0.25rem", whiteSpace: "nowrap"}}>
          {i18n.editor.walls.wallThickness}
        </div>
        <div style={{display: "flex"}}>
          <CalciteInputNumber 
            style={{width: "8rem", marginInlineEnd: "0.25rem"}}
            value={""+wallWidth}
            status={!!(typeof wallWidth === "number" && wallWidth >= min) ? "valid" : "invalid"}
            {...inputProps}
            onCalciteInputNumberInput={e => {
              let v = e.target.value;
              //@ts-ignore
              v = (v.trim().length > 0) ? Number(v.trim()) : null;
              if (typeof v === "number") {
                this.setState({
                  wallWidth: v
                })
                const widthInMapUnits = this.getWidthInMapUnits(v,wallWidthUnits);
                this.props.onChange(widthInMapUnits);
              }
            }}
          />
          <CalciteSegmentedControl 
            onCalciteSegmentedControlChange={(e) => {
              const v = e.target.value;
              let w = wallWidth;
              let stateParams;
              let u: IEditorState["EDITOR_WALL_WIDTH_UNITS"];
               if (v === "inches") {
                u = "inches";
                if (wallWidth === this.defaultMM) {
                  stateParams = {
                    wallWidth: this.defaultInches
                  }
                  w = stateParams.wallWidth;
                } 
              } else {
                u = "millimeters";
                if (wallWidth === this.defaultInches) {
                  stateParams = {
                    wallWidth: this.defaultMM
                  }
                  w = stateParams.wallWidth;
                }
              }
              const widthInMapUnits = this.getWidthInMapUnits(w,u);
              if (stateParams) this.setState(stateParams);
              this.props.setWallWidthUnits(u);
              this.props.onChange(widthInMapUnits);
            }}
          >
            <CalciteSegmentedControlItem key="inches" value="inches" {...checkedProps["inches"]}>
              {i18n.editor.walls.inches}
            </CalciteSegmentedControlItem>
            <CalciteSegmentedControlItem key="millimeters" value="millimeters" {...checkedProps["millimeters"]}>
              {i18n.editor.walls.millimeters}
            </CalciteSegmentedControlItem>
          </CalciteSegmentedControl>
        </div>
        {(typeof wallWidth === "number" && (wallWidth < min)) && 
          <div style={{paddingTop: "0.4rem"}}>
            <Warning message={valErrMsg} />
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  wallWidthUnits: getWallWidthUnits(state)
});

const mapDispatchToProps = (dispatch) => ({
  setWallWidthUnits: (units: IEditorState["EDITOR_WALL_WIDTH_UNITS"]) =>
    dispatch(setWallWidthUnits(units))
});

export default connect(mapStateToProps,mapDispatchToProps)(WallThickness);