import React from "react";
import { connect } from "react-redux";
// using `import type...` is not required for types/interfaces
import { IRootState } from "../../../redux/Rdx";
import { Dispatch } from "redux";

import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import MapSelection, { IMapSelection } from "./support/MapSelection";
import GridPanel from "./grid/GridPanel";
import CopyPastePanel from "./copypaste/CopyPastePanel";
import MergePlanPanel from "../../miniapps/Switcher/MergePlan/MergePlanPanel";
import PlaceDetailsPanel from "./place/PlaceDetailsPanel";
import SidebarButton from "./SidebarButton";
import SidebarPanel from "./SidebarPanel";
import EditorPanel from "./support/EditorPanel";
import SplitPanel from "./split/SplitPanel";
import WallsPanel from "./walls/WallsPanel";
import MergePanel from "./merge/MergePanel";
import FootprintPanel from "./footprints/FootprintPanel";
import DeletePanel from "./delete/DeletePanel";
import * as component from "../../../components/util/component";
import {
  getActiveTab,
  setActiveTab,
  getActiveFeatureType,
  IEditorState,
  HitTest,
  setHitTestResults,
  setSelectedFeatureIndex,
  EditorTabs
} from "./redux";
import { setMiniAppSwitcherActive, getMiniappsSidebarCollapsed, setMiniappsSidebarCollapsed } from "../../redux";
import { goToPointCenter } from "./support/editorUtil";

import AppLauncherIcon from "calcite-ui-icons-react/AppLauncherIcon";
import HamburgerIcon from "calcite-ui-icons-react/HamburgerIcon";
import UnitsIcon from "calcite-ui-icons-react/AddInIcon";
import DrawUnitIcon from "calcite-ui-icons-react/AddInEditIcon";
import LineStraightIcon from "calcite-ui-icons-react/LineStraightIcon";
import WallsIcon from "calcite-ui-icons-react/LineStraightIcon";
import WindowsIcon from "calcite-ui-icons-react/ContentFullIcon";
import FurnitureIcon from "calcite-ui-icons-react/DeskIcon";
import TransitionsIcon from "calcite-ui-icons-react/StairsIcon";
import MergePlanIcon from "calcite-ui-icons-react/MergeIcon";
import MediaLayerIcon from "calcite-ui-icons-react/ImageIcon";
import LayersIcon from "calcite-ui-icons-react/LayersIcon";
import LegendIcon from "calcite-ui-icons-react/LegendIcon";
import CollapseIcon from "calcite-ui-icons-react/ChevronsLeftIcon";
import ExpandIcon from "calcite-ui-icons-react/ChevronsRightIcon";
import CopyPasteIcon from "calcite-ui-icons-react/PasteIcon";
import TrashIcon from "calcite-ui-icons-react/TrashIcon";
import UnitsPanel from "./units/UnitsPanel";
import { ReactComponent as MergeDetailsIcon } from "../../../assets/merge-details-icon.svg";
import { ReactComponent as MergeUnitsIcon } from "../../../assets/merge-units-icon.svg";
import { ReactComponent as SplitIcon } from "../../../assets/split-unit-icon.svg";
import { ReactComponent as EntrywaysIcon } from "../../../assets/entryway-icon.svg";

import "@esri/calcite-components/dist/components/calcite-icon";
import {
  CalciteIcon
} from "@esri/calcite-components-react";
import MediaPanel from "./media/MediaPanel";
import * as mediaUtil from "./media/mediaUtil";
import { LayerType } from "../../../util/interfaces";
import { getCapitalizedType } from "./support/formUtil";

import { FpeType } from "./support/StencilLoader";

interface ISidebarProps {
  activeTab: IEditorState["EDITOR_ACTIVE_SIDEBAR_TAB"],
  collapsed: boolean,
  loading?: boolean,
  selectedFeatureKey: LayerType,
  setActiveTab: (tab: string) => void,
  setMiniappsSidebarCollapsed: (collapsed: boolean) => void,
  setSwitcherActive: (active: boolean) => void,
  setHitTestResults: (results: HitTest[]) => void,
  setSelectedFeatureIndex: (index: number) => void
}
interface ISidebarState {
  viewLoaded: boolean
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props?: ISidebarProps) {
    super(props);
    this.state = component.newState({
      mapToolsEnabled: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.ReloadViews, () => this.setState({ viewLoaded: false })),
      Topic.subscribe(Topic.ViewActivated, () => this.setState({ viewLoaded: true }))
    ]);
  }

  componentDidUpdate(prevProps: ISidebarProps) {
    if (prevProps.activeTab === "editor" && this.props.activeTab == null) {
      this.props.setHitTestResults([]);
      this.props.setSelectedFeatureIndex(null);
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }
  private getIcon(iconSize: number) {
    switch (this.props.selectedFeatureKey) {
      case "detail":
        return <LineStraightIcon size={iconSize} />;
      case "unit":
        return <UnitsIcon size={iconSize} />;
      case "facility":
        return <CalciteIcon icon="organization" scale="s" />;
      case "level":
        return <CalciteIcon icon="reorder-cards" scale="s" />;
      case "site":
        return <CalciteIcon icon="map" scale="s" />;
    }
  }
  onMapSelection = (result: Parameters<IMapSelection["onSelection"]>[0]) => {
    if (result.features.length) {
      if (this.props.activeTab !== "editor") {
        const { lib, views } = Context.getInstance();
        const view = views.activeView;
        const reactiveUtils: __esri.reactiveUtils = lib.esri.reactiveUtils;
        const handle = reactiveUtils.watch(
          () => [view.stationary, view.extent],
          ([stationary, extent], [wasStationary]) => {
            handle.remove();
            if (this.props.activeTab === "editor" && "mapPoint" in result.event) {
              if (!view.extent.contains(result.event.mapPoint)) {
                goToPointCenter(result.event.mapPoint);
              }
            }
          }
        );
      }
      this.props.setActiveTab("editor");
    } else {
      this.props.setActiveTab(null);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { activeTab, collapsed } = this.props;
    const { viewLoaded } = this.state;
    const expanded = !collapsed;
    const iconSize = 16;

    const icon = this.getIcon(iconSize);
    const title = <span>{getCapitalizedType(this.props.selectedFeatureKey)}</span>;

    const canEditMediaLayers = Context.instance.user.canEditMediaLayers();
    const hasMediaItems = mediaUtil.hasMediaItems();

    return (
      <div className="i-editor-sidebar">
        <div className={"i-editor-sidebar-menu" + (this.props.loading ? " i--loading" : "")}>
          <div>
            <SidebarButton 
              expanded={expanded}
              tab="app-switcher"
              label={<span className="smallcaps">{i18n.editor.floorPlans}</span>} 
              icon={<HamburgerIcon size={iconSize} />}
              className="i-miniapps-switcher-btn"
              onClick={() => this.props.setSwitcherActive(true)}
            />
            <SidebarButton
              expanded={expanded}
              tab="sites"
              label={i18n.editor.sidebar.sites}
              icon={<CalciteIcon icon="map" scale="s"/>}
            />
            <SidebarButton
              expanded={expanded}
              tab="facilities"
              label={i18n.editor.sidebar.facilities}
              icon={<CalciteIcon icon="organization" scale="s"/>}
            />
            <SidebarButton 
              expanded={expanded}
              tab="levels"
              label={i18n.editor.sidebar.levels} 
              icon={<CalciteIcon icon="reorder-cards" scale="s"/>} 
            />   
            <div className="separator"></div>
            <SidebarButton 
              expanded={expanded}
              tab="units"
              label={i18n.editor.sidebar.drawUnits} 
              icon={<DrawUnitIcon size={iconSize} />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton 
              expanded={expanded}
              tab="walls"
              label={i18n.editor.sidebar.drawWalls} 
              icon={<WallsIcon size={iconSize} />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton 
              expanded={expanded}
              tab="entryways"
              label={i18n.editor.sidebar.entryways} 
              icon={<EntrywaysIcon />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton
              expanded={expanded}
              tab="windows"
              label={i18n.editor.sidebar.windows} 
              icon={<WindowsIcon size={iconSize} />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton
              expanded={expanded}
              tab="furniture"
              label={i18n.editor.sidebar.furniture} 
              icon={<FurnitureIcon size={iconSize} />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton
              expanded={expanded}
              tab="transitions"
              label={i18n.editor.sidebar.transitions} 
              icon={<TransitionsIcon size={iconSize} />} 
            />
            <div className="separator"></div>
            <SidebarButton 
              expanded={expanded}
              tab="splitUnit"
              label={i18n.editor.sidebar.splitUnits} 
              icon={<SplitIcon />} 
              onClick={(e) => {
              }}
            />
            <SidebarButton 
              expanded={expanded}
              tab="mergeUnits"
              label={i18n.editor.sidebar.mergeUnits}
              icon={<MergeUnitsIcon />}
              onClick={(e) => {
              }}
            />
            <SidebarButton 
              expanded={expanded}
              tab="mergeDetails"
              label={i18n.editor.sidebar.mergeDetails}
              icon={<MergeDetailsIcon />}
              onClick={(e) => {
              }}
            />
            <SidebarButton 
              expanded={expanded}
              tab="copypaste"
              label={i18n.editor.sidebar.copypaste}
              icon={<CopyPasteIcon size={iconSize} />}
            />
            <SidebarButton
              expanded={expanded}
              tab="delete"
              label={i18n.editor.sidebar._delete}
              icon={<TrashIcon size={iconSize} />}
            />
            <div className="separator"></div>
            {/*
            <SidebarButton 
              expanded={expanded}
              tab="validate"
              label={i18n.editor.sidebar.validate} 
              icon={<ValidateIcon size={iconSize} />}
              onClick={(e) => {
              }}
            />
            */}
            <SidebarButton 
              expanded={expanded}
              tab="mergePlan"
              label={i18n.editor.sidebar.managePlan}  
              icon={<MergePlanIcon size={iconSize} />} 
              onClick={(e) => {
              }}
            />
            {(canEditMediaLayers || hasMediaItems) && 
              <SidebarButton 
                expanded={expanded}
                tab="media"
                icon={<CalciteIcon scale="s" icon="media-layer"/>}
                label={i18n.editor.sidebar.images}
              />  
            }
            <SidebarButton 
              expanded={expanded}
              tab="grid"
              icon={<CalciteIcon scale="s" icon="grid-unit"/>}
              label={i18n.editor.sidebar.grid}
            /> 
          </div>
          <div>
            {/*
            <SidebarButton 
              expanded={expanded}
              tab="layers"
              label={i18n.editor.sidebar.layers} 
              icon={<LayersIcon size={iconSize} />}
              onClick={(e) => {
              }}
            />
            <SidebarButton
              expanded={expanded}
              tab="legend"
              label={i18n.editor.sidebar.legend} 
              icon={<LegendIcon size={iconSize} />}
              onClick={(e) => {
              }}
            />
            */}
            {expanded &&
              <SidebarButton 
                expanded={expanded}
                label={i18n.editor.sidebar.collapse} 
                icon={<CollapseIcon size={iconSize} />}
                onClick={(e) => {
                  this.props.setMiniappsSidebarCollapsed(true);
                }}
              />
            }
            {!expanded &&
              <SidebarButton 
                expanded={expanded}
                label={i18n.editor.sidebar.expand} 
                icon={<ExpandIcon size={iconSize} />}
                onClick={(e) => {
                  this.props.setMiniappsSidebarCollapsed(false);
                }}
              />
            }
          </div>
        </div>
        <div className="i-editor-sidebar-panels">
          <SidebarPanel tab="sites" label={i18n.editor.sidebar.sites}>
            <FootprintPanel fpeType="site" />
          </SidebarPanel>
          <SidebarPanel tab="facilities" label={i18n.editor.sidebar.facilities}>
            <FootprintPanel fpeType="facility" />
          </SidebarPanel>
          <SidebarPanel tab="levels" label={i18n.editor.sidebar.levels}>
            <FootprintPanel fpeType="level"/>
          </SidebarPanel>  
          <SidebarPanel tab="units" label={i18n.editor.sidebar.drawUnits}>
            <UnitsPanel />
          </SidebarPanel>
          <SidebarPanel tab="splitUnit" label={i18n.editor.sidebar.splitUnits}>
            <SplitPanel />
          </SidebarPanel>
          <SidebarPanel tab="walls" label={i18n.editor.sidebar.drawWalls}>
            <WallsPanel />
          </SidebarPanel>
          <SidebarPanel tab="mergeUnits" label={i18n.editor.sidebar.mergeUnits}>
            <MergePanel selectionType="unit" />
          </SidebarPanel>
          <SidebarPanel tab="mergeDetails" label={i18n.editor.sidebar.mergeDetails}>
            <MergePanel selectionType="detail" />
          </SidebarPanel>
          <SidebarPanel tab="entryways" label={i18n.editor.sidebar.entryways}>
            <PlaceDetailsPanel fpeType={FpeType.entryway} />
          </SidebarPanel>
          <SidebarPanel tab="windows" label={i18n.editor.sidebar.windows}>
            <PlaceDetailsPanel fpeType={FpeType.window} />
          </SidebarPanel>
          <SidebarPanel tab="furniture" label={i18n.editor.sidebar.furniture}>
            <PlaceDetailsPanel fpeType={FpeType.furniture} />
          </SidebarPanel>
          <SidebarPanel tab="transitions" label={i18n.editor.sidebar.transitions}>
            <PlaceDetailsPanel fpeType={FpeType.transition} />
          </SidebarPanel>
          <SidebarPanel tab="copypaste" label={i18n.editor.sidebar.copypaste}>
            <CopyPastePanel />
          </SidebarPanel>
          <SidebarPanel tab="delete" label={i18n.editor.sidebar._delete}>
            <DeletePanel />
          </SidebarPanel>
          <SidebarPanel tab="mergePlan" label={i18n.editor.sidebar.managePlan}>
            <MergePlanPanel miniapp="editor" />
          </SidebarPanel>
          {(canEditMediaLayers || hasMediaItems) && 
            <SidebarPanel tab="media" label={i18n.editor.sidebar.images}>
              <MediaPanel/>
            </SidebarPanel>
          }
          <SidebarPanel tab="grid" label={i18n.editor.sidebar.grid}>
            <GridPanel onClose={() => this.props.setActiveTab(null)} />
          </SidebarPanel>
          <SidebarPanel tab="editor" label={title} icon={icon}>
            <EditorPanel />
          </SidebarPanel>
        </div>
        {viewLoaded && (activeTab == null || activeTab === "editor") &&
          <MapSelection
            autoSetFloorFilter={true}
            multipleSelectionEnabled={false}
            view={Context.getInstance().views.activeView}
            onSelection={this.onMapSelection} />
        }
      </div>
    )
  }

}

const mapStateToProps = (state: IRootState) => ({
  activeTab: getActiveTab(state),
  collapsed: getMiniappsSidebarCollapsed(state),
  selectedFeatureKey: getActiveFeatureType(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setActiveTab: (tab: EditorTabs) => dispatch(setActiveTab(tab)), 
  setSwitcherActive: (active: boolean) => dispatch(setMiniAppSwitcherActive(active)),
  setMiniappsSidebarCollapsed: (value: boolean) => dispatch(setMiniappsSidebarCollapsed(value)),
  setHitTestResults: (results: HitTest[]) => dispatch(setHitTestResults(results)),
  setSelectedFeatureIndex: (index: number) => dispatch(setSelectedFeatureIndex(index)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);