import Context from "../../../context/Context";

export default class UsernameCache {

  usersByUsername = {};

  static instance: UsernameCache = new UsernameCache();

  getUser(username: string): any {
    if (username && typeof username === "string") {
      const lc = username.toLowerCase();
      return this.usersByUsername[lc];
    }
  }

  async load(usernames: string[]) {
    const splits = [];
    if (usernames && usernames.length > 0) {
      const names = [];
      usernames.forEach(username => {
        const lc = username.toLowerCase();
        if (!this.usersByUsername[lc] && (names.indexOf(lc) === -1)) {
          names.push(lc);
        }
      })
      const maxPer = 100;
      for (let i=0, j=names.length; i<j; i+=maxPer) {
        const a = names.slice(i,i+maxPer);
        if (a.length > 0) {
          splits.push(a)
        }
      }
    }
    await this.queryUsers(splits,0)
  }

  async queryUsers(splits,splitIndex) {
    const portal = Context.instance.getPortal();
    const usernames = splits[splitIndex];
    let query;
    if (usernames && usernames.length > 0) {
      const parts = [];
      usernames.forEach(username => {
        const part = `(username: "${username}")`;
        parts.push(part);
      })
      query = "("+parts.join(" OR ")+")";
    }
    if (query) {
      const params = {
        query: query,
      }
      const data = await portal.queryUsers(params);
      if (data && data.results) {
        data.results.forEach(user => {
          if (user.username && typeof user.username === "string") {
            const lc = user.username.toLowerCase();
            this.usersByUsername[lc] = user;
          }
        });
        await this.queryUsers(splits,splitIndex + 1);
      }
    }
  }

}