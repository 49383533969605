import React from 'react';
import Form from 'calcite-react/Form';
import Context from "../../../context/Context";
import ShareWith from "../common/ShareWith";
import { SharingLevels } from "../../../common/components/Sharing";

const Sharing = ({shareWith, disabled, disallowEveryone, chips, groups, onChange}) => {
  const portal = Context.getInstance().getPortal();
  let groupsUrl;
  if (portal) {
    groupsUrl = `${portal.url}/home/groups.html?sortOrder=asc&sortField=title`;
  }
 
  return (
    <Form className="i--form">
      <ShareWith 
        horizontal={false}
        disallowEveryone={disallowEveryone}
        disabled={disabled}
        groups={groups}
        assignedGroups={chips}
        shareWith={shareWith}
        onChange={onChange}
        private={shareWith === SharingLevels.PRIVATE || shareWith === SharingLevels.SHARED}
        organization={shareWith === SharingLevels.ORGANIZATION || shareWith === SharingLevels.PUBLIC}
        />
    </Form>
  )
}

export default Sharing