import Context from "../../context/Context";
import Source from "../base/Source";
import FieldNames from "../../aiim/datasets/FieldNames";
import { HomeOfficeId } from "../../spaceplanner/base/AreasTable";
import { IRecurrenceOptions } from "../../components/main/More/Actions/BookWorkspace/BookingRecurrence";
import { IField, ILayerDefinition } from "@esri/arcgis-rest-types";
import { DayOfWeek } from "@microsoft/microsoft-graph-types";
import { DayOfWeekShape } from "react-dates";
import moment from "moment";
import { IIndoorsEvent } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/Office365";

export function appendTokenToUrl(url) {
  if (url && url.indexOf("token=") === -1) {
    let token = null;
    const credential = Context.getInstance().lib.esri.esriId.findCredential(url);
    if (credential) {
      token = credential.token;
    }
    if (token) {
      if (url.indexOf("?") === -1) url += "?";
      else url += "&";
      url += "token=" + encodeURIComponent(token);
    }
  }
  return url;
}

export function findAttributeName(attributes, name) {
  if (!attributes) return null;
  if (attributes.hasOwnProperty(name)) return name;
  let found = null, lc = name.toLowerCase();
  Object.keys(attributes).some(key => {
    if (key.toLowerCase() === lc) {
      found = key;
      return true;
    }
    return false;
  });
  return found;
}

export function findField(fields: __esri.Field[] | IField[], name: string): __esri.Field {
  let found = null;
  if (fields && fields.length > 0 && name) {
    const lc = name.toLowerCase();
    fields.some(field => {
      if (lc === field.name.toLowerCase()) {
        found = field;
        return true;
      }
      return false;
    });
  }
  return found;
}

export function findFieldName(fields: __esri.Field[], name: string) {
  const field = findField(fields, name);
  if (field) return field.name;
  return null;
}

export function findObjectIdField(fields: __esri.Field[]) {
  let objectIdField: string = null;
  if (fields && fields.length > 0) {
    fields.some(field => {
      if (field.type === "oid") {
        objectIdField = field.name;
        return true;
      }
      return false;
    });
  }
  return objectIdField;
}

export function getAttributeValue(attributes, name) {
  if (!attributes || !name) return;
  if (typeof name !== "string") return;
  if (attributes.hasOwnProperty(name)) return attributes[name];
  let value, lc = name.toLowerCase();
  Object.keys(attributes).some(key => {
    if (key.toLowerCase() === lc) {
      value = attributes[key];
      return true;
    }
    return false;
  });
  return value;
}

export function getGlobalIdField(layer: __esri.FeatureLayer | ILayerDefinition) {
  return "globalIdField" in layer
    ? layer.globalIdField
    : (layer.fields as __esri.Field[]).find(f => f.type === "global-id")?.name;
}

export function getLayers(view) {
  if (view) {
    const layers = view.map.layers.flatten(function (item) {
      return item.layers || item.sublayers;
    });
    return layers;
  }
  return [];
}
/** Hydrates recurrence options based on a single booking's attributes.
 * @param attributes - The feature attributes for the booking.
 */
export function getRecurrencePattern(attributes: Record<string, any> | IIndoorsEvent): IRecurrenceOptions {
  if (attributes && "recurrence" in attributes) {
    // m365
    const recurrence = (attributes as IIndoorsEvent).recurrence;
    if (recurrence) {
      const pattern = recurrence.pattern;
      const range = recurrence.range;
      const days = pattern?.daysOfWeek;
      const endDate = range?.endDate;
      const startDate = range?.startDate;
      const type = pattern?.type as IRecurrenceOptions["type"];
      const dateEnd = moment(endDate).endOf("d").toDate();
      const dateStart = moment(startDate).toDate();
      const daysIndex = getDaysInIndex(days);

      return {
        enabled : true,
        days: daysIndex,
        type: type,
        endDate: dateEnd,
        interval: pattern.interval,
        startDate: dateStart
      }
    }
  } else {
    // esri
    const id = getAttributeValue(attributes, FieldNames.RECURRENCE_ID);
    const recurrenceConfig = getAttributeValue(attributes, FieldNames.RECURRENCE_CONFIG);
    try {
      const recurrence: IRecurrenceOptions = recurrenceConfig?.length > 0
        ? { ...JSON.parse(recurrenceConfig, (k, v) => k === "endDate" ? moment(v).endOf("d").toDate() : v), enabled: true, id }
        : null;
      return recurrence;
    } catch (ex) {
      console.error("Error in getting recurrence pattern", ex);
    }
  }
}

function getDaysInIndex(daysofWeek: DayOfWeek[]) {
  const daysInIndex: DayOfWeekShape[] = [];
  if (daysofWeek && daysofWeek.length > 0) {
    daysofWeek.forEach(day=> {
      if (day === 'sunday') daysInIndex.push(0);
      if (day === 'monday') daysInIndex.push(1);
      if (day === 'tuesday') daysInIndex.push(2);
      if (day === 'wednesday') daysInIndex.push(3);
      if (day === 'thursday') daysInIndex.push(4);
      if (day === 'friday') daysInIndex.push(5);
      if (day === 'saturday') daysInIndex.push(6);
    })
  }
  return daysInIndex;
}

export function getSource(sourceKey) {
  const categories = Context.getInstance().aiim.datasets.categories;
  return categories.findSourceByKey(sourceKey);
}

export function hasAttribute(attributes, name) {
  if (!attributes) return false;
  if (typeof name !== "string") return false;
  let found = false, lc = name.toLowerCase();
  Object.keys(attributes).some(key => {
    if (key.toLowerCase() === lc) {
      found = true;
    }
    return found;
  });
  return found;
}

export function lookupDomainValue(source, feature, fieldName) {
  let v = getAttributeValue(feature.attributes, fieldName);
  if (v === null || v === undefined) return v;
  let layer = source.layer2D;
  if (!layer) return v;
  let field = findField(layer.fields, fieldName);
  if (!field) return v;

  if (typeof layer.getFeatureType === "function") {
    const typeIdField = layer.typeIdField;
    if (typeof typeIdField === "string" &&
      typeIdField.toLowerCase() === field.name.toLowerCase()) {
      const featureType = layer.getFeatureType(feature);
      if (featureType) return featureType.name;
    }
  }

  if (field.domain && field.domain.type === "coded-value") {
    let codedValues = field.domain.codedValues;
    if (codedValues && codedValues.hasOwnProperty(v)) {
      let codedValue = codedValues[v];
      if (codedValue) {
        return codedValue.name;
      }
    }
  }

  // if (typeof layer.getFieldDomain === "function") {
  //   console.log("field",field.name.field);
  //   const domain = layer.getFieldDomain(field.name, {
  //     feature: feature
  //   });
  //   console.log(fieldName,"domain",domain)
  //   if (domain && domain.type === "coded-value") {
  //     return domain.getName(v);
  //   }
  // }

  return v;
}

export function readServiceJson(url) {
  const lib = Context.getInstance().lib;
  url = Context.checkMixedContent(url);
  const options = { query: { f: "json" }, responseType: "json" };
  return lib.esri.esriRequest(url, options);
}

export function removeShapeAttributes(attributes) {
  if (!attributes) return;
  const names = [];
  Object.keys(attributes).forEach(key => {
    const lc = key.toLowerCase();
    if (lc === "shape_area" || lc === "shape_length" || lc === "shape_len" ||
      lc === "shape__area" || lc === "shape__length" || lc === "shape__len" ||
      lc === "shape.area" || lc === "shape.length" || lc === "shape.len" ||
      lc === "shape.starea()" || lc === "shape.stlength()") {
      names.push(key);
    }
  });
  names.forEach(name => {
    delete attributes[name];
  });
}

export function setPopupTemplateTitle(
  feature: __esri.Graphic,
  template: __esri.PopupTemplate): __esri.PopupTemplate {
  if (feature == null) {
    return template;
  }
  const clone = template.clone();
  if (feature && getAttributeValue(feature.attributes, FieldNames.AREA_ID) === HomeOfficeId) {
    clone.title += ` (${Context.getInstance().i18n.spaceplanner.assignmentType.home})`
  }
  return clone;
}

export function waitForLayer(layer: __esri.Layer): Promise<__esri.Layer | void> {
  const promise = new Promise<__esri.Layer | void>((resolve, reject) => {
    if (layer && typeof layer.when === "function") {
      layer.when(() => {
        resolve(layer);
      }).catch(ex => {
        console.error("Error while waiting for layer:", ex);
        resolve(); // reject?
      });
    } else {
      resolve();
    }
  });
  return promise;
}

export function waitForLayers(
  view: __esri.View,
  layers?: __esri.Collection<__esri.Layer> | __esri.Layer[])
  : Promise<(__esri.Layer | void)[] | void> {
  const promises: Promise<__esri.Layer | void>[] = [];
  if (!layers) layers = getLayers(view);
  layers.forEach(layer => {
    promises.push(waitForLayer(layer));
  });
  if (promises.length > 0) {
    return Promise.all(promises);
  }
  return Promise.resolve();
}

export function isDetailsLayer(layer) {
  const title = layer.title
  if (title === "Details") return true
  else return false
}

export function makeReservationsSource(task) {
  const reservationsSource = task.sources.find((src) => src.key === "Reservations");
  if (reservationsSource) {
    return;
  }

  const view = Context.getInstance().views.mapView;
  const layers = getLayers(view);
  const reservationsLayer = layers.find((layer) => layer.title.toLowerCase() === "reservations");

  if (reservationsLayer && reservationsLayer.declaredClass !== "esri.layers.support.Sublayer") {
    const source = new Source({
      key: "Reservations",
      name: "Reservations"
    });
    source.uniqueIdField = reservationsLayer.objectIdField;
    source.url = `${reservationsLayer.url}/${reservationsLayer.layerId}`;
    source.displayField = reservationsLayer.displayField;
    source.layer2D = reservationsLayer;

    task.sources.push(source);
  }
}

/**
 * Returns a formatted value based on the field's type and format option.
 * @param feature The graphic containing the attribute value to be formatted.
 * @param field The field for which to format the value.
 * @param format An optional FieldInfoFormat with numeric and/or date formatting.
 * @returns A formatted string or `undefined` if the value is empty.
 */
export function getFieldValue(
  feature: __esri.Graphic,
  field: __esri.Field,
  format?: __esri.FieldInfoFormat
): string | undefined {

  const attributes = feature && feature.attributes;
  if (!(attributes && field)) {
    return;
  }
  // get raw value
  let value = getAttributeValue(attributes, field.name);
  if (value == null) return;
  
  const intl: typeof __esri.intl = Context.getInstance().lib.esri.intl;

  if (feature.layer && feature.layer.type === "feature") {
    const layer = feature.layer as __esri.FeatureLayer;
    const typeIdField = layer.typeIdField && layer.typeIdField.toLowerCase();
    const type = typeIdField ? layer.getFeatureType(feature) : null;    
    if (
      type &&
      type.domains &&
      type.domains.hasOwnProperty(field.name) &&
      type.domains[field.name].type == 'coded-value') {
      // type domain
      const domain = type.domains[field.name] as __esri.CodedValueDomain;
      const cv = domain.codedValues.find(c => c.code === value);
      value = (cv || {}).name;
    } else if (field.domain && field.domain.type === 'coded-value') {
      // field domain
      const cv = field.domain.codedValues.find(c => c.code === value);
      value = (cv || {}).name;
    } else if (field.type === "date") {
      // dates
      value = format != null
        ? intl.formatDate(value, intl.convertDateFormatToIntlOptions(
            format.dateFormat as Exclude<__esri.FieldInfoFormat["dateFormat"], "default">))
        : intl.formatDate(value);      
    } else if (typeof value === "number") {
      value = format != null
        ? intl.formatNumber(value, {
          ...intl.convertNumberFormatToIntlOptions(format),
          minimumFractionDigits: 0
        })
        : intl.formatNumber(value);
    }
  }
  
  return value;    
}
  