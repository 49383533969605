import React from "react";
import Context from "./../../../context/Context";

const CSS = {
  main: "i-layout",
  button: "i-iphone-button",
  logo: "i-iphone-indoors-logo",
  downloadLink: "i-iphone-apple-download-link",
  continueLink: "i-iphone-continue-link",
  continueLinkText: "i-iphone-continue-link-text",
  container:"i-iphone-container",
  textCenter: "i-iphone-text-center"
};

export default class IPhone extends React.Component {

  openWebApp=()=> {
    var path = window.location.pathname.replace(/index.html$/i, "");
    if (!this.endsWith(path, "/", true)) {
      path += "/";
    }
    path += "index.html";

    var query = window.location.search;
    if (query === null || query === undefined || query === "") {
      query = "?";
    } else {
      query += "&";
    }
    query += "openWebApp=true";

    window.location.href = window.location.origin + path + query;
  }

  endsWith=(str, suffix, ignoreCase)=> {
    str = (ignoreCase && str) ? str.toUpperCase() : str;
    suffix = (ignoreCase && suffix) ? suffix.toUpperCase() : suffix;
    return str && suffix
      && (str.length >= suffix.length)
      && (str.substring(str.length - suffix.length).localeCompare(suffix) === 0);
  }

  openMobileApp=()=>{
    window.location.href = "arcgis-indoors://loc" + window.location.search;
    //console.log("Open", window.location.search);
  }

  componentDidMount() {
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const hashref = "#";
    return (
        <div>
            <div className={CSS.container}>
            <img className={CSS.logo} src="assets/indoors-logo-white.svg"
              alt={i18n.general.image} />
            <h1 className="title">{i18n.getStarted.iphoneTitle}</h1>
            <button className={CSS.button} onClick={this.openMobileApp}
              >{i18n.getStarted.iphoneOpenApp}</button>
            <a className={CSS.downloadLink}
              href="https://itunes.apple.com/us/app/arcgis-indoors/id1322110701"> </a>
            <div className={CSS.continueLink}>
              <a className={CSS.continueLinkText} href={hashref}
                onClick={this.openWebApp}>{i18n.getStarted.iphoneLink}</a>
            </div>
        </div>
        </div>
    );
  }
}
