import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Context from "../../../../context/Context";
import GridVM from "./GridVM";
import Savebar from "../support/Savebar";
import * as component from "../../../../components/util/component";
import * as sourceUtil from "../../../base/sourceUtil";

import LengthAndWidth from "../support/LengthAndWidth";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";
import { IGridSettings } from "../support/Grid";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import {
  CalciteBlock,
  CalciteButton,
  CalciteCheckbox,
  CalciteIcon,
  CalciteInputNumber,
  CalciteLabel,
  CalciteNotice
} from "@esri/calcite-components-react";

import { getActiveHelpTip, HelpTip, setHelpTip } from "../redux";

interface Props {
  onClose: () => void,
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}

interface State {
  geometryEditOn: boolean,
  gridSettings: IGridSettings
}

class GridPanel extends React.Component<Props, State> {

  componentId: string;
  viewModel: GridVM;

  _mounted = false;

  constructor(props:Props) {
    super(props);
    const grid = sourceUtil.getGrid();

    this.viewModel = new GridVM({});
    this.viewModel.onSettingsChange = () => this.trackSettings();

    this.componentId = component.nextId("grid-panel");
    this.state = component.newState({
      geometryEditOn: false,
      gridSettings: grid.cloneSettings()
    });
  }

  componentDidMount() {
    this._mounted = true;
    const grid = sourceUtil.getGrid();
    grid.activate();
    grid.setVisible(true);
    if (!grid.gridSettings.fixedLowerLeft) {
      setTimeout(() => {
        this.activateSketch();
      }, 200)
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.viewModel.clear();
    this.viewModel.destroy();
    sourceUtil.getGrid().deactivate();
    component.componentWillUnmount(this);
  }

  activateSketch() {
    this.setState({geometryEditOn: true});
    this.viewModel.activateUpdate();
  }

  cancelSketch() {
    this.setState({geometryEditOn: false});
    this.viewModel.cancelSketch();
  }

  render() {
    return (
      <div className="i-editor-sidebar-container i-editor-grid-container">
        <div className="i-editor-sidebar-content">
          {this.renderInstruction()}
          {this.renderGridSize()}
          {this.renderAngle()}
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderSavebar()}        
        </div>
      </div>
    );
  }

  renderAngle() {
    const i18n = Context.instance.i18n;
    const grid = sourceUtil.getGrid();
    const enabled = grid.gridSettings.enabled;
    const fixedLowerLeft = grid.gridSettings.fixedLowerLeft;
    const inputStyle = {width: "8rem", marginInlineEnd: "0.1rem"};
    let angle = grid.gridSettings.rotationAngle;
    angle = Math.round(angle * 100) / 100;
    if (angle !== 0) angle = -angle;
    return (
      <CalciteBlock open heading={""}>
        <CalciteLabel scale="s" for={this.componentId+"-angle"}>
          {i18n.editor.grid.angle}
          <div className="i-flex i-align-items-end">
            <CalciteInputNumber 
              id={this.componentId+"-angle"}
              style={inputStyle}
              disabled={(!enabled || fixedLowerLeft) || undefined}
              value={""+angle}
              min={-360}
              max={360}
              scale="s"
              onCalciteInputNumberInput={e => {
                const f = parseFloat(e.target.value);
                if (!isNaN(f)) {
                  let v = Math.abs((Math.round(f * 100)) / 100);
                  v = Math.min(v, 360) * (f < 0 ? -1 : 1);
                  if (v !== 0) v = -v;
                  sourceUtil.getGrid().setRotationAngle(v);
                  this.trackSettings();
                  if (this.state.geometryEditOn) this.viewModel.activateUpdate();
                }
              }}
            />
          </div>
        </CalciteLabel>
        {this.renderExtra()}
      </CalciteBlock>
    )
  }

  renderExtra() {
    const i18n = Context.instance.i18n;
    const grid = sourceUtil.getGrid();
    const enabled = grid.gridSettings.enabled;
    const fixedLowerLeft = grid.gridSettings.fixedLowerLeft;
    return (
      <div className="i-flex-between-centered" style={{marginTop: "0.25rem"}}>
        <CalciteLabel layout="inline" scale="s" className="i-cursor-pointer" style={{marginTop: "0.4rem"}}>
          <CalciteCheckbox 
            checked={grid.gridSettings.fixedLowerLeft || undefined}
            onCalciteCheckboxChange={e => {
              const value = !!e.target.checked;
              grid.setFixedLowerLeft(value);
              if (value) this.cancelSketch();
              else this.activateSketch();
              this.trackSettings();
            }}
          />
          {i18n.editor.grid.freezeGrid}
          <CalciteIcon scale="s" icon="information" title={i18n.editor.grid.freezeGridTip} />
        </CalciteLabel>
        <CalciteButton
          appearance="transparent"
          disabled={(!enabled || fixedLowerLeft) || undefined}
          iconStart="object-detection"
          scale="s"
          onClick={e=> {
            this.activateSketch();
          }}
        >
          {i18n.editor.grid.center}
        </CalciteButton>
      </div>
    )
  }

  renderGridSize() {
    const i18n = Context.instance.i18n;
    const grid = sourceUtil.getGrid();
    const enabled = grid.gridSettings.enabled;
    return (
      <LengthAndWidth 
        showLength 
        lengthLabel={i18n.editor.grid.spacing}
        disabled={!enabled}
        minLength={0.1}
        lengthAndWidthInfo={grid.gridSettings.lengthAndWidthInfo}
        onChange={(lengthAndWidthInfo: ILengthAndWidthInfo) => {
          grid.gridSettings.lengthAndWidthInfo = lengthAndWidthInfo;
          grid.setLengthAndWidthInfo(lengthAndWidthInfo);
          this.trackSettings();
        }}
      />
    )
  }

  renderInstruction() {
    const i18n = Context.instance.i18n;
    const { tip } = this.props;
    //const fixedLowerLeft = sourceUtil.getGrid().gridSettings.fixedLowerLeft;
    //const prompt = fixedLowerLeft ? i18n.editor.grid.instruction : i18n.editor.grid.instruction2;
    const prompt = i18n.editor.grid.instruction;
    return (
      <CalciteNotice 
        scale="s" 
        icon="lightbulb"
        width="full"
        closable
        open={!(tip && tip.visible) ? true : undefined} 
        onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: prompt })}
      >
        <div slot="message">{prompt}</div>
      </CalciteNotice>
    )
  }

  renderSavebar() {
    const i18n = Context.instance.i18n;
    return (
      <Savebar
        parentPanelKey="grid"
        resetDisabled={false}
        saveDisabled={false}
        saveLabel={i18n.general.done}
        onReset={() => {
          sourceUtil.getGrid().reset();
          this.trackSettings();
          this.activateSketch();
        }} 
        onSave={() => {
          this.props.onClose()
        }}
      />
    )
  }

  trackSettings() {
    const gridSettings = sourceUtil.getGrid().cloneSettings();
    this.setState({gridSettings})
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("grid", tip)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridPanel);