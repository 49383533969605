import FieldNames from "../../../../aiim/datasets/FieldNames";
import { ILevelData } from "../../../../aiim/datasets/Levels";
import { findField } from "../../../../aiim/util/aiimUtil";
import Context from "../../../../context/Context";
import { IFormField, LayerType } from "../../../../util/interfaces";
import { getLayer } from "../../../base/mapUtil";
import { WarningMsg } from "./editorUtil";

/** Provides form field options for a facility footprint. */
export const FACILITIES_FIELDS: readonly IFormField[] = Object.freeze([
  { name: FieldNames.NAME, type: "primary", required: true },
  { name: FieldNames.NAME_LONG, type: "primary" },
  { name: FieldNames.HEIGHT_RELATIVE, type: "primary", required: false },
  { name: FieldNames.FACILITY_LEVELS_ABOVE_GROUND, type: "primary", required: false },
  { name: FieldNames.FACILITY_ID, type: "secondary", required: true, toggle: true },
  { name: FieldNames.SITE_ID, type: "secondary", required: true, readonly: true, visible: true }
]);
/** Provides form field options for a site footprint. */
export const SITES_FIELDS: readonly IFormField[] = Object.freeze([
  { name: FieldNames.NAME, type: "primary", required: true },
  { name: FieldNames.NAME_LONG, type: "primary" },
  { name: FieldNames.SITE_ID, type: "secondary", required: true, toggle: true }
]);
/** Provides form field options for a level footprint. */
export const LEVELS_FIELDS: readonly IFormField[] = Object.freeze([
  { name: FieldNames.NAME, type: "primary", required: true },
  { name: FieldNames.NAME_SHORT, type: "primary", required: true },
  { name: FieldNames.LEVEL_NUMBER, type: "primary", required: true },
  { name: FieldNames.VERTICAL_ORDER, type: "primary", required: true },
  { name: FieldNames.HEIGHT_RELATIVE, type: "primary", required: false },
  { name: FieldNames.LEVEL_ID, type: "secondary", required: true, toggle: true },
  { name: FieldNames.FACILITY_ID, type: "secondary", required: true, readonly: true },
  { name: FieldNames.UNITS_AREA_GROSS, type: "secondary", required: true, toggle: true }
]);
export type FootprintType = Extract<LayerType, "site" | "facility" | "level">;

/** Retrieves form options for the specified footprint type. */
export const getFieldObjects = (layerTypeOrFields: LayerType | IFormField[], fieldType?: "primary" | "secondary") => {
  const fields = typeof layerTypeOrFields === "string"
    ? layerTypeOrFields === "site"
      ? SITES_FIELDS
      : layerTypeOrFields === "facility"
        ? FACILITIES_FIELDS
        : LEVELS_FIELDS
    : layerTypeOrFields;
  return fields.filter(f => fieldType == null || f.type === fieldType).map(f => ({ ...f }));
}
/** Retrieves layer field objects based on form options for specified footprint type. */
export const getFields = (layerType: LayerType, layer: __esri.FeatureLayer, type?: "primary" | "secondary") => {
  const fields = getFieldObjects(layerType, type);
  const names = fields.map(f => f.name);
  return layer.fields.filter(f => names.includes(f.name.toLowerCase()));
}
/** Returns the unique ID/key field for the specified layer type. */
export const getIdField = (layerType: LayerType) => {
  switch (layerType) {
    case "detail":
      return FieldNames.DETAIL_ID;
    case "facility":
      return FieldNames.FACILITY_ID;
    case "level":
      return FieldNames.LEVEL_ID;
    case "site":
      return FieldNames.SITE_ID;
    case "unit":
      return FieldNames.UNIT_ID;
  }
}
export const getLevelData = async (geometry: __esri.Geometry): Promise<{ level?: ILevelData, error?: WarningMsg }> => {
  const { i18n, aiim: { datasets: { levels } } } = Context.getInstance();
  const list = await levels.queryLevelDataByGeometry(geometry, true);
  let submessage: string = null;
  if (!list || list.length === 0) {
    submessage = i18n.editor.levels.errNotWithinLevel;
  } else if (list && list.length > 1) {
    submessage = i18n.editor.levels.errMultipleLevels;
  }

  let error: WarningMsg = null;
  if (submessage) {
    error = { type: "error", message: i18n.editor.warnLocationInvalid, submessage };
  }
  return { level: error ? null : list[0], error };
}
export const getSite = (geometry: __esri.Geometry) => {
  const { views: { floorFilter: { activeWidget: { viewModel } } } } = Context.getInstance();
  const features: { sites: { sitesInfo: { geometry: __esri.Polygon, id: string, name: string }[] } } = viewModel?.get("filterFeatures");
  const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
  return features.sites.sitesInfo.find(site => engine.within(geometry, site.geometry));
}
export const getCapitalizedType = (layerType: LayerType, plural: boolean = false) => {
  const i18n = Context.instance.i18n;
  switch (layerType) {
    case "unit":
      return plural ? i18n.editor.selectFeature.units : i18n.editor.selectFeature.unit;
    case "detail":
      return plural ? i18n.editor.selectFeature.details : i18n.editor.selectFeature.detail;
    case "facility":
      return plural ? i18n.editor.selectFeature.facilities : i18n.editor.selectFeature.facility;
    case "level":
      return plural ? i18n.editor.selectFeature.levels : i18n.editor.selectFeature.level;
    case "site":
      return plural ? i18n.editor.selectFeature.sites : i18n.editor.selectFeature.site;
  }
}
export const hasLayer = (layerType: LayerType) => {
  return getLayer(layerType) != null;
}
export const hasLevels = () => {
  const { aiim: { datasets: { levels } }, views: { floorFilter: { activeWidget } } } = Context.getInstance();
  return levels?.getFacilityData(activeWidget?.facility)?.levels?.length > 0;
}
export const hasSites = () => {
  const { aiim: { datasets: { levels: { _data: { sitesById } } } } } = Context.getInstance();
  return sitesById ? Object.keys(sitesById).length > 0 : false;
}
/** Generates a new template for a form based on the input fields and options. */
export const getFormTemplate = (type: LayerType, fields: __esri.Field[], options: IFormField[], groupExpanded: boolean = false) => {
  const { lib, i18n } = Context.getInstance();
  if (!fields?.length) {
    return;
  }
  const FieldElement: typeof __esri.FieldElement = lib.esri.FieldElement;
  const GroupElement: typeof __esri.GroupElement = lib.esri.GroupElement;
  const FormTemplate: typeof __esri.FormTemplate = lib.esri.FormTemplate;
  const makeElement = (fieldObj: IFormField) => {
    const field = findField(fields, fieldObj.name);
    const label = field.alias || field.name;
    const fieldElement = new FieldElement({
      fieldName: field.name,
      label: !field.nullable || fieldObj.required
        ? i18n.general.requiredField.replace("{field}", label)
        : label,
      editableExpression: field.editable && !fieldObj.toggle && !fieldObj.readonly
        ? "true"
        : "false",
      requiredExpression: !field.nullable || fieldObj.required
        ? "true"
        : "false",
      domain: field.domain as (__esri.CodedValueDomain | __esri.RangeDomain)
    });
    fieldElement.set("tooltip", getFieldTooltip(type, fieldObj.name, label));
    fieldElement.set("indoorsType", fieldObj.toggle ? "edit-toggle" : "default");
    return fieldElement;
  }

  const primaryFields = getFieldObjects(options, "primary").filter(f => f.visible !== false);
  const secondaryFields = getFieldObjects(options, "secondary").filter(f => f.visible !== false);
  const elements: __esri.Element[] = primaryFields.map(makeElement);
  elements.push(new GroupElement({
    label: i18n.editor.advancedAttributes,
    initialState: groupExpanded ? "expanded" : "collapsed",
    elements: secondaryFields.map(makeElement)
  }));

  return new FormTemplate({ elements });
}

export const isFootprint = (type: LayerType | "person") => ["site", "facility", "level"].includes(type);

export const getFieldTooltip = (type: LayerType, fieldName: string, defaultTooltip: string) => {
  const { i18n: { editor: { fields } } } = Context.getInstance();
  switch (fieldName.toLowerCase()) {
    case FieldNames.NAME:
      return getNameTooltip(type) || defaultTooltip;
    case FieldNames.NAME_LONG:
      return getLongNameTooltip(type) || defaultTooltip;
    case FieldNames.HEIGHT_RELATIVE:
      return getHeightTooltip(type) || defaultTooltip;
    case FieldNames.FACILITY_LEVELS_ABOVE_GROUND:
      return fields.numFloors;
    case FieldNames.NAME_SHORT:
      return fields.floorName;
    case FieldNames.LEVEL_NUMBER:
      return fields.levelNumber;
    case FieldNames.VERTICAL_ORDER:
      return fields.verticalOrder;
    case FieldNames.UNITS_USE_TYPE:
    case FieldNames.DETAILS_USE_TYPE:
      return type === "unit" ? fields.unitUseType : type === "detail" ? fields.detailUseType : defaultTooltip;
    case FieldNames.SCHEDULE_EMAIL:
      return fields.unitEmailAddress;
    case FieldNames.UNIT_CAPACITY:
      return fields.unitCapacity;
    case FieldNames.SITE_ID:
      return fields.siteId;
    case FieldNames.FACILITY_ID:
      return fields.facilityId;
    case FieldNames.UNIT_ID:
      return fields.unitId;
    case FieldNames.DETAIL_ID:
      return fields.detailId;
    case FieldNames.LEVEL_ID:
      return getLevelIDTooltip(type) || defaultTooltip;
    case FieldNames.UNITS_AREA_GROSS:
      return type === "level" ? fields.levelGrossArea : type === "unit" ? fields.unitGrossArea : defaultTooltip;
    case FieldNames.RESERVATION_METHOD:
      return fields.unitReservable;
    default:
      return defaultTooltip;
  }
}
const getNameTooltip = (type: LayerType) => {
  const { i18n: { editor: { fields } } } = Context.getInstance();
  switch (type) {
    case "site":
      return fields.nameOfSite;
    case "facility":
      return fields.nameOfFacility;
    case "level":
      return fields.nameOfLevel;
    case "unit":
      return fields.nameOfUnit;
    case "detail":
      return fields.nameofDetail;
  }
}
const getLongNameTooltip = (type: LayerType) => {
  const { i18n: { editor: { fields } } } = Context.getInstance();
   switch (type) {    
     case "site":
       return fields.descOfSite;
     case "facility":
       return fields.descOfFacility;
     case "unit":
       return fields.descOfUnit;
   } 
 }
const getHeightTooltip = (type: LayerType) => {
 const { i18n: { editor: { fields } } } = Context.getInstance();
  switch (type) {    
    case "facility":
      return fields.heightFacility;
    case "level":
      return fields.heightLevel;
    case "unit":
      return fields.heightUnit;
    case "detail":
      return fields.heightDetail;
  } 
}

const getLevelIDTooltip = (type: LayerType) => {
 const { i18n: { editor: { fields } } } = Context.getInstance();
 switch (type) {    
   case "level":
     return fields.levelId;
   case "unit":
     return fields.unitLevel;
   case "detail":
     return fields.detailLevel;
 } 
}
