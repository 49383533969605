import React, { Component, createRef } from "react";
import Context from "../../../../context/Context";
import ErrorDetails from "./ErrorDetails";
import "@esri/calcite-components/dist/components/calcite-modal";
import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteModal, CalciteButton } from "@esri/calcite-components-react";
import { JSX as CalciteJSX, CalciteModalCustomEvent } from "@esri/calcite-components";

export interface IModalProps extends CalciteJSX.CalciteModal {
  className?: string,
  children?: JSX.Element | JSX.Element[],
  onClose?: () => void,
  onMount?: (component: HTMLCalciteModalElement) => void
}
export interface IAlertOptions extends IModalProps {
  hideCancel?: boolean,
  hideOkCancel?: boolean,
  isError?: boolean,
  message?: string | JSX.Element,
  okLabel?: string,
  okTooltip?: string,
  cancelLabel?: string,
  cancelTooltip?: string,
  onCancel?: () => void,
  onOk?: () => void,
  submessage?: string,
  title?: string
}
export interface IErrorOptions extends IModalProps {
  errors?: {
    generic: string,
    detailed: string[]
  },
  title?: string
}
export default class Modal<T extends IModalProps> extends Component<T> {
  private modal = createRef<HTMLCalciteModalElement>();
  constructor(props: T) {
    super(props);

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.onMount === "function") {
      this.props.onMount(this.modal.current);
    }
  }

  protected close(e?: CalciteModalCustomEvent<void>) {
    if (typeof this.props.onCalciteModalClose === "function") {
      e && this.props.onCalciteModalClose(e);
    }
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  render() {
    return (
      <CalciteModal {...this.props} onCalciteModalClose={this.close} ref={this.modal}>
        {this.props.children}
      </CalciteModal>
    );
  }
}

export class Alert extends Modal<IAlertOptions> {

  constructor(props: IAlertOptions) {
    super(props);
    this.okClicked = this.okClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);
  }
  private okClicked() {
    this.props.onOk && this.props.onOk();
    this.close();
  }
  private cancelClicked() {
    this.props.onCancel && this.props.onCancel();
    this.close();
  }
  render() {
    const ctx = Context.getInstance();
    const i18n = ctx.i18n;
    const { title, ...props } = this.props;
    const okLabel = props.okLabel || i18n.general.ok;
    const cancelLabel = props.cancelLabel || i18n.general.cancel;
    const okTooltip = props.okTooltip || okLabel;
    const cancelTooltip = props.cancelTooltip || cancelLabel;
    return (
      <Modal {...props}>
        <>
          <header slot="header">{title}</header>
          <div slot="content">
            {props.message}
            {props.submessage}
          </div>
          {!props.isError && !props.hideOkCancel &&
            <>
              <CalciteButton slot="primary" width="full" onClick={this.okClicked} title={okTooltip}>
                {okLabel }
              </CalciteButton>
              {!props.hideCancel &&
                <CalciteButton slot="secondary" width="full" appearance="outline" onClick={this.cancelClicked} title={cancelTooltip}>
                  {cancelLabel}
                </CalciteButton>
              }
            </>
          }
        </>
      </Modal>
    );
  }
}

export class Error extends Modal<IErrorOptions> {
  
  render() {
    return (
      <Modal {...this.props}>
        <>
          <header slot="header">{this.props.title}</header>
          <div slot="content">
            <ErrorDetails errors={this.props.errors} />
          </div>        
        </>
      </Modal>
    );
  }
}