import React from "react";
import {connect} from "react-redux";
import Rdx from "../../../../../redux/Rdx";
import Context from "../../../../../context/Context";
import Icons from "../../../../util/Icons";
import * as component from "../../../../util/component";
import * as bookWorkspaceUtil from "../BookWorkspace/BookWorkspaceUtil";
import DropdownButton from "../../../../common/Dropdown/DropdownButton";
import Topic from "../../../../../context/Topic";

const CSS = {
    highLightItem: "i-sitefilter-highlight",
    unhighLightItem: "i-sitefilter-unhighlight"
}

class SiteDropdown extends React.Component {

    _mounted = false;

    constructor(props){
        super(props);
        const { initialFilter } = props;
        this.state = component.newState({
            selectedValue: initialFilter ? null : this.props.rdxSiteFilters
        })
    }

    componentDidMount() {
        this._mounted = true;
        component.own(this, [
            Topic.subscribe(Topic.SpaceFiltersSubmit, () => {
                Rdx.setValue(this, Rdx.Keys.SITE_FILTERS, this.state.selectedValue);
            }),
            Topic.subscribe(Topic.BookFiltersCleared, () => {
                this.setState({
                    selectedValue: null
                })
                Rdx.setValue(this, Rdx.Keys.SITE_FILTERS, null);
                Rdx.setValue(this, Rdx.Keys.SITE_DROPDOWN, this.props.siteFilters);
            }),
            Topic.subscribe(Topic.FilterAreaChange, ()=> {
                bookWorkspaceUtil.getSitesByAreas(this.props.rdxAreasFilters)
                .then((result)=> {
                    if(result && result.siteFilterDropdown && result.siteFilterDropdown.length > 0) {
                      Rdx.setValue(this, Rdx.Keys.SITE_DROPDOWN, result.siteFilterDropdown);
                    } else {
                      Rdx.setValue(this, Rdx.Keys.SITE_DROPDOWN, []);
                    }
                    Rdx.setValue(this, Rdx.Keys.SITE_FILTERS, null);
                    this.setState({
                        selectedValue: null
                    })
                    Topic.publish(Topic.FilterSiteChange, {})
                })
                .catch((error)=> {
                    console.error(error);
                })
            })
        ])
    }

    componentWillUnmount() {
        this._mounted = false;
        component.componentWillUnmount(this);
    }

    siteSelected=(siteName)=>{
        this.setState({
            selectedValue: siteName
        }, ()=> {
            Rdx.setValue(this, Rdx.Keys.SITE_FILTERS, siteName);
            setTimeout(() => {Topic.publish(Topic.FilterSiteChange, {})}, 1);
        })
    }

    render() {
        const i18n = Context.instance.i18n;
        const hashref = "#";
        const siteOptions = [];
        let className;
        if(!this.state.selectedValue) className = CSS.highLightItem;
        else className = CSS.unhighLightItem;

        siteOptions.push(
            <li key={"any"} className={className} role="none">
                <a className="i-dd-menuitem" href={hashref} tabIndex="0" target="_top"
                    role="menuitem button" 
                    onClick={()=> {this.siteSelected(null)}}>
                    {i18n.more.bookWorkspace.any}</a>
            </li>
        );
        const sites = this.props.rdxSiteDropdown;
        if(!sites) return null;
        sites.map((site) => {
            if(this.state.selectedValue === site) className = CSS.highLightItem;
            else className = CSS.unhighLightItem;
            siteOptions.push (
                <li className={className} 
                    key={site} role="none">
                    <a className="i-dd-menuitem" href={hashref} tabIndex="0" target="_top"
                        role="menuitem button" 
                        onClick={()=> {this.siteSelected(site)}}
                    >{site}</a>
                </li>
            )
        })
        const siteName = this.state && this.state.selectedValue;
        let caption;
        if(!siteName) caption = i18n.more.bookWorkspace.any;
        else caption = this.state.selectedValue;

        const icon = Icons.doubleVertical();

        const buttonContent = (
            <div role="button" aria-label={i18n.more.bookWorkspace.areaListAriaLabel}>
              <span key="caption">{caption}</span>
              <span key="icon">{icon}</span>
            </div>
        );
        const dropdownContent = (
            <ul role="menu">{siteOptions}</ul>
        );

        return (
            <div className={CSS.optionsContainer}>
            <div className={CSS.options}>
              <div className={CSS.main}>
                <DropdownButton
                  closeOnSelect={true}
                  focusFirst={false}
                  buttonClassName={"i-choice-dd"}
                  buttonContent={buttonContent}
                  dropdownContent={dropdownContent}
                  disabled={false}
                  placement={"bottom-start"}>
                </DropdownButton>
              </div>
            </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      rdxSiteFilters: Rdx.getValue(state, Rdx.Keys.SITE_FILTERS),
      rdxAreasFilters: Rdx.getValue(state, Rdx.Keys.AREA_FILTERS),
      rdxSiteDropdown: Rdx.getValue(state, Rdx.Keys.SITE_DROPDOWN)
    }
}
  
export default connect(mapStateToProps)(SiteDropdown);