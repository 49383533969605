import React, { useEffect, useState } from "react";
import { IItem } from "@esri/arcgis-rest-types";
import { IConfigurables, IConfiguration } from "../../../context/Configuration";
import Context from "../../../context/Context";
import {
  Fieldset,
  FormControl,
  Legend
} from "calcite-react/Form";
import Checkbox from "calcite-react/Checkbox";
import Radio from "calcite-react/Radio";
import { ReservationMethod } from "../../../util/interfaces";
import { findFieldName } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { getWorkspaceInfo } from "../../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import {
  setHotdesksEnabled, setHotelsEnabled, setMeetingRoomsEnabled, setWorkspacesEnabled,
  setHotelReservationMethod, setMeetingRoomReservationMethod
} from "../../redux";
import { useDispatch } from "react-redux";
import Topic from "../../../context/Topic";
import { useHandles } from "../../miniapps/common/hooks";

type SpacePlannerType = IConfigurables["spaceplanner"];

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  toggleLabel: "i-toggle-label",
  optionsContainer: "i--restrict-merge-groups",
  config: "i-workspace-areas",
  checkboxGroup: "i-workspace-type",
  radioGroup: "i-reservation-methods",
  toggleGroup: "i-toggle-group",
  meetingRoomHelp: "i-meeting-room-help"
};

interface IWorkspaceAreaProps {
  cfg: {
    activeStepKey: string,
    initialConfigurables: IConfigurables,
    appItem: IItem,
    webmapItem: __esri.PortalItem,
    getConfigurable: (name: keyof IConfigurables) => IConfigurables[keyof IConfigurables],
    setConfigurable: (name: keyof IConfiguration, value: keyof IConfigurables) => void
  }
}

const getUnitsLayer = () => {
  const project = Context.instance.spaceplanner?.planner?.project;
  if (project?.isVersioned) {
    return project.versionedInfo?.unitsLayer;
  } else if (project?.isHosted) {
    return project.hostedInfo?.unitsLayer;
  }
}

const WorkspaceAreas = ({ cfg }: IWorkspaceAreaProps) => {
  const { i18n } = Context.getInstance();
  const dispatch = useDispatch();
  const spaceplanner = cfg.getConfigurable("spaceplanner") as SpacePlannerType;
  const { workspaceAreas: wsa } = spaceplanner;
  const [enableHotDesks, setEnableHotDesks] = useState<boolean>(wsa.hotDesks.enabled);
  const [enableHotels, setEnableHotels] = useState<boolean>(wsa.hotels.enabled);
  const [enableMeetingRooms, setEnableMeetingRooms] = useState<boolean>(wsa.meetingRooms.enabled);
  const [reservationTypeHotel, setReservationTypeHotel] = useState<ReservationMethod>(wsa.hotels.reservationMethod);
  const [reservationTypeMeeting, setReservationTypeMeeting] = useState<ReservationMethod>(wsa.meetingRooms.reservationMethod);
  const setHandles = useHandles()[1];

  useEffect(() => {
    init();
    setHandles([Topic.subscribe(Topic.ViewsReloaded, init)]);

  }, [enableHotels, enableMeetingRooms]);

  const init = async () => {
    const info = getWorkspaceInfo();
    const unitsLayer = getUnitsLayer();
    
    // check if default values need to be set
    if (reservationTypeHotel == null || reservationTypeMeeting == null) {
      let supports365 = false, supportsEsri = false;
      if (info.hasReservationsLayer) {
        supportsEsri = true;
      }
      if (info.hasUnitsLayer && unitsLayer) {
        const emailFld = findFieldName(unitsLayer.fields, FieldNames.SCHEDULE_EMAIL);
        const query = unitsLayer.createQuery();
        query.where = `${emailFld} IS NOT NULL`;
        const count = await unitsLayer.queryFeatureCount(query);
        if (count > 0) {
          supports365 = true;
        }
      }
      const supportsBoth = supports365 && supportsEsri;
      const type = supportsBoth || supportsEsri ? "esri" : "office365";
      // only set default if value is null
      if (reservationTypeHotel == null) {
        setReservationTypeHotel(type);
        dispatch(setHotelReservationMethod(type));
      }
      if (reservationTypeMeeting == null) {
        setReservationTypeMeeting(type);
        dispatch(setMeetingRoomReservationMethod(type));
      }
    }
  }

  const renderHotDeskSection = () =>
    <Checkbox
      key={"__hotdesks__"}
      className={CSS.checkboxGroup}
      name={i18n.spaceplanner.configurator.workspaceAreas.hotdesks}
      checked={enableHotDesks}
      onChange={(event) => {
        wsa.hotDesks.enabled = event.target.checked;
        setEnableHotDesks(wsa.hotDesks.enabled);
        dispatch(setHotdesksEnabled(wsa.hotDesks.enabled));
        checkEnabled();
      }}>
      {i18n.spaceplanner.configurator.workspaceAreas.hotDesks}
    </Checkbox>

  const renderHotelSection = () => {
    return (
      <>
        <Checkbox
          key={"__hotels__"}
          className={CSS.checkboxGroup}
          name={i18n.spaceplanner.configurator.workspaceAreas.hotels}
          checked={enableHotels}
          onChange={(event) => {
            wsa.hotels.enabled = event.target.checked;
            setEnableHotels(wsa.hotels.enabled);
            dispatch(setHotelsEnabled(wsa.hotels.enabled));
            dispatch(setHotelReservationMethod(reservationTypeHotel));
            checkEnabled();
          }}>
          {i18n.spaceplanner.configurator.workspaceAreas.hotels}
        </Checkbox>
        {enableHotels && renderReservationMethod("hotels")}
      </>
    );
  }
  const renderMeetingRoomSection = () => {
    return (
      <>
        <Checkbox
          key={"__meetingRooms__"}
          className={CSS.checkboxGroup}
          labelStyle={{ display: "flex", flexGrow: "1", marginRight: "0" }}
          name={i18n.spaceplanner.configurator.workspaceAreas.meetingRooms}
          checked={enableMeetingRooms}
          onChange={(event) => {
            wsa.meetingRooms.enabled = event.target.checked;
            setEnableMeetingRooms(wsa.meetingRooms.enabled);
            dispatch(setMeetingRoomsEnabled(wsa.meetingRooms.enabled));
            dispatch(setMeetingRoomReservationMethod(reservationTypeMeeting));
            checkEnabled();
          }}>
          <span className={CSS.meetingRoomHelp}>
            {i18n.spaceplanner.configurator.workspaceAreas.meetingRooms}
          </span>
        </Checkbox>
        {enableMeetingRooms && renderReservationMethod("meetingRooms")}
      </>
    );
  }
  const renderReservationMethod = (type: "hotels" | "meetingRooms") => {
    return (
      <FormControl className="i-reservation-methods">
        <Fieldset name={`reservationMethod_${type}`}>
          <Legend>{i18n.configurator.officeHoteling.reservationType.caption}</Legend>
          <Radio
            disabled={type === "hotels" ? !enableHotels : !enableMeetingRooms}
            value={`office365-${type}`}
            checked={type === "hotels"
              ? reservationTypeHotel === "office365"
              : reservationTypeMeeting === "office365"}
            onChange={() => {
              wsa[type].reservationMethod = "office365";
              if (type === "hotels") {
                setReservationTypeHotel("office365");
                dispatch(setHotelReservationMethod("office365"));
              } else {
                setReservationTypeMeeting("office365");
                dispatch(setMeetingRoomReservationMethod("office365"));
              }
            }}>
            {i18n.configurator.officeHoteling.reservationType.office365}
          </Radio>
          <Radio
            disabled={type === "hotels" ? !enableHotels : !enableMeetingRooms}
            value={`esri-${type}`}
            checked={type === "hotels"
              ? reservationTypeHotel === "esri"
              : reservationTypeMeeting === "esri"}
            onChange={() => {
              wsa[type].reservationMethod = "esri";
              if (type === "hotels") {
                setReservationTypeHotel("esri");
                dispatch(setHotelReservationMethod("esri"));
              } else {
                setReservationTypeMeeting("esri");
                dispatch(setMeetingRoomReservationMethod("esri"));
              }
            }}>
            {i18n.configurator.officeHoteling.reservationType.reservationLayer}
          </Radio>
        </Fieldset>
      </FormControl>
    )
  }
  const checkEnabled = () => {    
    dispatch(setWorkspacesEnabled(wsa.hotDesks.enabled || wsa.hotels.enabled || wsa.meetingRooms.enabled));
  }
  return (
    <div className={CSS.section}>
      <div key="header" className={CSS.formGroupHeader}>
        <span className={CSS.formGroupName}>
          {i18n.spaceplanner.configurator.workspaceAreas.caption}
        </span>
      </div>
      <div>{i18n.spaceplanner.configurator.workspaceAreas.description}</div>
      <div className={CSS.config}>
        {renderHotDeskSection()}
        {renderHotelSection()}
        {renderMeetingRoomSection()}
      </div>
    </div>
  )
}

export default WorkspaceAreas;