import Context from "../../context/Context";
 
export function getEquipmentFields(domainName) {
    const units = Context.getInstance().aiim.datasets.units;
    let hotelEquipmentFields = [];
    if(!units) return false;
    if(units && units.layer2D && units.layer2D.fields && units.layer2D.fields.length > 0) {
        const fields = units.layer2D.fields;
        fields.forEach((field) => {
            if(field.domain && field.domain.name && (field.domain.name.toLowerCase() === domainName.toLowerCase() 
                || field.domain.name.toLowerCase() === "dom_equipment")
                && field.domain.type === "coded-value"
                && (field.type === "small-integer"||field.type === "integer" || field.type === "long")) {
                hotelEquipmentFields.push(field.name);
            }
        })
        return hotelEquipmentFields;
    }
}

export function getAliasName(fieldName) {
    const units = Context.getInstance().aiim.datasets.units;
    if(!units) return false;
    if(units && units.layer2D && units.layer2D.fields && units.layer2D.fields.length > 0) {
      const fields = units.layer2D.fields;
      for(let i=0;i<fields.length;i++){
        const field = fields[i];
        if(field.name.toLowerCase() === fieldName.toLowerCase()) {
          return field.alias || fieldName;
        }
      }
    }
    return fieldName;
} 