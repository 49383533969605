import React from "react";
import { connect } from "react-redux";

import Context from "../../../context/Context";
import ChoiceDropdown from "../../common/ChoiceDropdown/ChoiceDropdown";
import MenuOption from "../../common/ChoiceDropdown/MenuOption";
import Rdx from "../../../redux/Rdx";
import * as component from "../../util/component";

const CSS = {
  main: "i-events-timefilter"
};

class TimeFilter extends React.Component {

  constructor(props) {
    super(props);
    const optionData = this.makeOptionData();
    this.state = component.newState({optionData: optionData});
    this.clicked = this.clicked.bind(this);
  }

  clicked(menuOption) {
    Rdx.setValue(this,Rdx.Keys.EVENTS_TIMEFILTER_OPTION,menuOption.props.value);
    if (this.props.onClick) {
      this.props.onClick("TimeFilter",menuOption);
    }
  }

  makeOptionData() {
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const optionData = [
      {
        label: i18n.events.timeFilter.any,
        value: null
      }
    ];
    const refDate = lib.dojo.stamp.fromISOString("T00:00:00");
    refDate.setFullYear(1970, 0, 1);
    for (let hour = 0 ; hour < 24; hour++) {
      const date = new Date(refDate.getTime());
			date.setHours(date.getHours() + hour);
      date.setFullYear(1970, 0, 1);
      const text = lib.dojo.locale.format(date,{selector:"time"});
      optionData.push({
        label: text,
        value: hour
      });
    }
    return optionData;
  }

  render() {
    let disabled = true;
    const opt = this.props.rdxDateOption;
    if (opt === "today" || opt === "tomorrow" || opt === "pickdate") {
      disabled = false;
    } else if (opt === "pickdate" && this.props.rdxDateSelected) {
      disabled = false;
    }
    const i18n = Context.getInstance().i18n;
    let caption = i18n.events.timeFilter.any;
    let activeValue = this.props.rdxOption;
    if (activeValue === undefined) activeValue = null;
    const optionData = this.state.optionData;
    const options = optionData.map(info => {
      let key = info.key;
      if (key === null) key = "k";
      else key = "k" + info.value;
      const checked = (activeValue === info.value);
      if (checked) caption = info.label;
      return (
        <MenuOption key={key} label={info.label} value={info.value}
          checked={checked} onClick={this.clicked} />
      );
    });
    return (
      <div className={CSS.main} aria-disabled = {disabled}>
        <ChoiceDropdown caption={caption} disabled={disabled}>
          {options}
        </ChoiceDropdown>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    rdxOption: Rdx.getValue(state,Rdx.Keys.EVENTS_TIMEFILTER_OPTION),
    rdxDateOption: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED)
  }
}

export default connect(mapStateToProps)(TimeFilter);
