import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { IOccupantsCriteria } from "../../common/types";
import OccupantsVM from "./OccupantsVM";
import OfficePlan from "../../../base/OfficePlan";
import * as component from "../../../../components/util/component";
import * as occupantsUtil from "./occupantsUtil";
import  { makeAvatarName } from "../../support/avatarUtil";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-bar";
import "@esri/calcite-components/dist/components/calcite-avatar";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-card";
import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-loader";
import "@esri/calcite-components/dist/components/calcite-pagination";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteAction,
  CalciteActionBar,
  CalciteAvatar,
  CalciteButton,
  CalciteCard,
  CalciteChip,
  CalciteIcon,
  CalciteInputText,
  CalciteLoader,
  CalcitePagination,
  CalcitePanel,
  CalciteTooltip
} from "@esri/calcite-components-react";

interface Props {
  criteria: IOccupantsCriteria;
  onCriteriaChange: (criteria: IOccupantsCriteria) => void
}

interface State {
  occupants: any,
  total: number,
  working: boolean;
}

class OccupantsList extends React.Component<Props, State> {

  asyncStamp;
  viewModel: OccupantsVM;

  constructor(props) {
    super(props);
    this.state = component.newState({
      occupants: null,
      total: 0,
      working: false
    });
    this.viewModel = new OccupantsVM();
  }

  componentDidMount() {
    this.query();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.criteria !== this.props.criteria) {
      this.query();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  query = async () => {
    try {
      this.setState({working: true})
      const stamp = this.asyncStamp = Date.now();
      const { criteria } = this.props;
      const result = await occupantsUtil.queryOccupants(criteria);
      //console.log("query.occupants.result",result)
      if (stamp === this.asyncStamp) {
        this.setState({
          occupants: result.occupants, 
          total: result.total,
          working: false
        })
      }
    } catch(ex) {
      console.error(ex)
    }
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height:"100%"}}>
        {this.renderOccupants()}
        <div style={{padding: "0.5rem"}}>
          <div style={{display: "flex", justifyContent: "center"}}>
            {this.renderPagination()}
          </div>
          {this.renderCount()}
          {false && this.renderActions()}
        </div>
      </div>
    )
  }

  renderActions() {
    const i18n = Context.instance.i18n;

    return (
      <div className="i-flex-between" style={{marginTop: "0.55rem"}}>
        <CalciteButton appearance="outline"
          onClick={e => {
          }}
        >
          {i18n.miniapps.unassignFrom}
        </CalciteButton>
        <CalciteButton
          onClick={e => {
          }}
        >
          {i18n.miniapps.assignTo}
        </CalciteButton>
      </div>
    )
  }

  renderAssignmentPill(assignment) {
    let icon, iconName;
    const iconStyle = {verticalAlign: "middle", margin: "0 0.2rem"};
    if (assignment.asnType === OfficePlan.SpaceAssignmentTypes.office) {
      iconName = "add-in";
    } else if (assignment.asnType === OfficePlan.SpaceAssignmentTypes.hotel) {
      iconName = "services";
      iconStyle.verticalAlign = "bottom"
    } else if (assignment.asnType === OfficePlan.SpaceAssignmentTypes.hotdesk) {
      iconName = "desk";
    }
    if (iconName) {
      icon = <CalciteIcon icon={iconName} scale="s" style={iconStyle} />;
    }

    let actionDel;
    if (assignment.asnType !== OfficePlan.SpaceAssignmentTypes.none) {
      actionDel = (
        <CalciteIcon icon="x" scale="s" 
          style={{verticalAlign: "middle", margin: "0 0.2rem", cursor: "pointer"}} 
          onClick={() => {
          }}
        />
      )
    }

    /*
    const tipId = "i-tip-ol-"+assignment.key;
    <CalciteTooltip label={assignment.asnName} referenceElement={tipId}>
      <span>{assignment.asnName}</span>
    </CalciteTooltip>
    */

    return (
      <div key={assignment.key}>
        <CalciteChip value={assignment.key} scale="s" style={{marginTop: "2px"}}>
          <div style={{display: "flex"}} title={assignment.asnName}>
            {icon}
            <span className="i-assignment-name">{assignment.asnName}</span>
            {false && actionDel}
          </div>
        </CalciteChip>
      </div>
    )
  }

  renderCount() {
    const i18n = Context.instance.i18n;
    const { criteria } = this.props;
    const { occupants, total, working } = this.state;
    const n = occupants ? occupants.length : 0;
    let v = i18n.miniapps.assignments.occupants.countPattern;
    v = v.replace("{num}",n).replace("{total}",total)
    return (
      <div style={{display: "flex", alignItems: "center", marginTop: "0.55rem"}}>
        {working && 
          <CalciteLoader className="i--small" label="Loading" scale="s" />
        }
        <span>{v}</span>
      </div>
    )
  }

  renderOccupant(occupant) {
    const sublist = [];
    occupant.assignments.forEach(assignment => {
      const p = this.renderAssignmentPill(assignment);
      if (p) sublist.push(p);
    })
    
    return (
      <CalciteCard key={occupant.key}>
        <div style={{display: "flex"}}>
          <CalciteAvatar full-name={makeAvatarName(occupant.name)} />
          <div style={{display: "flex", flexDirection: "column", margin: "0 0.5rem"}}>
            <div><strong>{occupant.name}</strong></div>
            <div>{sublist}</div>
          </div>
        </div>
      </CalciteCard>
    )
  }

  renderOccupants() {
    const { occupants } = this.state;
    if (occupants && occupants.length > 0) {
      const items = occupants.map(o => this.renderOccupant(o));
      return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", overflowY: "auto"}}>
          {items}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  renderPagination() {
    const { criteria } = this.props;
    const { total } = this.state;
    const start = criteria.start + 1;
    if (total > 0) {
      return (
        <CalcitePagination pageSize={criteria.num} startItem={start} totalItems={total} 
          onCalcitePaginationChange={e => {
            let v = e && e.target && e.target.startItem;
            if (typeof v === "number" && v !== start && v > 0) {
              v = v - 1;
              const c = JSON.parse(JSON.stringify(criteria));
              c.start = v;
              this.props.onCriteriaChange(c);
            }
          }}
        />
      )
    }
    return null;
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(OccupantsList);
