import styled from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import { CalciteH3 } from "calcite-react/Elements";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.palette.white};
  border-bottom: 1px solid ${props => props.theme.palette.lightestGray};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

const Title = styled(CalciteH3)`
  margin-bottom: 0;
  font-size: 1.33rem;
  line-height: 1.33rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.5rem;
`;

const SearchRow = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
`;

export { Container, TitleRow, Title, FilterRow, SearchRow };
