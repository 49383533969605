import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { IFilterCriteria } from "../../common/types";
import UniqueValues from "./UniqueValues";
import * as component from "../../../../components/util/component";
import * as filterUtil from "./filterUtil";
import * as whereUtil from "./whereUtil";

interface Props {
  filterCriteria: IFilterCriteria,
  layerType: string;
  onChange: (value?: string, where?: string) => void;
}

interface State {
  lastWhere?: string;
  uniqueValues: any;
}

class AssignmentTypeFilter extends React.Component<Props, State> {

  asyncStamp;

  constructor(props) {
    super(props);
    const fc = this.props.filterCriteria;
    const uniqueValues = filterUtil.getLevels(fc);
    this.state = component.newState({
      lastWhere: null,
      uniqueValues: []
    });
  }

  componentDidMount() {
    this.query();
  }

  componentDidUpdate(prevProps) {
    const fc = this.props.filterCriteria;
    if (prevProps.filterCriteria !== fc) {
      const { filterCriteria } = this.props;
      const where = whereUtil.makeFilterWhere(filterCriteria);
      if (where !== this.state.lastWhere) this.query();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  query = async () => {
    try {
      const stamp = this.asyncStamp = Date.now();
      const { layerType, filterCriteria } = this.props;
      const where = whereUtil.makeFilterWhere(filterCriteria);
      const uniqueValues = await filterUtil.queryOccupantAssignmentTypes(where);
      if (stamp === this.asyncStamp) {
        this.setState({
          uniqueValues,
          lastWhere: where
        });
      }
    } catch(ex) {
      console.error(ex)
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { uniqueValues} = this.state;
    const type = this.props.filterCriteria.assignmentType.type;
    const values = type ? [type] : [];
    if (uniqueValues) {
      return (
        <UniqueValues 
          label={i18n.miniapps.assignments.filter.assignmentType}
          uniqueValues={uniqueValues} 
          values={values}
          onChange={values => {
            const v = values[0] || null;
            let w;
            uniqueValues && uniqueValues.some(uv => {
              if (v === uv.value) {
                w = uv.where;
                return true;
              }
              return false;
            })
            this.props.onChange(v,w);
          }}
        />
      )
    }
    return null;
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(AssignmentTypeFilter);
