import React from "react";

import Context from "../../../context/Context";
//import { ModalController } from "../Modal";
import { ModalController } from "../../../common/components/Modal";
import PeopleList from "../Modal/PeopleList";
import * as assignPrompt from "./assignPrompt";
import * as component from "../../../components/util/component";

import { CalciteP } from "calcite-react/Elements";
import { Container } from "../../styles/Common/selectArea";

export default class SelectPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onSelectionChanged = selected => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(selected);
    }
  };

  render() {
    const prompt = assignPrompt.getPrompt(this.props.promptOptions);
    return (
      <Container>
        <CalciteP>{prompt.text}</CalciteP>
        <PeopleList
          handleSelection={this.onSelectionChanged}
          returnGeometry={this.props.returnGeometry}
        />
      </Container>
    );
  }

  static showModal(props) {
    let content, controller, okButton, selected, warningComponent;

    const onOK = obj => {
      if (props && props.onOK) {
        props.onOK(selected);
      }
    };

    let selectProps = {
      promptOptions: props && props.promptOptions,
      returnGeometry: props && props.returnGeometry,
      onSelectionChanged: sel => {
        selected = sel;
        if (okButton) {
          if (sel) okButton.enable();
          else okButton.disable();
        }
        if (selected && selected.feature && warningComponent) {
          const chk = props && (typeof props.capacity === "number");
          if (chk && (props.assignments >= props.capacity)) {
            let i18n = Context.instance.i18n
            let message = i18n.spaceplanner.capacity.atCapacityWarningPerson
            message = message.replace("{name}", selected.feature.attributes.KNOWNAS)
            message = message.replace("{capacity}", props.capacity)
            message = message.replace("{assignments}", props.assignments)
            message = props.assignments === 1 ?
              message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionSingular) :
              message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionPlural)
            warningComponent.setMessage(message)
          } else {
            warningComponent.setMessage(null)
          }
        }
      }
    };

    let modalProps = {
      title: props && props.title,
      showOKCancel: true,
      okLabel: Context.instance.i18n.spaceplanner.buttonLabel.assign,
      onOK: onOK,
      dialogStyle: { height: "65vh", width: "35vw" }
    };
    modalProps.onMountOKButton = btn => {
      okButton = btn;
      okButton.disable();
    };
    modalProps.onMountWarning = warning => {
      warningComponent = warning
    }

    content = <SelectPerson {...selectProps} />;
    controller = new ModalController({});
    controller.show(modalProps, content);
  }
}
