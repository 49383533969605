import { IApplyEditsOptions, IFeature } from "@esri/arcgis-rest-feature-layer";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findFieldName } from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";
import { getFacilitiesLayer, getLevelsLayer, getSitesLayer } from "../sourceUtil";
import { makeTransactionInfo } from "./transaction";
import { checkUniqueIds, cloneFeature } from "./transactionUtil";

const isValidArray = (arr: (IFeature | __esri.Graphic)[]) => Array.isArray(arr) && arr.length > 0;

export type UpdateFootprintTask = {
  info: {
    adds?: IFeature[] | __esri.Graphic[],
    deletes?: IFeature[] | __esri.Graphic[],
    type: "site" | "facility" | "level",
    undoUpdates?: IFeature[] | __esri.Graphic[],
    updates?: IFeature[] | __esri.Graphic[]
  }
}
export async function prepare(task: UpdateFootprintTask) {
  const info = task.info;
  const layer = info.type === "site"
    ? getSitesLayer()
    : info.type === "facility"
      ? getFacilitiesLayer()
      : getLevelsLayer();
  if (!layer.capabilities.editing.supportsGlobalId) {
    throw new Error("Levels does not support editing with GlobalID: " + layer.url);
  }
  const transactionInfo = makeTransactionInfo();
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  if (isValidArray(info.adds)) {
    const fldId = findFieldName(layer.fields, info.type === "site"
      ? FieldNames.SITE_ID
      : info.type === "facility"
        ? FieldNames.FACILITY_ID
        : FieldNames.LEVEL_ID);
    const uids = info.adds.map(f => f.attributes[fldId]).filter(x => !!x);
    const dupes = await checkUniqueIds(uids, layer, fldId);
    if (isValidArray(dupes) && dupes.length) {
      const { editor, filter: { fields } } = Context.getInstance().i18n;
      const type = info.type === "site"
        ? fields.site
        : info.type === "facility"
          ? fields.facility
          : fields.level;
      throw new Error(`${editor.footprints.errNonUniqueId
        .replace("{type}", type)
        .replace("{typeLower}", info.type)} [${dupes}]`);
    }
  }
  const makeFootprintEdits = async () => {
    const layerEdits: Omit<IApplyEditsOptions, "url"> & { id: number } = {
      id: layer.layerId,
      adds: [],
      updates: [],
      deletes: []
    };
    const undoLayerEdits: Omit<IApplyEditsOptions, "url"> & { id: number } = {
      id: layer.layerId,
      adds: [],
      updates: [],
      deletes: []
    };

    if (isValidArray(info.adds)) {
      layerEdits.adds.push(...info.adds.map(f => cloneFeature(f)));
      undoLayerEdits.deletes = ["?"];
    }
    if (isValidArray(info.updates)) {
      layerEdits.updates.push(...info.updates.map(f => cloneFeature(f)));
    }
    if (isValidArray(info.undoUpdates)) {
      undoLayerEdits.updates.push(...info.undoUpdates.map(f => cloneFeature(f)));
    }
    if (isValidArray(info.deletes)) {
      const objectIdField = layer.objectIdField;
      if (!objectIdField) {
        console.error("Unable to find ObjectID field on Levels layer, deletes won't be saved");
      }
      (layerEdits.deletes as string[]).push(...info.deletes.map(f => f.attributes[objectIdField]));
      undoLayerEdits.adds.push(...info.deletes.map(f => cloneFeature(f)));
    }
    if (layerEdits.adds.length || layerEdits.updates.length || layerEdits.deletes.length) {
      if (info.type === "site") {
        transactionInfo.siteEdits = true;
        transactionInfo.undo.siteEdits = true;
      } else if (info.type === "facility") {
        transactionInfo.facilityEdits = true;
        transactionInfo.undo.facilityEdits = true;
      } else {
        transactionInfo.levelEdits = true;
        transactionInfo.undo.levelEdits = true;
      }
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  makeFootprintEdits();

  return transactionInfo;
}