import React, {createRef} from "react";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import * as tsUtil from "../../../../util/tsUtil";
import { getFieldTooltip } from "../support/formUtil";


interface Props {
  changeable?: boolean,
  feature: __esri.Feature,
  onCreateForm?: (attributesComponent: Attributes, featureForm: __esri.FeatureForm) => void,
  options: any
}

interface State {
}

export default class Attributes extends React.Component<Props, State> {

  _coreNodeRef;
  _coreWidget;

  constructor(props) {
    super(props);
    this._coreNodeRef = createRef();
    this.state = component.newState({});
  }

  componentDidMount () {
    this._initWidget();
  }


  componentDidUpdate(prevProps) {
    const { changeable, feature } = this.props;
    if (changeable && feature !== prevProps.feature) {
      const node = this._coreNodeRef && this._coreNodeRef.current;
      if (node && this._coreWidget) {
        this._coreWidget.destroy();
        this._coreWidget = null;
        this._initWidget();
      }
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _initWidget() {
    if (this._coreWidget) return;
    const context = Context.instance;
    const node = this._coreNodeRef && this._coreNodeRef.current;
    if (node) {
      const subnode = document.createElement("span");
      node.appendChild(subnode);
      this._coreWidget = new context.lib.indoors.CustomFeatureForm({
        indoorsContext: context,
        tsUtil: tsUtil,
        container: subnode,
        feature: this.props.feature,
        indoorsOptions: {
          ...this.props.options,
          getTooltip: getFieldTooltip
        }
      });
      if (this.props.onCreateForm) {
        this.props.onCreateForm(this,this._coreWidget);
      }
    }
  }

  render() {
    return (
      <div className="i-feature-form-container">
        <div ref={this._coreNodeRef}></div>
      </div>
    );
  }

}
