/* A simple KVP section of the store (state.rdx) */
import React from "react";
import moment from "moment";
import { DispatchProp } from "react-redux";
import { AnyAction, ActionCreator, Dispatch } from "redux";
import {
  ISpacePlannerState,
  initialSpacePlannerState,
  SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID,
  SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE
} from "../spaceplanner/redux";
import { IEditorState, initialEditorState } from "../spaceplanner/components/Editor/redux";

let nextId = 0;

// actions
const RESET_ALL = "RESET_ALL";
const SET_VALUE = "SET_VALUE";
    
// state keys
const APP_STARTED = "APP_STARTED";
const SIDEBAR_ACTIVE_KEY = "SIDEBAR_ACTIVE_KEY";
const SIDEBAR_PANELS_EXPANDED = "SIDEBAR_PANELS_EXPANDED";
const INFOPANEL_ACTIVE_KEY = "INFOPANEL_ACTIVE_KEY";
const INFOPANEL_OPEN = "INFOPANEL_OPEN";
const EVENTS_DATEFILTER_OPTION = "EVENTS_DATEFILTER_OPTION";
const EVENTS_DATEFILTER_DATESELECTED = "EVENTS_DATEFILTER_DATESELECTED";
const EVENTS_TIMEFILTER_OPTION = "EVENTS_TIMEFILTER_OPTION";
const EVENTS_TYPEFILTER_OPTION = "EVENTS_TYPEFILTER_OPTION";
const EVENTS_TYPEFILTER_OPTIONS = "EVENTS_TYPEFILTER_OPTIONS";
const BOOK_DATEFILTER_OPTION = "BOOK_DATEFILTER_OPTION";
const BOOK_DATEFILTER_DATESELECTED = "BOOK_DATEFILTER_DATESELECTED";
const BOOK_TAB_SELECTED = "BOOK_TAB_SELECTED";
const BOOK_DATEFILTER_STARTSELECTED = "BOOK_DATEFILTER_STARTSELECTED";
const BOOK_DATEFILTER_ENDSELECTED = "BOOK_DATEFILTER_ENDSELECTED";
const BOOK_TIMEFILTER_STARTSELECTED = "BOOK_TIMEFILTER_STARTSELECTED";
const BOOK_TIMEFILTER_ENDSELECTED = "BOOK_TIMEFILTER_ENDSELECTED";
const BOOK_ALLDAY = "BOOK_ALLDAY";
const BOOK_CHECKIN = "BOOK_CHECKIN";
const BOOK_CHECKOUT = "BOOK_CHECKOUT";
const BOOK_RECURRENCE_ENABLED = "BOOK_RECURRENCE_ENABLED";
const BOOK_RECURRENCE_ENDDATE = "BOOK_RECURRENCE_ENDDATE";
const BOOK_RECURRENCE_DAYS = "BOOK_RECURRENCE_DAYS";
const BOOK_RECURRENCE_TYPE = "BOOK_RECURRENCE_TYPE";
const BOOK_RECURRENCE_INTERVAL = "BOOK_RECURRENCE_INTERVAL";
const SITE_FILTERS = "SITE_FILTERS";
const SITE_DROPDOWN = "SITE_DROPDOWN";
const SPACETYPES_DROPDOWN = "SPACETYPES_DROPDOWN";
const SPACETYPE_FILTERS = "SPACETYPE_FILTERS";
const AREA_FILTERS = "AREA_FILTERS";
const AREA_FILTERS_MEETING_ROOM = "AREA_FILTERS_MEETING_ROOM";
const RESERVE_FOR_OTHERS = "RESERVE_FOR_OTHERS";
const DEFAULT_AREA = "DEFAULT_AREA";
const EQUIPMENT_DROPDOWN = "EQUIPMENT_DROPDOWN";
const EQUIPMENT_FILTERS = "EQUIPMENT_FILTERS";
const BOOK_FILTERED_RESULTS = "BOOK_FILTERED_RESULTS";
const BOOK_FILTERED_RESULTS_NO_EMAIL = "BOOK_FILTERED_RESULTS_NO_EMAIL";
const BOOK_SORT_SORTBY = "SORT_SORTBY";
const BOOK_SORT_SORTDIR = "SORT_SORTDIR";
const BOOK_WAS_SORT_INIT = "BOOK_WAS_SORT_INIT";
const ACTIVE_HOTDESK_AREA_ID = "ACTIVE_HOTDESK_AREA_ID";
const ACTIVE_HOTEL_AREA_ID = "ACTIVE_HOTEL_AREA_ID";
const PLAN_CAN_REDO = "PLAN_CAN_REDO";
const PLAN_CAN_UNDO = "PLAN_CAN_UNDO";
const COMMENTS_ACTIVE_PIN = "COMMENTS_ACTIVE_PIN";

const SPACEPLANNER_ACTIVE_ASSETBAR_SECTION = "SPACEPLANNER_ACTIVE_ASSETBAR_SECTION";
const SPACEPLANNER_ACTIVE_SECTION = "SPACEPLANNER_ACTIVE_SECTION";
const SPACEPLANNER_WORKSPACES_ENABLED = "SPACEPLANNER_WORKSPACES_ENABLED";
const SPACEPLANNER_HOTDESKS_ENABLED = "SPACEPLANNER_HOTDESKS_ENABLED";
const SPACEPLANNER_HOTELS_ENABLED = "SPACEPLANNER_HOTELS_ENABLED";
const SPACEPLANNER_MEETINGROOMS_ENABLED = "SPACEPLANNER_MEETINGROOMS_ENABLED";
const SPACEPLANNER_HOTEL_RESERVATION_METHOD = "SPACEPLANNER_HOTEL_RESERVATION_METHOD";
const SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD = "SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD";

export interface IRootState {
  rdx: {
    [APP_STARTED]?: boolean
  } & IViewerState & ISpacePlannerState & IEditorState
}

export interface IViewerState {
  [EVENTS_DATEFILTER_OPTION]?: string,
  [BOOK_DATEFILTER_OPTION]?: string,
  [BOOK_TAB_SELECTED]?: string,
  [BOOK_DATEFILTER_STARTSELECTED]?: Date,
  [BOOK_DATEFILTER_ENDSELECTED]?: Date,
  [BOOK_DATEFILTER_DATESELECTED]?: Date,
  [BOOK_TIMEFILTER_STARTSELECTED]?: moment.Moment,
  [BOOK_TIMEFILTER_ENDSELECTED]?: moment.Moment,
  [BOOK_ALLDAY]?: boolean,
  [BOOK_CHECKIN]?: string,
  [BOOK_CHECKOUT]?: string,
  [BOOK_RECURRENCE_ENABLED]?: boolean,
  [BOOK_RECURRENCE_ENDDATE]?: Date,
  [BOOK_RECURRENCE_DAYS]?: number[],
  [BOOK_RECURRENCE_TYPE]?: "daily" | "weekly",
  [BOOK_RECURRENCE_INTERVAL]?: number,
  [SITE_FILTERS]?: string | null,
  [SITE_DROPDOWN]?: string[],
  [SPACETYPES_DROPDOWN]?: string[],
  [SPACETYPE_FILTERS]?: string[],
  [AREA_FILTERS]?: string[],
  [AREA_FILTERS_MEETING_ROOM]?: string[],
  [RESERVE_FOR_OTHERS]?: boolean,
  [DEFAULT_AREA]?: string[] | null,
  [EQUIPMENT_DROPDOWN]?: string[],
  [EQUIPMENT_FILTERS]?: string[],
  [BOOK_FILTERED_RESULTS]?: string[],
  [BOOK_FILTERED_RESULTS_NO_EMAIL]?: string[],
  [BOOK_SORT_SORTBY]?: string,
  [BOOK_SORT_SORTDIR]?: string,
  [BOOK_WAS_SORT_INIT]?: boolean,
  [SPACEPLANNER_ACTIVE_SECTION]?: string,
  [SPACEPLANNER_HOTDESKS_ENABLED]?: boolean,
  [SPACEPLANNER_HOTELS_ENABLED]?: boolean,
  [SPACEPLANNER_MEETINGROOMS_ENABLED]?: boolean,
  [SPACEPLANNER_WORKSPACES_ENABLED]?: boolean,
  [SPACEPLANNER_HOTEL_RESERVATION_METHOD]?: boolean,
  [SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD]?: boolean
}

export const setValue = (key: string, value): AnyAction => ({
  type: Rdx.Types.SET_VALUE,
  payload: { key, value }
});

export const resetAll = (): AnyAction => ({
  type: Rdx.Types.RESET_ALL
});

export const createAction: ActionCreator<AnyAction> = (key: string, value) =>
  ({ type: Rdx.Types.SET_VALUE, payload: { key, value } });

export interface IRdxProps {
  setRdxValue: (key: string, value: any) => void
}
export const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRdxValue: (key: string, value: any) => dispatch(setValue(key, value))
});
export default class Rdx {

  static store;

  static Keys = {
    APP_STARTED,
    SIDEBAR_ACTIVE_KEY,
    SIDEBAR_PANELS_EXPANDED,
    INFOPANEL_ACTIVE_KEY,
    INFOPANEL_OPEN,
    EVENTS_DATEFILTER_OPTION,
    EVENTS_DATEFILTER_DATESELECTED,
    EVENTS_TIMEFILTER_OPTION,
    EVENTS_TYPEFILTER_OPTION,
    EVENTS_TYPEFILTER_OPTIONS,

    BOOK_DATEFILTER_OPTION,
    BOOK_DATEFILTER_DATESELECTED,
    BOOK_TAB_SELECTED,

    BOOK_DATEFILTER_STARTSELECTED,
    BOOK_DATEFILTER_ENDSELECTED,
    BOOK_TIMEFILTER_STARTSELECTED,
    BOOK_TIMEFILTER_ENDSELECTED,
    BOOK_ALLDAY,
    BOOK_CHECKIN,
    BOOK_CHECKOUT,
    BOOK_RECURRENCE_ENABLED,
    BOOK_RECURRENCE_ENDDATE,
    BOOK_RECURRENCE_DAYS,
    BOOK_RECURRENCE_TYPE,
    BOOK_RECURRENCE_INTERVAL,

    SITE_FILTERS,
    SITE_DROPDOWN,
    SPACETYPES_DROPDOWN,
    SPACETYPE_FILTERS,
    AREA_FILTERS,
    AREA_FILTERS_MEETING_ROOM,
    RESERVE_FOR_OTHERS,
    DEFAULT_AREA,
    EQUIPMENT_DROPDOWN,
    EQUIPMENT_FILTERS,
    BOOK_FILTERED_RESULTS,
    BOOK_FILTERED_RESULTS_NO_EMAIL,
    BOOK_SORT_SORTBY,
    BOOK_SORT_SORTDIR,
    BOOK_WAS_SORT_INIT,

    ACTIVE_HOTDESK_AREA_ID,
    ACTIVE_HOTEL_AREA_ID,

    PLAN_CAN_REDO,
    PLAN_CAN_UNDO,

    COMMENTS_ACTIVE_PIN,

    SPACEPLANNER_ACTIVE_ASSETBAR_SECTION,
    SPACEPLANNER_ACTIVE_SECTION,
    SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID,
    SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE,
    SPACEPLANNER_HOTDESKS_ENABLED,
    SPACEPLANNER_HOTELS_ENABLED,
    SPACEPLANNER_MEETINGROOMS_ENABLED,
    SPACEPLANNER_WORKSPACES_ENABLED,
    SPACEPLANNER_HOTEL_RESERVATION_METHOD,
    SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD

  };

  static Types = {
    RESET_ALL,
    SET_VALUE
  };

  static Actions = {

    resetAll: (): AnyAction => ({
      type: Rdx.Types.RESET_ALL
    }),

    setValue: (key: string, value): AnyAction => ({
      type: Rdx.Types.SET_VALUE,
      payload: { key, value }
    })

  };

  static Selectors = {

    getValue: (state: IRootState, section: string, key: string) => {
      if (state[section]) return state[section][key];
    }

  }

  static Reducers = {

    rdx: (state: IViewerState & ISpacePlannerState & IEditorState = {}, action: AnyAction) => {
      let newState: IViewerState & ISpacePlannerState & IEditorState;
      switch (action.type) {
        case Rdx.Types.RESET_ALL:
          newState = Object.assign({}, Rdx.getInitialState().rdx);
          Object.keys(state).forEach(k => {
            if (k && k.indexOf("cfg-") === 0) {
              newState[k] = state[k];
            }
          });
          return newState;
        case Rdx.Types.SET_VALUE:
          newState = Object.assign({}, state);
          newState[action.payload.key] = action.payload.value;
          return newState;
        default:
          return state
      }
    }

  }
  
  static getInitialState(): IRootState {
    const initialViewerState: IViewerState = {
      [EVENTS_DATEFILTER_OPTION]: "today",
      [BOOK_DATEFILTER_OPTION]: "any",
      [BOOK_TAB_SELECTED]: "self",
      [BOOK_DATEFILTER_STARTSELECTED]: new Date(),
      [BOOK_DATEFILTER_ENDSELECTED]: new Date(),
      [BOOK_DATEFILTER_DATESELECTED]: null,
      [BOOK_TIMEFILTER_STARTSELECTED]: null,
      [BOOK_TIMEFILTER_ENDSELECTED]: null,
      [BOOK_ALLDAY]: true,
      [BOOK_CHECKIN]: null,
      [BOOK_CHECKOUT]: null,
      [BOOK_RECURRENCE_ENABLED]: false,
      [BOOK_RECURRENCE_ENDDATE]: null,
      [BOOK_RECURRENCE_DAYS]: [],
      [BOOK_RECURRENCE_TYPE]: "weekly",
      [BOOK_RECURRENCE_INTERVAL]: 1,
      [SITE_FILTERS]: null,
      [SITE_DROPDOWN]: [],
      [SPACETYPES_DROPDOWN]: [],
      [SPACETYPE_FILTERS]: [],
      [AREA_FILTERS]: [],
      [AREA_FILTERS_MEETING_ROOM]: [],
      [RESERVE_FOR_OTHERS]: false,
      [DEFAULT_AREA]: null,
      [EQUIPMENT_DROPDOWN]: [],
      [EQUIPMENT_FILTERS]: [],
      [BOOK_FILTERED_RESULTS]: [],
      [BOOK_FILTERED_RESULTS_NO_EMAIL]: [],
      [BOOK_SORT_SORTBY]: "name",
      [BOOK_SORT_SORTDIR]: "asc",
      [BOOK_WAS_SORT_INIT]: false,
      [SPACEPLANNER_ACTIVE_SECTION]: "plan",
      [SPACEPLANNER_HOTDESKS_ENABLED]: true,
      [SPACEPLANNER_HOTELS_ENABLED]: true,
      [SPACEPLANNER_MEETINGROOMS_ENABLED]: true,
      [SPACEPLANNER_WORKSPACES_ENABLED]: false,
      [SPACEPLANNER_HOTEL_RESERVATION_METHOD]: null,
      [SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD]: null
    };
    return {
      rdx: {
        [APP_STARTED]: false,
        ...initialViewerState,
        ...initialSpacePlannerState,
        ...initialEditorState()
      }
    };
  }

  static getNextComponentId() {
    return "component_" + nextId++;
  }

  static getNextId() {
    return nextId++;
  }

  static getValue(state: IRootState, key) {
    if (!state && Rdx.store) {
      state = Rdx.store.getState();
    }
    return Rdx.Selectors.getValue(state, "rdx", key);
  }

  static resetAll(store) {
    const action = Rdx.Actions.resetAll();
    if (!store) store = Rdx.store;
    store.dispatch(action);
  }
  
  static setValue(component: React.Component & { props: DispatchProp }, key: string, value: any) {
    const action = Rdx.Actions.setValue(key, value);
    if (!component && Rdx.store) {
      const dispatch = Rdx.store.dispatch;
      dispatch(action);
    } else {
      const dispatch = component.props.dispatch;
      dispatch(action);
    }
  }

}
