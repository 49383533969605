import React from 'react';
import moment from 'moment';

// aiim.datasets
import FieldNames from '../../../aiim/datasets/FieldNames';

// aiim.tracking
import TrackingLocation from '../../../aiim/tracking/TrackingLocation';

// aiim.util
import { getAttributeValue } from '../../../aiim/util/aiimUtil';

// components.util
import { newState, nextTmpId } from '../../../components/util/component';
import Icons from '../../../components/util/Icons';

// context
import Context from '../../../context/Context';
import Topic from '../../../context/Topic';

// util
import { assignmentsFound, getLKL, queryTrackingInfo, Assignment, ReservationType, PersonFeature, PersonAssignments } from '../../../util/multipleAssignmentsUtil';
import { getLayerFeatureText } from '../../../util/tsUtil';

// Calcite
import HomeIcon from "calcite-ui-icons-react/HomeIcon";
import FloorPlanIcon from "calcite-ui-icons-react/FloorPlanIcon";
import ServicesIcon from "calcite-ui-icons-react/ServicesIcon";
import WorkspaceAreaIcon from "calcite-ui-icons-react/AppsIcon";
import MeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";
import { formatDate } from '../../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

const CSS = {
  multipleAssignmentsModal: "i-multiple-assignments-modal",
  assignmentsList: "i-assignments-list",
  assignment: "i-assignment",
  primaryAssignment: "i-primary-assignment",
  assignmentTitle: "i-assignment-title",
  assignmentSubtitle: "i-assignment-subtitle",
  assignmentIcon: "i-assignment-icon",
  flexRow: "i-flex-row",
  flexColumns: "i-flex-columns",
  area: "i-area-assignment"
};
export interface IMultipleAssignmentsProps {
  assignments?: PersonAssignments,
  closePopup?: () => void,
  directions?: boolean,
  directionsCallback?: (result: CustomSearchResult) => void,
  hasLKL?: boolean,
  noRoutableLocations?: boolean,
  personFeatures: __esri.Graphic[],
  primary?: Assignment,
  pastReservation?: Assignment[],
  upcomingReservations: Assignment[]
}
interface IMultipleAssignmentsState {
  trackingInfo: any
}
interface CustomSearchResult extends __esri.SearchResult {
  sourceIndex: number,
  reservation: __esri.Graphic,
  unit: __esri.Graphic
}

export default class MultipleAssignments extends React.Component<IMultipleAssignmentsProps, IMultipleAssignmentsState> {
  _trackingInfoPromise = null;

  constructor(props) {
    super(props);
    this.state = newState({
      trackingInfo: null
    });
  }

  componentDidMount() {
    this.getRealTimeInfo();
  }

  getRealTimeInfo() {
    const { personFeatures } = this.props;
    this._trackingInfoPromise = queryTrackingInfo(personFeatures)
      .then((trackingInfo) => {
        this.setState({ trackingInfo });
      });
  }

  renderAssignment(assignment: Assignment, type: ReservationType) {
    const content = this.renderAssignmentContent(assignment, type);
    if (!content) {
      return null;
    }

    return (
      <li key={nextTmpId(`${type}-`)}>
        <button
          className={CSS.assignment}
          onClick={() => this._onAssignmentClick(assignment, type)}
        >
          {content}
        </button>
      </li>
    );
  }

  renderPrimaryAssignment(assignment: Assignment, type: ReservationType) {
    const content = this.renderAssignmentContent(assignment, type);

    return (
      <div className={CSS.primaryAssignment}>
        {content}
      </div>
    );
  }

  renderAssignmentContent(assignment: Assignment, type: ReservationType) {
    switch (type) {
      case "lkl":
        return this.renderLastKnownLocation();
      case "unit":
        return this.renderUnit(assignment);
      case "workspaceArea":
      case "hotel":
      case "hotdesk":
      case "homeoffice":
        return this.renderArea(assignment, type);
      case "ongoing":
      case "upcoming":
        return this.renderReservation(assignment, type);
      case "past":
        return this.renderReservation(assignment, type);
      default:
        return null;
    }
  }

  renderLastKnownLocation() {
    const i18n = Context.getInstance().i18n;
    const { trackingInfo } = this.state;
    const summary = trackingInfo && trackingInfo.maSummaryText;

    return (
      <div className={CSS.flexRow}>
        {Icons.personPin()}
        <div className={CSS.flexColumns}>
          <h3>{i18n.search.lastSharedLocation}</h3>
          {summary && (<h4>{summary}</h4>)}
        </div>
      </div>
    );
  }

  renderUnit(assignment) {
    const { unit } = assignment;
    if (!unit) {
      return null;
    }

    const attributes = unit.attributes;

    // Get title
    const title = getAttributeValue(attributes, FieldNames.NAME);

    // Get location info
    const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
    const levelData = units && units.getLevelData(unit);
    const level = levelData && levelData.levelName;
    const facility = levelData && levelData.facilityName;
    const site = levelData && levelData.siteName;

    // Format subtitle
    const subtitleLeading = [];
    if (level) {
      subtitleLeading.push(level);
    }
    if (facility) {
      subtitleLeading.push(facility);
    }

    let subtitle = null;
    if (site && subtitleLeading.length > 0) {
      subtitle = [subtitleLeading.join(", "), site].join(" \u2027 ");
    } else if (site && subtitleLeading.length === 0) {
      subtitle = site;
    } else if (!site && subtitleLeading.length > 0) {
      subtitle = subtitleLeading.join(", ");
    }

    return (
      <div className={CSS.flexRow}>
        <FloorPlanIcon />
        <div className={CSS.flexColumns}>
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
        </div>
      </div>
    );
  }

  renderArea(assignment: Assignment, type: ReservationType) {
    const { upcomingReservations } = this.props;
    const { area } = assignment;
    if (!area) {
      return null;
    }

    const attributes = area.attributes;

    // Don't show if there is a reservation for this area
    const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);
    const existingAreaBooking = upcomingReservations.some((r) => r.areaId === areaId);
    if (!!existingAreaBooking) {
      return null;
    }

    const title = getAttributeValue(attributes, FieldNames.AREA_NAME);
    const icon = type === "homeoffice" ? <HomeIcon /> : <WorkspaceAreaIcon />

    return (
      <div className={`${CSS.flexRow} ${CSS.area}--${type}`}>
        {icon}
        <div className={CSS.flexColumns}>
          <h3>{title}</h3>
        </div>
      </div>
    );
  }

  renderReservation(assignment: Assignment, type: ReservationType) {
    const { reservation, unit, area } = assignment;
    if (!reservation || !unit) {
      return null;
    }

    const attributes = unit.attributes;

    // Get title
    const unitName = getAttributeValue(attributes, FieldNames.NAME);
    const areaName = getAttributeValue(area && area.attributes, FieldNames.AREA_NAME);
    let title = null;
    if (unitName && areaName) {
      title = [unitName, areaName].join(" \u2027 ");
    } else {
      title = unitName;
    }
    
    const hasAreaId = !!(getAttributeValue(attributes, FieldNames.UNITS_AREA_ID));
    const asnType = getAttributeValue(attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const icon = (!hasAreaId || asnType === "meeting room") ? <MeetingRoomIcon /> : <ServicesIcon />

    // Get time info
    const startTime = getAttributeValue(reservation.attributes, FieldNames.START_TIME);
    const endTime = getAttributeValue(reservation.attributes, FieldNames.END_TIME);
    const allDay = getAttributeValue(reservation.attributes, FieldNames.ALL_DAY);
    const subtitle = formatDate(
      moment(startTime),
      moment(endTime),
      allDay === 1,
      { isMultipleAssignments: true }
    );
    const subtitleCls = type === "ongoing" ? `${CSS.assignmentSubtitle}--ongoing` : CSS.assignmentSubtitle;
    let timezone = null;

    if (typeof startTime === "number") {
      const dt = new Date(startTime);
      const djLocale = Context.getInstance().lib.dojo.locale;
      timezone = djLocale.format(dt,{selector:"time",timePattern:"z"});
      timezone = " ("+timezone+")";
    }
    
    return (
      <div className={CSS.flexRow}>
        {icon}
        <div className={CSS.flexColumns}>
          <h3>{title}</h3>
          <div className={subtitleCls}><h4>{subtitle}{timezone}</h4></div>
        </div>
      </div>
    );
  }

  render() {
    const {
      assignments,
      primary,
      directions,
      pastReservation,
      upcomingReservations,
      noRoutableLocations
    } = this.props;
    const personFeatures = (Array.isArray(this.props.personFeatures) && this.props.personFeatures) || [];
    const i18n = Context.getInstance().i18n;
    if (!assignments && (!upcomingReservations || upcomingReservations.length === 0) && !noRoutableLocations) {
      return null;
    }

    const primaryAssignment = (primary && primary.type)
      ? this.renderPrimaryAssignment(primary, primary.type)
      : null;

    // We need person features to get the last shared location. If the only other locations are the
    // reservations, add a person feature to this array so we can find the last shared location as well
    if (personFeatures.length === 0 && upcomingReservations && upcomingReservations.length > 0) {
      personFeatures.push(upcomingReservations[0].person.feature as __esri.Graphic);
    }

    // Make components for all assignments
    const lkl = this.props.hasLKL && getLKL(personFeatures);
    const reservations = upcomingReservations.map((reservation) => this.renderAssignment(reservation, reservation.type));
    const units = this._sort(assignments.units, FieldNames.NAME).map((unit) => this.renderAssignment(unit, "unit"));
    const workspaceAreas = this._sort(assignments.workspaceAreas, FieldNames.AREA_NAME).map((w) => this.renderAssignment(w, "workspaceArea"));
    const reserPast = pastReservation.map((reservation) => this.renderAssignment(reservation, "past"));

    // Put them into the list: LKL > Units > Workspace Areas
    const options = [];
    if (!!lkl) {
      options.push(this.renderAssignment({ person: lkl }, "lkl"));
    }
    this._pushArray(options, reserPast);
    this._pushArray(options, reservations);
    this._pushArray(options, units);
    if (!directions) {
      this._pushArray(options, workspaceAreas);
      if (assignments.homeOffice) {
        this._pushArray(options, [this.renderAssignment(assignments.homeOffice, "homeoffice")]);
      }
    }

    const label = primaryAssignment
      ? i18n.search.otherLocations
      : options.length > 0
        ? i18n.search.chooseLocation
        : i18n.directions.noRoutableAssignments;

    const list = (
      <ul className={CSS.assignmentsList}>
        {options}
      </ul>
    );

    return (
      <div className={CSS.multipleAssignmentsModal}>
        {primaryAssignment}
        <h2>{label}</h2>
        {options.length > 0 && list}
      </div>
    );
  }

  //----------------------------------
  //
  //  Private Methods
  //
  //----------------------------------

  _onAssignmentClick = async (assignment: Assignment, type: ReservationType) => {
    const { directions, directionsCallback, closePopup } = this.props;
    const { trackingInfo } = this.state;
    if (type === "lkl" && !trackingInfo && this._trackingInfoPromise) {
      await this._trackingInfoPromise;
    }

    const locationFeature = this._getAssignmentFeature(assignment, type);
    const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
    const source = people && people.getSource();
    if (!assignment) {
      return;
    }

    if (directions && typeof directionsCallback === "function") {
      const template = source.searchTemplate;
      const displayField = source.displayField;
      const person = assignment.person && "feature" in assignment.person
        ? assignment.person.feature
        : assignment.person as __esri.Graphic;
      const name = getLayerFeatureText(person, template, displayField);
      const extent = locationFeature && locationFeature.geometry && locationFeature.geometry.extent;
      const feature = type === "lkl" && trackingInfo ? trackingInfo.feature : person;
      const target = type === "lkl" && trackingInfo ? trackingInfo.feature : locationFeature;

      const searchResult: CustomSearchResult = {
        extent,
        feature,
        target,
        name,
        sourceIndex: source.index,
        reservation: assignment.reservation,
        unit: assignment.unit
      };

      directionsCallback(searchResult);
      if (closePopup && typeof closePopup === "function") {
        closePopup();
      }
    } else {
      this._showSearchResult(assignment, type);
    }
  }

  _getAssignmentFeature(assignment, type) {
    switch (type) {
      case "unit":
        return assignment.unit;
      case "workspaceArea":
      case "hotel":
      case "hotdesk":
        return assignment.area;
      case "lkl":
      default:
        return null;
    }
  }

  _showSearchResult(assignment: Assignment, type: ReservationType) {
    const { closePopup } = this.props;
    const { trackingInfo } = this.state;

    const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
    const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
    const source = people && people.getSource();
    let searchResult: Partial<PersonFeature> = assignment.person && "feature" in assignment.person
      ? assignment.person
      : { feature: assignment.person as __esri.Graphic, name: "" };

    // If the location is a reservation, zoom to the unit but show the person info panel
    if (type === "ongoing" || type === "upcoming" || type === "past") {
      const { unit } = assignment;
      if (unit && unit.geometry && searchResult.feature) {
        const unitsFloorField = units && units.layer2D && units.layer2D.floorInfo && units.layer2D.floorInfo.floorField;
        const levelId = unitsFloorField && getAttributeValue(unit.attributes, unitsFloorField);
        const peopleFloorField = people && people.layer2D && people.layer2D.floorInfo && people.layer2D.floorInfo.floorField;

        // Set the geometry and level id
        searchResult = { feature: this._cloneFeature(searchResult.feature) };
        searchResult.feature.geometry = unit.geometry;
        if (levelId && peopleFloorField) {
          searchResult.feature.attributes[peopleFloorField] = levelId;
        }
      }
    }
    if (!source) {
      return;
    }

    // Set result name
    if (searchResult && searchResult.feature) {
      const name = source.getTitle(searchResult.feature);
      searchResult.name = name;
    }

    const zoom = type === "unit" || type === "ongoing" || type === "upcoming" || type === "past";
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult,
      onlyRealTime: type === "lkl",
      trackingInfo: trackingInfo,
      zoom,
      highlight: true,
      trackRecent: true,
      showLocations: { person: assignment.person }
    });

    if (closePopup && typeof closePopup === "function") {
      closePopup();
    }
  }

  _sort = (array, sortField) => {
    if (!array || array.length === 0) {
      return [];
    }

    return array
      .sort((a, b) => {
        const nameA = getAttributeValue(a.attributes, sortField);
        const nameB = getAttributeValue(b.attributes, sortField);
        if (!nameA && !nameB) {
          return 0;
        } else if (nameA && !nameB) {
          return 1;
        } else if (!nameA && nameB) {
          return -1;
        }
        return (nameA.toUpperCase() < nameB.toUpperCase()) ? -1 : (nameA.toUpperCase() > nameB.toUpperCase()) ? 1 : 0;
      });
  }

  _pushArray = (parent, array) => {
    array.forEach((value) => {
      parent.push(value);
    });
  }

  _cloneFeature = (feature) => {
    if (typeof feature.clone === "function") {
      return feature.clone();
    }

    const cloned = { attributes: null, geometry: null };
    cloned.attributes = Object.assign({}, feature.attributes);
    if (feature.geometry) {
      if (typeof feature.geometry.toJSON === "function") {
        cloned.geometry = feature.geometry.toJSON()
      } else {
        cloned.geometry = feature.geometry;
      }
    }

    return cloned;
  }
}
