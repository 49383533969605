// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import * as component from "../../util/component";

// Component specific modules (Component-styled, etc.)

// App components

// Third-party components (buttons, icons, etc.)
import clockwiseIcon from "../../../assets/clockwise.svg";
import counterclockwiseIcon from "../../../assets/anti-clockwise.svg";

// JSON

// CSS
const CSS = {
  main: "i-compass",
  wrapper: "i-compass-wrapper",
  clockwise: "i-compass-clockwise",
  counterclockwise: "i-compass-counterclockwise",
  container: "i-compass-container"
};

export default class Compass extends Component {

  _coreNode;
  _coreWidget;
  _promise
  _rotating = false;

  componentDidMount() {
    try {
      if (this._coreNode && this.props.view) {
        const lib = Context.getInstance().lib;
        this._coreWidget = new lib.esri.Compass({
          container: this._coreNode,
          view: this.props.view
        });
      }
    } catch(ex) {
      console.error("Error initializing Compass",ex);
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  clockwiseClicked = event => {
    this.rotate(true);
  }

  clockwisePressed = event => {
    if (event && event.keyCode === 13) this.rotate(true);
  }

  counterclockwiseClicked = event => {
    this.rotate(false);
  }

  counterclockwisePressed = event => {
    if (event && event.keyCode === 13) this.rotate(false);
  }

  render() {
    if (!this.props.view) return null;
    const isSpacePlanner = Context.instance.appMode.isSP_or_FPE();
    const containerNode = (<div key="container" className={CSS.container} ref={this.setCoreNode}></div>)
    const i18n = Context.getInstance().i18n;
    return (
      <div key="wrapper" className={CSS.wrapper}>
        <span key="counterClockwise" className={CSS.counterclockwise}
          role="button" tabIndex="0"
          onClick={this.counterclockwiseClicked}
          onKeyUp={this.counterclockwisePressed}
          title={i18n.map.compass.counterclockwise}
          ><img src={counterclockwiseIcon} alt="counterclockwise"></img></span>
        {isSpacePlanner && containerNode}
        <span key="clockwise" className={CSS.clockwise}
          role="button" tabIndex="0"
          onClick={this.clockwiseClicked}
          onKeyUp={this.clockwisePressed}
          title={i18n.map.compass.clockwise}
          ><img src={clockwiseIcon} alt="clockwise"></img></span>
         {!isSpacePlanner && containerNode}
      </div>
    );
  }

  rotate = clockwise => {
    if (this._rotating || !this.props.view) return;
    this._rotating = true;

    let promise;
    try {
      const view = this.props.view;
      const target = view.center;
      let rotation = view.viewpoint.rotation;
      if (clockwise) rotation = rotation + 90;
      else rotation = rotation - 90;
      const gotoTarget = {
        target: target, 
        rotation: rotation
      }
      if (typeof view.viewpoint.scale === "number") {
        gotoTarget.scale = view.viewpoint.scale;
      }

      const options = {duration: 1000,easing: "out-back"};
      // if (view.type === "3d" && view.camera) {
      //   let heading = (360 - rotation);
      //   let camera = view.camera.clone();
      //   let position = camera.position;
      //   //camera.heading = 360 - rotation;
      //   promise = this._promise = view.goTo({target: target, heading: heading}, options);
      // } else {
      //   promise = this._promise = view.goTo({target: target, rotation: rotation}, options);
      // }
      promise = this._promise = view.goTo(gotoTarget, options);
      promise.then(() => {
        this._rotating = false;
        this._promise = null;
        // console.log("view.viewpoint.rotation",view.viewpoint.rotation);
        // if (view.camera) {
        //   let c = view.camera;
        //   let p = view.camera.position;
        //   console.log("h=",c.heading,"t=",c.tilt,"x=",p.x,"y=",p.y,"z=",p.z);
        // }
      }).catch(ex2 => {
        this._rotating = false;
        this._promise = null;
        console.error("Error rotating view",ex2);
      });

    } catch(ex) {
      this._rotating = false;
      this._promise = null;
      console.error("Error rotating view",ex);
    }

    setTimeout(() => {
      if (this._rotating && this._promise === promise) {
        // JSAPI issue sometimes the view.goTo doesn't resolve/reject
        this._rotating = false;
        this._promise = null;
      }
    },2000);
  }

  setCoreNode = node => {
    this._coreNode = node;
  }

}
