import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import Toggle from "../../common/Toggle/Toggle";
import Topic from "../../../../src/context/Topic";
import * as component from "../../util/component";
import * as val from "../../../util/val";
import * as idleTimeOut from "../../../util/idleTimeOut";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label",
  helptext: "i-helptext",
  toggleGroup: "i-form-group i-toggle-group"
};

export default class IdleTime extends React.Component {

  constructor(props) {
    super(props);
    const applicationReset = Context.instance.config.applicationReset;
    this.state = component.newState({
      resetEnabled: applicationReset.enabled,
      resetTimeoutMillis: applicationReset.timeoutMillis,
      resetPopupMillis: applicationReset.popupMillis,
      idleTimeValidation: true,
      popupTimeValidation: true,
      idleTimeErrorMessage:"",
      invalidNumberError:""
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeIdleSeconds = (evt) => {
    const applicationResetDefault = Context.instance.config.applicationReset;
    const i18n = Context.instance.i18n;
    const cfg = this.props.cfg;
    let v, text = evt.target.value.trim();
    if (text.length > 0) v = Number(text);
    if (val.isFiniteNum(v)&& v>=1) {
      v = Math.round(v);
      if (v < 1) v = (applicationResetDefault.timeoutMillis / 1000);
      v = v * 1000;
      this.setState(state => {
        return {
          resetTimeoutMillis: v,
          idleTimeValidation: true,
          idleTimeErrorMessage: ""
        }
      });
      cfg.getConfigurable("applicationReset").timeoutMillis = v;
      idleTimeOut.start();
    }
    else if(!val.isFiniteNum(v)){
      this.setState({
        idleTimeErrorMessage: i18n.configurator.kiosk.invalidNumberError,
        idleTimeValidation: false
      })
    }
    else if(v<1){
      this.setState({
        idleTimeErrorMessage: i18n.configurator.kiosk.negativeNumberError,
        idleTimeValidation: false
      })
    }
  }

  onChangePopupSeconds = (evt) => {
    const applicationResetDefault = Context.instance.config.applicationReset;
    const i18n = Context.instance.i18n;
    const cfg = this.props.cfg;
    let v, text = evt.target.value.trim();
    if (text.length > 0) v = Number(text);
    if (val.isFiniteNum(v) && v>=1) {
      v = Math.round(v);
      if (v < 1) v = (applicationResetDefault.popupMillis / 1000);
      v = v * 1000;
      this.setState(state => {
        return {
          resetTimeoutMillis: v,
          popupTimeValidation: true,
          popupTimeErrorMessage:""
        }
      });
      cfg.getConfigurable("applicationReset").popupMillis = v;
      idleTimeOut.start();
    }
    else if(!val.isFiniteNum(v)){
      this.setState({
        popupTimeErrorMessage: i18n.configurator.kiosk.invalidNumberError,
        popupTimeValidation: false
      })
    }
    else if(v<1){
      this.setState({
        popupTimeErrorMessage: i18n.configurator.kiosk.negativeNumberError,
        popupTimeValidation: false
      })
    }
  }

  onChangeResetEnabled = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        resetEnabled: checked
      }
    });
    cfg.getConfigurable("applicationReset").enabled = checked;
    idleTimeOut.start();
  }

  render() {
    const i18n = Context.instance.i18n;
    const resetEnabled = this.state.resetEnabled;
    const resetTimeoutMillis = this.state.resetTimeoutMillis;
    const resetPopupMillis = this.state.resetPopupMillis;
    const idleSeconds = resetTimeoutMillis / 1000;
    const popupSeconds = resetPopupMillis / 1000;

    return (
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.idleTimeout.caption}</span>
          <HelpTip text={i18n.configurator.idleTimeout.help}/>
        </div>

        <div key="areset" className={CSS.toggleGroup}>
          <label id={"cfg-areset-enabled-label"} htmlFor={"cfg-areset-enabled"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.idleTimeout.appReset.enabled}</div>
            <Toggle className="spaceplanner" id={"cfg-areset-enabled"} checked={resetEnabled}
              onChange={this.onChangeResetEnabled}/>
          </label>
        </div>

        <div key="areset-idle" className={CSS.formGroup}>
          <label id={"cfg-areset-idle-label"} htmlFor={"cfg-areset-idle"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.kiosk.kioskReset.idleTime}</div>
            <input id={"cfg-areset-idle"} type="number" min="1"
              disabled={!resetEnabled}
              defaultValue={idleSeconds} onChange={this.onChangeIdleSeconds}/>
            <div className="i-issue">{this.state.idleTimeErrorMessage}</div>
          </label>
        </div>

        <div key="areset-popup" className={CSS.formGroup}>
          <label id={"cfg-areset-popup-label"} htmlFor={"cfg-areset-popup"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.kiosk.kioskReset.popupTime}</div>
            <input id={"cfg-areset-popup"} type="number" min="1"
              disabled={!resetEnabled}
              defaultValue={popupSeconds} onChange={this.onChangePopupSeconds}/>
            <div className="i-issue">{this.state.popupTimeErrorMessage}</div>
          </label>
        </div>
      </div>
    );

  }

}
