import React from "react";
import { connect } from "react-redux";
import "react-toggle/style.css";
import Rdx from "../../../../src/redux/Rdx";
import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import ReactToggle from "react-toggle";

import { Topic } from "../../../util/tsUtil";
import Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import * as workspaceReservationUtil from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import { stringFormatter } from "../../../util/formatUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";

//Calcite
import { FormControlLabel } from 'calcite-react/Form'
import Tooltip from "calcite-react/Tooltip";
import Checkbox from "calcite-react/Checkbox";
import Alert, { AlertMessage } from 'calcite-react/Alert';
import TextField from 'calcite-react/TextField';


const CSS = {
    section: "i-form-group i-section",
    formGroup: "i-form-group",
    formGroupHeader: "i-form-group-header",
    formGroupName: "i-form-group-name",
    toggleLabel: "i-toggle-label",
    sidebarPanelOpen: "i-sidebar-panel-open",
    reservationTitle: "i-reservation-title"
};

const validLicenseTypes = ["indoors", "indoorsspaces"];

class OfficeHoteling extends React.Component {

    hasHotelingUnits = true;

    constructor(props) {
      super(props);
      const workspaceReservation = Context.getInstance().config.workspaceReservation;
      const wsInfo = workspaceReservationUtil.getWorkspaceInfo();
      const ctx = Context.getInstance();
      this.state = {
        enableMeeting: workspaceReservation.enableMeeting,
        enableHotel: workspaceReservation.enableHotel,
        enableHotelLabel: workspaceReservation.enableHotelLabel,
        enableMeetingLabel: workspaceReservation.enableMeetingLabel,
        reservationLabelHotel: workspaceReservation.reservationLabelHotel,
        reservationLabelMeeting: workspaceReservation.reservationLabelMeeting,
        reservationLayerId: workspaceReservation.reservationLayerId,
        reservationTypeHotel: workspaceReservation.reservationTypeHotel,
        reservationTypeMeeting: workspaceReservation.reservationTypeMeeting,
        reservationsLyrTitle: wsInfo && wsInfo.reservationsLyrTitle,
        appId: workspaceReservation.appId,
        tenantId: workspaceReservation.tenantId,
        isSingleTenant: workspaceReservation.isSingleTenant,
        licensed: validLicenseTypes.includes(ctx.orgLicenseType),
        enableGoogleCalendar: workspaceReservation.enableGoogleCalendar,
        enableOutlookCalendar: workspaceReservation.enableOutlookCalendar,
        allowMeetingCheckIn: !!workspaceReservation.allowMeetingCheckIn
      }
    }

    componentDidMount() {
      if (this.props.onMount) {
        this.props.onMount(this);
      }
      this.findHotelingUnits().then(() => this.init("componentMounted"));
  
      component.own(this,
        [
          Topic.subscribe(Topic.ReloadWorkspace, () => {
            this.findHotelingUnits()
          }),
  
          Topic.subscribe(Topic.ViewsReloaded, (params) => {
            this.init("viewsReloaded", params);
          }),
  
          Topic.subscribe(Topic.UpdateHotelingConfig, () => {
            this.init("componentMounted");
          })
        ]
      )
    }

    applyState =()=> {
      const workspaceReservation = Context.getInstance().config.workspaceReservation;
      if (this.state.enableHotel) {
        workspaceReservation.enableHotel = this.state.enableHotel;
        workspaceReservation.reservationTypeHotel = this.state.reservationTypeHotel;
      } else {
        workspaceReservation.enableHotel = false;
        workspaceReservation.reservationTypeHotel = null;
      }
      if (this.state.enableMeeting) {
        workspaceReservation.enableMeeting = this.state.enableMeeting;
        workspaceReservation.reservationTypeMeeting = this.state.reservationTypeMeeting;
      } else {
        workspaceReservation.enableMeeting = null;
        workspaceReservation.reservationTypeMeeting = null;
      }

      if (workspaceReservation.enableMeeting && this.state.allowMeetingCheckIn && (workspaceReservation.reservationTypeMeeting === "esri")) {
        workspaceReservation.allowMeetingCheckIn = true;
      } else {
        workspaceReservation.allowMeetingCheckIn = false;
      }
      
      if ((this.state.enableHotel && this.state.reservationTypeHotel === "office365") || 
          (this.state.enableMeeting && this.state.reservationTypeMeeting === "office365")) {
        workspaceReservation.appId = this.state.appId;
        workspaceReservation.isSingleTenant = this.state.isSingleTenant;
        workspaceReservation.tenantId = this.state.tenantId;
      } else {
        workspaceReservation.appId = null;
        workspaceReservation.isSingleTenant = false;
        workspaceReservation.tenantId = null;
      }

      if ((this.state.enableHotel && this.state.reservationTypeHotel === "esri") || 
          (this.state.enableMeeting && this.state.reservationTypeMeeting === "esri")) {
        workspaceReservation.reservationLayerId = this.state.reservationLayerId;
      } else {
        workspaceReservation.reservationLayerId = null;
      }
      const { enableHotelLabel, enableMeetingLabel, reservationLabelHotel, reservationLabelMeeting } = this.state;
      workspaceReservation.enableHotelLabel = enableHotelLabel;
      workspaceReservation.reservationLabelHotel = enableHotelLabel ? reservationLabelHotel : null;
      workspaceReservation.enableMeetingLabel = enableMeetingLabel;
      workspaceReservation.reservationLabelMeeting = enableMeetingLabel ? reservationLabelMeeting : null;

      if (this.state.enableGoogleCalendar) {
        workspaceReservation.enableGoogleCalendar = this.state.enableGoogleCalendar;
      } else {
        workspaceReservation.enableGoogleCalendar = false;
      }
      
      if (this.state.enableOutlookCalendar) {
        workspaceReservation.enableOutlookCalendar = this.state.enableOutlookCalendar;
      } else {
        workspaceReservation.enableOutlookCalendar = false;
      }

      Topic.publish(Topic.ReloadWorkspace, {});
      Topic.publish(Topic.RenderSidebar, {});
    }

    checkMissingAreasFields=(info)=> {
      let errorMsgHotel;
      const i18n = Context.instance.i18n;
  
      const hasAreaId = info.hasAreaId;
      const hasAreaType = info.hasAreaType;
      const hasAreaName = info.hasAreaName;
      
      const templateSingular = i18n.configurator.officeHoteling.reservationType.missingAreasTableReqField;
      const template = i18n.configurator.officeHoteling.reservationType.missingAreasTableReqFields;
  
      if(!hasAreaId || !hasAreaType || !hasAreaName) {
        if (!hasAreaId && !hasAreaType && !hasAreaName) 
          errorMsgHotel = stringFormatter (template, {fields: [FieldNames.AREA_ID.toUpperCase(), 
                    FieldNames.AREA_NAME.toUpperCase(), FieldNames.AREA_TYPE.toUpperCase()].join(", ")});
        else if (!hasAreaId && !hasAreaName) 
          errorMsgHotel = stringFormatter (template, {fields: [FieldNames.AREA_ID.toUpperCase(), 
            FieldNames.AREA_NAME.toUpperCase()].join(", ")});
        else if (!hasAreaId && !hasAreaType) 
          errorMsgHotel = stringFormatter (template, {fields: [FieldNames.AREA_ID.toUpperCase(), 
            FieldNames.AREA_TYPE.toUpperCase()].join(", ")});
        else if (!hasAreaType && !hasAreaName)
          errorMsgHotel = stringFormatter (template, {fields: [FieldNames.AREA_TYPE.toUpperCase(), 
            FieldNames.AREA_NAME.toUpperCase()].join(", ")});
        else if (!hasAreaId) errorMsgHotel = stringFormatter (templateSingular, {field: FieldNames.AREA_ID.toUpperCase()});
        else if (!hasAreaType) errorMsgHotel = stringFormatter (templateSingular, {field: FieldNames.AREA_TYPE.toUpperCase()});
        else errorMsgHotel = stringFormatter (templateSingular, {field: FieldNames.AREA_NAME.toUpperCase()});
      }
      return errorMsgHotel;
    }

    closeBookWorkspacePanel(activeKey) {
      if(!activeKey || (activeKey && activeKey === this.props.rdxSidebarActiveKey)) {
        Rdx.setValue(this, Rdx.Keys.SIDEBAR_ACTIVE_KEY, null);
        document.body.classList.remove(CSS.sidebarPanelOpen);
        Context.instance.views.toggleClickHandlers("resume");
      }
    }

    defaultHotelMeetingSetting =()=> {
      const wsInfo = workspaceReservationUtil.getWorkspaceInfo();
      if (!wsInfo) return;

      // check for reservations layer hotel
      if (wsInfo.isValidLicense && wsInfo.hasReservationsLayer && wsInfo.isLayerEditable && wsInfo.unitsHasAreaId && wsInfo.hasAreasTable) {
        this.setState({
          enableHotel: true,
          reservationTypeHotel: "esri"
        }, this.applyState)
      } else {
        this.setState({
          enableHotel: false,
          reservationTypeHotel: "office365"
        }, this.applyState)
      }

      // check for reservations layer meeting
      if (wsInfo.isValidLicense && wsInfo.hasReservationsLayer && wsInfo.isLayerEditable && wsInfo.unitsHasReservationMethod) {

        this.setState({
          enableMeeting: true,
          reservationTypeMeeting: "esri"
        }, this.applyState)
      } else {
        this.setState({
          enableMeeting: false,
          reservationTypeMeeting: "office365"
        }, this.applyState)
      }
    }

    findHotelingUnits = () => {
      // The map or data has likely changed, which means we need to re-query for hotels
      const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
      if (!units) {
        return Promise.resolve();
      }
  
      const wsCfg = this.props.cfg.getConfigurable("workspaceReservation");
      const { reservationTypeHotel } = wsCfg;
  
      return units.queryAllHotels()
        .then(result => {
          const scheduleUnits = [];
          if (result && result.features) {
            result.features.forEach(unit => {
              const scheduleEmail = aiimUtil.getAttributeValue(unit.attributes, FieldNames.SCHEDULE_EMAIL)
              const areaId = aiimUtil.getAttributeValue(unit.attributes, FieldNames.AREA_ID)
              const useType = aiimUtil.getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE)
  
              if (
                reservationTypeHotel === "esri" &&
                areaId &&
                useType
              ) {
                scheduleUnits.push(unit);
              } else if (
                reservationTypeHotel === "office365" &&
                scheduleEmail &&
                areaId &&
                useType
              ) {
                scheduleUnits.push(unit);
              }
            });
            this.hasHotelingUnits = scheduleUnits.length > 0;
          } else {
            this.hasHotelingUnits = false;
          }
        })
        .catch(error => {
          console.error(error);
        });
    }

    init = (initType, params) => {
      // initType "componentMounted" | "viewsReloaded"

      const wsInfo = workspaceReservationUtil.getWorkspaceInfo() || {};

      this.setState({
        reservationLayerId : wsInfo.reservationLayerId,
        reservationsLyrTitle: wsInfo.reservationsLyrTitle
      }, this.applyState)

        
      if(initType === "componentMounted") {
        if(!Context.instance.session.appDataHadWorkspaceConfig) this.defaultHotelMeetingSetting();
        else this.setValuesForDisabledToggles();
      }
      
      if (initType === "viewsReloaded") {
        if(params && params.type === "webMap") {
          this.defaultHotelMeetingSetting();
        } 
      }

      let viewsReloaded = initType === "viewsReloaded";
      const promise = viewsReloaded ? this.findHotelingUnits() : Promise.resolve();

      promise.then(() => {
        setTimeout(()=> {
          Topic.publish(Topic.ReloadWorkspace, {});
        }, 1);
      })
    }  

    onChangeHotelRadio =(val)=> {
      this.closeBookWorkspacePanel("bookWorkspace");
      this.setState({
        reservationTypeHotel: val
      }, this.applyState)
    }

    onChangeMeetingRadio =(val)=> {
      this.closeBookWorkspacePanel("meetingRooms");
      this.setState ({
        reservationTypeMeeting: val
      }, this.applyState)
    }

    onToggleMeeting =(event)=> {
      this.closeBookWorkspacePanel(event.target.name);
      this.setState({
        enableMeeting: !this.state.enableMeeting
      }, this.applyState)
    }

    onToggleHotel =(event)=> {
      this.closeBookWorkspacePanel(event.target.name);
      this.setState({
        enableHotel: !this.state.enableHotel
      }, this.applyState)
    }

    onAppIdTextChange = (e) => {
      if (!e.target.value) this.closeBookWorkspacePanel("meetingRooms");
      if (!e.target.value) this.closeBookWorkspacePanel("bookWorkspace");
      this.setState({
        appId: e.target.value
      }, this.applyState)
    }
  
    onAppIdTextBlur = (e) =>{
      Office365.getInstance().updateAppId(e.target.value);
      Topic.publish(Topic.ReloadWorkspace, {});
    }
  
    onTenantIdTextChange = (e) => {
      this.setState({
        tenantId: e.target.value
      }, this.applyState)
    }
  
    onTenantIdTextBlur = (e) => {
      Office365.getInstance().updateTenantId(e.target.value);
      Topic.publish(Topic.ReloadWorkspace, {});
    }
  
    onSingleTenantCheckChanged = (e) => {
      this.setState({
        isSingleTenant: !this.state.isSingleTenant
      }, this.applyState)
  
      const workspaceReservation = Context.getInstance().config.workspaceReservation;  
      if(workspaceReservation.tenantId) {
        Office365.getInstance().updateTenantId(workspaceReservation.tenantId);
        Topic.publish(Topic.ReloadWorkspace, {});
      }
    }
    
    onCalendarExportChange = (e) => {
      // support existing applications
      const uncheck = this.state.enableGoogleCalendar === undefined;
      const option = e.target.value;
      if (option === 'google') {
        this.setState({
          enableGoogleCalendar: uncheck ? false : !this.state.enableGoogleCalendar
        }, this.applyState);
      } else {
        this.setState({
          enableOutlookCalendar: uncheck ? false : !this.state.enableOutlookCalendar
        }, this.applyState);
      }
    }

    renderOffice365Sections() {
      const i18n = Context.instance.i18n;
      const isSingleTenant = this.state.isSingleTenant;
      const appId = this.state.appId || "";
      const tenantId = this.state.tenantId || "";
      
      let appIdStr = i18n.more.bookWorkspace.appIdSubTitle;
      if ((this.state.enableHotel && this.state.reservationTypeHotel === "office365") 
          && (this.state.enableMeeting && this.state.reservationTypeMeeting === "office365")) {
        appIdStr = i18n.more.bookWorkspace.appIdSubTitle;
      } else if (this.state.enableMeeting && this.state.reservationTypeMeeting === "office365") {
        appIdStr = i18n.more.bookWorkspace.appIdSubTitleMeetingRooms;
      } else if (this.state.enableHotel && this.state.reservationTypeHotel === "office365") {
        appIdStr = i18n.more.bookWorkspace.appIdSubTitleHotel;
      }

      return (
        <div>
          <div className="i-hoteling-appid" style={{marginTop: '1.5rem'}}>
            <div className={CSS.formGroup}>
                <div key="header" className={CSS.formGroupHeader}>
                  <FormControlLabel
                    className="i-appid-label"
                    htmlFor="configurator-office-appid"
                  >
                    {i18n.more.bookWorkspace.appId}
                  </FormControlLabel>
                  <HelpTip text={i18n.more.bookWorkspace.newappIdHelpTip}/>
                </div>
                <div className="i--a">
                  <p>{appIdStr}</p>
                </div>
                <TextField
                  id="configurator-office-appid"
                  placeholder={i18n.more.bookWorkspace.appIdPlaceholder}
                  value={appId}
                  onChange={this.onAppIdTextChange}
                  onBlur={this.onAppIdTextBlur}
                />
              </div>
              <div className="i-hoteling-tenantinfo">
                <Checkbox
                  checked={isSingleTenant}
                  onChange={this.onSingleTenantCheckChanged}
                >
                  {i18n.more.bookWorkspace.newtenantIdCheckbox}
                </Checkbox>
                <div className="i-hoteling-tenantid">
                  <div key="header2" className={CSS.formGroupHeader}>
                    <FormControlLabel
                      className="i-appid-label"
                      htmlFor="configurator-office-tenantid"
                    >
                      {i18n.more.bookWorkspace.tenantId}
                    </FormControlLabel>
                    <HelpTip text={i18n.more.bookWorkspace.newtenantIdHelptip}/>
                  </div>
                  <div className="i--a">
                      <p>{i18n.more.bookWorkspace.newtenantIdSubTitle}</p>
                  </div>
                  <TextField
                    id="configurator-office-tenantid"
                    placeholder={i18n.more.bookWorkspace.tenantIdPlaceholder}
                    value={tenantId}
                    onChange={this.onTenantIdTextChange}
                    onBlur={this.onTenantIdTextBlur}
                    disabled={!isSingleTenant}
                  />
                </div>
              </div>
            </div>
        </div>
      );
    }

    renderHeader() {
      const i18n = Context.instance.i18n;
      return (
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.officeHoteling.newcaption}</span>
          <HelpTip text={i18n.more.bookWorkspace.newhotelingHelpTip}/>
        </div>
      );
    }

    
    renderLicenseWarning() {
      return (
        <Alert yellow showIcon>
          <AlertMessage>{Context.getInstance().i18n.messages.noIndoorsSpacesSubscription}</AlertMessage>
        </Alert>
      );
    }

    renderRadioBtnMeetingSection =()=> {
      const i18n = Context.instance.i18n;
      const meeting365Errors = this.renderOffice365ErrorsMeeting();
      const meetingEsriErrors = this.renderMeetingEsriErrors();

      return (
        <div style={{marginLeft: "1rem"}}>
          <div className="i-radio-item-hotelBookOptions">
              <ul className="i-form-legend-hotelBookOptions">
                  <li key="office365Meeting" className="i-radio-item-hotelBookOptions">
                      <input
                      type="radio"
                      name="bookworkspaceRadioMeeting"
                      value="office365Meeting"
                      checked={this.state.reservationTypeMeeting === "office365"}
                      onChange={()=> this.onChangeMeetingRadio ("office365")}
                      /> {i18n.configurator.officeHoteling.reservationType.office365}
                  </li>
                  {this.state.reservationTypeMeeting === "office365" && meeting365Errors && (
                    <p className="i-hotel-config i-issue">{meeting365Errors}</p>)}
                  <li key="esriMeeting" className="i-radio-item-hotelBookOptions">
                      <input
                      type="radio"
                      name="bookworkspaceRadioMeeting"
                      value="esriMeeting"
                      checked={this.state.reservationTypeMeeting === "esri"}
                      onChange={()=> this.onChangeMeetingRadio ("esri")}
                      /> {i18n.configurator.officeHoteling.reservationType.reservationLayer}
                      {(this.state.reservationTypeMeeting === "esri") &&
                        <div className="i-reservation-checkinout">
                          <input
                            style={{width: '1rem'}}
                            type="checkbox"
                            checked={this.state.allowMeetingCheckIn}
                            onChange={(e)=> {
                              this.setState({
                                allowMeetingCheckIn: !this.state.allowMeetingCheckIn
                              },this.applyState);
                            }}
                          />
                          {/* @todo i18n move to resources.js */}
                          <span>Enable Check In</span>
                        </div>
                      }
                  </li>
                  {this.state.reservationTypeMeeting === "esri" && (<p className={CSS.reservationTitle}>{this.state.reservationsLyrTitle}</p>)}
                  {this.state.reservationTypeMeeting === "esri" && meetingEsriErrors && (
                    <p className="i-hotel-config i-issue"> {meetingEsriErrors} </p>
                  )}
              </ul>
          </div>
        </div>
      )
    }

    renderRadioBtnHotelSection =()=> {
      const i18n = Context.instance.i18n;
      const office365Errors = this.renderHotelOffice365Errors();
      const officeEsriErrors = this.renderHotelEsriErrors();

      return (
        <div style={{marginLeft: "1rem"}}>
          <div className="i-radio-item-hotelBookOptions">
            <ul className="i-form-legend-hotelBookOptions">
              <li className="i-radio-item-hotelBookOptions">
                  <input
                      type="radio"
                      name="bookworkspaceRadioHotel"
                      value="office365Hotel"
                      checked={this.state.reservationTypeHotel === "office365"}
                      onChange={()=> this.onChangeHotelRadio ("office365")}
                  /> {i18n.configurator.officeHoteling.reservationType.office365}
              </li>
              {this.state.reservationTypeHotel === "office365" && office365Errors && (
                <p className="i-hotel-config i-issue"> {office365Errors} </p>
              )}
              <li className="i-radio-item-hotelBookOptions">
                  <input
                      type="radio"
                      name="bookworkspaceRadioHotel"
                      value="esriHotel"
                      checked={this.state.reservationTypeHotel === "esri"}
                      onChange={()=> this.onChangeHotelRadio ("esri")}
                  /> {i18n.configurator.officeHoteling.reservationType.reservationLayer}
              </li>
              {this.state.reservationTypeHotel === "esri" && (<p className={CSS.reservationTitle}>{this.state.reservationsLyrTitle}</p>)}
              {this.state.reservationTypeHotel === "esri" && officeEsriErrors && (
                 <p className="i-hotel-config i-issue"> {officeEsriErrors} </p>
              )}
            </ul>
          </div>
        </div>
      )
    }
    
    renderCalendarExport = () => {
      const i18n = Context.instance.i18n;
      const helpTipText = i18n.configurator.officeHoteling.calendarExportOptions.helpTip.replaceAll('{break}', '<br />');
      return (
        <>
          <div key='calendarExportHeader' className={CSS.formGroupHeader} style={{marginTop: '1.5rem'}}>
              <FormControlLabel
                className='i-appid-label'
                htmlFor='configurator-reservations-calendar'
                style={{fontWeight: '500'}}
              >
                {i18n.configurator.officeHoteling.calendarExportOptions.caption} 
              </FormControlLabel>
              <HelpTip text={helpTipText} />
          </div>
          <div className={CSS.formGroup} style={{marginLeft: '1rem'}}>
          <div className="i-radio-item-hotelBookOptions">
            <ul className="i-form-legend-hotelBookOptions">
              <li className="i-radio-item-hotelBookOptions">
                  <input
                      style={{width: '1rem'}}
                      type="checkbox"
                      name="google"
                      value="google"
                      checked={this.state.enableGoogleCalendar}
                      onChange={this.onCalendarExportChange}
                  /> {i18n.configurator.officeHoteling.calendarExportOptions.google}
              </li>
              <li className="i-radio-item-hotelBookOptions">
                  <input
                      style={{width: '1rem'}}
                      type="checkbox"
                      name="outlook"
                      value="outlook"
                      checked={this.state.enableOutlookCalendar}
                      onChange={this.onCalendarExportChange}
                  /> {i18n.configurator.officeHoteling.calendarExportOptions.outlook}
              </li>
            </ul>
          </div>
          </div>
        </>
      )
    }
    renderCustomLabelSection(isHotel) {
      const i18n = Context.instance.i18n;
      const enabled = isHotel ? this.state.enableHotelLabel : this.state.enableMeetingLabel;
      const helpTip = isHotel
        ? i18n.configurator.officeHoteling.reservationType.customLabelHotelHelpTip
        : i18n.configurator.officeHoteling.reservationType.customLabelMeetingHelpTip;
      const defaultValue = isHotel ? i18n.more.bookWorkspace.caption : i18n.meetingRooms.caption;
      const value = isHotel
        ? this.state.reservationLabelHotel ?? ""
        : this.state.reservationLabelMeeting ?? "";
      const MAX_LABEL_LENGTH = 20;
      const pattern = i18n.item.categoryTypePattern
        .replace("{category}", value.length)
        .replace("{type}", MAX_LABEL_LENGTH);
      return (
        <>
          <div className="i-checkbox-hotelBookOptions">
            <Checkbox
              checked={enabled}
              onChange={() => {
                this.setState(isHotel
                  ? { enableHotelLabel: isHotel ? !enabled : enabled }
                  : { enableMeetingLabel: isHotel ? enabled : !enabled },
                  this.applyState
                );
              }}>
              {i18n.configurator.officeHoteling.reservationType.customLabel}
            </Checkbox>           
            <HelpTip text={helpTip}/>
          </div>
          <div className="i-textbox-hotelBookOptions">
            <TextField
              disabled={!enabled}
              value={enabled ? value : defaultValue}
              required
              maxLength={MAX_LABEL_LENGTH}
              onChange={(e) => {
                const value = e.target.value?.trim().length ? e.target.value : null;
                const state = isHotel
                  ? { reservationLabelHotel: value }
                  : { reservationLabelMeeting: value };
                this.setState(state, () => {
                  if ((value === null) || value?.length <= MAX_LABEL_LENGTH) {
                    this.applyState();
                  }
                });
              }}
            />
            <div style={{fontSize: "0.8125rem"}}>{pattern}</div>
          </div>
        </>
      );
    }
    renderHotelSection() {
      return (
        <>
          {this.renderCustomLabelSection(true)}
          {this.renderRadioBtnHotelSection()}
        </>
      );
    }
    renderMeetingSection() {
      return (
        <>
          {this.renderCustomLabelSection(false)}
          {this.renderRadioBtnMeetingSection()}
        </>
      );
    }
    renderToggleSection =()=> {
      const i18n = Context.instance.i18n;
      let { enableMeeting, enableHotel, licensed } = this.state;

      return (
        <div key="officeHoteling" className={CSS.formGroup}>
            <label id={"cfg-kreset-enabled-label"} htmlFor={"cfg-kreset-enabled"}>
              <div className={CSS.toggleLabel}>
                {i18n.configurator.officeHoteling.workspaceOptions.newofficeHoteling}</div>
                <ReactToggle
                  id={"cfg-officehoteling-hotels"}
                  checked={enableHotel}
                  icons={false}
                  name="bookWorkspace" 
                  onChange={this.onToggleHotel} />
            </label>
            {this.state.enableHotel && licensed && this.renderHotelSection()}
            <label id={"cfg-kreset-enabled-label"} htmlFor={"cfg-kreset-enabled"} style={{marginTop: "1.5rem"}}>
              <div className={CSS.toggleLabel}>{i18n.configurator.officeHoteling.workspaceOptions.meetingRooms}</div>
                <ReactToggle
                  id={"cfg-officehoteling-meetingRooms"}
                  checked={enableMeeting}
                  icons={false}
                  name="meetingRooms"
                  onChange={this.onToggleMeeting}/>
            </label>
            {this.state.enableMeeting && licensed && this.renderMeetingSection()}
            {(this.state.enableHotel || this.state.enableMeeting) && !licensed && this.renderLicenseWarning()}
        </div>
      )
    }

    renderHotelOffice365Errors=()=> {
      const info = workspaceReservationUtil.getWorkspaceInfo();
      if (!info) return null;

      const i18n = Context.instance.i18n;
      let unitErr = null, occupantsErr = null, areaErr = null, missingLyrTable = null;
      const hasAreasTable = info.hasAreasTable;
      const hasUnitsLayer = info.hasUnitsLayer;
      const hasOccupantsLayer = info.hasOccupantsLayer;
  
      const templateSingular = i18n.more.bookWorkspace.missingLyrTable;
      const template = i18n.more.bookWorkspace.missingLyrTables;
  
      let temp = [];
      if(!hasUnitsLayer) temp.push(i18n.more.bookWorkspace.unitsLayer);
      if(!hasOccupantsLayer) temp.push(i18n.more.bookWorkspace.peopleLayer);
      if(!hasAreasTable) temp.push(i18n.more.bookWorkspace.areasTable);
      if(!hasUnitsLayer || !hasOccupantsLayer || !hasAreasTable) {
        if(temp.length === 1) missingLyrTable = stringFormatter(templateSingular, {item: temp.join(", ")});
        if(temp.length > 1) missingLyrTable = stringFormatter(template, {items: temp.join(", ")});
      }
  
      if (hasUnitsLayer) {
        const unitHasScheduleEmail = info.unitsHasScheduleEmail;
        if (!unitHasScheduleEmail) {
          const template = i18n.configurator.officeHoteling.reservationType.missingUnitsLyrReqField;
          unitErr = stringFormatter (template, {fields: FieldNames.SCHEDULE_EMAIL.toUpperCase()})
        }
      }
  
      if (hasOccupantsLayer) {
        const templateSingular = i18n.configurator.officeHoteling.reservationType.missingPeopleLyrReqField;
        const template = i18n.configurator.officeHoteling.reservationType.missingPeopleLyrReqFields;
  
        const occupantsHasKnownAs = info.peopleHasFullName;
        const occupantHasEmail = info.peopleHasEmail;  
        if (!occupantsHasKnownAs || !occupantHasEmail) {
          if(!occupantsHasKnownAs && !occupantHasEmail) {
            occupantsErr = stringFormatter (template, 
                            {fields: [FieldNames.PEOPLE_EMAIL.toUpperCase(), FieldNames.PEOPLE_FULLNAME.toUpperCase()].join(", ")})
          } else if(!occupantsHasKnownAs) {
            occupantsErr = stringFormatter (templateSingular, {field: FieldNames.PEOPLE_FULLNAME.toUpperCase()});
          } else  {
            occupantsErr = stringFormatter (templateSingular, {field: FieldNames.PEOPLE_EMAIL.toUpperCase()});
          }
        }
      }
 
      if(info.hasAreasTable) {
        areaErr = this.checkMissingAreasFields(info);
      }  
      if (missingLyrTable || unitErr || occupantsErr || areaErr) {
        return (
          <div style={{marginLeft: "0"}}>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {missingLyrTable}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {unitErr}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {occupantsErr}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {areaErr}
            </div>
          </div>
        )
      }
      return null;
    }

    renderOffice365ErrorsMeeting =()=> {
      const info = workspaceReservationUtil.getWorkspaceInfo();
      if (!info) return null;

      const i18n = Context.instance.i18n;
      let unitErr = null, missingLyrTable = null;
      const hasUnitsLayer = info.hasUnitsLayer
  
      const templateSingular = i18n.more.bookWorkspace.missingLyrTable;
      let temp = [];
      if(!hasUnitsLayer) temp.push(i18n.more.bookWorkspace.unitsLayer);
      if(!hasUnitsLayer) {
        missingLyrTable = stringFormatter(templateSingular, {item: temp.join(", ")});
      }
  
      if (hasUnitsLayer) {
        const unitHasScheduleEmail = info.unitsHasScheduleEmail;  
        if (!unitHasScheduleEmail) {
          const template = i18n.configurator.officeHoteling.reservationType.missingUnitsLyrReqField;
          unitErr = stringFormatter (template, {fields: FieldNames.SCHEDULE_EMAIL.toUpperCase()})
        }
      }
  
      if (missingLyrTable || unitErr) {
        return (
          <div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {missingLyrTable}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {unitErr}
            </div>
          </div>
        )
      }
      return null;
    }

    renderHotelEsriErrors =()=> {

      const info = workspaceReservationUtil.getWorkspaceInfo();
      if (!info) return null;

      const i18n = Context.instance.i18n;
      const hasReservationsLayer = info.hasReservationsLayer;
      const hasOccupantsLayer = info.hasOccupantsLayer;
      const hasUnitsLayer = info.hasUnitsLayer;
      const hasAreasTable = info.hasAreasTable;

      const isLayerEditable = info.isLayerEditable;
      let resLyrErr, occupantsErr, areasErr, missingLyrTable;
      
      if(!hasReservationsLayer || !isLayerEditable) {
        if(!hasReservationsLayer) {
          resLyrErr = i18n.configurator.officeHoteling.reservationType.noValidReservationLyr + ": ";
          resLyrErr += FieldNames.RESERVED_FOR_USERNAME.toUpperCase() + ", ";
          resLyrErr += FieldNames.RESERVED_FOR_FULL_NAME.toUpperCase() + ", ";
          resLyrErr += FieldNames.STATE.toUpperCase() + ", ";
          resLyrErr += FieldNames.START_TIME.toUpperCase() + ", ";
          resLyrErr += FieldNames.END_TIME.toUpperCase() + ", ";
          resLyrErr += FieldNames.CHECK_IN_TIME.toUpperCase() + ", ";
          resLyrErr += FieldNames.CHECK_OUT_TIME.toUpperCase() + ", ";
          resLyrErr += FieldNames.TITLE.toUpperCase() + ", ";
          resLyrErr += FieldNames.ALL_DAY.toUpperCase() + ", ";
          resLyrErr += FieldNames.DESCRIPTION.toUpperCase() + ", ";
          resLyrErr += FieldNames.UNIT_ID.toUpperCase() + ", ";
          resLyrErr += FieldNames.LEVEL_ID.toUpperCase();      
        } else if (!isLayerEditable) {
          resLyrErr = i18n.configurator.officeHoteling.reservationType.notEditable;
        }
      } else {
          const templateSingular = i18n.more.bookWorkspace.missingLyrTable;
          const template = i18n.more.bookWorkspace.missingLyrTables;
          let temp = [];
          if(!hasUnitsLayer) temp.push(i18n.more.bookWorkspace.unitsLayer);
          if(!hasOccupantsLayer) temp.push(i18n.more.bookWorkspace.peopleLayer);
          if(!hasAreasTable) temp.push(i18n.more.bookWorkspace.areasTable);
          if(!hasUnitsLayer || !hasOccupantsLayer || !hasAreasTable) {
            if(temp.length === 1) missingLyrTable = stringFormatter(templateSingular, {item: temp.join(", ")});
            if(temp.length > 1) missingLyrTable = stringFormatter(template, {items: temp.join(", ")});
          }

          if (hasOccupantsLayer) {
            const templateSingular = i18n.configurator.officeHoteling.reservationType.missingPeopleLyrReqField;
            const template = i18n.configurator.officeHoteling.reservationType.missingPeopleLyrReqFields;
            const occupantsHasKnownAs = info.peopleHasFullName;
            const occupantHasEmail = info.peopleHasEmail;
      
            if (!occupantsHasKnownAs || !occupantHasEmail) {
              if(!occupantsHasKnownAs && !occupantHasEmail) 
                  occupantsErr = stringFormatter (template,
                    {fields: [FieldNames.PEOPLE_EMAIL.toUpperCase(), FieldNames.PEOPLE_FULLNAME.toUpperCase()].join(", ")})
              else if(!occupantsHasKnownAs) occupantsErr = stringFormatter (templateSingular, {
                                                            field: FieldNames.PEOPLE_FULLNAME.toUpperCase()})
              else occupantsErr = stringFormatter (templateSingular, {field: FieldNames.PEOPLE_EMAIL.toUpperCase()})
            }
          }

          if (hasAreasTable) areasErr = this.checkMissingAreasFields(info);

      }

      if (resLyrErr || missingLyrTable || occupantsErr || areasErr) {
        return (
          <div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {resLyrErr}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {missingLyrTable}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {occupantsErr}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {areasErr}
            </div>
          </div>
        )
      }
      return null;
    }

    renderMeetingEsriErrors =()=> {
      
      const info = workspaceReservationUtil.getWorkspaceInfo();
      if (!info) return null;

      const i18n = Context.instance.i18n;
      const hasReservationsLayer = info.hasReservationsLayer;
      const isLayerEditable = info.isLayerEditable;
      let reservationLyrErr, missingLyrTable, missingUnitFields;
      
      if(!hasReservationsLayer || !isLayerEditable) {
        if(!hasReservationsLayer) {
          reservationLyrErr = i18n.configurator.officeHoteling.reservationType.noValidReservationLyr + ": ";
          reservationLyrErr += FieldNames.RESERVED_FOR_USERNAME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.RESERVED_FOR_FULL_NAME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.STATE.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.START_TIME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.END_TIME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.CHECK_IN_TIME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.CHECK_OUT_TIME.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.TITLE.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.ALL_DAY.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.DESCRIPTION.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.UNIT_ID.toUpperCase() + ", ";
          reservationLyrErr += FieldNames.LEVEL_ID.toUpperCase();      
        } else if (!isLayerEditable) {
          reservationLyrErr = i18n.configurator.officeHoteling.reservationType.notEditable;
        }
      } else {
          const hasUnitsLayer = info.hasUnitsLayer;
          const unitHasAreaId = info.unitsHasAreaId;
          const unitHasResMethod = info.unitsHasReservationMethod;

          const templateSingular = i18n.more.bookWorkspace.missingLyrTable;
          let temp = [];
          if(!hasUnitsLayer) temp.push(i18n.more.bookWorkspace.unitsLayer);
          if(!hasUnitsLayer) {
            missingLyrTable = stringFormatter(templateSingular, {item: temp.join(", ")});
          } else if (!unitHasAreaId || !unitHasResMethod) {
            const template = i18n.configurator.officeHoteling.reservationType.missingUnitsLyrReqFields;
            const templateSingular = i18n.configurator.officeHoteling.reservationType.missingUnitsLyrReqField;
            if(!unitHasAreaId && !unitHasResMethod) 
              missingUnitFields = stringFormatter (template, {fields: [FieldNames.AREA_ID.toUpperCase(), FieldNames.RESERVATION_METHOD.toUpperCase()].join(", ")});
            else if(!unitHasAreaId) missingUnitFields = stringFormatter (templateSingular, {fields: FieldNames.AREA_ID.toUpperCase()});
            else missingUnitFields = stringFormatter (templateSingular, {fields: FieldNames.RESERVATION_METHOD.toUpperCase()});
          } 
      }

      if (reservationLyrErr || missingLyrTable || missingUnitFields) {
        return (
          <div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {reservationLyrErr}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {missingLyrTable}
            </div>
            <div className="i-hotel-config i-issue-office365" style={{marginLeft: "0"}}>
              {missingUnitFields}
            </div>
          </div>
        )
      }
      return null;
    }

    render() {
      const isMeetingErrorsO365 = this.renderOffice365ErrorsMeeting();
      const isHotelErrorsO365 = this.renderHotelOffice365Errors();
      const showCalendarExport = 
        ((this.state.enableHotel && this.state.reservationTypeHotel !== 'office365') 
        || (this.state.enableMeeting && this.state.reservationTypeMeeting !== 'office365'));

      const showOffice365Section = 
        ((this.state.enableHotel && this.state.licensed && !isHotelErrorsO365 && this.state.reservationTypeHotel === "office365") 
        || (this.state.enableMeeting && this.state.licensed && !isMeetingErrorsO365 && this.state.reservationTypeMeeting === "office365"));


      return (
        <div className={CSS.section}>
            {this.renderHeader()}
            {this.renderToggleSection()}
            {showCalendarExport && this.renderCalendarExport()}
            {showOffice365Section && this.renderOffice365Sections()}
        </div>
      )
    }

    setValuesForDisabledToggles =()=> {
      const wsInfo = workspaceReservationUtil.getWorkspaceInfo();
      if(!this.state.reservationTypeHotel) {
        if (wsInfo.isValidLicense && wsInfo.hasReservationsLayer && wsInfo.isLayerEditable && wsInfo.unitsHasAreaId && wsInfo.hasAreasTable) {
          this.setState({
            reservationTypeHotel: "esri"
          }, this.applyState)
        } else {
          this.setState({
            reservationTypeHotel: "office365"
          })
        }
      }
      if(!this.state.reservationTypeMeeting) {
        if (wsInfo.isValidLicense && wsInfo.hasReservationsLayer && wsInfo.isLayerEditable && wsInfo.unitsHasReservationMethod) {
          this.setState({
            reservationTypeMeeting: "esri"
          }, this.applyState)
        } else {
          this.setState({
            reservationTypeMeeting: "office365"
          }, this.applyState)
        }
      }
    }

}

const mapStateToProps = (state) => {
    return {
      rdxSidebarActiveKey: Rdx.getValue(state,Rdx.Keys.SIDEBAR_ACTIVE_KEY),
    }
}
  
export default connect(mapStateToProps)(OfficeHoteling);