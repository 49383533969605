import React, { createRef } from "react";
import Context from "../../../../context/Context";
import Topic from "../../../../context/Topic";
import * as component from "../../../../components/util/component";
import { IFileInfo } from "../../../miniapps/common/types";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-label";
import {
  CalciteButton,
  CalciteLabel
} from "@esri/calcite-components-react";

interface Props {
  onSelect: (fileInfo: IFileInfo) => void
}

interface State {
}

export default class FileBrowser extends React.Component<Props, State> {

  divRef = createRef<HTMLDivElement>();
  inputRef = createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = component.newState({})
  }

  getExtension = (file): string => {
    if (file && file.name) {
      const idx = file.name.lastIndexOf(".");
      if (idx !== -1) {
        const extension = file.name.substring(idx+1).trim().toLowerCase();
        if (extension.length > 0) return extension;
      }
    }
    return null;
  }

  getExtensionOK = (extension) => {
    return this.getExtensions().includes(extension);
  }

  getExtensions = (): string[] => {
    // allowedExtensions = ["jpg", "jpeg", "png", "webp"];
    return ["jpg", "jpeg", "png"]; 
  }

  getTypeOK = (type) => {
    return this.getTypes().includes(type);
  }

  getTypes = (): string[] => {
    return ["image/png","image/jpeg"]; 
  }

  highlighDropArea(type: "active" | "inactive") {
    const el = this.divRef && this.divRef.current;
    if (el && type === "active") {
      el.classList.add("i--active");
    } else if (el && type === "inactive") {
      el.classList.remove("i--active");
    }
  }

  onDragLeave = (event )=> {
    try {
      if (event.currentTarget && event.currentTarget.contains && event.currentTarget.contains(event.relatedTarget)) return;
      let within = false;
      let el = event.relatedTarget;
      while (el) {
        if (el && el.classList && el.classList.contains("i-file-browser")) {
          within = true;
          break;
        }
        el = el.parentNode;
      }
      if (!within) this.highlighDropArea("inactive");
    } catch (ex) {
    }
  }

  onDragOver = (event )=> {
    const items = event.dataTransfer.items;
    const type: string = items && items[0] && items[0].type;
    const typeOK = this.getTypeOK(type);
    if (typeOK) {
      event.dataTransfer.dropEffect = "copy";
      event.preventDefault();
      event.stopPropagation();
      Topic.publish(Topic.ClearToast,{});
      this.highlighDropArea("active");
    } else {
      event.dataTransfer.dropEffect = "none";
      event.preventDefault();
      event.stopPropagation();
    }
  }
    
  onDrop = async (event)=> {
    event.preventDefault();
    this.highlighDropArea("inactive");
    const files = event.dataTransfer.files;
    const file = files && files[0];
    if (file) this.onSelect(file);
  }
    
  onFileSelect = async (event)=> {
    const files = event.target.files;
    const file = files && files[0];
    if (file) this.onSelect(file);
  }

  onSelect = (file) => {
    const i18n = Context.instance.i18n;

    const { name, type } = file;
    const title = name.replace(/\.[^/.]+$/, ""); // https://devtopia.esri.com/WebGIS/arcgis-webviewer-app/blob/fb17d4da357837c53838117ddac7d02cd05bed01/src/widgets/panels/MediaLayer/addmedia/MediaBrowser.tsx
    const extension = this.getExtension(file);
    const extensionOK = this.getExtensionOK(extension);
    const typeOK = this.getTypeOK(type);
    if (!(extensionOK && typeOK)) {
      console.warn("Unsupported file type:",type,"extension:",extension);
      Topic.publish(Topic.ShowToast,{
        message: i18n.editor.media.unsupportedType,
        submessage: i18n.editor.media.unsupportedTypeMsg,
        type: "warning",
        dismissAll: true
      });
      return;
    }

    const maxFileSize = 10000000;
    if (file.size > maxFileSize) {
      const sizeInMB = maxFileSize / 1000000;
      const msg = i18n.editor.media.maxFileSize.replace("{size}",sizeInMB.toString());
      Topic.publish(Topic.ShowToast,{
        message: msg,
        type: "warning",
        dismissAll: true
      });
      return;
    }

    Topic.publish(Topic.ClearToast,{});
    const blob = new Blob([file],{type});
    const fileInfo: IFileInfo = {
      blob,
      file,
      name,
      extension,
      title,
      type
    }
    this.props.onSelect(fileInfo);
  }

  render() {
    const i18n = Context.instance.i18n;
    const extensions = this.getExtensions().map(v => "."+v).join(",");
    return (
      <div 
        className="i-file-browser"
        ref={this.divRef}
        onDragLeave={this.onDragLeave} 
        onDragOver={this.onDragOver} 
        onDrop={this.onDrop}
      >
        <div>
          <CalciteButton
            onClick={e => {
              if (this.inputRef && this.inputRef.current) {
                this.inputRef.current.value = null;
                this.inputRef.current.click();
              }
            }}
          >
            {i18n.editor.media.add}
          </CalciteButton>
          <input 
            style={{display: "none"}}
            ref={this.inputRef}
            type="file" 
            accept={extensions} 
            onChange={this.onFileSelect} 
          />
        </div>
        <div className="i-drop-prompt">
          {i18n.editor.media.drop}
        </div>
      </div>
    )
  }
    
}