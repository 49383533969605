import React from "react";

import Book from "../MeetingRooms/Book";
import Context from "../../../context/Context";
import DropdownButton from "../../common/Dropdown/DropdownButton";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Icons from "../../util/Icons";
import ModalController from "../../../common/components/Modal/ModalController";
import Schedule from "../MeetingRooms/Schedule";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as dateUtil from "../Events/dateUtil";
import * as shareUtil from "./shareUtil";
import * as val from "../../../util/val";
import PinIcon from "calcite-ui-icons-react/PinIcon";
import { flattenAssignments, showMultipleAssignments } from "../../../util/multipleAssignmentsUtil";

const CSS = {
  actionButton: "i-actionbar-button",
  actionButtonCaption: "i-actionbar-button-caption",
  hasFavorite: "i-infopanel-hasfavorite"
};

export class AppLaunch extends React.Component {

  render() {
    const urls = this.props.urls || [];
    const moreActions = this.props.moreActions || {};
    const item = this.props.item;
    const hasGeometry = this.props.hasGeometry;

    const getPlacement = () => {
      if (Context.instance.uiMode.modeIsHorizontalSplit()) {
        return "bottom-end";
      }
    };

    let firstWasSet = false;
    const isFirst = () => {
      if (!firstWasSet) {
        firstWasSet = true;
        return true;
      }
      return false;
    };

    const i18n = Context.instance.i18n;
    const buttonContent = (
      <div>
        {Icons.handleHorizontal()}
        <span className={CSS.actionButtonCaption}>{i18n.general.more}</span>
      </div>
    );

    const options = [];

    if (moreActions.setHome) {
      options.push(
        <li key="setHome"><SetHome isMore isFirst={isFirst()}
          item={item} disabled={!hasGeometry}/></li>
      );
    }

    urls.forEach((urlInfo,idx) => {
      let v = val.trim(urlInfo.label);
      if (typeof v !== "string" || v.length === 0) {
        v = i18n.general.untitled;
      }
      let option = (
        <li key={urlInfo.key} role="none">
          <a target="_blank" rel="noopener noreferrer"
            className="i-dd-menuitem"
            role="menuitem link" tabIndex="0"
            href={urlInfo.url} data-i--first={isFirst()}>
            {Icons.launch()}
            <span>{v}</span>
          </a>
        </li>
      );
      options.push(option);
    });
    const dropdownContent = (
      <ul role="menu">{options}</ul>
    );

    return (
      <DropdownButton
        disabled={urls.length === 0}
        closeOnSelect={true}
        focusFirst={true}
        buttonClassName={"i-actionbar-button"}
        contentClassName={"i--applaunch"}
        buttonContent={buttonContent}
        dropdownContent={dropdownContent}
        getPlacement= {getPlacement}>
      </DropdownButton>
    );
  }
}

export class BookHotelRoom extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    if (this.props.onBookClicked) {
      this.props.onBookClicked();
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    let className = CSS.actionButton;
    if (this.props.disabled){
      className += " i--disabled";
    }
    const node = (
      <button className={className} onClick={this.clicked}>
        {Icons.bookHotel()}
        <span className={CSS.actionButtonCaption}>{i18n.meetingRooms.book.label}</span>
      </button>
    );
    return node;
  }
}

export class BookMeetingRoom extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    if (this.props.onBookClicked) {
      this.props.onBookClicked();
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    let className = CSS.actionButton;
    if (this.props.disabled){
      className += " i--disabled";
    }
    const node = (
      <button className={className} onClick={this.clicked}>
        {Icons.conferenceRoom()}
        <span className={CSS.actionButtonCaption}>{i18n.meetingRooms.book.label}</span>
      </button>
    );
    return node;
  }
}

export class Directions extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const searchResult = this.props.item.getSearchResult();
    Topic.publish(Topic.DirectionsClicked,{searchResult: searchResult});
  }

  render() {
    const itemGeometry = this.props?.item?.searchResult?.feature?.geometry;
    const i18n = Context.getInstance().i18n;
    let className = CSS.actionButton;
    if (this.props.disabled || !itemGeometry){
      className += " i--disabled";
    }
    const node = (
      <button className={className} onClick={this.clicked}>
        {Icons.directions()}
        <span className={CSS.actionButtonCaption}>{i18n.directions.caption}</span>
      </button>
    );
    return node;
  }
}

// export class HotelRoomSchedule extends React.Component {
//   constructor(props) {
//     super(props);
//     this.clicked = this.clicked.bind(this);
//   }

//   clicked(event) {
//     const i18n = Context.getInstance().i18n;
//     const item = this.props.item;
//     const feature = item.searchResult.feature;
//     const locationUrl = shareUtil.generateShareUrl(item);
//     const roomItem = {
//       isHotel: true,
//       locationUrl: locationUrl,
//       unitId: aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_ID),
//       name: aiimUtil.getAttributeValue(feature.attributes,FieldNames.NAME),
//       unitFeature: feature
//     };
//     const controller = new ModalController();
//     let title = i18n.meetingRooms.schedule.captionPattern;
//     title = title.replace("{room}",roomItem.name);
//     const modalProps = {
//       className: "i-modal-confirm i-modal-schedule",
//       title: title,
//       cancelLabel: i18n.general.close,
//       showOKCancel: true,
//       hideOK: true
//     };
//     const content = (
//       <Schedule item={roomItem}
//         onBookClicked={info => {
//           if (this.props.onBookClicked) {
//             this.props.onBookClicked(info);
//           }
//           controller.close();
//         }}
//       />
//     )
//     controller.show(modalProps,content);
//   }

//   render() {
//     const i18n = Context.getInstance().i18n;
//     let className = CSS.actionButton;
//     if (this.props.disabled){
//       className += " i--disabled";
//     }
//     const node = (
//       <button className={className} onClick={this.clicked}>
//         {Icons.schedule()}
//         <span className={CSS.actionButtonCaption}>{i18n.meetingRooms.schedule.label}</span>
//       </button>
//     );
//     return node;
//   }
// }

export class Launch311 extends React.Component {
  render() {
    const i18n = Context.getInstance().i18n;
    const url = this.props._311Url;
    const node = (
      <a className={CSS.actionButton} href={url} role="button"
        rel="noopener noreferrer" target="_blank">
        {Icons.bell()}
        <span className={CSS.actionButtonCaption}>{i18n.infoPanel.actions.launch311}</span>
      </a>
    );
    return node;
  }
}

export class MeetingRoomSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const i18n = Context.getInstance().i18n;
    const item = this.props.item;
    const feature = item.searchResult.feature;
    const roomItem = {
      unitId: aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_ID),
      name: aiimUtil.getAttributeValue(feature.attributes,FieldNames.NAME),
      unitFeature: feature
    };
    const controller = new ModalController();
    let title = i18n.meetingRooms.schedule.captionPattern;
    title = title.replace("{room}",roomItem.name);
    const modalProps = {
      className: "i-modal-confirm i-modal-schedule",
      title: title,
      cancelLabel: i18n.general.close,
      showOKCancel: true,
      hideOK: true
    };
    const content = (
      <Schedule item={roomItem} onShowOccupant={() => controller.close()} />
    )
    controller.show(modalProps,content);
  }

  render() {
    const i18n = Context.getInstance().i18n;
    let className = CSS.actionButton;
    if (this.props.disabled){
      className += " i--disabled";
    }
    const node = (
      <button className={className} onClick={this.clicked}>
        {Icons.schedule()}
        <span className={CSS.actionButtonCaption}>{i18n.meetingRooms.schedule.label}</span>
      </button>
    );
    return node;
  }
}

export class SaveFavorite extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const item = this.props.item;
    const referenceLayer = Context.getInstance().session.referenceLayer;
    referenceLayer.addFavorite(item.getSourceKey(),item.getSearchResult());
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const item = this.props.item;
    const referenceLayer = Context.getInstance().session.referenceLayer;
    const hasFavorite = referenceLayer.hasFavorite(item.getSourceKey(),item.getSearchResult());
    let cls = hasFavorite ? CSS.actionButton + " " + CSS.hasFavorite : CSS.actionButton;
    const tip = hasFavorite ? i18n.infoPanel.actions.tipAlreadySaved : "";
    const caption = hasFavorite ? i18n.infoPanel.actions.savedFavorite : i18n.infoPanel.actions.saveFavorite;
    if (this.props.disabled){
      cls += " i--disabled";
    }
    const node = (
      <button className={cls} title={tip} onClick={this.clicked}>
        {Icons.favorites()}
        <span className={CSS.actionButtonCaption}>{caption}</span>
      </button>
    );
    return node;
  }
}

export class SetHome extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const item = this.props.item;
    const referenceLayer = Context.getInstance().session.referenceLayer;
    referenceLayer.setHomeLocation(item.getSourceKey(),item.getSearchResult());
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const isMore = this.props.isMore
    let className = CSS.actionButton;
    if (this.props.disabled){
      className += " i--disabled";
    }
    if (isMore) {
      className += " i-dd-menuitem i--focusable";
    }
    const node = (
      <button className={className} onClick={this.clicked}>
        {isMore ? Icons.homeNoFill() : Icons.home()}
        <span className={CSS.actionButtonCaption}>{i18n.infoPanel.actions.setHome}</span>
      </button>
    );
    return node;
  }
}

export class Locations extends React.Component {
  extractPersonFeatures = () => {
    const { activeLocation, otherLocations } = this.props;

    const personFeatures = [];
    const activeAssignment = activeLocation && flattenAssignments(activeLocation);
    if (activeAssignment && activeAssignment.length === 1) {
      personFeatures.push(activeAssignment[0].person);
    } else if (activeLocation && activeLocation.person) {
      personFeatures.push(activeLocation.person)
    }
    const otherAssignments = otherLocations && flattenAssignments(otherLocations);
    if (otherAssignments && otherAssignments.length > 0) {
      otherAssignments.forEach((assignment) => personFeatures.push(assignment.person));
    }

    return personFeatures;
  }

  extractPersonName = () => {
    const { activeLocation } = this.props;
    if (!activeLocation) {
      return "";
    }

    const person = activeLocation && activeLocation.person;
    const attributes = (person && person.feature) ? person.feature.attributes : person && person.attributes;
    return aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME) || "";
  }

  clicked = () => {
    const { activeLocation, otherLocations, pastReservation, upcomingReservations, hasLKL } = this.props;
    const personFeatures = this.extractPersonFeatures();
    const title = this.extractPersonName();

    showMultipleAssignments({
      assignments: otherLocations,
      primary: activeLocation,
      pastReservation,
      upcomingReservations,
      personFeatures,
      title,
      hasLKL
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;

    return (
      <button
        className={CSS.actionButton}
        onClick={this.clicked}
      >
        {Icons.pin()}
        <span className={CSS.actionButtonCaption}>{i18n.infoPanel.actions.locations}</span>
      </button>
    );
  }
}
