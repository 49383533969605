import React, { CSSProperties } from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import RotateAngle from "./RotateAngle";
import * as component from "../../../../components/util/component";
import * as mapUtil from "../../../base/mapUtil";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-popover";
import {
  CalciteButton,
  CalcitePopover
} from "@esri/calcite-components-react";
import { HeaderToolType } from "./PanelHeaderTools";
import { LayerType } from "../../../../util/interfaces";
import { getActiveFeatureType } from "../redux";

export interface IMapButtonProps {
  angle: number,
  disabled: boolean,
  graphic: __esri.Graphic,
  onClick?: () => void,
  onRotate?: (angle: number, geom: __esri.Geometry) => void,
  onRotateOpen?: (comp: RotateAngle) => void,
  onRotateClose?: (angle: number) => void,
  scale: "s" | "m" | "l",
  tool: Extract<HeaderToolType, "delete" | "duplicate" | "edit" | "flipH" | "flipV" | "reflect" | "rotate"> | "flip",
  type: LayerType,
  updateState: __esri.SketchViewModelUpdateEvent["state"]
}

interface State {
  rotatePopoverOpen: boolean
}

class MapButton extends React.Component<IMapButtonProps,State> {

  private componentId: string = null;
  private watchHandles: __esri.WatchHandle[] = [];

  private tools = {
    "delete": {
      icon: "trash",
      title: Context.instance.i18n.editor.mapButtons.del,
      css: null
    },
    "duplicate": {
      icon: "duplicate",
      title: Context.instance.i18n.editor.mapButtons.duplicate,
      css: null
    },
    "edit": {
      icon: "pencil",
      title: Context.instance.i18n.editor.mapButtons.edit,
      css: null
    },
    "flip": {
      icon: "flip",
      title: Context.instance.i18n.editor.mapButtons.flip,
      css: null
    },
    "flipH": {
      icon: "flip",
      title: Context.instance.i18n.editor.mapButtons.flipH,
      css: null
    },
    "flipV": {
      icon: "flip",
      title: Context.instance.i18n.editor.mapButtons.flipV,
      css: {transform: "rotate(90deg)"}
    },
    "reflect": {
      icon: "flip",
      title: Context.instance.i18n.editor.mapButtons.reflect,
      css: {transform: "rotate(90deg)"}
    },
    "rotate": {
      icon: "rotate",
      title: Context.instance.i18n.editor.mapButtons.rotate,
      css: null
    }
  }

  constructor(props: IMapButtonProps) {
    super(props);
    this.componentId = component.nextId("mapbutton-")
    this.state = component.newState({
      rotatePopoverOpen: false
    });
  }

  componentDidMount() {
    const view = mapUtil.getView();
    if (view) {
      this.watchHandles.push(view.watch("extent",(newVal,oldVal,property,object) => {
        component.refresh(this);
      }));
    }
  }

  componentWillUnmount() {
    this.clearWatchHandles();
    component.componentWillUnmount(this);
  }

  clearWatchHandles() {
    this.watchHandles.forEach((h) => {
      try {
        h.remove();
      } catch(ex) {
        console.error(ex);
      }
    });
    this.watchHandles = [];
  }

  getStyle = () => {
    return this.getStyle_Screen();
  }

  getStyle_Map = () => {
    const view = mapUtil.getView() as __esri.MapView;
    const graphic = this.props.graphic;
    const angle = this.props.angle; // counter-clockwise from the x-axis

    let min = 1.5
    let ext = graphic.geometry.extent.clone();
    if (ext.spatialReference && ext.spatialReference.wkid === 102100) {
      if (ext.width < min) {
        const mid = (ext.xmin + ext.xmax) / 2;
        ext.xmin = mid - (min / 2);
        ext.xmax = mid + (min / 2);
      }
      if (ext.height < min) {
        const mid = (ext.ymin + ext.ymax) / 2;
        ext.ymin = mid - (min / 2);
        ext.ymax = mid + (min / 2);
      }
    }

    const cen = ext.center;
    let dx = Math.cos(angle * Math.PI / 180) * (ext.width + (ext.width / 4));
    let dy = Math.sin(angle * Math.PI / 180) * (ext.height + (ext.height / 4));
    const mapPoint = new Context.instance.lib.esri.Point({
      x: cen.x + dx,
      y: cen.y + dy,
      spatialReference: view.spatialReference
    })
    const screenPoint = view.toScreen(mapPoint);

    const el = document.getElementById("sp-view-container");
    const rect = el.getBoundingClientRect();
    let left = rect.left + window.scrollX;
    let top = rect.top + window.scrollY;

    const style: CSSProperties = {
      position: "absolute",
      left: screenPoint.x + left + "px",
      top: screenPoint.y + top + "px",
      transform: "translateY(-50%) translateX(-50%)", 
      zIndex: "9999"
    }
    return style;
  }

  getStyle_Screen = () => {
    const view = mapUtil.getView() as __esri.MapView;
    const graphic = this.props.graphic;
    const angle = this.props.angle; // counter-clockwise from the x-axis

    let px = 100;
    let dx = Math.cos(angle * Math.PI / 180) * px;
    let dy = Math.sin(angle * Math.PI / 180) * px;

    const ext = graphic.geometry.extent.clone();
    const screenCen = view.toScreen(ext.center);
    const screenPoint = {
      x: screenCen.x + dx,
      y: screenCen.y - dy,
    };

    const el = document.getElementById("sp-view-container");
    const rect = el.getBoundingClientRect();
    let left = rect.left + window.scrollX;
    let top = rect.top + window.scrollY;

    const style: CSSProperties = {
      position: "absolute",
      left: screenPoint.x + left + "px",
      top: screenPoint.y + top + "px",
      transform: "translateY(-50%) translateX(-50%)", 
      zIndex: "100"
    }

    const isRtl = Context.instance.uiMode.isRtl;
    if (!isRtl) {
      if (screenPoint.x < 0) style.display = "none";
    }

    return style;
  }

  getTooltip() {
    const { disabled, tool, type } = this.props;
    const { i18n } = Context.getInstance();
    const toolInfo = this.tools[tool];
    const text = toolInfo?.title;
    if (disabled) {
      if (tool === "duplicate") {
        return type === "site"
          ? i18n.editor.mapButtons.notAvailableDuplicateSite
          : this.props.type === "facility"
            ? i18n.editor.mapButtons.notAvailableDuplicateFacility
            : i18n.editor.mapButtons.notAvailableDuplicateLevel;
      } else if (["edit", "delete"].includes(tool)) {
        return type === "site"
          ? i18n.editor.mapButtons.notAvailableSites
          : type === "facility"
            ? i18n.editor.mapButtons.notAvailableFacilities
            : i18n.editor.mapButtons.notAvailableLevels;
      } else {
        return i18n.editor.mapButtons.disabled;
      }
    } else {
      return text;
    }
  }

  render() {
    const { rotatePopoverOpen } = this.state;
    const { disabled, graphic, scale, tool, updateState } = this.props;
    const { mapButtons: i18n } = Context.getInstance().i18n.editor;
    if (!graphic || (updateState !== "start")) return null;

    const id = this.componentId;
    const style = this.getStyle();
    const toolInfo = this.tools[tool];
    const isRotate = (tool === "rotate");

    return (
      <div className="i-editor-map-button" style={style}>
        <CalciteButton 
          id={id} 
          round
          scale={scale}
          icon-start={toolInfo.icon}
          title={this.getTooltip()}
          style={{...toolInfo.css}}
          disabled={disabled || undefined}
          onClick={() => {
            if (isRotate) {
              this.setState({rotatePopoverOpen: !rotatePopoverOpen})
            } else {
              if (this.props.onClick) this.props.onClick();
            }
          }}
        />
        {isRotate && rotatePopoverOpen && 
          <CalcitePopover 
            referenceElement={id} 
            placement="top" 
            label="rotate"
            autoClose={true} 
            closable={true}
            open={true}
            style={{width: "16rem"}}
            onCalcitePopoverClose={() => { 
              this.setState({rotatePopoverOpen: false})
            }}
          >
            <RotateAngle
              geometry={graphic.geometry}
              onOpen={this.props.onRotateOpen}
              onClose={this.props.onRotateClose}
              onRotate={(angle,geometry) => {
                if (this.props.onRotate) this.props.onRotate(angle,geometry);
              }}
            />
          </CalcitePopover>
        }
      </div>
    )
  }


}

const mapStateToProps = (state) => ({
  type: getActiveFeatureType(state)
});

export default connect(mapStateToProps)(MapButton);