import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../datasets/FieldNames";
import * as aiimUtil from "../util/aiimUtil";

export default class Source extends BaseClass {

  displayField; // mandatory
  displayTemplate;
  fromCategoriesTable;
  icon;
  iconUrl;
  identifier;
  index;
  itemCard;
  key;
  layer2D;
  layer3D;
  mappings;
  sortOptions;
  subLayer;
  subTitleField = FieldNames.NAME_SUBTITLE;
  subTypes;
  subTypesByValue;
  subTypesPromise;
  uniqueIdField = FieldNames.POINT_OF_INTEREST_ID; // mandatory
  url;
  workOrderMappings;

  categoryLevel;
  name;
  searchFields;
  subCategoryField;
  suggestionOrderByFields;
  suggestionTemplate;

  checkFieldNameProperty(propertyName, fields, defaultField) {
    let v = this[propertyName], ok = false;
    if (typeof v === "string" && v.length > 0 && fields) {
      let v2 = aiimUtil.findFieldName(fields,v);
      if (typeof v2 === "string" && v2.length > 0) {
        if (v !== v2) this[propertyName] = v2;
        ok = true;
      }
      //if (!ok) console.warn(this.key+" - invalid "+propertyName+": "+v);
    }
    if (!ok && defaultField) {
      let v2 = aiimUtil.findFieldName(fields,defaultField);
      if (typeof v2 === "string" && v2.length > 0) {
        this[propertyName] = v2;
        ok = true;
        //console.warn(this.key+" - setting "+propertyName+": "+v2);
      }
    }
    return ok;
  }

  checkSchema() {

    const trimQuotes = (v) => {
      if (typeof v === "string" && v.length > 2) {
        let v2 = v;
        if (v2.indexOf("\"") === 0) {
          v2 = v.substring(1);
          if (v2.indexOf("\"") === (v2.length - 1)) {
            v2 = v2.substring(0,(v2.length - 1));
            return v2;
          }
        }
      }
      return v;
    }

    const checkSuggestionTemplate = (layer,text) => {
      let text2 = "", name = "", names = [], orderBy = [];
      let c, i, v, ph, bIn = false;
      for (i=0;i<text.length;i++) {
        c = text.charAt(i);
        if (bIn) {
          if (c === "}") {
            bIn = false;
            ph = "__"+names.length+"__";
            text2 += ph;
            names.push(name);
            //console.log("name",name);
          } else {
            name += c;
          }
        } else {
          if (c === "{") {
            bIn = true;
            name = "";
          } else {
            text2 += c;
          }
        }
      }
      for (i=0;i<names.length;i++) {
        ph = "__"+i+"__";
        name = names[i];
        v = aiimUtil.findFieldName(layer.fields,name);
        if (typeof v === "string" && v.length > 0) {
          text2 = text2.replace(ph,"{"+v+"}");
          orderBy.push(v);
        } else {
          text2 = text2.replace(ph,"");
          console.warn(this.key+" - invalid suggestionTemplate field: "+name);
        }
      }
      text2 = text2.trim();
      if (text2.length > 0) {
        this.suggestionTemplate = text2;
        this.suggestionOrderByFields = orderBy;
      } else {
        this.suggestionTemplate = null;
        this.suggestionOrderByFields = null;
      }
      //console.log("suggestionTemplate",this.suggestionTemplate);
    };

    const promise = new Promise((resolve,reject) => {
      if (this.layer2D) {
        const layer = this.layer2D;
        layer.when(() => {
          const fields = layer.fields;
          if (!this.displayField) {
            this.displayField = layer.displayField;
          }
          let ok, v, v2;

          if (layer.floorInfo && layer.floorInfo.floorField) {
            if (!this.mappings) this.mappings = {};
            this.mappings["levelIdField"] = layer.floorInfo.floorField;
          }

          // TODO this is temporary
          if (this.isAiimPeople()) {
            if (typeof this.displayField === "string" &&
                this.displayField.toLowerCase() === "level_name") {
              this.displayField = "knownas";
              //console.warn(this.key+" - setting displayField from level_name to knownas");
            }
          }

          ok = this.checkFieldNameProperty("displayField",fields,FieldNames.NAME);
          if (!ok) {
            // serious problem
            this.displayField = layer.objectIdField;
            //console.warn(this.key+" - setting displayField: "+this.displayField);
          }

          ok = this.checkFieldNameProperty("uniqueIdField",fields,null);
          if (!ok) {
            // serious problem, mapping features from 3D to 2D won't work
            this.uniqueIdField = layer.objectIdField;
            //console.warn(this.key+" - setting uniqueIdField: "+this.uniqueIdField);
          }

          ok = this.checkFieldNameProperty("subCategoryField",fields,null);
          if (!ok) {
            // TODO this is temporary
            if (this.isAiimEvents()) {
              if (typeof this.subCategoryField === "string" &&
                  this.subCategoryField.toLowerCase() === "category_subtype") {
                this.subCategoryField = null;
                //console.warn(this.key+" - setting subCategoryField from category_subtype to null");
              }
            }
          }

          ok = this.checkFieldNameProperty("subTitleField",fields,null);
          if (!ok) {
            // TODO this is temporary
            if (this.isAiimPeople()) {
              if (typeof this.subTitleField === "string" &&
                  this.subTitleField.toLowerCase() === "name_subtitle") {
                this.subTitleField = "unit_name";
                this.checkFieldNameProperty("subTitleField",fields,null);
                //console.warn(this.key+" - setting subTitleField from name_subtitle to " + this.subTitleField);
              }
            }
          }

          if (typeof this.searchFields === "string" && this.searchFields.indexOf("[") === 0) {
            try {
              let sf = JSON.parse(this.searchFields);
              this.searchFields = sf;
            } catch (exsf) {
              console.warn("Error parsing search fields for",this.name,", searchFields=",this.searchFields);
              console.error(exsf);
            }
          }

          if (Array.isArray(this.searchFields) && this.searchFields.length > 0) {
            let flds = [];
            this.searchFields.forEach((v) => {
              v2 = aiimUtil.findFieldName(fields,v);
              if (typeof v2 === "string" && v2.length > 0) {
                let lc = v2.toLowerCase();
                if (lc === "date_start" || lc === "date_end") {
                  console.warn(this.key+" - unsupported searchField: "+v);
                } else {
                  flds.push(v2);
                }
              } else {
                console.warn(this.key+" - invalid searchField: "+v);
              }
            });
            this.searchFields = flds;
          }
          if (Array.isArray(this.searchFields)) {
            if (this.searchFields.length === 0) {
              this.searchFields = null;
            }
          } else if (typeof this.searchFields === "string") {
            this.searchFields = null;
            v2 = aiimUtil.findFieldName(fields,this.searchFields);
            if (typeof v2 === "string" && v2.length > 0) {
              this.searchFields = [v2];
            } else {
              console.warn(this.key+" - invalid searchField: "+this.searchFields);
              this.searchFields = null;
            }
          }

          v = this.suggestionTemplate;
          v = trimQuotes(v);
          if (typeof v === "string" && v.length > 0) {
            checkSuggestionTemplate(layer,v);
          } else {
            this.suggestionTemplate = null;
          }

          resolve();
        }).catch(ex => {
          console.log("Failed to load source layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  findField(fields,fieldName,fieldMappingName) {
    if (!fields) fields = this.getFields();
    if (this.mappings && this.mappings.hasOwnProperty(fieldMappingName) &&
        this.mappings[fieldMappingName]) {
      fieldName = this.mappings[fieldMappingName];
    }
    return aiimUtil.findField(fields,fieldName);
  }

  findSubTypeByFeature(feature) {
    if (feature && feature.attributes && this.subCategoryField && this.subTypes) {
      const v = aiimUtil.getAttributeValue(feature.attributes,this.subCategoryField);
      return this.findSubTypeByValue(v);
    }
    return null;
  }

  findSubTypeByValue(v) {
    let subType = null;
    if (v !== undefined && v != null) {
      if (this.subTypesByValue && this.subTypesByValue[v]) {
        return this.subTypesByValue[v];
      }
      this.subTypes.some(st => {
        if (v === st.value) {
          subType = st;
          return true;
        }
        return false;
      });
    }
    return subType;
  }

  getAiimAddressInfo(feature) {
    const info = {
      unitId: null,
      unitName: null,
      sectionId: null,
      sectionName: null,
      levelId: null,
      levelName: null,
      levelShortName: null,
      levelNumber: null,
      verticalOrder: null,
      facilityId: null,
      facilityName: null,
      siteId: null,
      siteName: null,
    };
    if (feature && feature.attributes) {
      const a = feature.attributes;

      if (this.isAiimUnits()) {
        info.unitId = aiimUtil.getAttributeValue(a,FieldNames.UNIT_ID);
        info.unitName = aiimUtil.getAttributeValue(a,FieldNames.NAME);
      } else {
        info.unitId = aiimUtil.getAttributeValue(a,FieldNames.UNIT_ID);
        info.unitName = aiimUtil.getAttributeValue(a,FieldNames.UNIT_NAME);
      }

      if (this.isAiimSections()) {
        info.sectionId = aiimUtil.getAttributeValue(a,FieldNames.SECTION_ID);
        info.sectionName = aiimUtil.getAttributeValue(a,FieldNames.NAME);
      } else {
        info.sectionId = aiimUtil.getAttributeValue(a,FieldNames.SECTION_ID);
        info.sectionName = aiimUtil.getAttributeValue(a,FieldNames.SECTION_NAME);
      }

      if (this.isAiimFacilities()) {
        info.facilityId = aiimUtil.getAttributeValue(a,FieldNames.FACILITY_ID);
        info.facilityName = aiimUtil.getAttributeValue(a,FieldNames.NAME);
      } else {
        info.facilityId = aiimUtil.getAttributeValue(a,FieldNames.FACILITY_ID);
        info.facilityName = aiimUtil.getAttributeValue(a,FieldNames.FACILITY_NAME);
      }

      if (this.isAiimSites()) {
        info.siteId = aiimUtil.getAttributeValue(a,FieldNames.SITE_ID);
        info.siteName = aiimUtil.getAttributeValue(a,FieldNames.NAME);
      } else {
        info.siteId = aiimUtil.getAttributeValue(a,FieldNames.SITE_ID);
        info.siteName = aiimUtil.getAttributeValue(a,FieldNames.SITE_NAME);
      }

      info.levelNumber = aiimUtil.getAttributeValue(a,FieldNames.LEVEL_NUMBER);
      info.verticalOrder = aiimUtil.getAttributeValue(a,FieldNames.VERTICAL_ORDER);
      if (this.isAiimLevels()) {
        info.levelId = aiimUtil.getAttributeValue(a,FieldNames.LEVEL_ID);
        info.levelName = aiimUtil.getAttributeValue(a,FieldNames.NAME);
        info.levelShortName = aiimUtil.getAttributeValue(a,FieldNames.NAME_SHORT);
      } else {
        info.levelId = aiimUtil.getAttributeValue(a,FieldNames.LEVEL_ID);
        info.levelName = aiimUtil.getAttributeValue(a,FieldNames.LEVEL_NAME);
        if (typeof info.levelNumber === "number") {
          info.levelShortName = ""+info.levelNumber;
        }
        const levels = Context.getInstance().aiim.datasets.levels;
        if (levels) {
          info.levelShortName = levels.getShortNameIfLoaded(info.facilityId,info.levelNumber);
        }
      }

    }
    return info;
  }

  getFields() {
    if (this.layer2D) return this.layer2D.fields;
  }

  getItemCardInfo(subTypeValue) {
  }

  getObjectIdField() {
    if (this.layer2D) {
      return this.layer2D.objectIdField;
    }
    return "objectid";
  }

  getOrderByInfo(subTypeValue) {
  }

  getSubTitle(feature) {
    if (!feature) return null;
    if (this.subTitleField) {
      let v = feature.attributes[this.subTitleField];
      if (typeof v === "number") v = "" + v;
      return v;
    }
  }

  getTitle(feature) {
    if (!feature) return null;
    // let title = null;
    // if (this.displayField) {
    //   title = feature.attributes[this.displayField];
    // }
    // console.log(this.key,"title",title,this.displayField,this,feature);

    if (this.displayField) {
      let v = feature.attributes[this.displayField];
      if (typeof v === "number") v = "" + v;
      return v;
    }
  }

  isAiimEvents() {
    return (this.key && this.key.toLowerCase() === "events");
  }

  isAiimFacilities() {
    return (this.key && this.key.toLowerCase() === "facilities");
  }

  isAiimLevels() {
    return (this.key && this.key.toLowerCase() === "levels");
  }

  isAiimPeople() {
    return (this.key && this.key.toLowerCase() === "people");
  }

  isAiimSections() {
    return (this.key && this.key.toLowerCase() === "sections");
  }

  isAiimSites() {
    return (this.key && this.key.toLowerCase() === "sites");
  }

  isAiimUnits() {
    return (this.key && this.key.toLowerCase() === "units");
  }

  isAiimZones() {
    return (this.key && this.key.toLowerCase() === "zones");
  }

  isReservations() {
    return (this.key && this.key.toLowerCase() === "reservations");
  }

  isPeopleLayer() {
    //if (this.layer2D && this.layer2D.title === "People") return true;
    let aiim = Context.instance.aiim;
    if (this.layer2D && aiim && aiim.datasets && aiim.datasets.people) {
      return (this.layer2D === aiim.datasets.people.layer2D);
    }
    return false;
  }

  makeCaption(feature) {
    return this.getTitle(feature);
    /*
    let caption = "???"; // TODO
    if (this.suggestionTemplate && feature) {
      let a = feature.attributes;
      let v = esriLang.substitute(a,this.suggestionTemplate);
      if (typeof v === "string" && v.length > 0) {
        caption = v;
      }
    }
    return caption;
    */
  }

  makeSearchResult(view, feature) {
    const caption = this.makeCaption(feature);
    let extent = null;

    // if (view) {
    //   const scale = view.scale;
    //   const zoomScale = 0; // TODO ?
    //   const geometryExtent = createExtentFromGeometry(feature.geometry,view,scale);
    //   extent = zoomScale ? scaleExtent(geometryExtent.clone(),view, zoomScale) : geometryExtent;
    // }

    const searchResult = {
      extent: extent,
      feature: feature,
      name: caption,
      key: feature.attributes[this.uniqueIdField], // TODO uniqueIdField is required
      sourceIndex: this.index
    };
    return searchResult;
  }

  mixin(props) {
    if (props !== undefined && props !== null && typeof props === "object") {
      Object.assign(this,props);
      if (props.mappings) {
        const mappings = props.mappings;
        let v = mappings.uniqueIdField;
        if (typeof v === "string" && v.length > 0) this.uniqueIdField = v;
        v = mappings.displayField;
        if (typeof v === "string" && v.length > 0) this.displayField = v;
        if (mappings.hasOwnProperty("subTitleField") && mappings.subTitleField) {
          this.subTitleField = mappings.subTitleField
        }
      }
    }
    return this;
  }

  namedFieldMappings() {
    const mappings = this.mappings || {};
    const named = {}, tracked = {};
    const add = (name,key) => {
      let value = mappings[key];
      if (name && value) {
        named[name.toLowerCase()] = value;
        tracked[key.toLowerCase()] = value;
      }
    };
    const add2 = (name,value) => {
      if (name && value && !tracked[name.toLowerCase()]) {
        named[name.toLowerCase()] = value;
      }
    };
    add(FieldNames.SITE_ID,"siteIdField");
    add(FieldNames.SITE_NAME,"siteNameField");
    add(FieldNames.FACILITY_ID,"facilityIdField");
    add(FieldNames.FACILITY_NAME,"facilityNameField");
    add(FieldNames.LEVEL_ID,"levelIdField");
    add(FieldNames.LEVEL_NAME,"levelNameField");
    add(FieldNames.LEVEL_NUMBER,"levelNumberField");
    add(FieldNames.UNIT_ID,"unitIdField");
    add(FieldNames.UNIT_NAME,"unitNameField");
    add(FieldNames.VERTICAL_ORDER,"verticalOrderField");
    Object.keys(mappings).forEach(k => {
      let v = mappings[k];
      if (typeof k === "string" && k.length > 0) {
        add2(k,v);
      }
    });
    return named;
  }

}
