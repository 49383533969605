import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import OfficePlan from "../../../base/OfficePlan";
import Savebar from "../support/Savebar";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";
import WallsVM from "./WallsVM";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as component from "../../../../components/util/component";
import * as editorUtil from "../support/editorUtil";
import Attributes from "./Attributes";
import * as sourceUtil from "../../../base/sourceUtil";
import * as wallTypesUtil from "../../../miniapps/configurator/wallTypesUtil";
import UseType from "./UseType";
import {ModalController} from "../../Modal";

import LengthAndWidth from "../support/LengthAndWidth";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";

import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import { 
  CalciteCheckbox, 
  CalciteLabel,
  CalciteNotice
} from '@esri/calcite-components-react';

import {
  HelpTip,
  getActiveHelpTip,
  setHelpTip
} from "../redux";

import PanelHeaderTools, { HeaderToolType }  from "../support/PanelHeaderTools";
import RotateAngle from "../support/RotateAngle";

type DrawToolType = "polyline" | "point";

interface IWallsPanelProps {
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}

interface IWallsPanelState {
  isAttrMode?: boolean,
  cutUnitArea?: boolean,
  drawTool: DrawToolType,
  newWallOptions?: {
    title: string,
    layer: __esri.FeatureLayer,
    type: "detail",
    allowName: boolean,
    forSplit: boolean,
    feature: __esri.Graphic,
    topFields: string[],
    bottomFields: string[],
    showAsterisk: boolean,
    useGroup: boolean
  },
  canSave?: boolean,
  layerLoaded?: boolean,
  thicknessInMapUnits: number,
  templatePrototype?: any
}

class WallsPanel extends React.Component<IWallsPanelProps, IWallsPanelState> {

  attributesComponent: Attributes;
  newWallForm: __esri.FeatureForm;
  viewModel: WallsVM;
  divFeatureTemplatesWalls;
  ffValueChangeHandle: __esri.WatchHandle = null;

  _mounted = false;

  constructor(props: IWallsPanelProps) {
    super(props);

    this.divFeatureTemplatesWalls = React.createRef();
    this.viewModel = new WallsVM();

    this.state = component.newState({
      isAttrMode: false,
      cutUnitArea: false,
      drawTool: "polyline",
      isShowTip: true,
      newWallOptions: null,
      canSave: false,
      thicknessInMapUnits: this.viewModel.lengthAndWidthInfo.thicknessInMapUnits,
      templatePrototype: null,
      layerLoaded: sourceUtil.getDetailsLayer() != null
    });

    this.viewModel.onAttributeUpdate = (fieldName: string, value: any) => {
      if (this.state.isAttrMode && this.attributesComponent) {
        const wgt = this.attributesComponent._coreWidget;
        if (wgt && wgt.viewModel) wgt.viewModel.setValue(fieldName, value);
      }
    }

    this.viewModel.onChange = () => {
      this.setState({ canSave: this.viewModel.canSave() });
      component.refresh(this);
    }

    this.viewModel.onDrawComplete = (tool) => { 
      const feature = this.viewModel.getNewWallGraphic();
      let newWallOptions;
      if (feature) {
        const i18n = Context.instance.i18n;
        const detailsLayer= sourceUtil.getDetailsLayer();
        const wallTypeValues = wallTypesUtil.getWallTypes();

        newWallOptions = {
          title: i18n.editor.newWall,
          layer: detailsLayer,
          type: "detail",
          allowName: true,
          forSplit: true,
          feature: feature,
          topFields: ["use_type", "height_relative"],
          bottomFields: ["level_id","detail_id"],
          showAsterisk: true,
          useGroup: true,
          useTypes: wallTypeValues
        }
        this.setState({ newWallOptions: newWallOptions });
      }
      this.setState({ isAttrMode: true, canSave: this.viewModel.canSave() });
    };
  }

  componentDidMount() {
    this._mounted = true;
    this._initTemplatePicker();
    sourceUtil.getGrid().activate();
    this.setState({ canSave: this.viewModel.canSave() });
    component.own(this,[
      Topic.subscribe(Topic.PlanOpened, () => {
        this.setState({ layerLoaded: sourceUtil.getDetailsLayer() != null })
      }),
      Topic.subscribe(Topic.PlanModified,params => {
        try {
          if (this._mounted && params && (params.wasUndoRedo || params.wasReconciled)) {
            this.reset(false);
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    ]);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.ffValueChangeHandle && this.ffValueChangeHandle.remove();
    sourceUtil.getGrid().deactivate();
    this.viewModel.onUnMount();
    component.componentWillUnmount(this);
  }

  drawNewWall=(evtTemplate)=> {
    const templatePrototype = evtTemplate.template.prototype;
    this.setState({templatePrototype});
    this.viewModel.activateDrawWall(templatePrototype,this.state.drawTool);
  }

  _initTemplatePicker=()=> {
    const detailsLayer= sourceUtil.getDetailsLayer();
    if (!this.isLoaded() || !detailsLayer) {
      console.log("Details Layer not yet ready...");
      setTimeout(this._initTemplatePicker, 500);
      return;
    }

    if (!detailsLayer.templates || detailsLayer.templates.length) {
      const emptyTemplate = new Context.instance.lib.esri.FeatureTemplate({
        drawingTool: "esriFeatureEditToolLine",
        name: "Default Empty Wall",
        description: "",
        prototype: {
          "attributes": {
            "DETAIL_ID": "",
            "HEIGHT_RELATIVE": null,
            "LEVEL_ID": "",
            "USE_TYPE": ""
          }
        },
      });
      if (!Array.isArray(detailsLayer.templates))
        detailsLayer.templates = [];
      detailsLayer.templates.splice(0, 0, emptyTemplate);
    }
  }

  isLoaded = () => {
    const planner = Context.instance.spaceplanner.planner;
    return planner && planner.hasValidPlan(); 
  };

  render() {
    const i18n = Context.instance.i18n;
    const { tip } = this.props;
    const { drawTool, isAttrMode, layerLoaded }= this.state;
    const detailsLayer = sourceUtil.getDetailsLayer();
    const newWallOptions = this.state.newWallOptions;
    const feature = newWallOptions && newWallOptions.feature;
    const fldUseType = detailsLayer && aiimUtil.findFieldName(detailsLayer.fields, FieldNames.DETAILS_USE_TYPE);
    let prompt = i18n.editor.walls.drawWallInstructions;

    if (!isAttrMode && (drawTool === "point")) {
      prompt = i18n.editor.walls.tipEnterDimensions;
    }

    return (
      <div className={`i-editor-sidebar-container i-editor-walls-container${!this.isLoaded() ? " i-editor-sidebar-disabled" : ""}`}>
        {this.renderHeaderTools()}
        <div className="i-editor-sidebar-content">
          {!isAttrMode &&
            <CalciteNotice open={!(tip && tip.visible) ? true : undefined} scale="s" closable icon="lightbulb"
              onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: "" })}>
              <div slot="message">{prompt}</div>
            </CalciteNotice>
          }
          {this.renderWallOptions()}
          
          {layerLoaded && 
            <div style={{overflowY: "auto"}}>
              <UseType
                hidden={!!this.state.isAttrMode}
                isShowTip={!(tip && tip.visible)} 
                layer={detailsLayer} 
                drawNewWall={this.drawNewWall} 
                viewModel={this.viewModel} 
              />
            </div>
          }
          {feature && isAttrMode &&
            <div className="i-editor-units-attributes" style={{display:"flex", flexDirection:"column", width:"100%"}}>
              <div style={{ lineHeight:"1.4rem", fontSize:"0.875rem", fontWeight:"500"}}>
                {i18n.editor.walls.attributes}
              </div>
              <Attributes 
                feature={newWallOptions.feature} 
                options={newWallOptions}
                onCreateForm={(ac,ff) => {
                  this.attributesComponent = ac;
                  this.ffValueChangeHandle && this.ffValueChangeHandle.remove();
                  this.newWallForm = ff;
                  this.ffValueChangeHandle = ff.on("value-change",info => {
                    if (info.valid && info.fieldName===fldUseType) {
                      const f = {attributes: { ... info.feature.attributes}};
                      f.attributes[info.fieldName] = info.value;
                      this.viewModel.useTypeChanged(f);
                    }
                  });
                }}
              />
          </div>}
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderSavebar()}
        </div>
      </div>
    )
  }

  renderHeaderTools = () => {
    const i18n = Context.instance.i18n;
    const { drawTool, isAttrMode, templatePrototype } = this.state;
    const activeTools = [];
    if (sourceUtil.getGrid().isVisible()) activeTools.push("grid")

    if (!isAttrMode) {
      const tools: HeaderToolType[] = ["grid","separator","drawWall", "placeWall"];
      const selectedTool = drawTool==="polyline" ? "drawWall" : drawTool==="point" ? "placeWall" : null; 
      return (
        <div className="i-editor-sidebar-toolbar">
          <PanelHeaderTools 
            key="drawTools" 
            className="i-flex-between"
            tools={tools}
            selectedTool={selectedTool}
            activeTools={activeTools}
            onButtonClick={(e, toolInfo) => {
              const tool = toolInfo && toolInfo.name;
              if (tool==="drawWall") {
                this.setState({drawTool: "polyline"});
                if (templatePrototype) this.viewModel.activateDrawWall(templatePrototype,"polyline");
              } else if (tool==="placeWall") {
                this.setState({drawTool: "point"});
                if (templatePrototype) this.viewModel.activateDrawWall(templatePrototype,"point");
              } else if (tool==="grid") {
                sourceUtil.getGrid().setVisible(!sourceUtil.getGrid().isVisible());
                component.refresh(this);
              }
            }}
          />
        </div>
      )
    }

    if (isAttrMode) {
      const feature = this.viewModel.getActiveFeature();
      const rotatePopover = feature && (
        <RotateAngle
          geometry={feature.geometry}
          onOpen={comp => {
            comp && (comp.geometry = feature.geometry);
          }}
          onRotate={(angle, geometry) => {
            this.viewModel.updateWallGeometry(geometry)
          }}
        />
      )
      return (
        <div className="i-editor-sidebar-toolbar">
          <PanelHeaderTools
            key="editTools" 
            className="i-flex-between"
            tools={["grid","separator","rotate", "flipV", "flipH", "separator", "zoomIn"]}
            toolInfos={{"rotate": {name: "rotate", icon: "rotate", text: i18n.editor.headerTools.rotate, jsx: rotatePopover}}}
            activeTools={activeTools}
            onButtonClick={(e, toolInfo) => {
              const tool = toolInfo && toolInfo.name;
              if (tool==="zoomIn") {
                const f = this.viewModel.getActiveFeature();
                f && editorUtil.onZoomToFeature(f);
              } else if (tool==="flipV") {
                this.viewModel.flip("v");
              } else if (tool==="flipH") {
                this.viewModel.flip("h");
              } else if (tool==="grid") {
                sourceUtil.getGrid().setVisible(!sourceUtil.getGrid().isVisible());
                component.refresh(this);
              }
            }}
          />
        </div>
      );
    }

    return null;
  }

  renderSavebar() {
    return (
      <Savebar
        parentPanelKey="drawWall"
        resetDisabled={false}
        saveDisabled={!this.state.canSave}
        editIsActive={!!this.state.isAttrMode}
        onReset={this.reset} 
        onSave={this.save}
      />
    )
  }

  renderWallOptions() {
    const i18n = Context.instance.i18n;
    const { drawTool, isAttrMode } = this.state
    const thicknessInMapUnits = this.viewModel.lengthAndWidthInfo.thicknessInMapUnits;
    const canCut = (this.viewModel.lengthAndWidthInfo.thicknessInMapUnits > 0);
    return (
      <div className="i-editor-wall-options">
        {!isAttrMode &&
          <div className="i-separated-form-section">
            <LengthAndWidth 
              showLabel //showLabel={drawTool === "point"}
              showLength={drawTool === "point"}
              showThickness
              //thicknessLabel={i18n.editor.dimensions.wallThickness}
              lengthAndWidthInfo={this.viewModel.lengthAndWidthInfo}
              onChange={(lengthAndWidthInfo: ILengthAndWidthInfo) => {
                const templatePrototype = this.state.templatePrototype;;
                this.viewModel.lengthAndWidthInfo = lengthAndWidthInfo;
                this.setState({thicknessInMapUnits: lengthAndWidthInfo.thicknessInMapUnits});
                if (templatePrototype) this.viewModel.activateDrawWall(templatePrototype,drawTool);
              }}
            />
          </div>
        }
        {isAttrMode && canCut &&
          <div className="i-miniapps-padding-1-y">
            <CalciteLabel layout="inline" className="i-cursor-pointer">
              <CalciteCheckbox 
                disabled={!canCut ? true : undefined}
                checked={this.state.cutUnitArea ? true : undefined}
                onCalciteCheckboxChange={(e) => {
                  const checked = e.target.checked;
                  this.setState({
                    cutUnitArea: checked
                  })
                  this.viewModel.setCutUnitArea(checked);
                }}>
              </CalciteCheckbox>
              {i18n.editor.walls.cutWallArea}
            </CalciteLabel>
          </div>
        }
      </div>
    )
  }

  reset = async (isConfirm) => {
    const i18n = Context.instance.i18n;
    let isReset = true; 
    if (isConfirm) {
      const confirmResult = await ModalController.confirm({
        title: i18n.editor.walls.resetModalTitle,
        message: i18n.editor.walls.resetModalMessage,
        okLabel: i18n.general.reset,
        cancelLabel: i18n.general.cancel,
        showOKCancel: true,
        closeOnOK: true,
        closeOnCancel: true
      });
      isReset = confirmResult.ok;
    } 
    if (isReset) {
      this.viewModel.clear();
      this.viewModel.cutUnitArea = false;
      const { drawTool, templatePrototype } = this.state;
      this.setState({
        isAttrMode: false, 
        canSave: false,
        cutUnitArea: false
      });
      if (templatePrototype) this.viewModel.activateDrawWall(templatePrototype,drawTool);
    }
  }

  save = async () => {
    if(this.state.canSave) {
      const guard = new TransactionGuard({force:true});
      try {
        guard.close();
        await this.viewModel.save();
        this.reset(false);
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      } catch(ex) {
        console.error(ex);
        guard.close();
        Topic.publishErrorUpdatingData(ex.message);
      }
    } 
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("walls", tip))
});

export default connect(mapStateToProps, mapDispatchToProps)(WallsPanel);
