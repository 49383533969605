import React from "react";

import Header from "../Header/Header";
import InfoPanel from "../InfoPanel/InfoPanel";
import ModalController from "../../common/Modal/ModalController";
import Navigator from "../Directions/Navigator";
import Sidebar from "../Sidebar/Sidebar";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  main: "i-layout"
};

export default class Layout extends React.Component {

  _handles = [];

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ShowModal,(params) => {
        const controller = new ModalController();
        controller.show(params.content)
      })
    ]);
  }

  componentWillUnmount() {
    //console.log("Layout::componentWillUnmount");
    component.componentWillUnmount(this);
  }

  render() {
    return (
      <div className={CSS.main}>
        <Header />
        <Sidebar />
        <InfoPanel />
        <Navigator />
      </div>
    );
  }

}
