import React from "react";

import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import SearchableMultiSelect from "../../../components/common/SearchableMultiSelect/SearchableMultiSelect";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as sourceUtil from "../../base/sourceUtil";

export default class ReservationManagers extends React.Component {

  componentId;
  roleData;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  handleItemsUpdated = (items) => {
    const areaRolesTable = OfficePlan.getActive().areaRolesTable;
    if (areaRolesTable) {
      let areaId = this.props.areaId;
      let role = 1;
      this.roleData = areaRolesTable.getRoleData(areaId,role,items);
      if (this.props.onRoleDataUpdated) {
        this.props.onRoleDataUpdated(this.roleData);
      }
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const peopleLayer = sourceUtil.getPeopleLayer();
    const fields = peopleLayer.fields;
    const knownasField = aiimUtil.findFieldName(fields, FieldNames.PEOPLE_FULLNAME);
    const emailField = aiimUtil.findFieldName(fields, FieldNames.PEOPLE_EMAIL);
    const objectIdField = peopleLayer.objectIdField;
    if (!knownasField || !emailField) {
      return null;
    }
    const peopleDataset = {
      layer2D: peopleLayer,
      url: peopleLayer.url+"/"+peopleLayer.layerId
    }

    let initialFeatures = this.props.initialPeopleFeatures;

    // Remove duplicate people
    // const postQueryProcessing = (features) => {
    //   const listFeatures = [];
    //   features.forEach((feature) => {
    //     let email = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
    //     if (email && typeof email === "string" && email.length > 0) {
    //       const existingPerson = listFeatures.find((listFeature) => {
    //         const listEmail = aiimUtil.getAttributeValue(listFeature.attributes, FieldNames.PEOPLE_EMAIL);
    //         return email === listEmail;
    //       });
    //       if (!existingPerson) {
    //         listFeatures.push(feature);
    //       }
    //     }
    //   });
    //   return listFeatures;
    // };

    const multiSelectProps = {
      allowSelf: true,
      dataset: peopleDataset,
      displayField: knownasField,
      sortField: knownasField,
      subtitleField: emailField,
      searchFields: [knownasField, emailField],
      searchPlaceholder: i18n.spaceplanner.reservationManager.selectOneOrMore,
      searchIcon: (<UserIcon />),
      initialClause: "1=1", // @todo
      maxItems: 100,
      onItemsUpdated: this.handleItemsUpdated,
      //postQueryProcessing: postQueryProcessing,
      preSelectedItems:
        initialFeatures &&
        Array.isArray(initialFeatures) &&
        initialFeatures.map((feature) => {
          return {
            id: feature.attributes[objectIdField],
            display: feature.attributes[knownasField],
            subtitle: feature.attributes[emailField],
            feature,
            checked: true
          };
        }),
      customizer: {
        // This won't be used with the inclusion of "preSelectedItems" to prevent UX lag - #4573
        onItemCreated: item => {
          if (initialFeatures) {
            item.checked = initialFeatures.some(f => {
              const a = item.feature.attributes[emailField];
              const b = f.attributes[emailField];
              return (a === b);
            })
          }
        }
      }
    };

    return (
      <div style={{margin: "0 0.3875rem"}}>
        <div
          style={{
            fontSize: "1rem",
            marginBottom: "0.25rem",
            display: "inline-block"
          }}
        >{i18n.spaceplanner.reservationManager.plural}</div>
        <SearchableMultiSelect {...multiSelectProps} isReservationManager={true}/>
      </div>
    )
  }

  static showModal(peopleFeatures, areaItem, areaId, areaName, onSaved) {
    let controller, updating = false;
    let roleData;
    const i18n = Context.instance.i18n;

    const onOK = () => {
      if (updating) return;
      if (!roleData) {
        if (controller) controller.close();
        return;
      }
      updating = true;
      if (controller) controller.close();
      const guard = new TransactionGuard({force: true});
      let areasTable = OfficePlan.getActive().areasTable;
      let areaRolesTable = OfficePlan.getActive().areaRolesTable;
      areaRolesTable.prepUsernames(roleData).then(() => {
        return areasTable.updateAreaRoles(areaItem,roleData).then(() => {
          // if (controller) controller.close();
          if (typeof onSaved === "function") onSaved();
          guard.close();
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
        })
      }).catch(ex => {
        // if (controller) controller.close();
        guard.close();
        console.error(ex);
        updating = false;
        Topic.publishErrorUpdatingData(ex.submessage);
      })
    }

    const onReservationManagersUpdated = (data) => {
      roleData = data;
    }

    const modalProps = {
      dialogStyle: { width: "35vw" },
      title: areaName,
      showOKCancel: true,
      closeOnOK: false,
      onOK: onOK,
      okLabel: i18n.general.save
    };

    const content = (
      <ReservationManagers
        areaItem={areaItem}
        initialPeopleFeatures={peopleFeatures}
        onRoleDataUpdated={onReservationManagersUpdated} />
    );
    controller = new ModalController({});
    controller.show(modalProps, content);
  }

}
