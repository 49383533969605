import React from "react";

import Icons from "../../util/Icons";

const CSS = {
  menuOption: "i-menu-option",
  menuOptionConfig: "i-menu-option-themeDropdown",
  emptyIcon: "svg-icon i-icon-empty"
};

export default class MenuOption extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    if (this.props.disabled) return;
    if (this.props.onClick) {
      setTimeout(() => {
        this.props.onClick(this);
      },10);
    }
  }

  render() {
    const hashref = "#";

    let chk = null, dataChecked = "n";
    if (!this.props.noIcon) {
      if (this.props.checked) {
        dataChecked = "y";
        chk = (<span key="icon">{Icons.checkMark()}</span>);
      } else {
        chk = (<span key="icon" className={CSS.emptyIcon}></span>);
      }
    }
    if (this.props.checked) {
      dataChecked = "y"
    }

    let cls = CSS.menuOption;
    if (this.props.type === "themeDropdown") cls = CSS.menuOptionConfig;

    if (this.props.disabled) cls += " i--disabled";
    const item = (
      <li role="none">
        <a className={cls} href={hashref} role="menuitem button" tabIndex="0"
            onClick={this.clicked} data-i-checked={dataChecked}>
          {chk}
          <span className={this.props.labelClass} key="label">{this.props.label}</span>
        </a>
      </li>
    );
    return item;
  }

}
