// spaceplanner.base
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import QueryAll from "../../../spaceplanner/base/QueryAll";
import * as aiimUtil from "../../../aiim/util/aiimUtil";

// util
import BaseClass from "../../../util/BaseClass";

export default class SearchableMultiSelectViewModel extends BaseClass {
  //----------------------------------
  //
  //  Properties
  //
  //----------------------------------

  //----------------------------------
  //  allowSelf
  //----------------------------------

  allowSelf = true;

  //----------------------------------
  //  dataset
  //----------------------------------

  dataset = null;

  //----------------------------------
  //  displayField
  //----------------------------------

  displayField = null;

  //----------------------------------
  //  initialClause
  //----------------------------------

  initialClause = null;

  //----------------------------------
  //  items
  //----------------------------------

  items = [];

  //----------------------------------
  //  maxItems
  //----------------------------------

  maxItems = null;

  //----------------------------------
  //  searchFields
  //----------------------------------

  searchFields = null;

  //----------------------------------
  //  searchTerm
  //----------------------------------

  searchTerm = null;

  //----------------------------------
  //  singleSelection
  //----------------------------------

  singleSelection = false;

  //----------------------------------
  //  sortField
  //----------------------------------

  sortField = null;

  //----------------------------------
  //  subtitleField
  //----------------------------------

  subtitleField = null;

  //----------------------------------
  //  view
  //----------------------------------

  view = null;

  //----------------------------------
  //
  //  Lifecycle
  //
  //----------------------------------

  constructor(props) {
    super(props);

    const {
      dataset,
      displayField,
      initialClause,
      maxItems,
      searchFields,
      singleSelection,
      sortField,
      subtitleField,
      view
    } = props;

    this.dataset = dataset;
    this.displayField = displayField;
    this.initialClause = initialClause;
    this.maxItems = maxItems;
    this.searchFields = searchFields;
    this.singleSelection = singleSelection;
    this.sortField = sortField;
    this.subtitleField = subtitleField;
    this.view = view;

    if (typeof props.allowSelf === "boolean") {
      this.allowSelf = props.allowSelf;
    }

    //this.initialize();
  }

  async initialize() {
    await this._queryDataset();
  }

  //----------------------------------
  //
  //  Public Methods
  //
  //----------------------------------

  /**
   * Set the active search text and filter list items
   *
   * @param {string} searchTerm
   */
  setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
    this._onItemsUpdated();
  }

  /**
   * Find the item by object ID and toggle the *checked* property
   *
   * @param {number} objectId
   */
  selectFeatureById(objectId,forInit) {
    const { items, maxItems, singleSelection } = this;
    const index = items.findIndex((item) => item.id == objectId);
    if (index === -1) {
      return;
    }

    const item = items[index];
    if (singleSelection) {
      const selectedItems = this.getSelectedItems();
      const selected = selectedItems.length > 0 && selectedItems[0];
      if (selected) {
        selected.checked = false;
      }
    } else if (!item.checked && this.getSelectedItems().length === maxItems) {
      return;
    }

    items[index].checked = !item.checked;
    if (forInit) this.searchTerm = item.display;
    this._onItemsUpdated();
  }

  /**
   * @returns The currently selected items
   */
  getSelectedItems() {
    return this.items.filter((item) => !!item.checked);
  }

  /**
   * Pre-populate items that are known to be selected
   *
   * @param {any[]} items
   */
  setPreSelectedItems(items) {
    if (items) {
      this.items = items;
      this._onItemsUpdated();
    }
  }

  //----------------------------------
  //
  //  Private Methods
  //
  //----------------------------------

  /**
   * Query the provided dataset with the specified clause (default 1=1) to make the features
   * into a list
   */
  async _queryDataset() {
    const { postQueryProcessing } = this;
    const { layer2D: layer, url } = this.dataset;
    if (!layer) {
      return;
    }

    // Create the query for features to populate the list (using QueryAll to get every feature)
    const queryAll = new QueryAll();
    const query = layer.createQuery();
    query.returnGeometry = false;
    query.returnZ = false;
    query.outFields = ["*"];
    if (this.initialClause) {
      query.where = this.initialClause;
    }

    try {
      const result = await queryAll.execute(url, query, { layer });
      const features = result && result.features;
      if (!features.length) {
        return;
      }

      const listFeatures = typeof postQueryProcessing === "function" ? postQueryProcessing(features) : this._postQueryProcessing(features);
      this._populateList(listFeatures);
    } catch (e) {
      console.error(`Couldn't query layer: ${layer.title}`, e);
    }
  }

  _postQueryProcessing(features) {
    // this is for occupant selection
    let listFeatures = [], lcidx = {}, lcuser;
    if (!this.allowSelf) {
      const userEmail = Context.instance.user.getEmail();
      if (typeof userEmail === "string") lcuser = userEmail.toLowerCase();
    }
    features.forEach((feature) => {
      const email = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
      if (email && typeof email === "string") {
        const lc = email.toLowerCase();
        const isUser = !!(lcuser && lcuser === lc);
        const existingPerson = !!lcidx[lc];
        if (!existingPerson && !isUser) {
          lcidx[lc] = true;
          listFeatures.push(feature);
        }
      }
    })
    return listFeatures;
  }

  /**
   * Create *item* objects from the resulting features
   *
   * @param {any[]} features
   */
  _populateList(features) {
    const { displayField, subtitleField } = this;
    const { layer2D: layer } = this.dataset;
    const { objectIdField } = layer;
    // const onItemCreated = this.customizer && this.customizer.onItemCreated; // @todo Urban

    // Make a list of items for the list
    const items = this.items.length > 0 ? this.items : [];
    features.forEach((feature) => {
      const id = feature.attributes[objectIdField];
      const display = feature.attributes[displayField];
      const subtitle = feature.attributes[subtitleField];
      const item = { id, display, subtitle, feature, checked: false };

      // if (onItemCreated) onItemCreated(item); // @todo Urban, initializes item.checked

      // This prevents pre-selected items from being re-added
      if (!!!items.find((item) => item.id == id)) {
        items.push(item);
      }
    });
    this._sortItems(items);

    this.items = items;
    this._onItemsUpdated();
  }

  /**
   * Sort items by their sort field
   *
   * @param {any[]} items
   * @returns Sorted list of items
   */
  _sortItems(items) {
    const { sortField } = this;
    if (!items || items.length <= 1) {
      return;
    }

    items.sort((a, b) => {
      const valA = a.feature.attributes[sortField];
      const valB = b.feature.attributes[sortField];
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    });
  }

  /**
   * Filter the items by search and propogate new list to the component
   */
  _onItemsUpdated() {
    const { view } = this;
    const items = this._filterBySearch();
    //if (items) items = items.slice(0,100)
    view.onItemsUpdated(this.getSelectedItems());
    view.setState({ items });
  }

  /**
   * Filter the list of items by the search term (using search field values to compare
   * against)
   *
   * @returns Filtered list items
   */
  _filterBySearch() {
    const { items, searchTerm, searchFields } = this;
    if (!searchTerm || searchTerm.trim() === "") {
      return items;
    }

    const lc = searchTerm.toLowerCase();
    return items.filter((item) => {
      if (searchFields) {
        return searchFields.some((field) => {
          const value = item.feature.attributes[field];
          return (typeof value === "string" && value.toLowerCase().includes(lc));
        });
      } else {
        const { display, subtitle } = item;
        return (
          (typeof display === "string" && display.toLowerCase().includes(lc)) ||
          (typeof subtitle === "string" && subtitle.toLowerCase().includes(lc))
        );
      }
    });
  }
}
