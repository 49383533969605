import Context from "../../../../context/Context";
import * as val from "../../../../util/val";
import * as sourceUtil from "../../../base/sourceUtil";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as splitUtil from "./splitUtil"
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { LayerType } from "../../../../util/interfaces";
import { HitTest } from "../redux";

export function createDuplicateFeature(feature:__esri.Graphic, type: LayerType): Promise<HitTest> {
  if (type === "unit") {
    return initDuplicateUnit(feature);
  } else if (type === "detail") {
    return initDuplicateDetail(feature);
  } else if (type === "facility") {
    return initDuplicateFacility(feature);
  } else if (type === "level") {
    return initDuplicateLevel(feature);
  } else if (type === "site") {
    return initDuplicateSite(feature);
  }
}

function getOffSetValue() {
  return 2;
}

async function initDuplicateDetail(feature:__esri.Graphic) {
  const layer = sourceUtil.getDetailsLayer();
  const originalAttr = Object.assign({}, feature.attributes);
  const geometry = feature.geometry;
  const detailIdField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAIL_ID);
  const detailId = val.generateRandomId();
  const newAttributes = Object.assign({}, originalAttr);

  newAttributes[detailIdField] = detailId; 

  delete newAttributes[layer.objectIdField];
  const globalIdField = layer.fields.find(f => f.type === "global-id").name;
  delete newAttributes[globalIdField];

  const newGeometry = offsetPolyline(geometry as __esri.Polyline);
  const newFeature = new Context.instance.lib.esri.Graphic({
    attributes: newAttributes,
    geometry: newGeometry,
    symbol: splitUtil.getSymbol(layer, feature)
  }).toJSON();


  const newResults: HitTest = {
    feature: newFeature,
    oid: newFeature.attributes[layer.objectIdField],
    key: "detail"
  }

  return newResults;
}
// TODO: Implement
async function initDuplicateFacility(feature: __esri.Graphic) {
  const layer = sourceUtil.getFacilitiesLayer();
  const originalAttr = Object.assign({},feature.attributes);
  const originalGeometry = feature.geometry;
  
  aiimUtil.removeShapeAttributes(originalAttr);

  const idField = aiimUtil.findFieldName(layer.fields, FieldNames.FACILITY_ID);
  const nameField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
  
  const attributes = Object.assign({}, originalAttr);
  const newName = "New Facility " + new Date().getTime();
  const facilityId = val.generateRandomId();

  delete attributes[layer.objectIdField];
  delete attributes[aiimUtil.getGlobalIdField(layer)];
  attributes[idField] = facilityId;
  attributes[nameField] = newName;
  
  const geometry = offsetPolygon(originalGeometry as __esri.Polygon);
  const newFeature = new Context.instance.lib.esri.Graphic({
    attributes,
    geometry,
    symbol: splitUtil.getSymbol(layer, feature)
  }).toJSON();

  const newResults: HitTest = {
    feature: newFeature,
    oid: newFeature.attributes[layer.objectIdField],
    key: "facility"   
  }

  return newResults;
}
// TODO: Implement
async function initDuplicateLevel(feature: __esri.Graphic) {
  const layer = sourceUtil.getLevelsLayer();
  const originalAttr = Object.assign({}, feature.attributes);
  const originalGeometry = feature.geometry;
  
  aiimUtil.removeShapeAttributes(originalAttr);

  const idField = aiimUtil.findFieldName(layer.fields, FieldNames.LEVEL_ID);
  const nameField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
  
  const attributes = Object.assign({}, originalAttr);
  const newName = "New Level " + new Date().getTime();
  const levelId = val.generateRandomId();

  delete attributes[layer.objectIdField];
  delete attributes[aiimUtil.getGlobalIdField(layer)];
  attributes[idField] = levelId;
  attributes[nameField] = newName;
  
  const geometry = offsetPolygon(originalGeometry as __esri.Polygon);
  const newFeature = new Context.instance.lib.esri.Graphic({
    attributes,
    geometry,
    symbol: splitUtil.getSymbol(layer, feature)
  }).toJSON();

  const newResults: HitTest = {
    feature: newFeature,
    oid: newFeature.attributes[layer.objectIdField],
    key: "level"   
  }

  return newResults;
}
// TODO: Implement
async function initDuplicateSite(feature: __esri.Graphic) {
  const layer = sourceUtil.getSitesLayer();
  const originalAttr = Object.assign({}, feature.attributes);
  const originalGeometry = feature.geometry;
  
  aiimUtil.removeShapeAttributes(originalAttr);

  const idField = aiimUtil.findFieldName(layer.fields, FieldNames.SITE_ID);
  const nameField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
  
  const attributes = Object.assign({}, originalAttr);
  const newName = "New Site " + new Date().getTime();
  const siteId = val.generateRandomId();

  delete attributes[layer.objectIdField];
  delete attributes[aiimUtil.getGlobalIdField(layer)];
  attributes[idField] = siteId;
  attributes[nameField] = newName;
  
  const geometry = offsetPolygon(originalGeometry as __esri.Polygon);
  const newFeature = new Context.instance.lib.esri.Graphic({
    attributes,
    geometry,
    symbol: splitUtil.getSymbol(layer, feature)
  }).toJSON();

  const newResults: HitTest = {
    feature: newFeature,
    oid: newFeature.attributes[layer.objectIdField],
    key: "site"   
  }

  return newResults;
}
async function initDuplicateUnit(newUnit:__esri.Graphic){
  const originalAttr = Object.assign({}, newUnit.attributes);
  const geometry = newUnit.geometry;
  aiimUtil.removeShapeAttributes(originalAttr);
  const layer = sourceUtil.getUnitsLayer();
  const unitIdField = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
  const nameField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
  const nameLongField = aiimUtil.findFieldName(layer.fields, FieldNames.NAME_LONG);

  const attributes = Object.assign({}, originalAttr);
  const newName = "New Unit " + new Date().getTime();
  const unitId = val.generateRandomId();
  
  delete attributes[layer.objectIdField];
  delete attributes[aiimUtil.getGlobalIdField(layer)];
  attributes[unitIdField] = unitId;
  attributes[nameField] = newName;

  if (nameLongField) 
    attributes[nameLongField] = newName;
  
  // Assignment Type, Schedule Email, Reservation Method and Area ID set to null
  const fldAssignmentType = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const fldScheduleEmail = aiimUtil.findFieldName(layer.fields, FieldNames.SCHEDULE_EMAIL);  
  const fldReservationMethod = aiimUtil.findFieldName(layer.fields, FieldNames.RESERVATION_METHOD);
  const fldAreaId = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_AREA_ID);

  if(fldAssignmentType) attributes[fldAssignmentType] = "none";
  if(fldScheduleEmail) attributes[fldScheduleEmail] = null;
  if(fldReservationMethod) attributes[fldReservationMethod] = null;
  if(fldAreaId) attributes[fldAreaId] = null;

  const offsetGeometry = offsetPolygon(geometry as __esri.Polygon);
  const newFeature = new Context.instance.lib.esri.Graphic({
    attributes: attributes,
    geometry: offsetGeometry,
    symbol: splitUtil.getSymbol(layer, newUnit)
  }).toJSON();

  const newResults: HitTest = {
    feature: newFeature,
    oid: newFeature.attributes[layer.objectIdField],
    key: "unit"   
  }

  return newResults;
}

function offsetPolygon(geometry: __esri.Polygon) {
    const geometry2 = geometry.clone();
    const offsetVal = getOffSetValue();
    geometry2.hasZ = true;
    geometry.rings.forEach((ring,ringIndex) => {
      ring.forEach((coords,coordIndex) => {
        const point = new Context.instance.lib.esri.Point({
          hasZ: true,
          x: coords[0]+offsetVal,
          y: coords[1]-offsetVal,
          z: coords[2],
          spatialReference: geometry.spatialReference
        })
        const point2 = point.clone();
        if (point2) geometry2.setPoint(ringIndex,coordIndex,point2);
      })
    })
    return geometry2;
  }

  function offsetPolyline(geometry: __esri.Polyline) {
    const geometry2 = geometry.clone();
    const offsetVal = getOffSetValue();
    geometry2.hasZ = true;
    geometry.paths.forEach((path,pathIndex) => {
      path.forEach((coords,coordIndex) => {
        const point = new Context.instance.lib.esri.Point({
          hasZ: true,
          x: coords[0]+offsetVal,
          y: coords[1]-offsetVal,
          z: coords[2],
          spatialReference: geometry.spatialReference
        })
        const point2 = point.clone();
        if (point2) geometry2.setPoint(pathIndex,coordIndex,point2)
      })
    })
    return geometry2;
  }
