import React from "react";
import { connect } from "react-redux";

import Rdx from "../../../redux/Rdx";
import * as component from "../../util/component";

const CSS = {
  sidebarPanel: "i-sidebar-panel",
  sidebarPanelVisible: "i-sidebar-panel-visible"
};

class SidebarPanel extends React.Component {

  componentWillUnmount() {
    //console.log("SidebarPanel::componentWillUnmount");
  }

  getContent() {
    return component.getFirstChild(this);
  }

  // hide() {
  //   const active = (this.props.sidebarKey === this.props.rdxSidebarActiveKey);
  //   if (active) {
  //     Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,null);
  //     document.body.classList.remove(CSS.sidebarPanelOpen);
  //   }
  // }

  render() {
    const active = (this.props.sidebarKey === this.props.rdxSidebarActiveKey);
    if (!active) {
      if (!this.props.keepAlive) return null;
    }
    let className = CSS.sidebarPanel, style = null;
    if (active) className += " " + CSS.sidebarPanelVisible
    else style = {display: "none"};
    const content = this.getContent();
    return (
      <div key="sidebarPanel" className={className} style={style}>
        {content}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    rdxSidebarActiveKey: Rdx.getValue(state,Rdx.Keys.SIDEBAR_ACTIVE_KEY),
    rdxSidebarPanelsExpanded: Rdx.getValue(state,Rdx.Keys.SIDEBAR_PANELS_EXPANDED)
  }
}

export default connect(mapStateToProps)(SidebarPanel);
