import styled from "styled-components";
import Button from "calcite-react/Button";
import { unitCalc } from "calcite-react/utils/helpers";

const UserSettingsContainer = styled.div`
  background: ${props => props.theme.palette.background};
  width: ${props => unitCalc(props.theme.sidePanelWidth, 0.95, '*')};
`;

const UserSettingsHeader = styled.h3`
  padding: 0.8rem;
  font-weight: 400;
  border-bottom: 1px solid ${props => props.theme.palette.lightestGray};
  color: ${props => props.theme.palette.textColor};
  font-size: 1.2rem;
`;

const UserSettingsLabel = styled.label`
  padding: 0 0.8rem;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h4 {
    font-size: ${props => props.theme.type.bodySize};
    color: ${props => props.theme.palette.textColor};
    font-weight: 400;
    max-width: ${props => unitCalc(props.theme.sidePanelWidth, 0.75, '*')};
    margin: 0;
  }
`;

const UserSettingsDescription = styled.p`
  padding: 0 0.8rem;
  line-height: 1rem;
  font-size: ${props => props.theme.type.smallSize};
  color: ${props => props.theme.palette.textColor};
  max-width: ${props => unitCalc(props.theme.sidePanelWidth, 0.75, '*')};
`;

const ConfigureAppButton = styled(Button)`
  width: 100%;
  color: ${props => props.theme.typeColor};
  background: ${props => props.theme.palette.white};
`;

export {
  UserSettingsContainer,
  UserSettingsHeader,
  UserSettingsLabel,
  UserSettingsDescription,
  ConfigureAppButton
};