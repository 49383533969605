import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import Topic from "../../../context/Topic";
import { AreaTypes } from "../../../util/interfaces";
import OfficePlan from "../../base/OfficePlan";
import { useHandles } from "../../miniapps/common/hooks";
import { getActiveWorkspaceAreaId, getActiveWorkspaceAreaType, setActiveWorkspaceAreaId, setActiveWorkspaceAreaType } from "../../redux";
import AreaHeader from "./AreaHeader";
import { AreaStats } from "../stats";
import {
  ListItem,
  ListItemTitle,
  ListItemSubtitle
} from "../../styles/Common/list";

interface IWorkspaceAreaProps {
  areaItem: __esri.Graphic
}
const WorkspaceArea = ({
  areaItem
}: IWorkspaceAreaProps) => {
  const dispatch = useDispatch();
  const activeAreaId = useSelector(getActiveWorkspaceAreaId);
  const activeAreaType = useSelector(getActiveWorkspaceAreaType);
  const setHandles = useHandles()[1];
  useEffect(() => {
    const handles = [];
    handles.push(
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          // TODO: force refresh
        }
      })
    );

    setHandles(handles);
  }, [activeAreaId, activeAreaType, areaItem]);

  const getAreaId = () => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  }
  const getAreaType = (): AreaTypes => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_TYPE);
  }
  const toggleDetails = () => {
    dispatch(setActiveWorkspaceAreaId(getAreaId()));
    dispatch(setActiveWorkspaceAreaType(getAreaType()));
  }
  return (
    <ListItem onClick={toggleDetails}>
      <ListItemTitle>
        <AreaHeader areaItem={areaItem} />
      </ListItemTitle>
      <ListItemSubtitle>
        <AreaStats areaItem={areaItem} />
      </ListItemSubtitle>
    </ListItem>
  )
}

export default WorkspaceArea;
