import React, {createRef} from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";

import Context from "../../../context/Context";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import FilterPopup from "./Actions/BookWorkspace/FilterPopup";
import { getHotelBookingSystem, getMeetingBookingSystem } from "./Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";

//Authentication
import { login, getAccessToken, getLoggedInUser } from "./Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";

import { ModalController } from "../../../common/components/Modal";
import {
  hideReservationsByFilter,
  showReservationsByFilter
} from "./Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import { resetMeetingCriteria } from "../MeetingRooms/MeetingRoomUtil";

const CSS = {
  sidebarButton: "i-sidebar-button i-more-button",
  sidebarButtonActive: "i-sidebar-button-active",
  sidebarButtonCaption: "i-sidebar-button-caption i-more-caption",
  sidebarPanelOpen: "i-sidebar-panel-open",
  iconContainer: "i-sidebar-icon-container i-more-container",
  icon: "i-more-menu-icon"
};

class MoreButton extends React.Component {

  buttonRef = createRef();

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.clicked = this.clicked.bind(this);
  }

  showPopup = () => {
    this.togglePanel(false);
    if (this.props.sidebarKey !== "meetingRooms") {
      Topic.publish(Topic.BookingFiltersSubmit, { init: true });
    }
  }

  clicked(event) {
    const i18n = Context.instance.i18n
    const active = (this.props.sidebarKey === this.props.rdxSidebarActiveKey);

    // Check for errors with reservations
    if (
      this.props.sidebarKey === "bookWorkspace" || this.props.sidebarKey === "meetingRooms"
    ) {
      const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
      let errorHandler = reservations && reservations.errorHandler;
      if (this.props.sidebarKey === "meetingRooms") 
          errorHandler = reservations && reservations.errorHandlerMeetingRooms;

      const detailedErrors = errorHandler && errorHandler.getErrors();
      if (detailedErrors && detailedErrors.length > 0) {
        let generic = i18n.more.bookWorkspace.errors.header;
        if (this.props.sidebarKey === "meetingRooms") generic = i18n.more.bookWorkspace.errors.meetinRooms.header;
        const errors = {
          generic: generic,
          detailed: detailedErrors.map((error) => error.getErrorMessage())
        };
        const options = { title: i18n.general.information, errors, className: "i-portrait-access-hotel-error" };
        ModalController.showError(options);
        return;
      }
    }

    if (this.props.sidebarKey === "bookWorkspace" || this.props.sidebarKey === "meetingRooms") {

      let bookingSystem;
      if (this.props.sidebarKey === "bookWorkspace") bookingSystem = getHotelBookingSystem();
      else if (this.props.sidebarKey === "meetingRooms") bookingSystem = getMeetingBookingSystem();

      if (!bookingSystem) {
        ModalController.showMessage(i18n.more.bookWorkspace.insufficientFields);
        return;
      }

      const user = getLoggedInUser(bookingSystem)
      if (user) {
        getAccessToken(bookingSystem).then(token => {
          let isEsri = bookingSystem && bookingSystem.type === "esri"; // Issue #4425
          if (token || isEsri) {
            Topic.publish(Topic.BookingLogin, {})
            if (!active) {
              this.showPopup()
            } else {
              this.togglePanel(active)
            }
          } else {
            ModalController.showMessage(i18n.more.bookWorkspace.accessErrorMsg)
            console.error(i18n.more.bookWorkspace.accessErrorMsg)
          }
        }).catch(error => {
          console.error(error)
          ModalController.showMessage(i18n.more.bookWorkspace.accessErrorMsg)
        })
      } else {
        login(bookingSystem).then(result => {
          console.log(result)
          getAccessToken(bookingSystem).then(token => {
            if (token) {
              Topic.publish(Topic.BookingLogin, {})
              if (!active) {
                this.showPopup()
              } else {
                this.togglePanel(active)
              }
            } else {
              ModalController.showMessage(i18n.more.bookWorkspace.accessErrorMsg)
              console.error(i18n.more.bookWorkspace.accessErrorMsg)
            }
          }).catch(error => {
            console.error(error)
            ModalController.showMessage(i18n.more.bookWorkspace.accessErrorMsg)
          })
        }).catch(error => {
          console.error(error)
          ModalController.showMessage(i18n.more.bookWorkspace.accessErrorMsg)
        })
      }
    } else {
      this.togglePanel(active)
    }
  }

  togglePanel(active) {
    if (active) {
      hideReservationsByFilter();
      resetMeetingCriteria();

      Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,null);
      document.body.classList.remove(CSS.sidebarPanelOpen);
    } else {
      hideReservationsByFilter();
      resetMeetingCriteria();
      if (
        this.props.sidebarKey === "bookWorkspace" ||
        this.props.sidebarKey === "meetingRooms"
      ) {
        showReservationsByFilter();
      }

      Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,this.props.sidebarKey);
      document.body.classList.add(CSS.sidebarPanelOpen);
      Topic.publish(Topic.SidebarButtonClicked,{sidebarKey: this.props.sidebarKey});
    }
    Context.instance.views.toggleClickHandlers("resume");
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.SetSidebarButtonFocus,params => {
        if (params && params.sidebarKey === this.props.sidebarKey) {
          this._focus();
        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _focus() {
    const btn = this.buttonRef && this.buttonRef.current;
    if (btn && btn.focus) btn.focus();
  }

  render() {
    const active = (this.props.sidebarKey === this.props.rdxSidebarActiveKey);
    let cls = null;
    if (active) cls = CSS.sidebarButtonActive;
    if (this.props.liClassName) {
      if (cls) cls += " " + this.props.liClassName;
      else cls = this.props.liClassName;
    }

    const icon = "libs/calcite-ui-icons/icons/sprite-16.svg#" + this.props.iconClassName;

    return (
      <li key={icon} className={cls}>
        <button className={CSS.sidebarButton}
         onClick={this.clicked} ref={this.buttonRef} aria-label={this.props.arialLabel}>
         <div className={CSS.iconContainer}>
            {<svg className={CSS.icon}><use href={icon}></use></svg>}
            <div className={CSS.sidebarButtonCaption}>{this.props.caption}</div>
         </div>
        </button>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdxSidebarActiveKey: Rdx.getValue(state,Rdx.Keys.SIDEBAR_ACTIVE_KEY)
  }
}

export default connect(mapStateToProps)(MoreButton);
