// Framework and third-party non-ui
import React from "react";
// Redux operations and local helpers/utils/modules
import * as component from "../../../components/util/component";
import * as commentReplyUtil from "./commentReplyUtil";
// Component specific modules (Component-styled, etc.)
import { ReplyWrapper } from "../../styles/commentsStyles";
// App components
import Reply from "./Reply";

export default class ReplyPanel extends React.Component {

    constructor(props){
        super(props);
        this.state = component.newState({
            replies : []
        });
    }

    componentDidMount(){
        this.getReplies();
    }
    
    componentWillUnmount(){
        component.componentWillUnmount(this);
    }

    getReplies=()=>{
        const allReplies = this.props.allReplies[0].features;
        const parent_cid = commentReplyUtil.getParentCidFieldName();
        let temp = [];
        if(allReplies) {
            for(let i=0;i<allReplies.length;i++) {
                if(this.props.commentId === allReplies[i].attributes[parent_cid]){
                    temp.push(allReplies[i].attributes);
                }
            }
        }
        return temp;
    }

    render(){
        return(
            <ReplyWrapper>
                {this.renderReplies()}
            </ReplyWrapper>
        )
    }

    renderReplies() {
        const replies = this.getReplies();
        let repliesCount = replies.length;
        const items = replies.map((reply, i)=> {
            if(repliesCount === i+1) {
                return (<Reply showReply= {true} key={reply.objectid} reply ={reply} changeMode={this.props.changeMode}
                commentId={this.props.commentId} geometry={this.props.geometry}/>)
            }else {
                return (<Reply showReply= {false} key={reply.objectid} reply ={reply} changeMode={this.props.changeMode}
                commentId={this.props.commentId} geometry={this.props.geometry}/>)
            }
        })
        return items;
    }
}
