import { createGlobalStyle } from "styled-components";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Styles that affect top level index.html elements and global resets ONLY
let GlobalStyle = null
if (isSafari) {
  GlobalStyle = createGlobalStyle`
    body,
    #root {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    body {
      background: #f5f5f5;
    }
    /* MAP STYLES */
    /* This should be wrapped, should not have to reset this element directly */
    .esri-view {
      width: 100%;
      height: 100%;
    }
    .esri-ui {
      z-index: ${props => props.theme.levels.mapUI};
    }
    .esri-ui-top-left {
      top: 0;
      html[dir='rtl'] & {
        ${'' /* top: 50px; */}
      }
    }
    .esri-ui-top-right {
      ${'' /* top: 50px; */}
      html[dir='rtl'] & {
        top: 0;
      }
    }
    /* LEVEL FILTER */
    button.i-viewtoggle,
    .i-levelfilter button {
      margin: 0;
      padding: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border: none;
      cursor: pointer;
    }
    .i-rtl .i-levelfilter > div > div > div{
      transform: translate3d(32px, 0px, 0px) !important;
    }
    .i-levelfilter-facilities select{
      -moz-appearance: none;
      appearance: none;
      background: url("assets/caret-down-16.svg") center right / 12px no-repeat #fff;
      padding-right: 1.5rem;
      cursor: pointer;
    }
    .i-rtl .i-levelfilter-facilities select{
      -moz-appearance: none;
      appearance: none;
      background: url("assets/caret-down-16.svg") center left / 12px no-repeat #fff;
      padding-right: 0.5rem;
      padding-left: 1.5rem;
      cursor: pointer;
    }
    .i-levelfilter-facilities .i--building-icon svg {
      margin-right: 0.2rem;
      width: 0.9rem;
      height: auto;
      fill: ${props => props.theme.typeColor};
    }
    .i-levelfilter-facilities {
      line-height: 2rem;
      height: 2rem;
      box-shadow: ${props => props.theme.boxShadow};
    }
    .i-levelfilter {
      color: ${props => props.theme.typeColor};
      background-color: ${props => props.theme.palette.white};
      box-shadow: ${props => props.theme.boxShadow};
    }
    .i-levelfilter-caption {
      color: ${props => props.theme.typeColor};
    }
    .i-levelfilter ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      max-height: 30vh;
    }
    body.i-mode-horizontal-split .i-levelfilter ul,
    body.i-mode-vertical-split .i-levelfilter ul {
      max-height: 5.7rem;
    }
    /* multi character floor names */
    .i-levelfilter ul {
      width: auto;
    }
    .i-levelfilter ul {
      scrollbar-width: none;
    }
    .i-levelfilter ul::-webkit-scrollbar {
      width: 1px;
      background-color: ${props => props.theme.palette.white};
    }
    .i-levelfilter ul::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.palette.themeColor};
    }
    .i-levelfilter ul > li {
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }
    .i-levelfilter button {
      display: block;
      text-align: center;
    }
    .i-levelfilter button {
      margin: 0;
      padding: 0.3rem;
      min-width: 2rem;
      min-height: 2rem;
    }
    .i-levelfilter button {
      background-color: ${props => props.theme.palette.white};
      color: ${props => props.theme.typeColor};
      font-size: ${props => props.theme.type.smallSize};
      border-bottom: 1px solid ${props => props.theme.palette.lightestGray};
    }
    .i-levelfilter button svg{
        width: 0.9rem;
        height: auto;
        fill: ${props => props.theme.typeColor};
    }
    .i-levelfilter .i-button--clear svg {
        width: 0.8rem;
        height: 0.8rem;
    }
    button.i-button-listitem.i-button--expander,
    button.i-button-listitem.i-button--clear,
    button.i-button-listitem.i-button--info {
      background-color: ${props => props.theme.palette.lightestGray};
      border: none;
    }
    .i-levelfilter button.i--active {
      color: ${props => props.theme.palette.white};
      background-color: ${props => props.theme.palette.themeColor};
    }
    .i-levelfilter .i-button--up,
    .i-levelfilter .i-button--down {
      margin: 0;
      padding: 0;
    }
    .i-levelfilter .i-button--up,
    .i-levelfilter .i-button--down {
      border-bottom: none;
    }
    .i-levelfilter .i-button--up > svg,
    .i-levelfilter .i-button--down > svg {
      margin: 0;
      padding: 0;
      width: 0.6rem;
      height: 0.6rem;
    }
    .i-levelfilter-facilities {
      display: flex;
      color: ${props => props.theme.typeColor};
      background-color: ${props => props.theme.palette.white};
      padding: 0 0.4rem;
      border: 1px solid ${props => props.theme.palette.white};
    }
    button.i-button-listitem.i-button--info {
      width: 2rem;
      display: inline-block;
      background-color: ${props => props.theme.palette.themeColor};
    }
    .i-levelfilter-facilities .i-facilities-select {
      display: inline-block;
      margin: 0;
      border: none;
      height: auto;
      ${'' /* max-width: 8rem; */}
      min-width: 4rem;
      background-color: ${props => props.theme.palette.white};
    }
    .i-levelfilter-facilities .i-sites-select {
      display: inline-block;
      margin: 0;
      border: none;
      height: auto;
      ${'' /* max-width: 6rem; */}
      min-width: 4rem;
      background-color: ${props => props.theme.palette.white};
    }
    .i-levelfilter-facilities .i--building-icon svg{
      position: relative;
      top: 0.1rem;
    }
    .i-levelfilter-facilities .i-button-listitem.i-button--info svg,
    .i-levelfilter-facilities .i-button-listitem.i-button--expander svg{
      position: relative;
      bottom: 0.1rem;
    }
    .i-levelfilter-facilities .i-button-listitem.i-button--info{
      background-color: ${props => props.theme.palette.lightestGray};
      border-left: 1px solid ${props => props.theme.palette.lightestGray};
    }
    /* SELECT WIDGET */
    .esri-icon-cursor-filled:before {
      color: ${props => props.theme.palette.themeColor};
    }
    /* MODAL */
    [class*="StyledModalHeader"] {
      flex: none;
    }
  `;
} else {
  GlobalStyle = createGlobalStyle`
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    background: #f5f5f5;
  }

  /* MAP STYLES */
  /* This should be wrapped, should not have to reset this element directly */
  .esri-view {
    width: 100%;
    height: 100%;
  }

  .esri-ui {
    z-index: ${props => props.theme.levels.mapUI};
  }

  .esri-ui-top-left {
    top: 0;

    html[dir='rtl'] & {
      top: 0px;
    }
  }

  .esri-ui-top-right {
    top: 0px;

    html[dir='rtl'] & {
      top: 0;
    }
  }

  /* LEVEL FILTER */
  button.i-viewtoggle,
  .i-levelfilter button {
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    cursor: pointer;
  }

  .i-rtl .i-levelfilter > div > div > div{
    transform: translate3d(32px, 0px, 0px) !important;
  }

  .i-levelfilter-facilities select{
    -moz-appearance: none;
    appearance: none;
    background: url("assets/caret-down-16.svg") right / 12px no-repeat #fff;
    padding-right: 1.5rem;
    cursor: pointer;
  }

  .i-rtl .i-levelfilter-facilities select{
    -moz-appearance: none;
    appearance: none;
    background: url("assets/caret-down-16.svg") left / 12px no-repeat #fff;
    padding-right: 0.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
  }

  .i-levelfilter-facilities .i--building-icon svg {
    margin-right: 0.2rem;
    width: 0.9rem;
    height: auto;
    fill: ${props => props.theme.typeColor};
  }

  .i-levelfilter-facilities {
    line-height: 2rem;
    height: 2rem;
  }

  .i-levelfilter {
    color: ${props => props.theme.typeColor};
    background-color: ${props => props.theme.palette.white};
  }

  .i-levelfilter-caption {
    color: ${props => props.theme.typeColor};
  }

  .i-levelfilter ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: 30vh;
  }

  body.i-mode-horizontal-split .i-levelfilter ul,
  body.i-mode-vertical-split .i-levelfilter ul {
    max-height: 5.7rem;
  }

  /* multi character floor names */
  .i-levelfilter ul {
    width: auto;
  }

  .i-levelfilter ul {
    scrollbar-width: none;
  }

  .i-levelfilter ul::-webkit-scrollbar {
    width: 1px;
    background-color: ${props => props.theme.palette.white};
  }

  .i-levelfilter ul::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.palette.themeColor};
  }

  .i-levelfilter ul > li {
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  .i-levelfilter button {
    display: block;
    text-align: center;
  }

  .i-levelfilter-facilities.i-facilities-expander button {
    margin: 0;
    padding: 0.2rem;
    min-width: 2rem;
    min-height: 33px;
  }

  .i-levelfilter-facilities.i-sites-expander button {
    margin: 0;
    padding: 0.2rem;
    min-width: 2rem;
    min-height: 33px;
  }

  .i-levelfilter button {
    background-color: ${props => props.theme.palette.white};
    font-size: ${props => props.theme.type.smallSize};
    margin: 0;
    padding: 0.2rem;
    min-width: 2rem;
    min-height: 2rem;
    ${'' /* border-bottom: 1px solid #dfdfdf; */}
  }

  .i-levelfilter button svg{
      width: 0.9rem;
      height: auto;
      fill: ${props => props.theme.typeColor};
  }

  .i-levelfilter .i-button--clear svg {
      width: 0.8rem;
      height: 0.8rem;
  }

  button.i-button-listitem.i-button--expander,
  button.i-button-listitem.i-button--clear,
  button.i-button-listitem.i-button--info {
    background-color: ${props => props.theme.palette.lightestGray};
    border: none;
  }

  .i-levelfilter button.i--active {
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.themeColor};
  }

  .i-levelfilter .i-button--up,
  .i-levelfilter .i-button--down {
    margin: 0;
    padding: 0;
  }

  .i-levelfilter .i-button--up,
  .i-levelfilter .i-button--down {
    border-bottom: none;
  }

  .i-levelfilter .i-button--up > svg,
  .i-levelfilter .i-button--down > svg {
    margin: 0;
    padding: 0;
    width: 0.6rem;
    height: 0.6rem;
  }

  .i-levelfilter-facilities {
    display: flex;
    color: ${props => props.theme.typeColor};
    background-color: ${props => props.theme.palette.white};
    ${'' /* border: 1px solid ${props => props.theme.palette.white}; */}
  }

  button.i-button-listitem.i-button--info {
    width: 2rem;
    display: inline-block;
    background-color: ${props => props.theme.palette.themeColor};
  }

  .i-levelfilter-facilities .i-dd-button-container.i-dd-levelfilter {
    button:focus {
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: -2px;
      position: relative;
      z-index: auto;
    }
    button:-moz-focusring {
      outline: 2px auto -webkit-focus-ring-color;
      outline-offset: -3px;
      position: relative;
      z-index: auto;
    }
  }

  .i-levelfilter-facilities .i-facilities-select {
    display: inline-block;
    margin: 0;
    border: none;
    height: auto;
    max-width: 8rem;
    background-color: ${props => props.theme.palette.white};
  }

  .i-levelfilter-facilities .i-sites-select {
    display: inline-block;
    margin: 0;
    border: none;
    height: auto;
    ${'' /* max-width: 6rem; */}
    background-color: ${props => props.theme.palette.white};
  }

  .i-levelfilter-facilities .i-button-listitem.i-button--info svg,
  .i-levelfilter-facilities .i-button-listitem.i-button--expander svg{
    position: relative;
    bottom: 0.1rem;
  }

  .i-levelfilter-facilities .i-button-listitem.i-button--info{
    background-color: ${props => props.theme.palette.lightestGray};
  }

  /* SELECT WIDGET */
  .esri-icon-cursor-filled:before {
    color: ${props => props.theme.palette.themeColor};
  }

  /* MODAL */
  [class*="StyledModalHeader"] {
    flex: none;
  }
`;
}


export { GlobalStyle };
