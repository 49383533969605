import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import Topic from "../../context/Topic";
import * as selectionUtil from "../../aiim/util/selectionUtil";
import * as sourceUtil from "./sourceUtil";
import * as val from "../../util/val";
import * as dateUtil from "../../../src/components/main/Events/dateUtil";
import type Source from "../../aiim/base/Source";

export interface IFilterPair {
  id: string,
  field: string,
  value: string,
  label?: string,
  hidden?: boolean,
  type?: __esri.Field["type"],
  operator?: "Between" | "Equal" | "Exact" | "Minimum" | "Maximum" | "Range",
  getValues?: (opt: {
    filteredLevelInfo: {
      facilityIdx: { [prop: string]: number },
      levelIdx: { [prop: string]: number },
      siteIdx: { [prop: string]: number }
    }
  }) => {
    name: string,
    value: any
  }[],
  fixedOptions?: {
    name: string,
    value: string,
    expression?: string
  }[]
}

export default class QueryCriteria extends BaseClass {

  customSort;
  filterableFields;
  filterPairs: IFilterPair[];
  forPeopleChanges: boolean;
  forUnassignedPeople: boolean;
  forAssignedPeople: boolean;
  key: string;
  objectIds: number[];
  primarySortField: string;
  quickFilters;
  requiresGeometry;
  requiredExpression;
  searchText = "";
  sortOptions;
  sourceKey: string; 
  where: string;

  _siteFacilityLevel;
  _localPairs: IFilterPair[];

  constructor(props) {
    super(props);
    this.key = props.key;
    this.sourceKey = props.sourceKey;
  }
  static get(key: string, sourceKey?: string) {
    const session = Context.instance.session;
    if (!session.queryCriteriaByKey) session.queryCriteriaByKey = {};
    let queryCriteria = session.queryCriteriaByKey[key];
    if (!queryCriteria) {
      queryCriteria = session.queryCriteriaByKey[key] = new QueryCriteria({
        key: key,
        sourceKey: sourceKey
      });
    }
    return queryCriteria;
  }

  getSource() {
    return sourceUtil.getSourceByKey(this.getSourceKey());
  }

  getSourceKey() {
    return this.sourceKey;
  }

  getWhere() {
    return this.where || "1=1";
  }

  hasFilterPairs() {
    let hasFilter = false;
    const pairs = this.filterPairs;
    if (Array.isArray(pairs) && pairs.length > 0) {
      hasFilter = pairs.some(pair => {
        if (typeof pair.field === "string" && pair.field.length > 0 &&
            pair.value !== undefined) {
          return true;
        } else if (typeof pair.getValues === "function" &&
            pair.value !== undefined) {
          return true;
        }
        return false;
      });
    }
    return hasFilter;
  }

  makeFilterPart(source: Source, pairs: IFilterPair[], ignoreField?: string) {
    let q, part, parts = "", where = null;
    if (Array.isArray(pairs) && pairs.length > 0) {
      pairs.forEach(pair => {
        part = null;

        if (pair.fixedOptions) {
          pair.fixedOptions.some(opt => {
            if (pair.value === opt.value) {
              if (typeof opt.expression === "string" && opt.expression.length > 0) {
                part = opt.expression;
              }
              return true;
            }
            return false;
          });
        } else if (pair.value === "__novalue") {
            if(pair.field && pair.field.length > 0 && pair.value !== undefined 
                && pair.field !== ignoreField){
              if(pair.type === "string") {
                part = "(("+pair.field+" IS NULL)";
                part += " OR " + "("+pair.field+" = " + "''))"; 
              } else {
                part = "("+pair.field+" IS NULL)";
              }
            }
        } else if ((pair.type === "double" || pair.type ==="integer" || pair.type === "small-integer" 
            ||pair.type === "single" || pair.type === "long" || pair.type === "oid") 
            && pair.field.length > 0 && pair.value !== undefined && pair.field !== ignoreField) {
              if (pair.value === null) {
                part = "("+pair.field+" IS NULL)";
              } else {
                q = pair.value;
                if(pair.operator === "Equal") {
                  if(pair.type === "double" || pair.type === "single") {
                    q = Number.parseFloat(pair.value);
                    let offset = 0.000000000001;
                    // let places = 12;
                    if(pair.type === "single") {
                      offset = 0.000001;
                      // places = 6;
                    }

                    let q1 = q - offset;
                    // q1 = Context.instance.lib.dojo.number.format(q1, {locale: "root", places: places});
                    // q1 = q1.replaceAll(",", "");
                    let q2 = q + offset;
                    // q2 = Context.instance.lib.dojo.number.format(q2, {locale: "root", places: places});
                    // q2 = q2.replaceAll(",", "");
                    part = "("+pair.field+" BETWEEN "+q1+" AND "+ q2+")";
                  }else {
                    part = "("+pair.field+" = "+q+")";
                  }
                }else if(pair.operator === "Minimum") {
                  part = "("+pair.field+" >= "+q+")";
                }else if(pair.operator === "Maximum") {
                  part = "("+pair.field+" <= "+q+")";
                }else if(pair.operator === "Between") {
                  const q1 = q[0];
                  const q2 = q[1];
                  if(q1 && q2) part = "("+pair.field+" BETWEEN "+q1+" AND "+ q2+")";
                  else if(q1) part = "("+pair.field+" >= "+q1+")";
                  else if(q2) part = "("+pair.field+" <= "+q2+")";
                }
              }   
        }else if(pair.type === "date" && pair.field.length > 0 && pair.value !== undefined 
                && pair.field !== ignoreField){
          if (pair.value === null) {
            part = "("+pair.field+" IS NULL)";
          }else {
            q = pair.value;
            if(pair.operator === "Exact") {
              let startDateTime, endDateTime;
              if(q && typeof q === "number") {
                startDateTime = dateUtil.toStartOfDay(new Date(q));
                startDateTime = dateUtil.getZulu(startDateTime);
                endDateTime = dateUtil.toEndOfDay(new Date(q));
                endDateTime = dateUtil.getZulu(endDateTime);
                part = "("+pair.field+" BETWEEN timestamp '"+startDateTime+"' AND timestamp '"+endDateTime+"')";
              }
            }else if(pair.operator === "Range"){
              let q1 = q[0];
              let q2 = q[1];
              if(q1 && typeof q1 === "number") {
                q1 = dateUtil.toStartOfDay(new Date(q1));
                q1 = dateUtil.getZulu(q1);
              }
              if(q2 && typeof q2 === "number") {
                q2 = dateUtil.toEndOfDay(new Date(q2));
                q2 = dateUtil.getZulu(q2);
              }
              if(q1 && q2) {
                part = "("+pair.field+" BETWEEN timestamp '"+q1+"' AND timestamp '"+q2+"')";
              }else if(q1) {
                part = "("+pair.field+ " >= timestamp '"+q1+"')";
              }else if (q2 === "number") {
                part = "("+pair.field+ " =< timestamp '"+q2+"')";
              }
            }
          } 
        }else if(pair.type === "string" && pair.field && pair.field.length > 0 && pair.value !== undefined 
          && pair.field !== ignoreField){
            if (pair.value === null) {
              part = "("+pair.field+" IS NULL)";
            } else {
              if (typeof pair.value === "string") {
                q = "'" + selectionUtil.escSqlQuote(pair.value) + "'";
              } else {
                q = pair.value;
              }
              part = "("+pair.field+" = "+q+")";
          }
        }
        if (part) {
          if (parts.length > 0) parts += " AND ";
          parts += "("+part+")";
        }
      })
    }
    if (parts && parts.length > 0) {
      //if (where.length > 0) where += " AND ";
      where = "("+parts+")";
    }
    return where;
  }

  makeLevelFilter(source: Source) {
    let v1, v2, filter;
    const levelsDataset = Context.instance.aiim.datasets.levels;
    const fi = Context.instance.aiim.getActiveFacilityInfo();
    const level = fi && fi.activeLevel;
    const facilityId = level && level.facilityId;
    const levelId = level && level.levelId;
    const layer = source && source.layer2D;
    const levelIdField = Context.instance.aiim.getLevelIdField(layer);

    if (levelIdField && levelsDataset) {
      let levelPart, levelsPart;
      let values = [];
      let ids = levelsDataset.getZeroVOLevelIds(facilityId,levelId);
      ids.forEach(v => {
        if (levelIdField.type === "string" && typeof v === "string") {
          v = "'"+selectionUtil.escSqlQuote(v)+"'";
        }
        values.push(v);
      });
      if (values.length > 0) {
        levelsPart = "("+levelIdField.name + " IN (" + values.join(",") + "))";
      }
      if (level) {
        let v = level.levelId;
        if (levelIdField.type === "string" && typeof v === "string") {
          v = "'"+selectionUtil.escSqlQuote(v)+"'";
        }
        levelPart = "("+levelIdField.name+" = "+v+")";
      }
      //console.log("levelPart",levelPart)
      //console.log("levelsPart",levelsPart)
      if (levelPart || levelsPart) {
        let where;
        if (levelPart && levelsPart) {
          where = "("+levelPart+" OR "+levelsPart+")";
        } else {
          where = levelPart || levelsPart;
        }
        let base = selectionUtil.getBaseDefinitionExpression(layer);
        if (base) {
          where = "(" + base + ") AND (" + where + ")";
        }
        //console.log("QueryCriteria.makeLevelFilter",where)
        return where;
      }
    }

    const fields = source.getFields();
    const fidField = source.findField(fields,FieldNames.FACILITY_ID,"facilityIdField");
    const voField = source.findField(fields,FieldNames.VERTICAL_ORDER,"verticalOrderField");

    if (level && fidField && voField) {
      v1 = "("+fidField.name+" = '"+selectionUtil.escSqlQuote(level.facilityId)+"')";
      v1 += " AND ("+voField.name+" = "+level.verticalOrder+")";
      v2 = "("+fidField.name+" <> '"+selectionUtil.escSqlQuote(level.facilityId)+"')";
      v2 += " AND ("+voField.name+" = 0)";
      filter = "("+v1+") OR ("+v2+")";
    } else if (voField) {
      filter = "("+voField.name+" = 0)";
    }
    return filter;
  }

  makeOrderByFields() {
    let orderByField, orderByFields;
    const sortOptions = this.sortOptions;
    const primarySortField= this.primarySortField;
    if (sortOptions && sortOptions.sortBy && sortOptions.sortBy !== "distance") {
      orderByField = sortOptions.sortBy;
      if (sortOptions.sortDir === "desc") orderByField += " DESC";
      orderByFields = [orderByField]
      if (primarySortField &&
          primarySortField.toLowerCase() !== sortOptions.sortBy.toLowerCase()) {
        orderByFields.push(primarySortField)
      }
    } else if (primarySortField) {
      orderByFields = [primarySortField]
    }
    return orderByFields;
  }

  makeQuickFilterPart() {
    let part, where = null;
    let keys, filters = this.quickFilters;
    if (filters) keys = Object.keys(this.quickFilters);
    if (keys) {
      keys.forEach(key => {
        if (filters[key].on) {
          part = filters[key].where;
          if (part) {
            if (where === null) where = "";
            if (where.length > 0) where += " AND ";
            where += "("+part+")";
          }
        }
      })
    }
    return where;
  }

  makeSiteFacilityLevelPart(source: Source, sfl) {
    if (source && sfl) {
      const levelsDataset = Context.instance.aiim.datasets.levels;
      const lidField = Context.instance.aiim.getLevelIdField(source);
      if (levelsDataset && lidField) {
        //let ids = levelsDataset.getZeroVOLevelIds(facilityId,levelId);
        let lids = [], values = [];
        let activeSite = sfl.activeSite;
        let activeFacility = sfl.activeFacility;
        let activeLevel = sfl.activeLevel;
        if (activeLevel) {
          lids = [activeLevel];
        } else if (activeFacility) {
          let fd = levelsDataset.getFacilityData(activeFacility);
          if (fd) {
            fd.levels.forEach(l => {
              lids.push(l.levelId);
            });
          }
        } else if (activeSite) {
          let facilities = levelsDataset.getFacilities();
          if (facilities) {
            facilities.forEach(fd => {
              if (fd.siteId === activeSite) {
                fd.levels.forEach(l => {
                  lids.push(l.levelId);
                });
              }
            });
          }
        }
        if (lids && lids.length > 0) {
          //console.log("lids",lids);
          lids.forEach(v => {
            if (lidField.type === "string" && typeof v === "string") {
              v = "'"+selectionUtil.escSqlQuote(v)+"'";
            }
            values.push(v);
          });
        }
        if (values.length > 0) {
          let wh = "("+lidField.name + " IN (" + values.join(",") + "))";
          //console.log("wh",wh)
          return wh;
        }
      }
    }
  }

  makeWhere(source: Source) {
    let where = "", q, v, part;

    q = this.searchText;
    if (typeof q === "string" && q.length > 0) {
      part = "";
      q = q.toUpperCase();
      q = "'%" + selectionUtil.escSqlQuote(q) + "%'";
      let searchFields = source && source.searchFields;
      if (!searchFields || searchFields.length === 0) {
        if (source.displayField) {
          searchFields = [source.displayField];
          if(source && source.key === "Units") {
            const unitField = Context.instance.aiim.getUnitName();
            if(unitField) searchFields = [unitField];
          }
        }
      }
      if (Array.isArray(searchFields)) {
        searchFields.forEach(f => {
          v = "(UPPER("+f+") LIKE "+q+")";
          if (part.length > 0) part += " OR ";
          part += v;
        })
      }
      if (part && part.length > 0) {
        if (where.length > 0) where += " AND ";
        where += "("+part+")";
      }
    }

    part = this.makeFilterPart(source,this.filterPairs);
    if (part && part.length > 0) {
      if (where.length > 0) where += " AND ";
      where += "("+part+")";
    }

    part = this.makeSiteFacilityLevelPart(source,this._siteFacilityLevel);
    if (part && part.length > 0) {
      if (where.length > 0) where += " AND ";
      where += "("+part+")";
    }

    part = this.makeQuickFilterPart();
    if (part && part.length > 0) {
      if (where.length > 0) where += " AND ";
      where += "("+part+")";
    }

    // let parts = "";
    // const pairs = this.filterPairs;
    // if (Array.isArray(pairs) && pairs.length > 0) {
    //   pairs.forEach(pair => {
    //     if (typeof pair.field === "string" && pair.field.length > 0 &&
    //        pair.value !== undefined) {
    //       if (pair.value === null) {
    //         part = "("+pair.field+" IS NULL)";
    //       } else {
    //         if (typeof pair.value === "string") {
    //           q = "'" + selectionUtil.escSqlQuote(pair.value) + "'";
    //         } else {
    //           q = pair.value;
    //         }
    //         part = "("+pair.field+" = "+q+")";
    //       }
    //       if (parts.length > 0) parts += " AND ";
    //       parts += "("+part+")";
    //     }
    //   })
    // }
    // if (parts.length > 0) {
    //   if (where.length > 0) where += " AND ";
    //   where += "("+parts+")";
    // }

    let base = selectionUtil.getBaseDefinitionExpression(source && source.layer2D);
    let required = this.requiredExpression;
    if (base && base.length > 0) {
      if (required && required.length > 0) {
        base = "(("+required+") AND ("+base+"))";
      }
    } else if (required && required.length > 0) {
      base = required;
    }
    if (base && base.length > 0) {
      if (where && where.length > 0)  {
        where = "("+base+") AND ("+where+")";
      } else {
        where = base;
      }
    }

    if (where.length === 0) where = "1=1";
    return where;
  }

  newFilterPair(params: Partial<IFilterPair>): IFilterPair {
    let pair = {
      id: val.generateRandomId(),
      field: undefined,
      value: undefined
    };
    if (params) pair = Object.assign(pair, params);
    return pair;
  }

  onFilterPairsChanged(filterPairs?: IFilterPair[]) {
    this.filterPairs = filterPairs;
    this.where = this.makeWhere(this.getSource());
    Topic.publish(Topic.QueryCriteriaChanged,{key: this.key});
  }

  onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    this.where = this.makeWhere(this.getSource());
    Topic.publish(Topic.QueryCriteriaChanged,{key: this.key});
  }

  onSortChanged(sortOptions) {
    this.sortOptions = sortOptions;
    this.where = this.makeWhere(this.getSource());
    Topic.publish(Topic.QueryCriteriaChanged,{key: this.key});
  }

}
