import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import {Provider, connect} from "react-redux";
import { ModalController } from "../../../../src/common/components/Modal";

import Attributes from "./Attributes";
import Context from "../../../context/Context";
import InfoPanelHeader from "./InfoPanelHeader";
import Rdx from "../../../redux/Rdx";
import Related from "./Related";
import RealTimeLocation from "./RealTimeLocation";
import Topic from "../../../context/Topic";
import * as Actions from "./Actions";
import * as appLaunchUtil from "../../../aiim/util/appLaunchUtil";
import * as component from "../../util/component";
import * as itemUtil from "../../../aiim/util/itemUtil";
import { login, getAccessToken, getLoggedInUser } from "../../../../src/components/main/More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";
import FieldNames from "../../../../src/aiim/datasets/FieldNames";
import { stringFormatter } from "../../../../src/util/formatUtil";

import * as aiimUtil from "../../../../src/aiim/util/aiimUtil";
import * as reservationUtil from "../../../aiim/util/reservationUtil";
import BookingDetails from "../More/Actions/BookWorkspace/BookingDetails";
import BookingConfirmed from "../More/Actions/BookWorkspace/BookingConfirmed";
import { getArea, getHotelBookingSystem, getHotelBookingSystemType, makeEndDateTime, makeInitialTimes, makeStartDateTime } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as dateUtil from "../Events/dateUtil";
import { escSqlQuote } from "../../../aiim/util/selectionUtil";
import MyHotelBookings from "./MyHotelBookings";
import Schedule from "../MeetingRooms/Schedule";
import Book from "../MeetingRooms/Book";

import { assignmentsFound, findDuplicateRecords, findUserReservations, flattenAssignments, getAssignments, getLKL, hasAssignment, queryTrackingInfo } from "../../../util/multipleAssignmentsUtil";
import Icons from "../../util/Icons";
import * as validateUtil from "../More/Actions/BookWorkspace/validateUtil";
import ShareUrl from "./ShareUrl";

const CSS = {
  main: "i-content",
  infopanelContent: "i-infopanel-content",
  featureContent: "i-infopanel-content-feature",
  actionBar: "i-actionbar",
  tabs: "tab-nav",
  tab: "tab-title",
  activeTab: "is-active",
  tabContent: "i-infopanel-feature-tabcontent",
  bookButton: "i-bookbutton",
  bookButtonDiv: "i-bookbuttondiv",
  actionBarContainer: "i-actionbar-container",
  infopanelBookButton: "i-infopanel-book-button",
  notAssigned: "i-infopanel-notassigned"
};


class ItemDetails extends React.Component {

  constructor(props) {
    super(props);
    this.bookingSystem = getHotelBookingSystem();

    // const startDate = this.props.rdxStartDateSelected;
    // const endDate = this.props.rdxEndDateSelected;
    // const allDay = this.props.rdxAllDayChecked;

    this.state = component.newState({
      activeTab: "attributes",
      appLaunchUrls: [],
      mode: "details",
      _311Url: null,
      spaceType : null,
      area: null,
      areaName : null,
      scheduleEmail : null,
      levelName: null,
      bookings: [],
      activeLocation: null,
      otherLocations: null,
      lkl: null,
      upcomingReservations: [],
      pastReservation: [],
      expandNoPermissionToBook: false
    });
    this.modeClicked = this.modeClicked.bind(this);
    this.tabClicked = this.tabClicked.bind(this);
  }

  makeEndDateTime =(endTime, endDate, allDay, forSubmit)=> {
    return validateUtil.makeEndDateTime(endTime, endDate, allDay, forSubmit);
  }
  
  makeStartDateTime =(startTime, startDate, allDay)=> {
    return validateUtil.makeStartDateTime(startTime, startDate, allDay);
  }

  bookClicked = async () => {
    const item = this.props.item;
    const attributes = item.searchResult.feature.attributes;
    const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID);
    const spaceType = aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_USE_TYPE);
    const unitName = aiimUtil.getAttributeValue(attributes, FieldNames.NAME);
    const scheduleEmail = aiimUtil.getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL);
    let levelName = aiimUtil.getAttributeValue(attributes, FieldNames.LEVEL_NAME)+"z";
    let facilityName = aiimUtil.getAttributeValue(attributes, FieldNames.FACILITY_NAME)+"z";
    const unitsDataset = Context.instance.aiim.datasets.units;
    if (unitsDataset) {
      const levelData = unitsDataset.getLevelData(item.searchResult.feature);
      if (levelData && levelData.levelName) levelName = levelData.levelName;
      if (levelData && levelData.facilityName) facilityName = levelData.facilityName;
    }
    const bookingSystem = this.bookingSystem;
    let user = getLoggedInUser(bookingSystem);
    if (user) {
      return getAccessToken(bookingSystem)
      .then(() => {
        return getArea(areaId);
      })
      .then((area) => {
        const areaName = area && aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_NAME);
        this.setState({
          spaceType : spaceType,
          area,
          areaName : areaName,
          scheduleEmail : scheduleEmail,
          unitName : unitName,
          levelName : levelName,
          facilityName : facilityName
        }, ()=>{
          if (this.props.isBookWorkspacePanel) {
            this.renderBookingDetails();
          } else {
            this.renderSchedule();
          }
        })
      })
      .catch(error =>{
        console.log("Error in booking workspace.", error);
      })
    } else {
      return login(bookingSystem)
      .then((status)=>{
        if(status === "Success") return getArea(areaId);
      })
      .then((area)=> {
        const areaName = area && aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_NAME);
        this.setState({
          spaceType : spaceType,
          area,
          areaName : areaName,
          scheduleEmail : scheduleEmail,
          unitName : unitName,
          levelName : levelName,
          facilityName : facilityName
        }, ()=>{
          if (this.props.isBookWorkspacePanel) {
            this.renderBookingDetails();
          } else {
            this.renderSchedule();
          }
        })
      })
      .catch(error =>{
        console.log("Error in booking workspace.", error);
      })
    }
  }

  bookClickedMeetingRooms =()=> {
    const item = this.props.item;
    const feature = item.searchResult.feature;
    const bookItem = {
      unitId: aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_ID),
      name: aiimUtil.getAttributeValue(feature.attributes,FieldNames.NAME),
      feature: feature
    };
    let duration = "oneHour";
    let dtStart = dateUtil.getTopOfHour();
    let dtEnd = dateUtil.addDuration(dtStart,duration);
    let criteria = {
      when: {
        duration: duration,
        start: {date: dtStart.getTime()},
        end: { date: dtEnd.getTime() },
        recurrence: { enabled: false }
      },
    };
    let sharedWhen = Context.instance.session.sharedMeetingRoomWhen;
    if (sharedWhen) {
      criteria.when = {
        ...sharedWhen,
        start: { date: sharedWhen.start.date.getTime() },
        end: { date: sharedWhen.end.date.getTime() },
        recurrence: { ...sharedWhen.recurrence }
      };
    }

    if(this.props && this.props.isMeetingRoomsPanel) {
      let showInfo = {
        bookItem: bookItem,
        criteria: criteria,
        reserveForInfo: Context.instance.aiim.reserveForInfo
      }
      Book.show(showInfo);
    } else {
      const details = {
        type: "meetingRoom",
        bookItem: bookItem,
        criteria: criteria
      }
      this.renderSchedule(details, "meetingRooms");
    }
  }

  componentDidMount() {
    //this.generate311Url();
    this.generateAppLaunchUrls();
    this.queryUserReservations();
    this.queryUserLocations();

    component.own(this,[
      Topic.subscribe(Topic.AppLaunchURLsChanged,params => {
        this.generateAppLaunchUrls();
      }),
      Topic.subscribe(Topic.ReferenceLayerUpdated,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.RenderBookingList, () => {
        this.queryUserReservations();
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  queryUserReservations = async () => {
    const { item } = this.props;
    const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    const key = item.sourceKey;
    const source = units.getSource();
    if (!item || !reservations || !units || !source || key !== source.key) {
      return;
    }
    if (Context.instance.user.isAnonymous()) return;

    const user = Context.instance.user;
    const username = user.getUsername();
    const unit = item.getFeature();
    const unitId = aiimUtil.getAttributeValue(unit.attributes, FieldNames.UNIT_ID);

    if (!username || !unitId) {
      return;
    }

    const now = dateUtil.getZulu(new Date());
    const whereClauses = [
      `${reservations.unitIdField} = '${unitId}'`,
      `${reservations.reservedForUsernameField} = '${username}'`,
      `${reservations.endTimeField} > TIMESTAMP '${escSqlQuote(now)}'`,
      `${reservations.stateField} IN (1,4)`
    ];
    const where = `${whereClauses.join(' AND ')}`;

    try {
      const myReservations = await reservations.query(where);
      const bookings = (myReservations && myReservations.features) || [];
      const filteredBookings = await this.filterHotelReservations(bookings, reservations.hasRecurrenceFields());
      this.setState({ bookings: filteredBookings });
    } catch (e) {
      console.error(e);
      this.setState({ bookings: [] });
    }
  }

  queryUserLocations = async () => {
    const { item, showLocations } = this.props;
    const people = Context.instance.aiim.datasets && Context.instance.aiim.datasets.people;
    const source = people && people.getSource();
    if (!item || !people || !source || !showLocations /*|| key !== source.key*/) {
      return;
    }
    if (Context.instance.user.isAnonymous()) return;

    const layer = source && source.layer2D;

    const person = (showLocations && showLocations.person && showLocations.person) || item.getFeature();
    const personAttributes = (person && person.feature) ? person.feature.attributes : person && person.attributes;

    // See if the user has ongoing and/or upcoming reservations
    const upcomingReservations = await findUserReservations((person && person.feature) ? person : { feature: person }, "upcoming");
    const pastReservation = await findUserReservations((person && person.feature) ? person : { feature: person }, "past")

    const itemReservation = this.isItemReservation(item, upcomingReservations)
    const pastItemReservation = this.isItemReservation(item, pastReservation);

    try {
      const results = await findDuplicateRecords(layer, personAttributes);
      const personRecords = [];
      if (results && results.features && results.features.length > 0) {
        results.features.forEach((record) => {
          const attributes = record.attributes;
          const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID);
          const existingAreaBooking = upcomingReservations.some((r) => r.areaId === areaId);
          const existingAreaBookingPast = pastReservation.some((r)=> r.areaId === areaId);
          const isItem = Object.keys(attributes).every((key) => attributes[key] === personAttributes[key]);

          const includeItem = !isItem || (isItem && itemReservation.isReservation);
          const includePastItem = !isItem || (isItem && pastItemReservation.isReservation);
          const hasExistingAreaBooking = !areaId || (areaId && !existingAreaBooking);
          const hasExistingAreaBookingPast = !areaId || (areaId && !existingAreaBookingPast);
          if (((includeItem && hasExistingAreaBooking) || (includePastItem && hasExistingAreaBookingPast))
              && hasAssignment(record)) {
            personRecords.push(record);
          }
        });
      }

      // Get other assignments
      const assignments = await getAssignments(personRecords);

      // Find last shared location
      let lkl = null;
      if (results && results.features && results.features.length > 0) {
        const lklFeature = getLKL(results.features);
        if (lklFeature) {
          const ti = await queryTrackingInfo(results.features);
          if (ti && ti.feature) {
            lkl = lklFeature;
          }
        }
      }

      // Get the active feature's assignment
      const activeAssignment = await getAssignments([person]);
      const flattenedActiveAssignment = flattenAssignments(activeAssignment);

      let activeLocationCurrent = itemReservation.isReservation
        ? upcomingReservations[itemReservation.index]
        : flattenedActiveAssignment.length === 1 && flattenedActiveAssignment[0];
      let activeLocationPast = pastItemReservation.isReservation
        ? pastReservation[pastItemReservation.index]
        : flattenedActiveAssignment.length === 1 && flattenedActiveAssignment[0];

      // Remove the reservation which is the active location
      if (itemReservation.isReservation) upcomingReservations.splice(itemReservation.index, 1);

      if (pastItemReservation.isReservation) pastReservation.splice(pastItemReservation.index, 1);

      let activeLocation;
      if (activeLocationCurrent) activeLocation = activeLocationCurrent;
      else if (activeLocationPast) activeLocation = activeLocationPast;

      this.setState({
        otherLocations: assignments,
        lkl,
        activeLocation,
        pastReservation,
        upcomingReservations
      });
    } catch (e) {
      console.error(e);
      this.setState({
        otherLocations: null,
        lkl: null,
        activeLocation: null,
        pastReservation: [],
        upcomingReservations: []
      });
    }
  }

  isItemReservation = (item, upcomingReservations) => {
    const lib = Context.getInstance().lib;
    const geometryEngine = lib.esri.geometryEngine;

    // Compare geometries to find reservation
    const feature = item.getFeature();
    const featureGeometry = feature && feature.geometry;
    const index = upcomingReservations.findIndex((reservation) => {
      const reservationGeometry = reservation.unit && reservation.unit.geometry;
      return (featureGeometry && reservationGeometry && geometryEngine.equals(featureGeometry, reservationGeometry));
    });

    return { isReservation: index > -1, index };
  }

  filterHotelReservations = async (bookings, hasRecurrenceFields) => {
    if (!bookings || !bookings.length) {
      return [];
    }

    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const unitFields = unitsDataset.layer2D && unitsDataset.layer2D.fields;
    const hotelBookings = [];

    // Gather the unit IDs from the reservations to make a query
    const unitIds = bookings
      .map((booking) => {
        const bookingAttrs = booking.attributes;
        const unitId = aiimUtil.getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
        return `'${escSqlQuote(unitId)}'`;
      });
    // To make sure we don't add duplicate ids to the query
    // Ex: UNIT_ID IN ('1', '1', '2', '2') -> UNIT_ID IN ('1', '2')
    const uniqueIds = [...new Set(unitIds)];

    const assignmentTypeField = aiimUtil.findFieldName(unitFields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const whereClauses = [
      `${unitsDataset.uidField} IN (${uniqueIds.join(", ")})`,
      `${assignmentTypeField} = 'hotel'`
    ];
    const where = `${whereClauses.join(" AND ")}`;

    try {
      const results = await unitsDataset.query(where);
      const units = (results && results.features) || [];

      // Filter units by those that are hotels
      const hotelingUnitIds = units.map((unit) => aiimUtil.getAttributeValue(unit.attributes, FieldNames.UNIT_ID));

      // Find the bookings that have hoteling unit IDs
      bookings.forEach((booking) => {
        const bookingAttrs = booking.attributes;
        const unitId = aiimUtil.getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
        if (hotelingUnitIds.includes(unitId)) {
          const unit = units.find((unit) => aiimUtil.getAttributeValue(unit.attributes, FieldNames.UNIT_ID) === unitId);
          const hotelBooking = { booking, unit };
          if (hasRecurrenceFields) {
            const id = aiimUtil.getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_ID);
            const config = aiimUtil.getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_CONFIG);
            if (id && config?.length > 0) {
              hotelBooking.recurrenceId = id;
            }
          }
          hotelBookings.push(hotelBooking);
        }
      });
    } catch(e) {
      console.error(e);
    }

    return hotelBookings;
  }

  // makeBooking = (checkInDate, checkOutDate, allDay, options) => {
  //   const type = getBookingSystemType();
  //   const i18n = Context.getInstance().i18n;
  //   const modalOptions = {
  //     title: i18n.more.bookWorkspace.multipleBookingTitle,
  //     message: i18n.more.bookWorkspace.multipleBookingsMessage,
  //     showOKCancel: true,
  //     hideCancel: true,
  //     okLabel: i18n.general.ok
  //   }
  //   if (options && options.reserveForInfo && options.reserveForInfo.username) {
  //     const occupant = options.reserveForInfo.occupantFeature;
  //     const name = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
  //     const template = i18n.more.bookWorkspace.bookingOtherConflict;
  //     modalOptions.message = stringFormatter(template, { person: name });
  //   }
  //   switch (type) {
  //     case "office365":
  //       this.makeBookingOffice365(checkInDate, checkOutDate);
  //       break;
  //     case "esri":
  //       this.makeBookingEsri(checkInDate, checkOutDate, allDay, options);
  //       break;
  //     default:
  //       return;
  //   }
  // }

  // makeBookingOffice365 = (checkInDate, checkOutDate) => {
  //   // return login(this.bookingSystem)
  //   // .then((status)=> {
  //   //   if(status === "Success") return getAccessToken(this.bookingSystem);
  //   // })
  //   return getAccessToken(this.bookingSystem).then((token) =>{
  //     const scheduleEmail = this.state.scheduleEmail;
  //     const unitName = this.state.unitName;
  //     const params = {
  //       email: scheduleEmail,
  //       unitName,
  //       checkInDate,
  //       checkOutDate,
  //       item: this.props.item
  //     }
  //     return bookWorkspace(this.bookingSystem, token, params);
  //   })
  //   .then((response) => {
  //     if(response){
  //       this.renderBookingConfirmed();
  //     }
  //   })
  //   .catch((error) =>{
  //     if (error && isNetworkError(error.message)) {
  //       this.showServerErrorPopup(checkInDate, checkOutDate);
  //     }
  //     console.error(error);
  //   })
  // }

  // makeBookingEsri = (checkInDate, checkOutDate, allDay, options) => {
  //   const params = {
  //     unit: this.getFeature(),
  //     checkIn: checkInDate,
  //     checkOut: checkOutDate,
  //     allDay,
  //     item: this.props.item
  //   };
  //   if (options.others && options.others.length > 0) {
  //     params.others = options.others;
  //   }

  //   return bookWorkspace(this.bookingSystem, null, params)
  //     .then((data) => {
  //       Topic.publish(Topic.RenderBookingList, {});
  //       this.renderBookingConfirmed(data, params);
  //     })
  //     .catch((e) => {
  //       if (e && isNetworkError(e.message)) {
  //         this.showServerErrorPopup(checkInDate, checkOutDate, allDay, options);
  //       } else {
  //         const i18n = Context.getInstance().i18n;
  //         let title = i18n.more.bookWorkspace.workspaceUnavailableTitle;
  //         title = title.replace("{unitName}", this.state.unitName);
  //         let errorMessage = i18n.more.bookWorkspace.workspaceUnavailable;
  //         errorMessage = errorMessage.replace("{unitName}", this.state.unitName);
  //         ModalController.confirm({
  //           isError: true,
  //           title: title,
  //           message: errorMessage,
  //           submessage: null,
  //           hideCancel: true
  //         });
  //       }
  //       console.error(e);
  //     });
  // }

  checkAvailability = (options) => {
    const unit = this.getFeature();
    const task = {
      checkInDate: options.checkInDate,
      checkOutDate: options.checkOutDate,
      allDay: options.allDay,
      options: {
        ...options,
        recurrence: options.recurrence
      },
      unit: unit,
      item: this.props.item,
      unitName: this.state.unitName,
      levelName: this.state.levelName,
      facilityName: this.state.facilityName,
      scheduleEmail: this.state.scheduleEmail,
      bookingSystem: this.bookingSystem,
      bookingType: "hotel",
      renderBookingConfirmed: this.renderBookingConfirmed
    }
    task.retry=()=> {
      this.checkAvailability(options);
    }
    return validateUtil.verifyAndBook(task);
  }

  generateAppLaunchUrls() {
    appLaunchUtil.generateUrlsCategoriesAndLayers(this.props.item).then(urls => {
      this.setState({
        appLaunchUrls: urls
      });
    }).catch(ex => {
       console.error("Error generating app launch urls",ex);
    });
    // appLaunchUtil.generateUrls(this.props.item).then(urls => {
    //   //console.log("urls222",urls);
    //   this.setState(state => {
    //     return {
    //       appLaunchUrls: urls
    //     };
    //   });
    // }).catch(ex => {
    //    console.error("Error generating app launch urls",ex);
    // });
  }

  getCaption() {
    const v = this.props.item.getTitle();
    if (typeof v === "string") return v;
    return "";
  }

  getFeature() {
    return (this.props.item.getFeature());
  }

  getSource() {
    return (this.props.item.getSource());
  }

  modeClicked(mode) {
    // const activeModeKey = this.props.infoPanelKey + "-ItemDetails-ActiveMode";
    // if (mode) {
    //   Rdx.setValue(this,activeModeKey,mode);
    // }
    this.setState(state => {
      return {
        mode: mode
      };
    });
    if (this.props.realTimeToggled) this.props.realTimeToggled(mode);
  }

  render() {
      return (
        <div className={CSS.main}>
          {this.renderHeader()}
          {this.renderContent()}
        </div>
      );
  }

  renderActions() {
    const isKiosk = Context.getInstance().isKiosk;
    const { activeLocation, otherLocations, pastReservation, upcomingReservations, lkl } = this.state;
    const item = this.props.item;
    const appLaunchUrls = this.state.appLaunchUrls;
    const hasAppLaunch = (appLaunchUrls && appLaunchUrls.length > 0);
    const listItems = [];

    const source = this.getSource();
    const feature = this.getFeature();
    const hasGeometry = itemUtil.hasGeometry(source,feature);

    // supportsMeetingRooms() and supportHotels() check `isKiosk` internally
    let showBookMeetingRoom = reservationUtil.supportsMeetingRooms() && this._isBookableMeetingRoom().bookable;
    let showBookHotelRoom = reservationUtil.supportsHotels() && this._isBookableHotel().bookable;

    let moreActions = {};

    if (item && item.isValid()) {

      if (showBookHotelRoom) {
        listItems.push(
          <li key="bookhotel">
            <Actions.BookHotelRoom item={item}
              onBookClicked={info => {
                this.bookClicked()
              }}
            />
          </li>
        );
        if (hasAppLaunch) {
          moreActions.setHome = true;
        }
      }

      if (showBookMeetingRoom) {
        listItems.push(
          <li key="bookmeeting">
            <Actions.BookMeetingRoom item={item} 
              onBookClicked={info => {
                if (this.hasAreaAccess(feature,"meetingRoom")) {
                  this.bookClickedMeetingRooms()
                } else {
                  console.log("Area access is no longer available, Meeting Room panel has likely closed...")
                }
              }}
              />
          </li>
        );
        if (hasAppLaunch) {
          moreActions.setHome = true;
        }
      }

      if (Context.getInstance().config.networkServiceUrl) {
        listItems.push(
          <li key="directions"><Actions.Directions item={item} disabled={!hasGeometry}/></li>
        );
      }

      const showLocations = () => {
        const hasOtherLocations = (otherLocations && assignmentsFound(otherLocations)) || lkl;
        const hasReservations = this.isItemReservation(item, upcomingReservations).isReservation
          ? upcomingReservations.length > 1
          : upcomingReservations.length > 0;
        return hasOtherLocations || hasReservations;
      }

      if (showLocations()) {
        listItems.push(
          <li key="otherLocations">
            <Actions.Locations
              activeLocation={activeLocation}
              otherLocations={otherLocations}
              pastReservation={pastReservation}
              upcomingReservations={upcomingReservations}
              hasLKL={!!lkl}
            />
          </li>
        );
      }

      if (!isKiosk) {

        if (!moreActions.setHome) {
          listItems.push(
            <li key="setHome"><Actions.SetHome item={item} disabled={!hasGeometry}/></li>
          );
        }

        listItems.push(
          <li key="saveFavorite"><Actions.SaveFavorite item={item} disabled={!hasGeometry}/></li>
        );

        listItems.push(
          <li key="share">
            <ShareUrl item={item} caption={this.getCaption()} />
          </li>
        );

        
        if (hasAppLaunch) {
          listItems.push(
            <li key="appLaunch">
              <Actions.AppLaunch urls={appLaunchUrls}
                item={item}
                hasGeometry={hasGeometry}
                moreActions={moreActions} />
            </li>
          );
        }

        // const configuration = Context.instance.configuration;
        // const configurables = configuration.extractConfigurables();
        // if (configurables.officeHoteling && configurables.officeHoteling.enabled){
        //   if (this.isBookableUnit()){
        //     const i18n = Context.getInstance().i18n;
        //     bookUnit = (
        //       <BookButtonDiv>
        //         <BookButton
        //           tabIndex="0"
        //           role="button"
        //           aria-label={i18n.more.bookWorkspace.bookInfoAriaLabel}
        //           iconPosition={"before"}
        //           clear
        //           icon={<LabelIcon/>}
        //           onClick={this.bookClicked}
        //         >
        //           {i18n.more.bookWorkspace.bookButton}
        //         </BookButton>
        //       </BookButtonDiv>
        //     )
        //   }
        // }

      }
    }

    if (listItems.length === 0) return null;
    return (
      <div className={CSS.actionBarContainer}>
      <ul className={CSS.actionBar}>
        {listItems}
      </ul>
      {/*}
      <div className={CSS.infopanelBookButton}>
      {bookUnit}
      </div>
      */}
      </div>
    );
  }

  renderBookingConfirmed(data, params, task) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };
    const recurrence = params.recurrence;    
    let bookingType = "single";
    if (recurrence?.enabled) {
      bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
    }
    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={getHotelBookingSystemType()}
        bookingType={bookingType}
        unitName={task.unitName}
        levelName={task.levelName}
        facilityName={task.facilityName}
        closePopup = {onClose}
        data={data}
        unit={params && params.unit}
        params={params}
      />
    ), node);
  }

  renderBookingDetails(when) {
    const node = document.createElement("div");
    document.body.appendChild(node);
    const spaceType = this.state.spaceType;
    const areaName = this.state.areaName;
    const area = this.state.area;
    const unit = this.getFeature();
    const areaId = aiimUtil.getAttributeValue(unit.attributes, FieldNames.AREA_ID);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <BookingDetails
          spaceType = {spaceType}
          area={area}
          areaName = {areaName}
          areaId={areaId}
          scheduleEmail = {this.state.scheduleEmail}
          unitName = {this.state.unitName}
          levelName = {this.state.levelName}
          facilityName = {this.state.facilityName}
          checkAvailability = {this.checkAvailability}
          isBookWorkspacePanel = {this.props.isBookWorkspacePanel}
          closePopup = {onClose}
          unitFeature={this.getFeature()}
          when={when}
          reserveForInfo={Context.instance.aiim.reserveForInfo}
          reserveForSelf={false}
        />
      </Provider>
    ), node);
  }

  renderSchedule(info, bookingType) {
    const i18n = Context.getInstance().i18n;
    const node = document.createElement("div");
    document.body.appendChild(node);
    const scheduleInfo = this.getScheduleInfo(info);
    const { roomItem, start, when } = scheduleInfo;

    const controller = new ModalController();
    const template = i18n.meetingRooms.schedule.captionPattern;
    const title = stringFormatter(template, { room: roomItem.name });
    const modalProps = {
      className: "i-modal-confirm i-modal-schedule",
      title: title,
      cancelLabel: i18n.general.close,
      showOKCancel: true,
      hideOK: true
    };

    let type = bookingType;
    if (!bookingType) type = "hotel"

    const content = (
      <Schedule
        bookingType={type}
        item={roomItem}
        start={start}
        when={when}
        onShowOccupant={() => controller.close()}
        onBookClicked={(when) => {
          if (info && info.type === "meetingRoom") {
            controller.close();
            let criteria = {
              when: when
            }
            let showInfo = {
              bookItem: info.bookItem,
              criteria: criteria
            }
            Book.show(showInfo)
          } else {
            this.renderBookingDetails(when);
            controller.close();
          }
        }}
      />
    );
    controller.show(modalProps, content);
  }

  getScheduleInfo = (info) => {

    let isHotel = true, start, when;
    if (info && info.type === "meetingRoom") isHotel = false;
    const attributes = this.getFeature().attributes;
    const roomItem = {
      isHotel: isHotel,
      unitId: aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_ID),
      scheduleEmail: aiimUtil.getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL),
      name: aiimUtil.getAttributeValue(attributes, FieldNames.NAME),
      unitFeature: this.getFeature()
    };

    // Form the when object
    if (isHotel) {
      const defaultTimes = makeInitialTimes();
      const isAllDay = !!this.props.rdxAllDayChecked;
      const startDate = this.props.rdxStartDateSelected;
      const startTime = this.props.rdxStartTimeSelected || defaultTimes.startTime;
      const endDate = this.props.rdxEndDateSelected;
      const endTime = this.props.rdxEndTimeSelected || defaultTimes.endTime;
  
      let dtStart = new Date(makeStartDateTime(startDate, startTime, isAllDay));
      let dtEnd = new Date(makeEndDateTime(endDate, endTime, isAllDay, true));
      if (isAllDay) {
        dtStart = moment(dtStart).startOf("day").toDate();
        dtEnd = moment(dtEnd).endOf("day").toDate();
      }
      start = dtStart.getTime();
      when = {
        duration: isAllDay ? "allDay" : null,
        start: {
          date: dtStart.getTime()
        },
        end: {
          date: dtEnd.getTime()
        },
        recurrence: { enabled: false }
      }
    } else {
      when = info && info.criteria && info.criteria.when;
      start = when && when.start && when.start.date;
    }

    return { roomItem, start, when };
  }

  renderContent() {
    const i18n = Context.getInstance().i18n;
    //const isKiosk = Context.getInstance().uiMode.isKiosk;
    const isAnonymous = Context.getInstance().user.isAnonymous();
    const feature = this.getFeature();
    //if (!feature || !feature.geometry) {
    if (!feature) {
      return (
        <div className="i-padded">
          <p>{i18n.infoPanel.messages.noGeometry}</p>
        </div>
      );
    }
    const source = this.getSource();
    const item = this.props.item;
    const { onlyRealTime, trackingInfo } = this.props;
    const itemIsValid = (source && item && item.isValid());
    const hasGeometry = itemUtil.hasGeometry(source,feature);

    let canObserve = false, realTime = null;
    const detailsStyle = {};
    //const activeMode = this.props.rdxActiveMode || "details";
    const activeMode = this.state.mode || "details";
    //if (!isKiosk && !isAnonymous && itemIsValid && source.isAiimPeople()) {
    if (!isAnonymous && itemIsValid && source && source.isPeopleLayer()) {
      const trackingViews =  Context.getInstance().user.trackingViews;
      if (trackingViews) {
        canObserve = trackingViews.canObserve(source,feature);
        if (canObserve) {
          if (activeMode === "realtime" || onlyRealTime) detailsStyle.display = "none";
          realTime = (
            <RealTimeLocation infoPanelKey={this.props.infoPanelKey} item={item}
              onlyRealTime={onlyRealTime}
              trackingInfo={trackingInfo}
              realTimePinActive={this.props.realTimePinActive}
              modeClicked={this.modeClicked} />
          );
        }
      }
    }

    const hasBookings = this.state.bookings.length > 0;
    let activeTab = this.props.rdxActiveTab || "attributes";
    if (!hasBookings && activeTab === "mybookings") {
      activeTab = "attributes";
    }
    const tabs = (
      <>
      {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
      <nav className={CSS.tabs} role="tablist">
        {this.renderTab(activeTab,"attributes",i18n.infoPanel.tabs.attributes)}
        {hasBookings ? this.renderTab(activeTab, "mybookings", i18n.infoPanel.tabs.myBookings) : null}
        {this.renderTab(activeTab,"related",i18n.infoPanel.tabs.related,!hasGeometry)}
      </nav>
      </>
    );

    let bookUnit;
    const attributes = item.searchResult.feature.attributes;
    const scheduleEmail = aiimUtil.getAttributeValue(attributes,FieldNames.SCHEDULE_EMAIL);
    const assignmentType = aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);

    // if(scheduleEmail && assignmentType === "hotel"){
    //   bookUnit = (
    //     <BookButtonDiv>
    //       <BookButton iconPosition={"after"} clear icon={<LabelIcon/>}
    //           onClick={()=>this.bookClicked(item, scheduleEmail)}>
    //         Book
    //       </BookButton>
    //     </BookButtonDiv>
    //   )
    // }

    let tabContent = null;
    if (itemIsValid) {
      tabContent = (
        <div key="i-feature-tabcontent" className={CSS.tabContent}>
          <Attributes item={item} active={activeTab === "attributes"}/>
          <MyHotelBookings bookings={this.state.bookings} active={activeTab === "mybookings"} />
          <Related item={item} active={activeTab === "related"}/>
        </div>
      );
    }

    const isKiosk = Context.getInstance().isKiosk;
    let showNotAssigned = false;
    if (!isKiosk) {
      const hotelInfo = this._isBookableHotel();
      const meetingInfo = this._isBookableMeetingRoom();
      if ((!hotelInfo.bookable && hotelInfo.notAssigned)
        || (!meetingInfo.bookable && meetingInfo.notAssigned)) {
        showNotAssigned = true;
      }
    } 

    const isRtl = Context.getInstance().uiMode.isRtl;
    const marginStyle = {
      height: '16px',
      width: '16px',
      marginRight: '1rem',
      marginLeft: '0.2rem'
    }
    if (isRtl) {
      marginStyle.marginRight = '0.2rem';
      marginStyle.marginLeft = '1rem';
    }

    return (
      <div key="i-infopanel-content" className={CSS.infopanelContent}>
        {realTime}
        {showNotAssigned && 
          (<div className={CSS.notAssigned}>
            <span>{Icons.infoNoFill()}</span>
            <span>{i18n.more.bookWorkspace.noBookingPermission}</span>
          </div>)
        }
        <div key="i-feature-content" className={CSS.featureContent} style={detailsStyle}>
          {this.renderActions()}
          {/* {bookUnit} */}
          {tabs}
          {this.props.backNextButtons}
          {tabContent}
        </div>
      </div>
    );
  }

  renderHeader() {
    const caption = this.getCaption();
    return <InfoPanelHeader caption={caption} infoPanelKey={this.props.infoPanelKey}/>
  }

  renderTab(activeTab,type,label,disabled) {
    let className = CSS.tab;
    const hasBookings = this.state.bookings.length;
    const selected = (activeTab === type);
    if (selected) className += " " + CSS.activeTab;
    if (disabled) className += " i--disabled";
    if (hasBookings) className += " i--hasbookings";
    const tab = (
      <button className={className} role="tab" aria-selected={selected}
      onClick={this.tabClicked} data-tabtype={type}><span data-tabtype={type}>{label}</span></button>
    )
    return tab;
  }

  // showServerErrorPopup = (checkInDate, checkOutDate, allDay, bookingOptions) => {
  //   const i18n = Context.getInstance().i18n;
  //   const options = {
  //     title: i18n.more.bookWorkspace.error,
  //     message: i18n.more.bookWorkspace.bookHotelsError,
  //     okLabel: i18n.more.bookWorkspace.tryAgain,
  //     showOkCancel: true,
  //     hideCancel : false,
  //     closeOnOK: true,
  //     cancelLabel: i18n.more.bookWorkspace.cancel
  //   };
  //   ModalController.confirm(options)
  //   .then(result => {
  //     if(result.ok) {
  //       this.makeBooking(checkInDate, checkOutDate, allDay, bookingOptions);
  //     }
  //   })
  //   .catch((error) => {
  //     if (error && isNetworkError(error.message)) {
  //       this.showServerErrorPopup(checkInDate, checkOutDate, allDay, bookingOptions);
  //     } else {
  //       Topic.publish(Topic.publishErrorUpdatingData());
  //     }
  //     console.error(error);
  //   })
  // }

  tabClicked(event) {
    try {
      const activeTabKey = this.props.infoPanelKey + "-ItemDetails-ActiveTab";
      const type = event.target.getAttribute("data-tabtype");
      if (type) {
        Rdx.setValue(this,activeTabKey,type);
      }
    } catch(ex) {
      console.warn("Error handling tab click for event:",event);
      console.error(ex);
    }
  }

  _isBookableHotel = () => {
    const info = {
      bookable: false,
      notAssigned: false
    }
    const unitFeature = this.getFeature();
    const attributes = unitFeature.attributes;
    const assignmentType = aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID);
    if (assignmentType !== "hotel" || !areaId) {
      return info;
    }

    if (!this.hasAreaAccess(unitFeature,"hotel")) {
      info.notAssigned = true;
      return info;
    }

    const bookingSystemType = getHotelBookingSystemType();
    switch (bookingSystemType) {
      case "office365":
        const scheduleEmail = aiimUtil.getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL);
        info.bookable = !!scheduleEmail;
        break;
      case "esri":
        info.bookable = true;
        break;
      default:
        break;
    }

    return info;
  }
  
  _isBookableMeetingRoom = () => {
    const info = {
      bookable: false,
      notAssigned: false
    };
    const source = this.getSource();
    const feature = this.getFeature();
    const units = Context.instance.aiim.datasets.units;
    if (units && units.isMeetingRoom(source, feature)) {
      if (!this.hasAreaAccess(feature,"meetingRoom")) {
        info.notAssigned = true;
      } else {
        info.bookable = true;
      }
    }
    return info;
  }

  // type: "hotel" | "meetingRoom"
  hasAreaAccess = (unitFeature, type) => {
    let notAssigned = false;
    const areaId = aiimUtil.getAttributeValue(unitFeature.attributes, FieldNames.AREA_ID);
    if (areaId) {
      let ids = [];
      if (type === "meetingRoom") {
        // there is no <ReserveFor> control in the Book dialog at 11.3
        ids = Context.instance.aiim.getAppUserAreaIDs(true,false)
      } else {
        ids = Context.instance.aiim.getAppUserAreaIDs(false,true)
      }
      if (ids.indexOf(areaId) === -1) {
        notAssigned = true;
      }
    }
    return !notAssigned
  }

}

const mapStateToProps = (state,ownProps) => {
  //const activeModeKey = ownProps.infoPanelKey + "-ItemDetails-ActiveMode";
  const activeTabKey = ownProps.infoPanelKey + "-ItemDetails-ActiveTab";
  return {
    //rdxActiveMode: Rdx.getValue(state,activeModeKey),
    rdxActiveTab: Rdx.getValue(state,activeTabKey),
    rdxStartDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDayChecked: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY)
  }
}

export default connect(mapStateToProps)(ItemDetails);
