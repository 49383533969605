import moment from 'moment';

import { getHotelBookingSystem, getMeetingBookingSystem } from "./WorkspaceReservation/workspaceReservationUtil";
import { isNetworkError } from '../../../../../util/networkUtil';
import { stringFormatter } from '../../../../../util/formatUtil';
import { getZulu } from '../../../Events/dateUtil';
import { escSqlQuote } from '../../../../../aiim/util/selectionUtil';
import { findFieldName, getAttributeValue } from '../../../../../aiim/util/aiimUtil';

import { ModalController }  from "../../../../../../src/common/components/Modal/index";
import { cancelBooking, updateCheckInOut, updateMultiCheckInOut } from "./WorkspaceReservation/OfficeHotelingInterface";

import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';
import FieldNames from '../../../../../aiim/datasets/FieldNames';
import { getRecurrenceSeries, IBookingData, IBookingsState } from './MyBookings';
import { IApplyEditsResult } from '@esri/arcgis-rest-feature-layer';

import * as reservationUtil from "../../../../../aiim/util/reservationUtil";

const APPROVED = 1;
const CHECKED_IN = 4;

export function allowHotelCheckin(): boolean {
  return true;
}

export function allowMeetingRoomCheckin(): boolean {
  const workspaceReservation = Context.instance.config.workspaceReservation;
  if (workspaceReservation.enableMeeting && workspaceReservation.allowMeetingCheckIn && (workspaceReservation.reservationTypeMeeting === "esri")) {
    return true;
  }
  return false;
}

export async function checkInOnStartup() {
  const canCheckIntoHotels = reservationUtil.canCheckIntoHotels();
  const canCheckIntoMeetingRooms = reservationUtil.canCheckIntoMeetingRooms();
  if (canCheckIntoHotels || canCheckIntoMeetingRooms) {
    const unitsDataset = Context.instance.aiim.datasets.units;
    const reservationsDataset = Context.instance.aiim.datasets.reservations;
    const { startTimeField, endTimeField  } = reservationsDataset;
    const zStart = getZulu(new Date(Date.now()+(20*60000)));
    const zEnd = getZulu(new Date());
    const bookingDateFilter = {
      where: `((${startTimeField} < TIMESTAMP '${escSqlQuote(zStart)}') AND (${endTimeField} > TIMESTAMP '${escSqlQuote(zEnd)}'))`
    }
    const result = await getUserReservations("mybookings",bookingDateFilter,true);
    let bookings = result?.bookings;
    if (!canCheckIntoHotels || !canCheckIntoMeetingRooms) {
      if (bookings?.length > 0) {
        const unitsIds = [];
        bookings.forEach(data => {
          const unitId = getAttributeValue(data.booking.attributes,FieldNames.UNIT_ID);
          if (unitId) unitsIds.push(`'${escSqlQuote(unitId)}'`);
        });
        if (unitsIds.length > 0) {
          const uniqueIds = [...new Set(unitsIds)];
          const where = `${unitsDataset.uidField} IN (${uniqueIds.join(", ")})`;
          const result2 = await unitsDataset.queryAttributes(where);
          if (result2?.features?.length > 0) {
            const typesByUnitId = {};
            result2.features.forEach(f => {
              const unitId = getAttributeValue(f.attributes,FieldNames.UNIT_ID);
              const isHotel = (getAttributeValue(f.attributes,unitsDataset.asnTypeField) === "hotel");
              const isMeetingRoom = unitsDataset.isMeetingRoom(unitsDataset.getSource(),f);
              if (isHotel) typesByUnitId[unitId] = "hotel";
              else if (isMeetingRoom) typesByUnitId[unitId] = "meetingRoom";
            })
            const bookings2 = [];
            bookings.forEach(data => {
              const unitId = getAttributeValue(data.booking.attributes,FieldNames.UNIT_ID);
              if (canCheckIntoHotels) {
                if (typesByUnitId[unitId] === "hotel") bookings2.push(data);
              } else if (canCheckIntoMeetingRooms) {
                if (typesByUnitId[unitId] === "meetingRoom") bookings2.push(data);
              }
            });
            bookings = bookings2;
          }
        }
      }
    }
    if (bookings?.length > 0) {
      const reminders = getRemindersList(bookings,"mybookings");
      reminders.checkOut = [];
      if (reminders.checkIn.length > 0) {
        if (reminders.checkIn.length > 1) {
          const list = reminders.checkIn;
          list.sort((attrsA, attrsB) => {
            const startTimeA = getAttributeValue(attrsA, FieldNames.START_TIME);
            const startTimeB = getAttributeValue(attrsB, FieldNames.START_TIME);
            return startTimeA > startTimeB ? 1 : startTimeA < startTimeB ? -1 : 0;
          });
          reminders.checkIn = list;
        }
        const bookingType = canCheckIntoHotels ? "hotel" : "meetingRoom";
        showMultiReminderPopup("checkIn",reminders,"mybookings",bookingType);
      }
    }
  }
}

export async function filterHotelReservations (bookings: __esri.Graphic[], hasRecurrenceFields: boolean, fetchOccupants: boolean){
  if (!bookings || !bookings.length) {
    return [];
  }

  const unitsDataset = Context.getInstance().aiim.datasets.units;
  const reservations = Context.getInstance().aiim.datasets.reservations;
  const people = Context.getInstance().aiim.datasets?.people;
  const unitFields = unitsDataset.layer2D && unitsDataset.layer2D.fields;
  const hotelBookings: IBookingData[] = [];

  // Gather the unit IDs from the reservations to make a query
  const unitIds = bookings
    .map((booking) => {
      const bookingAttrs = booking.attributes;
      const unitId = getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
      return `'${escSqlQuote(unitId)}'`;
    });
  // Gather fullNames from the reservations to query occupants
  const fullNames = Object.fromEntries(bookings.map(({ attributes }) =>
  ([
    getAttributeValue(attributes, reservations.reservedForUsernameField),
    getAttributeValue(attributes, reservations.reservedForFullNameField)
  ])));
  // To make sure we don't add duplicate ids to the query
  // Ex: UNIT_ID IN ('1', '1', '2', '2') -> UNIT_ID IN ('1', '2')
  const uniqueIds = [...new Set(unitIds)];

  const assignmentTypeField = findFieldName(unitFields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const whereClauses = [
    `${unitsDataset.uidField} IN (${uniqueIds.join(", ")})`,
    `${assignmentTypeField} = 'hotel'`
  ];
  const where = `${whereClauses.join(" AND ")}`;

  try {
    const results = await unitsDataset.query(where);
    const occupants = (people && fetchOccupants) ? await people.getOccupantsByFullName(fullNames) : [];
    const units = (results && results.features) || [];

    // Filter units by those that are hotels
    const hotelingUnitIds = units.map((unit) => getAttributeValue(unit.attributes, FieldNames.UNIT_ID));

    // Find the bookings that have hoteling unit IDs
    bookings.forEach((booking) => {
      const bookingAttrs = booking.attributes;
      const unitId = getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
      if (hotelingUnitIds.includes(unitId)) {
        const unit = units.find((unit) => getAttributeValue(unit.attributes, FieldNames.UNIT_ID) === unitId);
        const hotelBooking: IBookingData = { booking, unit };
        if (hasRecurrenceFields) {
          const id = getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_ID);
          const config = getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_CONFIG);
          if (id && config?.length > 0) {
            hotelBooking.recurrenceId = id;
          }
        }
        if (occupants.length > 0) {
          const reservedFor = getAttributeValue(booking.attributes, FieldNames.RESERVED_FOR_FULL_NAME);
          const occupant = occupants.find(o => reservedFor === getAttributeValue(o?.attributes, FieldNames.PEOPLE_FULLNAME));
          if (occupant) {
            hotelBooking.occupant = occupant;
          }
        }
        hotelBookings.push(hotelBooking);
      }
    });
  } catch(e) {
    console.error(e);
  }
  return hotelBookings;
}

export async function getUserReservations(bookingType, bookingDateFilter, noHotelFilter?): Promise<IBookingsState> {
    const reservations = Context.getInstance().aiim.datasets 
                          && Context.getInstance().aiim.datasets.reservations;
    if (!reservations) return ({isWorking: false});

    const username = Context.getInstance().user.getUsername();
    const { reservedForUsernameField, reservedByUsernameField, endTimeField, stateField  } = reservations;

    if (!username || !reservedForUsernameField) return ({isWorking: false});

    try {
      const now = getZulu(new Date());
      let whereClauses;
      if (bookingType === "otherbookings") {
        whereClauses = [
          `${reservedByUsernameField} = '${escSqlQuote(username)}'`,
          `${reservedForUsernameField} <> '${escSqlQuote(username)}'`,
          `(${endTimeField} > TIMESTAMP '${escSqlQuote(now)}' OR ${stateField} = ${CHECKED_IN})`
        ];
      } else {
        whereClauses = [
          `${reservedForUsernameField} = '${escSqlQuote(username)}'`,
          `(${endTimeField} > TIMESTAMP '${escSqlQuote(now)}' OR ${stateField} = ${CHECKED_IN})`
        ];
      }
      if (bookingDateFilter && bookingDateFilter.where) {
        whereClauses.push(bookingDateFilter.where);
      }
      const myReservations = await reservations.query(whereClauses.join(" AND "));
      if (noHotelFilter) {
        const features = (myReservations && myReservations.features) || [];
        const bookings = features.map(f => ({booking: f}))
        return ({bookings, isWorking: false});
      } else {
        const bookings = (myReservations && myReservations.features) || [];
        const fetchOccupants = (bookingType === "otherbookings");
        const hotelBookings = await filterHotelReservations(bookings, reservations.hasRecurrenceFields(),fetchOccupants);
        const series = getRecurrenceSeries(hotelBookings);
        return ({ bookings: hotelBookings, isWorking: false, series })
      }
    } catch(e) {
      console.error("Unable to query for user reservations", e);
      if (!noHotelFilter) {
        if (e && isNetworkError(e.message)) {
          const i18n = Context.getInstance().i18n;
          Topic.publishNetworkError(i18n.meetingRooms.issues.m7);
        } else {
          Topic.publishErrorAccessingData();
        }
      }
      return ({bookings: [], isWorking: false})
    }
}

export function showServerErrorPopup(type, attributes, bookingType?: "hotel" | "meetingRoom"){
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);
    const reservations = Context.instance.aiim.datasets.reservations;
    const bookingSystem = (bookingType === "meetingRoom") ? getMeetingBookingSystem() : getHotelBookingSystem();
    const i18n = Context.getInstance().i18n;
    let message;

    if(type === "checkIn") {
      message = i18n.more.bookWorkspace.checkInOut.checkIn.serverErrorMessage;
    } else if (type === "checkOut") {
      message = i18n.more.bookWorkspace.checkInOut.checkOut.serverErrorMessage;
    } else if (type === "cancel") {
      message = i18n.more.bookWorkspace.checkInOut.cancelBooking.serverErrorMessage;
    }
    message = message.replace("{unit}", unitName);

    const options = {
      title: i18n.more.bookWorkspace.checkInOut.error,
      message: message,
      okLabel: i18n.more.bookWorkspace.checkInOut.tryAgain,
      showOkCancel: true,
      hideCancel : false,
      closeOnOK: true,
      cancelLabel: i18n.more.bookWorkspace.checkInOut.close
    };

    ModalController.confirm(options)
    .then(result => {
      if (result.ok) {
        let objectIdField;
        if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
          objectIdField = reservations.layer2D.objectIdField;
        }
        const objectID = getAttributeValue(attributes, objectIdField);
        if (type === "cancel") {
          cancelBooking(bookingSystem, attributes).then(({ data }: { data: IApplyEditsResult }) => {
            if (data?.updateResults?.some(r => r.success === false)) {
              Topic.publishErrorUpdatingData();
            } else {
              Topic.publish(Topic.ShowToast, { message: i18n.more.bookWorkspace.checkInOut.cancelBooking.toastMessage });
              Topic.publish(Topic.RenderBookingList, {});
              Topic.publish(Topic.UpdateReservationDetails, { objectId: objectID });
            }
          }).catch(error => {
            if (error && isNetworkError(error.message)) {
              showServerErrorPopup(type, attributes, bookingType);
            } else {
              Topic.publishErrorUpdatingData();
            }
            console.error(error);
          });
        } else {
          if(type === "checkOut") attributes[reservations.checkOutTimeField] = new Date().getTime();
          if(type === "checkIn") attributes[reservations.checkInTimeField] = new Date().getTime();

          updateCheckInOut(bookingSystem, attributes, type)
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] && 
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                let msg = i18n.more.bookWorkspace.checkInOut.checkOut.toastMessage;
                if(type === "checkIn") msg = i18n.more.bookWorkspace.checkInOut.checkIn.toastMessage;
                Topic.publish(Topic.ShowToast,{
                  message: msg
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && isNetworkError(error.message)) {
              showServerErrorPopup(type, attributes, bookingType);
            } else {
              Topic.publishErrorUpdatingData();
            }
            console.error(error);
          })
        }
      }
    })
    .catch(error => {
      console.error(error);
      Topic.publishErrorUpdatingData()
    })
  }

  export function hideCheckInButton(attributes) {
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const checkInTime = getAttributeValue(attributes, FieldNames.CHECK_IN_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const currentTime = new Date().getTime();
    const startTimeM = moment(startTime);
    const currentTimeM = moment(currentTime);

    const elapsed = startTimeM.diff(currentTimeM, 'seconds');

    if(!checkInTime) {
      if((state === APPROVED) &&
          (((0 <= elapsed) && (elapsed <= 300)) || ((startTime <= currentTime) && ( currentTime <= endTime)))){
        return false;
      } else return true;
    }else return false;
  }

  export function showCheckInButton(attributes) {
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const checkInTime = getAttributeValue(attributes, FieldNames.CHECK_IN_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const currentTime = new Date().getTime();
    const startTimeM = moment(startTime);
    const currentTimeM = moment(currentTime);
    const elapsed = startTimeM.diff(currentTimeM, 'seconds');
    if (!checkInTime && (state === APPROVED)) {
      if (((0 <= elapsed) && (elapsed <= 300)) || ((startTime <= currentTime) && (currentTime <= endTime))) {
        return true;
      }
    }
    return false;
  }

  export function showCheckOutButton(attributes) {
    const checkInTime = getAttributeValue(attributes, FieldNames.CHECK_IN_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const currentTime = new Date().getTime();
    if (checkInTime && (state === CHECKED_IN)) {
      if (currentTime <= endTime) {
        return true;
      }
    }
    return false;
  }

  export function getRemindersList (sortedBookings, type){
    let reservations = sortedBookings;
    if (!sortedBookings || sortedBookings.length === 0) return;

    let listReminders = {
      checkIn: [],
      checkOut: []
    };
    reservations.forEach((data) => {
      let attributes;
      if(type === "mybookings") attributes = data.booking.attributes;
      else if(type === "otherbookings") attributes = data.reservations[0].attributes;
      const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
      const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
      const state = getAttributeValue(attributes, FieldNames.STATE);

      // Comparisons
      const startTimeM = moment(startTime);
      const currentTime = moment();
      const elapsed = startTimeM.diff(currentTime, 'seconds');
      const needsCheckIn = (0 <= elapsed && elapsed <= 300) 
                            || (startTime <= currentTime && currentTime <= endTime);
      const needsCheckOut = currentTime.isAfter(moment(endTime));

      if (state === APPROVED && needsCheckIn) {
        listReminders.checkIn.push(attributes);
      } else if (state === CHECKED_IN && needsCheckOut) {
        listReminders.checkOut.push(attributes);
      }
    });
    return listReminders;
  }

  export async function showMultiReminderPopup(
    type: "checkIn" | "checkOut", 
    remindersList, 
    listingType?: "mybookings" | "otherbookings",
    bookingType?: "hotel" | "meetingRoom"
  ) {
    const i18n = Context.instance.i18n;
    let reminders = remindersList[type];
    if (!reminders || reminders.length === 0) return;

    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    const bookingSystem = (bookingType === "meetingRoom") ? getMeetingBookingSystem() : getHotelBookingSystem();

    const refresh = () => {
      Topic.publish(Topic.RenderBookingList, {});
      Topic.publish(Topic.UpdateReservationDetails, {});
    }

    const update = async () => {
      const list = [];
      const now = Date.now();
      reminders.forEach(reminder => {
        const attr = Object.assign({},reminder);
        if (type === "checkIn") {
          attr[reservations.checkInTimeField] = now;
        } else if (type === "checkOut") {
          attr[reservations.checkOutTimeField] = now;
        }
        list.push(attr);
      })

      let error;
      try {
        const response = await updateMultiCheckInOut(bookingSystem,list,type);
        if (response && response.updateFeatureResults) {
          response.updateFeatureResults.some(r => {
            if (r.error) r = error;
            return !!error;
          })
        }
      } catch(ex) {
        error = ex;
      }
      if (error) {
        console.error(error)
        Topic.publishErrorUpdatingData(error);
        showMultiReminderPopup(type,remindersList,listingType,bookingType);
      } else {
        const msg = i18n.more.bookWorkspace.checkInOut[type].toastMessage;
        Topic.publish(Topic.ShowToast,{message: msg});
        refresh();
      }
    }

    let reminderUnitNames = reminders.map((reminder) => getAttributeValue(reminder, FieldNames.UNIT_NAME)).join(", ");
    const template = type === "checkIn" 
    ? i18n.more.bookWorkspace.checkInOut.reminder.newMessage 
    : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
    const message = stringFormatter(template, { units: reminderUnitNames, hotel: reminderUnitNames });
    const options = {
      title: i18n.more.bookWorkspace[type],
      message: message,
      showOkCancel: true,
      closeOnOK: true,
      okLabel: i18n.more.bookWorkspace[type],
      cancelLabel: i18n.more.bookWorkspace.checkInOut.notNow
    };

    if (reminders.length === 1) {
      const attributes = reminders[0];
      const unitName = attributes[reservations.unitNameField];
      let message;
      let template = type === "checkIn" 
        ? i18n.more.bookWorkspace.checkInOut.checkIn.bookingReady
        : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
      message = stringFormatter(template, { unit: unitName, hotel: unitName });
      if (listingType === "otherbookings" && type === "checkIn") {
        const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);
        const person = getAttributeValue(attributes, FieldNames.RESERVED_FOR_FULL_NAME);
        message = i18n.more.bookWorkspace.checkInOut.someoneElse.checkinReminder;
        message = message.replace("{unit name}", unitName);
        message = message.replace("{person}", person);
      }
      /* @todo FIXME: need an updated string for i18n.more.bookWorkspace.checkInOut.checkIn.bookingReady */
      const rmtxt = " Check in when you arrive at the location";
      if (message.endsWith(rmtxt)) message = message.replace(rmtxt,"");
      options.message = message;
    }

    const result = await ModalController.confirm(options);
    if (result && result.ok) {
      update();
    }
  }

    /**
   * Show a reminder popup to prompt the user to check in/out
   * 
   * @param {"checkIn" | "checkOut"} type 
   */

  export async function showReminderPopup(
    type: "checkIn" | "checkOut", 
    listReminders, 
    listingType?: "mybookings" | "otherbookings",
    bookingType?: "hotel" | "meetingRoom"
  ) {
    let reminders = listReminders[type];
    if (!reminders || reminders.length === 0) return;

    const i18n = Context.getInstance().i18n;
    let reminderUnitNames = reminders.map((reminder) => getAttributeValue(reminder, FieldNames.UNIT_NAME)).join(", ");
    const template = type === "checkIn" 
    ? i18n.more.bookWorkspace.checkInOut.reminder.newMessage 
    : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
    const message = stringFormatter(template, { units: reminderUnitNames, hotel: reminderUnitNames });

    const options = {
      title: i18n.more.bookWorkspace.checkInOut.reminder.caption,
      okLabel: i18n.more.bookWorkspace.checkInOut.reminder.close,
      message: message,
      showOkCancel: true,
      hideCancel : true,
      closeOnOK: true
    };

    const refresh = () => {
      Topic.publish(Topic.RenderBookingList, {});
      Topic.publish(Topic.UpdateReservationDetails, {});
    }

    const showToast = (attr) => {
      const person = getAttributeValue(attr, FieldNames.RESERVED_FOR_FULL_NAME);
      let msg = i18n.more.bookWorkspace.checkInOut[type].toastMessage;
      if (listingType === "otherbookings" && type === "checkIn") {
        msg = i18n.more.bookWorkspace.checkInOut.someoneElse.confirmationSubtitleCheckIn;
        msg = msg.replace("{person}", person);
      }

      Topic.publish(Topic.ShowToast,{
          message: msg
      });
    }

    if (reminders.length === 1) {
      const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
      const bookingSystem = (bookingType === "meetingRoom") ? getMeetingBookingSystem() : getHotelBookingSystem();
      const attributes = reminders[0];

      const unitName = attributes[reservations.unitNameField];
      let message;
      let template = type === "checkIn" 
        ? i18n.more.bookWorkspace.checkInOut.checkIn.bookingReady
        : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
      
      message = stringFormatter(template, { unit: unitName, hotel: unitName });

      let attributesRem;
      if (listingType === "otherbookings" && type === "checkIn") {
        const checkInItem = listReminders && listReminders.checkIn;
        if (checkInItem && checkInItem.length > 0) attributesRem = checkInItem && checkInItem[0];
        const unitName = getAttributeValue(attributesRem, FieldNames.UNIT_NAME);
        const person = getAttributeValue(attributesRem, FieldNames.RESERVED_FOR_FULL_NAME);
        message = i18n.more.bookWorkspace.checkInOut.someoneElse.checkinReminder;
        message = message.replace("{unit name}", unitName);
        message = message.replace("{person}", person);
      }

      options.message = message;
      options["okLabel"] = i18n.more.bookWorkspace[type];
      options["hideCancel"] = false;
      options["cancelLabel"] = i18n.more.bookWorkspace.checkInOut.notNow;

      try {
        const result = await ModalController.confirm(options);
        if (result && result.ok) {
          const attr = Object.assign({},attributes);
          if (type === "checkIn") {
            attr[reservations.checkInTimeField] = new Date().getTime();
          } else if (type === "checkOut") {
            attr[reservations.checkOutTimeField] = new Date().getTime();
          }
          const updateResponse = await updateCheckInOut(bookingSystem, attr, type);
          if (
            updateResponse && 
            updateResponse.updateFeatureResults && 
            updateResponse.updateFeatureResults[0] && 
            updateResponse.updateFeatureResults[0].error
          ) {
            throw new Error(updateResponse.updateFeatureResults[0].error);
          } else {
            showToast(attributesRem);
            refresh();
          }
        }
      } catch (error) {
        console.error(error);
        Topic.publishErrorUpdatingData(error);
      } finally {
        if (type === "checkIn" && listReminders.checkOut.length > 0) {
          await showReminderPopup("checkOut", listReminders["checkOut"], listingType, bookingType);
        }
      }
    } else {
      try {
        await ModalController.confirm(options);
      } catch (error) {
        console.error(error);
        Topic.publishErrorUpdatingData(error);
      } finally {
        if (type === "checkIn" && listReminders.checkOut.length > 0) {
          await showReminderPopup("checkOut", listReminders["checkOut"], listingType, bookingType);
        }
      }
    }
  }

export function showNoEditPopup (type, reservationManager){
    const i18n = Context.getInstance().i18n;
    
    let title;
    if (type === "checkIn") title = i18n.more.bookWorkspace.checkInOut.checkIn.caption;
    else if (type === "checkOut") title = i18n.more.bookWorkspace.checkInOut.checkOut.caption;
    else title = i18n.more.bookWorkspace.checkInOut.cancelBooking.caption;

    let message = i18n.more.bookWorkspace.checkInOut.noEditPermissonMsg;
    message = message.replace("{reservationManagerName}", reservationManager);
    const options = {
      title: title,
      message: message,
      okLabel: i18n.more.bookWorkspace.checkInOut.ok,
      closeOnOK: true,
      hideCancel: true
    };
    ModalController.confirm(options);
}
