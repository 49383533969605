import React, { useState, useEffect, useRef } from "react";

/** Provides a hook that adds state for managing ESRI and/or Dojo event listeners (i.e. __esri.WatchHandle) */
export const useHandles = (): [__esri.WatchHandle[], React.Dispatch<React.SetStateAction<__esri.WatchHandle[]>>] => {
  const [handles, setHandles] = useState<__esri.WatchHandle[]>([]);

  const clearHandles = () => {    
    if (!Array.isArray(handles)) return;
    handles.forEach((h) => {
      try {
        if (h && typeof h.remove === "function") {
          h.remove();
        }
      } catch(ex) {
        console.warn("Error component::clearHandles - error removing handle:", h);
        console.error(ex);
      }
    });
  }
  useEffect(() => clearHandles, [handles]);

  return [handles, setHandles];
}

export const useForceUpdate = () => {
  const [id, setId] = useState<number>(0);
  return () => setId(id => id + 1);
}