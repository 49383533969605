import React, { CSSProperties } from "react";

// Calcite Components
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as filterUtil from "./filterUtil";
import * as selectionUtil from "../../../aiim/util/selectionUtil";

// Syled-Components
import {MenuItem} from "../../styles/Filter/filterGroup";
import NumericFilter from "./NumericFilter";
import DateFilter from "./DateFilter";
import TextFilter from "./TextFilter";
import Context from "../../../context/Context";
import { IFilterPair } from "../../base/QueryCriteria";
import type FilterModel from "./FilterModel";

interface IFilterGroupProps {
  filterModel: FilterModel,
  onChange: (id: string, fieldName: string, value: any, operator: string) => void,
  pair: IFilterPair,
  queryCriteriaKey: string
}
interface IFilterGroupState {
  fieldName: string,
  fieldValue: any,
  hidden: boolean,
  filteredLevelInfo: {
    facilityIdx: { [prop: string]: number },
    levelIdx: { [prop: string]: number },
    siteIdx: { [prop: string]: number }
  },
  filterType: "numeric" | "date" | "text",
  values: {
    name: string,
    value: any
  }[]
}
export default class FilterGroup extends React.Component<IFilterGroupProps, IFilterGroupState> {
  applyLocalFilter = true;
  _mounted = false

  constructor(props) {
    super(props);
    this.state = component.newState({
      fieldName: this.props.pair.field,
      fieldValue: this.props.pair.value,
      hidden: this.props.pair.hidden,
      filteredLevelInfo: null,
      values: null,
      filterType : ''
    });
  }

  clear = () => {
    this.onChange(undefined);
  };

  componentDidMount() {
    this._mounted = true;
    if (this.state.fieldName || this.props.pair.getValues) {
      this.queryUniqueValues(this.state.fieldName);
    }

    if(this.props.pair) {
      let filterType = this.decideFilter();
      this.setState({
        filterType : filterType
      })
    }

    component.own(this, [

      Topic.subscribe(Topic.FilterValueSelected, params => {
        if (this._mounted && params.queryCriteriaKey === this.props.queryCriteriaKey) {
          const fieldName = this.props.pair.field;
          this.queryUniqueValues(fieldName);
        }
      }),

      Topic.subscribe(Topic.FilteredLevelIds, params => {
        if (this._mounted && this.props.pair.getValues &&
            params.queryCriteriaKey === this.props.queryCriteriaKey) {
          const pid = this.props.pair.id;
          if (pid === "__sites__" || pid === "__facilities__" || pid === "__levels__") {
            const values = this.props.pair.getValues({
              filteredLevelInfo: params.filteredLevelInfo
            });
            this.setState({
              filteredLevelInfo: params.filteredLevelInfo,
              values: values
            });
          }
        }
      }),

    ]);
  }

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this);
  }

  getQueryCriteria() {
    return QueryCriteria.get(this.props.queryCriteriaKey);
  }

  makeWhere(fieldName, includeSFL) {
    const queryCriteria = this.getQueryCriteria();
    const source = queryCriteria && queryCriteria.getSource();
    const layer = source && source.layer2D;
    const pairs = queryCriteria && queryCriteria._localPairs;
    let where = selectionUtil.getBaseDefinitionExpression(layer);
    let part = queryCriteria.requiredExpression;
    if (part && part.length > 0) {
      if (typeof where !== "string") where = "";
      if (where.length > 0) where += " AND ";
      where += "(" + part + ")";
    }
    if (this.applyLocalFilter) {
      part = queryCriteria.makeFilterPart(source, pairs, fieldName);
      if (part && part.length > 0) {
        if (typeof where !== "string") where = "";
        if (where.length > 0) where += " AND ";
        where += "(" + part + ")";
      }
      if (this.props.filterModel && includeSFL) {
        let sfl = this.props.filterModel._siteFacilityLevel;
        if (sfl) {
          part = queryCriteria.makeSiteFacilityLevelPart(source, sfl);
          if (part && part.length > 0) {
            if (typeof where !== "string") where = "";
            if (where.length > 0) where += " AND ";
            where += "(" + part + ")";
          }
        }
      }
    }
    return where;
  }

  onChange = (value?: any, operator?: string) => {
    if (typeof this.props.onChange === "function") {
      const id = this.props.pair.id;
      const fieldName = this.props.pair.field;
      this.props.onChange(id, fieldName, value, operator);
    }
  };

  queryUniqueValues(fieldName) {
    if (this.props.pair.fixedOptions) return;
    if (this.props.pair.getValues) {
      const values = this.props.pair.getValues({
        filteredLevelInfo: this.state.filteredLevelInfo
      });
      this.setState({
        values: values
      });
      if (this.props.pair.id === "__levels__") {
        let where = this.makeWhere(fieldName,false);
        filterUtil.queryLevelIds(this.getQueryCriteria(),where);
      }
    }
    const queryCriteria = this.getQueryCriteria();
    const source = queryCriteria && queryCriteria.getSource();
    const layer = source && source.layer2D;
    const fields = layer && layer.fields;
    const field = filterUtil.getField(fields, fieldName);
    if (!field) return;

    let where = this.makeWhere(fieldName,true);
    const options = {
      layer: layer,
      source: source,
      field: field,
      fieldName: fieldName,
      where: where,
      objectIds: queryCriteria && queryCriteria.objectIds
    };

    let numPerPage = 1000;
    if (options.layer.sourceJSON && options.layer.sourceJSON.maxRecordCount) {
      numPerPage = options.layer.sourceJSON.maxRecordCount;
    }

    filterUtil
    .queryUniqueValues(options)
    .then(values => {
      if(values && values.length >= numPerPage) {
        return filterUtil.queryAllUniqueValues(options);
      }
      return values;
    })
    .then(values => {
      this.setState({
        values: values
      });
    })
    .catch(ex => {
      console.error(ex);
    });
  }

  decideFilter= (): IFilterGroupState["filterType"] =>{
    const dataType = this.props.pair.type;
    if(dataType ==="integer" || dataType === "double" || dataType === "small-integer" ||dataType === "single" || 
        dataType === "long" || dataType === "oid") return "numeric";
    if(dataType === "date") return "date";
    if(dataType === "string" || dataType === "global-id") return "text";
    if(dataType === undefined || dataType === null || dataType === "blob" ||dataType === "xml" || dataType === "geometry" || 
      dataType === "raster") return null;
  }

  render() {
    const values = this.state.values;
    const fixedOptions = this.props.pair.fixedOptions;
    const filterType = this.state.filterType;
    const queryCriteria = this.getQueryCriteria();

    const menuItems = [];
    if (Array.isArray(fixedOptions)) {
      fixedOptions.forEach(info => {
        const v = info.value;
        const s = info.name || v;
        menuItems.push(
          <MenuItem key={v} value={v}>
            {s}
          </MenuItem>
        );
      });
    } else if (Array.isArray(values)) {
      values.forEach(info => {
        const v = info.value;
        const s = info.name || v;
        menuItems.push(
          <MenuItem key={v} value={v}>
            {s}
          </MenuItem>
        );
      });
    }

    const style: CSSProperties = {
      display: this.state.hidden ? 'none': 'block'
    }
    if (Context.getInstance().uiMode.isRtl) {
      style.marginLeft = '0.75rem';
    } else {
      style.marginRight = '0.75rem';
    }
    
    let disableControl = false, toolTip;
    if(!fixedOptions && (!values || values.length === 0)) {
      const i18n = Context.instance.i18n;
      disableControl = true;
      toolTip = i18n.filter.noSelectableValues;
    }

    return (
      <div style={style}>
        {filterType === "numeric" && <NumericFilter pair={this.props.pair} onChange={this.onChange} clear={this.clear}/>} 
        {filterType === "date" && <DateFilter pair={this.props.pair} onChange={this.onChange} clear={this.clear}/>}
        {filterType === "text" && <TextFilter toolTip={toolTip} disableControl ={disableControl} 
          items={values} pair={this.props.pair} onChange={this.onChange} />}
        {fixedOptions && <TextFilter toolTip={toolTip} disableControl={false} items={fixedOptions.map(o =>
          ({ name: o.name, value: o.value }))} pair={this.props.pair} onChange={this.onChange}/>}
      </div>
    );
  }
}
