import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

/*
  transactions

    moveOccupantWithinUnit

*/

export function prepare(task) {
  const promise = new Promise((resolve,reject) => {
    const updates = [], undos = [];
    const layer = sourceUtil.getPeopleLayer();
    const keyField = layer.globalIdField;

    if (task.moveOccupantWithinUnit) {
      const feature = task.personFeatureItem.feature;
      let geom = task.occupantGeometry;
      let undoGeom;
      if (feature.geometry) {
        if (typeof feature.geometry.clone === "function") {
          undoGeom = feature.geometry.clone().toJSON();
        } else {
          undoGeom = JSON.parse(JSON.stringify(feature.geometry));
        }
      } else {
        undoGeom = transactionUtil.makeEmptyPoint(task);
      }
      const update = {attributes: {}};
      update.attributes[keyField] = feature.attributes[keyField];
      update.geometry = geom;
      updates.push(update);
      const undo = {attributes: {}};
      undo.attributes[keyField] = feature.attributes[keyField];
      undo.geometry = undoGeom;
      undos.push(undo);
    }

    const transactionInfo = transaction.makeTransactionInfo();
    if (updates.length > 0) {
      transactionInfo.peopleEdits = {updates: updates};
      transactionInfo.undo.peopleEdits = {updates: undos};
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undos
      });
    }

    console.log("transactionInfo",transactionInfo)

    resolve(transactionInfo);
  });
  return promise;
}
