import styled, { css } from "styled-components";

const Container = styled.div`
  position: relative;
  z-index: ${props => props.theme.levels.top};
  min-width: ${props => (props.active ? props.theme.sidePanelWidth : "0")};
  height: 100%;
  box-shadow: ${props => props.theme.boxShadow};
  
  ${props =>
    props.fullWidth &&
    css`
        width: 22vw;
    `};
`;

export { Container };
