import React from "react";
import Checkbox from "calcite-react/Checkbox";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Topic from "../../../context/Topic";
import * as component from "../../../../src/components/util/component";
import * as aiimUtil from "../../../../src/aiim/util/aiimUtil";

const CSS = {
    section: "i-form-group i-section",
    formGroup: "i-form-group",
    formGroupHeader: "i-form-group-header",
    formGroupName: "i-form-group-name",
    required: "i--required",
    issues: "i-issues",
    issue: "i-issue",
    actions: "i-actions i-text-right",
    primaryAction: "i-button",
    secondaryAction: "i-button-clear-no-border i-font-brand-primary",
    config: "i-filter-config"
};

export default class FilterColumns extends React.Component {

    constructor(props) {
        super(props);
        const filterSection = Context.instance.config.spaceplanner.filterColumns;
        const allOccupantsSection = filterSection.allOccupantsInfo;
        const unassignedOccupantsSection = filterSection.unassignedOccupantsInfo;
        const allUnitsSection = filterSection.allUnitsInfo;
        const unassignedUnitsSection = filterSection.unassignedUnitsInfo;

        this.state= {
            unitsFields : [],
            occupantsFields : [],
            allOccupantsInfo : allOccupantsSection || {},
            unassignedOccupantsInfo : unassignedOccupantsSection || {},
            allUnitsInfo : allUnitsSection || {},
            unassignedUnitsInfo : unassignedUnitsSection || {},
            filterSection : filterSection
        }
    }

    componentDidMount() {
        this.handleWebmapChange();
        if(Object.keys(this.state.allOccupantsInfo).length === 0 || Object.keys(this.state.unassignedOccupantsInfo).length === 0
            || Object.keys(this.state.allUnitsInfo).length === 0 || Object.keys(this.state.unassignedUnitsInfo).length === 0) {
                this.init_filter();
            }   
        component.own(this,[
            Topic.subscribe(Topic.ViewsReloaded,params => {
                this.handleWebmapChange();
                this.init_filter();
            })
          ]);
    }

    componentWillUnmount() {
        component.componentWillUnmount(this);
    }

    init_filter=()=> {
        const project = Context.instance.spaceplanner.planner.project;
        const filterSection = Context.instance.config.spaceplanner.filterColumns;

        let unassignedUnitsObj = filterSection.unassignedUnitsInfo || {}, allUnitsObj = filterSection.allUnitsInfo || {}, 
        unassigedPeopleObj = filterSection.unassignedOccupantsInfo || {}, allPeopleObj = filterSection.allOccupantsInfo || {};
        //let unassignedUnitsObj = {}, allUnitsObj = {}, unassigedPeopleObj = {}, allPeopleObj = {};
        let fields;
        if(project && project.hostedInfo && project.hostedInfo.peopleLayer 
            && project.hostedInfo.peopleLayer.fields) fields = project.hostedInfo.peopleLayer.fields;

        if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer &&
            project.versionedInfo.peopleLayer.fields) fields = project.versionedInfo.peopleLayer.fields;

            let unitFields;
            if(project && project.hostedInfo && project.hostedInfo.unitsLayer 
                && project.hostedInfo.unitsLayer.fields) unitFields = project.hostedInfo.unitsLayer.fields;
    
            if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.unitsLayer &&
                project.versionedInfo.unitsLayer.fields) unitFields = project.versionedInfo.unitsLayer.fields;

        if(fields) {
            const peopleFields = fields;
            let levelId;
            if(project.isVersioned) levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.peopleLayer);
            else levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.peopleLayer);

            const facilityName = this.getFacilityNamePeople();
            const siteName = this.getSiteNamePeople();
            const levelName = this.getLevelNamePeople();
            const org1 = this.getPeopleOrgLevel1();
            const org2 = this.getPeopleOrgLevel2();
            const jobTitle = this.getPeopleJobTitle();

            let derived = false;

            if(Object.keys(this.state.allOccupantsInfo).length === 0) { 
                if(levelId && !facilityName) {
                    allPeopleObj["__site__"] = true;
                    allPeopleObj["__facility__"] = true;
                    allPeopleObj["__level__"] = true;
                    derived = true;
                }
                for(let i=0;i<peopleFields.length; i++) {
                    if(!derived) {
                        if(siteName && (peopleFields[i].name === siteName)) {
                            allPeopleObj[siteName] = true;
                        }
                        if(facilityName && (peopleFields[i].name === facilityName)) {
                            allPeopleObj[facilityName] = true;
                        }
                        if(levelName && (peopleFields[i].name === levelName)){
                            allPeopleObj[levelName] = true;
                        }
                    }
                    if(peopleFields[i].name === org1) {
                        allPeopleObj[org1] = true;
                    }
                    if(peopleFields[i].name === org2) {
                        allPeopleObj[org2] = true;
                    }
                    if(peopleFields[i].name === jobTitle){
                        allPeopleObj[jobTitle] = true;
                    }
                }
            }
            if(Object.keys(this.state.unassignedOccupantsInfo).length === 0) {
                if(levelId && !facilityName) {
                    unassigedPeopleObj["__site__"] = true;
                    derived = true;
                }
                for(let i=0;i<peopleFields.length; i++) {
                    if(!derived) {
                        if(siteName && (peopleFields[i].name === siteName)) {
                            unassigedPeopleObj[siteName] = true;
                        }
                    }
                    if(peopleFields[i].name === org1) {
                        unassigedPeopleObj[org1] = true;
                    }
                    if(peopleFields[i].name === org2) {
                        unassigedPeopleObj[org2] = true;
                    }
                    if(peopleFields[i].name === jobTitle){
                        unassigedPeopleObj[jobTitle] = true;
                    }
                }
            }

        }

        if(unitFields) {

            let levelId;
            if(project.isVersioned) levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.unitsLayer);
            else levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.unitsLayer);

            const facilityName = this.getFacilityName();
            const siteName = this.getSiteName();
            const levelName = this.getLevelName();
            let derived = false;


            if(Object.keys(this.state.allUnitsInfo).length === 0) { 
                if(levelId && !facilityName) {
                    allUnitsObj["__site__"] = true;
                    allUnitsObj["__facility__"] = true;
                    allUnitsObj["__level__"] = true;
                    derived = true;
                }
                for(let i=0;i<unitFields.length; i++) {
                    if(!derived) {
                        if(siteName && (unitFields[i].name === siteName)) {
                            allUnitsObj[siteName] = true;
                        }
                        if(facilityName && (unitFields[i].name === facilityName)) {
                            allUnitsObj[facilityName] = true;
                        }
                        if(levelName && (unitFields[i].name === levelName)){
                            allUnitsObj[levelName] = true;
                        }
                    }
                }
            }
            if(Object.keys(this.state.unassignedUnitsInfo).length === 0) {
                if(levelId && !facilityName) {
                    unassignedUnitsObj["__site__"] = true;
                    unassignedUnitsObj["__facility__"] = true;
                    unassignedUnitsObj["__level__"] = true;
                    derived = true;
                }
                for(let i=0;i<unitFields.length; i++) {
                    if(!derived) {
                        if(siteName && (unitFields[i].name === siteName)) {
                            unassignedUnitsObj[siteName] = true;
                        }
                        if(facilityName && (unitFields[i].name === facilityName)) {
                            unassignedUnitsObj[facilityName] = true;
                        }
                        if(levelName && (unitFields[i].name === levelName)){
                            unassignedUnitsObj[levelName] = true;
                        }
                    }
                }
            }

        }

        this.setState({
            unassignedUnitsInfo : unassignedUnitsObj,
            unassignedOccupantsInfo : unassigedPeopleObj,
            allOccupantsInfo : allPeopleObj,
            allUnitsInfo : allUnitsObj
        })
    }

    handleWebmapChange=()=>{
        const project = Context.instance.spaceplanner.planner.project;
        const webmap = Context.instance.config.webmap;
        if(webmap) {
            if(project && project.hostedInfo && project.hostedInfo.peopleLayer && 
                project.hostedInfo.peopleLayer.fields) {
                    this.setState({
                        occupantsFields : project.hostedInfo.peopleLayer.fields
                    })
            }
            if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer &&
                project.versionedInfo.peopleLayer.fields) {
                    this.setState({
                        occupantsFields : project.versionedInfo.peopleLayer.fields
                    })
            }
            if(project && project.hostedInfo && project.hostedInfo.unitsLayer && 
                project.hostedInfo.unitsLayer.fields) {
                    this.setState({
                        unitsFields : project.hostedInfo.unitsLayer.fields
                    })
            }
            if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.unitsLayer &&
                project.versionedInfo.unitsLayer.fields) {
                    this.setState({
                        unitsFields : project.versionedInfo.unitsLayer.fields
                    })
                }
        }
    }

    getUnitsFields=()=>{
        const project = Context.instance.spaceplanner.planner.project;
        if(project && project.hostedInfo && project.hostedInfo.unitsLayer && project.hostedInfo.unitsLayer.fields) 
            return project.hostedInfo.unitsLayer.fields;
        if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.unitsLayer &&
            project.versionedInfo.unitsLayer.fields)    
            return project.versionedInfo.unitsLayer.fields;
    }

    getOccupantsFields=()=>{
        const project = Context.instance.spaceplanner.planner.project;
        if(project && project.hostedInfo && project.hostedInfo.peopleLayer && project.hostedInfo.peopleLayer.fields) 
            return project.hostedInfo.peopleLayer.fields;
        if (project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer &&
            project.versionedInfo.peopleLayer.fields) return project.versionedInfo.peopleLayer.fields;
    }

    renderOccupantsColumns() {
        let list = [];
        let facilityName = this.getFacilityNamePeople();
        const i18n = Context.instance.i18n;
        const project = Context.instance.spaceplanner.planner.project;
        let levelId, editTrackingFields;
        if(project && project.hostedInfo && project.hostedInfo.peopleLayer && project.hostedInfo.peopleLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.peopleLayer);
            editTrackingFields = this.getEditTrackingFields(project.hostedInfo.peopleLayer);
        }
        if(project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer && 
            project.versionedInfo.peopleLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.peopleLayer);
            editTrackingFields = this.getEditTrackingFields(project.versionedInfo.peopleLayer);
        }
        if(!this.state.occupantsFields || this.state.occupantsFields.length === 0) return;

        if(levelId && !facilityName) {
            list.push(
                <Checkbox 
                    key ="__site__"
                    name={i18n.filter.fields.site}
                    checked = {!!this.state.allOccupantsInfo["__site__"]}
                    onChange={(event) => {
                        let temp = this.state.allOccupantsInfo;
                        temp["__site__"] = event.target.checked;
                        this.setState({
                            allOccupantsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allOccupantsInfo = this.state.allOccupantsInfo;
                        })
                    }}>
                    {i18n.filter.fields.site}
                </Checkbox>
            )
            list.push(
                <Checkbox 
                    key="__facility__"
                    name={i18n.filter.fields.facility}
                    checked = {!!this.state.allOccupantsInfo["__facility__"]}
                    onChange={(event) => {
                        let temp = this.state.allOccupantsInfo;
                        temp["__facility__"] = event.target.checked;
                        this.setState({
                            allOccupantsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allOccupantsInfo = this.state.allOccupantsInfo;
                        })
                    }}>
                    {i18n.filter.fields.facility}
                </Checkbox>
            )
            list.push(
                <Checkbox 
                    key = "__level__"
                    name={i18n.filter.fields.level}
                    checked = {!!this.state.allOccupantsInfo["__level__"]}
                    onChange={(event) => {
                        let temp = this.state.allOccupantsInfo;
                        temp["__level__"] = event.target.checked;
                        this.setState({
                            allOccupantsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allOccupantsInfo = this.state.allOccupantsInfo;
                        })
                    }}>
                    {i18n.filter.fields.level}
                </Checkbox>
            )
        }

        const sortedFields = this.sortFields(this.state.occupantsFields);
        for(let i=0;i<sortedFields.length;i++) {
            const name = sortedFields[i].name;
            if(editTrackingFields && editTrackingFields.length > 1 && editTrackingFields.includes(name)) {}
            else {
                list.push(
                    <Checkbox 
                        key= {sortedFields[i].name}
                        name= {sortedFields[i].name}
                        //default = {true}
                        checked = {!!this.state.allOccupantsInfo[sortedFields[i].name]}
                        onChange={(event) => {
                            let temp = this.state.allOccupantsInfo;
                            temp[event.target.name] = event.target.checked;
                            this.setState({
                                allOccupantsInfo : temp
                            }, ()=> {
                                this.props.cfg.getConfigurable("spaceplanner").filterColumns.allOccupantsInfo = this.state.allOccupantsInfo;
                            })
                        }}>
                            {sortedFields[i].alias || sortedFields[i].name}
                    </Checkbox>
                )
            }
        } 
        return list;
    }

    renderUnassignedOccupantsColumns() {
        let list = [];
        let facilityName = this.getFacilityNamePeople();
        const i18n = Context.instance.i18n;
        const project = Context.instance.spaceplanner.planner.project;
        let levelId, editTrackingFields;
        if(project && project.hostedInfo && project.hostedInfo.peopleLayer && 
            project.hostedInfo.peopleLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.peopleLayer);
            editTrackingFields = this.getEditTrackingFields(project.hostedInfo.peopleLayer);
        }
        if(project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer && 
            project.versionedInfo.peopleLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.peopleLayer);
            editTrackingFields = this.getEditTrackingFields(project.versionedInfo.peopleLayer);
        }
        if(!this.state.occupantsFields || this.state.occupantsFields.length === 0) return;

        if(levelId && !facilityName) {
            list.push(
                <Checkbox 
                    name={i18n.filter.fields.site}
                    key = "__site__"
                    checked = {!!this.state.unassignedOccupantsInfo["__site__"]}
                    onChange={(event) => {
                        let temp = this.state.unassignedOccupantsInfo;
                        temp["__site__"] = event.target.checked;
                        this.setState({
                            unassignedOccupantsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedOccupantsInfo = this.state.unassignedOccupantsInfo;
                        })
                    }}>
                    {i18n.filter.fields.site}
                </Checkbox>
            )
        }
        const sortedFields = this.sortFields(this.state.occupantsFields);
        for(let i=0;i<sortedFields.length;i++) {
            const name = sortedFields[i].name;
            if(editTrackingFields && editTrackingFields.length > 1 && editTrackingFields.includes(name)) {}
            else {
                list.push(
                    <Checkbox 
                        key = {sortedFields[i].name}
                        name={sortedFields[i].name}
                        checked = {!!this.state.unassignedOccupantsInfo[sortedFields[i].name]}
                        onChange={(event) => {
                            let temp = this.state.unassignedOccupantsInfo;
                            temp[event.target.name] = event.target.checked;
                            this.setState({
                                unassignedOccupantsInfo : temp
                            }, ()=> {
                                this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedOccupantsInfo = this.state.unassignedOccupantsInfo;
                            })
                        }}>
                        {sortedFields[i].alias || sortedFields[i].name}
                    </Checkbox>
                )
            }
        }
        return list;
    }

    getLevelFieldName=()=>{
        const project = Context.instance.spaceplanner.planner.project;
        const fields = this.getUnitsFields();
        let layer, levelName;

        if(project && project.hostedInfo && project.hostedInfo.unitsLayer) layer = project.hostedInfo.unitsLayer;
        if(project && project.isVersioned && project.versionedInfo && project.versionedInfo.peopleLayer) 
            layer = project.versionedInfo.unitsLayer;

        if(layer && layer.floorInfo && layer.floorInfo.floorField) {
            levelName = layer.floorInfo.floorField;
        }else {
            levelName = aiimUtil.findField(fields, FieldNames.LEVEL_ID)
        }
        return levelName;
    }

    getPeopleOrgLevel1=()=>{
        const fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.PEOPLE_ORG_LEVEL_1);
        if(field) return field.name;
    }

    getPeopleOrgLevel2=()=>{
        const fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.PEOPLE_ORG_LEVEL_2);
        if(field) return field.name;
    }

    getPeopleJobTitle=()=>{
        const fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.PEOPLE_JOB_TITLE);
        if(field) return field.name;
    }

    getFacilityName=()=>{
        let fields = this.getUnitsFields();
        let field = aiimUtil.findField(fields, FieldNames.FACILITY_NAME);
        if(field) return field.name;
    }

    getSiteName=()=>{
        let fields = this.getUnitsFields();
        let field = aiimUtil.findField(fields, FieldNames.SITE_NAME);
        if(field) return field.name;
    }

    getLevelName=()=> {
        let fields = this.getUnitsFields();
        let field = aiimUtil.findField(fields, FieldNames.LEVEL_NAME);
        if(field) return field.name;
    }

    getLevelNamePeople=()=>{
        let fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.LEVEL_NAME);
        if(field) return field.name;
    }

    getSiteNamePeople=()=>{
        let fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.SITE_NAME);
        if(field) return field.name;
    }

    getFacilityNamePeople=()=>{
        let fields = this.getOccupantsFields();
        let field = aiimUtil.findField(fields, FieldNames.FACILITY_NAME);
        if(field) return field.name;
    }

    getShapeAreaName=()=>{
        let fields = this.getUnitsFields();
        let field = aiimUtil.findField(fields, FieldNames.SHAPE_AREA);
        if(field) return field.name;
    }

    getShapeLengthName=()=>{
        let fields = this.getUnitsFields();
        let field = aiimUtil.findField(fields, FieldNames.SHAPE_LENGTH);
        if(field) return field.name;
    }

    getEditTrackingFields =(layer)=> {
        if(!layer) return;
        const editFieldsInfo = layer && layer.editFieldsInfo;
        if(editFieldsInfo) return Object.values(editFieldsInfo);
    }

    renderUnitColumns=()=> {
        let list = [];
        const i18n = Context.instance.i18n;
        let facilityName = this.getFacilityName();
        const project = Context.instance.spaceplanner.planner.project;
        let levelId, editTrackingFields;
        if(project && project.hostedInfo && project.hostedInfo.unitsLayer && project.hostedInfo.unitsLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.unitsLayer);
            editTrackingFields = this.getEditTrackingFields(project.hostedInfo.unitsLayer);
        }
        if(project && project.isVersioned && project.versionedInfo && project.versionedInfo.unitsLayer && 
            project.versionedInfo.unitsLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.unitsLayer);
            editTrackingFields = this.getEditTrackingFields(project.versionedInfo.unitsLayer);
        }
        let shapeAreaFieldName = this.getShapeAreaName();
        let shapeLenFieldName = this.getShapeLengthName();

        if(!this.state.unitsFields || this.state.unitsFields.length === 0) return;
        if(levelId && !facilityName) {
            list.push(
                <Checkbox 
                    key = "__site__"
                    name={i18n.filter.fields.site}
                    checked = {!!this.state.allUnitsInfo["__site__"]}
                    onChange={(event) => {
                        let temp = this.state.allUnitsInfo;
                        temp["__site__"] = event.target.checked;
                        this.setState({
                            allUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allUnitsInfo = this.state.allUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.site}
                </Checkbox>
            )
            list.push(
                <Checkbox 
                    name={i18n.filter.fields.facility}
                    key = "__facility__"
                    checked = {!!this.state.allUnitsInfo["__facility__"]}
                    onChange={(event) => {
                        let temp = this.state.allUnitsInfo;
                        temp["__facility__"] = event.target.checked;
                        this.setState({
                            allUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allUnitsInfo = this.state.allUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.facility}
                </Checkbox>
            )
            list.push(
                <Checkbox 
                    name={i18n.filter.fields.level}
                    key = "__level__"
                    checked = {!!this.state.allUnitsInfo["__level__"]}
                    onChange={(event) => {
                        let temp = this.state.allUnitsInfo;
                        temp["__level__"] = event.target.checked;
                        this.setState({
                            allUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.allUnitsInfo = this.state.allUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.level}
                </Checkbox>
            )
        }

        const sortedFields = this.sortFields(this.state.unitsFields);
        for(let i=0;i<sortedFields.length;i++) {
            const name = sortedFields[i].name;
            if(name === shapeAreaFieldName || name === shapeLenFieldName || 
                (editTrackingFields && editTrackingFields.length > 1 && 
                    editTrackingFields.includes(name))) {
                    }
            else {
                list.push(
                    <Checkbox 
                        key = {sortedFields[i].name}
                        name={sortedFields[i].name}
                        checked = {!!this.state.allUnitsInfo[sortedFields[i].name]}
                        onChange={(event) => {
                            let temp = this.state.allUnitsInfo;
                            temp[event.target.name] = event.target.checked;
                            this.setState({
                                allUnitsInfo : temp
                            }, ()=> {
                                this.props.cfg.getConfigurable("spaceplanner").filterColumns.allUnitsInfo = this.state.allUnitsInfo;
                            })
                        }}>
                        {sortedFields[i].alias || sortedFields[i].name}
                    </Checkbox>
                )
            }
        }
        return list;
    }

    renderUnassignedUnitColumns() {
        let list = [];
        const i18n = Context.getInstance().i18n;        
        let facilityName = this.getFacilityName();
        const project = Context.instance.spaceplanner.planner.project;
        let levelId, editTrackingFields;
        if(project && project.hostedInfo && project.hostedInfo.unitsLayer && project.hostedInfo.unitsLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.hostedInfo.unitsLayer);
            editTrackingFields = this.getEditTrackingFields(project.hostedInfo.unitsLayer);
        }
        if(project && project.isVersioned && project.versionedInfo && project.versionedInfo.unitsLayer && 
            project.versionedInfo.unitsLayer.fields) {
            levelId = Context.instance.aiim.getLevelIdField(project.versionedInfo.unitsLayer);
            editTrackingFields = this.getEditTrackingFields(project.versionedInfo.unitsLayer);
        }
        let shapeAreaFieldName = this.getShapeAreaName();
        let shapeLenFieldName = this.getShapeLengthName();

        if(!this.state.unitsFields || this.state.unitsFields.length === 0) return;

        if(levelId && !facilityName) {
            list.push(
                <Checkbox 
                    key = "__site__"
                    name={i18n.filter.fields.site}
                    checked = {!!this.state.unassignedUnitsInfo["__site__"]}       
                    onChange={(event) => {
                        let temp = this.state.unassignedUnitsInfo;
                        temp["__site__"] = event.target.checked;
                        this.setState({
                            unassignedUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedUnitsInfo = this.state.unassignedUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.site}
                </Checkbox>
            )

            list.push(
                <Checkbox 
                    key = "__facility__"
                    name={i18n.filter.fields.facility}
                    checked = {!!this.state.unassignedUnitsInfo["__facility__"]}
                    onChange={(event) => {
                        let temp = this.state.unassignedUnitsInfo;
                        temp["__facility__"] = event.target.checked;
                        this.setState({
                            unassignedUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedUnitsInfo = this.state.unassignedUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.facility}
                </Checkbox>
            )
            list.push(
                <Checkbox 
                    key = "__level__"
                    name= {i18n.filter.fields.level}
                    checked = {!!this.state.unassignedUnitsInfo["__level__"]}
                    onChange={(event) => {
                        let temp = this.state.unassignedUnitsInfo;
                        temp["__level__"] = event.target.checked;
                        this.setState({
                            unassignedUnitsInfo : temp
                        }, ()=> {
                            this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedUnitsInfo = this.state.unassignedUnitsInfo;
                        })
                    }}>
                    {i18n.filter.fields.level}
                </Checkbox>
            )
        }

        const sortedFields = this.sortFields(this.state.unitsFields);
        for(let i=0;i<sortedFields.length;i++) {
            const name = sortedFields[i].name;
            if((name === shapeAreaFieldName) || (name === shapeLenFieldName) ||
                (editTrackingFields && editTrackingFields.length > 1 && 
                    editTrackingFields.includes(name))) {}
            else {
                list.push(
                    <Checkbox 
                        key = {sortedFields[i].name}
                        name= {sortedFields[i].name}
                        checked = {!!this.state.unassignedUnitsInfo[sortedFields[i].name]}                    
                        onChange={(event) => {
                            let temp = this.state.unassignedUnitsInfo;
                            temp[event.target.name] = event.target.checked;
                            this.setState({
                                unassignedUnitsInfo : temp
                            }, ()=> {
                                this.props.cfg.getConfigurable("spaceplanner").filterColumns.unassignedUnitsInfo = this.state.unassignedUnitsInfo;
                            })
                        }}>
                            {sortedFields[i].alias || sortedFields[i].name}
                    </Checkbox>
                )
            }
        }
        return list;
    }

    render() {
        const unitFields = this.renderUnitColumns();
        const unassignedUnitsFields = this.renderUnassignedUnitColumns();
        const occupantFields = this.renderOccupantsColumns();
        const unassignedOccupantsFields = this.renderUnassignedOccupantsColumns();
        const i18n = Context.getInstance().i18n;

        return(
            <div>
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}>
                    {i18n.spaceplanner.configurator.filterColumns.allOccupants.caption}
                </span>
                </div>
                <div key="itemTitle">{i18n.spaceplanner.configurator.filterColumns.allOccupants.description}</div>
                <div className={CSS.config}>
                    {occupantFields}
                </div>
            </div>
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}>
                    {i18n.spaceplanner.configurator.filterColumns.unassignedOccupants.caption}
                </span>
                </div>
                <div key="itemTitle">{i18n.spaceplanner.configurator.filterColumns.unassignedOccupants.description}</div>
                <div className={CSS.config}>
                    {unassignedOccupantsFields}
                </div>
            </div>
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}>
                    {i18n.spaceplanner.configurator.filterColumns.allUnits.caption}
                </span>
                </div>
                <div key="itemTitle">{i18n.spaceplanner.configurator.filterColumns.allUnits.description}</div>
                <div className={CSS.config}>
                    {unitFields}
                </div>
            </div>
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}>
                    {i18n.spaceplanner.configurator.filterColumns.unassignedUnits.caption}
                </span>
                </div>
                <div key="itemTitle">{i18n.spaceplanner.configurator.filterColumns.unassignedUnits.description}</div>
                <div className={CSS.config}>
                    {unassignedUnitsFields}
                </div>
            </div>
            </div>
        )
    }

    sortFields = (fields) => {
        // Sort pairs alphabetically
        const alphabeticalFields = fields.sort((a, b) => {
            const aliasA = a.alias || a.name;
            const aliasB = b.alias || b.name;
            return (aliasA < aliasB) ? -1 : (aliasA > aliasB) ? 1 : 0;
        });
    
        return alphabeticalFields;
    }
}