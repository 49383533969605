import BaseClass from "../../util/BaseClass";
import ChangeReader from "../components/MoveDates/ChangeReader";
import Context from "../../context/Context";
import OfficePlan from "./OfficePlan";
import FieldNames from "../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import * as sourceUtil from "./sourceUtil";

export default class Reader extends BaseClass {

  readFeatures(options) {
    const promise = new Promise((resolve,reject) => {
      const task = {
        options: options,
        plan: OfficePlan.getActive(),
        chunks: [],
        features: [],
        noMatch: false
      };
      const peopleSource = sourceUtil.getPeopleSource();
      Promise.resolve().then(() => {
        const cr = new ChangeReader();
        return cr.readFeatures({});
      }).then(changes => {
        //console.log("Reader.changes",changes)
        let globalIds = changes && changes.people.globalIds;
        let featuresByGlobalId = changes && changes.people.featuresByGlobalId;
        if (globalIds) {
          globalIds.forEach(gid => {
            let f = featuresByGlobalId[gid];
            if (f) task.features.push(f);
          })
        }
      }).then(result => {
        return this._makePeopleCSV(task,peopleSource);
      }).then(result => {
        //console.log("Reader.readFeatures.task",task)
        resolve(task);
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise;
  }

  readFeaturesv0(options) {
    const promise = new Promise((resolve,reject) => {
      const task = {
        options: options,
        plan: OfficePlan.getActive(),
        chunks: [],
        features: [],
        noMatch: false
      };
      const peopleSource = sourceUtil.getPeopleSource();
      Promise.resolve().then(() => {
        return this._extractIds(task,peopleSource);
        // if (task.plan.isVersioned) {
        //   return this._extractIds(task,peopleSource);
        // } else {
        //   const where = this._makePeopleWhere(task,peopleSource);
        //   return this._queryIds(task,peopleSource,where);
        // }
      }).then(result => {
        this._makeChunks(task,peopleSource);
      }).then(result => {
        return this._processChunks(task);
      }).then(result => {
        return this._makePeopleCSV(task,peopleSource);
      }).then(result => {
        //console.log("readFeatures.task",task)
        resolve(task);
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise;
  }

  // options: {url,globalIdField,globalIdIndex,where}
  readLayerGlobalIdIndex(options) {
    const promise = new Promise((resolve,reject) => {
      const source = options.source || options;
      const task = {
        chunks: [],
        features: [],
        forGlobalIds: true,
        globalIdField: options.globalIdField
      };
      Promise.resolve().then(() => {
        return this._queryIds(task,source,options.where);
      }).then(() => {
        this._makeChunks(task,source);
      }).then(() => {
        return this._processChunks(task);
      }).then(() => {
        let gidField = options.globalIdField;
        let gidIndex = options.globalIdIndex;
        let toUpper = !!options.toUpper;
        task.features.forEach(f => {
          let gid = f.attributes[gidField];
          if (toUpper && typeof gid === "string") gid = gid.toUpperCase();
          gidIndex[gid] = 1;
        })
      }).then(() => {
        //console.log("readLayerGlobalIds.options",options)
        //console.log("readLayerGlobalIds.task",task)
        resolve(task);
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise;
  }

  _csvesc(v) {
    if (v === undefined) return "";
    if (v === null) return "";
    if (typeof v !== "string") return v;
    v = v.replace(/"/g,'""');
    if (v.search(/("|,|\n)/g) >= 0) v = "\""+v+"\"";
    return v;
  }

  _makeChunks(task,source,query) {
    let chunks = [];
    const objectIds = task.objectIds;
    if (Array.isArray(objectIds) && objectIds.length > 0) {
      let chunkLen = 1000;
      for (let i=0,j=objectIds.length; i<j; i+=chunkLen) {
        let a = objectIds.slice(i,i+chunkLen);
        if (a.length > 0) {
          chunks.push( {
            source: source,
            objectIds: a
          });
        }
      }
    }
    task.chunks = chunks;
  }

  _makeDataURL(task,csvText) {
    const promise = new Promise((resolve, reject) =>{
      task.csvInfo = {
        href: null
      }
      const BOM = "\uFEFF"; 
      csvText = BOM + csvText;
      if (csvText && FileReader && Blob) {
        const blob = new Blob([csvText], {type:"text/csv;charset=utf-8"});
        const reader = new FileReader();
        reader.onload = function(event) {
          task.csvInfo.href = event.target.result;
          resolve();
        };
        reader.onerror = function(err) {
          reject(err);
        }
        reader.readAsDataURL(blob);
      } else {
        resolve();
      }
    })
    return promise;
  }

  _makePeopleCSV(task,source) {
    const promise = new Promise((resolve,reject) => {
      const fields = source.layer2D.fields;
      const rows = [];
      let cells = [];
      fields.forEach(field => {
        let v = this._csvesc(field.name);
        cells.push(v);
      });
      cells.push("ASSIGNMENT_TYPE","ASSIGNMENT_NAME");
      rows.push(cells.join(","));

      let unitIdField = aiimUtil.findField(fields,FieldNames.PEOPLE_UNIT_ID);
      let unitNameField = aiimUtil.findField(fields,FieldNames.UNIT_NAME);
      let areaIdField = aiimUtil.findField(fields,FieldNames.PEOPLE_AREA_ID);
      if (!unitNameField) unitNameField = unitIdField;

      task.features.forEach((feature,i) => {
        let attributes = feature.attributes;
        cells = [];
        fields.forEach(field => {
          let v = this._csvesc(attributes[field.name]);
          cells.push(v);
        });
        let unitId = aiimUtil.getAttributeValue(attributes,unitIdField.name);
        let areaId = aiimUtil.getAttributeValue(attributes,areaIdField.name);
        let asnType = "";
        let asnName = "";
        let ok = true;
        if (unitId) {
          asnName = aiimUtil.getAttributeValue(attributes,unitNameField.name);
          asnType = OfficePlan.SpaceAssignmentTypes.office;
          ok = task.options.unitAssignments;
        } else if (areaId) {
          asnName = task.plan.areasTable.getAreaName(areaId);
          asnType = task.plan.areasTable.getAreaType(areaId);
          if (asnType === "hotdesk" || asnType === "hotel" || asnType === "workspace") {
            ok = task.options.workspaceAreaAssignments;
            asnType = "workspace";
          } else if (asnType === OfficePlan.SpaceAssignmentTypes.home) {
            ok = task.options.homeAssignments;
          } else {
            ok = task.options.noAssignment;
          }
        } else {
          asnType = OfficePlan.SpaceAssignmentTypes.none;
          ok = task.options.noAssignment;
        }
        cells.push(this._csvesc(asnType));
        cells.push(this._csvesc(asnName));
        if (ok) rows.push(cells.join(","));
      });

      if (rows.length === 1) {
        task.noMatch = true;
        resolve();
      } else {
        const csvText = rows.join("\n");
        this._makeDataURL(task,csvText).then(() => {
          resolve();
        }).catch(ex => {
          reject(ex);
        });
      }
    });
    return promise;
  }

  // _makePeopleWhere = (task,source) => {
  //   const layer = source.layer2D;
  //   const f1 = aiimUtil.findField(layer.fields,"INITIAL_UNIT_ID");
  //   const f2 = aiimUtil.findField(layer.fields,"UNIT_ID");
  //   const f3 = aiimUtil.findField(layer.fields,"INITIAL_AREA_ID");
  //   const f4 = aiimUtil.findField(layer.fields,"AREA_ID");
  //
  //   let where = "(" + f1.name +" IS NULL AND " + f2.name +" IS NOT NULL)";
  //   where += " OR (" + f1.name +" IS NOT NULL AND " + f2.name +" IS NULL)";
  //   where += " OR (" + f3.name +" IS NULL AND " + f4.name +" IS NOT NULL)";
  //   where += " OR (" + f3.name +" IS NOT NULL AND " + f4.name +" IS NULL)";
  //   where += " OR (" + f1.name + " <> "+ f2.name + ")";
  //   where += " OR (" + f3.name + " <> "+ f4.name + ")";
  //   return where;
  // }

  _processChunk(task,chunk) {
    const promise = new Promise((resolve,reject) => {
      const objectIds = chunk && chunk.objectIds;
      if (!objectIds || objectIds.length === 0) {
        resolve();
      } else {
        const lib = Context.instance.lib;
        const url = Context.checkMixedContent(chunk.source.url);
        const queryTask = new lib.esri.QueryTask({url: url});
        const query = new lib.esri.Query();
        const view = Context.instance.views.mapView;
        if (view) query.outSpatialReference = view.spatialReference;
        query.returnGeometry = false;
        query.returnZ = false;
        query.outFields = ["*"];
        query.objectIds = chunk.objectIds;
        queryUtil.applyGdbVersion(chunk.source,query)
        //console.log("pcq",query)
        if (task.forGlobalIds) {
          query.outFields = [task.globalIdField];
        }
        queryTask.execute(query).then(result => {
          //console.log("_processChunk.results",result && result.features && result.features.length)
          chunk.result = result;
          resolve();
        }).catch(ex => {
          reject(ex);
        });
      }
    });
    return promise
  }

  _processChunks(task) {
    const promise = new Promise((resolve,reject) => {

      const process = (chunkIdx) => {
        const isLast = (chunkIdx >= (task.chunks.length - 1));
        const chunk = task.chunks[chunkIdx];
        //console.log("chunkIdx",chunkIdx,"count",chunk && chunk.objectIds.length)
        this._processChunk(task,chunk).then(() => {
          if (isLast) {
            let features = []
            task.chunks.forEach(chunk => {
              if (chunk.result && chunk.result.features) {
                features = features.concat(chunk.result.features);
              }
            });
            task.features = features;
            resolve();
          } else {
            process(chunkIdx + 1)
          }
        }).catch(ex => {
          reject(ex);
        })
      }

      if (task.chunks && task.chunks.length > 0) {
        process(0);
      } else {
        resolve();
      }
    });
    return promise
  }

  _extractIds(task,source) {
    const promise = new Promise((resolve,reject) => {
      const layer = source.layer2D;
      const url = Context.checkMixedContent(source.layer2D.url+"/extractChanges");
      const layerId = layer.layerId;
      const layers = [layerId];
      const layerServerGens = [{ "id" : layerId, "serverGen" : 0}];

      const params = {
        f: "json",
        layers: JSON.stringify(layers),
        layerServerGens: JSON.stringify(layerServerGens),
        gdbVersion: layer.gdbVersion,
        returnIdsOnly: true,
        returnInserts: true,
        returnUpdates: true,
        returnDeletes: true,
        returnExtentOnly: false,
        returnAttachments: false,
        dataFormat: "json",
        transportType: "esriTransportTypeEmbedded",
      };
      const esriRequest = Context.instance.lib.esri.esriRequest;
      const options = {query: params, method: "post", responseType: "json"};
      esriRequest(url,options).then(result => {
        const edits = result && result.data && result.data.edits;
        if (Array.isArray(edits)) {
          edits.forEach(edit => {
            if (edit.id === layerId) {
              const adds = (edit.objectIds && edit.objectIds.adds) || [];
              const updates = (edit.objectIds && edit.objectIds.updates) || [];
              const deletes = (edit.objectIds && edit.objectIds.deletes) || [];
              const ids = updates.concat(adds).concat(deletes);
              task.objectIds = ids;
            }
          })
        }
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _queryIds(task,source,where) {
    const promise = new Promise((resolve,reject) => {
      where = (where || "1=1");
      let options = {
        source: source,
        where: where,
      };
      queryUtil.queryObjectIds(options).then(results => {
        //console.log("queryObjectIds",results)
        task.objectIds = results;
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise
  }

}
