import React from "react";
import ShareDropdown from "./ShareDropdown";
import Topic from "../../../context/Topic";
import * as shareUtil from "./shareUtil";

export default class ShareUrl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shareUrl: null
        }
    }

    componentDidMount() {
        Topic.subscribe(Topic.LayerVisibilityChanged, params => {
            this.generateShareUrl();
        })
        this.generateShareUrl();
    }

    generateShareUrl() {
        shareUtil.generateShareUrlAsync(this.props.item).then(url => {
            this.setState({
                shareUrl : url
            })
        })
    }

    render() {
        let emailSubject;
        if (this.props && this.props.type === "eventDetails") emailSubject = this.props && this.props.item && this.props.item.name;

        return (
            <div>
                <ShareDropdown emailSubject={emailSubject} shareUrl={this.state.shareUrl} caption={this.props.caption} />
            </div>
        )
    }
}
