import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import QRAnimation from "../../../assets/QRAnimation.mp4";
import QRAnimationImage from "../../../assets/QRAnimation_Image.png";
import * as component from "../../util/component";

const CSS = {
    panelHeader: "i-infopanel-header i-panel-header",
    panelHeaderTools: "i-panel-header-tools",
    expandButton: "i-button-expand i-icon-button",
    closeButton: "i-button-close i-icon-button",
    infoPanelOpen: "i-infopanel-open",
    popoutInner: "i-popout-inner",
    popoutOuter: "i-popout-outer",
    qr: "i-qr",
    videoContainer: "i-video-container",
    qrBox: "i-qr-box",
    qrContainer: "i-qr-container",
    qrInfo: "i-qr-info"
  };

export default class QRCodePopup extends Component {
  _mounted = false

  constructor(props) {
    super(props)
    this.contentHeader = React.createRef();
    this.closePoup = React.createRef();
    this.dialogId = component.nextId();
    this.closeId = component.nextId();
    this.shouldUseImage = this.isSafari();
  }

  isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  componentDidMount() {
    this._mounted = true
    if(this.contentHeader && this.contentHeader.current && this.contentHeader.current.focus){
      this.contentHeader.current.focus();
    }

    setTimeout(() => {
      const player = document.getElementById('qr-gif-video')
      if (player) {
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute("muted", "")
        player.autoplay = true
        const promise = player.play()

        if (promise.then) {
          promise
            .then(() => {})
            .catch(() => {
              player.style.display = "none"
              this.shouldUseImage = true;
            })
        } else {
          player.style.display = "none"
          this.shouldUseImage = true;
        }
      }
    }, 10)
  }

  componentWillUnmount() {
    this._mounted = false
  }

  onKeyDown=(event)=>{
    if(event.target.id === this.closeId || (event.shiftKey && this.contentHeader.current.focus)){
      if(this.contentHeader && this.contentHeader.current && this.contentHeader.current.focus){
        event.preventDefault();
        setTimeout(()=>{
          this.contentHeader.current.focus();
        },1);
      }
    } 
    return event.keyCode === 27 && this.props.closePopup();
  }

  checkForClosePopup=(event)=>{
    if(event.keyCode === 13) this.props.closePopup();
  }

  render() {
    const hashref = "#"
    const i18n = Context.instance.i18n;
    const video = this.shouldUseImage ? (
      <img src={QRAnimationImage} alt="QR Code" />
    ) : (
      <video id="qr-gif-video" autoPlay loop muted playsInline>
        <source src={QRAnimation} type="video/mp4"></source>
        {i18n.QRCode.videoError}
      </video>
    )

    return (
      <div className={CSS.popoutOuter} aria-labelledby={this.dialogId} onKeyDown={this.onKeyDown}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <h2 tabIndex="0" ref={this.contentHeader} role="dialog" id={this.dialogId}>{this.props.caption}</h2>
            <div className={CSS.panelHeaderTools}>
              <a className={CSS.expandButton} href={hashref} role="button"
                title={i18n.general.expand}>
              </a>
              <button className={CSS.closeButton} ref={this.closePoup} href={hashref} onKeyDown={this.checkForClosePopup}
              id={this.closeId} title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel} onClick={this.props.closePopup}>
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.qr}>
            <div className={CSS.qrInfo}>
            <h5>{i18n.QRCode.scanTip}</h5>
            <div className={CSS.videoContainer}>
              {video}
            </div>
            </div>
            <div className={CSS.qrBox}>
              <div className={CSS.qrContainer}>
                <QRCode value={this.props.shareUrl} size={this.props.QRSize}></QRCode>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}