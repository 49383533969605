import React from "react";

import Context from "../../../context/Context";
import SidebarHeader from "../Sidebar/SidebarHeader";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  main: "i-myplaces",
  section: "i-section",
  subcaption: "i-myplaces-subcaption",
  items: "i-feature-items",
  itemIcon: "i-feature-item-icon",
  itemText: "i-feature-item-text",
  itemTitle: "i-feature-item-title",
  itemSubTitle: "i-feature-item-subtitle",
  itemAction: "i-feature-item-action",
  icon: "icon",
  homeIconName: "home-16-f",
  favoriteIconName: "star-16-f",
  recentIconName: "pin-16-f",
  removeIconName: "x-16",
  scrollPlaces: "i-scroll-places"
};

export default class MyPlaces extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ReferenceLayerUpdated,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.HomeLocationSet,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    //console.log("MyPlaces::componentWillUnmount");
    component.componentWillUnmount(this);
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        <div className={CSS.main}>
          <div className={CSS.scrollPlaces}>
            {this.renderSaved()}
            {this.renderRecent()}
          </div>
        </div>
      </div>
    );
  }

  renderFavorites() {
    const list = Context.getInstance().session.referenceLayer.favorites;
    const iconName = CSS.favoriteIconName;
    const nodes = [];
    list.forEach((item,index) => {
      let key = "i-myplaces-favorite-"+index;
      nodes.push(this.renderItem("favorite",item,key,iconName));
    });
    if (nodes.length === 0) return null;
    return nodes;
  }

  renderHeader() {
    const i18n = Context.getInstance().i18n;
    return <SidebarHeader caption={i18n.yourPlaces.caption}
      closeAriaLabel={i18n.yourPlaces.closeAriaLabel}
      sidebarKey={this.props.sidebarKey}/>
  }

  renderHome() {
    const item = Context.getInstance().session.referenceLayer.homeLocation;
    if (!item.isValid()) return null;
    const key = "i-myplaces-item-home";
    const iconName = CSS.homeIconName;
    return this.renderItem("home",item,key,iconName);
  }

  renderItem(type,item,key,iconName) {
    key = key+"-"+item.sourceKey+"-"+item.uniqueId;
    return (
      <li key={key}>
        <PlaceItem item={item} type={type} iconName={iconName}/>
      </li>
    );
  }

  renderRecent() {
    const i18n = Context.getInstance().i18n;
    const list = Context.getInstance().session.referenceLayer.recent;
    const iconName = CSS.recentIconName;
    const nodes = [];
    list.forEach((item,index) => {
      let key = "i-myplaces-recent-"+index;
      nodes.push(this.renderItem("recent",item,key,iconName));
    });
    if (nodes.length === 0) return null;
    return (
      <div key="recent" className={CSS.section}>
        <div className={CSS.subcaption}>{i18n.yourPlaces.recent}</div>
          <ul className={CSS.items} aria-label={i18n.yourPlaces.recentAriaLabel}>
            {nodes}
          </ul>
      </div>
    );
  }

  renderSaved() {
    const i18n = Context.getInstance().i18n;
    return (
      <div key="saved" className={CSS.section}>
        <div className={CSS.subcaption}>{i18n.yourPlaces.saved}</div>
          <ul className={CSS.items} aria-label={i18n.yourPlaces.savedAriaLabel}>
            {this.renderHome()}
            {this.renderFavorites()}
          </ul>
      </div>
    );
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class PlaceItem extends React.Component {
  _componentId
  _itemRef

  constructor(props) {
    super(props);
    this.state = component.newState({})
    this.detailsClicked = this.detailsClicked.bind(this);
    this.removeClicked = this.removeClicked.bind(this);
    this._componentId = component.nextId()
    this._itemRef = React.createRef()
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus()
        }
      })
    ])
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  detailsClicked() {
    const item = this.props.item;
    const type = this.props.type;
    const trackRecent = (type !== "recent");
    const sourceKey = item.sourceKey;
    Topic.publish(Topic.ShowSearchResult,{
      sourceKey: item.sourceKey,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: trackRecent,
      componentId: this._componentId,
      showLocations: sourceKey === "People" && { person: item.searchResult}
    });
  }

  removeClicked(event) {
    event.stopPropagation();
    event.preventDefault();
    const item = this.props.item;
    const type = this.props.type;
    if (type === "favorite") {
      Context.instance.session.referenceLayer.removeFavorite(item);
      Topic.publish(Topic.ShowToast,{
        message: Context.instance.i18n.yourPlaces.removed,
        toastId: "myPlaces/ItemRemoved"
      });
    } else if (type === "home") {
      Context.instance.session.referenceLayer.removeHomeLocation();
      Topic.publish(Topic.ShowToast,{
        message: Context.instance.i18n.yourPlaces.removed,
        toastId: "myPlaces/ItemRemoved"
      });
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const item = this.props.item;
    const type = this.props.type;
    const hashref = "#";
    let title = item.getTitle();
    let subTitle = item.getSubTitle();
    let subTitleNode = null;
    if (type === "home") {
      title = i18n.yourPlaces.home;
      subTitle = item.getTitle();
    }
    if (subTitle) {
      subTitleNode = (<span key="subTitle" className={CSS.itemSubTitle}>{subTitle}</span>);
    }

    const icon = this.renderIcon(CSS.itemIcon,this.props.iconName);

    let action = null;
    if (type === "favorite") {
      action = this.renderRemove();
    } else if (type === "home") {
      const hl = Context.instance.session.referenceLayer.homeLocation;
      if (hl && !hl.fromOfficeLocation) {
        action = this.renderRemove();
      }
    }

    const node = (
      <button ref={this._itemRef} onClick={this.detailsClicked} className="i-myplaces-button">
        {icon}
        <span className={CSS.itemText}>
          <span key="title" className={CSS.itemTitle}>{title}</span>
          {subTitleNode}
        </span>
        {action}
      </button>
    );
    return node;
  }

  renderIcon(className,iconName) {
    const iconPath = "libs/calcite-ui-icons/icons/sprite-16.svg#"+iconName;
    return <svg className={className}><use href={iconPath}></use></svg>
  }

  renderRemove(item,type) {
    const i18n = Context.getInstance().i18n;
    const icon = this.renderIcon(CSS.icon,CSS.removeIconName);
    const hashref = "#";
    const node = (
      <a href={hashref} className={CSS.itemAction}
        onClick={this.removeClicked} title={i18n.yourPlaces.remove} role="button">
        {icon}
      </a>
    );
    return node;
  }

}
