import React from "react";
import Select from "calcite-react/Select";
import Context from "../../../context/Context";
import ClearIcon from "calcite-ui-icons-react/XCircleIcon";
import {ModalController} from "../../../../src/common/components/Modal/index";
import moment from "moment";
import FDPWrapper from "./FDPWrapper";

import {
    LabelGroup,
    ClearButton,
    SelectGroup,
    MenuItem
} from "../../styles/Filter/filterGroup";
import * as component from "../../../components/util/component";
import TextField from "calcite-react/TextField/TextField";

export default class DateFilter extends React.Component {

    constructor(props) {
        super(props);
        this.componentId = component.nextId();
        const now = new Date();
        let date = moment(now), startDate = moment(now), endDate = moment(now).add(1, 'days'), operator = "Any";
        const pair = this.props.pair;
        if(pair && pair.operator) operator = pair.operator;
        if(pair && pair.value) {
            if(Array.isArray(pair.value)) {
               startDate = moment(pair.value[0]);
               endDate = moment(pair.value[1]);
            }else {
                date = moment(pair.value);
            }
        }
        this.state={
            date: date,
            startDate: startDate,
            endDate: endDate,
            operator: operator
        }
    }

    onOperatorChange=(value)=>{
        this.setState({
            operator : value
        },()=> {
            if (value === 'Exact') {
                let date = this.state.date;
                if (typeof date !== 'number') {
                    if (date && typeof date.toDate === 'function') {
                        date = date.toDate().getTime();
                    }
                }
                this.props.onChange(date, this.state.operator);
            } else if (value === 'Range') {
                let startDate = this.state.startDate;
                let endDate = this.state.endDate;

                if ((typeof startDate !== 'number') && (typeof endDate !== 'number')) {
                    if (startDate && typeof startDate.toDate === 'function') {
                        startDate = startDate.toDate().getTime();
                    }
                    if (endDate && typeof endDate.toDate === 'function') {
                        endDate = endDate.toDate().getTime();
                    }
                }
                this.props.onChange([startDate, endDate], this.state.operator);
            }
        })
    }

    clear=()=>{
        this.setState({
            operator : "Any",
            date : moment(new Date()),
            startDate : moment(new Date()),
            endDate : moment(new Date()).add(1, 'days')
        })
        this.props.onChange(undefined, "Any");
        this.props.clear();
    }

    renderClearButton() {
        const i18n = Context.instance.i18n;
        if(this.state.operator === "Any") return;
        if(this.state.operator === "Exact" && !this.state.date) return;
        if(this.state.operator === "Range" && !(this.state.startDate && this.state.endDate)) return;
        return (
                <ClearButton
                    iconButton
                    icon={<ClearIcon size={16} />}
                    title={i18n.filter.clearPart}
                    onClick={this.clear}
                />
            );
    }

    renderMenuItems=()=>{
        let menuItems = [];
        const i18n = Context.instance.i18n;
        let anyStr = i18n.filter.dateLabels.any;
        let exactStr = i18n.filter.dateLabels.exact;
        let rangeStr = i18n.filter.dateLabels.range;
        menuItems.push(
            <MenuItem key={anyStr} value="Any">
                  {anyStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={exactStr} value="Exact">
                  {exactStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={rangeStr} value="Range">
                  {rangeStr}
            </MenuItem>
        )
        return menuItems;
    }

    displayDate = (date, startDate, endDate) => {
        this.setState({
            date : date,
            startDate: startDate,
            endDate : endDate
        })
    }
    
    showDataPicker=(value)=>{
        let content;
        content = (
        <FDPWrapper 
            operator = {this.state.operator} 
            onChange = {this.props.onChange}
            value = {value}
            onStartChange = {this.onStartChange}
            date={this.state.date}
            startDate = {this.state.startDate}
            endDate = {this.state.endDate}
            displayDate = {this.displayDate}/>)
        const controller = new ModalController();
        const props = {
            className: "i-disable-calendar-input",
            showOKCancel: true,
            hideCancel: true,
            closeOnOK: true,
            noPadding: true,
            overlayStyle: {width:"320px !important"}
        };
        controller.show(props, content);
    }

    getFormatDate=(date)=>{
        const djLocale = Context.getInstance().lib.dojo.locale;
        let dateTime = date.toDate().getTime();
        let dt = new Date(dateTime);
        return djLocale.format(dt,{selector:"date",formatLength:"short"});
    }

    render(){
        const i18n = Context.instance.i18n;
        const operator = this.state.operator;
        const closeButton = this.renderClearButton();
        let menuItems = this.renderMenuItems();
        let date, startDate, endDate;
        let startDtStr = i18n.more.bookWorkspace.startDate;
        startDtStr = startDtStr.replace(": {startDate}", '');
        let endDtStr = i18n.more.bookWorkspace.endDate;
        endDtStr = endDtStr.replace(": {endDate}", '');
        if(this.state.date) date = this.getFormatDate(this.state.date);
        if(this.state.startDate) startDate = this.getFormatDate(this.state.startDate);
        if(this.state.endDate) endDate = this.getFormatDate(this.state.endDate);

        const icon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16";
        const calendarIcon = (
            <div className="i-filter-icon-container">
                <svg className="i-filter-icon"><use href={icon}></use></svg>
            </div>)

        return (
            <div>
            <SelectGroup>
                <LabelGroup>
                    <label> {this.props.pair.label} </label>
                    {closeButton}
                </LabelGroup>
                <Select
                    appendToBody
                    style={{width: "20rem"}}
                    placeholder={i18n.filter.valuePrompt}
                    selectedValue={operator}
                    onChange={this.onOperatorChange}
                >
                {menuItems}
                </Select>
            </SelectGroup>
            {operator === "Exact" &&
            (<div className={"i-filter-group"}>
                <TextField 
                    style={{width: "6rem"}}
                    placeholder={i18n.spaceplanner.moveDates.columns.date}
                    value={date}
                    rightAdornment={calendarIcon}
                    onClick={()=> {this.showDataPicker()}}/> 
            </div>)}
            {operator === "Range" && 
            (<div className={"i-filter-group"}>
                 <div className={"i-filter-input"}>
                    <LabelGroup>
                        <label> {startDtStr} </label>
                    </LabelGroup>
                    <TextField 
                        style={{width: "6rem"}}
                        placeholder={startDtStr}
                        value={startDate}
                        rightAdornment={calendarIcon}
                        onClick={()=>{this.showDataPicker("start")}}/>
                </div>
                <div className={"i-filter-input"}>
                     <LabelGroup>
                        <label> {endDtStr} </label>
                    </LabelGroup>
                    <TextField 
                        style={{justifyContent: "flex-end", width: "6rem"}}
                        placeholder={endDtStr}
                        value={endDate}
                        rightAdornment={calendarIcon}
                        onClick={()=>{this.showDataPicker("end")}}/>
                </div>

                </div>)}
            </div>
        )
    }
}