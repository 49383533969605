import React from "react";
import {connect} from "react-redux";

import Context from "../../../../../context/Context";
import * as component from "../../../../util/component";
import Topic from "../../../../../context/Topic";
import Rdx from "../../../../../redux/Rdx";
import Icons from "../../../../util/Icons";
import DropdownButton from "../../../../common/Dropdown/DropdownButton";
import FilterOption from "./FilterOption";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";

const CSS = {
  main: "i-events-datefilter",
  optionsContainer: "i-events-options-container",
  options: "i-events-options",
};

class AreaDropdown extends React.Component {
  constructor(props) {
    super(props)
    const { initialFilter } = props;
    this.state = component.newState({
      selectedValues: initialFilter ? [] : this.props.rdxAreaFilters,
      anySelected: initialFilter ? true : this.props.rdxAreaFilters.length === 0
    })
  }

  componentDidMount() {
    const people = Context.instance.aiim.datasets.people
    const defaultArea = this.props.rdxDefaultArea
    if (!Context.instance.user.isAnonymous() && people && !defaultArea && !this.props.forMeetingRooms) {
      Promise.resolve().then(() => {
      }).then(() => {
        Context.instance.areas.then(areas => {
          let areaId;
          if (Context.instance.aiim.datasets.areas) {
            let ids = Context.instance.aiim.getAppUserAreaIDs(true);
            ids = Context.instance.aiim.datasets.areas.filterHotelIDs(ids,areas);
            if (ids && ids.length === 1) areaId = ids[0];
          }
          for (let i = 0; i < areas.length; i++) {
            const area = areas[i]
            const id = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_ID)
            if (id === areaId) {
              const name = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_NAME)
              if (name) {
                Rdx.setValue(this, Rdx.Keys.DEFAULT_AREA, name)
                this.applyChanges(false, name)
                break
              }
            }
          }
        }).catch(e => {
          console.warn("Error loading areas")
          console.error(e)
        })
      }).catch(e => {
        console.warn("Error finding logged in user")
        console.error(e)
      })
    }
    component.own(this, [
      Topic.subscribe(Topic.SpaceFiltersSubmit, params => {
        Rdx.setValue(this, Rdx.Keys.AREA_FILTERS, this.state.selectedValues)
      }),

      Topic.subscribe(Topic.BookFiltersCleared, params => {
        this.setState({
          selectedValues: [],
          anySelected: true
        })
        Rdx.setValue(this, Rdx.Keys.AREA_FILTERS, []);
      })
    ])
  }

  onClick = (option) => {
    this.applyChanges(option.props.checked, option.props.value);
  }

  applyChanges = (checked, value) => {
    if (checked) {
      let values = this.state.selectedValues
      const index = values.indexOf(value)
      if (index > -1) {
        values.splice(index, 1)
      }
      const anySelected = values.length === 0
      this.setState({
        selectedValues: values,
        anySelected: anySelected
      }, ()=> {Rdx.setValue(this, this.getRdxKey(), this.state.selectedValues)})
    } else {
      let values = this.state.selectedValues
      values.push(value)
      this.setState({
        selectedValues: values,
        anySelected: false
      }, ()=> {Rdx.setValue(this, this.getRdxKey(), this.state.selectedValues)})
    }
    setTimeout(()=> {
      Topic.publish(Topic.FilterAreaChange, {})
    }, 1);
  }

  getRdxKey() {
    return this.props.rdxKey || Rdx.Keys.AREA_FILTERS;
  }

  onAnyClicked = (option) => {
    if (option.props.checked) {
      if (this.state.selectedValues.length !== 0) {
        this.setState({
          anySelected: false
        }, ()=> {Rdx.setValue(this, this.getRdxKey(), this.state.selectedValues)})
      }
    } else {
      this.setState({
        selectedValues: [],
        anySelected: true
      }, ()=> {Rdx.setValue(this, this.getRdxKey(), this.state.selectedValues)})
    }
    setTimeout(()=> {
      Topic.publish(Topic.FilterAreaChange, {})
    }, 1);
  }

  render() {
    const options = this.props.areaFilters.map(item => {
      let checked = false
      if (this.state.selectedValues.includes(item)) checked = true
      return (
        <FilterOption key={item} label={item} value={item} checked={checked} onClick={this.onClick} />
      )
    })
    const i18n = Context.instance.i18n
    const any = i18n.more.bookWorkspace.any
    options.unshift(
      <FilterOption key={"any"} label={any} value={any} checked={this.state.anySelected} onClick={this.onAnyClicked} />
    )

    let icon = Icons.doubleVertical();

    let caption = ""
    for (var i = 0; i < this.state.selectedValues.length; i++) {
      if (i < this.state.selectedValues.length - 1) {
        caption += this.state.selectedValues[i] + ", "
      } else {
        caption += this.state.selectedValues[i]
      }
    }
    if (caption === "") {
      caption = any
    }
    if (caption.length > 26) {
      caption = caption.substring(0, 26)
      let len = caption.length
      if (caption[len - 1] === ' ' || caption[len - 1] === ',') {
        caption = caption.replace(/.$/, '')
      }
      caption += "..."

    }

    const buttonContent = (
      <div role="button" aria-label={i18n.more.bookWorkspace.areaListAriaLabel}>
        <span key="caption">{caption}</span>
        <span key="icon">{icon}</span>
      </div>
    );
    const dropdownContent = (
      <ul role="menu"
        >{options}</ul>
    );
    return (
      <div className={CSS.optionsContainer}>
        <div className={CSS.options}>
          <div className={CSS.main}>
            <DropdownButton
              closeOnSelect={false}
              focusFirst={true}
              buttonClassName={"i-choice-dd"}
              buttonContent={buttonContent}
              dropdownContent={dropdownContent}
              disabled={false}
              placement={"bottom-start"}>
            </DropdownButton>
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const rdxKey = ownProps.rdxKey || Rdx.Keys.AREA_FILTERS
  return {
    rdxAreaFilters: Rdx.getValue(state, rdxKey),
    rdxDefaultArea: Rdx.getValue(state, Rdx.Keys.DEFAULT_AREA)
  }
}

export default connect(mapStateToProps)(AreaDropdown);
