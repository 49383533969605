import BaseClass from "../util/BaseClass";
import ReferenceLayer from "../aiim/base/ReferenceLayer";
import UserSettings from "../aiim/base/UserSettings";
import type UnitNameCache from "../spaceplanner/base/UnitNameCache";
import {
  IOccupantsCriteria
} from "../spaceplanner/miniapps/common/types";
import type QueryCriteria from "../spaceplanner/base/QueryCriteria";
import { IDuration } from "../components/main/Events/dateUtil";
import { IMeetingRoomCriteria } from "../components/main/MeetingRooms/MeetingRoomsModel";
import { IWorkspaceAreaConfig } from "../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";

export default class Session extends BaseClass {

  appDataHadWorkspaceConfig: boolean;
  cfServiceDescription;
  cfgState = {};
  meetingRoomsCriteria?: IMeetingRoomCriteria;
  networkServiceInfo;
  noAccessToPlan: boolean;
  occupantsCriteria: IOccupantsCriteria;
  openPlanCriteria: {
    searchText: string,
    scope: string,
    sortField: string,
    sortDir: string,
    recentPlanIds: string[]
  };
  queryCriteriaByKey: {
    [prop: string]: QueryCriteria
  };
  referenceLayer: ReferenceLayer;
  selectedTravelModeID;
  sharedMeetingRoomWhen: IDuration;
  startInConfiguratorMode;
  startupToast;
  unitNameCache: UnitNameCache;
  userSettings: UserSettings;
  newHotelConfig: IWorkspaceAreaConfig;

  constructor(props?) {
    super(props);
    this.referenceLayer = new ReferenceLayer();
    this.userSettings = new UserSettings();
  }

  viewsReloading() {
    this.startupToast = null;
    this.referenceLayer = new ReferenceLayer();
  }

}
