import React, { MutableRefObject } from "react";
import Icons from "../../../../util/Icons";
import Context from "../../../../../context/Context";
import Button from "calcite-react/Button";
import { formatDate } from "./WorkspaceReservation/workspaceReservationUtil";
import { BookingType, makeEventInfo } from "../../../../../util/calendarUtil";
import CalendarSelector from "../../../../../util/CalendarSelector";
import { stringFormatter } from "../../../../../util/formatUtil";
import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import { nextTmpId } from "../../../../util/component";
import moment from "moment";
import { Event } from "@microsoft/microsoft-graph-types";

// Calcite
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import PinIcon from "calcite-ui-icons-react/PinIcon";
import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleIcon";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import { IBookingData, IBookingSystem, IBookWorkspaceResults, IDateOptions } from "./WorkspaceReservation/BookingSystem";
import { IApplyEditsResult } from "@esri/arcgis-rest-feature-layer";

const CSS = {
  popoutInner: "i-popout-inner i-booking-canceled-popup",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  filterForm: "i-filter-form",
  durationFilter: "i-duration-filter",
  icon: "i-more-menu-icon",
  button: "i-book-button",
  bookingConfirmedBody: "i-booking-confirmed-body",
  bookingConfirmedHeader: "i-booking-confirmed-header",
  bookingCancelledFooter: "i-booking-cancelled-footer",
  bookingDetailsTitle: "i-booking-details-title",
  bookingOthersInfo: "i-booking-others-info",
  bookingFailed: "i-booking-failed",
  itemTag: "i-item-tag",
  iconLabel: "i-icon-label",
  footer: "i-confirmed-footer"
};
interface IBookingCancelProps {
  bookingSystemType: IBookingSystem["type"],
  bookingType?: BookingType,
  closePopup: () => void,
  data?: IApplyEditsResult | IBookWorkspaceResults | IBookingData | Event,
  facilityName?: string,
  levelName?: string,
  params?: IDateOptions,
  message?: string,
  unitName?: string
}
interface IBookingCancelState {
  popoverOpen: boolean
}
export default class BookingCancel extends React.Component<IBookingCancelProps, IBookingCancelState> {
  bookingSystemType = this.props && this.props.bookingSystemType;
  closeRef: MutableRefObject<HTMLButtonElement>;

  constructor(props: IBookingCancelProps) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  _togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  renderCalendarButton() {
    if (this.bookingSystemType === "office365") {
      return null;
    }
    const { data, bookingType: type } = this.props;
    const reservation = data && "booking" in data && data.booking;
    const unit = data && "unit" in data && data.unit;
    // Use the first reservation in the list. Even if there is a booking for multiple
    // users from a reservation manager, the calendar item will contain the same info for all
    // and the reservation manager could share the downloaded ICS file to the person/people
    // const reservation = reservations && reservations.length > 0 && reservations[0];

    if (!reservation) {
      return null;
    }

    const { popoverOpen } = this.state;
    const i18n = Context.instance.i18n;
    const calendarButton = (
      <Button onClick={this._togglePopover}>
        {i18n.events.details.removeFromCalendar}
      </Button>
    );

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const operation = "removeBooking";
    const calendarParams = makeEventInfo(reservationsDataset, reservation, unitsDataset, unit, operation);

    return (
      <CalendarSelector
        onRequestClose={this._togglePopover}
        open={popoverOpen}
        targetEl={calendarButton}
        params={calendarParams}
        bookingType={type}
        reservation={reservation}
        removeBooking={true}
      />
    );
  }

  renderFooter() {
    if (this.bookingSystemType === "esri" && this.props.data != null) {
      return null;
    }
    const { i18n } = Context.getInstance();
    return (
      <div className={CSS.bookingCancelledFooter}>
        <Button style={{margin: "0.5rem"}} 
          onClick={this.props.closePopup} title={i18n.general.close}>
          {i18n.general.close}
        </Button>
      </div>
    );
  }
  renderPersonTag(feature, rejected) {
    const field = rejected ? FieldNames.PEOPLE_FULLNAME : FieldNames.RESERVED_FOR_FULL_NAME;
    const fullName = getAttributeValue(feature.attributes, field);
    return (
      <li key={`${fullName}-${nextTmpId()}`} className={CSS.itemTag}>
        {fullName}
      </li>
    );
  }

  getFirstReservation = () => {
    const { reservations } = this.props.data as IBookWorkspaceResults;
    return reservations && reservations.length > 0 && reservations[0];
  }

  // Style the modal for those who were found and confirmed, and for those not found
  // and not booked. If nobody on the list was booked, we also need to let the user know that.
  renderMultipleBookings() {
    const i18n = Context.instance.i18n;
    const { data, unitName, levelName, facilityName, params } = this.props;
    const reservations = data && "reservations" in data && data.reservations;
    const rejections = data && "rejections" in data && data.rejections;
    const hasReservations = reservations && reservations.length > 0;
    const hasRejections = rejections && rejections.length > 0;

    // Person info
    const personName = hasReservations && getAttributeValue(reservations[0].attributes, FieldNames.RESERVED_FOR_FULL_NAME);
    const errorPersonName = hasRejections && getAttributeValue(rejections[0].attributes, FieldNames.PEOPLE_FULLNAME);

    let title = i18n.more.bookWorkspace.bookingDetailsTitle;
    title = title.replace("{levelName}", levelName);
    title = title.replace("{buildingName}", facilityName);

    let requestHeader =
      this.bookingSystemType === "office365"
        ? i18n.more.bookWorkspace.bookingRequested
        : this.bookingSystemType === "esri"
          ? i18n.more.bookWorkspace.bookingConfirmed
          : null;

    if (!hasReservations && hasRejections) {
      requestHeader = i18n.more.bookWorkspace.bookingUnsuccessful;
    }

    const failedTemplate = i18n.more.bookWorkspace.bookingRequestFailedOthersAlt;

    // Level/Facility info
    const template = i18n.more.bookWorkspace.unitLevelDescription;
    const description = stringFormatter(template, {
      unit: unitName,
      level: levelName,
      facility: facilityName
    });

    // Format a date string from the start and end times
    const startTime = moment(params.checkIn);
    const endTime = moment(params.checkOut);
    const isAllDay = params.allDay;
    const formattedDate = formatDate(startTime, endTime, isAllDay);

    const nameInfo = personName && (
      <div className={CSS.iconLabel}>
        <UserIcon />
        <p>{personName}</p>
      </div>
    );


    const errorInfo = errorPersonName && (
      <div className={CSS.bookingFailed}>
        {stringFormatter(failedTemplate, { person: errorPersonName })}
      </div>
    );

    const requestInfo = (
      <div className={CSS.bookingOthersInfo}>
        <div className={CSS.iconLabel}>
          <ClockIcon />
          <p>{formattedDate}</p>
        </div>
        <div className={CSS.iconLabel}>
          <PinIcon />
          <p>{description}</p>
        </div>
        {nameInfo}
        {errorInfo}
      </div>
    );

    const footer = (
      <div className={CSS.footer}>
        {this.renderCalendarButton()}
      </div>
    );

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <div>
              <h2>{this.props.unitName}</h2>
              <h4>{title}</h4>
            </div>
            <div className={CSS.panelHeaderTools}>
              <button
                ref={this.closeRef}
                title={i18n.general.close}
                aria-label={i18n.infoPanel.closeAriaLabel}
                onClick={this.props.closePopup}
              >
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.bookingConfirmedHeader}>
            {hasReservations ? <CheckCircleIcon color="green" /> : <ExclamationMarkTriangleIcon color="#ecaf08" />}
            <h1>{requestHeader}</h1>
          </div>
          {requestInfo}
          {hasReservations && footer}
        </div>
      </div>
    );
  }

  render() {
    const i18n = Context.instance.i18n;
    const { data, unitName, message } = this.props;    
    const rejections = data && "rejections" in data && data.rejections;
    
    if (!!rejections) {
      return this.renderMultipleBookings();
    }
    
    const requestHeader = i18n.more.bookWorkspace.checkInOut.cancelBooking.confirmationTitle;
    let requestInfo = message || i18n.more.bookWorkspace.checkInOut.cancelBooking.confirmationSubtitle;
    if (!message) {
      requestInfo = requestInfo.replace("{unit}", unitName);
    }
    requestInfo = (<h2 style={{ marginBottom: "2rem" }}>{requestInfo}</h2>);

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <div className={CSS.bookingDetailsTitle}>
              <h2>{i18n.more.bookWorkspace.checkInOut.cancelBooking.header}</h2>
              {/* <h2>{i18n.more.bookWorkspace.checkInOut.cancelBooking.confirmationTitle}</h2> */}
            </div>
            <div className={CSS.panelHeaderTools}>
              <button ref={this.closeRef}
                title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
                tabIndex={0} onClick={this.props.closePopup}>
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.bookingConfirmedBody}>
            <CheckCircleIcon color="green" />
            <h1>{requestHeader}</h1>
            {requestInfo}
            {this.renderCalendarButton()}
          </div>
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}