import React from "react";
import moment from "moment";
import Context from "../../../context/Context";
import DateTime, { OnPartChanged } from "./DateTime";
import Duration from "./Duration";
import Tooltip from "calcite-react/Tooltip";
import * as component from "../../util/component";
import * as dateUtil from "../Events/dateUtil";
import { DayOfWeekShape, isSameDay } from "react-dates";
import BookingRecurrence, { getDefaultEndDate, supportsRecurrence } from "../More/Actions/BookWorkspace/BookingRecurrence";
import { getMeetingBookingSystemType } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import { IMeetingRoomCriteria } from "./MeetingRoomsModel";

interface IWhenProps {
  criteria: { when: dateUtil.IDuration },
  onMount: (when: When) => void,
  onUpdate?: (valid: boolean) => void
}
interface IWhenState {
  when: dateUtil.IDuration
}
export default class When extends React.Component<IWhenProps, IWhenState> {

  componentId: string;
  durationComponent: Duration;
  startComponent: DateTime;
  endComponent: DateTime;

  constructor(props: IWhenProps) {
    super(props);
    this.componentId = component.nextId("when-");
    const when: dateUtil.IDuration = {
      ...props.criteria.when,
      start: { ...props.criteria.when.start },
      end: { ...props.criteria.when.end },
      recurrence: { ...props.criteria.when.recurrence }
    }
    this.state = component.newState({ when });
  }

  override componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);
  }

  override componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  private _onUpdate() {
    if (this.props.onUpdate) {
      let newCriteria: Partial<IMeetingRoomCriteria> = {};
      let ok = this.validate(newCriteria);
      this.props.onUpdate(ok);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { duration, recurrence, start, end } = this.state.when;
    const type = getMeetingBookingSystemType();
    const sameDayBooking = isSameDay(moment(start.date), moment(end.date));

    const recurrenceUI = supportsRecurrence(type) &&
      <BookingRecurrence
        {...recurrence}
        startDate={end.date}
        disabled={!sameDayBooking}
        onUpdate={recurrence => {
          this.setState(prev => ({
            when: {
              ...prev.when,
              recurrence
            }
          }), this._onUpdate);
        }}/>
    const onPartChanged: OnPartChanged = (c, type, dateMoment, timeMoment, prev) => {
      let c2 = this.endComponent;
      if (c === this.endComponent) c2 = this.startComponent;
      const newParts = c2.whenAssociatedPartChanged(c, type, dateMoment, timeMoment, this.durationComponent, prev);
      const newStart = c === this.startComponent
        ? dateMoment.toDate()
        : newParts?.timeMoment ? newParts.timeMoment.toDate() : start.date;
      const newEnd = c === this.startComponent
        ? newParts?.timeMoment ? newParts.timeMoment.toDate() : end.date
        : dateMoment.toDate();
      // recurrence validation
      if (type === "date" || type === "datetime") {
        const tempDate = dateMoment.toDate();
        tempDate.setHours(0, 0, 0, 0);
        if (c === this.startComponent) { // start date changed
          const check = tempDate <= newEnd;
          this.setState(prev => {
            const recurrence = {
              ...prev.when.recurrence,
              days: [dateMoment.get("day") as DayOfWeekShape],
              endDate: getDefaultEndDate(prev.when.recurrence.type, check ? newEnd : dateMoment.toDate())
            };
            if (check && !isSameDay(dateMoment, moment(newEnd))) {
              recurrence.enabled = false;
              recurrence.endDate = getDefaultEndDate(prev.when.recurrence.type, end.date);
            }
            return {
              when: {
                ...prev.when,
                start: { date: newStart },
                end: { date: newEnd },
                recurrence
              }
            };
          }, this._onUpdate);
        } else { // end date changed
          const check = tempDate >= newStart;
          this.setState(prev => {
            const recurrence = {
              ...prev.when.recurrence,
              days: [dateMoment.get("day") as DayOfWeekShape],
              endDate: getDefaultEndDate(prev.when.recurrence.type, newEnd)
            };
            if (check && !isSameDay(moment(newStart), dateMoment)) {
              recurrence.enabled = false;
              recurrence.days = [moment(start.date).get("day") as DayOfWeekShape];
              recurrence.endDate = undefined;
            }
            return {
              when: {
                ...prev.when,
                start: { date: newStart },
                end: { date: newEnd },
                recurrence
              }
            };
          }, this._onUpdate);          
        }
      }
    };
    return (
      <div className="i-filter-form">
        <div className="i-when-filter-form">
          <div>
            <Duration
              formKey={this.componentId}
              duration={duration}
              onMount={c => this.durationComponent = c}/>
          </div>
          <div>
            <DateTime
              formKey={this.componentId}
              duration={duration}
              label={i18n.meetingRooms.start}
              isStart={true}
              date={start.date}
              onMount={c => this.startComponent = c}
              onPartChanged={onPartChanged} />
          </div>
          <div>
            <DateTime
              formKey={this.componentId}
              duration={duration}
              label={i18n.meetingRooms.end}
              isEnd={true}
              date={end.date}
              startDate={start.date}
              onMount={c => this.endComponent = c}
              onPartChanged={onPartChanged} />
          </div>
          {!sameDayBooking
              ? <Tooltip placement="top" positionFixed
                  title={i18n.calendars.recurringToggleDisableMeetingRoom}>{recurrenceUI}</Tooltip>
              : recurrenceUI
            }
        </div>
      </div>
    );
  }

  validate(criteria) {
    let ok = true;
    let when: dateUtil.IDuration = {
      duration: null,
      start: { date: null },
      end: { date: null },
      recurrence: null
    };
    if (ok) ok = this.durationComponent.validate(when);
    if (ok) ok = this.startComponent.validate(when.start);
    if (ok) ok = this.endComponent.validate(when.end);
    if (ok) {
      // recurrence
      when.recurrence = this.state.when.recurrence;
      if (when.recurrence.enabled === true) {
        if ((moment(when.recurrence.endDate).isSameOrBefore(when.start.date))
          || (when.recurrence.type === "weekly" && when.recurrence.days?.length === 0)) {
          ok = false;
        }
      }     
    }
    if (ok) {
      if (when.start.date && when.end.date &&
         (when.start.date <= when.end.date)) {
        // also check isRequired?
      }
    }
    if (ok && when.start.date && when.end.date &&
        when.duration !== "allDay") {
      let dt1 = new Date(when.start.date);
      let dt2 = new Date(when.end.date);
      let d = dateUtil.getDurationKey(dt1,dt2);
      when.duration = d;
    }
    if (ok) {
      criteria.when = when;
    }
    return ok;
  }

}
