import Dataset from "./Dataset";
import Context from "../../context/Context";
import FieldNames from "./FieldNames";
import * as aiimUtil from "../util/aiimUtil";
import { escSqlQuote } from "../util/selectionUtil";

export default class Areas extends Dataset {

  table: __esri.FeatureLayer;

  filterHotelIDs(areaIDs,areaFeatures) {
    let hotelIDs = [];
    if (areaIDs && (areaIDs.length > 0) && areaFeatures) {
      areaFeatures.forEach(f => {
        let type = aiimUtil.getAttributeValue(f.attributes,FieldNames.AREA_TYPE);
        if (type === "hotel" || type === "hotdesk" || type === "workspace") {
          let id = aiimUtil.getAttributeValue(f.attributes,FieldNames.AREA_ID);
          if (areaIDs.indexOf(id) !== -1) {
            hotelIDs.push(id);
          }
        }
      })
    }
    return hotelIDs;
  }

  queryAll(opts?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    return task.execute(query).then(result => {
      if (result && result.features && result.features.length > 0) {
        if (opts && opts.assignedToAppUser) {
          let considerUnrestricted = true;
          let ids = Context.instance.aiim.getAppUserAreaIDs(true);
          if (Context.instance.aiim.reserveForInfo && Context.instance.aiim.reserveForInfo.areaIDs) {
            considerUnrestricted = false;
          }

          let idField = FieldNames.AREA_ID;
          let typeField = FieldNames.AREA_TYPE;
          let restrictedField;
          if (result.fields) {
            let fld2 = aiimUtil.findField(result.fields,idField);
            if (fld2) idField = fld2.name;
            fld2 = aiimUtil.findField(result.fields,typeField);
            if (fld2) typeField = fld2.name;
            if (FieldNames.AREAS_RESTRICTED) {
              fld2 = aiimUtil.findField(result.fields,FieldNames.AREAS_RESTRICTED);
              if (fld2) restrictedField = fld2.name;
            }
          }

          result.features = result.features.filter(f => {
            let id = aiimUtil.getAttributeValue(f.attributes,idField);

            // hotels are unrestricted unless RESTRICTED = 1
            // let idOk = true
            // if (restrictedField) {
            //   let v = aiimUtil.getAttributeValue(f.attributes,restrictedField);
            //   console.log("v value************", v);
            //   let restricted = (v === 1); // (v === 0) is unrestricted
            //   if (restricted) {
            //     idOk = (ids.indexOf(id) !== -1);
            //   }
            // }

            // hotels are restricted unless RESTRICTED = 0
            let idOk = (ids.indexOf(id) !== -1);
            if (!idOk && restrictedField && considerUnrestricted) {
              let v = aiimUtil.getAttributeValue(f.attributes,restrictedField);
              let unrestricted = (v === 0); // use (v !== 1) ?
              if (unrestricted) idOk = true;
            }

            let typeOk = true;
            if (opts.hotelsOnly || opts.hotDesksOnly || opts.workspacesOnly) {
              typeOk = false;
              let v = aiimUtil.getAttributeValue(f.attributes,typeField);
              if (v === "hotel" || v === "hotdesk" || v === "workspace") {
                typeOk = true;
              }
            }

            return (idOk && typeOk);
          })

        }
      }
      return result;
    });
  }

  async queryAreaById(areaId) {
    if (!this.table) {
      return null;
    }

    const areaIdField = aiimUtil.findFieldName(this.table.fields, FieldNames.AREA_ID);
    if (!areaIdField) {
      return null;
    }

    const query = this.table.createQuery();
    query.outFields = ["*"];
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = `${areaIdField} = '${escSqlQuote(areaId)}'`;

    try {
      const result = await this.table.queryFeatures(query);
      return (result && result.features && result.features.length > 0) ? result.features[0] : null;
    } catch (e) {
      console.error("Couldn't query areas", e);
      return null;
    }
  }

  async queryUnrestrictedIDs() {
    const ids = [];
    const result = await this.queryAll();
    if (result && result.features && result.features.length > 0) {
      let idField = aiimUtil.findFieldName(result.fields,FieldNames.AREA_ID);
      let restrictedField = aiimUtil.findFieldName(result.fields,FieldNames.AREAS_RESTRICTED);
      if (idField && restrictedField) {
        result.features.forEach(f => {
          // hotels are restricted unless RESTRICTED = 0
          let id = aiimUtil.getAttributeValue(f.attributes,idField);
          let v = aiimUtil.getAttributeValue(f.attributes,restrictedField);
          let unrestricted = (v === 0);
          if (id && unrestricted) {
            ids.push(id)
          }
        });
      }
    }
    return ids;
  }

}
