import React, { Component } from "react";

// Calcite React
import SortAscendingIcon from "calcite-ui-icons-react/SortAscendingIcon";
import SortDescendingIcon from "calcite-ui-icons-react/SortDescendingIcon";

// Components
import Context from "../../../context/Context";
import DropdownButton from "./DropdownButton";
import Menu from "../../../common/components/Menu";
import QueryCriteria from "../../base/QueryCriteria";
import * as component from "../../../components/util/component";
import * as sortUtil from "./sortUtil";

// Styled-Components
import { ButtonGroup, Button } from "../../styles/Common/sort";
import MenuItem from "./MenuItem";

export default class Sort extends Component {
  constructor(props) {
    super(props);
    this.state = component.newState({
      sortables: null,
      sortBy: null,
      sortDir: null
    });
  }

  componentDidMount() {
    const queryCriteria = this.getQueryCriteria();
    const source = queryCriteria && queryCriteria.getSource();
    const sortOptions = (queryCriteria && queryCriteria.sortOptions) || {};
    const sortables = sortUtil.getSortables(source);
    this.setState({
      sortables: sortables,
      sortBy: sortOptions.sortBy,
      sortDir: sortOptions.sortDir
    });
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getQueryCriteria() {
    return QueryCriteria.get(this.props.queryCriteriaKey);
  }

  onChange = (event, menuItem, value) => {
    const sortable = menuItem.props.sortable;
    const queryCriteria = this.getQueryCriteria();
    let sortBy, sortDir, sortOptions;
    if (sortable) {
      sortBy = sortable.value;
      sortDir = sortable.defaultSortDir || "asc";

      sortOptions = {
        sortBy: sortBy,
        sortDir: sortDir
      };
    }
    this.setState({
      sortBy: sortBy,
      sortDir: sortDir
    });
    queryCriteria.onSortChanged(sortOptions);
  };

  toggleSort = () => {
    let { sortDir, sortBy } = this.state;
    const queryCriteria = this.getQueryCriteria();

    if (sortDir === "desc") {
      sortDir = "asc";
    } else {
      sortDir = "desc";
    }

    const sortOptions = {
      sortBy: sortBy,
      sortDir: sortDir
    };

    queryCriteria.onSortChanged(sortOptions);

    this.setState({
      sortDir: sortDir
    });
  };

  render() {
    const { sortables } = this.state;
    const i18n = Context.instance.i18n;
    let fieldPrompt = i18n.sort.fieldPrompt;
    let selectedText = i18n.sort.fieldPrompt;
    let selectedIcon = null;
    let buttonTitle = null;
    let sortBy = this.state.sortBy;
    let sortDir = this.state.sortDir;

    const menuItems = [];
    if (sortables && sortables.length > 0) {
      sortables.forEach(sortable => {
        let checked = false,
          icon = null;
        const v = sortable.value;
        const s = sortable.label || v;
        if (sortBy && sortBy === v) {
          selectedText = s;
          checked = true;
          if (sortDir === "desc") {
            buttonTitle = i18n.sort.ascSortTitle;
            icon = <SortDescendingIcon />;
            selectedIcon = <SortDescendingIcon />;
          } else {
            buttonTitle = i18n.sort.descSortTitle;
            icon = <SortAscendingIcon />;
            selectedIcon = <SortAscendingIcon />;
          }
        }
        menuItems.push(
          <MenuItem
            key={v}
            value={v}
            sortable={sortable}
            checked={checked}
            onClick={this.onChange}
          >
            {icon}
            {s}
          </MenuItem>
        );
      });
    }

    //let buttonContent = (<span>{selectedIcon}{selectedText}</span>);
    let buttonContent = selectedText;
    let dropdownContent = <Menu className="i-dd-menu">{menuItems}</Menu>;

    let dropdown = (
      <ButtonGroup>
        <Button
          title={buttonTitle}
          iconButton
          icon={selectedIcon}
          onClick={this.toggleSort}
        />

        <DropdownButton
          closeOnSelect={true}
          focusFirst={true}
          buttonContent={buttonContent}
          buttonTitle={fieldPrompt}
          dropdownContent={dropdownContent}
          placement={this.props.placement}
        ></DropdownButton>
      </ButtonGroup>
    );

    return dropdown;
  }
}
