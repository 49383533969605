import React from "react";
import { connect } from "react-redux";
import { getActiveTab, setActiveTab } from "./redux";

import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as mapUtil from "../../base/mapUtil";
import * as mapUtil2 from "../../../util/mapUtil";
import * as queryUitl from "../../base/queryUtil";
import * as sourceUtil from "../../base/sourceUtil";
import { ModalController } from "../../components/Modal";
import SidebarHeader from "./SidebarHeader";

import CheckExtentIcon from "calcite-ui-icons-react/CheckExtentIcon";
import ChevronsDownIcon from "calcite-ui-icons-react/ChevronsDownIcon";
import ChevronsUpIcon from "calcite-ui-icons-react/ChevronsUpIcon";
import CloseIcon from "calcite-ui-icons-react/XIcon";
import EraseIcon from "calcite-ui-icons-react/EraseIcon";
import FloorPlanIcon from "calcite-ui-icons-react/FloorPlanIcon";
import GridIcon from "calcite-ui-icons-react/GridIcon";
import LineIcon from "calcite-ui-icons-react/LineIcon";
import PointIcon from "calcite-ui-icons-react/PointIcon";
import PolygonIcon from "calcite-ui-icons-react/PolygonIcon";
import PolygonLineCheckIcon from "calcite-ui-icons-react/PolygonLineCheckIcon";
import QuestionIcon from "calcite-ui-icons-react/QuestionIcon";

import Button from "calcite-react/Button";
import Loader from "calcite-react/Loader";
import Progress from 'calcite-react/Progress';

import Icons from "../../../components/util/Icons";
import moment from "moment";

import { Space, Table, Tag } from 'antd';


const factoryStringSortFunc = function(fldNm) {
  return function(a,b){ return a[fldNm] && b[fldNm] ? a[fldNm].localeCompare(b[fldNm]) : -1 }
}
const factoryExactFilterFunc = function(fldNm) {
  return function(value, record){ return record && (record[fldNm]===value || String(record[fldNm])===String(value)) };
}
const factoryContainsFilterFunc = function(fldNm) {
  return function(value, record){ return value && record[fldNm] && record[fldNm].toLowerCase().includes(value.toLowerCase()) };
}
const filterSearchFunc = function(input, filter) {
  if (!input || !filter || typeof filter.text!=='string' || typeof filter.value!=='string')
    return false;
  input = input.toLowerCase();
  return filter.text.toLowerCase().includes(input) || filter.value.toLowerCase().includes(input);
};

function debounce(cb, wait){
  //		Wraps a function around 'cb' that will only execute after 'wait' ms of repeated execution. 
  //    Useful for delaying some event action slightly to allow for rapidly-firing events 
  //    such as window.resize, node.mousemove and so on
  if (!cb || wait<1) { return cb }
  let timer;
  return function(){
    timer && clearTimeout(timer);
    const self = this;
    const a = arguments;
    timer = setTimeout(function(){cb.apply(self, a);}, wait);
  };
}

function i18nReplace(template, obj) {
  if (!template || !obj)
    return template; 
  let str = String(template);
  Object.keys(obj).forEach(k => str.match(/{.*}/) && (str = str.replace(`{${k}}`, obj[k])));
  return str; 
}

class BottomBarPanel extends React.Component {
  //[ {layerId:0, dataElement:{ aliasName:'Validation Point Errros', dsId:17778, fields:{ fieldArray: [{name,type,aliasName},{},...], }  }, {...}, {..}, ...]
  layerDataElements = null; //Refer to arcgis rest api for FeatureServer/QueryDataElements
  dsIdToLayers = { 0:{name:'',id:'',alias:'',url:'',type:'',shapeType:'',fields:[]} };
  errorLayers = []; //[ {name,id,dsId,alias,url,type,shapeType,fields:[{}]}, ... ]
  validationSvcUrl = null;
  featureServiceUrl = null;
  isInit = false;
  gdbVersion = null;
  planId = null;
  tableColumns = [];
  isPageSizeChanged = false;
  tableDefCurrentPage = 1;
  tableDefPageSize = 10;
  graphicsLayer = null;

  //We MUST assume that all the 'Error Layers' have the same schema.
  //These field names are updated after querying the schema.
  fldNmFOID = "FeatureObjectID";
  fldNmFCID = "FeatureClassID";
  fldNmLastUpdate = "LASTUPDATE";
  fldNmLevelId = "LEVEL_ID";
  errorLyrRequiredFlds = "FeatureClassID,FeatureObjectID,ErrorNumber,ErrorMessage,RuleType,RuleName,RuleID,RuleDescription,Severity,IsException,ErrorStatus,ErrorPhase,CREATOR,CREATIONDATE,UPDATEDBY,LASTUPDATE,LEVEL_ID".split(",").map(x=>x.trim().toLowerCase());
  errorLyrQueryFlds    = "FeatureClassID,FeatureObjectID,ErrorMessage,RuleName,RuleType,RuleDescription,CREATOR,CREATIONDATE,UPDATEDBY,LASTUPDATE,LEVEL_ID".split(',').map(x=>x.trim());
  tableFilterFields    = "__shapeType,__layerName,LEVEL_ID,RuleName,ErrorMessage,RuleDescription,CREATOR".split(",").map(x=>x.trim());

  constructor(props) {
    super(props);
    this.state = component.newState({
      isValidationEnabled: false,
      lastValidated: null,
      currentErrors: 0,
      totalErrors: 0,
      size: 'hidden', //hidden, collapsed, visible, expanded
      isBusy: false,
      people: null,
      isBlur: false,
      isChecked: false,
      tableData: [],
      tableHeight: 420,
      tableColumns: this.tableColumns,
      selectedRowKeys: [],
      selectedRows: [],
      tableLoading: false,
      tableSortedInfo: {},
      tableFilters: {},
      tablePageSize: null,
    });
    this.vTable = React.createRef();
  }

  reset() {
    this.layerDataElements = null;
    this.dsIdToLayers = {};
    this.errorLayers = [];
    this.validationSvcUrl = null;
    this.featureServiceUrl = null;
    this.isInit = false;
    this.gdbVersion = null;
    this.planId = null;
    if (this.graphicsLayer)
      this.graphicsLayer.graphics.removeAll();
  }

  componentDidMount() {
    //console.debug("BottomBarPanel.mounted");

    this._createInitialTableSchema();
    
    component.own(this, Topic.subscribe(Topic.PlanOpened, async params => {
      const planId = Context.instance.spaceplanner.planId;
      //console.log('Attributes Validation needs to recheck - new plan opened', planId);      
      const officePlan = Context.instance.spaceplanner.activePlan;
      if (planId !== this.planId) {
        this.reset();
      }
    }));

    window.addEventListener('resize', this.onWindowResize);

    //for debugging
    window.indoorsContext = Context.instance;
    window.validatorToggleLoading = (yes) => { this.setState({ isBusy:yes}) };
    window.tableToggleLoading = (yes) => { this.setState({ isBusy:yes, isBlur:yes, tableLoading:yes }) };
    window.queryErrorFeatures = async ()=>{ await this.queryErrorFeatures(); console.debug('done') };
    window.bottomPanel = this;
  }

  componentDidUpdate(prevProps, prevState) {
    //console.debug("BottomBarPanel.didUpdate, props=", this.props, "state=", this.state);
    this.isPageSizeChanged = prevState.tablePageSize !== this.state.tablePageSize;

    if (prevProps.activeSidebarTab !== this.props.activeSidebarTab) {
      if (this.props.activeSidebarTab==='validate') {
        this.setState({size: "visible"}, () => {
          console.debug(this.state.size);
          this.setBodyLayoutStyles('visible');
        });
        if (!this.isInit) {
          this.setState({isBlur:true, isChecked: false});
          setTimeout(() => this._init(), 1000);
        }
      } else {
        if (this.state.size !== 'hidden') {
          this._clearSelection();
          this.setState({size: "hidden"}, () => {
            this.setBodyLayoutStyles('hidden');
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    component.componentWillUnmount(this);
    this.reset();
    if (this.graphicsLayer) {
      const view = Context.getInstance().views.activeView;
      view.map.remove(this.graphicsLayer);
    }
    this.graphicsLayer = null;
  }

  computeTableHeight(s) {
    const node1 = document.querySelector(".i-editor-header"); //could be absent
    const headerHt = node1 ? node1.getBoundingClientRect().height : 0;
    const node2 = document.querySelector(".i-editor-layout-middle");
    //The bottombar could be 80vh or 50vh
    const mapHt = node2 ? node2.getBoundingClientRect().height : window.innerHeight * (s==='expanded' ? 0.2 : 0.5); 
    const bbHt = window.innerHeight - headerHt - mapHt;
    return Math.floor(bbHt-125);
  }

  onWindowResize = debounce((e) => {
    if (['visible','expanded'].includes(this.state.size))
      this.setState({tableHeight: this.computeTableHeight()});
  }, 1000)

  setBodyLayoutStyles(s) {
    if (s==='expanded') {
      document.body.classList.add("i-editor-validator-expanded");
      document.body.classList.remove("i-editor-validator-visible");
    } else if (s==='visible') {
      document.body.classList.remove("i-editor-validator-expanded");
      document.body.classList.add("i-editor-validator-visible");
    } else { // if (s==='collapsed' || s==='hidden' || !s) {
      document.body.classList.remove("i-editor-validator-expanded");
      document.body.classList.remove("i-editor-validator-visible");
    }
    if (['visible','expanded'].includes(this.state.size))
      this.setState({tableHeight: this.computeTableHeight(s)});
  }

  _createInitialTableSchema() {
    /*
      For each column:
        key (string): Unique key of this column, you can ignore this prop if you've set a unique dataIndex
        dataIndex (string|string[]): Display field of the data record, support nest path by string array
        ___sort: if true, then allows sorting
        responsive: ['xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs']
    */
    //NOTE: This is the starting template and is modified at runtime. 
    //Several column object properties are overwritten at runtime
    this.tableColumns = [
      {
        title: 'Shape',
        dataIndex: '__shapeType',
        key: '__shapeType',
        render: (text, row) => { 
          //return JSX Node image icon or tag
          switch(text) {
            case 'esriGeometryPoint': 
              return (<>&nbsp;&nbsp;<PointIcon size={16}/></>);
            case 'esriGeometryPolyline': 
              return (<>&nbsp;&nbsp;<LineIcon size={16}/></>);
            case 'esriGeometryPolygon': 
              return (<>&nbsp;&nbsp;<PolygonIcon size={16}/></>);
            default: 
              return (<>&nbsp;&nbsp;<QuestionIcon size={16}/></>);
          }          
        },
        width: 90,        
        align: 'middle',
        filters: [
          { text:'Points', value:'esriGeometryPoint' },
          { text:'Lines', value:'esriGeometryPolyline' },
          { text:'Areas', value:'esriGeometryPolygon' },
        ],
        onFilter: (value, record) => record.__shapeType===value,
        //filteredValue: this.state.tableFilters['__shapeType'] || null,
        sorter: factoryStringSortFunc('__shapeType'),
        //sortOrder: this.state.tableSortedInfo.columnKey==='__shapeType' ? this.state.tableSortedInfo.order : null,
      },
      {
        title: 'Layer', //FeatureClassID -> geodb.datasetID
        dataIndex: '__layerName',
        key: '__layerName',
        //fixed: true,        
        ellipsis: true,
        width: 120,
        sorter: factoryStringSortFunc('__layerName'),
        //filteredValue: undefined,
        filters: [
          { text:'Details', value:'Details' },
          { text:'Levels', value:'Levels' },
          { text:'Units', value:'Units' },
        ],
        onFilter: factoryExactFilterFunc('__layerName'),
      },
      {
        title: 'Level',
        dataIndex: 'LEVEL_ID',
        key: 'LEVEL_ID',
        align: 'middle',
        width: 200,
        className: 'cell-small',
        ___sort: true,
        //filteredValue: undefined,
        filters: [{text:'MAIN.O1', value:'1000.US01.MAIN.O1'}],
        onFilter: factoryExactFilterFunc('LEVEL_ID'),
        filterSearch: filterSearchFunc,
        _render: (text, row) => {
          const parts = text && text.length>8 ? text.split('.') : null;
          return parts && parts.length>2 ? parts[parts.length-2]+"."+parts[parts.length-1] : text;
        },        
      },      
      {
        title: 'OID',
        dataIndex: 'FeatureObjectID',
        key: 'FeatureObjectID',
        align: 'middle',
        width: 70,
        ___sort: true,
        responsive: ['lg'],
      },
      {
        title: 'Rule Name',
        dataIndex: 'RuleName',
        key: 'RuleName',        
        ellipsis: true,
        width: 150,
        ___sort: true,
        //filteredValue: undefined,
        filters: [
          { text:'Unique ID', value:'Unique ID' },
          { text:'Unit contains another unit', value:'Unit contains another unit' },
          { text:'Missing units(s)', value:'Missing units(s)' },
          { text:'Correct Z value', value:'Correct Z value' },
          { text:'Overlapping geometry', value:'Overlapping geometry' },
          { text:'Valid Facility ID', value:'Valid Facility ID' },
          { text:'Valid Level ID', value:'Valid Level ID' },
        ],
        onFilter: factoryExactFilterFunc('RuleName'),
        filterSearch: filterSearchFunc,
      },
      {
        title: 'Error Message',
        dataIndex: 'ErrorMessage',
        key: 'ErrorMessage',
        ellipsis: true,
        ___sort: true,
        //filteredValue: undefined,
        onFilter: factoryExactFilterFunc('ErrorMessage'),
        filterSearch: filterSearchFunc
      },
      {
        title: 'Description',
        dataIndex: 'RuleDescription',
        key: 'RuleDescription',
        ellipsis: true,
        ___sort: true,
        //filteredValue: undefined,
        filters: [{text:'Check unit feature',value:'Check unit feature'}],
        onFilter: factoryExactFilterFunc('RuleDescription'),
        filterSearch: filterSearchFunc,
        responsive: ['lg'],
      },
      {
        title: 'Found By',
        dataIndex: 'CREATOR',
        key: 'CREATOR',
        ___sort: true,
        ellipsis: true,
        width: 120,
        responsive: ['lg'],
      },
      {
        title: 'Found On',
        dataIndex: 'CREATIONDATE',
        key: 'CREATIONDATE',
        className: 'cell-small',
        render: text => moment(text).format('YY/MM/DD HH:mm'),
        ___sort: true,
        ellipsis: true,
        width: 120,
        responsive: ['lg'],
      },
    ];    
  }
  
  async _init() {
    const i18n = Context.instance.i18n;
    this.isInit = true;
    const {isRetry, isSupported, errorMessages} = await this.isValidationSupported();
    if (isRetry) {
      console.log("Will retry isValidationSupported() once app/plan is ready/loaded.");
    } else if (isSupported) {
      if (errorMessages && errorMessages.length)
        console.warn(errorMessages);
      this.createGraphicsLayer();
      await this.queryErrorFeatures();
    } else {
      ModalController.showMessage(
        errorMessages ? errorMessages.join("\n") : i18n.editor.validate.defaultUnsupportedMsg, 
        i18n.editor.validate.notSupported
      );
    }
  }
  
  _checkPlan() {
    //Context.instance.spaceplanner.planner.project.isVersioned
    const officePlan = Context.instance.spaceplanner.activePlan;
    // or const officePlan = OfficePlan.getActive();
    if (!officePlan) {
      console.warn("No activeplan loaded");
      if (!this.eh_planOpened) {
        this.eh_planOpened = Topic.subscribe(Topic.PlanOpened, async params => {
          const planId = Context.instance.spaceplanner.planId;
          this.eh_planOpened.remove();
          this.eh_planOpened = null;
          if (!this.isChecked)
            await this._init();
        });
      }

      return false;
    }
    console.debug("Active/Loaded Plan:", officePlan, "plan.isVersioned:", officePlan.isVersioned, "plan.versionInfo:", officePlan.versionInfo);
    const lyrUnits = sourceUtil.getUnitsLayer();
    if (!officePlan.isUnitsLayerValid() || !lyrUnits) {
      console.warn("Invalid or no units layer");
      return false;
    }
    
    //TODO if versionInfo is null, should we assume 'DEFAULT'
    //console.debug("units layer gdbVersion=", lyrUnits.gdbVersion);
    this.gdbVersion = null;

    //officePlan.isVersioned && (this.gdbVersion = "DEFAULT");
    if (officePlan.isVersioned && officePlan.versionInfo && lyrUnits.gdbVersion) {
      this.gdbVersion = lyrUnits.gdbVersion;
    } else {
      console.warn("Plan is not versioned");
      return false;
    }
    this.planId = Context.instance.spaceplanner.planId;
    return true;
  }

  /**
   * https://developers.arcgis.com/rest/services-reference/enterprise/query-data-elements-feature-service-.htm
   * @returns {boolean}
   */
  async isValidationSupported() {
    let isDisabled=true;
    let errMsgs = [];
    let isRetry=false;
    const i18n = Context.instance.i18n;
    try {
      this.setState({isBlur: true, isBusy: true, isChecked: false});
      if (!this._checkPlan()) {
        isRetry = true;
        return; //NOTE that this skips to finally block. Will retry once plan is loaded.
      }

      const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
      if (units && units.url && units.url.toLowerCase().includes('featureserver'))
      {
        const unitsUrl = units.url;
        const fsRootUrl = unitsUrl.substring(0, unitsUrl.lastIndexOf('/'));
        if (fsRootUrl.toLowerCase().endsWith('featureserver')) {
          this.featureServiceUrl = fsRootUrl;
          const esriRequest = Context.instance.lib.esri.esriRequest;
          const reqOpts = {query:{f:"json"}, method:"get", responseType:"json"};
          const response = await esriRequest(fsRootUrl+"/queryDataElements", reqOpts);
          if (response && response.data && response.data.layerDataElements) {
            //console.debug("Obtained Layer Data Elements:", response.data);
            this.layerDataElements = response.data.layerDataElements;
            this.layerDataElements.forEach(obj => {
              const de = obj.dataElement;
              const lObj = {
                id: de.layerId,
                dsId: de.dsId,
                name: de.aliasName,
                type: de.datasetType, //esriDTFeatureClass | esriDTTable
                shapeType: de.shapeType || null, //esriGeometryPoint
                fields: de.fields && Array.isArray(de.fields.fieldArray) && de.fields.fieldArray,
                alias: undefined, //TODO name of layer in webmap (which could be diff from geodb layer name or map/aprx/svc layer name)
                url: fsRootUrl + "/" + de.layerId,
              };
              this.dsIdToLayers[de.dsId] = lObj;
              //if (de.aliasName.match(/Validation.*Errors/))
              const lyrFldNames = lObj.fields.map(x=>x.name.toLowerCase());
              if (this.errorLyrRequiredFlds.every(f=>f && lyrFldNames.includes(f)) && lObj.shapeType && lObj.type==="esriDTFeatureClass")
                this.errorLayers.push(lObj);
            });            
            if (!this.errorLayers.length) {
              const msg1 = i18n.editor.validate.noMatchLayers;
              errMsgs.push(msg1);
              console.warn(msg1);
            } else {
              //console.debug("Found Validation Error layers:", this.errorLayers.map(x=>x.name), this.errorLayers);
              const firstErrLyr = this.errorLayers[0];
              this.errorLyrQueryFlds = this._getCorrectFieldNames(this.errorLyrQueryFlds);
              //this.errorLyrQueryFlds.map(nm => this.getField(nm, this.errorLayers[0])).filter(x=>!!x).map(x=>x.name);
              this.tableFilterFields = this._getCorrectFieldNames(this.tableFilterFields, true);
              this.fldNmFOID = this.getField(this.fldNmFOID, firstErrLyr).name;
              this.fldNmFCID = this.getField(this.fldNmFCID, firstErrLyr).name;
              this.fldNmLevelId    = this.getField(this.fldNmLevelId, firstErrLyr).name;
              this.fldNmLastUpdate = this.getField(this.fldNmLastUpdate, firstErrLyr).name;
              
              //Adjust the table columns to use Error Layer Schema (field names may be different)
              this.tableColumns.forEach(tc => {
                if (tc.dataIndex.startsWith("_"))
                  return;
                let fld = this.getField(tc.dataIndex, this.errorLayers[0]);
                if (!fld)
                  console.warn(`Validation Error Table may not work, column[${tc.title}:${tc.dataIndex}] not found in Error Layer Schema`, this.errorLayers[0])
                else if (tc.dataIndex!==fld.name || tc.___sort===true) {
                  tc.dataIndex = fld.name;
                  if (tc.sorter || tc.___sort===true) {
                    if (fld.type.toLowerCase().includes("string")) //esriFieldTypeString
                      tc.sorter = factoryStringSortFunc(fld.name);
                    else if (["double","integer","oid","date"].some(n => fld.type.toLowerCase().includes(n)))
                      tc.sorter = (a,b) => a[fld.name] - b[fld.name];
                    
                    //tc.sortOrder = this.state.tableSortedInfo.columnKey===tc.key ? this.state.tableSortedInfo.order : null;                    
                  }
                }
              });

              try {
                const vSvcUrl = fsRootUrl.substring(0, fsRootUrl.lastIndexOf("/")) + "/ValidationServer";
                const vResponse = await esriRequest(vSvcUrl, {query:{f:"json"},method:"get",responseType:"json"});
                if (vResponse && vResponse.data && vResponse.data.name && vResponse.data.name.toLowerCase().includes("validation")) {
                  const sampleVResp = {"name": "Validation Server", "type": "Map Server Extension", "capabilities": { "supportsTopologyValidation": true, "supportsTopologyErrorModification": true }};
                  //console.debug("Validation Service is valid:", vResponse.data);
                  this.validationSvcUrl = vSvcUrl;
                  isDisabled = false;
                } else {
                  const msg2 = i18n.editor.validate.badValidationSvc;
                  errMsgs.push(msg2);
                  console.warn(msg2, vResponse);
                }
              } catch (vErr) {
                const msg3 = i18n.editor.validate.noValidationSvc;
                console.warn(msg3, vErr)
                throw new Error(msg3, { cause: vErr });
              }
            }
          } else {        
            const msg4 = i18n.editor.validate.noQueryDataSvc;
            errMsgs.push(msg4);
            console.warn(msg4, response);
          }
        }
      }
    } catch (err) {
      const msg5 = "Error occurred: ";
      errMsgs.push(msg5 + err.message + " " + err.name + " " + JSON.stringify(err.details))
      console.warn(msg5, err);
    } finally {
      if (isRetry) {
        console.debug("Will retry isValidationSupported()")
        this.setState({isBlur: true, isBusy: true, isChecked: false, isValidationEnabled: false});
      } else if (isDisabled) {
        console.warn("Validation disabled, unable to obtain supporting info from either FeatureServer/QueryDataElements or Error Layers or ValidationService\n", errMsgs);
        this.setState({isBlur: true, isBusy: false, isChecked: true, isValidationEnabled: false});
      } else {
        console.debug("Validation is SUPPORTED");
        this.setState({
          isBlur: false, 
          isBusy: false, 
          isChecked: true, 
          tableColumns: [...this.tableColumns],
          isValidationEnabled: true,
        });
      }
      return { isSupported: !isDisabled, errorMessages: errMsgs && errMsgs.length ? errMsgs : null, isRetry };
    } 
  }

  getToken(url) {
    const esriId = Context.instance.lib.esri.esriId;
    let credential = null;
    if (!url)
      credential = esriId.findCredential(Context.instance.getPortalUrl());
    else 
      credential = esriId.findCredential(url);
    return credential ? credential.token : null;
  }

  getField(nm, lyrObj) {    
    if (!lyrObj)
      lyrObj = Array.isArray(this.errorLayers) && this.errorLayers[0];
    if (!lyrObj || !Array.isArray(lyrObj.fields) || !nm )
      return null;
    nm = nm.toLowerCase();  
    return lyrObj.fields.find(f => f.name.toLowerCase()===nm);
  }

  _getCorrectFieldNames(fldNms, isKeepFieldsNotFound=false) {
    if (!Array.isArray(fldNms) || !this.errorLayers.length)
      return null;
      
    if (!isKeepFieldsNotFound)
      return fldNms.map(nm => this.getField(nm)).filter(x=>!!x).map(x=>x.name);
    else {
      return fldNms.map(nm => {
        const fo = this.getField(nm);
        return fo ? fo.name : nm
      })
    }
  }

  async delay(time){ return new Promise(resolve => setTimeout(resolve, time)) } 

  getSymbol(geomType, isSource=false) {
    const lib = Context.getInstance().lib;

    const colorMagenta = [249, 4, 172];
    const colorGreen = [27, 255, 0]; 

    const sfs = new lib.esri.SimpleFillSymbol({
      style: isSource ? "horizontal" : "diagonal-cross",
      outline: { 
        cap: "round", 
        join: "round", 
        width: isSource ? 4 : 2, 
        color: isSource ? [...colorGreen, 0.5] :[...colorMagenta, 0.85], 
      },
      color: isSource ? [...colorGreen, 0.7] : [...colorMagenta, 0.5],
    });

    const sls = new lib.esri.SimpleLineSymbol({
      color: isSource ? [...colorGreen, 0.5] : [...colorMagenta, 0.1],
      width: isSource ? 2 : 8,
      cap: "round",
      join: "round",
    });

    const sms = new lib.esri.SimpleMarkerSymbol({
      color: isSource ? [...colorGreen, 0.5] : [...colorMagenta, 0.2],
      size: 20,
      outline: { 
        color: isSource ? [...colorGreen, 0.5] : [...colorMagenta, 0.2],
        width: isSource ? 2 : 4,
      }
    });

    if (geomType.toLowerCase().includes('point'))
      return sms;
    else if (geomType.toLowerCase().includes('line'))
      return sls;
    else if (geomType.toLowerCase().includes('polygon'))
      return sfs;
    else 
      return null;

    
    const lineSymbol = {
      type: "simple-line", //autocasts as new SimpleLineSymbol()
      color: [249, 4, 172, 0.5], //RGBA
      width: 4
    }; 
    const sampleLine = {
      type: "polyline", // autocasts as new Polyline()
      paths: [[-111.3, 52.68], [-98, 49.5], [-93.94, 29.89]],
      spatialReference: { wkid: 4326 }
    };
    const sampleGra = new lib.esri.Graphic({
      geometry: sampleLine,
      symbol: lineSymbol
    });
  }

  createGraphicsLayer() {
    const lib = Context.getInstance().lib;
    const view = Context.getInstance().views.mapView;

    const lyrId = "fpe-validation-errors";
    let lyr = view.map.findLayerById(lyrId);
    if (!lyr) {
      lyr = new lib.esri.GraphicsLayer({
        id: lyrId,
        listMode: "show",  //show,dhide,hide-children
        title: "Validation Error Features",
        spatialReference: { wkid: view.spatialReference.wkid }
      });
      view.map.add(lyr);
    } else {
      lyr.graphics.removeAll();
    }
    this.graphicsLayer = lyr;    
  }

  async queryFeature(layerId, oid) {
    if ([null, undefined].some(x=>[layerId, oid].includes(x))) 
      return null;
    const url = Context.checkMixedContent(this.featureServiceUrl+"/"+layerId);
    //try {
      const task = new Context.instance.lib.esri.QueryTask({url: url});
      const query = new Context.instance.lib.esri.Query();
      query.outFields = ["*"]
      query.returnGeometry = true;
      query.returnZ = true;
      query.objectIds = Array.isArray(oid) ? oid : [oid];
      if (this.gdbVersion)
        query.gdbVersion = this.gdbVersion;
      const fs = await task.execute(query);
      //queryUtil.applyGdbVersion
      return fs && fs.features && fs.features.length ? fs.features[0] : null;
    //} catch (err) { console.warn(`Error querying for feature oid[${oid}] in layer[${url}]`, err) return null; }
  }  

  async queryErrorFeatures() {
    /* Each error layer must have the same fields which are: 
          FeatureClassID,
          FeatureObjectID,
          ErrorNumber(int),           
          ErrorMessage, 
          RuleType(int, codedvalue),
          RuleName, 
          RuleID, 
          RuleDescription, 
          Severity, 
          IsException, 
          ErrorStatus,
          CREATOR,
          CREATIONDATE,
          UPDATEDBY,
          LASTUPDATE,
          LEVEL_ID,
    */
    this.setState({tableData:[], totalErrors:0, isBusy:true, isBlur:true, lastValidated: null});
    let maxDate = 0;;
    const allResults = {};
    const tableRows = [];

    const proms = this.errorLayers.map(async li => {
      allResults[li.name] = null;
      if (!li || !li.url || !li.shapeType || li.type!=="esriDTFeatureClass")
        return [li, null];
      try {
        const i18n = Context.instance.i18n;
        const qt = new Context.instance.lib.esri.QueryTask({url: li.url});
        const q = new Context.instance.lib.esri.Query();
        q.outFields = this.errorLyrQueryFlds;
        q.returnGeometry = true;
        q.where = "1=1";
        q.gdbVersion = this.gdbVersion;
        const fs = await qt.execute(q);
        fs.features.forEach((f,idx) => {
          const sourceLI = this.dsIdToLayers[f.attributes[this.fldNmFCID]];
          f.attributes['__layerName'] = sourceLI ? sourceLI.name : '???';
          f.attributes['__layerId']   = sourceLI ? sourceLI.id : -1;
          f.attributes['__shapeType'] = li.shapeType;
          f.attributes['_key_'] = `${sourceLI.id}_${f.attributes[this.fldNmFOID]}_${idx}`;
          f.attributes['_geometry_'] = f.geometry;
          const lastU = f.attributes[this.fldNmLastUpdate];
          lastU > maxDate && (maxDate = lastU);
        });
        if (fs.exceededTransferLimit) {
          console.warn(`Too many features found in Error Layer[${li.name}], only showing the first ${fs.features.length}`);
          ModalController.showMessage(i18nReplace(i18n.editor.validate.tooManyFeatures, {layer:li.name, count:fs.features.length}));
        }
        return [li, fs.features];
      } catch (err) {
        console.warn(`Error querying Error Layer ${li.name}`, li, err);
        return [li, err];
      }
    });

    const allPromiseResults = await Promise.allSettled(proms);
    allPromiseResults.forEach(res => {
      //console.debug(res.status, res.value, res.reason);
      const [ li, fsOrErr ] = res.value;
      if (fsOrErr instanceof Error) {
        const err = fsOrErr;
        ModalController.showMessage(err.message + " " + err.name + " " + JSON.stringify(err.details), `Error querying ${li.name}`);
      } else {
        tableRows.push(...fsOrErr.map(g => g.attributes));
        allResults[li.name] = fsOrErr;
      }
    });
    //console.debug(allResults);
    //const dupes = tableRows.filter(f1 => tableRows.find(f2 => f1!==f2 && f2._key_===f1._key_));
    //dupes.length && console.log("Dupes founds, revise keys:", dupes);

    const uniqueValues = {}; //{ "fieldName": {"val1":11, "val2":3}};
    this.tableFilterFields.forEach(f => uniqueValues[f] = {});
    Object.keys(uniqueValues).length && tableRows.forEach(row => {
      for (let fldNm in uniqueValues) {
        const fldVal = row[fldNm];
        !uniqueValues[fldNm][fldVal] && (uniqueValues[fldNm][fldVal]=0);
        uniqueValues[fldNm][fldVal]++;
      }
    });

    //create columns filters based on the unique values per attribute/column for all features 
    this.tableColumns.forEach(tc => {
      const valToCnts = uniqueValues[tc.dataIndex];
      const uniqueVals = valToCnts && Object.keys(valToCnts);
      if (valToCnts && uniqueVals.length) {
        if (tc.dataIndex==='__shapeType') {
          tc.filters = uniqueVals.map(v => {return {text:`${this._shapeTypeToAlias(v)}s (${valToCnts[v]})` , value:v}});
        } else if (tc.dataIndex===this.fldNmLevelId) {
          tc.filters = uniqueVals.map(v => {return {text:`${v} (${valToCnts[v]})` , value:v}});
          //tc.filters = uniqueVals.map(v => {return {text:`${v.substring(v.lastIndexOf('.')+1)}s (${valToCnts[v]})` , value:v}});
        } else {
          tc.filters = uniqueVals.map(v => {return {text:`${v} (${valToCnts[v]})` , value:v}});
        }
        tc.onFilter = factoryExactFilterFunc(tc.dataIndex);
        //tc.filteredValue = this.state.tableFilters[tc.dataIndex] || null;
      }
    });

    this.setState({
      //tableColumns:[...this.tableColumns],
      tableData:tableRows, 
      totalErrors:tableRows.length, 
      isBusy:false, 
      isBlur:false, 
      lastValidated:maxDate>0?maxDate:null,
    });
    //console.debug(tableRows.length, tableRows, new Date(maxDate));
    return allResults;
  }

  async runValidate(useExtent=true) {
    const i18n = Context.instance.i18n;
    const startTime = new Date().getTime();
    const [result, errTitle, errMsg] = await this._executeValidate(useExtent);
    if (result) {
      const timeMins = (result.lastUpdatedTime-startTime)/6e4;
      const timeShown = timeMins > 2 ? `${timeMins.toFixed(1)}mins` : `${(timeMins*60).toFixed(0)}seconds`;
      ModalController.showMessage(`${i18n.editor.validate.newErrors}: ${result.errorsIdentified}, ${i18n.editor.validate.timeTaken}: ${timeShown}`, i18n.editor.validate.finished);
      this.queryErrorFeatures();
    } else {
      console.log('NO RESULT', result, errTitle, errMsg);
    }
    if (errTitle || errMsg) {
      console.warn(errTitle, errMsg);
      ModalController.showMessage(errMsg, errTitle);
    }
  }

  /**
   * Execute the evaluate rules on the service asynchronously. This uses the system 
   * ValidationTools geoprocessing service, which allows for long-running processes. 
   * Only available when the 'Units' source is a feature service with the Validation capability.
   * 
   * GP param @gdbVeresion - If the plan is versioned, it uses the gdbVersion name from the Units layer.
   * GP param @changesInVersion - 'Modified in this version': Evaluates edits made in the current branch version. 
   * 
   * More info:
   *  https://pro.arcgis.com/en/pro-app/latest/help/data/geodatabases/overview/evaluate-attribute-rules.htm
   * 
   * @param {boolean} useExtent 
   * @returns {Promise} with result [response, errorTitle, errorDesc]
   */
  async _executeValidate(useExtent=true) {
    const i18n = Context.instance.i18n;
    if (!this.validationSvcUrl || !this.state.isValidationEnabled)
      return [null, i18n.editor.validate.unableToComplete, i18n.editor.validate.notSupported];
    if (this.state.isBusy) {
      console.log("Validation already in progress...");
      return [null];
    }
    const view = Context.instance.views.activeView;
    const ext = view.extent;
    const params = {
      gdbVersion: this.gdbVersion,
      changesInVersion: false, //default false (doesn't mean anything if gdbVersion=DEFAULT)
      //selection: null,//[{ id: 4, objectIds: [] }],
      //token: this.getToken(this.validationSvcUrl),
      evaluationType: '["validationRules"]', //batchCalculationRules
      returnEdits:true,
      async:true,
      f:"json",
    };
    if (useExtent)
      params['evaluationArea'] = `{"xmin": ${ext.xmin},"ymin": ${ext.ymin},"xmax": ${ext.xmax},"ymax": ${ext.ymax},"spatialReference": { "wkid": ${view.spatialReference.wkid}}}`;
    //console.debug("executeValidate() params:", params);

    const esriRequest = Context.instance.lib.esri.esriRequest;
    const timeout10Min = 10*60*1000
    this.setState({isBusy: true});
    try {
      const reqOpts = {
        query: params,
        method: "get",
        responseType: "json",
        //withCredentials: true,
        timeout: timeout10Min
      };      
      let result1 = await esriRequest(this.validationSvcUrl+"/evaluate", reqOpts);
      result1 = result1.data;
      if (result1.error) {
        console.warn("Validation Service returned error:", result1.error, result1);
        const err = result1.error;        
        return [null, i18n.editor.validate.errValidating, `${err.message}. Code:${err.code}<br/>${err.details.join('<br/>')}`];
      }
      if (!result1.statusUrl) {
        console.warn("Validation svc returned invalid response: ", result1);
        return [null, i18n.editor.validate.unableToComplete, `${i18n.editor.validate.badValidationSvc}: ${JSON.stringify(result1)}`];
      }

      console.debug("Starting validation async GP status checks", new Date(), result1);

      let esriErrDetails = null;
      let responseData = null; 
      let numXhr = 0;
      const MAX_XHRS = 2000;
      let isStop=false;
      let numHttpErrors=0;
      const MAX_STATUS_RETRIES=10;
      const errMsg1 = "Error checking validation status";
      do {
        await this.delay(2000);
        let requestResponse = null; //esri.esriRequest.RequestResponse

        try {
          requestResponse = await esriRequest(result1.statusUrl, { 
            query: {f:"json"}, 
            //token: this.getToken(result1.statusUrl)}, //withCredentials: true,
            method: "get", 
            responseType: "json",
            timeout: timeout10Min,
            cacheBust: true,
          });
          //console.debug(requestResponse);
          responseData = requestResponse.data;
          if (responseData.error)
            numHttpErrors++ && console.warn(errMsg1 + ", will try again...", responseData.error)
          else
            console.debug(responseData.status || "InProgress", new Date(), responseData);
        } catch (err2) {
          console.log(err2, err2.details ? err2.details.raw : err2.details, requestResponse);
          if (err2.details && (err2.details.raw || err2.name==="request:server")) {
            esriErrDetails = { ...err2.details, $message: err2.message, $name: err2.name };
            isStop = true;
          } else {
            numHttpErrors++;
            //retry for other http errors
          }
          console.warn("Caught", errMsg1 + isStop?", quitting":", will try again...", err2, responseData);
        }

        if (responseData)
          isStop = isStop || responseData.status.toLowerCase()==="completed" || responseData.error || responseData.success;
        isStop = isStop || numXhr++>MAX_XHRS || numHttpErrors>MAX_STATUS_RETRIES;
      } while (!isStop);
      
      console.debug("Finished", new Date(), responseData);
      if (numHttpErrors>MAX_STATUS_RETRIES) {
        console.warn(errMsg1, numHttpErrors, numXhr);
        return [null, i18n.editor.validate.unableToComplete , i18n.editors.validate.exceededMaxChecks];
      }
      if (esriErrDetails) {
        console.warn("Validation could not complete:", numHttpErrors, responseData, esriErrDetails);
        return [null, i18n.editor.validate.unableToComplete + " " + (esriErrDetails && esriErrDetails.$message), JSON.stringify(esriErrDetails.raw)];
      }
      if (responseData && (responseData.error || !responseData.success || responseData.status.toLowerCase()!=="completed")) {
        console.warn("Validation could not complete:", numHttpErrors, responseData, esriErrDetails);
        return [null, i18n.editor.validate.unableToComplete, JSON.stringify(responseData)];
      } 
      if (numXhr > MAX_XHRS) {
        console.warn("Error:", i18n.editors.validate.exceededMaxChecks, numXhr, numHttpErrors, responseData, esriErrDetails);
        return [null, i18n.editor.validate.unableToComplete , i18n.editors.validate.exceededMaxChecks];
      } 
      return [responseData];
    } catch (err) {
      console.warn("Unhandled Error validating:", err);
      return [null, `${i18n.editor.validate.errValidating}: ${err.name}`, err.message];
    } finally {
      this.setState({isBusy: false});
    }
  }

  _shapeTypeToAlias(nm) {
    switch(nm) {
      case 'esriGeometryPoint': return 'Point';
      case 'esriGeometryPolyline': return 'Line';
      case 'esriGeometryPolygon': return 'Area';
      default: return nm;
    }
  }

  _clearSelection() {
    this.graphicsLayer && this.graphicsLayer.graphics.removeAll();
    const prevRows = this.state.selectedRows;
    const node = document.querySelector(".ant-table-body .ant-table-tbody .ant-table-row.row-selected");
    if (node)
      node.classList.remove("row-selected");
    if (Array.isArray(prevRows) && prevRows.length) {
      const prevRow = prevRows[0];
      delete prevRow.__isSelected;      
    }
    this.setState({selectedRows:[], selectedRowKeys:[]});
  }

  _clearTableFiltersAndSorts() {
    this.setState({tableFilters:{}, tableSortedInfo:{}});
  }

  /**
   * @param zoom - 1 to 23
   * @param options - {animate:true, duration:ms, easing:linear|ease|ease-in|ease-out|ease-in-out, signal:AbortSignal}
   */
  _goToGeometry(geometry, scale, zoom, options) {
    const view = Context.instance.views.activeView;
    const is3D = (view && view.type === "3d");
    if (!geometry)
      return Promise.resolve();
    let goToTarget = { target: geometry };
    if (geometry.type==="point") {
      if (!scale || !zoom)
        zoom = 21;
    } else if (geometry.extent) {
      goToTarget.target = geometry.extent.expand(1.25);
    }
    if (scale)
      goToTarget.scale = scale;
    if (zoom)
      goToTarget.zoom = zoom;
    let prom = view.goTo(goToTarget, options);
    false && prom.then(() => {
      if (!is3D && (view.extent && !view.extent.contains(geometry))) {
        view.zoom -= 1;
      }
    });
    return prom;
  }

  createOnRowFuncs = (record, rowIndex) => {
    return {
      onClick: e => false && console.debug('Table.Row.click', e, record, rowIndex),
      onDoubleClick: async (e) => {
        //console.debug('Table.Row.DoubleClick', e, record, rowIndex);
        this.setState({tableLoading:true});
        if (this.isPanning) {
          console.debug("Ignoring dblClick");
          return;
        }
        try {
          if (false && Array.isArray(this.state.selectedRowKeys) && this.state.selectedRowKeys.length) {
            const prevKey = this.state.selectedRowKeys[0];
            const node = document.querySelector(`.i-editor-bottombar-body tr[data-row-key='${prevKey}']`);
            node && (node.style.backgroundColor = "");
          }
          if (Array.isArray(this.state.selectedRows) && this.state.selectedRows.length) {
            const prevRow = this.state.selectedRows[0];
            delete prevRow.__isSelected;
          }

          this.isPanning = true;
          this.setState({selectedRowKeys: [record._key_], selectedRows: [record]});
          record.__isSelected = true;
          if (false)
            document.querySelector(`.i-editor-bottombar-body tr[data-row-key='${record._key_}']`).style.backgroundColor = "aqua";
          
          const i18n = Context.instance.i18n;
          const lib = Context.instance.lib;
          //remove prev gra/s from map 
          this.graphicsLayer.graphics.removeAll();
          this._goToGeometry(record._geometry_); //async but we don't care when it finishes

          const floorFilter = Context.instance.views.floorFilter;
          const levelId = record[this.fldNmLevelId];
          let isLevelIdValid = true;
          //if (floorFilter?.activeWidget?.viewModel?.filterFeatures?.levels?.levelsInfo) {
          if (lib.dojo.kernel.exists("activeWidget.viewModel.filterFeatures.levels.levelsInfo", floorFilter)) {
            isLevelIdValid = floorFilter.activeWidget.viewModel.filterFeatures.levels.levelsInfo.find(li => [li, li.id].includes(levelId));
          }
          if (levelId && isLevelIdValid) {
            floorFilter.setLevel(levelId);
            //Context.instance.views.floorFilter.activeWidget.level = ...
          } else if (!isLevelIdValid) {
            Topic.publish(Topic.ShowToast,{
              message: i18n.editor.validate.invalidLevelId,
              ...levelId ? {submessage: `${i18n.editor.validate.unableToSelectFloor} ${levelId}`} : null,
            });
            //ModalController.showMessage(`${i18n.editor.validate.unableToSelectFloor} ${levelId}`, i18n.editor.validate.invalidLevelId);
          }

          this.graphicsLayer.graphics.add(new lib.esri.Graphic({
            geometry: record._geometry_,
            symbol: this.getSymbol(record.__shapeType)
          }));

          //let goToOpts = record.__shapeType==='esriGeometryPoint' || record.__layerId===sourceUtil.getUnitsLayer().layerId ? {scale:70} : null;
          //const view = Context.instance.views.activeView;
          const proms = [
            this.queryFeature(record.__layerId, record[this.fldNmFOID]),
            //mapUtil.goToGeometry(view, record._geometry_, "unit_level"), //causes really long UI freezing issues, up to 60s
            //mapUtil2.goToFeature(view, {geometry: record._geometry_}, null, goToOpts), //causes really long UI freezing issues
          ];
          const results = await Promise.all(proms);
          const srcGra = results[0];
          if (srcGra) {
            srcGra.symbol = this.getSymbol(record.__shapeType, true);
            console.debug("Found error source feature", srcGra);
            this.graphicsLayer.graphics.add(srcGra);
          } else {
            console.debug("Error source feature not found");
          }
        } catch (err) {
          console.warn("Unable to highlight error and/or error source feature", err);
        } finally {
          this.setState({tableLoading:false});
          this.isPanning = false
        }
      }
    };
  }  

  render() {
    const i18n = Context.instance.i18n;
    const { tab, activeSidebarTab } = this.props;
    const active = tab && (tab === activeSidebarTab);
    const { 
      size, isBusy, totalErrors, isBlur, isValidationEnabled, lastValidated, 
      tableColumns, tableData, tableLoading, tableHeight, tablePageSize, 
      selectedRowKeys, selectedRows, isChecked } = this.state; 
    if (!active || size==="hidden") 
      return null;    

    const bodyStyle = { display: size==="collapsed" ? "none" : "block" };
    const mainStyle = {}; //{ height: size==="expanded" ? "80vh" : (size==="visible" ? "50vh" : "") };

    const txtValidateExtent = `${i18n.editor.validate.validate} ${i18n.editor.validate.extent}`;
    const txtValidateAll = `${i18n.editor.validate.validate} ${i18n.editor.validate.all}`;
    const txtClear = i18n.general.clear;
    //{ isBusy && <Progress type="indeterminate" /> }

    return (
      <div className="i-editor-bottombar" style={mainStyle}>
        <div style={{height:'2px'}}>
          <Progress type="indeterminate" 
            style={{padding:'0px', margin:'0px',display:isBusy?'block':'none'}} />
        </div>
        <div style={{position:'relative',height:'100%',width:'100%',}}>
          <div className={`i-editor-bottombar-content-wrapper ${isBlur ? 'blur':''}`}>
            <div className="i-editor-bottombar-header">
              <div className="i-editor-bottombar-header-left">
                <span style={{fontSize:"1rem"}}>{i18n.editor.validate.title}</span>
                <Button title={txtValidateAll} aria-label={txtValidateAll}
                  onClick={()=>this.runValidate(false)} disabled={isBusy || !isValidationEnabled}
                  icon={<PolygonLineCheckIcon size={16}/>} iconPosition="before" transparent>
                  {txtValidateAll}
                </Button>
                <Button title={txtValidateExtent} aria-label={txtValidateExtent}
                  onClick={()=>this.runValidate(true)} disabled={isBusy || !isValidationEnabled} 
                  icon={<CheckExtentIcon size={16}/>} iconPosition="before" transparent>
                  {txtValidateExtent}
                </Button>
                { Array.isArray(selectedRows) && selectedRows.length>0 &&
                <Button title={txtClear} aria-label={txtClear}
                  onClick={()=>this._clearSelection()} 
                  icon={<EraseIcon size={16}/>} iconPosition="before" transparent>
                  {txtClear}
                </Button>
                }
              </div>
        
              <div className="i-editor-bottombar-header-right">
                {totalErrors>0 && 
                <span className="i-editor-bottombar-bubble">
                  {totalErrors} error{totalErrors>1 && "s"}
                </span>}
                {lastValidated && <span>{i18n.editor.validate.lastValidated} {moment(lastValidated).format('Y-M-D HH:mm')}</span>}
                {size !== "expanded" &&
                <Button title={i18n.general.expand} disabled={isBusy} icon={<ChevronsUpIcon size={24}/>}
                    onClick={e => {
                      const s = size==="collapsed" ? "visible" : "expanded";
                      this.setState({size: s}, ()=>this.setBodyLayoutStyles(s));
                    }} transparent>
                </Button>}
                {size!=="collapsed" &&
                <Button title={i18n.general.collapse} disabled={isBusy} icon={<ChevronsDownIcon size={24}/>}
                    onClick={e => {
                      const s = size==="expanded" ? "visible" : "collapsed";
                      this.setState({size: s}, ()=>this.setBodyLayoutStyles(s));
                    }} transparent>
                </Button>}
                <Button title={i18n.general.close} icon={<CloseIcon size={24} />} 
                  onClick={() => this.props.setActiveTab(null)} transparent>
                </Button>
              </div>
              
            </div>
            <div className="i-editor-bottombar-body" style={bodyStyle}>
              <div style={{display: !isValidationEnabled && isChecked ?'block':'none',textAlign:'center',margin:'1rem',}}>
                <h1>{i18n.editor.validate.notSupported}</h1>
              </div>
              <Table columns={tableColumns} 
                style={{display: isValidationEnabled ? "block" : "none"}}
                ref={this.vTable}
                dataSource={tableData} 
                loading={tableLoading}
                rowKey="_key_"
                onChange={(pagination, filters, sorter, extra) => {
                  console.debug('Table.onChange', pagination, filters, sorter, extra);
                  if (pagination) {
                    this.tableDefPageSize = pagination.pageSize;
                    this.tableDefCurrentPage = pagination.current;
                  }
                  tableColumns.forEach(c => {
                    delete c.defaultFilteredValue;
                    delete c.defaultSortOrder; 
                  });
                  if (filters && Object.keys(filters).length > 0) {
                    for (let fldNm in filters) {
                      const col = tableColumns.find(c => c.dataIndex===fldNm);
                      if (col) {
                        col.defaultFilteredValue = filters[fldNm];
                      }
                    }
                  }
                  if (sorter && sorter.field && sorter.order) {
                    const col = tableColumns.find(c => c.dataIndex===sorter.field);
                    if (col) {
                      col.defaultSortOrder = sorter.order; 
                    }
                  }
                  const numItems = extra.currentDataSource ? extra.currentDataSource.length : -1;
                  this.setState({totalErrors: numItems, tableFilters: filters, tableSortedInfo: sorter,
                    ... pagination ? {
                      tablePageSize: pagination.pageSize,
                    } : null, 
                  });
                  //if (Object.keys(filters).length && extra.action==="filter") { }
                }}
                size="small"
                showSorterTooltip={false}
                scroll={{y: tableHeight}}
                //scroll={{y: "max-content", x: "max-content"}}
                onRow={this.createOnRowFuncs}
                pagination={{
                  current: this.isPageSizeChanged ? 1 : this.tableDefCurrentPage,
                  defaultPageSize: this.tableDefPageSize,
                  defaultCurrent: this.tableDefCurrentPage,
                  position: ['bottomCenter'],
                  pageSize: (size==="expanded" || tableHeight > 390 ? Math.max(tablePageSize, 20) : Math.max(tablePageSize, 10)),
                  onShowSizeChange: (current, size) => {
                    //console.debug("pagination.onShowSizeChange()", current, size);
                    this.tableDefPageSize = size;
                    this.tableDefCurrentPage = current;
                    this.setState({tablePageSize: size});
                  }
                }}
                rowClassName={(row) => row.__isSelected===true ? 'row-selected' : '' }
                _rowSelection={{
                  type: "radio",
                  selectedRowKeys: selectedRowKeys,
                  onChange: (newSelKeys, newSelRows) => {
                    console.debug("selectedRowKeys changed, old:", selectedRowKeys, "new:", newSelKeys, newSelRows);
                    this.setState({selectedRowKeys: newSelKeys});
                  },
                  getCheckboxProps: (row) => ({
                    disabled: !row._geometry_, //String(row.FeatureClassID).startsWith("1"), 
                    // Column configuration not to be checked
                    name: null,
                  }),
                }}
              />
            </div>
          </div>
          {(isBlur || !isChecked) && isBusy && 
          <div className="i-editor-bottombar-loader"><Loader sizeRatio={1} style={{marginTop:'13vh'}}/></div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeSidebarTab: getActiveTab(state)
});
const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(tab))
});
export default connect(mapStateToProps, mapDispatchToProps)(BottomBarPanel);