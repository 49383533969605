import Context from "../../../../context/Context";
import { Units } from "../redux";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";

export const defaultLengthInFeet = 10;
export const defaultLengthInMeters = 3;
export const defaultThicknessInInches = 8;
export const defaultThicknessInMillimeters = 120;
export const defaultGridSizeInFeet = 3;
export const defaultGridSizeInMeters = 1;

export function getDefaultLinearUnits(): "feet" | "meters" {
  const locale = getLocale();
  if (typeof locale === "string" && locale.toLowerCase() === "en-us") {
    return "feet";
  }
  return "meters";
}

export function getDefaultWallWidthUnits(): Units {
  const locale = getLocale();
  if (typeof locale === "string" && locale.toLowerCase() === "en-us") {
    return "inches";
  }
  return "millimeters";
}

export function getDefaultGapUnits(): Units {
  return getDefaultWallWidthUnits();
}

function getLocale(): string {
  const lib = Context.instance.lib
  let locale = lib && lib.dojo && lib.dojo.kernel && lib.dojo.kernel.locale;
  if (typeof locale === "string") return locale;
  locale = document.documentElement.lang;
  if (typeof locale === "string") return locale;
  return null;
}

export function getLengthInMapUnits(v: number, units: "inches" | "feet" | "millimeters" | "meters"): number {
  const { views } = Context.instance;
  switch (views.mapView.spatialReference.unit) {
    case "meters":
      if (units === "inches") {
        v = v * 0.0254;
      } else if (units === "feet") {
        v = (v * 12) * 0.0254;
      } else if (units === "millimeters") {
        v = v * 0.001;
      } else if (units === "meters") {
        return v;
      }
      break;
    case "us-feet":
      if (units === "inches") {
        v = v * 0.0833333;
      } else if (units === "feet") {
        return v;
      } else if (units === "millimeters") { 
        v = (v * 0.001) * 3.28084;
      } else if (units === "meters") {
        v = v * 3.28084;
      } 
      break;
    default:
      // TODO: support for other measurement units
      console.error(`Unsupported map unit: ${views.mapView.spatialReference.unit}`);
      break;
  }   
  return v;
}

export function newLengthAndWidthInfo(type: "units" | "walls" | "grid"): ILengthAndWidthInfo {
  const metric = isMetric();
  let lengthAndWidthInfo: ILengthAndWidthInfo;
  if (type === "units") {
    lengthAndWidthInfo = {
      length: metric ? defaultLengthInMeters : defaultLengthInFeet,
      width: metric ? defaultLengthInMeters : defaultLengthInFeet,
      thickness: 0,
      lengthInMapUnits: 0,
      widthInMapUnits: 0,
      thicknessInMapUnits: 0,
      units: metric ? "meters" : "feet",
      thicknessUnits: metric ? "millimeters" : "inches",
      anchor1Position: "uc",
      anchor2Position: "ur"
    }
    lengthAndWidthInfo.lengthInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.length,lengthAndWidthInfo.units);
    lengthAndWidthInfo.widthInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.width,lengthAndWidthInfo.units);
  } else if (type === "walls") {
    lengthAndWidthInfo = {
      length: metric ? defaultLengthInMeters : defaultLengthInFeet,
      width: 0,
      thickness: metric ? defaultThicknessInMillimeters : defaultThicknessInInches,
      lengthInMapUnits: 0,
      widthInMapUnits: 0,
      thicknessInMapUnits: 0,
      units: metric ? "meters" : "feet",
      thicknessUnits: metric ? "millimeters" : "inches",
      anchor1Position: "uc",
      anchor2Position: "ur"
    }
    lengthAndWidthInfo.lengthInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.length,lengthAndWidthInfo.units);
    lengthAndWidthInfo.thicknessInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.thickness,lengthAndWidthInfo.thicknessUnits);
  } else if (type === "grid") {
    lengthAndWidthInfo = {
      length: metric ? defaultGridSizeInMeters : defaultGridSizeInFeet,
      width: metric ? defaultGridSizeInMeters : defaultGridSizeInFeet,
      thickness: 0,
      lengthInMapUnits: 0,
      widthInMapUnits: 0,
      thicknessInMapUnits: 0,
      units: metric ? "meters" : "feet",
      thicknessUnits: metric ? "millimeters" : "inches",
      anchor1Position: "uc",
      anchor2Position: "ur"
    }
    lengthAndWidthInfo.lengthInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.length,lengthAndWidthInfo.units);
    lengthAndWidthInfo.widthInMapUnits = getLengthInMapUnits(lengthAndWidthInfo.width,lengthAndWidthInfo.units);
  }
  return lengthAndWidthInfo;
}

function isEnUS(): boolean {
  const lib = Context.instance.lib
  let locale = lib && lib.dojo && lib.dojo.kernel && lib.dojo.kernel.locale;
  if (typeof locale !== "string") locale = document.documentElement.lang;
  if (typeof locale === "string" && locale.toLowerCase() === "en-us") return true;
  return false;
}

export function isMetric(): boolean {
  return !isEnUS();
}
