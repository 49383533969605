import React from "react";
import SiteDropdown from "./SiteDropdown";
import SpaceTypeDropdown from "./SpaceTypeDropdown";
import AreaDropdown from "./AreaDropdown";
import Button from "../../../../../common/components/Button";
import Context from "../../../../../context/Context";
import Icons from "../../../../util/Icons";
import Topic from "../../../../../context/Topic";
import EquipmentFilterDropdown from "./EquipmentFilterDropdown";

const CSS = {
  popoutInner: "i-popout-inner i-filter-space",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  spaceFilters: "i-space-filter",
  spaceFilterForm: "i-space-filter-form",
  button: "i-book-button",
  filterForm: "i-filter-form i-popup",
  clearButton: "i-button--clear",
  spaceFilterLabel: "i-space-filter-label"
};

export default class SpaceFilters extends React.Component {
  _mounted = false

  constructor(props) {
    super(props)
    this.closeRef = React.createRef();
  }

  componentDidMount() {
    this._mounted = true
    if (this.closeRef && this.closeRef.current && this.closeRef.current.focus) {
      this.closeRef.current.focus()
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  onSubmit = () => {
    if (this._mounted) {
      Topic.publish(Topic.SpaceFiltersSubmit, {})
      Topic.publish(Topic.BookingFiltersSubmit, {})
      if (typeof this.props.closePopup === "function") this.props.closePopup()
    }
  }

  onClear = () => {
    Topic.publish(Topic.BookFiltersCleared, {})
  }

  render() {
    const i18n = Context.instance.i18n

    if (this.props.initialFilter) {
      return (
        <div className={CSS.spaceFilterForm}>
          <div className={CSS.spaceFilters}>
            <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.workspaceArea}</div>
            <AreaDropdown initialFilter={true} areaFilters={this.props.areaFilters} />
            {this.props.siteFilters && this.props.siteFilters.length > 1 &&
              (<div>
                <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.site}</div>
                <SiteDropdown initialFilter={true} siteFilters={this.props.siteFilters} />
              </div>)
            }
            <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.spaceType}</div>
            <SpaceTypeDropdown initialFilter={true} spaceTypeFilters={this.props.spaceTypeFilters} />
            {this.props.equipmentFilters && this.props.equipmentFilters.length > 0 &&
              (<div>
                <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.equipment}</div>
                <EquipmentFilterDropdown initialFilter={true} equipmentFilters={this.props.equipmentFilters} />
              </div>)
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className={CSS.popoutOuter}>
          <div className={CSS.popoutInner}>
            <div className={CSS.panelHeader}>
              <h2>{i18n.more.bookWorkspace.spaceFilterHeader}</h2>
              <div className={CSS.panelHeaderTools}>
                <button ref={this.closeRef}
                  title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
                  tabIndex="0" onClick={this.props.closePopup}>
                  {Icons.close()}
                </button>
              </div>
            </div>
            <div className={CSS.filterForm}>
              <div className={CSS.spaceFilters}>
              <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.workspaceArea}</div>
              <AreaDropdown areaFilters={this.props.areaFilters} />
              {this.props.siteFilters && this.props.siteFilters.length > 1 &&
                (<div>
                  <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.site}</div>
                  <SiteDropdown siteFilters={this.props.siteFilters} />
                </div>)
              }
              <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.spaceType}</div>
              <SpaceTypeDropdown spaceTypeFilters={this.props.spaceTypeFilters} />
              {this.props.equipmentFilters && this.props.equipmentFilters.length > 0 &&
                (<div>
                  <div className={CSS.spaceFilterLabel}>{i18n.more.bookWorkspace.equipment}</div>
                  <EquipmentFilterDropdown equipmentFilters={this.props.equipmentFilters} />
                </div>)
               }
              </div>
              </div>
              <div className={CSS.button}>
                <div>
                  <Button type="button" className={CSS.clearButton} onClick={this.onClear} clear={true}>
                    {i18n.more.bookWorkspace.clearAll}</Button>
                </div>
                <div>
                  <Button type="button" onClick={this.onSubmit}>{i18n.more.bookWorkspace.apply}</Button>
                </div>
            </div>
          </div>
        </div>
      )
    }
  }
}