import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";
import * as occupantUtil from "./occupantUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

const isValidArray = arr => Array.isArray(arr) && arr.length;

const addUnitEditsToTransaction = (layer, taskInfo, outTransactionInfo) => {
  const layerId = layer.layerId;
  const txnInfo = outTransactionInfo;

  const layerEdits     = { id:layerId, adds:[], updates:[], deletes:[] };
  const undoLayerEdits = { id:layerId, adds:[], updates:[], deteles:[] };

  const _clone = f => transactionUtil.cloneUnitFeature(taskInfo, f);

  if (isValidArray(taskInfo.newUnits)) {
    layerEdits.adds.push(...taskInfo.newUnits.map(_clone));
    undoLayerEdits.deletes = ["?"]; // populated after add
  }

  if (isValidArray(taskInfo.modifiedUnits)) {
    layerEdits.updates.push(...taskInfo.modifiedUnits.map(_clone));
  }
  if (isValidArray(taskInfo.originalUnits)) {
    undoLayerEdits.updates.push(...taskInfo.originalUnits.map(_clone));
  }
  if (isValidArray(taskInfo.deletedUnits)) {
    layerEdits.deletes.push(...taskInfo.deletedUnits.map(f => f.attributes[layer.objectIdField]));
    undoLayerEdits.adds.push(...taskInfo.deletedUnits.map(_clone));
  }    

  if (layerEdits.adds.length || layerEdits.updates.length || layerEdits.deletes.length) {
    txnInfo.unitEdits = true;
    txnInfo.undo.unitEdits = true;
    txnInfo.edits.push(layerEdits);
    txnInfo.undo.edits.push(undoLayerEdits);
  }
};

export async function prepare(task) {
  const { newUnits, modifiedUnits, originalUnits, deletedUnits } = task.info;
  if (!(newUnits || modifiedUnits || deletedUnits))
    throw new Error("Invalid args");

  const unitsLayer = sourceUtil.getUnitsLayer();
  const transactionInfo = transaction.makeTransactionInfo();
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  // unassign occupants if units are deleted or if the unit_id was changed
  const delUnitIds = [];
  if (isValidArray(modifiedUnits) && isValidArray(originalUnits)) {
    const unitIdField = aiimUtil.findFieldName(unitsLayer.fields,FieldNames.UNIT_ID);
    const asnTypeField = aiimUtil.findFieldName(unitsLayer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const unitIdsByOid = {};
    originalUnits.forEach(f => {
      const oid = f.attributes[unitsLayer.objectIdField];
      const unitId = f.attributes[unitIdField];
      unitIdsByOid[oid] = unitId;
    })
    modifiedUnits.forEach(f => {
      const oid = f.attributes[unitsLayer.objectIdField];
      const unitId = f.attributes[unitIdField];
      const originalUnitId = unitIdsByOid[oid];
      const changed = (unitId !== originalUnitId);
      if (changed && unitId && originalUnitId && delUnitIds.indexOf(originalUnitId) === -1) {
        delUnitIds.push(originalUnitId);
        const asnType = f.attributes[asnTypeField];
        if (asnType === "office") {
          f.attributes[asnTypeField] = "none";
        }
      }
    })
  }
  if (isValidArray(deletedUnits)) {
    const unitIdField = aiimUtil.findFieldName(unitsLayer.fields,FieldNames.UNIT_ID); 
    deletedUnits.forEach(f => {
      const unitId = f.attributes[unitIdField];
      if (unitId && delUnitIds.indexOf(unitId) === -1) delUnitIds.push(unitId);
    })
  }
  if (delUnitIds.length > 0) {
    await occupantUtil.removeOccupantAssignments(transactionInfo,delUnitIds);
  }

  if (isValidArray(newUnits)) {
    const fieldName = aiimUtil.findFieldName(unitsLayer.fields, FieldNames.UNIT_ID); 
    const uids = newUnits.map(f => f.attributes[fieldName]).filter(x=>!!x);
    const dupes = await transactionUtil.checkUniqueIds(uids, unitsLayer, fieldName);
    if (isValidArray(dupes) && dupes.length) {
      const i18n = Context.getInstance().i18n;
        if(dupes.length>1) {
          throw new Error(`${i18n.editor.units.errNonUniqueIds} [${dupes}]`);
        } else {
          throw new Error(`${i18n.editor.units.errNonUniqueId} [${dupes}]`);
        }
    }
  }

  if (isValidArray(modifiedUnits)) {
    const fieldName = aiimUtil.findFieldName(unitsLayer.fields, FieldNames.UNIT_ID); 
    const uids = modifiedUnits.map(f => f.attributes[fieldName]).filter(x=>!!x);
    const origAttr = originalUnits && originalUnits[0] && originalUnits[0].attributes;
    const uidOrig = aiimUtil.getAttributeValue(origAttr, fieldName);
    if(!uids.includes(uidOrig)) {
      const dupes = await transactionUtil.checkUniqueIds(uids, unitsLayer, fieldName);
      if (isValidArray(dupes) && dupes.length) {
        const i18n = Context.getInstance().i18n;
        if(dupes.length>1) {
          throw new Error(`${i18n.editor.units.errNonUniqueIds} [${dupes}]`);
        } else {
          throw new Error(`${i18n.editor.units.errNonUniqueId} [${dupes}]`);
        }
      }
    }
  }

  addUnitEditsToTransaction(unitsLayer, task.info, transactionInfo);

  return transactionInfo;
}
