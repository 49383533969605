import Context from "../../context/Context";
import NetworkService from "./NetworkService";

export default class ClosestFacilityService extends NetworkService {

  info = {};

  getLayerUrl() {
    return this.info.layerUrl;
  }

  load() {
    const promise = new Promise((resolve,reject) => {
      const task = {
        isClosestFacility: true,
        itemId: null,
        item: null,
        serviceUrl: null,
        serviceInfo: null,
        layerName: null,
        layerUrl: null,
        layerInfo: null
      };
      const defaultLayerName = "Closest Facility";
      const config = Context.instance.config;

      if (config.closestFacilityService && config.closestFacilityService.itemId) {
        task.itemId = config.closestFacilityService.itemId;
        task.layerName = config.closestFacilityService.layerName;
      } else if (config.closestFacilityServiceUrl) {
        const info = this._parseUrl(config.closestFacilityServiceUrl,defaultLayerName);
        Object.assign(task,info);
      }

      this._load(task).then(() => {
        if (task.layerUrl) config.closestFacilityServiceUrl = task.layerUrl;
        this.info = task;
        resolve();
      }).catch(ex => {
        // TODO inform user?
        console.warn("Error loading Closest Facility service");
        resolve();
      });
    });
    return promise;
  }

}
