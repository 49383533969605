import React from "react";

import ModalController from "../../common/Modal/ModalController";
import SDPWrapper from "./SDPWrapper";
import * as component from "../../util/component";

export default class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.onDateChange = this.onDateChange.bind(this);
  }

  onDateChange(date) {
    if (date !== null && date !== undefined) {
      if (this.props.onDateChange) this.props.onDateChange(date);
    }
  }

  render() {
    return (
      <SDPWrapper 
        initialDate={this.props.initialDate} 
        onDateChange={this.onDateChange}
        outsideRange={this.props.isOutsideRange}
      />
    );
  }

  static showModal(initialDate, onDateChange, isOutsideRange) {
    let controller = new ModalController();
    const _onDateChange = (_date) => {
      controller.close();
      if (typeof onDateChange === "function") {
        onDateChange(_date);
      }
    };
    const content = (
      <Calendar 
        initialDate={initialDate} 
        onDateChange={_onDateChange} 
        isOutsideRange={isOutsideRange} 
      />
    );
    controller.show(content);
  }

}
