import React from "react";

import * as component from "../../../components/util/component";
import { StatWrapper } from "../../styles/statsStyles";

export default class GraphicStat extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    let cls = "i-graphicstat";
    //if (this.props.danger) cls += " i--danger";
    return (
      <StatWrapper>
        <span className={cls}>
          <div className="i-flex i-align-items-center">
            {this.props.icon}
            <div>
              <div className="i-graphicstat-info">
                <div key="v" className="i-areastat-info-value">
                  {this.props.value}
                </div>
              </div>
            </div>
          </div>
          <div key="l" className="i-graphicstat-info-label">
            {this.props.label}
          </div>
        </span>
      </StatWrapper>
    );
  }
}
