import React from "react";

import Context from "../../../context/Context";
import Issues from "../common/Issues";
import ItemBrowser from "../../../components/common/ItemBrowser/ItemBrowser";
import {ModalController} from "../../../common/components/Modal";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";
//import * as val from "../../../util/val";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  issues: "i-issues",
  issue: "i-issue",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  itemTitle: "i-item-title",
  //secondaryAction: "i-button i-button-clear",
  secondaryAction: "i-button-clear-no-border i-font-brand-primary"
};

export default class WebMapSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      viewsReloading: false
    });
    this.browseClicked = this.browseClicked.bind(this);
  }

  browseClicked() {
    const controller = new ModalController();
    const props = {
      itemType: "Web Map",
      rdxKey: "cfg-webmap",
      onSelect: (item) => {
        const cfg = this.props.cfg;
        cfg.webmapItem = item;
        cfg.setConfigurable("webmap",item.id);
        Topic.publish(Topic.ReloadViews,{});
        controller.close();
      }
    }
    const content = (<ItemBrowser {...props}/>);
    const modalProps = {
      title: Context.instance.i18n.configurator.primary.webmap,
      className: "i-configurator-modal"
    };
    controller.show(modalProps,content);
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.Configurator_BrowseForMap,params => {
        this.browseClicked();
      }),
      Topic.subscribe(Topic.ReloadViews,params => {
        this.setState(state => {
          return {
            viewsReloading: true
          };
        });
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.setState(state => {
          return {
            viewsReloading: false
          };
        });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const cfg = this.props.cfg;
    const item = cfg.webmapItem;

    let browseLabel = i18n.configurator.add;
    let itemTitleNode = null;
    if (item) {
      browseLabel = i18n.configurator.change;
      const itemTitle = (item && item.title) || "";
      itemTitleNode = (
        <div className={CSS.itemTitle} key="itemTitle">{itemTitle}</div>
      );
    }

    const issues = this.renderIssues();
    const detailsLink = configUtil.renderDetailsLink(item);

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName+" "+CSS.required}
            >{i18n.spaceplanner.configurator.webmap}</span>
        </div>
        {itemTitleNode}
        {issues}
        <div key="actions" className={CSS.actions}>
          <button type="button" className={CSS.secondaryAction}
            onClick={this.browseClicked}>{browseLabel}</button>
          {detailsLink}
        </div>
      </div>
    );
  }

  renderIssues() {
    if (this.state.viewsReloading) return null;
    const planner = Context.instance.spaceplanner.planner;
    const project = planner && planner.project;
    if (project && project.hasIssues()) {
      return <Issues issues={project.issues} />
    }
    return null;
  }

}
