import BaseClass from "../../util/BaseClass"
import Context from "../../context/Context";
import DirectionsUtil from "../../util/DirectionsUtil";
import RouteResult from "../../components/main/Directions/RouteResult";
import * as aiimUtil from "../util/aiimUtil";

export default class NetworkService extends BaseClass {

  directionsUtil = new DirectionsUtil();

  isRoutable(feature2) {
    const promise = new Promise((resolve,reject) => {
      let feature1;
      const lib = Context.getInstance().lib;
      const levels = Context.getInstance().aiim.datasets.levels;
      if(levels){
        let centroid = levels.getFirstCentroid();
        if(centroid){
          feature1 = new lib.esri.Graphic({
            geometry : centroid.clone(),
            attributes: {
              name:"startPoint"
            }
          })

          if(!feature1.geometry.hasZ){
            feature1.geometry.z = 0;
          }
        }
      }
      if(!feature1){
        resolve(true);
      }
      let url = this.getRouteServiceUrl();
      feature2 = new lib.esri.Graphic({
        geometry : feature2.geometry.clone(),
        attributes : feature2.attributes
      })

      if(!feature2.geometry.hasZ){
        feature2.geometry.z = 0;
      }

      const stop1 = feature1;
      const stop2 = feature2;
      if (!url || !stop1 || !stop2) {
        resolve();
        return;
      }
      url += "/solve";
      this.readRouteServiceJson().then(serviceInfo => {
        //console.log("================= readRouteServiceJson2",serviceInfo);

        let stops = JSON.stringify({features:[stop1,stop2]});
        let params = {
          f: "json",
          directionsLanguage: "en-us",
          directionsOutputType: "esriDOTFeatureSets",
          directionsStyleName: "NA Campus",
          doNotLocateOnRestrictedElements: true,
          findBestSequence: false,
          ignoreInvalidLocations: true,
          outputLines: "esriNAOutputLineTrueShapeWithMeasure",
          outSR: 102100,
          preserveFirstStop: true,
          preserveLastStop: true,
          restrictUTurns: "esriNFSBAtDeadEndsAndIntersections",
          returnBarriers: false,
          returnDirections: false,
          returnPolygonBarriers: false,
          returnPolylineBarriers: false,
          returnRoutes: true,
          returnStops: true,
          returnZ: true,
          startTime: Date.now(),
          startTimeIsUTC: true,
          useHierarchy: true,
          useTimeWindows: false,
          stops: stops
        };

        const travelMode = this.findTravelMode(serviceInfo);

        if (travelMode) {
          // solving the route will fail if there is no travel mode
          params.travelMode = JSON.stringify(travelMode);
        }
        const mapView = Context.getInstance().views.mapView;

        if (mapView && mapView.spatialReference && mapView.spatialReference.wkid) {
          params.outSR = mapView.spatialReference.wkid;
        }

        const options = {query: params, method: "post", responseType: "json"};

        lib.esri.esriRequest(url,options).then(response => {
          let routeresult = new RouteResult();
          let route = response.data.routes.features[0];
          const graphic = lib.esri.Graphic.fromJSON(route);
          let lastReachablePoint = routeresult._getLastPoint(graphic);
          let pt1 ={};
          let pt2 ={};
          pt1 = {
            x : lastReachablePoint.x,
            y : lastReachablePoint.y,
            z : 0
          }

          pt2 = {
            x : feature2.geometry.x,
            y : feature2.geometry.y,
            z : 0
          }

          let distance = 0;
          if (pt1 && pt2) {
              distance = Math.sqrt(
              Math.pow((pt2.x - pt1.x),2) + Math.pow((pt2.y - pt1.y),2) + Math.pow((pt2.z - pt1.z),2)
              )
          }

          //Threshold set to 50metres
          if(distance < 50){
            resolve(true);
          }else{
            resolve(false);
          }
        }).catch(ex => {
          reject(ex);
        });
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  estimateDistance(feature) {
    const promise = new Promise((resolve,reject) => {
      const lib = Context.getInstance().lib;
      let url = this.getRouteServiceUrl();
      const stop1 = this.getHomeStop(1);
      const stop2 = this.featureToStop(feature,2);
      if (!url || !stop1 || !stop2) {
        resolve();
        return;
      }
      url += "/solve";
      this.readRouteServiceJson().then(serviceInfo => {
        //console.log("================= readRouteServiceJson2",serviceInfo);
        let uiLengthUnits = "esriMeters";
        if (lib.dojo.kernel.locale === "en-us") {
          uiLengthUnits = "esriFeet";
        }
        const lengthAttribute = "Cumul_Length";
        const timeAttribute = "Cumul_" + serviceInfo.timeAttributeName;
        const lengthUnits = serviceInfo.serviceLengthUnits;
        const timeUnits = serviceInfo.serviceTimeUnits;

        let stops = JSON.stringify({features:[stop1,stop2]});
        let params = {
          f: "json",
          directionsLanguage: "en-us",
          directionsLengthUnits: lengthUnits,
          directionsOutputType: "esriDOTFeatureSets",
          directionsStyleName: "NA Campus",
          doNotLocateOnRestrictedElements: true,
          findBestSequence: false,
          ignoreInvalidLocations: true,
          outputLines: "esriNAOutputLineTrueShapeWithMeasure",
          outSR: 102100,
          preserveFirstStop: true,
          preserveLastStop: true,
          restrictUTurns: "esriNFSBAtDeadEndsAndIntersections",
          returnBarriers: false,
          returnDirections: false,
          returnPolygonBarriers: false,
          returnPolylineBarriers: false,
          returnRoutes: false,
          returnStops: true,
          returnZ: true,
          startTime: Date.now(),
          startTimeIsUTC: true,
          useHierarchy: true,
          useTimeWindows: false,
          stops: stops
        };
        const travelMode = this.findTravelMode(serviceInfo);
        if (travelMode) {
          // solving the route will fail if there is no travel mode
          params.travelMode = JSON.stringify(travelMode);
        }
        const mapView = Context.getInstance().views.mapView;
        if (mapView && mapView.spatialReference && mapView.spatialReference.wkid) {
          params.outSR = mapView.spatialReference.wkid;
        }
        const options = {query: params, method: "post", responseType: "json"};

        lib.esri.esriRequest(url,options).then(response => {
          const estimate = {travelMode: travelMode};
          const result = response && response.data;
          const rStops = result && result.stops && result.stops.features;
          if (rStops && rStops.length === 2) {
            const dest = rStops[1];
            if (dest && dest.attributes) {
              const length = aiimUtil.getAttributeValue(dest.attributes,lengthAttribute);
              const time = aiimUtil.getAttributeValue(dest.attributes,timeAttribute);
              if (typeof length === "number" && typeof time === "number") {
                const sLength = this.directionsUtil.formatCFDistance(length,lengthUnits,uiLengthUnits);
                const sTime = this.directionsUtil.formatCFTime(time,timeUnits);
                estimate.lengthText = sLength;
                estimate.timeText = sTime;
              }
            }
          }
          resolve(estimate);
        }).catch(ex => {
          //if(ex.message === "Error invoking service") resolve();
          reject(ex)
         // else reject(ex)
        });
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  featureToStop(feature,objectId) {
    let point, stop;
    if (feature && feature.geometry) {
      if (feature.geometry.type === "point") {
        point = feature.geometry;
      } else if (feature.geometry.type === "polygon") {
        point = feature.geometry.centroid;
      }
      if (point && !point.hasZ) {
        point = point.clone();
        point.z = 0;
        point.hasZ = true;
      }
      if (point) {
        stop = {
          geometry: point.toJSON(),
          attributes: {
            "OBJECTID": objectId
          }
        }
      }
    }
    return stop;
  }

  findTravelMode(serviceInfo) {
    const find = (modes,id) => {
      let found;
      if (Array.isArray(modes) && id !== undefined && id !== null) {
        modes.some(mode => {
          if (id === mode.itemId) {
            found = mode;
            return true;
          }
          return false;
        });
      };
      return found;
    };
    const serviceDesc = serviceInfo && serviceInfo.serviceDescription;
    const modes = serviceDesc && serviceDesc.supportedTravelModes;
    const defaultID = serviceDesc && serviceDesc.defaultTravelMode;
    const selectedID = Context.getInstance().session.selectedTravelModeID;
    let travelMode;
    if (!travelMode) travelMode = find(modes,selectedID);
    if (!travelMode) travelMode = find(modes,defaultID);
    if (!travelMode && modes && modes.length > 0) {
      travelMode = modes[0];
    }
    return travelMode;
  }

  getHomeStop(objectId) {
    const referenceLayer = Context.getInstance().session.referenceLayer;
    const item = referenceLayer.homeLocation;
    if (item && item.isValid()) {
      return this.featureToStop(item.getFeature(),objectId);
    }
  }

  getRouteServiceUrl() {
    let url = Context.getInstance().config.networkServiceUrl;
    if (url) url = Context.checkMixedContent(url);
    if (typeof url === "string" && url.length > 0) {
      const idx = url.lastIndexOf("/Route");
      if (idx === -1 || idx !== (url.length - 6)) {
        // url += "/Route";
      }
    }
    return url;
  }

  readRouteServiceJson() {
    const promise = new Promise((resolve,reject) => {
      let serviceInfo = Context.getInstance().session.networkServiceInfo;
      if (serviceInfo) {
        resolve(serviceInfo);
        return;
      }
      const url = this.getRouteServiceUrl();
      if (url) {
        aiimUtil.readServiceJson(url).then(result => {
          //console.log("================= readRouteServiceJson",result);
          const serviceDescription = result && result.data;
          if (serviceDescription) {
            serviceInfo = {
              serviceDescription: serviceDescription,
              serviceLengthUnits: "esriNAUMeters",
              serviceTimeUnits: "esriNAUMinutes",
              timeAttributeName: "WalkTime",
              travelMode: null
            };
            const timeAttribute = this.directionsUtil.getDirectionsTimeAttribute(serviceInfo);
            if (timeAttribute) {
              if (timeAttribute.name) {
                serviceInfo.timeAttributeName = timeAttribute.name;
              }
              if (timeAttribute.units) {
                serviceInfo.serviceTimeUnits = timeAttribute.units;
              }
            }
            serviceInfo.serviceLengthUnits = serviceDescription.directionsLengthUnits;
            if (serviceDescription.defaultTravelMode && serviceDescription.supportedTravelModes) {
              serviceDescription.supportedTravelModes.some(travelMode => {
                if (serviceDescription.defaultTravelMode === travelMode.itemId) {
                  serviceInfo.travelMode = travelMode;
                  return true;
                }
                return false;
              });
            }
            Context.getInstance().session.networkServiceInfo = serviceInfo;
          }
          resolve(serviceInfo);
    }).catch((error) => {
        reject(error);
    })
  }else{
    resolve();
  }
})
    return promise;
  }
} 
