import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import * as aiimUtil from "../util/aiimUtil";
import * as searchUtil from "../util/searchUtil";
import * as selectionUtil from "../util/selectionUtil";
import type { CustomQueryTask } from "../../context/EsriLib";
import {
  getPortalUserByEmail,
  getUsernameFromPortalUsers
} from "../../spaceplanner/base/ReviewerManagement/reviewersUtil";

interface IAppUserAssignments {
  occupantFeatures: __esri.Graphic[],
  areaIDs: string[],
  unitIDs: string[]
}
export default class People extends Dataset {

  areaIDField = FieldNames.PEOPLE_AREA_ID;
  emailField = FieldNames.PEOPLE_EMAIL;
  fullNameField = FieldNames.PEOPLE_FULLNAME;
  unitIDField = FieldNames.PEOPLE_UNIT_ID;
  type = "feature";

  checkSchema() {
    const promise = new Promise<void>((resolve,reject) => {
      if (this.layer2D) {
        const layer = this.layer2D;
        layer.when(() => {
          this.checkFieldNameProperty("areaIDField",layer.fields);
          this.checkFieldNameProperty("emailField",layer.fields);
          this.checkFieldNameProperty("fullNameField",layer.fields);
          this.checkFieldNameProperty("unitIDField",layer.fields);
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }
  /** Retrieves an occupant from the feature layer that matches the full name and username. */
  async getOccupantByFullName(username: string, fullName: string): Promise<__esri.Graphic> {
    if (!this.url) return;
    if (typeof fullName === "string" && fullName.length > 0) {
      fullName = fullName.toUpperCase();
    } else {
      return;
    }
    const layer = this.layer2D;
    const query = layer.createQuery();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = `UPPER(${this.fullNameField}) = '${selectionUtil.escSqlQuote(fullName)}'`;
    const result = await layer.queryFeatures(query);
    const features = result?.features || [];
    const uniqueEmails = new Set<string>(features.map(f => aiimUtil.getAttributeValue(f.attributes, this.emailField)));
    return this.matchOccupant(features, username, uniqueEmails);
  }
  /** Retrieves occupants from the feature layer that matches each username/fullname combination provided. */
  async getOccupantsByFullName(names: Record<string, string>) {
    if (!this.url) return;

    const fullNames = Object.values(names).map(fullName => fullName.toUpperCase());
    const layer = this.layer2D;
    const query = layer.createQuery();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = `UPPER(${this.fullNameField}) IN (${fullNames.map(fullName => `'${selectionUtil.escSqlQuote(fullName)}'`).join(",")})`;
    const result = await layer.queryFeatures(query);
    const features = result?.features || [];
    const matched: __esri.Graphic[] = [];
    for (const username in names) {
      const byName = features.filter(f => aiimUtil.getAttributeValue(f.attributes, this.fullNameField) === names[username]);
      const uniqueEmails = new Set<string>(byName.map(f => aiimUtil.getAttributeValue(f.attributes, this.emailField)));
      matched.push(await this.matchOccupant(byName, username, uniqueEmails));
    }
    return matched;
  }
  getSource() {
    return Context.getInstance().aiim.datasets.categories.findSourceByKey("People"); // TODO
  }

  getUnitID(peopleFeature) {
    return aiimUtil.getAttributeValue(peopleFeature.attributes,this.unitIDField);
  }

  isPeopleLayer(layer,fields) {
    if (fields) {
      let count = 0;
      const matched = fields.some((field) => {
        const lc = field.name.toLowerCase();
        if (lc === FieldNames.PEOPLE_FULLNAME.toLowerCase() ||
            lc === FieldNames.PEOPLE_EMAIL.toLowerCase()) {
          count++;
          if (count >= 2) return true;
        }
        return false;
      });
      return matched;
    }
    return false;
  }
  async matchOccupant(features: __esri.Graphic[], username: string, uniqueEmails: Set<string>) {
    if (features?.length === 1) {
      return features[0];
    } else if (features?.length > 1) {
      // if multiple occupants match full name but with multiple emails, query portal users and try to match on username
      if (uniqueEmails.size > 1) {
        const emails = features.map(async occupant => {
          const email = aiimUtil.getAttributeValue(occupant.attributes, this.emailField);
          return Promise.resolve({ occupant, portalResult: await getPortalUserByEmail({ email }) });
        });
        let match;
        for await (const result of emails) {
          const { occupant, portalResult } = result;
          const user = getUsernameFromPortalUsers(portalResult, occupant);
          if (user === username) {
            match = occupant;
            break;
          }
        };
        return match;
      } else if (features?.length > 1 && uniqueEmails.size === 1) {
        // if multiple occupants but only one unique email then choose the first
        return features[0];
      }
    }
  }
  queryAppUserAssignments() {
    const promise = new Promise<IAppUserAssignments>((resolve,reject) => {
      const url = this.url;
      const areaIDField = this.areaIDField;
      const emailField = this.emailField;
      const fullNameField = this.fullNameField;
      const unitIDField = this.unitIDField;
      let email = Context.instance.user.getEmail();
      let fullName = Context.instance.user.getFullName();
      let source = this.getSource();
      let matchFullName = false;
      let features: __esri.Graphic[], areaIDs: string[] = [], unitIDs: string[] = [];
      let info: IAppUserAssignments = {
        occupantFeatures: features,
        areaIDs: areaIDs,
        unitIDs: unitIDs
      }

      //fullName = "Victor Welch";
      //email = "victor.welch@esri.com";
      //areaIDs.push("hotel-dabf794839d440f3984e3c74d36dfc13");

      if (typeof email !== "string") {
        resolve(info);
        return;
      }
      let fld = "UPPER("+emailField+")"; // case insensitive match
      let uc = email.toUpperCase();

      searchUtil.queryFieldValue(source,url,fld,uc).then(result => {
        features = result && result.features;
      }).then(() => {
        if (features && features.length > 0 && matchFullName) {
          features = features.filter(f => {
            let v = aiimUtil.getAttributeValue(f.attributes,fullNameField);
            if (fullName && (v === fullName)) {
              return true;
            }
            return false;
          })
        }
      }).then(() => {
        if (features) {
          features.forEach(f => {
            let areaID = aiimUtil.getAttributeValue(f.attributes,areaIDField);
            let unitID = aiimUtil.getAttributeValue(f.attributes,unitIDField);
            if (areaID && (areaIDs.indexOf(areaID) === -1)) areaIDs.push(areaID);
            if (unitID && (unitIDs.indexOf(unitID) === -1)) unitIDs.push(unitID);
          })
        }
      }).then(() => {
        info.occupantFeatures = features;
        resolve(info);
        //console.log("queryAppUserAssignments",info);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  async queryOccupantAreaIds(email: string, includeUnrestricted: boolean, matchAreaIds: string[]) {
    if (!this.url) return;
    if (typeof email === "string" && email.length > 0) {
      email = email.toUpperCase();
    } else {
      return;
    }
    let areaIds: string[] = [];
    const areaIDField = this.areaIDField;
    const emailField = this.emailField;
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task: CustomQueryTask = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = [areaIDField];
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = "UPPER("+emailField+") = '"+selectionUtil.escSqlQuote(email)+"'";
    const result = await task.execute(query);
    if (result && result.features) {
      result.features.forEach(f => {
        const areaId = aiimUtil.getAttributeValue(f.attributes,areaIDField);
        if (areaId && !areaIds.includes(areaId)) areaIds.push(areaId);
      })
    }
    if (includeUnrestricted) {
      const areas = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas;
      if (areas) {
        const ids = await areas.queryUnrestrictedIDs();
        if (ids) {
          ids.forEach(areaId => {
            if (areaId && !areaIds.includes(areaId)) areaIds.push(areaId);
          })
        }
      }
    }
    if (matchAreaIds) {
      areaIds = areaIds.filter(areaId => {
        return matchAreaIds.includes(areaId);
      })
    }
    return areaIds;
  }

}
