import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { IFilterCriteria, IFilterField } from "../../common/types";
import UniqueValues from "./UniqueValues";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as component from "../../../../components/util/component";
import * as filterUtil from "./filterUtil";
import * as whereUtil from "./whereUtil";

interface Props {
  field: string;
  filterCriteria: IFilterCriteria;
  layerType: string;
  onChange: (values: [], where?: string) => void;
  values?: any;
}

interface State {
  label: string;
  lastWhere?: string;
  uniqueValues?: any;
}

class UniqueValueFilter extends React.Component<Props, State> {

  asyncStamp;

  constructor(props) {
    super(props);
    const { layerType, field } = this.props;
    const label = filterUtil.getFieldLabel(layerType,field);
    this.state = component.newState({
      label: label,
      lastWhere: null,
      uniqueValues: []
    });
  }

  componentDidMount() {
    this.query();
  }

  componentDidUpdate(prevProps) {
    const fc = this.props.filterCriteria;
    if (prevProps.filterCriteria !== fc) {
      const { field, filterCriteria } = this.props;
      const where = whereUtil.makeFilterWhere(filterCriteria,field);
      if (where !== this.state.lastWhere) this.query();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  query = async () => {
    try {
      const stamp = this.asyncStamp = Date.now();
      const { layerType, field, filterCriteria } = this.props;
      const fld = filterUtil.getField(layerType,field);
      if (fld) {
        const where = whereUtil.makeFilterWhere(filterCriteria,field);
        const uniqueValues = await filterUtil.queryUniqueValues(layerType,field,where);
        if (stamp === this.asyncStamp) {
          this.setState({
            uniqueValues,
            lastWhere: where
          });
        }
      }
    } catch(ex) {
      console.error(ex)
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { layerType, field, values } = this.props;
    const { label, uniqueValues } = this.state;
    if (label && uniqueValues) {
      //console.log("uvf.values",label,values)
      return (
        <UniqueValues 
          label={label}
          uniqueValues={uniqueValues} 
          values={values}
          onChange={values => {
            const where = whereUtil.makeFieldWhere(layerType,field,values)
            this.props.onChange(values,where);
          }}
        />
      )
    }
    return null;
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(UniqueValueFilter);
