import styled, { css } from "styled-components";

import CalciteComboButton from "calcite-react/ComboButton";
import CalciteButton from "calcite-react/Button";

const ComboButton = styled(CalciteComboButton)`
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled(CalciteButton)`
  ${props =>
    props.noWrap &&
    css`
      margin-block-start: 0;
      margin-inline-start: 0.775rem;
    `};
`;

export { ComboButton, Button };
