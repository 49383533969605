import React from "react";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";
import Context from "../../../../src/context/Context";

const CSS = {
  main: "i-toggle"
};

export default class Toggle extends React.Component {

  onChange = (evt) => {
    const v = evt.target.checked;
    if (this.props.onChange) this.props.onChange(v);
  }

  render() {
    const isSpacePlanner = Context.instance.appMode.isSP();
    if(isSpacePlanner) CSS.main="i-toggle-spaceplanner";
    return (
      <span className={CSS.main}>
        <ReactToggle id={this.props.id}
          defaultChecked={this.props.checked}
          onChange={this.onChange}
          icons={false} />
      </span>
    );
  }

}
