import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { isSameDay } from "react-dates";

import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import DateTime, { OnPartChanged } from "./DateTime";
import Duration from "./Duration";
import EsriReservationSchema from "../More/Actions/BookWorkspace/WorkspaceReservation/EsriReservationSchema";
import FieldNames from "../../../aiim/datasets/FieldNames";
import ItemReference from "../../../aiim/base/ItemReference";
import Loader from "calcite-react/Loader/Loader";
import Tooltip from "calcite-react/Tooltip";
import ModalController from "../../../common/components/Modal/ModalController";
import Schedule from "./Schedule";
import TextField from "calcite-react/TextField";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as dateUtil from "../Events/dateUtil";
import * as ics from "../Events/ics";
import MeetingBookingConfirmed from "./MeetingBookingConfirmed";
import BookingConfirmed from "../More/Actions/BookWorkspace/BookingConfirmed";
import { BookingType, makeEventInfo } from "../../../util/calendarUtil";
import { formatRecurringDates, getMeetingBookingSystem, getMeetingBookingSystemType, IReservationInfo } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as validateUtil from "../More/Actions/BookWorkspace/validateUtil";
import Topic from '../../../context/Topic';
import TextareaAutosize from 'react-textarea-autosize';
import { IMeetingRoom, IMeetingRoomCriteria } from "./MeetingRoomsModel";
import { IBookingTask, IEsriBookingTask, IOffice365BookingTask, IOriginalReservation, IReserveForInfo } from "../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import type Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import { IFeature } from "@esri/arcgis-rest-feature-layer";
import BookingRecurrence, { displayRecurrence, IRecurrenceSeries } from "../More/Actions/BookWorkspace/BookingRecurrence";
import { recurringSeriesMatch } from "../More/Actions/BookWorkspace/MyBookings";
import UserIcon from "calcite-ui-icons-react/UserIcon";
interface IBookProps {
  criteria: IMeetingRoomCriteria,
  item: IMeetingRoom,
  onMount: (book: Book) => void,
  originalReservation: IOriginalReservation,
  recurrenceType?: BookingType,
  reserveForInfo?: IReserveForInfo
}
interface IBookState {
  description: string,
  descriptionCharCount: number,
  title: string,
  titleError: string,
  working: boolean,
  when: dateUtil.IDuration
}
interface IMeetingRoomCreateTask {
  bookComponent: Book,
  controller: ModalController,
  criteria: IMeetingRoomCriteria,
  item: IMeetingRoom,
  reservation?: IFeature,
  status: { executing: boolean },
  reserveForInfo?: IReserveForInfo
}
interface IMeetingRoomUpdateTask extends IMeetingRoomCreateTask {
  originalReservation: IOriginalReservation,
  recurrenceType: BookingType
}
export default class Book extends React.Component<IBookProps, IBookState> {

  componentId;
  durationComponent: Duration;
  startComponent: DateTime;
  endComponent: DateTime;
  isOngoingBooking = false;
  maxLenO365Desc = 4000;
  modalOkButton: Button;

  constructor(props: IBookProps) {
    super(props);
    this.componentId = component.nextId("book-");
    let title = null;
    if(this.props.criteria && this.props.criteria.operation === "updateBookingTime") {
      title = this.props && this.props.item && this.props.item.title; 
    }
    this.state = component.newState({
      title: title || "",
      titleError: null,
      description: null,
      descriptionCharCount: 0,
      working: false,
      when: {
        ...props.criteria.when,
        start: { ...props.criteria.when.start },
        end: { ...props.criteria.when.end },
        recurrence: { ...props.criteria.when.recurrence }
      }
    });
  }

  override componentDidMount() {
    this.props.onMount && this.props.onMount(this);
    this._checkModalOKButton();
  }

  override componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  override componentDidUpdate(prevProps: IBookProps, prevState: IBookState) {
    const { when, title } = this.state;
    const { criteria } = this.props;
    if (prevProps.recurrenceType !== this.props.recurrenceType || 
      when.start?.date !== criteria.when.start?.date ||
      when.end?.date !== criteria.when.end?.date ||
      when.duration !== criteria.when.duration ||
      ((title !== prevState.title) && (title !== criteria.title)) ||
      !recurringSeriesMatch(when.recurrence, criteria.when.recurrence)) {
      this._checkModalOKButton();
    }
  }

  getDescriptionFieldLength=()=>{
    const bookingSystemType = getMeetingBookingSystemType();
    if (bookingSystemType === "office365") {
      return this.maxLenO365Desc;
    } else {
      const reservation = Context.instance.aiim.datasets.reservations;
      const fields = reservation && reservation.layer2D && reservation.layer2D.fields;
      if (!fields) return 0;
      const field = aiimUtil.findField(fields,FieldNames.DESCRIPTION);
      return field.length;
    }
  }

  _checkModalOKButton() {
    const i18n = Context.instance.i18n;
    const { recurrence, start, end } = this.state.when;

    if (!this.modalOkButton)
      return true;
    let btnNode = ReactDOM.findDOMNode(this.modalOkButton);
    if (btnNode) {
      btnNode.title = "";
      btnNode.style.setProperty("pointer-events", "auto");
    }
    const origWhen = Book.getOrigWhen(this.props.originalReservation, this.state.when, this.props.recurrenceType);
    let newCriteria: Partial<IMeetingRoomCriteria> = {};
    const sameDayBooking = isSameDay(moment(start.date), moment(end.date));
    const weeklyMissingDays = recurrence?.enabled && recurrence?.type === "weekly" ? recurrence.days.length === 0 : false;
    let ok = !recurrence?.enabled || (sameDayBooking && !weeklyMissingDays);
    let btnTitle = !ok && i18n.meetingRooms.book.invalidEndDate;

    if (!origWhen && ok) {
      this.modalOkButton.enable();
      return true;
    }

    if (ok) {
      ok = this.validate(newCriteria);
    }

    if (ok) {
      ok = this.props.criteria.when.recurrence?.enabled && this.props.recurrenceType === "series"
        ? newCriteria.when.recurrence.endDate?.getTime() > Date.now()
        : newCriteria.when.end.date.getTime() > Date.now();
      btnTitle = !ok ? i18n.meetingRooms.book.invalidEndDate : btnTitle;
    }

    if (ok) {
      if (this.props.criteria.operation === "updateBookingTime" && recurrence?.enabled) {
        ok = hasChanges(newCriteria, this.props.criteria, this.props.originalReservation, this.props.recurrenceType);
        btnTitle = !ok ? `${i18n.meetingRooms.book.noChangesMsg}` : btnTitle;
      } else {
        ok = !(newCriteria.when.start.date.getTime()===origWhen.start && 
                newCriteria.when.end.date.getTime()===origWhen.end && newCriteria.title===origWhen.title);
        btnTitle = !ok ? `${i18n.meetingRooms.book.noChangesMsg}` : btnTitle;
      }
    }

    if (ok) {
      this.modalOkButton.enable();
      btnNode && (btnNode.title = "");
    } else {
      this.modalOkButton.disable()
      btnNode && (btnNode.title = btnTitle);
    }
    
    return ok;
  }

  render() {
    const i18n = Context.instance.i18n;
    const { criteria, recurrenceType } = this.props;
    const when = Object.assign({}, criteria.when);
    let disabled = false;
    let isOngoingBooking = false;
    const placeholder = i18n.meetingRooms.book.title;
    const { recurrence, start, end } = this.state.when;
    const sameDayBooking = isSameDay(moment(start.date), moment(end.date));
    const unitFeature = this.props?.item?.feature;
    let equipmentStr = null;
    const equipmentList = Context.instance.aiim.datasets.units.getEquipmentList(unitFeature, "DOM_EQUIPMENT_MEETING");
    if (equipmentList && equipmentList.length > 0 ) equipmentStr = equipmentList.join(', ');

    const onPartChanged: OnPartChanged = (c,type,dateMoment,timeMoment,prev) => {
      let c2 = this.endComponent;
      if (c === this.endComponent) c2 = this.startComponent;
      const newParts = c2.whenAssociatedPartChanged(c, type, dateMoment, timeMoment, this.durationComponent, prev);
      this.setState(prev => {
        const newStart = c === this.startComponent
          ? dateMoment.toDate()
          : newParts?.timeMoment ? newParts.timeMoment.toDate() : prev.when.start?.date;
        const newEnd = c === this.startComponent
          ? newParts?.timeMoment ? newParts.timeMoment.toDate() : prev.when.end?.date
          : dateMoment.toDate();

        // recurrence validation
        const endDate = recurrence?.enabled
          && this.props.recurrenceType !== "occurrence"
          && moment(newEnd).isSameOrAfter(prev.when.recurrence.endDate, "d")
          ? undefined
          : prev.when.recurrence.endDate;

        return {
          when: {
            ...prev.when,
            start: { date: newStart },
            end: { date: newEnd },
            recurrence: { ...prev.when.recurrence, endDate }
          }
        };
      });
    };

    const onDurationChanged = (val, oldVal) => {
      this.setState(prev => ({
        when: {
          ...prev.when,
          duration: val
        }
      }));
    }

    let loader;
    if (this.state.working) {
      loader = (
        <div style={{position:"absolute",width:"80%",top:"100px"}}>
          <Loader sizeRatio={1} style={{marginTop: "3rem"}} />
        </div>
      );
    }

    const maxLen = this.getDescriptionFieldLength();

    let pattern = i18n.item.categoryTypePattern;
    pattern = pattern.replace("{category}", this.state.descriptionCharCount);
    pattern = pattern.replace("{type}", maxLen);

    let showDesc = true;
    const { operation } = criteria;
    if (operation === "updateBookingTime") {
      showDesc = false;
      if (criteria.isOngoingBooking) {
        disabled = true;
        isOngoingBooking = true;
      }
    }
    const type = getMeetingBookingSystemType();
    const recurrenceUI = displayRecurrence({ type, operation, recurrenceType }) &&     
      <BookingRecurrence 
        {...recurrence}
        canToggle={operation !== "updateBookingTime"}
        startDate={this.state.when.end.date}
        disabled={!sameDayBooking}
        onUpdate={recurrence => {
          this.setState(prev => ({
            when: {
              ...prev.when,
              recurrence: {
                ...prev.when.recurrence,
                ...recurrence
              }
            }
          }));
        }}
      />
    return (
      <div className="i-filter-form">
        <div style={{marginBottom: "1.2rem"}}>
          <TextField type="text"
            placeholder={placeholder}
            error={!!this.state.titleError}
            title={this.state.titleError}
            value={this.state.title}
            onChange={evt => {
              let v = evt.target.value;
              this.setState({
                title: v,
                titleError: null
              });
            }}
          />
        </div>
        {showDesc && <div style={{marginBottom: "1.2rem"}}>
          <TextareaAutosize 
            placeholder={i18n.meetingRooms.book.descriptionMeeting}
            maxLength={maxLen}
            maxRows = {4}
            value={this.state.description ?? ""}
            onChange={evt => {
              let v = evt.target.value;
              this.setState({
                description: v,
                descriptionCharCount: v.length
              }, () => {
                if (v.length >= maxLen) {
                  Topic.publish(Topic.ShowToast, { message: i18n.meetingRooms.book.descriptionLimitToast })
                }
              });
            }}
            minRows={3}/>
            <div style={{fontSize: "0.8125rem"}}>{pattern}</div>
        </div>}
        {loader}
        <div>
          <Duration
            isOngoingBooking={isOngoingBooking}
            disabled={disabled}
            formKey={this.componentId}
            duration={when.duration}
            onChange={onDurationChanged}
            onMount={c => this.durationComponent = c}/>
        </div>
        <div>
          <DateTime
            formKey={this.componentId}
            isOngoingBooking={isOngoingBooking}
            disabled={disabled}
            duration={when.duration}
            label={i18n.meetingRooms.start}
            isStart={true}
            date={when.start.date}
            onMount={c => this.startComponent = c}
            onPartChanged={onPartChanged} />
        </div>
        <div>
          <DateTime
            formKey={this.componentId}
            duration={when.duration}
            label={i18n.meetingRooms.end}
            isEnd={true}
            date={when.end.date}
            startDate={when.start.date}
            onMount={c => this.endComponent = c}
            onPartChanged={onPartChanged} />
        </div>
        {!sameDayBooking
          ? <Tooltip placement="top" positionFixed targetWrapperStyle={{ display: "block" }}         
            title={i18n.calendars.recurringToggleDisableMeetingRoom}>{recurrenceUI}</Tooltip>
          : recurrenceUI
        }
        {this.renderReserveFor()}
        <div>
          <div>{i18n.meetingRooms.book.location}</div>
          <div
            style={{
              marginTop: "0.3rem"
            }}>
            {this.props.item.name}
          </div>
        </div>
        {equipmentStr && (
          <div style={{marginTop: "1.2rem", maxWidth: "80vh", width: "348px"}}>
            <div>{i18n.more.bookWorkspace.equipmentType}</div>
            <div 
              style={{
                marginTop: "0.3rem"
            }}>
              {equipmentStr}
            </div>
          </div>
        )}
        {/*
        <div style={{marginTop: "1.2rem"}}>
          <div>{i18n.meetingRooms.book.description}</div>
          <div>
            <TextField type="textarea"
              onChange={evt => {
                let v = evt.target.value;
                this.setState({
                  description: v
                });
              }}
            />
          </div>
        </div>
        */}
      </div>
    );
  }

  renderReserveFor() {
    const { reserveForInfo } = this.props;
    if (reserveForInfo) {
      let knownas = reserveForInfo.fullName;
      let email = "";
      if (!knownas) {
        const f = reserveForInfo.occupantFeature;
        knownas = aiimUtil.getAttributeValue(f.attributes, FieldNames.PEOPLE_FULLNAME);
        email = "(" + aiimUtil.getAttributeValue(f.attributes, FieldNames.PEOPLE_EMAIL) + ")";
      }
      return (
        <div className="i-reservefor-text">
          <span><UserIcon size={16}/></span>
          <span>{knownas}</span><span>{email}</span>
        </div>
      )
    }
    return null;
  }

  setStart(m,roundedMillis) {
    this.startComponent.setFromMoment(m,roundedMillis);
  }

  setWhen(when: dateUtil.IDurationAsMilliseconds) {
    this.durationComponent.setWhen(when.duration);
    this.startComponent.setWhen(when.start.date, when.duration);
    this.endComponent.setWhen(when.end.date, when.duration, when.start.date);
    this.setState(prev => ({
      when: {
        ...prev.when,
        start: { date: new Date(when.start.date) },
        end: { date: new Date(when.end.date) },
        recurrence: { ...when.recurrence }
      }
    }));
  }

  validate(criteria: Partial<IMeetingRoomCriteria>, whenOnly?: boolean) {
    const i18n = Context.instance.i18n;
    let ok = true;
    let when: dateUtil.IDuration = {
      duration: null,
      start: { date: null },
      end: { date: null },
      recurrence: null
    };

    let title = this.state.title;
    if (typeof title !== "string") title = "";
    title = title.trim();
    if (title.length === 0 && !whenOnly) {
      ok = false;
      this.setState({
        titleError: i18n.general.valueRequired
      })
    }

    let desc = this.state.description;
    if (typeof desc === "string") desc = desc.trim();

    if (ok) ok = this.durationComponent.validate(when);
    if (ok) ok = this.startComponent.validate(when.start);
    if (ok) ok = this.endComponent.validate(when.end);
    if (ok) {
      when.recurrence = this.state.when.recurrence;
    }
    if (ok) {
      if (when.start.date && when.end.date &&
         (when.start.date <= when.end.date)) {
        // also check isRequired?
      }
    }
    if (ok && when.start.date && when.end.date && when.duration) {
      if (when.duration === "allDay") {
        let dt1 = dateUtil.toStartOfDay(new Date(when.start.date));
        let dt2 = dateUtil.toEndOfDay(new Date(when.end.date));
        //let d = dateUtil.getDurationKey(dt1,dt2);
        when.start.date = dt1;
        when.end.date = dt2;
        //when.duration = d;
      } else {
        let dt1 = new Date(when.start.date);
        let dt2 = new Date(when.end.date);
        let d = dateUtil.getDurationKey(dt1,dt2);
        when.duration = d;
      }
    }
    if (ok) {
      criteria.title = title;
      criteria.description = desc;
      criteria.when = when;
    }
    return ok;
  }

  static async createBookingEsri(task: IMeetingRoomCreateTask) {
    task.bookComponent.setState({working: true});
    let itemRef;
    const ds = Context.instance.aiim.datasets.units;
    const source = ds && ds.getSource();
    const sr = source && source.makeSearchResult(null, task.item.feature);
    if (sr) {
      itemRef = new ItemReference();
      itemRef.fromSearchResult(source.key,sr)
    }
    
    const when = task.criteria.when;
    const bookingSystem = getMeetingBookingSystem();
    const item = task && task.item;
    const controller = task.controller;
    const params: IBookingTask = {
      title: task.criteria.title,
      description: Context.sanitizeHtml(task.criteria.description),
      checkInDate: new Date(when.start.date),
      checkOutDate: new Date(when.end.date),
      allDay: (when.duration === "allDay"),
      unit: item.feature,
      item: itemRef,
      unitName: item.name,
      bookingSystem: bookingSystem,
      bookingType: "meetingRooms",
      options: {
        recurrence: { ...when.recurrence },
        reserveForInfo: task.reserveForInfo        
      },
      renderBookingConfirmed: async (data: any, params: any, task2: IBookingTask) => {
        controller.close();
        const reservation = data.reservations && data.reservations.length > 0 && data.reservations[0];
        task.reservation = reservation;
        await Book.makeIcsInfo(task, reservation);

        const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
        const unitsDataset = Context.getInstance().aiim.datasets.units;
        const calendarParams = makeEventInfo(reservationsDataset, task.reservation, unitsDataset, item.feature, "addBooking");
        const i18n = Context.instance.i18n
        const lib = Context.instance.lib
        const levels = Context.instance.aiim.datasets.levels;
        let attr = task.reservation.attributes;
        let name = aiimUtil.getAttributeValue(attr,FieldNames.TITLE);
        let dtFrom = new Date(aiimUtil.getAttributeValue(attr,FieldNames.START_TIME));
        let dtTo = new Date(aiimUtil.getAttributeValue(attr,FieldNames.END_TIME));
        let fullName = aiimUtil.getAttributeValue(attr,FieldNames.RESERVED_FOR_FULL_NAME);
        let levelId = aiimUtil.getAttributeValue(attr,FieldNames.LEVEL_ID);
        let unit = aiimUtil.getAttributeValue(attr,FieldNames.UNIT_NAME);
        let from = lib.dojo.locale.format(dtFrom,{formatLength:"short"});
        let to = lib.dojo.locale.format(dtTo,{formatLength:"short"});
        let range = i18n.meetingRooms.rangePattern.replace("{fromDate}",from).replace("{toDate}",to);
        if (task?.criteria?.when?.recurrence?.enabled) {
          range = formatRecurringDates(task.criteria.when.recurrence, moment(when.start.date), moment(when.end.date),(when.duration === "allDay"));
        }
        let orgBy = i18n.meetingRooms.organizedBy.replace("{person}",fullName);
        if (task.reserveForInfo) orgBy = i18n.meetingRooms.reservedFor.replace("{person}",fullName);
        let fl;
        let ld = levels && levels.getLevelData(levelId);
        if (ld) {
          let p = i18n.meetingRooms.levelAndFacility;
          fl = p.replace("{levelName}",ld.levelName).replace("{facilityName}",ld.facilityName);
        }
        const { recurrence } = params;
        let bookingType: BookingType = "single";
        if (recurrence) {
          bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
        }
        let content = (
          <MeetingBookingConfirmed
            bookingType={bookingType}
            name={name}
            range={range}
            unit={unit}
            fl={fl}
            orgBy={orgBy}
            calendarParams={calendarParams}
            reservation={task.reservation}
            isRecurring={recurrence?.enabled}
          />
        );
        const c = new ModalController();
        c.show({
          title: i18n.meetingRooms.book.newMeeting,
          className: "i-modal-confirm i-modal-transparent",
          showOKCancel: false
        },content);

      }
    }
    if (params.allDay) {
      params.checkInDate = dateUtil.toStartOfDay(new Date(params.checkInDate));
      params.checkOutDate = dateUtil.toEndOfDay(new Date(params.checkOutDate));
    }
    if (task.reserveForInfo?.occupantFeature) params.options.others = [task.reserveForInfo?.occupantFeature];

    const response = await validateUtil.verifyAndBook(params);
    if (!response || (response && ((response.warnings && response.warnings.length > 0) || response.error))) {
      task.status.executing = false;
      task.bookComponent.setState({working: false});
    }
    return response;
  }

  static async createBookingOffice365(task: IMeetingRoomCreateTask) {
    task.bookComponent.setState({working: true});
    let itemRef;
    const ds = Context.instance.aiim.datasets.units;
    const source = ds && ds.getSource();
    const sr = source && source.makeSearchResult(null, task.item.feature);
    if (sr) {
      itemRef = new ItemReference();
      itemRef.fromSearchResult(source.key,sr)
    }
    const when = task.criteria.when;
    const bookingSystem = getMeetingBookingSystem();
    const item = task && task.item;
    const controller = task.controller;
    const params: IBookingTask = {
      title: task.criteria.title,
      description: Context.sanitizeHtml(task.criteria.description),
      checkInDate: new Date(when.start.date),
      checkOutDate: new Date(when.end.date),
      allDay: (when.duration === "allDay"),
      unit: item.feature,
      item: itemRef,
      unitName: item.name,
      scheduleEmail: item.scheduleEmail,
      bookingSystem: bookingSystem,
      bookingType: "meetingRooms",
      renderBookingConfirmed: (data: boolean, params: boolean, task: IBookingTask) => {
        controller.close();
        this.renderBookingConfirmed365(data, params, task);
      },
      options: {
        recurrence: { ...when.recurrence }        
      }
    }
    const response = await validateUtil.verifyAndBook(params);
    if (!response || (response && ((response.warnings && response.warnings.length > 0) || response.error))) {
      task.status.executing = false;
      task.bookComponent.setState({working: false});
    }
    return response;
  }

  static async updateBookingOffice365(task: IMeetingRoomUpdateTask) {
    task.bookComponent.setState({working: true});
    let itemRef;
    const ds = Context.instance.aiim.datasets.units;
    const source = ds && ds.getSource();
    const sr = source && source.makeSearchResult(null, task.item.feature);
    if (sr) {
      itemRef = new ItemReference();
      itemRef.fromSearchResult(source.key,sr)
    }
    const when = task.criteria.when;
    const bookingSystem = getMeetingBookingSystem() as Office365;
    const item = task && task.item;
    const controller = task.controller;
    const recurrenceType = task.recurrenceType;
    const params: IOffice365BookingTask = {
      title: task.criteria.title,
      checkInDate: new Date(when.start.date),
      checkOutDate: new Date(when.end.date),
      allDay: (when.duration === "allDay"),
      unit: item.feature,
      item: itemRef,
      unitName: item.name,
      scheduleEmail: item.scheduleEmail,
      bookingSystem: bookingSystem,
      bookingType: "meetingRooms",
      operation: "updateBookingTime",
      eventId: task.criteria.eventId,
      origBooking: task.item?.origBooking,
      renderBookingConfirmed: (data: boolean, params: boolean, task: IBookingTask) => {
        controller.close();
        this.renderBookingConfirmed365(data, params, task);
      },
      options: {
        recurrence: { ...when.recurrence }        
      }
    }
    if (recurrenceType === "series") {
      params.seriesMasterId = task.criteria.eventId;
      // params.options.recurrence.startDate = this.recurrence.startDate;
    }
    const response = await validateUtil.verifyAndBook(params);
    if (!response || (response && response.warnings && response.warnings.length > 0)) {
      task.status.executing = false;
      task.bookComponent.setState({working: false});
    }
    return response;
  }

  static async updateBookingEsri(task: IMeetingRoomUpdateTask) {
    task.bookComponent.setState({working: true});
    let itemRef;
    const ds = Context.instance.aiim.datasets.units;
    const source = ds && ds.getSource();
    const sr = source && source.makeSearchResult(null, task.item.feature);
    if (sr) {
      itemRef = new ItemReference();
      itemRef.fromSearchResult(source.key,sr)
    }

    const origResAttr = task && task.originalReservation && task.originalReservation.attributes; 
    const when = task.criteria.when;
    const bookingSystem = getMeetingBookingSystem() as EsriReservationSchema;
    const item = task && task.item;
    const params: IEsriBookingTask = {
      title: task.criteria.title,
      description: aiimUtil.getAttributeValue(origResAttr, FieldNames.DESCRIPTION),
      checkInDate: new Date(when.start.date),
      checkOutDate: new Date(when.end.date),
      allDay: (when.duration === "allDay"),
      unit: item.feature,
      item: itemRef,
      unitName: item.name,
      bookingSystem: bookingSystem,
      bookingType: "meetingRooms",
      operation: "updateBookingTime",
      objectIds: task && task.criteria && task.criteria.objectID,
      options: { 
        recurrence: { ...when.recurrence },
        reserveForInfo: task.reserveForInfo
      },
      series: task.criteria.series as IRecurrenceSeries
    }
    if (task.reserveForInfo?.occupantFeature) params.options.others = [task.reserveForInfo?.occupantFeature];
    const response = await validateUtil.verifyAndBook(params);
    if (!response || (response && response.error) || (response && response.warnings && response.warnings.length > 0)) {
      task.status.executing = false;
      task.bookComponent.setState({working: false});
      return response;
    }
    
    Topic.publish(Topic.RenderMeetingRooms, {origResAttr: origResAttr});
    if (response && response.reservation) 
      task.reservation = response.reservation as IFeature;
    await Book.makeIcsInfo(task, task.reservation);
    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const calendarParams = makeEventInfo(reservationsDataset, task.reservation, unitsDataset, task.item.feature, 
                                          "updateBooking", origResAttr);
    const lib = Context.instance.lib
    const levels = Context.instance.aiim.datasets.levels;
    let attr = task.reservation.attributes;
    let name = aiimUtil.getAttributeValue(attr,FieldNames.TITLE);
    let dtFrom = new Date(aiimUtil.getAttributeValue(attr,FieldNames.START_TIME));
    let dtTo = new Date(aiimUtil.getAttributeValue(attr,FieldNames.END_TIME));
    let fullName = aiimUtil.getAttributeValue(attr,FieldNames.RESERVED_FOR_FULL_NAME);
    let levelId = aiimUtil.getAttributeValue(attr,FieldNames.LEVEL_ID);
    let unit = aiimUtil.getAttributeValue(attr,FieldNames.UNIT_NAME);
    let from = lib.dojo.locale.format(dtFrom,{formatLength:"short"});
    let to = lib.dojo.locale.format(dtTo,{formatLength:"short"});
    const i18n = Context.instance.i18n;
    let range = i18n.meetingRooms.rangePattern.replace("{fromDate}",from).replace("{toDate}",to);
    let orgBy = i18n.meetingRooms.organizedBy.replace("{person}",fullName);
    if (task.reserveForInfo) orgBy = i18n.meetingRooms.reservedFor.replace("{person}",task.reserveForInfo.fullName);
    let fl;
    let ld = levels && levels.getLevelData(levelId);
    if (ld) {
      let p = i18n.meetingRooms.levelAndFacility;
      fl = p.replace("{levelName}",ld.levelName).replace("{facilityName}",ld.facilityName);
    }
    
    if (task?.criteria?.when?.recurrence?.enabled && task.criteria.when.recurrence?.modified !== "occurrence") {
      range = formatRecurringDates(task.criteria.when.recurrence, moment(when.start.date), moment(when.end.date),
                    (when.duration === "allDay"));
    }

    const recurrence = task?.criteria?.when?.recurrence;
    let bookingType: BookingType = "single";
    if (recurrence) {
      bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
    }

    let content = (
      <MeetingBookingConfirmed
        bookingType={bookingType}
        name={name}
        range={range}
        unit={unit}
        fl={fl}
        orgBy={orgBy}
        calendarParams={calendarParams}
        updateBooking={true}
        origResAttr={origResAttr}
        reservation={task.reservation}
      />
    );
    task.controller.close();
    const c = new ModalController();

    c.show({
      title: i18n.meetingRooms.book.updateMeeting,
      className: "i-modal-confirm i-modal-transparent",
      showOKCancel: false
    },content);
  }

  static getOrigRecurrence(originalReservation: IOriginalReservation) {
    if (!(originalReservation?.booking || originalReservation?.attributes))
      return null;
    const origResAttr = originalReservation.attributes;
    const origBooking = originalReservation.booking;
    return aiimUtil.getRecurrencePattern(origResAttr ?? origBooking);
  }
  static getOrigWhen(originalReservation: IOriginalReservation, when: dateUtil.IDuration, recurrenceType: BookingType) {
    if (!originalReservation)
      return null;
    const origResAttr = originalReservation.attributes;
    const origBooking = originalReservation.booking;      
    if (!origResAttr && !origBooking)
      return null;

    let start: number, end: number, allDay: boolean, title: string, duration: dateUtil.IDuration["duration"];
    if (origResAttr) {
      start = recurrenceType === "series"
        ? when.start.date.valueOf()
        : aiimUtil.getAttributeValue(origResAttr, FieldNames.START_TIME);
      end = recurrenceType === "series"
        ? when.end.date.valueOf()
        : aiimUtil.getAttributeValue(origResAttr, FieldNames.END_TIME);
      title = aiimUtil.getAttributeValue(origResAttr, FieldNames.TITLE);
    } else if (origBooking) {
      const o365toDateObj = dateUtil.O365toDate(origBooking.start.dateTime, origBooking.end.dateTime);
      const utc = origBooking.start.timeZone?.toLowerCase() === "utc";
      const toLocal = utc ? moment.utc : moment;
      start = dateUtil.getMsFromTimeObj(recurrenceType === "series"
        ? toLocal(origBooking.masterEvent.start.dateTime).valueOf()
        : o365toDateObj.fromDateTime.valueOf());
      end = dateUtil.getMsFromTimeObj(recurrenceType === "series"
        ? toLocal(origBooking.masterEvent.end.dateTime)
        : o365toDateObj.toDateTime);
      title = recurrenceType === "series" ? origBooking.masterEvent.subject : origBooking.subject;
    }
    allDay = dateUtil.isAllDay(start, end);
    duration = dateUtil.getDurationKey(new Date(start), new Date(end));
    if (start && end)
      return { start, end, title, allDay, duration };
    return null; 
  }

  static async createBooking(task: IMeetingRoomCreateTask) {
    const bookingSystemType = getMeetingBookingSystemType();
    if (bookingSystemType === "office365") {
      await Book.createBookingOffice365(task);
    } else {
      Book.createBookingEsri(task);
    }
  }

  static async updateBooking(task: IMeetingRoomUpdateTask) {
    const bookingSystemType = getMeetingBookingSystemType();
    if (bookingSystemType === "office365") {
      await Book.updateBookingOffice365(task);
    } else if (bookingSystemType === "esri") {
      await Book.updateBookingEsri(task);
    }
  }

  static renderBookingConfirmed365(data: boolean, params: boolean, task: IBookingTask) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const unit = task && task.unit;
    const attributes = unit && unit.attributes;
    const levelId = aiimUtil.getAttributeValue(attributes, FieldNames.LEVEL_ID);
    const levels = Context.getInstance().aiim.datasets.levels;
    const levelData = levels.getLevelData(levelId); 
    const levelName = levelData && levelData.levelName;
    const facilityName = levelData && levelData.facilityName;

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={getMeetingBookingSystemType()}
        unitName={task.unitName}
        levelName={levelName}
        facilityName={facilityName}
        closePopup={onClose}
        data={data}
        unit={params}
        params={params}
      />
    ), node);
  }

  static makeIcsInfo(task: IMeetingRoomCreateTask, reservation: IFeature) {
    const dataset = Context.getInstance().aiim.datasets.reservations;
    let feature = task.item.feature;
    let filename = "reservation.ics";
    let attr = reservation.attributes;
    let info: Partial<IReservationInfo> = {
      name: aiimUtil.getAttributeValue(attr,FieldNames.TITLE),
      description: aiimUtil.getAttributeValue(attr,FieldNames.DESCRIPTION),
      location: aiimUtil.getAttributeValue(attr,FieldNames.UNIT_NAME),
      date_start: new Date(aiimUtil.getAttributeValue(attr,FieldNames.START_TIME)),
      date_end: new Date(aiimUtil.getAttributeValue(attr,FieldNames.END_TIME)),
      geo: null,
      geometry: feature && feature.geometry,
      recurrenceOptions: task.criteria?.when?.recurrence,
      reservationObjectId: aiimUtil.getAttributeValue(attr, dataset.getObjectIdField())
    };
    return ics.makeIcsInfo(info as IReservationInfo, filename).then(icsInfo => {
      return icsInfo;
    }).catch(ex => {
      console.error("Error generating .ics file:",ex);
    });
  }

  static scheduleClicked(task: {
    bookComponent: Book,
    controller: ModalController,
    item: IMeetingRoom,
    criteria: IMeetingRoomCriteria,
    originalReservation?: IOriginalReservation,
    recurrenceType?: BookingType,
    reserveForInfo?: IReserveForInfo
  }) {
    const i18n = Context.instance.i18n;
    const roomItem = {
      unitId: task.item.unitId,
      name: task.item.name,
      scheduleEmail: task.item.scheduleEmail,
      unitFeature: task.item.feature
    };
    const when = task.criteria.when;
    const start = when.start.date;
    const targetWhen: dateUtil.IDurationAsMilliseconds = {
      duration: when.duration,
      start: {
        date: when.start.date.getTime()
      },
      end: {
        date: when.end.date.getTime()
      },
      recurrence: when.recurrence
    }

    const controller = new ModalController();
    let title = i18n.meetingRooms.schedule.captionPattern;
    title = title.replace("{room}",roomItem.name);
    const modalProps = {
      className: "i-modal-confirm i-modal-schedule",
      title: title,
      cancelLabel: i18n.general.close,
      showOKCancel: true,
      hideOK: true
    };

    const content = (
      <Schedule 
        bookingType="meetingRooms"
        operation={task.criteria.operation}
        isOngoingBooking={task.criteria.isOngoingBooking}
        item={roomItem} 
        start={start} 
        when={targetWhen}
        series={task.criteria.series}
        originalReservation={task.originalReservation}
        recurrenceType={task.recurrenceType}
        reserveForInfo={task.reserveForInfo}
        onShowOccupant={() => {
          controller.close();
          task.controller.close();
        }}
        onBookClicked={selectedWhen => {
          task.bookComponent.setWhen(selectedWhen);
          controller.close();
        }}
      />
    )
    controller.show(modalProps,content);
  }

  static show(info: {
    bookItem: IMeetingRoom,
    criteria: IMeetingRoomCriteria,
    originalReservation?: IOriginalReservation,
    recurrenceType?: BookingType,
    reserveForInfo?: IReserveForInfo
  }) {
    const item = info.bookItem;
    const criteria = info.criteria;
    const operation = criteria && criteria.operation;
    const originalReservation = info.originalReservation;
    const series = criteria?.series;
    const recurrenceType = info.recurrenceType;
    let reserveForInfo = info.reserveForInfo;

    if (reserveForInfo) {
      let isAreaManager = false;
      if (Context.instance.aiim.isReservationManager() && item.feature && item.feature.attributes) {
        const areaId = aiimUtil.getAttributeValue(item.feature.attributes,FieldNames.UNITS_AREA_ID);
        const managerInfo = Context.instance.aiim.managerInfo;
        isAreaManager = managerInfo.reservationManagerIds.includes(areaId);
      }
      if (!isAreaManager) {
        reserveForInfo = null;
      }
    }

    const is365 = getMeetingBookingSystemType() === "office365";
    if (item && is365 && !item.scheduleEmail && item.feature && item.feature.attributes) {
      item.scheduleEmail = aiimUtil.getAttributeValue(item.feature.attributes, FieldNames.SCHEDULE_EMAIL);
    }

    let bookComponent: Book;
    let okButton;
    const status = {executing: false}
    const i18n = Context.instance.i18n;
    const controller = new ModalController();
    let title = i18n.meetingRooms.book.newMeeting;
    if (operation && operation === "updateBookingTime") title = i18n.meetingRooms.book.updateMeeting;
    const modalProps = {
      className: "i-modal-confirm",
      title: title,
      okLabel: i18n.more.bookWorkspace.confirm,
      showOKCancel: true,
      hideCancel: true,
      closeOnOK: false,
      onMountOKButton: btn => {
        okButton = btn;
        if (bookComponent) {
          bookComponent.modalOkButton = btn;
          bookComponent._checkModalOKButton();
        }
      },      
      onOK: () => {
        if (status.executing) return;
        let newCriteria: Partial<IMeetingRoomCriteria> = {};
        let ok = bookComponent.validate(newCriteria);
        if (ok) {
          if ((!(series || originalReservation?.booking?.masterEvent)) && newCriteria.when.end.date.getTime() <= Date.now()) {
            ok = false;
            ModalController.showMessage(i18n.more.bookWorkspace.pastTime, title);
          }
        }
        const changes = hasChanges(newCriteria, bookComponent.props.criteria, originalReservation, info.recurrenceType);
        if (ok && !changes) {
          ok = false; 
          ModalController.showMessage(i18n.meetingRooms.book.noChangesMsg);
        }

        if (ok) {
          status.executing = true;
          if (operation === "updateBookingTime") {
            if (newCriteria) {
              newCriteria.eventId = criteria.eventId
              newCriteria.operation = operation
              newCriteria.objectID = criteria && criteria.objectID;
              newCriteria.series = series;
            }
            Book.updateBooking({
              item: item,
              criteria: newCriteria as IMeetingRoomCriteria,
              bookComponent: bookComponent,
              controller: controller,
              status: status,
              originalReservation,
              recurrenceType,
              reserveForInfo
            })
          } else {
            Book.createBooking({
              item: item,
              criteria: newCriteria as IMeetingRoomCriteria,
              bookComponent: bookComponent,
              controller: controller,
              status: status,
              reserveForInfo
            });
          }
        }
      },
      extraButton: (
        <Button key="extraButton" className="i-extra-button"
          onClick={() => {
            let newCriteria: Partial<IMeetingRoomCriteria> = {};
            bookComponent.validate(newCriteria,true);
            if (operation === "updateBookingTime") {
              newCriteria.eventId = criteria.eventId
              newCriteria.operation = operation
              newCriteria.isOngoingBooking = criteria.isOngoingBooking
              newCriteria.series = series;
            }
            Book.scheduleClicked({
              item: item,
              controller,
              criteria: newCriteria as IMeetingRoomCriteria,
              bookComponent: bookComponent,
              originalReservation,
              recurrenceType,
              reserveForInfo
            });
          }}
        >{i18n.meetingRooms.schedule.viewSchedule}</Button>
      )
    };
    const content = (
      <Book 
        item={item} 
        criteria={criteria}
        onMount={c => bookComponent = c }
        originalReservation={originalReservation}
        recurrenceType={info.recurrenceType}
        reserveForInfo={reserveForInfo}
      />
    )
    controller.show(modalProps,content);
  }
}

const hasChanges = (
  newCriteria: Partial<IMeetingRoomCriteria>,
  oldCriteria: Partial<IMeetingRoomCriteria>,
  originalReservation?: IBookProps["originalReservation"],
  recurrenceType?: BookingType
) => {
  if (!originalReservation) {
    return true;
  }
  const origWhen = Book.getOrigWhen(originalReservation, oldCriteria.when, recurrenceType);
  const origRecurrence = Book.getOrigRecurrence(originalReservation);
  const when = newCriteria.when;
  const currRecurrence = when.recurrence;
  if (origWhen || origRecurrence || currRecurrence) {
    //(when.duration==="allDay" && origWhen.duration==="allDay") //oneHour,allDay etc. 
    const currStart = when.start.date.getTime();
    const currEnd = when.end.date.getTime();
    const currDuration = when.duration;
    // occurrences and exceptions are stored in m365 without milliseconds so all-day
    // meeting rooms have a UTC end time of 04:59.00 instead of 04:59:59
    const origEnd = (recurrenceType === "series" || recurrenceType === "occurrence") && origWhen.allDay
      ? moment(origWhen.end).endOf("d").valueOf()
      : origWhen.end;
    const whenDisabled = origWhen && currStart === origWhen.start && currEnd === origEnd && currDuration === origWhen.duration;
    const recurrenceDisabled = origRecurrence !== currRecurrence ? recurringSeriesMatch(currRecurrence, origRecurrence) : true;
    if (whenDisabled && newCriteria.title === origWhen.title && recurrenceDisabled) {
      return false;
    }
  }
  return true;
}
