
export function append(layers,tables,options) {
  //tables.push(planReviewTable());
  tables.push(reviewersTable());
}

// function planReviewTable() {
//   const def = {
//     "name": "PlanReview",
//     "type": "Table",
//     "objectIdField": "objectid",
//     "globalIdField": "globalid",
//     "displayField": "objectid",
//     "typeIdField": "",
//     "capabilities": "Query,Editing,Create,Update,Delete,Extract",
//     "description": "",
//     "copyrightText": "",
//     "relationships": [],
//     "maxRecordCount": 2000,
//     "allowGeometryUpdates": true,
//     "hasAttachments": false,
//     "htmlPopupType": "esriServerHTMLPopupTypeNone",
//     "dateFieldsTimeReference": {
//       "timeZone": "UTC",
//       "respectsDaylightSaving": false
//     },
//     "fields": [{
//         "name": "objectid",
//         "type": "esriFieldTypeOID",
//         "alias": "OBJECTID",
//         "domain": null,
//         "nullable": false,
//         "defaultValue": null,
//         "editable": false
//       },
//       {
//         "name": "globalid",
//         "type": "esriFieldTypeGlobalID",
//         "alias": "GlobalID",
//         "domain": null,
//         "editable": false,
//         "nullable": false,
//         "length": 38,
//         "defaultValue": null
//       },
//       {
//         "name": "ready",
//         "type": "esriFieldTypeString",
//         "alias": "READY",
//         "domain": null,
//         "editable": true,
//         "nullable": true,
//         "length": 255,
//         "defaultValue": null
//       },
//       {
//         "name": "portal_groupid",
//         "type": "esriFieldTypeString",
//         "alias": "PORTAL_GROUPID",
//         "domain": null,
//         "editable": true,
//         "nullable": true,
//         "length": 255,
//         "defaultValue": null
//       },
//       {
//         "name": "planid",
//         "type": "esriFieldTypeString",
//         "alias": "PLANID",
//         "domain": null,
//         "editable": true,
//         "nullable": true,
//         "length": 255,
//         "defaultValue": null
//       }
//     ],
//     "types": [],
//     "templates": [{
//       "name": "PlanReview",
//       "description": "",
//       "drawingTool": "esriFeatureEditToolNone",
//       "prototype": {
//         "attributes": {
//           "ready": null,
//           "portal_groupid": null,
//           "planid": null
//         }
//       }
//     }]
//   };

//   return def;
// }

function reviewersTable() {
  const def = {
    "name": "Reviewers",
    "type": "Table",
    "objectIdField": "objectid",
    "globalIdField": "globalid",
    "displayField": "USERNAME",
    "typeIdField": "",
    "capabilities": "Query,Editing,Create,Update,Delete,Extract",
    "description": "",
    "copyrightText": "",
    "relationships": [],
    "maxRecordCount": 2000,
    "allowGeometryUpdates": true,
    "hasAttachments": false,
    "htmlPopupType": "esriServerHTMLPopupTypeNone",
    "dateFieldsTimeReference": {
      "timeZone": "UTC",
      "respectsDaylightSaving": false
    },
    "fields": [{
        "name": "objectid",
        "type": "esriFieldTypeOID",
        "alias": "OBJECTID",
        "domain": null,
        "nullable": false,
        "defaultValue": null,
        "editable": false
      },
      {
        "name": "globalid",
        "type": "esriFieldTypeGlobalID",
        "alias": "GlobalID",
        "domain": null,
        "editable": false,
        "nullable": false,
        "length": 38,
        "defaultValue": null
      },
      {
        "name": "USERNAME",
        "type": "esriFieldTypeString",
        "alias": "USERNAME",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 255,
        "defaultValue": null
      },
      {
        "name": "REVIEW_STATUS",
        "type": "esriFieldTypeString",
        "alias": "REVIEW_STATUS",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 255,
        "defaultValue": null
      },
      {
        "name": "PLAN_ID",
        "type": "esriFieldTypeString",
        "alias": "PLAN_ID",
        "domain": null,
        "editable": true,
        "nullable": true,
        "length": 255,
        "defaultValue": null
      }
    ],
    "types": [],
    "templates": [{
      "name": "Reviewers",
      "description": "",
      "drawingTool": "esriFeatureEditToolNone",
      "prototype": {
        "attributes": {
          "USERNAME": " ",
          "REVIEW_STATUS": " ",
          "PLAN_ID": null
        }
      }
    }]
  };

  return def;
}
