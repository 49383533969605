import React, { Component } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as CrudReply from "../../base/transaction/reply";
import * as commentReplyUtil from "./commentReplyUtil";
import * as component from "../../../../src/components/util/component";
import  { ThemeColorButton, CharacterCount, EmptyInput, ButtonGroupInput } from "../../styles/commentsStyles";


export default class EditComment extends Component {

    componentId;
    total =  commentReplyUtil.getMaxCommentLength();

    constructor(props){
        super(props);
        this.componentId = component.nextId();
        this.state={
            reply: this.props.reply,
            emptyReply: false,
            isCalloutClicked : false,
            characterCount: this.props.reply.length
        }
    }

    componentDidMount(){
        this._focus();
    }

    _focus=()=>{
        const input = document.getElementById(this.componentId);
        commentReplyUtil.setCursorPosition(input, this.props.reply.length);
        input.scrollTop = input.scrollHeight;
    }

    handleCancel=()=>{
        this.props.cancelClicked();
        Topic.publish(Topic.LoadComments);
    }

    handleInputChange =(evt)=> {
        let replyStr = evt.target.value;
        if(replyStr.length > this.total) return;
        replyStr = Context.sanitizeHtml(replyStr);
        this.setState({
            reply : replyStr,
            characterCount : replyStr.length
        },function(){
            if(!this.state.reply) {
                this.setState({
                    emptyReply : true
                })
            }else{
                this.setState({
                    emptyReply : false
                })
            }
        })
    }

    handleSave = () => {
        const reply = this.state.reply.trim();
        const author = Context.instance.user.getUsername();
        const openPlanId = Context.instance.spaceplanner.planId;
        const commentId = this.props.commentId;
        const objectId = this.props.replyId;
        if(!reply){
            this.setState({
                emptyReply : true
            })
        }else{
            const obj = {
                reply: reply,
                author: author,
                planId: openPlanId,
                commentId: commentId,
                objectId: objectId
            }
            const url = commentReplyUtil.getCommentUrl();
            CrudReply.editReply(obj, url).then(()=>{
                Topic.publish(Topic.LoadComments);
                this.props.cancelClicked();
            })
            .catch(error=>{
              console.error(error);
              Topic.publishErrorUpdatingData(error.message);
            })
        }
    }

    render(){
        const i18n = Context.instance.i18n;
        const id = this.componentId;
        let showError;
        if(this.state.emptyReply) showError = i18n.spaceplanner.reply.emptyReply;
        let pattern = i18n.item.categoryTypePattern;
        pattern = pattern.replace("{category}", this.state.characterCount);
        pattern = pattern.replace("{type}", this.total);
        return(
            <div style={{display:"flex", flexDirection: "column", alignItems: "flex-end", margin: "0 0.5rem", paddingBottom:"0.5rem"}}>
                <TextareaAutosize 
                    id={id}
                    minRows={2} 
                    maxRows = {10}
                    style={{width: "97%", borderColor:"#cacaca", padding:"5px", fontFamily: "sans-serif", fontSize: "0.833rem", color:"#323232"}}
                    value={Context.sanitizeHtml(this.state.reply)}
                    onChange={this.handleInputChange}
                    />
                <EmptyInput>{showError}</EmptyInput>
                <ButtonGroupInput>
                    <CharacterCount>{pattern}</CharacterCount>
                    <div>
                        <ThemeColorButton onClick={this.handleSave}>{i18n.general.save}</ThemeColorButton>
                        <ThemeColorButton onClick={this.handleCancel}>{i18n.general.cancel}</ThemeColorButton>
                    </div>
                </ButtonGroupInput>
            </div>
        )
    }

}