import BaseClass from "../util/BaseClass";
import Context from "./Context";
import Topic from "./Topic";
import moment from "moment";
import ScreenSplit from "./ScreenSplit";

export default class UIMode extends BaseClass {

  static MODE_HORIZONTAL_SPLIT = "i-mode-horizontal-split";
  static MODE_VERTICAL_SPLIT = "i-mode-vertical-split";
  static MODE_NORMAL = "i-mode-normal";
  static screenSplit = new ScreenSplit();

  isAndroid = false;
  isConfiguratorOn = false;
  isIOS = false;
  isKiosk = false;
  isRtl = false;
  isSafari = false;
  isTouch = false;
  isMobile = false;
  isTablet = false;
  mode;

  constructor(props?) {
    super(props);
    Topic.subscribe(Topic.ApplyTheme,(params) => {
      if (params && params.theme) {
        this._applyTheme(params.theme);
      }
    });
  }

  _applyTheme(theme) {
    if (theme && Context.instance.isFPE()) {
      theme["theme-color-brand"] = "#005e95";
      theme["theme-color-brand-highlight"] ="#004D7A";
      theme["theme-color-link"] = "#005e95";
      theme["theme-color-button"] = "#005e95";
      theme["theme-color-button-highlight"] = "#004D7A";
      theme["theme-color-accent"] = "#005e95";
    }
    if (theme) {
      Object.keys(theme).forEach(key => {
        if (typeof key === "string" && key.length > 0) {
          let value = theme[key];
          if (value !== undefined && value !== null) {
            // let ok = true;
            // if (typeof value === "string") {
            // } else if (typeof value === "number") {
            // }
            try {
              let prop = key;
              if (prop.indexOf("--i-") !== 0) {
                prop = "--i-"+prop;
              }
              document.documentElement.style.setProperty(prop,value);
            } catch(ex) {
              console.warn("Error applying theme property, key:",key,"value:",value);
              console.error(ex);
            }
          }
        }
      });
    }
  }

  _checkAndroidTablet(setHSplit, setVSplit, setNormal){

    const check =()=>{
      let angle = null;
      if (window.screen && window.screen.orientation) {
        angle = window.screen.orientation.angle
      } else {
        angle = window.orientation;
      }
      if (typeof angle === "number") {
        if (angle === 0 || angle === 180) {
          // portrait
          UIMode.screenSplit.setHSplit();
        } else if (angle === 90 || angle === -90 || angle === 270) {
          // landscape
          UIMode.screenSplit.setNormal();
        }
      }
    }
    let isAndroidPhone= false, isAndroidTablet= false;
    if(navigator.userAgent.match(/Android/i)) {
      isAndroidPhone= navigator.userAgent.includes("Mobile");
      isAndroidTablet= !isAndroidPhone;
    }
    if(isAndroidTablet) {
      let width= Math.min(window.innerWidth, window.innerHeight);
      if(width<= 960){
        window.addEventListener("orientationchange", () => {
          check();
        });
        check();
      }
    }
    return isAndroidTablet;
  }

  _checkTouch() {
    let isTouch = false;
    if (window.PointerEvent && ("maxTouchPoints" in navigator)) {
      if (navigator.maxTouchPoints > 0) {
        isTouch = true;
      }
    } else {
      if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
        isTouch = true;
      } else if (window.TouchEvent || ("ontouchstart" in window)) {
        isTouch = true;
      }
    }
    // if (!isTouch) {
    //   const self = this;
    //   window.addEventListener("touchstart", function onTouch() {
    //     console.log("UIMode-window.touchstart ....................................")
    //     self.isTouch = true;
    //     window.removeEventListener("touchstart",onTouch);
    //   });
    //   document.addEventListener("touchstart", function onTouch2() {
    //     console.log("UIMode-document.touchstart ....................................")
    //     self.isTouch = true;
    //     document.removeEventListener("touchstart",onTouch2);
    //   });
    // }
    return isTouch;
  }

  getCssVariable(name) {
    if (typeof name === "string" && name.length > 0 && name.indexOf("--") !== 0) {
      name = "--"+name;
    }
    return window.getComputedStyle(document.documentElement,null).getPropertyValue(name);
  }

  getLogoName(){
    let logo = Context.instance.config.logo;
    if (logo && logo.name) return logo.name;
    return this.getCssVariable("--i-logo-name");
  }

  getLogoUrl() {
    let logo = Context.instance.config.logo;
    if (logo && logo.url) return logo.url;

    const startsWith = (v,s) => {
      return (v.indexOf(s) === 0);
    };
    const endsWith = (v,s) => {
      return (v.substring(v.length - s.length, v.length) === s);
    };
    const trim = (v,s) => {
      if (startsWith(v,s) && endsWith(v,s)) {
        v = v.substring(s.length);
        return v.substring(0,v.length - s.length);
      }
      return v;
    };
    let url = this.getCssVariable("--i-logo-url");
    if (typeof url === "string") {
      url = url.trim();
      if (url.length > 0) {
        if (startsWith(url,"url(") && endsWith(url,")")) {
          url = url.substring(4);
          url = url.substring(0,url.length - 1);
        }
        url = trim(url,"\"").trim(url,"'").trim();
      }
      if (url.indexOf("../../../") === 0) {
        url = url.substring(7);
      }
      if (url.length > 0) {
        return url;
      }
    }
    return null;
  }

  getLogoSize(){
    return Context.instance.config.logo.size;
  }

  hideSkeleton() {
    document.body.classList.remove("i-skeleton-on");
    document.getElementById("skeleton").style.display = "none";
  }

  init() {
    const lib = Context.getInstance().lib;
    const kernel = lib.dojo.kernel;

    let isAndroid = false, isIOS = false, hasOrientationChange = false;


    try {
      isAndroid = this.isAndroid = /Android/i.test(navigator.userAgent);
      isIOS = this.isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      //let isStandalone = !!navigator.standalone;
      if (this.isSafari) {
        const html = document.getElementsByTagName("html")[0]
        if (html) {
          html.classList.remove("i-not-safari")
          html.classList.add("i-safari")
        }
      }

      let isRtl = false;
      if (typeof kernel.locale === "string" && kernel.locale.length > 0 && kernel.locale !== "en") {
        document.documentElement.lang = kernel.locale;
        const lc = kernel.locale.toLowerCase();
        if (lc === "ar" || lc.indexOf("ar-") === 0 ||
            lc === "he" || lc.indexOf("he-") === 0) {
          isRtl = true;
        }
        try {
          moment.locale(lc);
        } catch(ex) {
          console.warn("Error setting moment.locale",lc);
        }
      }
      const rtlParam = window.location.search.match(/rtl=([\w-]+)/) ? RegExp.$1 : null;
      if (rtlParam === "true" || isRtl) {
        const nd = document.getElementsByTagName("html")[0];
        nd.setAttribute("dir","rtl");
        document.body.classList.add("i-rtl");
      }
      this.isTouch = this._checkTouch();
      if (this.isTouch && "onorientationchange" in window) {
        hasOrientationChange = true;
      }

      if (window.location.pathname.toLowerCase().endsWith("/kiosk.html")) {
        const elBody = document.body;
        if (!elBody.classList.contains("i-kiosk") && elBody.classList.contains(UIMode.MODE_NORMAL)) {
          // react dev mode using index.html
          elBody.classList.add("i-kiosk");
          elBody.classList.remove(UIMode.MODE_NORMAL);
          let el = <HTMLMetaElement>document.querySelector("meta[name=\"viewport\"]");
          if (el) {
            el.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
          }
        }
      }

      if (document.body.classList.contains("i-kiosk")) {
        this.isKiosk = true;
      }

      if (document.body.classList.contains("i-rtl")) {
        this.isRtl = true;
      }

      try {
        const ios = /iPhone|iPad|iPod/i.test(navigator.platform);
        if (ios && this.isSafari && !navigator.standalone && navigator.appVersion) {
          document.body.classList.add("i-svb");
          // const ios13 = /OS 13/i.test(navigator.appVersion);
          // if (ios13) {
          //   document.body.classList.add("i-svb"); // Safari viewport bug
          // }
        }
      } catch(ex2) {
        console.error(ex2);
      }

    } catch(ex) {
      console.warn("UIMode::init error");
      console.error(ex);
    }

    const userAgent = navigator.userAgent.toLowerCase();
    this.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    let vsplitQ, hsplitQ;
    let watchOrientation= true, isAndroidTablet;
    if(/iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile= true;
    }
    else if(/Android/i.test(navigator.userAgent)) {
      this.isMobile= navigator.userAgent.includes("Mobile");
    }
    if(this.isMobile){
      document.body.classList.add("i--mobile");
    }

    if(this.isMobile){
        vsplitQ = "screen and (max-width: 481px) and (orientation: portrait)";
        hsplitQ = "screen and (max-height: 481px) and (orientation: landscape)";
    }
    else if(navigator.userAgent.match(/iPad/i)){
        vsplitQ = "screen and (max-width: 690px)";
        hsplitQ = "screen and (min-width: 691px) and (max-width: 960px)";
        watchOrientation= false;
    }else{
        isAndroidTablet= this._checkAndroidTablet(UIMode.screenSplit.setHSplit,UIMode.screenSplit.setVSplit,UIMode.screenSplit.setNormal);
        if(isAndroidTablet) {
          vsplitQ= null;
          hsplitQ= null;
          watchOrientation= false;
        }else{
          vsplitQ = "screen and (max-width: 690px)";
          hsplitQ = "screen and (min-width: 691px) and (max-width: 960px)";
          watchOrientation= false;
        }
    }

    const vsplitM = vsplitQ && window.matchMedia(vsplitQ);
    const hsplitM = hsplitQ && window.matchMedia(hsplitQ);

    const setMode = () => {
      if (vsplitM && vsplitM.matches) {
        UIMode.screenSplit.setVSplit();
      } else if (hsplitM && hsplitM.matches) {
        UIMode.screenSplit.setHSplit();
      } else {
        UIMode.screenSplit.setNormal();
      }
    };

    try {

      if (!this.isKiosk) {
        if(!isAndroidTablet){setMode();}
        if ((this.mode === UIMode.MODE_HORIZONTAL_SPLIT || this.mode === UIMode.MODE_VERTICAL_SPLIT) &&
            (isAndroid || isIOS) && hasOrientationChange && watchOrientation)  {

          window.addEventListener("orientationchange", () => {
            let angle = null;
            if (window.screen && window.screen.orientation) {
              angle = window.screen.orientation.angle
            } else {
              angle = window.orientation;
            }
            if (typeof angle === "number") {
              if (angle === 0 || angle === 180) {
                // portrait
                UIMode.screenSplit.setVSplit();
              } else if (angle === 90 || angle === -90 || angle === 270) {
                // landscape
                UIMode.screenSplit.setHSplit();
              }
            }
          });

          if (navigator.userAgent.match(/iPhone/i)) {
             const vp = <HTMLMetaElement>document.querySelector("meta[name=\"viewport\"]");
             if (vp) {
               // to avoid zooming in when rotating from portrait to landscape
               vp.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=yes";
             }
          }

        } else {
          if (!isAndroid) {
            if(vsplitM) vsplitM.addListener(() => setMode());
            if(hsplitM) hsplitM.addListener(() => setMode());
          }
        }
      }

    } catch(ex) {
      console.warn("UIMode::init error");
      console.error(ex);
    }

    this._watchEsc();
  }

  modeIsHorizontalSplit(): boolean {
    return (this.mode === UIMode.MODE_HORIZONTAL_SPLIT);
  }

  modeIsNormal(): boolean {
    return (this.mode === UIMode.MODE_NORMAL);
  }

  modeIsVerticalSplit(): boolean {
    return (this.mode === UIMode.MODE_VERTICAL_SPLIT);
  }

  _moveViewTools(fromMode,toMode) {
    // disable this for now
    const disabled = true;
    if (disabled || fromMode === toMode) return;

    const move = (view,fromPosition,toPosition) => {
      let nodes = null;
      if (view && fromPosition && toPosition) {
        const node = view.ui._cornerNameToContainerLookup[fromPosition];
        if (node && node.children) {
          nodes = Array.prototype.slice.call(node.children);
        }
      }
      if (nodes) {
        nodes.forEach(node => {
          const c = view.ui.find(node);
          if (c && (c.widget || c.node)) {
            let sb = (c.node && c.node.className && c.node.className.indexOf("esri-scale-bar") !== -1);
            if (!sb) view.ui.move(c,toPosition);
          }
        });
      }
    };

    const processView = (view) => {
      if (!view) return;
      let fromPosition = null, toPosition = null;
      if (!this.isRtl) {
        if (toMode === UIMode.MODE_HORIZONTAL_SPLIT) {
          fromPosition = "top-left";
          toPosition = "bottom-right";
        } else {
          fromPosition = "bottom-right";
          toPosition = "top-left";
        }
      } else {
        if (toMode === UIMode.MODE_HORIZONTAL_SPLIT) {
          fromPosition = "top-right";
          toPosition = "bottom-left";
        } else {
          fromPosition = "bottom-left";
          toPosition = "top-right";
        }
      }
      move(view,fromPosition,toPosition);
    };

    try {
      const views = Context.getInstance().views;
      if (views) {
        processView(views.mapView);
        processView(views.sceneView);
      }
    } catch(ex) {
      console.warn("UIMode::moveViewTools error");
      console.error(ex);
    }
  }

  supportsNavigator() {
    let v = false;
    // v = true; // TODO temporary
    // console.log("UIMode::supportsNavigator() always true *************************");
    if (this.modeIsHorizontalSplit() || this.modeIsVerticalSplit()) {
      if (this.isMobile && !this.isKiosk) {
        v = true;
      }
    }
    return v;
  }

  toggleConfigurator(show) {
    //console.log("toggleConfigurator",show)
    if (show) {
      this.isConfiguratorOn = true;
      document.body.classList.add("i-configurator-on");
    } else {
      this.isConfiguratorOn = false;
      document.body.classList.remove("i-configurator-on");
    }
  }

  _watchEsc() {
    // close SidebarPanel or InfoPanel if Esc is pressed from within
    window.addEventListener("keydown", event => {
      if (event && event.keyCode === 27) {
        let node = document.activeElement;
        while (node) {
          if (node && node.classList) {
            if (node.classList.contains("esri-search__input")) {
              // issue with core widget not stopping propagation
              let nd = document.body.querySelector(".esri-search--show-suggestions");
              if (nd) break;
            } else if (node.classList.contains("i-sidebar-panel")) {
              Topic.publish(Topic.CloseSidebarPanel,{});
              break;
            } else if (node.classList.contains("i-infopanel")) {
              Topic.publish(Topic.CloseInfoPanel,{});
              break;
            }
          }
          //@ts-ignore
          node = node.parentNode;
        }
      }
    });
  }

}
