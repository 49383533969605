import BaseClass from "../../../util/BaseClass";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import  { escSqlQuote } from "../../../aiim/util/selectionUtil";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import OfficePlan from "../../base/OfficePlan";

export interface IAreaStats {
  peopleCount: number,
  hotDeskCount: number,
  meetingRoomCount: number,
  hotelCapacity: number
}

export default class AreaStatsModel extends BaseClass {

  async queryStats(areaItem: __esri.Graphic): Promise<IAreaStats> {
    const info: IAreaStats = {
      peopleCount: 0,
      hotDeskCount: 0,
      meetingRoomCount: 0,
      hotelCapacity: 0
    };
    const promises = [];

    const areaId = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREA_ID)
    const unitsSource = officePlanUtil.getUnitsSource();
    const unitFields = unitsSource.layer2D.fields;
    const capacityField = aiimUtil.findField(unitFields,FieldNames.UNITS_CAPACITY);
    const unitsAreaIdField = aiimUtil.findFieldName(unitFields,FieldNames.UNITS_AREA_ID);
    const unitsAsnTypeField = aiimUtil.findFieldName(unitFields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    
    const peopleCountOptions = {
      source: officePlanUtil.getPeopleSource(),
      where: officePlanUtil.makeWhereForPeopleAssignedToArea(areaItem)
    };
    const pPeopleCount = queryUtil.queryCount(peopleCountOptions);
    promises.push(pPeopleCount);
    pPeopleCount.then(v => info.peopleCount = v)

    const hotDeskCountOptions = {
      source: unitsSource,
      where: `(${unitsAreaIdField} = '${escSqlQuote(areaId)}') AND (${unitsAsnTypeField} = '${OfficePlan.SpaceAssignmentTypes.hotdesk}')`
    };
    const pHotDeskCount = queryUtil.queryCount(hotDeskCountOptions);
    promises.push(pHotDeskCount);
    pHotDeskCount.then(v => info.hotDeskCount = v)

    const meetingRoomCountOptions = {
      source: unitsSource,
      where: `(${unitsAreaIdField} = '${escSqlQuote(areaId)}') AND (${unitsAsnTypeField} = '${OfficePlan.SpaceAssignmentTypes.meetingroom}')`
    };
    const pMeetingRoomCount = queryUtil.queryCount(meetingRoomCountOptions);
    promises.push(pMeetingRoomCount);
    pMeetingRoomCount.then(v => info.meetingRoomCount = v)

    let hotelCapacityStat = 0, hotelCapacityNullCount = 0;
    if (capacityField) {
      const hotelCapacityOptions = {
        source: unitsSource,
        where: `(${unitsAreaIdField} = '${escSqlQuote(areaId)}') AND (${unitsAsnTypeField} = '${OfficePlan.SpaceAssignmentTypes.hotel}')`,
        outStatistics: [{
          onStatisticField: capacityField.name,
          outStatisticFieldName: "sumCapacity",
          statisticType: "sum"
        }],
      };
      const pHotelCapacityStat = queryUtil.queryStats(hotelCapacityOptions);
      promises.push(pHotelCapacityStat);
      pHotelCapacityStat.then(result => {
        const v = aiimUtil.getAttributeValue(result?.features[0]?.attributes,"sumCapacity");
        if (typeof v === "number") hotelCapacityStat = v;
      })
      const nullCapacityCountOptions = {
        source: unitsSource,
        where: `(${unitsAreaIdField} = '${escSqlQuote(areaId)}') AND (${unitsAsnTypeField} = '${OfficePlan.SpaceAssignmentTypes.hotel}')` + 
          ` AND (${capacityField.name} IS NULL)`
      };
      const pNullCapacityCount = queryUtil.queryCount(nullCapacityCountOptions);
      promises.push(pNullCapacityCount);
      pNullCapacityCount.then(v => {
        if (typeof v === "number") hotelCapacityNullCount = v;
      })
    }

    await Promise.all(promises)
    info.hotelCapacity = (hotelCapacityStat + hotelCapacityNullCount);
    return info
  }

}
