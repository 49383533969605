import BaseClass from "../../../util/BaseClass"
import Context from "../../../context/Context";
import DirectionsUtil from "../../../util/DirectionsUtil";

const CSS = {
  main: "i-print-window",
  noPrint: "i-print-window-no-print",
  content: "i-print-window-content",
  actions: "i-print-window-actions i-print-window-no-print",
  actionButton: "btn",
  title: "i-print-window-title",
  map: "i-print-window-map i-print-window-wait",
  mapImage: "i-print-window-mapimage",
  wait: "i-print-window-wait",
  failed: "i-print-window-failed",
  pageBreak: "i-print-window-page-break",
  notes: "i-print-window-notes i-print-window-no-screen",
  notesArea: "i-print-window-notes-area i-print-window-no-print",
  directions: "i-print-window-directions",
  directionsRow: "i-print-window-directions-row",
  directionsRowEvent: "i-print-window-directions-row-event",
  directionsEvent: "i-print-window-directions-event",
  //routeZoomClass: "",
  routeIconColumn: "",
  routeIcon: "i-print-window-row-icon",
  routeStepColumn: "",
  routeStep: "i-print-window-row-step",
  routeInfoColumn: "",
  routeInfo: "i-print-window-row-info",
  routeText: "i-print-window-row-text",
  routeLength: "i-print-window-row-length",
  routeLengthCumulative: "i-print-window-row-length-cumulative",
  costSummary: "i-print-window-cost-summary",
  logo: "i-brand-logo-print"
};

export default class Print extends BaseClass {

  directions;
  lastRoute;
  routeParameters;
  routeResult;
  serviceDescription;
  stops;
  updateDelay = 1000;
  util = new DirectionsUtil();

  sizeInfo = {
    dpi: 384,  // base was 96 dpi, width: 800px height: 400px
    mapWidth: 3200,
    mapHeight: 2400,
    pageWidth: "800px",
    pageHeight: "600px"
  };

  boldText(s1,s2) {
    let v = s1;
    try {
      const re = new RegExp("[^<strong>&nbsp;]" + s2.replace(/(\||\$|\^|\(|\)|\[|\]|\{|\}|\/|\.|\+|\*|\?|\?)/g, "\\$1") + "[^&nbsp;</strong>]", 'g');
      v = (" " + v + " ").replace(re, "<strong>&nbsp;" + s2 + "&nbsp;</strong>");
      v = v.trim();
    } catch(err){}
    return v;
  }

  createPrintTask() {
    const lib = Context.getInstance().lib;

    const makeDestinationSymbol = () => {
      const sym = new lib.esri.SimpleMarkerSymbol({
        style: "circle",
        size: 19,
        color: [255, 255, 255, 1],
        outline: { color: [51, 51, 51, 1], width: 3 }
      });
      return sym;
    };
    const makeDestinationSymbol2 = () => {
      const sym = new lib.esri.SimpleMarkerSymbol({
        style: "square",
        size: 7,
        color: [20, 20, 20, 1],
        outline: null
      });
      return sym;
    };


    const url = this.getPrintTaskUrl();
    const task = new lib.esri.PrintTask({
      url: url,
      updateDelay: this.updateDelay
    });

    const orig = task._createFeatureCollectionJSON;
    task._createFeatureCollectionJSON = (graphicsLayer, graphics, printTemplate) => {
      if (!graphics && graphicsLayer &&
        graphicsLayer.declaredClass === "esri.layers.GraphicsLayer" &&
        graphicsLayer.type === "graphics") {
        const newGraphics = [];
        if (graphicsLayer.title === "indoorsRouteGraphicsLayer" ||
            graphicsLayer.title === "indoorsHighlightGraphicsLayer" ||
            graphicsLayer.title === "indoorsStopGraphicsLayer" ||
            graphicsLayer.title === "indoorsLocationGraphics") {
          const gfx = graphicsLayer.graphics.items;
          if (gfx && gfx.length > 0) {
            for (let i = 0; i < gfx.length; i++) {
              let g = gfx[i] || gfx.getItemAt(i);
              //console.log("g",g);
              //console.log("g.json",g.toJSON());
              if (g && g.geometry) {
                let json = g.toJSON();
                if (json && json.geometry) {
                  json.geometry.hasZ = false;
                  delete json.geometry.z;

                  let override = true, overrideLines = false;
                  if (overrideLines && json.symbol && g.geometry.type === "polyline" &&
                      graphicsLayer.title === "indoorsRouteGraphicsLayer" &&
                      json.symbol.style === "esriSLSShortDash") {
                    json.symbol.style = "esriSLSDash";
                    newGraphics.push(lib.esri.Graphic.fromJSON(json));
                  } else if (override && json.symbol &&
                      (g.geometry.type === "point" || g.geometry.type === "polygon") &&
                      graphicsLayer.title === "indoorsStopGraphicsLayer" &&
                      json.symbol.contentType === "image/svg+xml") {
                    let sym = makeDestinationSymbol();
                    if (sym) json.symbol = sym.toJSON();
                    newGraphics.push(lib.esri.Graphic.fromJSON(json));
                    let sym2 = makeDestinationSymbol2();
                    if (sym2) {
                      let json2 = g.toJSON();
                      json2.geometry.hasZ = false;
                      delete json2.geometry.z;
                      json2.symbol = sym2.toJSON();
                      newGraphics.push(lib.esri.Graphic.fromJSON(json2));
                    }
                  } else if (override && json.symbol &&
                      (g.geometry.type === "point" || g.geometry.type === "polygon") &&
                      graphicsLayer.title === "indoorsLocationGraphics" &&
                      json.symbol.contentType === "image/svg+xml") {
                  } else {
                    newGraphics.push(lib.esri.Graphic.fromJSON(json));
                  }
                }
              }
            }
          }
        }
        if (newGraphics && newGraphics.length > 0) {
          return orig.call(task,{},newGraphics,printTemplate);
        }
      }
      return orig.call(task,graphicsLayer,graphics,printTemplate);
    };

    return task;
  }

  getLetter(stopIndexOrStopGraphic) {
    return "";
  }

  getPrintTaskUrl() {
    let url = null;
    try {
      const portal = Context.getInstance().portal;
      if (portal && portal.helperServices && portal.helperServices.printTask) {
        const v = portal.helperServices.printTask.url;
        if (typeof v === "string" && v.length > 0) {
          if (v.indexOf("tools/newPrint") === -1) {
            url = Context.checkMixedContent(v);
          }
        }
      }
      if (!url) {
        console.warn("The Portal print task has not been configured.");
      }
    } catch(ex) {
      console.warn("Error getting PrintTask url:");
      console.error(ex);
    }
    return url;
  }

  getRouteName() {
    if (this.routeResult) {
      const v = this.routeResult.routeName;
      if (typeof v === "string" && v.length > 0) {
        return v;
      }
    }
    return null;
  }

  loadPrintWindow(printWindow,hasImage) {

    const lib = Context.getInstance().lib;
    const domConstruct = lib.dojo.domConstruct;
    const domGeom = lib.dojo.domGeom;
    var pageLayout=Context.getInstance().config.print.layout;
    if(pageLayout!=="letter" && pageLayout!=="a4" && pageLayout!=="map-only"){
      pageLayout="map-only";
    }
    const i18n = Context.getInstance().i18n;
    const routeName = this.getRouteName();
    console.log("Route Name"+routeName);
    const title = routeName ? routeName: ""; // TODO esc the title

    let dir = "ltr";
    if (!domGeom.isBodyLtr()) dir = "rtl";

    let doc = "";
    doc += "<!DOCTYPE HTML>";
    doc += "<html lang='en' dir='" + dir + "'>"; // TODO lang= ?
    doc += "<html dir='" + dir + "'>";
    doc += "<head>";
    doc += "<meta charset='utf-8'>";
    doc += "<meta http-equiv='X-UA-Compatible' content='IE=Edge,chrome=1'>";
    doc += "<meta http-equiv='Cache-Control' content='no-cache, no-store, must-revalidate' />";
    doc += "<meta http-equiv='Pragma' content='no-cache' />";
    doc += "<meta http-equiv='Expires' content='0' />";
    doc += "<title>" + title + "</title>";
    doc += "<link rel='stylesheet' media='screen' type='text/css' href='./app/style/css/directions-screen.css' />";
    doc += "<link rel='stylesheet' media='print' type='text/css' href='./app/style/css/directions-print.css' />";
    doc += "<link rel='stylesheet' type='text/css' href='./app/style/css/theme.css' />";
    doc += "</head>";
    doc += "<body class='" + CSS.main + "'>";
    doc += "</body>";
    doc += "</html>";
    printWindow.document.open("text/html", "replace");
    printWindow.document.write(doc);
    printWindow.document.close();

    const content = domConstruct.create("div",{
      "class": CSS.content,
    },printWindow.document.body,"last");
    printWindow.document.body.appendChild(content);

    let cssActionButton = CSS.actionButton;
    const uiMode = Context.getInstance().uiMode;
    if (uiMode.isTouch) {
      if (uiMode.modeIsHorizontalSplit() || uiMode.modeIsVerticalSplit()) {
        cssActionButton += " i-large";
      }
    }

    const actions = domConstruct.create("div",{
      "class": CSS.actions
    },content,"last");

    domConstruct.create("button",{
      "class": cssActionButton,
      innerHTML: Context.sanitizeHtml(i18n.general.print),
      onclick: "window.print();"
    },actions,"last");

    domConstruct.create("button",{
      "class": cssActionButton,
      innerHTML: Context.sanitizeHtml(i18n.general.close),
      onclick: "window.close();"
    },actions,"last");

    if(pageLayout==="map-only"){
     this.renderLogo(printWindow,content);
     domConstruct.create("div",{
       "class": CSS.title,
       "innerHTML": Context.sanitizeHtml(title)
     },content,"last");
    }

    if (hasImage) {
      domConstruct.create("div",{
        "class": CSS.map,
        id: "divMap"
      },content,"last");
      domConstruct.create("hr",{
        "class": CSS.noPrint
      },content,"last");
    }

    this.renderSummary(printWindow,content);

    var printNotesNode = domConstruct.create("div",{
      "class": CSS.notes,
      id: "print-notes"
    },content,"last");

    domConstruct.create("textarea",{
      "class": CSS.notesArea,
      id: "print-notes-area",
      onchange: function() {
        printNotesNode.innerHTML = Context.sanitizeHtml(this.value);
      },
      placeholder: i18n.directions.printWindow.notes
    },content,"last");

    this.renderDirections(printWindow,content);
  }

  usePrintService(view, printWindow, pageLayout) {
    const routeName = this.getRouteName();
    const title = routeName ? routeName: "";
    const lib = Context.getInstance().lib;

    let i18n = Context.getInstance().i18n;
    let task = this.createPrintTask();

    let sizeInfo = this.sizeInfo;

    let template;
    let templateObj=new lib.esri.PrintTemplate().set({
      attributionVisible: false, // TODO?
      preserveScale: false, // TODO?
      showLabels: true, // TODO?
      format: "png32", // TODO pdf png32 ?
      layout: "letter-ansi-a-portrait", // or A4-landscape
      layoutOptions: {
        titleText:title,
        scalebarUnit: "Feet",
        legendLayers:[]
      }
      })

    /*  letter = letter-ansi-a-portrait
        a4= a4-portrait
        map-only = map-only */
     if(pageLayout==="letter"){
       template=templateObj;
       template.layout="letter-ansi-a-portrait"
     }else if (pageLayout==="a4"){
       template=templateObj;
       template.layout="a4-portrait";
     }else{
       template=templateObj;
       template.layout="map-only";
       template.exportOptions={
        width: sizeInfo.mapWidth,
        height: sizeInfo.mapHeight,
        dpi: sizeInfo.dpi
       }
     }

    // TODO params.outSpatialReference = this.map.spatialReference; v3 directions widget

    let params = new lib.esri.PrintParameters({
      view: view,
      outSpatialReference: view.spatialReference,
      template: template,
    });

    // this.zoomToFullRoute() TODO?

    task.execute(params).then(result => {
      //console.log("Print.result",result);
      lib.dojo.window.withDoc(printWindow.document,function() {
        let container = lib.dojo.dom.byId("divMap");
        if (container) {
          let style = "width:"+sizeInfo.pageWidth+";height:"+sizeInfo.pageHeight+";";
          lib.dojo.domClass.remove(container,CSS.wait);
          lib.dojo.domClass.add(container,CSS.pageBreak);
          if(pageLayout ==="map-only"){
          lib.dojo.domConstruct.create("img",{
            "src": result.url,
            "class": CSS.mapImage,
            "style": style,
            "alt": i18n.directions.printWindow.mapAltText
          },container);
        }else{
          lib.dojo.domConstruct.create("img",{
            "src": result.url,
            "class": CSS.mapImage,
            "alt": i18n.directions.printWindow.mapAltText
          },container);
        }
        }
      });
    }).catch(error => {
      console.warn("Error creating print:");
      console.error(error);
      lib.dojo.window.withDoc(printWindow.document,function() {
        let container = lib.dojo.dom.byId("divMap");
        if (container) {
          lib.dojo.domClass.remove(container,CSS.wait);
          lib.dojo.domClass.add(container,CSS.failed);
          container.innerHTML = Context.sanitizeHtml(i18n.directions.printWindow.failed);
        }
      });
    });
  }

  useScreenshot(view, printWindow) {
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const sizeModifier = 0.75;

    const modifySize = (size) => {
      return size * sizeModifier;
    };

    view.takeScreenshot().then((screenshot) => {
      const pageWidth = `${modifySize(screenshot.data.width)}px`;
      const pageHeight = `${modifySize(screenshot.data.height)}px`;
      lib.dojo.window.withDoc(printWindow.document, function() {
        let container = lib.dojo.dom.byId("divMap");
        if (container) {
          let style = `width:${pageWidth};height:${pageHeight};`;
          lib.dojo.domClass.remove(container, CSS.wait);
          lib.dojo.domClass.add(container, CSS.pageBreak);
          lib.dojo.domConstruct.create("img", {
            "src": screenshot.dataUrl,
            "class": CSS.mapImage,
            "style": style,
            "alt": i18n.directions.printWindow.mapAltText
          }, container);
        } else {
          lib.dojo.domConstruct.create("img", {
            "src": screenshot.dataUrl,
            "class": CSS.mapImage,
            "alt": i18n.directions.printWindow.mapAltText
          }, container);
        }
      });
    }).catch(error => {
        console.warn("Error creating print:");
        console.error(error);
        lib.dojo.window.withDoc(printWindow.document, function() {
          let container = lib.dojo.dom.byId("divMap");
          if (container) {
            lib.dojo.domClass.remove(container, CSS.wait);
            lib.dojo.domClass.add(container, CSS.failed);
            container.innerHTML = Context.sanitizeHtml(i18n.directions.printWindow.failed);
          }
        });
      });
  }

  print() {
    const configuration = Context.getInstance().config;
    const view = Context.getInstance().views.activeView; // TODO will not work for 3d
    const map = view && view.map;
    const hasFloorInfo = !!(map && map.floorInfo);

    var pageLayout=configuration.print.layout;
    if(pageLayout!=="letter" && pageLayout!=="a4" && pageLayout!=="map-only"){
      pageLayout="map-only";
    }

    // TODO params.outSpatialReference = this.map.spatialReference; v3 directions widget

    let w = window.screen.width / 2;
    let h = window.screen.height / 1.5;
    let left = (window.screen.width / 2) - (w / 2);
    let top = (window.screen.height / 2) - (h / 2);
    let options = "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes";
    options += ",width=" + w + ",height=" + h + ",top=" + top + ",left=" + left;
    let printWindow = window.open("","indoors_print_directions",options,true);
    this.loadPrintWindow(printWindow,true);

    // this.zoomToFullRoute() TODO?

    this.useScreenshot(view, printWindow, pageLayout);

    // if (hasFloorInfo) {
    //   this.useScreenshot(view, printWindow, pageLayout);
    // } else {
    //   this.usePrintService(view, printWindow, pageLayout);
    // }
  }

  renderDirections(printWindow,parentNode) {
    if (!this.routeResult) return;
    const lib = Context.getInstance().lib;
    const domConstruct = lib.dojo.domConstruct;
    let result = this.routeResult;
    let sections = result.sections;
    if (sections && sections.length > 0) {
      let table = domConstruct.create("table",{
        "class": CSS.directions,
      },parentNode,"last");
      const tbody = domConstruct.create("tbody",{},table,"last");
      let sequence = 0;
      sections.forEach(section => {
        section.steps.forEach(step => {
          if (!step.isEvent) sequence++;
          this.renderStep(printWindow,tbody,section,step,sequence);
        });
      });
    }
  }

  renderLogo(printWindow,parentNode) {
    console.log("inside logo");
    const lib = Context.getInstance().lib;
    const domConstruct = lib.dojo.domConstruct;
    const url = Context.getInstance().uiMode.getLogoUrl();
    const width = "auto";
    const height = Context.instance.uiMode.getLogoSize() + "rem";
    // const cs = window.getComputedStyle(document.documentElement,null);
    // const width = cs.getPropertyValue("--i-logo-print-width");
    // const height = cs.getPropertyValue("--i-logo-print-height");
    let style = "clear: both; display: block;"
    if (typeof width === "string" && width.trim().length > 0) {
      style += " width: "+width.trim()+";";
    }
    if (typeof height === "string" && height.trim().length > 0) {
      style += " height: "+height.trim()+";";
    }
    if (typeof url === "string" && url.length > 0) {
      //console.log("renderLogo url",url,"style",style);
      domConstruct.create("img",{
        "src": url,
        "style": style,
        "class": CSS.logo
      },parentNode,"last");
    }
    console.log(CSS.logo);
  }

  renderStep(printWindow, parentNode, section, step, sequence) {
    const lib = Context.getInstance().lib;
    const domConstruct = lib.dojo.domConstruct;
    let rowClass = CSS.directionsRow;
    if (step.isEvent) rowClass = CSS.directionsRowEvent;
    const tr = domConstruct.create("tr",{
      "class": rowClass,
      "data-segment": sequence,
      "tab-index": "0",
      "role": "menuitem"
    },parentNode,"last");

    // https://devtopia.esri.com/WebGIS/arcgis-js-api/blob/4master/esri/widgets/Directions/support/maneuverUtils.ts
    const tdIcon = domConstruct.create("td",{
      "class": CSS.routeIconColumn
    },tr,"last");
    const iconUrl = step.maneuverIconUrl;
    if (iconUrl) {
      domConstruct.create("img",{
        "class": CSS.routeIcon,
        src: iconUrl
      },tdIcon,"last");
    }

    let v = "";
    if (!step.isEvent) {
      v = lib.dojo.number.format(sequence) + ". ";
    }
    const tdSequence = domConstruct.create("td",{
      "class": CSS.routeStepColumn
    },tr,"last");
    domConstruct.create("strong",{
      innerHTML: Context.sanitizeHtml(v)
    },tdSequence,"last");

    const tdInfo = domConstruct.create("td",{
      "class": CSS.routeInfoColumn
    },tr,"last");
    const info = domConstruct.create("div",{
      "class": CSS.routeInfo,
      innerHTML: Context.sanitizeHtml(this.getLetter(null)) // f._associatedStop
    },tdInfo,"last");

    const text = step.text;
    const routeText = domConstruct.create("div",{
      "class": CSS.routeText
    },info,"last");
    domConstruct.create("span",{
      innerHTML: Context.sanitizeHtml(text)
    },routeText,"last");

    let showCost = false;
    if (!step.isEvent && !step.isPseudoEvent) {
      const mtype = step.maneuverType;
      if (mtype && mtype !== "esriDPTManeuverArrive" && mtype !== "esriDPTManeuverDepart") {
        showCost = true;
      }
    }
    if (showCost) {
      const distance = section.distanceText;
      if (distance) {
        const routeLength = domConstruct.create("div",{
          "class": CSS.routeLength
        },info,"last");
        domConstruct.create("span",{
          innerHTML: distance
        },routeLength,"last");
        const time = section.timeText;
        if (time) {
          domConstruct.create("span",{
            innerHTML: "&nbsp;&middot;<wbr>&nbsp;",
            style:"text-align:center"
          },routeLength,"last");
          domConstruct.create("span",{
            innerHTML: time
          },routeLength,"last");
        }
      }
    }
  }

  renderSummary(printWindow,parentNode) {
    if (!this.routeResult) return;
    const lib = Context.getInstance().lib;
    const domConstruct = lib.dojo.domConstruct;
    const result = this.routeResult;
    let distance = result.totalDistanceText;
    //let distanceOnly=this.util.getDistanceOnly();
    //console.log("Only *********",distanceOnly);

    if (distance) {
      const routeLength = domConstruct.create("div",{
        "class": CSS.costSummary
      },parentNode,"last");
      domConstruct.create("span",{
        innerHTML: distance
      },routeLength,"last");
      const time = result.totalTimeText;
      if (time) {
        domConstruct.create("span",{
          innerHTML: "&nbsp;&middot;<wbr>&nbsp;"
        },routeLength,"last");
        domConstruct.create("span",{
          innerHTML: time
        },routeLength,"last");
      }
    }
  }

}
