import BaseClass from "../../../util/BaseClass";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import Context from "../../../context/Context";

export default class VirtualKeyboard extends BaseClass {

    keyboard;
    closeKeyboard;
    handleInputChange;
    handleEnterClick;
    handleCloseClick;
    ignoreNodes = [];

    clearInput(){
        if(!this.keyboard) return;
        this.keyboard.clearInput();
    }

    closeKeyboard(fromVirtualKeyboard){
        document.getElementsByClassName('simple-keyboard')[0].style.display='none';
        document.body.classList.remove('i-keyboard-open');
        if(this.handleCloseClick) this.handleCloseClick(fromVirtualKeyboard);
    }

    enabled(){
        if(Context.instance.uiMode.isKiosk && Context.instance.config && Context.instance.config.kiosk && Context.instance.config.kiosk.kioskVirtualKeyboard
            && Context.instance.config.kiosk.kioskVirtualKeyboard.enabled) {
                return true;
        }
        return false;
    }

    _handleShift() {
        if(!this.keyboard) return;
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";
        this.keyboard.setOptions({
          layoutName: shiftToggle
        });
    }

    init=()=>{
        if(!this.enabled()) return;
        window.addEventListener('click', (event)=> {
            if(!this.isOpen()) return;
            if(document.getElementsByClassName('simple-keyboard')[0].contains(event.target)){}
            else if (document.getElementsByClassName('i-map')[0].contains(event.target)){
                this.closeKeyboard(false);
            }else{
                const inputNode = document.activeElement; 
                let elementsList = this.ignoreNodes;
                if(!elementsList || elementsList.length === 0) return;
                let flag = false;
                for (var index = 0; index < elementsList.length; index++) {
                    var element = elementsList[index];
                    if (element === inputNode) {
                        flag = true;
                    }
                }
                if(!flag) this.closeKeyboard(true);
            }
        })
        this.setSize(Context.instance.config.kiosk.kioskVirtualKeyboard.size);
    }

    isOpen() {
        return (!!document.body.classList.contains('i-keyboard-open'));
    }

    _onChange(input){
        if(this.handleInputChange) this.handleInputChange(input, this.keyboard.caretPosition);
    }

    _onKeyPress(button) {
        if (button === "{shiftleft}" || button === "{lock}") this._handleShift();
        if (button === "{close}") if(this.closeKeyboard) this.closeKeyboard(true);
        if (button === "{enter}") if(this.handleEnterClick) this.handleEnterClick();
    }

    setSize(size){
        var ele = document.getElementsByClassName('simple-keyboard-size')[0];
        if(size === "small") {
            ele.classList.remove("i-large-size");
            ele.classList.remove("i-medium-size");
            ele.classList.add("i-small-size");
        }else if(size === "large") {
            ele.classList.remove("i-small-size");
            ele.classList.remove("i-medium-size");
            ele.classList.add("i-large-size");
        }else {
            ele.classList.remove("i-large-size");
            ele.classList.remove("i-small-size");
            ele.classList.add("i-medium-size");
        }
    }

    showSimpleKeyboard(options) {
        if(!this.enabled()) return;

        this.handleInputChange = options.handleInputChange;
        this.handleEnterClick = options.handleEnterClick;
        this.handleCloseClick = options.handleCloseClick;
        if(options.ignoreElements) this.ignoreNodes = options.ignoreElements;
        else if(options.ignoreReferences) {
            this.ignoreNodes = options.ignoreReferences;
        }else{
            this.ignoreNodes = null;
        }
        
        if(!this.keyboard) {
            this.keyboard = new Keyboard({
                onChange: input => this._onChange(input),
                onKeyPress: button => this._onKeyPress(button),
                preventMouseDownDefault: true,
                stopMouseDownPropagation:true,
                useMouseEvents:true,
                layout: {
                    default: [
                        "{close}",
                        "1 2 3 4 5 6 7 8 9 0 {backspace}",
                        "q w e r t y u i o p",
                        "a s d f g h j k l {enter}",
                        "{shiftleft} z x c v b n m",
                        "{space}"
                    ],
                    shift: [
                        "{close}",
                        "1 2 3 4 5 6 7 8 9 0 {backspace}",
                        "Q W E R T Y U I O P",
                        'A S D F G H J K L {enter}',
                        "{shiftleft} Z X C V B N M",
                        "{space}"
                    ]
                },
                display: {
                    "{backspace}": "backspace ⌫",
                    "{enter}": "enter ↵",
                    "{shiftleft}": "shift ⇧",
                    "{space}": "space",
                    "{close}":"✕"
                },
                buttonTheme: [
                    {
                      class: "hg-button",
                      buttons: 
                      "  ~ ! @ # $ % ^ & * ( ) _ + {backspace} Q W E R T Y U I O P { } | A S D F G H J K L : {enter} {shiftleft} Z X C V B N M < > ? {space}"
                    },
                    {
                      class: "hg-close",   
                      buttons: "{close}"
                    } 
                ]
            });
        }
        this.keyboard.setInput(options.value);
        document.getElementsByClassName('simple-keyboard')[0].style.display='block';
        document.body.classList.add('i-keyboard-open');
    }
} 