import BaseClass from "../../util/BaseClass"
import Context from "../../context/Context";
import FieldNames from "../datasets/FieldNames";
import Topic from "../../context/Topic";
import * as aiimUtil from "../util/aiimUtil";
import * as selectionUtil from "../util/selectionUtil";
import type Source from "./Source";
import { ILevelData } from "../datasets/Levels";

export interface IFacilityInfo {
  facilityId: string,
  facilityName: string,
  facilitiesSource: Source,
  levels: ILevelData[],
  activeLevel: ILevelData,
  zeroVOLevel: ILevelData,
  lastCamera,
  lastExtent2D,
}
export default class FacilityMode extends BaseClass {

  activeFacilityInfo: IFacilityInfo;

  _stamp;
  _tmpTestLevels;
  _use2DShells = true;
  _use2DShellTransparency = false;

  constructor(props?) {
    super(props);
    this._stamp = Date.now();

    this.own([

      Topic.subscribe(Topic.ActivateLevel, info => {
        //console.log("FacilityMode.subscribe.ActivateLevel",info)
        if (info && info.facilityData && info.levelData && info.view) {
          const facilityInfo = this._newFacilityInfo(info);
          this._activateFacility(facilityInfo,info.facilityData.facilityId,
            info.levelData,info.view,info.origin);
        }
      }),

      Topic.subscribe(Topic.AppStarted, info => {
        this._init();
      }),

      Topic.subscribe(Topic.DeactivateFacilityClicked, info => {
        try {
          const facilities = Context.getInstance().aiim.datasets.facilities;
          if (facilities) {
            const lastCamera = info.facilityInfo && info.facilityInfo.lastCamera;
            const lastExtent2D = info.facilityInfo && info.facilityInfo.lastExtent2D;
            this.activeFacilityInfo = null;
            selectionUtil.resetAll(info.view);
            this._toggleShell(info.view,lastCamera,lastExtent2D);
            Topic.publish(Topic.FacilityDeactivated,{view: info.view});
            Topic.publish(Topic.FacilityModeUpdated,{facilityMode: this, view: info.view});
          }
        } catch(ex) {
          console.error("ViewMode::Topic.DeactivateFacilityClicked",ex);
        }
      }),

      Topic.subscribe(Topic.ItemOpened, info => {
        if (info && info.feature && info.source) {
          this._handleItemOpened(info);
        }
      }),

      Topic.subscribe(Topic.LevelSelected, info => {
        try {
          if (this.activeFacilityInfo && this.activeFacilityInfo.facilityId === info.facilityId) {
            const levelData = info.levelData;
            const criteria = {
              facilityId: info.facilityId,
              levelNumber: levelData && levelData.levelNumber,
              levelData: levelData
            };
            this.activeFacilityInfo.activeLevel = levelData;
            selectionUtil.selectFacility(info.view,criteria);
            Topic.publish(Topic.FacilityModeUpdated, {
              facilityMode: this,
              view: info.view,
              origin: info.origin
            });
          }
        } catch(ex) {
          console.error("ViewMode::Topic.LevelSelected",ex);
        }
      }),

      Topic.subscribe(Topic.ViewActivated, info => {
        this._setViewCursor(false);
      }),

      Topic.subscribe(Topic.ViewsReloaded, info => {
        this._init();
      })

    ]);
  }

  _activateFacility(facilityInfo,facilityId,levelData,view,origin?) {
    try {
      facilityInfo.facilityId = facilityId;
      const levelsDataset = Context.getInstance().aiim.datasets.levels;
      const facilityData = levelsDataset.getFacilityData(facilityId);
      let levels = facilityData && facilityData.levels;

      // TODO: temporary
      // Test only, make some additional level data for ESRI.RED.MAIN.O
      //levels = this._makeTestLevels(facilityId,levels);

      facilityInfo.levels = levels;
      facilityInfo.zeroVOLevel = levelsDataset.getZeroVOLevel(facilityData);

      if (typeof levelData === "number") {
        if (levels && levels.length > 0) {
          const ln = levelData;
          levels.some(level => {
            if (level.levelNumber === ln) {
              levelData = level;
              return true;
            }
            return false;
          });
        }
      }

      const criteria = {
        facilityId: facilityId,
        levelNumber: levelData && levelData.levelNumber,
        levelData: levelData
      };
      this.activeFacilityInfo = facilityInfo

      if (typeof criteria.levelNumber === "number") {
        facilityInfo.activeLevel = levelData;
        selectionUtil.selectFacility(view,criteria);
      } else {
        if (view && view.type === "2d" && facilityInfo.zeroVOLevel) {
          criteria.levelNumber = facilityInfo.zeroVOLevel.levelNumber;
          selectionUtil.selectFacility(view,criteria);
        } else {
          selectionUtil.resetAll(view); // TODO?
        }
      }

      this._toggleShell(view);
      Topic.publish(Topic.FacilityActivated,{
        facilityInfo: facilityInfo, view: view
      });
      Topic.publish(Topic.FacilityModeUpdated,{
        facilityMode: this, view: view, origin: origin, wasActivateFacility: true
      });

    } catch(ex) {
      // TODO need to test this out
      console.error("Error activating facility",facilityInfo,ex);
    }
  }

  activateLevelFilter(view,facilityId,levelNumber) {
    try {
      let lastCamera = (view && view.camera) ? view.camera.clone() : null;
      let lastExtent2D = (view && view.type === "2d" && view.extent) ? view.extent.clone() : null;
      if (this.activeFacilityInfo && this.activeFacilityInfo.lastCamera) {
        lastCamera = this.activeFacilityInfo.lastCamera;
      }
      if (this.activeFacilityInfo && this.activeFacilityInfo.lastExtent2D) {
        lastExtent2D = this.activeFacilityInfo.lastExtent2D;
      }
      const facilityInfo = {
        facilityId: null,
        facilityName: null,
        facilitiesSource: this._getFacilitiesSource(),
        levels: null,
        activeLevel: null,
        zeroVOLevel: null,
        lastCamera: lastCamera,
        lastExtent2D: lastExtent2D
      }
      if (typeof facilityId === "string" && facilityId.length > 0) {
        this._activateFacility(facilityInfo,facilityId,levelNumber,view);
      }
    } catch(ex) {
      console.error("ViewMode::activateLevelFilter",ex);
    }
  }

  applyActiveFacilityInfo(view) {
    const facilityInfo = this.activeFacilityInfo;
    if (!facilityInfo) {
      const facilities = Context.getInstance().aiim.datasets.facilities;
      if (!facilities) return;
      const lastCamera = null, lastExtent2D = null;
      selectionUtil.resetAll(view);
      this._toggleShell(view,lastCamera,lastExtent2D);
    } else {
      const facilityId = facilityInfo.facilityId;
      const levelData = facilityInfo.activeLevel;
      this._activateFacility(facilityInfo,facilityId,levelData,view);
    }
  }

  _getFacilitiesSource() {
    const categories = Context.getInstance().aiim.datasets.categories;
    return categories.findSourceByKey("Facilities");
  }

  _handleItemOpened(info) {
    try {
      const facilityInfo = this._newFacilityInfo(info);
      const context = Context.getInstance();
      const source = info.source;
      const feature = info.feature;
      const facilities = context.aiim.datasets.facilities;
      const levels = context.aiim.datasets.levels;
      if (facilities && levels && source && feature && feature.geometry) {
        const attributes = feature.attributes;
        let facilityId;
        let isFacilityRef = source.layer2D && (source.layer2D === facilities.layer2D);
        if (source.isAiimFacilities() || isFacilityRef) {
          facilityId = aiimUtil.getAttributeValue(attributes,facilities.facilityIdField);
          if (typeof facilityId === "string" && facilityId.length > 0) {
            this._activateFacility(facilityInfo,facilityId,null,info.view);
          }
        } else {
          const zInfo = levels.getZInfo(source,feature);
          facilityId = zInfo && zInfo.facilityId;
          const levelNumber = zInfo && zInfo.levelData && zInfo.levelData.levelNumber;
          if (typeof facilityId === "string" && facilityId.length > 0) {
            this._activateFacility(facilityInfo,facilityId,levelNumber,info.view);
          }
        }
      }
    } catch(ex) {
      console.error("ViewMode::handleItemOpened",ex);
    }
  }

  _init() {
    const context = Context.getInstance();
    if (context.config.setLevelOnStart) {
      const facilities = Context.getInstance().aiim.datasets.facilities;
      if (facilities) {
        selectionUtil.reset2D(context.views.mapView);
      }
    }
    this._watch2D();
    this._watch3D();
    const facilitiesSource = this._getFacilitiesSource();
    const view = Context.getInstance().views.mapView;
    if (view && facilitiesSource) {
      this._toggleLayerVisibilities2D(view,facilitiesSource,false);
    }
  }

  _isActiveFacilityId(view,facilityId) {
    if (this.activeFacilityInfo && this.activeFacilityInfo.facilityId === facilityId) {
      return true;
    }
    return false;
  }

  _makeTestLevels(facilityId,levels) {
    if (facilityId === "ESRI.RED.MAIN.O") {
      if (this._tmpTestLevels) return this._tmpTestLevels;
      let n = 0, levels2 = [];
      for (let i=1;i<=10;i++) {
        for (let j=1;j<=3;j++) {
          n++;
          let levelData = {
            levelNumber: j,
            levelShortName: "Level "+n,
            verticalOrder: j - 1
          };
          levels2.push(levelData);
        }
      }
      this._tmpTestLevels = levels2;
      return levels2;
    }
    return levels;
  }

  _newFacilityInfo(info) {
    const facilityInfo = {
      facilityId: null,
      facilityName: null,
      facilitiesSource: this._getFacilitiesSource(),
      levels: null,
      activeLevel: null,
      zeroVOLevel: null,
      lastCamera: info && info.lastCamera,
      lastExtent2D: info && info.lastExtent2D
    };
    if (this.activeFacilityInfo && this.activeFacilityInfo.lastCamera) {
      facilityInfo.lastCamera = this.activeFacilityInfo.lastCamera;
    }
    if (this.activeFacilityInfo && this.activeFacilityInfo.lastExtent2D) {
      facilityInfo.lastExtent2D = this.activeFacilityInfo.lastExtent2D;
    }
    return facilityInfo;
  }

  setFromHome(homeLocation,feature,view) {
    const info = {
      source: homeLocation.getSource(),
      feature: feature,
      lastCamera: null,
      lastExtent2D: null,
      view: view
    };
    if (info && info.feature && info.source) {
      this._handleItemOpened(info);
    }
  }

  _setViewCursor(bHand) {
    const v = bHand ? "pointer" : "default";
    //Context.instance.lib.dojo.domStyle.set("view-container", {"cursor": v});
    document.getElementById("view-container").style.cursor = v;
    //console.log("_setViewCursor",v);
  }

  _toggleLayerVisibilities2D(view,facilitiesSource,visible) {
    // if (true) return;
    // if (!view || !facilitiesSource) return;
    // let layers = view.map.layers.toArray().slice().reverse();
    // //let layers = view.map.layers.flatten().toArray().slice().reverse();
    // let parentMapService, found;
    // if (facilitiesSource.subLayer) {
    //   parentMapService = facilitiesSource.subLayer.parent;
    //   if (parentMapService.type === "map-image") {
    //     let msLayers = parentMapService.sublayers;
    //     if (msLayers) {
    //       msLayers = msLayers.toArray().slice().reverse();
    //       let layers2 = [];
    //       layers.forEach(layer => {
    //         if (layer === parentMapService) {
    //           msLayers.forEach(msLayer => {
    //             layers2.push(msLayer);
    //           });
    //         } else {
    //           layers2.push(layer);
    //         }
    //       });
    //       layers = layers2;
    //     }
    //   }
    // }
    //
    // if (!layers) return;
    // layers.forEach(layer => {
    //   //console.log(layer.title);
    //   if (layer === facilitiesSource.layer2D || layer === facilitiesSource.subLayer) {
    //     found = true;
    //   } else if (found) {
    //     if (!layer.xtnVisibility) {
    //       layer.xtnVisibility = {
    //         originallyVisible: layer.visible,
    //         visibilitySet: null
    //       }
    //     }
    //     if (typeof visible === "boolean" && layer.xtnVisibility) {
    //       //console.log("toggle",layer.title);
    //       let xtn = layer.xtnVisibility;
    //       if (xtn.visibilitySet === "n" && layer.visible) {
    //         // user checked visibility
    //         xtn.originallyVisible = true;
    //         delete xtn.visibilitySet;
    //       } else if (xtn.visibilitySet === "y" && !layer.visible) {
    //         // user unchecked visibility
    //         xtn.originallyVisible = false;
    //         delete xtn.visibilitySet;
    //       }
    //       if (visible) {
    //         if (!layer.visible && xtn.originallyVisible) {
    //           layer.visible = true;
    //           xtn.visibilitySet = "y";
    //         }
    //       } else {
    //         if (layer.visible) {
    //           layer.visible = false;
    //           xtn.visibilitySet = "n";
    //         }
    //       }
    //     }
    //   }
    // });
  }

  _toggleShell(view,lastCamera?,lastExtent2D?) {
    if (Context.getInstance().isFPE())
      return;

    const setExpression = (facilityId,layer) => {
      selectionUtil.perLayer([layer],(layer,fields) => {
        const field = aiimUtil.findField(fields,FieldNames.FACILITY_ID);
        if (field) {
          const expression = field.name + " <> '" + facilityId + "'";
          layer.definitionExpression = expression;
        }
      });
    };

    const use2DShells = this._use2DShells;
    const facilitiesSource = this._getFacilitiesSource();
    const facilityFootprints = Context.getInstance().aiim.facilityFootprints;
    if (this.activeFacilityInfo) {
      const facilityId = this.activeFacilityInfo.facilityId;

      if (view && view.type === "2d" && use2DShells && facilitiesSource && facilitiesSource.layer2D) {
        facilityFootprints.activating(this.activeFacilityInfo,facilitiesSource);
      }

      if (view && view.type === "2d" && use2DShells && facilitiesSource && facilitiesSource.layer2D) {
        const layer = facilitiesSource.layer2D;
        if (layer.xtnSubLayer) {
          setExpression(facilityId,layer.xtnSubLayer);
        } else {
          setExpression(facilityId,layer);
        }
        this._toggleLayerVisibilities2D(view,facilitiesSource,true);
      }

      if (view && view.type === "3d" && facilitiesSource && facilitiesSource.layer3D) {
        const layer = facilitiesSource.layer3D;
        if (layer.renderer) layer.renderer = null;
        setExpression(facilityId,layer);
      }

    } else {

      if (view && view.type === "2d" && use2DShells && facilitiesSource && facilitiesSource.layer2D) {
        this._toggleLayerVisibilities2D(view,facilitiesSource.layer2D,false);
        facilityFootprints.deactivating(facilitiesSource);
        // if (lastExtent2D) {
        //   view.goTo(lastExtent2D, {
        //     duration: 1000,
        //     easing: "out-back"
        //   });
        // }
      }

      if (view && view.type === "2d" && use2DShells && facilitiesSource && facilitiesSource.layer2D) {
        const layer = facilitiesSource.layer2D;
        if (layer.xtnSubLayer) {
          if (layer.xtnSubLayer.definitionExpression) layer.xtnSubLayer.definitionExpression = null;
        } else {
          if (layer.definitionExpression) layer.definitionExpression = null;
        }
        if (lastExtent2D) {
          view.goTo(lastExtent2D, {
            duration: 1000,
            easing: "out-back"
          });
        }
      }

      if (view && view.type === "3d" && facilitiesSource && facilitiesSource.layer3D) {
        const layer = facilitiesSource.layer3D;
        if (layer.renderer) layer.renderer = null;
        if (layer.definitionExpression) layer.definitionExpression = null;
        if (lastCamera) {
          view.goTo(lastCamera, {
            duration: 1000,
            easing: "out-back"
          });
        }
      }
    }
  }

  _watch2D() {
    const context = Context.instance;
    //console.log("isTouch",context.uiMode.isTouch,"*********************************")
    if (context.uiMode.isTouch) return;
    const view = context.views.mapView;
    if (!view || !context.config.highlightFacilityOnHover2D) return;
    const facilities = context.aiim.datasets.facilities;
    const facilityFootprints = context.aiim.facilityFootprints;
    const facilitiesLayer = facilities && facilities.layer2D;
    const facilitiesSource = this._getFacilitiesSource();
    if (!facilities || !facilitiesLayer) return;
    if (!facilitiesSource || !facilityFootprints) return;
    const oidField = facilities.objectIdField;
    const isMapService = facilities.isMapServiceBased;
    //if (!this._use2DShells) return;
    //if (!this._use2DShellTransparency) return;

    const lib = Context.instance.lib;
    let aspectHandle, leaveHandle, moveHandle, clickHandle;
    aspectHandle = lib.dojo.aspect.after(view,"destroy",() => {
      if (leaveHandle) leaveHandle.remove();
      if (moveHandle) moveHandle.remove();
      if (clickHandle) clickHandle.remove();
      if (aspectHandle) aspectHandle.remove();
    });

    moveHandle = view.on("pointer-move",evt => {
      if (evt && evt.native &&
         (evt.native.shiftKey || evt.native.ctrlKey || (evt.buttons === 1))) {
        // evt.buttons === 1 - primary mouse button is pressed
        return;
      }
      let stamp = Date.now();
      if ((stamp - this._stamp) < 100) {
        return;
      }
      this._stamp = stamp;
      //console.log("evt",evt)
      let footprintGraphic;
      let footprints = facilityFootprints.hitTest(view,evt,true);
      if (footprints && footprints.length > 0) {
        footprintGraphic = footprints[0];
      }
      if (footprintGraphic) {
        this._setViewCursor(true);
        facilityFootprints.addHoverHighlight2D(footprintGraphic);
      } else {
        this._setViewCursor(false);
        facilityFootprints.removeHoverHighlight2D();
      }
    });

    // moveHandle = view.on("pointer-move",evt => {
    //   let stamp = Date.now();
    //   view.hitTest(evt).then(r => {
    //     if (stamp < this._stamp) return;
    //     this._stamp = stamp;
    //     let graphic, footprintGraphic, aboveFacilities;
    //
    //     if (r && r.results && r.results.length > 0) {
    //       graphic = r.results[0].graphic;
    //       if (graphic && graphic.layer && graphic.layer.xtnAboveFacilities) {
    //         aboveFacilities = true;
    //       }
    //     }
    //
    //     if (!aboveFacilities) {
    //       let ok = true;
    //       //ok = facilities.isVisibleAtScale2D(view);
    //       if (ok && isMapService) {
    //         let footprints = facilityFootprints.hitTest(view,evt);
    //         if (footprints && footprints.length > 0) {
    //           footprintGraphic = footprints[0];
    //         }
    //       } else if (ok) {
    //         if (graphic && graphic.layer && graphic.layer === facilitiesLayer) {
    //           if (facilities.isVisibleAtScale2D(view)) {
    //             const oid = aiimUtil.getAttributeValue(graphic.attributes,oidField);
    //             footprintGraphic = facilityFootprints.graphicsByObjectId[oid];
    //           }
    //         }
    //       }
    //     }
    //
    //     if (footprintGraphic) {
    //       this._setViewCursor(true);
    //       facilityFootprints.addHoverHighlight2D(footprintGraphic);
    //     } else {
    //       this._setViewCursor(false);
    //       facilityFootprints.removeHoverHighlight2D();
    //     }
    //   });
    // });

    leaveHandle = view.on("pointer-leave",evt => {
      this._setViewCursor(false);
      facilityFootprints.removeHoverHighlight2D();
    });

    clickHandle = view.on("click",evt => {
      this._setViewCursor(false);
    });
  }

  _watch3D() {
    const context = Context.instance;
    if (context.uiMode.isTouch) return;
    const view = context.views.sceneView;
    const facilitiesSource = this._getFacilitiesSource();
    if (!view || !facilitiesSource) return;

    const lib = Context.instance.lib;
    let aspectHandle, leaveHandle, moveHandle, clickHandle;
    aspectHandle = lib.dojo.aspect.after(view,"destroy",() => {
      if (leaveHandle) leaveHandle.remove();
      if (moveHandle) moveHandle.remove();
      if (clickHandle) clickHandle.remove();
      if (aspectHandle) aspectHandle.remove();
    });

    const makeHighlightRenderer = (objectid,objectIdField) => {
      objectid = parseInt(objectid, 10) || 0;
      return {
        type: "unique-value",
        field: objectIdField,
        uniqueValueInfos: [{
          value: objectid,
          label: "Facility Highlight",
          symbol: {
            type: "mesh-3d",
            symbolLayers: [{
              type: "fill",
              material: {
                color: [230, 230, 230, 0.6],
                colorMixMode: "replace"
              }
            }]
          }
        }]
      };
    }

    moveHandle = view.on("pointer-move",evt => {
      view.hitTest(evt).then(r => {
        let facilityGraphic, facilitiesLayer;
        if (facilitiesSource && facilitiesSource.layer3D) {
          facilitiesLayer = facilitiesSource.layer3D;
        }
        if (r && r.results && r.results.length > 0) {
          const graphic = (r.results[0] as __esri.SceneViewGraphicHit).graphic;
          if (graphic && graphic.layer && graphic.layer === facilitiesLayer) {
            facilityGraphic = graphic;
          }
        }
        if (facilityGraphic) {
          //console.log("facilityGraphic",facilityGraphic);
          const objectIdField = facilityGraphic.layer.objectIdField;
          const objectid = facilityGraphic.attributes[objectIdField];
          const renderer = makeHighlightRenderer(objectid,objectIdField);
          facilityGraphic.layer.renderer = renderer;
          this._setViewCursor(true);
        } else {
          if (facilitiesLayer && facilitiesLayer.renderer) {
            facilitiesLayer.renderer = null;
          }
          this._setViewCursor(false);
        }
      });
    });

    leaveHandle = view.on("pointer-leave",evt => {
      if (facilitiesSource && facilitiesSource.layer3D) {
        const facilitiesLayer = facilitiesSource.layer3D;
        if (facilitiesLayer && facilitiesLayer.renderer) {
          facilitiesLayer.renderer = null;
        }
      }
      this._setViewCursor(false);
    });

    clickHandle = view.on("click",evt => {
      this._setViewCursor(false);
    });
  }

}
