import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  overflow: hidden;
`;

export { Container };
