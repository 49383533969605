/** Provides Redux support for the Floor Plan Editor miniapp. */

import Rdx, { IRootState, createAction } from "../../../redux/Rdx";
import { IFeature } from "@esri/arcgis-rest-types";
import { AnyAction } from "redux";
import { getDefaultLinearUnits, getDefaultWallWidthUnits } from "../../components/Editor/support/unitUtil";
import { LayerType } from "../../../util/interfaces";

// state keys
export const EDITOR_ACTIVE_SIDEBAR_TAB = "EDITOR_ACTIVE_SIDEBAR_TAB";
export const EDITOR_HIT_TEST_RESULTS = "EDITOR_HIT_TEST_RESULTS";
export const EDITOR_ATTRIBUTE_EDITOR_ENABLED = "EDITOR_ATTRIBUTE_EDITOR_ENABLED";
export const EDITOR_SELECTED_HIT_TEST_RESULT = "EDITOR_SELECTED_HIT_TEST_RESULT";
export const EDITOR_SELECTION_VISIBILITY = "EDITOR_SELECTION_VISIBILITY";
export const EDITOR_MERGE_UNITS_HELP = "EDITOR_MERGE_UNITS_HELP";
export const EDITOR_MERGE_DETAILS_HELP = "EDITOR_MERGE_DETAILS_HELP";
export const EDITOR_SPLIT_HELP = "EDITOR_SPLIT_HELP";
export const EDITOR_UNIT_HELP = "EDITOR_UNIT_HELP";
export const EDITOR_WALL_HELP = "EDITOR_WALL_HELP";
export const EDITOR_ENTRYWAY_HELP = "EDITOR_ENTRYWAY_HELP";
export const EDITOR_WINDOW_HELP = "EDITOR_WINDOW_HELP";
export const EDITOR_FURNITURE_HELP = "EDITOR_FURNITURE_HELP";
export const EDITOR_TRANSITION_HELP = "EDITOR_TRANSITION_HELP";
export const EDITOR_MERGEPLAN_HELP = "EDITOR_MERGEPLAN_HELP";
export const EDITOR_MEDIA_HELP = "EDITOR_MEDIA_HELP";
export const EDITOR_FACILITIES_HELP = "EDITOR_FACILITIES_HELP";
export const EDITOR_SITES_HELP = "EDITOR_SITES_HELP";
export const EDITOR_LEVELS_HELP = "EDITOR_LEVELS_HELP";
export const EDITOR_COPYPASTE_HELP = "EDITOR_COPYPASTE_HELP";
export const EDITOR_DELETE_HELP = "EDITOR_DELETE_HELP";
export const EDITOR_GRID_HELP = "EDITOR_GRID_HELP";
export const EDITOR_WALL_WIDTH_UNITS = "EDITOR_WALL_WIDTH_UNITS";
export const EDITOR_UNDOREDO_DISABLED = "EDITOR_UNDOREDO_DISABLED";
export const EDITOR_DIMENSION_LABEL_INFO = "EDITOR_DIMENSION_LABEL_INFO";
export const EDITOR_DELETE_CONFIRMATION_VISIBILITY = "EDITOR_DELETE_CONFIRMATION_VISIBILITY";

export type HitTest = {
  feature: IFeature,
  key: LayerType,
  oid?: number,
  duplicateObj?: {
    isDuplicate: boolean,
    originalFt: Omit<HitTest, "duplicateObj">[],
    originalOid: number,
    originalType: LayerType,
    isSaved: boolean
  }
}
export type HelpTip = {
  visible: boolean,
  tooltip: string
}
export type EditorTabs =
  | "app-switcher"
  | "editor"
  | "sites"
  | "facilities"
  | "levels"
  | "units"
  | "walls"
  | "splitUnit"
  | "mergeDetails"
  | "mergeUnits"
  | "entryways"
  | "windows"
  | "furniture"
  | "transitions"
  | "validate"
  | "media"
  | "mergePlan"
  | "layers"
  | "legend"
  | "copypaste"
  | "delete"
  | "grid"
  | null;

export type Units = "feet" | "inches" | "millimeters";

export type DimensionLabelInfo = {
  visible: boolean,
  units: "feet" | "meters"
}

// Actions
export const setActiveTab = (tab: EditorTabs): AnyAction =>
  createAction(EDITOR_ACTIVE_SIDEBAR_TAB, tab);
export const setHitTestResults = (results: HitTest[]): AnyAction =>
  createAction(EDITOR_HIT_TEST_RESULTS, results);
export const setAttributeEditorEnabled = (enabled: boolean): AnyAction =>
  createAction(EDITOR_ATTRIBUTE_EDITOR_ENABLED, enabled);
export const setSelectedFeatureIndex = (index: number): AnyAction =>
  createAction(EDITOR_SELECTED_HIT_TEST_RESULT, index);
export const setSelectionVisibility = (visible: boolean): AnyAction =>
  createAction(EDITOR_SELECTION_VISIBILITY, visible);
export const setWallWidthUnits = (units: IEditorState["EDITOR_WALL_WIDTH_UNITS"]): AnyAction =>
  createAction(EDITOR_WALL_WIDTH_UNITS, units);
export const setUndoRedoDisabled = (disabled: boolean): AnyAction =>
  createAction(EDITOR_UNDOREDO_DISABLED, disabled);
export const setHelpTip = (
  tab: EditorTabs,
  tip: HelpTip): AnyAction => {
  switch (tab) {
    case "facilities":
      return createAction(EDITOR_FACILITIES_HELP, tip);
    case "sites":
      return createAction(EDITOR_SITES_HELP, tip);
    case "levels":
      return createAction(EDITOR_LEVELS_HELP, tip);
    case "units":
      return createAction(EDITOR_UNIT_HELP, tip);
    case "walls":
      return createAction(EDITOR_WALL_HELP, tip);
    case "splitUnit":
      return createAction(EDITOR_SPLIT_HELP, tip);
    case "mergeDetails":
      return createAction(EDITOR_MERGE_DETAILS_HELP, tip);
    case "mergeUnits":
      return createAction(EDITOR_MERGE_UNITS_HELP, tip);
    case "entryways":
      return createAction(EDITOR_ENTRYWAY_HELP, tip);
    case "windows":
      return createAction(EDITOR_WINDOW_HELP, tip);
    case "furniture":
      return createAction(EDITOR_FURNITURE_HELP, tip);
    case "transitions":
      return createAction(EDITOR_TRANSITION_HELP, tip);
    case "mergePlan":
      return createAction(EDITOR_MERGEPLAN_HELP, tip);
    case "media":
      return createAction(EDITOR_MEDIA_HELP, tip);
    case "copypaste":
      return createAction(EDITOR_COPYPASTE_HELP, tip);
    case "delete":
      return createAction(EDITOR_DELETE_HELP, tip);
    case "grid":
      return createAction(EDITOR_GRID_HELP, tip);
    default:
      return { type: null };
  }
}
export const setDimensionLabelInfo = (info: DimensionLabelInfo): AnyAction =>
  createAction(EDITOR_DIMENSION_LABEL_INFO, info);
export const setDeleteConfirmationVisibility = (visible: boolean): AnyAction =>
  createAction(EDITOR_DELETE_CONFIRMATION_VISIBILITY, visible);
 
// Selectors
export const getHitTestResults = (state: IRootState): IEditorState["EDITOR_HIT_TEST_RESULTS"] =>
  Rdx.getValue(state, EDITOR_HIT_TEST_RESULTS);
export const getActiveTab = (state: IRootState): EditorTabs =>
  Rdx.getValue(state, EDITOR_ACTIVE_SIDEBAR_TAB);
export const getAttributeEditorEnabled = (state: IRootState): IEditorState["EDITOR_ATTRIBUTE_EDITOR_ENABLED"] =>
  Rdx.getValue(state, EDITOR_ATTRIBUTE_EDITOR_ENABLED);
export const getSelectedFeatureIndex = (state: IRootState): IEditorState["EDITOR_SELECTED_HIT_TEST_RESULT"] =>
  Rdx.getValue(state, EDITOR_SELECTED_HIT_TEST_RESULT);
export const getSelectionVisibility = (state: IRootState): IEditorState["EDITOR_SELECTION_VISIBILITY"] =>
  Rdx.getValue(state, EDITOR_SELECTION_VISIBILITY);
export const getUndoRedoDisabled = (state: IRootState): IEditorState["EDITOR_UNDOREDO_DISABLED"] =>
  Rdx.getValue(state, EDITOR_UNDOREDO_DISABLED);
/** Returns the help tip associated with the currently active tab. */
export const getActiveHelpTip = (state: IRootState): HelpTip => {
  const tab = getActiveTab(state);
  switch (tab) {
    case "facilities":
      return Rdx.getValue(state, EDITOR_FACILITIES_HELP);
    case "sites":
      return Rdx.getValue(state, EDITOR_SITES_HELP);
    case "levels":
      return Rdx.getValue(state, EDITOR_LEVELS_HELP);
    case "units":
      return Rdx.getValue(state, EDITOR_UNIT_HELP);
    case "walls":
      return Rdx.getValue(state, EDITOR_WALL_HELP);
    case "splitUnit":
      return Rdx.getValue(state, EDITOR_SPLIT_HELP);
    case "mergeDetails":
      return Rdx.getValue(state, EDITOR_MERGE_DETAILS_HELP);
    case "mergeUnits":
      return Rdx.getValue(state, EDITOR_MERGE_UNITS_HELP);
    case "entryways":
      return Rdx.getValue(state, EDITOR_ENTRYWAY_HELP);
    case "windows":
      return Rdx.getValue(state, EDITOR_WINDOW_HELP);
    case "furniture":
      return Rdx.getValue(state, EDITOR_FURNITURE_HELP);
    case "transitions":
      return Rdx.getValue(state, EDITOR_TRANSITION_HELP);
    case "mergePlan":
      return Rdx.getValue(state, EDITOR_MERGEPLAN_HELP);
    case "media":
      return Rdx.getValue(state, EDITOR_MEDIA_HELP);
    case "copypaste":
      return Rdx.getValue(state, EDITOR_COPYPASTE_HELP);
    case "delete":
      return Rdx.getValue(state, EDITOR_DELETE_HELP);
    case "grid":
      return Rdx.getValue(state, EDITOR_GRID_HELP);
    default:
      return null;
  }
}
export const getWallWidthUnits = (state: IRootState): IEditorState["EDITOR_WALL_WIDTH_UNITS"] =>
  Rdx.getValue(state, EDITOR_WALL_WIDTH_UNITS);
export const getActiveFeatureType = (state: IRootState): LayerType => {
  const idx = getSelectedFeatureIndex(state);
  const results = getHitTestResults(state);
  const active = results && results.length ? results[idx] : null;
  return active ? active.key : null;
}
export const getDeleteConfirmationVisibility = (state: IRootState): IEditorState["EDITOR_DELETE_CONFIRMATION_VISIBILITY"] =>
  Rdx.getValue(state, EDITOR_DELETE_CONFIRMATION_VISIBILITY);
export const getDimensionLabelInfo = (state: IRootState): IEditorState["EDITOR_DIMENSION_LABEL_INFO"] =>
  Rdx.getValue(state, EDITOR_DIMENSION_LABEL_INFO);

// State
export interface IEditorState {
  [EDITOR_ACTIVE_SIDEBAR_TAB]?: EditorTabs,
  [EDITOR_HIT_TEST_RESULTS]?: HitTest[],
  [EDITOR_ATTRIBUTE_EDITOR_ENABLED]?: boolean,
  [EDITOR_SELECTED_HIT_TEST_RESULT]?: number,
  [EDITOR_SELECTION_VISIBILITY]?: boolean,
  [EDITOR_MERGE_UNITS_HELP]?: HelpTip,
  [EDITOR_MERGE_DETAILS_HELP]?: HelpTip,
  [EDITOR_SPLIT_HELP]?: HelpTip,
  [EDITOR_UNIT_HELP]?: HelpTip,
  [EDITOR_WALL_HELP]?: HelpTip,
  [EDITOR_ENTRYWAY_HELP]?: HelpTip,
  [EDITOR_WINDOW_HELP]?: HelpTip,
  [EDITOR_FURNITURE_HELP]?: HelpTip,
  [EDITOR_TRANSITION_HELP]?: HelpTip,
  [EDITOR_MERGEPLAN_HELP]?: HelpTip,
  [EDITOR_MEDIA_HELP]?: HelpTip,
  [EDITOR_LEVELS_HELP]?: HelpTip,
  [EDITOR_FACILITIES_HELP]?: HelpTip,
  [EDITOR_SITES_HELP]?: HelpTip,
  [EDITOR_COPYPASTE_HELP]?: HelpTip,
  [EDITOR_DELETE_HELP]?: HelpTip,
  [EDITOR_GRID_HELP]?: HelpTip,
  [EDITOR_WALL_WIDTH_UNITS]?: Units,
  [EDITOR_UNDOREDO_DISABLED]?: boolean,
  [EDITOR_DIMENSION_LABEL_INFO]?: DimensionLabelInfo
  [EDITOR_DELETE_CONFIRMATION_VISIBILITY]?: boolean
}
export const initialEditorState = (): IEditorState => ({  
  [EDITOR_ACTIVE_SIDEBAR_TAB]: null,
  [EDITOR_HIT_TEST_RESULTS]: [],
  [EDITOR_ATTRIBUTE_EDITOR_ENABLED]: false,
  [EDITOR_SELECTED_HIT_TEST_RESULT]: null,
  [EDITOR_SELECTION_VISIBILITY]: true,
  [EDITOR_MERGE_UNITS_HELP]: { visible: false, tooltip: "" },
  [EDITOR_MERGE_DETAILS_HELP]: { visible: false, tooltip: "" },
  [EDITOR_SPLIT_HELP]: { visible: false, tooltip: "" },
  [EDITOR_UNIT_HELP]: { visible: false, tooltip: "" },
  [EDITOR_WALL_HELP]: { visible: false, tooltip: "" },
  [EDITOR_ENTRYWAY_HELP]: { visible: false, tooltip: "" },
  [EDITOR_WINDOW_HELP]: { visible: false, tooltip: "" },
  [EDITOR_FURNITURE_HELP]: { visible: false, tooltip: "" },
  [EDITOR_TRANSITION_HELP]: { visible: false, tooltip: "" },
  [EDITOR_MERGEPLAN_HELP]: { visible: false, tooltip: "" },
  [EDITOR_MEDIA_HELP]: { visible: false, tooltip: "" },
  [EDITOR_LEVELS_HELP]: { visible: false, tooltip: "" },
  [EDITOR_FACILITIES_HELP]: { visible: false, tooltip: "" },
  [EDITOR_SITES_HELP]: { visible: false, tooltip: "" },
  [EDITOR_COPYPASTE_HELP]: { visible: false, tooltip: "" },
  [EDITOR_GRID_HELP]: { visible: false, tooltip: "" },
  [EDITOR_WALL_WIDTH_UNITS]:getDefaultWallWidthUnits(),
  [EDITOR_UNDOREDO_DISABLED]: false,
  [EDITOR_DIMENSION_LABEL_INFO]: {
    visible: true,
    units: getDefaultLinearUnits()
  },
  [EDITOR_DELETE_CONFIRMATION_VISIBILITY]: true
});
