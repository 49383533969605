import React from "react";

import Context from "../../../context/Context";
import SubTypeIcon from "../../common/SubTypeIcon/SubTypeIcon";
import * as component from "../../util/component";
import * as aiimUtil from "../../../aiim/util/aiimUtil";

const CSS = {
  main: "i-category",
  subtypes: "i-category-subtypes"
};

export default class Category extends React.Component {

  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      subTypeValues: null
    });
    this.subTypeClicked = this.subTypeClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.querySubTypes();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  querySubTypes() {
    const handleSubTypes = (subTypes) => {
      if (!this._mounted) return;
      if (subTypes && subTypes.length > 0) {
        let subTypeValues = [];
        subTypes.forEach(subType => {
          subTypeValues.push(subType.value);
        });
        this.setState(state => {
          return {
            subTypeValues: subTypeValues
          };
        });
      } else {
        this.subTypeClicked("__noneAvailable__",null);
      }
    };
    const source = aiimUtil.getSource(this.props.sourceKey);
    if (source && source.subTypesPromise) {
      source.subTypesPromise.then(() => {
        handleSubTypes(source.subTypes);
      }).catch(ex => {
        // message is handled in src/aiim/datasets/Categories
        handleSubTypes();
      });
    } else if (source) {
      handleSubTypes(source.subTypes);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const subTypeValues = this.state.subTypeValues;
    const source = aiimUtil.getSource(this.props.sourceKey);
    const nodes = [];
    if (source && subTypeValues && subTypeValues.length > 0) {
      subTypeValues.forEach((v,index) => {
        const subType = source.findSubTypeByValue(v);
        if (subType) {
          nodes.push(this.renderSubType(source,subType,index));
        }
      });
    }
    return (
      <ul className={CSS.subtypes} aria-label={i18n.explore.category.typesAriaLabel}>
        {nodes}
      </ul>
    );
  }

  renderSubType(source,subType,index) {
    const key = "subType_" + subType.value;
    return (
      <SubType key={key} sourceKey={source.key} subTypeValue={subType.value}
        subTypeName={subType.name} subTypeClickHandler={this.subTypeClicked} />
    );
  }

  subTypeClicked(subTypeValue,subTypeName) {
    //console.log("Category::subTypeClicked",subTypeValue,subTypeName);
    if (this.props.subTypeClickHandler) {
      const sourceKey = this.props.sourceKey
      this.props.subTypeClickHandler(sourceKey,subTypeValue,subTypeName);
    }
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class SubType extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    const subTypeValue = this.props.subTypeValue;
    const subTypeName = this.props.subTypeName;
    if (this.props.subTypeClickHandler) {
      this.props.subTypeClickHandler(subTypeValue,subTypeName);
    }
  }

  render() {
    const hashref = "#";
    const item = (
      <li>
        <a href={hashref} role="button" onClick={this.clicked} aria-label={this.props.subTypeName}>
          <SubTypeIcon forFeature={false} sourceKey={this.props.sourceKey}
            subTypeValue={this.props.subTypeValue} />
          <p>{this.props.subTypeName}</p>
        </a>
      </li>
    );
    return item;
  }
}
