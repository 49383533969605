import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import { debounce } from "../../../miniapps/support/debounceUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as unitUtil from "./unitUtil";
import { ILengthAndWidthInfo } from "../../../miniapps/common/types";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import "@esri/calcite-components/dist/components/calcite-switch";
import {
  CalciteBlock,
  CalciteIcon,
  CalciteInputNumber,
  CalciteInputText,
  CalciteLabel,
  CalciteSegmentedControl,
  CalciteSegmentedControlItem,
  CalciteSwitch
} from "@esri/calcite-components-react";

interface IProps {
  disabled?: boolean;
  lengthAndWidthInfo: ILengthAndWidthInfo,
  onChange?: (lengthAndWidthInfo: ILengthAndWidthInfo) => void,
  showLabel: boolean,
  showLength: boolean,
  showWidth: boolean,
  showThickness: boolean,
  label?: string,
  lengthLabel?: string,
  widthLabel?: string,
  thicknessLabel?: string,
  minLength?: number,
  minWidth?: number,
  minThickness?: number,
}

interface IState {
  length: number,
  width: number,
  thickness: number
}

class LengthAndWidth extends React.Component<IProps,IState> {

  componentId: string;
  showAnchors = false;

  constructor(props: IProps) {
    super(props);
    this.componentId = component.nextId("lengthAndWidthInfo");
    const { lengthAndWidthInfo } = this.props;
    this.state = component.newState({
      length: lengthAndWidthInfo.length,
      width: lengthAndWidthInfo.width,
      thickness: lengthAndWidthInfo.thickness,
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    const { lengthAndWidthInfo } = this.props;
    if (prevProps.lengthAndWidthInfo !== lengthAndWidthInfo) {
      this.setState({
        length: lengthAndWidthInfo.length,
        width: lengthAndWidthInfo.width,
        thickness: lengthAndWidthInfo.thickness,
      });
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { disabled, label, showLabel } = this.props; 
    return (
      <CalciteBlock open heading={""} disabled={disabled || undefined}>
        {showLabel &&
          <h4>{label || i18n.editor.dimensions.label}</h4>
        }
        {this.renderContent()}
      </CalciteBlock>
    )
  }

  renderContent() {
    const i18n = Context.instance.i18n;
    const { length, width, thickness } = this.state;
    const { disabled, lengthAndWidthInfo, showLength, showWidth, showThickness } = this.props; 
    const inputStyle = {width: "5rem", marginInlineEnd: "0.1rem"};
    const minLength = (this.props.minLength === 0) ? 0 : (this.props.minLength || 0.1);
    const minWidth =  (this.props.minWidth === 0) ? 0 : (this.props.minWidth || 0.1);
    const minThickness = (this.props.minThickness === 0) ? 0 : (this.props.minThickness || 0);

    const lengthOK = (typeof length === "number") && (length >= minLength);
    const widthOK = (typeof width === "number") && (length >= minWidth);
    const thicknessOK = (typeof thickness === "number") && (length >= minThickness)

    const onInput = debounce((e,type) => {
      let v = e.target.value;
      //@ts-ignore
      v = (v.trim().length > 0) ? Number(v.trim()) : null;
      if (typeof v === "number") {
        if (type === "length") {
          if (v >= minLength) {
            const vInMapUnits = unitUtil.getLengthInMapUnits(v,lengthAndWidthInfo.units);
            lengthAndWidthInfo.length = v;
            lengthAndWidthInfo.lengthInMapUnits = vInMapUnits;
            this.setState({length: v});
            this.props.onChange(lengthAndWidthInfo);
          } else {
            this.setState({length: v});
          }
        } else if (type === "width") {
          if (v >= minWidth) {
            const vInMapUnits = unitUtil.getLengthInMapUnits(v,lengthAndWidthInfo.units);
            lengthAndWidthInfo.width = v;
            lengthAndWidthInfo.widthInMapUnits = vInMapUnits;
            this.setState({width: v});
            this.props.onChange(lengthAndWidthInfo);
          } else {
            this.setState({width: v});
          }
        } else if (type === "thickness") {
          if (v >= minThickness) {
            const vInMapUnits = unitUtil.getLengthInMapUnits(v,lengthAndWidthInfo.thicknessUnits);
            lengthAndWidthInfo.thickness = v;
            lengthAndWidthInfo.thicknessInMapUnits = vInMapUnits;
            this.setState({thickness: v}); 
            this.props.onChange(lengthAndWidthInfo);
          } else {
            this.setState({thickness: v});
          }    
        }
      }
    }, 100)

    return (
      <div>
        <div style={{display: "flex"}}>
          {showLength &&
            <CalciteLabel scale="s" for={this.componentId+"-length"}>
              {this.props.lengthLabel || i18n.editor.dimensions.length}
              <div className="i-flex i-align-items-end">
                <CalciteInputNumber 
                  id={this.componentId+"-length"}
                  style={inputStyle}
                  value={""+length}
                  min={minLength}
                  scale="s"
                  disabled={disabled || undefined}
                  status={!lengthOK ? "invalid" : undefined}
                  onCalciteInputNumberInput={e => onInput(e,"length")}
                />
                {!showWidth && this.renderUnits("length")}
              </div>
            </CalciteLabel>
          }
          {showWidth &&
            <CalciteLabel scale="s" for={this.componentId+"-width"}>
              {this.props.widthLabel || i18n.editor.dimensions.width}
              <div className="i-flex i-align-items-end">
                <CalciteInputNumber 
                  id={this.componentId+"-width"}
                  style={inputStyle}
                  value={""+width}
                  min={minWidth}
                  scale="s"
                  disabled={disabled || undefined}
                  status={!widthOK ? "invalid" : undefined}
                  onCalciteInputNumberInput={e => onInput(e,"width")}
                />
                {this.renderUnits("length")}
              </div>
            </CalciteLabel>
          }
          {showThickness &&
            <CalciteLabel scale="s" for={this.componentId+"-thickness"}>
              {this.props.thicknessLabel || i18n.editor.dimensions.thickness}
              <div className="i-flex i-align-items-end">
                <CalciteInputNumber 
                  id={this.componentId+"-thickness"}
                  style={inputStyle}
                  scale="s"
                  value={""+thickness}
                  min={minThickness}
                  disabled={disabled || undefined}
                  status={!thicknessOK ? "invalid" : undefined}
                  onCalciteInputNumberInput={e => onInput(e,"thickness")}
                />
                {this.renderUnits("thickness")}
              </div>
            </CalciteLabel>
          }
        </div>
        {showLength && this.renderAnchors()}
      </div>
    )
  }

  renderAnchors() {
    if (!this.showAnchors) return null;
    const { lengthAndWidthInfo } = this.props;
    const positions = ["ul" , "uc" , "ur" , "cl" , "cc" , "cr" , "ll" , "lc" , "lr"]
    const inputStyle = {width: "4rem", marginInlineStart: "0.25rem", marginInlineEnd: "1rem"};
    return (
      <div style={{display: "flex", marginTop: "1rem"}}>
        <CalciteLabel scale="s" layout="inline" for={this.componentId+"-a1p"}>
          a1
        </CalciteLabel>
        <CalciteInputText
          id={this.componentId+"-a1p"}
          style={inputStyle}
          scale="s"
          value={lengthAndWidthInfo.anchor1Position}
          onCalciteInputTextInput={e => {
            let v = e.target.value;
            if (positions.includes(v)) {
              // @ts-ignore
              lengthAndWidthInfo.anchor1Position = v;
              this.props.onChange(lengthAndWidthInfo);                    
            }
          }}
        />
        <CalciteLabel scale="s" layout="inline" for={this.componentId+"-a2p"}>
          a2
        </CalciteLabel>
        <CalciteInputText
          id={this.componentId+"-a2p"}
          style={inputStyle}
          scale="s"
          value={lengthAndWidthInfo.anchor2Position}
          onCalciteInputTextInput={e => {
            let v = e.target.value;
            if (typeof v === "string" && v.length === 0) v = null
            if (positions.includes(v) || v === null) {
              // @ts-ignore
              lengthAndWidthInfo.anchor2Position = v;
              this.props.onChange(lengthAndWidthInfo);                    
            }
          }}
        />
      </div>
    )
  }

  // renderSwitch() {
  //   const i18n = Context.instance.i18n;
  //   const { enabled } = this.state;
  //   const { lengthAndWidthInfo } = this.props; 
  //   const id = this.componentId+"-switch"
  //   // var(--calcite-label-margin-bottom, 0.75rem)
  //   return (
  //     <div className="i-flex-between">
  //       <CalciteLabel 
  //         for={id}
  //         className="i-cursor-pointer" 
  //         scale="m"
  //         layout="inline"
  //       >
  //         <CalciteIcon scale="s" icon="dimensions" />
  //         {this.props.label || i18n.editor.dimensions.specifyDimensions}
  //       </CalciteLabel>
  //       <CalciteSwitch 
  //         id={id}
  //         scale="m"
  //         checked={enabled || undefined}
  //         style={{marginBottom: "var(--calcite-label-margin-bottom)"}}
  //         onCalciteSwitchChange={e => {
  //           const v = !!e.target.checked;
  //           lengthAndWidthInfo.enabled = v;       
  //           this.setState({enabled: v});
  //           this.props.onChange(lengthAndWidthInfo); 
  //         }}
  //       />
  //     </div>
  //   )
  // }

  renderUnits(type: "length" | "thickness") {
    const { lengthAndWidthInfo, showLength, showWidth, showThickness } = this.props; 

    const items: any = {
      feet: {
        value: "feet",
        label: "ft",
        checked: (lengthAndWidthInfo.units === "feet")
      },
      meters: {
        value: "meters",
        label: "m",
        checked: (lengthAndWidthInfo.units === "meters")        
      },
      inches: {
        value: "inches",
        label: "in",
        checked: (lengthAndWidthInfo.thicknessUnits === "inches")
      },
      millimeters: {
        value: "millimeters",
        label: "mm",
        checked: (lengthAndWidthInfo.thicknessUnits === "millimeters")        
      },
    }
    const itemA = type === "length" ? items.feet : items.inches;
    const itemB = type === "length" ? items.meters : items.millimeters;

    const isChecked = (item) => {
      if (item.checked) return {checked: true}
    }

    return (
      <span style={{marginInlineEnd: "0.25rem"}}>
        <CalciteSegmentedControl scale="s"
          onCalciteSegmentedControlChange={(e) => {
            const v = e.target.value;
            const newState: any = {};
            if (type === "length") {
              if (v === "feet" && showLength && lengthAndWidthInfo.length === unitUtil.defaultLengthInMeters) {
                lengthAndWidthInfo.length = newState.length = unitUtil.defaultLengthInFeet;
              } else if (v === "meters" && showLength && lengthAndWidthInfo.length === unitUtil.defaultLengthInFeet) {
                lengthAndWidthInfo.length = newState.length = unitUtil.defaultLengthInMeters;
              }
              if (v === "feet" && showWidth && lengthAndWidthInfo.width === unitUtil.defaultLengthInMeters) {
                lengthAndWidthInfo.width = newState.width = unitUtil.defaultLengthInFeet;
              } else if (v === "meters" && showWidth && lengthAndWidthInfo.width === unitUtil.defaultLengthInFeet) {
                lengthAndWidthInfo.width = newState.width = unitUtil.defaultLengthInMeters;
              }
              // @ts-ignore
              lengthAndWidthInfo.units = v;
              lengthAndWidthInfo.lengthInMapUnits = unitUtil.getLengthInMapUnits(lengthAndWidthInfo.length,lengthAndWidthInfo.units);
              lengthAndWidthInfo.widthInMapUnits = unitUtil.getLengthInMapUnits(lengthAndWidthInfo.width,lengthAndWidthInfo.units);
            } else {
              if (v === "inches" && showThickness && lengthAndWidthInfo.thickness === unitUtil.defaultThicknessInMillimeters) {
                lengthAndWidthInfo.thickness = newState.thickness = unitUtil.defaultThicknessInInches;
              } else if (v === "millimeters" && showThickness && lengthAndWidthInfo.thickness === unitUtil.defaultThicknessInInches) {
                lengthAndWidthInfo.thickness = newState.thickness = unitUtil.defaultThicknessInMillimeters;
              }
              // @ts-ignore
              lengthAndWidthInfo.thicknessUnits = v;
              lengthAndWidthInfo.thicknessInMapUnits = unitUtil.getLengthInMapUnits(lengthAndWidthInfo.thickness,lengthAndWidthInfo.thicknessUnits);            
            }
            this.setState(newState);
            this.props.onChange(lengthAndWidthInfo);
          }}
        >
          <CalciteSegmentedControlItem key={itemA.value} value={itemA.value} {...isChecked(itemA)}>
            {itemA.label}
          </CalciteSegmentedControlItem>
          <CalciteSegmentedControlItem key={itemB.value} value={itemB.value} {...isChecked(itemB)}>
            {itemB.label}
          </CalciteSegmentedControlItem>
        </CalciteSegmentedControl>
      </span>
    )
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
  }
}

export default connect(mapStateToProps)(LengthAndWidth);