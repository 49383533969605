import React from "react";
import moment from "moment";

import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import SubTypeIcon from "../../common/SubTypeIcon/SubTypeIcon";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as itemUtil from "../../../aiim/util/itemUtil";

import directionsIcon from "../../../assets/route.svg";

const CSS = {
  itemText: "i-feature-item-text",
  itemTitle: "i-feature-item-title",
  itemSubTitle: "i-feature-item-subtitle",
  itemAction: "i-feature-item-action"
};

export default class WorkOrderItem extends React.Component {
  _componentId
  _itemRef

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.detailsClicked = this.detailsClicked.bind(this);
    this.directionsClicked = this.directionsClicked.bind(this);
    this._componentId = component.nextId("workorder-item-")
    this._itemRef = React.createRef()
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus()
        }
      })
    ])
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  detailsClicked(event) {
    const source = aiimUtil.getSource(this.props.sourceKey);
    const feature = this.props.featureItem.feature;
    itemUtil.makeItemReference(source,feature,true).then(ref => {
      if (ref) {
        Topic.publish(Topic.ShowSearchResult,{
          sourceKey: ref.sourceKey,
          searchResult: ref.searchResult,
          zoom: true,
          highlight: true,
          trackRecent: true,
          componentId: this._componentId
        });
      }
    });
  }

  directionsClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    const source = aiimUtil.getSource(this.props.sourceKey);
    const feature = this.props.featureItem.feature;
    itemUtil.makeItemReference(source,feature,true).then(ref => {
      if (ref) {
        Topic.publish(Topic.DirectionsClicked,{
          searchResult: ref.searchResult
        });
      }
    });
  }

  makeTimeAgo(createdOn,createdBy) {
    if (typeof createdOn !== "number") return;
    let timeAgo = moment(createdOn).fromNow();
    if (typeof createdBy === "string" && createdBy.length > 0) {
      const i18n = Context.getInstance().i18n;
      let pattern = i18n.timeAgo.byPersonPattern;
      timeAgo = pattern.replace("{timeAgo}",timeAgo);
      timeAgo = timeAgo.replace("{person}",createdBy);
    }
    return timeAgo;
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const source = aiimUtil.getSource(this.props.sourceKey);
    const featureItem = this.props.featureItem;
    const subTypeValue = this.props.subTypeValue;
    const feature = featureItem.feature;
    const hashref = "#";

    let mappings = source.workOrderMappings;
    let id = aiimUtil.lookupDomainValue(source,feature,mappings.idField);
    let title = aiimUtil.lookupDomainValue(source,feature,mappings.titleField);
    let priority = aiimUtil.lookupDomainValue(source,feature,mappings.priorityField);
    let status = aiimUtil.lookupDomainValue(source,feature,mappings.statusField);
    let createdBy = aiimUtil.lookupDomainValue(source,feature,mappings.createdByField);
    let createdOn = aiimUtil.getAttributeValue(feature.attributes,mappings.createdOnField);
    let assignedTo = aiimUtil.lookupDomainValue(source,feature,mappings.assignedToField);

    if (id === null || id === undefined) id = "";
    if (title === null || title === undefined) title = "";
    if (priority === null || priority === undefined) priority = "";
    if (status === null || status === undefined) {
      if (mappings.statusField) status = "";
    }
    if (assignedTo === null || assignedTo === undefined) {
      if (mappings.assignedToField) assignedTo = "";
    }

    //createdOn = Date.now() - (60000 * 60 * 7); // testing only
    //console.log(title,id,createdOn,new Date(createdOn));

    let timeAgo = this.makeTimeAgo(createdOn,createdBy);
    if (timeAgo === null || timeAgo === undefined) {
      if (mappings.createdOnField) timeAgo = "";
    }

    let proximityNode = null;
    const proximity = featureItem.proximity;
    if (typeof proximity === "string" && proximity.length > 0) {
      proximityNode = (
        <span key="proximity" className={CSS.itemSubTitle}
          dangerouslySetInnerHTML={{ __html: proximity}}></span>
      );
    }

    let actionNode = null;
    let directionsTitle = i18n.directions.ariaTitle.directionsTo;
    directionsTitle = directionsTitle.replace("{destinationName}", title);

    if (Context.getInstance().config.networkServiceUrl) {
      actionNode = (
        <div>
          <button className={CSS.itemAction} onClick={this.directionsClicked}
            aria-label={directionsTitle}>
            <span><img src={directionsIcon}></img></span>
          </button>
          <div key="priority" className={"i-priority"}>
          <span key="svg">{Icons.exclamationTriangle()}</span>
          <span key="lbl">{priority}</span>
          </div>  
        </div>
      );
    }

    const list = [];
    list.push(
      <div key="idp" className="i--toprow">
        <span key="id" className="i--id">
          <span key="svg" className="i--col1">{Icons.label()}</span>
          <span key="lbl" className="i--col2">{id}</span>
        </span>
      </div>
    );
    if (timeAgo !== null && timeAgo !== undefined) {
      list.push(
        <div key="timeAgo" className="i--row">
          <span key="a" className="i--col1">
            {Icons.calendar()}
          </span>
          <span key="b" className="i--col2">{timeAgo}</span>
        </div>
      );
    }
    if (status !== null && status !== undefined) {
      list.push(
        <div key="status" className="i--row">
          <span key="a" className="i--col1">
            {Icons.information()}
          </span>
          <span key="b" className="i--col2">{status}</span>
        </div>
      );
    }
    if (assignedTo !== null && assignedTo !== undefined) {
      list.push(
        <div key="assignedTo" className="i--row">
          <span key="a" className="i--col1">
            {Icons.user()}
          </span>
          <span key="b" className="i--col2">{assignedTo}</span>
        </div>
      );
    }

    const node = (
      <li className={"i-workorder"}  aria-label={title} onClick={this.detailsClicked}>
        <button ref={this._itemRef} onClick={this.detailsClicked}>
          <span className="--i-item-header">
            <span className="--i-item-title">
              <SubTypeIcon forFeature={true} sourceKey={source.key} subTypeValue={subTypeValue} />
              <span className={CSS.itemText}>
                <span key="title" className={CSS.itemTitle}>{title}</span>
                {proximityNode}
              </span>
            </span>
          </span>
          <div className="i-workorder-attributes" aria-hidden="true">
          <div>{list}</div>
          </div>
        </button>
        {actionNode}
      </li>
    );
    return node;
  }

}
