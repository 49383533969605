import BaseClass from "../util/BaseClass";
import Context from "./Context";
import TrackingViews from "../aiim/tracking/TrackingViews";
import * as mediaUtil from "../spaceplanner/components/Editor/media//mediaUtil";
import * as portalUtil from "../util/portalUtil";
import OfficePlan from "../spaceplanner/base/OfficePlan";
import { IItem } from "@esri/arcgis-rest-types";

export default class User extends BaseClass {

  portal: __esri.Portal;
  portalUser: __esri.PortalUser;
  trackingViews = new TrackingViews();

  _indoorsFolderID: string;
  _isIndoorsUser = false;
  _watchTVIntervalID = null;
  _watchTVIntervalMillis = 60000 * 10; // -1 to turn off, auto refresh interval

  canConfigureApp() {
    const item = Context.instance.configuration.appItem;
    if (item) {
      return this.canEditPortalItem(item);
    } else {
      return this.canCreateAppItem();
    }
  }

  canCreateAppItem() {
    return (this.isAdmin() || this.isPublisher());
  }

  canCreatePlan() {
    if (Context.instance.isFPE() && this.isEditor()) {
      return true;
    } else {
      return (this.isAdmin() || this.isPublisher());
    }
  }

  canCreateService() {
    if (Context.instance.isFPE()) return false; // no support service for FPE
    return (this.isAdmin() || this.isPublisher());
  }

  canEditMediaLayers() {
    const portalItem = mediaUtil.getWebmapItem();
    const itemControl = portalItem && portalItem.itemControl;
    return (itemControl === "admin" || itemControl === "update");
  }

  canEditPortalItem(item: IItem) {
    const owner = item && item.owner;
    if (typeof owner === "string" && owner.length > 0 && this.portalUser) {
      if (this.isAdmin()) return true;
      // if (owner.toLowerCase() === this.getUsername().toLowerCase() && this.isPublisher()) {
      if (owner.toLowerCase() === this.getUsername().toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  ensureIndoorsFolder(): Promise<string> {
    const promise = new Promise<string>((resolve,reject) => {
      if (!this.isAnonymous() && !this._indoorsFolderID) {
        this.portalUser.fetchFolders().then(result => {
          let folderID = this._findIndoorsFolderID(result);
          if (folderID) {
            this._indoorsFolderID = folderID;
          } else {
            let v = this.getIndoorsFolderName();
            return portalUtil.createFolder(v,this.getUsername()).then(r2 => {
              this._indoorsFolderID = r2.data.folder.id;
            });
          }
        }).then(() => {
          resolve(this._indoorsFolderID);
        }).catch(ex => {
          reject(ex);
        });
      } else {
        resolve(this._indoorsFolderID);
      }
    });
    return promise;
  }

  _findIndoorsFolderID(folders: __esri.PortalFolder[]): string {
    let folderID = null;
    const lc = this.getIndoorsFolderName().toLowerCase();
    if (folders && folders.length > 0) {
      folders.some(f => {
        if (f.title.toLowerCase() === lc) folderID = f.id;
        return !!folderID;
      });
    }
    return folderID;
  }

  getDisplayName() {
    const user = this.portalUser;
    if (user) {
      /* TODO should we display full name?
      if (typeof user.fullName === "string" && user.fullName.length > 0) {
        return user.fullName;
      } else {
        return user.username;
      }
      */
      return user.username;
    }
    return null;
  }

  getIndoorsFolderName() {
    return "Indoors";
  }

  getFullName() {
    if (this.portalUser) {
      return this.portalUser.fullName;
    }
    return null;
  }

  getEmail() {
    if (this.portalUser) {
      const email = this.portalUser.email;
      if (typeof email === "string" && email.length > 0) {
        return email;
      }
    }
    return null;
  }

  getUnit() {
    const lib = Context.getInstance().lib;
    let unit = "metric";
    if (lib.dojo.kernel.locale === "en-us"){
      unit = "non-metric";
    }
    return unit;
  }

  getUsername() {
    if (this.portalUser) {
      return this.portalUser.username;
    }
    return null;
  }

  hasPrivilege(name) {
    // @ts-ignore
    const privileges = this.portalUser && this.portalUser.privileges;
    if (privileges && privileges.length > 0) {
      for (let i=0; i<privileges.length; i++){
        if (privileges[i] === name) {
          return true;
        }
      }
    }
    return false;
  }

  isAdmin() {
    const r = this.portalUser && this.portalUser.role;
    // @ts-ignore
    if (r === "org_admin" || r === "account_admin") return true;
    return false;
  }

  isAnonymous() {
    return (!this.portalUser);
  }

  isDataEdit() {
    // @ts-ignore
    const privileges = this.portalUser && this.portalUser.privileges;
    if(privileges && privileges.length >0) {
      for (let i=0;i< privileges.length; i++){
        if(privileges[i] === "features:user:edit")
          return true;
      }
    }
    return false;
  }

  isEditor() {
    return this.isDataEdit() || this.isFullDataEdit()
  }

  isFullDataEdit() {
    // @ts-ignore
    const privileges = this.portalUser && this.portalUser.privileges;
    if(privileges && privileges.length >0) {
      for (let i=0;i< privileges.length; i++){
        if(privileges[i] === "features:user:fullEdit")
          return true;
      }
    }
    return false;
  }

  isIndoorsUser() {
    return this._isIndoorsUser;
  }

  isPlanOwner() {
    const plan = OfficePlan.getActive()
    let info = null
    if (plan) {
      info = plan.getMetaInfo()
    }
    const owner = info && info.owner && info.owner.toLowerCase();
    return owner === this.getUsername().toLowerCase();
  }

  isPublisher() {
    if (this.isAdmin()) return true;
    const r = this.portalUser && this.portalUser.role;
    // @ts-ignore
    if (r === "org_publisher" || r === "account_publisher") {
      return this.isDataEdit() || this.isFullDataEdit()
      // return true
    }
    return false;
  }

  setPortal(portal: __esri.Portal) {
    this.portal = portal;
    if (portal && portal.user) {
      this.portalUser = portal.user;

      this._isIndoorsUser = this.hasPrivilege("features:user:editIndoorsSpaces");
      if (!this._isIndoorsUser) {
        if (this.portalUser && this.portalUser.sourceJSON && this.portalUser.sourceJSON.userLicenseTypeId) {
          const userLicenseTypeId = this.portalUser && this.portalUser.sourceJSON
                            && this.portalUser.sourceJSON.userLicenseTypeId;
          if(userLicenseTypeId === "IndoorsUserUT") this._isIndoorsUser = true;
        }
      }

      this.trackingViews.load(this.portal,this.portalUser);
      this._watchTrackingViews();
    } else {
      this.portalUser = null;
      this.trackingViews = new TrackingViews();
    }
  }

  _watchTrackingViews() {
    clearInterval(this._watchTVIntervalID);
    if (this.trackingViews && this.portal && this.portal.user) {
      const millis = this._watchTVIntervalMillis;
      if (millis >= 10000) {
        this._watchTVIntervalID = setInterval(() => {
          this.trackingViews.load(this.portal,this.portalUser);
        },millis);
      }
    }
  }

}
