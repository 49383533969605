import BaseClass from "./BaseClass";
import Context from "../context/Context";
import Rdx from "../redux/Rdx";
import Starter from "../context/Starter";
import Topic from "../context/Topic";
import * as component from "../components/util/component";

export default class AppHelper extends BaseClass {

  app;
  shouldConsiderNativeApp = true;

  _considerNativeApp() {
    if (!this.shouldConsiderNativeApp) {
      return false;
    } else if (Context.instance.appMode.isSP_or_FPE()) {
      return false;
    } else if (window.location.pathname.toLowerCase().indexOf("/kiosk.html") !== -1) {
      return false;
    }

    // copy url from Safari to chrome, # is encoded on url, issue #1912
    let href = window.location.href;
    let idx = href.indexOf("%23");
    if (idx > 0) {
      href = href.substring(0,idx);
      window.history.replaceState({},"",href);
    }

    let regex = new RegExp('[\\?&]openWebApp=([^&#]*)');
    let results = regex.exec(window.location.search);
    let v = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    if (v === "true") return false;
    regex = new RegExp('[\\?&]edit=([^&#]*)');
    results = regex.exec(window.location.search);
    v = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    if (v === "true") return false;
    return (!!navigator.platform && /iPhone|iPad/.test(navigator.platform));
  }

  _handleStartupError(error) {
  }

  _listen() {
    component.own(this.app,[
      Topic.subscribe(Topic.ConfiguratorActivated,params => {
        component.refresh(this.app);
      }),
      Topic.subscribe(Topic.ConfiguratorClosed,params => {
        component.refresh(this.app);
      })
    ]);
  }

  reloadViews(params) {
    const promise = new Promise((resolve,reject) => {
      // TODO
      // this.setState(state => {
      //   return {
      //     reloadingViews: true,
      //     startupError: null
      //   };
      // });
      if (Rdx.store) {
        Rdx.resetAll(Rdx.store);
      }
      document.body.classList.remove(
        "i-infopanel-open","i-sidebar-panel-open",
        "i--workbar-panel-open","i--assetbar-panel-open"
      );
      const starter = new Starter();
      starter.reloadViews(params).then(result => {
        //console.log("Views reloaded...");


        // TODO
        // this.setState(state => {
        //   return {
        //     wasStarted: true,
        //     startupError: null,
        //     reloadingViews: false,
        //     noAppIdOrWebMap: !Context.instance.views.activeView
        //   };
        // });

        resolve();
      }).catch(error => {

        console.error("Error reloading views:",error);
        reject(error);

        // TODO
        // this.setState(state => {
        //   return {
        //     wasStarted: true,
        //     startupError: null,
        //     reloadingViews: false,
        //     noAppIdOrWebMap: !Context.instance.views.activeView
        //   };
        // });
        // const i18n = Context.instance.i18n;
        // ModalController.showMessage(i18n.messages.errorAccessingData);

      });
    });
    return promise;
  }

  startupApp() {
    const promise = new Promise((resolve,reject) => {
      //console.log("shouldConsiderNativeApp",this.shouldConsiderNativeApp)

      const lib = Context.instance.lib;
      const url = lib.esri.urlUtils.urlToObject(window.location.href);
      const hasAppId = (url && url.query && url.query.appid);
      const hasCfg = (url && url.query &&
        (url.query.configure === "true" || url.query.edit === "true"));

      const considerNativeApp = this._considerNativeApp();
      const starter = new Starter();
      starter.considerNativeApp = considerNativeApp;
      starter.startupApp().then(result => {
        console.log("App started, starting UI...");

        let config = Context.instance.config;
        if (considerNativeApp && config.nativeApp && config.nativeApp.showIOS) {

          // TODO
          // this.setState({
          //   wasStarted: true,
          //   showNativeAppOption: true,
          //   startupError: null
          // });

        } else {
          this._listen();
          if (Context.instance.session.startInConfiguratorMode) {
            Context.instance.uiMode.toggleConfigurator(true);
          }

          // // TODO:
          // this.setState({
          //   wasStarted: true,
          //   startupError: null
          // });

          //this.watchKioskActivity(); TODO
          resolve();
        }
      }).catch(error => {
        const noWebMap = (error && error.message === "No webmap was specified");
        //console.log("startInConfiguratorMode",Context.instance.session.startInConfiguratorMode)
        if (noWebMap) {
          console.warn("No webmap was specified");
        } else {
          console.error(error);
        }

        if (Context.instance.session.startInConfiguratorMode) {
          Context.instance.uiMode.toggleConfigurator(true);
          resolve();
        } else if (!hasAppId && hasCfg) {
          Context.instance.uiMode.toggleConfigurator(true);
          resolve();
        } else {
          this._handleStartupError(error);
          reject(error)
        }
      });
    });
    return promise;
  }

}
