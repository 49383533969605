import React from "react";

//import Context from "../../../context/Context";
import * as component from "../../../components/util/component";

export default class Issues extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    //const i18n = Context.instance.i18n;
    const issues = this.props.issues;
    const nodes = [];

    if (Array.isArray(issues)) {
      issues.forEach(issue => {
        nodes.push(
          <li key={issue.key}>{issue.message}</li>
        );
      })
    }

    if (nodes.length > 0) {
      return (
        <ul className="i-issues">
          {nodes}
        </ul>
      );
    }
    return null;
  }

}
