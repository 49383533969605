import React from "react";

import Context from "../../../context/Context";
import DropdownButton from "../../common/Dropdown/DropdownButton";
import Icons from "../../util/Icons";
import Topic from "../../../context/Topic";
import Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import {getLoggedInUser, logout} from "../More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";
import * as component from "../../util/component";
import icon from "./ArcGIS_Indoors_Round.png";

const CSS = {
  main: "i-header-user",
  userButton: "i-header-user-button"
};

export default class UserControl extends React.Component {

  constructor(props) {
    super(props);
    this.signinClicked = this.signinClicked.bind(this);
    this.signoutClicked = this.signoutClicked.bind(this);
  }

  componentDidMount() {
    component.own([
      Topic.subscribe(Topic.BookingLogin, params => {
        this.forceUpdate()
      }),
      Topic.subscribe(Topic.AppIdUpdated, params => {
        this.forceUpdate()
      })
    ])
  }

  configureClicked = () => {
    const uiMode = Context.instance.uiMode
    if (uiMode.isConfiguratorOn) return;
    setTimeout(() => {
      uiMode.toggleConfigurator(true);
      Topic.publish(Topic.ConfiguratorActivated,{});
    },10);
  }

  render() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const user = context.user;
    const hashref = "#";

    if (user.isAnonymous()) {
      return (
        <div className={CSS.main}>
          <a className={CSS.userButton} href={hashref} tabIndex="0"
            role="button" aria-label={i18n.user.signinTooltip}
            onClick={this.signinClicked}>
            <span>{i18n.user.signin}</span>
          </a>
        </div>
      );
    } else {
      const name = Context.getInstance().user.getDisplayName();
      const buttonContent = (
        <div key="i-user-dropdown" aria-label={i18n.user.menuTooltip}>
          <span className="esri-icon-user"></span>
          <span>{name}</span>
          <span>{Icons.dropdown()}</span>
        </div>
      );
      const dropdownContent = this.renderDropdownMenu();
      return (
        <div className={CSS.main}>
          <DropdownButton
            closeOnSelect={true}
            focusFirst={true}
            buttonClassName={"i-header-user-button"}
            buttonContent={buttonContent}
            dropdownContent={dropdownContent}
            placement="bottom-end">
          </DropdownButton>
        </div>
      );
    }
  }

  renderDropdownMenu() {
    const i18n = Context.instance.i18n;
    const hashref = "#";
    let isConfigureFirst = false;

    let configure = null;
    const canConfigure = Context.instance.user.canConfigureApp();
    if (canConfigure) {
      isConfigureFirst = true;
      configure = (
        <li key="configure" role="none">
          <a className="i-dd-menuitem" href={hashref} role="menuitem button" tabIndex="0"
            aria-label={i18n.configurator.buttonLabel}
            onClick={this.configureClicked}
            data-i--first={isConfigureFirst}
          >{i18n.configurator.buttonLabel}</a>
        </li>
      );
    }

    let logoutBookingSystem = null
    let bookingSystem = Office365.getInstance()
    const user = getLoggedInUser(bookingSystem)
    // Check configurator
    const configuration = Context.instance.configuration
    const configurables = configuration.extractConfigurables()
    const hotelingEnabled = configurables.workspaceReservation 
          && (configurables.workspaceReservation.enableHotel || configurables.workspaceReservation.enableMeeting)
    if (user && hotelingEnabled) {
      let label = i18n.more.bookWorkspace.logout
      label = label.replace("{system}", "Office365")
      const logoutClicked = () => {
        logout(bookingSystem)
      }
      logoutBookingSystem = (
        <li key="logoutBookingSystem" role="none">
          <a className="i-dd-menuitem i-logout" href={hashref} role="menuitem button" tabIndex="0"
            aria-label={label}
            onClick={logoutClicked}>
            {Icons.office365()}
            <div className="i-logout-info">
              <div className="i-user-name">{user.userName}</div>
              <div className="i-booking-name">{label}</div>
            </div>
          </a>
        </li>
      );
    }

    const name = Context.getInstance().user.getDisplayName();
    const menu = (
      <ul className="i-user-menu" role="menu">
        {configure}
        {logoutBookingSystem}
        <li className="i-user-logout" key="signout" role="none">
          <a className="i-dd-menuitem i-logout" href={hashref} role="menuitem button" tabIndex="0"
            aria-label={i18n.user.signoutTooltip}
            onClick={this.signoutClicked}
            data-i--first={!isConfigureFirst}
          >
          <img src={icon} alt="Logo" />
          <div className="i-logout-info">
            <div className="i-user-name">{name}</div>
            <div className="i-booking-name">{i18n.more.bookWorkspace.logoutIndoors}</div>
          </div>
        </a>
        </li>
      </ul>
    );
    return menu;
  }

  signinClicked() {
    Topic.publish(Topic.SignInClicked,{});
  }

  signoutClicked() {
    Topic.publish(Topic.SignOutClicked, {});
  }

}
