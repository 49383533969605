import React from 'react';
import * as component from "../../../../components/util/component";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as queryUtil from "../../../base/queryUtil";
import * as selectionUtil from "../../../../aiim/util/selectionUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import FieldNames from '../../../../aiim/datasets/FieldNames';
import Context from '../../../../context/Context';
import {
  List,
  ListItem,
  ListItemTitle,
  ListItemTitleMain
} from "../../../styles/Common/list";
import Topic from '../../../../context/Topic';
import RelatedPopup from './RelatedPopup';
import Popup from './Popup';
import OfficePlan from '../../../base/OfficePlan';


export default class RelatedFeatures extends React.Component {
  _mounted = false

  constructor(props) {
    super(props)
    this.state = component.newState({
      results: [],
      isQuerying: true
    })
  }

  componentDidMount() {
    this._mounted = true
    this.runQuery()

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated]
        if (actions && actions.indexOf(params.action) !== -1) {
          this.refreshFeatureItem()
        }
      })
    ])
  }

  setRelatedItems = (count) => {
    if (typeof this.props.setRelatedItems === "function") {
      this.props.setRelatedItems(count)
    }
  }

  runQuery = () => {
    const feature = this.getFeatureItem()
    if (feature && feature.geometry) {
      this.query().then(results => {
        if (this._mounted) {
          this.setState({
            results: results,
            isQuerying: false
          })
          this.setRelatedItems(results.length)
        }
      }).catch(e => {
        console.error("Error querying feature",e)
      })
    } else {
      this.setState({
        results: [],
        isQuerying: false
      })
      this.setRelatedItems(0)
    }
  }

  refreshFeatureItem = () => {
    const featureItem = this.getFeatureItem();
    const type = this.props.type
    let source = type === "person" ?
      officePlanUtil.getPeopleSource() :
      officePlanUtil.getUnitsSource()
    queryUtil
      .refreshFeatureItem(source, featureItem)
      .then((result) => {
        if (result && this._mounted) {
          this.setState({ feature: result });
          this.runQuery()
        }
      })
      .catch((ex) => {
        console.error("Popup: Error refreshing feature", ex);
      });
  }

  getFeatureItem = () => {
    return this.state.feature || this.props.feature
  }

  componentWillUnmount() {
    this._mounted = false
  }

  query = async () => {
    const promises = []
    const item = this.props.item
    const itemSource = item.getSource()
    const itemFeature = this.getFeatureItem().feature
    const levelId = aiimUtil.getAttributeValue(itemFeature.attributes, FieldNames.LEVEL_ID)
    const layers = Context.instance.views.mapView.map.allLayers.items
    const project = Context.instance.spaceplanner.planner.project
    const baseline = project.isHosted && project.hostedInfo
    const job = {
      source: itemSource,
      feature: itemFeature,
      levelId: levelId,
      isVersioned: project.isVersioned
    }
    layers.forEach(layer => {
      if (layer.geometryType === "point" && layer.type === "feature") {
        let ok = true
        if (project.isHosted) {
          if (layer === baseline.peopleLayer || layer === baseline.unitsLayer) {
            ok = false
          }
        }
        if (ok) {
          const promise = this.queryLayer(job, layer)
          promises.push(promise)
        }
      }
    })

    if (promises.length === 0) {
      return Promise.resolve([]);
    } else {
      const results = await Promise.all(promises);
      const finalResults = [];
      results.forEach(featureList => {
        if (featureList !== undefined && featureList.length > 0) {
          featureList.forEach(feature => {
            const featureLayer = feature && feature.layer
            const peopleLayer = sourceUtil.getPeopleLayer();
            const baselinePeopleLayer = project && project.hostedInfo && project.hostedInfo.peopleLayer;
            let isOccupant = (featureLayer === peopleLayer) || (featureLayer === baselinePeopleLayer);
            if (!isOccupant) finalResults.push(feature);
          });
        }
      });
      return Promise.resolve(finalResults);
    }
  }

  queryLayer = (job, layer) => {
    const url = Context.checkMixedContent(layer.url) + "/" + layer.layerId
    const lib = Context.instance.lib
    const layerSource = layer.source
    const task = new lib.esri.QueryTask({ url: url })
    const query = new lib.esri.Query()

    query.outFields = ["*"]
    query.returnGeometry = true
    query.returnZ = true
    query.geometry = this.getFeatureItem().geometry
    query.spatialRelationship = "intersects"
    if (job.isVersioned) queryUtil.applyGdbVersion(layer, query)

    let where = selectionUtil.getBaseDefinitionExpression(layer);
    let fld = Context.instance.aiim.getLevelIdField(layer);
    if (fld) {
      let v = job.levelId;
      if (fld.type === "string" && typeof v === "string") {
        v = "'"+selectionUtil.escSqlQuote(v)+"'";
      }
      let levelPart = "("+fld.name+" = "+v+")";
      if (where) {
        where = "(" + where + ") AND (" + levelPart + ")";
      } else {
        where = levelPart;
      }
    }
    if (!where) where = "1=1";
    query.where = where;

    const promise = task.execute(query)
    let filtered = []
    return promise.then(result => {
      if (result && result.features && result.features.length > 0) {
        result.features.forEach(feature => {
          const attributes = feature.attributes
          const featureLevelId = aiimUtil.getAttributeValue(attributes, FieldNames.LEVEL_ID)
          if (featureLevelId === job.levelId) {
            if (filtered.indexOf(feature) === -1) {
              filtered.push({
                feature: feature,
                layer: layer,
                source: layerSource
              })
            }
          }
        })
        return filtered
      }
    })
  }

  render() {
    const results = this.state.results
    if (results && results.length > 0) {
      let items = results.map((item, index) => {
        return this.renderItem(item, index)
      })
      return items
    } else if (!this.state.isQuerying) {
      const i18n = Context.instance.i18n
      return (
        <List>
          <ListItem unassigned={true}>
            <ListItemTitle>
              <ListItemTitleMain>{i18n.spaceplanner.popup.noRelatedItems}</ListItemTitleMain>
            </ListItemTitle>
          </ListItem>
        </List>
      )
    } else return null

  }

  renderItem = (item, index) => {
    const layer = item.layer
    const attributes = item.feature.attributes
    const displayField = layer.displayField
    const name = aiimUtil.getAttributeValue(attributes, displayField) ||
      aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME) ||
      aiimUtil.getAttributeValue(attributes, FieldNames.NAME)

    const backButtonClicked = () => {
      const type = this.props.type
      const feature = this.getFeatureItem()
      const attributes = feature.feature.attributes
      let name, source, content
      if (type === "unit") {
        source = officePlanUtil.getUnitsSource()
        name = aiimUtil.getAttributeValue(attributes, Context.instance.aiim.getUnitName())
        content = <Popup sourceKey={source.key} featureItem={feature} isPerson={false} />
      } else if (type === "person") {
        source = officePlanUtil.getPeopleSource()
        name = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
        content = <Popup sourceKey={source.key} featureItem={feature} isPerson={true} />
      }
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: feature,
        fetchFeatureItemGeometry: true,
        zoom: false,
        content: content
      })
    }

    const openRelatedPopup = () => {
      const source = this.props.type === "person" ?
        officePlanUtil.getPeopleSource() :
        this.props.type === "unit" ?
        officePlanUtil.getUnitsSource() : null
      Topic.publish(Topic.ShowItemPopup, {
        view: Context.instance.views.activeView,
        title: name,
        source: source,
        featureItem: this.getFeatureItem(),
        fetchFeatureItemGeometry: true,
        zoom: false,
        content: <RelatedPopup feature={item.feature} layer={item.layer} backButtonClicked={backButtonClicked} />
      })
    }

    return (
      <ListItem key={index} onClick={openRelatedPopup}>
      <ListItemTitle>
        <ListItemTitleMain>
          {name}
        </ListItemTitleMain>
        </ListItemTitle>
      </ListItem>
    );
  }

}
