import styled, { css } from "styled-components";

const Container = styled.div`
  margin: 0;
  opacity: 1;
  ${(props) =>
    props.isRefreshing &&
    css`
      opacity: 0.3;
    `};
  
`;

export { Container };
