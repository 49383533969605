import React from "react";

import Context from "../../../context/Context";
import ChoiceDropdown from "../../common/ChoiceDropdown/ChoiceDropdown";
import MenuOption from "../../common/ChoiceDropdown/MenuOption";
import * as component from "../../util/component";
import Topic from "../../../context/Topic";
import Configuration from "../../../context/Configuration";

export default class ThemeDropdown extends React.Component {

  constructor(props) {
    super(props);
    const optionData = this.makeOptionData();
    this.state = component.newState({
      optionData: optionData,
      menuOption2: false
      });
    this.clicked = this.clicked.bind(this);
  }

  getPortalTheme =()=>{
    let theme=null;
    let portal=Context.instance.portal;
    let sharedTheme = portal.portalProperties && portal.portalProperties.sharedTheme ;
    let indoorsTheme=Context.getInstance().configuration._configurables.theme;
    let brand, brandText, brandHighlight, background, highlight, text, textSecondary, link, button,
        buttonHighlight, buttonText, accent, border;

    if(sharedTheme.header.background && sharedTheme.header.background!== "no-color") brand = sharedTheme.header.background
    else brand = indoorsTheme["theme-color-brand"]

    if(sharedTheme.header.text && sharedTheme.header.text!== "no-color") brandText = sharedTheme.header.text
    else brandText = indoorsTheme["theme-color-brand-text"]

    if(sharedTheme.header.background && sharedTheme.header.background!=="no-color") brandHighlight = sharedTheme.header.background
    else brandHighlight = indoorsTheme["theme-color-brand"]

    if(sharedTheme.body.background && sharedTheme.body.background!== "no-color") background = sharedTheme.body.background
    else background = indoorsTheme["theme-color-background"]

    if(sharedTheme.body.background && sharedTheme.body.background!== "no-color") highlight = sharedTheme.body.background
    else highlight = indoorsTheme["theme-color-background"]

    if(sharedTheme.body.text && sharedTheme.body.text!== "no-color") text = sharedTheme.body.text
    else text = indoorsTheme["theme-color-text"]

    if(sharedTheme.body.text && sharedTheme.body.text!== "no-color") textSecondary = sharedTheme.body.text
    else textSecondary = indoorsTheme["theme-color-text-secondary"]

    if(sharedTheme.body.link && sharedTheme.body.link!== "no-color") link = sharedTheme.body.link
    else link = indoorsTheme["theme-color-link"]

    if(sharedTheme.button.background && sharedTheme.button.background!== "no-color") button = sharedTheme.button.background 
    else button = indoorsTheme["theme-color-button"]

    if(sharedTheme.button.background && sharedTheme.button.background!== "no-color") buttonHighlight = sharedTheme.button.background 
    else buttonHighlight = indoorsTheme["theme-color-button"]

    if(sharedTheme.button.text && sharedTheme.button.text!== "no-color") buttonText = sharedTheme.button.text
    else buttonText = indoorsTheme["theme-color-button-text"]

    if(sharedTheme.header.background && sharedTheme.header.background!== "no-color") accent = sharedTheme.header.background
    else accent = indoorsTheme["theme-color-accent"]

    if(sharedTheme.body.text && sharedTheme.body.text!== "no-color") border = sharedTheme.body.text
    else border = indoorsTheme["theme-color-border"]

    if(sharedTheme && sharedTheme.body && sharedTheme.header && sharedTheme.button){
      theme = {
        "theme-color-brand": brand,
        "theme-color-brand-text": brandText,
        "theme-color-brand-highlight": brandHighlight,
        "theme-color-background": background,
        "theme-color-highlight": highlight,
        "theme-color-text": text,
        "theme-color-text-secondary": textSecondary,
        "theme-color-link": link,
        "theme-color-button": button,
        "theme-color-button-highlight": buttonHighlight,
        "theme-color-button-text": buttonText,
        "theme-color-accent": accent,
        "theme-color-border": border,
      }
    }else{
        theme= indoorsTheme;
    }
    return theme;
  }

  clicked=(menuOption)=> {
    if(menuOption.props.value === "Option1"){
      let theme=this.getPortalTheme();
      const cfg = Context.getInstance().config;
      Object.assign(cfg.theme, theme);
      Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme})
    }else{
      this.setState({
        menuOption2:true
      })
      const configuration = new Configuration();
      let themeConfig = configuration._configurables.theme;
      const cfg = Context.getInstance().config;
      Object.assign(cfg.theme, themeConfig);
      Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme});
    }
  }

  makeOptionData() {
    const i18n = Context.getInstance().i18n;
    const optionData = [];
      optionData.push({
        label: i18n.configurator.theme.resetToPortalTheme,
        value: "Option1"
      });
      optionData.push({
        label: i18n.configurator.theme.resetToDefaultTheme,
        value: "Option2"
      });
    return optionData;
  }

  // makeSubOptionData =()=> {
  //   const i18n = Context.getInstance().i18n;
  //   const menuOption2Data = [];
  //   menuOption2Data.push({
  //     label: i18n.configurator.theme0["menu-option2-drop-down-option1"],
  //     value: "MenuOption1"
  //   })

  //   menuOption2Data.push({
  //     label: i18n.configurator.theme0["menu-option2-drop-down-option2"],
  //     value: "MenuOption2"
  //   })

  //   menuOption2Data.push({
  //     label: i18n.configurator.theme0["menu-option2-drop-down-option3"],
  //     value: "MenuOption3"
  //   })
  //   return menuOption2Data;
  // }

  // subMenuClicked =()=>{
  //   const configuration = new Configuration();
  //   let themeConfig = configuration._configurables.theme;
  //   const cfg = Context.getInstance().config;
  //   Object.assign(cfg.theme, themeConfig);
  //   Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme});


    // let theme1Config = configuration._configurables.theme1;
    // let theme2Config = configuration._configurables.theme2;
    // let optionValue = subOptionMenu.props.value;
    // const cfg = Context.getInstance().config;
    // switch(optionValue){
    //   case "MenuOption1":
    //         Object.assign(cfg.theme, theme0Config);
    //         Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme});
    //         break;
    //   case "MenuOption2":
    //         Object.assign(cfg.theme, theme1Config);
    //         Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme});
    //         break;
    //   case "MenuOption3":
    //         Object.assign(cfg.theme, theme2Config);
    //         Topic.publish(Topic.ApplyTheme, {theme:Context.instance.config.theme});
    //         break;
    //   default:
    //         console.log("Invalid option");
    // }


 // }

  render() {
    // let disabled = true;
    // let cssStyle={
    //     position:"fixed"
    // }
    const optionData = this.state.optionData;
    //let subMenu=null;
    const options = optionData.map(info => {
      return (
        <MenuOption key={info.value} label={info.label} value={info.value}
          checked={false} noIcon={true} onClick={this.clicked} type="themeDropdown"/>
      );
    });

     return (
      <div>
        <section>
        <ChoiceDropdown disabled={false} icon="threeDots">
          {options}
        </ChoiceDropdown>
        </section>
      </div>
     )
  }
}
