import React from "react";

import {ListItemTitle as CalciteListItemTitle} from "calcite-react/List";

export default class ListItemTitle extends React.Component {

  render() {
    return (
      <CalciteListItemTitle {...this.props}>
        {this.props.children}
      </CalciteListItemTitle>
    );
  }

}
