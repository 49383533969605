import styled from "styled-components";

import CalciteButton from "calcite-react/Button";

const Button = styled(CalciteButton)`
  padding: 0.9rem;
  border: 0;
  background: ${props =>
    props.active === true
      ? props.theme.palette.transparentDarkerGray
      : "inherit"};
  color: ${props =>
    props.active === true
      ? props.theme.palette.lightBlue
      : props.theme.palette.white};
  transition: all ${props => props.theme.transition};

  &:hover {
    background: ${props => props.theme.palette.transparentDarkerGray};

    /* keep the current color of the icon on hover */
    color: ${props =>
      props.active === true
        ? props.theme.palette.lightBlue
        : props.theme.palette.white};
  }
`;

export { Button };
