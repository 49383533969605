import Context from "../../context/Context";
import QueryAll from "./QueryAll";
import Source from "../../aiim/base/Source";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as mergeUtil from "./mergeUtil";
import * as serviceUtil from "./serviceUtil";
import * as sourceUtil from "./sourceUtil";

export function applyEdits(task,edits) {
  const promise = new Promise((resolve,reject) => {
    Promise.resolve().then(() => {
      const hasEdits = (edits && edits.length > 0);
      const serviceUrl = task.sourceInfo.serviceUrl;
      const timeout = task.timeout || (60000 * 30);
      if (hasEdits) {
        let url = serviceUrl+"/applyEdits";
        url = aiimUtil.appendTokenToUrl(url);
        const params = {
          f: "json",
          useGlobalIds: true,
          rollbackOnFailure: true,
          edits: JSON.stringify(edits)
        };
        if (task.gdbVersion) params.gdbVersion = task.gdbVersion;
        const options = {
          query: params,
          method: "post",
          responseType: "json",
          timeout: timeout
        };
        console.log("saveAsUtil.applyEdits.params",params)
        //console.log("applyEdits.options",options)
        const esriRequest = Context.instance.lib.esri.esriRequest;
        return esriRequest(url,options).then(result => {
          console.log("saveAsUtil.applyEdits.result",result);
          serviceUtil.validateApplyEditsResponse(result);
        });
      }
    }).then(() => {
      resolve();
    }).catch(ex => {
      //console.log("************************* saveAsutil.applyEdits.catch",ex)
      reject(ex);
    });
  });
  return promise;
}

export function applyParentPlanEdits(task) {
  const edits = task.edits;
  const hasEdits = (edits && edits.length > 0);
  if (!hasEdits) return Promise.resolve();
  const promise = new Promise((resolve,reject) => {
    Promise.resolve().then(() => {
      return makeNewSources(task);
    }).then(() => {
      return applyEdits(task,edits);
    }).then(() => {
      const project = Context.instance.spaceplanner.planner.project;
      const plan = Context.instance.spaceplanner.activePlan;
      if (plan.isVersioned) {
        const versionManager = project.versionedInfo.versionManager;
        const opts = {
          plan: {
            versionInfo: task.newPlanVersionInfo,
          },
          versionManager: versionManager,
          reconcile: true,
          applyEdits: true,
          post: false,
          forSaveAs: true,
          _areasTable: task._areasTable,
          _areaRolesTable: task._areaRolesTable,
          _peopleSource: task._peopleSource,
          _unitsSource: task._unitsSource
        };
        return mergeUtil.analyze(opts);
      } else {
        const hostedMergeTask = {
          wasMerged: false
        };
        const opts = {
          applyEdits: true,
          forSaveAs: true,
          _areasTable: task._areasTable,
          _areaRolesTable: task._areaRolesTable,
          _peopleSource: task._peopleSource,
          _unitsSource: task._unitsSource
        };
        return mergeUtil.beforeHostedMerge(hostedMergeTask,opts).then(() => {
          return mergeUtil.analyzeHosted(hostedMergeTask,opts);
        });
      }
    }).then(() => {
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

// export function ensureAreas(task) {
//   const promise = new Promise((resolve,reject) => {
//     const project = Context.instance.spaceplanner.planner.project;
//     const plan = Context.instance.spaceplanner.activePlan;
//     let versionManager;
//     if (project.versionedInfo) {
//       versionManager = project.versionedInfo.versionManager;
//     }
//     const mergeTask = mergeUtil.makeTask({
//       plan: plan,
//       versionManager: versionManager
//     });
//     task.queryAreasMergeTask = mergeTask;
//     Promise.resolve().then(() => {
//       return mergeUtil.queryAreas(mergeTask,mergeTask.preReconciledData);
//     }).then(() => {
//       resolve(task);
//     }).catch(ex => {
//       reject(ex);
//     })
//   });
//   return promise;
// }

export function makeNewSources(task) {
  const promises = [];
  const sourceInfo = task.sourceInfo;
  const peopleLayerInfo = sourceInfo.peopleLayerInfo;
  const peopleSourceKey = sourceUtil.getPeopleSourceKey();
  const unitsLayerInfo = sourceInfo.unitsLayerInfo;
  const unitsSourceKey = sourceUtil.getUnitsSourceKey();
  const areasTableInfo = sourceInfo.areasTableInfo;
  const areaRolesTableInfo = sourceInfo.areaRolesTableInfo;
  promises.push(makeSource(task,peopleLayerInfo,peopleSourceKey));
  promises.push(makeSource(task,unitsLayerInfo,unitsSourceKey));
  promises.push(makeTable(task,areasTableInfo,true,false));
  if (areaRolesTableInfo) {
    promises.push(makeTable(task,areaRolesTableInfo,false,true));
  }
  return Promise.all(promises);
}

function makeSource(task,layerInfo,sourceKey) {
  const promise = new Promise((resolve,reject) => {
    let source;
    const lib = Context.instance.lib;
    const sourceInfo = task.sourceInfo;
    const params = {url: sourceInfo.serviceUrl+"/"+layerInfo.id};
    if (task.gdbVersion) params.gdbVersion = task.gdbVersion;
    const layer = new lib.esri.FeatureLayer(params);
    layer.load().then(() => {
      source = new Source({
        key: sourceKey,
        name: layer.title,
        url: layer.url+"/"+layer.layerId,
        layer2D: layer
      });
      return source.checkSchema();
    }).then(() => {
      if (sourceKey === sourceUtil.getPeopleSourceKey()) {
        task._peopleSource = source;
      } else if (sourceKey === sourceUtil.getUnitsSourceKey()) {
        task._unitsSource = source;
      }
      resolve(source);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

function makeTable(task,tableInfo,isAreasTable,isAreaRolesTable) {
  const promise = new Promise((resolve,reject) => {
    let source;
    const lib = Context.instance.lib;
    const sourceInfo = task.sourceInfo;
    const params = {url: sourceInfo.serviceUrl+"/"+tableInfo.id};
    if (task.gdbVersion) params.gdbVersion = task.gdbVersion;
    const table = new lib.esri.FeatureLayer(params);
    table.load().then(() => {
      if (isAreasTable) {
        task._areasTable = table;
      } else if (isAreaRolesTable) {
        task._areaRolesTable = table;
      }
      resolve(source);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

function makeReviewerLayerAdds(task,opts) {
  const promise = new Promise((resolve,reject) => {
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(opts.fromLayerUrl);
    const toLayerInfo = opts.toLayerInfo;
    const planIdField = aiimUtil.findField(toLayerInfo.fields,"plan_id").name;

    const query = new lib.esri.Query();
    query.where = "1=1";
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    //if (task.gdbVersion) query.gdbVersion = task.gdbVersion;

    const adds = [];
    const qaopts = {
      perFeatureCallback: feature => {
        //console.log("makeAdds.perFeatureCallback",opts.planId,feature);
        let f = {attributes: feature.attributes};
        if (feature.geometry) {
          f.geometry = feature.geometry;
          if (f.geometry && f.geometry.toJSON) {
            f.geometry = feature.geometry.toJSON();
          }
        }
        f.attributes[planIdField] = opts.planId;
        adds.push(f);
      }
    };

    const qa = new QueryAll();
    qa.execute(url,query,qaopts).then(result => {
      console.log("fetchParent.result",result)
      if (adds.length > 0) {
        let layerEdits = {
          id: toLayerInfo.id,
          adds: adds
        }
        task.supportLayerEdits.push(layerEdits);
      }
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

function makeCommentLayerAdds(task,opts) {
  const promise = new Promise((resolve,reject) => {
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(opts.fromLayerUrl);
    const toLayerInfo = opts.toLayerInfo;
    const parentField = aiimUtil.findField(toLayerInfo.fields,"parent_cid").name;
    const planIdField = aiimUtil.findField(toLayerInfo.fields,"plan_id").name;
    const authorField = aiimUtil.findField(toLayerInfo.fields, "author").name;
    const dateField = aiimUtil.findField(toLayerInfo.fields, "author_date").name;
    const creatorField = toLayerInfo.editFieldsInfo.creatorField;
    const createDateField = toLayerInfo.editFieldsInfo.creationDateField;
    const objectIdField = toLayerInfo.objectIdField;

    const query = new lib.esri.Query();
    query.where = "1=1";
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    //if (task.gdbVersion) query.gdbVersion = task.gdbVersion;

    const adds = [];
    const commentAdds = [], replyAdds =[];
    const oidIdx = {};
    let idx = 0;
    const qaopts = {
      perFeatureCallback: feature => {
        //console.log("makeAdds.perFeatureCallback",opts.planId,feature);
        let f = {attributes: feature.attributes};
        if (feature.geometry) {
          f.geometry = feature.geometry;
          if (f.geometry && f.geometry.toJSON) {
            f.geometry = feature.geometry.toJSON();
          }
        }
        f.attributes[planIdField] = opts.planId;
        // adds.push(f);
        let pid = f.attributes[parentField];
        if(typeof pid === "number") {
          replyAdds.push(f);
        }else {
          const oid = f.attributes[objectIdField];
          idx++;
          oidIdx[oid] = idx;
          commentAdds.push(f);
        }
      }
    };

    const getNewParentId =(f)=> {
      const orig_oid = f.attributes[parentField];
      return oidIdx[orig_oid];
    }

    const resetPids =()=> {
      replyAdds.forEach((f)=> {
        f.attributes[parentField] = getNewParentId(f);
      })
      commentAdds.forEach((f)=>{
        adds.push(f);
      })
      replyAdds.forEach((f)=>{
        adds.push(f);
      })
    }

    const ensureAuthor =()=> {
      adds.forEach((f)=> {
        if(!f.attributes.hasOwnProperty(authorField)) {
          f.attributes[authorField] = f.attributes[creatorField];
          f.attributes[dateField] = f.attributes[createDateField];
        }
      })
    }

    const qa = new QueryAll();
    qa.execute(url,query,qaopts).then(result => {
      console.log("fetchParent.result",result)
      resetPids();
      ensureAuthor();
      if (adds.length > 0) {
        let layerEdits = {
          id: toLayerInfo.id,
          adds: adds
        }
        task.supportLayerEdits.push(layerEdits);
      }
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

export function readSupportLayers(task) {
  const sourceInfo = task.plan.sourceInfo;
  const planId = sourceInfo && sourceInfo.planId;
  const parentSupportInfo = sourceInfo && sourceInfo.parentSupportInfo;
  const planSupportInfo = sourceInfo && sourceInfo.supportInfo;
  const promises = [];
  if (parentSupportInfo && parentSupportInfo.commentsLayerInfo &&
      planSupportInfo && planSupportInfo.commentsLayerInfo) {
    const opts = {
      fromLayerUrl: parentSupportInfo.serviceUrl+"/"+parentSupportInfo.commentsLayerInfo.id,
      toLayerInfo: planSupportInfo.commentsLayerInfo,
      planId: planId
    }
    //console.log("opts.comments",opts)
    const p = makeCommentLayerAdds(task,opts);
    promises.push(p);
  }
  if (parentSupportInfo && parentSupportInfo.reviewersTableInfo &&
      planSupportInfo && planSupportInfo.reviewersTableInfo) {
    const opts = {
      fromLayerUrl: parentSupportInfo.serviceUrl+"/"+parentSupportInfo.reviewersTableInfo.id,
      toLayerInfo: planSupportInfo.reviewersTableInfo,
      planId: planId
    }
    //console.log("opts.reviewers",opts)
    const p = makeReviewerLayerAdds(task,opts);
    promises.push(p);
  }
  return Promise.all(promises);
}
