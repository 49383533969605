import Context from "../../context/Context";
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import QueryAll from "../../spaceplanner/base/QueryAll";
import * as aiimUtil from "../util/aiimUtil";
import { IFloorAwareInfo } from "./Datasets";
import { getDetailsLayer, getUnitsLayer } from "../../spaceplanner/base/sourceUtil";
import { CustomQueryTask } from "../../context/EsriLib";
import { escSqlQuote } from "../util/selectionUtil";
export interface IFacilityData {
  siteId: string,
  siteName: string
  facilityId: string,
  facilityName: string,
  levels: ILevelData[],
  levelsByLevelName: Record<string, ILevelData>,
  levelsByLevelNumber: Record<number, ILevelData>,
  levelsByVO: Record<number, ILevelData>,
  shortNamesByLevelNumber: Record<number, string>,
  levelNumbersByVO: Record<number, number>,
  zeroVOLevel: ILevelData,
  baseVO: number
}
export interface ILevelData {
  siteId: string,
  siteName: string,
  facilityId: string,
  facilityName: string,
  feature: __esri.Graphic,
  levelId: string,
  levelName: string,
  levelNumber: number,
  levelShortName: string,
  levelLongName?: string,
  verticalOrder: number,
  z: number,
  elevationAbsolute?: number,
  elevationRelative?: number,
  heightAbsolute?: number,
  heightRelative?: number
}
export default class Levels extends Dataset {

  siteIdField = FieldNames.SITE_ID; // used locally only (backward compatibility)
  siteNameField = FieldNames.SITE_NAME; // used locally only (backward compatibility)
  facilityNameField = FieldNames.FACILITY_NAME; // used locally only (backward compatibility)

  floorAwareInfo: IFloorAwareInfo;
  facilityIdField = FieldNames.FACILITY_ID;
  levelIdField = FieldNames.LEVEL_ID;
  levelNameField = FieldNames.NAME;
  levelNumberField = FieldNames.LEVEL_NUMBER;
  levelShortNameField = FieldNames.NAME_SHORT;
  verticalOrderField = FieldNames.VERTICAL_ORDER;

  requiresFacilityMode = true;
  type = "feature";

  loadPromise: Promise<void>;
  _data: {
    facilities: IFacilityData[],
    dataByFacility: Record<string, IFacilityData>,
    facilityIdsByName: Record<string, string>,
    levelsByLevelId: Record<string, ILevelData>,
    sitesById?: Record<string, any>,
  } = null;

  constructor(props?) {
    super(props)
    this.mixinProps(props);
  }

  checkSchema() {
    const promise = new Promise<void>((resolve,reject) => {
      const fi = this.floorAwareInfo;
      if (fi && fi.levelInfo) {
        if (fi.levelInfo.levelIdField) {
          this.levelIdField = fi.levelInfo.levelIdField;
        }
        if (fi.levelInfo.facilityIdField) {
          this.facilityIdField = fi.levelInfo.facilityIdField;
        }
        if (fi.levelInfo.longNameField) {
          this.levelNameField = fi.levelInfo.longNameField;
        }
        if (fi.levelInfo.shortNameField) {
          this.levelShortNameField = fi.levelInfo.shortNameField;
        }
        if (fi.levelInfo.levelNumberField) {
          this.levelNumberField = fi.levelInfo.levelNumberField;
        }
        if (fi.levelInfo.verticalOrderField) {
          this.verticalOrderField = fi.levelInfo.verticalOrderField;
        }
      }
      if (this.layer2D) {
        const layer = this.layer2D;
        layer.when(() => {
          this.checkFieldNameProperty("facilityIdField",layer.fields);
          this.checkFieldNameProperty("facilityNameField",layer.fields);
          this.checkFieldNameProperty("levelIdField",layer.fields);
          this.checkFieldNameProperty("levelNameField",layer.fields);
          this.checkFieldNameProperty("levelNumberField",layer.fields);
          this.checkFieldNameProperty("levelShortNameField",layer.fields);
          this.checkFieldNameProperty("siteIdField",layer.fields);
          this.checkFieldNameProperty("siteNameField",layer.fields);
          this.checkFieldNameProperty("verticalOrderField",layer.fields);
          //if (!this._data) this._queryAll();
          //if (!this._data) this.load();
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }
  async containsUnitsOrDetails(id: string) {
    const units = getUnitsLayer();
    const details = getDetailsLayer();
    if (!(units && details)) return Promise.resolve(false);
    const containsOthers = await Promise.all([units, details].map(layer => {
      const url = Context.checkMixedContent(`${layer.url.replace(/\/+$/g, "")}/${layer.layerId}`);
      const queryTask = new CustomQueryTask({ url });
      const query: __esri.Query = new Context.instance.lib.esri.Query();
      const field = aiimUtil.findFieldName(layer.fields, this.levelIdField);
      query.outFields = [field];
      query.returnGeometry = false;
      query.where = `(${field} = '${escSqlQuote(id)}')`;
      if (this.getVersionName())
        query.gdbVersion = this.getVersionName();
      return queryTask.executeForCount(query);
    }));
    return containsOthers.some(cnt => cnt > 0);
  }
  findSiteByPoint(point) {
    let site = null;
    let sitesById = (this._data && this._data.sitesById);
    if (sitesById) {
      Object.keys(sitesById).some(k => {
        let s = sitesById[k];
        let g = s.feature && s.feature.geometry;
        if (g && g.type === "polygon" && g.contains(point)) {
          site = s;
        }
        return !!site;
      });
    }
    return site;
  }

  fixZ(source,feature) {
    const geometry = feature && feature.geometry;
    if (geometry && geometry.type === "point" && !geometry.hasZ) {
      const zInfo = this.getZInfo(source,feature);
      if (zInfo && zInfo.levelData) {
        const z = zInfo.levelData.z;
        if (typeof z === "number") {
          geometry.z = z;
          geometry.hasZ = true;
        }
      }
    }
  }

  getFacilities(): IFacilityData[] {
    return this._data && this._data.facilities;
  }

  getFacilityData(facilityId: string): IFacilityData {
    if (typeof facilityId === "string" && facilityId.length > 0) {
      if (this._data && this._data.dataByFacility) {
        return this._data.dataByFacility[facilityId];
      }
    }
    return null;
  }

  getFirstCentroid(){
    let facilities = this._data && this._data.facilities;
    if(facilities && facilities.length > 0){
      let facilityID = this._data.facilities[0].facilityId;
      const facilitiesFootprints = Context.getInstance().aiim.facilityFootprints;
      if(facilitiesFootprints && facilitiesFootprints.graphicsByFacilityId){
        let centroid = facilitiesFootprints.graphicsByFacilityId[facilityID].geometry.centroid;
        return centroid;
      }
    }
  }

  getLevelData(levelId): ILevelData {
    const data = this._data;
    return data && data.levelsByLevelId[levelId];
  }
  getAllLevels(): ILevelData[] {
    const data = this._data;
    return data && Object.values(data.levelsByLevelId);
  }

  getShortNameIfLoaded(facilityId: string, levelNumber: number): string {
    if (this._data && this._data.dataByFacility) {
      const facilityData = this._data.dataByFacility[facilityId];
      if (facilityData && facilityData.shortNamesByLevelNumber) {
        const sn = facilityData.shortNamesByLevelNumber[levelNumber];
        //console.log("==sn==",sn);
        return sn;
      }
    }
    if (typeof levelNumber === "number") return ""+levelNumber;
    return "";
  }

  getZeroVOLevel(facilityData: IFacilityData) {
    if (!facilityData) return null;
    if (facilityData.zeroVOLevel) return facilityData.zeroVOLevel;
    facilityData.levels.some(level => {
      const baseVO = (facilityData.baseVO || 0);
      if (level.verticalOrder === baseVO) {
        if (typeof level.levelNumber === "number") {
          facilityData.zeroVOLevel = level;
        }
        return true;
      }
      return false;
    });
    return facilityData.zeroVOLevel;
  }

  getZeroVOLevelIds(excludeFacilityId,excludeLevelId) {
    const levelIds = [];
    const facilities = this.getFacilities();
    if (facilities) {
      facilities.forEach(f => {
        let zl = this.getZeroVOLevel(f);
        if (zl) {
          if (!excludeFacilityId || excludeFacilityId !== zl.facilityId) {
            if (!excludeLevelId || excludeLevelId !== zl.levelId) {
              if (levelIds.indexOf(zl.levelId) === -1) {
                levelIds.push(zl.levelId);
              }
            }
          }
        }
      });
    }
    return levelIds;
  }

  getZInfo(source,feature) {

    const chkStr = (v) => {
      return (typeof v === "string" && v.length > 0);
    };

    const getField = (name,defaultValue) => {
      if (source && source.mappings &&
        source.mappings.hasOwnProperty(name) && source.mappings[name]) {
        return source.mappings[name];
      }
      return defaultValue;
    };

    const data = this._data;
    const attributes = feature && feature.attributes;
    let facilityData, levelData;

    const lidField = Context.instance.aiim.getLevelIdField(source);
    if (lidField) {
      const levelId = aiimUtil.getAttributeValue(attributes,lidField.name);
      if (chkStr(levelId)) {
        levelData = data && data.levelsByLevelId[levelId];
      }
    }

    if (!levelData) {
      const facilityIdField = getField("facilityIdField",FieldNames.FACILITY_ID);
      const facilityNameField = getField("facilityNameField",FieldNames.FACILITY_NAME);
      const levelIdField = getField("levelIdField",FieldNames.LEVEL_ID);
      const levelNameField = getField("levelNameField",FieldNames.LEVEL_NAME);
      const levelNumberField = getField("levelNumberField",FieldNames.LEVEL_NUMBER);
      const verticalOrderField = getField("levelNumberField",FieldNames.VERTICAL_ORDER);

      if (!levelData && chkStr(levelIdField)) {
        const levelId = aiimUtil.getAttributeValue(attributes,levelIdField);
        if (chkStr(levelId)) {
          levelData = data && data.levelsByLevelId[levelId];
        }
      }

      if (!levelData && !facilityData && chkStr(facilityIdField)) {
        const facilityId = aiimUtil.getAttributeValue(attributes,facilityIdField);
        facilityData = this.getFacilityData(facilityId);
      }

      if (!levelData && !facilityData && chkStr(facilityNameField)) {
        const facilityName = aiimUtil.getAttributeValue(attributes,facilityNameField);
        if (chkStr(facilityName)) {
          const facilityId = data && data.facilityIdsByName[facilityName];
          facilityData = this.getFacilityData(facilityId);
        }
      }

      if (!levelData && facilityData && chkStr(levelNumberField)) {
        const levelNumber = aiimUtil.getAttributeValue(attributes,levelNumberField);
        levelData = facilityData.levelsByLevelNumber[levelNumber];
      }

      if (!levelData && facilityData && chkStr(levelNameField)) {
        const levelName = aiimUtil.getAttributeValue(attributes,levelNameField);
        levelData = facilityData.levelsByLevelName[levelName];
      }

      if (!levelData && facilityData && chkStr(verticalOrderField)) {
        const vo = aiimUtil.getAttributeValue(attributes,verticalOrderField);
        levelData = facilityData.levelsByVO[vo];
      }
    }

    if (levelData) {
      return {
        facilityId: levelData.facilityId,
        levelData: levelData
      }
    }
    return null;
  }

  hasData() {
    return !!this._data;
  }

  hasSitesById() {
    let sitesById = (this._data && this._data.sitesById);
    if (sitesById) return (Object.keys(sitesById).length > 0);
    return false;
  }

  load() {
    //console.log("Levels.load",this);
    if (this.floorAwareInfo) {
      this.loadPromise = this._loadFloorAware();;
      return this.loadPromise;
    } else {
      this.loadPromise = this._queryAll();
      return this.loadPromise;
    }
  }

  _loadFloorAware() {
    // for floor aware webmaps
    //console.log("Levels._loadFloorAware...")
    const promise = new Promise<void>((resolve,reject) => {
      const fi = this.floorAwareInfo;
      const datasets = Context.instance.aiim.datasets;
      const promises = [];

      const sitesById = {};
      if (datasets.sites && fi.siteInfo) {
        let dataset = datasets.sites;
        let fields = dataset.layer2D.fields;
        let idField = aiimUtil.findFieldName(fields,fi.siteInfo.siteIdField);
        let nameField = aiimUtil.findFieldName(fields,fi.siteInfo.nameField);
        if (!nameField) nameField = idField;
        if (idField) {
          promises.push(dataset.queryAll({
            perFeatureCallback: (f) => {
              let id = aiimUtil.getAttributeValue(f.attributes,idField);
              let name = aiimUtil.getAttributeValue(f.attributes,nameField);
              sitesById[id] = {
                id: id,
                name: name,
                feature: f
              }
              //console.log("sitesById[id] ",sitesById[id])
            }
          }));
        }
      }

      const facilitiesById = {};
      if (datasets.facilities) {
        let dataset = datasets.facilities;
        let fields = dataset.layer2D.fields;
        let idField = aiimUtil.findFieldName(fields,fi.facilityInfo.facilityIdField);
        let nameField = aiimUtil.findFieldName(fields,fi.facilityInfo.nameField);
        let sidField = aiimUtil.findFieldName(fields,fi.facilityInfo.siteIdField);
        if (!nameField) nameField = idField;
        if (idField) {
          promises.push(dataset.queryAll({
            perFeatureCallback: (f) => {
              let id = aiimUtil.getAttributeValue(f.attributes,idField);
              let name = aiimUtil.getAttributeValue(f.attributes,nameField);
              facilitiesById[id] = {
                id: id,
                name: name,
                siteId: aiimUtil.getAttributeValue(f.attributes,sidField),
                feature: f
              }
              //console.log("facilitiesById[id] ",facilitiesById[id])
            }
          }));
        }
      }

      const levelsById = {};
      if (true) {
        let dataset = datasets.levels;
        let fields = dataset.layer2D.fields;
        let idField = aiimUtil.findFieldName(fields,fi.levelInfo.levelIdField);
        let shortNameField = aiimUtil.findFieldName(fields,fi.levelInfo.shortNameField);
        let longNameField = aiimUtil.findFieldName(fields,fi.levelInfo.longNameField);
        let fidField = aiimUtil.findFieldName(fields,fi.levelInfo.facilityIdField);
        let lnumField = aiimUtil.findFieldName(fields,fi.levelInfo.levelNumberField);
        let voField = aiimUtil.findFieldName(fields,fi.levelInfo.verticalOrderField || "VERTICAL_ORDER");
        let heightRelField = aiimUtil.findFieldName(fields,FieldNames.HEIGHT_RELATIVE);
        if (idField) {
          promises.push(this.queryAll({
            perFeatureCallback: (f) => {
              let id = aiimUtil.getAttributeValue(f.attributes,idField);
              levelsById[id] = {
                id: id,
                name: aiimUtil.getAttributeValue(f.attributes,shortNameField),
                shortName: aiimUtil.getAttributeValue(f.attributes,shortNameField),
                longName: aiimUtil.getAttributeValue(f.attributes,longNameField),
                facilityId: aiimUtil.getAttributeValue(f.attributes,fidField),
                levelNumber: aiimUtil.getAttributeValue(f.attributes,lnumField),
                verticalOrder: aiimUtil.getAttributeValue(f.attributes,voField),
                heightRelative: aiimUtil.getAttributeValue(f.attributes,heightRelField),
                feature: f
              }
              //console.log("levelsById[id] ",levelsById[id])
            }
          }));
        }
      }

      Promise.all(promises).then(() => {
        Object.keys(facilitiesById).forEach(k => {
          let facility = facilitiesById[k];
          let site = sitesById[facility.siteId];
          if (site) {
            facility.siteName = site.name;
          }
        });
        Object.keys(levelsById).forEach(k => {
          let level = levelsById[k];
          let facility = facilitiesById[level.facilityId];
          if (facility) {
            level.facilityName = facility.name;
            level.siteId = facility.siteId;
            level.siteName = facility.siteName;
          }
        });
        //console.log("levelsById",levelsById)
        this._makeData(fi,sitesById,facilitiesById,levelsById);
        resolve();
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      })

    });
    return promise;
  }

  _makeData(fi,sitesById,facilitiesById,levelsById) {
    const dataByFacility: Record<string, IFacilityData> = {}, levelsByLevelId: Record<string, ILevelData> = {};
    const facilities: IFacilityData[] = [], facilityIdsByName: Record<string, string> = {};
    Object.keys(levelsById).forEach(k => {
      let level = levelsById[k];
      let facilityId = level.facilityId;
      let facilityName = level.facilityName;
      let levelName = level.longName;
      let levelNumber = level.levelNumber;
      let vo = level.verticalOrder;
      let feature = level.feature;
      let centroid = feature && feature.geometry && feature.geometry.centroid;
      let z = (centroid && typeof centroid.z === "number") ? centroid.z : 0;

      if (typeof facilityId === "string" && facilityId.length > 0) {
        let facilityData: IFacilityData;
        if (dataByFacility.hasOwnProperty(facilityId)) {
          facilityData = dataByFacility[facilityId];
        } else {
          facilityData = {
            siteId: level.siteId,
            siteName: level.siteName,
            facilityId: facilityId,
            facilityName: level.facilityName,
            levels: [],
            levelsByLevelName: {},
            levelsByLevelNumber: {},
            levelsByVO: {},
            shortNamesByLevelNumber: {},
            levelNumbersByVO: {},
            zeroVOLevel: null,
            baseVO: null
          };
          dataByFacility[facilityId] = facilityData;
          facilities.push(facilityData);

          // for testing long building names
          // if (facilityData.facilityName === "O") {
          //   facilityData.facilityName = "A very very very very very long building name name name name name";
          // }

        }
        const levelData: ILevelData = {
          siteId: level.siteId,
          siteName: level.siteName,
          facilityId: level.facilityId,
          facilityName: level.facilityName,
          levelId: level.id,
          levelName: levelName,
          levelNumber: level.levelNumber,
          levelShortName: level.shortName,
          levelLongName: level.longName,
          verticalOrder: vo,
          z: z,
          feature: level.feature,
          heightRelative: level.heightRelative,
        }
        facilityData.levels.push(levelData);
        facilityData.levelsByLevelName[levelName] = levelData;
        facilityData.levelsByLevelNumber[levelNumber] = levelData;
        facilityData.levelsByVO[vo] = levelData;
        facilityData.shortNamesByLevelNumber[levelNumber] = level.shortName;
        facilityData.levelNumbersByVO[vo] = levelNumber;
        if (!facilityIdsByName[facilityName]) {
          facilityIdsByName[facilityName] = facilityId;
        }
        levelsByLevelId[level.id] = levelData;

        if (typeof vo === "number" && isFinite(vo)) {
          const baseVO = facilityData.baseVO;
          if (vo >= 0) {
            if (baseVO === null || baseVO < 0 || vo < baseVO) {
              facilityData.baseVO = vo;
              facilityData.zeroVOLevel = levelData;
            }
          } else if (vo < 0) {
            if (baseVO === null || (baseVO < 0 && vo > baseVO)) {
              facilityData.baseVO = vo;
              facilityData.zeroVOLevel = levelData;
            }
          }
        }

      }

    });
    facilities.sort((a,b) => {
      if (a.facilityName && b.facilityName) {
        return a.facilityName.localeCompare(b.facilityName);
      }
      return 0;
    });
    const data = {
      sitesById: sitesById,
      facilities: facilities,
      dataByFacility: dataByFacility,
      facilityIdsByName: facilityIdsByName,
      levelsByLevelId: levelsByLevelId
    }
    this._data = data;
    console.log("========= Levels:_makeData",data);
  }

  queryAll(qaopts) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    qaopts = qaopts || {};
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts);
  }

  _queryAll() {
    // for non floor aware webmaps
    if (this._data) {
      return Promise.resolve(this._data);
    }
    const dataByFacility = {}, levelsByLevelId = {};
    const facilities = [], facilityIdsByName = {};
    const siteIdField = this.siteIdField;
    const siteNameField = this.siteNameField;
    const facilityIdField = this.facilityIdField;
    const facilityNameField = this.facilityNameField;
    const levelIdField = this.levelIdField;
    const levelNameField = this.levelNameField;
    const levelNumberField = this.levelNumberField;
    const levelShortNameField = this.levelShortNameField;
    const verticalOrderField = this.verticalOrderField;

    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.orderByFields = [verticalOrderField]
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();

    //console.log("Levels - loading all...");
    return task.execute(query).then((result) => {
      //console.log("Levels.result",result);
      if (result && result.features) {

        const elevationA = aiimUtil.findField(result.fields,"elevation_absolute");
        const elevationR = aiimUtil.findField(result.fields,"elevation_relative");
        const heightA = aiimUtil.findField(result.fields,"height_absolute");
        const heightR = aiimUtil.findField(result.fields,"height_relative");

        result.features.forEach((feature) => {
          if (feature && feature.attributes) {
            //console.log("======level=",feature.attributes);
            let attributes = feature.attributes;
            const siteId = aiimUtil.getAttributeValue(feature.attributes,siteIdField);
            const siteName = aiimUtil.getAttributeValue(feature.attributes,siteNameField);
            const facilityId = aiimUtil.getAttributeValue(feature.attributes,facilityIdField);
            const facilityName = aiimUtil.getAttributeValue(feature.attributes,facilityNameField);
            const levelName = aiimUtil.getAttributeValue(feature.attributes,levelNameField);
            const levelId = aiimUtil.getAttributeValue(feature.attributes,levelIdField);
            const levelNumber = aiimUtil.getAttributeValue(feature.attributes,levelNumberField);
            const sn = aiimUtil.getAttributeValue(feature.attributes,levelShortNameField);
            const vo = aiimUtil.getAttributeValue(feature.attributes,verticalOrderField);
            const centroid = feature.geometry && feature.geometry.centroid;
            const z = (centroid && typeof centroid.z === "number") ? centroid.z : 0;

            if (levelsByLevelId[levelId]) {
              console.warn("Levels: "+levelIdField+"="+levelId+" is not unique");
              //console.warn("levelData",levelData);
              //console.warn("levelsByLevelId[levelId]",levelsByLevelId[levelId]);
              return;
            }

            if (typeof facilityId === "string" && facilityId.length > 0) {
              let facilityData;
              if (dataByFacility.hasOwnProperty(facilityId)) {
                facilityData = dataByFacility[facilityId];
              } else {
                facilityData = {
                  siteId: siteId,
                  siteName: siteName,
                  facilityId: facilityId,
                  facilityName: facilityName,
                  levels: [],
                  levelsByLevelName: {},
                  levelsByLevelNumber: {},
                  levelsByVO: {},
                  shortNamesByLevelNumber: {},
                  levelNumbersByVO: {},
                  zeroVOLevel: null,
                  baseVO: null
                };
                dataByFacility[facilityId] = facilityData;
                facilities.push(facilityData);

                // for testing long building names
                // if (facilityData.facilityName === "O") {
                //   facilityData.facilityName = "A very very very very very long building name name name name name";
                // }

              }
              const levelData: ILevelData = {
                siteId: siteId,
                siteName: siteName,
                facilityId: facilityId,
                facilityName: facilityName,
                levelId: levelId,
                levelName: levelName,
                levelNumber: levelNumber,
                levelShortName: sn,
                verticalOrder: vo,
                z: z,
                feature,
                elevationAbsolute: elevationA && aiimUtil.getAttributeValue(attributes,elevationA.name),
                elevationRelative: elevationR && aiimUtil.getAttributeValue(attributes,elevationR.name),
                heightAbsolute: heightA && aiimUtil.getAttributeValue(attributes,heightA.name),
                heightRelative: heightR && aiimUtil.getAttributeValue(attributes,heightR.name)
              }
              facilityData.levels.push(levelData);
              facilityData.levelsByLevelName[levelName] = levelData;
              facilityData.levelsByLevelNumber[levelNumber] = levelData;
              facilityData.levelsByVO[vo] = levelData;
              facilityData.shortNamesByLevelNumber[levelNumber] = sn;
              facilityData.levelNumbersByVO[vo] = levelNumber;
              if (!facilityIdsByName[facilityName]) {
                facilityIdsByName[facilityName] = facilityId;
              }
              levelsByLevelId[levelId] = levelData;

              if (typeof vo === "number" && isFinite(vo)) {
                const baseVO = facilityData.baseVO;
                if (vo >= 0) {
                  if (baseVO === null || baseVO < 0 || vo < baseVO) {
                    facilityData.baseVO = vo;
                    facilityData.zeroVOLevel = levelData;
                  }
                } else if (vo < 0) {
                  if (baseVO === null || (baseVO < 0 && vo > baseVO)) {
                    facilityData.baseVO = vo;
                    facilityData.zeroVOLevel = levelData;
                  }
                }
              }

            }
          }
        });
      }
      facilities.sort((a,b) => {
        if (a.facilityName && b.facilityName) {
          return a.facilityName.localeCompare(b.facilityName);
        }
        return 0;
      });
      const data = {
        facilities: facilities,
        dataByFacility: dataByFacility,
        facilityIdsByName: facilityIdsByName,
        levelsByLevelId: levelsByLevelId
      }
      if (!this._data) this._data = data;
      //console.log("========= Levels:_queryAll",data);
      return data;
    }).catch(ex => {
      console.log("Error querying all levels:");
      console.error(ex);
      return Promise.reject(ex);
    });
  }

  async queryByGeometry(geometry,outSpatialReference?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = this.makeFloorAwareWhere();;
    query.geometry = geometry;
    if (outSpatialReference) query.outSpatialReference = outSpatialReference;
    if (this.layer2D && this.layer2D.gdbVersion) query.gdbVersion = this.layer2D.gdbVersion;
    return task.execute(query);
  }

  async queryLevelDataByPoint(point) {
  if (!this.url || !this.layer2D) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = false;
    query.returnZ = true;
    query.where = this.makeFloorAwareWhere();
    query.geometry = point;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    return task.execute(query).then(result => {
      let levelData;
      let features = result && result.features;
      let f = features && features.length === 1 && features[0];
      //console.log("f",f)
      if (f && this._data && this._data.levelsByLevelId) {
        let lid = aiimUtil.getAttributeValue(f.attributes,this.levelIdField);
        if (lid) levelData = this._data.levelsByLevelId[lid];
      }
      return levelData;
    });
  }

  async queryLevelDataByGeometry(geometry:__esri.Geometry, within?: boolean):Promise<ILevelData[]> {
    if (!this.url || !this.layer2D) 
      return null;
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = false;
    query.returnZ = true;
    query.where = this.makeFloorAwareWhere();
    query.geometry = geometry;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    if (within) query.spatialRelationship = "within";
    return task.execute(query).then((result:__esri.FeatureSet) => {
      const levelDataList:ILevelData[] = [], idx = {};
      const features = result && result.features;
      features && features.forEach(f => {
        const lid = aiimUtil.getAttributeValue(f.attributes,this.levelIdField);
        if (lid && !idx[lid]) {
          const levelData = this.getLevelData(lid);
          if (levelData) {
            levelDataList.push(levelData);
            idx[lid] = true;
          }
        }
      });
      return levelDataList;
    });
  }

  makeFloorAwareWhere():string {
    const floorInfo = this.layer2D.floorInfo || (this.layer2D.xtnSubLayer && this.layer2D.xtnSubLayer.floorInfo);
    const floorField = floorInfo.floorField || aiimUtil.findFieldName(this.layer2D.fields, FieldNames.LEVEL_ID);
    if (!floorField) {
      console.warn("Unable to get level/floor info, unable to find level_id field");
      return this.layer2D.definitionExpression || "1=1";
    }

    const view = Context.getInstance().views.activeView;
    const floors = view && (view as __esri.MapView | __esri.SceneView).floors;
    
    if (!floorField || !floors || floors.length === 0) {
      return this.layer2D.definitionExpression || "1=1";
    }
    const floorExp = `${floorField} IN (${floors.map(floor => `'${floor}'`).join(",")})`;
    const where = this.layer2D.definitionExpression
      ? `(${this.layer2D.definitionExpression}) AND ${floorExp}`
      : floorExp;
    return where;
  }

  getVersionName(): string {
    return this.layer2D?.gdbVersion;
  }
}