import React from "react";
import {connect} from "react-redux";

import Card from "./Card";
import SortBy from "../../../components/common/SortBy/SortBy";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Rdx from "../../../redux/Rdx";
import * as component from "../../util/component";
import * as val from "../../../util/val";

const CSS = {
  main: "i-itembrowser",
  searchbar: "i-searchbar",
  searchButton: "i-icon-button",
  sortBy: "i-sortby",
  items: "i-items",
  note: "i-note"
};

function queryItems(props) {
  const portal = Context.instance.getPortal();
  if (!portal) return Promise.resolve();

  const appendQ = (query,q) => {
    if (query.length > 0) query += " AND ";
    query += q;
    return query
  };

  let query = props.query || "";
  if (typeof props.itemType === "string" && props.itemType.length > 0) {
    query = appendQ(query,"(type: \"" + props.itemType + "\")");
  }
  if (typeof props.q === "string" && props.q.length > 0) {
    query = appendQ(query,"(" + props.q + ")");
  }

  if (props.orgOnly) {
    const user = Context.instance.user;
    let orgId = portal.id;
    if (user.portalUser) orgId = user.portalUser.orgId;
    if (typeof orgId === "string" && orgId.length > 0) {
      const qOrg = "((orgid:" + val.escapeForLucene(orgId) + ") -owner:esri_livingatlas)";
      query = appendQ(query,qOrg);
    }
  }

  const queryParams = {
    query: query,
    start: props.start,
    num: 50
  };
  if (props.sortField) {
    queryParams.sortField = props.sortField;
    if (props.sortOrder) queryParams.sortOrder = props.sortOrder;
  }
  return portal.queryItems(queryParams);
}

class ItemBrowser extends React.Component {

  orgOnly = true;

  _lastPromise;
  _mounted = false;
  _searchInput = null;
  _wasQueried = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: false,
      nextStart: -1,
      result: null,
      resultItems: null
    });
    this.query = this.query.bind(this);
    this.searchClicked = this.searchClicked.bind(this);
    this.sortByClicked = this.sortByClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    if (!this._wasQueried) {
      this.query({
        searchText: this.props.rdxSearchText,
        sortBy: this.props.rdxSortBy,
        sortDir: this.props.rdxSortDir
      });
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  moreClicked = (evt) => {
    this.searchClicked(evt,true);
  }

  query(options) {
    let prevItems, start = 1;
    if (options.isMore) {
      if (this.state.nextStart > 1) start = this.state.nextStart;
      prevItems = (this.state.resultItems || []).slice()
    }
    const props = {
      orgOnly: this.orgOnly,
      itemType: this.props.itemType,
      query: this.props.query,
      q: null,
      start: start
    };

    if (val.isNonEmptyStr(options.searchText)) {
      props.q = options.searchText;
    }
    if (options.sortBy) {
      if (options.sortBy === "relevance") {
        // TODO sort by date if no text?
      } else {
        props.sortField = options.sortBy;
        if (options.sortDir) {
          props.sortOrder = options.sortDir;
        }
      }
    }
    if (options.isMore && this.state.nextStart > 0) {
      props.start = this.state.nextStart;
    }

    let promise = this._lastPromise = queryItems(props);
    promise.then(result => {
      if (!this._mounted) return;
      if (promise !== this._lastPromise) return;
      //console.log("itemBrowserResult",result);
      let resultItems = ((result && result.results) || []).slice();
      if (options.isMore && start > 1 && Array.isArray(prevItems)) {
        resultItems = prevItems.concat(resultItems);
      }
      let nextStart = (result && result.nextQueryParams && result.nextQueryParams.start);
      if (typeof nextStart !== "number") nextStart = -1;
      this.setState(state => {
        return {
          isWorking: false,
          nextStart: nextStart,
          result: result,
          resultItems: resultItems
        };
      });
    }).catch(ex => {
      console.error("Error querying portal items",ex); // TODO??
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const searchbar = this.renderSearchBar();
    const items = this.renderItems();
    let more = null;
    if (this.state.nextStart > 0) {
      more = (
        <div key="more" className="i-text-center">
          <button type="button" className="i-button i-button-clear"
            onClick={this.moreClicked}>{i18n.portalItemBrowser.more}</button>
        </div>
      );
    }
    return (
      <div className={CSS.main}>
        {searchbar}
        {items}
        {more}
      </div>
    );
  }

  renderItems() {
    const i18n = Context.instance.i18n;
    let items = [];
    const results = (this.state.resultItems);
    if (results && results.length === 0) {
      items = (
        <span className={CSS.note}>{i18n.portalItemBrowser.noMatch}</span>
      );
    } else if (results && results.length > 0) {
      results.forEach(item => {
        items.push(
          <Card key={item.id} item={item} onSelect={this.props.onSelect}/>
        );
      });
    }
    return (
      <div key="items" className={CSS.items}>{items}</div>
    );
  }

  renderSearchBar() {
    const searchText = this.props.rdxSearchText || "";
    const onChange = (evt) => {
      this.searchClicked(evt);
    };
    const onKeyPress = (evt) => {
      const keyCode = (evt.which || evt.keyCode);
      if (keyCode === 13) this.searchClicked(evt);
    };
    const setInputNode = (node) => {
      this._searchInput = node;
    };
    const sortBy = this.renderSortBy();
    const i18n = Context.instance.i18n;
    return (
      <div key="searchbar" className={CSS.searchbar}>
      <div className="i-item-browser-search-input">
        <span className="i-item-browser-search-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M14.65 13.74L9.732 8.823A5.387 5.387 0 1 0 5.5 10.904a5.358 5.358 0 0 0 3.288-1.142l4.92 4.92zM5.5 9.25a3.75 3.75 0 1 1 3.75-3.751 3.752 3.752 0 0 1-3.75 3.75z"/></svg>
        </span>
        <input type="text" placeholder={i18n.search.allPlaceholder} ref={setInputNode} defaultValue={searchText}
          onChange={onChange} onKeyPress={onKeyPress} autoFocus={true}/>
      </div>
        {sortBy}
      </div>
    );
  }


  sortingAsc=()=>{
    if(this.props.rdxSortBy === "relevance") return;
    const sortDirKey = this.props.rdxKey+"__sortDir";
    Rdx.setValue(this,sortDirKey,"asc");
    this.query({
      searchText: this.props.rdxSearchText,
      sortBy: this.props.rdxSortBy,
      sortDir:"asc"
    });
  }

  sortingDesc=()=>{
    if(this.props.rdxSortBy === "relevance") return;
    const sortDirKey = this.props.rdxKey+"__sortDir";
    Rdx.setValue(this,sortDirKey,"desc");
    this.query({
      searchText: this.props.rdxSearchText,
      sortBy: this.props.rdxSortBy,
      sortDir:"desc"
    });
  }

  renderSortBy() {
    const current = this.props.rdxSortBy || "relevance";
    const i18n = Context.instance.i18n;
    const menuOptions = [];

    let buttonGroup = null;
    if (current !== "relevance") {
      let clsAsc, clsDesc;
      const currentDir = this.props.rdxSortDir;
      if (currentDir === "desc") clsDesc = "i--active";
      else clsAsc = "i--active";
      buttonGroup = (
        <div key="sorting" className="i-sorting-button">
          <span key="asc" title={i18n.portalItemBrowser.sortBy.ascTooltip}>
            <button className={clsAsc}
              onClick={this.sortingAsc}>{Icons.ascendingSort()}</button>
          </span>
          <span key="desc" title={i18n.portalItemBrowser.sortBy.descTooltip}>
            <button className={clsDesc}
              onClick={this.sortingDesc}>{Icons.descendingSort()}</button>
          </span>
        </div>
      );
    }

    const options = [
      {
        label: i18n.portalItemBrowser.sortBy.relevance,
        value: "relevance",
        canReverseSort: false,
        defaultSortDir: null,
        checked: true
      },
      {
        label: i18n.portalItemBrowser.sortBy.title,
        value: "title",
        canReverseSort: true,
        defaultSortDir: "asc",
        checked: false
      },
      {
        label: i18n.portalItemBrowser.sortBy.date,
        value: "modified",
        canReverseSort: true,
        defaultSortDir: "desc",
        checked: false
      }
    ];

    let optionData="";
    options.forEach(option => {
      let checked = (current === option.value);
      optionData = {
          key: option.value,
          label: option.label,
          value: option.value,
          canReverseSort: option.canReverseSort,
          defaultSortDir: option.defaultSortDir,
          checked: checked
      };

    });
    menuOptions.push(optionData);
    return (
        <SortBy
            caption= {i18n.explore.category.sortBy}
            options= {options}
            currentDir={this.props.rdxSortDir}
            buttonGroup= {buttonGroup}
            sortByClicked= {this.sortByClicked}
            sortBy= {current}
            sortingAsc= {this.sortingAsc}
            sortingDesc= {this.sortingDesc}
        >
      </SortBy>
   );
  }

  searchClicked(evt,isMore) {
    const searchTextKey = this.props.rdxKey + "__searchText";
    let searchText;
    if (this._searchInput) {
      searchText = this._searchInput.value;
      Rdx.setValue(this,searchTextKey,searchText);
    }
    this.query({
      searchText: searchText,
      sortBy: this.props.rdxSortBy,
      sortDir: this.props.rdxSortDir,
      isMore: isMore
    });
  }

  sortByClicked(menuOption) {
    const searchTextKey = this.props.rdxKey + "__searchText";
    const sortByKey = this.props.rdxKey+"__sortBy";
    const sortDirKey = this.props.rdxKey+"__sortDir";
    /* change relevance */
    const current = this.props.rdxSortBy;
    const currentDir = this.props.rdxSortDir;
    const sortBy = menuOption.value;
    let searchText;
    if (this._searchInput) searchText = this._searchInput.value;
    let sortDir = "asc";
    if (current && current === sortBy) {
      if (menuOption.canReverseSort) {
        if (currentDir === "asc") sortDir = "desc";
        Rdx.setValue(this,searchTextKey,searchText);
        Rdx.setValue(this,sortDirKey,sortDir);
        this.query({
          searchText: searchText,
          sortBy: sortBy,
          sortDir:sortDir
        });
      }
    } else {
      sortDir = menuOption.defaultSortDir;
      Rdx.setValue(this,searchTextKey,searchText);
      Rdx.setValue(this,sortByKey,sortBy);
      Rdx.setValue(this,sortDirKey,menuOption.defaultSortDir);
      this.query({
        searchText: searchText,
        sortBy: sortBy,
        sortDir:sortDir
      });
    }
  }

  // static showModal(props) {
  //   queryItems(props).then(result => {
  //     const controller = new ModalController();
  //     const onSelect = (item => {
  //       if (props.onSelect) props.onSelect(item,controller);
  //     });
  //     const content = (
  //       <ItemBrowser itemType={props.itemType} result={result}
  //         rdxKey={props.rdxKey} onSelect={onSelect} />
  //     );
  //     controller.show(content,props.title);
  //   }).catch(ex => {
  //     console.error("Error querying portal items",ex); // TODO??
  //   });
  // }

}

const mapStateToProps = (state,ownProps) => {
  const rdxKey = ownProps.rdxKey;
  const searchTextKey = rdxKey + "__searchText";
  const sortByKey = rdxKey + "__sortBy";
  const sortDirKey = rdxKey + "__sortDir";
  return {
    rdxSearchText: Rdx.getValue(state,searchTextKey),
    rdxSortBy: Rdx.getValue(state,sortByKey),
    rdxSortDir: Rdx.getValue(state,sortDirKey)
  }
}

export default connect(mapStateToProps)(ItemBrowser);
