export {default as ar} from 'antd/es/locale/ar_EG';
export {default as azAZ} from 'antd/es/locale/az_AZ';
export {default as bg} from 'antd/es/locale/bg_BG';
export {default as byBY} from 'antd/es/locale/by_BY';
export {default as ca} from 'antd/es/locale/ca_ES';
export {default as cs} from 'antd/es/locale/cs_CZ';
export {default as da} from 'antd/es/locale/da_DK';
export {default as de} from 'antd/es/locale/de_DE';
export {default as el} from 'antd/es/locale/el_GR';
export {default as enGB} from 'antd/es/locale/en_GB';
export {default as enUS} from 'antd/es/locale/en_US';
export {default as es} from 'antd/es/locale/es_ES';
export {default as et} from 'antd/es/locale/et_EE';
export {default as faIR} from 'antd/es/locale/fa_IR';
export {default as fi} from 'antd/es/locale/fi_FI';
export {default as frBE} from 'antd/es/locale/fr_BE';
export {default as frCA} from 'antd/es/locale/fr_CA';
export {default as fr} from 'antd/es/locale/fr_FR';
export {default as gaIE} from 'antd/es/locale/ga_IE';
export {default as glES} from 'antd/es/locale/gl_ES';
export {default as he} from 'antd/es/locale/he_IL';
export {default as hiIN} from 'antd/es/locale/hi_IN';
export {default as hr} from 'antd/es/locale/hr_HR';
export {default as hu} from 'antd/es/locale/hu_HU';
export {default as hyAM} from 'antd/es/locale/hy_AM';
export {default as id} from 'antd/es/locale/id_ID';
export {default as isIS} from 'antd/es/locale/is_IS';
export {default as it} from 'antd/es/locale/it_IT';
export {default as ja} from 'antd/es/locale/ja_JP';

export {default as kkKZ} from 'antd/es/locale/kk_KZ';
export {default as kmrIQ} from 'antd/es/locale/kmr_IQ';

export {default as knIN} from 'antd/es/locale/kn_IN';
export {default as ko} from 'antd/es/locale/ko_KR';
export {default as kuIQ} from 'antd/es/locale/ku_IQ';
export {default as lt} from 'antd/es/locale/lt_LT';
export {default as lv} from 'antd/es/locale/lv_LV';
export {default as mkMK} from 'antd/es/locale/mk_MK';

export {default as mnMN} from 'antd/es/locale/mn_MN';
export {default as msMY} from 'antd/es/locale/ms_MY';
export {default as nb} from 'antd/es/locale/nb_NO';
export {default as neNP} from 'antd/es/locale/ne_NP';
export {default as nlBE} from 'antd/es/locale/nl_BE';
export {default as nl} from 'antd/es/locale/nl_NL';
export {default as pl} from 'antd/es/locale/pl_PL';
export {default as ptBr} from 'antd/es/locale/pt_BR';
export {default as ptPT} from 'antd/es/locale/pt_PT';
export {default as ro} from 'antd/es/locale/ro_RO';
export {default as ru} from 'antd/es/locale/ru_RU';
export {default as sk} from 'antd/es/locale/sk_SK';
export {default as sl} from 'antd/es/locale/sl_SI';
export {default as sr} from 'antd/es/locale/sr_RS';
export {default as sv} from 'antd/es/locale/sv_SE';
export {default as taIN} from 'antd/es/locale/ta_IN';
export {default as th} from 'antd/es/locale/th_TH';
export {default as tr} from 'antd/es/locale/tr_TR';
export {default as uk} from 'antd/es/locale/uk_UA';
export {default as vi} from 'antd/es/locale/vi_VN';
export {default as zhCN} from 'antd/es/locale/zh_CN';
export {default as zhHK} from 'antd/es/locale/zh_HK';
export {default as zhTW} from 'antd/es/locale/zh_TW';

export function getLocale(o) {
  if (!o)
    return null; 
  let nm = String(o);
  nm = nm.toLowerCase();
  const nm2 = nm.replace("-","").replace("_","");
  for (let ln in this) {
    const cd = String(ln);
    ln = ln.toLowerCase();
    if (ln===nm)
      return this[cd];
    ln = ln.replace("-","").replace("_","");
    if (ln===nm) 
      return this[cd];    
    if (ln===nm2) 
      return this[cd];    
    if (ln.startsWith(nm))
      return this[cd]
    if (ln.startsWith(nm2))  
      return this[cd];
  }
  console.log("WARN: specified locale not found:", o);
  return null;
}