import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as whereUtil from "./whereUtil";

export function getDataset() {
  const dataset = Context.getInstance().aiim.datasets.events;
  if (dataset && dataset.url) return dataset;
  return null;
}

export function makeResultInfo(source,result) {
  const resultInfo = {
    exceededTransferLimit: false,
    featureItems: [],
    voField: null
  };
  if (result && result.fields) {
    resultInfo.voField = aiimUtil.findFieldName(result.fields,FieldNames.VERTICAL_ORDER);
  }
  if (result && result.features && result.features.length > 0) {
    const levels = Context.getInstance().aiim.datasets.levels;
    let oidField = result.objectIdField;
    if (!oidField) oidField = aiimUtil.findObjectIdField(result.fields);
    const featureSet = result.toJSON();
    resultInfo.exceededTransferLimit = result.exceededTransferLimit;
    const job = {}, dataset = getDataset();
    featureSet.features.forEach((feature,index) => {
      let key = index;
      if (oidField) {
        key = source.name + "_" + aiimUtil.getAttributeValue(feature.attributes,oidField);
      }
      if (levels) levels.fixZ(source,feature);
      const featureItem = {
        key: key,
        feature: feature,
        eventInfo: dataset.getEventInfo(job,feature)
      };
      resultInfo.featureItems.push(featureItem);
    });
  }
  return resultInfo;
}

export function makeWhere(criteria) {
  return whereUtil.makeWhere(criteria,getDataset());
}

export function queryFeatures(dataset,where,isMore,lastFeatureCount) {
  const lib = Context.getInstance().lib;
  const url = Context.checkMixedContent(dataset.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  const view = Context.getInstance().views.mapView;
  const orderByField = dataset.dateStartField;
  query.returnGeometry = true;
  query.returnZ = true;
  query.outFields = ["*"];
  query.where = where;
  if (orderByField) query.orderByFields = [orderByField];
  if (view) query.outSpatialReference = view.spatialReference;
  if (isMore) {
    query.start = lastFeatureCount;
    query.num = 10000;
  }
  return task.execute(query);
}

export function queryTypes(dataset,optionData) {
  //console.log("eventUtil::queryTypes",new Date());
  const promise = dataset.queryTypes();
  promise.then(results => {
    //console.log("eventUtil.queryTypes",results);
    if (results && results.length > 0) {
      results.forEach(name => {
        optionData.push({
          label: name,
          value: name,
        });
      });
    }
  });
  return promise;
}
