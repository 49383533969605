import React, { Component } from "react";
import {connect} from "react-redux";
import Rdx from "../../../redux/Rdx";
import { getActiveSection } from "../../redux";

//import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import MapSelectionModel from "./MapSelectionModel";
import OfficePlan from "../../base/OfficePlan";
import PeopleAssignTo from "../common/PeopleAssignTo";
import Topic from "../../../context/Topic";
import UnitsAssignTo from "../common/UnitsAssignTo";
import * as component from "../../../components/util/component";
import * as sourceUtil from "../../base/sourceUtil";
import * as highlightUtil from "../../base/highlightUtil";
import Skeleton from "react-loading-skeleton";

// calcite-react
import CalciteThemeProvider from "calcite-react/CalciteThemeProvider";
import { Theme } from "../../../theme";
import { ButtonGroup } from "calcite-react/Button";
import ClearIcon from "calcite-ui-icons-react/EraseIcon";
import NavIcon from "calcite-ui-icons-react/TwoDExploreIcon";
import PeopleIcon from "calcite-ui-icons-react/UsersIcon";
import PointIcon from "calcite-ui-icons-react/PointIcon";
import PolygonIcon from "calcite-ui-icons-react/PolygonIcon";
import RectangleIcon from "calcite-ui-icons-react/RectangleIcon";
import UnitsIcon from "calcite-ui-icons-react/FloorPlanIcon";

// styles
import { Wrapper, SelectionCount, Button } from "../../styles/mapStyles";

class MapSelection extends Component {
  mapSelectionModel;

  constructor(props) {
    super(props);
    this.state = component.newState({
      activeTool: "point",
      arePeopleActive: false,
      areUnitsActive: true,
      peopleFeatures: [],
      peapleFeatureItems: [],
      unitFeatures: [],
      unitFeatureItems: [],
      selectedTab: this.props.rdxActiveAppSection
    });

    this.mapSelectionModel = new MapSelectionModel({
      activeSourceKey: sourceUtil.getUnitsSourceKey(),
      view: this.props.view,
      getActiveFeatures: this.getActiveFeatures,
      onFeaturesSelected: this.onFeaturesSelected
    });
 }

  activateSketch = tool => {
    this.setState({
      activeTool: tool
    });
    if (tool === "nav") {
      this.deactivateSketch();
    } else {
      this.mapSelectionModel.activateSketch(tool);
    }
  };

  clearSelectionClicked = () => {
    this.mapSelectionModel.clearHighlight();
    if (this.state.arePeopleActive) {
      this.setState({
        peopleFeatures: [],
        peopleFeatureItems: []
      });
    } else if (this.state.areUnitsActive) {
      this.setState({
        unitFeatures: [],
        unitFeatureItems: []
      });
    }
  };

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.AppStarted, params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.ExpanderChanged, params => {
        if (!this.props.view || !this.props.view.map) return;
        if (params.type === "MapSelection") {
          const expanded = params.newVal;
          this.mapSelectionModel.expanded = expanded;
          this.mapSelectionModel.cancelSketch();
          if (expanded) {
            highlightUtil.removeHighlight();
            Context.instance.session.isMapSelectionActive = true;
            this.activateSketch(this.state.activeTool);
          } else {
            Context.instance.session.isMapSelectionActive = false;
            this.mapSelectionModel.clearHighlight();
            this.setState({
              peopleFeatures: [],
              peapleFeatureItems: [],
              unitFeatures: [],
              unitFeatureItems: []
            });
          }
        }
      }),
      Topic.subscribe(Topic.FacilityModeUpdated, params=>{
        this.mapSelectionModel.rehighlightPeople();
      }),
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          // if (this.state.arePeopleActive) {
          //   this.clearSelectionClicked();
          // }
          this.clearSelectionClicked();
        }
      }),

      Topic.subscribe(Topic.PlanOpened, params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    //console.log("MapSelection.componentWillUnmount......................")
    component.componentWillUnmount(this);
  }

  deactivateSketch = () => {
    this.mapSelectionModel.cancelSketch();
  };

  getActiveFeatures = source => {
    if (!source) return [];
    if (source.isAiimPeople()) {
      if (this.state.arePeopleActive) {
        return this.state.peopleFeatures;
      }
    } else if (source.isAiimUnits()) {
      if (this.state.areUnitsActive) {
        return this.state.unitFeatures;
      }
    }
  };

  onFeaturesSelected = (source, features, featureItems) => {
    if (!source) return;
    if (source.isAiimPeople()) {
      if (this.state.arePeopleActive) {
        this.setState({
          peopleFeatures: features,
          peopleFeatureItems: featureItems
        });
      }
    } else if (source.isAiimUnits()) {
      if (this.state.areUnitsActive) {
        this.setState({
          unitFeatures: features,
          unitFeatureItems: featureItems
        });
      }
    }
  };

  peopleClicked = () => {
    if (this.state.arePeopleActive) return;
    const sourceKey = sourceUtil.getPeopleSourceKey();
    this.mapSelectionModel.setActiveSourceKey(sourceKey);
    this.mapSelectionModel.clearHighlight();
    this.setState({
      arePeopleActive: true,
      areUnitsActive: false,
      unitFeatures: [],
      unitFeatureItems: []
    });
  };

  isLoaded = () => {
    const planner = Context.instance.spaceplanner.planner;
    if (
      this.props.view &&
      this.props.view.map &&
      planner &&
      planner.hasValidPlan()
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { activeTool, arePeopleActive, areUnitsActive } = this.state;
    const i18n = Context.instance.i18n;

    try {
      if(this.props.view && this.props.view.ui && this.props.view.ui._components){
        this.props.view.ui._components.some(c => {
          if (c.node && c.widget && c.widget.xtnIsMapSelector) {
            if(this.props.rdxActiveAppSection === "review" || this.props.rdxActiveAppSection === "publish"){
              if(c.widget.expanded) c.widget.toggle();
              c.node.style.display = "none";
            }
            else c.node.style.display = "block";
            return true;
          }
          return false;
        });
      }
    } catch(ex) {
      console.error(ex)
    }

    return this.isLoaded() ? (
      <CalciteThemeProvider theme={Theme}>
        <Wrapper>
          <ButtonGroup isToggle style={{ marginInlineEnd: "0.775rem" }}>
            <Button
              key="nav"
              clear={activeTool !== "nav"}
              title={i18n.spaceplanner.mapSelection.nav}
              onClick={() => this.activateSketch("nav")}
              icon={<NavIcon size={16} style={{ margin: 0 }} />}
            ></Button>
          </ButtonGroup>

          <ButtonGroup isToggle style={{ marginInlineEnd: "0.775rem" }}>
            <Button
              key="point"
              clear={activeTool !== "point"}
              title={i18n.spaceplanner.mapSelection.byPoint}
              onClick={() => this.activateSketch("point")}
              icon={<PointIcon size={16} style={{ margin: 0 }} />}
            ></Button>

            <Button
              key="rectangle"
              clear={activeTool !== "rectangle"}
              title={i18n.spaceplanner.mapSelection.byRectangle}
              onClick={() => this.activateSketch("rectangle")}
              icon={<RectangleIcon size={16} style={{ margin: 0 }} />}
            ></Button>

            <Button
              key="polygon"
              clear={activeTool !== "polygon"}
              title={i18n.spaceplanner.mapSelection.byPolygon}
              onClick={() => this.activateSketch("polygon")}
              icon={<PolygonIcon size={16} style={{ margin: 0 }} />}
            ></Button>
          </ButtonGroup>

          <ButtonGroup isToggle style={{ marginInlineEnd: "0.775rem" }}>
            <Button
              key="people"
              clear={!arePeopleActive}
              title={i18n.spaceplanner.assets.people.title}
              onClick={() => this.peopleClicked()}
              icon={<PeopleIcon size={16} style={{ margin: 0 }} />}
            ></Button>

            <Button
              key="units"
              clear={!areUnitsActive}
              title={i18n.spaceplanner.assets.units.title}
              onClick={() => this.unitsClicked()}
              icon={<UnitsIcon size={16} style={{ margin: 0 }} />}
            ></Button>
          </ButtonGroup>

          {this.renderSelectionCount()}

          <Button
            key="clear"
            style={{ marginInlineEnd: "0.775rem" }}
            iconButton={true}
            icon={<ClearIcon />}
            title={i18n.spaceplanner.mapSelection.clearSelection}
            onClick={() => this.clearSelectionClicked()}
          ></Button>

          {this.renderAssignTo()}
        </Wrapper>
      </CalciteThemeProvider>
    ) : (
      <Wrapper>
        <Skeleton height={48} width={300} />
      </Wrapper>
    );
  }

  renderAssignTo = () => {
    const peopleOK = OfficePlan.getActive().isPeopleLayerValid();
    const unitsOK = OfficePlan.getActive().isUnitsLayerValid();
    if (!peopleOK || !unitsOK) return null;
    let assignTo, featureItems;
    const arePeopleActive = this.state.arePeopleActive;
    const areUnitsActive = this.state.areUnitsActive;
    if (arePeopleActive) {
      featureItems = this.state.peopleFeatureItems;
      assignTo = <PeopleAssignTo featureItems={featureItems} noMargin noWrap />;
    } else if (areUnitsActive) {
      featureItems = this.state.unitFeatureItems;
      assignTo = <UnitsAssignTo featureItems={featureItems} noMargin noWrap />;
    }
    return assignTo;
  };

  renderSelectionCount = () => {
    const peopleOK = OfficePlan.getActive().isPeopleLayerValid();
    const unitsOK = OfficePlan.getActive().isUnitsLayerValid();
    let n, features, pattern, text;
    const i18n = Context.instance.i18n;
    const arePeopleActive = this.state.arePeopleActive;
    const areUnitsActive = this.state.areUnitsActive;
    if (arePeopleActive) {
      if (!peopleOK) {
        text = i18n.spaceplanner.noValidPeopleLayer;
      } else if (!unitsOK) {
        text = i18n.spaceplanner.noValidUnitsLayer;
      } else {
        features = this.state.peopleFeatures;
        n = features ? features.length : 0;
        if (n === 1) {
          pattern = i18n.spaceplanner.assets.people.selectedPatternSingular;
        } else {
          pattern = i18n.spaceplanner.assets.people.selectedPattern;
        }
        text = pattern.replace("{count}", n);
      }
    } else if (areUnitsActive) {
      if (!unitsOK) {
        text = i18n.spaceplanner.noValidUnitsLayer;
      } else if (!peopleOK) {
        text = i18n.spaceplanner.noValidPeopleLayer;
      } else {
        features = this.state.unitFeatures;
        n = features ? features.length : 0;
        if (n === 1) {
          pattern = i18n.spaceplanner.assets.units.selectedPatternSingular;
        } else {
          pattern = i18n.spaceplanner.assets.units.selectedPattern;
        }
        text = pattern.replace("{count}", n);
      }
    }
    if (text) {
      return <SelectionCount key="selectionCount">{text}</SelectionCount>;
    }
  };

  unitsClicked = () => {
    if (this.state.areUnitsActive) return;
    const sourceKey = sourceUtil.getUnitsSourceKey();
    this.mapSelectionModel.setActiveSourceKey(sourceKey);
    this.mapSelectionModel.clearHighlight();
    this.setState({
      arePeopleActive: false,
      areUnitsActive: true,
      peopleFeatures: [],
      peopleFeatureItems: []
    });
  };
}

const mapStateToProps = (state, ownProps) => ({
  rdxActiveAppSection: getActiveSection(state),
  rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
})

export default connect(mapStateToProps)(MapSelection);
