import React from "react";
import Context from "../../../context/Context";

export default class IdlePopup extends React.Component{

    intervalID=null;
    constructor(props){
      super();
      const applicationReset = Context.instance.config.applicationReset;
      this.state={
        countDownTime:applicationReset.popupMillis/1000
      }
    }
  
    componentDidMount(){
      // CountDown on the popup before the screen reloads due to inacitvity
      const applicationReset = Context.instance.config.applicationReset;
      const timeoutInMillisecondsPopup=applicationReset.popupMillis;
      let startTimeMS=(new Date()).getTime();
      this.intervalID=setInterval(()=>{
        let elapsedTime=new Date().getTime() - startTimeMS ;
        let remainingTime=timeoutInMillisecondsPopup-elapsedTime;
        let remainingTimeRounded=Math.ceil(remainingTime/1000) *1000;
        let temp=remainingTimeRounded/1000;
        if(temp<0){
          clearInterval(this.intervalID);
          temp=0;
        }
        this.setState({
          countDownTime:temp
        })
      },1000);
    }
  
    componentWillUnmount(){
      clearInterval(this.intervalID);
    }
  
    render(){
      const i18n=Context.getInstance().i18n;
      let message=this.props.popupContent;
      message=message.replace("{remainingTime}",this.state.countDownTime )
      return(
        <div>
            <h2> {this.props.popupHeading}</h2>
            <span>{message}</span>
            <br></br>
            <button className="i-button i-button-fill i-idle-timeout i-margin-top-1" onClick={this.props.resetPopupTimer}>{i18n.resetKiosk.promptButton}</button>
        </div>
      );
    }
}