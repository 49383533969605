import React from "react";
import {connect} from "react-redux";
import Context from "../../../context/Context";
import DateFilter from "./DateFilter";
import EventItems from "./EventItems";
import SidebarHeader from "../Sidebar/SidebarHeader";
import Rdx from "../../../redux/Rdx";
import TimeFilter from "./TimeFilter";
import Topic from "../../../context/Topic";
import TypeFilter from "./TypeFilter";
import * as component from "../../util/component";
import * as eventUtil from "./eventUtil";

const CSS = {
  main: "i-events",
  optionsContainer: "i-events-options-container",
  options: "i-events-options",
  scrollEvents: "i-scroll-events"
};

class Events extends React.Component {

  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      where: this.makeWhere()
    });
    this.optionClicked = this.optionClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    component.own(this,[

      Topic.subscribe(Topic.ReloadViews,params => {
        try {
          this.setState(state => {
            return {
              abandon: true
            };
          });
          // Rdx.setValue(this,Rdx.Keys.EVENTS_DATEFILTER_OPTION,"today");
          // Rdx.setValue(this,Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED,false);
          // Rdx.setValue(this,Rdx.Keys.EVENTS_TIMEFILTER_OPTION,null);
          // Rdx.setValue(this,Rdx.Keys.EVENTS_TYPEFILTER_OPTION,null);
          // Rdx.setValue(this,Rdx.Keys.EVENTS_TYPEFILTER_OPTIONS,null);
        } catch(ex) {
          console.error(ex);
        }
      })

    ]);
  }

  componentWillUnmount() {
    //console.log("Events::componentWillUnmount");
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  getCriteria() {
    const criteria = {
      dateOption: this.props.rdxDateOption,
      dateSelected: this.props.rdxDateSelected,
      timeOption: this.props.rdxTimeOption,
      typeOption: this.props.rdxTypeOption,
    }
    return criteria;
  }

  optionClicked(type, menuOption, dateSelected) {
    const criteria = this.getCriteria();
    // redux hasn't updated yet, need to manually add the new criteria
    const value = menuOption.props.value;
    if (type === "DateFilter") {
      criteria.dateOption = value;
      if (value === "pickdate") {
        criteria.dateSelected = dateSelected;
      }
    } else if (type === "TimeFilter") {
      criteria.timeOption = value;
    } else if (type === "TypeFilter") {
      criteria.typeOption = value;
    }
    const where = eventUtil.makeWhere(criteria);
    if (!this._mounted) return;
    Topic.publish(Topic.EventsFilterSet,{where: where});
  }

  makeWhere() {
    return eventUtil.makeWhere(this.getCriteria());
  }

  render() {
    if (this.state.abandon) return null;
    const dataset = eventUtil.getDataset();
    const source = dataset.getSource();
    if (!dataset || !source) return null;
    return (
      <div>
        {this.renderHeader()}
        <div className={CSS.main}>
          <div className={CSS.scrollEvents}>
            {this.renderContent(source)}
          </div>
        </div>
      </div>
    );
  }

  renderContent(source) {
    const options = this.renderOptions();
    return (
      <>
      {options}
      <EventItems sourceKey={source.key} where={this.state.where}/>
      </>
    );
  }

  renderHeader() {
    const i18n = Context.getInstance().i18n;
    return <SidebarHeader caption={i18n.events.caption}
      closeAriaLabel={i18n.events.closeAriaLabel}
      sidebarKey={this.props.sidebarKey}/>
  }

  renderOptions() {
    const uiMode = Context.getInstance().uiMode;
    let className = CSS.options;
    if (uiMode.isIOS && uiMode.isTouch) {
      className += " i--short";
    }
    return (
      <div className={CSS.optionsContainer}>
        <div key="datetime" className={className}>
          <DateFilter onClick={this.optionClicked} />
          <TimeFilter onClick={this.optionClicked} />
        </div>
        <div key="type" className={className}>
          <TypeFilter onClick={this.optionClicked} />
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    rdxDateOption: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED),
    rdxTimeOption: Rdx.getValue(state,Rdx.Keys.EVENTS_TIMEFILTER_OPTION),
    rdxTypeOption: Rdx.getValue(state,Rdx.Keys.EVENTS_TYPEFILTER_OPTION)
  }
}

export default connect(mapStateToProps)(Events);
