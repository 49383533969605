import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";

// 3rd party
import Loader from 'calcite-react/Loader';
import Tooltip from "calcite-react/Tooltip";
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';
import XIcon from 'calcite-ui-icons-react/XIcon';
import PencilIcon from "calcite-ui-icons-react/PencilIcon";
import WarningIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import RecurrenceIcon from 'calcite-ui-icons-react/RecurrenceIcon';
import BookingCancel from '../More/Actions/BookWorkspace/BookingCancel';

// aiim.base
import ItemReference from '../../../aiim/base/ItemReference';

// aiim.datasets
import FieldNames from '../../../aiim/datasets/FieldNames';

// aiim.util
import { findFieldName, getAttributeValue, getRecurrencePattern } from '../../../aiim/util/aiimUtil';

// common.components.Modal
import { ModalController } from '../../../common/components/Modal';

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import { getMeetingBookingSystem, getMeetingBookingSystemType } from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

// components.util
import * as component from '../../util/component';
import * as workspaceReservationUtil from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';
import * as dateUtil from '../Events/dateUtil';
import Office365, { IIndoorsEvent } from '../More/Actions/BookWorkspace/WorkspaceReservation/Office365';

// context
import Context from '../../../context/Context';
import Topic from '../../../context/Topic';

// util
import { isNetworkError } from '../../../util/networkUtil';
import { stringFormatter } from '../../../util/formatUtil';
import * as validateUtil from "../More/Actions/BookWorkspace/validateUtil";
import Schedule from "./Schedule";
import Book from './Book';
import { ICommonBookingProps } from '../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem';
import { IMeetingRoomCriteria } from './MeetingRoomsModel';
import { IRecurrenceOptions } from '../More/Actions/BookWorkspace/BookingRecurrence';
import RecurrenceDropdown, { RecurrenceType } from '../More/Actions/BookWorkspace/RecurrenceDropdown';
import Icons from '../../util/Icons';
import { IBookingsProps } from '../More/Actions/BookWorkspace/MyBookings';
import { BookingType } from '../../../util/calendarUtil';
import { IBookingDateFilter } from '../Events/BookingDateFilter';

const CSS = {
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  bookingInfoLine: "i-booking-info-line",
  locationDescription: "i-booking-location-description",
  flexRow: "i-flex-row",
  addToCalendar: "i-booking-add-calendar",
  cancel: "i-booking-cancel",
  edit: "i-booking-edit",
  popoutInner: "i-popout-inner i-meeting-cancel-confirmed",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  cancelConfirmed: "i-meeting-cancel-confirmed-body",
  meetingTitle: "i-meeting-cancel-confirmed--title",
  actionGroup: "i-action-group",
  bookingDescription: "i-booking-description",
  recurrenceIcon: "i-recurrence-icon"
};
interface IMeetingRooms365State {
  bookings: IIndoorsEvent[],
  isWorking: boolean,
  units: __esri.Graphic[]
}
export default class MyMeetingRoomsOffice365 extends React.Component<IBookingsProps, IMeetingRooms365State> {

  private _mounted: boolean = false;

  constructor(props: IBookingsProps) {
    super(props);

    this.state = component.newState({
      bookings: [],
      isWorking: true,
      units: []
    });
  }

  override componentDidMount() {
    this._mounted = true;
    this._init();
    Topic.subscribe(Topic.RenderMeetingListOffice365, ()=> {
      this._mounted && this.setState({ isWorking: true });
      this._init();
    })
  }
  
  override componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingDateFilter !== this.props.bookingDateFilter) {
      this.setState({isWorking: true});
      this._init();
    }
  }

  override componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  _getBookings = (): Promise<{ value: IIndoorsEvent[] }> => {
    let currentDateTime = moment(Date.now()).add(1,"milliseconds").toISOString();
    const params = {
      currentDateTime: currentDateTime,
      bookingType: "meetingRooms" as ICommonBookingProps["bookingType"],
      bookingDateFilter: this.props.bookingDateFilter
    }
    const bookingSystem = getMeetingBookingSystem() as Office365;
    return bookingSystem.getBookings(params).catch(e => {
      console.error(e);
      throw e;
    });
  }

  _getUserBookings = async () => {
    return this._getBookings().then(scheduleResult => {
      const bookings: IIndoorsEvent[] = scheduleResult?.value || [];
      const ids = new Set<string>();
      bookings.forEach((item) => ids.add(item.extensions[0]?.unitId));
      const unitIds = [...ids].filter(x => !!x);
      if (unitIds.length > 0) {
        workspaceReservationUtil.queryUnitByUnitId(unitIds).then(fs => {
          this._mounted && this.setState({
            bookings,
            isWorking: false,
            units: fs?.features || []
          });
        });
      } else {
        this._mounted && this.setState({
          bookings: [],
          isWorking: false,
          units: []
        });
      }
    }).catch(e => {
      console.error("Unable to query for user reservations", e);
      this._mounted && this.setState({
        bookings: [],
        isWorking: false,
        units: []
      });
    
      if (e && isNetworkError(e.message)) {
        const i18n = Context.getInstance().i18n;
        Topic.publishNetworkError(i18n.meetingRooms.issues.m7);
      } else {
        Topic.publishErrorAccessingData();
      }
      throw e;
    });
  }

  _init =async()=> {
    return await this._getUserBookings();
  }

  _makeBookingList = (bookings: IIndoorsEvent[]) => {
    const { units } = this.state;
    const dataset = Context.getInstance().aiim.datasets?.units;
    const layer = dataset?.layer2D;
    const unitIdField = findFieldName(layer?.fields, FieldNames.UNIT_ID);
    return bookings.map((booking) => {
      const unit = units.find(u => getAttributeValue(u.attributes, unitIdField) === booking.extensions[0]?.unitId);
      return (
        <MyMeetingRoomsCard
          key={booking.id}
          booking={booking}
          refresh={this._refreshList}
          bookingDateFilter={this.props.bookingDateFilter}
          unitFeature={unit}
        />
      );
    });
  }

  renderBookingCards() {
    const i18n = Context.getInstance().i18n;
    const { bookings } = this.state;
    const bookingList = this._makeBookingList(bookings);

    return bookingList && bookingList.length > 0 ? (
      <ul className={CSS.bookingCardList}>
        {bookingList}
      </ul>
    ) : (
      <div style={{ padding: '0.7rem 1rem' }}>{i18n.meetingRooms.noBookings}</div>
    );
  }
  
  _refreshList = () => {
    this.setState({ isWorking: true });
    this._getUserBookings();
  }

  renderWorking() {
    return (<Loader sizeRatio={1} style={{ marginTop: '3rem' }} />);
  }

  render() {
    const { isWorking } = this.state;
    return isWorking ? this.renderWorking() : this.renderBookingCards();
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
interface IMyMeetingRoomsCard365Props extends IBookingsProps {
  booking: IIndoorsEvent,
  refresh: () => void,
  unitFeature: __esri.Graphic
}
interface IMyMeetingRoomsCard365State {
  isLocationChange: boolean,
  popoverOpen: boolean,
  popoverType?: "cancel" | "edit"
}
class MyMeetingRoomsCard extends React.Component<IMyMeetingRoomsCard365Props, IMyMeetingRoomsCard365State> {
  private _componentId: string;
  private _itemRef;
  private _isOngoingBooking: boolean;

  constructor(props: IMyMeetingRoomsCard365Props) {
    super(props);
    this._componentId = component.nextId("meeting-room-");
    this._itemRef = React.createRef();
    this._isOngoingBooking = this.isOngoingBooking();
    const isLocationChange = this._isLocationChange();
    this.state = component.newState({
      popoverOpen: false,
      popoverType: null,
      isLocationChange: isLocationChange
    })
  }

  _isLocationChange =()=> {
    const booking = this.props && this.props.booking && this.props.booking;
    const extensions = booking && booking.extensions && booking.extensions[0];
    const unitId = extensions && extensions.unitId;
    const unc = Context.instance.session.unitNameCache;
    if (unc && unc.defaultNamesPerUnitId && unitId) {
      const origUnitName = unc.defaultNamesPerUnitId[unitId];
      if (origUnitName !== this.displayName) return true
      return false;
    }
  }

  isOngoingBooking =() => {
    // const booking = this.props.data;
    // const o365toDateObj = dateUtil.O365toDate(booking.start.dateTime, booking.end.dateTime);
    // const dtStart = o365toDateObj.fromDate;
    // const epochStartTime = new Date(dtStart).getTime();
    // const epochCurrentTime = workspaceReservationUtil.getEpochCurrentTime();
    // if (epochCurrentTime > epochStartTime) return true;
    return false;
  }
  _makeFeatureItem =async()=> {
    const source = Context.instance.aiim.datasets.units.getSource();
    const searchResult = source.makeSearchResult(null, this.props.unitFeature);
    const item = new ItemReference();
    item.fromSearchResult(source.key, { feature: this.props.unitFeature, name:  searchResult.name});
    return item;
  }

  _statusClicked = async (status) => {
    if (status === "declined") {
      const i18n = Context.instance.i18n;
      const options = {
        title: i18n.meetingRooms.book.declineBookingO365Title,
        message: i18n.meetingRooms.book.declineBookingO365Msg,
        okLabel: i18n.general.ok,
        showOkCancel: true,
        hideCancel : true,
        closeOnOK: true
      };
      ModalController.confirm(options);
      return;
    } else if (status === "mismatchEmail") {
      const booking = this.props && this.props.booking;
      const unitName = this.displayName;
      const scheduleEmail = booking && booking.extensions && booking.extensions[0] && booking.extensions[0].scheduleEmail;
      let attendeeEmails = [], attendeeEmailsStr;
      for (let i=0;i<booking.attendees.length;i++) {
        const emailAddress = booking.attendees[i].emailAddress.address;
        attendeeEmails.push(emailAddress);
        attendeeEmailsStr = attendeeEmails.join()
      }      
      const i18n = Context.instance.i18n;
      let msg = i18n.meetingRooms.emailMismatch.message;
      msg = msg.replace("{scheduleEmail}", scheduleEmail);
      msg = msg.replace("{unitName}", unitName);
      msg = msg.replace("{attendeeEmails}", attendeeEmailsStr)

      ModalController.showError({
        title: i18n.general.information,
        className: "i-portrait-access-hotel-error",
        errors: {
          generic: i18n.meetingRooms.emailMismatch.caption,
          detailed: [msg]
        }
      });
    }
  }

  _bookingCardClicked = async () => {
    const isLocationChange = this.state.isLocationChange;
    // const declined = Office365.wasDeclined( this.props.booking);
    if (isLocationChange) {
      const i18n = Context.instance.i18n;
      const options = {
        title: (isLocationChange ? i18n.meetingRooms.locationChange.caption : i18n.meetingRooms.book.declineBookingO365Title),
        message: (isLocationChange ? i18n.meetingRooms.locationChange.message : i18n.meetingRooms.book.declineBookingO365Msg),
        okLabel: i18n.general.ok,
        showOkCancel: true,
        hideCancel : true,
        closeOnOK: true
      };
      ModalController.confirm(options);
      return;
    }

    const source = Context.instance.aiim.datasets.units.getSource();
    const item = await this._makeFeatureItem();
    
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      componentId: this._componentId
    });
  }

  _cancelClicked = (type?: RecurrenceType) => {
    this._showCancelPopup(false, type);
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus();
        }
      })
    ]);
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus();
    }
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;
    if (event.keyCode !== ENTER && event.keyCode !== SPACE) {
      return;
    }

    // Prevent propagation from children keyboard events
    const dataId = event.target.getAttribute("data-id");
    if (dataId === "card") {
      event.preventDefault();
      this._bookingCardClicked();
    }
  }

  processScheduleClick = true;

  private _scheduleClicked = async (type?: RecurrenceType) => {
    const { booking } = this.props;
    const utc = booking.start.timeZone?.toLowerCase() === "utc";
    const toLocal = utc ? moment.utc : moment;
    // use start/end time of booking that was clicked
    const start = toLocal(booking.start.dateTime);
    const end = toLocal(booking.end.dateTime);
    const bookingDateFilter: IBookingDateFilter = {
      dateStart: start.toDate(),
      dateEnd: end.toDate()
    };
    if (type === "series") {
      const info = await workspaceReservationUtil.getReScheduleInfo(booking, "meetingRooms", bookingDateFilter);
      const selectedWhen = info && info.when;
      if (selectedWhen && booking.masterEvent) {
        // switch to start/end time of series
        const seriesStart = toLocal(booking.masterEvent.start.dateTime);
        const seriesEnd = toLocal(booking.masterEvent.end.dateTime);
        selectedWhen.start = { date: seriesStart.toDate() };
        selectedWhen.end = { date: seriesEnd.toDate() };
        selectedWhen.isOngoingBooking = info.isOngoingBooking;
        selectedWhen.originalReservation = { booking };
        selectedWhen.series = info.series;
      }
      this._rescheduleBooking(selectedWhen, type);
    } else {
      if (!this.processScheduleClick) return;
      try {
        this.processScheduleClick = false;
        const i18n = Context.getInstance().i18n;
        const info = await workspaceReservationUtil.getReScheduleInfo(this.props.booking, "meetingRooms", bookingDateFilter);
        if (!info) {
          Topic.publish(Topic.RenderMeetingListOffice365, {});
          Topic.publish(Topic.ShowToast, {
            message: i18n.more.bookWorkspace.checkInOut.cancelBooking.bookingNotAvailable
          });
          return;
        }
        const controller = new ModalController();
        const title = i18n.meetingRooms.schedule.captionPattern.replace("{room}", info.item.name);
        const reservationO365 = this.props.booking; //obj from O365 graphAPI 
        //example: { id, subject, isAllDay, start: { dateTime:'2022-10-22T21:00:00.0000000', timeZone:'UTC'}, end: {}, location: {displayName,locationType,uniqueId,uniqueIdType}, extenstions:[{}, {}, ...]}

        const modalProps = {
          className: "i-modal-confirm i-modal-schedule",
          title: title,
          cancelLabel: i18n.general.close,
          showOKCancel: true,
          hideOK: true
        };

        const content = (
          <Schedule
            item={info.item}
            start={info.start}
            when={info.when}
            bookingType="meetingRooms"
            operation={info.operation}
            series={info.series}
            isOngoingBooking={this._isOngoingBooking}
            originalReservation={{ booking: reservationO365, attributes: null }}
            recurrenceType={type == null ? "single" : type}
            onShowOccupant={() => controller.close()}
            onBookClicked={(selectedWhen) => {
              this._rescheduleBooking({ ...selectedWhen, series: info.series }, type == null ? "single" : type);
              controller.close();
            }} />
        )
        controller.show(modalProps, content);
      } catch (ex) {
        console.error(ex)
      } finally {
        setTimeout(() => {
          this.processScheduleClick = true;
        }, 200)
      }
    }
  }

  _showCancelPopup = (retry: boolean, type?: RecurrenceType) => {
    const i18n = Context.getInstance().i18n;
    const booking = this.props.booking;
    const eventId = booking && booking.id;
    const seriesMasterId = booking && booking.seriesMasterId;
    const isRecurring = booking.recurrence ? true : false;
    const unitName = this.displayName;
    let msg = i18n.meetingRooms.cancel.message;
    if (unitName) {
      msg = i18n.meetingRooms.cancel.messagePattern.replace("{unitName}",unitName);
    }
    if (isRecurring) {
      msg = type === "occurrence" ? i18n.meetingRooms.cancel.messageOccurrence : i18n.meetingRooms.cancel.messageSeries;
      if (unitName) {
        msg = type === "occurrence" ? i18n.meetingRooms.cancel.messagePatternOccurrence : i18n.meetingRooms.cancel.messagePatternSeries;
        msg = msg.replace("{unitName}", unitName);
      }
    }
    const message = retry ? i18n.meetingRooms.cancel.retry : msg;

    let okButton= null;
    const options = {
      title: i18n.meetingRooms.cancel.modalTitle,
      message,
      okLabel: i18n.general.yes,
      cancelLabel: i18n.more.bookWorkspace.no,
      showOKCancel: true,
      closeOnOK: false,
      closeOnCancel: true,
      onMountOKButton: btn => {
        okButton = btn;
      }
    };

    ModalController.confirm(options)
      .then((result) => {
        // User chose to continue canceling the meeting, proceed
        if (result && result.ok) {
          if (okButton) okButton.disable();
          let currentDateTime = moment(Date.now()).add(1, "milliseconds").toISOString();
          const params = {
            currentDateTime: currentDateTime,
            bookingType: "meetingRooms" as ICommonBookingProps["bookingType"],
            bookingDateFilter: this.props.bookingDateFilter
          }
          const bookingSystem = getMeetingBookingSystem() as Office365;
          return bookingSystem.getBookings(params).then((res) => {
            const value = res && res.value;
            let isBooking = false;
            for (let i = 0; i < value.length; i++) {
              if (value[i].id === eventId) isBooking = true;
            }

            if (!isBooking) {
              Topic.publish(Topic.ShowToast, {
                message: i18n.more.bookWorkspace.checkInOut.cancelBooking.bookingNotAvailable
              });
              Topic.publish(Topic.RenderMeetingListOffice365, {});
              return "bookingNotAvailable";;
            } else {
              const params = {
                eventId: type === "series" ? seriesMasterId : eventId
              }
              return bookingSystem.cancelBooking(params);
            }
          }).then((info) => {
            if (result.controller) result.controller.close();
            if (info === "bookingNotAvailable") {
              return;
            }
            if (typeof this.props.refresh === "function") {
              this.props.refresh();
            }
            this._renderCancelConfirmed();
          }).catch(e => {
            if (result.controller) result.controller.close();
            console.error("Couldn't cancel meeting", e);
            // test this
            if (e && isNetworkError(e.message)) {
              const i18n = Context.getInstance().i18n;
              const template = i18n.meetingRooms.issues.m9;
              const message = stringFormatter(template, { unit: unitName });
              Topic.publishNetworkError(message);
            } else {
              Topic.publishErrorUpdatingData();
            }

            // Give the user an option to try again
            this._showCancelPopup(true);
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }
  
  _renderCancelConfirmed() {
    const unitName = this.displayName;
    
    const node = document.createElement("div");
    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node);
      }
    };

    document.body.appendChild(node);
    ReactDOM.render(
      <BookingCancel
        bookingSystemType={getMeetingBookingSystemType()}
        bookingType="single"
        unitName={unitName}        
        closePopup={onClose}
        data={this.props.booking}
        />, node);
  }

  _togglePopover = (type?: IMyMeetingRoomsCard365State["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }
  get displayName() {
    const { booking } = this.props;
    return workspaceReservationUtil.getDisplayName(booking);
  }

  private _getRecurrenceIcon(
    mStartTime: Date,
    mEndTime: Date,
    isAllDay: boolean,
    recurrence: IRecurrenceOptions,
    bookingType: IIndoorsEvent["type"]
  ) {

    if (!recurrence) return null;

    const tooltip = workspaceReservationUtil.formatRecurringDates(recurrence,
      moment(mStartTime), moment(mEndTime), isAllDay);
    // add check for modified occurrence here
    return bookingType === "exception"
      ? <span className={CSS.recurrenceIcon}>{Icons.noRecurrence()}</span>
      : <span className={CSS.recurrenceIcon} title={tooltip}><RecurrenceIcon size={16}/></span>
  }

  get recurrence() {
    const { booking } = this.props;
    return getRecurrencePattern(booking);
  }

  _rescheduleBooking =async(options, type?: BookingType)=> {
    const item = await this._makeFeatureItem();
    const feature = item.getFeature();
    const { booking } = this.props;
    const eventId = type === "series" ? booking?.seriesMasterId : booking?.id;
    const o365toDateObj = dateUtil.O365toDate(booking.start.dateTime, booking.end.dateTime);
    const title = booking && booking.subject;
    const body = booking && booking.body;
    const recurrence = this.recurrence;
    if (recurrence && type === "occurrence") {
      recurrence.modified = "occurrence";
    }

    const bookItem = {
      unitId: getAttributeValue(feature.attributes,FieldNames.UNIT_ID),
      name: getAttributeValue(feature.attributes,FieldNames.NAME),
      feature: feature,
      origBooking: o365toDateObj,
      title: title,
      body: body
    };

    let duration = options && options.duration;
    let dtStart = options && options.start.date;
    let dtEnd = options && options.end.date;
    const originalReservation = options && options.originalReservation;

    let criteria: IMeetingRoomCriteria = {
      when: {
        duration: duration,
        start: {date: dtStart},
        end: {date: dtEnd},
        recurrence: recurrence
      },
      eventId: eventId,
      isOngoingBooking: this._isOngoingBooking,
      operation: "updateBookingTime",
      series: options.series
    };
    Book.show({ bookItem, criteria, originalReservation, recurrenceType: type == null ? "single" : type });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { booking } = this.props;
    const { popoverOpen, popoverType } = this.state;
    const levels = Context.getInstance().aiim.datasets.levels;
    const isLocationChange = this.state.isLocationChange;
    const declined = Office365.wasDeclined(booking);
    const scheduleEmail = booking && booking.extensions && booking.extensions[0] && booking.extensions[0].scheduleEmail;
    const statusObj = Office365.getEventStatus(booking, scheduleEmail);
    const status = statusObj.status;
    const statusDetails = validateUtil.getStatusIconMsg(statusObj); 
    const statusIcon = statusDetails.icon;
    const statusMsg = statusDetails.msg;
    let borderStyle = statusDetails.borderStyle;

    // Field values to display
    const checkInDate = booking && booking.start;
    const checkOutDate = booking && booking.end;

    const o365toDateObj = dateUtil.O365toDate(checkInDate.dateTime, checkOutDate.dateTime);
    const fromDateTime = o365toDateObj.fromDateTime;
    const toDateTime = o365toDateObj.toDateTime;

    const subject = booking && booking.subject;
    const unitName = this.displayName;
    const levelId = booking && booking.extensions && booking.extensions[0].levelId;

    // Level/Facility info
    const levelData = levels.getLevelData(levelId); 
    const levelName = levelData && levelData.levelName;
    const facilityName = levelData && levelData.facilityName;
    const template = i18n.more.bookWorkspace.levelDescription;
    const description = !isLocationChange && stringFormatter(template, {
      level: levelName,
      facility: facilityName
    });
    // let description = `${levelName}, ${facilityName}`;
    // if (this.state.isLocationChange) description = unitName;

    // Format a date string from the start and end times
    const fromDate = o365toDateObj.fromDate;
    const toDate = o365toDateObj.toDate;
    const isAllDay = dateUtil.isAllDay(fromDate.getTime(), toDate.getTime());
    const formattedDate = workspaceReservationUtil.formatDate(fromDateTime, toDateTime, isAllDay);
    const recurrence = getRecurrencePattern(booking);
    const recurrenceIcon = this._getRecurrenceIcon(fromDate, toDate, isAllDay, recurrence, booking?.type);
    const isRecurring = recurrence ? true : false;
    const isRTL = Context.getInstance().uiMode.isRtl;
    const textAlign = isRTL ? "right" : "left";

    let statusTitle;
    if (isLocationChange) statusTitle = i18n.meetingRooms.locationChange.message;
    else if (declined) statusTitle = i18n.meetingRooms.book.declineBookingO365Msg;
    else if (status && status === "mismatchEmail") statusTitle = i18n.meetingRooms.emailMismatch.caption;

    if (isLocationChange ||(status && status === "mismatchEmail")) borderStyle = {borderLeft: "1px #dfdfdf solid"}

    const cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={(event) => {
          // Prevent the click event from propagating to the card
          event.stopPropagation();
          isRecurring ? this._togglePopover("cancel") : this._cancelClicked();
        }}>
        <XIcon />
        {i18n.general.cancel}
      </button>
    );
  
    const rescheduleButton = (
      <button
        className={CSS.edit}
        data-id={"edit"}
        onClick={(event) => {
          // Prevent the click event from propagating to the card
          event.stopPropagation();
          isRecurring ? this._togglePopover("edit") : this._scheduleClicked();
        }}
        disabled={isLocationChange ||(status && status === "mismatchEmail")}>
        <PencilIcon/>
        {i18n.general.edit}
      </button>
    )

    return (
      <li>
        <div className={CSS.bookingCard} style={borderStyle}>
          <div
            id={this._componentId}
            className={CSS.bookingDescription}
            ref={this._itemRef}
            role="button"
            tabIndex={0}
            data-id={"card"}
            onClick={this._bookingCardClicked}
            onKeyDown={this._onKeyDown}
          >
            <h3 className={CSS.bookingInfoLine}>
              <span style={{ flexGrow: 1 }}>{subject}</span>
              {isRecurring && recurrenceIcon}
            </h3>
            <div className={CSS.bookingInfoLine}>
              <ClockIcon />
              <h4>{formattedDate}</h4>
            </div>
            {!isLocationChange && ( 
              <>
                <h4 className={CSS.bookingInfoLine}>
                  <PinIcon />
                  {unitName}
                </h4>
                <h4 className={CSS.locationDescription} style={{paddingLeft: "1.4rem", paddingRight: "1.4rem"}}>{description}</h4>
              </>
            )}
            {isLocationChange && ( 
              <Tooltip style={{textAlign: textAlign}} placement="top" positionFixed={true}
              title={statusTitle}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    this._bookingCardClicked()
                  }}
                >
                <h4 className="i-booking-info-line i--warn-location-change">
                  <PinIcon />
                  {unitName}
                  <span><WarningIcon className="i--warn" size={16} /></span>
                </h4>
                <h4 className={CSS.locationDescription+" i--warn-location-change"}>{description}</h4>
                </div>
              </Tooltip>
            )}
            {!isLocationChange && !statusTitle && (
                <div className={CSS.bookingInfoLine}>
                  <h4>{statusIcon}</h4>
                  <h4>{statusMsg}</h4>
                </div>
              )
            }

            {!isLocationChange && statusTitle && (
                <Tooltip style={{textAlign: textAlign}} placement="top" positionFixed={true}
                title={statusTitle}>
                  <div
                    role="button"
                  tabIndex={0}
                    onClick={(e) => {
                      e.stopPropagation();
                      this._statusClicked(status)
                    }}
                  >
                    <div className={CSS.bookingInfoLine}>
                      <h4>{statusIcon}</h4>
                      <h4>{statusMsg}</h4>
                    </div>
                  </div>
                </Tooltip>
              )
            }
          </div>
          <div className={CSS.actionGroup}>            
              <RecurrenceDropdown
                type="edit"
                open={popoverOpen && popoverType === "edit"}
                targetEl={rescheduleButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._scheduleClicked(type)
                  this._togglePopover();
                }}
              />
              <RecurrenceDropdown
                type="cancel"
                open={popoverOpen && popoverType === "cancel"}
                targetEl={cancelButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._cancelClicked(type);
                  this._togglePopover();
                }} />
          </div>
        </div>
      </li>
    );
  }
}
