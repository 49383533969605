import FieldNames from "../../aiim/datasets/FieldNames";
import Stat from "./Stat";
import * as queryUtil from "./queryUtil";
import * as aiimUtil from "../../aiim/util/aiimUtil";

export default class StatUnitsUnassigned extends Stat {

  query() {
    const source = this.getSource();
    if (!source || !source.layer2D) return;
    let assignmentFieldName = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;

    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields, assignmentFieldName);
      if (f) assignmentFieldName= f.name;
    }

    const wh = assignmentFieldName+"='none'";
    const options = {
      source: source,
      where: wh,
    }
    queryUtil.queryCount(options).then(result => {
      if (typeof result === "number") {
        this.value = result;
        //console.log("Units",this.value)
        this.broadcastRefreshed();
      }
    }).catch(ex => {
      console.error("Error querying stat",this.key,ex);
    })
  }

}
