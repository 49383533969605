import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";

import CalciteButton from "calcite-react/Button";
import CalciteSelect from "calcite-react/Select";

const StyledButton = styled(CalciteButton)`
  ${props =>
    props.gapRight &&
    css`
      margin-right: ${props => unitCalc(props.theme.baseline, 2, "/")};
    `};
`;

const ReviewersButton = styled(CalciteButton)`
  width: 50% !important;
  margin: 5px ${props => unitCalc(props.theme.baseline, 3, "/")} !important;
`
const ReviewersSelect = styled(CalciteSelect)`
  margin: 5px ${props => unitCalc(props.theme.baseline, 3, "/")};
  max-width: 13rem;
  min-width: 4rem;
`

const BookButtonDiv = styled.div`
  position: relative;
  height: 20%;
  position: relative;
`;

const BookButton = styled(CalciteButton)`

    border-radius: 2px;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    &:focus {
      outline: 2px auto -webkit-focus-ring-color;
      outline: 2px solid -moz-mac-focusring;
    }

    ${props => props.clear &&
        css `
        color: ${props.theme.palette.themeColorViewer};
        border-color: ${props.theme.palette.themeColorViewer};
        
        &:hover {
          background: ${props.theme.palette.white};
          color: ${props.theme.palette.themeColorViewer};
          border-color: #005e95;
        }
        `
    }
`;

const ShowAllButton = styled(CalciteButton)`
  ${props =>
      props.showAll &&
      css `
        margin-top: 0.3rem;
      `};
`;

export { StyledButton, BookButton, BookButtonDiv, ShowAllButton, ReviewersButton, ReviewersSelect };
