import React from "react";

import Configurator from "../Configurator/Configurator";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import ItemBrowser from "../../common/ItemBrowser/ItemBrowser";
import Layout from "./Layout";
import Rdx from "../../../redux/Rdx";
import Starter from "../../../context/Starter";
import Topic from "../../../context/Topic";
import ModalController from "../../common/Modal/ModalController";
import IPhone from "./IPhone";
import * as component from "../../util/component";

const CSS = {
  startupError: "i-startup-error i-padded"
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      cfgOnUrl: false,
      noAppIdOrWebMap: false,
      reloadingViews: false,
      showNativeAppOption: false,
      startupError: null,
      wasStarted: false
    });
  }

  browseForApp(forEdit) {
    const i18n = Context.instance.i18n;
    const typeKeyword = Context.instance.configuration.getIndoorsAppTypeKeyword();
    const controller = new ModalController();
    const props = {
      title: forEdit ? i18n.getStarted.configureApp : i18n.getStarted.openApp,
      itemType: "Web Mapping Application",
      query: "(typekeywords: \"" + typeKeyword + "\")",
      rdxKey: "cfg-appsection",
      onSelect: (item) => {
        const lib = Context.instance.lib;
        const urlObj = lib.esri.urlUtils.urlToObject(window.location.href);
        let href = urlObj.path;
        if (href.indexOf("#") > 0) href = href.substring(0,href.indexOf("#"));
        href += "?appid=" + encodeURIComponent(item.id);
        if (forEdit) {
          href += "&edit=true";
        } else {
          let regex = new RegExp('[\\?&]openWebApp=([^&#]*)');
          let results = regex.exec(window.location.search);
          let v = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
          if (v === "true") {
            href += "&openWebApp=true";
          }
        }
        window.location.href = href;
        //controller.close();
      }
    }
    const content = (
      <ItemBrowser itemType={props.itemType} query={props.query}
        rdxKey={props.rdxKey} onSelect={props.onSelect} />
    );
    controller.show(content,props.title);
  }

  componentDidMount() {
    this._startupApp();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  considerNativeApp() {
    if (window.location.pathname.toLowerCase().indexOf("/kiosk.html") !== -1) return false;

    // copy url from Safari to chrome, # is encoded on url, issue #1912
    let href = window.location.href;
    let idx = href.indexOf("%23");
    if (idx > 0) {
      href = href.substring(0,idx);
      window.history.replaceState({},"",href);
    }

    let regex = new RegExp('[\\?&]openWebApp=([^&#]*)');
    let results = regex.exec(window.location.search);
    let v = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    if (v === "true") return false;
    regex = new RegExp('[\\?&]edit=([^&#]*)');
    results = regex.exec(window.location.search);
    v = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    if (v === "true") return false;
    let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
    let isAndroid = /Android/i.test(navigator.userAgent)
    return isIOS || isAndroid;
  }

  createAppClicked = (evt) => {
    let href = window.location.href;
    if (href.indexOf("#") > 0) href = href.substring(0,href.indexOf("#"));
    if (href.indexOf("?") === -1) href += "?";
    else href += "&";
    href += "edit=true";
    window.location.href = href;
  }

  _handleStartupError(error) {
    try {
      if (error === "__noIndoorsSubscription__" ||
          error === "__notAnIndoorsApp__") {
        this.setState({startupError: error});
      } else {
        let msg = (error && error.message) || "";

        let noAppIdOrWebMap = false;
        let cfgOnUrl = false;
        if (error && error.message === "No webmap was specified") {
          msg = Context.instance.i18n.messages.noWebmapSpecified;
          const lib = Context.instance.lib;
          const url = lib.esri.urlUtils.urlToObject(window.location.href);
          const hasAppId = (url && url.query && url.query.appid);
          const hasCfg = (url && url.query &&
            (url.query.configure === "true" || url.query.edit === "true"));

          if (!hasAppId) {
            noAppIdOrWebMap = true;
            cfgOnUrl = hasCfg;
          }

          if (hasCfg) {
            Context.instance.uiMode.toggleConfigurator(true);
            this.setState(state => {
              return {
                cfgOnUrl: cfgOnUrl,
                startupError: null,
                wasStarted: true
              };
            });
            return;
          }
        } else {
          console.error("Startup error:",error);
        }

        this.setState(state => {
          return {
            startupError: msg,
            noAppIdOrWebMap: noAppIdOrWebMap,
            cfgOnUrl: cfgOnUrl
          };
        });

      }
    } catch(ex2) {
      console.error("Startup error2:",ex2);
    }
  }

  _listen() {
    component.own(this,[
      Topic.subscribe(Topic.ConfiguratorActivated,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ConfiguratorClosed,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ReloadViews,params => {
        //console.log("Reloaded views....");
        setTimeout(() => {
          this._reloadViews(params);
        },100)
      })
    ]);
  }

  openAppClicked = () => {
    this.browseForApp();
  }

  openAppForEditClicked = () => {
    this.browseForApp(true);
  }

  _reloadViews(params) {
    this.setState(state => {
      return {
        reloadingViews: true,
        startupError: null
      };
    });
    if (this.props.store) {
      Rdx.resetAll(this.props.store);
    }
    document.body.classList.remove("i-infopanel-open","i-sidebar-panel-open");
    const starter = new Starter();
    starter.reloadViews(params).then(result => {
      //console.log("Views reloaded...");
      this.setState(state => {
        return {
          wasStarted: true,
          startupError: null,
          reloadingViews: false,
          noAppIdOrWebMap: !Context.instance.views.activeView
        };
      });
    }).catch(error => {
      const i18n = Context.instance.i18n;
      console.error("Error reloading views:",error);
      this.setState(state => {
        return {
          wasStarted: true,
          startupError: null,
          reloadingViews: false,
          noAppIdOrWebMap: !Context.instance.views.activeView
        };
      });
      ModalController.showMessage(i18n.messages.errorAccessingData);
    });
  }

  render() {

    if (this.state.showNativeAppOption){
      document.body.classList.remove("i-skeleton-on");
      document.getElementById("skeleton").style.display = "none";
      document.getElementById("view-container").style.zIndex = "-1";
      document.body.style.backgroundColor = "#0079c1";
      return <IPhone />
    }

    const hasStartupError = (typeof this.state.startupError === "string");
    if (!this.state.wasStarted && !hasStartupError) {
      return null;
    }

    const context = Context.getInstance();
    const i18n = context.i18n;
    const isConfiguratorOn = context.uiMode.isConfiguratorOn;

    if (!isConfiguratorOn && hasStartupError && this.state.noAppIdOrWebMap &&
        !this.state.cfgOnUrl) {
      return this.renderGetStarted();
    }

    let errorNode = null;
    if (hasStartupError) {
      let msg = this.state.startupError;
      if (msg === "__noIndoorsSubscription__") {
        msg = i18n.messages.noIndoorsMapsSubscription;
        if (Context.instance.noIndoorsSubscriptionMsg) {
          msg = Context.instance.noIndoorsSubscriptionMsg;
        }
        return (<div className={CSS.startupError}>{msg}</div>);
      } else if (msg === "__notAnIndoorsApp__") {
        msg = i18n.messages.notAnIndoorsApp;
        return (<div className={CSS.startupError}>{msg}</div>);
      } else {
        errorNode = (
          <div className={CSS.startupError}>
            <div>{i18n.messages.errorStartingApp}</div>
            <div>{msg}</div>
          </div>);
        if (!isConfiguratorOn) return errorNode;
      }
    }

    let configurator;
    if (isConfiguratorOn) {
      configurator = (<Configurator />);
    }

    let layout;
    if (!this.state.reloadingViews && !errorNode) {
      layout = (<Layout />);
    }

    return (
      <div className="i-app">
        {configurator}
        {errorNode}
        {layout}
      </div>
    );
  }

  renderGetStarted() {
    Context.instance.uiMode.hideSkeleton();
    document.getElementById("view-container").style.zIndex = "-1";
    const i18n = Context.instance.i18n;
    const isKiosk = Context.instance.uiMode.isKiosk;
    let showCreate = true;
    if (!Context.instance.user.isAnonymous()) {
      showCreate = Context.instance.user.canCreateAppItem();
    }

    const logoInfo = Context.instance.configuration._configurables.logo;
    const logoStyle = {backgroundImage: "url(\"" + logoInfo.url + "\")"};
    let title = (
      <div key="title" className={"i--title"}>
        <span key="logo" className={"i--logo"} style={logoStyle}></span>
        <span key="name" className={"i--logoname"}>{logoInfo.name}</span>
      </div>
    );

    let openApp = (
      <button type="button" className={"i-button"} autoFocus={true}
        onClick={this.openAppClicked}>{i18n.getStarted.openApp}</button>
    );

    let configureApp = null;
    if (showCreate && !isKiosk) {
      configureApp = (
        <button type="button" className={"i-button i-button-clear"}
          onClick={this.openAppForEditClicked}>{i18n.getStarted.configureApp}</button>
      );
    }

    let createApp = null;
    if (showCreate && !isKiosk) {
      createApp = (
        <button type="button" className={"i-button  i-button-clear"}
          onClick={this.createAppClicked}>{i18n.getStarted.createApp}</button>
      );
    }

    let signin = null, username = null, signout = null;
    if (Context.instance.user.isAnonymous()) {
      signin = (
        <button type="button" className={"i-button-clear-no-border"}
          onClick={() => {
            Topic.publish(Topic.SignInClicked,{});
          }}
          >{i18n.user.signin}</button>
      );
    } else {
      username = (
        <div>
        <span className="i--user-icon" key="icon">{Icons.user()}</span>
        <span className="i--username" key="username">{Context.instance.user.getUsername()}</span>
        </div>
      );
      signout = (
        <button type="button" className={"i-button-clear-no-border"}
          onClick ={() =>{
            Topic.publish(Topic.SignOutClicked,{});
          }}
          >{i18n.user.signout}</button>
      );
    }
    let header = (
      <div key="header" className={"i--header"}>
        {username}
        {username ? signout : signin}
      </div>
    )

    return (
      <div className="i-app">
        <div className="i-app-getstarted">
          {header}
          <div key="body" className={"i--body"}>
            {title}
            <div key="actions" className={"i--actions"}>
              {openApp}
              {configureApp}
              {createApp}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _startupApp() {
    const considerNativeApp = this.considerNativeApp();
    const starter = new Starter();
    starter.considerNativeApp = considerNativeApp;
    starter.startupApp().then(result => {
      console.log("App started, starting UI...");
      starter.displayToast();
      let config = Context.instance.config;
      if (considerNativeApp && config.nativeApp && config.nativeApp.showIOS) {
        this.setState({
          wasStarted: true,
          showNativeAppOption: true,
          startupError: null
        });
      } else {
        this._listen();
        if (Context.instance.session.startInConfiguratorMode) {
          Context.instance.uiMode.toggleConfigurator(true);
        }
        this.setState({
          wasStarted: true,
          startupError: null
        });
        this.watchKioskActivity();
      }
    }).catch(error => {
      this._listen();
      if (Context.instance.session.startInConfiguratorMode) {
        Context.getInstance().uiMode.toggleConfigurator(true);
      }
      this._handleStartupError(error);
    });
  }

  // Watch Kiosk for inactivity
  watchKioskActivity() {
    //console.log("Watch Kiosk Activity");
    if (!Context.instance.uiMode.isKiosk) return;
    const kioskReset = Context.instance.config.kiosk.kioskReset;
    if(kioskReset.enabled){
     let timeoutIdScreen, timeoutIdPopup, controller;
     const timeoutInMilliseconds = kioskReset.timeoutMillis;
     const timeoutInMillisecondsPopup = kioskReset.popupMillis;

    // Start screen timer to check for inactivity on the screen
    const startScreenTimer=()=>{
      clearTimeout(timeoutIdScreen);
      if(!controller){
        timeoutIdScreen = window.setTimeout(doInactive, timeoutInMilliseconds);
      }
    }

    const resetScreenTimer=()=>{
      window.clearTimeout(timeoutIdScreen);
      startScreenTimer();
    }

    const resetPopupTimer=()=>{
      if(controller){
        controller.close();
        controller=null;
      }
      window.clearTimeout(timeoutIdPopup);
      window.clearTimeout(timeoutIdScreen);
      startScreenTimer();
    }

    // Set the popup timer when inactivity is detected
    const setPopupTimer=()=>{
      clearTimeout(timeoutIdPopup);
      timeoutIdPopup = window.setTimeout(doInactive, timeoutInMillisecondsPopup);
    }

    const doInactive=()=>{
      if(controller){
        controller.close();
        controller=null;
        window.location.reload(true);
        resetScreenTimer();
      }else{
        showPopup();
        setPopupTimer();
      }
    }

    const showPopup=()=>{
      let content=  <Popup resetPopupTimer={resetPopupTimer}/>
      controller = new ModalController({
        onClose:()=> {
          controller=null;
          window.clearTimeout(timeoutIdPopup);
          window.clearTimeout(timeoutIdScreen);
          startScreenTimer()
        }
      });
      controller.show(content);
    }

    document.addEventListener("mousemove", resetScreenTimer, false);
    document.addEventListener("mousedown", resetScreenTimer, false);
    document.addEventListener("keypress", resetScreenTimer, false);
    document.addEventListener("touchmove", resetScreenTimer, false);
    document.addEventListener("touchstart", resetScreenTimer, false);

  }
 }
}

class Popup extends React.Component{

  intervalID=null;
  constructor(props){
    super();
    const kioskReset = Context.instance.config.kiosk.kioskReset;
    this.state={
      countDownTime:kioskReset.popupMillis/1000
    }
  }

  componentDidMount(){
    // CountDown on the popup before the screen reloads due to inacitvity
    const kioskReset = Context.instance.config.kiosk.kioskReset;
    const timeoutInMillisecondsPopup=kioskReset.popupMillis;
    let startTimeMS=(new Date()).getTime();
    this.intervalID=setInterval(()=>{
      let elapsedTime=new Date().getTime() - startTimeMS ;
      let remainingTime=timeoutInMillisecondsPopup-elapsedTime;
      let remainingTimeRounded=Math.ceil(remainingTime/1000) *1000;
      let temp=remainingTimeRounded/1000;
      if(temp<0){
        clearInterval(this.intervalID);
        temp=0;
      }
      this.setState({
        countDownTime:temp
      })
    },1000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalID);
  }

  render(){
    const i18n=Context.getInstance().i18n;
    let message=i18n.resetKiosk.promptOptions;
    message=message.replace("{count}",this.state.countDownTime )
    return(
      <div>
          <h2> {i18n.resetKiosk.prompt}</h2>
          <span>{message}</span>
          <br></br>
          <button className="i-button i-button-fill i-margin-top-1" onClick={this.props.resetPopupTimer}>{i18n.resetKiosk.promptButton}</button>
      </div>
    );
  }
 }

export default App;
