import BaseVM from "../../../components/Editor/support/BaseVM";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { IOccupantsCriteria } from "../../common/types";
import Planner from "../../../base/Planner";
import OfficePlan from "../../../base/OfficePlan";
import UsernameCache from "../../support/UsernameCache";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as occupantsUtil from "./occupantsUtil";
import * as sourceUtil from "../../../base/sourceUtil";

export default class OccupantsVM extends BaseVM {

  getCriteria(): IOccupantsCriteria {
    let criteria = Context.instance.session.occupantsCriteria;
    if (!criteria) {
      criteria = this.newCriteria();
    }
    return criteria;
  }

  getKnownAsAlias(): string {
    const i18n = Context.instance.i18n;
    const layer = sourceUtil.getPeopleLayer();
    const field = layer &&  aiimUtil.findField(layer.fields,FieldNames.PEOPLE_FULLNAME);
    return (field && field.alias) || i18n.miniapps.assignments.occupants.knownAs;
  }

  newCriteria(): IOccupantsCriteria {
    return {
      searchText: null,
      sortField: "knownas",
      sortDir: "asc",
      start: 0,
      num: 1000,
      filter: {
        siteFacilityLevel: {
          siteId: null,
          facilityId: null,
          levelId: null,
          where: null
        },
        assignmentType: {
          type: null,
          where: null
        },
        fields: [
          {
            field: FieldNames.PEOPLE_ORG_LEVEL_1,
            values: [],
            where: null
          },
          {
            field: FieldNames.PEOPLE_JOB_TITLE,
            values: [],
            where: null
          },
          {
            field: FieldNames.PEOPLE_ORG_LEVEL_2,
            values: [],
            where: null
          }
        ]
      }
    }
  }

  setCriteria(criteria: IOccupantsCriteria) {
    Context.instance.session.occupantsCriteria = criteria;
  }

}