import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getActiveFeatureType } from "../redux";
import Context from "../../../../context/Context";
import { ReactComponent as PolygonSelectionIcon } from "../../../../assets/selection-polygon.svg";
import { ReactComponent as PlaceUnitIcon } from "../../../../assets/place-unit-icon.svg";
import { ReactComponent as PlaceWallIcon } from "../../../../assets/place-wall-icon.svg";
import "@esri/calcite-components/dist/components/calcite-action-bar";
import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-group";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteAction,
  CalciteActionGroup,
  CalciteActionBar,
  CalciteTooltip,
  CalcitePopover
} from "@esri/calcite-components-react";
import * as sourceUtil from "../../../base/sourceUtil";

export type HeaderToolType = (
  | "dimensionLabels"
  | "grid"
  | "duplicate"
  | "copy"
  | "paste"
  | "edit"
  | "delete"
  | "zoomIn"
  | "zoomOut"
  | "reset"
  | "flipH"
  | "flipV"
  | "reflect"
  | "rotate"
  | "rotateL" 
  | "rotateR"
  | "pointDraw"
  | "rectDraw"
  | "polyDraw"
  | "lineDraw"
  | "drawWall"
  | "placeUnit"
  | "placeWall"
  | "pointSelect"
  | "rectSelect"
  | "polySelect"
  | "lineSelect"
  | "clearSelection"
  | "undo"
  | "redo"
  | "separator"
)
export interface IPanelHeaderToolsProps {
  className?: string,
  expandDisabled?: boolean,
  layout?: "vertical" | "horizontal",
  onButtonClick?: (e:React.MouseEvent, toolInfo:IHeaderToolInfo) => void,
  tools: HeaderToolType[],
  activeTools?: HeaderToolType[],
  disabledTools?: HeaderToolType[],
  selectedTool?: HeaderToolType,
  toolInfos?: IToolInfos
}
export type IToolInfos = {[index in HeaderToolType]?: IHeaderToolInfo | null}
export interface IHeaderToolInfo {
  name: HeaderToolType,
  text?: string,
  icon?: string, 
  css?: {},
  jsx?: JSX.Element | JSX.Element[],
}
const phtInfo = { count: 0, rnd: Math.floor(Math.random()*1e6) };
let allToolInfos:IToolInfos;

const PanelHeaderTools = ({
  className = null,
  expandDisabled = true,
  layout = "horizontal",
  onButtonClick = (e, toolInfo) => { },
  tools = ["zoomIn","delete","edit"],
  activeTools = [],
  disabledTools = [],
  selectedTool = null,
  toolInfos = {}
}: IPanelHeaderToolsProps) => {
  const layerType = useSelector(getActiveFeatureType);
  const [activeTool, setActiveTool] = useState<HeaderToolType>(selectedTool);
  //multiple tools can have a popover, but only one should be open at a time based on the activeTool
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  phtInfo.count++;
  const compId = "pht-" + phtInfo.rnd;
  const ctx = Context.getInstance();
  const { i18n, lib } = ctx;
  const i18nHT = i18n.editor.headerTools;

  if (!allToolInfos) {
    allToolInfos = {
      "dimensionLabels" : {name:"dimensionLabels", text: i18nHT.dimensionLabels, icon: "dimensions"},
      "grid" : {name:"grid", text: i18nHT.grid, icon: "grid-unit"},
      "duplicate" : {name:"duplicate", text: i18nHT.duplicate, icon: "duplicate"},
      "copy" : {name:"copy", text: i18nHT.copy, icon: "copy"},
      "paste" : {name:"paste", text: i18nHT.paste, icon: "paste"},
      "edit" : {name:"edit", text: i18n.general.edit, icon: "pencil"},
      "delete" : {name:"delete", text: i18n.general.del, icon: "trash"},
      "zoomIn" : {name:"zoomIn", text: i18nHT.zoomIn, icon: "magnifying-glass-plus"}, 
      "zoomOut": {name:"zoomOut", text: i18nHT.zoomOut, icon: "magnifying-glass-minus"},
      "reset" : {name:"reset", text: i18n.general.reset, icon: "reset"},
      "flipH" : {name:"flipH", text: i18nHT.flipH, icon: "flip"}, 
      "flipV" : {name:"flipV", text: i18nHT.flipV, icon: "flip", css: {transform: "rotate(90deg)"}},
      "reflect" : {name:"reflect", text: i18nHT.reflect, icon: "seamlines"},  //center-vertical, center-horizontal
      "rotate" : {name:"rotate", text: i18nHT.rotate, icon: "rotate"},
      "rotateL" : {name:"rotateL", text: i18nHT.rotateL, icon: "rotate", css: {transform: "rotateY(180deg)"}},
      "rotateR" : {name:"rotateR", text: i18nHT.rotateR, icon: "rotate"},
      "pointDraw" : {name:"pointDraw", text: i18nHT.drawPoint, icon: "point"},
      "rectDraw" : {name:"rectDraw", text: i18nHT.drawRect, icon: "rectangle"},
      "polyDraw" : {name:"polyDraw", text: i18nHT.drawPoly, icon: "polygon-vertices"},
      "lineDraw" : {name:"lineDraw", text: i18nHT.drawLine, icon: "line"},
      "drawWall" : {name:"drawWall", text: i18nHT.drawWall, icon: "line"},
      "placeUnit" : {name:"placeUnit", text: i18nHT.placeUnit, icon: null},
      "placeWall" : {name:"placeWall", text: i18nHT.placeWall, icon: null},
      "pointSelect" : {name:"pointSelect", text: i18n.miniapps.mapSelection.byPoint, icon: "cursor"},
      "rectSelect" : {name:"rectSelect", text: i18n.miniapps.mapSelection.byRectangle, icon: "cursor-marquee"},
      "polySelect" : {name:"polySelect", text: i18n.miniapps.mapSelection.byPolygon, icon: null }, //"lasso-select"
      "lineSelect" : {name:"lineSelect", text: i18nHT.selectByLine, icon: "line"},
      "clearSelection" : {name:"clearSelection", text: i18n.miniapps.mapSelection.clear, icon: "erase"},
      "undo" : {name:"undo", text: i18n.spaceplanner.undo, icon: "undo"},
      "redo" : {name:"redo", text: i18n.spaceplanner.redo, icon: "redo"},
      "separator" : null,
    };
  }

  toolInfos && Object.keys(allToolInfos).forEach(k => k in toolInfos && toolInfos[k] && Object.assign(allToolInfos[k], toolInfos[k]));

  const createActionButton = (tool: HeaderToolType, key: string) => {
    const eid = `${compId}__${tool}`;
    const toolInfo = allToolInfos[tool];
    if (!toolInfo) return undefined;
    if (toolInfo.name === "grid" && !sourceUtil.getGrid().gridSettings.enabled) return undefined;
    const text = toolInfo.text || tool;
    const jsx = toolInfo.jsx;
    let isActive = activeTool === tool && 
      "pointSelect,rectSelect,polySelect,lineSelect,pointDraw,rectDraw,polyDraw,lineDraw,drawWall,placeUnit,placeWall".includes(tool);
    isActive = isActive || activeTools.includes(tool);
    const icon = toolInfo.icon;
    key = key + "_" + tool;
    const button = [
      <CalciteAction
        id={eid}
        text={typeof text === "string" ? text : ""}
        icon={icon}
        key={key + "_ca"}
        //onClick={}
        style={{ ...toolInfo.css }}
        active={isActive ? true : undefined}
        disabled={disabledTools.includes(tool) || undefined}
      >
        {icon===null && tool==="polySelect" ? <PolygonSelectionIcon width="16" /> : undefined}
        {icon===null && tool==="placeUnit" ? <PlaceUnitIcon width="16" height="16"/> : undefined}
        {icon===null && tool==="placeWall" ? <PlaceWallIcon width="16" height="16"/> : undefined}
      </CalciteAction>
    ];
    jsx && typeof jsx === "object" && isOpenPopover && activeTool == tool &&
      button.push(<CalcitePopover referenceElement={eid}
        placement="bottom" label="mypopover"
        key={key + "_cp"}
        autoClose={true}
        closable={false}
        open={true}
        onCalcitePopoverOpen={() => {
          console.log('popover open');
        }}
        onCalcitePopoverClose={() => {
          console.log('popover close');
          setIsOpenPopover(false);
          //setTimeout(() => setIsOpenPopover(false), 500);
        }}
      >{jsx}</CalcitePopover>);
    !isOpenPopover &&
      button.push(<CalciteTooltip
        referenceElement={eid}
        key={key + "_ctt"}
        placement="bottom"
        overlayPositioning="fixed"
        label={text}>
        <span>{getTooltip(tool)}</span>
      </CalciteTooltip>);
    return button;
  }

  const createToolBar = () => {
    const resultJsx = [];
    const groups = tools.join("|").split("separator");
    groups.forEach((grp, j) => {
      const btns = grp.split("|").filter(x=>!!x);
      const key1 = `${compId}_${j}`;
      if (groups.length>1) {
        resultJsx.push((
          <CalciteActionGroup layout={layout} key={`${key1}_cag`}>
            {btns.map(t => createActionButton(t as HeaderToolType, key1))}
          </CalciteActionGroup>
        ));
      } else {
        resultJsx.push(...btns.map((t, i) => createActionButton(t as HeaderToolType, `${key1}_${i}`)));
      }
    });

    let cls = "i-panel-header-tools";
    if (className) cls += " "+className;
    return (
      <CalciteActionBar className={cls}
        key={`${compId}_cab`}
        layout={layout} expandDisabled={expandDisabled || undefined}
        onClick={(e) => {
          if (e.target===e.currentTarget) { //no buttons clicked
            onButtonClick && onButtonClick(e, null);
          } else {
            const id = (e.target as HTMLElement).id;
            const tool = id.substring(id.lastIndexOf("__")+2) as HeaderToolType;
            const toolInfo = allToolInfos[tool];
            if (toolInfo) {
              if (!"dimensionLabels,grid".includes(toolInfo.name)) setActiveTool(tool);
              onButtonClick && onButtonClick(e, { ...toolInfo, name: tool});
              if (toolInfo.jsx && typeof toolInfo.jsx === "object") {
                setIsOpenPopover(true); //enable the popover viz
              }
            }
          }
        }}>
        {resultJsx}
      </CalciteActionBar>
    );
  }

  const getTooltip = (tool: HeaderToolType) => {
    const toolInfo = allToolInfos[tool];
    const text = toolInfo?.text || tool;
    if (disabledTools.includes(tool)) {
      if (tool === "duplicate") {
        return layerType === "site"
          ? i18n.editor.mapButtons.notAvailableDuplicateSite
          : layerType === "facility"
            ? i18n.editor.mapButtons.notAvailableDuplicateFacility
            : i18n.editor.mapButtons.notAvailableDuplicateLevel;
        } else if (["edit", "delete"].includes(tool)) {
          return layerType === "site"
            ? i18n.editor.mapButtons.notAvailableSites
            : layerType === "facility"
              ? i18n.editor.mapButtons.notAvailableFacilities
              : i18n.editor.mapButtons.notAvailableLevels;
        } else {
        return i18n.editor.mapButtons.disabled;
      }
    } else {
      return text;
    }
  }

  return createToolBar();
}
export default PanelHeaderTools;