// src.aiim.base
import ItemReference from "../aiim/base/ItemReference";

// src.context
import Context from "./Context";
import Topic from "./Topic";

// src.util
import BaseClass from "../util/BaseClass";
import * as mapUtil from "../util/mapUtil";
import { FilterFeatures, IWidgetConstructorProps } from "../util/interfaces";

export default class FloorFilter extends BaseClass {
  //----------------------------------
  //
  //  Variables
  //
  //----------------------------------

  //----------------------------------
  //  activeView
  //----------------------------------

  // The active view where the active widget displays and reads from
  activeView: __esri.MapView | __esri.SceneView = null;

  //----------------------------------
  //  mapView
  //----------------------------------

  // The map view where the map widget displays and reads from
  mapView: __esri.MapView = null;

  //----------------------------------
  //  sceneView
  //----------------------------------

  // The scene view where the scene widget displays and reads from
  sceneView: __esri.SceneView = null;

  //----------------------------------
  //  activeWidget
  //----------------------------------

  // The widget object used in the active view
  activeWidget: __esri.FloorFilter = null;

  //----------------------------------
  //  mapViewWidget
  //----------------------------------

  // The widget object used in the map view
  mapViewWidget: __esri.FloorFilter = null;

  //----------------------------------
  //  sceneViewWidget
  //----------------------------------

  // The widget object used in the scene view
  sceneViewWidget: __esri.FloorFilter = null;

  //----------------------------------
  //
  //  Private Variables
  //
  //----------------------------------

  _outOfSync = false;
  _preventFacilityPanel = false;
  _syncProperties = null;

  //----------------------------------
  //
  //  Lifecycle
  //
  //----------------------------------

  constructor() {
    super();

    this.own([
      Topic.subscribe(Topic.FacilityModeUpdated,info => {
        try {
          if (this.activeView && this.activeView !== info.view) {
            this._outOfSync = true;
          }

          const vw = this.activeWidget && this.activeWidget.view;
          const is3D = vw && vw.type === "3d";
          const fm = info && info.facilityMode;
          if (fm && info.view && info.origin !== this) {
            const fi = fm.activeFacilityInfo;
            const fid = fi && fi.facilityId;
            let ld = fi && fi.activeLevel;
            if (ld && ld.levelId) {
              this.setLevel(ld.levelId)
            } else {
              ld = fi && fid && fi.zeroVOLevel;
              if (ld && ld.levelId && !is3D) {
                this.setLevel(ld.levelId)
              } else if (fid) {
                this.setFacility(fid);

                // "All" option isn't selected in floor picker when clicking on a facility in 3D mode #3914
                if (fi && fi.levels && fi.levels.length > 1) {
                  const lid = `all--${fid}`;
                  this.setLevel(lid)
                }
                
              }
            }
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.FacilityActivated, (params) => {
        try {
          const view = this.activeView;
          if (view && view !== params.view) {
            this._outOfSync = true;
          } else {
            if (view && view.type === "2d") {
              const footprints = Context.instance.aiim.facilityFootprints;
              if (footprints) {
                footprints.highlightFacility2D(params.facilityInfo);
              }
            }
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.SiteActivated, (params) => {
        try {
          const view = this.activeView;
          if (view && view !== params.view) {
            this._outOfSync = true;
          }
        } catch(ex) {
          console.error(ex)
        }
      }),

      Topic.subscribe(Topic.FacilityDeactivated, (params) => {
        try {
          const view = this.activeView;
          if (view && view !== params.view) {
            this._outOfSync = true;
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.SiteDeactivated, (params) => {
        try {
          const view = this.activeView;
          if (view && view !== params.view) {
            this._outOfSync = true;
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.PlanOpened, (params) => {
        try {
          const view = this.activeView;
          if (view && view === params.view) {
            const facilityMode = Context.getInstance().aiim.facilityMode;
            if (facilityMode) {
              facilityMode.applyActiveFacilityInfo(view);
            }
            this._outOfSync = false;
          } else if (view && view !== params.view) {
            this._outOfSync = true;
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ViewActivated0, (params) => {
        try {
          const view = this.activeView;
          if (view && view === params.view) {
            if (this._outOfSync) {
              const facilityMode = Context.getInstance().aiim.facilityMode;
              if (facilityMode) {
                facilityMode.applyActiveFacilityInfo(view);
              }
              this._outOfSync = false;
            }
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ViewActivated, (params) => {
        if (this.activeWidget) {
          this._syncProperties = this.activeWidget.viewModel;
        }

        const view = params.view;
        const sync = this.activeView !== view;

        if (view.type === "2d" && this.mapView && this.mapViewWidget) {
          this.activeView = this.mapView;
          this.activeWidget = this.mapViewWidget;
        } else if (view.type === "3d" && this.sceneView && this.sceneViewWidget) {
          this.activeView = this.sceneView;
          this.activeWidget = this.sceneViewWidget;
        }

        if (sync) {
          this._syncWidgets();
        }
      })
    ]);
  }

  // Initialize widgets, views, and listeners for 2D and 3D
  init(view: __esri.MapView | __esri.SceneView, level?: string) {
    const lib = Context.getInstance().lib;
    const params: IWidgetConstructorProps & __esri.FloorFilterProperties = {
      view,
      indoorsContext: Context.getInstance()
    };

    if (level) params.level = level;

    if (view.type === "2d") {
      this.mapView = view;
      this.mapViewWidget = new lib.indoors.CustomFloorFilter(params);
      this._addListeners(this.mapViewWidget, view);
    } else {
      this.sceneView = view;
      this.sceneViewWidget = new lib.esri.FloorFilter({
        view: view
      });
      this._addListeners(this.sceneViewWidget, view);
    }
  }

  refreshData(view?: __esri.MapView | __esri.SceneView) {
    if (!view) view = Context.instance.views.activeView;
    const map = view && view.map;
    const wgt = this.activeWidget;
    const vm = wgt && wgt.viewModel;
    if (vm && map) {
      // @ts-ignore
      if (vm._updateFloorFilterTask != null) {
        // @ts-ignore
        vm._updateFloorFilterTask.abort();
        // @ts-ignore
        vm._updateFloorFilterTask = null;
      }
      const createTask = Context.instance.lib.esri.asyncUtils.createTask;
      const throwIfAborted = Context.instance.lib.esri.promiseUtils.throwIfAborted;
      // @ts-ignore
      vm._updateFloorFilterTask = createTask(async (signal) => {
        // @ts-ignore
        await vm._updateFloorFilterFromMap(map);
        throwIfAborted(signal);
        // @ts-ignore
        if (!vm.original_isOverridden) vm.original_isOverridden = vm._isOverridden;
        try {
          const level = wgt.level;
          // @ts-ignore
          vm.filterFeatures = null;
          // @ts-ignore
          vm._isOverridden = (name) => {
            if (name === "filterFeatures") return false;
            // @ts-ignore
            return vm.original_isOverridden(name);
          }
          console.log("Refreshing FloorFilter data...");
          const currentMenu = vm.get("filterMenuType");
          const currentMenuOpen = vm.get("filterMenuOpen");
          // @ts-ignore
          await vm._setInitialViewState(map);
          // @ts-ignore
          vm._isOverridden = vm.original_isOverridden;
          if (level) wgt.level = level;
          vm.set("filterMenuType", currentMenu);
          vm.set("filterMenuOpen", currentMenuOpen);
          
          // reload levels
          const { aiim: { datasets: { levels } } } = Context.getInstance();
          await levels.load();
        } finally {
          // @ts-ignore
          vm._isOverridden = vm.original_isOverridden;
        }
      });
      // @ts-ignore
      return vm._updateFloorFilterTask.promise;
    } else {
      return Promise.resolve();
    }
  }

  //----------------------------------
  //
  //  Public Methods
  //
  //----------------------------------

  // Set the site on the core widget
  setSite(siteId) {
    if (this.activeWidget) {
      this.activeWidget.site = siteId;
    }
  }

  // Set the facility on the core widget
  setFacility(facilityId) {
    if (this.activeWidget) {
      this.activeWidget.facility = facilityId;
    }
  }

  // Set the level on the core widget
  setLevel(levelId) {
    this._preventFacilityPanel = true;
    if (this.activeWidget) {
      this.activeWidget.level = levelId;
    }
  }

  //----------------------------------
  //
  //  Private Methods
  //
  //----------------------------------

  _addListeners(widget, view) {
    view.ui.add(widget, "top-trailing");

    // Watch for changes to floors on the view
    widget.watch("view.floors", this._onFloorsChanged);

    // Watch for changes to site and facility properties
    widget.watch("site", this._onSiteChanged);
    widget.watch("facility", this._onFacilityChanged);
  }

  // Listener for a site property change on the core widget
  _onSiteChanged = (newVal,oldVal) => {
    if (oldVal === undefined) return; // issue #3658

    Topic.publish(Topic.FacilityDeactivated, { view: this.activeView });
    Topic.publish(Topic.SiteActivated, { view: this.activeView });

    const view = Context.instance.views.activeView
    const inactiveView = Context.instance.views.getInactiveView()
    const aiim = Context.getInstance().aiim;
    const facilityFootprints = aiim && aiim.facilityFootprints;
    if (facilityFootprints) {
      facilityFootprints.removeHighlight();
    }
    mapUtil.clearLocationGraphic(view)
    mapUtil.clearLocationGraphic(inactiveView)
    Topic.publish(Topic.CloseInfoPanel, { siteChanged: true });
  }

  // Listener for a facility property change on the core widget
  _onFacilityChanged = (value) => {
    Topic.publish(Topic.FacilitySelected, value);

    if (!Context.getInstance().appMode.supportsInfoPanel()) return;
    const aiim = Context.getInstance().aiim;
    const dataset = aiim && aiim.datasets && aiim.datasets.facilities;
    const source = dataset && dataset.getSource();
    if (!source) return;

    const json = {
      sourceKey: source.key,
      uniqueIdField: source.uniqueIdField,
      uniqueId: value
    };

    const item = new ItemReference();
    if (!this._preventFacilityPanel) {
      item.fromJson(json).then(ok => {
        if (ok && item.searchResult) {
          Topic.publish(Topic.ShowSearchResult,{
            sourceKey: item.sourceKey,
            searchResult: item.searchResult,
            zoom: false,
            highlight: true,
            trackRecent: true,
            publishedFromLevelFilter: true
          });
        } else {
          console.warn("The facility item is invalid: ", value);
        }
      }).catch(ex => {
        console.error("Error opening facility:", ex);
      });
    } else {
      this._preventFacilityPanel = false;
    }
  }

  // Listener for a change to the MapView | SceneView floors property
  _onFloorsChanged = () => {
    const facilityId = this.activeWidget.facility;
    const levelId = this.activeWidget.level;

    let isAll = false;
    const allLevelInfo = levelId && levelId.split("--");
    if (allLevelInfo && allLevelInfo.length > 1) {
      if (allLevelInfo[0] === "all") {
        isAll = true;
      }
    }

    const aiim = Context.instance.aiim;
    const levelsDataset = aiim && aiim.datasets && aiim.datasets.levels;
    if (levelsDataset) {
      let levelData, facilityData
      levelData = !isAll ? levelsDataset.getLevelData(levelId) : { levelId: `all--${facilityId}`};
      facilityData = levelsDataset.getFacilityData(facilityId);
      if (levelData || facilityData) {
        Topic.publish(Topic.ActivateLevel,{
          facilityData: facilityData,
          levelData: levelData,
          view: this.activeWidget.view,
          origin: this // Prevents endless loop of publish/subscribe
        });

        // Specifically to InfoPanel.js to know whether the info panel is open
        // to a different floor in the same facility. If so, shows a toast
        Topic.publish(Topic.LevelSelected2, {
          facilityData: facilityData,
          levelData: levelData,
          view: this.activeWidget.view,
          origin: this // Prevents endless loop of publish/subscribe
        });

      }
    }
  }

  // When switching between 2D and 3D, preserve the core widget properties
  _syncWidgets = () => {
    if (!this.activeWidget || !this.activeWidget.viewModel) {
      return;
    }
    const activeVM = this.activeWidget.viewModel;
    const properties = this._syncProperties;
    if (!properties) {
      return;
    }

    if (properties.level && activeVM.level !== properties.level) {
      let level = properties.level

      // Check if the "All" level from the 3D widget is selected
      let isAll = false;
      const allLevelInfo = level.split("--");
      if (allLevelInfo.length > 1) {
        if (allLevelInfo[0] === "all") {
          isAll = true;
        }
      }
      if (isAll) {
        const facilityId = allLevelInfo[1];
        // @ts-ignore
        const facility = activeVM.getFacility(facilityId);
        // @ts-ignore
        const baseLevel = activeVM.getBaseLevel(facility);
        if (baseLevel && baseLevel.id) {
          level = baseLevel.id;
        }
      }

      this.setLevel(level);
    } else if (properties.facility && activeVM.facility !== properties.facility) {
      this.setFacility(properties.facility);
    } else if (properties.site && activeVM.site !== properties.site) {
      this.setSite(properties.site);
    }

    if (activeVM.longNames !== properties.longNames) {
      activeVM.longNames = properties.longNames;
    }
    // @ts-ignore
    if (activeVM.filterMenuOpen !== properties.filterMenuOpen) {
      // @ts-ignore
      activeVM.filterMenuOpen = properties.filterMenuOpen;
    }
  }
}
