import React from "react";
import { connect } from "react-redux";
import AppHelper from "../../../util/AppHelper";
import Backstage from "../Backstage/Backstage";
import Context from "../../../context/Context";
import Layout from "./Layout";
import {ModalController} from "../../../common/components/Modal";
import MiniappsIssues from "../../miniapps/configurator/Issues";
import MiniappsLayout from "../../miniapps/layout/Layout";
import MiniappsShell from "../../miniapps/layout/Shell";
import Switcher from "../../miniapps/Switcher/Switcher";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

import { Container } from "../../styles/App/app";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import {
  CalciteButton,
  CalciteIcon
} from "@esri/calcite-components-react";
import { IConfigurables } from "../../../context/Configuration";

interface IAppState {
  startupErrorIsFatal: boolean,
  startupError: string,
  startupErrorDetails: string[],
  startupErrorTitle?: string,
  wasStarted: boolean,
  showOpenPlan: boolean,
  showGetStarted?: boolean
}

class App extends React.Component<IRdxProps, IAppState> {
  appHelper: AppHelper;

  constructor(props?: IRdxProps) {
    super(props);
    this.appHelper = new AppHelper({ app: this });
    this.state = component.newState({
      startupError: null,
      startupErrorDetails: null,
      startupErrorTitle: null,
      startupErrorIsFatal: false,
      wasStarted: false,
      showGetStarted: false
    });

    document.body.classList.add("i-editor-on");
  }
  static defaultProps = {
    version: 1
  }
  componentDidMount() {

    try {
      document.getElementById("view-container").style.display = "none";
    } catch(ex) {}

    this.appHelper
      .startupApp()
      .then(result => {
        this.setState({
          wasStarted: true
        });
        this.setRdxValues();
      })
      .catch(error => {
        this._handleStartupError(error);
      });

    component.own(this, [
      Topic.subscribe(Topic.ReloadViews, params => {
        this.appHelper
          .reloadViews(params)
          .then(result => {
            console.log("App.reloadViews.complete...");
            this.setRdxValues();
            if (params.callback) params.callback();
          })
          .catch(ex => {
            console.log("App.reloadViews.error...");
            if (params.callback) params.callback(ex);
            // console.error(ex);
            // component.refresh(this);
          });
      })
    ]);
  }

  private setRdxValues() {
    const { config } = Context.getInstance();
    const { workspaceAreas: wsa } = config["spaceplanner"] as IConfigurables["spaceplanner"];
    this.props.setRdxValue(Rdx.Keys.APP_STARTED, true);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_WORKSPACES_ENABLED,
      wsa.hotDesks.enabled || wsa.hotels.enabled || wsa.meetingRooms.enabled);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_HOTDESKS_ENABLED, wsa.hotDesks.enabled);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_HOTELS_ENABLED, wsa.hotels.enabled);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_MEETINGROOMS_ENABLED, wsa.meetingRooms.enabled);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_HOTEL_RESERVATION_METHOD, wsa.hotels.reservationMethod);
    this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD, wsa.meetingRooms.reservationMethod);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _handleStartupError(error) {
    const i18n = Context.instance.i18n;
    let msg = null,
      details = null,
      errorTitle = null,
      isFatal = false,
      showOpenPlan = true,
      showGetStarted = false,
      popupError = true;
    const invalidUser = error && (error.xtnCode === "__invalidUser__");

    if (error === "__noIndoorsSubscription__") {
      msg = i18n.messages.noIndoorsSpacesSubscription;
      if (Context.instance.noIndoorsSubscriptionMsg) {
        msg = Context.instance.noIndoorsSubscriptionMsg;
      }
      isFatal = true;
    } else if (error === "__notAnIndoorsApp__") {
      msg = i18n.messages.notAnIndoorsApp;
      if (Context.instance.isSP()) {
        msg = i18n.messages.notASpacePlannerApp;
      } else if (Context.instance.isFPE()) {
        msg = i18n.messages.notAFloorPlanEditorApp;
      }
      isFatal = true;
    } else if (error && error.message === "No webmap was specified") {
      if ((window.location.href.indexOf("?") === -1) && Context.instance.isFPE() && 
          Context.instance.user && Context.instance.user.canConfigureApp()) {
        popupError = false;
        showGetStarted = true;
      } else {
        msg = i18n.messages.noWebmapSpecified;
        popupError = false;
        isFatal = true;
        if (Context.instance.user && Context.instance.user.canConfigureApp()) isFatal = false;
      }
    } else {
      console.error("Startup error", error);
      msg = (error && error.message) || null;
      isFatal = true
      //if (error && error.xtnIsFatal) isFatal = true;
    }
    if (invalidUser) {
      isFatal = true;
      errorTitle = i18n.spaceplanner.application.insufficientRoleTitle
    }
    this.setState({
      startupError: msg,
      startupErrorDetails: details,
      startupErrorTitle: errorTitle,
      startupErrorIsFatal: isFatal,
      showOpenPlan: showOpenPlan,
      showGetStarted: showGetStarted
    });

    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    if (!isFatal) {
      if (popupError) {
        let title = i18n.spaceplanner.application.errorStartingAppTitle;
        if (invalidUser) title = i18n.spaceplanner.application.insufficientRoleTitle;

        ModalController.confirm({
          isError: true,
          title: title,
          hideCancel: true,
          content: (
            <div>
              <div>{msg}</div>
              <div>{details}</div>
            </div>
          )
        }).then(() => {
          if (invalidUser) {
            Topic.publish(Topic.SignOutClicked,{});
          } else {
            if (!cfgOn) {
              Topic.publish(Topic.ShowBackstage, { tabKey: "openPlan" });
            }
          }
        });

      } else if (showOpenPlan) {
        if (!cfgOn) {
          if (Context.instance.user.canConfigureApp()) {
            Context.instance.uiMode.toggleConfigurator(true);
            Topic.publish(Topic.ConfiguratorActivated,{});
          } else {
            Topic.publish(Topic.ShowBackstage, { tabKey: "openPlan" });
          }
        }
      }
    }
  }

  render() {
    if (Context.instance.isFPE()) {
      return this.renderFPE();
    } else {
      return this.renderSP();
    }
  }

  renderSP() {
    if (this.state.startupErrorIsFatal) {
      const errorMsg = this.state.startupError;
      const errorDetails = this.state.startupErrorDetails || "";
      const errorNode = (
        <div key="err" className="i-startup-error i-padded">
          <div>{errorMsg}</div>
          <div>{errorDetails}</div>
        </div>
      );
      return errorNode;
    }
    return (
      <Container>
        <Layout />
        <Backstage />
      </Container>
    );
  }

  renderFPE() {
    const { showGetStarted, startupErrorIsFatal, wasStarted } = this.state;
    if (startupErrorIsFatal) {
      return this.renderFPEStartupError();
    } else if (showGetStarted) {
      return this.renderShowGetStarted();
    }
    return (
      <div className="i-miniapps-container">
        <MiniappsLayout wasAppStarted={wasStarted} />
        <Switcher />
      </div>
    )
  }

  renderFPEStartupError() {
    const i18n = Context.instance.i18n;
    const title = this.state.startupErrorTitle || i18n.spaceplanner.application.errorStartingAppTitle;
    let msg = this.state.startupError;
    if (typeof msg === "string") {
      if (msg.includes("Server object extension 'versionmanagementserver' not found")) {
        msg = i18n.misc.noVersionManager;
        if (Context.instance.isFPE()) msg = i18n.misc.noVersionManagerFPE;
      }
    }
    let details: any = this.state.startupErrorDetails || "";
    if (!details) {
      const planner = Context.instance.spaceplanner && Context.instance.spaceplanner.planner;
      const issues = planner && planner.project && planner.project.issues;
      if (issues && issues.length > 0) {
        details = (<MiniappsIssues simple issues={issues} />);
      }
    }
    return (
      <MiniappsShell>
        <div className="i-miniapps-startup-issue-container">
          <div className="i-miniapps-startup-issue">
            <h2><CalciteIcon icon="exclamation-mark-triangle" />{title}</h2>
            <div>{msg}</div>
            <div>{details}</div>
          </div>
        </div>
      </MiniappsShell>
    )
  }

  renderShowGetStarted() {
    const i18n = Context.instance.i18n;
    return (
      <MiniappsShell>
        <div className="i-miniapps-getstarted-container">
          <div className="i-miniapps-getstarted">
            <div>
              <CalciteButton 
                onClick={e => {
                  Context.instance.uiMode.toggleConfigurator(true);
                  Topic.publish(Topic.ConfiguratorActivated,{});
                  this.setState({showGetStarted: false})
                }}
              >
                {i18n.miniapps.createFpeApp}
              </CalciteButton>
            </div>
          </div>
        </div>
      </MiniappsShell>
    )
  }

}

const mapStateToProps = (state) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
