import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import IdleTime from "./IdleTime";
import Toggle from "../../common/Toggle/Toggle";
import * as component from "../../util/component";
import * as val from "../../../util/val";
import Topic from "../../../context/Topic";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label",
  helptext: "i-helptext"
};

export default class Kiosk extends React.Component {

  constructor(props) {
    super(props);
    const kiosk = this.props.cfg.getConfigurable("kiosk");
    this.state = component.newState({
      resetEnabled: kiosk.kioskReset.enabled,
      resetTimeoutMillis: kiosk.kioskReset.timeoutMillis,
      resetPopupMillis: kiosk.kioskReset.popupMillis,
      idleTimeValidation: true,
      popupTimeValidation: true,
      idleTimeErrorMessage:"",
      invalidNumberError:"",
      virtualKeyboardEnabled: kiosk.kioskVirtualKeyboard.enabled,
      virtualKeyboardSize: kiosk.kioskVirtualKeyboard.size,
      qrCodeEnabled: kiosk.kioskQRCode.enabled
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeIdleSeconds = (evt) => {
    //console.log("onChangeIdleSeconds", typeof evt.target.value, evt);
    const kioskDefault = Context.instance.configuration._configurables.kiosk;
    const i18n = Context.instance.i18n;
    const cfg = this.props.cfg;
    let v, text = evt.target.value.trim();
    if (text.length > 0) v = Number(text);
    if (val.isFiniteNum(v)&& v>=1) {
      v = Math.round(v);
      if (v < 1) v = (kioskDefault.kioskReset.timeoutMillis / 1000);
      v = v * 1000;
      this.setState(state => {
        return {
          resetTimeoutMillis: v,
          idleTimeValidation: true,
          idleTimeErrorMessage: ""
        }
      });
      cfg.getConfigurable("kiosk").kioskReset.timeoutMillis = v;
    }
    else if(!val.isFiniteNum(v)){
      this.setState({
        idleTimeErrorMessage: i18n.configurator.kiosk.invalidNumberError,
        idleTimeValidation: false
      })
    }
    else if(v<1){
      this.setState({
        idleTimeErrorMessage: i18n.configurator.kiosk.negativeNumberError,
        idleTimeValidation: false
      })
    }
  }

  onChangePopupSeconds = (evt) => {
    const kioskDefault = Context.instance.configuration._configurables.kiosk;
    const i18n = Context.instance.i18n;
    const cfg = this.props.cfg;
    let v, text = evt.target.value.trim();
    console.log("Type", typeof text);
    if (text.length > 0) v = Number(text);
    if (val.isFiniteNum(v) && v>=1) {
      v = Math.round(v);
      if (v < 1) v = (kioskDefault.kioskReset.popupMillis / 1000);
      v = v * 1000;
      this.setState(state => {
        return {
          resetTimeoutMillis: v,
          popupTimeValidation: true,
          popupTimeErrorMessage:""
        }
      });
      cfg.getConfigurable("kiosk").kioskReset.popupMillis = v;
    }
    else if(!val.isFiniteNum(v)){
      this.setState({
        popupTimeErrorMessage: i18n.configurator.kiosk.invalidNumberError,
        popupTimeValidation: false
      })
    }
    else if(v<1){
      this.setState({
        popupTimeErrorMessage: i18n.configurator.kiosk.negativeNumberError,
        popupTimeValidation: false
      })
    }
  }

  onChangeResetEnabled = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        resetEnabled: checked
      }
    });
    cfg.getConfigurable("kiosk").kioskReset.enabled = checked;
  }

  
  onChangeVirtualKeyboardEnabled =(checked) => {
    const cfg = this.props.cfg;
    this.setState(() => {
      return {
        virtualKeyboardEnabled: checked
      }
    });
    cfg.getConfigurable("kiosk").kioskVirtualKeyboard.enabled = checked;
  }

  onChangeQRCodeEnabled =(checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        qrCodeEnabled: checked
      }
    });
    cfg.getConfigurable("kiosk").kioskQRCode.enabled = checked;
  }

  handleSizeChange=(event)=> {
    this.props.cfg.getConfigurable("kiosk").kioskVirtualKeyboard.size=event.target.value;
    this.setState({
      virtualKeyboardSize : event.target.value
    })
    Context.instance.virtualKeyboard.setSize(event.target.value);
  }

  render() {
    const i18n = Context.instance.i18n;
    const resetEnabled = this.state.resetEnabled;
    const resetTimeoutMillis = this.state.resetTimeoutMillis;
    const resetPopupMillis = this.state.resetPopupMillis;
    const idleSeconds = resetTimeoutMillis / 1000;
    const popupSeconds = resetPopupMillis / 1000;
    const qrCodeEnabled = this.state.qrCodeEnabled;
    const helpUrl = Context.instance.config.kioskQRCodeHelpUrl;
    const virtualKeyboardEnabled = this.state.virtualKeyboardEnabled;

    return (
      <div>
         <IdleTime cfg={this.props.cfg}/>
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.kiosk.caption}</span>
          <HelpTip text={i18n.configurator.kiosk.help}/>
        </div>

        <div key="kreset" className={CSS.formGroup}>
          <label id={"cfg-kreset-enabled-label"} htmlFor={"cfg-kreset-enabled"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.kiosk.kioskReset.enabled}</div>
            <Toggle id={"cfg-kreset-enabled"} checked={resetEnabled}
              onChange={this.onChangeResetEnabled}/>
          </label>
        </div>

        <div key="kreset-idle" className={CSS.formGroup}>
          <label id={"cfg-kreset-idle-label"} htmlFor={"cfg-kreset-idle"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.kiosk.kioskReset.idleTime}</div>
            <input id={"cfg-kreset-idle"} type="number" min="1"
              disabled={!resetEnabled}
              defaultValue={idleSeconds} onChange={this.onChangeIdleSeconds}/>
            <div className="i-issue">{this.state.idleTimeErrorMessage}</div>
          </label>
        </div>

        <div key="kreset-popup" className={CSS.formGroup}>
          <label id={"cfg-kreset-popup-label"} htmlFor={"cfg-kreset-popup"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.kiosk.kioskReset.popupTime}</div>
            <input id={"cfg-kreset-popup"} type="number" min="1"
              disabled={!resetEnabled}
              defaultValue={popupSeconds} onChange={this.onChangePopupSeconds}/>
            <div className="i-issue">{this.state.popupTimeErrorMessage}</div>
          </label>
        </div>

        
        <div key="virtualKeyboard" className={CSS.formGroup}>
        <div className={"cfg-virtualKeyboard-enabled-label"}>{i18n.configurator.virtualKeyboard.caption}
            <div className={"cfg-helptip"}>
              <HelpTip helpUrl={helpUrl}
                text={i18n.configurator.virtualKeyboard.help}/>
            </div>
          <Toggle id={"cfg-virtualKeyboard-enabled"} checked={virtualKeyboardEnabled}
              onChange={this.onChangeVirtualKeyboardEnabled}/>
        </div>
        </div>

        <div key="virtualKeyboard-size" className={CSS.formGroup}>
          <label id={"cfg-virtualKeyboard-size-label"} htmlFor={"cfg-virtualKeyboard-size"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.virtualKeyboard.sizeCaption}</div>
              <select disabled={!this.state.virtualKeyboardEnabled} value={this.state.virtualKeyboardSize} 
                onChange={this.handleSizeChange}>
                <option value="small">{i18n.configurator.virtualKeyboard.sizeOpt1}</option>
                <option value="medium">{i18n.configurator.virtualKeyboard.sizeOpt2}</option>
                <option value="large">{i18n.configurator.virtualKeyboard.sizeOpt3}</option>
              </select>
          </label>
        </div>

        <div key="qrcode">
          <div className={"cfg-qrcode-enabled-label"}>{i18n.configurator.kiosk.kioskQRCode.enabled}
            <div className={"cfg-helptip"}>
              <HelpTip helpUrl={helpUrl}
                text={i18n.configurator.kiosk.kioskQRCode.help}/>
            </div>
            <Toggle checked={qrCodeEnabled}
                  onChange={this.onChangeQRCodeEnabled}/>
          </div>
        </div>
        </div>
      </div>
    );

  }

}
