import React from 'react';

// components.util
import Icons from "../../../components/util/Icons";

// context
import Context from '../../../context/Context';

const CSS = {
  popoutInner: "i-popout-inner",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
};

export default class BrandedPopup extends React.Component {
  constructor(props) {
    super(props);

    this.closeRef = React.createRef();
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this._focus();
  }

  _focus() {
    if (this.titleRef && this.titleRef.current && this.titleRef.current.focus) {
      this.titleRef.current.focus();
    }
  }

  render() {
    const { content, closePopup, needsCloseProp, title } = this.props;
    const i18n = Context.getInstance().i18n;

    const contentDisplay = needsCloseProp 
      ? React.cloneElement(content, { ...content.props, closePopup })
      : content;

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <h2 tabIndex="0" ref={this.titleRef}>{title}</h2>
            <div className={CSS.panelHeaderTools}>
              <button 
                ref={this.closeRef}
                title={i18n.general.close} 
                aria-label={i18n.more.bookWorkspace.closeAriaLabelInit}
                onClick={closePopup}
              >
                {Icons.close()}
              </button>
            </div>
          </div>
          {contentDisplay}
        </div>
      </div>
    );
  }
}