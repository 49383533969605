import React from "react";

import Attributes from "./Attributes";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import SubTypeIcon from "../../common/SubTypeIcon/SubTypeIcon";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as itemUtil from "../../../aiim/util/itemUtil";

//import directionsIcon from "../../../assets/route.svg";

const CSS = {
  itemText: "i-feature-item-text",
  itemTitle: "i-feature-item-title",
  itemSubTitle: "i-feature-item-subtitle",
  itemAction: "i-feature-item-action",
  directionsButtonWrapper: "i-directions-button-wrapper"
};

export default class Item extends React.Component {
  _componentId
  _itemRef

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.detailsClicked = this.detailsClicked.bind(this);
    this.directionsClicked = this.directionsClicked.bind(this);
    this._componentId = component.nextId("item-")
    this._itemRef = React.createRef()
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus()
        }
      })
    ])
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  checkIfEnter=(event, para)=>{
    if(event && event.keyCode === 13) {
      if(para === "details")  this.detailsClicked(event);
      else this.directionsClicked(event);
    }
  }

  detailsClicked(event) {
    if (event) event.stopPropagation();
    const source = aiimUtil.getSource(this.props.sourceKey);
    const feature = this.props.featureItem.feature;
    itemUtil.makeItemReference(source,feature,true).then(ref => {
      if (ref) {
        Topic.publish(Topic.ShowSearchResult,{
          sourceKey: ref.sourceKey,
          searchResult: ref.searchResult,
          zoom: true,
          highlight: true,
          trackRecent: true,
          componentId: this._componentId
        });
      }
    });
  }

  directionsClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    const source = aiimUtil.getSource(this.props.sourceKey);
    const feature = this.props.featureItem.feature;
    itemUtil.makeItemReference(source,feature,true).then(ref => {
      if (ref) {
        Topic.publish(Topic.DirectionsClicked,{
          searchResult: ref.searchResult
        });
      }
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const source = aiimUtil.getSource(this.props.sourceKey);
    const featureItem = this.props.featureItem;
    const subTypeValue = this.props.subTypeValue;
    const feature = featureItem.feature;
    const title = source.getTitle(feature);
    const subTitle = source.getSubTitle(feature);
    const hashref = "#";

    // if (source.name === "ServiceNow-Incident-1336") {
    //   console.log("featureItem",featureItem);
    //   const popupTemplate = source.itemCard && source.itemCard.popupTemplate;
    //   if (feature && popupTemplate) {
    //     feature.popupTemplate = popupTemplate;
    //     return (
    //       <Attributes feature={feature} active={true}/>
    //     );
    //   }
    //
    //   // const popupInfo = source.itemCard && source.itemCard.popupInfo;
    //   // console.log("popupInfo",popupInfo);
    //   // if (feature && popupInfo) {
    //   //   /*
    //   //   const graphic = new lib.esri.Graphic({
    //   //     attributes: feature.attributes,
    //   //     geometry: feature.geometry,
    //   //     popupTemplate: feature.popupTemplate,
    //   //     layer: layer
    //   //   });
    //   //    */
    //   //   // popupInfo.content = {
    //   //   //   "type": "fields"
    //   //   // };
    //   //   // popupInfo.content = popupInfo.popupElements;
    //   //   //
    //   //   // popupInfo.content = "Some text";
    //   //   // feature.popupTemplate = {
    //   //   //   popupInfo
    //   //   // };
    //   //
    //   //   // feature.popupTemplate = {
    //   //   //   content: "Some text"
    //   //   // };
    //   //
    //   //   feature.popupTemplate = popupInfo;
    //
    //   //   return (
    //   //     <Attributes feature={feature} active={true}/>
    //   //   );
    //   // }
    // }


    // <Attributes item={item} active={activeTab === "attributes"}/>

    let subTitleNode = null;
    if (subTitle) {
      subTitleNode = <span key="subtitle" className={CSS.itemSubTitle}>{subTitle}</span>;
    }

    let proximityNode = null;
    const proximity = featureItem.proximity;
    if (typeof proximity === "string" && proximity.length > 0) {
      proximityNode = (
        <span key="proximity" className={CSS.itemSubTitle}
          dangerouslySetInnerHTML={{ __html: proximity}}></span>
      );
    }

    //<img src={directionsIcon} alt={i18n.directions.caption}></img>
    let actionNode = null;
    if (Context.getInstance().config.networkServiceUrl) {
      const hasGeometry = itemUtil.hasGeometry(source,feature);
      let cls = CSS.itemAction;
      if (!hasGeometry) cls += " i--disabled";
      let directionsTitle = i18n.directions.ariaTitle.directionsTo;
      directionsTitle = directionsTitle.replace("{destinationName}", title);
      actionNode = (
        <div className={CSS.directionsButtonWrapper}>
          <button 
            className={cls}
            aria-label={directionsTitle}
            onClick={hasGeometry && this.directionsClicked}
          >
            {Icons.directions()}
          </button>
        </div>
      );
    }

    let attributesNode = null;
    const popupTemplate = source.itemCard && source.itemCard.popupTemplate;
    if (feature && popupTemplate) {
      feature.popupTemplate = popupTemplate;
      attributesNode = (
        <Attributes feature={feature} active={true}/>
      );
    }

    const node = (
      <li>
        <div onClick={this.detailsClicked}>
        <span onClick={this.detailsClicked} onKeyDown={(event)=> this.checkIfEnter(event, "details")}
         tabIndex="0" href="#" ref={this._itemRef}>
          <SubTypeIcon forFeature={true} sourceKey={source.key} subTypeValue={subTypeValue} />
          <span className={CSS.itemText}>
            <span role="button" key="title" className={CSS.itemTitle}>{title}</span>
            {proximityNode}
          </span>
        </span>
        {actionNode}
        {attributesNode}
        </div>
      </li>
    );
    return node;
  }

}
