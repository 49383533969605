import BaseVM from "../../../components/Editor/support/BaseVM";
import Context from "../../../../context/Context";
import Planner from "../../../base/Planner";
import OfficePlan from "../../../base/OfficePlan";
import UsernameCache from "../../support/UsernameCache";
import { SharingLevels } from "../../../../common/components/Sharing";
import * as portalUtil from "../../../../util/portalUtil";
import * as versionUtil from "../../../base/versionUtil";
import * as groupUtils from "../../../base/groupsUtil";

export default class OpenPlanVM extends BaseVM {

  canDelete(plan) {
    const user = Context.instance.user;
    const isAdmin = user.isAdmin();
    const username = user.portalUser.username;
    const owner = plan && plan.owner;
    const isOwner = (username && owner && (owner.toLowerCase() === username.toLowerCase()));
    const openPlanId = Context.instance.spaceplanner.planId;
    let isCurrentlyOpen = false;
    if (openPlanId && plan && plan.versionInfo) {
      if (openPlanId === plan.versionInfo.versionGuid) isCurrentlyOpen = true;
    } else if (openPlanId && plan && plan.id) {
      if (openPlanId === plan.id) isCurrentlyOpen = true;
    }
    if (plan && !isCurrentlyOpen) {
      return (isAdmin || isOwner);
    }
    return false;
  };

  async deletePlan(plan) {
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned) {
      const vm = project.versionedInfo.versionManager;
      await vm.deleteVersion(plan.versionInfo);
    } else {
      await portalUtil.deleteItems(plan.id,plan.owner);
    }
  }

  getCriteria() {
    let criteria = Context.instance.session.openPlanCriteria;
    if (!criteria) {
      criteria = {
        searchText: "",
        scope: "all",
        sortField: "title",
        sortDir: "asc",
        recentPlanIds: null
      }
    }
    return criteria;
  }

  makeInfo(item, isVersion, versionManager?, supportService?) {
    const djLocale = Context.instance.lib.dojo.locale;
    const makeDate = v => {
      const d = new Date(v);
      const s = djLocale.format(d, {
        formatLength: "short"
      });
      return s;
    };

    const info = {
      key: null,
      id: null,
      title: null,
      created: null,
      createdString: null,
      modified: null,
      modifiedString: null,
      lastEdited: null,
      lastEditedString: null,
      access: null,
      summary: null,
      owner: null,
      ownerFullname: null,
      ownerInitials: null,
      ownerThumbnailUrl: null,
      status: null,
      portalItem: null,
      versionInfo: null,
      supportService: null,
      hasStandaloneAccess: false,
    };

    if (isVersion) {
      info.key = item.versionGuid;
      info.id = item.versionGuid;
      info.title = versionManager.getVersionTitle(item,true);
      info.created = item.creationDate;
      info.modified = item.modifiedDate;
      info.lastEdited = item.modifiedDate;
      info.createdString = makeDate(item.creationDate);
      info.modifiedString = makeDate(item.modifiedDate);
      info.lastEditedString = makeDate(item.modifiedDate);
      info.access = supportService && supportService.access;
      info.summary = item.description;
      info.owner = versionManager.getVersionOwner(item);
      info.status = versionManager.getStatus(item);
      info.versionInfo = item;
      info.supportService = supportService;
      if (!supportService) {
        if (versionManager.hasStandaloneAccess(item)) {
          info.access = item.access === "public" ? "org" : "private";
          info.hasStandaloneAccess = true;
        }
      }
    } else {
      info.key = item.id;
      info.id = item.id;
      info.title = item.title;
      info.created = item.created;
      info.modified = item.modified;
      info.createdString = makeDate(item.created);
      info.modifiedString = makeDate(item.modified);
      info.access = item.access;
      info.summary = item.snippet;
      info.owner = item.owner;
      info.portalItem = item;
    }
    return info;
  }

  matchesCriteria(info,criteria,lcText,lcUser) {
    let matches = true;
    if (matches && lcText) {
      if (info.title && !info.title.toLowerCase().includes(lcText)) matches = false;
    }
    if (matches && criteria && criteria.scope !== "all") {
      const isOwner = info.owner && (info.owner.toLowerCase() === lcUser);
      if (criteria.scope === "myContent" && !isOwner) matches = false;
      else if (criteria.scope === "sharedContent" && isOwner) matches = false;
    }
    return matches;
  }

  openPlan(evt,plan) {
    evt.stopPropagation();
    let appId, planId;
    const planner = Context.instance.spaceplanner.planner;
    if (plan.versionInfo) {
      planId = plan.versionInfo.versionGuid;
      appId = Planner.getParentAppId(plan);
    } else {
      planId = plan.portalItem.id;
      appId = Planner.getParentAppId(plan.portalItem);
    }
    planner.reloadApp(appId,planId);
  }

  async query(criteria) { 
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned) {
      return this.queryVersioned(criteria);
    } else if (project) {
      return this.queryHosted(criteria);
    }
  }

  async queryHosted(criteria) {
    const plans = [];
    const items = [];
    const itemsById = {};
    await this.queryHostedItems(criteria,items,itemsById,0);
    const lcUser = Context.instance.user.getUsername().toLowerCase();
    const lcText = (criteria && criteria.searchText && criteria.searchText.toLowerCase()) || "";
    items.forEach(item => {
      const info = this.makeInfo(item,false);
      let matches = this.matchesCriteria(info,criteria,lcText,lcUser);
      if (matches) plans.push(info);
    });
    await this.queryUsers(plans);
    this.sortVersioned(plans,criteria);
    return plans;
  }

  async queryHostedItems(criteria,items,itemsById,start) {
    try {
      const portal = Context.instance.getPortal();
      const appItem = Context.instance.configuration.appItem;
      const tkParent = OfficePlan.TypeKeywordParentPfx + appItem.id;
      const tkService = OfficePlan.TypeKeywordFS;
      let query = `(typekeywords: "${tkParent}") AND (typekeywords: "${tkService}")`;
      if (criteria.recentPlanIds) {
        if (criteria.recentPlanIds.length === 0) return;
        const parts = [];
        criteria.recentPlanIds.forEach(id => {
          const part = `(id: "${id}")`;
          parts.push(part);
        });
        query += " AND (" + parts.join(" OR ") + ")";
      }
      const params = {
        query: query,
        start: start,
        num: 100
      }
      const data = await portal.queryItems(params);
      if (data && data.results) {
        data.results.forEach(item => {
          items.push(item);
          itemsById[item.id] = item;
        });
        if (data.nextQueryParams) {
          const nextStart = data.nextQueryParams.start;
          if (typeof nextStart === "number" && nextStart > 1) {
            await this.queryHostedItems(criteria,items,itemsById,nextStart)
          }
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async queryUsers(plans) {
    const usernames = [];
    plans.forEach(plan => {
      const lc = plan.owner && plan.owner.toLowerCase();
      if (lc && (usernames.indexOf(lc) === -1)) {
        usernames.push(lc);
      }
    });
    if (usernames.length > 0) {
      const usernameCache = UsernameCache.instance;
      await usernameCache.load(usernames);
      plans.forEach(plan => {
        const user = usernameCache.getUser(plan.owner);
        const fullname = user && user.fullName;
        plan.ownerFullname = fullname || plan.owner;
        // a user can have a thumbnailUrl
        let initials = "";
        const a = plan.ownerFullname.split(" ");
        a.forEach(c => {
          c = c.trim();
          if (c) initials += c.substring(0,1);
        });
        if (initials) plan.ownerInitials = initials;
        if (user && user.thumbnailUrl) plan.ownerThumbnailUrl = user.thumbnailUrl;
      });
    }
  }

  async queryVersioned(criteria) { 
    const plans = [];
    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const task: any = {};
    const itemsByGuid = {};
    
    await versionManager.readVersionInfos(task);
    const splits = [];
    if (task.versionInfos) {
      const guids = [];
      task.versionInfos.forEach(versionInfo => {
        if (!versionManager.isDefault(versionInfo)) {
          const guid = versionUtil.normalizeGuid(versionInfo.versionGuid);
          if (criteria.recentPlanIds) {
            if (criteria.recentPlanIds.indexOf(versionInfo.versionGuid) !== -1) {
              guids.push(guid);
            }
          } else {
            guids.push(guid);
          }
        }
      })
      const maxPer = 100;
      for (let i=0, j=guids.length; i<j; i+=maxPer) {
        const a = guids.slice(i,i+maxPer);
        if (a.length > 0) {
          splits.push(a)
        }
      }
    }
    await this.queryVersionedItems(itemsByGuid,splits,0);

    if (task.versionInfos) {
      const lcUser = Context.instance.user.getUsername().toLowerCase();
      const lcText = (criteria && criteria.searchText && criteria.searchText.toLowerCase()) || "";
      task.versionInfos.forEach(versionInfo => {
        if (!versionManager.isDefault(versionInfo)) {
          const guid = versionUtil.normalizeGuid(versionInfo.versionGuid);
          const supportService = itemsByGuid[guid];
          const info = this.makeInfo(versionInfo, true, versionManager, supportService);
          let matches = !!supportService;
          if (!matches && info.hasStandaloneAccess) matches = true;
          if (matches) matches = this.matchesCriteria(info,criteria,lcText,lcUser);
          if (matches) plans.push(info);
        }
      })
    }
    await this.queryUsers(plans);
    this.sortVersioned(plans,criteria);
    return plans;
  }

  async queryVersionedItems(itemsByGuid,splits,splitIndex) {
    try {
      const portal = Context.instance.getPortal();
      const appItem = Context.instance.configuration.appItem;
      const tkParent = OfficePlan.VersionedTypeKeywordParentPfx + appItem.id;
      // const tkService = OfficePlan.VersionedTypeKeywordFS;
      let query;
 
      const guids = splits[splitIndex];
      if (guids && guids.length > 0) {
        const parts = [];
        guids.forEach(guid => {
          const tkVersion = OfficePlan.VersionedTypeKeywordVersionPfx + guid;
          const part = `(typekeywords: "${tkVersion}")`;
          parts.push(part);
        })
        const qVersions = "("+parts.join(" OR ")+")";
        const qParent = `(typekeywords: "${tkParent}")`;
        query = qParent+" AND "+qVersions;
      }
      if (query) {
        const params = {
          query: query,
          num: 100
        }
        const data = await portal.queryItems(params);
        if (data && data.results) {
          const pfx = OfficePlan.VersionedTypeKeywordVersionPfx;
          data.results.forEach(item => {
            item.typeKeywords.some(k => {
              if (k && k.startsWith(pfx)) {
                const guid = k.substring(pfx.length);
                if (guid) itemsByGuid[guid] = item;;
                return true;
              }
            })
            return false;
          });
          await this.queryVersionedItems(itemsByGuid,splits,splitIndex + 1);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  sortVersioned(plans,criteria) {
    const property = criteria.sortField;
    let isString = true;
    let isNumber = false;
    if (property === "created" || property === "lastEdited") {
      isString = false;
      isNumber = true;
    }
    plans.sort((planA,planB) => {
      const a = planA[property];
      const b = planB[property];
      if (isString) {
        if (a && b) {
          return a.localeCompare(b);
        } else if(a) {
          return -1;
        } else if(b) {
          return 1;
        }
      } else if (isNumber) {
        if (typeof a === "number" && typeof b === "number") {
          return a - b;
        } else if (typeof a === "number") {
          return -1;
        } else if (typeof b === "number") {
          return 1;
        }
      }
      return 0;
    });
    if (criteria.sortDir === "desc") {
      plans.reverse();
    }
  }

  async updatePlanSharingLevel({ info, assignedGroupIds, id }) {
    const project = Context.instance.spaceplanner.planner.project;
    let {shareIds, unshareIds} = info;
    if (info.access === SharingLevels.ORGANIZATION) {
      shareIds = [];
      unshareIds = assignedGroupIds;
    }
    if (project && project.isVersioned) {
      await groupUtils.updateSharingVersioned(shareIds, unshareIds, info.access, id, info.portalItem, info.versionInfo);
    } else {
      await groupUtils.updateSharing(shareIds, unshareIds, info.access, id, info.portalItem);
    }
  }
}