import React from "react";

import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import DndPrompt from "../components/common/DndPrompt";
import FieldNames from "../../aiim/datasets/FieldNames";
import {ModalController} from "../../common/components/Modal";
import OfficePlan from "./OfficePlan";
import Topic from "../../context/Topic";
import TransactionGuard from "./TransactionGuard";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as officePlanUtil from "./officePlanUtil";
import * as queryUtil from "./queryUtil";
import * as sourceUtil from "./sourceUtil";
import Warning from "../../common/components/Warning/Warning";
import * as transactions from "./transaction/transactions";
import * as highlightUtil from "../base/highlightUtil";
import {
  canDeleteDuplicate,
  shouldSkipModal,
  deleteDuplicatePeople,
  getAttributes,
  getPersonAssignments,
  unassignAdditionalAssignments,
  canSkipModal
} from "../components/common/MultipleAssignments/multipleAssignmentsUtil";

export default class Dnd extends BaseClass {

  _dragInfo;
  _type;

  constructor(props) {
    super(props);
    this._listen();
  }

  _assignPersonToArea(dragInfo, areaItem, areaType) {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    const guard = new TransactionGuard({featureItems: [personFeatureItem]});
    transactions
      .assignPeopleToArea([personFeatureItem], areaItem, areaType)
      .then(result => {
        guard.close();
        this._clearHighlight(dragInfo);
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      })
      .catch(error => {
        guard.close();
        this._clearHighlight(dragInfo);
        console.error("Error assigning person to area", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  // _assignPersonToUnit(dragInfo,unitFeatureItem,unassignOthers) {
  //   const personFeatureItem = dragInfo.subjectParams.featureItem;
  //   const guard = new TransactionGuard({featureItems: [personFeatureItem]});
  //   Promise.resolve()
  //     .then(result => {
  //       return transactions.assignPeopleToUnit([personFeatureItem], unitFeatureItem, unassignOthers);
  //     })
  //     .then(result => {
  //       guard.close();
  //       this._clearHighlight(dragInfo);
  //       Topic.publish(Topic.PlanModified, {
  //         action: OfficePlan.Action_AssignmentsUpdated
  //       });
  //     })
  //     .catch(error => {
  //       guard.close();
  //       this._clearHighlight(dragInfo);
  //       console.error("Error assigning person to unit", error);
  //       Topic.publishErrorUpdatingData(error.submessage);
  //     });
  // };

  _begin(subjectParams) {
    let dragInfo;
    const view = Context.instance.views.mapView;
    highlightUtil.removeHighlight();
    Context.instance.session.isDndActive = true;
    const layerViews = view && view.allLayerViews.toArray();
    const unitsLayer = sourceUtil.getUnitsLayer();
    const viewContainer = document.getElementById("sp-view-container");
    if (view && layerViews && unitsLayer && viewContainer) {
      let asnField = aiimUtil.findField(unitsLayer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
      layerViews.some(lv => {
        if (lv && lv.layer === unitsLayer) {
          dragInfo = {
            subjectParams: subjectParams,
            dropping: false,
            isHtml: !subjectParams.isMoveOccupantPoint,
            layerView: lv,
            oidField: unitsLayer.objectIdField,
            asnField: asnField && asnField.name,
            lastUnit: null,
            prevOid: null,
            highlightHandle: null,
            rect: viewContainer.getBoundingClientRect(),
            stamp: Date.now()
          }
        }
        return !!dragInfo;
      });
    }
    this._dragInfo = dragInfo;
  }

  checkOccupancy(personName,targetOffice) {
    const promise = new Promise((resolve, reject) => {
      let info = {
        peopleNames: null,
      };
      const unit = targetOffice;
      if (unit) {
        officePlanUtil.queryPeopleNamesAssignedToUnits([unit]).then(result => {
          if (result && result.names && result.names.length > 0 &&
              !result.exceededTransferLimit) {
            const subject = personName;
            const peopleNames = result.names.filter(v => {
              return (v !== subject);
            });
            if (peopleNames && peopleNames.length > 0) {
              info.peopleNames = peopleNames;
            }
          }
          resolve(info);
        }).catch(ex => {
          console.error("Error querying people names",ex);
          resolve(info);
        });
      } else {
        resolve(info);
      }
    });
    return promise;
  }

  _clearHighlight(dragInfo) {
    const h = dragInfo && dragInfo.highlightHandle;
    if (h) {
      h.remove();
      dragInfo.highlightHandle = null;
    }
  }

  async _confirm(dragInfo,unitFeatureItem) {
    const i18n = Context.instance.i18n;
    const plan = OfficePlan.getActive();
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    const unitAttributes = unitFeatureItem.feature.attributes;
    const peopleAttributes = personFeatureItem.feature.attributes;
    const unitField = Context.instance.aiim.getUnitName();
    const unitName = aiimUtil.getAttributeValue(unitAttributes,unitField);

    const currentAssignment = await officePlanUtil.getPersonAssignmentNameAsync(personFeatureItem)
    const personName = aiimUtil.getAttributeValue(peopleAttributes,FieldNames.PEOPLE_FULLNAME);

    const pattern = i18n.spaceplanner.assignSubjectToTargetQ;
    let title = i18n.spaceplanner.people.assignTo.unit.captionSingular;
    let prompt = pattern.replace("{subject}",personName).replace("{target}",unitName)
    let targetOffice = unitFeatureItem;

    let areaItem, areaType, areaName, assignToArea = false;
    //const asnType = aiimUtil.getAttributeValue(unitAttributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const areaId = aiimUtil.getAttributeValue(unitAttributes,FieldNames.UNITS_AREA_ID);
    const hasAreaId = (typeof areaId === "string" && areaId.length > 0);

    if (hasAreaId) {
      areaItem = plan.areasTable.getArea(areaId);
      areaType = plan.areasTable.getAreaType(areaId);
      areaName = plan.areasTable.getAreaName(areaId);
      if (areaItem) {
        assignToArea = true;
        targetOffice = null;
        title = i18n.spaceplanner.people.assignTo.workspaceArea.captionSingular;
        prompt = pattern.replace("{subject}",personName).replace("{target}",areaName);
      }
    }
    this._type = assignToArea ? "area" : "unit"

    let unassignOthers = false;
    const onUnassignOthersChanged = checked => {
      unassignOthers = !!checked;
    };

    let notAssignable = false;
    const asnType = unitAttributes[dragInfo.asnField];
    if (asnType === OfficePlan.SpaceAssignmentTypes.notAssignable) {
      notAssignable= true;
    }

    const task = {
      ok: false,
      peopleNames: null
    };
    let checkOccupancy = this.checkOccupancy;

    let unassignAssignments = false
    const onUnassignAssignmentsChanged = (checked) => {
      unassignAssignments = !!checked
    }
    let keepCurrentAssignment = false
    const onCurrentChanged = (checked) => {
      keepCurrentAssignment = !!!checked
    }

    let personAssignments = null
    Promise.resolve().then(() => {
      const personAttributes = getAttributes(personFeatureItem)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      return getPersonAssignments(personName, personEmail)
    }).then(assignments => {
      personAssignments = assignments
    }).then(() => {
      if (shouldSkipModal(personFeatureItem, targetOffice, dragInfo)) {
        checkOccupancy = null;
        return this.checkOccupancy(personName,targetOffice).then(result => {
          task.peopleNames = (result && result.peopleNames) || null;
          if (!task.peopleNames || task.peopleNames.length === 0) {
            if (canSkipModal()) task.ok = true;
          }
        })
      }
    }).then(() => {
      if (!task.ok) {
        const modalOptions = {
          title: title,
        };
        if (notAssignable) {
          modalOptions.okLabel = i18n.general.ok;
          modalOptions.content = i18n.spaceplanner.units.notAssignableMessage;
        } else {
          modalOptions.okLabel = i18n.spaceplanner.buttonLabel.assign
          modalOptions.content = (
            <DndPrompt
              text={prompt}
              personFeature={personFeatureItem}
              personName={personName}
              areaItem={areaItem}
              targetOffice={targetOffice}
              unassignOthers={unassignOthers}
              peopleNames={task.peopleNames}
              checkOccupancy={checkOccupancy}
              onUnassignOthersChanged={onUnassignOthersChanged}
              onUnassignAssignmentsChanged={onUnassignAssignmentsChanged}
              onCurrentChanged={onCurrentChanged}
              assignments={personAssignments}
              currentAssignment={currentAssignment}
              dragInfo={dragInfo} />
          );
        }
        return ModalController.confirm(modalOptions).then(result => {
          task.ok = !!result.ok;
        })
      } else {
        return officePlanUtil
        .queryPeopleAssignedToUnit(targetOffice)
        .then((result) => {
          const assignments = result.length
          const capacity = aiimUtil.getAttributeValue(targetOffice.feature.attributes,FieldNames.UNITS_CAPACITY);
          const chk = (typeof capacity === "number");
          if (chk && (assignments > capacity)) {
            const i18n = Context.instance.i18n;
            let message = i18n.spaceplanner.capacity.atCapacityWarningPerson
            message = message.replace("{name}", personName)
            message = message.replace("{capacity}", capacity)
            message = message.replace("{assignments}", assignments)
            message = assignments === 1 ?
              message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionSingular) :
              message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionPlural)
            const modalOptions = {
              title: title,
              okLabel: i18n.spaceplanner.buttonLabel.assign,
              content: (
                <div style={{maxWidth: '30vw'}}>
                  <Warning key="capacity-warning-dnd" message={message}></Warning>
                </div>
              )
            };
            return ModalController.confirm(modalOptions).then(result => {
              task.ok = !!result.ok;
            })
          }
        })
        .catch((ex) => {
          console.error("Error querying people assigned to unit", ex);
        });
      }
    }).then(() => {
      if (task.ok) {
        if (notAssignable) {
          this._clearHighlight(dragInfo);
          this._callOnInvalidDrop(dragInfo);
        } else if (assignToArea) {
          if (unassignAssignments && keepCurrentAssignment) {
            unassignAdditionalAssignments(personAssignments, personFeatureItem).then(duplicatePeople => {
              this.duplicatePersonToArea(dragInfo, areaItem, areaType, duplicatePeople)
            }).catch(e => {
              this._callOnInvalidDrop(dragInfo);
              console.error("Couldn't remove additional assignments", e)
            })
          } else if (unassignAssignments) {
            unassignAdditionalAssignments(personAssignments, personFeatureItem).then(duplicatePeople => {
              this.assignPersonToArea(dragInfo, areaItem, areaType, duplicatePeople);
            }).catch(e => {
              this._callOnInvalidDrop(dragInfo);
              console.error("Couldn't remove additional assignments", e)
            })
          } else if (keepCurrentAssignment) {
            this.duplicatePersonToArea(dragInfo, areaItem, areaType)
          } else {
            this.assignPersonToArea(dragInfo, areaItem, areaType);
          }
        } else {
          if (unassignAssignments && keepCurrentAssignment) {
            unassignAdditionalAssignments(personAssignments, personFeatureItem).then(duplicatePeople => {
              this.duplicatePersonToUnit(dragInfo, targetOffice, unassignOthers, duplicatePeople)
            }).catch(e => {
              this._callOnInvalidDrop(dragInfo);
              console.error("Couldn't remove additional assignments", e)
            })
          } else if (unassignAssignments) {
            unassignAdditionalAssignments(personAssignments, personFeatureItem).then(duplicatePeople => {
              this.assignPersonToUnit(dragInfo, unitFeatureItem, unassignOthers, duplicatePeople);
            }).catch(e => {
              this._callOnInvalidDrop(dragInfo);
              console.error("Couldn't remove additional assignments", e)
            })
          } else if (keepCurrentAssignment) {
            this.duplicatePersonToUnit(dragInfo, targetOffice, unassignOthers)
          } else {
            this.assignPersonToUnit(dragInfo, unitFeatureItem, unassignOthers);
          }
        }

      } else {
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
      }
    }).catch(ex => {
      this._clearHighlight(dragInfo);
      this._callOnInvalidDrop(dragInfo);
      console.error(ex);
    });
  }

  duplicatePersonToArea = (dragInfo, areaItem, areaType, duplicatePeople)  => {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    let mod = false, guard
    guard = new TransactionGuard({featureItems: [personFeatureItem]});
    return transactions.addPerson(personFeatureItem, null, areaItem, false, duplicatePeople)
      .then(result => {
        if (guard) guard.close();
        this._clearHighlight(dragInfo);
        this._callOnEditsApplied(dragInfo);
        // if (mod) {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
          // this.onAssignment();
        // }
      })
      .catch(error => {
        if (guard) guard.close();
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
        console.error("Error assigning person to area", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  useCentroid(dragInfo,unitFeatureItem,unassignOthers) {
    // only implemented for assignPersonToUnit duplicatePersonToUnit
    const f = unitFeatureItem && unitFeatureItem.feature;
    const asn = f && aiimUtil.getAttributeValue(f.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const isOffice = asn === "office";
    //console.log("useCentroid.asn",asn,"unassignOthers",unassignOthers)
    return !isOffice || !!unassignOthers;
  }

  duplicatePersonToUnit = (dragInfo, unitFeatureItem, unassignOthers, duplicatePeople) => {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    let mod = false, guard
    this.ensureUnitGeometry(unitFeatureItem)
      .then(() => {
        if (this.canAssign([unitFeatureItem], "assignToPerson")) {
          mod = true;
          guard = new TransactionGuard({featureItems: [personFeatureItem]});
          const useDropPoint = !this.useCentroid(dragInfo,unitFeatureItem,unassignOthers);
          return transactions.addPerson(personFeatureItem, unitFeatureItem, null,
            unassignOthers, duplicatePeople, useDropPoint && dragInfo.dropOccupantGeometry)
        }
      })
      .then(result => {
        if (guard) guard.close();
        this._clearHighlight(dragInfo);
        if (mod) {
          this._callOnEditsApplied(dragInfo);
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
          // this.onAssignment();
        } else {
          this._callOnInvalidDrop(dragInfo);
        }
      })
      .catch(error => {
        if (guard) guard.close();
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  assignPersonToUnit = (dragInfo, unitFeatureItem, unassignOthers, duplicatePeople) => {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    let guard
    Promise.resolve()
      .then(result => {
        guard = new TransactionGuard({featureItems: [personFeatureItem], minimal: true});
        const useDropPoint = !this.useCentroid(dragInfo,unitFeatureItem,unassignOthers);
        return transactions.assignPeopleToUnit(
          [personFeatureItem],
          unitFeatureItem,
          unassignOthers,
          duplicatePeople,
          useDropPoint && dragInfo.dropOccupantGeometry
        );
      })
      .then(result => {
        guard.close();
        this._clearHighlight(dragInfo);
        this._callOnEditsApplied(dragInfo);
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      })
      .catch(error => {
        guard.close();
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  assignPersonToArea = (dragInfo, areaItem, areaType, duplicatePeople) => {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    let guard
    Promise.resolve()
      .then(result => {
        guard = new TransactionGuard({featureItems: [personFeatureItem]});
        return transactions.assignPeopleToArea(
          [personFeatureItem],
          areaItem,
          areaType,
          duplicatePeople
        );
      })
      .then(result => {
        guard.close();
        this._clearHighlight(dragInfo);
        this._callOnEditsApplied(dragInfo);
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      })
      .catch(error => {
        guard.close();
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  movePersonWithinUnit = (dragInfo,unitFeatureItem,occupantGeometry) => {
    const personFeatureItem = dragInfo.subjectParams.featureItem;
    let guard
    Promise.resolve().then(result => {
      guard = new TransactionGuard({
        featureItems: [personFeatureItem],
        minimal: true
      });
      return transactions.moveOccupantWithinUnit(
        personFeatureItem,
        unitFeatureItem,
        occupantGeometry
      );
    }).then(result => {
      const wasMoveWithinUnit = true;
      guard.close();
      this._clearHighlight(dragInfo);
      this._callOnEditsApplied(dragInfo,wasMoveWithinUnit);
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated,
        wasMoveWithinUnit: true
      });
    }).catch(error => {
      guard.close();
      this._clearHighlight(dragInfo);
      this._callOnInvalidDrop(dragInfo);
      console.error("Error moving occupant within unit", error);
      Topic.publishErrorUpdatingData(error.submessage);
    });
  }

  checkForDuplicatePeople = (peopleFeatures, baseGuard) => {
    if (!peopleFeatures) return Promise.resolve()
    let promises = [], duplicates = [], singleRecords = []
    peopleFeatures.forEach(person => {
      const attributes = getAttributes(person)
      const personName = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
      const promise = canDeleteDuplicate(personName, personEmail).then(result => {
        if (result) duplicates.push(person)
        else singleRecords.push(person)
      })
      promises.push(promise)
    })
    return Promise.all(promises).then(() => {
      if (duplicates && duplicates.length > 0) {
        const type = this._type
        if (type === "area") {
          duplicates = duplicates.map(p => {return {person: p, associatedUnit: null, associatedArea: null}})
          deleteDuplicatePeople(duplicates, true, true, baseGuard)
        } else if (type === "unit") {
          duplicates = duplicates.map(p => {return {person: p, associatedUnit: null, associatedArea: null}})
          deleteDuplicatePeople(duplicates, true, true, baseGuard)
        }
      }
      if (singleRecords && singleRecords.length > 0) this.deletePeople(singleRecords, baseGuard)
    })
  }

  deletePeople = (peopleItems, baseGuard) => {
    let guard = null
    if (!baseGuard) guard = new TransactionGuard({force: true, minimal: true});
    transactions
      .unassignPeople(peopleItems)
      .then((result) => {
        if (guard) {
          guard.close();
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
          });
        }
      })
      .catch((error) => {
        if (guard) guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  ensureUnitGeometry = unitFeatureItem => {
    const source = officePlanUtil.getUnitsSource();
    return queryUtil.ensureGeometry(source, unitFeatureItem);
  };

  canAssign = (unitFeatureItems, type) => {
    if (type === "assignToPerson") {
      return unitFeatureItems && unitFeatureItems.length === 1;
    } else {
      return (
        unitFeatureItems &&
        unitFeatureItems.length > 0 &&
        unitFeatureItems.length <= this.getMaxFeatureItems()
      );
    }
  };

  _callOnEditsApplied(dragInfo) {
    const cb = dragInfo && dragInfo.subjectParams && dragInfo.subjectParams.onEditsApplied;
    if (cb) cb(dragInfo);
  }

  _callOnConfirmingDrop(dragInfo) {
    const cb = dragInfo && dragInfo.subjectParams && dragInfo.subjectParams.onConfirmingDrop;
    if (cb) cb(dragInfo);
  }

  _callOnInvalidDrop(dragInfo) {
    const cb = dragInfo && dragInfo.subjectParams && dragInfo.subjectParams.onInvalidDrop;
    if (cb) cb(dragInfo);
  }

  _end(subjectParams) {
    const dragInfo = this._dragInfo;
    if (dragInfo && !dragInfo.dropping) {
      this._clearHighlight(dragInfo);
    }
    Context.instance.session.isDndActive = false;
    this._dragInfo = undefined;
  }

  _handleDrop(dragInfo,unit,mapPoint) {
    //console.log("Dnd::handleDrop.unit",unit)
    if (!unit) {
      this._clearHighlight(dragInfo);
      this._callOnInvalidDrop(dragInfo);
      console.warn("Dnd::handleDrop, no unit was located at the dnd drop point.");
      return;
    }
    const source = sourceUtil.getUnitsSource();
    const featureItem = queryUtil.makeFeatureItem(source,unit);
    const occupantGeometry = this._makeDropOccupantGeometry(dragInfo,mapPoint);
    dragInfo.dropOccupantGeometry = occupantGeometry;

    const isMoveWithin = this._isMoveFromUnit(dragInfo,featureItem.objectId);
    if (isMoveWithin) {
      this._callOnConfirmingDrop(dragInfo);
      this.movePersonWithinUnit(dragInfo,featureItem,dragInfo.dropOccupantGeometry);
      return;
    }

    queryUtil.queryGeometry(source,featureItem).then(result => {
      //console.log("Dnd::handleDrop.result",result);
      if (result.features && result.features.length > 0) {
        const feature = result.features[0];
        const unitFeatureItem = queryUtil.makeFeatureItem(source,feature);
        this._callOnConfirmingDrop(dragInfo)
        this._confirm(dragInfo,unitFeatureItem)
      } else {
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
        console.warn("Dnd::handleDrop, no unit was fetched at the dnd drop point.");
      }
    }).catch(ex => {
      this._clearHighlight(dragInfo);
      this._callOnInvalidDrop(dragInfo);
      console.warn("Dnd::handleDrop, unable to fetch unit");
      console.error(ex);
    });
  }

  _isMoveFromUnit(dragInfo,oid) {
    if (dragInfo && dragInfo.subjectParams && dragInfo.subjectParams.isMoveOccupantPoint) {
      return (oid === dragInfo.subjectParams.fromUnitOid);
    }
    return false;
  }

  _listen() {
    // Topic.subscribe(Topic.PlanOpened,params => {
    //   this._init();
    //   this._watchMap();
    // })
    //
    Topic.subscribe(Topic.DragItemStart,subjectParams => {
      this._begin(subjectParams);
    })

    Topic.subscribe(Topic.DragItemEnd,subjectParams => {
      //console.log("ending....................................")
      this._end(subjectParams);
    })
  }

  _makeDropOccupantGeometry(dragInfo,mapPoint) {
    let geom, z = 0;
    if (dragInfo.subjectParams.isMoveOccupantPoint) {
      const feature = dragInfo.subjectParams.featureItem.feature;
      if (feature.geometry) {
        z = feature.geometry.z;
      }
      if (typeof mapPoint.clone === "function") {
        geom = mapPoint.clone().toJSON();
      } else {
        geom = JSON.parse(JSON.stringify(mapPoint));
      }
      geom.z = z;
    }
    return geom;
  }

  _processMapEvent(evt,type) {
    const dragInfo = this._dragInfo;
    if (!dragInfo) return;
    if (!dragInfo.subjectParams.sourceKey === "People") return;
    const {layerView, oidField, lastUnit, rect} = dragInfo;
    const isMoveOccupantPoint = dragInfo.subjectParams && dragInfo.subjectParams.isMoveOccupantPoint;
    const hadHitTest = dragInfo.hadHitTest;

    if (type ==="drag") {
      if (lastUnit) {
        if (dragInfo.isHtml) {
          //evt.dataTransfer.dropEffect = "copy";
          evt.preventDefault(); // signifies that the subject can be dropped
        }
        const oid = lastUnit.attributes[oidField];
        if (oid !== dragInfo.prevOid) {
          this._clearHighlight(dragInfo);
          dragInfo.prevOid = oid;
          dragInfo.highlightHandle = layerView.highlight([lastUnit]);
          // const isMoveWithin = this._isMoveFromUnit(dragInfo,oid);
          // if (!isMoveWithin) {
          //   dragInfo.highlightHandle = layerView.highlight([lastUnit]);
          // }
        }
        if (isMoveOccupantPoint) this._setCursor("grabbing");
      } else {
        dragInfo.prevOid = null;
        this._clearHighlight(dragInfo);
        if (isMoveOccupantPoint && hadHitTest) this._setCursor("no-drop");
      }
    } else {
      dragInfo.dropping = true;
      // setTimeout(() => {
      //   this._clearHighlight(dragInfo);
      // },2000);
    }

    let x, y;
    if (evt.useScreenPoint) {
      x = evt.screenPoint.x;
      y = evt.screenPoint.y;
    } else {
      x = evt.pageX - rect.x;
      y = evt.pageY - rect.y;
    }
    const mapPoint = evt.mapPoint;
    //const stamp = Date.now();
    layerView.hitTest(mapPoint, { x: x, y: y }).then(res => {
      res = Context.instance.aiim.fixHitTestResult(res); 
      let unit = res && res[0] && res[0].graphic;

      if (unit) {
        const asnType = unit.attributes[dragInfo.asnField];
        // console.log("asnType=====",dragInfo.asnField,asnType,unit.attributes)
        if (asnType === OfficePlan.SpaceAssignmentTypes.notAssignable) {
          unit = null;
        }
      }

      if (type === "drag") {
        //if (stamp < dragInfo.stamp) return;
        //dragInfo.stamp = stamp
        dragInfo.lastUnit = unit;
        dragInfo.hadHitTest = true;
      } else if (type === "drop") {
        this._handleDrop(dragInfo,unit,mapPoint);
      }
    }).catch(ex => {
      if (type === "drop") {
        this._clearHighlight(dragInfo);
        this._callOnInvalidDrop(dragInfo);
      }
      console.warn("Dnd::hitTest, error finding unit");
      console.error(ex);
    });
  }

  _setCursor(cursor) {
    const view = Context.instance.views.mapView;
    if (view && view.cursor !== cursor) {
      view.cursor = cursor;
    }
  }

  whenDragOverMapContainer(evt) {
    const dragInfo = this._dragInfo;
    if (!dragInfo) return;
    if(dragInfo.lastMove){
      if(Date.now() - dragInfo.lastMove < 100) {
        if (dragInfo.lastUnit) {
          if (dragInfo.isHtml) {
            evt.preventDefault();
          }
        }
        return;
      }
    }
    dragInfo.lastMove = Date.now();
    this._processMapEvent(evt,"drag");
  }

  whenDropOntoMapContainer(evt) {
    //console.log("dropping>>>>>>>>>>>>>>>>>>>>>>>>>>")
    this._processMapEvent(evt,"drop");
  }

}
