import React, { useState } from 'react';
import FieldNames from '../../aiim/datasets/FieldNames';
import * as aiimUtil from '../../aiim/util/aiimUtil';
import Context from '../../context/Context';
import { getName } from './replacePlaceholders';
import * as sourceUtil from "./sourceUtil";

export const ReplacePlaceholder = ({occupants, placeholders, onChange}) => {
  
  const [data, setData] = useState({
    delPlaceholders: [],
    checked: {}
  });
  const layer = sourceUtil.getPeopleLayer();
  const emailField = aiimUtil.findFieldName(layer.fields, FieldNames.PEOPLE_EMAIL);
  const knownasField = aiimUtil.findFieldName(layer.fields, FieldNames.PEOPLE_FULLNAME);
  const i18n = Context.instance.i18n;
  const isRtl = Context.instance.uiMode.isRtl;
  
  const {delPlaceholders, checked} = data;
  
  const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const index = occupants.findIndex((f: __esri.Graphic) => f.attributes[emailField] === e.target.id);
    if (index !== -1) {
      checked[e.target.id] = e.target.checked;
      const delIndex = delPlaceholders.findIndex((f: __esri.Graphic) => getName(f) === e.target.name.trim().toLowerCase());
      if (!checked[e.target.id] && delIndex !== -1) {
        delPlaceholders.splice(delIndex, 1);
      } else {
        const plhIndex = placeholders.findIndex((f: __esri.Graphic) => getName(f) === e.target.name.trim().toLowerCase());
        const placeholder = placeholders[plhIndex];
        delPlaceholders.push(placeholder);
      }
    }
    
    onChange(checked, delPlaceholders);
  }
  
  return (
    <>
      <div className="i-radio-item-replacePlaceholders">
      <div className="i-placeholder-description">
        <p>{i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.description}</p>
      </div>
      {occupants && occupants.map((f: __esri.Graphic) => {
        const knownas = f.attributes[knownasField];
        const email = f.attributes[emailField];
        
        return (
          <React.Fragment key={email}>
            <div className='i-placeholder-item'>
              <input type="checkbox"
                className="i-placeholders-checkbox-row"
                id={email}
                name={knownas}
                onChange={onClick}
                style={isRtl ? {marginLeft: '10px'} : {marginRight: '10px'}}
              />
              <div>
                <span>{knownas}</span>
                {email && <span>{email}</span>}
              </div>
            </div>
            {placeholders.length > 1 && <hr className='i-placeholder-divider' />}
          </React.Fragment>
        )
      })}
      </div>
    </>
  )
}
