import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import * as mapUtil from "../../../base/mapUtil";
import * as stencilUtil from "./stencilUtil";

export default class StencilMover extends BaseClass {

  _moverLayerId = "indoors-mover-layer";
  _pointerMoveHandle;
  _snappingContext;
  _snappingGraphicsLayer;
  _snappingGraphicsLayerId = "indoors-mover-snapping-layer";
  _snappingTask;

  clear() {
    this.clearSnappingTask();
    const view = mapUtil.getView();
    mapUtil.removeAllGraphics(view,this._moverLayerId);
    mapUtil.removeAllGraphics(view,this._snappingGraphicsLayerId);
    if (this._pointerMoveHandle) {
      this._pointerMoveHandle.remove();
      this._pointerMoveHandle = null;
    }
    // if (isSome(this._snappingGraphicsLayer)) {
    //   this.view.map.layers.remove(this._snappingGraphicsLayer);
    //   this._snappingGraphicsLayer.destroy();
    // }
    // this._editGeometryOperations = destroyMaybe(this._editGeometryOperations);
    // this._snappingTask = abortMaybe(this._snappingTask);
  }

  clearSnappingTask() {
    if (this._snappingTask) {
      this._snappingTask.abort();
      this._snappingTask = null;
    }
  }

  /*
    https://devtopia.esri.com/WebGIS/arcgis-js-api/blob/4master/esri/views/draw/support/GraphicMover.ts

    https://devtopia.esri.com/WebGIS/arcgis-js-api/blob/6635c65bf5b30a9d6ad8ce2a66751d9d250d9043/esri/views/draw/support/Reshape.ts
  */

  async getGraphicMover(sketchViewModel,activeItem,graphics,options,view) {
    //console.log("getGraphicMover.init...")
    this.clear();
    const ge = Context.instance.lib.esri.geometryEngine;
    const svm = sketchViewModel;
    const { enableMoveAllGraphics } = options;

    const cen = activeItem.graphic.geometry.extent.center.clone();
    let rotationAngle = activeItem.rotationAngle;
    let reflect = activeItem.reflect;
    let flip = activeItem.flip;
    let anchor1, anchorGraphic, ptStart, stagedAnchor;

    if (activeItem && activeItem.anchorPoint1) {
      anchor1 = activeItem.anchorPoint1.clone();
      anchorGraphic = this.highlightAnchor(anchor1);
    } else {
      anchor1 = activeItem.graphic.geometry.extent.center.clone();
    }
    this.newSnappingContext(activeItem.stencil,anchor1,activeItem.graphic,view);

    const makeNewAnchor = (event) => {
      const cen2 = event.graphic.geometry.extent.center;
      let dx2 = cen2.x - cen.x;
      let dy2 = cen2.y - cen.y;
      if (event && ptStart) {
        const pt = view.toMap({x: event.x, y: event.y})
        dx2 = pt.x - ptStart.x;
        dy2 = pt.y - ptStart.y;
      }
      const newX = anchor1.x + dx2;
      const newY = anchor1.y + dy2;
      const newAnchor = new Context.instance.lib.esri.Point({
        x: newX,
        y: newY,
        spatialReference: anchor1.spatialReference
      });
      if (anchorGraphic) anchorGraphic.geometry = newAnchor;
      return newAnchor;
    }

    const translate = (newAnchorPoint) => {
      const stencilProps = Object.assign(activeItem,{});
      stencilProps.rotationAngle = rotationAngle;
      stencilProps.reflect = reflect;
      stencilProps.flip = flip;
      let insitu = stencilUtil.insitu(activeItem);
      let dx = newAnchorPoint.x - insitu.anchor1.x;
      let dy = newAnchorPoint.y - insitu.anchor1.y;
      let translated = stencilUtil.translateInsitu(stencilProps,insitu,dx,dy)
      return translated.geometry;
    }

    let lastMove = Date.now();
    const mover = new Context.instance.lib.esri.GraphicMover({
      enableMoveAllGraphics,
      highlightsEnabled: true,
      indicatorsEnabled: false,
      graphics,
      view,
      callbacks: {

        // onGraphicClick: (event) => {},

        onGraphicMoveStart: (event) => {
          let { dx, dy, graphic } = event;
          ptStart = view.toMap({x: event.x, y: event.y})

          svm._displayGrabbingCursor();
          svm.emit("update", {
            graphics: svm.updateGraphics.toArray(),
            state: "active",
            aborted: false,
            tool: svm.activeTool,
            toolEventInfo: { dx, dy, mover: graphic, type: "move-start" },
            type: "update"
          });
        },

        onGraphicMove: async (event) => {
          let { dx, dy, graphic } = event;
          this.clearSnappingTask()
          const sm = svm.snappingManager;
          const sc = this._snappingContext;
          if (sm) {
            const newAnchor = makeNewAnchor(event);
            graphic.geometry = translate(newAnchor);
            stagedAnchor = sm.update({point: newAnchor, context: sc});

            if (lastMove && ((Date.now() - lastMove) > 200)) {
              lastMove = Date.now();
              this._snappingTask = Context.instance.lib.esri.asyncUtils.createTask(async (signal) => {
                const snapResult = await sm.snap({point: newAnchor, context: sc, signal: signal});
                if (snapResult.valid) {
                  const snappedPoint = snapResult.apply();
                  const stencil = activeItem.stencil;
                  const screenPoint = view.toScreen(snappedPoint);
                  const segments = await stencilUtil.queryHit(snappedPoint,screenPoint)
                  const tol = this.getTolerance();
                  if (segments && segments[0] && segments[0].d < tol) {
                    stagedAnchor = snappedPoint;
                    const offsetResult = stencilUtil.offsetStencilGeometry(snappedPoint,stencil,segments,activeItem.z,{
                      rotationAngle: rotationAngle,
                      reflect: reflect,
                      flip: flip
                    });
                    rotationAngle = offsetResult.rotationAngle;
                    //reflect = false;
                    //flip = false;
                    graphic.geometry = offsetResult.geometry;
                    lastMove = Date.now();
                  } else {
                    lastMove = Date.now();
                  }
                }
              })
            }

          }

          svm.emit("update", {
            graphics: svm.updateGraphics.toArray(),
            state: "active",
            aborted: false,
            tool: svm.activeTool,
            toolEventInfo: { dx, dy, mover: graphic, type: "move" },
            type: "update"
          })
        },

        onGraphicMoveStop: async (event) => {
          let { dx, dy, graphic } = event;
          const sm = svm.snappingManager;
          const sc = this._snappingContext;
          let newAnchor = makeNewAnchor(event);
          if (sm && sc && stagedAnchor) {
            newAnchor = sm.update({point: newAnchor, context: sc});
            //console.log("sa.x",stagedAnchor.x,newAnchor.x,"sa.y",stagedAnchor.y,newAnchor.y)
          }
          graphic.geometry = translate(newAnchor);
          const stencil = activeItem.stencil;
          const screenPoint = view.toScreen(newAnchor);
          const segments = await stencilUtil.queryHit(newAnchor,screenPoint)
          const offsetResult = stencilUtil.offsetStencilGeometry(newAnchor,stencil,segments,activeItem.z,{
            rotationAngle: rotationAngle,
            reflect: reflect,
            flip: flip
          });
          svm._displayPointerCursor();
          svm.emit("update", {
            graphics: svm.updateGraphics.toArray(),
            state: "active",
            aborted: false,
            tool: svm.activeTool,
            toolEventInfo: { dx, dy, mover: graphic, type: "move-stop" },
            type: "update",
            xtnOffsetResult: offsetResult
          });
          this.clear();
        },

        onGraphicPointerOver: () => svm._displayPointerCursor(),
        onGraphicPointerOut: () => svm._displayDefaultCursor()
      }
    });

    return mover;
  }

  getTolerance() {
    return stencilUtil.getTolerance(); // 0.1; // 0.01
  }

  highlightAnchor(anchor) {
    const view = mapUtil.getView()
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._moverLayerId);
    let symbol = stencilUtil.makeAnchorSymbol();
    let graphic = new Context.instance.lib.esri.Graphic({
      attributes: {},
      geometry: anchor,
      symbol: symbol
    });
    graphicsLayer.removeAll();
    graphicsLayer.add(graphic);
    return graphic;
  }

  async lastOffsetResult(view,event,stencil) {
    let lastOR = this._offsetResult;
    let point = event.graphic.geometry;
    if (point.type === "polygon") point = point.centroid;
    // @ts-ignore
    const screenPoint = view.toScreen(point);
    const segments = await stencilUtil.queryHit(point,screenPoint)
    if (!lastOR || (segments && segments.length > 0)) {
      let prev;
      if (!lastOR && (!segments || segments.length == 0)) {
        const viewAngle = view.rotation;
        if (typeof viewAngle === "number" && !isNaN(viewAngle) && isFinite(viewAngle) && viewAngle > 0) {
          prev = {
            rotationAngle: viewAngle,
            reflect: false,
            flip: false
          }
        }
      }
      lastOR = stencilUtil.offsetStencilGeometry(point,stencil,segments,0,prev);
    }
    return lastOR;
  }

  newSnappingContext(stencil,anchor,excludeFeature,view) {
    const cen = stencil.geometry.extent.center.clone();
    const viewingModeLocal = 2;
    const egops = Context.instance.lib.esri.EditGeometryOperations.fromGeometry(anchor,viewingModeLocal)
    const sgl = mapUtil.ensureGraphicsLayer(view,this._snappingGraphicsLayerId);
    this._snappingContext = new Context.instance.lib.esri.SnappingContext({
      editGeometryOperations: egops,
      elevationInfo: { mode: "on-the-ground", offset: 0 },
      pointer: "mouse", // event.viewEvent?.pointerType || "mouse"
      excludeFeature: excludeFeature,
      visualizer: new Context.instance.lib.esri.SnappingVisualizer2D(sgl),
      //vertexHandle: this._getVertexFromEditGeometry(handleGraphic)
    });
  }

  setupCreate(sketchViewModel,stencil,view) {
    this.clear();
    const ge = Context.instance.lib.esri.geometryEngine;
    const svm = sketchViewModel;
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._moverLayerId);
    graphicsLayer.removeAll();
    let rotationAngle = null, reflect = false, flip = false, z = 0, stagedAnchor;
    const activeItem = stencilUtil.newStencilItem({
      stencil: stencil,
      graphic: null,
      anchorPoint1: null,
      rotationAngle: 0,
      z: z
    });

    const translate = (newAnchorPoint) => {
      if (!this._offsetResult) {
        const viewAngle = view.rotation;
        if (typeof viewAngle === "number" && !isNaN(viewAngle) && isFinite(viewAngle) && viewAngle > 0) {
          rotationAngle = viewAngle;
        }
      }
      const stencilProps = Object.assign(activeItem,{});
      stencilProps.rotationAngle = rotationAngle;
      stencilProps.reflect = reflect;
      stencilProps.flip = flip;
      let insitu = stencilUtil.insitu(activeItem);
      let dx = newAnchorPoint.x - insitu.anchor1.x;
      let dy = newAnchorPoint.y - insitu.anchor1.y;
      let translated = stencilUtil.translateInsitu(stencilProps,insitu,dx,dy)
      return translated.geometry;
    }

    const sm = svm.snappingManager;
    let graphic, lastMove = Date.now();

    this._pointerMoveHandle = view.on("pointer-move",(event) => {
      const screenPoint = {x: event.x, y: event.y};
      const newAnchor = view.toMap(screenPoint);
      const geometry = translate(newAnchor);
      const symbol = stencilUtil.makeLineSymbol();
      if (!graphic) {
        graphic = new Context.instance.lib.esri.Graphic({
          attributes: {},
          geometry: geometry,
          symbol: symbol
        });
        graphicsLayer.add(graphic)
      } else {
        graphic.geometry = geometry;
      }
      const lastOR = this._offsetResult;
      if (lastOR) {
        lastOR.anchorPoint1 = newAnchor;
        lastOR.geometry = geometry.clone();
      }

      let sc = this._snappingContext;
      if (sm && !sc) {
        this.newSnappingContext(stencil,newAnchor,graphic,view);
        sc = this._snappingContext;
      }
      if (sm && sc) {
        this.clearSnappingTask();
        if (lastMove && ((Date.now() - lastMove) > 200)) {
          lastMove = Date.now();
          this._snappingTask = Context.instance.lib.esri.asyncUtils.createTask(async (signal) => {
            const snapResult = await sm.snap({point: newAnchor, context: sc, signal: signal});
            if (snapResult.valid) {
              const snappedPoint = snapResult.apply();
              const screenPoint = view.toScreen(snappedPoint);
              const segments = await stencilUtil.queryHit(snappedPoint,screenPoint)
              const tol = this.getTolerance();
              if (segments && segments[0] && segments[0].d < tol) {
                stagedAnchor = snappedPoint;
                const prev = !stencil.anchor2 ? {rotationAngle} : null;
                const offsetResult = stencilUtil.offsetStencilGeometry(snappedPoint,stencil,segments,z,prev);
                rotationAngle = offsetResult.rotationAngle;
                reflect = false;
                flip = false;
                graphic.geometry = offsetResult.geometry;
                this._offsetResult = offsetResult;
                lastMove = Date.now();
              } else {
                lastMove = Date.now();
              }
            }
          })
        }

      }
    })
  }

}