export function append(layers,tables,options) {
  const layer = commentsLayer();
  if (options && options.unitsLayerInfo) {
    const info = options.unitsLayerInfo;
    if(info && info.extent && info.extent.spatialReference) {
      const ext = JSON.parse(JSON.stringify(info.extent));
      layer.extent = ext;
      layer.adminLayerInfo.geometryField.srid = ext.spatialReference.wkid;
    } 
  }
  layers.push(layer);
}

function commentsLayer() {

  // updateDefinition: {"editorTrackingInfo":{"enableEditorTracking":true}}

  /*
  "useStandardizedQueries": true,
  "standardMaxRecordCount": 16000,
  "tileMaxRecordCount": 4000,
  "standardMaxRecordCountNoGeometry": 16000,
  "maxRecordCountFactor": 1,
   */
  const def = {
    "name": "Comments",
    "type": "Feature Layer",
    "objectIdField": "objectid",
    "globalIdField": "globalid",
    "displayField": "comment",
    "typeIdField": "",
    "capabilities": "Query,Editing,Create,Update,Delete,Extract",
    "adminLayerInfo": {
      "geometryField": {
        "name": "Shape",
        "srid": 102100
      }
    },
    "defaultVisibility": true,
    "minScale": 0,
    "maxScale": 0,
    "geometryType": "esriGeometryPoint",
    "description": "",
    "copyrightText": "",
    "relationships": [],
    "maxRecordCount": 2000,
    "allowGeometryUpdates": true,
    "enableZDefaults": true,
    "zDefault": 0,
    "hasGeometryProperties": true,
    "hasM": false,
    "hasZ": true,
    "hasAttachments": false,
    "htmlPopupType": "esriServerHTMLPopupTypeAsHTMLText",
    "dateFieldsTimeReference": {
      "timeZone": "UTC",
      "respectsDaylightSaving": false
    },
    "extent" : {
      "xmin" : -20037507.0671618,
      "ymin" : -30240971.9583862,
      "xmax" : 20037507.0671618,
      "ymax" : 18398924.324645,
      "spatialReference" : {
        "wkid" : 102100,
        "latestWkid" : 3857
      }
    },

      "drawingInfo": {
        "renderer": {
          "type": "simple",
          "symbol": {
            "type": "esriSMS",
            "color": [0, 77, 76, 255],
            "angle": 0,
            "xoffset": 0,
            "yoffset": 8,
            "size": 24,
            "style": "esriSMSPath",
            // "path": "M16,21.65h0v-16h0C15.72-1.83.34-1.94,0,5.65H0v16H0l8,8,8-8ZM8,5.15C13,5.08,13.11,13,8,13,2.92,13.06,3,5,8,5.15Z",
            // JSAPI 4.22 update - There is formatting validation on svg paths so the above path will no longer
            // work. It has been updated to this format for it to work. For existing plans with the incorrect symbol path
            // we will programmatically set the path to the correct format on opening the plan.
            "path": "M 16,21.65 h 0 v -16 h 0 C 15.72,-1.83 0.34,-1.94 0,5.65 H 0 v 16 H 0 l 8,8 l 8,-8 Z M 8,5.15 C 13,5.08 13.11,13 8,13 C 2.92,13.06 3,5 8,5.15 Z",
            "outline": {
              "type": "esriSLS",
              "color": [255, 255, 255, 255],
              "width": 1,
              "style": "esriSLSSolid"
            }
          }
        },
        "scaleSymbols": true,
        "transparency": 0
        // "labelingInfo": [
        //   {
        //     "labelExpressionInfo": {
        //       "expression": "$feature.OBJECTID"
        //     },
        //     "labelPlacement": "esriServerPointLabelPlacementCenterCenter",
        //     "deconflictionStrategy": "static",
        //     "allowOverlapOfFeatureInterior": "avoid",
        //     "stackLabel": true,
        //     "stackRowLength": 24,
        //     "stackAlignment": "dynamic",
        //     "removeDuplicates": "none",
        //     "useCodedValues": true,
        //     "maxScale": 0,
        //     "minScale": 0,
        //     "name": "Class 1",
        //     "priority": -1,
        //     "symbol": {
        //       "type": "esriTS",
        //       "color": [
        //         255,
        //         255,
        //         255,
        //         255
        //       ],
        //       "backgroundColor": null,
        //       "borderLineColor": null,
        //       "borderLineSize": null,
        //       "verticalAlignment": "bottom",
        //       "horizontalAlignment": "left",
        //       "rightToLeft": false,
        //       "angle": 0,
        //       "xoffset": 0,
        //       "yoffset": 0,
        //       "kerning": true,
        //       "haloColor": null,
        //       "haloSize": null,
        //       "font": {
        //         "family": "Tahoma",
        //         "size": 10,
        //         "style": "normal",
        //         "weight": "normal",
        //         "decoration": "none"
        //       }
        //     }
        //   }
        // ]
      },
    "fields": [{
        "name": "objectid",
        "type": "esriFieldTypeOID",
        "alias": "OBJECTID",
        "domain": null,
        "nullable": false,
        "defaultValue": null,
        "editable": false
      },
      {
        "name": "globalid",
        "type": "esriFieldTypeGlobalID",
        "alias": "GlobalID",
        "domain": null,
        "editable": false,
        "nullable": false,
        "length": 38,
        "defaultValue": null
      },
      {
        "name": "comment",
        "type": "esriFieldTypeString",
        "alias": "COMMENT",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 4000,
        "defaultValue": null
      },
      {
        "name": "author",
        "type": "esriFieldTypeString",
        "alias": "AUTHOR",
        "domain": null,
        "editable": true,
        "nullable": true,
        "length": 128,
        "defaultValue": null
      },
      {
        "name": "author_date",
        "type": "esriFieldTypeDate",
        "alias": "AUTHOR_DATE",
        "domain": null,
        "editable": true,
        "nullable": true,
        "length": 8,
        "defaultValue": null
      },
      {
        "name": "plan_id",
        "type": "esriFieldTypeString",
        "alias": "PLAN_ID",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 255,
        "defaultValue": null
      },
      {
        "name": "parent_cid",
        "type": "esriFieldTypeInteger",
        "alias": "PARENT_CID",
        "domain": null,
        "editable": true,
        "nullable": true,
        "defaultValue": null
      },
      {
        "name": "level_id",
        "type": "esriFieldTypeString",
        "alias": "LEVEL_ID",
        "domain": null,
        "editable": true,
        "nullable": true,
        "length": 255,
        "defaultValue": null
      },
      {
        "name": "facility_id",
        "type": "esriFieldTypeString",
        "alias": "FACILITY_ID",
        "domain": null,
        "editable": true,
        "nullable": true,
        "length": 255,
        "defaultValue": null
      }
    ],
    "types": [],
    "templates": [{
      "name": "Comments",
      "description": "",
      "prototype": {
        "attributes": {
          "comment": null,
          "plan_id": null,
          "parent_cid": null
        }
      },
      "drawingTool": "esriFeatureEditToolPoint"
    }]
  };

  return def;
}

function repliesTable() {
  const def = {
    "name": "Replies",
    "type": "Table",
    "objectIdField": "objectid",
    "globalIdField": "globalid",
    "displayField": "parent_cid",
    "typeIdField": "",
    "capabilities": "Query,Editing,Create,Update,Delete,Extract",
    "description": "",
    "copyrightText": "",
    "relationships": [],
    "maxRecordCount": 2000,
    "allowGeometryUpdates": true,
    "hasAttachments": false,
    "htmlPopupType": "esriServerHTMLPopupTypeNone",
    "dateFieldsTimeReference": {
      "timeZone": "UTC",
      "respectsDaylightSaving": false
    },
    "fields": [{
        "name": "objectid",
        "type": "esriFieldTypeOID",
        "alias": "OBJECTID",
        "domain": null,
        "nullable": false,
        "defaultValue": null,
        "editable": false
      },
      {
        "name": "globalid",
        "type": "esriFieldTypeGlobalID",
        "alias": "GlobalID",
        "domain": null,
        "editable": false,
        "nullable": false,
        "length": 38,
        "defaultValue": null
      },
      {
        "name": "parent_cid",
        "type": "esriFieldTypeInteger",
        "alias": "PARENT_CID",
        "domain": null,
        "editable": true,
        "nullable": false,
        "defaultValue": null
      },
      {
        "name": "reply",
        "type": "esriFieldTypeString",
        "alias": "REPLY",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 4000,
        "defaultValue": null
      },
      {
        "name": "planid",
        "type": "esriFieldTypeString",
        "alias": "PLANID",
        "domain": null,
        "editable": true,
        "nullable": false,
        "length": 255,
        "defaultValue": null
      }
    ],
    "types": [],
    "templates": [{
      "name": "REPLY",
      "description": "",
      "prototype": {
        "attributes": {
          "reply": " ",
          "planid": " ",
          "parent_cid": " "
        }
      },
      "drawingTool": "esriFeatureEditToolNone"
    }]
  };

  return def;
}

/*

{
  "currentVersion": 10.81,
  "cimVersion": "2.6.0",
  "id": 0,
  "name": "Comments",
  "type": "Feature Layer",
  "parentLayer": null,
  "defaultVisibility": true,
  "minScale": 0,
  "maxScale": 0,
  "canScaleSymbols": false,
  "geometryType": "esriGeometryPoint",
  "description": "",
  "copyrightText": "",
  "editFieldsInfo": {
    "creationDateField": "created_date",
    "creatorField": "created_user",
    "editDateField": "last_edited_date",
    "editorField": "last_edited_user"
  },
  "syncCanReturnChanges": true,
  "relationships": [],
  "isDataVersioned": false,
  "isDataArchived": false,
  "isDataBranchVersioned": false,
  "isDataReplicaTracked": false,
  "isCoGoEnabled": false,
  "supportsRollbackOnFailureParameter": true,
  "supportsStatistics": true,
  "supportsAdvancedQueries": true,
  "supportsValidateSQL": true,
  "supportsCoordinatesQuantization": true,
  "supportsCalculate": true,
  "supportsASyncCalculate": true,
  "advancedQueryCapabilities": {
    "supportsReturningQueryExtent": true,
    "supportsStatistics": true,
    "supportsDistinct": true,
    "supportsPagination": true,
    "supportsOrderBy": true,
    "supportsQueryWithDistance": true,
    "supportsLod": true,
    "supportsPaginationOnAggregatedQueries": true,
    "supportsQueryWithResultType": true,
    "supportsCountDistinct": true,
    "supportsReturningGeometryCentroid": false,
    "supportsHavingClause": true,
    "supportsQueryWithLodSR": true,
    "supportsTopFeaturesQuery": true,
    "supportsOrderByOnlyOnLayerFields": false,
    "supportsPercentileStatistics": true,
    "supportsQueryAttachments": true,
    "supportsQueryAttachmentsWithReturnUrl": true,
    "supportsQueryWithDatumTransformation": true
  },
  "extent": {
    "xmin": "NaN",
    "ymin": "NaN",
    "xmax": "NaN",
    "ymax": "NaN",
    "spatialReference": {
      "wkid": 102100,
      "latestWkid": 3857,
      "xyTolerance": 0.001,
      "zTolerance": 0.001,
      "mTolerance": 0.001,
      "falseX": -20037700,
      "falseY": -30241100,
      "xyUnits": 10000,
      "falseZ": -100000,
      "zUnits": 10000,
      "falseM": -100000,
      "mUnits": 10000
    }
  },
  "drawingInfo": {
    "renderer": {
      "type": "simple",
      "symbol": {
        "type": "esriSMS",
        "style": "esriSMSCircle",
        "color": [
          71,
          163,
          97,
          255
        ],
        "size": 4,
        "angle": 0,
        "xoffset": 0,
        "yoffset": 0,
        "outline": {
          "color": [
            0,
            0,
            0,
            255
          ],
          "width": 0.7
        }
      }
    },
    "scaleSymbols": true,
    "transparency": 0,
    "labelingInfo": null
  },
  "hasM": false,
  "hasZ": true,
  "supportsVCSProjection": true,
  "allowTrueCurvesUpdates": false,
  "onlyAllowTrueCurveUpdatesByTrueCurveClients": true,
  "hasAttachments": false,
  "htmlPopupType": "esriServerHTMLPopupTypeAsHTMLText",
  "objectIdField": "objectid",
  "globalIdField": "globalid",
  "displayField": "comment",
  "typeIdField": "",
  "fields": [{
      "name": "objectid",
      "type": "esriFieldTypeOID",
      "alias": "OBJECTID",
      "domain": null,
      "nullable": false,
      "defaultValue": null,
      "modelName": "objectid",
      "editable": false
    },
    {
      "name": "globalid",
      "type": "esriFieldTypeGlobalID",
      "alias": "GlobalID",
      "domain": null,
      "editable": false,
      "nullable": false,
      "length": 38,
      "defaultValue": null,
      "modelName": "globalid"
    },
    {
      "name": "comment",
      "type": "esriFieldTypeString",
      "alias": "COMMENT",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 4000,
      "defaultValue": null,
      "modelName": "comment"
    },
    {
      "name": "author",
      "type": "esriFieldTypeString",
      "alias": "AUTHOR",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 255,
      "defaultValue": null,
      "modelName": "author"
    },
    {
      "name": "planid",
      "type": "esriFieldTypeString",
      "alias": "PLANID",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 255,
      "defaultValue": null,
      "modelName": "planid"
    },
    {
      "alias": "created_user",
      "name": "created_user",
      "length": 255,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeString",
      "editable": false
    },
    {
      "alias": "created_date",
      "name": "created_date",
      "length": 29,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeDate",
      "editable": false
    },
    {
      "alias": "last_edited_user",
      "name": "last_edited_user",
      "length": 255,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeString",
      "editable": false
    },
    {
      "alias": "last_edited_date",
      "name": "last_edited_date",
      "length": 29,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeDate",
      "editable": false
    }
  ],
  "geometryField": {
    "name": "shape",
    "type": "esriFieldTypeGeometry",
    "alias": "Shape",
    "domain": null,
    "editable": true,
    "nullable": true,
    "defaultValue": null,
    "modelName": "shape"
  },
  "indexes": [{
      "name": "r83_sde_rowid_uk",
      "fields": "objectid",
      "isAscending": true,
      "isUnique": true,
      "description": ""
    },
    {
      "name": "uuid_83",
      "fields": "globalid",
      "isAscending": true,
      "isUnique": true,
      "description": ""
    },
    {
      "name": "a63_ix1",
      "fields": "shape",
      "isAscending": true,
      "isUnique": true,
      "description": ""
    }
  ],
  "dateFieldsTimeReference": {
    "timeZone": "UTC",
    "respectsDaylightSaving": false
  },
  "types": [],
  "templates": [{
    "name": "Comments",
    "description": "",
    "prototype": {
      "attributes": {
        "author": " ",
        "comment": " ",
        "planid": " "
      }
    },
    "drawingTool": "esriFeatureEditToolPoint"
  }],
  "useStandardizedQueries": true,
  "standardMaxRecordCount": 16000,
  "tileMaxRecordCount": 4000,
  "standardMaxRecordCountNoGeometry": 16000,
  "maxRecordCountFactor": 1,
  "supportedQueryFormats": "JSON, geoJSON, PBF",
  "maxRecordCount": 2000,
  "allowGeometryUpdates": true,
  "capabilities": "Create,Editing,Uploads,Query,Update,Delete",
  "enableZDefaults": true,
  "zDefault": 0,
  "allowUpdateWithoutMValues": true,
  "hasGeometryProperties": true,
  "serviceItemId": "3780ac65048748cb856c3ce1631a85ac",
  "hasMetadata": true,
  "ownershipBasedAccessControlForFeatures": {
    "allowOthersToQuery": true,
    "allowOthersToUpdate": false,
    "allowOthersToDelete": false
  },
  "advancedEditingCapabilities": {
    "supportedSqlFormatsInCalculate": [
      "standard"
    ]
  },
  "supportsApplyEditsWithGlobalIds": true,
  "supportsAppend": true,
  "supportedAppendFormats": "shapefile,geojson,csv,featureCollection",
  "supportsQuantizationEditMode": true,
  "supportsAttachmentsByUploadId": true,
  "supportsAsyncDelete": true,
  "supportsFieldDescriptionProperty": true,
  "supportsExceedsLimitStatistics": true,
  "sqlParserVersion": "PG_10.8.1"
}

 */



/*
{
  "currentVersion": 10.81,
  "cimVersion": "2.6.0",
  "id": 1,
  "name": "REPLY",
  "type": "Table",
  "description": "",
  "copyrightText": "",
  "editFieldsInfo": {
    "creationDateField": "created_date",
    "creatorField": "created_user",
    "editDateField": "last_edited_date",
    "editorField": "last_edited_user"
  },
  "syncCanReturnChanges": true,
  "relationships": [],
  "isDataVersioned": false,
  "isDataArchived": false,
  "isDataBranchVersioned": false,
  "isDataReplicaTracked": false,
  "isCoGoEnabled": false,
  "supportsRollbackOnFailureParameter": true,
  "supportsStatistics": true,
  "supportsAdvancedQueries": true,
  "supportsValidateSQL": true,
  "supportsCoordinatesQuantization": true,
  "supportsCalculate": true,
  "supportsASyncCalculate": true,
  "advancedQueryCapabilities": {
    "supportsStatistics": true,
    "supportsDistinct": true,
    "supportsPagination": true,
    "supportsOrderBy": true,
    "supportsPaginationOnAggregatedQueries": true,
    "supportsQueryWithResultType": true,
    "supportsHavingClause": true,
    "supportsLod": false,
    "supportsQueryWithLodSR": false,
    "supportsTopFeaturesQuery": true,
    "supportsOrderByOnlyOnLayerFields": false,
    "supportsPercentileStatistics": true,
    "supportsQueryAttachments": true,
    "supportsQueryAttachmentsWithReturnUrl": true,
    "supportsQueryWithDatumTransformation": true
  },
  "hasAttachments": false,
  "htmlPopupType": "esriServerHTMLPopupTypeNone",
  "objectIdField": "objectid",
  "globalIdField": "globalid",
  "displayField": "parent_cid",
  "typeIdField": "",
  "fields": [{
      "name": "objectid",
      "type": "esriFieldTypeOID",
      "alias": "OBJECTID",
      "domain": null,
      "nullable": false,
      "defaultValue": null,
      "modelName": "objectid",
      "editable": false
    },
    {
      "name": "globalid",
      "type": "esriFieldTypeGlobalID",
      "alias": "GlobalID",
      "domain": null,
      "editable": false,
      "nullable": false,
      "length": 38,
      "defaultValue": null,
      "modelName": "globalid"
    },
    {
      "name": "parent_cid",
      "type": "esriFieldTypeString",
      "alias": "PARENT_CID",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 255,
      "defaultValue": null,
      "modelName": "parent_cid"
    },
    {
      "name": "reply",
      "type": "esriFieldTypeString",
      "alias": "REPLY",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 4000,
      "defaultValue": null,
      "modelName": "reply"
    },
    {
      "name": "author",
      "type": "esriFieldTypeString",
      "alias": "AUTHOR",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 255,
      "defaultValue": null,
      "modelName": "author"
    },
    {
      "name": "planid",
      "type": "esriFieldTypeString",
      "alias": "PLANID",
      "domain": null,
      "editable": true,
      "nullable": false,
      "length": 255,
      "defaultValue": null,
      "modelName": "planid"
    },
    {
      "alias": "created_user",
      "name": "created_user",
      "length": 255,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeString",
      "editable": false
    },
    {
      "alias": "created_date",
      "name": "created_date",
      "length": 29,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeDate",
      "editable": false
    },
    {
      "alias": "last_edited_user",
      "name": "last_edited_user",
      "length": 255,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeString",
      "editable": false
    },
    {
      "alias": "last_edited_date",
      "name": "last_edited_date",
      "length": 29,
      "domain": null,
      "nullable": true,
      "type": "esriFieldTypeDate",
      "editable": false
    }
  ],
  "indexes": [{
      "name": "r84_sde_rowid_uk",
      "fields": "objectid",
      "isAscending": true,
      "isUnique": true,
      "description": ""
    },
    {
      "name": "uuid_84",
      "fields": "globalid",
      "isAscending": true,
      "isUnique": true,
      "description": ""
    }
  ],
  "dateFieldsTimeReference": {
    "timeZone": "UTC",
    "respectsDaylightSaving": false
  },
  "types": [],
  "templates": [{
    "name": "REPLY",
    "description": "",
    "prototype": {
      "attributes": {
        "author": " ",
        "reply": " ",
        "planid": " ",
        "parent_cid": " "
      }
    },
    "drawingTool": "esriFeatureEditToolNone"
  }],
  "useStandardizedQueries": true,
  "standardMaxRecordCount": 16000,
  "tileMaxRecordCount": 4000,
  "maxRecordCountFactor": 1,
  "supportedQueryFormats": "JSON, geoJSON, PBF",
  "maxRecordCount": 2000,
  "allowGeometryUpdates": true,
  "capabilities": "Create,Editing,Uploads,Query,Update,Delete",
  "enableZDefaults": true,
  "zDefault": 0,
  "allowUpdateWithoutMValues": true,
  "serviceItemId": "3780ac65048748cb856c3ce1631a85ac",
  "hasMetadata": true,
  "ownershipBasedAccessControlForFeatures": {
    "allowOthersToQuery": true,
    "allowOthersToUpdate": false,
    "allowOthersToDelete": false
  },
  "advancedEditingCapabilities": {
    "supportedSqlFormatsInCalculate": [
      "standard"
    ]
  },
  "supportsApplyEditsWithGlobalIds": true,
  "supportsAppend": true,
  "supportedAppendFormats": "shapefile,geojson,csv,featureCollection",
  "supportsQuantizationEditMode": true,
  "supportsAttachmentsByUploadId": true,
  "supportsAsyncDelete": true,
  "supportsFieldDescriptionProperty": true,
  "supportsExceedsLimitStatistics": true,
  "sqlParserVersion": "PG_10.8.1"
}
 */
