import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

export async function prepare(task) {
  const transactionInfo = transaction.makeTransactionInfo();
  const info = task.info;
  const newWall = info && info.newWalls && info.newWalls[0];
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  const makeDetailEdits = () => {
    const layer = sourceUtil.getDetailsLayer();
    const adds = [];
    if (layer && newWall && newWall.attributes) {
      const add = transactionUtil.cloneDetailFeature(task,newWall);
      adds.push(add);
    }
    if (adds.length > 0) {
      const layerEdits = {
        id: layer.layerId,
        adds: adds
      }
      const undoLayerEdits = {
        id: layer.layerId,
        deletes: ["?"] // needs to be populated following add
      }
      transactionInfo.detailEdits = true;
      transactionInfo.undo.detailEdits = true;
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  const makeUnitsEdits = () => {
    const layer = sourceUtil.getUnitsLayer();
    const originalUnits = info && info.originalUnits;
    const modifiedUnits = info && info.modifiedUnits;
    const adds = [], updates = [], undoUpdates = [];

    if (originalUnits && modifiedUnits) {
      modifiedUnits.forEach((modifiedUnit)=> {
        const update = transactionUtil.cloneUnitFeature(task,modifiedUnit);
        updates.push(update);
      })
      originalUnits.forEach((originalUnit)=> {
        const undo = transactionUtil.cloneUnitFeature(task,originalUnit);
        undoUpdates.push(undo);
      })
      const layerEdits = {
        id: layer.layerId,
        updates: updates
      }
      const undoLayerEdits = {
        id: layer.layerId,
        updates: undoUpdates
      }
      if (adds.length > 0) {
        layerEdits.adds = adds;
        undoLayerEdits.deletes = ["?"]; // needs to be populated following add
      }

      transactionInfo.unitEdits = true;
      transactionInfo.undo.unitEdits = true;
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  makeDetailEdits();
  makeUnitsEdits();

  return transactionInfo;
}
