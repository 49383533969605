import BaseClass from "../../../util/BaseClass";
import ClosestFacility from "../../../aiim/base/ClosestFacility";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as dateUtil from "../Events/dateUtil";
import moment from "moment";
import {
  dropUnitPins,
  setReservationsDefExp
} from "../More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import { goToFeature } from "../../../util/mapUtil";
import { isNetworkError } from "../../../util/networkUtil";
import { formatRecurringDates, getMeetingBookingSystem, getMeetingBookingSystemType } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as validateUtil from "../More/Actions/BookWorkspace/validateUtil";
import { ISiteFacilityLevelStrings } from "../../../aiim/datasets/Units";
import { getRecurringDates, IRecurrenceSeries } from "../More/Actions/BookWorkspace/BookingRecurrence";
import * as selectionUtil from "../../../aiim/util/selectionUtil";
import { IIndoorsEvent } from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import { IReserveForInfo } from "../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";

export interface IMeetingRoom {
  capacity?: number,
  facilityId?: string,
  feature: __esri.Graphic,
  hasCapacity?: boolean,
  levelId?: string,
  name: string,
  oid?: number,
  origBooking?: {
    fromDate: Date,
    toDate: Date
  },
  reservationMethod?: string,
  scheduleEmail?: string,
  sfl?: ISiteFacilityLevelStrings,
  siteId?: string,
  unitId: string,
  title?: string,
  areaId?: string,
  assignmentType?: string
}
export interface IMeetingRoomCriteria {
  description?: string,
  eventId?: string,
  filter?: {
    siteId: string,
    facilityId: string,
    levelId: string,
    capacity: number,
    equipments: string[],
    areaIds?: string[]
  },
  isOngoingBooking?: boolean,
  objectID?: number[],
  operation?: "updateBookingTime",
  sortBy?: "name" | "distanceBluedot" | "distanceHome",
  sortDir?: "asc" | "desc",
  title?: string,
  wasSiteInitialised?: boolean,
  when: dateUtil.IDuration,
  series?: IRecurrenceSeries | IIndoorsEvent[]
}
export interface IMeetingRoomsModelProps {
  onComplete: (info: { working: boolean }) => void,
  onReservationsQueried: (info: { criteriaString: string, filteredItems: IMeetingRoom[], reservedUnits: IMeetingRoom[] }) => void,
  onUnitsQueried: (info: { criteriaString: string, items: IMeetingRoom[], areasWithMeetingRooms: __esri.Graphic[]  }) => void,
  onUnitsSorted?: (info: { filteredItems: IMeetingRoom[] }) => void
}
export default class MeetingRoomsModel extends BaseClass implements IMeetingRoomsModelProps {

  private _controller: AbortController;

  reserveForInfo: IReserveForInfo = null;

  constructor(props?: IMeetingRoomsModelProps) {
    super(props);    
    this.mixinProps(props);
    this.getCriteria();
  }
  onComplete(info: { working: boolean }) {}
  onReservationsQueried(info: { criteriaString: string, filteredItems: IMeetingRoom[], reservedUnits: IMeetingRoom[] }) {}
  onUnitsQueried(info: { criteriaString: string, items: IMeetingRoom[], areasWithMeetingRooms: __esri.Graphic[] }) {}
  onUnitsSorted(info: { filteredItems: IMeetingRoom[] }) {}

  canSortByBluedotDistance() {
    const url = Context.instance.config.closestFacilityServiceUrl;
    if (url) {
      const graphic = Context.instance.bluedot.graphic;
      return !!graphic;
    }
    return false;
  }

  canSortByCapacity() {
    const ds = Context.instance.aiim.datasets.units;
    if (ds && ds.layer2D) {
      const fld = aiimUtil.findField(ds.layer2D.fields,FieldNames.UNITS_CAPACITY);
      return !!fld;
    }
    return false;
  }

  canSortByHomeDistance() {
    const url = Context.instance.config.closestFacilityServiceUrl;
    if (url) {
      const referenceLayer = Context.instance.session.referenceLayer;
      const item = referenceLayer.homeLocation;
      if (item && item.isValid()) {
        return true;
      }
    }
    return false;
  }
  executeTask(
    type: "sortUnits" | "filterUnits" | "queryReservations",
    info: {
      items: IMeetingRoom[],
      filteredItems: IMeetingRoom[],
      reservedUnits: IMeetingRoom[]
    }
  ) {
    if (this._controller && !this._controller.signal.aborted) {
      this._controller.abort();
      this._controller = null;
    }
    this._controller = new AbortController();

    const task = {
      criteria: this.getCriteria(),
      criteriaString: null,
      items: info.items,
      filteredItems: info.filteredItems,
      reservedUnits: info.reservedUnits
    }

    this.onComplete({ working: true });
    let promise;
    switch (type) {
      case "sortUnits":
        promise = this.sortUnits(task).then(() => this.onUnitsSorted({ filteredItems: task.filteredItems }));
        break;
      case "filterUnits":
        promise = this.queryUnits(false)
        break;
      case "queryReservations":
        promise = this.queryReservations(task).then(() => this.onReservationsQueried({
          criteriaString: task.criteriaString,
          filteredItems: task.filteredItems,
          reservedUnits: task.reservedUnits
        }));
        break;
      default:
        return;
    }
   
    promise.then(() => {
      if (type !== "filterUnits") {
        // Drop pins, filter layer, and zoom to first feature
        this.onRoomsListed(task);
        this.onComplete({ working: false });        
      }
    }).catch(ex => {
      const promiseUtils: __esri.promiseUtils = Context.instance.lib.esri.promiseUtils;
      if (!promiseUtils.isAbortError(ex) && ex?.code !== "AbortError") {
        console.error(ex);
        this.onComplete({ working: false });
        Topic.publishErrorAccessingData();
      }
    });
  }

  filterUnits(task) {
    const items = task.items;
    const criteria = task.criteria;
    const reservedUnits = task.reservedUnits;
    const reservedUnitIndex = (reservedUnits && reservedUnits.index) || {};
    const capacity = criteria.filter.capacity;
    const facilityId = criteria.filter.facilityId;
    const levelId = criteria.filter.levelId;
    const siteId = criteria.filter.siteId;
    const equipments = criteria.filter.equipments;
    const areaIds = criteria.filter.areaIds;
    const hasCapacity = (typeof capacity === "number");
    const hasFaciliyId = !!facilityId;
    const hasLevelId = !!levelId;
    const hasSiteId = !!siteId;
    const hasEquipments = !!equipments;
    const hasAreaIds = areaIds && (areaIds.length > 0);

    let filteredItems = null;
    if (items && items.length > 0) {
      filteredItems = [];
      items.forEach(item => {
        let ok = true;
        if (ok) {
          ok = !reservedUnitIndex[item.unitId];
        }
        if (ok && hasLevelId) {
          ok = (item.levelId === levelId);
        }
        if (ok && hasFaciliyId) {
          ok = (item.facilityId === facilityId);
        }
        if (ok && hasSiteId) {
          ok = (item.siteId === siteId);
        }
        if (ok && hasCapacity) {
          ok = (item.capacity >= capacity);
        }
        if (ok && hasAreaIds) {
          ok = !!(item.areaId && areaIds.includes(item.areaId));
        }
        if (ok && hasEquipments) {
          const attributes = item.feature && item.feature.attributes;
          for(let i=0;i<equipments.length;i++) {
            const fieldName = equipments[i];
            const isEquipmentAvailable = aiimUtil.getAttributeValue(attributes, fieldName);
            if(!isEquipmentAvailable || (isEquipmentAvailable && (isEquipmentAvailable !== 1))) {
              ok = false;
              break;
            }
          }
        }
        if (ok) filteredItems.push(item);
      });
    }
    task.filteredItems = filteredItems;
    return this.sortUnits(task);
  }

  onRoomsListed(task) {
    const { filteredItems, criteria } = task;
    if (!filteredItems || !filteredItems.length) {
      this.onNoRoomsListed(task);
      return;
    }
    const unitFeatures = filteredItems.map((item) => item.feature);

    const units = Context.getInstance().aiim.datasets.units;
    const unitsLayer = units && units.layer2D;
    const floorField = unitsLayer && unitsLayer.floorInfo && unitsLayer.floorInfo.floorField;
    const levels = Context.getInstance().aiim.datasets.levels;

    // Make sure the unit has accessible level data
    const validateLevelData = (unit) => {
      const attributes = unit.attributes;
      const levelId = floorField
        ? aiimUtil.getAttributeValue(attributes, floorField)
        : aiimUtil.getAttributeValue(attributes, FieldNames.LEVEL_ID);

      return levels.getLevelData(levelId);
    }

    // Filter the unit features by those who have level data
    const validUnitFeatures = unitFeatures.filter((unit) => {
      return !!validateLevelData(unit);
    });

    if (!validUnitFeatures || !validUnitFeatures.length) {
      this.onNoRoomsListed(task);
      return;
    }

    // Take first unit in the list
    const initialUnit = validUnitFeatures[0];
    const centroid = initialUnit.geometry && initialUnit.geometry.centroid;

    // Get the level and facility data to activate the level
    const view = Context.getInstance().views.activeView;
    const levelData = validateLevelData(initialUnit);
    const { facilityId } = levelData;
    const facilityData = levels.getFacilityData(facilityId);

    // Set the level and zoom to the unit
    Topic.publish(Topic.ActivateLevel, { facilityData, levelData, view });
    const feature = centroid ? { geometry: centroid } : initialUnit;
    goToFeature(view, feature, false, { scale: 300 });

    const when = criteria && criteria.when;
    const allDay = when.duration === "allDay";
    if (allDay && when.start.date && when.end.date) {
      const date = new Date(when.start.date);
      const start = dateUtil.toStartOfDay(date);
      const end = dateUtil.toEndOfDay(new Date(when.end.date));
      setReservationsDefExp(start, end);
    } else if (when.start.date && when.end.date) {
      const start = new Date(when.start.date);
      const end = new Date(when.end.date);
      setReservationsDefExp(start, end);
    }

    // Drop unit pins
    dropUnitPins(validUnitFeatures);
  }

  onNoRoomsListed(task) {
    const { criteria } = task;

    const when = criteria && criteria.when;
    const allDay = when.duration === "allDay";
    if (allDay && when.start.date && when.end.date) {
      const date = new Date(when.start.date);
      const start = dateUtil.toStartOfDay(date);
      const end = dateUtil.toEndOfDay(new Date(when.end.date));
      setReservationsDefExp(start, end);
    } else if (when.start.date && when.end.date) {
      const start = new Date(when.start.date);
      const end = new Date(when.end.date);
      setReservationsDefExp(start, end);
    }

    dropUnitPins([]);
  }

  async getAreasWithMeetingRooms(items: IMeetingRoom[]) {
    const areasWithMeetingRooms: __esri.Graphic[] = [];
    if (Context.instance.areas) {
      const areas = await Context.instance.areas;
      const idx = {};
      items.forEach(item => {
        if (item.areaId && (item.assignmentType === "meeting room")) {
          idx[item.areaId] = true;
        }
      })
      areas.forEach(area => {
        const id = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_ID);
        const type = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_TYPE);
        if (idx[id] && ((type === "hotel") || (type === "hotdesk") || (type === "workspace"))) {
          areasWithMeetingRooms.push(area)
        }
      })
    }
    return areasWithMeetingRooms;
  }

  getCriteria() {
    let criteria = Context.instance.session.meetingRoomsCriteria;
    if (!criteria) {
      let dtStart = dateUtil.getTopOfHour();
      let dtEnd = dateUtil.addDuration(dtStart, "oneHour");
      criteria = Context.instance.session.meetingRoomsCriteria = {
        sortBy: "name",
        sortDir: "asc",
        when: {
          duration: "oneHour",
          start: {
            date: dtStart
          },
          end: {
            date: dtEnd
          },
          recurrence: {
            enabled: false
          }
        },
        filter: {
          siteId: null,
          facilityId: null,
          levelId: null,
          capacity: null,
          equipments: [],
          areaIds: []
        }
      };
      if (this.canSortByBluedotDistance() && this.isBluedotInSite()) {
        criteria.sortBy = "distanceBluedot";
      } else if (this.canSortByHomeDistance()) {
        criteria.sortBy = "distanceHome";
      }
    }
    return criteria;
  }

  isBluedotInSite() {
    const graphic = Context.instance.bluedot.graphic;
    let levels = Context.instance.aiim.datasets.levels;
    if (graphic && graphic.geometry && levels && levels.hasSitesById()) {
      let site = levels.findSiteByPoint(graphic.geometry);
      if (site) return true;
    }
    return false;
  }

  makeCriteriaString(task) {
    const i18n = Context.instance.i18n;
    const djl = Context.instance.lib.dojo.locale;
    const when: dateUtil.IDuration = task.criteria.when;
    const allDay = when.duration === "allDay";
    let msg = null;

    let dateFormat = {selector:"date", formatLength: "short" };
    let timeFormat = "H:mm";
    let locale = Context.instance.lib.dojo.kernel.locale
    if (locale === "en" || locale === "en-us") timeFormat = "h:mm A"

    if (when.start.date && when.end.date) {
      if (when?.recurrence?.enabled) {
        msg = formatRecurringDates(when.recurrence, moment(when.start.date), moment(when.end.date),
          allDay);
      } else {
        let dtNow = new Date();
        let dtStart = new Date(when.start.date);
        let dtEnd = new Date(when.end.date);
        let today = djl.format(dtNow,dateFormat);
        let start = djl.format(dtStart,dateFormat);
        let end = djl.format(dtEnd,dateFormat);
        let st = moment(dtStart).format(timeFormat);
        let et = moment(dtEnd).format(timeFormat);
        let isToday = (today === start) && (today === end);
        let isSameDay = (start === end);

        if (allDay) {
          let dur, day
          if (isToday) {
            dur = ""
            day = i18n.meetingRooms.today;
          } else if (isSameDay) {
            dur = i18n.meetingRooms.on;
            day = start;
          } else {
            dur = i18n.meetingRooms.from
            day = start + " - " + end
          }
          msg = i18n.meetingRooms.availableUnitsAllDay
          msg = msg.replace("{duration}", dur)
          msg = msg.replace("{day}", day)
        } else {
          let dur, dateString, pattern;
          if (isToday) {
            dateString = i18n.meetingRooms.today;
            dur = "";
            pattern = i18n.meetingRooms.availableUnitsSameDay;
          } else if (isSameDay) {
            dateString = start;
            dur = i18n.meetingRooms.on;
            pattern = i18n.meetingRooms.availableUnitsSameDay;
          } else {
            dur = i18n.meetingRooms.from;
            pattern = i18n.meetingRooms.availableUnits;
          }
          msg = pattern
            .replace("{duration}", dur)
            .replace("{date}", dateString)
            .replace("{startDate}", start)
            .replace("{startTime}", st)
            .replace("{endDate}", end)
            .replace("{endTime}", et);
        }
      }
    }
    task.criteriaString = msg;
  }

  async queryReservationsOffice365 (task) {
    const items = task && task.items;
    const when = this.getCriteria().when;

    let startDateTime = moment(when.start.date).toISOString();
    let endDateTime = moment(when.end.date).toISOString();
    let allDay = when.duration === "allDay";

    if (allDay) {
      if (when.start.date && when.end.date) {
        let dt = new Date(when.start.date);
        let dtStart = dateUtil.toStartOfDay(dt);
        let dtEnd = dateUtil.toEndOfDay(new Date(when.end.date));
        startDateTime = moment(dtStart).toISOString();
        endDateTime = moment(dtEnd).toISOString();
      }
    }

    const scheduleEmails = [];
    const unitsByEmail = new Map<string, string[]>();
    const bookingSystem = getMeetingBookingSystem();
    items.forEach((item)=> {
      if (item.scheduleEmail) {
        scheduleEmails.push(item.scheduleEmail);
        if (unitsByEmail.has(item.scheduleEmail)) {
          unitsByEmail.get(item.scheduleEmail).push(item.unitId);
        } else {
          unitsByEmail.set(item.scheduleEmail, [item.unitId]);
        }
      }
    })
    const schedules = await bookingSystem.checkAvailability(scheduleEmails, startDateTime, endDateTime, null, { recurrence: when.recurrence }, this._controller?.signal)
    const results = schedules;
    let index = {}, list = [];
    results.forEach((result) => {
      const isUnitAvailable = validateUtil.isUnitAvailable(result);
      if (!isUnitAvailable) {
        const scheduleEmail = result && result.scheduleId;
        const uids = unitsByEmail.get(scheduleEmail);
        if (uids?.length) {
          uids.forEach(uid => {
            if (!index[uid]) {
              index[uid] = uid;
              list.push(uid);
            }
          });
        }
      }
    })

    task.reservedUnits = {
      index: index,
      list: list
    }

    await this.filterUnits(task);
    this.makeCriteriaString(task);
    this.trackSharedMeetingRoom(when);

 }

 trackSharedMeetingRoom (when: dateUtil.IDuration) {
  Context.instance.session.sharedMeetingRoomWhen = {
    duration: when.duration,
    start: {
      date: when.start.date
    },
    end: {
      date: when.end.date
    },
    recurrence: when.recurrence
  };
 }

  async queryReservationsEsri(task) {
    let r = Context.instance.aiim.datasets.reservations;
    let qaopts: __esri.QueryProperties = {};
    let when = this.getCriteria().when;
    const where = [];
    const currentDateTime = dateUtil.getZulu(new Date());
    const allDay = when.duration === "allDay";
    const getWhere = (start: Date, end: Date) => {
      let startStr = start && dateUtil.getZulu(start);
      let endStr = end && dateUtil.getZulu(end);
      let w;
      if (allDay) {
        if (start && end) {
          const dtStart = dateUtil.toStartOfDay(start);
          const dtEnd = dateUtil.toEndOfDay(end);
          startStr = dateUtil.getZulu(dtStart);
          endStr = dateUtil.getZulu(dtEnd);
          w = `((${r.startTimeField} < TIMESTAMP '${endStr}')`;
          w += ` AND (${r.endTimeField} > TIMESTAMP '${startStr}')`;
          w += ` AND (${r.endTimeField} >= TIMESTAMP '${currentDateTime}'))`;
        }
      } else if (start && end) {
        w = `((${r.startTimeField} < TIMESTAMP '${endStr}')`;
        w += ` AND (${r.endTimeField} > TIMESTAMP '${startStr}'))`;
      }
      return w;
    }
    if (when.recurrence.enabled) {
      const recurringDates = getRecurringDates(when.recurrence, moment(when.start.date), moment(when.end.date));
      where.push(...recurringDates.map(date => getWhere(date.start.toDate(), date.end.toDate())));      
    } else {
      where.push(getWhere(when.start.date, when.end.date));
    }
    qaopts.where = where.join(" OR ");
    if (qaopts.where) {
      qaopts.where = `((${qaopts.where}) AND (${r.stateField} IN (0,1,4)))`; // pending, approved, checked-in
    }

    if (!qaopts.where) {
      task.reservedUnits = null;
    } else {
      const info = await r.queryUnitIds({ ...qaopts, signal: this._controller?.signal });
      if (info) task.reservedUnits = info;
    }
    await this.filterUnits(task);
    this.makeCriteriaString(task);
    this.trackSharedMeetingRoom(when);
  }

  queryReservations(task) {
    const bookingSystemType = getMeetingBookingSystemType();
    if (bookingSystemType === "office365") return this.queryReservationsOffice365(task);
    else return this.queryReservationsEsri(task);
  }

  async getMeetingRoomUnits() {
    const items = await this.queryMeetingRoomUnits(false);
    return items;
  }

  async queryMeetingRoomUnits(abort: boolean = true): Promise<IMeetingRoom[]> {
    const items: IMeetingRoom[] = [];
    const ds = Context.instance.aiim.datasets.units;
    const lds = Context.instance.aiim.datasets.levels;
    if (!ds || !ds.layer2D) return;

    let resmethFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.RESERVATION_METHOD);
    let oidFld = ds.layer2D.objectIdField;
    let uidFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.UNIT_ID);
    let aidFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.AREA_ID);
    let lidFld = Context.instance.aiim.getLevelIdField(ds.layer2D);
    let nameFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.NAME);
    let capFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.UNITS_CAPACITY);
    let scheduleFld = aiimUtil.findField(ds.layer2D.fields, FieldNames.SCHEDULE_EMAIL);
    let asnFld = aiimUtil.findField(ds.layer2D.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const type = getMeetingBookingSystemType();

    if (type === "esri" && !resmethFld) return;

    let where = "1=2";
    let whereA = null;
    let areaIds = Context.instance.aiim.getAppUserAreaIDs(true);
    if (areaIds.length > 0) {
      whereA = "("+asnFld.name+" = 'meeting room')";
      let values = [];
      areaIds.forEach(id => {
        let v = "'"+selectionUtil.escSqlQuote(id)+"'";
        values.push(v);
      });
      let w = "(" + aidFld.name + " IN (" + values.join(",") + "))";
      whereA = "(" + whereA + " AND " + w + ")";
      where = whereA;
    }

    if (!this.reserveForInfo) {
      // backwardly compatible meeting rooms
      let whereB;
      if (type === "office365") {
        whereB = "("+scheduleFld.name+" IS NOT NULL)";
      } else {
        whereB = "(("+resmethFld.name+" = 1) OR ("+resmethFld.name+" = 2))";
      }
      whereB = "(" + whereB + " AND (("+aidFld.name+" IS NULL) OR ("+aidFld.name+" = ''))" + " AND (("+asnFld.name+" IS NULL) OR ("+asnFld.name+" <> 'office')))";
      where = whereA ? "(" + whereA + " OR " + whereB + ")" : whereB;
    }

    if (abort) {
      if (this._controller && !this._controller.signal.aborted) {
        this._controller.abort();
        this._controller = null;
      }
      this._controller = new AbortController();
    }
    const qaopts = {
      layer: ds.layer2D,
      where: where,
      signal: this._controller?.signal,
      perFeatureCallback: feature => {
        const levelId = lidFld && feature.attributes[lidFld.name];
        const capacity = capFld && feature.attributes[capFld.name];
        let ld = lds.getLevelData(levelId);
        let item: IMeetingRoom = {
          oid: feature.attributes[oidFld],
          unitId: uidFld && feature.attributes[uidFld.name],
          levelId,
          name: nameFld && feature.attributes[nameFld.name],
          capacity,
          reservationMethod: feature.attributes[resmethFld.name],
          sfl: ds.getSiteFacilityLevelStrings(ds.source,feature),
          feature: feature,
          scheduleEmail: scheduleFld && feature.attributes[scheduleFld.name],
          hasCapacity: (typeof capacity === "number"),
          facilityId: ld?.facilityId,
          siteId: ld?.siteId,
          areaId: aidFld && feature.attributes[aidFld.name],
          assignmentType: asnFld && feature.attributes[asnFld.name]
        };

        let ok = true;
        if (type === "office365") {
          if (!item.scheduleEmail) {
            ok = false;
          }
        }

        if (ok) items.push(item);          
      }
    };

    await ds.queryAll(qaopts);
    return items;
  }

  queryUnits(abort: boolean = true) {
    const task = {
      criteria: this.getCriteria(),
      criteriaString: null,
      items: null,
      filteredItems: null,
      reservedUnits: null
    }
    return this.queryMeetingRoomUnits(abort).then(items => {
      task.items = items;
      return this.getAreasWithMeetingRooms(items);
    }).then(areasWithMeetingRooms => {
      this.onUnitsQueried({
        criteriaString: task.criteriaString,
        items: task.items,
        areasWithMeetingRooms: areasWithMeetingRooms
      });
      if (!this._controller?.signal.aborted) {
        return this.queryReservations(task);
      }
    }).then(() => {
      this.onReservationsQueried({
        criteriaString: task.criteriaString,
        filteredItems: task.filteredItems,
        reservedUnits: task.reservedUnits,
      })
      // Drop pins, filter layer, and zoom to first feature
      this.onRoomsListed(task);
      this.onComplete({ working: false });
    }).catch(ex => {
      const promiseUtils: __esri.promiseUtils = Context.instance.lib.esri.promiseUtils;
      if (ex && isNetworkError(ex.message)) {
        const i18n = Context.getInstance().i18n;
        Topic.publishNetworkError(i18n.meetingRooms.issues.m3);
      } else if (!promiseUtils.isAbortError(ex) && ex?.code !== "AbortError") {
        console.error(ex);
        this.onComplete({ working: false });
        Topic.publishErrorAccessingData();
      }
    });
  }

  async sortByDistance(task) {
    const filteredItems = task.filteredItems;
    const featureItems = filteredItems.map(item => {
      return {
        feature: item.feature,
        filteredItem: item
      };
    });
    const cf = new ClosestFacility();
    const ds = Context.instance.aiim.datasets.units;
    const source = ds.getSource();
    const useBluedot = task.criteria.sortBy === "distanceBluedot";
    let fromFeature, fromSource, voField;

    if (useBluedot) {
      let graphic = Context.instance.bluedot.graphic;
      if (graphic) {
        fromFeature = graphic;
      }
    } else {
      fromFeature = cf.getFromFeature();
      fromSource = cf.getFromSource();
    }

    if (fromFeature && fromFeature.geometry) {
      cf.euclideanSort(fromFeature, featureItems, voField, fromSource, source);
      //console.log("euclideanSort.featureItems",featureItems);
      const max = cf.proximityMaxItemsToSort;
      const topItems = featureItems.slice(0,max);
      if (topItems.length > 0) {
        try {
          await cf.networkSort(fromFeature,topItems,true);
          for (let i=0;i<topItems.length;i++) {
            featureItems[i] = topItems[i];
          }
          task.filteredItems = featureItems.map(item => item.filteredItem);
        } catch (ex) {
          console.error("Error sorting by distance",ex);
          task.filteredItems = featureItems.map(item => item.filteredItem);
        }
      }
    }
  }

  async sortUnits(task) {
    const criteria = task.criteria;
    const filteredItems = task.filteredItems;
    let sortBy = criteria.sortBy || "name";
    let sortDir = criteria.sortDir || "asc";
    let byName = (sortBy === "name");
    let byCapacity = (sortBy === "capacity");
    let byDistance = (sortBy === "distanceHome") || (sortBy === "distanceBluedot");

    if (filteredItems && filteredItems.length > 0) {
      if (byName || byCapacity) {
        filteredItems.sort((a,b) => {
          if (byName) {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name);
            } else if(a.name) {
              return -1;
            } else if(b.name) {
              return 1;
            }
          } else {
            if (a.hasCapacity && b.hasCapacity) {
              return a.capacity - b.capacity;
            } else if (a.hasCapacity) {
              return -1;
            } else if (b.hasCapacity) {
              return 1;
            }
          }
          return 0;
        });
        if (sortDir === "desc") {
          filteredItems.reverse();
        }
        task.filteredItems = filteredItems;
      } else if (byDistance) {
        await this.sortByDistance(task);
      }
    }
  }
}
