import React from "react";
import Context from "../../../context/Context";

export function removeEditFromUrl() {
  const lib = Context.instance.lib;
  const url = lib.esri.urlUtils.urlToObject(window.location.href);
  if (url && url.query && url.query.edit === "true") {
    delete url.query.edit;
    const href = lib.esri.urlUtils.addQueryParameters(url.path,url.query);
    window.history.replaceState({},"",href);
  }
}

export function renderDetailsLink(item) {
  if (!item) return null;
  const portalUrl = Context.instance.getPortalUrl();
  let href = portalUrl + "/home/item.html?id=" + encodeURIComponent(item.id);
  href = Context.checkMixedContent(href);
  return (
    <a className={"i-button-clear-no-border i-font-brand-primary"} target="_blank" rel="noopener noreferrer"
      href={href}>{Context.instance.i18n.portalItemBrowser.details}</a>
  );
}
