import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan, { SpaceAssignmentTypes } from "../OfficePlan";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import {addAttributeEdit, fetchGlobalIds} from "./transactionUtil";
import { AreaTypes } from "../../../util/interfaces";
import { IWorkspaceAreaConfig } from "../../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import { IFeatureItem } from "../../components/common/PeopleAssignTo";

/*
  transactions

    addArea
    deleteArea
    renameArea
    unassignArea

*/
export type AreasTask = {
  info: {
    addArea?: boolean,
    areaType: AreaTypes,
    areaId?: string,
    areaItem?: __esri.Graphic,
    areaName?: string,
    data?: {
      config?: IWorkspaceAreaConfig,
      restricted?: boolean,
      roleData?: {
        email: string,
        role: number,
        username: string
      }[]
    },
    deleteArea?: boolean,
    deletePeopleData?: __esri.Graphic[] | IFeatureItem[],
    peopleData?: {
      duplicates: __esri.Graphic[] | IFeatureItem[],
      singleRecords: __esri.Graphic[] | IFeatureItem[]
    },
    renameArea?: boolean,
    unassignArea?: boolean,
    updateArea?: boolean,
    updateAreaRestriction?: boolean,
    updateAreaRoles?: boolean
  }
}
function afterAddArea(results,undoTransaction,method) {
  // fix the undo (i.e. the delete) following an add
  const areasTable = OfficePlan.getActive().areasTable;
  const areasLayerId = areasTable.table.layerId;
  const requestInfo = undoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;

  results.forEach(layerResult => {
    if (layerResult.id === areasLayerId) {
      if (layerResult.addResults && layerResult.addResults.length === 1) {
        const newKey = layerResult.addResults[0].globalId;
        //console.log("******************************* newKey",newKey)

        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          let found = false;
          edits.forEach(layerEdit => {
            if (layerEdit.id === areasLayerId) {
              if (layerEdit.deletes && layerEdit.deletes.length === 1) {
                //console.log("found deletes",layerEdit)
                layerEdit.deletes = [newKey];
                found = true;
              }
            }
          });
          if (found) {
            //console.log("new edits",edits)
            query.edits = JSON.stringify(edits);
          }
        }
      }
    }
  });

  afterAddAreaRoles(results,undoTransaction,method);
}

function afterAddAreaRoles(results,undoTransaction,method) {
  // fix the undo (i.e. the delete) following an add
  const hasAreaRoles = OfficePlan.getActive().hasAreaRoles();
  if (!hasAreaRoles) return;

  const areaRolesTable = OfficePlan.getActive().areaRolesTable;
  const areaRolesLayerId = areaRolesTable.table.layerId;
  const requestInfo = undoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;

  results.forEach(layerResult => {
    if (layerResult.id === areaRolesLayerId) {
      if (layerResult.addResults && layerResult.addResults.length > 0) {
        let newKeys = [];
        layerResult.addResults.forEach(addResult => {
          newKeys.push(addResult.globalId);
        })
        let found = false;
        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          edits.forEach(layerEdit => {
            if (layerEdit.id === areaRolesLayerId) {
              if (layerEdit.deletes && layerEdit.deletes.length > 0) {
                layerEdit.deletes = newKeys;
                found = true;
              }
            }
          });
        }
        if (found) {
          query.edits = JSON.stringify(edits);
        }
        // console.log("found",found,query.edits)
      }
    }
  });
}

function afterUndoDeleteAreaRoles(results,undoTransaction,method) {
  console.log("afterUndoDeleteAreaRoles......................")
  // fix the undo (i.e. the delete) following an add
  const hasAreaRoles = OfficePlan.getActive().hasAreaRoles();
  if (!hasAreaRoles) return;

  const areaRolesTable = OfficePlan.getActive().areaRolesTable;
  const areaRolesLayerId = areaRolesTable.table.layerId;
  const requestInfo = undoTransaction.redoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;

  results.forEach(layerResult => {
    if (layerResult.id === areaRolesLayerId) {
      if (layerResult.addResults && layerResult.addResults.length > 0) {
        let newKeys = [];
        layerResult.addResults.forEach(addResult => {
          //newKeys.push(addResult.globalId);
          newKeys.push(addResult.objectId);
        })
        let found = false;
        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          edits.forEach(layerEdit => {
            if (layerEdit.id === areaRolesLayerId) {
              if (layerEdit.deletes && layerEdit.deletes.length > 0) {
                layerEdit.deletes = newKeys;
                found = true;
              }
            }
          });
        }
        if (found) {
          query.edits = JSON.stringify(edits);
        }
        console.log("afterUndoDeleteAreaRoles.found",found,query.edits)
      }
    }
  });
}

export function prepare(task: AreasTask) {  
  const transactionInfo = transaction.makeTransactionInfo();
  const peopleSource = sourceUtil.getPeopleSource();
  const unitsSource = sourceUtil.getUnitsSource();
  const info = task.info;
  const areaItem = info.areaItem;
  const areaType = info.areaType;
  const areasTable = OfficePlan.getActive().areasTable;
  const areaId = areaItem && aiimUtil.getAttributeValue(
    areaItem.attributes,FieldNames.AREA_ID);
  const areaIds = [areaId];
  const peopleData = info.peopleData
  const deletePeopleData = info.deletePeopleData;
  const duplicates = deletePeopleData || (peopleData && peopleData.duplicates) || []
  const singleRecords = (peopleData && peopleData.singleRecords) || []
  const hasAreaRoles = OfficePlan.getActive().hasAreaRoles();
  const areaRolesTable = OfficePlan.getActive().areaRolesTable;
  let areaRoleData;

  const makeAreaRoleEdits = () => {
    if (!hasAreaRoles) return;

    const table = areaRolesTable.table;
    const fields = table.fields;
    const keyField = table.globalIdField;

    let adds = [], undoAdds = []
    let deletes = [], undoDeletes = []

    if (hasAreaRoles && info.addArea) {
      if (info.data.roleData) {
        info.data.roleData.forEach(item => {
          const edit = {attributes: {}};
          addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_AREA_ID,info.areaId);
          addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_ROLE,item.role);
          addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_EMAIL,item.email);
          addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_USERNAME,item.username);
          adds.push(edit);
        })
      }
      if (adds.length > 0) {
        undoAdds = ["?"] // needs to be populated following add
      }
    }

    if (hasAreaRoles && info.deleteArea) {
      if (areaRoleData && areaRoleData.items) {
        areaRoleData.items.forEach(item => {
          const feature = item.feature;
          const key = feature.attributes[keyField]
          const undo = {attributes: Object.assign({}, feature.attributes)}
          deletes.push(key)
          undoDeletes.push(undo)
        })
      }
    }

    if (hasAreaRoles && info.updateAreaRoles) {
      if (areaRoleData && areaRoleData.items && info.data.roleData) {

        info.data.roleData.forEach(item => {
          const found = areaRoleData.items.some(item2 => {
            const a = item.email && item.email.toLowerCase();
            const b = item2.email && item2.email.toLowerCase();
            return (a && a === b && item.role === item2.role);
          });
          if (!found) {
            const edit = {attributes: {}};
            addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_AREA_ID,areaId);
            addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_ROLE,item.role);
            addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_EMAIL,item.email);
            addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ROLES_USERNAME,item.username);
            adds.push(edit);
          }
        })
        if (adds.length > 0) {
          undoAdds = ["?"] // needs to be populated following add
          transactionInfo.useGlobalIds = false;
          transactionInfo.afterApplyEdits = afterAddAreaRoles;
          //transactionInfo.undo.afterApplyEdits = afterAddAreaRoles;
        }

        areaRoleData.items.forEach(item => {
          const found = info.data.roleData.some(item2 => {
            const a = item.email && item.email.toLowerCase();
            const b = item2.email && item2.email.toLowerCase();
            return (a && a === b && item.role === item2.role);
          });
          if (!found) {
            const feature = item.feature;
            let key = feature.attributes[keyField]
            if (!transactionInfo.useGlobalIds) {
              key = feature.attributes[table.objectIdField]
              transactionInfo.undo.afterApplyEdits = afterUndoDeleteAreaRoles;
            }
            const undo = {attributes: Object.assign({}, feature.attributes)}
            deletes.push(key)
            undoDeletes.push(undo)
          }
        })

      }
    }

    if (adds.length > 0 || deletes.length > 0) {
      transactionInfo.edits.push({
        id: table.layerId,
        adds: adds,
        deletes: deletes
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        adds: undoDeletes,
        deletes: undoAdds
      });
    }

  }

  const makePeopleEdits = (keys: string[]) => {
    let updates = [], undoUpdates = [], deletes = [], undoDeletes = [];
    const layer = peopleSource.layer2D;
    const fields = layer.fields;
    const keyField = layer.globalIdField;

    if (duplicates && duplicates.length > 0) {
      duplicates.forEach(person => {
        const personFeature = person.feature || person
        const key = personFeature.attributes[keyField]
        const undo = {attributes: Object.assign({}, personFeature.attributes)}
        deletes.push(key)
        undoDeletes.push(undo)
      })
    }

    if (singleRecords && singleRecords.length > 0) {
      singleRecords.forEach(person => {
        const personFeature = person.feature || person
        const key = personFeature.attributes[keyField]
        const update = {attributes: {}}
        update.attributes[keyField] = key
        addAttributeEdit(fields, update.attributes, FieldNames.PEOPLE_AREA_ID, null)
        updates.push(update)
        const undo = {attributes: {}}
        undo.attributes[keyField] = key
        addAttributeEdit(fields, undo.attributes, FieldNames.PEOPLE_AREA_ID, areaId)
        undoUpdates.push(undo)
      })
    }

    if (Array.isArray(keys) && !peopleData) {
      keys.forEach(key => {
        if (deletePeopleData) {
          if (deletes.indexOf(key) !== -1) return;
        }
        const update = {attributes: {}};
        update.attributes[keyField] = key;
        addAttributeEdit(fields,update.attributes,FieldNames.PEOPLE_AREA_ID,null);
        updates.push(update);
        const undo = {attributes: {}};
        undo.attributes[keyField] = key;
        addAttributeEdit(fields,undo.attributes,FieldNames.PEOPLE_AREA_ID,areaId);
        undoUpdates.push(undo);
      });
    }

    if (updates.length > 0 || deletes.length > 0) {
      transactionInfo.peopleEdits = true;
      transactionInfo.undo.peopleEdits = true;
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates,
        deletes: deletes
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undoUpdates,
        adds: undoDeletes
      });
    }
  };

  const makeUnitEdits = (keys: { [gid: string]: number | Omit<SpaceAssignmentTypes, "home"> }) => {
    const updates = [], undos = [];
    const layer = unitsSource.layer2D;

    if (typeof keys === "object") {
      const fields = layer.fields;
      const keyField = layer.globalIdField;
      const none = OfficePlan.SpaceAssignmentTypes.none;
      for (const key in keys) {
        const update = {attributes: {}};
        update.attributes[keyField] = key;
        addAttributeEdit(fields,update.attributes,FieldNames.UNITS_AREA_ID,null);
        addAttributeEdit(fields,update.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,none);
        addAttributeEdit(fields,update.attributes,FieldNames.UTILIZATION,0);
        updates.push(update);
        const undo = {attributes: {}};
        undo.attributes[keyField] = key;
        const asnType = keys[key];
        addAttributeEdit(fields, undo.attributes, FieldNames.UNITS_AREA_ID, areaId);
        addAttributeEdit(fields,undo.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType);
        addAttributeEdit(fields,undo.attributes,FieldNames.UTILIZATION,0);
        undos.push(undo);
      };
    }

    if (updates.length > 0) {
      transactionInfo.unitEdits = true;
      transactionInfo.undo.unitEdits = true;
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undos
      });
    }
  };

  const makeAreasTableEdits = () => {
    const table = areasTable.table;
    const fields = table.fields;
    const keyField = aiimUtil.getGlobalIdField(table);
    //const keyField = table.objectIdField;

    if (info.addArea) {
      const edit = {attributes: {}};
      addAttributeEdit(fields,edit.attributes,FieldNames.AREA_ID,info.areaId);
      addAttributeEdit(fields,edit.attributes,FieldNames.AREA_NAME,info.areaName);
      addAttributeEdit(fields,edit.attributes,FieldNames.AREA_TYPE,info.areaType);
      // Disable Restrict and Reservation Manager property for hot desk #4704
      const rv = info.data.restricted ? 1 : 0;
      addAttributeEdit(fields,edit.attributes,FieldNames.AREAS_RESTRICTED,rv);
      if (info.data && info.data.hasOwnProperty("config")) {
        const config = info.data.config, attr = edit.attributes;
        addAttributeEdit(fields,attr,FieldNames.AREAS_CONFIG,config);
      }

      transactionInfo.areasTableEdits = true;
      transactionInfo.undo.areasTableEdits = true;
      transactionInfo.edits.push({
        id: table.layerId,
        adds: [edit]
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        deletes: ["?"] // needs to be populated following add
      });
      transactionInfo.useGlobalIds = false;
      transactionInfo.afterApplyEdits = afterAddArea;

      makeAreaRoleEdits();
    }

    if (info.deleteArea) {
      const key = areaItem.attributes[keyField];;
      const undo = {attributes: Object.assign({},areaItem.attributes)};

      transactionInfo.areasTableEdits = true;
      transactionInfo.undo.areasTableEdits = true;
      transactionInfo.edits.push({
        id: table.layerId,
        deletes: [key]
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        adds: [undo]
      });
    }

    if (info.updateArea) {
      const name = info.areaName;
      const key = areaItem.attributes[keyField];
      const update = {attributes: {}};
      update.attributes[keyField] = key;
      addAttributeEdit(fields,update.attributes,FieldNames.AREA_NAME,name);
      if (typeof info.data.restricted === "boolean") {
        const rv = info.data.restricted ? 1 : 0;
        addAttributeEdit(fields,update.attributes,FieldNames.AREAS_RESTRICTED,rv);
      }
      if (info.data && info.data.hasOwnProperty("config")) {
        const config = info.data.config, attr = update.attributes;
        addAttributeEdit(fields,attr,FieldNames.AREAS_CONFIG,config);
      }
      const undo = {attributes: Object.assign({},areaItem.attributes)};
      undo.attributes[keyField] = key;

      transactionInfo.areasTableEdits = true;
      transactionInfo.undo.areasTableEdits = true;
      transactionInfo.edits.push({
        id: table.layerId,
        updates: [update]
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        updates: [undo]
      });
    }

    if (info.renameArea) {
      const name = info.areaName;
      const prevName = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREA_NAME);
      const key = areaItem.attributes[keyField];
      const update = {attributes: {}};
      update.attributes[keyField] = key;
      addAttributeEdit(fields,update.attributes,FieldNames.AREA_NAME,name);
      const undo = {attributes: {}};
      undo.attributes[keyField] = key;
      addAttributeEdit(fields,undo.attributes,FieldNames.AREA_NAME,prevName);

      transactionInfo.areasTableEdits = true;
      transactionInfo.undo.areasTableEdits = true;
      transactionInfo.edits.push({
        id: table.layerId,
        updates: [update]
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        updates: [undo]
      });
    }

    if (info.updateAreaRestriction) {
      const rv = info.data.restricted ? 1 : 0;
      const prevVal = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREAS_RESTRICTED);
      const key = areaItem.attributes[keyField];
      const update = {attributes: {}};
      update.attributes[keyField] = key;
      addAttributeEdit(fields,update.attributes,FieldNames.AREAS_RESTRICTED,rv);
      const undo = {attributes: {}};
      undo.attributes[keyField] = key;
      addAttributeEdit(fields,undo.attributes,FieldNames.AREAS_RESTRICTED,prevVal);

      transactionInfo.areasTableEdits = true;
      transactionInfo.undo.areasTableEdits = true;
      transactionInfo.edits.push({
        id: table.layerId,
        updates: [update]
      });
      transactionInfo.undo.edits.push({
        id: table.layerId,
        updates: [undo]
      });
    }

  };

  return Promise.resolve().then(() => {
    if (info.unassignArea) {
      return fetchGlobalIds(peopleSource,areaIds,areaType).then(result => {
        makePeopleEdits(Object.keys(result));
      });
    }
  }).then(() => {
    if (info.unassignArea) {
      return fetchGlobalIds(unitsSource,areaIds,areaType).then(result => {
        makeUnitEdits(result);
      });
    }
  }).then(() => {
    if ((info.deleteArea  || info.updateAreaRoles) && hasAreaRoles) {
      return areaRolesTable.queryRoleDataByAreaId(areaId).then(result => {
        areaRoleData = result;
      });
    }
  }).then(() => {
    if (!info.updateAreaRoles) {
      makeAreasTableEdits();
    } else {
      makeAreaRoleEdits();
    }
  }).then(() => {
    return transactionInfo;
  });
}
