import React from "react";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import QueryAll from "./QueryAll";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import * as sourceUtil from "./sourceUtil";
import { ReplacePlaceholder } from "./ReplacePlaceholder";
import { getPlaceholdersToReplace, makeReplaceRequest } from "./replacePlaceholders";
import { ModalController } from "../../common/components/Modal";
import Button from "../../common/components/Button";

export async function readPlaceholderIds(task) {
  if (!sourceUtil.getPeopleLayer()) return null;
  const data = {
    globalIdIndex: {},
    objectIdIndex: {},
    unitIdIndex: {},
    knownas: {}
  }
  const layer = sourceUtil.getPeopleLayer();
  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const objectIdField = layer.objectIdField;
  const globalIdField = layer.globalIdField;
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);
  const unitIdField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_UNIT_ID);
  const knownasField = aiimUtil.findFieldName(layer.fields, FieldNames.PEOPLE_FULLNAME);
  if (!emailField || !unitIdField) {
    return null;
  }
  const where = emailField + " LIKE 'placeholder%'";
  let hasData = false, hasUnitIds = false;
  const opts = {
    layer: layer,
    perFeatureCallback: f => {
      if (!hasData) hasData = true;
      let oid = f.attributes[objectIdField];
      let gid = f.attributes[globalIdField];
      let uid = f.attributes[unitIdField];
      let name = f.attributes[knownasField];
      if (typeof gid === "string") gid = gid.toUpperCase();
      data.globalIdIndex[gid] = true;
      data.objectIdIndex[oid] = true;
      data.knownas[name] = true;
      if (uid)  {
        if (!hasUnitIds) hasUnitIds = true;
        data.unitIdIndex[uid] = true;
      }
    }
  }
  const query = new Context.instance.lib.esri.Query();
  query.outFields = ["*"];
  query.returnGeometry = false;
  query.returnZ = false;
  query.where = where;
  queryUtil.applyGdbVersion(layer,query);
  const qa = new QueryAll();
  await qa.execute(url,query,opts);

  if (task && task.versionManager && hasData) {
    const peopleLayerId = sourceUtil.getPeopleLayer().layerId;
    const unitsLayerId = sourceUtil.getUnitsLayer().layerId;
    const options = {plan: task.plan};
    const result = await task.versionManager.hasDifferences(options);
    const differences = result && result.differences;
    if (Array.isArray(differences)) {
      const rows = [];
      for (const d of differences) {
        let list = [];
        if (d.updates && d.updates.length > 0) {
          list = list.concat(d.updates);
        } 
        if (d.inserts && d.inserts.length > 0) {
          list = list.concat(d.inserts);
        }
        if (d.deletes && d.deletes.length > 0) {
          list = list.concat(d.deletes);
        }

        if (list.length > 0) {
          let oidIndex;
          if (d.layerId === peopleLayerId) {
            oidIndex = data.objectIdIndex
            list = list.filter(oid => {
              const keep = !oidIndex[oid];
              //if (!keep) console.log("placeholder.dontPost.occupant",oid)
              return keep;
            })
          } else if (hasUnitIds && d.layerId === unitsLayerId) {
            // @todo check hasUnitIds
            await task.plan.unitNameCache.load();
            const unitIdIndex = data.unitIdIndex;
            const uidsPerObjectId = task.plan.unitNameCache.uidsPerObjectId;
            list = list.filter(oid => {
              const uid = uidsPerObjectId[oid];
              const keep = !unitIdIndex[uid];
              //if (!keep) console.log("placeholder.dontPost.unit",uid)
              return keep;
            })
          }
        }

        if (list.length > 0) {
          rows.push({
            layerId: d.layerId,
            objectIds: list
          })
        }
      }
      
      data.partialPostRows = rows;
      task.partialPostRows = rows;
    }
    //console.log("task.partialPostRows",task.partialPostRows)
  }

  if (hasData) return data;
  else return null;
}

/**
* Get matched placeholders with new occupants in the default plan, and add it to the option that used in merge.
* @param {Object} options Options to query placeholder occupants.
* @param {Object} workingController working controller to display a message while retrieving placeholders.
*/
export const checkReplacePlaceholders = async (options, workingController) => {
  const i18n = Context.instance.i18n;
  const res = await getPlaceholdersToReplace(options);
  let occupants;
  let placeholders;
  let selectedPlaceholders;
  let okButton;
  let skipped = false;
  let controller;
  if (res && res.occupants) occupants = res.occupants;
  if (res && res.placeholders) placeholders = res.placeholders; 
  if (occupants.length > 0 && placeholders.length > 0) {
    // replace placeholders options
    const placeholderOptions = {
      className: "i-modal-confirm i-modal",
      title: i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.title,
      content: <ReplacePlaceholder occupants={occupants} placeholders={placeholders} onChange={(checked, placeholders) => {
        if (Object.values(checked).some(x => x === true)) okButton.enable();
        else okButton.disable();
        selectedPlaceholders = placeholders; 
      }} />,
      okLabel: i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.okLabel,
      // TODO: replace hardcoded string after the string freeze
      cancelLabel: i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.cancelMerge,
      closeOnCancel: true,
      extraButton: (
        <Button
          className="i-extra-button"
          name='skip'
          onClick={() => {
            options.cancelMerge = false;
            skipped = true;
            if (controller) controller.close();
            return;
          }}
        >{i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.skipButton}</Button>
      ),
      onMountOKButton: btn => {
        okButton = btn;
        okButton.disable();
      },
      onMount: (obj) => {
        controller = obj;
      }
    }
    workingController.close();
    await ModalController.confirm(placeholderOptions).then(async (result) => {
      if (result.ok) {
        if (selectedPlaceholders && selectedPlaceholders.length > 0) {
          const edits = makeReplaceRequest(occupants, selectedPlaceholders);
          options.replacePlaceholderEdits = edits;
        }
      } else {
        if (!skipped) options.cancelMerge = true;
      }
    }).catch(err => {
      console.error(err);
    })
  }
}
