import React from "react";
import { useSelector } from "react-redux";
import Rdx, { IRootState } from "../../../../../redux/Rdx";

import OtherBookings from "./OtherBookings";
import MyBookings from "./MyBookings";
import { IDateCriteria } from "./BookWorkspace";
interface IBookingsTabProps {
  bookingDateFilter: { criteria?: IDateCriteria, where?: string }
}
const BookingsTab = ({
  bookingDateFilter
}: IBookingsTabProps) => {

  const rdxTabSelected = useSelector((state: IRootState): "self" | "others" =>
    Rdx.getValue(state, Rdx.Keys.BOOK_TAB_SELECTED));

  const renderBookings = () => {
    if (rdxTabSelected === "self") {
      return <MyBookings bookingDateFilter={bookingDateFilter} />
    } else if (rdxTabSelected === "others") {
      return <OtherBookings bookingDateFilter={bookingDateFilter} />
    } else {
      return null;
    }
  }
  return renderBookings();
}
export default BookingsTab;
