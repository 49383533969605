import moment from "moment";

// aiim.datasets
import FieldNames from "../../../../../../aiim/datasets/FieldNames";

// aiim.util
import { findField, findFieldName, getAttributeValue, getGlobalIdField, getRecurrencePattern } from "../../../../../../aiim/util/aiimUtil";

// components.main.InfoPanel
import { generateShareUrlAsync } from "../../../../InfoPanel/shareUtil";

// context
import Context from "../../../../../../context/Context";
import Topic from "../../../../../../context/Topic";
import { applyGdbVersion } from "../../../../../../spaceplanner/base/queryUtil";

// main.More.Actions.BookWorkspace.WorkspaceReservation
import BookingSystem, { IEsriBookingParams, IBookingSystem, ICheckAvailabilityParams, ICancelBookingParams, IBookingTask } from "./BookingSystem";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from "../../../../../../spaceplanner/base/ReviewerManagement/reviewersUtil";
import { IApplyEditsOptions, IApplyEditsResult, IFeature } from "@esri/arcgis-rest-feature-layer";
import type { CustomQueryTask } from "../../../../../../context/EsriLib";
import type Reservations from "../../../../../../aiim/datasets/Reservations";
import { IRecurrenceOptions } from "../BookingRecurrence";
import { isSameDay } from "react-dates";
import { generateRandomUuid } from "../../../../../../util/val";
import QueryAll from "../../../../../../spaceplanner/base/QueryAll";
export enum BOOKING_STATE {
  PENDING_APPROVAL = 0,
  APPROVED = 1,
  CANCELED = 3,
  CHECKED_IN = 4,
  CHECKED_OUT = 5
}
const PENDING_APPROVAL = BOOKING_STATE.PENDING_APPROVAL;
const APPROVED = BOOKING_STATE.APPROVED;
const CANCELED = BOOKING_STATE.CANCELED;
const CHECKED_IN = BOOKING_STATE.CHECKED_IN;
const CHECKED_OUT = BOOKING_STATE.CHECKED_OUT;

const ALL_DAY = 1;
const NOT_ALL_DAY = 0;
export default class EsriReservationSchema extends BookingSystem {
  static instance: IBookingSystem;

  constructor() {
    super();
    this.type = "esri";
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new EsriReservationSchema();
    }
    return this.instance;
  }

  login = () => {
    if (Context.getInstance().user.isAnonymous()) {
      Topic.publish(Topic.SignInClicked, {});
    }
    return Promise.resolve();
  };

  getAccessToken = () => {
    const esriId = Context.instance.lib.esri.esriId;
    const credential = esriId.findCredential(Context.instance.getPortalUrl());
    return Promise.resolve(credential && credential.token);
  };

  getBookingSchedule = (params: IEsriBookingParams, signal?: AbortSignal) => {
    const { checkInDate, checkOutDate, unitId, operation, objectIds } = params;
    let ignoreObjectIds: boolean | number[] = false;
    if (operation && operation === "updateBookingTime") {
      ignoreObjectIds = [...(objectIds || [])]
    }
    return this.checkAvailability([unitId], checkInDate as string, checkOutDate as string, ignoreObjectIds, params, signal);
  };

  logout = () => {
    return;
  };

  getLoggedInUser = () => {
    return Context.getInstance().user.portalUser;
  };

  bookWorkspace = async (params: IEsriBookingParams) => {
    const i18n = Context.instance.i18n;
    const reservationsDataset = Context.instance.aiim.datasets.reservations;
    const layer = reservationsDataset && reservationsDataset.layer2D;
    const objectIdField = layer && layer.objectIdField;
    const globalIdField = getGlobalIdField(layer);
    if (!layer) {
      return Promise.reject("No RESERVATIONS layer found");
    }
    if (params.recurrence?.enabled && !params.recurrence.modified
      && !isSameDay(moment(params.checkIn), moment(params.checkOut))) {
      throw new Error(params.bookingType === "meetingRooms"
        ? i18n.calendars.recurringToggleDisableMeetingRoom
        : i18n.calendars.recurringToggleDisable);
    }
    let autoCancelUpdates = params.autoCancelUpdates;
    const { reservations, rejections } = await this._createReservationFeatures(params);

    return this._addReservationFeatures(reservations, autoCancelUpdates)
      .then((results) => {
        const addFeatureResults = results.data && (results.data as IApplyEditsResult).addResults;
        if (addFeatureResults && addFeatureResults.some(result => !result.success)) {
          const hasLengthError = (r => r.error && r.error.code === 1000);
          if (addFeatureResults.some(hasLengthError)) {
            const titleField = layer.fields.find(f => f.name === reservationsDataset.titleField);
            const i18n = Context.instance.i18n;
            // check if title is longer than allowed
            if (reservations.some(r => r.attributes[reservationsDataset.titleField]?.length > titleField.length)) {
              console.error(addFeatureResults);
              throw new Error(i18n.meetingRooms.book.descriptionLimitError);
            } else {
              // @ts-ignore
              throw new Error(addFeatureResults.find(hasLengthError)?.error.description);
            }
          }
        }
        const objectIds = addFeatureResults.map(result => result.objectId);
        const globalIds = addFeatureResults.map(result => result.globalId);
        if (objectIds) {
          params.objectIds = objectIds;
          if (objectIds.length > 0) {
            reservations.forEach((reservation, i) => {
              reservation.attributes[objectIdField] = objectIds[i];
              reservation.attributes[globalIdField] = globalIds[i];
            });
          }
        } else {
          console.error(addFeatureResults);
          throw new Error("Unable to add reservation(s)");
        }
        return this._verifyUnitAvailability(params, reservations);
      })
      .then(() => {
        Topic.publish(Topic.ReservationsUpdated, {});
        return { reservations, rejections, params };
      });
  };

  updateBooking = async(info: IEsriBookingParams)=> {
    const reservationsDataset = Context.instance.aiim.datasets.reservations;
    const layer = reservationsDataset && reservationsDataset.layer2D;
    const objectIdField = layer.objectIdField;
    const globalIdField = getGlobalIdField(layer);
    const { recurrence, series, origReservation } = info;
    const updates = [], adds = [], deletes = [];
    
    if (info && info.operation === "updateBookingTime") {
      let allDay = 0;
      if (info && info.allDay) allDay = 1;
      // get highest sequence from series and add 1
      const seriesSequence = (series?.allOccurrences
        .map(r => getRecurrencePattern(r.attributes).sequence ?? 0)
        .sort((a, b) => a < b ? 1 : a > b ? -1 : 0)[0] ?? 0) + 1;
      if (recurrence?.enabled && (!recurrence.modified || recurrence.modified === "series") && series) {
        const recurringDates = this.getRecurringDates(recurrence, moment(info.checkIn), moment(info.checkOut));
        if (recurringDates.length < series.occurrences.length) {
          updates.push(...series.occurrences.slice(recurringDates.length).map(o => ({
            attributes: {
              [objectIdField]: o.attributes[objectIdField],
              [reservationsDataset.stateField]: CANCELED
            }
          })));
        }
        recurringDates.forEach((dates, i) => {
          const rCheckIn = dates.start.toDate();
          const rCheckOut = dates.end.toDate();
          const { enabled, id, days, endDate, ...common } = recurrence;
          const update = { attributes: {} };
          update.attributes[reservationsDataset.startTimeField] = rCheckIn.getTime();
          update.attributes[reservationsDataset.endTimeField] = rCheckOut.getTime();
          update.attributes[reservationsDataset.allDayField] = allDay;
          if (info && info.bookingType === "meetingRooms") update.attributes[reservationsDataset.titleField] = info.title;
          const existing = series.occurrences[i];
          if (existing) {
            update.attributes[objectIdField] = existing.attributes[objectIdField];
            updates.push(update);
          } else {
            const params: IEsriBookingParams = { ...info, checkIn: rCheckIn, checkOut: rCheckOut };
            const person = origReservation
              ? {
                username: origReservation.attributes[reservationsDataset.reservedForUsernameField],
                fullName: origReservation.attributes[reservationsDataset.reservedForFullNameField]
              }
              : null;
            const add = this._createReservationFeature({ ...params, person });
            add.attributes[reservationsDataset.stateField] = APPROVED;
            add.attributes[reservationsDataset.recurrenceIdField] = series.id;
            adds.push(add);
          }
        });
        updates.concat(adds).forEach(f => {
          const { enabled, id, days, endDate, ...common } = recurrence;
          let recurrenceConfig = JSON.stringify({
            ...common,
            ...recurrence.type === "weekly" && { days },
            endDate: recurrence.endDate.getTime(),
            modified: "series",
            sequence: seriesSequence
          });
          f.attributes[reservationsDataset.recurrenceConfigField] = recurrenceConfig;
        });
      } else {
        const update = { attributes: {} };
        update.attributes[objectIdField] = info.objectIds[0];
        update.attributes[reservationsDataset.startTimeField] = (new Date(info.checkIn)).getTime();
        update.attributes[reservationsDataset.endTimeField] = (new Date(info.checkOut)).getTime();
        update.attributes[reservationsDataset.allDayField] = allDay;
        if (info && info.bookingType === "meetingRooms") update.attributes[reservationsDataset.titleField] = info.title;
        if (recurrence?.modified === "occurrence" || recurrence) {
          const { enabled, id, days, sequence, ...common } = recurrence;
          const recurrenceConfig = JSON.stringify({
            ...common,
            ...recurrence.type === "weekly" && { days },
            modified: "occurrence",
            sequence: seriesSequence
          });
          update.attributes[reservationsDataset.recurrenceConfigField] = recurrenceConfig;
        }
        updates.push(update);
      }
    }
    const layerUrl = reservationsDataset && reservationsDataset.url;
    const url = layerUrl+"/applyEdits";
    const params = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      adds: JSON.stringify(adds),
      updates: JSON.stringify(updates)
    };
    const options: __esri.RequestOptions = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationsDataset, options.query);

    const request: typeof __esri.request = Context.getInstance().lib.esri.esriRequest;
    const response = await request(url, options);
    const { updateResults, addResults }: IApplyEditsResult = (response?.data as IApplyEditsResult);
    const success = updateResults.every(r => r.success) && addResults.every(r => r.success);
    if (success) {
      if (info && info.bookingForOther) return response;
      else {
        // updates are from index 0 through series.occurrences.length - 1
        // adds are from index series.occurrences.length through the end of the array
        const updateIds = updateResults.map(result => result.objectId);
        const updateGuids = updateResults.map(result => result.globalId);
        const addIds = addResults.map(result => result.objectId);
        const addGuids = addResults.map(result => result.globalId);
        const { reservations, rejections } = await this._createReservationFeatures(info);
        if (updateIds) {
          info.objectIds = updateIds.concat(addIds);
          const globalIds = updateGuids.concat(addGuids);
          if (updateIds.length > 0) { // no updateIds means no addIds either
            if (adds.length === 0) {
              reservations.forEach((reservation, i) => {
                reservation.attributes[objectIdField] = updateIds[i];
                reservation.attributes[globalIdField] = globalIds[i];
              });
            } else {
              reservations.forEach((reservation, i) => {
                reservation.attributes[objectIdField] = i < updateIds.length ? updateIds[i] : addIds[i - updateIds.length];
                reservation.attributes[globalIdField] = i < updateGuids.length ? updateGuids[i] : addGuids[i - updateGuids.length];
              });
            }
          }
        }
        return { reservations, rejections };
      }
    } else {
      // @ts-ignore
      if (addResults?.some(result => result.error && result.error.code === 1000)) {
        console.error(addResults);
        const i18n = Context.instance.i18n;
        throw new Error(i18n.meetingRooms.book.descriptionLimitError);
      }
      throw new Error("Unable to update reservation(s)");
    }
  }

  _addReservationFeatures = (reservations: IFeature[], autoCancelUpdates: IFeature[]) => {
    const reservationDataset = Context.instance.aiim.datasets.reservations;
    let layerUrl = reservationDataset.url;
    const url = layerUrl + "/applyEdits";
    const lib = Context.getInstance().lib;
    const request: typeof __esri.request = lib.esri.esriRequest;
    const options: __esri.RequestOptions = {
      query: {
        f: "json",
        useGlobalIds: false,
        adds: JSON.stringify(reservations),
        rollbackOnFailure: true
      },
      responseType: "json",
      method: "post"
    };
    if (autoCancelUpdates && autoCancelUpdates.length > 0) {
      options.query.updates = JSON.stringify(autoCancelUpdates);
    }
    applyGdbVersion(reservationDataset, options.query);

    return request(url, options);
  }

  _verifyUnitAvailability = (params: IEsriBookingParams, reservations: IFeature[]) => {    
    const { objectIds } = params;
    const ignoreIds = objectIds.slice();
    const promises = [];
    if (params.isUpdateBooking) ignoreIds.push(...(params.reservationObjectIds || []));
       
    promises.push(...reservations.map((reservation) => {
      const attrs = reservation.attributes;
      const startTime = getAttributeValue(attrs, FieldNames.START_TIME);
      const endTime = getAttributeValue(attrs, FieldNames.END_TIME);
      const unitId = getAttributeValue(attrs, FieldNames.UNIT_ID);
      return this.checkAvailability([unitId], startTime, endTime, ignoreIds).then((availableUnits) => {
        if (!params.isUpdateBooking) {
          if (availableUnits && availableUnits.length === 1 && availableUnits[0] === unitId) {
            return this._updateReservationFeatures(objectIds);
          } else {
            const err = new Error("Booking lock conflict, aborting reservation...");
            // @ts-ignore
            err.code = "booking-lock-conflict";
            throw err;
          }
        } else {
          if (availableUnits && availableUnits.length === 1 && availableUnits[0] === unitId) {
            // na
          } else {
            const err = new Error("Booking lock conflict, aborting reservation update...");
            // @ts-ignore
            err.code = "booking-lock-conflict";
            throw err;
          }
        }
      });
    }));    

    return Promise.all(promises).then(() => {}).catch((e) => {
      this._deleteConflictingReservations(objectIds);
      throw e;
    });
  }

  _updateReservationFeatures = (objectIds: number[]) => {
    if (!objectIds) {
      return Promise.resolve();
    }

    const reservationDataset = Context.getInstance().aiim.datasets.reservations;
    const layerUrl = reservationDataset && reservationDataset.url;
    const layer = reservationDataset && reservationDataset.layer2D;
    const objectIdField = layer.objectIdField;

    const updates = [];

    if (objectIds && objectIds.length > 0)
    objectIds.forEach((objectId) => {
      const update = { attributes: {} };
      update.attributes[objectIdField] = objectId;
      update.attributes[reservationDataset.stateField] = APPROVED;
      updates.push(update);
    });

    const url = layerUrl+"/applyEdits";
    const params = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      updates: JSON.stringify(updates)
    };
    const options = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationDataset, options.query);

    const lib = Context.getInstance().lib;
    return lib.esri.esriRequest(url,options);
  }

  deletePseudoLock = async (objectIds: number[]) => {
    try {
      await this._deleteConflictingReservations(objectIds);
    } catch (error) {
      console.error("Deleting pseudo lock", error);
    }
  }

  _deleteConflictingReservations = (objectIds: number[]) => {
    if (!objectIds) {
      return Promise.resolve();
    }

    const reservationDataset = Context.getInstance().aiim.datasets.reservations;
    const layerUrl = reservationDataset && reservationDataset.url;
    const url = layerUrl+"/applyEdits";
    const params = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      deletes: JSON.stringify(objectIds)
    };
    const options = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationDataset, options.query);

    const lib = Context.getInstance().lib;
    return lib.esri.esriRequest(url,options);
  }
  private _createRecurringReservationFeatures(params: IEsriBookingParams) {
    if (!params.recurrence?.enabled) return [];

    const { reservations: { recurrenceIdField } } = Context.instance.aiim.datasets;
    const recurrence = { ...params.recurrence }; // clone
    const recurrenceId = params.series?.id ?? params.recurrence?.id;
    if (recurrenceIdField) {
      recurrence.id = recurrenceId ?? generateRandomUuid();
    }
    const recurringDates = this.getRecurringDates(recurrence, moment(params.checkIn), moment(params.checkOut));
    return recurringDates.map(dates => {
      const rCheckIn = dates.start.toDate();
      const rCheckOut = dates.end.toDate();
      return this._createReservationFeature({ ...params, recurrence, checkIn: rCheckIn, checkOut: rCheckOut });
    });
  }
  private _createReservationFeatures = async (params: IEsriBookingParams) => {
    const reservations: IFeature[] = [];
    if (params.others && params.others.length > 0) {
      const rejections: IFeature[] = [];
      for (const feature of params.others) {
        const email = getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
        try {
          const result = await getPortalUserByEmail({ email });
          const username = getUsernameFromPortalUsers(result, feature);
          const person = {
            username: username,
            fullName: getAttributeValue(feature.attributes, FieldNames.PEOPLE_FULLNAME)
          };
          if (params.recurrence?.enabled && !params.recurrence.modified) {
            reservations.push(...this._createRecurringReservationFeatures({ ...params, person }));            
          } else {
            reservations.push(this._createReservationFeature({ ...params, person }));
          }
        } catch (e) {
          console.error(e.message);

          // Add people who weren't found in the org to the rejected list
          if (e.message.includes("No result in your organization for user")) {
            rejections.push(feature);
          }
        }
      }

      return { reservations, rejections };
    }
    if (params.recurrence?.enabled && !params.recurrence.modified) {
      reservations.push(...this._createRecurringReservationFeatures(params));
    } else {
      reservations.push(this._createReservationFeature(params));
    }
    return { reservations, rejections: null };
  }

  private _createReservationFeature = (params: IEsriBookingParams): IFeature => {
    const { unit, checkIn, checkOut, allDay, item, person, recurrence } = params;
    const reservations = Context.instance.aiim.datasets.reservations;
    const unitsDataset = Context.instance.aiim.datasets.units;
    const i18n = Context.instance.i18n;
    const user = this.getLoggedInUser();
    const state = PENDING_APPROVAL;
    const allDayValue = allDay ? ALL_DAY : NOT_ALL_DAY;

    const attributes = {};
    const geometry = unit.geometry;

    // RESERVED_BY_USERNAME, RESERVED_FOR_USERNAME
    if (reservations.reservedByUsernameField) {
      attributes[reservations.reservedByUsernameField] = user.username;
    }
    attributes[reservations.reservedForUsernameField] = person ? person.username : user.username;

    // RESERVED_BY_FULL_NAME, RESERVED_FOR_FULL_NAME
    if (reservations.reservedByFullNameField) {
      attributes[reservations.reservedByFullNameField] = user.fullName;
    }
    attributes[reservations.reservedForFullNameField] = person ? person.fullName : user.fullName;

    // STATE
    attributes[reservations.stateField] = state;

    // START_TIME, END_TIME
    attributes[reservations.startTimeField] = new Date(checkIn).getTime();
    attributes[reservations.endTimeField] = new Date(checkOut).getTime();

    // ALL_DAY
    attributes[reservations.allDayField] = allDayValue;

    // UNIT_ID, UNIT_NAME, LEVEL_ID
    const unitId = getAttributeValue(unit.attributes, FieldNames.UNIT_ID);
    const unitName = getAttributeValue(unit.attributes, FieldNames.NAME);
    const levelData = unitsDataset.getLevelData(unit);
    const levelId = levelData && levelData.levelId;
    if(reservations.unitIdField) attributes[reservations.unitIdField] = unitId;
    if(reservations.unitNameField) attributes[reservations.unitNameField] = unitName;
    if(reservations.levelIdField) attributes[reservations.levelIdField] = levelId;

    // TITLE, DESCRIPTION (optional layer fields)
    const titleField = reservations.titleField;
    const descriptionField = reservations.descriptionField;

    if (titleField && params.title) {
      attributes[titleField] = params.title;
    } else {
      // Populate title with "Booked Workspace - {UNIT_NAME}"
      if (titleField && unitName) {
        const title = i18n.more.bookWorkspace.emailSubject.replace("{workspaceName}", unitName);
        attributes[titleField] = title;
      }
    }

    // RECURRENCE
    if (recurrence?.enabled && reservations.hasRecurrenceFields()) {
      const { enabled, id, days, endDate, ...common } = params.recurrence;
      const recurrenceConfig = JSON.stringify({
        ...common,
        ...params.recurrence.type === "weekly" && { days },
        endDate: endDate.getTime(),
        sequence: 0
      });
      attributes[reservations.recurrenceIdField] = id;
      attributes[reservations.recurrenceConfigField] = recurrenceConfig;
    }

    const bookingType = params && params.bookingType;
    if (descriptionField && item && params.description && (bookingType === "meetingRooms")) {
      attributes[descriptionField] = params.description;
    } else if (!params.noAutoDescription && (bookingType === "meetingRooms")) {
      // Populate description with hyperlink to unit feature
      // if (descriptionField && item) {
      //   const locationUrl = await generateShareUrlAsync(item);
      //   const description = locationUrl;
      //   attributes[descriptionField] = description;
      // }
    }
    return { attributes, geometry };
  }

  getAreaId =(unitId, hotelUnits)=> {
    if (!hotelUnits || hotelUnits.length === 0) return null;
    for (const hotel of hotelUnits){
      const unitIdVal = getAttributeValue(hotel.attributes, FieldNames.UNIT_ID);
      if (unitId === unitIdVal) return getAttributeValue(hotel.attributes, FieldNames.AREA_ID);
    }
  }

  queryCapacity = async (unitIds: string[], signal?: AbortSignal) => {
    const unitsDataset = Context.instance.aiim.datasets.units;
    const layer = unitsDataset && unitsDataset.layer2D;
    const layerUrl = unitsDataset && unitsDataset.url;
    if(!layer) return;

    const ids = unitIds.map((unitId) => `'${unitId}'`);
    const whereClauses = [
      `${unitsDataset.uidField} IN (${ids.join(", ")})`
    ];
    const url = Context.checkMixedContent(layerUrl);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = [unitsDataset.capacityField, unitsDataset.uidField, unitsDataset.areaIDField, unitsDataset.useTypeField];
    const asnField = findFieldName(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    if (asnField) query.outFields.push(asnField);
    const methodField = findFieldName(layer.fields,FieldNames.RESERVATION_METHOD);
    if (methodField) query.outFields.push(methodField);
    query.returnGeometry = false;
    query.returnZ = true;
    query.where = whereClauses;
    const qaopts = { signal };
    const qa = new QueryAll();
    const result = await qa.execute(url,query,qaopts);
    const features = result && result.features;
    if(!features || features.length === 0) return null;
    let unitIdCapacity = {};
    for (let i=0;i<features.length;i++) {
      const ft = features[i];
      const attr = ft.attributes
      const unitId = attr && attr[unitsDataset.uidField];
      let capacity = attr && attr[unitsDataset.capacityField];
      if(capacity === null || capacity === 0) capacity = 1;
      if (Context.instance.aiim.isHotelMeetingRoom(ft)) capacity = 1;
      unitIdCapacity[unitId] = capacity;
    }
    return unitIdCapacity;
  }

  private _countOccupancy = (unitId: string, startDate: moment.Moment, endDate: moment.Moment, 
    unitReservations: IFeature[] | __esri.Graphic[], unitCapacity: number, ignoreObjectIds: boolean | number[]) => {

    const currentTime = new Date().getTime();
    const reservations: Reservations = Context.instance.aiim.datasets.reservations;
    const fields = reservations.layer2D.fields;
    const stateField = findFieldName(fields, FieldNames.STATE);
    const startField = findFieldName(fields, FieldNames.START_TIME);
    const endField = findFieldName(fields, FieldNames.END_TIME);
    const reservedForUsernameField = findFieldName(fields, FieldNames.RESERVED_FOR_USERNAME);
    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }

    const start = startDate.toDate().getTime();
    const end = endDate.toDate().getTime();
    const offset = 60000*5;
    let result = {
      unitId: unitId,
      occupancy: 0,
      available: true,
      unitCapacity: unitCapacity
    }

    for(let i=start; i<=end; i+=offset) {
      let count = 0;
      let intervalStart = new Date(i);
      let intervalEnd = new Date(i+offset);
      if(intervalEnd.getTime() > end) {
        intervalEnd = new Date(end);
      }
      unitReservations.some((unitReservation) => {
        let ignore = false;
        const attrs = unitReservation.attributes;
        const reservationState = attrs[stateField];
        const reservationStart = attrs[startField]
        const reservationEnd = attrs[endField];
        const reservedForUsername = attrs[reservedForUsernameField];
        const objectId = attrs[objectIdField];
        const activeReservation =
          reservationState === PENDING_APPROVAL ||
          reservationState === APPROVED ||
          reservationState === CHECKED_IN;
        const pastBooking = reservationEnd <= currentTime;
        // Ignore the specified objectId
        // ignore ongoing booking id
        if (ignoreObjectIds && Array.isArray(ignoreObjectIds) && (ignoreObjectIds.length > 0 )
            && ignoreObjectIds.includes(objectId)) {
          ignore = true;
        }

        if(activeReservation && !pastBooking && !ignore) { 
          const reserveFor = Context.instance.aiim.reserveForInfo;
          const username = (reserveFor && reserveFor.username) || Context.instance.user.getUsername();
          let occupied = 
            (reservationStart < intervalStart && reservationEnd > intervalStart) ||
            (reservationStart >= intervalStart && intervalEnd > reservationEnd);
          if (occupied) {
            if(username === reservedForUsername) {
              result.available = false;
            }
            count++;
            if (result.occupancy < count) {
              result.occupancy = count;
            }
          }
        }
      })

      if((count+1) > unitCapacity) {
        result.available = false;
        break;
      }
    }
    return result;
  }

  public canReserve = (
    unitId: string,
    ignoreObjectIds: boolean | number[],
    unitCapacity: number,
    unitReservations: IFeature[] | __esri.Graphic[],
    checkIn: string,
    checkOut: string,
    recurrence?: IRecurrenceOptions
  ) => {
    let canReserve = true;
    let occupancy = 0;
    let conflict: moment.Moment = null;
    const recurringDates = recurrence
    ? this.getRecurringDates(recurrence, moment(checkIn), moment(checkOut))
    : [];

    const considerAsRegularBooking = recurrence?.modified === "occurrence";
    if(recurringDates && recurringDates.length > 0 && !considerAsRegularBooking) {
      recurringDates.some(dates => {
        const start = dates.start;
        const end = dates.end;
        const result = this._countOccupancy(unitId, start, end, unitReservations, unitCapacity, ignoreObjectIds);
        const available = result && result.available;
        occupancy = result && result.occupancy;
        if(!available) {
          canReserve = false;
          conflict = start;
        }
        return !canReserve;
      })
    } else {
      const result = this._countOccupancy(unitId, moment(checkIn), 
                              moment(checkOut), unitReservations, unitCapacity, ignoreObjectIds);
      const available = result && result.available;
      occupancy = result && result.occupancy;

      if(!available) {
        canReserve = false;
      }
    }

    return {
      unitId,
      canReserve,
      objectIdsToCancel: [],
      occupancy,
      unitCapacity,
      conflict
    }
  }

  checkOccupancy = async(
    unitIds: string[],
    checkIn: string,
    checkOut: string,
    ignoreObjectIds: boolean | number[],
    params?: {
      recurrence: IRecurrenceOptions,
      task?: IBookingTask
    }
  ) => {
    const reservations: Reservations = Context.instance.aiim.datasets.reservations;
    const recurrence = params && params.recurrence;
    if(!reservations) return;

    const ids = unitIds.map((unitId) => `'${unitId}'`);
    const whereClauses = [
      `${reservations.unitIdField} IN (${ids.join(", ")})`
    ];

    const where = `${whereClauses.join(" AND ")}`;

    const data = await reservations.query(where);
    const availableUnitDetails= [];
    const features = data.features;
    const reservedUnits = {};
    features.forEach((reservation) => {
      const unitId = getAttributeValue(reservation.attributes, FieldNames.UNIT_ID);
      if (!reservedUnits[unitId]) {
        reservedUnits[unitId] = [reservation];
      } else {
        reservedUnits[unitId].push(reservation);
      }
    });
    const unitIdCapacity = await this.queryCapacity(unitIds);

    for (const unitId of unitIds) {
      const capacity = unitIdCapacity[unitId];
      if(capacity > 0) {
        if (reservedUnits[unitId]) {
          const unitReservations = reservedUnits[unitId];
          const info = this.canReserve(unitId, ignoreObjectIds, capacity, unitReservations, checkIn, checkOut, recurrence);
          availableUnitDetails.push(info);
          if (params && params.task) {
            params.task.canReserveInfo = info;
          }
        } else {
          availableUnitDetails.push({
            unitId,
            canReserve: true,
            objectIdsToCancel: [],
            occupancy: 0,
            unitCapacity: capacity
          });
        }
      }
    };
    return availableUnitDetails;
  };

  checkAvailability = async(
    unitIds: string[],
    checkIn: string,
    checkOut: string,
    ignoreObjectIds: boolean | number[],
    params?: ICheckAvailabilityParams,
    signal?: AbortSignal
  ) => {
    const reservations: Reservations = Context.instance.aiim.datasets.reservations;
    const hotelUnits = params && params.hotelUnits;
    const recurrence = params && params.recurrence;
    // if(!reservations) return;
    const layer: __esri.FeatureLayer = reservations && reservations.layer2D;

    const ids = unitIds.map((unitId) => `'${unitId}'`);
    const whereClauses = [
      `${reservations.unitIdField} IN (${ids.join(", ")})`
    ];

    const where = `${whereClauses.join(" AND ")}`;

    const data = await reservations.query(where, null, { signal });
    const availableUnits: string[] = [];
    const features = data.features;
    const reservedUnits = {};
    features.forEach((reservation) => {
      const unitId = getAttributeValue(reservation.attributes, FieldNames.UNIT_ID);
      if (!reservedUnits[unitId]) {
        reservedUnits[unitId] = [reservation];
      } else {
        reservedUnits[unitId].push(reservation);
      }
    });
    const unitIdCapacity = await this.queryCapacity(unitIds, signal);

    for (const unitId of unitIds) {
      const capacity = unitIdCapacity[unitId];
      if(capacity > 0) {
        if (reservedUnits[unitId]) {
          const unitReservations = reservedUnits[unitId];
          const info = this.canReserve(unitId, ignoreObjectIds, capacity, unitReservations, checkIn, checkOut, recurrence);
          if (info && info.canReserve) {
            availableUnits.push(unitId);
          }
          if (params && params.task) {
            params.task.canReserveInfo = info;
          }
        } else {
          availableUnits.push(unitId);
        }
      }
    };
    return availableUnits;
  };

 cancelBooking = (attributes: ICancelBookingParams | ICancelBookingParams[]): Promise<__esri.RequestResponse> => {
    const reservationDataset = Context.instance.aiim.datasets.reservations;
    const layer = reservationDataset && reservationDataset.layer2D;
    const layerUrl = reservationDataset && reservationDataset.url;
    if (!layer) {
      return Promise.resolve(null);
    }

    const objectIdField = layer.objectIdField;
    const updateFeatures = [];

    const addUpdate = (attrs) => {
      const objectId = attrs[objectIdField];
      const update = { attributes: {} };
      update.attributes[objectIdField] = objectId;
      update.attributes[reservationDataset.stateField] = CANCELED;
      updateFeatures.push(update);
    }

    if (Array.isArray(attributes)) {
      attributes.forEach((attrs) => addUpdate(attrs));
    } else {
      addUpdate(attributes);
    }

    const url = layerUrl+"/applyEdits";
    const params = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      updates: JSON.stringify(updateFeatures)
    };
    const options: __esri.RequestOptions = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationDataset, options.query);

    const lib = Context.getInstance().lib;
    const request: typeof __esri.request = lib.esri.esriRequest;
    return request(url, options);
  }

  queryCheckInOut =(uniqueId)=> {
    const reservations = Context.instance.aiim.datasets.reservations;
    const layer = reservations && reservations.layer2D;
    let objectId;
    if(layer && layer.objectIdField) objectId = layer.objectIdField;
    let url = layer.url;
    let layerId = layer.layerId;
    url = url + "/" + layerId
    url = Context.checkMixedContent(url);
    const lib = Context.getInstance().lib;
    const task: CustomQueryTask = new lib.esri.QueryTask({url: url});
    const query: __esri.Query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = objectId + " = " + uniqueId;
    return task.execute(query);
  }


  updateCheckInOut =(attributes, stateVal)=> {
    const reservationDataset = Context.instance.aiim.datasets.reservations;
    const layer = reservationDataset && reservationDataset.layer2D;
    const layerUrl = reservationDataset && reservationDataset.url;
    const fields = layer && layer.fields;
    let objectId;
    if(layer && layer.objectIdField) objectId = layer.objectIdField;
    const checkInTime = findField(fields, FieldNames.CHECK_IN_TIME).name;
    const checkOutTime = findField(fields, FieldNames.CHECK_OUT_TIME).name;
    const state = findField(fields, FieldNames.STATE).name;

    const checkInTimeVal = getAttributeValue(attributes, FieldNames.CHECK_IN_TIME);
    const objectIdVal = getAttributeValue(attributes,objectId);
    const checkOutTimeVal = getAttributeValue(attributes, FieldNames.CHECK_OUT_TIME);

    const update = { attributes: {} };
    update.attributes[objectId] = objectIdVal;
    update.attributes[checkInTime] = checkInTimeVal;
    update.attributes[checkOutTime] = checkOutTimeVal;
    if(stateVal === "checkIn") update.attributes[state] = CHECKED_IN;
    else if(stateVal === "checkOut") update.attributes[state] = CHECKED_OUT;

    const url = layerUrl+"/applyEdits";
    const params: IApplyEditsOptions = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      // @ts-ignore
      updates: JSON.stringify([update])
    };
    const options: __esri.RequestOptions = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationDataset, options.query);

    const lib = Context.getInstance().lib;
    return lib.esri.esriRequest(url,options);
  }

  async updateMultiCheckInOut(attributesList, stateVal: "checkIn" | "checkOut") {
    const reservationDataset = Context.instance.aiim.datasets.reservations;
    const layer = reservationDataset.layer2D;
    const layerUrl = reservationDataset.url;
    const fields = layer.fields;
    const objectIdField = layer.objectIdField;
    const checkInTimeField = findFieldName(fields,reservationDataset.checkInTimeField);
    const checkOutTimeField = findFieldName(fields,reservationDataset.checkOutTimeField);
    const stateField = findFieldName(fields,reservationDataset.stateField);

    const updates = [];
    attributesList.forEach(attributes => {
      const update = { attributes: {} };
      update.attributes[objectIdField] = attributes[objectIdField];
      if (stateVal === "checkIn") {
        update.attributes[checkInTimeField] = attributes[checkInTimeField];
        update.attributes[stateField] = CHECKED_IN;
      } else if(stateVal === "checkOut") {
        update.attributes[checkOutTimeField] = attributes[checkOutTimeField];
        update.attributes[stateField] = CHECKED_OUT;
      }
      updates.push(update);
    })

    const url = layerUrl+"/applyEdits";
    const params: IApplyEditsOptions = {
      f: "json",
      useGlobalIds: false,
      rollbackOnFailure: true,
      // @ts-ignore
      updates: JSON.stringify(updates)
    };
    const options: __esri.RequestOptions = {
      query: params,
      method: "post",
      responseType: "json"
    };
    applyGdbVersion(reservationDataset, options.query);

    return await Context.instance.lib.esri.esriRequest(url,options);
  }

}

