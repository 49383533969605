import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import MergePlanVM, { MergeInfo }  from "./MergePlanVM";
import {
  WorkingController,
} from "../../../../common/components/Modal";
import * as component from "../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteAlert,
  CalciteBlock,
  CalciteButton,
  CalciteIcon,
  CalciteLabel,
  CalciteNotice,
  CalcitePanel,
  CalciteRadioButton,
  CalciteRadioButtonGroup,
  CalciteTooltip
} from "@esri/calcite-components-react";
import { getActiveHelpTip, HelpTip, setHelpTip } from "../../../components/Editor/redux";
import { IMergePlanTask } from "../../../base/mergeUtil";

interface Props {
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}

interface State {
  loading: boolean,
  mergeInfo?: MergeInfo,
  merging: boolean,
  successType?: "pull" | "push"
  type?: "pull" | "push"
}

class MergePlanPanel extends React.Component<Props, State> {

  alertDuration: number = 5000;
  viewModel: MergePlanVM;

  constructor(props) {
    super(props);
    this.viewModel = new MergePlanVM();
    this.state = component.newState({
      loading: true,
      mergeInfo: null,
      merging: false,
      successType: null,
      type: null
    });
  }

  componentDidMount() {
    this.gatherInfo(true);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  canMerge() {
    const { mergeInfo, merging, type } = this.state;
    let canPull = !!(mergeInfo && mergeInfo.canPull);
    let canPush = !!(mergeInfo && mergeInfo.canPush);
    if (!merging && ((type === "pull" && canPull) || (type === "push" && canPush))) {
      return true;
    }
    return false;
  }

  async gatherInfo(init) {
    try {
      const mergeInfo = await this.viewModel.gatherInfo();
      const newState: Pick<State, "mergeInfo" | "loading" | "type"> = {mergeInfo, loading: false}
      if (init && mergeInfo) {
        if (mergeInfo.canPush) newState.type = "push";
        else if (mergeInfo.canPull) newState.type = "pull";
      }
      this.setState(newState)
    } catch(ex) {
      console.error(ex); // @todo show error report??
      this.setState({loading: false})
    }
  }

  mergeClicked = async () => {
    const i18n = Context.instance.i18n;
    const { mergeInfo, type } = this.state;
    let workingController 
    if (this.canMerge()) {
      try {
        if (this.state.merging) return;
        this.setState({merging: true});
        const mergeOptions = {
          type
        }
        workingController = new WorkingController();
        // hosted merge not yet available
        const result = await this.viewModel.merge(mergeOptions,workingController) as IMergePlanTask;
        await this.gatherInfo(false);

        let successType = null;
        if (type === "pull" && result.wasReconciled) {
          successType = type;
        } else if (type === "push" && result.wasMerged) {
          successType = type;
        } else if (type === "push" && result.wasReconciled) {// user aborted
          successType = "pull";
        }
        this.setState({
          merging: false,
          successType
        });
        workingController.close();
        setTimeout(() => {
          this.setState({successType: null});
        }, this.alertDuration);

      } catch(ex) {
        console.error(ex);
        this.setState({merging: false});
        if (workingController) workingController.close();
        this.viewModel.showError(i18n.switcher.mergePlan.mergeErrorTitle,i18n.switcher.mergePlan.mergeErrorMessage,ex);
      }
    }
  }

  render() {
    if (!this.viewModel.getPlan()) return null;
    return (
      <div className="i-editor-sidebar-container i-editor-merge-plan-container">
        <div className="i-editor-sidebar-content">
          {this.renderNotice()}
          {this.renderLoading()}
          {this.renderInfo()}
          {this.renderChoice()}
        </div>
        <div className="i-miniapps-padding-1">
          {this.renderSuccess()}
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderButton()}
        </div>
      </div>
    )
  }

  renderButton() {
    const i18n = Context.instance.i18n;
    const disabled = !this.canMerge() || this.state.merging;
    return (
      <CalciteButton width="full"
        disabled={disabled ? true : undefined}
        onClick={this.mergeClicked}
      >
        {i18n.switcher.mergePlan.mergeButton}
      </CalciteButton>
    )
  }

  renderChoice() {
    const i18n = Context.instance.i18n;
    const { mergeInfo, type } = this.state;
    if (!mergeInfo) return null;
    const canPull = !!(mergeInfo && mergeInfo.canPull) && !this.state.merging;
    const canPush = !!(mergeInfo && mergeInfo.canPush) && !this.state.merging;
    const disablePullReason = mergeInfo.disablePullReason;
    const disablePushReason = mergeInfo.disablePushReason;
    return (
      <>
        <h4 className="i-miniapps-section-label i-miniapps-margin-bottom-1">{i18n.switcher.mergePlan.mergeType.caption}</h4>
        <CalciteRadioButtonGroup layout="vertical" name="merge-plan-options">
          <CalciteLabel id="merge-choice-pull" layout="inline">
            <CalciteRadioButton value="pull" 
              checked={type === "pull" ? true : undefined}
              disabled={canPull ? undefined : true}
              onCalciteRadioButtonChange={e => {
                this.setState({type: e.target.value})
              }}
            />
            <CalciteIcon scale="s" icon="download"/>
            <span>{i18n.switcher.mergePlan.mergeType.pull}</span>
          </CalciteLabel>
          {disablePullReason &&
            <CalciteTooltip label={disablePullReason} referenceElement="merge-choice-pull" placement="top-start">
              <span>{disablePullReason}</span>
            </CalciteTooltip>
          }
          <CalciteLabel id="merge-choice-push" layout="inline">
            <CalciteRadioButton value="push" 
              checked={type === "push" ? true : undefined}
              disabled={canPush ? undefined : true}
              onCalciteRadioButtonChange={e => {
                this.setState({type: e.target.value})
              }}
            />
            <CalciteIcon scale="s" icon="upload"/>
            <span>{i18n.switcher.mergePlan.mergeType.push}</span>
          </CalciteLabel>
          {disablePushReason &&
            <CalciteTooltip label={disablePushReason} referenceElement="merge-choice-push" placement="top-start">
              <span>{disablePushReason}</span>
            </CalciteTooltip>
          }
        </CalciteRadioButtonGroup>
      </>
    )
  }

  renderInfo() {
    const i18n = Context.instance.i18n;
    const { mergeInfo } = this.state;
    const vm = this.viewModel;
    let s, v, icon;
    if (!mergeInfo) return null;

    if (mergeInfo.defaultPlan.hasChanges) {
      s = i18n.switcher.mergePlan.info.hasDefultChanges;
      icon = <CalciteIcon scale="s" icon="exclamation-mark-triangle" className="i--info" />
    } else {
      s = i18n.switcher.mergePlan.info.noDefultChanges;
      icon = <CalciteIcon scale="s" icon="information" className="i--info" />
    }
    const defaultStatus = (
      <div>
        <div className="i-miniapps-note i-medium-weight">
          {icon}
          <span className="i-miniapps-margin-lr-small">{s}</span>
        </div>
      </div>
    )

    if (mergeInfo.plan.hasChanges) {
      v = mergeInfo.plan;
      s = i18n.switcher.mergePlan.info.changePattern;
      s = s
        .replace("{sitesEdited}", v.sitesEdited)
        .replace("{facilitiesEdited}", v.facilitiesEdited)
        .replace("{levelsEdited}", v.levelsEdited)
        .replace("{unitsEdited}", v.unitsEdited)
        .replace("{detailsEdited}", v.detailsEdited)
        .replace("{occupantsEdited}", v.occupantsEdited);
      icon = <CalciteIcon scale="s" icon="exclamation-mark-triangle" className="i--info" />
    } else {
      s = i18n.switcher.mergePlan.info.noPlanChanges;
      icon = <CalciteIcon scale="s" icon="information" className="i--info" />
    }
    const planStatus = (
      <div>
        <div className="i-miniapps-note i-medium-weight">
          {icon}
          <span className="i-miniapps-margin-lr-small">{s}</span>
        </div>
      </div>
    )

    let lastPull;
    if (mergeInfo.lastPullDate) {
      v = vm.formatDateTime(mergeInfo.lastPullDate);
      s = i18n.switcher.mergePlan.info.lastPull.replace("{date}",v);
      lastPull = (
        <div>
          <div className="i-miniapps-note i-border-input">
            <CalciteIcon scale="s" icon="clock"/>
            <span className="i-miniapps-margin-lr-small">{s}</span>
          </div>
        </div>
      )
    }

    let lastPush;
    if (mergeInfo.lastPushDate) {
      v = vm.formatDateTime(mergeInfo.lastPushDate);
      s = i18n.switcher.mergePlan.info.lastPush.replace("{date}",v);
      lastPush = (
        <div>
          <div className="i-miniapps-note i-border-input">
            <CalciteIcon scale="s" icon="clock"/>
            <span className="i-miniapps-margin-lr-small">{s}</span>
          </div>
        </div>
      )
    }
    
    return (
      <div>
        <div>
          <h4 className="i-miniapps-section-label">{i18n.switcher.mergePlan.info.changesToPull}</h4>
          <div className="i-miniapps-note">{i18n.switcher.mergePlan.info.changesToPullDesc}</div>
          {defaultStatus}
          {lastPull}
          <h4 className="i-miniapps-section-label">{i18n.switcher.mergePlan.info.changesToPush}</h4>
          <div className="i-miniapps-note">{i18n.switcher.mergePlan.info.changesToPushDesc}</div>
          {planStatus}
          {lastPush}
        </div>
      </div>
    )
    
  }

  renderLoading() {
    if (!this.state.loading) return;
    return (
      <CalcitePanel className="i-loading-transparent" loading={true} 
        style={{height: "100px", marginTop: "0.5rem"}} />
    )
  }

  renderNotice() {
    const i18n = Context.instance.i18n;
    const { tip } = this.props;
    const isVersioned = this.viewModel.getPlan().isVersioned;
    return (
      <CalciteNotice open={!(tip && tip.visible) ? true : undefined} closable icon="lightbulb" scale="s"
        onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: "" })}>
        <div slot="message">
          <div>{i18n.switcher.mergePlan.part1}</div>
          {!isVersioned &&
            <>
              <br />
              <div>{i18n.switcher.mergePlan.part2}</div>
            </>
          }
        </div>
      </CalciteNotice>
    )
  }

  renderSuccess() {
    const i18n = Context.instance.i18n;
    const { successType } = this.state
    let title, message;
    if (successType === "pull") {
      title = i18n.switcher.mergePlan.mergeType.pullSuccessTitle;
      message = i18n.switcher.mergePlan.mergeType.pullSuccess;
    } else if (successType === "push") {
      title = i18n.switcher.mergePlan.mergeType.pushSuccessTitle;
      message = i18n.switcher.mergePlan.mergeType.pushSuccess;
    } else {
      return null;
    }
    return (
      <CalciteAlert open icon="check-circle" kind="success"
        label={"success"}
        placement="bottom-end"
        onCalciteAlertClose={() => {
          this.setState({ successType: null });
        }} 
        >
        <div slot="title">{title}</div>
        <div slot="message">{message}</div>
      </CalciteAlert>
    )
  }

}

const mapStateToProps = (state, ownProps) => ({
  tip: getActiveHelpTip(state)
});

const mapDispatchToProps = (dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("mergePlan", tip))
});

export default connect(mapStateToProps,mapDispatchToProps)(MergePlanPanel);
