import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import NetworkService from "../base/NetworkService";

export default class TrackingLocation extends BaseClass {

  estimateDistance(feature) {
    const promise = new Promise((resolve,reject) => {
        const networkService = new NetworkService();
        networkService.estimateDistance(feature).then(result => {
          let estimate = result;
          resolve(estimate);
        })
        .catch(error => {
          console.error("Error estimating distance",error);
          resolve(null);
        })
    });
    return promise;
  }

  query(item) {
    const promise = new Promise((resolve,reject) => {
      const trackingInfo = {
        noInfo: true,
        noLastKnownLocation: false,
        feature: null
      };
      let username;
      const feature = item && item.getFeature();
      const source = item && item.getSource();
      const itemIsValid = (source && feature && item && item.isValid());
      const trackingViews =  Context.getInstance().user.trackingViews;
      if (trackingViews && itemIsValid && source && source.isPeopleLayer()) {
        username = trackingViews.usernameFromFeature(source,feature);
      }
      if (!username) {
        // TODO clear UI ?
        resolve(trackingInfo);
        return;
      }

      let lastKnown, estimate, text, maText;
      const i18n = Context.getInstance().i18n;
      const djLocale = Context.getInstance().lib.dojo.locale;
      trackingViews.queryLastKnownLocation(username).then(result => {
        lastKnown = result;
        if (lastKnown && lastKnown.feature) {
          trackingInfo.noInfo = false;
          trackingInfo.feature = lastKnown.feature;
          trackingInfo.floor = lastKnown.floor;
          trackingInfo.horizontalAccuracy = lastKnown.horizontalAccuracy;
          trackingInfo.verticalAccuracy = lastKnown.verticalAccuracy;
        } else if (lastKnown && lastKnown.noLastKnownLocation) {
          trackingInfo.noInfo = false;
          trackingInfo.noLastKnownLocation = true;
        } else {
          // no info
        }
      }).then(() => {
        if (lastKnown && lastKnown.feature) {
          return this.estimateDistance(lastKnown.feature).then(result => {
            estimate = result;
          })
        }
      }).then(() => {
        //console.log("trackingInfo",trackingInfo);
        //console.log("estimate",estimate);
        var flag=false;

        if (lastKnown && lastKnown.feature) {
          const locationTimestamp = lastKnown.locationTimestamp;
          if (typeof locationTimestamp === "number") {
            const now = new Date(Date.now());
            const lastLocation= new Date(locationTimestamp);
            //const dt = new Date(Date.now() - (1000 * 60 * 60)); // for testing
            const sameDay = (lastLocation.getFullYear() === now.getFullYear() &&
                             lastLocation.getMonth() === now.getMonth() &&
                             lastLocation.getDate() === now.getDate());


            let pattern = i18n.infoPanel.realTime.lastKnownTimestampPattern;
            let maPattern = i18n.infoPanel.realTime.multipleAssignmentsText;
            if (sameDay) {
              text = djLocale.format(lastLocation,{selector:"time",formatLength:"short"});
              maText = djLocale.format(lastLocation,{selector:"time",formatLength:"short"});
            } else {
              flag=true;
              text = djLocale.format(lastLocation,{selector:"date",formatLength:"short"});
              maText = djLocale.format(lastLocation,{selector:"date",formatLength:"short"});
              var timeText = djLocale.format(lastLocation,{selector:"time",formatLength:"short"});
              var maTimeText = djLocale.format(lastLocation,{selector:"time",formatLength:"short"});
            }
            text = pattern.replace("{timestamp}",text);
            maText = maPattern.replace("{timestamp}",maText);
            trackingInfo.lastKnownDate = lastLocation;
            trackingInfo.lastKnownTimestampText = text;
            trackingInfo.maLastKnownTimestampText = maText;
            trackingInfo.summaryText = text;
            trackingInfo.maSummaryText = maText;
            if(flag===true){
            pattern = i18n.infoPanel.realTime.summaryPattern1;
            text = pattern.replace("{date}",trackingInfo.lastKnownTimestampText);
            text = text.replace("{time}",timeText);
            trackingInfo.summaryText = text;
            pattern = i18n.infoPanel.realTime.summaryPattern1;
            maText = pattern.replace("{date}",trackingInfo.maLastKnownTimestampText);
            maText = maText.replace("{time}",maTimeText);
            trackingInfo.maSummaryText = maText;
            }else{
              pattern = i18n.infoPanel.realTime.summaryPattern2;
              text = pattern.replace("{lastKnownTimestamp}",trackingInfo.lastKnownTimestampText);
              trackingInfo.summaryText = text;
              pattern = i18n.infoPanel.realTime.summaryPattern2;
              maText = pattern.replace("{lastKnownTimestamp}",trackingInfo.maLastKnownTimestampText);
              trackingInfo.maSummaryText = maText;
            }
            if (estimate && estimate.lengthText && estimate.timeText) {
              pattern = i18n.infoPanel.realTime.estimatePattern;
              text = pattern.replace("{length}",estimate.lengthText);
              text = text.replace("{time}",estimate.timeText);
              trackingInfo.estimateText = text;
            }
          }
        }

      }).then(() => {
        //setTimeout(() => {console.log("ok......");resolve(trackingInfo);},10000);
        resolve(trackingInfo);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

}
