import Context from "../../context/Context";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import type Levels from "../../aiim/datasets/Levels";
import type Source from "../../aiim/base/Source";
import type { CustomQueryTask } from "../../context/EsriLib";
import { IFeatureItem } from "../components/common/PeopleAssignTo";
import { IFeature, IFeatureSet } from "@esri/arcgis-rest-types";

let NUM = 1000;
//NUM = 3;

export function applyGdbVersion(source,query) {
  const layer = (source && source.layer2D) || source;
  if (layer && layer.gdbVersion) {
    query.gdbVersion = layer.gdbVersion;
  }
}

export function ensureGeometry(source,featureItem) {
  let geometry = featureItem.geometry || featureItem.feature.geometry;
  if (geometry) return Promise.resolve();
  const promise = new Promise<void>((resolve,reject) => {
    queryGeometry(source,featureItem).then(result => {
      if (result && result.features && result.features.length > 0) {
        geometry = result.features[0].geometry;
        if (geometry) {
          const graphic = Context.instance.lib.esri.Graphic.fromJSON({
             attributes: featureItem.feature.attributes,
             geometry: geometry
          });
          geometry = graphic.geometry;
          featureItem.geometry = geometry;
          // if (!options.zInfo) {
          //   const levels = Context.instance.aiim.datasets.levels;
          //   const zInfo = levels && levels.getZInfo(source,graphic);
          //   options.zInfo = zInfo;
          // }
        }
      }
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

function _makeFeatureItem(source: Source, levels: Levels, oidField: string, feature: IFeature) {
  const objectId = aiimUtil.getAttributeValue(feature.attributes,oidField);
  const key = source.key + "_" + aiimUtil.getAttributeValue(feature.attributes,oidField);
  if (levels) levels.fixZ(source,feature);
  const featureItem: IFeatureItem = {
    key: key,
    objectId: objectId,
    feature: feature
  };
  return featureItem;
}

export function makeFeatureItem(source: Source, feature: IFeature) {
  const levels = null;
  const oidField = source.layer2D.objectIdField;
  return _makeFeatureItem(source,levels,oidField,feature)
}

export function makeResultInfo(source: Source, result: __esri.FeatureSet) {
  const resultInfo: {
    exceededTransferLimit: boolean,
    featureItems: IFeatureItem[]
  } = {
    exceededTransferLimit: false,
    featureItems: [],
    //voField: null
  };
  // if (result && result.fields) {
  //   resultInfo.voField = aiimUtil.findFieldName(result.fields,FieldNames.VERTICAL_ORDER);
  // }
  if (result && result.features && result.features.length > 0) {
    const levels = Context.instance.aiim.datasets.levels;
    // @ts-ignore
    let oidField: string = result.objectIdField;
    if (!oidField) oidField = aiimUtil.findObjectIdField(result.fields);
    const featureSet: IFeatureSet = result.toJSON();
    resultInfo.exceededTransferLimit = result.exceededTransferLimit;
    featureSet.features.forEach(feature => {
      const featureItem = _makeFeatureItem(source,levels,oidField,feature);
      resultInfo.featureItems.push(featureItem);

      // let key = index, objectId;
      // if (oidField) {
      //   objectId = aiimUtil.getAttributeValue(feature.attributes,oidField);
      //   key = source.key + "_" + aiimUtil.getAttributeValue(feature.attributes,oidField);
      // }
      // if (levels) levels.fixZ(source,feature);
      // const featureItem = {
      //   key: key,
      //   objectId: objectId,
      //   feature: feature
      // };
      // resultInfo.featureItems.push(featureItem);

    });
  }
  return resultInfo;
}

export function queryCount(options) {
  let {source,where,geometry} = options;
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.where = where;
  if (geometry) query.geometry = geometry;
  applyGdbVersion(source,query);
  return task.executeForCount(query);
}

export function queryFeatures(options: {
  source: Source,
  where: string,
  geometry?: __esri.Geometry,
  isMore?: boolean,
  isShowAll?: boolean,
  lastFeatureCount?: number,
  orderByFields?: string[],
  outFields?: string[],
  queryCriteria?: { num?: number, requiresGeometry?: boolean, returnGeometry?: boolean },
  returnGeometry?: boolean
}) {
  let {
    source,
    isMore,
    lastFeatureCount,
    orderByFields,
    outFields,
    where,
    geometry,
    queryCriteria,
    isShowAll
  } = options;

  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task: CustomQueryTask = new lib.esri.QueryTask({url: url});
  const query: __esri.Query = new lib.esri.Query();
  query.returnGeometry = false;
  query.returnZ = false;

  let returnGeometry = options.returnGeometry;
  if (queryCriteria && (queryCriteria.requiresGeometry || queryCriteria.returnGeometry)) {
    returnGeometry = true;
  }
  if (returnGeometry) {
    const view = Context.instance.views.mapView;
    if (view) query.outSpatialReference = view.spatialReference;
    query.returnGeometry = true;
    query.returnZ = true;
  }

  query.outFields = ["*"];
  query.where = where || "1=1";

  if (orderByFields) query.orderByFields = orderByFields;
  if (outFields) query.outFields = outFields;
  if (geometry) query.geometry = geometry;

  if (isMore || isShowAll) {
    query.start = lastFeatureCount;
  }
  query.num = NUM;

  if (!isShowAll && queryCriteria) {
    if (queryCriteria.num) {
      query.num = queryCriteria.num;
    }
  }

  applyGdbVersion(source,query);
  return task.execute(query);
}

export function queryGeometry(source,featureItem) {
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  const view = Context.getInstance().views.activeView;
  if (view) query.outSpatialReference = view.spatialReference;
  query.returnGeometry = true;
  query.returnZ = true;
  query.outFields = ["*"];
  query.objectIds = [featureItem.objectId];
  applyGdbVersion(source,query);
  return task.execute(query);
}

export function queryObjectIds(options) {
  let {source,where} = options;
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.where = where;
  applyGdbVersion(source,query);
  return task.executeForIds(query);
}

export function queryStats(options) {
  let {source,outStatistics,where} = options;
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.where = where;
  query.outStatistics = outStatistics;
  applyGdbVersion(source,query);
  return task.execute(query);
}

export function refreshFeatureItem(source,featureItem) {
  const promise = new Promise((resolve,reject) => {
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(source.url);
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.returnGeometry = true;
    query.returnZ = true;
    query.outFields = ["*"];
    query.objectIds = [featureItem.objectId];
    applyGdbVersion(source,query);
    task.execute(query).then(result => {
      //console.log("queryUtil.refreshFeatureItem.result",result);
      let featureItem2;
      if (result && result.features && result.features.length === 1) {
        let oidField = result.objectIdField;
        if (!oidField) oidField = aiimUtil.findObjectIdField(result.fields);
        const feature = result.features[0];
        let geometry = feature.geometry;
        if (geometry) {
          const graphic = Context.instance.lib.esri.Graphic.fromJSON({
             attributes: feature.attributes,
             geometry: geometry
          });
          geometry = graphic.geometry;
        }
        const levels = Context.instance.aiim.datasets.levels;
        //const zInfo = levels && levels.getZInfo(source,graphic);
        featureItem2 = _makeFeatureItem(source,levels,oidField,feature);
        featureItem2.geometry = geometry;
      }
      resolve(featureItem2);
    }).catch(ex => {
      reject(ex)
    });
  });
  return promise;
}
