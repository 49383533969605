import * as dateUtil from "../../../../../../src/components/main/Events/dateUtil";
import Context from "../../../../../context/Context";
import moment from 'moment';

function getDateRange(fromDate, toDate) {
  const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
  const startTimeField = reservations && reservations.startTimeField;
  const endTimeField = reservations && reservations.endTimeField;

  if (reservations && fromDate && toDate) {
    let start = dateUtil.getZulu(fromDate);
    let end = dateUtil.getZulu(toDate);
    let w = "(("+startTimeField + " < TIMESTAMP '" + end+"')";
    w += " AND ("+endTimeField + " > TIMESTAMP '" + start+"'))";
    return w;
  }
}

function makeDatePart(job, result) {
  let where = "1=1";
  let method = job.criteria.dateOption;
  let dt, dtStart, dtEnd;
  let now = Date.now();

  if (method === "today") {
    dt = new Date(now);
    dtStart = dateUtil.toStartOfDay(dt);
    dtEnd = dateUtil.toEndOfDay(dt);
    where = getDateRange(dtStart,dtEnd);
  } else if (method === "tomorrow") {
    dt = new Date(now);
    dt.setDate(dt.getDate() + 1);
    dtStart = dateUtil.toStartOfDay(dt);
    dtEnd = dateUtil.toEndOfDay(dt);
    where = getDateRange(dtStart,dtEnd);
  } else if (method === "thisweek") {
    const count = job && job.criteria && job.criteria.countWeek;
    dtStart = new Date(now);
    if (count) {
      var currentDate = new Date(now);
      currentDate.setDate(currentDate.getDate() + count * 7);
      dtStart = new Date(currentDate);
    }
    let day = dtStart.getDay();
    dtStart.setDate(dtStart.getDate() - day);
    dtEnd = new Date(dtStart.getTime());
    dtEnd.setDate(dtEnd.getDate() + 6);
    dtStart = dateUtil.toStartOfDay(dtStart);
    dtEnd = dateUtil.toEndOfDay(dtEnd);
    where = getDateRange(dtStart,dtEnd);
  } else if (method === "thismonth") {
    const count = job && job.criteria && job.criteria.countMonth;
    dt = new Date(now);
    if (count) {
      dtStart = new Date(dt.getFullYear(), dt.getMonth() + (count -1), 1);
      dtEnd = new Date(dt.getFullYear(), dt.getMonth() + count, 0);
    } else {
      dtStart = new Date(dt.getFullYear(), dt.getMonth(), 1);
      dtEnd = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    }
    dtStart = dateUtil.toStartOfDay(dtStart);
    dtEnd = dateUtil.toEndOfDay(dtEnd);
    where = getDateRange(dtStart,dtEnd);
  } else if (method === "pickdate") {
    let dateSelected = job.criteria.dateSelected
    if (dateSelected !== undefined && dateSelected !== null) {
      dt = new Date(dateSelected.getTime());
      dtStart = dateUtil.toStartOfDay(dt);
      dtEnd = dateUtil.toEndOfDay(dt);
      where = getDateRange(dtStart,dtEnd);
    }
  }
  if (result) {
    result.dtStart = dtStart;
    result.dtEnd = dtEnd;
  }
  return where;
}

export function makeWhere(criteria, result) {
  let where = ""
  const append = (where,part) => {
    if (typeof part === "string" && part.length > 0) {
      if (where.length > 0) where += " AND ";
      where += part;
    }
    return where;
  };

  const job = {
    criteria: criteria
  };

  where = append(where, makeDatePart(job, result));
  if (where.length === 0) where = "1=1";
  return where;
}

