import React from "react";
import Context from "../../../context/Context";
import * as component from "../../util/component";
import { findField } from "../../../aiim/util/aiimUtil";
import { setPopupTemplateTitle } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";

export default class Attributes extends React.Component {

  _coreNode;
  _coreWidget;

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.setCoreNode = this.setCoreNode.bind(this);
  }

  componentDidMount () {
    try {
      if (this._coreNode) {
        const lib = Context.getInstance().lib;
        const feature = this.getFeature();
        const layer = this.getLayer();
        if (!this._coreWidget && feature && layer) {
          //console.log("creating Feature widget",feature.attributes);
          const graphic = new lib.esri.Graphic({
            attributes: feature.attributes,
            geometry: !!this.props.popup ? null : feature.geometry,
            popupTemplate: feature.popupTemplate,
            layer: layer
          });
          if (!graphic.popupTemplate) {
            graphic.popupTemplate = this.getPopupTemplate();
          }
          this._coreWidget = new lib.esri.Feature({
            container: this._coreNode,
            view: Context.getInstance().views.activeView,
            graphic: graphic
          });
          this._coreWidget._shouldOpenInNewTab = function(url) {
            return false; // seems backwards
          };
          this._coreWidget.viewModel.set("graphic",graphic);
        }
      }
    } catch(ex) {
      console.error("Error initializing Attributes",ex);
    }
  }

  componentDidUpdate() {
    if (this.props.popup && this._coreWidget && this._coreWidget.graphic) {
      const graphic = this._coreWidget.graphic;
      const feature = this.getFeature();
      if (graphic && feature) {
        graphic.attributes = feature.attributes;
        this._coreWidget.viewModel.set("graphic",graphic);
        this._coreWidget.scheduleRender();
      }
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getDefaultFieldInfo(serviceFieldInfo) {
    //serviceFieldInfo: {name,alias,type,...}
    const fieldName = serviceFieldInfo.name;
    const item = {
      fieldName: fieldName,
      label: serviceFieldInfo.alias || fieldName,
      tooltip: "",
      visible: false,
      format: null,
      stringFieldOption: "text-box"
    };

    const type = serviceFieldInfo.type;
    //console.log("serviceFieldInfo.type",serviceFieldInfo.type);
    switch (type) {
      case "esriFieldTypeSmallInteger":
      case "esriFieldTypeInteger":
        item.format = {
          places: 0,
          digitSeparator: true
        };
        break;
      case "esriFieldTypeSingle":
      case "esriFieldTypeDouble":
        item.format = {
          places: 2,
          digitSeparator: true
        };
        break;
      case "esriFieldTypeDate":
        item.format = {
          dateFormat: "longMonthDayYear"
        };
        break;
      default:
    }
    return item;
  }

  getFeature() {
    if (this.props.feature) {
      return this.props.feature;
    }
    if (this.props.item && this.props.item.isValid()) {
      return this.props.item.getFeature();
    }
  }

  getLayer() {
    if (this.props.layer) {
      return this.props.layer;
    }
    const feature = this.getFeature();
    if (feature && feature.layer) {
      return feature.layer;
    } else {
      if (this.props.item && this.props.item.isValid()) {
        const source = this.props.source || this.props.item.getSource();
        if (source && source.layer2D) {
          return source.layer2D;
        }
      }
    }
  }
  addEmailToTemplate(feature, template) {
    const clone = template.clone();
    const layer = feature.layer;
    const fields = layer?.fields;
    const emailFld = findField(fields, FieldNames.PEOPLE_EMAIL);
    const content = Array.isArray(clone.content) && clone.content[0]?.type === "fields"
      ? clone.content[0]
      : null;
    if (content && emailFld) {
      const { lib } = Context.instance;
      const ExpressionInfo = lib.esri.ExpressionInfo;
      const FieldInfo = lib.esri.FieldInfo;
      const fInfo = new FieldInfo({ fieldName: "expression/email" });
      const eInfo = new ExpressionInfo({
        name: "email",
        title: emailFld.alias || emailFld.name,
        expression: `"mailto:" + $feature.${emailFld.name}`
      });
      if (clone.expressionInfos) {
        clone.expressionInfos.push(eInfo)
      } else {
        clone.expressionInfos = [eInfo];
      }
      const index = content.fieldInfos.findIndex(f =>
        f.fieldName.toLowerCase() === emailFld.name.toLowerCase());
      if (index >= 0) {
        content.fieldInfos.splice(index, 1, fInfo);
      }
    }
    return clone;
  }
  getPopupTemplate() {
    if (this.props.item && this.props.item.isValid()) {
      const source = this.props.source || this.props.item.getSource();
      const feature = this.getFeature();
      if (source && source.subLayer && source.subLayer.popupTemplate) {
        return setPopupTemplateTitle(feature, source.subLayer.popupTemplate);
      }
      if (source && source.layer2D) {
        const isOccupant = source.isAiimPeople();
        if (source.layer2D.popupTemplate) {
          return isOccupant
            ? this.addEmailToTemplate(feature, setPopupTemplateTitle(feature, source.layer2D.popupTemplate))
            : setPopupTemplateTitle(feature, source.layer2D.popupTemplate);
        } else {
          return this.makePopupTemplate(source.layer2D, isOccupant);
        }
      }
    } else {
      const feature = this.getFeature();
      const layer = this.getLayer();
      const isOccupant = layer?.title?.toLowerCase() === "people" || layer?.title?.toLowerCase() === "occupants";
      const template = feature?.popupTemplate ?? layer?.popupTemplate;
      return isOccupant
        ? this.addEmailToTemplate(feature, setPopupTemplateTitle(feature, template))
        : setPopupTemplateTitle(feature, template);
    }
    return null;
  }

  makePopupTemplate(layer, withEmail = false) {
    if (layer && layer.fields) {
      let fieldInfo;
      const fieldInfos = [];
      const emailFld = withEmail && findField(layer.fields, FieldNames.PEOPLE_EMAIL);
      layer.fields.forEach(field => {
        fieldInfo = emailFld?.name === field.name
          ? { fieldName: "expression/email" }
          : this.getDefaultFieldInfo(field);
        fieldInfo.visible = true;
        fieldInfos.push(fieldInfo);
      });
      const expressionInfos = [];
      if (withEmail && emailFld) {
        expressionInfos.push({
          name: "email",
          title: emailFld.alias || emailFld.name,
          expression: `"mailto:" + $feature.${emailFld.name}`
        });
      }
      const popupInfo = {
        title: layer.title,
        expressionInfos,
        content: [
          {
            type: "fields",
            fieldInfos: fieldInfos
          },
          {
            type: "attachments"
          }
        ]
      };
      const lib = Context.getInstance().lib;
      return new lib.esri.PopupTemplate(popupInfo);
    }
    return null;
  }

  render() {
    const style = {};
    if (!this.props.active) style.display = "none";
    return (
      <div style={style} ref={this.setCoreNode}></div>
    );
  }

  setCoreNode(node) {
    this._coreNode = node;
  }

}
