import React from "react";

// Components
//import AssignPersonToUnitButton from "../common/AssignPersonToUnitButton";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Items from "../common/Items";
import OfficePlan from "../../base/OfficePlan";
import PeopleAssignTo from "../common/PeopleAssignTo";
import PersonPopup from "../common/PersonPopup";
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import moment from "moment";
import ListHeader from "../common/ListHeader";
import ListCount from "../common/ListCount";
import AttributeEditor from "../common/Popup/AttributeEditor";

// Styled Components
import { FooterContainer } from "../../styles/Assets/assetStyles";
import { Panel } from "../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemTitleAside,
  ListItemSubtitle,
  ListItemAttribute,
  ListItemOverlay,
  ListItemOverlayContent,
  ListItemOverlayHeader,
  ListItemButtons,
} from "../../styles/Common/list";
import Popup from "../common/Popup/Popup";

// calcite-react
import Button from "calcite-react/Button";
import PlusIcon from "calcite-ui-icons-react/PlusIcon";

export default class PeoplePanel extends React.Component {
  constructor(props) {
    super(props);

    // const source = officePlanUtil.getPeopleSource();
    // let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    // if (source && source.layer2D) {
    //   const fields = source.layer2D.fields;
    //   let f = aiimUtil.findField(fields, nameFieldName);
    //   if (f) nameFieldName = f.name;
    // }
    // const queryCriteriaKey = this.getPanelKey();
    // const queryCriteria = QueryCriteria.get(
    //   queryCriteriaKey,
    //   this.getSourceKey()
    // );
    // if (!queryCriteria.sortOptions) {
    //   queryCriteria.primarySortField = nameFieldName;
    //   queryCriteria.sortOptions = {
    //     sortBy: queryCriteria.primarySortField,
    //     sortDir: "asc"
    //   };
    // }

    const source = officePlanUtil.getPeopleSource();
    let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    let unitIdFieldName = FieldNames.UNIT_ID;
    let areaIdFieldName = FieldNames.PEOPLE_AREA_ID;
    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields, nameFieldName);
      if (f) nameFieldName = f.name;
      f = aiimUtil.findField(fields, unitIdFieldName);
      if (f) unitIdFieldName = f.name;
      f = aiimUtil.findField(fields, areaIdFieldName);
      if (f) areaIdFieldName = f.name;
    }

    const queryCriteriaKey = this.getPanelKey();
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      this.getSourceKey()
    );
    queryCriteria.requiresGeometry = true;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = nameFieldName;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc",
      };
    }
    queryCriteria.forUnassignedPeople = true;
    const wh =
      "(" + unitIdFieldName + " IS NULL) AND (" + areaIdFieldName + " IS NULL)";
    queryCriteria.requiredExpression = wh;
    queryCriteria.where = queryCriteria.makeWhere(queryCriteria.getSource());

    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getPanelKey = () => {
    return this.props.panelKey;
  };

  getSourceKey = () => {
    return this.props.sourceKey;
  };

  updateFeatureCount = (featureCount, totalCount) => {
    this.setState({
      featureCount,
      totalCount,
    });
  };

  getRdxFeatureItemsKey = () => {
    const queryCriteriaKey = this.getPanelKey() || "";
    return queryCriteriaKey + "_FeatureItems";
  };
  
  // Add new hire or a job requisite 
  addNewHire = (type) => {
    const i18n = Context.instance.i18n;
    const feature = {attributes: {}}
    const fields = officePlanUtil.getPeopleSource().layer2D.fields;
    fields.forEach(f => {
      if (f.editable) {
        feature.attributes[f.name] = null;
      }
    })
    AttributeEditor.show({
      title: i18n.spaceplanner.people.add.title,
      type: "person",
      feature: feature,
      occupantType: type
    });
  }
  
  // Add buttons to dropdown
  renderAddButton = () => {
    const i18n = Context.instance.i18n;
    return (
      <Button
        label={i18n.general.add}
        icon={<PlusIcon size={16} />}
        iconPosition='before'
        onClick={e => this.addNewHire('placeholder')}>
          {i18n.general.add}
      </Button>
    );
  };
  

  render() {
    const i18n = Context.instance.i18n;
    const { layerCaption, filterTooltip } = this.props;
    const queryCriteriaKey = this.getPanelKey() || "";
    return (
      <Panel>
        <ListHeader
          title={i18n.spaceplanner.assets.people.unassignedTitle}
          layerCaption={layerCaption}
          filterTooltip={filterTooltip}
          queryCriteriaKey={queryCriteriaKey}
          button={this.renderAddButton()}
        ></ListHeader>
        <Items
          queryCriteriaKey={queryCriteriaKey}
          rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
          renderItem={this.renderItem}
          requeryOnPlanActions={[
            OfficePlan.Action_AssignmentsUpdated,
            OfficePlan.Action_PersonAssignedToUnit,
            OfficePlan.Action_PersonUnassignedFromUnit,
            OfficePlan.Action_UnitUnassigned,
          ]}
          isShowAllButton={true}
        />
        <FooterContainer>
          <ListCount
            queryCriteriaKey={queryCriteriaKey}
            label={i18n.spaceplanner.assets.people.countPattern}
          ></ListCount>

          <PeopleAssignTo
            buttonClassName="i-border-none"
            placement="bottom"
            rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
            disableUnassign={true}
          />
        </FooterContainer>
      </Panel>
    );
  }

  renderItem = (source, featureItem, index) => {
    const i18n = Context.instance.i18n;
    const key = featureItem.key;
    const attributes = featureItem.feature.attributes;

    const queryCriteriaKey = this.getPanelKey() || "";
    const rdxFeatureItemsKey = queryCriteriaKey + "_FeatureItems";

    const nameField = FieldNames.PEOPLE_FULLNAME;
    const name = aiimUtil.getAttributeValue(attributes, nameField);
    const labelWithNameString = i18n.spaceplanner.people.assignTo.labelWithName;
    const labelWithName = labelWithNameString.replace("{name}", name);

    const startedField = FieldNames.PEOPLE_START_DATE;
    const startedOn = aiimUtil.getAttributeValue(attributes, startedField);
    
    const mStartedOn = moment(startedOn);
    const now = moment();
    const displayTime = mStartedOn.isAfter(now) ? mStartedOn.from(now) : mStartedOn.to(now, true);

    const duration =
      typeof startedOn === "number" ? (
        <ListItemTitleAside>
          {displayTime}
        </ListItemTitleAside>
      ) : (
        ""
      );

    const roleField = FieldNames.PEOPLE_JOB_TITLE;
    const role = aiimUtil.getAttributeValue(attributes, roleField);
    const email = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
    const isPlaceholder = officePlanUtil.isPersonPlaceholder(featureItem)

    let orgLevel = null;
    const orgLevel1 = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_ORG_LEVEL_1
    );
    const orgLevel2 = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_ORG_LEVEL_2
    );
    if (orgLevel1 && orgLevel2) {
      const pattern = i18n.spaceplanner.assets.people.orgLevelPattern;
      orgLevel = pattern.replace("{orgLevel1}", orgLevel1);
      orgLevel = orgLevel.replace("{orgLevel2}", orgLevel2);
    } else {
      orgLevel = orgLevel1 || orgLevel2 || null;
    }
    const isUnassigned = officePlanUtil.isPersonUnassigned(featureItem);
    
    const clicked = (event) => {
      Topic.publish(Topic.ShowItemPopup, {
        title: name,
        source: source,
        featureItem: featureItem,
        fetchFeatureItemGeometry: false,
        zoom: false,
        content: (
          <Popup
            sourceKey={this.getSourceKey()}
            featureItem={featureItem}
            isPerson={true}
          />
        ),
      });
    };

    const dragStart = (evt) => {
      evt.dataTransfer.dropEffect = "copy"; // copy move link
      Topic.publish(Topic.DragItemStart, {
        sourceKey: source.key,
        featureItem: featureItem,
      });
    };

    const dragEnd = (evt) => {
      Topic.publish(Topic.DragItemEnd, {
        sourceKey: source.key,
        featureItem: featureItem,
      });
    };

    return (
      <ListItem
        unassigned={isUnassigned}
        isPlaceholder={isPlaceholder}
        key={key}
        onClick={clicked}
        draggable
        onDragStart={dragStart}
        onDragEnd={dragEnd}
      >
        <ListItemTitle>
          {name}
          {duration}
        </ListItemTitle>
        {/* <ListItemSubtitle>
          <ListItemAttribute>{email}</ListItemAttribute>
        </ListItemSubtitle> */}
        {this.renderSubtitle(role, orgLevel)}
        <ListItemOverlay>
          <ListItemOverlayContent>
            <ListItemOverlayHeader>{labelWithName}</ListItemOverlayHeader>
            <ListItemButtons>
              <PeopleAssignTo
                featureItems={[featureItem]}
                rdxFeatureItemsKey={rdxFeatureItemsKey}
                disableUnassign={isUnassigned}
                enableUnassign={!isUnassigned}
                name={name}
                buttons
              />
            </ListItemButtons>
          </ListItemOverlayContent>
        </ListItemOverlay>
      </ListItem>
    );
  };

  renderSubtitle = (role, orgLevel) => {
    const i18n = Context.instance.i18n;
    if (!this.isEmpty(role) && !this.isEmpty(orgLevel)) {
      return (
        <ListItemSubtitle>
          <ListItemAttribute>{role}</ListItemAttribute>
          <ListItemAttribute>{orgLevel}</ListItemAttribute>
        </ListItemSubtitle>
      );
    } else if (!this.isEmpty(role)) {
      return (
        <ListItemSubtitle>
          <ListItemAttribute>{role}</ListItemAttribute>
        </ListItemSubtitle>
      );
    } else if (!this.isEmpty(orgLevel)) {
      return (
        <ListItemSubtitle>
          <ListItemAttribute>{orgLevel}</ListItemAttribute>
        </ListItemSubtitle>
      );
    } else {
      return (
        <ListItemSubtitle>
          {i18n.spaceplanner.backstage.openPlan.noDescription}
        </ListItemSubtitle>
      );
    }
  };

  isEmpty(obj) {
    return !obj || (typeof obj === "string" && obj.trim().length === 0);
  }
}
