import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { getAttributeValue } from "../../../../aiim/util/aiimUtil";
import PencilIcon from "calcite-ui-icons-react/PencilIcon";
import Context from "../../../../context/Context";
import ItemReference from "../../../../aiim/base/ItemReference";
import * as tsUtil from "../../../../util/tsUtil";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { IFormOptions } from "../../../../util/interfaces";
import { getFieldTooltip } from "../support/formUtil";

interface IAttributesProps {
  formOptions: IFormOptions,
  headerText: string,
  onCreateForm?: (form: __esri.FeatureForm) => void,
  usePopupFields?: boolean,
  idField: string,
  isEditMode?: boolean,
  isDisabled?: boolean,
  onMount?: (form: __esri.FeatureForm) => void,
  onValueChange?: (e: __esri.FeatureFormValueChangeEvent, hasValidChanges: boolean) => void,
  onDomainValueChange?: __esri.FeatureFormValueChangeEventHandler,
  onFormValidityChange?: (valid: boolean) => void,
}
export interface IAttributesHandle {
  hasInvalidInput: (ff:__esri.FeatureForm) => { hasInvalid: boolean, fieldName: string, inputField: __esri.FieldInput },
  validate: (options?:{}) => boolean,
  isValid: () => boolean,
  setFormValue: (fldNm:string, val:any) => void
}
const coreNonVisible = ["globalid", "objectid"];
const numericTypes = ["small-integer", "integer", "single", "double", "long"];
const Attributes = forwardRef<IAttributesHandle, IAttributesProps>(({
  formOptions,
  headerText,
  onCreateForm,
  usePopupFields = false,
  idField = formOptions.layer.objectIdField,
  isEditMode = false,
  isDisabled = false,
  onMount,
  onValueChange,
  onDomainValueChange,
  onFormValidityChange,
}, ref) => {

  const [isEdit, setIsEdit] = useState(isEditMode);
  const ctx = Context.getInstance();
  const { intl } = ctx.lib.esri;
  const { CustomFeatureForm } = ctx.lib.indoors;

  const feature = formOptions && formOptions.feature;
  const layer = formOptions && formOptions.layer;
  const allNonVisible = coreNonVisible
    .concat(Object.values(layer.editFieldsInfo || {}))
    .concat(layer.geometryFieldsInfo
      ? [layer.geometryFieldsInfo.shapeAreaField, layer.geometryFieldsInfo.shapeLengthField]
      : [])
    .concat(formOptions.nonVisibleFields)
    .filter(f => !!f && typeof f.toLowerCase==="function")
    .map(f => f.toLowerCase());

  // useRef: returns a mutable ref object whose 'current' property is initialized to the passed argument (initialValue).
  // The returned object will persist for the full lifetime of the component.
  const _divRef = useRef<HTMLDivElement>();
  const _widgetRef = useRef<__esri.FeatureForm>();
  const handlesRef = useRef([]);

  useEffect(() => {
    console.log("Attributes mounted");
    const node = _divRef && _divRef.current;
    if (!node) { 
      destroyWidget();
      return;
    }
    if (isEdit === true)
      renderEditable(node);
    typeof onMount==="function" && onMount(_widgetRef.current);
    !isDisabled && setTimeout(()=>validate(), 500);

    return () => {
      console.log("Attributes unmounted");
      destroyWidget();
    };
  }, [isEdit]);
  
  useEffect(() => {
    if (isEdit === true) {
      if (_widgetRef.current && !isSameFeature()) {
        console.log("Attributes feature is different")
        _widgetRef.current.feature = feature;
        !isDisabled && setTimeout(() => validate(), 500);
      }
    }
    return () => {
      console.log("Attributes feature changed");
    };
  }, [isEdit, feature]);

  const destroyWidget = () => {
    handlesRef.current.forEach(h => {try{ h && typeof h.remove==="function" && h.remove() }catch (err){}});
    handlesRef.current.length = 0;
    _widgetRef.current && _widgetRef.current.destroy();
    _widgetRef.current = null;
  }

  const canSave = () => false;
  const clear = () => {};
  const save = () => {};
  const isNumericField = (field) => numericTypes.includes(field.type);
  const isValid = () => !!(_widgetRef.current && _widgetRef.current.viewModel && _widgetRef.current.viewModel.valid); 
  const debounce = function(cb, wait){
    //		Wraps a function around 'cb' that will only execute after 'wait' ms of repeated execution. 
    //    Useful for delaying some event action slightly to allow for rapidly-firing events 
    //    such as window.resize, node.mousemove and so on
    if (!cb || wait<1) { return cb }
    let timer;
    return function(){
      timer && clearTimeout(timer);
      //@ts-ignore
      const self = this;
      const a = arguments;
      timer = setTimeout(function(){cb.apply(self, a);}, wait);
    };
  }

  const applyValues = (f, values) => {
    if (!f || !f.attributes || !values) return;
    const attrs = f.attributes;
    Object.keys(values).forEach(name => {
      if (attrs[name] !== values[name]) {
        attrs[name] = values[name];
      }
    });
  }

  const hasInvalidInput = (ff:__esri.FeatureForm):{hasInvalid:boolean, fieldName:string, inputField: __esri.FieldInput} => {
    const result = {hasInvalid: false, fieldName: null, inputField: null};
    if (!ff)
      ff = _widgetRef.current;
    if (!ff)
      return result;
    const inputs = ff.viewModel.inputs
      .filter(f => f.type === "field" || f.type === "group")
      .map(f => f.type === "group"
        ? f.inputs as __esri.FieldInput[]
        : f as __esri.FieldInput)
      .flat();
    inputs.some(fieldInput => {
      if (!fieldInput.valid) {
        result.hasInvalid = true;
        result.fieldName = fieldInput.field.name;
        result.inputField = fieldInput;
      }
      return result.hasInvalid;
    });
    return result;
  }

  const validate = (options?:{apply:boolean}):boolean => {
    const ff = _widgetRef.current;
    ff.disabled = isDisabled;
    const result = hasInvalidInput(ff);
    if (result.hasInvalid) {
      ff.submit();
      ff.viewModel.inputs.forEach(f => {
        if (f.type === "group" && f.state === "collapsed") {
          const found = f.inputs.some(el => {
            return el.type === "field" && el.fieldName === result.fieldName;
          });
          if (found) 
            f.state = "expanded";
        }
      });

      setTimeout(() => {
        if (ff.container && result.fieldName) {
          const nd = (ff.container as HTMLElement).querySelector("[data-field-name='"+result.fieldName+"']");
          if (nd && typeof nd.scrollIntoView === "function") {
            nd.scrollIntoView({block: "center", inline: "nearest"});
          }
        }
      },100);
    }

    if (!result.hasInvalid && options && options.apply)
      applyValues(feature, ff && ff.getValues());

    console.debug("AttributesEditor.validate() feature (newUnit):", feature)
    return !result.hasInvalid;
  }

  const setFormValue = (fldNm:string, val:any) => {
    if (_widgetRef.current) {
      _widgetRef.current.viewModel.setValue(fldNm, val);
    }
  }

  const _onValueChange = debounce((cb, info)=>cb && cb(info), 500);

  const isSameFeature = () => {
    if (!_widgetRef.current?.feature?.attributes || !feature?.attributes)
      return false;
    
    const attr1 = _widgetRef.current.feature.attributes;
    const attr2 = feature.attributes;
    const sameId = (idField in attr1 && idField in attr2 && getAttributeValue(attr1, idField) === getAttributeValue(attr2, idField));
    if (sameId)
      return true;

    if (attr1===null && attr2===null)
      return true;

    const fSer = function(attr:{}) {
      return Object.keys(attr).sort().map(k => typeof attr[k]!=='object' && `${k}=${attr[k]}`).filter(x=>!!x).join(":");
    }
    const str1 = fSer(attr1)
    const str2 = fSer(attr2);
    return str1===str2;
  }

  const renderEditable = (node) => {    
    const ff = _widgetRef.current = new CustomFeatureForm({
      indoorsContext: ctx,
      tsUtil: tsUtil,
      container: node,
      feature,
      indoorsOptions: {
        ...formOptions,
        getTooltip: getFieldTooltip
      }
    });
    ff.disabled = !isEditMode || isDisabled;
    handlesRef.current.push(ff.on("value-change", ({layer, feature, fieldName, value, valid})=>{
      //console.debug("value-change", fieldName ,":", value, "isValid?", valid, feature);
      //if (isModifyInputFeature) feature.attributes[fieldName] = value;
      onValueChange && _onValueChange(onValueChange, {layer, feature, fieldName, value, valid});
      const lyr = formOptions.layer || feature.layer;
      if (!lyr || !lyr.renderer)
        return;
      const fld = lyr.fields.find(f=> f.name===fieldName)
      if (fld && (fld.domain || fld.name.toLowerCase()===FieldNames.UNITS_USE_TYPE)) {
        onDomainValueChange && onDomainValueChange({layer, feature, fieldName, value, valid});
      }
    }));
    handlesRef.current.push(ff.viewModel.watch("valid", (newVal, oldVal, property, object)=>{
      if (newVal!==oldVal) {
        onFormValidityChange && onFormValidityChange(newVal)
      }
    }));
    onCreateForm && onCreateForm(ff);
  }

  const renderReadOnly = () => {
    if (!layer) return;

    const { fields } = layer;
    const content = layer.popupTemplate && layer.popupTemplate.content;

    // only supporting FieldsContent element
    let fieldInfos = [];
    if (Array.isArray(content) && content.some(e => e.type === "fields")) {
      fieldInfos = (content.find(e => e.type === "fields") as __esri.FieldsContent).fieldInfos;
    }
    const units = ctx.aiim.datasets.units;
    const item = new ItemReference();
    item._fromFeature(units.getSource().key, feature);

    const formatValue = (field, value, format) =>
      isNumericField(field)
        ? intl.formatNumber(value, intl.convertNumberFormatToIntlOptions(format))
        : field.type === "date"
          ? intl.formatDate(value, intl.convertDateFormatToIntlOptions(format.dateFormat))
          : value;
    return (
      <div className="i-editor-attributes">
        {/* <InfoPanelAttributes feature={feature} active={true}/> */}
        {
          usePopupFields ?
            fieldInfos
              .filter(fi => !allNonVisible.includes(fi.fieldName.toLowerCase()) && fi.isEditable === true)
              .map(fi => {               
                const field = fields.find(f => f.name.toLowerCase() === fi.fieldName.toLowerCase())
                const format = fi && fi.format;
                const value = getAttributeValue(feature.attributes, fi.fieldName);
                return (
                  <div className="i-editor-attributes-row">
                    <label>{fi.label}</label>
                    <div>{format ? formatValue(field, value, format) : value}</div>
                  </div>
                );
              }) :
            fields
              .filter(field => !allNonVisible.includes(field.name.toLowerCase()) && field.editable === true)
              .map(field => {
                const fi = fieldInfos.find(fi => 
                  fi.fieldName.toLowerCase() === field.name.toLowerCase() || 
                  fi.fieldName.toLowerCase() === field.alias.toLowerCase());
                const format = fi && fi.format;
                const value = getAttributeValue(feature.attributes, field.name);

                return (
                  <div key={field.name} className="i-editor-attributes-row">
                    <label>{field.alias}</label>
                    <div>{format ? formatValue(field, value, format) : value}</div>
                  </div>
                );
              })
        }
      </div>
    );
  }
  const renderHeader = () => {
    if (isEdit === true) {
      return headerText ? (<h4 className="i-editor-attributes-header">{headerText}</h4>) : null;
    } else {
      return (
        <div className="i-editor-attributes-header">
          <h4>{headerText}</h4>
          <a onClick={() => setIsEdit(true)}><PencilIcon size={16}/>{ctx.i18n.general.edit}</a>
        </div>
      )
    }
  }
  
  const publicRef:IAttributesHandle = {
    hasInvalidInput,
    validate,
    isValid,
    setFormValue,
  }

  useImperativeHandle(ref, ()=>publicRef);

  return (
    <div className={isDisabled ? "i-editor-attributes-disabled" : ""}>
      {renderHeader()}
      {isEdit === true
        ? <div ref={_divRef}></div>
        : renderReadOnly()
      }
    </div>   
  );
})

export default Attributes;