import React, { RefObject } from "react";
import { Dispatch } from "redux";
import {connect} from "react-redux";
import Office365 from "./WorkspaceReservation/Office365";
import Context from "../../../../../context/Context";
import moment from "moment";
import Rdx, { setValue } from "../../../../../redux/Rdx";
import { ModalController } from "../../../../../common/components/Modal";
import Icons from "../../../../util/Icons";
import Button from "../../../../../common/components/Button";
import DayToggle from "./DayToggle";
import { TimePicker } from "antd";
import Topic from "../../../../../context/Topic";
import * as component from "../../../../util/component";
import Popover from "calcite-react/Popover";
import Tooltip from "calcite-react/Tooltip";
import SDPWrapper from "../../../../common/Calendar/SDPWrapper";
import { makeEndDateTime, makeInitialTimes, makeStartDateTime, getHotelBookingSystemType } from "./WorkspaceReservation/workspaceReservationUtil";
import BookingRecurrence, { getDefaultEndDate, IRecurrenceOptions, supportsRecurrence } from "./BookingRecurrence";
import { DayOfWeekShape, isSameDay } from "react-dates";

const CSS = {
  popoutInner: "i-popout-inner i-filter-duration",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  filterForm: "i-filter-form i-popup",
  toggleAllDay: "i-toggle-all-day",
  toggleLabel: "i-toggle-label",
  durationFilter: "i-duration-filter",
  durationFilterForm: "i-duration-filter-form",
  durationGroup: "i-duration-group",
  durationDate: "i-date",
  durationTime: "i-time",
  iconContainer: "i-sidebar-icon-container i-filter-container",
  icon: "i-more-menu-icon",
  spaceFilters: "i-space-filter",
  button: "i-book-button",
  dateString: "i-date-string",
  checkin:"i--checkin",
  checkout: "i--checkout"
};
interface IDurationFiltersProps {
  closePopup?: () => void,
  initialFilter: boolean,
  onRecurrenceUpdated?: (options: IRecurrenceOptions) => void,
  rdxAllDayChecked: boolean,
  rdxEndDateSelected: Date,
  rdxEndTimeSelected: moment.Moment,
  rdxStartDateSelected: Date,
  rdxStartTimeSelected: moment.Moment,
  rdxRecurrence: IRecurrenceOptions,
  rdxForOthers: boolean,
  setRdxValue: (key: string, value) => void
}
interface IDurationFiltersState {
  endCalendarOpen: boolean,
  allDay: boolean,
  endDate: Date,
  endTime: moment.Moment,
  recurrence: IRecurrenceOptions,
  startCalendarOpen: boolean,
  startDate: Date,
  startTime: moment.Moment,
  toggleFocused: boolean
}
class DurationFilters extends React.Component<IDurationFiltersProps, IDurationFiltersState> {
  format: string;
  use12Hours: boolean;
  keys = {
    checkInDate: "checkInDate",
    checkInString: "checkInString",
    checkOutDate: "checkOutDate",
    checkOutString: "checkOutString"
  }
  closeRef: RefObject<HTMLButtonElement>;
  bookingSystem: Office365;
  private _mounted = false;

  constructor(props: IDurationFiltersProps) {
    super(props);
    this.closeRef = React.createRef()
    this.bookingSystem = Office365.getInstance() as Office365;
    const locale = Context.instance.lib.dojo.kernel.locale
    if (locale === "en" || locale === "en-us") {
      this.use12Hours = true
      this.format = "h:mm A"
    } else {
      this.use12Hours = false
      this.format = "H:mm"
    }

    const { initialFilter } = this.props;
    let startTime = this.props.rdxStartTimeSelected;
    let endTime = this.props.rdxEndTimeSelected;

    if ((!startTime && !endTime) || initialFilter) {
      const initialTimes = makeInitialTimes();
      startTime = initialTimes.startTime;
      endTime = initialTimes.endTime;
    }

    this.state = component.newState({
      startDate: initialFilter ? new Date() : this.props.rdxStartDateSelected,
      endDate: initialFilter ? new Date() : this.props.rdxEndDateSelected,
      startTime,
      endTime,
      allDay: initialFilter ? true : this.props.rdxAllDayChecked,
      recurrence: initialFilter ? { enabled: false } : this.props.rdxRecurrence,
      startCalendarOpen: false,
      endCalendarOpen: false,
      toggleFocused: false
    })
  }

  returnClicked(event) {
    if (event.keyCode === 13) {
      Topic.publish(Topic.ToggleReturnClicked, {})
    }
  }

  componentWillUnmount() {
    this._mounted = false
    document.removeEventListener("keyup", this.returnClicked, false)
    component.componentWillUnmount(this)
  }

  componentDidUpdate() {
    const clockIcons = document.getElementsByClassName("ant-picker-suffix")
    for (let i = 0; i < clockIcons.length; i++) {
      clockIcons[i].setAttribute("aria-hidden", "true")
    }
    const cells = document.getElementsByClassName("ant-picker-time-panel-cell")
    for (let i = 0; i < cells.length; i++) {
      const text = (cells[i].firstChild as HTMLElement).innerText
      let ariaLabel = ""
      if (text) ariaLabel = text
      cells[i].setAttribute("tabIndex", "0")
      cells[i].setAttribute("role", "button")
      if (ariaLabel && ariaLabel.length > 0) cells[i].setAttribute("aria-label", text)
    }
    const panel = document.getElementsByClassName("ant-picker-panel")
    for (let i = 0; i < panel.length; i++) {
      panel[i].setAttribute("tabIndex", "0")
    }
  }

  componentDidMount() {
    this._mounted = true
    if (this.closeRef && this.closeRef.current && this.closeRef.current.focus) {
      this.closeRef.current.focus()
    }
    document.addEventListener("keyup", this.returnClicked, false)
    component.own(this, [
      Topic.subscribe(Topic.DurationFiltersSet, params => {
        if (this._mounted) {
          if (this.onSubmit()) Topic.publish(Topic.SpaceFiltersSubmit, {})
        }
      }),
      Topic.subscribe(Topic.ToggleReturnClicked, params => {
        if (this.state.toggleFocused) {
          this.setState({
            allDay: !this.state.allDay
          })
        }
      })
    ])
  }

  allDayChanged = (checked) => {
    this.setState({
      allDay: checked
    })
  }

  equalDates = (date1, date2) => {
    const matchingDays = date1.getDate() === date2.getDate()
    const matchingMonths = date1.getMonth() === date2.getMonth()
    const matchingYears = date1.getFullYear() === date2.getFullYear()
    return matchingDays && matchingMonths && matchingYears
  }

  onSubmit = () => {
    const i18n = Context.instance.i18n
    const checkIn = this.state.startDate
    const checkOut = this.state.endDate
    const checkInTime = this.state.startTime
    const checkOutTime = this.state.endTime
    let timeCheck = () => {
      const now = moment()
      const nowHour = now.hour()
      const nowMinute = now.minute()
      const ciHour = checkInTime.hour()
      const ciMinute = checkInTime.minute()
      const coHour = checkOutTime.hour()
      const coMinute = checkOutTime.minute()
      if (ciHour < nowHour) return -1
      if (ciHour === nowHour) {
        if (ciMinute < nowMinute) return -1
      }
      if (ciHour === coHour && ciMinute === coMinute) return 0
      if (ciHour === coHour) {
        if (ciMinute > coMinute) return 1
      } else if (ciHour > coHour) return 1
    }
    const currentDate = new Date()
    checkIn.setHours(0, 0, 0, 0)
    checkOut.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)
    if (this.equalDates(checkIn, currentDate) && timeCheck() === -1 && !this.state.allDay) {
      ModalController.showMessage(i18n.more.bookWorkspace.pastTime)
    } else if (checkIn < currentDate || checkOut < currentDate) {
      ModalController.showMessage(i18n.more.bookWorkspace.pastDate)
    } else if (this.equalDates(checkIn, checkOut) && timeCheck() === 0) {
      ModalController.showMessage(i18n.more.bookWorkspace.sameTimes)
    } else if (checkIn > checkOut) {
      ModalController.showMessage(i18n.more.bookWorkspace.checkOutBeforeError)
    } else if (this.equalDates(checkIn, checkOut) && timeCheck() === 1 && !this.state.allDay) {
      ModalController.showMessage(i18n.more.bookWorkspace.checkOutBeforeError)
    } else {
      let valid = true
      if (this.equalDates(checkIn, checkOut)) {
        if (!this.state.allDay) {
          if (checkInTime.isAfter(checkOutTime)) {
            ModalController.showMessage(i18n.more.bookWorkspace.checkOutBeforeError)
            valid = false
          }
        }
      }
      if (valid) {
        const checkInString = makeStartDateTime(this.state.startDate, this.state.startTime, this.state.allDay);
        const checkOutString = makeEndDateTime(this.state.endDate, this.state.endTime, this.state.allDay);

        // Update Redux
        this.props.setRdxValue(Rdx.Keys.BOOK_CHECKIN, checkInString);
        this.props.setRdxValue(Rdx.Keys.BOOK_CHECKOUT, checkOutString);
        this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED, this.state.startDate);
        this.props.setRdxValue(Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED, this.state.startTime);
        this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED, this.state.endDate);
        this.props.setRdxValue(Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED, this.state.endTime);
        this.props.setRdxValue(Rdx.Keys.BOOK_ALLDAY, this.state.allDay);
        this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_ENABLED, this.state.recurrence.enabled);
        this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_ENDDATE, this.state.recurrence.endDate);
        this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_DAYS, this.state.recurrence.days);
        this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_TYPE, this.state.recurrence.type);
        if (this.props.initialFilter) {
          Topic.publish(Topic.BookingFiltersSet, {})
        } else {
          Topic.publish(Topic.BookingFiltersSubmit, {})
        }
        if (typeof this.props.closePopup === "function") {
          this.props.closePopup()
        }
        return true
      }
    }
  }

  onStartTimeChange = (time, timeString) => {
    const checkIn = this.state.startDate
    const checkOut = this.state.endDate
    if (time && time !== undefined) {
      if (this.equalDates(checkIn, checkOut)) {
        let endTime = this.state.endTime;
        if (time.isSame(endTime)) {
          endTime = moment(time).add(1, "hour");
        } else {
          if (time.isAfter(endTime)) {
            endTime = moment(time).add(1, "hour");
            if (!time.isAfter(endTime)) {
              const endDate = moment(endTime).set({
                year: checkOut.getFullYear(),
                month: checkOut.getMonth(),
                date: checkOut.getDate()
              });
              // FIXME: Determine if `endDate.toDate()` is equivalant and change, if so.
              this.setState({
                // @ts-ignore
                endDate: new Date(endDate)
              })
            }
          } else {
            if (time.isSame(endTime, "hour")) {
              const endMin = endTime.minute();
              const startMin = time.minute();
              const diff = endMin - startMin;
              if (diff <= 30) {
                endTime = moment(endTime).add(30 - diff, "minute");
              }
            } else if (endTime.isSame(moment(time).add(1, "hour"), "hour")) {
              const endMin = endTime.minute() + 60;
              const startMin = time.minute();
              const diff = Math.abs(endMin - startMin);
              if (diff <= 30) {
                endTime = moment(endTime).add(30 - diff, "minute");
              }
            }
          }
        }
        this.setState({
          startTime: time,
          endTime: endTime
        });
      } else {
        this.setState({
          startTime: time
        });
      }
    }
  }

  onEndTimeChange = (time, timeString) => {
    const checkIn = this.state.startDate
    const checkOut = this.state.endDate
    if (time && time !== undefined) {
      if (this.equalDates(checkIn, checkOut)) {
        if (time.isSame(this.state.startTime, "hour")) {
          const startMin = this.state.startTime.minute();
          const endMin = time.minute();
          const diff = endMin - startMin;
          if (diff >= 30) {
            this.setState({
              endTime: time
            });
          }
        } else if (time.isSame(moment(this.state.startTime).add(1, "hour"), "hour")) {
          const startMin = this.state.startTime.minute();
          const endMin = time.minute() + 60;
          const diff = endMin - startMin;
          if (diff >= 30) {
            this.setState({
              endTime: time
            });
          }
        } else if (time.isAfter(this.state.startTime)) {
          this.setState({
            endTime: time
          });
        }
      } else {
        this.setState({
          endTime: time
        });
      }
    }
  }

  togglePopoverStart = () => {
    this.setState({
      startCalendarOpen: !this.state.startCalendarOpen,
      endCalendarOpen: false
    })
  }

  togglePopoverEnd = () => {
    this.setState({
      endCalendarOpen: !this.state.endCalendarOpen,
      startCalendarOpen: false
    })
  }

  closeStart = () => {
    this.setState({
      startCalendarOpen: false
    })
  }

  closeEnd = () => {
    this.setState({
      endCalendarOpen: false
    })
  }

  onStartDateChanged = (date) => {
    if (date !== null && date !== undefined) {
      const tempDate = date
      tempDate.setHours(0, 0, 0, 0)
      let check = tempDate <= this.state.endDate
      if (check) {
        let allDay = this.state.allDay
        if (!this.state.allDay) {
          const baseStart = moment("0:00", this.format)
          const baseEnd = moment("23:59", this.format)
          if (this.state.startTime.hour() === baseStart.hour() &&
            this.state.startTime.minute() === baseStart.minute() &&
            this.state.endTime.hour() === baseEnd.hour() &&
            this.state.endTime.minute() === baseEnd.minute()) {
            allDay = true
          }
        }
        this.setState(prev => ({
          startDate: date,
          allDay: allDay,
          startCalendarOpen: false,
          recurrence: {
            ...prev.recurrence,
            days: [moment(date).get("day") as DayOfWeekShape],
            endDate: getDefaultEndDate(prev.recurrence.type, this.state.endDate)
          }
        }));
        if (!isSameDay(moment(date), moment(this.state.endDate))) {
          this.setState(prev => ({
            recurrence: {
              ...prev.recurrence,
              enabled: false,
              endDate: getDefaultEndDate(prev.recurrence.type, this.state.endDate)
            }
          }));
        }
      } else {
        this.setState(prev => ({
          startDate: date,
          endDate: date,
          startCalendarOpen: false,
          recurrence: {
            ...prev.recurrence,
            days: [moment(date).get("day") as DayOfWeekShape],
            endDate: getDefaultEndDate(prev.recurrence.type, date)
          }
        }));
      }
    }
  };

  onEndDateChanged = (date) => {
    if (date !== null && date !== undefined) {
      const tempDate = date
      tempDate.setHours(0, 0, 0, 0)
      let check = tempDate >= this.state.startDate
      if (check) {
        let allDay = this.state.allDay
        if (!this.state.allDay) {
          const baseStart = moment("0:00", this.format)
          const baseEnd = moment("23:59", this.format)
          if (this.state.startTime.hour() === baseStart.hour() &&
            this.state.startTime.minute() === baseStart.minute() &&
            this.state.endTime.hour() === baseEnd.hour() &&
            this.state.endTime.minute() === baseEnd.minute()) {
            allDay = true
          }
        }
        this.setState(prev => ({
          endDate: date,
          allDay: allDay,
          endCalendarOpen: false,
          recurrence: { ...prev.recurrence, days: [moment(date).get("day") as DayOfWeekShape], endDate: undefined }
        }));
        if (!isSameDay(moment(this.state.startDate), moment(date))) {
          this.setState(prev => ({
            recurrence: { ...prev.recurrence, enabled: false, days: [moment(this.state.startDate).get("day") as DayOfWeekShape], endDate: undefined }
          }));
        }
      } else {
        // ModalController.showMessage("Check out must be after check in")
        this.setState(prev => ({
          startDate: date,
          endDate: date,
          endCalendarOpen: false,
          recurrence: { ...prev.recurrence, days: [moment(date).get("day") as DayOfWeekShape], endDate: undefined }
        }));
      }
    }
  }

  onToggleFocus = () => {
    this.setState({
      toggleFocused: true
    })
  }

  onToggleBlur = () => {
    this.setState({
      toggleFocused: false
    })
  }

  render() {
    const hashref = "#"
    const i18n = Context.instance.i18n
    const lib = Context.instance.lib
    const calendarIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16"

    const startTime = this.state.startTime
    const endTime = this.state.endTime
    const startDate = lib.dojo.locale.format(this.state.startDate, { selector:"date", formatLength:"short" })
    const endDate = lib.dojo.locale.format(this.state.endDate, { selector:"date", formatLength:"short" })

    const checked = this.state.allDay || ''

    const checkInDateLabel = startDate + ", " + i18n.more.bookWorkspace.startCalendarAriaLabel
    const checkOutDateLabel = endDate + ", " + i18n.more.bookWorkspace.endCalendarAriaLabel

    const startDateButton = (
      <button aria-label={checkInDateLabel}
        className={CSS.durationDate} onClick={this.togglePopoverStart}>
        <div className={CSS.iconContainer}>
          <div className={CSS.dateString}>{startDate}</div>
          <svg className={CSS.icon}><use href={calendarIcon}></use></svg>
        </div>
      </button>
    )
    const endDateButton = (
      <button aria-label={checkOutDateLabel}
        className={CSS.durationDate} onClick={this.togglePopoverEnd}>
        <div className={CSS.iconContainer}>
          <div className={CSS.dateString}>{endDate}</div>
          <svg className={CSS.icon}><use href={calendarIcon}></use></svg>
        </div>
      </button>
    )
    const isOutsideRange = (day) => moment().isAfter(day, 'day')
    const isDayHighlightedStart = (day) => {
      const start = moment(this.state.startDate)
      return moment().isSame(day, 'day') && !start.isSame(day, 'day')
    }
    const isDayHighlightedEnd = (day) => {
      const end = moment(this.state.endDate)
      return moment().isSame(day, 'day') && !end.isSame(day, 'day')
    }

    const onOpenChange = (open) => {
      if (open) {
        const preventScroll = () => {
          const timeColumns = document.getElementsByClassName("ant-picker-time-panel-column")
          for (let i = 0; i < timeColumns.length; i++) {
            const text = (timeColumns[i].firstChild.firstChild as HTMLElement).innerText
            if (text === "AM" || text === "PM") {
              (timeColumns[i] as HTMLElement).style.overflowY = "visible"
            }
          }
          // const cells = document.getElementsByClassName("ant-picker-time-panel-cell")
          // for (let i = 0; i < cells.length; i++) {
          //   cells[i].
          // }
        }
        setTimeout(preventScroll, 100)
      }
    }
    const type = getHotelBookingSystemType();
    const sameDayBooking = isSameDay(moment(this.state.startDate), moment(this.state.endDate));
    const recurrence = this.state.recurrence;
    let isDoneDisabled = false;
    if (recurrence?.enabled && recurrence?.type === "weekly" && recurrence.days?.length === 0) {
      isDoneDisabled = true;
    }
    const recurrenceUI = supportsRecurrence(type) &&
      <BookingRecurrence
        {...this.state.recurrence}
        startDate={this.state.endDate}
        disabled={!sameDayBooking}
        onUpdate={(options) => {
          this.setState({ recurrence: options })
          if (this.props.initialFilter) {
            this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_ENABLED, options.enabled);
            this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_ENDDATE, options.endDate);
            this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_DAYS, options.days);
            this.props.setRdxValue(Rdx.Keys.BOOK_RECURRENCE_TYPE, options.type);
          }
          this.props.onRecurrenceUpdated && this.props.onRecurrenceUpdated(options);
        }}/>
    const checkInTimeLabel = "Check in time"
    const checkOutTimeLabel = "Check out time"
    if (this.props.initialFilter) {
      return (
        <div className={CSS.durationFilterForm}>
          <div className={CSS.toggleAllDay}>
            <label htmlFor={"allDay"} className={CSS.toggleLabel}>{i18n.more.bookWorkspace.allDay}</label>
            <DayToggle id={"allDay"} checked={!!checked} onChange={this.allDayChanged}
              // ariaLabel={i18n.more.bookWorkspace.toggleAriaLabel}
              onToggleFocus={this.onToggleFocus}
              onToggleBlur={this.onToggleBlur}></DayToggle>
          </div>
          <div className={CSS.durationFilter}>
            <div>{i18n.more.bookWorkspace.checkIn}</div>
            <div className={CSS.durationGroup}>
              <Popover
                closeOnSelect={false}
                onRequestClose={this.closeStart}
                open={this.state.startCalendarOpen}
                targetEl={startDateButton}
                appendToBody>
                <SDPWrapper initialDate={this.state.startDate} onDateChange={this.onStartDateChanged.bind(this)}
                  outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedStart} booking={true}
                />
              </Popover>
              {
                this.state.allDay ?
                <TimePicker use12Hours={this.use12Hours} format={this.format}
                  value={moment('0:00', this.format)}
                  disabled aria-label={checkInTimeLabel} /> :
                <TimePicker use12Hours={this.use12Hours} format={this.format}
                  value={startTime} onOpenChange={onOpenChange} aria-label={checkInTimeLabel}
                  onChange={this.onStartTimeChange.bind(this)} minuteStep={5} showNow={false} inputReadOnly={true} />
              }
            </div>
            <div>{i18n.more.bookWorkspace.checkOut}</div>
            <div className={CSS.durationGroup}>
              <Popover
                closeOnSelect={false}
                onRequestClose={this.closeEnd}
                open={this.state.endCalendarOpen}
                targetEl={endDateButton}
                appendToBody>
                <SDPWrapper initialDate={this.state.endDate} onDateChange={this.onEndDateChanged.bind(this)}
                  outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedEnd} booking={true}
                />
              </Popover>
              {
                this.state.allDay ?
                <TimePicker use12Hours={this.use12Hours} format={this.format}
                  value={moment('23:59', this.format)}
                  disabled aria-label={checkOutTimeLabel} /> :
                <TimePicker use12Hours={this.use12Hours} format={this.format}
                  value={endTime} onOpenChange={onOpenChange} aria-label={checkOutTimeLabel}
                  onChange={this.onEndTimeChange.bind(this)} minuteStep={5} showNow={false} inputReadOnly={true} />
              }
            </div>
          </div>
          {!sameDayBooking
            ? <Tooltip placement="top" positionFixed
                title={i18n.calendars.recurringToggleDisable}>{recurrenceUI}</Tooltip>
            : recurrenceUI
          }
        </div>
      )
    } else {
      return (
        <div className={CSS.popoutOuter}>
          <div className={CSS.popoutInner}>
            <div className={CSS.panelHeader}>
              <h2>{i18n.more.bookWorkspace.durationHeader}</h2>
              <div className={CSS.panelHeaderTools}>
                <button ref={this.closeRef}
                  title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
                  tabIndex={0} onClick={this.props.closePopup}>
                  {Icons.close()}
                </button>
              </div>
            </div>
            <div className={CSS.filterForm}>
              <div className={CSS.durationFilterForm}>
                <div className={CSS.toggleAllDay}>
                  <label htmlFor={"allDay"} className={CSS.toggleLabel}>{i18n.more.bookWorkspace.allDay}</label>
                  <DayToggle id={"allDay"} checked={!!checked} onChange={this.allDayChanged}
                    // ariaLabel={i18n.more.bookWorkspace.toggleAriaLabel}
                    onToggleFocus={this.onToggleFocus}
                    onToggleBlur={this.onToggleBlur}></DayToggle>
                </div>
                <div className={CSS.durationFilter}>
                  <div>{i18n.more.bookWorkspace.checkIn}</div>
                  <div className={CSS.durationGroup}>
                    <Popover
                      closeOnSelect={false}
                      onRequestClose={this.closeStart}
                      open={this.state.startCalendarOpen}
                      targetEl={startDateButton}
                      appendToBody>
                      <SDPWrapper initialDate={this.state.startDate} onDateChange={this.onStartDateChanged}
                        outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedStart} booking={true}
                      />
                    </Popover>
                    {
                      this.state.allDay ?
                      <TimePicker use12Hours={this.use12Hours} format={this.format}
                        value={moment('0:00', this.format)}
                        disabled aria-label={checkInTimeLabel} /> :
                      <TimePicker use12Hours={this.use12Hours} format={this.format}
                        value={startTime} onOpenChange={onOpenChange} aria-label={checkInTimeLabel}
                        onChange={this.onStartTimeChange} minuteStep={5} showNow={false} inputReadOnly={true} />
                    }
                  </div>
                  <div>{i18n.more.bookWorkspace.checkOut}</div>
                  <div className={CSS.durationGroup}>
                    <Popover
                      closeOnSelect={false}
                      onRequestClose={this.closeEnd}
                      open={this.state.endCalendarOpen}
                      targetEl={endDateButton}
                      appendToBody>
                      <SDPWrapper initialDate={this.state.endDate} onDateChange={this.onEndDateChanged}
                        outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedEnd} booking={true}
                      />
                    </Popover>
                    {
                      this.state.allDay ?
                      <TimePicker use12Hours={this.use12Hours} format={this.format}
                        value={moment('23:59', this.format)}
                        disabled aria-label={checkOutTimeLabel} /> :
                      <TimePicker use12Hours={this.use12Hours} format={this.format}
                        value={endTime} onOpenChange={onOpenChange} aria-label={checkOutTimeLabel}
                        onChange={this.onEndTimeChange} minuteStep={5} showNow={false} inputReadOnly={true} />
                    }
                  </div>
                </div>
                {!sameDayBooking
                  ? <Tooltip placement="top" positionFixed
                      title={i18n.calendars.recurringToggleDisable}>{recurrenceUI}</Tooltip>
                  : recurrenceUI
                }
              </div>
            </div>
            <div className={CSS.button}>
                <Button type="button" disabled={isDoneDisabled} onClick={this.onSubmit}>{i18n.general.done}</Button>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    rdxStartDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDayChecked: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY),
    rdxForOthers: Rdx.getValue(state, Rdx.Keys.RESERVE_FOR_OTHERS),
    rdxRecurrence: {
      enabled: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENABLED),
      endDate: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENDDATE),
      type: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_TYPE),
      days: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_DAYS),
      interval: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_INTERVAL)
    }
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRdxValue: (key: string, value) => dispatch(setValue(key, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(DurationFilters);
