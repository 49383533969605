import React, { useCallback, useEffect, useState } from 'react';
import ShareIcon from 'calcite-ui-icons-react/ShareIcon';
import { ModalController } from '../../../common/components/Modal';
import OfficePlan from '../../base/OfficePlan';
import Sharing from '../Modal/Sharing';
import { SharingLevels } from "../../../common/components/Sharing";
import * as groupsUtil from '../../base/groupsUtil';
import Context from '../../../context/Context';


const SharingLevel = () => {
  
  const onShareClick = async (e) => {
    e.stopPropagation();
    e.persist();

    const plan = OfficePlan.getActive();
    const portal = Context.getInstance().getPortal();
    const username = portal && portal.user && portal.user.username;
    const i18n = Context.instance.i18n;
    let showOk = false;
    let disabled = true;
    let id;
    const info = {
      access: null,
      shareIds: [],
      unshareIds: [],
      assignedGroups: null,
      portalItem: null,
      versionInfo: null,
      isVersioned: plan && plan.isVersioned
    }

    if (plan && plan.isVersioned) {
      info.portalItem = plan.supportInfo.serviceItem;
      info.access = info.portalItem.access;
      info.versionInfo = plan.versionInfo;
      showOk = plan.supportInfo.serviceItem.owner === username;
      disabled = plan.supportInfo.serviceItem.owner !== username;
    } else if (plan && plan.planServiceItem) {
      info.portalItem = plan.planServiceItem;
      info.access = info.portalItem.access;
      showOk = plan.planServiceItem.owner === username;
      disabled = plan.planServiceItem.owner !== username;
    }
    id = info.portalItem && info.portalItem.id;
    const [groups, chips] = await groupsUtil.getGroups(id);
    info.assignedGroups = chips;
    let assignedGroupIds = [];
    if (chips && chips.length > 0) assignedGroupIds = chips.map(group => group.id);

    const modalProps = {
      dialogStyle: {minWidth: '35vw', minHeight: '35vh'},
      title: i18n.configurator.appItem.shareWith.sharingLevel.title,
      noPadding: false,
      okLabel: i18n.general.save,
      showOKCancel: showOk,
      closeOnOk: true,
      onOK: () => {
        try {
          let {shareIds, unshareIds} = info;
          if (info.access === SharingLevels.ORGANIZATION) {
            shareIds = [];
            unshareIds = assignedGroupIds;
          }
          if (plan && plan.isVersioned) {
            groupsUtil.updateSharingVersioned(shareIds, unshareIds, info.access, id, info.portalItem, info.versionInfo);
          } else {
            groupsUtil.updateSharing(shareIds, unshareIds, info.access, id, info.portalItem);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    const content = (
      <Sharing
        chips={chips}
        disabled={disabled}
        shareWith={info.portalItem && info.portalItem.access}
        groups={groups}
        onChange={(shareWith, shareIds, unShareIds) => {
          info.access = shareWith;
          if (shareIds) {
            info.shareIds = shareIds.slice();
          }
          if (unShareIds) {
            info.unshareIds = unShareIds.slice();
          }
        }}
      />
    )
    
    const controller = new ModalController({});
    controller.show(modalProps, content);
  }
  
  return (
    <ShareIcon style={{cursor: 'pointer', color: '#202020', marginRight: '1rem'}} size={16} onClick={onShareClick} />
  )
}

export default SharingLevel;