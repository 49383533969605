import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import {
  CalciteH1,
  CalciteH3,
  CalciteH4,
  CalciteH5,
  CalciteP
} from "calcite-react/Elements";
import List, { ListItem, ListHeader } from "../../common/components/List";
import {
  StyledArcgisAccountControl,
  StyledArcgisAccountContentMenu
} from "calcite-react/ArcgisAccount/ArcgisAccount-styled";
import { StyledAvatar } from "calcite-react/Avatar/Avatar-styled";
import Popover from "calcite-react/Popover";
import { StyledPopover } from "calcite-react/Popover/Popover-styled";
import { StyledPanel } from "calcite-react/Panel/Panel-styled";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.theme.levels.backstage};
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.palette.background};
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.baseline};
  border-bottom: 1px solid ${props => props.theme.palette.background};
  color: ${props => props.theme.palette.white};

  button {
    padding: 0;
    color: ${props => props.theme.palette.white};
  }
`;

const BackText = styled(CalciteP)`
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: 1rem;
`;

const LeftPanel = styled.div`
  background-color: ${props => props.theme.palette.themeColorDark};
  width: ${props => props.theme.sidePanelWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LeftPanelContent = styled.div``;

const Title = styled(CalciteH1)`
  margin: 0;
  word-break: break-word;
`;

const ContentPanel = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
`;

const FormControl = styled.div`
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
  margin: ${props => props.theme.baseline};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
`;

const Header = styled(CalciteH3)`
  margin: 0;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: ${props => props.theme.baseline};
  label,
  legend {
    min-width: ${props => unitCalc(props.theme.baseline, 5, "*")};
  }
`;

const InfoCardHeader = styled(CalciteH4)`
  margin: 0;
  color: ${props => props.theme.typeColor};
`;

const InfoCard = styled.div`
  max-width: ${props => unitCalc(props.theme.baseline, 20, "*")};
  padding: ${props => unitCalc(props.theme.baseline, 2, "/")};
  background: ${props => props.theme.palette.white};
  color: ${props => props.theme.typeColor};
  box-shadow: ${props => props.theme.boxShadow};
  margin: ${props => unitCalc(props.theme.baseline, 2, "/")} 0;
`;

const InfoGroup = styled.div`
  margin-bottom: ${props => unitCalc(props.theme.baseline, 2, "/")};
  display: flex;
  flex-direction: column;
`;

const InfoHeader = styled(CalciteH5)`
  margin: 0;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: ${props => props.theme.palette.gray};
`;

const InfoItem = styled.div`
  margin: 0;
  overflow-wrap: break-word;
`;

const StyledList = styled(List)`
  background: transparent;
  border: 0;
  padding: 0;
  margin: ${props => props.theme.baseline} 0;
`;

const StyledListItem = styled(ListItem)`
  background: transparent;
  border: 0;
  color: ${props => props.theme.palette.white};
  padding: ${props => unitCalc(props.theme.baseline, 2, "/")}
    ${props => props.theme.baseline};
  word-break: break-word;

  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme.palette.white};
    color: ${props => props.theme.typeColor};
  }

  ${props =>
    props.active &&
    css`
      background: ${props.theme.palette.white};
      color: ${props => props.theme.typeColor};
    `};
`;

const StyledListHeader = styled(ListHeader)`
  padding: ${props => props.theme.baseline} ${props => props.theme.baseline} 0;
  border-top: 1px solid ${props => props.theme.palette.background};
  background: transparent;
  font-size: 1.2rem;
  color: ${props => props.theme.palette.white};

  &:hover {
    background: transparent;
    color: ${props => props.theme.palette.white};
  }
`;

const ArcgisAccountWrapper = styled.div`
  width: 80%;
  
  & > div {
    width: 100%;
  }
  ${StyledArcgisAccountControl} {
    border-left: 0;
    border-top: 1px solid ${props => props.theme.palette.background};
    color: ${props => props.theme.palette.white};
    height: inherit;
    padding: ${props => props.theme.baseline};
    max-width: inherit;
    &:hover {
      box-shadow: inherit;
      background: ${props => props.theme.palette.themeColor};
    }
  }
  ${StyledArcgisAccountContentMenu} {
    display: none;
  }
  ${StyledAvatar} {
    background: ${props => props.theme.palette.white};
    color: ${props => props.theme.palette.themeColor};
    box-shadow: ${props => props.theme.palette.themeColor} 0px 0px 0px 3px !important;
  }
  ${StyledPopover} {
    width: unset;
  }
`;

const AccountSettings = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${props => props.theme.palette.background};
  &:hover {
    background: ${props => props.theme.palette.themeColor};
    cursor: pointer;
  }

  & svg {
    display: block;
    margin: auto;
    height: 100%;
    fill: ${props => props.theme.palette.background}
  }

  ${StyledPanel} {
    background: #f8f8f8 !important;
    padding: 0.5rem 0 !important;
    margin-left: -${props => unitCalc(props.theme.sidePanelWidth, 0.1, '*')} !important;
  }  
`;

const SettingsPopover = styled(Popover)`
  margin-left: -${props => unitCalc(props.theme.sidePanelWidth, 0.1, '*')} !important;
  box-shadow: none !important;
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SubHeaderContainer = styled.div`
  margin: 0 ${props => props.theme.baseline};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  button {
    background: inherit;
  }
`;

export {
  Wrapper,
  BackButtonContainer,
  BackText,
  LeftPanel,
  LeftPanelContent,
  Title,
  ContentPanel,
  ContentWrapper,
  FormControl,
  HeaderContainer,
  Header,
  SearchWrapper,
  ContentContainer,
  InfoCardHeader,
  InfoCard,
  InfoGroup,
  InfoHeader,
  InfoItem,
  StyledList,
  StyledListItem,
  StyledListHeader,
  ArcgisAccountWrapper,
  AccountSettings,
  SettingsPopover,
  AccountWrapper,
  SubHeaderContainer,
  SortContainer
};
