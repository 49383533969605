import React from "react";

import AreasList from "../Modal/AreasList";
import Context from "../../../context/Context";
//import { ModalController } from "../Modal";
import { ModalController } from "../../../common/components/Modal";
import * as assignPrompt from "./assignPrompt";
import * as component from "../../../components/util/component";

import { CalciteP } from "calcite-react/Elements";
import { Container } from "../../styles/Common/selectArea";
import { SpaceAssignmentTypes } from "../../base/OfficePlan";
export interface IPromptOptions {
  disableAssignments?: boolean,
  from?: string,
  fromName?: string,
  fromItem?: __esri.Graphic,
  target?: string,
  subject: string,
  subjectItem?: __esri.Graphic,
  subjectItems?: __esri.Graphic[]
}
interface ISelectAreaProps {
  onSelectionChanged: (selction: __esri.Graphic) => void,
  areaType: SpaceAssignmentTypes,
  disabledAreaIds: string[],
  promptOptions: IPromptOptions
}

export default class SelectArea extends React.Component<ISelectAreaProps> {
  constructor(props: ISelectAreaProps) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onSelectionChanged = (selected: __esri.Graphic) => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(selected);
    }
  };

  render() {
    const prompt = assignPrompt.getPrompt(this.props.promptOptions);
    return (
      <Container>
        <CalciteP>{prompt.text}</CalciteP>
        <AreasList
          areaType={this.props.areaType}
          disabledAreaIds={this.props.disabledAreaIds}
          handleSelection={this.onSelectionChanged}
        />
      </Container>
    );
  }

  static showModal(props) {
    let content, controller, okButton, selected;

    const onOK = obj => {
      if (props && props.onOK) {
        props.onOK(selected);
      }
    };

    let selectProps = {
      areaType: props && props.areaType,
      disabledAreaIds: props && props.disabledAreaIds,
      promptOptions: props && props.promptOptions,
      onSelectionChanged: sel => {
        selected = sel;
        if (okButton) {
          if (sel) okButton.enable();
          else okButton.disable();
        }
      }
    };

    let modalProps = {
      title: props && props.title,
      okLabel: Context.instance.i18n.spaceplanner.buttonLabel.assign,
      showOKCancel: true,
      onOK: onOK,
      dialogStyle: { height: "65vh", width: "35vw" },
      onMountOKButton: btn => {
        okButton = btn;
        okButton.disable();
      }
    };

    content = <SelectArea {...selectProps} />;
    controller = new ModalController({});
    controller.show(modalProps, content);
  }
}
