import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import DimensionAnnotation from "../annotation/DimensionAnnotation";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "../support/editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as val from "../../../../util/val";
import { WarningMsg, CutType } from "../support/editorUtil";
import Topic from "../../../../context/Topic";
import { ILevelData } from "../../../../aiim/datasets/Levels";
import { debounce } from "../../../miniapps/support/debounceUtil";

import { ILengthAndWidthInfo } from "../../../miniapps/common/types";
import * as unitUtil from "../support/unitUtil";

import StencilMover from "../support/StencilMover";
import { IStencil } from "../../../miniapps/common/types";
import * as stencilUtil from "../support/stencilUtil";

const svmUpdateOptions: __esri.SketchViewModelDefaultUpdateOptions = {
  tool: "reshape", // "transform"|"reshape"|"move"
  enableRotation: true,
  enableScaling: true,
  preserveAspectRatio: false,
  toggleToolOnClick: true,
  enableZ: true
};
const cn = "UnitsVM";
//@ts-ignore
const isDebug = () => typeof window._debug!=='undefined' && window._debug===true;
const warnDiffFloor = "Unit being actively edited has z-value that is not the same as the current floor (on a different floor).";
const warnZAttrs = "Unable to set floor/level/z, rel height for new unit, either unit is drawn over an invalid area or nothing selected in the FloorFilter widget.";

export default class UnitsVM extends BaseVM {

  activeFeature: {
    isModifying: boolean,
    modifiedUnits: __esri.Graphic[],
    originalUnits: __esri.Graphic[],
    warnings: WarningMsg[],
    geometry: __esri.Geometry,
    attributes: Record<string ,any>,
  } = {
    isModifying: false,
    modifiedUnits: [],
    originalUnits: [],
    warnings: [],
    geometry: null,
    attributes: null,
  };
  cutType:CutType = CutType.none;

  dimensionAnnotation: DimensionAnnotation = new DimensionAnnotation({layerType: "unit"});

  highlightOutline: __esri.SimpleLineSymbol;
  highlightSym: __esri.SimpleFillSymbol;
  activeEditLineSym: __esri.SimpleLineSymbol;
  reshapedUnitSym: __esri.SimpleFillSymbol;
  invalidSym: __esri.SimpleFillSymbol;
  removedUnitSym: __esri.SimpleFillSymbol;  

  activeSketchViewModel: __esri.SketchViewModel;
  isBusy:boolean = false;

  activeStencil: {
    stencil: IStencil,
    stencilMover: StencilMover
  } = null;

  lengthAndWidthInfo: ILengthAndWidthInfo;

  private _isMapClickEnabled: boolean = false;
  //to be used by SVM only
  private _svmLyrId:string = "indoors-unit-panel-svm";
  //to show overlapping units that have been modified and their labels or reshaped active unit etc.
  private _resultLayerId:string = "indoors-unit-panel-cuts";
  //to show info/drawings for active feature like labels, boundaries etc. 
  private _unitLayerId:string = "indoors-unit-panel-units";
  private _isValidGeom:boolean = true;
  private _z: number = -1;
  private _fldsToLogForUnit:string[] = [];
  
  onDrawUpdate: (tool:string) => void;
  onDrawComplete: (tool:"create" | "update" | "delete" | "undo" | "redo") => void;
  onValidityChange: (isValid:boolean) => void; 
  onBusyChange: (isBusy:boolean) => void;

  constructor() {
    super();
    this.lengthAndWidthInfo = unitUtil.newLengthAndWidthInfo("units");
  }

  private _init() {
    const ctx = Context.getInstance();
    if (!this.activeSketchViewModel) {
      //#e0f900
      this.highlightOutline = new ctx.lib.esri.SimpleLineSymbol({
        color: [224,294,0, 0.5], 
        width: 3,
        style: "dash"
      });
      this.highlightSym = new ctx.lib.esri.SimpleFillSymbol({
        color: [0,0,0,0],
        style: "solid",
        outline: this.highlightOutline,
      });
      this.activeEditLineSym = new ctx.lib.esri.SimpleLineSymbol({
        color: [0,255,250, 0.5], 
        width: 3,
        style: "dash"
      });
      this.invalidSym = new ctx.lib.esri.SimpleFillSymbol({
        color: [255,0,0,1],
        style: "diagonal-cross",
        outline: {
          color: [75,0,0,0.5],
          width: 3,
          style: "dash"
        },
      });
      this.removedUnitSym = new ctx.lib.esri.SimpleFillSymbol({
        color: [230,163,0,.85],
        style: "diagonal-cross",
        outline: {
          color: [230,122,0,0.5],
          width: 3,
          style: "dash"
        },
      });
      this.reshapedUnitSym = new ctx.lib.esri.SimpleFillSymbol({
        color: [0,255,0,1],
        style: "diagonal-cross",
        outline: {
          color: [0,111,0,0.75],
          width: 3,
          style: "short-dash"
        },
      });
      
      const view = this.getView();
      const unitsLayer = this.getLayer();
      const detailsLayer = sourceUtil.getDetailsLayer();

      const fldUnitId = aiimUtil.findFieldName(unitsLayer.fields, FieldNames.UNIT_ID);
      const fldUnitName = aiimUtil.findFieldName(unitsLayer.fields, FieldNames.NAME);
      const fldUnitUseType = aiimUtil.findFieldName(unitsLayer.fields, FieldNames.UNITS_USE_TYPE); 

      const reqFlds = [fldUnitId, fldUnitName, fldUnitUseType];
      const invalids = reqFlds.filter(x=>!x)
      if (invalids && invalids.length>0) {
        console.warn("Unable to find a required field for details or units:", reqFlds);
      }
      this._fldsToLogForUnit = [fldUnitName, fldUnitId, unitsLayer.objectIdField];      
      
      //order is important
      mapUtil.ensureGraphicsLayer(view, this._unitLayerId);
      const graphicsLayer = mapUtil.ensureGraphicsLayer(view, this._svmLyrId);
      mapUtil.ensureGraphicsLayer(view, this._resultLayerId);

      this.dimensionAnnotation.initialize();
      
      const svm = this.activeSketchViewModel = new ctx.lib.esri.SketchViewModel({
        view: view,
        layer: graphicsLayer,
        snappingOptions: this.makeSnappingOptions()
      });
      svm.defaultUpdateOptions = svmUpdateOptions;
      //svm events -> create, delete, undo, redo, update 
      this.own([
        svm.on("create", this._onSvmCreate),
        svm.on("update", this._onSvmUpdate),
        svm.on("undo",   this._onSvmUndo), 
        svm.on("redo",   this._onSvmRedo),
        this.getView().on("click", this._onActiveCutUnitClick),        
      ]);
    }
  }

  updateAttributes(newAttrs:Record<string, any>) {    
    this.updateUnitSketch(newAttrs);
    if (this.activeFeature && this.activeFeature.attributes) {
      this.activeFeature.isModifying = true;
      this.activeFeature.attributes = newAttrs;
    }
  }

  updateUnitSketch(newAttrs?:Record<string, any>, geom?:__esri.Geometry) {
    newAttrs = newAttrs || this.activeFeature.attributes; //TODO
    const view = this.getView();
    if (this.activeFeature.geometry) {
      const svmGra = this.getMatchingGraphic(this._svmLyrId);
      //const fldUseType = aiimUtil.findFieldName(this.getLayer().fields, FieldNames.UNITS_USE_TYPE);
      const graphicsLayer = mapUtil.ensureGraphicsLayer(view, this._svmLyrId);
      graphicsLayer && graphicsLayer.graphics.forEach(gra => {
        geom && (gra.geometry = geom);
        const sym = this.getSymbol(gra);
        sym && (gra.symbol = sym);
      });
    }
  }

  flip(type: "v"|"h") {
    const ge = Context.instance.lib.esri.geometryEngine;
    if (!"v,h".includes(type))
      return;
    const geom:__esri.Geometry = ge[type==="h" ? "flipHorizontal" : "flipVertical"](this.activeFeature.geometry);
    if (geom) {
      this.activeFeature.geometry = geom;
      this.updateUnitSketch(null, geom.clone());
      //@ts-ignore
      const opHandle = this.activeSketchViewModel._operationHandle;
      opHandle && opHandle.refreshComponent();
      this.updateDimensionAnnotation();
    }
  }

  updateActiveFeatureGeom(geom:__esri.Geometry) {
    if (this.activeFeature && geom) {
      this.activeFeature.geometry = geom;
      this.updateUnitSketch(null, geom.clone());
      //@ts-ignore
      const opHandle = this.activeSketchViewModel._operationHandle;
      opHandle && opHandle.refreshComponent();
      this.updateDimensionAnnotation();

      if (!this.activeSketchViewModel.activeTool) {
        this.addHighlightBorderToMap(geom as __esri.Polygon);
      }

      this._processDuringDrawUpdate({
        graphics: [this.getActiveFeature()],
        tool: "transform",
        type: "update",
        state: "complete",
        toolEventInfo: null,
        aborted: false,
      });      
    }
  }

  updateDimensionAnnotation() {
    if (this.activeFeature && this.activeFeature.geometry) {
      this.dimensionAnnotation.updateDimensions([
        new Context.instance.lib.esri.Graphic({
          geometry: this.activeFeature.geometry.clone()
        })
      ])
    }
  }

  async getLevelData(geometry:__esri.Geometry): Promise<{level?:ILevelData, error?:WarningMsg}> {
    const levels = Context.getInstance().aiim.datasets.levels;
    const list = await levels.queryLevelDataByGeometry(geometry,true);
    let submessage:string = null;
    const i18nEU = Context.instance.i18n.editor.units;

    if (!list || list.length === 0) {
      submessage = i18nEU.unitInALevelFacility;
    } else if(list && list.length > 1) {
      submessage = i18nEU.unitInMultipleLevelFacility;
    }

    let error:WarningMsg = null;
    if (submessage) {
      error = {type: "error", message: i18nEU.invalid, submessage};
    }
    return { level:error ? null : list[0], error };
  }

  activateDrawUnit(attrs?:Record<string, any>, tool: __esri.SketchCreateEvent["tool"] = "polygon", isModifying=false) {
    this.onValidityChange && this.onValidityChange(false);

    //tool = tool && tool.toLowerCase();
    if (!"polygon,rectangle,circle,point".includes(tool)) {
      console.warn("Invalid tool", tool);
      return;
    }
    this.cancelAndclearSketch();

    this._init();

    if (isModifying && this.activeFeature && this.activeFeature.attributes) {
      this.activeFeature.isModifying = true;
      attrs && (this.activeFeature.attributes = attrs);
    } else  {
      this.activeFeature = {
        isModifying: false,
        geometry: null,
        attributes: attrs,
        modifiedUnits: [],
        originalUnits: [],
        warnings: [],
      };
    }
    
    const view = this.getView();
    const ctx = Context.getInstance();
    ctx.views.toggleClickHandlers("pause");
    if (attrs) {
      const gra = new ctx.lib.esri.Graphic({attributes: attrs});
      //@ts-ignore
      this.activeSketchViewModel.polygonSymbol = this.getSymbol(gra);
    }
    const levelId = ctx.views.floorFilter.activeWidget.level;
    let zVal = levelId ? ctx.aiim.datasets.levels.getLevelData(levelId)?.z : 0;

    const placeStencil = (tool === "point");
    if (placeStencil) {
      const { lengthInMapUnits, widthInMapUnits, anchor1Position, anchor2Position} = this.lengthAndWidthInfo;
      const stencil = stencilUtil.newDynamicStencil(view,lengthInMapUnits,widthInMapUnits,anchor1Position,anchor2Position);
      const stencilMover = new StencilMover();
      stencilMover.setupCreate(this.activeSketchViewModel,stencil,view);
      this.activeStencil = {stencil, stencilMover}
    }

    const origFocus = view.focus;
    if (placeStencil) view.focus = () => {};
    this.activeSketchViewModel.create(tool, {
      hasZ: true, 
      defaultZ: zVal || 0,
      mode: "click"
    });
    if (placeStencil) {
      // activating the SketchViewModel calls view.focus(), focus gets lost on the dimension input box
      setTimeout(() => {
        view.focus = origFocus;
      },500)
    }

    // `default`, `crosshair`, `help`, `move`, `pointer`, `progress`, `grab`, `grabbing`
    view && (view.container.style.cursor = "crosshair");
  }

  activateUpdate(graphic?) {
    this.cancelAndclearSketch();
    if (!graphic && this.activeFeature && this.activeFeature.attributes && this.activeFeature.geometry) {
      graphic = new Context.instance.lib.esri.Graphic({
        attributes: this.activeFeature.attributes,
        geometry: this.activeFeature.geometry,
      });
    }
    if (graphic) {
      const symbol = this.getSymbol(graphic);
      graphic.symbol = symbol;
      const gl = mapUtil.ensureGraphicsLayer(this.getView(),this._svmLyrId);
      gl.add(graphic);
      this.activeSketchViewModel.update([graphic], svmUpdateOptions);
    }
  }

  _onSvmCreate = async (event:__esri.SketchViewModelCreateEvent) => {
    if (!(event.toolEventInfo && event.toolEventInfo.type==="cursor-update") && isDebug)
      console.debug("Sketch.create", "|", event.toolEventInfo && event.toolEventInfo.type, "|", event.state, "|",  event);
    this._isMapClickEnabled = false;
    const ctx = Context.getInstance();
    const view = this.getView();
    this.clearGraphics(this._unitLayerId);

    let stencilGraphic;
    if (event.state === "complete" || event.state === "cancel") {
      if (this.activeStencil) {
        const { stencil, stencilMover } = this.activeStencil;
        this.activeStencil = null;
        stencilMover.clear();
        if (event.state === "complete") {
          this.cancelAndclearSketch();
          const lastOR = await stencilMover.lastOffsetResult(view,event,stencil)
          const g = lastOR.geometry;
          stencilGraphic = new Context.instance.lib.esri.Graphic({
            attributes: {},
            geometry: g,
            spatialReference: lastOR.geometry.spatialReference
          })
          this.activateUpdate(stencilGraphic);
        }        
      }
    }

    //event.state: start|active|complete|cancel, 
    //event.tool: polygon|polyline|rectangle|point|circle, 
    //event.type: create|redo|undo|update|delete,
    //event.toolEventInof: CreateToolEventInfo|UpdateToolEventInfo|null,
    if (event.state === "complete") {
      this.cancelSketch();
      const gra = stencilGraphic || event.graphic;
      gra.attributes = this.activeFeature.attributes;
      const { level, error} = await this.getLevelData(gra.geometry);
      this._isValidGeom = !error;

      if (!this.activeFeature.isModifying)
        await this.initNewUnit(gra, level);
      this.activeFeature.geometry = gra.geometry;
      this.activeFeature.attributes = gra.attributes;
      this.activeFeature.isModifying = false;

      if (gra.geometry.type==="polygon") {
        this.addHighlightBorderToMap(gra.geometry as __esri.Polygon);
      }

      if (error) {
        if (gra) gra.symbol = this.invalidSym;
        console.warn(error.submessage);
        Topic.publish(Topic.ShowToast, error);
        this.onDrawComplete && this.onDrawComplete(event.type);
        this.onValidityChange && this.onValidityChange(this.isValid());
      } else {
        await this._processDuringDrawUpdate(event,stencilGraphic);
      }
      
      //this.onDrawComplete && this.onDrawComplete(event.type); //'create' SketchViewModelCreateEvent.type
      //this.onValidityChange && this.onValidityChange(this.isValid());
      this._isMapClickEnabled = this.cutType===CutType.cutThis; //TODO
    }

    this.dimensionAnnotation.handleCreateEvent(event,stencilGraphic);
  }

  _onSvmUpdate = async (event: __esri.SketchViewModelUpdateEvent) => {
    if (event.toolEventInfo && !"rotate,move,reshape,scale".includes(event.toolEventInfo.type) && isDebug())
      console.debug("Sketch.update", "|", event.toolEventInfo && event.toolEventInfo.type, "|", event.state, "|",  event);        

    this._isMapClickEnabled = false;
    this.activeFeature.isModifying = true;
    const ctx = Context.getInstance();
    this.clearGraphics(this._unitLayerId);

    const view = this.getView();
    const geom = event.graphics && event.graphics[0] && event.graphics[0].geometry;

    const tei = event.toolEventInfo; 
    // MoveEventInfo | ReshapeEventInfo | RotateEventInfo | ScaleEventInfo | SelectionChangeEventInfo | VertexAddEventInfo | VertexRemoveEventInfo
    const teiType = tei && tei.type;
    const handledTypes = ["move-stop","reshape-stop","rotate-stop","scale-stop","vertex-remove","vertex-add"];
    
    if (handledTypes.includes(teiType)) {
      await this._processDuringDrawUpdate(event); //promise
    } 

    if (event.state === "complete") {
      if (geom) {
        this.activeFeature.geometry = geom;
      }
    
      if (geom.type==="polygon") {
        this.addHighlightBorderToMap(geom as __esri.Polygon);
      }
      this.cancelSketch();

      this.activeFeature.isModifying = false;
      this.onDrawComplete && this.onDrawComplete(event.type); //"update"
      this.onValidityChange && this.onValidityChange(this.isValid());
      this._isMapClickEnabled = this.cutType===CutType.cutThis;
    }

    this.dimensionAnnotation.handleUpdateEvent(event);
  }

  private _onSvmUndo = async (event:__esri.SketchViewModelUndoEvent) => {
    this._processDuringDrawUpdate(event);
    this.dimensionAnnotation.handleUndoEvent(event);
  }

  private _onSvmRedo = async (event:__esri.SketchViewModelRedoEvent) => {
    this._processDuringDrawUpdate(event);
    this.dimensionAnnotation.handleRedoEvent(event);
  }

  async _processDuringDrawUpdate(event:__esri.SketchViewModelUpdateEvent
    | __esri.SketchViewModelCreateEvent
    | __esri.SketchViewModelUndoEvent 
    | __esri.SketchViewModelRedoEvent,
    stencilGraphic?: __esri.Graphic) {
    if (!this.activeFeature.isModifying || event.type==="create") {
      isDebug() && console.debug("New Unit just drawn on map");
    }
    this.setBusy(true);

    const ctx = Context.getInstance();
    const i18nEU = Context.instance.i18n.editor.units;

    const gfx = stencilGraphic || ("graphics" in event ? event.graphics && event.graphics[0] : (event as __esri.SketchViewModelCreateEvent).graphic);
    const geom = gfx && gfx.geometry;
    if (geom)
      this.activeFeature.geometry = geom;
    this.activeFeature.warnings.length = 0;
    Topic.publish(Topic.ClearToast, {}); 
    
    const { level, error} = await this.getLevelData(geom);
    if (error) {
      this._isValidGeom = false;
      console.warn(error.submessage);
      gfx.symbol = this.invalidSym;
      this.clearGraphics(this._resultLayerId);
      Topic.publish(Topic.ShowToast, error);
    } else {
      this._isValidGeom = true;
      await this.updateModifiedUnit(this.getActiveFeature(), level);
      gfx.symbol = this.getSymbol(this.activeFeature);
      if (this.cutType===CutType.cutUnderlying) {
        await this.cutUnderlyingUnits();
      } else if (this.cutType===CutType.cutThis) {
        await this.cutNewUnit();
      }      
    }

    //event.type = create|update|redo|undo|delete - type from SketchViewModelCreateEvent|SketchViewModelUpdateEvent
    if ("create,undo,redo".includes(event.type)) {
      this.onDrawComplete && this.onDrawComplete(event.type); 
    } else {
      this.onDrawUpdate && this.onDrawUpdate(event.type); 
    }
    this.onValidityChange && this.onValidityChange(this.isValid());
    this.setBusy();
    return null;
  };

  _onActiveCutUnitClick = async (e:__esri.ViewClickEvent) => {
    if (!this._isMapClickEnabled || this.isSketchActive())
      return;
    isDebug() && console.debug(cn, "map clicked", e);
    const hitTestResult = await (this.getView() as __esri.MapView).hitTest(e);
    let vwHits = hitTestResult.results;
    vwHits = Context.instance.aiim.fixHitTestResult(vwHits); 
    const oidFld = this.getLayer().objectIdField;
    const activeAttr = this.activeFeature && this.activeFeature.attributes;
    if (!activeAttr)
      return;

    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    if (!svmGra)
      return;

    const gfx = vwHits && vwHits.find(vwHit => {
      if (vwHit.type==="graphic"
        && vwHit.graphic.layer?.id===this._resultLayerId
        && this.cutType === CutType.cutThis
      ) {
        return vwHit.graphic.attributes[oidFld] === svmGra.attributes[oidFld];
      }
      return false;
    });
    if (gfx) {
      isDebug() && console.debug('matched gra from resultsLayer to svmLayer, and ready to update');
      this.activeSketchViewModel.update([svmGra], svmUpdateOptions);
      this._isMapClickEnabled = false;
    }
  }

  updateZBasedAttrs(gra:{geometry:__esri.Geometry, attributes:Record<string,any>}, levelData:ILevelData, isNewlyDrawn:boolean=true) {
    //const levelData = Context.instance.aiim.datasets.levels.getLevelData(floorFilter.activeWidget.level);
    //const levelData = (await this.getLevelData(gra.geometry)).level;
    if (levelData) {
      const attr = gra.attributes;
      const layer = this.getLayer();
      const levelIdField = aiimUtil.findFieldName(layer.fields, FieldNames.LEVEL_ID);
      const featureLevelId = attr[levelIdField];
      const floorFilter = Context.instance.views.floorFilter;
      const ffLevelId = floorFilter.activeWidget.level;

      editorUtil.updateZ(gra.geometry, levelData.z);

      if (levelData.levelId!==featureLevelId) {
        //newly drawn unit moved to another level or facility, so update it
        !isNewlyDrawn && console.warn(warnDiffFloor);
        attr[levelIdField] = levelData.levelId;
        const heightRelField = aiimUtil.findFieldName(layer.fields, FieldNames.HEIGHT_RELATIVE);
        if (heightRelField && typeof levelData.heightRelative==="number") {
          attr[heightRelField] = levelData.heightRelative;
        }
      }
      if (ffLevelId!==levelData.levelId) {
        console.warn(warnDiffFloor);
      }
    } else {
      this._isValidGeom = false;
      console.warn(warnZAttrs);
    }    
  }

  async updateModifiedUnit(modifiedUnit:__esri.Graphic, levelData:ILevelData) {
    this.setBusy(true);
    aiimUtil.removeShapeAttributes(modifiedUnit.attributes);
    this.updateZBasedAttrs(modifiedUnit, levelData, false);
    await editorUtil.updateGrossArea(modifiedUnit);
    this.setBusy();
  }

  async initNewUnit(newUnit:__esri.Graphic, levelData:ILevelData) {
    this.setBusy(true);
    const attributes = newUnit.attributes;
    aiimUtil.removeShapeAttributes(attributes);
    const layer = this.getLayer();
    
    const fldUnitId = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
    const fldAsn = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const fldName = aiimUtil.findFieldName(layer.fields, FieldNames.NAME);
    const fldLongNm = aiimUtil.findFieldName(layer.fields, FieldNames.NAME_LONG);
    const fldUseType = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_USE_TYPE);

    const useType = attributes[fldUseType] || "Unit";
    const newName = "New " + useType; 
    const unitId = val.generateRandomUuid(); //{includeBraces:true}
    const asnType = fldAsn && attributes[fldAsn];
    
    delete attributes[layer.objectIdField];
    delete attributes[aiimUtil.getGlobalIdField(layer)];
    attributes[fldUnitId] = unitId;
    attributes[fldName] = newName;

    if (fldLongNm) 
      attributes[fldLongNm] = newName + " " + new Date().getTime();;
    if (asnType === "office") {
      //attributes[areaIdField] = null; // @todo ?
      attributes[fldAsn] = "none";
    }
    await editorUtil.updateGrossArea(newUnit);
    await this.updateZBasedAttrs(newUnit, levelData, true);
    this.setBusy();
    return newUnit;
  }

  setBusy(isBz:boolean=false) {
    this.isBusy=!!isBz;
    this.onBusyChange && this.onBusyChange(this.isBusy);
  }

  cancelSketch() {
    const view = this.getView();
    const svm = this.activeSketchViewModel;
    if (svm) {
      svm.cancel();
      //svm.destroy();
      //this.activeSketchViewModel = null;
    }
    Context.instance.views.toggleClickHandlers("resume");
    if (view) {
      // `default`, `crosshair`, `help`, `move`, `pointer`, `progress`, `grab`, `grabbing`
      view.container.style.cursor = "default";
    } 
  }

  cancelAndclearSketch() {
    this.cancelSketch();
    this.dimensionAnnotation.clear();
    const view = this.getView();
    [this._svmLyrId, this._unitLayerId, this._resultLayerId].forEach(id => mapUtil.removeAllGraphics(view, id));
  }

  reset() {
    this.cancelAndclearSketch();
    this.activeFeature = null;
    this._isValidGeom = true;
  }

  destroy() {
    this.cancelAndclearSketch();
    const view = this.getView();
    [this._svmLyrId, this._unitLayerId, this._resultLayerId].forEach(id => {
      const gl = mapUtil.findLayerById(view, id);
      gl && view.map.remove(gl);
    });
    
    this.activeFeature = null;
    this.onDrawComplete = null;
    this.activeSketchViewModel && this.activeSketchViewModel.destroy();
    this.dimensionAnnotation.destroy();
    super.destroy();
  }

  addModifiedUnitsToMap() {
    this.activeFeature.modifiedUnits.forEach(g => { 
      g.symbol = g.geometry.type==="polygon" ? this.highlightSym : this.highlightOutline;
    });
    this._addGraphics(this.activeFeature.modifiedUnits, this._resultLayerId);
  }

  addHighlightBorderToMap(geom:__esri.Polygon) {    
    this.clearGraphics(this._unitLayerId);
    if (!geom)
      return;
    const ctx = Context.getInstance();
    const paths = [];
    (geom.clone() as __esri.Polygon).rings.forEach((r:number[][]) => paths.push(r));
    const line:__esri.Polyline = new ctx.lib.esri.Polyline({
      paths: paths,
      spatialReference: geom.spatialReference,
    });
    const highlightGra = new Context.instance.lib.esri.Graphic({
      attributes: this.activeFeature.attributes, 
      geometry: line,
      symbol: this.activeEditLineSym,
    });
    this._addGraphics([highlightGra], this._unitLayerId);
  }

  private _addGraphics(gras:__esri.Graphic[], lyrId:string) {
    const gl:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), lyrId);
    Array.isArray(gras) && gras.length>0 && gl && gl.addMany(gras);
  }

  private getMatchingGraphic(lyrId:string):__esri.Graphic {
    const gl:__esri.GraphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(), lyrId);
    const fldOid = this.getLayer().objectIdField;
    const gra = this.getActiveFeature();
    return gra && gl.graphics.find(g => g.attributes && gra.attributes && g.attributes[fldOid]===gra.attributes[fldOid]);
  }

  clearGraphics(lyrId:string) {
    mapUtil.removeAllGraphics(this.getView(), lyrId);
  }

  getLayer(): __esri.FeatureLayer {
    return sourceUtil.getUnitsLayer();
  }

  getView() {
    return Context.getInstance().views.activeView;
  }

  isSketchActive() {
    return this.activeSketchViewModel && this.activeSketchViewModel.state==="active";
  }

  async cutUnderlyingUnits(isSync?:boolean) {
    try {
      this.setBusy(true);
      this.clearGraphics(this._resultLayerId);
      Topic.publish(Topic.ClearToast,{});
      this.activeFeature.warnings.length=0;
      this.activeFeature.modifiedUnits = null;
      this.activeFeature.originalUnits = null;
      const gra = this.getActiveFeature();
      if (!gra || !gra.geometry || !gra.attributes) {
        return; 
      }

      const svmGra = this.getMatchingGraphic(this._svmLyrId);
      svmGra && isSync && (gra.geometry = this.activeFeature.geometry = svmGra.geometry.clone());
      
      const i18nEU = Context.instance.i18n.editor.units;
      const cutResult = await editorUtil.cutThisOrUnderlyingUnits(gra, CutType.cutUnderlying, false, i18nEU.warnBadGeomPreferExisting);
      if (!cutResult || !Array.isArray(cutResult.cutFeatures) || cutResult.cutFeatures.length===0) {
        return;
      }
      const { cutFeatures, engulfedFeatures, warnings, originalFeatures } = cutResult;
      cutFeatures.forEach(g => g.geometry && (g.symbol = g.geometry.type==="polygon" ? this.highlightSym : this.highlightOutline));
      engulfedFeatures.forEach(gfx => gfx.symbol = this.removedUnitSym);

      this.activeFeature.modifiedUnits = cutFeatures;
      this.activeFeature.originalUnits = originalFeatures;
      this.activeFeature.warnings = warnings;
      this._addGraphics([...cutFeatures.filter(f=>f.geometry), ...engulfedFeatures], this._resultLayerId);
      Array.isArray(warnings) && warnings.forEach(w => Topic.publish(Topic.ShowToast, w));
      this.updateActiveFeatureGrossArea();
    } catch (err) {
      console.warn("cutUnderlyingUnits error:", err);
    } finally {
      this.setBusy();
    }
  }

  cutNothing() {
    this.clearGraphics(this._resultLayerId);
    this.activeFeature.warnings.length=0;
    this.activeFeature.modifiedUnits = null;
    this.activeFeature.originalUnits = null;
    const svmGra = this.getMatchingGraphic(this._svmLyrId);
    if (svmGra) {
      this.activeFeature.geometry = svmGra.geometry;
    }
    this.updateActiveFeatureGrossArea();
  }

  async cutNewUnit() {
    try {
      this.setBusy(true);
      this.clearGraphics(this._resultLayerId);
      Topic.publish(Topic.ClearToast,{});
      this.activeFeature.warnings.length=0;
      this.activeFeature.modifiedUnits = null;
      this.activeFeature.originalUnits = null;
      const graphic = this.getActiveFeature();
      if (!graphic || !graphic.geometry || !graphic.attributes) {
        return null; 
      }

      let resultGeom:__esri.Geometry = null;
      const i18nEU = Context.instance.i18n.editor.units;
      const cutResult = await editorUtil.cutThisOrUnderlyingUnits(graphic, CutType.cutThis, false, i18nEU.warnBadGeomPreferNew, false);
      if (cutResult && Array.isArray(cutResult.cutFeatures) && cutResult.cutFeatures.length>0) {
        const { cutFeatures, warnings } = cutResult;
        const thisCut = cutFeatures[0];
        const fldOid = this.getLayer().objectIdField;
        const svmGraLyr = mapUtil.ensureGraphicsLayer(this.getView(), this._svmLyrId);
        const svmGra = svmGraLyr.graphics.find(g => g.attributes[fldOid] === graphic.attributes[fldOid]);

        //if featuresToCut is currently being edited (cutThis), keep geom as-is, don't allow the new invalid geom
        //active unit could be entirely cut out by other existing units
        if (!this.isGeometryEqual(svmGra.geometry, thisCut.geometry)) {
          thisCut.symbol = this.reshapedUnitSym;
          if (!this._isValidGeom) thisCut.symbol = this.invalidSym;
          this.activeFeature.geometry = thisCut.geometry;
          this.activeFeature.warnings = warnings;
          this._isMapClickEnabled = true;
          this._addGraphics([thisCut], this._resultLayerId); 
          resultGeom = thisCut.geometry;
        }

        Array.isArray(warnings) && warnings.forEach(w => Topic.publish(Topic.ShowToast, w));
      }

      this.updateActiveFeatureGrossArea();
      if (this.isSketchActive() && this.cutType === CutType.cutThis) {
        this._isMapClickEnabled = true;
      }
      return resultGeom;
    } catch (err) {
      console.warn("cutNewUnit error:", err);
    } finally {
      this.setBusy();
    }
    
  }

  async updateActiveFeatureGrossArea() {
    const g = this.getActiveFeature();
    if ((g.geometry as __esri.Polygon).rings[0]?.length > 3) {
      await editorUtil.updateGrossArea(g);
      this.onDrawUpdate && this.onDrawUpdate("transform");
      this.onValidityChange && this.onValidityChange(this.isValid());
    }
  }

  getSymbol(gra:{attributes:Record<string,any>}): __esri.Symbol {
    if (!gra && this.activeFeature && this.activeFeature.attributes)
      gra = this.getActiveFeature();
    if (!gra || !gra.attributes)
      return null; 
    const lyr = this.getLayer();
    if (!lyr || !lyr.renderer)
      return null;
    //@ts-ignore
    let sym = lyr.renderer.getSymbol(gra) || lyr.renderer.defaultSymbol || lyr.defaultSymbol;
    return sym;
  }

  getActiveFeature(): __esri.Graphic {
    if (this.activeFeature && this.activeFeature.geometry) 
      return new Context.instance.lib.esri.Graphic({
        geometry: this.activeFeature.geometry, 
        attributes: this.activeFeature.attributes
      });
    return null;
  }

  isValid(): boolean {
    return !!(this.activeFeature 
      && this.activeFeature.geometry 
      && this.activeFeature.attributes
      && this._isValidGeom);
  }

  save = async () => {
    const gra = this.getActiveFeature();
    if (!gra)
      throw new Error("Unable to get geometry or attributes to save");

    // User should be able to change GROSS AREA and value honored after save #7325
    // await editorUtil.updateGrossArea(gra);

    const info = { newUnits: [gra], modifiedUnits: [], originalUnits: [] };

    if (this.activeFeature.modifiedUnits && this.cutType===CutType.cutUnderlying) {
      info.modifiedUnits.push(...this.activeFeature.modifiedUnits);
      info.originalUnits.push(...this.activeFeature.originalUnits);

      const lyr = this.getLayer();
      await Promise.all(info.modifiedUnits.map(f => editorUtil.updateGrossArea(f)));
    }

    console.debug(cn + ".save()", info);
    const results = await transactions.fpeEditUnit(info);
    console.debug("fpeEditUnit.save() results", results);
    this.cancelAndclearSketch();
  }

  isGeometrySimple(geom?:__esri.Geometry):boolean {
    const thisGeom = geom ? geom : this.activeFeature && this.activeFeature.geometry;
    if (!thisGeom)
      return false;
    if (thisGeom.type!=='polygon') { 
      const poly = thisGeom as __esri.Polygon;
      if (poly.isSelfIntersecting || poly.rings.length>1)
        return false;
    }
    const ge = Context.instance.lib.esri.geometryEngine;
    const isSimple = ge.isSimple(thisGeom);
    if (!isSimple)
      this.activeFeature.geometry = ge.simplify(thisGeom);
    return isSimple;
  }

  isGeometryEqual(geometry:__esri.Geometry, otherGeometry:__esri.Geometry): boolean {
    if (!(geometry && otherGeometry && geometry.type === otherGeometry.type)) {
      return false;
    }
    const ge: typeof __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    return ge.equals(geometry, otherGeometry);
  }

}