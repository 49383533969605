import React from "react";
import { connect } from "react-redux";
import { getActiveTab } from "../../redux";
import SidebarHeader from "./SidebarHeader";
import * as component from "../../../components/util/component";

interface Props {
  activeTab?: string;
  activeTabKey: string;
  children?: any;
  icon?: any;
  label: string;
  tab?: string;
}

interface State {
}

class SidebarPanel extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { tab, activeTab } = this.props;
    const active = tab && (tab === activeTab);
    if (!active) return null;
    return (
      <div className="i-editor-sidebar-panel">
        <SidebarHeader activeTabKey={this.props.activeTabKey} label={this.props.label} icon={this.props.icon} />
        <div className="i-editor-sidebar-panel-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => ({
  activeTab: getActiveTab(state,ownProps.activeTabKey)
});

export default connect(mapStateToProps)(SidebarPanel);
