import React from "react";

import DropdownButton from "../Dropdown/DropdownButton";
import Icons from "../../util/Icons";
import * as component from "../../util/component";

const CSS = {
  dropdown: "i-dropdown",
  dropdownButton: "i-dropdown-button",
  dropdownButtonSubCaption: "i-dropdown-button-subcaption",
  dropdownMenuContainer: "i-dropdown-menu-container",
  dropdownMenu: "i-dropdown-menu",
  dropdownMenuContent: "i-dropdown-menu-content",
  menuOption: "i-menu-option",
  emptyIcon: "svg-icon i-icon-empty"
};

export default class ChoiceDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onOptionKeyUp = this.onOptionKeyUp.bind(this);
  }

  onOptionClick(event) {
    //this.dropdownUtil.setDropdownIsActive(false);
  }

  onOptionKeyUp(event) {
    if (event && event.keyCode === 13) this.onOptionClick(event);
  }

  render() {
    const caption = this.props.caption;
    // const subcaption = this.props.subcaption;
    //
    // let btnClassName = CSS.dropdownButton;
    // let txtClassName = CSS.dropdownButtonSubCaption;
    // if (this.props.disabled) {
    //   btnClassName += " i--disabled";
    //   txtClassName += " i--disabled";
    // }

    // let subcaptionNode  = null;
    // if (typeof subcaption === "string" && subcaption.length > 0) {
    //   subcaptionNode = (
    //     <div key="subcaption" className={txtClassName}>{subcaption}</div>
    //   );
    // }


    let icon = Icons.dropdown();
    if(this.props.icon ==="threeDots"){
      icon = Icons.colorContrast();
    }

    const buttonContent = (
      <>
        <span key="caption" className={this.props.captionClass}>{caption}</span>
        <span key="icon" className={this.props.arrowClass}>{icon}</span>
      </>
    );
    const dropdownContent = this.renderDropdownMenu();

    const placement = "bottom-start";
    return (
      <div className={CSS.main}>
        <DropdownButton
          closeOnSelect={true}
          focusFirst={true}
          buttonClassName={"i-choice-dd"}
          className={"i-dd-levelfilter"}
          buttonContent={buttonContent}
          dropdownContent={dropdownContent}
          disabled={!!this.props.disabled}
          placement={placement}>
        </DropdownButton>
      </div>
    );
  }

  renderDropdownMenu() {
    let liClass = !!this.props.facilityfilter ? 
      "i-facilities-list" : !!this.props.sitefilter ?
      "i-sites-list" : null
    // const isFirefox = typeof InstallTrigger !== 'undefined';
    // liClass += isFirefox ? " firefox" : ""
    const menu = (
      <ul role="menu" className={liClass}
        onClick={this.onOptionClick} onKeyUp={this.onOptionKeyUp}
        >{this.props.children}</ul>
    );
    return menu;
  }

  // renderDropdownMenu() {
  //   if (!this.dropdownUtil.isDropdownActive()) return null;
  //   const menu = (
  //     <div className={CSS.dropdownMenuContainer}>
  //       <div className={CSS.dropdownMenu}>
  //         {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
  //         <div className={CSS.dropdownMenuContent} role="dialog"
  //           onClick={this.onOptionClick} onKeyUp={this.onOptionKeyUp}
  //           ref={this.dropdownCreated}>
  //           <ul>{this.props.children}</ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  //   return menu;
  // }

  // scrollToMenuOption() {
  //   try {
  //     const contentNode = this.dropdownUtil && this.dropdownUtil.dropdownMenuContentNode;
  //     if (contentNode) {
  //       const nl = contentNode.querySelectorAll("a[data-i-checked='y']");
  //       if (nl && nl.length === 1) {
  //         const nd = nl[0];
  //         if (nd && typeof nd.scrollIntoView === "function") {
  //           nd.scrollIntoView();
  //         }
  //       }
  //     }
  //   } catch(ex) {
  //     console.error("Error scrolling to menu option",ex);
  //   }
  // }

}
