import React, { Component } from "react";
import { connect } from "react-redux";

import OfficePlan      from "../../base/OfficePlan";
import * as queryUtil  from "../../base/queryUtil";
import QueryAll        from "../../base/QueryAll";
import * as sourceUtil from "../../base/sourceUtil";
//import Panel from "../../../common/components/Panel";
import Rdx     from "../../../redux/Rdx";
import Topic   from "../../../context/Topic";
import Context from "../../../context/Context";
import * as aiimUtil   from "../../../aiim/util/aiimUtil";
import FieldNames      from "../../../aiim/datasets/FieldNames";
import * as component  from "../../../components/util/component";
import * as dateUtil   from "../../../components/main/Events/dateUtil";
import Button          from "../../../common/components/Button";
import ModalController from "../../../common/components/Modal/ModalController";
import { ContentWrapper } from "../../styles/Work/workBar";

import CalciteComboButton from "calcite-react/ComboButton";
import Panel, {PanelTitle, PanelText} from "calcite-react/Panel";
//import { ComboButton, Button } from "../../styles/Common/peopleAssignTo";
import Menu, { MenuItem } from "calcite-react/Menu";
import moment from "moment";

import TopNav, { TopNavBrand, TopNavTitle, TopNavList, TopNavLink, TopNavActionsList } from 'calcite-react/TopNav'
import XIcon from "calcite-ui-icons-react/XIcon";
import { ButtonContainer } from "../../styles/commentsStyles";
import HotelAreaUsageChartSettings from "./HotelAreaUsageChartSettings";
import Loader from "calcite-react/Loader";
import * as val from "../../../util/val";
import reactDom from "react-dom";

import { am4charts, am4core } from "../../../context/amcharts4";

let now;
let daysOfTheWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
const cn = "HotelAreaUsageAnalytics";
const isDebug = ()=>typeof _debug!=='undefined' && window._debug;

class HotelAreaUsageAnalytics extends Component {

    _mounted = false;

    divBarChart = null;
    divBarChartWrapper = null;
    barChart = null;
    global_start_time = null; 
    global_end_time = null; 
    unitIdsInArea = null; 
    cachedFeatureAttrs = [];
    cachedStats = null;
    reservationsLyrUrl = null;

    isPlanModified = false;

    ddlDateRange = null;
    ddlChartType = null;

    constructor(props) {
        super(props);
        this.uid = val.generateRandomUuid();
        now = new Date();
        const i18n = Context.instance.i18n;
        this.state = component.newState({
            working: true,
            dateRangeLabel: i18n.spaceplanner.usageAnalytics.pastWeek,
            startDate: moment(now).subtract(1, "week").startOf('day').valueOf(),
            endDate:   moment(now).endOf('day').valueOf(),
            workDayStartTime: moment('2022-01-01 08:00:00').valueOf(),
            workDayEndTime: moment('2022-01-01 17:00:00').valueOf(),
            workWeek: [1,2,3,4,5],
            chartType: 'daily',
            chartLabel: i18n.spaceplanner.usageAnalytics.chartDaily,
            isDisabled: props.isDisabled,
            isAllDay: false,
        });
        this.requiredFieldsObj = props.requiredFieldsObj;
        this.divBarChart = React.createRef();
        this.divBarChartWrapper = React.createRef();
        this.ddlDateRange = React.createRef();
        this.ddlChartType = React.createRef();
    }

    async componentDidMount() {
        const lib = Context.getInstance().lib;
        const htmlNode = document.getElementsByTagName("html")[0];
        const i18nLang = htmlNode && htmlNode.hasAttribute('lang') ? htmlNode.attributes['lang'].value : null;
        const dojoLocale = Context.getInstance().lib.dojo.kernel.locale;
        isDebug() && console.debug("HotelAreaUsageAnalytics.mounted isRtl:", Context.instance.uiMode.isRtl, "language:", i18nLang, "dojoLocale", dojoLocale);
        
        const i18n = Context.instance.i18n;
        daysOfTheWeek = [i18n.calendars.sun, i18n.calendars.mon, i18n.calendars.tues, i18n.calendars.wed, i18n.calendars.thurs, i18n.calendars.fri, i18n.calendars.sat];

        this._mounted = true;
        this.props.onMount && this.props.onMount(this);
        component.own(this, [
            Topic.subscribe(Topic.PlanModified, params => {
                const actions = [OfficePlan.Action_AssignmentsUpdated];
                if (actions && actions.indexOf(params.action) !== -1) {
                    this.cachedFeatureAttrs = [];
                    this.cachedStats = null;
                    if (this.props.isShowUsagePanel) {
                        this.onShowUsageClicked(null);
                    }
                }
            })
        ]);

        const layer = sourceUtil.getReservationsLayer();
        if (!layer) {
            console.warn("Reservations Layer not found");
            this.setState({isDisabled: true });
            return;
        }

        this.reservationsLyrUrl = Context.checkMixedContent(layer.url+"/"+layer.layerId);
        this.requiredFieldsObj = this.props.requiredFieldsObj;

        if (this.requiredFieldsObj) {
            Object.assign(this, this.requiredFieldsObj);
        } else {
            this.FIELD_START_TIME = aiimUtil.findFieldName(layer.fields, FieldNames.START_TIME);
            this.FIELD_END_TIME   = aiimUtil.findFieldName(layer.fields, FieldNames.END_TIME);
            this.FIELD_ALL_DAY    = aiimUtil.findFieldName(layer.fields, FieldNames.ALL_DAY);
            this.FIELD_UNIT_ID    = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
            this.FIELD_STATE      = aiimUtil.findFieldName(layer.fields, FieldNames.STATE);
            this.FIELD_CHECK_IN_TIME  = aiimUtil.findFieldName(layer.fields, FieldNames.CHECK_IN_TIME);
            this.FIELD_CHECK_OUT_TIME = aiimUtil.findFieldName(layer.fields, FieldNames.CHECK_OUT_TIME);
        }

        this.requiredFields = [this.FIELD_START_TIME, this.FIELD_END_TIME, this.FIELD_ALL_DAY, this.FIELD_UNIT_ID, 
            this.FIELD_STATE, this.FIELD_CHECK_IN_TIME, this.FIELD_CHECK_OUT_TIME];
        if (this.requiredFields.filter(x=>!x).length>0) {
            console.warn("Reservations Layer does not have all required fields. Charts/Analytics disabled", this.requiredFields);
            this.setState({isDisabled: true });
            return;
        }
    }

    componentWillUnmount() {
        component.componentWillUnmount(this);

        this.cachedFeatureAttrs = [];
        this.cachedStats = null;
        this.chartDataDaily = null;
        this.chartDataHourly = null;
        this.chartDataDailyUtilization = null;
        this.barChart && this.barChart.dispose();

        this._mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isShowUsagePanel) {
            return;
        }

        const state = this.state;
        let isChartRefresh=false;
        let isReCreateChartData=false;
        let isReQuery=false;
        if (this.isPlanModified) {
            this.cachedFeatureAttrs = [];
            this.cachedStats = null;
            this.isPlanModified = false;
            if (this.props.isShowUsagePanel) {
                setTimeout(async() => { await this.onShowUsageClicked(null) }, 200);
            }
        } else {
            if (this.props.isShowUsagePanel===true && prevProps.isShowUsagePanel===false) {
                this.onShowUsageClicked(null);
                return;
            } else if (state.startDate < prevState.startDate  || state.endDate > prevState.endDate) {
                isReQuery=true; //only re-query if dates are out of range of cachedData else do client-side filtering
            } else if (state.startDate!==prevState.startDate || state.endDate!==prevState.endDate) {
                isReCreateChartData=true;
            } else if (state.isAllDay !== prevState.isAllDay) {
                isReCreateChartData = true;
            } else if (
                state.isAllDay === prevState.isAllDay 
                && 
                (
                    (state.workDayStartTime && this.getMinutesOfDay(state.workDayStartTime) !== this.getMinutesOfDay(prevState.workDayStartTime))
                    || 
                    (state.workDayEndTime && this.getMinutesOfDay(state.workDayEndTime)!==this.getMinutesOfDay(prevState.workDayEndTime))
                )
            ) {
                isReCreateChartData=true;
            } else {
                if (state.chartType !== prevState.chartType) {
                    isChartRefresh=true;
                } else if (state.workWeek && state.workWeek.length>0 && prevState.workWeek && prevState.workWeek.length>0)  {
                    if (JSON.stringify(state.workWeek.sort())!==JSON.stringify(prevState.workWeek.sort()))
                        isChartRefresh=true;
                }
            }
        }
        isDebug() && console.debug(`${cn}.didUpdate - isReQuery:${isReQuery}, isReCreateChartData:${isReCreateChartData}, isChartRefresh:${isReCreateChartData}`);

        if (isReQuery) {
            this.queryReservationData(state.workDayStartTime, state.workDayEndTime, state.startDate, state.endDate, state.isAllDay);
        } else if (isReCreateChartData) {
            this.createChartData(this.cachedFeatureAttrs, state.workDayStartTime, state.workDayEndTime, state.startDate, state.endDate, state.isAllDay);
        } else if (isChartRefresh) {
            this.createChart(state.workWeek, state.chartType);
        }
    }

    async queryGlobalDateRange(url) {
        const fs = await queryUtil.queryStats({
            source: { url },
            outStatistics: [{
                "statisticType": "min",
                "onStatisticField": this.FIELD_START_TIME, //FieldNames.START_TIME, //"START_TIME",
                "outStatisticFieldName": "global_start_time"
            },
            {
                "statisticType": "max",
                "onStatisticField": this.FIELD_END_TIME, //FieldNames.END_TIME, //"END_TIME",
                "outStatisticFieldName": "global_end_time"
            }
            ],
            where: null
        });
        if (fs && fs.features && fs.features.length>0) {
            this.global_start_time = this.getFieldValue(fs.features[0].attributes, "global_start_time");
            this.global_end_time   = this.getFieldValue(fs.features[0].attributes, "global_end_time");
        }
    }

    async queryUnitIdsInArea() {
        this.unitIdsInArea = null;
        try {
            const layer = sourceUtil.getUnitsLayer();
            const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
            const areaIdField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_AREA_ID);
            const asnField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
            const query = new Context.instance.lib.esri.Query();
            query.outFields = [FieldNames.UNIT_ID];
            query.returnGeometry = false;
            query.where = `(${areaIdField}='${this.props.activeAreaId}') AND (${asnField}='hotel')`;
            const fs = await new QueryAll().executeInParallel(url, query, { layer: layer });
            const fldUnitId = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
            this.unitIdsInArea = fs.features.map(f => f.attributes[fldUnitId]);
            isDebug() && console.debug("Units in this Area:", this.unitIdsInArea);
        } catch (err) {
            console.error("Unable to get units in area: ", err);
        }
    }

    getFieldValue(attr, fldNm) {
        if (!attr || !fldNm)
            return null;
        if (attr.hasOwnProperty(fldNm))
            return attr[fldNm];
        fldNm = fldNm.toLowerCase();
        for (var fld in attr) {
            if (fld.toLowerCase()===fldNm)
                return attr[fld];
        }
        return null;
    }

    getMinutesOfDay(dtc) {
        if (!dtc)
            return null;
        let dt = dtc;
        if (!(dtc instanceof Date)) 
            dt = new Date(dtc);
        return dt.getHours()*60 + dt.getMinutes();
    }

    //calls createChartData -> createChart
    async queryReservationData(workDayStartTime, workDayEndTime, startDate, endDate, isAllDay=false) {
        isDebug() && console.debug(`${cn}.queryReservationData()`, 'startDate', new Date(startDate), 'endDate', new Date(endDate), 'startTime', this.getMinutesOfDay(workDayStartTime), 'endTime', this.getMinutesOfDay(workDayEndTime), 'isAllDay', isAllDay);
        if (!this.unitIdsInArea || this.unitIdsInArea.length<1) {
            console.warn("No units in area");
            this.cachedFeatureAttrs = [];
            this.cachedStats = null;
            this.chartDataDaily = [];
            this.chartDataHourly = [];
            this.chartDataDailyUtilization = [];
            this.setState({isNoData: true});
            return; 
        }
        const { FIELD_UNIT_ID:fldUnitId, FIELD_START_TIME:fldStartTime, FIELD_END_TIME:fldEndTime, FIELD_ALL_DAY:fldAllDay, FIELD_STATE:fldState } = this;
        const query = new Context.instance.lib.esri.Query();
        query.outFields = this.requiredFields;
        query.returnGeometry = false;

        let startDtStr = dateUtil.getZulu(dateUtil.toStartOfDay(new Date(startDate)));
        let endDtStr   = dateUtil.getZulu(dateUtil.toEndOfDay(new Date(endDate)));
        //note: a reservation could be long enough to contain both the start and end dates, so below takes care of it
        // NOT (feat.edtc<startDateMS || endDateMS<feat.sdtc)
        // (startDateMS<feat.edtc && feat.sdtc<endDateMS)
        // feat.sdtc < endDateMS && feat.edtc > startDateMS   (IMPORTANT)
        query.where = `(${fldStartTime} < TIMESTAMP '${endDtStr}' AND ${fldEndTime} > TIMESTAMP '${startDtStr}' AND ${fldState} IN (1,4,5))`;        
        //NOTE that timestring must be in UTC aka Zulu
        //START_TIME BETWEEN timestamp '2022-01-04 05:00:00' AND timestamp '2022-08-09 14:44:59'        
        //query.where = `((${this.FIELD_START_TIME} BETWEEN TIMESTAMP '${startDtStr}' AND TIMESTAMP '${endDtStr}') OR (${this.FIELD_END_TIME} BETWEEN TIMESTAMP '${endDtStr}' AND TIMESTAMP '${endDtStr}'))`;
        isDebug() && console.debug("time extent to query:", query.where);
        // STATE/STATUS of a reservartion could be 0 = Pending, 1 = Reserved, 3 = Canceled, 4 = Checked in, 5 = Checked out

        //query.timeExtent = { start: startDate, end: endDate};

        let allFeatures = [];
        const qaopts = { layer: sourceUtil.getReservationsLayer() };
        const allPromises = [];
        if (this.unitIdsInArea.length > 1000) {
            //when units>1000, we submit multiple queries to overcome an issue in oracle dbms where the sql IN stmt cannot exceed 1000 items
            let qCnt = 0;
            for (let i=0; i<this.unitIdsInArea.length; i+=1000) {
                const unitIds  = this.unitIdsInArea.slice(i, i+1000);
                const oneQuery = query.clone();
                oneQuery.where += ` AND (${fldUnitId} IN ('${unitIds.join("','")}'))`;
                allPromises.push(new QueryAll().executeInParallel(this.reservationsLyrUrl, oneQuery, qaopts));
            }
            const allTasks = await Promise.all(allPromises);
            allTasks.forEach(task => allFeatures.push(...task.features));
        } else {            
            query.where += ` AND (${fldUnitId} IN ('${this.unitIdsInArea.join("','")}'))`;
            const task = await new QueryAll().executeInParallel(this.reservationsLyrUrl, query, qaopts);
            allFeatures = task.features;
        }
        
        const dailyBookings = [];
        let numIgnored = 0;
        const msInHr = 60*60*1000;
        allFeatures.forEach(f => {
            const attr = f.attributes;
            const sdtc = attr[fldStartTime];
            let edtc = attr[fldEndTime];
            const isAllDayRes = attr[fldAllDay];
            const fState = attr[fldState]; // 0=Pending, 1=Reserved, 3=Canceled, 4=Checked in, 5=Checked out
            
            if (!sdtc || !edtc) {
                console.log("WARN: found a reservation feature with invalid start or end date/s:", JSON.stringify(attr));
                if (!sdtc) {
                    numIgnored++;
                    return;
                } else { //if end dt not present set to 8pm of same day
                    edtc = attr[fldEndTime] = this.addDaySetHourMinute(sdtc, 0, 19, 0).getTime();
                }
            }
            const fStartDate = new Date(sdtc);
            const fEndDate = new Date(edtc);

            const resLengthMs = Math.abs(edtc-sdtc);
            //if booking is for over a day, split that booking into multiple days
            if (resLengthMs > (23.9*msInHr) && (fStartDate.getDate() !== fEndDate.getDate())) {
                const numDays1 = resLengthMs / (24*msInHr);
                const numDays = Math.floor(numDays1);
                let numHours = (numDays1%1) * 24;
                const numMins = (numHours%1) * 60
                numHours = Math.floor(numHours);
                isDebug() && console.debug(`Found a multi-day reservation: [${numDays}]days [${numHours}]hours [${numMins}]mins,   ${this.datesToString(attr)}     feature:`, attr);
                attr[fldEndTime] = this.addDaySetHourMinute(fStartDate, 0, 17, 0).getTime();
                dailyBookings.push(attr);
                
                for (let i=1;i<=numDays;i++) {
                    const newAttr = JSON.parse(JSON.stringify(attr));
                    const new_stdt = this.addDaySetHourMinute(fStartDate, i, 7, 0); //8am of next day
                    if (!new_stdt)
                        break;
                    newAttr[fldStartTime] = new_stdt.getTime();
                    newAttr[fldEndTime]   = this.addDaySetHourMinute(new_stdt, 0, 17, 0).getTime(); //6pm of same day
                    newAttr[fldAllDay]    = 1;
                    dailyBookings.push(newAttr);
                    isDebug() && console.debug(`     [${i}] Added a split multi-day reservation: `, this.datesToString(newAttr));
                }
            } else {
                dailyBookings.push(attr);
            }
        });
        isDebug() && console.debug("queryReservationData() - items ignored due to invalid start date:", numIgnored);
        this.cachedFeatureAttrs = dailyBookings;
        this.setState({isNoData: !this.cachedFeatureAttrs || this.cachedFeatureAttrs.length<1});
        this.createChartData(this.cachedFeatureAttrs, workDayStartTime, workDayEndTime, startDate, endDate, isAllDay);
    }

    datesToString(attr) {
        return `from: ${moment(attr[this.FIELD_START_TIME]).format('YY/MM/DD HH:mm')} to: ${ moment(attr[this.FIELD_END_TIME]).format('YY/MM/DD HH:mm')}`;
    }

    addDaySetHourMinute(initialDate, numDays /** days to add */, setHr /** 0 to 23 */, setMin /** 0 to 59 */) {
        const stdt = new Date(initialDate);
        if (!stdt)
            return null;
        if (numDays > 0)
            stdt.setDate(stdt.getDate()+numDays);
        stdt.setHours(setHr);
        stdt.setMinutes(setMin);
        stdt.setSeconds(0);
        stdt.setMilliseconds(0);
        return stdt;
    }

    /*
        //initialize a date based on a timezone - pst, pdt, est, edt, cst, etc. 
        var dt4 = new Date('2022-1-1 0:59:00 AM utc');
        >'2022-01-01T00:59:00.000Z'
        //adjust hours (add or substract) based on a timezone's offset
        new Date(new Date(dt4).setHours(dt4.getHours() - 4.5)).toISOString(); 
        >'2021-12-31T19:59:00.000Z'
        //Add or substract a day
        new Date(new Date(dt4).setDate(dt4.getDate() - 1)).toISOString();
        >'2021-12-31T00:59:00.000Z'

        //Always do the cacls in UTC and get day of week in UTC as well
        new Date(new Date(dt4).setUTCHours(dt4.getUTCHours() - 7)).getUTCDay()

        //6 = timezone's current offset (a timezone could have multiple offsets)
        let dtx=new Date('2022/07/31 20:45 utc'); new Date(dtx.setUTCHours(dtx.getUTCHours() + 6)).getUTCDate()


        //https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
        new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' })); 

        //List of all timezones in JS
        https://stackoverflow.com/questions/38399465/how-to-get-list-of-all-timezones-in-javascript
        https://github.com/UltiRequiem/timezones/blob/main/mod.ts
    */
    changeTimezone(date, tzStr) {
        if (!(date instanceof Date))
            date = new Date(date);
        const tzDate = new Date(date.toLocaleString('en-US', {
            timeZone: tzStr //e.g. America/Los_Angeles, Asia/Dhaka, US/Eastern
        }));
        return tzDate;
        //const diff = date.getTime() - tzDate.getTime();
        //return new Date(date.getTime() - diff);        
    }

    getDatesBetweenDates(startDate, endDate) {
        let dates = []        
        const theDate = new Date(startDate)
        while (theDate < new Date(endDate)) {
            dates = [...dates, new Date(theDate)]
            theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, new Date(endDate)]
        return dates
    }

    //also refreshes the chart
    createChartData(featureAttrs, workStartTime, workEndTime, startDateMS, endDateMS, isAllDay=false) {
        isDebug() && console.debug(featureAttrs && featureAttrs.length, 'startDate', new Date(startDateMS), 'endDate', new Date(endDateMS), 'startTime', 
            this.getMinutesOfDay(workStartTime), 'endTime', this.getMinutesOfDay(workEndTime));

        if (!startDateMS || !new Date(startDateMS)){ //if absent, set it to 1 year ago
            console.log(`WARN: invalid startDate[${startDateMS}] specified, using default of past year`);
            let sDt = dateUtil.toStartOfDay(new Date());
            sDt.setFullYear(sDt.getFullYear()-1);
            startDateMS = sDt.getTime();
        }
        if (!endDateMS || !new Date(endDateMS)) {
            console.log(`WARN: invalid endDate[${endDateMS}] specified, defaulting to end of today`);
            endDateMS = dateUtil.toEndOfDay(new Date()).getTime();
        }

        const startDateMins = Math.floor(startDateMS/60000);
        const endDateMins = Math.floor(endDateMS/60000);

        this.chartDataDaily = null;
        this.chartDataDailyUtilization = null;
        this.chartDataHourly = null;
        this.cachedStats = null;

        const dateBinStats = {}; //1 obj for each day 
        const dateBinStats_sample = { 
            '5/1/22': { maxUnits:9, maxWhen:615, dayOfWeek:0, intervals: { 0:0, 15:0, 30:0, 45:0, 60:0, 360:6, 630:9, 1440:0 }},
            '5/2/22': { maxUnits:9, maxWhen:570, dayOfWeek:1, intervals: { 0:{u1:null, u2:null, u4:null}, 360:{u3:null, u4:null, u9:null}, 630:{}, 1440:{} }}, 
            '8/1/22': { 
                maxUnits:9, maxWhen:885, dayOfWeek:3, intervals: { 0:0, 360:6, 630:9, 1440:0  },
                maxUnitsUtilized: 8, maxWhenUtilized: 485, intervalsUtilized: { 0:0, 360:6, 630:9, 1440:0 }
            }, 
            '9/1/22': { 
                maxUnits:9, maxWhen:885, dayOfWeek:4, intervals: { 0:0, 360:6, 630:9, 1440:0  }, 
                maxUnitsUtilized: 8, maxWhenUtilized: 885, intervalsUtilized: { 0:0, 360:6, 630:9, 1440:0 }
            }, 
        };

        //minutes passed since midnight, default to 8am
        const stMinsFilter = workStartTime ? this.getMinutesOfDay(workStartTime) : 480;
        //minutes passed since midnight, default to 6pm
        const endMinsFilter = workEndTime ? this.getMinutesOfDay(workEndTime) : 1080;
        const stHrFilter = new Date(workStartTime).getHours();
        const endHrFilter = new Date(workEndTime).getHours()===23 ? 24 : new Date(workEndTime).getHours();

        const startHr = new Date(workStartTime).getHours();
        const endHr = new Date(workEndTime).getHours();
        function formatHours(hr0to23) {
            var hours = hr0to23;          
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'            
            return hours + ' ' + ampm;
        }        

        let numIgnored=0;
        let numCounted=0;
        const ignored = [];

        featureAttrs.forEach(attr => {
            const sdtc = attr[this.FIELD_START_TIME];
            let edtc = attr[this.FIELD_END_TIME];
            const unitId = attr[this.FIELD_UNIT_ID];
            const isAllDayRes = attr[this.FIELD_ALL_DAY] || isAllDay;

            const fState = attr[this.FIELD_STATE];
            const checkIn = attr[this.FIELD_CHECK_IN_TIME];
            const checkOut = attr[this.FIELD_CHECK_OUT_TIME];

            if (!sdtc || !edtc) {
                console.warn("Found a reservation feature with invalid start or end date/s:", JSON.stringify(attr));
                if (!sdtc) {
                    numIgnored++;
                    return;
                } else { //if end dt not present set to 8pm of same day
                    edtc = this.addDaySetHourMinute(sdtc, 0, 19, 0).getTime();
                }
            }

            //filter out the reservation intervals outside the range (start and end date), but don't filter out the intervals that intersect the range.
            if (edtc < startDateMS || endDateMS < sdtc) {
                isDebug() && console.debug(`IGNORED: ${edtc/60000}(featEnd) < ${startDateMins}(filterSt) || ${endDateMins}(filterEnd) < ${sdtc/60000}(featSt)`, this.datesToString(attr));
                numIgnored++;
                ignored.push(attr);
                return;
            }
            const featStDt = new Date(sdtc); //new Date(new Date(sdtc).toLocaleString('en-US', { timeZone: 'US/Pacific' })); //TODO convert time to a timezone
            const featEndDt = new Date(edtc); //new Date(new Date(edtc).toLocaleString('en-US', { timeZone: 'US/Pacific' })); //TODO convert time to a timezone

            const featStMins = featStDt.getHours()*60 + featStDt.getMinutes();
            const featEndMins = featEndDt.getHours()*60 + featEndDt.getMinutes();
            const dayIdx = featStDt.getDay();
            //At this stage, multi-day reservations shouldn't exist since they were decomposed at a prior step
            if (featStDt.getMonth()+"_"+featStDt.getDay() !== featEndDt.getMonth()+"_"+featEndDt.getDay()) {
                console.warn("Found a multiple day reservation: ", unitId, featStDt, featEndDt, attr);
            }
            const featEndHr = Math.ceil(featEndMins/60);
            const featStHr = featStDt.getHours();
            
            const ddMMyyyy = moment(featStDt).format('l');
            let isCount = false; 
            if (isAllDayRes || (featStMins < endMinsFilter && featEndMins > stMinsFilter)) {
                isCount = true;
                numCounted++;
            } else {
                isDebug() && console.debug(`IGNORED: itemSt:${featStMins/60} < filterEnd:${endMinsFilter/60} && itemEnd:${featEndMins/60} > filterSt:${stMinsFilter/60}`, this.datesToString(attr));
                numIgnored++;
                ignored.push(attr);
                return;
            }

            !dateBinStats[ddMMyyyy] && (dateBinStats[ddMMyyyy] = {
                maxUnits:0,
                maxWhen:0,
                dayOfWeek:0,
                intervals:{},
                maxUnitsUtilized: 0,
                maxWhenUtilized: 0,
                intervalsUtilized: {},
            });
            const statsObj = dateBinStats[ddMMyyyy];
            //const startMinInterval = Math.floor(featStMins/15)*15;
            //for (let slice=startMinInterval; slice<featEndMins; slice=slice+15) {
            for (let slice=stMinsFilter; slice<endMinsFilter; slice=slice+15) {
                //if (!(featStMins <= slice && slice <= featEndMins))
                if (slice < featStMins || featEndMins <= slice)
                    continue;
                !statsObj.intervals[slice] && (statsObj.intervals[slice]={});
                statsObj.intervals[slice][unitId] = null;
                const numUnitsInSlice = Object.keys(statsObj.intervals[slice]).length;
                //!statsObj.intervals[interval] && (statsObj.intervals[interval]=0);
                //statsObj.intervals[interval]++ 
                //const numUnitsInSlice = statsObj.intervals[slice];
                statsObj.dayOfWeek = dayIdx;
                if (statsObj.maxUnits < numUnitsInSlice) {
                    statsObj.maxUnits = numUnitsInSlice; 
                    statsObj.maxWhen = slice;
                }

                if (fState!==3 && (checkIn || checkOut)) {
                    // 0 = Pending, 1 = Reserved, 3 = Canceled, 4 = Checked in, 5 = Checked out
                    //TODO  ?? Reservations not utilized for the full duration of the bookings should be accounted for in calculating the actual usage ??
                    !statsObj.intervalsUtilized[slice] && (statsObj.intervalsUtilized[slice]={});
                    statsObj.intervalsUtilized[slice][unitId] = null;
                    const numUnitsUtilizedInSlice = Object.keys(statsObj.intervalsUtilized[slice]).length;                        
                    if (statsObj.maxUnitsUtilized < numUnitsUtilizedInSlice) {
                        statsObj.maxUnitsUtilized = numUnitsUtilizedInSlice; 
                        statsObj.maxWhenUtilized = slice;
                    }
                }                    
            } //for slices
        }); //for 

        isDebug() && console.debug('numIgnored by filter:', numIgnored, `numCounted: ${numCounted}/${featureAttrs.length}`);

        let isEmpty = true;
        //convert units hashmap to just a count
        for (let dateP in dateBinStats) {
            const statsObj = dateBinStats[dateP];
            for (let slice in statsObj.intervals) {
                const unitsInSlice = statsObj.intervals[slice];
                statsObj.intervals[slice] = Object.keys(unitsInSlice).length;
            }
            for (let slice in statsObj.intervalsUtilized) {
                const unitsInSlice = statsObj.intervalsUtilized[slice];
                statsObj.intervalsUtilized[slice] = Object.keys(unitsInSlice).length;
            }
            if (isEmpty) { 
                isEmpty = statsObj.maxUnits < 1;
            }
        }
        //this.cachedStats = dateBinStats;
        isDebug() && console.debug("dateBinStats", dateBinStats);           
        

        //initialize hrly peak obj for each day of week (s-s)
        //[{}, {}, {}, {}, {}, {}, { 0:{}, 1:{ dayIdx:2, weekday:'T', hour:'2PM', value:8, maxDates:[{'5/1/22':1},{'5/8/22':4},...]}, 2:{} , 3:{}..., 23:{} }]
        const obj_hourly_peaks = [...new Array(7)].map((un, d) => {
            const obj = {};
            for (let hr=stHrFilter; hr<endHrFilter; hr++) {
                obj[hr] = { dayIdx:d, weekday:daysOfTheWeek[d], hour:formatHours(hr), value:0, maxDates:[]};
            }
            //[...new Array(24)].forEach((un, h) => {return {dayIdx:d, weekday:daysOfTheWeek[d], hour:formatHours(h), value:0, maxDates:[]}});
            return obj;
        });

        //chart data for daily peaks + daily util peaks
        const cd_daily_peaks_and_utils = [...new Array(7)].map((un, d) => { 
            return { 
                dayIdx:d, day:daysOfTheWeek[d], 
                //for keeping track of bookings/reservations
                unitsBooked:0, 
                maxDates:[], 
                maxHours:[],
                //for keeping track of utilizations
                unitsUtilized:0,    
                maxUtilizedDates:[],
                maxUtilizedHours: [],
            }
        });

        //convert the dateBinStats (date(5/1/22)-intervals(15m)-rooms(u1,u2,u5)) to chart data (daily, hour peaks)
        for (let dateP in dateBinStats) {
            const statsObj = dateBinStats[dateP];
            const dayIdx = statsObj.dayOfWeek;            
            if (dayIdx!==null && dayIdx>-1) {
                //populate the daily peaks
                const dailyPeakObj = cd_daily_peaks_and_utils[dayIdx];
                if (dailyPeakObj.unitsBooked <= statsObj.maxUnits) {
                    dailyPeakObj.unitsBooked = statsObj.maxUnits;
                    //the maxDates now contains multiples days with different maxDate values, 
                    //the days where the max occurs is filtered at a later step                    
                    dailyPeakObj.maxDates.push({max:statsObj.maxUnits, date:dateP});
                    dailyPeakObj.maxHours.push({max:statsObj.maxUnits, hour:formatHours(Math.floor(statsObj.maxWhen/60))});
                }
                //add the daily util peaks, only when it's higher than what was encountered before
                if (dailyPeakObj.unitsUtilized <= statsObj.maxUnitsUtilized) {
                    dailyPeakObj.unitsUtilized = statsObj.maxUnitsUtilized;
                    //the maxUtilizedDates now contains multiples days with different maxUtilized values, 
                    //the days where the max occurs is filtered at a later step
                    dailyPeakObj.maxUtilizedDates.push({max:statsObj.maxUnitsUtilized, date:dateP});
                    dailyPeakObj.maxUtilizedHours.push({max:statsObj.maxUnits, hour:formatHours(Math.floor(statsObj.maxWhenUtilized/60))});
                }

                //populate the hourly peaks
                const dayHourlyPeakObjs = obj_hourly_peaks[dayIdx];
                for (let hr=stHrFilter; hr<endHrFilter; hr++) {
                    const hourlyPeakObj = dayHourlyPeakObjs[hr];
                    let maxInHr = 0;
                    //get the max from the 4x15min intervals in this hour
                    for (let intvl=0; intvl<4; intvl++) {  
                        let cnt = statsObj.intervals[(hr*60)+(intvl*15)];
                        if (cnt && maxInHr <= cnt)
                            maxInHr = cnt;
                    }
                    if (hourlyPeakObj.value<maxInHr)
                        hourlyPeakObj.value = maxInHr;
                    if (maxInHr>0)
                        hourlyPeakObj.maxDates.push({[dateP]:maxInHr});
                }
            }
        }

        const splitIntoLines = function(arr, itemsPerLine=4) {
            let str = "";
            const len = arr.length;
            arr.forEach((dt, i) => {
                if (i===len-1)
                    str += dt;
                else if (i>=(itemsPerLine-1) && (i+1)%itemsPerLine===0)
                    str += dt+"\n";
                else
                    str += dt+", ";
            });
            return str; 
        }

        //only get the dates where the 'max' occurred, filter out the other dates, and then split into lines
        const processMaxDates = function(arrOfMaxDatesKV=[{max:0,date:'1/1/1'},{max:0, date:'1/2/1'}], fldNm='date') {
            let maxDates = arrOfMaxDatesKV;
            if (Array.isArray(maxDates) && maxDates.length>0) {
                if (maxDates.length > 1) {
                    const maxUtil = Math.max(...maxDates.map(x=>x.max));
                    maxDates = maxDates.filter(x=>x.max===maxUtil).map(x=>x[fldNm]);
                } else {
                    maxDates = maxDates.map(x=>x[fldNm]);
                }

                if (maxDates && maxDates.length > 4) {
                    maxDates = splitIntoLines(maxDates);
                }
                return maxDates;
            }
        }

        //format the maxDates in daily peaks
        cd_daily_peaks_and_utils.forEach((dailyPeakObj, dayIdx) => {
            //only show the dates where the 'max' occurred, filter out the other dates
            dailyPeakObj.maxDates = processMaxDates(dailyPeakObj.maxDates);
            dailyPeakObj.maxHours = processMaxDates(dailyPeakObj.maxDates, 'hour');

            //only show the dates where the 'max utilized' occurred, filter out the other dates
            dailyPeakObj.maxUtilizedDates = processMaxDates(dailyPeakObj.maxUtilizedDates);
            dailyPeakObj.maxUtilizedHours = processMaxDates(dailyPeakObj.maxUtilizedHours, 'hour');
        });

        //create chart data for hourly peaks, and get the dates for the hourly peak usages only
        const cd_hourly_peaks = [];
        obj_hourly_peaks.forEach((dayHourDateUnits, dayIdx) => {
            Object.entries(dayHourDateUnits).forEach( kvArr => {
                const hr = kvArr[0], hourlyPeakObj = kvArr[1];
                //get the dates where the peak usage occurred for this day and hour from the list of all maxDates
                const pkUsg = Math.max(...hourlyPeakObj.maxDates.map(kv => Object.values(kv)[0]));
                hourlyPeakObj.maxDates = hourlyPeakObj.maxDates.filter(kv => Object.values(kv)[0]===pkUsg).map(kv => Object.keys(kv)[0]);
                cd_hourly_peaks.push({
                    dayIdx,
                    hour: hourlyPeakObj.hour, 
                    weekday: hourlyPeakObj.weekday,
                    value: hourlyPeakObj.value, 
                    maxDates: hourlyPeakObj.maxDates && hourlyPeakObj.maxDates.length<6 ? hourlyPeakObj.maxDates : null,
                });
            })
        });
        this.chartDataDaily = cd_daily_peaks_and_utils;
        this.chartDataHourly = cd_hourly_peaks;
        this.chartDataDailyUtilization = cd_daily_peaks_and_utils;
        
        this.setState({isNoData: isEmpty});
        !isEmpty && this.createChart();
    }

    createChartHourly(workWeek, chartData) {
        this.disposeChart();
        const i18n = Context.instance.i18n;
        if (!chartData)
            chartData = this.chartDataHourly;

        if (!workWeek)
            workWeek = this.state.workWeek; 
        isDebug() && console.debug('workWeek:', workWeek);
        if (workWeek && workWeek.length>0)
            chartData = chartData.filter(obj => workWeek.includes(obj.dayIdx));              

        const chart = am4core.create(this.divBarChart.current, am4charts.XYChart);
        this.barChart = chart;
        chart.maskBullets = false;
        chart.numberFormatter.numberFormat = "#.";
        chart.rtl = Context.instance.uiMode.isRtl;

        const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "weekday";
        yAxis.dataFields.category = "hour";
        
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;
        
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;
        
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "weekday";
        series.dataFields.categoryY = "hour";
        series.dataFields.value = "value";
        series.dataFields.maxDates = "maxDates";
        series.name = "Max Units booked by hour of day";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;
        series.columns.template.width = am4core.percent(100);
        series.columns.template.height = am4core.percent(100);     
        
        series.heatRules.push({
            target:series.columns.template, 
            property:"fill", 
            min:am4core.color("#FFFF62"), //TODO match with theme color , 00b140
            max:am4core.color("#004d4c")  //692155 (purple), 890902 (deep red), 004d4c (indoors theme) c80815 (nice red)
        });

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#ffffff");
        columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')} " + i18n.spaceplanner.usageAnalytics.unitsBooked + "\n {maxDates}";
        
        // heat legend
        var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = series;
        heatLegend.numberFormatter.numberFormat = "#.";
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.numberFormatter.numberFormat = "#.";
        heatLegend.valueAxis.adjustLabelPrecision = false;
        

        // heat legend behavior
        //series.columns.template.events.on("click", (event) => { console.debug('series.colum click', event) });
        //series.columns.template.events.on("over",  (event) => { handleHover(event.target) });
        //series.columns.template.events.on("hit",   (event) => { handleHover(event.target) });
        function handleHover(column) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            } else {
                heatLegend.valueAxis.hideTooltip();
            }
        }
        series.columns.template.events.on("out", (event) => { heatLegend.valueAxis.hideTooltip() });

        let sampleData = [
            {
                "hour": "12pm",
                "weekday": "Sun",
                "value": 2990
            },
            {
                "hour": "1am",
                "weekday": "Sun",
                "value": 2520
            },
            {
                "hour": "2am",
                "weekday": "Sun",
                "value": 2334
            },
            {
                "hour": "3am",
                "weekday": "Sun",
                "value": 2230
            },
            {
                "hour": "4am",
                "weekday": "Sun",
                "value": 2325
            }
        ];
        chart.data = chartData;
    }

    createChartDaily(workWeek, chartData) {
        //console.debug('areaItem', this.props.areaItem, 'areaId', this.props.activeAreaId, 'unitsLayer', sourceUtil.getUnitsLayer());
        const i18n = Context.instance.i18n;
        const numUnits = Array.isArray(this.unitIdsInArea) ? this.unitIdsInArea.length : 0;
        //isDebug() && console.debug('numUnits', numUnits);

        this.disposeChart();

        if (!chartData)
            chartData = this.chartDataDaily;

        if (!workWeek)
            workWeek = this.state.workWeek; 
        //isDebug() && console.debug('workWeek:', workWeek);
        
        const chart = am4core.create(this.divBarChart.current, am4charts.XYChart);
        this.barChart = chart;
        chart.numberFormatter.numberFormat = "#.";
        chart.rtl = Context.instance.uiMode.isRtl;
        const sampleData = [
            {
                dayIdx: 1,
                day: 'Mon',
                totalUnitsBooked: 439,
                unitsBooked: 99,
                maxDates: "2022-06-12,2022-06-19,2022-06-26"
            },
            {
                dayIdx: 2,
                day: 'Tues',
                totalUnitsBooked: 383,
                unitsBooked: 85,
                maxDates: "2022-06-13,2022-06-20"
            },
            {
                dayIdx: 3,
                day: 'Wed',
                totalUnitsBooked: 699,
                unitsBooked: 93,
                maxDates: "2022-06-14,2022-06-21"
            },
            {
                dayIdx: 4,
                day: 'Thurs',
                totalUnitsBooked: 560,
                unitsBooked: 50,
                maxDates: "2022-06-15,2022-06-29"
            },
            {
                dayIdx: 5,
                day: 'Fri',
                totalUnitsBooked: 350,
                unitsBooked: 42,
                maxDates: "2022-06-16"
            }
        ];
        chart.data = chartData || sampleData;
        if (workWeek && workWeek.length>0)
            chart.data = chart.data.filter(obj => workWeek.includes(obj.dayIdx));
        
        const maxYValue = Math.max(...chart.data.map(d => d.unitsBooked));
        isDebug() && console.debug("maxYValue", maxYValue);

        // Create x-axes (day of week)
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        //categoryAxis.title.text = "Working Days";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;

        //create y-axis (units)
        const vAxis = new am4charts.ValueAxis();
        vAxis.integersOnly = true;
        vAxis.maxPrecision = 0;
        const valueAxis = chart.yAxes.push(vAxis);
        valueAxis.title.text = i18n.spaceplanner.usageAnalytics.unitsBooked; //"Units Booked" ;
        valueAxis.min = 0;
        valueAxis.maxPrecision = 0;
        //valueAxis.minGridDistance = 1;
        valueAxis.numberFormatter.numberFormat = "#.";
        valueAxis.strictMinMax = true;
        valueAxis.adjustLabelPrecision = false;

        //match it with theme color (--i-theme-color-brand)
        //..\git-indoors-web\theme\base\theme.scss
        //import { Theme } from "./theme";
        const themeBgColor = getComputedStyle(document.documentElement).getPropertyValue("--i-theme-color-brand");
        isDebug() && console.debug("themeBgColor", themeBgColor);

        //create series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "unitsBooked";
        series.dataFields.categoryX = "day";
        series.dataFields.days = "maxDates";
        series.name = i18n.spaceplanner.usageAnalytics.unitsBooked;
        series.columns.template.tooltipText = "{categoryX}: {valueY} " + i18n.spaceplanner.usageAnalytics.unitsBooked + "\n {maxDates}";
        series.columns.template.fill = am4core.color("#0079c1"); // fill "#004d4c"
        !!0 && series.columns.template.adapter.add("fill", function(fill, target){
            if (numUnits<5 || maxYValue<5)
                return fill;
            const valY = target.dataItem ? target.dataItem.valueY : null;
            if (valY && valY >2 && (valY >= numUnits || valY >= maxYValue))
                return am4core.color("#c60b01");
            return fill;
        });

        //gradient colors on the columns/bars based on valueY of the series
        if (false) {
            series.heatRules.push({
                "target": series.columns.template,
                "property": "fill",
                "min": am4core.color("#004d4c"),
                "max": am4core.color("#890902"),
                "dataField": "valueY"
            });

            const heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
            heatLegend.width = am4core.percent(100);
            heatLegend.series = series;
            heatLegend.numberFormatter.numberFormat = "#.";
            //heatLegend.minValue = 0;
            heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
            heatLegend.valueAxis.numberFormatter.numberFormat = "#.";
            heatLegend.valueAxis.adjustLabelPrecision = false;
        }
    }

    createChartDailyUtilization(workWeek, chartData) {
        const sampleData = [{
            dayIdx: 1,
            day: 'Mon',
            unitsBooked: 99,
            unitsUtilized: 78,
            maxDates: ['22/11/31','22/10/24']
        }, {
            dayIdx: 2,
            day: 'Tues',            
            unitsBooked: 77,
            unitsUtilized: 59,
            maxDates: []
        }, {
            dayIdx: 3,
            day: 'Wed',      
            unitsBooked: 66,
            unitsUtilized: 61
        }, {
            dayIdx: 4,
            day: 'Thurs',
            unitsBooked: 81,
            unitsUtilized: 63
        }, {
            dayIdx: 5,
            day: 'Fri',
            unitsBooked: 46,
            unitsUtilized: 41
        }];

        const i18n = Context.instance.i18n;
        this.disposeChart();
        if (!chartData)
            chartData = this.chartDataDailyUtilization; 
        if (!workWeek)
            workWeek = this.state.workWeek;

        const chart = am4core.create(this.divBarChart.current, am4charts.XYChart);
        this.barChart = chart;
        chart.numberFormatter.numberFormat = "#.";
        chart.rtl = Context.instance.uiMode.isRtl;
        chart.data = chartData;
        if (workWeek && workWeek.length>0)
            chart.data = chart.data.filter(obj => workWeek.includes(obj.dayIdx));        

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'top';
        chart.legend.paddingBottom = 20;
        chart.legend.labels.template.maxWidth = 95;

        //X-axis (based on unique values/category)
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 0;

        //Y-axis (value based on numeric scale)
        const vAxis = new am4charts.ValueAxis();
        vAxis.integersOnly = true;
        vAxis.maxPrecision = 0;
        const valueAxis = chart.yAxes.push(vAxis);
        valueAxis.min = 0;
        valueAxis.maxPrecision = 0;        
        valueAxis.numberFormatter.numberFormat = "#.";
        valueAxis.strictMinMax = true;
        valueAxis.adjustLabelPrecision = false;        
        valueAxis.title.text = i18n.spaceplanner.usageAnalytics.units; //"Peak units booked vs checked-in";
        valueAxis.title.fontWeight = 800;
        
        // Create series - Column for unitsBooked
        const series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.name = i18n.spaceplanner.usageAnalytics.booked; //"Booked";
        series1.dataFields.valueY = "unitsBooked";
        series1.dataFields.categoryX = "day";
        series1.clustered = true;
        series1.columns.template.width = am4core.percent(50);
        series1.tooltipText = "[bold]{valueY}[/] " + i18n.spaceplanner.usageAnalytics.unitsBooked + " on a " + i18n.spaceplanner.usageAnalytics.peak + " {categoryX}\nDates: {maxDates}";
        
        // Create series - Column for unitsUtilized
        const series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.name = i18n.spaceplanner.usageAnalytics.checkedIn; //"Checked-in"
        series2.dataFields.valueY = "unitsUtilized";
        series2.dataFields.categoryX = "day";
        series2.clustered = true;
        series2.columns.template.width = am4core.percent(50)
        series2.tooltipText = "[bold]{valueY}[/] " + i18n.spaceplanner.usageAnalytics.unitsUtilized + " on a " + i18n.spaceplanner.usageAnalytics.peak + " {categoryX}\nDates: {maxUtilizedDates}";

        if (false) { //create circular labels for each bar series
            const bullet1 = series1.bullets.push(new am4charts.LabelBullet());
            bullet1.interactionsEnabled = true;
            bullet1.dy = 20;
            bullet1.label.text = '{valueY}';
            bullet1.label.fill = am4core.color('#0');
            const bullet1a = series1.bullets.push(new am4charts.CircleBullet());
            bullet1a.dy = 20;
            bullet1a.fill = am4core.color("#fff");
            bullet1a.circle.fillOpacity = 0.5;
            bullet1a.circle.radius = 20;    

            const bullet2 = series2.bullets.push(new am4charts.LabelBullet());
            bullet2.interactionsEnabled = true;
            bullet2.dy = 20;
            bullet2.label.text = '{valueY}';
            bullet2.label.fill = am4core.color('#0');
            const bullet2a = series2.bullets.push(new am4charts.CircleBullet());
            bullet2a.dy = 20;
            bullet2a.fill = am4core.color("#fff");
            bullet2a.circle.fillOpacity = 0.3;
            bullet2a.circle.radius = 20;
        }
        
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
    }

    disposeChart() {
        if (this.barChart) {
            this.barChart.data = null;
            this.barChart.dispose();
            this.barChart = null;
        }        
    }

    createChart(workWeek, chartType) {           
        if (!workWeek)
            workWeek = this.state.workWeek;
        if (!chartType)
            chartType = this.state.chartType;
        isDebug() && console.debug(`${cn}.createChart()`, workWeek, chartType);

        if (chartType==="daily") {            
            this.createChartDaily(workWeek, this.chartDataDaily);
        } else if (chartType==="hourly") {            
            this.createChartHourly(workWeek, this.chartDataHourly);
        } else if (chartType==="dailyUtilization") {
            this.createChartDailyUtilization(workWeek, this.chartDataDailyUtilization);            
        } else {
            console.warn("Invalid chart type:", this.state.chartType)
        }
    }

    getAreaId() {
        const areaItem = this.getAreaItem();
        return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
    }

    getAreaItem() {
        return this.props.areaItem;
    }

    renderGlobalDateRanges() {
        const i18nUA = Context.instance.i18n.spaceplanner.usageAnalytics;
        return this.global_start_time ? (
            <i>
                {i18nUA.analyticsRange.replace('{start}', new Date(this.global_start_time).toLocaleDateString()).replace('{end}', new Date(this.global_end_time).toLocaleDateString())}
            </i>
        ) : (
            <i></i>
        )
    }

    renderPanelText() {
        const i18n = Context.instance.i18n;
        const locale = Context.getInstance().lib.dojo.kernel.locale;    
        let format;
        if (locale === "en" || locale === "en-us") {
            format = "h:mm A"
        } else {
            format = "H:mm"
        }

        return (
            <i>
                { i18n.spaceplanner.usageAnalytics.msgDaysHoursDesc
                    .replace('{startDay}', daysOfTheWeek[this.state.workWeek[0]])
                    .replace('{endDay}', daysOfTheWeek[this.state.workWeek[this.state.workWeek.length-1]])
                    .replace('{startHour}', moment(this.state.workDayStartTime).format(format))
                    .replace('{endHour}', moment(this.state.workDayEndTime).format(format))
                }
                <br/>
                { i18n.spaceplanner.usageAnalytics.msgDateRange
                    .replace('{start}', moment(this.state.startDate).format('l'))
                    .replace('{end}', moment(this.state.endDate).format('l'))
                }
            </i>
        )
    }

    onShowUsageClicked = async() => {
        if (this.props.isDisabled) {
            console.log("Usage Analytics disabled");
            return;
        }
        if (Array.isArray(this.cachedFeatureAttrs) && this.cachedFeatureAttrs.length > 0) {
            isDebug() && console.debug("Re-creating chart only with cached data:", this.cachedFeatureAttrs.length);
            //show chart based on previously cached data
            this.createChart();
        } else {
            isDebug() && console.debug("Re-querying and re-creating chart");
            this.cachedFeatureAttrs = [];
            this.disposeChart();           
            this.setState({ working: true, isNoData: false });
            await this.queryGlobalDateRange(this.reservationsLyrUrl);
            await this.queryUnitIdsInArea();
            await this.queryReservationData(this.state.workDayStartTime, this.state.workDayEndTime, this.state.startDate, this.state.endDate, this.state.isAllDay);
            this.setState({ working: false });
        }
    }

    showChartSettingsModal = (title, mode) => {
        const props = {
            startDate: moment(this.state.startDate),
            endDate: moment(this.state.endDate),
            workWeek: this.state.workWeek.sort(),
            workDayStartTime: moment(this.state.workDayStartTime),
            workDayEndTime: moment(this.state.workDayEndTime),
            globalStartDate: moment(this.global_start_time),
            globalEndDate: moment(this.global_end_time),
            isAllDay: this.state.isAllDay,
        }
        const callback = (data) =>{ 
            if (!data)
                return;
            const wdSt = data.isAllDay ? moment().startOf('day') : data.workDayStartTime;
            const wdEt = data.isAllDay ? moment().endOf('day') : data.workDayEndTime;
            this.setState({
                startDate: data.startDate.toDate().getTime(),
                endDate: data.endDate.toDate().getTime(),
                workWeek: data.workWeek.sort(),
                workDayStartTime: wdSt.toDate().getTime(),
                workDayEndTime: wdEt.toDate().getTime(),
                isAllDay: data.isAllDay,
            });
        };
        HotelAreaUsageChartSettings.showModal(title, mode, props, callback);
    }

    render() {
        if (this.props.isDisabled)
            return (<></>);
        if (!this.props.isShowUsagePanel)
            return null;
        
        const isRtl = Context.instance.uiMode.isRtl;
        const i18n = Context.instance.i18n;
        const i18nUA = i18n.spaceplanner.usageAnalytics;
        const lblChartType = i18nUA.chartType;
        const lblChartHeader = i18nUA.peakUsageChartType.replace('{chartType}', this.state.chartLabel);

        let loader;
        if (this.state.working) {
            loader = (
            <div style={{position:"absolute",width:"100%",top:"calc(40%)",left:"calc(15%)", zoom:"150%"}}>
                <Loader sizeRatio={1} style={{marginTop: "3rem"}} />
            </div>
            );
        }
        function closeDDL(ddlRef) { ddlRef && ddlRef.current && ddlRef.current.setState && ddlRef.current.setState({open: false}) }

        return (
        <div style={{ width: "calc(100vw - 29.0625rem)", minWidth: "43.5625rem"}}>
            <TopNav style={{paddingLeft:"10px", width: "100%"}}>
                <h4>{i18n.misc.hotelUsageSummary}</h4>
                <TopNavActionsList>
                    <Button key="close" clear={true}
                        style={!isRtl ? {position:"absolute", right: "5px"} : {position:"absolute", left: "5px"}}
                        title={i18n.general.close}
                        onClick={(e) => this.props.onCloseClick(e)}
                        iconButton={true} icon={<XIcon />} />
                </TopNavActionsList>
            </TopNav>
            <Panel>                        
                <PanelText>
                    {this.renderPanelText()}
                </PanelText>
                <ButtonContainer>
                    <CalciteComboButton clear
                        ref={this.ddlDateRange}
                        label={this.state.dateRangeLabel}
                        value={this.state.dateRangeValue}
                        closeOnSelect={true}
                        onClick={(e)=>{                                        
                            if (this.state.dateRangeValue==="Custom Range")
                                this.showChartSettingsModal(i18nUA.customDateRange || "Custom Date Range", "date");
                            closeDDL(this.ddlDateRange);
                            return true;
                        }}>
                        <Menu>
                            <MenuItem onClick={e => {
                                this.setState({
                                    dateRangeValue: "Past Week",
                                    dateRangeLabel: i18nUA.pastWeek, 
                                    startDate: moment(now).subtract(1, "week").startOf("day").valueOf(),
                                    endDate: now.getTime()
                                });
                                closeDDL(this.ddlDateRange);
                            }}>{i18nUA.pastWeek || "Past Week"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({
                                    dateRangeValue: "Past Month",
                                    dateRangeLabel: i18nUA.pastMonth,
                                    startDate: moment(now).subtract(1, "month").startOf("day").valueOf(),
                                    endDate: now.getTime()
                                });
                                closeDDL(this.ddlDateRange);
                            }}>{i18nUA.pastMonth || "Past Month"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({
                                    dateRangeValue: "Year to Date",
                                    dateRangeLabel: i18nUA.yearToDate,
                                    startDate: moment(now).startOf("year").startOf("day").valueOf(),
                                    endDate: now.getTime()
                                });
                                closeDDL(this.ddlDateRange);
                            }}>{i18nUA.yearToDate || "Year to Date"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({
                                    dateRangeValue: "Past Year",
                                    dateRangeLabel: i18nUA.pastYear, 
                                    startDate: moment(now).subtract(1, "year").startOf("day").valueOf(),
                                    endDate: now.getTime()
                                });
                                closeDDL(this.ddlDateRange);
                            }}>{i18nUA.pastYear || "Past Year"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({dateRangeValue: "Custom Range", dateRangeLabel: i18nUA.customRange});
                                this.showChartSettingsModal(i18nUA.customDateRange || "Custom Date Range", "date");
                                closeDDL(this.ddlDateRange);
                            }}>{i18nUA.customRange || "Custom Range"}</MenuItem>
                        </Menu>
                    </CalciteComboButton>
                    <Button clear style={{margin:"auto"}} onClick={(e)=>this.showChartSettingsModal(i18nUA.workSchedule || 'Work Schedule', 'work')}>
                        {i18nUA.workSchedule || "Work Schedule"}
                    </Button>
                    <CalciteComboButton clear label={lblChartType} closeOnSelect={true} ref={this.ddlChartType}>
                        <Menu>
                            <MenuItem onClick={e => {
                                this.setState({ chartType: "daily", chartLabel: i18nUA.chartDaily});
                                closeDDL(this.ddlChartType);
                            }}>{i18nUA.chartDaily || "Daily Peaks"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({chartType: "hourly", chartLabel: i18nUA.chartHourly});
                                closeDDL(this.ddlChartType);
                            }}>{i18nUA.chartHourly|| "Hourly Peaks"}</MenuItem>
                            <MenuItem onClick={e => {
                                this.setState({chartType: "dailyUtilization", chartLabel: i18nUA.chartDailyUtilization});
                                closeDDL(this.ddlChartType);
                            }}>{i18nUA.chartDailyUtilization|| "Peak Daily Utilization"}</MenuItem>
                        </Menu>
                    </CalciteComboButton>
                </ButtonContainer>
            </Panel>

            <div ref={this.divBarChartWrapper} style={{ height: "calc(100% - 105px)", backgroundColor: "#eeeeee", display: "flex", flexDirection: "column" }}>
                <header style={{height: "30px", textAlign: "center"}}>{lblChartHeader}</header>
                <div style={{flex:1}}>
                    {loader}
                    <div ref={this.divBarChart} style={{ height: "100%", display: (this.state.isNoData?"none":"block") }}></div>
                    {this.state.isNoData && <div className="i-sp-analytics-no-data">
                        {i18nUA.noDataMsg}
                    </div>}
                </div>
                <footer style={{height: "30px", textAlign: "center"}}>{this.renderGlobalDateRanges()}</footer>
            </div>
        </div>
        );
    }
}

const mapReduxStateToProps = (rdxState, ownProps) => {
    const rdxStateToProps = {
        rdxActiveAreaId: Rdx.getValue(rdxState, Rdx.Keys.ACTIVE_HOTEL_AREA_ID),
        rdxActiveAssetTabKey: Rdx.getValue(rdxState, Rdx.Keys.SPACEPLANNER_ACTIVE_ASSETBAR_SECTION)
    };
    return rdxStateToProps;
}

export default connect(mapReduxStateToProps)(HotelAreaUsageAnalytics);