import React from "react";
import styled from "styled-components";

import Button from "calcite-react/Button";
import ChangeReader from "./ChangeReader";
import Checkbox from "calcite-react/Checkbox";
import Context from "../../../context/Context";
import DatePicker from "calcite-react/DatePicker";
import DatePopup from "./DatePopup";
//import ItemDate from "./ItemDate";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Filter from "../Filter";
import ListHeader from "../common/ListHeader";
import OfficePlan from "../../base/OfficePlan";
import QueryCriteria from "../../base/QueryCriteria";
import Search from "../common/Search";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as mapUtil from "../../base/mapUtil";
import * as sourceUtil from "../../base/sourceUtil";
import * as highlightUtil from "../../base/highlightUtil";
import * as transactions from "../../base/transaction/transactions";
import { Panel } from "../../styles/Common/panel";
import { ContentWrapper } from "../../styles/Work/workBar";
import { FooterContainer } from "../../styles/Assets/assetStyles";
import { FooterMessage, FooterDate } from "../../styles/moveStyles";

import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  FilterRowButton,
  ThemeColorButton
} from "../../styles/moveStyles.js";

import HotDeskIcon from 'calcite-ui-icons-react/DeskIcon';
import HotelIcon from "calcite-ui-icons-react/ServicesIcon";
import OfficeIcon from "calcite-ui-icons-react/FloorPlanIcon";
import HomeIcon from "calcite-ui-icons-react/HomeIcon";
import WorkspaceAreaIcon from "calcite-ui-icons-react/AppsIcon";

import DownIcon from "calcite-ui-icons-react/ChevronDownIcon";
import UpIcon from "calcite-ui-icons-react/ChevronUpIcon";
import Loader from 'calcite-react/Loader/Loader';
import UnitNameCache from "../../base/UnitNameCache";


/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

// border-top: 1px solid ${props => props.theme.palette.background};
//

const StyledTable = styled(Table)`
  font-size: 0.8rem;
  display: block;
  overflow-y: auto;
`;

const StyledTbody = styled(Tbody)`
`;

const StyledTh = styled(Th)`
  cursor: pointer;
`;

const StyledThContent = styled.div`
  display: flex;
`;

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export default class MoveDates extends React.Component {

  componentId;
  peopleQueryCriteriaKey = "movedates_people";

  _activePromise;
  _mounted = false;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.makePeopleQC();
    this.state = component.newState({
      changes: null,
      date: null,
      datePickerFocused: false,
      isWorking: true,
      overwriteExistingDates: false,
      peopleGlobalIds: null
    });
  }

  _activateLevel(options) {
    if (options.zInfo) {
      const levels = Context.instance.aiim.datasets.levels;
      const facilityData = levels.getFacilityData(options.zInfo.facilityId);
      const levelData = options.zInfo.levelData;
      if (facilityData && levelData) {
        Topic.publish(Topic.ActivateLevel,{
          view: options.view,
          facilityData: facilityData,
          levelData: levelData
        });
      }
    }
  }

  applyDate = () => {
    const globalIds = this.state.peopleGlobalIds;
    const momentObj = this.state.date;
    if (globalIds && globalIds.length > 0 && momentObj) {
      const date = momentObj.toDate();
      this.execApplyDate(globalIds,date);
    }
  }

  clearFiltersClicked = () => {
    let qc = QueryCriteria.get(this.peopleQueryCriteriaKey);
    qc.quickFilters["needsDate"].on = false;
    qc.searchText = "";
    qc.filterPairs = undefined;
    qc._localPairs = undefined;
    qc.where = qc.makeWhere(qc.getSource());
    Topic.publish(Topic.QueryCriteriaCleared,{
      key: qc.key,
      queryCriteria: qc
    });
    Topic.publish(Topic.QueryCriteriaChanged,{key: qc.key});
  }

  componentDidMount() {
    this._mounted = true;
    this.readChanges();
    component.own(this,[

      Topic.subscribe(Topic.PlanModified, (params) => {
        if (this._mounted) {
          this.readChanges();
        }
      }),

      Topic.subscribe(Topic.QueryCriteriaChanged, (params) => {
        const queryCriteriaKey = this.peopleQueryCriteriaKey;
        if (queryCriteriaKey && queryCriteriaKey === params.key) {
          if (this._mounted) {
            this.querySubset();
          }
        }
      }),

      Topic.subscribe(Topic.UnitNameCacheUpdated,(params) => {
        this.readChanges();
      })
      
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
    highlightUtil.removeHighlight("movedates");
  }

  execApplyDate = (globalIds,date) => {
    const changes = this.state.changes;
    if (globalIds && globalIds.length > 0 && date) {
      const guard = new TransactionGuard({force:true});
      transactions.setPeopleMoveDates(changes,globalIds,date).then(result => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
      }).catch(error => {
        guard.close();
        console.error("Error updating move dates", error);
        Topic.publishErrorUpdatingData(error.message);
      });
    }
  }

  filterByNeedsDateClicked = () => {
    let qc = QueryCriteria.get(this.peopleQueryCriteriaKey);
    qc.quickFilters["needsDate"].on = !qc.quickFilters["needsDate"].on;
    qc.where = qc.makeWhere(qc.getSource());
    Topic.publish(Topic.QueryCriteriaChanged,{key: qc.key});
  }

  getActiveQueryCriteria() {
    const key = this.peopleQueryCriteriaKey;
    return QueryCriteria.get(key);
  }

  hasFilter() {
    let qc = QueryCriteria.get(this.peopleQueryCriteriaKey);
    if(qc) {
      if(qc.searchText || qc.makeQuickFilterPart()){
        return true;
      }else if(qc.hasFilterPairs()) {
        return true;
      } 
    }
    return false;
  }

  makePeopleQC() {
    const i18n = Context.instance.i18n;
    const source = sourceUtil.getPeopleSource();
    const queryCriteriaKey = this.peopleQueryCriteriaKey;
    let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    let moveDateFieldName = FieldNames.PEOPLE_MOVE_DATE;
    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields, nameFieldName);
      if (f) nameFieldName = f.name;
      /*
      f = aiimUtil.findField(fields, moveDateFieldName);
      if (f) moveDateFieldName = f.name;
      */
    }
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      source.key,
    );
    if (nameFieldName) {
      queryCriteria.customSort = {
        field: "name",
        dir: "asc"
      };
    }
    queryCriteria.quickFilters = {
      needsDate: {
        on: false,
        where: moveDateFieldName+" IS NULL"
      }
    };
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = nameFieldName;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc",
      };
    }
    queryCriteria.layerCaption = i18n.spaceplanner.assets.people.title;
    queryCriteria.filterTooltip = i18n.spaceplanner.assets.people.filterTooltip;
    //queryCriteria.forPeopleChanges = true;
  }

  onDateChange = (date) => {
    this.setState({
      date: date
    });
  }

  onDateFocusChange = ({focused}) => {
    this.setState({
      datePickerFocused: focused
    });
  };

  onOverwriteChange = (evt) => {
    let v = !!evt.target.checked;
    this.setState({
      overwriteExistingDates: v
    });
  };

  querySubset(changes) {
    this.setState({isWorking: true});
    let queryCriteriaKey = this.peopleQueryCriteriaKey;
    let queryCriteria = QueryCriteria.get(queryCriteriaKey);
    const cr = new ChangeReader();
    const changesPeople = changes || this.state.changes;
    const promise = this._activePromise2 = cr.queryPeople(queryCriteria,changesPeople);
    const promiseA =  this._activePromise;
    promise.then(globalIds => {
      if (!this._mounted) return;
      if (promise !== this._activePromise2) return;
      this._activePromise2 = null;
      if (promiseA !== this._activePromise) return;
      changes = changes || this.state.changes;
      this.setChanges(changes, globalIds)
    }).catch(ex => {
      console.error("Error querying people",ex);
      if (!this._mounted) return;
      if (promise !== this._activePromise2) return;
      this._activePromise2 = null;
      if (promiseA !== this._activePromise) return;
      this.setState({isWorking: false});
      Topic.publishErrorAccessingData();
    });

  }

  readChanges(objectIds) {
    this.setState({isWorking: true});
    let queryCriteriaKey = this.peopleQueryCriteriaKey;
    let queryCriteria = QueryCriteria.get(queryCriteriaKey);
    const cr = new ChangeReader();
    const promise = this._activePromise = cr.readFeatures({
      queryCriteria: queryCriteria,
      objectIds: objectIds,
      noFilter: true
    });
    promise.then(changes => {
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;
      let globalIds = changes.people.globalIds;
      if(this.hasFilter()) {
        return this.querySubset(changes);
      }else{
        this.setChanges(changes, globalIds);
      }
    }).catch(ex => {
      console.error("Error reading changes",ex);
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;
      this.setState({isWorking: false});
      Topic.publishErrorAccessingData();
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const placeholderString = i18n.spaceplanner.people.listHeaderPlaceholder;
    const qc = this.getActiveQueryCriteria();
    //const layerCaption = qc.layerCaption;
    //const queryCriteriaKey = qc.key;
    //const filterTooltip = qc.filterTooltip;

    return (
      <Panel>
        <div>
          <ListHeader title={i18n.spaceplanner.moveDates.caption}></ListHeader>
          <Search
            fullWidth
            minimal
            queryCriteriaKey={qc.key}
            placeholder={placeholderString}
          />
          {this.renderFilterRow()}
        </div>
        {this.state.isWorking ? <Loader color="#007471" sizeRatio={1} style={{marginTop: '3rem'}} /> : (
        <ContentWrapper>
          <StyledTable>
            <Thead>{this.renderTableColumns()}</Thead>
            <StyledTbody>{this.renderPeople()}</StyledTbody>
          </StyledTable>
        </ContentWrapper>)}
        {/*this.renderFooter()*/}
      </Panel>
    );
  }

  renderFilterRow() {
    const i18n = Context.instance.i18n;
    const qc = this.getActiveQueryCriteria();
    let peopleCount = null, num = 0;
    let globalIds = this.state.peopleGlobalIds;
    if (globalIds) {
      num = globalIds.length;
      peopleCount = i18n.spaceplanner.moveDates.totalMoves;
      // if (num === 1) {
      //   peopleCount = i18n.spaceplanner.moveDates.peopleCountSingular;
      // } else {
      //   peopleCount = i18n.spaceplanner.moveDates.peopleCountPlural;
      // }
      peopleCount = peopleCount.replace("{num}",num);
    }
    return (
      <div style={{display: "flex", justifyContent: "space-between",
                   alignItems: "center", padding:"0.3rem", background:"white"}}>
        <span>{peopleCount}</span>
        <Filter
          queryCriteriaKey={qc.key}
          layerCaption={qc.layerCaption}
          filterTooltip={qc.filterTooltip}
          label={i18n.spaceplanner.moveDates.filters.moreFilters}
        />
      </div>
    );
  }

  renderFilterRow2() {
    const i18n = Context.instance.i18n;
    const qc = this.getActiveQueryCriteria();
    const needsDateActive = !!qc.quickFilters["needsDate"].on;
    return (
      <div style={{display: "flex", alignItems: "center", padding:"0.3rem", background:"white"}}>
        <FilterRowButton onClick={this.clearFiltersClicked}
          >{i18n.spaceplanner.moveDates.filters.all}</FilterRowButton>
        <FilterRowButton active={needsDateActive} onClick={this.filterByNeedsDateClicked}
          >{i18n.spaceplanner.moveDates.filters.needsDate}</FilterRowButton>
        <Filter
          queryCriteriaKey={qc.key}
          layerCaption={qc.layerCaption}
          filterTooltip={qc.filterTooltip}
          label={i18n.spaceplanner.moveDates.filters.moreFilters}
        />
      </div>
    );
  }

  renderFooter() {
    const i18n = Context.instance.i18n;
    const isWorking = this.state.isWorking;
    let prompt = null, num = 0;
    let globalIds = this.state.peopleGlobalIds;
    if (globalIds) {
      num = globalIds.length;
      if (num === 1) {
        prompt = i18n.spaceplanner.moveDates.applyToPeopleSingular;
      } else {
        prompt = i18n.spaceplanner.moveDates.applyToPeoplePlural;
      }
      prompt = prompt.replace("{num}",num);
    }

    let applyDisabled = (num < 1) || !this.state.date || this.state.isWorking;

    return (
      <FooterContainer>
        <FooterMessage>{prompt}</FooterMessage>
        <FooterDate>
          <DatePicker
            id={this.componentId+"_date"}
            date={this.state.date}
            onDateChange={this.onDateChange}
            focused={this.state.datePickerFocused}
            onFocusChange={this.onDateFocusChange}
            openDirection="up"
            disabled={isWorking}
          />
          <Button onClick={this.applyDate} disabled={applyDisabled}>
            {i18n.spaceplanner.moveDates.apply}
          </Button>
        </FooterDate>
        {/*
        <Checkbox onChange={this.onOverwriteChange} disabled={isWorking}>
          {i18n.spaceplanner.moveDates.overwriteExistingDates}
        </Checkbox>
        */}
      </FooterContainer>
    );
  }

  renderPeople() {
    const items = [];
    const changes = this.state.changes;
    const globalIds = this.state.peopleGlobalIds;
    if (globalIds && globalIds.length > 0) {
      globalIds.forEach(globalId => {
        items.push(this.renderPerson(changes,globalId));
      });
    }
    return items;
  }

  renderPerson(changes,globalId) {
    // const i18n = Context.instance.i18n;
    // const lib = Context.instance.lib;
    const item = changes.people.itemsByGlobalId[globalId] || {};

    const clicked = (evt) => {
      let changes = this.state.changes;
      let f = changes && changes.people.featuresByGlobalId[globalId];
      Topic.publish(Topic.CloseItemPopup, {})
      if (f && f.geometry && mapUtil.hasPointGeometry(f)) {
        const view = Context.instance.views.mapView;
        const isPerson = true;
        highlightUtil.highlightUnit("movedates",f,view,isPerson);
        mapUtil.goToGeometry(view,f.geometry,"point_level"); // "unit_level"
        const source = sourceUtil.getPeopleSource();
        const levels = Context.instance.aiim.datasets.levels;
        const zInfo = levels && levels.getZInfo(source,f);
        this._activateLevel({
          zInfo: zInfo,
          view: Context.instance.views.mapView
        });
      } else highlightUtil.removeHighlight("movedates");
    };

    const getIcon = asnType => {
      if (asnType === OfficePlan.SpaceAssignmentTypes.office) {
        return <OfficeIcon />;
      } else if (asnType === "workspace") {
        return <WorkspaceAreaIcon />
      } else if (asnType === OfficePlan.SpaceAssignmentTypes.hotel) {
        return <HotelIcon />
      } else if (asnType === OfficePlan.SpaceAssignmentTypes.hotdesk) {
        return <HotDeskIcon />
      } else if (asnType === OfficePlan.SpaceAssignmentTypes.home) {
        return <HomeIcon />
      }
      return null;
    }

    let from = item.outbound && item.outbound.asnName;
    let fromIcon = getIcon(item.outbound && item.outbound.asnType);
    let to = item.inbound && item.inbound.asnName;
    let toIcon = getIcon(item.inbound && item.inbound.asnType);

    let dt = null;
    // if (item.inbound) {
    //   let date = null;
    //   if (item.inbound.moveDate) {
    //     date = new Date(item.inbound.moveDate);
    //   }
    //   dt = (
    //     <ItemDate item={item} date={date} appendToBody/>
    //   );
    // }
    /*
    if (item.inbound) {
      let gids = [globalId];
      let date = null;
      let v = i18n.spaceplanner.moveDates.setItemDate;
      if (item.inbound.moveDate) {
        date = new Date(item.inbound.moveDate);
        v = lib.dojo.locale.format(date,{selector:"date",formatLength:"short"});
      }
      dt = (
        <ThemeColorButton
          onClick={evt => {
            if (evt) evt.stopPropagation();
            DatePopup.show(item,date,selDate => {
              if (selDate) {
                this.execApplyDate(gids,selDate)
              }
            });
          }}
        >{v}</ThemeColorButton>
      );
    }
    */

    return (
      <Tr key={globalId} onClick={clicked} style={{cursor:"default"}}>
        <Td key="name">{item.name}</Td>
        <Td key="from">{fromIcon}{from}</Td>
        <Td key="to">{toIcon}{to}</Td>
        {/*<Td key="dt">{dt}</Td>*/}
      </Tr>
    );
  }

  renderTableColumns() {
    const i18n = Context.instance.i18n;
    const globalIds = this.state.peopleGlobalIds;
    if (!globalIds || globalIds.length === 0) {
      return null;
    }

    const clicked = (field,defaultDir) => {
      this.setCustomSort(field,defaultDir);
    };

    const getSortIcon = (field) => {
      let qc = QueryCriteria.get(this.peopleQueryCriteriaKey);
      let cs = qc.customSort;
      if (cs && field && cs.field === field) {
        if (cs.dir === "asc") {
          return <DownIcon />
        } else if (cs.dir === "desc") {
          return <UpIcon />
        }
      }
      return null;
    };

    return (
      <Tr>
        <StyledTh onClick={() => clicked("name")}>
          <StyledThContent>
            {i18n.spaceplanner.moveDates.columns.name}{getSortIcon("name")}
          </StyledThContent>
        </StyledTh>
        <StyledTh onClick={() => clicked("from")}>
          <StyledThContent>
            {i18n.spaceplanner.moveDates.columns.from}{getSortIcon("from")}
          </StyledThContent>
        </StyledTh>
        <StyledTh onClick={() => clicked("to")}>
          <StyledThContent>
            {i18n.spaceplanner.moveDates.columns.to}{getSortIcon("to")}
          </StyledThContent>
        </StyledTh>
        {/*
        <StyledTh onClick={() => clicked("date","desc")}>
          <StyledThContent>
            {i18n.spaceplanner.moveDates.columns.date}{getSortIcon("date")}
          </StyledThContent>
        </StyledTh>
        */}
      </Tr>
    );
  }

  setChanges(changes, globalIds){
    const cr = new ChangeReader();
    let queryCriteriaKey = this.peopleQueryCriteriaKey;
    let queryCriteria = QueryCriteria.get(queryCriteriaKey);
    let cs = queryCriteria.customSort;
    this.setState({
      changes: changes,
      peopleGlobalIds: cr.sort(cs,changes,globalIds),
      isWorking: false
    })
  }

  setCustomSort(field,defaultDir) {
    let dir = defaultDir || "asc";
    let qc = QueryCriteria.get(this.peopleQueryCriteriaKey);
    let cs = qc.customSort;
    let cr = new ChangeReader();
    let changes = this.state.changes;
    let globalIds = this.state.peopleGlobalIds;
    if (cs && cs.field === field) {
      if (cs.dir === "asc") dir = "desc";
      else dir = "asc";
    }
    cs = qc.customSort = {
      field: field,
      dir: dir
    };
    this.setState({
      peopleGlobalIds: cr.sort(cs,changes,globalIds),
      zzz: Date.now()
    });
  }

}
