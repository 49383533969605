import React from "react";
import * as component from "../../../components/util/component";

interface Props {
  active: boolean
}

interface State {
}

export default class SectionPanel extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const { active } = this.props;
    let cls = "i-configurator-section";
    if (this.props.active) cls += " i--active"
    return (
      <div className={cls}>
        {this.props.children}
      </div>
    );
  }
  
}

