import React from "react";

import Context from "../../../context/Context";
import ItemBrowser from "../../../components/common/ItemBrowser/ItemBrowser";
import ModalController from "../../../components/common/Modal/ModalController";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  issues: "i-issues",
  issue: "i-issue",
  itemTitle: "i-item-title",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  secondaryAction: "i-button-clear-no-border i-font-brand-primary"
};

export default class AppSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.browseClicked = this.browseClicked.bind(this);
  }

  browseClicked() {
    console.log("this.props.cfg.appItem",this.props.cfg.appItem)
    const i18n = Context.instance.i18n;
    const controller = new ModalController();
    const props = {
      title: i18n.configurator.primary.app,
      itemType: "Web Mapping Application",
      rdxKey: "cfg-appsection",
      onSelect: (item) => {
        // need to validate that it's an IndoorsApp
        // need to reload package
        // need to promt for confirmation if edits have been made
        controller.close();
      }
    }
    const content = (
      <ItemBrowser itemType={props.itemType}
        rdxKey={props.rdxKey} onSelect={props.onSelect} />
    );
    controller.show(content,props.title);
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const context = Context.instance;
    const i18n = context.i18n;
    const item = this.props.cfg.appItem;
    if (!item) return null;

    let itemTitleNode = null;
    if (item) {
      const itemTitle = (item && item.title) || "";
      itemTitleNode = (
        <div className={CSS.itemTitle} key="itemTitle">{itemTitle}</div>
      );
    }

    let browseLabel = i18n.configurator.change;
    let browseButton = null;
    if (false) {
      browseButton = (
        <button type="button" className={CSS.secondaryAction}
          onClick={this.browseClicked}>{browseLabel}</button>
      );
    }

    const issues = this.renderIssues();
    const detailsLink = configUtil.renderDetailsLink(item);

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}>{i18n.spaceplanner.configurator.app}</span>
        </div>
        {itemTitleNode}
        {issues}
        <div key="actions" className={CSS.actions}>
          {browseButton}
          {detailsLink}
        </div>
      </div>
    );
  }

  renderIssues() {
    return null;
  }

}
