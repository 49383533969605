import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import Button from "calcite-react/Button";


const MapCommentWrapper = styled.div`
    background: ${props => props.theme.palette.backgroundColor};
    padding: ${props => props.theme.panelPadding};
    display: flex;
    flex-direction: column;
`;

const CommentWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.backgroundAccentColor};
  background: #ffffff;
  margin: 0.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  
  ${props =>
    props.hasLocation &&
    css`
        cursor: pointer;
    `};
`;

const ReplyWrapper = styled.div`
    margin-left: ${props => props.theme.baseline};
`;

const TopWrapper = styled.div`
    padding: ${(props) => unitCalc(props.theme.baseline, 4, "/")} ${(props) => props.theme.panelPadding};
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.palette.textColorLight};
`;

const Author = styled.h2`
    font-size: 0.9rem;
    color: #202020;
    margin: 0;
`;

const Expander = styled.div`
    display: none;
`;

const TimePassed = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 0.8125rem;
    color: #808080;
    padding: ${(props) => unitCalc(props.theme.baseline, 4, "/")} ${(props) => props.theme.panelPadding};
`;

const Message = styled.h3`
    font-size: 0.9rem;
    color: #323232;
    font-weight: normal;
    margin: 0;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${(props) => unitCalc(props.theme.baseline, 4, "/")} ${(props) => props.theme.panelPadding};
    border-top: 1px solid ${(props) => props.theme.palette.backgroundAccentColor};
`;

const Links = styled.div`
    display: flex;
    flex-direction: row;
`;


const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    fill: ${props => props.theme.palette.themeColorDark};
    font-size: ${props => props.theme.baseline};
    margin: ${(props) => unitCalc(props.theme.baseline, 2, "*")} auto;
    & > svg {
        width: ${(props) => unitCalc(props.theme.baseline, 3, "*")};
        height: ${(props) => unitCalc(props.theme.baseline, 3, "*")};
    }
`;

const ThemeColorButton = styled.button`

    color: ${props => props.theme.palette.themeColorDark};
    border: none;
    background: transparent;
    font-size: 0.9375rem;
    margin: 5px 0 3px 0;
    cursor: pointer;
`;

const ButtonGroupInput = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 97%;
`;

const CharacterCount = styled.div `

    font-size: 0.8125rem;
    color: #202020;
`;

const EmptyInput = styled.div`

    font-size: 0.8125rem;
    color: rgb(128, 128, 128);
    display: flex;
    justify-content: flex-start;
    width: 97%;
`;

const RedColorButton = styled.button`

    color: #ad3c17;
    border: none;
    background: transparent;
    font-size: 0.9375rem;
    margin: 5px 0 3px 0;
    cursor: pointer;
`;



export { MapCommentWrapper, CommentWrapper, ReplyWrapper, TopWrapper, Author, Expander, TimePassed, Message, ButtonContainer, Links, EmptyContainer, ThemeColorButton, ButtonGroupInput, CharacterCount, RedColorButton, EmptyInput};