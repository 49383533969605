import React from "react";
import { connect } from "react-redux";

import Context from "../../../context/Context";
import SaveAs from "./SaveAs";
import SectionPanel from "./SectionPanel";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";
import * as modalUtil from "../common/components/modalUtil";

import AppItem from "./AppItem";
import IdleTime from "./IdleTime";
import MergePermission from "./MergePermission";
import Palette from "./Palette";
import WebMap from "./WebMap";
import WallTypes from "./WallTypes";

import { ModalController } from "../../../common/components/Modal";

import { IConfigurables } from "../../../context/Configuration";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-pad";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-panel";
import {
  CalciteAction,
  CalciteActionPad,
  CalciteButton,
  CalcitePanel
} from "@esri/calcite-components-react";

interface Props {
}

interface State {
  actionPadExpanded: boolean,
  activeSection: string,
  saveAsOpen: boolean,
  validAppItem: boolean,
  validWebMap: boolean,
  validIdleTime: boolean,
  validMergePermission: boolean,
  validPalette: boolean,
  validFilter: boolean
}

let wasStartupShown = false;

class Configurator extends React.Component<Props, State> {

  initialConfigurables: IConfigurables

  constructor(props) {
    super(props);
    this.state = component.newState({
      actionPadExpanded: true,
      activeSection: "app",
      saveAsOpen: false,
      validAppItem: true,
      validWebMap: true,
      validIdleTime: true,
      validMergePermission: true,
      validPalette: true,
      validFilter: true
    });
    this.initialConfigurables = Context.instance.configuration.extractConfigurables();
  }

  componentDidMount() {
    if (!wasStartupShown) {
      wasStartupShown = true;
      const views = Context.instance.views;
      const appItem = Context.instance.configuration.appItem;
      // @ts-ignore
      const webmapItem = views && views.mapView && views.mapView.map && views.mapView.map.portalItem;
      if (!appItem || !webmapItem) {
        this.showGettingStarted();
      }
    }

    component.own(this,[
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);

    Topic.publish(Topic.ConfiguratorStarted,{});
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  closeClicked = async (showOpenPlan) => {
    const initial = this.initialConfigurables;
    const current = Context.instance.configuration.extractConfigurables();
    const a = JSON.stringify(initial);
    const b = JSON.stringify(current);
    if (a !== b) {
      const i18n = Context.instance.i18n;
      const ok = await modalUtil.confirm({
        title: i18n.miniapps.configurator.settings,
        message: i18n.configurator.discardPrompt,
        okLabel: i18n.general.discard
      });
      if (ok) {
        Object.assign(Context.instance.config,initial);
        Topic.publish(Topic.ConfigurationDiscarded,{});
        Topic.publish(Topic.ReloadViews,{});
        Context.instance.uiMode.toggleConfigurator(false);
        Topic.publish(Topic.ConfiguratorClosed,{});
        configUtil.removeEditFromUrl();
      }
    } else {
      Context.instance.uiMode.toggleConfigurator(false);
      Topic.publish(Topic.ConfiguratorClosed,{});
      configUtil.removeEditFromUrl();
      if(showOpenPlan) {
        Topic.publish(Topic.ShowBackstage,{
          forceRefresh: true,
          tabKey: "openPlan"
        })
      }
    }
  }

  isSectionValid(name) {
    const { validAppItem, validWebMap, validIdleTime, validMergePermission, validPalette, validFilter} = this.state;
    if (name === "app") {
      return !!(validAppItem && validWebMap && validIdleTime && validMergePermission);
    } else if (name === "palette") {
      return !!(validPalette);
    } else if (name === "walls") {
      return true;
    } else if (name === "filter") {
      return !!(validFilter);
    }
  }

  isValid() {
    return !!(this.isSectionValid("app") && this.isSectionValid("palette") && this.isSectionValid("walls")
              && this.isSectionValid("filter"));
  }

  render() {
    const i18n = Context.instance.i18n;
    const { actionPadExpanded, activeSection } = this.state;
    const hasMapView = !!Context.instance.views.mapView;

    const isActive = (v) => {
      return (activeSection === v) ? true : undefined;
    }

    const activate = (v) => {
      this.setState({activeSection: v})
    }

    return (
      <div className="i-miniapps-configurator">
        <CalcitePanel 
          className="i-bg-white"
          heading={i18n.miniapps.configurator.settings}
        >
          <div slot="header-actions-end">
            {this.renderActions()}
          </div>
          <div style={{display: "flex", flexDirection: "row", height: "100%"}}>
            <CalciteActionPad style={{display: "flex", borderRight: "1px solid var(--calcite-ui-border-3)"}}
              layout="vertical"
              expanded={actionPadExpanded ? true : undefined}
              expandDisabled={false}
              onCalciteActionPadToggle={(e) => {
                this.setState({actionPadExpanded: !actionPadExpanded})
              }}
            >
              <CalciteAction 
                className="i-warning-indicator"
                text={i18n.miniapps.configurator.sections.app} 
                icon="sliders"
                active={isActive("app")}
                indicator={!this.isSectionValid("app") ? true : undefined}
                onClick={() => activate("app")}
              />
              <CalciteAction 
                className="i-warning-indicator"
                text={i18n.miniapps.configurator.sections.palette} 
                icon="palette" 
                active={isActive("palette")}
                indicator={!this.isSectionValid("palette") ? true : undefined}
                disabled={!hasMapView ? true : undefined}
                onClick={() => activate("palette")}
              />
              <CalciteAction 
                className="i-warning-indicator"
                text={i18n.switcher.wallTypes.walls} 
                icon="button" 
                active={isActive("walls")}
                indicator={!this.isSectionValid("walls") ? true : undefined}
                disabled={!hasMapView ? true : undefined}
                onClick={() => activate("walls")}
              />
            </CalciteActionPad>
            <div className="i-configurator-sections">
              <SectionPanel active={isActive("app")}>
                <AppItem 
                  onChange={info => this.setState({validAppItem: info.isValid})} 
                />
                <WebMap 
                  onChange={info => this.setState({validWebMap: info.isValid})} 
                />
                <MergePermission 
                  onChange={info => this.setState({validMergePermission: info.isValid})} 
                />
                <IdleTime 
                  onChange={info => this.setState({validIdleTime: info.isValid})} 
                />
              </SectionPanel>
              <SectionPanel active={isActive("palette")}>
                <Palette 
                  onChange={info => this.setState({validPalette: info.isValid})} 
                />
              </SectionPanel>
              <SectionPanel active={isActive("walls")}>
                <WallTypes />
              </SectionPanel>
            </div>
          </div>
          {this.renderSaveAs()}
        </CalcitePanel>
      </div>
    )
  }

  renderActions() {
    const i18n = Context.instance.i18n;
    const user = Context.instance.user;
    const appItem = Context.instance.configuration.appItem;
    const canSave = user.canEditPortalItem(appItem);
    const canSaveAs = user.canCreateAppItem();
    const disabled = !this.isValid();
    const planner = Context.instance.spaceplanner.planner;
    const hasPlan = !!(planner && planner.hasValidPlan()); 
    const hasApp = !!appItem;
    const showClose = hasPlan || hasApp;
    return (
      <span 
        className="i-button-bar-right i-configurator-button-bar" 
        style={!showClose ? {height: "100%", marginInlineEnd: "1rem"} : undefined}
      >
        {canSaveAs &&
          <CalciteButton 
            appearance={canSave ? "outline" : undefined}
            disabled={disabled ? true : undefined}
            onClick={() => {
              this.setState({saveAsOpen: true})
            }}
          > 
            {i18n.configurator.saveAs}
          </CalciteButton>
        }
        {canSave &&
          <CalciteButton 
            disabled={disabled ? true : undefined}
            onClick={() => {
              this.save();
            }}
          > 
            {i18n.general.save}
          </CalciteButton>
        }
        {showClose && 
          <CalciteAction text="close" icon="x" 
            onClick={()=>{
              const showOpenPlan = !hasPlan && hasApp;
              this.closeClicked(showOpenPlan);
            }}
          />
        }
      </span>
    )
  }

  renderSaveAs() {
    const { saveAsOpen } = this.state;
    if (saveAsOpen) {
      return (
        <SaveAs open 
          onClose={() => {
            this.setState({saveAsOpen: false})
          }}
          onSaveClicked={(metaInfo, folderId, sharingInfo) => {
            this.saveAs(metaInfo, folderId, sharingInfo)
          }}
        />
      )
    }
    return null;
  }

  async save() {
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    const metaInfo = {};
    const appItem = Context.instance.configuration.appItem;
    const itemId = appItem && appItem.id;
    if (!itemId) return;

    try {
      await configuration.saveAppItem(metaInfo)
      this.initialConfigurables = Context.instance.configuration.extractConfigurables();
      component.refresh(this);
      Topic.publish(Topic.ShowToast,{
        message: i18n.configurator.configurationSaved,
        type: "success",
        dismissAll: true
      });

      const planner = Context.instance.spaceplanner.planner;
      if (!planner.hasValidPlan()) {
        Topic.publish(Topic.ShowBackstage,{
          forceRefresh: true,
          tabKey: "openPlan"
        });      
      }

      // Clicking Save button closes App Settings panel but doesn't remove "&edit=true" from url #7251
      const url = Context.instance.lib.esri.urlUtils.urlToObject(window.location.href);
      if (url && url.query && url.query.edit === "true") {
        delete url.query.edit;
        const href = Context.instance.lib.esri.urlUtils.addQueryParameters(url.path,url.query);
        window.history.replaceState({},"",href);
      }

    } catch(ex) {
      ModalController.showMessage(i18n.messages.errorSavingItem,
        appItem.title,"i-configurator-modal");
      console.error("Error saving item",ex);
    };
  }

  async saveAs(metaInfo,folderId,sharingInfo) {
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    try {
      await configuration.saveAppItem(metaInfo,folderId,sharingInfo,true,true)
      //this.cfg.initialConfigurables = configuration.extractConfigurables();
      //this.setState({saveAsOpen: false})
    } catch(ex) {
      ModalController.showMessage(i18n.messages.errorSavingItem,
        metaInfo.title,"i-configurator-modal");
      console.error("Error saving item",ex);
    };
  }

  showGettingStarted() {
    const i18n = Context.instance.i18n;
    const isFPE = Context.instance.isFPE();
    const props = {
      title: isFPE ? i18n.miniapps.fpeAppTitle : i18n.spaceplanner.application.title,
      message: isFPE ? i18n.miniapps.configurator.fpeStartupPrompt : i18n.spaceplanner.configurator.startupPrompt
    }
    ModalController.confirm(props).then(result => {
      if (result.ok) {
        Topic.publish(Topic.Configurator_BrowseForMap,{});
      }
    });
  }

}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);