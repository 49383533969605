import React from "react";
import Icons from "../../../components/util/Icons";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";


const CSS = {
    capacityWarning: "i-modal-capacity-warning",
    limitHotelSchedule: "i-limit-hotel-schedule"
}

export default class Warning extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        message: null
    }
  }

  componentDidMount () {
    if (typeof this.props._onMount === "function") {
      this.props._onMount(this);
    }
  }

  setMessage(message) {
      this.setState({
          message: message
      })
  }

  render() {
    const check = this.props && this.props.check;
    if (check === 'limit-hoteling-schedule') {
      const warnings = this.props.warnings;
      let warningMsgs, warningMsg;
      if (warnings && warnings.length > 1) {
        warningMsgs = warnings.map((warning)=> {
          return (<li style={{fontSize: '1rem', lineHeight: '1.5rem'}}>{warning.msg}</li>)
        })
      } else warningMsg = warnings[0].msg;

      return (
        <div className={CSS.limitHotelSchedule}>
          <div style={{justifyContent: 'center'}}><ExclamationMarkTriangleIcon color="orange" size={64}/></div>
          <h2 className='i-title'>{this.props.subTitle}</h2>
          {warningMsgs && <ul className='i-list'>{warningMsgs}</ul>}
          {warningMsg && <div style={{maxWidth: '40vw'}}>{warningMsg}</div>}
        </div>
      )
    } else {
      const style = this.props.reviewerWarning ? {marginBottom: '0.5rem', marginTop: '0 !important'} : {}
      return this.state.message ? (
        <div className={CSS.capacityWarning} style={style}>
          {Icons.exclamationTriangle16()}
          {this.state.message}
        </div>
      ) : this.props.message ? (
        <div className={CSS.capacityWarning} style={style}>
          {Icons.exclamationTriangle16()}
          {this.props.message}
        </div>
      ) : null;
    }
  }
}
