import React from "react";
import {connect} from "react-redux";

import AreaDropdown from "../More/Actions/BookWorkspace/AreaDropdown";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Rdx from "../../../redux/Rdx";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import { IMeetingRoom } from "./MeetingRoomsModel";

interface IAreaFilterProps {
  areaIds: string[],
  areasWithMeetingRooms?: __esri.Graphic[],
  items: IMeetingRoom[],
  onMount?: (filter: AreaFilter) => void,
  rdxAreaFilters: string[]
}

interface IAreaFilterState {
  areaFilters: string[],
  areaIdsByName: any
}

class AreaFilter extends React.Component<IAreaFilterProps, IAreaFilterState> {

  constructor(props: IAreaFilterProps) {
    super(props);
    this.state = component.newState({
      areaFilters: [],
      areaIdsByName: {}
    });
  }

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);
    this.initAreaFilters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.areasWithMeetingRooms !== this.props.areasWithMeetingRooms) {
      this.initAreaFilters();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  initAreaFilters() {
    const areaFilters = [], areaIdsByName = {};
    const areas = this.props.areasWithMeetingRooms;
    if (areas) {
      areas.forEach(area => {
        const id = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_ID);
        const name = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_NAME)
        const type = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREA_TYPE)
        if (!areaFilters.includes(name) && ((type === "hotel") || (type === "hotdesk") || (type === "workspace"))) {
          areaFilters.push(name);
          areaIdsByName[name] = id;
        }
      })
    }
    this.setState({areaFilters,areaIdsByName});
  }

  render() {
    const i18n = Context.instance.i18n;
    const { areaFilters } = this.state;
    return (
      <div>
        <label>
          <div className="i-meetingroom-areas">
            {i18n.more.bookWorkspace.workspaceArea}
            <AreaDropdown 
              areaFilters={areaFilters} 
              rdxKey={Rdx.Keys.AREA_FILTERS_MEETING_ROOM}
              forMeetingRooms={true}
            />
          </div>
        </label>
      </div>
    )
  }

  validate(criteriaPart) {
    const { areaIdsByName } = this.state;
    const areaIds = [];
    if (this.props.rdxAreaFilters) {
      this.props.rdxAreaFilters.forEach(v => {
        if (areaIdsByName[v]) areaIds.push(areaIdsByName[v]);
      })
    }
    criteriaPart.areaIds = areaIds;
    return true;
  }

}

const mapStateToProps = (state) => {
  return {
    rdxAreaFilters: Rdx.getValue(state, Rdx.Keys.AREA_FILTERS_MEETING_ROOM)
  }
}

export default connect(mapStateToProps)(AreaFilter);
