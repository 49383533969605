import React from "react";
import Context from "../../../context/Context";
import * as component from "../../util/component";
import { IMeetingRoom } from "./MeetingRoomsModel";

interface ICapacityFilterProps {
  capacity: number,
  items: IMeetingRoom[],
  onMount?: (filter: CapacityFilter) => void
}
interface ICapacityFilterState {
  value: number
}
export default class CapacityFilter extends React.Component<ICapacityFilterProps, ICapacityFilterState> {

  constructor(props: ICapacityFilterProps) {
    super(props);
    this.state = component.newState({
      value: this.props.capacity
    });
  }

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const items = this.props.items;
    const value = this.state.value;

    const valueSelected = evt => {
      let v = evt.target.value;
      if (v && typeof v === "string") {
        v = Number(v);
      } else {
        v = null;
      }
      this.setState({
        value: v
      })
    };

    const valueClicked = evt => {
      valueSelected(evt);
    };

    let options, values = [], idx = {};
    if (items && items.length > 0) {
      items.forEach(item => {
        let n = item.capacity;
        if (typeof n === "number") {
          if (!idx[n]) {
            idx[n] = true;
            values.push(n);
          }
        }
      });
      if (values.length > 0) {
        values.sort((a,b) => {
          return a - b;
        });
        options = [];
        options.push(
          <option key="empty" value="">
            {i18n.meetingRooms.any}
          </option>
        );
        values.forEach(v => {
          options.push(
            <option key={v} value={v}>
              {v}
            </option>
          );
        });
      }
    }

    return (
      <>
      {options &&
        <div>
          <label>
            <div>
              {i18n.meetingRooms.filterOptions.capacity}
              <span style={{float:"right"}}>({i18n.filter.numericLabels.min})</span>
            </div>
            <select defaultValue={value}
              className="i-filter-select"
              onBlur={valueSelected}
              onClick={valueClicked} onKeyUp={valueClicked}>
              {options}
            </select>
          </label>
        </div>
      }
      </>
    );
  }

  validate(criteriaPart) {
    let ok = true;
    criteriaPart.capacity = this.state.value;
    return ok;
  }

}
