import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkspaceAreaId,
  getActiveWorkspaceAreaType,
  getHotdesksEnabled,
  getHotelsEnabled,
  getMeetingRoomsEnabled,
  setActiveWorkspaceAreaId,
  setActiveWorkspaceAreaType
} from "../../redux";
import { getWorkspaceInfo } from "../../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { findField, getAttributeValue } from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import OfficePlan from "../../base/OfficePlan";
import { useHandles } from "../../miniapps/common/hooks";
import AreaProperties from "./AreaProperties";
import Skeleton from "react-loading-skeleton";
import WorkspaceArea from "./WorkspaceArea";
import WorkspaceAreaDetails from "./WorkspaceAreaDetails";
import ListHeader from "../common/ListHeader";
// Styled Components
import { ContentWrapper } from "../../styles/Work/workBar";
import { Panel } from "../../styles/Common/panel";
import { EmptyListMessage } from "../../styles/Common/emptyListMessage";
// calcite-react
import Button from "calcite-react/Button";
import PlusIcon from "calcite-ui-icons-react/PlusIcon";
import { AreaTypes } from "../../../util/interfaces";

const WorkspaceAreasPanel = () => {
  const ctx = Context.getInstance();
  const dispatch = useDispatch();
  const { i18n } = ctx;
  const rdxActiveAreaId = useSelector(getActiveWorkspaceAreaId);
  const rdxActiveAreaType = useSelector(getActiveWorkspaceAreaType);
  const rdxHotdesksEnabled = useSelector(getHotdesksEnabled);
  const rdxHotelsEnabled = useSelector(getHotelsEnabled);
  const rdxMeetingRoomsEnabled = useSelector(getMeetingRoomsEnabled);
  const [areas, setAreas] = useState<__esri.Graphic[]>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const setHandles = useHandles()[1];
  const areaTypes = useRef<string[]>([]);

  useEffect(() => {
    const info = getWorkspaceInfo();
    if (info.hasAreasTable) {
      const areas = Context.instance.aiim.datasets?.areas;
      const areasFields = areas?.table?.fields;
      const areaTypeField = findField(areasFields, FieldNames.AREA_TYPE);
      areaTypes.current = (areaTypeField?.domain as __esri.CodedValueDomain)?.codedValues.map(cv => cv.code as string);
    }
    queryAreas();

    const handles = [];
    handles.push(
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          queryAreas();
        }
      })
    );

    setHandles(handles);

    return () => {
      // cleanup
    }
  }, [rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled]);

  const addClicked = () => {
    AreaProperties.showModal({ caption: i18n.spaceplanner.workspaceAreas.addArea });
  };

  const backClicked = event => {
    // reset the currently selected active item
    dispatch(setActiveWorkspaceAreaId(null));
    dispatch(setActiveWorkspaceAreaType(null));
    setIsExpanded(false);
  };

  const getAreaType = areaItem => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_TYPE);
  };

  const getAreaId = areaItem => {
    return getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  };

  const queryAreas = () => {
    const officePlan = OfficePlan.getActive();
    if (!(rdxHotdesksEnabled || rdxHotelsEnabled || rdxMeetingRoomsEnabled)) {
      setAreas(null);
      return;
    } else {
      officePlan.queryWorkspaceAreas().then(results => {
        setAreas(results.flat());
      }).catch(ex => {
        console.warn("Error querying hotel areas:");
        console.error(ex);
        Topic.publishErrorAccessingData();
      });
    }
  };

  const renderHeader = () => {
    return rdxActiveAreaId && rdxActiveAreaType
      ? <ListHeader backClicked={backClicked}></ListHeader>
      : <ListHeader title={i18n.spaceplanner.workspaceAreas.caption} button={renderAddButton()}></ListHeader>;
  };

  const onIsExpanded = (isExpanded) => {
    setIsExpanded(isExpanded);
  }

  const renderContent = () => {
    if (rdxActiveAreaId && rdxActiveAreaType) {
      if (areas != null) {
        const foundItem = areas.find(areaItem => {
          return getAreaId(areaItem) === rdxActiveAreaId && getAreaType(areaItem) === rdxActiveAreaType;
        });
        if (foundItem) {
          return <WorkspaceAreaDetails areaItem={foundItem} onIsExpanded={onIsExpanded} />
        }
      } else {
        return null;
      }
    } else {
      return renderAreas();
    }
  };
  const renderAddButton = () => {
    const i18n = Context.instance.i18n;
    return (
      <Button
        onClick={addClicked}
        icon={<PlusIcon size={16} />}
        iconPosition="before"
      >
        {i18n.general.add}
      </Button>
    );
  };

  const renderAreas = () => {
    if (areas?.length > 0) {
      const plan = OfficePlan.getActive();
      const oidField = plan.areasTable.table.objectIdField;
      return areas.map(area => {
        return renderArea(area, oidField, plan);
      });
    } else if (areas?.length === 0) {
      return (
        <EmptyListMessage>
          {i18n.spaceplanner.workspaceAreas.noneInList}
        </EmptyListMessage>
      );
    } else {
      return <Skeleton count={10} height={50} />;
    }
  };

  const renderArea = (area: __esri.Graphic, oidField: string, plan: OfficePlan) => {
    const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
    const oid = getAttributeValue(area.attributes, oidField);
    const type = plan.getAreaType(id);
    return <WorkspaceArea key={`${type}_${id}_${oid}`} areaItem={area} />;
  };
  return (
    <Panel style={{ ...(!isExpanded ? { width: '19.595rem' } : {}) }}>
      {renderHeader()}
      <ContentWrapper
        className="i-sp-hotels-panel"
        areas
        style={{ minWidth: "19.595rem", maxHeight: "calc(100vh - 115px)" }}>
        {renderContent()}
      </ContentWrapper>
    </Panel>
  )
}
export default WorkspaceAreasPanel;