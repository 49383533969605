import React, { Component } from "react";
import { connect } from "react-redux";
import { getActiveSection, getWorkspacesEnabled } from "../../redux";
import Context from "../../../context/Context";
import WorkspaceAreasPanel from "./WorkspaceAreasPanel";
import MoveDates from "../MoveDates/MoveDates";
import ReviewersPanel from "./ReviewersPanel";
import OfficePlan from "../../base/OfficePlan";
import PeoplePanel from "./PeoplePanel";
import StatValue from "./StatValue";
import TabButton from "./TabButton";
import TabPanel from "./TabPanel";
import Topic from "../../../context/Topic";
import UnitsPanel from "./UnitsPanel";
import * as component from "../../../components/util/component";
import Skeleton from "react-loading-skeleton";
import CommentsPanel from "../Comments/CommentsPanel";

import {
  Container,
  SideNav,
  ButtonSection,
  SubTitle,
  ButtonLabel,
  CenteredWrapper
} from "../../styles/Work/workBar";

// calcite-react
import WorkspaceAreasIcon from "calcite-ui-icons-react/AppsIcon";
import MoveDateIcon from "calcite-ui-icons-react/EventIcon";
import ReviewersIcon from "calcite-ui-icons-react/GroupIcon";
import CommentsIcon from "calcite-ui-icons-react/SpeechBubblesIcon";
interface IWorkbarProps {
  rdxActiveAppSection: string,
  rdxWorkspacesEnabled: boolean
}
interface IWorkbarState {
  hasPlan: boolean,
  wasAppStarted: boolean
}
class WorkBar extends Component<IWorkbarProps, IWorkbarState> {

  constructor(props?: IWorkbarProps) {
    super(props);
    this.state = component.newState({
      hasPlan: false,
      wasAppStarted: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.AppStarted, params => {
        this.setState({ wasAppStarted: true });
      }),
      Topic.subscribe(Topic.PlanModified, params => {
        this.refreshStats();
      }),
      Topic.subscribe(Topic.PlanOpened, params => {
        this.setState({ hasPlan: true });
        this.refreshStats();
      }),
      Topic.subscribe(Topic.ReloadViews, params => {
        this.setState({ hasPlan: false });
      }),
      Topic.subscribe(Topic.ViewsReloaded, params => {
        this.setState({ wasAppStarted: true });
      }),
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  refreshStats = () => {
    const plan = OfficePlan.getActive();
    if (plan) plan.refreshStats();
  };

  supportsReview = () => {
    const plan = OfficePlan.getActive()
    return plan && plan.supportsReview()
  }

  render() {
    const appSection = this.props.rdxActiveAppSection;
    if (appSection === "plan") {
      return this.renderPlanSection();
    } else if (appSection === "review") {
      return this.renderReviewSection();
    } else if (appSection === "publish") {
      return this.renderPublishSection();
    } else {
      return null;
    }
  }

  renderPlanSection() {
    const { hasPlan } = this.state;
    const { rdxWorkspacesEnabled } = this.props;
    const i18n = Context.instance.i18n;
    const navKey = "i-spaceplanner-workbar-nav";
    const panelOpenClassName = "i--workbar-panel-open";
    return (
      <Container>
        <SideNav className="i-side-nav" title={i18n.spaceplanner.menu.sideNav.label}>
          <ButtonSection>
            <SubTitle title={i18n.spaceplanner.menu.notAssigned.label}>
              {i18n.spaceplanner.menu.notAssigned.label}
            </SubTitle>
            {hasPlan ? (
              <>
                <TabButton
                  key="people"
                  tabKey="people"
                  navKey={navKey}
                  panelOpenClassName={panelOpenClassName}
                  title={i18n.spaceplanner.assets.people.title}
                >
                  <StatValue statKey={OfficePlan.Stat_PeopleUnassigned} />
                  <ButtonLabel>
                    {i18n.spaceplanner.assets.people.title}
                  </ButtonLabel>
                </TabButton>
                <TabButton
                  key="units"
                  tabKey="units"
                  navKey={navKey}
                  panelOpenClassName={panelOpenClassName}
                  title={i18n.spaceplanner.assets.units.title}
                >
                  <StatValue statKey={OfficePlan.Stat_UnitsUnassigned} />
                  <ButtonLabel>
                    {i18n.spaceplanner.assets.units.title}
                  </ButtonLabel>
                </TabButton>
              </>
            ) : (
              <CenteredWrapper>
                <Skeleton count={2} height={64} width={64} />
              </CenteredWrapper>
            )}
          </ButtonSection>
          <ButtonSection>
            {hasPlan && rdxWorkspacesEnabled && (
              <>
                <TabButton
                  key="workspaceAreas"
                  tabKey="workspaceAreas"
                  navKey={navKey}
                  panelOpenClassName={panelOpenClassName}
                  title={i18n.spaceplanner.workspaceAreas.caption}
                >
                  <WorkspaceAreasIcon />
                  <ButtonLabel>
                    {i18n.spaceplanner.workspaceAreas.buttonCaption}
                  </ButtonLabel>
                </TabButton>
              </>
            )}
            {!hasPlan && rdxWorkspacesEnabled && (
              <CenteredWrapper>
                <Skeleton count={1} height={64} width={64} />
              </CenteredWrapper>
            )}
          </ButtonSection>
        </SideNav>
        {hasPlan && (
          <>
            <TabPanel key="people" tabKey="people" navKey={navKey}>
              <PeoplePanel
                panelKey="spaceplanner/workbar/PeoplePanel"
                sourceKey="People"
                layerCaption={i18n.spaceplanner.assets.people.unassignedTitle}
                filterTooltip={
                  i18n.spaceplanner.assets.people.unassignedFilterTooltip
                }
              />
            </TabPanel>
            <TabPanel key="units" tabKey="units" navKey={navKey}>
              <UnitsPanel
                panelKey="spaceplanner/workbar/UnitsPanel"
                sourceKey="Units"
                layerCaption={i18n.spaceplanner.assets.units.unassignedTitle}
                filterTooltip={
                  i18n.spaceplanner.assets.units.unassignedFilterTooltip
                }
              />
            </TabPanel>
            {rdxWorkspacesEnabled &&
              <TabPanel key="workspaces" tabKey="workspaceAreas" navKey={navKey}>
                <WorkspaceAreasPanel />
              </TabPanel>
            }
          </>
        )}
      </Container>
    );
  }

  renderPublishSection() {
    const {hasPlan} = this.state;
    const i18n = Context.instance.i18n;
    const navKey = "i-spaceplanner-workbar-publish-nav";
    const panelOpenClassName = "i--workbar-panel-open";
    return (
      <Container>
        <SideNav className="i-side-nav" title={i18n.spaceplanner.menu.sideNav.publishLabel}>
          <ButtonSection key="burger" className="i--burger">

          </ButtonSection>
        </SideNav>
      </Container>
    );
  }

  renderReviewSection() {
    const {hasPlan} = this.state;
    const i18n = Context.instance.i18n;
    const navKey = "i-spaceplanner-workbar-review-nav";
    const panelOpenClassName = "i--workbar-panel-open";
    //const supportsMoveDates = hasPlan && OfficePlan.getActive().supportsMoveDates();
    const supportsMoveDates = !!hasPlan;
    return (
      <Container>
        <SideNav className="i-side-nav" title={i18n.spaceplanner.menu.sideNav.reviewLabel}>
          <ButtonSection key="burger" className="i--burger">
            {supportsMoveDates && (
              <TabButton
                key="moveDates"
                tabKey="moveDates"
                navKey={navKey}
                panelOpenClassName={panelOpenClassName}
                title={i18n.spaceplanner.moveDates.caption}
              >
                {/*<HotDeskIcon />*/}
                <MoveDateIcon />
                <ButtonLabel>
                  {i18n.spaceplanner.moveDates.buttonCaption}
                </ButtonLabel>
              </TabButton>
            )}

            {hasPlan && this.supportsComment() && (
                <TabButton
                  key="commentSection"
                  tabKey="commentSection"
                  navKey={navKey}
                  panelOpenClassName={panelOpenClassName}
                  title={i18n.spaceplanner.comments.caption}
                >
                  <CommentsIcon />
                  <ButtonLabel>
                    {i18n.spaceplanner.comments.caption}
                  </ButtonLabel>
                </TabButton>
            )}

            {hasPlan && this.supportsReview() && (
              <TabButton
                key="reviewers"
                tabKey="reviewers"
                navKey={navKey}
                panelOpenClassName={panelOpenClassName}
                title={i18n.spaceplanner.reviewers.caption}
              >
                <ReviewersIcon />
                <ButtonLabel>
                  {i18n.spaceplanner.reviewers.caption}
                </ButtonLabel>
              </TabButton>
            )}

          </ButtonSection>
        </SideNav>

          {supportsMoveDates && (
            <TabPanel key="moveDates" tabKey="moveDates" navKey={navKey} fullWidth>
              <MoveDates panelKey="spaceplanner/workbar/MoveDates">
              </MoveDates>
            </TabPanel>
          )}
          {hasPlan && this.supportsComment() && (
              <TabPanel fullWidth key="commentSection" tabKey="commentSection" navKey={navKey}>
                <CommentsPanel panelKey="spaceplanner/workbar/CommentSection">
                </CommentsPanel>
              </TabPanel>
          )}

          {hasPlan && this.supportsReview() && (
            <TabPanel key="reviewers" tabKey="reviewers" navKey={navKey}>
              <ReviewersPanel panelKey="spaceplanner/workbar/ReviewersPanel" />
            </TabPanel>
          )}

      </Container>
    );
  }

  supportsComment=()=> {
    const plan = OfficePlan.getActive();
    if(plan) {
      return plan.supportsComment()
      }
    }
  }


const mapStateToProps = (state) => ({
  rdxActiveAppSection: getActiveSection(state),
  rdxWorkspacesEnabled: getWorkspacesEnabled(state)
})

export default connect(mapStateToProps)(WorkBar);
