import React from "react";

import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  main: "i-step",
  label: "i-step-label"
};

export default class Step extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.Configurator_RenderSteps,() => {
        component.refresh(this);
      }),
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const key = this.props.stepKey;
    const active = (key === this.props.cfg.activeStepKey);
    const style = active ? {} : {display: "none"};

    let label = null;
    if (this.props.stepLabel) {
      label = (
        <div key="step-label" className={CSS.label}>
          {this.props.stepLabel}
        </div>
      );
    }
    return (
      <div key={key} className={CSS.main} style={style}>
        {label}
        {this.props.children}
      </div>
    );
  }

}
