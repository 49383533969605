import React from "react";
import DatePicker from "calcite-react/DatePicker";
import Context from "../../../context/Context";
import MonthYearSelector from "./MonthYearSelector";
import * as component from "../../../components/util/component";

export default class FDPWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.componentId = component.nextId();
        this.state = {
            date : this.props.date,
            operator : this.props.operator,
            startDate : this.props.startDate,
            endDate : this.props.endDate
        }
    }

    handleInputChange =(date, startDate, endDate)=> {
        this.props.displayDate(date, startDate, endDate);
    }

    onStartDateChange = (startDate) => {
        this.setState({
            startDate : startDate
          }, ()=> {
              let startDateTime, endDateTime, dt = null;
              if(this.state.startDate) {
                  dt = this.state.startDate.toDate().getTime();
                  startDateTime = dt;
              }
              if(this.state.endDate) {
                  dt = this.state.endDate.toDate().getTime(); 
                  endDateTime = dt;
              }
              this.handleInputChange(this.state.date, this.state.startDate, this.state.endDate);
              this.props.onChange([startDateTime, endDateTime], this.state.operator);
          })
    }

    onEndDateChange = (endDate) => {
        this.setState({
            endDate : endDate
          }, ()=> {
              let startDateTime, endDateTime, dt = null;
              if(this.state.startDate) {
                  dt = this.state.startDate.toDate().getTime();
                  startDateTime = dt;
              }
              if(this.state.endDate) {
                  dt = this.state.endDate.toDate().getTime(); 
                  endDateTime = dt;
              }
              this.handleInputChange(this.state.date, this.state.startDate, this.state.endDate);
              this.props.onChange([startDateTime, endDateTime], this.state.operator);
          })
    }

    
    onDateChange = (date) => {
        this.setState({
            date: date
        }, ()=> {
            let dateTime;
            if(this.state.date) dateTime = this.state.date.toDate().getTime();
            this.handleInputChange(this.state.date, this.state.startDate, this.state.endDate);
            this.props.onChange(dateTime, this.state.operator);
        });
    }

    render() {
        const operator = this.state.operator;
        if(operator === "Exact") {
            return (
                <DatePicker
                    id={this.componentId+"_date"}
                    date={this.state.date}
                    selected={this.state.date}
                    onDateChange={this.onDateChange}
                    onMonthChange={this.onYearSelect}
                    focused={true}
                    isRTL={Context.getInstance().uiMode.isRtl}
                    hideInputIcon={true}
                    onFocusChange={()=>{}}
                    isOutsideRange={() => {}}
                    renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
                        <MonthYearSelector 
                            month={month}
                            onYearSelect={onYearSelect} 
                            onMonthSelect={onMonthSelect}/>
                    )}/>
            )
        }else {
            if(this.props.value === "start") {
                return (
                    <DatePicker
                        id={this.componentId+"_startDate"}
                        date={this.state.startDate}
                        selected={this.state.startDate}
                        onDateChange={this.onStartDateChange}
                        focused={true}
                        isRTL={Context.getInstance().uiMode.isRtl}
                        hideInputIcon={true}
                        isOutsideRange={() => {}}
                        onFocusChange={()=>{}}
                        renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
                            <MonthYearSelector 
                                month={month}
                                onYearSelect={onYearSelect} 
                                onMonthSelect={onMonthSelect}/>
                        )}/>
                )
            }else {
                return(
                    <DatePicker
                        id={this.componentId+"_endDate"}
                        date={this.state.endDate}
                        selected={this.state.endDate}
                        onDateChange={this.onEndDateChange}
                        focused={true}
                        isRTL={Context.getInstance().uiMode.isRtl}
                        hideInputIcon={true}
                        isOutsideRange={() => {}}
                        onFocusChange={()=>{}}
                        renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
                            <MonthYearSelector 
                                month={month}
                                onYearSelect={onYearSelect} 
                                onMonthSelect={onMonthSelect}/>
                        )}/>
                )
            }
        }
    }
}