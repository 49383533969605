import BaseClass from "../util/BaseClass";
import Context from "./Context";
import * as esriLoader from "esri-loader";

export interface jsapi {
  esri: { [key: string]: any },
  dojo: { [key: string]: any },
  dojox: { [key: string]: any },
  indoors: { [key: string]: any }
}
export default class EsriLib extends BaseClass {

  lib: jsapi = {
    esri: {},
    dojo: {},
    dojox: {},
    indoors: {}
  };

  load(options) {
    return esriLoader.loadModules([
      "esri/Color",
      "esri/config",
      "esri/core/asyncUtils",
      "esri/core/promiseUtils",
      "esri/core/reactiveUtils",
      "esri/core/unitUtils",
      "esri/core/urlUtils",
      "esri/geometry/Circle",
      "esri/geometry/Extent",
      "esri/geometry/geometryEngine",
      "esri/geometry/Point",
      "esri/geometry/Polygon",
      "esri/geometry/Polyline",
      "esri/geometry/SpatialReference",
      "esri/geometry/support/jsonUtils",
      "esri/geometry/support/normalizeUtils",
      "esri/geometry/support/scaleUtils",
      "esri/geometry/support/webMercatorUtils",
      "esri/Graphic",
      "esri/identity/IdentityManager",
      "esri/identity/OAuthInfo",
      "esri/intl",
      "esri/kernel",
      "esri/layers/FeatureLayer",
      "esri/layers/GraphicsLayer",
      "esri/layers/MediaLayer",
      "esri/layers/support/CodedValueDomain",
      "esri/layers/support/ExtentAndRotationGeoreference",
      "esri/layers/support/FeatureTemplate",
      "esri/layers/support/ImageElement",
      "esri/layers/support/LayerFloorInfo",
      "esri/layers/support/LOD",
      "esri/layers/support/mediaUtils",
      "esri/PopupTemplate",
      "esri/popup/ExpressionInfo",
      "esri/popup/FieldInfo",
      "esri/portal/Portal",
      "esri/portal/PortalItem",
      "esri/portal/PortalUser",
      "esri/portal/PortalItemResource",
      "esri/renderers/support/jsonUtils",
      "esri/request",
      "esri/rest/geometryService",
      "esri/rest/query",
      "esri/rest/support/DataFile",
      "esri/rest/support/FeatureSet",
      "esri/rest/support/PrintParameters",
      "esri/rest/support/PrintTemplate",
      "esri/rest/support/ProjectParameters",
      "esri/rest/support/Query",
      "esri/rest/support/StatisticDefinition",
      "esri/rest/support/AreasAndLengthsParameters",
      "esri/symbols/support/jsonUtils",
      "esri/symbols/PictureMarkerSymbol",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/symbols/TextSymbol",
      // @todo "esri/tasks/PrintTask",
      "esri/Viewpoint",
      "esri/views/2d/interactive/editingTools",
      "esri/views/2d/interactive/SnappingVisualizer2D",
      "esri/views/draw/support/GraphicMover",
      "esri/views/interactive/editGeometry/EditGeometryOperations",
      "esri/views/interactive/snapping/SnappingContext",
      "esri/views/MapView",
      "esri/views/SceneView",
      "esri/views/support/WebGLRequirements",
      "esri/WebMap",
      "esri/WebScene",
      "esri/widgets/BasemapGallery",
      "esri/widgets/Compass",
      "esri/widgets/Expand",
      "esri/widgets/Feature",
      "esri/form/FormTemplate",
      "esri/form/elements/FieldElement",
      "esri/form/elements/GroupElement",
      "esri/widgets/FeatureTemplates",
      "esri/widgets/FloorFilter",
      "esri/widgets/Home",
      "esri/widgets/LayerList",
      "esri/widgets/Legend",
      "esri/widgets/Popup",
      "esri/widgets/Search",
      "esri/widgets/ScaleBar",
      "esri/widgets/Sketch/SketchViewModel",
      "esri/widgets/Track",
      "esri/widgets/Measurement",

      "dojo/aspect",
      "dojo/_base/fx",
      "dojo/_base/kernel",
      "dojo/_base/window",
      "dojo/date/locale",
      "dojo/date/stamp",
      "dojo/dom",
      "dojo/dom-class",
      "dojo/dom-construct",
      "dojo/dom-geometry",
      "dojo/dom-style",
      "dojo/io-query",
      "dojo/number",
      "dojo/query",
      "dojo/sniff",
      "dojo/topic",
      "dojox/uuid/generateRandomUuid",

      "widgets/FloorFilter/CustomFloorFilter.js",
      "widgets/FloorFilter/CustomFloorFilterViewModel.js",
      "widgets/Directions/CustomDirections.js",
      "widgets/Directions/CustomDirectionsViewModel.js",
      "widgets/Directions/customSolve.js",
      "widgets/Directions/Steps.js",
      "widgets/FeatureForm/CustomFeatureForm.js",
      "widgets/LayerList/CustomLayerList.js",
      "widgets/Search/CustomSearch.js",
      "widgets/Search/CustomSearchViewModel.js",
      "widgets/util/measureUtil.js",
      "widgets/util/requireUtil.js"
    ], options).then(([
      Color,
      esriConfig,
      asyncUtils,
      promiseUtils,
      reactiveUtils,
      unitUtils,
      urlUtils,
      Circle,
      Extent,
      geometryEngine,
      Point,
      Polygon,
      Polyline,
      SpatialReference,
      geometryJsonUtils,
      normalizeUtils,
      scaleUtils,
      webMercatorUtils,
      Graphic,
      esriId,
      OAuthInfo,
      intl,
      esriNS ,
      FeatureLayer,
      GraphicsLayer,
      MediaLayer,
      CodedValueDomain,
      ExtentAndRotationGeoreference,
      FeatureTemplate,
      ImageElement,
      LayerFloorInfo,
      LOD,
      mediaUtils,
      PopupTemplate,
      ExpressionInfo,
      FieldInfo,
      Portal,
      PortalItem,
      PortalUser,
      PortalItemResource,
      rendererJsonUtils,
      esriRequest,
      geometryService,
      restQuery,
      DataFile,
      FeatureSet,
      PrintParameters,
      PrintTemplate,
      ProjectParameters,
      Query,
      StatisticDefinition,
      AreasAndLengthsParameters,
      symbolJsonUtils,
      PictureMarkerSymbol,
      SimpleFillSymbol,
      SimpleLineSymbol,
      SimpleMarkerSymbol,
      TextSymbol,
      // @todo PrintTask,
      Viewpoint,
      editingTools,
      SnappingVisualizer2D,
      GraphicMover,
      EditGeometryOperations,
      SnappingContext,
      MapView,
      SceneView,
      WebGLRequirements,
      WebMap,
      WebScene,
      BasemapGallery,
      Compass,
      Expand,
      Feature,
      FormTemplate,
      FieldElement,
      GroupElement,
      FeatureTemplates,
      FloorFilter,
      Home,
      LayerList,
      Legend,
      Popup,
      Search,
      ScaleBar,
      SketchViewModel,
      Track,
      Measurement,

      djAspect,
      djFx,
      djKernel,
      djWindow,
      djLocale,
      djStamp,
      dom,
      domClass,
      domConstruct,
      domGeom,
      domStyle,
      djIoQuery,
      djNumber,
      djQuery,
      djSniff,
      djTopic,
      djxGenerateRandomUuid,
      
      CustomFloorFilter,
      CustomFloorFilterViewModel,
      CustomDirections,
      CustomDirectionsViewModel,
      customSolve,
      Steps,
      CustomFeatureForm,
      CustomLayerList,
      CustomSearch,
      CustomSearchViewModel,
      measureUtil,
      requireUtil
    ]) => {
      let lib = this.lib;

      lib.esri.Color = Color;
      lib.esri.esriConfig = esriConfig;
      lib.esri.asyncUtils = asyncUtils;
      lib.esri.promiseUtils = promiseUtils;
      lib.esri.reactiveUtils = reactiveUtils;
      lib.esri.unitUtils = unitUtils;
      lib.esri.urlUtils = urlUtils;
      lib.esri.Circle = Circle;
      lib.esri.Extent = Extent;
      lib.esri.geometryEngine= geometryEngine;
      lib.esri.Point = Point;
      lib.esri.Polygon = Polygon;
      lib.esri.Polyline = Polyline;
      lib.esri.SpatialReference = SpatialReference;
      lib.esri.geometryJsonUtils = geometryJsonUtils;
      lib.esri.normalizeUtils = normalizeUtils;
      lib.esri.scaleUtils = scaleUtils;
      lib.esri.webMercatorUtils = webMercatorUtils;
      lib.esri.Graphic = Graphic;
      lib.esri.esriId = esriId;
      lib.esri.OAuthInfo = OAuthInfo;
      lib.esri.intl = intl;
      lib.esri.esriNS = esriNS;
      lib.esri.FeatureLayer = FeatureLayer;
      lib.esri.GraphicsLayer = GraphicsLayer;     
      lib.esri.MediaLayer = MediaLayer;
      lib.esri.CodedValueDomain = CodedValueDomain;
      lib.esri.ExtentAndRotationGeoreference = ExtentAndRotationGeoreference;
      lib.esri.FeatureTemplate = FeatureTemplate;
      lib.esri.ImageElement = ImageElement;
      lib.esri.LayerFloorInfo = LayerFloorInfo;
      lib.esri.LOD = LOD;
      lib.esri.mediaUtils = mediaUtils;
      lib.esri.PopupTemplate = PopupTemplate;
      lib.esri.ExpressionInfo = ExpressionInfo;
      lib.esri.FieldInfo = FieldInfo;
      lib.esri.Portal = Portal;
      lib.esri.PortalItem = PortalItem;
      lib.esri.PortalItemResource = PortalItemResource;
      lib.esri.PortalUser = PortalUser;
      lib.esri.rendererJsonUtils = rendererJsonUtils;
      lib.esri.esriRequest = esriRequest;
      lib.esri.geometryService = geometryService;
      lib.esri.restQuery = restQuery;
      lib.esri.DataFile = DataFile;
      lib.esri.FeatureSet = FeatureSet;
      lib.esri.PrintParameters = PrintParameters;
      lib.esri.PrintTemplate = PrintTemplate;
      lib.esri.ProjectParameters = ProjectParameters;
      lib.esri.Query = Query;
      lib.esri.StatisticDefinition = StatisticDefinition;
      lib.esri.AreasAndLengthsParameters = AreasAndLengthsParameters;
      lib.esri.symbolJsonUtils = symbolJsonUtils;
      lib.esri.PictureMarkerSymbol = PictureMarkerSymbol;
      lib.esri.SimpleFillSymbol = SimpleFillSymbol;
      lib.esri.SimpleLineSymbol = SimpleLineSymbol;
      lib.esri.SimpleMarkerSymbol = SimpleMarkerSymbol;
      lib.esri.TextSymbol = TextSymbol;
      // @todo lib.esri.PrintTask = PrintTask;
      lib.esri.Viewpoint = Viewpoint;
      lib.esri.editingTools = editingTools;
      lib.esri.SnappingVisualizer2D = SnappingVisualizer2D.SnappingVisualizer2D;
      lib.esri.GraphicMover = GraphicMover;
      lib.esri.EditGeometryOperations = EditGeometryOperations.EditGeometryOperations;
      lib.esri.SnappingContext = SnappingContext.SnappingContext;
      lib.esri.MapView = MapView;
      lib.esri.SceneView = SceneView;
      lib.esri.WebGLRequirements = WebGLRequirements;
      lib.esri.WebMap = WebMap;
      lib.esri.WebScene = WebScene;
      lib.esri.BasemapGallery = BasemapGallery;
      lib.esri.Compass = Compass;
      lib.esri.Expand = Expand;
      lib.esri.Feature = Feature;
      lib.esri.FormTemplate = FormTemplate;
      lib.esri.FieldElement = FieldElement;
      lib.esri.GroupElement = GroupElement;
      lib.esri.FeatureTemplates = FeatureTemplates;
      lib.esri.FloorFilter = FloorFilter;
      lib.esri.Home = Home;
      lib.esri.LayerList = LayerList;
      lib.esri.Legend = Legend;
      lib.esri.Popup = Popup;
      lib.esri.Search = Search;
      lib.esri.ScaleBar = ScaleBar;
      lib.esri.SketchViewModel = SketchViewModel;
      lib.esri.Track = Track;
      lib.esri.Measurement = Measurement;

      lib.esri.QueryTask = CustomQueryTask;

      lib.dojo.aspect = djAspect;
      lib.dojo.fx = djFx;
      lib.dojo.kernel = djKernel;
      lib.dojo.window = djWindow;
      lib.dojo.locale = djLocale;
      lib.dojo.stamp = djStamp;
      lib.dojo.dom = dom;
      lib.dojo.domClass = domClass;
      lib.dojo.domConstruct = domConstruct;
      lib.dojo.domGeom = domGeom;
      lib.dojo.domStyle = domStyle;
      lib.dojo.ioQuery = djIoQuery;
      lib.dojo.number = djNumber;
      lib.dojo.query = djQuery;
      lib.dojo.sniff = djSniff;
      lib.dojo.topic = djTopic;
      lib.dojox.generateRandomUuid = djxGenerateRandomUuid;

      lib.indoors.CustomFloorFilter = CustomFloorFilter.default;
      lib.indoors.CustomFloorFilterViewModel = CustomFloorFilterViewModel.default;
      lib.indoors.CustomDirections = CustomDirections.default;
      lib.indoors.CustomDirectionsViewModel = CustomDirectionsViewModel.default;
      lib.indoors.customSolve = customSolve;
      lib.indoors.Steps = Steps.default;
      lib.indoors.CustomFeatureForm = CustomFeatureForm.default;
      lib.indoors.CustomLayerList  = CustomLayerList.default;
      lib.indoors.CustomSearch = CustomSearch.default;
      lib.indoors.CustomSearchViewModel = CustomSearchViewModel.default;
      lib.indoors.measureUtil = measureUtil;
      lib.indoors.requireUtil = requireUtil;
      
      console.log("esriNS.version",esriNS.version);

    });
  }

}

export class CustomQueryTask {

  url: string;

  constructor(props: { url: string }) {
    this.url = props && props.url;
  }

  execute(query: __esri.Query, requestOptions?: __esri.RequestOptions) {
    query = this._fixQuery(query);
    const restQuery: __esri.query = Context.instance.lib.esri.restQuery;
    return restQuery.executeQueryJSON(this.url,query,requestOptions);
  }

  executeForCount(query: __esri.Query, requestOptions?: __esri.RequestOptions) {
    query = this._fixQuery(query);
    const restQuery: __esri.query = Context.instance.lib.esri.restQuery;
    return restQuery.executeForCount(this.url,query,requestOptions);
  }

  executeForIds(query: __esri.Query, requestOptions?: __esri.RequestOptions) {
    query = this._fixQuery(query);
    const restQuery: __esri.query = Context.instance.lib.esri.restQuery;
    return restQuery.executeForIds(this.url,query,requestOptions);
  }

  _fixQuery(query: __esri.Query) {
    const setSR = !!(query && !query.outSpatialReference && !Context.instance.appMode.isSP_or_FPE());
    if (setSR) {
      const views = Context.instance.views;
      const view = views && views.mapView;
      if (view && view.spatialReference) {
        query.outSpatialReference = view.spatialReference;
      }
    }
    return query;
  }

}
