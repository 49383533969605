import React from "react";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

import ReactToggle from "react-toggle";
import "react-toggle/style.css";
import { IDuration } from "../Events/dateUtil";
type DurationType = IDuration["duration"];
interface IDurationProps {
  disabled?: boolean,
  duration: DurationType,
  formKey: string,
  isOngoingBooking?: boolean,
  onChange?: (newValue: DurationType, oldValue: DurationType) => void,
  onMount: (duration: Duration) => void
}
interface IDurationState {
  value: IDuration["duration"]
}
export default class Duration extends React.Component<IDurationProps, IDurationState> {

  constructor(props: IDurationProps) {
    super(props);
    this.state = component.newState({
      value: this.props.duration
    });
  }

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);

    component.own(this, [
      Topic.subscribe(Topic.WhenChanged, params => {
        if (params && params.formKey === this.props.formKey) {

        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  publishChange(value, oldValue) {
    Topic.publish(Topic.WhenChanged,{
      formKey: this.props.formKey,
      part: "duration",
      value: value
    })
    if (this.props.onChange) {
      this.props.onChange(value, oldValue);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const value = this.state.value;
    const values = [
      "thirtyMin",
      "oneHour",
      "oneAndAHalfHours",
      "twoHours",
      "twoAndAHalfHours",
      "threeHours",
      "allDay"
    ];

    const allDayChange = evt => {
      let v = null;
      if (evt.target.checked) v = "allDay";
      if (v !== value) {
        this.setState({
          value: v
        }, () => this.publishChange(v, value));
      }
    };

    const valueSelected = evt => {
      let v = evt.target.value;
      if (!v) v = null;
      if (v !== value) {
        this.setState({
          value: v
        }, () => this.publishChange(v, value));
      }
    };

    const valueClicked = evt => {
      valueSelected(evt);
    };

    const options = [];
    options.push(
      <option key="empty" value="">
        {i18n.meetingRooms.other}
      </option>
    );
    values.forEach(v => {
      options.push(
        <option key={v} value={v}>
          {i18n.meetingRooms.durations[v]}
        </option>
      );
    });

    let disabled = false;
    let allDay = (value === "allDay");
    if(this.props.isOngoingBooking) {
      if (this.props && this.props.disabled) {
        disabled = this.props.disabled;
        allDay = false;
      }
    }

    return (
      <div>
        <label>
          <div>{i18n.meetingRooms.durations.caption}</div>
          <div className="i-flex-between i-nowrap">
            <select value={value || ""}
              onChange={valueSelected}
              onClick={valueClicked} onKeyUp={valueClicked}>
              {options}
            </select>
            <span className="i-toggle">
              <span className="i-separated-left-right">
                {i18n.meetingRooms.durations.allDay}
              </span>
              <ReactToggle
                disabled={disabled}
                checked={allDay}
                onChange={allDayChange}
                icons={false} />
            </span>
          </div>
        </label>
      </div>
    );
  }

  setWhen(duration) {
    this.setState({
      value: duration
    })
  }

  validate(criteriaPart: IDuration) {
    let ok = true;
    criteriaPart.duration = this.state.value;
    return ok;
  }

}
