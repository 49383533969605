import Context from "../../context/Context";
import OfficePlan from "./OfficePlan";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as portalUtil from "../../util/portalUtil";
import * as sourceUtil from "./sourceUtil";

import * as comments from "./templates/comments";
import * as mergeInfo from "./templates/mergeInfo";
import * as reviews from "./templates/reviews";

export function addSupportingTables(serviceUrl,hosted,unitsLayerInfo) {
  const layers = [], tables = [];
  comments.append(layers,tables,{unitsLayerInfo: unitsLayerInfo});
  reviews.append(layers,tables);
  const addTo = {
    layers: layers,
    tables: tables
  };
  if (hosted && unitsLayerInfo) {
    const a = layers.concat(tables);
    const efi = unitsLayerInfo.editFieldsInfo;
    a.forEach(o => {
      o.capabilities = unitsLayerInfo.capabilities;
      o.editFieldsInfo = efi;
      o.fields.push(aiimUtil.findField(unitsLayerInfo.fields,efi.creationDateField));
      o.fields.push(aiimUtil.findField(unitsLayerInfo.fields,efi.creatorField));
      o.fields.push(aiimUtil.findField(unitsLayerInfo.fields,efi.editDateField));
      o.fields.push(aiimUtil.findField(unitsLayerInfo.fields,efi.editorField));
    });
  }
  const token = getToken();
  return addToDefinition(serviceUrl,addTo,token);
}

export function addToDefinition(serviceUrl,json,token) {
  if (!json) return Promise.resolve();
  let url = getServiceAdminUrl(serviceUrl)+"/addToDefinition ";
  let params = {
    f: "json",
    addToDefinition : JSON.stringify(json)
  };
  if (token) params.token = token;
  const options = {query: params, method: "post", responseType: "json"};
  const esriRequest = Context.instance.lib.esri.esriRequest;
  //console.log("addToDefinition",url,options);
  return esriRequest(url,options);
}

export function createVersionSupportService(task,name,versionGuid,sharingInfo,sourceInfo) {
  const promise = new Promise((resolve,reject) => {
    let guid = versionGuid.replace("{","").replace("}","");
    guid = guid.replace(/-/g,"");
    const title = name;
    const serviceName = "IndoorPlanFS_"+guid;
    const snippet = "Version: "+versionGuid+", Service: "+serviceName;

    const userContentUrl = portalUtil.getUserContentUrl();
    const appItem = Context.instance.configuration.appItem;
    const tags = [OfficePlan.VersionedTagFS];
    const tkService = OfficePlan.VersionedTypeKeywordFS;
    const tkParent = OfficePlan.VersionedTypeKeywordParentPfx + appItem.id;
    const tkVersion = OfficePlan.VersionedTypeKeywordVersionPfx + guid;
    const itemProps = {
      title: title,
      snippet: snippet,
      tags: tags.join(",")
    };

    const timeout = (60000 * 10);
    //const capabilities = "Query,Editing,Create,Update,Delete,Extract,ChangeTracking";
    const capabilities = "Query,Editing,Create,Update,Delete,Extract";
    const serviceInfo = {
      "maxRecordCount": 2000,
      "units": "esriMeters",
      "spatialReference": {
        "wkid" : 102100,
        "latestWkid" : 3857
      },
      "initialExtent" : {
        "xmin" : -20037507.0671618,
        "ymin" : -30240971.9583862,
        "xmax" : 20037507.0671618,
        "ymax" : 18398924.324645,
        "spatialReference" : {
          "wkid" : 102100,
          "latestWkid" : 3857
        }
      },
      "editorTrackingInfo": {
        "enableEditorTracking": true,
        "enableOwnershipAccessControl": false,
        "allowOthersToUpdate": true,
        "allowOthersToDelete": true,
        "allowOthersToQuery": true,
        "allowAnonymousToUpdate": false,
        "allowAnonymousToDelete": false
      }
    };

    const addOpts = {
      unitsLayerInfo : sourceInfo && sourceInfo.unitsLayerInfo,
      serviceInfo : sourceInfo && sourceInfo.serviceInfo
    };
    if(addOpts && addOpts.serviceInfo && addOpts.serviceInfo.initialExtent && addOpts.serviceInfo.spatialReference) {
      const ext = JSON.parse(JSON.stringify(addOpts.serviceInfo.initialExtent));
      const sr = JSON.parse(JSON.stringify(addOpts.serviceInfo.spatialReference)); 
      serviceInfo.initialExtent = ext;
      serviceInfo.spatialReference = sr;
    }

    const createParameters = {
      name: serviceName,
      capabilities: capabilities,
      allowGeometryUpdates: true,
      hasStaticData: false,
      maxRecordCount: serviceInfo.maxRecordCount,
      units: serviceInfo.units,
      spatialReference: serviceInfo.spatialReference,
      initialExtent: serviceInfo.initialExtent,
      editorTrackingInfo: serviceInfo.editorTrackingInfo,
      //supportedQueryFormats: serviceInfo.supportedQueryFormats,
      //serviceDescription: serviceInfo.serviceDescription,
      //description: serviceInfo.description,
      //copyrightText: serviceInfo.copyrightText,
      //xssPreventionInfo: serviceInfo.xssPreventionInfo
    };

    const params = {
      f: "json",
      outputType: "featureService",
      title: itemProps.title,
      tags: itemProps.tags,
      createParameters: JSON.stringify(createParameters)
    };
    if (itemProps.summary) params.snippet = itemProps.summary;

    const options = {
      query: params,
      method: "post",
      responseType: "json",
      timeout: timeout
    };

    const url = userContentUrl+"/createService";
    const lib = Context.instance.lib;
    const user = Context.instance.user;
    //console.log("createParameters",createParameters)
    //console.log("params",params)
    //console.log("url",url)

    let itemId, folderId, fromFolderId = null;
    let serviceUrl;
    let owner = user.getUsername();

    user.ensureIndoorsFolder().then(fid => {
      folderId = fid;
    }).then(result => {
      return lib.esri.esriRequest(url,options).then(result => {
        //console.log("createServiceResult",result);
        itemId = result.data.itemId;
        serviceUrl = result.data.serviceurl || result.data.serviceUrl;
        task.supportServiceUrl = serviceUrl
        task.supportServiceItemId = itemId;
      });
    }).then(result => {
      return addSupportingTables(serviceUrl, null, addOpts.unitsLayerInfo)
    }).then(result => {
      return portalUtil.readItem(itemId).then(result => {
        //console.log("readItem",result);
        const itm = result.data;
        if (itm.ownerFolder) fromFolderId = itm.ownerFolder;
        portalUtil.ensureTypeKeywords(itm,[tkService,tkParent,tkVersion]);
        if (Context.instance.isFPE()) {
          portalUtil.ensureTypeKeywords(itm,OfficePlan.TypeKeywordFloorPlanEditorPlan);
        }
        itemProps.typeKeywords = itm.typeKeywords.join(",");
      });
    }).then(result => {
      return portalUtil.moveItem(itemId,fromFolderId,folderId,owner);
    }).then(result => {
      return portalUtil.saveItem(itemProps,itemId,folderId,owner);
    }).then(result => {
      // let target = {};
      // return readServiceJson(serviceUrl,target).then(() => {
      //   console.log("readServiceJson.target",target)
      // });
    }).then(result => {
      if (sharingInfo) {
        if (sharingInfo.everyone || sharingInfo.org || sharingInfo.groups) {
          return portalUtil.shareItem(itemId,owner,sharingInfo)
        }
      }
    }).then(result => {
      resolve();
    }).catch(ex => {
      console.error(ex);
      reject(ex);
    });

  });
  return promise;
}

export function getServiceAdminUrl(serviceUrl) {
  let url = serviceUrl.replace("/rest/services/","/rest/admin/services/");
  // AppContext.addCors(url); // TODO???
  return url;
}

export function getToken() {
  if (Context.instance._platformSelfTKN) return Context.instance._platformSelfTKN;
  const esriId = Context.instance.lib.esri.esriId;
  const credential = esriId.findCredential(Context.instance.getPortalUrl());
  return credential && credential.token;
}

export function readServiceJson(url,target) {
  const promise = new Promise((resolve,reject) => {
    const promises = [];

    aiimUtil.readServiceJson(url).then(result => {
      //console.log("_readServiceJson",result)
      const serviceInfo = result.data;
      target.serviceInfo = serviceInfo;
      serviceInfo.layers.forEach(layer => {
        const id = layer.id;
        const p = aiimUtil.readServiceJson(url+"/"+id);
        promises.push(p);
        p.then(layerResult => {
          const layerInfo = layerResult.data;
          if (sourceUtil.hasPeopleFields(layerInfo,true)) {
            target.peopleLayerInfo = layerInfo;
          } else if (sourceUtil.hasUnitsFields(layerInfo,true)) {
            target.unitsLayerInfo = layerInfo;
          } else if (sourceUtil.hasDetailsFields(layerInfo,true)) {
            if (Context.instance.isFPE()) target.detailsLayerInfo = layerInfo;
          } else if (sourceUtil.hasCommentsFields(layerInfo,true)) {
            target.commentsLayerInfo = layerInfo;
          } else if (sourceUtil.hasPaletteFields(layerInfo,true)) {
            target.paletteLayerInfo = layerInfo;
          }
        });
      });
      serviceInfo.tables.forEach(table => {
        const id = table.id;
        const p = aiimUtil.readServiceJson(url+"/"+id);
        promises.push(p);
        p.then(tableResult => {
          const tableInfo = tableResult.data;
          if (sourceUtil.hasAreasFields(tableInfo)) {
            target.areasTableInfo = tableInfo;
          } else if (sourceUtil.hasAreaRolesFields(tableInfo)) {
            target.areaRolesTableInfo = tableInfo;
          } else if (tableInfo.name.toLowerCase() === "mergeinfo") {
            target.mergeInfoTableInfo = tableInfo;
          } else if (tableInfo.name.toLowerCase() === "reviewers") {
            target.reviewersTableInfo = tableInfo;
          }
        });
      });

      if (promises.length > 0) {
        return Promise.all(promises);
      }
    }).then(() => {
      resolve();
    }).catch(ex => {
      reject(ex);
    });

  });
  return promise
}

export function validateApplyEditsResponse(response) {
  const results = response.data;
  let error = null;

  const makeError = (err) => {
    let err2 = new Error("Error applying edits");
    if (err.code) err2.code = err.code;
    if (err.message) err2.message = err.message;
    if (err.description) err2.message = err.description;
    return err2;
  };

  const check = (list) => {
    if (list) {
      list.forEach(featureResult => {
        if (featureResult.error) {
          if (!error) {
            error = makeError(featureResult.error);
          }
          if (featureResult.error.code === 1003) {
            // "Operation rolled back."
          } else {
            // throw the first error that isn't a roll back
            throw makeError(featureResult.error);
          }
        }
      });
    }
  };

  results.forEach(layerResult => {
    check(layerResult.addResults);
    check(layerResult.updateResults);
    check(layerResult.deleteResults);
  });
  if (error) throw error;
}
