import React from 'react';
import ColorPicker from "../../common/ColorPicker/ColorPicker";
import ModalController from '../../common/Modal/ModalController';
import Topic from "../../../context/Topic";
import Context from "../../../context/Context";
import * as component from "../../util/component";

export default class ThemeColor extends React.Component {

  constructor(props){
    super(props);
    this.state = component.newState({})
  }

  static showModal=()=> {
    let controller = new ModalController();
    controller.show(
      <ThemeColor/>
    );
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

   showColorPicker=()=> {
     let color = this.getBackground();
     let controller = new ModalController();
     controller.show(
       <ColorPicker color= {color}
                    handleChangeComplete= {this.handleChangeComplete}
       />
     ,Context.instance.i18n.configurator.theme.colorPickerCaption
     ,"i-configurator-modal i-colorpicker-modal");
   }

   getBackground=()=>{
    return (this.props.cfg.theme[this.props.themeProperty]);
   }

   getBackgroundVar =()=>{
    return ("var(--i-"+this.props.themeProperty+")");
   }

  handleChangeComplete = (color) => {

    // Color displays data in all color modals (hsl, hsv, rgba). Hex is the most direct way of getting color without any conversion
    this.setState({ color: color.hex });
    this.props.cfg.theme[this.props.themeProperty]=color.hex;
    Topic.publish(Topic.ApplyTheme,{
      theme:{
        [this.props.themeProperty]:color.hex
      }
    })
  };

  render(){
      let colorVariable={
          background:this.getBackgroundVar(),
          border:"#959595 1px solid"
      }

      let header = null;
      if (this.props.heading) {
        header = (
          <section key="hdr" className="i-form-group-header">
            <span className="i-form-group-name">{this.props.heading}</span>
          </section>
        );
      }

      return(
      <div>
        {header}
        <section key="body" className="i-theme-row">
          <div key="name" className="i--a">
            <span className="i-form-group-name">{this.props.title}</span>
            <p>{this.props.example}</p>
          </div>
          <div key="button" className="i--b">
            <button className="i-color" style={colorVariable}
                    onClick={this.showColorPicker}>
            </button>
          </div>
        </section>
      </div>
      )
    }
   }
