import React from "react";

import Context from "../../../context/Context";
import Toggle from "../../common/Toggle/Toggle";
import * as component from "../../util/component";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label"
};

export default class IndoorPositioning extends React.Component {

  constructor(props) {
    super(props);
    const ips = this.props.cfg.getConfigurable("indoorPositioning");
    this.state = component.newState({
      iOS: !!ips.iOS,
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeIOS = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        iOS: checked
      }
    });
    cfg.getConfigurable("indoorPositioning").iOS = checked;
  }

  render() {
    const i18n = Context.instance.i18n;
    const iOS = this.state.iOS;

    return (
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.indoorPositioning.caption}</span>
        </div>

        <div key="showIOS" className={CSS.formGroup}>
          <label id={"cfg-ips-ios-label"} htmlFor={"cfg-ips-ios"}
            className={"i-flex-between"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.indoorPositioning.iOS}</div>
            <Toggle id={"cfg-ips-ios"} checked={iOS}
              onChange={this.onChangeIOS}/>
          </label>
        </div>

      </div>
    );

  }


}
