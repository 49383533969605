import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import ClosestFacilityService from "../network/ClosestFacilityService";
import Datasets from "../datasets/Datasets";
import FacilityFootprints from "./FacilityFootprints";
import FacilityMode from "./FacilityMode";
import FieldNames from "../datasets/FieldNames";
import RouteService from "../network/RouteService";
import RouteBarriers from "../network/RouteBarriers";
import Topic from "../../context/Topic";
import UnitNameCache from "../../spaceplanner/base/UnitNameCache";
import * as aiimUtil from "../util/aiimUtil";
import * as sourceUtil from "../../spaceplanner/base/sourceUtil";
import { IReserveForInfo } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";
import { SpaceAssignmentTypes } from "../../spaceplanner/base/OfficePlan";
import { getMeetingBookingSystemType } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import * as checkInOutUtil from "../../components/main/More/Actions/BookWorkspace/CheckInOutUtil";

export default class Aiim extends BaseClass {

  appUserAssignments;
  closestFacilityService: ClosestFacilityService;
  datasets: Datasets;
  facilityFootprints: FacilityFootprints;
  facilityMode: FacilityMode;
  managerInfo;
  reserveForInfo: IReserveForInfo;
  routeService: RouteService;
  routeBarriers: RouteBarriers;


  private checkFloorFilter() {
    // Selection does not honor floor filter for a map with a preset floor filter #7972
    try {
      const ffWidget = Context.instance.views?.floorFilter?.activeWidget;
      if (ffWidget && Context.instance.appMode.isSP()) {
        const levelsDataset = this.datasets?.levels;
        const facilityId = ffWidget.facility;
        const levelId = ffWidget.level;
        if (levelsDataset && facilityId && levelId && (levelId.indexOf("all--") == -1)) {
          const facilityData = levelsDataset.getFacilityData(facilityId);
          const levelData = levelsDataset.getLevelData(levelId);
          if (levelData && facilityData) {
            Topic.publish(Topic.ActivateLevel,{
              facilityData: facilityData,
              levelData: levelData,
              view: ffWidget.view,
              origin: Context.instance.views.floorFilter // Prevents endless loop of publish/subscribe
            });
          }
        }
      }
    } catch(ex) {
      console.error(ex);
    }
  }
  
  fixHitTestResult(result) {
    /*
    // LayerView.hitTest was not floor aware at JSAPI 4.29 but has been fixed
    if (Array.isArray(result) && result.length > 0) {
      const view = Context.instance.views.activeView;
      const floors = view?.floors;
      if (floors && floors.length > 0) {
        const result2 = [];
        result.forEach(h => {
          if (h && h.type === "graphic") {
            const f = h?.layer?.floorInfo?.floorField;
            if (f && h?.graphic?.attributes) {
              const v = h.graphic.attributes[f];
              if (floors.includes(v)) {
                result2.push(h);
              }
            } else {
              result2.push(h);
            }
          }
        });
        result = result2;
      }
    }
    */
    return result;
  }

  getActiveFacilityInfo() {
    return this.facilityMode && this.facilityMode.activeFacilityInfo;
  }

  getAppUserAreaIDs(considerReserveFor, includeManagerIds?) {
    let info = this.appUserAssignments;
    const ids = [];

    if (considerReserveFor && this.reserveForInfo && this.reserveForInfo.areaIDs) {
      info = this.reserveForInfo;
    }

    if (info) {
      info.areaIDs.forEach((id) => {
        if (!ids.includes(id)) {
          ids.push(id);
        }
      });
    }

    if (includeManagerIds) {
      const managerInfo = this.managerInfo;
      if (managerInfo) {
        managerInfo.reservationManagerIds.forEach((id) => {
          if (!ids.includes(id)) {
            ids.push(id);
          }
        });
      }
    }

    return ids;
  }

  getFindHotelsButtonLabel(): string {
    // VA: Config to allow overlapping office hotel bookings #7337
    const i18n = Context.instance.i18n;
    try {
      const customConfig = Context.instance.customConfig;
      const v = (customConfig && customConfig.findHotelsButtonLabel);
      if (typeof v === "string" && v.length > 0) return v;
    } catch(ex) {
      console.error(ex);
    }
    return i18n.more.bookWorkspace.findHotels;
  }

  getHotelButtonLabel(): string {
    // VA: Config to allow overlapping office hotel bookings #7337
    const i18n = Context.instance.i18n;
    try {
      const customConfig = Context.instance.customConfig;
      const v = (customConfig && customConfig.hotelButtonLabel);
      if (typeof v === "string" && v.length > 0) return v;
    } catch(ex) {
      console.error(ex);
    }
    const { workspaceReservation } = Context.instance.config;
    const defaultLabel = i18n.more.bookWorkspace.caption;
    return workspaceReservation.enableHotelLabel
      ? workspaceReservation.reservationLabelHotel ?? defaultLabel
      : defaultLabel;
  }

  getMeetingButtonLabel(): string {
    const i18n = Context.instance.i18n;
    const { workspaceReservation } = Context.instance.config;
    const defaultLabel = i18n.meetingRooms.caption;
    return workspaceReservation.enableMeetingLabel
      ? workspaceReservation.reservationLabelMeeting ?? defaultLabel
      : defaultLabel;
  }

  isHotelMeetingRoom(unitFeature): boolean {

    const areaId = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.UNITS_AREA_ID);
    const asnType = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const isAreaMeetingRoom = (!!areaId && (asnType === SpaceAssignmentTypes.meetingroom));
    if (isAreaMeetingRoom) return true;
    if (!areaId && (asnType !== SpaceAssignmentTypes.office)) {
      const bookingSystemType = getMeetingBookingSystemType();
      if (bookingSystemType === "office365") {
        const email = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.SCHEDULE_EMAIL);
        if (!!email) return true; // backwardly compatible meeting rooms
      } else {
        const meth = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.RESERVATION_METHOD);
        if (meth === 1 || meth === 2) return true; // backwardly compatible meeting rooms
      }
    }

    // VA: Config to allow overlapping office hotel bookings #7337
    try {
      const customConfig = Context.instance.customConfig;
      const allowHotelMeetingRooms = !!(customConfig && customConfig.allowHotelMeetingRooms);
      const hotelMeetingRoomUseTypes = (customConfig && customConfig.hotelMeetingRoomUseTypes);
      if (allowHotelMeetingRooms && Array.isArray(hotelMeetingRoomUseTypes)) {
        const areaId = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.UNITS_AREA_ID);
        if (typeof areaId === "string" && areaId.length > 0) {
          const useType = aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.UNITS_USE_TYPE);
          if (hotelMeetingRoomUseTypes.includes(useType)) {
            //console.log("isHotelMeetingRoom",useType)
            return true;
          }
        }
      }
    } catch(ex) {
      console.error(ex);
    }
    return false;
  }

  isReservationManager() {
    const { managerInfo } = this;
    return managerInfo && managerInfo.reservationManagerIds.length > 0;
  }

  getCIMCategories() {
    return this.datasets && this.datasets.cimCategories;
  }

  getLevelIdField(sourceOrLayer): __esri.Field {
    let field, fields, layer, name, source;
    if (sourceOrLayer && sourceOrLayer.layer2D) {
      source = sourceOrLayer;
      layer = sourceOrLayer.layer2D;
      fields = layer.fields;
    } else if (sourceOrLayer && sourceOrLayer.fields) {
      layer = sourceOrLayer;
      fields = layer.fields;
      source = layer.xtnAiim && layer.xtnAiim.source;
    }
    if (layer && layer.floorInfo && layer.floorInfo.floorField) {
      name = layer.floorInfo.floorField;
      field = aiimUtil.findField(fields,name);
    } else if (source && source.mappings && source.mappings.levelIdField) {
      name = source.mappings["levelIdField"];
      field = aiimUtil.findField(fields,name);
    } else if (fields) {
      name = FieldNames.LEVEL_ID;
      field = aiimUtil.findField(fields,name);
    }
    return field;
  }

  getSource(key) {
    const categories = this.datasets && this.datasets.categories;
    if (categories) return categories.findSourceByKey(key);
  }

  getUnitName() {
    const config = Context.getInstance().config;
    const unitFieldName = config && config.spaceplanner && config.spaceplanner.unitName;
    if(unitFieldName) {
      const layer = sourceUtil.getUnitsLayer();
      if(layer && layer.fields) {
        const field = aiimUtil.findField(layer.fields, unitFieldName);
        if(field) return field.name;
      }
    }
    return FieldNames.NAME;
  }

  getUnitNameFieldName() {
    return this.getUnitName();
  }

  getUnitNameValue(feature) {
    const field = this.getUnitName();
    return aiimUtil.getAttributeValue(feature.attributes,field);
  }

  getZInfo(source,feature) {
    if (this.datasets && this.datasets.levels) {
      return this.datasets.levels.getZInfo(source,feature);
    }
    return null;
  }

  isFloorAwareMap() {
    if (this.datasets && this.datasets.levels) {
      return !!this.datasets.levels.floorAwareInfo;
    }
    return null;
  }

  load() {
    const promise = new Promise<void>((resolve,reject) => {
      this.datasets = new Datasets();

      let nsPromise;
      if (Context.instance.appMode.isSP_or_FPE()) {
        nsPromise = Promise.resolve()
      } else {
        nsPromise = this._loadNetworkServices();
      }

      this.datasets.load().then(result => {
        checkInOutUtil.checkInOnStartup();
      }).then((result) => {
        //console.log("after.Datasets::load",this.datasets);
        this.facilityFootprints = new FacilityFootprints();
        return this.facilityFootprints.load();
      }).then((result) => {
        this.facilityMode = new FacilityMode();
      }).then((result) => {
        this.routeBarriers = new RouteBarriers();
        return this.routeBarriers.load();
      }).then((result) => {
        if (Context.instance.appMode.isSP_or_FPE()) {
          const planner = Context.instance.spaceplanner.planner;
          return planner.load();
        } else {
          const unc = Context.instance.session.unitNameCache = new UnitNameCache();
          unc.load(true);
          return this._loadAppUserAssignments();
        }
      }).then(()=>{
          Context.instance.views._addLegend(Context.instance.views.activeView)
      }).then(()=> {
        setTimeout(()=> {
          Context.instance.views._addViewToggle(Context.instance.views.activeView)
        }, 500);
      }).then(() => {
        this.checkFloorFilter();
      }).then(() => {
        return nsPromise;
      }).then((result) => {
        console.log("Aiim::load....................");
        resolve();
      }).catch(ex => {
        reject(ex);
      });

    });
    return promise;
  }

  _loadAppUserAssignments() {
    const promise = new Promise<void>((resolve,reject) => {
      this.appUserAssignments = null;
      this.reserveForInfo = null;
      const people = this.datasets.people;
      const reservations = this.datasets.reservations;
      const areaRoles = this.datasets.areaRoles;
      const isKiosk = Context.instance.uiMode.isKiosk;
      const isAnonymous = Context.instance.user.isAnonymous();
      if (!people || isKiosk || isAnonymous) {
        resolve();
      } else {
        let areaIDs = [];
        Promise.resolve().then(() => {
          let areas = this.datasets.areas;
          if (areas) {
            let opts = {
              assignedToAppUser: this.useRestrictedHotelBookings(),
              hotelsOnly: true
            }
            return areas.queryAll(opts).then(result => {
              if (result && result.features) {
                result.features.forEach(f => {
                  let aid = aiimUtil.getAttributeValue(f.attributes,FieldNames.AREA_ID);
                  if (aid) areaIDs.push(aid);
                })
              }
            })
          }
        }).then(() => {
          return people.queryAppUserAssignments().then(info => {
            areaIDs.forEach(aid => {
              if (info.areaIDs.indexOf(aid) === -1) {
                info.areaIDs.push(aid);
              }
            })
            //console.log("queryAppUserAssignments",info)
            this.appUserAssignments = info;
          });
        }).then(() => {
          if (!areaRoles || !reservations || !reservations.hasReservedByFields()) {
            return Promise.resolve();
          }

          return areaRoles.queryAppUser().then((managerInfo) => {
            this.managerInfo = managerInfo;
          });
        }).then(() => {
          resolve();
        }).catch(ex => {
          console.error("Error loading app user assignments",ex);
          resolve();
        })
      }
    });
    return promise;
  }

  _loadNetworkServices() {
    const promise = new Promise<void>((resolve,reject) => {
      const promises = [];
      this.routeService = new RouteService();
      this.closestFacilityService = new ClosestFacilityService();
      promises.push(this.routeService.load());
      promises.push(this.closestFacilityService.load());
      // no catch,
      // routeService.load() and closestFacilityService.load should not reject
      Promise.all(promises).then(list => {
        //console.log("this.routeService",this.routeService);
        //console.log("this.closestFacilityService",this.closestFacilityService);
        resolve();
      });
    });
    return promise;
  }

  reset() {
    this.appUserAssignments = null;
    this.managerInfo = null;
    if (this.facilityFootprints) {
      this.facilityFootprints.destroy();
      this.facilityFootprints = null;
    }
    if (this.facilityMode) {
      this.facilityMode.destroy();
      this.facilityMode = null;
    }
    if (this.datasets) {
      this.datasets.destroy();
      this.datasets = null;
    }
  }

  useCIMCategories() {
    const cimCategories = this.datasets && this.datasets.cimCategories;
    return cimCategories && cimCategories.hasData();
  }

  useRestrictedHotelBookings() {
    return true;
  }

}
