/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import Context from "../../../../context/Context";
import { ModalController } from "../../../../common/components/Modal";
import { IGroup } from '@esri/arcgis-rest-types';

import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-value-list";
import "@esri/calcite-components/dist/components/calcite-value-list-item";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteChip,
  CalciteIcon,
  CalciteInputText,
  CalciteLabel,
  CalciteValueList,
  CalciteValueListItem,
  CalciteTooltip
} from "@esri/calcite-components-react";
import {
  CalciteInputTextCustomEvent,
  CalciteValueListCustomEvent,
  CalciteChipCustomEvent
} from "@esri/calcite-components";

const CSS = {
  list: 'i-groups-list',
  chip: 'i-div-chip',
  chips: 'i-div-chips',
  info: 'i-icon-info'
}
interface IGroupSearchProps {
  assignedGroups: IGroup[],
  disabled: boolean,
  groups: IGroup[],
  horizontal: boolean,
  onShareGroup: (shareIds: string[], unShareIds: string[]) => void,
  onUnshareGroup: (unShareIds: string[], shareIds: string[]) => void
}
const GroupSearch = ({ groups, horizontal, onShareGroup, onUnshareGroup, assignedGroups, disabled }: IGroupSearchProps) => {
  const [data, setData] = useState<{
    chips: IGroup[],
    shareIds: string[],
    suggestions: any[],
    suggestionsActive: boolean,
    unShareIds: string[]
  }>({
    suggestions: [],
    suggestionsActive: false,
    chips: assignedGroups && assignedGroups.length > 0 ? assignedGroups : [],
    shareIds: [],
    unShareIds: [],
  });
  const [value, setValue] = useState<string>('');
  const { suggestions, suggestionsActive, chips, shareIds, unShareIds } = data;
  const ctx = Context.getInstance();
  const i18n = ctx.i18n;
  const portal = ctx.getPortal();
  const groupsUrl = portal ? `${portal.url}/home/groups.html?sortOrder=asc&sortField=title` : null;

  useEffect(() => {
    if (!groups || groups.length === 0) {
      setValue('')
      setData({
        ...data,
        suggestionsActive: false,
        suggestions: [],
        chips: [],
        shareIds: [],
        unShareIds: [],
      });
    } else {
      setData({ ...data, chips: assignedGroups || [] });
    }
  }, [groups]);

  // Filter groups for suggestions list
  const handleChange = (e: CalciteInputTextCustomEvent<void>) => {
    const query = e.target.value.toLowerCase();
    setValue(e.target.value);
    if (query.length > 1) {
      const filterSuggestions = groups.filter(x => x.title.toLowerCase().indexOf(query) > -1);
      setData({ ...data, suggestions: filterSuggestions, suggestionsActive: true });
    } else {
      setData({ ...data, suggestionsActive: false });
    }
  }

  const handleClick = (e: CalciteValueListCustomEvent<Map<string, HTMLCalciteValueListItemElement>>) => {
    // e.detail is still working for CalciteValueList at 1.3.1 
    const id = e.detail.keys().next().value;
    const selectedGroup = groups.find(x => x.id === id);
    const index = chips.indexOf(selectedGroup);
    if (index !== -1) {
      setValue('');
      setData({ ...data, suggestions: [], suggestionsActive: false });
      return;
    }
    if (!selectedGroup.membershipAccess) {
      // any org has access
      ModalController.alert({
        title: i18n.configurator.appItem.shareWith.groups.cannotShare.replace("{title}", selectedGroup.title),
        message: i18n.configurator.appItem.shareWith.groups.mustBeOrgOnly
      });
      setValue('');
      setData({ ...data, suggestions: [], suggestionsActive: false });
      return;
    }
    chips.push(selectedGroup);
    shareIds.push(id);
    const index2 = unShareIds.indexOf(id);
    if (index2 !== -1) unShareIds.splice(index2, 1);
    setValue('');
    setData({ ...data, suggestions: [], suggestionsActive: false });
    onShareGroup(shareIds, unShareIds);
  }

  // Render suggestions list
  const Suggestions = () => {
    return (
      <CalciteValueList className={CSS.list} onCalciteListChange={handleClick}>
        {suggestions.map((suggestion, index) => (
          <CalciteValueListItem key={index} label={suggestion.title} value={suggestion.id}>
            <CalciteIcon icon="users" slot="content-start" />
          </CalciteValueListItem>
        ))}
      </CalciteValueList>
    )
  }

  // Delete selected groups
  const deleteChip = (e: CalciteChipCustomEvent<any>) => {
    if (!e.target.id) return;
    const id = e.target.id;
    setData({ ...data, chips: chips.filter(x => x.id !== id) });
    const index = shareIds.indexOf(id);
    if (index !== -1) shareIds.splice(index, 1);
    unShareIds.push(id)
    if (onUnshareGroup) onUnshareGroup(unShareIds, shareIds);
  }

  // Chips are compact elements that represent an input, attribute, or in this case group.
  const Chip = () => {
    return (
      <>
        {chips.map(chip => {
          const props = {
            icon: "users",
            ...!disabled && { closable: true }
          };
          return (
            <CalciteChip
              key={chip.id}
              id={chip.id}
              className={CSS.chip}
              color="blue"
              value={chip.id}
              onCalciteChipClose={deleteChip}
              {...props}>
              {chip.title}
            </CalciteChip>
          );
        })
        }
      </>
    )
  }
  const Text = () => {
    const props = {
      ...disabled && { disabled: true }
    }
    return (
      <CalciteInputText style={{ marginBottom: "10px" }}
        value={value}
        scale="l"
        onCalciteInputTextInput={handleChange}
        clearable
        icon="search"
        placeholder={i18n.configurator.appItem.shareWith.groups.search}
        {...props}
      />
    )
  }
  const cls = suggestionsActive || (assignedGroups && assignedGroups.length)
    ? "manage-groups manage-groups-active"
    : "manage-groups";
  const link = (
    <a href={groupsUrl} target='_blank' rel='noopener noreferrer' className={cls}>
      {<CalciteIcon icon="users" scale="s" />} {i18n.configurator.appItem.shareWith.groups.link}
    </a>)
  const isVersioned = Context.instance.spaceplanner.planner.project.isVersioned || false;
  const infoLabel = isVersioned
    ? i18n.configurator.appItem.shareWith.groups.tooltipVersioned
    : i18n.configurator.appItem.shareWith.groups.tooltipHosted;
  return (
    <>
      <CalciteLabel
        for="title"
        scale="l"
        layout="inline-space-between"
        className={horizontal ? "i-new-plan i--form-label" : ""}>
        <span className="i-share-with-group-label">
          {i18n.configurator.appItem.shareWith.groups.title}
          <CalciteIcon icon="information" scale="s" id="new-plan-info-icon" />
          <CalciteTooltip placement="top-start" referenceElement="new-plan-info-icon"
            label={infoLabel}>
            {infoLabel}
          </CalciteTooltip>
        </span>
        <span className="i-share-with-group-label">
          {portal && link}
        </span>
      </CalciteLabel>
      <div className="i-share-with-group-text" id="new-plan-group-text">
        {disabled &&
          <CalciteTooltip referenceElement="new-plan-group-text"
            label={i18n.configurator.appItem.shareWith.groups.notApplicable}>
            {i18n.configurator.appItem.shareWith.groups.notApplicable}
          </CalciteTooltip>}
        {Text()}

        {suggestionsActive && <Suggestions />}
        <div className={CSS.chips}>
          {chips.length > 0 && <Chip />}
        </div>
      </div>
    </>
  )
}

export default GroupSearch;
