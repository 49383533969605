import BaseClass from "../../util/BaseClass";
import * as aiimUtil from "../util/aiimUtil";

export default class Dataset extends BaseClass {

  layer2D: __esri.FeatureLayer & { xtnSubLayer: __esri.Sublayer};
  layer3D;
  name;
  requiresFacilityMode = false;
  subLayer;
  type;
  url;

  constructor(props?) {
    super(props)
    this.mixinProps(props);
  }

  checkFieldNameProperty(propertyName, fields) {
    try {
      const v = this[propertyName];
      if (typeof v === "string" && v.length > 0 && fields) {
        const v2 = aiimUtil.findFieldName(fields,v);
        if (v !== v2 && typeof v2 === "string" && v2.length > 0) {
          this[propertyName] = v2;
        }
      }
    } catch(ex) {
      console.error("Dataset.checkFieldNameProperty",ex);
    }
  }

  checkSchema(): Promise<void> {
    return Promise.resolve();
  }

  isMapServiceBased() {
    return this.layer2D && !!this.subLayer;
  }

  getObjectIdField() {
    return this.layer2D && this.layer2D.objectIdField;
  }

}
