import React from "react";

import ConfiguratorMinView from "../Configurator/ConfiguratorMinView";
import Burger from "./Burger";
import Context from "../../../context/Context";
import Searchbox from "./Searchbox";
import UserControl from "./UserControl";
import Reset from "./Reset";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  main: "i-header",
  left: "i-header-left",
  right: "i-header-right",
  logo: "i-header-logo i-brand-logo"
};

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      logoUrl: Context.getInstance().uiMode.getLogoUrl(),
      logoName: Context.getInstance().uiMode.getLogoName(),
      logoSize: Context.getInstance().uiMode.getLogoSize()
    });
  }

  componentDidMount(){
    component.own(this,[
      Topic.subscribe(Topic.ConfigurationDiscarded,() => {
        this.setState({
          logoUrl: Context.getInstance().uiMode.getLogoUrl(),
          logoName: Context.getInstance().uiMode.getLogoName(),
          logoSize: Context.getInstance().uiMode.getLogoSize()
        })
      }),
      Topic.subscribe(Topic.LogoChanged, ()=>{
        this.setState({
          logoUrl: Context.getInstance().uiMode.getLogoUrl(),
          logoName: Context.getInstance().uiMode.getLogoName(),
          logoSize: Context.getInstance().uiMode.getLogoSize()
        })
      }),
      Topic.subscribe(Topic.LogonameChanged,()=>{
        this.setState({
          logoName: Context.getInstance().uiMode.getLogoName()
        })
      })
     ]);
  }

  // componentDidUpdate(){
  //   try {
  //     if (navigator.platform && /MacIntel/.test(navigator.platform)) {
  //       var ua = navigator.userAgent.toLowerCase();
  //       if (ua.indexOf('safari') !== -1) {
  //         document.getElementById("view-container").style.zIndex = "-1";
  //         document.getElementById("view-container").style.zIndex = "1";
  //         let node=document.createElement('div');
  //         document.body.appendChild(node);
  //         setTimeout(document.body.removeChild(node), 100);
  //       }
  //     }
  //   } catch(ex) {
  //     console.error(ex);
  //   }
  // }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const logo = this.renderLogo();
    let reset = null;
    if (Context.getInstance().uiMode.isKiosk &&
        Context.getInstance().config.kiosk.kioskReset.enabled) {
      reset = (<Reset />);
    }
    return (
      <header className={CSS.main} role="banner">
        <div className={CSS.left}>
          <Burger />
          {logo}
          <Searchbox />
          {reset}
          <ConfiguratorMinView />
        </div>
        <div className={CSS.right}>
          <UserControl />
        </div>
      </header>
    );
  }

  renderLogo() {
    const logoSize = this.state.logoSize;
    const url = this.state.logoUrl;
    if (typeof url === "string" && url.length > 0) {
      const style = {width: "auto", height: logoSize + "rem"};
      return (
        <img className={CSS.logo} src={url} style={style}
          alt={this.state.logoName} title={this.state.logoName}/>
      );
    }
    return null;
  }
}
