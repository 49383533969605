import Context from "../../context/Context";
import * as three11Util from "./three11Util";
import * as val from "../../util/val";
import * as aiimUtil from "./aiimUtil";
import FieldNames from "../datasets/FieldNames";
import { ILaunchAction } from "../../context/Configuration";

let COUNTER = 0;


export function actionMatches(action,source,subType) {
  if (!action || !source) return false;
  const categories = action.categories;
  const layers = action.layers;
  let sourceName = source.name, matches = false;
  if (source.fromCategoriesTable) {
    if (categories.length === 1 && categories[0] === "*") {
      matches = true;
    } else if (categories.indexOf(sourceName) !== -1) {
      matches = true;
    } else if (layers.indexOf(sourceName) !== -1) { // TODO?
      matches = true;
    // } else if (layers.length === 1 && layers[0] === "*") { // TODO?
    //   matches = true;
    } else if (subType) {
      if (categories.indexOf(subType.name) !== -1) {
        matches = true;
      }
    }
  } else {
    if (layers.length === 1 && layers[0] === "*") {
      matches = true;
    } else if (layers.indexOf(sourceName) !== -1) {
      matches = true;
    // } else if (categories.indexOf(sourceName) !== -1) { // TODO?
    //   matches = true;
    }
  }
  return matches;
}

function actionMatchesItem(action,item,considerSubTypes) {
  const source = item && item.getSource();
  if (!action || !source) return false;
  let subType;
  if (considerSubTypes && source.subTypesByValue) {
    let st = item.getSubType();
    if (source.subTypesByValue.hasOwnProperty(st)) {
      subType = source.subTypesByValue[st];
    }
  }
  return actionMatches(action,source,subType);
}

export function checkActions(actions) {
  if (Array.isArray(actions)) {
    actions.forEach(action => {
      if (typeof action.label !== "string") action.label = null;
      if (typeof action.url !== "string") action.url = null;
      if (!Array.isArray(action.layers)) action.layers = [];
      if (!Array.isArray(action.categories)) action.categories = [];
    });
  }
}

export function cloneActions(actionsToClone) {
  const actions = [];
  actionsToClone.forEach(action => {
    action = Object.assign({},action);
    if (Array.isArray(action.layers)) {
      action.layers = action.layers.slice()
    } else {
      action.layers = []
    }
    if (Array.isArray(action.categories)) {
      action.categories = action.categories.slice()
    } else {
      action.categories = []
    }
    actions.push(action);
  });
  return actions;
}

function generateUrl(action,item,urlInfo,itemSource?): Promise<void> {
  const promise = new Promise<void>((resolve,reject) => {
    const source = itemSource || (item && item.getSource());
    const searchResult = item && item.getSearchResult();
    const feature = searchResult && searchResult.feature;
    const actionUrl = val.trim(action.webUrl) || val.trim(action.url);
    three11Util.generate311Url(actionUrl,source,feature).then(url => {
      if (val.isNonEmptyStr(url,true)) {
        urlInfo.url = url.trim();
      }
      resolve();
    }).catch(ex => {
      console.warn("Error generating app launch url for",action);
      console.error(ex);
      resolve();
    });
  });
  return promise;
}

export function generateUrls(item) {
  const promise = new Promise((resolve,reject) => {
    try {
      const appLaunch = Context.instance.config.appLaunch;

      /* A few test cases */
      // appLaunch.actions = [
      //   {"label":"JSAPI","url":"https://developers.arcgis.com/javascript/index.html","layers":["*"],"categories":[]},
      //   // {"label":"Test1","url":null,"layers":["*"],"categories":[]},
      //   // {"label":"Test2","url":"","layers":["*"],"categories":[]},
      //   // {"label":null,"url":"https://www.esri.com","layers":["*"],"categories":[]},
      //   // {"label":"","url":"https://www.google.com","layers":["*"],"categories":[]},
      //   // {"label":"Test5","url":"https://www.google.com","layers":[],"categories":[]},
      //   // {"label":"Test6","url":"https://www.google.com","layers":[],"categories":["*"]},
      //   {"label":"Esri","url":"https://www.esri.com","layers":["*"],"categories":[]}
      // ];
      // for (let i=1;i<20;i++) {
      //   appLaunch.actions.push({"label":"T"+i,"url":"https://www.google.com","layers":["*"],"categories":[""]})
      // }
      // appLaunch.actions = [
      //   {"label":"Computer Lab","url":"https://www.esri.com","layers":[],"categories":["Computer Lab"]}
      // ];

      const actions = appLaunch.actions;
      const list = [], promises = [];
      actions.forEach(action => {
        let matches = actionMatchesItem(action,item,true);
        if (matches) {
          let urlInfo = {
            key: nextId(),
            label: action.label,
            url: null
          };
          if (val.isNonEmptyStr(action.url)) {
            list.push(urlInfo);
            promises.push(generateUrl(action,item,urlInfo));
          }
        }
      });
      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          const urls = [];
          list.forEach(urlInfo => {
            if (urlInfo.url) urls.push(urlInfo);
          });
          //console.log("urls111",urls);
          resolve(urls);
        }).catch(ex => {
          reject(ex);
        });
      } else {
        resolve([]);
      }
    } catch(ex) {
      reject(ex);
    }
  });
  return promise;
}

export function generateUrlsCategoriesAndLayers(item) {
  return new Promise((resolve, reject) => {
    try {
      const actions = Context.instance.config.appLaunch.actions
      const list = [], promises = []
      actions.forEach(action => {
        // Check if the item matches the action
        const categoryObj = actionMatchesItemCategories(action, item)
        const layerObj = actionMatchesItemLayer(action, item)
        const match = categoryObj.match || layerObj.match
        const itemSource = categoryObj.cimCategory || layerObj.source
        if (match) {
          let urlInfo = {
            key: nextId(),
            label: action.label,
            url: null
          }
          if (val.isNonEmptyStr(action.webUrl) || val.isNonEmptyStr(action.url)) {
            list.push(urlInfo)
            promises.push(generateUrl(action, item, urlInfo, itemSource))
          }
        }
      })
      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          const urls = [];
          list.forEach(urlInfo => {
            if (urlInfo.url) urls.push(urlInfo);
          });
          resolve(urls);
        }).catch(ex => {
          reject(ex);
        });
      } else {
        resolve([]);
      }
    } catch(ex) {
      reject(ex);
    }
  })
}

function actionMatchesItemCategories(action, item) {
  const itemFeature = item && item.searchResult && item.searchResult.feature
  // const itemCategorySubType = aiimUtil.getAttributeValue(itemFeature.attributes, FieldNames.CATEGORY_SUBTYPE)
  // const itemCategoryType = aiimUtil.getAttributeValue(itemFeature.attributes, FieldNames.CATEGORY_TYPE)
  // const categories = action.categories
  // const source = item.getSource()

  let itemCategorySubType,  itemCategoryType;
  const categories = action.categories
  const source = item.getSource()
  if (!source || !source.cimCategory) {
    itemCategorySubType = aiimUtil.getAttributeValue(itemFeature.attributes, FieldNames.CATEGORY_SUBTYPE)
    itemCategoryType = aiimUtil.getAttributeValue(itemFeature.attributes, FieldNames.CATEGORY_TYPE)
  }

  let match = false
  let cimCategory = null
  if (Array.isArray(categories)) {
    if (categories.length === 1 && categories[0] === "*") {
      match = true
      cimCategory = findCIMCategory(itemCategoryType, itemCategorySubType)
    }
    categories.forEach(category => {
      if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
        if (category === itemCategorySubType/* || category === itemCategoryType*/) {
          match = true
          cimCategory = findCIMCategory(itemCategoryType, itemCategorySubType)
        }
      } else {
        if (itemCategorySubType && itemCategoryType) {
          const name = category.name || category
          if (name) {
            if (name === itemCategorySubType || name === itemCategoryType) {
              match = true
              cimCategory = findCIMCategory(itemCategoryType, itemCategorySubType)
            }
          }
        } else if (itemFeature && itemFeature.xtnSource && itemFeature.xtnSource) {
          const itemSourceName = itemFeature.xtnSource.name
          categories.forEach(category => {
            if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
              if (category === itemSourceName) {
                match = true
                cimCategory = findCIMCategory(null, itemSourceName)
              }
            } else {
              const name = category.name || category
              if (name && name === itemSourceName) {
                match = true
                cimCategory = findCIMCategory(null, itemSourceName)
              }
            }
          })
        } else if (source && source.cimCategory) {
          const itemSourceName = source.cimCategory.name
          categories.forEach(category => {
            if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
              if (category === itemSourceName) {
                match = true
                cimCategory = findCIMCategory(null, itemSourceName)
              }
            } else {
              const name = category.name || category
              if (name && name === itemSourceName) {
                match = true
                cimCategory = findCIMCategory(null, itemSourceName)
              }
            }
          })
        }
      }
    })
  }
  return { match: match, cimCategory: cimCategory }
}

function actionMatchesItemLayer(action, item) {
  const itemFeature = item && item.searchResult && item.searchResult.feature
  const source = item.getSource()
  const itemLayer = (source && source.layer2D) || (itemFeature && itemFeature.layer)
  let match = false
  const layers = action.layers
  
  if (Array.isArray(layers)) {
    if (itemLayer) {
      if (layers.length === 1 && layers[0] === "*") {
        match = true
      } else {
        let byTitle = Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig;
        if(!byTitle && layers.length > 0 && typeof layers[0] === "string") {
          byTitle = true;
        }
        if (byTitle) {
          layers.forEach(layer => {
            if (itemLayer.layer && itemLayer.layer.title) {
              if (layer === itemLayer.layer.title) {
                match = true
              }
            } else {
              if (itemLayer.title === layer) {
                match = true
              }
            }
          })
        } else {
          let xtnSubLayer = itemLayer.xtnSubLayer;
          layers.forEach(layer => {
            if (!itemLayer.layer && itemLayer.id) {
              if (xtnSubLayer) {
                const webMapLayerId = layer.webMapLayerId;
                const mapServiceLayerId = layer.mapServiceLayerId;
                if (xtnSubLayer.id === mapServiceLayerId) {
                  let parent = xtnSubLayer.parent;

                  while (parent) {
                    if (parent && parent.id === webMapLayerId) {
                      match = true;
                      break;
                    } else if (parent) {
                      parent = parent.parent;
                    } else {
                      parent = null;
                      break;
                    }
                  }
                }
              } else {
                const webMapLayerId = layer.webMapLayerId
                const layerId = itemLayer.id
                if (webMapLayerId === layerId) {
                  match = true
                }
              }
            } else if (itemLayer.layer && itemLayer.layer.id) {
              const webMapLayerId = layer.webMapLayerId
              const mapServiceLayerId = layer.mapServiceLayerId
              if (itemLayer.layer.id === webMapLayerId && itemLayer.id === mapServiceLayerId) {
                match = true
              }
            } else {
              const mapServiceLayerId = layer.mapServiceLayerId
              if (itemLayer.layerId === mapServiceLayerId) {
                match = true
              }
            }
          })
        }
      }
    } else if (layers && itemFeature && itemFeature.xtnSource && itemFeature.xtnSource.layer2D) {
      const itemLayerId = itemFeature.xtnSource.layer2D.layerId
      layers.forEach(layer => {
        if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
          const layerName = itemFeature.xtnSource.layer2D.title
          if (layer === layerName) {
            match = true
          }
        } else {
          if (layer.mapServiceLayerId && layer.mapServiceLayerId === itemLayerId) {
            match = true
          } else if (layer.webMapLayerId && layer.webMapLayerId === itemFeature.xtnSource.layer2D.id) {
            match = true
          }
        }
      })
    }
  }
  return { match: match, source: source }
}

function findCIMCategory(itemType, itemSubType) {
  const cimCategories = Context.instance.aiim.getCIMCategories()
  const sources = cimCategories && cimCategories.sources
  let match = null
  sources.forEach(src => {
    if (src.name && (src.name === itemSubType || src.name === itemType)) match = src
  })
  return match
}

export function getSources() {
  const list = [];
  const aiim = Context.instance.aiim;
  const sources = (aiim && aiim.datasets && aiim.datasets.categories &&
    aiim.datasets.categories.sources) || [];
  sources.forEach(source => {
    if (source.url && source.layer2D) {
      list.push(source);
    }
  });
  return list;
}

// export function makeValidActions(actions) {
//   let valid = [];
//   actions.forEach(action => {
//     if (val.isNonEmptyStr(action.label,true) && val.isNonEmptyStr(action.url,true)) {
//       if (action.layers.length > 0 || action.categories.length > 0) {
//         let action2 = Object.assign({},action);
//         action2.label = val.trim(action.label);
//         action2.url = val.trim(action.url);
//         action2.categories = action2.categories.slice();
//         action2.layers = action2.layers.slice()
//         delete action2.uiKey;
//         valid.push(action2);
//       }
//     }
//   });
//   return valid;
// }

export function newAction() {
  let action = {
    label: null,
    url: null,
    layers: ["*"],
    categories: ["*"]
  };
  return action;
}

function nextId() {
  return "appLaunchUrl-" + COUNTER++;
}

export function three11AsAppLaunch(three11Url: string): {
  actions: ILaunchAction[]
} {
  let appLaunch = null;
  if (typeof val.isNonEmptyStr(three11Url,true)) {
    const i18n = Context.instance.i18n;
    const action = newAction();
    Object.assign(action, {
      label: i18n.infoPanel.actions.launch311,
      url: three11Url.trim(),
      layers: ["*"],
      categories: ["*"]
    });
    appLaunch = {
      actions: [action]
    }
  }
  return appLaunch;
}
