import * as sourceUtil from "../sourceUtil";
import { makeTransactionInfo } from "./transaction";
import { cloneFeature, checkUniqueIds } from "./transactionUtil";

import FieldNames from "../../../aiim/datasets/FieldNames";
import Context from "../../../context/Context";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as occupantUtil from "./occupantUtil";
import { LayerType } from "../../../util/interfaces";

export type MergeTask = {
  info: {
    type: Extract<LayerType, "unit" | "detail">,
    /** The graphics containing the original geometries and attributes. */
    features: __esri.Graphic[],
    /** The merged graphic including updated geometry and attributes. */
    merged: __esri.Graphic,
    /** The updated detail features (e.g. walls) that had sections removed, if any. */
    dissolved?: {
      features: __esri.Graphic[],
      original: __esri.Graphic[]
    }
  }
}

export async function prepare(task: MergeTask) {
  const transactionInfo = makeTransactionInfo();
  const { type, features, merged, dissolved } = task.info;
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  const makeEdits = async () => {
    if (features.length > 1 && merged) {
      const units: __esri.FeatureLayer = sourceUtil.getUnitsLayer();
      const details: __esri.FeatureLayer = sourceUtil.getDetailsLayer();
      const layer: __esri.FeatureLayer = type === "unit" ? units : details;

      const unitIdField = units.objectIdField;
      const detailsIdField = details.objectIdField;
      const keyField = type === "unit" ? unitIdField : detailsIdField;
      const original = features.find(f => f.attributes[keyField] === merged.attributes[keyField]);
      const others = features.filter(f => f.attributes[keyField] !== merged.attributes[keyField]);

      // unassign occupants if units are deleted or if the unit_id was changed
      if (type === "unit") {
        const delUnitIds = [];
        const unitIdFieldName = aiimUtil.findFieldName(layer.fields,FieldNames.UNIT_ID);
        const asnTypeField = aiimUtil.findFieldName(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE); 
        if (merged && original) {
          const unitId = merged.attributes[unitIdFieldName];
          const originalUnitId = original.attributes[unitIdFieldName];
          const changed = (unitId !== originalUnitId);
          if (changed && unitId && originalUnitId && delUnitIds.indexOf(originalUnitId) === -1) {
            delUnitIds.push(originalUnitId);
            const asnType = merged.attributes[asnTypeField];
            if (asnType === "office") {
              merged.attributes[asnTypeField] = "none";
            }
          }
        }
        others.forEach(f => {
          const unitId = f.attributes[unitIdFieldName];
          if (unitId && delUnitIds.indexOf(unitId) === -1) delUnitIds.push(unitId);
        })
        if (delUnitIds.length > 0) {
          await occupantUtil.removeOccupantAssignments(transactionInfo,delUnitIds);
        }
        if(merged) {
          const mergedAttr = merged && merged.attributes;
          const uid = mergedAttr && aiimUtil.getAttributeValue(mergedAttr, unitIdFieldName);
          const uidFt = features.map(f => f.attributes[unitIdFieldName]).filter(x=>!!x);
          if(!uidFt.includes(uid)){
            const dupes = await checkUniqueIds([uid], units, unitIdFieldName);
            if (dupes && dupes.length) {
              const i18n = Context.getInstance().i18n;
              if(dupes.length>1) {
                throw new Error(`${i18n.editor.units.errNonUniqueIds} [${dupes}]`);
              } else {
                throw new Error(`${i18n.editor.units.errNonUniqueId} [${dupes}]`);
              }
            }
          }
        }
      }
      
      transactionInfo.unitEdits = transactionInfo.undo.unitEdits = type === "unit";
      transactionInfo.detailEdits = transactionInfo.undo.detailEdits = type === "detail" || (dissolved && dissolved.features.length > 0);

      // merging will include 1 update (`info.merged`) and 1 or more deletes (the merged features)
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: [cloneFeature(merged)],
        deletes: others.map(f => cloneFeature(f).attributes[keyField])
      });

      // if dissolving, also include detail edits
      if (dissolved && dissolved.features.length > 0) {
        // include as an update if geometry is not null (i.e. geometry wasn't completely dissolved)
        // include as a delete if geometry is null (i.e. entire geometry was removed)
        const updated = dissolved.features.filter(f => f.geometry != null);
        const deleted = dissolved.features.filter(f => f.geometry == null);
        const updatedIds = updated.map(f => f.attributes[detailsIdField]);
        const deletedIds = deleted.map(f => f.attributes[detailsIdField])
        const undoUpdated = dissolved.original.filter(f => updatedIds.includes(f.attributes[detailsIdField]));
        const undoDeletes = dissolved.original.filter(f => deletedIds.includes(f.attributes[detailsIdField]));
        transactionInfo.edits.push({
          id: details.layerId,
          updates: dissolved.features.filter(f => f.geometry != null).map(f => cloneFeature(f)),
          deletes: dissolved.features.filter(f => f.geometry == null).map(f => f.attributes[detailsIdField])
        });
        transactionInfo.undo.edits.push({
          id: details.layerId,
          updates: undoUpdated.map(f => cloneFeature(f)),
          adds: undoDeletes.map(f => cloneFeature(f))
        })
      }

      // undoing the merge will include 1 update (original feature) and 1 or more adds (the merged features)
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: [cloneFeature(original)],
        adds: others.map(f => cloneFeature(f))
      })

    }
  }

  await makeEdits();
  console.log("merge.transactionInfo", transactionInfo);
  return transactionInfo;
}