import React from "react";
import { Manager, Reference, Popper } from "react-popper";

import Context from "../../../context/Context";
import ChoiceDropdown from "../../common/ChoiceDropdown/ChoiceDropdown";
import MenuOption from "../../common/ChoiceDropdown/MenuOption";
import Icons from "../../util/Icons";
import ItemReference from "../../../aiim/base/ItemReference";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as mapUtil from "../../../util/mapUtil";

const CSS = {
  main: "i-levelfilter",
  caption: "i-levelfilter-caption",
  list: "i-levelfilter-list",
  item: "i-levelfilter-item ",
  button: "i-button-listitem",
  itemButton: "i-button-listitem",
  itemButtonActive: "i-button-listitem i--active",
  clearButton: "i-button-listitem i-button--clear",
  upButton: "i-button-listitem i-button--up",
  downButton: "i-button-listitem i-button--down",
  infoButton: "i-button-listitem i-button--info",
  labelClass: "i-dd-levelfilter-item",
  ddLevelfilter: "i-dd-levelfilter",
  captionClass: "i-dd-caption",
  arrowClass: "i-dd-arrow"
};

export default class LevelFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      considerScrollIntoView: false,
      siteSelected: null,
      facilityInfo: null,
      activeSiteFacilityCache: {},
      activeLevelData: {},
      siteRowExpanded: true,
      facilityRowExpanded: true,
      outOfSync: false
    });
    this.allClicked = this.allClicked.bind(this);
    this.downClicked = this.downClicked.bind(this);
    this.exitClicked = this.exitClicked.bind(this);
    this.upClicked = this.upClicked.bind(this);
  }

  componentDidMount() {
    component.own(this,[

      Topic.subscribe(Topic.AppStarted,params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.FacilityCache, info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                outOfSync: true
              };
            });
          } else {
            const facilities = Context.getInstance().aiim.datasets.facilities;
            const levels = Context.getInstance().aiim.datasets.levels;
            if (facilities && levels) {
              const facility = info.facilityInfo
              const siteId = info.facilityInfo.zeroVOLevel.siteId
              let facilityCache = this.state.activeSiteFacilityCache
              if (facilityCache) {
                facilityCache[siteId] = facility
              }
              let activeLevelData = this.state.activeLevelData
              if (activeLevelData) {
                activeLevelData[siteId] = info.levelData
              }
              this.setState(state => {
                return {
                  activeSiteFacilityCache: facilityCache,
                  activeLevelData: activeLevelData
                };
              });
            }
          }
        } catch (ex) {
          console.error(ex)
        }
      }),

      Topic.subscribe(Topic.FacilityActivated,info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                facilityInfo: null,
                outOfSync: true
              };
            });
          } else {
            const facilities = Context.getInstance().aiim.datasets.facilities;
            const levels = Context.getInstance().aiim.datasets.levels;
            if (facilities && levels) {
              // let facilityRowExpanded = this.state.facilityRowExpanded;
              // if (info && info.facilityInfo && !info.facilityInfo.levels) {
              //   facilityRowExpanded = true;
              // }
              const facility = info.facilityInfo
              const site = info.facilityInfo.zeroVOLevel.siteName
              const siteId = info.facilityInfo.zeroVOLevel.siteId
              let facilityCache = this.state.activeSiteFacilityCache
              let levelCache = this.state.activeLevelData
              if (facilityCache) {
                facilityCache[siteId] = facility
              }
              if (levelCache) {
                levelCache[siteId] = facility.activeLevel
              }
              this.setState(state => {
                return {
                  schedSiteUpdate: true,
                  schedFacilityUpdate: true,
                  facilityInfo: facility,
                  siteSelected: site,
                  activeSiteFacilityCache: facilityCache,
                  considerScrollIntoView: true,
                  facilityRowExpanded: true // Issue #1924
                };
              });
              if (view.type === "2d") {
                const footprints = Context.instance.aiim.facilityFootprints;
                if (footprints) footprints.highlightFacility2D(info.facilityInfo);
              }
            }
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.SiteActivated, info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                siteSelected: null,
                outOfSync: true
              };
            });
          } else {
            const siteInfo = info.siteInfo
            const facilityInfo = info.facilityInfo
            this.setState({
              schedSiteUpdate: true,
              schedFacilityUpdate: true,
              siteSelected: siteInfo,
              facilityInfo: facilityInfo
            })
          }
        } catch(ex) {
          console.error(ex)
        }
      }),

      Topic.subscribe(Topic.FacilityDeactivated,info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                facilityInfo: null,
                outOfSync: true
              };
            });
          } else {
            this.setState(state => {
              return {
                schedSiteUpdate: true,
                schedFacilityUpdate: true,
                facilityInfo: null,
                facilityRowExpanded: true // Issue #1924
              };
            });
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.SiteDeactivated,info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                siteSelected: null,
                outOfSync: true
              };
            });
          } else {
            this.setState(state => {
              return {
                schedSiteUpdate: true,
                schedFacilityUpdate: true,
                siteSelected: null,
                facilityRowExpanded: true // Issue #1924
              };
            });
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.FacilityModeUpdated,info => {
        try {
          const view = this.props.view;
          if (view && view !== info.view) {
            this.setState(state => {
              return {
                facilityInfo: null,
                outOfSync: true
              };
            });
            return;
          } else {
            component.refresh(this);
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.PlanOpened,params => {
        try {
          const view = this.props.view;
          if (view && view === params.view) {
            const facilityMode = Context.getInstance().aiim.facilityMode;
            if (facilityMode) {
              facilityMode.applyActiveFacilityInfo(view);
            }
            this.setState(state => {
              return {
                outOfSync: false,
                facilityRowExpanded: true // Issue #1924
              };
            });
          } else if (view && view !== params.view) {
            this.setState(state => {
              return {
                schedSiteUpdate: true,
                schedFacilityUpdate: true,
                facilityInfo: null,
                outOfSync: true
              };
            });
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ReloadViews,params => {
        this.setState(state => {
          return {
            abandon: true
          };
        });
        component.componentWillUnmount(this);
      }),

      Topic.subscribe(Topic.ViewActivated0,params => {
        try {
          const view = this.props.view;
          if (view && view === params.view) {
            if (this.state.outOfSync) {
              const facilityMode = Context.getInstance().aiim.facilityMode;
              if (facilityMode) {
                facilityMode.applyActiveFacilityInfo(view);
              }
              this.setState(state => {
                return {
                  schedSiteUpdate: true,
                  schedFacilityUpdate: true,
                  outOfSync: false,
                  facilityRowExpanded: true // Issue #1924
                };
              });
            }
          }
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ViewActivated,params => {
        if (this.props.view && this.props.view === params.view) {
          //if (!this.supportsInfoPanel()) component.refresh(this);
          component.refresh(this);
        }
      })

    ]);
  }

  componentDidUpdate() {
    if (this.state.considerScrollIntoView) {
      this.scrollIntoView();
      this.setState(state => {
        return {
          considerScrollIntoView: false
        };
      });
    }
  }

  componentWillUnmount() {
    //console.log(">>>>>>>>>>>>>>>>>>>LevelFilter::componentWillUnmount");
    component.componentWillUnmount(this);
  }

  allClicked() {
    Topic.publish(Topic.LevelSelected,{
      facilityId: this.state.facilityInfo.facilityId,
      view: this.props.view
    });
  }

  downClicked() {
    this.stepUpOrDown(false);
  }

  exitClicked() {
    this.setState({
      schedSiteUpdate: true,
      schedFacilityUpdate: true,
      facilityRowExpanded: true // Issue #1924
    })
    Topic.publish(Topic.DeactivateFacilityClicked,{
      facilityInfo: this.state.facilityInfo,
      view: this.props.view
    });
  }

  siteExpanderClicked = (evt) => {
    this.setState({
      schedSiteUpdate: true,
      schedFacilityUpdate: true,
      siteRowExpanded: !this.state.siteRowExpanded
    })
  }

  facilityExpanderClicked = (evt) => {
    this.setState(state => {
      return {
        schedSiteUpdate: true,
        schedFacilityUpdate: true,
        facilityRowExpanded: !state.facilityRowExpanded
      }
    });
  }

  siteSelected = (evt) => {
    const v = evt.props.value;
    const view = Context.instance.views.activeView;
    Topic.publish(Topic.FacilityDeactivated, {view: view})
    if (v === "") {
      this.exitClicked()
      Topic.publish(Topic.SiteDeactivated, {view: view})
    } else {
      const sites = this.getSites()
      const siteIds = sites.siteIds
      const siteData = sites.siteData
      const siteId = siteIds[v]

      if (siteData[v].length > 0) {
        const facilityCache = this.state.activeSiteFacilityCache[siteId]
        let facility, facilityInfo
        facility = facilityCache ? facilityCache : null
        if (facility) {
          facilityInfo = {
            ...facility,
            facilitiesSource: Context.instance.aiim.datasets.facilities.getSource()
          }
        } else {
          facilityInfo = null
        }
        Topic.publish(Topic.SiteActivated, {siteInfo: v, facilityInfo: facilityInfo, view: view})
        this._zoomToSite(siteId)
        if (facilityInfo) {
          const aiim = Context.instance.aiim
          const levels = aiim && aiim.datasets && aiim.datasets.levels;
          const defaultLevel = levels && levels.getZeroVOLevel(facilityInfo);
          const levelCache = this.state.activeLevelData[siteId]
          const activeLevelData = (levelCache) ?
            levelCache :
            (defaultLevel) ?
            defaultLevel :
            null
          if (activeLevelData) {
            Topic.publish(Topic.ActivateLevel,{
              facilityData: facilityInfo,
              levelData: activeLevelData,
              view: view
            });
          }
        }
      }
    }
  }

  facilitySelected = (evt) => {
    const v = evt.props.value;
    if (v === "") {
      this.exitClicked();
    } else {
      this._openFacility(v, true, false);
    }
  }

  facilityInfoClicked = () => {
    if (this.state.facilityInfo) {
      const facilityInfo = this.state.facilityInfo;
      const facilityId = facilityInfo && facilityInfo.facilityId;
      this._openFacility(facilityId,false,false);
    }
  }

  siteInfoClicked = () => {
    if (this.state.siteSelected) {
      const siteSelected = this.state.siteSelected
      const siteIds = this.getSites().siteIds
      const siteId = siteIds[siteSelected]
      this._zoomToSite(siteId)
    }
  }

  _zoomToSite(siteId) {
    //if (true) return;
    const aiim = Context.instance.aiim
    const siteFacilityGeometries = []
    let extent = null;
    aiim.datasets.facilities.queryAll().then(result => {
      result.features.forEach(feature => {
        let featureSiteId = feature.attributes.site_id
        if (!featureSiteId) featureSiteId = feature.attributes.SITE_ID
        if (featureSiteId === siteId) {
          if (feature.geometry && feature.geometry.extent) {
            if (!extent) {
              extent = feature.geometry.extent.clone();
            } else {
              extent = extent.union(feature.geometry.extent.clone())
            }
            //siteFacilityGeometries.push(feature.geometry.extent)
          }
          //siteFacilityGeometries.push(feature.geometry)
        }
      })
      if (extent) siteFacilityGeometries.push(extent);
      else return;

      //Union all geometries to make a 'site extent'
      let engine = Context.instance.lib.esri.geometryEngine
      var siteGeometry = siteFacilityGeometries[0]
      siteFacilityGeometries.forEach(geometry => {
        siteGeometry = engine.union(siteGeometry, geometry)
      })

      const feature = {
        geometry: siteGeometry
      }
      const view = Context.instance.views.activeView
      const inactiveView = Context.instance.views.getInactiveView()
      mapUtil.goToFeature(view, feature)
      Context.instance.aiim.facilityFootprints.removeHighlight()
      mapUtil.clearLocationGraphic(view)
      mapUtil.clearLocationGraphic(inactiveView)
      Topic.publish(Topic.CloseInfoPanel, {})
    })
  }

  _openFacility(facilityId,zoom,publishedFromLevelFilter) {
    const aiim = Context.instance.aiim;
    const facilityInfo = this.state.facilityInfo;
    let source = facilityInfo && facilityInfo.facilitiesSource;
    if (!source) {
      const dataset = aiim && aiim.datasets && aiim.datasets.facilities;
      source = dataset && dataset.getSource();
    }
    if (!source) return;
    if (this.supportsInfoPanel()) {
      const json = {
        sourceKey: source.key,
        uniqueIdField: source.uniqueIdField,
        uniqueId: facilityId
      };
      const item = new ItemReference();
      item.fromJson(json).then(ok => {
        if (ok && item.searchResult) {
          Topic.publish(Topic.ShowSearchResult,{
            sourceKey: item.sourceKey,
            searchResult: item.searchResult,
            zoom: !!zoom,
            highlight: true,
            trackRecent: true,
            publishedFromLevelFilter: publishedFromLevelFilter
          });
        } else {
          console.warn("The facility item is invalid: ",facilityId);
        }
      }).catch(ex => {
        console.error("Error opening facility:",ex);
      });
    } else {
      const view = Context.instance.views.activeView;
      const levels = aiim && aiim.datasets && aiim.datasets.levels;
      const facilityData = levels && levels.getFacilityData(facilityId);
      const levelData = facilityData && levels && levels.getZeroVOLevel(facilityData);
      if (levelData) {
        Topic.publish(Topic.ActivateLevel,{
          view: view,
          facilityData: facilityData,
          levelData: levelData
        });
        if (zoom && view) {
          const footprints = aiim && aiim.facilityFootprints;
          if (footprints && footprints.graphicsByFacilityId) {
            const feature = footprints.graphicsByFacilityId[facilityId];
            if (feature) {
              mapUtil.goToFeature(view, feature);
            }
          }
        }
      }
    }
  }

  getSites() {
    const aiim = Context.instance.aiim;
    const levels = aiim && aiim.datasets && aiim.datasets.levels;
    const facilities = levels && levels.getFacilities();
    const siteData = {}
    const siteIds = {}

    if (!facilities || facilities.length === 0) return null;
    facilities.forEach(facility => {
      let name = facility.siteName
      let id = facility.siteId
      if (name && id) {
        if (siteData[name]) siteData[name].push(facility)
        else siteData[name] = [facility]
        if (!siteIds[name]) siteIds[name] = id
      }
    })

    return { siteData: siteData, siteIds: siteIds }
  }

  render() {
    if (this.state.abandon) return null;
    // const alwaysOn = !this.supportsInfoPanel();
    const alwaysOn = true
    // if (!this.supportsInfoPanel()) {
      if (this.props.view !== Context.instance.views.activeView) {
        return (<div style={{display:"none"}}></div>);
      }
    // }
    const style = {display: "block"};
    const sites = this.getSites();
    const siteData = sites && sites.siteData;
    const siteIds = sites && sites.siteIds;
    const renderSites = (siteIds && Object.keys(siteIds).length <= 1) ? null : this.renderSites(siteIds);
    const facilities = (siteData) ? this.renderFacilities(siteData) : null;
    const levels = this.renderLevels();
    if (facilities && (levels || alwaysOn)) {
      return (
        <div className={CSS.main} style={style}>
          {renderSites}
          {facilities}
          {levels}
          {this.renderExit()}
        </div>
      );
    } else {
      style.display = "none";
      return (<div style={style}></div>);
    };
  }

  renderAll() {
    const i18n = Context.getInstance().i18n;
    const name = i18n.levelFilter.all;
    const key = "k_all";
    const facilityInfo = this.state.facilityInfo;
    const activeLevel =  facilityInfo && facilityInfo.activeLevel;
    const className = activeLevel ? CSS.itemButton : CSS.itemButtonActive;
    return (
      <button key={key} type="button" className={className}
        onClick={this.allClicked}>{name}</button>
    );
  }

  renderExit() {
    const facilityInfo = this.state.facilityInfo;
    const ok = facilityInfo && facilityInfo.levels && facilityInfo.levels.length > 0;
    if (!ok) return;
    const name = Context.getInstance().i18n.levelFilter.exit;
    const key = "k_exit";
    return (
      <button key={key} type="button" className={CSS.clearButton} title={name}
        onClick={this.exitClicked}>
        {Icons.levelFilterExit()}
      </button>
    );
  }

  renderSites(siteIds) {
    const i18n = Context.instance.i18n;
    const facilityInfo = this.state.facilityInfo
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    if (facilityInfo && facilityInfo.siteName && facilityInfo.siteName !== this.state.siteSelected) {
      this.setState({
        siteSelected: facilityInfo.siteName
      })
    }

    let expanderTip = i18n.levelFilter.site;
    let expanderIcon = Icons.urban();
    if (this.state.siteRowExpanded) {
      expanderTip = i18n.levelFilter.collapseSiteSelector;
      if (!Context.instance.uiMode.isRtl) {
        expanderIcon = Icons.chevronsRight();
      } else {
        expanderIcon = Icons.chevronsLeft();
      }
    }
    const expander = (
      <button key="expander" type="button" title={expanderTip}
        className={"i-button-listitem i-button--expander i-sites-expander"}
        onClick={this.siteExpanderClicked}>{expanderIcon}</button>
    );

    const options = [];
    options.push(
      {
        label: i18n.levelFilter.site,
        caption: i18n.levelFilter.site,
        value: ""
      }
    );

    for (var site in siteIds) {
      options.push(
        {
          label: site,
          caption: site,
          value: site
        }
      )
    }

    const menuItems = []
    options.forEach(option => {
      let checked = this.state.siteSelected === option.value
      menuItems.push(
        <MenuOption labelClass={CSS.labelClass} key={option.value} value={option.value} label={option.label} asnOption={option}
          onClick={this.siteSelected} noIcon={true} checked={checked} />
      );
    });

    let caption = i18n.levelFilter.site
    if (this.state.siteSelected && this.state.siteSelected !== "") {
      caption = this.state.siteSelected
    }

    let customMain = CSS.main + " facilities"
    customMain = Context.instance.uiMode.isKiosk ?
      customMain += " kiosk" :
      Context.instance.appMode.isSP() ?
      customMain += " spaceplanner" :
      customMain += " notkiosk"

    const dropdown = (
      <div className={customMain} style={{position: 'inherit', minWidth: '3rem'}}>
        <ChoiceDropdown sitefilter={true} caption={caption} captionClass={CSS.captionClass} arrowClass={CSS.arrowClass}>
          {menuItems}
        </ChoiceDropdown>
      </div>
    );

    let content = null
    if (this.state.siteRowExpanded) {
      let iconClass = "i--building-icon"
      iconClass += Context.instance.uiMode.isRtl ?
        Context.instance.appMode.isSP() ?
        " rtl spaceplanner" :
        " rtl" :
        " ltr"
      content = (
        <div key="content" className="i-levelfilter-facilities i-sites-expander">
          <span className={iconClass} key="site">{Icons.urban()}</span>
          {dropdown}
          {this.renderInfo(true)}
        </div>
      )
    }

    const placement = Context.instance.uiMode.isRtl? "right": "left-start";
    const manager = (
      <div key="sites">
        <Manager>
          <Reference>
            {({ ref }) => (
              <span key="ref" ref={ref}>{expander}</span>
            )}
          </Reference>
          <Popper placement={placement}>
            {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
              if (arrowProps && arrowProps.style && isNaN(arrowProps.style.top)) {
                arrowProps.style.top = 0; // issue with the popper here
              }
              if (Context.instance.uiMode.isRtl && style && style.transform) {
                if (Context.instance.uiMode.isKiosk) {
                  style.top = '-1px'
                  style.left = '0px'
                } else if (Context.instance.appMode.isSP()) {
                  style.top = '-1px'
                  style.left = '0px'
                } else {
                  style.transform = 'translate3d(32px, -1px, 0px)'
                }
              } else if (style && style.transform) {
                if (Context.instance.uiMode.isKiosk) {
                  if (isFirefox) {
                    style.top = '0px'
                  } else {
                    style.top = '-1px'
                  }
                } else if (Context.instance.appMode.isSP()) {
                  if (isFirefox) {
                    //console.log("left",style.left,typeof style.left)
                    if (this.state.siteSelected) {
                      style.top = "0px"
                      style.left = "0px"
                    } else {
                      style.top = "0px"
                      style.left = "1px"
                    }
                  } else {
                    style.top = '-1px'
                    style.left = "0px"
                  }
                } else {
                  if (isFirefox) {
                    style.top = "0px"
                  } else {
                    style.top = '-1px'
                  }
                }
              }

              //scheduleUpdate()
              if (this.state.schedSiteUpdate) {
                //console.log("style.left.sites",style.left)
                scheduleUpdate()
                setTimeout(() => {
                  this.setState({schedSiteUpdate: false})
                },1)
              }

              return (
                <div className={CSS.facilitiesList} ref={ref} style={style} data-placement={placement}>
                  {content}
                  <div ref={arrowProps.ref} style={arrowProps.style}></div>
                </div>
              )
            }}
          </Popper>
        </Manager>
      </div>
    );

    return (
      <div key="sites">
        {manager}
      </div>
    );
  }

  renderFacilities(siteData) {
    const i18n = Context.instance.i18n;
    const aiim = Context.instance.aiim;
    const levels = aiim && aiim.datasets && aiim.datasets.levels;
    const facilities = levels && levels.getFacilities();
    const facilityRowExpanded = this.state.facilityRowExpanded;
    const facilityInfo = this.state.facilityInfo;
    const facilityId = facilityInfo && facilityInfo.facilityId;
    const multipleSites = (siteData && Object.keys(siteData).length > 1)
    if (!facilities || facilities.length === 0) return null;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    let expanderTip = i18n.levelFilter.facility;
    let expanderIcon = Icons.building();
    if (facilityRowExpanded) {
      expanderTip = i18n.levelFilter.collapseFacilitySelector;
      if (!Context.instance.uiMode.isRtl) {
        expanderIcon = Icons.chevronsRight();
      } else {
        expanderIcon = Icons.chevronsLeft();
      }
    }
    const expander = (
      <button key="expander" type="button" title={expanderTip}
        className={"i-button-listitem i-button--expander i-facilities-expander"}
        onClick={this.facilityExpanderClicked}>{expanderIcon}</button>
    );

    let hasFacilities = false;
    const options = [];
    options.push(
      {
        label: i18n.levelFilter.facility,
        caption: i18n.levelFilter.facility,
        value: ""
      }
    );

    facilities.forEach(facilityData => {
      hasFacilities = true;
      let name = facilityData.facilityName;
      let id = facilityData.facilityId;
      if (!this.state.siteSelected) {
        options.push(
          {
            label: name,
            caption: name,
            value: id
          }
        )
      }
      else if (this.state.siteSelected && facilityData.siteName === this.state.siteSelected) {
        options.push(
          {
            label: name,
            caption: name,
            value: id
          }
        )
      }
    });
    if (!hasFacilities) return null;

    const menuItems = [];
    options.forEach(option => {
      let checked = facilityId === option.value
      menuItems.push(
        <MenuOption labelClass={CSS.labelClass} key={option.value} value={option.value} label={option.label} asnOption={option}
          onClick={this.facilitySelected} noIcon={true} checked={checked} />
      );
    });

    let customMain = CSS.main + " facilities"
    customMain = Context.instance.uiMode.isKiosk ?
      customMain += " kiosk" :
      Context.instance.appMode.isSP() ?
      customMain += " spaceplanner" :
      customMain += " notkiosk"

    const caption = (facilityInfo && facilityInfo.zeroVOLevel && facilityInfo.zeroVOLevel.facilityName) || i18n.levelFilter.facility
    const dropdown = (
      <div className={customMain} style={{position: 'inherit', minWidth: '3rem'}}>
        <ChoiceDropdown facilityfilter={true} caption={caption} captionClass={CSS.captionClass} arrowClass={CSS.arrowClass}>
          {menuItems}
        </ChoiceDropdown>
      </div>
    );

    let content = null;
    if (facilityRowExpanded) {
      let iconClass = "i--building-icon"
      iconClass += Context.instance.uiMode.isRtl ?
        Context.instance.appMode.isSP() ?
        " rtl spaceplanner" :
        " rtl" :
        " ltr"
      content= (
        <div key="content" className="i-levelfilter-facilities i-facilities-expander">
          <span className={iconClass} key="bldg">{Icons.building()}</span>
          {dropdown}
          {this.renderInfo(false)}
        </div>
      );
    }

    const placement = Context.instance.uiMode.isRtl? "right": "left-start";
    const manager = (
      <div key="facilities">
        <Manager>
          <Reference>
            {({ ref }) => (
              <span key="ref" ref={ref}>{expander}</span>
            )}
          </Reference>
          <Popper placement={placement}>
            {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
              //console.log("style",style)
              //console.log("arrowProps.style",arrowProps.style)
              if (arrowProps && arrowProps.style && isNaN(arrowProps.style.top)) {
                arrowProps.style.top = 0; // issue with the popper here
              }
              if (style && style.transform) {
                if (Context.instance.uiMode.isRtl) {
                  if (multipleSites) {
                    if (Context.instance.uiMode.isKiosk) {
                      style.top = '49px'
                      style.left = '0px'
                    } else if (Context.instance.appMode.isSP()) {
                      style.top = '33px'
                      style.left = '0px'
                    } else {
                      style.transform = 'translate3d(32px, 33px, 0px)'
                    }
                  } else {
                    style.transform = 'translate3d(32px, 0px, 0px)'
                  }
                } else {
                  if (multipleSites) {
                    if (Context.instance.uiMode.isKiosk) {
                      if (isFirefox) {
                        style.top = '1px'
                        style.left = "0px"
                      } else {
                        style.top = '0px'
                        style.left = "0px"
                      }
                    } else if (Context.instance.appMode.isSP()) {
                      if (isFirefox) {
                        style.top = "1px"
                        style.left = "1px"
                      } else {
                        style.top = "0px"
                        style.left = "0px"
                      }
                    } else {
                      if (isFirefox) {
                        style.top = '1px'
                        style.left = "0px"
                      } else {
                        style.top = '0px'
                        style.left = "0px"
                      }
                    }
                  }
                }
              }

              //scheduleUpdate()
              if (this.state.schedFacilityUpdate) {
                //console.log("style.left.facilities",style.left)
                scheduleUpdate()
                setTimeout(() => {
                  this.setState({schedFacilityUpdate: false})
                },1)
              }

              return (
                //<ResizeAware onResize={scheduleUpdate} onlyEvent style={{ position: 'relative' }}>
                  <div className={CSS.facilitiesList} ref={ref} style={style} data-placement={placement}>
                    {content}
                    <div ref={arrowProps.ref} style={arrowProps.style}></div>
                  </div>
                //</ResizeAware>
              )
            }}
          </Popper>
        </Manager>
      </div>
    );

    return (
      <div key="facilities">
        {manager}
      </div>
    );
  }

  renderInfo(sites) {
    if (!this.supportsInfoPanel() && !sites) return null;
    const i18n = Context.instance.i18n
    const key = "k_info";
    const facilityInfo = this.state.facilityInfo;
    if (sites && this.state.siteSelected) {
      const buttonCSS = Context.instance.appMode.isSP() ? CSS.infoButton + " spaceplanner" : CSS.infoButton
      return (
        <button key={key} type="button" className={buttonCSS} title={i18n.levelFilter.zoomToSite}
          onClick={this.siteInfoClicked}>
          {Icons.zoomToObject24()}
        </button>
      );
    } else if (facilityInfo && facilityInfo.facilitiesSource) {
      return (
        <button key={key} type="button" className={CSS.infoButton} title={i18n.levelFilter.facilityInfo}
          onClick={this.facilityInfoClicked}>
          {Icons.levelFilterInfo()}
        </button>
      );
    }
    return null;
  }

  renderLevel(view,facilityInfo,levelData,index) {
    const key = "k_"+index+"_"+levelData.levelId;
    const isFirst = (index === 0);
    return (
      <li key={key}>
        <LevelButton view={view} facilityInfo={facilityInfo} levelData={levelData}
          isFirst={isFirst}/>
      </li>
    );
  }

  renderLevels() {
    const i18n = Context.instance.i18n;
    const key = "levels";
    const view = this.props.view;
    const facilityInfo = this.state.facilityInfo;
    if (facilityInfo && facilityInfo.levels && facilityInfo.levels.length > 0) {

      let items = facilityInfo.levels.map((levelData,index) => {
        return this.renderLevel(view,facilityInfo,levelData,index);
      });
      if (items && items.length > 1) {
        items = items.slice().reverse();
      }

      let all = null;
      if (items.length > 1 && view && view.type === "3d") {
        all = this.renderAll();
      }

      let up = null, down = null;
      let isTouch = Context.getInstance().uiMode.isTouch;
      if (!isTouch && items.length > 3) {
        up = (
          <button key="k_up" type="button" className={CSS.upButton}
            onClick={this.upClicked}>{Icons.levelFilterUp()}</button>
        );
        down = (
          <button key="k_dn" type="button" className={CSS.downButton}
            onClick={this.downClicked}>{Icons.levelFilterDown()}</button>
        );
      }

      return (
        <div key={key}>
          {up}
          <ul className={CSS.list} aria-label={i18n.levelFilter.levelsAriaLabel}
            >{items}</ul>
          {down}
          {all}
        </div>
      );
    }
    return null;
  }

  scrollIntoView() {
    const domNode = this.props.domNode;
    if (domNode) {
      let levelNode = domNode.querySelector("li > .i--active");
      if (!levelNode) levelNode = domNode.querySelector("li > .i--first");
      if (levelNode) {
        //console.log("LevelFilter::scrollIntoView",levelNode);
        levelNode.scrollIntoView({block: "end", behavior: "smooth"});
      }
    }
  }

  stepUpOrDown(up) {
    const domNode = this.props.domNode;
    if (domNode) {
      const ulNode = domNode.querySelector("ul");
      const liNodes = domNode.querySelectorAll("ul > li");
      if (ulNode && liNodes && liNodes.length > 1) {
        const step = ulNode.scrollHeight / liNodes.length;
        if (up) {
          ulNode.scrollTop = ulNode.scrollTop - step;
        } else {
          ulNode.scrollTop = ulNode.scrollTop + step;
        }
      }
    }
  }

  supportsInfoPanel() {
    return Context.instance.appMode.supportsInfoPanel();
  }

  upClicked() {
    this.stepUpOrDown(true);
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class LevelButton extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    //console.log("Clicked");
    let facilityInfo = this.props.facilityInfo;
    let levelData = this.props.levelData;
    Topic.publish(Topic.LevelSelected,{
      facilityId: facilityInfo.facilityId,
      levelData: levelData,
      view: this.props.view
    });
    Topic.publish(Topic.FacilityCache, {view: this.props.view, facilityInfo: facilityInfo, levelData: levelData})
    component.refresh(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    let facilityInfo = this.props.facilityInfo;
    let levelData = this.props.levelData;
    let name = levelData.levelShortName;
    let className = CSS.itemButton;
    let activeLevel =  facilityInfo && facilityInfo.activeLevel;
    if (!activeLevel && this.props.view && this.props.view.type === "2d") {
      activeLevel =  facilityInfo && facilityInfo.zeroVOLevel;
    }
    if (activeLevel === levelData) className = CSS.itemButtonActive;
    if (this.props.isFirst === 0) className += " i--first";
    const ariaLabel = i18n.levelFilter.floorPattern.replace("{name}",name);
    return (
      <button type="button" className={className} aria-label={ariaLabel}
        onClick={this.clicked}>{name}</button>
    );
  }
}
