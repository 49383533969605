import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import OfficePlan, { SpaceAssignmentTypes } from "./OfficePlan";
//import Reader from "./Reader";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import * as selectionUtil from "../../aiim/util/selectionUtil";
import * as sourceUtil from "./sourceUtil";
import { getAttributes } from "../components/common/MultipleAssignments/multipleAssignmentsUtil";
import { AreaTypes } from "../../util/interfaces";
import type { CustomQueryTask } from "../../context/EsriLib";

/*
  Transactions

    assignPeopleToArea
    assignPeopleToUnit
    unassignPeople

    assignUnitsToArea
    unassignUnits

    addArea
    deleteArea
    renameArea
    unassignArea

 */

//const TIMEOUT = (60000 * 30);

const unitBasedFieldsForPeople = [
  "site_id","site_name",
  "facility_id","facility_name",
  "level_id","level_name","level_number","vertical_order",
  "unit_id","unit_name",
  "section_id","section_name",
  "access_type","use_type",
  "capacity","utilization",
  "area_gross","area_net","area_um",
  "elevation_absolute","elevation_relative",
  "height_absolute","height_relative",
  "name_long","name_subtitle","description"
];

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export function applyGdbVersion(layer,editOptions) {
  if (layer && layer.gdbVersion && editOptions) {
    editOptions.gdbVersion = layer.gdbVersion;
  }
}
/** Determines the workspace area type based on currently assigned units.
 * @param areaId - An existing `AREA_ID` value
 * @returns An `AreaType` value based on the mixture of assigned units types.
 */
export async function describeWorkspaceArea(areaId: string): Promise<AreaTypes> {
  const source = getUnitsSource();
  const layer = source.layer2D;
  const idField = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_AREA_ID);
  const typeField = aiimUtil.findFieldName(layer.fields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const types = ["hotel", "hotdesk", "meeting room"];
  let where = makeWhereForUnitsAssignedToArea(areaId);
  where += ` AND (${typeField} IN (${types.map(t => `'${t}'`).join(",")}))`;
  const lib = Context.instance.lib;
  const url = Context.checkMixedContent(source.url);
  const task: CustomQueryTask = new lib.esri.QueryTask({url: url});
  const query: __esri.Query = new lib.esri.Query();
  query.returnGeometry = false;
  query.returnDistinctValues = true;
  query.outFields = [idField, typeField];
  query.where = where;
  const result = await task.execute(query);
  if (result?.features?.length === 1) {
    const assignmentType = aiimUtil.getAttributeValue(result.features[0].attributes, typeField);
    return assignmentType === SpaceAssignmentTypes.meetingroom ? "workspace" : assignmentType;
  } else {
    return "workspace";
  }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export function addAttributeEdit(fields,attributes,name,value) {
  const f = aiimUtil.findField(fields,name);
  if (f) attributes[f.name] = value;
}

export function getPeopleSource() {
  return sourceUtil.getPeopleSource();
}

export function getUnitsSource() {
  return sourceUtil.getUnitsSource();
}

export function getPersonAssignmentName(personFeatureItem) {
  const attributes = personFeatureItem.feature.attributes;
  const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_ID);
  const areaId = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_AREA_ID);
  const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
  const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
  if (hasUnitId) {
    let v;
    const plan = OfficePlan.getActive();
    if (plan && plan.unitNameCache && plan.unitNameCache.namesPerUnitId) {
      v = plan.unitNameCache.namesPerUnitId[unitId];
      if (v) return v;
    }
    v = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_NAME);
    if (v) return v;
    return unitId;
  } else {
    if (hasAreaId) {
      return OfficePlan.getActive().getAreaName(areaId);
    } else {
      return Context.instance.i18n.spaceplanner.people.noAssignment;
    }
  }
}

export async function getPersonAssignmentNameAsync(personFeatureItem) {
  const attributes = personFeatureItem.feature.attributes;
  const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_ID);
  const areaId = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_AREA_ID);
  const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
  const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
  if (hasUnitId) {
    let v = await queryUnitName(unitId)
    if (!v) v = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_NAME);
    if (!v) v = unitId;
    return v;
  } else {
    if (hasAreaId) {
      return OfficePlan.getActive().getAreaName(areaId);
    } else {
      return Context.instance.i18n.spaceplanner.people.noAssignment;
    }
  }
}

export function getUnitAssignmentType(unitFeatureItem) {
  const attributes = unitFeatureItem.feature.attributes;
  return aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
}

export function getUnitAssignmentName(unitFeatureItem) {
  // TODO validate the FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE
  const msg = "Inconsistant space assignment type for unit:";
  const attributes = unitFeatureItem.feature.attributes;
  const asnType = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const areaId = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_AREA_ID);
  const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
  //console.log("asnType",asnType,"hasAreaId",hasAreaId,unitFeatureItem.feature.attributes);
  if (hasAreaId) {
    if (asnType !== OfficePlan.SpaceAssignmentTypes.hotdesk &&
        asnType !== OfficePlan.SpaceAssignmentTypes.hotel &&
        asnType !== OfficePlan.SpaceAssignmentTypes.meetingroom &&
        asnType !== OfficePlan.SpaceAssignmentTypes.home) {
      console.warn(msg,unitFeatureItem);
    }
    return OfficePlan.getActive().getAreaName(areaId);
  } else if (asnType === OfficePlan.SpaceAssignmentTypes.office) {
    // validate that people are actually assigned?
    return Context.instance.i18n.spaceplanner.units.occupied;
  } else if (asnType === OfficePlan.SpaceAssignmentTypes.none) {
    return Context.instance.i18n.spaceplanner.unassigned;
  } else if (asnType === OfficePlan.SpaceAssignmentTypes.notAssignable) {
    return Context.instance.i18n.spaceplanner.units.notAssignable;
  }
}

export function isPersonAssignedToUnit(featureItem) {
  const unitId = aiimUtil.getAttributeValue(featureItem.feature.attributes,FieldNames.UNIT_ID);
  const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
  return hasUnitId;
}

export function isPersonUnassigned(featureItem) {
  const unitId = aiimUtil.getAttributeValue(featureItem.feature.attributes,FieldNames.UNIT_ID);
  const areaId = aiimUtil.getAttributeValue(featureItem.feature.attributes,FieldNames.PEOPLE_AREA_ID);
  const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
  const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
  return !(hasUnitId || hasAreaId);
}

export function isPersonPlaceholder(featureItem) {
  const email = aiimUtil.getAttributeValue(featureItem.feature.attributes, FieldNames.PEOPLE_EMAIL);
  return email && email.startsWith('placeholder');
}

export function isUnitUnassigned(featureItem) {
  const attributes = featureItem.feature.attributes;
  const asnType = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const areaId = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_AREA_ID);
  const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
  return !(hasAreaId || (asnType && asnType !== OfficePlan.SpaceAssignmentTypes.none));
}

export function getPeople(unitFeatureItems) {
  let where = "";
  const unitsLayer = sourceUtil.getUnitsSource().layer2D;
  const peopleLayer = sourceUtil.getPeopleSource().layer2D;
  const fldA = aiimUtil.findField(unitsLayer.fields,FieldNames.UNIT_ID);
  const fldB = aiimUtil.findField(peopleLayer.fields,FieldNames.PEOPLE_UNIT_ID);
  const nameFld = aiimUtil.findField(peopleLayer.fields,FieldNames.PEOPLE_FULLNAME);

  const values = [];
  unitFeatureItems.forEach((featureItem,i) => {
    let v = aiimUtil.getAttributeValue(featureItem.attributes,fldA.name);
    v = "'" + selectionUtil.escSqlQuote(v) + "'";
    values.push(v);
  });
  if (values.length > 0) where = fldB.name + " IN (" + values.join(",") + ")";
  else return Promise.resolve({features: []});

  if (where.length > 0) {
    const options = {
      source: getPeopleSource(),
      where: where,
      orderByFields: [nameFld.name],
      outFields: ["*"],
      returnGeometry:true,
      returnZ:true
    }
    return queryUtil.queryFeatures(options).then(result => {
      if (result && result.features) {
        return result;
      }else{
        return ({features:[]});
      }
    });
  }
}

export function makeWhereForPeopleAssignedToArea(areaItem,areaType?) {
  const areaId = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREA_ID);
  const source = getPeopleSource();
  const layer = source.layer2D;
  const areaIdField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_AREA_ID);
  const v = selectionUtil.escSqlQuote(areaId);
  const where = "("+areaIdField.name+" = '"+v+"')";
  return where;
}

export function makeWhereForUnitsAssignedToArea(areaItemOrId: __esri.Graphic | string, areaType?: AreaTypes): string {
  const areaId = typeof areaItemOrId === "object"
    ? aiimUtil.getAttributeValue(areaItemOrId.attributes, FieldNames.AREA_ID)
    : areaItemOrId;
  const source = getUnitsSource();
  const layer = source.layer2D;
  const areaIdField = aiimUtil.findField(layer.fields,FieldNames.UNITS_AREA_ID);
  const asnTypeField = aiimUtil.findField(layer.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  let v1 = selectionUtil.escSqlQuote(areaId), v2 = "";
  v1 = "(" + areaIdField.name + " = '" + v1 + "')";
  if (areaType != null) {
    v2 = selectionUtil.escSqlQuote(areaType);
    v2 = "(" + asnTypeField.name + " = '" + v2 + "')";
  }
  const where = areaType != null ? v1 + " AND " + v2 : v1;
  return where;
}

export async function queryDetailByObjectId(objectId) {
  const layer = sourceUtil.getDetailsLayer();
  const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
  const lib = Context.getInstance().lib;
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.outFields = ["*"]
  query.returnGeometry = true;
  query.returnZ = true;
  query.objectIds = [objectId];
  queryUtil.applyGdbVersion(layer, query);
  return task.execute(query).then(r => {
    if (r && r.features && r.features.length === 1) {
      return r.features[0];
    }
    return null;
  })
}

export async function queryOccupantAreaIDs(occupantFeature) {
  const areaIds = [];
  const source = getPeopleSource();
  const layer = source.layer2D;
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);
  const areaIdField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_AREA_ID);
  const email =  occupantFeature.attributes[emailField];
  if (email) {
    const where = `${emailField} = '${selectionUtil.escSqlQuote(email)}'`;
    const options = {
      source: source,
      where: where
    };
    const result = await queryUtil.queryFeatures(options);
    if (result && result.features) {
      result.features.forEach(f => {
        const areaId = f.attributes[areaIdField];
        if (areaId && areaIds.indexOf(areaId) === -1) areaIds.push(areaId);
      })
    }
  }
  return areaIds;
}

export async function queryOccupantUnitIDs(occupantFeature) {
  const unitIds = [];
  const source = getPeopleSource();
  const layer = source.layer2D;
  const emailField = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_EMAIL);
  const unitIdield = aiimUtil.findFieldName(layer.fields,FieldNames.PEOPLE_UNIT_ID);
  const email =  occupantFeature.attributes[emailField];
  if (email) {
    const where = `${emailField} = '${selectionUtil.escSqlQuote(email)}'`;
    const options = {
      source: source,
      where: where
    };
    const result = await queryUtil.queryFeatures(options);
    if (result && result.features) {
      result.features.forEach(f => {
        const unitId = f.attributes[unitIdield];
        if (unitId && unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
      })
    }
  }
  return unitIds;
}

export function queryPeopleAssignedToArea(areaItem,areaType?) {
  const source = getPeopleSource();
  const layer = source.layer2D;
  const orderByField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_FULLNAME);
  const where = makeWhereForPeopleAssignedToArea(areaItem,areaType);
  const options = {
    source: source,
    where: where,
    orderByFields: [orderByField.name]
  };
  return queryUtil.queryFeatures(options).then(result => {
    if (result && result.features) {
      const resultInfo = queryUtil.makeResultInfo(source,result);
      return resultInfo.featureItems;
    } else {
      return [];
    }
  });
}

export async function queryPeopleAssignedToUnit(unitFeatureItem) {
  const unitIdField = FieldNames.UNIT_ID;
  const unitId = aiimUtil.getAttributeValue(unitFeatureItem.feature.attributes,unitIdField);
  const source = getPeopleSource();
  const v = selectionUtil.escSqlQuote(unitId);
  const where = "("+unitIdField+" = '"+v+"')";
  const options = {
    source: source,
    where: where,
    orderByFields: [FieldNames.PEOPLE_FULLNAME],
    returnGeometry: true
  }
  return queryUtil.queryFeatures(options).then(result => {
    if (result && result.features) {
      const resultInfo = queryUtil.makeResultInfo(source,result);
      return resultInfo.featureItems;
    } else {
      return [];
    }
  });
}

export function queryPeopleNamesAssignedToUnits(unitFeatureItems) {
  let where = "";
  const unitsLayer = sourceUtil.getUnitsSource().layer2D;
  const peopleLayer = sourceUtil.getPeopleSource().layer2D;
  const fldA = aiimUtil.findField(unitsLayer.fields,FieldNames.UNIT_ID);
  const fldB = aiimUtil.findField(peopleLayer.fields,FieldNames.PEOPLE_UNIT_ID);
  const nameFld = aiimUtil.findField(peopleLayer.fields,FieldNames.PEOPLE_FULLNAME);

  const values = [];
  unitFeatureItems.forEach((featureItem,i) => {
    let v = aiimUtil.getAttributeValue(featureItem.feature.attributes,fldA.name);
    v = "'" + selectionUtil.escSqlQuote(v) + "'";
    values.push(v);
  });
  if (values.length > 0) where = fldB.name + " IN (" + values.join(",") + ")";

  if (where.length > 0) {
    const options = {
      source: getPeopleSource(),
      where: where,
      orderByFields: [nameFld.name],
      outFields: [nameFld.name]
    }
    return queryUtil.queryFeatures(options).then(result => {
      const names = [];
      if (result && result.features) {
        result.features.forEach(f => {
          let v = aiimUtil.getAttributeValue(f.attributes,nameFld.name);
          names.push(v);
        });
      }
      return {
        names: names,
        exceededTransferLimit: result.exceededTransferLimit
      }
    });
  }
}

export async function queryUnitName(unitId) {
  const layer = sourceUtil.getUnitsLayer();
  const unitIdField = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
  const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
  const lib = Context.getInstance().lib;
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.outFields = ["*"]
  query.returnGeometry = true;
  query.returnZ = true;
  const v = "'"+selectionUtil.escSqlQuote(unitId)+"'";
  const w = "("+unitIdField+" = "+v+")";
  query.where = w
  queryUtil.applyGdbVersion(layer, query);
  return task.execute(query).then(r => {
    if (r && r.features && r.features.length > 0) {
      const field = Context.instance.aiim.getUnitName();
      return aiimUtil.getAttributeValue(r.features[0].attributes,field);
    }
    return null;
  })
}

export async function queryUnitByObjectId(objectId) {
  const layer = sourceUtil.getUnitsLayer();
  const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
  const lib = Context.getInstance().lib;
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.outFields = ["*"]
  query.returnGeometry = true;
  query.returnZ = true;
  query.objectIds = [objectId];
  queryUtil.applyGdbVersion(layer, query);
  return task.execute(query).then(r => {
    if (r && r.features && r.features.length === 1) {
      return r.features[0];
    }
    return null;
  })
}

export async function queryUnitByUnitId(unitId) {
  const layer = sourceUtil.getUnitsLayer();
  const unitIdField = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
  const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
  const lib = Context.getInstance().lib;
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  query.outFields = ["*"]
  query.returnGeometry = true;
  query.returnZ = true;
  const v = "'"+selectionUtil.escSqlQuote(unitId)+"'";
  const w = "("+unitIdField+" = "+v+")";
  query.where = w
  queryUtil.applyGdbVersion(layer, query);
  return task.execute(query).then(r => {
    if (r && r.features && r.features.length > 0) {
      return r;
    }
    return null;
  })
}

export function queryUnitsAssignedToArea(areaItemOrId: __esri.Graphic | string, areaType?: AreaTypes) {
  const source = getUnitsSource();
  const layer = source.layer2D;
  const orderByField = aiimUtil.findField(layer.fields, FieldNames.NAME);
  const where = makeWhereForUnitsAssignedToArea(areaItemOrId, areaType);
  const options = { source, where, orderByFields: [orderByField.name] };
  return queryUtil.queryFeatures(options).then(result => {
    if (result && result.features) {
      const resultInfo = queryUtil.makeResultInfo(source, result);
      return resultInfo.featureItems;
    } else {
      return [];
    }
  });
}

export function isUnitFeature(feature) {
  const attributes = getAttributes(feature);
  const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID);
  console.log(areaId, !!!areaId)
  return !!!areaId;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export function appendPersonUnassignment(task,peopleFields,peopleUpdate) {
  if (task.isVersioned === undefined) {
    const p = Context.instance.spaceplanner.planner;
    if (p && p.project) {
      task.isVersioned = !!p.project.isVersioned;
    }
  }
  addAttributeEdit(peopleFields,peopleUpdate.attributes,
    FieldNames.PEOPLE_AREA_ID,null);
  // addAttributeEdit(peopleFields,peopleUpdate.attributes,
  //   FieldNames.PEOPLE_SPACE_ASSIGNMENT_TYPE,
  //   OfficePlan.SpaceAssignmentTypes.none);
  unitBasedFieldsForPeople.forEach(f => {
    if(f === FieldNames.SITE_ID || f === FieldNames.SITE_NAME) {}
    else {addAttributeEdit(peopleFields,peopleUpdate.attributes,f,null);}
  });
  const pt = new Context.instance.lib.esri.Point({wkid: 4326});
  pt.x = pt.y = null;
  if (task.isVersioned) {
    // TODO?
    pt.x = pt.y = 0; pt.z = 0;
  }
  peopleUpdate.geometry = pt;
}

export function checkUpdateResult(result) {
  const checked: { error?: any } = {};
  const updateResults = result && result.updateFeatureResults;
  if (Array.isArray(updateResults)) {
    updateResults.some(ur => {
      if (ur.error) {
        ur.error.submessage = ur.error.message;
        checked.error = ur.error;
        return true;
      }
      return false;
    });
  }
  return checked;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
