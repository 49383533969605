import BaseClass from "../util/BaseClass";
import Topic from "./Topic";
import Context from "./Context";

export default class ScreenSplit extends BaseClass {
    static MODE_HORIZONTAL_SPLIT = "i-mode-horizontal-split";
    static MODE_VERTICAL_SPLIT = "i-mode-vertical-split";
    static MODE_NORMAL = "i-mode-normal";

    setHSplit = () => {
      const elBody = document.body;
      Context.instance.uiMode.mode = ScreenSplit.MODE_HORIZONTAL_SPLIT;
      elBody.classList.add(ScreenSplit.MODE_HORIZONTAL_SPLIT);
      elBody.classList.remove(ScreenSplit.MODE_VERTICAL_SPLIT,ScreenSplit.MODE_NORMAL);
      Topic.publish(Topic.UIModeSet,{});
    };

    setVSplit = () => {
      const elBody = document.body;
      Context.instance.uiMode.mode = ScreenSplit.MODE_VERTICAL_SPLIT;
      elBody.classList.add(ScreenSplit.MODE_VERTICAL_SPLIT);
      elBody.classList.remove(ScreenSplit.MODE_HORIZONTAL_SPLIT,ScreenSplit.MODE_NORMAL);
      Topic.publish(Topic.UIModeSet,{});
    };

    setNormal = () => {
      const elBody = document.body;
      Context.instance.uiMode.mode = ScreenSplit.MODE_NORMAL;
      elBody.classList.add(ScreenSplit.MODE_NORMAL);
      elBody.classList.remove(ScreenSplit.MODE_VERTICAL_SPLIT,ScreenSplit.MODE_HORIZONTAL_SPLIT);
      Topic.publish(Topic.UIModeSet,{});
    };
}
