import React from "react";

const CSS = {
  sidebarButton2: "i-sidebar-button2"
};

export default class SidebarButton2 extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    if (this.props.clickHandler) this.props.clickHandler(event);
  }

  render() {
    const icon = "libs/calcite-ui-icons/icons/sprite-16.svg#" + this.props.iconClassName;
    return (
      <li>
        <button className={CSS.sidebarButton2}
          title={this.props.tooltip} onClick={this.clicked}>
        <svg className="icon"><use href={icon}></use></svg>
        </button>
      </li>
    );
  }

}
