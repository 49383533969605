import React from "react";

import CalciteModal from "calcite-react/Modal";

export default class Modal extends React.Component {

  elModal;

  constructor(props) {
    super(props);
    this.elModal = document.createElement("div");
    this.state = {
      open: true
    }
    this.close = this.close.bind(this);
  }

  componentDidMount () {
    if (typeof this.props._onMount === "function") {
      this.props._onMount(this);
    } else if (typeof this.props.onMount === "function") {
      this.props.onMount(this);
    }
  }

  componentWillUnmount() { }

  close() {
    if (typeof this.props._onClose === "function") {
      this.props._onClose(this);
    } else if (typeof this.props.onClose === "function") {
      this.props.onClose(this);
    }
    this.setState({
      open: false
    });
  }

  render() {
    if (!this.state.open) return null;
    //console.log("CalciteModal",this.props);
    return (
      <CalciteModal {...this.props}
        open={this.state.open}
        showClose={true}
        onRequestClose={this.close}>
        {this.props.children}
      </CalciteModal>
    );
  }

}
