import React, { CSSProperties } from "react";
import ReactDOM from "react-dom";
import moment from 'moment';
import { Provider, connect } from "react-redux";

import Context from "../../../../../context/Context";
import SidebarHeader from "../../../Sidebar/SidebarHeader";
import Rdx, { IViewerState, setValue } from "../../../../../redux/Rdx";
import Topic from "../../../../../context/Topic";
import Icons from "../../../../util/Icons";
import DurationFilters from "./DurationFilters";
import ReserveFor from "./ReserveFor";
import SpaceFilters from "./SpaceFilters";
import * as component from "../../../../util/component";
import { getLoggedInUser, login, checkAvailability } from "./WorkspaceReservation/OfficeHotelingInterface";
import Loader from 'calcite-react/Loader/Loader';
import { goToFeature } from "../../../../../util/mapUtil";
import { ModalController } from "../../../../../common/components/Modal";
import { getAttributes } from "../../../../../spaceplanner/components/common/MultipleAssignments/multipleAssignmentsUtil";
import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../../aiim/datasets/FieldNames";

import Button from "calcite-react/Button";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import AreasTable from "../../../../../spaceplanner/base/AreasTable";

// Workspace Reservation Util
import {
  getHotelBookingSystem,
  getHotelBookingSystemType,
  shouldShowHotelTabs,
  shouldSortHotels,
  sortByDistance,
  formatRecurringDates
} from "./WorkspaceReservation/workspaceReservationUtil";
import {
  hideReservationsByFilter,
  showReservationsByFilter,
  setReservationsDefExp,
  dropUnitPins
} from "./WorkspaceReservation/reservationsLayerUtil";
import { stringFormatter } from "../../../../../util/formatUtil";
import DropdownButton from "../../../../common/Dropdown/DropdownButton";

import SortAscIcon from "calcite-ui-icons-react/SortAscendingArrowIcon";
import SortDescIcon from "calcite-ui-icons-react/SortDescendingArrowIcon";
import FilterIcon from "calcite-ui-icons-react/FilterIcon";
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import * as bookWorkspaceUtil from "./BookWorkspaceUtil";
import * as validateUtil from "./validateUtil";
import * as equipmentFilterUtil from "../../../../../../src/aiim/util/equipmentFilterUtil";
import AvailableUnitCard from "./AvailableUnitCard";
import { isNetworkError } from "../../../../../util/networkUtil";
import BookingsTab from "./BookingsTab";
import MyBookings from "./MyBookings";
import { formatDateFilter, isAllDay } from "../../../Events/dateUtil";
import MyBookingsOffice365 from "./MyBookingsOffice365";
import BookingDateFilter, { IBookingDateFilter } from "../../../Events/BookingDateFilter";
import { Dispatch } from "redux";
import { IRecurrenceOptions } from "./BookingRecurrence";
import { ICheckAvailabilityParams, IReserveForInfo } from "./WorkspaceReservation/BookingSystem";
import { makeWhere } from "./whereUtil";
import ChevronRightIcon from "calcite-ui-icons-react/ChevronRightIcon";
import ChevronLeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import { ScheduleInformation } from "@microsoft/microsoft-graph-types";

const CSS = {
  optionsContainer: "i-hotel-list-filters i-events-options-container",
  optionsContainerManager: "i-events-options-for-manager",
  options: "i-events-options",
  filterButton: "i-filter-button",
  ddButton: "i-dd-button",
  units: "i-available-units",
  description: "i-filter-description",
  sidebarPanelOpen: "i-sidebar-panel-open",
  unitsList: "i-available-units-list",
  hotelscroll: "i-hotelscroll",
  hotels: "i-hotels",
  hotelTabs: "i-hotels-tabs",
  hotelTab: "i-hotel-tab",
  hotelContent: "i-hotel-content",
  filterForm: "i-filter-form",
  findHotelsButton: "i-find-hotels-button",
  date: "i-events-date",
  dateLabel: "i-events-date-label",
  dateRange: "i-events-date-range",
  dateRangeBtn: "i-events-date-range-button"
}
//let COUNTER = 0

const TABS = {
  available: "available",
  booked: "booked",
  others: "others"
};
export interface IDateCriteria {
  dateSelected: Date,
  dateOption: string,
  countWeek?: number,
  countMonth?: number
}
interface IBookWorkspaceProps {
  morePanel?: boolean,
  rdxSidebarActiveKey: string,
  rdxCheckIn: IViewerState["BOOK_CHECKIN"],
  rdxCheckOut: IViewerState["BOOK_CHECKOUT"],
  rdxStartDate: IViewerState["BOOK_DATEFILTER_STARTSELECTED"],
  rdxEndDate: IViewerState["BOOK_DATEFILTER_ENDSELECTED"],
  rdxStartTime: IViewerState["BOOK_TIMEFILTER_STARTSELECTED"],
  rdxEndTime: IViewerState["BOOK_TIMEFILTER_ENDSELECTED"],
  rdxAllDay: IViewerState["BOOK_ALLDAY"],
  rdxSiteFilters: IViewerState["SITE_FILTERS"],
  rdxSpaceTypeFilters: IViewerState["SPACETYPE_FILTERS"],
  rdxAreaFilters: IViewerState["AREA_FILTERS"],
  rdxEquipmentFilters: IViewerState["EQUIPMENT_FILTERS"],
  rdxFilteredResults: IViewerState["BOOK_FILTERED_RESULTS"],
  rdxFilteredResultsNoEmail: IViewerState["BOOK_FILTERED_RESULTS_NO_EMAIL"],
  rdxSortSortBy: IViewerState["SORT_SORTBY"],
  rdxSortSortDir: IViewerState["SORT_SORTDIR"],
  rdxWasSortInit: IViewerState["BOOK_WAS_SORT_INIT"],
  rdxDateOption: IViewerState["BOOK_DATEFILTER_OPTION"],
  rdxDateSelected: IViewerState["BOOK_DATEFILTER_DATESELECTED"],
  rdxTabSelected: IViewerState["BOOK_TAB_SELECTED"],
  rdxRecurrence: IRecurrenceOptions,
  setRdxValue: (key: string, value: any) => void,
  sidebarKey: string
}
interface IBookWorkspaceState {
  activeTab: string,
  areaFilters: string[],
  areas: __esri.Graphic[],
  bookingDateFilter: IBookingDateFilter
  equipmentFilters: string[],
  findDisabled: boolean,
  isQuerying: boolean,
  isWorking: boolean,
  noFilters: boolean,
  noUnitsAtAllMsg: string,
  reserveForEmail: string,
  reserveForInfo: IReserveForInfo,
  reserveForOn: boolean,
  reserveForToggling: boolean,
  siteFilters: string[],
  spaceTypeFilters: string[],
  type: "self" | "others",
  units: __esri.Graphic[]
}
class BookWorkspace extends React.Component<IBookWorkspaceProps, IBookWorkspaceState> {
  _mounted = false
  listRef = React.createRef<AvailableUnitCard>()
  originalScheduleEmailObjs = [];
  hotelEquipmentFields: boolean | string[] = [];
  where = null;
  countWeek = 0;
  countMonth = 1;

  constructor(props) {
    super(props)
    const hasFilters = false;
    this.state = component.newState({
      isWorking: true,
      isQuerying: hasFilters,
      areaFilters: [],
      siteFilters: [],
      spaceTypeFilters: [],
      equipmentFilters: [],
      units: [],
      areas: [],
      noFilters: !hasFilters,
      activeTab: TABS.available,
      noUnitsAtAllMsg: null,
      reserveForOn: false,
      reserveForToggling: false,
      reserveForEmail: null,
      reserveForInfo: null,
      bookingDateFilter: null,
      findDisabled: false
    })
  }

  componentWillUnmount() {
    if (Context.instance.aiim.reserveForInfo) {
      Context.instance.aiim.reserveForInfo = null;
      Context.instance._loadHotelingUnits();
      Context.instance._loadAreas();
      this.resetSpaceFilterRdxValues();
    }
    this._mounted = false;
    this.props.setRdxValue(Rdx.Keys.RESERVE_FOR_OTHERS, false);
    this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION, 'any');
    component.componentWillUnmount(this)
  }

  resetSpaceFilterRdxValues() {
    this.props.setRdxValue(Rdx.Keys.DEFAULT_AREA, null)
  }

  setDropdownValues =(units,areas)=> {
    const layer2D = Context.getInstance().aiim.datasets.units &&
                        Context.getInstance().aiim.datasets.units.layer2D;
    // const areas = this.state.areas;
    this.hotelEquipmentFields = equipmentFilterUtil.getEquipmentFields("DOM_EQUIPMENT_HOTEL");
    let spaceTypeFilters = []
    let siteFilters = []
    let equipmentFilters = []
    const areasWithHotels = {};
    units.forEach(unit => {
      const unitAttributes = getAttributes(unit);
      const spaceType = getAttributeValue(unitAttributes, FieldNames.UNITS_USE_TYPE);
      const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
      const levelId = levelIdField && getAttributeValue(unitAttributes,levelIdField.name);
      const siteName = bookWorkspaceUtil.getSiteName(levelId);
      if (!spaceTypeFilters.includes(spaceType)) spaceTypeFilters.push(spaceType);
      if (!siteFilters.includes(siteName)) siteFilters.push(siteName);
      (this.hotelEquipmentFields as string[]).forEach((fieldName)=> {
        const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
        if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
          if(!equipmentFilters.includes(fieldName))equipmentFilters.push(fieldName);
        }
      })
      const areaId = getAttributeValue(unitAttributes, FieldNames.AREA_ID);
      const asnType = getAttributeValue(unitAttributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
      if (areaId && asnType === "hotel") areasWithHotels[areaId] = true;
    })
    if(spaceTypeFilters) this.props.setRdxValue(Rdx.Keys.SPACETYPES_DROPDOWN, spaceTypeFilters);
    if(siteFilters) this.props.setRdxValue(Rdx.Keys.SITE_DROPDOWN, siteFilters);
    if(equipmentFilters) this.props.setRdxValue(Rdx.Keys.EQUIPMENT_DROPDOWN, equipmentFilters);
    let areaFilters = []
    areas.forEach(area => {
      const areaAttributes = getAttributes(area);
      const name = getAttributeValue(areaAttributes, FieldNames.AREA_NAME)
      const type = getAttributeValue(areaAttributes, FieldNames.AREA_TYPE)
      const areaId = getAttributeValue(areaAttributes, FieldNames.AREA_ID);
      if (!areaFilters.includes(name) && ((type === "hotel") || (type === "hotdesk") || (type === "workspace"))) {
        if (areasWithHotels[areaId]) areaFilters.push(name)
      }
    })
    this.setState({
      isWorking: false,
      siteFilters: siteFilters,
      spaceTypeFilters: spaceTypeFilters,
      areaFilters: areaFilters,
      equipmentFilters: equipmentFilters,
      units: units,
      areas: areas
    })
  }

  initFilters=()=>{
    if (!Context.instance.hotelingUnits) {
      this.setState({
        isWorking: false,
        siteFilters: [],
        spaceTypeFilters: [],
        areaFilters: [],
        equipmentFilters: [],
        units: [],
        areas: []
      })
      return;
    }
    Context.instance.hotelingUnits.then(units => {
      if (!units || units.length === 0) {
        const mi = Context.instance.aiim.managerInfo;
        const mids = mi && mi.reservationManagerIds;
        const canReserveForOthers = mids && mids.length > 0;
        if (!canReserveForOthers) {
          this.setState({
            isWorking: false,
            noUnitsAtAllMsg: Context.instance.i18n.more.bookWorkspace.noUnitsAtAll
          })
          return;
        }
      }
      Context.instance.areas.then(areas => {
        this.setDropdownValues(units, areas);
      }).catch(error => {
        this.setState({
          isWorking: false,
          siteFilters: [],
          spaceTypeFilters: [],
          areaFilters: [],
          equipmentFilters: [],
          units: [],
          areas: []
        })
        this.closePanel()
      })
    }).catch(error => {
      this.setState({
        isWorking: false,
        siteFilters: [],
        spaceTypeFilters: [],
        areaFilters: [],
        equipmentFilters: [],
        units: [],
        areas: []
      })
      this.closePanel()
    })
  }

  setBookingDateFilter = (criteria) => {
    const filterDataChanges = {
      dtStart: null,
      dtEnd: null
    };
    const where = makeWhere(criteria, filterDataChanges);
    const data = {
      where: where,
      criteria: criteria,
      dateStart: filterDataChanges.dtStart,
      dateEnd: filterDataChanges.dtEnd
    };
    this.where = where;
    this.setState({ bookingDateFilter: data })
  }

  initBookingDateFilter =()=> {
    this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION, 'thisweek');
    const criteria = {
      dateOption: 'thisweek',
      dateSelected: this.props.rdxDateSelected
    }
    this.setBookingDateFilter(criteria);
    return criteria;
  }

  componentDidMount() {
    this._mounted = true;
    Context.instance._loadHotelingUnits()
    Context.instance._loadAreas()
    this.initFilters();
    let criteria, where;
    const type = getHotelBookingSystemType();
    if (type === 'office365') {
      criteria = this.initBookingDateFilter();
    } else {
      criteria = this.getCriteria();
      where = makeWhere(criteria);
      this.where = where;
    }

    const aiim = Context.getInstance().aiim;
    const isReservationManager = aiim && aiim.isReservationManager();

    if (isReservationManager && type === "esri") {
      this.props.setRdxValue(Rdx.Keys.BOOK_TAB_SELECTED,"others");
    }

    Topic.publish(Topic.BookDateFilterChange, where)
    // Load data here
    component.own(this, [
      Topic.subscribe(Topic.BookingFiltersSet, () => {
        Topic.publish(Topic.BookingFiltersSubmit, {});
      }),

      Topic.subscribe(Topic.BookingFiltersSubmit, params => {
        bookWorkspaceUtil.getHotelingUnits();
        if (!Context.instance.hotelingUnits) {
          this.setState({
            isWorking: false,
            siteFilters: [],
            spaceTypeFilters: [],
            areaFilters: [],
            equipmentFilters: [],
            units: [],
            areas: []
          })
          return;
        }

        Context.instance.hotelingUnits.then(units => {
          const filterDataChanges = () => {
            const areas = this.state.areas;
            this.setDropdownValues(units, areas);
          }

          const applyFilters = () => {
            const { init } = params;
            if (!!init) {
              return;
            }

            let checkIn = this.props.rdxCheckIn
            let checkOut = this.props.rdxCheckOut

            if (checkIn && checkOut) {
              this.setState({
                noFilters: false
              });
            } else {
              return;
            }

            showReservationsByFilter();

            this.setState({
              isQuerying: true
            })
            const bookingSystem = getHotelBookingSystem();

            // const units = this.state.units
            const areas = this.state.areas

            // Map Area IDs to Area Names
            let areaNames = {}, areaConfigByAreaId = {};
            areas.forEach(area => {
              const name = getAttributeValue(area.attributes, FieldNames.AREA_NAME);
              const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
              const config = getAttributeValue(area.attributes, FieldNames.AREAS_CONFIG);
              if (config) areaConfigByAreaId[id] = AreasTable.getConfig(area);
              areaNames[id] = name
            })

            const {
              noEmails,
              uniqueIds
            } = this.filterUnits(bookingSystem.type, units, areaNames);
            this.props.setRdxValue(Rdx.Keys.BOOK_FILTERED_RESULTS_NO_EMAIL, noEmails)

            checkIn = this.props.rdxCheckIn;
            checkOut = this.props.rdxCheckOut;

            // Filter Reservations layer by time
            setReservationsDefExp(new Date(checkIn), new Date(checkOut));
            // Query for schedules
            if (uniqueIds.length === 0) {
              this.setState({
                isQuerying: false
              })
              this.onBookingsListed([]);
              this.props.setRdxValue(Rdx.Keys.BOOK_FILTERED_RESULTS, [])
            } else {
              let user = getLoggedInUser(bookingSystem)
              if (user) {
                const params: ICheckAvailabilityParams = {
                  areaConfigByAreaId: areaConfigByAreaId,
                  hotelUnits: units,
                  recurrence: this.props.rdxRecurrence
                }

                checkAvailability(bookingSystem, uniqueIds, checkIn, checkOut, false, params).then(finalResults => {
                  const results = finalResults;
                 
                  let filteredResults = [];
                  if (bookingSystem.type === "office365" && results) {
                    const byEmail = (results as ScheduleInformation[]).reduce((acc, curr) => {
                      acc.has(curr.scheduleId) ? acc.get(curr.scheduleId).push(curr) : acc.set(curr.scheduleId, [curr]);
                      return acc;
                    }, new Map<string, ScheduleInformation[]>());
                    for (const infos of byEmail.values()) {
                      if (infos.every(schedule => (schedule && validateUtil.isUnitAvailable(schedule)))) {
                        filteredResults.push(infos[0]);
                      }
                    }
                  } else filteredResults = results;

                  let promise = Promise.resolve(filteredResults);
                  if (shouldSortHotels() && filteredResults && filteredResults.length > 0) {
                    const unitFeatures = this.getUnitFeatures(filteredResults);
                    const office365Results = bookingSystem.type === "office365" ? filteredResults : null;
                    promise = sortByDistance(unitFeatures, office365Results);
                  }
                  return promise;
                }).then((results) => {
                  if (bookingSystem.type === "office365") this.originalScheduleEmailObjs = results;
                  if (!this.props.rdxWasSortInit) this.initialiseSort(results);
                  else {
                    setTimeout(() => {
                      const unitFeatures = [];
                      if (bookingSystem.type === "office365") {
                        results.forEach((result) => {
                          unitFeatures.push(result)
                        })
                      } else if (bookingSystem.type === "esri") {
                        results.forEach((unitId) => {
                          unitFeatures.push(this.unitIdToFeature(unitId))
                        })
                      }
                      const task = {
                        sortBy: this.props.rdxSortSortBy,
                        sortDir: this.props.rdxSortSortDir,
                        filteredItems: unitFeatures
                      }
                      this.executeTask(task);
                    }, 1);
                  }
                  this.setState({
                    isQuerying: false
                  })
                  this.props.setRdxValue(Rdx.Keys.BOOK_FILTERED_RESULTS, results)
                }).catch(e => {
                  console.error(e)
                  if (e && isNetworkError(e.message)) {
                    const i18n = Context.getInstance().i18n;
                    Topic.publishNetworkError(i18n.more.bookWorkspace.findHotelsError);
                  } else {
                    Topic.publishErrorAccessingData();
                  }
                  this.setState({
                    isQuerying: false,
                    isWorking: false
                  });
                })
              } else {
                const params = {
                  areaConfigByAreaId: areaConfigByAreaId,
                  hotelUnits: units
                }
                login(bookingSystem).then(result => {
                  checkAvailability(bookingSystem, uniqueIds, checkIn, checkOut, false, params).then(finalResults => {
                    const results = finalResults;
                    let filteredResults = [];
                    if (bookingSystem.type === "office365" && results) {
                      const byEmail = (results as ScheduleInformation[]).reduce((acc, curr) => {
                        acc.has(curr.scheduleId) ? acc.get(curr.scheduleId).push(curr) : acc.set(curr.scheduleId, [curr]);
                        return acc;
                      }, new Map<string, ScheduleInformation[]>());
                      for (const infos of byEmail.values()) {
                        if (infos.every(schedule => (schedule && validateUtil.isUnitAvailable(schedule)))) {
                          filteredResults.push(infos[0]);
                        }
                      }
                    } else filteredResults = results;

                    let promise = Promise.resolve(filteredResults);
                    if (shouldSortHotels() && filteredResults && filteredResults.length > 0) {
                      const unitFeatures = this.getUnitFeatures(filteredResults);
                      const office365Results = bookingSystem.type === "office365" ? filteredResults : null;
                      promise = sortByDistance(unitFeatures, office365Results);
                    }
                    return promise;
                  }).then((results) => {
                    if (bookingSystem.type === "office365") this.originalScheduleEmailObjs = results;
                    if (!this.props.rdxWasSortInit) this.initialiseSort(results);
                    else {
                      setTimeout(() => {
                        const unitFeature = [];

                        if (bookingSystem.type === "office365") {
                          results.forEach((result) => {
                            unitFeature.push(result)
                          })
                        } else if (bookingSystem.type === "esri") {
                          results.forEach((unitId) => {
                            unitFeature.push(this.unitIdToFeature(unitId))
                          })
                        }

                        const task = {
                          sortBy: this.props.rdxSortSortBy,
                          sortDir: this.props.rdxSortSortDir,
                          filteredItems: unitFeature
                        }
                        this.executeTask(task);
                      }, 1);
                    }
                    this.setState({
                      isQuerying: false
                    })
                    this.props.setRdxValue(Rdx.Keys.BOOK_FILTERED_RESULTS, results)
                  }).catch(e => {
                    console.error(e)
                    if (e && isNetworkError(e.message)) {
                      const i18n = Context.getInstance().i18n;
                      Topic.publishNetworkError(i18n.more.bookWorkspace.findHotelsError);
                    } else {
                      Topic.publishErrorAccessingData();
                    }
                    this.setState({
                      isQuerying: false,
                      isWorking: false
                    });
                  })
                }).catch(e => {
                  console.error(e)
                  ModalController.showMessage(e.message)
                })
              }
            }
          }

          if (this._mounted) {
            filterDataChanges();
            setTimeout(applyFilters, 500);
          }
        }).catch(error => {
          console.error(error);
        });
      })
    ])
  }

  componentDidUpdate() {
    
  }

  dropPins = (validUnitFeatures: string[] | { scheduleId: string }[])=> {
    const data = validUnitFeatures || [];
    const bookingSystemType = getHotelBookingSystemType();
    let validUnits = [];
    if(bookingSystemType === "office365" && data) {
      data.forEach((result)=> {
        const isAvailable = validateUtil.isUnitAvailable(result);
        if (isAvailable) {
          const matchingUnit = this.uniqueIdToUnit(result.scheduleId, FieldNames.SCHEDULE_EMAIL)
            if (matchingUnit) {
              validUnits.push(matchingUnit.searchResult);
            }
        }
      })
    } else {
      data.forEach(unitId => {
        const matchingUnit = this.uniqueIdToUnit(unitId, FieldNames.UNIT_ID);
        if (matchingUnit) {
          validUnits.push(matchingUnit.searchResult);
        }
      });
    }
    return validUnits;
  }


  initialiseSort = (results) => {
    // display hotels nearest to current location (bluedot) on initial render;
    //if current location not available, sort on the basis of name (ascending)

    if(bookWorkspaceUtil) {
      let sortBy = this.props.rdxSortSortBy;
      let sortDir = this.props.rdxSortSortDir;

      if(bookWorkspaceUtil.canSortByBluedotDistance() && bookWorkspaceUtil.isBluedotInSite()) {
        sortBy = "distanceBluedot";
      }
      else if(bookWorkspaceUtil.canSortByHomeDistance()) {
        sortBy = "distanceHome";
      }

      setTimeout(() => {
        const unitFeature = [];
        const bookingSystemType = getHotelBookingSystemType();
        if(bookingSystemType === "office365") {
          results.forEach((result)=> {
            unitFeature.push(result)
          })
        } else if (bookingSystemType === "esri") {
          results.forEach((unitId)=> {
            unitFeature.push(this.unitIdToFeature(unitId))
          })
        }

        const task = {
          sortBy: sortBy,
          sortDir: sortDir,
          filteredItems: unitFeature
        }
        this.executeTask(task);
      },1);

    }
  }

  /**
   * When available hotels are listed, drop pins on them, and zoom to the first unit in
   * the list
   * @param {Object[]} unitFeatures
   */
  onBookingsListed(
    unitFeatures: __esri.Graphic[],
    sortedrdxFilteredResults?: string[] | { scheduleId: string }[]
  ) {
    if (!unitFeatures || !unitFeatures.length) {
      dropUnitPins([]);
      return;
    }

    const units = Context.getInstance().aiim.datasets.units;
    const unitsLayer = units && units.layer2D;
    const floorField = unitsLayer && unitsLayer.floorInfo && unitsLayer.floorInfo.floorField;
    const levels = Context.getInstance().aiim.datasets.levels;

    // Make sure the unit has accessible level data
    const validateLevelData = (unit) => {
      const attributes = unit.attributes;
      const levelId = floorField
        ? getAttributeValue(attributes, floorField)
        : getAttributeValue(attributes, FieldNames.LEVEL_ID);

      return levels.getLevelData(levelId);
    }

    // Filter the unit features by those who have level data
    const validUnitFeatures = unitFeatures.filter((unit) => {
      return !!validateLevelData(unit);
    });

    if (!validUnitFeatures || !validUnitFeatures.length) {
      dropUnitPins([]);
      return;
    }

    // Take first unit in the list
    const initialUnit = validUnitFeatures[0];
    const centroid = initialUnit.geometry && (initialUnit.geometry as __esri.Polygon).centroid;

    // Get the level and facility data to activate the level
    const view = Context.getInstance().views.activeView;
    const levelData = validateLevelData(initialUnit);
    const { facilityId } = levelData;
    const facilityData = levels.getFacilityData(facilityId);

    // Set the level and zoom to the unit
    Topic.publish(Topic.ActivateLevel, { facilityData, levelData, view });
    const feature = centroid ? { geometry: centroid } : initialUnit;
    goToFeature(view, feature, false, { scale: 300 });

    // Place pins on the available units
    const validUnits = this.dropPins(sortedrdxFilteredResults);
    dropUnitPins(validUnits);
  }

  filterUnits = (type, units, areaNames) => {
    switch (type) {
      case "office365":
        const filteredOffice365Units = this.filterOffice365Units(units, areaNames);
        return {
          noEmails: filteredOffice365Units.filteredUnitsNoEmail,
          uniqueIds: filteredOffice365Units.scheduleEmails
        };
      case "esri":
        const filteredEsriUnits = this.filterEsriUnits(units, areaNames);
        return {
          noEmails: [],
          uniqueIds: filteredEsriUnits.uniqueIds
        };
      default:
        return { noEmails: [], uniqueIds: [] };
    }
  }

  filterOffice365Units = (units, areaNames) => {
    const selectedSpaceTypeFilters = this.props.rdxSpaceTypeFilters
    const selectedAreaFilters = this.props.rdxAreaFilters
    const selectedSiteFilters = this.props.rdxSiteFilters
    const selectedEquipmentFilters = this.props.rdxEquipmentFilters;
    const layer2D = Context.getInstance().aiim.datasets.units &&
                        Context.getInstance().aiim.datasets.units.layer2D;

    // let filteredUnits = []
    let filteredUnitsNoEmail = []
    let scheduleEmails = []
    units.forEach(unit => {
      const spaceType = getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE);
      const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
      const levelId = levelIdField && getAttributeValue(unit.attributes,levelIdField.name);
      const siteName = bookWorkspaceUtil.getSiteName(levelId);
      const areaId = getAttributeValue(unit.attributes, FieldNames.AREA_ID);
      const areaName = areaId && areaNames[areaId]
      const scheduleEmail = getAttributeValue(unit.attributes, FieldNames.SCHEDULE_EMAIL);
      let areAllEquipmentsAvailable = true;
      for (let i=0;i<selectedEquipmentFilters.length;i++) {
        const equipment = selectedEquipmentFilters[i];
        const isEquipmentAvail = getAttributeValue(unit.attributes, equipment);
        if(!isEquipmentAvail || (isEquipmentAvail && isEquipmentAvail !== 1)) {
          areAllEquipmentsAvailable = false;
          break;
        }
      }
      if (spaceType && areaId && areaName) {
        if ((!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
            && (!selectedAreaFilters || selectedAreaFilters.length === 0)
            && (!selectedSiteFilters || selectedSiteFilters.length === 0)
            && (!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedAreaFilters || selectedAreaFilters.length === 0)) {
            if(selectedSiteFilters === siteName) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(selectedSpaceTypeFilters.includes(spaceType)) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName)){
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(areAllEquipmentsAvailable) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName) && selectedSiteFilters === (siteName)) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if ((selectedSiteFilters === siteName) && areAllEquipmentsAvailable) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if (selectedSpaceTypeFilters.includes(spaceType) && areAllEquipmentsAvailable) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName))){
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if ((!selectedSiteFilters || selectedSiteFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName) && areAllEquipmentsAvailable) {
              if (scheduleEmail) {
                // filteredUnits.push(unit)
                scheduleEmails.push(scheduleEmail)
              } else {
                filteredUnitsNoEmail.push(unit)
              }
            }
        } else if (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0) {
          if (selectedAreaFilters.includes(areaName) && (selectedSiteFilters === siteName)
              && areAllEquipmentsAvailable) {
                if (scheduleEmail) {
                  // filteredUnits.push(unit)
                  scheduleEmails.push(scheduleEmail)
                } else {
                  filteredUnitsNoEmail.push(unit)
                }
          }
        } else if (!selectedAreaFilters || selectedAreaFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedSiteFilters === siteName)
              && areAllEquipmentsAvailable) {
                if (scheduleEmail) {
                  // filteredUnits.push(unit)
                  scheduleEmails.push(scheduleEmail)
                } else {
                  filteredUnitsNoEmail.push(unit)
                }
          }
        } else if (!selectedSiteFilters || selectedSiteFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName))
              && areAllEquipmentsAvailable) {
                if (scheduleEmail) {
                  // filteredUnits.push(unit)
                  scheduleEmails.push(scheduleEmail)
                } else {
                  filteredUnitsNoEmail.push(unit)
                }
          }
        } else if (!selectedEquipmentFilters || selectedEquipmentFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName)
              && (selectedSiteFilters === siteName))) {
                if (scheduleEmail) {
                  // filteredUnits.push(unit)
                  scheduleEmails.push(scheduleEmail)
                } else {
                  filteredUnitsNoEmail.push(unit)
                }
          }
        } else if (
          (selectedSiteFilters === siteName) &&
          selectedSpaceTypeFilters.includes(spaceType) &&
          selectedAreaFilters.includes(areaName) &&
          areAllEquipmentsAvailable
        ) {
          if (scheduleEmail) {
            // filteredUnits.push(unit)
            scheduleEmails.push(scheduleEmail)
          } else {
            filteredUnitsNoEmail.push(unit)
          }
        }
      }
    })
    return { filteredUnitsNoEmail, scheduleEmails };
  }

  filterEsriUnits(units, areaNames) {
    const selectedSpaceTypeFilters = this.props.rdxSpaceTypeFilters
    const selectedAreaFilters = this.props.rdxAreaFilters
    const selectedSiteFilters = this.props.rdxSiteFilters
    const selectedEquipmentFilters = this.props.rdxEquipmentFilters;
    const layer2D = Context.getInstance().aiim.datasets.units &&
                      Context.getInstance().aiim.datasets.units.layer2D;

    let uniqueIds = []
    units.forEach(unit => {
      const unitId = getAttributeValue(unit.attributes, FieldNames.UNIT_ID);
      const spaceType = getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE);
      const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
      const levelId = levelIdField && getAttributeValue(unit.attributes,levelIdField.name);
      const siteName = bookWorkspaceUtil.getSiteName(levelId);
      const areaId = getAttributeValue(unit.attributes, FieldNames.AREA_ID);
      const areaName = areaId && areaNames[areaId];
      let areAllEquipmentsAvailable = true;
      for (let i=0;i<selectedEquipmentFilters.length;i++) {
        const equipment = selectedEquipmentFilters[i];
        const isEquipmentAvail = getAttributeValue(unit.attributes, equipment);
        if(!isEquipmentAvail || (isEquipmentAvail && (isEquipmentAvail !== 1))) {
          areAllEquipmentsAvailable = false;
          break;
        }
      }

      if (spaceType && areaId) {
        if ((!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
            && (!selectedAreaFilters || selectedAreaFilters.length === 0)
            && (!selectedSiteFilters || selectedSiteFilters.length === 0)
            && (!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)) {
          uniqueIds.push(unitId);
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedAreaFilters || selectedAreaFilters.length === 0)) {
            if(selectedSiteFilters === siteName) uniqueIds.push(unitId);
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(selectedSpaceTypeFilters.includes(spaceType)) uniqueIds.push(unitId);
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName)) uniqueIds.push(unitId);
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if(areAllEquipmentsAvailable) uniqueIds.push(unitId);
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName) && selectedSiteFilters === (siteName)) {
              uniqueIds.push(unitId);
            }
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if ((selectedSiteFilters === siteName) && areAllEquipmentsAvailable) {
              uniqueIds.push(unitId);
            }
        } else if ((!selectedAreaFilters || selectedAreaFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if (selectedSpaceTypeFilters.includes(spaceType) && areAllEquipmentsAvailable) {
              uniqueIds.push(unitId);
            }
        } else if ((!selectedEquipmentFilters || selectedEquipmentFilters.length === 0)
          && (!selectedSiteFilters || selectedSiteFilters.length === 0)) {
            if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName))){
              uniqueIds.push(unitId);
            }
        } else if ((!selectedSiteFilters || selectedSiteFilters.length === 0)
          && (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0)) {
            if(selectedAreaFilters.includes(areaName) && areAllEquipmentsAvailable) uniqueIds.push(unitId);
        } else if (!selectedSpaceTypeFilters || selectedSpaceTypeFilters.length === 0) {
          if (selectedAreaFilters.includes(areaName) && (selectedSiteFilters === siteName)
              && areAllEquipmentsAvailable) {
            uniqueIds.push(unitId);
          }
        } else if (!selectedAreaFilters || selectedAreaFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedSiteFilters === siteName)
              && areAllEquipmentsAvailable) {
            uniqueIds.push(unitId);
          }
        } else if (!selectedSiteFilters || selectedSiteFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName))
              && areAllEquipmentsAvailable) {
            uniqueIds.push(unitId);
          }
        } else if (!selectedEquipmentFilters || selectedEquipmentFilters.length === 0) {
          if (selectedSpaceTypeFilters.includes(spaceType) && (selectedAreaFilters.includes(areaName)
              && (selectedSiteFilters === siteName))) {
            uniqueIds.push(unitId);
          }
        } else if (
          (selectedSiteFilters === siteName) &&
          selectedSpaceTypeFilters.includes(spaceType) &&
          selectedAreaFilters.includes(areaName) &&
          areAllEquipmentsAvailable
        ) {
          uniqueIds.push(unitId);
        }
      }
    })

    return { uniqueIds };
  }

  //#region
  // _getFocusables = () => {
  //   let nodes = [];
  //   const units = document.getElementById("available-units");
  //   const duration = document.getElementById("btn-durationfilter")
  //   const filter = document.getElementById("btn-spacefilter")
  //   const refresh = document.getElementById("btn-refresh")

  //   if (units) {
  //     units.querySelectorAll("button").forEach(unit => {
  //       nodes.push(unit)
  //     })
  //   }
  //   nodes.push(refresh)
  //   nodes.push(filter)
  //   nodes.push(duration)
  //   return nodes;
  // }

  // _focusButton = () => {
  //   const node = document.getElementById(this.id+"-btn");
  //   focusUtil.focusNode(node);
  // }

  // _focusStep = (event, direction, isTabShift) => {
  //   let nodes = this._getFocusables();
  //   if (nodes && nodes.length > 1) {
  //     let info = focusUtil.findTarget(nodes,event.target);
  //     let index = (direction === "previous")? info.previousIndex: info.nextIndex;
  //     if (index !== -1) {
  //       if (isTabShift && info.index === 0) {
  //         this._focusButton();
  //       } else {
  //         focusUtil.focusIndex(nodes,index);
  //       }
  //     }
  //   }
  // }

  // _keydownContent = (event) => {
  //   if (event) {
  //     if (event.keyCode === 9) { // tab
  //       event.preventDefault();
  //       event.stopPropagation();
  //       const dir = event.shiftKey? "previous": "next";
  //       this._focusStep(event,dir,!!event.shiftKey);
  //     } else if (event.keyCode === 38) { // up arrow
  //       event.preventDefault();
  //       event.stopPropagation();
  //       this._focusStep(event,"previous");
  //     } else if (event.keyCode === 40) { // down arrow
  //       event.preventDefault();
  //       event.stopPropagation();
  //       this._focusStep(event,"next");
  //     }
  //   }
  // }
  //#endregion

  closePanel() {
    hideReservationsByFilter();
    this.props.setRdxValue(Rdx.Keys.SIDEBAR_ACTIVE_KEY, null);
    document.body.classList.remove(CSS.sidebarPanelOpen);
    Context.instance.views.toggleClickHandlers("resume");
  }

  backClicked = () => {
    hideReservationsByFilter();
    this.props.setRdxValue(Rdx.Keys.SIDEBAR_ACTIVE_KEY, "more");
    document.body.classList.add(CSS.sidebarPanelOpen);
    Topic.publish(Topic.SidebarButtonClicked,{sidebarKey: "more"});
    Context.instance.views.toggleClickHandlers("resume");
  }

  _focusFilterButton = (elemId) => {
    const button = document.getElementById(elemId)
    if (button) {
      button.focus()
    }
  }

  showDurationPopup = () => {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
        this._focusFilterButton("btn-durationfilter")
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <DurationFilters initialFilter={false} closePopup={onClose} />
      </Provider>
    ), node);
  }

  showSpacePopup = () => {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
        this._focusFilterButton("btn-spacefilter")
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <SpaceFilters initialFilter={false} closePopup={onClose}
          siteFilters={this.state.siteFilters}
          spaceTypeFilters={this.state.spaceTypeFilters}
          areaFilters={this.state.areaFilters}
          equipmentFilters={this.state.equipmentFilters}
        />
      </Provider>
    ), node);
  }

  refresh =() => {
    bookWorkspaceUtil.getHotelingUnits();
    Context.instance.hotelingUnits
    .then(()=> {
      this.initFilters();
      Topic.publish(Topic.BookingFiltersSubmit, {});
    })
    .catch(error => {
      console.error(error);
    })
  }

  renderHeader() {
    const i18n = Context.getInstance().i18n;
    return (
      <SidebarHeader
        caption={Context.instance.aiim.getHotelButtonLabel()}
        closeAriaLabel={i18n.more.bookWorkspace.closeAriaLabel}
        sidebarKey={this.props.sidebarKey}
        showBack={!!this.props.morePanel}
        backHandler={this.backClicked}
      />
    );
  }

  renderOptions() {
    const i18n = Context.instance.i18n;
    const disabled = !!this.state.isQuerying;

    return (
      <div className="i-separated-top">
        <div key="datetime" className={CSS.options}>
          <div className={CSS.filterButton}>
            <button id="btn-durationfilter" aria-label={i18n.more.bookWorkspace.durationAriaLabel}
              className={CSS.ddButton} onClick={this.showDurationPopup} disabled={disabled}>
                <div className="i-flex-between-centered">
                  <ClockIcon size={16}/>
                  <span className="i--ml">{i18n.more.bookWorkspace.duration}</span>
                </div>
            </button>
          </div>
          <div className={CSS.filterButton}>
            <button id="btn-spacefilter" aria-label={i18n.more.bookWorkspace.filterAriaLabel}
              className={CSS.ddButton} onClick={this.showSpacePopup} disabled={disabled}>
                <div className="i-flex-between-centered">
                  <FilterIcon size={16}/>
                  <span className="i--ml">{i18n.more.bookWorkspace.filter}</span>
                </div>
            </button>
          </div>
          <div className={CSS.filterButton}>
            {this.renderSort(disabled)}
          </div>
          {
            (this.props.rdxCheckIn && this.props.rdxCheckOut) ?
              <div className={CSS.filterButton + " i-refresh"}>
                <button id="btn-refresh" title={i18n.more.bookWorkspace.refresh} disabled={disabled}
                  aria-label={i18n.more.bookWorkspace.refreshAriaLabel} className={CSS.ddButton}
                  onClick={this.refresh}>
                  {Icons.reload()}
                </button>
              </div> : null
          }
        </div>
      </div>
    );
  }

  refreshClicked =()=> {
    const type = getHotelBookingSystemType();
    if (type === "office365") Topic.publish(Topic.RenderBookingListOffice365, {});
    else Topic.publish(Topic.RenderBookingList, {});
  }

  renderRefresh() {
    const i18n = Context.instance.i18n;
    const disabled = !!this.state.isQuerying;

    return (
      <div key="datetime" className={CSS.options} >
        <div className={CSS.filterButton + " i-refresh"}>
          <button id="btn-refresh" title={i18n.more.bookWorkspace.refresh} disabled={disabled}
            aria-label={i18n.more.bookWorkspace.refreshAriaLabel} className={CSS.ddButton}
            onClick={this.refreshClicked}>
            {Icons.reload()}
          </button>
        </div>
      </div>
    )
  }

  getScheduleEmailObj =(scheduleId)=> {
    const scheduleEmailObjs = this.originalScheduleEmailObjs;
    for(let i=0;i<scheduleEmailObjs.length;i++) {
      if(scheduleEmailObjs[i].scheduleId === scheduleId) return scheduleEmailObjs[i];
    }
  }

  executeTask=(task)=> {
    const bookingSystemType = getHotelBookingSystemType();

    this.setState({
      isQuerying: true
    })
    if(bookingSystemType === "office365") {
      let filteredItems = [];
      task.filteredItems.forEach((item)=> {
        const scheduleId = item.scheduleId;
        filteredItems.push(this.scheduleEmailToFeature(scheduleId));
      })
      task.filteredItems = filteredItems;
    }

    bookWorkspaceUtil.sortUnits(task).then((units)=>{
      let sortedrdxFilteredResults: string[] | { scheduleId: string }[] = [];
      if(bookingSystemType === "office365") {
        units.forEach((unit)=> {
          const scheduleId = getAttributeValue(unit.attributes, FieldNames.SCHEDULE_EMAIL);
          sortedrdxFilteredResults.push(this.getScheduleEmailObj(scheduleId));
        })
      } else if (bookingSystemType === "esri") {
        units.forEach((unit)=> {
          const unitId = getAttributeValue(unit.attributes, FieldNames.UNIT_ID);
          sortedrdxFilteredResults.push(unitId);
        })
      }

      this.props.setRdxValue(Rdx.Keys.BOOK_WAS_SORT_INIT, true);
      this.setState({
        isQuerying: false
      })

      // Zoom to first booking
      this.onBookingsListed(units, sortedrdxFilteredResults);

      this.props.setRdxValue(Rdx.Keys.BOOK_FILTERED_RESULTS, sortedrdxFilteredResults);
      this.props.setRdxValue(Rdx.Keys.BOOK_SORT_SORTBY, task.sortBy);
    }).catch(error => {
      console.error("Error in sorting hotel list", error);
    })
  }

  renderSort =(disabled)=>{
    const i18n = Context.instance.i18n;
    let sortBy = this.props.rdxSortSortBy;
    let sortDir = this.props.rdxSortSortDir;

    const sort = () => {
      const unitIds = this.props.rdxFilteredResults;
      const unitFeatures = [];

      const bookingSystemType = getHotelBookingSystemType();
      if(bookingSystemType === "office365") {
        unitIds.forEach((unit)=> {
          unitFeatures.push(unit);
        })
      } else if (bookingSystemType === "esri") {
        unitIds.forEach((unitId)=> {
          unitFeatures.push(this.unitIdToFeature(unitId))
        })
      }

      setTimeout(() => {
        const task = {
          sortBy: this.props.rdxSortSortBy,
          sortDir: this.props.rdxSortSortDir,
          filteredItems: unitFeatures
        }
        this.executeTask(task);
      },1);
    };

    const setSortBy = opt => {
      if (sortBy !== opt) {
        this.props.setRdxValue(Rdx.Keys.BOOK_SORT_SORTDIR, "asc");
      }
      this.props.setRdxValue(Rdx.Keys.BOOK_SORT_SORTBY, opt);
      sort();
    };

    const setSortDir = opt => {
      this.props.setRdxValue(Rdx.Keys.BOOK_SORT_SORTDIR, opt);
      sort();
    };

    const getSortDirClass = opt => {
      let cn ="i-sorting-button";
      if (sortDir === opt) {
        cn += " i--active";
      }
      return cn;
    }

    const getSortIcon = () => {
      if (sortDir === "desc") {
        return <SortDescIcon size={16} />
      } else {
        return <SortAscIcon size={16} />
      }
    }

    const getIcon = opt => {
      let chk;
      if (sortBy === opt) {
        chk = (<span key="icon">{Icons.checkMark()}</span>);
      } else {
        chk = (<span key="icon" className="svg-icon i-icon-empty"></span>);
      }
      return chk;
    };

    let sortButton = (
      // @ts-ignore
      <div className="i-dd-button" disabled={disabled}
        aria-label={i18n.meetingRooms.sortAriaLabel}>
        <div className="i-flex-between-centered">
          {getSortIcon()}
          <span className="i--ml">{i18n.more.bookWorkspace.sort}</span>
        </div>
      </div>
    );

    const sortOptions = [];

    sortOptions.push(
      <li key={"dir"} role="none" className="i-flex-between i-border-bottom">
        <div></div>
        <div className="i-sortby">
          <button type="button" className={getSortDirClass("asc")+" i--focusable"}
            title={i18n.more.bookWorkspace.sortOptions.ascending}
            onClick={() => {
              setSortDir("asc");
            }}>
            {Icons.ascendingSort()}
          </button>
          <button type="button" className={getSortDirClass("desc")+" i--focusable"}
            disabled={sortBy === "distanceHome" || sortBy === "distanceBluedot"}
            title={i18n.more.bookWorkspace.sortOptions.descending}
            onClick={() => {
              setSortDir("desc");
            }}>
            {Icons.descendingSort()}
          </button>
        </div>
      </li>
    );

    if(bookWorkspaceUtil.canSortByHomeDistance()) {
      sortOptions.push(
        <li key={"distanceHome"} role="none" className="i-border-bottom">
          <button type="button" className="i-button-link i-padded i--focusable"
            onClick={() => {
              setSortBy("distanceHome");
            }}>
              {getIcon("distanceHome")}
              {i18n.more.bookWorkspace.sortOptions.distanceHome}
          </button>
        </li>
      );
    }

    sortOptions.push(
      <li key={"name"} role="none" className="i-border-bottom">
        <button type="button" className="i-button-link i-padded i--focusable"
          onClick={() => {
            setSortBy("name");
          }}>
            {getIcon("name")}
          {i18n.more.bookWorkspace.sortOptions.name}
        </button>
      </li>
    );

    if(bookWorkspaceUtil.canSortByBluedotDistance()) {
      sortOptions.push(
        <li key={"distanceBluedot"} role="none" className="i-border-bottom">
          <button type="button" className="i-button-link i-padded i--focusable"
            onClick={() => {
              setSortBy("distanceBluedot");
            }}>
              {getIcon("distanceBluedot")}
              {i18n.more.bookWorkspace.sortOptions.distanceMyLocation}
          </button>
        </li>
      );
    }

    let sortContent = (
      <ul className="i-padded" role="menu" aria-label={i18n.more.bookWorkspace.sortOptions.sort}>
        {sortOptions}</ul>
    );

    return (
      <DropdownButton
        closeOnSelect2={true}
        focusFirst={true}
        buttonClassName={"i-no-padding i-no-border"}
        buttonContent={sortButton}
        dropdownContent={sortContent}
        disabled={disabled}
        placement={"bottom-start"}>
      </DropdownButton>
    );
  }

  getUnitFeatures(uniqueIds) {
    const bookingSystemType = getHotelBookingSystemType();

    switch (bookingSystemType) {
      case "esri":
        return uniqueIds.map((unitId) => this.unitIdToFeature(unitId));
      case "office365":
        return uniqueIds.map((result) => this.scheduleEmailToFeature(result.scheduleId));
      default:
        return [];
    }
  }

  uniqueIdToUnit(uniqueId, uniqueIdField) {
    const units = this.state.units
    const areas = this.state.areas

    // Map Area IDs to Area Names
    let areaNames = {}
    areas.forEach(area => {
      const name = getAttributeValue(area.attributes, FieldNames.AREA_NAME);
      const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
      areaNames[id] = name
    })

    let result = null
    units.forEach(unit => {
      const uniqueIdValue = getAttributeValue(unit.attributes, uniqueIdField);
      if (uniqueIdValue === uniqueId) {
        const spaceType = getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE);
        const areaId = getAttributeValue(unit.attributes, FieldNames.AREA_ID);
        const areaName = areaId && areaNames[areaId]
        const name = getAttributeValue(unit.attributes, FieldNames.NAME);
        let level = getAttributeValue(unit.attributes, FieldNames.LEVEL_NAME);
        let facility = getAttributeValue(unit.attributes, FieldNames.FACILITY_NAME);

        const unitsDataset = Context.instance.aiim.datasets.units;
        if (unitsDataset) {
          const levelData = unitsDataset.getLevelData(unit);
          if (levelData && levelData.levelName) level = levelData.levelName;
          if (levelData && levelData.facilityName) facility = levelData.facilityName;
        }

        if (spaceType && name && level && facility) {
          result = {
            spaceType: spaceType,
            areaName: areaName,
            name: name,
            level: level,
            facility: facility,
            searchResult: unit
          }
        }
      }
    });

    return result;
  }

  unitIdToFeature =(unitId)=> {
    const units = this.state.units;
    return units.find((unit) => getAttributeValue(unit.attributes, FieldNames.UNIT_ID) === unitId);
  }

  scheduleEmailToFeature(scheduleEmail) {
    const units = this.state.units;
    return units.find((unit) => getAttributeValue(unit.attributes, FieldNames.SCHEDULE_EMAIL) === scheduleEmail);
  }

  createCards(data) {
    if (!data) {
      return { cards: [], nonBookableCards: [] };
    }
    const type = getHotelBookingSystemType();
    switch (type) {
      case "office365":
        return this.createOffice365Cards(data);
      case "esri":
        return this.createEsriCards(data);
      default:
        return { cards: [], nonBookableCards: [] };
    };
  }

  createOffice365Cards(data) {
    const i18n = Context.getInstance().i18n;
    const cards = [];
    const nonBookableCards = [];
    this.props.rdxFilteredResultsNoEmail.forEach(unit => nonBookableCards.push(unit));
    data.forEach(unit => {
      if (unit.scheduleItems) {
        const isAvailable = validateUtil.isUnitAvailable(unit);
        if (isAvailable) {
          const matchingUnit = this.uniqueIdToUnit(unit.scheduleId, FieldNames.SCHEDULE_EMAIL);
          if (matchingUnit) {
            const spaceType = matchingUnit.spaceType
            const name = matchingUnit.name
            const level = matchingUnit.level
            const facility = matchingUnit.facility
            const searchResult = matchingUnit.searchResult
            const template = i18n.more.bookWorkspace.levelDescription;
            const description = stringFormatter(template, { level, facility });
            cards.push(
              <AvailableUnitCard
                key={unit.scheduleId}
                name={name}
                spaceType={spaceType}
                description={description}
                hasEmail={true}
                unit={searchResult}
                ref={cards.length === 0 && this.listRef} 
                startTime={this.props.rdxStartTime}
                startDate={this.props.rdxStartDate}
                endTime={this.props.rdxEndTime}
                endDate={this.props.rdxEndDate}
                allDay={this.props.rdxAllDay} 
                recurrence={this.props.rdxRecurrence}
              />
            )
          }
        }
      } else {
        console.warn(`Not a valid email: ${unit.scheduleId}`)
        const matchingUnit = this.uniqueIdToUnit(unit.scheduleId, FieldNames.SCHEDULE_EMAIL)
        if (matchingUnit && !nonBookableCards.includes(matchingUnit.searchResult)) {
          nonBookableCards.push(matchingUnit.searchResult)
        }
      }
    });

    return { cards, nonBookableCards };
  }

  createEsriCards(data) {
    const i18n = Context.getInstance().i18n;
    const cards = [];

    data.forEach(unitId => {
      const matchingUnit = this.uniqueIdToUnit(unitId, FieldNames.UNIT_ID);
      if (matchingUnit) {
        const spaceType = matchingUnit.spaceType
        const name = matchingUnit.name
        const level = matchingUnit.level
        const facility = matchingUnit.facility
        const searchResult = matchingUnit.searchResult
        const template = i18n.more.bookWorkspace.levelDescription;
        const description = stringFormatter(template, { level, facility });
        cards.push(
          <AvailableUnitCard
            key={unitId}
            name={name}
            spaceType={spaceType}
            description={description}
            hasEmail={true}
            unit={searchResult}
            ref={cards.length === 0 && this.listRef}
            reserveForInfo={this.state.reserveForInfo}
            reserveForSelf={!this.state.reserveForInfo}
            startTime={this.props.rdxStartTime}
            startDate={this.props.rdxStartDate}
            checkIn={this.props.rdxCheckIn}
            checkOut={this.props.rdxCheckOut}
            endTime={this.props.rdxEndTime}
            endDate={this.props.rdxEndDate}
            allDay={this.props.rdxAllDay}
            recurrence={this.props.rdxRecurrence}
          />
        )
      }
    });

    return { cards, nonBookableCards: [] };
  }

  renderCards() {
    const i18n = Context.instance.i18n
    const data = this.props.rdxFilteredResults;
    const { cards, nonBookableCards } = this.createCards(data);

    nonBookableCards.forEach(unit => {
      const areas = this.state.areas
      const id = getAttributeValue(unit.attributes, FieldNames.UNIT_ID);

      // Map Area IDs to Area Names
      let areaNames = {}
      areas.forEach(area => {
        const name = getAttributeValue(area.attributes, FieldNames.AREA_NAME);
        const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
        areaNames[id] = name
      })
      const spaceType = getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE);
      const name = getAttributeValue(unit.attributes, FieldNames.NAME);
      let level = getAttributeValue(unit.attributes, FieldNames.LEVEL_NAME);
      let facility = getAttributeValue(unit.attributes, FieldNames.FACILITY_NAME);

      const unitsDataset = Context.instance.aiim.datasets.units;
      if (unitsDataset) {
        const levelData = unitsDataset.getLevelData(unit);
        if (levelData && levelData.levelName) level = levelData.levelName;
        if (levelData && levelData.facilityName) facility = levelData.facilityName;
      }

      if (spaceType && name && level && facility) {
        const template = i18n.more.bookWorkspace.levelDescription;
        const description = stringFormatter(template, { level, facility });
        cards.push(
          <AvailableUnitCard
            key={id}
            name={name}
            spaceType={spaceType}
            description={description}
            hasEmail={false}
            unit={unit}
            ref={cards.length === 0 && this.listRef} />
        )
      }
    })

    const noItems = i18n.more.bookWorkspace.noUnits
    const allDay = this.props.rdxAllDay
    const lib = Context.instance.lib
    const today = lib.dojo.locale.format(new Date(), { selector:"date", formatLength: "short" })
    const startDate = lib.dojo.locale.format(this.props.rdxStartDate, { selector: "date", formatLength: "short" })
    const endDate = lib.dojo.locale.format(this.props.rdxEndDate, { selector: "date", formatLength: "short" })
    let areItems
    if (allDay) {
      let dur, day
      if (startDate === today && endDate === today) {
        // Available today all day
        dur = ""
        day = i18n.more.bookWorkspace.today
      } else if (startDate === endDate) {
        // Ex: Available on 7/22/21 all day
        dur = i18n.more.bookWorkspace.on
        day = startDate
      } else {
        // Ex: Available from 7/22/21 - 7/23/21 all day
        dur = i18n.more.bookWorkspace.from
        day = startDate + " - " + endDate
      }

      const template = i18n.more.bookWorkspace.availableUnitsAllDay;
      areItems = stringFormatter(template, { duration: dur, day });
    } else {
      let st, et, dur;
      let dateString = null;
      if (startDate === endDate) {
        if (startDate === today) {
          // Ex: Available today, 5:00 PM to 6:00 PM
          dateString = i18n.more.bookWorkspace.today;
          dur = "";
        } else {
          // Ex: Available on 7/23/21, 5:00 PM to 6:00 PM
          dateString = startDate
          dur = i18n.more.bookWorkspace.on
        }
      } else {
        // Ex: Available from 7/22/21, 5:00 PM to 7/23/21, 6:00 PM
        dur = i18n.more.bookWorkspace.from
      }

      let locale = Context.instance.lib.dojo.kernel.locale
      const format = (locale === "en" || locale === "en-us") ? "h:mm A" : "H:mm";

      st = this.props.rdxStartTime.format(format)
      et = this.props.rdxEndTime.format(format)

      const template = dateString
        ? i18n.more.bookWorkspace.availableUnitsSameDay
        : i18n.more.bookWorkspace.availableUnits

      areItems = stringFormatter(template, {
        duration: dur,
        date: dateString,
        startDate,
        startTime: st,
        endDate,
        endTime: et
      });
    }

    const recurrence = this.props.rdxRecurrence;
    if (recurrence?.enabled) {
      areItems = formatRecurringDates(this.props.rdxRecurrence, moment(this.props.rdxStartTime), 
                    moment(this.props.rdxEndTime), allDay, true);
    } 

    let sortedCards;
    // if(wasSortInit === false) {
    //   sortedCards = cards.sort((a, b) => {
    //   const aName = a.props.name;
    //   const bName = b.props.name;
    //   return aName < bName ? -1 : aName > bName ? 1 : 0;
    //   });
    // }

    let reserveForNode;
    if (!this.state.isQuerying && this.state.reserveForInfo) {
      const f = this.state.reserveForInfo.occupantFeature;
      const knownas = getAttributeValue(f.attributes, FieldNames.PEOPLE_FULLNAME);
      const email = "(" + getAttributeValue(f.attributes, FieldNames.PEOPLE_EMAIL) + ")";
      // @todo Needs Translation
      // const forLabel = "For:";
      var cssStyle = {
        paddingTop: "1.5rem",
        paddingBottom: "1.25rem",
        display: "inline-block"
      }
      var iconStyle: CSSProperties = {
        verticalAlign: "sub"
      }
      const isRTL = Context.getInstance().uiMode.isRtl;
      if (isRTL) iconStyle.marginLeft = "0.2rem";
      else iconStyle.marginRight = "0.2rem";

      reserveForNode = (
        <div className={CSS.description} style={cssStyle}>
          <span><UserIcon size={16} style={iconStyle}/></span>
          <span style={{wordBreak: "break-word"}}> {knownas} {email} </span>
        </div>
      )
    }

    return (
      <div className={CSS.hotelContent}>
        {reserveForNode}
        <div className={CSS.description} style={{paddingTop: "0px"}}>
          {this.state.isQuerying ? i18n.more.bookWorkspace.searching : (cards.length > 0) ? areItems : noItems}
        </div>
        {
          this.state.isQuerying ? <Loader sizeRatio={1} style={{marginTop: '3rem'}} /> :
          <div id="available-units" role="none" className={"i-section i-scroll-items"}>
            {cards}
          </div>
        }
      </div>
    )
  }

  tabClicked = (event) => {
    const type = event.target.getAttribute("data-tabtype");
    this.setState({
      activeTab: type
    });
  }

  tabClickedReserManager =(type)=> {
    this.props.setRdxValue(Rdx.Keys.BOOK_TAB_SELECTED,type);
    if(type === "self") {
        this.setState({
            type: "self"
        })
    } else if (type === "others") {
        this.setState({
            type: "others"
        })
    }
  }

  renderTabs() {
    if (!shouldShowHotelTabs()) {
      return null;
    }

    const aiim = Context.getInstance().aiim;
    const isReservationManager = aiim && aiim.isReservationManager();

    const i18n = Context.instance.i18n;
    return (
      <div className={"i-hotel-tabs"}>
        {this.renderTab(i18n.more.bookWorkspace.tabs.available, TABS.available)}
        {this.renderTab(i18n.more.bookWorkspace.tabs.booked, TABS.booked)}
        {/* {isReservationManager && this.renderTab(i18n.more.bookWorkspace.tabs.others, TABS.others)} */}
      </div>
    );
  }

  renderTab(title, type) {
    const { activeTab } = this.state;
    const active = type === activeTab;
    const cls = active ? `${CSS.hotelTab} i--active` : `${CSS.hotelTab} i--inactive`;
    return (
      <button
        className={cls}
        role="tab"
        aria-selected={active}
        data-tabtype={type}
        onClick={this.tabClicked}
      >
        {title}
      </button>
    );
  }

  renderAvailable() {
    const cls = shouldShowHotelTabs() ? `${CSS.hotelscroll} i--tabs` : CSS.hotelscroll;

    return (
      <div className={cls}>
        {this.renderOptions()}
        {this.renderCards()}
      </div>
    );
  }

  renderNextWeek(dateOption) {
    const icon = Context.getInstance().uiMode.isRtl
      ? <ChevronLeftIcon size={16} />
      : <ChevronRightIcon size={16} />;

    if (dateOption === "thisweek") { 
      return (
        <Button
          className={CSS.dateRangeBtn}
          small={true}
          icon={icon}
          transparent={true}
          onClick={this.nextWeek}>
        </Button> 
      )
    } else if (dateOption === "thismonth") {
      return (
        <Button
          className={CSS.dateRangeBtn}
          small={true}
          icon={icon}
          transparent={true}
          onClick={this.nextMonth}>
        </Button> 
      )
    }
  }

  renderPrevWeek(dateOption) {
    const icon = Context.getInstance().uiMode.isRtl
      ? <ChevronRightIcon size={16} />
      : <ChevronLeftIcon size={16} />;
    const emptyDiv = <div className={CSS.dateRangeBtn}></div>;
    if (dateOption === "thisweek") {
      return this.countWeek === 0
        ? emptyDiv
        : <Button
            className={CSS.dateRangeBtn}
            small={true}
            icon={icon}
            transparent={true}
            onClick={this.prevWeek}>
          </Button>      
    } else if (dateOption === "thismonth") {
      return this.countMonth === 1
        ? emptyDiv
        : <Button
            className={CSS.dateRangeBtn}
            small={true}
            icon={icon}
            transparent={true}
            onClick={this.prevMonth}>
          </Button>      
    }
  }

  nextMonth=()=> {
    const criteria = this.getCriteria();
    this.countMonth = this.countMonth + 1;
    criteria.countMonth = this.countMonth;
    this.setBookingDateFilter(criteria);
  }

  nextWeek=()=> {
    const criteria = this.getCriteria();
    this.countWeek = this.countWeek + 1;
    criteria.countWeek = this.countWeek;
    this.setBookingDateFilter(criteria);
  }

  prevMonth=()=> {
    const criteria = this.getCriteria();
    this.countMonth = this.countMonth - 1;
    criteria.countMonth = this.countMonth;
    this.setBookingDateFilter(criteria);
  }

  prevWeek=()=> {
    const criteria = this.getCriteria();
    this.countWeek = this.countWeek - 1;
    criteria.countWeek = this.countWeek;
    this.setBookingDateFilter(criteria);
  }
  
  formatWeekDateRange(bookingDateFilter) {
    return `${moment(bookingDateFilter.dateStart).locale("en-us").format("MM/DD")} - ${moment(bookingDateFilter.dateEnd).locale("en-us").format("MM/DD")}`
  }

  formatMonth() {
    const { intl } = Context.getInstance().lib.esri;
    const a = intl.formatDate;
    return a(moment().add((this.countMonth-1), "month"), { month: "short", year: "numeric"});
  }

  renderDateLabel() {
    const { bookingDateFilter } = this.state;
    const { criteria } = bookingDateFilter || {};
    if (!(criteria && criteria.dateOption)) {
      return null;
    }
    let date = formatDateFilter(bookingDateFilter);
    const type = getHotelBookingSystemType();
    const dateOption = criteria && criteria.dateOption;
    if (type === "office365" && dateOption === "thisweek") {
      date = this.formatWeekDateRange(bookingDateFilter);    
    } else if (type === "office365" && dateOption === "thismonth") {
      date = this.formatMonth();
    }
    return <div className={CSS.dateLabel}>{date}</div>;
  }
  renderFilters() {
    const i18n = Context.getInstance().i18n;
    const cls = shouldShowHotelTabs() ? `${CSS.hotelscroll} i--tabs` : CSS.hotelscroll;
    let showSpaceFilters = true;
    if (this.state.reserveForToggling || (this.state.reserveForOn && !this.state.reserveForEmail)) {
      showSpaceFilters = false;
    }

    const onSubmit = () => {
      Topic.publish(Topic.DurationFiltersSet, {});
    }
    const onReserveForChange = (info) => {
      const reserveForOn = (info && info.areaIDs);
      const reserveForEmail = (info && info.email);
      const reserveForInfo = reserveForEmail && info;
      this.resetSpaceFilterRdxValues();
      this.initFilters();
      setTimeout(() => {
        this.setState({
          reserveForToggling: true
        }, () => {
          this.setState({
            reserveForOn,
            reserveForEmail,
            reserveForInfo,
            reserveForToggling: false
          })
        })
      },10)
    }

    let cls2 = CSS.filterForm;
    if ((getHotelBookingSystemType() !== "office365") &&
        Context.instance.aiim.isReservationManager()) {
      cls2 += " i-initial-hotel-criteria";
    };

    return (
      <div className={cls}>
        <div className={cls2}>
          <DurationFilters initialFilter={true} onRecurrenceUpdated={(recurrence) => {
            this.setState({
              findDisabled: recurrence?.enabled && recurrence?.type === "weekly" && recurrence.days?.length === 0
            })
          }}/>
          <ReserveFor bookingType="hotel" onChange={onReserveForChange}/>
          {showSpaceFilters &&
            <SpaceFilters
              initialFilter={true}
              siteFilters={this.state.siteFilters}
              spaceTypeFilters={this.state.spaceTypeFilters}
              areaFilters={this.state.areaFilters}
              equipmentFilters={this.state.equipmentFilters}
            />
          }
          {showSpaceFilters &&
            <div className={CSS.findHotelsButton}>
              <Button disabled={this.state.findDisabled} className="i-button-theme-color" onClick={onSubmit}>
                {Context.instance.aiim.getFindHotelsButtonLabel()}</Button>
            </div>
          }
        </div>
      </div>
    )
  }

  getCriteria = (): IDateCriteria => {
    const criteria = {
      dateOption: this.props.rdxDateOption,
      dateSelected: this.props.rdxDateSelected,
      countWeek: this.countWeek,
      countMonth: this.countMonth
    }
    return criteria;
  }

  renderBookings() {
    const cls = shouldShowHotelTabs() ? `${CSS.hotelscroll} i--tabs` : CSS.hotelscroll;
    const isReservationManager = Context.getInstance().aiim
                                  && Context.getInstance().aiim.isReservationManager();
    const type = getHotelBookingSystemType();
    const i18n = Context.instance.i18n;
    const dateOption = this.getCriteria() && this.getCriteria().dateOption;

    if (isReservationManager && type === "esri") {
      const type = this.props.rdxTabSelected;
      return (
        <div className={cls}>
          <div className={`${CSS.optionsContainer} ${CSS.optionsContainerManager}`}>
            <BookingDateFilter type='bookings' onFilterChange={(data) => {
              this.setState({ bookingDateFilter: data })}} />
            <button title="For you" type="button"
              className={`i-booktab-button-clear${type === "self" ? " i--active" : ""}`}
              onClick={()=> {this.tabClickedReserManager("self")}}>
              <span className="i-font-size-bookingtab">{i18n.more.bookWorkspace.newTabs.forYou}</span>
            </button>
            <button title="For others" type="button"
              className={`i-booktab-button-clear${type !== "self" ? " i--active" : ""}`}
              onClick={()=> {this.tabClickedReserManager("others")}}>
              <span className="i-font-size-bookingtab">{i18n.more.bookWorkspace.newTabs.forOthers}</span>
            </button>
            {this.renderRefresh()}
            {this.renderDateLabel()}
          </div>
          <BookingsTab bookingDateFilter={this.state.bookingDateFilter}/>
        </div>
      );
    } else {    
      const dateFilter = (
        <div className={CSS.optionsContainer}>
          <BookingDateFilter type='bookings' onFilterChange={data =>
            this.setState({ bookingDateFilter: data })} />
          {this.renderRefresh()}
          {this.renderDateLabel()}
        </div>
      )      
      const isRange = dateOption === "thisweek" || dateOption === "thismonth";
      return (
        <div className={cls}>
          {type === "esri" && (
            <>
              {dateFilter}
              <MyBookings bookingDateFilter={this.state.bookingDateFilter}/>
            </>)}
          {type === "office365" && (
            <>              
              <div className={CSS.optionsContainer}>
                <BookingDateFilter type='bookings' isOffice365={true} onFilterChange={(data) => {
                  this.countWeek = 0;
                  this.countMonth = 1;
                  this.setState({ bookingDateFilter: data })}} />
                {this.renderRefresh()}
                <div className={isRange ? CSS.dateRange : CSS.date}>   
                  {isRange && this.renderPrevWeek(dateOption)}
                  {this.renderDateLabel()}
                  {isRange && this.renderNextWeek(dateOption)}
                </div>               
              </div>
              <MyBookingsOffice365 bookingDateFilter={this.state.bookingDateFilter} />
            </>
          )}
        </div>
      )
    }
  }

  // renderOtherBookings() {
  //   const aiim = Context.getInstance().aiim;
  //   if (!aiim || !aiim.isReservationManager()) {
  //     return null;
  //   }

  //   const cls = shouldShowHotelTabs() ? `${CSS.hotelscroll} i--tabs` : CSS.hotelscroll;
  //   return (
  //     <div className={cls}>
  //       <OtherBookings />
  //     </div>
  //   );
  // }

  renderContent() {
    const { activeTab, noFilters, noUnitsAtAllMsg } = this.state;
    switch (activeTab) {
      case TABS.booked:
        return this.renderBookings();
      // case TABS.others:
      //   return this.renderOtherBookings();
      case TABS.available:
      default:
        if (noUnitsAtAllMsg) {
          return (
            <div className={CSS.hotelscroll+" i--tabs"}>
              <div style={{padding: "0.7rem 1rem"}}>
                {noUnitsAtAllMsg}
              </div>
            </div>
          )
        }
        return noFilters ? this.renderFilters() : this.renderAvailable();
    }
  }

  renderLoader() {
    return (<Loader sizeRatio={1} style={{ marginTop: "3rem" }} />);
  }

  render() {
    return (
      <div className={CSS.hotels}>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.state.isQuerying ? this.renderLoader() : this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdxSidebarActiveKey: Rdx.getValue(state, Rdx.Keys.SIDEBAR_ACTIVE_KEY),
    rdxCheckIn: Rdx.getValue(state, Rdx.Keys.BOOK_CHECKIN),
    rdxCheckOut: Rdx.getValue(state, Rdx.Keys.BOOK_CHECKOUT),
    rdxStartDate: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDate: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTime: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTime: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDay: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY),
    rdxSiteFilters: Rdx.getValue(state,Rdx.Keys.SITE_FILTERS),
    rdxSpaceTypeFilters: Rdx.getValue(state, Rdx.Keys.SPACETYPE_FILTERS),
    rdxAreaFilters: Rdx.getValue(state, Rdx.Keys.AREA_FILTERS),
    rdxEquipmentFilters: Rdx.getValue(state, Rdx.Keys.EQUIPMENT_FILTERS),
    rdxFilteredResults: Rdx.getValue(state, Rdx.Keys.BOOK_FILTERED_RESULTS),
    rdxFilteredResultsNoEmail: Rdx.getValue(state, Rdx.Keys.BOOK_FILTERED_RESULTS_NO_EMAIL),
    rdxSortSortBy: Rdx.getValue(state, Rdx.Keys.BOOK_SORT_SORTBY),
    rdxSortSortDir: Rdx.getValue(state, Rdx.Keys.BOOK_SORT_SORTDIR),
    rdxWasSortInit: Rdx.getValue(state, Rdx.Keys.BOOK_WAS_SORT_INIT),
    rdxDateOption: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_DATESELECTED),
    rdxTabSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TAB_SELECTED),
    rdxRecurrence: {
      enabled: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENABLED),
      endDate: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENDDATE),
      type: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_TYPE),
      days: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_DAYS),
      interval: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_INTERVAL)
    }
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRdxValue: (key: string, value: any) => dispatch(setValue(key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookWorkspace);
