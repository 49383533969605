import React from "react";
import { connect } from "react-redux";
import Rdx from "../../../redux/Rdx";

// Styled Components
import { Container } from "../../styles/Assets/tabPanel";

class TabPanel extends React.Component {
  render() {
    const active = this.props.rdxActiveTabKey === this.props.tabKey;

    if (!active) {
      return null;
    }

    return <Container active={active}>{this.props.children}</Container>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
  };
};

export default connect(mapStateToProps)(TabPanel);
