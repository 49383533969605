import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import OccupantsVM from "./OccupantsVM";

import AssignmentTypeFilter from "../filter/AssignmentTypeFilter";
import SiteFacilityLevelFilter from "../filter/SiteFacilityLevelFilter";
import UniqueValueFilter from "../filter/UniqueValueFilter";

import { IFilterCriteria, IOccupantsCriteria } from "../../common/types";
import * as component from "../../../../components/util/component";
import * as whereUtil from "../filter/whereUtil";


import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-modal";
import {
  CalciteButton,
  CalciteModal
} from "@esri/calcite-components-react";

interface Props {
  active: boolean,
  criteria: IOccupantsCriteria,
  onClose: () => void,
  onCriteriaChange: (criteria: IOccupantsCriteria) => void,
  viewModel: any
}

interface State {
  filterCriteria: IFilterCriteria,
  working: boolean
}

class Filter extends React.Component<Props, State> {

  asyncStamp;
  layerType: string = "occupants";
  viewModel: OccupantsVM;

  constructor(props) {
    super(props);
    const fc = JSON.parse(JSON.stringify(this.props.criteria.filter))
    this.state = component.newState({
      filterCriteria: fc,
      working: false
    });
    this.viewModel = new OccupantsVM();
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { active } = this.props;
    return (
      <CalciteModal 
        widthScale="s"
        scale="s"
        open={active ? true : undefined}
        onCalciteModalClose={this.props.onClose}
      >
        <div slot="header">{i18n.miniapps.assignments.filter.occupantsCaption}</div>
        <div slot="content">{this.renderContent()}</div>
        {this.renderActions()}
      </CalciteModal>
    );
  }

  renderActions() {
    const i18n = Context.instance.i18n;
    const { filterCriteria } = this.state;
    const { criteria } = this.props;
    const applyCriteria = (fc: IFilterCriteria) => {
      const c = JSON.parse(JSON.stringify(criteria))
      c.filter = fc;
      c.start = 0;
      this.props.onCriteriaChange(c);
      this.props.viewModel.setCriteria(c);
    }
    const onOkClicked = (e) => {
      const where = whereUtil.makeFilterWhere(filterCriteria);
      const fc = JSON.parse(JSON.stringify(filterCriteria))
      fc.where = where;
      applyCriteria(fc);
      this.props.onClose();
    }
    const onCancelClicked = (e) => {
      this.props.onClose();
    }
    const onClearClicked = (e) => {
      const fc = this.props.viewModel.newCriteria().filter;
      applyCriteria(fc);
      this.props.onClose();
    }
    return (
      <>
        <CalciteButton slot="primary" onClick={onOkClicked}>
          {i18n.miniapps.assignments.filter.applyFilter}
        </CalciteButton>
        <CalciteButton slot="secondary" appearance="outline" onClick={onCancelClicked}>
          {i18n.general.cancel}
        </CalciteButton>
        <CalciteButton slot="back" appearance="outline" onClick={onClearClicked}>
          {i18n.miniapps.assignments.filter.clearFilter}
        </CalciteButton>
      </>
    )
  }

  renderContent() {
    const layerType = this.layerType;
    const { filterCriteria } = this.state;

    const setFieldValues = (fieldsIndex,values,where) => {
      const fc = JSON.parse(JSON.stringify(filterCriteria))
      const f = fc.fields[fieldsIndex];
      f.values = values;
      f.where = where;
      this.setState({filterCriteria: fc})
    }

    const fieldFilters = filterCriteria.fields.map((f,i) => {
      return (
        <UniqueValueFilter key={f.field} layerType={layerType} 
          filterCriteria={filterCriteria} field={f.field} values={f.values}
          onChange={(values,where) => setFieldValues(i,values,where)}
        />
      )
    })

    return (
      <div className="i-modal-content-tall i-modal-content-filter">
        <SiteFacilityLevelFilter layerType={layerType} filterCriteria={filterCriteria}
          onChange={fc => {
            this.setState({filterCriteria: fc})
          }}
        />
        <AssignmentTypeFilter layerType={layerType} filterCriteria={filterCriteria}
          onChange={(type,where) => {
            const fc = JSON.parse(JSON.stringify(filterCriteria))
            const asn = fc.assignmentType;
            asn.type = type;
            asn.where = where;
            this.setState({filterCriteria: fc})
          }}
        />
        {fieldFilters}
      </div>
    )
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(Filter);