import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../OfficePlan";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as transaction from "./transaction";
import {addAttributeEdit} from "./transactionUtil";
import Context from "../../../context/Context";


export function prepare(task) {
  return new Promise((resolve, reject) => {
    const transactionInfo = transaction.makeTransactionInfo()
    transactionInfo.supportsUndo = false;
    const portalMembers = task.portalMembers
    const usersToRemove = task.usersToRemove
    const removeAll = task.removeAll
    const username = task.username
    const status = task.status
    const planId = task.planId || Context.instance.spaceplanner.planId
    const removeReviewer = task.removeReviewer
    const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable

    const makeReviewersTableEdits = () => {
      const table = reviewersTable.table
      const fields = table.fields
      const keyField = table.globalIdField

      if (portalMembers) {
        portalMembers.forEach(member => {
          const edit = {attributes: {}}
          addAttributeEdit(fields, edit.attributes, FieldNames.USERNAME, member)
          addAttributeEdit(fields, edit.attributes, FieldNames.PLAN_ID, planId)
          addAttributeEdit(fields, edit.attributes, FieldNames.REVIEW_STATUS, "pending")

          if (!transactionInfo.supportInfo.reviewersTableEdits) {
            transactionInfo.supportInfo.reviewersTableEdits = {adds: [edit]}
          } else if (transactionInfo.supportInfo.reviewersTableEdits.adds) {
            transactionInfo.supportInfo.reviewersTableEdits.adds.push(edit)
          }
          
          transactionInfo.edits.push({
            id: table.layerId,
            adds: [edit]
          })
          transactionInfo.useGlobalIds = false
          transactionInfo.supportInfo.serviceUrl = table.url
        })
      } else if (removeAll && usersToRemove) { // FOR TESTING
        task.usersToRemove.forEach(user => {
          const key = user.attributes[keyField]
          if (!transactionInfo.supportInfo.reviewersTableEdits) {
            transactionInfo.supportInfo.reviewersTableEdits = {deletes: [key]}
          } else if (transactionInfo.supportInfo.reviewersTableEdits.deletes) {
            transactionInfo.supportInfo.reviewersTableEdits.deletes.push(key)
          }

          transactionInfo.edits.push({
            id: table.layerId,
            deletes: [key]
          })
          transactionInfo.supportInfo.serviceUrl = table.url
        })
      } else if (username && status) {
        const reviewers = reviewersTable._reviewersByPlanId[planId].reviewers
        if (reviewers && Array.isArray(reviewers) && reviewers.length > 0) {
          let user = null
          reviewers.forEach(r => {
            const name = aiimUtil.getAttributeValue(r.attributes, FieldNames.USERNAME)
            if (name && name.toLowerCase() === username.toLowerCase()) {
              user = r
            }
          })
          if (user) {
            const key = user.attributes[keyField]
            const update = {attributes: {}}
            update.attributes[keyField] = key
            addAttributeEdit(fields, update.attributes, FieldNames.REVIEW_STATUS, status)
            transactionInfo.supportInfo.reviewersTableEdits = {updates: [update]}
            transactionInfo.edits.push({
              id: table.layerId,
              updates: [update]
            })
          }
        }
      } else if (username && planId && !removeReviewer) {
        const edit = {attributes: {}}
        addAttributeEdit(fields, edit.attributes, FieldNames.USERNAME, username)
        addAttributeEdit(fields, edit.attributes, FieldNames.REVIEW_STATUS, "pending")
        addAttributeEdit(fields, edit.attributes, FieldNames.PLAN_ID, planId)

        transactionInfo.supportInfo.reviewersTableEdits = {adds: [edit]}
        transactionInfo.edits.push({
          id: table.layerId,
          adds: [edit]
        })
        transactionInfo.useGlobalIds = false
        transactionInfo.supportInfo.serviceUrl = table.url
      } else if (username && removeReviewer) {
        const reviewers = reviewersTable._reviewersByPlanId[planId].reviewers
        if (reviewers && Array.isArray(reviewers) && reviewers.length > 0) {
          let user = null
          reviewers.forEach(r => {
            const name = aiimUtil.getAttributeValue(r.attributes, FieldNames.USERNAME)
            if (name && name.toLowerCase() === username.toLowerCase()) {
              user = r
            }
          })
          const key = user.attributes[keyField]
          if (!transactionInfo.supportInfo.reviewersTableEdits) {
            transactionInfo.supportInfo.reviewersTableEdits = {deletes: [key]}
          } else if (transactionInfo.supportInfo.reviewersTableEdits.deletes) {
            transactionInfo.supportInfo.reviewersTableEdits.deletes.push(key)
          }

          transactionInfo.edits.push({
            id: table.layerId,
            deletes: [key]
          })
          transactionInfo.supportInfo.serviceUrl = table.url
        }
      }
      transactionInfo.supportInfo.serviceUrl = table.url
    }

    Promise.resolve().then(() => {
      makeReviewersTableEdits()
    }).then(() => {
      resolve(transactionInfo)
    }).catch(e => {
      console.error("Error editing reviewers table", e)
      reject(e)
    })
  })
}