import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import BaseClass from "../util/BaseClass";
import Compass from "../components/map/Compass/Compass";
import Context from "./Context";
import FloorFilter from "./FloorFilter";
import LevelFilter from "../components/map/LevelFilter/LevelFilter";
import LevelFilterSafari from "../components/map/LevelFilter/LevelFilterSafari";
import Locate from "../components/map/Locate/Locate";
import Measurement from "../components/map/Measurement/Measurement";
import MapSelection from "../spaceplanner/components/MapSelection/MapSelection";
import Rdx from "../redux/Rdx";
import Topic from "./Topic";
import UndoRedo from "../spaceplanner/miniapps/common/components/undo/UndoRedo";
import ViewToggle from "../components/map/ViewToggle/ViewToggle";
import * as aiimUtil from "../aiim/util/aiimUtil";
import * as component from "../components/util/component";
import * as localStore from "../util/localStore";
import * as mediaUtil from "../spaceplanner/components/Editor/media//mediaUtil";
import * as tsUtil from "../util/tsUtil";
import * as viewUtil from "../util/viewUtil";
import { getConstraints } from "../spaceplanner/base/mapUtil";

export default class Views extends BaseClass {

  activeView: __esri.MapView | __esri.SceneView;
  mapView: __esri.MapView;
  sceneView: __esri.SceneView;
  floorFilter: FloorFilter;
  startIn3D;
  mapClickDisabled = false;
  relatedConfig = null;

  _handles = [];

  _measurement2d;
  _measurement3d;

  _mapViewClickHandler;
  _sceneViewClickHandler;

  _ll2d;
  _ll3d;
  _llex2d;
  _llex3d;

  _addLayerList(view: __esri.MapView | __esri.SceneView, expanderOnly?: boolean) {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const lib = context.lib;
    const uiMode = context.uiMode;
    const position = "top-leading";

    if (!Context.instance.uiMode.isKiosk &&
        !Context.instance.appMode.isSP_or_FPE()) {

      let layerList;
      if (!expanderOnly) {
        layerList = new lib.indoors.CustomLayerList({
          indoorsContext: context,
          tsUuil: tsUtil,
          view: view,
          listItemCreatedFunction: (event) => {
            //console.log("listItemCreatedFunction.event",event);
            const item = (event && event.item);
            if (item && item.layer) {
              if (item.layer.declaredClass === "esri.layers.MapImageLayer") {
                if (!item.open) item.open = true;
              }
            }
          }
        });
        this["_ll"+view.type] = layerList;
      }

      let expander = this["_llex"+view.type];
      if (expander) {
        expander.content = layerList;
      } else {
        expander = new lib.esri.Expand({
          view: view,
          content: layerList,
          expandIconClass: "esri-icon-layers",
          expandTooltip: i18n.map.layerList.tip,
          collapseTooltip: i18n.map.layerList.collapse,
          group: "map-tools"
        });
        view.ui.add(expander,position);
        this["_llex"+view.type] = expander;

        if (this.mapView && view.type === "2d") {
          this.mapView.map.allLayers.forEach(lyr => {

            if (lyr && typeof lyr.when === "function") {
              lyr.when(()=> {
                lib.esri.reactiveUtils.watch(() => lyr.visible, v => {
                  Topic.publish(Topic.LayerVisibilityChanged, {});
                })
                if (lyr["allSublayers"]) {
                  const items = (lyr as
                    | __esri.MapImageLayer
                    | __esri.TileLayer
                    // | __esri.BuildingSceneLayer
                    // | __esri.WMSLayer
                  ).allSublayers;
                  items.forEach(subLayer => {
                    lib.esri.reactiveUtils.watch(() => subLayer.visible, v => {
                      Topic.publish(Topic.LayerVisibilityChanged, {});
                    })
                  })
                }
              })
            }
          })
        }
      }
    }
  }
  private _addCompass(view: __esri.MapView | __esri.SceneView, position: __esri.UIAddPosition) {
    view.ui.remove("compass");
    const compassNode = document.createElement("div");
    compassNode.classList.add("i-compass");
    view.ui.add(compassNode, position);
    ReactDOM.render(<Compass view={view}/>, compassNode);
  }
  _getLegendEnabledLayers (view) {
    let temp = [];
    const items = view && view.map && view.map.allLayers && view.map.allLayers.items;
    if (!items || items.length === 0) return;
    items.forEach((layer)=> {
      if(layer && layer.legendEnabled) temp.push({layer: layer, title: layer.title});
    })
    return temp;
  }
  private _getPositions(): [
    __esri.UIAddPosition["position"],
    __esri.UIAddPosition["position"],
    __esri.UIAddPosition["position"]
  ] {
    const navigation = "top-leading";
    const floorFilter = "top-trailing";
    const mapTools = "bottom-leading";

    return [navigation, floorFilter, mapTools];
  }
  _addLegend (view) {
    if (Context.instance.uiMode.isKiosk || Context.instance.appMode.isSP_or_FPE()) return;
    let expander = view.ui.find("legendExpander");
    let content = expander && expander.content;
    if (expander && content) return;

    const context = Context.getInstance();
    const lib = context.lib;
    const i18n = context.i18n;
    const uiMode = context.uiMode;
    const position = "top-leading";

    view.when(()=> {
      let layerInfos = [];
      layerInfos = this._getLegendEnabledLayers(view);

      const legend = new lib.esri.Legend({
        view: view,
        layerInfos: layerInfos
      })
      if (expander) {
        expander.content = legend;
        return;
      }

      const lgExpand = new lib.esri.Expand({
        view: view,
        content: legend,
        id: "legendExpander",
        expandIconClass: "esri-icon-legend",
        expandTooltip: i18n.map.legend.tip,
        collapseTooltip: i18n.map.legend.collapse,
        group: "map-tools"
      })

      view.ui.add(lgExpand, {position: position, index: 5});
      lib.esri.reactiveUtils.watch(() => lgExpand.expanded, v => {
        try {
          const list = document.getElementsByClassName("esri-legend esri-widget esri-widget--panel");
          if (list && list.length > 0) {
            const legendCss = list[0] as HTMLElement;
            legendCss.style.maxHeight = "420px";
            legendCss.style.height = "300px";
          }
          const layerTitleCss = Array.from(document.getElementsByClassName("esri-widget__heading esri-legend__service-label"));
          if (layerTitleCss && layerTitleCss.length > 0) {
            layerTitleCss.forEach((titleDOM: HTMLElement)=> {
              titleDOM.style.maxWidth = "320px";
            })
          }
        } catch (ex0) {
        }
      })

    })
  }
  
  _addMeasurement(view) {
    const context = Context.getInstance();
    const { lib, appMode, uiMode, i18n, config } = context;
    if (uiMode.isKiosk) return;
    if (!(appMode.isSP_or_FPE() || config.measure.enabled)) return;
  
    const expander = view.ui.find("measurementExpander");
    const content = expander && expander.content;
    if (expander && content) return;
    const position = "top-leading";
    
    if (uiMode.isMobile) return;

    const coreWidget = new lib.esri.Measurement({
      view: view,
    });
    if (view.type === "2d") this._measurement2d = coreWidget;
    if (view.type === "3d") this._measurement3d = coreWidget;

    const measurementNode = document.createElement("div");
    ReactDOM.render(<Measurement view={view} coreWidget={coreWidget} disableMapClick={this._disableMapClick} />, measurementNode);
    const component = new lib.esri.Expand({
      view: view,
      content: measurementNode,
      id: 'measurementExpander',
      expandIconClass: 'esri-icon-measure',
      expandTooltip: i18n.map.measurement.tip,
      collapseTooltip: i18n.map.measurement.collapse,
      group: "map-tools",
      mode: "floating"
    });
    
    // Clear graphics on the view when widget collapsed
    lib.esri.reactiveUtils.watch(() => component.expanded, expanded => {
      if (!expanded) {
        coreWidget.clear();
        this._disableMapClick(false);
      }
    })
    
    view.ui.add([{ component, position, index: appMode.isSP_or_FPE() ? 3 : 2}]);
  }
  
  _disableMapClick = (isDisabled) => {
    this.mapClickDisabled = isDisabled;
  }
  
  _addScalebar(view) {
    const context = Context.getInstance();
    const { lib, appMode, config, } = context;

    //Check in the configuration file if scalebar has to be displayed
    const sbar = context.config.scalebar;
    let units = sbar.units;
    if (sbar.show && view.type === "2d") {
      //Addition of the scalebar component to the MapView
      //Retrive accurate Scalebar details depending on the locale
      if (units === "auto"){
        units = context.user.getUnit();
      }
      if (units === "metric"|| units === "non-metric"|| units === "dual"){
      } else {
        console.log("Switching to Dual scalebar from",units);
        units = "dual"
      }
      const scalebar= new lib.esri.ScaleBar({
        view: view,
        unit: units,
        style: sbar.style
      });
      view.ui.add(scalebar, "bottom-leading");
    }

  }
  private _addSpaceplannerWidgets(view: __esri.MapView | __esri.SceneView, position: __esri.UIAddPosition["position"]) {
    const context = Context.getInstance();
    const { appMode, config, lib, i18n } = context;
    if (appMode.isSP()) {
      const msNode = document.createElement("div");
        ReactDOM.render((
          <Provider store={Rdx.store}>
            <MapSelection view={view} />
          </Provider>
        ), msNode);
        
      const mapSelExpand = new lib.esri.Expand({
        view: view,
        content: msNode,
        expandIconClass: "esri-icon-cursor-filled",
        expandTooltip: i18n.spaceplanner.mapSelection.tip,
        collapseTooltip: i18n.spaceplanner.mapSelection.collapse,
        group: "map-tools"
      });
      mapSelExpand.xtnIsMapSelector = true;
      if (mapSelExpand.domNode && mapSelExpand.domNode.classList) {
        mapSelExpand.domNode.classList.add("i-expander-map-selection");
      }
      view.ui.add(mapSelExpand, position);

      this._handles.push(mapSelExpand.watch("expanded", (newVal, oldVal, property, object) => {
        Topic.publish(Topic.ExpanderChanged, {
          type: "MapSelection",
          property: property,
          newVal: newVal,
          oldVal: oldVal
        });
      }));
      this._handles.push(mapSelExpand.watch("domNode", (newVal, oldVal, property, object) => {
        if (newVal && newVal.classList) {
          newVal.classList.add("i-expander-map-selection");
        }
      }));
      this._handles.push(Topic.subscribe(Topic.MoveOccupants_Show, () => {
        mapSelExpand.collapse();
      }));      
    }

    if (appMode.isFPE()) {
      this._addUndoRedo(view,"top-leading");
    }
  }

  _addUndoRedo(view,position) {
    view.when(()=> {
      const vtNode = document.createElement("div");
      view.ui.add(vtNode,position);
      ReactDOM.render(<Provider store={Rdx.store}><UndoRedo /></Provider>,vtNode);
    })
  }

  _addViewToggle(view) {
    if (!view) return;
    if(view.ui.find("i--view-toggle")) return;
    view.when(()=> {
      const position = "top-leading";
      if (this.sceneView && this.mapView) {
        const vtNode = document.createElement("div");
        vtNode.setAttribute("data-i-isViewToggle","y");
        vtNode.id = "i--view-toggle";
        view.ui.add(vtNode,position);
        ReactDOM.render(<ViewToggle view={view} clickHandler={() => {this._toggleView();}}/>,vtNode);
      }
    })
  }
  
  _moveHome(view) {
    const position = "top-leading";
    const homeWidget = view.ui.find('homeWidget');
    if (homeWidget) view.ui.move(homeWidget, {position: position, index: 6});
  }

  _addWidgets(view: __esri.MapView | __esri.SceneView) {
    //if (true) return;
    //if (view.type === "3d") return;
    const context = Context.getInstance();
    const { i18n, lib, appMode, bluedot } = context;
    const [navigation, floorFilter, mapTools] = this._getPositions();

    try {
      view.ui.move(["zoom", "navigation-toggle"], "top-leading");
    } catch(ex) {
      console.error(ex)
    }

    const home = new lib.esri.Home({view: view, id: "homeWidget", icon: "globe"});
    view.ui.add(home, { position: navigation });

    if (bluedot && bluedot.enabled) {
      const locateNode = document.createElement("div");
      view.ui.add(locateNode, navigation);
      ReactDOM.render(<Locate view={view}/>, locateNode);
    }

    // let track = new lib.esri.Track({
    //   view: view
    // });
    // view.ui.add(track,"top-left");

    this._addScalebar(view);
    this._addMeasurement(view);

    if(!Context.instance.uiMode.isKiosk && appMode.supportsBasemapGallery()){
      const basemapGallery = new lib.esri.BasemapGallery({
        view: view,
        container: document.createElement("div")
      });
      const basemapGalleryExpand = new lib.esri.Expand({
        view: view,
        content: basemapGallery,
        expandIconClass: "esri-icon-basemap",
        expandTooltip: i18n.map.basemapGallery.tip,
        collapseTooltip: i18n.map.basemapGallery.collapse,
        group: "map-tools"
      });
      
      document.addEventListener("keyup", function(event) {
        if (event && event.keyCode === 9) {
          if (basemapGalleryExpand && basemapGalleryExpand.viewModel && basemapGalleryExpand.viewModel.expanded) {
            const activeElement = document.activeElement
            const basemapElems = document.getElementsByClassName("esri-basemap-gallery__item")
            if (basemapElems && basemapElems.length > 0) {
              for (let i = 0; i < basemapElems.length; i++) {
                if (activeElement === basemapElems[i]) return
              }
            }
            basemapGalleryExpand.collapse()
          }
        }
      })
      view.ui.add(basemapGalleryExpand,navigation);
      this._watchBasemapGallery(view,basemapGallery,basemapGalleryExpand);
    }
    if (view.type === "2d") this._addLayerList(view);
    else this._addLayerList(view,true);
    
    if (appMode.appType === 'indoors') this._moveHome(view);
    
    this._addCompass(view, {
      position: navigation,
      index: 0
    });
    
    const map = view.map as __esri.WebMap;
    if (!map.floorInfo) {
      // Old Indoors floor filter
      const lfNode = document.createElement("div");
      view.ui.add(lfNode,floorFilter);
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        ReactDOM.render(<LevelFilterSafari view={view} domNode={lfNode}/>,lfNode);
      } else {
        ReactDOM.render(<LevelFilter view={view} domNode={lfNode}/>,lfNode);
      }
    } else {
      if (!this.floorFilter) {
        this.floorFilter = new FloorFilter();
      }
      // New JSAPI FloorFilter
      this.floorFilter.init(view);
    }

    this._addSpaceplannerWidgets(view, mapTools);
  }

  destroy() {
    try {
      component.clearHandles(this._handles);
      this._destroyLegend(this.mapView);
      this._destroyLegend(this.sceneView);
      this._removeMeasurement(this.mapView);
      this._removeMeasurement(this.sceneView);
      if (this.mapView) this.mapView.container = null;
      if (this.sceneView) this.sceneView.container = null;
      if (this.mapView) this.mapView.destroy();
      if (this.sceneView) this.sceneView.destroy();
      this._ll2d = this._ll3d = this._llex2d = this._llex3d = null;
    } catch(ex) {
      console.error("Error destroying views",ex);
    }

    try {
      if (this.floorFilter) {
        this.floorFilter.destroy();
      }
    } catch(ex) {
      console.error("Error destroying views",ex);
    }
  }

  _destroyLegend(view) {
    try {
      let expander = view && view.ui.find("legendExpander");
      let content = expander && expander.content;
      if (content) {
        // JSAPI 4.24 issue, need to destroy the active legend, then recreate as needed
        expander.content = null;
        content.destroy();
      }
    } catch(ex) {
      console.error(ex);
    }
  }
  _destroyMeasurement(view) {
    try {
      if (view.type === "2d") {
        if (this._measurement2d) {
          this._measurement2d.destroy();
          this._measurement2d = null;
        }
      }
      if (view.type === "3d") {
        if (this._measurement3d) {
          this._measurement3d.destroy();
          this._measurement3d = null;
        }
      }
    } catch(ex) {
      console.error(ex);
    }
  }
  _fetchRelatedConfig(webmap: __esri.WebMap): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      if (webmap && webmap.portalItem) {
        const params: __esri.PortalItemFetchRelatedItemsParams = {
          relationshipType: "Map2IndoorsConfig",
          direction: "forward"
        };
        webmap.portalItem.fetchRelatedItems(params).then(items => {
          if (items && items.length > 0) {
            const item = items[0];
            return item.fetchData();
          }
          return null;
        }).then(data => {
          if (data) this.relatedConfig = data;
        }).then(() => {
          resolve();
        }).catch(ex => {
          console.error("Error fetching related config item",ex);
          resolve();
        });
      } else {
        resolve()
      }
    });
    return promise;
  }

  getInactiveView() {
    if (this.activeView === this.mapView) return this.sceneView;
    if (this.activeView === this.sceneView) return this.mapView;
    return null;
  }

  load(): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const { config, appMode } = Context.getInstance();
      const webmapId = config.webmap;
      const websceneId = config.webscene;
      const mapDivId = Context.instance.appMode.mapDivId;
      const node = document.getElementById(mapDivId);
      if (!node) {
        resolve();
        return;
      }
      const container = document.createElement("div");
      let webMap;
      node.appendChild(container);
      if (Context.instance.appMode.appType === "indoors") {
        node.classList.add("i-map");
      }
      this._loadWebMap(webmapId).then(result => {
        // console.log("webmap",result)
        webMap = result;
        return this._fetchRelatedConfig(webMap);
      }).then(() => {
        return appMode.isFPE()
          ? getConstraints(webMap)
          : Promise.resolve();
      }).then((constraints: __esri.MapViewConstraints) => {
        Topic.publish(Topic.WebMapLoaded,{});
        this._makeMapView(webMap, constraints);
        return this._loadWebScene(websceneId);
      }).then(webScene => {
        this._makeSceneView(webScene,null);
        if (!this.activeView && this.mapView) {
          this.activeView = this.mapView;
          this.activeView.container = container;
        }
        if ((!this.activeView && this.sceneView) ||
            (this.startIn3D && this.sceneView)) {
          this.activeView = this.sceneView;
          this.activeView.container = container;
        }
        if (this.mapView && !this.sceneView) {
          this._removeViewToggle(this.mapView);
        }
        if (!this.mapView && this.sceneView) {
          this._removeViewToggle(this.sceneView);
        }
        if (this.activeView) {
          return this.activeView.when(this._addMapLabel);
        }
      }).then(() => {
        if (Context.instance.appMode.appType === "indoors") {
          viewUtil.watchCursors(this.mapView,this.sceneView);
        }
      }).then(() => {
        this._checkForLayerServices();
      }).then(() => {
        if (this.activeView) {
          if (this.mapView) this._addWidgets(this.mapView)
          if (this.sceneView) this._addWidgets(this.sceneView)
          mediaUtil.fixOpacityOnLoad(this.mapView);
          Topic.publish(Topic.ViewActivated,{view: this.activeView});
        }
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
    return promise;
  }

  _loadWebMap(id) {
    if (!id) {
      console.warn("No webmap was specified")
      return Promise.reject(new Error("No webmap was specified"));
    }
    const lib = Context.getInstance().lib;
    const webmap = new lib.esri.WebMap({
      portalItem: {id: id}
    });
    return webmap.load();
  }

  _loadWebScene(id) {
    if (!Context.instance.appMode.supports3D()) return Promise.resolve(null);
    const i18n=Context.getInstance().i18n;
    const promiseObj=new Promise((resolve,reject)=>{
        if (!id) {
          return resolve(null)
        };
        if (!Context.getInstance().supports3D) return resolve(null);
        const lib = Context.getInstance().lib;
        const webscene = new lib.esri.WebScene({
          portalItem: {id: id}
        });
        webscene.load().then(()=>{
          resolve(webscene);
        }).catch(error=>{
          if(!Context.getInstance().uiMode.isKiosk){
            Context.getInstance().session.startupToast={
              message:i18n.messages.sceneIdNotFound
             };
          }
          resolve(null);
        });
    })
    return promiseObj;
  }

  _checkForLayerServices(){

    const i18n=Context.getInstance().i18n;
    const checkLayers=(view, prompt)=>{
      let errorMessages=[];
      const layers = aiimUtil.getLayers(view);
      layers.forEach(layer => {
        if(layer.loadStatus==="failed"){
          errorMessages.push(layer.title);
        }
      });
      if(errorMessages.length >0){
        let messages=prompt+errorMessages.reverse().join(',');
        return messages;
      }return '';
    }

    if(!Context.getInstance().uiMode.isKiosk){
      aiimUtil.waitForLayers(this.mapView).then(()=>{
        return  aiimUtil.waitForLayers(this.sceneView);
      }).then((result)=>{
        let message2D=checkLayers(this.mapView, "<br/>"+ i18n.messages.webmapLoadError +"<br/>");
        let message3D=checkLayers(this.sceneView, "<br/> <br/>" +i18n.messages.websceneLoadError+" <br/>");
        if(message2D.length >0 || message3D.length >0){
          let params = {
            message: message2D + message3D,
            dangerouslySetInnerHTML: true
          };
          Context.getInstance().session.startupToast = params;
          Topic.publish(Topic.ShowToast,params);
        }
      }).catch((error)=>{
      });
    }
  }

  _makeMapView(map: __esri.WebMap, constraints?: __esri.MapViewConstraints) {
    if (!map) return null;
    const props = { map, ...constraints && { constraints} };
    const { lib } = Context.getInstance();

    // try{
    //   if(map.resourceInfo && map.resourceInfo.initialState && map.resourceInfo.initialState.viewpoint){
    //     const viewPoint = lib.esri.Viewpoint.fromJSON(map.resourceInfo.initialState.viewpoint);
    //     props.viewpoint = viewPoint;
    //   }
    // }catch(error){
    //   console.error(error);
    // }

    try {
      const ff = map.widgets && map.widgets.floorFilter
      if (ff && ff.facility && Context.instance.appMode.appType === "indoors") {
        let reset = false;
        const url = Context.instance.lib.esri.urlUtils.urlToObject(window.location.href);
        const q = url && url.query;
        if (q && !reset) {
          if (q.hasOwnProperty("x") ||
              q.hasOwnProperty("itemUniqueId") ||
              q.hasOwnProperty("rsX") ||
              q.hasOwnProperty("rsItemUniqueId")) {
            reset = true;
          }
        }
        if (!reset) {
          const v = localStore.getItem(localStore.keys.homeLocation);
          if (v) reset = true;
        }
        if (reset) {
          ff.site = null;
          ff.facility = null;
          ff.level = null;
        }
      }
    } catch(error){
      console.error(error);
    }

    const view: __esri.MapView = new lib.esri.MapView(props); //2d
    this.mapView = view;
    if (view.popup) view.popup.autoOpenEnabled = false;
    if (view.highlightOptions) {
      view.highlightOptions.fillOpacity = 0;
    }

    this._mapViewClickHandler = viewUtil.watchForViewClick("views",view,
      (event, wasLongPress) => {
        if (!this.mapClickDisabled) {
          Topic.publish(Topic.ViewClicked,{view: view, event: event, wasLongPress: wasLongPress});
        }
      }
    )

    //console.log("mapView.constraints",view.constraints);
    //if (view.constraints) view.constraints.snapToZoom = false;
    return view;
  }

  _makeSceneView(map, container) {
    if (!map) return null;
    const props = {
      map: map,
      container: container,
      //popup: null
    };
    const lib = Context.getInstance().lib;
    const view = new lib.esri.SceneView(props); //3D
    this.sceneView = view;
    if (view.popup) view.popup.autoOpenEnabled = false;

    this._sceneViewClickHandler = viewUtil.watchForViewClick("views",view,event => {
      if (!this.mapClickDisabled) {
        Topic.publish(Topic.ViewClicked,{view: view, event: event});
      }
    });

    // issue 2580
    // import { Stage, StageOptions } from "./3d/webgl-engine/Stage";
    view._exitStage = () => {
      view._set("sceneIntersectionHelper", null);

      // issue 2580 wrap with try/catch
      try {
        view._stage.destroy();
      } catch(ex) {
        console.error("Error destroying sceneView._stage",ex);
      }

      view._stage = null;
      if (view._lostWebGLContextHandle) view._lostWebGLContextHandle.remove();
      view._lostWebGLContextHandle = null;
      view.handles.remove("stage");
      view._set("canvas", null);
    };

    return view;
  }
  _removeMeasurement(view?) {
    try {
      let expander = view && view.ui.find("measurementExpander");
      let content = expander && expander.content;
      if (content) {
        view.ui.remove(expander);
        this._destroyMeasurement(view);
        expander.destroy();
      }    
    } catch(ex) {
      console.error(ex);
    }
  }
  _removeScalebar(view) {
    try {
      let component = null;
      view.ui._components.some(c => {
        if (c && c.widget && c.widget.declaredClass === "esri.widgets.ScaleBar") {
          component = c;
          return true;
        }
        return false;
      });
      if (component) view.ui.remove(component);
    } catch(ex) {
      console.error(ex);
    }
  }

  _removeViewToggle(view) {
    try {
      let vt = null;
      view.ui._components.some(c => {
        if (c.node && c.node.getAttribute("data-i-isViewToggle") === "y") {
          vt = c;
          return true;
        }
        return false;
      });
      if (vt) view.ui.remove(vt);
    } catch(ex) {
      console.error(ex);
    }
  }

  toggleClickHandlers(state) {
    let a = [this._mapViewClickHandler,this._sceneViewClickHandler];
    a.forEach(h => {
      if (h) {
        if (state === "pause") {
          h.pause();
        } else if (state === "resume") {
          h.resume();
        }
      }
    });
  }

  _addMapLabel() {
    const mapViewDiv = document.getElementsByClassName('esri-view-surface')
    if (mapViewDiv && mapViewDiv.length === 1) {
      const i18n = Context.instance.i18n
      const mapViewNode = mapViewDiv[0]
      mapViewNode.setAttribute("aria-label", i18n.map.general.ariaLabelMap)
    }
  }

  async _toggleView() {
    const fx = Context.getInstance().lib.dojo.fx;
    if (this.activeView && this.mapView && this.sceneView) {
      const mapDivId = Context.instance.appMode.mapDivId;
      const node = document.getElementById(mapDivId);
      const container = this.activeView.container;

      try {
        let expander = this["_llex"+this.activeView.type];
        let layerList = this["_ll"+this.activeView.type];
        if (expander) {
          expander.expanded = false;
          expander.content = null;
          if (layerList) {
            layerList.destroy();
            this["_ll"+this.activeView.type] = null;
          }
        }
      } catch(ex) {
        console.error(ex);
      }

      this._destroyLegend(this.activeView);
      this._removeMeasurement(this.activeView);

      if (this.activeView.type === "2d") {

        const viewpoint = this.mapView.viewpoint.clone();
        if (!viewpoint.camera) {
          // initialize the tilt?
        }
        this.mapView.container = null;
        this.sceneView.container = container;
        this.activeView = this.sceneView;
        this.sceneView.viewpoint = viewpoint;
        this._addLayerList(this.activeView);
        this._addMeasurement(this.activeView);
        this._addLegend(this.activeView);
        this._addViewToggle(this.activeView);
        Topic.publish(Topic.ViewActivated0,{view: this.activeView});
        Topic.publish(Topic.ViewActivated,{view: this.activeView});

      } else {

        const viewpoint = this.sceneView.viewpoint.clone();
        try {
          this.sceneView.container = null;
        } catch(ex) {
          console.warn("Error unsetting sceneView container:");
          console.error(ex);
        }
        this.mapView.container = container;
        this.mapView.viewpoint = viewpoint;
        this.activeView = this.mapView;
        this._addLayerList(this.activeView);
        this._addLegend(this.activeView);
        this._addMeasurement(this.activeView);
        this._addViewToggle(this.activeView);
        Topic.publish(Topic.ViewActivated0,{view: this.activeView});
        Topic.publish(Topic.ViewActivated,{view: this.activeView});
        this._addMapLabel()

        // TODO: JSAPI issue, map-image layers are not drawing, force a refresh
        if ((this.mapView === this.activeView) && this.mapView.map && this.mapView.map.allLayers) {
          this.mapView.map.allLayers.forEach(lyr => {
            try {
              if (lyr.type === "map-image" && lyr.visible) {
                if (typeof (lyr as __esri.MapImageLayer).refresh === "function") {
                  (lyr as __esri.MapImageLayer).refresh();
                }
              }
            } catch(ex2) {
              console.warn("Error refreshing map-image");
              console.error(ex2);
            }
          });
        }

      }
    }
  }
  updateMeasure() {
    this._removeMeasurement(this.mapView);
    this._removeMeasurement(this.sceneView);
    this._addMeasurement(this.activeView);    
  }

  updateScalebar() {
    const view = this.mapView;
    if (view) {
      this._removeScalebar(view);
      this._addScalebar(view);
    }
  }

  _watchBasemapGallery(view, basemapGallery, basemapGalleryExpand) {
    const aspect = Context.getInstance().lib.dojo.aspect;
    this._handles.push(aspect.after(basemapGallery,"_handleClick",(event) => {
      //console.log("basemapGallery._handleClick",event);
      // Ignore KeyboardEvent if not Enter or spacebar.
      if(event && event.type === "keydown" && event.keyCode !== 13 && event.keyCode !== 32) {
        return;
      }

      const item = event.currentTarget["data-item"];
      if (item && item.state === "ready") {
        //console.log("basemapGallery._handleClick.ready");
        //this.activeBasemap = item.basemap;
        if (view === this.mapView) {
          if (this.sceneView) this.sceneView.map.basemap = item.basemap;
        } else if (view === this.sceneView) {
          if (this.mapView) this.mapView.map.basemap = item.basemap;
        }
        basemapGalleryExpand.collapse();
      }
    },true));
    // basemapGallery.watch("activeBasemap",(newVal) => {
    //   if (newVal) {
    //     //console.log("Basemap change",newVal);
    //     if (view === this.mapView) {
    //       //if (this.sceneView) this.sceneView.map.basemap = newVal.clone();
    //     } else if (view === this.sceneView) {
    //       //if (this.mapView) this.mapView.map.basemap = newVal.clone();
    //     }
    //   }
    // });
  }

}
