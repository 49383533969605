import React from "react";
import {connect} from "react-redux";

import Context from "../../../context/Context";
import ChoiceDropdown from "../../common/ChoiceDropdown/ChoiceDropdown";
import MenuOption from "../../common/ChoiceDropdown/MenuOption";
import Rdx from "../../../redux/Rdx";
import * as component from "../../util/component";
import * as eventUtil from "./eventUtil";

const CSS = {
  main: "i-events-typefilter"
};

class TypeFilter extends React.Component {

  _activePromise;
  _mounted;

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.clicked = this.clicked.bind(this);
  }

  clicked(menuOption) {
    Rdx.setValue(this,Rdx.Keys.EVENTS_TYPEFILTER_OPTION,menuOption.props.value);
    if (this.props.onClick) {
      this.props.onClick("TypeFilter",menuOption);
    }
  }

  componentDidMount() {
    this._mounted = true;
    if (!this.props.rdxOptions) {
      this.queryTypes();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  queryTypes() {
    const i18n = Context.getInstance().i18n;
    const optionData = [{
      label: i18n.events.typeFilter.any,
      value: null
    }];
    const dataset = eventUtil.getDataset();
    const promise = this._activePromise = eventUtil.queryTypes(dataset,optionData);
    promise.then(() => {
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      Rdx.setValue(this,Rdx.Keys.EVENTS_TYPEFILTER_OPTIONS,optionData);
    }).catch(ex => {
      console.warn("Error querying event types:");
      console.error(ex);
      if (!this._mounted) return;
      if (promise === this._activePromise) {
        this._activePromise = null;
      }
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    let caption = i18n.events.typeFilter.any;
    let activeValue = this.props.rdxOption;
    if (activeValue === undefined) activeValue = null;
    const optionData = this.props.rdxOptionData;
    let options = null;
    if (optionData) {
      options = optionData.map(info => {
        let key = info.key;
        if (key === null) key = "k";
        else key = "k" + info.value;
        const checked = (activeValue === info.value);
        if (checked) caption = info.label;
        return (
          <MenuOption key={key} label={info.label} value={info.value}
            checked={checked} onClick={this.clicked} />
        );
      });
    }
    return (
      <div className={CSS.main}>
        <ChoiceDropdown caption={caption}>
          {options}
        </ChoiceDropdown>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    rdxOption: Rdx.getValue(state,Rdx.Keys.EVENTS_TYPEFILTER_OPTION),
    rdxOptionData: Rdx.getValue(state,Rdx.Keys.EVENTS_TYPEFILTER_OPTIONS)
  }
}

export default connect(mapStateToProps)(TypeFilter);
