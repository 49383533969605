import React from "react";

import {MenuItem as CalciteMenuItem} from "calcite-react/Menu";

export default class MenuItem extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(event,this,this.props.value);
    }
  }

  render() {
    return (
      <CalciteMenuItem {...this.props} onClick={this.clicked}
        role="menuitem link" tabIndex="0">
        {this.props.children}
      </CalciteMenuItem>
    );
  }

}
