import React from "react";
import { connect } from "react-redux";
import { getActiveTab, setActiveTab } from "../../redux";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

interface Props {
  activeTab?: string;
  activeTabKey: string;
  className?: string;
  expanded: boolean;
  icon?: any;
  label: any;
  onClick? : (e: any) => void;
  setActiveTab? : (tab: string) => void;
  tab?: string;
}

interface State {
}

class SidebarButton extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ConfiguratorStarted, params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.PlanOpened,params => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { expanded, label, tab, activeTab } = this.props;
    const active = tab && (tab === activeTab);

    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    const planner = Context.instance.spaceplanner.planner;
    let disabled = !planner || !planner.hasValidProject() || !planner.hasValidPlan(); 
    if (tab === "app-switcher" && cfgOn && disabled && planner && planner.hasValidProject()) disabled = false;

    return (
      <div className={this.props.className}>
        <button 
          type="button"
          title={!expanded ? label: undefined}
          className={active ? "i--active": undefined}
          style={{cursor: "pointer"}}
          disabled={disabled ? true : undefined}
          onClick={(e) => {
            if (tab !== "app-switcher") {
              if (tab && tab === activeTab) {
                this.props.setActiveTab(null);
              } else if (tab) {
                this.props.setActiveTab(tab);
              }
            }
            if (this.props.onClick) this.props.onClick(e);
          }}
        >  
          {this.props.icon}
          {expanded &&
            <span className="i--label">{label}</span>
          }
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps) => ({
  activeTab: getActiveTab(state,ownProps.activeTabKey)
});
const mapDispatchToProps = (dispatch,ownProps) => ({
  setActiveTab: (tab) => dispatch(setActiveTab(ownProps.activeTabKey,tab))
});
export default connect(mapStateToProps, mapDispatchToProps)(SidebarButton);
