import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../datasets/FieldNames";
import HomeLocation from "./HomeLocation";

import ItemReference from "./ItemReference";
import Topic from "../../context/Topic";
import * as aiimUtil from "../util/aiimUtil";
import * as localStore from "../../util/localStore";
import * as mapUtil from "../../util/mapUtil";
import * as layerUtil from "../util/layerUtil";
import Rdx from "../../redux/Rdx";
import { ModalController } from "../../common/components/Modal";

export default class ReferenceLayer extends BaseClass {

  favorites = [];
  homeLocation = new HomeLocation();
  recent = [];
  _featureLayers = [];

  _favoriteToastId = "referenceLayer/favorite"

  invalidStartFeatureMessage = Context.instance.i18n.directions.invalidStartFeatureMessage
  invalidEndFeatureMessage = Context.instance.i18n.directions.invalidEndFeatureMessage
  invalidRouteFeatures = Context.instance.i18n.directions.invalidRouteFeatures

  constructor(props?) {
    super(props);

    this.own([
      Topic.subscribe(Topic.AppStarted,params => {
        if (!Context.instance.appMode.supportsMyPlaces()) return;
        const autoSetLevel = !!Context.getInstance().config.setLevelFromHome;
        this._restoreAll(autoSetLevel);
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        if (!Context.instance.appMode.supportsMyPlaces()) return;
        const autoSetLevel = !!Context.getInstance().config.setLevelFromHome;
        this._restoreAll(autoSetLevel);
      }),

      Topic.subscribe(Topic.SignedIn,() => {
        if (!Context.instance.appMode.supportsMyPlaces()) return;
        this._restoreAll();
      }),

      Topic.subscribe(Topic.FacilityModeUpdated,params => {
        const views = Context.getInstance().views;
        if (views && views.mapView) {
          mapUtil.afterSetLevel(views.mapView);
        }
      }),

      Topic.subscribe(Topic.HomeLocationSet,params => {
        if (!Context.instance.appMode.supportsMyPlaces()) return;
        this._updateYouAreHere(params.homeLocation);
      }),

      Topic.subscribe(Topic.ReloadViews,params => {
        this.clearHandles();
      }),

      Topic.subscribe(Topic.ViewActivated, params => {
        // this._featureLayers = this._findFeatureLayers()
        this._checkLayerVisibilityParams()
      })
    ]);
  }

  addFavorite(sourceKey,searchResult) {
    const max = Context.getInstance().config.maxFavoritePlaces;
    const i18n = Context.getInstance().i18n;
    const item = new ItemReference();
    let ok = item.fromSearchResult(sourceKey,searchResult);
    if (ok) {
      this.favorites.some(item2 => {
        if (item.sourceKey === item2.sourceKey && item.uniqueId === item2.uniqueId) {
          //console.log("Favorite already exists",item,item2); // TODO temporary
          Topic.publish(Topic.ShowToast,{message: i18n.infoPanel.messages.favoriteExists});
          ok = false;
          return true;
        }
        return false;
      });
    }
    if (ok) {
      if (this.favorites.length === max) {
        //this.favorites.length = max;
        Topic.publish(Topic.ShowToast,{message: i18n.infoPanel.messages.favoriteMaxReached});
      } else {
        this.favorites.unshift(item); // TODO push? sort?
        this._storeFavorites();
        Topic.publish(Topic.ShowToast,{message: i18n.infoPanel.messages.favoriteSaved});
        Topic.publish(Topic.ReferenceLayerUpdated,{"action": "FAVORITE_ADDED"});
      }
    }
  }

  addRecent(sourceKey,searchResult) {
    const max = Context.getInstance().config.maxRecentPlaces;
    const item = new ItemReference();
    const recent = this.recent;
    let ok = item.fromSearchResult(sourceKey,searchResult);
    let existingIndex = -1;
    if (ok) {
      recent.some((item2,index) => {
        if (item.sourceKey === item2.sourceKey && item.uniqueId === item2.uniqueId) {
          existingIndex = index;
          return true;
        }
        return false;
      });
      if (existingIndex === 0) {
        return; // nothing to do
      } else if (existingIndex > 0) {
        // remove the item and re-add
        recent.splice(existingIndex,1);
      }
    }

    if (ok) {
      recent.unshift(item);
      if (recent.length > max) recent.length = max;
      this._storeRecent();
      Topic.publish(Topic.ReferenceLayerUpdated,{"action": "RECENT_ADDED"});
    }
  }

  _goToXYL(x,y,l) {
    const lib = Context.getInstance().lib;
    const point = new lib.esri.Point({
      x: x,
      y: y,
      spatialReference: lib.esri.SpatialReference.WGS84
    });
    const units = Context.getInstance().aiim.datasets.units;
    const facilities = Context.getInstance().aiim.datasets.facilities;
    let located = false, vo = null;

    if (typeof l === "string") {
      try {
        const n = Number(l);
        if (typeof n === "number" && !isNaN(n) && isFinite(n)) {
          l = n;
        }
      } catch(ex) {
        console.error(ex);
      }
    }
    if (typeof l === "number") vo = l;

    const publish = (source,feature) => {
      const view = Context.getInstance().views.activeView;
      if (view && source) {
        const searchResult = source.makeSearchResult(view,feature);
        Topic.publish(Topic.ShowSearchResult,{
          sourceKey: source.key,
          searchResult: searchResult,
          zoom: true,
          highlight: true,
          trackRecent: false
        });
      }
    };

    const queryUnits:any = () => {
      if (!units || vo === null) return Promise.resolve();
      return units.queryByGeometry(point).then((result) => {
        if (result && result.features && result.features.length > 0) {
          result.features.some(feature => {
            let v = aiimUtil.getAttributeValue(feature.attributes,FieldNames.VERTICAL_ORDER);
            if (typeof v !== "number") {
              const levelData = units.getLevelData(feature);
              v = levelData && levelData.verticalOrder;
            }
            if (vo === v) {
              located = true;
              publish(units.getSource(),feature);
              return true;
            }
            return false;
          });
        }
      });
    };

    const queryFacilities = () => {
      if (!facilities) return Promise.resolve();
      return facilities.queryByGeometry(point).then((result) => {
        if (result && result.features && result.features.length === 1) {
          located = true;
          publish(facilities.getSource(),result.features[0]);
        }
      });
    };

    queryUnits().then(() => {
      if (!located) {
        return queryFacilities();
      }
    }).then(() => {
      if (!located) {
        const view = Context.getInstance().views.activeView;
        if (view) {
          let point = new Context.instance.lib.esri.Point({x,y})
          mapUtil.addLocationGraphic(view,point)
          return mapUtil.goToXYLocation(view,x,y,l);
        }
      }
    }).catch(ex => {
      console.error("Error querying layer",ex);
    });
    return Promise.resolve();
  }

  hasFavorite(sourceKey,searchResult) {
    const item = new ItemReference();
    let ok = item.fromSearchResult(sourceKey,searchResult);
    let found = null
    if (ok) {
      found = this.favorites.some(item2 => {
        if (item.sourceKey === item2.sourceKey && item.uniqueId === item2.uniqueId) {
          return true;
        }
        return false;
      });
    }
    return found;
  }

  hasItems(): boolean {
    if (this.homeLocation.isValid()) return true;
    if (this.favorites.length > 0) return true;
    if (this.recent.length > 0) return true;
    return false;
  }

  removeFavorite(item) {
    const index = this.favorites.indexOf(item);
    if (index !== -1) {
      this.favorites.splice(index, 1);
      this._storeFavorites();
      Topic.publish(Topic.ReferenceLayerUpdated,{"action": "FAVORITE_REMOVED"});
    }
  }

  removeHomeLocation() {
    localStore.removeItem(localStore.keys.homeLocation);
    this.homeLocation = new HomeLocation();
    this.homeLocation.queryLocation(false)
    Topic.publish(Topic.HomeLocationSet,{homeLocation: this.homeLocation});
    Topic.publish(Topic.ReferenceLayerUpdated,{"action": "HOME_UPDATED"});
  }

  removeRecent(item) {
    const index = this.recent.indexOf(item);
    if (index !== -1) {
      this.favorites.splice(index, 1);
      this._storeRecent();
      Topic.publish(Topic.ReferenceLayerUpdated,{"action": "RECENT_REMOVED"});
    }
  }

  _restoreAll(autoSetLevel?) {
    this.homeLocation = new HomeLocation();
    this.favorites = [];
    this.recent = [];

    this._restoreList(localStore.keys.favoritePlaces,this.favorites);
    this._restoreList(localStore.keys.recentPlaces,this.recent);



    this._getHomeLocation()
    .then(() => {})
    .catch(e => { console.warn("No home location set for route") })
    .finally(() => {
      this._setRouteFromUrl().then(result => {
        if (result.showInDirections) {
          setTimeout(() => {
            Topic.publish(Topic.DirectionsFromURL, result)
          }, 100)
        } else {
          this._setLocationFromUrl().then(result => {
            if (result.usingLocationFromUrl) autoSetLevel = false;
            this._restoreHomeLocation(autoSetLevel,result.urlItem);
          }).catch((ex) => {
            console.error("Error setting location from URL. ", ex);
            this._restoreHomeLocation(autoSetLevel);
          });
        }
      }).catch(ex => {
        console.error("Failed to route", ex)
        ModalController.showMessage(ex)
        this._setLocationFromUrl().then(result => {
          if (result.usingLocationFromUrl) autoSetLevel = false;
          this._restoreHomeLocation(autoSetLevel,result.urlItem);
        }).catch((ex) => {
          console.error("Error setting location from URL. ", ex);
          this._restoreHomeLocation(autoSetLevel);
        });
      })
    })
  }

  _checkLayerVisibilityParams() {
    const lib = Context.instance.lib
    let layerIds = null
    let urlObject = lib.esri.urlUtils.urlToObject(window.location.href)
    if (urlObject && urlObject.query) {
      layerIds = this.chkParam(urlObject, "visibleLayers");
      if(urlObject.query.hasOwnProperty("visibleLayers")){
        layerUtil.changeLayerVisibility(layerIds);
      }
    }
  }

  _restoreHomeLocation(autoSetLevel?,urlItem?) {
    if (!Context.instance.appMode.supportsHome()) return;
    const isAnonymous = Context.getInstance().user.isAnonymous();
    const isKiosk = Context.getInstance().uiMode.isKiosk;
    //let isIOS = Context.getInstance().uiMode.isIOS;
    //localStore.removeItem(localStore.keys.homeLocation);
    const loc = new HomeLocation();
    let json = localStore.getJsonItem(localStore.keys.homeLocation);
    if (!json && (urlItem && isKiosk)) {
      json = urlItem.toJson();
    }
    if (json) {
      loc.fromJson(json).then(() => {
        if (loc.isValid()) {
          this.homeLocation = loc;
          if (autoSetLevel) this.homeLocation.setLevel();
          this._updateYouAreHere(this.homeLocation);
        } else {
          if (!isAnonymous) this.homeLocation.queryLocation(autoSetLevel);
        }
      });
    } else {
      if (!isAnonymous) this.homeLocation.queryLocation(autoSetLevel);
    }
  }

  _getHomeLocation() {
    if (!Context.instance.appMode.supportsHome()) return Promise.resolve();
    return new Promise<void>((resolve, reject) => {
      const loc = new HomeLocation();
      let json = localStore.getJsonItem(localStore.keys.homeLocation);
      if (json) {
        loc.fromJson(json).then(() => {
          if (loc.isValid()) {
            this.homeLocation = loc;
            resolve()
          } else {
            reject()
          }
        }).catch(e => {
          console.error("Couldn't get home location", e)
          reject(e)
        });
      } else {
        reject()
      }
    })

  }

  _restoreList(key,list) {
    const data = localStore.getJsonItem(key);
    if (data && Array.isArray(data)) {
      data.forEach(json => {
        const item = new ItemReference();
        // TODO strategy to lazy load to avoid multiple queries on startup?
        list.push(item);
        item.fromJson(json).then(() => {
          if (item.isValid()) {
            // TODO promises will not be returned in order, need to sort?
            //list.push(item);
          }
        });
      });
    }
  }

  setHomeLocation(sourceKey,searchResult) {
    const ok = this.homeLocation.fromSearchResult(sourceKey,searchResult);
    if (ok) {
      this.homeLocation.fromOfficeLocation = false;
      const json = this.homeLocation.toJson();
      localStore.setJsonItem(localStore.keys.homeLocation,json);
      const i18n = Context.getInstance().i18n;
      Topic.publish(Topic.HomeLocationSet,{homeLocation: this.homeLocation});
      Topic.publish(Topic.ShowToast,{message: i18n.infoPanel.messages.homeSet});
      Topic.publish(Topic.ReferenceLayerUpdated,{"action": "HOME_UPDATED"});
    }
  }

  openDirectionsPanel() {
    Rdx.setValue(null, Rdx.Keys.SIDEBAR_ACTIVE_KEY, "directions");
    document.body.classList.add("i-sidebar-panel-open");
    Topic.publish(Topic.SidebarButtonClicked, { sidebarKey: "directions" });
    Context.instance.views.toggleClickHandlers("resume");
  }

  makeExtent(geometry) {
    const lib = Context.instance.lib
    // 1 meter in Web Mercator
    const METERS_OFFSET = 0.00089
    let extent = null
    const type = geometry.type
    if (type === "point") {
      const bufferMeters = 20
      const newGeometry = lib.esri.geometryEngine.geodesicBuffer(geometry, bufferMeters, "meters");
      if (newGeometry && newGeometry.extent) {
        extent = newGeometry.extent;
      } else {
        extent = new lib.esri.Extent(
          geometry.x - METERS_OFFSET, geometry.y - METERS_OFFSET,
          geometry.x + METERS_OFFSET, geometry.y + METERS_OFFSET,
          geometry.spatialReference)
      }
    } else if (type === "polygon") {
      extent = geometry.extent
    }
    return extent
  }

  updateRouteResult(startItem, endItem, result) {
    if (startItem && startItem.isValid() && endItem && endItem.isValid()) {
      // Features and geometries
      const startFeature = startItem.searchResult && startItem.searchResult.feature
      let startGeometry = startFeature && startFeature.geometry
      if (startGeometry && startGeometry.spatialReference && startGeometry.spatialReference.isWGS84 &&
          Context.instance.views.activeView.spatialReference.isWebMercator) {
        startGeometry = Context.instance.lib.esri.webMercatorUtils.geographicToWebMercator(startGeometry);
      }
      const endFeature = endItem.searchResult && endItem.searchResult.feature
      let endGeometry = endFeature && endFeature.geometry
      if (endGeometry && endGeometry.spatialReference && endGeometry.spatialReference.isWGS84 &&
          Context.instance.views.activeView.spatialReference.isWebMercator) {
        endGeometry = Context.instance.lib.esri.webMercatorUtils.geographicToWebMercator(endGeometry);
      }

      // Combine extents and show
      const startExtent = startGeometry && this.makeExtent(startGeometry)
      const endExtent = endGeometry && this.makeExtent(endGeometry)
      const extent = startExtent.union(endExtent)
      const view = Context.instance.views.activeView
      if (extent) {
        mapUtil.goToFeature(view, { geometry: extent }, true)
      }

      result.startItem = startItem
      result.endItem = endItem
      result.showInDirections = true

    } else if (startItem && startItem.isValid()) {
      if (endItem && !endItem.isValid()) ModalController.showMessage(this.invalidEndFeatureMessage)
      const feature = startItem && startItem.searchResult && startItem.searchResult.feature
      let geometry = feature && feature.geometry
      if (geometry && geometry.spatialReference && geometry.spatialReference.isWGS84 &&
          Context.instance.views.activeView.spatialReference.isWebMercator) {
        geometry = Context.instance.lib.esri.webMercatorUtils.geographicToWebMercator(geometry);
      }
      const extent = geometry && this.makeExtent(geometry)
      const view = Context.instance.views.activeView
      if (extent) {
        mapUtil.goToFeature(view, { geometry: extent })
      }

      result.startItem = startItem
      result.startOnly = true
      result.showInDirections = true
    } else if (endItem && endItem.isValid()) {
      if (startItem && !startItem.isValid()) ModalController.showMessage(this.invalidStartFeatureMessage)
      const feature = endItem && endItem.searchResult && endItem.searchResult.feature
      let geometry = feature && feature.geometry
      if (geometry && geometry.spatialReference && geometry.spatialReference.isWGS84 &&
          Context.instance.views.activeView.spatialReference.isWebMercator) {
        geometry = Context.instance.lib.esri.webMercatorUtils.geographicToWebMercator(geometry);
      }
      const extent = geometry && this.makeExtent(geometry)
      const view = Context.instance.views.activeView
      if (extent) {
        mapUtil.goToFeature(view, { geometry: extent })
      }

      result.endItem = endItem
      result.endOnly = true
      result.showInDirections = true
    } else if (startItem && endItem) ModalController.showMessage(this.invalidRouteFeatures)
    else if (startItem) ModalController.showMessage(this.invalidStartFeatureMessage)
    else if (endItem) ModalController.showMessage(this.invalidEndFeatureMessage)
    return result
  }

  makeRouteJsonFeature(rsItemSourceKey, rsItemUniqueIdField, rsItemUniqueId,
    reItemSourceKey, reItemUniqueIdField, reItemUniqueId) {
      return {
        start: {
          sourceKey: rsItemSourceKey,
          uniqueIdField: rsItemUniqueIdField,
          uniqueId: rsItemUniqueId
        },
        end: {
          sourceKey: reItemSourceKey,
          uniqueIdField: reItemUniqueIdField,
          uniqueId: reItemUniqueId
        }
      }
  }

  getStandaloneNumber() {
    let standaloneNumber = 1
    const numbers = []
    const refLayer = Context.instance.session.referenceLayer
    if (refLayer) {
      const lists = [[refLayer.homeLocation], refLayer.favorites,refLayer.recent]
      lists.forEach(list => {
        if (Array.isArray(list)) {
          list.forEach(itm => {
            if (typeof itm._standaloneNumber === "number" && itm._standaloneNumber >= 1) {
              numbers.push(itm._standaloneNumber)
            }
          })
        }
      })
      numbers.sort()
      for (var i = 1; i <= 10000; i++) {
        if (numbers.indexOf(i) === -1) {
          standaloneNumber = i
          break
        }
      }
    }
    return standaloneNumber
  }

  makeRouteJsonXYL(rsX, rsY, rsItemUniqueId, reX, reY, reItemUniqueId) {
    const standaloneNumberStart = this.getStandaloneNumber()
    const standaloneNumberEnd = (rsX && rsY && rsItemUniqueId && reX && reY && reItemUniqueId) ?
      standaloneNumberStart + 1 :
      standaloneNumberStart
    return {
      start: {
        geometry: new Context.instance.lib.esri.Point({
          x: rsX,
          y: rsY,
          wkid: 4326
        }),
        sourceKey: "standalone",
        standaloneNumber: standaloneNumberStart,
        uniqueId: rsItemUniqueId
      },
      end: {
        geometry: new Context.instance.lib.esri.Point({
          x: reX,
          y: reY,
          wkid: 4326
        }),
        sourceKey: "standalone",
        standaloneNumber: standaloneNumberEnd,
        uniqueId: reItemUniqueId
      }
    }
  }

  _routeToXYL(x, y, l): Promise<any> {
    return new Promise((resolve, reject) => {
      const lib = Context.getInstance().lib;
      const point = new lib.esri.Point({
        x: x,
        y: y,
        spatialReference: lib.esri.SpatialReference.WGS84
      })
      const units = Context.getInstance().aiim.datasets.units;
      const facilities = Context.getInstance().aiim.datasets.facilities;
      let located = false, vo = null

      if (typeof l === "string") {
        try {
          const n = Number(l)
          if (typeof n === "number" && !isNaN(n) && isFinite(n)) {
            l = n
          }
        } catch(ex) {
          console.error(ex)
        }
      }
      if (typeof l === "number") vo = l

      const publish = (source, feature) => {
        const view = Context.getInstance().views.activeView
        if (view && source) {
          const searchResult = source.makeSearchResult(view,feature)
          resolve({
            sourceKey: source.key,
            searchResult: searchResult
          })
        }
      }

      const queryUnits = () => {
        if (!units || vo === null) return Promise.resolve()
        return units.queryByGeometry(point).then((result) => {
          if (result && result.features && result.features.length > 0) {
            result.features.some(feature => {
              let v = aiimUtil.getAttributeValue(feature.attributes, FieldNames.VERTICAL_ORDER)
              if (typeof v !== "number") {
                const levelData = units.getLevelData(feature);
                v = levelData && levelData.verticalOrder;
              }
              if (vo === v) {
                located = true
                publish(units.getSource(), feature)
                return true
              }
              return false
            })
          }
        })
      }

      const queryFacilities = () => {
        if (!facilities) return Promise.resolve()
        return facilities.queryByGeometry(point).then((result) => {
          if (result && result.features && result.features.length === 1) {
            located = true
            publish(facilities.getSource(), result.features[0])
          }
        })
      }

      queryUnits().then(() => {
        if (!located) {
          return queryFacilities()
        }
      }).then(() => {
        if (!located) {
          console.error("Could not find matching feature")
          reject("Could not find matching feature")
        }
      }).catch(ex => {
        console.error("Error querying layer", ex)
        reject(ex)
      })
    })
  }

  chkParam (urlObject,name) {
    const v = urlObject.query[name];
    if (typeof v === "string" && v.length > 0) {
      return v;
    }
    return null;
  }

  _setRouteFromUrl(): Promise<any> {
    return new Promise((resolve, reject) => {
      const lib = Context.instance.lib
      const result = {
        showInDirections: false,
        startOnly: false,
        endOnly: false,
        startItem: null,
        endItem: null
      }
      let rsItemSourceKey = null, rsItemUniqueIdField = null, rsItemUniqueId = null
      let reItemSourceKey = null, reItemUniqueIdField = null, reItemUniqueId = null
      let rsX = null, rsY = null, rsL = null
      let reX = null, reY = null, reL = null
      let urlObject = lib.esri.urlUtils.urlToObject(window.location.href)
      // Get all relevant URL params
      if (urlObject && urlObject.query) {
        rsItemSourceKey = this.chkParam(urlObject, "rsItemSourceKey")
        rsItemUniqueIdField = this.chkParam(urlObject, "rsItemUniqueIdField")
        rsItemUniqueId = this.chkParam(urlObject, "rsItemUniqueId")
        reItemSourceKey = this.chkParam(urlObject, "reItemSourceKey")
        reItemUniqueIdField = this.chkParam(urlObject, "reItemUniqueIdField")
        reItemUniqueId = this.chkParam(urlObject, "reItemUniqueId")
        rsX = this.chkParam(urlObject, "rsX")
        rsY = this.chkParam(urlObject, "rsY")
        rsL = this.chkParam(urlObject, "rsL")
        reX = this.chkParam(urlObject, "reX")
        reY = this.chkParam(urlObject, "reY")
        reL = this.chkParam(urlObject, "reL")
      }

      // Check for routing service
      if (rsItemSourceKey || rsItemUniqueIdField || rsItemUniqueId || rsX || rsY || rsL ||
          reItemSourceKey || reItemUniqueIdField || reItemUniqueId || reX || reY || reL) {
        const url = Context.getInstance().config.networkServiceUrl
        if (!url || url.length === 0) {
          const message = "Routing URL params found but no network service is available"
          console.warn(message)
          ModalController.showMessage(message)
        }
      }

      if (rsItemSourceKey !== null && rsItemUniqueIdField !== null && rsItemUniqueId !== null &&
          reItemSourceKey !== null && reItemUniqueIdField !== null && reItemUniqueId !== null) {
        // Check for start/end features by source and unique ID
        this.openDirectionsPanel()
        const json = this.makeRouteJsonFeature(rsItemSourceKey, rsItemUniqueIdField, rsItemUniqueId,
          reItemSourceKey, reItemUniqueIdField, reItemUniqueId)

        const startItem = new ItemReference()
        const promiseStartItem = startItem.fromJson(json.start)
        const endItem = new ItemReference()
        const promiseEndItem = endItem.fromJson(json.end)

        Promise.all([promiseStartItem, promiseEndItem]).then(() => {
          const updatedResult = this.updateRouteResult(startItem, endItem, result)
          resolve(updatedResult)
        }).catch(e => {
          console.warn(e)
          if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
          else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
          else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
        })
      } else if (rsItemSourceKey !== null && rsItemUniqueIdField !== null && rsItemUniqueId !== null) {
        // Check for start feature by source and unique ID
        this.openDirectionsPanel()
        const json = this.makeRouteJsonFeature(rsItemSourceKey, rsItemUniqueIdField, rsItemUniqueId, null, null, null)
        const startItem = new ItemReference()
        if (reX === null || reY === null || reL === null) {
          startItem.fromJson(json.start).then(() => {
            const updatedResult = this.updateRouteResult(startItem, null, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidStartFeatureMessage, e)
            reject(this.invalidStartFeatureMessage)
          })
        } else if (reX !== null && reY !== null && reL !== null) {
          const promiseStartItem = startItem.fromJson(json.start)
          const jsonXYL = this.makeRouteJsonXYL(null, null, null, reX, reY, reItemUniqueId)
          const endItem = new ItemReference()
          if (reItemSourceKey === "standalone") {
            const promiseEndItem = endItem.fromJson(jsonXYL.end)
            Promise.all([promiseStartItem, promiseEndItem]).then(() => {
              const updatedResult = this.updateRouteResult(startItem, endItem, result)
              resolve(updatedResult)
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          } else {
            const promiseEndItem = this._routeToXYL(reX, reY, reL)
            Promise.all([promiseStartItem, promiseEndItem]).then(values => {
              if (values && values.length > 1) {
                const found = values[1]
                if (!found) {
                  console.warn(this.invalidEndFeatureMessage)
                  ModalController.showMessage(this.invalidEndFeatureMessage)
                } else {
                  endItem.fromSearchResult(found.sourceKey, found.searchResult)
                  const updatedResult = this.updateRouteResult(startItem, endItem, result)
                  resolve(updatedResult)
                }
              }
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          }
        }
      } else if (reItemSourceKey !== null && reItemUniqueIdField !== null && reItemUniqueId !== null) {
        // Check for end feature by source and unique ID
        this.openDirectionsPanel()
        const json = this.makeRouteJsonFeature(null, null, null, reItemSourceKey, reItemUniqueIdField, reItemUniqueId)
        const endItem = new ItemReference()
        if (rsX === null || rsY === null || rsL === null) {
          endItem.fromJson(json.end).then(() => {
            const startItem = this.homeLocation && this.homeLocation.isValid() && endItem && endItem.isValid()
             ? this.homeLocation : null
            const updatedResult = this.updateRouteResult(startItem, endItem, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidEndFeatureMessage, e)
            reject(this.invalidEndFeatureMessage)
          })
        } else if (rsX !== null && rsY !== null && rsL !== null) {
          const promiseEndItem = endItem.fromJson(json.end)
          const jsonXYL = this.makeRouteJsonXYL(rsX, rsY, rsItemUniqueId, null, null, null)
          const startItem = new ItemReference()
          if (rsItemSourceKey === "standalone") {
            const promiseStartItem = startItem.fromJson(jsonXYL.start)
            Promise.all([promiseStartItem, promiseEndItem]).then(() => {
              const updatedResult = this.updateRouteResult(startItem, endItem, result)
              resolve(updatedResult)
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          } else {
            const promiseStartItem = this._routeToXYL(rsX, rsY, rsL)
            Promise.all([promiseEndItem, promiseStartItem]).then(values => {
              if (values && values.length > 1) {
                const found = values[1]
                if (!found) {
                  console.warn(this.invalidStartFeatureMessage)
                  ModalController.showMessage(this.invalidStartFeatureMessage)
                } else {
                  startItem.fromSearchResult(found.sourceKey, found.searchResult)
                  const updatedResult = this.updateRouteResult(startItem, endItem, result)
                  resolve(updatedResult)
                }
              }
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          }
        }
      } else if (rsX !== null && rsY !== null && rsL !== null && reX !== null && reY !== null && reL !== null) {
        // Check start/end features by x, y, l coords
        this.openDirectionsPanel()
        // Check if features are standalone points or meant to located a pre-existing feature
        if (rsItemSourceKey === "standalone" && reItemSourceKey === "standalone") {
          const json = this.makeRouteJsonXYL(rsX, rsY, rsItemUniqueId, reX, reY, reItemUniqueId)
          const startItem = new ItemReference()
          const promiseStartItem = startItem.fromJson(json.start)
          const endItem = new ItemReference()
          const promiseEndItem = endItem.fromJson(json.end)

          Promise.all([promiseStartItem, promiseEndItem]).then(() => {
            const updatedResult = this.updateRouteResult(startItem, endItem, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(e)
            if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
            else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
            else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
          })
        } else if (rsItemSourceKey === "standalone") {
          const json = this.makeRouteJsonXYL(rsX, rsY, rsItemUniqueId, null, null, null)
          const startItem = new ItemReference()
          const promiseStartItem = startItem.fromJson(json.start)
          const endItem = new ItemReference()
          this._routeToXYL(reX, reY, reL).then(found => {
            const promiseEndItem = endItem.fromSearchResult(found.sourceKey, found.searchResult)
            Promise.all([promiseStartItem, promiseEndItem]).then(() => {
              const updatedResult = this.updateRouteResult(startItem, endItem, result)
              resolve(updatedResult)
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          }).catch(e => {
            console.warn(e)
            if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
            else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
            else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
          })
        } else if (reItemSourceKey === "standalone") {
          const json = this.makeRouteJsonXYL(null, null, null, reX, reY, reItemUniqueId)
          const endItem = new ItemReference()
          const promiseEndItem = endItem.fromJson(json.end)
          const startItem = new ItemReference()
          this._routeToXYL(rsX, rsY, rsL).then(found => {
            console.log(found)
            const promiseStartItem = startItem.fromSearchResult(found.sourceKey, found.searchResult)
            Promise.all([promiseStartItem, promiseEndItem]).then(() => {
              const updatedResult = this.updateRouteResult(startItem, endItem, result)
              resolve(updatedResult)
            }).catch(e => {
              console.warn(e)
              if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
              else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
              else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
            })
          }).catch(e => {
            console.warn(e)
            if ((!startItem || !startItem.isValid()) && (!endItem || !endItem.isValid())) reject(this.invalidRouteFeatures)
            else if (!startItem || !startItem.isValid()) reject(this.invalidStartFeatureMessage)
            else if (!endItem || !endItem.isValid()) reject(this.invalidEndFeatureMessage)
          })
        }
      } else if (rsX !== null && rsY !== null && rsL !== null) {
        // Check start feature by x, y, l coords
        this.openDirectionsPanel()
        const json = this.makeRouteJsonXYL(rsX, rsY, rsItemUniqueId, null, null, null)
        const startItem = new ItemReference()
        if (rsItemSourceKey === "standalone") {
          startItem.fromJson(json.start).then(() => {
            const updatedResult = this.updateRouteResult(startItem, null, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidStartFeatureMessage, e)
            reject(this.invalidStartFeatureMessage)
          })
        } else {
          this._routeToXYL(rsX, rsY, rsL).then(found => {
            startItem.fromSearchResult(found.sourceKey, found.searchResult)
            const updatedResult = this.updateRouteResult(startItem, null, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidStartFeatureMessage, e)
            reject(this.invalidStartFeatureMessage)
          })
        }
      } else if (reX !== null && reY !== null && reL !== null) {
        // Check end feature by x, y, l coords
        this.openDirectionsPanel()
        const json = this.makeRouteJsonXYL(null, null, null, reX, reY, reItemUniqueId)
        const endItem = new ItemReference()
        if (reItemSourceKey === "standalone") {
          endItem.fromJson(json.end).then(() => {
            const startItem = this.homeLocation && this.homeLocation.isValid() && endItem && endItem.isValid()
             ? this.homeLocation : null
            const updatedResult = this.updateRouteResult(startItem, endItem, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidEndFeatureMessage, e)
            reject(this.invalidEndFeatureMessage)
          })
        } else {
          this._routeToXYL(reX, reY, reL).then(found => {
            endItem.fromSearchResult(found.sourceKey, found.searchResult)
            const startItem = this.homeLocation && this.homeLocation.isValid() && endItem && endItem.isValid()
             ? this.homeLocation : null
            const updatedResult = this.updateRouteResult(startItem, endItem, result)
            resolve(updatedResult)
          }).catch(e => {
            console.warn(this.invalidEndFeatureMessage, e)
            reject(this.invalidEndFeatureMessage)
          })
        }
      } else {
        resolve(result)
      }
    })
  }

  // Set location from paramters provided in URL.
  // Return true if using paramters from url, else return false.
  _setLocationFromUrl(): Promise<any> {
    const promise = new Promise((resolve,reject) => {
      const isKiosk = Context.getInstance().uiMode.isKiosk;
      const result = {
        usingLocationFromUrl: false,
        urlItem: null
      };
      const lib = Context.getInstance().lib;
      let sourceKey = null, uniqueIdField = null, uniqueId = null;
      let xParameter = null, yParameter = null, lParameter = null;
      let urlObject = lib.esri.urlUtils.urlToObject(window.location.href);
      if (urlObject && urlObject.query) {
        sourceKey = this.chkParam(urlObject,"itemSourceKey");
        uniqueIdField = this.chkParam(urlObject,"itemUniqueIdField");
        uniqueId = this.chkParam(urlObject,"itemUniqueId");
        xParameter = this.chkParam(urlObject,"x");
        yParameter = this.chkParam(urlObject,"y");
        lParameter = this.chkParam(urlObject,"l");
      }
      if (sourceKey !== null && uniqueIdField !== null && uniqueId !== null) {
        const json = {
          sourceKey: sourceKey,
          uniqueIdField: uniqueIdField,
          uniqueId: uniqueId
        };
        const item = new ItemReference();
        const opts = {
          caseInsensitive: true
        }
        item.fromJson(json,opts).then(() => {
          if (item.isValid()) {
            let homeJson = localStore.getJsonItem(localStore.keys.homeLocation);
            if(!isKiosk || homeJson){
              Topic.publish(Topic.ShowSearchResult,{
                sourceKey: item.sourceKey,
                searchResult: item.searchResult,
                zoom: true,
                highlight: true,
                trackRecent: false
              });
            result.usingLocationFromUrl = true;
          }
            result.urlItem = item;
            resolve(result);
          } else {
            resolve(result);
          }
        });
      }else if (xParameter !== null && yParameter !== null && lParameter !== null && sourceKey ==="standalone") {
        const item = new ItemReference();
        const json = {
          geometry: new Context.instance.lib.esri.Point({
            x: xParameter,
            y: yParameter,
            wkid: 4326
          }),
          sourceKey: sourceKey,
          uniqueId: uniqueId
        };

        item.fromJson(json).then(() => {
          if (item.isValid()) {
            let homeJson = localStore.getJsonItem(localStore.keys.homeLocation);
            if(!isKiosk || homeJson){
              setTimeout(function(){
                Topic.publish(Topic.ShowSearchResult,{
                sourceKey: item.sourceKey,
                searchResult: item.searchResult,
                zoom: true,
                highlight: true,
                trackRecent: false
              });
            }, 1000);
            result.usingLocationFromUrl = true;
          }
            result.urlItem = item;
            resolve(result);
          } else {
            resolve(result);
          }
        });
    }else if(xParameter !== null && yParameter !== null && lParameter !== null){
      this._goToXYL(xParameter,yParameter,lParameter);
      result.usingLocationFromUrl = true;
      resolve(result);
    }else{
        resolve(result);
      }
    })
      return promise;
    }

  _storeFavorites() {
    this._storeList(localStore.keys.favoritePlaces,this.favorites);
  }

  _storeRecent() {
    this._storeList(localStore.keys.recentPlaces,this.recent);
  }

  _storeList(key,list) {
    const data = list.map(item => {
      return item.toJson();
    });
    localStore.setJsonItem(key,data);
  }

  _updateYouAreHere(homeLocation) {
    //console.log("_updateYouAreHere",homeLocation)
    const context = Context.getInstance();
    const isKiosk = context.isKiosk;
    const views = context.views;
    if (!isKiosk || !homeLocation || !views) return;
    const feature = homeLocation.getFeature();
    if (feature && feature.geometry) {
      if (views.mapView) {
        const info = {
          facilityId: aiimUtil.getAttributeValue(feature.attributes,FieldNames.FACILITY_ID),
          levelNumber: aiimUtil.getAttributeValue(feature.attributes,FieldNames.LEVEL_NUMBER),
          verticalOrder: aiimUtil.getAttributeValue(feature.attributes,FieldNames.VERTICAL_ORDER),
          locationType: aiimUtil.getAttributeValue(feature.attributes,FieldNames.LOCATION_TYPE),
        };
        const unitsDataset = Context.instance.aiim.datasets.units;
        if (unitsDataset) {
          const levelData = unitsDataset.getLevelData(feature);
          if (levelData) {
            info.facilityId = levelData.facilityId;
            info.levelNumber = levelData.levelNumber;
            info.verticalOrder = levelData.verticalOrder;
          }
        }
        mapUtil.addYouAreHereGraphic(views.mapView,feature.geometry,info);
        mapUtil.afterSetLevel(views.mapView);
      }
      if (views.sceneView) {
        mapUtil.addYouAreHereGraphic(views.sceneView,feature.geometry,null);
      }
    }
  }

}
