// aiim.datasets
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";
import QueryAll from "../../spaceplanner/base/QueryAll";

// aiim.util
import { findField, findFieldName, getAttributeValue, getRecurrencePattern, readServiceJson } from "../util/aiimUtil";
import ReservationsErrorHandler from "../util/ReservationsErrorHandler";
import ReservationsErrorHandlerMeetingRooms from "../util/ReservationsErrorHandlerMeetingRooms";

// context
import Context from "../../context/Context";
import * as dateUtil from "../../components/main/Events/dateUtil";
import * as selectionUtil from "../../aiim/util/selectionUtil";

// main.More.BookWorkspace.WorkspaceReservation
import {
  makeCurrentTimeRange,
  makeDefExpClause,
  setReservationsDefExp
} from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import type { CustomQueryTask } from "../../context/EsriLib";
import { IRecurrenceSeries } from "../../components/main/More/Actions/BookWorkspace/BookingRecurrence";
import { BOOKING_STATE } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/EsriReservationSchema";
import moment from "moment";
import { IScheduleItem } from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem";

export default class Reservations extends Dataset {
  isIndoorsService = false;
  name = "reservations";
  reservedByUsernameField = FieldNames.RESERVED_BY_USERNAME;
  reservedByFullNameField = FieldNames.RESERVED_BY_FULL_NAME;
  reservedForUsernameField = FieldNames.RESERVED_FOR_USERNAME;
  reservedForFullNameField = FieldNames.RESERVED_FOR_FULL_NAME;
  stateField = FieldNames.STATE;
  startTimeField = FieldNames.START_TIME;
  endTimeField = FieldNames.END_TIME;
  levelIdField = FieldNames.LEVEL_ID;
  unitIdField = FieldNames.UNIT_ID;
  unitNameField = FieldNames.UNIT_NAME;
  allDayField = FieldNames.ALL_DAY;
  checkInTimeField = FieldNames.CHECK_IN_TIME;
  checkOutTimeField = FieldNames.CHECK_OUT_TIME;

  // Optional fields
  titleField = null;
  descriptionField = null;
  recurrenceIdField = null;
  recurrenceConfigField = null;

  // Error handler
  errorHandler;
  errorHandlerMeetingRooms: ReservationsErrorHandlerMeetingRooms;

  currentTimeInterval;
  refreshInterval;
  previousVisibility;

  constructor(refreshInterval) {
    super();
    this.refreshInterval = refreshInterval;
    this.setCurrentTimeInterval();
  }

  /**
   * Properties for time-awareness, and disabling identify on the layer
   */
  setLayerProperties() {
    const { start, end } = makeCurrentTimeRange();
    this.layer2D.definitionExpression = makeDefExpClause(start, end);
    this.layer2D.popupEnabled = false;
    // @ts-ignore
    this.layer2D.xtnHitTestDisabled = true;
    this.layer2D.useViewTime = false;
  }

  checkSchema() {
    if (this.layer2D) {
      this.setLayerProperties();

      const layer: __esri.FeatureLayer = this.layer2D;
      if (layer.floorInfo && layer.floorInfo.floorField) {
        this.levelIdField = layer.floorInfo.floorField;
      }

      return layer.when(() => {
        this.checkFieldNameProperty("reservedByUsernameField", layer.fields);
        this.checkFieldNameProperty("reservedByFullNameField", layer.fields);
        this.checkFieldNameProperty("reservedForUsernameField", layer.fields);
        this.checkFieldNameProperty("reservedForFullNameField", layer.fields);
        this.checkFieldNameProperty("stateField", layer.fields);
        this.checkFieldNameProperty("startTimeField", layer.fields);
        this.checkFieldNameProperty("endTimeField", layer.fields);
        this.checkFieldNameProperty("levelIdField", layer.fields);
        this.checkFieldNameProperty("unitIdField", layer.fields);
        this.checkFieldNameProperty("unitNameField", layer.fields);
        this.checkFieldNameProperty("allDayField", layer.fields);
        this.checkFieldNameProperty("checkInTimeField", layer.fields);
        this.checkFieldNameProperty("checkOutTimeField", layer.fields);

        // Optional fields
        this.titleField = findFieldName(layer.fields, FieldNames.TITLE);
        this.descriptionField = findFieldName(layer.fields, FieldNames.DESCRIPTION);
        this.recurrenceIdField = findFieldName(layer.fields, FieldNames.RECURRENCE_ID);
        this.recurrenceConfigField = findFieldName(layer.fields, FieldNames.RECURRENCE_CONFIG);
        return readServiceJson(layer.url).then((result)=> {
          if (result && result.data && result.data.isIndoorsService) this.isIndoorsService = true;
        })
      }).then(()=>{
        this.errorHandler = new ReservationsErrorHandler(this);
        this.errorHandlerMeetingRooms = new ReservationsErrorHandlerMeetingRooms(this);
      }).catch(ex => {
        console.warn("Failed to load dataset layer:", layer.title);
        console.error(ex);
      });
    } else {
      return Promise.resolve();
    }
  }

  canUserMakeReservations() {
    return this.getCapabilityInfo().userCanEdit;
  }

  fixMeetingRoomEndDate(feature) {
    // Meeting rooms: All day booking is misrepresented in the Schedule View #8022
    // For all day meeting room bookings, the end_time is set 1 millisecond before midnight,
    // if the length of the end_time field is 8, the end_time will get rounded to midnight on the back-end,
    // this will reset the end_time to 1 millisecond before midnight
    if (feature && feature.attributes && this.layer2D) {
      const allDay = feature.attributes[this.allDayField];
      if (allDay === 1) {
        const startTime = feature.attributes[this.startTimeField];
        const endTime = feature.attributes[this.endTimeField];
        if (startTime && endTime) {
          const midnight = moment(startTime).add(1,"d").valueOf();
          if (endTime === midnight) {
            feature.attributes[this.endTimeField] = endTime - 1;
          }
        }
      }
    }
  }

  getCapabilityInfo() {
    const user = Context.getInstance().user;
    const layer = this.layer2D;

    const info = {
      supportsAddUpdateDelete: false,
      supportsEditing: false,
      userCanEdit: false,
      userHasPrivilege: false
    }

    if (user && layer) {
      const hasPrivilige =
        user.isAdmin() ||
        user.isPublisher() ||
        user.isDataEdit() ||
        user.isFullDataEdit() ||
        user.isIndoorsUser();
        
      const supportedOperations = layer.capabilities && layer.capabilities.operations;
      const supportsAdd = supportedOperations && supportedOperations.supportsAdd;
      const supportsDelete = supportedOperations && supportedOperations.supportsDelete;
      const supportsUpdate = supportedOperations && supportedOperations.supportsUpdate;
      const supportsEditing = supportedOperations && supportedOperations.supportsEditing;
      const editable = supportsAdd && supportsDelete && supportsUpdate && supportsEditing;
      const editingEnabled = layer.editingEnabled || 
                            (editable && user.isIndoorsUser() && this.isIndoorsService);

      info.supportsAddUpdateDelete = supportsAdd && supportsDelete && supportsUpdate;
      info.supportsEditing = supportsEditing;
      info.userCanEdit = hasPrivilige && editable && editingEnabled;
      info.userHasPrivilege = hasPrivilige;
    }
    return info;
  }
  
  canUserEditFeature(attributes) {
    const layer2D = this.layer2D;
    const editFieldsInfo = layer2D && layer2D.editFieldsInfo;
    const creatorField = editFieldsInfo && editFieldsInfo.creatorField;
    const capabilities =  this.layer2D && this.layer2D.capabilities;
    const supportsUpdateByOthers = capabilities && capabilities.editing 
                                  && capabilities.editing.supportsUpdateByOthers;
    const loggedInUsername = Context.getInstance().user.getUsername();
    const creatorUsername = creatorField && getAttributeValue(attributes, creatorField);
    
    if (creatorField && !supportsUpdateByOthers) {
      if (loggedInUsername !== creatorUsername) return false;
    }
    return true;
  }

  async getRecurringSeries(id: string): Promise<IRecurrenceSeries> {
    if (!id?.length) return null;

    const {endTimeField, recurrenceIdField, startTimeField, stateField } = this;
    const { lib } = Context.getInstance();
    const task: CustomQueryTask = new lib.esri.QueryTask({ url: this.url });
    const query: __esri.Query = new lib.esri.Query();
    
    const now = dateUtil.getZulu(new Date());
    const v = `'${selectionUtil.escSqlQuote(id)}'`;
    const w = [
      `(${this.recurrenceIdField} = ${v})`,
      `(${endTimeField} > TIMESTAMP '${selectionUtil.escSqlQuote(now)}' OR ${stateField} = ${BOOKING_STATE.CHECKED_IN})`
    ];
    query.outFields = [this.getObjectIdField(), recurrenceIdField, this.recurrenceConfigField, startTimeField, endTimeField, stateField];
    query.returnGeometry = false;
    query.where = w.join(" AND ");
    query.orderByFields = [this.startTimeField];
   
    const response = await task.execute(query);
    const bookings = response?.features || [];
    if (bookings.length === 0) return null;

    // use first booking to get the series start DATE
    const firstEvent = bookings[0];
    const seriesStartDate = moment(getAttributeValue(firstEvent.attributes, startTimeField));
    
    // use first non-modified booking to get the series start TIME
    const masterEvent = bookings.find(b => getRecurrencePattern(b.attributes)?.modified !== "occurrence");
    const options = getRecurrencePattern(masterEvent?.attributes);
    const checkIn = moment(getAttributeValue(masterEvent?.attributes, startTimeField));
    const checkOut = moment(getAttributeValue(masterEvent?.attributes, endTimeField));
    const series: IRecurrenceSeries = {
      id,
      occurrences: [...bookings.filter(b => [BOOKING_STATE.APPROVED, BOOKING_STATE.CHECKED_IN].includes(b.attributes[this.stateField]))],
      allOccurrences: [...bookings],
      checkIn: seriesStartDate.clone().set({
        hour: checkIn.hour(),
        minute: checkIn.minute(),
        second: checkIn.second(),
        millisecond: checkIn.millisecond()
      }).toDate(),
      checkOut: seriesStartDate.clone().set({
        hour: checkOut.hour(),
        minute: checkOut.minute(),
        second: checkOut.second(),
        millisecond: checkOut.millisecond()
      }).toDate(),
      options
    }
    return series;
  }
 
  async getMasterEventDate (id: string) {
    const series = await this.getRecurringSeries(id);
    if (series) {
      return {
        startTime: series.checkIn,
        endTime: series.checkOut
      }
    }
    return null;
  }

  getSource() {
    return Context.getInstance().aiim.datasets.categories.findSourceByKey("Reservations");
  }

  query(where: string, options?: __esri.Query, requestOptions?: __esri.RequestOptions): Promise<__esri.FeatureSet> {
    if (!this.url) return Promise.resolve(null);
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task: CustomQueryTask = new lib.esri.QueryTask({ url });
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = where || "1=1";
    // @ts-ignore
    if (options && options.orderByField) query.orderByFields = options.orderByField;
    if (options && options.num) query.num = options.num;
    return task.execute(query, requestOptions);
  }

  setCurrentTimeInterval() {
    if (this.currentTimeInterval) {
      return;
    }

    const ms = this.refreshInterval ? this.refreshInterval * 60000 : 30000;
    this.currentTimeInterval = setInterval(() => {
      if (this.layer2D) {
        const { start, end } = makeCurrentTimeRange();
        setReservationsDefExp(start, end);
      }
    }, ms);
  }

  clearCurrentTimeInterval() {
    clearInterval(this.currentTimeInterval);
    this.currentTimeInterval = undefined;
  }

  queryAll(qaopts) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    qaopts = qaopts || {};
    if (qaopts.where) query.where = qaopts.where;
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts);
  }

  queryUnitIds(qaopts) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    const unitIdField = this.unitIdField
    query.outFields = [unitIdField];
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = "1=1";
    qaopts = qaopts || {};
    if (qaopts.where) query.where = qaopts.where;
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts).then(result => {
      //console.log("queryUnitIds",result)
      let index = {}, list = [];
      result.features.forEach(f => {
        let uid = f.attributes[unitIdField];
        if (uid) {
          if (!index[uid]) {
            index[uid] = uid;
            list.push(uid);
          }
        }
      });
      return {
        index: index,
        list: list
      };
    });
  }

  getGoogleData(event) {
    const { attributes } = event;
    const title = getAttributeValue(attributes, FieldNames.TITLE);
    const description = getAttributeValue(attributes, FieldNames.DESCRIPTION);
    const startDate = getAttributeValue(attributes, FieldNames.START_TIME);
    const endDate = getAttributeValue(attributes, FieldNames.END_TIME);
    const allDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
    const location = getAttributeValue(attributes, FieldNames.UNIT_NAME);
    return { title, description, startDate, endDate, allDay, location };
  }

  queryUnitSchedule(unitId: string, fromDate: Date, toDate: Date, upcomingOnly?: boolean): Promise<{ reserved: IScheduleItem[] }> {
    if (!this.url) return Promise.resolve(null);
    let v = selectionUtil.escSqlQuote(unitId);
    let w = "("+this.unitIdField+" = '"+v+"')";
    let w2 = "("+this.stateField+" IN (0,1,4))"; // pending, approved, checked-in
    w = "("+w+" AND "+w2+")";
    if (fromDate && toDate) {
      let start = dateUtil.getZulu(fromDate);
      let end = dateUtil.getZulu(toDate);
      let w3 = "("+this.startTimeField + " < TIMESTAMP '" + end+"')";
      w3 += " AND ("+this.endTimeField + " > TIMESTAMP '" + start+"')";
      if (upcomingOnly) {
        let currentDateTime = dateUtil.getZulu(new Date());
        w3 += " AND ("+this.endTimeField + " >= TIMESTAMP '" + currentDateTime + "')";
      }
      w = "("+w+" AND ("+w3+"))";
    }
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.orderByFields = [this.startTimeField];
    query.returnGeometry = false;
    query.where = w;

    const reservationInfo = {
      reserved: []
    }
    const qaopts = {
      perFeatureCallback: f => {
        let row: IScheduleItem = {
          oid: f.attributes[this.layer2D.objectIdField],
          fromDate: f.attributes[this.startTimeField],
          toDate: f.attributes[this.endTimeField],
          title: f.attributes[this.titleField],
          description: f.attributes[this.descriptionField],
          unitName: f.attributes[this.unitNameField],
          levelId: f.attributes[this.levelIdField],
          reserveeFullName: f.attributes[this.reservedForFullNameField],
          reserveeUsername: f.attributes[this.reservedForUsernameField],
          feature: f
        }
        reservationInfo.reserved.push(row);
      }
    };

    const qa = new QueryAll();
    return qa.execute(url,query,qaopts).then(result => {
      //console.log("reservationInfo",reservationInfo)
      return reservationInfo;
    });
  }

  hasReservedByFields() {
    const layer = this.layer2D;
    const fields = layer && layer.fields;
    if (!fields) {
      return false;
    }

    const hasUsernameField = !!findField(fields, FieldNames.RESERVED_BY_USERNAME);
    const hasFullNameField = !!findField(fields, FieldNames.RESERVED_BY_FULL_NAME);
    return hasUsernameField && hasFullNameField;
  }

  hasRecurrenceFields() {
    const { fields } = this.layer2D;
    if (!fields) {
      return false;
    }

    const hasRecurrenceIdField = !!findField(fields, FieldNames.RECURRENCE_ID);
    const hasRecurrenceConfigField = !!findField(fields, FieldNames.RECURRENCE_CONFIG);
    return hasRecurrenceIdField && hasRecurrenceConfigField;
  }
}
