import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Context from "../../../../context/Context";
import MapSelection from "../support/MapSelection";
import DeleteVM from "./DeleteVM";
import { LayerType } from "../../../../util/interfaces";
import Savebar, { ISavebar } from "../support/Savebar";
import FeatureList from "../support/FeatureList";
import {
  getActiveHelpTip,
  getDeleteConfirmationVisibility,
  getHitTestResults,
  getSelectedFeatureIndex,
  HitTest,
  setDeleteConfirmationVisibility,
  setHelpTip,
  setHitTestResults,
  setSelectedFeatureIndex,
  setUndoRedoDisabled
} from "../redux";
import { IFeatureItem } from "../copypaste/featureItemUtil";
import * as featureItemUtil from "../copypaste/featureItemUtil";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-loader";
import "@esri/calcite-components/dist/components/calcite-modal";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import {
  CalciteIcon,
  CalciteButton,
  CalciteCheckbox,
  CalciteChip,
  CalciteLabel,
  CalciteModal,
  CalciteNotice,
  CalcitePanel,
  CalciteSegmentedControl,
  CalciteSegmentedControlItem
} from "@esri/calcite-components-react";
import { useHandles } from "../../../miniapps/common/hooks";
import Topic from "../../../../context/Topic";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import { getAttributeValue } from "../../../../aiim/util/aiimUtil";

type SelectionType = Extract<LayerType, "unit" | "detail"> | "both";

const VIRTUALIZE_LIST = true;

const DeletePanel = () => {
  const ctx = Context.getInstance();
  const { i18n } = ctx;
  // redux
  const dispatch = useDispatch();
  const tip = useSelector(getActiveHelpTip);
  const hitTestResults = useSelector(getHitTestResults);
  const selectedFeatureIndex = useSelector(getSelectedFeatureIndex);
  const shouldShowConfirmationMessage = useSelector(getDeleteConfirmationVisibility);
  const instructions = i18n.editor._delete.instructions;
  const byType = (items: HitTest[]) => {
    const final = [];
    for (let i = 0; i < items.length; i++) {      
      if (["unit", "detail"].includes(items[i]?.key)
        && (currentSelectionType === "both" || items[i]?.key === currentSelectionType)) {
        final.push(items[i]);
      }
    }
    return final;
  }
  const findIndex = (item: IFeatureItem) => item.hitIndex !== selectedFeatureIndex
    ? hitTestResults.findIndex(h => `${h.key}_${h.oid}` === item.key)
    : item.hitIndex;

  // refs
  const viewModel = useRef<DeleteVM>(new DeleteVM());
  const saveBarRef = useRef<ISavebar>();
  // state
  const setHandles = useHandles()[1];
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [updateConfirmationMessage, setUpdateConfirmationMessage] = useState<boolean>(false);
  const [currentSelectionType, setCurrentSelectionType] = useState<SelectionType>("both");
  const selectedFeatureItems = useRef<IFeatureItem[]>(
    featureItemUtil.hitsToFeatureItems(byType(hitTestResults), selectedFeatureIndex));
  const [info, setInfo] = useState<{ canSave: boolean, currentCount: number, isWorking: boolean }>({
    currentCount: selectedFeatureItems.current.length,
    canSave: false,
    isWorking: false
  });

  useEffect(() => {
    const handles = [];
    handles.push(
      Topic.subscribe(Topic.PlanModified, params => {
        try {
          if (params && (params.wasUndoRedo || params.wasReconciled)) {
            reset();
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    );
    setHandles(handles);
    return () => {
      selectedFeatureItems.current.length = 0;
      viewModel.current.destroy();
      reset();
      dispatch(setUndoRedoDisabled(false));
    }
  }, []);

  useEffect(() => {
    selectedFeatureItems.current = featureItemUtil.hitsToFeatureItems(byType(hitTestResults), selectedFeatureIndex);
    setInfo({
      ...info,
      currentCount: selectedFeatureItems.current.length,
      canSave: validate()
    });
  }, [hitTestResults, currentSelectionType]);

  const closeAlert = () => {
    Topic.publish(Topic.ClearToast, null);
  }

  const isSingleFloor = () => new Set(selectedFeatureItems.current.map(i =>
    getAttributeValue(i.graphic?.attributes, FieldNames.LEVEL_ID))).size <= 1;

  const validate = () => selectedFeatureItems.current.length > 0 && isSingleFloor();

  const renderToolbar = () => {
    return (
      <>
        <MapSelection
          view={viewModel.current.getView()}
          tools={["point", "rectangle", "polygon"]}
          selectionTypes={currentSelectionType === "both" ? ["detail", "unit"] : [currentSelectionType]}
          alwaysHighlightActive={true}
          ignoreVisibleAtScale={true}
          autoSetFloorFilter={true}
          activeFillSymbol={viewModel.current.symbolParams.activeFillSymbol}
          activeLineSymbol={viewModel.current.symbolParams.activeLineSymbol}
          fillSymbol={viewModel.current.symbolParams.fillSymbol}
          lineSymbol={viewModel.current.symbolParams.lineSymbol}
        />
        <span className="i-delete-panel-selection-label">{i18n.editor._delete.selected}</span>
        <CalciteChip value={info.currentCount} kind="brand" scale="s">
          {info.currentCount}
        </CalciteChip>
      </>
    )
  }
  const renderFeatureList = () => {
    return <FeatureList
      virtualize={VIRTUALIZE_LIST}
      selectedFeatureItems={selectedFeatureItems.current}
      onRemove={(item) => {
        const index = findIndex(item);
        const newResults = [...hitTestResults];
        newResults.splice(index, 1);
        dispatch(setHitTestResults(newResults));
        dispatch(setSelectedFeatureIndex(selectedFeatureIndex == null
          ? null
          : selectedFeatureIndex >= newResults.length
            ? newResults.length - 1
            : index < selectedFeatureIndex
              ? selectedFeatureIndex - 1
              : selectedFeatureIndex
        ));
      }}
      onSelect={(item) => {
        dispatch(setSelectedFeatureIndex(item.selected ? findIndex(item) : null));
      }}
    />
  }
  const renderSelectionTypes = () => {
    const checked = (type: SelectionType) => ({
      ...type === currentSelectionType && { checked: true }
    });
    return (
      <CalciteSegmentedControl width="full" onCalciteSegmentedControlChange={(e) =>
        setCurrentSelectionType(e.target.value as SelectionType)}>
        <CalciteSegmentedControlItem value="both" {...checked("both")}>
          {i18n.editor._delete.selectionType.both}
        </CalciteSegmentedControlItem>
        <CalciteSegmentedControlItem value="unit" {...checked("unit")}>
          {i18n.editor._delete.selectionType.units}
        </CalciteSegmentedControlItem>
        <CalciteSegmentedControlItem value="detail" {...checked("detail")}>
          {i18n.editor._delete.selectionType.details}
        </CalciteSegmentedControlItem>
      </CalciteSegmentedControl>
    )
  }
  const renderConfirmation = () => {
    return showConfirmation && (
      <CalciteModal open widthScale="s" scale="s" closeButtonDisabled onCalciteModalClose={() => setShowConfirmation(false)}>
        <header slot="header">{i18n.editor._delete.modalTitle}</header>
        <div slot="content">{i18n.editor._delete.modalDescription}</div>
        <div slot="back" className="i-editor-delete-message-box">
          <CalciteLabel layout="inline">
            <CalciteCheckbox
              checked={!shouldShowConfirmationMessage || updateConfirmationMessage || undefined}
              onCalciteCheckboxChange={e => setUpdateConfirmationMessage(e.target.checked)} />
            {i18n.editor._delete.modalDoNoShow}
          </CalciteLabel>
        </div>
        <CalciteButton
          slot="primary"
          width="full"
          kind={"danger"}
          title={i18n.general.del}
          onClick={save}>
          {i18n.general.del}
        </CalciteButton>
        <CalciteButton
          slot="secondary"
          width="full"
          kind={"danger"}
          appearance="outline"
          title={i18n.general.cancel}
          onClick={() => setShowConfirmation(false)}>
          {i18n.general.cancel}
        </CalciteButton>
      </CalciteModal>
    )
  }
  const reset = async () => {
    dispatch(setHitTestResults([]));
    dispatch(setSelectedFeatureIndex(null));
    viewModel.current.reset();
  }
  const save = async () => {
    closeAlert();
    setShowConfirmation(false);
    viewModel.current.save(selectedFeatureItems.current)
      .then(() => reset())
      .then(() => {
        Topic.publish(Topic.ShowToast, {
          open: true,
          type: "success",
          message: i18n.editor._delete.deleteSuccess,
          submessage: i18n.editor._delete.successMessage
        });
      })
      .catch((error) => {
        const details = error.details;
        const submessage = details && details.messages && details.messages.length
          ? details.messages.join("\n")
          : error.message;
        Topic.publishErrorUpdatingData(submessage);
      });
    if (updateConfirmationMessage) {
      dispatch(setDeleteConfirmationVisibility(false));
    }
  }
  const typeLabel = currentSelectionType === "detail"
    ? i18n.editor._delete.selectionType.details
    : i18n.editor._delete.selectionType.units;
  return (
    <div className="i-editor-sidebar-container i-editor-delete-container">
      <div className="i-editor-sidebar-toolbar">
        {renderToolbar()}
      </div>
      <div className="i-editor-sidebar-content">
        <CalcitePanel>
          <CalciteNotice icon="lightbulb" scale="s" closable open={!(tip && tip.visible) ? true : undefined}
            onCalciteNoticeClose={() => dispatch(setHelpTip("delete", {
              visible: true,
              tooltip: instructions
            }))}>
            <div slot="message">{instructions}</div>
          </CalciteNotice>
          <div className="i-editor-delete-types">
            {renderSelectionTypes()}
          </div>
          <div className={`i-editor-feature-list${VIRTUALIZE_LIST ? " i-editor-virtualized-list" : ""}`}>
            {renderFeatureList()}
          </div>
          {!isSingleFloor() &&
            <div className="i-editor-delete-warning">
              <CalciteIcon className="i--warning" icon="exclamation-mark-triangle" scale="s" />
              {currentSelectionType === "both"
                ? i18n.editor._delete.sameFloorRequiredBoth
                : i18n.editor._delete.sameFloorRequired.replace("{features}", typeLabel.toLowerCase())
              }
            </div>}
        </CalcitePanel>
      </div>
      <div className="i-editor-sidebar-footer">
        <Savebar
          parentPanelKey={`deletePanel_${currentSelectionType}`}
          resetDisabled={false}
          saveDisabled={!info.canSave}
          saveLabel={i18n.general.del}
          kind="danger"
          onReset={() => reset()}
          onSave={() => {
            closeAlert();
            if (shouldShowConfirmationMessage) {
              setShowConfirmation(true);
            } else {
              save();
            }
          }}
          ref={saveBarRef}
        />
        {renderConfirmation()}
      </div>
    </div>
  )
}
export default DeletePanel;