import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Context from "../../../../context/Context";
import Rdx, { IRootState } from "../../../../redux/Rdx";
import ItemProperties from "../../common/components/ItemProperties";
import VersionProperties from "../../common/components/VersionProperties";
import Modal, { IModalProps, IErrorOptions } from "../../common/components/Modal";
import { SharingLevels } from "../../../../common/components/Sharing";
import { IItemProperties } from "../../common/types";
import { NewPlanVM } from "./NewPlanVM";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-loader";
import "@esri/calcite-components/dist/components/calcite-alert";
import "@esri/calcite-components/dist/components/calcite-block";
import { CalciteButton, CalciteLoader, CalciteAlert, CalciteBlock } from "@esri/calcite-components-react";
import { JSX as CalciteJSX } from "@esri/calcite-components";

interface INewPlanProps {
  appLoaded?: boolean,
  isSaveAs: boolean,
  onClose?: () => void
}
const NewPlan = ({ isSaveAs, onClose }: INewPlanProps) => {
  const viewModel = useRef<NewPlanVM>(new NewPlanVM({ isSaveAs }));
  const modal = useRef<HTMLCalciteModalElement>();
  const isWorking = useRef<boolean>(false);
  const [formOptions, setFormOptions] = useState<Partial<IModalProps>>({ open: true });
  const [errorOptions, setErrorOptions] = useState<Partial<CalciteJSX.CalciteAlert & IErrorOptions>>({ open: false });
  const appLoaded = useSelector<IRootState, boolean>((state) =>
    Rdx.getValue(state, Rdx.Keys.APP_STARTED))
  const ctx = Context.getInstance();
  const i18n = ctx.i18n;

  const renderHostedFields = () => (
    <ItemProperties
      disallowEveryone={true}
      horizontal={false}
      shareWith={SharingLevels.PRIVATE}
      onMount={setProperties}
      submitHandle={saveClicked}>
      {renderButtons()}
    </ItemProperties>
  );

  const renderVersionedFields = () => (
    <VersionProperties
      horizontal={false}
      onMount={setProperties}
      submitHandle={saveClicked}>
      {renderButtons()}
    </VersionProperties>
  );
  const renderButtons = () => (
    <div className="i--form-buttons">
      <CalciteButton width="auto" appearance="outline" className="i--blue"
        onClick={() => {
          setFormOptions({ open: false });
          typeof onClose === "function" && onClose();
        }}>
        {i18n.general.cancel}
      </CalciteButton>
      <CalciteButton width="auto" type="submit" className="i--blue">
        {i18n.spaceplanner.backstage.newPlan.createPlan}
      </CalciteButton>
    </div>
  )

  const saveClicked = async () => {
    if (isWorking.current) return;
    isWorking.current = true;
    setFormOptions({ open: false });
    viewModel.current.create().then((result) => {
      const planner = ctx.spaceplanner.planner;
      planner.reloadApp(result.planAppId, result.planId);
    }).catch(error => {
      isWorking.current = false;
      if (error) {
        setErrorOptions({
          ...error,
          open: true,
          errors: {
            generic: error.message,
            detailed: Array.isArray(error.submessage)
              ? error.submessage
              : [error.submessage]
          }
        });
      }
    });
  }

  const closeErrorModal = () => {
    errorOptions.open && setErrorOptions({ open: false });
    setFormOptions({ open: true });
  }
  const setProperties = (properties: IItemProperties) =>
    viewModel.current.properties = properties;

  return (
    <>
      {/* Main modal */}
      {
        <Modal
          open={formOptions.open && !errorOptions.open}
          widthScale="s"
          onMount={(m) => modal.current = m}
          className="i--form"
          onClose={() => {
            if (formOptions.open) {
              setFormOptions({ open: false });
              typeof onClose === "function" && onClose();
            }
          }}>
          <header slot="header">{i18n.spaceplanner.backstage.newPlan.createPlan}</header>
          <div slot="content">
            {appLoaded
              ? viewModel.current.isVersioned()
                ? renderVersionedFields()
                : renderHostedFields()
              : <CalciteLoader label={i18n.explore.loading} />
            }
          </div>
        </Modal>}

      {/* Errors */}
      {
        errorOptions && errorOptions.open &&
        <CalciteAlert
          {...errorOptions}
          className="i-error-details"
          scale="m"
          kind="danger"
          label="New Plan Error"
          placement="bottom-end"
          icon="exclamation-mark-triangle"
          style={{ position: "absolute" }}
          onCalciteAlertClose={closeErrorModal}>
          <div slot="message">
            {errorOptions.errors.generic}
            {errorOptions.errors.detailed && errorOptions.errors.detailed.filter(d => !!d).length > 0 &&
              <CalciteBlock collapsible heading={i18n.more.bookWorkspace.errors.moreDetails} >
                <div>
                  <ul>
                    {errorOptions.errors.detailed.filter(d => !!d).map((e, i) =>
                      (<li key={i}>{e}</li>))}
                  </ul>
                </div>
              </CalciteBlock>
            }
          </div>
        </CalciteAlert>
      }
    </>
  );
}

export default NewPlan;