import React from "react";

// Components
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Items from "../common/Items";
import QueryCriteria from "../../base/QueryCriteria";
import Search from "../common/Search";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as sourceUtil from "../../base/sourceUtil";

// Styled Components
import { Panel } from "../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAttribute
} from "../../styles/Common/list";

export default class UnitsList extends React.Component {

  componentId;

  constructor(props) {
    super(props);
    this.componentId = component.nextId("qc");

    const source = sourceUtil.getUnitsSource();
    const fields = source.layer2D.fields;
    let asnFieldName = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;
    const asnField = aiimUtil.findField(fields, asnFieldName);
    if (asnField) asnFieldName = asnField.name;
    const unitField = Context.instance.aiim.getUnitName();  

    const queryCriteriaKey = this.getQueryCriteriaKey();
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      source.key
    );
    queryCriteria.num = 30;
    queryCriteria.requiresGeometry = !!this.props.returnGeometry;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = unitField;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc"
      };
    }

    const wh = `${asnFieldName} <> 'not assignable' OR ${asnFieldName} IS NULL`;
    queryCriteria.requiredExpression = wh;
    queryCriteria.where = queryCriteria.makeWhere(queryCriteria.getSource());

    this.state = component.newState({
      selectedItem: null
    });
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.RefreshUnitName,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    const session = Context.instance.session;
    const queryCriteriaKey = this.getQueryCriteriaKey();
    if (session.queryCriteriaByKey) {
      delete session.queryCriteriaByKey[queryCriteriaKey];
    }
    component.componentWillUnmount(this);
  }

  getQueryCriteriaKey() {
    return this.props.queryCriteriaKey || this.componentId;
  }

  handleSelection = featureItem => {
    if (this.props.handleSelection) {
      this.props.handleSelection(featureItem);
    }
    this.setState({
      selectedItem: featureItem
    });
  };

  render() {
    const i18n = Context.instance.i18n;
    const queryCriteriaKey = this.getQueryCriteriaKey();
    const rdxFeatureItemsKey = null;
    const placeholder = i18n.spaceplanner.units.listHeaderPlaceholder;

    return (
      <Panel bordered>
        <Search
          fullWidth
          minimal
          queryCriteriaKey={queryCriteriaKey}
          placeholder={placeholder}
          searchTextChanged={this.searchTextChanged}
        />
        <Items
          queryCriteriaKey={queryCriteriaKey}
          rdxFeatureItemsKey={rdxFeatureItemsKey}
          renderItem={this.renderItem}
          isShowAllButton={false}
        />
      </Panel>
    );
  }

  renderItem = (source, featureItem, index) => {
    const key = featureItem.key;
    const attributes = featureItem.feature.attributes;
    const unitField = Context.instance.aiim.getUnitName();
    const i18n = Context.instance.i18n;
    const useTypeField = "use_type";
    const name = aiimUtil.getAttributeValue(attributes, unitField);

    let useType = aiimUtil.getAttributeValue(attributes, useTypeField);
    const asnName = officePlanUtil.getUnitAssignmentName(featureItem);
    let selected = false;
    if (this.state.selectedItem) {
      selected = (key === this.state.selectedItem.key);
    }
    const isUnassigned = officePlanUtil.isUnitUnassigned(featureItem);

    let siteName, facilityName, levelNumber;
    const zInfo = Context.instance.aiim.getZInfo(source,featureItem.feature);
    const levelData = zInfo && zInfo.levelData
    if (levelData) {
      siteName = levelData.siteName;
      facilityName = levelData.facilityName;
      levelNumber = levelData.levelNumber;
      if (!siteName) siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      //console.log("siteName",siteName,"facilityName",facilityName,"levelNumber",levelNumber)
    } else {
      siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      facilityName = aiimUtil.getAttributeValue(attributes,FieldNames.FACILITY_NAME);
      levelNumber = aiimUtil.getAttributeValue(attributes,FieldNames.LEVEL_NUMBER);
    }

    let facilityLevel = null;
    if (facilityName && typeof levelNumber === "number") {
      const pattern = i18n.spaceplanner.units.facilityFloorPattern;
      facilityLevel = pattern.replace("{facility}", facilityName);
      facilityLevel = facilityLevel.replace("{level}", levelNumber);
    } else {
      facilityLevel = (facilityName || levelNumber || null);
    }

    const unitId = aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_ID);
    const disabledUnitIds = this.props.disabledUnitIds || [];
    const disabled = !!(disabledUnitIds && disabledUnitIds.indexOf(unitId) !== -1);

    return (
      <ListItem
        key={key}
        onClick={() => this.handleSelection(featureItem)}
        selected={selected}
        unassigned={isUnassigned}
        disabled={disabled}
      >
        <ListItemTitle>{name}</ListItemTitle>
        <ListItemSubtitle>
          <ListItemAttribute>{siteName || null}</ListItemAttribute>
          <ListItemAttribute>{facilityLevel}</ListItemAttribute>
          <ListItemAttribute>{useType}</ListItemAttribute>
          <ListItemAttribute>{asnName}</ListItemAttribute>
        </ListItemSubtitle>
      </ListItem>
    );
  };

  searchTextChanged = (text) => {
    this.handleSelection(null);
  }

}
