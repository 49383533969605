
import React from "react";
import moment from "moment";
import DatePicker from "calcite-react/DatePicker";
import Context from "../../../context/Context";
import { ModalController } from "../../../spaceplanner/components/Modal";
import MonthYearSelector from "../../../spaceplanner/components/Filter/MonthYearSelector";
  
export function showDatePicker(date, onDateChange, isOutsideRange) {
    const initialDate = moment(date);
  
    const _onDateChange = (_date) => {
      controller.close();
      if (typeof onDateChange === "function") {
        onDateChange(_date);
      }
    };

    const props = {
        className: "i-disable-calendar-input",
        showOKCancel: false,
        closeOnOK: true,
        noPadding: true,
        overlayStyle: {width:"320px !important"}
    };   

    let controller = new ModalController();
    const content = (
      <DatePicker
        id="date-picker"
        date={initialDate}
        selected={initialDate}
        onDateChange={_onDateChange}
        focused={true}
        isRTL={Context.getInstance().uiMode.isRtl}
        hideInputIcon={true}
        isOutsideRange={isOutsideRange}
        onFocusChange={()=>{}}
        renderMonthElement={({month, onMonthSelect, onYearSelect}) => (
            <MonthYearSelector 
                month={month}
                onYearSelect={onYearSelect} 
                onMonthSelect={onMonthSelect}/>
      )}/>
    )
    controller.show(props, content);
}