import * as mixinUtil from "./mixinUtil";

export default class BaseClass {
  _handles: __esri.Handle[];

  // TODO: Verify this property is necessary
  handles: __esri.Handle[];

  constructor(props?) {
    // @todo the mixin no longer works, 
    // not sure if it's TypeScript related or a change to the JavaScript target version,
    // currently each subclass must also mixin within their constructor
    this.mixinProps(props);
  }

  clearHandles() {
    let handles = this._handles;
    if (!Array.isArray(handles)) return;
    handles.forEach((h) => {
      try {
        if (h && typeof h.remove === "function") {
          // Dojo handle
          h.remove();
          //console.log("BaseClass::destroy - handle removed");
        }
      } catch(ex) {
        console.warn("Error BaseClass::destroy - error removing handle:",h);
        console.error(ex);
      }
    });
    this.handles = this._handles = [];
  }

  destroy() {
    this.clearHandles();
  }

  protected mixinProps(props?) {
    if (props) mixinUtil.mixin(this, props);
  }

  own(handles: __esri.Handle[] | __esri.Handle) {
    try {
      if (!handles) return;
      if (!Array.isArray(handles)) handles = [handles];
      if (handles.length === 0) return;
      if (this._handles) {
        this._handles = this._handles.concat(handles);
      } else {
        this._handles = handles.slice();
      }
    } catch(ex) {
      console.warn("Error tracking handles:",this);
      console.error(ex);
    }
  }

}
