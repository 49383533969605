import React from 'react';
import moment from 'moment';
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import Rdx from "../../../../../redux/Rdx";
import PencilIcon from "calcite-ui-icons-react/PencilIcon"; // EditAttributesIcon
import RecurrenceIcon from "calcite-ui-icons-react/RecurrenceIcon";

// 3rd party
import Loader from "calcite-react/Loader";
import Tooltip from "calcite-react/Tooltip";
import WarningIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import XIcon from "calcite-ui-icons-react/XIcon";
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';

// aiim.base
import ItemReference from '../../../../../aiim/base/ItemReference';

// components.main.Actions.More.BookWorkspace.WorkspaceReservation
import { getHotelBookingSystem, getHotelBookingSystemType } from './WorkspaceReservation/workspaceReservationUtil';
import * as workspaceReservationUtil from "./WorkspaceReservation/workspaceReservationUtil";
import { getBookings, cancelBooking} from "./WorkspaceReservation/OfficeHotelingInterface";
import Office365, { IIndoorsEvent } from './WorkspaceReservation/Office365';

// components.util
import * as component from '../../../../util/component';

// util
import { stringFormatter } from '../../../../../util/formatUtil';

// context
import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';

// common/components/Modal
import { ModalController }  from "../../../../../common/components/Modal/index";
import BookingDetails, { ICheckAvailabilityOptions } from './BookingDetails';
import BookingCancel from './BookingCancel';
import * as validateUtil from './validateUtil';
import * as dateUtil from '../../../Events/dateUtil';

import { findFieldName, getAttributeValue, getRecurrencePattern } from '../../../../../aiim/util/aiimUtil';
import FieldNames from '../../../../../aiim/datasets/FieldNames';
import BookingConfirmed from './BookingConfirmed';
import Schedule, { ISelectedWhen } from '../../../MeetingRooms/Schedule';
import Icons from '../../../../util/Icons';
import RecurrenceDropdown, { RecurrenceType } from './RecurrenceDropdown';
import { IBookingsProps } from './MyBookings';
import { ResponseType } from "@microsoft/microsoft-graph-types";
import { IBookingDateFilter } from '../../../Events/BookingDateFilter';
import { IBookingSystem, IOffice365BookingTask } from './WorkspaceReservation/BookingSystem';
import { BookingType } from '../../../../../util/calendarUtil';
import { IRecurrenceOptions } from './BookingRecurrence';

const CSS = {
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  bookingInfoLine: "i-booking-info-line",
  locationDescription: "i-booking-location-description",
  button: "i-book-button",
  buttonCheckIn: "i-book-card-button-checkIn",
  buttonCheckOut: "i-book-card-button-checkOut",
  actionGroup: "i-action-group",
  actionSubGroup: "i-action-subgroup",
  cancel: "i-booking-cancel",
  edit: "i-booking-edit",
  descriptionContent: "i-booking-description",
  recurrenceIcon: "i-recurrence-icon"
};
export interface IBookingsState {
  bookings?: IIndoorsEvent[],
  isWorking: boolean,
  units: __esri.Graphic[]
}
export default class MyBookingsOffice365 extends React.Component<IBookingsProps, IBookingsState> {

  where: string;
  bookingSystem = getHotelBookingSystem();
  private _mounted: boolean;

  constructor(props: IBookingsProps) {
    super(props);

    this.state = component.newState({
      bookings: [],
      isWorking: true,
      units: []
    });
  }

  override componentDidMount() {
    this._mounted = true;
    this._init();
    component.own(this, [
      Topic.subscribe(Topic.RenderBookingListOffice365, () => {
        this._mounted && this.setState({isWorking: true})
        this._init();
      })
    ])
  }
  
  override componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingDateFilter !== this.props.bookingDateFilter) {
      this.setState({isWorking: true});
      this._init();
    }
  }

  override componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  _getBookings = (): Promise<{ value: IIndoorsEvent[] }> => {
    let currentDateTime = moment(Date.now()).add(1,"milliseconds").toISOString();
    const params = {
      currentDateTime: currentDateTime,
      bookingType: "hotel",
      bookingDateFilter: this.props.bookingDateFilter
    }
    return getBookings(this.bookingSystem, params).catch(e => {
      console.error(e);
      throw e;
    });
  }

  _getUserBookings() {
    return this._getBookings().then(scheduleResult => {
      const bookings: IIndoorsEvent[] = scheduleResult?.value || [];
      const ids = new Set<string>();
      bookings.forEach((item) => ids.add(item.extensions[0]?.unitId));
      const unitIds = [...ids].filter(x => !!x);
      if (unitIds.length > 0) {
        workspaceReservationUtil.queryUnitByUnitId(unitIds).then(fs => {
          this._mounted && this.setState({
            bookings,
            isWorking: false,
            units: fs?.features || []
          });
        });
      } else {
        this._mounted && this.setState({
          bookings: [],
          isWorking: false,
          units: []
        })
      }
    }).catch(e => {
      console.error(e);
      throw e;
    });
  }

  _init =async()=> {
    return await this._getUserBookings();
  }

  makeBookingList = () => {
    const { bookings, units } = this.state;
    const myBookingsList = [];
    const dataset = Context.getInstance().aiim.datasets?.units;
    const layer = dataset?.layer2D;
    const unitIdField = findFieldName(layer?.fields, FieldNames.UNIT_ID);
    bookings.forEach((booking) => {
      const unit = units.find(u => getAttributeValue(u.attributes, unitIdField) === booking.extensions[0]?.unitId);
      myBookingsList.push(
        <MyBookingsCard 
          key={booking.id}
          booking={booking}
          bookingDateFilter={this.props.bookingDateFilter}
          bookingSystem={this.bookingSystem}
          unitFeature={unit}
        />
      );
    });

    return myBookingsList;
  }

  renderBookingCards() {
    const i18n = Context.getInstance().i18n;
    const bookingList = this.makeBookingList();

    return bookingList && bookingList.length > 0 ? (
      <ul className={CSS.bookingCardList}>
        {bookingList}
      </ul>
    ) : (
      <div style={{ padding: '0.7rem 1rem' }}>{i18n.more.bookWorkspace.noBookings}</div>
    );
  }

  renderWorking() {
    return (<Loader sizeRatio={1} style={{ marginTop: '3rem' }} />);
  }

  render() {
    const { isWorking } = this.state;
    return isWorking ? this.renderWorking() : this.renderBookingCards();
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
interface IBookingCardProps {
  bookingDateFilter: IBookingDateFilter,
  booking: IIndoorsEvent,
  bookingSystem: IBookingSystem,
  unitFeature: __esri.Graphic
}
interface IBookingCardState {
  facilityName?: string,
  isLocationChange: boolean,
  levelName?: string,
  popoverOpen: boolean,
  popoverType?: "calendar" | "cancel" | "edit",
  scheduleEmail?: string,
  status: ResponseType,
  unitName?: string
}
class MyBookingsCard extends React.Component<IBookingCardProps, IBookingCardState> {
  private _componentId: string;
  private _itemRef;
  private _mounted: boolean;

  constructor(props: IBookingCardProps) {
    super(props);
    this._componentId = component.nextId("booking-");
    this._itemRef = React.createRef();
    this.state= {
      isLocationChange: this._isLocationChange(),
      status: "none",
      popoverOpen: false,
      popoverType: null
    }
  }

  override componentDidMount() {
    this._mounted = true;
  }

  _isLocationChange =()=> {
    const booking = this.props.booking;
    const extensions = booking && booking.extensions && booking.extensions[0];
    const unitId = extensions && extensions.unitId;
    const unc = Context.instance.session.unitNameCache;
    if (unc && unc.defaultNamesPerUnitId && unitId) {
      const origUnitName = unc.defaultNamesPerUnitId[unitId];
      if (origUnitName !== this.displayName) return true
      return false;
    }
  }

  _statusClicked = async (status) => {
    if (status === "declined") {
      const i18n = Context.instance.i18n;
      const options = {
        title: i18n.more.bookWorkspace.declineBookingO365Title,
        message: i18n.more.bookWorkspace.declineBookingO365Msg,
        okLabel: i18n.general.ok,
        showOkCancel: true,
        hideCancel : true,
        closeOnOK: true
      };
      ModalController.confirm(options);
      return;
    } else if (status === "mismatchEmail") {
      const booking = this.props.booking;
      const unitName = this.displayName;
      const scheduleEmail = booking && booking.extensions && booking.extensions[0] && booking.extensions[0].scheduleEmail;
      let attendeeEmails = [], attendeeEmailsStr: string;
      for (let i=0;i<booking.attendees.length;i++) {
        const emailAddress = booking.attendees[i].emailAddress.address;
        attendeeEmails.push(emailAddress);
        attendeeEmailsStr = attendeeEmails.join(", ")
      }      
      const i18n = Context.instance.i18n;
      let msg = i18n.meetingRooms.emailMismatch.message;
      msg = msg.replace("{scheduleEmail}", scheduleEmail);
      msg = msg.replace("{unitName}", unitName);
      msg = msg.replace("{attendeeEmails}", attendeeEmailsStr)

      ModalController.showError({
        title: i18n.general.information,
        className: "i-portrait-access-hotel-error",
        errors: {
          generic: i18n.meetingRooms.emailMismatch.caption,
          detailed: [msg]
        }
      });
    }
  }

  _bookingCardClicked = async (e, considerDeclined?: boolean) => {
    const isLocationChange = this.state.isLocationChange;
    const declined = Office365.wasDeclined(this.props.booking);
    if (isLocationChange || (considerDeclined && declined)) {
      const i18n = Context.instance.i18n;
      const options = {
        title: (isLocationChange ? i18n.meetingRooms.locationChange.caption : i18n.more.bookWorkspace.declineBookingO365Title),
        message: (isLocationChange ? i18n.meetingRooms.locationChange.message : i18n.more.bookWorkspace.declineBookingO365Msg),
        okLabel: i18n.general.ok,
        showOkCancel: true,
        hideCancel : true,
        closeOnOK: true
      };
      ModalController.confirm(options);
      return;
    }
    const source = Context.instance.aiim.datasets.units.getSource();
    const searchResult = source.makeSearchResult(null, this.props.unitFeature);
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      componentId: this._componentId
    });
  }

  _cancelClicked = (type?: RecurrenceType) => {
    const booking = this.props.booking;
    const unitName = this.displayName;
    const eventId = booking && booking.id;
    const seriesMasterId = booking && booking.seriesMasterId;
    const isRecurring = booking.recurrence ? true : false;
    const i18n = Context.getInstance().i18n;
    let msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.message;
    if (unitName) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.newMessage;
      msg = msg.replace("{unit}",unitName);
    }
    if (isRecurring) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.seriesMessage;
      if (type === "occurrence") msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.occurrenceMessage;
      msg = msg.replace("{unit}", unitName);
    }

    let okButton = null;
    const options = {
      title: i18n.more.bookWorkspace.checkInOut.cancelBooking.modalTitle,
      message: msg,
      okLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.yes,
      cancelLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.no,
      showOKCancel: true,
      closeOnOK: false,
      closeOnCancel: true,
      onMountOKButton: btn => {
        okButton = btn;
      }
    };
    ModalController.confirm(options)
      .then((result) => {
        if (result && result.ok) {
          return new Promise<void>((resolve, reject) => {
            if (okButton) okButton.disable();
            let currentDateTime = moment(Date.now()).add(1,"milliseconds").toISOString();
            const params = {
              currentDateTime: currentDateTime,
              bookingType: "hotel",
              bookingDateFilter: this.props.bookingDateFilter
            }
            getBookings(this.props.bookingSystem, params)
            .then((res)=> {
              const value = res && res.value;
              let isBooking = false;
              for (let i=0;i<value.length;i++) {
                if (value[i].id === eventId) {
                  isBooking = true;
                  break;
                }
              }
  
              if (!isBooking) {
                Topic.publish(Topic.ShowToast,{
                  message: i18n.more.bookWorkspace.checkInOut.cancelBooking.bookingNotAvailable
                });
                return "bookingNotAvailable";
              } else {
                let params = {eventId: null}
                if (type === "series") {
                  params.eventId = seriesMasterId;
                } else {
                  params.eventId = eventId
                }
                return cancelBooking(this.props.bookingSystem, params)
              }
            })
            .then((info)=> {
              if (result.controller) result.controller.close();
              Topic.publish(Topic.RenderBookingListOffice365, {});
              if (info === "bookingNotAvailable") {
                resolve();
                return;
              };

              this._renderCancelConfirmed(booking); 
              resolve();
            })
            .catch(e => {
              if (result.controller) result.controller.close();
              console.error("Couldn't cancel hotel booking", e);
              reject(e);
            });
          })
        }
      })     
      .catch((e) => {
        console.error(e);
      });
  }

  override componentWillUnmount(){
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;

    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this._bookingCardClicked(event);
    }
  }
  get displayName() {
    const { booking } = this.props;
    return workspaceReservationUtil.getDisplayName(booking);
  }
  private _getRecurrenceIcon(
    mStartTime: Date,
    mEndTime: Date,
    isAllDay: boolean,
    recurrence: IRecurrenceOptions,
    bookingType: IIndoorsEvent["type"]
  ) {
    if (!recurrence) return null;

    const tooltip = workspaceReservationUtil.formatRecurringDates(recurrence, 
      moment(mStartTime), moment(mEndTime), isAllDay);

    return bookingType === "exception"
      ? <span className={CSS.recurrenceIcon}>{Icons.noRecurrence()}</span>
      : <span className={CSS.recurrenceIcon} title={tooltip}><RecurrenceIcon size={16}/></span>
  }

  get recurrence() {
    const { booking } = this.props;
    return getRecurrencePattern(booking);
  }

  _togglePopover = (type?: IBookingCardState["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const booking = this.props.booking;
    const levels = Context.getInstance().aiim.datasets.levels;
    const isLocationChange = this.state.isLocationChange;
    const declined = Office365.wasDeclined(booking);
    const scheduleEmail = booking && booking.extensions && booking.extensions[0] && booking.extensions[0].scheduleEmail;
    const statusObj = Office365.getEventStatus(booking, scheduleEmail);
    let status = statusObj && statusObj.status;
    const statusDetails = validateUtil.getStatusIconMsg(statusObj); 
    const statusIcon = statusDetails && statusDetails.icon;
    const statusMsg = statusDetails && statusDetails.msg;
    let borderStyle = statusDetails && statusDetails.borderStyle;

    // Field values to display
    const checkInDate = booking && booking.start;
    const checkOutDate = booking && booking.end;

    const o365toDateObj = dateUtil.O365toDate(checkInDate.dateTime, checkOutDate.dateTime);
    const fromDateTime = o365toDateObj.fromDateTime;
    const toDateTime = o365toDateObj.toDateTime;
    
    const unitName = this.displayName;
    const levelId = booking && booking.extensions && booking.extensions[0].levelId;

    // Level/Facility info
    const levelData = levels.getLevelData(levelId); 
    const levelName = levelData && levelData.levelName;
    const facilityName = levelData && levelData.facilityName;
    const template = i18n.more.bookWorkspace.levelDescription;
    const description = !isLocationChange && stringFormatter(template, {
      level: levelName,
      facility: facilityName
    });

    let statusTitle;
    if (isLocationChange) statusTitle = i18n.meetingRooms.locationChange.message;
    else if (declined) statusTitle = i18n.more.bookWorkspace.declineBookingO365Msg;
    else if (status && status === "mismatchEmail") statusTitle = i18n.meetingRooms.emailMismatch.caption;

    // Format a date string from the start and end times

    const fromDate = o365toDateObj.fromDate;
    const toDate = o365toDateObj.toDate;
    const isAllDay = dateUtil.isAllDay(fromDate.getTime(), toDate.getTime());
    const formattedDate = workspaceReservationUtil.formatDate(fromDateTime, isAllDay
      ? toDateTime.subtract(1, "millisecond")
      : toDateTime, isAllDay);
    const recurrence = getRecurrencePattern(booking);
    const bookingType = booking?.type;
    const recurrenceIcon = this._getRecurrenceIcon(fromDate, toDate, isAllDay, recurrence, bookingType);
    const { popoverOpen, popoverType } = this.state;
    const isRecurring = recurrence ? true : false;

    const isRtl = Context.getInstance().uiMode.isRtl;
    const textAlign = isRtl ? "right" : "left";

    const cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("cancel") : this._cancelClicked()}
      >
        <XIcon />
        {i18n.general.cancel}
      </button>
    );

    const cancelDropdownButton = (
      <RecurrenceDropdown
        type="cancel"
        open={popoverOpen && popoverType === "cancel"}
        targetEl={cancelButton}
        onRequestClose={this._togglePopover}
        onSelection={(type) => {
          this._cancelClicked(type);
          this._togglePopover();
        }} />
    )

    const rescheduleButton = (
      <button
        className={CSS.edit}
        data-id={"edit"}
        onClick={() => isRecurring ? this._togglePopover("edit") : this._scheduleClicked(isAllDay)}
        disabled={isLocationChange ||(status && status === "mismatchEmail")}
      >
        <PencilIcon/>
        {i18n.more.bookWorkspace.checkInOut.rescheduleBooking.caption}
      </button>
    )

    const rescheduleDropdownButton = (
      <RecurrenceDropdown
        type="edit"
        open={popoverOpen && popoverType === "edit"}
        targetEl={rescheduleButton}
        onRequestClose={this._togglePopover}
        onSelection={(type) => {
          this._scheduleClicked(isAllDay, type)
          this._togglePopover();
      }} />
    )

    if (isLocationChange ||(status && status === "mismatchEmail")) borderStyle = {borderLeft: "1px #dfdfdf solid"}
    return (
      <li className={CSS.bookingCard}>
        <div className="i-book-card-container" style={borderStyle}>
          <div
            id={this._componentId}
            className={CSS.descriptionContent}
            ref={this._itemRef}
            role="button"
            tabIndex={0}
            onClick={this._bookingCardClicked}
            onKeyDown={this._onKeyDown}
          >
            <h3 className={CSS.bookingInfoLine}>
              <span style={{ flexGrow: 1 }}>
                <ClockIcon size={16}/>
                {formattedDate}
              </span>
              <span>
                {isRecurring && recurrenceIcon}
              </span>
            </h3>

            {!isLocationChange && ( 
              <>
                <h4 className={CSS.bookingInfoLine}>
                  <PinIcon />
                  {unitName}
                </h4>
                <h4 className={CSS.locationDescription} style={{paddingLeft: "1.3rem", paddingRight: "1.4rem", marginBottom: "0rem"}}>{description}</h4>
              </>
            )}

            {isLocationChange && ( 
              <Tooltip style={{textAlign: textAlign}} placement="top" positionFixed={true}
                title={statusTitle}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    this._bookingCardClicked(e,true)
                  }}
                >
                  <h4 className="i-booking-info-line i--warn-location-change">
                    <PinIcon />
                    {unitName}
                    <span><WarningIcon className="i--warn" size={16} /></span>
                  </h4>
                  <h4 className={CSS.locationDescription+" i--warn-location-change"}>{description}</h4>
                </div>
              </Tooltip>
            )}
           
            {!isLocationChange && !statusTitle && (
                <div className={CSS.bookingInfoLine}>
                  <h4>{statusIcon}</h4>
                  <h4>{statusMsg}</h4>
                </div>
              )
            }

            {!isLocationChange && statusTitle && (
              <Tooltip style={{textAlign: textAlign}} placement="top" positionFixed={true}
                title={statusTitle}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    this._statusClicked(status)
                  }}
                >
                  <div className={CSS.bookingInfoLine}>
                    <h4>{statusIcon}</h4>
                    <h4>{statusMsg}</h4>
                  </div>
                </div>
              </Tooltip>
              )
            }
          </div>
          <div className={CSS.actionGroup}>
            {isRecurring ? rescheduleDropdownButton : rescheduleButton}
            {cancelDropdownButton}
          </div>
        </div>
      </li>
    );
  }

  checkAvailability = (options: ICheckAvailabilityOptions) => {    
    const booking = this.props.booking;
    const units = Context.instance.aiim.datasets.units;
    const item = new ItemReference();
    const unit = this.props.unitFeature;

    item._fromFeature(units.getSource().key, unit);
    const o365toDateObj = dateUtil.O365toDate(booking.start.dateTime, booking.end.dateTime);
    const seriesMasterId = booking && booking.seriesMasterId;
    const eventId = booking && booking.id;

    const recurrenceType = options?.recurrenceType;

    const task: IOffice365BookingTask = {
      checkInDate: options.checkInDate,
      checkOutDate: options.checkOutDate,
      allDay: options.allDay,
      options: options,
      unit: unit,
      unitName: this.state.unitName,
      levelName: this.state.levelName,
      facilityName: this.state.facilityName,
      scheduleEmail: this.state.scheduleEmail,
      bookingSystem: this.props.bookingSystem as Office365,
      bookingType: "hotel",
      booking,
      item: item,
      operation: "updateBookingTime",
      eventId: eventId,
      renderBookingConfirmed: this.renderBookingConfirmed,
      origBooking: o365toDateObj
    }

    if (recurrenceType === "series") {
      task.seriesMasterId = seriesMasterId;
      options.recurrence.startDate = this.recurrence.startDate;
    }

    task.retry=()=> {
      this.checkAvailability(options);
    }
    return validateUtil.verifyAndBook(task);
  }
  
  _renderCancelConfirmed(booking) {
    const unitName = this.displayName;

    const node = document.createElement("div");
    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node);
      }
    };

    document.body.appendChild(node);
    ReactDOM.render(
      <BookingCancel
        bookingSystemType={getHotelBookingSystemType()}
        unitName={unitName}        
        closePopup={onClose}
        data={booking}/>, node);
  }
  
  renderBookingConfirmed(data, params, task) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={getHotelBookingSystemType()}
        unitName={task.unitName}
        levelName={task.levelName}
        facilityName={task.facilityName}
        closePopup={onClose}
        data={data}
        unit={params && params.unit}
        params={params}
      />
    ), node);
  }

  processScheduleClick = true;

  _scheduleClicked = async (isAllDay: boolean, type?: RecurrenceType) => {
    const booking = this.props.booking;
    const utc = booking.start.timeZone?.toLowerCase() === "utc";
    const toLocal = utc ? moment.utc : moment;
    // use start/end time of booking that was clicked
    const start = toLocal(booking.start.dateTime);
    const end = toLocal(booking.end.dateTime);
    const bookingDateFilter: IBookingDateFilter = {
      dateStart: start.toDate(),
      dateEnd: end.toDate()
    };
    if (type === "series") {
      const info = await workspaceReservationUtil.getReScheduleInfo(booking, "hotel", bookingDateFilter);
      const selectedWhen = info && info.when;
      if (selectedWhen && booking.masterEvent) {
        // switch to start/end time of series and check allDay for the series
        const seriesStart = toLocal(booking.masterEvent.start.dateTime);
        const seriesEnd = toLocal(booking.masterEvent.end.dateTime);
        selectedWhen.start = { date: seriesStart.toDate() };
        selectedWhen.isOngoingBooking = info.isOngoingBooking;
        selectedWhen.series = info.series;
        isAllDay = dateUtil.isAllDay(seriesStart.valueOf(), seriesEnd.valueOf());
        if (isAllDay) {
          selectedWhen.end = { date: moment(seriesEnd).subtract(1, "millisecond").toDate() };
        } else {
          selectedWhen.end = { date: seriesEnd.toDate() };
        }
        selectedWhen.duration = dateUtil.getDurationKey(selectedWhen.start.date, selectedWhen.end.date);
      }
      this._rescheduleBooking(selectedWhen, type);
    } else {
      if (!this.processScheduleClick) return;
      try {
        this.processScheduleClick = false;
        const i18n = Context.instance.i18n;
        const info = await workspaceReservationUtil.getReScheduleInfo(booking, "hotel", bookingDateFilter);
        if (!info) {
          const i18n = Context.getInstance().i18n;
          Topic.publish(Topic.RenderBookingListOffice365, {});
          Topic.publish(Topic.ShowToast,{
            message: i18n.more.bookWorkspace.checkInOut.cancelBooking.bookingNotAvailable
          });
          return;
        }
          // end date for all-day bookings (M365) is saved as 12 am the following day
          // subtract 1 second from all-day end date
        if (isAllDay) {
          info.when.end.date = moment(info.when.end.date).subtract(1, "millisecond").valueOf();
        }
        const controller = new ModalController();
        let title = i18n.meetingRooms.schedule.captionPattern;
        title = title.replace("{room}",info.item.name);
        const modalProps = {
          className: "i-modal-confirm i-modal-schedule",
          title: title,
          cancelLabel: i18n.general.close,
          showOKCancel: true,
          hideOK: true
        };
  
        const content = (
          <Schedule 
            bookingType="hotel"
            item={info.item} 
            start={info.start} 
            when={info.when} 
            series={info.series}
            originalReservation={{booking, attributes: null}}
            operation={info.operation}
            isOngoingBooking={info.isOngoingBooking}
            recurrenceType={type == null ? "single" : type}
            onShowOccupant={() => controller.close()}
            onBookClicked={selectedWhen => {
              this._rescheduleBooking({ ...selectedWhen, series: info.series}, type == null ? "single" : type);
              controller.close();
            }}/>
        )
        controller.show(modalProps,content);
      } catch(ex) {
        console.error(ex)
      } finally {
        setTimeout(()=> {
          this.processScheduleClick = true;
        },200)
      }
    }
  }

  getEpochCurrentTime =()=> {
    const currentDateTime = new Date();
    var unix_seconds = (new Date(currentDateTime)).getTime();
    return unix_seconds;
  }

  _rescheduleBooking =async (options: ISelectedWhen & { series: IIndoorsEvent[] }, recurrenceType: BookingType)=> {

    const booking = this.props.booking;
    const extension = booking && booking.extensions && booking.extensions[0];
    const areaId = extension.areaId;
    const scheduleEmail = extension.scheduleEmail;
    const recurrence = this.recurrence;

    if (recurrence && recurrenceType === "occurrence") {
      recurrence.modified = "occurrence";
    }
    const aiim = Context.instance.aiim;
    const levels = aiim.datasets && aiim.datasets.levels;
    let levelName, facilityName;
    if (levels) {
      const levelId = extension.levelId;
      const levelData = levels.getLevelData(levelId);
      levelName = levelData.levelName;
      facilityName = levelData.facilityName;
    }

    const area = await workspaceReservationUtil.getArea(areaId);
    const areaAttr = area && area.attributes;
    const areaName = getAttributeValue(areaAttr, FieldNames.AREA_NAME)
    const unitAttr = this.props.unitFeature.attributes;
    const spaceType = getAttributeValue(unitAttr, FieldNames.UNITS_USE_TYPE);
    const unitName = getAttributeValue(unitAttr, FieldNames.NAME);

    const fromDate = new Date(options.start.date);
    const toDate = new Date(options.end.date);
    const fromDateTime = moment(options.start.date);
    const endDateTime = moment(options.end.date);
    const isAllDay = options && options.duration === "allDay";

    this.setState({
      unitName: unitName,
      levelName: levelName,
      facilityName: facilityName,
      scheduleEmail: scheduleEmail
    })

    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <BookingDetails
          operation="updateBookingTime"
          isOngoingBooking={options.isOngoingBooking}
          recurrenceType={recurrenceType}
          recurrence={recurrence}
          area={area}
          areaName={areaName}
          areaId={areaId}
          spaceType={spaceType}
          scheduleEmail={scheduleEmail}
          unitName={unitName}
          levelName={levelName}
          facilityName={facilityName}
          startDate={fromDate}
          startTime={fromDateTime}
          endTime={endDateTime}
          endDate={toDate}
          allDay={isAllDay}
          checkAvailability={this.checkAvailability}
          isBookWorkspacePanel={true}
          unitFeature={this.props.unitFeature}
          originalReservation={{attributes: null, booking}}
          closePopup={onClose}
          series={options.series}
        />
      </Provider>
    ), node);
  }
}
