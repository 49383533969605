import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import Toggle from "../../common/Toggle/Toggle";
import * as component from "../../util/component";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label"
};

export default class Measure extends React.Component {

  constructor(props) {
    super(props);
    const measure = this.props.cfg.getConfigurable("measure");
    this.state = component.newState({
      enabled: measure.enabled,
    });
  }

  onChangeEnabled = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        enabled: checked
      }
    });
    cfg.getConfigurable("measure").enabled = checked;
    this.updateMeasureTool();
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const enabled = this.state.enabled;

    return (
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.measure.caption}</span>
          <HelpTip text={i18n.configurator.measure.helpTip}/>
        </div>

        <div key="enabled" className={CSS.formGroup}>
          <label id={"cfg-measure-enabled-label"} htmlFor={"cfg-measure-enabled"}
            className={"i-flex-between"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.measure.enabled}</div>
            <Toggle id={"cfg-measure-enabled"} checked={enabled}
              onChange={this.onChangeEnabled}/>
          </label>
        </div>

      </div>
    );

  }
  
  updateMeasureTool() {
    Context.getInstance().views.updateMeasure();
  }
}