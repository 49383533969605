import React from "react";

import Button from "../../../common/components/Button/index";
import Context from "../../../context/Context";
import { DropdownButton } from "../../../common/components/Dropdown";
import DeleteIcon from "calcite-ui-icons-react/XIcon";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Menu, { MenuItem } from "../../../common/components/Menu";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import PersonPopup from "../common/PersonPopup";
import { PopupPanel } from "../../styles/Common/panel";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import UnassignPrompt from "./UnassignPrompt";
import UnitsAssignTo from "./UnitsAssignTo";
import { UnitStats } from "../stats";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as transactions from "../../base/transaction/transactions";

import Skeleton from "react-loading-skeleton";

import {
  List,
  ListItem,
  ListItemTitle,
  ListItemTitleMain,
  ListItemTitleAside,
} from "../../styles/Common/list";
import {
  AssignmentsWrapper,
  AssignmentsTitle,
  AssignmentsContent,
} from "../../styles/popupStyles";

export default class UnitPopup extends React.Component {
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      peopleAssigned: null,
    });
  }

  componentDidMount() {
    this._mounted = true;
    console.log("UnitPopup.featureItem", this.getFeatureItem());

    // const attributes = this.getFeatureItem().feature.attributes;
    // const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_ID);
    // const unitName = aiimUtil.getAttributeValue(attributes,FieldNames.NAME);
    // Rdx.setValue(null,OfficePlan.Rdx_ActiveUnit,{
    //   unitId: unitId,
    //   unitName: unitName,
    //   canAssignPerson: false
    // });
    //this.setActiveUnit(false);

    const attributes = this.getFeatureItem().feature.attributes;
    const unitName = aiimUtil.getAttributeValue(attributes, FieldNames.NAME);
    Rdx.setValue(null, OfficePlan.Rdx_ActiveUnitName, unitName);

    this.queryAssignments();

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, (params) => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          this.refreshFeatureItem();
        }
      }),
      // Topic.subscribe(Topic.UpdatePlan,params => {
      //   console.log("UnitPopup.UpdatePlan",params)
      //   if (params.taskName === OfficePlan.Task_AssignPersonToUnit) {
      //     this.assignPersonToUnit(params.personObjectId)
      //   }
      // })
    ]);
  }

  componentWillUnmount() {
    //console.log("UnitPopup.componentWillUnmount",this.getFeatureItem().key)
    Rdx.setValue(null, OfficePlan.Rdx_ActiveUnit, null);
    Rdx.setValue(null, OfficePlan.Rdx_ActiveUnitName, null);
    Rdx.setValue(null, OfficePlan.Rdx_CanAssignPersonToActiveUnit, false);
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  confirmDelete = (item) => {
    const i18n = Context.instance.i18n;
    const promptOptions = {
      subject: "person",
      subjectItem: item,
      from: "unit",
      fromItem: this.getFeatureItem(),
    };
    const content = <UnassignPrompt promptOptions={promptOptions} />;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: i18n.spaceplanner.unassignPrompt.removeAssignmentTitle,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        this.deletePerson(item);
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  deletePerson = (personItem) => {
    const guard = new TransactionGuard({force: true});
    transactions
      .unassignPeople([personItem])
      .then((result) => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
      })
      .catch((error) => {
        guard.close();
        console.error("Error unassigning people", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  getFeatureItem() {
    return this.state.featureItem || this.props.featureItem;
  }

  getSource() {
    return officePlanUtil.getUnitsSource();
  }

  hasAreaId = (featureItem) => {
    if (!featureItem || !featureItem.feature) return false;
    const attributes = featureItem.feature.attributes;
    return aiimUtil.getAttributeValue(attributes, FieldNames.UNITS_AREA_ID);
  };

  hasPeopleAssigned = () => {
    return this.state.peopleAssigned && this.state.peopleAssigned.length > 0;
  };

  popupPerson = (featureItem) => {
    const source = officePlanUtil.getPeopleSource();
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_FULLNAME
    );
    Topic.publish(Topic.ShowItemPopup, {
      title: name,
      source: source,
      featureItem: featureItem,
      fetchFeatureItemGeometry: true,
      zoom: false,
      content: <PersonPopup sourceKey={source.key} featureItem={featureItem} />,
    });
  };

  queryAssignments() {
    officePlanUtil
      .queryPeopleAssignedToUnit(this.getFeatureItem())
      .then((result) => {
        if (!this._mounted) return;
        this.setState({
          peopleAssigned: result,
        });
        // const attributes = this.getFeatureItem().feature.attributes;
        // const capacity = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_CAPACITY);
        // let hasCapacity = (typeof capacity === "number" && capacity > 0);
        // if (hasCapacity && Array.isArray(result) && result.length > 0) {
        //   const numAssigned = result.length;
        //   hasCapacity = ((capacity - numAssigned) > 0);
        // }
        // this.setActiveUnit(hasCapacity);
      })
      .catch((ex) => {
        console.error("Error querying people assigned to unit", ex);
      });
  }

  refreshFeatureItem() {
    const source = this.getSource();
    const featureItem = this.getFeatureItem();
    queryUtil
      .refreshFeatureItem(source, featureItem)
      .then((result) => {
        if (result && this._mounted) {
          this.setState({ featureItem: result });
          this.queryAssignments();
        }
      })
      .catch((ex) => {
        console.error("UnitPopup: Error refreshing feature", ex);
      });
  }

  render() {
    const featureItem = this.getFeatureItem();
    const hasPeople = this.hasPeopleAssigned();
    const hasArea = this.hasAreaId(featureItem);
    const enableUnassign = hasPeople || hasArea || undefined;

    const attributes = featureItem.feature.attributes;
    const asnType = aiimUtil.getAttributeValue(attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    const notAssignable = (asnType === OfficePlan.SpaceAssignmentTypes.notAssignable);

    //const reserveForDropdown = this.renderReserveForDropdown();
    return (
      <div>
        {this.renderInfo()}
        {!notAssignable && (
          <div key="actions" className="i-margin-tb">
            <UnitsAssignTo
              enableUnassign={enableUnassign}
              featureItems={[featureItem]}
            />
          </div>
        )}
      </div>
    );
  }

  renderInfo() {
    const i18n = Context.instance.i18n;
    const featureItem = this.getFeatureItem();

    // assignment name
    let asnName = officePlanUtil.getUnitAssignmentName(featureItem);
    // TODO: hack to prevent problems with data
    if (asnName === undefined) {
      console.log("Data problem detected with item: ", featureItem);
      asnName = i18n.spaceplanner.unassigned;
    }

    // assignment type
    const asnType = officePlanUtil.getUnitAssignmentType(featureItem);

    // is unassigned
    const isUnassigned = officePlanUtil.isUnitUnassigned(featureItem);

    // assignment label
    let asnTypeLabel = "";
    if (asnType === "hotdesk") {
      asnTypeLabel = i18n.spaceplanner.assignmentType.hotdesk;
    } else if (asnType === "hotel") {
      asnTypeLabel = i18n.spaceplanner.assignmentType.hotel;
    }

    // asignments title
    const title = i18n.spaceplanner.assignments;
    let assignmentsTitle;

    // get list of assigned people
    const peopleAssigned = this.state.peopleAssigned;
    const hasPeopleAssigned = peopleAssigned && peopleAssigned.length > 0;
    if (Array.isArray(peopleAssigned) && peopleAssigned.length > 0) {
      assignmentsTitle = title.replace("{count}", peopleAssigned.length);
    } else {
      assignmentsTitle = i18n.spaceplanner.assignedTo;
    }

    return (
      <PopupPanel key="info">
        <UnitStats featureItem={featureItem} />
        <AssignmentsWrapper>
          <AssignmentsTitle>{assignmentsTitle}</AssignmentsTitle>
          <AssignmentsContent>
            {hasPeopleAssigned ? (
              this.renderAssignments(peopleAssigned)
            ) : (
              <List>
                <ListItem noMargin unassigned={isUnassigned}>
                  <ListItemTitle>
                    <ListItemTitleMain>{asnName}</ListItemTitleMain>
                    <ListItemTitleAside>{asnTypeLabel}</ListItemTitleAside>
                  </ListItemTitle>
                </ListItem>
              </List>
            )}
          </AssignmentsContent>
        </AssignmentsWrapper>
      </PopupPanel>
    );
  }

  renderReserveForDropdown() {
    const i18n = Context.instance.i18n;
    const options = [
      {
        label: i18n.spaceplanner.units.reserveFor.inboundEmployee,
        value: "reserveForInboundEmployee",
      },
      {
        label: i18n.spaceplanner.units.reserveFor.intern,
        value: "reserveForIntern",
      },
      {
        label: i18n.spaceplanner.units.reserveFor.contractor,
        value: "reserveForContractor",
      },
      {
        label: i18n.spaceplanner.units.reserveFor.requisition,
        value: "reserveForRequisition",
      },
    ];

    const menuItems = [];
    options.forEach((option) => {
      menuItems.push(
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={this.reserveForClicked}
        >
          {option.label}
        </MenuItem>
      );
    });
    const buttonProps = {
      extraSmall: true,
    };
    const buttonContent = (
      <span>{i18n.spaceplanner.units.reserveFor.label}</span>
    );
    const dropdownContent = <Menu className="i-dd-menu">{menuItems}</Menu>;

    const dropdown = (
      <DropdownButton
        closeOnSelect={true}
        focusFirst={true}
        buttonClassName={"i-margin-lr"}
        buttonContent={buttonContent}
        buttonProps={buttonProps}
        dropdownContent={dropdownContent}
        placement="bottom-start"
      ></DropdownButton>
    );
    return dropdown;
  }

  reserveForClicked = (event, menuItem, value) => {
    console.log("reserveForClicked", value);
  };

  renderAssignments = (assignments) => {
    return Array.isArray(assignments) ? (
      assignments.map((item, index) => (
        this.renderItem(item, index)
      ))
    ) : (
      <Skeleton count={2} />
    );
  };

  renderItem =(item, index)=> {

    const i18n = Context.instance.i18n;
    const field = FieldNames.PEOPLE_FULLNAME;
    let addClass=false;

    const dragStart =(evt)=> {
      addClass=true;
      const source = officePlanUtil.getPeopleSource();
      evt.dataTransfer.dropEffect = "copy";  // copy move link
      Topic.publish(Topic.DragItemStart,{
        sourceKey: source.key,
        featureItem: item
      });
    };

    const dragging=()=>{
      if(addClass){
        document.body.classList.add("i-drag-from-popup");
        addClass=false;
      }
    }

    const dragEnd=()=>{
      addClass=false;
      const source = officePlanUtil.getPeopleSource();
      Topic.publish(Topic.DragItemEnd,{
        sourceKey: source.key,
        featureItem: item
      });
      setTimeout(()=>{
        document.body.classList.remove("i-drag-from-popup");
      },1000);
    }

    return(
      <ListItem key={index} noMargin onClick={() => this.popupPerson(item)}
      draggable onDragStart={dragStart} onDrag={dragging} onDragEnd={dragEnd}
      >
      <ListItemTitle>
        <ListItemTitleMain>
          {aiimUtil.getAttributeValue(item.feature.attributes, field)}
        </ListItemTitleMain>
        <ListItemTitleAside>
          <Button
            iconButton={true}
            icon={<DeleteIcon />}
            style={{ marginLeft: "10px" }}
            title={i18n.spaceplanner.unassign}
            onClick={(evt) => {
              if (evt) evt.stopPropagation();
              this.confirmDelete(item);
            }}
          />
        </ListItemTitleAside>
        </ListItemTitle>
      </ListItem>
    );
  }

  // setActiveUnit(canAssignPerson) {
  //   const attributes = this.getFeatureItem().feature.attributes;
  //   const unitId = aiimUtil.getAttributeValue(attributes,FieldNames.UNIT_ID);
  //   const unitName = aiimUtil.getAttributeValue(attributes,FieldNames.NAME);
  //   Rdx.setValue(null,OfficePlan.Rdx_ActiveUnit,{
  //     unitId: unitId,
  //     unitName: unitName,
  //     canAssignPerson: canAssignPerson
  //   });
  // }
}
