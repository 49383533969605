import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import OfficePlan from "./OfficePlan";
import QueryAll from "./QueryAll";
import Topic from "../../context/Topic";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as officePlanUtil from "./officePlanUtil";
import * as placeholderUtil from "./placeholderUtil";
import * as queryUtil from "./queryUtil";
import * as selectionUtil from "../../aiim/util/selectionUtil";
import * as serviceUtil from "./serviceUtil";
import * as sourceUtil from "./sourceUtil";
import * as modalUtil from "../miniapps/common/components/modalUtil";
import { getValidationMessage, validateSitesAndFacilities } from "./validationUtil";
import { HomeOfficeId, HomeOfficeJSON } from "./AreasTable";
import { IApplyEditsOptions, IFeature } from "@esri/arcgis-rest-feature-layer";
import type VersionManager from "./VersionManager";
import type Source from "../../aiim/base/Source";
import { IDifferencesTask } from "./VersionManager";
import { ITransaction } from "./transaction/transaction";
import type { CustomQueryTask } from "../../context/EsriLib";

/*

  Default - UnitA has PersonA assigned
  VersionA - unassign UnitA
  VersionB - assign PersonB to UnitA
  Merge VersionA then VersionB
  What should happen to PersonA?
  Actual:
  UnitA ASSIGNMENT_TYPE='office'
  PersonA UNIT_ID=null
  PersonB UNIT_ID='UnitA'

  Default - UnitA has PersonA assigned
  VersionA - assign PersonB to UnitA
  VersionB - unassign UnitA
  Merge VersionA then VersionB
  What should happen to PersonB?
  Actual:
  UnitA ASSIGNMENT_TYPE='none'
  PersonA UNIT_ID=null
  PersonB UNIT_ID='UnitA'
  Resolution:
  We could resolve by unassigning people assigned to UnitA
  OR we could set UnitA ASSIGNMENT_TYPE='office'

  Default - UnitA is unassigned, HotelA exists
  VersionA - assign PersonA to UnitA
  VersionB - assign UnitA to HotelA
  Merge VersionA then VersionB
  What should happen to PersonA?
  Actual:
  UnitA ASSIGNMENT_TYPE='hotel', AREA_ID='HotelA'
  PersonA UNIT_ID='UnitA'
  Resolution:
  We could resolve by unassigning people assigned to UnitA
  OR we could aassign PersonA to HotelA

  Need to test
  Default - UnitA is assigned to HotelA
  VersionA - assign PersonA to UnitA
  VersionB - unassign UnitA
  Merge VersionA then VersionB
  What should happen to UnitA, PersonA?
  Actual:
  UnitA ASSIGNMENT_TYPE='none', AREA_ID=null
  PersonA UNIT_ID='UnitA'
  Resolution:
  We could resolve by unassigning people assigned to UnitA
  OR we could set UnitA ASSIGNMENT_TYPE='office'

  Need to test
  Default - HotelA exists
  VersionA - delete HotelA
  VersionB - assign UnitA to HotelA
  Merge VersionA then VersionB
  Actual:
  UnitA ASSIGNMENT_TYPE='hotel', AREA_ID='HotelA'
  AREAS table has no HotelA
  Resolution:
  Re-add HotelA

  Need to test
  Default - HotelA exists
  VersionA - assign UnitA to HotelA
  VersionB - delete HotelA
  Merge VersionA then VersionB

  .....................................................................

  Default - UnitA is unassigned, HotelA exists
  VersionA - assign UnitA to HotelA
  VersionB - assign PersonA to UnitA
  Merge VersionA then VersionB
  What should happen to UnitA, PersonA?
  Actual:
  UnitA ASSIGNMENT_TYPE='office', AREA_ID='null'
  PersonA UNIT_ID='UnitA'
  (o1w175, engineering hotel, abigail bailey)

*/

export function analyze(options: IMergePlanOptions): Promise<IMergePlanTask> {
  const promise = new Promise<IMergePlanTask>((resolve,reject) => {
    const task = makeTask(options);
    const planData = task.planData;
    console.log("mergeUtil.task",task)

    let considerUnitGeometryUpdates = false; // disable, no longer relevant for - Floor Plan Editor

    const hasPeople = !!(sourceUtil.getPeopleLayer());
    const hasAreas = !!(task.plan && task.plan.areasTable && task.plan.areasTable.table);
    const analyzeAssignments = (hasPeople && hasAreas);

    //options.applyEdits = false;
    //options.post = false;

    Promise.resolve().then(() => {
    }).then(() => {
      return checkDifferences(task,task.preReconciledData);
    }).then(() => {
      return queryAreas(task,task.preReconciledData);
    }).then(() => {
      if (options.reconcile) {
        return reconcile(task,planData,"reconcileResult").then(() => {
          task.wasReconciled = true;
        });
      }
    }).then(() => {
      if (options.replacePlaceholderEdits) {
        return replacePlaceholderEdits(task, planData, options, false);
      }
    }).then(() => {
      return queryAreas(task,planData);
    }).then(() => {
      // We need to remove duplicate areas potentially created by Save As.
      // When we add an area to a plan, then use Save As to create a new plan,
      // the new area has the same globalId as the original but a different objectId,
      // when reconciled there will be two records for that area
      if (options.reconcile) {
        //return analyzeDuplicateAreas(task, {applyEdits: true});
      }
    }).then(() => {
      if (options.reconcile && task.planData.areaDupDeletes) {
        return queryAreas(task,planData);
      }
    }).then(() => {
      if (analyzeAssignments) return analyzePeople(task,planData);
    }).then(() => {
      if (analyzeAssignments) return analyzeUnits(task,planData);
    }).then(() => {
      if (analyzeAssignments) analyzeMultiAssignments(task,planData);
    }).then(() => {
      if (considerUnitGeometryUpdates) {
        return checkUnitGeometries(task);
      }
    }).then(() => {
      if (options.applyEdits) {
        return applyEdits(task,planData);
      }
    }).then(() => {
      if (options.applyEdits) {
        return applyEdits(task,planData,{multiAssignmentDeletesOnly: true});
      }
    }).then(() => {
      if (options.applyEdits && considerUnitGeometryUpdates) {
        return applyUnitGeometryUpdates(task);
      }
    }).then(() => {
      if (options.applyEdits) {
        return fixAreaRoles(task,planData);
      }
    }).then(() => {
      if (options.reconcile && planData.editsApplied) {
        // reconcile again following data integrity edits
        return reconcile(task,planData,"reconcileResult2");
      }
    }).then(() => {
      if (options.reconcile && options.applyEdits && options.post) {
        return placeholderUtil.readPlaceholderIds(task).then(data => {
          task.planData.placeholderIds = data;
        });
      }
    }).then(() => {
      return options.reconcile ? checkFootprintGeometries(task, options.post) : true;
    }).then(continueMerge => {
      if (options.reconcile && options.applyEdits && options.post && continueMerge) {
        const opts = { plan: options.plan, partialPostRows: task.partialPostRows };
        return task.versionManager.mergePlan(opts).then(result => {
          task.wasMerged = !!(result && result.wasMerged);
        });
      }
    }).then(() => {
      if (options.reconcile || planData.editsApplied) {
        if (!options.forSaveAs) {
          let lyrs = [
            sourceUtil.getPeopleLayer(),
            sourceUtil.getUnitsLayer(),
            sourceUtil.getDetailsLayer(),
            sourceUtil.getSitesLayer(),
            sourceUtil.getFacilitiesLayer(),
            sourceUtil.getLevelsLayer()
          ]
          lyrs.forEach(lyr => {
            if (lyr && typeof lyr.refresh === "function") lyr.refresh();
          })
          if (OfficePlan.getActive().levelsLayer && Context.instance.views.floorFilter) {
            Context.instance.views.floorFilter.refreshData();
          }
          if (OfficePlan.getActive().areasTable) {
            return OfficePlan.getActive().areasTable._refresh();
          }
        }
      }
    }).then(() => {
      if (options.reconcile || planData.editsApplied) {
        if (!options.forSaveAs) {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
            wasReconciled: true
          });
        }
      }
    }).then(() => {
      if (task.verbose) console.log("mergeUtil::analyze",task)
      resolve(task);
    }).catch(ex => {
      console.error(ex);
      reject(ex);
    })
  });
  return promise;
}

export function analyzeHosted(hostedMergeTask,options) {
  const promise = new Promise<void>((resolve,reject) => {
    const task = hostedMergeTask.mergeUtilTask;
    const planData = task.planData;
    Promise.resolve().then(() => {
      return queryAreas(task,planData);
    }).then(() => {
      if (options.replacePlaceholderEdits) {
        return replacePlaceholderEdits(task, planData, options, true);
      }
    }).then(() => {
      return analyzePeople(task,planData);
    }).then(() => {
      return analyzeUnits(task,planData);
    }).then(() => {
      analyzeMultiAssignments(task,planData);
    }).then(() => {
      if (options.applyEdits) {
        return applyEdits(task,planData);
      }
    }).then(() => {
      if (options.applyEdits) {
        return applyEdits(task,planData,{multiAssignmentDeletesOnly: true});
      }
    }).then(() => {
      if (options.applyEdits) {
        return fixAreaRoles(task,planData);
      }
    }).then(() => {
      if (planData.editsApplied) {
        hostedMergeTask.editsApplied = true;
      }
    }).then(() => {
      if (task.verbose) console.log("mergeUtil::analyzeHosted",task)
      resolve();
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

export function beforeHostedMerge(hostedMergeTask,options) {
  const promise = new Promise((resolve,reject) => {
    const task = makeTask(options);
    hostedMergeTask.mergeUtilTask = task;
    Promise.resolve().then(() => {
      return queryAreas(task,task.preReconciledData);
    }).then(() => {
      if (task.verbose) console.log("mergeUtil::beforeHostedMerge",task)
      resolve(task);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

function addAreaInsert(task,section,area) {
  if (area && !section.addAreas.areasById[area.id]) {
    section.addAreas.areasById[area.id] = area;
    section.addAreas.areas.push(area);

    const table = task._areasTable || OfficePlan.getActive().areasTable.table;
    const fields = table.fields;
    const edit = {attributes: {}};
    officePlanUtil.addAttributeEdit(fields,edit.attributes,
     FieldNames.AREA_ID,area.id);
    officePlanUtil.addAttributeEdit(fields,edit.attributes,
     FieldNames.AREA_NAME,area.name);
    officePlanUtil.addAttributeEdit(fields,edit.attributes,
     FieldNames.AREA_TYPE,area.type);
    section.areaInserts.push(edit);
  }
}

function addUnassignPeopleEdits(task,section,peopleObjectIds) {
  if (Array.isArray(peopleObjectIds) && peopleObjectIds.length > 0) {
    const source = task._peopleSource || sourceUtil.getPeopleSource();
    const fields = source.layer2D.fields;
    const objectIdField = source.layer2D.objectIdField;
    const secondaryTask = {};
    const multiPropsByOid = section.multiPropsByOid;
    peopleObjectIds.forEach(objectId => {
      const update = {attributes: {}};
      update.attributes[objectIdField] = objectId;
      officePlanUtil.appendPersonUnassignment(secondaryTask,fields,update);
      section.peopleUpdates.push(update);
      const multiProps = multiPropsByOid[objectId];
      if (multiProps) {
        multiProps.unitId = null;
        multiProps.areaId = null;
      }
    });
  }
}

function analyzeDuplicateAreas(task, options) {
  const find = (areas,areaId) => {
    const a = [];
    areas.forEach(area => {
      if (area.id === areaId) a.push(area);
    });
    return a;
  };

  const deletes = [];
  const preReconciledAreas = task.preReconciledData.areas;
  const reconciledAreas = task.planData.areas;
  if (preReconciledAreas && reconciledAreas) {
    const idx = {}, dupids = [];
    reconciledAreas.forEach(area => {
      if (idx[area.id]) {
        if (dupids.indexOf(area.id) === -1) dupids.push(area.id);
      } else {
        idx[area.id] = 1;
      }
    });
    dupids.forEach(areaId => {
      let keep;
      let pre = find(preReconciledAreas,areaId);
      let rec = find(reconciledAreas,areaId);
      if (pre.length > 0) {
        let oid = pre[0].objectId;
        rec.some(area => {
          if (oid === area.objectId) keep = area;
          return !!keep;
        });
      }
      if (!keep && rec.length > 0) keep = rec[0];
      if (keep) {
        rec.forEach(area => {
          if (area !== keep) {
            deletes.push(area.objectId);
          }
        });
      }
    });
  }
  if (deletes.length > 0) {
    task.planData.areaDupDeletes = deletes;
    if (options && options.applyEdits) {
      return applyEdits(task,task.planData,{areaDupDeletesOnly: true});
    }
  }
  return Promise.resolve();
}

function analyzeMultiAssignments(task: IMergePlanTask, section: IMergePlanData) {
  const multiPropsByKey = section.multiPropsByKey;
  const multiKeys = section.multiKeys;
  const multiOidsToDelete = section.multiOidsToDelete;
  if (multiKeys && multiKeys.length > 0) {
    multiKeys.forEach(k => {
      //console.log("multiKey....",k)
      let list = multiPropsByKey[k];
      if (list && list.length > 1) {
        //console.log("...multiple assignments....",k);
        let rm = [], hasAsn = false;
        list.forEach(multiProps => {
          const areaId = multiProps.areaId;
          const unitId = multiProps.unitId;
          const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
          const hasUnitId = (typeof unitId === "string" && unitId.length > 0);
          if (hasAreaId || hasUnitId) {
            hasAsn = true;
          } else {
            rm.push(multiProps);
          }
        });
        if (hasAsn && rm.length > 0) {
          rm.forEach(multiProps => {
            console.log("Multiple assignment - deleting unused record:",multiProps.objectId,k);
            multiOidsToDelete.push(multiProps.objectId)
          });
        }
      }
    });
  }
  //console.log("multiOidsToDelete",multiOidsToDelete);
}

function analyzePeople(task: IMergePlanTask, section: IMergePlanData) {
  const source = task._peopleSource || sourceUtil.getPeopleSource();
  const processor = processPeople;
  return processLayer(task,section,source,processor)
}

function analyzeUnits(task: IMergePlanTask, section: IMergePlanData) {
  const source = task._unitsSource || sourceUtil.getUnitsSource();
  const processor = processUnits;
  return processLayer(task,section,source,processor).then(() => {
    const verbose = task.verbose;
    const peopleUnitIdIndex = section.peopleUnitIdIndex;
    const officeUnitIdIndex = section.officeUnitIdIndex;
    const unassignedPeopleUnitIdIndex = section.unassignedPeopleUnitIdIndex;
    Object.keys(peopleUnitIdIndex).forEach(unitId => {
      if (!officeUnitIdIndex.hasOwnProperty(unitId)) {
        // People were assigned to a unit that is not an office,
        // check to see if they are not targeted for unassignment
        if (!unassignedPeopleUnitIdIndex.hasOwnProperty(unitId)) {
          /* ********************************************************
             possible version conflict
             unassign these people
             Don't have a use case that actually causes this
             ******************************************************** */
          // ******************************
          if (verbose) {
            console.log("Need to remove people assignments for unitId",unitId,"(not asnType=office)");
          }
          addUnassignPeopleEdits(task,section,peopleUnitIdIndex[unitId]);
        }
      }
    })
  });
}

function applyEdits(task: IMergePlanTask, section: IMergePlanData, opts?: {
  areaDupDeletesOnly?: boolean,
  areaRoleDeletesOnly?: {
    tableId: number,
    areaRoleDelKeys: number[]
  },
  multiAssignmentDeletesOnly?: boolean,
  replacePlaceholderEdits?: ITransaction["edits"],
  replacePlaceholdersOnly?: boolean,
  unitGeometryUpdatesOnly?: boolean,
  updates?: IFeature[],
  useGlobalIds?: boolean
}) {
  const promise = new Promise<void>((resolve,reject) => {
    let edits = [];
    const aTable = task._areasTable || (OfficePlan.getActive().areasTable && OfficePlan.getActive().areasTable.table);
    const peopleSource = task._peopleSource || sourceUtil.getPeopleSource();
    const unitsSource = task._unitsSource || sourceUtil.getUnitsSource();
    const areaDupDeletesOnly = opts && opts.areaDupDeletesOnly;
    const multiAssignmentDeletesOnly = opts && opts.multiAssignmentDeletesOnly;
    const areaRoleDeletesOnly = opts && opts.areaRoleDeletesOnly;

    if (multiAssignmentDeletesOnly) {
      if (section.multiOidsToDelete && section.multiOidsToDelete.length > 0) {
        edits.push({
          id: peopleSource.layer2D.layerId,
          deletes: section.multiOidsToDelete
        })
      }
    } else if (areaRoleDeletesOnly) {
      edits.push({
        id: areaRoleDeletesOnly.tableId,
        deletes: areaRoleDeletesOnly.areaRoleDelKeys
      })
    } else if (areaDupDeletesOnly) {
      if (section.areaDupDeletes && section.areaDupDeletes.length > 0) {
        edits.push({
          id: aTable.layerId,
          deletes: section.areaDupDeletes
        })
      }
    } else if (opts && opts.unitGeometryUpdatesOnly) {
      edits.push({
        id: unitsSource.layer2D.layerId,
        updates: opts.updates
      })
      //console.log("applyEdits::unitGeometryUpdatesOnly",edits)
    } else if (opts && opts.replacePlaceholdersOnly) {
      edits = opts.replacePlaceholderEdits
    } else {
      if (section.areaInserts.length > 0) {
        edits.push({
          id: aTable.layerId,
          adds: section.areaInserts
        })
      }
      if (section.peopleUpdates.length > 0) {
        edits.push({
          id: peopleSource.layer2D.layerId,
          updates: section.peopleUpdates
        })
      }
      if (section.unitUpdates.length > 0) {
        edits.push({
          id: unitsSource.layer2D.layerId,
          updates: section.unitUpdates
        })
      }
    }

    if (edits.length === 0) {
      resolve();
      return;
    }

    const serviceUrl = unitsSource.layer2D.url;
    const gdbVersion = unitsSource.layer2D.gdbVersion;
    const timeout = task.timeout || (60000 * 30);
    let useGlobalIds = false;
    if (opts && opts.useGlobalIds) useGlobalIds = opts.useGlobalIds;
    let url = serviceUrl+"/applyEdits";
    //url = aiimUtil.appendTokenToUrl(url);
    const params: IApplyEditsOptions = {
      useGlobalIds: useGlobalIds,
      rollbackOnFailure: true,
      // @ts-ignore
      edits: JSON.stringify(edits),
      f: "json"
    };   
    if (gdbVersion) params.gdbVersion = gdbVersion;
    const options: __esri.RequestOptions = {
      query: params,
      method: "post",
      responseType: "json",
      timeout: timeout
    };

    if (task.verbose) console.log("mergeUtil::applyEdits.params",params);
    if (task.verbose) console.log("mergeUtil::applyEdits",edits);
    const esriRequest = Context.instance.lib.esri.esriRequest;
    esriRequest(url,options).then(result => {
      if (task.verbose) console.log("mergeUtil::applyEdits.result",result);
      serviceUtil.validateApplyEditsResponse(result);
      section.editsApplied = true;
    }).then(() => {
      resolve();
    }).catch(ex => {
      console.error("Error applying edits (merge)",ex);
      reject(ex);
    });
  });
  return promise;
}

function applyUnitGeometryUpdates(task: IMergePlanTask) {
  const promise = new Promise<void>((resolve,reject) => {
    const chunks = [];

    // const applyEditsTmp = (chunkIdx) => {
    //   return Promise.resolve();
    // };

    const process = (chunkIdx) => {
      const isLast = (chunkIdx >= (chunks.length - 1));
      const chunk = chunks[chunkIdx];
      const opts = {
        unitGeometryUpdatesOnly: true,
        updates: chunk,
      }
      //console.log("applying chunk",opts)
      applyEdits(task,task.planData,opts).then(() => {
        if (isLast) {
          resolve();
        } else {
          process(chunkIdx + 1)
        }
      }).catch(ex => {
        reject(ex);
      })
    }

    const updates = task.unitGeometryUpdates;
    if (updates && updates.length > 0) {
      const chunkLen = 100; // TODO ? , update 100 features per request
      for (let i=0,j=updates.length; i<j; i+=chunkLen) {
        let a = updates.slice(i,i+chunkLen);
        if (a.length > 0) {
          chunks.push(a);
        }
      }
    }
    if (chunks.length > 0) {
      console.log("reconcile plan: Unit geometry updates to pull from default:",updates.length);
      process(0);
    } else {
      console.log("reconcile plan: No unit geometry updates to pull from default");
      resolve();
    }
  });
  return promise;
}

function checkDifferences(task: IMergePlanTask, section: IPlanData & {
  differences?: IDifferencesTask["differences"],
  hasDifferences?: IDifferencesTask["hasDifferences"]
}) {
  const options = {plan: task.plan};
  return task.versionManager.hasDifferences(options).then(result => {
    section.hasDifferences = result && result.hasDifferences;
    section.differences = result && result.differences;

    const unitsSource = task._unitsSource || sourceUtil.getUnitsSource();
    const unitsLayer = unitsSource.layer2D;
    if (section.differences) {
      section.differences.some(d => {
        if (d.layerId === unitsLayer.layerId) {
          if (d.updates && d.updates.length > 0) {
            d.updates.forEach(oid => {
              if (task.updatedUnitOIDs.indexOf(oid) === -1) {
                task.updatedUnitOIDs.push(oid);
              }
            })
          }
          return true;
        }
        return false;
      })
    }

  });
}

async function checkFootprintGeometries(task: IMergePlanTask, isPost: boolean): Promise<boolean> {
  const ctx = Context.getInstance();
  if (ctx.isFPE() && sourceUtil.getFacilitiesLayer() != null) {
    const { i18n, views: { floorFilter } } = ctx;
    return floorFilter.refreshData()
      .then(validateSitesAndFacilities)
      .then((validity) => {
        if (Object.values(validity).every(v => !!v)) {
          return true;
        } else {
          task.hasConflicts = true;
          const message = getValidationMessage(isPost, validity);
          const options = {
            title: i18n.editor.footprints.conflict,
            message,
            hideCancel: !isPost,
            okLabel: isPost ? i18n.general._continue : i18n.general.ok,
            okTooltip: isPost ? i18n.editor.footprints.continueMerge : i18n.general.ok,
            cancelLabel: i18n.general.cancel,
            cancelTooltip: i18n.editor.footprints.cancelMerge,
            escapeDisabled: true,
            closeButtonDisabled: false,
            outsideCloseDisabled: true
          };
          // yes/ok result means to continue with push
          return modalUtil.confirm(options);
        }
      });
  }
  return true;
}

function checkUnitGeometries(task: IMergePlanTask) {
  const promise = new Promise<void>((resolve,reject) => {
    const oids = task.updatedUnitOIDs;
    if (oids && oids.length > 0) {
      const source = task._unitsSource || sourceUtil.getUnitsSource();
      const objectIdField = source.layer2D.objectIdField;
      const unitIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNIT_ID);
      const url = Context.checkMixedContent(source.url);

      const query = new Context.instance.lib.esri.Query();
      query.returnGeometry = true;
      query.returnZ = true;
      query.outFields = [objectIdField,unitIdField.name];
      queryUtil.applyGdbVersion(source,query);

      const queryDefault = new Context.instance.lib.esri.Query();
      queryDefault.returnGeometry = true;
      queryDefault.returnZ = true;
      queryDefault.outFields = [objectIdField,unitIdField.name];

      const planFeaturesByUnitId = {};
      const unitIds = [], unitIdValues = [];
      const qaopts = {
        objectIds: oids,
        layer: source.layer2D,
        perFeatureCallback: f => {
          const unitId = f.attributes[unitIdField.name]
          if (unitId && unitIds.indexOf(unitId) === -1) {
            planFeaturesByUnitId[unitId] = f;
            unitIds.push(unitId);
            const v = "'" + selectionUtil.escSqlQuote(unitId) + "'";
            unitIdValues.push(v);
          }
        }
      };

      const unitUpdates = [], unitIdsDefault = [], unitIdsToPull = [];
      const geometryEngine = Context.instance.lib.esri.geometryEngine;
      const qaoptsDefault = {
        layer: source.layer2D,
        perFeatureCallback: f => {
          const unitId = f.attributes[unitIdField.name];
          if (unitId && unitIdsDefault.indexOf(unitId) === -1) {
            unitIdsDefault.push(unitId);
            const planFeature = planFeaturesByUnitId[unitId];
            if (planFeature) {
              const geomDefault = f.geometry;
              const geomPlan = planFeature.geometry;
              if (geomDefault && geomPlan) {
                let same = geometryEngine.equals(geomDefault,geomPlan);
                //console.log(unitId,"same=",same)
                //same = false; // TODO temporary
                if (!same) {
                  const update: IFeature = {attributes: {}};
                  update.attributes[objectIdField] = planFeature.attributes[objectIdField];
                  update.geometry = geomDefault.toJSON();
                  unitUpdates.push(update);
                  unitIdsToPull.push(unitId);
                }
              }
            }
          }
        }
      };

      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        if (unitIdValues.length > 0) {
          const wh = unitIdField.name + " IN (" + unitIdValues.join(",") + ")";
          queryDefault.where = wh;
          const qa2 = new QueryAll();
          return qa2.execute(url,queryDefault,qaoptsDefault)
        }
      }).then(() => {
        if (unitUpdates && unitUpdates.length > 0) {
          console.log("checkUnitGeometries::unitIdsToPullIntoPlan",unitIdsToPull);
          task.unitGeometryUpdates = unitUpdates;
        }
        resolve();
      }).catch(ex => {
        console.error("Error checking unit geometries",ex);
        resolve();
      });
    } else {
      resolve();
    }
  });
  return promise;
}

function fixAreaRoles(task: IMergePlanTask, planData: IMergePlanData) {
  if (!OfficePlan.getActive().areasTable || !OfficePlan.getActive().areasTable.table) return Promise.resolve();
  const hasAreaRoles = OfficePlan.getActive().hasAreaRoles();
  if (!hasAreaRoles) return Promise.resolve();

  const promise = new Promise<void>((resolve,reject) => {
    const lib = Context.instance.lib;
    const atable = task._areasTable || OfficePlan.getActive().areasTable.table;
    const artable = task._areaRolesTable || OfficePlan.getActive().areaRolesTable.table;
    const areaIdIndex = {};
    const areaRoleDelIndex = {};
    const areaRoleDelKeys = [];
    const roleAreaEmailIndex = {};

    const aurl = Context.checkMixedContent(atable.url+"/"+atable.layerId);
    const aquery = new lib.esri.Query();
    aquery.outFields = ["*"];
    aquery.where = "1=1";
    if (atable.gdbVersion) aquery.gdbVersion = atable.gdbVersion;
    const aidFld = aiimUtil.findField(atable.fields,FieldNames.AREA_ID);
    const aqopts = {
      layer: atable,
      perFeatureCallback: f => {
        const areaId = f.attributes[aidFld.name];
        if (typeof areaId === "string" && areaId.length > 0) {
          areaIdIndex[areaId] = true;
        }
      }
    }
    const aqa = new QueryAll();

    const arurl = Context.checkMixedContent(artable.url+"/"+artable.layerId);
    const arquery = new lib.esri.Query();
    arquery.outFields = ["*"];
    arquery.where = "1=1";
    if (artable.gdbVersion) arquery.gdbVersion = artable.gdbVersion;
    const resmgrRole = 1;
    //const arkeyField = artable.globalIdField;
    const arkeyField = artable.objectIdField;
    const aridFld = aiimUtil.findField(artable.fields,FieldNames.AREA_ROLES_AREA_ID);
    const roleFld = aiimUtil.findField(artable.fields,FieldNames.AREA_ROLES_ROLE);
    const emailFld = aiimUtil.findField(artable.fields,FieldNames.AREA_ROLES_EMAIL);
    const arqopts = {
      layer: artable,
      perFeatureCallback: f => {
        const key = f.attributes[arkeyField]
        const areaId = f.attributes[aridFld.name];
        const role = f.attributes[roleFld.name];
        const email = f.attributes[emailFld.name];
        const isResmgrRole = (role === resmgrRole);
        if (!areaIdIndex.hasOwnProperty(areaId)) {
          if (isResmgrRole) {
            // this area no longer exists
            if (!areaRoleDelIndex.hasOwnProperty(key)) {
              areaRoleDelIndex[key] = true;
              areaRoleDelKeys.push(key);
            }
          }
        } else if (typeof areaId === "string" && areaId.length > 0) {
          if (isResmgrRole) {
            if (typeof email === "string" && email.length > 0) {
              let k = "_"+role+"_"+areaId+"_"+email;
              if (roleAreaEmailIndex.hasOwnProperty(k)) {
                // this is a duplicate
                if (!areaRoleDelIndex.hasOwnProperty(key)) {
                  areaRoleDelIndex[key] = true;
                  areaRoleDelKeys.push(key);
                }
              } else {
                roleAreaEmailIndex[k] = true;
              }
            }
          }
        }
      }
    }
    const arqa = new QueryAll();

    aqa.execute(aurl,aquery,aqopts).then(() => {
      return arqa.execute(arurl,arquery,arqopts)
    }).then(() => {
      if (areaRoleDelKeys.length > 0) {
        const opts = {
          areaRoleDeletesOnly: {
            tableId: artable.layerId,
            areaRoleDelKeys: areaRoleDelKeys
          }
        }
        return applyEdits(task,planData,opts);
      }
    }).then(() => {
      resolve();
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}
export interface IMergePlanTask extends IMergePlanCommonProps, IMergePlanPrivateProps {
  hasConflicts: boolean,
  partialPostRows?: IPartialPostRow[],
  verbose: boolean,
  wasReconciled: boolean,
  wasMerged: boolean,
  preReconciledData: IPlanData & { isPreReconciled: boolean },
  planData: IMergePlanData,
  timeout?: number
  updatedUnitOIDs: number[],
  unitGeometryUpdates: IFeature[] // relavant prior to Floor Plan Editor only  
}
export interface IMergePlanCommonProps {
  plan: OfficePlan,
  versionManager: VersionManager  
}
export interface IMergePlanPrivateProps {
  _areasTable: __esri.FeatureLayer,
  _areaRolesTable: __esri.FeatureLayer,
  _peopleSource: Source,
  _unitsSource: Source
}
export interface IMergePlanOptions extends IMergePlanCommonProps, Partial<IMergePlanPrivateProps> {
  applyEdits: boolean,
  forSaveAs?: boolean,
  post: boolean,
  reconcile: boolean,
  replacePlaceholderEdits?: boolean
}
export interface IAreaData {
  areas: (IFeature | {
    id: string,
    type: string,
    name: string,
    objectId: number,
    globalId: number
  })[],
  areasById: Record<string, __esri.Graphic>
}
export interface IPlanData extends IAreaData {
  isDefault?: boolean
}
export interface IMergePlanData extends IPlanData {
  addAreas: IAreaData,
  peopleUnitIdIndex: Record<string, number[]>,
  officeUnitIdIndex: Record<string, string>,
  unassignedPeopleUnitIdIndex: Record<string, string>,
  areaDupDeletes: number[],
  areaInserts: IFeature[],
  peopleUpdates: IFeature[],
  unitUpdates: IFeature[],
  editsApplied: boolean,
  reconcileResult: {
    guid: string,
    sessionID: string,
    triedStopEditing: boolean,
    triedStopReading: boolean,
    wasMerged: boolean
  },
  multiKeys: string[],
  multiPropsByOid: Record<number, IMultiProps>,
  multiPropsByKey: Record<string, IMultiProps[]>,
  multiOidsToDelete: number[],
  placeholderIds: {
    globalIdIndex: Record<string, boolean>,
    objectIdIndex: Record<number, boolean>,
    unitIdIndex: Record<string, boolean>,
    knownas: Record<string, boolean>,
    partialPostRows?: IPartialPostRow[]
  }
}
export interface IMultiProps {
  multiKey: string,
  objectId: number,
  areaId: string,
  unitId: string
}
export interface IPartialPostRow {
  layerId: string,
  objectIds: number[]
}
type Processor = (task: IMergePlanTask, section: IMergePlanData, start: number, num: number) => Promise<__esri.FeatureSet>;

export function makeTask(options: IMergePlanOptions) {
  const plan: OfficePlan = (options && options.plan) || OfficePlan.getActive();
  const task: IMergePlanTask = {
    hasConflicts: false,
    verbose: true,
    wasReconciled: false,
    wasMerged: false,
    plan: plan,
    versionManager: options && options.versionManager,
    preReconciledData: {
      isPreReconciled: true,
      areas: [{ ...HomeOfficeJSON }],
      areasById: {
        [HomeOfficeId]: plan.areasTable && plan.areasTable.HomeOffice
      },
    },
    planData: {
      areas: [{ ...HomeOfficeJSON }],
      areasById: {
        [HomeOfficeId]: plan.areasTable && plan.areasTable.HomeOffice
      },
      addAreas: {
        areas: [],
        areasById: {}
      },
      peopleUnitIdIndex: {},
      officeUnitIdIndex: {},
      unassignedPeopleUnitIdIndex: {},
      areaDupDeletes: null,
      areaInserts: [],
      peopleUpdates: [],
      unitUpdates: [],
      editsApplied: false,
      reconcileResult: null,
      multiKeys: [],
      multiPropsByOid: {},
      multiPropsByKey: {},
      multiOidsToDelete: [],
      placeholderIds: null
    },
    updatedUnitOIDs: [],
    unitGeometryUpdates: null, // relavant prior to Floor Plan Editor only
    _areasTable: options && options._areasTable,
    _areaRolesTable: options && options._areaRolesTable,
    _peopleSource: options && options._peopleSource,
    _unitsSource: options && options._unitsSource
  };
  return task;
}

function processLayer(task: IMergePlanTask, section: IMergePlanData, source: Source, processor: Processor) {
  const promise = new Promise<void>((resolve,reject) => {
    const num = source.layer2D.sourceJSON.maxRecordCount;
    const process = start => {
      processor(task,section,start,num).then(result => {
        let exceededTransferLimit = result && result.exceededTransferLimit;
        if (exceededTransferLimit) {
          process(start + num);
        } else {
          resolve();
        }
      }).catch(ex => {
        reject(ex);
      })
    };
    process(0);
  });
  return promise;
}

function processPeople(task: IMergePlanTask, section: IMergePlanData, start: number, num: number) {

  const verbose = task.verbose;
  const log = (objectId,msg,reason,unitId,newUnitId,areaId,newAreaId) => {
    if (!verbose) return;
    if (newUnitId === undefined) newUnitId= "n/a";
    if (newAreaId === undefined) newAreaId= "n/a";
    console.log(
      "Person",objectId,msg+",",reason,
      "unitId",unitId,"newUnitId",newUnitId,
      "areaId",areaId,"newAreaId",newAreaId
    );
  };

  const promise = new Promise<__esri.FeatureSet>((resolve,reject) => {
    const source = task._peopleSource || sourceUtil.getPeopleSource();
    const objectIdField = source.layer2D.objectIdField;
    const areaIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.PEOPLE_AREA_ID);
    const unitIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.PEOPLE_UNIT_ID);

    const nameField = aiimUtil.findField(source.layer2D.fields,FieldNames.PEOPLE_FULLNAME);
    const keyField = aiimUtil.findField(source.layer2D.fields,FieldNames.PEOPLE_EMAIL);
    const multiPropsByOid = section.multiPropsByOid;
    const multiPropsByKey = section.multiPropsByKey;
    const multiKeys = section.multiKeys;

    const where = selectionUtil.getBaseDefinitionExpression(source.layer2D);
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(source.url);
    const queryTask: CustomQueryTask = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.returnGeometry = false;
    query.returnZ = false;
    query.outFields = [objectIdField,areaIdField.name,unitIdField.name];
    if (nameField && keyField) {
      query.outFields.push(nameField.name);
      query.outFields.push(keyField.name);
    }
    query.where = where || "1=1";
    if (!section.isDefault) {
      queryUtil.applyGdbVersion(source,query);
    }
    query.start = start;
    query.num = num;
    //console.log("query",query)
    queryTask.execute(query).then(result => {
      //console.log("processPeople",url,result);
      const features = result && result.features;
      if (features) {
        const areaIdFieldName = areaIdField.name;
        const unitIdFieldName = unitIdField.name;
        const peopleUnitIdIndex = section.peopleUnitIdIndex;
        features.forEach(feature => {
          const attributes = feature && feature.attributes;
          const objectId = aiimUtil.getAttributeValue(attributes,objectIdField);
          const areaId = aiimUtil.getAttributeValue(attributes,areaIdFieldName);
          const unitId = aiimUtil.getAttributeValue(attributes,unitIdFieldName);
          const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
          const hasUnitId = (typeof unitId === "string" && unitId.length > 0);

          let addArea, newAreaId, newUnitId, reason = "";

          let multiProps = null;
          if (nameField && keyField && multiPropsByOid) {
            const name = aiimUtil.getAttributeValue(attributes,nameField.name);
            const key = aiimUtil.getAttributeValue(attributes,keyField.name);
            if (name && key) {
              const multiKey = name+"__"+key;
              multiProps = {
                multiKey: multiKey,
                objectId: objectId,
                areaId: areaId,
                unitId: unitId,
              };
              multiPropsByOid[objectId] = multiProps;
              if (multiPropsByKey.hasOwnProperty(multiKey)) {
                multiPropsByKey[multiKey].push(multiProps);
              } else {
                multiPropsByKey[multiKey] = [multiProps];
                multiKeys.push(multiKey);
              }
            }
          }

          if (hasAreaId) {
            let area = section.areasById[areaId];
            if (!area) {
              /* ********************************************************
                 possible version conflict
                 we'll try to re-add the area

                 Default - HotelA exists
                 VersionA - delete HotelA
                 VersionB - assign PersonA to HotelA
                 Merge VersionA then VersionB

                 Inconsistent state:
                 PersonA AREA_ID='HotelA'
                 AREAS table has no HotelA
                 ******************************************************** */
              area = task.preReconciledData.areasById[areaId];
              if (area) {
                addArea = area;
              } else {
                // if we can't find the area set it to null
                reason = "Missing area";
                newAreaId = null;
              }
            }
            if (area && unitId !== null) {
              // this is a row inconsistency and shouldn't happen
              // should the point geometry be reset?
              reason = "Row inconsistency, both unit_id and area_id are set";
              newUnitId = null;
            }
          }

          if (hasUnitId && newUnitId === undefined) {
            let oids = peopleUnitIdIndex[unitId];
            if (!oids) {
              peopleUnitIdIndex[unitId] = [objectId];
            } else {
              oids.push(objectId);
            }
          }

          let hasUpdate = false;
          const update: IFeature = {attributes: {}};
          update.attributes[objectIdField] = objectId;
          if (areaId === HomeOfficeId) {
            update.attributes[areaIdFieldName] = areaId;
            hasUpdate = true;
            if (multiProps) multiProps.areaId = areaId;
          } else if (newAreaId !== undefined) {
            log(objectId,"need to unset areaId",reason,unitId,newUnitId,areaId,newAreaId);
            update.attributes[areaIdFieldName] = newAreaId;
            hasUpdate = true;
            if (multiProps) multiProps.areaId = newAreaId;
          }
          if (newUnitId !== undefined) {
            log(objectId,"need to unset unitId",reason,unitId,newUnitId,areaId,newAreaId);
            update.attributes[unitIdFieldName] = newUnitId;
            hasUpdate = true;
            if (multiProps) multiProps.unitId = newUnitId;
          }
          if (hasUpdate) {
            section.peopleUpdates.push(update);
          }

          if (addArea) {
            if (!section.addAreas.areasById[addArea.id]) {
              if (verbose) console.log("People",objectId,"need to re-add area",addArea.name);
              addAreaInsert(task,section,addArea);
            }
          }

        });
      }
      resolve(result);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

function processUnits(task: IMergePlanTask, section: IMergePlanData, start: number, num: number) {

  const verbose = task.verbose;
  const log = (unitId,msg,reason,asnType,newAsnType,areaId,newAreaId) => {
    if (!verbose) return;
    if (newAsnType === undefined) newAsnType= "n/a";
    if (newAreaId === undefined) newAreaId= "n/a";
    console.log(
      "Unit",unitId,msg+",",reason,
      "asnType",asnType,"newAsnType",newAsnType,
      "areaId",areaId,"newAreaId",newAreaId
    );
  };
  // const log2 = (unitId,msg,reason,utilization,newUtilization) => {
  //   if (!verbose) return;
  //   console.log(
  //     "Unit",unitId,msg+",",reason,
  //     "utilization",utilization,"newUtilization",newUtilization
  //   );
  // };

  const promise = new Promise<__esri.FeatureSet>((resolve,reject) => {
    const source = task._unitsSource || sourceUtil.getUnitsSource();
    const objectIdField = source.layer2D.objectIdField;
    const areaIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNITS_AREA_ID);
    const unitIdField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNIT_ID);
    const asnTypeField = aiimUtil.findField(source.layer2D.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    //const utilField = aiimUtil.findField(source.layer2D.fields,FieldNames.UTILIZATION);
    const where = selectionUtil.getBaseDefinitionExpression(source.layer2D);
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(source.url);
    const queryTask = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.returnGeometry = false;
    query.returnZ = false;
    //query.outFields = [objectIdField,areaIdField.name,unitIdField.name,asnTypeField.name,utilField.name];
    query.outFields = [objectIdField,areaIdField.name,unitIdField.name,asnTypeField.name];
    query.where = where || "1=1";
    if (!section.isDefault) {
      queryUtil.applyGdbVersion(source,query);
    }
    query.start = start;
    query.num = num;
    //console.log("query",query)
    queryTask.execute(query).then(result => {
      //console.log("processUnits",result);
      const features = result && result.features;
      if (features) {
        const areaIdFieldName = areaIdField.name;
        const unitIdFieldName = unitIdField.name;
        const asnTypeFieldName = asnTypeField.name;
        //const utilFieldName = utilField.name;
        const peopleUnitIdIndex = section.peopleUnitIdIndex;
        const officeUnitIdIndex = section.officeUnitIdIndex;
        const unassignedPeopleUnitIdIndex = section.unassignedPeopleUnitIdIndex;

        const asnTypes = {
          "office" : "office",
          "hotdesk" : "hotdesk",
          "hotel" : "hotel",
          "meeting room" : "meeting room",
          "none" : "none",
          "not assignable" : "not assignable"
        };

        features.forEach(feature => {
          const attributes = feature && feature.attributes;
          const objectId = aiimUtil.getAttributeValue(attributes,objectIdField);
          const areaId = aiimUtil.getAttributeValue(attributes,areaIdFieldName);
          const unitId = aiimUtil.getAttributeValue(attributes,unitIdFieldName);
          //const utilization = aiimUtil.getAttributeValue(attributes,utilFieldName);
          let asnType = aiimUtil.getAttributeValue(attributes,asnTypeFieldName);
          if (!asnTypes.hasOwnProperty(asnType)) asnType = "empty";

          let newAsnType, newAreaId, addArea, unassignPeople = false;
          //let newUtilization;
          let reason = "";

          const peopleOidsAssigned = peopleUnitIdIndex[unitId];
          const hasAreaId = (typeof areaId === "string" && areaId.length > 0);
          const hasPeopleAssigned = !!(peopleOidsAssigned);

          if ((asnType === "hotdesk" || asnType === "hotel" || asnType === "meeting room") && hasAreaId) {
            let area = section.areasById[areaId];
            if (area) {
              // area is ok
              if (hasPeopleAssigned) {
                /* ********************************************************
                   possible version conflict
                   we could unassign the people referencing this unit
                   or set the ASSIGNMENT_TYPE to 'office' and AREA_TD to null

                   Default - UnitA is unassigned, HotelA exists
                   VersionA - assign PersonA to UnitA
                   VersionB - assign UnitA to HotelA
                   Merge VersionA then VersionB

                   Inconsistent state:
                   UnitA ASSIGNMENT_TYPE='hotel', AREA_ID='HotelA'
                   PersonA UNIT_ID='UnitA'

                   Resolve by unassigning PersonA
                   ******************************************************** */
                //console.log("======================",attributes)
                unassignPeople = true;
                // vs newAsnType = "office"; newAreaId = null;
              }
            } else {
              /* ********************************************************
                 possible version conflict
                 we'll try to re-add the area

                 Default - HotelA exists
                 VersionA - delete HotelA
                 VersionB - assign UnitA to HotelA
                 Merge VersionA then VersionB

                 Inconsistent state:
                 UnitA ASSIGNMENT_TYPE='hotel', AREA_ID='HotelA'
                 AREAS table has no HotelA
                 ******************************************************** */
              area = task.preReconciledData.areasById[areaId];
              if (area) {
                addArea = area;
                if (hasPeopleAssigned) {
                  unassignPeople = true;
                }
              } else {
                reason = "Missing area";
                newAreaId = null;
                if (hasPeopleAssigned) {
                  newAsnType = "office";
                } else {
                  newAsnType = "none";
                }
              }
            }
          }

          if ((asnType === "hotdesk" || asnType === "hotel" || asnType === "meeting room") && !hasAreaId) {
            // this is a row inconsistency and shouldn't happen
            if (areaId !== null) {
              // the AREA_ID for this unit should be set to null
              newAreaId = null;
            }
            if (hasPeopleAssigned) {
              // the ASSIGNMENT_TYPE should be set to 'office'
              newAsnType = "office";
            } else {
              // the ASSIGNMENT_TYPE should be set to 'none'
              newAsnType = "none";
            }
          }

          if (asnType === "office") {
            if (areaId !== null) {
              // this is a row inconsistency and shouldn't happen
              // the AREA_ID for this unit should be set to null
              newAreaId = null;
            }
            if (!hasPeopleAssigned) {
              /* ********************************************************
                 possible version conflict
                 we could set the ASSIGNMENT_TYPE to 'none'
                 or attempt to recover the previous person assignments
                 Don't have a use case that actually causes this
                 ******************************************************** */
              reason = "no people assigned"
              newAsnType = "none";
            }
          }

          if (asnType === "none") {
            if (areaId !== null) {
              // this is a row inconsistency and shouldn't happen
              // the AREA_ID for this unit should be set to null
              newAreaId = null;
            }
            if (hasPeopleAssigned) {
              /* ********************************************************
                 possible version conflict
                 we could unassign the people referencing this unit
                 or set the ASSIGNMENT_TYPE to 'office'

                 Default - UnitA has PersonA assigned
                 VersionA - assign PersonB to UnitA
                 VersionB - unassign UnitA
                 Merge VersionA then VersionB

                 Inconsistent state:
                 UnitA ASSIGNMENT_TYPE='none'
                 PersonA UNIT_ID=null
                 PersonB UNIT_ID='UnitA'

                 Resolve by unassigning PersonB
                 ******************************************************** */
              unassignPeople = true;
              // vs newAsnType = "office";
            }
          }

          if (asnType === "not assignable") {
            if (areaId !== null) {
              // this is a dataset inconsistency and shouldn't happen
              // the AREA_ID for this unit should be set to null
              newAreaId = null;
            }
            if (hasPeopleAssigned) {
              // this is a dataset inconsistency and shouldn't happen
              // the people referencing this unit shoud be unassigned
              unassignPeople = true;
            }
          }

          const considerEmpty = false;
          if (asnType === "empty" && considerEmpty) {
            // this is a dataset inconsistency and shouldn't happen
            if (areaId !== null) {
              // the AREA_ID for this unit should be set to null
              newAreaId = null;
            }
            if (hasPeopleAssigned) {
              // the ASSIGNMENT_TYPE should be set to 'office'
              newAsnType = "office";
            } else {
              // the ASSIGNMENT_TYPE should be set to 'none'
              newAsnType = "none";
            }
          } else if (asnType === "empty" && !considerEmpty) {
            if (verbose) {
              console.log("WARNING: empty assignment_type",asnType,"Unit:",unitId);
            }
          }

          if (newAsnType !== undefined) {
            if (newAsnType === "office") {
              officeUnitIdIndex[unitId] = unitId;
            }
          } else if (asnType === "office") {
            officeUnitIdIndex[unitId] = unitId;
          }

          /* disable utilization update until 8.4
          let chkAsnType = asnType;
          if (newAsnType !== undefined) chkAsnType = newAsnType;
          if (chkAsnType === "office") {
            if (Array.isArray(peopleOidsAssigned) && peopleOidsAssigned.length > 0) {
              let nAssigned = peopleOidsAssigned.length;
              if (utilization !== nAssigned) newUtilization = nAssigned;
            } else {
              // shouldn't happen
            }
          } else if (chkAsnType === "hotdesk" || chkAsnType === "hotel" || chkAsnType === "none") {
            if (utilization !== 0) newUtilization = 0;
          } else if (chkAsnType === "not assignable") {
            // ignore this
          } else {
            // ignore this
          }
          */

          let hasUpdate = false;
          const update = {attributes: {}};
          update.attributes[objectIdField] = objectId;

          if (newAsnType !== undefined) {
            log(unitId,"need to set assignment_type",reason,asnType,newAsnType,areaId,newAreaId);
            update.attributes[asnTypeFieldName] = newAsnType;
            hasUpdate = true;
          }
          if (newAreaId !== undefined) {
            log(unitId,"need to unset area_id",reason,asnType,newAsnType,areaId,newAreaId);
            update.attributes[areaIdFieldName] = newAreaId;
            hasUpdate = true;
          }
          // if (newUtilization !== undefined) {
          //   log2(unitId,"need to set utilization","",utilization,newUtilization);
          //   update.attributes[utilFieldName] = newUtilization;
          //   hasUpdate = true;
          // }
          if (hasUpdate) {
            section.unitUpdates.push(update);
            if (task.updatedUnitOIDs.indexOf(objectId) === -1) {
              task.updatedUnitOIDs.push(objectId);
            }
          }

          if (unassignPeople) {
            log(unitId,"need to unassign people",reason,asnType,newAsnType,areaId,newAreaId);
            unassignedPeopleUnitIdIndex[unitId] = unitId;
            addUnassignPeopleEdits(task,section,peopleOidsAssigned);
          }

          if (addArea) {
            if (!section.addAreas.areasById[addArea.id]) {
              if (verbose) console.log("Unit",unitId,"need to re-add area",addArea.name);
              addAreaInsert(task,section,addArea);
            }
          }

        })
      }
      resolve(result);
    }).catch(ex => {
      reject(ex);
    })
  });
  return promise;
}

export function queryAreas(task: IMergePlanTask, section: IPlanData) {
  if (!OfficePlan.getActive().areasTable || !OfficePlan.getActive().areasTable.table) return Promise.resolve();
  const promise = new Promise<void>((resolve,reject) => {
    const table = task._areasTable || OfficePlan.getActive().areasTable.table;
    const areas: (IFeature | {
      id: string,
      type: string,
      name: string,
      objectId: number,
      globalId: number
    })[] = [{ ...HomeOfficeJSON }];
    const areasById = {
      [HomeOfficeId]: OfficePlan.getActive().areasTable.HomeOffice
    };
    const lib = Context.instance.lib;
    const url = Context.checkMixedContent(table.url+"/"+table.layerId);
    const queryTask = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.where = "1=1";
    if (!section.isDefault) {
      if (table.gdbVersion) query.gdbVersion = table.gdbVersion;
    }
    queryTask.execute(query).then(result => {
      //console.log("AreasTable.query.result",result);
      let fields = (result.fields || table.fields);
      let idField = aiimUtil.findField(fields,FieldNames.AREA_ID);
      let nameField = aiimUtil.findField(fields,FieldNames.AREA_NAME);
      let typeField = aiimUtil.findField(fields,FieldNames.AREA_TYPE);
      result.features.forEach(row => {
        const areaId = aiimUtil.getAttributeValue(row.attributes,idField.name);
        const areaType = aiimUtil.getAttributeValue(row.attributes,typeField.name);
        const areaName = aiimUtil.getAttributeValue(row.attributes,nameField.name);
        const oid = aiimUtil.getAttributeValue(row.attributes,table.objectIdField);
        const gid = aiimUtil.getAttributeValue(row.attributes, aiimUtil.getGlobalIdField(table));
        const area = {
          id: areaId,
          type: areaType,
          name: areaName,
          objectId: oid,
          globalId: gid
        }
        areas.push(area);
        areasById[areaId] = area;
      });
      section.areas = areas;
      section.areasById = areasById;
      resolve();
    }).catch(ex => {
      console.error("Error querying AREAS table",ex);
      reject(ex);
    });
  });
  return promise;
}

function reconcile(task: IMergePlanTask, section: IMergePlanData, prop: string) {
  if (!prop) prop = "reconcileResult";
  const options = {plan: task.plan};
  return task.versionManager.reconcilePlan(options).then(result => {
    section[prop] = result && result.reconcile;
  });
}

function replacePlaceholderEdits(task, section, options, useGlobalIds) {
  return applyEdits(task, section, {
    useGlobalIds: !!useGlobalIds,
    replacePlaceholdersOnly: true, 
    replacePlaceholderEdits: options.replacePlaceholderEdits.edits,
  })
}
