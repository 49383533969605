import React from 'react';

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-notice";
import { CalciteBlock, CalciteNotice } from '@esri/calcite-components-react';
import Context from '../../../../context/Context';
import { nextId } from '../../../../components/util/component';
import { IErrorOptions } from './Modal';

const CSS = {
  main: "i-error-details"
};
const ErrorDetails = ({ errors }: IErrorOptions) => {
  const i18n = Context.getInstance().i18n;
  const listId = nextId("error-list-");
  const { generic, detailed } = errors || {};
  const renderDetails = () => {
    return (
      <>
        {renderMessage()}
        <CalciteBlock collapsible heading={i18n.more.bookWorkspace.errors.moreDetails} >
          <div>
            <ul>
              {detailed.filter(d => !!d).map((e, i) =>
                (<li key={`${listId}-${i}`}>{e}</li>))}
            </ul>
          </div>
        </CalciteBlock>
      </>
    );
  }
  const renderMessage = () => {
    return (
      <CalciteNotice open kind="danger" icon="exclamation-mark-triangle">
        <div slot="message">{generic}</div>
      </CalciteNotice>
    )
  }

  return (
    <div className={CSS.main}>
      {detailed && detailed.filter(d => !!d).length > 0
        ? renderDetails()
        : renderMessage()}
    </div>
  );
}

export default ErrorDetails;
