import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as portalUtil from "../../../../util/portalUtil";
import * as serviceUtil from "../../../base/serviceUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as stencilUtil from "./stencilUtil";
import * as unitUtil from "./unitUtil";
import { getGeometryServiceUrl } from "../../../../util/geoUtil";
import { IStencil } from "../../../miniapps/common/types";

import { arcgisToGeoJSON } from "@esri/arcgis-to-geojson-utils";
import { geoMercator, geoPath } from "d3-geo";

export enum FpeType {
  entryway = "entryway",
  window = "window",
  furniture = "furniture",
  transition = "transition"
};

export interface UseTypeIssues {
  allOK: () => boolean,
  fpeTypeOK: (fpeTpe: FpeType) => boolean,
  stencilOK: (stencil: IStencil) => boolean
}

let _defaultPaletteInfo;
let _paletteInfo;

/**
 * We ship 3 palette files with the app:
 *   public/app/config/details-palette-meters.json
 *   public/app/config/details-palette-feet.json
 *   public/app/config/details-palette-meters-ft.json
 * 
 * Set the variable below to true:
 *   const buildingDefaultPalette = true;
 * 
 * From the Configurator/Palette panel:
 * 
 *   - choose the service containing the "meters" palette 
 *   - spatialReference.unit: 'meters'
 *   - the JSON for this palette will be logged in the console
 *   - you'll need to copy the logged content (beautified) to details-palette-meters.json
 * 
 *   - choose the service containing the "feet" palette 
 *   - spatialReference.unit: 'us-feet'
 *   - the JSON for this palette will be logged in the console
 *   - you'll need to copy the logged content (beautified) to details-palette-feet.json
 *
 *   - choose the service containing the "meters" palette
 *   - spatialReference.unit: 'meters', with features measured in feet
 *   - the JSON for this palette will be logged in the console
 *   - you'll need to copy the logged content (beautified) to details-palette-meters-ft.json
 * 
 * Reset the variable below to false:
 *   const buildingDefaultPalette = false;
 * 
 * The last sources used were (2023-08-31):
 *   ps0019921.esri.com: FINAL_WGS1984_WebMercatorAuxSphere_ObjInMeters_MapInMeters
 *   ps0019921.esri.com: FINAL_NAD1983_StatePlane_CaliforniaVI_Feet_SANDIEGO
 *   ps0019921.esri.com: FINAL_WGS1984_WebMercatorAuxSphere_ObjInFeet_MapInMeters
 * 
 * When using the default palettes:
 *
 *   if the basemap spatialReference.unit is 'us-feet'
 *     we load details-palette-feet.json - based on FINAL_NAD1983_StatePlane_CaliforniaVI_Feet_SANDIEGO
 * 
 *   if the basemap spatialReference.unit is 'meters' and the measurement system is 'metric'
 *     we load details-palette-meters.json - based on FINAL_WGS1984_WebMercatorAuxSphere_ObjInMeters_MapInMeters
 * 
 *   if the basemap spatialReference.unit is 'meters' and the measurement system is 'imperial'
 *     we load details-palette-meters-ft.json - based on FINAL_WGS1984_WebMercatorAuxSphere_ObjInFeet_MapInMeters
 * 
 */
const buildingDefaultPalette = false;

export default class StencilLoader extends BaseClass {

  static filter(stencils,fpeType) {
    if (fpeType && stencils && stencils.length > 0) {
      stencils = stencils.filter(stencil => {
        return (fpeType === stencil.fpeType);
      })
    }
    return stencils;
  }

  static checkUseTypes(paletteInfo): UseTypeIssues {
    const result = {
      problemFpeTypes: [],
      problemKeys: [],
      allOK: () => {
        return (result.problemKeys.length === 0);
      },
      fpeTypeOK: (fpeTpe: FpeType) => {
        return (result.problemFpeTypes.indexOf(fpeTpe) === -1);
      },
      stencilOK: (stencil: IStencil) => {
        return !!(stencil && result.problemKeys.indexOf(stencil.key) === -1);
      }
    }
    const layer = sourceUtil.getDetailsLayer();
    if (paletteInfo && paletteInfo.stencils && layer && layer.renderer && layer.renderer.type === "unique-value") {
      const renderer = (layer.renderer as __esri.UniqueValueRenderer);
      const defaultSymbol = renderer.defaultSymbol;
      const useTypeField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAILS_USE_TYPE);
      if (!defaultSymbol && (useTypeField === renderer.field) && !renderer.field2 && !renderer.field3) {
        paletteInfo.stencils.forEach(stencil => {
          let fpeType = stencil.fpeType;
          let useType = stencil.detailUseType;
          if (typeof useType === "undefined") useType = null;
          const g = {attributes: {[useTypeField]: useType}};
          // @ts-ignore
          const uvInfo = renderer._getUniqueValueInfo(g);
          if (!uvInfo || !uvInfo.symbol) {
            result.problemKeys.push(stencil.key);
            if (!result.problemFpeTypes.includes(fpeType)) result.problemFpeTypes.push(fpeType);
          }
        })
      }
    }
    return result;
  }

  async load(fpeType) {
    const itemId = Context.instance.config.spaceplanner.palette.itemId;
    let stencils = [], paletteInfo = _paletteInfo;

    if (itemId) {
      const hadIssues = (paletteInfo && paletteInfo.issues && paletteInfo.issues.length > 0);
      if (!paletteInfo || itemId !== paletteInfo.itemId || hadIssues) {
        paletteInfo = await this.loadItem(itemId);
        const hasIssues = (paletteInfo && paletteInfo.issues && paletteInfo.issues.length > 0);
        if (hasIssues) {
          // @todo fallback to default?, show issues?
          // paletteInfo = await this.loadDefault();
        }
        _paletteInfo = paletteInfo;
      }
    } else {
      if (!paletteInfo || !paletteInfo.isDefault) {
        paletteInfo = await this.loadDefault();
        _paletteInfo = paletteInfo;
      }
    }

    if (paletteInfo && paletteInfo.stencils) {
      const view = Context.instance.views && Context.instance.views.mapView;
      let viewSR = view && view.spatialReference;
      if (viewSR) viewSR = viewSR.clone();
      const geometryJsonUtils = Context.instance.lib.esri.geometryJsonUtils;
      stencils = paletteInfo.stencils.map(stencil => {
        const newStencil = Object.assign({},stencil)
        if (typeof stencil.geometry.clone !== "function") {
          newStencil.geometry = geometryJsonUtils.fromJSON(stencil.geometry);
        } else {
          newStencil.geometry = stencil.geometry.clone();
        }
        if (viewSR) newStencil.geometry.spatialReference = viewSR;
        return newStencil;
      })
      if (fpeType) {
        stencils = StencilLoader.filter(stencils,fpeType);
      }
      paletteInfo = Object.assign({},paletteInfo);
      paletteInfo.stencils = stencils;
    }

    // console.log("paletteInfo",paletteInfo)
    return paletteInfo;
  }

  async loadDefault() {
    const view = Context.instance.views && Context.instance.views.mapView;
    let file = "details-palette-meters.json";
    if (view && view.spatialReference && view.spatialReference.unit === "us-feet") {
      file = "details-palette-feet.json";
    }
    if (file === "details-palette-meters.json") {
      const metric = unitUtil.isMetric();
      if (!metric) file = "details-palette-meters-ft.json";
    }
    if (_defaultPaletteInfo && _defaultPaletteInfo.file === file) return _defaultPaletteInfo; // load once

    const lib = Context.instance.lib;
    let path = lib.esri.urlUtils.urlToObject(window.location.href).path;
    path = path.replace("/index.html","");
    if (!path.endsWith("/")) path += "/";
    const url = path + "app/config/"+file;
    const options = {query: {}, responseType: "json"};
    const result = await lib.esri.esriRequest(url,options);
    let stencils = result && result.data;
    if (!Array.isArray(stencils)) stencils = [];
    //stencils.forEach(stencil => stencil.name = stencil.name +"x") // @todo, temporary to distinguish default in UI
    const paletteInfo = {
      isDefault: true,
      defaultFile: file,
      issues: [],
      stencils: stencils
    }
    _defaultPaletteInfo = paletteInfo;
    return _defaultPaletteInfo;
  }

  async loadItem(itemId) {
    const i18n = Context.instance.i18n;
    const paletteInfo = {
      isDefault: false,
      issues: [],
      item: null,
      itemId: itemId,
      layerInfo: null,
      layerUrl: null,
      stencils: null
    }

    try {
      const result = await (itemId && portalUtil.readItem(itemId));
      paletteInfo.item = result && result.data;
      if (!paletteInfo.item) {
        paletteInfo.issues.push({
          key: "unableToLoadItem",
          message: i18n.miniapps.configurator.palette.issues.unableToLoadItem
        });
      } else if (!paletteInfo.item.url) {
        paletteInfo.issues.push({
          key: "noItemUrl",
          message: i18n.miniapps.configurator.palette.issues.noItemUrl
        });
      }
    } catch(ex) {
      console.error(ex)
      paletteInfo.issues.push({
        key: "unableToLoadItem",
        message: i18n.miniapps.configurator.palette.issues.unableToLoadItem
      });
    }

    try {
      if (paletteInfo.item && paletteInfo.item.url) {
        const target: any = {};
        await serviceUtil.readServiceJson(paletteInfo.item.url,target);
        if (target.paletteLayerInfo) {
          paletteInfo.layerInfo = target.paletteLayerInfo;
          paletteInfo.layerUrl = paletteInfo.item.url + "/" + target.paletteLayerInfo.id;
        } else {
          paletteInfo.issues.push({
            key: "noPaletteLayer",
            message: i18n.miniapps.configurator.palette.issues.noPaletteLayer
          });
        }
      }
    } catch(ex) {
      console.error(ex)
      paletteInfo.issues.push({
        key: "unableToLoadLayer",
        message: i18n.miniapps.configurator.palette.issues.unableToLoadLayer
      });
    }

    try {
      if (paletteInfo.layerUrl) {
        paletteInfo.stencils = await this.loadLayer(paletteInfo.layerUrl,paletteInfo);
        if (!paletteInfo.stencils || paletteInfo.stencils.length === 0) {
          paletteInfo.issues.push({
            key: "noStencils",
            message: i18n.miniapps.configurator.palette.issues.noFeatures
          });
        }
      }
    } catch(ex) {
      console.error(ex)
      paletteInfo.issues.push({
        key: "unableToLoadLayer",
        message: i18n.miniapps.configurator.palette.issues.unableToLoadLayer
      });
    }

    return paletteInfo;
  }

  async loadLayer(url,paletteInfo) {
    url = Context.checkMixedContent(url);
    const i18n = Context.instance.i18n;
    const view = Context.instance.views && Context.instance.views.mapView;
    const viewSR = view && view.spatialReference;

    const query = new Context.instance.lib.esri.Query();
    query.returnGeometry = true;
    query.returnZ = true;
    query.outFields = ["*"];
    //if (viewSR) query.outSpatialReference = viewSR; // would require projecting anchor points, dimensions may be off
    let where = "1=1"
    query.where = where;

    const qtask = new Context.instance.lib.esri.QueryTask({url: url});
    let result = await qtask.execute(query);
    //console.log("stencils.result",result)
    let stencils = [];
    if (result && result.features && result.fields) {

      const paletteSR = result.spatialReference;
      if (paletteSR.unit !== "meters" && paletteSR.unit !== "us-feet") {
        let msg = i18n.miniapps.configurator.palette.issues.paletteMustBeMetersOrFeet;
        msg = msg.replace("{paletteUnit}",paletteSR.unit);
        console.warn(msg, paletteSR)
        paletteInfo.issues.push({
          key: "paletteMustBeMetersOrFeet",
          message: msg
        });
      }
      if (viewSR) {
        const matchWkid = !buildingDefaultPalette;
        if (matchWkid && paletteSR.wkid !== viewSR.wkid) {
          let msg = i18n.miniapps.configurator.palette.issues.paletteSRMustMatchView;
          msg = msg.replace("{paletteSR}",paletteSR.wkid).replace("{viewSR}",viewSR.wkid);
          console.warn(msg, "paletteSR", paletteSR, "viewSR", viewSR)
          paletteInfo.issues.push({
            key: "paletteSRMustMatchView",
            message: msg
          });
        } else if (paletteSR.unit !== viewSR.unit) {
          let msg = i18n.miniapps.configurator.palette.issues.paletteUnitMustMatchView;
          msg = msg.replace("{paletteUnit}",paletteSR.unit).replace("{viewUnit}",viewSR.unit);
          console.warn(msg, "paletteSR", paletteSR, "viewSR", viewSR)
          paletteInfo.issues.push({
            key: "paletteUnitMustMatchView",
            message: msg
          });
        }
      }

      const fields = result.fields;
      let oidField;
      fields.forEach(f => {
        if (f.type === "oid") oidField = f.name;
      })
      const layerInfo = {
        oidField: oidField,
        nameField: aiimUtil.findFieldName(fields,"name"),
        descriptionField: aiimUtil.findFieldName(fields,"description") || aiimUtil.findFieldName(fields,"descriptio"),
        detailUseTypeField: aiimUtil.findFieldName(fields,"detail_use_type") || aiimUtil.findFieldName(fields,"detail_use"),
        featureTypeField: aiimUtil.findFieldName(fields,"feature_type") || aiimUtil.findFieldName(fields,"feature_ty"),
        anchor1XField: aiimUtil.findFieldName(fields,"primary_anchor_x"),
        anchor1YField: aiimUtil.findFieldName(fields,"primary_anchor_y"),
        anchor2XField: aiimUtil.findFieldName(fields,"secondary_anchor_x"),
        anchor2YField: aiimUtil.findFieldName(fields,"secondary_anchor_y")
      }

      let anchorsByOid;
      // if (true) {
      //   // our services have anchor points defined in WebMercator coordinates
      //   const inSR = { wkid: 102100 } // Web Mercator
      //   //const outSR = { wkid: 102646 } // California State Plane
      //   const outSR = { wkid: result.spatialReference.wkid }
      //   anchorsByOid = await this.projectAnchors(layerInfo,result.features,inSR,outSR)
      //   console.log("anchorsByOid",anchorsByOid)
      // }

      result.features.forEach(f => {
        if (f.attributes && f.geometry && f.geometry.extent) {
          let stencil = stencilUtil.newStencil({
            key: ""+aiimUtil.getAttributeValue(f.attributes,layerInfo.oidField),
            name: aiimUtil.getAttributeValue(f.attributes,layerInfo.nameField) || "Unnamed",
            description: aiimUtil.getAttributeValue(f.attributes,layerInfo.descriptionField),
            detailUseType: aiimUtil.getAttributeValue(f.attributes,layerInfo.detailUseTypeField),
            featureType: aiimUtil.getAttributeValue(f.attributes,layerInfo.featureTypeField),
            geometry: f.geometry
          })
          const cen = f.geometry.extent.center;

          let ft = stencil.featureType; // entryway window furniture transition // equipment
          if (typeof ft === "string") ft = ft.toLowerCase();
          if (ft === "entryway" || ft === "window" || ft === "furniture" || ft === "transition") {
            stencil.fpeType = ft;
          }

          let oid, a1x, a1y, a2x, a2y;
          oid = aiimUtil.getAttributeValue(f.attributes,layerInfo.oidField);
          if (!anchorsByOid) {
            a1x = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor1XField);
            a1y = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor1YField);
            a2x = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor2XField);
            a2y = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor2YField);
          }
          if (anchorsByOid) {
            const o = anchorsByOid.get(oid);
            if (o && o.anchor1) {
              a1x = o.anchor1.x;
              a1y = o.anchor1.y;
              a2x = a2y = null;
              if (o && o.anchor2) {
                a2x = o.anchor2.x;
                a2y = o.anchor2.y;
              }
            }
          }

          if (typeof a1x !== "number" || typeof a1y !== "number" || a1x === 0 || a1y === 0) {
            a1x = cen.x;
            a1y = cen.y;
          }
          if (typeof a1x === "number" && typeof a1y === "number" && a1x !== 0 && a1y !== 0) {
            stencil.anchor1 = {
              dx: cen.x - a1x,
              dy: cen.y - a1y
            }
            if (typeof a2x === "number" && typeof a2y === "number" && a2x !== 0 && a2y !== 0) {
              stencil.anchor2 = {
                dx: cen.x - a2x,
                dy: cen.y - a2y
              }
            }
          }

          if (stencil.fpeType) {
            stencils.push(stencil);
          }
        }
      })
    }
    this.makeSVGs(stencils)
    if (buildingDefaultPalette) console.log("\n\n\n",JSON.stringify(stencils),"\n\n\n");
    return stencils;
  }

  makeSVGs(stencils) {
    stencils.forEach((stencil,i) => {
      const p = Context.instance.lib.esri.webMercatorUtils.webMercatorToGeographic(stencil.geometry);
      // @ts-ignore
      let geojsonGeometry = arcgisToGeoJSON(p.toJSON());
      let geojsonFeature = {
        type: "Feature",
        properties: {},
        geometry: geojsonGeometry
      }
      // let geojsonFeatureCollection = {
      //   type: "FeatureCollection",
      //   features: [geojsonFeature]
      // }
      const svgWidth = 32;
      const svgHeight = 32;
      const d3Projection = geoMercator().fitSize([svgWidth, svgHeight], geojsonFeature)
      const d3PathGenerator = geoPath().projection(d3Projection);
      const svgPath = d3PathGenerator(geojsonFeature);
      stencil.svgPath = svgPath;
      stencil.svgWidth = svgWidth;
      stencil.svgHeight = svgHeight;
    })
  }

  async projectAnchors(layerInfo,features,inSR,outSR) {
    const geometries = [], info = [];
    const gs: __esri.geometryService = Context.instance.lib.esri.geometryService;
    const url = getGeometryServiceUrl();

    for (let i=0; i<features.length; i++) {
      let f = features[i];
      let oid = aiimUtil.getAttributeValue(f.attributes,layerInfo.oidField);
      let a1x = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor1XField);
      let a1y = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor1YField);
      let a2x = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor2XField);
      let a2y = aiimUtil.getAttributeValue(f.attributes,layerInfo.anchor2YField);
      if (typeof a1x === "number" && typeof a1y === "number" && a1x !== 0 && a1y !== 0) {
        const pt = new Context.instance.lib.esri.Point({
          x: a1x,
          y: a1y,
          spatialReference: inSR
        })
        geometries.push(pt);
        info.push({
          oid,
          type: "a1"
        });
      }
      if (typeof a2x === "number" && typeof a2y === "number" && a2x !== 0 && a2y !== 0) {
        const pt = new Context.instance.lib.esri.Point({
          x: a2x,
          y: a2y,
          spatialReference: inSR
        })
        geometries.push(pt);
        info.push({
          oid,
          type: "a2"
        });
      }
    }

    const anchorsByOid = new Map()
    const params: __esri.ProjectParameters = new Context.instance.lib.esri.ProjectParameters({
      geometries: geometries,
      outSpatialReference: outSR
    });

    let projected = await gs.project(url, params);
    projected.forEach((p,i) => {
      const { oid, type } = info[i];
      let anchors = anchorsByOid.get(oid);
      if (!anchors) {
        anchors = {};
        anchorsByOid.set(oid,anchors)
      }
      if (type === "a1") anchors.anchor1 = p;
      if (type === "a2") anchors.anchor2 = p;
    })

    return anchorsByOid;
  }
  
  setPaletteInfo(paletteInfo) {
    _paletteInfo = paletteInfo;
  }

}