import React from "react";
import * as component from "../../../../src/components/util/component";
import Select from "calcite-react/Select";
import { MenuItem } from "calcite-react/Menu";
import Context from "../../../context/Context";

export default class FilterableSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = component.newState({
            portalGroups : this.props.portalGroups,
            selectedValue: this.props.caption
        })
    }

    handleSelectChange=(value, item)=> {
        const groupId = item.key;
        const groupTitle = value;
        this.props.storeGroupIds(groupId, groupTitle);
        this.setState({
            selectedValue: value
        })
    }
  
    render() {
        const portalGroups = this.state.portalGroups;
        const context = Context.getInstance();
        const i18n = context.i18n;
        return (
            <Select
                placement="bottom-right"
                placeholder={i18n.spaceplanner.configurator.showPortalGroups}
                filterable
                appendToBody
                onChange={this.handleSelectChange}
                selectedValue={this.state.selectedValue}
            >   
                {portalGroups.map(group => {
                    const id = group.id;
                    const title = group.title;
                    return (
                    <MenuItem key={id} value={title}>
                        {title}
                    </MenuItem>
                    )
                })}
            </Select>
        )
    }
}
