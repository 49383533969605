import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

import "@esri/calcite-components/dist/components/calcite-block";
import {
  CalciteBlock,
} from "@esri/calcite-components-react";

interface Props {
  onChange: ({isValid}) => void;
}

interface State {
}

export default class AppItem extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const i18n = Context.instance.i18n;
    const item = Context.instance.configuration.appItem;
    if (!item) return null;
    return (
      <CalciteBlock 
        open 
        heading={i18n.miniapps.configurator.appItem.caption}
        description={item.title}>
        <div className="i-button-bar-right">
          {configUtil.renderDetailsLink(item)}
        </div>
      </CalciteBlock>
    )
  }

}
