import Context from "../context/Context";
import * as projectionUtil from "./projectionUtil";
import { makeIdentifySymbol, makeRealtimeSymbol, makeYouAreHereSymbol2D, makeYouAreHereSymbol3D } from "./symbolUtil";

function _makeLocPoint(view, geom) {
  const lib = Context.getInstance().lib;
  let xVal, yVal, zVal;
  if ((geom.type === "polygon") || geom.hasOwnProperty("rings")) {
    if(geom.centroid) {
      xVal = geom.centroid.x;
      yVal = geom.centroid.y;
      if (geom.centroid.hasZ) {
        zVal = geom.centroid.z;
      }
    }
    else{
      xVal = geom.extent.center.x;
      yVal = geom.extent.center.y;
      if (geom.hasZ) {
        zVal = geom.extent.center.z;
      }
    }
  } else {
    xVal = geom.x;
    yVal = geom.y;
    if (geom.hasZ) {
      zVal = geom.z;
    }
  }
  const point = new lib.esri.Point({
    x: xVal,
    y: yVal,
    z: zVal,
    spatialReference: geom.spatialReference
  });
  return point;
}

function _setLocGraphic(view, point, symbol, layerId, layerTitle, options) {
  const lib = Context.getInstance().lib;
  const offset = Context.getInstance().config.graphicElevationOffset;
  const graphic = new lib.esri.Graphic({
    geometry: point,
    symbol: symbol
  });
  let lyr = view.map.findLayerById(layerId);
  if (!lyr) {
    lyr = new lib.esri.GraphicsLayer({
      id: layerId,
      listMode: "hide",
      title: layerTitle
    });
    lyr.elevationInfo = {
      mode: "relative-to-ground",
      offset: offset,
      unit: "meters",
    };
    view.map.add(lyr);
  }
  lyr.graphics.removeAll();
  if (options && options.type === "realtime" ) {
    //lyr.elevationInfo.offset = 0;
    if (options.uncertaintyGraphic) {
      if (Array.isArray(options.uncertaintyGraphic)) {
        lyr.graphics.addMany(options.uncertaintyGraphic);
      } else {
        lyr.graphics.add(options.uncertaintyGraphic);
      }
    }
  } else {
    // if (lyr.elevationInfo.offset !== offset) {
    //   lyr.elevationInfo.offset = offset;
    // }
  }
  lyr.graphics.add(graphic);
  return lyr;
}

export function afterSetLevel(view) {
  const chkDefault = (info) => {
    if (info && info.locationType === 0) {
      // outdoors
      return true;
    }
    return false;
  };

  if (!view) return;
  if (view.type === "2d") {
    const layerId = "indoors-location-graphics2";
    const lyr = view.map.findLayerById(layerId);
    if (!lyr) return;
    const info = lyr && lyr.xtnInfo;

    if (info && info.locationType === 0) {
      // outdoors
      lyr.visible = true;
      return;
    }

    const facilityId = info && info.facilityId;
    if (typeof facilityId === "string" && facilityId.length > 0) {
      const activeFacilityInfo = Context.instance.aiim.getActiveFacilityInfo();
      if (activeFacilityInfo) {
        /* e.g.
          (facility_id = 'ESRI.RED.MAIN.M' AND level_number = 1) OR (location_type = 0)
         */
        if (activeFacilityInfo.facilityId === facilityId) {
          let levelNumber;
          if (activeFacilityInfo.activeLevel) {
            levelNumber = activeFacilityInfo.activeLevel.levelNumber;
          } else if (activeFacilityInfo.zeroVOLevel) {
            levelNumber = activeFacilityInfo.zeroVOLevel.levelNumber;
          }
          if (typeof levelNumber === "number" && typeof info.levelNumber === "number") {
            lyr.visible = (levelNumber === info.levelNumber);
          } else if (typeof info.levelNumber !== "number") {
            lyr.visible = true;
          } else {
            lyr.visible = true;
          }
        } else {
          lyr.visible = chkDefault(info);
        }
      } else {
        lyr.visible = chkDefault(info);
      }
    } else {
      //not associated with a facility
      return true;
    }
  }
}

export function addLocationGraphic(view, geom, options) {
  if (!view) return;
  const lib = Context.getInstance().lib;
  const layerId = "indoors-location-graphics";
  const layerTitle = "indoorsLocationGraphics";
  if (options && options.makeTransparent) {
    const lyr = view.map.findLayerById(layerId);
    lyr.opacity = 0.4;
    return;
  } else if (options && options.makeSolid) {
    const lyr = view.map.findLayerById(layerId);
    lyr.opacity = 1;
    return;
  }
  const point = _makeLocPoint(view,geom);
  let symbol = makeIdentifySymbol();
  if (options && options.type === "realtime") {
    symbol = makeRealtimeSymbol();
    if (geom && geom.type === "point" && options.horizontalAccuracy) {
      const outlineColor = [0, 121, 193, 0.4];
      const outlineWidth = "2px";
      const circle = new lib.esri.Circle({
        center: geom.clone(),
        radius: options.horizontalAccuracy,
        radiusUnits: "meters"
      });
      const symbol2 = {
        type: "simple-fill",
        color: [255,255,255,0.4],
        outline: {
          color: outlineColor,
          width: outlineWidth
        }
      };
      let uncertaintyGraphic = new lib.esri.Graphic({
        geometry: circle,
        symbol: symbol2
      });
      if (options.horizontalAccuracy >= 0.6) {
        const cenGeom = new lib.esri.Circle({
          center: geom.clone(),
          radius: 0.2,
          radiusUnits: "meters"
        });
        const cenGraphic = {
          geometry: cenGeom,
          symbol: {
            type: "simple-fill",
            color: [255,255,255,0],
            outline: {
              color: outlineColor,
              width: outlineWidth
            }
          }
        };
        uncertaintyGraphic = [uncertaintyGraphic,cenGraphic];
      }

      options.uncertaintyGraphic = uncertaintyGraphic;
    }
  }

  const lyr=_setLocGraphic(view,point,symbol,layerId,layerTitle,options);
  let text2D="";
  let labelPoint=point;
  if(view.type ==="2d" && options && options.text){
    var str = options.text;
    var chunks=str.match(/.{1,75}(\s|$)/g);
    var textData="";
    chunks.forEach(function(element){
      textData=textData + element;
      textData=textData + "\n";
    })
   text2D = {
      type: "text",
      text: "",
      color: "#000000",
      haloColor: "rgba(255,255,255,0.7)",
      haloSize: 1,
      xoffset: 0,
      yoffset: 28,
      font: {
        size: 11,
        family: "sans-serif",
        weight: "bold"
      }
    };
  }

  else if(view.type === "3d" && options && options.text){
    //  str = options.text;
    //  chunks=str.match(/.{1,75}(\s|$)/g);
    //  textData="";
    //  chunks.forEach(function(element){
    //   textData=textData + element;
    //   textData=textData + "\n";
    // })

    text2D = {
      type: "point-3d",
      symbolLayers: [{
        type: "text",
        text: "",
        material: {
          color: "#005e95"
        },
        halo: {
          color: [255, 255, 255, 0.7],
          size: 2
        },
        size: 11,
        font: {
          size: 11,
          family: "sans-serif",
          weight: "bold"
        }
      }]
      // verticalOffset: {
      //   screenLength: 10,
      //   maxWorldLength: 2,
      //   minWorldLength: 1.5
      // },
      // callout: {
      //   type: "line",
      //   size: 0.5,
      //   color: [0, 0, 0],
      //   border: {
      //     color: [255, 255, 255, 0.7]
      //   }
      // }
    };
    // labelPoint= point.clone();
    // labelPoint.y= labelPoint.y+ 1;
  }

  if (text2D) {
    lyr.graphics.add(new lib.esri.Graphic({
      geometry: labelPoint,
      symbol: text2D
    }));
  }

  if(options && options.isDifferentLevel){
      lyr.opacity=0.4;
    }else{
      lyr.opacity=1;
    }
}

export function addYouAreHereGraphic(view, geom, info) {
  if (!view) return;
  const lib = Context.getInstance().lib;
  const i18n = Context.getInstance().i18n;
  const layerId = "indoors-location-graphics2";
  const layerTitle = "indoorsLocationGraphics2";
  const point = _makeLocPoint(view,geom);

  if (view.type === "2d") {

    const marker = makeYouAreHereSymbol2D();

    const text2D = {
      type: "text",
      text: i18n.map.youAreHere,
      color: "#005e95",
      haloColor: "#ffffff",
      haloSize: 1,
      xoffset: 0,
      yoffset: 22,
      font: {
        size: 16,
        family: "sans-serif",
        weight: "bold"
      }
    };
    const lyr = _setLocGraphic(view,point,marker,layerId,layerTitle);
    lyr.xtnInfo = info;
    lyr.graphics.add(new lib.esri.Graphic({
      geometry: point,
      symbol: text2D
    }));
    lyr.visible = true;

  } else if (view.type === "3d") {

    /*
    let symbol3D = {
      type: "point-3d",
      symbolLayers: [{
        type: "icon",
        resource: {
          href: "CityHall.svg"
        },
        size: 20
      }],
      verticalOffset: {
        screenLength: 40,
        maxWorldLength: 100,
        minWorldLength: 20
      },
      callout: {
        type: "line",
        size: 1.5,
        color: "white",
        border: {
          color: "black"
        }
      }
    };
    */

    const marker = makeYouAreHereSymbol3D();

    const callout3D = {
      type: "point-3d",
      symbolLayers: [{
        type: "text",
        text: i18n.map.youAreHere,
        material: {
          color: "#005e95"
        },
        halo: {
          color: [255, 255, 255, 0.7],
          size: 2
        },
        size: 16,
        font: {
          size: 16,
          family: "sans-serif",
          weight: "bold"
        }
      }],
      verticalOffset: {
        screenLength: 40,
        maxWorldLength: 100,
        minWorldLength: 10
      },
      callout: {
        type: "line",
        size: 0.5,
        color: [0, 0, 0],
        border: {
          color: [255, 255, 255, 0.7]
        }
      }
    };

    const lyr = _setLocGraphic(view,point,marker,layerId,layerTitle);
    lyr.graphics.add(new lib.esri.Graphic({
      geometry: point,
      symbol: callout3D
    }));

    //_setLocGraphic(view,point,callout3D,layerId,layerTitle);
  }
}

export function clearLocationGraphic(view) {
  if (!view) return;
  const lid = "indoors-location-graphics";
  const lyr = view.map.findLayerById(lid);
  if (lyr) {
    lyr.graphics.removeAll();
  }
}

export function goToFeature(view, feature, showingRoute, customOptions) {
  const lib = Context.getInstance().lib;
  const bufferMeters = 10;

  const addZ = (extent,z) => {
    const ext = new lib.esri.Extent({
      "xmin": extent.xmin,
      "xmax": extent.xmax,
      "ymin": extent.ymin,
      "ymax": extent.ymax,
      "zmin": z,
      "zmax": z,
      spatialReference: extent.spatialReference
    });
    return ext;
  };

  if (!feature || !feature.geometry) return Promise.resolve();
  // TODO need a different approach for 3D?
  // TODO jsapi3 has map.setExtent(extent, fit?), equivalent in 4?
  const is3D = (view && view.type === "3d");
  let geometry = null, target = null, heading = null, tilt = null;
  if (feature.geometry.type === "point") {
    // TODO use buffer or geodesicBuffer ?
    geometry = projectionUtil.geodesicBuffer(feature.geometry, bufferMeters);
    // geometry = lib.esri.geometryEngine.geodesicBuffer(feature.geometry,bufferMeters,"meters");
    if (geometry && geometry.extent) {
      geometry = geometry.extent;
      const z = (typeof feature.geometry.z === "number") ? feature.geometry.z : 0;
      geometry = addZ(geometry.extent,z);
      target = {target: geometry};
    }
  } else if (feature.geometry.extent) {
    let ext = feature.geometry.extent.clone();
    if (showingRoute && Context.instance.uiMode.isMobile) {
      ext = ext.clone().expand(1.25);
    }
    geometry = projectionUtil.geodesicBuffer(feature.geometry, bufferMeters);
    // geometry = lib.esri.geometryEngine.geodesicBuffer(ext,bufferMeters,"meters");
    if (geometry && geometry.extent) {
      geometry = geometry.extent;
      const z = (typeof ext.zmin === "number") ? ext.zmin : 0;
      geometry = addZ(geometry.extent,z);
      target = {target: geometry};
    }
  } else {
    geometry = feature.geometry;
    target = {target: geometry};
  }
  const options = {
    duration: 1000,
    easing: "out-back"
  };

  if (target && customOptions && customOptions.scale) {
    target.scale = customOptions.scale;
  }

  if (is3D) {
    if (view.camera) {
      //console.log("camera.heading",view.camera.heading,"camera.tilt",view.camera.tilt);
      heading = view.camera.heading;
      //tilt = view.camera.tilt;
      tilt = 60;
    }
    if (typeof heading === "number") target.heading = heading;
    if (typeof tilt === "number") target.tilt = tilt;
  }

  if (target) {
    return view.goTo(target,options).then(() => {
      if (!is3D && (view.extent && !view.extent.contains(geometry))) {
        //console.log("view.extent",view.extent,"geometry",geometry);
        view.zoom -= 1;
      }
    });
  } else {
    return Promise.resolve();
  }
}

export function goToXYLocation(view, x, y, l) {
  const lib = Context.getInstance().lib;
  const point = new lib.esri.Point({
    x: x,
    y: y,
    spatialReference: lib.esri.SpatialReference.WGS84
  });
  const geometry = lib.esri.webMercatorUtils.geographicToWebMercator(point);
  const feature =  new lib.esri.Graphic({
    geometry: geometry,
    symbol: null
  });
  return goToFeature(view, feature);
}

export function reorderLocationGraphicLayers() {
  try {
    const reorder = (view) => {
      if (!view) return;
      const lid = "indoors-location-graphics";
      const lyr = view.map.findLayerById(lid);
      if (lyr) {
        const len = view.map.layers.length;
        view.map.reorder(lyr,len);
      }
    };
    const views = Context.getInstance().views;
    reorder(views.mapView);
    reorder(views.sceneView);
  } catch(ex) {
    console.warn("Error reordering layers.");
    console.error(ex);
  }
}
