import Context from "../../context/Context";
import * as aiimUtil from "./aiimUtil";
import * as searchUtil from "./searchUtil";
import * as selectionUtil from "./selectionUtil";

function createQueryGeometry(view, point, tolerance) {
  const resolution = view.get("state.resolution");
  const overlayPixelSizeInMapUnits = typeof resolution === "number" ? resolution : 1;
  const worldSize = tolerance * overlayPixelSizeInMapUnits;
  const { spatialReference } = view;
  const lib = Context.getInstance().lib;
  //console.log("tolerance",tolerance,"worldSize",worldSize)
  return new lib.esri.Extent({
    xmin: Math.min(point.x - worldSize, point.x + worldSize),
    ymin: Math.min(point.y - worldSize, point.y + worldSize),
    xmax: Math.max(point.x - worldSize, point.x + worldSize),
    ymax: Math.max(point.y - worldSize, point.y + worldSize),
    spatialReference
  });
}

function calculateTolerance(sublayer,renderer) {
  let defaultTolerance = 6;
  if (sublayer && sublayer.xtnFeatureLayer) {
    const fl = sublayer.xtnFeatureLayer;
    if (fl.geometryType === "polyline") defaultTolerance = 4
    if (!renderer) renderer = fl.renderer;
  }
  if (!renderer) return defaultTolerance;

  const offsetTolerance = (tolerance, symbol) => {
    if (symbol && symbol.xoffset) {
      return Math.max(tolerance,Math.abs(symbol.xoffset));
    }
    if (symbol && symbol.yoffset) {
      return Math.max(tolerance,Math.abs(symbol.yoffset));
    }
    return tolerance;
  }
  if (renderer.type === "simple") {
    return offsetTolerance(defaultTolerance,renderer.symbol);
  }
  if (renderer.type === "unique-value") {
    let tolerance = defaultTolerance;
    renderer.uniqueValueInfos.forEach((info) => {
      tolerance = offsetTolerance(tolerance,info.symbol);
    });
    return tolerance;
  }
  if (renderer.type === "class-breaks") {
    let tolerance = defaultTolerance;
    renderer.classBreakInfos.forEach((info) => {
      tolerance = offsetTolerance(tolerance,info.symbol);
    });
    return tolerance;
  }
  return defaultTolerance;
}

function makeHit(view,qresult,event) {
  const source = qresult.source;
  const layer = qresult.layer;
  const hitFeature = qresult.features[0]
  const hit = {
    event: event,
    source: qresult.source,
    sourceKey: source && source.key,
    sourceIndex: source && source.index,
    featureLayer: null,
    feature: null,
    objectId: null,
    searchResult: null,
    isNonAiimFeature: !source,
    isMoveOccupantsLayer: !!qresult.isMoveOccupantsLayer
  };
  if (layer.type === "scene") {
    if (layer.associatedLayer && layer.associatedLayer.type === "feature" &&
        layer.associatedLayer.objectIdField) {
      const objectIdField = layer.associatedLayer.objectIdField;
      hit.featureLayer = layer.associatedLayer;
      hit.featureLayer.title = layer.title;
      hit.objectId = aiimUtil.getAttributeValue(hitFeature.attributes,objectIdField);
    }
  } else if (layer.declaredClass === "esri.layers.support.Sublayer") {
    if (source && source.layer2D && source.layer2D.objectIdField) {
      const objectIdField = source.layer2D.objectIdField;
      hit.featureLayer = source.layer2D;
      hit.objectId = aiimUtil.getAttributeValue(hitFeature.attributes,objectIdField);
    }
  } else if (layer.type === "feature" && layer.objectIdField) {
    const objectIdField = layer.objectIdField;
    hit.featureLayer = layer;
    hit.objectId = aiimUtil.getAttributeValue(hitFeature.attributes,objectIdField);
  }
  return hit;
}

function populateSearchResult(hit, view) {
  hit.searchResult = hit.source.makeSearchResult(view,hit.feature);
  hit.searchResult.key = hit.objectId;
  hit.caption = hit.searchResult.name;
}

function queryFeature(hit, view) {
  const source = hit.source;
  const featureLayer = hit.featureLayer;
  const objectId = hit.objectId;
  const is3D = (view && view.type === "3d");
  const promise = new Promise((resolve,reject) => {
    const url = featureLayer.url + "/" + featureLayer.layerId;
    const gdbv = featureLayer.gdbVersion;
    searchUtil.queryFieldValue(source,url,null,objectId,true,gdbv).then(result => {
      if (result && result.features.length === 1) {
        if (featureLayer.geometryType === "multipatch" || is3D) {
          const fld = source.uniqueIdField;
          const val = aiimUtil.getAttributeValue(result.features[0].attributes,fld);
          if (fld && val) {
            return searchUtil.queryFieldValue(source,source.url,fld,val);
          }
        }
      }
      return result;
    }).then(result => {
      //console.log("hitTest.result2",result);
      if (result && result.features.length === 1) {
        const feature = result.features[0];
        hit.feature = feature;
        populateSearchResult(hit,view);
      }
      return result;
    }).then(result => {
      resolve(result);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

function queryFeatureNoSource(hit, view) {
  const source = null;
  const featureLayer = hit.featureLayer;
  const objectId = hit.objectId;
  //const is3D = (view && view.type === "3d");
  const promise = new Promise((resolve,reject) => {
    const url = featureLayer.url + "/" + featureLayer.layerId;
    const gdbv = featureLayer.gdbVersion;
    searchUtil.queryFieldValue(source,url,null,objectId,true,gdbv).then(result => {
      // if (result && result.features.length === 1) {
      //   if (featureLayer.geometryType === "multipatch" || is3D) {
      //     const fld = source.uniqueIdField;
      //     const val = aiimUtil.getAttributeValue(result.features[0].attributes,fld);
      //     if (fld && val) {
      //       return searchUtil.queryFieldValue(source,source.url,fld,val);
      //     }
      //   }
      // }
      return result;
    }).then(result => {
      if (result && result.features.length === 1) {
        hit.feature = result.features[0];
      }
      return result;
    }).then(result => {
      resolve(result);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

function isLayerHidden(layer, view) {
  if (layer.visible && layer.parent && typeof layer.parent.visible !== "undefined") {
    if (view && isLayerOutsideScaleRange(layer, view.scale)) return true;
    return isLayerHidden(layer.parent, view);
  } else {
    return !layer.visible || (view && isLayerOutsideScaleRange(layer, view.scale));
  }
}

function isLayerOutsideScaleRange(layer, currentScale) {
  if (!layer || isNaN(currentScale)) {
    return false;
  }

  const min = findLayerMinScale(layer);
  const max = findLayerMaxScale(layer);

  const isOutsideMinScale = !isNaN(min) && min > 0 && currentScale > min;
  const isOutisdeMaxScale = !isNaN(max) && max > 0 && currentScale < max;

  return isOutsideMinScale || isOutisdeMaxScale;
}

function findLayerMinScale(layer) {
  if (!layer) {
    return undefined;
  }

  return layer.minScale != null ? layer.minScale : undefined;
}

function findLayerMaxScale(layer) {
  if (!layer) {
    return undefined;
  }

  return layer.maxScale != null ? layer.maxScale : undefined;
}

function queryLayerView(view,layerView,event,allowNonAiim) {
  const baseLayer = layerView && layerView.layer;
  if (layerView && layerView.layer && layerView.layer.xtnHitTestDisabled) {
    return Promise.resolve();
  }
  if (layerView && layerView.layer && layerView.layer.type === "map-image") {
    return queryMapService(view,layerView.layer,event,allowNonAiim);
  } else if (layerView && typeof layerView.hitTest === "function") {
    const screenPoint = event.screenPoint;
    const mapPoint = event.mapPoint;
    const isPlanLayer = baseLayer && baseLayer.xtnAiim && baseLayer.xtnAiim.isPlanLayer;
    const isMoveOccupantsLayer = (baseLayer && baseLayer.title === "indoors-moveOccupants-people");
    const isSP = Context.getInstance().appMode.isSP_or_FPE();
    const spCheck = !isSP || (isSP && (isPlanLayer || isMoveOccupantsLayer));
    // JSAPI 4.22 update - Promises for layer views that are hidden, or out of scale range will
    // stay pending until they are visible, so we shouldn't add those to the promise list. For Space Planner
    // we only want to be able to identify plan layers. Make sure the screen point exists, because the params
    // of hitTest() have changed to hitTest(_mapPoint: Point, screenPoint: ScreenPoint).
    const test = spCheck && screenPoint && !isLayerHidden(baseLayer, view) && layerView.hitTest(mapPoint, { x: screenPoint.x, y: screenPoint.y });

    if (test) {
      const promise = new Promise((resolve,reject) => {
        const categories = Context.getInstance().aiim.datasets.categories;
        const facilityFootprints = Context.getInstance().aiim.facilityFootprints;
        const lib = Context.instance.lib;
        const geometryEngine = lib.esri.geometryEngine;
        test.then(qresult => {
          qresult = Context.instance.aiim.fixHitTestResult(qresult); 
          //console.log("queryLayerView.result",allowNonAiim,qresult && qresult.layer && qresult.layer.title,qresult);
          const results = [];

          // 4.22 Update - This will ALWAYS return an array now
          // TODO this might be an array?
          if (Array.isArray(qresult)) {
            //console.warn("Warning: aiim/util/hitTest::queryLayerView result is an Array.....");
            if (qresult && qresult.length > 0 && qresult[0]) {
              qresult = qresult[0];
              // jsapi 4.24 change
              if (qresult && qresult.type === "graphic" && qresult.graphic) {
                qresult = qresult.graphic;
              } else {
                qresult = null; 
              }
            }
          }

          // lessen the priority for linear features like Details
          if (qresult && qresult.layer && qresult.layer.title) {
            let mapPoint = event.mapPoint, geometry = qresult.geometry;
            if (qresult.layer.geometryType === "polyline" && mapPoint && geometry) {
              let metersPerMapUnit = lib.esri.unitUtils.getMetersPerUnit(view.spatialReference);
              if (typeof metersPerMapUnit === "number" && !isNaN(metersPerMapUnit) &&
                  isFinite(metersPerMapUnit)) {
                const resolution = view.get("state.resolution");
                const mapUnitsPerPixel = (typeof resolution === "number" ? resolution : 1);
                const metersPerPixel = (mapUnitsPerPixel * metersPerMapUnit);
                let numPixels = 2;
                if (metersPerPixel > 0.5) numPixels = 1
                if (metersPerPixel < 0.02) numPixels = 3
                const threshold = (numPixels * metersPerPixel);
                const distance = geometryEngine.distance(mapPoint,geometry,"meters");
                if (distance > threshold) {
                  qresult = null;
                }
                //console.log("metersPerPixel",metersPerPixel)
              }
            }
          }

          if (qresult && qresult.layer && qresult.layer.title) {
            //console.log("hit",qresult.layer.title,qresult)
            let source = categories.findSourceByLayer(qresult.layer);
            let layer = qresult.layer;
            let popupEnabled = qresult.layer.popupEnabled && !!qresult.layer.popupTemplate;
            let isFacilityFootprint = (qresult.layer.id === facilityFootprints.layerId) && qresult.visible;
            if (isFacilityFootprint) {
              source = facilityFootprints.getSource();
              if (source) layer = source.layer2D;
            }
            if (source || isMoveOccupantsLayer || (allowNonAiim && popupEnabled && qresult.visible)) {
              results.push({
                source: source,
                layer: layer,
                features: [qresult],
                hitResult: qresult,
                isFacilityFootprint: isFacilityFootprint,
                isMoveOccupantsLayer: isMoveOccupantsLayer
              });
            }
          }
          // console.log("Fulfilled!", baseLayer.title, results)
          resolve(results);
        }).catch(ex => {
          console.warn("Error hitTest::queryLayerView");
          console.error(ex);
          resolve();
        });
      });
      return promise;
    }
  }
  return Promise.resolve();
}

function queryLayerViews(view,event,allowNonAiim) {
  const promises = [];
  // const layerViewsToTest: IHitTestable[] = [view._graphicsView];
  // layerViewsToTest.push.apply(layerViewsToTest, this.allLayerViews.toArray().reverse());
  const layerViews = view.allLayerViews.toArray().slice(0).reverse();
  layerViews.forEach(layerView => {
    //console.log("layerView",layerView);
    let ok = true;
    if (layerView && layerView.layer && layerView.layer.parent &&
        layerView.layer.parent.declaredClass === "esri.Basemap") {
      ok = false;
    }
    if (ok) {
      const promise = queryLayerView(view,layerView,event,allowNonAiim);
      if (promise) promises.push(promise);
    }
  });
  if (promises.length > 0) {
    const promise = new Promise((resolve,reject) => {
      Promise.all(promises).then(qresults => {
        const results = [];
        if (qresults) {
          //console.log("queryLayerViews.qresults",allowNonAiim,qresults);
          qresults.forEach(qresult => {
            if (Array.isArray(qresult)) {
              qresult.forEach(qresult2 => {
                if (qresult2 && (qresult2.source || qresult2.isMoveOccupantsLayer || allowNonAiim)) {
                  results.push(qresult2);
                }
              });
            } else if (qresult && (qresult.source || qresult.isMoveOccupantsLayer || allowNonAiim)) {
              results.push(qresult);
            }
          });
        }
        resolve(results);
      }).catch(ex => {
        console.warn("Error hitTest::queryLayerViews");
        console.error(ex);
        resolve();
      });
    });
    return promise;
  }
  return Promise.resolve();
}

function queryMapService(view,layer,event,allowNonAiim) {
  if (layer && layer.xtnHitTestDisabled) {
    return Promise.resolve();
  }

  const promises = [], point = event.mapPoint;
  if (point) {
    const categories = Context.getInstance().aiim.datasets.categories;
    const scale = view.get("scale");

    let floors;
    if (Context.instance.aiim.isFloorAwareMap() && view.floors && view.floors.length > 0) {
      view.floors.toArray().forEach(v => {
        if (v !== null && v !== undefined) {
          if (typeof v === "string") {
            v = "'"+selectionUtil.escSqlQuote(v)+"'";
          }
          if (!floors) floors = [];
          floors.push(v);
        }
      });
    }

    let sublayers = layer.allSublayers.toArray().slice(0).reverse();
    sublayers = sublayers.filter(sublayer => {
      const minOk = sublayer.minScale === 0 || scale <= sublayer.minScale;
      const maxOk = sublayer.maxScale === 0 || scale >= sublayer.maxScale;
      return sublayer.visible && minOk && maxOk;
      //return sublayer.popupTemplate && sublayer.popupEnabled && sublayer.visible && minOk && maxOk;
    });
    sublayers.forEach(sublayer => {
      //console.log("sublayer",sublayer);
      const source = categories.findSourceByLayer(sublayer);
      let popupEnabled = sublayer.popupEnabled;
      if (popupEnabled && !sublayer.popupTemplate) popupEnabled = false;
      if (source || (allowNonAiim && popupEnabled)) {
        const query = sublayer.createQuery();

        if (floors && sublayer.floorInfo && sublayer.floorInfo.floorField) {
          let f = sublayer.floorInfo.floorField;
          let fld = aiimUtil.findField(sublayer.fields,f);
          if (fld) f = fld.name;
          let w = f + " IN ("+floors.join(",") + ")";
          if (query.where && query.where !== "1=1") {
            w = "((" + query.where + ") AND ("+w+"))";
          }
          query.where = w;
        }

        const tolerance = calculateTolerance(sublayer,sublayer.renderer);
        //console.log(sublayer.title,tolerance)
        //query.geometry = layer.createFetchPopupFeaturesQueryGeometry(mapPoint,tolerance);
        //query.geometry = popupUtils2D.createQueryGeometry(mapPoint,tolerance,view);
        query.geometry = createQueryGeometry(view,point,tolerance);
        //query.outFields = sublayer.get("popupTemplate.requiredFields");
        query.outFields = ["*"];
        //console.log(sublayer.title,query.where,"definitionExpression",sublayer.definitionExpression,sublayer);
        const promise = sublayer.queryFeatures(query).then(result => {
          //console.log("****** sublayer.queryFeatures",result);
          if (result && result.features && result.features.length > 0) {
            // let oidField = source.getObjectIdField();
            // let objectIds = [];
            // result.features.forEach(feature => {
            //   let objectId = aiimUtil.getAttributeValue(feature.attributes,oidField);
            //   objectIds.push(objectId);
            // });
            if (!source && allowNonAiim) {
              result.features.forEach(feature => {
                feature.layer = sublayer;
              });
            }
            return {
              mapServiceBased: true,
              source: source,
              layer: sublayer,
              features: result.features,
              queryResult: result
            }
          } else {
            return null;
          }
        });
        promises.push(promise);
      }
    });
  }
  if (promises.length > 0) {
    const promise = new Promise((resolve,reject) => {
      Promise.all(promises).then(qresults => {
        //console.log("queryMapService.qresults",qresults);
        const results = [];
        if (qresults) {
          qresults.forEach(qresult => {
            if (qresult && (qresult.source || allowNonAiim)) {
              results.push(qresult);
            }
          });
        }
        resolve(results);
      }).catch(ex => {
        console.warn("Error hitTest::queryMapService");
        console.error(ex);
        resolve();
      });
    });
    return promise;
  }
  return Promise.resolve();
}

function querySceneView(view,event,allowNonAiim) {
  const promise = new Promise((resolve,reject) => {
    view.hitTest(event.screenPoint).then(response => {
      //console.log("querySceneView.hitTest.response",response);
      const results = [];
      if (response && response.results && response.results.length > 0) {
        const categories = Context.getInstance().aiim.datasets.categories;
        response.results.forEach(qresult => {
          if (qresult && qresult.graphic && qresult.graphic.layer && qresult.graphic.layer.title) {
            //console.log("***",qresult.graphic.layer.title,qresult.graphic.layer);
            const source = categories.findSourceByLayer(qresult.graphic.layer);
            //console.log("source",source);
            const popupEnabled = (qresult.graphic.layer.popupEnabled  &&
                                  !!qresult.graphic.layer.popupTemplate);
            if (source || (allowNonAiim && popupEnabled)) {
              results.push({
                sceneViewBased: true,
                source: source,
                layer: qresult.graphic.layer,
                features: [qresult.graphic],
                hitResult: qresult
              });
            }
          }
        });
      }
      resolve(results);
    }).catch(ex => {
      console.warn("Error hitTest::querySceneView");
      console.error(ex);
      resolve();
    });
  });
  return promise;
}

function queryView(view,event,allowNonAiim) {
  if (view.type === "2d") {
    const screenPoint = event.screenPoint;
    if (!view.ready || isNaN(screenPoint.x) || isNaN(screenPoint.y)) {
      return Promise.resolve();
    }
    return queryLayerViews(view,event,allowNonAiim);
  } else {
    return querySceneView(view,event,allowNonAiim);
  }
}

export function search(view, event, allowNonAiim) {
  //console.log("hitTest.search",Date.now())
  const hits = [];
  const promise = new Promise((resolve,reject) => {
    queryView(view,event,allowNonAiim).then(qresults => {
      //console.log("hitTest::search.queryView.results",qresults);
      if (qresults && qresults.length > 0) {
        let qresult = qresults[0];

        // peek for a graphic above the facilities layer
        if (view.type === "2d" && qresult.isFacilityFootprint && qresults.length > 1) {
          let q2 = qresults[1];
          if (q2 && q2.layer && q2.layer.xtnAboveFacilities) {
            //console.log("resetting hit to ",q2.layer.title,q2);
            qresult = q2;
          }
        }

        let hit = makeHit(view,qresult,event);
        //console.log("hit",hit);
        if (hit.isNonAiimFeature) {
          if (qresult.features && qresult.features.length > 0) {
            if (qresult.mapServiceBased || !hit.featureLayer) {
              hit.feature = qresult.features[0];
              hit.searchResult = {feature: qresult.features[0]}
              hits.push(hit);
            } else {
              return queryFeatureNoSource(hit,view).then(() => {
                 if (hit.feature) {
                   hit.feature.layer = hit.featureLayer;
                   hit.searchResult = {feature: hit.feature}
                   hits.push(hit);
                 }
              });
            }
          }
        } else if (hit.featureLayer) {
          return queryFeature(hit,view).then(() => {
            //console.log("hitTest::queryFeature",hit);
            if (hit.feature) hits.push(hit);
          });
        }
      }
    }).then(() => {
      resolve(hits);
    }).catch(ex => {
      //console.warn("Error hitTest::search");
      //console.error(ex);
      reject(ex);
    });
  });
  return promise;
}
