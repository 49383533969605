import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";

// 3rd party
import Loader from 'calcite-react/Loader';
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';
import XIcon from 'calcite-ui-icons-react/XIcon';
import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleIcon";
import BookingCancel from '../More/Actions/BookWorkspace/BookingCancel';
import PencilIcon from "calcite-ui-icons-react/PencilIcon";
import UserIcon from 'calcite-ui-icons-react/UserIcon';
import { getMeetingBookingSystem, getMeetingBookingSystemType} from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';

// aiim.base
import ItemReference from '../../../aiim/base/ItemReference';

// aiim.datasets
import FieldNames from '../../../aiim/datasets/FieldNames';

// aiim.util
import { findFieldName, getAttributeValue, getRecurrencePattern } from '../../../aiim/util/aiimUtil';

// common.components.Modal
import { ModalController } from '../../../common/components/Modal';

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import { cancelBooking } from '../More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface';
import * as workspaceReservationUtil from '../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil';
import * as reservationsLayerUtil from '../More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil';

// components.main.Events
import * as dateUtil from '../Events/dateUtil';

// components.util
import * as component from '../../util/component';

// context
import Context from '../../../context/Context';
import Topic from '../../../context/Topic';

// util
import { BookingType, makeEventInfo } from '../../../util/calendarUtil';
import CalendarSelector from '../../../util/CalendarSelector';
import Icons from "../../util/Icons";
import { isNetworkError } from '../../../util/networkUtil';
import { stringFormatter } from '../../../util/formatUtil';
import RecurrenceIcon from 'calcite-ui-icons-react/RecurrenceIcon';
import { IBookingData } from '../More/Actions/BookWorkspace/WorkspaceReservation/BookingSystem';
import { getRecurrenceSeries, IBookingsProps } from '../More/Actions/BookWorkspace/MyBookings';
import { IRecurrenceOptions, IRecurrenceSeries } from '../More/Actions/BookWorkspace/BookingRecurrence';
import RecurrenceDropdown, { RecurrenceType } from '../More/Actions/BookWorkspace/RecurrenceDropdown';

import { escSqlQuote } from '../../../aiim/util/selectionUtil';

import CheckIn from "../More/Actions/BookWorkspace/CheckIn";
import CheckOut from "../More/Actions/BookWorkspace/CheckOut";
import { BOOKING_STATE } from "../More/Actions/BookWorkspace/WorkspaceReservation/EsriReservationSchema";
import * as checkInOutUtil from "../More/Actions/BookWorkspace/CheckInOutUtil";

const CSS = {
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  bookingInfoLine: "i-booking-info-line",
  locationDescription: "i-booking-location-description",
  flexRow: "i-flex-row",
  addToCalendar: "i-booking-add-calendar",
  cancel: "i-booking-cancel",
  edit: "i-booking-edit",
  popoutInner: "i-popout-inner i-meeting-cancel-confirmed",
  popoutOuter: "i-popout-outer",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  cancelConfirmed: "i-meeting-cancel-confirmed-body",
  meetingTitle: "i-meeting-cancel-confirmed--title",
  actionGroup: "i-action-group",
  bookingDescription: "i-booking-description",
  recurrenceIcon: "i-recurrence-icon"
};

interface IMyMeetingRoomsState {
  bookings: IBookingData[],
  isWorking: boolean,
  series?: Map<string, IRecurrenceSeries>
}
export default class MyMeetingRooms extends React.Component<IBookingsProps, IMyMeetingRoomsState> {

  remindersShown = {
    checkIn: false,
    checkOut: false
  };

  updateICSfile = false;

  constructor(props: IBookingsProps) {
    super(props);
    this.state = component.newState({
      bookings: [],
      isWorking: true
    });
  }

  componentDidMount() {
    this._getUserReservations(true);
    component.own(this, [
      Topic.subscribe(Topic.RenderMeetingRooms, (origResAttr) => {
        this.setState({isWorking: true})
        this.updateICSfile=true;
        this._getUserReservations();
      }),
      Topic.subscribe(Topic.RenderBookingList, () => {
        this.setState({isWorking: true})
        this.updateICSfile=true;
        this._getUserReservations();
      }),
      Topic.subscribe(Topic.ReloadWorkspace, () => {
        this.setState({isWorking: true})
        this.updateICSfile=true;
        this._getUserReservations();
      })
    ])
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookingDateFilter !== this.props.bookingDateFilter) {
      this.setState({isWorking: true});
      this._getUserReservations();
    } else if (prevProps.listingType !== this.props.listingType) {
      this.setState({isWorking: true});
      this.remindersShown = {
        checkIn: false,
        checkOut: false
      }
      this._getUserReservations(true);
    }
  }
  
  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  checkReminders() {
    /* Disabled
    // Do not show check in prompt when Booked tab is activated #8191
    if (!checkInOutUtil.allowMeetingRoomCheckin()) return;
    const { bookings } = this.state;
    const { listingType } = this.props;
    const sortedBookings = this._sortBookings(bookings);
    const ltype = (listingType === "others") ? "otherbookings" : "mybookings"
    const reminders = checkInOutUtil.getRemindersList(sortedBookings,"mybookings");
    if (reminders && reminders.checkIn && reminders.checkIn.length > 0) {
      if (!this.remindersShown["checkIn"]) {
        checkInOutUtil.showReminderPopup("checkIn",reminders,ltype,"meetingRoom");
        this.remindersShown["checkIn"] = true;
      }
    } else if (reminders && reminders.checkOut && reminders.checkOut.length > 0) {
      if (!this.remindersShown["checkOut"]) {
        checkInOutUtil.showReminderPopup("checkOut",reminders,ltype,"meetingRoom");
        this.remindersShown["checkOut"] = true;
      }
    }
    */
  }

  _getUserReservations = async (init?: boolean) => {
    const { listingType } = this.props;
    const reservations = Context.getInstance().aiim.datasets.reservations;
    if (!reservations) {
      this.setState({ isWorking: false });
      return;
    }

    // Get logged in username
    const username = Context.getInstance().user.getUsername();
    const { reservedByUsernameField, reservedForUsernameField, endTimeField, stateField } = reservations;

    if (!username || !reservedForUsernameField || !endTimeField || !stateField) {
      this.setState({ isWorking: false });
      return;
    }

    try {
      const now = dateUtil.getZulu(new Date());
      let whereClauses = [];
      if (listingType === "self") {
        whereClauses.push(`(${reservedForUsernameField} = '${escSqlQuote(username)}')`);
      } else {
        whereClauses.push(`(${reservedByUsernameField} = '${escSqlQuote(username)}')`);
        whereClauses.push(`(${reservedForUsernameField} <> '${escSqlQuote(username)}')`);
      }
      whereClauses.push(`(${endTimeField} > TIMESTAMP '${now}')`);
      whereClauses.push(`(${stateField} IN (1,4))`);
      if (this.props.bookingDateFilter && this.props.bookingDateFilter.where) {
        whereClauses.push(this.props.bookingDateFilter.where);
      }
      const myReservations = await reservations.query(whereClauses.join(" AND "));
      const bookings = (myReservations && myReservations.features) || [];
      const meetingRoomBookings = await this._filterMeetingReservations(bookings, reservations.hasRecurrenceFields());
      const series = getRecurrenceSeries(meetingRoomBookings);

      this.setState({
        bookings: meetingRoomBookings,
        isWorking: false,
        series
      }, () => {
        if (init) this.checkReminders();
      });
      return;
    } catch(e) {
      console.error("Unable to query for user reservations", e);
      this.setState({
        bookings: [],
        isWorking: false
      });

      if (e && isNetworkError(e.message)) {
        const i18n = Context.getInstance().i18n;
        Topic.publishNetworkError(i18n.meetingRooms.issues.m7);
      } else {
        Topic.publishErrorAccessingData();
      }
    }
  }

  _filterMeetingReservations = async (bookings: __esri.Graphic[], hasRecurrenceFields: boolean) => {
    if (!bookings || !bookings.length) {
      return [];
    }

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const unitFields = unitsDataset.layer2D && unitsDataset.layer2D.fields;
    const meetingRoomBookings = [];

    // Gather the unit IDs from the reservations to make a query
    const unitIds = bookings
      .map((booking) => {
        const bookingAttrs = booking.attributes;
        const unitId = getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
        return `'${unitId}'`;
      });
    // To make sure we don't add duplicate ids to the query
    // Ex: UNIT_ID IN ('1', '1', '2', '2') -> UNIT_ID IN ('1', '2')
    const uniqueIds = [...new Set(unitIds)];

    const whereClauses = [
      `${unitsDataset.uidField} IN (${uniqueIds.join(",")})`,
    ];

    const reservationMethodField = findFieldName(unitFields, FieldNames.RESERVATION_METHOD);
    const areaIdField = findFieldName(unitFields, FieldNames.AREA_ID);
    const asnField = findFieldName(unitFields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);

    if (areaIdField && reservationMethodField) {
      // backwardly compatible meeting rooms
      let whereB = `(${reservationMethodField} = 1 OR ${reservationMethodField} = 2) AND (${areaIdField} = '' OR ${areaIdField} IS NULL)`;
      if (asnField) {
        whereB += ` AND (${asnField} <> 'office' OR ${asnField} IS NULL)`;
        const whereA = `(${asnField} = 'meeting room')`;
        whereClauses.push(`(${whereA} OR (${whereB}))`)
      } else {
        whereClauses.push("("+whereB+")")
      }
    }

    const where = `${whereClauses.join(" AND ")}`;

    try {
      const results = await unitsDataset.query(where);
      const units = (results && results.features) || [];
      const meetingRoomUnitIds = units.map((unit) => getAttributeValue(unit.attributes, FieldNames.UNIT_ID));

      // Find the bookings that have meeting room unit IDs
      bookings.forEach((booking) => {
        const bookingAttrs = booking.attributes;
        const unitId = getAttributeValue(bookingAttrs, FieldNames.UNIT_ID);
        if (meetingRoomUnitIds.includes(unitId)) {
          const unit = units.find((unit) => getAttributeValue(unit.attributes, FieldNames.UNIT_ID) === unitId)
          const meetingRoomBooking: IBookingData = { booking, unit };
          if (hasRecurrenceFields) {
            if (reservationsDataset) reservationsDataset.fixMeetingRoomEndDate(booking);
            const id = getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_ID);
            const config = getAttributeValue(bookingAttrs, FieldNames.RECURRENCE_CONFIG);
            if (id && config?.length > 0) {
              meetingRoomBooking.recurrenceId = id;
            }
          }
          meetingRoomBookings.push(meetingRoomBooking);
        }
      });
    } catch(e) {
      console.error(e);
    }

    return meetingRoomBookings;
  }

  _sortBookings = (bookings) => {
    return bookings.sort((resA, resB) => {
      const attrsA = resA.booking.attributes;
      const attrsB = resB.booking.attributes;

      const startTimeA = getAttributeValue(attrsA, FieldNames.START_TIME);
      const startTimeB = getAttributeValue(attrsB, FieldNames.START_TIME);

      return startTimeA > startTimeB ? 1 : startTimeA < startTimeB ? -1 : 0;
    });
  }

  _makeBookingList = (bookings) => {
    const sortedBookings = this._sortBookings(bookings);
    const { series } = this.state;
    return sortedBookings.map((data, i) => {
      const reservation = data.booking;
      const recurrenceId = getAttributeValue(reservation.attributes, FieldNames.RECURRENCE_ID);
      const recurrenceConfig = getRecurrencePattern(reservation.attributes);
      const isOccurrenceModified = recurrenceConfig?.modified === "occurrence"
      const isRecurring = series?.has(recurrenceId);
      return (
        <MyMeetingRoomsCard
          key={i}
          data={data}
          refresh={this._refreshList}
          updateICSfile={this.updateICSfile}
          isRecurring={isRecurring}
          isOccurrenceModified={isOccurrenceModified}
          series={series?.get(recurrenceId)}
          listingType={this.props.listingType}
        />
      );
    });
  }

  _refreshList = () => {
    this.setState({ isWorking: true });
    this._getUserReservations();
  }

  renderBookingCards() {
    const i18n = Context.getInstance().i18n;
    const { bookings } = this.state;
    const bookingList = this._makeBookingList(bookings);

    return bookingList && bookingList.length > 0 ? (
      <ul className={CSS.bookingCardList}>
        {bookingList}
      </ul>
    ) : (
      <div style={{ padding: '0.7rem 1rem' }}>{i18n.meetingRooms.noBookings}</div>
    );
  }

  renderWorking() {
    return (<Loader sizeRatio={1} style={{ marginTop: '3rem' }} />);
  }

  render() {
    const { isWorking } = this.state;
    return isWorking ? this.renderWorking() : this.renderBookingCards();
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
interface IMyMeetingRoomsCardProps {
  data: IBookingData,
  isRecurring: boolean,
  isOccurrenceModified?: boolean,
  series?: IRecurrenceSeries,
  refresh: () => void,
  updateICSfile: boolean,
  listingType?: "self" | "others"
}
interface IMyMeetingRoomsCardState {
  popoverOpen: boolean,
  unitFeature: __esri.Graphic,
  popoverType?: "calendar" | "cancel" | "edit"
}
class MyMeetingRoomsCard extends React.Component<IMyMeetingRoomsCardProps, IMyMeetingRoomsCardState> {
  _componentId;
  _itemRef;

  constructor(props: IMyMeetingRoomsCardProps) {
    super(props);
    this.state = component.newState({
      popoverOpen: false
    });
    this._componentId = component.nextId("meeting-room-");
    this._itemRef = React.createRef();
  }

  async componentDidMount() {
    const booking = this.props.data && this.props.data.booking;
    const unitId = getAttributeValue(booking.attributes, FieldNames.UNIT_ID);
    const fs = await workspaceReservationUtil.queryUnitByUnitId(unitId);
    const unitFeature = fs?.features?.[0];
    if (unitFeature) {
      this.setState({
        unitFeature: unitFeature
      })
    }
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus();
        }
      })
    ]);
  }

  private get recurrence(): IRecurrenceOptions {
    const { data: { booking: { attributes } } } = this.props;
    return getRecurrencePattern(attributes);
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus();
    }
  }

  _makeItem = () => {
    const { data } = this.props;
    const { unit } = data;
    const units = Context.getInstance().aiim.datasets.units
    const source = units.getSource();

    const item = new ItemReference();
    item.fromSearchResult(source.key, { feature: unit, name: source.getTitle(unit) });
    return item;
  }

  _bookingCardClicked = () => {
    const item = this._makeItem();
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: item.sourceKey,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      isMeetingRoomsPanel: true,
      componentId: this._componentId
    });
  }

  _cancelClicked = (event, type?: RecurrenceType) => {
    // Prevent the click event from propagating to the card
    if(event) event.stopPropagation();
    this._showCancelPopup(false, type);
  }

  _showCancelPopup = (retry, type?) => {
    const bookingSystem = getMeetingBookingSystem();
    const i18n = Context.getInstance().i18n;
    const { data: { booking: { attributes } = {} }, series, isRecurring } = this.props;
    const reservation = this.props.data.booking;

    const bookings = (isRecurring && type!== "occurrence") 
                      ? series.occurrences.map(s => s.attributes) : [reservation.attributes];

    const unitName = getAttributeValue(attributes,FieldNames.UNIT_NAME);
    let msg = i18n.meetingRooms.cancel.message;
    if (unitName) {
      msg = i18n.meetingRooms.cancel.messagePattern.replace("{unitName}",unitName);
    }

    if (isRecurring) {
      msg = type === "occurrence" ? i18n.meetingRooms.cancel.messageOccurrence : i18n.meetingRooms.cancel.messageSeries;
      if (unitName) {
        msg = type === "occurrence" ? i18n.meetingRooms.cancel.messagePatternOccurrence : i18n.meetingRooms.cancel.messagePatternSeries;
        msg = msg.replace("{unitName}", unitName);
      }
    }
    
    const message = retry
      ? i18n.meetingRooms.cancel.retry
      : msg;

    const options = {
      title: i18n.meetingRooms.cancel.modalTitle,
      message,
      okLabel: i18n.general.yes,
      cancelLabel: i18n.more.bookWorkspace.no,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    };

    ModalController.confirm(options)
      .then((result) => {
        // User chose to continue canceling the meeting, proceed
        if (result && result.ok) {
          cancelBooking(bookingSystem, bookings)
            .then(() => {
              // Refresh the list of booked meetings
              if (typeof this.props.refresh === "function") {
                this.props.refresh();
              }
              // Show a confirmation
              this._renderCancelConfirmed(attributes, type);
            })
            .catch((e) => {
              console.error("Couldn't cancel meeting", e);

              if (e && isNetworkError(e.message)) {
                const i18n = Context.getInstance().i18n;
                const template = i18n.meetingRooms.issues.m9;
                const message = stringFormatter(template, { unit: unitName });
                Topic.publishNetworkError(message);
              } else {
                Topic.publishErrorUpdatingData();
              }

              // Give the user an option to try again
              this._showCancelPopup(true);
            });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  _renderCancelConfirmed(attributes, type: RecurrenceType) {
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);   
    const node = document.createElement("div");
    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    document.body.appendChild(node);
    ReactDOM.render(
      <BookingCancel 
        bookingSystemType={getMeetingBookingSystemType()}
        bookingType={type}
        unitName={unitName}
        closePopup={onClose}
        data={this.props.data}        
      />, node);
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;
    if (event.keyCode !== ENTER && event.keyCode !== SPACE) {
      return;
    }

    // Prevent propagation from children keyboard events
    const dataId = event.target.getAttribute("data-id");
    if (dataId === "card") {
      event.preventDefault();
      this._bookingCardClicked();
    }
  }

  _togglePopover = (type?: IMyMeetingRoomsCardState["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }

  getReserveForInfo(unitFeature,reservationFeature) {
    let reserveForInfo = null;
    if (this.props.listingType === "others") {
      if (unitFeature && reservationFeature) {
        const areaId = getAttributeValue(unitFeature.attributes, FieldNames.AREA_ID)
        const reservedForUsername = getAttributeValue(reservationFeature.attributes, FieldNames.RESERVED_FOR_USERNAME);
        const reservedForFullname = getAttributeValue(reservationFeature.attributes, FieldNames.RESERVED_FOR_FULL_NAME);
        reserveForInfo = {
          areaIDs: [areaId],
          email: null,
          occupantFeature: null,
          username: reservedForUsername,
          fullName: reservedForFullname
        };
      }
    }
    return reserveForInfo;
  }

  _getRecurrenceIcon(mStartTime, mEndTime, isAllDay) { 
    const { isRecurring, isOccurrenceModified } = this.props;
    if (isRecurring) {
      const title = workspaceReservationUtil.formatRecurringDates(this.recurrence, mStartTime, mEndTime, isAllDay);
      return isOccurrenceModified
        ? <span className={CSS.recurrenceIcon}>{Icons.noRecurrence()}</span>
        : <span className={CSS.recurrenceIcon} title={title}><RecurrenceIcon size={16}/></span>
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { data, isRecurring, listingType } = this.props;
    const { booking, unit } = data;
    const { popoverOpen, popoverType } = this.state;
    const reservations = Context.getInstance().aiim.datasets.reservations;
    const levels = Context.getInstance().aiim.datasets.levels;
    const attributes = booking.attributes;
    const floorField = reservations.levelIdField;

    // Field values to display
    const title = getAttributeValue(attributes, FieldNames.TITLE);
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);
    const reservedFor = getAttributeValue(attributes, reservations.reservedForFullNameField);

    // Level/Facility info
    const levelId = getAttributeValue(attributes, floorField);
    const levelData = levels.getLevelData(levelId);
    const { levelName, facilityName } = levelData;
    const description = `${unitName}, ${levelName}, ${facilityName}`;

    // Format a date string from the start and end times
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    const isAllDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
    const formattedDate = workspaceReservationUtil.formatDate(mStartTime, mEndTime, isAllDay);

    const addToCalendarButton = (
      <button
        className={CSS.addToCalendar}
        data-id={"calendar"}
        onClick={() => this._togglePopover("calendar")}
      >
        {Icons.calendarPlus()}
        {i18n.general.add}
      </button>
    );

    const editButton = (
      <button
        className={CSS.edit}
        data-id={"edit"}
        onClick={() => isRecurring ? this._togglePopover("edit") : this._scheduleClicked()}
      >
        <PencilIcon />
        {i18n.more.bookWorkspace.checkInOut.rescheduleBooking.caption}
      </button>
    )

    let cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={(event) => isRecurring ? 
                this._togglePopover("cancel") : this._cancelClicked(event)}
      >
        <XIcon />
        {i18n.general.cancel}
      </button>
    )

    let checkInButton = null, checkOutButton = null;
    if (checkInOutUtil.allowMeetingRoomCheckin()) {
      if (checkInOutUtil.showCheckInButton(attributes)) {
        checkInButton = (
          <CheckIn 
            type={(listingType === "others") ? "otherbookings" : "self"}
            bookingType="meetingRoom"
            attributes={attributes}
          />
        )
      } else if (checkInOutUtil.showCheckOutButton(attributes)) {
        cancelButton = null;
        checkOutButton = (
          <CheckOut 
            type={(listingType === "others") ? "otherbookings" : "self"}
            bookingType="meetingRoom"
            attributes={attributes}
          />
        )
      }
    }

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const calendarParams = makeEventInfo(reservationsDataset, booking, unitsDataset, unit);

    return (
      <li>
        <div className={CSS.bookingCard}>
          <div
            id={this._componentId}
            className={CSS.bookingDescription}
            ref={this._itemRef}
            role="button"
            tabIndex={0}
            data-id={"card"}
            onClick={this._bookingCardClicked}
            onKeyDown={this._onKeyDown}
          >
            <h3 className={CSS.bookingInfoLine}>
              <span style={{ flexGrow: 1 }}>{title}</span>
              {this._getRecurrenceIcon(mStartTime, mEndTime, !!isAllDay)}
            </h3>
            <div className={CSS.bookingInfoLine}>
              <ClockIcon />
              <h4>{formattedDate}</h4>
            </div>
            <div className={CSS.bookingInfoLine}>
              <PinIcon />
              <h4>{description}</h4>
            </div>
          </div>
          {(listingType === "others") &&
            <div className={CSS.bookingDescription}>
              <div className={CSS.bookingInfoLine} style={{marginTop: "0"}}>
                <UserIcon />
                <h4>
                  <a href="#" className="i-meeting-occupant-link"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      reservationsLayerUtil.showOccupantInfo(booking)
                    }}
                  >{reservedFor}</a>
                </h4>
              </div>
            </div>
          }
          <div className={CSS.actionGroup}>
            {checkInButton}
            {checkOutButton}
            {(listingType === "self") &&
              <CalendarSelector
                onRequestClose={this._togglePopover}
                open={popoverOpen && popoverType === "calendar"}
                targetEl={addToCalendarButton}
                params={calendarParams}
                reservation={booking}
                _updateICSfile={this.props.updateICSfile}
              />
            }
            {<RecurrenceDropdown
              type="edit"
              open={popoverOpen && popoverType === "edit"}
              targetEl={editButton}
              onRequestClose={this._togglePopover}
              onSelection={(type) => {
                this._scheduleClicked(type)
                this._togglePopover();
              }} />
            }
            {cancelButton &&
              <RecurrenceDropdown
                type="cancel"
                open={popoverOpen && popoverType === "cancel"}
                targetEl={cancelButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._cancelClicked(null, type);
                  this._togglePopover();
              }} />
            }
          </div>
        </div>
      </li>
    );
  }

  _rescheduleBooking = async(when, isOngoingBooking, recurrenceType)=> {
    const unit = this.props && this.props.data && this.props.data.unit;
    const resFeature = this.props && this.props.data && this.props.data.booking;
    let objectIdField;
    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }
    const reserveForInfo = this.getReserveForInfo(unit,resFeature);
    const details = {
      when,
      isOngoingBooking,
      unitAttr: unit && unit.attributes,
      unitFeature: this.state.unitFeature,
      originalReservation: { attributes: resFeature && resFeature.attributes, booking: null },
      selfBooking: true,
      bookingType: "meetingRooms",
      recurrenceType: recurrenceType,
      objectID: [getAttributeValue(resFeature.attributes, objectIdField)],
      series: this.props.series,
      reserveForInfo
    };
    workspaceReservationUtil.rescheduleBooking(details);
  }

  _scheduleClicked =async(type?: RecurrenceType)=> {
    const { data: { booking, unit }, isRecurring, series } = this.props;
    const { unitFeature } = this.state;
    const recurrence = series?.options;
    let objectIdField, details;
    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }
    const reserveForInfo = this.getReserveForInfo(unitFeature,booking);
    const state = getAttributeValue(booking.attributes, FieldNames.STATE);
    const isOngoingBooking = (state === BOOKING_STATE.CHECKED_IN);
    if (isRecurring && type!=="occurrence") {
      // if it is a series edit
      const allDay = getAttributeValue(booking.attributes, FieldNames.ALL_DAY);
      const objectIds = series.occurrences.map(o => getAttributeValue(o.attributes, objectIdField));
      const when: dateUtil.IDuration = {
        start: { date: series.checkIn },
        end: { date: series.checkOut },
        duration: allDay ? "allDay" : null,
        recurrence: recurrence
      };
      let duration = when.duration === "allDay" ? when.duration : dateUtil.determineDurationKey(when);
      const selectedWhen = {
        duration: duration,
        start: { date: when.start.date },
        end: { date: when.end.date },
        originalReservation: booking,
        recurrence: when.recurrence,
        isOngoingBooking
      };
      details = {
        when: selectedWhen,
        unitAttr: unit?.attributes,
        unitFeature: unitFeature,
        selfBooking: true,
        bookingType: "meetingRooms",
        originalReservation: { attributes: booking?.attributes, booking: null },
        recurrenceType: "series",
        reserveForInfo,
        objectID: objectIds,
        series: series,
        isOngoingBooking
      };
      workspaceReservationUtil.rescheduleBooking(details);
    } else {
      details = {
        reservation: booking,
        rescheduleBooking: this._rescheduleBooking,
        operation: "updateBookingTime",
        bookingType: "meetingRooms",
        recurrenceType: type == null ? "single" : type,
        reserveForInfo,
        series
      }
      workspaceReservationUtil.showScheduleViewEsri(details);
    }
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class CancelConfirmed extends React.Component<{
  attributes: Record<string, any>,
  closePopup: () => void
}> {
  closeRef: MutableRefObject<HTMLButtonElement>;
  render() {
    const { attributes } = this.props;
    const i18n = Context.getInstance().i18n;
    const meetingTitle = getAttributeValue(attributes, FieldNames.TITLE);

    return (
      <div className={CSS.popoutOuter}>
        <div className={CSS.popoutInner}>
          <div className={CSS.panelHeader}>
            <div className={CSS.flexRow}>
              <h2>{i18n.meetingRooms.cancel.success}</h2>
            </div>
            <div className={CSS.panelHeaderTools}>
              <button
                ref={this.closeRef}
                title={i18n.general.close}
                aria-label={i18n.infoPanel.closeAriaLabel}
                onClick={this.props.closePopup}
              >
                {Icons.close()}
              </button>
            </div>
          </div>
          <div className={CSS.cancelConfirmed}>
            <CheckCircleIcon color="green" />
            <p>{i18n.meetingRooms.cancel.confirmation}</p>
            <p className={CSS.meetingTitle}>{meetingTitle}</p>
          </div>
        </div>
      </div>
    );
  }
}
