import moment, { Moment } from "moment";
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import Rdx from "../../../../../../redux/Rdx";
import { ModalController } from "../../../../../../common/components/Modal";

// aiim.base
import ClosestFacility from "../../../../../../aiim/base/ClosestFacility";

// aiim.datasets
import FieldNames from "../../../../../../aiim/datasets/FieldNames";

// aiim.util
import { getAttributeValue, getRecurrencePattern } from "../../../../../../aiim/util/aiimUtil";

// context
import Context from "../../../../../../context/Context";

// main.InfoPanel
import { generateShareUrlAsync } from "../../../../InfoPanel/shareUtil";
import * as projectionUtil from "../../../../../../util/projectionUtil";

// main.More.Actions.BookWorkspace.WorkspaceReservation
import EsriReservationSchema, { BOOKING_STATE } from "./EsriReservationSchema";
import Office365, { IIndoorsEvent } from "./Office365";

// util
import { stringFormatter } from "../../../../../../util/formatUtil";

import * as whereUtil from "../../BookWorkspace/whereUtil";
import * as aiimUtil from "../../../../../../aiim/util/aiimUtil";
import * as selectionUtil from "../../../../../../aiim/util/selectionUtil";
import * as dateUtil from "../../../../Events/dateUtil";
import * as validateUtil from "../validateUtil";
import BookingDetails from "../BookingDetails";
import ItemReference from "../../../../../../aiim/base/ItemReference";

import Schedule from "../../../../MeetingRooms/Schedule";
import Book from "../../../../MeetingRooms/Book";
import { getBookings } from "./OfficeHotelingInterface";
import { IBookingSystem, IBookingTask, ICommonBookingProps } from "./BookingSystem";
import { IRecurrenceOptions, getRecurringDates } from "../BookingRecurrence";
import type Events from "../../../../../../aiim/datasets/Events";
import { IFeature } from "@esri/arcgis-rest-types";
import { generateRandomUuid } from "../../../../../../util/val";
import { IMeetingRoom, IMeetingRoomCriteria } from "../../../../MeetingRooms/MeetingRoomsModel";
import { IBookingDateFilter } from "../../../../Events/BookingDateFilter";
import { isSameDay } from "react-dates";
import type { CustomQueryTask } from "../../../../../../context/EsriLib";

const MAX_NETWORK_SORT = 10;
const CHECKED_IN = 4;


export function findReservationsLayer(layers){
  if(!layers) return;

  for(let i=0;i<layers.length;i++) {
    const fields = layers[i].fields;
    const levelIdField = Context.instance.aiim.getLevelIdField(layers[i]);

    if(layers[i].geometryType === "point") {}
    else if(aiimUtil.findField(fields,FieldNames.RESERVED_FOR_USERNAME) &&
          aiimUtil.findField(fields,FieldNames.RESERVED_FOR_FULL_NAME) &&
          aiimUtil.findField(fields,FieldNames.STATE) &&
          aiimUtil.findField(fields,FieldNames.START_TIME) &&
          aiimUtil.findField(fields,FieldNames.END_TIME) &&
          aiimUtil.findField(fields,FieldNames.CHECK_IN_TIME) &&
          aiimUtil.findField(fields,FieldNames.CHECK_OUT_TIME) &&
          aiimUtil.findField(fields,FieldNames.TITLE) &&
          aiimUtil.findField(fields, FieldNames.ALL_DAY) &&
          aiimUtil.findField(fields,FieldNames.DESCRIPTION) &&
          aiimUtil.findField(fields,FieldNames.UNIT_ID) &&
          aiimUtil.findField(fields,FieldNames.UNIT_NAME) && levelIdField) {
        return layers[i];
    }
  }
}

export function hasReservationsLayer(layerId){
  let layer;
  const view = Context.getInstance().views.mapView;
  if(view) layer = view.map.findLayerById(layerId);
  if(!layer) return false;

  const fields = layer.fields;
  const levelIdField = Context.instance.aiim.getLevelIdField(layer);

  if(aiimUtil.findField(fields,FieldNames.RESERVED_FOR_USERNAME) &&
    aiimUtil.findField(fields,FieldNames.RESERVED_FOR_FULL_NAME) &&
    aiimUtil.findField(fields,FieldNames.STATE) &&
    aiimUtil.findField(fields,FieldNames.START_TIME) &&
    aiimUtil.findField(fields,FieldNames.END_TIME) &&
    aiimUtil.findField(fields,FieldNames.CHECK_IN_TIME) &&
    aiimUtil.findField(fields,FieldNames.CHECK_OUT_TIME) &&
    aiimUtil.findField(fields,FieldNames.TITLE) &&
    aiimUtil.findField(fields, FieldNames.ALL_DAY) &&
    aiimUtil.findField(fields,FieldNames.DESCRIPTION) &&
    aiimUtil.findField(fields,FieldNames.UNIT_ID) &&
    aiimUtil.findField(fields,FieldNames.UNIT_NAME) && levelIdField) {
      return true;
    }
  return false;
}

export function getWorkspaceInfo() {
  const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
  const people =  Context.instance.aiim.datasets && Context.instance.aiim.datasets.people;
  const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
  const areas = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas;
  let wsCfg = Context.getInstance().config.workspaceReservation;

  let info: any = {
    reservationsFieldsSupportsEsriHotel: null,
    reservationsFieldsSupportsEsriMeeting: null,
    isValidLicense: true,

    hasUnitsLayer: true,
    hasOccupantsLayer: true,
    hasReservationsLayer: null,
    hasAreasTable: null,

    reservationLayerId: null,
    reservationsLyrTitle: null,
    isLayerEditable: null,

    unitsHasAreaId: null,
    unitsHasReservationMethod : null,
    unitsHasScheduleEmail : null,
    appId: null,
    tenantId: null,
    isSingleTenant: false,

    areasHasAreaId: null,
    areasHasAreaType: null,
    areasHasAreaName: null,
    hasReqAreaFields: false,

    peopleHasFullName: null,
    peopleHasEmail: null
  };

  if (Context.instance.orgLicenseType !=="indoors" && Context.instance.orgLicenseType !=="indoorsspaces")
    info.isValidLicense = false;

  info.hasUnitsLayer = Context.instance.aiim.datasets && !!Context.instance.aiim.datasets.units 
                        && !!Context.instance.aiim.datasets.units.layer2D;
  info.hasOccupantsLayer = Context.instance.aiim.datasets && !!Context.instance.aiim.datasets.people 
                        && !!Context.instance.aiim.datasets.people.layer2D;
  info.hasAreasTable = Context.instance.aiim.datasets && !!Context.instance.aiim.datasets.areas 
                        && !!Context.instance.aiim.datasets.areas.table;

  const checkField = (fields, fieldName) => {
    return !!aiimUtil.findField(fields, fieldName);
  }

  const checkOccupantFields = (fieldName) => {
    const layer = people && people.layer2D;
    const fields = layer && layer.fields;
    if (!fields) {
      return false;
    }
    return checkField(fields, fieldName);
  }

  const checkReservationsFieldsEsri = () => {

    const layer = reservations && reservations.layer2D;
    const fields = layer && layer.fields;
    if (!fields) {
      return false;
    }
    let floorField = FieldNames.LEVEL_ID;
    if (layer.floorInfo && layer.floorInfo.floorField) {
      floorField = layer.floorInfo.floorField;
    }

    return (
      checkField(fields, FieldNames.RESERVED_FOR_USERNAME) &&
      checkField(fields, FieldNames.RESERVED_FOR_FULL_NAME) &&
      checkField(fields, FieldNames.START_TIME) &&
      checkField(fields, FieldNames.END_TIME) &&
      checkField(fields, FieldNames.CHECK_IN_TIME) &&
      checkField(fields, FieldNames.CHECK_OUT_TIME) &&
      checkField(fields, FieldNames.TITLE) &&
      checkField(fields, FieldNames.DESCRIPTION) &&
      checkField(fields, FieldNames.STATE) &&
      checkField(fields, FieldNames.UNIT_ID) &&
      checkField(fields, FieldNames.UNIT_NAME) &&
      checkField(fields, FieldNames.ALL_DAY) &&
      checkField(fields, floorField)
    );
  }

    info.reservationsFieldsSupportsEsriHotel = reservations && checkReservationsFieldsEsri();
    info.reservationsFieldsSupportsEsriMeeting = reservations && checkReservationsFieldsEsri();
    info.peopleHasFullName = people && checkOccupantFields(FieldNames.PEOPLE_FULLNAME);
    info.peopleHasEmail = people && checkOccupantFields(FieldNames.PEOPLE_EMAIL);

    let layerId = wsCfg.reservationLayerId;
    const view = Context.instance.views.mapView;
    const layers = aiimUtil.getLayers(view).items;
    let reservationsLayer;
    
    if (layerId) {
      if (hasReservationsLayer(layerId)) {
        reservationsLayer = view.map.findLayerById(layerId);
      }
    }
    if (!reservationsLayer) {
      reservationsLayer = findReservationsLayer(layers);
    }
  
    if (reservationsLayer) {
      info.isLayerEditable = isLayerEditable(reservationsLayer);
      info.hasReservationsLayer = true;
      info.reservationLayerId = reservationsLayer.id;
      info.reservationsLyrTitle = reservationsLayer.title;
      info.reservationsLayer = reservationsLayer;
    }
  
    const unitsLayer = units && units.layer2D;
    const unitsFields = unitsLayer && unitsLayer.fields;
    info.unitsHasAreaId = !!aiimUtil.findField(unitsFields,FieldNames.UNITS_AREA_ID);  
    info.unitsHasReservationMethod = !!aiimUtil.findField(unitsFields,FieldNames.RESERVATION_METHOD);
    info.unitsHasScheduleEmail = !!aiimUtil.findField(unitsFields,FieldNames.SCHEDULE_EMAIL);
    
    if (reservationsLayer && info.isLayerEditable && info.unitsHasAreaId && info.hasAreasTable 
        && (info.unitsHasScheduleEmail)) {
          info.canAutoEnableReservations = true;
    }
  
    if (!reservationsLayer) info.hasReservationsLayer = false;

    if (wsCfg.appId) info.appId = wsCfg.appId;
    if (wsCfg.tenantId) info.tenantId = wsCfg.tenantId;
    if (wsCfg.isSingleTenant) info.isSingleTenant = wsCfg.isSingleTenant;

    if (info.hasAreasTable) {
      const areasFields = areas && areas.table && areas.table.fields;
      info.hasAreaId = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_ID);
      info.hasAreaType = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_TYPE);
      info.hasAreaName = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_NAME);
      info.hasReqAreaFields = info.hasAreasTable && info.hasAreaId && info.hasAreaType && info.hasAreaName;
    }

  return info;
}

export function getMeetingBookingSystem() {
  const info = getWorkspaceInfo();
  const type = Context.getInstance().config.workspaceReservation.reservationTypeMeeting;
  const isMeetingToggleOn = Context.getInstance().config.workspaceReservation.enableMeeting;

  if (!info || !info.isValidLicense || !isMeetingToggleOn) return null;

  if (type === "office365" && info.hasUnitsLayer && info.unitsHasScheduleEmail) { //unitsSupportsHotel365
    return Office365.getInstance();
  } else if (type === "esri" && info.hasUnitsLayer && info.hasReservationsLayer && info.unitsHasReservationMethod){
    return EsriReservationSchema.getInstance();
  }
}

export function getHotelBookingSystem() {
  const info = getWorkspaceInfo();
  const type = Context.getInstance().config.workspaceReservation.reservationTypeHotel;
  const isHotelToggleOn = Context.getInstance().config.workspaceReservation.enableHotel;

  if (!info || !info.isValidLicense || !isHotelToggleOn) return null;

  if (type === "office365" && info.hasUnitsLayer && info.hasOccupantsLayer 
      && info.unitsHasScheduleEmail && info.peopleHasFullName && info.peopleHasEmail) { 
    return Office365.getInstance();
  } else if (type === "esri" && info.hasUnitsLayer && info.hasReservationsLayer && info.unitsHasAreaId){
    return EsriReservationSchema.getInstance();
  }
}

export function getMeetingBookingSystemType() {
  const bookingSystem = getMeetingBookingSystem();
  return bookingSystem?.type;
}

export function getHotelBookingSystemType() {
  const bookingSystem = getHotelBookingSystem();
  return bookingSystem?.type;
}

export async function getAreaName(areaId) {
  const matchedArea = await getArea(areaId);
  if (matchedArea) {
    const areaName = getAttributeValue(matchedArea.attributes, FieldNames.AREA_NAME);
    return areaName;
  }
}

export async function getArea(areaId) {
  const areas = await Context.instance.areas;
  let matchedArea = areas.find((area) => {
    const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
    return areaId === id;
  });
  if (!matchedArea) {
    const dataset = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas;
    if (dataset) {
      matchedArea = await dataset.queryAreaById(areaId);
    }
  }
  return matchedArea;
}
/** Gets the displayName property from an Office 365 booking. */
export function getDisplayName(booking: IIndoorsEvent) {
  const unitId = booking?.extensions?.[0]?.unitId;
  const unitName = booking?.location?.displayName ?? "";
  const unc = Context.instance.session.unitNameCache;
  const defaultName = unc?.defaultNamesPerUnitId?.[unitId];
  const length = defaultName?.split(";")?.length ?? 1;
  
  // if edited in outlook, additional values are appended to the displayName separated by semi-colons
  const split = unitName.split(";", length);
  return split.join(";");
}

export function makeWhere(criteria) {
  return whereUtil.makeWhere(criteria);
}
export interface IICSInfo {
  filename?: string
}
/** Represents an individually modified occurrence. */
export interface IOccurrence {
  original_date_start: Date,
  date_start: Date,
  date_end: Date,
  is_cancelled: boolean,
  sequence: number
}
export interface IICSCommonProps {
  bookingType?: ICommonBookingProps["bookingType"],
  canceled?: IOccurrence[],
  date_start: Date,
  date_end: Date,
  description: string,
  ex_date?: Date[],
  geo?: string,
  geometry?: __esri.Geometry,
  hyperlink: string,
  location: string,
  name: string,
  objectId: number,
  globalId: string,
  /** Represents a specific occurrence (start time) that is being updated. */
  occurrence_id?: Date,
  modified?: IOccurrence[],
  sequence?: number,
  series_start?: Date,
  series_end?: Date,
  serviceItemId?: string,
  uid: string
}
export interface IReservationInfo extends IICSCommonProps {
  recurrenceOptions: IRecurrenceOptions,
  reservationObjectId: number
}
export interface IEventInfo extends IICSCommonProps { 
  image_url: string,
  shortDate: string,
  longDate: string,
  mmm: string,
  d: string,
  yyyy: string,
  reservationDesc?: string,
  timeZone: string,
  timePeriod: string,
  attachments: {
    href: string
  }[]
}
export interface IReservationICS extends IICSInfo {
  reservationInfo: IReservationInfo
}
export interface IEventICS extends IICSInfo {
  eventInfo: IEventInfo
}
export async function makeReservationICS(
  reservation: __esri.Graphic | IFeature,
  params: {
    item: ItemReference,
    title: string,
    objectId?: number,
    globalId?: string
  },
  type: string,
  featureDataset?: Events
): Promise<IReservationICS | IEventICS> {
  if (type === "reservations") {
    const i18n = Context.getInstance().i18n;
    const dataset = Context.getInstance().aiim.datasets.reservations;
    const layer = dataset && dataset.layer2D;
    const objectIdField = layer && layer.objectIdField;
    const attributes = reservation.attributes;
    const geometry = reservation.geometry;
    const { item, title, objectId: oid, globalId: guid } = params;
  
    const unitName = attributes[dataset.unitNameField];
    let start = attributes[dataset.startTimeField];
    let end = attributes[dataset.endTimeField];
    let occurrence_id, sequence = 0;
    const reservationTitle = attributes[dataset.titleField];
    const reservationDesc = attributes[dataset.descriptionField];
    const objectId = getAttributeValue(attributes, objectIdField);
    const globalId = guid ?? getAttributeValue(attributes, aiimUtil.getGlobalIdField(layer));
    // @ts-ignore
    const serviceItemId = `${layer.serviceItemId}oid${objectId}` ?? btoa(`${dataset.url}oid${objectId}`)
    const recurrenceId = getAttributeValue(attributes, dataset.recurrenceIdField);
    const series = await dataset.getRecurringSeries(recurrenceId);
    const modified: IOccurrence[] = [];
    const canceled: IOccurrence[] = [];
    const ex_dates: Date[] = [];
    if (series) {
      // gather the oids of each modified/canceled occurrence so that we can reference the original start times
      // TODO: Consider storing the original start time in the recurrence JSON for reference
      const modifiedOids = series.allOccurrences
        .map(({ attributes }, index) => ({
          oid: attributes[objectIdField] as number,
          start: moment(attributes[dataset.startTimeField]),
          index,
          attributes
        }))
        .filter(({ attributes }) => getRecurrencePattern(attributes).modified === "occurrence")
      const canceledOids = series.allOccurrences
        .map(({ attributes }, index) => ({
          oid: attributes[objectIdField],
          start: moment(attributes[dataset.startTimeField]),
          index,
          attributes
        }))
        .filter(({ attributes }) => attributes[dataset.stateField] === BOOKING_STATE.CANCELED)
      sequence = series.allOccurrences
        .map(o => getRecurrencePattern(o.attributes).sequence)
        .sort((a, b) => a < b ? 1 : a > b ? -1 : 0)[0] ?? 0; // sort highest to lowest

      // original recurrence dates
      const dates = getRecurringDates(series.options, moment(series.checkIn), moment(series.checkOut));
      const currentIndex = series.allOccurrences.findIndex(o => o.attributes[objectIdField] === oid);
      if (currentIndex >= 0) {
        occurrence_id = dates[currentIndex]?.start.toDate();
      }
      const toOccurrence = ({ oid, index }: { oid: number, index: number }) => {
        const o = series.allOccurrences.find(o => o.attributes[objectIdField] === oid);
        const original_date_start = dates[index]?.start.toDate();
        return {
          original_date_start,
          date_start: o?.attributes[dataset.startTimeField],
          date_end: o?.attributes[dataset.endTimeField],
          is_cancelled: o?.attributes[dataset.stateField] === BOOKING_STATE.CANCELED,
          sequence: (getRecurrencePattern(o?.attributes)?.sequence ?? -1) + 1
        };
      };
      modified.push(...modifiedOids.map(toOccurrence));
      canceled.push(...canceledOids.map(toOccurrence));
      ex_dates.push(...dates
        .filter(date => series.occurrences.find(o =>
          o.attributes[dataset.startTimeField] === date.start.valueOf() && o.attributes[dataset.endTimeField] === date.end.valueOf()) == null)
        .map(date => date.start.toDate()));
    }
    const series_start = series?.checkIn;
    const series_end = series?.checkOut;
    const uid = series?.id ?? recurrenceId ?? globalId ?? serviceItemId;
    const validFileName = (reservationTitle && reservationTitle.replace(/[/\\?%*:|"<>]/g, '-')) || "reservation";
    const filename = `${validFileName}.ics`;
  
    // Get title and description from reservation feature
    const name = title || i18n.more.bookWorkspace.emailSubject.replace("{workspaceName}", unitName);
    const locationUrl = await generateShareUrlAsync(item);
    const linkPattern = i18n.more.bookWorkspace.locationLinkPattern;
    const itemAttr = item && item.searchResult && item.searchResult.feature && item.searchResult.feature.attributes;
    const assignmentType = getAttributeValue(itemAttr, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    let bookingType;
    if (assignmentType === "hotel") bookingType = "hotel";
    else bookingType = "meetingRooms"
  
    // Hyperlink for email clients that support it like Outlook.
    // Defaults to the description if the client doesn't recognize
    // the X-ALT-DESC param on the ICS file

    const description = `${linkPattern} - ${locationUrl}`;
    const hyperlink = _makeDescriptionHyperlink(linkPattern, locationUrl,reservationDesc);

    const reservationInfo: IReservationInfo = {
      name: name,
      description: description,
      hyperlink: hyperlink,
      location: unitName,
      date_start: start,
      date_end: end,
      ex_date: [...ex_dates],
      series_start,
      series_end,
      occurrence_id,
      modified,
      canceled,
      objectId,
      globalId,
      recurrenceOptions: series?.options || getRecurrencePattern(attributes),
      reservationObjectId: objectId,
      sequence,
      serviceItemId,
      bookingType,
      uid: (uid ?? generateRandomUuid()).toLowerCase()
    }
  
    if (params.objectId) {
      reservationInfo.objectId = params.objectId;
    }
  
    const result: any = await projectionUtil.projectToGeographic(geometry);
    if (result && result.centroid) {
      const { latitude, longitude } = result.centroid;
      reservationInfo.geo = `${latitude};${longitude}`;
    }  
    return { reservationInfo, filename };
  } else {
    const geometry =  await projectionUtil.projectToGeographic(reservation.geometry);
    let copy = (reservation as __esri.Graphic).clone();
    copy.geometry = geometry;
    const { item } = params;
    const globalId = getAttributeValue(copy.attributes, aiimUtil.getGlobalIdField(featureDataset.layer2D));
    const eventInfo: IEventInfo = {
      ...featureDataset.getEventInfo({}, copy, params),
      globalId,
      uid: (globalId ?? generateRandomUuid()).toLowerCase()
    };
    const i18n = Context.getInstance().i18n;
    const locationUrl = await generateShareUrlAsync(item);
    const linkPattern = i18n.more.bookWorkspace.locationLinkPattern;
    const description = `${linkPattern} - ${locationUrl}`;
    const htmlDescription = eventInfo.description ? `${eventInfo.description}<br><br>` : "";
    const temp = eventInfo.reservationDesc ? `${eventInfo.reservationDesc}<br><br>` : "";
    const hyperlink = `X-ALT-DESC;FMTTYPE=text/html:<html><body>${temp}${htmlDescription}<a href="${locationUrl}">${linkPattern}</a></body></html>`;

    eventInfo.description = eventInfo.description
      ? `${eventInfo.description}\\n\\n${description}`
      : description;
    eventInfo.hyperlink = hyperlink;
    const eventTitle = eventInfo && eventInfo.name;
    const validFileName = (eventTitle && eventTitle.replace(/[/\\?%*:|"<>]/g, '-')) || "event";
    const filename = `${validFileName}.ics`;
    return { eventInfo, filename};
  }
}

/**
 * Makes an ICS alternate html description for certain email clients
 * @param {string} label
 * @param {string} url
 * @returns ICS param string
 */
function _makeDescriptionHyperlink(label, url, reservationDesc) {
  if (reservationDesc) {
    reservationDesc = reservationDesc.replace(/[\n\r]/g,'<br>');
    return `X-ALT-DESC;FMTTYPE=text/html:<html><body>${reservationDesc}<br><br><a href="${url}">${label}</a></body></html>`;
  }
  return `X-ALT-DESC;FMTTYPE=text/html:<html><body><a href="${url}">${label}</a></body></html>`;
}

// export async function downloadICSFile(reservation, params) {
//   if (!reservation || !params) {
//     return;
//   }

//   const { reservationInfo, filename } = makeReservationICS(reservation, params);
//   const icsInfo = await makeIcsInfo(reservationInfo, filename);

//   // Download file
//   const a = document.createElement("a");
//   a.style = "display: none";
//   a.href = icsInfo.href;
//   a.download = icsInfo.filename;
//   a.click();
// }

export async function sortByDistance(features, office365Results) {
  const hotelingUnits = features.map((feature) => {
    return { feature: feature };
  })
  const cf = new ClosestFacility();

  const bluedot = Context.getInstance().bluedot;
  const fromFeature = bluedot.graphic;
  const map = Context.getInstance().views.activeView.map;
  const fromSource = map.findLayerById("indoors-bluedot");
  const source = Context.getInstance().aiim.datasets.units.getSource();

  cf.euclideanSort(fromFeature, hotelingUnits, null, fromSource, source);

  const topItems = hotelingUnits.slice(0, MAX_NETWORK_SORT);
  try {
    await cf.networkSort(fromFeature, topItems, true);
  } catch(e) {
    console.error("Couldn't network sort", e);
    return _getHotelData(hotelingUnits, office365Results);
  }

  // Merge
  for (let i = 0; i < topItems.length; i++) {
    hotelingUnits[i] = topItems[i];
  }

  return _getHotelData(hotelingUnits, office365Results);
}

function _getHotelData(units, office365Results) {
  const type = getHotelBookingSystemType();

  switch (type) {
    case "esri":
      return units.map((unit) => {
        const feature = unit.feature;
        const attributes = feature && feature.attributes;
        const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID);
        return unitId;
      });
    case "office365":
      return units.map((unit) => {
        const feature = unit.feature;
        const attributes = feature && feature.attributes;
        const scheduleEmail = getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL);
        const data = office365Results.find((result) => scheduleEmail === result.scheduleId);
        return data;
      });
    default:
      return [];
  }
}

export function shouldSortHotels() {
  const bluedot = Context.getInstance().bluedot;
  return bluedot.enabled && bluedot.graphic;
}

/**
 * Scenarios in which tabs should show on the Hotel panel
 * @returns Boolean of whether to show tabs or not
 */
export function shouldShowHotelTabs() {
  return true;
  // return getBookingSystemType() === "esri";
}

// All day for one day (24 hours)
function _isAllDayOne(startTime, endTime) {
  const incrementDay = moment(startTime).add(1, 'd'); 
  return endTime.isBetween(startTime, incrementDay);
  //return endTime.isSame(incrementDay, 'day');
}

// All day for multiple days
function _isAllDayMany(startTime, endTime) {  
  const incrementDay = moment(startTime).add(1, 'd').subtract(1, 'm');
  return endTime.isAfter(incrementDay, 'day');
}

/**
 * Format a readable date string based on the provided start and end moment objects,
 * and whether the booking is all day or not
 *
 * @param {moment.MomentInput} startTime
 * @param {moment.MomentInput} endTime
 * @param {boolean} isAllDay
 * @returns Formatted date string
 */
export function formatDate(startTime, endTime, isAllDay, options?) {
  const i18n = Context.getInstance().i18n;
  const isMultipleAssignments = options && options.isMultipleAssignments;

  if (isAllDay && _isAllDayOne(startTime, endTime)) {
    // Ex: May 22, all day
    const dateFormat = !!isMultipleAssignments ? "L" : "MMM D";
    const template = !!isMultipleAssignments
      ? i18n.multipleAssignments.bookingSameDayAllDay
      : i18n.more.bookWorkspace.bookingSameDayAllDay;
    let date = startTime.format(dateFormat);
    if (!!isMultipleAssignments && moment().isSame(startTime, 'day')) {
      date = i18n.multipleAssignments.today;
    }

    const pairs = { date };
    return stringFormatter(template, pairs);
  } else if (isAllDay && _isAllDayMany(startTime, endTime)) {
    // Ex: May 22 - May 24, all day
    // Need to subtract one minute from end time to show an accurate time period
    // Ex: May 22 12:00am - May 24 12:00am NOT EQUAL TO May 22 - May 24, all day
    // Must be May 22 - May 23, all day (the booking is not "all day" on the 24th as well).
    const dateFormat = !!isMultipleAssignments ? "L" : "MMM D";
    const template = !!isMultipleAssignments
      ? i18n.multipleAssignments.bookingDifferentDaysAllDay
      : i18n.more.bookWorkspace.bookingDifferentDaysAllDay;

    const pairs = {
      startDate: startTime.format(dateFormat),
      endDate: moment(endTime).subtract(1, 'm').format(dateFormat)
    };
    return stringFormatter(template, pairs);
  } else if (startTime.isSame(endTime, 'day')) {
    // Ex: May 22, 10:00am - 4:45pm
    const timeFormat = dateUtil.getMomentTimeFormat();
    const dateFormat = !!isMultipleAssignments ? "L" : "MMM D";
    const template = !!isMultipleAssignments
      ? i18n.multipleAssignments.bookingSameDay
      : i18n.more.bookWorkspace.bookingSameDay;
    let date = startTime.format(dateFormat);
    if (!!isMultipleAssignments && moment().isSame(startTime, 'day')) {
      date = i18n.multipleAssignments.today;
    }

    const pairs = {
      date,
      startTime: startTime.format(timeFormat),
      endTime: endTime.format(timeFormat)
    };
    return stringFormatter(template, pairs);
  } else {
    // Ex: May 22, 2:00pm - May 23, 3:30pm
    const timeFormat = dateUtil.getMomentTimeFormat();
    const dateFormat = !!isMultipleAssignments ? "L" : "MMM D";
    const template = !!isMultipleAssignments
      ? i18n.multipleAssignments.bookingDifferentDays
      : i18n.more.bookWorkspace.bookingDifferentDays;

    const pairs = {
      startDate: startTime.format(dateFormat),
      endDate: endTime.format(dateFormat),
      startTime: startTime.format(timeFormat),
      endTime: endTime.format(timeFormat)
    };
    return stringFormatter(template, pairs);
  }
}
export function formatRecurringDates(options: IRecurrenceOptions, startTime: Moment, endTime: Moment, 
  allDay: boolean, isAvailablePanel?: boolean, isScheduleView?: boolean) {
  const { calendars: i18nC, more: { bookWorkspace: { recurrence: i18nR } } } = Context.getInstance().i18n;
  const format = dateUtil.getMomentTimeFormat();
  if (options?.enabled === true && (options.type === "daily" || options.days != null)) {
    let template = "";
    let pairs: Record<string, any> = {
      endDate: moment(options.endDate).format("L")
    };
    const i18nStr = Context.getInstance().i18n.more.bookWorkspace.recurrenceAvailability;
    if (options.type === "daily" || (options.type === "weekly" && options.days.length === 7)) {
      template = allDay ? i18nR.formatDailyAllDay : i18nR.formatDaily;
      if(isAvailablePanel) {
        template = allDay ? i18nStr.every3: i18nStr.every1;
      } else if (isScheduleView) {
        template = i18nR.formatDailyShort;
      }
      if (!allDay) {
        pairs.startTime = startTime.format(format);
        pairs.endTime = endTime.format(format);
      }
    } else {
      template = allDay ? i18nR.formatWeeklyAllDay : i18nR.formatWeekly;
      if(isAvailablePanel) {
        template = allDay ? i18nStr.every4 : i18nStr.every2;
      } else if (isScheduleView) {
        template = i18nR.formatWeeklyShort;
      }
      const allDays = [i18nC.sun, i18nC.mon, i18nC.tues, i18nC.wed, i18nC.thurs, i18nC.fri, i18nC.sat];
      pairs.days = options.days.map(d => allDays[d]).join(", ");
      if (!allDay) {
        pairs.startTime = startTime.format(format);
        pairs.endTime = endTime.format(format);
      }
    }
    return stringFormatter(template, pairs);
  } else {
    return "";
  }
}
export function makeStartDateTime(startDate, startTime, allDay) {
  const lib = Context.getInstance().lib;
  const date = lib.dojo.locale.format(startDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  });

  let hour = startTime.hour();
  if (hour < 10) {
    hour = `0${hour}`;
  }
  let minute = startTime.minute();
  if (minute < 10) {
    minute = `0${minute}`;
  }
  let time = `${hour}:${minute}:00`;

  if (allDay) {
    time = "00:00:00"
  }

  const checkInDate = `${date} ${time}`;
  const finalDate = new Date(checkInDate).toISOString();

  return finalDate;
}

export function makeEndDateTime(endDate: Date, endTime: moment.Moment, allDay: boolean, noMidnight?: boolean) {
  const lib = Context.getInstance().lib;
  let date = lib.dojo.locale.format(endDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  });

  let hour: string | number = endTime.hour();
  if (hour < 10) {
    hour = `0${hour}`;
  }
  let minute: string | number = endTime.minute();
  if (minute < 10) {
    minute = `0${minute}`;
  }
  let time = `${hour}:${minute}:00`;

  if (allDay) {
    let nextDay = new Date(endDate);
    if (!noMidnight) {
      nextDay.setDate(endDate.getDate() + 1);
    }

    date = lib.dojo.locale.format(nextDay, {
      selector: "date",
      datePattern: "yyyy/MM/dd"
    });
    if (!noMidnight) {
      time = "00:00:00";
    } else {
      time = "23:59:00";
    }
  }

  const checkOutDate = `${date} ${time}`;
  const finalDate = new Date(checkOutDate).toISOString();

  return finalDate;
}

/**
 * Make the initial filter times for hoteling (nearest half-hour start time, then an hour
 * later end time)
 */
export function makeInitialTimes() {
  const locale = Context.instance.lib.dojo.kernel.locale;
  const format = (locale === "en" || locale === "en-us") ? "h:mm A" : "H:mm";

  const now = moment();
  const remainder = 30 - (now.minute() % 30);
  const startTime = moment(now, format).add(remainder, "minutes");
  const endTime = moment(startTime, format).add(1, "hour");

  return { startTime, endTime };
}

export async function queryUnitByUnitId(unitId: string | string[]): Promise<__esri.FeatureSet> {
  const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
  const layer = units && units.layer2D;
  const unitIdField = aiimUtil.findFieldName(layer.fields, FieldNames.UNIT_ID);
  const url = Context.checkMixedContent(layer.url + "/" + layer.layerId);
  const lib = Context.getInstance().lib;
  const task: CustomQueryTask = new lib.esri.QueryTask({url: url});
  const query: __esri.Query = new lib.esri.Query();
  query.outFields = ["*"]
  query.returnGeometry = true;
  query.returnZ = true;
  const v = Array.isArray(unitId)
    ? unitId.map(u => `'${selectionUtil.escSqlQuote(u)}'`).join(",")
    : `'${selectionUtil.escSqlQuote(unitId)}'`;
  query.where = `(${unitIdField} IN (${v}))`;
  return task.execute(query);
}

export function hasAreasOccupantsUnits() {
  const hasAreasTable = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas 
                        && Context.instance.aiim.datasets.areas.table;
  const hasUnitsLayer = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units 
                        && Context.instance.aiim.datasets.units.layer2D;
  const hasOccupantsLayer = Context.instance.aiim.datasets && Context.instance.aiim.datasets.people
                        && Context.instance.aiim.datasets.people.layer2D;
  return !!hasAreasTable && !!hasUnitsLayer && !!hasOccupantsLayer;
}

export function hasReqAreaFields() {
  const areas = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas;
  const areasFields = areas && areas.table && areas.table.fields;
  const hasAreaId = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_ID);
  const hasAreaType = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_TYPE);
  const hasAreaName = areasFields && aiimUtil.findFieldName(areasFields, FieldNames.AREA_NAME);
  return !!hasAreaId && !!hasAreaType && !!hasAreaName;
}

export function unitHasScheduleEmail(){
  const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
  if(!units) return false;

  const layer = units.layer2D;
  if(!layer) return false;

  const fields = layer.fields;
  if(aiimUtil.findField(fields,FieldNames.SCHEDULE_EMAIL)) return true;
  return false;
}

export function unitLyrHasAreaID() {
  const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
  if(!units) return false;

  const layer = units.layer2D;
  if(!layer) return false;

  const fields = layer.fields;
  if(aiimUtil.findField(fields,FieldNames.UNITS_AREA_ID)) return true;
  return false;
}

export function unitHasReservationMethod(){
  const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
  if(!units) return false;

  const layer = units.layer2D;
  if(!layer) return false;

  const fields = layer.fields;
  if(aiimUtil.findField(fields,FieldNames.RESERVATION_METHOD)) return true;
  return false;
}

export function occupantsHasField(fieldName) {
  const occupants = Context.instance.aiim.datasets && Context.instance.aiim.datasets.people;
  if(!occupants) return false;

  const layer = occupants.layer2D;
  if(!layer) return false;

  const fields = layer.fields;
  if(aiimUtil.findField(fields,fieldName)) return true;
  return false;
}

export function getReservationsLayer() {
  const configuration = Context.getInstance().configuration;
  const configurables = configuration.extractConfigurables();
  const workspaceReservation = configurables && configurables.workspaceReservation;
  const reservationLayerId = workspaceReservation && workspaceReservation.reservationLayerId;
  // if reservation layer id is present; check if the layerid gives a valid reservations layer
  if(reservationLayerId) {
    const view = Context.getInstance().views.mapView;
    return view.map.findLayerById(reservationLayerId);
  }
}

export function isLayerEditable(reservationsLayer) {
  if(!reservationsLayer) return false;

  if(reservationsLayer.type !== "feature") return false;

  // check if add and update operations are enabled
  const supportedOperations = reservationsLayer.capabilities && reservationsLayer.capabilities.operations;
  const editingEnabled = supportedOperations && supportedOperations.supportsEditing; 
  const supportsAdd = supportedOperations && supportedOperations.supportsAdd;
  const supportsUpdate = supportedOperations && supportedOperations.supportsUpdate;
  const supportsDelete = supportedOperations && supportedOperations.supportsDelete;
  const supportsRequiredOperations = supportsAdd && supportsUpdate && supportsDelete;

  if(editingEnabled && supportsRequiredOperations) return true;
  return false;
}

export function getEpochCurrentTime(){
  const currentDateTime = new Date();
  var unix_seconds = (new Date(currentDateTime)).getTime();
  return unix_seconds;
}

export function getValidationInfo() {

  const info = getWorkspaceInfo();
  if (!info) return {
    isValidHotel: false,
    isValidMeetingRooms: false
  }
  
  const configuration = Context.getInstance().configuration;
  const configurables = configuration.extractConfigurables();
  const workspaceReservation = configurables && configurables.workspaceReservation;
  const reservationTypeHotel = workspaceReservation && workspaceReservation.reservationTypeHotel;
  const reservationTypeMeeting = workspaceReservation && workspaceReservation.reservationTypeMeeting;
  const enableHotel = workspaceReservation && workspaceReservation.enableHotel;
  const enableMeeting = workspaceReservation && workspaceReservation.enableMeeting;

  let isValid: { isValidHotel: boolean, isValidMeetingRooms: boolean, appId?: boolean } = {
    isValidHotel: true,
    isValidMeetingRooms: true
  };

  // toggle is turned off
  if (!enableHotel && !enableMeeting) return isValid;

  // o365 - hotel and meeting rooms
  if (reservationTypeHotel === "office365" || reservationTypeMeeting === "office365") {
    const hasReqAreaFields = !!info.hasAreaId && !!info.hasAreaType && !!info.hasAreaName;
    const hasReqOccupantFields = info.peopleHasEmail && info.peopleHasFullName;

    isValid.appId = !!workspaceReservation.appId;

    if(enableHotel && reservationTypeHotel === "office365") {
      if (isValid.appId) {
        if (info.hasAreasTable && info.hasUnitsLayer && info.hasOccupantsLayer && hasReqAreaFields) {
          if (info.unitsHasScheduleEmail && hasReqOccupantFields) {} 
          else isValid.isValidHotel = false;
        } else {
          isValid.isValidHotel = false;
        }
      } else {
        isValid.isValidHotel = false;
      }
    }

    if (enableMeeting && reservationTypeMeeting === "office365") {
      if (isValid.appId && info.hasUnitsLayer && info.unitsHasScheduleEmail) {} 
      else {
        isValid.isValidMeetingRooms = false;
      }
    }
  } 

  if (reservationTypeHotel === "esri" || reservationTypeMeeting === "esri") {
    const hasReservationsLayer = !!info.hasReservationsLayer;
    const isLayerEditable = info.isLayerEditable;
    const hasAreasTable = info.hasAreasTable;
    const hasUnitsLayer = info.hasUnitsLayer;
    const hasReqAreaFields = !!info.hasAreaId && !!info.hasAreaType && !!info.hasAreaName;
    const unitHasAreaId = info.unitsHasAreaId;
    const unitHasResMethod = info.unitsHasReservationMethod;
    const hasReqOccupantFields = info.peopleHasEmail && info.peopleHasFullName;
    const hasOccupantsLayer = info.hasOccupantsLayer;

    if (enableHotel && reservationTypeHotel === "esri") {
      if (hasReservationsLayer && isLayerEditable && hasAreasTable && hasReqAreaFields && hasOccupantsLayer 
          && hasReqOccupantFields && hasUnitsLayer && unitHasAreaId ) {} 
      else {
        isValid.isValidHotel = false;
      }
    }

    if (enableMeeting && reservationTypeMeeting === "esri") {
      if(hasReservationsLayer && isLayerEditable) {
        if(hasUnitsLayer && unitHasResMethod) {} 
        else isValid.isValidMeetingRooms = false;
      } else {
        isValid.isValidMeetingRooms = false;
      }
    }
  }
    return isValid;
  }
  
export async function getReScheduleInfo(booking: IIndoorsEvent, bookingType: ICommonBookingProps["bookingType"], bookingDateFilter?: IBookingDateFilter) {

  let currentDateTime = moment(Date.now()).add(1,"milliseconds").toISOString();
  const params = {
    currentDateTime: currentDateTime,
    bookingType: bookingType,
    bookingDateFilter
  }

  let bookingSystem: IBookingSystem;
  if (bookingType === "hotel") bookingSystem = getHotelBookingSystem();
  else if (bookingType === "meetingRooms") bookingSystem = getMeetingBookingSystem();

  const response = await getBookings(bookingSystem, params);
  let rescheduleInfo: any = {};
  const unitId = booking && booking.extensions && booking.extensions[0].unitId;
  const scheduleEmail = booking && booking.extensions && booking.extensions[0].scheduleEmail;
  const eventId = booking && booking.id;
  const value = response && response.value && response.value.length > 0;
  if (!value) return null;

  let isBooking = false;
  for(let i=0;i<response.value.length;i++) {
    if (response.value[i].id === eventId) {
      isBooking = true;
      break;
    }
  }

  if (!isBooking) {
    return null;
  }

  const fs = await queryUnitByUnitId(unitId);
  const unitFeature = fs?.features?.[0];
  const unitAttr = unitFeature && unitFeature.attributes;
  const roomItem = {
    unitId: getAttributeValue(unitAttr,FieldNames.UNIT_ID),
    scheduleEmail: getAttributeValue(unitAttr, FieldNames.SCHEDULE_EMAIL) || scheduleEmail,
    name: getAttributeValue(unitAttr, FieldNames.NAME),
    unitFeature: unitFeature,
    isHotel: bookingType === "hotel"
  };

  const o365toDateObj = dateUtil.O365toDate(booking.start.dateTime, booking.end.dateTime);
  const fromDate = o365toDateObj.fromDate;
  const toDate = o365toDateObj.toDate;

  const isAllDay = dateUtil.isAllDay(fromDate.getTime(), toDate.getTime());

  let dtStart = fromDate;
  let dtEnd = toDate;

  const when = {
    duration: isAllDay ? "allDay" : null,
    start: {
      date: dtStart.getTime()
    },
    end: {
      date: dtEnd.getTime()
    },
    recurrence: getRecurrencePattern(booking)
  }
  if (when.recurrence?.enabled && booking.seriesMasterId) {
    const range = { dateStart: when.recurrence.startDate, dateEnd: when.recurrence.endDate };
    rescheduleInfo.series = await (bookingSystem as Office365).getBookingSeries(booking.seriesMasterId, bookingType, range);
  }
  let isOngoingBooking = false;
  const epochStartTime = new Date(dtStart).getTime();
  const epochCurrentTime = getEpochCurrentTime();
  if (bookingType === "hotel" && epochCurrentTime > epochStartTime) isOngoingBooking = true;

  rescheduleInfo.item = roomItem;
  rescheduleInfo.start = dtStart.getTime();
  rescheduleInfo.when = when;
  rescheduleInfo.operation = "updateBookingTime";
  rescheduleInfo.isOngoingBooking = isOngoingBooking;
  rescheduleInfo.origBooking = booking;

  return rescheduleInfo;
}

export async function showScheduleViewEsri(details) {
  const i18n = Context.instance.i18n;
  const resFeature = details.reservation;
  const attributes = resFeature && resFeature.attributes;
  const operation = details && details.operation;
  const bookingType = details && details.bookingType;
  const series = details.series;
  const recurrence = series?.options;
  const recurrenceType = details.recurrenceType ?? "single";
  const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID);
  const start: number = getAttributeValue(attributes, FieldNames.START_TIME);
  const end: number = getAttributeValue(attributes, FieldNames.END_TIME);
  const allDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
  const state = getAttributeValue(attributes, FieldNames.STATE);
  const bookingTitle = getAttributeValue(attributes, FieldNames.TITLE);

  const rescheduleBooking = details.rescheduleBooking;
  const fs = await queryUnitByUnitId(unitId);
  const unitFeature = fs?.features?.[0];

  const roomItem = {
    unitId: aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.UNIT_ID),
    name: aiimUtil.getAttributeValue(unitFeature.attributes,FieldNames.NAME),
    unitFeature: unitFeature,
    title: bookingTitle,
    isHotel: bookingType === "hotel"
  };

  const targetWhen: dateUtil.IDurationAsMilliseconds = {
    start: {
      date: start
    },
    end: {
      date: end
    },
    duration: allDay ? "allDay" : null,
    recurrence
  }

  let isOngoingBooking = false;
  if(details && state === CHECKED_IN) isOngoingBooking = true;
  const reserveForInfo = details && details.reserveForInfo;

  const controller = new ModalController();
  let title = i18n.meetingRooms.schedule.captionPattern;
  title = title.replace("{room}",roomItem.name);
  const modalProps = {
    className: "i-modal-confirm i-modal-schedule",
    title: title,
    cancelLabel: i18n.general.close,
    showOKCancel: true,
    hideOK: true
  };
  const content = (
    <Schedule
      item={roomItem}
      originalReservation={{booking: null, attributes}}
      start={start} 
      when={targetWhen} 
      operation={operation}
      isOngoingBooking={isOngoingBooking}
      bookingType={bookingType}
      recurrenceType={recurrenceType}
      reserveForInfo={reserveForInfo}
      series={series}
      onShowOccupant={() => controller.close()}
      onBookClicked={selectedWhen => {
        rescheduleBooking(selectedWhen, isOngoingBooking, recurrenceType);
        controller.close();
      }}/>
  )
  controller.show(modalProps,content);
}

// show Reschedule Booking Details 
export async function rescheduleBooking (details) {

  const unitAttr = details && details.unitAttr;
  const when = details && details.when;
  const isOngoingBooking = details && details.isOngoingBooking;
  const unitFeature = details && details.unitFeature;
  const checkAvailability = details && details.checkAvailability;
  const bookingType = details && details.bookingType;
  const originalReservation = details && details.originalReservation;
  const recurrence = getRecurrencePattern(originalReservation.attributes);
  const series = details && details.series;
  const recurrenceType = details?.recurrenceType ?? "single";
  if (recurrence && recurrenceType === "occurrence") {
    recurrence.modified = "occurrence";
  } else if (recurrence && recurrenceType === "series") {
    recurrence.modified = "series";
  }

  let levelName, facilityName;
  const aiim = Context.instance.aiim;
  const levels = aiim.datasets && aiim.datasets.levels;

  if (levels) {
    const levelId = getAttributeValue(unitAttr, FieldNames.LEVEL_ID)
    const levelData = levels.getLevelData(levelId);
    levelName = levelData && levelData.levelName;
    facilityName = levelData && levelData.facilityName;
  }

  const areaId = getAttributeValue(unitAttr, FieldNames.AREA_ID);
  const area = await getArea(areaId);
  const areaAttr = area && area.attributes;
  const areaName = getAttributeValue(areaAttr, FieldNames.AREA_NAME)
  const spaceType = getAttributeValue(unitAttr, FieldNames.UNITS_USE_TYPE);
  const unitName = getAttributeValue(unitAttr, FieldNames.NAME);

  // @ts-ignore
  const fromDate = new Date(moment(when.start.date));
  // @ts-ignore
  const toDate = new Date(moment(when.end.date));
  const fromDateTime = moment(when.start.date);
  const endDateTime = moment(when.end.date);
  const isAllDay = when && when.duration === "allDay";

  const node = document.createElement("div");
  document.body.appendChild(node);

  const onClose = () => {
    if (node && node.parentNode) {
      node.parentNode.removeChild(node);
      ReactDOM.unmountComponentAtNode(node)
    }
  };

  let reserveForInfo = details && details.reserveForInfo, reserveForSelf = false;
  if (details && details.selfBooking) reserveForSelf = true;

  if (bookingType === "hotel") {
    ReactDOM.render((
      <Provider store={Rdx.store}>
        <BookingDetails
          operation="updateBookingTime"
          isOngoingBooking={isOngoingBooking}
          recurrenceType={recurrenceType}
          area={area}
          areaName={areaName}
          areaId={areaId}
          spaceType={spaceType}
          unitName={unitName}
          levelName={levelName}
          facilityName={facilityName}
          startDate={fromDate}
          startTime={fromDateTime}
          endTime={endDateTime}
          endDate={toDate}
          allDay={isAllDay}
          checkAvailability={checkAvailability}
          recurrence={recurrence}
          reserveForSelf={reserveForSelf}
          reserveForInfo={reserveForInfo}
          isBookWorkspacePanel={true}
          unitFeature={unitFeature}
          originalReservation={originalReservation}
          closePopup={onClose}
          series={series}
        />
      </Provider>
    ), node);
  } else {
    const origResAttr = originalReservation && originalReservation.attributes;
    const origBooking = originalReservation && originalReservation.booking;
    const title = origResAttr ? aiimUtil.getAttributeValue(origResAttr, FieldNames.TITLE) : origBooking ? origBooking.subject : null; 
    const bookItem: IMeetingRoom = {
      unitId: aiimUtil.getAttributeValue(unitAttr,FieldNames.UNIT_ID),
      name: aiimUtil.getAttributeValue(unitAttr,FieldNames.NAME),
      feature: unitFeature,
      title,
    };
    const objectID = details && details.objectID;
    let criteria: IMeetingRoomCriteria = {
      when: when,
      isOngoingBooking: isOngoingBooking,
      operation: "updateBookingTime",
      objectID: objectID,
      series: series
    };
    if (recurrence) criteria.when.recurrence = recurrence;
    Book.show({ bookItem, criteria, originalReservation, recurrenceType, reserveForInfo });
  }
}

export function checkAvailability(info: IBookingTask & { unitFeature: __esri.Graphic}) {
  if (!info) return;
  const unitFeature = info?.unitFeature;
  const reservations = Context.getInstance().aiim.datasets.reservations;
  const reservation = info && info.reservation;
  const reservationAttr = reservation && reservation.attributes;
  const series = info?.series;
  const options = info?.options;
  const recurrenceType = options?.recurrence?.enabled
    ? options.recurrence.modified ?? "series"
    : "single";

  let objectIdField;
  if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
    objectIdField = reservations.layer2D.objectIdField
  }
  const objectIds = (series != null && recurrenceType !== "occurrence")
    ? series.occurrences.map(o => getAttributeValue(o.attributes, objectIdField))
    : [getAttributeValue(reservationAttr, objectIdField)];

  const levels = Context.getInstance().aiim.datasets.levels;
  const floorField = reservations.levelIdField;
  // Level/Facility info
  const unit = info?.unit;
  const unitAttr = unit?.attributes;
  const levelId = getAttributeValue(unitAttr, floorField);
  const levelData = levels.getLevelData(levelId);
  const levelName = levelData.levelName;
  const facilityName = levelData.facilityName;
  const checkInDate = info?.checkInDate;
  const checkOutDate = info?.checkOutDate;
  const allDay = info?.allDay;
  const bookingType = info?.bookingType;

  const unitName = getAttributeValue(unitAttr, FieldNames.NAME);

  let bookingSystem: IBookingSystem;
  if (bookingType === "hotel") bookingSystem = getHotelBookingSystem();
  else if (bookingType === "meetingRooms") bookingSystem = getMeetingBookingSystem();

  const units = Context.instance.aiim.datasets.units;
  const item = new ItemReference();
  item._fromFeature(units.getSource().key, unitFeature);

  const task: IBookingTask = {
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    allDay: allDay,
    options: options,
    unit: unitFeature,
    item: item,
    unitName: unitName,
    levelName: levelName,
    facilityName: facilityName,
    bookingSystem: bookingSystem,
    bookingType: bookingType,
    operation: "updateBookingTime",
    bookingForOther: info && info.bookingForOther,
    renderBookingConfirmed: info && info.renderBookingConfirmed,
    objectIds,
    series: info?.series,
    reservation: info && info.reservation,
    retry: () => {
      // @ts-ignore
      this.checkAvailability(checkInDate, checkOutDate, allDay, options);
    }
  }
  return validateUtil.verifyAndBook(task);
}
