import React from "react";

import Context from "../../../context/Context";
import DefaultImage from "../../../assets/events-placeholder.png";
import * as component from "../../util/component";

const CSS = {
  main: "i-carousel",
  backButton: "i-carousel-backbutton",
  nextButton: "i-carousel-nextbutton",
  imageContainer: "i-carousel-imagecontainer",
};

export default class Carousel extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      currentIndex: 0
    });
    this.backClicked = this.backClicked.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
  }

  backClicked() {
    const items = this.getItems();
    const currentIndex = this.state.currentIndex;
    let newIndex = currentIndex;
    if (items && items.length > 1) {
      if (currentIndex === 0) {
        newIndex = items.length - 1;
      } else {
        newIndex -= 1;
      }
    }
    if (newIndex !== currentIndex) {
      this.setState(state => {
        return {
          currentIndex: newIndex
        };
      });
    }
  }

  getItems() {
    return this.props.items;
  }

  nextClicked() {
    const items = this.getItems();
    const currentIndex = this.state.currentIndex;
    let newIndex = currentIndex;
    if (items && items.length > 1) {
      if (currentIndex === (items.length - 1)) {
        newIndex = 0;
      } else {
        newIndex += 1;
      }
    }
    if (newIndex !== currentIndex) {
      this.setState(state => {
        return {
          currentIndex: newIndex
        };
      });
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const items = this.getItems();
    const currentIndex = this.state.currentIndex;
    const hashref = "#";
    let back = null, next = null;

    const style = {
      backgroundImage: `url(${DefaultImage})`
    };

    if (items && items.length > 0) {
      const info = items[currentIndex];
      style.backgroundImage = "url(\"" + info.href + "\")";
      if (items.length > 1) {
        const backIcon = (<svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M25 28h-5L8 16 20 4h5L13 16l12 12z"/></svg>);
        back = (
          <a key="back" href={hashref} className={CSS.backButton} onClick={this.backClicked}
            title={i18n.general.back}>
            {backIcon}
          </a>
        );
        const nextIcon = (<svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M7 4h5l12 12-12 12H7l12-12L7 4z"/></svg>);
        next = (
          <a key="next" href={hashref} className={CSS.nextButton} onClick={this.nextClicked}
            title={i18n.general.next}>
            {nextIcon}
          </a>
        );
      }
    }

    return (
      <div className={CSS.main}>
        <div className={CSS.imageContainer} style={style}>
          {back}
          {next}
        </div>
      </div>
    );
  }

}
