import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import MapSelectionModel from "./MapSelectionModel";
import {
  getActiveTab,
  getHitTestResults,
  setHitTestResults,
  setSelectedFeatureIndex,
  getSelectedFeatureIndex,
  getSelectionVisibility,
  HitTest
} from "../redux";
import { nextId } from "../../../../components/util/component";
import { ReactComponent as PolygonSelectionIcon } from "../../../../assets/selection-polygon.svg";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-action-bar";
import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-action-group";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteAction,
  CalciteButton,
  CalciteActionGroup,
  CalciteActionBar,
  CalciteTooltip
} from "@esri/calcite-components-react";
import Context from "../../../../context/Context";
import { activateFloorFilter } from "./editorUtil";
import { LayerType } from "../../../../util/interfaces";

export interface ISymbolParams {
  lineSymbol: __esri.SimpleLineSymbolProperties;
  fillSymbol: __esri.SimpleFillSymbolProperties;
  activeLineSymbol: __esri.SimpleLineSymbolProperties;
  activeFillSymbol: __esri.SimpleFillSymbolProperties
}
export interface IMapSelection {
  activeFillSymbol?: __esri.SimpleFillSymbolProperties,
  activeLineSymbol?: __esri.SimpleLineSymbolProperties,
  autoSetFloorFilter?: boolean,
  disabled?: boolean,
  expandDisabled?: boolean,
  fillSymbol?: __esri.SimpleFillSymbolProperties,
  layout?: "vertical" | "horizontal",
  lineSymbol?: __esri.SimpleLineSymbolProperties,
  alwaysHighlightActive?: boolean,
  ignoreVisibleAtScale?: boolean,
  multipleSelectionEnabled?: boolean,
  onClear?: () => void,
  onSelection?: (result: {
    features: HitTest[],
    index: number,
    event: __esri.SketchViewModelCreateEvent | __esri.ViewClickEvent
  }) => void,
  selectionTypes?: LayerType[],
  tools?: Extract<__esri.Sketch["activeTool"], "point" | "rectangle" | "polygon">[],
  view: __esri.MapView | __esri.SceneView
}
const DEFAULT_INDEX = 0;
export const FeatureSelector = () => {
  const dispatch = useDispatch();
  const activeHits = useSelector(getHitTestResults);
  const selectedFeatureIndex = useSelector(getSelectedFeatureIndex);
  const ctx = Context.getInstance();
  const { lib, i18n } = ctx;
  let selectedStr = i18n.editor.selectFeature.caption;
  selectedStr = selectedStr.replace("{count}", selectedFeatureIndex + 1);
  selectedStr = selectedStr.replace("{totalCount}", activeHits.length);
  const isSingleFt = activeHits && activeHits.length === 1 ? true : false;

  const onSelectFeature = (type: "prev" | "next") => {
    let index = selectedFeatureIndex;
    if (type === 'next') {
      index = index + 1;
      if (index > activeHits.length - 1) index = 0;
    } else if (type === 'prev') {
      index = index - 1;
      if (index < 0) index = activeHits.length - 1;
    }

    dispatch(setSelectedFeatureIndex(index));
  }

  return (isSingleFt ? null :
    <>
      <div className="i-editor-map-selection">
        <CalciteButton title={i18n.editor.selectFeature.prev} iconStart="chevron-left" width="auto" appearance="solid" onClick={() => onSelectFeature('prev')} />
        <span>{selectedStr}</span>
        <CalciteButton title={i18n.editor.selectFeature.next} iconEnd="chevron-right" width="auto" appearance="solid" onClick={() => onSelectFeature('next')} />
      </div>
    </>
  )
}
const MapSelection = ({
  activeFillSymbol,
  activeLineSymbol,
  autoSetFloorFilter,
  disabled = false,
  expandDisabled = true,
  fillSymbol,  
  layout = "horizontal",
  lineSymbol,
  alwaysHighlightActive = false,
  ignoreVisibleAtScale = false,
  multipleSelectionEnabled = true,
  onClear = () => { },
  onSelection = () => { },
  selectionTypes = [],
  tools = ["point"],
  view
}: IMapSelection) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const activeHits = useSelector(getHitTestResults);
  const selectedFeatureIndex = useSelector(getSelectedFeatureIndex);
  const selectionVisibility = useSelector(getSelectionVisibility);
  const [activeTool, setActiveTool] = useState<Extract<__esri.Sketch["activeTool"], "point" | "rectangle" | "polygon">>("point");
  const component_id = nextId("map-selection-toolbar-");
  const ctx = Context.getInstance();
  const { i18n, lib } = ctx;

  useEffect(() => {
    activeTab == null
      ? viewModel.current.clearHighlights()
      : viewModel.current.highlightFeatures();

    viewModel.current.activateSketch(activeTool);
    return () => {
      viewModel.current.clearHighlights();
      viewModel.current.cancelSketch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (disabled !== true) {
      viewModel.current.activateSketch(activeTool);
    } else {
      viewModel.current.cancelSketch();
    }
  }, [disabled]);

  useEffect(() => {
    viewModel.current.selectedFeatureIndex = selectedFeatureIndex;
    viewModel.current.clearHighlights();
    if (selectedFeatureIndex != null) {
      viewModel.current.highlightFeatures();
    }
  }, [selectedFeatureIndex]);

  useEffect(() => {
    viewModel.current.clearHighlights();
    viewModel.current.graphics = activeHits.map(({ feature, key, oid }) => ({
      graphic: lib.esri.Graphic.fromJSON(feature),
      key,
      oid
    }));
    if (selectedFeatureIndex != null && activeTab != null) {
      viewModel.current.highlightFeatures();
    }
  }, [activeHits]);

  useEffect(() => {
    viewModel.current.clearHighlights();
    viewModel.current.selectedFeatureIndex = selectedFeatureIndex;
    viewModel.current.selectionTypes = selectionTypes;
    if (selectedFeatureIndex != null && activeTab != null) {
      viewModel.current.highlightFeatures();
    }
  }, [selectionTypes]);

  const onFeaturesSelected = (features: HitTest[], event: __esri.SketchViewModelCreateEvent | __esri.ViewClickEvent) => {
    if (autoSetFloorFilter) {
      const feature = features?.[0];
      if (feature) activateFloorFilter(feature);
    }
    const index = features.length ? DEFAULT_INDEX : null;
    dispatch(setHitTestResults(features));
    dispatch(setSelectedFeatureIndex(index));
    onSelection({ features, index, event });
  }

  const viewModel = useRef<MapSelectionModel>(new MapSelectionModel({
    activeFillSymbol,
    activeLineSymbol,
    fillSymbol,    
    lineSymbol,
    selectedFeatureIndex,
    alwaysHighlightActive,
    ignoreVisibleAtScale,
    multipleSelectionEnabled,
    onFeaturesSelected,
    selectionTypes,
    view,
    visible: selectionVisibility,
    graphics: activeHits.map(({ feature, key, oid }) => ({
      graphic: lib.esri.Graphic.fromJSON(feature),
      key,
      oid
    }))
  }));
  const activateTool = (e: typeof activeTool) => {
    viewModel.current.activateSketch(e);
    setActiveTool(e);
  }
  const pointId = `${component_id}-point`;
  const rectangleId = `${component_id}-rectangle`;
  const polygonId = `${component_id}-polygon`;

  return (
    tools.length === 0 || (tools.length === 1 && tools[0] === "point")
      ? <></>
      : <CalciteActionBar className="i-map-selection" layout={layout} expandDisabled={expandDisabled || undefined}>
        <CalciteActionGroup layout={layout}>
          {tools.includes("point") &&
            <>
              <CalciteAction
                id={pointId}
                text={i18n.miniapps.mapSelection.byPoint}
                icon="cursor"
                onClick={() => {
                  activateTool("point");
                  view.focus();
                }}
                active={activeTool === "point" ? true : undefined}
                disabled={disabled || undefined}>
              </CalciteAction>
              <CalciteTooltip
                referenceElement={pointId}
                placement="bottom"
                overlayPositioning="fixed"
                label={i18n.miniapps.mapSelection.byPoint}>
                <span>{i18n.miniapps.mapSelection.byPoint}</span>
              </CalciteTooltip>
            </>
          }
          {tools.includes("rectangle") &&
            <>
              <CalciteAction
                id={rectangleId}
                text={i18n.miniapps.mapSelection.byRectangle}
                icon="cursor-marquee"
                onClick={() => activateTool("rectangle")}
                active={activeTool === "rectangle" ? true : undefined}
                disabled={disabled || undefined}>
              </CalciteAction>
              <CalciteTooltip
                referenceElement={rectangleId}
                placement="bottom"
                overlayPositioning="fixed"
                label={i18n.miniapps.mapSelection.byRectangle}>
                <span>{i18n.miniapps.mapSelection.byRectangle}</span>
              </CalciteTooltip>
            </>
          }
          {tools.includes("polygon") &&
            <>
              <CalciteAction
                id={polygonId}
                text={i18n.miniapps.mapSelection.byPolygon}
                onClick={() => activateTool("polygon")}
                active={activeTool === "polygon" ? true : undefined}
                disabled={disabled || undefined}>
                <PolygonSelectionIcon width="16" />
              </CalciteAction>
              <CalciteTooltip
                referenceElement={polygonId}
                placement="bottom"
                overlayPositioning="fixed"
                label={i18n.miniapps.mapSelection.byPolygon}>
                <span>{i18n.miniapps.mapSelection.byPolygon}</span>
              </CalciteTooltip>
            </>
          }
        </CalciteActionGroup>
        <CalciteAction
          id={`${component_id}-clear`}
          text={i18n.miniapps.mapSelection.clear}
          icon="group-x"
          disabled={disabled || undefined}
          onClick={() => {
            dispatch(setHitTestResults([]));
            dispatch(setSelectedFeatureIndex(null));
            onClear()
          }}>
        </CalciteAction>
        <CalciteTooltip
          referenceElement={`${component_id}-clear`}
          placement="bottom"
          label={i18n.miniapps.mapSelection.clear}>
          <span>{i18n.miniapps.mapSelection.clear}</span>
        </CalciteTooltip>
        {/* <CalciteActionGroup>
              <CalciteAction text="Undo" icon="undo" disabled></CalciteAction>
              <CalciteAction text="Redo" icon="redo"></CalciteAction>
            </CalciteActionGroup> */}
        <CalciteTooltip slot="expand-tooltip" label={i18n.miniapps.mapSelection.expand}>
          {i18n.miniapps.mapSelection.expand}
        </CalciteTooltip>
      </CalciteActionBar>
  )
}

export default MapSelection;