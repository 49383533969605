import React from "react";

import CalciteTextField from "calcite-react/TextField";

export default class TextField extends React.Component {

  render() {
    return (
      <CalciteTextField {...this.props}>
        {this.props.children}
      </CalciteTextField>
    );
  }

}
