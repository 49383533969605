import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";
import {
  checkForDuplicatePeople,
  getAttributes,
  getPersonAssignments
} from "../../components/common/MultipleAssignments/multipleAssignmentsUtil";
import OfficePlan from "../OfficePlan";
import { addAttributeEdit, queryPeopleAssignedToUnit } from "../officePlanUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";
import * as val from "../../../util/val";

function afterAddPerson(results,undoTransaction,method) {
  // fix the undo (i.e. the delete) following an add
  const peopleLayer = sourceUtil.getPeopleLayer();
  const layerId = peopleLayer.layerId;
  const requestInfo = undoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;
  results.forEach(layerResult => {
    if (layerResult.id === layerId) {
      if (layerResult.addResults && layerResult.addResults.length === 1) {
        const newKey = layerResult.addResults[0].globalId;
        //console.log("******************************* newKey",newKey)

        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          let found = false;
          edits.forEach(layerEdit => {
            if (layerEdit.id === layerId) {
              if (layerEdit.deletes && layerEdit.deletes.length === 1) {
                //console.log("found deletes",layerEdit)
                layerEdit.deletes = [newKey];
                found = true;
              }
            }
          });
          if (found) {
            //console.log("new edits for addPerson.undo",edits)
            query.edits = JSON.stringify(edits);
          }
        }
      }
    }
  });
}

export function prepare(task) {
  return new Promise((resolve, reject) => {
    const transactionInfo = transaction.makeTransactionInfo()
    let relatedUnitInfo;
    const unitIds = []
    const countByUnit = {}
    //const peopleSource = sourceUtil.getPeopleSource()
    const basePersonFeature = task.basePersonFeature
    const unitFeature = task.unitFeature
    const areaFeature = task.areaFeature
    const unassignOthers = task.unassignOthers
    const duplicatePeople = task.duplicatePeople
    const duplicatePeopleFeatures = duplicatePeople && duplicatePeople.map(p => {
      if (p.person) return p.person
      else return p
    })
    const associatedUnitFeatures = duplicatePeople && duplicatePeople.map(p => p.associatedUnit)
    //const associatedAreaFeatures = duplicatePeople && duplicatePeople.map(p => p.associatedArea)

    // if (unitFeature) {
    //   let unitId = aiimUtil.getAttributeValue(unitFeature.feature.attributes, FieldNames.UNIT_ID)
    //   if (typeof unitId === "string" && unitId.length > 0) {
    //     if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId)
    //     if (!countByUnit[unitId]) countByUnit[unitId] = 1
    //     else countByUnit[unitId] = countByUnit[unitId] + 1
    //   }
    // }
    if (duplicatePeopleFeatures && duplicatePeopleFeatures.length > 0) {
      duplicatePeopleFeatures.forEach(person => {
        let attrs = getAttributes(person)
        let unitId = aiimUtil.getAttributeValue(attrs, FieldNames.PEOPLE_UNIT_ID)
        if (typeof unitId === "string" && unitId.length > 0) {
          if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId)
          if (!countByUnit[unitId]) countByUnit[unitId] = 1
          else countByUnit[unitId] = countByUnit[unitId] + 1
        }
      })
    }

    const checkIfAlreadyAssigned = (assignments) => {
      // Check to see if this person already has this assignment
      if (!assignments) return false
      let isAlreadyAssigned = false
      if (unitFeature) {
        const unitAttributes = getAttributes(unitFeature)
        const toAssignUnitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
        const units = (assignments && assignments.units) || []
        units.forEach(unit => {
          const attributes = getAttributes(unit)
          const unitId = aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_ID)
          if (toAssignUnitId === unitId) isAlreadyAssigned = true
        })
      } else if (areaFeature) {
        const areaAttributes = getAttributes(areaFeature)
        const toAssignAreaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
        const areas = (assignments && assignments.areas) || []
        areas.forEach(area => {
          const attributes = getAttributes(area)
          const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
          if (toAssignAreaId === areaId) isAlreadyAssigned = true
        })
      }
      return isAlreadyAssigned
    }

    const adds = [], deletes = [], updates = [], undoAdds = [], undoDeletes = [], undoUpdates = []

    const makePeopleEdits = () => {
      // const adds = [], undos = [];
      const layer = sourceUtil.getPeopleLayer();
      const fields = layer.fields;
      const keyField = layer.globalIdField;

      const edit = {attributes: {}}
      const newPerson = transactionUtil.clonePersonFeature(task, basePersonFeature.feature, "ensure-geometry")
      const attributes = newPerson && newPerson.attributes
      // TODO: Can we trust this function? Core bug.
      attributes[layer.globalIdField] = val.generateRandomUuid({includeBraces: true})
      delete attributes[layer.objectIdField]
      Object.keys(attributes).forEach(f => {
        let v = aiimUtil.getAttributeValue(attributes, f)
          addAttributeEdit(fields, edit.attributes, f, v)
      })

      // Duplicate to be assigned to unit
      if (unitFeature) {
        addAttributeEdit(fields, edit.attributes, FieldNames.PEOPLE_AREA_ID, null)
        const unitAttributes = unitFeature.feature && unitFeature.feature.attributes
        let unitGeometry = unitFeature.geometry || (unitFeature.feature && unitFeature.feature.geometry)
        transactionUtil.unitBasedFieldsForPeople.forEach(f => {
          let v = aiimUtil.getAttributeValue(unitAttributes, f)
          if (f === "unit_name") v = aiimUtil.getAttributeValue(unitAttributes, "name")
          addAttributeEdit(fields, edit.attributes, f, v)
        })
        if (unitGeometry && typeof unitGeometry.clone !== "function") {
          const g = Context.instance.lib.esri.Graphic.fromJSON({
            attributes: unitAttributes,
            geometry: unitGeometry
          })
          unitGeometry = g.geometry
        }
        edit.geometry = unitGeometry.clone().centroid
        if (task.occupantGeometry) {
          edit.geometry = task.occupantGeometry;
        }
      }
      // Duplicate to be assigned to area
      else if (areaFeature) {
        transactionUtil.appendPersonUnassignment(task, fields, edit)
        const areaId = aiimUtil.getAttributeValue(areaFeature.attributes, FieldNames.AREA_ID)
        addAttributeEdit(fields, edit.attributes, FieldNames.PEOPLE_AREA_ID, areaId)
      }

      adds.push(edit)
      undoAdds.push("?")
      // transactionInfo.peopleEdits = {adds: [edit]}
      // transactionInfo.undo.peopleEdits = {deletes: ["?"]}
      // transactionInfo.edits.push({
      //   id: layer.layerId,
      //   adds: [edit]
      // })
      // transactionInfo.undo.edits.push({
      //   id: layer.layerId,
      //   deletes: ["?"]
      // })
      // transactionInfo.useGlobalIds = false
    }

    const makeUnitEdits = () => {
      const features = (relatedUnitInfo && relatedUnitInfo.features) || []
      const unitUpdates = [], unitUndos = []
      const layer = sourceUtil.getUnitsLayer()
      const fields = layer.fields
      const keyField = layer.globalIdField

      if (unitFeature) {
        const unitAttributes = getAttributes(unitFeature)
        const key = unitAttributes[keyField]
        const asnType = OfficePlan.SpaceAssignmentTypes.office
        unitUndos.push(transactionUtil.cloneUnitFeature(task, unitFeature.feature,"no-geometry"))
        const update = {attributes: {}}
        update.attributes[keyField] = key
        addAttributeEdit(fields, update.attributes, FieldNames.UNITS_AREA_ID, null)
        addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
        unitUpdates.push(update)
      }

      const promises = []
      features.forEach(u => {
        // TODO need to test
        const unit = u.feature ? u : {feature: u}
        const promise = queryPeopleAssignedToUnit(unit).then(result => {
          const count = result.length
          const unitAttributes = getAttributes(unit)
          const unitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID);
          const key = unitAttributes[keyField]
          const asnType = count <= countByUnit[unitId] ?
            OfficePlan.SpaceAssignmentTypes.none :
            OfficePlan.SpaceAssignmentTypes.office
          unitUndos.push(transactionUtil.cloneUnitFeature(task, unit.feature, "no-geometry"))
          const update = {attributes: {}}
          update.attributes[keyField] = key
          addAttributeEdit(fields, update.attributes, FieldNames.UNITS_AREA_ID, null)
          addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
          unitUpdates.push(update)
        })
        promises.push(promise)
      })

      return Promise.all(promises).then(() => {
        if (unitUpdates.length > 0) {
          transactionInfo.unitEdits = {updates: unitUpdates}
          transactionInfo.undo.unitEdits = {updates: unitUndos}
          transactionInfo.edits.push({
            id: layer.layerId,
            updates: unitUpdates
          })
          transactionInfo.undo.edits.push({
            id: layer.layerId,
            updates: unitUndos
          })
        }
      })
    }

    const unassignOthersEdits = (data) => {
      const {duplicates, singleRecords} = data
      const layer = sourceUtil.getPeopleLayer()
      const fields = layer.fields
      const keyField = layer.globalIdField

      // const deletes = [], undoAdds = [], updates = [], undoUpdates = []
      duplicates.forEach(person => {
        const personFeature = person.feature || person
        const key = personFeature.attributes[keyField]
        //const undo = {attributes: Object.assign({}, personFeature.attributes)}
        const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
        deletes.push(key)
        undoDeletes.push(undo)
      })

      singleRecords.forEach(person => {
        const personFeature = person.feature || person
        undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
        const update = {attributes: {}}
        const personAttributes = getAttributes(person)
        update.attributes[keyField] = personAttributes[keyField]
        transactionUtil.appendPersonUnassignment(task, fields, update)
        updates.push(update)
      })
    }

    const hasUnits = () => {
      if (!associatedUnitFeatures) return false
      let hasUnits = true
      associatedUnitFeatures.forEach(unit => {
        if (!unit) hasUnits = false
      })
      return hasUnits
    }

    const unassignAssignmentsPeopleEdits = () => {
      // TODO need to test
      if (!duplicatePeopleFeatures || duplicatePeopleFeatures.length === 0) return
      const layer = sourceUtil.getPeopleLayer()
      const keyField = layer.globalIdField

      duplicatePeopleFeatures.forEach(person => {
        const personFeature = person.feature || person
        const key = personFeature.attributes[keyField]
        //const undo = {attributes: Object.assign({}, personFeature.attributes)}
        const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
        deletes.push(key)
        undoDeletes.push(undo)
      })
    }

    // const unassignAssignmentsUnitEdits = () => {
    //   if (!hasUnits() || unitFeature) return Promise.resolve()
    //   const unitUpdates = [], unitUndos = [], promises = []
    //   const layer = sourceUtil.getUnitsLayer()
    //   const fields = layer.fields
    //   const keyField = layer.globalIdField

    //   associatedUnitFeatures.forEach(unit => {
    //     const promise = queryPeopleAssignedToUnit(unit).then((result) => {
    //       const count = result.length
    //       if (count <= 1) {
    //         const key = unit.feature && unit.feature.attributes[keyField]
    //         const asnType = OfficePlan.SpaceAssignmentTypes.none
    //         unitUndos.push(transactionUtil.cloneUnitFeature(task, unit.feature, null))
    //         const update = {attributes: {}}
    //         update.attributes[keyField] = key
    //         addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
    //         unitUpdates.push(update)
    //       }
    //     }).catch(e => {
    //       console.error("Error querying people assigned to unit", e);
    //     });
    //     promises.push(promise)
    //   })

    //   return Promise.all(promises).then(() => {
    //     transactionInfo.unitEdits = {updates: unitUpdates}
    //     transactionInfo.undo.unitEdits = {updates: unitUndos}
    //     transactionInfo.edits.push({
    //       id: layer.layerId,
    //       updates: unitUpdates
    //     })
    //     transactionInfo.undo.edits.push({
    //       id: layer.layerId,
    //       updates: unitUndos
    //     })
    //   }).catch(e => {
    //     console.error("Couldn't update units", e)
    //   })
    // }

    const updateTransactionInfo = () => {
      const layer = sourceUtil.getPeopleLayer()
      if (adds.length > 0 || updates.length > 0 || deletes.length > 0) {
        transactionInfo.peopleEdits = {
          adds: adds,
          deletes: deletes,
          updates: updates
        }
        transactionInfo.undo.peopleEdits = {
          adds: undoDeletes,
          deletes: undoAdds,
          updates: undoUpdates
        }
        transactionInfo.edits.push({
          id: layer.layerId,
          adds: adds,
          deletes: deletes,
          updates: updates
        })
        transactionInfo.undo.edits.push({
          id: layer.layerId,
          adds: undoDeletes,
          deletes: undoAdds,
          updates: undoUpdates
        })
        if (undoAdds && undoAdds.length > 0) {
          transactionInfo.afterApplyEdits = afterAddPerson;
        }
      }
      //console.log("addPerson.transactionInfo",transactionInfo)
    }

    let alreadyAssigned = false;
    Promise.resolve().then(() => {
      return transactionUtil.fetchRelatedUnits(task, unitIds).then(result => {
        relatedUnitInfo = result;
      });
    }).then(() => {
      const personAttributes = getAttributes(basePersonFeature)
      if (personAttributes) {
        const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
        const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
        return getPersonAssignments(personName, personEmail)
      }
    }).then(assignments => {
      alreadyAssigned = checkIfAlreadyAssigned(assignments)
      // if (alreadyAssigned) {
      //   console.error("Can't assign the same person to the same place multiple times")
      //   //resolve(transactionInfo)
      // }
    }).then(() => {
      if (!unassignOthers) return Promise.resolve()
      else return queryPeopleAssignedToUnit(unitFeature)
    }).then(people => {
      if (!unassignOthers) return Promise.resolve()
      else return checkForDuplicatePeople(people)
    }).then(data => {
      if (!unassignOthers) return Promise.resolve()
      else unassignOthersEdits(data)
    }).then(() => {
      unassignAssignmentsPeopleEdits()
      // return unassignAssignmentsUnitEdits()
    }).then(() => {
      if (!alreadyAssigned) makePeopleEdits()
      return makeUnitEdits()
    }).then(() => {
      updateTransactionInfo()
      console.log("transactionInfo",transactionInfo)
      //throw new Error("Can't")
    }).then(() => {
      resolve(transactionInfo)
    }).catch(e => {
      reject(e)
    })
  })
}
