import BaseVM from "../../../components/Editor/support/BaseVM";
import Context from "../../../../context/Context";
import HostedMerge from "../../../base/HostedMerge";
import {
  WorkingController,
} from "../../../../common/components/Modal";
import * as mergeUtil from "../../../base/mergeUtil";
import * as sourceUtil from "../../../base/sourceUtil";

export interface MergeInfo {
  canPull: boolean,
  canPush: boolean,
  lastPullDate?: Date,
  lastPushDate?: Date,
  disablePullReason?: string,
  disablePushReason?: string,
  plan: {
    hasChanges: boolean,
    unitsEdited: number,
    detailsEdited: number,
    facilitiesEdited: number,
    levelsEdited: number,
    occupantsEdited: number,
    sitesEdited: number
  },
  defaultPlan: {
    hasChanges: boolean,
    unitsEdited: number,
    detailsEdited: number,
    facilitiesEdited: number,
    levelsEdited: number,
    occupantsEdited: number,
    sitesEdited: number
  }
}

export interface MergeOptions {
  type: "pull" | "push",
}

export default class MergePlanVM extends BaseVM {

  async checkDifferences(mergeInfo: MergeInfo) {
    const plan = this.getPlan();
    if (plan.isVersioned) {
      await this.checkDifferencesVersioned(mergeInfo);
    } else {
      await this.checkDifferencesHosted(mergeInfo);
    }
  }

  async checkDifferencesHosted(mergeInfo: MergeInfo) {
    if (true) throw new Error("Not yet")
    //const hm = new HostedMerge();
    //hm.hasDifferences({});
    /*
    this.setState({ isWorking: true });
    const hm = new HostedMerge();
    hm.hasDifferences({}).then(result => {
      let hasDiff = result && result.hasDifferences;
      if (!hasDiff) this.setState({reconcileOnly: true});
      this.setState({
        isWorking: false,
        diffChecked: true,
        noDiff: !hasDiff
      });
    }).catch(ex => {
      //this.setState({ isWorking: false });
      console.error("Error checking differences", ex);
      Topic.publishErrorAccessingData();
    });
    */
  }

  async checkDifferencesVersioned(mergeInfo: MergeInfo) {
    const plan = this.getPlan();
    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const promises = [];
    const planGuid =  plan.versionInfo.versionGuid;
    const defaultGuid =  versionManager.serviceInfo.defaultVersionGuid;
    const unitsLayer = sourceUtil.getUnitsLayer();
    const detailsLayer = sourceUtil.getDetailsLayer();
    const occupantsLayer = sourceUtil.getPeopleLayer();
    const sitesLayer = sourceUtil.getSitesLayer();
    const facilitiesLayer = sourceUtil.getFacilitiesLayer();
    const levelsLayer = sourceUtil.getLevelsLayer();
    const planDiffOptions = {versionGuid: planGuid};
    let planVI, planDiff, defaultVI, defaultDiff;

    const collectCounts = (differences, section: any) => {
      differences && differences.forEach(edits => {
        let n = 0;
        if (edits.inserts) n += edits.inserts.length;
        if (edits.updates) n += edits.updates.length;
        if (edits.deletes) n += edits.deletes.length;
        if (unitsLayer && unitsLayer.layerId === edits.layerId) {
          section.unitsEdited = n;
        } else if (detailsLayer && detailsLayer.layerId === edits.layerId) {
          section.detailsEdited = n;
        } else if (occupantsLayer && occupantsLayer.layerId === edits.layerId) {
          section.occupantsEdited = n;
        } else if (sitesLayer && sitesLayer.layerId === edits.layerId) {
          section.sitesEdited = n;
        } else if (facilitiesLayer && facilitiesLayer.layerId === edits.layerId) {
          section.facilitiesEdited = n;
        } else if (levelsLayer && levelsLayer.layerId === edits.layerId) {
          section.levelsEdited = n;
        }
      });      
    }

    promises.push(versionManager.readVersion(planGuid).then(v => planVI = v))
    promises.push(versionManager.readVersion(defaultGuid).then(v => defaultVI = v))
    promises.push(versionManager.hasDifferences(planDiffOptions).then(d => planDiff = d))
    await Promise.all(promises);

    // @todo seems to be a core bug, using the fromMoment throws an error
    // https://developers.arcgis.com/rest/services-reference/enterprise/differences.htm 
    const defaultDiffOptions = {
      fromMoment: planVI.commonAncestorDate,
      versionGuid: defaultGuid
    };
    // await versionManager.hasDifferences(defaultDiffOptions).then(d => defaultDiff = d)

    collectCounts(planDiff && planDiff.differences,mergeInfo.plan);
    collectCounts(defaultDiff && defaultDiff.differences,mergeInfo.defaultPlan);

    mergeInfo.plan.hasChanges = !!(planDiff && planDiff.hasDifferences);
    if (defaultVI.modifiedDate > planVI.commonAncestorDate) {
      mergeInfo.defaultPlan.hasChanges = true;
    }
    if (planVI.reconcileDate === null) {
      if (planVI.modifiedDate === planVI.commonAncestorDate) {
        if (planVI.modifiedDate !== planVI.creationDate) {
          mergeInfo.lastPullDate = new Date(planVI.commonAncestorDate);
        }
      }
    } else {
      mergeInfo.lastPullDate = new Date(planVI.reconcileDate);
    }
    if (!mergeInfo.plan.hasChanges && planVI.reconcileDate === null) {
      if (planVI.modifiedDate === planVI.commonAncestorDate) {
        if (planVI.modifiedDate !== planVI.creationDate) {
          mergeInfo.lastPushDate = new Date(planVI.commonAncestorDate);
        }
      }
    }

  }

  async gatherInfo(): Promise<MergeInfo> {
    const i18n = Context.instance.i18n;
    const mergeInfo: MergeInfo = {
      canPull: false,
      canPush: false,
      lastPullDate: null,
      lastPushDate: null,
      disablePullReason: null,
      disablePushReason: null,
      plan: {
        hasChanges: false,
        unitsEdited: 0,
        detailsEdited: 0,
        facilitiesEdited: 0,
        levelsEdited: 0,
        occupantsEdited: 0,
        sitesEdited: 0
      },
      defaultPlan: {
        hasChanges: false,
        unitsEdited: 0,
        detailsEdited: 0,
        facilitiesEdited: 0,
        levelsEdited: 0,
        occupantsEdited: 0,
        sitesEdited: 0
      }
    }
    const isVersioned = this.getPlan().isVersioned;
    const unitsLayer = sourceUtil.getUnitsLayer();

    await this.checkDifferences(mergeInfo);
    const canEdit = !!(unitsLayer && unitsLayer.editingEnabled);
    const isMembershipOK = await this.isMergeGroupMembershipOK();
    const isOwnerOrAdmin = this.isOwnerOrAdmin();
    if (isVersioned) {
      mergeInfo.canPull = canEdit;
      mergeInfo.canPush = !!(canEdit && isMembershipOK && mergeInfo.plan.hasChanges);
      if (!mergeInfo.canPull) {
        if (!canEdit) {
          mergeInfo.disablePullReason = i18n.switcher.mergePlan.noEditAccess;
        }
      }
      if (!mergeInfo.canPush) {
        if (!canEdit) {
          mergeInfo.disablePushReason = i18n.switcher.mergePlan.noEditAccess;
        } else if (!isMembershipOK) {
          const v = this.getMergeGroupTitle() || "?";
          mergeInfo.disablePushReason = i18n.switcher.mergePlan.mustBeAMember.replace("{groupName}",v);
        } else if (!mergeInfo.plan.hasChanges) {
          mergeInfo.disablePushReason = i18n.switcher.mergePlan.info.noPlanChanges;
        }
      }
    }
    return mergeInfo;
  }

  getMergeGroupId(): string {
    const mergePermission = Context.instance.config.spaceplanner.mergepermission;
    if (mergePermission && mergePermission.restrictUsers) {
      return mergePermission.groupId;
    }
  }

  getMergeGroupTitle(): string {
    const mergePermission = Context.instance.config.spaceplanner.mergepermission;
    if (mergePermission && mergePermission.restrictUsers) {
      return mergePermission.groupTitle;
    }
  }

  async isMergeGroupMembershipOK() {
    let isMembershipOK = true;
    const groupId = this.getMergeGroupId();
    if (groupId) {
      isMembershipOK = false;
      const portal = Context.instance.getPortal();
      const groups = await portal.user.fetchGroups();
      groups && groups.some(group => {
        if (groupId === group.id) {
          isMembershipOK = true;
          return true;
        }
        return false;
      })
    }
    return isMembershipOK;
  }

  getPlan() {
    return Context.instance.spaceplanner.activePlan;
  }

  isOwner(): boolean {
    const plan = Context.instance.spaceplanner.activePlan;
    const item = plan.planServiceItem;
    const username = Context.instance.user.getUsername();
    const owner = item && item.owner && item.owner.toLowerCase();
    const isOwner = username && username.toLowerCase() === owner;
    return !!isOwner
  }

  isOwnerOrAdmin(): boolean {
    if (Context.instance.user.isAdmin()) return true;
    return this.isOwner();
  }

  async merge(mergeOptions: MergeOptions, workingController: WorkingController) {
    this.getPlan().undoManager.clear();
    const isVersioned = this.getPlan().isVersioned;
    if (isVersioned) {
      return this.mergeVersioned(mergeOptions,workingController);
    } else {
      return this.mergeHosted(mergeOptions,workingController);
    }
  }

  async mergeHosted(mergeOptions: MergeOptions, workingController: WorkingController) {
    if (true) throw new Error("Not yet")
  }

  async mergeVersioned(mergeOptions: MergeOptions, workingController: WorkingController) {
    const i18n = Context.instance.i18n;
    let workingContent = i18n.spaceplanner.backstage.mergePlan.mergingPlan;
    if (mergeOptions.type === "pull") {
      workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
    }
    workingController.show({}, workingContent);

    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const plan = this.getPlan();
    const options = {
      plan: plan,
      versionManager: versionManager,
      reconcile: true,
      applyEdits: true,
      post: !!(mergeOptions.type === "push")
    };
    //options.post = false;

    const result = await mergeUtil.analyze(options);
    return result;
  }

}