import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import BaseClass from "../../../util/BaseClass";
//import Context from "../../../context/Context";
import Working from "./Working";
import Rdx from "../../../redux/Rdx";

export default class WorkingController extends BaseClass {

  _node;
  _working;

  close() {
    const node = this._node;
    if (this._working) this._working.close();
    this._node = null;
    if (node) {
      try {
        node.parentNode.removeChild(node);
      } catch(ex) {
        console.error(ex);
      }
    }
  }

  setContent(content) {
    if (typeof content === "string") {
      this.setText(content);
    } else {
      if (this._working) this._working.setContent(content);
    }
  }

  setText(text) {
    if (this._working) this._working.setText(text);
  }

  show(props,content) {
    //const i18n = Context.instance.i18n;
    this.close();
    const minimal = !!(props && props.minimal);

    let text = null;
    if (typeof content === "string") {
      text = content;
      content = null;
    }

    let node = this._node = document.createElement("div");
    document.body.appendChild(node);

    const _onMount = (obj) => {
      this._working = obj;
      if (props && typeof props.onMount === "function") {
        props.onMount(this);
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <Working _onMount={_onMount} content={content} text={text} minimal={minimal}/>
      </Provider>
    ),node);
  }

}
