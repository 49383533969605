import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import { IFeatureItem } from "./featureItemUtil";
import * as component from "../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-list-item";
import {
  CalciteAction,
  CalciteIcon,
  CalciteListItem
} from "@esri/calcite-components-react";


interface Props {
  featureItem: IFeatureItem,
  invalid?: boolean,
  invalidMessage?: string,
  removeable?: boolean,
  selected?: boolean,
  showRadio?: boolean,
  onMouseEnter?: (e) => void,
  onMouseLeave?: (e) => void,
  onRadioClick?: (featureItem: IFeatureItem) => void,
  onRemove?: (featureItem: IFeatureItem) => void,
  onSelect?: (featureItem: IFeatureItem) => boolean,
}

interface State {
}

class FeatureCard extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { featureItem, invalid, invalidMessage, removeable, selected, showRadio } = this.props;
    return (
      <CalciteListItem
        label={featureItem.title}
        description={featureItem.description}
        selected={selected || undefined}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onCalciteListItemSelect={(e) => {
          const sel = e.target.selected;
          if (this.props.onSelect) this.props.onSelect(featureItem);
          if (!sel) component.refresh(this); // to persist the selection, @todo selectionMode="single-persist" at 1.4.2
        }}
      >
        {showRadio && 
          <CalciteAction
            slot="actions-start"
            scale="s"
            icon={featureItem.selected ? "circle-f" : "circle"}
            text={i18n.general.select}
            onClick={() => {
              if (this.props.onRadioClick) this.props.onRadioClick(featureItem);
            }}
          />
        }
        {removeable &&
          <div slot="actions-end" style={{display: "flex", alignItems: "center"}}>
            {invalid &&
              <CalciteIcon 
                className="i-indicator-dot" 
                scale="s" 
                icon="circle-f" 
                title={invalidMessage} 
              />
            }
            <CalciteAction
              scale="s"
              icon="x"
              text={i18n.general.del}
              onClick={() => {
                if (this.props.onRemove) this.props.onRemove(featureItem);
              }}
            />
          </div>
        }
      </CalciteListItem>
    )
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
  }
}

export default connect(mapStateToProps)(FeatureCard);