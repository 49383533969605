import Context from "../../../../../context/Context";
import ClosestFacility from "../../../../../aiim/base/ClosestFacility";
import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import Topic from "../../../../../context/Topic";
import * as dateUtil from "../../../Events/dateUtil";
import * as selectionUtil from "../../../../../aiim/util/selectionUtil";
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";
import { getAttributes } from "../../../../../spaceplanner/components/common/MultipleAssignments/multipleAssignmentsUtil";

export function isBluedotInSite() {
  const graphic = Context.instance.bluedot.graphic;
  let levels = Context.instance.aiim.datasets.levels;
  if (graphic && graphic.geometry && levels && levels.hasSitesById()) {
    let site = levels.findSiteByPoint(Context.instance.bluedot.graphic.geometry);
    if (site) return true;
  }
  return false;
}

export function canSortByHomeDistance() {
  const url = Context.instance.config.closestFacilityServiceUrl;
  if (url) {
    const referenceLayer = Context.instance.session.referenceLayer;
    const item = referenceLayer.homeLocation;
    if (item && item.isValid()) {
      return true;
    }
  }
  return false;
}

export function canSortByBluedotDistance() {
  const url = Context.instance.config.closestFacilityServiceUrl;
  if (url) {
    const graphic = Context.instance.bluedot.graphic;
    return !!graphic;
  }
  return false;
}

export function sortUnits(task) {
  const promise = new Promise(async (resolve,reject) => {
    let filteredItems = task.filteredItems;
    let sortBy = task.sortBy;
    let sortDir = task.sortDir;
    let byName = (sortBy === "name");
    let byDistance = (sortBy === "distanceHome") || (sortBy === "distanceBluedot");

    if (filteredItems && filteredItems.length > 0) {
      if (byName) {
        filteredItems.sort((a,b) => {
            const aName = getAttributeValue(a.attributes, FieldNames.NAME);
            const bName = getAttributeValue(b.attributes, FieldNames.NAME);
            if (aName && bName) {
              return aName.localeCompare(bName);
            }else if(aName) {
              return -1;
            }else if(bName) {
              return 1;
            }
          return 0;
        });

        if (sortDir === "desc") {
          filteredItems.reverse();
        }
        resolve(filteredItems);
      }else if (byDistance) {
        const items = await sortByDistance(task);
        resolve(items);
      }
    }
    resolve([]);
  });
  return promise;
  }

  export async function sortByDistance(task) {
    const filteredItems = task.filteredItems;
    const featureItems = filteredItems.map(item => {
      return {
        feature: item,
        filteredItem: item
      };
    });
    const cf = new ClosestFacility();
    const ds = Context.instance.aiim.datasets.units;
    const source = ds.getSource();
    const useBluedot = task.sortBy === "distanceBluedot";
    let fromFeature, fromSource, voField;

    if (useBluedot) {
      let graphic = Context.instance.bluedot.graphic;
      if (graphic) {
        fromFeature = graphic;
      }
    } else {
      fromFeature = cf.getFromFeature();
      fromSource = cf.getFromSource();
    }

    if (fromFeature && fromFeature.geometry) {
      // use euclidean sort to sort all hotels from nearest to farthest
      cf.euclideanSort(fromFeature, featureItems, voField, fromSource, source);
      const max = cf.proximityMaxItemsToSort;
      const topItems = featureItems.slice(0,max);
      if (topItems.length > 0) {
        try {
          // display top 10 nearest hotels using networkSort
          await cf.networkSort(fromFeature,topItems,true);
          for (let i=0;i<topItems.length;i++) {
            featureItems[i] = topItems[i];
          }
          return featureItems.map(item => item.filteredItem);
        } catch (ex) {
          console.error("Error sorting by distance",ex);
          return featureItems.map(item => item.filteredItem);
        }
      }
    }
}

export function verifyMultipleBookings(startTime, endTime, options, taskObj){

  const taskObjectId = taskObj && taskObj.objectID;

  return new Promise((resolve, reject) => {
    const zuluStartTime = dateUtil.getZulu(new Date(startTime));
    const zuluEndTime = dateUtil.getZulu(new Date(endTime));
    let reservedForUsername = Context.instance.user.getUsername();
    if (options && options.reserveForInfo && options.reserveForInfo.username) {
      reservedForUsername = options.reserveForInfo.username;
    }

    const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
    const startTimeField = reservations.startTimeField;
    const endTimeField = reservations.endTimeField;
    const stateField = reservations.stateField;
    let url = reservations.url;
    const usernameField = reservations.reservedForUsernameField;

    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }

    // alert user for multiple bookings at the same time
    if (!url) return Promise.resolve();
    url = Context.checkMixedContent(url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = false;
    query.returnZ = true;
    query.orderByFields = [startTimeField];

    let w3;
    w3 = "(("+ usernameField + " = '" + selectionUtil.escSqlQuote(reservedForUsername) + "')";
    w3 += " AND ("+stateField+" IN (0,1,4))"; // pending, approved, checked-in
    w3 += " AND ("+startTimeField + " < TIMESTAMP '" + zuluEndTime+"')";
    w3 += " AND (" + endTimeField + " > TIMESTAMP '" + zuluStartTime + "'))";
    if (taskObj.objectIds) {
      w3 += " AND " + objectIdField + " NOT IN (" + taskObj.objectIds.join(",") + ")";
    }

    query.where = w3;
    return task.execute(query)
    .then((result)=> {
      const currentTime = new Date().getTime();
      if(result && result.features && result.features.length > 0) {
        result.features = result.features.filter((feature)=> {
          let endTime;
          const attributes = feature.attributes;
          if(attributes) {
            endTime = getAttributeValue(attributes, endTimeField);
            const objectID = getAttributeValue(attributes, objectIdField)

            if (endTime <= currentTime) {}
            else if (taskObj.updateBooking && (objectID === taskObjectId)) {}
            else return feature;
          }
          return false;
        })
      }
      return result;
    })
    .then((result)=>{
      let unitIds = [];
      if(result && result.features && result.features.length > 0) {
        result.features.forEach(feature => {
          const unitId = getAttributeValue(feature.attributes, FieldNames.UNIT_ID);
          if(unitId) unitIds.push(unitId);
        })
      }
      return unitIds;
    })
    .then((unitIds)=> {
      if(!unitIds || unitIds.length === 0) {
        return;
      }
      let values = [];
      unitIds.forEach(id => {
        let v = "'"+selectionUtil.escSqlQuote(id)+"'";
        values.push(v);
      });

      // filter for hotel bookings (discard meeting room bookings)
      const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
      const unitId = FieldNames.UNIT_ID;
      const areaId = FieldNames.AREA_ID;
      let url = units.url;
      url = Context.checkMixedContent(url);
      const lib = Context.getInstance().lib;
      const task = new lib.esri.QueryTask({url: url});
      const query = new lib.esri.Query();
      query.outFields = ["*"];
      query.returnGeometry = false;
      query.returnZ = true;
      let w = "(" + unitId + " IN (" + values.join(",") + "))";
      w += " AND ("+ areaId + " IS NOT NULL)";
      query.where = w;
      return task.execute(query);
    })
    .then((result)=> {
      const features = [];
      if (result && result.features && result.features.length > 0) {
        result.features.some(f => {
          if (!Context.instance.aiim.isHotelMeetingRoom(f)) {
            features.push(f);
            return true;
          }
          return false;
        })
      }
      if (features.length > 0) {
        resolve("hasExistingBookings");
      } else {
        resolve();
      }
    })
    .catch((e)=> {
      console.error(e);
      Topic.publish(Topic.publishErrorUpdatingData())
      reject(e);
    })
  })
}


export function getSiteName (selectedLevelId) {
  const levelsDataset = Context.instance.aiim.datasets.levels;
  let levelData = levelsDataset && levelsDataset.getLevelData(selectedLevelId);
  let siteName = levelData && levelData.siteName;
  return siteName;
}

export function getSitesByAreas (rdxAreaName) {
  const layer2D = Context.getInstance().aiim.datasets.units &&
                    Context.getInstance().aiim.datasets.units.layer2D;
  return new Promise ((resolve, reject) => {
    Context.instance.hotelingUnits.then(units => {
      Context.instance.areas.then(areas => {
        let siteFilterDropdown = [];
        units.forEach(unit => {
        const unitAttributes = getAttributes(unit);
        const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
        const levelId = levelIdField && getAttributeValue(unitAttributes,levelIdField.name);
        const siteName = getSiteName(levelId);
        const areaId = getAttributeValue(unitAttributes, FieldNames.AREA_ID);
        let areaName, areaType;
          areas.forEach(area => {
            const areaAttributes = getAttributes(area);
            const id = getAttributeValue(areaAttributes, FieldNames.AREA_ID);
            areaType = getAttributeValue(areaAttributes, FieldNames.AREA_TYPE);
            if(id === areaId) areaName = getAttributeValue(areaAttributes, FieldNames.AREA_NAME);
          })
          if(!rdxAreaName || rdxAreaName.length === 0) {
            if(!siteFilterDropdown.includes(siteName)) siteFilterDropdown.push(siteName);
          }
          if(((areaType === "hotel") || (areaType === "hotdesk") || (areaType === "workspace")) && rdxAreaName.includes(areaName)) {
            if(!siteFilterDropdown.includes(siteName)) siteFilterDropdown.push(siteName);
          }
        })
        resolve ({
          siteFilterDropdown: siteFilterDropdown,
          isError: false
        })
        return;
      })
      .catch(()=> {
        resolve ({
          siteFilterDropdown: [],
          isError: true
        })
        return;
      })
    })
    .catch(()=> {
      resolve ({
        siteFilterDropdown: [],
        isError: true
      })
      return;
    })
  })
}

export function getSpaceTypesBySiteArea(rdxSiteName, rdxAreaName) {
  const layer2D = Context.getInstance().aiim.datasets.units &&
                    Context.getInstance().aiim.datasets.units.layer2D;
  return new Promise ((resolve, reject) => {
    Context.instance.hotelingUnits.then(units => {
      Context.instance.areas.then(areas => {
      let spaceTypeDropdown = [];
      units.forEach(unit => {
        const unitAttributes = getAttributes(unit);
        const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
        const levelId = levelIdField && getAttributeValue(unitAttributes,levelIdField.name);
        const siteName = getSiteName(levelId);
        const spaceType = getAttributeValue(unitAttributes, FieldNames.UNITS_USE_TYPE);
        const areaId = getAttributeValue(unitAttributes, FieldNames.AREA_ID);
        let areaName, areaType;
        areas.forEach(area => {
          const areaAttributes = getAttributes(area);
          const id = getAttributeValue(areaAttributes, FieldNames.AREA_ID);
          areaType = getAttributeValue(areaAttributes, FieldNames.AREA_TYPE);
          if(id === areaId) areaName = getAttributeValue(areaAttributes, FieldNames.AREA_NAME);
        })

        if(!rdxSiteName && (!rdxAreaName|| (rdxAreaName && rdxAreaName.length === 0))){
          if(!spaceTypeDropdown.includes(spaceType)) spaceTypeDropdown.push(spaceType);
        } else if(!rdxAreaName || (rdxAreaName && rdxAreaName.length === 0)) {
          if((rdxSiteName === siteName) && !spaceTypeDropdown.includes(spaceType)) spaceTypeDropdown.push(spaceType);
        } else if(!rdxSiteName) {
          if((areaType === "hotel") || (areaType === "hotdesk") || (areaType === "workspace") && (rdxAreaName.includes(areaName))) {
            if(!spaceTypeDropdown.includes(spaceType)) spaceTypeDropdown.push(spaceType);
          }

        } else {
         if((areaType === "hotel") || (areaType === "hotdesk") || (areaType === "workspace") && (rdxAreaName.includes(areaName)) && (rdxSiteName === siteName) &&
              !spaceTypeDropdown.includes(spaceType)){
                spaceTypeDropdown.push(spaceType);
            }
        }
      })
      if(spaceTypeDropdown && spaceTypeDropdown.length > 0) {
        resolve ({
          spaceTypeDropdown: spaceTypeDropdown,
          isError: false
        })
        return;
      } else {
        resolve ({
          spaceTypeDropdown: [],
          isError: true
        })
      }
    })
    .catch(() => {
      resolve ({
        spaceTypeDropdown: [],
        isError: true
      })
      return;
    })
  })
.catch(() => {
  resolve ({
    spaceTypeDropdown: [],
    isError: true
  })
  return;
})
})
}

export function getEquipmentBySpaceTypeAreaSite(rdxAreaName, rdxSiteName, rdxSpaceType) {
  const layer2D = Context.getInstance().aiim.datasets.units &&
                    Context.getInstance().aiim.datasets.units.layer2D;
  return new Promise ((resolve, reject) => {
    Context.instance.hotelingUnits.then(units => {
      Context.instance.areas.then(areas => {
        let equipmentsDropdown = [];
        units.forEach(unit => {
          const unitAttributes = getAttributes(unit);
          const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
          const levelId = levelIdField && getAttributeValue(unitAttributes,levelIdField.name);
          const siteName = getSiteName(levelId);
          const spaceType = getAttributeValue(unitAttributes, FieldNames.UNITS_USE_TYPE);
          const areaId = getAttributeValue(unitAttributes, FieldNames.AREA_ID);
          let areaName, areaType;
          areas.forEach(area => {
            const areaAttributes = getAttributes(area);
            const id = getAttributeValue(areaAttributes, FieldNames.AREA_ID);
            areaType = getAttributeValue(areaAttributes, FieldNames.AREA_TYPE);
            if(id === areaId) areaName = getAttributeValue(areaAttributes, FieldNames.AREA_NAME);
          })
          const hotelEquipments = getHotelEquipmentFields();
          if(!rdxSiteName && (!rdxAreaName || (rdxAreaName && rdxAreaName.length === 0)) &&
            (!rdxSpaceType || (rdxSpaceType && rdxSpaceType.length === 0))){
              hotelEquipments.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
                }
              })
            return;
          } else if (!rdxSiteName && (!rdxAreaName || (rdxAreaName && rdxAreaName.length === 0))
              && rdxSpaceType.includes(spaceType)) {
              hotelEquipments.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
                }
              })
          } else if ((!rdxAreaName || (rdxAreaName && rdxAreaName.length === 0))
              && (!rdxSpaceType || (rdxSpaceType && rdxSpaceType.length === 0))
              && (rdxSiteName === siteName)) {
              hotelEquipments.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
                }
              })
          } else if ((!rdxSpaceType || (rdxSpaceType && rdxSpaceType.length === 0)) && !rdxSiteName
            && rdxAreaName.includes(areaName)) {
              hotelEquipments.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentsDropdown.includes(fieldName)) equipmentsDropdown.push(fieldName);
                }
              })
          } else if(!rdxSiteName && rdxSpaceType.includes(spaceType) && rdxAreaName.includes(areaName)) {
            hotelEquipments.forEach((fieldName)=> {
              const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
              if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
              }
            })
          } else if((!rdxAreaName || (rdxAreaName && rdxAreaName.length === 0))
            && (rdxSiteName === siteName) && rdxSpaceType.includes(spaceType)) {
              hotelEquipments.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
                }
              })
          } else if((!rdxSpaceType || (rdxSpaceType && rdxSpaceType.length === 0))
            && (rdxAreaName.includes(areaName)) && (rdxSiteName === siteName)) {
            hotelEquipments.forEach((fieldName)=> {
              const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
              if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
              }
            })
          }
          else if ((rdxSiteName === siteName) && (rdxSpaceType && (rdxSpaceType.includes(spaceType)))
          && (rdxAreaName && rdxAreaName.includes(areaName))){
            hotelEquipments.forEach((fieldName)=> {
              const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
              if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                if(!equipmentsDropdown.includes(fieldName))equipmentsDropdown.push(fieldName);
              }
            })
          }
        })
        if(equipmentsDropdown && equipmentsDropdown.length > 0 ) {
          resolve ({
            equipmentsDropdown: equipmentsDropdown,
            isError: false
          })
          return;
        } else {
          resolve ({
            equipmentsDropdown: [],
            isError: false
          })
          return;
        }
      })
      .catch(() => {
        resolve ({
          equipmentsDropdown: [],
          isError: true
        })
        return;
      })
    })
    .catch(() => {
      resolve ({
        equipmentsDropdown: [],
        isError: true
      })
      return;
    })
  })
}

export function getHotelEquipmentFields() {
  const units = Context.getInstance().aiim.datasets.units;
  let hotelEquipmentFields = [];
  if(!units) return false;
  if(units && units.layer2D && units.layer2D.fields && units.layer2D.fields.length > 0) {
    const fields = units.layer2D.fields;
    fields.forEach((field) => {
      if(field.domain && field.domain.name && (field.domain.name.toLowerCase() === "dom_equipment_hotel"
          || field.domain.name.toLowerCase() === "dom_equipment")
          && field.domain.type === "coded-value"
          && (field.type === "small-integer"||field.type === "integer" || field.type === "long")) {
        hotelEquipmentFields.push(field.name);
      }
    })
    return hotelEquipmentFields;
  }
}

export function getHotelingUnits() {
  Context.instance._loadHotelingUnits();
}
