import React from "react";

//import AssignPersonToUnitButton from "../common/AssignPersonToUnitButton";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Items from "../common/Items";
import OfficePlan from "../../base/OfficePlan";
import UnitsAssignTo from "../common/UnitsAssignTo";
import Popup from "../common/Popup/Popup";
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import ListHeader from "../common/ListHeader";
import ListCount from "../common/ListCount";

// Styled Components
import { FooterContainer } from "../../styles/Assets/assetStyles";
import { Panel } from "../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemSubtitle,
  ListItemAttribute,
  ListItemOverlay,
  ListItemOverlayContent,
  ListItemOverlayHeader,
  ListItemButtons
} from "../../styles/Common/list";

export default class UnitsPanel extends React.Component {
  constructor(props) {
    super(props);
    const source = officePlanUtil.getUnitsSource();
    let nameFieldName = FieldNames.NAME;
    const unitField = Context.instance.aiim.getUnitName();

    let asnFieldName = FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE;
    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields, nameFieldName);
      if (f) nameFieldName = f.name;
      f = aiimUtil.findField(fields, asnFieldName);
      if (f) asnFieldName = f.name;
    }
    const queryCriteriaKey = this.getPanelKey();
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      this.getSourceKey()
    );
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = unitField;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc"
      };
    }

    // #3742 Don't show NULL assignment_type units in Unassigned panel for now
    // const wh = `${asnFieldName} = 'none' OR ${asnFieldName} IS NULL`;
    const wh = asnFieldName + " = 'none'";
    queryCriteria.requiredExpression = wh;
    queryCriteria.where = queryCriteria.makeWhere(queryCriteria.getSource());

    this.state = component.newState({});
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.RefreshUnitName,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getPanelKey() {
    return this.props.panelKey;
  }

  getSourceKey() {
    return this.props.sourceKey;
  }

  getRdxFeatureItemsKey = () => {
    const queryCriteriaKey = this.getPanelKey() || "";
    return queryCriteriaKey + "_FeatureItems";
  };

  render() {
    const { layerCaption, filterTooltip } = this.props;
    const i18n = Context.instance.i18n;
    const queryCriteriaKey = this.getPanelKey() || "";
    return (
      <Panel>
        <ListHeader
          title={i18n.spaceplanner.assets.units.unassignedTitle}
          layerCaption={layerCaption}
          filterTooltip={filterTooltip}
          queryCriteriaKey={queryCriteriaKey}
        ></ListHeader>
        <Items
          queryCriteriaKey={queryCriteriaKey}
          rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
          renderItem={this.renderItem}
          requeryOnPlanActions={[
            OfficePlan.Action_AssignmentsUpdated,
            OfficePlan.Action_PersonAssignedToUnit,
            OfficePlan.Action_PersonUnassignedFromUnit,
            OfficePlan.Action_UnitUnassigned
          ]}
          isShowAllButton={true}
        />
        <FooterContainer>
          <ListCount
            queryCriteriaKey={queryCriteriaKey}
            label={i18n.spaceplanner.assets.units.countPattern}
          ></ListCount>

          <UnitsAssignTo
            placement="bottom"
            rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
            disableUnassign={true}
          />
        </FooterContainer>
      </Panel>
    );
  }

  renderItem(source, featureItem, index) {
    const i18n = Context.instance.i18n;
    const unitField = Context.instance.aiim.getUnitName();
    const key = featureItem.key;
    const attributes = featureItem.feature.attributes;
    const name = aiimUtil.getAttributeValue(attributes, unitField);
    const labelWithNameString = i18n.spaceplanner.units.assignTo.labelWithName;
    const labelWithName = labelWithNameString.replace('{name}', name);

    let siteName, facilityName, levelNumber;
    const zInfo = Context.instance.aiim.getZInfo(source,featureItem.feature);
    const levelData = zInfo && zInfo.levelData
    if (levelData) {
      siteName = levelData.siteName;
      facilityName = levelData.facilityName;
      levelNumber = levelData.levelNumber;
      if (!siteName) siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      //console.log("siteName",siteName,"facilityName",facilityName,"levelNumber",levelNumber)
    } else {
      siteName = aiimUtil.getAttributeValue(attributes,FieldNames.SITE_NAME);
      facilityName = aiimUtil.getAttributeValue(attributes,FieldNames.FACILITY_NAME);
      levelNumber = aiimUtil.getAttributeValue(attributes,FieldNames.LEVEL_NUMBER);
    }

    let facilityLevel = null;
    if (facilityName && typeof levelNumber === "number") {
      const pattern = i18n.spaceplanner.units.facilityFloorPattern;
      facilityLevel = pattern.replace("{facility}", facilityName);
      facilityLevel = facilityLevel.replace("{level}", levelNumber);
    } else {
      facilityLevel = (facilityName || levelNumber || null);
    }
    const isUnassigned = officePlanUtil.isUnitUnassigned(featureItem);

    const clicked = event => {
      Topic.publish(Topic.ShowItemPopup, {
        title: name,
        source: source,
        featureItem: featureItem,
        fetchFeatureItemGeometry: true,
        zoom: true,
        content: (
          <Popup
            sourceKey={this.getSourceKey()}
            featureItem={featureItem}
          />
        )
      });
    };

    return (
      <ListItem unassigned={isUnassigned} key={key} onClick={clicked}>
        <ListItemTitle>{name}</ListItemTitle>
        <ListItemSubtitle>
          <ListItemAttribute>{siteName || null}</ListItemAttribute>
          <ListItemAttribute>{facilityLevel}</ListItemAttribute>
        </ListItemSubtitle>
        <ListItemOverlay>
          <ListItemOverlayContent>
            <ListItemOverlayHeader>{labelWithName}</ListItemOverlayHeader>
            <ListItemButtons>
              <UnitsAssignTo
                featureItems={[featureItem]}
                rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
                disableUnassign={isUnassigned}
                enableUnassign={!isUnassigned}
                name={name}
                buttons
              />
            </ListItemButtons>
          </ListItemOverlayContent>
        </ListItemOverlay>
      </ListItem>
    );
  }
}
