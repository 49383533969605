import moment from "moment";
import { IFeature } from "@esri/arcgis-rest-types";
import { IRecurrenceOptions, IRecurrenceSeries, getRecurringDates } from "../BookingRecurrence";
import { ScheduleInformation, Event } from "@microsoft/microsoft-graph-types";
import type EsriReservationSchema from "./EsriReservationSchema";
import type Office365 from "./Office365";
import { IIndoorsEvent, IScheduleParams } from "./Office365";
import type ItemReference from "../../../../../../aiim/base/ItemReference";

export interface IBookingData {
  booking: __esri.Graphic,
  occupant?: __esri.Graphic,
  unit?: __esri.Graphic,
  recurrenceId?: string
}
export interface IOriginalReservation {
  attributes: Record<string, any>,
  booking: IIndoorsEvent
}
export interface IScheduleItem {
  oid: string,
  fromDate: number,
  toDate: number,
  title: string,
  description: string,
  unitName: string,
  levelId: string,
  reserveeFullName: string,
  reserveeUsername: string,
  feature: __esri.Graphic
}
export interface IBookingSystem {
  bookWorkspace: (params: IBookingParams) => Promise<IBookWorkspaceResults>,
  cancelBooking: (params: ICancelBookingParams | ICancelBookingParams[]) => Promise<__esri.RequestResponse | never>,
  checkAvailability: (
    unitIds: string[],
    checkIn: string,
    checkOut: string,
    ignoreObjectIds?: boolean | number[],
    params?: ICheckAvailabilityParams,
    signal?: AbortSignal
  ) => Promise<string[] | ScheduleInformation[]>,
  getBookingSchedule: (params: IBookingParams | IScheduleParams | IScheduleParams[], signal?: AbortSignal) => Promise<string[] | { value: ScheduleInformation[] }>,
  getRecurringDates: (options: IRecurrenceOptions, checkIn: moment.Moment, checkOut: moment.Moment) =>
    { start: moment.Moment, end: moment.Moment }[],
  type: "esri" | "office365",
  updateBooking: (params: IBookingParams) => Promise<__esri.RequestResponse | IBookWorkspaceResults>
}
export interface IBookWorkspaceResults {
  reservations: IFeature[],
  rejections: IFeature[],
  params?: IBookingParams
}
export interface IEsriBookingParams extends ICheckAvailabilityParams, ICommonBookingProps {
  forLock?: boolean,
  isUpdateBooking?: boolean,
  noAutoDescription?: boolean,
  origReservation?: __esri.Graphic | IFeature,
  person?: { username: string, fullName: string },
  reservationObjectIds?: number[],
  state?: number
}
export interface IOffice365BookingParams extends ICheckAvailabilityParams, ICommonBookingProps {
  email?: string,
  event: Event,
  eventId: string,  
  unitName?: string
}
export type IBookingParams = IEsriBookingParams | IOffice365BookingParams;

export interface IEsriCancelParams extends Record<string, any> { };
export interface IOffice365CancelParams {
  eventId: string
}
export type ICancelBookingParams = IEsriCancelParams | IOffice365CancelParams;
export interface IDateOptions {
  allDay: boolean,
  checkIn?: Date,
  checkOut?: Date,
  checkInDate?: Date | string,
  checkOutDate?: Date | string,
}
export interface ICheckAvailabilityParams extends Partial<IDateOptions> {
  areaConfigByAreaId?: {
    [prop: string]: IWorkspaceAreaConfig
  },
  reserveForInfo?: IReserveForInfo,
  hotelUnits?: __esri.Graphic[],
  recurrence?: IRecurrenceOptions,
  series?: IRecurrenceSeries,
  task?: {
    canReserveInfo?: ICanReserveInfo
  },
  unitId?: string,
}
interface IAreaConfig {
  maxBookingsPerPerson: number,
  maxDaysPerBooking: number,
  maxDaysInAdvance: number
}
export interface IWorkspaceAreaConfig {
  hotel: IAreaConfig,
  meetingRoom: Omit<IAreaConfig, "maxDaysPerBooking"> & { maxHoursPerBooking: number }
}
export interface ICanReserveInfo {
  unitId: string,
  canReserve: boolean,
  objectIdsToCancel: number[]
}
export interface IReserveForInfo {
  areaIDs?: string[],
  email?: string,
  fullName?: string,
  occupantFeature?: IFeature,
  username?: string
}
export interface ICommonBookingProps extends Partial<IDateOptions> {
  areaConfigByAreaId?: {
    [prop: string]: IWorkspaceAreaConfig
  },
  autoCancelUpdates?: IFeature[],
  bookingForOther?: boolean,
  bookingType?: "hotel" | "meetingRooms",
  description?: string,
  item?: ItemReference,
  objectIds?: number[],
  operation?: "updateBookingTime",
  others?: IFeature[],
  title?: string,
  unit?: __esri.Graphic
}
export interface IBookingTask extends ICommonBookingProps {
  bookingSuccessful?: boolean,
  bookingSystem: IBookingSystem,
  canReserveInfo?: ICanReserveInfo,
  facilityName?: string,
  levelName?: string,
  makeBooking?: boolean,
  options: IBookingParams,
  origBooking?: {
    fromDate: Date,
    toDate: Date
  },
  others?: IFeature[],
  renderBookingConfirmed?: (
    data: IBookWorkspaceResults | boolean | __esri.RequestResponse,
    params: IBookingParams | boolean,
    task: IBookingTask) => void,
  reservation?: __esri.Graphic | IFeature,
  retry?: () => void,
  scheduleEmail?: string,
  series?: IRecurrenceSeries,
  unitName?: string,
  updateBooking?: boolean
}
export interface IEsriBookingTask extends IBookingTask {
  bookingSystem: EsriReservationSchema,
  item?: ItemReference,
  objectIds: number[]
}
export interface IOffice365BookingTask extends IBookingTask {
  bookingSystem: Office365,
  booking?: Event,
  eventId: string,
  seriesMasterId?: string
}
export interface IBookingStatus {
  checksSuccessful?: boolean,
  error?: Error & {
    code?: string,
    responseCode?: string,
    submessage?: string
  },
  hasBookingConflict?: boolean,
  ok?: boolean,
  warnings?: { msg: string, title: string }[]
}
/** Base class for differnet booking systems. */
export default abstract class BookingSystem implements IBookingSystem {
  type: IBookingSystem["type"];

  abstract bookWorkspace: (params: IBookingParams) =>
    ReturnType<IBookingSystem["bookWorkspace"]>;

  abstract cancelBooking: (params: ICancelBookingParams | ICancelBookingParams[]) =>
    ReturnType<IBookingSystem["cancelBooking"]>;

  abstract checkAvailability: (...args: Parameters<IBookingSystem["checkAvailability"]>) =>
    ReturnType<IBookingSystem["checkAvailability"]>;

  abstract getBookingSchedule: (...args: Parameters<IBookingSystem["getBookingSchedule"]>) =>
    ReturnType<IBookingSystem["getBookingSchedule"]>;
  
  getRecurringDates(options: IRecurrenceOptions, checkIn: moment.Moment, checkOut: moment.Moment) {
    return getRecurringDates(options, checkIn, checkOut);
  }

  abstract updateBooking: (params: IBookingParams) =>
    ReturnType<IBookingSystem["updateBooking"]>;
}