// App components
import * as CrudComment from "../../base/transaction/comment";
import Comment from "./Comment";
import Context from "../../../context/Context";
import OfficePlan from "../../../spaceplanner/base/OfficePlan";
import Topic from "../../../context/Topic";

export function activateSketch(type, objectId, url){
    const lib = Context.getInstance().lib;
    const view = Context.getInstance().views.mapView;
    const graphicsLayer = ensureGraphicsLayer(view,"comments-temp");
    let color = "#e6e6e6";
    let svm = new lib.esri.SketchViewModel({
        view: view,
        layer: graphicsLayer,
        pointSymbol: new Context.instance.lib.esri.SimpleMarkerSymbol({
            style: "circle",
            size: 6,
            color: color,
            outline: { color: color, width: 1 }
        })
    });
    svm.on("create",event => {
        if (event.state === "complete") {
            graphicsLayer.remove(event.graphic);
            const geometry = {
                x: event.graphic.geometry.x,
                y: event.graphic.geometry.y,
                // z: event.graphic.geometry.z,
                spatialReference: event.graphic.geometry.spatialReference
            }
            if(OfficePlan.getActive().commentsLayer.hasM) geometry.m = 0;

            const obj = {
                commentId : objectId,
                geometry : geometry
            }
            Comment.cancelSketch();
            CrudComment.editComment(obj, url).then(()=> {
                //svm.create(type);
                Topic.publish(Topic.LoadComments, {});
            })
            .catch(error => {
                console.log(error);
                Topic.publishErrorUpdatingData(error.description);
            })
        }
    });
    Context.instance.views.toggleClickHandlers("pause");
    window.addEventListener("keydown",checkEsc);
    svm.create(type);
    return svm;
}

export function cancelSketch(svm) {
    if (svm) {
        svm.cancel();
        svm.destroy();
        Context.instance.views.toggleClickHandlers("resume");
        window.removeEventListener("keydown",checkEsc);
    }
}

export function checkEsc(event) {
  try {
    if (event && event.keyCode === 27) {
      //console.log("SketchView escape pressed .......");
      Comment.cancelSketch();
    }
  } catch(ex) {
    console.error(ex);
  }
}

export function ensureGraphicsLayer(view,layerId) {
    let layer = view.map.findLayerById(layerId);
    if (!layer) {
        const lib = Context.instance.lib;
        layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
        });
        layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: Context.instance.config.graphicElevationOffset,
        unit: "meters"
        };
        view.map.add(layer);
    }
    return layer;
}
