import BaseClass from "../../util/BaseClass"
import Context from "../../context/Context";
import DirectionsUtil from "../../util/DirectionsUtil";
import * as aiimUtil from "../util/aiimUtil";
import RouteBarriers from "../network/RouteBarriers";
import * as projectionUtil from "../../../src/util/projectionUtil";

/*
  Result - Facility Feature (7504)
    {
      "ObjectID": 1,
      "Name": "ESRI.RED.MAIN.E1-001",
      "SourceID": 2,
      "SourceOID": 8087,
      "PosAlong": 0.8724200975250604,
      "SideOfEdge": 1,
      "CurbApproach": 0,
      "Status": 0,
      "Attr_Length": 0,
      "Attr_WalkTime": 0,
      "Cutoff_Length": null,
      "Cutoff_WalkTime": null
    }

  Result - Route Feature
    {
      "ObjectID": 4,
      "FacilityID": 5654,
      "FacilityRank": 4,
      "Name": "Home_#10004 - Amit Sinha",
      "IncidentCurbApproach": 1,
      "FacilityCurbApproach": 2,
      "IncidentID": 1,
      "Total_WalkTime": 0.15196517338753993,
      "Total_Length": 9.885986671183883,
      "Shape_Length": 9.885986648777115
    }

 */

export default class ClosestFacility extends BaseClass {

  defaultCutoff = 1000;
  defaultTargetFacilityCount = 1000;
  directionsUtil = new DirectionsUtil();
  distanceUnits = "esriNAUFeet"; // not currently used
  proximityMaxItemsToSort = 10;
  proximityMetersPerLevel = 40;
  results;
  serviceDistanceUnits = "esriNAUMeters";
  serviceTimeUnits = "esriNAUMinutes";
  timeAttributeName = "WalkTime";

  constructor(props?) {
    super(props);
    const config = Context.getInstance().config;
    let v = config.proximityMaxItemsToSort;
    if (typeof v === "number" && v > 0) {
      this.proximityMaxItemsToSort = v;
    }
    v = config.proximityMetersPerLevel;
    if (typeof v === "number") {
      this.proximityMetersPerLevel = v;
    }
  }

  cleanItems(featureItems) {
    featureItems.forEach(featureItem => {
      delete featureItem.distance;
      delete featureItem.hasEucledianDistance;
      delete featureItem.eucledianDistance;
      delete featureItem.cfInfo;
    });
  }

  euclideanSort(fromFeature,featureItems,voField,fromSource,source) {
    //let start = Date.now();
    const lib = Context.getInstance().lib;
    let requiresSort = false;
    let distanceUnit = "meters";
    let voCost = this.proximityMetersPerLevel;
    let fromGeometry = fromFeature.geometry;
    let pt1 = null, metersPer = null;
    if (fromGeometry && fromGeometry.spatialReference) {
      let sr = fromGeometry.spatialReference;
      metersPer = lib.esri.unitUtils.getMetersPerUnit(sr);
      if (typeof metersPer !== "number" || isNaN(metersPer) || !isFinite(metersPer)) {
        console.warn("ClosestFacility - Invalid metersPerUnit:",metersPer,", for spatialReference:",sr);
        metersPer = null;
      }
      if (fromGeometry.type === "point" && fromGeometry.hasZ) {
        pt1 = fromGeometry;
      } else if (fromGeometry.type === "polygon" && fromGeometry.centroid && fromGeometry.centroid.hasZ) {
        pt1 = fromGeometry.centroid;
      }
    }

    const levelsDataset = Context.instance.aiim.datasets.levels;
    const fromLidField = Context.instance.aiim.getLevelIdField(fromSource);
    const lidField = Context.instance.aiim.getLevelIdField(source);

    let voFrom = fromFeature.xtnVerticalOrder, considerVO = false, ldFrom;
    if (fromLidField && levelsDataset) {
      let lid = aiimUtil.getAttributeValue(fromFeature.attributes,fromLidField.name);
      ldFrom = levelsDataset.getLevelData(lid);
    }
    if (ldFrom) {
      voFrom = ldFrom && ldFrom.verticalOrder;
      // if (voField) {
      //   var voFromB = aiimUtil.getAttributeValue(fromFeature.attributes,voField);
      //   if (voFrom !== voFromB) console.log("voFrom !== voFromB *********************************** ")
      // }
      // console.log("voFrom",voFrom,"voFromB",voFromB)
    } else if (voField) {
      voFrom = aiimUtil.getAttributeValue(fromFeature.attributes,voField);
    }
    if (typeof voFrom !== "number" || isNaN(voFrom) || !isFinite(voFrom)) {
      voField = null;
    } else {
      considerVO = true;
    }

    featureItems.forEach(featureItem => {
      let feature = featureItem.feature;
      let geometry = feature.geometry;
      if (fromGeometry && geometry) {
        let distance = null, pt2 = null;
        if (pt1) {
          if (geometry.type === "point" && geometry.hasZ) {
            pt2 = geometry;
          } else if (geometry.type === "polygon" && geometry.centroid && geometry.centroid.hasZ) {
            pt2 = geometry.centroid;
          }
        }
        //console.log("pt2",pt2,geometry);
        if (pt1 && pt2) {
          distance = Math.sqrt(
            Math.pow((pt2.x - pt1.x),2) + Math.pow((pt2.y - pt1.y),2) + Math.pow((pt2.z - pt1.z),2)
          );
          // let distance1 = Math.sqrt(
          //   Math.pow((pt2.x - pt1.x),2) + Math.pow((pt2.y - pt1.y),2) + Math.pow((pt2.z - pt1.z),2)
          // );
          // let distance2 = Math.sqrt(
          //   Math.pow((pt2.x - pt1.x),2) + Math.pow((pt2.y - pt1.y),2) + Math.pow((0 - pt1.z),2)
          // );
          // let z1 = pt2.z;
          // console.log("xyzDistance",z1,distance1,distance2);
        } else {
          //console.log("geometryEngine",geometry);
          // distance = lib.esri.geometryEngine.distance(fromGeometry,geometry,distanceUnit);
          distance = projectionUtil.distance(fromGeometry,geometry,distanceUnit);
          if (typeof metersPer === "number") {
            distance = (distance * metersPer);
          }
          // let distance1 = lib.esri.geometryEngine.distance(fromGeometry,geometry,distanceUnit);
          // let z1 = geometry.z;
          // geometry.z = 0;
          // let distance2 = lib.esri.geometryEngine.distance(fromGeometry,geometry,distanceUnit);
          // console.log("geometryEngineDistance",z1,distance1,distance2);
        }
        if (typeof distance === "number" && !isNaN(distance) && isFinite(distance)) {
          if (considerVO) {
            let ld, vo;
            if (lidField && levelsDataset) {
              let lid = aiimUtil.getAttributeValue(feature.attributes,lidField.name);
              ld = levelsDataset.getLevelData(lid);
            }
            if (ld) {
              vo = ld && ld.verticalOrder;
              // if (voField) {
              //   var voB = aiimUtil.getAttributeValue(feature.attributes,voField);
              //   if (vo !== voB) console.log("vo !== voB *********************************** ")
              // }
              // console.log("vo",vo,"voB",voB)
            } else if (voField) {
              vo = aiimUtil.getAttributeValue(feature.attributes,voField);
            }
            if (typeof vo === "number" && !isNaN(vo) && isFinite(vo)) {
              let voDiff = Math.abs(voFrom - vo);
              if (voDiff > 0) {
                distance += voDiff * voCost;
              }
            }
          }
          requiresSort = true;
          featureItem.hasEucledianDistance = true;
          featureItem.eucledianDistance = distance;
        }
      }
    });
    if (requiresSort) {
      featureItems.sort((cfItemA,cfItemB) => {
        if (cfItemA.hasEucledianDistance && cfItemB.hasEucledianDistance) {
          let a = cfItemA.eucledianDistance;
          let b = cfItemB.eucledianDistance;
          if (a < b) return -1;
          if (a > b) return 1;
        } else if (cfItemA.hasEucledianDistance) {
          return -1;
        } else if (cfItemB.hasEucledianDistance) {
          return 1;
        }
        return 0;
      });

      // TODO temporary
      //let elapsed = Date.now() - start;
      //console.warn("ClosestFacility::euclideanSort",elapsed,"milli-seconds, ", featureItems.length, "features")

      // featureItems.forEach(featureItem => {
      //   console.log("feature=",featureItem.feature);
      // });
    }
  }

  facilitiesFromFeatureItems(featureItems, noJSON) {
    const lib = Context.getInstance().lib;
    const features = [];
    featureItems.forEach(featureItem => {
      const feature = featureItem.feature;
      // let graphic = new lib.esri.Graphic(feature.geometry,feature.atttributes);
      const graphic = noJSON
      ? new lib.esri.Graphic({
          geometry: feature.geometry,
          attributes: feature.attributes
        })
      : lib.esri.Graphic.fromJSON(feature);
      if(graphic.geometry.type === "polygon" && graphic.geometry.centroid) {
        graphic.geometry = graphic.geometry.centroid;
      }
      if (graphic.geometry.type === "point" &&
          graphic.geometry.z === undefined && !graphic.geometry.hasZ) {
        //console.warn("Stop does not have a Z value",graphic);
        graphic.geometry.z = 0;
        graphic.geometry.hasZ = true;
      }
      features.push(graphic);
    });
    const facilities = new lib.esri.FeatureSet({
      features: features
    });
    const json = facilities.toJSON();
    json.type = "features";
    json.doNotLocateOnRestrictedElements = true; // TODO?
    return JSON.stringify(json);
  }

  getFromFeature() {
    const referenceLayer = Context.getInstance().session.referenceLayer;
    const item = referenceLayer.homeLocation;
    if (item && item.isValid()) {
      return item.getFeature();
    }
  }

  getFromSource() {
    const referenceLayer = Context.getInstance().session.referenceLayer;
    const item = referenceLayer.homeLocation;
    return item && item.getSource();
  }

  _getSolveUrl() {
    let url = this._getServiceUrl();
    if (url) {
      url = url + "/solveClosestFacility"; // TODO
    }
    return url;
  }

  _getServiceUrl(): string {
    let url = Context.getInstance().config.closestFacilityServiceUrl;
    if (url) {
      url = Context.checkMixedContent(url);
    }
    return url;
  }

  _incidentFromFeature(feature): string {
    if (!feature) return;
    const lib = Context.getInstance().lib;
    let facilities;
    if (feature.geometry && feature.geometry.type === "polygon" && feature.geometry.centroid) {
      //stop.geometry = stop.geometry.centroid;
      const cloned = feature.clone();
      const geometry = cloned.geometry.centroid;
      cloned.geometry = geometry;
      aiimUtil.removeShapeAttributes(cloned.attributes);
      facilities = new lib.esri.FeatureSet({
        features: [cloned]
      });
    } else {
      facilities = new lib.esri.FeatureSet({
        features: [feature]
      });
    }
    const json = facilities.toJSON();
    json.type = "features";
    json.doNotLocateOnRestrictedElements = true; // TODO?
    return JSON.stringify(json);
  }

  networkSort(fromFeature,featureItems,noJSON) {
    const incident = this._incidentFromFeature(fromFeature);
    const facilities = this.facilitiesFromFeatureItems(featureItems, noJSON);
    const promise = this._solve(incident,facilities);
    promise.then(() => {
      this._sort(featureItems,this.results);
    });
    return promise;
  }

  _readServiceInfo(){
    const setup = (serviceDescription) => {
      const info = {
        routeParameters: {
          travelMode: null, // TODO?
          directionsTimeAttribute: null // TODO?
        },
        serviceDescription: serviceDescription
      }
      const timeAttribute = this.directionsUtil.getDirectionsTimeAttribute(info);
      if (timeAttribute) {
        if (timeAttribute.name) {
          this.timeAttributeName = timeAttribute.name;
        }
        if (timeAttribute.units) {
          this.serviceTimeUnits = timeAttribute.units;
        }
      }
      this.serviceDistanceUnits = serviceDescription.directionsLengthUnits;
    };
    const session = Context.getInstance().session;
    if (session.cfServiceDescription) {
      setup(session.cfServiceDescription);
      return Promise.resolve();
    }
    const url = this._getServiceUrl();
    if (typeof url !== "string" || url.length === 0) {
      return Promise.resolve();
    } else {
      return aiimUtil.readServiceJson(url).then(result => {
        //console.log("ClosestFacility:serviceInfo",result);
        if (result && result.data) {
          session.cfServiceDescription = result.data;
          setup(session.cfServiceDescription);
        }
      });
    }
  }

  _solve(incident,facilities?) {
    // https://developers.arcgis.com/javascript/3/jssamples/routetask_closest_facility.html

    //let start = Date.now();
    const lib = Context.getInstance().lib;
    const results = this.results = [];
    const defaultCutoff = this.defaultCutoff;
    const defaultTargetFacilityCount = this.defaultTargetFacilityCount;

    const url = this._getSolveUrl();
    if (typeof url !== "string" || url.length === 0) {
      return Promise.resolve();
    }
    if (!incident) {
      return Promise.resolve();
    }

    // travelMode
    // accumulateAttributeNames impedanceAttributeName
    // directionsLengthUnits: "esriNAUMeters",

    let query = {
      f: "json",
      returnFacilities: true,
      returnCFRoutes: true,
      returnDirections: false,
      returnIncidents: false,
      returnBarriers: false,
      returnPolygonBarriers: false,
      returnPolylineBarriers: false,
      returnZ: false,
      useHierarchy: false,
      defaultCutoff: defaultCutoff,
      defaultTargetFacilityCount: defaultTargetFacilityCount,
      outputLines: "esriNAOutputLineNone",
      travelDirection: "esriNATravelDirectionToFacility",
      incidents: incident
    };

    if (facilities) {
      //@ts-ignore
      query.facilities = facilities;
    }
    const options = {query: query, method: "post", responseType: "json"};

    const promise = this._readServiceInfo().then(()=> {
      Context.instance.aiim.routeBarriers.addBarriers(query,true);
      return lib.esri.esriRequest(url,options);
    }).then(result => {
      //console.log("esriRequest ClosestFacility result",result);
      let facilityFeatures = [];
      if (result && result.data && result.data.facilities) {
        if (result.data.facilities.features) {
          facilityFeatures = result.data.facilities.features;
        }
      }
      if (result && result.data && result.data.routes) {
        if (result.data.routes.features) {
          //console.log("result.data.routes.features.length",result.data.routes.features.length);
          result.data.routes.features.forEach(f => {
            if (f.attributes) {
              let facilityId = aiimUtil.getAttributeValue(f.attributes,"FacilityID");
              let ff = facilityFeatures[facilityId - 1];
              //let ff = facilitiesById[facilityId];
              if (ff) {
                //let routeName = aiimUtil.getAttributeValue(f.attributes,"Name");
                let facilityName = aiimUtil.getAttributeValue(ff.attributes,"Name");
                let sourceId = aiimUtil.getAttributeValue(ff.attributes,"SourceID");
                let totalTimeProp = "Total_"+this.timeAttributeName;

                let info = {
                  facilityName: facilityName,
                  objectId: aiimUtil.getAttributeValue(ff.attributes,"ObjectID"),
                  sourceId: sourceId,
                  sourceOid: aiimUtil.getAttributeValue(ff.attributes,"SourceOID"),
                  facilityRank: aiimUtil.getAttributeValue(f.attributes,"FacilityRank"),
                  travelTime: aiimUtil.getAttributeValue(f.attributes,totalTimeProp),
                  //total_Length: aiimUtil.getAttributeValue(f.attributes,"Total_Length")
                }
                results.push(info);

                //console.log(routeName,"===",facilityName,"sourceId=",sourceId);
              } else {
                console.warn("ClosestFacility - No matching facility for FacilityID:",facilityId);
              }
            }
          });
        }
      }

      /*
         100: 3 - 3.25 seconds
         200: 4.5 - 5 seconds
         300: 5.75 - 6.5 seconds
         400: 7.25 - 7.75 seconds
         500: 8.5 - 9.5 seconds
         1000: 15.75 - 18 seconds
      */
      // TODO temporary
      //let elapsed = Date.now() - start;
      //console.log("ClosestFacility::solve",elapsed / 1000,"seconds, ", facilityFeatures.length, "facilities")

    }).catch(ex => {
      console.warn("Error solving closest facility:");
      console.error(ex);
    });

    return promise;
  }

  _sort(cfItems,cfResults) {
    let requiresSort = false;
    if (cfResults && cfResults.length > 0) {
      let i = -1;
      cfItems.forEach(cfItem => {
        i++;
        //let feature = cfItem.feature;
        //let oid = feature.attributes[objectIdField];
        let oid = (i + 1);
        if (typeof oid === "number") {
          cfResults.some(cfInfo => {
            //if (oid === cfInfo.sourceOid) {
            if (oid === cfInfo.objectId) {
              //console.log("matched",cfInfo.facilityName);
              // cfItem.distance = this.directionsUtil.formatCFDistance(cfInfo.total_Length,
              //   this.serviceDistanceUnits,this.distanceUnits);
              cfItem.proximity = this.directionsUtil.formatCFTime(cfInfo.travelTime,
                this.serviceTimeUnits);
              cfItem.cfInfo = cfInfo;
              requiresSort = true;
              //console.log(cfInfo.facilityRank,"=",cfItem.distance,"=",cfInfo.total_Length,"=",cfInfo.travelTime);
              return true;
            }
            return false;
          });
        }
      });
    }

    if (requiresSort) {
      cfItems.sort((cfItemA,cfItemB) => {
        if (cfItemA.cfInfo && cfItemB.cfInfo) {
          let a = cfItemA.cfInfo.facilityRank;
          let b = cfItemB.cfInfo.facilityRank;
          if (a < b) return -1;
          if (a > b) return 1;
        } else if (cfItemA.cfInfo) {
          return -1;
        } else if (cfItemB.cfInfo) {
          return 1;
        }
        return 0;
      });

      // cfItems.forEach(cfItem => {
      //   //console.log("cfItem",cfItem);
      //   if (cfItem.cfInfo) {
      //     console.log("sorted rank:",cfItem.cfInfo.facilityRank,"f=",cfItem.feature);
      //   } else {
      //     console.log("sorted rank:",null,"f=",cfItem.feature);
      //   }
      // });

    }
  }

}
