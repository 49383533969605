import React from "react";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BaseClass from "./BaseClass";
import Context from "../context/Context";
import Topic from "../context/Topic";

/*
.Toastify__toast--default {
  background: #fff;
  color: #aaa; }
.Toastify__toast--info {
  background: #3498db; }
.Toastify__toast--success {
  background: #07bc0c; }
.Toastify__toast--warning {
  background: #f1c40f; }
.Toastify__toast--error {
  background: #e74c3c; }

  --i-color-toast-background: #efe397;
  --i-color-toast-background-danger: #e4a793;

  .Toastify__toast--warning {
    background: #efe397;
  }
  .Toastify__toast--error {
    background: #e4a793;
  }
 */

export default class Toaster extends BaseClass {

  _millis = 10000;

  static LevelMessage = "toast/LevelMessage";

  constructor(props) {
    super(props);
    this._init();
    this._checkBrowser();
    if (Context.instance.session.startupToast){
      this.showToast(Context.instance.session.startupToast);
    }
  }

  dismiss(toastId) {
    toast.dismiss(toastId);
  }

  dismissAll() {
    toast.dismiss();
  }

  _checkBrowser() {

    let toastId;
    if (Context.instance.isKiosk) {
      const checkOrientation = () => {
        const orientation = window.orientation;
        if (typeof orientation === "number") {
          if (orientation === 0 || orientation === 180) {
            if (toastId) this.dismiss(toastId);
            toastId = this.showToast({
              message: Context.instance.i18n.messages.rotateToLandscape,
              type: "danger"
            }).toastId;
          } else if (orientation === -90 || orientation === 90) {
            if (toastId) this.dismiss(toastId);
          }
        }
      };

      let orientationEvent;
      if ("onorientationchange" in window) {
        orientationEvent = "orientationchange";
        //orientationEvent = "resize";
      } else {
        //orientationEvent = "resize";
      }
      if (orientationEvent) {
        //alert("orientationEvent:"+orientationEvent);
        window.addEventListener(orientationEvent, () => {
          //alert("changed:"+orientationEvent);
          checkOrientation();
        });
      }
      checkOrientation();

      // 0, 180 is portrait, -90,90 is landscape
      //alert("window.lockOrientation: "+window.lockOrientation);

      // window.onorientationchange
      // http://www.williammalone.com/articles/html5-javascript-ios-orientation/

      // let lockOrientation = "landscape";
      // let _screen: any = window.screen;
      // if (lockOrientation) {
      //   if (_screen && _screen.orientation && typeof _screen.orientation.lock === "function") {
      //     console.log("screen.orientation.lock:",lockOrientation);
      //     _screen.orientation.lock(lockOrientation); // Chrome, Firefox
      //   } else if (_screen && typeof _screen.lockOrientation === "function") {
      //     alert("screen.lockOrientation:"+lockOrientation);
      //     console.log("screen.lockOrientation:",lockOrientation);
      //     _screen.lockOrientation(lockOrientation);
      //   } else if (_screen && typeof _screen.mozLockOrientation  === "function") {
      //     console.log("screen.mozLockOrientation :",lockOrientation);
      //     _screen.mozLockOrientation (lockOrientation);
      //   } else if (_screen && typeof _screen.msLockOrientation === "function") {
      //     console.log("screen.msLockOrientation:",lockOrientation);
      //     _screen.msLockOrientation(lockOrientation); // Edge
      //   }
      // }
    }

  }

  _init() {
    Topic.subscribe(Topic.ShowToast,params => {
      this.showToast(params);
    });

    Topic.subscribe(Topic.ClearToast, params =>{
      this.dismiss(params && params.toastId);
    });
  }

  isActive(toastId) {
    return toast.isActive(toastId);
  }

  showToast(params) {
    let type = params.type;
    if (!type) type = "warning";
    if (type === "danger") type = "error";

    let autoClose = this._millis;
    let millis = params.millis;
    if (typeof millis === "number") autoClose = millis;

    let position = params.position || "top-center";

    let progressClassName = "";
    if (params.showProgress) {
      progressClassName = "progress-visible";
    } else if (params.showProgress === false) {
      progressClassName = "progress-hidden";
    }

    let messageNode = null;
    const msg = params.message;
    if (params.dangerouslySetInnerHTML){
      messageNode = <span key="msg" dangerouslySetInnerHTML={{ __html: Context.sanitizeHtml(msg)}}></span>
    } else {
      messageNode = <span key="msg">{msg}</span>
    }

    let submessageNode = null;
    const submsg = params.submessage;
    if (typeof submsg === "string" && submsg.length > 0) {
      submessageNode = <div key="submsg">{submsg}</div>
    }

    //let iconNode = null;
    //const iconPath = "libs/calcite-ui-icons/icons/sprite-16.svg#"+iconName;
    //iconNode = (<svg className={CSS.icon}><use href={iconPath}></use></svg>);

    const content = (
      <div>
        {messageNode}
        {submessageNode}
      </div>
    );

    const options = {
      autoClose: autoClose,
      position: position,
      progressClassName: progressClassName,
      type: type
    };
    if (params.toastId) options.toastId = params.toastId;

    // let toastId;
    // if (params.toastId && toast.isActive(params.toastId)) {
    //   toast.update(params.toastId,content,options);
    //   toastId = params.toastId;
    // } else {
    //   toastId = toast(content,options);
    // }
    // return {
    //   toastId: toastId
    // };

    let toastId = toast(content,options);
    return {
      toastId: toastId
    };

  }

}
