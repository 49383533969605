import Context from "../../context/Context";
import Source from "../base/Source";
import FieldNames from "../datasets/FieldNames";
import * as aiimUtil from "./aiimUtil";
import * as itemUtil from "./itemUtil";
import * as searchUtil from "./searchUtil";
import * as projectionUtil from "../../util/projectionUtil";

export async function generate311Url(three11Url,source,feature) {
  const promise = new Promise(async (resolve,reject) => {
    try {
      const template = Context.checkMixedContent(three11Url);
      if (typeof template !== "string" || template.length === 0) {
        resolve(null);
        return null;
      }
      const task = {
        source: source,
        feature: feature,
        attributes: feature && feature.attributes,
        geometryValues: null,
        template: template,
        parts: [],
        related: {}
      }
      const hasGeometry = itemUtil.hasGeometry(source,feature);
      if (hasGeometry) {
        task.geometryValues = await getGeometryValuesAsync(source,feature);
      }
      //console.log("attributes",task.attributes);

      const re = /{(.*?)}/g; // match anything between braces (? stops this from being greedy)
      const template2 = template.replace(re, (match, value, offset) => {
        const index = task.parts.length;
        const key = "__" + index + "__";
        let isOptional = false;
        if (value.endsWith("?")) {
          value = value.substring(0,value.length - 1);
          isOptional = true;
        }
        const part = {
          match: match,
          value: value,
          offset: offset,
          index: index,
          isOptional: isOptional,
          transformed: "",
          layer: null,
          field: null,
          relatedPromise: null
        }
        task.parts.push(part);

        return key;
      });

      transform(task).then(() => {
        let url = template2;
        task.parts.forEach(part => {
          const key = "__" + part.index + "__";
          let value = part.transformed;
          if (value === null || value === undefined) value = "";
          url = url.replace(key,encodeURIComponent(value));
        });
        //console.log("311 template",template);
        //console.log("311 template2",template2);
        //console.log("311 url",url);
        resolve(url);
      }).catch(ex2 => {
        reject(ex2);
      });

    } catch(ex) {
      reject(ex);
    }
  });
  return promise;
}

export function generate311Url_v1(three11Url, source, feature) {
  if (!source || !feature) return Promise.resolve();

  // https://survey123.arcgis.com/share/bfa73d47f10f4f74be6e9ec80097f3ac?field:building=O&field:floor=2&field:wing=NA&field:room=O2a020
  // MOBILE_311_ATTRIBUTE_LIST "F6_SITEID,F6_SITENAME,F8_FACILITYID,F8_FACILITYNAME,F11_UNITID,F11_UNITNAME"
  //console.log("feature",feature);
  let params = "";
  const append = (paramName,value) => {
    if (value !== undefined && value !== null) {
      if (params.length > 0) params += "&";
      //paramName = encodeURIComponent("field:" + paramName);
      if(paramName !== "center") {
        paramName = "field:" + paramName;
        value = encodeURIComponent(value);
      }
      let param = paramName + "=" + value;
      params += param;
    }
  };

  let url = three11Url;
  const fields = source.getFields();
  if (fields && url) {
    const info = source.getAiimAddressInfo(feature);
    append("building",info.facilityName);
    append("wing",info.sectionName);
    append("floor",info.levelShortName);
    append("room",info.unitName);
    const hasGeometry = itemUtil.hasGeometry(source,feature);
    const geometryValues = hasGeometry && getGeometryValues(source,feature);
    if (geometryValues) {
      append("x",geometryValues.x);
      append("y",geometryValues.y);
      append("z",geometryValues.z);
      append("center",geometryValues.center);
    }
    if (params.length > 0) {
      if (url.indexOf("?") === -1) url += "?" + params;
      else url += "&" + params;
    }
    return Promise.resolve(url);
  }
  return Promise.resolve();
}

export function makeSitesSource() {
  let map = Context.instance.views.mapView.map;
  let fi = (map && map.floorInfo);
  let siteInfo = fi && fi.siteLayer;
  let siteLayer = siteInfo && map.findLayerById(siteInfo.layerId);
  if(!siteLayer) {
    const layers = aiimUtil.getLayers(Context.instance.views.mapView);
    layers.some(layer => {
      if(layer.title === "Sites") {
        siteLayer = layer;
      }
      return !!siteLayer;
    })
  }
  let source;
  if(siteLayer) {
    source = new Source({key: "Sites"});
    source.url = siteLayer.url + "/" + siteLayer.layerId;
  }
  return source;
}

export async function getGeometryValuesAsync(source,feature) {
  let geometry = feature && feature.geometry;
  let x = null, y = null, z = null, center = null, geometryGeographic = null;
  if (geometry && geometry.spatialReference) {
    geometryGeographic = await projectionUtil.projectToGeographic(geometry);
    if ((geometryGeographic.type === "polygon" || geometryGeographic.type === "polyline") &&
         geometryGeographic.extent && geometryGeographic.extent.center) {
      x = geometryGeographic.extent.center.x;
      y = geometryGeographic.extent.center.y;
      z = geometryGeographic.extent.center.z;
    } else if (geometryGeographic.type === "point") {
      x = geometryGeographic.x;
      y = geometryGeographic.y;
      z = geometryGeographic.z;
    }
    if (x !== undefined && x !== null && y !== undefined && y != null) {
      if (z === undefined || z ===  null) {
        let zInfo = Context.getInstance().aiim.getZInfo(source,feature);
        if (zInfo && zInfo.levelData) {
          z = zInfo.levelData.z;
        }
      }
      center = y + "," + x;
    }
  }
  return {
    x: x,
    y: y,
    z: z,
    center: center
  }
}

export function getGeometryValues(source,feature) {
  let geometry = feature && feature.geometry;
  let x = null, y = null, z = null, center = null, geometryGeographic = null;
  if (geometry && geometry.spatialReference) {
    if(geometry.spatialReference.isWebMercator) {
      const lib = Context.getInstance().lib;
      geometryGeographic = lib.esri.webMercatorUtils.webMercatorToGeographic(geometry);
    } else {
      geometryGeographic = geometry;
    }

    if ((geometryGeographic.type === "polygon" || geometryGeographic.type === "polyline") &&
         geometryGeographic.extent && geometryGeographic.extent.center) {
      x = geometryGeographic.extent.center.x;
      y = geometryGeographic.extent.center.y;
      z = geometryGeographic.extent.center.z;
    } else if (geometryGeographic.type === "point") {
      x = geometryGeographic.x;
      y = geometryGeographic.y;
      z = geometryGeographic.z;
    }
    if (x !== undefined && x !== null && y !== undefined && y != null) {
      if (z === undefined || z ===  null) {
        let zInfo = Context.getInstance().aiim.getZInfo(source,feature);
        if (zInfo && zInfo.levelData) {
          z = zInfo.levelData.z;
        }
      }
      center = y + "," + x;
    }
  }
  return {
    x: x,
    y: y,
    z: z,
    center: center
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

function getAttributeValue(task,source,attributes,field) {
  if (source && attributes) {
    const namedFieldMappings = source.namedFieldMappings();
    let mappedTo;
    if (field && namedFieldMappings) {
      mappedTo = namedFieldMappings[field.toLowerCase()];
      if (mappedTo) field = mappedTo;
    }
    if (!mappedTo) {
      if (source.isAiimSites() && field === FieldNames.SITE_NAME.toLowerCase()) {
        field = FieldNames.NAME.toLowerCase();
      } else if (source.isAiimFacilities() && field === FieldNames.FACILITY_NAME.toLowerCase()) {
        field = FieldNames.NAME.toLowerCase();
      } else if (source.isAiimSections() && field === FieldNames.SECTION_NAME.toLowerCase()) {
        field = FieldNames.NAME.toLowerCase();
      } else if (source.isAiimLevels() && field === FieldNames.LEVEL_NAME.toLowerCase()) {
        field = FieldNames.NAME.toLowerCase();
      } else if (source.isAiimUnits() && field === FieldNames.UNIT_NAME.toLowerCase()) {
        field = FieldNames.NAME.toLowerCase();
      }
    }
    return aiimUtil.getAttributeValue(attributes,field);
  }
  return;
}

function getRelationship(task,part) {
  const relationship = {
    keyField: null,
    keyValue: null
  }
  const relatedSource = part.relatedSource;
  const zInfo = Context.getInstance().aiim.getZInfo(task.source,task.feature);
  const levelData = zInfo && zInfo.levelData;
  let fallback = undefined;
  if (relatedSource.isAiimSites() || relatedSource.key === "_Sites") {
    relationship.keyField = FieldNames.SITE_ID;
    fallback = levelData && levelData.siteId;
  } else if (relatedSource.isAiimFacilities() || relatedSource.key === "_Facilities") {
    relationship.keyField = FieldNames.FACILITY_ID;
    fallback = levelData && levelData.facilityId;
  } else if (relatedSource.isAiimSections()) {
    relationship.keyField = FieldNames.SECTION_ID;
  } else if (relatedSource.isAiimLevels() || relatedSource.key === "_Levels") {
    relationship.keyField = FieldNames.LEVEL_ID;
    fallback = levelData && levelData.levelId;
  } else if (relatedSource.isAiimUnits() || relatedSource.key === "_Units") {
    relationship.keyField = FieldNames.UNIT_ID;
    const v = aiimUtil.findAttributeName(task.attributes,FieldNames.UNIT_ID)
    if (!v && levelData && levelData.levelId) {
      relationship.tryUnitSpatialQuery = true;
      relationship.levelId = levelData.levelId;
    }
  }
  if (relationship.keyField) {
    if (fallback !== undefined) {
      relationship.keyValue = fallback;
    } else {
      relationship.keyValue = getAttributeValue(task,task.source,task.attributes,relationship.keyField);
    }
  }
  /*
  if (relationship.keyField) {
    relationship.keyValue = getAttributeValue(task,task.source,task.attributes,relationship.keyField);
    if (relationship.keyValue === undefined && fallback !== undefined) {
      relationship.keyValue = fallback;
    }
  }
  */
  return relationship;
}

function queryRelatedFeature(task,part) {
  const promise = new Promise((resolve,reject) => {
    try {
      let relatedSource = part.relatedSource;
      let url = Context.checkMixedContent(relatedSource.url);
      let relationship = getRelationship(task,part);
      let keyField = relationship && relationship.keyField;
      let keyValue = relationship && relationship.keyValue;
      if (url && keyField && keyValue !== undefined && keyValue !== null) {
        searchUtil.queryFieldValue(relatedSource,url,keyField,keyValue).then(result => {
          if (result && result.features && result.features.length === 1) {
            //console.log("relatedAttributes",result.features[0].attributes);
            resolve(result.features[0]);
          } else {
            console.warn("311: No single related feature",task.source.name,keyField,keyValue);
            resolve();
          }
        }).catch(ex2 => {
          // don't trigger an error, just log the issue
          console.warn("Error getting related data",ex2);
          resolve();
        });
      } else if (relationship && relationship.tryUnitSpatialQuery) {
        let dataset = Context.instance.aiim.datasets.units;
        let geom = task.feature && task.feature.geometry;
        let lid = relationship.levelId;
        if (dataset && geom) {
          dataset.queryByGeometry(geom,null,lid).then(result => {
            //console.log("three11Util.queryByGeometry.result",result)
            let useFirst = true;
            if (useFirst && result && result.features && result.features.length > 0) {
              resolve(result.features[0]);
            } else if (result && result.features && result.features.length === 1) {
              resolve(result.features[0]);
            } else {
              console.warn("311: No single related feature",task.source.name,keyField,keyValue);
              resolve();
            }
          }).catch(ex2 => {
            // don't trigger an error, just log the issue
            console.warn("Error getting related data",ex2);
            resolve();
          });
        }
      } else {
        resolve();
      }
    } catch(ex) {
      // don't trigger an error, just log the issue
      console.warn("Error getting related data",ex);
      resolve();
    }
  });
  return promise;
}

function transform(task) {
  /*
    {layer-name.field-name}
    {field-name}
    {shape.x}  // longitude
    {shape.y}  // latitude
    {shape.z}
    {user.username}
  */
  const promise = new Promise((resolve,reject) => {
    try {
      const categories = Context.getInstance().aiim.datasets.categories;
      const relatedPromises = [];
      task.parts.forEach(part => {
        const lc = part.value.toLowerCase();
        //console.log("part",part,task)
        if (lc === "shape.x") {
          if (task.geometryValues && typeof task.geometryValues.x === "number") {
            part.transformed = task.geometryValues.x;
          }
        } else if (lc === "shape.y") {
          if (task.geometryValues && typeof task.geometryValues.y === "number") {
            part.transformed = task.geometryValues.y;
          }
        } else if (lc === "shape.z") {
          if (task.geometryValues && typeof task.geometryValues.z === "number") {
            part.transformed = task.geometryValues.z;
          }
        } else if (lc === "user.username") {
          part.transformed = Context.getInstance().user.getUsername();
        } else if (lc === "layer.name") {
          part.transformed = "";
          if (task.source && task.source.layer2D && task.source.layer2D.title) {
            part.transformed = task.source.layer2D.title;
          }
        } else if (lc.length > 0 && task.source && task.attributes) {
          let layer, field;
          const parts = lc.split(".");
          if (parts.length === 2) {
            layer = parts[0].trim();
            field = parts[1].trim();
          } else {
            field = lc.trim();
          }
          let hasLayer = (typeof layer === "string" && layer.length > 0);
          let hasField = (typeof field === "string" && field.length > 0);
          if (hasLayer && !hasField) {
            console.warn("Invalid 311 URL part:",part.match);
            console.warn("311 URL:",task.template);
          } else if (!hasLayer && hasField) {
            part.transformed = getAttributeValue(task,task.source,task.attributes,field);
          } else if (hasLayer && hasField) {
            // TODO
            part.layer = layer;
            part.field = field;
            part.relatedSource = categories.findSourceByName(part.layer);
            if(!part.relatedSource) {
              if(part.layer === "sites") {
                part.relatedSource = makeSitesSource(part);
              }
            }
            if (!part.relatedSource) {
              console.warn("Invalid 311 URL part, mo matching layer source:",part.match);
              console.warn("311 URL:",task.template);
            } else if (part.relatedSource === task.source) {
              part.transformed = getAttributeValue(task,task.source,task.attributes,part.field);
            } else {
              let relatedPromise = task.related[part.layer];
              if (!relatedPromise) {
                relatedPromise = queryRelatedFeature(task,part);
                if (relatedPromise) {
                  task.related[part.layer] = relatedPromise;
                  part.relatedPromise = relatedPromise;
                  relatedPromises.push(relatedPromise);
                }
              } else {
                part.relatedPromise = relatedPromise;
              }
            }
          }
        }
      });
      if (relatedPromises.length === 0) {
        resolve();
      } else {
        Promise.all(relatedPromises).then(() => {
          updateRelated(task).then(() => {
            resolve();
          }).catch(ex3 => {
            reject(ex3);
          });
        }).catch(ex2 => {
          reject(ex2);
        });
      }
    } catch(ex) {
      reject(ex);
    }
  });
  return promise;
}

function updateRelated(task) {
  const promise = new Promise((resolve,reject) => {
    try {
      let parts = [];
      task.parts.forEach(part => {
        if (part.relatedPromise) parts.push(part);
      });
      if (parts.length === 0) {
        resolve();
      } else {
        let i = -1;
        let last = (parts.length - 1);
        parts.forEach((part) => {
          part.relatedPromise.then(relatedFeature => {
            try {
              //console.log("relatedFeature",relatedFeature);
              i++;
              let source = part.relatedSource;
              let attributes = relatedFeature && relatedFeature.attributes;
              if (source && attributes) {
                //console.log("related",source.name,part.field,attributes);
                part.transformed = getAttributeValue(task,source,attributes,part.field);
                //console.log("part.transformed",part.transformed);
              }
              //console.log("i >= last",i >= last,i,last,parts.length,part);
              if (i >= last) resolve();
            } catch(ex2) {
              reject(ex2);
            }
          });
        });
      }
    } catch(ex) {
      reject(ex);
    }
  });
  return promise;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export function test() {
  let source = null;
  let feature = null;

  let referenceLayer = Context.getInstance().session.referenceLayer;
  let homeLocation = referenceLayer && referenceLayer.homeLocation;
  if (homeLocation) {
    source = homeLocation.getSource();
    feature = homeLocation.getFeature();
  }
  //console.log("homeLocation",homeLocation);
  //console.log("source",source);
  //console.log("feature",feature);

  const exec = url => {
    generate311Url(url,source,feature).then(result => {
      console.log(">>>",url);
      console.log("<<<",result);
    }).catch(ex => {
      console.error(ex);
    });
  };

  // https://survey123.arcgis.com/share/092a86f0213e4e248d88518622ddaf23?portalUrl=https://indoorsportal2.esri.com/portal&field:building=O&field:floor=1&field:room=O1w225&field:x=-117.19763885219211&field:y=34.056818183202076&field:z=0&center=34.056818183202076,-117.19763885219211

  //exec("http://host/311?a=[q]{123},{456},{},{999 t?},{?}");
  //exec("http://host/311?shp={shape.x},{shape.y},{shape.z}");
  //exec("http://host/311?ccn={costctrn}&EMAIL={EMAIL}");
  //exec("http://host/311?abc={zzz}&d=d");
  exec("https://survey123.arcgis.com/share/092a86f0213e4e248d88518622ddaf23?portalUrl=https://indoorsportal2.esri.com/portal&field:building={facility_name}&field:wing={section_name}&field:floor={levels.name_short}&field:room={unit_name}&field:x={shape.x}&field:y={shape.y}&field:z={shape.z}&center={shape.y},{shape.x}&test={facilities.name_long}");

}
