import React from "react";

import Context from "../../../context/Context";
import ModalController from "../../common/Modal/ModalController";
import * as component from "../../util/component";
import { SharingLevels } from "../../../common/components/Sharing";
const CSS = {
  main: "i-cfg-saveas",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  actions: "i-actions",
  button: "i-button"
};

export default class SaveAs extends React.Component {

  componentId;
  _saving = false;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      title: "",
      tags: Context.instance.configuration.indoorsTag,
      summary: "",
      folder: "",
      everyoneChecked: false,
      organizationChecked: true
    });
    this.onChangeFolder = this.onChangeFolder.bind(this);
    this.onChangeSummary = this.onChangeSummary.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
  }

  cancelClicked = (evt) => {
    if (this.props.onCancelCliked) {
      this.props.onCancelCliked();
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeFolder(evt) {
    const v = evt.target.value;
    this.setState(state => {
      return {
        folder: v
      }
    });
  }

  onChangeSummary(evt) {
    const v = evt.target.value;
    this.setState(state => {
      return {
        summary: v
      }
    });
  }

  onChangeTags(evt) {
    const v = evt.target.value;
    this.setState(state => {
      return {
        tags: v
      }
    });
  }

  onChangeTitle(evt) {
    const v = evt.target.value;
    this.setState(state => {
      return {
        title: v
      }
    });
  }

  render() {
    const id = this.componentId;
    const i18n = Context.instance.i18n;
    //const cfg = this.props.cfg;

    const title = this.state.title;
    const tags = this.state.tags;
    const summary = this.state.summary;
    const folder = this.state.folder;

    const saveDisabled = (this.state.title.trim().length === 0);
    const actions = (
      <div key="actions" className={CSS.actions}>
        <button key="cancel" type="button"
          className={"i-button i-button-clear i-margin-all-small"}
          onClick={this.cancelClicked}>{i18n.general.cancel}</button>
        <button key="ok" type="button" disabled={saveDisabled}
          className={"i-button i-margin-all-small"}
          onClick={this.saveClicked}>{i18n.general.save}</button>
      </div>
    );

    const username = Context.instance.user.getUsername();
    const folderOptions = [(
      <option key={username} value="">{username}</option>
    )];
    if (Array.isArray(this.props.folders)) {
      this.props.folders.forEach(f => {
        folderOptions.push(
          <option key={f.id} value={f.id}>{f.title}</option>
        );
      });
    }

    return (
      <div className={CSS.main}>
        <div key="form">

          <div key="title" className={CSS.formGroup}>
            <label id={id+"-title-label"} htmlFor={id+"-title"}>
              <div className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName+" "+CSS.required}
                  >{i18n.configurator.appItem.title}</span>
              </div>
              <input id={id+"-title"} type="text" defaultValue={title}
                onChange={this.onChangeTitle}/>
            </label>
          </div>

          <div key="tags" className={CSS.formGroup}>
            <label id={id+"-tags-label"} htmlFor={id+"-tags"}>
              <div className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}
                  >{i18n.configurator.appItem.tags}</span>
              </div>
              <input id={id+"-tags"} type="text" defaultValue={tags}
                onChange={this.onChangeTags}/>
            </label>
          </div>

          <div key="summary" className={CSS.formGroup}>
            <label id={id+"-summary-label"} htmlFor={id+"-summary"}>
              <div className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}
                  >{i18n.configurator.appItem.summary}</span>
              </div>
              <textarea id={id+"-summary"} defaultValue={summary}
                onChange={this.onChangeSummary}/>
            </label>
          </div>

          <div key="folder" className={CSS.formGroup}>
            <label id={id+"-folder-label"} htmlFor={id+"-folder"}>
              <div className={CSS.formGroupHeader}>
                <span className={CSS.formGroupName}
                  >{i18n.configurator.appItem.folder}</span>
              </div>
              <select id={id+"-folder"} value={folder}
                onChange={this.onChangeFolder}>
                {folderOptions}
              </select>
            </label>
          </div>

          <div key="share" className={CSS.formGroup}>
            <div key="hdr" className={CSS.formGroupHeader}>
              <span className={CSS.formGroupName}
                >{i18n.configurator.appItem.shareWith.caption}</span>
            </div>

            <select id={"cfg-share-options"} onChange={this.shareWithEveryoneChanged}>
                <option key={SharingLevels.ORGANIZATION} value={SharingLevels.ORGANIZATION}
                  >{i18n.configurator.appItem.shareWith.organization}</option>
                <option key={SharingLevels.PUBLIC} value={SharingLevels.PUBLIC}
                  >{i18n.configurator.appItem.shareWith.everyone}</option>
                <option key={SharingLevels.PRIVATE} value={SharingLevels.PRIVATE}
                  >{i18n.configurator.appItem.shareWith.private}</option>
            </select> 
          </div>
        </div>
        {actions}
      </div>
    );
  }

  saveClicked() {
    if (this._saving) return;
    let title = this.state.title.trim();
    if (title.length === 0) return;

    let summary = this.state.summary.trim();
    let folderId = this.state.folder;
    if (folderId === "") folderId = null;

    // const indoorsTag = this.indoorsTag;
    // const tags = [indoorsTag];
    // const a = this.state.tags.trim().split(",");
    // const lc = indoorsTag.toLowerCase();
    // a.forEach(v => {
    //   v = v.trim()
    //   if (v.length > 0 && v.toLowerCase() !== lc) {
    //     tags.push(v);
    //   }
    // });

    const tags = [], a = this.state.tags.trim().split(",");
    a.forEach(v => {
      v = v.trim()
      if (v.length > 0) tags.push(v);
    });

    const metaInfo  = {
      title: title,
      tags: tags,
      snippet: summary
    };

    const sharingInfo = {
      everyone: this.state.everyoneChecked,
      org: this.state.organizationChecked
    };

    if (this.props.onSaveCliked) {
      this._saving = true;
      this.props.onSaveCliked(metaInfo,folderId,sharingInfo);
    }
  }

  shareWithEveryoneChanged = (evt) => {
    if(evt.target.value === SharingLevels.PUBLIC){
      this.setState({
        everyoneChecked: true,
        organizationChecked: true
      })
    }else if (evt.target.value === SharingLevels.ORGANIZATION){
      this.setState({
        everyoneChecked: false,
        organizationChecked: true
      })
    }else if(evt.target.value === SharingLevels.PRIVATE){
      this.setState({
        everyoneChecked: false,
        organizationChecked: false
      })
    }
  }
  
  static showModal(props) {
    const i18n = Context.instance.i18n;
    Context.instance.user.portalUser.fetchFolders().then(result => {
      const controller = new ModalController();
      const onSaveCliked = (metaInfo,folderId,sharingInfo) => {
        if (props.onSaveCliked) {
          props.onSaveCliked(metaInfo,folderId,sharingInfo,controller);
        }
      };
      const onCancelCliked = () => {
        controller.close();
      };
      const content = (
        <SaveAs folders={result} cfg={props.cfg} onSaveCliked={onSaveCliked}
          onCancelCliked={onCancelCliked}/>
      );
      controller.show(content,i18n.configurator.saveAsDialogTitle,
        "i-configurator-modal");
    }).catch(ex => {
      console.warning("Error fetching folders",ex);
    });
  }

}
