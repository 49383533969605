import styled from "styled-components";

import CalciteButton from "calcite-react/Button";
import CalciteMenu from "calcite-react/Menu";

const FilterContainer = styled(CalciteMenu)`
  width: 21rem;
  padding: 1rem;
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
`;

const FilterTitle = styled.div`
  font-size: 1.1rem;
`;

const FilterFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterButton = styled(CalciteButton)`
  color: ${props => props.theme.typeColor};

  &:hover {
    color: ${props => props.theme.palette.themeColor};
  }
`;

const ApplyButton = styled(CalciteButton)`
  background: ${props => props.theme.palette.buttonColor};
  border-color: ${props => props.theme.palette.buttonColor};

  &:hover {
    background: ${props => props.theme.palette.buttonColorHover};
    border-color: ${props => props.theme.palette.buttonColorHover};
  }
`;

const ClearButton = styled(CalciteButton)`
  background: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.themeColorDark};
  border-color: ${props => props.theme.palette.themeColorDark};

  &:hover {
    background: ${props => props.theme.palette.themeColorDark};
    border-color: ${props => props.theme.palette.themeColorDark};
    color: ${props => props.theme.palette.white};
  }
`;

const Indicator = styled.div`
  position: absolute;
  z-index: 10;
  display: ${props => (props.filterApplied ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.3rem;
  padding: 0.1rem;
  border-radius: 1.5rem;
  background: ${props => props.theme.palette.themeColor};
  border: 2px solid ${props => props.theme.palette.offWhite};
  color: ${props => props.theme.palette.white};
`;

export {
  FilterContainer,
  FilterHeader,
  FilterTitle,
  FilterFooter,
  FilterButton,
  ApplyButton,
  ClearButton,
  Indicator
};
