import React from "react";
import { connect } from "react-redux";

import Context from "../../../context/Context";
import OccupantsPanel from "./occupants/OccupantsPanel";
import SidebarButton from "../sidebar/SidebarButton";
import SidebarPanel from "../sidebar/SidebarPanel";
import { 
  ASSIGNMENTS_ACTIVE_SIDEBAR_TAB as activeTabKey,
  getMiniappsSidebarCollapsed, 
  setMiniappsSidebarCollapsed,
  setMiniAppSwitcherActive 
} from "../../redux";
import * as component from "../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-loader";
import {
  CalciteIcon,
  CalciteLoader
} from "@esri/calcite-components-react";

import { ReactComponent as OfficeIcon } from "../../../assets/office.svg";

interface Props {
  collapsed: boolean;
  loading: boolean;
  setMiniappsSidebarCollapsed: (value: boolean) => void;
  setMiniAppSwitcherActive: (value: boolean) => void;
}

interface State {
}

class Sidebar extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const i18n = Context.instance.i18n;
    const expanded = !this.props.collapsed;
    return (
      <div className="i-editor-sidebar">
        <div className={"i-editor-sidebar-menu" + (this.props.loading ? " i--loading" : "")}>
          {this.props.loading && <div className="i--loading-overlay"><CalciteLoader scale="l"label="loading"/></div>}
          <div>
            <SidebarButton
              tab="app-switcher"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={<span className="smallcaps">{i18n.miniapps.assignments.sidebar.caption}</span>} 
              icon={<CalciteIcon icon="app-launcher" scale="s" />}
              className="i-miniapps-switcher-btn i--green"
              onClick={(e) => {
                this.props.setMiniAppSwitcherActive(true);
              }}
            />
            <SidebarButton 
              tab="assignments-occupants"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.occupants} 
              icon={<CalciteIcon icon="circle-f" scale="s" />} 
            />
            <SidebarButton 
              tab="assignments-units"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.units} 
              icon={<CalciteIcon icon="add-in" scale="s" />} 
            />
            <div className="separator"></div>
            <SidebarButton 
              tab="assignments-offices"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.offices} 
              icon={<OfficeIcon />} 
            />
            <SidebarButton 
              tab="assignments-hotdesks"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.hotdesks} 
              icon={<CalciteIcon icon="desk" scale="s" />} 
            />
            <SidebarButton 
              tab="assignments-hotels"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.hotels} 
              icon={<CalciteIcon icon="services" scale="s" />} 
            />
            <SidebarButton 
              tab="assignments-meetingRooms"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.miniapps.assignments.sidebar.meetingRooms} 
              icon={<CalciteIcon icon="conference-room" scale="s" />} 
            />
          </div>
          <div>
            <SidebarButton 
              tab="layers"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.editor.sidebar.layers} 
              icon={<CalciteIcon icon="layers" scale="s" />}
            />
            <SidebarButton
              tab="legend"
              activeTabKey={activeTabKey}
              expanded={expanded}
              label={i18n.editor.sidebar.legend} 
              icon={<CalciteIcon icon="legend" scale="s" />}
            />
            {expanded &&
              <SidebarButton
                activeTabKey={activeTabKey}
                expanded={expanded}
                label={i18n.editor.sidebar.collapse} 
                icon={<CalciteIcon icon="chevrons-left" scale="s" />}
                onClick={(e) => {
                  this.props.setMiniappsSidebarCollapsed(true);
                }}
              />
            }
            {!expanded &&
              <SidebarButton 
                activeTabKey={activeTabKey}
                expanded={expanded}
                label={i18n.editor.sidebar.expand} 
                icon={<CalciteIcon icon="chevrons-right" scale="s" />}
                onClick={(e) => {
                  this.props.setMiniappsSidebarCollapsed(false);
                }}
              />
            }
          </div>
        </div>
        <div className="i-editor-sidebar-panels">
          <SidebarPanel tab="assignments-occupants" activeTabKey={activeTabKey}
            label={i18n.miniapps.assignments.sidebar.occupants}>
            <OccupantsPanel />
          </SidebarPanel>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  collapsed: getMiniappsSidebarCollapsed(state)
});
const mapDispatchToProps = (dispatch) => ({
  setMiniappsSidebarCollapsed: (value) => dispatch(setMiniappsSidebarCollapsed(value)),
  setMiniAppSwitcherActive: (value) => dispatch(setMiniAppSwitcherActive(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);