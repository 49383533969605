import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import {WorkingController} from "../../common/components/Modal";

export default class TransactionGuard extends BaseClass {

  minimal;
  workingController;
  timestamp;

  constructor(props) {
    super(props);
    let show = false;
    if (props && props.featureItems && props.featureItems.length > 1) {
      show = true;
    } else if (props && props.force) {
      show = true;
    }
    show = true; // always show
    if (show) {
      const i18n = Context.instance.i18n;
      const content = i18n.spaceplanner.transactionGuard.message;
      this.timestamp = Date.now();
      this.workingController = new WorkingController();
      this.workingController.show({minimal: !!this.minimal}, content);
    }
  }

  close() {
    const wc = this.workingController;
    this.workingController = null;
    if (wc) {
      if (this.minimal) {
        wc.close();
      } else {
        // ensure that the guard is visible for at least 2 seconds
        let millis = 1500 - (Date.now() - this.timestamp);
        if (millis < 1) millis = 1;
        setTimeout(() => {
          wc.close();
        }, millis);
      }
    }
  }

}
