import React from "react";

import Button from "../../../common/components/Button/Button";
import Context from "../../../context/Context";
import HostedMerge from "../../base/HostedMerge";
import {
  ModalController,
  WorkingController,
} from "../../../common/components/Modal";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as mergeUtil from "../../base/mergeUtil";
import * as placeholderUtil from "../../base/placeholderUtil";

import Form, {
  FormControl,
  Legend,
  Fieldset,
} from "calcite-react/Form";
import { CalciteP } from "calcite-react/Elements";
import Radio from "calcite-react/Radio";
import Tooltip from "calcite-react/Tooltip";
import QuestionIcon from "calcite-ui-icons-react/QuestionFIcon";

import LeftIcon from "calcite-ui-icons-react/ChevronLeftIcon";
import RightIcon from "calcite-ui-icons-react/ChevronRightIcon";

import {
  ContentWrapper,
  HeaderContainer,
  Header,
  ContentContainer
} from "../../styles/backstageStyles";

export default class MergePlan extends React.Component {

  componentId;

  constructor(props){
    super(props);
    this.componentId = component.nextId()
    const mergePermission = Context.instance.config.spaceplanner.mergepermission;
    const groupTitle = mergePermission.groupTitle;
    this.state = component.newState({
      isWorking: true,
      noDiff: false,
      diffChecked: false,
      wasMerged: false,
      wasReconciled: false,
      authorizationChecked: false,
      userAuthorized: false,
      errorAuthorizing : false,
      groupTitle: groupTitle,
      reason: null,
      reconcileOnly: false,
      hasPlaceHolderIds: false
    });
  }

  backClicked = () => {
    let reconcileOnly = this.state.reconcileOnly;
    if (!this.state.userAuthorized) reconcileOnly = true;
    this.setState({
      isWorking: true,
      noDiff: false,
      diffChecked: false,
      wasMerged: false,
      wasReconciled: false,
      reconcileOnly: reconcileOnly
    })
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan.isVersioned) {
      this.checkDifferencesVersioned();
    } else {
      this.checkDifferencesHosted();
    }
  }

  checkIfUserAuthorized(){
    const plan = Context.instance.spaceplanner.activePlan;
    var reason = "userUnAuthorized";
    this.isUserAuthorized()
    .then(resp => {
      let authorized = resp;
      if(resp === true) {
        if(plan && !plan.isVersioned){
          if(!this.isOwner()) {
            reason = "nonOwnerAdminOnline";
            authorized = false;
          }
        }
      }
      if(resp === false) {
        reason = "userUnAuthorized";
        if (plan && !plan.isVersioned) {
          if(!this.isOwner()) {
            reason = "nonMemberOwnerAdminOnline";
          }
        }
        this.getGroupTitle();
      }
      if (!authorized) this.setState({reconcileOnly: true});
      this.setState({
        userAuthorized : authorized,
        reason : reason,
        authorizationChecked: true
      })
    })
    .catch(error => {
      console.log("Error in isUserAuthorized", error);
      this.setState({
        errorAuthorizing : true,
        authorizationChecked: true,
        reconcileOnly: true
      })
      Topic.publishErrorAccessingData();
    })
  }

  async componentDidMount() {
    const plan = Context.instance.spaceplanner.activePlan;
    await this.hasPlaceHolderIds();
    if (plan && !plan.isVersioned) {
      if(Context.instance.user.isAdmin()) {
        this.setState({userAuthorized : true, authorizationChecked: true});
      }else{
        this.checkIfUserAuthorized();
      }
    }else{
      this.checkIfUserAuthorized();
    }
    if (plan.isVersioned) {
      this.checkDifferencesVersioned();
    } else {
      this.checkDifferencesHosted();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  hasPlaceHolderIds= async()=> {
    const data = await placeholderUtil.readPlaceholderIds({})
    if(data) {
      this.setState({
        hasPlaceHolderIds: true
      })
    } else {
      this.setState({
        hasPlaceHolderIds: false
      })
    }
  }

  getGroupTitle=()=>{
    const mergePermission = Context.instance.config.spaceplanner.mergepermission;
    const groupId = mergePermission.groupId;
    const portal = Context.instance.getPortal();
    var query = "id:" + groupId;
    var queryParams = {
      query: query,
      num: 100
    };
    portal.queryGroups(queryParams).then((response)=>{
      if(response && response.results && response.results.length === 1) {
        this.setState({
          groupTitle: response.results[0].title
        })
      }else{
        this.setState({
          groupTitle : mergePermission.groupTitle
        })
      }
    })
  }

  isOwner =()=> {
    const plan = Context.instance.spaceplanner.activePlan;
    const item = plan.planServiceItem;
    const username = Context.instance.user.getUsername();
    const owner = item && item.owner && item.owner.toLowerCase();
    const isOwner = username && username.toLowerCase() === owner;
    if(isOwner) return true;
    return false;
  }

  isOwnerOrAdmin =()=> {
    if (Context.instance.user.isAdmin()) return true;
    return this.isOwner();
  }

  isUserAuthorized = () => {
    const mergePermission = Context.instance.config.spaceplanner.mergepermission;
    let authorized = false;
    return new Promise((resolve, reject)=> {
      if(mergePermission.restrictUsers === true) {
        const allowMergeGroupId = mergePermission.groupId;
        const portal = Context.instance.getPortal();
          portal.user.fetchGroups()
          .then((userGroups)=> {
            userGroups.some(userGroup => {
              const id = userGroup.id;
              if(allowMergeGroupId === id) {
                authorized = true;
                return true;
              }
              return false;
            })
          })
          .then(()=> {
            resolve(authorized);
          })
          .catch(error => {
            console.log("Error in fetchGroups", error);
            reject(error);
          })
      }else{
        resolve(true);
      }
    })
  }

  checkDifferencesHosted = () => {
    this.setState({ isWorking: true });
    const hm = new HostedMerge();
    hm.hasDifferences({}).then(result => {
      let hasDiff = result && result.hasDifferences;
      if (!hasDiff) this.setState({reconcileOnly: true});
      this.setState({
        isWorking: false,
        diffChecked: true,
        noDiff: !hasDiff
      });
    }).catch(ex => {
      //this.setState({ isWorking: false });
      console.error("Error checking differences", ex);
      Topic.publishErrorAccessingData();
    });
  }

  checkDifferencesVersioned = () => {
    this.setState({ isWorking: true });
    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const options = {
      plan: Context.instance.spaceplanner.activePlan
    };
    versionManager.hasDifferences(options).then(result => {
      let hasDiff = result && result.hasDifferences;
      if (!hasDiff) this.setState({reconcileOnly: true});
      this.setState({
        isWorking: false,
        diffChecked: true,
        noDiff: !hasDiff
      });
    }).catch(ex => {
      //this.setState({ isWorking: false });
      console.error("Error checking differences", ex);
      Topic.publishErrorAccessingData();
    });
  }
  
  showReplacePlaceholders = async () => {
    const project = Context.instance.spaceplanner.planner.project;
    const vm = project.versionedInfo && project.versionedInfo.versionManager;
    const options = {
      cancelMerge: false,
      versionManager: vm
    }
    const i18n = Context.instance.i18n;
    const workingController = new WorkingController();
    workingController.show({}, i18n.spaceplanner.backstage.mergePlan.replacePlaceholders.controller);
    try {
      await placeholderUtil.checkReplacePlaceholders(options, workingController);
      workingController.close()
    } catch (error) {
      workingController.close()
      console.error(error);
    }
    return options;
  }

  mergeClicked = () => {
    if (this.state.isWorking) return;
    const params = {};

    const mergePlan =()=> {
      Topic.publish(Topic.MergingPlan,{});
      const plan = Context.instance.spaceplanner.activePlan;
      plan.undoManager.clear();
      if (plan.isVersioned) {
        this.mergeVersioned(params);
      } else {
        this.mergeHosted(params);
      }
    }

    if (this.state.hasPlaceHolderIds) {
      const i18n = Context.instance.i18n;
      const options = {
        title: i18n.spaceplanner.backstage.mergePlan.placeholderWarning.caption,
        message: i18n.spaceplanner.backstage.mergePlan.placeholderWarning.prompt,
        okLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.yes,
        showOKCancel: true,
        closeOnOK: true,
        closeOnCancel: true
      };
      ModalController.confirm(options)
      .then(async (result) => {
        if(result && result.ok) {
          const result2 = await this.showReplacePlaceholders();
          if (result2.cancelMerge) {
            return;
          } else {
            params.replacePlaceholderEdits = result2.replacePlaceholderEdits;
            mergePlan();
          }
        }
      })
      .catch(error => {
        console.error(error);
      })
    } else {
      mergePlan();
    }
  }

  mergeHosted = async (params) => {
    if (this.state.isWorking) return;
    this.setState({ isWorking: true });

    const plan = Context.instance.spaceplanner.activePlan;
    const reconcileOnly = !!this.state.reconcileOnly;
    const options = {
      plan: plan,
      reconcileOnly: reconcileOnly,
    };
    Object.assign(options, params);
    const i18n = Context.instance.i18n;
    const workingController = new WorkingController();
    let workingContent = i18n.spaceplanner.backstage.mergePlan.mergingPlan;
    if (this.state.reconcileOnly) {
      workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
    }
    workingController.show({}, workingContent);


    const hm = new HostedMerge();
    hm.merge(options).then(task => {
      //console.log("MergePlan mergeUtil::analyze",task)
      workingController.close();
      this.setState({
        isWorking: false,
        wasMerged: !!task.wasMerged,
        wasReconciled: !!task.wasReconciled
      });
    }).catch(ex => {
      let submessage = ex.submessage || ex.message;
      workingController.close();
      ModalController.alert({
        isError: true,
        title: i18n.spaceplanner.backstage.mergePlan.errorMergingTitle,
        message: i18n.spaceplanner.backstage.mergePlan.errorMergingMessage,
        submessage: submessage
      });
      this.setState({ isWorking: false });
      console.error("Error merging plan", ex);
    });
  }

  mergeVersioned = async (params) => {
    if (this.state.isWorking) return;
    this.setState({ isWorking: true });

    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const plan = Context.instance.spaceplanner.activePlan;
    const reconcileOnly = !!this.state.reconcileOnly;
    const options = {
      plan: plan,
      versionManager: versionManager,
      reconcile: true,
      applyEdits: true,
      post: !reconcileOnly
    };
    Object.assign(options, params);
    const i18n = Context.instance.i18n;
    const workingController = new WorkingController();
    let workingContent = i18n.spaceplanner.backstage.mergePlan.mergingPlan;
    if (this.state.reconcileOnly) {
      workingContent = i18n.spaceplanner.backstage.mergePlan.gettingLatest;
    }
    workingController.show({}, workingContent);
    //options.post = false;

    //console.log("MergePlan merging",options)
    mergeUtil.analyze(options).then(task => {
      //console.log("MergePlan mergeUtil::analyze",task)
      workingController.close();
      this.setState({
        isWorking: false,
        wasMerged: !!task.wasMerged,
        wasReconciled: reconcileOnly && !!task.wasReconciled
      });
    }).catch(ex => {
      let submessage = ex.submessage || ex.message;
      workingController.close();
      ModalController.alert({
        isError: true,
        title: i18n.spaceplanner.backstage.mergePlan.errorMergingTitle,
        message: i18n.spaceplanner.backstage.mergePlan.errorMergingMessage,
        submessage: submessage
      });
      this.setState({ isWorking: false });
      console.error("Error merging plan", ex);
    });
  }
  
  render() {
    const i18n = Context.instance.i18n;
    return (
      <ContentWrapper>
        <HeaderContainer>
          <Header>{i18n.spaceplanner.backstage.mergePlan.caption}</Header>
        </HeaderContainer>
        <ContentContainer>
          {this.renderContent()}
        </ContentContainer>
      </ContentWrapper>
    )
  }

  renderContent() {
    let {authorizationChecked, userAuthorized, reason} = this.state;
    const {isWorking, noDiff, wasMerged, wasReconciled, diffChecked} = this.state;
    const i18n = Context.instance.i18n;
    const plan = Context.instance.spaceplanner.activePlan;
    let isVersioned = plan && plan.isVersioned;
    let msg;
    // if (!isVersioned) {
    //   msg = i18n.spaceplanner.backstage.mergePlan.promptAlt;
    // }

    let reconcileOnlyDisabled = true;
    let reconcileOnlyChecked = this.state.reconcileOnly;
    let fullMergeDisabled = true;
    let fullMergeChecked = false;
    let buttonDisabled = false;
    let buttonLabel = i18n.spaceplanner.backstage.mergePlan.label;
    let choice;

    if (wasMerged) {
      msg = i18n.spaceplanner.backstage.mergePlan.merged;
    } else if (wasReconciled) {
      msg = i18n.spaceplanner.backstage.mergePlan.reconciled;
    } else if (noDiff) {
      msg = i18n.spaceplanner.backstage.mergePlan.noDiffAlt;
    } else if (!userAuthorized) {
      if (plan && !plan.isVersioned) {
        if (reason === "nonMemberOwnerAdminOnline") {
          msg = i18n.spaceplanner.backstage.mergePlan.nonMemberOwnerAdminOnline;
        } else if(reason === "nonOwnerAdminOnline") {
          msg = i18n.spaceplanner.backstage.mergePlan.nonOwnerAdminOnline;
        } else if(reason === "userUnAuthorized"){
          msg = i18n.spaceplanner.backstage.mergePlan.userUnAuthorized;
        }
      } else if(reason === "userUnAuthorized"){
        msg = i18n.spaceplanner.backstage.mergePlan.userUnAuthorized;
      }
      if (msg) {
        const groupName = this.state.groupTitle;
        if (msg.includes('{groupName}')) msg = msg.replace("{groupName}", groupName);
      }
      if (this.state.errorAuthorizing) msg = i18n.spaceplanner.backstage.mergePlan.errorAuthorizing;
    }

    if (!isVersioned) {
      // buttonDisabled = !userAuthorized || isWorking || noDiff || wasMerged;
      buttonDisabled = true;
      if (!isWorking && !wasMerged && !wasReconciled) {
        if (authorizationChecked && diffChecked) {
          if (reconcileOnlyChecked) {
            if (this.isOwnerOrAdmin()) buttonDisabled = false;
          } else {
            if (userAuthorized && !noDiff) buttonDisabled = false;
          }
        }
      }
      if (authorizationChecked && diffChecked) {
        reconcileOnlyDisabled = !this.isOwnerOrAdmin();
        if (userAuthorized && !noDiff) {
          fullMergeDisabled = false;
          fullMergeChecked = !reconcileOnlyChecked;
        }
      }

    } else {
      buttonDisabled = isWorking || wasMerged || wasReconciled;
      if (authorizationChecked && diffChecked) {
        reconcileOnlyDisabled = false;
        if (userAuthorized && !noDiff) {
          fullMergeDisabled = false;
          fullMergeChecked = !reconcileOnlyChecked;
        }
      }
    }
    if (!authorizationChecked || !diffChecked) {
      buttonDisabled = true;
    }
    if (reconcileOnlyChecked) {
      buttonLabel = i18n.spaceplanner.backstage.mergePlan.reconcileLabel;
    }

    let opt1 = i18n.spaceplanner.backstage.mergePlan.choice.reconcileOnlyAlt;
    let opt1Tip = i18n.spaceplanner.backstage.mergePlan.choice.reconcileOnlyTipAlt;
    let opt2 = i18n.spaceplanner.backstage.mergePlan.choice.fullMergeAlt;
    let opt2Tip = i18n.spaceplanner.backstage.mergePlan.choice.fullMergeTip;

    if (!(wasMerged || wasReconciled)) {
      let textAlign = "left";
      if (Context.instance.uiMode.isRtl) textAlign = "right";
      choice = (
        <Form>
          <FormControl>
            <Fieldset name={"choice_"+this.componentId}>
              <Legend><b>{i18n.spaceplanner.backstage.mergePlan.choice.prompt}</b></Legend>
              <Radio key="reconcileOnly"
                disabled={reconcileOnlyDisabled}
                checked={reconcileOnlyChecked}
                onChange={evt => {
                  this.setState({
                    reconcileOnly: true
                  })
                }}
                >
                {opt1}
                <Tooltip style={{textAlign: textAlign}}
                  title={opt1Tip}>
                  <QuestionIcon size="16" style={{margin: "0px 4px 0px 4px"}}/>
                </Tooltip>
              </Radio>
              <Radio key="fullMerge"
                disabled={fullMergeDisabled}
                checked={fullMergeChecked}
                onChange={evt => {
                  this.setState({
                    reconcileOnly: false
                  })
                }}
                >
                {opt2}
                <Tooltip style={{textAlign: textAlign}}
                  title={opt2Tip}>
                  <QuestionIcon size="16" style={{margin: "0px 4px 0px 4px"}}/>
                </Tooltip>
              </Radio>
            </Fieldset>
          </FormControl>
        </Form>
      );
    }

    if (msg) msg = <CalciteP>{msg}</CalciteP>;

    let mainButton = (
      <Button disabled={buttonDisabled} onClick={this.mergeClicked}>
        {buttonLabel}
      </Button>
    )
    let backButton;
    if (wasMerged || wasReconciled) {
      mainButton = null;
      let pos = "before";
      let icon = <LeftIcon />
      if (Context.instance.uiMode.isRtl) {
        icon = <RightIcon />
      }
      backButton = (
        <Button onClick={this.backClicked} icon={icon} iconPosition={pos}>
          {i18n.general.back}
        </Button>
      )
    }

    return (
      <div>
        {msg}
        {choice}
        <div>
          {mainButton}
          {backButton}
        </div>
      </div>
    );
  }

}
