import BaseClass from "../util/BaseClass";
import Context from "./Context";
//import Planner from "../spaceplanner/base/Planner";
import * as portalUtil from "../util/portalUtil";
import * as val from "../util/val";
import Configuration, { IAppInfoTask, IUrlQueryParameters } from "./Configuration";

export default class ConfigLoader extends BaseClass {

  load(configuration: Configuration): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const task: IAppInfoTask = {
        appid: null,
        appItem: null,
        appData: null,
        urlBased: null,
        startInConfiguratorMode: false
      };
      this._parseUrl(task);
      this._readAppData(task).then(result => {
        configuration._apply(task);
        resolve();
      }).catch(ex => {
        console.error("Error loading application configuration:",ex);
        if (ex && ex.message === "__notAnIndoorsApp__") {
          reject(ex.message);
        } else {
          reject(ex);
        }
      });
    });
    return promise;
  }

  _parseUrl(task: IAppInfoTask) {
    const urlBased: IUrlQueryParameters = {
      appid: null,
      webmap: null,
      webscene: null,
      configure: null
    };
    let configuredAppId = Context.instance.config.configuredAppId;
    const lib = Context.instance.lib;
    const url = lib.esri.urlUtils.urlToObject(window.location.href);
    if (url && url.query) {
      let v, q = url.query;
      v = val.trim(q.appid);
      if (val.isNonEmptyStr(v)) {
        configuredAppId = null;
        urlBased.appid = v;
        task.appid = v;
      } else {
        v = val.trim(q.webmap);
        if (val.isNonEmptyStr(v)) {
          configuredAppId = null;
          urlBased.webmap = v;
          v = val.trim(q.webscene);
          if (val.isNonEmptyStr(v)) urlBased.webscene = v;
        }
      }
      if (q.configure === "true" || q.edit === "true") {
        urlBased.configure = true;
      }
    }
    if (val.isNonEmptyStr(configuredAppId)) {
      task.appid = configuredAppId;
    }
    task.urlBased = urlBased;
  }

  _readAppData(task: IAppInfoTask): Promise<void> {
    if (!task.appid) return Promise.resolve();
    const promise = new Promise<void>((resolve,reject) => {
      portalUtil.readItem(task.appid).then(itemResult => {
        task.appItem = itemResult && itemResult.data;
        return portalUtil.readItemJsonData(task.appid);
      }).then(dataResult => {
        task.appData = dataResult && dataResult.data;
        //task.appData.values = {};
      }).then(() => {
        //console.log("readAppData",task)
        if (task.urlBased && task.urlBased.configure) {
          const user = Context.instance.user;
          const canConfigure = (user.canEditPortalItem(task.appItem) ||
            user.canCreateAppItem());
          if (canConfigure && !Context.instance.uiMode.isKiosk) {
            task.startInConfiguratorMode = true;
          }
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.warn("Error loading application configuration item: ",task.appid);
        reject(ex);
      });
    });
    return promise;
  }

}
