import React from "react";

import { CalciteP } from "calcite-react/Elements";

import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as assignPrompt from "./assignPrompt";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as sourceUtil from "../../base/sourceUtil";

export default class UnassignPrompt extends React.Component {

  constructor(props) {
    super(props);

    let peopleNames = null;
    let unitNames = null
    const peopleFeatureItems = this.props.peopleFeatureItems;
    const unitFeatureItems = this.props.unitFeatureItems;
    if (peopleFeatureItems && peopleFeatureItems.length > 1) {
      const peopleLayer = sourceUtil.getPeopleSource().layer2D;
      const nameFld = aiimUtil.findField(peopleLayer.fields,FieldNames.PEOPLE_FULLNAME);
      peopleNames = [];
      peopleFeatureItems.forEach(f => {
        let v = aiimUtil.getAttributeValue(f.feature.attributes,nameFld.name);
        peopleNames.push(v);
      });
      peopleNames.sort();
    }
    if (!!this.props.unassignAreaUnits && unitFeatureItems && unitFeatureItems.length > 1) {
      const unitsLayer = sourceUtil.getUnitsSource().layer2D;
      const nameFld = aiimUtil.findField(unitsLayer.fields, FieldNames.NAME);
      unitNames = [];
      unitFeatureItems.forEach(f => {
        let v = aiimUtil.getAttributeValue(f.feature.attributes, nameFld.name);
        unitNames.push(v);
      });
      unitNames.sort();
    }

    this.state = component.newState({
      peopleNames: peopleNames,
      unitNames: unitNames
    });
  }

  componentDidMount() {
    const units = this.props.unitFeatureItems;
    if (!this.props.unassignAreaUnits && !this.props.isArea && units && units.length > 0 && units.length <= 200) {
      officePlanUtil.queryPeopleNamesAssignedToUnits(units).then(result => {
        let peopleNames = null;
        if (result && result.names && result.names.length > 0 &&
            !result.exceededTransferLimit) {
          peopleNames = result.names;
        }
        this.setState({peopleNames: peopleNames});
      }).catch(ex => {
        console.error("Error querying people names",ex);
      });
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const prompt = assignPrompt.getUnassignPrompt(this.props.promptOptions);

    let namesNode = null;
    const peopleNames = this.state.peopleNames;
    const unitNames = this.state.unitNames
    if (!!this.props.unassignAreaUnits && unitNames && unitNames.length > 0) {
      namesNode = (
        <div style={{fontSize: "0.8rem", overflow: "auto"}}>
          {unitNames.join(", ")}
        </div>
      )
    } else if (!this.props.unassignAreaUnits && peopleNames && peopleNames.length > 0) {
      namesNode = (
        <div style={{fontSize: "0.8rem", overflow: "auto"}}>
          {peopleNames.join(", ")}
        </div>
      )
    }
    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <CalciteP>{prompt.text}</CalciteP>
        {namesNode}
      </div>
    );
  }

}
