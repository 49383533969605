import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import OfficePlan from "../OfficePlan";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import TransactionGuard from "../TransactionGuard";
import * as serviceUtil from "../serviceUtil";
import * as sourceUtil from "../sourceUtil";
import { fixIdentifiers, IUndoRedoTransaction, refreshLayers } from "./transaction";
import * as val from "../../../util/val";

/*
  https://developer.myscript.com/docs/interactive-ink/1.3/android/advanced/combined-undo-redo-stacks
*/

export default class UndoManager extends BaseClass {

  identifiers = null;

  _locked = false;
  _lockedAdds;

  _maxLength = 10000;

  _stack: IUndoRedoTransaction[];
  _stackPosition: number;

  constructor() {
    super();
    this._stack = [];
    this._stackPosition = -1;
  }

  addTransaction(transaction: IUndoRedoTransaction) {
    if (!this._locked) {
      this._stack.splice(this._stackPosition + 1); // remove the redo transactions from the stack
      this._stack.push(transaction);
      if (this._maxLength) {
        const n = this._stack.length - this._maxLength;
        if (n > 0) {
          this._stack = this._stack.slice(n);
        }
      }
      this._stackPosition = (this._stack.length - 1);
      this._updateStatus();
    } else {
      this._lockedAdds.push(transaction);
    }
  }

  _apply(undoTransaction: IUndoRedoTransaction, method: "undo" | "redo") {
    const guard = new TransactionGuard({force: true});
    let transaction = undoTransaction;
    if (method === "redo") transaction = transaction.redoTransaction;
    console.log("UndoManager.applyTransaction",transaction);
    const reqInfo = transaction.requestInfo;
    const reqUrl = reqInfo.requestUrl;
    const reqOpts = reqInfo.requestOptions;
    const esriRequest = Context.instance.lib.esri.esriRequest;
    console.log("applyEdits.reqInfo",reqInfo)
    Promise.resolve().then(() => {
      if (method === "undo" && OfficePlan.getActive().isVersioned && OfficePlan.getActive().idCache) {
        return this._checkRestoreRows(transaction);
      }
    }).then(() => {
      return esriRequest(reqUrl,reqOpts);
    }).then((result) => {
      console.log("UndoManager.applyEdits.result",result);
      serviceUtil.validateApplyEditsResponse(result);
      const results = (result && result.data);

      fixIdentifiers(results,undoTransaction,method,this._stack);

      if (transaction.afterApplyEdits) {
        transaction.afterApplyEdits(results,undoTransaction,method);
      }
      return refreshLayers(transaction);
    }).then(() => {
      guard.close();
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated,
        wasUndoRedo: true
      });
      this._unlock(method);
    }).catch((error) => {
      guard.close();
      console.error("Error applying edits", error);
      Topic.publishErrorUpdatingData(error.submessage);
      this._unlock(); // TODO???
    });
  }

  async _checkRestoreRows(undoTransaction: IUndoRedoTransaction) {
    // this code is not in play yet, waiting on a fix from the branch versioning team
    const query = undoTransaction.requestInfo.requestOptions.query;
    const edits = JSON.parse(query.edits);
    if (Array.isArray(edits)) {
      const plan = OfficePlan.getActive();
      const restoreRows = [];
      edits.forEach(layerEdit => {
        if (layerEdit.adds && layerEdit.adds.length > 0) {
          //console.log("found adds ",layerEdit.adds)
          const restoreOIDs = [];
          const unitsLayer = sourceUtil.getUnitsLayer();
          const detailsLayer = sourceUtil.getDetailsLayer();
          const occupantsLayer = sourceUtil.getPeopleLayer();
          const sitesLayer = sourceUtil.getSitesLayer();
          const facilitiesLayer = sourceUtil.getFacilitiesLayer();
          const levelsLayer = sourceUtil.getLevelsLayer();
          let layer;
          if (unitsLayer && layerEdit.id === unitsLayer.layerId) {
            layer = unitsLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultUnitID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          } else if (detailsLayer && layerEdit.id === detailsLayer.layerId) {
            layer = detailsLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultDetailID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          } else if (occupantsLayer && layerEdit.id === occupantsLayer.layerId) {
            layer = occupantsLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultOccupantID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          } else if (sitesLayer && layerEdit.id === sitesLayer.layerId) {
            layer = sitesLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultSiteID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          } else if (facilitiesLayer && layerEdit.id === facilitiesLayer.layerId) {
            layer = facilitiesLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultFacilityID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          } else if (levelsLayer && layerEdit.id === levelsLayer.layerId) {
            layer = levelsLayer;
            layerEdit.adds.forEach(edit => {
              const oid = edit.attributes[layer.objectIdField];
              if (plan.idCache.hasDefaultLevelID(oid)) {
                restoreOIDs.push(oid);
              }
            })
          }
          if (layer && restoreOIDs.length > 0) {
            restoreRows.push({
              layerId: layerEdit.id,
              objectIds: restoreOIDs.slice()
            })
          }
        }
      });
      //console.log("beforeRestoreRows",restoreRows.length,restoreRows)
      if (restoreRows.length > 0) {
        const project = Context.instance.spaceplanner.planner.project;
        const versionManager = project.versionedInfo.versionManager;
        await versionManager.restoreRows(plan,restoreRows);
        //console.log("afterRestoreRows..............................")
      }
    }
  }

  clear() {
    this.identifiers = null;
    this._stack = [];
    this._stackPosition = -1;
    this._locked = false;
    this._lockedAdds = [];
    this._updateStatus();
  }

  _lock() {
    if (this._locked) return;
    this._locked = true;
    this._lockedAdds = [];
    this._updateStatus();
  }

  makeTransaction(props: Omit<IUndoRedoTransaction, "id" | "wasCompleted" | "failed" | "redoTransaction">): IUndoRedoTransaction {
    const transaction = {
      id: val.generateRandomUuid(),
      wasCompleted: false,
      failed: false,
      hadPeopleEdits: false,
      hadUnitEdits: false,
      hadDetailEdits: false,
      hadSiteEdits: false,
      hadFacilityEdits: false,
      hadLevelEdits: false,
      hadAreasTableEdits: false,
      requestInfo: null,
      redoTransaction: null
    };
    if (props) {
      Object.assign(transaction, props);
    }
    return transaction;
  }

  redo() {
    if (this._locked) return;
    this._lock();
    let transaction = this._stack[this._stackPosition + 1];
    if (transaction && transaction.redoTransaction) {
      this._apply(transaction,"redo");
    } else {
      this._unlock();
    }
  }

  undo() {
    if (this._locked) return;
    this._lock();
    const transaction = this._stack[this._stackPosition];
    if (transaction) {
      this._apply(transaction,"undo");
    } else {
      this._unlock();
    }
  }

  _unlock(methodApplied?: "undo" | "redo") {
    if (!this._locked) return;
    if (this._lockedAdds.length > 0) {
      this._stack.splice(this._stackPosition + 1); // remove the redo transactions from the stack
      this._lockedAdds.forEach(transaction => {
        this._stack.push(transaction);
        this._stackPosition = (this._stack.length - 1);
      })
    } else if (methodApplied === "undo") {
      this._stackPosition = (this._stackPosition - 1);
    } else if (methodApplied === "redo") {
      this._stackPosition = (this._stackPosition + 1);
    }
    this._locked = false;
    this._lockedAdds = [];
    this._updateStatus();
  }

  _updateStatus() {
    let canUndo = false;
    let canRedo = false;
    if (!this._locked) {
      let pos = this._stackPosition;
      let len = this._stack.length;
      canUndo = (len > 0 && pos >= 0);
      canRedo = (len > 0 && (pos < (len - 1)));
    }
    Rdx.setValue(null,Rdx.Keys.PLAN_CAN_UNDO,canUndo);
    Rdx.setValue(null,Rdx.Keys.PLAN_CAN_REDO,canRedo);
  }

}
