import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Reservations from "./Reservations";
import Topic from "../../context/Topic";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as workspaceReservationUtil from "../../components/main/More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";

export default class UpdateWorkspaceReservation extends BaseClass {

  constructor() {
    super();
    Topic.subscribe(Topic.ReloadWorkspace, ()=> {
      if (!Context.instance.aiim.datasets) return;
      this._init();
      const datasets = Context.instance.aiim.datasets;
      const configuration = Context.getInstance().configuration
      const configurables = configuration.extractConfigurables()
      const hotelingEnabled = configurables.workspaceReservation &&
        !Context.instance.uiMode.isKiosk && !Context.instance.user.isAnonymous();
        if (datasets.units && hotelingEnabled) {
          datasets._findHotelingUnits().then((res)=>{
            Topic.publish(Topic.RenderSidebar, {});
          })
        }
      Topic.publish(Topic.RenderSidebar, {});
    })
  }

  _init() {
    const view = Context.getInstance().views.mapView;
    if (!view) return;
    const datasets = Context.instance.aiim.datasets;
    const reservationLyr = datasets.getReservationsLayer(view);
    if (reservationLyr && !Context.instance.aiim.datasets.reservations) {
      datasets.reservations = new Reservations();
      datasets._datasets.push(datasets.reservations);
      datasets._setDatasetLayer(datasets.reservations, view, reservationLyr);
      datasets.reservations.checkSchema();
    }else if(reservationLyr) {
      datasets._setDatasetLayer(datasets.reservations, view, reservationLyr);
      datasets.reservations.checkSchema();
    }else {
      datasets.reservations = null;
    }
  }
}
