import React from "react";

import {ListHeader as CalciteListHeader} from "calcite-react/List";

export default class ListHeader extends React.Component {

  render() {
    return (
      <CalciteListHeader {...this.props}>
        {this.props.children}
      </CalciteListHeader>
    );
  }

}
