import React from "react"

import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import { Panel } from "../../styles/Common/panel";
import ListHeader from "../common/ListHeader";
import { ListItem, ListItemTitle, ListItemTitleAside } from "../../styles/Common/list";
import { ReviewersButton } from "../../styles/Common/button";
import Button from "../../../common/components/Button";
import DeleteIcon from "calcite-ui-icons-react/XIcon";
import RejectedIcon from "calcite-ui-icons-react/ExclamationMarkCircleFIcon";
import PendingIcon from "calcite-ui-icons-react/HourglassActiveIcon";
import AcceptedIcon from "calcite-ui-icons-react/CheckCircleFIcon";
import Loader from 'calcite-react/Loader/Loader';
import * as reviewersUtil from "../../base/ReviewerManagement/reviewersUtil";
import AddReviewer from "../common/Reviewers/AddReviewer";
import { ModalController } from "../../../common/components/Modal";
import * as assignPrompt from "../../components/common/assignPrompt";


export default class ReviewersPanel extends React.Component {
  _mounted = false
  _isPlanOwner = Context.instance.user.isPlanOwner()

  constructor(props) {
    super(props)
    this.state = component.newState({
      portalGroups: [],
      planReviewers: [],
      readyForReview: false,
      isQuerying: true,
      isUpdatingReviewers: false,
      selectedGroupId: null
    })
  }

  componentDidMount() {
    this._mounted = true

    this.updateReviewers()

    component.own(this, [
      Topic.subscribe(Topic.RefreshReviewers, params => {
        this.setState({
          // isQuerying: true,
          isUpdatingReviewers: true
        })
        this.updateReviewers()
      })
    ])

    // To remove all records. COMMENT OUT
    // const planId = Context.instance.spaceplanner.planId
    // this.resetPlanData(planId)
  }

  resetPlanData(planId) {
    Promise.all([this.changeReadyStatus(), this.deleteAllReviewers(planId), this.resetPortalGroup()])
  }

  resetPortalGroup() {
    const planReviewTable = OfficePlan.getActive().supportInfo.planReviewTable
    return planReviewTable.updatePortalGroup("invalid").then(() => {
      console.log("success")
      this.setState({
        selectedGroupId: null
      })
    }).catch(e => {
      console.error(e)
    })
  }

  deleteAllReviewers = (planId) => {
    const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable
    return reviewersTable.removeAll(planId).then(() => {
      console.log("deleted")
    }).catch(e => {
      console.error(e)
    })
  }

  updateReviewers = () => {
    this.getReviewers().then(result => {
      this.setState({
        planReviewers: result.planReviewers,
        isUpdatingReviewers: result.isUpdatingReviewers,
        isQuerying: false
      })
    }).catch(e => {
      this.setState({
        planReviewers: [],
        isUpdatingReviewers: false,
        isQuerying: false
      })
    })
  }

  getReviewers = () => {
    if (!this._mounted) return Promise.resolve()
    return new Promise((resolve, reject) => {
      const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable
      const planId = Context.instance.spaceplanner.planId
      let planReviewers = []
      let isUpdatingReviewers = true
      reviewersTable.queryReviewersByPlanId(planId).then(result => {
        if (result) {
          const reviewers = result.reviewers || []
          const promises = []
          reviewers.forEach(r => {
            const username = aiimUtil.getAttributeValue(r.attributes, FieldNames.USERNAME)
            const status = aiimUtil.getAttributeValue(r.attributes, FieldNames.REVIEW_STATUS)
            const tableData = {status: status}
            const promise = reviewersUtil.getReviewerData(username, tableData)
            promises.push(promise)
          })
          Promise.all(promises).then(results => {
            if (results && results.length > 0) {
              planReviewers = results.map(user => {
                const data = user.data, tableData = user.tableData
                const { username, firstName, lastName, fullName } = data;
                const status = tableData.status
                return {
                  username, 
                  firstName, 
                  lastName,
                  fullName,
                  status
                }
              })
              planReviewers.sort((a, b) => {
                let aFullName = a.fullName;
                if (!aFullName && a.firstName && a.lastName) {
                  const { firstName, lastName } = a;
                  aFullName = `${firstName} ${lastName}`;
                }
                if (!aFullName) {
                  aFullName = "";
                }

                let bFullName = b.fullName;
                if (!bFullName && b.firstName && b.lastName) {
                  const { firstName, lastName } = b;
                  bFullName = `${firstName} ${lastName}`;
                }
                if (!bFullName) {
                  bFullName = "";
                }
                
                return (
                  aFullName.toLowerCase() > bFullName.toLowerCase() 
                  ? 1
                  : aFullName.toLowerCase() < bFullName.toLowerCase() 
                    ? -1
                    : 0
                );
              })
              isUpdatingReviewers = false
            }
            resolve({
              planReviewers: planReviewers, 
              isUpdatingReviewers: isUpdatingReviewers
            })
          }).catch(e => {
            console.error(e)
            reject(e)
          })
        } else {
          resolve()
        }
      }).catch(e => {
        console.error("Could not get plan reviewers", e)
        reject(e)
      })
    })
  }

  componentWillUnmount() {
    this._mounted = false
  }

  removeReviewer = (user) => {
    const i18n = Context.instance.i18n
    const controller = new ModalController()

    const onOK = obj => {
      const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable
      const username = user.username
      reviewersTable.removeReviewer(username)
    } 

    let modalProps = {
      title: i18n.spaceplanner.reviewers.removeReviewerLabel,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: Context.instance.i18n.spaceplanner.reviewers.remove,
      onOK: onOK
    };

    // Try fullName first
    let fullName = user.fullName;

    // Otherwise... try to combine first and last names
    if (!fullName && user.firstName && user.lastName) {
      const { firstName, lastName } = user;
      fullName = `${firstName} ${lastName}`;
    }

    // If still nothing, make it blank?
    if (!fullName) {
      fullName = "";
    }
    const prompt = assignPrompt.getUnassignPrompt({
      subject: "reviewer",
      subjectItem: fullName
    })
    const content = (
      <div>{prompt.text}</div>
    )
    controller.show(modalProps, content)
  }

  addReviewer = (user) => {
    const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable
    const username = user.username
    const planId = Context.instance.spaceplanner.planId
    reviewersTable.addReviewer(username, planId)
  }

  onAddReviewerClicked = (e) => {
    const i18n = Context.instance.i18n
    AddReviewer.showModal({
      title: i18n.spaceplanner.reviewers.addReviewerLabel,
      planReviewers: this.state.planReviewers,
      onOK: this.addReviewer
    })
  }

  render() {
    const i18n = Context.instance.i18n
    const reviewers = this.state.planReviewers
    const reviewersItems = reviewers.map(reviewer => {
      return this.renderReviewer(reviewer)
    })
    
    if (reviewersItems.length === 0) {
      reviewersItems.push(
        <ListItem key={"no-reviewers"} reviewers>
          <ListItemTitle>
            {i18n.spaceplanner.reviewers.noReviewers}
          </ListItemTitle>
        </ListItem>
      )
    }

    const contentClassName = this._isPlanOwner ? "i-reviewers-content owner" : "i-reviewers-content"

    const addReviewerBtn = this._isPlanOwner ? (
      <ReviewersButton onClick={this.onAddReviewerClicked}>
        {i18n.spaceplanner.reviewers.addReviewerLabel}
      </ReviewersButton>
    ) : null

    const list = this.state.isUpdatingReviewers && !this.state.isQuerying ?
      (<Loader color="#004d4c" sizeRatio={1} style={{marginTop: '3rem', marginBottom: '3rem'}} />) :
      (<ul className="i-reviewers-list">{reviewersItems}</ul>)
    
    const content = this.state.isQuerying ?
      (<Loader color="#004d4c" sizeRatio={1} style={{marginTop: '3rem'}} />) : 
      (
        <div className={contentClassName}>
          {list}
          {addReviewerBtn}
        </div>
      )

    return (
      <Panel>
        <ListHeader title={i18n.spaceplanner.reviewers.caption}></ListHeader>
        {content}
      </Panel>
    )
  }

  updateReviewerStatus = (e, reviewer, status) => {
    const username = reviewer.username
    const reviewersTable = OfficePlan.getActive().supportInfo.reviewersTable
    reviewersTable.updateReviewerStatus(username, status).then(() => {
      console.log("success")
    }).catch(e => {
      console.error(e)
    })
  }

  renderReviewer(reviewer) {
    const i18n = Context.instance.i18n
    // Try fullName first
    let displayName = reviewer.fullName;

    // Otherwise... try to combine first and last names
    if (!displayName && reviewer.firstName && reviewer.lastName) {
      const { firstName, lastName } = reviewer;
      displayName = `${firstName} ${lastName}`;
    }

    // If still nothing, make it blank?
    if (!displayName) {
      displayName = "";
    }

    const status = reviewer.status
    const portalUser = Context.instance.user && Context.instance.user.portalUser
    const loggedInUsername = portalUser && portalUser.username
    let icon = null, iconTitle = null, buttons = null
    if (status === "accepted") {
      icon = (<AcceptedIcon style={{fill: "rgb(52 172 70)"}} />)
      iconTitle = i18n.spaceplanner.reviewers.acceptedTitle
    } else if (status === "pending") {
      icon = (<PendingIcon style={{top: '-0.1rem'}} />)
      iconTitle = i18n.spaceplanner.reviewers.pendingTitle
    } else if (status === "not-accepted") {
      icon = (<RejectedIcon style={{fill: "rgb(216 48 32)"}} />)
      iconTitle = i18n.spaceplanner.reviewers.notAcceptedTitle
    }

    if (loggedInUsername && loggedInUsername === reviewer.username) {
      buttons = (
        <div className={"i-reviewers-btns"}>
          <Button
            disabled={status === "not-accepted"}
            className={"i-notaccept-button"}
            title={i18n.spaceplanner.reviewers.notAcceptTitle}
            ariaLabel={i18n.spaceplanner.reviewers.notAcceptTitle}
            onClick={e => {this.updateReviewerStatus(e, reviewer, "not-accepted")}}>
            {i18n.spaceplanner.reviewers.notAccept}
          </Button>
          <Button
            disabled={status === "accepted"}
            className={"i-accept-button"}
            title={i18n.spaceplanner.reviewers.acceptTitle}
            ariaLabel={i18n.spaceplanner.reviewers.acceptTitle}
            onClick={e => {this.updateReviewerStatus(e, reviewer, "accepted")}}>
            {i18n.spaceplanner.reviewers.accept}
          </Button>
        </div>
      )
    }
    const statusClass = buttons ? "i-reviewers-status-user" : "i-reviewers-status"

    const deleteIcon = this._isPlanOwner ? 
      (
        <ListItemTitleAside>
        <Button
          iconButton={true}
          icon={<DeleteIcon />}
          onClick={() => this.removeReviewer(reviewer)}
        />
        </ListItemTitleAside>
      ) : null

    return (
      <ListItem key={component.nextId("reviewer-")} reviewers>
        <ListItemTitle>
          <div className={"i-reviewers-item"}>
            <div className={statusClass}>
              <span title={iconTitle}>{icon}</span>
              <div className={"i-reviewer-name"}>{displayName}</div>
            </div>
            {buttons}
          </div>
          {deleteIcon}
        </ListItemTitle>
      </ListItem>
    )
  }
}