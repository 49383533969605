import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import Source from "../base/Source";
import * as aiimUtil from "../util/aiimUtil";

export default class CimCategories extends BaseClass {

  categoryData;
  sources = [];

  _sourceKeys;
  _sourcesByKey;

  findSourceByKey(key) {
    return this._findSourceByKey(key,this.sources);
  }

  getCategoryData() {
    return this.categoryData;
  }

  hasData() {
    return !!this.categoryData;
  }

  load() {
    //console.log("CimCategories.load......................................")

    const promise = new Promise((resolve,reject) => {
      const views = Context.instance.views;
      const view = views && views.mapView;
      const sceneView = views && views.sceneView;
      const map = view && view.map;
      const layers = (map && map.allLayers) || [];
      let categoryData = this.categoryData = views && views.relatedConfig;
      if (views && views.relatedConfig && views.relatedConfig.launchActions) {
        const actions = views.relatedConfig.launchActions
        const list = []
        if (Array.isArray(actions) && actions.length > 0) {
          actions.forEach(action => {
            const matchAction = Context.instance.config.appLaunch.actions.map(function(e) {
              return e.actionId
            }).indexOf(action.actionId)
            const matchRemoved = Context.instance.config.appLaunch.removedWebmapActions.map(function(e) {
              return e.actionId
            }).indexOf(action.actionId)
            if (matchAction === -1 && matchRemoved === -1) {
              Context.instance.config.appLaunch.actions.push(action)
              list.push(action)
            }
          })
        }
      }

      if (categoryData) {
        categoryData.isRoot = true;
      } else {
        resolve(); // TODO will need to fill here instead of Categories
        return;
      }
      const task = {
        layersById: this._makeLayersById(view),
        mapView: view,
        sceneView: sceneView,
        sources: []
      };

      Promise.resolve().then(() => {
        return aiimUtil.waitForLayers(null,layers);
      }).then(() => {
        this._process(task,categoryData);
      }).then(() => {
        return this._processSources(task);
      }).then(() => {
        return this._processScene(task);
      }).then(() => {
        //console.log("categoryData",categoryData);
        //console.log("map",map);
        resolve();
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });
    });
    return promise;
  }

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

  _checkSchemas() {
    const promises = [];
    this.sources.forEach(source => {
      const p = source.checkSchema();
      if (p) promises.push(p);
    });
    if (promises.length > 0) {
      return Promise.all(promises).then((results) => {
        console.log("sources =.=.=.=.=.=",this.sources); // TODO temporary
      });
    } else {
      return Promise.resolve();
    }
  }

  _fillSources(task) {
    aiimUtil.makeReservationsSource(task);

    const cfg = Context.instance.config.categories;
    if (cfg && cfg.fill) {
      Object.keys(cfg.fill).forEach(key => {
        let mixin = false;
        let info = cfg.fill[key];
        if (info && this._isFillable(info)) {
          let source = this._findSourceByKey(key,task.sources);
          if (!source) {
            mixin = true;
            source = new Source({key: key});
            task.sources.push(source);
          } else {
            mixin = true; // TODO?????
          }
          if (mixin && info) {
            source.mixin(info);
          }
          if (!source.layer2D) {
            let layer2D = this._findLayer(task,task.mapView,source,true);
            if (layer2D) {
              this._setSourceLayer({
                source: source,
                setSource: true,
                layer: layer2D
              });
            }
          }
        }
      });
    };
  }

  _findLayer(task,view,source,updateSource) {
    if (!view) return;

    const addMapping = (key,value) => {
      if (key && value) {
        if (!source.mappings) source.mappings = {};
        source.mappings[key] = value;
      }
    };

    if (view.type === "2d" && source) {
      let dataset, info, uidField;
      let datasets = Context.instance.aiim.datasets || {};
      if (source.key === "Sites") {
        dataset = datasets.sites;
        if (dataset && dataset.floorAwareInfo) {
          info = dataset.floorAwareInfo.siteInfo;
          if (info && updateSource) {
            uidField = info.siteIdField;
            if (uidField) source.uniqueIdField = uidField;
            addMapping("siteIdField",info.siteIdField);
            addMapping("siteNameField",info.nameField);
          }
        }
      } else if (source.key === "Facilities") {
        dataset = datasets.facilities;
        if (dataset && dataset.floorAwareInfo) {
          info = dataset.floorAwareInfo.facilityInfo;
          if (info && updateSource) {
            uidField = dataset.floorAwareInfo.facilityInfo.facilityIdField;
            if (uidField) source.uniqueIdField = uidField;
            addMapping("facilityIdField",info.facilityIdField);
            addMapping("facilityNameField",info.nameField);
            addMapping("siteIdField",info.siteIdField);
          }
        }
      } else if (source.key === "Levels") {
        dataset = datasets.levels;
        if (dataset && dataset.floorAwareInfo) {
          info = dataset.floorAwareInfo.levelInfo;
          if (info && updateSource) {
            uidField = dataset.floorAwareInfo.levelInfo.levelIdField;
            if (uidField) source.uniqueIdField = uidField;
            addMapping("levelIdField",info.levelIdField);
            addMapping("facilityIdField",info.facilityIdField);
            addMapping("levelNameField",info.longNameField); // TODO?
            //addMapping("levelNameField",info.shortNameField);
            addMapping("levelNumberField",info.levelNumberField);
            addMapping("verticalOrderField",info.verticalOrderField);
          }
        }
      } else if (source.key === "Units") {
        dataset = datasets.units;
      } else if (source.key === "People") {
        dataset = datasets.people;
      } else if (source.key === "Events") {
        dataset = datasets.events;
      }
      if (dataset && dataset.layer2D) {
        return dataset.layer2D;
      }
    }
    //const is3D = (view.type === "3d");
    const layers = aiimUtil.getLayers(view);
    let found;
    layers.some(layer => {
      if (source.name === layer.title) {
        found = layer;
      } else if (source.name === "Facilities" && (layer.title === "Facilities Textured" || layer.title === "Facilities3D" || layer.title === "Facilities 3D")) {
        found = layer;
      } else if (source.name === "Units" && (layer.title === "Units3D" || layer.title === "Units 3D")) {
        found = layer;
      }
      return !!found;
    });
    return found;
  }

  _findSourceByKey(key,sources) {
    if (this._sourcesByKey && this._sourcesByKey[key]) {
      return this._sourcesByKey[key];
    }
    let found = null;
    sources.some(source => {
      if (source.key === key) {
        found = source;
        return true;
      }
      return false;
    });
    return found;
  }

  _getIconUrl(category) {
    let iconUrl = (
      (category.largeIcon && category.largeIcon.data) ||
      (category.mediumIcon && category.mediumIcon.data) ||
      (category.smallIcon && category.smallIcon.data)
    );
    if (typeof iconUrl === "string" && iconUrl.length > 0) {
      if (iconUrl.indexOf("data:") !== 0) {
        iconUrl = "data:image/png;base64," + iconUrl;
      }
    }
    return iconUrl;
  }

  _isFillable(fillInfo) {
    if (fillInfo) {
      if (Array.isArray(fillInfo.appTypes)) {
        const appType = Context.instance.appMode.appType;
        const fillable = fillInfo.appTypes.some(v => {
          return (v === appType);
        });
        return fillable;
      } else {
        return true;
      }
    }
    return false;
  }

  _isPeopleLayer(layer) {
    let aiim = Context.instance.aiim;
    if (layer && aiim && aiim.datasets && aiim.datasets.people) {
      return (layer === aiim.datasets.people.layer2D);
    }
    return false;
  }

  _makeLayersById(view) {
    const layersById = {};
    const map = view && view.map;
    const layers = (map && map.allLayers) || [];
    layers.forEach(l => {
      layersById[l.id] = l;
    });
    return layersById;
  }

  _makeSource(task,category,layer) {
    let key = category.name;
    // Don't duplicate sources defined in Pro (CIM) and config.js
    if (category.name === "Units" ||
        category.name === "Levels" ||
        category.name === "Facilities" ||
        category.name === "Sites") {
      key = "_"+key;
    }
    const source = new Source({
      cimCategory: category,
      key: key,
      name: category.name,
      iconUrl: category.xtn.iconUrl,
      searchFields: category.searchFields,
      suggestionTemplate: category.suggestionTemplate,
      fromCategoriesTable: true, // TODO???
      categoryLevel: 1, // TODO??
      subCategoryField: null, // TODO??
      layer2D: null,
      layer3D: null
    });

    let mappings = {
      displayField: category.displayFieldName,
      uniqueIdField: category.uniqueIdFieldName,
      subTitleField: category.subtitleFieldName,
      siteIdField: category.siteIdFieldName,
      siteNameField: category.siteNameFieldName,
      facilityIdField: category.facilityIdFieldName,
      facilityNameField: category.facilityNameFieldName,
      levelIdField: category.levelIdFieldName,
      levelNameField: category.levelNameFieldName,
      levelNumberField: category.levelNumberFieldName,
      levelShortNameField: category.levelShortNameFieldName,
      unitIdField: category.unitIdFieldName,
      unitNameField: category.unitNameFieldName,
      verticalOrderField: category.verticalOrderFieldName
    };
    mappings = JSON.parse(JSON.stringify(mappings));
    source.mixin({mappings: mappings});

    if (category.workOrderTitleFieldName) {
      let workOrderMappings = {
        idField: category.workOrderIdFieldName,
        titleField: category.workOrderTitleFieldName,
        priorityField: category.workOrderPriorityFieldName,
        statusField: category.workOrderStatusFieldName,
        createdOnField: category.workOrderCreatedOnFieldName,
        createdByField: category.workOrderCreatedByFieldName,
        assignedToField: category.workOrderAssignedToFieldName,
      };
      workOrderMappings = JSON.parse(JSON.stringify(workOrderMappings));
      source.mixin({workOrderMappings: workOrderMappings});
    }

    if (layer) {
      // const isPeopleLyr = this._isPeopleLayer(layer);
      // if (isPeopleLyr) {
      //   if (layer.title === "People" || layer.title === "Occupants") {
      //     source.key = "People";
      //   }
      // }
      this._setSourceLayer({
        source: source,
        cimSource: source,
        setSource: false,
        layer: layer
      });
    }
    return source;
  }

  _process(task,category) {
    if (!category.xtn) category.xtn = {};

    let iconUrl = this._getIconUrl(category);
    if (!iconUrl) {
      let pc = category.xtn.parentCategory;
      if (pc && pc.xtn.iconUrl) {
        iconUrl = pc.xtn.iconUrl;
      }
    }
    category.xtn.iconUrl = iconUrl;

    let webMapLayerId = category.webMapLayerId;
    if (webMapLayerId === "dcae95ddde0b47a9bd9d38f6a495bdad") {
      webMapLayerId = "Redlands_Pro_2_6_Web_Map_MS_MIL1_2889";
    }

    if (webMapLayerId) {
      let mapServiceLayerId = category.mapServiceLayerId;
      let layer = task.layersById[webMapLayerId];

      if (layer && layer.type === "map-image") {
        let ms = layer;
        layer = null;
        if (ms && ms.allSublayers) {
          ms.allSublayers.some(sl => {
            if (sl.id === mapServiceLayerId) {
              layer = sl;
              return true;
            }
            return false;
          });
        }
      }

      if (layer) {
        let source = this._makeSource(task,category,layer);
        if (source) {
          category.xtn.source = source;
          task.sources.push(source);
        }
      }

    }

    if (Array.isArray(category.categories)) {
      category.categories.forEach(cat => {
        if (!cat.xtn) cat.xtn = {};
        cat.xtn.parentCategory = category;
        this._process(task,cat)
      });
    }
  }

  _processScene(task) {
    if (!task.mapView || !task.sceneView) return Promise.resolve();
    const layers2D = aiimUtil.getLayers(task.mapView);
    const layers3D = aiimUtil.getLayers(task.sceneView);

    layers3D.forEach(layer3D => {
      let facilitiesSource, facilitiesLayer2D;
      if (layer3D.title === "Facilities" || layer3D.title === "Facilities Textured" || layer3D.title === "Facilities3D" || layer3D.title === "Facilities 3D") {
        let datasets = Context.instance.aiim.datasets;
        if(datasets && datasets.facilities && datasets.facilities.layer3D) {
          facilitiesLayer2D = datasets.facilities.layer2D;
          task.sources.some((s)=>{
            if(s.key === "Facilities") {
              facilitiesSource = s;
              return true;
            }
            return false;
          })
        }
      }
      layers2D.some(layer2D => {
        let source = null, matched = false, isFacilitiesSource = false;
        if (layer2D.xtnAiim) {
          source = (layer2D.xtnAiim.source || layer2D.xtnAiim.cimSource);
          if (source && source.layer2D === facilitiesLayer2D) {
            source = facilitiesSource;
            isFacilitiesSource = true;
          }
        }
        if (source) {
          if (layer2D.xtnAiim && layer2D.xtnAiim.dataset && layer3D.xtnAiim &&
              layer2D.xtnAiim.dataset === layer3D.xtnAiim.dataset) {
            matched = true;
          } else if (layer3D.title === layer2D.title) {
            matched = true;
          } else if (layer2D.title === "Facilities" && (layer3D.title === "Facilities Textured" || layer3D.title === "Facilities3D" || layer3D.title === "Facilities 3D")) {
            matched = true;
          } else if (layer2D.title === "Units" && (layer3D.title === "Units3D" || layer3D.title === "Units 3D")) {
            matched = true;
          }
          if (matched && !source.layer3D) {
            source.layer3D = layer3D;
            if (!layer3D.xtnAiim) layer3D.xtnAiim = {};
            if (layer2D.xtnAiim.source) {
              layer3D.xtnAiim.source = layer2D.xtnAiim.source;
            }
            if (layer2D.xtnAiim.cimSource) {
              layer3D.xtnAiim.cimSource = layer2D.xtnAiim.cimSource;
            }
            if (isFacilitiesSource) {
              layer3D.xtnAiim.source = facilitiesSource;
            }
          }
        }
        return !!matched;
      });
    });
    return Promise.resolve();
  }

  _processSources(task) {
    const sourceKeys = [], sourcesByKey = {};

    let hasPeopleSource = task.sources.some((source) => {
      return source.key === "People"
    });
    if (!hasPeopleSource) {
      let first = null, firstNoExp = null;
      task.sources.forEach((source) => {
        if (source.layer2D && source.cimCategory) {
          const hasExp = !!source.cimCategory.filterExpression;
          const layer = source.layer2D;
          const isPeopleLyr = this._isPeopleLayer(layer);
          if (isPeopleLyr) {
            if (layer.title === "People" || layer.title === "Occupants") {
              if (!first) first = source;
              if (!firstNoExp && !hasExp) firstNoExp = source;
            }
          }
        }
      });
      if (firstNoExp) first = firstNoExp
      if (first) first.key = "People"
    }

    this._fillSources(task);
    task.sources.forEach((source,idx) => {
      source.index = idx;
      sourceKeys.push(source.key);
      sourcesByKey[source.key] = source;
      if (source.workOrderMappings) {
        if (!source.iconUrl) source.iconUrl = "assets/workorder.png"
      }
    });
    this.sources = task.sources;
    this._sourceKeys = sourceKeys;
    this._sourcesByKey = sourcesByKey;
    return this._checkSchemas();
  }

  _setSourceLayer(options) {
    const {source, cimSource, setSource, layer} = options;
    if (layer && layer.declaredClass === "esri.layers.support.Sublayer") {
      let layer2D = layer.xtnFeatureLayer;
      // if (!layer2D) {
      //   layer2D = layer.createFeatureLayer();
      //   layer2D.load();
      // }
      source.subLayer = layer;
      source.url = layer.url;
      source.layer2D = layer2D;
      if (!layer.xtnAiim) layer.xtnAiim = {};
      if (cimSource && !layer.xtnAiim.cimSource) {
        layer.xtnAiim.cimSource = cimSource;
      }
      if (setSource && !layer.xtnAiim.source ) {
        layer.xtnAiim.source = source;
      }
      if (layer2D && !layer2D.xtnAiim) layer2D.xtnAiim = layer.xtnAiim;
      if (!layer2D) {
        console.log("No subLayer.xtnFeatureLayer for:",source)
      }
    } else {
      source.url = layer.url+"/"+layer.layerId;
      source.layer2D = layer;
      if (!layer.xtnAiim) layer.xtnAiim = {};
      if (cimSource && !layer.xtnAiim.cimSource) {
        layer.xtnAiim.cimSource = cimSource;
      }
      if (setSource && !layer.xtnAiim.source ) {
        layer.xtnAiim.source = source;
      }
      if (setSource && layer.xtnSubLayer && !layer.xtnSubLayer.xtnAiim) {
        layer.xtnSubLayer.xtnAiim = layer.xtnAiim;
      }
    }
  }

}
