import React, { Component } from 'react';
import {connect} from "react-redux";

import Context from "../../../../../context/Context";
import Icons from "../../../../util/Icons";
import SpaceFilters from "./SpaceFilters";
import Button from '../../../../../common/components/Button';
import Rdx from '../../../../../redux/Rdx';
import * as component from "../../../../util/component";
import * as bookWorkspaceUtil from "./BookWorkspaceUtil";
import * as equipmentFilterUtil from "../../../../../../src/aiim/util/equipmentFilterUtil";


// MSAL Authentication
import Office365 from "./WorkspaceReservation/Office365";
import { ModalController } from '../../../../../common/components/Modal';
import DurationFilters from './DurationFilters';
import Loader from 'calcite-react/Loader/Loader';
import Topic from '../../../../../context/Topic';
import { getAttributeValue } from '../../../../../aiim/util/aiimUtil';
import FieldNames from '../../../../../aiim/datasets/FieldNames';
import { getAttributes } from '../../../../../spaceplanner/components/common/MultipleAssignments/multipleAssignmentsUtil';

const CSS = {
    popoutInner: "i-popout-inner i-filter-popup",
    popoutOuter: "i-popout-outer",
    panelHeader: "i-infopanel-header i-panel-header",
    panelHeaderTools: "i-panel-header-tools",
    filterForm: "i-filter-form",
    toggleAllDay: "i-toggle-all-day",
    toggleLabel: "i-toggle-label",
    durationFilter: "i-duration-filter",
    durationGroup: "i-duration-group",
    durationDate: "i-date",
    durationTime: "i-time",
    iconContainer: "i-sidebar-icon-container i-filter-container",
    icon: "i-more-menu-icon",
    spaceFilters: "i-space-filter",
    button: "i-book-button",
    dateString: "i-date-string",
    sidebarPanelOpen: "i-sidebar-panel-open",
  };

class FilterPopup extends Component {
  accessToken
  format = "h:mm A"
  keys = {
    checkInDate: "checkInDate",
    checkInString: "checkInString",
    checkOutDate: "checkOutDate",
    checkOutString: "checkOutString"
  }
  _mounted = false
  hotelEquipmentFields = []

  constructor(props) {
    super(props)
    this.state = component.newState({
      isWorking: true,
      noData: false,
      spaceTypeFilters: [],
      areaFilters: [],
      equipmentFilters: [],
    })
    this.closeRef = React.createRef()
    this.titleRef = React.createRef()
    this.bookingSystem = Office365.getInstance()
  }

  componentWillUnmount() {
    this._mounted = false
    component.componentWillUnmount(this)
  }

  componentDidMount() {
    this._mounted = true
    this._focus()
    const i18n = Context.instance.i18n
    const layer2D = Context.getInstance().aiim.datasets.units && 
                        Context.getInstance().aiim.datasets.units.layer2D;
    if (Context.instance.hotelingUnits) {
      Context.instance.hotelingUnits.then(units => {
        if (Context.instance.areas) {
          Context.instance.areas.then(areas => {
            this.hotelEquipmentFields = equipmentFilterUtil.getEquipmentFields("DOM_EQUIPMENT_HOTEL");
            let spaceTypeFilters = []
            let siteFilters = []
            let equipmentFilters = []
            units.forEach(unit => {
              const unitAttributes = getAttributes(unit);
              const spaceType = getAttributeValue(unitAttributes, FieldNames.UNITS_USE_TYPE);
              const levelIdField = Context.instance.aiim.getLevelIdField(layer2D);
              const levelId = levelIdField && getAttributeValue(unitAttributes,levelIdField.name);
              const siteName = bookWorkspaceUtil.getSiteName(levelId);
              if (!spaceTypeFilters.includes(spaceType)) spaceTypeFilters.push(spaceType)
              if (!siteFilters.includes(siteName)) siteFilters.push(siteName);
              this.hotelEquipmentFields.forEach((fieldName)=> {
                const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
                if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
                  if(!equipmentFilters.includes(fieldName))equipmentFilters.push(fieldName);
                }
              })
            })
            if(spaceTypeFilters) Rdx.setValue(this, Rdx.Keys.SPACETYPES_DROPDOWN, spaceTypeFilters);
            if(siteFilters) Rdx.setValue(this, Rdx.Keys.SITE_DROPDOWN, siteFilters);
            if(equipmentFilters) Rdx.setValue(this, Rdx.Keys.EQUIPMENT_DROPDOWN, equipmentFilters);

            let areaFilters = []
            areas.forEach(area => {
              const areaAttributes = getAttributes(area);
              const name = getAttributeValue(areaAttributes, FieldNames.AREA_NAME)
              const type = getAttributeValue(areaAttributes, FieldNames.AREA_TYPE)
              if (!areaFilters.includes(name) && ((type === "hotel") || (type === "hotdesk") || (type === "workspace"))) areaFilters.push(name)
            })
            this.setState({
              isWorking: false,
              siteFilters: siteFilters,
              spaceTypeFilters: spaceTypeFilters,
              areaFilters: areaFilters,
              equipmentFilters: equipmentFilters
            })
          }).catch(error => {
            this.setState({
              isWorking: false,
              siteFilters: [],
              spaceTypeFilters: [],
              areaFilters: [],
              equipmentFilters: [],
              noData: true
            })
            console.error(error)
            ModalController.showMessage(i18n.more.bookWorkspace.invalidData)
          })
        } else {
          this.setState({
            isWorking: false,
            siteFilters: [],
            spaceTypeFilters: [],
            areaFilters: [],
            equipmentFilters: [],
            noData: true
          })
          ModalController.showMessage(i18n.more.bookWorkspace.missingAreaTable)
        }
      }).catch(error => {
        this.setState({
          isWorking: false,
          siteFilters: [],
          spaceTypeFilters: [],
          areaFilters: [],
          equipmentFilters: [],
          noData: true
        })
        console.error(error)
        ModalController.showMessage(i18n.more.bookWorkspace.invalidData)
      })
      component.own(this, [
        Topic.subscribe(Topic.BookingFiltersSet, params => {
          if (this._mounted) {
            if (typeof this.props.closePopup === "function") this.props.closePopup()
            if (this.props.rdxSidebarActiveKey !== "bookWorkspace") {
              Rdx.setValue(this, Rdx.Keys.SIDEBAR_ACTIVE_KEY, "bookWorkspace");
              document.body.classList.add(CSS.sidebarPanelOpen);
              Topic.publish(Topic.SidebarButtonClicked, {sidebarKey: "bookWorkspace"});
              Context.instance.views.toggleClickHandlers("resume");
            }

            setTimeout(function() {
              Topic.publish(Topic.BookingFiltersSubmit, {})
            }, 100)
          }
        })
      ])
    } else {
      this.setState({
        isWorking: false,
        siteFilters: [],
        spaceTypeFilters: [],
        areaFilters: [],
        noData: true
      })
      ModalController.showMessage(i18n.more.bookWorkspace.missingUnits)
    }
  }

  componentDidUpdate() {
    if (!this.state.noData && !this.state.isWorking) {
      this._focus()
    }
  }

  _focus() {
    if (this.titleRef && this.titleRef.current && this.titleRef.current.focus) {
      this.titleRef.current.focus()
    }
  }

  onSubmit = () => {
    if (this._mounted) {
      Topic.publish(Topic.DurationFiltersSet, {})
    }
  }

  render() {
    const i18n = Context.instance.i18n

    if (this.state.noData) {
      return null
    } else if (this.state.isWorking) {
      return (
        <div className={CSS.popoutOuter}>
          <Loader sizeRatio={2} />
        </div>
      )
    } else {
      return (
        <div className={CSS.popoutOuter}>
          <div className={CSS.popoutInner}>
            <div className={CSS.panelHeader}>
              <h2 tabIndex="0" ref={this.titleRef}>{i18n.more.bookWorkspace.popupTitle}</h2>
              <div className={CSS.panelHeaderTools}>
                <button ref={this.closeRef}
                  title={i18n.general.close} aria-label={i18n.more.bookWorkspace.closeAriaLabelInit}
                  tabIndex="0" onClick={this.props.closePopup}>
                  {Icons.close()}
                </button>
              </div>
            </div>
            <div className={CSS.filterForm}>
              <DurationFilters initialFilter={true} />
              <SpaceFilters 
                initialFilter={true} 
                siteFilters={this.state.siteFilters}
                spaceTypeFilters={this.state.spaceTypeFilters} 
                areaFilters={this.state.areaFilters} 
                equipmentFilters={this.state.equipmentFilters}
              />
            </div>
            <div className={CSS.button}>
                <Button type="button" onClick={this.onSubmit}>{i18n.general.done}</Button>
            </div> 
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    rdxStartDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDayChecked: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY),
    rdxSidebarActiveKey: Rdx.getValue(state, Rdx.Keys.SIDEBAR_ACTIVE_KEY)
  }
}

export default connect(mapStateToProps)(FilterPopup);