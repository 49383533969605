import React from "react";
import Context from "../../../context/Context";
import * as component from "../../util/component";
import MeetingRoomsModel, { IMeetingRoom } from "./MeetingRoomsModel";
import EquipmentFilterDropdown from "../More/Actions/BookWorkspace/EquipmentFilterDropdown";
import { getAttributeValue } from '../../../aiim/util/aiimUtil';
import { getEquipmentFields } from "../../../aiim/util/equipmentFilterUtil";
import Topic from "../../../context/Topic";

interface ILevelFilterProps {
  facilityId: string,
  levelId: string,
  siteId: string,
  items: IMeetingRoom[],
  equipments: string[],
  onMount?: (filter: LevelFilter) => void
}
interface ILevelFilterState {
  facilityId?: string,
  levelId?: string,
  siteId?: string,
  equipments?: string[],
  equipmentOptions?: string[],
  working: boolean // not in use but MeetingRoomsModel has a reference to it
}
export default class LevelFilter extends React.Component<ILevelFilterProps, ILevelFilterState> {

  meetingRoomUnits: IMeetingRoom[] = [];
  meetingEquipments: string[] = [];
  model: MeetingRoomsModel;
  mounted: boolean;

  constructor(props: ILevelFilterProps) {
    super(props);
    this.model = new MeetingRoomsModel();
    this.state = component.newState({
      facilityId: this.props.facilityId,
      levelId: this.props.levelId,
      siteId: this.props.siteId,
      equipments: this.props.equipments,
      equipmentOptions: []
    });
  }

  componentDidMount() {
    this.model.getMeetingRoomUnits().then((units)=> {
      this.meetingRoomUnits = units;
      this.getEquipmentOptions("onMount");
    })
    if (this.props.onMount) this.props.onMount(this);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getEquipmentOptions(when?: "onMount") {
    const siteId = this.state.siteId;
    const facilityId = this.state.facilityId;
    const levelId = this.state.levelId;
    let temp = [];
    if(!this.meetingRoomUnits || this.meetingRoomUnits.length === 0) return [];

    const checkAvail=(unitAttributes, fieldName)=> {
      const isEquipmentAvailable = getAttributeValue(unitAttributes, fieldName);
      if(isEquipmentAvailable && (isEquipmentAvailable === 1)) {
        if(!temp.includes(fieldName)) temp.push(fieldName);
      }
    }

    this.meetingEquipments = getEquipmentFields("DOM_EQUIPMENT_MEETING") as string[];
    this.meetingRoomUnits.forEach((meetingUnit) => {
      const site = meetingUnit.siteId;
      const facility = meetingUnit.facilityId;
      const level = meetingUnit.levelId;

      const unitAttributes = meetingUnit.feature && meetingUnit.feature.attributes;
      if(siteId && facilityId && levelId) {
        if((siteId === site) && (facilityId === facility) && (levelId === level)){
              this.meetingEquipments.forEach((fieldName)=> {
                checkAvail(unitAttributes, fieldName);
              })
        }
      } else if (!siteId && !facilityId && (levelId && (levelId === level))) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if (!facilityId && !levelId && (siteId && (siteId === site))) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if (!siteId && !levelId && (facilityId && (facilityId === facility))) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if ((siteId === site) && (facilityId === facility) && !levelId) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if ((facilityId === facility) && (levelId === level) && !siteId) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if ((levelId === level) && (siteId === site) && !facilityId) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      } else if (!siteId && !facilityId && !levelId) {
        this.meetingEquipments.forEach((fieldName)=> {
          checkAvail(unitAttributes, fieldName);
        })
      }
    });

    this.setState({
      equipmentOptions: temp
    }, ()=> {
      if(when!== "onMount") Topic.publish(Topic.FiltersMeetingChange, {})
    })
  }

  render() {
    const i18n = Context.instance.i18n;
    const levelsDataset = Context.instance.aiim.datasets.levels;
    const datasets = Context.instance.aiim.datasets;
    const isSitesLayer = datasets && datasets.sites;    
    const facilityId = this.state.facilityId;
    const levelId = this.state.levelId;
    const siteId = this.state.siteId;

    const siteSelected = evt => {
      const v = evt.target.value || null;
      if(v !== siteId) {
        this.setState({
          siteId: v,
          facilityId: null,
          levelId: null,
          equipments: []
        }, ()=> {
          this.getEquipmentOptions();
        })
      }
    }

    const facilitySelected = evt => {
      let levelIdV, equipmentsV = this.state.equipments;
      const v = evt.target.value || null;
      if (levelId && !v) {
        levelIdV = null;
      }
      if (levelId && v) {
        const ld = levelsDataset && levelsDataset.getLevelData(levelId);
        if (!ld || ld.facilityId !== v) {
          levelIdV = null;
        }
      }
      if(v!== facilityId) {
        equipmentsV = [];
      }
      this.setState({
        facilityId: v,
        levelId: levelIdV,
        equipments: equipmentsV
      }, ()=> {
        if(v!== facilityId) {
          this.getEquipmentOptions();
        }
      });
    };

    const facilityClicked = evt => {
      facilitySelected(evt);
    };

    const levelSelected = evt => {
      const v = evt.target.value || null;
      let val;
      if(v!== levelId) {
        val = [];
      }
      this.setState({
        levelId: v,
        equipments: val
      }, ()=> {
        if(v!== levelId) {
          this.getEquipmentOptions();
        }
      })
    };

    const levelClicked = evt => {
      levelSelected(evt);
    };

    let facilities = levelsDataset && levelsDataset.getFacilities();
    let facilitiesA = [];
    if(facilities && facilities.length > 0) {
      facilities.forEach((facility)=> {
        if (!siteId || siteId === facility.siteId) {
          facilitiesA.push(facility);
        }
      })
    }

    // filter facilities and levels depending on site selected (cascading)
    // const levels = [], facilitiesA = [];
    // if (facilities && facilities.length > 0) {
    //   facilities.forEach(f => {
    //     if (!siteId || siteId === f.siteId) {
    //       facilitiesA.push({
    //         name : f.facilityName,
    //         value: f.facilityId
    //       })
    //     }
    //     if (!facilityId || facilityId === f.facilityId) {
    //       f.levels.forEach(l => {
    //         levels.push({
    //           name: l.levelShortName,
    //           value: l.levelId
    //         })
    //       });
    //     }
    //   });
    // }

    //sites to be displayed in the sites filter
    let sites = new Set(), siteOptions = [];
    if(facilities && facilities.length > 0) {
      facilities = facilities.slice();
      facilities.sort((a,b) => {
        if (a.siteName && b.siteName) {
          return a.siteName.localeCompare(b.siteName);
        }
        return 0;
      });
      siteOptions.push(
        <option key="empty" value="">
          {i18n.meetingRooms.any}
        </option>
      );
      facilities.forEach(facility => {
        if(!sites.has(facility.siteId)){
          sites.add(facility.siteId);
          siteOptions.push( 
          <option key={facility.siteId} value={facility.siteId}>
            {facility.siteName}
          </option>)
        }
      })
    }

    // facilities
    let facilityOptions;
    const levels = [];
    if (facilitiesA && facilitiesA.length > 0) {
      facilitiesA.sort((a,b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
      facilityOptions = [];
      facilityOptions.push(
        <option key="empty" value="">
          {i18n.meetingRooms.any}
        </option>
      );
      facilitiesA.forEach(f => {
        if (facilityId && facilityId === f.facilityId) {
          f.levels.forEach(l => {
            levels.push({
              name: l.levelShortName,
              value: l.levelId
            })
          });
        }
        facilityOptions.push(
          <option key={f.facilityId} value={f.facilityId}>
            {f.facilityName}
          </option>
        );
      });
    }

    // levels to be displayed depending facility selected
    let levelOptions = [], removeDuplicates = new Set();
    levelOptions.push(
      <option key="empty" value="">
        {i18n.meetingRooms.any}
      </option>
    );
    if (levels && levels.length > 0) {
      levels.sort((a,b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
      levels.forEach(level => {
        if(!removeDuplicates.has(level.name)) {
          removeDuplicates.add(level.name);
          levelOptions.push(
            <option key={level.value} value={level.value}>
              {level.name}
            </option>
          );
        }
      });
    }

    const equipmentChecked=(values)=>{
      this.setState({
        equipments: values
      })
    }

    return (
      <div>
        {isSitesLayer && siteOptions && siteOptions.length > 1 && 
           <div>
           <label>
             <div>{i18n.meetingRooms.filterOptions.site}</div>
             <select defaultValue={siteId} 
                className="i-filter-select"
                onBlur={siteSelected} 
                onClick={siteSelected} onKeyUp={siteSelected}>
               {siteOptions}
             </select>
           </label>
         </div>
        }
        {facilityOptions &&
          <div>
            <label>
              <div>{i18n.meetingRooms.filterOptions.facility}</div>
              <select defaultValue={facilityId}
                className="i-filter-select"
                onBlur={facilitySelected}
                onClick={facilityClicked} onKeyUp={facilityClicked}>
                {facilityOptions}
              </select>
            </label>
          </div>
        }
        {levelOptions &&
          <div>
            <label>
              <div>{i18n.meetingRooms.filterOptions.level}</div>
              <select defaultValue={levelId} onBlur={levelSelected}
                className="i-filter-select"
                onClick={levelClicked} onKeyUp={levelClicked}
                disabled={levelOptions.length === 1}>
                {levelOptions}
              </select>
            </label>
          </div>
        }
        {this.meetingEquipments && this.meetingEquipments.length > 0 && (
            <div>
              <label>
                <div>{i18n.meetingRooms.filterOptions.equipment}</div>
                <EquipmentFilterDropdown 
                  className="i-dd-levelfilter"
                  type="meetingRooms"
                  equipmentOptions={this.state.equipmentOptions}
                  checkedEquipments={this.state.equipments}
                  onChange={equipmentChecked} 
                />
              </label>
            </div>
          )
        }
      </div>
    );
  }

  validate(criteriaPart) {
    let ok = true;
    criteriaPart.facilityId = this.state.facilityId;
    criteriaPart.levelId = this.state.levelId;
    criteriaPart.siteId = this.state.siteId;
    criteriaPart.equipments = this.state.equipments;
    return ok;
  }

}
