import FieldNames from "../../aiim/datasets/FieldNames";
import Stat from "./Stat";
import * as queryUtil from "./queryUtil";
import * as aiimUtil from "../../aiim/util/aiimUtil";

export default class StatPeopleUnassigned extends Stat {

  query() {
    const source = this.getSource();
    if (!source || !source.layer2D) return;
    let unitIdFieldName = FieldNames.UNIT_ID;
    let areaIdFieldName = FieldNames.PEOPLE_AREA_ID;
    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields,unitIdFieldName);
      if (f) unitIdFieldName= f.name;
      f = aiimUtil.findField(fields,areaIdFieldName);
      if (f) areaIdFieldName= f.name;
    }
    const wh = "("+unitIdFieldName+" IS NULL) AND ("+areaIdFieldName+" IS NULL)";
    const options = {
      source: source,
      where: wh,
    }
    queryUtil.queryCount(options).then(result => {
      if (typeof result === "number") {
        this.value = result;
        //console.log("People",this.value)
        this.broadcastRefreshed();
      }
    }).catch(ex => {
      console.error("Error querying stat",this.key,ex);
    })
  }

}
