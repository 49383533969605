import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import { DimensionLabelInfo } from "../redux";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import { LayerType } from "../../../../util/interfaces";

export interface IDimensionAnnotationProps {
  layerType: LayerType;
}

export default class DimensionAnnotation extends BaseClass implements IDimensionAnnotationProps {

  layerType: LayerType;

  private dimensionLabelInfo: DimensionLabelInfo;
  private graphicsLayer: __esri.GraphicsLayer;
  private graphicsLayerId = "indoors-dimension-annotation";

  private minDimensionScale = 100;
  private maxDimensionSegments = 100000; // basically not currently in use
  private warnIfGeographic = true;

  private _graphics: __esri.Graphic[];

  constructor(props: IDimensionAnnotationProps) {
    super(props);
    Object.assign(this,props);

    const view = mapUtil.getView();
    const reactiveUtils = Context.instance.lib.esri.reactiveUtils;
    this.own(
      reactiveUtils.when(() => view.stationary === true, () => {
        if (this.graphicsLayer && this._graphics && (this._graphics.length > 0)) {
          this.updateDimensions(this._graphics);
        }
      })
    )
  }

  clear() {
    this._graphics = null;
    if (this.graphicsLayer) this.graphicsLayer.removeAll();
  }

  formatLength(value: number, intlUnit: string) {
    const intl: typeof __esri.intl = Context.instance.lib.esri.intl;
    return intl.formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "unit",
      unit: intlUnit,
      unitDisplay: "short",
    });
  }

  getIntlUnit(): "foot" | "inch" | "meter" | "millimeter" {
    const linearUnits = this.getLinearUnits();
    if (linearUnits === "feet") return "foot";
    else if (linearUnits === "meters") return "meter";
  }

  getLength(view: __esri.View, polyline: __esri.Polyline): number {
    const linearUnits = this.getLinearUnits();
    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const isWebMercator = view.spatialReference.isWebMercator;
    const isGeographic = view.spatialReference.isGeographic;
    //const isWGS84 = view.spatialReference.isWGS84;
    if (isWebMercator) {
      //return ge.geodesicLength(polyline,linearUnits);
      // use planar length so that annotation matches specified drawing dimensions
      return ge.planarLength(polyline,linearUnits);
    } else if (!isGeographic) {
      return ge.planarLength(polyline,linearUnits);
    } else {
      // @todo handle geographic coordinates (async will be too slow)
      if (this.warnIfGeographic) {
        this.warnIfGeographic = false;
        console.warn("Dimension annotation is not supported for a geographic spatial reference.")
      }
      return 0;
    }
  }

  getLinearUnits(): "feet" | "meters" {
    if (this.dimensionLabelInfo) return this.dimensionLabelInfo.units;
    return "feet";
  }

  handleCreateEvent(event: __esri.SketchViewModelCreateEvent, stencilGraphic?: __esri.Graphic) {
    // "start" "active" "complete" "cancel"
    if (event.state === "start") this.reorderLayer();
    const graphic = stencilGraphic || event.graphic;
    if (graphic) this.updateDimensions([graphic]);
  }

  handleUpdateEvent(event: __esri.SketchViewModelUpdateEvent) {
    // "start" "active" "complete" "cancel"
    if (event.state === "start") this.reorderLayer();
    if (event.graphics && event.graphics.length === 1) {
      this.updateDimensions([event.graphics[0]]);
    }
  }

  handleUndoEvent(event: __esri.SketchViewModelUndoEvent) {
    if (event.graphics && event.graphics.length === 1) {
      this.updateDimensions([event.graphics[0]]);
    }
  }

  handleRedoEvent(event: __esri.SketchViewModelRedoEvent) {
    if (event.graphics && event.graphics.length === 1) {
      this.updateDimensions([event.graphics[0]]);
    }
  }

  initialize(layerType?: LayerType) {
    this.clear();
    if (layerType) this.layerType = layerType;
    const view = mapUtil.getView();
    const graphicsLayer = this.graphicsLayer = mapUtil.ensureGraphicsLayer(view,this.graphicsLayerId);
    graphicsLayer.minScale = 0;
    graphicsLayer.maxScale = 0;

    /*
    const unitsLayer = sourceUtil.getUnitsLayer();
    if (unitsLayer && unitsLayer.labelingInfo && unitsLayer.labelingInfo.length > 0) {
      let minScale = unitsLayer.labelingInfo[0].minScale;
      let maxScale = unitsLayer.labelingInfo[0].maxScale;
      if (typeof minScale === "number" && typeof minScale === "number") {
        graphicsLayer.minScale = minScale;
        graphicsLayer.maxScale = maxScale;
      }
    }
    */

    if (this.minDimensionScale > 0) graphicsLayer.minScale = this.minDimensionScale;
  }

  makeTextSymbol(text) {
    const symbol = {
      type: "text",
      text: text,
      color: "black",
      angle: 0,
      xoffset: 0,
      yoffset: 0,
      horizontalAlignment: "center",
      verticalAlignment: "middle",
      font: {
        size: 12,
        family: "Ubuntu Mono"
      },
      haloSize: 2,
      haloColor: "#e8e8e8"
    };
    return symbol;
  }

  reorderLayer() {
    const view = mapUtil.getView();
    view.map.reorder(this.graphicsLayer,view.map.layers.length); 
  }

  setDimensionLabelInfo(info: DimensionLabelInfo) {
    this.dimensionLabelInfo = info;
    if (!this.dimensionLabelInfo || !this.dimensionLabelInfo.visible) this.clear();
  }

  updateDimensions(graphics: __esri.Graphic[]) {
    this._graphics = null;
    if (!this.dimensionLabelInfo || !this.dimensionLabelInfo.visible) return;

    let geometry;
    const layer = mapUtil.getLayer(this.layerType);
    const view = mapUtil.getView() as __esri.MapView;
    const intlUnit = this.getIntlUnit();
    if (!layer || !graphics) return;

    if (this.layerType !== "unit") return; // only units implemented

  
    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const mapAngle = view.rotation;
    let minLength = 0;

    /*
    // not in use at the moment - set the min line segment length to be about 50 pixels
    const minPixels = 50;
    const mapUnitsPerPixel = (view.extent.width / view.container.getBoundingClientRect().width);
    minLength = (mapUnitsPerPixel * minPixels);
    if (view.spatialReference.unit === "meters") {
      if (this.getLinearUnits() === "feet") {
        minLength = minLength * 3.28084
      }
    } else if (view.spatialReference.unit === "feet") {
      if (this.getLinearUnits() === "meters") {
        minLength = minLength * 0.3048;
      }
    } else {
      minLength = 0;
    }
    */

    const graphicsLayer = this.graphicsLayer;
    const gfx = [], addGfx = [], rmGfx = [];
    const gfxByAnnoKey = {}, origGfxByAnnoKey = {};
    graphicsLayer.graphics.forEach((g: any) => origGfxByAnnoKey[g.xtnAnnoKey] = g);

    graphics.forEach((graphic,graphicIndex) => {
      geometry = graphic.geometry;
      if (!geometry) return;

      const segments = [];
      if ((geometry.type === "polygon") && (geometry.rings.length > 0)) {
        geometry.rings.forEach(ring => {
          let lastIndex = ring.length - 1;
          if (ring.length === 3) {
            lastIndex = ring.length - 2;
          }
          ring.forEach((coords,coordsIndex) => {
            if (coordsIndex < lastIndex) {
              const coordsA = coords && coords.slice();
              const coordsB = ring[coordsIndex + 1] && ring[coordsIndex + 1].slice();
              if (coordsA && coordsB) {
                segments.push({coordsA,coordsB})
              }
            }
          })
        })
      } else if ((geometry.type === "polyline") && (geometry.paths.length > 0)) {
        geometry.paths.forEach(path => {
          path.forEach((coords,coordsIndex) => {
            const coordsA = coords && coords.slice();
            const coordsB = path[coordsIndex + 1] && path[coordsIndex + 1].slice();
            if (coordsA && coordsB) {
              segments.push({coordsA,coordsB})
            }
          })
        })
      }
  
      if ((segments.length > 0) && (segments.length <= this.maxDimensionSegments)) {
        segments.forEach((segment,segmentIndex) => {
          let { coordsA, coordsB} = segment;
          let polyline = new Context.instance.lib.esri.Polyline({
            paths: [[coordsA,coordsB]],
            spatialReference: view.spatialReference
          });

          if (mapAngle !== 0) {
            polyline = ge.rotate(polyline,360 - mapAngle)
            coordsA = polyline.paths[0][0];
            coordsB = polyline.paths[0][1];
          }

          const rise = coordsB[1] - coordsA[1];
          const run = coordsB[0] - coordsA[0];
          const slope = rise / run;
          const angle = (180 / Math.PI) * Math.atan2(run,rise);
          let textAngle = (angle - 90);
  
          let offset = 16;
          let dx = Math.sin((360 - textAngle) * (Math.PI / 180));
          let dy = Math.cos((360 - textAngle) * (Math.PI / 180));
          if (slope === 0 || slope === Number.POSITIVE_INFINITY || slope === Number.NEGATIVE_INFINITY) {
            if (Math.abs(dx) < 1) dx = 0;
            if (Math.abs(dy) < 1) dy = 0;
          }
          dx = dx * -offset;
          dy = dy * offset;
          
          if (angle < 0) textAngle = textAngle - 180;
  
          const length = this.getLength(view,polyline);
          if (typeof length === "number" && length > minLength) {
            const text = this.formatLength(length,intlUnit);
            const symbol = this.makeTextSymbol(text);
            symbol.angle = textAngle;
            // @ts-ignore
            symbol.xoffset = dx+"px";
            // @ts-ignore
            symbol.yoffset = dy+"px";
            const g = new Context.instance.lib.esri.Graphic({
              symbol: symbol,
              geometry: polyline.extent.center.clone()
            })
            g.xtnAnnoKey = this.layerType+"_"+graphicIndex+"_"+segmentIndex;
            gfxByAnnoKey[g.xtnAnnoKey] = g;
            gfx.push(g);
            const og = origGfxByAnnoKey[g.xtnAnnoKey];
            if (og) {
              if ((og.symbol.text !== g.symbol.text)
                 || (og.symbol.angle !== g.symbol.angle)
                 || (og.symbol.xoffset !== g.symbol.xoffset)
                 || (og.symbol.yoffset !== g.symbol.yoffset)) {
                og.symbol = g.symbol;
              }
              if ((og.geometry.x !== g.geometry.x) || (og.geometry.y !== g.geometry.y)) {
                og.geometry = g.geometry;
              }
            } else {
              addGfx.push(g);
            }
          }
        })
      }

    })

    graphicsLayer.graphics.forEach(g => {
      // @ts-ignore
      if (!gfxByAnnoKey[g.xtnAnnoKey]) rmGfx.push(g)
    })
    graphicsLayer.removeMany(rmGfx);
    graphicsLayer.addMany(addGfx);
    this._graphics = graphics;
  }

}