import React, { Component } from "react";

import Context from "../../../context/Context";
import LayerItems from "../common/LayerItems";
import * as component from "../../../components/util/component";

import { Panel } from "../../styles/Common/panel";
import {
  HeaderContainer,
  TitleRow,
  Title
} from "../../styles/Assets/assetStyles";

class LayersPanel extends Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    return (
      <Panel>
        <HeaderContainer>
          <TitleRow key="h">
            <Title>{i18n.spaceplanner.assets.layers.heading}</Title>
          </TitleRow>
        </HeaderContainer>

        <LayerItems />
      </Panel>
    );
  }
}

export default LayersPanel;