import React, { Component } from "react";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as CrudComment from "../../base/transaction/comment";
import * as commentReplyUtil from "./commentReplyUtil";
import TextareaAutosize from 'react-textarea-autosize';
import { ButtonGroupInput, CharacterCount, EmptyInput, ThemeColorButton } from "../../styles/commentsStyles";

export default class InputComment extends Component {

    total = commentReplyUtil.getMaxCommentLength();

    constructor(props){
        super(props);
        this.state={
            inputChange : '',
            emptyComment: false,
            characterCount: 0
        }
    }

    addComment =()=> {
        this.setState({
            inputChange : this.state.inputChange
        },
        function(){
            if(!this.state.inputChange){
                this.setState({
                    emptyComment : true
                })
            }else{
                const url = commentReplyUtil.getCommentUrl();
                const comment = this.state.inputChange.trim();
                const author = Context.instance.user.getUsername();
                const openPlanId = Context.instance.spaceplanner.planId;
                const obj = {
                    comment : comment,
                    author : author,
                    planid : openPlanId
                }
                CrudComment.addComment(obj, url).then((response)=> {
                    if(response) {
                        this.setState({
                            commentId : response.data.addResults[0].objectId,
                            hideCallout : false,
                            inputChange : ''
                        })
                        Topic.publish(Topic.LoadComments, {});
                    }
                    this.setState({
                        emptyComment : false
                    })
                    this.props.closeInputComment();
                })
                .catch(error =>{
                    console.error(error);
                    Topic.publishErrorUpdatingData(error.message);
                })
            }
        })
    }

    handleCancel=()=>{
        this.props.closeInputComment();
        Topic.publish(Topic.LoadComments);
    }

    handleInputChange =(evt)=> {
        let commentStr = evt.target.value;
        if(commentStr.length > this.total) return;
        commentStr = Context.sanitizeHtml(commentStr);
        this.setState({
            inputChange : commentStr,
            characterCount : commentStr.length
        },function(){
            if(!this.state.inputChange) {
                this.setState({
                    emptyComment : true
                })
            }else{
                this.setState({
                    emptyComment : false
                })
            }
        })
    }

    render(){
        const i18n = Context.instance.i18n;
        let showError;
        if(this.state.emptyComment) showError = i18n.spaceplanner.comments.emptyComment;
        let pattern = i18n.item.categoryTypePattern;
        pattern = pattern.replace("{category}", this.state.characterCount);
        pattern = pattern.replace("{type}", this.total);
        return(
            <div style={{width: "100%"}}>
                <TextareaAutosize 
                    autoFocus
                    maxRows = {10}
                    value={Context.sanitizeHtml(this.state.inputChange)}
                    style={{width: "97%", marginTop:"20px", borderColor:"#cacaca", fontFamily: "sans-serif", fontSize: "0.833rem", color:"#323232", padding:"5px"}} minRows={2} 
                    onChange={this.handleInputChange}/>
                <EmptyInput>{showError}</EmptyInput>
                <ButtonGroupInput>
                    <CharacterCount>{pattern}</CharacterCount>
                    <div>
                        <ThemeColorButton onClick={this.addComment}>{i18n.general.save}</ThemeColorButton>
                        <ThemeColorButton onClick={this.handleCancel}>{i18n.general.cancel}</ThemeColorButton>
                    </div>
                </ButtonGroupInput>
            </div>
        )
    }
}