/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { FormControlLabel } from "calcite-react/Form";
import List, {
  ListItem,
  ListItemTitle
} from 'calcite-react/List';
import Button from 'calcite-react/Button';
import TextField from "calcite-react/TextField";
import Tooltip from 'calcite-react/Tooltip';

import InformationIcon from "calcite-ui-icons-react/InformationIcon";
import UsersIcon from "calcite-ui-icons-react/UsersIcon";
import XIcon from 'calcite-ui-icons-react/XIcon';
import Context from '../../../context/Context';
import {ModalController} from "../../../common/components/Modal";

const CSS = {
  list: 'i-groups-list',
  chip: 'i-div-chip',
  chips: 'i-div-chips',
  info: 'i-icon-info'
}

const GroupSearch = ({groups, horizontal, onShareGroup, onUnshareGroup, assignedGroups, disabled}) => {
  const [data, setData] = useState({
    suggestions: [],
    suggestionsActive: false,
    chips: [],
    shareIds: [],
    unShareIds: []
  });
  const [value, setValue] = useState('');
  const [userGroups, setUserGroups] = useState((groups && groups.slice()) || []);
  const {suggestions, suggestionsActive, chips, shareIds, unShareIds} = data;
  const ctx = Context.getInstance();
  const i18n = ctx.i18n; 
  const portal = ctx.getPortal();
  const groupsUrl = portal ? `${portal.url}/home/groups.html?sortOrder=asc&sortField=title` : null;
  
  // Check if the plan is already shared with groups on component mount
  useEffect(() => {
    if (assignedGroups && assignedGroups.length > 0) {
      setData({...data, chips: assignedGroups});
    }
    fetchGroups();
  }, []);

  useEffect(() => {
    fetchGroups()
  }, [value]);

  useEffect(() => {
    if (!groups || groups.length === 0) {
      setValue('')
      setData({...data, 
        suggestionsActive: false,
        suggestions: [],
        chips: [],
        shareIds: [],
        unShareIds: [],
      });
    } else {
      setData({...data, chips: assignedGroups || []});
    }
  }, [groups]);

  // PlanGroupSharing: Newly created group should appear in the dropdown if search term is re-entered (without the need to refresh the app) #5555
  const fetchGroups = async () => {
    const portal = Context.getInstance().getPortal();
    const esriRequest = Context.instance.lib.esri.esriRequest;
    const selfUrl = `${portal.restUrl}/community/self`;
    const options = {query: {f: 'json'}, responseType: 'json'};
    const result = await esriRequest(selfUrl, options);
    if (result && result.data && result.data.groups) {
      const list = []
      result.data.groups.forEach(group => {
        if (!group.isViewOnly || group.userMembership.memberType === 'owner' || group.userMembership.memberType === 'admin') {
          list.push(group);
        }
      });
      list.sort((A, B) => {
        const a = A.title;
        const b = B.title;
        if (!a && !b) return 0;
        else if (!b) return 1;
        else if (!a)return -1;
        return a.localeCompare(b);
      });
      setUserGroups(list);
    }
  }
  
  // Filter groups for suggestions list
  const handleChange = (e) => {
    const groups = userGroups.slice();
    const query = e.target.value.toLowerCase();
    setValue(e.target.value);
    if (query.length > 1) {
      const filterSuggestions = groups.filter(x => x.title.toLowerCase().indexOf(query) > -1);
      setData({...data, suggestions: filterSuggestions, suggestionsActive: true});
    } else {
      setData({...data, suggestionsActive: false});
    }
  }
  
  const handleClick = (e) => {
    e.persist();
    const groups = userGroups.slice();
    const selectedGroup = groups.find(x => x.id === e.target.id);
    const index = chips.indexOf(selectedGroup);
    if (index !== -1) {
      setValue('');
      setData({...data, suggestions: [], suggestionsActive: false});
      return;
    }
    if (!selectedGroup.membershipAccess) {
      // any org has access
      let title = i18n.configurator.appItem.shareWith.groups.cannotSharePlan;
      title = title.replace("{title}", selectedGroup.title);
      ModalController.alert({
        title: title,
        message: i18n.configurator.appItem.shareWith.groups.cannotSharePlanMsg
      });
      setValue('');
      setData({...data, suggestions: [], suggestionsActive: false});
      return;
    }
    chips.push(selectedGroup);
    shareIds.push(e.target.id);
    const index2 = unShareIds.indexOf(e.target.id);
    if (index2 !== -1) unShareIds.splice(index2, 1);
    setValue('');
    setData({...data, suggestions: [], suggestionsActive: false});
    onShareGroup(e, shareIds, unShareIds);
  }

  const isSelected = (suggestion) => {
    return chips.some(c => suggestion.title === c.title)
  }
  
  // Render suggestions list
  const Suggestions = () => {
    return (
      <List className={CSS.list}>
        {suggestions.map((suggestion, index) => {
          if (!isSelected(suggestion)) {
            return (
              <ListItem leftNode={<UsersIcon size={16} />} key={index}>
                <ListItemTitle id={suggestion.id} onClick={handleClick}>{suggestion.title}</ListItemTitle>
              </ListItem>
            )
          }
        })}
      </List>
    )
  }
  
  // Delete selected groups
  const deleteChip = (e) => {
    e.persist();
    if (!e.currentTarget.id) return;
    const id = e.currentTarget.id;
    setData({...data, chips: chips.filter(x => x.id !== id)});
    const index = shareIds.indexOf(id);
    if (index !== -1) shareIds.splice(index, 1);
    unShareIds.push(id)
    if (onUnshareGroup) onUnshareGroup(e, unShareIds, shareIds);
  }
  
  // Chips are compact elements that represent an input, attribute, or in this case group.
  const Chip = () => {
    return (
      <>
        {chips.map(chip => (
          <div key={chip.id} className={CSS.chip}>
            <UsersIcon size={16} />
            {chip.title}
            {!disabled && <Button extraSmall transparent style={{cursor: 'default'}}>
              <XIcon size={24} id={chip.id} onClick={deleteChip} style={{cursor: 'pointer'}}/>
            </Button>}
          </div>
          ))
        }
      </>
    )
  }
  const Text = () => {    
    return (
      <TextField
        fullWidth={true}
        value={value}
        onChange={handleChange}
        onRequestClear={() => {
          setValue('')
          setData({...data, suggestionsActive: false});
        }}
        search
        placeholder={i18n.configurator.appItem.shareWith.groups.search}
        disabled={disabled}
      />
    )
  }
  const cls = suggestionsActive || (assignedGroups && assignedGroups.length)
    ? "manage-groups manage-groups-active"
    : "manage-groups";
  const link = (
  <a href={groupsUrl} target='_blank' rel='noopener noreferrer' className={cls}>
    {<UsersIcon size={16} />} {i18n.configurator.appItem.shareWith.groups.link}
  </a>)
  const isVersioned = Context.instance.spaceplanner.planner.project.isVersioned || false;
  return (
    <>
      <FormControlLabel htmlFor="title" className={horizontal ? "i-new-plan i--form-label" : ""}>
        <span className="i-share-with-group-label">{i18n.configurator.appItem.shareWith.groups.title}
        <Tooltip title={isVersioned ? i18n.configurator.appItem.shareWith.groups.tooltipVersioned : i18n.configurator.appItem.shareWith.groups.tooltipHosted }
          placement="top-start">
          <InformationIcon className={CSS.info} size={16} />
        </Tooltip>
        </span>
        {portal && link}
      </FormControlLabel>
      <div className="i-share-with-group-text">
        {disabled
          ? <Tooltip title={i18n.configurator.appItem.shareWith.groups.cannotShareTooltip}
              targetWrapperStyle={{width: "100%"}} placement="top-start">
              {Text()}
            </Tooltip>
          : Text()
        }
        {suggestionsActive && <Suggestions />}
        <div className={CSS.chips}>
          {chips.length > 0 && <Chip />}
        </div>
      </div>
    </>
  )
}

export default GroupSearch;