import React from "react";

import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import ModalController from "../../common/Modal/ModalController";
import Topic from "../../../context/Topic";
import * as appLaunchUtil from "../../../aiim/util/appLaunchUtil";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as shareUtil from "../InfoPanel/shareUtil";
import * as val from "../../../util/val";

const CSS = {
  main: "i-cfg-applaunch i-form-group i-section",
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  note: "i-note",
  issues: "i-issues",
  issue: "i-issue",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  secondaryAction: "i-button i-button-clear",
  link: "i-link"
};

function actionMatches(action,source,subType) {
  return appLaunchUtil.actionMatches(action,source,subType);
}

function getSources() {
  return appLaunchUtil.getSources();
}

function getLayersCount() {
  const views = Context.instance.views
  const map = views && views.mapView && views.mapView.map
  const layers = map && map.allLayers && map.allLayers.items
  let count = 0
  if (!layers) return count
  const searchLayers = (layer) => {
    if (!layer.allSublayers) {
      if (layer.type !== "graphics" && !aiimUtil.isDetailsLayer(layer)) {
        count++
      }
    } else {
      layer.allSublayers.forEach(sublayer => {
        searchLayers(sublayer)
      })
    }
  }
  layers.forEach(layer => {
    searchLayers(layer)
  })
  return count
}

function getCIMSourcesCount() {
  const sources = getSources()
  let count = 0
  sources.forEach(src => {
    const id = src.cimCategory && src.cimCategory.id
    if (id) count++
  })
  return count
}

function makeValidActions(actions) {
  const valid = [];
  actions = appLaunchUtil.cloneActions(actions)
  actions.forEach(action => {
    let isEmpty = false;
    if (!val.isNonEmptyStr(action.label,true) && !val.isNonEmptyStr(action.url,true) && !val.isNonEmptyStr(action.webUrl,true)) {
      if (action.layers.length === 0 && action.categories.length === 0) {
        isEmpty = true;
      } else if (action.layers.length === 1 && action.layers[0] === "*" &&
        action.categories.length === 1 && action.categories[0] === "*") {
        isEmpty = true;
      }
    }
    if (!isEmpty) {
      delete action.uiKey;
      valid.push(action);
    }
  });
  return valid;
}

function sourceMatches(action,source) {
  let matches = actionMatches(action,source);
  if (!matches && source.subTypes && source.subTypes.length) {
    matches = source.subTypes.some(subType => {
      return (action.categories.indexOf(subType.name) !== -1);
    });
  }
  return matches;
}

function actionMatchesCategory(action) {
  const actionCategories = action.categories
  if (!actionCategories || actionCategories.length === 0) return []
  const matches = []
  const sources = getSources()
  if (actionCategories.length === 1 && actionCategories[0] === "*") {
    sources.forEach(src => {
      const id = src.cimCategory && src.cimCategory.id
      if (id) matches.push(src)
    })
  } else {
    sources.forEach(src => {
      if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
        actionCategories.forEach(category => {
          if (category === src.name) matches.push(src)
        })
      } else {
        let id = src.cimCategory && src.cimCategory.id
        id = id && id.toLowerCase()
        if (id) {
          actionCategories.forEach(category => {
            let categoryId = category.id
            categoryId = categoryId && categoryId.toLowerCase()
            if (id.includes(categoryId)) matches.push(src)
          })
        } else {
          actionCategories.forEach(category => {
            if (category === src.name) matches.push(src)
          })
        }
      }
    })
  }
  return matches
}

function actionMatchesLayer(action) {
  const views = Context.instance.views
  const map = views && views.mapView && views.mapView.map
  const layers = map && map.allLayers && map.allLayers.items
  const actionLayers = action.layers
  if (!actionLayers || !layers) return []
  const matches = []
  if (actionLayers[0] === "*") {
    layers.forEach(layer => {
      if (layer.allSublayers && layer.allSublayers.items && layer.allSublayers.items.length > 0) {
        const subLayers = layer.allSublayers.items
        subLayers.forEach(subLayer => {
          if (!aiimUtil.isDetailsLayer(subLayer)) matches.push(subLayer)
        })
      } else {
        if (layer.type === "feature" && !aiimUtil.isDetailsLayer(layer)) matches.push(layer)
      }
    })
  } else {
    if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
      layers.forEach(layer => {
        const name = layer.title
        actionLayers.forEach(actionLayer => {
          if (layer.allSublayers && layer.allSublayers.items && layer.allSublayers.items.length > 0) {
            const subLayers = layer.allSublayers.items
            subLayers.forEach(subLayer => {
              if (subLayer.title === actionLayer) {
                if (!aiimUtil.isDetailsLayer(subLayer)) matches.push(subLayer)
              }
            })
          } else {
            if (actionLayer === name && layer.type === "feature" && !aiimUtil.isDetailsLayer(layer)) {
              matches.push(layer)
            }
          }
        })
      })
    } else {
      layers.forEach(layer => {
        const id = layer.id
        if (id) {
          actionLayers.forEach(actionLayer => {
            const actionLayerId = actionLayer.webMapLayerId
            const name = layer.title
            if (actionLayerId && id === actionLayerId) {
              if (layer.allSublayers && layer.allSublayers.items && layer.allSublayers.items.length > 0) {
                const mapServiceLayerId = actionLayer.mapServiceLayerId
                if (mapServiceLayerId) {
                  const subLayers = layer.allSublayers.items
                  subLayers.forEach(subLayer => {
                    if (subLayer.id === mapServiceLayerId) {
                      if (!aiimUtil.isDetailsLayer(subLayer)) matches.push(subLayer)
                    }
                  })
                }
              } else {
                if (layer.type === "feature" && !aiimUtil.isDetailsLayer(layer)) matches.push(layer)
              }
            } else if (!actionLayerId) {
              if (layer.allSublayers && layer.allSublayers.items && layer.allSublayers.items.length > 0) {
                const subLayers = layer.allSublayers.items
                subLayers.forEach(subLayer => {
                  if (subLayer.title === actionLayer) {
                    if (!aiimUtil.isDetailsLayer(subLayer)) matches.push(subLayer)
                  }
                })
              } else {
                if (actionLayer === name && layer.type === "feature" && !aiimUtil.isDetailsLayer(layer)) {
                  matches.push(layer)
                }
              }
            }
          })
        }
      })
    }
  }
  return matches
}

function subTypeMatches(action,source,subType) {
  return actionMatches(action,source,subType);
}

export default class AppLaunch extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      actions: this.cloneActionsFromConfig()
    });
  }

  addClicked = (evt) => {
    const actions = this.state.actions;
    const action = appLaunchUtil.newAction();
    this.setActionKey(action);
    actions.push(action);
    component.refresh(this);
  }

  cloneActionsFromConfig() {
    let actions = Context.instance.config.appLaunch.actions;
    actions = appLaunchUtil.cloneActions(actions)
    actions.forEach(action => {
      this.setActionKey(action);
    });
    return actions;
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.setState(state => {
          return {
            actions: this.cloneActionsFromConfig()
          };
        });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  publishChange = () => {
    const actions = makeValidActions(this.state.actions);
    Context.instance.config.appLaunch.actions = actions;
    //console.log(JSON.stringify(actions,null,2));
    component.refresh(this);
    Topic.publish(Topic.AppLaunchURLsChanged,{});
  }

  remove = (action) => {
    const actions = this.state.actions;
    const idx = actions.indexOf(action);
    if (idx !== -1) {
      // If this is a webmap action, make sure it doesn't get reloaded in
      if (action.actionId) {
        const match = Context.instance.config.appLaunch.removedWebmapActions.map(function(a) {
          return a.actionId
        }).indexOf(action.actionId)
        if (match === -1) {
          const removedWebmapActions = JSON.parse(JSON.stringify(Context.instance.config.appLaunch.removedWebmapActions))
          removedWebmapActions.push(action)
          Context.instance.config.appLaunch.removedWebmapActions = removedWebmapActions
        }
      }
      actions.splice(idx,1);
      this.publishChange();
    }
  }

  reorder = (action,dir) => {
    const actions = this.state.actions;
    const idx = actions.indexOf(action);
    if (dir === "moveUp" && idx > 0) {
      actions.splice(idx,1);
      actions.splice(idx - 1,0,action);
      this.publishChange();
    }
    if (dir === "moveDown" && idx < (actions.length - 1)) {
      actions.splice(idx,1);
      actions.splice(idx + 1,0,action);
      this.publishChange();
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const helpUrl = Context.instance.config.appLaunchHelpUrl;
    const actions = this.state.actions;

    let items = [];
    actions.forEach((action,i) => {
      let key = action.uiKey;
      let isFirst = (i === 0);
      let isLast = (i === (actions.length - 1));
      const categories = actionMatchesCategory(action)
      const layers = actionMatchesLayer(action)
      items.push(
        <li key={key}>
          <LaunchItem key={key} itemKey={key}
            action={action} isFirst={isFirst} isLast={isLast}
            onRemove={this.remove} onReorder={this.reorder}
            publishChange={this.publishChange}
            categories={categories} layers={layers}
            />
        </li>
      );
    });

    let add = (
      <button type="button" className={"i-button i-margin-all-small"}
        onClick={this.addClicked}
        >{i18n.configurator.appLaunch.add}</button>
    );

    return (
      <div className={CSS.main}>
        <div key="hdr" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.appLaunch.caption}</span>
        </div>
        <div key="prompt" className={"i--prompt"}
          >{i18n.configurator.appLaunch.prompt}</div>
        <div key="help" className={"i--help i-text-right"}>
          <a target="_blank" rel="noopener noreferrer" className={"i-link"}
            href={helpUrl}>{i18n.configurator.appLaunch.helpLink}</a>
        </div>
        <ul className="i-cfg-applaunch-items">
          {items}
        </ul>
        <div key="footer" className={"i--footer-actions i-text-right"}>
          {add}
        </div>
      </div>
    );
  }

  setActionKey(action) {
    action.uiKey = component.nextTmpId("i-cfg-applaunch-");
  }

}

class LaunchItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  getCategoryAndLayerNames() {
    const categories = this.props.categories
    const layers = this.props.layers
    const i18nAppLaunch = Context.instance.i18n.configurator.appLaunch;
    let categoriesString = null, layersString = null
    if (getCIMSourcesCount() === 0 && getLayersCount() === 0) {
      return null
    } else {
      const categoryNames = []
      const layerNames = []
      let categoriesMatched = 0
      let layersMatched = 0
      if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
        const cimCategories = Context.instance.aiim.getCIMCategories()
        if (cimCategories && cimCategories.categoryData && cimCategories.categoryData.categories) {
          const ctgs = cimCategories.categoryData.categories
          ctgs.forEach(c => {
            if (categories.indexOf(c.name) !== -1) {
              categoryNames.push(c.name)
              if (c.categories && c.categories.length > 0) categoriesMatched += c.categories.length
              else categoriesMatched++
            }
          })
        }
      }
      categories.forEach(category => {
        const name = category.name
        if (name) {
          categoryNames.push(name)
          categoriesMatched++
        }
      })
      layers.forEach(layer => {
        const name = layer.title
        if (name) {
          layerNames.push(name)
          layersMatched++
        }
      })
      
      if (categoryNames.length > 0) {
        if (categoriesMatched === getCIMSourcesCount()) {
          categoriesString = i18nAppLaunch.item.allCategoriesSelected
        } else {
          categoriesString = categoryNames.join(", ")
        }
      }
      if (layerNames.length > 0) {
        if (layersMatched === getLayersCount()) {
          layersString = i18nAppLaunch.item.allLayersSelected
        } else {
          layersString = layerNames.join(", ")
        }
      }
      return { categories: categoriesString, layers: layersString }
    }
  }

  getLayerNames(sources) {
    const i18nAppLaunch = Context.instance.i18n.configurator.appLaunch;
    if (sources.length === 0) {
      return i18nAppLaunch.item.noApplicableLayers;
    } else {
      const layerNames = [];
      const action = this.props.action;
      let nSourcesMatched = 0;
      sources.forEach(source => {
        if (actionMatches(action,source)) {
          layerNames.push(source.name);
          nSourcesMatched++;
        } else {
          if (source.subTypes && source.subTypes.length > 0) {
            source.subTypes.forEach(subType => {
              if (actionMatches(action,source,subType)) {
                layerNames.push(subType.name);
              }
            });
          }
        }
      });
      if (layerNames.length > 0) {
        if (nSourcesMatched === sources.length) {
          return i18nAppLaunch.item.allLayersSelected;
        } else {
          return layerNames.join(", ");
        }
      } else {
        return i18nAppLaunch.item.noLayersSelected;
      }
    }
  }

  moveDownClicked = (evt) => {
    if (this.props.onReorder) this.props.onReorder(this.props.action,"moveDown");
  }

  moveUpClicked = (evt) => {
    if (this.props.onReorder) this.props.onReorder(this.props.action,"moveUp");
  }

  onChangeLabel = (evt) => {
    let v = evt.target.value;
    if (typeof v === "string") v = v.trim();
    else v = null;
    this.props.action.label = v;
    this.publishChange();
  }

  onChangeUrl = (evt) => {
    let v = evt.target.value;
    if (typeof v === "string") v = v.trim();
    else v = null;
    if (typeof this.props.action.webUrl !== undefined) {
      this.props.action.webUrl = v
    } else {
      this.props.action.url = v;
    }
    this.publishChange();
  }

  onLayersSelected = () => {
    component.refresh(this);
    this.publishChange();
  }

  publishChange = () => {
    if (this.props.publishChange) this.props.publishChange();
  }

  removeClicked = (evt) => {
    const i18n = Context.instance.i18n;
    ModalController.showOkCancel({
      content: i18n.configurator.removePrompt,
      title: i18n.configurator.appLaunch.item.remove,
      className: "i-configurator-modal",
      onOk: () => this.props.onRemove(this.props.action)
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const i18nItem = i18n.configurator.appLaunch.item;
    const i18nAppLaunch = Context.instance.i18n.configurator.appLaunch;
    const action = this.props.action;
    const key = this.props.itemKey;
    const btnCls = "i-button-clear-no-border i-font-brand-primary";

    let label = (
      <label key={key+"-label-label"} id={key+"-label-label"}
        htmlFor={key+"-label-input"}>
        <span className={CSS.formGroupName}
          >{i18nItem.label}</span>
        <input id={key+"-label-input"} type="text"
          defaultValue={action.label} onChange={this.onChangeLabel}/>
      </label>
    );

    let example = i18nItem.urlExamplePattern;
    let exampleUrl = "https://survey123.arcgis.com/share/myId?portalUrl=https://example.com/portal";
    example = example.replace("{url}",exampleUrl);
    let exampleNode = (
      <div key="urlExample" className={CSS.note}>{example}</div>
    );

    const actionUrl = action.webUrl || action.url
    let url = (
      <label key={key+"-url-label"} id={key+"-url-label"}
        htmlFor={key+"-url-input"}>
        <span className={CSS.formGroupName}
          >{i18nItem.url}</span>
        <input id={key+"-url-input"} type="text"
          defaultValue={actionUrl} onChange={this.onChangeUrl}/>
        {exampleNode}
      </label>
    );

    const sources = getSources();
    // let layerNames = this.getLayerNames(sources);
    const names = this.getCategoryAndLayerNames()
    let finalString = null
    if (!names) {
      finalString = i18nAppLaunch.item.noApplicableCategoriesOrLayers
    } else {
      let layerNames = names.layers && i18nAppLaunch.item.layersList.replace("{list}", names.layers)
      let categoryNames = names.categories && i18nAppLaunch.item.categoriesList.replace("{list}", names.categories)
      if (!layerNames || !categoryNames) {
        if (layerNames) {
          categoryNames = i18nAppLaunch.item.categoriesList.replace("{list}", i18nAppLaunch.item.noCategoriesSelected)
        } else if (categoryNames) {
          layerNames = i18nAppLaunch.item.layersList.replace("{list}", i18nAppLaunch.item.noLayersSelected)
        } else {
          categoryNames = i18nAppLaunch.item.categoriesList.replace("{list}", i18nAppLaunch.item.noCategoriesSelected)
          layerNames = i18nAppLaunch.item.layersList.replace("{list}", i18nAppLaunch.item.noLayersSelected)
        }
      } 
      finalString = (
        <>
          <p>{categoryNames}</p>
          <p>{layerNames}</p>
        </>
      )
    }
    
    let layers = (
      <div key={key+"-layers"}>
        <div key="hdr" className={"i--layer-header"}>
          <span className={CSS.formGroupName}
            >{i18nItem.categoriesAndLayers}</span>
        </div>
        <div key="names" className={"i--layer-names"}>{finalString}</div>
        <div key="sel" className={"i--actions i-text-right"}>
          <button type="button" className={btnCls}
            disabled={sources.length === 0}
            onClick={this.selectLayersClicked}
            >{i18nAppLaunch.selectLayers.caption}</button>
        </div>
      </div>
    );

    let moveUp = (
      <button type="button" className={btnCls} onClick={this.moveUpClicked}
        disabled={this.props.isFirst} title={i18nItem.moveUp}
        >{Icons.arrowUp()}</button>
    );

    let moveDown = (
      <button type="button" className={btnCls} onClick={this.moveDownClicked}
        disabled={this.props.isLast} title={i18nItem.moveDown}
        >{Icons.arrowDown()}</button>
    );

    let remove = (
      <button type="button" className={btnCls} onClick={this.removeClicked}
        title={i18nItem.remove}>{Icons.X()}</button>
    );

    return (
      <div className={"i-cfg-applaunch-item"}>
        <div key="actions" className={"i--actions i-text-right"}>
          {moveUp}{moveDown}{remove}
        </div>
        {label}
        {url}
        {layers}
      </div>
    );
  }

  selectLayersClicked = (evt) => {
    const props = {
      action: this.props.action,
      categories: this.props.categories,
      layers: this.props.layers,
      onLayersSelected: this.onLayersSelected
    };
    SelectLayers.showModal(props);
  }

}

class SelectLayers extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  onChangeCheckLayers = (item) => {
    const list = this.props.layerList;
    const checked = !!(item && item.checked);
    if (item && item.isAll) {
      list.forEach(item2 => {
        item2.checked = checked;
      });
    }

    // check/un-check all sub-types when parent is checked/un-checked
    if (item && item.subTypeItems && item.subTypeItems.length > 0) {
      item.subTypeItems.forEach(subTypeItem => {
        subTypeItem.checked = checked;
      });
    }

    // if (item && item.parentItem) {
    //   if (!checked) {
    //     // un-check the parent if a sub-type is unchecked
    //     item.parentItem.checked = false;
    //   } else {
    //     let subTypeItems = item.parentItem.subTypeItems;
    //     if (subTypeItems && subTypeItems.length > 0) {
    //       let all = subTypeItems.every(subTypeItem => {
    //         return subTypeItem.checked;
    //       });
    //       if (all) {
    //         // check the parent when sub-types are checked
    //         item.parentItem.checked = true;
    //       }
    //     }
    //   }
    // }

    component.refresh(this);
  }

  onChangeCheckCategories = (item) => {
    const list = this.props.categoriesList;
    const checked = !!(item && item.checked);
    if (item && item.isAll) {
      const checkAll = (item) => {
        if (!item.categories) {
          item.checked = checked
        } else {
          item.checked = checked
          item.categories.forEach(i => {
            checkAll(i)
          })
        }
      }
      list.forEach(category => {
        checkAll(category)
      })
    }

    // check/un-check all sub-types when parent is checked/un-checked
    if (item && item.categories && item.categories.length > 0) {
      item.categories.forEach(category => {
        category.checked = checked;
      });
    }

    component.refresh(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const i18nAppLaunch = i18n.configurator.appLaunch;
    const layerList = this.props.layerList;
    const categoriesList = this.props.categoriesList;

    let numLayersSelected = 0;
    const layerCheckboxes = [];
    layerList.forEach(item => {
      if (item.checked) numLayersSelected++;
      layerCheckboxes.push(
        <li key={item.uiKey}>
          <CheckBox item={item} onChange={this.onChangeCheckLayers}/>
        </li>
      );
    });

    let numCategoriesSelected = 0
    const categoryCheckboxes = [];

    const pushAllCheckBoxes = (category) => {
      if (!category.categories) {
        if (category.checked) numCategoriesSelected++
        categoryCheckboxes.push(
          <li key={category.uiKey}>
            <CheckBox item={category} onChange={this.onChangeCheckCategories}/>
          </li>
        );
      } else {
        if (category.checked) numCategoriesSelected++
        categoryCheckboxes.push(
          <li key={category.uiKey}>
            <CheckBox item={category} onChange={this.onChangeCheckCategories}/>
          </li>
        );
        category.categories.forEach(c => {
          pushAllCheckBoxes(c)
        })
      }
    }
    if (categoriesList && categoriesList.length > 0) {
      categoriesList.forEach(c => {
        pushAllCheckBoxes(c)
      })
    }

    const allLayersItem = {
      name: i18nAppLaunch.selectLayers.allLayers,
      checked: (numLayersSelected > 0 && (numLayersSelected === layerCheckboxes.length)),
      isAll: true
    }
    const allLayers = (
      <CheckBox item={allLayersItem} onChange={this.onChangeCheckLayers}/>
    );

    const allCategoriesItem = {
      name: i18nAppLaunch.selectLayers.allCategories,
      checked: (numCategoriesSelected > 0 && (numCategoriesSelected === categoryCheckboxes.length)),
      isAll: true
    }
    const allCategories = (
      <CheckBox item={allCategoriesItem} onChange={this.onChangeCheckCategories}/>
    );

    return (
      <div>
        <div className={"i--layers"}>
          {allCategories}
          <ul>
            {categoryCheckboxes}
          </ul>
        </div>
        <div className={"i--layers"}>
          {allLayers}
          <ul>
            {layerCheckboxes}
          </ul>
        </div>
      </div>
    );
  }

  static makeCategoriesList(actionCategories, action) {
    let list = []
    const cimCategories = Context.instance.aiim.getCIMCategories()
    if (cimCategories && cimCategories.categoryData && cimCategories.categoryData.categories) {
      const categories = cimCategories.categoryData.categories
      categories.forEach(category => {
        const sublist = []
        const searchCategories = (category, level) => {
          const arr = []
          if (!category.categories) {
            const checked = category.id && actionCategories.map(function(c) {
              return c.cimCategory && c.cimCategory.id
            }).indexOf(category.id)
            return {
              name: category.name,
              checked: checked !== -1,
              uiKey: category.name,
              id: category.id,
              level: level,
              parentItem: null
            }
          } else {
            const categories = category.categories
            categories.forEach(c => {
              arr.unshift(searchCategories(c, level + 1))
            })
          }
          let checked
          if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
            checked = actionCategories.indexOf(category.name) !== -1
            if (checked) {
              arr.forEach(i => {
                i.checked = true
              })
            } else {
              arr.forEach(i => {
                if (i.checked) checked = true
              })
            }
          } else {
            checked = category.id && actionCategories.map(function(c) {
              return c.cimCategory && c.cimCategory.id
            }).indexOf(category.id) !== -1
            arr.forEach(i => {
              if (i.checked) checked = true
            })
          }
          const item = {
            name: category.name,
            checked: checked,
            uiKey: category.name,
            level: level,
            categories: arr,
            parentItem: null
          }
          sublist.unshift(item)
        }
        const res = searchCategories(category, 0)
        if (res) list.unshift(res)
        list = [].concat(list, sublist)
      })
      if (list && list.length > 0) {
        list.forEach(category => {
          this.searchParents(category, null, category.checked)
        })
      }
    }
    return list
  }

  static makeLayersList(actionLayers) {
    const map = Context.instance.views.mapView.map
    const layers = map.allLayers && map.allLayers.items

    const list = []
    const searchLayers = (layer) => {
      if (!layer.allSublayers) {
        const validLayerType = (
          (layer.type === "feature" || layer.xtnFeatureLayer && layer.xtnFeatureLayer.type === "feature") &&
          !aiimUtil.isDetailsLayer(layer)
        );
        if (validLayerType) {
          let checked = false
          if (layer.layer && layer.layer.id) {
            actionLayers.forEach(actionLayer => {
              if (actionLayer.layer && actionLayer.layer.id) {
                const layerId = layer.layer.id
                const actionLayerId = actionLayer.layer.id
                if (layerId === actionLayerId) {
                  if (layer.id === actionLayer.id) {
                    checked = true
                  }
                }
              }
            })
          } else {
            const layerId = layer.id
            actionLayers.forEach(actionLayer => {
              if (layerId && actionLayer.id && layerId === actionLayer.id) {
                checked = true
              }
            })
          }
          const uiKey = layer && layer.layer && layer.layer.id ? layer.title + "/" + layer.id : layer.id
          const mapServiceLayerId = layer && layer.layer && layer.layer.id ? layer.id : null
          const webMapLayerId = layer && layer.layer && layer.layer.id ? layer.layer.id : layer.id
          const item = {
            name: layer.title,
            checked: checked,
            uiKey: uiKey,
            mapServiceLayerId: mapServiceLayerId,
            webMapLayerId:  webMapLayerId
          }
          list.unshift(item)
        }
      } else {
        layer.allSublayers.forEach(sublayer => {
          searchLayers(sublayer)
        })
      }
    }
    layers.forEach(layer => {
      searchLayers(layer)
    })
    return list
  }

  static searchParents(category, parent) {
    category.parentItem = parent
    if (!category.categories) return
    else {
      const categories = category.categories
      categories.forEach(c => {
        this.searchParents(c, category)
      })
    }
  }

  static makeList(action) {
    const list = [];
    const sources = getSources();
    sources.forEach(source => {
      let itemChecked = sourceMatches(action,source);
      let item = {
        name: source.name,
        uiKey: source.name,
        fromCategoriesTable: !!source.fromCategoriesTable,
        checked: itemChecked,
        subTypeItems: []
      }
      list.push(item);
      if (source.subTypes && source.subTypes.length > 0) {
        source.subTypes.forEach(subType => {
          let subTypeChecked = subTypeMatches(action,source,subType);
          let subTypeItem = {
            name: subType.name,
            value: subType.name,
            uiKey: source.key+"/"+subType.name,
            fromCategoriesTable: !!source.fromCategoriesTable,
            checked: subTypeChecked,
            parentItem: item,
            isSubType: true
          }
          item.subTypeItems.push(subTypeItem);
          list.push(subTypeItem);;
        });
      }
    });
    return list;
  }

  static showModal(props) {
    const i18n = Context.instance.i18n;
    const i18nAppLaunch = i18n.configurator.appLaunch;
    const action = props.action;
    console.log(action)
    // const layerList = SelectLayers.makeList(props.action);
    const layerList = SelectLayers.makeLayersList(props.layers)
    const categoriesList = SelectLayers.makeCategoriesList(props.categories, action)
    const modalController = new ModalController();

    const saveCategories = () => {
      action.categories.length = 0;
      let allChecked = true
      if(!categoriesList || categoriesList.length === 0) allChecked = false;
      const searchAll = (category) => {
        if (!category.categories) {
          if (!category.checked) allChecked = false
        } else if (!category.checked) {
          allChecked = false
        } else {
          category.categories.forEach(c => {
            searchAll(c)
          })
        }
      }
      const addCategories = (category) => {
        if (!category.categories) {
          if (category.checked) {
            console.log(category)
            if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
              action.categories.push(category.name)
            } else {
              action.categories.push({
                id: category.id,
                name: category.name
              })
            }
          }
        } else {
          category.categories.forEach(c => {
            addCategories(c)
          })
        }
      }
      categoriesList.forEach(item => {
        searchAll(item)
      })
      if (allChecked) {
        action.categories.push("*")
      } else {
        categoriesList.forEach(item => {
          addCategories(item)
        })
      }
    }

    const saveLayers = () => {
      action.layers.length = 0
      let allChecked = true
      layerList.forEach(item => {
        if (!item.checked) allChecked = false
      })
      if (allChecked) {
        action.layers.push("*")
      } else {
        layerList.forEach(layer => {
          if (layer.checked) {
            if (Context.instance.configuration.srcInfo.appLaunchFromIndoorsConfig) {
              action.layers.push(layer.name)
            } else {
              action.layers.push({
                name: layer.name,
                mapServiceLayerId: layer.mapServiceLayerId,
                webMapLayerId: layer.webMapLayerId
              })
            }
          }
        })
      }
    }

    const okClicked = () => {
      saveCategories()
      saveLayers()
      if (props.onLayersSelected) props.onLayersSelected(action);
      modalController.close();
    };
    const cancelClicked = () => {
      modalController.close();
    }
    const content = (
      <div key="cfg-configurator-cancel-popup" className={CSS.formGroup}>
        <div className={"i-separated"}>
          <SelectLayers action={action} layerList={layerList} categoriesList={categoriesList}/>
        </div>
        <div key="actions" className={"i-actions i-text-right"}>
          <button key="cancel" type="button"
            className={"i-button i-button-clear i-margin-all-small"}
            onClick={cancelClicked}>{i18n.general.cancel}</button>
          <button key="ok" type="button"
            className={"i-button i-margin-all-small"}
            onClick={okClicked}>{i18n.general.ok}</button>
        </div>
      </div>
    );
    modalController.show(content,
      i18nAppLaunch.selectLayers.caption,"i-configurator-modal i-cfg-applaunch");
  }

}

class CheckBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      componentId: component.nextId()
    });
  }

  onChange = (evt) => {
    const checked = evt.target.checked;
    this.props.item.checked = checked;
    component.refresh(this);
    if (this.props.onChange) this.props.onChange(this.props.item);
  }

  render() {
    const id = this.state.componentId;
    const item = this.props.item;
    const isSubType = !!item.isSubType || (item.level && item.level > 0);
    let cls = "i-checkbox-row";
    if (item.isAll) cls += " i--all";
    if (isSubType) {
      cls += " i--indent";
      if (!item.parentItem.checked) return null;
    }
    return (
      <div className={cls}>
        <input id={id+"-input"} type="checkbox" checked={item.checked}
          onChange={this.onChange}/>
        <label htmlFor={id+"-input"}>{item.name}</label>
      </div>
    );
  }

}
