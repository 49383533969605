import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../redux/Rdx";
import { Dispatch } from "redux";

import Context from "../../../../context/Context";
import OfficePlan from "../../../base/OfficePlan";
import Savebar from "../support/Savebar";
import SplitAttributes from "./SplitAttributes";
import SplitVM from "./SplitVM";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";
import WallThickness from "../support/WallThickness";
import * as component from "../../../../components/util/component";

import { getWallWidthUnits, setWallWidthUnits, IEditorState, HelpTip, getActiveHelpTip, setHelpTip } from "../redux";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-panel";
import {
  CalciteButton,
  CalciteCheckbox,
  CalciteLabel,
  CalciteNotice
} from "@esri/calcite-components-react";

interface ISplitPanelProps {
  setTip: (tip: HelpTip) => void,
  tip: HelpTip
}
interface ISplitPanelState {
  activeTool?: string,
  bufferWall?: boolean,
  clipWall?: boolean,
  insertWall?: boolean,
  swapUnits?: boolean,
  splitUnderlyingUnit?: boolean,
}
class SplitPanel extends React.Component<ISplitPanelProps, ISplitPanelState> {

  splitAttributes;
  viewModel: SplitVM;

  _mounted = false;

  constructor(props: ISplitPanelProps) {
    super(props);
    this.state = component.newState({
      activeTool: "select",
      bufferWall: false,
      clipWall: true,
      insertWall: false,
      swapUnits: false,
      splitUnderlyingUnit: true
    });
    this.viewModel = new SplitVM();
    this.viewModel.bufferWall = this.state.bufferWall;
    this.viewModel.clipWall = this.state.clipWall;
    this.viewModel.insertWall = this.state.insertWall;
    this.viewModel.swapUnits = this.state.swapUnits;
    this.viewModel.onChange = () => {
      if (!this._mounted) return;
      component.refresh(this);
    }
    this.viewModel.onToolActivated = (tool) => {
      if (!this._mounted) return;
      this.setState({
        activeTool: tool
      });
    }
  }

  componentDidMount() {
    this._mounted = true;
    if (this.isLoaded()) {
      this.viewModel.activateTool("select");
    }

    component.own(this,[
      Topic.subscribe(Topic.PlanModified,params => {
        try {
          if (this._mounted && params && (params.wasUndoRedo || params.wasReconciled)) {
            this.reset();
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    ]);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.viewModel.setActiveFeatureVisibility(true);
    this.viewModel.clear();
    this.viewModel.destroy();;
    component.componentWillUnmount(this);
  }

  activateTool = tool => {
    this.setState({
      activeTool: tool
    });
    this.viewModel.activateTool(tool);
  };

  isLoaded = () => {
    const planner = Context.instance.spaceplanner.planner;
    return planner && planner.hasValidPlan(); 
  };

  render() {
    const i18n = Context.instance.i18n;
    const { tip } = this.props;
    const { activeTool, bufferWall, insertWall }= this.state;
    const canSave = this.viewModel.canSave();
    let prompt = "";
    if (canSave) {
      prompt = i18n.editor.splitUnit.attributesInstruction;
    } else if (activeTool === "select") {
      prompt = i18n.editor.splitUnit.selectInstruction;
    } else if (activeTool === "drawWall") {
      prompt = i18n.editor.splitUnit.drawWallInstruction;
    } else if (activeTool === "adjustWall") {
      prompt = i18n.editor.splitUnit.adjustWallInstruction;
    } else {
      prompt = i18n.editor.splitUnit.selectInstruction;
    }

    return (
      <div className="i-editor-sidebar-container i-editor-split-container">
        <div className="i-editor-sidebar-content">
          <CalciteNotice open={!(tip && tip.visible) ? true : undefined} scale="s" closable icon="lightbulb"
            onCalciteNoticeClose={() => this.props.setTip({ visible: true, tooltip: prompt })}>
            <div slot="message">{prompt}</div>
          </CalciteNotice>
          <div className="panel-group">
            {this.renderWallOptions()}
            {insertWall && bufferWall && this.renderWallThickness()}
          </div>
          {canSave &&
            <div className="panel-group i-flex-columns" style={{overflow: "hidden"}}>
              <div className="i-flex-between-centered">
                <div className="i-editor-attributes-title">{i18n.editor.walls.attributes}</div>
                {this.renderSwapUnit()} 
              </div>
              {this.renderSplitAttributes()}
            </div>
          }
        </div>
        <div className="i-editor-sidebar-footer">
          {this.renderSavebar()}
        </div>
      </div>
    )
  }

  renderSavebar() {
    return (
      <Savebar
        parentPanelKey="splitUnit"
        resetDisabled={false}
        saveDisabled={!this.viewModel.canSave()}
        editIsActive={this.viewModel.canSave()}
        onReset={this.reset} 
        onSave={this.saveClicked}
      />
    )
  }

  renderSplitAttributes() {
    const key = this.viewModel.getSplitKey();
    return (
      <SplitAttributes 
        key={key} 
        splitKey={key} 
        viewModel={this.viewModel}
        onMount={splitAttributes => this.splitAttributes = splitAttributes}
        onUnmount={() => this.splitAttributes = null}
      />
    )
  }

  renderSwapUnit() {
    const i18n = Context.instance.i18n;
    const info = this.viewModel.activeSplit;
    if (info && info.modifiedUnit && info.newUnit) {
      return (
        <CalciteButton 
          appearance="transparent"
          kind="brand"
          iconStart="arrow-right-left"
          onClick={this.swapUnitsClicked}
        >
          {i18n.editor.sidebar.swapUnits}
        </CalciteButton>
      )
    }
    return null;
  }

  renderWallOptions() {
    const i18n = Context.instance.i18n;
    const { bufferWall, clipWall, insertWall } = this.state;
    return (
      <div>
        <div className="i-separated-form-section">
          <CalciteLabel layout="inline" className="i-cursor-pointer">
            <CalciteCheckbox checked={insertWall || undefined} 
              onCalciteCheckboxChange={e => {
                const checked = e.target.checked;
                this.setState({
                  insertWall: checked
                })
                this.viewModel.setInsertWall(checked);
              }}
            />
            {i18n.editor.splitUnit.insertWall}
          </CalciteLabel>
        </div>
        {insertWall && 
          <>
            <div className="i-separated-form-section">
              <CalciteLabel layout="inline" className="i-cursor-pointer">
                <CalciteCheckbox checked={clipWall || undefined} 
                  onCalciteCheckboxChange={e => {
                    const checked = e.target.checked;
                    this.setState({
                      clipWall: checked
                    })
                    this.viewModel.setClipWall(checked);
                  }}
                />
                {i18n.editor.splitUnit.clipWall}
              </CalciteLabel>
            </div>
            <div>
              <CalciteLabel layout="inline" className="i-cursor-pointer">
                <CalciteCheckbox checked={bufferWall || undefined} 
                  onCalciteCheckboxChange={e => {
                    const checked = e.target.checked;
                    this.setState({
                      bufferWall: checked
                    })
                    this.viewModel.setBufferWall(checked);
                  }}
                />
                {i18n.editor.splitUnit.bufferWall}
              </CalciteLabel>
            </div>
          </>
        }
      </div>
    )
  }

  renderWallThickness() {
    return (
      <WallThickness 
        min={0.1}
        onChange={widthInMapUnits => {
          this.viewModel.setWallWidthInMapUnits(widthInMapUnits);
        }}
      />
    )
  }

  reset = async () => {
    this.setState({
      swapUnits: false
    })
    this.viewModel.swapUnits = false;
    this.viewModel.clear();
    this.activateTool("select");
  }

  save = async () => {
    const guard = new TransactionGuard({force:true});
    try {
      await this.viewModel.save();
      this.reset();
      guard.close();
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated
      });
    } catch(ex) {
      console.error(ex);
      guard.close();
      Topic.publishErrorUpdatingData(ex.message);
    }
  }

  saveClicked = () => {
    if (this.splitAttributes) {
      const ok = this.splitAttributes.validate({apply: true});
      if (ok) this.save();
    }
  }

  swapUnitsClicked =()=> {
    const swapUnits = !this.state.swapUnits;
    this.setState({
      swapUnits: swapUnits
    })
    this.viewModel.setSwapUnits(swapUnits);
  }

}

const mapStateToProps = (state: IRootState) => ({
  tip: getActiveHelpTip(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTip: (tip: HelpTip) => dispatch(setHelpTip("splitUnit", tip))
});

export default connect(mapStateToProps, mapDispatchToProps)(SplitPanel);
