import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import * as highlightUtil from "../../base/highlightUtil";

import { Container, Popup } from "../../styles/App/map";

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
    // Tell React to never update this component, that's up to us
    return false;
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onDragOver = evt => {
    const dnd = Context.instance.spaceplanner.dnd;
    dnd.whenDragOverMapContainer(evt);
  };

  onDrop = evt => {
    const dnd = Context.instance.spaceplanner.dnd;
    dnd.whenDropOntoMapContainer(evt);
  };

  render() {
    return (
      <Container style={{overflow: "hidden"}}
        id="sp-view-container"
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        <Popup id="item-popup" className="i-item-popup"></Popup>
      </Container>
    );
  }
}
