import React from "react";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import Select from "calcite-react/Select";
import { MenuItem } from "calcite-react/Menu";
import { Topic } from "../../../util/tsUtil";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

const CSS = {
    section: "i-form-group i-section",
    formGroup: "i-form-group",
    formGroupHeader: "i-form-group-header",
    formGroupName: "i-form-group-name",
    required: "i--required",
    toggleLabel: "i-toggle-label",
    optionsContainer: "i--restrict-merge-groups"
};

export default class UnitName extends React.Component {

    constructor(props) {
        super(props);
        this.state = component.newState({
            unitField: this.props.cfg.getConfigurable("spaceplanner").unitName || null
        });
    }

    componentDidMount() {
      this.initField();
      component.own(this,[
        Topic.subscribe(Topic.ViewsReloaded,params => {
          this.initField();
        })
      ]);
    }

    componentWillUnmount() {
      component.componentWillUnmount(this);
    }
    
    initField() {
      if (!this.state.unitField) {
        const fields = this.getUnitFields();
        if (fields && fields.length > 0) {
          const unitField = aiimUtil.findFieldName(fields,FieldNames.NAME);
          if (unitField) {
            this.onUnitNameChange(unitField);
          }
        }
      }
    }

    onUnitNameChange=(value)=> {
        this.setState({
            unitField: value
        })
        const cfg = this.props.cfg;
        cfg.getConfigurable("spaceplanner").unitName = value;
        Topic.publish(Topic.RefreshUnitName, {});
    }

    getUnitFields() {
      let project = Context.instance.spaceplanner && Context.instance.spaceplanner.planner &&
                    Context.instance.spaceplanner.planner.project;
      let unitsLayer = project && project.hostedInfo && project.hostedInfo.unitsLayer;
      if (!unitsLayer) {
        const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
        unitsLayer = units && units.layer2D;
      }
      const fields = unitsLayer && unitsLayer.fields;
      if(!fields) return [];
      const unitsFields = [];
      fields.forEach(field => {
        if(field.type === "string") unitsFields.push(field);
      })
      return unitsFields;
    }

    render() {
        const context = Context.getInstance();
        const i18n = context.i18n;
        const unitFields = this.getUnitFields();

        return (
            <div className={CSS.section}>
                <div key="header" className={CSS.formGroupHeader}>
                    <span className={CSS.formGroupName}>
                        {i18n.spaceplanner.configurator.unitName.caption}
                    </span>
                </div>
                <div key="unitName">{i18n.spaceplanner.configurator.unitName.description}</div>
                <div className={CSS.config}>
                    <Select
                        className="i-unit-name"
                        placement="bottom-start"
                        placeholder={i18n.spaceplanner.configurator.unitName.placeholder}
                        selectedValue={this.state.unitField} 
                        onChange={this.onUnitNameChange}
                        appendToBody
                    >
                        {
                            unitFields.map(field => {
                            const alias = field.alias;
                            const name = field.name;
                            return (
                                <MenuItem className="i-menu-item" key={name} value={name}>
                                    {alias}
                                </MenuItem>
                            )
                            })
                        }
                    </Select>
                </div>
            </div>
        );
    }
}
