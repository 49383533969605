import React, { CSSProperties } from "react";
import ReactToggle from "react-toggle";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Rdx, { setValue } from "../../../../../redux/Rdx";
import Context from "../../../../../context/Context";
import moment from "moment";
import Icons from "../../../../util/Icons";
import Button from "../../../../../common/components/Button";
import DayToggle from "./DayToggle";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import { TimePicker } from "antd";
import ModalController from "../../../../../common/components/Modal/ModalController";
import Popover from "calcite-react/Popover";
import Tooltip from "calcite-react/Tooltip";
import Schedule from "../../../MeetingRooms/Schedule";
import SDPWrapper from "../../../../common/Calendar/SDPWrapper";
import Topic from "../../../../../context/Topic";
import {getHotelBookingSystemType, makeInitialTimes} from "./WorkspaceReservation/workspaceReservationUtil";
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";
import * as component from "../../../../util/component";
import * as dateUtil from '../../../Events/dateUtil';
import SearchableMultiSelect from "../../../../common/SearchableMultiSelect/SearchableMultiSelect";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from "../../../../../spaceplanner/base/ReviewerManagement/reviewersUtil";
import { escSqlQuote } from "../../../../../aiim/util/selectionUtil";
import { stringFormatter } from "../../../../../util/formatUtil";
import Loader from "calcite-react/Loader/Loader";
import { IFeature } from "@esri/arcgis-rest-types";
import { IBookingStatus, IBookingTask, IDateOptions, IOriginalReservation, IReserveForInfo } from "./WorkspaceReservation/BookingSystem";
import BookingRecurrence, { displayRecurrence, getRecurringDates, IRecurrenceOptions, IRecurrenceSeries } from "./BookingRecurrence";
import { isSameDay } from "react-dates";
import { recurringSeriesMatch } from "./MyBookings";
import { BookingType } from "../../../../../util/calendarUtil";
import { IIndoorsEvent } from "./WorkspaceReservation/Office365";

const CSS = {
  popoutInner: "i-popout-inner i-booking-details-popup",
  popoutOuter: "i-popout-outer",
  popoutInnerWithRecurrence: "i-booking-details-with-recurrence",
  popoutInnerWithRecurrenceEnabled: "i-booking-details-with-recurrence-enabled",
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  filterForm: "i-filter-form",
  toggle: "i-toggle",
  toggleReserveOthers: "i-toggle-reserve-others",
  toggleAllDay: "i-toggle-all-day",
  toggleLabel: "i-toggle-label",
  durationFilter: "i-duration-filter",
  durationFilterForm: "i-duration-filter-form",
  durationGroup: "i-duration-group",
  durationDate: "i-date",
  durationTime: "i-time",
  iconContainer: "i-sidebar-icon-container i-filter-container",
  icon: "i-more-menu-icon",
  spaceFilters: "i-space-filter",
  button: "i-book-button",
  dateString: "i-date-string",
  confirmButton: "i-book-confirm",
  bookingFields: "i-booking-fields",
  bookingDetails: "i-booking-details",
  bookingDetailsTitle: "i-booking-details-title",
  othersErrors: "i-others-errors"
};
export interface ICheckAvailabilityOptions extends IDateOptions {
  others?: IFeature[],
  reserveForInfo?: IReserveForInfo,
  recurrence?: IRecurrenceOptions,
  recurrenceType: BookingType
}
interface IBookingDetailsProps {
  allDay?: boolean,
  area: IFeature,
  areaId: string,
  areaName: string,
  checkAvailability: (options: ICheckAvailabilityOptions) => Promise<IBookingTask & IBookingStatus>,
  closePopup: () => void,
  endDate?: Date,
  endTime?: moment.Moment,
  facilityName: string,
  isOngoingBooking: boolean,
  levelName: string,
  operation?: "updateBookingTime",
  originalReservation: IOriginalReservation,
  rdxAllDayChecked: boolean,
  rdxEndDateSelected: Date,
  rdxEndTimeSelected: moment.Moment,
  rdxStartDateSelected: Date,
  rdxStartTimeSelected: moment.Moment,
  rdxForOthers: boolean,
  rdxRecurrence: IRecurrenceOptions,
  recurrence?: IRecurrenceOptions,
  recurrenceType?: BookingType,
  reserveForInfo: IReserveForInfo,
  reserveForSelf: boolean,
  series?: IRecurrenceSeries | IIndoorsEvent[],
  setRdxValue: (key: string, value) => void,
  spaceType: string,
  startDate?: Date,
  startTime?: moment.Moment,
  unitFeature: __esri.Graphic,
  unitName: string,
  when?: dateUtil.IDuration
}
interface IBookingDetailsState {
  allDay: boolean,
  endCalendarOpen: boolean,
  endDate: Date,
  endTime: moment.Moment,
  errors: string[],
  isWorking: boolean,
  others: IFeature[],
  recurrence: IRecurrenceOptions,
  reserveForUsername: string,
  reserveOthersChecked: boolean,
  reserveOthersToggleFocused: boolean,
  startCalendarOpen: boolean,
  startDate: Date,
  startTime: moment.Moment,
  toggleFocused: boolean
}
class BookingDetails extends React.Component<IBookingDetailsProps, IBookingDetailsState> {
  format
  use12Hours
  queryConflicts = true;
  isOngoingBooking = this.props.isOngoingBooking;
  closeRef: React.RefObject<HTMLButtonElement>;
  constructor(props) {
    super(props);
    this.closeRef = React.createRef<HTMLButtonElement>();
    const locale = Context.instance.lib.dojo.kernel.locale
    if (locale === "en" || locale === "en-us") {
      this.use12Hours = true
      this.format = "h:mm A"
    } else {
      this.use12Hours = false
      this.format = "H:mm"
    }

    const originalReservation = this.props.originalReservation; 

    let startDate = this.props.rdxStartDateSelected;
    let endDate = this.props.rdxEndDateSelected;
    let startTime = this.props.rdxStartTimeSelected;
    let endTime = this.props.rdxEndTimeSelected;
    let allDay = this.props.rdxAllDayChecked;
    let recurrence = this.props.rdxRecurrence;
   
    if (this.props.operation === "updateBookingTime") {
      startDate = this.props.startDate;
      endDate = this.props.endDate;
      startTime = this.props.startTime;
      endTime = this.props.endTime;
      allDay = this.props.allDay;
      recurrence = this.props.recurrence;
      if (this.isOngoingBooking && this.props.recurrenceType !== "series") allDay = false;
    }

    if (this.props.when) {
      allDay = this.props.when.duration === "allDay";
      startDate = new Date(this.props.when.start.date);
      endDate = new Date(this.props.when.end.date);
      recurrence = this.props.when.recurrence;
      if (!allDay) {
        startTime = moment(startDate);
        endTime = moment(endDate);
      } else {
        startTime = this.getDefaultStartTime();
        endTime = this.getDefaultEndTime();
      }
    }

    if (!startTime && !endTime) {
      startTime = this.getDefaultStartTime();
      endTime = this.getDefaultEndTime();
      allDay = true;
    }

    if (!startTime && !endTime) {
      startTime = this.getDefaultStartTime();
      endTime = this.getDefaultEndTime();
    }

    this.state = component.newState({
      startDate : startDate,
      endDate : endDate,
      startTime : startTime,
      endTime : endTime,
      startCalendarOpen: false,
      endCalendarOpen: false,
      allDay: allDay,
      toggleFocused: false,
      reserveOthersChecked: false,
      reserveOthersToggleFocused: false,
      reserveForUsername : null,
      others: [],
      errors: [],
      isWorking: false,
      recurrence
    });
  }

  returnClicked(event) {
    if (event.keyCode === 13) {
      Topic.publish(Topic.ToggleReturnClicked, {})
    }
  }

  init=()=> {
    const startDate = this.props.rdxStartDateSelected;
    const endDate = this.props.rdxEndDateSelected;
    let startTime = this.props.rdxStartTimeSelected;
    let endTime = this.props.rdxEndTimeSelected;

    if (!startTime && !endTime) {
      startTime = this.getDefaultStartTime();
      endTime = this.getDefaultEndTime();
    }

    this.setState({
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime
    })
  }

  getDefaultStartTime =()=> {
    // var m = moment();
    // const remainder = 30 - (m.minute() % 30);
    // var roundUp = moment(m).add(remainder, "minutes");
    // return (moment(roundUp));
    const initialTimes = makeInitialTimes();
    return initialTimes.startTime;
  }

  getDefaultEndTime =()=> {
    // var startTime = this.getDefaultStartTime();
    // return (moment(startTime).add(1,'hours'));
    const initialTimes = makeInitialTimes();
    return initialTimes.endTime;
  }

  getValidEndTime =()=> {
    var startTime = this.state.startTime;
    return (moment(startTime).add(1, 'hours'));
  }

  componentDidMount() {
    if (this.closeRef && this.closeRef.current && this.closeRef.current.focus) {
      this.closeRef.current.focus()
    }
    document.addEventListener("keyup", this.returnClicked, false)
    component.own(this, [
      Topic.subscribe(Topic.ToggleReturnClicked, params => {
        const { allDay, toggleFocused, reserveOthersChecked, reserveOthersToggleFocused } = this.state;
        if (toggleFocused) {
          this.setState({ allDay: !allDay });
        } else if (reserveOthersToggleFocused) {
          this.setState({ reserveOthersChecked: !reserveOthersChecked });
        }
      })
    ])
  }

  componentDidUpdate(prevProps, prevState) {
    const clockIcons = document.getElementsByClassName("ant-picker-suffix")
    for (let i = 0; i < clockIcons.length; i++) {
      clockIcons[i].setAttribute("aria-hidden", "true")
    }
    // const timeInputs = document.getElementsByClassName("ant-picker-input")
    // for (let i = 0; i < timeInputs.length; i++) {
    //   timeInputs[i].setAttribute("aria-hidden", "true")
    // }
    // const focusedTime = document.getElementsByClassName("ant-picker")
    // for (let i = 0; i < focusedTime.length; i++) {
    //   focusedTime[i].setAttribute("tabIndex", "0")
    //   focusedTime[i].setAttribute("role", "button")
    // }
    const cells = document.getElementsByClassName("ant-picker-time-panel-cell")
    for (let i = 0; i < cells.length; i++) {
      cells[i].setAttribute("tabIndex", "0")
    }
    const panel = document.getElementsByClassName("ant-picker-panel")
    for (let i = 0; i < panel.length; i++) {
      panel[i].setAttribute("tabIndex", "0")
    }

    // Check if we should update
    const type = getHotelBookingSystemType();
    const { startDate, startTime, endDate, endTime, allDay, others } = prevState;
    const { reserveOthersChecked } = this.state;
    if (!reserveOthersChecked || type === "office365" || this.state.others.length === 0) {
      return;
    }

    let samePerson = false;
    if (others.length === 1 && this.state.others.length === 1) {
      const prevEmail = aiimUtil.getAttributeValue(others[0].attributes, FieldNames.PEOPLE_EMAIL);
      const email = aiimUtil.getAttributeValue(this.state.others[0].attributes, FieldNames.PEOPLE_EMAIL);
      samePerson = prevEmail === email;
      if (!samePerson) {
        this._checkUsername();
      }
    } else if (others.length === 0 && this.state.others.length === 1) {
      this._checkUsername();
    }

    // const cStartDate = this.makeStartDateTime();
    // const cEndDate = this.makeEndDateTime();
    // const pStartDate = makeStartDateTime(startDate, startTime, allDay);
    // const pEndDate = makeEndDateTime(endDate, endTime, allDay);
    //
    // const sameDates = moment(cStartDate).isSame(moment(pStartDate)) && moment(cEndDate).isSame(moment(pEndDate));
    //
    // if (sameDates && samePerson) {
    //   return;
    // }
    //
    // this._checkConflictingReservations();
  }

  async _checkUsername() {
    const i18n = Context.getInstance().i18n;
    const { others } = this.state;
    if (!others || others.length !== 1) return;

    const occupant = others[0];
    const name = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
    const email = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_EMAIL);
    const errors = [];
    try {
      const portalResult = await getPortalUserByEmail({ email });
      const username = getUsernameFromPortalUsers(portalResult, occupant);
      if (!username) throw new Error("No result in your organization for user")
      this.setState({ errors, reserveForUsername: username });
    } catch (e) {
      console.error(e);
      if (e.message.includes("No result in your organization for user")) {
        const template = i18n.more.bookWorkspace.bookingRequestFailedOthersAlt;
        const error = stringFormatter(template, { person: name });
        this.setState({ errors: [error], reserveForUsername: null});
      } else {
        const error = i18n.messages.errorAccessingData;
        this.setState({ errors: [error], reserveForUsername: null});
      }
    }
  }

  // async _checkConflictingReservations() {
  //   const i18n = Context.getInstance().i18n;
  //   const { others } = this.state;
  //   const { unitName } = this.props;
  //   if (!others || others.length !== 1) {
  //     return;
  //   }
  //
  //   const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
  //   const occupant = others[0];
  //   const name = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
  //   const email = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_EMAIL);
  //
  //   try {
  //     this.queryConflicts = false;
  //     const portalResult = await getPortalUserByEmail({ email });
  //     const username = getUsernameFromPortalUsers(portalResult, occupant);
  //     if (!username) {
  //       return;
  //     }
  //
  //     const where = `${reservations.reservedForUsernameField} = '${escSqlQuote(username)}'`;
  //     const result = await reservations.query(where);
  //     if (!result || !result.features) {
  //       return;
  //     }
  //
  //     const canReserve = this._canReserve(result.features, this.makeStartDateTime(), this.makeEndDateTime());
  //     const errors = [];
  //     if (!canReserve) {
  //       const template = i18n.more.bookWorkspace.bookingOtherConflict;
  //       errors.push(stringFormatter(template, { person: name }));
  //     }
  //     this.setState({ errors });
  //   } catch (e) {
  //     console.error(e);
  //
  //     if (e.message.includes("No result in your organization for user")) {
  //       const template = i18n.more.bookWorkspace.bookingRequestFailedOthersAlt;
  //       const error = stringFormatter(template, { person: name });
  //       this.setState({ errors: [error] });
  //     } else {
  //       // Undetermined error, it can be handled when booking
  //       this.setState({ errors: [] });
  //     }
  //   }
  // }

  // _canReserve(reservations, checkIn, checkOut) {
  //   const PENDING_APPROVAL = 0;
  //   const APPROVED = 1;
  //   const CHECKED_IN = 4;
  //
  //   let canReserve = true;
  //   reservations.forEach((reservation) => {
  //     const attrs = reservation.attributes;
  //     const reservationState = aiimUtil.getAttributeValue(attrs, FieldNames.STATE);
  //     const reservationStart = aiimUtil.getAttributeValue(attrs, FieldNames.START_TIME);
  //     const reservationEnd = aiimUtil.getAttributeValue(attrs, FieldNames.END_TIME);
  //
  //     const mReservationStart = moment(reservationStart);
  //     const mReservationEnd = moment(reservationEnd);
  //     const mCheckIn = moment(checkIn);
  //     const mCheckOut = moment(checkOut);
  //
  //     const activeReservation =
  //       reservationState === PENDING_APPROVAL ||
  //       reservationState === APPROVED ||
  //       reservationState === CHECKED_IN;
  //       const notAvailable =
  //       (mReservationStart < mCheckIn && mReservationEnd > mCheckIn) ||
  //       (mReservationStart >= mCheckIn && mCheckOut > mReservationStart);
  //
  //     if (activeReservation && notAvailable) {
  //       canReserve = false;
  //     }
  //   });
  //
  //   return canReserve;
  // }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.returnClicked, false)
    component.componentWillUnmount(this)
  }

  confirmClicked=async()=>{
    const { allDay, others } = this.state;
    const i18n = Context.instance.i18n
    let checkInDate = this.makeStartDateTime();
    let checkOutDate = this.makeEndDateTime(true);
    if (checkOutDate.getTime() <= checkInDate.getTime()) {
      ModalController.showMessage(i18n.more.bookWorkspace.checkOutBeforeError)
      return;
    }
    if(this.props.recurrenceType !== "series" && checkOutDate.getTime() < this.getEpochCurrentTime()) {
      ModalController.showMessage(i18n.more.bookWorkspace.pastBookingError)
      return;
    }

    const origWhen = this.getOriginalWhen();
    const origRecurrence = this.getOrigRecurrence();
    const currRecurrence = this.state.recurrence;
    if (origWhen || origRecurrence || currRecurrence) {
      const currStart = dateUtil.joinDayAndTime(this.state.startDate, this.state.startTime);
      const currEnd = dateUtil.joinDayAndTime(this.state.endDate, this.state.endTime);
      const whenDisabled = origWhen && currStart === origWhen.start && currEnd === origWhen.end && this.state.allDay === origWhen.allDay;      
      const recurrenceDisabled = origRecurrence !== currRecurrence ? recurringSeriesMatch(currRecurrence, origRecurrence) : true;
      if (whenDisabled && recurrenceDisabled) {
        ModalController.showMessage(i18n.more.bookWorkspace.noChangesMsg);
        return;
      }
    }
    
    this.setState({isWorking: true});
    const options: ICheckAvailabilityOptions = {
      checkInDate,
      checkOutDate,
      allDay,
      recurrence: this.state.recurrence,
      recurrenceType: this.props.recurrenceType
    };
    if (others && others.length > 0) {
      options.others = others;
      options.reserveForInfo = {
        username: this.state.reserveForUsername,
        occupantFeature: others[0]
      }
    } else if (this.props.reserveForInfo) {
      options.others = [this.props.reserveForInfo.occupantFeature];
      options.reserveForInfo = this.props.reserveForInfo;
    }
    this.setState({isWorking: true});
    const response = await this.props.checkAvailability(options);
    this.setState({ isWorking: false });
    if (response?.ok) {
      this.props.closePopup();
    }
  }

  getEpochCurrentTime =()=> {
    const currentDateTime = new Date();
    var unix_seconds = (new Date(currentDateTime)).getTime();
    return unix_seconds;
  }

  getEpochEndTime =()=> {
    let endDateTime = this.makeEndDateTime();
    var unix_seconds = (new Date(endDateTime)).getTime();
    return unix_seconds;
  }

  getEpochStartTime =()=> {
    let startDateTime = this.makeStartDateTime();
    var unix_seconds = (new Date(startDateTime)).getTime();
    return unix_seconds;
  }

  makeEndDateTime = (forSubmit?: boolean) => {
    const lib = Context.instance.lib
    const allDay = this.state.allDay
    let endDate = lib.dojo.locale.format(this.state.endDate, {
      selector: "date",
      datePattern: "yyyy/MM/dd"
    })
    const endTime = this.state.endTime;
    let hour: number | string = endTime.hour()
    if (hour < 10) hour = "0" + hour
    let minute: number | string = endTime.minute()
    if (minute < 10) minute = "0" + minute
    // let second = endTime.seconds()
    // if (second < 10) second = "0" + second
    let time = hour + ":" + minute + ":00";
    if (allDay) {
      if (forSubmit && (getHotelBookingSystemType() !== "office365")) {
        endDate = lib.dojo.locale.format(this.state.endDate, {
          selector: "date",
          datePattern: "yyyy/MM/dd"
        })
        time = "23:59:00"
      } else {
        let nextDay = new Date(this.state.endDate)
        nextDay.setDate(this.state.endDate.getDate() + 1)
        endDate = lib.dojo.locale.format(nextDay, {
          selector: "date",
          datePattern: "yyyy/MM/dd"
        })
        time = "00:00:00";
      }
    }

    const checkOutDate1 = endDate + ' '+ time;
    //const checkOutDate = new Date(checkOutDate1).toISOString();
    const checkOutDate = new Date(checkOutDate1);
    return checkOutDate;
  }

  makeStartDateTime= () => {
    const lib = Context.instance.lib
    const startTime = this.state.startTime;
    const allDay = this.state.allDay
    let startDate = lib.dojo.locale.format(this.state.startDate, {
      selector: "date",
      datePattern: "yyyy/MM/dd"
    })
    let hour: number | string = startTime.hour()
    if (hour < 10) hour = "0" + hour
    let minute: number | string = startTime.minute()
    if (minute < 10) minute = "0" + minute
    // let second = startTime.seconds()
    // if (second < 10) second = "0" + second
    let time = hour + ":" + minute + ":00";
    // const checkInDate = startDate + "T" + time;
    if (allDay) {
      time = "00:00:00"
    }
    const checkInDate1 = startDate + ' '+ time;
    //const checkInDate = new Date(checkInDate1).toISOString();
    const checkInDate = new Date(checkInDate1);
    return checkInDate;
  }

  allDayChanged = (checked: boolean) => {
    if (!checked) {
      const when = this.getOriginalWhen();
      const times = when
        ? {
          startTime: moment(when.start),
          startDate: new Date(when.start),
          endTime: moment(when.end), 
          endDate: new Date(when.end) 
        }
        : null;
      !when && this.makeValidations();
      this.setState({ ...times, allDay: checked }, ()=>{
        //this.makeValidations();
      })
    } else {
      this.makeValidations();
      this.setState({ allDay: checked })
    }
  }

  makeValidations = (validateRecurrence: boolean = false) => {
    if (this.isOngoingBooking) return;
    if(this.getEpochEndTime() <= this.getEpochStartTime() || this.getEpochStartTime() < this.getEpochCurrentTime()){
      if(this.getEpochStartTime() < this.getEpochCurrentTime()){
        this.setState({
          startTime : this.getDefaultStartTime(),
          endTime : this.getDefaultEndTime()
        })
      }
      if(this.getEpochEndTime() <= this.getEpochStartTime()){
        this.setState({
          endTime: this.getValidEndTime()
        })
      }
      if(this.state.startDate > this.state.endDate){
        this.setState({
          endDate : this.state.startDate
        }, this.validateRecurrence);
      }
    }
    // only need to check recurrence when start/end date changes (i.e. not time)
    if (validateRecurrence) {
      this.validateRecurrence();
    }
  }

  equalDates = (date1, date2) => {
    const matchingDays = date1.getDate() === date2.getDate()
    const matchingMonths = date1.getMonth() === date2.getMonth()
    const matchingYears = date1.getFullYear() === date2.getFullYear()
    return matchingDays && matchingMonths && matchingYears
  }

  onEndTimeChange = (time) => {
    const checkIn = this.state.startDate
    const checkOut = this.state.endDate
    if (time && time !== undefined) {
      if (this.equalDates(checkIn, checkOut)) {
        if (time.isSame(this.state.startTime, "hour")) {
          const startMin = this.state.startTime.minute()
          const endMin = time.minute()
          const diff = endMin - startMin
          if (diff >= 30) {
            this.setState({
              endTime: time
            },()=>{
              this.makeValidations();
            })
          }
        } else if (time.isAfter(this.state.startTime, "hour")) {
          const startMin = this.state.startTime.minute();
          const endMin = time.minute() + 60;
          const diff = endMin - startMin;
          if (diff >= 30) {
            this.setState({
              endTime: time
            },()=>{
              this.makeValidations();
            })
          }
        }
      } else {
        this.setState({
          endTime: time
        })
      }
    }
  }

  onStartTimeChange = (time) => {
    const checkIn = this.state.startDate
    const checkOut = this.state.endDate
    if (time && time !== undefined) {
      if (this.equalDates(checkIn, checkOut)) {
        let endTime = this.state.endTime
        if (time.isSame(endTime)) {
          endTime = moment(time).add(1, "hour")
        } else {
          if (time.isAfter(endTime)) {
            endTime = moment(time).add(1, "hour")
          } else {
            if (time.isSame(endTime, "hour")) {
              const endMin = endTime.minute()
              const startMin = time.minute()
              const diff = endMin - startMin
              if (diff <= 30) {
                endTime = moment(endTime).add(30 - diff, "minute")
              }
            } else {
              const endMin = endTime.minute() + 60
              const startMin = time.minute()
              const diff = Math.abs(endMin - startMin)
              if (diff <= 30) {
                endTime = moment(endTime).add(30 - diff, "minute")
              }
            }
          }
        }
        this.setState({
          startTime: time,
          endTime: endTime
        },()=>{
          this.makeValidations();
        })
      } else {
        this.setState({
          startTime: time
        })
      }
    }
  }

  onEndDateChanged = (date) => {
    this.setState({
      endDate : date,
      endCalendarOpen: false
    }, ()=>{
      this.makeValidations(true);
    });
  }

  onStartDateChanged = (date) => {
    this.setState({
      startDate : date,
      startCalendarOpen: false
    },()=>{
      this.makeValidations(true);
    });
  }

  onToggleFocus = () => {
    this.setState({
      toggleFocused: true
    })
  }

  onToggleBlur = () => {
    this.setState({
      toggleFocused: false
    })
  }

  renderOccupantsList() {
    if (getHotelBookingSystemType() === "office365") return null;
    if (this.props.reserveForSelf) return null;

    if (this.props.reserveForInfo) {
      let knownas = this.props.reserveForInfo.fullName;
      let email = ""
      if (!knownas) {
        const f = this.props.reserveForInfo.occupantFeature;
        knownas = aiimUtil.getAttributeValue(f.attributes, FieldNames.PEOPLE_FULLNAME);
        email = "(" + aiimUtil.getAttributeValue(f.attributes, FieldNames.PEOPLE_EMAIL) +")";
      }
      // @todo Needs Translation
      // const forLabel = "For";
      var cssStyle = {
        paddingTop: "0.5rem",
        paddingBottom: "1rem",
        display: "inline-block",
        fontWeight: "400"
      }
      var iconStyle: CSSProperties = {
        verticalAlign: "sub"
      }
      const isRTL = Context.getInstance().uiMode.isRtl;
      if (isRTL) iconStyle.marginLeft = "0.2rem";
      else iconStyle.marginRight = "0.2rem";

      return (
        <div className={"i-booking-fields"} style={cssStyle}>
          <span><UserIcon size={16} style={iconStyle} /></span>
          <span style={{wordBreak: "break-word"}}> {knownas} {email} </span>
        </div>
      )
    }

    const { area, areaId } = this.props;
    let isRestricted = true;
    if (area) {
      const v = aiimUtil.getAttributeValue(area.attributes, FieldNames.AREAS_RESTRICTED);
      const unrestricted = (v === 0);
      if (unrestricted) isRestricted= false;
    }

    // Check to make sure this user is a reservation manager
    const managerInfo = Context.instance.aiim.managerInfo;
    const isManager = Context.instance.aiim.isReservationManager();
    if (!isManager) {
      return null;
    }
    const isAreaManager = managerInfo.reservationManagerIds.includes(areaId);
    if (!isAreaManager) {
      return null;
    }

    const i18n = Context.getInstance().i18n;
    const people = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.people;
    const fields = people && people.layer2D && people.layer2D.fields;
    if (!fields) {
      return null;
    }

    const { reserveOthersChecked, errors, others } = this.state;
    const id = component.nextTmpId("reserveOthers-");

    const areaIdField = aiimUtil.findFieldName(fields, FieldNames.AREA_ID);
    const knownasField = aiimUtil.findFieldName(fields, FieldNames.PEOPLE_FULLNAME);
    const emailField = aiimUtil.findFieldName(fields, FieldNames.PEOPLE_EMAIL);
    if (!areaIdField || !knownasField || !emailField) {
      return null;
    }

    // Remove duplicate people and don't include logged in user
    // const postQueryProcessing = (features) => {
    //   const listFeatures = [];
    //   features.forEach((feature) => {
    //     const email = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
    //     if (!email) return;
    //     const existingPerson = listFeatures.find((listFeature) => {
    //       const listEmail = aiimUtil.getAttributeValue(listFeature.attributes, FieldNames.PEOPLE_EMAIL);
    //       return email === listEmail;
    //     });
    //
    //     const userEmail = Context.getInstance().user.getEmail();
    //     const isUser = email === userEmail;
    //
    //     if (!existingPerson && !isUser) {
    //       listFeatures.push(feature);
    //     }
    //   });
    //
    //   return listFeatures;
    // };

    const clauses = [`${areaIdField} = '${escSqlQuote(areaId)}'`];

    const multiSelectProps = {
      allowSelf: false,
      dataset: people,
      displayField: knownasField,
      initialClause: isRestricted && clauses[0],
      maxItems: 1,
      onItemsUpdated: this._onOthersUpdated,
      //postQueryProcessing,
      searchIcon: (<UserIcon />),
      searchFields: [knownasField, emailField],
      searchPlaceholder: i18n.search.personPlaceholder,
      singleSelection: true,
      sortField: knownasField,
      subtitleField: emailField
    };

    // if (reserveOthersChecked && others.length === 0 && !errors.includes(i18n.search.mustSelectPerson)) {
    //   errors.push(i18n.search.mustSelectPerson);
    // }
    // const displayErrors = reserveOthersChecked && errors.map((error) => <li key={error}><p>{error}</p></li>);

    let displayErrors, msg;
    if (errors && errors.length > 0) {
      msg = errors[0];
    } else if (reserveOthersChecked && others.length === 0) {
      msg = i18n.search.mustSelectPerson;
    }
    if (msg) {
      displayErrors = [<li key={msg}><p>{msg}</p></li>]
    }

    return (
      <div className={CSS.toggleReserveOthers}>
        <div className="i-toggle-right">
          <label htmlFor={id} className={CSS.toggleLabel}>{i18n.more.bookWorkspace.reserveOthersLabel}</label>
          <ReactToggle
            id={id}
            className={CSS.toggle}
            checked={reserveOthersChecked}
            onChange={this._onReserveOthersChange}
            icons={false}
            aria-label={i18n.more.bookWorkspace.reserveOthersLabel}
            onFocus={this._onReserveOthersFocus}
            onBlur={this._onReserveOthersBlur}
          />
        </div>
        {reserveOthersChecked && <SearchableMultiSelect {...multiSelectProps} />}
        <ul className={CSS.othersErrors}>{displayErrors}</ul>
      </div>
    );
  }

  _onReserveOthersChange = (e) => {
    const checked = e.target.checked;
    if (!!checked) {
      this.setState({ reserveOthersChecked: !!checked });
    } else {
      this.setState({ reserveOthersChecked: !!checked, others: [], errors: [] });
    }
  }

  _onReserveOthersFocus = () => {
    this.setState({ reserveOthersToggleFocused: true });
  }

  _onReserveOthersBlur = () => {
    this.setState({ reserveOthersToggleFocused: false });
  }

  _onOthersUpdated = (items) => {
    const features = items.map((item) => item.feature);
    this.setState({ others: features });
  }
  private getOrigRecurrence() {
    if (!this.props.originalReservation)
      return null;
    const origResAttr = this.props.originalReservation.attributes;
    const origBooking = this.props.originalReservation.booking;
    return aiimUtil.getRecurrencePattern(origResAttr ?? origBooking);
  }
  private getOriginalWhen() {
    if (!this.props.originalReservation)
      return null;
    const origResAttr = this.props.originalReservation.attributes;
    const origBooking = this.props.originalReservation.booking;      
    if (!origResAttr && !origBooking)
      return null;

    let start: number, end: number, allDay: boolean, title: string;
    if (origResAttr) {
      start = this.props.recurrenceType === "series" ? this.props.startTime.valueOf() : aiimUtil.getAttributeValue(origResAttr, FieldNames.START_TIME);
      end = this.props.recurrenceType === "series" ? this.props.endTime.valueOf() : aiimUtil.getAttributeValue(origResAttr, FieldNames.END_TIME);
      allDay = this.props.recurrenceType === "series" ? dateUtil.isAllDay(start, end) : aiimUtil.getAttributeValue(origResAttr, FieldNames.ALL_DAY) ? true : false;
      title = aiimUtil.getAttributeValue(origResAttr, FieldNames.TITLE);
    } else if (origBooking) {
      const o365toDateObj = dateUtil.O365toDate(origBooking.start.dateTime, origBooking.end.dateTime);
      const utc = origBooking.start.timeZone?.toLowerCase() === "utc";
      const toLocal = utc ? moment.utc : moment;
      start = dateUtil.getMsFromTimeObj(this.props.recurrenceType === "series"
        ? toLocal(origBooking.masterEvent.start.dateTime)
        : o365toDateObj.fromDateTime);
      end = dateUtil.getMsFromTimeObj(this.props.recurrenceType === "series"
        ? toLocal(origBooking.masterEvent.end.dateTime)
        : o365toDateObj.toDateTime);
      allDay = dateUtil.isAllDay(start, end);
      title = origBooking.subject;
      // end date for all-day bookings (M365) is saved as 12 am the following day
      allDay && (end -= 1);
    } else {
      return null; 
    }
    if (start && end)
      return { start, end, title, allDay };
    return null; 
  }

  render() {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const { recurrenceType, operation } = this.props;
    const { reserveOthersChecked, others, errors, recurrence } = this.state;
    const type = getHotelBookingSystemType();
    const showScheduleButton = type === "esri" || type === "office365";

    const calendarIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16";

    const startDate = lib.dojo.locale.format(this.state.startDate, { selector:"date", formatLength:"short" })
    const endDate = lib.dojo.locale.format(this.state.endDate, { selector:"date", formatLength:"short" })

    const startTime = this.state.startTime;
    const endTime = this.state.endTime;
    let title = i18n.more.bookWorkspace.bookingDetailsTitle;
    title = title.replace("{levelName}", this.props.levelName);
    title = title.replace("{buildingName}", this.props.facilityName);
    const checked = this.state.allDay===true;

    const checkInDateLabel = startDate + ", " + i18n.more.bookWorkspace.startCalendarAriaLabel
    const checkOutDateLabel = endDate + ", " + i18n.more.bookWorkspace.endCalendarAriaLabel

    const isOutsideRange = (day) => recurrenceType !== "series" && moment().isAfter(day, 'day')
    const isDayHighlightedStart = (day) => {
      const start = moment(this.state.startDate)
      return moment().isSame(day, 'day') && !start.isSame(day, 'day')
    }
    const isDayHighlightedEnd = (day) => {
      const end = moment(this.state.endDate)
      return moment().isSame(day, 'day') && !end.isSame(day, 'day')
    }

    const onOpenChange = (open) => {
      if (open) {
        const preventScroll = () => {
          const timeColumns = document.getElementsByClassName("ant-picker-time-panel-column")
          for (var i = 0; i < timeColumns.length; i++) {
            const text = (timeColumns[i].firstChild.firstChild as HTMLElement).innerText
            if (text === "AM" || text === "PM") {
              (timeColumns[i] as HTMLElement).style.overflowY = "visible"
            }
          }
        }
        setTimeout(preventScroll, 100)
      }
    }

    let disabled = false;
    let btnConfirmTitle;
    const { areaId } = this.props;
    const appUserAssignments = Context.instance.aiim.appUserAssignments;
    const isUserAssigned = appUserAssignments && appUserAssignments.areaIDs.includes(areaId);
    if (!this.props.reserveForInfo) {
      if (reserveOthersChecked) {
        disabled = others.length === 0 || errors.length > 0;
        disabled && (btnConfirmTitle = i18n.more.bookWorkspace.noUserBtn);
      } else {
        disabled = !isUserAssigned;
        disabled && (btnConfirmTitle = i18n.more.bookWorkspace.noBookingPermission);
      }
    }

    const origWhen = this.getOriginalWhen();
    const origRecurrence = this.getOrigRecurrence();
    const currRecurrence = this.state.recurrence;
    if (origWhen || origRecurrence || currRecurrence) {
      let currStart = dateUtil.joinDayAndTime(this.state.startDate, this.state.startTime);
      let currEnd = dateUtil.joinDayAndTime(this.state.endDate, this.state.endTime);
      const currRecurrence = this.state.recurrence;
      
      // current start/end times reflect checkIn/checkOut in the dialog; if this is a series, those times
      // reflect the series start/end and will not match the original when retrieved from booking attributes    
      if (recurrenceType === "series") {
        const dates = getRecurringDates(currRecurrence, moment(currStart), moment(currEnd));
        const occurrence = dates.find(d => d.start.isSame(origWhen.start, "minutes"));
        if (occurrence) {
          currStart = occurrence.start.toDate().getTime();
          currEnd = occurrence.end.toDate().getTime();
        }
      }
      const whenDisabled = origWhen && currStart === origWhen.start && currEnd === origWhen.end && this.state.allDay === origWhen.allDay;
      const recurrenceDisabled = currRecurrence == null ? true : recurringSeriesMatch(currRecurrence, origRecurrence);
      disabled = disabled || (whenDisabled && recurrenceDisabled);
      disabled && btnConfirmTitle == null && (btnConfirmTitle=` ${i18n.more.bookWorkspace.noChangesMsg}`);      
    }
    const sameDayBooking = isSameDay(moment(this.state.startDate), moment(this.state.endDate));
    const weeklyMissingDays = recurrence?.enabled && recurrence?.type === "weekly" ? recurrence.days.length === 0 : false;
    disabled = disabled || this.state.isWorking || (recurrence?.enabled && (!sameDayBooking || weeklyMissingDays));
    
    const checkInTimeLabel = "Check in time"
    const checkOutTimeLabel = "Check out time"

    const unitFeature = this.props && this.props.unitFeature;
    let equipmentStr = null, equipmentList;
    if (unitFeature) {
      equipmentList = Context.instance.aiim.datasets.units.getEquipmentList(unitFeature, "DOM_EQUIPMENT_HOTEL");
      if (equipmentList && equipmentList.length > 0 ) equipmentStr = equipmentList.join(', ');
    }

    let confirmStr = i18n.more.bookWorkspace.confirm;
    let durationStyle: CSSProperties = {
      flexWrap: "nowrap"
    }

    let disableToggle = false, disableTime = false;
    if (operation === "updateBookingTime") {
      if (recurrenceType !== "series" && this.isOngoingBooking) {
        disableToggle = true
        durationStyle.cursor = "not-allowed";
        disableTime = true;
      }
      if (this.state.allDay) disableTime = true;
    } else {
      if (this.state.allDay) disableTime = true;
    }

    const startDateButton = (
      <button aria-label={checkInDateLabel}
        className={CSS.durationDate} onClick={this.togglePopoverStart} disabled={disableToggle}>
        <div className={CSS.iconContainer}>
          <div className={CSS.dateString}>{startDate}</div>
          <svg className={CSS.icon}><use href={calendarIcon}></use></svg>
        </div>
      </button>
    )
    const endDateButton = (
      <button aria-label={checkOutDateLabel}
        className={CSS.durationDate} onClick={this.togglePopoverEnd}>
        <div className={CSS.iconContainer}>
          <div className={CSS.dateString}>{endDate}</div>
          <svg className={CSS.icon}><use href={calendarIcon}></use></svg>
        </div>
      </button>
    )
    const recurrenceUI = displayRecurrence({ type, operation, recurrenceType }) &&
      <BookingRecurrence
        {...recurrence}
        disabled={!sameDayBooking}
        startDate={this.state.startDate}
        canToggle={this.props.operation !== "updateBookingTime"}
        onUpdate={recurrence => this.setState({ recurrence })} />
    return (
    <div className={CSS.popoutOuter}>
      {this.state.isWorking && 
      (<div style={{position:"fixed",width:"80%",transform: "translate(0%, 0%)", zIndex: "2"}}>
          <Loader sizeRatio={1} style={{marginTop: "3rem"}} />
      </div>)}
        <div className={CSS.popoutInner}>
        <div className={CSS.panelHeader}>
            <div className={CSS.bookingDetailsTitle}>
              <h2>{this.props.unitName}</h2>
              <h4>{title}</h4>
            </div>
          <div className={CSS.panelHeaderTools}>
              <button ref={this.closeRef}
              title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
              tabIndex={0} onClick={this.props.closePopup}>
              {Icons.close()}
              </button>
          </div>
        </div>
        <div className={CSS.filterForm}>
          <div className={CSS.durationFilterForm}>
          <div className={CSS.toggleAllDay}>
            <label htmlFor={"allDay"} className={CSS.toggleLabel}>{i18n.more.bookWorkspace.allDay}</label>
            <DayToggle id={"allDay"} checked={checked} onChange={this.allDayChanged} disabled={disableToggle}
                ariaLabel={i18n.more.bookWorkspace.toggleAriaLabel}
                onToggleFocus={this.onToggleFocus}
                onToggleBlur={this.onToggleBlur}></DayToggle>
          </div>
          </div>
          <div className={CSS.bookingFields}>{i18n.more.bookWorkspace.checkIn}</div>
          <div className={CSS.durationGroup} style={durationStyle}>
            <Popover
              closeOnSelect={false}
              onRequestClose={this.closeStart}
              open={this.state.startCalendarOpen}
              targetEl={startDateButton}
              appendToBody>
              <SDPWrapper initialDate={this.state.startDate} onDateChange={this.onStartDateChanged}
                outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedStart} booking={true}
              />
            </Popover>
            {
              this.state.allDay ?
              <TimePicker use12Hours={this.use12Hours} format={this.format}
                value={moment('0:00', this.format)} disabled={disableTime} aria-label={checkInTimeLabel} /> :
              <TimePicker  use12Hours={this.use12Hours}  format={this.format}
                minuteStep = {5}
                showNow = {false}
                value = {startTime}
                disabled={disableTime}
                onOpenChange={onOpenChange}
                onChange={this.onStartTimeChange}
                inputReadOnly={true}
                aria-label={checkInTimeLabel} />
            }
          </div>
          <div className={CSS.bookingFields}>{i18n.more.bookWorkspace.checkOut}</div>
          <div className={CSS.durationGroup} style={{flexWrap: "nowrap"}}>
            <Popover
              closeOnSelect={false}
              onRequestClose={this.closeEnd}
              open={this.state.endCalendarOpen}
              targetEl={endDateButton}
              appendToBody>
              <SDPWrapper initialDate={this.state.endDate} onDateChange={this.onEndDateChanged}
                outsideRange={isOutsideRange} dayHighlighted={isDayHighlightedEnd} booking={true}
              />
            </Popover>
            {
              this.state.allDay ?
              <TimePicker use12Hours={this.use12Hours} format={this.format}
                value={moment('23:59', this.format)} disabled aria-label={checkOutTimeLabel} /> :
              <TimePicker  use12Hours={this.use12Hours}  format={this.format}
                allowClear = {false}
                minuteStep = {5}
                showNow = {false}
                value = {endTime}
                onOpenChange={onOpenChange}
                onChange={this.onEndTimeChange}
                inputReadOnly={true}
                aria-label={checkOutTimeLabel} />
            }
            </div>
            {!sameDayBooking
              ? <Tooltip placement="top" positionFixed targetWrapperStyle={{ display: "block" }}
                title={i18n.calendars.recurringToggleDisable}>{recurrenceUI}</Tooltip>
              : recurrenceUI
            }
          {this.renderOccupantsList()}
          <div className={CSS.bookingFields}>{i18n.more.bookWorkspace.spaceType} </div>
          <div className={CSS.bookingDetails}>{this.props.spaceType}</div>
          {this.props.areaName &&
            <div>
                  <div className={CSS.bookingFields}>{i18n.more.bookWorkspace.area} </div>
                  <div className={CSS.bookingDetails}>{this.props.areaName}</div>
            </div>
          }
          {equipmentStr && (
            <div>
              <div className={CSS.bookingFields}>{i18n.more.bookWorkspace.equipmentType} </div>
              <div className={CSS.bookingDetails}>{equipmentStr}</div>
            </div>
          )}
        </div>
        <div className={CSS.button}>
            <Button disabled={disabled} className={CSS.confirmButton} type="button" title={btnConfirmTitle} style={{pointerEvents:'auto'}}
              onClick={this.confirmClicked}>{confirmStr}</Button>
            {showScheduleButton &&
              <Button className="i-button" type="button" clear
                onClick={this.scheduleClicked}>{i18n.meetingRooms.schedule.viewSchedule}</Button>
            }
        </div>
      </div>
    </div>
    )
  }
  private validateRecurrence() {
    const { recurrence, endDate } = this.state;
    const endMoment = moment(endDate);

    if (recurrence?.enabled
      && this.props.recurrenceType !== "occurrence"
      && endMoment.isSameOrAfter(recurrence?.endDate, "d")) {
      this.setState(prev => ({
        recurrence: { ...prev.recurrence, endDate: undefined }
      }));
    }
  }
  scheduleClicked = () => {
    const i18n = Context.instance.i18n;
    const roomItem = {
      isHotel: true,
      unitId: aiimUtil.getAttributeValue(this.props.unitFeature.attributes,FieldNames.UNIT_ID),
      scheduleEmail: aiimUtil.getAttributeValue(this.props.unitFeature.attributes, FieldNames.SCHEDULE_EMAIL),
      name: this.props.unitName,
      unitFeature: this.props.unitFeature
    };
    let isAllDay = !!this.state.allDay;
    let dtStart = this.makeStartDateTime();
    let dtEnd = this.makeEndDateTime();
    if (isAllDay) {
      dtStart = moment(dtStart).startOf("day").toDate();
      dtEnd = moment(this.state.endDate).endOf("day").toDate();
    }
    let start = dtStart.getTime();
    let when: dateUtil.IDurationAsMilliseconds = {
      duration: isAllDay ? "allDay" : null,
      start: {
        date: dtStart.getTime()
      },
      end: {
        date: dtEnd.getTime()
      },
      recurrence: this.state.recurrence
    }
    const controller = new ModalController();
    let title = i18n.meetingRooms.schedule.captionPattern;
    title = title.replace("{room}",roomItem.name);
    const modalProps = {
      className: "i-modal-confirm i-modal-schedule",
      title: title,
      cancelLabel: i18n.general.close,
      showOKCancel: true,
      hideOK: true
    };

    let operation;
    if (this.props && this.props.operation === "updateBookingTime") {
      operation = this.props.operation;
    }

    let reserveForInfo = null;
    if (!this.props.reserveForSelf) reserveForInfo = this.props.reserveForInfo;

    const content = (
      <Schedule 
        bookingType="hotel"
        item={roomItem} 
        start={start} 
        when={when}
        series={this.props.series}
        operation={operation}
        isOngoingBooking={this.isOngoingBooking}
        originalReservation={this.props.originalReservation}
        recurrenceType={this.props.recurrenceType}
        reserveForInfo={reserveForInfo}
        onShowOccupant={() => {
          controller.close();
          this.props.closePopup && this.props.closePopup();
        }}
        onBookClicked={selectedWhen => {
          const allDay = selectedWhen.duration === "allDay";
          let startDate = new Date(selectedWhen.start.date);
          let endDate = new Date(selectedWhen.end.date);
          let startTime, endTime;
          if (!allDay) {
            startTime = moment(startDate);
            endTime = moment(endDate);
          } else {
            startTime = this.state.startTime;
            endTime = this.state.endTime;
          }
          this.setState({
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            allDay: allDay,
            startCalendarOpen: false,
            endCalendarOpen: false,
            recurrence: { ...selectedWhen.recurrence }
          })
          controller.close();
        }}/>
    )
    controller.show(modalProps,content);
  }

  togglePopoverStart = () => {
    this.setState({
      startCalendarOpen: !this.state.startCalendarOpen,
      endCalendarOpen: false
    })
  }

  togglePopoverEnd = () => {
    this.setState({
      endCalendarOpen: !this.state.endCalendarOpen,
      startCalendarOpen: false
    })
  }

  closeStart = () => {
    this.setState({
      startCalendarOpen: false
    })
  }

  closeEnd = () => {
    this.setState({
      endCalendarOpen: false
    })
  }


}

const mapStateToProps = (state) => {
  return {
    rdxStartDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_STARTSELECTED),
    rdxEndDateSelected: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_ENDSELECTED),
    rdxStartTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_STARTSELECTED),
    rdxEndTimeSelected: Rdx.getValue(state, Rdx.Keys.BOOK_TIMEFILTER_ENDSELECTED),
    rdxAllDayChecked: Rdx.getValue(state, Rdx.Keys.BOOK_ALLDAY),
    rdxForOthers: Rdx.getValue(state, Rdx.Keys.RESERVE_FOR_OTHERS),
    rdxRecurrence: {
      enabled: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENABLED),
      endDate: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_ENDDATE),
      type: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_TYPE),
      days: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_DAYS),
      interval: Rdx.getValue(state, Rdx.Keys.BOOK_RECURRENCE_INTERVAL)
    }
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRdxValue: (key: string, value) => dispatch(setValue(key, value))
})
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);
