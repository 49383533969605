import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import Toggle from "../../common/Toggle/Toggle";
import * as component from "../../util/component";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  toggleLabel: "i-toggle-label"
};

export default class Scalebar extends React.Component {

  constructor(props) {
    super(props);
    const scalebar = this.props.cfg.getConfigurable("scalebar");
    this.state = component.newState({
      show: scalebar.show,
      units: scalebar.units,
      style: scalebar.style
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeShow = (checked) => {
    const cfg = this.props.cfg;
    this.setState(state => {
      return {
        show: checked
      }
    });
    cfg.getConfigurable("scalebar").show = checked;
    this.updateMapScalebar();
  }

  onChangeStyle = (evt) => {
    const cfg = this.props.cfg;
    const v = evt.target.value;
    this.setState(state => {
      return {
        style: v
      }
    });
    cfg.getConfigurable("scalebar").style = v;
    this.updateMapScalebar();
  }

  onChangeUnits = (evt) => {
    const cfg = this.props.cfg;
    const v = evt.target.value;
    let style = this.state.style;
    if (v === "dual" && style === "ruler") {
      style = "line";
      cfg.getConfigurable("scalebar").style = style;
    }
    this.setState(state => {
      return {
        units: v,
        style: style
      }
    });
    cfg.getConfigurable("scalebar").units = v;
    this.updateMapScalebar();
  }

  render() {
    const i18n = Context.instance.i18n;
    const show = this.state.show;
    const units = this.state.units;
    let style = this.state.style;
    const disabled = !this.state.show;

    const styleOptions = [];
    styleOptions.push((
      <option key={"line"} value={"line"}
        >{i18n.configurator.scalebar.style.line}</option>
    ));
    if (units !== "dual") {
      styleOptions.push((
        <option key={"ruler"} value={"ruler"}
          >{i18n.configurator.scalebar.style.ruler}</option>
      ));
    } else {
      if (style === "ruler") style= "line";
    }

    return (
      <div className={CSS.section}>

        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.scalebar.caption}</span>
          <HelpTip text={i18n.configurator.scalebar.help}/>
        </div>

        <div key="show" className={CSS.formGroup}>
          <label id={"cfg-scalebar-show-label"} htmlFor={"cfg-scalebar-show"}>
            <div className={CSS.toggleLabel}
              >{i18n.configurator.scalebar.show}</div>
            <Toggle id={"cfg-scalebar-show"} checked={show}
              onChange={this.onChangeShow}/>
          </label>
        </div>

        <div key="units" className={CSS.formGroup}>
          <label id={"cfg-scalebar-units-label"} htmlFor={"cfg-scalebar-units"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.scalebar.units.label}</div>
            <select id={"cfg-scalebar-units"} value={units}
              onChange={this.onChangeUnits} disabled={disabled}>
              <option key={"metric"} value={"metric"}
                >{i18n.configurator.scalebar.units.metric}</option>
              <option key={"nonMetric"} value={"non-metric"}
                >{i18n.configurator.scalebar.units.nonMetric}</option>
              <option key={"dual"} value={"dual"}
                >{i18n.configurator.scalebar.units.dual}</option>
            </select>
          </label>
        </div>

        <div key="style" className={CSS.formGroup}>
          <label id={"cfg-scalebar-style-label"} htmlFor={"cfg-scalebar-style"}>
            <div className={CSS.formGroupName}
              >{i18n.configurator.scalebar.style.label}</div>
            <select id={"cfg-scalebar-style"} value={style}
              onChange={this.onChangeStyle} disabled={disabled}>
              {styleOptions}
            </select>
          </label>
        </div>

      </div>
    );

  }

  updateMapScalebar() {
    Context.instance.views.updateScalebar();
  }

}
