import React from "react";

import { CalciteP } from "calcite-react/Elements";

import Form, {
  Fieldset,
  FormControl
} from "calcite-react/Form";
import Checkbox from "calcite-react/Checkbox";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import Warning from "../../../common/components/Warning/Warning";
import AssignmentsPromptPeople from "./MultipleAssignments/AssignmentsPromptPeople";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import { getAttributes, getAssignmentNames } from "./MultipleAssignments/multipleAssignmentsUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as localStore from "../../../util/localStore";

export default class DndPrompt extends React.Component {
  warningComponent = null
  capacityWarningNode = null

  constructor(props) {
    super(props);
    this.state = component.newState({
      checkingOccupancy: true,
      enablePopups: true,
      peopleNames: this.props.peopleNames,
      unassignOthers: this.props.unassignOthers
    });
  }

  componentWillMount() {
    this.capacityWarningNode = (
      <Warning key="capacity-warning-dnd" _onMount={this.onMountWarning}></Warning>
    )
  }

  componentDidMount() {
    const personName = this.props.personName;
    const targetOffice = this.props.targetOffice;
    if (targetOffice && this.props.checkOccupancy) {
      this.props.checkOccupancy(personName,targetOffice).then(result => {
        this.setState({
          checkingOccupancy: false,
          peopleNames: result && result.peopleNames
        });
      }).catch(ex => {
        console.error("Error checking occupancy",ex);
      })
    } else {
      this.setState({
        checkingOccupancy: false
      });
    }
    if (targetOffice) {
      officePlanUtil
        .queryPeopleAssignedToUnit(targetOffice)
        .then((result) => {
          const assignments = result.length
          const capacity = getAttributeValue(targetOffice.feature.attributes,FieldNames.UNITS_CAPACITY);
          if (this.warningComponent) {
            const chk = (typeof capacity === "number");
            if (chk && (assignments > capacity)) {
              const i18n = Context.instance.i18n;
              let message = i18n.spaceplanner.capacity.atCapacityWarningPerson
              message = message.replace("{name}", personName)
              message = message.replace("{capacity}", capacity)
              message = message.replace("{assignments}", assignments)
              message = assignments === 1 ?
                message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionSingular) :
                message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionPlural)
              this.warningComponent.setMessage(message)
            } else {
              this.warningComponent.setMessage(null)
            }
          }
        })
        .catch((ex) => {
          console.error("Error querying people assigned to unit", ex);
        });
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onDontShowChanged = (evt) => {
    const checked = !!!evt.target.checked;
    this.setState({
      enablePopups: checked
    });
    const session = Context.getInstance().session;
    const userSettings = session && session.userSettings;
    if (!userSettings) {
      return;
    }
    // Apply the setting
    userSettings.enablePopups = checked;
    const key = localStore.userSettingKeys.enablePopups;
    localStore.setUserSetting(key, checked);
  }

  onUnassignOthersChanged = (evt) => {
    const checked = !!evt.target.checked;
    this.setState({unassignOthers: checked});
    if (this.props.onUnassignOthersChanged) {
      this.props.onUnassignOthersChanged(checked);
    }
  }

  onMountWarning = obj => {
    this.warningComponent = obj
  }

  renderItemTag(text) {
    // Make button eventually
    return (
      <div className="i-modal-tag">
        {text}
      </div>
    )
  }

  render() {
    const i18n = Context.instance.i18n;
    const {
      text,
      targetOffice,
      areaItem,
      personFeature,
      assignments,
      onUnassignAssignmentsChanged,
      onCurrentChanged,
      dragInfo
    } = this.props;
    const {checkingOccupancy, enablePopups, peopleNames, unassignOthers} = this.state;
    const formControlStyle = { margin: '0' }

    const currentAssignment = this.props.currentAssignment
    const notAssigned = currentAssignment === Context.instance.i18n.spaceplanner.people.noAssignment
    let selectProps = {
      dnd: true,
      hasPeopleAssigned: peopleNames && peopleNames.length > 0,
      personFeature: personFeature,
      assignToFeature: areaItem || targetOffice,
      assignments: assignments,
      currentAssignment: currentAssignment,
      // showKeepCurrentAssignment: showKeepCurrentAssignment,
      showKeepCurrentAssignment: !notAssigned,
      onUnassignChanged: onUnassignAssignmentsChanged,
      onCurrentChanged: onCurrentChanged,
      dragInfo: dragInfo
    }

    const unitField = Context.instance.aiim.getUnitName();
    let targetOfficeName = ""
    if (targetOffice && typeof targetOffice !== undefined) {
      targetOfficeName = getAttributeValue(getAttributes(targetOffice), unitField)
    }

    const style = !notAssigned ? {marginTop: '0.75rem'} : {}
    const unassignOthersLabel =
      i18n.spaceplanner.people.assignTo.unit.unassignOthersFrom
      .replace("{unit}", targetOfficeName);
    // const unassignOthersLabel = "Unassign others from " + targetOfficeName + ":"

    const assignmentNames = getAssignmentNames(assignments, currentAssignment);
    const hasAssignment = (
      currentAssignment &&
      !notAssigned &&
      !dragInfo.subjectParams.fromUnitPopup
    );
    const displayDontShowNode = assignmentNames.length === 0 && !hasAssignment;

    let dontShowNode = null;
    let choiceNode = null;
    let namesNode = null;
    if (peopleNames && peopleNames.length > 0) {
      if (this.props.targetOffice) {
        choiceNode = (
          <Form style={style}>
            <FormControl style={formControlStyle}>
              <Fieldset name="assignmentOption">
                <Checkbox
                  checked={unassignOthers}
                  onChange={this.onUnassignOthersChanged}>
                  {unassignOthersLabel}
                </Checkbox>
              </Fieldset>
            </FormControl>
          </Form>
        );
      }
      namesNode = (
        <div className={"i-assignment-names"} /*style={{fontSize: "0.8rem", overflow: "auto"}}*/>
          {/* {peopleNames.join(", ")} */}
          {peopleNames.map(n => this.renderItemTag(n))}
        </div>
      );
    } else if (displayDontShowNode && targetOffice && !checkingOccupancy) {
      const dontShowFormStyle = {
        margin: '1rem 0 0'
      };
      const dontShowTextStyle = {
        margin: '0',
        fontSize: '0.85rem'
      };
      dontShowNode = (
        <Form>
          <FormControl style={dontShowFormStyle}>
            <Fieldset name="dontShow">
              <Checkbox
                checked={!enablePopups}
                onChange={this.onDontShowChanged}>
                {/* {i18n.spaceplanner.dontShowPopupAgain} */}
                <p style={dontShowTextStyle}>{i18n.spaceplanner.popup.dontShowDialog}</p>
              </Checkbox>
            </Fieldset>
          </FormControl>
        </Form>
      );
    }

    return (
      <div className={"i-sp-modal-checkboxes"}>
        <CalciteP>{text}</CalciteP>
        <AssignmentsPromptPeople {...selectProps} />
        {choiceNode}
        {namesNode}
        {dontShowNode}
        {this.capacityWarningNode}
      </div>
    );
  }

}
