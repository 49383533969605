import React from "react";
import AreaFilter from "./AreaFilter";
import CapacityFilter from "./CapacityFilter";
import Context from "../../../context/Context";
import LevelFilter from "./LevelFilter";
import * as component from "../../util/component";
import { IMeetingRoom, IMeetingRoomCriteria } from "./MeetingRoomsModel";
interface IFilterProps {
  criteria: IMeetingRoomCriteria,
  items: IMeetingRoom[],
  areasWithMeetingRooms?: __esri.Graphic[],
  onMount: (filter: Filter) => void
}
export default class Filter extends React.Component<IFilterProps> {

  areaComponent;
  capacityComponent;
  levelComponent;

  constructor(props) {
    super(props);
    this.state = component.newState({
    });
  }

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const criteria = this.props.criteria;
    const unitsLayer = Context.instance.aiim.datasets?.units?.layer2D;
    return (
      <div className="i-filter-form" style={{minWidth: "200px", padding: "0.5rem"}}>
        <AreaFilter
          areaIds={criteria.filter.areaIds}
          items={this.props.items}
          areasWithMeetingRooms={this.props.areasWithMeetingRooms}
          onMount={c => this.areaComponent = c} />
        <CapacityFilter
          capacity={criteria.filter.capacity}
          items={this.props.items}
          onMount={c => this.capacityComponent = c} />
        <LevelFilter items={this.props.items}
          facilityId={criteria.filter.facilityId}
          levelId={criteria.filter.levelId}
          siteId={criteria.filter.siteId}
          equipments={criteria.filter.equipments}
          onMount={c => this.levelComponent = c} />
      </div>
    );
  }

  validate(criteria) {
    let ok = true;
    let filter = {
      siteId: null,
      facilityId: null,
      levelId: null,
      capacity: null,
      equipments: [],
      areaIds: []
    };
    if (ok && this.areaComponent) ok = this.areaComponent.validate(filter);
    if (ok) ok = this.capacityComponent.validate(filter);
    if (ok) ok = this.levelComponent.validate(filter);
    if (ok) {
      criteria.filter = filter;
    }
    return ok;
  }

}
