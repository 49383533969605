import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import * as aiimUtil from "../util/aiimUtil";
import * as selectionUtil from "../util/selectionUtil";

export default class TrackingViews extends BaseClass {

  _lastKnownViews;
  _serviceInfo;
  _userData;

  canObserve(source,feature) {
    const username = this.usernameFromFeature(source,feature);
    if (username) return !!this.viewFor(username);
    return false;
  }

  load(portal,portalUser) {
    //console.log("TrackingViews.load=============================",new Date());
    const promise = new Promise((resolve,reject) => {
      const task = {
        portal: portal,
        portalUser: portalUser,
        serviceInfo: null,
        lastKnownViews: [],
        userData: {
          usernames: [],
          usernamesByFullName_lc: {},
          usernamesByEmail_lc: {}
        }
      };
      this._loadServiceInfo(task).then(() => {
        if (task.serviceInfo) {
          return this._loadViews(task);
        }
      }).then(() => {
        if (task.userData.usernames.length > 0) {
          return this._loadUsers(task);
        }
      }).then(() => {
        this._serviceInfo = task.serviceInfo;
        this._lastKnownViews = task.lastKnownViews;
        this._userData = task.userData;
        //console.log("TrackingViews",this);
        //console.log("TrackingViews.load.............................",new Date());
        resolve();
      }).catch(ex => {
        console.error("Error loading tracking views",ex);
        resolve();
      });
    });
    return promise;
  }

  _loadLastKnownView(task,item) {
    const promise = new Promise((resolve,reject) => {
      const url = item.url + "/1";
      aiimUtil.readServiceJson(url).then(result => {
        //console.log("TrackingViews::_loadLastknownView.result",result);
        const info = result && result.data;
        //console.log("_loadLastKnownView",info);
        if (info && info.viewDefinitionQuery) {
          const usernames = this._parseDefinitionQuery(task,info);
          if (usernames && usernames.length > 0) {
            task.lastKnownViews.push({
              item: item,
              url: url,
              usernames: usernames,
              viewInfo: info
            });
            usernames.forEach(username => {
              if (task.userData.usernames.indexOf(username) === -1) {
                task.userData.usernames.push(username);
              }
            });
          }
        }
        resolve();
      }).catch(ex => {
        console.warn("Error loading:",url);
        console.error(ex);
        resolve();
      });
    });
    return promise;
  }

  _loadServiceInfo(task) {
    const promise = new Promise((resolve,reject) => {
      const helperServices = (task.portal && task.portal.helperServices);
      const locationTracking = (helperServices && helperServices.locationTracking);
      const url = locationTracking && locationTracking.url
      if (!task.portal || !task.portalUser || !locationTracking || !url) {
        resolve();
        return;
      }
      aiimUtil.readServiceJson(url).then(result => {
        const info = (result && result.data);
        if (info && info.isLocationTrackingService) {
          task.serviceInfo = info;
        }
        resolve();
      }).catch(ex => {
        console.warn("Error loading:",url);
        console.error(ex);
        resolve();
      });
    });
    return promise;
  }

  _loadUsers(task) {
    const promise = new Promise((resolve,reject) => {
      const splitLength = 1000; // TODO?
      const promises = [], splits = [];

      const makeSplits = () => {
        const all = task.userData.usernames.slice();
        while (all.length > 0) {
          let query = "", usernames = all.splice(0,splitLength)
          usernames.forEach(username => {
            if (query.length > 0) query += " OR ";
            query += "username:\"" + username + "\"";
          });
          if (query.length > 0) {
            splits.push({
              queryParams: {
                query: query,
                num: splitLength
              },
              usernames: usernames
            });
          }
        }
      };

      const execSplit = (split) => {
        const promise2 = new Promise((resolve2,reject2) => {
          task.portal.queryUsers(split.queryParams).then(result => {
            //console.log("TrackingViews::_loadUsers",result);
            if (result && result.results) {
              result.results.forEach(user => {
                if(split.usernames.indexOf(user.username) === -1) return;
                let username = user.username;
                let fullName = user.fullName;
                let email = user.email;
                if (typeof fullName === "string" && fullName.length > 0) {
                  let lc = fullName.toLowerCase();
                  task.userData.usernamesByFullName_lc[lc] = username;
                }
                if (typeof email === "string" && email.length > 0) {
                  let lc = email.toLowerCase();
                  task.userData.usernamesByEmail_lc[lc] = username;
                }
              });
            }
            resolve2();
          }).catch(ex => {
            console.error("Error loading tracking view users",ex);
            resolve2();
          });
        });
        return promise2;
      }

      makeSplits();
      splits.forEach(split => promises.push(execSplit(split)));
      Promise.all(promises).then(() => {
        //console.log("TrackingViews::_loadUsers complete ......");
        resolve();
      }).catch(ex => {
        console.error("Error loading tracking view users",ex);
        resolve();
      });
    });
    return promise;
  }

  _loadViews(task) {
    const promise = new Promise((resolve,reject) => {
      const queryParams = {
        query: "typekeywords:\"Location Tracking View\"",
        num: 10000
      };
      task.portal.queryItems(queryParams).then(result => {
        //console.log("TrackingViews::_loadViews",result);
        const promises = [];
        if (result && result.results) {
          result.results.forEach(item => {
            if (typeof item.url === "string" && item.url.length > 0) {
              promises.push(this._loadLastKnownView(task,item));
            }
          });
        }
        Promise.all(promises).then(() => {
          //console.log("TrackingViews::load complete ......");
          resolve();
        }).catch(ex => {
          console.error("Error loading tracking views",ex);
          resolve();
        });
      }).catch(ex => {
        console.error("Error querying tracking views",ex);
        resolve();
      });
    });
    return promise;
  }

  _parseDefinitionQuery(task,viewInfo) {
    const usernames = [];
    const v = viewInfo && viewInfo.viewDefinitionQuery;
    const fld = (viewInfo.editFieldsInfo && viewInfo.editFieldsInfo.creatorField);
    if (fld && typeof v === "string" && v.length > 0) {
      // e.g. (created_user in ('tomhill','tomadmin','mobiletracker','summer'))
      const re = /'(.*?)'/g; // match anything between single quotes (? stops this from being greedy)
      v.replace(re, (match, value, offset) => {
        //console.log("matched",value);
        if (typeof value === "string" && value.length > 0) {
          usernames.push(value);
        }
      });
    }
    return usernames;
  }

  queryLastKnownLocation(username) {
    const view = this.viewFor(username);
    if (!view) return Promise.resolve();
    const viewInfo = view.viewInfo;
    const url = Context.checkMixedContent(view.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    const timestampField = viewInfo.timeInfo && viewInfo.timeInfo.startTimeField;
    const creatorField = viewInfo.editFieldsInfo.creatorField;
    const value = "'" + selectionUtil.escSqlQuote(username) + "'";
    const mapView = Context.getInstance().views.mapView;
    if (mapView) query.outSpatialReference = mapView.spatialReference;
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "(" + creatorField + " = " + value + ")";
    if (timestampField) {
      query.orderByFields = [timestampField + " DESC"];
    }
    return task.execute(query).then(result => {
      let info;
      if (result && result.features) {
        if (result.features.length > 0) {
          const feature = result.features[0];
          if (feature && feature.geometry && feature.geometry.type === "point") {
            info = {
              feature: feature,
              locationTimestamp: aiimUtil.getAttributeValue(feature.attributes,"location_timestamp"),
              altitude: aiimUtil.getAttributeValue(feature.attributes,"altitude"),
              floor: aiimUtil.getAttributeValue(feature.attributes,"floor"),
              horizontalAccuracy: aiimUtil.getAttributeValue(feature.attributes,"horizontal_accuracy"),
              verticalAccuracy: aiimUtil.getAttributeValue(feature.attributes,"vertical_accuracy")
            };
            if (!feature.geometry.hasZ && typeof info.altitude === "number") {
              feature.geometry.z = info.altitude;
              feature.geometry.hasZ = true;
            }
          }
        } else {
          // no last known location for this user
          info = {
            noLastKnownLocation: true
          };
        }
      }
      return info;
    });
  }

  usernameFromFeature(source,feature) {
    let username;
    const userData = this._userData;
    if (userData && userData.usernames.length > 0 && source.isPeopleLayer() && feature && feature.attributes) {
      //const user =  Context.getInstance().aiim.user;
      const peopleDataset =  Context.getInstance().aiim.datasets.people;
      if (peopleDataset) {
        //console.log("...canObserve",feature);
        if (!username) {
          const field = peopleDataset.emailField;
          const v = aiimUtil.getAttributeValue(feature.attributes,field);
          if (typeof v === "string" && v.length > 0) {
            username = userData.usernamesByEmail_lc[v.toLowerCase()];
          }
        }
        if (!username) {
          const field = peopleDataset.fullNameField;
          const v = aiimUtil.getAttributeValue(feature.attributes,field);
          if (typeof v === "string" && v.length > 0) {
            username = userData.usernamesByFullName_lc[v.toLowerCase()];
          }
        }
      }
    }
    return username;
  }

  viewFor(username) {
    let view;
    const views = this._lastKnownViews || [];
    if (username) {
      views.some(vw => {
        if (vw.usernames && vw.usernames.indexOf(username) !== -1) {
          view = vw;
          return true;
        }
        return false;
      });
    }
    return view;
  }

}
