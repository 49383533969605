import React from "react";

import CalciteList from "calcite-react/List";

export default class List extends React.Component {

  render() {
    return (
      <CalciteList {...this.props}>
        {this.props.children}
      </CalciteList>
    );
  }

}
