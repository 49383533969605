import React, { useState } from "react";
import Context from "../../../../../context/Context";
import moment, { Moment } from "moment";
import ReactToggle from "react-toggle";
import Button, { ButtonGroup } from "calcite-react/Button";
import Radio from "calcite-react/Radio";
import { Fieldset } from "calcite-react/Form";
import Popover from "calcite-react/Popover"
import SDPWrapper from "../../../../common/Calendar/SDPWrapper";
import "react-toggle/style.css";
import { DayOfWeekShape } from "react-dates";
import { nextTmpId } from "../../../../util/component";
import { IBookingSystem } from "./WorkspaceReservation/BookingSystem";
import { BookingType } from "../../../../../util/calendarUtil";

/** Recurrence pattern options. */
export interface IRecurrenceOptions {
  /** Unique ID for recurrence options stored in database. */
  id?: string,
  /** Enables recurrence options. */
  enabled: boolean,
  endDate?: Date | null,
  days?: DayOfWeekShape[],
  interval?: number,
  sequence?: number,
  startDate?: Date,
  type?: "daily" | "weekly",
  modified?: "occurrence" | "series" 
}
export interface IRecurrenceSeries {
  id: string,
  occurrences: __esri.Graphic[],
  allOccurrences?: __esri.Graphic[],
  options: IRecurrenceOptions,
  checkIn: Date,
  checkOut: Date
}
export interface IBookingRecurrenceProps extends IRecurrenceOptions {
  /** Whether options can be enabled/disabled. */
  canToggle?: boolean,
  /** Disables the entire user interface. */
  disabled?: boolean,
  onUpdate?: (options: IRecurrenceOptions) => void,
  /** Whether the toggle button is visible or not. */
  showToggle?: boolean,
  startDate?: Date
}
/** Determines if the currently configured booking system supports recurrence. */
export const supportsRecurrence = (type: IBookingSystem["type"]) => type === "office365"
  || (type === "esri" && Context.getInstance().aiim.datasets.reservations?.hasRecurrenceFields());

/** Indicates whether to display recurrence UI based on type of operation. */
export const displayRecurrence = ({ type, recurrenceType, operation }:
  { type: IBookingSystem["type"], recurrenceType: BookingType, operation?: "updateBookingTime" }) => {
  if (supportsRecurrence(type)) {
    return operation === "updateBookingTime" ? recurrenceType === "series" : true;
  } else {
    return false;
  }
}

export const getDefaultRecurrenceOptions = ({ enabled, type }: IRecurrenceOptions, start: ConstructorParameters<DateConstructor>[0]): IRecurrenceOptions => {
  return {
    enabled,
    type,
    days: type === "weekly" ? [moment(start).get("day") as DayOfWeekShape] : [],
    endDate: getDefaultEndDate(type, new Date(start))
  };
}
export const getDefaultEndDate = (type: IRecurrenceOptions["type"], startDate: Date = moment().toDate()) => {
  const endDate = moment(startDate).add(1, type === "daily" ? "week" : "month").toDate();
  endDate.setHours(23, 59, 59, 999);
  return endDate;
}
export const getRecurringDates = (options: IRecurrenceOptions, checkIn: moment.Moment, checkOut: moment.Moment) => {
  const dates: { start: moment.Moment, end: moment.Moment }[] = [];
  if (!(options && options.enabled)) {
    return dates;
  }
  const days = options.type === "daily" ? [0, 1, 2, 3, 4, 5, 6] : options.days;
  const dayDiff = checkOut.diff(checkIn, "day");
  const monthDiff = checkOut.diff(checkIn, "month");
  const yearDiff = checkOut.diff(checkIn, "year");
  // checkin day (0 - 6)
  const checkInDay = checkIn.day();

  days.forEach(day => {
    // first recurring date
    const current = checkIn.clone().day(checkInDay > day
      ? day + 7
      : day);
    const clone = (cloneDate: moment.Moment) => current.clone()
      .set("hour", cloneDate.hour())
      .set("minute", cloneDate.minute())
      .set("second", cloneDate.second())
      .set("millisecond", cloneDate.millisecond());

    while (current.isSameOrBefore(options.endDate)) {
      const start = clone(checkIn);
      const end = clone(checkOut).add(dayDiff, "day").add(monthDiff, "month").add(yearDiff, "year");
      dates.push({ start, end });
      current.add(1, "week");
    }
  });
  return sortDates(dates);
}

function sortDates(dates: { start: Moment, end: Moment }[]) {
  return dates.sort((resA, resB) => {
    const startTimeA = resA.start;
    const startTimeB = resB.start;
    return startTimeA > startTimeB ? 1 : startTimeA < startTimeB ? -1 : 0;
  });
}

/** Provides options for specifying recurring time slots. */
const BookingRecurrence = ({
  startDate = moment().toDate(),
  canToggle = true,
  showToggle = true,
  days = [moment(startDate).get("day") as DayOfWeekShape],
  disabled = false,
  enabled,
  type = "weekly",
  endDate = getDefaultEndDate(type, startDate),
  onUpdate = () => { }
}: IBookingRecurrenceProps) => {
  const { i18n, lib } = Context.getInstance();
  const allDays = [i18n.calendars.sunAbbr, i18n.calendars.monAbbr, i18n.calendars.tuesAbbr, i18n.calendars.wedAbbr, i18n.calendars.thursAbbr, i18n.calendars.friAbbr, i18n.calendars.satAbbr];
  const [endCalendarOpen, setEndCalendarOpen] = useState<boolean>(false);
  const [dailyEndDate, setDailyEndDate] = useState<Date>();
  const [weeklyEndDate, setWeeklyEndDate] = useState<Date>();
  const [isDaySelected, setIsDaySelected] = useState<boolean>(true);
  const uniqueDays = new Set(days);
  const update = (options?: Partial<IRecurrenceOptions>) => {
    onUpdate({ enabled, endDate, type, interval: 1, days: [...uniqueDays].sort(), ...options });
    setEndCalendarOpen(false);
  }
  const toggleId = nextTmpId("recurrenceToggle-");
  return (
    <div className={`i-recurrence-options${disabled ? " i-recurrence-disabled" : ""}`}>
      {showToggle &&
        <div className="i-toggle-right">
          <label htmlFor={toggleId} className="i-toggle-label">{i18n.more.bookWorkspace.recurrence.toggle}</label>
          <ReactToggle id={toggleId} disabled={disabled || !canToggle} className="i-toggle" checked={enabled} icons={false} onChange={(evt: InputEvent) =>
            update({ enabled: (evt.target as HTMLInputElement).checked })} />
        </div>
      }
      {enabled &&
        <div className="i-recurrence-pattern">
          <fieldset className="i-recurrence-row i-recurrence-type">
            <Radio disabled={disabled} checked={type === "daily"} onChange={() => update({
              type: "daily",
              endDate: dailyEndDate != null ? dailyEndDate : getDefaultEndDate("daily", startDate)
            })}>{i18n.more.bookWorkspace.recurrence.daily}</Radio>
            <Radio disabled={disabled} checked={type === "weekly"} onChange={() => update({
              type: "weekly",
              endDate: weeklyEndDate != null ? weeklyEndDate : getDefaultEndDate("weekly", startDate)
            })}>{i18n.more.bookWorkspace.recurrence.weekly}</Radio>
          </fieldset>
          {/* <Fieldset name="recurrence-frequency" horizontal className="i-recurrence-row i-recurrence-frequency">
            <FormControlLabel>Occurs every</FormControlLabel>
            <TextField type="number" min="0" value={frequency} />
          </Fieldset>           */}
          {type === "weekly" &&
            <>
            <ButtonGroup disabled={disabled} className="i-recurrence-row i-recurrence-days" isToggle={false}>
              {allDays.map((d: string, i: DayOfWeekShape) =>
                <Button
                  key={i}
                  className={`i-recurrence-day${!uniqueDays.has(i) ? " i--clear" : ""}`}
                  disabled={disabled}
                  onClick={() => {
                    uniqueDays.has(i) ? uniqueDays.delete(i) : uniqueDays.add(i);
                    const isDays = uniqueDays.size === 0 ? false : true;
                    setIsDaySelected(isDays);
                    update();
                  }}>{d}</Button>
              )}
            </ButtonGroup>
            {!isDaySelected && <p className="i-recurrence-message"><em>{i18n.calendars.recurringNoDaySelectedMsg}</em></p>}
            </>
          }
        <div className="i-recurrence-row i-recurrence-end-date">
          <label>{i18n.more.bookWorkspace.recurrence.endDate}</label>
          <Popover
            appendToBody
            closeOnSelect={false}
            onRequestClose={() => setEndCalendarOpen(false)}
            open={endCalendarOpen}
            targetEl={(
              <button disabled={disabled} className="i-date" aria-label={"Select end date for recurring booking"} onClick={() => setEndCalendarOpen(!endCalendarOpen)}>
                <div className="i-date-icon-container">
                  <span className="i-date-string">{lib.dojo.locale.format(endDate, { selector: "date", formatLength: "short" })}</span>
                  <svg className="i-date-icon"><use href="libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16"></use></svg>
                </div>
              </button>
            )}>
            <SDPWrapper
              booking={true}
              initialDate={endDate}
              onDateChange={endDate => {
                endDate.setHours(23, 59, 59, 999);
                type === "daily" ? setDailyEndDate(endDate) : setWeeklyEndDate(endDate);
                update({ endDate });
              }}
              outsideRange={(day: Moment) => moment(startDate).isAfter(day, "day")}
              dayHighlighted={(day: Moment) => {
                const end = moment(endDate);
                return moment().isSame(day, "day") && !end.isSame(day, "day");
              }} />
            </Popover>
          </div>
        </div>
      }
    </div>
  );
}

export default BookingRecurrence;