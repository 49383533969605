import BaseClass from "./BaseClass";
import Context from "../context/Context";

export default class Speech extends BaseClass {

  audibleDirectionsEnabled = false;
  voice;


  _status = {
    utterance: null
  };

  _useStrategyB = false;

  constructor(props) {
    super(props);
    const ios = /iPhone|iPad|iPod/i.test(navigator.platform);
    //let isMac = (navigator.platform && /MacIntel/.test(navigator.platform));
    if (ios) this._useStrategyB = true;
    this._init();
  }

  cancel() {
    if (this._useStrategyB) this._cancelB();
    else this._cancel();
  }

  _cancel() {
    try {
      const synth = window.speechSynthesis;
      if (synth && synth.speaking) synth.cancel();
    } catch(ex) {
      console.error(ex);
    }
  }

  _cancelB() {
    try {
      const synth = window.speechSynthesis;
      const status = this._status;
      if (synth && status.utterance) {
        synth.cancel();
      }
      status.utterance = null;
    } catch(ex) {
      console.error(ex);
    }
  }

  _init() {
    try {
      if (!window.speechSynthesis) return;
      window.speechSynthesis.onvoiceschanged = ()=> {
        let voices= window.speechSynthesis.getVoices();
        this._initVoice(voices);
      };
      let voices = window.speechSynthesis.getVoices();
      this._initVoice(voices);
    } catch(ex) {
      console.error(ex);
    }
  }

  _initVoice(voices) {
    try {
      if (this.voice || !voices || voices.length === 0) return;

      const kernel = Context.instance.lib.dojo.kernel;
      const locale = (kernel.locale || "en-US").toLowerCase().replace("_","-");
      const langCode = locale.split("-")[0];
      //console.log("Speech::_initVoice voices",voices);

      let exact, partial;
      for(let i=0;i< voices.length;i++){
        let voice = voices[i];
        let lang = voice.lang;
        if (typeof lang === "string") {
          lang = lang.toLowerCase().replace("_","-");
          let voiceLangCode = lang.split("-")[0];
          if (locale === lang) {
            if (!exact || !exact.default) exact = voice;
          }
          if (langCode === voiceLangCode) {
            if (!partial || !partial.default) partial = voice;
          }
        }
      }
      if (exact) {
        //console.log("Speech::_initVoice exact match",kernel.locale,exact);
        this.voice = exact;
      } else if (partial) {
        //console.log("Speech::_initVoice partial match",kernel.locale,partial);
        this.voice = partial;
      } else {
        //console.log("Speech::_initVoice no match",kernel.locale);
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  hasVoice() {
    return !!this.voice;
  }

  speak(textToSpeak){
    var stripAbbrTag = textToSpeak.replace(/(<([^>]+)>)/ig,"");
    if (this._useStrategyB) this._speakB(stripAbbrTag);
    else this._speak(stripAbbrTag);
  }

  _speak(textToSpeak){
    try {
      const synth = window.speechSynthesis;
      const voice = this.voice;
      if (!synth || !voice) return;

      if (typeof textToSpeak === "string" && textToSpeak.length > 0) {
        const utterance = new SpeechSynthesisUtterance(textToSpeak);
        utterance.voice = voice;
        if (synth.speaking) synth.cancel();
        synth.speak(utterance);
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  _speakB(textToSpeak){
    try {
      const synth = window.speechSynthesis;
      const voice = this.voice;
      const status = this._status;
      if (!synth || !voice) return;

      if (typeof textToSpeak === "string" && textToSpeak.length > 0) {
        const utterance = new SpeechSynthesisUtterance(textToSpeak);
        utterance.voice = voice;
        utterance.onend = (event) => {
          status.utterance = null;
        }
        utterance.onerror = (event) => {
          status.utterance = null;
        }
        if (status.utterance) {
          synth.cancel();
        } else {
          status.utterance = utterance;
          synth.speak(utterance);
        }
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  // _speakC(textToSpeak){
  //   try {
  //     const synth = window.speechSynthesis;
  //     const voice = this.voice;
  //     const status = this._status;
  //     if (!synth || !voice) return;
  //     alert("speakB");
  //
  //     //let txt = !!synth.speaking + " " + !!synth.paused + " " + synth.pending + " " + Date.now();
  //     //document.getElementById("tmptxt").value = txt;
  //
  //     if (typeof textToSpeak === "string" && textToSpeak.length > 0) {
  //       const utterance = new SpeechSynthesisUtterance(textToSpeak);
  //
  //       //utterance.voice = voice;
  //       utterance.onend = (event) => {
  //         //alert("onend")
  //         //console.log("Speech utterence.onend",event);
  //         if (status.utterance === utterance) {
  //           status.paused = status.speaking = status.utterance = null;
  //         }
  //       }
  //       utterance.onerror = (event) => {
  //         //alert("onerror")
  //         //console.log("Speech utterence.onerror",event);
  //         if (status.utterance === utterance) {
  //           status.paused = status.speaking = status.utterance = null;
  //         }
  //       }
  //
  //       if (synth.speaking || status.utterance) {
  //          synth.pause();
  //          status.paused = true;
  //          //synth.cancel();
  //          return;
  //       }
  //
  //       if (synth.paused) {
  //          synth.resume();
  //          status.paused = false;
  //       }
  //
  //       if (!status.utterance) {
  //         status.utterance = utterance;
  //         status.speaking = true;
  //         status.paused = false;
  //         synth.speak(utterance);
  //       }
  //
  //       // if (!status.speaking) {
  //       //   status.utterance = utterance;
  //       //   status.speaking = true;
  //       //   synth.speak(utterance);
  //       // } else {
  //       //   synth.cancel();
  //       // }
  //
  //       // if (synth.speaking) {
  //       //   synth.pause();
  //       //   //synth.cancel();
  //       // }
  //       // if (synth.paused) {
  //       //   synth.cancel();
  //       //   synth.resume();
  //       // }
  //
  //       //if (synth.paused) synth.resume();
  //
  //       //synth.speak(utterance);
  //
  //       // if (!synth.speaking) {
  //       //   if (synth.paused) synth.resume();
  //       //   synth.speak(utterance);
  //       // }
  //
  //       //this._speakUtterance(synth,utterance);
  //     }
  //   } catch(ex) {
  //     console.error(ex);
  //   }
  // }

  // _speakUtterance(synth,utterance) {
  //
  //   const promise = new Promise((resolve,reject) => {
  //     // const activeInfo = {
  //     //   promise: promise,
  //     //   utterance: utterance,
  //     //   resolve: resolve,
  //     //   reject: reject
  //     // };
  //     // if (active.)
  //     //
  //     // if (this._)
  //     //
  //     utterance.onend = (event) => {
  //       console.log("Speech: utterance.onend",event);
  //     };
  //     synth.speak(utterance);
  //   });
  //   return promise;
  // }

  // _speakUtterance(synth,utterance) {
  //   clearTimeout(this._waitForCancelTimeout);
  //   if (synth.speaking) {
  //     synth.cancel();
  //     this._waitForCancelTimeout = setTimeout(() => {
  //       if (!synth.speaking) synth.speak(utterance);
  //     },100);
  //   } else {
  //     synth.speak(utterance);
  //   }
  // }

  // ___speak(textToSpeak, locale, voices){
  //     let synth = window.speechSynthesis;
  //     let finalVoice="";
  //
  //     if(voices) finalVoice=this.selectVoice(locale, voices);
  //     console.log("Voice", finalVoice);
  //
  //     if (synth.speaking) {
  //         alert("Still speaking")
  //         return;
  //     }
  //     if (textToSpeak !== '') {
  //     var utterThis = new SpeechSynthesisUtterance(textToSpeak);
  //     utterThis.onend = function (event) {
  //         console.log('SpeechSynthesisUtterance.onend');
  //     }
  //     utterThis.onerror = function (event) {
  //         console.error('SpeechSynthesisUtterance.onerror');
  //     }
  //
  //     try{
  //         if(finalVoice!== "noVoice") utterThis.voice= finalVoice;
  //         else return finalVoice;
  //     }catch(error){
  //         console.log("Voice array might be empty", error);
  //     }
  //
  //     if(!synth.speaking){
  //         synth.speak(utterThis);
  //     }
  //   }
  // }

  // selectVoice(locale, voices){
  //     let selectedVoices=[];
  //     let finalVoice= "noVoice";
  //     let flag = false;
  //
  //     for(let i=0;i< voices.length;i++){
  //         if(voices[i].lang.toLowerCase().localeCompare(locale.toLowerCase())=== 0){
  //             selectedVoices.push(voices[i]);
  //             flag = true;
  //         }
  //     }
  //
  //     console.log("Selected voices array", selectedVoices);
  //     if(flag === false){
  //         for(let i=0;i< voices.length;i++){
  //             if(voices[i].lang.toLowerCase().includes(locale.toLowerCase())){
  //                 selectedVoices.push(voices[i]);
  //                 flag = true;
  //             }
  //         }
  //     }
  //
  //     if(flag === true){
  //         for(let i=0;i<voices.length;i++){
  //             if(voices[i].default === true){
  //                 finalVoice= voices[i];
  //                 return finalVoice;
  //             }
  //         }
  //         finalVoice= voices[0];
  //         console.log("Final Voices", finalVoice);
  //     }else{
  //         console.log("No match found");
  //         return finalVoice;
  //     }
  // }


}
