import React from "react";
import moment from "moment";

import Context from "../../../context/Context";
import DatePicker from "calcite-react/DatePicker";
import {ModalController} from "../../../common/components/Modal";
import * as component from "../../../components/util/component";

export default class DatePopup extends React.Component {

  componentId;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      date: null,
      datePickerFocused: true
    });
  }

  onDateChange = (date) => {
    //console.log("DatePopup.onDateChange",date)
    if (this.props.onDateChange) {
      this.props.onDateChange(date);
    }
    this.setState({
      date: date
    });
  }

  onDateFocusChange = ({focused}) => {
    // this.setState({
    //   datePickerFocused: focused
    // });
  }

  render() {
    let date = this.state.date || this.props.date || null;
    return (
      <DatePicker
        {...this.props}
        id={this.componentId+"_date"}
        date={date}
        onDateChange={this.onDateChange}
        focused={this.state.datePickerFocused}
        onFocusChange={this.onDateFocusChange}
        keepOpenOnDateSelect={true}
        reopenPickerOnClearDates={true}
      />
    );
  }

  static show(item,date,callback) {
    const i18n = Context.instance.i18n;
    let selDate = date, okButton;
    let momentObj;
    if (date) momentObj = moment(date.getTime());
    let content = (
      <div className="i-modal-date-popup">
        <DatePopup date={momentObj}
          onDateChange={dt => {
            if (dt) dt = dt.toDate();
            selDate = dt
            if (okButton) {
              if (selDate) okButton.enable();
              else okButton.disable();
            }
          }}
        />
      </div>
    );
    ModalController.confirm({
      title: i18n.spaceplanner.moveDates.datePopup.caption,
      content: content,
      onMountOKButton: btn => {
        okButton = btn;
        if (okButton) {
          if (selDate) okButton.enable();
          else okButton.disable();
        }
      }
    }).then(result => {
      if (result.ok) {
        if (selDate && selDate !== date) {
          if (callback) {
            callback(selDate);
          }
        }
      }
    });
  }

}
