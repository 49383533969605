import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../datasets/FieldNames";
import ItemReference from "../../aiim/base/ItemReference";
import * as aiimUtil from "../util/aiimUtil";
import * as selectionUtil from "../util/selectionUtil";

export default class RelatedItems extends BaseClass {

  query(subjectItem) {
    const promises = [];
    const subjectSource = subjectItem.getSource();
    const subjectFeature = subjectItem.getFeature();
    const zInfo = Context.getInstance().aiim.getZInfo(subjectSource,subjectFeature);
    const job = {
      subjectSource: subjectSource,
      subjectFeature: subjectFeature,
      levelData: zInfo && zInfo.levelData,
      results: []
    }

    //console.log("subjectSource:",subjectSource.name);
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const sources = Context.getInstance().aiim.datasets.categories.sources;
    sources.forEach(targetSource => {
      if (targetSource.layer2D) {
        //console.log("need to query:",targetSource.name,targetSource.layer2D);
        const targetLayer = targetSource.layer2D;
        // Do not display Events or Reservations in Related Items tab
        if (
          targetLayer.type === "feature" && 
          targetSource.key !== "Events" && 
          targetSource.key !== "Reservations"
        ) {
          let ok = true;
          if (targetSource === subjectSource) {
            if (unitsDataset && targetLayer.xtnAiim &&
                targetLayer.xtnAiim.dataset === unitsDataset) {
              // don't query for units that are related to a unit, adjacency issues
              //console.log("RelatedItems: ignore units",targetSource.name,targetLayer);
              ok = false;
            }
          }
          if (ok) {
            const targetItems = [];

            job.results.push({
              source: targetSource,
              items: targetItems
            })
            const promise = this._queryLayer(job,targetSource,targetLayer,targetItems);
            promises.push(promise);
          }
        }
      }
    });
    if (promises.length === 0) {
      return Promise.resolve(job.results);
    } else {
      return Promise.all(promises).then(list => {
        job.results = job.results.filter(info => {
          return (info.items.length > 0);
        });
        return Promise.resolve(job.results);
      });
    }
  }

  _queryLayer(job,targetSource,targetLayer,targetItems): Promise<any> {
    const subjectFeature = job.subjectFeature;
    const objectIdField = targetLayer.objectIdField;
    const objectId = subjectFeature.attributes[objectIdField];

    let levelPart;
    if (job.levelData) {
      let isFloorAwareMap = Context.instance.aiim.isFloorAwareMap();
      let fld = Context.instance.aiim.getLevelIdField(targetLayer);
      if (!fld) fld = Context.instance.aiim.getLevelIdField(targetSource);
      if (fld) {
        let v = job.levelData.levelId;
        if (fld.type === "string" && typeof v === "string") {
          v = "'"+selectionUtil.escSqlQuote(v)+"'";
        }
        levelPart = "("+fld.name+" = "+v+")";
      } else if (!isFloorAwareMap) {
        levelPart = selectionUtil.makeLevelWhere(job.levelData,targetLayer);
      }
    }

    let where = null;
    const orig = targetLayer.xtnOriginalDefinitionExpression;
    const expr = targetLayer.definitionExpression;
    if (typeof orig === "string" && orig.length > 0) {
      if (orig !== "__none__") where = orig;
    } else if (typeof expr === "string" && expr.length > 0) {
      where = expr;
    }
    if (typeof levelPart === "string" && levelPart.length > 0) {
      if (where) where = "(" + where + ") AND (" + levelPart + ")";
      else where = levelPart;
    }

    if (targetSource.cimCategory && targetSource.cimCategory.filterExpression) {
      let exp = targetSource.cimCategory.filterExpression;
      if (!where) {
        where = exp;
      } else {
        where = "("+exp+") AND ("+where+")";
      }
    }

    if (!where) where = "1=1";

    const url = Context.checkMixedContent(targetLayer.url) + "/" + targetLayer.layerId;
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    const view = Context.getInstance().views.mapView;
    if (view) query.outSpatialReference = view.spatialReference;
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.multipatchOption = "xyFootprint";
    query.where = where;
    query.orderByFields = [targetSource.displayField]; // TODO order events by date
    //console.log("RelatedItems::query",targetLayer.title,query.where,query);
    if (subjectFeature.geometry.type === "point") {
      //query.distance = 100; // TODO?
      //query.units = "meters";
    }
    query.geometry = subjectFeature.geometry;
    query.spatialRelationship = "intersects";
    if(targetSource && targetSource.isAiimFacilities()) {
      query.where = "1=1";
    }
    const promise = task.execute(query);
    return promise.then(result => {
      if (result && result.features && result.features.length > 0) {
        result.features.forEach(resultFeature => {
          let ok = true;
          if (targetSource === job.subjectSource) {
            ok = (objectId !== resultFeature.attributes[objectIdField]);
          }
          if (ok) {
            const sr = targetSource.makeSearchResult(null,resultFeature);
            const ref = new ItemReference();
            ref.fromSearchResult(targetSource.key,sr);
            targetItems.push(ref);
            const subType = targetSource.findSubTypeByFeature(resultFeature);
            if (subType) {
              //@ts-ignore
              ref.subType = subType;
            }
          }
        });
      }
    });
  }

}
