import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// aiim.base
import ItemReference from "../../../../../aiim/base/ItemReference";

// aiim.datasets
import FieldNames from "../../../../../aiim/datasets/FieldNames";

// aiim.util
import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";

// common.components
import { ModalController } from "../../../../../common/components/Modal";

// components.util
import * as component from "../../../../util/component";
import Icons from "../../../../util/Icons";

// components.main.More.Actions.BookWorkspace
import BookingConfirmed from "./BookingConfirmed";
import BookingDetails, { ICheckAvailabilityOptions } from "./BookingDetails";

// components.main.More.Actions.BookWorkspace.WorkspaceReservation
import {
  getLoggedInUser,
  login,
  getAccessToken
} from "./WorkspaceReservation/OfficeHotelingInterface";
import {
  getArea,
  getHotelBookingSystem,
  getHotelBookingSystemType
} from "./WorkspaceReservation/workspaceReservationUtil";

// context
import Context from "../../../../../context/Context";
import Topic from "../../../../../context/Topic";

// redux
import Rdx from "../../../../../redux/Rdx";

// Calcite
import ServicesIcon from "calcite-ui-icons-react/ServicesIcon";
import * as validateUtil from "./validateUtil";
import { Moment } from "moment";
import { IRecurrenceOptions } from "./BookingRecurrence";
import { IBookingParams, IBookingTask, IBookWorkspaceResults, IReserveForInfo } from "./WorkspaceReservation/BookingSystem";
import Loader from 'calcite-react/Loader/Loader';
import { BookingType } from "../../../../../util/calendarUtil";


const CardCSS = {
  main: "i-available-unit",
  description: "i-description",
  title: "i-title",
  unitInfo: "i-unit-info",
  spaceType: "i-space-type",
  hotelInfo: "i-hotel-info"
}
interface IAvailableUnitCardProps {
  allDay?: boolean,
  description: string,
  endDate?: Date,
  endTime?: Moment,
  hasEmail: boolean,
  key: string,
  name: string,
  recurrence?: IRecurrenceOptions,
  reserveForInfo?: IReserveForInfo,
  reserveForSelf?: boolean,
  spaceType: string,
  startDate?: Date,
  startTime?: Moment,
  unit: __esri.Graphic,
  checkIn?: string,
  checkOut?: string
}
interface IAvailableUnitCardState {
  area: __esri.Graphic,
  areaName: string,
  disableBook: boolean,
  facilityName: string,
  levelName: string,
  unitName: string,
  scheduleEmail: string,
  spaceType: string,
  capacity: number,
  occupancy: number
}
export default class AvailableUnitCard extends React.Component<IAvailableUnitCardProps, IAvailableUnitCardState> {
  _componentId;
  _itemRef;
  bookingSystem = getHotelBookingSystem();

  constructor(props) {
    super(props);
    const { unit } = this.props;
    const attributes = unit.attributes;

    const spaceType = getAttributeValue(attributes, FieldNames.UNITS_USE_TYPE);
    const unitName = getAttributeValue(attributes, FieldNames.NAME);
    const scheduleEmail = getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL);
    let levelName = getAttributeValue(attributes, FieldNames.LEVEL_NAME);
    let facilityName = getAttributeValue(attributes, FieldNames.FACILITY_NAME);
    const unitsDataset = Context.instance.aiim.datasets.units;
    if (unitsDataset) {
      const levelData = unitsDataset.getLevelData(unit);
      if (levelData && levelData.levelName) levelName = levelData.levelName;
      if (levelData && levelData.facilityName) facilityName = levelData.facilityName;
    }

    this.state = component.newState({
      areaName: null,
      spaceType,
      unitName,
      scheduleEmail,
      levelName,
      facilityName,
      disableBook: false,
      capacity: null,
      occupancy: null
    });
    this._componentId = component.nextId("hotel-item-")
    this._itemRef = React.createRef()
  }

  async componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus()
        }
      })
    ])
    // const unit = this.props && this.props.unit;
    // const unitId = getAttributeValue(unit.attributes, FieldNames.UNIT_ID);
    // const startDate = this.props && this.props.checkIn;
    // const endDate = this.props && this.props.checkOut;
    // const params = {
    //   recurrence: this.props && this.props.recurrence
    // }
    // const bookingSystem = this.bookingSystem;
    // if (bookingSystem?.type === "esri" && "checkOccupancy" in bookingSystem) {
    //   const system = bookingSystem as EsriReservationSchema;
    //   const result = await system.checkOccupancy([unitId], 
    //     startDate, endDate, [], params);
    //   const occupancy = result && result[0] && result[0].occupancy;
    //   const capacity = result && result[0] && result[0].unitCapacity;
    //   this.setState({
    //     occupancy: occupancy,
    //     capacity: capacity
    //   })
    // }
  }

  getFeature() {
    return this.props.unit;
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  onClick = () => {
    if (this.state.disableBook) return;
    const source = Context.instance.aiim.datasets.units.getSource()
    const feature = this.props.unit
    const item = new ItemReference()
    item.fromSearchResult(source.key, { feature: feature, name: source.getTitle(feature) })
    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      isBookWorkspacePanel: true,
      componentId: this._componentId
    })
    if (!this.props.hasEmail) {
      const i18n = Context.instance.i18n
      let msg = i18n.more.bookWorkspace.missingEmail
      msg = msg.replace("{unitName}", this.props.name)
      ModalController.showMessage(msg)
    }
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;

    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this.onClick();
    }
  }

  makeEndDateTime =(endTime, endDate, allDay, forSubmit)=> {
    return validateUtil.makeEndDateTime(endTime, endDate, allDay, forSubmit);
  }
  
  makeStartDateTime =(startTime, startDate, allDay)=> {
    return validateUtil.makeStartDateTime(startTime, startDate, allDay);
  }

  removeLoaderNode=(node)=> {
    if (node && node.parentNode) {
      node.parentNode.removeChild(node);
      ReactDOM.unmountComponentAtNode(node)
    }
  }

  bookClicked = async (event) => {
    if(event) event.preventDefault();
    if(event) event.stopPropagation();
    this.setState({disableBook: true});
    let checkInDate, checkOutDate;

    const unit = this.props.unit;
    const attributes = unit.attributes;
    const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);
    const bookingSystem = this.bookingSystem;
    let user = getLoggedInUser(bookingSystem)

    if (this.props && this.props.startTime && this.props.startDate && this.props.endTime && this.props.endDate) {
      checkInDate = this.makeStartDateTime(this.props.startTime, this.props.startDate, this.props.allDay);
      checkOutDate = this.makeEndDateTime(this.props.endTime, this.props.endDate, this.props.allDay, true);
    }

    const node = document.createElement("div");
    document.body.appendChild(node);
    ReactDOM.render((<Loader sizeRatio={1} 
      style={{top: '50%',
      position: 'absolute',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'}}/> ), node);

    const task: IBookingTask = {
      bookingType: 'hotel',
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      allDay: this.props && this.props.allDay,
      unit: unit,
      unitName: this.state.unitName,
      scheduleEmail: this.state.scheduleEmail,
      bookingSystem: this.bookingSystem,
      options: {
        recurrence: this.props.recurrence,
        reserveForInfo: this.props.reserveForInfo
      }
    }
    
    let response;
    try {
      response = await validateUtil.verify(task);
    } catch(ex) {
      throw(ex)
    } finally {
      this.removeLoaderNode(node);
    }

    if (!response || !response.ok) {
      this.setState({disableBook: false});
      return;
    }

    if (user) {
      return getAccessToken(bookingSystem)
      .then(() => {
        return getArea(areaId);
      })
      .then((area)=> {
        const areaName = area && getAttributeValue(area.attributes, FieldNames.AREA_NAME);
        this.setState({
          area,
          areaName : areaName
        }, () => {
          this.renderBookingDetails();
          this.setState({disableBook: false});
        });
      })
      .catch(error =>{
        console.log("Error in booking workspace.", error);
        this.setState({disableBook: false});
      })
    } else {
      return login(bookingSystem)
      .then((status)=>{
        if(status === "Success") return getArea(areaId);
      })
      .then((area)=> {
        const areaName = area && getAttributeValue(area.attributes, FieldNames.AREA_NAME);
        this.setState({
          area,
          areaName : areaName
        }, () => {
          this.renderBookingDetails();
          this.setState({disableBook: false});
        });
      })
      .catch(error =>{
        console.error("Error in booking workspace.", error);
        this.setState({disableBook: false});
      })
    }
  }

  renderBookingDetails(){
    const node = document.createElement("div");
    document.body.appendChild(node);
    const spaceType = this.state.spaceType;
    const areaName = this.state.areaName;
    const area = this.state.area;
    const unit = this.getFeature();
    const attributes = unit.attributes;
    const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    ReactDOM.render((
      <Provider store={Rdx.store}>
        <BookingDetails
          spaceType={spaceType}
          area={area}
          areaName={areaName}
          areaId={areaId}
          scheduleEmail={this.state.scheduleEmail}
          unitName={this.state.unitName}
          levelName={this.state.levelName}
          facilityName={this.state.facilityName}
          checkAvailability={this.checkAvailability}
          isBookWorkspacePanel={true}
          closePopup={onClose}
          unitFeature={this.getFeature()}
          reserveForInfo={this.props.reserveForInfo}
          reserveForSelf={this.props.reserveForSelf}
        />
      </Provider>
    ), node);
  }

  renderBookingConfirmed(data: IBookWorkspaceResults, params: IBookingParams, task: IBookingTask) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };
    const recurrence = params.recurrence;    
    let bookingType: BookingType = "single";
    if (recurrence?.enabled) {
      bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
    }
    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={getHotelBookingSystemType()}
        bookingType={bookingType}
        unitName={task.unitName}
        levelName={task.levelName}
        facilityName={task.facilityName}
        closePopup={onClose}
        data={data}
        unit={params && params.unit}
        params={params}
      />
    ), node);
  }

  checkAvailability = (options: ICheckAvailabilityOptions) => {
    const units = Context.instance.aiim.datasets.units;
    const item = new ItemReference();
    const unit = this.getFeature();

    item._fromFeature(units.getSource().key, this.props.unit);

    const task: IBookingTask = {
      checkInDate: options.checkInDate,
      checkOutDate: options.checkOutDate,
      allDay: options.allDay,
      options: {
        ...options,
        recurrence: options.recurrence
      },
      unit: unit,
      unitName: this.state.unitName,
      levelName: this.state.levelName,
      facilityName: this.state.facilityName,
      scheduleEmail: this.state.scheduleEmail,
      bookingSystem: this.bookingSystem,
      bookingType: "hotel",
      item: item,
      renderBookingConfirmed: this.renderBookingConfirmed,
      retry: () => {
        this.checkAvailability(options);
      }
    }
    return validateUtil.verifyAndBook(task);
  }

  renderOccupancy() {
    const type = getHotelBookingSystemType();
    const i18n = Context.getInstance().i18n;
    if (type === "esri") {
      const capacity = this.state.capacity;
      const occupancy = this.state.occupancy;
      const available = capacity - occupancy;
      const capacityStr = i18n.meetingRooms.capacityPattern.replace("{num}", capacity);
      const availableStr = i18n.more.bookWorkspace.tabs.available;
      return (
        <div className={CardCSS.spaceType}>
          {capacityStr} {availableStr} {available}
        </div>
      )
    }
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const mainCSS = this.props.hasEmail ? CardCSS.main : CardCSS.main + " no-email"
    const pinIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#pin-16";

    return (
      <div
        ref={this._itemRef}
        role="button"
        className={mainCSS}
        onClick={this.onClick}
        tabIndex={0}
        onKeyDown={this._onKeyDown}
      >
        <div className={CardCSS.description}>
          <div className={CardCSS.title}>{this.props.name}</div>
          <div className={CardCSS.unitInfo}>
            <svg><use href={pinIcon}></use></svg>
            <div>{this.props.description}</div>
          </div>
          <div className={CardCSS.spaceType}>
            {Icons.desk()}
            <div>{this.props.spaceType}</div>
          </div>
          {/* {this.renderOccupancy()} */}
        </div>
        <button className="i-button-clear" onClick={this.bookClicked} disabled={this.state.disableBook}>
          <ServicesIcon />
          {i18n.more.bookWorkspace.bookButton}
        </button>
      </div>
    )
  }
}
