import React, { RefObject } from "react";

import Context from "../../../../context/Context";

import { debounce } from "../../../miniapps/support/debounceUtil";

import "@esri/calcite-components/dist/components/calcite-input";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-popover";
import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteInput, CalciteButton } from "@esri/calcite-components-react";
import { CalciteInputCustomEvent } from "@esri/calcite-components";

interface Props {
  onRotate: (angle: number, geom: __esri.Geometry) => void;
  onOpen?: (comp:RotateAngle) => void;
  onClose?: (angle: number) => void;
  geometry: __esri.Geometry;
  copyOriginal?: boolean;
}
interface State {
  rotateAngle: number;
}

export default class RotateAngle extends React.Component<Props, State> {

  geometry: __esri.Geometry;
  input:RefObject<HTMLCalciteInputElement>;

  constructor (props) {
    super(props);
    this.state = {
      rotateAngle: 0
    };
    this.input = React.createRef<HTMLCalciteInputElement>();
  }

  componentDidMount () {
    console.debug("componentDidMount");
    this.props.onOpen && this.props.onOpen(this);
    setTimeout(()=>this.input?.current?.focus(), 300);
  }

  componentWillUnmount () {
    //this.props.onRotate(this.state.rotateAngle, newGeom);
    this.props.onClose && this.props.onClose(this.state.rotateAngle);
  }

  onRotateAngleChange = debounce((e: CalciteInputCustomEvent<number>) => {
    const angleF = parseFloat(e && e.target && e.target.value);
    if(isNaN(angleF))
      return;
    
    let angle = Math.abs((Math.round(angleF*100))/100);
    angle = Math.min(angle, 360) * (angleF<0 ? -1 : 1);
    if (angleF===this.state.rotateAngle)
      return;
    
    const ge = Context.instance.lib.esri.geometryEngine;
    const newGeom = ge.rotate(this.geometry || this.props.geometry, -angle);
    this.setState({ rotateAngle: angle }, () => {
      this.props.onRotate(this.state.rotateAngle, newGeom);
    });
  }, 500);

  onReset = () => {
    this.setState({ rotateAngle: 0 }, () => {
      this.props.onRotate(0, this.geometry || this.props.geometry);
    });
  };

  render () {
    const i18n = Context.instance.i18n;
    return (
      <div style={{padding:"8px",margin:"0"}}>
        <span>{i18n.editor.rotateAngle}</span>
        <div style={{display:"flex",alignItems:"center"}}>
          <CalciteInput
            ref={this.input}
            scale="s"
            style={{width:"7rem",marginRight:"5px"}}
            type="number"
            value={""+this.state.rotateAngle}
            min={-360}
            max={360}
            inputMode="decimal"
            onCalciteInputInput={this.onRotateAngleChange}
          >
            {this.state.rotateAngle}
          </CalciteInput>
          <CalciteButton appearance="transparent" onClick={this.onReset} scale="s">
            Reset
          </CalciteButton>
        </div>
      </div>
    );
  }
}