import React from "react";

import {ListItemSubtitle as CalciteListItemSubtitle} from "calcite-react/List";

export default class ListItemSubtitle extends React.Component {

  render() {
    return (
      <CalciteListItemSubtitle {...this.props}>
        {this.props.children}
      </CalciteListItemSubtitle>
    );
  }

}
