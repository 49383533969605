import FieldNames from "../../../aiim/datasets/FieldNames";
import Context from "../../../context/Context";


export function getField(fields,fieldName) {
  let found;
  if (fields && fields.length > 0 && fieldName) {
    const lc = fieldName.toLowerCase();
    fields.some(field => {
      if (lc === field.name.toLowerCase()) found = field;
      return !!found;
    });
  }
  return found;
}

export function getSortables(source) {
  const list = [];
  const layer = source && source.layer2D;
  const fields = layer && layer.fields;
  const unitField = Context.instance.aiim.getUnitName();  

  if (fields && fields.length > 0) {
    /*
    "small-integer"|"integer"|"single"|"double"|"long"|"string"|"date"|"oid"|
    "geometry"|"blob"|"raster"|"guid"|"global-id"|"xml"
     */
    const types = ["string"];
    let names = null;
    if (source.isAiimPeople()) {
      names = [FieldNames.PEOPLE_FULLNAME.toLowerCase(), 
               FieldNames.PEOPLE_ORG_LEVEL_1.toLowerCase(),
               FieldNames.PEOPLE_ORG_LEVEL_2.toLowerCase(),
               FieldNames.PEOPLE_JOB_TITLE.toLowerCase(),
               FieldNames.PEOPLE_START_DATE.toLowerCase()];
    } else if (source.isAiimUnits()) {
      names = [unitField.toLowerCase(),
               FieldNames.FACILITY_NAME.toLowerCase(),
               FieldNames.LEVEL_NUMBER.toLowerCase(),
               FieldNames.SITE_NAME.toLowerCase()
              ];
    }
    types.push("small-integer","integer","long","single","double","date");
    fields.forEach(field => {
      let ok = false;
      if (types.indexOf(field.type) !== -1) {
        ok = true;
        if (names && names.length > 0 ) {
          if (names.indexOf(field.name.toLowerCase()) === -1) {
            ok = false;
          }
        }
      }
      if (ok) {
        list.push({
          canReverseSort: true,
          defaultSortDir: field.type === "date"? "desc": "asc",
          field: field,
          label: field.alias || field.name,
          value: field.name
        })
      }
    });
    list.sort((a,b) => {
      if (typeof a.label === "string" && typeof (b.label === "string")) {
        return (a.label.localeCompare(b.label));
      }
      return 0;
    });
  }
  return list;
}
