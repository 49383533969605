import React, {createRef} from "react";
import { connect } from "react-redux";

import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import { hideReservationsByFilter } from "../More/Actions/BookWorkspace/WorkspaceReservation/reservationsLayerUtil";
import { addDuration, getTopOfHour } from "../Events/dateUtil";
import { resetMeetingCriteria } from "../MeetingRooms/MeetingRoomUtil";

const CSS = {
  panelHeader: "i-panel-header",
  panelHeaderCaption: "i-panel-header-caption",
  panelHeaderTools: "i-panel-header-tools",
  captionSection: "i-category-caption-section",
  caption: "i-category-caption",
  subcaption: "i-category-subcaption",
  backButton: "i-button-back i-icon-button",
  expandButton: "i-button-expand i-icon-button",
  closeButton: "i-button-close i-icon-button",
  sidebarPanelOpen: "i-sidebar-panel-open"
};

class SidebarHeader extends React.Component {

  closeButtonRef = createRef();
  titleRef = createRef();

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.backClicked = this.backClicked.bind(this);
    this.closeButtonClicked = this.closeButtonClicked.bind(this);
    this.hide = this.hide.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  backClicked(event) {
    if (this.props.backHandler) {
      this.props.backHandler(event);
    } else if (this.isMorePanel()) {
      Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY, "more");
      document.body.classList.add("i-sidebar-panel-open");
      Topic.publish(Topic.SidebarButtonClicked,{sidebarKey: "more"});
      Context.instance.views.toggleClickHandlers("resume");
    }
  }

  closeButtonClicked(event,ignoreCallback) {
    this.hide();
    if (!ignoreCallback) {
      if (this.props.onCloseButtonClicked) this.props.onCloseButtonClicked(event);
    }
    Topic.publish(Topic.SetSidebarButtonFocus,{sidebarKey: this.props.sidebarKey});
  }

  componentDidMount() {
    if (this._isActive()) this._focus();

    component.own(this,[
      Topic.subscribe(Topic.CloseSidebarPanel,params => {
        if (this._isActive()) this.closeButtonClicked(null,true)
      }),
      Topic.subscribe(Topic.SidebarButtonClicked,params => {
        if (params && params.sidebarKey && params.sidebarKey === this.props.sidebarKey) {
          setTimeout(() => {
            this._focus();
          },200)
        }
      })
    ]);
  }

  componentDidUpdate() {
    if (this._isActive()) this._focus()
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _focus() {
    const title = this.titleRef && this.titleRef.current;
    if (title && title.focus) title.focus();
  }

  hide() {
    Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,null);
    document.body.classList.remove(CSS.sidebarPanelOpen);
    Context.instance.views.toggleClickHandlers("resume");
    hideReservationsByFilter();
    if (this.props.sidebarKey === "meetingRooms") {
      resetMeetingCriteria();
    }
  }

  _isActive() {
    const key = Rdx.getValue(null,Rdx.Keys.SIDEBAR_ACTIVE_KEY);
    return (key && key === this.props.sidebarKey);
  }

  isMorePanel() {
    let keys = Context.instance.session.morePanelKeys || [];
    return (keys.indexOf(this.props.sidebarKey) !== -1);
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const hashref = "#";
    const closeAriaLabel = this.props.closeAriaLabel || i18n.general.close;

    const expanded = this.props.rdxSidebarPanelsExpanded;
    const expandTip = expanded ? i18n.general.collapse : i18n.general.expand;
    let expandIcon = expanded ? Icons.panelCollapse() : Icons.panelExpand();
    if (Context.getInstance().uiMode.isRtl) {
      expandIcon = expanded ? Icons.panelExpand() : Icons.panelCollapse();
    }

    const caption = this.props.caption;
    const subCaption = this.props.subCaption;
    let captionNode = null, subCaptionNode = null
    if (typeof subCaption === "string" && subCaption.length > 0) {
      subCaptionNode = (
        <span key={CSS.subcaption} className={CSS.subcaption}
          role="heading" aria-level="3" tabIndex="0" ref={this.titleRef}>{subCaption}</span>
      );
    }

    if (this.props.showBack || this.isMorePanel()) {

      captionNode = (
        <div className={CSS.panelHeaderCaption}>
          <a href={hashref} className={CSS.backButton} onClick={this.backClicked}
            role="button" tabIndex="0" title={i18n.general.back}>
            {Icons.back()}
          </a>
          <span key={CSS.captionSection} className={CSS.captionSection}>
            <span key={CSS.caption} className={CSS.caption}
              role="heading" aria-level="2" tabIndex="0" ref={this.titleRef}>{caption}</span>
            {subCaptionNode}
          </span>
        </div>
      );
    } else {
      captionNode = (<div className={CSS.panelHeaderCaption} role="heading"
        tabIndex="0" ref={this.titleRef}>{caption}</div>);
    }

    return (
      <div className={CSS.panelHeader}>
        {captionNode}
        <div className={CSS.panelHeaderTools}>
          <a className={CSS.expandButton} href={hashref}
            role="button" title={expandTip}
            onClick={this.toggleExpanded}>
            {expandIcon}
          </a>
          <button className={CSS.closeButton} href={hashref} tabIndex="0"
            title={i18n.general.close}
            aria-label={closeAriaLabel}
            onClick={this.closeButtonClicked}
            ref={this.closeButtonRef}>
            {Icons.close()}
          </button>
          {/* <button className={CSS.closeButton} href={hashref}
            role="button" title={i18n.general.close}
            onClick={this.closeButtonClicked}>{Icons.close()}</button> */}
        </div>
      </div>
    );
  }

  toggleExpanded() {
    const expanded = this.props.rdxSidebarPanelsExpanded;
    Rdx.setValue(this,Rdx.Keys.SIDEBAR_PANELS_EXPANDED,!expanded);
  }

}

const mapStateToProps = (state) => {
  return {
    rdxSidebarPanelsExpanded: Rdx.getValue(state,Rdx.Keys.SIDEBAR_PANELS_EXPANDED)
  }
}

export default connect(mapStateToProps)(SidebarHeader);
