import Context from "../context/Context";

export function getHelperService(name: string): Record<string, any> & { url: string } {
  const portal: __esri.Portal = Context.instance.portal;
  return portal && portal.helperServices && portal.helperServices[name];
}

export function getGeometryServiceUrl() {
  const service = getHelperService("geometry");
  return service && service.url;
}

export async function project(geometry: __esri.Geometry, outSR: __esri.SpatialReference) {
  if (!geometry || !outSR) return Promise.resolve(geometry)
  const promise = new Promise<__esri.Geometry>((resolve,reject) => {
    const lib = Context.instance.lib;
    const webMercatorUtils: __esri.webMercatorUtils = lib.esri.webMercatorUtils;
    const inSR = geometry && geometry.spatialReference;
    if (inSR.equals(outSR)) {
      resolve(geometry);
      return;
    }
    if (inSR.isWGS84 && outSR.isWebMercator) {
      const p = webMercatorUtils.geographicToWebMercator(geometry);
      resolve(p);
      return;
    }
    if (inSR.isWebMercator && outSR.isWGS84) {
      const p = webMercatorUtils.webMercatorToGeographic(geometry);
      resolve(p);
      return;
    }
    const url = getGeometryServiceUrl();
    if (!url) {
      reject(new Error("No geometry service url"));
      return;
    }
    const service: __esri.geometryService = lib.esri.geometryService;
    const params: __esri.ProjectParameters = new lib.esri.ProjectParameters({
      geometries: [geometry],
      outSpatialReference: outSR
    });
    service.project(url, params).then((projected) => {
      resolve(projected[0]);
    }).catch(ex => {
      reject(ex);
    });

  });
  return promise;
}

export function projectToView(geometry: __esri.Geometry, view: __esri.View) {
  return project(geometry, view.spatialReference);
}

/** Calculates the area of a polygon using the specified units.
 * @param polygon - The polygon for which to calculate area.
 * @param [areaUnit] - Units in which area will be calculated.
 */
export async function calculateArea(
  polygon: __esri.Geometry,
  areaUnit: __esri.AreasAndLengthsParameters["areaUnit"] = "square-feet"
) {
  if (polygon && polygon.type === "polygon") {
    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const isWebMercator = polygon.spatialReference.isWebMercator;
    const isGeographic = polygon.spatialReference.isGeographic;
    const isWGS84 = polygon.spatialReference.isWGS84;
    const simple = ge.isSimple(polygon) ? polygon : ge.simplify(polygon);
    if (!simple) {
      console.warn("Empty geometry while calculating area",polygon);
      return 0;
      //throw new Error("Empty geometry");
    }

    if (isWebMercator || !isGeographic) {
      return ge.planarArea(<__esri.Polygon> simple, areaUnit);
    } else if (isWGS84) {
      return ge.geodesicArea(<__esri.Polygon>simple, areaUnit);
    } else {
      const gs: __esri.geometryService = Context.instance.lib.esri.geometryService;
      const url = getGeometryServiceUrl();
      if (!url) {
        throw new Error("No geometry service url.");
      }
      const calculationType = isWebMercator || isWGS84 ? "geodesic" : "preserve-shape";
      const params: __esri.AreasAndLengthsParameters = new Context.instance.lib.esri.AreasAndLengthsParameters({
        areaUnit,
        calculationType,
        polygons: [simple]
      });
      const results: { areas: number[], lengths: number[] } = await gs.areasAndLengths(url, params)
        .catch(e => { throw new Error(e) });
      return results?.areas?.[0];
    }
  } else {
    throw new Error("Unable to calculate area: invalid geometry.");
  }
}

/** Calculates the length of a polyline using the specified units.
 * @param polyline - The polyline for which to calculate distance
 * @param [lengthUnit] - Units in which length will be calculated.
 */
export async function calculateLength(
  polyline: __esri.Geometry,
  lengthUnit: __esri.LinearUnits = "feet"
) {
  if (polyline && polyline.type === "polyline") {
    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const isWebMercator = polyline.spatialReference.isWebMercator;
    const isGeographic = polyline.spatialReference.isGeographic;
    const isWGS84 = polyline.spatialReference.isWGS84;

    if (isWebMercator || !isGeographic) {
      return ge.planarLength(<__esri.Polyline>polyline, lengthUnit);
    } else if (isWGS84) {
      return ge.geodesicLength(<__esri.Polyline>polyline, lengthUnit);
    } else {
      const gs: __esri.geometryService = Context.instance.lib.esri.geometryService;
      const url = getGeometryServiceUrl();
      if (!url) {
        throw new Error("No geometry service url.");
      }
      const calculationType = isWebMercator || isWGS84 ? "geodesic" : "preserve-shape";
      const params: __esri.AreasAndLengthsParameters = new Context.instance.lib.esri.AreasAndLengthsParameters({
        lengthUnit,
        calculationType,
        polylines: [polyline]
      });
      const results: { lengths: number[] } = await gs.areasAndLengths(url, params)
        .catch(e => { throw new Error(e) });
      return results?.lengths?.[0];
    }
  } else {
    throw new Error("Unable to calculate length: invalid geometry.");
  }
}
/** Returns the buffer(s) of the input geometry or geometries.
 * @param geometries 
 * @param distances 
 * @param unit 
 * @param unionResults 
 * @returns 
 */
export async function buffer(
  geometries: __esri.Geometry | __esri.Geometry[],
  distances: number | number[],
  unit: __esri.LinearUnits = "feet",
  unionResults: boolean = false
) {
  if ((Array.isArray(geometries) && geometries.length > 0) || geometries != null) {
    const ge: __esri.geometryEngine = Context.instance.lib.esri.geometryEngine;
    const sr = Array.isArray(geometries) && geometries.length > 0
      ? geometries[0].spatialReference
      : (geometries as __esri.Geometry)?.spatialReference;
    const isWebMercator = sr?.isWebMercator;
    const isGeographic = sr?.isGeographic;
    const isWGS84 = sr?.isWGS84;

    if (isWebMercator || isWGS84) {
      return ge.geodesicBuffer(geometries, distances, unit, unionResults);
    } else if (!isGeographic) {
      return ge.buffer(geometries, distances, unit, unionResults);
    } else {
      const gs: __esri.geometryService = Context.instance.lib.esri.geometryService;
      const url = getGeometryServiceUrl();
      if (!url) {
        throw new Error("No geometry service url.");
      }
      const geodesic = isWebMercator || isWGS84 ? true : false;
      const params: __esri.BufferParameters = new Context.instance.lib.esri.BufferParameters({
        distances,
        geometries,
        geodesic,
        unit,
        unionResults
      });
      const results: __esri.Polygon[] = await gs.buffer(url, params)
        .catch(e => { throw new Error(e) });
      return results;
    }
  } else {
    throw new Error("Unable to buffer: invalid geometry.");
  }
}
