import Context from "../../../../context/Context";
import * as mapUtil from "../../../base/mapUtil";

export function bufferPolyline(polyline,snapPoly,meters,z) {
  if (polyline.paths.length !== 1) {
    console.log("bufferPolyline - unexpected number of paths in polyline, should be 1", polyline)
  }
  const view = Context.instance.views.activeView;
  const ge = Context.instance.lib.esri.geometryEngine;
  const srinfo = ge.extendedSpatialReferenceInfo(view.spatialReference);

  let pathOut = [];
  const path = polyline.paths[0].slice();
  let last;
  path.forEach((coords,coordsIndex) => {
    let ok = true;
    if (last) {
      if (last[0] === coords[0] && last[1] === coords[1]) {
        ok = false;
      }
      if (ok && (coordsIndex === (path.length - 1)) && (path.length > 2)) {
        // ignore the second last point if it is too close to the last point (double-click to end issues)
        // 9001 = meters, 9003 = survery foot
        if ((srinfo.unitID === 9001) || (srinfo.unitID === 9003)) {
          const el = document.getElementById("sp-view-container");
          const rect = el.getBoundingClientRect();
          const mapUnitsPerPixel = (view.extent.width / rect.width);
          const minD = mapUnitsPerPixel * 10;
          const dx = coords[0] - last[0];
          const dy = coords[1] - last[1];
          const d = Math.sqrt((dx*dx)+(dy*dy));
          if (d < minD) {
            ok = false;
            last[0] = coords[0];
            last[1] = coords[1];
          }
        }
      }
    }
    if (ok) {
      pathOut.push(coords);
      last = coords;
    }
  });

  let snapEndpointA, snapEndpointB;
  if (snapPoly && pathOut.length > 1) {
    const first = pathOut[0];
    const last = pathOut[pathOut.length - 1];
    const tol = 0.1;
    snapEndpointA = makePerpendicularSnapEndpoint(snapPoly,first[0],first[1],tol);
    snapEndpointB = makePerpendicularSnapEndpoint(snapPoly,last[0],last[1],tol);
  }

  const pathIn = pathOut.slice().reverse();
  const newPath = [];

  const adjustEnd = (coords,coordsFrom,coordsTo,dir,isFirst,isLast) => {
    // snap buffered end to the snap poly
    let adjust = false;
    if (dir === "out") {
      adjust = (isFirst && snapEndpointA) || (isLast && snapEndpointB);
    } else if (dir === "in") {
      adjust = (isFirst && snapEndpointB) || (isLast && snapEndpointA);
    }
    if (adjust && snapPoly) {
      const snapEndpoint = makeSnapEndpoint(snapPoly,coordsFrom,coordsTo,meters);
      if (snapEndpoint) {
        return [snapEndpoint.x,snapEndpoint.y,z];
      }
    }
    return coords;
  }

  const dropPerp = (A,B,reversed) => {
    let dx , dy;
    let len = meters;
    let rise = B[1] - A[1];
    let run = B[0] - A[0];
    let slope = rise / run;

    if (slope === 0) {
      dx = 0;
      dy = len;
      if (run > 0 && !reversed) dy = -len;
      if (run > 0 && reversed) dy = -len;
    } else if (slope === Number.POSITIVE_INFINITY) {
      dx = len;
      dy = 0;
    } else if (slope === Number.NEGATIVE_INFINITY) {
      dx = -len;
      dy = 0;
    } else {
      if (isNaN(slope)) return; // @todo
      if (rise < 0 && run < 0) {
        reversed = !reversed;
      } else if (rise > 0 && run < 0) {
      } else if (rise < 0 && run > 0) {
        reversed = !reversed;
      }
      let perpSlope = -1 / slope; 
      //const yint = B[1] - (slope * B[0]);
      //const perpYint = B[1] - (perpSlope * B[0]); 
      dx = (len * Math.sqrt(1 / (1 + Math.pow(perpSlope,2)) ));
      dy = (perpSlope * len * Math.sqrt(1 / (1 + Math.pow(perpSlope,2)) ));
    }

    //console.log("slope",slope,"rise",rise,"run",run,"reversed",reversed)
    //console.log("dx",dx,"dy",dy)

    let x = B[0] - dx;
    let y = B[1] - dy; 
    if (reversed) {
      x = B[0] + dx;
      y = B[1] + dy; 
    }
    return [x,y,z];
  }

  let tmpGraphicsLayer;
  //tmpGraphicsLayer = mapUtil.ensureGraphicsLayer(view,"tmp_vector-util"); // for debug
  if (tmpGraphicsLayer) tmpGraphicsLayer.graphics.removeAll();
  const tmpGfx: any = [];
  const tmpColors = ["red","green","blue","orange","yellow","cyan","purple"];
  const tmpPointSymbol = () => {
    return {
      type: "simple-marker",
      style: "circle",
      size: 9,
      color: [255, 255, 255],
      outline: {
      color: [50, 50, 50],
        width: 1
      }
    };
  }
  const tmpAddPoint = (coords,color?) => {
    if (!tmpGraphicsLayer) return;
    const sym = tmpPointSymbol();
    const g = new Context.instance.lib.esri.Graphic({
      geometry: new Context.instance.lib.esri.Point({
        x: coords[0],
        y: coords[1],
        z: 0,
        spatialReference: view.spatialReference
      }),
      symbol: sym
    })
    if (color) g.symbol.color = color;
    else if (tmpColors[tmpGfx.length]) g.symbol.color = tmpColors[tmpGfx.length];
    tmpGfx.push(g);
    tmpGraphicsLayer.addMany([g]);
  }

  const processSegments = (segments,dir) => {
    let reversed = false;
    segments.forEach((segment,segmentIndex) => {
      const isFirst = (segmentIndex === 0);
      const isLast = (segmentIndex === (segments.length - 1));
      let A = segment.coordsA;
      let B = segment.coordsB;
      let coords1 = dropPerp(B,A,!reversed);
      let coords2 = dropPerp(A,B,reversed);
      if (isFirst) {
        const from = coords2;
        const to = coords1;
        newPath.push(adjustEnd(coords1,from,to,dir,true,false));
        tmpAddPoint(newPath[newPath.length - 1]);
      }
      let segment2 = segments[segmentIndex + 1];
      if (segment2) {
        let A2 = segment2.coordsA;
        let B2 = segment2.coordsB;
        let coords3 = dropPerp(B2,A2,!reversed);
        let coords4 = dropPerp(A2,B2,reversed);
        let point = intersectLines(
          coords1[0],coords1[1],
          coords2[0],coords2[1],
          coords3[0],coords3[1],
          coords4[0],coords4[1],
          true,(dir === "in")
        );
        if (point) {
          if (Array.isArray(point)) {
            point.forEach(pt => {
              newPath.push([pt.x,pt.y,z]);
              tmpAddPoint(newPath[newPath.length - 1]);
            })
          } else {
            newPath.push([point.x,point.y,z]);
            tmpAddPoint(newPath[newPath.length - 1]);            
          }
        }
      }
      if (isLast) {
        const from = newPath[newPath.length - 1];
        const to = coords2;
        newPath.push(adjustEnd(coords2,from,to,dir,false,true));
        tmpAddPoint(newPath[newPath.length - 1]);
      }
    })
  }

  let segmentsOut = [], segmentsIn = [];
  pathOut.forEach((coords,coordsIndex) => {
    let coordsA = coords && coords.slice();
    let coordsB = pathOut[coordsIndex + 1] && pathOut[coordsIndex + 1].slice();
    if (coordsA && coordsB) {
      segmentsOut.push({coordsA,coordsB})
    }
  });
  pathIn.forEach((coords,coordsIndex) => {
    let coordsA = coords && coords.slice();
    let coordsB = pathIn[coordsIndex + 1] && pathIn[coordsIndex + 1].slice();
    if (coordsA && coordsB) {
      segmentsIn.push({coordsA,coordsB})
    }
  });

  processSegments(segmentsOut,"out");
  processSegments(segmentsIn,"in");

  if (newPath.length > 1) {
    newPath.push(newPath[0].slice()); // close
    let buffered = new Context.instance.lib.esri.Polyline({
      hasZ: true,
      paths: [newPath],
      spatialReference: polyline.spatialReference.clone()
    })
    buffered = ge.simplify(buffered);
    return buffered;
  }

}

export function extendLine(a,b,dist) {
  const len = Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  const cx = a.x + (a.x - b.x) / len * dist;
  const cy = a.y + (a.y - b.y) / len * dist;
  const cx2 = b.x + (b.x - a.x) / len * dist;
  const cy2 = b.y + (b.y - a.y) / len * dist;
  let v1 = {
    x: cx,
    y: cy
  }
  let v2 = {
    x: cx2,
    y: cy2
  }
  return {v1,v2}
}

export function intersectLines(x1,y1,x2,y2,x3,y3,x4,y4,processingSegments?,reversed?): any {
  const a1 = y2 - y1;
  const b1 = x1 - x2;
  const c1 = (a1 * x1) + (b1 * y1);

  const a2 = y4 - y3;
  const b2 = x3 - x4;
  const c2 = (a2 * x3) + (b2 * y3);

  const determinant = (a1 * b2) - (a2 * b1);
  const tol = 0.00001;

  // let deg = null;
  // const slope1 = (y2 - y1) / (x2 - x1);
  // const slope2 = (y4 - y3) / (x4 - x3);
  // if (slope1 === Number.POSITIVE_INFINITY || slope1 === Number.NEGATIVE_INFINITY) {
  //   if (slope2 === Number.POSITIVE_INFINITY || slope2 === Number.NEGATIVE_INFINITY) {
  //   } else {
  //     let tan = slope2;
  //     const atan = Math.atan(tan);
  //     deg = 90 - ((atan * 180) / (Math.PI))
  //   }
  // } else if (slope2 === Number.POSITIVE_INFINITY || slope2 === Number.NEGATIVE_INFINITY) {
  //   let tan = slope1;
  //   const atan = Math.atan(tan);
  //   deg = 90 + ((atan * 180) / (Math.PI))
  // } else {
  //   const atan2 = Math.atan2((slope1 - slope2),(1 + (slope1 * slope2)))
  //   deg = ((atan2 * 180) / (Math.PI))
  // }
  
  let ok = true;
  if (Math.abs(determinant) < tol) {
    // parallel
    ok = false;
    if ((x2 === x3) && (y2 === y3)) {
      // continuing a stright line segment
      return {x: x2, y: y2};
    }
    if (processingSegments) {
      const dx1 = x2 - x1;
      const dy1 = y2 - y1;
      const dx2 = x4 - x3;
      const dy2 = y4 - y3;
      if ((dx1 > 0) && (dx2 < 0) || (dx1 < 0) && (dx2 > 0) || (dy1 > 0) && (dy2 < 0) || (dy1 < 0) && (dy2 > 0)) {
        // reversed on itself
        if (reversed) {
          return [{x: x2, y: y2}, {x: x3, y: y3}];
        } else {
          //return {x: x2, y: y2};
          return [{x: x2, y: y2}, {x: x3, y: y3}];
        }
      }
    }
  }

  if (ok) {
    const x = ((b2 * c1) - (b1 * c2)) / determinant;
    const y = ((a1 * c2) - (a2 * c1)) / determinant;
    return {x, y};
  }
}

export function makePerpendicularSnapEndpoint(snapPoly,ptX,ptY,tolerance) {
  let d = null, snapEndpoint = null;
  snapPoly.geometry.rings.forEach((ring,ringIndex) => {
    ring.forEach((coords,coordsIndex) => {
      const x = coords[0];
      const xNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][0];
      const y = coords[1];
      const yNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][1];
      if (typeof xNext === "number") {
        const result = projectPointOnLine(
          {x: x, y: y},
          {x: xNext, y: yNext},
          {x: ptX, y: ptY}
        )
        if (result && result.pPrime && result.d <= tolerance && (d === null || result.d < d)) {
          d = result.d;
          snapEndpoint = {
            x: result.pPrime.x,
            y: result.pPrime.y
          }
        }
      }
    })
  })
  return snapEndpoint;
}

export function makeSnapEndpoint(snapPoly,coordsFrom,coordsTo,tolerance) {
  let d = null, snapEndpoint = null;
  snapPoly.geometry.rings.forEach((ring,ringIndex) => {
    ring.forEach((coords,coordsIndex) => {
      const x = coords[0];
      const xNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][0];
      const y = coords[1];
      const yNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][1];
      if (typeof xNext === "number") {
        let point = intersectLines(
          x,y,
          xNext,yNext,
          coordsFrom[0],coordsFrom[1],
          coordsTo[0],coordsTo[1]
        );
        const result = projectPointOnLine(
          {x: x, y: y},
          {x: xNext, y: yNext},
          {x: coordsTo[0], y: coordsTo[1]}
        )
        if (point && result && result.pPrime && result.d <= tolerance) {
          const xmin = Math.min(x,xNext);
          const xmax = Math.max(x,xNext);
          const ymin = Math.min(y,yNext);
          const ymax = Math.max(y,yNext);
          if ((xmin <= point.x) && (xmax >= point.x) && (ymin <= point.y) && (ymax >= point.y)) {
            snapEndpoint = {
              x: point.x,
              y: point.y
            }
          }
        }

      }
    })
  })
  return snapEndpoint;
}

export function projectPointOnLine(v1,v2,p)  {
  // http://www.sunshine2k.de/coding/java/PointOnLine/PointOnLine.html
  const dotProduct = (p1,p2) => {
    return ((p1.x * p2.x) + (p1.y * p2.y))
  }
  const perpDotProduct = (p1,p2) => {
    return ((p1.x * p2.y) - (p1.y * p2.x));
  }
  const perpDotProduct3 = (p1,p2,p3) => {
    return (p1.x - p3.x) * (p2.y - p3.y) - (p1.y - p3.y) * (p2.x - p3.x);
  }
  const getEpsilon = () => {
    const dx1 = v2.x - v1.x;
    const dy1 = v2.y - v1.y;
    const epsilon = 0.003 * (dx1 * dx1 + dy1 * dy1);
    return epsilon;
  }
  const isPointOnLine = () => {
    return (Math.abs(perpDotProduct3(v1, v2, p)) < getEpsilon());
  }
  const isPointOnLineSegment = () => {
    if (!((v1.x <= p.x && p.x <= v2.x) || (v2.x <= p.x && p.x <= v1.x))) {
      return false;
    }
    if (!((v1.y <= p.y && p.y <= v2.y) || (v2.y <= p.y && p.y <= v1.y))) {
      return false;
    }
    // @todo ??? return isPointOnLine();
  }
  const isProjectedPointOnLineSegment = (e1,e2,dp) => {
    const recArea = dotProduct(e1, e1);
    return (dp >= 0 && dp <= recArea);
  }

  let result, pPrime;
  if (isPointOnLineSegment()) {
    pPrime = {
      x: p.x,
      y: p.y
    }
  } else {
    const e1 = {
      x: v2.x - v1.x, 
      y: v2.y - v1.y
    }
    const e2 = {
      x: p.x - v1.x, 
      y: p.y - v1.y
    }
    const dp = dotProduct(e1, e2);
    if (isProjectedPointOnLineSegment(e1,e2,dp)) {
      const len2 = (e1.x * e1.x) + (e1.y * e1.y);
      pPrime = {
        x: (v1.x + ((dp * e1.x) / len2)),
        y: (v1.y + ((dp * e1.y) / len2))
      }
    }
  }

  if (pPrime) {
    const a = p.x - pPrime.x;
    const b = p.y - pPrime.y;
    const d = Math.sqrt(Math.pow(a,2) + Math.pow(b,2))
    result = {
      d: d,
      pPrime: pPrime,
      v1: v1,
      v2: v2
    }
  }
  return result;
}

