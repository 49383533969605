import * as areas from "./areas";
import * as moveDates from "./moveDates";
import * as people from "./people";
import * as transaction from "./transaction";
import * as units from "./units";
import * as footprints from "./footprints";
import * as features from "./features";
import * as addPersonTransaction from "./addPersonTransaction";
import * as deletePersonTransaction from "./deletePersonTransaction";
import * as bulkPeopleTransactions from "./bulkPeopleTransactions";
import * as moveOccupantLocation from "./moveOccupantLocation";
import * as reviewers from "./reviewers";
import * as _updateAttributes from "./updateAttributes";

import * as details from "./details";
import * as _paste from "./paste";
import * as _splitUnit from "./splitUnit";
import * as merge from "./merge";
import * as createUnit from "./createUnit";
import * as createWall from "./createWall";

import * as addOccupant from "./insertOccupant";
import { AreaTypes } from "../../../util/interfaces";
import { IFeatureItem } from "../../components/common/PeopleAssignTo";


/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export function addArea(areaType: AreaTypes, areaId: string, areaName: string, data: {}) {
  const task = {
    preparor: areas,
    info: {
      areaType,
      areaId,
      areaName,
      data: data || {},
      addArea: true
    }
  };
  return execute(task);
}

export function assignPeopleToArea(peopleFeatureItems,areaItem,areaType,duplicatePeople) {
  const task = {
    preparor: people,
    peopleFeatureItems: peopleFeatureItems,
    duplicatePeople: duplicatePeople,
    assignPeopleToArea: {
      areaItem: areaItem,
      areaType: areaType
    }
  };
  return execute(task);
}

export function assignPeopleToUnit(peopleFeatureItems,unitFeatureItem,unassignCurrentOccupants,duplicatePeople,occupantGeometry?) {
  const task = {
    preparor: people,
    peopleFeatureItems: peopleFeatureItems,
    duplicatePeople: duplicatePeople,
    occupantGeometry: occupantGeometry,
    assignPeopleToUnit: {
      unitFeatureItem: unitFeatureItem,
      unassignCurrentOccupants: unassignCurrentOccupants
    }
  };
  return execute(task);
}

export function assignUnitsToArea(unitFeatureItems,areaItem,areaType,peopleData) {
  const task = {
    preparor: units,
    unitFeatureItems: unitFeatureItems,
    peopleData: peopleData,
    assignUnitsToArea: {
      areaItem: areaItem,
      areaType: areaType
    }
  };
  return execute(task);
}

export function deleteArea(areaItem: __esri.Graphic, areaType: AreaTypes, deletePeopleData: __esri.Graphic[] | IFeatureItem[]) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      areaType,
      deletePeopleData,
      unassignArea: true,
      deleteArea: true
    }
  };
  return execute(task);
}

/** A generic transaction for editing features from a single feature service. Multiple `info`s
 * can be provided for each {@link LayerType} to be edited.
 */
export function editFeatures(infos: features.FeaturesTask["infos"]) {
  const task = {
    preparor: features,
    infos
  }
  return execute(task);
}
export function mergeFeatures(info: merge.MergeTask["info"]) {
  const task = {
    preparor: merge,
    info
  }
  return execute(task);
}
export function moveOccupantWithinUnit(personFeatureItem,unitFeatureItem,occupantGeometry) {
  const task = {
    preparor: moveOccupantLocation,
    personFeatureItem: personFeatureItem,
    unitFeatureItem: unitFeatureItem,
    occupantGeometry: occupantGeometry,
    moveOccupantWithinUnit: true
  };
  return execute(task);
}

export function renameArea(areaItem: __esri.Graphic, areaType: AreaTypes, areaName: string) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      areaType,
      areaName,
      renameArea: true
    }
  };
  return execute(task);
}

export function updateArea(
  areaItem: __esri.Graphic,
  areaType: AreaTypes,
  areaName: string,
  data: areas.AreasTask["info"]["data"]
) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      areaType,
      areaName,
      data,
      updateArea: true
    }
  };
  return execute(task);
}

export function updateAreaRestriction(
  areaItem: __esri.Graphic,
  areaType: AreaTypes,
  restricted: boolean
) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      areaType,
      data: { restricted },
      updateAreaRestriction: true
    }
  };
  return execute(task);
}

export function updateAreaRoles(
  areaItem: __esri.Graphic,
  roleData: areas.AreasTask["info"]["data"]["roleData"]
) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      data: { roleData: roleData },
      updateAreaRoles: true
    }
  };
  return execute(task);
}

export function setPeopleMoveDates(changes,globalIds,moveDate) {
  const task = {
    preparor: moveDates,
    changes: changes,
    globalIds: globalIds,
    moveDate: moveDate,
    setPeopleMoveDates: true
  };
  return execute(task);
}

export function unassignArea(areaItem: __esri.Graphic, areaType: AreaTypes, peopleData: never) {
  const task = {
    preparor: areas,
    info: {
      areaItem,
      areaType,
      unassignArea: true,
      peopleData: peopleData
    }
  };
  return execute(task);
}

export function unassignPeople(peopleFeatureItems, duplicatePeople) {
  const task = {
    preparor: people,
    peopleFeatureItems: peopleFeatureItems,
    unassignPeople: true,
    duplicatePeople: duplicatePeople
  };
  return execute(task);
}

export function unassignUnits(unitFeatureItems, peopleData) {
  const task = {
    preparor: units,
    unitFeatureItems: unitFeatureItems,
    unassignUnits: true,
    peopleData: peopleData
  };
  return execute(task);
}

export function updateAttributes(
  type: _updateAttributes.UpdateAttributesTask["type"],
  feature: __esri.Graphic,
  attributes: Record<string, any>,
  includeGeometry?: boolean
) {
  const task = {
    preparor: _updateAttributes,
    type,
    feature,
    attributes,
    includeGeometry
  };
  return execute(task);
}

export function addPerson(basePersonFeature, unitFeature, areaFeature, unassignOthers, duplicatePeople, occupantGeometry?) {
  const task = {
    preparor: addPersonTransaction,
    basePersonFeature: basePersonFeature,
    unitFeature: unitFeature,
    areaFeature: areaFeature,
    unassignOthers: unassignOthers,
    duplicatePeople: duplicatePeople,
    occupantGeometry: occupantGeometry
  };
  return execute(task);
}

export function deletePeople(peopleFeatures, isRemoveAll) {
  const task = {
    preparor: deletePersonTransaction,
    peopleFeatures: peopleFeatures,
    isRemoveAll: isRemoveAll
  }
  return execute(task);
}

// Delete all duplicates and leave one record unassigned
export function bulkPanelUnassignPeople(people) {
  const task = {
    preparor: bulkPeopleTransactions,
    people: people,
    bulkUnassignPeople: true
  }
  return execute(task);
}

// Assign and remove duplicates
export function bulkPanelAssignPeopleToUnit(people, unit) {
  const task = {
    preparor: bulkPeopleTransactions,
    people: people,
    unit: unit,
    bulkAssignPeopleToUnit: true
  }
  return execute(task);
}

// Duplicates
export function bulkPanelDuplicatePeopleToUnit(people, unit) {
  const task = {
    preparor: bulkPeopleTransactions,
    people: people,
    unit: unit,
    bulkDuplicatePeopleToUnit: true
  }
  return execute(task);
}

// Assign and remove duplicates
export function bulkPanelAssignPeopleToArea(people, area) {
  const task = {
    preparor: bulkPeopleTransactions,
    people: people,
    area: area,
    bulkAssignPeopleToArea: true
  }
  return execute(task);
}

// Duplicates
export function bulkPanelDuplicatePeopleToArea(people, area) {
  const task = {
    preparor: bulkPeopleTransactions,
    people: people,
    area: area,
    bulkDuplicatePeopleToArea: true
  }
  return execute(task);
}

export function selectPortalGroupReviewers(portalMembers/*, portalGroupId*/) {
  const task = {
    preparor: reviewers,
    portalMembers: portalMembers,
    // portalGroupId: portalGroupId
  }
  return execute(task)
}

export function addReviewer(username, /*portalGroupId, */planId/*, initTable*/) {
  const task = {
    preparor: reviewers,
    username: username,
    // portalGroupId: portalGroupId,
    planId: planId,
    // initTable: initTable
  }
  return execute(task)
}

export function removeReviewer(username/*, portalGroupId*/) {
  const task = {
    preparor: reviewers,
    username: username,
    // portalGroupId: portalGroupId,
    removeReviewer: true
  }
  return execute(task)
}

// export function updatePortalGroup(portalGroupId) {
//   const task = {
//     preparor: planReview,
//     portalGroupId: portalGroupId
//   }
//   return execute(task)
// }

// export function changeReadyStatus(status) {
//   const task = {
//     preparor: planReview,
//     status: status
//   }
//   return execute(task)
// }

export function removeAllReviewers(users) {
  const task = {
    preparor: reviewers,
    removeAll: true,
    usersToRemove: users
  }
  return execute(task)
}

export function updateReviewerStatus(username, /*portalGroupId, */status) {
  const task = {
    preparor: reviewers,
    username: username,
    // portalGroupId: portalGroupId,
    status: status
  }
  return execute(task)
}

export function insertOccupant(attributes) {
  const task = {
    preparor: addOccupant,
    attributes: attributes
  }
  return execute(task);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export async function editDetail(info: details.UpdateDetailsTask["info"]) {
  const task = {
    preparor: details,
    info: info
  }
  return execute(task)
}

export function editFootprint(info: footprints.UpdateFootprintTask["info"]) {
  const task = {
    preparor: footprints,
    info
  };
  return execute(task);
}

export async function paste(info) {
  const task = {
    preparor: _paste,
    info: info
  }
  return execute(task)
}

export async function splitUnit(info) {
  const task = {
    preparor: _splitUnit,
    info: info
  }
  return execute(task)
}

export async function fpeEditUnit(info: {
  newUnits?:__esri.Graphic[],
  modifiedUnits?:__esri.Graphic[],
  deletedUnits?:__esri.Graphic[],
  originalUnits?:__esri.Graphic[],
}) {
  console.log('transactions.fpeEditUnit', info);
  //contains adds, updates, deletes, `originals`
  if (info && (info.newUnits || info.modifiedUnits || info.deletedUnits)) {
    return execute({ preparor: createUnit, info });
  } else {
    throw new Error("Unable to perform Unit edits, invalid parameter");
  }
}

export async function fpeEditWall(info) {
  if (info && info.newWalls) {
    return execute({ preparor: createWall, info });
  } else {
    throw new Error("Unable to perform Unit edits, invalid parameter");
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

function execute(task: {
  preparor: {
    prepare: (task: Record<string, any>) => Promise<transaction.ITransactionInfo>
  },
  transactionInfo?: transaction.ITransactionInfo,
  [otherProps: string]: any
}) {
  return task.preparor.prepare(task).then(transactionInfo => {
    task.transactionInfo = transactionInfo;
    const edits = transactionInfo.edits;
    if (edits && edits.length > 0) {
      return transaction.applyEdits(transactionInfo);
    }
  });
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
