import React from "react";
import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import * as mapUtil from "../../../base/mapUtil";
import { IMediaItem } from "../../../miniapps/common/types";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-list-item";
import "@esri/calcite-components/dist/components/calcite-slider";
import { 
  CalciteAction, 
  CalciteCheckbox, 
  CalciteInputNumber,
  CalciteLabel,
  CalciteListItem, 
  CalciteSlider,  
} from "@esri/calcite-components-react";

interface Props {
  canEditMediaLayers: boolean,
  mediaItem: IMediaItem,
  selected: boolean,
  onAspectRatioChange: (mediaItem: IMediaItem) => void,
  onOpacityChange: (mediaItem: IMediaItem) => void,
  onRemove: (mediaItem: IMediaItem) => void,
  onSelect: (mediaItem?: IMediaItem) => void,
  onVisibilityChange: (mediaItem: IMediaItem) => void
}

interface State {
}

export default class MediaItem extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({})
  }

  render() {
    const i18n = Context.instance.i18n;
    const { canEditMediaLayers, mediaItem, selected } = this.props;
    const mediaLayer = mediaItem.mediaLayer;
    return (
      <CalciteListItem
        label={mediaItem.mediaLayer.title} 
        selected={this.props.selected}
        onCalciteListItemSelect={(event) => {
          this.props.onSelect(selected ? null : mediaItem)
        }}
      >
        <CalciteAction 
          slot="actions-start" 
          icon={selected ? "chevron-up" : "chevron-down"} 
          scale="s"
          text={i18n.editor.media.selected}
          onClick={e => {
            this.props.onSelect(selected ? null : mediaItem)
          }}
        />
        <CalciteAction 
          slot="actions-end" 
          icon={mediaLayer.visible ? "view-visible" : "view-hide"} 
          scale="s"
          text={i18n.editor.media.visibility}
          title={i18n.editor.media.visibility}
          onClick={e => {
            mediaLayer.visible = !mediaLayer.visible;
            this.props.onVisibilityChange(mediaItem);
            component.refresh(this);
          }} 
        />
        <CalciteAction 
          slot="actions-end" 
          icon={"magnifying-glass-plus"} 
          scale="s"
          text={i18n.editor.media.zoomTo}
          title={i18n.editor.media.zoomTo}
          onClick={e => {
            mapUtil.goToGeometry(Context.instance.views.mapView,mediaLayer.fullExtent,"fpe_feature");
          }} 
        />
        {canEditMediaLayers &&
          <CalciteAction 
            slot="actions-end" 
            icon="trash" 
            scale="s"
            text={i18n.editor.media.remove}
            title={i18n.editor.media.remove}
            onClick={e => this.props.onRemove(mediaItem)} 
          />
        }

        {selected && this.renderContent()}
      </CalciteListItem>
    )
  }

  renderAspectRatioLock() {
    const i18n = Context.instance.i18n;
    const { canEditMediaLayers, mediaItem } = this.props;
    if (!canEditMediaLayers) return null;
    return (
      <div className="i-content-gap">
        <CalciteLabel layout="inline">
          <CalciteCheckbox 
            checked={mediaItem.preserveAspectRatio || undefined}
            onCalciteCheckboxChange={e => {
              const v = !!e.target.checked;
              mediaItem.preserveAspectRatio = v;
              this.props.onAspectRatioChange(mediaItem);
            }} 
          />
          {i18n.editor.media.lockAspectRatio}
        </CalciteLabel>
      </div>
    )
  }

  renderContent() {
    const cls = "i-media-item-content i--selected";
    return (
      <div className={cls}>
        {this.renderTransparency()}
        {this.renderAspectRatioLock()}
      </div>
    )
  }

  renderTransparency() {
    const i18n = Context.instance.i18n;
    const { mediaItem, selected } = this.props;
    const mediaLayer = mediaItem.mediaLayer;

    let transparency = 0;
    if (typeof mediaLayer.opacity === "number") {
      transparency = Math.round((100 - (mediaLayer.opacity * 100)));
    }

    const setOpacity = (t) => {
      if (typeof t === "number") {
        t = Math.min(Math.max(t,0),100);
        let v = ((100 - t) / 100);
        mediaLayer.opacity = v;
        this.props.onOpacityChange(mediaItem);
        component.refresh(this);
      }
    }

    return (
      <div>
        <CalciteLabel>
          {i18n.editor.media.transparency}
        </CalciteLabel>
        <div className="i-media-transparency i-flex-between-centered">
          <CalciteSlider
            scale="s"
            min={0}
            max={100}
            ticks={25}
            value={transparency}
            onCalciteSliderInput={(e) => {
              const v = e.target.value;
              setOpacity(v);
            }}
          />
          <CalciteInputNumber
            min={0}
            max={100}
            step={1}
            value={""+transparency}
            onCalciteInputNumberInput={(e)=>{
              const v = Number(e.target.value);
              setOpacity(v);
            }}
          />
        </div>
      </div>
    )
  }
    
}