import React from "react";

import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

import { Stat } from "../../styles/Work/workBar";

export default class StatValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({
      value: ""
    });
  }

  componentDidMount() {
    const plan = OfficePlan.getActive();
    if (plan && plan.wasOpened) {
      const stat = plan.getStat(this.props.statKey);
      if (stat) {
        this.setState({value: stat.value});
      }
    }
    
    component.own(this,[
      Topic.subscribe(Topic.StatRefreshed,params => {
        const key = this.props.statKey;
        if (key && key === params.stat.key) {
          this.setState({value: params.stat.value});
        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const value = this.state.value;
    return (
      <Stat>{value}</Stat>
    )
  }

}
