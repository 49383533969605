import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as commentReplyUtil from "../../components/Comments/commentReplyUtil";
import * as queryUtil from "../../base/queryUtil";
import * as selectionUtil from "../../../../src/aiim/util/selectionUtil";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";

export function addComment(obj, layerUrl) {
    return new Promise((resolve, reject)=> {
        const edits = [];
        const comment = commentReplyUtil.getCommentFieldName();
        const plan_id = commentReplyUtil.getPlanIdFieldName();
        const edit = {
          "attributes":{
            [comment]: obj.comment,
            [plan_id]: obj.planid
          }
        }
        commentReplyUtil.addAuthorFields(edit);
        
        edits.push(edit);
        const url = layerUrl+"/applyEdits";
        const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            adds: JSON.stringify(edits)
        };
        const commentLayer = OfficePlan.getActive().commentsLayer;
        if (commentLayer.gdbVersion) params.gdbVersion = commentLayer.gdbVersion;

        const options = {
            query: params,
            method: "post",
            responseType: "json"
        };
        const esriRequest = Context.instance.lib.esri.esriRequest;
        esriRequest(url,options).then(result => {
            if(result && result.data && result.data.addResults && result.data.addResults.length>0 && result.data.addResults[0].success === false) {
              Topic.publish(Topic.LoadComments, {});  
              throw new Error(result.data.addResults[0].error.message);
            }
            resolve(result);
        })
        .catch((error)=> {reject(error);})
    })
}

export function deleteComment(id, layerUrl){
    return new Promise((resolve, reject)=> {
        const parentCid = commentReplyUtil.getParentCidFieldName();
        const objectid = commentReplyUtil.getObjectIdComment();
        const edits = [];
        edits.push(id);
        const url = layerUrl+"/deleteFeatures";
        const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            where: "(" + objectid + " = "+id+ " OR "+ parentCid + " = "+id+ ")"
        };

        const commentLayer = OfficePlan.getActive().commentsLayer;
        if (commentLayer.gdbVersion) params.gdbVersion = commentLayer.gdbVersion;

        const options = {
            query: params,
            method: "post",
            responseType: "json"
        };
        let result;
        const esriRequest = Context.instance.lib.esri.esriRequest;
        esriRequest(url,options).then(result => {
            if(result && result.data && result.data.deleteResults && result.data.deleteResults.length>0 && result.data.deleteResults[0].success === false) {
              Topic.publish(Topic.LoadComments, {});  
              throw new Error(result.data.deleteResults[0].error.description);
            }
            return result;
        })
        .then(()=> {
            const lyr = OfficePlan.getActive().commentsLayer;
            if(lyr) lyr.refresh();
            Topic.publish(Topic.LoadComments, {});
            resolve(result);
        })
        .catch((error)=> {
            reject(error);
        })
    })
}

export function editComment(obj, layerUrl){
    const commentLayer = OfficePlan.getActive().commentsLayer;
    const fields = commentLayer && commentLayer.fields;
    const objectid = commentReplyUtil.getObjectIdComment();
    const comment = commentReplyUtil.getCommentFieldName();
    const levelIdField = aiimUtil.findField(fields,FieldNames.LEVEL_ID);
    const facilityIdField = aiimUtil.findField(fields,FieldNames.FACILITY_ID);
    return new Promise((resolve, reject)=> {
        const edit = {
          "attributes":{
            [comment]: obj.updatedComment,
            [objectid]: obj.commentId
          }
        }
        if(obj.geometry){
          let z = obj.geometry.z;
          if(!z) z = 0;
          const point = {
            "x": obj.geometry.x,
            "y": obj.geometry.y,
            "z": z,
            "spatialReference": obj.geometry.spatialReference.toJSON()
          }
          if(OfficePlan.getActive().commentsLayer.hasM) point.m = 0;
          edit.geometry = point;
        }

        Promise.resolve().then(() => {
          const levels = Context.instance.aiim.datasets.levels;
          if (levels) {
            if (edit.geometry) {
              const gu = Context.instance.lib.esri.geometryJsonUtils;
              const geom = gu.fromJSON(edit.geometry);
              return levels.queryLevelDataByPoint(geom).then(levelData => {
                //console.log("queryLevelDataByPoint.levelData",levelData);
                if (levelData && typeof levelData.z === "number") {
                  edit.geometry.z = levelData.z;
                }
                if (levelIdField) {
                  if (levelData) {
                    edit.attributes[levelIdField.name] = levelData.levelId;
                  } else {
                    edit.attributes[levelIdField.name] = null;
                  }
                }
                if (facilityIdField) {
                  if (levelData) {
                    edit.attributes[facilityIdField.name] = levelData.facilityId;
                  } else {
                    edit.attributes[facilityIdField.name] = "";
                  }
                }
              });
            }
          }
        }).then(() => {
          console.log("EditComment",edit)
          const url = layerUrl+"/updateFeatures";
          const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            features: JSON.stringify([edit])
          };
          if (commentLayer.gdbVersion) params.gdbVersion = commentLayer.gdbVersion;
          const options = {
            query: params,
            method: "post",
            responseType: "json"
          };
          const esriRequest = Context.instance.lib.esri.esriRequest;
          return esriRequest(url,options).then(result => {
            if(result && result.data && result.data.updateResults &&
               result.data.updateResults.length>0 && result.data.updateResults[0].success === false) {
                Topic.publish(Topic.LoadComments, {});
                throw new Error(result.data.updateResults[0].error.description);
            }
          });
        }).then(() => {
          const lyr = OfficePlan.getActive().commentsLayer;
          if(lyr) lyr.refresh();
          Topic.publish(Topic.LoadComments, {});
          resolve();
        }).catch((error)=> {
          reject(error)
        });
    })
}

export function isGeometry(id, layerUrl) {
    return new Promise((resolve, reject)=> {
        const objectid = commentReplyUtil.getObjectIdComment();
        const commentLayer = OfficePlan.getActive().commentsLayer;
        const url = Context.checkMixedContent(layerUrl);
        const lib = Context.getInstance().lib;
        const task = new lib.esri.QueryTask({url: url});
        const query = new lib.esri.Query();
        query.outFields = ["*"];
        query.returnGeometry = true;
        query.returnZ = true;
        query.where = objectid + " = "+id;
        queryUtil.applyGdbVersion(commentLayer.source,query);
        task.execute(query).then(result =>{
          resolve(result);
        })
        .catch(error => {reject(error)});
    })
}

export function showAllComments(planId, layerUrl){
    return new Promise((resolve, reject)=> {
        const commentLayer = OfficePlan.getActive().commentsLayer;
        const planid = commentReplyUtil.getPlanIdFieldName();
        const parent_cid = commentReplyUtil.getParentCidFieldName();
        const createdDate = commentReplyUtil.getAuthorDateField();
        const url = Context.checkMixedContent(layerUrl);
        const lib = Context.getInstance().lib;
        const task = new lib.esri.QueryTask({url: url});
        const query = new lib.esri.Query();
        query.outFields = ["*"];
        query.returnGeometry = true;
        query.returnZ = true;
        query.where = planid + " = '"+ selectionUtil.escSqlQuote(planId)+"'";
        query.where += " AND ("+parent_cid+" is null)";
        query.orderByFields = [createdDate + " DESC"];
        queryUtil.applyGdbVersion(commentLayer,query);
        task.execute(query).then(result =>{
          resolve(result);
        })
        .catch(error => {reject(error)});
    })
}
