import styled, { css } from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import Button from "calcite-react/Button";

const AssignToContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${props =>
    props.noWrap &&
    css`
      flex-wrap: nowrap;
    `};

  & [class*="StyledComboButtonContainer"] {
    max-width: 100%;

    ${props => props.noMargin
      ? css`margin-block-start: 0;`
      : css`margin-block-start: ${unitCalc(props.theme.baseline, 2, "/")};`
    };

    & [class*="StyledComboButtonDropdown"] {
      width: 100%;
    }
  }

  > [class*="StyledButton"] {
    ${props => props.noMargin
      ? css`margin-block-start: 0;`
      : css`margin-block-start: ${unitCalc(props.theme.baseline, 2, "/")};`
    };
  }
`;

const StyledButton = styled(Button)`
  margin: 0 ${props => unitCalc(props.theme.baseline, 2, "/")};
`;

export { AssignToContainer, StyledButton };
