import React from "react";
import moment, { Moment } from "moment";
import Context from "../../../context/Context";
import Popover from "calcite-react/Popover"
import SDPWrapper from "../../../components/common/Calendar/SDPWrapper";
import Topic from "../../../context/Topic";
import { TimePicker } from "antd";
import * as component from "../../util/component";
import * as dateUtil from "../Events/dateUtil";
import type Duration from "./Duration";
type DurationType = dateUtil.IDuration["duration"];
type DatePart = "date" | "time" | "datetime";
type Previous = { dateMoment: Moment, timeMoment: Moment };
export type OnPartChanged = (
  c: DateTime,
  type: DatePart,
  dateMoment: Moment,
  timeMoment: Moment,
  prev: Previous) => void;
interface IDateTimeProps {
  date: Date,
  disabled?: boolean,
  duration: DurationType,
  formKey: string,
  isEnd?: boolean,
  isOngoingBooking?: boolean,
  isStart?: boolean,
  label: string,
  onMount: (dateTime: DateTime) => void,
  onPartChanged: OnPartChanged,
  startDate?: Date
}
interface IDateTimeState {
  startDateMoment?: Moment,
  dateMoment?: Moment,
  timeMoment?: Moment,
  calendarOpen?: boolean,
  duration: DurationType
}
export default class DateTime extends React.Component<IDateTimeProps, IDateTimeState> {

  timeFormat;
  use12Hours;

  constructor(props: IDateTimeProps) {
    super(props);
    const locale = Context.instance.lib.dojo.kernel.locale
    if (locale === "en" || locale === "en-us") {
      this.use12Hours = true
      this.timeFormat = "h:mm A"
    } else {
      this.use12Hours = false
      this.timeFormat = "H:mm"
    }
    let dateMoment, timeMoment;
    if (this.props.date) {
      let dt = new Date(this.props.date);
      let dtStart = dateUtil.toStartOfDay(dt);
      dateMoment = moment(dtStart);
      timeMoment = moment(dt);
    } else {
      dateMoment = moment(new Date());
    }
    const state = component.newState({
      dateMoment: dateMoment,
      timeMoment: timeMoment,
      calendarOpen: false,
      duration: this.props.duration
    });

    if (this.props.isEnd && this.props.startDate) {
      let dt = new Date(this.props.startDate);
      state.startDateMoment = moment(dt);
    }
    this.state = state;
  }

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this);

    component.own(this, [
      Topic.subscribe(Topic.WhenChanged, params => {
        if (params && params.formKey === this.props.formKey) {
          if (params.part === "duration") {
            let data: IDateTimeState = {
              duration: params.value
            }
            if (this.props.isEnd && params.value) {
              if (params.value !== "allDay") {
                if (this.state.startDateMoment) {
                  let dt = this.state.startDateMoment.toDate();
                  let dt2 = dateUtil.addDuration(dt,params.value);
                  let dtStart = dateUtil.toStartOfDay(dt2);
                  let dateMoment = moment(dtStart);
                  let timeMoment = moment(dt2);
                  data.dateMoment = dateMoment;
                  data.timeMoment = timeMoment;
                }
              }
            }
            this.setState(data);
          }
        }
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getDateMillis(dateMoment: Moment, timeMoment: Moment) {
    let date: number = null;
    if (dateMoment) {
      let dtStart = dateUtil.toStartOfDay(dateMoment.toDate());
      date = dtStart.getTime();
      if (timeMoment) {
        date = this.timeMomentAsDateMillis(dateMoment,timeMoment);
      }
    }
    return date;
  }

  getTimeString = (timeObj) => {
    let hour = timeObj.hour()
    if (hour < 10) hour = "0" + hour
    let minute = timeObj.minute()
    if (minute < 10) minute = "0" + minute
    // let second = timeObj.seconds()
    // if (second < 10) second = "0" + second
    let time = hour + ":" + minute + ":00";
    /*
    if (this.state.allDay) {
      // time = moment("0:00", this.format).format("HH:mm:ss")
      time = "00:00:00"
    }
    */
    return time;
  }

  render() {
    let label = this.props.label;
    return (
      <div>
        <div>{label}</div>
        <div className="i-duration-group">
          {this.renderDate()}
          {this.renderTime()}
        </div>
      </div>
    );
  }

  renderDate() {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const dateMoment = this.state.dateMoment;
    const isOpen = !!this.state.calendarOpen;
    const calendarIcon = "libs/calcite-ui-icons/icons/sprite-16.svg#calendar-16";
    let ariaLabel = i18n.meetingRooms.dateAriaLabel;
    if (this.props.isStart) {
      ariaLabel = i18n.meetingRooms.fromDateAriaLabel;
    } else if (this.props.isEnd) {
      ariaLabel = i18n.meetingRooms.toDateAriaLabel;
    }
    let s;
    if (dateMoment) {
      try {
        s = lib.dojo.locale.format(dateMoment.toDate(),{selector:"date",formatLength:"short"});
      } catch (ex) {
        console.log("badDateMoment",dateMoment)
        console.error(ex);
      }

    }

    const isOutsideRange = (day) => {
      return moment().isAfter(day, 'day')
    }
    const isDayHighlighted = (day) => {
      const m = moment(this.state.dateMoment)
      return moment().isSame(day, 'day') && m && !m.isSame(day, 'day')
    }

    const clicked = () => {
      this.setState({
        calendarOpen: !this.state.calendarOpen
      })
    }

    const changed = (date) => {
      if (date !== null && date !== undefined) {
        let prev = {
          dateMoment: this.state.dateMoment,
          timeMoment: this.state.timeMoment
        }
        let dtMoment = moment(date);
        let timeMoment = this.state.timeMoment;
        this.setState({
          dateMoment: dtMoment,
          calendarOpen: false
        });
        if (this.props.onPartChanged) {
          this.props.onPartChanged(this,"date",dtMoment,timeMoment,prev);
        }
      }
    }

    const close = () => {
      this.setState({
        calendarOpen: false
      })
    }

    let disabled = false;
    if(this.props.isStart && this.props.disabled) {
      disabled = this.props.disabled;
    }
    const button = (
      <button aria-label={ariaLabel} className="i-date" disabled={disabled}
        onClick={clicked}>
        <div className="i-sidebar-icon-container i-filter-container">
          <div className="i-date-string">{s}</div>
          <svg className="i-more-menu-icon"><use href={calendarIcon}></use></svg>
        </div>
      </button>
    );

    return (
      <Popover
        closeOnSelect={false}
        onRequestClose={close}
        open={isOpen}
        targetEl={button}
        appendToBody>
        <SDPWrapper
          initialDate={dateMoment.toDate()}
          outsideRange={isOutsideRange}
          dayHighlighted={isDayHighlighted}
          booking={true}
          onDateChange={changed}
        />
      </Popover>
    );
  }

  renderTime() {
    let ariaLabel;
    let timeMoment = this.state.timeMoment;
    let allDay = this.state.duration === "allDay";
    let isEnd = !!this.props.isEnd;
    let use12Hours = this.use12Hours;
    let timeFormat = this.timeFormat;
    let minuteStep = 5;
    let disabled = false;

    if(this.props.isStart && this.props.isOngoingBooking){
      if(this.props.disabled) {
        disabled = this.props.disabled;
      }
    } 

    if (allDay) {
      disabled = true;
      if (isEnd) {
        timeMoment = moment("23:59",timeFormat);
      } else {
        timeMoment = moment("0:00",timeFormat);
      }
    }

    const changed = (time, timeString) => {
      let prev = {
        dateMoment: this.state.dateMoment,
        timeMoment: this.state.timeMoment
      }
      this.setState({
        timeMoment: time
      },() => {
        if (this.props.onPartChanged) {
          this.props.onPartChanged(this,"time",prev.dateMoment,time,prev);
        }
      });
    };

    const openChanged = (open) => {
      if (open) {
        const preventScroll = () => {
          const timeColumns = document.getElementsByClassName("ant-picker-time-panel-column")
          for (let i = 0; i < timeColumns.length; i++) {
            const text = (timeColumns[i].firstChild.firstChild as HTMLElement).innerText
            if (text === "AM" || text === "PM") {
              (timeColumns[i] as HTMLElement).style.overflowY = "visible"
            }
          }
        }
        setTimeout(preventScroll, 100)
      }
    };

    return (
      <TimePicker
        aria-label={ariaLabel}
        disabled={disabled}
        use12Hours={use12Hours}
        format={timeFormat}
        value={timeMoment}
        minuteStep={minuteStep}
        showNow={false}
        inputReadOnly={true}
        onChange={changed}
        onOpenChange={openChanged}/>
    );

  }

  setFromMoment(m,roundedMillis) {
    let prev = {
      dateMoment: this.state.dateMoment,
      timeMoment: this.state.timeMoment
    }
    let dt = new Date(roundedMillis);
    let dtStart = dateUtil.toStartOfDay(dt);
    let dateMoment = moment(dtStart);
    let timeMoment = moment(dt);
    this.setState({
      dateMoment: dateMoment,
      timeMoment: timeMoment,
      calendarOpen: false
    }, () => {
      if (this.props.onPartChanged) {
        this.props.onPartChanged(this,"datetime",dateMoment,timeMoment,prev);
      }
    });
  }

  setWhen(millis: number, duration: DurationType, newStart?: number) {
    const dt = new Date(millis);
    const dtStart = dateUtil.toStartOfDay(dt);
    const dateMoment = moment(dtStart);
    const timeMoment = moment(dt);
    const startDateMoment = this.props.isEnd && newStart != null
      ? moment(newStart)
      : null;

    this.setState({
      dateMoment,
      timeMoment,
      duration,
      startDateMoment,
      calendarOpen: false
    })
  }

  timeMomentAsDateMillis(dateMoment: Moment, timeMoment: Moment) {
    if (timeMoment) {
      let dtStart = dateUtil.toStartOfDay(dateMoment.toDate());
      let dt = timeMoment.toDate();
      let dt0 = dateUtil.toStartOfDay(dt);
      let millis = dt.getTime() - dt0.getTime();
      let dateMillis = dtStart.getTime() + millis;
      return dateMillis;
    }
  }

  validate(criteriaPart: { date: Date }) {
    let ok = true;
    let dateMoment = this.state.dateMoment;
    let timeMoment = this.state.timeMoment;
    let date = this.getDateMillis(dateMoment,timeMoment);
    criteriaPart.date = new Date(date);
    return ok;
  }

  whenAssociatedPartChanged(
    c: DateTime,
    type: DatePart,
    dtMoment: Moment,
    tmMoment: Moment,
    durationComponent: Duration,
    prev: Previous
  ): IDateTimeState {
    if (c === this) return;
    let dateMoment = this.state.dateMoment;
    let timeMoment = this.state.timeMoment;
    let newProps;

    let millis = this.getDateMillis(dateMoment,timeMoment);
    let millisAssoc = this.getDateMillis(dtMoment,tmMoment);

    const applyDuration = (offsetMillis?: number) => {
      if (durationComponent) {
        let d1 = new Date(this.getDateMillis(dtMoment,tmMoment));
        let d2;
        if (offsetMillis) {
          d2 = moment(d1).add(offsetMillis,"milliseconds").toDate();
        } else {
          let dur = durationComponent.state.value || "oneHour";
          if(dur === "allDay" && this.props.isEnd && c.props.isStart){
            d2 = dateUtil.toEndOfDay(dtMoment.toDate());
          } else {
            d2 = dateUtil.addDuration(d1,dur);
          }
        }
        if (!newProps) newProps = {};
        newProps.dateMoment = moment(dateUtil.toStartOfDay(d2));
        newProps.timeMoment = moment(d2);
      }
    }

    if (this.props.isEnd && c.props.isStart) {
      if (type === "date" || type === "time" || type === "datetime") {
        if (!newProps) newProps = {};
        let startDateMillis = this.getDateMillis(dtMoment,tmMoment);
        newProps.startDateMoment = moment(new Date(startDateMillis));
        if (millis <= millisAssoc) {
          let offsetMillis;
          if (!durationComponent.state.value && prev) {
            let prevStartMillis = this.getDateMillis(prev.dateMoment,prev.timeMoment);
            offsetMillis = millis - prevStartMillis;
          }
          applyDuration(offsetMillis);
        } else if (type === "datetime") {
          applyDuration();
        } else {
          if (durationComponent && durationComponent.state.value &&
              durationComponent.state.value !== "allDay") {
            applyDuration();
          }

          // if (type === "time") {
          //   let sameDay = dateMoment.isSame(dtMoment,"day");
          //   if (sameDay) {
          //     applyDuration();
          //   }
          // }

        }
      }
    }

    if (this.props.isStart && c.props.isEnd) {
      let startDateMillis, newMillis, wasEndReset = false;
      if (millis >= millisAssoc) {
        if (type === "date") {
          let millis2 = this.getDateMillis(dtMoment,timeMoment);
          if (millis2 < millisAssoc) {
            newMillis = millis2;
          } else {
            newMillis = millisAssoc;
          }
        } else if (type === "time") {
          // reset the time to it's previous value
          if (prev && prev.timeMoment) {
            wasEndReset = true;
            c.setState({
              timeMoment: prev.timeMoment
            });
          } else {
            newMillis = millisAssoc;
          }
        }
      }

      if (newMillis) {
        startDateMillis = newMillis;
        let dt = new Date(newMillis);
        if (!newProps) newProps = {};
        newProps.dateMoment = moment(dateUtil.toStartOfDay(dt));
        newProps.timeMoment = moment(dt);
      }

      let endProps;
      if (startDateMillis) {
        if (!endProps) endProps = {};
        endProps.startDateMoment = moment(new Date(startDateMillis));
      }

      let newDuration = undefined;
      let curDuration = (durationComponent && durationComponent.state.value)
      let isAllDay = curDuration ==="allDay";
      if (!wasEndReset && !isAllDay) {
        let millisA = newMillis || millis;
        let millisB = millisAssoc;
        let dur = dateUtil.getDurationKey(new Date(millisA),new Date(millisB));
        if (dur !== curDuration) {
          newDuration = dur;
          if (durationComponent) {
            durationComponent.setState({
              value: newDuration
            })
          }
        }
      }
      if (newDuration) {
        if (!newProps) newProps = {};
        newProps.duration = newDuration;
        if (!endProps) endProps = {};
        endProps.duration = newDuration;
      }

      if (endProps) {
        c.setState(endProps);
      }

      // if (startDateMillis) {
      //   c.setState({
      //     startDateMoment: moment(new Date(startDateMillis))
      //   });
      // }

    }

    if (newProps) {
      this.setState(newProps);
    }
    return newProps;
  }

}
