import React from "react";

import Context from "../../../context/Context";
import * as component from "../../util/component";

import defaultIcon from "../../../assets/category-default.svg";

const CSS = {
  categories: "i-categories",
  categoryIcon: "i-category-icon"
};

export default class Categories extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const i18n = Context.instance.i18n;
    const categories = this.props.category.categories;
    const list = [];

    if (Array.isArray(categories) && categories.length > 0) {
      categories.forEach(category => {
        list.push(this.renderCategory(category));
      });
    }
    if (list.length > 0) {
      return (
        <ul className={CSS.categories} aria-label={i18n.explore.categoriesAriaLabel}>
          {list}
        </ul>
      );
    } else {
      return (
        <p>{i18n.messages.noSearchCategories}</p>
      )
    }
  }

  renderCategory(category) {

    const clicked = (evt) => {
      if (this.props.clickHandler) {
        this.props.clickHandler(evt,category);
      }
    };

    const hashref = "#";
    const name = category.name;
    // TODO dataUrl use parent
    let dataUrl = (
      (category.xtn.iconUrl) ||
      (category.largeIcon && category.largeIcon.data) ||
      (category.mediumIcon && category.mediumIcon.data) ||
      (category.smallIcon && category.smallIcon.data)
    );
    if (typeof dataUrl === "string" && dataUrl.length > 0) {
      if (dataUrl.indexOf("data:") !== 0) {
        dataUrl = "data:image/png;base64," + dataUrl;
      }
    } else {
      dataUrl = defaultIcon;
    }
    const icon = <img className={CSS.categoryIcon} src={dataUrl} alt=""/>

    return (
      <li key={category.id}>
        <a href={hashref} onClick={clicked} role="button" tabIndex="0">
          {icon}
          <p>{name}</p>
        </a>
      </li>
    );
  }

}
