import Rdx, { IRootState, createAction } from "../redux/Rdx";
import { AnyAction } from "redux";
import { AreaTypes, ReservationMethod } from "../util/interfaces";

// state keys
const SPACEPLANNER_ACTIVE_SECTION = "SPACEPLANNER_ACTIVE_SECTION";
export const SPACEPLANNER_MINI_APP = "SPACEPLANNER_MINI_APP";
const SPACEPLANNER_VERSION = "SPACEPLANNER_VERSION";
const SPACEPLANNER_MINIAPPS_SWITCHER_ISACTIVE = "MINIAPPS_SWITCHER_ISACTIVE";
export const SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID = "SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID";
export const SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE = "SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE";
const MINIAPPS_SIDEBAR_COLLAPSED = "MINIAPPS_SIDEBAR_COLLAPSED";
export const ASSIGNMENTS_ACTIVE_SIDEBAR_TAB = "ASSIGNMENTS_ACTIVE_SIDEBAR_TAB";

// This module provides Redux components specifically for the SpacePlanner
// If this file becomes too large these components can be separated into individual files

// Actions
export const setActiveAssetbarSection = (tab: string): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_ACTIVE_ASSETBAR_SECTION, tab);
export const setActiveSection = (tab: string): AnyAction =>
  createAction(SPACEPLANNER_ACTIVE_SECTION, tab);
export const setActiveTab = (key: string, tab: string | null): AnyAction =>
  createAction(key, tab);
export const setMiniApp = (app: ISpacePlannerState["SPACEPLANNER_MINI_APP"]): AnyAction =>
  createAction(SPACEPLANNER_MINI_APP, app);
export const setMiniappsSidebarCollapsed = (collapsed: boolean): AnyAction =>
  createAction(MINIAPPS_SIDEBAR_COLLAPSED, collapsed);
export const setMiniAppSwitcherActive = (active: boolean): AnyAction =>
  createAction(SPACEPLANNER_MINIAPPS_SWITCHER_ISACTIVE, active);
export const setSpaceplannerVersion = (version: number): AnyAction =>
  createAction(SPACEPLANNER_VERSION, version);
export const setActiveWorkspaceAreaId = (id: string): AnyAction =>
  createAction(SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID, id);
export const setActiveWorkspaceAreaType = (type: AreaTypes): AnyAction =>
  createAction(SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE, type);
export const setHotdesksEnabled = (enabled: boolean): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_HOTDESKS_ENABLED, enabled);
export const setHotelsEnabled = (enabled: boolean): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_HOTELS_ENABLED, enabled);
export const setMeetingRoomsEnabled = (enabled: boolean): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_MEETINGROOMS_ENABLED, enabled);
export const setWorkspacesEnabled = (enabled: boolean): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_WORKSPACES_ENABLED, enabled);
export const setHotelReservationMethod = (method: ReservationMethod): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_HOTEL_RESERVATION_METHOD, method);
export const setMeetingRoomReservationMethod = (method: ReservationMethod): AnyAction =>
  createAction(Rdx.Keys.SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD, method);

// Selectors
export const getActiveAssetbarSection = (state: IRootState): boolean =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_ACTIVE_ASSETBAR_SECTION);
export const getActiveSection = (state: IRootState): string =>
  Rdx.getValue(state, SPACEPLANNER_ACTIVE_SECTION);
export const getActiveTab = (state: IRootState, key: string) =>
  Rdx.getValue(state, key);
export const getMiniApp = (state: IRootState): string =>
  Rdx.getValue(state, SPACEPLANNER_MINI_APP);
export const getSpaceplannerVersion = (state: IRootState): number =>
  Rdx.getValue(state, SPACEPLANNER_VERSION);
export const getMiniappsSidebarCollapsed = (state: IRootState): boolean =>
  Rdx.getValue(state, MINIAPPS_SIDEBAR_COLLAPSED);
export const getMiniAppSwitcherActive = (state: IRootState): boolean =>
  Rdx.getValue(state, SPACEPLANNER_MINIAPPS_SWITCHER_ISACTIVE);
export const getActiveWorkspaceAreaId = (state: IRootState): string =>
  Rdx.getValue(state, SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID);
export const getActiveWorkspaceAreaType = (state: IRootState): AreaTypes =>
  Rdx.getValue(state, SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE);
export const getHotdesksEnabled = (state: IRootState): boolean =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_HOTDESKS_ENABLED);
export const getHotelsEnabled = (state: IRootState): boolean =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_HOTELS_ENABLED);
export const getMeetingRoomsEnabled = (state: IRootState): boolean =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_MEETINGROOMS_ENABLED);
export const getWorkspacesEnabled = (state: IRootState): boolean =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_WORKSPACES_ENABLED);
export const getHotelReservationMethod = (state: IRootState): ReservationMethod =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_HOTEL_RESERVATION_METHOD);
export const getMeetingRoomReservationMethod = (state: IRootState): ReservationMethod =>
  Rdx.getValue(state, Rdx.Keys.SPACEPLANNER_MEETINGROOM_RESERVATION_METHOD);

// State
export interface ISpacePlannerState {
  [SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID]?: string,
  [SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE]?: AreaTypes,
  [SPACEPLANNER_ACTIVE_SECTION]?: string,
  [SPACEPLANNER_MINI_APP]?: "assignments" | "editor" | "management" | "review",
  [SPACEPLANNER_VERSION]?: number,
  [SPACEPLANNER_MINIAPPS_SWITCHER_ISACTIVE]?: boolean,
  [MINIAPPS_SIDEBAR_COLLAPSED]?: boolean
}
export const initialSpacePlannerState: ISpacePlannerState = {
  [SPACEPLANNER_ACTIVE_WORKSPACE_AREA_ID]: null,
  [SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE]: null,
  [SPACEPLANNER_ACTIVE_SECTION]: "plan",
  [SPACEPLANNER_MINI_APP]: null,
  [SPACEPLANNER_VERSION]: 1,
  [SPACEPLANNER_MINIAPPS_SWITCHER_ISACTIVE]: false
}