import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import * as modalUtil from "../../../miniapps/common/components/modalUtil";
import * as editorUtil from "./editorUtil";

export default class BaseVM extends BaseClass {

  constructor(props?) {
    super(props);
    this.mixinProps(props);
  }

  formatDate(date: Date) {
    return date.toLocaleDateString();
  }

  formatDateTime(date: Date) {
    return date.toLocaleString();
  }

  getVersionName() {
    if (!this.isPlanLoaded())
      return null;
    const plan = Context.getInstance().spaceplanner.activePlan;
    return plan && plan.isVersioned && plan.versionInfo ? plan.versionInfo.versionName : null;
  }

  getView() {
    return Context.instance.views && Context.instance.views.activeView;
  }

  isPlanLoaded() {
    const planner = Context.getInstance().spaceplanner.planner;
    return planner && planner.hasValidPlan();
  }

  makeSnappingOptions(mixin?: __esri.SnappingOptionsProperties) {
    return editorUtil.makeSnappingOptions(mixin);
  }

  showError(title?: string, message?: string, error?: Error) {
    modalUtil.showError(modalUtil.makeErrorOptions(title, message, error));
  }

}