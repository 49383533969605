// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as portalUtil from "../../../util/portalUtil";

// Component specific modules (Component-styled, etc.)
import {
  ContentWrapper,
  HeaderContainer,
  Header,
  ContentContainer
} from "../../styles/backstageStyles";

// App components
import Button from "../../../common/components/Button";
import ItemProperties from "../common/ItemProperties";
import Planner from "../../base/Planner";
import PlanCreator from "../../base/PlanCreator";
import {
  ModalController,
  WorkingController
} from "../../../common/components/Modal";
import VersionProperties from "../common/VersionProperties";
import { SharingLevels } from "../../../common/components/Sharing";

// JSON

// CSS
interface INewPlanProps {
  isSaveAs: boolean
}
interface INewPlanState {
  isWorking: boolean
}
export default class NewPlan extends Component<INewPlanProps, INewPlanState> {
  _itemProperties;
  _versionProperties;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: false
    });
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getItemInfo = isVersioned => {
    if (isVersioned) {
      return this._versionProperties && this._versionProperties.getItemInfo();
    } else {
      return this._itemProperties && this._itemProperties.getItemInfo();
    }
  };
  getProject = () => {
    return Context.instance.spaceplanner.planner.project;
  };

  render() {
    const i18n = Context.instance.i18n;
    const isVersioned =
      this.getProject() && this.getProject().isVersioned
        ? this.getProject().isVersioned
        : false;
    const isSaveAs = !!this.props.isSaveAs;
    
    const portal = Context.getInstance().getPortal();
    let groupsUrl;
    if (portal) {
      groupsUrl = `${portal.url}/home/groups.html?sortOrder=asc&sortField=title`;
    }

    return (
      <ContentWrapper>
        <HeaderContainer>
          <Header>
            {isSaveAs
              ? i18n.spaceplanner.backstage.savePlanAs.caption
              : i18n.spaceplanner.backstage.newPlan.caption}
          </Header>
        </HeaderContainer>
        <ContentContainer>
          {isVersioned
            ? this.renderVersionedFields()
            : this.renderHostedFields()}
          <Button
            disabled={this.state.isWorking}
            onClick={this.showWarning}
          >
            {i18n.spaceplanner.backstage.newPlan.createPlan}
          </Button>
        </ContentContainer>        
      </ContentWrapper>
    );
  }

  renderHostedFields = () => {
    return (
      <ItemProperties
        disallowEveryone={true}
        shareWith={SharingLevels.PRIVATE}
        onMount={this.setItemProperties}
        submitHandle={this.saveClicked}
      />
    );
  };

  renderVersionedFields = () => {
    return (
      <VersionProperties
        onMount={this.setVersionProperties}
        submitHandle={this.saveClicked}
      />
    );
  };

  showWarning =()=> {
    if (this.state.isWorking) return;
    else{
      const project = Context.instance.spaceplanner.planner.project;
      const isVersioned = project && project.isVersioned;
      if (isVersioned) {
        const itemInfo = this.getItemInfo(true);
        if (itemInfo.accessPermission === SharingLevels.PRIVATE) this.showConfirmModal();
        else this.saveClicked();
      } else {
        const itemInfo = this._itemProperties.getItemInfo();
        if (itemInfo) {
          const sharingInfo = itemInfo.sharingInfo;
          if (sharingInfo && (sharingInfo["org"] === true || (sharingInfo.groups && sharingInfo.groups.length > 0))) this.saveClicked();
          else this.showConfirmModal();
        }
      }
    }
  }

  showConfirmModal =()=> {
    const i18n = Context.instance.i18n;
    const options = {
      title: i18n.spaceplanner.backstage.newPlan.warning,
      message: i18n.spaceplanner.backstage.newPlan.warningPrivatePlan,
      okLabel: i18n.general.ok,
      closeOnCancel: true
    }
    ModalController.confirm(options).then(result => {
      if(result.ok){
        this.saveClicked();
      }
    })
  }

  saveClicked = () => {
    if (this.state.isWorking) return;
    const i18n = Context.instance.i18n;
    const project = Context.instance.spaceplanner.planner.project;
    const isVersioned = project && project.isVersioned;
    const itemInfo = this.getItemInfo(isVersioned);
    if (!itemInfo || !itemInfo.title) return;
    // if(!isVersioned){
      let isPortal = Context.instance.getPortal().isPortal;
      // const isPortal = false;
      if(isVersioned) isPortal = false;
      itemInfo.itemTitle= itemInfo.title;
      itemInfo.title = itemInfo.title.split(" ").join("_");
      let isMatch = isPortal ? /[.@~`!#$%\^&*+=\-\[\]\\';,\/{}|\\":<>\?]/g.test( itemInfo.title)
        : /[.@#%&*+=\-\\';,\/|\\":<>\?]/g.test(itemInfo.title);
      if (isMatch ||  itemInfo.title.length > 120) {
        const title = i18n.spaceplanner.backstage.newPlan.errorCreatingTitle;
        let message;
        if(isVersioned) message = i18n.spaceplanner.backstage.newPlan.errorInvalidPlanPortal;
        else message = i18n.spaceplanner.backstage.newPlan.errorInvalidPlan;
        const submessage= null;
        ModalController.alert({
          isError: true,
          title: title,
          message: message,
          submessage: submessage
        });
        return;
      }
    // }
    Topic.publish(Topic.CreatingPlan,{});
    this.setState({ isWorking: true });
    const workingController = new WorkingController();
    const workingContent = i18n.spaceplanner.backstage.newPlan.creating;
    workingController.show({}, workingContent);
    const pc = new PlanCreator();
    const options = {
      itemInfo: itemInfo,
      isSaveAs: !!this.props.isSaveAs,
      workingController: workingController
    };
    pc.createPlan(project, options)
      .then(result => {
        console.log("createPlan.result", result);
        let planId, planAppId;
        const portal = Context.getInstance().getPortal();
        const user = portal.user;
        
        if (itemInfo && itemInfo.sharingInfo && itemInfo.sharingInfo['groups']) {
          const sharingInfo = {
            everyone: false,
            org: false,
            groups: itemInfo.groupIds.join(',')
          }
          portalUtil.shareItem(result.plan.serviceItemId, user.username, sharingInfo);
        }

        if (isVersioned) {
          const versionInfo = result.versionInfo;
          planId = versionInfo.versionGuid;
          planAppId = Planner.getParentAppId({ versionInfo: versionInfo });
        } else {
          planId = result.plan.serviceItemId;
          planAppId = Planner.getParentAppId(result.plan.serviceItem);
        }
        const planner = Context.instance.spaceplanner.planner;
        planner.reloadApp(planAppId, planId);
      })
      .catch(ex => {
        // ex.message = The version already exists (for branch versions)
        let message = i18n.spaceplanner.backstage.newPlan.errorCreatingMessage;
        let title = i18n.spaceplanner.backstage.newPlan.errorCreatingTitle;
        let submessage = ex.submessage || ex.message;
        if (ex.code === "__serviceNameAlreadyExists__") {
          submessage = null;
          message = i18n.spaceplanner.backstage.newPlan.errorPlanNameExists;
        }
        if (ex.message === "The version already exists")  {
          submessage = null;
          message = i18n.spaceplanner.backstage.newPlan.errorPlanNameExists;
        }
        if(ex.message === "Invalid version name."){
          submessage = null;
          message = i18n.spaceplanner.backstage.newPlan.errorUnsupportedCharacters;
        }
        workingController.close();
        ModalController.alert({
          isError: true,
          title: title,
          message: message,
          submessage: submessage
        });
        this.setState({ isWorking: false });
        console.error("Error creating plan", ex);
      });
  };

  setItemProperties = itemProperties => {
    this._itemProperties = itemProperties;
  };

  setVersionProperties = versionProperties => {
    this._versionProperties = versionProperties;
  };
}
