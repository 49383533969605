import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { setAttributeEditorEnabled } from "../redux";
import { getAttributeValue, getFieldValue } from "../../../../aiim/util/aiimUtil";
import Topic from "../../../../context/Topic";
import Context from "../../../../context/Context";
import * as tsUtil from "../../../../util/tsUtil";
import * as formUtil from "../support/formUtil";
import * as transactions from "../../../base/transaction/transactions";
import TransactionGuard from "../../../base/TransactionGuard";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import { CalciteButton } from "@esri/calcite-components-react";
import { IFormOptions } from "../../../../util/interfaces";
import { IFeature, IGeometry, IHasZM } from "@esri/arcgis-rest-types";
import * as editorUtil from "./editorUtil";
import { isEqual } from "lodash";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import PanelHeaderTools, { HeaderToolType } from "./PanelHeaderTools";
import MapButtons from "./MapButtons";

const coreNonVisible = ["globalid", "objectid"];

export interface FeatureToUpdate {
  feature: IFeature & { geometry?: IGeometry & IHasZM },
  idField: string,
  changed: boolean,
}

export interface IAttributesProps {
  footer?: boolean | JSX.Element | JSX.Element[],
  formOptions: IFormOptions,
  header?: string | JSX.Element | JSX.Element[],
  headerDisplayField?: string,
  idField: string,
  isDisabled?: boolean,
  /** Determines if the component is in edit or view mode. */
  isEditing?: boolean,
  /** Determines the UI for the component. */
  layout?: "readonly" | "form" | "hybrid",
  onApplyEdit?: (updated: FeatureToUpdate) => Promise<boolean>,
  onCreateForm?: (form: __esri.FeatureForm) => void,
  onDelete?: (feature: __esri.Graphic) => void,
  onDuplicate?: (feature: __esri.Graphic) => void,
  onDomainValueChange?: __esri.FeatureFormValueChangeEventHandler,
  onFormValidityChange?: (valid: boolean) => void,
  onReset?: () => void,
  onValueChange?: (e: __esri.FeatureFormValueChangeEvent, hasValidChanges: boolean) => void,
  /** Indicates whether to call `applyEdits` when the form is submitted. To customize the applyEdits,
   * set this value to `false` and send the updates within the `onApplyEdit` callback.
   */
  sendUpdates?: boolean,
  showDelete?: boolean,
  usePopupFields?: boolean,
  validateOnLoad?: boolean
}
export interface IAttributesHandle {
  getValues: __esri.FeatureForm["getValues"],
  hasValidChanges: () => boolean,
  isValid: () => boolean,
  reset: () => void,
  save: () => void,
  setFormValues: (values: Record<string, any>) => void
}

const Attributes = forwardRef<IAttributesHandle, IAttributesProps>(({
  footer = true,
  formOptions,
  header,
  headerDisplayField,
  idField = formOptions.layer.objectIdField,
  isDisabled = false,
  onDelete,
  onDuplicate,
  onDomainValueChange = () => { },
  isEditing = false,
  layout = "hybrid",
  onApplyEdit = async (updated: FeatureToUpdate) => true,
  /** Callback to retrieve the instance of the `FeatureForm`. 
   * @example
   * <Attributes ...otherProps
   *    onCreateForm={(form) => {
   *      myFormInstanceRef.current = form;
   *      // can call instance methods of `FeatureForm`
   *      // e.g. myFormInstanceRef.current.submit();
   *    }} 
   * />
   */
  onCreateForm = () => { },
  onFormValidityChange = () => { },
  onReset = () => { },
  onValueChange = () => { },
  usePopupFields = false,
  sendUpdates = true,
  showDelete = false,
  validateOnLoad = false
}: IAttributesProps, ref) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(isEditing);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isFootprint, setIsFootprint] = useState<boolean>(formUtil.isFootprint(formOptions.type));
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const dispatch = useDispatch();
  const ctx = Context.getInstance();
  const { i18n } = ctx;
  const { intl } = ctx.lib.esri;
  const { CustomFeatureForm } = ctx.lib.indoors;
  const widgetRef = useRef<__esri.FeatureForm>();
  const containerRef = useRef();
  const handlesRef = useRef([]);
  const groupExpanded = useRef<boolean>(false);
  const allNonVisible = coreNonVisible
    .concat(Object.values(formOptions.layer.editFieldsInfo || {}))
    .concat(formOptions.layer.geometryFieldsInfo
      ? [
        formOptions.layer.geometryFieldsInfo.shapeAreaField,
        formOptions.layer.geometryFieldsInfo.shapeLengthField
      ]
      : [])
    .concat(formOptions.nonVisibleFields)
    .filter(f => !!f && typeof f.toLowerCase === "function")
    .map(f => f.toLowerCase());
  const feature = formOptions.feature;
  const existingGeometry = useRef<IGeometry & IHasZM>(feature.geometry.toJSON());
  feature.layer = formOptions.layer;

  useEffect(() => {
    const node = containerRef && containerRef.current;
    if (!node) {
      // read-only mode so destroy form
      destroyWidget();
      return;
    }

    if (!widgetRef.current) {
      // new instance of the form
      renderEditable(node);
      !isDisabled && validateOnLoad && setTimeout(validate, 500);
      // @ts-ignore
    } else if (layout === "form" && widgetRef.current._attached) {
      widgetRef.current.disabled = !isEditMode || isDisabled;
      widgetRef.current.feature = feature;
    } else if (!(isSameFeature() && isEditMode)) {
      // when `feature` prop changes switch to read-only mode
      destroyWidget();
      onSetEdit(false);
    }
  }, [layout, isEditMode, feature, isDisabled]);

  useEffect(() => setIsEditMode(isEditing), [isEditing]);
  useEffect(() => {
    const id = getAttributeValue(feature.attributes, idField);
    const footprint = formUtil.isFootprint(formOptions.type);
    setIsFootprint(footprint);
    if (footprint) {
      editorUtil.isEmpty(id, formOptions.type).then(containsOthers => {
        setIsEmpty(!!containsOthers);
      });
    } else {
      setIsEmpty(false);
    }
  }, [formOptions, isEditMode]);
  useEffect(() => () => {
    if (widgetRef.current) {
      const groups = widgetRef.current.viewModel.inputs.filter(i => i.type === "group") as __esri.GroupInput[];
      const expanded = groups.some(g => g.state === "expanded");
      groupExpanded.current = expanded;
    }
    destroyWidget();
  }, [feature]);
  const applyEdit = (edit: IFeature) => {
    if (!sendUpdates)
      return Promise.resolve();

    const guard = new TransactionGuard({ force: true });
    return Promise.resolve().then(() => {
      const type = formOptions.type;
      return type === "unit"
        ? transactions.updateAttributes(type, feature, edit.attributes, edit.geometry != null)
        : type === "detail"
          ? transactions.editDetail({ action: "update", feature: edit, prevFeature: feature })
          : Promise.reject("Only details and units are editable at the moment.");
    }).then(() => {
      guard.close();
      if (edit.geometry) {
        existingGeometry.current = edit.geometry;
      }
    }).catch((error) => {
      guard.close();
      console.error("Error updating feature", error);
      const details = error.details;
      Topic.publishErrorUpdatingData(details && details.messages && details.messages.length
        ? details.messages.join("\n")
        : error.message);
    });
  }

  const destroyWidget = () => {
    if (widgetRef.current) {
      widgetRef.current.destroy();
      widgetRef.current = null;
      existingGeometry.current = null;
      handlesRef.current.filter(h => !!h).forEach(h => {
        try {
          typeof h.remove === "function" && h.remove();
        } catch (err) {
          console.error(err);
        }
      });
      handlesRef.current.length = 0;
    }
  }

  const filter = (f: __esri.Field | __esri.FieldInfo) => {
    const field = "fieldName" in f ? f.fieldName.toLowerCase() : f.name.toLowerCase();
    const editable = "isEditable" in f ? f.isEditable : f.editable;
    return !allNonVisible.includes(field) && editable === true;
  }

  const hasPlan = () => {
    const planner = ctx.spaceplanner.planner;
    return planner && planner.hasValidPlan();
  }

  const hasValidChanges = () => {
    let changed = false;
    if (widgetRef.current) {
      const inputs = widgetRef.current.viewModel.inputs
        .filter(f => f.type === "field" || f.type === "group")
        .map(f => f.type === "group"
          ? f.inputs as __esri.FieldInput[]
          : f as __esri.FieldInput)
        .flat();
      const useTypeInput = inputs.find((input) => input.name.toLowerCase() === "use_type");
      if (useTypeInput) {
        useTypeInput.set("domain", useTypeInput.field.domain);
      }
      const hasInvalid = inputs.some(fieldInput => {
        return (!fieldInput.valid || tooLong(fieldInput)) || invalidEmail(fieldInput);
      });
      if (!hasInvalid) {
        const updated = widgetRef.current.getValues();
        Object.keys(updated).some(name => {
          if (feature.attributes[name] !== updated[name]) {
            changed = true;
          }
          return changed;
        });
      }
    }
    return changed;
  }

  const invalidEmail = (fieldInput: __esri.FieldInput) => {
    if (fieldInput.dataType !== "text") return false;
    // @ts-ignore
    return widgetRef.current && !widgetRef.current.isValidEmail(fieldInput);
  }

  const isSameFeature = () => {
    if (!(widgetRef.current
      && widgetRef.current.feature
      && widgetRef.current.feature.attributes
      && feature
      && feature.attributes)) return false;

    return getAttributeValue(widgetRef.current.feature.attributes, idField) === getAttributeValue(feature.attributes, idField);
  }

  const onSetEdit = (isEdit: boolean) => {
    setIsEditMode(isEdit);
    dispatch(setAttributeEditorEnabled(isEdit));
  }

  const renderEditable = (node: HTMLElement) => {
    // create a removable container div since calling `destroy` on the form removes it's container node
    const container = node.appendChild(document.createElement("div"));
    const ff: __esri.FeatureForm = widgetRef.current = new CustomFeatureForm({
      indoorsContext: ctx,
      tsUtil: tsUtil,
      container,
      feature,
      indoorsOptions: {
        ...formOptions,
        expandGroup: groupExpanded.current,
        getTooltip: formUtil.getFieldTooltip
      }
    });
    ff.disabled = !isEditMode || isDisabled;
    existingGeometry.current = feature.geometry.toJSON();
    handlesRef.current.push(ff.on("value-change", (e: __esri.FeatureFormValueChangeEvent) => {
      const valid = hasValidChanges();
      setCanSave(valid);
      onValueChange && onValueChange(e, valid);
      const lyr: __esri.FeatureLayer = formOptions.layer;
      if (!lyr || !lyr.renderer) {
        return;
      }
      const fld = lyr.fields.find((f: __esri.Field) => f.name === e.fieldName);
      const customDomains = [FieldNames.UNITS_USE_TYPE, FieldNames.DETAILS_USE_TYPE];
      if (fld && (fld.domain || customDomains.includes(fld.name.toLowerCase()))) {
        onDomainValueChange && onDomainValueChange(e);
      }
    }));

    handlesRef.current.push(ff.on("submit", async (info: __esri.FeatureFormSubmitEvent) => {
      // @ts-ignore
      if ((info.invalid && info.invalid.length > 0) || ff.hasInvalidEmail()) {
        // TODO ???
        return;
      }
      let changed = false;
      const globalIdField = formOptions.layer.fields.find(f => f.type === "global-id");
      const edit: IFeature & { geometry?: IGeometry & IHasZM } = {
        attributes: { [globalIdField.name]: feature.attributes[globalIdField.name] }
      };
      if (!isEqual(existingGeometry.current, feature.geometry.toJSON())) {
        edit.geometry = feature.geometry.toJSON();
      }
      const updated = ff.getValues();
      Object.keys(updated).forEach(name => {
        if (feature.attributes[name] !== updated[name]) {
          changed = true;
          edit.attributes[name] = updated[name];
        }
      });

      changed && await applyEdit(edit);
      const isSuccess = onApplyEdit ? await onApplyEdit({ feature: edit, idField: globalIdField.name, changed }) : true;
      isSuccess && layout === "hybrid" && onSetEdit(false);
    }));

    handlesRef.current.push(ff.viewModel.watch("valid", (newVal, oldVal) => {
      if (newVal !== oldVal) {
        onFormValidityChange && onFormValidityChange(newVal);
      }
    }));

    // return the form instance to the parent
    onCreateForm && onCreateForm(ff);
  }

  const renderFooter = () => {
    return (isEditMode && hasPlan() && footer === true
      ? <footer className="i-editor-attributes-footer">
        <CalciteButton
          appearance="outline"
          key="reset"
          width="half"
          onClick={reset}>
          {i18n.general.reset}
        </CalciteButton>
        <CalciteButton
          disabled={!canSave ? true : undefined}
          key="save"
          width="half"
          onClick={save}>
          {i18n.general.save}
        </CalciteButton>
      </footer>
      : typeof footer !== "boolean"
        ? footer
        : null
    );
  }

  const renderHeader = () => {
    return isEditMode === true ? renderEditHeader() : renderViewHeader();
  }

  const renderViewHeader = () => {
    const tools: HeaderToolType[] = ["duplicate", "delete", "separator", "zoomIn", "edit"];
    const disabled: HeaderToolType[] = [];
    if (isFootprint) {
      disabled.push("duplicate");
      if (isEmpty === false) {
        disabled.push("delete", "edit");
      }
    }
    if (showDelete === false) {
      tools.splice(1, 1);
    }
    return (
      <PanelHeaderTools tools={tools} disabledTools={disabled} onButtonClick={(e, toolInfo) => {
        const tool = toolInfo && toolInfo.name;
        if (tool === "zoomIn") {
          editorUtil.onZoomToFeature(feature);
        } else if (tool === "delete") {
          onDelete && onDelete(feature);
        } else if (tool === "edit") {
          onSetEdit(true);
        } else if (tool === "duplicate") {
          onDuplicate && onDuplicate(feature);
          // onCreateDuplicate()
        }
      }} />
    );
  }

  const renderEditHeader = () => [null, undefined].includes(header) ? undefined : (
    <header className="i-editor-attributes-header">
      {typeof header === "object" ? header : <h4>{header}</h4>}
    </header>
  )

  const renderReadOnly = () => {
    if (!formOptions.layer) return;

    const { fields } = formOptions.layer;
    const content = formOptions.layer.popupTemplate && formOptions.layer.popupTemplate.content;

    // only supporting FieldsContent element
    const fieldInfos: __esri.FieldInfo[] = Array.isArray(content) && content.some(e => e.type === "fields")
      ? (content.find(e => e.type === "fields") as __esri.FieldsContent).fieldInfos || []
      : [];

    const format = (f: __esri.Field | __esri.FieldInfo) => {
      const field = "format" in f
        ? fields.find(field => field.name.toLowerCase() === f.fieldName.toLowerCase())
        : f;
      const fieldInfo = "name" in f && "alias" in f
        ? fieldInfos.find(fi =>
          fi.fieldName.toLowerCase() === f.name.toLowerCase() ||
          fi.fieldName.toLowerCase() === f.alias.toLowerCase())
        : f;

      const format = fieldInfo && fieldInfo.format;
      const value = getFieldValue(feature, field, format);
      return (
        <div key={usePopupFields ? fieldInfo.fieldName : field.name} className="i-editor-attributes-row">
          <label>{usePopupFields ? fieldInfo.label : field.alias}</label>
          <div>{value == null ? <em>null</em> : value}</div>
        </div>
      );
    }
    return (
      <>
        <div className="i-editor-attributes-list">
          {
            usePopupFields
              ? fieldInfos.filter(filter).map(format)
              : fields.filter(filter).map(format)
          }
        </div>
        {renderReadOnlyMapButtons()}
      </>
    );
  }

  const renderReadOnlyMapButtons = () => {
    if (!feature) return null;
    const disabled: HeaderToolType[] = [];
    if (isFootprint) {
      disabled.push("duplicate");
      if (isEmpty === false) {
        disabled.push("delete", "edit");
      }
    }
    return (
      <MapButtons
        mode="duplicate-edit-delete"
        graphic={feature}
        updateState={"start"}
        supportsReflect={false}
        disabledTools={disabled}
        onDuplicate={() => {
          onDuplicate && onDuplicate(feature);
        }}
        onEdit={() => {
          onSetEdit(true);
        }}
        onDelete={() => {
          onDelete && onDelete(feature);
        }}
      />
    )
  }

  const reset = () => {
    setCanSave(false);
    const node = containerRef && containerRef.current;
    if (!node) {
      destroyWidget();
      return;
    }
    if (widgetRef.current) {
      destroyWidget();
    }
    renderEditable(node);
    onReset && onReset();
  }

  const save = () => {
    setCanSave(false);
    widgetRef.current && widgetRef.current.submit();
  }

  const setFormValues = (values: Record<string, any>) => {
    if (widgetRef.current && values) {
      for (const prop in values) {
        widgetRef.current.viewModel.setValue(prop, values[prop]);
      }
    }
  }

  const tooLong = (fieldInput: __esri.FieldInput) => {
    if (fieldInput.dataType !== "text") return false;
    const length = fieldInput.field && fieldInput.field.length;
    return length && fieldInput.value && (fieldInput.value as string).length > length;
  }

  const hasInvalidInput = () => {
    const result = { hasInvalid: false, fieldName: null, inputField: null };

    if (!widgetRef.current) {
      return result;
    }

    const inputs = widgetRef.current.viewModel.inputs
      .filter(f => f.type === "field" || f.type === "group")
      .map(f => f.type === "group"
        ? f.inputs as __esri.FieldInput[]
        : f as __esri.FieldInput)
      .flat();
    inputs.some(fieldInput => {
      if ((!fieldInput.valid || tooLong(fieldInput)) || invalidEmail(fieldInput)) {
        result.hasInvalid = true;
        result.fieldName = fieldInput.field.name;
        result.inputField = fieldInput;
      }
      return result.hasInvalid;
    });
    return result;
  }

  const validate = () => {
    const ff = widgetRef && widgetRef.current;
    if (!ff)
      return;
    ff.disabled = isDisabled;
    const result = hasInvalidInput();
    if (result.hasInvalid) {
      ff.submit();
      ff.viewModel.inputs.forEach(f => {
        if (f.type === "group" && f.state === "collapsed") {
          const found = f.inputs.some(el =>
            el.type === "field" && el.fieldName === result.fieldName);
          if (found) {
            f.state = "expanded";
          }
        }
      });
      setTimeout(() => {
        if (ff.container && result.fieldName) {
          const nd = (ff.container as HTMLElement).querySelector("[data-field-name='" + result.fieldName + "']");
          if (nd && typeof nd.scrollIntoView === "function") {
            nd.scrollIntoView({ block: "center", inline: "nearest" });
          }
        }
      }, 100);
    }

    return !result.hasInvalid;
  }

  useImperativeHandle(ref, () => ({
    getValues: () => widgetRef.current && widgetRef.current.getValues(),
    hasValidChanges,
    isValid: () => hasInvalidInput().hasInvalid === false,
    reset,
    save,
    setFormValues
  }));

  return (
    <div className={`i-editor-attributes`}>
      {renderHeader()}
      <div className={`i-editor-attributes-body${isDisabled ? " i-editor-attributes-disabled" : ""}`}>
        {layout === "form" || (isEditMode && hasPlan())
          ? <div ref={containerRef}></div>
          : renderReadOnly()
        }
      </div>
      {renderFooter()}
    </div>
  )
});

export default Attributes;