import * as localStorageUtil from "./localStorageUtil";

export function getRecentPlanIds() {
  const partialKey = localStorageUtil.PartialKeys.recentPlans;
  return localStorageUtil.parse(localStorageUtil.getAppUserItem(partialKey)) || [];
}

export function onPlanDeleted(plan) {
  let id;
  const partialKey = localStorageUtil.PartialKeys.recentPlans;
  if (plan && plan.versionInfo) {
    id = plan.versionInfo.versionGuid;
  } else if (plan && plan.planServiceItem) {
    id = plan.planServiceItem.id;
  }
  if (id) {
    let ids = getRecentPlanIds();
    const idx = ids.indexOf(id);
    if (idx !== -1) {
      ids.splice(idx,1);
      localStorageUtil.setAppUserItem(partialKey,JSON.stringify(ids));
    }
  }
}

export function onPlanOpened(plan) {
  let id, max = 5;
  const partialKey = localStorageUtil.PartialKeys.recentPlans;
  if (plan && plan.versionInfo) {
    id = plan.versionInfo.versionGuid;
  } else if (plan && plan.planServiceItem) {
    id = plan.planServiceItem.id;
  }
  if (id) {
    let ids = getRecentPlanIds();
    const idx = ids.indexOf(id);
    if (idx !== 0) {
      if (idx !== -1) ids.splice(idx,1);
      ids.unshift(id);
      ids = ids.slice(0,max);
      localStorageUtil.setAppUserItem(partialKey,JSON.stringify(ids));
    }
  }
}