import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import Context from "../../../context/Context";
import {
  getAttributes,
  getGeometry,
  getPersonAssignments,
  getUnitsUseType
} from "../../components/common/MultipleAssignments/multipleAssignmentsUtil";
import OfficePlan from "../OfficePlan";
import { addAttributeEdit, isPersonUnassigned, queryPeopleAssignedToUnit } from "../officePlanUtil";
import * as sourceUtil from "../sourceUtil";
import * as queryUtil from "../queryUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";
import * as val from "../../../util/val";

function afterAddPeople(results,undoTransaction,method) {
  // fix the undo (i.e. the delete) following an add
  const peopleLayer = sourceUtil.getPeopleLayer();
  const layerId = peopleLayer.layerId;
  const requestInfo = undoTransaction.requestInfo;
  const query = requestInfo.requestOptions.query;
  results.forEach(layerResult => {
    if (layerResult.id === layerId) {
      if (layerResult.addResults && layerResult.addResults.length > 0) {
        let updated = false, deletes = null;
        let edits = JSON.parse(query.edits);
        if (Array.isArray(edits)) {
          edits.forEach(layerEdit => {
            if (layerEdit.id === layerId) {
              deletes = layerEdit.deletes;
            }
          });
        }
        if (deletes) {
          layerResult.addResults.forEach((addResult,i) => {
            let newKey = addResult.globalId;
            //console.log("******************************* newKey",newKey)
            deletes[i] = newKey;
            updated = true;
          });
        }
        if (updated) {
          //console.log("new edits for addPerson.undo",edits)
          query.edits = JSON.stringify(edits);
        }
      }
    }
  });
}

function getRecords(allPeople, people, peopleDict, unitsDict, duplicates) {
  people.forEach(person => {
    const personAttributes = getAttributes(person)
    const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
    const uniquePeopleKey = personEmail
    const unitId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_UNIT_ID)

    if (!peopleDict[uniquePeopleKey]) peopleDict[uniquePeopleKey] = person
    else duplicates.push(person)

    if (unitId) {
      if (!unitsDict[unitId]) unitsDict[unitId] = 1
      else unitsDict[unitId] = unitsDict[unitId] + 1
    }
  })
  if (allPeople && typeof allPeople !== undefined) {
    allPeople.forEach(person => {
      const personAttributes = getAttributes(person)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const uniquePeopleKey = personEmail
      if (peopleDict[uniquePeopleKey]) {
        const recordMatch = compareOccupants(peopleDict[uniquePeopleKey], person)
        let dupMatch = false
        duplicates.forEach(d => {
          if (compareOccupants(d, person)) dupMatch = true
        })
        if (!recordMatch && !dupMatch && !isUnassigned(peopleDict[uniquePeopleKey])) {
          duplicates.push(peopleDict[uniquePeopleKey])
          delete peopleDict[uniquePeopleKey]
        } else if (isUnassigned(peopleDict[uniquePeopleKey])) {
          delete peopleDict[uniquePeopleKey]
        }
      }
    })
  }
}

function compareOccupants(occA, occB) {
  const attrsA = getAttributes(occA)
  const attrsB = getAttributes(occB)
  const unitA = aiimUtil.getAttributeValue(attrsA, FieldNames.PEOPLE_UNIT_ID)
  const unitB = aiimUtil.getAttributeValue(attrsB, FieldNames.PEOPLE_UNIT_ID)
  const areaA = aiimUtil.getAttributeValue(attrsA, FieldNames.AREA_ID)
  const areaB = aiimUtil.getAttributeValue(attrsB, FieldNames.AREA_ID)

  let match = false
  if (unitA && unitB && unitA === unitB) match = true
  if (areaA && areaB && areaA === areaB) match = true
  return match
}

function isUnassigned(occupant) {
  const attrs = getAttributes(occupant)
  const unitId = aiimUtil.getAttributeValue(attrs, FieldNames.PEOPLE_UNIT_ID)
  const areaId = aiimUtil.getAttributeValue(attrs, FieldNames.AREA_ID)
  return (!unitId && !areaId)
}

function getAllPeople() {
  const NUM_PER_QUERY = 1000
  return new Promise((resolve, reject) => {
    const source = sourceUtil.getPeopleSource()
    const options = {
      source,
      where: "1=1"
    }
    let totalCount, totalIterations
    queryUtil.queryCount(options).then(count => {
      totalCount = count
      totalIterations = Math.ceil(totalCount / NUM_PER_QUERY)
      let start = 0
      const promises = []
      for (let i = 0; i < totalIterations; i++) {
        const options = {
          source,
          lastFeatureCount : start,
          isShowAll: true
        }
        const promise = queryUtil.queryFeatures(options).then((result) =>{
          if (result && result.features && result.features.length > 0) {
            const resultInfo = queryUtil.makeResultInfo(source, result);
            const resultObject = {
              featureItems: resultInfo.featureItems,
              exceededTransferLimit: resultInfo.exceededTransferLimit
            }
            return resultObject;
          }
          return [];
        })
        promises.push(promise)
        start = start + NUM_PER_QUERY
      }
      return Promise.all(promises)
    }).then(results => {
      const people = []
      if (results && results.length > 0) {
        results.forEach(result => {
          if (result.featureItems && result.featureItems.length > 0) {
            result.featureItems.forEach(person => {
              people.push(person)
            })
          }
        })
      }
      resolve(people)
    }).catch(e => {
      console.error("Couldn't get count", e)
      resolve([])
    })
  })
}

function getUnitFeatures(unitsDict) {
  return getUnitsUseType(Object.keys(unitsDict))
}

export function prepare(task) {
  //console.log("bulkPeopleTransaction.prepare")
  return new Promise((resolve, reject) => {
    const transactionInfo = transaction.makeTransactionInfo()
    const basePeople = task.people
    const people = basePeople && basePeople.map(p => { return p.person || p })
    const assignToUnit = task.unit
    const assignToArea = task.area
    const associatedUnitFeatures = basePeople && basePeople.map(p => p.associatedUnit)
    const peopleDict = {}
    const unitsDict = {}
    let singleRecords = []
    const duplicates = []

    const makePeopleEdits = () => {
      const layer = sourceUtil.getPeopleLayer()
      const fields = layer.fields
      const keyField = layer.globalIdField
      const promises = []
      const updates = [], undoUpdates = [], deletes = [], undoDeletes = [], adds = [], undoAdds = []

      if (task.bulkUnassignPeople) {
        if (duplicates && duplicates.length > 0) {
          duplicates.forEach(person => {
            const personFeature = person.feature || person
            const personAttributes = getAttributes(person)
            const key = personAttributes[keyField]
            //const undo = {attributes: Object.assign({}, personAttributes)}
            const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
            deletes.push(key)
            undoDeletes.push(undo)
          })
        }
        if (singleRecords && singleRecords.length > 0) {
          singleRecords.forEach(person => {
            const personFeature = person.feature || person
            undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
            const update = {attributes: {}}
            const personAttributes = getAttributes(person)
            update.attributes[keyField] = personAttributes[keyField]
            transactionUtil.appendPersonUnassignment(task, fields, update)
            updates.push(update)
          })
        }
      }

      if (task.bulkAssignPeopleToUnit) {
        if (duplicates && duplicates.length > 0) {
          duplicates.forEach(person => {
            const personFeature = person.feature || person
            const personAttributes = getAttributes(person)
            const key = personAttributes[keyField]
            //const undo = {attributes: Object.assign({}, personAttributes)}
            const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
            deletes.push(key)
            undoDeletes.push(undo)
          })
        }
        if (singleRecords && singleRecords.length > 0) {
          singleRecords.forEach(person => {
            const personFeature = person.feature || person
            undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
            const update = {attributes: {}}
            const personAttributes = getAttributes(person)
            update.attributes[keyField] = personAttributes[keyField]
            addAttributeEdit(fields, update.attributes, FieldNames.PEOPLE_AREA_ID,null);
            const unitAttributes = getAttributes(assignToUnit)
            let unitGeometry = getGeometry(assignToUnit)
            transactionUtil.unitBasedFieldsForPeople.forEach(f => {
              let v = aiimUtil.getAttributeValue(unitAttributes, f)
              if (f === "unit_name") v = aiimUtil.getAttributeValue(unitAttributes, "name")
              addAttributeEdit(fields, update.attributes, f, v)
            })
            if (unitGeometry && typeof unitGeometry.clone !== "function") {
              const g = Context.instance.lib.esri.Graphic.fromJSON({
                attributes: unitAttributes,
                geometry: unitGeometry
              })
              unitGeometry = g.geometry
            }
            update.geometry = unitGeometry.clone().centroid
            updates.push(update)
          })
        }
      }

      if (task.bulkDuplicatePeopleToUnit) {
        // Check if person already has this assignment before duplicating!
        singleRecords.forEach(person => {
          const promise = Promise.resolve().then(() => {
            const personAttributes = getAttributes(person)
            if (personAttributes) {
              const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
              const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
              return getPersonAssignments(personName, personEmail)
            }
          }).then(assignments => {
            const alreadyAssigned = checkIfAlreadyAssigned(assignments)
            if (!alreadyAssigned) {
              // Check if this record is unassigned
              const unassigned = isPersonUnassigned(person)
              if (unassigned) {
                const personFeature = person.feature || person
                undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
                const update = {attributes: {}}
                const personAttributes = getAttributes(person)
                update.attributes[keyField] = personAttributes[keyField]
                addAttributeEdit(fields, update.attributes, FieldNames.PEOPLE_AREA_ID, null)
                const unitAttributes = getAttributes(assignToUnit)
                let unitGeometry = getGeometry(assignToUnit)
                transactionUtil.unitBasedFieldsForPeople.forEach(f => {
                  let v = aiimUtil.getAttributeValue(unitAttributes, f)
                  if (f === "unit_name") v = aiimUtil.getAttributeValue(unitAttributes, "name")
                  addAttributeEdit(fields, update.attributes, f, v)
                })
                if (unitGeometry && typeof unitGeometry.clone !== "function") {
                  const g = Context.instance.lib.esri.Graphic.fromJSON({
                    attributes: unitAttributes,
                    geometry: unitGeometry
                  })
                  unitGeometry = g.geometry
                }
                update.geometry = unitGeometry.clone().centroid
                updates.push(update)
              } else {
                const add = {attributes: {}}
                const newPerson = transactionUtil.clonePersonFeature(task, person.feature, "ensure-geometry")
                const attributes = newPerson && newPerson.attributes
                // TODO: Can we trust this function? Core bug.
                attributes[layer.globalIdField] = val.generateRandomUuid({includeBraces: true})
                delete attributes[layer.objectIdField]
                Object.keys(attributes).forEach(f => {
                  let v = aiimUtil.getAttributeValue(attributes, f)
                  addAttributeEdit(fields, add.attributes, f, v)
                })

                addAttributeEdit(fields, add.attributes, FieldNames.PEOPLE_AREA_ID, null)
                const unitAttributes = getAttributes(assignToUnit)
                let unitGeometry = getGeometry(assignToUnit)
                transactionUtil.unitBasedFieldsForPeople.forEach(f => {
                  let v = aiimUtil.getAttributeValue(unitAttributes, f)
                  if (f === "unit_name") v = aiimUtil.getAttributeValue(unitAttributes, "name")
                  addAttributeEdit(fields, add.attributes, f, v)
                })
                if (unitGeometry && typeof unitGeometry.clone !== "function") {
                  const g = Context.instance.lib.esri.Graphic.fromJSON({
                    attributes: unitAttributes,
                    geometry: unitGeometry
                  })
                  unitGeometry = g.geometry
                }
                add.geometry = unitGeometry.clone().centroid
                adds.push(add)
                undoAdds.push("?")
              }
            }
          })
          promises.push(promise)
        })
      }

      if (task.bulkAssignPeopleToArea) {
        if (duplicates && duplicates.length > 0) {
          duplicates.forEach(person => {
            const personFeature = person.feature || person
            const personAttributes = getAttributes(person)
            const key = personAttributes[keyField]
            //const undo = {attributes: Object.assign({}, personAttributes)}
            const undo = transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry");
            deletes.push(key)
            undoDeletes.push(undo)
          })
        }
        if (singleRecords && singleRecords.length > 0) {
          singleRecords.forEach(person => {
            const personFeature = person.feature || person
            undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
            const update = {attributes: {}}
            const personAttributes = getAttributes(person)
            update.attributes[keyField] = personAttributes[keyField]

            transactionUtil.appendPersonUnassignment(task,  fields, update)
            const areaAttributes = getAttributes(assignToArea)
            const areaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
            addAttributeEdit(fields, update.attributes, FieldNames.PEOPLE_AREA_ID, areaId)
            updates.push(update)
          })
        }
      }

      if (task.bulkDuplicatePeopleToArea) {
        // Check if person already has this assignment before duplicating!
        singleRecords.forEach(person => {
          const promise = Promise.resolve().then(() => {
            const personAttributes = getAttributes(person)
            if (personAttributes) {
              const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
              const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
              return getPersonAssignments(personName, personEmail)
            }
          }).then(assignments => {
            const alreadyAssigned = checkIfAlreadyAssigned(assignments)
            if (!alreadyAssigned) {
              // Check if this record is unassigned
              const unassigned = isPersonUnassigned(person)
              if (unassigned) {
                const personFeature = person.feature || person
                undoUpdates.push(transactionUtil.clonePersonFeature(task, personFeature, "ensure-geometry"))
                const update = {attributes: {}}
                const personAttributes = getAttributes(person)
                update.attributes[keyField] = personAttributes[keyField]

                transactionUtil.appendPersonUnassignment(task,  fields, update)
                const areaAttributes = getAttributes(assignToArea)
                const areaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
                addAttributeEdit(fields, update.attributes, FieldNames.PEOPLE_AREA_ID, areaId)
                updates.push(update)
              } else {
                const add = {attributes: {}}
                const newPerson = transactionUtil.clonePersonFeature(task, person.feature, "ensure-geometry")
                const attributes = newPerson && newPerson.attributes
                // TODO: Can we trust this function? Core bug.
                attributes[layer.globalIdField] = val.generateRandomUuid({includeBraces: true})
                delete attributes[layer.objectIdField]
                Object.keys(attributes).forEach(f => {
                  let v = aiimUtil.getAttributeValue(attributes, f)
                  addAttributeEdit(fields, add.attributes, f, v)
                })

                transactionUtil.appendPersonUnassignment(task, fields, add)
                const areaAttributes = getAttributes(assignToArea)
                const areaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
                addAttributeEdit(fields, add.attributes, FieldNames.PEOPLE_AREA_ID, areaId)
                adds.push(add)
                undoAdds.push("?")
              }
            }
          })
          promises.push(promise)
        })
      }

      return Promise.all(promises).then(() => {
        if (updates.length > 0 || deletes.length > 0 || adds.length > 0) {
          transactionInfo.peopleEdits = {
            adds: adds,
            updates: updates,
            deletes: deletes
          };
          transactionInfo.undo.peopleEdits = {
            deletes: undoAdds,
            updates: undoUpdates,
            adds: undoDeletes
          };
          transactionInfo.edits.push({
            id: layer.layerId,
            adds: adds,
            updates: updates,
            deletes: deletes
          });
          transactionInfo.undo.edits.push({
            id: layer.layerId,
            deletes: undoAdds,
            updates: undoUpdates,
            adds: undoDeletes
          });
          if (undoAdds && undoAdds.length > 0) {
            transactionInfo.afterApplyEdits = afterAddPeople;
          }
        }
      })
    }

    const makeUnitEdits = (units, unitsDict) => {
      // if (!hasUnits()) return Promise.resolve()
      const updates = [], undos = [], promises = []
      const layer = sourceUtil.getUnitsLayer()
      const fields = layer.fields
      const keyField = layer.globalIdField

      const unitsContainsUpdate = (key, keyField) => {
        let contains = false
        updates.forEach(u => {
          if (u.attributes[keyField] === key) contains = true
        })
        return contains
      }

      if (task.bulkUnassignPeople || task.bulkAssignPeopleToUnit || task.bulkAssignPeopleToArea) {
        units.forEach(unit => {
          // unit.feature.attributes
          const unitAttributes = getAttributes(unit)
          const unitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
          const promise = queryPeopleAssignedToUnit(unit).then((result) => {
            const count = result.length
            const peopleDifference = unitsDict[unitId]
            if ((peopleDifference && count === peopleDifference) || count === 0) {
              const key = unit.feature && unit.feature.attributes[keyField]
              const asnType = OfficePlan.SpaceAssignmentTypes.none
              undos.push(transactionUtil.cloneUnitFeature(task, unit.feature, "no-geometry"))
              const update = {attributes: {}}
              update.attributes[keyField] = key
              addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
              if (unitsContainsUpdate(key, keyField)) {
                // TODO?
              } else {
                updates.push(update)
              }
            }
          }).catch(e => {
            console.error("Error querying people assigned to unit", e);
          });
          promises.push(promise)
        })
      }

      if  (task.bulkAssignPeopleToUnit || task.bulkDuplicatePeopleToUnit) {
        const uf = assignToUnit.feature || assignToUnit
        const unitAttributes = getAttributes(assignToUnit)
        const key = unitAttributes[keyField]
        const asnType = OfficePlan.SpaceAssignmentTypes.office
        undos.push(transactionUtil.cloneUnitFeature(task, uf, "no-geometry"))
        const update = {attributes: {}}
        update.attributes[keyField] = key
        addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
        updates.push(update)
      }


      return Promise.all(promises).then(() => {
        transactionInfo.unitEdits = {updates: updates}
        transactionInfo.undo.unitEdits = {updates: undos}
        transactionInfo.edits.push({
          id: layer.layerId,
          updates: updates
        })
        transactionInfo.undo.edits.push({
          id: layer.layerId,
          updates: undos
        })
      }).catch(e => {
        console.error("Couldn't update units", e)
      })
    }

    const checkIfAlreadyAssigned = (assignments) => {
      // Check to see if this person already has this assignment
      if (!assignments) return false
      let isAlreadyAssigned = false
      if (assignToUnit) {
        const unitAttributes = getAttributes(assignToUnit)
        const toAssignUnitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
        const units = (assignments && assignments.units) || []
        units.forEach(unit => {
          const attributes = getAttributes(unit)
          const unitId = aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_ID)
          if (toAssignUnitId === unitId) isAlreadyAssigned = true
        })
      } else if (assignToArea) {
        const areaAttributes = getAttributes(assignToArea)
        const toAssignAreaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
        const areas = (assignments && assignments.areas) || []
        areas.forEach(area => {
          const attributes = getAttributes(area)
          const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
          if (toAssignAreaId === areaId) isAlreadyAssigned = true
        })
      }
      return isAlreadyAssigned
    }

    // const hasUnits = () => {
    //   if (!associatedUnitFeatures) return false
    //   let hasUnits = true
    //   associatedUnitFeatures.forEach(unit => {
    //     if (!unit) hasUnits = false
    //   })
    //   return hasUnits
    // }

    Promise.resolve().then(() => {
      if (task.bulkUnassignPeople) return getAllPeople()
    }).then(allPeople => {
      getRecords(allPeople, people, peopleDict, unitsDict, duplicates)
      singleRecords = Object.keys(peopleDict).map(key => peopleDict[key])
      return getUnitFeatures(unitsDict)
    }).then(units => {
      return makeUnitEdits(units, unitsDict)
    }).then(() => {
      return makePeopleEdits()
    }).then(() => {
      resolve(transactionInfo)
    }).catch(e => {
      reject(e)
    })
  })
}
