// aiim.datasets
import Dataset from "./Dataset";
import FieldNames from "./FieldNames";

// aiim.util
import * as aiimUtil from "../util/aiimUtil";
import { escSqlQuote } from "../util/selectionUtil";

// context
import Context from "../../context/Context";

// spaceplanner.base
import QueryAll from "../../spaceplanner/base/QueryAll";

const ROLES = {
  reservationManager: 1
};

export default class AreaRoles extends Dataset {
  table;
  areaIdField = FieldNames.AREA_ROLES_AREA_ID;
  emailField = FieldNames.AREA_ROLES_EMAIL;
  usernameField = FieldNames.AREA_ROLES_USERNAME;
  roleField = FieldNames.AREA_ROLES_ROLE;

  checkSchema() {
    const promise = new Promise((resolve, reject) => {
      if (this.layer2D) {
        const layer = this.layer2D;

        layer.when(() => {
          this.checkFieldNameProperty("areaIdField", layer.fields);
          this.checkFieldNameProperty("emailField", layer.fields);
          this.checkFieldNameProperty("usernameField", layer.fields);
          this.checkFieldNameProperty("roleField", layer.fields);

          resolve();
        }).catch(ex => {
          console.warn("Failed to load dataset layer:", layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  async queryAppUser() {
    const username = Context.getInstance().user.getUsername();
    const email = Context.getInstance().user.getEmail();
    if (!username || !email) {
      return;
    }

    const managerInfo = {
      reservationManagerIds: []
    };

    const clauses = [
      // `UPPER(${this.usernameField}) = '${escSqlQuote(username.toUpperCase())}'`,
      `UPPER(${this.emailField}) = '${escSqlQuote(email.toUpperCase())}'`,
      `${this.roleField} = ${ROLES.reservationManager}`
    ];
    const where = clauses.join(" AND ");

    try {
      const result = await this.queryAll({ where });
      if (result && result.features && result.features.length > 0) {
        result.features.forEach((feature) => {
          const areaId = aiimUtil.getAttributeValue(feature.attributes, FieldNames.AREA_ROLES_AREA_ID);
          if (!managerInfo.reservationManagerIds.includes(areaId)) {
            managerInfo.reservationManagerIds.push(areaId);
          }
        });
      }
      const areas = Context.instance.aiim.datasets && Context.instance.aiim.datasets.areas;
      if (areas && managerInfo.reservationManagerIds.length > 0) {
        managerInfo.unrestrictedIDs = await areas.queryUnrestrictedIDs();
      }
      return managerInfo;
    } catch(e) {
      console.error("Unable to query AREA_ROLES table", e);
      return;
    }
  }

  async queryAll(qaopts) {
    if (!this.url) {
      return;
    }

    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    qaopts = qaopts || {};
    if (qaopts.where) {
      query.where = qaopts.where;
    }
    const qa = new QueryAll();

    return qa.execute(url, query, qaopts);
  }
}
