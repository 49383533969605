import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  color: ${props => props.theme.palette.textColor};
  }
`;

export {
  Container
};
