import React from "react";
import Search from "calcite-react/Search";
import Select from "calcite-react/Select";
import Context from "../../../context/Context";
import ClearIcon from "calcite-ui-icons-react/XCircleIcon";
import {MenuItem} from "calcite-react/Menu";
import {ClearButton, LabelGroup, SelectGroup} from "../../styles/Filter/filterGroup";
import * as component from "../../../components/util/component";
import { IFilterPair } from "../../base/QueryCriteria";

interface ITextFilterProps {
  disableControl: boolean,
  items: { [prop: string]: string }[],
  pair: IFilterPair,
  onChange?: (e: string) => void,
  toolTip: string
}
interface ITextFilterState {
  items: Record<string, string>[],
  jsonObj: { [prop: string]: any},
  fieldValue: string,
  inputValue: string,
  selectedItem: string
}
export default class TextFilter extends React.Component<ITextFilterProps, ITextFilterState> {
  componentId: string;
  ref: React.MutableRefObject<HTMLInputElement>;
  
  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
        items: [],
        jsonObj: {},
        fieldValue : this.props.pair.value,
        inputValue: this.props.pair.value || '',
        selectedItem: this.props.pair.value || ''
    })
    this.ref = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.ref.current = document.getElementById(`${this.componentId}_textSelect`) as HTMLInputElement;
  }
  getKey=(obj, value)=> {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  clear=()=> {
    this.handleSelectChange(undefined);
    this.clearSearch();
  }

  clearSearch=()=>{
    this.setState({
      inputValue: '',
      selectedItem: '',
      fieldValue: ''
    },()=>{
      if(this.ref && this.ref.current) this.ref.current.value = '';
    })
    this.props.onChange(undefined);
  }

  searchChanged=(e)=> {
    this.setState({
      selectedItem: e
    })
    this.props.onChange(e);
  }

  onUserAction=(inputValue, selectedItemVal)=> {
    let obj = this.getJsonForm(this.props.items);
    let value;
    this.setState({
     inputValue : inputValue,
     selectedItem : selectedItemVal
    })
    if(selectedItemVal) value = obj[selectedItemVal];
    else value = obj[inputValue];
    this.props.onChange(value);
  }

  getJsonForm=(items: Record<string, string>[])=>{
    let tempObj = {};
    items.map((item) => {
      tempObj[item.name] = item.value;
    })
    return tempObj;
  }

  isSelectControl=()=>{
    const items = this.props.items;
    if((items && items.length <= 100) || (this.props.pair && (this.props.pair.id === "__sites__" 
      || this.props.pair.id === "__facilities__" ||  this.props.pair.id === "__levels__"))) {
        return true;
      }
    return false;
  }

  handleSelectChange=(value)=> {
    this.setState({
      fieldValue : value
    })
    this.props.onChange(value);
  }

  renderClearButton() {
    const i18n = Context.instance.i18n;
    if(!this.props.pair.value) return;
    return (
            <ClearButton
                iconButton
                icon={<ClearIcon size={16} />}
                title={i18n.filter.clearPart}
                onClick={this.clear}
            />
        );
  }

  render() {
    let jsonObj;
    if(this.props && this.props.items) jsonObj = this.getJsonForm(this.props.items);
    const isSelectControl = this.isSelectControl();
    const closeButton = this.renderClearButton();
    const obj = jsonObj;
    let items;
    if(obj) items = Object.keys(obj);
    let val = this.state.fieldValue;
    let inputVal = this.state.inputValue;
    let selectedVal = this.state.selectedItem;
    const i18n = Context.instance.i18n;

    if(isSelectControl) {
      return (
        <div>
          <SelectGroup>
            <LabelGroup>
              <label> {this.props.pair.label} </label>
              {!this.props.disableControl && closeButton}
            </LabelGroup>
          <Select 
            onBlur={(e)=>{
              if(!this.ref.current) this.ref.current = e.target;
            }}
            placeholder={i18n.filter.valuePrompt}
            disabled = {this.props.disableControl}
            id={this.componentId+"_textSelect"}
            filterable
            appendToBody
            style= {{width:"17.875rem"}}
            onChange={this.handleSelectChange}
            selectedValue={val || inputVal || selectedVal}>
            {items.map(name => {
            return (
              <MenuItem key={name} value={obj[name]}>
                {name}
              </MenuItem>
            )
            })}
          </Select>
          <div className={"i-filter-description"}>{this.props.toolTip}</div>
          </SelectGroup>
        </div>
      )
    }else {
      return (
            <div style={{marginBottom:"1.3rem", cursor:"pointer"}}>
              <LabelGroup>
                <label> {this.props.pair.label} </label>
                  {!this.props.disableControl && closeButton}
              </LabelGroup>
              <Search 
                placeholder={i18n.search.allPlaceholder}
                id={this.componentId+"_textSearch"}
                appendToBody
                style= {{width:"17.2rem"}}
                disabled = {this.props.disableControl}
                items = {items}
                inputValue={inputVal}
                selectedItem={selectedVal}
                onChange={this.searchChanged}
                onUserAction={this.onUserAction}
                onRequestClear={this.clearSearch}
                virtualized = {true}
              />
              <div className={"i-filter-description"}>{this.props.toolTip}</div>
            </div>
          )
    }
  }
}
