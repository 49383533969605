import React from "react";
import {connect} from "react-redux";

import ClosestFacility from "../../../aiim/base/ClosestFacility";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Item from "./Item";
import SortBy from "../../common/SortBy/SortBy";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import WorkOrderItem from "./WorkOrderItem";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as exploreUtil from "./exploreUtil";
import * as workOrderUtil from "../../../aiim/util/workOrderUtil";

const CSS = {
  main: "i-feature-items",
  moreButton: "i-category-more-button",
  workingIcon: "i-icon-working"
};

class Items extends React.Component {

  _activeJob;
  _activePromise;
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: true,
      exceededTransferLimit: false,
      featureCount: 0,
      featureItems: null,
      voField: null,
      wasMore: false
    });
    this.moreClicked = this.moreClicked.bind(this);
    this.sortByClicked = this.sortByClicked.bind(this);
  }

  canSortByClosest() {
    const url = Context.getInstance().config.closestFacilityServiceUrl;
    if (url) {
      const referenceLayer = Context.getInstance().session.referenceLayer;
      const item = referenceLayer.homeLocation;
      if (item && item.isValid()) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    this._mounted = true;
    this.queryFeatures(false,this.getSortOptions());

    component.own(this,[
      Topic.subscribe(Topic.HomeLocationSet,() => {
        if (!this._mounted) return;
        const state = this.state;
        const exceededTransferLimit = state.exceededTransferLimit;
        const featureCount = state.featureCount;
        const featureItems = state.featureItems;
        const voField = state.voField;
        const wasMore = state.wasMore;
        if (Array.isArray(featureItems) && featureItems.length > 0 &&
            !exceededTransferLimit && !wasMore && !this._activePromise &&
            this.getSortByClosest() && state.sortedByDistance) {
          const job = this._activeJob = {};
          this.sortResult(job,{
            exceededTransferLimit: exceededTransferLimit,
            featureCount: featureCount,
            featureItems: featureItems.slice(),
            voField: voField,
            wasMore: wasMore
          });
        } else {
          component.refresh(this); // to enable sort by distance
        }
      })
    ]);

  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  getSortByClosest(sortOptions) {
    if (this.canSortByClosest()) {
      let sortBy;
      if (sortOptions) {
        sortBy = sortOptions.sortBy;
      } else {
        try {
          sortBy = this.props.rdxSortBy;
        } catch(ex) {}
      }
      if (typeof sortBy === "string" && sortBy.length > 0) {
        if (sortBy.toLowerCase() !== "distance") {
          return false
        }
      }
      return true;
    }
    return false;
  }

  getSortOptions() {
    const sortBy = this.props.rdxSortBy;
    const sortDir = this.props.rdxSortDir;
    if (typeof sortBy === "string" && sortBy.length > 0) {
      return {
        sortBy: sortBy,
        sortDir: sortDir
      };
    }
    return null;
  }

  moreClicked(event) {
    if (this.state.isWorking) return;
    this.setState(state => {
      return {
        isWorking: true
      };
    });
    this.queryFeatures(true,this.getSortOptions());
  }

  queryFeatures(isMore,sortOptions) {
    const source = aiimUtil.getSource(this.props.sourceKey);
    const subTypeValue = this.props.subTypeValue;
    const lastCount = this.state.featureCount
    if (sortOptions && !sortOptions.sortBy && this._defaultSortField) {
      sortOptions.sortBy = this._defaultSortField;
    } else if (!sortOptions && this._defaultSortField) {
      sortOptions = {
        sortBy: this._defaultSortField,
        sortDir: "asc"
      }
    }
    const promise = this._activePromise = exploreUtil.queryFeatures(
      source,subTypeValue,isMore,lastCount,sortOptions);
    promise.then(result => {
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;
      const job = this._activeJob = {
        sortOptions: sortOptions
      };
      if (result && result.features) {
        const resultInfo = exploreUtil.makeResultInfo(source,result);
        let featureItems = resultInfo.featureItems;
        let featureCount = featureItems.length;
        const voField = resultInfo.voField
        if (isMore) {
          const prevFeatureItems = this.state.featureItems;
          if (prevFeatureItems && prevFeatureItems.length > 0) {
            featureItems = prevFeatureItems.concat(featureItems);
            featureCount = featureItems.length;
          }
        }
        this.sortResult(job,{
          exceededTransferLimit: !!result.exceededTransferLimit,
          featureCount: featureCount,
          featureItems: featureItems,
          voField: voField,
          wasMore: !!isMore
        });
      } else {
        if (job === this._activeJob) this._activeJob = null;
      }
    }).catch(ex => {
      console.warn("Error querying category features:");
      console.error(ex);
      if (!this._mounted) return;
      if (promise === this._activePromise) {
        this._activePromise = null;
        this.setState(state => {
          return {
            isWorking: false
          };
        });
        Topic.publishErrorAccessingData();
      }
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const nodes = [];
    const source = aiimUtil.getSource(this.props.sourceKey);
    const exceededTransferLimit = this.state.exceededTransferLimit;
    const featureItems = this.state.featureItems;
    if (Array.isArray(featureItems)) {
      featureItems.forEach((featuresItem,index) => {
        const nd = this.renderFeature(source,featuresItem,index);
        if (nd) nodes.push(nd);
      });
    }
    let working = null, content = null, noMatch = null;
    if (this.state.isWorking) {
      working = (<span key="workingIcon" className={CSS.workingIcon}></span>);
    }
    const sortBy = this.renderSortBy(source);
    if (nodes.length > 0) {
      content = (
        <ul className={CSS.main} aria-label={i18n.explore.itemsAriaLabel}>
          {nodes}
          {this.renderMore(nodes.length,exceededTransferLimit)}
        </ul>
      );
    } else if (!working) {
      noMatch = (<p role="alert" className={CSS.noMatch}>{i18n.general.noMatch}</p>);
    }

    let cls = undefined;
    if (source.workOrderMappings) cls = "i-workorder-listing";
    return (
      <div className={cls}>
        {working}
        {sortBy}
        {content}
        {noMatch}
      </div>
    );
  }

  renderFeature(source,featureItem,index) {
    const key = "item_"+featureItem.key;
    const subTypeValue = this.props.subTypeValue;
    if (source.workOrderMappings) {
      return (
        <WorkOrderItem key={key} sourceKey={source.key}
          subTypeValue={subTypeValue} featureItem={featureItem} />
      );
    } else {
      return (
        <Item key={key} sourceKey={source.key}
          subTypeValue={subTypeValue} featureItem={featureItem} />
      );
    }
  }

  renderMore(nodeCount,exceededTransferLimit) {
    const i18n = Context.getInstance().i18n;
    const name = i18n.explore.category.more;
    const hashref = "#";
    const isWorking = this.state.isWorking;

    if (nodeCount === 0 && isWorking) {
      return (
        <span key="workingIcon" className={CSS.workingIcon}></span>
      );
    }

    if (nodeCount > 0 && exceededTransferLimit) {
      let working = null;
      if (isWorking) {
        working = (
          <span key="workingIcon" className={CSS.workingIcon}></span>
        );
      }
      return (
        <li key="moreButton">
          <a href={hashref} className={CSS.moreButton} onClick={this.moreClicked}>
            {name}
            {working}
          </a>
        </li>
      );
    }
    return null;
  }

  renderSortBy(source) {
    let buttonGroup;
    const hasField = (fieldName) => {
      if (fieldName.toLowerCase() === "distance") {
        return true;
      } else if (source && source.layer2D) {
        return !!aiimUtil.findField(source.layer2D.fields,fieldName);
      }
      return false;
    };
    const isDateField = (fieldName) => {
      if (source && source.layer2D && fieldName.toLowerCase() !== "distance") {
        const f = aiimUtil.findField(source.layer2D.fields,fieldName);
        return (f && f.type === "date");
      }
      return false;
    };
    const i18n = Context.getInstance().i18n;
    const options = [];

    let sortOptions = source.sortOptions;
    if (source.workOrderMappings) {
      sortOptions = workOrderUtil.makeSortOptions(source);
    }
    if (Array.isArray(sortOptions)) {
      sortOptions = sortOptions.filter(opt => {
        return hasField(opt.field);
      });
    }

    let current = this.props.rdxSortBy || this._defaultSortField || "distance";
    if (Array.isArray(sortOptions)) {
      const canSortByClosest = this.canSortByClosest();
      let isDist = (typeof current === "string" && current.toLowerCase() === "distance");
      if (isDist && !canSortByClosest) {
        current = "";
        let disp = source && source.displayField;
        if (typeof disp === "string" && disp.length > 0) {
          disp = disp.toLowerCase();
        }
        let titleField;
        sortOptions.forEach(opt => {
          let field;
          if (typeof opt === "string") {
            opt = opt.trim();
            if (opt.length > 0) {
              field = opt;
            }
          } else if (typeof opt === "object") {
            field = opt.field;
            if (opt.isTitle) titleField = opt.field;
          }
          if (typeof field === "string" && field.toLowerCase() === disp) {
            current = field;
            if (!this._defaultSortField) this._defaultSortField = field;
          }
        });
        if (current === "" && titleField) {
          current = titleField;
          this._defaultSortField = titleField;
        }
      }

      sortOptions.forEach(opt => {
        let field, label, canReverseSort = true, defaultSortDir = "asc",
          disabled = false;
        if (typeof opt === "string") {
          opt = opt.trim();
          if (opt.length > 0) {
            field = opt;
            label = field;
            if (isDateField(field)) defaultSortDir= "desc";
          }
        } else if (typeof opt === "object") {
          field = opt.field;
          label = field;
          if (isDateField(field)) defaultSortDir= "desc";
          if (typeof opt.label === "string" && opt.label.length > 0) {
            label = opt.label;
          }
          if (typeof opt.canReverseSort === "boolean") {
            canReverseSort = opt.canReverseSort;
          }
          if (typeof opt.defaultSortDir === "boolean") {
            defaultSortDir = opt.defaultSortDir;
          }
        }
        if (typeof field === "string" && field.length > 0) {
          const key = field;
          const value = field;
          const checked = (current === value);
          if (field.toLowerCase() === "distance") {
            canReverseSort = false;
            defaultSortDir = "asc";
            if (!canSortByClosest) disabled = true;
          }
          const option = {
              key: key,
              label: label,
              value: value,
              canReverseSort: canReverseSort,
              defaultSortDir: defaultSortDir,
              checked: checked,
              disabled: disabled
          };
          options.push(option);
        }
      });
      if (options.length > 0 && typeof current === "string" &&
          current.length > 0 && current.toLowerCase() !== "distance") {
        const currentDir = this.props.rdxSortDir;
        let clsAsc, clsDesc;
        if (currentDir === "desc") clsDesc = "i--active";
        else clsAsc = "i--active";
        buttonGroup = (
          <div key="sorting" className="i-sorting-button">
            <span key="asc" title={i18n.portalItemBrowser.sortBy.ascTooltip}>
              <button className={clsAsc}
                onClick={this.sortingAsc}>{Icons.ascendingSort()}</button>
            </span>
            <span key="desc" title={i18n.portalItemBrowser.sortBy.descTooltip}>
              <button className={clsDesc}
                onClick={this.sortingDesc}>{Icons.descendingSort()}</button>
            </span>
          </div>
        );
      }
    }

    if (options.length > 0) {
      //console.log("rdxSortBy",this.props.rdxSortBy, new Date());
      const node = (
      <SortBy
        caption= {i18n.explore.category.sortBy}
        options= {options}
        currentDir={this.props.rdxSortDir}
        buttonGroup= {buttonGroup}
        sortByClicked= {this.sortByClicked}
        sortBy= {current}
        sortingAsc= {this.sortingAsc}
        sortingDesc= {this.sortingDesc}
      >
      </SortBy>
      )
      return node;
  }
    return null;
  }

  sortByClicked(menuOption) {
    const sortByKey = this.props.rdxKey+"__sortBy";
    const sortDirKey = this.props.rdxKey+"__sortDir";
    const current = this.props.rdxSortBy;
    const currentDir = this.props.rdxSortDir;
    const sortBy = menuOption.value;
    let sortDir = "asc";
    if (current && current === sortBy) {
      if (menuOption.canReverseSort) {
        if (currentDir === "asc") sortDir = "desc";
        Rdx.setValue(this,sortDirKey,sortDir);
        this.queryFeatures(false,{
          sortBy: sortBy,
          sortDir:sortDir
        });
      }
    } else {
      sortDir = menuOption.defaultSortDir;
      Rdx.setValue(this,sortByKey,sortBy);
      Rdx.setValue(this,sortDirKey,menuOption.defaultSortDir);
      this.queryFeatures(false,{
        sortBy: sortBy,
        sortDir:sortDir
      });
    }
  }

  sortingAsc=()=>{
    //console.log("Ascending");
    if (this.props.rdxSortBy === "distance") return;
    const sortDir = "asc";
    const sortDirKey = this.props.rdxKey+"__sortDir";
    Rdx.setValue(this,sortDirKey,sortDir);
    this.queryFeatures(false,{
      sortBy: this.props.rdxSortBy,
      sortDir: sortDir
    });
  }

  sortingDesc=()=>{
    if (this.props.rdxSortBy === "distance") return;
    const sortDir = "desc";
    const sortDirKey = this.props.rdxKey+"__sortDir";
    Rdx.setValue(this,sortDirKey,sortDir);
    this.queryFeatures(false,{
      sortBy: this.props.rdxSortBy,
      sortDir: sortDir
    });
  }

  sortResult(job,info) {

    const clearJob = (ex) => {
      if (job === this._activeJob) this._activeJob = null;
      if (ex) {
        console.warn("Error sorting items by network distance.");
        console.error(ex);
      }
    };

    const updateState = (featureItems,sortedByDistance) => {
      if (!this._mounted) return;
      this.setState(state => {
        return {
          isWorking: false,
          exceededTransferLimit: !!info.exceededTransferLimit,
          featureCount: info.featureCount,
          featureItems: featureItems || info.featureItems,
          voField: info.voField,
          wasMore: info.wasMore,
          sortedByDistance: !!sortedByDistance
        };
      });
    };

    let cf, fromFeature, fromSource, sortByClosest = false;

    if (info.featureItems && info.featureItems.length > 0) {
      sortByClosest = (!info.wasMore && !info.exceededTransferLimit && this.getSortByClosest());
      if (sortByClosest) {
        cf = new ClosestFacility();
        fromFeature = cf.getFromFeature();
        fromSource = cf.getFromSource();
        if (!fromFeature) sortByClosest = false;
      }
    }
    if (!sortByClosest) {
      clearJob();
      updateState();
      return;
    }

    const featureItems = info.featureItems.map(featureItem => {
      return {
        key: featureItem.key,
        feature: featureItem.feature
      };
    });
    const source = aiimUtil.getSource(this.props.sourceKey);
    cf.euclideanSort(fromFeature,featureItems,info.voField,fromSource,source);
    updateState(featureItems,true);

    const max = cf.proximityMaxItemsToSort;
    const topItems = featureItems.slice(0,max);
    if (topItems.length > 0) {
      cf.networkSort(fromFeature,topItems).then(() => {
        if (job === this._activeJob) {
          // merge results
          for (let i=0;i<topItems.length;i++) {
            featureItems[i] = topItems[i];
          }
          clearJob();
          updateState(featureItems,true);
        }
      }).catch(ex => {
        clearJob(ex);
      });
    } else {
      clearJob();
    }
  }

}

const mapStateToProps = (state,ownProps) => {
  const sortByKey = ownProps.rdxKey+"__sortBy";
  const sortDirKey = ownProps.rdxKey+"__sortDir";
  return {
    rdxSortBy: Rdx.getValue(state,sortByKey),
    rdxSortDir: Rdx.getValue(state,sortDirKey)
  }
}

export default connect(mapStateToProps)(Items);
