import React from "react";
import Context from "../../../context/Context";

import * as component from "../../../components/util/component";
import * as wallTypesUtil from "./wallTypesUtil"

import {
    CalciteBlock,
    CalciteCombobox,
    CalciteComboboxItem
  } from "@esri/calcite-components-react";
import Info from "./Info";
import Topic from "../../../context/Topic";


interface Props {

}

interface State {
  allTypes: { value: string | number, name: string }[],
  wallTypeValues: string[]
}

export default class WallTypes extends React.Component<Props, State>{

  constructor(props?: Props) {
    super(props);
    this.state = component.newState({
      allTypes: null,
      wallTypeValues: null
    })
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ReloadViews,() => {
        wallTypesUtil.validateWallTypes();
        this.init();
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.init();
      })
    ]);
    this.init();
  }

  apply = (wallTypeValues) => {
    const config = Context.instance.config;
    config.spaceplanner.wallTypeValues = wallTypeValues;
  }

  init=()=> {
    const planner = Context.instance.spaceplanner.planner;
    const detailsLayer = planner && planner.getProjectDetailsLayer();
    const allTypes = wallTypesUtil.getAllTypes(detailsLayer);
    const wallTypeValues = Context.instance.config.spaceplanner.wallTypeValues;

    this.setState({
      allTypes: allTypes,
      wallTypeValues: wallTypeValues
    })
  }

  render() {
    const i18n = Context.instance.i18n;
    const allTypes = this.state.allTypes && this.state.allTypes.length > 0;
    const noFilter = i18n.switcher.wallTypes.noFilter;
    const noFilterCss = {
      lineHeight: "12px",
      fontSize: "10px",
      fontWeight: "500"
    }

    return (
      <CalciteBlock 
        className="i-wallTypes-block"
        open 
        heading={i18n.switcher.wallTypes.caption}
        description={i18n.switcher.wallTypes.description}
      >
        <div className="i-min-block">
          {this.renderContent()}
        </div>
        {allTypes ? <Info message={i18n.switcher.wallTypes.information}/> : <span style={noFilterCss}>{noFilter}</span>}

      </CalciteBlock>
    )
  }

  renderContent() {
    const i18n = Context.instance.i18n;
    const allTypes = this.state.allTypes && this.state.allTypes.length > 0;
    const list = allTypes && this.state.allTypes.map(item => this.renderItem(item));
    const disableCombo = !!!allTypes;

    return (
      <div>
        <span>{i18n.switcher.wallTypes.useTypes}</span>
        <div>
          <CalciteCombobox 
            allowCustomValues={false} 
            label="items"
            disabled={disableCombo ? true : undefined}
            placeholder={i18n.switcher.wallTypes.placeholder}
            onBlur={ e => {
              try {
                //@ts-ignore
                e.target.resetText();
                //@ts-ignore
                e.target.filterItems("");
              } catch(ex) {
                console.error("Error reseting text", ex);
              }
            }}
            onCalciteComboboxChange={e => {
              let wallTypeValues = [];
              e.target && e.target.selectedItems && e.target.selectedItems.some(item => {
                if (item.selected) {
                  wallTypeValues.push(item.value);
                }
              });
              this.setState({
                wallTypeValues: wallTypeValues
              })
              this.apply(wallTypeValues);
            }}
          >
            {list}
          </CalciteCombobox>
        </div>
      </div>
    )
  }

  renderItem(item) {
    const wallTypeValues = this.state.wallTypeValues;
    const selected = wallTypeValues && wallTypeValues.indexOf(item.value) !== -1;
    return (
      <CalciteComboboxItem 
        key={item.value} 
        value={item.value} 
        textLabel={item.name} 
        selected={selected}
      />
    )
  }
}