import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import OfficePlan from "./OfficePlan";
import QueryAll from "./QueryAll";
import Reader from "./Reader";
import Topic from "../../context/Topic";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as mergeUtil from "./mergeUtil";
import * as placeholderUtil from "./placeholderUtil";
import * as portalUtil from "../../util/portalUtil";
import * as saveAsUtil from "./saveAsUtil";
import * as selectionUtil from "../../aiim/util/selectionUtil";
import * as serviceUtil from "./serviceUtil";
import * as sourceUtil from "./sourceUtil";
import * as transactionUtil from "./transaction/transactionUtil";
import { lang } from "moment";

export default class HostedMerge extends BaseClass {

  /*

     Plan edits take priority
       - loop through master edits and remove features that have been updated by the plan
       - apply master edits to the plan
       - run the analysis, apply edits to the plan
       - exctract changes from plan
       - apply edits to master

       - what is the geometry of a unit is reshaped in the master?
       - does the user have edit access to master
       - upserts may be an issue
       - objectids may be an issue

   */

  afterCreatePlan(createTask) {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      const hmInfo = createTask.hostedMergeInfo;
      task.plan.sourceInfo = {
        planId: createTask.plan.serviceItemId,
        serviceUrl: createTask.plan.serviceUrl,
        serviceInfo: createTask.plan.serviceInfo,
        peopleLayerInfo: createTask.plan.peopleLayerInfo,
        unitsLayerInfo: createTask.plan.unitsLayerInfo,
        detailsLayerInfo: createTask.plan.detailsLayerInfo,
        areasTableInfo: createTask.plan.areasTableInfo,
        areaRolesTableInfo: createTask.plan.areaRolesTableInfo,
        supportInfo: createTask.supportInfo,
        parentSupportInfo: hmInfo.parentSupportInfo
      };
      task.supportLayerEdits = [];

      Promise.resolve().then(() => {
        let section = task.plan;
        const opts = {returnIdsOnly: true};
        return this._extractChanges(task,section,section.sourceInfo,opts).then(() => {
          const masterServerGens = hmInfo && hmInfo.masterServerGens;
          const planServerGens = task.plan.changes.layerServerGens;
          if (masterServerGens || planServerGens) {
            const properties = {
              indoors: {}
            };
            if (masterServerGens) {
              properties.indoors.masterServerGens = masterServerGens;
            }
            if (planServerGens) {
              properties.indoors.planServerGens = planServerGens;
            }
            createTask.newItemProperties = properties;
          }
        });
      }).then(() => {
        if (createTask.isSaveAs) {
          return this._readAllGlobals(task,task.plan);
        }
      }).then(() => {
        if (createTask.isSaveAs) {
          return saveAsUtil.readSupportLayers(task);
        }
      }).then(() => {
        if (createTask.isSaveAs) {
          const edits = this._makeReapplyEdits({
            peopleLayerInfo: createTask.plan.peopleLayerInfo,
            unitsLayerInfo: createTask.plan.unitsLayerInfo,
            detailsLayerInfo: createTask.plan.detailsLayerInfo,
            areasTableInfo: createTask.plan.areasTableInfo,
            areaRolesTableInfo: createTask.plan.areaRolesTableInfo,
            peopleGlobalIndex: task.plan.peopleGlobalIndex,
            unitsGlobalIndex: task.plan.unitsGlobalIndex,
            areasGlobalIndex: task.plan.areasGlobalIndex,
            areaRolesGlobalIndex: task.plan.areaRolesGlobalIndex,
            planChanges: hmInfo && hmInfo.planChanges
          })
          if (task.supportLayerEdits.length > 0) {
            task.supportLayerEdits.forEach(le => {
              edits.push(le);
            })
          }
          if (edits && edits.length > 0) {
            const saveAsTask = {
              sourceInfo: task.plan.sourceInfo,
              edits: edits,
              timeout: task.timeout
            };
            //console.log("saveAsTask",saveAsTask)
            return saveAsUtil.applyParentPlanEdits(saveAsTask);
          }
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  beforeCreatePlan(createTask) {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      let section = task.master;
      let opts = {returnIdsOnly: true};
      this._extractChanges(task,section,section.sourceInfo,opts).then(() => {
        createTask.hostedMergeInfo = {
          masterServerGens: task.master.changes.layerServerGens
        };
      }).then(() => {
        if (createTask.isSaveAs) {
          if (task.activePlan && task.activePlan.supportInfo) {
            createTask.hostedMergeInfo.parentSupportInfo = task.activePlan.supportInfo;
          }
          let section = task.plan;
          let opts = {returnIdsOnly: false};
          return this._extractChanges(task,section,section.sourceInfo,opts).then(() => {
            createTask.hostedMergeInfo.planChanges = task.plan.changes;
          });
        }
      }).then(() => {
        //console.log("HM.createTask",createTask)
        resolve();
      }).catch(ex => {
        reject(ex);
      });

    });
    return promise;
  }

  defaultHasChanges() {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      const section = task.master;
      Promise.resolve().then(() => {
        return this._readServiceItem(task);
      }).then(() => {
        const opts = {returnIdsOnly: true};
        return this._extractChanges(task,section,section.sourceInfo,opts);
      }).then(() => {
        const hasChanges = this._hasEdits(task,section);
        resolve({hasChanges: hasChanges});
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });
    });
    return promise;
  }

  hasDifferences() {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      const section = task.plan;
      Promise.resolve().then(() => {
        return this._readServiceItem(task);
      }).then(() => {
        const opts = {returnIdsOnly: true};
        return this._extractChanges(task,section,section.sourceInfo,opts);
      }).then(() => {
        const hasDiff = this._hasEdits(task,section);
        resolve({hasDifferences: hasDiff});
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });
    });
    return promise;
  }

  merge(options) {
    const promise = new Promise((resolve,reject) => {
      const activePlan = Context.instance.spaceplanner.activePlan;
      const mergeInfo = {
        wasMerged: false,
        wasReconciled: false,
        reconcileOnly: !!(options && options.reconcileOnly),
        noAnalysisRequired: false
      };

      Promise.resolve().then(() => {
        return mergeUtil.beforeHostedMerge(mergeInfo);
      }).then(() => {
        return this._pull(mergeInfo).then(() => {
          mergeInfo.wasReconciled = true;
        });
      }).then(() => {
        if (!mergeInfo.noAnalysisRequired || (options && options.replacePlaceholderEdits)) {
          const opts = {applyEdits: true};
          if (options.replacePlaceholderEdits) {
            opts.replacePlaceholderEdits = options.replacePlaceholderEdits;
          }
          //opts.applyEdits = false;
          return mergeUtil.analyzeHosted(mergeInfo,opts);
        }
      }).then(() => {
        //console.log("afterAnalyzeHosted...........................",mergeInfo)
        //if (true) throw new Error("tmp1");
        if (!mergeInfo.reconcileOnly) {
          return this._push(mergeInfo);
        }
      }).then(() => {
        if (!mergeInfo.editsApplied && mergeInfo.pullTask) {
          mergeInfo.editsApplied = !!mergeInfo.pullTask.plan.editsApplied;
        }
        if (mergeInfo.pushTask) {
          mergeInfo.wasMerged = !!mergeInfo.pushTask.master.editsApplied;
        }
      }).then(() => {
        if (mergeInfo.editsApplied) {
          let lyr = sourceUtil.getPeopleLayer();
          if (lyr && typeof lyr.refresh === "function") lyr.refresh();
          lyr = sourceUtil.getUnitsLayer();
          if (lyr && typeof lyr.refresh === "function") lyr.refresh();
          return OfficePlan.getActive().areasTable._refresh();
        }
      }).then(() => {
        if (mergeInfo.editsApplied || mergeInfo.wasMerged) {
          const task = {activePlan: activePlan};
          return this._readServiceItem(task,true);
        }
      }).then(() => {
        if (mergeInfo.editsApplied || mergeInfo.wasMerged) {
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
            wasReconciled: true
          });
        }
      }).then(() => {
        console.log("HostedMerge.mergeInfo",mergeInfo)
        resolve(mergeInfo);
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });
    });
    return promise;
  }

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  _applyEdits(task,edits,direction) {
    if (!edits && !!edits.length === 0) return Promise.resolve();
    const promise = new Promise((resolve,reject) => {
      const isPull = (direction === "pull");
      const isPush = (direction === "push");
      const masterSourceInfo = task.master.sourceInfo;
      const planSourceInfo = task.plan.sourceInfo;
      const timeout = task.timeout;
      const newServerGens = {masterServerGens: null};

      let serviceUrl;
      if (isPull) {
        serviceUrl = planSourceInfo.serviceUrl;
      } else {
        serviceUrl = masterSourceInfo.serviceUrl;
      }
      let url = serviceUrl+"/applyEdits";
      url = aiimUtil.appendTokenToUrl(url);
      const params = {
        f: "json",
        useGlobalIds: true,
        rollbackOnFailure: true,
        edits: JSON.stringify(edits)
      }
      const options = {
        query: params,
        method: "post",
        responseType: "json",
        timeout: timeout
      };

      console.log("HostedMerge.applyingEdits...",edits)
      // if (true) {
      //   resolve();
      //   return;
      // }
      const esriRequest = Context.instance.lib.esri.esriRequest;
      esriRequest(url,options).then(result => {
        console.log("HostedMerge.applyEdits.result",result);
        serviceUtil.validateApplyEditsResponse(result);
        if (isPush) {
          return this._fetchMasterServerGens(newServerGens);
        }
      }).then(() => {
        let masterServerGens, planServerGens;
        if (isPull) {
          masterServerGens = task.master.changes.layerServerGens;
          task.plan.editsApplied = true;
        } else {
          masterServerGens = newServerGens.masterServerGens;
          planServerGens = task.plan.changes.layerServerGens;
          task.master.editsApplied = true;
        }
        const serviceItem = task.plan.serviceItem;
        return this._trackServerGens(serviceItem,masterServerGens,planServerGens);
      }).then(() => {
        resolve();
      }).catch(ex => {
        //console.log("************************* HostedMerge.applyEdits.catch",ex)
        reject(ex);
      });
    });
    return promise;
  }

  _checkMasterChanges(task) {
    // last in wins,
    // remove updates being pulled from master if an update for that feature exists in the plan
    // remove adds being pulled from master if that global id exists
    this._collectPlanIds(task);
    let edits = task.master.changes.edits;
    const sourceInfo = task.master.sourceInfo;
    const peopleLayerInfo = sourceInfo.peopleLayerInfo;
    const unitsLayerInfo = sourceInfo.unitsLayerInfo;
    const areasTableInfo = sourceInfo.areasTableInfo;
    const areaRolesTableInfo = sourceInfo.areaRolesTableInfo;
    const preserveUnitGeometriesByGID = task.preserveUnitGeometriesByGID;
    const hasAreaRoles = !!areaRolesTableInfo;
    //console.log("_checkMasterChanges",task)
    edits.forEach(layerEdits => {
      let layerInfo, updatedIds, globalIdIndex;
      if (layerEdits.id === peopleLayerInfo.id) {
        layerInfo = peopleLayerInfo;
        updatedIds = task.plan.updatedPeopleIds;
        globalIdIndex = task.plan.peopleGlobalIndex;
      } else if (layerEdits.id === unitsLayerInfo.id) {
        layerInfo = unitsLayerInfo;
        updatedIds = task.plan.updatedUnitIds;
        globalIdIndex = task.plan.unitsGlobalIndex;
      } else if (layerEdits.id === areasTableInfo.id) {
        layerInfo = areasTableInfo;
        updatedIds = task.plan.updatedAreaIds;
        globalIdIndex = task.plan.areasGlobalIndex;
      } else if (hasAreaRoles && layerEdits.id === areaRolesTableInfo.id) {
        layerInfo = areaRolesTableInfo;
        updatedIds = task.plan.updatedAreaRoleIds;
        globalIdIndex = task.plan.areaRolesGlobalIndex;
      }
      globalIdIndex = globalIdIndex || {};
      let globalIdField = layerInfo.globalIdField;

      let updates = layerEdits.features.updates;
      let updates2 = [];
      updates.forEach(f => {
        let gid = f.attributes[globalIdField];
        if (typeof gid === "string") gid = gid.toUpperCase();
        // plan edits take precedence
        if (!updatedIds[gid]) {
          updates2.push(f);
        } else {
          if (preserveUnitGeometriesByGID && preserveUnitGeometriesByGID[gid]) {
            f.attributes = {
              [globalIdField]: f.attributes[globalIdField]
            }
            updates2.push(f);
          }
        }
      });
      if (updates.length !== updates2.length) {
        layerEdits.features.updates = updates2;
      }

      let adds = layerEdits.features.adds;
      if (adds && adds.length > 0) {
        let adds2 = [];
        adds.forEach(f => {
          let gid = f.attributes[globalIdField];
          if (typeof gid === "string") gid = gid.toUpperCase();
          if (!globalIdIndex[gid]) {
            // plan edits take precedence, don't re-add
            // TODO does this work for people?
            // TODO also do this only if it hasn't been deleted?
            adds2.push(f);
          }
        });
        if (adds.length !== adds2.length) {
          layerEdits.features.adds = adds2;
        }
      }

      let deleteIds = layerEdits.features.deleteIds;
      if (deleteIds && deleteIds.length > 0) {
        let deleteIds2 = [];
        deleteIds.forEach(gid => {
          if (typeof gid === "string") gid = gid.toUpperCase();
          if (!updatedIds[gid]) {
            // plan edits take precedence
            deleteIds2.push(gid);
          }
          /*
          if (!globalIdIndex[gid]) {
            deleteIds2.push(gid);
          }
          */
        });
        if (deleteIds2.length !== deleteIds.length) {
          layerEdits.features.deleteIds = deleteIds2;
        }
      }

    });
  }

  _checkPlanChanges(task,mergeInfo) {
    // avoid re-pushing updates that have just been pulled from master,
    //   updates that were common to master and plan were previously
    //   removed from the pull (last in wins, _checkMasterChanges)
    // avoid re-pushing adds and deletes

    let edits = task.plan.changes.edits;
    const placeholderIds = task.plan.placeholderIds;
    const sourceInfo = task.plan.sourceInfo;
    const peopleLayerInfo = sourceInfo.peopleLayerInfo;
    const unitsLayerInfo = sourceInfo.unitsLayerInfo;
    const areasTableInfo = sourceInfo.areasTableInfo;
    const areaRolesTableInfo = sourceInfo.areaRolesTableInfo;
    const hasAreaRoles = !!areaRolesTableInfo;

    let pulledEdits, pulledPeopleEdits, pulledUnitEdits;
    let pulledAreaEdits, pulledAreaRoleEdits;
    if (mergeInfo.pullTask) {
      const masterSourceInfo = task.master.sourceInfo;
      const masterPeopleLayerInfo = masterSourceInfo.peopleLayerInfo;
      const masterUnitsLayerInfo = masterSourceInfo.unitsLayerInfo;
      const masterAreasTableInfo = masterSourceInfo.areasTableInfo;
      const masterAreaRolesTableInfo = masterSourceInfo.areaRolesTableInfo;
      const hasMasterAreaRoles = !!masterAreaRolesTableInfo;
      pulledEdits = mergeInfo.pullTask.master.changes.edits;
      pulledEdits.forEach(layerEdits => {
        if (layerEdits.id === masterPeopleLayerInfo.id) {
          pulledPeopleEdits = layerEdits;
        } else if (layerEdits.id === masterUnitsLayerInfo.id) {
          pulledUnitEdits = layerEdits;
        } else if (layerEdits.id === masterAreasTableInfo.id) {
          pulledAreaEdits = layerEdits;
        } else if (hasMasterAreaRoles && layerEdits.id === masterAreaRolesTableInfo.id) {
          pulledAreaRoleEdits = layerEdits;
        }
      });
    }
    if (!pulledEdits) return;

    let preserveUnitGeometriesByGID = (mergeInfo.pullTask &&
      mergeInfo.pullTask.preserveUnitGeometriesByGID);

    let peopleConsistencyUpdates, unitConsistencyUpdates;
    //let areaConsistencyInserts;
    const mergeUtilTask = mergeInfo.mergeUtilTask;
    if (mergeUtilTask && mergeUtilTask.planData) {
      peopleConsistencyUpdates = mergeUtilTask.planData.peopleUpdates;
      unitConsistencyUpdates = mergeUtilTask.planData.unitUpdates;
      //areaConsistencyInserts = mergeUtilTask.planData.areaInserts;
    }

    const collectGlobalIds = (layerInfo,list) => {
      let index = {};
      if (list && list.length > 0) {
        let field = layerInfo.globalIdField;
        list.forEach(f => {
          const id = f.attributes[field];
          index[id] = 1;
        })
      }
      return index;
    }

    const collectObjectIds = (layerInfo,list) => {
      let index = {};
      if (list && list.length > 0) {
        let field = layerInfo.objectIdField;
        list.forEach(f => {
          const id = f.attributes[field];
          index[id] = 1;
        })
      }
      return index;
    }

    const checkAdds = (layerInfo,list,pulledList) => {
      if ((list && list.length > 0) && (pulledList && pulledList.length > 0)) {
        let pulledGids = collectGlobalIds(layerInfo,pulledList);
        let gidField = layerInfo.globalIdField;
        let list2 = [];
        list.forEach(f => {
          let gid = f.attributes[gidField];
          let wasPulled = !!pulledGids[gid];
          if (!wasPulled) list2.push(f);
        });
        if (list.length !== list2.length) return list2;
      }
      return null;
    }

    const checkDeletes = (layerInfo,list,pulledList) => {
      if ((list && list.length > 0) && (pulledList && pulledList.length > 0)) {
        let list2 = [];
        list.forEach(gid => {
          let wasPulled = (pulledList.indexOf(gid) !== -1);
          if (!wasPulled) list2.push(gid);
        });
        if (list.length !== list2.length) return list2;
      }
      return null;
    }

    edits.forEach(layerEdits => {
      let layerInfo, pulledLayerEdits, consistencyUpdates;
      let isPeople = false, isUnits = false;
      let isAreas = false, isAreaRoles = false;
      if (layerEdits.id === peopleLayerInfo.id) {
        isPeople = true;
        layerInfo = peopleLayerInfo;
        pulledLayerEdits = pulledPeopleEdits;
        consistencyUpdates = peopleConsistencyUpdates;
      } else if (layerEdits.id === unitsLayerInfo.id) {
        isUnits = true;
        layerInfo = unitsLayerInfo;
        pulledLayerEdits = pulledUnitEdits;
        consistencyUpdates = unitConsistencyUpdates;
      } else if (layerEdits.id === areasTableInfo.id) {
        isAreas = true
        layerInfo = areasTableInfo;
        pulledLayerEdits = pulledAreaEdits;
      } else if (hasAreaRoles && layerEdits.id === areaRolesTableInfo.id) {
        isAreaRoles = true
        layerInfo = areaRolesTableInfo;
        pulledLayerEdits = pulledAreaRoleEdits;
      }

      if (layerInfo && pulledLayerEdits && !isAreas && !isAreaRoles) {
        let updates = layerEdits.features.updates;
        let pulledUpdates = pulledLayerEdits.features.updates;
        if ((updates && updates.length > 0) &&
            (pulledUpdates && pulledUpdates.length > 0)) {

          let pulledGids = collectGlobalIds(layerInfo,pulledUpdates);
          let consistencyOids = collectObjectIds(layerInfo,consistencyUpdates);
          let gidField = layerInfo.globalIdField;
          let oidField = layerInfo.objectIdField;
          let updates2 = [];
          updates.forEach(f => {
            let keep = true;
            let gid = f.attributes[gidField];
            let oid = f.attributes[oidField];
            let wasPulled = !!pulledGids[gid];
            let wasUpdatedForConsistency = !!consistencyOids[oid];
            if (wasPulled) {
              keep = false;
              if (wasUpdatedForConsistency) {
                keep = true;
              } else {
                if (preserveUnitGeometriesByGID && typeof gid === "string") {
                  if (preserveUnitGeometriesByGID[gid.toUpperCase()]) {
                    keep = true;
                  }
                }
              }
            }
            if (keep) updates2.push(f);
          });
          if (updates.length !== updates2.length) {
            layerEdits.features.updates = updates2;
          }

        }
      }

      if (layerInfo && pulledLayerEdits) {
        let list = layerEdits.features.adds;
        let pulledList = pulledLayerEdits.features.adds;
        let list2 = checkAdds(layerInfo,list,pulledList);
        if (list2) layerEdits.features.adds = list2;
      }

      if (layerInfo && pulledLayerEdits) {
        let list = layerEdits.features.deleteIds;
        let pulledList = pulledLayerEdits.features.deleteIds;
        let list2 = checkDeletes(layerInfo,list,pulledList);
        if (list2) layerEdits.features.deleteIds = list2;
      }

      /*
      if (isPeople && placeholderIds && placeholderIds.objectIdIndex[oid]) {
        // don't push placeholder occupants
        console.log("placeholder occupant",gid)
      }
      */

      // don't push placeholder occupants
      if (layerInfo && placeholderIds && (isPeople || isUnits)) {
        const oidField = layerInfo.objectIdField;
        const uidField = isUnits && aiimUtil.findFieldName(layerInfo.fields,FieldNames.PEOPLE_UNIT_ID);
        const adds = layerEdits.features.adds;
        const updates = layerEdits.features.updates;
        if (updates && updates.length > 0) {
          let updates2 = [];
          updates.forEach(f => {
            let keep = true;
            if (isPeople) {
              const oid = f.attributes[oidField];
              if (placeholderIds.objectIdIndex[oid]) {
                console.log("don't keep update.placeholder.occupant",oid)
                keep = false;
              }
            } else if (isUnits) {
              const uid = f.attributes[uidField];
              if (placeholderIds.unitIdIndex[uid]) {
                console.log("don't keep adupdaterd.placeholder.unit",uid)
                keep = false;
              }
            }
            if (keep) updates2.push(f);
          })
          if (updates.length !== updates2.length) {
            layerEdits.features.updates = updates2;
          }
        }
        if (isPeople && adds && adds.length > 0) {
          let adds2 = [];
          adds.forEach(f => {
            let keep = true;
            if (isPeople) {
              const oid = f.attributes[oidField];
              if (placeholderIds.objectIdIndex[oid]) {
                console.log("don't keep add.placeholder.occupant",oid)
                keep = false;
              }
            } 
            if (keep) adds2.push(f);
          })
          if (adds.length !== adds2.length) {
            layerEdits.features.adds = adds2;
          }
        }
      }

    });

  }

  _checkUnitGeometries(mergeInfo) {
    const promise = new Promise((resolve,reject) => {
      let task = mergeInfo.pullTask;
      let edits = task.master.changes.edits;
      const layerInfo = task.master.sourceInfo.unitsLayerInfo;
      const layerInfo2 = task.plan.sourceInfo.unitsLayerInfo;
      const globalIdField = layerInfo.globalIdField;
      const globalIdField2 = layerInfo2.globalIdField;
      const planGlobals = task.plan.unitsGlobalIndex;
      let updates, updates2;
      edits.some(layerEdits => {
        if (layerEdits.id === layerInfo.id) {
          updates = layerEdits.features.updates
          let edits2 = task.plan.changes.edits;
          edits2.some(layerEdits2 => {
            if (layerEdits2.id === layerInfo2.id) {
              updates2 = layerEdits2.features.updates
              return true;
            }
            return false;
          })
          return true;
        }
        return false;
      })
      let planFeaturesByGID = {}, queryGIDs = [];
      if (updates2 && updates2.length > 0) {
        updates2.forEach(update2 => {
          let gid = update2.attributes[globalIdField2];
          if (typeof gid === "string") gid = gid.toUpperCase();
          planFeaturesByGID[gid] = update2;
        })
      }
      if (updates && updates.length > 0) {
        updates.forEach(update => {
          let gid = update.attributes[globalIdField];
          if (typeof gid === "string") gid = gid.toUpperCase();
          if (!planFeaturesByGID[gid] && planGlobals[gid]) {
            gid = update.attributes[globalIdField];
            queryGIDs.push("'"+selectionUtil.escSqlQuote(gid)+"'");
          }
        })
      }

      Promise.resolve().then(() => {
        if (queryGIDs.length > 0) {
          const layer = sourceUtil.getUnitsLayer()
          const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
          const where = globalIdField2 + " IN (" + queryGIDs.join(",") + ")";
          const opts = {
            layer: layer,
            perFeatureCallback: f => {
              let gid = f.attributes[globalIdField2];
              if (typeof gid === "string") gid = gid.toUpperCase();
              planFeaturesByGID[gid] = f;
            }
          }
          const query = new Context.instance.lib.esri.Query();
          query.outFields = ["*"];
          query.returnGeometry = true;
          query.returnZ = true;
          query.where = where;
          const qa = new QueryAll();
          return qa.execute(url,query,opts);
        }
      }).then(() => {
        const preserveUnitGeometriesByGID = {};
        if (updates && updates.length > 0) {
          const geometryEngine = Context.instance.lib.esri.geometryEngine;
          const gu = Context.instance.lib.esri.geometryJsonUtils;
          updates.forEach(update => {
            let gid = update.attributes[globalIdField];
            if (typeof gid === "string") gid = gid.toUpperCase();
            const planFeature = planFeaturesByGID[gid];
            const geomDefault = update.geometry;
            const geomPlan = planFeature && planFeature.geometry;
            if (gid && geomDefault && geomPlan) {
              const geom = gu.fromJSON(geomDefault);
              let same = geometryEngine.equals(geom,geomPlan);
              if (!same) {
                preserveUnitGeometriesByGID[gid] = geomDefault;
              }
            } else if (geomDefault && !geomPlan) {
              preserveUnitGeometriesByGID[gid] = geomDefault;
            }
          })
        }
        task.preserveUnitGeometriesByGID = preserveUnitGeometriesByGID;
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });

    });
    return promise;
  }

  _collectPlanIds(task) {
    let edits = task.plan.changes.edits;
    const sourceInfo = task.plan.sourceInfo;
    const peopleLayerInfo = sourceInfo.peopleLayerInfo;
    const unitsLayerInfo = sourceInfo.unitsLayerInfo;
    const areasTableInfo = sourceInfo.areasTableInfo;
    const areaRolesTableInfo = sourceInfo.areaRolesTableInfo;
    const hasAreaRoles = !!areaRolesTableInfo;
    edits.forEach(layerEdits => {
      let collectDeletes = false;
      let layerInfo, updatedIds;
      if (layerEdits.id === peopleLayerInfo.id) {
        layerInfo = peopleLayerInfo;
        updatedIds = task.plan.updatedPeopleIds;
        collectDeletes = true;
      } else if (layerEdits.id === unitsLayerInfo.id) {
        layerInfo = unitsLayerInfo;
        updatedIds = task.plan.updatedUnitIds;
      } else if (layerEdits.id === areasTableInfo.id) {
        layerInfo = areasTableInfo;
        updatedIds = task.plan.updatedAreaIds;
      } else if (hasAreaRoles && layerEdits.id === areaRolesTableInfo.id) {
        layerInfo = areaRolesTableInfo;
        updatedIds = task.plan.updatedAreaRoleIds;
      }
      let globalIdField = layerInfo.globalIdField;
      //let adds = layerEdits.features.adds;
      //let deleteIds = layerEdits.features.deleteIds;
      let updates = layerEdits.features.updates;
      updates.forEach(f => {
        let gid = f.attributes[globalIdField];
        if (typeof gid === "string") gid = gid.toUpperCase();
        updatedIds[gid] = 1;
      });
      let adds = layerEdits.features.adds;
      if (adds) {
        adds.forEach(f => {
          let gid = f.attributes[globalIdField];
          if (typeof gid === "string") gid = gid.toUpperCase();
          updatedIds[gid] = 1;
        });
      }
      if (collectDeletes) {
        let deleteIds = layerEdits.features.deleteIds || [];
        deleteIds.forEach(gid => {
          if (typeof gid === "string") gid = gid.toUpperCase();
          updatedIds[gid] = 1;
        });
      }
    });
  }

  _extractChanges(task,section,sourceInfo,opts) {
    const promise = new Promise((resolve,reject) => {
      const serviceUrl = sourceInfo.serviceUrl;
      const changeTrackingInfo = sourceInfo.serviceInfo.changeTrackingInfo;
      const peopleLayerInfo = sourceInfo.peopleLayerInfo;
      const unitsLayerInfo = sourceInfo.unitsLayerInfo;
      const detailsLayerInfo = sourceInfo.detailsLayerInfo;
      const areasTableInfo = sourceInfo.areasTableInfo;
      const areaRolesTableInfo = sourceInfo.areaRolesTableInfo;
      const url = Context.checkMixedContent(serviceUrl+"/extractChanges");
      const layers = [
        peopleLayerInfo.id,
        unitsLayerInfo.id,
        areasTableInfo.id
      ];
      const layerQueries = {
        [peopleLayerInfo.id]: {queryOption: "all",},
        [unitsLayerInfo.id]: {queryOption: "all"},
        [areasTableInfo.id]: {queryOption: "all"}
      };
      if (detailsLayerInfo) {
        layers.push(detailsLayerInfo.id);
        layerQueries[detailsLayerInfo.id] = {queryOption: "all"};
      }      
      if (areaRolesTableInfo) {
        layers.push(areaRolesTableInfo.id);
        layerQueries[areaRolesTableInfo.id] = {queryOption: "all"};
      }

      let layerServerGens = [];
      if (section.lastServerGens) {
        layerServerGens = section.lastServerGens;
      } else {
        changeTrackingInfo.layerServerGens.forEach(l => {
          if ((l.id === peopleLayerInfo.id) ||
              (l.id === unitsLayerInfo.id) ||
              (l.id === areasTableInfo.id)) {
            layerServerGens.push(l);
          } else if (detailsLayerInfo) {
            if (l.id === detailsLayerInfo.id) {
              layerServerGens.push(l);
            }
          } else if (areaRolesTableInfo) {
            if (l.id === areaRolesTableInfo.id) {
              layerServerGens.push(l);
            }
          }
        });
      }

      // layerServerGens = [
      //   {id: peopleLayerInfo.id, serverGen: 0},
      //   {id: unitsLayerInfo.id, serverGen: 0},
      //   {id: areasTableInfo.id, serverGen: 0}
      // ];

      const returnIdsOnly = !!(opts && opts.returnIdsOnly);
      const params = {
        f: "json",
        layers: JSON.stringify(layers),
        layerQueries: JSON.stringify(layerQueries),
        layerServerGens: JSON.stringify(layerServerGens),
        returnInserts: true,
        returnUpdates: true,
        returnDeletes: true,
        returnAttachments: false,
        returnIdsOnly: returnIdsOnly,
        returnExtentOnly: false,
        dataFormat: "json",
        transportType: "esriTransportTypeUrl",
      };
      const esriRequest = Context.instance.lib.esri.esriRequest;
      const options = {query: params, method: "post", responseType: "json"};
      let jobInfo;
      section.changes = {};
      esriRequest(url,options).then(result => {
        //console.log("extractChanges.result",result)
        const statusUrl = result && result.data && result.data.statusUrl;
        if (statusUrl) {
          return this._pollJob(task,statusUrl).then(jobResult => {
            //console.log("jobResult",jobResult)
            jobInfo = jobResult;
          });
        } else {
          section.changes = (result && result.data) || {};
        }
      }).then(() => {
        // responseType: "esriDataChangesResponseTypeEdits"
        // responseType: "esriDataChangesResponseTypeNoEdits"
        if (jobInfo) {
          return this._fetchEditsFile(task,jobInfo.resultUrl).then(result => {
            //console.log("editsFile",result)
            section.changes = result || {};
          });
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _fetchEditsFile(task,url) {
    // const href = window.location.href;
    // if ((href.indexOf("http://indoorsbld.esri.com") === 0) ||
    //     (href.indexOf("https://indoorsbld.esri.com") === 0) ||
    //     (href.indexOf("http://localhost:3000") === 0)) {
    //   return this._fetchEditsFile2(task,url);
    // }
    return this._fetchEditsFile1(task,url);
  }

  _fetchEditsFile1(task,url) {
    const params = {f: "json"};
    const options = {query: params, method: "get", responseType: "json"};
    const esriRequest = Context.instance.lib.esri.esriRequest;
    return esriRequest(url,options).then(result => {
      return result && result.data;
    });
  }

  _fetchEditsFile2(task,url) {
    const promise = new Promise((resolve,reject) => {
      url = aiimUtil.appendTokenToUrl(url);

      //let proxyUrl = "https://cors-anywhere.herokuapp.com/";
      let proxyUrl = "http://indoorsbld.esri.com:8000/";
      //let proxyUrl = "https://indoorsportal2.esri.com/portal/sharing/proxy/";

      fetch(proxyUrl+url).then(response => {
        //console.log("fetched.response",response)
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Fetch error",response.status);
        }
      }).then(json => {
        //console.log("fetched.json",json)
        resolve(json);
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      });

    });
    return promise;
  }

  _fetchMasterServerGens(info) {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      let section = task.master;
      const opts = {returnIdsOnly: true};
      this._extractChanges(task,section,section.sourceInfo,opts).then(() => {
        info.masterServerGens = section.changes.layerServerGens;
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _fixUpsert(info) {
    let globalIdIndex = info.globalIdIndex || {};
    let globalIdField = info.globalIdField;
    let adds = info.adds
    let updates = info.updates;
    if (!adds) adds = [];
    let updates2 = [], mod = false;
    updates.forEach(f => {
      let gid = f.attributes[globalIdField];
      if (typeof gid === "string") gid = gid.toUpperCase();
      if (globalIdIndex && !globalIdIndex[gid]) {
        mod = true;
        //console.log("****** upsert ******",gid,f)
        adds.push(f);
      } else {
        updates2.push(f)
      }
    });
    if (mod) {
      info.updates = updates2;
      if (!info.adds) info.adds = adds;
    }
  }

  _hasEdits(task,section) {
    let hasEd = false;
    const edits = section.changes && section.changes.edits;
    if (edits) {
      edits.some(layerEdits => {
        let adds, updates, deleteIds;
        if (layerEdits.features) {
          adds = layerEdits.features.adds;
          updates = layerEdits.features.updates;
          deleteIds = layerEdits.features.deleteIds;
        } else if (layerEdits.objectIds) {
          adds = layerEdits.objectIds.adds;
          updates = layerEdits.objectIds.updates;
          deleteIds = layerEdits.objectIds.deleteIds || layerEdits.objectIds.deletes;
        }
        if ((adds && adds.length > 0) ||
            (updates && updates.length > 0) ||
            (deleteIds && deleteIds.length > 0)) {
          hasEd = true;
        }
        return hasEd;
      });
    }
    return hasEd;
  }

  _makeReapplyEdits(reapplyInfo) {
    const edits = [];
    const peopleLayerInfo = reapplyInfo.peopleLayerInfo;
    const unitsLayerInfo = reapplyInfo.unitsLayerInfo;
    const areasTableInfo = reapplyInfo.areasTableInfo;
    const areaRolesTableInfo = reapplyInfo.areaRolesTableInfo;
    const peopleGlobalIndex = reapplyInfo.peopleGlobalIndex;
    const unitsGlobalIndex = reapplyInfo.unitsGlobalIndex;
    const areasGlobalIndex = reapplyInfo.areasGlobalIndex;
    const areaRolesGlobalIndex = reapplyInfo.areaRolesGlobalIndex;
    const hasAreaRoles = !!areaRolesTableInfo;
    const planChanges = reapplyInfo.planChanges;
    const planEdits = planChanges && planChanges.edits;

    if (planEdits && planEdits.length > 0) {
      planEdits.forEach(ed => {
        if (ed.features) {
          let layerEdits = {
            id: ed.id
          }
          let edInfo = {
            adds: ed.features.adds,
            updates: ed.features.updates,
            deleteIds: ed.features.deleteIds
          };

          if (edInfo.updates && edInfo.updates.length > 0) {
            if (ed.id === peopleLayerInfo.id) {
              edInfo.globalIdIndex = peopleGlobalIndex;
              edInfo.globalIdField = peopleLayerInfo.globalIdField;
              edInfo.updates.forEach(f => {
                if (!f.geometry) {
                  // ensure that there is a geometry, issue #3644
                  //console.log("saveAs update, empty occupant geometry",f)
                  let pt = transactionUtil.makeEmptyPoint({isVersioned: false});
                  f.geometry = pt.toJSON();
                }
              });
              this._fixUpsert(edInfo);
            }
            if (ed.id === areasTableInfo.id) {
              edInfo.globalIdIndex = areasGlobalIndex;
              edInfo.globalIdField = areasTableInfo.globalIdField;
              this._fixUpsert(edInfo);
            }
            if (hasAreaRoles && ed.id === areaRolesTableInfo.id) {
              edInfo.globalIdIndex = areaRolesGlobalIndex;
              edInfo.globalIdField = areaRolesTableInfo.globalIdField;
              this._fixUpsert(edInfo);
            }
            if (ed.id === unitsLayerInfo.id) {
              edInfo.globalIdIndex = unitsGlobalIndex;
              edInfo.globalIdField = unitsLayerInfo.globalIdField;
              this._fixUpsert(edInfo);
              // don't take the plan unit geometries, just the attribute updates
              let updates2 = [];
              edInfo.updates.forEach(f => {
                updates2.push({
                  attributes: f.attributes
                })
              });
              edInfo.updates = updates2;
            }
          }

          if (edInfo.adds && edInfo.adds.length > 0) {
            let gidIndex, globalIdField;
            if (ed.id === peopleLayerInfo.id) {
              gidIndex = peopleGlobalIndex;
              globalIdField = peopleLayerInfo.globalIdField;
            } else if (ed.id === areasTableInfo.id) {
              gidIndex = areasGlobalIndex;
              globalIdField = areasTableInfo.globalIdField;
            } else if (hasAreaRoles && ed.id === areaRolesTableInfo.id) {
              gidIndex = areaRolesGlobalIndex;
              globalIdField = areaRolesTableInfo.globalIdField;
            }
            if (gidIndex) {
              let adds2 = [];
              edInfo.adds.forEach(f => {
                let gid = f.attributes[globalIdField];
                if (typeof gid === "string") gid = gid.toUpperCase();
                // don't re-add (area was merged by a sibling plan)
                if (!gidIndex[gid]) {
                  adds2.push(f);
                } else {
                  if (!edInfo.updates) edInfo.updates = [];
                  edInfo.updates.push(f);
                }
              });
              if (edInfo.adds.length !== adds2.length) {
                edInfo.adds = adds2;
              }
            }
          }

          if (edInfo.adds && edInfo.adds.length > 0) {
            layerEdits.adds = edInfo.adds;
          }
          if (edInfo.updates && edInfo.updates.length > 0) {
            layerEdits.updates = edInfo.updates;
          }
          if (edInfo.deleteIds && edInfo.deleteIds.length > 0) {
            layerEdits.deletes = edInfo.deleteIds;
          }
          if (layerEdits.adds || layerEdits.updates || layerEdits.deletes) {
            edits.push(layerEdits);
          }
        }
      });
    }
    return edits;
  }

  _makeTask() {
    const project = Context.instance.spaceplanner.planner.project;
    const activePlan = Context.instance.spaceplanner.activePlan;
    const task = {
      activePlan: activePlan,
      timeout: (60000 * 30),
      master: {
        sourceInfo: project.hostedInfo.sourceInfo,
        peopleGlobalIndex: null,
        unitsGlobalIndex: null,
        areasGlobalIndex: null,
        areaRolesGlobalIndex: null
      },
      plan: {
        sourceInfo: activePlan && activePlan.sourceInfo,
        updatedPeopleIds: {},
        updatedUnitIds: {},
        updatedAreaIds: {},
        updatedAreaRoleIds: {},
        peopleGlobalIndex: null,
        unitsGlobalIndex: null,
        areasGlobalIndex: null,
        areaRolesGlobalIndex: null,
        placeholderIds: null
      }
    };
    return task;
  }

  _pollJob(task,statusUrl) {
    const promise = new Promise((resolve,reject) => {
      const url = statusUrl;

      const poll = () => {
        const params = {
          f: "json",
        };
        // ****** jobId: jobId,
        // ****** jobType: jobType
        const options = {query: params, method: "get", responseType: "json"};
        const esriRequest = Context.instance.lib.esri.esriRequest;

        esriRequest(url,options).then(result => {
          let status = result.data.status;
          let statusMessage = result.data.statusMessage;
          if (typeof status === "string") status = status.toLowerCase();
          //console.log("poll.result",status,result)
          if (status === "completed") {
            // ****** task.completed[jobType] = true;
            resolve(result.data);
          } else if (status === "partial" || status === "processing" ||
                     status === "pending" || status === "inprogress" ||
                     status === "exportchanges" || status === "exportingdata" ||
                     status === "exportattachments") {
            setTimeout(() => {
              poll();
            },1000);
          } else if (status === "failed" || status === "completedwitherrors") {
            let msg = "Job failed";
            if (statusMessage) msg += ": "+statusMessage;
            console.warn("Job failed:",msg,result);
            reject(new Error(msg));
          } else {
            let msg = "Unknown job status type: "+status;
            if (statusMessage) msg += ", "+statusMessage;
            console.warn("Job failed:",msg,result);
            reject(new Error(msg));
          }
        }).catch(ex => {
          reject(ex);
        });
      };

      poll();
    });
    return promise;
  }

  _prepareEdits(task,direction) {
    const isPull = (direction === "pull");
    const isPush = (direction === "push");
    const masterSourceInfo = task.master.sourceInfo;
    const planSourceInfo = task.plan.sourceInfo;

    let masterPeopleLayerID = masterSourceInfo.peopleLayerInfo.id;
    let masterUnitsLayerID = masterSourceInfo.unitsLayerInfo.id;
    let masterAreasTableID = masterSourceInfo.areasTableInfo.id;
    let masterAreaRolesTableID = null;
    if (masterSourceInfo.areaRolesTableInfo) {
      masterAreaRolesTableID = masterSourceInfo.areaRolesTableInfo.id
    }

    let planPeopleLayerID = planSourceInfo.peopleLayerInfo.id;
    let planUnitsLayerID = planSourceInfo.unitsLayerInfo.id;
    let planAreasTableID = planSourceInfo.areasTableInfo.id;
    let planAreaRolesTableID = null;
    if (planSourceInfo.areaRolesTableInfo) {
      planAreaRolesTableID = planSourceInfo.areaRolesTableInfo.id
    }

    let preserveUnitGeometriesByGID = (isPull && task.preserveUnitGeometriesByGID);

    let edits, targets;
    if (isPull) {
      edits = task.master.changes.edits;
      targets = {
        [masterPeopleLayerID]: {
          isPeople: true,
          layerInfo: masterSourceInfo.peopleLayerInfo,
          targetLayerInfo: planSourceInfo.peopleLayerInfo,
          globalIdIndex: task.plan.peopleGlobalIndex
        },
        [masterUnitsLayerID]: {
          isUnits: true,
          layerInfo: masterSourceInfo.unitsLayerInfo,
          targetLayerInfo: planSourceInfo.unitsLayerInfo,
          globalIdIndex: task.plan.unitsGlobalIndex
        },
        [masterAreasTableID]: {
          isAreas: true,
          layerInfo: masterSourceInfo.areasTableInfo,
          targetLayerInfo: planSourceInfo.areasTableInfo,
          globalIdIndex: task.plan.areasGlobalIndex
        }
      }
      if (masterAreaRolesTableID !== null && masterAreaRolesTableID !== undefined) {
        targets[masterAreaRolesTableID] = {
          isAreaRoles: true,
          layerInfo: masterSourceInfo.areaRolesTableInfo,
          targetLayerInfo: planSourceInfo.areaRolesTableInfo,
          globalIdIndex: task.plan.areaRolesGlobalIndex
        }
      }
    } else {
      edits = task.plan.changes.edits;
      targets = {
        [planPeopleLayerID]: {
          isPeople: true,
          layerInfo: planSourceInfo.peopleLayerInfo,
          targetLayerInfo: masterSourceInfo.peopleLayerInfo,
          globalIdIndex: task.master.peopleGlobalIndex
        },
        [planUnitsLayerID]: {
          isUnits: true,
          layerInfo: planSourceInfo.unitsLayerInfo,
          targetLayerInfo: masterSourceInfo.unitsLayerInfo,
          globalIdIndex: task.master.unitsGlobalIndex
        },
        [planAreasTableID]: {
          isAreas: true,
          layerInfo: planSourceInfo.areasTableInfo,
          targetLayerInfo: masterSourceInfo.areasTableInfo,
          globalIdIndex: task.master.areasGlobalIndex
        }
      }
      if (planAreaRolesTableID !== null && planAreaRolesTableID !== undefined) {
        targets[planAreaRolesTableID] = {
          isAreaRoles: true,
          layerInfo: planSourceInfo.areaRolesTableInfo,
          targetLayerInfo: masterSourceInfo.areaRolesTableInfo,
          globalIdIndex: task.master.areaRolesGlobalIndex
        }
      }
    }

    //console.log("prepare...................",edits)

    const targetEdits = [];
    edits.forEach(layerEdits => {
      let target = targets[layerEdits.id];
      if (target) {
        let layerInfo = target.layerInfo;
        let globalIdField = layerInfo.globalIdField;
        let globalIdIndex = target.globalIdIndex || {};
        let adds = layerEdits.features.adds;
        let updates = layerEdits.features.updates;
        let deleteIds = layerEdits.features.deleteIds;

        if (target.isPeople && updates && updates.length > 0) {
          updates.forEach(f => {
            if (!f.geometry) {
              // ensure that there is a geometry, issue #3644
              let pt = transactionUtil.makeEmptyPoint({isVersioned: false});
              f.geometry = pt.toJSON();
            }
          });
        }

        if (globalIdIndex && updates && updates.length > 0) {
          /*
            FeatureService.extractChanges
            You add an Area then update its name.
            extractChanges reports this as an updated rather than an add,
            so we have to upsert
           */
          if (!adds) adds = [];
          let updates2 = [], mod = false;
          updates.forEach(f => {
            let gid = f.attributes[globalIdField];
            if (typeof gid === "string") gid = gid.toUpperCase();
            if (!globalIdIndex[gid]) {
              mod = true;
              //console.log("****** upsert ******",gid,f)
              // TODO do this only if it hasn't been deleted?
              adds.push(f);
            } else {
              updates2.push(f)
            }
          });
          if (mod) updates = updates2;
        }

        if (isPush) {
          if (globalIdIndex && adds && adds.length > 0) {
            let adds2 = [];
            adds.forEach(f => {
              let gid = f.attributes[globalIdField];
              if (typeof gid === "string") gid = gid.toUpperCase();
              if (!globalIdIndex[gid]) {
                // don't re-add
                adds2.push(f);
              } else {
                // TODO: should this be an update?
                if (!updates) updates = [];
                updates.push(f);
              }
            });
            if (adds.length !== adds2.length) {
              adds = adds2;
            }
          }
        }

        if ((adds && adds.length > 0) ||
            (updates && updates.length > 0) ||
            (deleteIds && deleteIds.length > 0)) {

          let targetLayerEdits = {
            id: target.targetLayerInfo.id
          };

          if (adds && adds.length > 0) {
            // adds.forEach(f => {
            //   delete f.attributes[layerInfo.objectIdField];
            // });
            targetLayerEdits.adds = adds;
          }

          if (updates && updates.length > 0) {
            updates.forEach(f => {
              //delete f.attributes[layerInfo.objectIdField];
              if (target.isUnits) {
                let preserve = false;
                if (preserveUnitGeometriesByGID) {
                  let gid = f.attributes[globalIdField];
                  if (typeof gid === "string") gid = gid.toUpperCase();
                  if (preserveUnitGeometriesByGID[gid]) preserve = true;
                }
                if (!preserve) delete f.geometry;
              }
            });
            targetLayerEdits.updates = updates;
          }

          if (deleteIds && deleteIds.length > 0) {
            targetLayerEdits.deletes = deleteIds;
          }

          targetEdits.push(targetLayerEdits);
        }
      }
    });

    if (targetEdits.length > 0) return targetEdits;
    return null;
  }

  _pull(mergeInfo) {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      task.reconcileOnly = mergeInfo.reconcileOnly;
      mergeInfo.pullTask = task;
      let hadChanges = false;
      Promise.resolve().then(() => {
        return this._readServiceItem(task);
      }).then(() => {
        const promises = [
          this._extractChanges(task,task.plan,task.plan.sourceInfo),
          this._extractChanges(task,task.master,task.master.sourceInfo),
          this._readAllGlobals(task,task.plan),
        ];
        return Promise.all(promises);
      }).then(() => {
        return this._checkUnitGeometries(mergeInfo);
      }).then(() => {
        const direction = "pull";
        hadChanges = this._hasEdits(task,task.master);
        this._checkMasterChanges(task);
        const edits = this._prepareEdits(task,direction);
        //console.log("editsToApply",direction,edits)
        //if (true) throw new Error("tmp2");
        if (edits) {
          return this._applyEdits(task,edits,direction);
        } else if (mergeInfo.reconcileOnly) {
          const masterServerGens = task.master.changes.layerServerGens;
          const planServerGens = null;
          const serviceItem = task.plan.serviceItem;
          return this._trackServerGens(serviceItem,masterServerGens,planServerGens);
        }
      }).then(() => {
        if (mergeInfo.reconcileOnly) {
          if (!hadChanges) {
            mergeInfo.noAnalysisRequired = true;
          } else {
            return this._reapplyPlanChanges(mergeInfo);
          }
        }
      }).then(() => {
        //if (true) throw new Error("tmp3");
        //console.log("Merge._pull.task",task)
        resolve(task);
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      })
    });
    return promise;
  }

  _push(mergeInfo) {
    const promise = new Promise((resolve,reject) => {
      const task = this._makeTask();
      mergeInfo.pushTask = task;
      Promise.resolve().then(() => {
        return this._readServiceItem(task);
      }).then(() => {
        return placeholderUtil.readPlaceholderIds({}).then(data => {
          task.plan.placeholderIds = data;
          //console.log("task.plan.placeholderIds",task.plan.placeholderIds)
        });
      }).then(() => {
        let section = task.plan;
        return this._extractChanges(task,section,section.sourceInfo);
      }).then(() => {
        return this._readAllGlobals(task,task.master);
      }).then(() => {
        const direction = "push";
        this._checkPlanChanges(task,mergeInfo);
        const edits = this._prepareEdits(task,direction);
        //console.log("editsToApply",direction,edits)
        //console.log("mergeInfo",mergeInfo);
        //if (true) throw new Error("tmp3")
        if (edits) {
          return this._applyEdits(task,edits,direction);
        } else {
          const pullTask = mergeInfo.pullTask;
          const masterServerGens = pullTask.master.changes.layerServerGens;
          const planServerGens = task.plan.changes.layerServerGens;
          const serviceItem = task.plan.serviceItem;
          return this._trackServerGens(serviceItem,masterServerGens,planServerGens);
        }
      }).then(() => {
        //console.log("Merge._push.task",task)
        resolve(task);
      }).catch(ex => {
        console.error(ex);
        reject(ex);
      })
    });
    return promise;
  }

  _readAllGlobals(task,section) {
    let promises = [
      this._readPeopleGlobals(task,section),
      this._readUnitsGlobals(task,section),
      this._readAreaGlobals(task,section),
      this._readAreaRoleGlobals(task,section)
    ]
    return Promise.all(promises);
  }

  _readAreaGlobals(task,section) {
    let sourceInfo = section.sourceInfo;
    let layerInfo = sourceInfo.areasTableInfo;
    let gids = {};
    return this._readLayerGlobals(task,sourceInfo,layerInfo,gids).then(() => {
      if (Object.keys(gids).length === 0) {
        section.areasGlobalIndex = null;
      } else {
        section.areasGlobalIndex = gids;
      }
    });
  }

  _readAreaRoleGlobals(task,section) {
    let sourceInfo = section.sourceInfo;
    let layerInfo = sourceInfo.areaRolesTableInfo;
    if (!layerInfo) {
      section.areaRolesGlobalIndex = null;
      return Promise.resolve();
    }
    let gids = {};
    return this._readLayerGlobals(task,sourceInfo,layerInfo,gids).then(() => {
      if (Object.keys(gids).length === 0) {
        section.areaRolesGlobalIndex = null;
      } else {
        section.areaRolesGlobalIndex = gids;
      }
    });
  }

  _readLayerGlobals(task,sourceInfo,layerInfo,globalIdIndex) {
    let opts = {
      url: sourceInfo.serviceUrl + "/" + layerInfo.id,
      globalIdField: layerInfo.globalIdField,
      globalIdIndex: globalIdIndex,
      toUpper: true
    };
    let reader = new Reader();
    return reader.readLayerGlobalIdIndex(opts);
  }

  _readPeopleGlobals(task,section) {
    let sourceInfo = section.sourceInfo;
    let layerInfo = sourceInfo.peopleLayerInfo;
    let gids = {};
    return this._readLayerGlobals(task,sourceInfo,layerInfo,gids).then(() => {
      if (Object.keys(gids).length === 0) {
        section.peopleGlobalIndex = null;
      } else {
        section.peopleGlobalIndex = gids;
      }
    });
  }

  _readServiceItem(task,forActivePlanReload) {
    const serviceItemId = task.activePlan.planServiceItem.id;
    return portalUtil.readItem(serviceItemId).then(result => {
      const item = result && result.data;

      if (forActivePlanReload) {
        task.activePlan.planServiceItem = item;
      } else {
        task.plan.serviceItem = item;
        if (item && item.properties && item.properties.indoors) {
          if (item.properties.indoors.masterServerGens) {
            task.master.lastServerGens = item.properties.indoors.masterServerGens;
          }
          if (item.properties.indoors.planServerGens) {
            task.plan.lastServerGens = item.properties.indoors.planServerGens;
          }
        }
      }
    })
  }

  _readUnitsGlobals(task,section) {
    let sourceInfo = section.sourceInfo;
    let layerInfo = sourceInfo.unitsLayerInfo;
    let gids = {};
    return this._readLayerGlobals(task,sourceInfo,layerInfo,gids).then(() => {
      if (Object.keys(gids).length === 0) {
        section.unitsGlobalIndex = null;
      } else {
        section.unitsGlobalIndex = gids;
      }
    });
  }

  _reapplyPlanChanges(mergeInfo) {
    const promise = new Promise((resolve,reject) => {
      let task = mergeInfo.pullTask;
      let edits, planServerGens;
      Promise.resolve().then(() => {
        const section = {};
        const opts = {returnIdsOnly: true};
        return this._extractChanges(task,section,task.plan.sourceInfo,opts).then(() => {
          // layerServerGens prior to re-applying plan changes
          planServerGens = section.changes && section.changes.layerServerGens
        });
      }).then(() => {
        return this._readAllGlobals(task,task.plan);
      }).then(() => {
        edits = this._makeReapplyEdits({
          peopleLayerInfo: task.plan.sourceInfo.peopleLayerInfo,
          unitsLayerInfo: task.plan.sourceInfo.unitsLayerInfo,
          areasTableInfo: task.plan.sourceInfo.areasTableInfo,
          areaRolesTableInfo: task.plan.sourceInfo.areaRolesTableInfo,
          peopleGlobalIndex: task.plan.peopleGlobalIndex,
          unitsGlobalIndex: task.plan.unitsGlobalIndex,
          areasGlobalIndex: task.plan.areasGlobalIndex,
          areaRolesGlobalIndex: task.plan.areaRolesGlobalIndex,
          planChanges: task.plan.changes
        })
        //console.log("_reapplyPlanChanges.mergeInfo",mergeInfo)
        //console.log("_reapplyPlanChanges.edits",edits)
        //console.log("_reapplyPlanChanges.planServerGens",planServerGens)
        if (edits && edits.length > 0) {
          const saveAsTask = {
            sourceInfo: task.plan.sourceInfo,
            edits: edits,
            timeout: task.timeout
          };
          return saveAsUtil.applyEdits(saveAsTask,edits).then(() => {
            task.plan.editsApplied = true;
          });
        }
      }).then(() => {
        // track the layerServerGens prior to re-applying plan changes
        const masterServerGens = null;
        const serviceItem = task.plan.serviceItem;
        return this._trackServerGens(serviceItem,masterServerGens,planServerGens);
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.error("Serious issue while re-applying plan changes",ex);
        reject(ex);
      });
    });
    return promise;
  }

  _trackServerGens(planServiceItem,masterServerGens,planServerGens) {
    let properties = planServiceItem.properties || {};
    if (!properties.indoors) properties.indoors = {};
    if (masterServerGens || planServerGens) {
      if (masterServerGens) {
        properties.indoors.masterServerGens = masterServerGens;
      }
      if (planServerGens) {
        properties.indoors.planServerGens = planServerGens;
      }
      const itemId = planServiceItem.id;
      const owner = planServiceItem.owner;
      const folderId = planServiceItem.ownerFolder;
      const item = {
        properties: JSON.stringify(properties)
      };
      console.log("_trackServerGens",properties.indoors);
      return portalUtil.saveItem(item,itemId,folderId,owner);
    }
    return Promise.resolve();
  }

}
