import React from "react";

import Loader from "calcite-react/Loader"

import * as component from "../../../components/util/component";

export default class Working extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      content: null,
      open: true,
      text: null,
    });
  }

  close() {
    //console.log("Working.close..................")
    this.setState({open: false});
  }

  componentDidMount () {
    if (typeof this.props._onMount === "function") {
      this.props._onMount(this);
    } else if (typeof this.props.onMount === "function") {
      this.props.onMount(this);
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    if (!this.state.open) return null;
    const minimal = !!this.props.minimal;
    if (minimal) {
      return (
        <div className="i-modal-working-overlay i--minimal">
        </div>
      );
    }
    const content = this.state.content || this.props.content;
    const text = this.state.text || this.props.text;
    let loader = null;
    if (true) {
      loader = <Loader sizeRatio={0.4} text={text}/>
    }
    return (
      <div className="i-modal-working-overlay i--top">
        <div className="i-modal-working-content">
          {loader}
          {content}
        </div>
      </div>
    );
  }

  setContent(content) {
    this.setState({content: content});
  }

  setText(text) {
    this.setState({text: text});
  }

}
