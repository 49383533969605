import React, {createRef} from "react";
import {connect} from "react-redux";

import Context from "../../../context/Context";
//import Rdx from "../../../redux/Rdx";
import TrackingLocation from "../../../aiim/tracking/TrackingLocation";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as mapUtil from "../../../util/mapUtil";
import Icons from "../../util/Icons";

const CSS = {
  main: "i-realtime",
  summary: "i-summary",
  summaryButton: "i-interactive-bar i-text-left",
  backButton: "i-button-clear-no-border",
  content: "i-content",
  timestamp: "i-timestamp",
  estimate: "i-estimate",
  actions: "i-actions i-margin-top-1",
  directionsButton: "i-button i-button-clear",
  recenterButton: "i-button",
  noLastKnownLocation: "i-note"
};

class RealTimeLocation extends React.Component {

  _backButtonRef = createRef();
  _intervalID = null;
  _mounted = false;
  _summaryButtonRef = createRef();
  _wasQueried = false;
  _watchIntervalMillis = 60000; // -1 to turn off, auto refresh interval
  _watchToleranceHours = 12; // watch if last known location occurred within this tolerance

  constructor(props) {
    super(props);
    const { trackingInfo } = this.props;

    this.state = component.newState({
      focusOnUpdate: false,
      mode: "summary",
      trackingInfo: trackingInfo || {},
    });
    this.backClicked = this.backClicked.bind(this);
    this.directionsClicked = this.directionsClicked.bind(this);
    this.realTimeModeClicked = this.realTimeModeClicked.bind(this);
    this.recenterClicked = this.recenterClicked.bind(this);


    if (trackingInfo) {
      this.onInfoReturned();
    }
  }

  backClicked() {
    //const activeModeKey = this.props.infoPanelKey + "-RealTime-ActiveMode";
    //Rdx.setValue(this,activeModeKey,"summary");
    this.setState(state => {
      return {
        focusOnUpdate: true,
        mode: "summary"
      };
    });
    if (this.props.modeClicked) this.props.modeClicked("details");
    if (typeof this.props.realTimePinActive === "function") {
      this.props.realTimePinActive(true);
    }
    Topic.publish(Topic.ShowInfoPanelGraphic,{});
  }

  componentDidMount() {
    this._mounted = true;
    const { onlyRealTime } = this.props;
    if (onlyRealTime) {
      this.realTimeModeClicked();
    }
    this.query();

    component.own(this,[
      Topic.subscribe(Topic.HomeLocationSet,() => {
        if (this._mounted) this.query();
      }),
      Topic.subscribe(Topic.TravelModeChanged,() => {
        if (this._mounted) this.query();
      })
    ]);
  }

  componentDidUpdate(prevProps,prevState) {
    let btn;
    if (this.state.focusOnUpdate) {
      if (this.state.mode === "summary") {
        btn = this._summaryButtonRef && this._summaryButtonRef.current;
      } else if (this.state.mode === "realtime") {
        btn = this._backButtonRef && this._backButtonRef.current;
      }
    }
    if (btn && btn.focus) {
      btn.focus();
      this.setState(state => {
        return {
          focusOnUpdate: false
        };
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._intervalID);
    component.componentWillUnmount(this);
    if (typeof this.props.realTimePinActive === "function") {
      this.props.realTimePinActive(false);
    }
    this._mounted = false;
  }

  directionsClicked() {
    const trackingInfo = this.state.trackingInfo;
    const feature = trackingInfo && trackingInfo.feature;
    const source = this.props.item.getSource();
    if (source && feature) {
      const searchResult = {
        extent: null,
        feature: feature,
        name: this.props.item.getTitle(),
        key: aiimUtil.getAttributeValue(feature.attributes,"objectid"),
        sourceIndex: source.index,
        isRealTime: true
      };
      Topic.publish(Topic.DirectionsClicked,{searchResult: searchResult});
    }
  }

  query() {
    const zoom = !this._wasQueried;
    const setLevel = zoom;
    const trackingLocation = new TrackingLocation();
    let trackingInfo;
    trackingLocation.query(this.props.item).then(result => {
      trackingInfo = result;
      let feature = trackingInfo && trackingInfo.feature;
      if (feature && feature.geometry && feature.geometry.type === "point") {
        const footprints =Context.getInstance().aiim.facilityFootprints;
        const levels = Context.getInstance().aiim.datasets.levels;
        if (footprints && levels) {
          let facilityId = footprints.findFacilityIdByPoint(feature.geometry);
          if (Array.isArray(facilityId)) facilityId = facilityId[0];
          trackingInfo.facilityId = facilityId;
        }
      }
    }).then(() => {
      //console.log("trackingInfo",trackingInfo);
      this._wasQueried = true;
      if (this._mounted && trackingInfo && !trackingInfo.noInfo) {
        //const activeMode = this.props.rdxActiveMode;
        const activeMode = this.state.mode;
        this.setState(state => {
          return {
            trackingInfo: trackingInfo
          };
        });
        if (activeMode === "realtime" && trackingInfo && trackingInfo.feature) {
          this.showGraphic(zoom,setLevel);
        }

        if (trackingInfo.lastKnownDate && typeof this._intervalID !== "number") {
          const hours = this._watchToleranceHours;
          const millis = 60000 * 60 * hours;
          const diff = (Date.now() - trackingInfo.lastKnownDate);
          if (diff <= millis) {
            this.watchLocation();
          }
        }
      }
    }).catch(ex => {
      console.error("Error querying location",ex);
    });
  }

  realTimeModeClicked() {
    //const activeModeKey = this.props.infoPanelKey + "-RealTime-ActiveMode";
    //Rdx.setValue(this,activeModeKey,"realtime");
    this.setState(state => {
      return {
        focusOnUpdate: true,
        mode: "realtime"
      };
    });
    if (this.props.modeClicked) this.props.modeClicked("realtime");
    const trackingInfo = this.state.trackingInfo;
    if (trackingInfo && trackingInfo.feature) {
      this.showGraphic(true,true);
    }
  }

  onInfoReturned = () => {
    const zoom = true;
    const setLevel = zoom;
    const { trackingInfo } = this.state;
    this._wasQueried = true;
    if (this._mounted && trackingInfo && !trackingInfo.noInfo) {
      //const activeMode = this.props.rdxActiveMode;
      const activeMode = this.state.mode;
      this.setState(state => {
        return {
          trackingInfo: trackingInfo
        };
      });
      if (activeMode === "realtime" && trackingInfo && trackingInfo.feature) {
        this.showGraphic(zoom,setLevel);
      }

      if (trackingInfo.lastKnownDate && typeof this._intervalID !== "number") {
        const hours = this._watchToleranceHours;
        const millis = 60000 * 60 * hours;
        const diff = (Date.now() - trackingInfo.lastKnownDate);
        if (diff <= millis) {
          this.watchLocation();
        }
      }
    }
  }

  recenterClicked() {
    this.showGraphic(true,true);
  }

  render() {
    //const activeMode = this.props.rdxActiveMode || "summary";
    const activeMode = this.state.mode || "summary";
    const summaryMode = (activeMode !== "realtime");
    if (summaryMode) {
      return this.renderSummary();
    } else {
      return this.renderContent();
    }
  }

  renderContent() {
    const i18n = Context.getInstance().i18n;
    const { onlyRealTime } = this.props;
    const trackingInfo = this.state.trackingInfo || {};
    let text;

    //let headerText = i18n.infoPanel.realTime.lastKnownLocation;
    let headerText = trackingInfo.summaryText;
    let backStyle = {};
    if (Context.getInstance().uiMode.isRtl) {
      backStyle = {transform: "rotateY(180deg)"};
    }
    const backIcon = (
      <svg className="icon i-icon-24" style={backStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M10.707 12H9.293l-4.5-4.5 4.5-4.5h1.414l-4.5 4.5z"/></svg>
      </svg>
    );
    const backButton = (
      <button 
        key="back" 
        onClick={this.backClicked}
        className={CSS.backButton} 
        ref={this._backButtonRef} 
        aria-label={i18n.infoPanel.back}
      >
        {backIcon}
      </button>
    );
    const header = (
      <div className="i-margin-top-1">
        {!onlyRealTime && backButton}
        <span className="i-details-header">{headerText}</span>
      </div>
    );

    let noLocation = null;
    if (trackingInfo.noInfo || trackingInfo.noLastKnownLocation) {
      text = i18n.infoPanel.realTime.noLocation;
      noLocation = (
        <div key="noLocation" className={CSS.noLastKnownLocation}>{text}</div>
      );
    }

    let timestamp = null;
    text = trackingInfo.lastKnownTimestampText;
    /* if (text) {
      timestamp = (
        <div key="timestamp" className={CSS.timestamp}>{text}</div>
      );
    } */

    let estimate = null;
    text = trackingInfo.estimateText;
    if (text) {
      estimate = (
        <div key="estimate" className={CSS.estimate}>
          <span dangerouslySetInnerHTML={{__html: text}}></span>
          <div>{i18n.infoPanel.realTime.fromHome}</div>
        </div>
      );
    }

    let actions = null;
    if (trackingInfo.feature) {
      actions = (
        <div key="actions" className={CSS.actions}>
          <button key="directions" className={CSS.directionsButton}
            onClick={this.directionsClicked}
            >{Icons.directions()}{i18n.infoPanel.realTime.directions}</button>
          <button key="recenter" className={CSS.recenterButton}
            onClick={this.recenterClicked}
            >{Icons.recenter()}{i18n.infoPanel.realTime.recenter}</button>
        </div>
      );
    }

    return (
      <div key="realtime-content" className={CSS.main}>
        {header}
        <div key="content" className={CSS.content}>
          {noLocation}
          {timestamp}
          {estimate}
          {actions}
        </div>
      </div>
    );
  }

  renderSummary() {
    const trackingInfo = this.state.trackingInfo || {};

    // if(Object.keys(trackingInfo).length === 0) {
    //   return (<div key="realtime-summary"></div>);
    // }

    if (trackingInfo.noInfo || trackingInfo.noLastKnownLocation) {
      return (<div key="realtime-summary"></div>);
    }

    let headerText = trackingInfo.summaryText;
    if (typeof headerText !== "string") headerText = "";
    let nextStyle = {};
    if (Context.getInstance().uiMode.isRtl) {
      nextStyle = {transform: "rotateY(180deg)"};
    }


    const nextIcon = (
      <svg className="icon i-icon-16" style={nextStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M11.293 7h1.414l9.5 9.5-9.5 9.5h-1.414l9.5-9.5z"/></svg>
      </svg>
    );


    // const currentLocationIcon=(
    //   <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    //   <title>Group 12 Copy 2</title>
    //   <desc>Created with Sketch.</desc>
    //   <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    //       <g id="Group-12-Copy-2">
    //           <circle id="Oval" fill="#0072B6" fillRule="nonzero" cx="8" cy="8" r="8"></circle>
    //               <g id="person-location-copy-2" transform="translate(4.000000, 2.250000)" fill="#FFFFFF">
    //               <path d="M4.046,-2.50202013e-14 C1.84208827,0.019911015 0.0526923722,1.78714582 0.00531914894,3.9906383 C0.00531914894,6.48510638 3.04821277,10.7249362 3.84634043,11.7725106 C3.89441644,11.8342147 3.96779115,11.870902 4.046,11.8723404 C4.12108289,11.8599144 4.19066901,11.8251213 4.24565957,11.7725106 C5.04353191,10.7744681 8.08642553,6.48510638 8.08642553,3.9906383 C8.08041883,2.92546653 7.65121274,1.9063751 6.89335579,1.15785668 C6.13549885,0.409338256 5.11116437,-0.0071999634 4.046,-2.50202013e-14 Z M4.046,6.73429787 C2.5583564,6.73429787 1.35238298,5.52832445 1.35238298,4.04068085 C1.35238298,2.55303725 2.5583564,1.34706383 4.046,1.34706383 C5.5336436,1.34706383 6.73961702,2.55303725 6.73961702,4.04068085 C6.74255038,4.75596773 6.45970147,5.44280541 5.95391301,5.94859386 C5.44812456,6.45438232 4.76128687,6.73723123 4.046,6.73429787 Z" id="Path_305" fillRule="nonzero"></path>
    //               <path d="M4.84412766,4.33991489 C4.60695682,4.18311171 4.33025213,4.09663043 4.046,4.09046809 C3.76174787,4.09663043 3.48504318,4.18311171 3.24787234,4.33991489 C2.72148434,4.60636606 2.35166243,5.10554994 2.25008511,5.6867234 L5.79187234,5.6867234 C5.72939909,5.10437099 5.37119262,4.59533567 4.84412766,4.33991489 Z" id="Path_306"></path>
    //               <path d="M4.04574468,3.9906383 C4.50823497,3.97692297 4.87994425,3.60521369 4.89365957,3.1427234 C4.88007669,2.68013343 4.50833055,2.30827534 4.04574468,2.29455319 C3.58325439,2.30826852 3.21154511,2.6799778 3.19782979,3.14246809 C3.19583552,3.36797791 3.28452032,3.58483506 3.44396207,3.74432482 C3.60340382,3.90381458 3.82023427,3.99256467 4.04574468,3.9906383 Z" id="Path_307"></path>
    //               </g>
    //           </g>
    //       </g>
    //   </svg>
    // )

    const currentLocationIcon = Icons.personPin();
    const header = (
      <button key="next" onClick={this.realTimeModeClicked}
        className={CSS.summaryButton} ref={this._summaryButtonRef}>
       <span> {currentLocationIcon}
        <span className="i-margin-left-small">{headerText}</span></span>
         {nextIcon}
      </button>
    );
    return (
      <div key="realtime-summary" className={CSS.main}>
        {header}
      </div>
    );
  }

  showGraphic(zoom,setLevel) {
    const trackingInfo = this.state.trackingInfo || {};
    const feature = trackingInfo && trackingInfo.feature;
    const view = Context.getInstance().views.activeView;
    const inactiveView = Context.getInstance().views.getInactiveView();
    let levelData = null;
    if (view && feature) {

      if (setLevel) {
        const facilityId = trackingInfo.facilityId;
        const verticalOrder = trackingInfo.floor;
        if (typeof facilityId === "string" && facilityId.length > 0 &&
          typeof verticalOrder === "number") {
          const levels = Context.getInstance().aiim.datasets.levels;
          if (levels) {
            const facilityData = levels.getFacilityData(facilityId);
            if (facilityData) {
              levelData = facilityData.levelsByVO[verticalOrder];
              if (!levelData) levelData = levels.getZeroVOLevel(facilityData);
              if (levelData) {
                if (typeof this.props.realTimePinActive === "function") {
                  this.props.realTimePinActive(true);
                }
                Topic.publish(Topic.ActivateLevel,{
                  facilityData: facilityData,
                  levelData: levelData,
                  view: Context.getInstance().views.activeView
                });
              }
            }
          }
        }
      }

      const options = {
        type: "realtime",
        z: feature.geometry.z,
        horizontalAccuracy: trackingInfo.horizontalAccuracy,
        verticalAccuracy: trackingInfo.verticalAccuracy
      }
      if (zoom) mapUtil.goToFeature(view,feature);
      mapUtil.addLocationGraphic(view,feature.geometry,options);
      if (inactiveView) mapUtil.addLocationGraphic(inactiveView,feature.geometry,options)
    }
  }

  watchLocation() {
    clearInterval(this._intervalID);
    this._intervalID = null;
    const millis = this._watchIntervalMillis;
    if (millis >= 1000) {
      this._intervalID = setInterval(() => {
        if (this._mounted) this.query();
      },millis);
    }
  }

}

const mapStateToProps = (state,ownProps) => {
  //const activeModeKey = ownProps.infoPanelKey + "-RealTime-ActiveMode";
  return {
    //rdxActiveMode: Rdx.getValue(state,activeModeKey),
  }
}

export default connect(mapStateToProps)(RealTimeLocation);
