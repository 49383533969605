import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";

export default class QueryAll extends BaseClass {

  numPerPage = 1000;

  async executeInParallel(url, query, options) {
    url = Context.checkMixedContent(url);
    const task = {
      options: options,
      url: url,
      query: query,
      chunks: [],
      features: []
    };
    let pageSize = options.layer && options.layer.sourceJSON && options.layer.sourceJSON.maxRecordCount ? options.layer.sourceJSON.maxRecordCount : options.numPerPage; 
    
    const lib = Context.instance.lib;
    const countTask = new lib.esri.QueryTask({url: url})
    const count = await countTask.executeForCount(query);
    if (count===0)
      return task;

    const pagePromises = [];
    for (let i=0; i<count; i+=pageSize) {
      const pagedQuery = task.query.clone();
      pagedQuery.start = i;
      pagedQuery.num = pageSize;
      const qtask = new lib.esri.QueryTask({url: task.url});
      
      pagePromises.push(new Promise((resolve, reject) => { 
        qtask.execute(pagedQuery).then(fs => {
          //console.log(`resolved concurrent PagedQuery[${i}] - got ${fs && fs.features && fs.features.length} features`);
          resolve(fs);
        }).catch(ex=>{
          reject(ex)
        }); 
      }));
      //or more concisely:
      //pagePromises.push(qtask.execute(pagedQuery));
    }

    const allResults = await Promise.all(pagePromises); //Array<FeatureSet>
    allResults.forEach(fs => fs && fs.features && task.features.push(...fs.features));
    //one error will invalidate the whole thing
    return task;
  }

  execute(url,query,options) {
    const promise = new Promise((resolve,reject) => {
      url = Context.checkMixedContent(url);
      const task = {
        options: options,
        url: url,
        query: query,
        chunks: [],
        features: []
      };
      if (options.numPerPage) {
        this.numPerPage = options.numPerPage;
      } else if (options.layer) {
        if (options.layer.sourceJSON && options.layer.sourceJSON.maxRecordCount) {
          this.numPerPage = options.layer.sourceJSON.maxRecordCount;
        }
      }
      // only need the signal option for now
      const { signal } = options;
      Promise.resolve().then(() => {
        if (options && options.objectIds) {
          task.objectIds = options.objectIds;
        } else {
          const lib = Context.instance.lib;
          const qtask = new lib.esri.QueryTask({url: url});
          return qtask.executeForIds(query, { signal }).then(objectIds => {
            //console.log("objectIds",objectIds,query)
            task.objectIds = objectIds;
          });
        }
      }).then(() => {
        this._makeChunks(task);
        return this._processChunks(task);
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _makeChunks(task,source,query) {
    const chunks = [];
    const objectIds = task.objectIds;
    if (Array.isArray(objectIds) && objectIds.length > 0) {
      const chunkLen = this.numPerPage;
      for (let i=0,j=objectIds.length; i<j; i+=chunkLen) {
        let a = objectIds.slice(i,i+chunkLen);
        if (a.length > 0) {
          let query = task.query.clone();
          // delete query.where;
          query.where = null;
          query.objectIds = a;
          chunks.push( {
            url: task.url,
            query: query,
            objectIds: a
          });
        }
      }
    }
    task.chunks = chunks;
  }

  _processChunk(task,chunk) {
    const lib = Context.instance.lib;
    const qtask = new lib.esri.QueryTask({url: chunk.url});
    return qtask.execute(chunk.query).then(result => {
      chunk.result = result;
      if (task.options && task.options.perFeatureCallback) {
        result.features.forEach(feature => task.options.perFeatureCallback(feature));
      }
    });
  }

  _processChunks(task) {
    const promise = new Promise((resolve,reject) => {

      const process = (chunkIdx) => {
        const isLast = (chunkIdx >= (task.chunks.length - 1));
        const chunk = task.chunks[chunkIdx];
        //console.log("chunkIdx",chunkIdx,"count",chunk && chunk.objectIds.length)
        this._processChunk(task,chunk).then(() => {
          if (isLast) {
            let features = []
            task.chunks.forEach(chunk => {
              if (chunk.result && chunk.result.features) {
                features = features.concat(chunk.result.features);
              }
            });
            task.features = features;
            resolve();
          } else {
            process(chunkIdx + 1)
          }
        }).catch(ex => {
          reject(ex);
        })
      }

      if (task.chunks && task.chunks.length > 0) {
        process(0);
      } else {
        resolve();
      }
    });
    return promise
  }

}
