import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import QueryAll from "./QueryAll";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from "./ReviewerManagement/reviewersUtil";
import { escSqlQuote } from "../../aiim/util/selectionUtil";
import * as sourceUtil from "./sourceUtil";

interface IRoleItem {
  areaId: string,
  email: string,
  knownas?: string,
  role: string,
  feature?: __esri.Graphic,
  username?: string
}
export default class AreaRolesTable extends BaseClass {

  table;

  static ROLE_RESERVATION_MANAGER = 1;

  getRoleData(areaId,role,peopleFeatures) {
    const data = [], lcidx = {};
    if (Array.isArray(peopleFeatures)) {
      const usernameField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_USERNAME);
      peopleFeatures.forEach(f => {
        f = f.feature || f;
        let value = aiimUtil.getAttributeValue(f.attributes,FieldNames.PEOPLE_EMAIL);
        if (typeof value === "string" && value.length > 0) {
          //value = value.trim();
          let lc = value.toLowerCase();
          if (!lcidx.hasOwnProperty(lc)) {
            let item: IRoleItem = {
              areaId: areaId,
              role: role,
              email: value,
              knownas: aiimUtil.getAttributeValue(f.attributes,FieldNames.PEOPLE_FULLNAME)
            };
            if (usernameField) item.username = null;
            data.push(item);
            lcidx[lc] = true;
          }
        }
      })
    }
    return data;
  }

  load(table) {
    const promise = new Promise<void>((resolve,reject) => {
      this.table = table;
      table.when().then(() => {
        if (table.loadStatus === "failed") {
          console.error("AreaRolesTable: load failed");
        }
      }).then(() => {
        resolve();
      }).catch(ex => {
        console.error("Error loading AREAS table",ex);
        reject(ex);
      });
      table.load();
    });
    return promise;
  }

  prepUsernames(roleData) {
    const promise = new Promise<void>((resolve,reject) => {
      const promises = [];
      const usernameField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_USERNAME);
      if (roleData && usernameField) {
        roleData.forEach(item => {
          promises.push(this.resolveUsernameByEmail(item));
        })
      }
      Promise.all(promises).then(() => {
        // console.log("prep.roleData",roleData)
        resolve();
      }).catch(ex => {
        reject(ex);
      })
    });
    return promise;
  }

  queryFullNames(data,where,num?) {
    const layer = sourceUtil.getPeopleLayer();
    const url = Context.checkMixedContent(layer.url)+"/"+layer.layerId;
    const knownasField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_FULLNAME);
    const emailField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_EMAIL);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = false;
    query.returnZ = false;
    query.where = where;
    query.orderByFields = [knownasField.name];
    queryUtil.applyGdbVersion(layer,query);
    if (typeof num === "number") query.num = num;
    const qtask = new Context.instance.lib.esri.QueryTask({url: url});
    return qtask.execute(query).then(result => {
      const idx = {}, names = [];
      const idx2 = {}, peopleFeatures = [];
      if (result && result.features) {
        result.features.forEach(f => {
          let v = f.attributes[knownasField.name];
          if (typeof v === "string") {
            if (!idx.hasOwnProperty(v)) {
              idx[v] = true;
              names.push(v);
            }
          }
          v = f.attributes[emailField.name];
          if (typeof v === "string") {
            if (!idx2.hasOwnProperty(v.toLowerCase())) {
              idx2[v.toLowerCase()] = true;
              peopleFeatures.push(f);
            }
          }
        })
      }
      data.fullNames = names;
      data.peopleFeatures = peopleFeatures;
      return names;
    });
  }

  queryRoleDataByAreaId(areaId: string, role?: number, queryFullNames?: boolean) {
    const promise = new Promise<{ items: IRoleItem[], fullNames?: string[], peopleFeatures?: __esri.Graphic[] }>((resolve,reject) => {
      const data: {
        items: IRoleItem[],
        fullNames?: string[]
      } = {
        items: []
      };
      const emailValues = [];
      const url = Context.checkMixedContent(this.table.url)+"/"+this.table.layerId;
      const areaIdField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_AREA_ID);
      const emailField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_EMAIL);
      const roleField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_ROLE);
      const usernameField = aiimUtil.findField(this.table.fields,FieldNames.AREA_ROLES_USERNAME);

      const query = new Context.instance.lib.esri.Query();
      query.outFields = ["*"];
      query.returnGeometry = false;
      query.returnZ = false;
      query.where = `(${areaIdField.name} = '${escSqlQuote(areaId)}')`;
      if (typeof role === "number") {
        let w = `(${roleField.name} = ${role})`
        query.where = query.where + " AND "+ w;
      }
      queryUtil.applyGdbVersion(this.table,query)
      let qaopts = {};
      const qa = new QueryAll();
      qa.execute(url,query,qaopts).then(result => {
        if (result && result.features) {
          result.features.forEach(f => {
            const email = f.attributes[emailField.name];
            const item: IRoleItem = {
              areaId: f.attributes[areaIdField.name],
              email: email,
              role: f.attributes[roleField.name],
              feature: f
            }
            if (usernameField) {
              item.username = f.attributes[usernameField.name];
            }
            data.items.push(item);
            if (typeof email === "string" && email.length > 0) {
              emailValues.push(`'${escSqlQuote(email.toUpperCase())}'`);
            }
          })
        }

      }).then(() => {
        if (queryFullNames) {
          data.fullNames = [];
          if (emailValues.length > 0) {
            let where = `(UPPER(${emailField.name}) IN (${emailValues.join(",")}))`;
            return this.queryFullNames(data,where);
          }
        }
      }).then(() => {
        resolve(data);
      }).catch(ex => {
        reject(ex)
      })
    });
    return promise;
  }

  async resolveUsernameByEmail(item) {
    try {
      if (item.email) {
        let { email, knownas } = item;
        const result = await getPortalUserByEmail({ email });
        const username = getUsernameFromPortalUsers(result, {
          attributes: { email, knownas }
        });
        if (typeof username === "string" && username.length > 0) {
          item.username = username;
        }
      }
    } catch(ex) {
      console.error(ex);
    }
  }

  // resolveUsernameByEmail(item) {
  //   const promise = new Promise((resolve,reject) => {
  //     let email = item.email;
  //     let knownas = item.knownas;
  //     let lc, user;
  //     if (typeof knownas === "string") {
  //       lc = knownas.toLowerCase();
  //     }
  //     let portal = Context.instance.getPortal();
  //     let queryPrams = {
  //       query: `email:(${email})`,
  //       num: 10
  //     }
  //     portal.queryUsers(queryPrams).then(result => {
  //       const results = result && result.results;
  //       if (results) {
  //         user = results.find(r => {
  //           if (typeof r.fullName === "string") {
  //             return lc === r.fullName.toLowerCase();
  //           }
  //           return false;
  //         });
  //         if (user) {
  //           item.username = user.username;
  //         }
  //       }
  //     }).then(() => {
  //       resolve();
  //     }).catch(ex => {
  //       console.error(ex);
  //       reject(ex);
  //     });
  //   });
  //   return promise;
  // }

  searchPeople(q) {
    const layer = sourceUtil.getPeopleLayer();
    const knownasField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_FULLNAME);
    const emailField = aiimUtil.findField(layer.fields,FieldNames.PEOPLE_EMAIL);
    let where = "1=1";
    if (typeof q === "string" && q.length > 0) {
      const c1 = `(${knownasField.name} LIKE '%${escSqlQuote(q)}%')`;
      const c2 = `(${emailField.name} LIKE '%${escSqlQuote(q)}%')`;
      where = `${c1} AND ${c2}`;
    }
    const data: {
      peopleFeatures?: __esri.Graphic[]
    } = {};
    return this.queryFullNames(data,where,10).then(() => {
      const items = [];
      if (data.peopleFeatures) {
        data.peopleFeatures.forEach(f => {
          items.push({
            knownas: f.attributes[knownasField.name],
            email: f.attributes[emailField.name]
          })
        })
      }
      return items;
    })
  }

}
