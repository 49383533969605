import ItemReference from "../base/ItemReference";
import Topic from "../../context/Topic";

export function hasGeometry(source,feature) {
  let ok = !!(feature && feature.geometry);
  if (ok) {
    let isPeople = false;
    if (typeof source === "string") {
      isPeople = (source === "People");
    } else {
      isPeople = source &&source.isAiimPeople();
    }
    // don't limit to just people
    if (isPeople || feature.geometry.type === "point") {
      let x = feature.geometry.x;
      let y = feature.geometry.y;
      if (x === null || y === null || (x === 0 && y === 0)) {
        ok = false;
      }
    }
  }
  return ok;
}

export function makeItemReference(source,feature,publishErrors?) {
  const ref = new ItemReference();
  const json = ref.getReferenceProperties(source,feature.attributes);
  return ref.fromJson(json).then(ok => {
    if (ok) {
      return Promise.resolve(ref);
    } else {
      console.warn("The item is invalid.",feature);
      Topic.publishErrorAccessingData();
    }
    return Promise.resolve(null);
  }).catch(ex => {
    // TODO inform user?
    console.warn("Error restoring item:");
    console.error(ex);
    return Promise.resolve(null);
  });
}
