import React from "react";

import Context from "../../../context/Context";
import * as component from "../../util/component";

export default class Attributes extends React.Component {

  _coreNode;
  _coreWidget;

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.setCoreNode = this.setCoreNode.bind(this);
  }

  componentDidMount () {
    try {
      if (this._coreNode) {
        const lib = Context.getInstance().lib;
        const feature = this.getFeature();
        //const layer = this.getLayer();
        //if (!this._coreWidget && feature && layer) {
        if (!this._coreWidget && feature) {
          const graphic = new lib.esri.Graphic({
            attributes: feature.attributes,
            popupTemplate: feature.popupTemplate
          });
          //if (feature.geometry) graphic.geometry = feature.geometry; // TODO
          // if (layer) graphic.layer = layer;
          if (!graphic.popupTemplate) {
            graphic.popupTemplate = this.getPopupTemplate();
          }
          this._coreWidget = new lib.esri.Feature({
            container: this._coreNode,
            view: Context.getInstance().views.activeView,
            graphic: graphic
          });
          this._coreWidget._shouldOpenInNewTab = function(url) {
            return false; // seems backwards
          };
          this._coreWidget.viewModel.set("graphic",graphic);
        }
      }
    } catch(ex) {
      console.error("Error initializing Attributes",ex);
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getDefaultFieldInfo(serviceFieldInfo) {
    //serviceFieldInfo: {name,alias,type,...}
    const fieldName = serviceFieldInfo.name;
    const item = {
      fieldName: fieldName,
      label: serviceFieldInfo.alias || fieldName,
      tooltip: "",
      visible: false,
      format: null,
      stringFieldOption: "text-box"
    };

    const type = serviceFieldInfo.type;
    //console.log("serviceFieldInfo.type",serviceFieldInfo.type);
    switch (type) {
      case "esriFieldTypeSmallInteger":
      case "esriFieldTypeInteger":
        item.format = {
          places: 0,
          digitSeparator: true
        };
        break;
      case "esriFieldTypeSingle":
      case "esriFieldTypeDouble":
        item.format = {
          places: 2,
          digitSeparator: true
        };
        break;
      case "esriFieldTypeDate":
        item.format = {
          dateFormat: "longMonthDayYear"
        };
        break;
      default:
    }
    return item;
  }

  getFeature() {
    if (this.props.feature) {
      return this.props.feature;
    }
    if (this.props.item && this.props.item.isValid()) {
      return this.props.item.getFeature();
    }
  }

  getLayer() {
    if (this.props.layer) {
      return this.props.layer;
    }
    const feature = this.getFeature();
    if (feature && feature.layer) {
      return feature.layer;
    } else {
      if (this.props.item && this.props.item.isValid()) {
        const source = this.props.item.getSource();
        if (source && source.layer2D) {
          return source.layer2D;
        }
      }
    }
  }

  getPopupTemplate() {
    if (this.props.item && this.props.item.isValid()) {
      const source = this.props.item.getSource();
      if (source && source.subLayer && source.subLayer.popupTemplate) {
        return source.subLayer.popupTemplate;
      }
      if (source && source.layer2D) {
        if (source.layer2D.popupTemplate) {
          return source.layer2D.popupTemplate;
        } else {
          return this.makePopupTemplate(source.layer2D);
        }
      }
    } else {
      const feature = this.getFeature();
      const layer = this.getLayer();
      if (feature && feature.popupTemplate) {
        return feature.popupTemplate;
      } else if (layer && layer.popupTemplate) {
        return layer.popupTemplate;
      }
    }
    return null;
  }

  makePopupTemplate(layer) {
    if (layer && layer.fields) {
      let fieldInfo;
      const fieldInfos = [];
      layer.fields.forEach(field => {
        fieldInfo = this.getDefaultFieldInfo(field);
        fieldInfo.visible = true;
        fieldInfos.push(fieldInfo);
      });
      const popupInfo = {
        title: layer.title,
        content: [
          {
            type: "fields",
            fieldInfos: fieldInfos
          },
          {
            type: "attachments"
          }
        ]
      };
      const lib = Context.getInstance().lib;
      return new lib.esri.PopupTemplate(popupInfo);
    }
    return null;
  }

  render() {
    const style = {};
    if (!this.props.active) style.display = "none";
    return (
      <div style={style} ref={this.setCoreNode}></div>
    );
  }

  setCoreNode(node) {
    this._coreNode = node;
  }

}
