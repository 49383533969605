import BaseClass from "../../../../util/BaseClass";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import Topic from "../../../../context/Topic";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "./editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as officePlanUtil from "../../../base/officePlanUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import { FpeType } from "../support/StencilLoader";

export default class SelectTool extends BaseClass {

  active;
  detailUseTypes;
  fpeType; FpeType

  constructor(props) {
    super(props);

    this.own([

      Topic.subscribe(Topic.ViewClicked, clickParams => {
        if (clickParams.event.button !== 0) {
          return;
        }
        if (this.fpeType === "unit") {
          this.selectUnit(clickParams);
        } else if (this.fpeType === FpeType.entryway || this.fpeType === FpeType.window || 
            this.fpeType === FpeType.furniture || this.fpeType === FpeType.transition) {
          this.selectDetail(clickParams,this.detailUseTypes);
        }
      })

    ])

  }

  activate() {
    this.active = true;
  }

  deactivate() {
    this.active = false;
  }

  onSelect(featureItem) {}

  async selectDetail(clickParams,detailUseTypes) {
    const layer = sourceUtil.getDetailsLayer();
    const useTypeField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAILS_USE_TYPE)
    return this.queryDetailHit({
      clickParams,
      hitFilter: (hit => {
        if (detailUseTypes && detailUseTypes.length > 0) {
          const v = hit.graphic.attributes[useTypeField];
          return (detailUseTypes.indexOf(v) !== -1); 
        }
        return true;
      })
    })
  }

  async selectUnit(clickParams) {
    return this.queryUnitHit({clickParams})
  }

  async queryDetailHit({clickParams,hitFilter}) {
    const layer = sourceUtil.getDetailsLayer();
    const layerView = mapUtil.getDetailsLayerView();
    const hit = await this.queryHit({clickParams,layerView,hitFilter})
    if (hit) {
      const oid = hit.graphic.attributes[layer.objectIdField]
      const feature = await officePlanUtil.queryDetailByObjectId(oid);
      const featureItem = {
        objectId: oid,
        globalId: feature.attributes[layer.globalIdField],
        feature: feature
      }
      this.onSelect(featureItem);
    }
  }

  async queryHit({clickParams,layerView,hitFilter}) {
    let hit;
    const event = clickParams.event;
    const vis = editorUtil.isLayerViewVisible(layerView);
    if (vis) {
      let result = await layerView.hitTest(event.mapPoint,event.screenPoint);
      result = Context.instance.aiim.fixHitTestResult(result);   
      if (Array.isArray(result) && result.length > 0) {
        result.some(h => {
          if (!hitFilter || hitFilter(h)) hit = h;
          return !!hit;
        });
      }
    }
    return hit;
  }

  async queryUnitHit({clickParams,hitFilter}) {
    const layer = sourceUtil.getUnitsLayer();
    const layerView = mapUtil.getUnitsLayerView();
    const hit = await this.queryHit({clickParams,layerView,hitFilter})
    if (hit) {
      const oid = hit.graphic.attributes[layer.objectIdField]
      const feature = await officePlanUtil.queryUnitByObjectId(oid);
      const featureItem = {
        objectId: oid,
        globalId: feature.attributes[layer.globalIdField],
        feature: feature
      }
      this.onSelect(featureItem);
    }
  }

}