import React from "react";

import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as itemUtil from "../../../aiim/util/itemUtil";

const CSS = {
  item: "i-event-item",
  itemLeft: "i-event-item-left",
  itemRight: "i-event-item-right",
  itemMonth: "i-event-item-month",
  itemDay: "i-event-item-day",
  itemYear: "i-event-item-year",
  itemName: "i-event-item-name",
  itemTimePeriod: "i-event-item-timeperiod",
  itemLocation: "i-event-item-location",
};

export default class EventItem extends React.Component {
  _componentId
  _itemRef  

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.detailsClicked = this.detailsClicked.bind(this);
    this._componentId = component.nextId("event-item-")
    this._itemRef = React.createRef()
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus()
        }
      })
    ])        
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  detailsClicked(event) {
    const source = aiimUtil.getSource(this.props.sourceKey);
    const feature = this.props.featureItem.feature;
    itemUtil.makeItemReference(source,feature,true).then(ref => {
      if (ref) {
        Topic.publish(Topic.ShowSearchResult,{
          sourceKey: ref.sourceKey,
          searchResult: ref.searchResult,
          zoom: true,
          highlight: true,
          trackRecent: true,
          componentId: this._componentId
        });
      }
    });
  }

  render() {
    const eventInfo = this.props.featureItem.eventInfo;
    if (!eventInfo) return null;
    const hashref = "#";

    const kl = "kl", kr = "kr";
    const kl1 = kl+"1", kl2 = kl+"2";
    const kr1 = kr+"1", kr2 = kr+"2", kr3 = kr+"3";

    //console.log("Event Info", eventInfo);

    const node = (
      <li>
        <button ref={this._itemRef} className={CSS.item} onClick={this.detailsClicked}>
          <div key={kl} className={CSS.itemLeft}>
            <abbr aria-label ={eventInfo.mmmm}><div key={kl1} className={CSS.itemMonth}>{eventInfo.mmm}</div></abbr>
            <div key={kl2} className={CSS.itemDay}>{eventInfo.d}</div>
          </div>
          <div key={kr} className={CSS.itemRight}>
            <div key={kr1} className={CSS.itemName}>{eventInfo.name}</div>
            <div key={kr2} className={CSS.itemTimePeriod}>{eventInfo.timePeriod}</div>
            <div key={kr3} className={CSS.itemLocation}>{eventInfo.location}</div>
          </div>
        </button>
      </li>
    );
    return node;
  }

}
