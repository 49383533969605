import React from "react";
import ReactToggle from "react-toggle";
import "react-toggle/style.css";

const CSS = {
  main: "i-toggle"
};

interface IToggleProps {
  ariaLabel?: string,
  checked: boolean,
  disabled?: boolean,
  id?: string,
  labelledBy?: string,
  onChange?: (checked: boolean) => void,
  onToggleBlur?: () => void,
  onToggleFocus?: () => void
}
export default class DayToggle extends React.Component<IToggleProps> {

  onChange = (evt) => {
    const v = evt.target.checked;
    if (this.props.onChange) this.props.onChange(v);
  }

  render() {
    return (
      <span className={CSS.main}>
        <ReactToggle id={this.props.id}
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.onChange}
          icons={false}
          aria-label={this.props.ariaLabel}
          aria-labelledby={this.props.labelledBy}
          onFocus={this.props.onToggleFocus}
          onBlur={this.props.onToggleBlur} />
      </span>
    );
  }

}