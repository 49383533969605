import React from "react";

import {ListItem as CalciteListItem} from "calcite-react/List";

export default class ListItem extends React.Component {

  render() {
    return (
      <CalciteListItem {...this.props}>
        {this.props.children}
      </CalciteListItem>
    );
  }

}
