import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-panel";
import {
  CalcitePanel
} from "@esri/calcite-components-react";

import { ReactComponent as IndoorsIcon } from "../../../assets/indoors-logo.svg";

interface Props {
}

interface State {
}

export default class Shell extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    return (
      <div className="i-miniapps-shell i--full">
        <CalcitePanel className="i--banner">
          <div className="i--heading" slot="header-content">
            <span><IndoorsIcon /></span>
            <h3>{"FLOOR PLAN EDITOR"}</h3>
          </div>
          {this.props.children}
        </CalcitePanel> 
      </div>
    )
  }
}
