import React from "react";
import Icons from "../../util/Icons";
import Context from "../../../context/Context";

export default class SortBy extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    if (this.props.disabled) return;
    if (this.props.onClick) this.props.onClick(this);
  }

  handleChange=(event)=>{
      if(!event || !event.target) return;
      let option=this.props.options[Number(event.target.value)];
      this.props.sortByClicked(option);
  }

  render() {
    let buttonGroup="";
    const currentDir = this.props.currentDir;
    let clsAsc, clsDesc;
    const i18n = Context.getInstance().i18n;
    if (currentDir === "desc") clsDesc = "i--active";
    else clsAsc = "i--active";
    //console.log("Sort by", this.props.sortBy);
    if(this.props.sortBy === "relevance" || this.props.sortBy === "distance"){
      buttonGroup=(
      <div>
        <button className= {"i-sorting-button "+clsAsc} type="button"
        aria-label= {i18n.workOrders.sort.ascendingSort}
        onClick={this.props.sortingAsc}>{Icons.ascendingSort()}</button>
      </div>
      )
    }else{
      buttonGroup=(
        <div className= "i--sortby-buttons">
          <button className= {"i-sorting-button "+clsAsc} type="button" 
          aria-label= {i18n.workOrders.sort.ascendingSort}
          onClick={this.props.sortingAsc}>{Icons.ascendingSort()}</button>
          <button className= {"i-sorting-button "+clsDesc} type="button"
          aria-label= {i18n.workOrders.sort.descendingSort}
          onClick={this.props.sortingDesc}>{Icons.descendingSort()}</button>
        </div>
      )
    }
    const items=[];
    let currentValue=null;
    for(let i=0;i<this.props.options.length; i++){
        if(this.props.options[i].value === this.props.sortBy){
            currentValue =i;
        }
        items.push(<option key={this.props.options[i].label} value={i} disabled={this.props.options[i].disabled} >
        {this.props.options[i].label} </option>)
    }

    return( <div className="i-sortby">
              <div className="i--a">
                <span>{this.props.caption}</span>
                <select value={currentValue} onChange={this.handleChange}>
                {items}
                </select>
              </div>
              {buttonGroup}
            </div>) ;
  }

}
