import Context from "../../../../context/Context";
import * as mapUtil from "../../../base/mapUtil";
import * as val from "../../../../util/val";
import { IFileInfo, IMediaItem } from "../../../miniapps/common/types";

export async function addFileToMap(fileInfo: IFileInfo): Promise<IMediaItem> {
  const lib = Context.instance.lib;
  const view = Context.instance.views.mapView;
  const extent = view.extent;
  const objectURL = URL.createObjectURL(fileInfo.blob);
  const imgel = new lib.esri.ImageElement({
    image: objectURL,
    georeference: new lib.esri.ExtentAndRotationGeoreference({
      extent: extent.clone()
    })
  });
  const georef = await lib.esri.mediaUtils.createDefaultControlPointsGeoreference(imgel,extent.clone())
  const imageElement = new lib.esri.ImageElement({
    image: objectURL,
    opacity: 1,
    georeference: georef
  });
  const id = "media-layer-"+ val.generateRandomId();
  let url = "./resources/media/"+id;
  if (fileInfo.extension) url += "."+fileInfo.extension
  const mediaLayer: __esri.MediaLayer = new lib.esri.MediaLayer({
    id: id,
    source: imageElement,
    title: fileInfo.title,
    opacity: 0.5,
    blendMode: "normal",
    spatialReference: view.spatialReference
  });
  await mediaLayer.load();
  view.map.add(mediaLayer);
  const mediaItem: IMediaItem = {
    fileInfo,
    mediaLayer,
    url,
    preserveAspectRatio: true,
    removed: false
  }
  return mediaItem;
}

export function asOperationalLayer(mediaItem: IMediaItem) {
  const mediaLayer = mediaItem.mediaLayer;

  // webmap spec requires controlPoints
  // @ts-ignore
  const georeference: any = mediaLayer.source.georeference;
  const georeferenceJSON = {
    width: georeference.width,
    height: georeference.height
  };
  georeference.writeControlPoints(georeference.controlPoints,georeferenceJSON)

  const opLayer = {
    id: mediaLayer.id,
    layerType: "MediaLayer",
    mediaType: "image", 
    title: mediaLayer.title,
    opacity: mediaLayer.opacity,
    visibility: mediaLayer.visible,
    url: mediaItem.url,
    georeference: georeferenceJSON
  }
  return opLayer;
}

export function fixOpacityOnLoad(view) {
  // jsapi issue @ 4.27 , both the MediaLayer and ImageElement have same opacity set effectivly doubling the transparency
  view && view.map && (view.type === "2d") && view.map.allLayers.forEach(l => {
    const url = l.url;
    if ((l.type === "media") && (typeof url === "string")) {
      if (url.toLowerCase().startsWith("./resources/media/")) {
        if (l.source) l.source.opacity = 1;
      }
    }
  })
}

export function getMediaItemsFromMap(): IMediaItem[] {
  const mediaItems: IMediaItem[] = [];
  const view = Context.instance.views.mapView;
  view && view.map && view.map.allLayers.forEach(l => {
    // @ts-ignore
    const url = l.url;
    if ((l.type === "media") && (typeof url === "string")) {
      if (url.toLowerCase().startsWith("./resources/media/")) {
        mediaItems.unshift({
          mediaLayer: (l as __esri.MediaLayer),
          url: url,
          preserveAspectRatio: true,
          removed: false
        })
      }
    }
  })
  return mediaItems;
}

export function getWebmapItem(): __esri.PortalItem {
  const view = Context.instance.views && Context.instance.views.mapView;
  // @ts-ignore
  const portalItem = view && view.map && view.map.portalItem;
  return portalItem;
}

export function hasMediaItems(): boolean {
  try {
    return (getMediaItemsFromMap().length > 0);
  } catch(ex) {
  }
  return false;
}

export async function hitTest(clickParams, mediaItems: IMediaItem[]): Promise<IMediaItem> {
  const screenPoint = clickParams.event.screenPoint;
  const mapPoint = clickParams.event.mapPoint;
  for await (const mediaItem of mediaItems) {
    const lyr = mediaItem.mediaLayer;
    const lv: any = lyr && mapUtil.getLayerView(lyr);
    if (lyr && lyr.visible && lv && lv.visibleAtCurrentScale && (typeof lv.hitTest === "function")) {
      const result = await lv.hitTest(mapPoint, { x: screenPoint.x, y: screenPoint.y });
      if (Array.isArray(result) && result.length > 0) {
        return mediaItem;
      }
    }
  }
  return null;
}
