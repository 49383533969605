import React from "react";

import Categories from "./Categories";
import Context from "../../../context/Context";
import Items from "./Items";
import SidebarHeader from "../Sidebar/SidebarHeader";
import * as component from "../../util/component";

const CSS = {
  main: "i-explore",
  content: "i-explore-content",
  category: "i-category",
  scrollItems: "i-scroll-items"
};

export default class ExploreCIM extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      activeCategory: null
    });
  }

  componentWillUnmount() {
    //console.log("Explore::componentWillUnmount");
  }

  backClicked = (evt) => {
    const activeCategory = this.state.activeCategory;
    if (activeCategory) {
      this.setState({
        activeCategory: activeCategory.xtn.parentCategory
      });
    }
  };

  categoryClicked = (evt,category) => {
    this.setState({
      activeCategory: category
    });
  };

  render() {
    const aiim = Context.instance.aiim;
    const cimCategories = aiim.getCIMCategories();
    let category = this.state.activeCategory;
    if (!category) {
      category = cimCategories && cimCategories.categoryData
    }
    let hasCategories = !!(category && category.categories);
    let source = category && category.xtn && category.xtn.source;

    if (hasCategories) {
      return (
        <CategoriesPanel category={category}
          sidebarKey={this.props.sidebarKey}
          backHandler={this.backClicked}
          clickHandler={this.categoryClicked} />
      );
    } else if (source) {
      return (
        <ItemsPanel category={category}
          sidebarKey={this.props.sidebarKey}
          backHandler={this.backClicked} />
      );
    } else {
      // TODO need to show a message if no categories were configured
      return (
        <ItemsPanel category={category} noItems={true}
          sidebarKey={this.props.sidebarKey}
          backHandler={this.backClicked} />
      );
      //return null;
    }
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class CategoriesPanel extends React.Component {
  render() {
    const i18n = Context.instance.i18n;
    const category = this.props.category;
    let caption = i18n.explore.panelCaption;
    let cls = CSS.content;
    if (!category.isRoot) {
      caption = category.name;
      cls = CSS.category;
    }
    return (
      <div>
        <SidebarHeader caption={caption}
          closeAriaLabel={i18n.explore.closeAriaLabel}
          sidebarKey={this.props.sidebarKey}
          backHandler={this.props.backHandler}
          showBack={!category.isRoot} />
          <div className={CSS.main}>
            <div className={cls}>
              <div className={CSS.scrollItems}>
                <Categories category={this.props.category}
                  clickHandler={this.props.clickHandler} />
              </div>
            </div>
          </div>
      </div>
    );
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class ItemsPanel extends React.Component {
  render() {
    const i18n = Context.instance.i18n;
    const category = this.props.category;
    const rdxKey = "CategoryItems__"+category.id;
    const source = category.xtn.source;
    return (
      <div>
        <SidebarHeader caption={category.name}
            closeAriaLabel={i18n.explore.closeAriaLabel}
            sidebarKey={this.props.sidebarKey}
            backHandler={this.props.backHandler}
            showBack={true} />
        <div className={CSS.main}>
          <div className={CSS.category}>
            <div className={CSS.scrollItems}>
              {!this.props.noItems &&
                <Items rdxKey={rdxKey} category={category} sourceKey={source.key} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
