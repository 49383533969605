// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as queryUtil from "../../base/queryUtil";
import Context from "../../../context/Context";
import QueryCriteria from "../../base/QueryCriteria";

// Component specific modules (Component-styled, etc.)
import { ListItem, EmptyAlert } from "../../styles/Common/list";

// App components
import Button from "../../../common/components/Button";
import {ShowAllButton} from "../../styles/Common/button";

// Third-party components (buttons, icons, etc.)
import { CalciteH5 } from "calcite-react/Elements";
import Skeleton from "react-loading-skeleton";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

// JSON

// CSS

const NUMPERPAGE = 1000;
export default class Items extends Component {
  _activePromise;
  _mounted = false;

  preferSkeleton = false;

  constructor(props) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 120,
    });
    this.state = component.newState({
      exceededTransferLimit: false,
      featureCount: 0,
      featureItems: null,
      isWorking: true,
      searchText: "",
      wasMoreShowAll: false,
      preservePosition: 0,
      preserveScroll: false,
      moreCount: 1,
      index: null,
      moreClicked: false,
      showAllClicked: false,
      noMoreResults: false
    })
    this.moreClicked = this.moreClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.queryFeatures();

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, (params) => {
        let actions = this.props.requeryOnPlanActions;
        const queryCriteriaKey = this.props.queryCriteriaKey;
        let index = (params.index && 
                     typeof params.index !== "undefined" &&
                     params.queryCriteriaKey === queryCriteriaKey)
                     ? params.index : null
        if (actions && actions.indexOf(params.action) !== -1) {
          if (this._mounted) {
            this.setState({ isWorking: true, preserveScroll: true, index: index });
            this.setRdxFeatureItems([]);
            this.queryFeatures(false, true);
          }
        }
      }),

      Topic.subscribe(Topic.QueryCriteriaChanged, (params) => {
        const queryCriteriaKey = this.props.queryCriteriaKey;
        if (queryCriteriaKey && queryCriteriaKey === params.key) {
          if (this._mounted) {
            this.setState({ isWorking: true, preserveScroll: false });
            this.setRdxFeatureItems([]);
            this.queryFeatures(false);
          }
        }
      }),
    ]);
  }

  componentDidUpdate = () => {
    if (!this.props.disableForceUpdate || this.state.featureCount === 0) {
      this.cache.clearAll();
      this._list.forceUpdateGrid();
    }
  };

  componentWillUnmount() {
    this.setRdxFeatureItems([]);
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  getQueryCriteria() {
    return QueryCriteria.get(this.props.queryCriteriaKey);
  }

  getSource() {
    return this.getQueryCriteria().getSource();
  }

  moreClicked =()=> {
    if (this.state.isWorking) return;

    this.setState({
      isWorking: true,
      preserveScroll: true,
      preservePosition: this.state.featureCount,
      wasMoreShowAll: true,
      moreCount: this.state.moreCount + 1,
      moreClicked: true,
      showAllClicked: false
    },() =>{
      this.queryFeatures();
    });
  }

  publishListInfo(isRefreshing, count, totalCount) {
    const queryCriteriaKey = this.props.queryCriteriaKey;
    Topic.publish(Topic.ListCountInfo, {
      queryCriteriaKey,
      isRefreshing,
      count,
      totalCount,
    });
  }

  publishWorking(isWorking) {
    this.publishListInfo(isWorking);
  }

  _setRef = (ref) => {
    this._list = ref;
  };

  clearIndex =()=> {
    this.setState({
      wasMoreShowAll: false
    })
  }

  render() {
    const {
      featureItems,
      isWorking,
      preserveScroll,
      exceededTransferLimit,
      preservePosition,
      wasMoreShowAll
    } = this.state;
    const listItems = [];
    let scrollToIndex;

    if (featureItems) {
      featureItems.forEach((featureItem, index) => {
        listItems.push(featureItem);
      });
    }

    if (isWorking && !preserveScroll) {
      if (this.preferSkeleton) {
        listItems.length = 0;
        listItems.push("__skeleton__");
      }
      scrollToIndex = 0;
    } else if (!featureItems || featureItems.length === 0) {
      if (!isWorking) listItems.push("__empty__");
    } else {
      if (exceededTransferLimit) {
        listItems.push("__more__");
      }
    }

    if (wasMoreShowAll) {
      scrollToIndex = preservePosition - 1;
    } else if (preserveScroll && this.state.index) {
      scrollToIndex = this.state.index
    }

    return (
      <div style={{ flexGrow: 1 }}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                ref={this._setRef}
                estimatedRowSize={120}
                width={width}
                height={height}
                rowRenderer={this.renderRow}
                rowCount={listItems.length}
                deferredMeasurementCache={this.cache}
                rowHeight={this.cache.rowHeight}
                overscanRowCount={3}
                scrollToIndex={scrollToIndex}
                onScroll={this.clearIndex}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  }

  renderRow = ({ index, key, style, parent, isScrolling }) => {
    const { featureItems, isWorking, preserveScroll } = this.state;
    const { renderItem } = this.props;
    const featureItem = (featureItems && featureItems[index]) || null;
    style = {
      ...style,
      margin: "4px 0",
    };

    let row;
    if (isWorking && !preserveScroll) {
      if (this.preferSkeleton) {
        row = <Skeleton count={15} />;
      } else {
        if (featureItem) {
          row = renderItem(this.getSource(), featureItem, index);
        } else if (featureItems.length === index) {
          //row = (<Skeleton count={15} />);
          row = <div></div>;
        }
      }
    } else if (!featureItems || featureItems.length === 0) {
      row = this.renderEmptyList();
    } else {
      if (featureItem) {
        row = renderItem(this.getSource(), featureItem, index);
      } else if (featureItems.length === index) {
        if (this.state.exceededTransferLimit && !this.state.noMoreResults) {
          row = this.renderMore();
        } else if (this.state.noMoreResults) {
          const i18n = Context.instance.i18n
          row = (<div style={{textAlign: 'center'}}>{i18n.spaceplanner.select.noMoreItems}</div>)
        }
      }
    }

    return (
      <CellMeasurer
        key={key}
        cache={this.cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>{row}</div>
      </CellMeasurer>
    );
  };

  renderItem(source, featureItem, index) {
    if (typeof this.props.renderItem === "function") {
      return this.props.renderItem(source, featureItem, index);
    }
    const key = featureItem.key;
    return <ListItem key={key}>{key}</ListItem>;
  }

  renderMore() {
    const i18n = Context.instance.i18n;
    return (
      <ListItem key={"more"} moreButton>
        <Button onClick={this.moreClicked}>{i18n.general.more}</Button>
        {this.props.isShowAllButton && <ShowAllButton showAll onClick={this.showAll}>{i18n.messages.showAll}</ShowAllButton>}
      </ListItem>
    );
  }

  renderEmptyList() {
    const i18n = Context.instance.i18n;
    const msg = (this.props.noResultsMessage || i18n.messages.noResults);
    return (
      <EmptyAlert key="noResults">
        <CalciteH5>{msg}</CalciteH5>
      </EmptyAlert>
    );
  }

  setRdxFeatureItems(featureItems) {
    const rdxFeatureItemsKey = this.props.rdxFeatureItemsKey;
    if (rdxFeatureItemsKey) {
      Rdx.setValue(null, rdxFeatureItemsKey, featureItems);
    }
  }

  showAll =()=>{

    if (this.state.isWorking) return;

    this.setState({
      isWorking: true,
      preserveScroll: true,
      showAllClicked: true,
      preservePosition: this.state.featureCount,
      wasMoreShowAll: true,
      moreClicked: false,
      moreCount: 0
    },()=>{
      this.queryFeatures();
    })
  }

  queryFeatures=()=>{
    const source = this.getSource();
    const lastFeatureCount = this.state.featureCount;

    const queryCriteria = this.getQueryCriteria();
    const orderByFields = queryCriteria.makeOrderByFields();
    const where = queryCriteria.getWhere();
    const isMore = this.state.moreClicked;
    const showAll = this.state.showAllClicked;
    if (!source) {
      this.setState({ isWorking: false });
      this.publishWorking(false);
      return;
    }
    this.publishWorking(true);

    let options = {
      source,
      isMore : isMore,
      lastFeatureCount,
      orderByFields,
      where,
      queryCriteria,
    };

    let totalIterations, totalCount;
    const promise = this._activePromise = queryUtil.queryCount(options);
    promise.then((count) => {
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      totalCount = count;
      totalIterations = this.state.iterationCount;    
      if(this.state.showAllClicked){
        totalIterations = count/NUMPERPAGE;
        totalIterations = Math.ceil(totalIterations);
      }
      else if(isMore){
        let maxTotalIterations, numPerPage;

        totalIterations = this.state.moreCount;
        numPerPage = queryCriteria.num;
        if(!numPerPage) numPerPage = NUMPERPAGE;
        maxTotalIterations = count/numPerPage;
        maxTotalIterations = Math.ceil(maxTotalIterations);
        totalIterations = Math.min(totalIterations, maxTotalIterations);
      }
      else{
        totalIterations = 1;
      }
    }).then(()=>{
      let start = 0
      var promises = [];
      for(let i=0;i<totalIterations; i++){
        let options = {
          source,
          isMore : isMore,
          lastFeatureCount : start,
          orderByFields,
          where,
          queryCriteria,
          isShowAll : showAll
        };

        promises.push(this.getRecords(options));
        let numPerPage = queryCriteria.num;
        if (!numPerPage) numPerPage = NUMPERPAGE;
        start = start + numPerPage
      }
      return Promise.all(promises);
    })
    .then((results)=>{
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;

      let featureItems, featureCount, noMoreRes = false;
      if(!results || results.length === 0) noMoreRes = true;
      else {
        for(let i=0;i<results.length;i++){
          if (!results[i].featureItems) {
            noMoreRes = true;
            break;
          } 
          if(featureItems) featureItems = featureItems.concat(results[i].featureItems);
          else featureItems = results[i].featureItems;
        }
      }

    if (featureItems && featureItems.length> 0) {
        let exceededTransferLimit;
        featureCount = featureItems.length;
        if(featureCount < totalCount) exceededTransferLimit = true;
        else exceededTransferLimit = false;
        this.setState(() => {
          return {
            exceededTransferLimit: exceededTransferLimit,
            featureCount: featureCount,
            featureItems: featureItems,
            isWorking: false,
            preserveScroll: true,
            noMoreResults: noMoreRes
          };
        });
        this.setRdxFeatureItems(featureItems);
      this.publishListInfo(false, featureCount, totalCount);
    } else {
      // if (isMore) {
          // TODO ???
      // } else {
        this.setState((state) => {
          return {
            exceededTransferLimit: false,
            isWorking: false,
            featureCount: 0,
            featureItems: [],
            wasMoreShowAll: false,
            preserveScroll: false,
            noMoreResults: true
          };
        });
        this.setRdxFeatureItems([]);
        this.publishListInfo(false, 0, 0);
      // }
    }
    })
    .catch((error)=> {
      console.log("Error in querying all people records", error);
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;
      this.setState({ isWorking: false });
      this.publishWorking(false);
      Topic.publishErrorAccessingData();
    })
  }

  getRecords =(options)=> {
    return queryUtil.queryFeatures(options).then((result) =>{
      if (result && result.features && result.features.length > 0) {
        const source = this.getSource();
        const resultInfo = queryUtil.makeResultInfo(source, result);
        const resultObject = {
          featureItems: resultInfo.featureItems,
          exceededTransferLimit: resultInfo.exceededTransferLimit
        }
        return resultObject;
      }
      return [];
    })
  } 

}
