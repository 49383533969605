import React from "react";

import Context from "../../../context/Context";
//import { ModalController } from "../Modal";
import { ModalController } from "../../../common/components/Modal";
import * as assignPrompt from "./assignPrompt";
import * as component from "../../../components/util/component";
import UnitsList from "../Modal/UnitsList";
import * as officePlanUtil from "../../base/officePlanUtil";

import { CalciteP } from "calcite-react/Elements";
import { Container } from "../../styles/Common/selectArea";
import { getAttributes } from "./MultipleAssignments/multipleAssignmentsUtil";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";

export default class SelectUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onSelectionChanged = selected => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(selected);
    }
  };

  render() {
    const prompt = assignPrompt.getPrompt(this.props.promptOptions);
    return (
      <Container>
        <CalciteP>{prompt.text}</CalciteP>
        <UnitsList
          handleSelection={this.onSelectionChanged}
          returnGeometry={this.props.returnGeometry}
          disabledUnitIds={this.props.disabledUnitIds}
        />
      </Container>
    );
  }

  static showModal(props) {
    let content, controller, okButton, selected, warningComponent;

    const onOK = obj => {
      if (props && props.onOK) {
        props.onOK(selected);
      }
    };

    const queryAssignments = (featureItem) => {
      return officePlanUtil
        .queryPeopleAssignedToUnit(featureItem)
        .then((result) => {
          return result.length
        })
        .catch((ex) => {
          console.error("Error querying people assigned to unit", ex);
        });
    }

    let selectProps = {
      promptOptions: props && props.promptOptions,
      returnGeometry: props && props.returnGeometry,
      disabledUnitIds: props && props.disabledUnitIds,
      onSelectionChanged: sel => {
        selected = sel;
        if (okButton) {
          if (sel) okButton.enable();
          else okButton.disable();
        }

        if (selected && selected.feature && warningComponent) {
          queryAssignments(selected).then(assignments => {
            const selectedAttributes = getAttributes(selected)
            const capacity = getAttributeValue(selectedAttributes, FieldNames.UNITS_CAPACITY)
            const uniquePeople = []
            const people = props.promptOptions.subjectItems
            people.forEach(person => {
              const personAttributes = getAttributes(person)
              const uniqueId = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
              if (!uniquePeople.includes(uniqueId)) uniquePeople.push(uniqueId)
            })
            const peopleToAdd = uniquePeople.length
            const projectedAssignments = peopleToAdd + assignments
            const chk = (typeof capacity === "number");
            if (chk && (projectedAssignments > capacity)) {
              let i18n = Context.instance.i18n
              let message = i18n.spaceplanner.capacity.atCapacityWarningUnit
              message = message.replace("{people}", peopleToAdd)
              message = peopleToAdd === 1 ?
                message.replace("{nounType}", i18n.spaceplanner.capacity.captionSingular) :
                message.replace("{nounType}", i18n.spaceplanner.capacity.captionPlural)
              message = message.replace("{capacity}", capacity)
              message = message.replace("{assignments}", assignments)
              message = assignments === 1 ?
                message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionSingular) :
                message.replace("{assignmentNounType}", i18n.spaceplanner.capacity.captionPlural)
              warningComponent.setMessage(message)
            } else {
              warningComponent.setMessage(null)
            }
          })
        }
      }
    };

    let modalProps = {
      title: props && props.title,
      showOKCancel: true,
      okLabel: Context.instance.i18n.spaceplanner.buttonLabel.assign,
      onOK: onOK,
      dialogStyle: { height: "65vh", width: "35vw" }
    };
    modalProps.onMountOKButton = btn => {
      okButton = btn;
      okButton.disable();
    };
    modalProps.onMountWarning = warning => {
      warningComponent = warning
    }

    content = <SelectUnit {...selectProps} />;
    controller = new ModalController({});
    controller.show(modalProps, content);
  }
}
