import React, { Component, createRef } from "react";
import Context from "../../../context/Context";

export default class LayerItems extends Component {

    _coreNode;
    _coreWidget;
    nodeRef = createRef();

    constructor(props) {
        super(props);
        this.setCoreNode = this.setCoreNode.bind(this);
    }

    componentDidMount() {
        this._initWidget();
    }

    _initWidget() {
        const context = Context.getInstance();
        const view = (context.views && context.views.activeView);
        if (this._coreNode) {
            this._coreWidget = new context.lib.esri.LayerList({
                container: this._coreNode,
                view: view,
            })
        }
    }

    render() {
        return <div ref={this.setCoreNode}></div>;
    }

    setCoreNode(node) {
        this._coreNode = node;
    }
}