import React, { useRef, useState } from "react";
import Context from "../../../../context/Context";

import * as featureItemUtil from "../copypaste/featureItemUtil";
import * as hoverUtil from "./hoverUtil";
import FeatureListItem from "./FeatureListItem";
import { IFeatureItem } from "../copypaste/featureItemUtil";

import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "@esri/calcite-components/dist/components/calcite-list";
import { CalciteList } from "@esri/calcite-components-react";

interface IFeatureListProps {
  selectedFeatureItems?: IFeatureItem[],
  onRemove?: (item: IFeatureItem) => void,
  onSelect?: (featureItem: IFeatureItem) => void,
  virtualize: boolean
}

const FeatureList = ({
  selectedFeatureItems,
  onRemove = () => { },
  onSelect,
  virtualize = true
}: IFeatureListProps) => {
  const i18n = Context.instance.i18n;
  const listRef = useRef<List>();
  const cacheRef = useRef<CellMeasurerCache>(new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 51
  }));
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const renderItem = (featureItem: IFeatureItem) => {
    const removeable = true;
    const selected = !!featureItem.selected;
    return (
      <FeatureListItem
        key={featureItem.layerType+"_"+featureItem.oid} 
        featureItem={featureItem}
        removeable={removeable}
        selected={selected}
        virtualize={virtualize}
        //invalid={invalid}
        //invalidMessage={i18n.editor.copypaste.notOnALevel}
        onMouseEnter={e => {
          hoverUtil.showHoverGraphic(featureItem);
        }}
        onMouseLeave={e => {
          hoverUtil.clearHoverGraphic();
        }}
        onRemove={e => {
          hoverUtil.clearHoverGraphic();
          onRemove(featureItem);
          // if (step === "selecting") {
          //   const newResults = [...hitTestResults];
          //   newResults.splice(item.hitIndex, 1);
          //   dispatch(setHitTestResults(newResults));
          //   dispatch(setSelectedFeatureIndex(selectedFeatureIndex >= newResults.length
          //     ? newResults.length - 1
          //     : selectedFeatureIndex
          //   ))
          // } else if (step === "pasted") {
          //   pastedFeatureItems.splice(i,1);
          //   if (pastedFeatureItems.length === 0) {
          //     this.reset(false);
          //   } else {
          //     this.viewModel.removeGraphic(item,true);
          //     if (this.state.activeFeatureItem && this.state.activeFeatureItem.key === item.key) {
          //       this.setState({activeFeatureItem: null})
          //     } else {
          //       component.refresh(this);
          //     }
          //   }
          // }
        }}
        onSelect={e => {
          //const ok = this.validateAttributes();
          const ok = true;
          if (ok) {
            const currentState = featureItem.selected;
            featureItemUtil.clearSelected(selectedFeatureItems);
            featureItem.selected = !currentState;
            const index = featureItem.selected ? featureItem.hitIndex : null;
            setSelectedIndex(index);
            onSelect(featureItem);
          }
          // if (step === "selecting" || step === "copied") {
          //   dispatch(setSelectedFeatureIndex(item.hitIndex))
          // } else if (step === "pasted") {
          //   const ok = this.validateAttributes();
          //   if (ok) {
          //     featureItemUtil.clearSelected(pastedFeatureItems);
          //     item.selected = true;
          //     this.setState({activeFeatureItem: item});
          //     this.viewModel.highlightActiveFeature(item);
          //   } else {
          //     component.refresh(this);
          //   }
          // }
        }}
      />
    )
  }
  const renderVirtualizedList = () => {
    if (selectedFeatureItems && selectedFeatureItems.length > 0) {
      return (
        <div style={{ flexGrow: 1 }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <List
                ref={listRef}
                estimatedRowSize={50}
                width={325}
                height={height}
                rowRenderer={({ index, key, style, parent }) => {
                  return (
                    <CellMeasurer
                      key={key}
                      cache={cacheRef.current}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      <div style={style}>{renderItem(selectedFeatureItems[index])}</div>                      
                    </CellMeasurer>
                  );
                }}
                rowCount={selectedFeatureItems.length}
                deferredMeasurementCache={cacheRef.current}
                rowHeight={cacheRef.current.rowHeight}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </div>
      );      
    }
    return <div></div>;   
  }
  const renderCalciteList = () => {
    if (selectedFeatureItems && selectedFeatureItems.length > 0) {
      const items = selectedFeatureItems.map(f => renderItem(f));
      return (
        <CalciteList
          selectionAppearance="border"
          selectionMode="single" // @todo selectionMode="single-persist" at 1.4.2
          onMouseLeave={e => {
            hoverUtil.clearHoverGraphic();
          }}
        >
          {items}
        </CalciteList>
      );
    }
    return <div></div>;
  }
  return (
    <>
      {virtualize
        ? renderVirtualizedList()
        : renderCalciteList()
      }
    </>
  )
}

export default FeatureList;