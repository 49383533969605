import React, {createRef} from "react";
import {connect} from "react-redux";

import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";

const CSS = {
  panelHeader: "i-infopanel-header i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  expandButton: "i-button-expand i-icon-button",
  closeButton: "i-button-close i-icon-button",
  infoPanelOpen: "i-infopanel-open"
};

class InfoPanelHeader extends React.Component {

  closeButtonRef = createRef();

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.hide = this.hide.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  componentDidMount() {
    let v = document.activeElement;
    if (v) v = v.parentElement;
    if (v) {
      v = v.getAttribute("data-i-infopanel-nav");
      if (v === "true") {
        // keep focus on the InfoPanel Back or Next button
        return;
      }
    }
    // initialize focus within the InfoPanel
    this._focus();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _focus() {
    const closeBtn = this.closeButtonRef && this.closeButtonRef.current;
    if (closeBtn && closeBtn.focus) {
      closeBtn.focus();
    }
  }

  hide() {
    Topic.publish(Topic.CloseInfoPanel,{});
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const caption = this.props.caption;
    const expanded = this.props.rdxSidebarPanelsExpanded;
    const expandIcon = expanded ? Icons.panelCollapse() : Icons.panelExpand();
    const expandTip = expanded ? i18n.general.collapse : i18n.general.expand;
    const hashref = "#";
    const id = this.props.infoPanelKey+"-header-label";
    return (
      <div className={CSS.panelHeader}>
        <h4 id={id}>{caption}</h4>
        <div className={CSS.panelHeaderTools}>
          <a className={CSS.expandButton} href={hashref} role="button"
            title={expandTip} onClick={this.toggleExpanded}>
            {expandIcon}
          </a>
          <a className={CSS.closeButton} href={hashref} role="button"
            title={i18n.general.close} aria-label={i18n.infoPanel.closeAriaLabel}
            tabIndex="0" onClick={this.hide}>
            {Icons.close()}
          </a>
        </div>
      </div>
    );
  }

  toggleExpanded() {
    let expanded = this.props.rdxSidebarPanelsExpanded;
    Rdx.setValue(this,Rdx.Keys.SIDEBAR_PANELS_EXPANDED,!expanded);
  }

}

const mapStateToProps = (state) => {
  return {
    rdxSidebarPanelsExpanded: Rdx.getValue(state,Rdx.Keys.SIDEBAR_PANELS_EXPANDED)
  }
}

export default connect(mapStateToProps)(InfoPanelHeader);
