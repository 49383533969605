import React from 'react'
import FieldNames from '../../../../aiim/datasets/FieldNames';
import * as aiimUtil from '../../../../aiim/util/aiimUtil';
import { ModalController } from '../../../../common/components/Modal';
import * as component from '../../../../components/util/component'
import Context from '../../../../context/Context';
import * as transactions from '../../../base/transaction/transactions';
import AssignmentIcon from "calcite-ui-icons-react/RouteToIcon";
import * as multipleAssignmentsUtil from "./multipleAssignmentsUtil";

import { Container } from "../../../styles/Common/selectArea";
import Button from '../../../../common/components/Button';
import Form, {
  Fieldset,
  FormControl
} from "calcite-react/Form";
import Checkbox from "calcite-react/Checkbox";
import { CalciteP } from 'calcite-react/Elements/Elements-styled';
import { isPersonUnassigned } from '../../../base/officePlanUtil';


export default class BulkDuplicatesModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = component.newState({
      keepAssignments: true,
      singlePerson: this.getUniquePeopleCount() === 1,
      assignments: null
    })
  }
  componentDidMount() {
    if (this.getUniquePeopleCount() === 1) {
      const person = this.props.people[0]
      const personAttributes = multipleAssignmentsUtil.getAttributes(person)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      multipleAssignmentsUtil.getPersonAssignments(personName, personEmail).then(assignments => {
        this.setState({ assignments: assignments })
      })
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this)
  }

  getUniquePeopleCount() {
    const uniquePeople = []
    this.props.people.forEach(person => {
      const personAttributes = multipleAssignmentsUtil.getAttributes(person)
      const uniqueId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      if (!uniquePeople.includes(uniqueId)) uniquePeople.push(uniqueId)
    })
    return uniquePeople.length
  }

  makeText = (assignTo) => {
    const i18n = Context.instance.i18n
    // const count = this.props.people.length
    const count = this.getUniquePeopleCount()
    const baseString = i18n.spaceplanner.multipleAssignments.modalText
    const assignToAttributes = assignTo.attributes || assignTo.feature.attributes
    const areaName = aiimUtil.getAttributeValue(assignToAttributes, FieldNames.AREA_NAME)
    let assignToName = null
    if (areaName) {
      assignToName = areaName
    } else {
      assignToName = aiimUtil.getAttributeValue(assignToAttributes, FieldNames.NAME)
    }
    let peopleText = ""
    if (count > 1) {
      peopleText = i18n.spaceplanner.popup.peopleLabel.replace("{count}", count)
    } else if (count === 1) {
      const person = this.props.people[0]
      const personAttributes = multipleAssignmentsUtil.getAttributes(person)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      peopleText = personName
    }
    if (assignToName) {
      return baseString.replace("{name}", peopleText).replace("{assignToName}", assignToName)
    } else return null
  }

  onCurrentCheckChange = (evt) => {
    const checked = !!evt.target.checked
    this.setState({
      keepAssignments: checked
    })
    if (this.props.onCurrentChanged) {
      this.props.onCurrentChanged(checked)
    }
  }

  renderItemTag(text) {
    // Make button eventually
    return (
      <div key={component.nextId("prompt-tag-")} className="i-modal-tag">
        {text}
      </div>
    )
  }

  render() {
    const i18n = Context.instance.i18n
    const formStyle = {maxHeight: '2.5rem'}
    const uniquePeople = {}
    this.props.people.forEach(person => {
      const personAttributes = multipleAssignmentsUtil.getAttributes(person)
      const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const uniqueId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const unassigned = isPersonUnassigned(person)
      if (!uniquePeople[uniqueId] && !unassigned) {
        uniquePeople[uniqueId] = personName
      }
    })

    let names = null
    if (!this.state.singlePerson) {
      names = Object.keys(uniquePeople).map(p => this.renderItemTag(uniquePeople[p]))
    } else {
      const { assignments } = this.state
      let assignmentNames = []
      if (assignments) {
        if (assignments.units && assignments.units.length > 0) {
          assignments.units.forEach(unit => {
            const unitAttributes = multipleAssignmentsUtil.getAttributes(unit)
            const unitName = aiimUtil.getAttributeValue(unitAttributes, FieldNames.NAME)
            assignmentNames.push(unitName)
          })
        }
        if (assignments.areas && assignments.areas.length > 0) {  
          assignments.areas.forEach(area => {
            const areaAttributes = multipleAssignmentsUtil.getAttributes(area)
            const areaName = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_NAME)
            assignmentNames.push(areaName)
          })
        }
      }
      names = assignmentNames.map(n => this.renderItemTag(n))
    }

    const choiceNode = (
      <Form style={formStyle}>
        <FormControl>
          <Fieldset name="assignmentOption">
            <Checkbox
              checked={this.state.keepAssignments}
              onChange={this.onCurrentCheckChange}>
              {i18n.spaceplanner.multipleAssignments.keepAssignments}
            </Checkbox>
          </Fieldset>
        </FormControl>
      </Form>
    )

    const namesNode = (
      <div className={"i-assignment-names"}>
        {names}
      </div>
    )

    const text = this.makeText(this.props.assignToFeature)
    const textNode = text ? (<CalciteP>{text}</CalciteP>) : null

    return (
      <div className="i-unassign-assignments">
        {textNode}
        {choiceNode}
        {namesNode}
      </div>
    )
  }

  static showModal(props) {
    let content
    const i18n = Context.instance.i18n
    const people = props.people
    const assignToFeature = props.assignToFeature

    let keepCurrentAssignments = true
    const onCurrentChanged = (checked) => {
      keepCurrentAssignments = !!checked
    }

    const onOK = () => {
      if (props && props.onOK) {
        props.onOK(keepCurrentAssignments)
      }
    }

    let selectProps = {
      people: people,
      assignToFeature: assignToFeature,
      onCurrentChanged: onCurrentChanged
    }

    const uniquePeople = []
    people.forEach(person => {
      const personAttributes = multipleAssignmentsUtil.getAttributes(person)
      const uniqueId = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      if (!uniquePeople.includes(uniqueId)) uniquePeople.push(uniqueId)
    })

    const personString = uniquePeople.length > 1 ? 
      i18n.spaceplanner.multipleAssignments.people : 
      i18n.spaceplanner.multipleAssignments.person
    const title = i18n.spaceplanner.multipleAssignments.modalTitle
      .replace("{person}", personString)
      .replace("{to}", props.to)
    content = <BulkDuplicatesModal {...selectProps} />
    let modalOptions = {
      title: title,
      className: "i-assign-people-modal",
      content: content,
      okLabel: i18n.spaceplanner.buttonLabel.assign
    }
    ModalController.confirm(modalOptions).then(result => {
      if (result.ok) {
        onOK()
      }
    })
  }
}