import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../redux/Rdx";
import { Dispatch } from "redux";
import {
  getActiveTab,
  setActiveTab,
  getAttributeEditorEnabled,
  setAttributeEditorEnabled,
  getActiveHelpTip,
  setHelpTip,
  HelpTip,
  EditorTabs
} from "./redux";
import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import { CalciteButton, CalciteIcon } from "@esri/calcite-components-react";

interface ISidebarHeaderProps {
  helpTip: HelpTip,
  goBackEnabled: boolean,
  goBack: () => void,
  icon: JSX.Element,
  label: string | JSX.Element,
  setActiveTab: (tab: EditorTabs) => void,
  setHelpTip: (tip: HelpTip) => void,
  tab: EditorTabs
}
class SidebarHeader extends React.Component<ISidebarHeaderProps> {
  constructor(props: ISidebarHeaderProps) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const { goBackEnabled, goBack, helpTip } = this.props;
    return (
      <div className="i-editor-sidebar-header">
        {goBackEnabled &&
          <CalciteButton
            appearance="transparent"
            iconStart="chevron-left"
            scale="l"
            title={i18n.general.back}
            onClick={() => goBackEnabled && goBack()} />
        }
        {this.props.icon}
        <h4>
          {this.props.label}
          {helpTip && helpTip.visible &&
            <CalciteIcon className="i-editor-sidebar-tip-bulb"
              icon="lightbulb" scale="s" title={helpTip.tooltip}
              onClick={() => this.props.setHelpTip({ ...helpTip, visible: false })} />
          }
        </h4>
        <CalciteButton
          appearance="transparent"
          iconStart="x"
          scale="l"
          className="i--close"
          title={i18n.general.close}
          onClick={() => {
            this.props.setActiveTab(null);
            goBackEnabled && goBack();
          }} />
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  const activeTab = getActiveTab(state);
  return {
    goBackEnabled: activeTab === "editor" && getAttributeEditorEnabled(state),
    helpTip: getActiveHelpTip(state)
  };
};
const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: Pick<ISidebarHeaderProps, "tab" | "icon" | "label">) => ({
    setActiveTab: (tab: EditorTabs) => dispatch(setActiveTab(tab)),
    setHelpTip: (tip: HelpTip) => dispatch(setHelpTip(ownProps.tab, tip)),
    goBack: () => dispatch(setAttributeEditorEnabled(false))
  });
export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
