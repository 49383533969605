import React from "react";

import Context from "../../../context/Context";
import Warning from "./Warning";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-combobox";
import "@esri/calcite-components/dist/components/calcite-combobox-item";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-switch";
import {
  CalciteBlock,
  CalciteCombobox,
  CalciteComboboxItem,
  CalciteLabel,
  CalciteSwitch,
} from "@esri/calcite-components-react";

interface Props {
  onChange: ({isValid}) => void;
}

interface State {
  enabled: boolean,
  groupId: string,
  groupTitle: string,
  groups: any,
  groupsWarning: string,
  loading: boolean,
  showPrompt: boolean
}

export default class MergePermission extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    const config = Context.instance.config;
    this.state = component.newState({
      enabled: config.spaceplanner.mergepermission.restrictUsers,
      groupId: config.spaceplanner.mergepermission.groupId,
      groupTitle: config.spaceplanner.mergepermission.groupTitle,
      groups: null,
      groupsWarning: null,
      loading: true,
      showPrompt: false
    });
  }

  componentDidMount() {
    this.queryGroups();
  }

  apply = () => {
    const { enabled, groupId, groupTitle, groupsWarning } = this.state;
    const config = Context.instance.config;
    if (!enabled) {
      config.spaceplanner.mergepermission.restrictUsers = false;
      config.spaceplanner.mergepermission.groupId = null;
      config.spaceplanner.mergepermission.groupTitle = null;
    } else if (enabled && groupId && groupTitle && !groupsWarning) {
      config.spaceplanner.mergepermission.restrictUsers = true;
      config.spaceplanner.mergepermission.groupId = groupId;
      config.spaceplanner.mergepermission.groupTitle = groupTitle;
    } else {
      config.spaceplanner.mergepermission.restrictUsers = enabled;
      config.spaceplanner.mergepermission.groupId = groupId;
      config.spaceplanner.mergepermission.groupTitle = groupTitle;
    }
    this.onChange()
  }

  onChange() {
    const { enabled, groupId, groupsWarning} = this.state;
    const invalid = (enabled && (!groupId || !!groupsWarning));
    this.props.onChange({isValid: !invalid});
  }

  async queryGroups() {
    const i18n = Context.instance.i18n;
    try {
      const groups = await configUtil.queryGroups();
      const groupsWarning = (groups && groups.length > 0) ? null : i18n.miniapps.configurator.mergePermission.noGroups;
      this.setState({
        groups, 
        groupsWarning, 
        loading: false
      }, this.apply)
    } catch(ex) {
      console.error(ex);
      this.setState({
        groupsWarning: i18n.miniapps.configurator.mergePermission.unableToLoadGroups,
        loading: false
      }, this.apply)
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { enabled, loading } = this.state;
    return (
      <CalciteBlock 
        open 
        heading={i18n.miniapps.configurator.mergePermission.caption}
        description={i18n.miniapps.configurator.mergePermission.description}
        loading={loading ? true : undefined}
      >
        <div className={loading ? "i-min-block" : undefined}>
          <div style={{display: "flex"}}>
            <CalciteLabel layout="inline" className="i-cursor-pointer" scale="s">
              {i18n.miniapps.configurator.mergePermission.enable}
              <CalciteSwitch 
                scale="s"
                checked={enabled ? true : undefined}
                onCalciteSwitchChange={e => {
                  const v = !!e.target.checked; 
                  this.setState({enabled: v, groupId: null, groupTitle: null, showPrompt: true}, this.apply);
                }}
              />
            </CalciteLabel>
          </div>
          {enabled && !loading && this.renderContent()}
        </div>
      </CalciteBlock>
    )
  }

  renderContent() {
    const i18n = Context.instance.i18n;
    const { groupId, groups, groupsWarning, showPrompt } = this.state;
    const hasGroups = (!groupsWarning && groups && groups.length > 0);
    const list = hasGroups && groups.map(g => this.renderGroup(g));
    return (
      <div className="i-toggled-content2">
        {hasGroups && (
          <CalciteCombobox 
            scale="s"
            allowCustomValues={false} 
            selectionMode="single" 
            label="groups"
            placeholder={i18n.miniapps.configurator.mergePermission.placeholder}
            className={showPrompt ? "i--border-danger" : "i--none"}
            onCalciteComboboxChange={e => {
              let info;;
              e.target && e.target.selectedItems && e.target.selectedItems.some(item => {
                if (item.selected) {
                  info = {groupId: item.value, groupTitle: item.textLabel};
                }
                return !!info
              });
              if (info && info.groupId) {
                this.setState({
                  groupId: info.groupId, 
                  groupTitle: info.groupTitle,
                  showPrompt: false
                }, this.apply);
              }
            }}
          >
            {list}
          </CalciteCombobox>
        )}
        {groupsWarning && <Warning message={groupsWarning} />}
      </div>
    )
  }

  renderGroup(group) {
    const { groupId } = this.state;
    return (
      <CalciteComboboxItem 
        key={group.id} 
        value={group.id} 
        textLabel={group.title} 
        selected={group.id === groupId}
      />
    )
  }

}
