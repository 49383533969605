import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Context from "../../../../context/Context";
import Topic from "../../../../context/Topic";
import {
  getActiveFeatureType,
  getHitTestResults,
  setHitTestResults,
  getSelectedFeatureIndex,
  setSelectedFeatureIndex,
  setUndoRedoDisabled,
  HitTest,
  getActiveHelpTip,
  setHelpTip
} from "../redux";
import { findFieldName, getAttributeValue } from "../../../../aiim/util/aiimUtil";
import Attributes, { IAttributesHandle } from "../support/Attributes";
import MapSelection from "../support/MapSelection";
import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-alert";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-list";
import "@esri/calcite-components/dist/components/calcite-list-item";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import {
  CalciteAction,
  CalciteAlert,
  CalciteButton,
  CalciteCheckbox,
  CalciteIcon,
  CalciteInputNumber,
  CalciteLabel,
  CalciteList,
  CalciteListItem,
  CalciteNotice,
  CalciteSegmentedControl,
  CalciteSegmentedControlItem
} from "@esri/calcite-components-react";
import { getDetailsLayer, getUnitsLayer } from "../../../base/sourceUtil";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import MergeVM from "./MergeVM";
import { IAlertOptions } from "../../../miniapps/common/components/Modal";
import { useHandles } from "../../../miniapps/common/hooks";
import Warning from "../../../miniapps/configurator/Warning";
import { getDefaultGapUnits } from "../support/unitUtil";
import { Units } from "../redux";
import { LayerType } from "../../../../util/interfaces";

interface IListItem {
  description: string,
  key: LayerType,
  oid: number,
  index: number,
  selected: boolean,
  title: string
}
type GapUnit = {
  value: __esri.LinearUnits,
  label: string,
  min: number,
  max: number,
  step: number,
  default: number
};

const MergePanel = ({ selectionType }: { selectionType: Extract<LayerType, "unit" | "detail"> }) => {
  const dispatch = useDispatch();
  const ctx = Context.getInstance();
  const { i18n, lib } = ctx;
  // https://resources.arcgis.com/en/help/arcobjects-cpp/componenthelp/index.html#/esriSRUnit2Type_Constants/000w00000041000000/
  const AvailableUnits: Record<Units, GapUnit> = {
    feet: { value: "feet", label: i18n.editor.merge.feet, min: 0, max: 2, step: .1, default: .4 },
    inches: { value: 109009, label: Context.instance.i18n.editor.merge.inches, min: 0, max: 24, step: 1, default: 5 },
    millimeters: { value: 109007, label: Context.instance.i18n.editor.merge.millimeters, min: 0, max: 610, step: 1, default: 120 }
  };
  const viewModel = useRef<MergeVM>(new MergeVM());
  const results = useSelector(getHitTestResults);
  const selectedFeatureIndex = useSelector(getSelectedFeatureIndex);
  const activeType = useSelector(getActiveFeatureType);
  const tip = useSelector(getActiveHelpTip);
  const [alertOptions, setAlertOptions] = useState<IAlertOptions>({ open: false });
  const [canMerge, setCanMerge] = useState<boolean>(false);
  const [viewLoaded, setViewLoaded] = useState<boolean>(viewModel.current.getView() != null);
  const setHandles = useHandles()[1];
  const units = getDefaultGapUnits();
  const tolerance = AvailableUnits[units].default;
  const gapState = useRef<IGapState>({
    dissolveWalls: true,
    removeGaps: true,
    units: units,
    tolerance: tolerance
  });
  const attributesRef = useRef<IAttributesHandle>();
  const saveBtnRef = useRef<HTMLCalciteButtonElement>();
  const hitTest = useRef<HitTest>(results && results.length ? results[selectedFeatureIndex] : null);
  const feature = useRef<__esri.Graphic>(hitTest.current ? lib.esri.Graphic.fromJSON(hitTest.current.feature) : null);
  const selection = useRef<HitTest[]>(results);
  const unitsLayer = getUnitsLayer();
  const detailsLayer = getDetailsLayer();

  const items = results.map((h, index) => {
    const graphic: __esri.Graphic = lib.esri.Graphic.fromJSON(h.feature);
    const layer: __esri.FeatureLayer = h.key === "unit" ? unitsLayer : detailsLayer;
    graphic.layer = layer;
    const title = getAttributeValue(graphic.attributes, findFieldName(layer.fields, FieldNames.UNITS_USE_TYPE));
    const oid = getAttributeValue(graphic.attributes, layer.objectIdField);
    const description = h.key === "unit"
      ? getAttributeValue(graphic.attributes, findFieldName(layer.fields, FieldNames.NAME))
      : getAttributeValue(graphic.attributes, findFieldName(layer.fields, FieldNames.DETAIL_ID));
    if (index === selectedFeatureIndex) {
      hitTest.current = h;
      feature.current = graphic;
    }
    return {
      key: h.key as LayerType,
      description,
      oid,
      index,
      selected: index === selectedFeatureIndex,
      title
    };
  });

  useEffect(() => {
    const handles = [];
    handles.push(
      Topic.subscribe(Topic.PlanModified, params => {
        try {
          if (params && (params.wasUndoRedo || params.wasReconciled)) {
            reset();
          }
        } catch(ex) {
          console.error(ex);
        }
      })
    );
    if (!viewLoaded) {
      handles.push(
        Topic.subscribe(Topic.ViewActivated, () => {
          setViewLoaded(true);
        })
      );
    }
    setHandles(handles);

    return () => {
      viewModel.current.reset();
      selection.current.length = 0;
      dispatch(setHitTestResults([]));
      dispatch(setSelectedFeatureIndex(null));
      dispatch(setUndoRedoDisabled(false));
    }
  }, []);

  useEffect(() => {
    selection.current = results;
    setCanMerge(validate());
    preview();
    dispatch(setUndoRedoDisabled(activeItems().length > 1));
  }, [results]);

  const reset = () => {
    viewModel.current.reset();
    selection.current.length = 0;
    closeAlert();
    setCanMerge(false);
    dispatch(setHitTestResults([]));
    dispatch(setSelectedFeatureIndex(null));
  }
  const preview = () => {
    const geometries = activeItems().map(h => lib.esri.geometryJsonUtils.fromJSON(h.feature.geometry));
    if (geometries.length > 1) {
      viewModel.current.preview(
        geometries,
        gapState.current.removeGaps === true && selectionType === "unit",
        gapState.current.tolerance,
        AvailableUnits[gapState.current.units].value
      );
    } else {
      viewModel.current.reset();
    }
  }
  const merge = () => {
    closeAlert();

    const type = selectionType;
    const attributes = attributesRef.current && attributesRef.current.getValues();
    const features = activeItems().map(h => lib.esri.Graphic.fromJSON(h.feature));
    viewModel.current.reset();
    viewModel.current.merge(
      type,
      features,
      attributes,
      feature.current.attributes,
      gapState.current.removeGaps === true && type === "unit",
      gapState.current.tolerance,
      AvailableUnits[gapState.current.units].value,
      gapState.current.dissolveWalls).then(() => {
        selection.current.length = 0;
        dispatch(setHitTestResults([]));
        dispatch(setSelectedFeatureIndex(null));
        setAlertOptions({
          open: true,
          isError: false,
          title: i18n.editor.merge.mergeComplete.replace("{features}", typeLabel),
          message: i18n.editor.merge.mergeCompleteDescription.replace("{features}", typeLabel.toLowerCase())
        });
      }).catch((error) => {
        const details = error.details;
        const message = details && details.messages && details.messages.length
          ? details.messages.join("\n")
          : error.message;
        setAlertOptions({
          open: true,
          isError: true,
          title: i18n.messages.errorUpdatingData,
          message
        })
      });
  }
  const activeItems = () => selection.current.filter(h => h.key === selectionType);

  const isSingleFloor = () => new Set(activeItems().map(i => {
    const attributes = i.feature.attributes;
    return getAttributeValue(attributes, FieldNames.LEVEL_ID);
  })).size <= 1;

  const validate = () =>
    activeItems().length > 1 &&
    attributesRef.current?.isValid() &&
    isSingleFloor();

  const closeAlert = () => {
    alertOptions.open && setAlertOptions({ open: false });
  }
  const removeItem = (item: IListItem) => {
    const newResults = [...results];
    newResults.splice(item.index, 1);
    selection.current = newResults;
    dispatch(setHitTestResults(newResults));
    dispatch(setSelectedFeatureIndex(selectedFeatureIndex >= newResults.length
      ? newResults.length - 1
      : selectedFeatureIndex
    ))
  }
  const renderItems = () => {
    if (items.length === 0) {
      return null
    } else {
      return (
        <>
          <CalciteList>
            {items.filter(i => i.key === selectionType).map(renderItem)}
          </CalciteList>
        </>
      )
    }
  }
  const renderItem = (item: IListItem) => {
    return (
      <CalciteListItem
        key={item.oid}
        label={item.title}
        description={item.description}
        onMouseDown={e => console.log(e.currentTarget)}>
        <CalciteAction
          slot="actions-start"
          scale="s"
          icon={item.selected ? "circle-f" : "circle"}
          text={i18n.general.select}
          onClick={() => dispatch(setSelectedFeatureIndex(item.index))}
        />
        <CalciteAction
          slot="actions-end"
          scale="s"
          icon="x"
          text={i18n.general.del}
          onClick={() => removeItem(item)}
        />
      </CalciteListItem>
    )
  }
  const renderAttributes = () => {
    const type = hitTest.current?.key;
    const layer: __esri.FeatureLayer = type === "unit"
      ? getUnitsLayer()
      : type === "detail"
        ? getDetailsLayer()
        : null;
    if (feature.current && layer && hitTest.current.key === selectionType && activeItems().length > 1) {
      const nonNullableFields = type === "unit"
        ? [FieldNames.UNITS_USE_TYPE, FieldNames.NAME, FieldNames.UNIT_ID]
        : [FieldNames.DETAILS_USE_TYPE, FieldNames.DETAIL_ID];
      const nonVisibleFields = [FieldNames.HEIGHT_RELATIVE, FieldNames.LEVEL_ID];
      const readOnlyFields = type === "unit"
        ? [FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, FieldNames.UNITS_AREA_ID]
        : [];
      const topFields = type === "unit"
        ? [
          FieldNames.UNITS_USE_TYPE,
          FieldNames.NAME,
          FieldNames.NAME_LONG,
          FieldNames.SCHEDULE_EMAIL,
          FieldNames.UNITS_CAPACITY,
          FieldNames.UNITS_AREA_GROSS,
          FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,
          FieldNames.UNIT_ID,
          FieldNames.UNITS_AREA_ID
        ]
        : [
          FieldNames.DETAILS_USE_TYPE,
          FieldNames.DETAIL_ID
        ];
      return (
        <Attributes
          footer={false}
          formOptions={{
            feature: feature.current,
            layer,
            type,
            readOnlyFields,
            nonVisibleFields,
            nonNullableFields,
            topFields,
            bottomFields: [],
            showAsterisk: true,
            useGroup: true
          }}
          header={<h4>{i18n.editor.merge.selectAttributesTitle.replace("{feature}", typeLabelSingular)}</h4>}
          idField={type === "unit" ? FieldNames.UNIT_ID : FieldNames.DETAIL_ID}
          isEditing={true}
          layout="form"
          onValueChange={onValueChange}
          onCreateForm={() => setCanMerge(validate())}
          ref={attributesRef}
        />
      )
    }
  }
  const onValueChange = () => {
    const canMerge = validate();
    saveBtnRef.current.disabled = !canMerge;
  }
  const { inches, millimeters } = AvailableUnits;
  const lineSymbol: __esri.SimpleLineSymbolProperties = { style: "solid", width: 3, color: [0, 255, 255, 1] };
  const fillSymbol: __esri.SimpleFillSymbolProperties = { style: "solid", color: [0, 255, 255, .25], outline: { ...lineSymbol } };
  const typeLabel = selectionType === "detail"
    ? i18n.editor.merge.details
    : i18n.editor.merge.units;
  const typeLabelSingular = selectionType === "detail"
    ? i18n.editor.merge.detailsSingular
    : i18n.editor.merge.unitsSingular;
  const instructions = i18n.editor.merge.selectInstructions
    .replace("{features}", typeLabel.toLowerCase())
    .replace("{feature}", typeLabelSingular.toLowerCase());
  return (
    <div className="i-editor-sidebar-container i-editor-merge-container">
      <div className="i-editor-sidebar-toolbar">
        {viewLoaded &&
          <MapSelection
            activeFillSymbol={{ ...fillSymbol, style: "solid", color: [0, 255, 255, .75], outline: { ...lineSymbol } }}
            fillSymbol={fillSymbol}
            lineSymbol={lineSymbol}
            view={viewModel.current.getView()}
            tools={["point", "rectangle", "polygon"]}
            selectionTypes={[selectionType]}
            onClear={() => selection.current.length = 0}
            onSelection={({ features }) => selection.current = features}
            />
        }
      </div>
      <div className="i-editor-sidebar-content">
        <CalciteNotice icon="lightbulb" scale="s" closable open={!(tip && tip.visible) ? true : undefined}
          onCalciteNoticeClose={() => dispatch(setHelpTip(selectionType === "detail" ? "mergeDetails" : "mergeUnits", {
            visible: true,
            tooltip: instructions
          }))}>
          <div slot="message">{instructions}</div>
        </CalciteNotice>
        <div className="i-editor-merge-list">
          <h4>
            {i18n.editor.merge.selectAttributesTip.replace("{features}", typeLabel)}
          </h4>
          {renderItems()}
          {activeItems().length <= 1 &&
            <p><CalciteIcon className="i--info" icon="information" scale="s" />{
              i18n.editor.merge.noFeaturesSelected.replace("{features}", typeLabel.toLowerCase())
            }</p>
          }
          {!isSingleFloor() &&
            <p>
              <CalciteIcon className="i--warning" icon="exclamation-mark-triangle" scale="s" />
              {i18n.editor.merge.sameFloorRequired.replace("{features}", typeLabel.toLowerCase())}
            </p>
          }
        </div>
        {selectionType === "unit" && canMerge &&
          <GapOptions
            availableUnits={{ inches, millimeters }}
            value={gapState.current}
            onUpdate={state => {
              gapState.current = { ...gapState.current, ...state };
              preview();
            }} />
        }
        
        {renderAttributes()}
        
      </div>
      <div className="i-editor-sidebar-footer">
        <CalciteButton
          appearance="outline"
          key="reset"
          width="half"
          onClick={reset}>
          {i18n.general.reset}
        </CalciteButton>
        <CalciteButton
          key="merge"
          disabled={!canMerge ? true : undefined}
          width="half"
          onClick={merge}
          ref={saveBtnRef}>
          {i18n.editor.merge.action}
        </CalciteButton>
      </div>
      {alertOptions && alertOptions.open &&
        <CalciteAlert
          {...alertOptions}
          className={alertOptions.isError ? "i-error-details" : ""}
          scale="m"
          kind={alertOptions.isError ? "danger" : "success"}
          label="Merge"
          placement="bottom-end"
          icon={alertOptions.isError ? "exclamation-mark-triangle" : "check-circle"}
          autoClose={alertOptions.isError ? false : true}
          autoCloseDuration="medium"
          onCalciteAlertClose={closeAlert}>
          <div slot="title">{alertOptions.title}</div>
          <div slot="message">{alertOptions.message}</div>
        </CalciteAlert>
      }
    </div>
  )
}
interface IGapState {
  dissolveWalls?: boolean,
  removeGaps?: boolean,
  units: Units,
  tolerance?: number
}
export const GapOptions = ({
  availableUnits,
  onUpdate = () => { },
  value
}: {
  availableUnits: Pick<Record<Units, GapUnit>, "inches" | "millimeters">,
  onUpdate?: (state: IGapState) => void,
  value: IGapState
}) => {
  const ctx = Context.getInstance();
  const { i18n } = ctx;
  const [dissolveWalls, setDissolveWalls] = useState<boolean>(value.dissolveWalls);
  const [removeGaps, setRemoveGaps] = useState<boolean>(value.removeGaps);
  const [units, setUnits] = useState<Units>(value.units);
  const [tolerance, setTolerance] = useState<number>(value.tolerance);
  let valErrMsg = i18n.editor.merge.invalidInput;
  valErrMsg = valErrMsg.replace("{value}", availableUnits[units].min);
  return (
    <div className="i-editor-merge-options">
      <CalciteLabel layout="inline">
        <CalciteCheckbox checked={dissolveWalls ? true : undefined}
          onCalciteCheckboxChange={e => {
            const state: IGapState = {
              dissolveWalls: e.target.checked,
              units
            };
            setDissolveWalls(state.dissolveWalls);
            if (state.dissolveWalls) {
              state.removeGaps = true;
              setRemoveGaps(true);
            }
            onUpdate(state);
          }} /> {i18n.editor.merge.dissolveLabel}
      </CalciteLabel>
      <CalciteLabel layout="inline">
        <CalciteCheckbox checked={dissolveWalls || removeGaps ? true : undefined} disabled={dissolveWalls ? true : undefined}
          onCalciteCheckboxChange={e => {
            const state: IGapState = {
              removeGaps: dissolveWalls ? true : e.target.checked,
              units
            };
            setRemoveGaps(state.removeGaps);
            onUpdate(state);
          }} /> {i18n.editor.merge.removeGapsLabel}
      </CalciteLabel>
      {removeGaps &&
      <>
        <CalciteLabel className="i-merge-gap-tolerance" layout="inline-space-between">
          <CalciteInputNumber
            value={tolerance.toString()}
            min={availableUnits[units].min}
            step={availableUnits[units].step}
            status={!!(typeof tolerance === "number" && tolerance >= availableUnits[units].min) ? "valid" : "invalid"}
            onCalciteInputNumberInput={e => {
              let v = e.target.value;
              //@ts-ignore
              v = (v.trim().length > 0) ? Number(v.trim()) : null;
              //@ts-ignore
              if(typeof v === "number") {
                const state: IGapState = {
                  tolerance: v,
                  units
                }
                setTolerance(state.tolerance);
                onUpdate(state);
              }
            }}
          />
          <CalciteSegmentedControl onCalciteSegmentedControlChange={(e) => {
            const v = e.target.selectedItem.value;
            const state: IGapState = {
              units: v as Units,
              tolerance: availableUnits[v as Units].default
            };
            setUnits(v as Units);
            setTolerance(state.tolerance);
            onUpdate(state);
          }}>
            {
              Object.entries(availableUnits).map(kvp =>
                <CalciteSegmentedControlItem key={kvp[0]} value={kvp[0]} {...kvp[0] === units && { checked: true }}>
                  {kvp[1].label}
                </CalciteSegmentedControlItem>
              )
            }
          </CalciteSegmentedControl>
        </CalciteLabel>
        {(typeof tolerance === "number" && (tolerance < availableUnits[units].min)) && 
          <Warning message={valErrMsg} />}
        </>
      }
    </div>
  )
}
export default MergePanel;