import React from "react";
import { connect } from "react-redux";

import AssignmentsSidebar from "../assignments/Sidebar";
import BottomBarPanel from "../../components/Editor/BottomBarPanel";
import Configurator from "../configurator/Configurator";
import ConfiguratorV1 from "../../components/Configurator/Configurator";
import Context from "../../../context/Context";
import EditorSidebar from "../../components/Editor/Sidebar";
import Map from "./Map";
import Rdx from "../../../redux/Rdx";
import Shell from "./Shell";
import Topic from "../../../context/Topic";

import * as component from "../../../components/util/component";
import * as val from "../../../util/val";
import { getMiniApp, SPACEPLANNER_MINI_APP } from "../../redux";

import "@esri/calcite-components/dist/components/calcite-loader";
import "@esri/calcite-components/dist/components/calcite-scrim";
import {
  CalciteLoader,
  CalciteScrim,
} from "@esri/calcite-components-react";

interface Props {
  activeApp: string,
  dispatch: any,
  wasAppStarted: boolean
}

interface State {
  planOpened: boolean
}

class Layout extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({
      planOpened: false
    });
  }

  componentDidMount() {
    component.own(this, [

      Topic.subscribe(Topic.ConfiguratorActivated, params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.ConfiguratorClosed, params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.PlanOpened, params => {
        const miniapp = "editor";
        //let miniapp = Rdx.getValue(null,SPACEPLANNER_MINI_APP) || Context.instance.config.miniapp || "assignments";
        const planner = Context.instance.spaceplanner.planner;
        const hasValidPlan = planner && planner.hasValidPlan(); 
        const plan = Context.instance.spaceplanner.activePlan;
        const editorOk = !!(plan && plan.detailsLayer);
        if (hasValidPlan) {
          if (miniapp === "editor" && !editorOk) {
            //miniapp = "assignments"
          }
          Rdx.setValue(this,SPACEPLANNER_MINI_APP,miniapp);
        }
        this.setState({planOpened: true})
      }),

      Topic.subscribe(Topic.ShowBackstage, params => {
        if (params && params.tabKey === "openPlan") component.refresh(this);
      })

    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const url = Context.instance.lib.esri.urlUtils.urlToObject(window.location.href);
    const planId = url && url.query && (val.trim(url.query.planid) || val.trim(url.query.planId));
    const cfgOn = Context.instance.uiMode.isConfiguratorOn;
    const configurator = cfgOn ? <Configurator />: null;
    //const configurator = cfgOn ? <ConfiguratorV1 />: null;

    const { planOpened } = this.state;
    const planner = Context.instance.spaceplanner.planner;
    const hasIssues = !!(planner && planner.hasIssues());
    const loading = !!(!planOpened && planId && !hasIssues);
    return (
      <>
        <div style={{width:"100%",height:"100%",display:"flex"}}>
          {loading && 
            <CalciteLoader className="i-editor-layout-loader" text={i18n.editor.walls.loading} scale="l" label="loading" />
          }
          {configurator}
          <div style={{width:"100%", height:"100%", display:"flex", flexDirection: "column"}}>
            <div className="i-editor-layout-middle">
              {this.renderSidebar(planId)}
              <div style={{position:"relative",width:"100%",height:"100%"}}>
                {loading &&
                  <CalciteScrim className="i-loading"></CalciteScrim>
                }
                <Map />
              </div>
            </div>
            <BottomBarPanel tab="validate"/>
          </div>
        </div>
        {!planId && !configurator &&
          <div className="i-modal-working-overlay" style={{backgroundColor: "#ffffff"}}>
            <Shell>
              <CalciteLoader label={""} style={{margin: "auto auto", height: "100%"}} />
            </Shell>
          </div>
        }
      </>
    )
  }

  renderSidebar(planId) {
    const activeApp = "editor";
    //const { planOpened } = this.state;
    //const plan = Context.instance.spaceplanner.activePlan;
    //const planner = Context.instance.spaceplanner.planner;
    //const editorOk = !!(plan && plan.detailsLayer);
    //let activeApp = this.props.activeApp || "editor";
    //if (!editorOk) activeApp = "assignments";
    //const hasIssues = !!(planner && planner.hasIssues());
    //const loading = !!(!planOpened && planId && !hasIssues);
    if (activeApp === "editor") {
      return <EditorSidebar/>
    } else if (activeApp === "assignments") {
      return <AssignmentsSidebar/>
    }
  }

}

const mapStateToProps = (state) => ({
  activeApp: getMiniApp(state)
});

export default connect(mapStateToProps)(Layout);
