import React, { createRef } from "react";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import Topic from "../../../../context/Topic";
import type WallsVM from "./WallsVM";
import { getAllTypes } from "../../../miniapps/configurator/wallTypesUtil";

interface IUseTypeProps {
  layer: __esri.FeatureLayer,
  drawNewWall: (template) => void,
  hidden?: boolean,
  isShowTip: boolean,
  viewModel: WallsVM
}
export default class UseType extends React.Component<IUseTypeProps> {

  _coreNodeRef = createRef<HTMLDivElement>();
  _divFeatureTemplates = createRef<HTMLDivElement>();
  _coreWidget: __esri.FeatureTemplates;

  allTypes: { value: string | number, name: string }[];

  constructor(props: IUseTypeProps) {
    super(props);
    this.state = component.newState({});
    this._initWidget();
  }

  componentDidMount() {
    this._initWidget();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._coreWidget = null; 
  }

  myFilterFunction = (filter) => {
    const allTypes = this.allTypes;
    const wallTypeValues = Context.instance.config.spaceplanner.wallTypeValues;
    if(wallTypeValues && wallTypeValues.length > 0) {
      if (allTypes) {
        for(let i=0;i<allTypes.length;i++) {
          if (allTypes[i].name === filter.label) {
            if (wallTypeValues.includes(allTypes[i].value)) return true;
          };
        }
      }
    } else {
      // if user has not defined walls in the configurator; show all the use types
      return true;
    }
  }


  _initWidget() {
    const layer = this.props && this.props.layer;
    const node = this._coreNodeRef && this._coreNodeRef.current;
    if (node && layer) {
      this.allTypes = getAllTypes(layer);
      this._coreWidget = new Context.instance.lib.esri.FeatureTemplates({
        container: node,
        selectionMode: "single",
        layers: [layer],
        visibleElements: { filter: false },
        filterFunction: this.myFilterFunction
      });

      component.own(this, this._coreWidget.on("select", evtTemplate => {
        this.props.drawNewWall(evtTemplate);
      }));
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const cssNm = this.props.isShowTip
      ? "i-editor-feature-templates"
      : "i-editor-feature-templates i-close";
    
    return (
      <div className="i-editor-use-types" style={{display: this.props.hidden ? "none" : "block"}}>
        <div>{i18n.editor.walls.useType}</div>
        <div ref={this._divFeatureTemplates} 
          className={cssNm}>  
          {this.props.layer && <div ref={this._coreNodeRef}></div>}
          {!this.props.layer && <div><h4>{i18n.editor.walls.loading}</h4></div>}
        </div>
      </div>
    );
  }

}
