import React from "react";
import Context from "../../../context/Context";

//import CalIcon from "calcite-ui-icons-react/CalendarIcon";
import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleIcon"
import ClockIcon from "calcite-ui-icons-react/ClockIcon";
import PinIcon from "calcite-ui-icons-react/PinIcon";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import CalendarSelector from "../../../util/CalendarSelector";
import { BookingType, ICalendarEventInfo } from "../../../util/calendarUtil";
import { IFeature } from "@esri/arcgis-rest-types";

interface IMeetingBookingConfirmedProps {
  calendarParams: Promise<ICalendarEventInfo>
  fl: string,
  name: string,
  orgBy: string,
  origResAttr?: Record<string, any>,
  range: string,
  unit: string,
  updateBooking?: boolean,
  isRecurring?: boolean,
  reservation?: __esri.Graphic | IFeature,
  bookingType?: BookingType
}
interface IMeetingBookingConfirmedState {
  popoverOpen: boolean
}
export default class MeetingBookingConfirmed extends React.Component<IMeetingBookingConfirmedProps, IMeetingBookingConfirmedState> {
    constructor(props: IMeetingBookingConfirmedProps) {
      super(props);
      this.state = {
        popoverOpen: false
      };
    }
  
    _togglePopover = () => {
      this.setState({ popoverOpen: !this.state.popoverOpen });
    }
  
    render() {
      const i18n = Context.getInstance().i18n;
      const { name, range, unit, fl, orgBy, calendarParams } = this.props;
      const { popoverOpen } = this.state;
  
      let btnStr = i18n.events.details.addToCalendar;
      if (this.props && this.props.updateBooking) btnStr = i18n.events.details.updateInCalendar;

      const addToCalendarButton = (
        <button
          className={"i-button"}
          onClick={this._togglePopover}
        >
          {btnStr}
        </button>
      );

      return (
        <div className="i-meeting-confirmation">
          <div className="i-booking-confirmed-body">
            <CheckCircleIcon color="green"/>
            <h1>{i18n.meetingRooms.book.meetingCreated}</h1>
          </div>
          <div className="i-meeting-details">
            <div className="i-text-center"><strong>{name}</strong></div>
            <div className="i--row"><span><ClockIcon /></span><span>{range}</span></div>
            <div className="i--row"><span><PinIcon /></span><span>{unit}<br />{fl}</span></div>
            <div className="i--row"><span><UserIcon /></span><span>{orgBy}</span></div>
            <div className="i-text-center i-separated">
              <CalendarSelector 
                bookingType={this.props.bookingType}
                onRequestClose={this._togglePopover}
                open={popoverOpen}
                targetEl={addToCalendarButton}
                params={calendarParams}
                updateBooking={this.props && this.props.updateBooking}
                origResAttr={this.props && this.props.origResAttr}
                reservation={this.props && this.props.reservation}
              />
            </div>
          </div>
        </div>
      );
    }
  }