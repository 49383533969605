import React, { Component } from "react";

import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan, { SpaceAssignmentTypes } from "../../base/OfficePlan";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import Skeleton from "react-loading-skeleton";
import AreaListItem from "./AreaListItem";
import Search from "../common/Search";

// Styled Components
import { ContentWrapper } from "../../styles/Work/workBar";
import { EmptyListMessage } from "../../styles/Common/emptyListMessage";
import { Panel } from "../../styles/Common/panel";

interface IAreasListProps {
  areaType: SpaceAssignmentTypes,
  disabledAreaIds?: string[],
  handleSelection: (selection: __esri.Graphic) => void
}
interface IAreasListState {
  areas: __esri.Graphic[],
  inputValue: string,
  noMatch: boolean,
  selectedItem: __esri.Graphic
}
export default class AreasList extends Component<IAreasListProps, IAreasListState> {

  constructor(props: IAreasListProps) {
    super(props);
    this.state = component.newState({
      areas: null,
      inputValue: "",
      selectedItem: null,
      noMatch: false
    });
  }

  componentDidMount() {
    this.queryAreas();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getAreaId = (areaItem: __esri.Graphic) => {
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  };

  getAreaType = () => {
    return this.props.areaType;
  };

  getSelectedID(): string | null {
    const { selectedItem } = this.state;
    if (selectedItem) {
      return this.getAreaId(selectedItem);
    }
    return null;
  }

  handleSelection = (area: __esri.Graphic) => {
    if (this.props.handleSelection) {
      this.props.handleSelection(area);
    }
    this.setState({
      selectedItem: area
    });
  };

  queryAreas(q?: string) {
    let hasQ = typeof q === "string" && q.length > 0;
    let allAreas: __esri.Graphic[], areas: __esri.Graphic[] = [], noMatch = false;
    let areaType = this.props.areaType;
    let areasTable = OfficePlan.getActive().areasTable;
    const fields = areasTable && areasTable.table && areasTable.table.fields;
    allAreas = (areasTable && areasTable.workspaceAreas) || [];
    if (hasQ && allAreas.length > 0) {
      const lc = q.toLowerCase();
      const nameField = aiimUtil.findField(fields, FieldNames.AREA_NAME);
      allAreas.forEach(area => {
        let v = aiimUtil.getAttributeValue(area.attributes, nameField.name) || "";
        if (lc && (typeof v === "string") && v.toLowerCase().includes(lc)) {
          areas.push(area);
        }
      });
      noMatch = (areas.length === 0);
    } else {
      areas = allAreas;
    }
    this.setState({
      areas: areas,
      noMatch: noMatch
    });
  }

  render() {
    const i18n = Context.instance.i18n;
    const placeholder = i18n.spaceplanner.workspaceAreas.listHeaderPlaceholder;

    return (
      <Panel>
        <Search fullWidth minimal placeholder={placeholder}
          searchTextChanged={this.searchTextChanged} />
        <ContentWrapper>{this.renderContent()}</ContentWrapper>
      </Panel>
    );
  }

  renderArea = (area: __esri.Graphic) => {
    const id = this.getAreaId(area);
    const selectID = this.getSelectedID();
    const selected = selectID && selectID === id ? true : false;
    const disabledAreaIds = this.props.disabledAreaIds || [];
    const disabled = !!(disabledAreaIds && disabledAreaIds.indexOf(id) !== -1);
    return (
      <AreaListItem
        key={id}
        areaItem={area}
        disabled={disabled}
        selectHandle={this.handleSelection}
        selected={selected}
      />
    );
  };

  renderAreas = () => {
    const i18n = Context.instance.i18n;
    const { areas, noMatch } = this.state;

    if (areas === null) {
      return <Skeleton count={10} height={50} />;
    } else if (areas.length > 0) {
      return areas.map(area => {
        return this.renderArea(area);
      });
    } else {
      let msg: string;
      if (noMatch) {
        msg = i18n.messages.noResults;
      } else {
        msg = i18n.spaceplanner.workspaceAreas.noneInList;
      }
      return <EmptyListMessage>{msg}</EmptyListMessage>;
    }
  };

  renderContent = () => {
    return this.renderAreas();
  };

  searchTextChanged = (text: string) => {
    text = text || "";
    this.setState({
      inputValue: text,
      selectedItem: null
    });
    if (this.props.handleSelection) {
      this.props.handleSelection(null);
    }
    this.queryAreas(text);
  }

}
