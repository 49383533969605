import styled, { css } from "styled-components";

import CalcitePanel from "calcite-react/Panel";

const Panel = styled(CalcitePanel)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background};
  border: 1px solid #eaeaea;

  ${props =>
    props.bordered &&
    css`
      border-bottom: ${props.theme.border};
    `};
`;

const PopupPanel = styled(CalcitePanel)`
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

export { Panel, PopupPanel };
