// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

// Component specific modules (Component-styled, etc.)
import { Container } from "../../styles/Common/listCount";
import Context from "../../../context/Context";

// App components

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

export default class ListCount extends Component {
  constructor(props) {
    super(props);
    this.state = component.newState({
      count: 0,
      totalCount: 0,
      isRefreshing: true,
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.ListCountInfo, (params) => {
        const key = this.props.queryCriteriaKey;
        if (key && key === params.queryCriteriaKey) {
          if (typeof params.count === "number") {
            this.setState({
              count: params.count,
              totalCount: params.totalCount,
              isRefreshing: params.isRefreshing,
            });
          } else {
            this.setState({
              isRefreshing: params.isRefreshing,
            });
          }
        }
      }),
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const label= this.props.label;
    const { count, totalCount, isRefreshing } = this.state;
    let text = label.replace("{count}", count).replace("{total}", totalCount)
    // if (useAssignments) {
    //   const i18n = Context.instance.i18n;
    //   text = count + " / " + totalCount + " " + i18n.spaceplanner.popup.assignments
    // }
    return (
      <Container isRefreshing={isRefreshing}>
        {/* {label.replace("{count}", count).replace("{total}", totalCount)} */}
        {text}
      </Container>
    );
  }
}
