import * as aiimUtil from "../../../src/aiim/util/aiimUtil";
import * as sourceUtil from "../base/sourceUtil";
import * as officeplanUtil from "../base/officePlanUtil";
import Context from "../../../src/context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";

let highlightHandle = null;
let highlightTag = null;

export function getUnitFeature(unitId) {
  return new Promise ((resolve, reject)=> {
    officeplanUtil.queryUnitByUnitId(unitId)
    .then((result)=> {
      resolve(result && result.features && result.features[0]);
    })
    .catch((error)=> {
      console.error(error);
      reject(error);
    })
  })
}

export function addGraphics(tag, feature, view) {
  const source = sourceUtil.getUnitsSource();
  if(!source) return;

  view.whenLayerView(source.layer2D)
  .then(layerView => {
    removeHighlight();
    if(feature && feature.attributes) {
      const assignmentType = aiimUtil.getAttributeValue(feature.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
      if(!assignmentType) return;
    }
    if (layerView && feature) {
      const objectId = aiimUtil.getAttributeValue(feature.attributes, source.layer2D.objectIdField);
      highlightHandle = layerView.highlight(objectId);
      highlightTag = tag;
    }
  })
  .catch((error)=> {
    console.error(error);
  })
}

export async function highlightUnit(tag, feature, view, isPerson) {
  if(Context.instance.session.isMapSelectionActive) return;
  if(isPerson) {
    const unitId = aiimUtil.getAttributeValue(feature.attributes,FieldNames.UNIT_ID);
    if(!unitId) {
      removeHighlight();
      return;
    };
    getUnitFeature(unitId)
    .then((feature)=> {
      addGraphics(tag, feature, view);
    })
    .catch((error)=> {
      console.error(error);
    })
  } else addGraphics(tag, feature, view);
}

export function removeHighlight(tag) {
  try {
    if (tag && (highlightTag!== tag)) return;
    const h = highlightHandle;
    if (h) {
      h.remove();
      highlightHandle = null;
      highlightTag = null;
    }
  } catch(error) {
    console.error(error);
  }
}
