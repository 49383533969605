import BaseClass from "../../util/BaseClass";
import Context from "../../context/Context";
import * as val from "../../util/val";
import * as versionUtil from "./versionUtil";
import { IFeature, ILayerDefinition } from "@esri/arcgis-rest-types";
import { IMergePlanTask, IPartialPostRow } from "./mergeUtil";
import type OfficePlan from "./OfficePlan";

export default class VersionManager extends BaseClass {

  featureServicePerPlan = true;
  serviceInfo: ILayerDefinition & {
    defaultVersionName: string,
    defaultVersionGuid: string
  };
  url: string;
  versionInfos: IVersionInfo[];

  //deleteVersion: (versionInfo) : admin/owner(permission): disable delete button if not
  //canDelete(versionInfo): boolean
  //getVersionedBranch()


  createVersion(task,newVersionInfo) {
    /*
      versionName
      description
      accessPermission  private | public | protected | hidden
     */
    newVersionInfo.f = "json";
    //newVersionInfo.accessPermission = "public";
    //newVersionInfo.ownerName = "urban@indoors";
    //newVersionInfo.access = "public";
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/create";
    const options = {query: newVersionInfo, method: "post", responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      task.createVersionResult = result && result.data;
    });
    return promise;
  }

  deleteVersion(versionInfo: IVersionInfo){
    const promise = new Promise<void>((resolve,reject) => {
      const lib = Context.instance.lib;
      let url = this.url;
      url += "/delete";
      const params = {
        f: "json",
        versionName: versionInfo.versionName
      }
      const options = {query: params, method: "post", responseType: "json"};
      lib.esri.esriRequest(url,options).then(result => {
      //   return planGroupUtil.findVersionedPlanGroupId(versionInfo)
      // }).then(portalGroupId => {
      //   return planGroupUtil.deletePlanGroup(portalGroupId)
      // }).then(result => {
        if (this.featureServicePerPlan) {
          const lenient = true;
          return versionUtil.deleteVersionSupportService(versionInfo,lenient);
        }
      }).then(result => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  differences(task: IDifferencesTask) {
    const lib = Context.instance.lib;
    const guid = task.guid;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(guid) + "/differences";
    //task.resultType = "features";
    const params: IDifferencesTask = {
      f: "json",
      sessionID: task.sessionID,
      resultType: task.resultType || "objectIds"
    };
    if (task.fromMoment) params.fromMoment = task.fromMoment;

    const options: __esri.RequestOptions = { query: params, method: "post", responseType: "json" };
    const request: typeof __esri.request = lib.esri.esriRequest;
    const promise = request(url,options);
    promise.then(result => {
      //console.log("VersionManager.differences",result)
      if (task.resultType === "features") {
        task.differences = result && result.data && result.data.features;
      } else {
        task.differences = result && result.data && result.data.differences;
      }
      if (Array.isArray(task.differences)) {
        task.differences.some(d => {
          let hasDiff = false;
          if (d.updates && d.updates.length > 0) {
            hasDiff = true;
          } else if (d.inserts && d.inserts.length > 0) {
            hasDiff = true;
          } else if (d.deletes && d.deletes.length > 0) {
            hasDiff = true;
          }
          if (hasDiff) task.hasDifferences = true;
          return hasDiff;
        })
      }
    }).catch(err => console.error(err));
    return promise;
  }

  getStatus(versionInfo: IVersionInfo) {
    let status = null; // _new _modified _merged
    const ancestorDate = versionInfo.commonAncestorDate;
    //const creationDate = versionInfo.creationDate;
    const modifiedDate = versionInfo.modifiedDate;
    const reconcileDate = versionInfo.reconcileDate;
    if (modifiedDate > ancestorDate) {
      status = "_modified";
    } else if (typeof reconcileDate !== "number") {
      status = "_new";
    } else {
      status = "_merged";
    }
    return status;
  }

  hasDifferences(options: {
    plan?: { versionInfo: IVersionInfo },   
    versionGuid?: string
  } & Partial<IDifferencesTask>) {
    const promise = new Promise<IDifferencesTask>((resolve,reject) => {
      let guid = options.versionGuid || options.plan.versionInfo.versionGuid;
      guid = this.normalizeGuid(guid);
      const task: IDifferencesTask = {
        guid: guid,
        sessionID: val.generateRandomUuid({includeBraces: true}),
        triedStop: false,
        hasDifferences: false,
        resultType: options.resultType || "objectIds"
      };
      if (options.fromMoment) task.fromMoment = options.fromMoment;
      this.startReading(task).then(() => {
        return this.differences(task);
      }).then(() => {
        task.triedStop = true;
        return this.stopReading(task);
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        if (task.hasReadSession && !task.triedStop) {
          this.stopReading(task).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping read session",ex2);
          });
        }
        reject(ex);
      });
    });
    return promise
  }

  hasStandaloneAccess(versionInfo) {
    const v = "floorplaneditor_"+Context.instance.configuration.appItem.id;
    if (versionInfo.description === v) {
      if (this.isOwner(versionInfo) || (versionInfo.access === "public")) {
        return true;
      }
    }
    return false;
  }

  findVersionInfo(planId) {
    let found = null;
    if (Array.isArray(this.versionInfos)) {
      this.versionInfos.some(versionInfo => {
        if (versionInfo.versionGuid === planId) {
          found = versionInfo;
        }
        return !!found;
      });
    }
    return found;
  }

  getVersionDate(versionInfo) {
    return versionInfo && new Date(versionInfo.creationDate);
  }

  getVersionTitle(versionInfo,forDisplay) {
    let v = versionInfo && versionInfo.versionName;
    if (typeof v !== "string") v = "Untitled";
    const idx = v.lastIndexOf(".");
    if (idx !== -1) v = v.substring(idx + 1);

    // Replace underscores with spaces in plan names (Floor Plan Editor) #6790
    if (forDisplay) v = v.replaceAll("_"," ");

    return v;
  }

  getVersionOwner(versionInfo) {
    let v = versionInfo && versionInfo.versionName;
    if (typeof v !== "string") v = "";
    const idx = v.lastIndexOf(".");
    if (idx !== -1) v = v.substring(0,idx);
    return v;
  }

  isDefault(versionInfo) {
    if (this.serviceInfo && this.serviceInfo.defaultVersionGuid && versionInfo) {
      return (this.serviceInfo.defaultVersionGuid === versionInfo.versionGuid);
    } else {
      return (versionInfo && versionInfo.versionId === 0);
    }
  }

  isOwner(versionInfo) {
    const owner = this.getVersionOwner(versionInfo);
    const username = Context.instance.user.getUsername();
    return (username && owner && (owner.toLowerCase() === username.toLowerCase()));
  }

  load(url: string) {
    this.url = url;
    const task: IVersionManagementTask = {
      versionManagerUrl: url
    };
    const promise = new Promise<void>((resolve,reject) => {
      this.readServiceInfo(task).then(() => {
        this.serviceInfo = task.serviceInfo;
        return this.readVersionInfos(task);
      }).then(() => {
        this.versionInfos = task.versionInfos;
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  mergePlan(options: { plan: OfficePlan, partialPostRows: IPartialPostRow[] }) {
    const promise = new Promise<IReconcileAndPostTask>((resolve,reject) => {
      const versionInfo = options.plan.versionInfo;
      let guid = versionInfo.versionGuid;
      guid = guid.replace("{","").replace("}","");
      const task: IReconcileAndPostTask = {
        guid: guid,
        sessionID: val.generateRandomUuid({includeBraces: true}),
        triedStopEditing: false,
        triedStopReading: false,
        wasMerged: false
      };
      let withPost = true, secondaryReconcileRequired = false;
      if (options.partialPostRows) withPost = false;
      this.startReading(task).then(() => {
        return this.startEditing(task);
      }).then(() => {
        return this.reconcile(task,withPost);
      }).then(() => {
        if (task.reconcile && !task.reconcile.didPost && task.reconcile.hasConflicts) {
          return this.reconcile(task,withPost);
        }
      }).then(() => {
        if (task.reconcile && options.partialPostRows) {
          if (options.partialPostRows.length > 0) {
            return this.post(task, options.partialPostRows).then(result => {
              task.wasMerged = !!(result && result.data && result.data.success);
              secondaryReconcileRequired = true;
            })
          } else {
            // nothing to post (placeholder occupants are not posted)
            task.wasMerged = true;
            console.log("No rows to partial post")
          }
        } else if (task.reconcile) {
          task.wasMerged = !!task.reconcile.didPost;
        }
      }).then(() => {
        if (secondaryReconcileRequired) {
          return this.reconcile(task,false);
        }
      }).then(() => {
        task.triedStopEditing = true;
        return this.stopEditing(task,false);
      }).then(() => {
        task.triedStopReading = true;
        return this.stopReading(task);
      }).then(() => {
        //console.log("VersionManager.mergePlan",task)
        resolve(task);
      }).catch(ex => {
        if (task.hasEditSession && !task.triedStopEditing) {
          this.stopEditing(task,false).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping edit session",ex2);
          });
        }
        if (task.hasReadSession && !task.triedStopReading) {
          this.stopReading(task).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping read session",ex2);
          });
        }
        reject(ex);
      });
    });
    return promise
  }

  normalizeGuid(versionGuid) {
    return versionGuid.replace("{","").replace("}","");
  }

  post(task: IReconcileAndPostTask, partialPostRows: IPartialPostRow[]) {
    const lib = Context.instance.lib;
    const guid = task.guid;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(guid) + "/post";
    const params: IPostTask = {
      f: "json",
      sessionId: task.sessionID
    };
    if (partialPostRows) params.rows = JSON.stringify(partialPostRows);
    const options: __esri.RequestOptions = { query: params, method: "post", responseType: "json" };
    const request: typeof __esri.request = lib.esri.esriRequest;
    const promise = request(url,options);
    promise.then(result => {
      task.postResult = result && result.data;
      //console.log("task.postResult",task.postResult)
    });
    return promise;
  }

  readServiceInfo(task: IVersionManagementTask) {
    const lib = Context.instance.lib;
    const url = (task && task.versionManagerUrl) || this.url;
    const options: __esri.RequestOptions = { query: { f: "json" }, responseType: "json" };
    const request: typeof __esri.request = lib.esri.esriRequest;
    const promise = request(url,options);
    promise.then(result => {
      task.serviceInfo = result && result.data;
    });
    return promise;
  }

  async readVersion(versionGuid) {
    const lib = Context.instance.lib;
    let url = this.url+"/versions/"+this.normalizeGuid(versionGuid);
    const options = {query: {f: "json"}, responseType: "json"};
    const result = await lib.esri.esriRequest(url,options);
    return result && result.data;
  }

  readVersionInfos(task: IVersionManagementTask) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versionInfos";
    const options = {query: {f: "json"}, responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      const versionInfos = result && result.data && result.data.versions;
      if (versionInfos) {
        versionInfos.forEach(vi => {
          vi.xtnTitle = this.getVersionTitle(vi,true);
        });
        versionInfos.sort((a,b) => {
          if (a.versionId === 0) return 0;
          else if (b.versionId === 0) return 1;
          return a.xtnTitle.localeCompare(b.xtnTitle);
        });
      }
      task.versionInfos = versionInfos;
    });
    return promise;
  }

  readVersions(task) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions";
    const options = {query: {f: "json"}, responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      task.versions = result && result.data;
    });
    return promise;
  }

  reconcile(task: IReconcileAndPostTask, withPost?: boolean) {
    const lib = Context.instance.lib;
    const guid = task.guid;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(guid) + "/reconcile";
    const params = {
      f: "json",
      sessionID: task.sessionID,
      abortIfConflicts: false,
      withPost: !!withPost
    };
    const options: __esri.RequestOptions = { query: params, method: "post", responseType: "json" };
    const request: typeof __esri.request = lib.esri.esriRequest;
    const promise = request(url, options);
    promise.then(result => {
      task.reconcile = result && result.data;
      //console.log("task.reconcile",task.reconcile)
    });
    return promise;
  }

  reconcilePlan(options: { plan: OfficePlan }) {
    const promise = new Promise<IReconcileAndPostTask>((resolve,reject) => {
      const versionInfo = options.plan.versionInfo;
      let guid = versionInfo.versionGuid;
      guid = guid.replace("{","").replace("}","");
      const task: IReconcileAndPostTask = {
        guid: guid,
        sessionID: val.generateRandomUuid({includeBraces: true}),
        triedStopEditing: false,
        triedStopReading: false,
        wasMerged: false
      };
      this.startReading(task).then(() => {
        return this.startEditing(task);
      }).then(() => {
        return this.reconcile(task);
      }).then(() => {
        task.triedStopEditing = true;
        return this.stopEditing(task,false);
      }).then(() => {
        task.triedStopReading = true;
        return this.stopReading(task);
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        if (task.hasEditSession && !task.triedStopEditing) {
          this.stopEditing(task,false).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping edit session",ex2);
          });
        }
        if (task.hasReadSession && !task.triedStopReading) {
          this.stopReading(task).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping read session",ex2);
          });
        }
        reject(ex);
      });
    });
    return promise
  }

  restoreRows(plan: OfficePlan, rows: IPartialPostRow[]) {
    const promise = new Promise((resolve,reject) => {
      const lib = Context.instance.lib;
      const versionInfo = plan.versionInfo;
      const guid = this.normalizeGuid(versionInfo.versionGuid);
      const task: IVersionManagementTask = {
        guid: guid,
        sessionID: val.generateRandomUuid({includeBraces: true}),
        triedStopEditing: false,
        triedStopReading: false
      };
      const url = this.url + "/versions/" + encodeURIComponent(guid) + "/restoreRows";
      const params = {
        f: "json",
        sessionID: task.sessionID,
        rows: JSON.stringify(rows)
      };
      this.startReading(task).then(() => {
        return this.startEditing(task);
      }).then(() => {
        const options = {query: params, method: "post", responseType: "json"};
        return lib.esri.esriRequest(url,options);
      }).then(() => {
        task.triedStopEditing = true;
        return this.stopEditing(task,false);
      }).then(() => {
        task.triedStopReading = true;
        return this.stopReading(task);
      }).then(() => {
        resolve(task);
      }).catch(ex => {
        if (task.hasEditSession && !task.triedStopEditing) {
          this.stopEditing(task,false).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping edit session",ex2);
          });
        }
        if (task.hasReadSession && !task.triedStopReading) {
          this.stopReading(task).then(() => {
          }).catch(ex2 => {
            console.error("Error stopping read session",ex2);
          });
        }
        reject(ex);
      });
    });
    return promise
  }

  startEditing(task: IVersionManagementTask) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(task.guid) + "/startEditing";
    const params = {
      f: "json",
      sessionID: task.sessionID
    };
    const options: __esri.RequestOptions = { query: params, method: "post", responseType: "json" };
    const request: typeof __esri.request = lib.esri.esriRequest;
    const promise = request(url,options);
    promise.then(result => {
      const ok = result && result.data && result.data.success;
      task.hasEditSession = !!ok;
      //console.log("task.startEditing",result)
    });
    return promise;
  }

  startReading(task: IVersionManagementTask) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(task.guid) + "/startReading";
    const params = {
      f: "json",
      sessionID: task.sessionID
    };
    const options = {query: params, method: "post", responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      const ok = result && result.data && result.data.success;
      task.hasReadSession = !!ok;
      //console.log("task.startReading",result)
    });
    return promise;
  }

  stopEditing(task: IVersionManagementTask, saveEdits: boolean) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(task.guid) + "/stopEditing";
    const params = {
      f: "json",
      sessionID: task.sessionID,
      saveEdits: !!saveEdits
    };
    const options = {query: params, method: "post", responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      const ok = result && result.data && result.data.success;
      if (ok) task.hasEditSession = false;
      //console.log("task.stopEditing",result)
    });
    return promise;
  }

  stopReading(task: IVersionManagementTask) {
    const lib = Context.instance.lib;
    let url = (task && task.versionManagerUrl) || this.url;
    url += "/versions/" + encodeURIComponent(task.guid) + "/stopReading";
    const params = {
      f: "json",
      sessionID: task.sessionID
    };
    const options = {query: params, method: "post", responseType: "json"};
    const promise = lib.esri.esriRequest(url,options);
    promise.then(result => {
      const ok = result && result.data && result.data.success;
      if (ok) task.hasReadSession = false;
      //console.log("task.stopReading",result)
    });
    return promise;
  }

}
export interface IVersionInfo {
  versionName: string,
  versionGuid: string,
  description: string,
  creationDate: Date,
  modifiedDate: Date,
  reconcileDate: Date,
  evaluationDate: Date,
  commonAncestorDate: Date,
  access: "private" | "public" | "protected"
}
export interface IVersionManagementTask {
  f?: "json",
  guid?: string,
  hasEditSession?: boolean,
  hasReadSession?: boolean,
  sessionID?: string,
  serviceInfo?: VersionManager["serviceInfo"],
  triedStopEditing?: boolean,
  triedStopReading?: boolean,
  versionInfos?: IVersionInfo[]
  versionManagerUrl?: string,
  wasMerged?: boolean
}
export interface IReconcileAndPostTask extends IVersionManagementTask {
  postResult?: {
    moment: Date,
    success: boolean
  },
  reconcile?: {
    hasConflicts: boolean,
    moment: Date,
    didPost: boolean,
    success: boolean
  }
}
export interface IDifferencesTask extends IVersionManagementTask {
  differences?: {
    layerId: number,
    inserts: IFeature[] | number[],
    updates: IFeature[] | number[],
    deletes: IFeature[] | number[]
  }[]
  fromMoment?: number,
  hasDifferences?: boolean,
  hasReadSession?: boolean,
  resultType: "objectIds" | "features",
  triedStop?: boolean
}
export interface IPostTask extends IVersionManagementTask {
  // there are inconsistencies in the REST API for the casing 
  // of this parameter depending on the specific operation
  // /post https://developers.arcgis.com/rest/services-reference/enterprise/post.htm#GUID-28BBA6E8-AC0C-46EB-A9BB-B2A934E0C9A4
  // /reconcile https://developers.arcgis.com/rest/services-reference/enterprise/reconcile.htm#GUID-49ED1DD5-E924-467C-B246-70A07BEC9F79
  sessionId: string,
  rows?: string
}