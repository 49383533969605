import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import Topic from "../../../../context/Topic";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as editorUtil from "../support/editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as splitUtil from "../support/splitUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as val from "../../../../util/val";
import * as vectorUtil from "../support/vectorUtil";

import { WarningMsg, CutType } from "../support/editorUtil";
import { ILevelData } from "../../../../aiim/datasets/Levels";

import { ILengthAndWidthInfo } from "../../../miniapps/common/types";
import * as unitUtil from "../support/unitUtil";

import StencilMover from "../support/StencilMover";
import { IStencil } from "../../../miniapps/common/types";
import * as stencilUtil from "../support/stencilUtil";

type CutUnderlyingResult = {
  originalUnits: __esri.Graphic[],
  modifiedUnits: __esri.Graphic[],
  warnings: WarningMsg[]
}; 


type LevelInfo = { levelData: ILevelData, error: string };

export default class WallsVM extends BaseVM {

  activeWall: {
    newWall: __esri.Graphic,
    polyline: __esri.Polyline,
    isStencil: boolean,
    levelInfo: LevelInfo,
    originalUnits: __esri.Graphic[],
    modifiedUnits: __esri.Graphic[],
    warnings: WarningMsg[],
  } = {
    newWall: null,
    polyline: null,
    isStencil: false,
    levelInfo: null,
    originalUnits: null,
    modifiedUnits: null,
    warnings: [],
  };
  cutUnitArea = false;  

  onAttributeUpdate: (fieldName: string, value: any) => void;
  onChange: () => void;
  onDrawComplete: (tool?: string) => void;

  _activeSketchViewModel = null;
  _cutLayerId = "indoors-wall-cut";
  _wallLayerId = "indoors-wall";

  private _processTimestamp: number;

  lengthAndWidthInfo: ILengthAndWidthInfo;

  constructor() {
    super();
    this.lengthAndWidthInfo = unitUtil.newLengthAndWidthInfo("walls");
  }

  activateDrawWall(templatePrototype: __esri.Graphic, drawTool: "point" | "polyline") {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const view = this.getView();
    const detailsLayer = sourceUtil.getDetailsLayer();
    const unitsLayer = sourceUtil.getUnitsLayer();
    mapUtil.ensureGraphicsLayer(view,this._cutLayerId);
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._wallLayerId);
    this.activeWall = {
      polyline: null,
      isStencil: false,
      newWall: null,
      levelInfo: null,
      modifiedUnits: [],
      originalUnits: [],
      warnings: [],
    };

    let svm = this._activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      updateOnGraphicClick: true,
      snappingOptions: this.makeSnappingOptions()
    });

    let stencil: IStencil, stencilMover;
    const placeStencil = (drawTool === "point");
    if (placeStencil) {
      const { lengthInMapUnits, thicknessInMapUnits, anchor1Position, anchor2Position} = this.lengthAndWidthInfo;
      stencil = stencilUtil.newDynamicStencil(view,lengthInMapUnits,thicknessInMapUnits,anchor1Position,anchor2Position);
      stencilMover = new StencilMover();
      stencilMover.setupCreate(this._activeSketchViewModel,stencil,view);
    }

    const svmUpdateOptions = {
      tool: "reshape", // "transform"|"reshape"|"move"
      enableRotation: true,
      enableScaling: true,
      preserveAspectRatio: false,
      toggleToolOnClick: true,
      enableZ: false
    };

    const createOptions = {
      mode: "click",
      hasZ: true,
      defaultZ: 5 // @todo
    }
    svm.defaultUpdateOptions = svmUpdateOptions

    const origFocus = view.focus;
    if (placeStencil) view.focus = () => {};
    svm.create(drawTool,createOptions);
    if (placeStencil) {
      // activating the SketchViewModel calls view.focus(), focus gets lost on the dimension input box
      setTimeout(() => {
        view.focus = origFocus;
      },500)
    }
    
    
    if (templatePrototype) {
      svm.polylineSymbol = splitUtil.getSymbol(detailsLayer, templatePrototype);
    }

    const process = async (event, stencilPolyline?) => {
      const polyline = stencilPolyline || (event && event.graphic && event.graphic.geometry);
      await this.processCreate(polyline, templatePrototype, !!stencilPolyline);
    }

    svm.on("create", async (event) => {

      let stencilPolyline;
      if (stencilMover && (event.state === "complete" || event.state === "cancel")) {
        stencilMover.clear();
        if (event.state === "complete") {
          this.cancelSketch();
          const lastOR = await stencilMover.lastOffsetResult(view,event,stencil)
          const g = lastOR.geometry;
          if (g.type === "polyline") {
            stencilPolyline = g;
          } else if (g.type === "polygon") {
            stencilPolyline = new Context.instance.lib.esri.Polyline({
              paths: g.rings,
              hasZ: g.hasZ,
              spatialReference: g.spatialReference
            });
          }
        }
      }

      if (event.state === "complete") {
        if (stencilPolyline) {
          process(event,stencilPolyline);
        } else {
          const paths = event.graphic && event.graphic.geometry && event.graphic.geometry.paths;
          if (paths && paths[0] && paths[0].length > 1) {
            this.cancelSketch();
            process(event);
          } else {
            this.activateDrawWall(templatePrototype,drawTool);
          }
        }
      }
      if (event.state === "cancel") {
        this.cancelSketch();
      }
    });

    Context.instance.views.toggleClickHandlers("pause");
    if (view) {
      view.container.style.cursor = "crosshair";
    }
  }

  activateUpdate() {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const view = this.getView();
    const detailsLayer = sourceUtil.getDetailsLayer();
    const unitsLayer = sourceUtil.getUnitsLayer();
    const cutGraphicsLayer = mapUtil.ensureGraphicsLayer(view,this._cutLayerId);
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._wallLayerId);
    const graphic = this.getActiveFeature();
    if (!graphic) return;

    const svm = this._activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      updateOnGraphicClick: true,
      snappingOptions: this.makeSnappingOptions()
    });

    const updateOptions = {
      tool: "reshape", // "transform"|"reshape"|"move"
      enableRotation: true,
      enableScaling: true,
      preserveAspectRatio: false,
      toggleToolOnClick: true,
      enableZ: true
    };
    svm.defaultUpdateOptions = updateOptions;

    svm.on("update", async (event) => {
      const tei = event.toolEventInfo;
      const teiType = tei && tei.type;
      if (teiType === "move-stop" || teiType === "rotate-stop" || teiType === "reshape-stop" || teiType === "scale-stop" ||
          teiType === "vertex-add" || teiType === "vertex-remove") {
        const g = event.graphics && event.graphics[0];
        if (g && g.geometry) {
          this.processUpdate(g)
        }
      }
      if (event.state === "active") {
        if (cutGraphicsLayer.graphics.length > 0) cutGraphicsLayer.removeAll();
      } else if (event.state === "complete") {
      } else if (event.state === "cancel") {
      }
    });

    Context.instance.views.toggleClickHandlers("pause");
    svm.update([graphic],updateOptions);
  }

  addOrClearCutGraphic() {
    let graphic;
    const wall = this.getActiveFeature();
    if (wall && this.cutUnitArea) {
      const polygon = this.asPolygon(wall);
      if (polygon) {
        graphic = new Context.instance.lib.esri.Graphic({
          geometry: polygon,
          symbol: this.makeCutUnitSymbol()
        });
      }
    }
    const graphicsLayer = mapUtil.ensureGraphicsLayer(this.getView(),this._cutLayerId);
    graphicsLayer.removeAll();
    if (graphic) graphicsLayer.add(graphic);
  }

  asPolygon(wall: __esri.Graphic): __esri.Polygon {
    let polygon: __esri.Polygon = null;
    const geometry = wall && wall.geometry;
    if (geometry && geometry.type === "polyline") {
      const polyline = (geometry as __esri.Polyline);
      let nClosed = 0, nPaths = polyline.paths.length;
      polyline.paths.forEach(path => {
        let pathClosed = false;
        if (path.length > 3) {
          const first = path[0];
          const last = path[path.length -1]
          if ((first[0] === last[0]) && (first[1] === last[1])) {
            pathClosed = true;
          }
        }
        if (pathClosed) nClosed++;
      })
      if (nClosed === nPaths && nPaths === 1) {
        polygon = new Context.instance.lib.esri.Polygon({
          rings: (geometry as __esri.Polyline).paths,
          hasZ: geometry.hasZ,
          spatialReference: geometry.spatialReference
        });
      }
    }
    return polygon
  }

  cancelSketch() {
    const view = this.getView();
    const svm = this._activeSketchViewModel;
    if (svm) {
      svm.cancel();
      svm.destroy();
      this._activeSketchViewModel = null;
      Context.instance.views.toggleClickHandlers("resume");
    }
    if (view) {
      //  `default`, `crosshair`, `help`, `move`, `pointer`, `progress`, `grab`, `grabbing`
      view.container.style.cursor = "default";
    }
    //mapUtil.removeAllGraphics(view,this._annoLayerId);
  }

  cancelAndclearSketch() {
    const view = this.getView();
    this.cancelSketch();
    mapUtil.removeAllGraphics(view,this._cutLayerId);
    mapUtil.removeAllGraphics(view,this._wallLayerId);
    this.activeWall = null;
    this._processTimestamp = null;
  }

  canSave() {
    const newWall = this.getActiveFeature();
    const isProcessing = !!this._processTimestamp;
    const wallOK = !!(newWall && newWall.geometry && newWall.attributes);
    const levelInfo = this.activeWall && this.activeWall.levelInfo;
    const hasCutWarnings = this.activeWall && this.activeWall.warnings && this.activeWall.warnings.length > 0;
    return !!(!isProcessing && wallOK && levelInfo && !levelInfo.error && !hasCutWarnings);
  }

  clear() {
    const view = this.getView();
    this.cancelSketch();
    mapUtil.removeAllGraphics(view,this._cutLayerId);
    mapUtil.removeAllGraphics(view,this._wallLayerId);
    this.activeWall = null;
    this._processTimestamp = null;
    this.onChange();
  }

  clearCutGraphics() {
    mapUtil.removeAllGraphics(this.getView(),this._cutLayerId);
  }

  async cutUnderlyingUnits(wall:__esri.Graphic): Promise<CutUnderlyingResult> {
    const result: CutUnderlyingResult = {
      originalUnits: [],
      modifiedUnits: [],
      warnings: []
    }; 
    const polygon = this.asPolygon(wall);
    if (polygon) {
      const graphic = wall.clone();
      graphic.geometry = polygon;
      const warnTitle = Context.instance.i18n.editor.walls.warnBadGeomPreferExisting;
      const cutResult = await editorUtil.cutThisOrUnderlyingUnits(graphic, CutType.cutUnderlying, false, warnTitle, true);
      if (cutResult) {
        result.originalUnits = cutResult.originalFeatures;
        result.modifiedUnits = cutResult.cutFeatures;
        result.warnings = cutResult.warnings;
      }
    }
    return result;
  }  

  async flip(type: "v" | "h") {
    const ge = Context.instance.lib.esri.geometryEngine;
    const flipFunc = ge[type==="h" ? "flipHorizontal" : "flipVertical"];
    const newWall = this.getActiveFeature();
    let geometry = newWall && newWall.geometry;
    if (geometry) {
      geometry = flipFunc(geometry);
      this.updateWallGeometry(geometry);
    }
  }

  getActiveFeature() {
    return this.activeWall && this.activeWall.newWall;
  }

  getNewWallGraphic() {
    const newWall = this.getActiveFeature();
    if (!newWall) return null;
    return new Context.instance.lib.esri.Graphic({
      geometry: newWall.geometry, 
      attributes: newWall.attributes
    });
  }

  initNewWall = (feature:__esri.Graphic, levelData:ILevelData) => {
    const layer = sourceUtil.getDetailsLayer();
    if (layer) {
      const attributes = feature.attributes;
      const detailIdField = aiimUtil.findFieldName(layer.fields,FieldNames.DETAIL_ID)
      const globalIdField = layer.fields.find(f => f.type === "global-id");
      const detailId = val.generateRandomId();
      delete attributes[layer.objectIdField];
      delete attributes[globalIdField.name];
      attributes[detailIdField] = detailId;
      aiimUtil.removeShapeAttributes(attributes);
      this.updateZAttributes(feature,levelData);
    }
  }

  makeCutUnitSymbol() {
    return {
      type: "simple-fill", 
      style: "solid",
      color: [0, 0, 0, 0.1]
    }
  }

  onUnMount() {
    this.cancelAndclearSketch();
    this.onDrawComplete = this.onChange = null;  
    this.destroy();
  }

  async processCreate(polyline:__esri.Polyline, templatePrototype:__esri.Graphic, isStencil: boolean) {
    let timestamp = this._processTimestamp = Date.now();
    try {
      const lib = Context.instance.lib;
      const view = this.getView();
      const detailsLayer = sourceUtil.getDetailsLayer();
      const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._wallLayerId);
      const thicknessInMapUnits = this.lengthAndWidthInfo.thicknessInMapUnits;
  
      let wallGeometry = polyline;
      if (!isStencil && (thicknessInMapUnits > 0)) {
        const buffered = vectorUtil.bufferPolyline(polyline,null,(thicknessInMapUnits / 2),0);
        if (buffered) wallGeometry = buffered;
      }
  
      const newWall = new lib.esri.Graphic({
        geometry: wallGeometry,
        attributes: Object.assign({},templatePrototype.attributes),
        symbol: splitUtil.getSymbol(detailsLayer, templatePrototype)
      });
      const levelInfo: LevelInfo = await this.queryLevelInfo(newWall.geometry);
  
      if (timestamp !== this._processTimestamp) return;
  
      if (levelInfo.error) {
        Topic.publish(Topic.ShowToast, {message: levelInfo.error, dismissAll: true});
      }
      this.initNewWall(newWall,levelInfo.levelData);
      graphicsLayer.removeAll();
      graphicsLayer.graphics.add(newWall);
      this.activeWall.polyline = polyline;
      this.activeWall.isStencil = !!isStencil;
      this.activeWall.newWall = newWall;
      this.activeWall.levelInfo = levelInfo;
      this._processTimestamp = null;
      this.onDrawComplete();
      this.activateUpdate();
    } catch(ex) {
      console.error(ex);
      if (timestamp !== this._processTimestamp) return;
      this._processTimestamp = null
      Topic.publishError(ex);
    }
  }

  async processCut() {
    let timestamp = this._processTimestamp = Date.now();
    try {
      Topic.publish(Topic.ClearToast,{});
      let cutUnderlyingResult: CutUnderlyingResult;
      const wall = this.activeWall.newWall;
      this.activeWall.modifiedUnits = [];
      this.activeWall.originalUnits = [];
      this.activeWall.warnings = [];
      if (this.cutUnitArea) {
        cutUnderlyingResult = await this.cutUnderlyingUnits(wall);
      }
      if (timestamp !== this._processTimestamp) return;

      if (cutUnderlyingResult) {
        this.activeWall.modifiedUnits = cutUnderlyingResult.modifiedUnits;
        this.activeWall.originalUnits = cutUnderlyingResult.originalUnits;
        this.activeWall.warnings = cutUnderlyingResult.warnings;
      }
      this._processTimestamp = null
      this.addOrClearCutGraphic();
      this.onChange();
    } catch(ex) {
      console.error(ex);
      if (timestamp !== this._processTimestamp) return;
      this._processTimestamp = null
      Topic.publishError(ex);
    }
  }

  async processUpdate(graphic:__esri.Graphic) {
    let timestamp = this._processTimestamp = Date.now();
    try {
      Topic.publish(Topic.ClearToast,{});
      let cutUnderlyingResult: CutUnderlyingResult;
      const wall = this.activeWall.newWall;
      this.activeWall.levelInfo = null;
      this.activeWall.modifiedUnits = [];
      this.activeWall.originalUnits = [];
      this.activeWall.warnings = [];
      const levelInfo: LevelInfo = await this.queryLevelInfo(graphic.geometry);
      if (this.cutUnitArea) {
        cutUnderlyingResult = await this.cutUnderlyingUnits(wall);
      }
      if (timestamp !== this._processTimestamp) return;
  
      if (levelInfo.error) {
        Topic.publish(Topic.ShowToast, {message: levelInfo.error, dismissAll: true});
      }
      this.updateZAttributes(wall,levelInfo.levelData);
      this.activeWall.levelInfo = levelInfo
      if (cutUnderlyingResult) {
        this.activeWall.modifiedUnits = cutUnderlyingResult.modifiedUnits;
        this.activeWall.originalUnits = cutUnderlyingResult.originalUnits;
        this.activeWall.warnings = cutUnderlyingResult.warnings;
      }
      this._processTimestamp = null;
      this.addOrClearCutGraphic();
      this.onChange();
    } catch(ex) {
      console.error(ex);
      if (timestamp !== this._processTimestamp) return;
      this._processTimestamp = null
      Topic.publishError(ex);
    }
  }

  async queryLevelInfo(geometry:__esri.Geometry) : Promise<LevelInfo> {
    const i18n = Context.instance.i18n;
    const levels = Context.instance.aiim.datasets.levels;
    const list = await levels.queryLevelDataByGeometry(geometry);
    let error:string;
    if (!list || list.length === 0) {
      error =i18n.editor.walls.notOnALevel;
    } else if(list && list.length > 1) {
      //error =  "Invalid Level Data"; @todo is this an error?
    } 
    return { levelData: list && list[0], error };
  }

  save = async () => {
    const newWall = this.getActiveFeature();
    if (!newWall || !newWall.geometry || !newWall.attributes) {
      throw new Error("Unable to get geometry or attributes to save");
    }
    editorUtil.updateZ(newWall.geometry,this.activeWall.levelInfo.levelData.z);
    const info = { 
      newWalls: [ newWall ],
      originalUnits: this.activeWall.originalUnits, 
      modifiedUnits: this.activeWall.modifiedUnits,
    };
    
    const lyr = sourceUtil.getUnitsLayer();
    const fldArea = aiimUtil.findFieldName(lyr.fields, FieldNames.UNITS_AREA_GROSS);   
    if(info.modifiedUnits) {
      await Promise.all(info.modifiedUnits.map(async unit => await editorUtil.updateGrossArea(unit, fldArea)));
    }
    await transactions.fpeEditWall(info);
    this.cancelAndclearSketch();
  }

  setCutUnitArea = async (v)=> {
    this.cutUnitArea = !!v;
    const wall = this.getActiveFeature();
    if (wall && this.cutUnitArea) {
      this.processCut();
    } else {
      this.clearCutGraphics();
    }
  }

  async updateWallGeometry(geometry: __esri.Geometry) {
    const wall = this.getActiveFeature();
    if (geometry && geometry.type==="polyline" && wall) {
      wall.geometry = geometry;
      this.activateUpdate();
      await this.processUpdate(wall);
    }
  }

  updateZAttributes(feature: __esri.Graphic, levelData: ILevelData) {
    const layer = sourceUtil.getDetailsLayer();
    const geometry = feature && feature.geometry;
    const attributes = feature && feature.attributes;
    const levelId = levelData && levelData.levelId;
    const heightRelative = levelData && levelData.heightRelative;
    const z = levelData && levelData.z;
    if (layer && geometry && attributes && levelId) {
      const levelIdField = aiimUtil.findFieldName(layer.fields,FieldNames.LEVEL_ID)
      const heightRelField = aiimUtil.findFieldName(layer.fields, FieldNames.HEIGHT_RELATIVE)
      if (attributes[levelIdField] !== levelId) {
        attributes[levelIdField] = levelId;
        this.onAttributeUpdate(levelIdField,levelIdField);
        if (heightRelField && typeof heightRelative === "number") {
          attributes[heightRelField] = heightRelative;
          this.onAttributeUpdate(heightRelField,heightRelative);
        }
        editorUtil.updateZ(geometry,z);
      }
    }
  }

  useTypeChanged = (feature) => {
    if(!feature) return;
    const wall = this.getActiveFeature();
    const layer = sourceUtil.getDetailsLayer();
    const symbol = splitUtil.getSymbol(layer, feature);
    if (wall && symbol) {
      wall.symbol = symbol;
    }
  }

}