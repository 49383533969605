// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";

// Component specific modules (Component-styled, etc.)
import { Panel } from "../../styles/Common/panel";
import {
  ListItem,
  ListItemTitle,
  ListItemTitleAside,
  ListItemSubtitle,
  ListItemAttribute,
  ListItemOverlay,
  ListItemOverlayContent,
  ListItemOverlayHeader,
  ListItemButtons,
} from "../../styles/Common/list";
import { FooterContainer } from "../../styles/Assets/assetStyles";

// App components
import Items from "../common/Items";
import OfficePlan from "../../base/OfficePlan";
import PeopleAssignTo from "../common/PeopleAssignTo";
import PersonPopup from "../common/PersonPopup";
import ListHeader from "../common/ListHeader";
import ListCount from "../common/ListCount";
import InvalidAssignment from "../common/InvalidAssignment";

// Third-party components (buttons, icons, etc.)
import moment from "moment";
import Popup from "../common/Popup/Popup";

// JSON

// CSS

export default class PeoplePanel extends Component {
  constructor(props) {
    super(props);
    const source = officePlanUtil.getPeopleSource();
    let nameFieldName = FieldNames.PEOPLE_FULLNAME;
    if (source && source.layer2D) {
      const fields = source.layer2D.fields;
      let f = aiimUtil.findField(fields, nameFieldName);
      if (f) nameFieldName = f.name;
    }
    const queryCriteriaKey = this.getPanelKey();
    const queryCriteria = QueryCriteria.get(
      queryCriteriaKey,
      this.getSourceKey()
    );
    queryCriteria.requiresGeometry = true;
    queryCriteria.forAssignedPeople = true;
    if (!queryCriteria.sortOptions) {
      queryCriteria.primarySortField = nameFieldName;
      queryCriteria.sortOptions = {
        sortBy: queryCriteria.primarySortField,
        sortDir: "asc",
      };
    }

    this.state = component.newState({});
  }

  componentDidMount = () => {
    component.own(this,[
      Topic.subscribe(Topic.UnitNameCacheUpdated,(params) => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount = () => {
    component.componentWillUnmount(this);
  };

  getPanelKey = () => {
    return this.props.panelKey;
  };

  getSourceKey = () => {
    return this.props.sourceKey;
  };

  getRdxFeatureItemsKey = () => {
    const queryCriteriaKey = this.getPanelKey() || "";
    return queryCriteriaKey + "_FeatureItems";
  };

  render() {
    const { layerCaption, filterTooltip } = this.props;
    const i18n = Context.instance.i18n;
    const queryCriteriaKey = this.getPanelKey() || "";
    return (
      <Panel>
        <ListHeader
          // title={i18n.spaceplanner.assets.people.title}
          title={i18n.spaceplanner.popup.assignments}
          layerCaption={layerCaption}
          filterTooltip={filterTooltip}
          queryCriteriaKey={queryCriteriaKey}
        ></ListHeader>
        <Items
          queryCriteriaKey={queryCriteriaKey}
          rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
          renderItem={this.renderItem}
          sourceKey={this.getSourceKey()}
          requeryOnPlanActions={[
            OfficePlan.Action_AssignmentsUpdated,
            OfficePlan.Action_PersonAssignedToUnit,
            OfficePlan.Action_PersonUnassignedFromUnit,
            OfficePlan.Action_UnitUnassigned,
          ]}
          isShowAllButton={true}
        />
        <FooterContainer>
          <ListCount
            queryCriteriaKey={queryCriteriaKey}
            label={i18n.spaceplanner.assets.people.assignmentsListed}
          ></ListCount>

          <PeopleAssignTo
            disableUnassign={false}
            placement="bottom"
            rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
          />
        </FooterContainer>
      </Panel>
    );
  }

  renderItem = (source, featureItem, index) => {
    const i18n = Context.instance.i18n;
    const key = featureItem.key;
    const attributes = featureItem.feature.attributes;
    const nameField = "knownas";
    const fetchGeometry = officePlanUtil.isPersonAssignedToUnit(featureItem);
    const name = aiimUtil.getAttributeValue(attributes, nameField);
    const assignmentName = officePlanUtil.getPersonAssignmentName(featureItem);
    const labelWithNameString = i18n.spaceplanner.people.assignTo.labelWithName;
    const labelWithName = labelWithNameString.replace("{name}", name);
    const startedField = FieldNames.PEOPLE_START_DATE;
    const startedOn = aiimUtil.getAttributeValue(attributes, startedField);
    const email = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)

    const mStartedOn = moment(startedOn);
    const now = moment();
    const displayTime = mStartedOn.isAfter(now) ? mStartedOn.fromNow() : mStartedOn.toNow(true);

    const duration =
      typeof startedOn === "number" ? (
        <ListItemTitleAside>
          {displayTime}
        </ListItemTitleAside>
      ) : (
        ""
      );

    const roleField = FieldNames.PEOPLE_JOB_TITLE;
    const role = aiimUtil.getAttributeValue(attributes, roleField);

    let orgLevel = null;
    const orgLevel1 = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_ORG_LEVEL_1
    );
    const orgLevel2 = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.PEOPLE_ORG_LEVEL_2
    );
    if (orgLevel1 && orgLevel2) {
      const pattern = i18n.spaceplanner.assets.people.orgLevelPattern;
      orgLevel = pattern.replace("{orgLevel1}", orgLevel1);
      orgLevel = orgLevel.replace("{orgLevel2}", orgLevel2);
    } else {
      orgLevel = orgLevel1 || orgLevel2 || null;
    }
    const isUnassigned = officePlanUtil.isPersonUnassigned(featureItem);
    const isPlaceholder = officePlanUtil.isPersonPlaceholder(featureItem)

    const clicked = (event) => {
      //console.log("featureItem",featureItem)
      Topic.publish(Topic.ShowItemPopup, {
        title: name,
        source: source,
        featureItem: featureItem,
        fetchFeatureItemGeometry: fetchGeometry,
        zoom: true,
        content: (
          <Popup
            sourceKey={this.getSourceKey()}
            featureItem={featureItem}
            isPerson={true}
          />
        ),
      });
    };

    const dragStart = (evt) => {
      evt.dataTransfer.dropEffect = "copy"; // copy move link
      Topic.publish(Topic.DragItemStart, {
        sourceKey: source.key,
        featureItem: featureItem,
      });
    };

    const dragEnd = (evt) => {
      Topic.publish(Topic.DragItemEnd, {
        sourceKey: source.key,
        featureItem: featureItem,
      });
    };

    return (
      <ListItem
        unassigned={isUnassigned}
        isPlaceholder={isPlaceholder}
        key={key}
        onClick={clicked}
        draggable
        onDragStart={dragStart}
        onDragEnd={dragEnd}
      >
        <ListItemTitle>
          {name}
          <ListItemTitleAside>{duration}</ListItemTitleAside>
        </ListItemTitle>
        {/* <ListItemSubtitle>
          <ListItemAttribute>{email}</ListItemAttribute>
        </ListItemSubtitle> */}
        <ListItemSubtitle>
          <ListItemAttribute>
            {assignmentName && assignmentName.length ? assignmentName : <InvalidAssignment />
          }</ListItemAttribute>
        </ListItemSubtitle>
        <ListItemSubtitle>
          <ListItemAttribute>{role || null}</ListItemAttribute>
          <ListItemAttribute>{orgLevel}</ListItemAttribute>
        </ListItemSubtitle>
        <ListItemOverlay>
          <ListItemOverlayContent>
            <ListItemOverlayHeader>{labelWithName}</ListItemOverlayHeader>
            <ListItemButtons>
              <PeopleAssignTo
                featureItems={[featureItem]}
                rdxFeatureItemsKey={this.getRdxFeatureItemsKey()}
                disableUnassign={isUnassigned}
                enableUnassign={!isUnassigned}
                name={name}
                buttons
              />
            </ListItemButtons>
          </ListItemOverlayContent>
        </ListItemOverlay>
      </ListItem>
    );
  };
}
