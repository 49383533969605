import Context from "../../context/Context";
import * as projectionUtil from "../../../src/util/projectionUtil";
import * as sourceUtil from "./sourceUtil";
import { LayerType } from "../../util/interfaces";

/** Creates a new polygon from the input rings and spatial reference.
 * @param rings - The array of rings for the polygon.
 * @param wkid - The well-known id of the spatial reference to use.
 */
export function createPolygon(rings: number[][][], wkid: number): __esri.Polygon {
  const { lib } = Context.getInstance();
  return new lib.esri.Polygon({
    rings,
    spatialReference: {
      wkid
    }
  });
} 
export function ensureGraphicsLayer(view:__esri.View, layerId:string, beforeLayer?:__esri.Layer):__esri.GraphicsLayer {
  let layer:__esri.GraphicsLayer;
  if (view && view.map) {
    layer = <__esri.GraphicsLayer>view.map.findLayerById(layerId);
    if (!layer) {
      const lib = Context.instance.lib;
      layer = new lib.esri.GraphicsLayer({
        id: layerId,
        title: layerId,
        listMode: "hide"
      });
      layer.elevationInfo = {
        mode: "relative-to-ground",
        offset: Context.instance.config.graphicElevationOffset,
        unit: "meters"
      };
      if(beforeLayer) {
        const found = view.map.layers.some((l,i)=> {
          if(l===beforeLayer) {
            view.map.add(layer,i+1);
            return true;
          }
          return false;
        })
        if(found) return layer;
      }
      view.map.add(layer);
    }
  }
  return layer;
}

export function findLayerById(view:__esri.View, layerId:string):__esri.Layer {
  if (view && view.map) return view.map.findLayerById(layerId);
}

/** Generates map view constraints with zoom levels to an approximate max scale 
 * of 1:1. New levels of detail are generated based on the tiling scheme of the 
 * specified web map's basemap.
 * @param map - The app's webmap containing basemap tile information.
 * @returns A promise that resolves with the new map constraints.
 */
export async function getConstraints(map: __esri.WebMap) {
  const existingLODs = await getBaseMapLodsFromLayer(map);
  const lib = Context.getInstance().lib;
  const LOD: typeof __esri.LOD = lib.esri.LOD;
  if (existingLODs) {
    const newLODs = getAdditionalLods(existingLODs);
    const constraints: __esri.MapViewConstraints = {
      lods: existingLODs.concat(newLODs).map(l => LOD.fromJSON(l)),
      maxZoom: newLODs[newLODs.length - 1].level,
      maxScale: newLODs[newLODs.length - 1].scale,
      minZoom: existingLODs[0].level,
      minScale: existingLODs[0].scale,
      snapToZoom: true
    }
    return constraints;
  }
}   
function getAdditionalLods(lods: __esri.LODProperties[]) {
  const lastLOD = lods[lods.length - 1];
  const newLODs: __esri.LODProperties[] = [];
  let { scale, resolution, level } = lastLOD;
  while (scale > 1) {
    scale = scale / 2;
    resolution = resolution / 2;
    level++;
    newLODs.push({ level, resolution, scale });
  }
  return newLODs;
}
function getBaseMapLodsFromLayer(map: __esri.WebMap): Promise<__esri.LODProperties[]> {
  const basemap = map && map.basemap;
  if (!basemap)
    return Promise.resolve([]);

  const originLods = [];

  if (basemap.loadStatus === "failed") return Promise.resolve(originLods);
  if (!basemap.loaded && basemap.loadStatus !== "loading") basemap.load();
  
  return basemap.when(() => {
    const promises = basemap.baseLayers.filter(l => l.loadStatus !== "failed").map((layer: __esri.TileLayer) => {
      if (!layer.loaded && layer.loadStatus !== "loading")
        layer.load();
      return layer.when();
    });
    return Promise.all(promises);
  }).then(() => {
    basemap.baseLayers.some((layer: __esri.TileLayer) => {
      if (layer.tileInfo && layer.tileInfo.lods && layer.tileInfo.lods.length > 0) {
        layer.tileInfo.lods.forEach(l => originLods.push(l.toJSON()));
        return true;
      }
    });
    return originLods;
  });
}

export async function goToGeometry(
  view: __esri.MapView | __esri.SceneView,
  targetGeometry: __esri.Geometry,
  purpose?: "point_level" | "unit_level" | "fpe_feature"
) {
  const lib = Context.instance.lib;
  let bufferMeters = 10;
  if (purpose === "unit_level") bufferMeters = 25;
  else if (purpose === "fpe_feature") bufferMeters = 1;

  const addZ = (extent,z) => {
    const ext = new lib.esri.Extent({
      "xmin": extent.xmin,
      "xmax": extent.xmax,
      "ymin": extent.ymin,
      "ymax": extent.ymax,
      "zmin": z,
      "zmax": z,
      spatialReference: extent.spatialReference
    });
    return ext;
  };

  if (!targetGeometry) return Promise.resolve();
  // TODO need a different approach for 3D?
  // TODO jsapi3 has map.setExtent(extent, fit?), equivalent in 4?
  const is3D = (view && view.type === "3d");
  let geometry = null, target = null, heading = null, tilt = null;
  if (targetGeometry.type === "point") {
    const point = <__esri.Point>targetGeometry;
    geometry = projectionUtil.geodesicBuffer(targetGeometry, bufferMeters);
    if (geometry && geometry.extent) {
      geometry = geometry.extent;
      const z = (typeof point.z === "number") ? point.z : 0;
      geometry = addZ(geometry.extent, z);
      target = { target: geometry };
    }
  } else if (targetGeometry.extent) {
    const ext = targetGeometry.extent.clone();
    geometry = projectionUtil.geodesicBuffer(targetGeometry, bufferMeters);
    if (geometry && geometry.extent) {
      geometry = geometry.extent;
      const z = (typeof ext.zmin === "number") ? ext.zmin : 0;
      geometry = addZ(geometry.extent, z);
      target = { target: geometry };
    }
  } else {
    geometry = targetGeometry;
    target = { target: geometry };
  }
  const options: __esri.GoToOptions2D & __esri.GoToOptions3D = {
    duration: 1000,
    //@ts-ignore possible values:
    // https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html#GoToOptions2D
    // https://developers.arcgis.com/javascript/latest/api-reference/esri-views-SceneView.html#GoToOptions3D
    easing: "out-back"
  };

  if (is3D) {
    if (view.camera) {
      heading = view.camera.heading;
      tilt = 60;
    }
    if (typeof heading === "number") target.heading = heading;
    if (typeof tilt === "number") target.tilt = tilt;
  }

  if (target) {
    return view.goTo(target, options).then(() => {
      if (!is3D && !view.extent.contains(geometry)) {
        view.zoom -= 1;
      }
    });
  } else {
    return Promise.resolve();
  }
}

export function getDetailsLayerView() {
  return getLayerView(sourceUtil.getDetailsLayer()) as __esri.FeatureLayerView;
}

/** Returns all rings of a polygon that are clockwise and not contained 
 * within any other ring of the polygon.
 * @param polygon - A single or multi-part polygon
 * @returns One or more rings of the polygon 
 */
export function getExteriorRings(polygon: __esri.Polygon): number[][][] {
  const { lib, views } = Context.getInstance();
  const engine: __esri.geometryEngine = lib.esri.geometryEngine;
  const SpatialReference: typeof __esri.SpatialReference = lib.esri.SpatialReference;

  if (polygon.rings.length === 1) {
    if (engine.isSimple(polygon)) {
      return polygon.rings;
    } else {
      const simple = engine.simplify(polygon) as __esri.Polygon;
      return simple ? simple.rings : [];
    }
  } else {
    // new polygons from each exterior ring
    var exterior = polygon.rings.filter(function (ring: number[][]) {
      return polygon.isClockwise(ring);
    }).map(function (ring) {
      return createPolygon([ring], polygon.spatialReference != null
        ? polygon.spatialReference.wkid
        : views && views.activeView && views.activeView.spatialReference
          ? views.activeView.spatialReference.wkid
          : SpatialReference.WebMercator.wkid);
    });
    // filter all rings that are not contained within any other ring
    return exterior.filter(function (polygon) {
      return exterior.filter(function (otherPolygon) {
        return !engine.equals(otherPolygon, polygon);
      }).every(function (otherPolygon) {
        return !engine.contains(otherPolygon, polygon);
      });
    }).map(function (polygon) {
      return polygon.rings;
    }).flat();
  }
}
export function getLayer(type: LayerType) {
  switch (type) {
    case "detail":
      return sourceUtil.getDetailsLayer();
    case "unit":
      return sourceUtil.getUnitsLayer();
    case "level":
      return sourceUtil.getLevelsLayer();
    case "facility":
      return sourceUtil.getFacilitiesLayer();
    case "site":
      return sourceUtil.getSitesLayer();
  }
}
export function getLayerView(typeOrLayer: LayerType | __esri.Layer): __esri.LayerView {
  const layer = typeof typeOrLayer === "string" ? getLayer(typeOrLayer) : typeOrLayer;
  let layerView: __esri.LayerView;
  const view: __esri.View = getView();
  const layerViews = view && view.allLayerViews.toArray();
  const viewContainer = document.getElementById("sp-view-container");
  if (view && layerViews && layer && viewContainer) {
    layerViews.some(lv => {
      if (lv && lv.layer === layer) {
        layerView = lv;
      }
      return !!layerView;
    })
  }
  return layerView;
}

export function getLevelsLayerView() {
  return getLayerView(sourceUtil.getLevelsLayer()) as __esri.FeatureLayerView;
}

export function getUnitsLayerView() {
  return getLayerView(sourceUtil.getUnitsLayer()) as __esri.FeatureLayerView;
}

export function getView():__esri.View {
  return Context.instance.views.activeView;
}

export function hasPointGeometry(feature:{geometry:__esri.Point}):boolean {
  let ok = !!(feature && feature.geometry);
  if (ok) {
    let x = feature.geometry.x;
    let y = feature.geometry.y;
    if (x === null || y === null || (x === 0 && y === 0)) {
      ok = false;
    }
  }
  return ok;
}

export function removeAllGraphics(view:__esri.View, layerOrId:__esri.GraphicsLayer | string) {
  let layer: __esri.GraphicsLayer;
  if (typeof layerOrId === "string" && view) {
    layer = <__esri.GraphicsLayer>findLayerById(view, layerOrId);
  }
  if (layer && layer.graphics)
    layer.graphics.removeAll();
}
