import React from "react";

import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import GraphicStat from "./GraphicStat";
// import OfficePlan from "../../base/OfficePlan";
// import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

// Styled Components
import {
  StatsWrapper,
  GroupIcon,
  MeasureAreaIcon,
  UnitsIcon
} from "../../styles/statsStyles";

export default class UnitStats extends React.Component {
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      peopleCount: 0,
      unitCount: 0,
      sumArea: 0,
      sumCapacity: 0
    });
  }

  componentDidMount() {
    this._mounted = true;
    this.refreshStats();

    // component.own(this,[
    //   Topic.subscribe(Topic.PlanModified,params => {
    //     const actions = [OfficePlan.Action_AssignmentsUpdated];
    //     if (actions && actions.indexOf(params.action) !== -1) {
    //       this.refreshStats();
    //     }
    //   })
    // ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  refreshStats = () => {};

  render() {
    const i18n = Context.instance.i18n;
    const lib = Context.instance.lib;
    const featureItem = this.props.featureItem;
    const attributes = featureItem.feature.attributes;
    let useType = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.UNITS_USE_TYPE
    );
    let capacity = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.UNITS_CAPACITY
    );
    let area = aiimUtil.getAttributeValue(
      attributes,
      FieldNames.UNITS_AREA_GROSS
    );

    if (typeof capacity !== "number") capacity = 0;
    if (typeof area !== "number") area = 0;
    if (typeof area === "number") {
      area = lib.dojo.number.format(area, { places: 0 });
    }

    return (
      <StatsWrapper key="a" className="i-areastats-graphics">
        <GraphicStat
          key="useType"
          icon={<UnitsIcon />}
          label={i18n.spaceplanner.unitStats.officeType}
          value={useType}
        />
        <GraphicStat
          key="capacity"
          icon={<GroupIcon />}
          label={i18n.spaceplanner.unitStats.capacity}
          value={capacity}
        />
        <GraphicStat
          key="area"
          icon={<MeasureAreaIcon />}
          label={i18n.spaceplanner.unitStats.area}
          value={area}
        />
      </StatsWrapper>
    );
  }
}
