// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import QueryCriteria from "../../base/QueryCriteria";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import Context from "../../../context/Context";

// Component specific modules (Component-styled, etc.)
import { SearchWrapper } from "../../styles/Common/search";

// App components
import TextField from "../../../common/components/TextField";

// Third-party components (buttons, icons, etc.)
import Button from "calcite-react/Button";
import CloseIcon from "calcite-ui-icons-react/XIcon";
import SearchIcon from "calcite-ui-icons-react/SearchIcon";

// JSON

// CSS

export default class Search extends Component {

  constructor(props) {
    super(props);
    let inputValue = "";
    const queryCriteria = this.getQueryCriteria();
    if (queryCriteria) inputValue = queryCriteria.searchText;
    this.state = component.newState({
      inputValue: inputValue,
      key: this.getRandomKey()
    });
  }

  componentDidMount() {
    component.own(this,[

      Topic.subscribe(Topic.QueryCriteriaCleared,params => {
        const key = this.props.queryCriteriaKey;
        if (key && key === params.key) {
          this.setState({
            inputValue: "",
            key: this.getRandomKey()
          });
        }
      })

    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getQueryCriteria = () => {
    if (this.props.queryCriteriaKey) {
      return QueryCriteria.get(this.props.queryCriteriaKey);
    }
  };

  getRandomKey = () => {
    return 'search_' + new Date().getTime();
  };

  clearSearch = evt => {
    const value = '';
    this.setState({ inputValue: value, key: this.getRandomKey() });
    this.onTextChange(value);
  };

  onTextChange = (text) => {
    const queryCriteria = this.getQueryCriteria();
    if (queryCriteria) queryCriteria.onSearchTextChanged(text);
    if (this.props.searchTextChanged) this.props.searchTextChanged(text);
  };

  onChange = (evt) => {
    let v = evt.target.value;
    this.setState({ inputValue: v });
    this.onTextChange(v);
  };

  render() {
    const { inputValue, key } = this.state;
    const isRTL = Context.getInstance().uiMode.isRtl;
    return (
      <SearchWrapper>
        {isRTL && <SearchIcon style={{margin: '15px 10px 0 8px'}} size={16} /> }
        <TextField
          {...this.props}
          search={!isRTL}
          key={key}
          defaultValue={inputValue}
          placeholder={this.props.placeholder}
          onChange={(evt) => this.onChange(evt)}
        />
        {inputValue !== "" ? (
          <Button iconButton icon={<CloseIcon />} onClick={evt => this.clearSearch(evt)} />
        ) : null}
      </SearchWrapper>
    );
  }
}
