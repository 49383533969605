import React from "react";

import Context from "../../../../context/Context";
import { IFeatureItem } from "../copypaste/featureItemUtil";

import "@esri/calcite-components/dist/components/calcite-action";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-list-item";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteAction,
  CalciteIcon,
  CalciteListItem,
  CalciteTooltip
} from "@esri/calcite-components-react";

interface IFeatureListItemProps {
  featureItem: IFeatureItem,
  invalid?: boolean,
  invalidMessage?: string,
  removeable?: boolean,
  selected?: boolean,
  showRadio?: boolean,
  onMouseEnter?: (e) => void,
  onMouseLeave?: (e) => void,
  onRadioClick?: (e) => void,
  onRemove?: (e) => void,
  onSelect?: (e) => void,
  virtualize: boolean
}

const FeatureListItem = ({
  featureItem,
  invalid = false,
  invalidMessage,
  removeable = false,
  selected = false,
  showRadio = false,
  onMouseEnter,
  onMouseLeave,
  onRadioClick,
  onRemove,
  onSelect,
  virtualize = true
}: IFeatureListItemProps) => {
  const i18n = Context.instance.i18n;

  const renderCalciteListItem = () => {
    return (
      <CalciteListItem
        className="i-editor-feature-list-item"
        label={featureItem.title}
        description={featureItem.description}
        selected={selected || undefined}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onCalciteListItemSelect={onSelect}
      >
        {showRadio &&
          <CalciteAction
            slot="actions-start"
            scale="s"
            icon={featureItem.selected ? "circle-f" : "circle"}
            text={i18n.general.select}
            onClick={onRadioClick}
          />
        }
        {removeable &&
          <div slot="actions-end" style={{ display: "flex", alignItems: "center" }}>
            {invalid &&
              <CalciteIcon
                className="i-indicator-dot"
                scale="s"
                icon="circle-f"
                title={invalidMessage}
              />
            }
            <CalciteAction
              id={`feature-list-item-${featureItem.key}`}
              scale="s"
              icon="x"
              text={i18n.general.del}
              onClick={onRemove}
            />
            <CalciteTooltip label={i18n.miniapps.mapSelection.removeFromSelection} referenceElement={`feature-list-item-${featureItem.key}`}>
              {i18n.miniapps.mapSelection.removeFromSelection}
            </CalciteTooltip>        
          </div>
        }
      </CalciteListItem>
    );
  }
  const renderList = () => (
    <div
      className={`i-editor-feature-list-item${selected ? " i-editor-feature-list-item-selected" : ""}`}
      onClick={onSelect}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={0}
    >
      {showRadio &&
        <CalciteIcon
          scale="s"
          icon={featureItem.selected ? "circle-f" : "circle"}
          textLabel={i18n.general.select}
          onClick={onRadioClick} />}
      <header>
        <div className="i-list-item-label">{featureItem.title}</div>
        <div className="i-list-item-description">{featureItem.description}</div>
      </header>
      {removeable && invalid && <CalciteIcon
        className="i-indicator-dot"
        scale="s"
        icon="circle-f"
        title={invalidMessage} />
      }
      {removeable &&
        <CalciteAction
          id={`feature-list-item-${featureItem.key}`}
          scale="s"
          icon="x"
          text={i18n.general.del}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(e);
          }} />
      }
      {removeable &&
        <CalciteTooltip label={i18n.miniapps.mapSelection.removeFromSelection} referenceElement={`feature-list-item-${featureItem.key}`}>
          {i18n.miniapps.mapSelection.removeFromSelection}
        </CalciteTooltip>
      }
    </div>
  );

  return (
    <>
      {virtualize
        ? renderList()
        : renderCalciteListItem()
      }
    </>
  )
}

export default FeatureListItem;