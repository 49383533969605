import BaseClass from "../../util/BaseClass"
import Context from "../../context/Context";
import * as aiimUtil from "../util/aiimUtil";

export default class FacilityFootprints extends BaseClass {

  graphics;
  graphicsByFacilityId;
  graphicsByObjectId;
  highlightLayer;
  layer;
  layerId = "indoors-facility-footprints";

  _highlightGraphic2D;
  _hoverGraphic2D;

  activating(facilityInfo,facilitiesSource) {
    this.removeHighlight();
  }

  _addHighlight2D(footprintGraphic) {
    if (this.highlightLayer && footprintGraphic && footprintGraphic.xtnOutline) {
      this.removeHighlight();
      const cloned = footprintGraphic.xtnOutline.clone();
      cloned.xtnSrc = footprintGraphic;
      cloned.xtnFacilityHighlight = true;
      this._highlightGraphic2D = cloned;
      this.highlightLayer.graphics.add(cloned);
    }
  }

  addHoverHighlight2D(footprintGraphic) {
    if (this._hoverGraphic2D && footprintGraphic === this._hoverGraphic2D.xtnSrc) {
      return;
    }
    if (this._highlightGraphic2D && footprintGraphic === this._highlightGraphic2D.xtnSrc) {
      this.removeHoverHighlight2D();
      return;
    }
    if (this.highlightLayer && footprintGraphic && footprintGraphic.xtnOutline) {
      this.removeHoverHighlight2D();
      const cloned = footprintGraphic.xtnOutline.clone();
      cloned.xtnSrc = footprintGraphic;
      cloned.xtnFacilityHighlight = true;
      this._hoverGraphic2D = cloned;
      this.highlightLayer.graphics.add(cloned);
    }
  }

  deactivating(facilitiesSource) {
    this.removeHighlight();
  }

  _ensureLayer(layer) {
    const view = Context.getInstance().views.mapView;
    if (view) {
      const lyr = view.map.findLayerById(this.layerId);
      if (!lyr) view.map.add(layer);
    }
  }

  findFacilityIdByPoint(point,meters?) {
    const result = [];
    const dataset = Context.getInstance().aiim.datasets.facilities;
    const field = dataset && dataset.facilityIdField;
    const addId = (graphic) => {
      const id = aiimUtil.getAttributeValue(graphic.attributes,field);
      if (typeof id === "string" && id.length > 0) {
        if (result.indexOf(id) === -1) result.push(id);
      }
    };
    const graphics = this.graphics;
    if (graphics && point) {
      graphics.forEach(graphic => {
        //console.log("findFacilityByPoint-graphic",graphic);
        if (graphic && graphic.attributes && graphic.geometry &&
            graphic.geometry.type === "polygon" &&
            graphic.geometry.contains(point)) {
          //console.log("findFacilityByPoint-contains",graphic.attributes.facility_id);
          addId(graphic);
        }
      });
    }
    if (result.length === 0) return null;
    else if (result.length === 1) return result[0];
    else return result;
  }

  getSource() {
    return Context.getInstance().aiim.datasets.categories.findSourceByKey("Facilities"); // TODO
  }

  highlightFacility2D(info) {
    if (info && info.facilityId && this.graphicsByFacilityId) {
      let graphic = this.graphicsByFacilityId[info.facilityId];
      if (graphic) this._addHighlight2D(graphic);
    }
  }

  hitTest(view,event,firstOnly) {
    const result = [];
    const graphics = this.graphics;
    if (graphics) {
      const screenPoint = {x: event.x, y: event.y};
      const mapPoint = view.toMap(screenPoint);
      graphics.some(graphic => {
        if (graphic && graphic.attributes && graphic.geometry &&
            graphic.geometry.type === "polygon" &&
            graphic.geometry.contains(mapPoint)) {
          result.push(graphic);
          if (firstOnly) return true;
        }
        return false;
      });
    }
    return result;
  }

  load() {
    const promise = new Promise<void>((resolve,reject) => {
      const facilitiesDataset = Context.getInstance().aiim.datasets.facilities;
      const levelsDataset = Context.getInstance().aiim.datasets.levels;
      if (!facilitiesDataset) {
        resolve();
        return;
      }
      facilitiesDataset.queryAll().then(result => {
        try {
          const layer = this._makeGraphicsLayer();
          const graphics = [], graphicsByFacilityId = {}, graphicsByObjectId = {};
          if (result && result.features) {
            const idFld = facilitiesDataset.facilityIdField;
            const oidFld = facilitiesDataset.objectIdField;
            result.features.forEach(feature => {
              const id = aiimUtil.getAttributeValue(feature.attributes,idFld);
              const oid = aiimUtil.getAttributeValue(feature.attributes,oidFld);
              const graphic = this._makeGraphic(feature);
              const outline = this._makePolylineGraphic(feature);
              if (graphic) {
                graphic.layer = layer;
                graphics.push(graphic);
                graphicsByFacilityId[id] = graphic;
                graphicsByObjectId[oid] = graphic;
                outline.layer = layer;
                graphic.xtnOutline = outline;
              }
            });
          }
          if (graphics.length > 0) {
            layer.graphics.removeAll();
            layer.graphics.addMany(graphics);
            this.graphics = graphics;
            this.graphicsByFacilityId = graphicsByFacilityId;
            this.graphicsByObjectId = graphicsByObjectId;
            this.highlightLayer = this._makeGraphicsLayer();
            this._ensureLayer(this.highlightLayer);
          }
        } catch(ex) {
          console.error("Error loading facility footprints",ex);
        }
        if (levelsDataset) {
          if (levelsDataset.loadPromise) return levelsDataset.loadPromise;
          else return levelsDataset.load();
        }
      }).then(() => {
        //console.log("FacilityFootprints::load - footprints loaded");
        resolve();
      }).catch(ex => {
        console.error("Error loading facility footprints",ex);
        resolve();
      });
    });
    return promise;
  }

  _makeGraphic(feature) {
    if (!feature || !feature.geometry || !feature.attributes) return null;
    const lib = Context.getInstance().lib;
    const graphic = new lib.esri.Graphic({
      geometry: feature.geometry,
      attributes: feature.attributes,
      symbol: this._makeSymbol(),
      visible: true
    });
    return graphic;
  }

  _makeGraphicsLayer() {
    const i18n = Context.getInstance().i18n;
    const lib = Context.getInstance().lib;
    const layer = new lib.esri.GraphicsLayer({
      id: this.layerId,
      title: i18n.facilities.facilityFootprintsLayer,
      listMode: "hide"
    });
    return layer;
  }

  _makePolylineGraphic(feature) {
    if (!feature || !feature.geometry || !feature.attributes) return null;
    const polyline = this._polygonToPolyline(feature.geometry);
    const lib = Context.getInstance().lib;
    const graphic = new lib.esri.Graphic({
      geometry: polyline,
      attributes: feature.attributes,
      symbol: this._makePolylineSymbol(),
      visible: true
    });
    return graphic;
  }

  _makePolylineSymbol() {
    let color = Context.instance.config.facilityOutlineColor2D;
    if (!color) color = "#005e95";
    const symbol = {
      type: "simple-line",
      color: color,
      width: "2.4",
      style: "solid"
    };
    return symbol;
  }

  _makeSymbol() {
    return {
      "type": "simple-fill",
      "color": [255,255,255,0],
      "style": "solid",
      "outline": {
        "color": "#005e95",
        "width": 2.4
      }
    };
  }

  _polygonToPolyline(polygon) {
    const paths = polygon.rings;
    const polyline = new Context.instance.lib.esri.Polyline({
      hasZ: polygon.hasZ,
      hasM: polygon.hasM,
      paths: paths,
      spatialReference: polygon.spatialReference
    });
    return polyline;
  }

  removeHighlight() {
    if (this.highlightLayer) {
      this.highlightLayer.graphics.removeAll();
    }
    this._highlightGraphic2D = this._hoverGraphic2D = null;
  }

  removeHoverHighlight2D() {
    if (this.highlightLayer && this._hoverGraphic2D) {
      //console.log("removeHoverHighlight2D",this._hoverGraphic2D)
      this.highlightLayer.remove(this._hoverGraphic2D);
      this._hoverGraphic2D = null;
    }
  }

}
