import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

export async function prepare(task) {
  const transactionInfo = transaction.makeTransactionInfo();
  const info = task.info;

  const makeEdits = () => {
    const featuresByLayerId = info.featuresByLayerId;
    const layerIds = info.layerIds;
    layerIds.forEach(layerId => {
      const features = featuresByLayerId[layerId];
      const adds = [];
      features.forEach(f => {
        const add = transactionUtil.cloneFeature(f);
        adds.push(add);
      });
      if (adds.length > 0) {
        const layerEdits = {
          id: layerId,
          adds: adds
        }
        const undoLayerEdits = {
          id: layerId,
          deletes: ["?"] // needs to be populated following add
        }
        transactionInfo.edits.push(layerEdits);
        transactionInfo.undo.edits.push(undoLayerEdits);
        transactionInfo.useGlobalIds = false;
      }
    })
  }

  makeEdits();
  console.log("paste.transactionInfo", transactionInfo);
  //if (true) throw new Error("paste.tmp")
  return transactionInfo;
}
