import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import * as mapUtil from "../../../base/mapUtil";
import * as mediaUtil from "./mediaUtil";
import { IMediaItem } from "../../../miniapps/common/types";

export default class MediaPanelVM extends BaseVM {

  activeEditingTool;
  private graphicsLayerId = "indoors-media-graphics";

  async activateSketch(mediaItem: IMediaItem) {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const mediaLayer = mediaItem.mediaLayer;
    const imageElement = mediaLayer.source as __esri.ImageElement;
    try {
      const view = this.getView();
      const advGeoref = await lib.esri.mediaUtils.createDefaultControlPointsGeoreference(imageElement,view.extent.clone())
      const advImageElement = new lib.esri.ImageElement({
        image: imageElement.image,
        georeference: advGeoref,
      });
      const editingTool = new lib.esri.editingTools.MediaTransformToolsWrapper({
        view: view,
        mediaElement: imageElement,
        preserveAspectRatio: mediaItem.preserveAspectRatio,
        advancedMode: {
          mediaElement: advImageElement,
          view: view
        }
      });
      if (mediaItem.preserveAspectRatio) {
        try {
          // jsapi core issue at 4.27
          editingTool._transformTool._preserveAspectRatio.enabled = mediaItem.preserveAspectRatio;
        } catch(ex) {
          console.error(ex);
        }
      }
      this.activeEditingTool = editingTool;

      // @ts-ignore
      this.own(lib.esri.reactiveUtils.watch(() => imageElement.georeference.controlPoints,
        (controlPoints) => {
          this.onGeoreferenceChange(mediaItem);
        }
      ));

    } catch(ex) {
      console.error(ex);
    }
  }

  async addResource(mediaItem: IMediaItem) {
    const portalItem = mediaUtil.getWebmapItem();
    const resource = new Context.instance.lib.esri.PortalItemResource({
      path: mediaItem.url
    });
    const content = mediaItem.fileInfo.blob;
    await portalItem.addResource(resource,content);
    // @ts-ignore
    mediaItem.mediaLayer.url = mediaItem.url;
  }

  cancelSketch() {
    this.clearHighlight();
    this.clearHandles();
    if (this.activeEditingTool) {
      try {
        // @ts-ignore
        this.getView().activeTool = null;
        this.activeEditingTool.destroy()
      } catch(ex) {
        console.error(ex);
      }
      this.activeEditingTool = null;
    }
  }

  clear() {
    this.cancelSketch();
  }

  clearHighlight() {
    mapUtil.removeAllGraphics(this.getView(),this.graphicsLayerId);
  }

  highlightItem(mediaItem: IMediaItem) {
    const view = this.getView()
    const gl = mapUtil.ensureGraphicsLayer(view,this.graphicsLayerId);
    const symbol: __esri.SimpleFillSymbolProperties = { 
      type: "simple-fill",
      style: "solid", 
      color: [0, 0, 0, 0],
      outline: {
        style: "solid", 
        width: 2, 
        color: [0, 255, 255, 1] 
      }
    }
    const extent = mediaItem.mediaLayer.fullExtent;
    if (extent) {
      const graphic = new Context.instance.lib.esri.Graphic({
        geometry: extent.clone(),
        symbol
      })
      gl.removeAll();
      gl.add(graphic);
    } else {
      gl.removeAll();
    }
  }

  onGeoreferenceChange(mediaItem: IMediaItem) {
  }

  async removeResource(mediaItem: IMediaItem) {
    const portalItem = mediaUtil.getWebmapItem();
    const resource = new Context.instance.lib.esri.PortalItemResource({
      path: mediaItem.url
    });
    await portalItem.removeResource(resource);
  }

  async save(mediaItems: IMediaItem[]) {
    const portalItem = mediaUtil.getWebmapItem();
    const data = await portalItem.fetchData("json");
    const findIndex = id => {
      for (let i=0; i < data.operationalLayers.length; i++) {
        if (id === data.operationalLayers[i].id) return i;
      }
      return -1;
    }
    mediaItems.slice().reverse().forEach(mediaItem => {
      const idx = findIndex(mediaItem.mediaLayer.id);
      if (!mediaItem.removed) {
        const opLayer = mediaUtil.asOperationalLayer(mediaItem);
        if (idx === -1) {
          data.operationalLayers.push(opLayer);
        } else {
          data.operationalLayers[idx] = opLayer;
        }
      } else {
        if (idx !== -1) {
          data.operationalLayers.splice(idx,1);
        }
      }
    })
    await portalItem.update({data});
  }

}