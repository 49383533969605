import React from "react";
import moment from 'moment';

export default class MonthYearSelector extends React.Component {

    render() {
        const monthVal = this.props.month.month();
        const yearVal = this.props.month.year();
        let years =[];
        for (let i=1900; i<=2050;i++) years.push(i);
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <select className="i-select-monthElement"
                        onChange={(e) => { 
                            this.props.onMonthSelect(this.props.month, e.target.value); }}>
                        {moment.months().map((label, value) => (
                        <option key={"_"+value} selected={value === monthVal}
                            style={{color: "#565a5c"}}value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select className="i-select-monthElement"
                        onChange={(e) => { this.props.onYearSelect(this.props.month, e.target.value); }}>
                        {years.map((value) => (
                            <option key={"_"+value} selected={value === yearVal}
                                style={{color: "#565a5c"}}value={value}>{value}</option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }

}