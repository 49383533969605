import React from "react";
import Select from "calcite-react/Select";
import Context from "../../../context/Context";
import Form, {FormControl} from "calcite-react/Form";
import ClearIcon from "calcite-ui-icons-react/XCircleIcon";
import TextField from 'calcite-react/TextField';
import * as component from "../../../components/util/component";

import {
    LabelGroup,
    ClearButton,
    SelectGroup,
    MenuItem
} from "../../styles/Filter/filterGroup";

export default class NumericFilter extends React.Component {

    constructor(props) {
        super(props);
        this.componentId = component.nextId();
        let min = null, max = null, inputValue = null, operator = "Any";
        const pair = this.props.pair;
        if(pair && pair.operator) operator = pair.operator;
        if(pair && pair.value) {
            if(Array.isArray(pair.value)) {
               min = pair.value[0];
               max = pair.value[1];
            }else {
                inputValue = pair.value;
            }
        }
        this.state={
            min : min,
            max : max,
            operator: operator,
            inputValue : inputValue
        }
    }

    handleExact=(event)=> {
        this.setState({
            inputValue : event.target.value
        }, ()=> {
            this.props.onChange(this.state.inputValue, this.state.operator);
        })
    }

    handleMin=(event)=>{
        this.setState({
            min : event.target.value
        }, ()=> {
            this.props.onChange([this.state.min, this.state.max], this.state.operator);
        })
    }

    handleMax=(event)=>{
        this.setState({
            max : event.target.value
        }, ()=> {
            this.props.onChange([this.state.min, this.state.max], this.state.operator);
        })
    }

    onOperatorChange=(value)=>{
        this.setState({
            operator : value
        }, ()=> {
            this.props.onChange(this.state.inputValue, this.state.operator);
        })
    }

    clear=()=>{
        this.setState({
            operator : "Any",
            inputValue : null,
            min : null,
            max: null
        })
        this.props.onChange(undefined);
        this.props.clear();
    }

    renderClearButton=()=> {
        const i18n = Context.instance.i18n;
        const operator = this.state.operator;
        if(!operator || operator.length === 0) return;
        if(!this.state.inputValue && !this.state.min && !this.state.max) return;
          return (
            <ClearButton
              iconButton
              icon={<ClearIcon size={16} />}
              title={i18n.filter.clearPart}
              onClick={this.clear}
            />
          );
    }

    renderMenuItems=()=>{
        let menuItems = [];
        const i18n = Context.instance.i18n;
        let anyStr = i18n.filter.numericLabels.any;
        let equalStr = i18n.filter.numericLabels.equal;
        let minStr = i18n.filter.numericLabels.min;
        let maxStr = i18n.filter.numericLabels.max;
        let betweenStr = i18n.filter.numericLabels.between;
        menuItems.push(
            <MenuItem key={anyStr} value="Any">
                  {anyStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={equalStr} value="Equal">
                  {equalStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={minStr} value="Minimum">
                  {minStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={maxStr} value="Maximum">
                  {maxStr}
            </MenuItem>
        )
        menuItems.push(
            <MenuItem key={betweenStr} value="Between">
                  {betweenStr}
            </MenuItem>
        )
        return menuItems;
    }

    render(){
        let menuItems = this.renderMenuItems();
        const i18n = Context.instance.i18n;
        const closeButton = this.renderClearButton();
        const operator = this.state.operator;
        const horizontal = true;
        const cls = horizontal ? "i--form i--form-horizontal" : "i--form";
        let validationMsg = i18n.filter.validationMsg;
        validationMsg = validationMsg.replace("{fieldName}", this.props.pair.label.toLowerCase());
        
        
        return (
            <div>
            <SelectGroup>
                <LabelGroup>
                    <label> {this.props.pair.label} </label>
                    {closeButton}
                </LabelGroup>
                <Select
                    appendToBody
                    style= {{width:"17.875rem"}}
                    placeholder={i18n.filter.valuePrompt}
                    selectedValue={operator}
                    onChange={this.onOperatorChange}
                >
                {menuItems}
                </Select>
            </SelectGroup>
            {(operator === "Equal" || operator === "Minimum" || operator ==="Maximum") &&
                (
                    <Form className={cls}> 
                        <FormControl className={"i-filter-group"}>
                            <TextField 
                                id={this.componentId+"_numeric"}
                                value={this.state.inputValue}
                                fullWidth={false}
                                type = "number"
                                placeholder={i18n.filter.inputPrompt}
                                onChange={this.handleExact}/>
                            {!this.state.inputValue && (<div className={"i-filter-description"}>{validationMsg}</div>)}
                        </FormControl>
                    </Form>
                )
            }
            {operator === "Between" && (
                 <Form onSubmit={this.submitRangeInput} className={cls}> 
                    <FormControl horizontal={horizontal} className={"i-filter-group"}>
                        <div>
                        <TextField 
                            id={this.componentId+"_numeric"}
                            type = "number"
                            value={this.state.min}
                            fullWidth={false}
                            placeholder={i18n.filter.numericLabels.min}
                            style={{width: "7rem"}}
                            onChange={this.handleMin}/>
                        {!this.state.min && (<div className={"i-filter-description"}>{i18n.filter.minValue}</div>)}  
                        </div>
                        <span>{i18n.filter.to}</span>
                        <div>
                        <TextField 
                            id={this.componentId+"_numeric"}
                            type = "number"
                            value={this.state.max}
                            fullWidth={false}
                            placeholder={i18n.filter.numericLabels.max}
                            style={{width: "7rem"}}
                            onChange={this.handleMax}/>
                        {!this.state.max && (<div className={"i-filter-description"}>{i18n.filter.maxValue}</div>)}
                        </div>
                    </FormControl>
                </Form>
            )}
            </div>
        )
    }
}