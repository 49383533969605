import React from "react";

import { StyledButton } from "../../styles/Common/button";

export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: undefined };
  }

  componentDidMount() {
    if (typeof this.props._onMount === "function") {
      this.props._onMount(this);
    }
  }

  disable() {
    this.setState({ disabled: true });
  }

  enable() {
    this.setState({ disabled: false });
  }

  render() {
    let disabled = this.state.disabled;
    if (disabled === undefined) disabled = this.props.disabled;
    return (
      <StyledButton {...this.props} disabled={!!disabled}>
        {this.props.children}
      </StyledButton>
    );
  }
}
