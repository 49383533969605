import React from "react";
import ModalController from "../../src/components/common/Modal/ModalController";
import Topic from "../context/Topic";
import IdlePopup from "../../src/components/common/IdlePopup/IdlePopup";
import Context from "../context/Context";
import Office365 from "../../src/components/main/More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import {getLoggedInUser, logout} from "../../src/components/main/More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";
import * as localStore from "../../src/util/localStore";

let timeoutIdScreen = null;
export function start(){
    const isKiosk = Context.instance.uiMode.isKiosk;
    if(isKiosk) return;
    if(Context.instance.user.isAnonymous()) return;
    if(timeoutIdScreen) window.clearTimeout(timeoutIdScreen);
    const applicationReset = Context.instance.config.applicationReset;
    if(!applicationReset.enabled) return;
    const i18n = Context.getInstance().i18n;
    const timeoutInMilliseconds = applicationReset.timeoutMillis;
    const timeoutInMillisecondsPopup = applicationReset.popupMillis;
    const popupHeading = i18n.resetKiosk.prompt;
    let popupContent = i18n.configurator.idleTimeout.appReset.popupMessage;
    watchAppActivity(timeoutInMilliseconds, timeoutInMillisecondsPopup, popupHeading, popupContent);
}

export function stop() {
  if (timeoutIdScreen) window.clearTimeout(timeoutIdScreen);
}

export function signOut(){
    Topic.publish(Topic.SignOutClicked,{});
}

export function watchAppActivity(timeoutInMilliseconds, timeoutInMillisecondsPopup, popupHeading, popupContent){

    let timeoutIdPopup, controller;

    // Start screen timer to check for inactivity on the screen
    const startScreenTimer=()=>{
        clearTimeout(timeoutIdScreen);
        if(!controller){
            timeoutIdScreen = window.setTimeout(doInactive, timeoutInMilliseconds);
        }
    }
    const resetScreenTimer=()=>{
        window.clearTimeout(timeoutIdScreen);
        const applicationReset = Context.instance.config.applicationReset;
        if(applicationReset.enabled) startScreenTimer();
    }

    const resetPopupTimer=()=>{
        if(controller){
            controller.close();
            controller=null;
        }
        window.clearTimeout(timeoutIdPopup);
        window.clearTimeout(timeoutIdScreen);
        startScreenTimer();
    }
    // Set the popup timer when inactivity is detected
    const setPopupTimer=()=>{
        clearTimeout(timeoutIdPopup);
        timeoutIdPopup = window.setTimeout(doInactive, timeoutInMillisecondsPopup);
    }
    const doInactive=()=>{
        if(controller){
            controller.close();
            controller=null;
            let key = null;
            const value = Date.now();
            const isSpacePlanner = Context.instance.appMode.isSP_or_FPE();
            if(!isSpacePlanner) {
                key = localStore.keys.viewerSignOut;
                localStore.setItem(key, value, "toastmsg");
            }
            signOut();
        }else{
            let uiMode = Context.instance.uiMode;
            const isMac = (navigator.platform && /MacIntel/.test(navigator.platform));
            if(uiMode.isIOS || isMac) {
                if(document.visibilityState === "hidden") {
                    let key = null;
                    const value = Date.now();
                    const isSpacePlanner = Context.instance.appMode.isSP_or_FPE();
                    if(!isSpacePlanner) {
                        key = localStore.keys.viewerSignOut;
                        localStore.setItem(key, value, "toastmsg");
                    }
                    signOut();
                }else{
                    showPopup();
                    setPopupTimer();
                }
            }
            else{
                showPopup();
                setPopupTimer();
            }
        }
    }

    const showPopup=()=>{
        let content=  <IdlePopup resetPopupTimer={resetPopupTimer} popupHeading={popupHeading} popupContent={popupContent}/>
        controller = new ModalController({
        onClose:()=> {
            controller=null;
            window.clearTimeout(timeoutIdPopup);
            window.clearTimeout(timeoutIdScreen);
            startScreenTimer()
        }
        });
        controller.show(content);
    }

    resetScreenTimer();

    document.addEventListener("mousemove", resetScreenTimer, false);
    document.addEventListener("mousedown", resetScreenTimer, false);
    document.addEventListener("keypress", resetScreenTimer, false);
    document.addEventListener("touchmove", resetScreenTimer, false);
    document.addEventListener("touchstart", resetScreenTimer, false);
}
