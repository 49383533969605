import React from "react";
import {connect} from "react-redux";

import Rdx from "../../../redux/Rdx";

import { Button } from "../../styles/Assets/tabButton";

class TabButton extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    const rdxKey = this.props.navKey;
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);
    const panelOpenClassName = this.props.panelOpenClassName;
    if (active) {
      Rdx.setValue(this,rdxKey,null);
      document.body.classList.remove(panelOpenClassName);
    } else {
      Rdx.setValue(this,rdxKey,this.props.tabKey);
      document.body.classList.add(panelOpenClassName);
    }
  }

  render() {
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);
    const clear = active? false: true;

    return (
      <Button
        {...this.props}
        clear={clear}
        onClick={this.clicked}
        active={active}
      >
        {this.props.children}
      </Button>
    )
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state,ownProps.navKey)
  }
}

export default connect(mapStateToProps)(TabButton);
