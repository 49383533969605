import BaseClass from "./BaseClass";
import Context from "../context/Context";

export default class DirectionsUtil extends BaseClass {

  _maneuverIconDir;

  convertDistance(val, fromUnits, toUnits) {
    const v = this.toMeters(val,fromUnits);
    return this.fromMeters(v,toUnits);
  }

  convertTime(val, fromUnits, toUnits) {
    const v = this.toMinutes(val,fromUnits);
    return this.fromMinutes(v,toUnits);
  }

  getDistanceUnit(toUnits) {
    const i18n = Context.getInstance().i18n;
    if (toUnits === "esriFeet" || toUnits === "esriNAUFeet") return i18n.directions.units.esriFeet.name;
    else if (toUnits === "esriMeters" || toUnits === "esriNAUMeters") return i18n.directions.units.esriMeters.name;
    else if (toUnits ==="esriMiles" || toUnits === "esriNAUMiles") return i18n.directions.units.esriMiles.name;
    else if (toUnits ==="esriKilometers" || toUnits === "esriNAUKilometers") return i18n.directions.units.esriKilometers.name;
  }

  formatCFDistance(dist, fromUnits, toUnits) {
    let result = "";
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const pattern = i18n.directions.valueUnitsPattern;
    const value = this.convertDistance(dist, fromUnits, toUnits);
    //const units = toUnits.replace("esriNAU","esri").replace("esri","").toLowerCase();
    const units = this.getDistanceUnit(toUnits);

    if (value || value === 0) {
      let places = 0;
      if(toUnits==="esriMiles"|| toUnits==="esriKilometers"){
        places=1;
      }
      const v = lib.dojo.number.format(value, {locale: "root", places: places});
      result = pattern.replace("{value}",v).replace("{units}",units);
    }
    return result;
  }

  formatCFTime(time, fromUnits) {
    let result = "";
    let shortFormat = true;
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const pattern = i18n.directions.valueUnitsPattern;
    const minutes = this.convertTime(time,fromUnits,"esriNAUMinutes");
    if (minutes || minutes === 0) {
      const minUnit = i18n.directions.time.min;
      const minutesUnit = i18n.directions.time.minutes;
      let units;
      if(shortFormat){
        units ="<abbr aria-label=\""+minutesUnit+"\">"+minUnit+"</abbr>";
      }else{
        units =minutesUnit;
      }
      const info = this.roundMinutes(minutes);
      const v = lib.dojo.number.format(info.minutes, {locale: "root", places: info.places});
      result = pattern.replace("{value}",v).replace("{units}",units);
    }
    return result;
  }

  formatDistance(info, dist, shortFormat?, fromUnits?, toUnits?) {
    let result = "";
    shortFormat = true;
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const pattern = i18n.directions.valueUnitsPattern;
    if (!toUnits) toUnits = this.getDistanceUnits(info);
    if (!fromUnits) fromUnits = toUnits;
    const du = i18n.directions.units[toUnits];
    const value = this.convertDistance(dist, fromUnits, toUnits);
    //let units = toUnits.replace("esri", "").toLowerCase();
    let units = this.getDistanceUnit(toUnits);
    
    if (du) {units = shortFormat ? du.abbr : du.name;}
    if (du){
      if(shortFormat){
        units = "<abbr aria-label=\""+du.name+"\">"+du.abbr+"</abbr>";
      }else{
        units = du.name;
      }
    }
    if (value || value === 0) {
      let places = 0;
      if(toUnits==="esriMiles"|| toUnits==="esriKilometers"){
        places=1;
      }
      const v = lib.dojo.number.format(value, {locale: "root", places: places});
      result = pattern.replace("{value}",v).replace("{units}",units);
    }
    //console.log("Result", result);
    return result;
  }

  formatTime(info, time, shortFormat?, fromUnits?): string {
    let result = "";
    shortFormat = true;
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    const pattern = i18n.directions.valueUnitsPattern;

    if (!fromUnits) {
      fromUnits = (this.getDirectionsTimeAttribute(info) || {}).units;
    }
    const minutes = this.convertTime(time,fromUnits,"esriNAUMinutes");
    if (minutes || minutes === 0) {
      const info = this.roundMinutes(minutes);
      const minUnit = i18n.directions.time.min;
      const minutesUnit = i18n.directions.time.minutes;
      let units;
      if(shortFormat){
        units = "<abbr aria-label=\""+minutesUnit+"\">"+minUnit+"</abbr>";
      }else{
        units = minutesUnit;
      }
      const v = lib.dojo.number.format(info.minutes, {locale: "root", places: info.places});
      result = pattern.replace("{value}",v).replace("{units}",units);
    }
    return result;
  }

  getManeuverIconDir() {
    if (!this._maneuverIconDir) {
      const lib = Context.getInstance().lib;
      let locationPath = window.location.pathname.replace(/index.html$/i,"");
      locationPath = locationPath.replace(/kiosk.html$/i,"");
      if (!locationPath.endsWith("/")) locationPath += "/";
      const assetPath = window.location.origin + locationPath + "assets/maneuvers/";
      this._maneuverIconDir = Context.checkMixedContent(assetPath);
    }
    if (this._maneuverIconDir) return this._maneuverIconDir;
    return "";
  }

  roundMinutes(minutes) {
    const info = {
      minutes: minutes,
      places: 0
    };
    const m = minutes;
    if (m < 1) {
      if (m < 0.125) {
        info.minutes = 0;
        info.places = 0;
      } else if (m >= 0.125 && m < 0.375) {
        info.minutes = 0.25;
        info.places = 2;
      } else if (m >= 0.375 && m < 0.625) {
        info.minutes = 0.5;
        info.places = 1;
      } else if (m >= 0.625 && m < 0.875) {
        info.minutes = 0.75;
        info.places = 2;
      } else {
        info.minutes = 1;
        info.places = 0;
      }
    }
    return info;
  }

  getCostAttribute(info,att) {
    const serviceDescription = info.serviceDescription;
    const atts = (serviceDescription && serviceDescription.networkDataset &&
                  serviceDescription.networkDataset.networkAttributes) || [];
    let res;
    for (let i = 0; i < atts.length; i++) {
      if (atts[i].name === att && atts[i].usageType === "esriNAUTCost") {
        res = atts[i];
        break;
      }
    }
    return res;
  }

  getDirectionsLanguage(info?) {
    const lib = Context.getInstance().lib;
    const locale = lib.dojo.kernel.locale;
    if (locale) return locale;
    return null;
  }

  getDirectionsTimeAttribute(info) {
    const routeParams = info.routeParameters || {};
    const serviceDescription = info.serviceDescription;
    const att = (routeParams && routeParams.travelMode && routeParams.travelMode.timeAttributeName) ||
                 routeParams.directionsTimeAttribute || serviceDescription.directionsTimeAttribute;
    return this.getCostAttribute(info,att);
  }

  getDistanceUnits(info) {
    let units = "esriFeet";
    const routeParams = info.routeParameters;
    if (routeParams && routeParams.directionsLengthUnits) {
      switch (routeParams.directionsLengthUnits) {
        case "feet":
          units = "esriFeet";
          break;
        case "esriFeet":
          units = "esriFeet";
          break;
        case "miles":
          units = "esriMiles";
          break;
        case "esriMiles":
          units = "esriMiles";
          break;
        case "meters":
          units = "esriMeters";
          break;
        case "esriMeters":
          units = "esriMeters";
          break;
        case "kilometers":
          units = "esriKilometers";
          break;
        case "esriKilometers":
          units = "esriKilometers";
          break;
        default:
          break;
      }
    }
    return units;
  }

  getToDistanceUnits(meterValue){
    let toUnits="esriMeters";
    const unit=Context.getInstance().user.getUnit();
    if(unit==="metric"){
      if(meterValue >= 100){
        toUnits="esriKilometers";
      }
    }else{
      toUnits="esriFeet";
      let feet=meterValue*3.28084;
      if(feet >= 528){
        toUnits="esriMiles";
      }
    }
    return toUnits;
  }

  getIconUrl(iconName) {
    if (iconName) {
      const iconDir = this.getManeuverIconDir();
      const iconFormat = window.devicePixelRatio === 2 ? "@2x" : "";
      const url = `${iconDir}${iconName}${iconFormat}.png`;
      //console.log("DirectionsUtil::getIconUrl",url);
      return Context.checkMixedContent(url);
    }
    return null;
  }

  getLocaleDistanceUnits() {
    const lib = Context.getInstance().lib;
    if (lib.dojo.kernel.locale === "en-us") {
      return "feet";
    } else {
      return "meters";
    }
  }

  // getManeuverIconPath(maneuverType) {
  //   const lib = Context.getInstance().lib;
  //   const iconName = lib.esri.maneuverUtils.toIconName(maneuverType);
  //   if (iconName) {
  //     const iconDir = this.getManeuverIconDir();
  //     const iconFormat = window.devicePixelRatio === 2 ? "@2x" : "";
  //     return `${iconDir}${iconName}${iconFormat}.png`;
  //   }
  //   if (maneuverType !== "esriDMTDepart" && maneuverType !== "esriDMTStop") {
  //     console.warn("No icon for maneuver type:",maneuverType);
  //   }
  //   return null;
  // }

  isTimeUnits(u) {
    return (u === "milliseconds" || u === "esriNAUSeconds" || u === "esriNAUMinutes" ||
            u === "esriNAUHours" || u === "esriNAUDays");
  }

  fromMeters(val, units) {
    return this.toMeters(val, units, true);
  }

  fromMinutes(val, units) {
    return this.toMinutes(val, units, true);
  }

  toMeters(val, units, inverse?) {
    val = val || 0;
    switch ((units || "").replace("esriNAU", "esri")) {
      case "esriInches":
        val *= Math.pow(0.0254, inverse? -1 : 1);
        break;
      case "esriFeet":
        val *= Math.pow(0.3048, inverse? -1 : 1);
        break;
      case "esriYards":
        val *= Math.pow(0.9144, inverse? -1 : 1);
        break;
      case "esriMiles":
        val *= Math.pow(1609.344, inverse? -1 : 1);
        break;
      case "esriNauticalMiles":
        val *= Math.pow(1851.995396854, inverse? -1 : 1);
        break;
      case "esriMillimeters":
        val /= Math.pow(1000, inverse? -1 : 1);
        break;
      case "esriCentimeters":
        val /= Math.pow(100, inverse? -1 : 1);
        break;
      case "esriKilometers":
        val *= Math.pow(1000, inverse? -1 : 1);
        break;
      case "esriDecimeters":
        val /= Math.pow(10, inverse? -1 : 1);
        break;
      default:
        break;
    }
    return val;
  }

  toMinutes(val, units, inverse?) {
    val = val || 0;
    switch (units) {
      case "milliseconds":
        val /= Math.pow(60 * 1000, inverse? -1 : 1);
        break;
      case "esriNAUSeconds":
        val /= Math.pow(60, inverse? -1 : 1);
        break;
      case "esriNAUHours":
        val *= Math.pow(60, inverse? -1 : 1);
        break;
      case "esriNAUDays":
        val *= Math.pow(60 * 24, inverse? -1 : 1);
        break;
      default:
        break;
    }
    return val;
  }

}
