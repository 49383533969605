import React from "react";

import Warning from "./Warning";
import * as component from "../../../components/util/component";

interface Props {
  issues: any,
  simple?: boolean
}

interface State {
}

export default class Issues extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const { issues, simple } = this.props;
    if (Array.isArray(issues) && issues.length > 0) {
      if (simple) {
        return (
          <ul className="i-issues--warning-simple">
            {issues.map(issue => {
              return <li key={issue.key}><span>{issue.message}</span></li>
            })}
          </ul>
        )
      } else {
        return (
          <ul className="i-issues--warning">
            {issues.map(issue => {
              return <li key={issue.key}><Warning message={issue.message} /></li>
            })}
          </ul>
        )
      }
    }
    return null;
  }

}
