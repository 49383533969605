// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import Planner from "../../base/Planner";
import * as component from "../../../components/util/component";
import * as portalUtil from "../../../util/portalUtil";
import {ListItem} from "../../styles/Common/list";
import * as groupsUtil from "../../base/groupsUtil";
import { SharingLevels } from "../../../common/components/Sharing";
import DeleteIcon from "calcite-ui-icons-react/TrashIcon";

// Component specific modules (Component-styled, etc.)
import {
  ContentWrapper,
  HeaderContainer,
  Header,
  SearchWrapper,
  SubHeaderContainer,
  SortContainer
} from "../../styles/backstageStyles";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../styles/tableStyles";
// App components

import TextField from "../../../common/components/TextField";

// Third-party components (buttons, icons, etc.)
import Select from "calcite-react/Select";
import Tooltip from "calcite-react/Tooltip";
import { MenuItem } from "calcite-react/Menu";
import SortAscendingIcon from "calcite-ui-icons-react/SortAscendingIcon";
import SortDescendingIcon from "calcite-ui-icons-react/SortDescendingIcon";
import OrganizationIcon from "calcite-ui-icons-react/OrganizationIcon";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import UsersIcon from "calcite-ui-icons-react/UsersIcon";
import PlusIcon from "calcite-ui-icons-react/PlusIcon";
import Sharing from "../Modal/Sharing";
import SearchIcon from "calcite-ui-icons-react/SearchIcon";

// JSON

// CSS

export default class OpenPlan extends Component {
  _lastPromise;
  _mounted = false;
  _guidList = []

  constructor(props) {
    super(props);
    this.state = component.newState({
      resultItems: null,
      searchText: null,
      versionInfos: null,
      supportServices: null,
      sortBy: "title",
      sortDir: "asc",
      nextStart: -1
    });
  }

  componentDidMount() {
    this._mounted = true;
    this.query({});
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  deletePlan = (event, item) => {
    event.stopPropagation();
    const i18n = Context.instance.i18n;
    const title = item.title;
    const planTitleString = i18n.spaceplanner.backstage.deletePlan.prompt.replace(
      "{planName}",
      title
    );
    const options = {
      title: i18n.spaceplanner.backstage.deletePlan.caption,
      message: planTitleString,
      okLabel: i18n.spaceplanner.backstage.deletePlan.buttonCaption,
      closeOnOK: true,
      closeOnCancel: true
    };
    ModalController.confirm(options).then(result => {
      if (result.ok) {
        const project = Context.instance.spaceplanner.planner.project;
        let parameter = "";
        if (project && project.isVersioned) {
          const project = Context.instance.spaceplanner.planner.project;
          const versionManager = project.versionedInfo.versionManager;
          const versionInfo = item.versionInfo;
          versionManager
            .deleteVersion(versionInfo)
            .then(() => {
              parameter = {
                searchText: this.state.searchText,
                type: "delete"
              };
              this.query(parameter);
            })
            .catch(ex => {
              let submessage = ex.submessage || ex.message;
              let title =
                i18n.spaceplanner.backstage.deletePlan.errorDeletingTitle;
              let message =
                i18n.spaceplanner.backstage.deletePlan.errorDeletingMessage;
              if (ex.message === "The version could not be located") {
                title =
                  i18n.spaceplanner.backstage.deletePlan.errorDeletePlanTitle;
                message = i18n.spaceplanner.backstage.deletePlan.planNotLocated;
                submessage = null;
              }
              ModalController.alert({
                isError: true,
                title: title,
                message: message,
                submessage: submessage
              });
              this.setState({ isWorking: false });
              console.error("Error deleting plan", ex);
            });
        } else {
          // planGroupUtil.findHostedPlanGroupId(item)
          // .then(portalGroupId => {
          //   return planGroupUtil.deletePlanGroup(portalGroupId)
          // }).then(result => {
            // return this.deletePortalItem(item.portalItem)
          // })
          this.deletePortalItem(item.portalItem).then(() => {
              setTimeout(() => {
                this.query({ searchText: this.state.searchText });
              }, 1000);
            })
            .catch(ex => {
              let submessage = ex.submessage || ex.message;
              ModalController.alert({
                isError: true,
                title:
                  i18n.spaceplanner.backstage.deletePlan.errorDeletingTitle,
                message:
                  i18n.spaceplanner.backstage.deletePlan.errorDeletingMessage,
                submessage: submessage
              });
              this.setState({ isWorking: false });
              console.error("Error deleting plan", ex);
            });
        }
      }
    });
  };

  deletePortalItem = item => {
    return portalUtil.deleteItems(item.id,item.owner);
  };

  disableDelete = item => {
    const user = Context.getInstance().user;
    const isAdmin = user.isAdmin();
    const project = Context.instance.spaceplanner.planner.project;
    const userName = user.portalUser.username;
    if (project && project.isVersioned) {
      const openPlanId = Context.instance.spaceplanner.planId;
      if (openPlanId && item && item.versionInfo) {
        if (openPlanId === item.versionInfo.versionGuid) return true;
      }
      if (isAdmin) return false;
      if (item && item.versionInfo) {
        const owner=item.owner;
        if (userName.toLowerCase() === owner.toLowerCase()) return false;
      }
      return true;
    } else {
      const openPlanId = Context.instance.spaceplanner.planId;
      if (openPlanId && item && item.id) {
        if (openPlanId === item.id) return true;
      }
      if (isAdmin) return false;
      if (item) {
        const itemOwner = item.owner;
        if (userName === itemOwner) return false;
      }
      return true;
    }
  };

  getInfo(item, isVersion, versionManager, supportService) {
    const djLocale = Context.instance.lib.dojo.locale;
    const makeDate = v => {
      const d = new Date(v);
      const s = djLocale.format(d, {
        formatLength: "short"
      });
      return s;
    };
    const info = {
      id: null,
      title: null,
      created: null,
      modified: null,
      summary: null,
      owner: null,
      portalItem: null,
      versionInfo: null,
      supportService: null
    };

    if (isVersion) {
      info.id = item.versionGuid;
      info.title = versionManager.getVersionTitle(item);
      info.created = item.creationDate;
      info.modified = item.modifiedDate;
      info.createdString = makeDate(item.creationDate);
      info.modifiedString = makeDate(item.modifiedDate);
      info.summary = item.description;
      info.owner = versionManager.getVersionOwner(item);
      info.versionInfo = item;
      info.supportService = supportService;
    } else {
      info.id = item.id;
      info.title = item.title;
      info.created = item.created;
      info.modified = item.modified;
      info.createdString = makeDate(item.created);
      info.modifiedString = makeDate(item.modified);
      info.summary = item.snippet;
      info.owner = item.owner;
      info.portalItem = item;
    }
    return info;
  }

  makeVersionedResultItems(searchText, versionManager, versionInfos, supportServices) {
    let lc = null;
    if (typeof searchText === "string" && searchText.length > 0) {
      lc = searchText.toLowerCase();
    }
    const resultItems = [];
    if (Array.isArray(versionInfos)) {
      versionInfos.forEach(versionInfo => {
        if (versionInfo.versionId !== 0) {
          const guid = versionInfo.versionGuid.replace("{","").replace("}","").replace(/-/g,"");
          const tkVersion = OfficePlan.VersionedTypeKeywordVersionPfx + guid;
          const supportIndex = supportServices.findIndex(x => x.typeKeywords.includes(tkVersion));
          let supportService;
          if (supportIndex !== -1) supportService = supportServices[supportIndex];
          let title = versionManager.getVersionTitle(versionInfo);
          let ok = true;
          if (lc) {
            ok = title && title.toLowerCase().includes(lc);
          }
          if (ok) {
            resultItems.push(this.getInfo(versionInfo, true, versionManager, supportService));
          }
        }
      });
    }
    resultItems.sort(this.compareValues(this.state.sortBy, this.state.sortDir));
    //return this.state.sortDir === "asc" ? resultItems : resultItems.reverse();
    return resultItems;
  }

  compareValues = (key, order = "asc") => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      let comparison = 0;

      let varA = a[key];
      let varB = b[key];
      if (typeof a[key] === "string") {
        // varA = a[key].toUpperCase();
        // varB = b[key].toUpperCase();
        varA = a[key];
        varB = b[key];
        comparison = varA.localeCompare(varB);
      } else if (typeof a[key] === "number") {
        varA = a[key];
        varB = b[key];
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
      } else if (a[key] instanceof Date) {
        varA = a[key].getTime();
        varB = b[key].getTime();
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
      } else {
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  };

  onSearchTextChanged = evt => {
    let v = evt.target.value;
    this.setState({ searchText: v });
    this.query({ searchText: v });
  };

  openPlan = (evt, planItem) => {
    evt.stopPropagation();
    let planId, planAppId;
    const planner = Context.instance.spaceplanner.planner;
    if (planItem.versionInfo) {
      planId = planItem.versionInfo.versionGuid;
      planAppId = Planner.getParentAppId(planItem);
    } else {
      const portalItem = planItem.portalItem;
      planId = portalItem.id;
      planAppId = Planner.getParentAppId(portalItem);
    }
    planner.reloadApp(planAppId, planId);
  };

  query(options) {
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned) {
      this.queryVersioned(options);
    } else if (project) {
      this.queryHosted(options);
    }
  }

  queryHosted(options) {
    const portal = Context.instance.getPortal();
    if (!portal) return Promise.resolve();

    const appendQ = (query, q) => {
      if (query.length > 0) query += " AND ";
      query += q;
      return query;
    };

    let props = {
      itemType: "Feature Service",
      start: 1,
      sortField: options.sortBy || this.state.sortBy,
      sortOrder: options.sortDir || this.state.sortDir
    };

    if(this.state.nextStart > 1 && options.isMore){
      props.start=this.state.nextStart;
    }

    let query = props.query || "";
    if (typeof props.itemType === "string" && props.itemType.length > 0) {
      query = appendQ(query, '(type: "' + props.itemType + '")');
    }

    query = appendQ(
      query,
      '(typekeywords: "' + OfficePlan.TypeKeywordFS + '")'
    );

    const considerProject = true;
    const appItem = Context.instance.configuration.appItem;
    if (appItem && considerProject) {
      const subkw = OfficePlan.TypeKeywordParentPfx + appItem.id;
      query = appendQ(query, '(typekeywords: "' + subkw + '")');
    }

    // if (props.orgOnly) {
    //   const user = Context.instance.user;
    //   let orgId = portal.id;
    //   if (user.portalUser) orgId = user.portalUser.orgId;
    //   if (typeof orgId === "string" && orgId.length > 0) {
    //     const qOrg = "((orgid:" + val.escapeForLucene(orgId) + ") -owner:esri_livingatlas)";
    //     query = appendQ(query,qOrg);
    //   }
    // }
    //

    let searchText = this.state.searchText;
    if (options && options.hasOwnProperty("searchText")) {
      searchText = options.searchText;
    }
    if (typeof searchText === "string" && searchText.length > 0) {
      query = appendQ(query, "(" + searchText + ")");
    }

    const queryParams = {
      query: query,
      start: props.start,
      num: 50
    };
    if (props.sortField) {
      queryParams.sortField = props.sortField;
      if (props.sortOrder) queryParams.sortOrder = props.sortOrder;
    }

    let promise = (this._lastPromise = portal.queryItems(queryParams));
    promise
      .then(result => {
        if (!this._mounted) return;
        if (promise !== this._lastPromise) return;

        let nextStart=-1;

        if(result.nextQueryParams){
          nextStart=result.nextQueryParams.start;
        }

        let resultItems = [];
        if (result && result.results) {
          result.results.forEach(item => {
            resultItems.push(this.getInfo(item));
          });

         if (options.isMore) {
            const prevFeatureItems = this.state.resultItems;
            if (prevFeatureItems && prevFeatureItems.length > 0) {
              resultItems = prevFeatureItems.concat(resultItems);
            }
          }
        }
        this.setState({
          isWorking: false,
          resultItems: resultItems,
          nextStart:nextStart
        });
      })
      .catch(ex => {
        console.error("Error querying portal items", ex);
        if (this._mounted && promise === this._lastPromise) {
          this.setState({
            isWorking: false
          });
        }
      });
  }
  
  getSupportServices = async (items, nextStart) => {
    try {
      const portal = Context.instance.getPortal();
      const keyword = OfficePlan.VersionedTypeKeywordFS;
      let query = `(typekeywords: "${keyword}")`;

      // Plan list should only show plans created within the app #6088
      const appItem = Context.instance.configuration.appItem;
      if (appItem) {
        const subkw = OfficePlan.TypeKeywordParentPfx + appItem.id;
        query += ` AND (typekeywords: "${subkw}")`;
      }

      const params = {
        query: query,
        start: nextStart,
        num: 100
      }
      const data = await portal.queryItems(params);
      if (data && data.results) {
        data.results.forEach(item => {
          items.push(item);
        });
        if (data.nextQueryParams) {
          const nextStart = data.nextQueryParams.start;
          if (typeof nextStart === "number" && nextStart > 1) {
            await this.getSupportServices(items,nextStart)
          } else {
            const pfx = OfficePlan.VersionedTypeKeywordVersionPfx;
            items.forEach(item => {
              item.typeKeywords.some(k => {
                if (k && k.startsWith(pfx)) {
                  const guid = k.substring(pfx.length);
                  if (guid) this._guidList.push(guid);
                  return true;
                }
              })
              return false;
            });
            this.setState({supportServices: items});
          }
        } 
      } 
    } catch (error) {
      console.log(error);
    }
  }

  queryVersioned(options) {
    const project = Context.instance.spaceplanner.planner.project;
    const versionManager = project.versionedInfo.versionManager;
    const task = {};
    let searchText = this.state.searchText;
    if (options && options.hasOwnProperty("searchText")) {
      searchText = options.searchText;
    }
    task.versionInfos = this.state.versionInfos;
    let hasVersionInfo = Array.isArray(task.versionInfos);
    let promise = (this._lastPromise = Promise.resolve());
    promise
      .then(() => {
        if (!hasVersionInfo || (options && options.type === "delete")) {
          return versionManager.readVersionInfos(task);
        }
      })
      .then(async () => {
        if (task.versionInfos) {
          this._guidList = [];
          await this.getSupportServices([], 0);
          const guidList = this._guidList; 
          task.versionInfos = task.versionInfos.filter(x => {
            const guid = x.versionGuid.replace("{","").replace("}","").replace(/-/g,"");
            return guidList && guidList.includes(guid);
          });
          task.supportServices = this.state.supportServices;
        }
      })
      .then(() => {
        if (
          this._mounted &&
          promise === this._lastPromise &&
          task.versionInfos
        ) {
          let resultItems = this.makeVersionedResultItems(
            searchText,
            versionManager,
            task.versionInfos,
            task.supportServices
          );
          let state = {
            isWorking: false,
            resultItems: resultItems
          };
          if (!hasVersionInfo) {
            state.versionInfos = task.versionInfos;
          }
          this.setState(state);
        }
      })
      .catch(ex => {
        console.error("Error querying versions", ex);
        if (this._mounted && promise === this._lastPromise) {
          this.setState({
            isWorking: false
          });
        }
      });
  }

  changeSortBy = sortBy => {
    if (sortBy !== this.state.sortBy) {
      let sortDir = "asc";
      if (sortBy === "created" || sortBy === "modified") {
        sortDir = "desc";
      }
      this.setState({
        sortBy: sortBy,
        sortDir: sortDir
      });
      this.query({
        searchText: this.state.searchText,
        sortBy: sortBy,
        sortDir: sortDir
      });
    }
  };

  changeSortDir = () => {
    const sortDir = this.state.sortDir === "asc" ? "desc" : "asc";
    this.setState({
      sortDir: sortDir
    });
    this.query({
      searchText: this.state.searchText,
      sortDir: sortDir
    });
  };

  // 20 ...16
  render() {
    let showMore=false;
    const { resultItems } = this.state;
    const i18n = Context.instance.i18n;
    const listCount = resultItems && resultItems.length === 1
      ? i18n.spaceplanner.backstage.openPlan.listCountSingular
      : i18n.spaceplanner.backstage.openPlan.listCount;
    const countString = resultItems
      ? listCount.replace("{count}", resultItems.length)
      : null;
    
      if(resultItems){
        if(this.state.nextStart > 1) showMore=true;
      }

    //const project = Context.instance.spaceplanner.planner.project;
    //const isVersioned = (project && project.isVersioned);
    const isRTL = Context.getInstance().uiMode.isRtl;
    const showSearch = true;
    return (
      <ContentWrapper>
        <HeaderContainer>
          <Header>{i18n.spaceplanner.backstage.openPlan.caption}</Header>
          <SearchWrapper>
            {showSearch ? (
              <>
                {isRTL && <SearchIcon style={{margin: '15px 0 0 8px'}} size={16}/>}
                <TextField
                  autoFocus
                  search={!isRTL}
                  minimal={isRTL}
                  placeholder={
                    i18n.spaceplanner.backstage.openPlan.searchPlaceholder
                  }
                  onChange={evt => this.onSearchTextChanged(evt)}
                />
              </>
            ) : null}
          </SearchWrapper>
        </HeaderContainer>
        <SubHeaderContainer>
          {countString}
          {this.renderSort()}
        </SubHeaderContainer>
        <TableContainer>
          <Table>
            <Thead>{this.renderItemsHeader()}</Thead>
            <Tbody>{this.renderItems()}</Tbody>
          </Table>
          {showMore && this.renderMore()}
        </TableContainer>
      </ContentWrapper>
    );
  }

  renderMore=()=> {
    const i18n = Context.instance.i18n;

    return (
      <ListItem key={"more"} moreButton>
        <Button disabled={this.state.isDisable}
        onClick={this.moreClicked}>{i18n.general.more}</Button>
      </ListItem>
    );
  }

  moreClicked=()=>{
    if (this.state.isWorking) return;
    this.setState({
      isWorking: true
    });
    this.queryHosted({
      isMore:true
    });
  }
  
  changeSharingLevel = async (e, item, versionInfo) => {
    e.stopPropagation();
    e.persist();
    try {
      const project = Context.instance.spaceplanner.planner.project;
      const portal = Context.getInstance().getPortal();
      const username = portal && portal.user && portal.user.username;
      const i18n = Context.instance.i18n;
      const id = item.portalItem.id;
      const info = {
        access: null,
        shareIds: [],
        unshareIds: [],
        assignedGroups: null,
        portalItem: item.portalItem,
        versionInfo: versionInfo,
        isVersioned: project && project.isVersioned
      }
      info.access = info.portalItem.access;

      const [groups, chips] = await groupsUtil.getGroups(id);
      info.assignedGroups = chips;
      let assignedGroupIds = [];
      if (chips && chips.length > 0) assignedGroupIds = chips.map(group => group.id);

      const modalProps = {
        dialogStyle: {minWidth: '35vw', minHeight: '35vh'},
        title: i18n.configurator.appItem.shareWith.sharingLevel.title,
        noPadding: false,
        className: "i-share-with-modal",
        okLabel: i18n.general.save,
        showOKCancel: item.portalItem.owner === username,
        closeOnOK: true,
        onOK: async () => {
          try {
            let {shareIds, unshareIds} = info;
            if (info.access === SharingLevels.ORGANIZATION) {
              shareIds = [];
              unshareIds = assignedGroupIds;
            }
            if (project.isVersioned) {
              await groupsUtil.updateSharingVersioned(shareIds, unshareIds, info.access, id, info.portalItem, info.versionInfo);
            } else {
              await groupsUtil.updateSharing(shareIds, unshareIds, info.access, id, info.portalItem);
            }
            component.refresh(this);
          } catch (error) {
            console.error(error);
          }
        }
      }
      
      const content = (
        <Sharing 
          horizontal={false}
          shareWith={item.portalItem.access}
          disabled={item.portalItem.owner !== username}
          chips={chips}
          groups={groups}
          disallowEveryone={true}
          onChange={(shareWith, shareIds, unShareIds) => {
            info.access = shareWith;
            if (shareIds) {
              info.shareIds = shareIds.slice();
            }
            if (unShareIds) {
              info.unshareIds = unShareIds.slice();
            }
          }}         
        />
      );
      
      const controller = new ModalController({});
      controller.show(modalProps, content);
    } catch (error) {
      console.log(error);
    }
  }
  
  /**
   * Renders Icons depending on the access level of the plan
   * @param {Obj} item plan feature service item for online or support service for enterprise
   * @returns rendered icons
   */
  accessType = (item, versionInfo) => {
    const i18n = Context.instance.i18n;
    if (item && item.portalItem && item.portalItem.access) {
      switch(item.portalItem.access) {
        case SharingLevels.PRIVATE:
            return (
              <button className="i-button-flat"
                onClick={(e) => this.changeSharingLevel(e, item, versionInfo)}>
                <Tooltip title={i18n.configurator.appItem.shareWith.sharingLevel.owner}>
                  <UserIcon size={16} />
                </Tooltip>
              </button>
            );
        case SharingLevels.ORGANIZATION:
          return (
            <button className="i-button-flat"
              onClick={(e) => this.changeSharingLevel(e, item, versionInfo)}>
              <Tooltip title={i18n.configurator.appItem.shareWith.sharingLevel.organization}>
                <OrganizationIcon size={16} />
              </Tooltip>
            </button>
          )
        case SharingLevels.SHARED:
          return (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <button className="i-button-flat"
                onClick={(e) => this.changeSharingLevel(e, item, versionInfo)}>
                <Tooltip title={i18n.configurator.appItem.shareWith.sharingLevel.groups} >
                  <UserIcon size={16} />
                  <PlusIcon size={16} />
                  <UsersIcon size={16} />
                </Tooltip>
              </button>
            </div>
          )
        default: 
          break;
      }
    }
  }

  renderSort = () => {
    const i18n = Context.instance.i18n;
    let showModifiedDate;
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned){
      showModifiedDate = (<MenuItem value="modified">
      {i18n.spaceplanner.backstage.openPlan.headers.modified}
      </MenuItem>)
    }else{
      showModifiedDate=(<></>)
    }
    const sortDir =
      this.state.sortDir === "asc" ? (
        <Button
          small
          iconButton
          title={i18n.workOrders.sort.ascendingSort} 
          onClick={() => this.changeSortDir()}
          icon={<SortAscendingIcon/>}></Button>
      ) : (
        <Button
          small
          iconButton
          title={i18n.workOrders.sort.descendingSort} 
          onClick={() => this.changeSortDir()}
          icon={<SortDescendingIcon />}>
        </Button>
      );
    return (
      <SortContainer>
        {sortDir}
        <Select
          minimal
          onChange={this.changeSortBy}
          selectedValue={this.state.sortBy}
          title={i18n.sort.fieldSortBy}
        >
          <MenuItem value="title">
            {i18n.spaceplanner.backstage.openPlan.headers.title}
          </MenuItem>
          <MenuItem value="owner">
            {i18n.spaceplanner.backstage.openPlan.headers.owner}
          </MenuItem>
          <MenuItem value="created">
            {i18n.spaceplanner.backstage.openPlan.headers.createdOn}
          </MenuItem>
          {showModifiedDate}
        </Select>
      </SortContainer>
    );
  };

  renderItemsHeader = () => {
    let showModifiedDate;
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned) showModifiedDate=true;
    const i18n = Context.instance.i18n;
    return (
      <Tr>
        <Th>{i18n.spaceplanner.backstage.openPlan.headers.title}</Th>
        <Th>{i18n.spaceplanner.backstage.openPlan.headers.created}</Th>
        {showModifiedDate &&
          <Th>{i18n.spaceplanner.backstage.openPlan.headers.modified}</Th>}
        <Th>
          {i18n.configurator.appItem.shareWith.sharingLevel.title}
        </Th>
        <Th></Th>
      </Tr>
    );
  };
  
  renderItems = () => {
    const i18n = Context.instance.i18n;
    const { resultItems } = this.state;
    return resultItems && resultItems.length > 0 ? (
      resultItems.map(item => this.renderItem(item))
    ) : (
      <Tr>
        <Td colspan="4">{i18n.portalItemBrowser.noMatch}</Td>
      </Tr>
    );
  };

  renderItem = item => {
    const i18n = Context.instance.i18n;
    let showModifiedDate;
    let supportService = {};
    const project = Context.instance.spaceplanner.planner.project;
    if (project && project.isVersioned) {
      showModifiedDate=true;
      supportService.portalItem = item.supportService;
    }
    const dateLabelString = i18n.spaceplanner.backstage.openPlan.dateLabel;
    const createdDateString = dateLabelString.replace("{date}", item.createdString);
    const modifiedDateString = item.modifiedString;
    const description =
      item.summary || i18n.spaceplanner.backstage.openPlan.noDescription;
    const disableDelete = this.disableDelete(item);
    return (
      <Tr key={item.id} onClick={evt => this.openPlan(evt, item)} listitem link>
        <Td>
          <div style={{ fontWeight: "bold" }}>{item.title}</div>
          <i>{description}</i>
        </Td>
        <Td>
          <div>{item.owner}</div>
          <div>{createdDateString}</div>
        </Td>
        {showModifiedDate && <Td>
          <div>{modifiedDateString}</div>
        </Td>}
        {item.portalItem ? (
          <Td>
            {this.accessType(item)}
          </Td> ) : (
          <Td>
            {this.accessType(supportService,item.versionInfo)}
          </Td>
          )
        }
        <Td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button small onClick={evt => this.openPlan(evt, item)}>
              {i18n.spaceplanner.backstage.openPlan.openButton}
            </Button>
            <Button
              small
              iconButton
              onClick={evt => this.deletePlan(evt, item)}
              disabled={disableDelete}
              title={i18n.general.del}
              icon={<DeleteIcon />}
            ></Button>
          </div>
        </Td>
      </Tr>
    );
  };
}
