import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import { debounce } from "../../../miniapps/support/debounceUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";

export interface IGridVMProps {
}

export default class GridVM extends BaseVM {

  private activeSketchViewModel: __esri.SketchViewModel;
  private svmGraphicsLayerId = "indoors-grid-svm";

  constructor(props: IGridVMProps) {
    super(props);
    this.mixinProps(props);
  }

  activateUpdate(graphic?: __esri.Graphic) {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const grid = sourceUtil.getGrid();
    const view = this.getView();
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this.svmGraphicsLayerId);
    graphic = graphic || this.makeUpdateGraphic();
    if (!graphic) return;
    
    graphicsLayer.removeAll();
    graphicsLayer.add(graphic);

    let initialGeometry: __esri.Polyline = (graphic.geometry as __esri.Polyline).clone();
    let offsetX = grid.gridSettings.offsetX;
    let offsetY = grid.gridSettings.offsetY;
    let rotationAngle = grid.gridSettings.rotationAngle;

    const svm = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      updateOnGraphicClick: false
    });

    const updateOptions = {
      tool: "transform", // "transform"|"reshape"|"move"
      enableRotation: true,
      enableScaling: false,
      preserveAspectRatio: true,
      toggleToolOnClick: false,
      enableZ: true
    };
    svm.defaultUpdateOptions = updateOptions;

    const setOffsets = debounce((geometry: __esri.Polyline, dx: number, dy: number, teiType) => {
      grid.setOffsets(dx,dy);
      this.onSettingsChange();
      if (teiType === "move-stop") {
        offsetX = grid.gridSettings.offsetX;
        offsetY = grid.gridSettings.offsetY;
        //this.activateUpdate(graphic);
      }
    }, 1);

    const setRotationAngle = debounce((geometry: __esri.Polyline, angle: number, teiType) => {
      if (angle > 360) angle = angle - 360;
      if (angle < -360) angle = angle + 360;
      grid.setRotationAngle(angle);
      this.onSettingsChange();
      if (teiType === "rotate-stop") {
        rotationAngle = grid.gridSettings.rotationAngle
        //this.activateUpdate(graphic);
      }
    }, 1);

    svm.on("update", (event) => {
      const tei = event.toolEventInfo;
      const teiType = tei && tei.type;

      if (teiType === "move" || teiType === "move-stop") {
        const g = event.graphics[0].geometry;
        const dx = g.extent.center.x - initialGeometry.extent.center.x + offsetX;
        const dy = g.extent.center.y - initialGeometry.extent.center.y + offsetY;
        setOffsets(g,dx,dy,teiType);
      } else if (teiType === "rotate" || teiType === "rotate-stop") {
        const g = event.graphics[0].geometry;
        const angle = tei.angle + rotationAngle;
        setRotationAngle(g,angle,teiType);
      }
      
      if (event.state === "complete") {
        //grid.refresh();
      } else if (event.state === "cancel") {
      }
    });

    svm.on("undo", (event) => {
    });
    svm.on("redo", (event) => {
    });

    svm.update([graphic],updateOptions);
  }

  cancelSketch() {
    const svm = this.activeSketchViewModel;
    if (svm) {
      svm.cancel();
      svm.destroy();
      this.activeSketchViewModel = null;
    }
    mapUtil.removeAllGraphics(this.getView(),this.svmGraphicsLayerId);
  }

  clear() {
    this.cancelSketch();
  }

  destroy() {
    super.destroy();
  }

  makeUpdateGraphic(): __esri.Graphic {
    const polyline = sourceUtil.getGrid().makeUpdateGeometry();
    const graphic = new Context.instance.lib.esri.Graphic({
      geometry: polyline,
      symbol: this.makeUpdateSymbol()
    });
    return graphic;
  }

  makeUpdateSymbol() {
    const symbol: any = {
      type: "simple-line",
      color: [255, 165, 0, 0],
      style: "solid",
      width: 4
    }
    return symbol;
  }

  onSettingsChange() {}

}