import "moment/min/locales";

import Aiim from "../aiim/base/Aiim";
import AppMode from "./AppMode";
import Bluedot from "../aiim/ips/Bluedot";
import CalciteToaster from "../util/CalciteToaster";
import Configuration, { IConfiguration } from "./Configuration";
import EsriLib, { jsapi } from "./EsriLib";
import {Sanitizer} from "@esri/arcgis-html-sanitizer";
import Session from "./Session";
import Speech from "../util/Speech";
import Toaster from "../util/Toaster";
import UIMode from "./UIMode";
import User from "./User";
import * as esriLoader from "esri-loader";
import * as aiimUtil from "../aiim/util/aiimUtil";
import FieldNames from "../aiim/datasets/FieldNames";
import VirtualKeyboard from "../components/main/VirtualKeyboard/VirtualKeyboard";
import type Graphic from "esri/Graphic";
import type Planner from "../spaceplanner/base/Planner";
import type OfficePlan from "../spaceplanner/base/OfficePlan";
import type Views from "./Views";

export default class Context {

  aiim: Aiim;
  appMode: AppMode;
  bluedot: Bluedot;
  config: IConfiguration;
  configuration: Configuration;
  customConfig;
  i18n;
  isKiosk: boolean = false;
  isLocalPortal: boolean = false;
  lib: jsapi = {
    esri: {},
    dojo: {},
    dojox: {},
    indoors: {}
  };
  orgLicenseType;
  portal: __esri.Portal;
  session: Session;
  speech: Speech;
  supports3D = true;
  toaster: CalciteToaster | Toaster;
  uiMode: UIMode;
  user: User;
  versionInfo;
  views: Views;
  hotelingUnits;
  areas;
  routeFromURL;
  virtualKeyboard;

  spaceplanner: {
    activePlan: OfficePlan,
    planId: string,
    planner: Planner,
    dnd?: any
  } = {
    activePlan: null,
    planId: null,
    planner: null
  };
  noIndoorsSubscriptionMsg: string;
  _platformSelfTKN: string;
  
  static htmlSanitizer = new Sanitizer();

  static instance: Context;

  static checkMixedContent(uri) {
    if (typeof window.location.href === "string" &&
        window.location.href.indexOf("https://") === 0) {
      if (typeof uri === "string" && uri.indexOf("http://") === 0) {
        uri = "https:" + uri.substring(5);
      }
    }
    return uri;
  }

  static getInstance(): Context {
    if (!this.instance) {
      this.instance = new this();
      this.instance.virtualKeyboard = new VirtualKeyboard();
      //window.AppContext = this.instance; // TODO temporary
    }
    return this.instance;
  }

  static logToTextarea(msg: string) {
    // <textarea id="tmptxt" style="position:absolute;top:0;left:0;z-index:9999;"></textarea>
    try {
      let textarea = <HTMLTextAreaElement>document.getElementById("tmptxt");
      if (textarea) textarea.value = msg;
    } catch(ex) {}
  }

  static sanitizeHtml(text) {
    if (typeof text !== "string") return text;
    return Context.htmlSanitizer.sanitize(text);
  }

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  getPortal() {
    return this.user && this.user.portal;
  }

  getPortalUrl(): string {
    return Context.checkMixedContent(this.config.portalUrl);
  }

  getSharingUrl(): string {
    return Context.checkMixedContent(this.config.portalUrl + "/sharing");
  }

  isFPE(): boolean {
    return this.appMode.isFPE();
  }

  isSP(): boolean {
    return this.appMode.isSP();
  }

  isSP_or_FPE(): boolean {
    return this.appMode.isSP_or_FPE();
  }

  isViewer(): boolean {
    return this.appMode.isViewer();
  }

  useCalcite(): boolean {
    return this.appMode.useCalcite();
  }

  /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

  _init() {
    let idx, localPortalUrl, jsapiUrl = window.jsapiUrl;
    if (typeof jsapiUrl === "string" && jsapiUrl.indexOf("../..") === 0) {
      // local Portal deployment
      let pathname = window.location.pathname;
      idx = pathname.indexOf("/apps/");
      if (idx > 0) {
        localPortalUrl = pathname.substring(0,idx);
        jsapiUrl = window.jsapiUrl = localPortalUrl + "/jsapi/jsapi4/";
        this.isLocalPortal = true;
      }
      idx = window.location.href.indexOf("/apps/");
      if (idx > 0) localPortalUrl = window.location.href.substring(0,idx);
    }
    const esriLoaderOptions = {
      url: jsapiUrl,
      dojoConfig: window.dojoConfig
    };
      
    return esriLoader.loadModules([
      "dojo/text!./app/config/config.json",
      "dojo/text!./app/config/version.json",
      "dojo/i18n!app/nls/resources.js"
    ],esriLoaderOptions).then(([
      config,
      versionFile,
      i18n
    ]) => {

      this.i18n = i18n;
      this.config = JSON.parse(config);
      this.versionInfo = JSON.parse(versionFile);

      //console.log("this.config.portalUrl",this.config.portalUrl);
      //console.log("localPortalUrl",localPortalUrl);

      if (this.config.portalUrl === "../.." && !localPortalUrl) {
        idx = window.location.href.indexOf("/apps/");
        if (idx > 0) localPortalUrl = window.location.href.substring(0,idx);
      }

      if (this.config.portalUrl === "../.." && localPortalUrl) {
        this.isLocalPortal = true;
        this.config.portalUrl = localPortalUrl;
        //this.config.proxyUrl = localPortalUrl + "/sharing/proxy";
      }
      this.config.portalUrl = Context.checkMixedContent(this.config.portalUrl);

      //console.log("this.config",this.config);
      //console.log("this.config.portalUrl",this.config.portalUrl);
      //console.log("this.config.proxyUrl",this.config.proxyUrl);

      this.configuration = new Configuration();

      const esriLib = new EsriLib();
      this.lib = esriLib.lib;
      return esriLib.load(esriLoaderOptions);

    }).then(() => {

      this.appMode = new AppMode();
      this.appMode.init();
      this.speech = new Speech();
      this.uiMode = new UIMode();
      this.uiMode.init();
      this.isKiosk = this.uiMode.isKiosk;
      this.user = new User();
      this.session = new Session();
      this.toaster = this.useCalcite() ? new CalciteToaster() : new Toaster();
      this.aiim = new Aiim();
      this.appMode.setBrowserTitle();

      if (this.config.proxyUrl) {
        this.config.proxyUrl = Context.checkMixedContent(this.config.proxyUrl);
        this.lib.esri.esriConfig.request.proxyUrl = this.config.proxyUrl;
      }

    }).then(() => {
      return this._loadCustomConfig();
    });
  }

  _load() {
    return this.configuration._load().then(() => {
      this.bluedot = new Bluedot();
      this.appMode.setBrowserTitle();
    });
  }

  async _loadCustomConfig() {
    // VA: Config to allow overlapping office hotel bookings #7337
    try {
      let file = "custom-config.json";
      let path = this.lib.esri.urlUtils.urlToObject(window.location.href).path;
      path = path.replace("/index.html","");
      if (!path.endsWith("/")) path += "/";
      const url = path + file;
      const options = {query: {}, responseType: "text"};
      const result = await this.lib.esri.esriRequest(url,options);
      const data = result && result.data;
      if ((typeof data === "string") && (data.indexOf("{") === 0)) {
        try {
          const json = JSON.parse(data);
          if (!!json.allowHotelMeetingRooms) {
            if (!Array.isArray(json.hotelMeetingRoomUseTypes)) {
              console.error(file,"hotelMeetingRoomUseTypes must be an array");
            } else {
              this.customConfig = json;
              console.log("Loaded",file,this.customConfig);
            }
          }
        } catch(ex2) {
          console.error("Error parsing "+file,ex2);
        }
      }
    } catch(ex) {
      // silent if the custom-config file isn't there
    }
  }

  _loadHotelingUnits(): void {
    if (this.aiim.datasets.units) {
      let reservationType;
      const reservationTypeConfig = this.config && this.config.workspaceReservation;

      if(!reservationTypeConfig) return;
      if(reservationTypeConfig) {
        reservationType = this.config.workspaceReservation.reservationTypeHotel;
      }

      this.hotelingUnits = new Promise((resolve, reject) => {
        let opts = {
          assignedToAppUser: this.aiim.useRestrictedHotelBookings()
        }
        this.aiim.datasets.units.queryAllHotels(opts).then(result => {
          let scheduleUnits: Graphic[] = []
          if (result && result.features) {
            result.features.forEach(unit => {
              const scheduleEmail = aiimUtil.getAttributeValue(unit.attributes, FieldNames.SCHEDULE_EMAIL)
              const areaId = aiimUtil.getAttributeValue(unit.attributes, FieldNames.AREA_ID)
              const useType = aiimUtil.getAttributeValue(unit.attributes, FieldNames.UNITS_USE_TYPE)

              if (
                reservationType === "esri" &&
                areaId &&
                useType
              ) {
                scheduleUnits.push(unit);
              } else if (
                reservationType === "office365" &&
                scheduleEmail &&
                areaId &&
                useType
              ) {
                scheduleUnits.push(unit);
              }
            });
          }
          resolve(scheduleUnits)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }

  _loadAreas(): void {
    if (this.aiim.datasets.areas) {
      this.areas = new Promise((resolve, reject) => {
        let opts = {
          assignedToAppUser: this.aiim.useRestrictedHotelBookings(),
          hotelsOnly: true
        }
        this.aiim.datasets.areas.queryAll(opts).then(result => {
          if(result && result.features) resolve(result.features);
          else resolve([]);
        }).catch(error => {
          reject(error)
        })
      })
    }
  }

}
