import React from "react";

import * as component from "../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-input-message";
import {
  CalciteInputMessage
} from "@esri/calcite-components-react";

interface Props {
  icon?: string,
  message: string,
  status?: "idle" | "invalid" | "valid"
}

interface State {
  message: string
}

export default class Warning extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const { icon, message, status } = this.props;
    if (message) {
      return (
        <div className="i-input-warning-container">
          <CalciteInputMessage 
            status={status || "invalid"} 
            icon={icon || "exclamation-mark-triangle"}
          >
            {message}
          </CalciteInputMessage>
        </div>
      )
    }
    return null;
  }

}
