import React from "react";

import Icons from "../../../../util/Icons";


const CSS = {
  menuOption: "i-menu-option",
  emptyIcon: "svg-icon i-icon-empty"
};

export default class FilterOption extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked() {
    if (this.props.disabled) return;
    if (this.props.onClick) {
      setTimeout(() => {
        this.props.onClick(this);
      },10);
    }
  }

  render() {
    const hashref = "#";
    let chk = null, dataChecked = "n";
    if (!this.props.noIcon) {
      if (this.props.checked) {
        dataChecked = "y";
        chk = (<span key="icon" className={"i-multi-box"}>{Icons.checkMark()}</span>);
      } else {
        chk = (<span key="icon" className={"i-multi-box i-empty"}>{Icons.checkMark()}</span>);
      }
    }

    let cls = CSS.menuOption;
    if (this.props.disabled) cls += " i--disabled";
    const item = (
      <li role="none" className={"i-booking-filters"}>
        <a className={cls} href={hashref} role="checkbox" aria-checked={this.props.checked} tabIndex="0"
            onClick={this.clicked} data-i-checked={dataChecked}>
          {chk}
          <span key="label">{this.props.label}</span>
        </a>
      </li>
    );
    return item;
  }

}
