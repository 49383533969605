import Context from "../../context/Context";
import FieldNames from "../../../src/aiim/datasets/FieldNames";
import BaseClass from "../../util/BaseClass"
import QueryAll from "../../spaceplanner/base/QueryAll";
import * as aiimUtil from "../util/aiimUtil";
import * as dateUtil from "../../../src/components/main/Events/dateUtil";
import Topic from "../../context/Topic";

export default class RouteBarriers extends BaseClass {

    pointLayer;
    polylineLayer;
    polygonLayer;
    pointFeatures;

    constructor(props){
        super(props);
        Topic.subscribe(Topic.UpdatePointBarriers, ()=>{
            if(this.pointLayer) this.loadPoints();
        })
    }

    load(){
        const promise = new Promise ((resolve, reject)=> {
            Promise.resolve().then(()=>{
                this.pointLayer = this.polylineLayer = this.polygonLayer = null;
                this.pointFeatures = null;
                const view = Context.instance.views.mapView;
                const layers = view && view.map.allLayers;
                if(layers) {
                    layers.forEach(layer => {
                        if(layer.type === "feature") {
                            if(layer.title === "Point Barriers") {
                                this.pointLayer = layer;
                            }if(layer.title === "Line Barriers"){
                                this.polylineLayer = layer;
                            }if(layer.title === "Polygon Barriers"){
                                this.polygonLayer = layer;
                            }
                        }
                    });
                }
            }).then(()=>{
                if(this.pointLayer) {
                    return this.loadPoints();
                }
                return;
            }).then(()=>{
                resolve("success");
            }).catch((error)=>{
                reject(error);
            })
        })
        return promise;
    }

    loadPoints() {
        const promise = new Promise((resolve, reject)=> {
            let layer = this.pointLayer;
            let source = {
                layer2D : layer
            }
            layer.when(()=>{
                let levelIdField = Context.instance.aiim.getLevelIdField(layer);
                const lib = Context.getInstance().lib;
                if(levelIdField) {
                    if (!layer.url) return Promise.resolve();
                    const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
                    const query = new Context.instance.lib.esri.Query();
                    query.outFields = ["*"];
                    query.returnGeometry = true;
                    query.returnZ = true;
                    query.where = "1=1";
                    let qaopts = {};
                    const qa = new QueryAll();
                    return qa.execute(url,query,qaopts);
                }
            }).then((result)=> {
                let points = [];
                if(result && result.features) {
                    let temp = result.features;
                    temp.forEach((point)=> {
                        const obj = Context.instance.aiim.getZInfo(source, point);
                        if(obj && obj.levelData) {
                            const zVal = obj.levelData.z;
                            if(typeof zVal === "number") {
                                point.geometry.z = zVal;
                                points.push(point);
                            }
                        }
                        else{
                            // shall we set it to 0 ??
                            point.geometry.z = 0;
                            points.push(point);
                        }
                    })
                }
                return points;
            }).then((pointsArray)=> {
                if(pointsArray && pointsArray.length >0) {
                    this.pointFeatures = pointsArray;
                }
            }).then(()=> {
                resolve();
            }).catch((error)=> {
                reject(error);
            })
        })
        return promise;
    }

    createWhereClause=(layer)=>{
        let currentTime = Date.now();
        let where;
        if(layer && layer.fields && layer.fields.length>0) {
            const startTime = aiimUtil.findField(layer.fields,FieldNames.BARRIER_START_TIME);
            const endTime = aiimUtil.findField(layer.fields,FieldNames.BARRIER_END_TIME);
            const dt = new Date(currentTime);
            const current = dateUtil.getZulu(dt);
            if(startTime && endTime) {
                let where1 = "(("+startTime.name + " <= TIMESTAMP '" + current+"') OR ("+startTime.name+" IS NULL))";
                let where2 = "(("+endTime.name + " >= TIMESTAMP '" + current+"') OR ("+endTime.name+" IS NULL))";
                where = "(" + where1 + " AND " + where2 + ")";
            }else if(startTime) {
                where = "(("+startTime.name + " <= TIMESTAMP '" + current+"') OR ("+startTime.name+" IS NULL))";
            }else if(endTime) {
                where = "(("+endTime.name + " >= TIMESTAMP '" + current+"') OR ("+endTime.name+" IS NULL))";
            }else {
                where = "1=1";
            }
        }
        return where;
    }

    addBarriers(routeParameters, isClosestFacility) {
        this.addPointBarriers(routeParameters, isClosestFacility);
        this.addPolygonBarriers(routeParameters, isClosestFacility);
        this.addPolylineBarriers(routeParameters, isClosestFacility);
    }

    addPointBarriers(routeParameters, isClosestFacility) {
        let layer = this.pointLayer;
        let features = this.pointFeatures;    
        if(features) {
            const t = Date.now();
            features = features.filter((f)=>{
                let t1 = aiimUtil.getAttributeValue(f.attributes, FieldNames.BARRIER_START_TIME);
                let t2 = aiimUtil.getAttributeValue(f.attributes, FieldNames.BARRIER_END_TIME);
                let ok = false;
                if(typeof(t1) === "number" && typeof(t2) === "number"){
                    ok = (t1 <=t) && (t2 >=t);
                }else if(typeof(t1)==="number"){
                    ok = t1 <=t;
                }else if(typeof(t2) === "number") {
                    ok = t2 >= t;
                }else{
                    ok = true;
                }
                if(ok) return f;
                return null;
            })
            if(!isClosestFacility) {
                const lib = Context.getInstance().lib;
                const featureSet = new lib.esri.FeatureSet({
                    features: features
                });
                routeParameters.pointBarriers=featureSet;
            }
            else {
                let barriers = {
                    "features": features
                  };
                routeParameters.barriers = JSON.stringify(barriers);
            }
        }else if(layer) {
            const lib = Context.getInstance().lib;
            let where = this.createWhereClause(layer);
            let url = layer.url + "/" + layer.layerId + "/query?where=" +encodeURIComponent(where)+ "&returnGeometry=true&returnZ=true&outFields=*&f=json";
            const createUrl = aiimUtil.appendTokenToUrl(url);
            let pointBarriersUrl;
            if(!isClosestFacility) {
                pointBarriersUrl = new lib.esri.DataFile({
                    url: createUrl
                });
                routeParameters.pointBarriers = pointBarriersUrl;
            }else {
                pointBarriersUrl = {
                    "url": createUrl
                }
                routeParameters.barriers = JSON.stringify(pointBarriersUrl);
            }
        }
    }

    addPolygonBarriers(routeParameters, isClosestFacility) {
        let layer = this.polygonLayer;
        if(layer) {
            const lib = Context.getInstance().lib;
            let where = this.createWhereClause(layer);
            let url = layer.url + "/" + layer.layerId + "/query?where=" +encodeURIComponent(where)+ "&returnGeometry=true&returnZ=true&outFields=*&f=json";
            const createUrl = aiimUtil.appendTokenToUrl(url);
            let polygonBarriersUrl;
            if(!isClosestFacility) {
                polygonBarriersUrl = new lib.esri.DataFile({
                    url: createUrl
                });
                routeParameters.polygonBarriers = polygonBarriersUrl;
            }else {
                polygonBarriersUrl = {
                    "url": createUrl
                }
                routeParameters.polygonBarriers = JSON.stringify(polygonBarriersUrl);
            }
        }else{
            routeParameters.polygonBarriers = null;
        }
    }

    addPolylineBarriers(routeParameters, isClosestFacility) {
        let layer = this.polylineLayer;
        if(layer) {
            const lib = Context.getInstance().lib;
            let where = this.createWhereClause(layer);
            let url = layer.url + "/" + layer.layerId + "/query?where=" +encodeURIComponent(where)+ "&returnGeometry=true&returnZ=true&outFields=*&f=json";
            const createUrl = aiimUtil.appendTokenToUrl(url);
            let polylineBarriersUrl;

            if(!isClosestFacility) {
                polylineBarriersUrl = new lib.esri.DataFile({
                    url: createUrl
                });
                routeParameters.polylineBarriers = polylineBarriersUrl;
            }else {        
                polylineBarriersUrl = {
                    "url": createUrl
                }
                routeParameters.polylineBarriers = JSON.stringify(polylineBarriersUrl);
            }
        }else{
            routeParameters.polylineBarriers = null;
        }
    }

}