import React, { FormEvent } from "react";
import { FormControl } from "../../../styles/backstageStyles";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import * as groupsUtil from "../../../base/groupsUtil";

import ShareWith, { SharingLevels } from "../../../../common/components/Sharing";
import { IItemProperties, IItemPropertiesProps, IItemPropertiesState, IVersionedItemInfo } from "../../common/types";
import "@esri/calcite-components/dist/components/calcite-input";
import "@esri/calcite-components/dist/components/calcite-input-message";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-icon";
import {
  CalciteInput,
  CalciteInputMessage,
  CalciteInputText,
  CalciteLabel
} from "@esri/calcite-components-react";
import { CalciteInputCustomEvent } from "@esri/calcite-components";

export default class VersionProperties
  extends React.Component<IItemPropertiesProps, IItemPropertiesState>
  implements IItemProperties {

  componentId: string;
  private mounted: boolean = false;

  constructor(props: IItemPropertiesProps) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      title: "",
      summary: "",
      groups: null,
      groupIds: [],
      shareWith: SharingLevels.PRIVATE,
      valid: true
    });
    this.onChangeSummary = this.onChangeSummary.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (typeof this.props.onMount === "function") this.props.onMount(this);
    if (Context.instance.user.canCreateService()) {
      groupsUtil.getGroups().then(res => {
        if (res && this.mounted) {
          const [groups, chips] = res;
          this.setState({ groups: groups });
        }
      }).catch(err => console.log(err));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    component.componentWillUnmount(this);
  }

  getItemInfo(): IVersionedItemInfo {
    const { title, summary, shareWith, groupIds } = this.state;

    const accessPermission = shareWith !== SharingLevels.PRIVATE || groupIds && groupIds.length > 0
      ? SharingLevels.PUBLIC
      : SharingLevels.PRIVATE;

    return {
      title: title.trim(),
      summary: summary.trim(),
      access: shareWith,
      accessPermission,
      groupIds: groupIds && groupIds.length > 0 && shareWith !== SharingLevels.ORGANIZATION
        ? groupIds
        : null
    };
  }

  onChangeSharing(shareWith: SharingLevels, groupIds: string[]) {
    const state: IItemPropertiesState = { shareWith };
    if (shareWith === SharingLevels.ORGANIZATION) {
      state.groupIds = [];
    } else if (groupIds) {
      state.groupIds = groupIds;
    }
    this.setState(state);
  }

  onChangeSummary(evt: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ summary: evt.target.value });
  }

  onChangeTitle = (evt: CalciteInputCustomEvent<void>) => {
    const title = evt.target.value;
    const { valid, validationMessage } = this.validate(title.trim());

    this.setState({ title, valid, validationMessage });
  };

  onSubmit = (evt: FormEvent) => {
    if (evt) evt.preventDefault();
    if (evt) evt.stopPropagation();
    if (this.props.submitHandle) {
      const title = this.state.title.trim();
      const { valid, validationMessage } = this.validate(title.split(" ").join("_"));
      if (valid) {
        this.props.submitHandle();
      }
      this.setState({ valid, validationMessage });
    }
  };
  validate(title: string) {
    const { i18n } = Context.getInstance();
    const length = title ? title.length : 0;
    const valid = length > 0 && length <= 60 &&
      !/[.@~`!#$%\^&*+=\-\[\]\\';,\/{}|\\":<>\?]/g.test(title.split(" ").join("_"));
    const validationMessage = !valid
      ? length <= 0
        ? i18n.general.valueRequired
        : i18n.spaceplanner.backstage.newPlan.errorInvalidPlanPortal
      : "";
    return { valid, validationMessage };
  }
  render() {
    const ctx = Context.getInstance();
    const i18n = ctx.i18n;
    const id = this.componentId;
    const { title, summary, shareWith, valid, validationMessage } = this.state;
    const { horizontal } = this.props;
    const cls = horizontal ? "i--form i--form-horizontal i--new-plan" : "i--form i--new-plan";
    const messageProps = { ...!valid && { active: true } };
    const hideSummary = Context.instance.isFPE();
    return (
      <form className={cls} onSubmit={this.onSubmit}>
        <FormControl>
          <CalciteLabel
            id={id + "-title-label"}
            for={id + "-title"}
            scale="l"
            className="i-new-plan i--form-label">
            <span className="i--required">
              {i18n.configurator.appItem.title}
            </span>
          <CalciteInputText
            id={id + "-title"}
            defaultValue={title}
            maxLength={60}
            scale="l"
            required
            placeholder={i18n.spaceplanner.backstage.newPlan.placeholder}
            onCalciteInputTextChange={this.onChangeTitle}
            />
            {!valid &&
              <CalciteInputMessage scale="l" status="invalid" icon={true} {...messageProps}>
                {validationMessage}
              </CalciteInputMessage>
            }
          </CalciteLabel>
        </FormControl>
        {!hideSummary &&
          <FormControl>
            <CalciteLabel
              id={id + "-summary-label"}
              for={id + "-summary"}
              scale="l"
              className="i-new-plan i--form-label">
              {i18n.configurator.appItem.summary}
            </CalciteLabel>
            <textarea
              id={id + "-summary"}
              maxLength={60}
              onChange={this.onChangeSummary}
              placeholder={i18n.spaceplanner.backstage.newPlan.placeholder}
              value={summary}>
            </textarea>
          </FormControl>
        }
        <ShareWith
          horizontal={horizontal}
          shareWith={shareWith}
          disallowEveryone={true}
          assignedGroups={[]}
          groups={this.state.groups}
          hideShareWithHelp={this.state.groups === null}
          onChange={this.onChangeSharing.bind(this)} />
        {this.props.children}
      </form>
    );
  }
}
