import React from "react";

import Context from "../../../context/Context";
import Icons from "../../../components/util/Icons";
import {ModalController} from "../../../common/components/Modal";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.cancelClicked = this.cancelClicked.bind(this);
  }

  cancelClicked() {
    const chkUrl = () => {
      configUtil.removeEditFromUrl();
    };
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    const initial = this.props.cfg.initialConfigurables;
    const current = configuration.extractConfigurables();
    const a = JSON.stringify(initial);
    const b = JSON.stringify(current);
    if (a !== b) {
      const modalController = new ModalController();

      const onOK = () => {
        Object.assign(Context.instance.config,initial);
        Topic.publish(Topic.ApplyTheme,{
          theme: Context.instance.config.theme
        });
        Topic.publish(Topic.ConfigurationDiscarded,{});
        Topic.publish(Topic.ReloadViews,{});
        //modalController.close();
        Context.instance.uiMode.toggleConfigurator(false);
        Topic.publish(Topic.ConfiguratorClosed,{});
        chkUrl();
      };

      const content = (
        <div className={"i-separated"}>{i18n.configurator.discardPrompt}</div>
      );
      const modalProps = {
        title: i18n.configurator.caption,
        className: "i-configurator-modal i--small-modal",
        showOKCancel: true,
        closeOnOK: true,
        okLabel: i18n.general.discard,
        onOK: onOK
      }
      modalController.show(modalProps,content);
    } else {
      Context.instance.uiMode.toggleConfigurator(false);
      Topic.publish(Topic.ConfiguratorClosed,{});
      chkUrl();
    }
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ViewsReloaded,params => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.instance.i18n;
    const title = i18n.spaceplanner.configurator.caption;

    let minimizeButton, expandCollapseButton;
    const cancelButton = (
       <button className={"i-button-flat"}
        onClick={this.cancelClicked} title={i18n.general.close}>
        {Icons.X()}
      </button>
    );

    return (
      <div className="i-panel-header">
        <span key="title" className="i--title">{title}</span>
        <div key="hdrTools" className="i-panel-header-tools">
          {minimizeButton}
          {expandCollapseButton}
          {cancelButton}
        </div>
      </div>
    );
  }
}
