import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as actionUtil from "./actionUtil";
import * as itemUtil from "../../../aiim/util/itemUtil";
import * as layerUtil from "../../../aiim/util/layerUtil";
import * as three11Util from "../../../aiim/util/three11Util";

export function copyToClipboard(value,toastMessage) {
  if (typeof value !== "string") value = "";

  const tempTextarea = document.createElement("textarea");
  tempTextarea.style.position = "fixed";
  tempTextarea.style.left = "0";
  tempTextarea.style.top = "0";
  tempTextarea.style.opacity = "0";
  tempTextarea.value = value;
  document.body.appendChild(tempTextarea);
  tempTextarea.focus();
  tempTextarea.select();

  // Fix to get copy to clipboard working on mobile devices. GitHub #753
  const range = document.createRange();
  range.selectNodeContents(tempTextarea);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  // Use a big number, to cover anything that could be inside the element.
  tempTextarea.setSelectionRange(0, 999999);

  document.execCommand("copy");
  document.body.removeChild(tempTextarea);

  if (value.length > 0 && toastMessage) {
    Topic.publish(Topic.ShowToast,{message: toastMessage});
  }
}

export function generateMailtoRef(shareUrl, emailSubject) {
  if (!shareUrl) return null;
  const i18n = Context.getInstance().i18n;
  let text = i18n.infoPanel.actions.share.shareLinkText;
  text = text.replace("{shareLinkUrl}", encodeURIComponent(shareUrl));
  let subject = i18n.infoPanel.actions.share.subject;
  let mailto = "mailto:?subject=" + subject + "&body=" + text;
  if (emailSubject) mailto = "mailto:?subject=" + emailSubject + "&body=" + text;
  return mailto;
}

export async function generateShareUrlAsync(item) {
  if (item && item.isValid()) {
  const source = item.getSource();
  const feature = item.getFeature();

  if (source) {
    const hasGeometry = itemUtil.hasGeometry(source,feature);
    const lib = Context.getInstance().lib;
    let x,y,verticalOrder;
    if(hasGeometry){
      const zInfo = Context.getInstance().aiim.getZInfo(source,feature);
      verticalOrder = zInfo && zInfo.levelData && zInfo.levelData.verticalOrder;
      if (typeof verticalOrder !== "number" || isNaN(verticalOrder) || !isFinite(verticalOrder)) {
        verticalOrder = 0;
      }

      const geometryValues = await three11Util.getGeometryValuesAsync(source,feature);
       x = geometryValues.x;
       y = geometryValues.y;
    }

    const href = window.location.href;
    let url = href;
    let idx = url.indexOf("?");
    if (idx !== -1) url = url.substring(0,idx);
    idx = url.indexOf("#");
    if (idx !== -1) url = url.substring(0,idx);
    const obj = lib.esri.urlUtils.urlToObject(href);
    obj.path = url;

    // Remove unneeded characters from obj.path
    if (obj && obj.path) {
      const pathLength = obj.path.length;
      const idxIndex = obj.path.toLocaleLowerCase().lastIndexOf("/index.html");
      const idxKiosk = obj.path.toLocaleLowerCase().lastIndexOf("/kiosk.html");
      if(obj.path.charAt(pathLength - 1) === "/") {
        obj.path = obj.path.substring(0, pathLength - 1);
      }
      else if (idxIndex > 0 && idxIndex === (pathLength - 11)) {
        obj.path = obj.path.substring(0, pathLength - 11);
      }
      else if (idxKiosk > 0 && idxKiosk === (pathLength - 11)) {
        obj.path = obj.path.substring(0, pathLength - 11);
      }
    };

    if (!obj.query) obj.query = {};
    if(!item.isStandAlone()){
      obj.query["itemUniqueIdField"] = item.uniqueIdField;
    }else{
      obj.query["itemUniqueIdField"] = null;
    }
    if(hasGeometry){
      obj.query["x"] = x;
      obj.query["y"] = y;
      obj.query["l"] = verticalOrder;
    }else{
      obj.query["x"] = null;
      obj.query["y"] = null;
      obj.query["l"] = null;
    }

    obj.query["itemSourceKey"] = item.sourceKey;
    obj.query["itemUniqueId"] = item.uniqueId;

    obj.query["rsItemUniqueIdField"] = null
    obj.query["rsItemUniqueId"] = null
    obj.query["rsItemSourceKey"] = null
    obj.query["rsX"] = null
    obj.query["rsY"] = null
    obj.query["rsL"] = null
    obj.query["reItemUniqueIdField"] = null
    obj.query["reItemUniqueId"] = null
    obj.query["reItemSourceKey"] = null
    obj.query["reX"] = null
    obj.query["reY"] = null
    obj.query["reL"] = null

    const layerDetails = layerUtil.getLayerVisibilityStates();
    if(layerDetails && layerDetails.length>0) {
      let layers = [];
      for(let i=0;i<layerDetails.length;i++) {
        const layerId = layerDetails[i].sublayerIds;
        const isMapService = layerDetails[i].isMapService;
        let final1 ={};
        if(isMapService) {
          final1 = {
            "layerId" : layerId[0],
            "subLayerIds" : []
          };
        }else{
          final1 = {
            "layerId" : layerId[0]
          };
        }

        if(layerId.length > 1) {
          let subId = layerId.slice(1,layerId.length);
          final1.subLayerIds = subId;
        }
        layers.push(final1);
      }
      obj.query["visibleLayers"] = JSON.stringify(layers);
    }else{
      obj.query["visibleLayers"] = '';
    }
    let shareUrl = lib.esri.urlUtils.addQueryParameters(obj.path,obj.query)
    shareUrl = shareUrl.replace('&edit=true', '');
    return(shareUrl);
  }
}
return (null);
}
