import React from "react";
import Context from "../../../context/Context";
import SidebarHeader from "../Sidebar/SidebarHeader";
import MoreButton from "./MoreButton";

const CSS = {
  moreMenus: "i-more-menus",
  categories: "i-categories"
};


export default class More extends React.Component {

  renderHeader() {
    const i18n = Context.getInstance().i18n;
    return <SidebarHeader caption={i18n.more.caption}
      closeAriaLabel={i18n.more.closeAriaLabel}
      sidebarKey={this.props.sidebarKey}/>
  }

  render() {
    return(
      <div>
        {this.renderHeader()}
        <ActionsPanel actions={this.props.actions} />
      </div>
    )
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class ActionsPanel extends React.Component {
  renderAction(key, sidebarKey, caption, ariaLabel, icon) {
    return (
      <MoreButton  
        key={key} 
        sidebarKey={sidebarKey} 
        caption={caption} 
        ariaLabel={ariaLabel} 
        iconClassName={icon} />
    )
  }

  render() {
    const list = []

    if (this.props.actions && this.props.actions.length > 0) {
      this.props.actions.forEach(action => {
        const key = action.key
        const sidebarKey = action.sidebarKey
        const caption = action.caption
        const ariaLabel = action.ariaLabel
        const icon = action.icon
        const option = this.renderAction(key, sidebarKey, caption, ariaLabel, icon)
        if (option) list.push(option)
      });
    }

    return (
      <div>
        <div className={CSS.moreMenus}>
          <ul className={CSS.categories}>
            {list}
          </ul>
        </div>
      </div>
    );
  }
}