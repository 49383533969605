import React, { Component } from 'react';

import Context from "../../../context/Context";
import * as component from "../../util/component";

const CSS = {
  lineButton: 'esri-widget--button esri-icon-measure-line',
  areaButton: 'esri-widget--button esri-interactive esri-icon-measure-area',
  clearButton: 'esri-widget--button esri-interactive esri-icon-trash',
  copyButton: 'esri-widget--button esri-interactive esri-icon-duplicate',
  container: 'i-measure-container',
  buttonsContainer: 'i-measure-buttons',
  placeholder: 'i-measure-placeholder',
  copyButtonContainer: 'i-copy-button-container',
  title: 'i-measure-title'
}
export default class Measurement extends Component {
  
  _coreWidget;
  _coreNode;
  _placeholder;
  
  constructor(props) {
    super(props);
    this.state = component.newState({
      widgetState: 'disabled'
    })
  }
  
  componentDidMount() {
    try {
      const ctx = Context.getInstance();
      const lib = ctx.lib;
      const portal = ctx.getPortal();
      const measurementUnits = portal.user.units != null
        ? portal.user.units.toLowerCase()
        : portal.user.region === 'US'
          ? 'english'
          : 'metric';
      this._coreWidget = this.props.coreWidget;
      this._coreWidget.container = this._coreNode;
      this._coreWidget.areaUnit = measurementUnits === 'english' ? 'imperial' : measurementUnits;
      this._coreWidget.linearUnit = measurementUnits === 'english' ? 'imperial' : measurementUnits;
      
      lib.esri.reactiveUtils.watch(() => this._coreWidget.viewModel.state, state => {
        this.setState({widgetState: state});
      });
    } catch (ex) {
      console.log("Error initializing Measurement", ex);
    }

    try {
      const lib = Context.instance.lib;
      const { coreWidget, view } = this.props;
      if (view && view.type === "2d" && view.spatialReference.isWebMercator) {
        component.own(this, lib.esri.reactiveUtils.watch(
          () => coreWidget.activeWidget,
          () => {
            const wgt = coreWidget.activeWidget;

            // jsapi measurement
            // esri/widgets/DistanceMeasurement2D/DistanceMeasurement2DViewModel.ts
            // esri/widgets/DistanceMeasurement2D/DistanceMeasurement2DTool.ts -> createDistanceMeasurementInfo2D
            if (wgt && wgt.declaredClass === "esri.widgets.DistanceMeasurement2D") {
              const vm = wgt.viewModel;
              if (vm && vm.tool) {
                vm.tool._updatePolylines = () => lib.indoors.measureUtil.updatePolylinesPlanar(vm.tool);
              }
              component.own(lib.esri.reactiveUtils.watch(
                () => vm.tool,
                () => {
                  if (vm.tool) {
                    vm.tool._updatePolylines = () => lib.indoors.measureUtil.updatePolylinesPlanar(vm.tool);
                  }
                }
              ))
            }

            // jsapi measurement
            // esri/widgets/AreaMeasurement2D/AreaMeasurement2DViewModel.ts
            // esri/widgets/AreaMeasurement2D/AreaMeasurement2DTool.ts -> createDistanceMeasurementInfo2D
            if (wgt && wgt.declaredClass === "esri.widgets.AreaMeasurement2D") {
              const vm = wgt.viewModel;
              if (vm && vm.tool) {
                vm.tool._updateGraphics = () => lib.indoors.measureUtil.updateAreaGraphicsPlanar(vm.tool);
              }
              component.own(lib.esri.reactiveUtils.watch(
                () => vm.tool,
                () => {
                  if (vm.tool) {
                    vm.tool._updateGraphics = () => lib.indoors.measureUtil.updateAreaGraphicsPlanar(vm.tool);
                  }
                }
              ))
            }

          }
        ))
      }
    } catch (ex) {
      console.log("Error initializing Measurement", ex);
    }

  }
  
  componentWillUnmount() {
    component.componentWillUnmount(this);
  }
  
  /**
   * Change class for widget buttons to visually indicate active tool
   * @param {string} tool - Active tool's icon name 
   */
  _toggleActive = (tool) => {
    const line = document.querySelector('.esri-icon-measure-line');
    const area = document.querySelector('.esri-icon-measure-area');
    if (tool === 'line') {
      line.classList.toggle('active');
      if (area.classList.contains('active')) area.classList.toggle('active');
    } else  if (tool === 'area'){
      area.classList.toggle('active');
      if (line.classList.contains('active')) line.classList.toggle('active');
    } else if (tool === 'clear') {
      if (area.classList.contains('active')) area.classList.toggle('active');
      if (line.classList.contains('active')) line.classList.toggle('active');
    }
  }
  
  distanceMeasurement = () => {
    this._toggleActive('line');
    const type = this.props.view.type;
    this._coreWidget.activeTool = type.toUpperCase() === '2D' ? 'distance' : 'direct-line';
    // Disable map click when measuring tool is active to prevent clicking on features
    this.props.disableMapClick(true);
  }
  
  areaMeasurement = () => {
    this._toggleActive('area');
    this._coreWidget.activeTool = 'area';
    // Disable map click when measuring tool is active to prevent clicking on features
    this.props.disableMapClick(true);
  }
  
  
  clearMeasurements = () => {
    this._toggleActive('clear');
    this._coreWidget.clear();
    this.props.disableMapClick(false);
  }
   
  render() {
    if (!this.props.view) return null;
    const i18n = Context.getInstance().i18n;
    return (
      <div className={CSS.container}>
        <p className={CSS.title}>{i18n.map.measurement.title}</p>
        <div className={CSS.buttonsContainer}>
          <button
          onClick={this.distanceMeasurement}
          className={CSS.lineButton}
          title={i18n.map.measurement.measureDistance}
          ></button>
          <button
          onClick={this.areaMeasurement}
          className={CSS.areaButton}
          title={i18n.map.measurement.measureArea}
          ></button>
          <button
          disabled={this.state.widgetState === 'disabled'}
          onClick={this.clearMeasurements}
          className={CSS.clearButton}
          title={i18n.map.measurement.clearMeasurements}
          ></button>
        </div>
        {this.state.widgetState === 'disabled' && (
          <div className={CSS.placeholder} ref={(node) => this._placeholder = node}>
            <p>{i18n.map.measurement.placeholder}</p>
          </div>
          )
        }
        <div className="coreNode" ref={(node) => this._coreNode = node}></div>
      </div>
    )
  }

}