import React from "react";
import { connect } from "react-redux";
import { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";

import Context from "../../../context/Context";
import {ModalController} from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import SelectPerson from "./SelectPerson";
import SelectUnit from "./SelectUnit";
import Topic from "../../../context/Topic";
import UnassignPrompt from "./UnassignPrompt";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as transactions from "../../base/transaction/transactions";
import { getAttributes, getDuplicateCount, showUnassignModal, unassignAdditionalAssignments } from "./MultipleAssignments/multipleAssignmentsUtil";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";
import TransactionGuard from "../../base/TransactionGuard";

import { Button } from "../../styles/Common/peopleAssignTo";
import Popover from 'calcite-react/Popover';
import Menu, { MenuItem } from "calcite-react/Menu";
import { getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled } from "../../redux";
import { IFeatureItem } from "./PeopleAssignTo";

interface IAreaAssignToProps extends IRdxProps {
  areaItem: IFeatureItem,
  isPopup?: boolean,
  onAssignment?: () => void,
  rdxHotdesksEnabled: boolean,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean,
  tab?: "people" | "units",
}

interface IAreaAssignToToState {
  menuOpen: boolean
}

class AreaAssignTo extends React.Component<IAreaAssignToProps, IAreaAssignToToState>  {

  constructor(props) {
    super(props);
    this.state = component.newState({
      menuOpen: false
    });
  }

  assignPersonToArea = (personFeatureItem, duplicatePeople?) => {
    const areaItem = this.getAreaItem();
    const areaType = this.getAreaType();
    // TODO: Change this areas function
    transactions.assignPeopleToArea([personFeatureItem],areaItem,areaType,duplicatePeople).then(result => {
      Topic.publish(Topic.PlanModified,{
        action: OfficePlan.Action_AssignmentsUpdated
      });
      this.onAssignment();
    }).catch(error => {
      console.error("Error assigning person to area",error);
      Topic.publishErrorUpdatingData(error.submessage);
    });
  }

  duplicatePersonToArea = (personFeatureItem, duplicatePeople?) => {
    const areaItem = this.getAreaItem()
    transactions.addPerson(personFeatureItem, null, areaItem, false, duplicatePeople).then(result => {
      Topic.publish(Topic.PlanModified,{
        action: OfficePlan.Action_AssignmentsUpdated
      });
      this.onAssignment();
    }).catch(error => {
      console.error("Error assigning person to area",error);
      Topic.publishErrorUpdatingData(error.submessage);
    });
  }

  assignToClicked(evt,option) {
    const value = option.value;
    const caption = option.caption || "";
    if (value === "assignToPerson") {
      this.assignToPersonClicked(caption);
    } else if (value === "assignToHotDesk") {
      this.assignToUnitClicked(caption,OfficePlan.SpaceAssignmentTypes.hotdesk);
    } else if (value === "assignToHotel") {
      this.assignToUnitClicked(caption,OfficePlan.SpaceAssignmentTypes.hotel);
    } else if (value === "assignToMeetingRoom") {
      this.assignToUnitClicked(caption,OfficePlan.SpaceAssignmentTypes.meetingroom);
    } else if (value === "assignToNone") {
      this.assignToNoneClicked(caption);
    }
  }

  assignToPersonClicked(title) {
    const i18n = Context.instance.i18n
    SelectPerson.showModal({
      title: title,
      promptOptions: {
        subject: this.getAreaType(),
        subjectItems: [this.getAreaItem()],
        target: "person",
      },
      onOK: (sel) => {
        let objectId = (sel && sel.objectId);
        if (typeof objectId === "number") {
          const source =  officePlanUtil.getPeopleSource();
          const featureItem = queryUtil.makeFeatureItem(source,sel.feature);
          let to = i18n.spaceplanner.multipleAssignments.assignTo.workspaceArea;
          let personAssignments  = null
          const setAssignments = (assignments) => {
            personAssignments = assignments
          }
          const onOK = (unassignAssignments, keepCurrentAssignment) => {
            if (unassignAssignments && keepCurrentAssignment) {
              unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
                this.duplicatePersonToArea(featureItem, duplicatePeople)
              }).catch(e => {
                console.error("Couldn't remove additional assignments", e)
              })
            } else if (unassignAssignments) {
              unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
                this.assignPersonToArea(featureItem, duplicatePeople)
              }).catch(e => {
                console.error("Couldn't remove additional assignments", e)
              })
            } else if (keepCurrentAssignment) {
              this.duplicatePersonToArea(featureItem)
            } else {
              this.assignPersonToArea(featureItem)
            }
          }
          showUnassignModal({
            personFeature: featureItem, 
            assignToFeature: this.getAreaItem(), 
            plural: false, 
            to: to, 
            showKeepCurrentAssignment: true,
            onOK: onOK,
            setAssignments: setAssignments
          })
        }
      }
    });
  }

  assignToUnitClicked(title, asnType) {
    SelectUnit.showModal({
      title: title,
      promptOptions: {
        subject: this.getAreaType(),
        subjectItems: [this.getAreaItem()],
        target: "unit",
      },
      onOK: (sel) => {
        let objectId = (sel && sel.objectId);
        if (typeof objectId === "number") {
          const source =  officePlanUtil.getUnitsSource();
          const featureItem = queryUtil.makeFeatureItem(source,sel.feature);
          this.assignUnitToArea(featureItem,asnType);
        }
      }
    });
  }

  assignToNoneClicked(title) {
    const i18n = Context.instance.i18n;
    const areaItem = this.getAreaItem();
    const areaType = this.getAreaType();
    const promptOptions = {
      subject: areaType,
      subjectItem: areaItem,
    };
    const content = <UnassignPrompt promptOptions={promptOptions}/>;
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: title,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        // this.unassignAll();
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      }
    };
    controller.show(props,content);
  }

  canAddToDuplicates = (duplicatePeople, totalCount, personName, personEmail) => {
    if (totalCount <= 1) return false
    let currentCount = 0
    duplicatePeople.forEach(person => {
      const personAttributes = getAttributes(person)
      const name = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const email = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      if (personName === name && personEmail === email) currentCount++
    })
    if (currentCount === (totalCount - 1)) return false
    else if (currentCount < (totalCount - 1)) return true
    else {
      console.warn("Inconsistent person assignments count")
      return false
    }
  }

  assignUnitToArea(unitFeatureItem,asnType) {
    const areaItem = this.getAreaItem();
    const duplicatePeople = [], singleRecords = []
    const guard = new TransactionGuard({featureItems: [unitFeatureItem]});
    const unitPromise = officePlanUtil.queryPeopleAssignedToUnit(unitFeatureItem).then(people => {
      const peoplePromises = []
      people.forEach(person => {
        const personAttributes = getAttributes(person)
        const personName = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
        const personEmail = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
        const personPromise = getDuplicateCount(personName, personEmail).then(count => {
          if (this.canAddToDuplicates(duplicatePeople, count, personName, personEmail)) {
            duplicatePeople.push(person)
          } else {
            singleRecords.push(person)
          }
        })
        peoplePromises.push(personPromise)
      })
      return Promise.all(peoplePromises)
    })
    unitPromise.then(() => {
      transactions.assignUnitsToArea([unitFeatureItem],areaItem,asnType, {
        duplicates: duplicatePeople,
        singleRecords: singleRecords
      }).then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified,{
          action: OfficePlan.Action_AssignmentsUpdated
        });
        this.onAssignment();
      }).catch(error => {
        guard.close()
        console.error("Error assigning unit to area",error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
    }).catch(e => {
      guard.close()
      console.error("Error assigning unit to area", e);
      Topic.publishErrorUpdatingData(e.submessage);
    })
    
  }

  componentDidMount() {}

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getAreaItem() {
    return this.props.areaItem;
  }

  getAreaType() {
    return "workspace";
  }

  onAssignment() {
    if (typeof this.props.onAssignment === "function") {
      this.props.onAssignment();
    }
  }

  render() {
    return this.renderPopupAssignButton()
  }

  renderPopupAssignButton = () => {
    const tab = this.props.tab
    if (tab === "people") {
      return this.renderPeoplePopupAssignButton();
    } else if (tab === "units") {
      return this.renderUnitPopupAssignButton();
    }
  }

  renderPeoplePopupAssignButton = () => {
    const i18n = Context.instance.i18n;
    const caption = i18n.spaceplanner.workspaceAreas.assignTo.person.caption;
    return (
      <Button className={"i-assign-btn"}
        onClick={e => {
          this.assignToClicked(e, { value: "assignToPerson", caption: caption })
        }}><span>{i18n.spaceplanner.popup.assignBtn}</span>
      </Button>
    )
  }

  renderUnitPopupAssignButton = () => {
    const i18n = Context.instance.i18n
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;

    const closePopover = () => {
      this.setState({
        menuOpen: false
      })
    }

    const togglePopover = () => {
      this.setState({
        menuOpen: !this.state.menuOpen
      })
    }

    const btn = (
      <Button className={"i-assign-btn"}
        onClick={togglePopover}>
       <span>{i18n.spaceplanner.popup.assignBtn}</span>
      </Button>
    )

    const makeMenu = () => {
      const style = { maxWidth: '10rem' }
      const hotdeskCaption = i18n.spaceplanner.units.assignTo.hotdesk.captionSingular
      const hotelCaption = i18n.spaceplanner.units.assignTo.hotel.captionSingular
      const meetingRoomCaption = i18n.spaceplanner.units.assignTo.meetingroom.captionSingular
      return (
        <Menu style={style}>
          {rdxHotdesksEnabled &&
            <MenuItem onClick={e => {
              closePopover();
              this.assignToClicked(e, { value: "assignToHotDesk", caption: hotdeskCaption })
            }}>{i18n.spaceplanner.units.assignTo.hotdesk.label}</MenuItem>
          }
          {rdxHotelsEnabled &&
            <MenuItem onClick={e => {
              closePopover();
              this.assignToClicked(e, { value: "assignToHotel", caption: hotelCaption })
            }}>{i18n.spaceplanner.units.assignTo.hotel.label}</MenuItem>
          }
          {rdxMeetingRoomsEnabled &&
            <MenuItem onClick={e => {
              closePopover();
              this.assignToClicked(e, { value: "assignToMeetingRoom", caption: meetingRoomCaption })
            }}>{i18n.spaceplanner.units.assignTo.meetingroom.label}</MenuItem>
          }
        </Menu>
      )
    }

    return (
      <Popover
        targetEl={btn}
        open={this.state.menuOpen}
        onRequestClose={closePopover}
        positionFixed
      >
        {makeMenu()}
      </Popover>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const props = {
    rdxHotdesksEnabled: getHotdesksEnabled(state),
    rdxHotelsEnabled: getHotelsEnabled(state),
    rdxMeetingRoomsEnabled: getMeetingRoomsEnabled(state)
  };
  return { ...props };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaAssignTo);
