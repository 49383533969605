import Context from "../../context/Context";

export default class ReservationsErrorHandlerMeetingRooms {
  _errors = [];
  _dataset;

  constructor(dataset) {
    this._dataset = dataset;
    this._dataset.layer2D.when(() => this._run());
  }

  _run() {
    const i18n = Context.getInstance().i18n;
    const info = this._dataset.getCapabilityInfo();
    const isMapServiceBased = this._dataset.isMapServiceBased();
    const userHasPrivilege = info.userHasPrivilege;
    // const userCanEdit = info.userCanEdit;
    const supportsEditing = info.supportsEditing;
    const supportsRequiredOperations = info.supportsAddUpdateDelete;

    if (isMapServiceBased) {
        this._addError(i18n.more.bookWorkspace.errors.meetinRooms.notFromFS, false);
    }

    if (!supportsEditing  && !supportsRequiredOperations) {
      this._addError(i18n.more.bookWorkspace.errors.meetinRooms.notEditable, false);
    }

    if (!supportsRequiredOperations) {
      this._addError(i18n.more.bookWorkspace.errors.meetinRooms.insufficentOperations, false);
    }

    if (!userHasPrivilege) {
      this._addError(i18n.more.bookWorkspace.errors.meetinRooms.insufficientUserRole, false);
    }
  }

  _addError(message, hideButton) {
    const error = new ReservationsError(message, hideButton);
    this._errors.push(error);
  }

  getErrors() {
    return this._errors;
  }

  hideButton() {
    return this._errors.some((error) => error.hideButton());
  }
}

class ReservationsError {
  _message;
  _hideButton;

  constructor(message, hideButton) {
    this._message = message;
    this._hideButton = hideButton;
  }

  getErrorMessage() {
    return this._message;
  }

  hideButton() {
    return this._hideButton;
  }
}