import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Rdx from "../../../../redux/Rdx";
import "@esri/calcite-components/dist/components/calcite-button";
import { 
  Alert,
  Error as ModalError,
  IAlertOptions,
  IErrorOptions
} from "./Modal";

export async function confirm(options: IAlertOptions, onMount?: (close: () => void) => void) {
  const promise = new Promise<boolean>((resolve, reject) => {
    const node = document.createElement("div");
    let resolved = false;
    document.body.appendChild(node);
    ReactDOM.render(
      <Provider store={Rdx.store}>
        <Alert open {...options}
          onOk={() => {
            resolved = true;
            unmountNode(node)
            resolve(true);
          }}
          onClose={() => {
            unmountNode(node)
            if (!resolved) resolve(false);
          }}
        >
        </Alert>
      </Provider>,
      node
    );
    typeof onMount === "function" && onMount(() => unmountNode(node));
  });
  return promise;
}

export function makeErrorOptions(title?: string, message?: string, error?: Error): IErrorOptions {
  const errorOptions: IErrorOptions = {
    title: title,
    errors: {
      generic: message,
      detailed: [error && error.message]
    }
  }
  return errorOptions;
}

export function showError(errorOptions: IErrorOptions) {
  const node = document.createElement("div");
  document.body.appendChild(node);
  ReactDOM.render(
    <Provider store={Rdx.store}>
      <ModalError open title={errorOptions.title} errors={errorOptions.errors}
        onCalciteModalClose={() => unmountNode(node)} />
    </Provider>,
    node
  )
}

function unmountNode(node) {
  try {
    if (node && node.parentNode) {
      node.parentNode.removeChild(node);
      ReactDOM.unmountComponentAtNode(node);
    }
  } catch(ex) {
    console.error(ex);
  }

}