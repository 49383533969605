import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import * as aiimUtil from "../../../aiim/util/aiimUtil";

export function makeResultInfo(source,result) {
  //console.log("result",result);
  const resultInfo = {
    exceededTransferLimit: false,
    featureItems: [],
    voField: null
  };
  if (result && result.fields) {
    resultInfo.voField = aiimUtil.findFieldName(result.fields,FieldNames.VERTICAL_ORDER);
  }
  if (result && result.features && result.features.length > 0) {
    const levels = Context.getInstance().aiim.datasets.levels;
    let oidField = result.objectIdField;
    if (!oidField) oidField = aiimUtil.findObjectIdField(result.fields);
    const featureSet = result.toJSON();
    resultInfo.exceededTransferLimit = result.exceededTransferLimit;
    featureSet.features.forEach((feature,index) => {
      if (feature.geometry) {
        if (!feature.geometry.type) {
          feature.geometry.type = result.geometryType;
        }
        if (typeof feature.geometry.hasZ !== "boolean") {
          feature.geometry.hasZ = result.hasZ;
        }
      }
      let key = index;
      if (oidField) {
        key = source.name + "_" + aiimUtil.getAttributeValue(feature.attributes,oidField);
      }
      if (levels) levels.fixZ(source,feature);
      const featureItem = {
        key: key,
        feature: feature
      };
      resultInfo.featureItems.push(featureItem);
    });
  }
  return resultInfo;
}

export function makeWhere(source,subTypeValue) {
  // findSubTypeByValue
  let where = "";
  const subType = source.findSubTypeByValue(subTypeValue);
  if (subType && typeof subType.where === "string" && subType.where.length > 0) {
    where = subType.where;
    return where;
  }
  if (subTypeValue !== null && subTypeValue !== undefined) {
    const field = source.subCategoryField;
    if (field) {
      let value = subTypeValue;
      if (typeof value === "string") {
        value = value.replace("'","''");
        where = "(" + field + " = '" + value + "')";
      } else {
        where = "(" + field + " = " + value + ")";
      }
    }
  }
  if (where.length === 0) {
    if (source.layer2D) {
      const orig = source.layer2D.xtnOriginalDefinitionExpression;
      const expr = source.layer2D.definitionExpression;
      if (typeof orig === "string" && orig.length > 0) {
        if (orig !== "__none__") where = orig;
      } else if (typeof expr === "string" && expr.length > 0) {
        where = expr;
      }
    }
  }
  if (where.length === 0) where = "1=1";
  return where;
}

export function queryFeatures(source,subTypeValue,isMore,lastFeatureCount,sortOptions) {
  const lib = Context.getInstance().lib;
  const url = Context.checkMixedContent(source.url);
  const task = new lib.esri.QueryTask({url: url});
  const query = new lib.esri.Query();
  const view = Context.getInstance().views.mapView;
  let orderByField = source.displayField;
  query.returnGeometry = true;
  query.returnZ = true;
  query.outFields = ["*"];
  query.where = makeWhere(source,subTypeValue);
  if (sortOptions && sortOptions.sortBy && sortOptions.sortBy !== "distance") {
    orderByField = sortOptions.sortBy;
    if (sortOptions.sortDir === "desc") orderByField += " DESC";
  }
  if (orderByField) query.orderByFields = [orderByField];
  if (view) query.outSpatialReference = view.spatialReference;
  if (isMore) {
    query.start = lastFeatureCount;
    query.num = 10000;
  }
  return task.execute(query);
}
