import React from "react";

import { Item } from "../../styles/Common/menuItem";

export default class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  clicked(event) {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(event, this, this.props.value);
    }
  }

  render() {
    return (
      <Item
        {...this.props}
        onClick={this.clicked}
        role="menuitem link"
        tabIndex="0"
      >
        {this.props.children}
      </Item>
    );
  }
}
