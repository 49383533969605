import styled from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";
import {
    TabNav as CalciteTabNav,
    TabTitle as CalciteTabTitle
} from "calcite-react/Tabs";

const TabNav = styled(CalciteTabNav)`
  justify-content: space-between;
  padding: 0 ${(props) => unitCalc(props.theme.baseline, 2, "/")};
`;

const TabTitle = styled(CalciteTabTitle)`
  flex-grow: 1;
  text-align: center;
`;

export { TabNav, TabTitle };