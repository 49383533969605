import { CalciteTheme } from "calcite-react/CalciteThemeProvider";
import { unitCalc } from "calcite-react/utils/helpers";

const themeColor = "#007471";
const themeColorHighlight = "c7ecec";
const themeColorDark = "#004d4c"; 
const buttonColor = themeColorDark;
const buttonColorHover = themeColor;
const themeColorViewer = "#005e95";
const backgroundColor = "#F8F8F8";
const backgroundAccentColor = "#E1E1E1";
const textColor = "#202020";
const textColorLight = "#606060";

export const Theme = {
  ...CalciteTheme,
  palette: {
    ...CalciteTheme.palette,
    sectionGrey: buttonColor,
    background: CalciteTheme.palette.offWhite,
    themeColor: themeColor,
    themeColorDark: themeColorDark,
    themeColorHighlight: themeColorHighlight,
    themeColorViewer: themeColorViewer,
    textColor: textColor,
    textColorLight: textColorLight,
    backgroundColor: backgroundColor,
    backgroundAccentColor: backgroundAccentColor,
    blue: buttonColor,
    lightBlue: buttonColorHover,
    darkBlue: buttonColor,
    semantic: {
      noAssignment: "#F89926"
    }
  },
  type: {
    ...CalciteTheme.type
  },
  levels: {
    dialog: 10000,
    backstage: 1000,
    top: 100,
    popup: 80,
    mapUI: 80,
    middle: 10,
    bottom: 1
  },
  panelMargin: unitCalc(CalciteTheme.baseline, 2, "/"),
  panelPadding: unitCalc(CalciteTheme.baseline, 2, "/"),
  sideNavWidth: "3.425rem",
  sidePanelWidth: "19.595rem",
  boxShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
  border: "1px solid #eaeaea"
};

export function updateCalciteReactTheme(context) {
  if (context.isFPE()) {
    const themeColor = "#005E95";
    const themeColorHighlight = "#006DAD";
    const themeColorDark = "#004D7A";
    const buttonColor = themeColorDark;
    const buttonColorHover = themeColor;
    const themeColorViewer = "#005e95";
    const backgroundColor = "#F8F8F8"; 
    const backgroundAccentColor = "#E1E1E1"; 
    const textColor = "#4A4A4A";
    const textColorLight = "#606060";
    Theme.palette.sectionGrey = buttonColor;
    Theme.palette.background = CalciteTheme.palette.offWhite;
    Theme.palette.themeColor = themeColor;
    Theme.palette.themeColorDark = themeColorDark;
    Theme.palette.themeColorHighlight = themeColorHighlight;
    Theme.palette.themeColorViewer = themeColorViewer;
    Theme.palette.textColor = textColor;
    Theme.palette.textColorLight = textColorLight;
    Theme.palette.backgroundColor = backgroundColor;
    Theme.palette.backgroundAccentColor = backgroundAccentColor;
    Theme.palette.blue = buttonColor;
    Theme.palette.lightBlue = buttonColorHover;
    Theme.palette.darkBlue = buttonColor;
  }
}
