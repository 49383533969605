import Context from "../context/Context";

/**
 * @returns Symbol that will be used to identify available hotels
 */
export function makeHotelMarkerSymbol() {
  const lib = Context.getInstance().lib;
  return new lib.esri.PictureMarkerSymbol({
    width: 23,
    height: 23,
    yoffset: 12,
    url: "assets/available_unit_pin.png"
  });
}

/**
 * @returns Symbol that will be used when identifying a feature (and the Info Panel is open)
 */
export function makeIdentifySymbol() {
  const lib = Context.getInstance().lib;
  return new lib.esri.PictureMarkerSymbol({
    width: 23,
    height: 23,
    yoffset: 12,
    url: `data:image/svg+xml;base64,${btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" width="512" height="512"><g><g><path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035    c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z" fill="#FF3F00"/></g></g></svg>'
    )}`
  });
}

/**
 * @returns Symbol that will be used to identify a realtime location
 */
export function makeRealtimeSymbol() {
  const lib = Context.getInstance().lib;
  return new lib.esri.PictureMarkerSymbol({
    width: 23,
    height: 23,
    yoffset: 12,
    url: `data:image/svg+xml;base64,${btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" width="512" height="512"><g><g><path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035    c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z" fill="#007ac1"/></g></g></svg>'
    )}`
  });
}

/**
 * @returns You are here symbol for Kiosk2D
 */
export function makeYouAreHereSymbol2D() {
  const lib = Context.getInstance().lib;
  return new lib.esri.PictureMarkerSymbol({
    width: 36,
    height: 36,
    url: `data:image/svg+xml;base64,${btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="svg-icon"><path d="M16.043.367L19.813 12H32l-9.859 7.172 3.789 11.625-9.887-7.193-9.889 7.193 3.787-11.625L0 12h12.271z" fill="#005e95"/></svg>'
    )}`
  });
}

/**
 * @returns You are here symbol for Kiosk3D
 */
export function makeYouAreHereSymbol3D() {
  const lib = Context.getInstance().lib;
  return new lib.esri.PictureMarkerSymbol({
    width: 36,
    height: 36,
    url: `data:image/svg+xml;base64,${btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="svg-icon"><path d="M16.043.367L19.813 12H32l-9.859 7.172 3.789 11.625-9.887-7.193-9.889 7.193 3.787-11.625L0 12h12.271z" fill="#005e95"/></svg>'
    )}`
  });
}

export function getCommentsMarkerPath() {
  return "M 16,21.65 h 0 v -16 h 0 C 15.72,-1.83 0.34,-1.94 0,5.65 H 0 v 16 H 0 l 8,8 l 8,-8 Z M 8,5.15 C 13,5.08 13.11,13 8,13 C 2.92,13.06 3,5 8,5.15 Z";
}