import { config } from "../../../../../../auth/Office365Config";
import { AuthResponse, UserAgentApplication } from "msal";
import Context from "../../../../../../context/Context";
import * as shareUtil from "../../../../InfoPanel/shareUtil";
import * as dateUtil from "../../../../Events/dateUtil";

import BookingSystem, {
  IBookingSystem,
  ICheckAvailabilityParams,
  ICommonBookingProps,
  IOffice365BookingParams,
  IOffice365BookingTask,
  IScheduleItem
} from "./BookingSystem";
import ModalController from "../../../../../../common/components/Modal/ModalController";
import moment, { Moment } from "moment";
import { nextTmpId } from "../../../../../util/component";
import { getAttributeValue } from "../../../../../../aiim/util/aiimUtil";
import FieldNames from "../../../../../../aiim/datasets/FieldNames";
import type { AuthOptions } from "msal/lib-commonjs/Configuration";
import { Client, FetchOptions, Options } from "@microsoft/microsoft-graph-client";
import { DateTimeTimeZone, ScheduleInformation, Event, Extension, NullableOption } from "@microsoft/microsoft-graph-types";
import { IBookingDateFilter } from "../../../../Events/BookingDateFilter";
export interface IIndoorsExtension extends Extension {
  areaId?: string,
  levelId: string,
  scheduleEmail: string,
  unitId: string,
  unitName: string
}
export interface IIndoorsEvent extends Event {
  extensions: NullableOption<IIndoorsExtension[]>,
  masterEvent?: IIndoorsEvent  
}
export type DateAndTimeZone = Omit<DateTimeTimeZone, "dateTime"> & {
  dateTime: string | Date
}
export interface IScheduleParams {
  id?: string,
  availabilityViewInterval?: number,
  schedules: string[],
  startTime: DateAndTimeZone,
  endTime: DateAndTimeZone
}
export interface IBatchRequest {
  id: string,
  method: string,
  headers: Record<string, any>,
  url: string,
  body: any
}
export interface IBatchResponse {
  responses: {
    id: string,
    status: number,
    headers: Record<string, any>,
    body: {
      value: ScheduleInformation[]
    }
  }[]
}
export default class Office365 extends BookingSystem {
  static instance: IBookingSystem;

  format: string;
  use12Hours: boolean;
  userAgentApplication: UserAgentApplication;

  constructor() {
    super();
    this.type = "office365";
    this.makeUserAgent();
  }

  static appStarting() {
    const key = "indoors-last-href";
    const split = window.location.href.split('#')
    const hash = window.location.hash;
    if (hash &&
       (hash.includes("id_token") || hash.includes("access_token")) &&
       (split.length === 2) &&
       !window.location.href.includes("appid")) {
      const href = window.localStorage.getItem(key);
      window.localStorage.removeItem(key);
      if (href) {
        window.location.href = href;
      }
    } else {
      window.localStorage.removeItem(key);
    }
  }

  static preLogin() {
    const key = "indoors-last-href";
    const config = Context.instance.config;
    if (config.workspaceReservation && config.workspaceReservation.appId) {
      window.localStorage.setItem(key,window.location.href);
    }
  }

  static getEventStatus = (m365Event: IIndoorsEvent, scheduleEmail: string) => {

    let emailMatch = false, isValidAttendee = false;
    const statusDetails: { status: string, message: string } = {
      status: null,
      message: null
    }
    const attendees = (m365Event && m365Event.attendees) || [];
    const i18n = Context.getInstance().i18n;
    if ((typeof scheduleEmail === "string") && scheduleEmail && Array.isArray(attendees)) {
      const lc = scheduleEmail.toLowerCase();
      for (let i=0;i<attendees.length; i++) {
        const attendee = attendees[i];
        if (attendee && attendee.emailAddress && (typeof attendee.emailAddress.address === "string")) {
          isValidAttendee = true;
          if (lc === attendee.emailAddress.address.toLowerCase()) {
            emailMatch = true;
            const status = attendee.status?.response?.toLowerCase();
            statusDetails.status = status;
            switch (status) {
              case "accepted":
                statusDetails.message = i18n.meetingRooms.attendeeStatus.accepted;
                break;
              case "declined":
                statusDetails.message = i18n.meetingRooms.attendeeStatus.declined;
                break;
              case "tentativelyaccepted":
                statusDetails.message = i18n.meetingRooms.attendeeStatus.tentativelyAccepted;
                break;
              case "none":
              case "notresponded":
                const masterStatus = m365Event.masterEvent?.attendees?.find(a =>
                  a.emailAddress?.address?.toLowerCase() === lc)?.status?.response;
                if (masterStatus === "accepted" && m365Event.type === "exception") {
                  statusDetails.status = masterStatus;
                  statusDetails.message = i18n.meetingRooms.attendeeStatus.accepted;
                } else {
                  statusDetails.message = i18n.meetingRooms.attendeeStatus.none;
                }
                break;
              default:
                statusDetails.message = status;
                break;
            }
          }
        }
      }
    }
    if (isValidAttendee && emailMatch) return statusDetails;
    else if (isValidAttendee && !emailMatch) {
      statusDetails.status = "mismatchEmail";
      statusDetails.message = i18n.meetingRooms.attendeeStatus.emailMismatch;
      return statusDetails;
    }
    else return statusDetails;
  } 

  static wasDeclined = (m365Event) => {
    let declined = false;
    const extension = m365Event && m365Event.extensions && m365Event.extensions[0];
    const scheduleEmail = extension && extension.scheduleEmail;
    const attendees = (m365Event && m365Event.attendees) || [];
    if ((typeof scheduleEmail === "string") && scheduleEmail && Array.isArray(attendees)) {
      const lc = scheduleEmail.toLowerCase();
      attendees.some(attendee => {
        if (attendee && attendee.emailAddress && (typeof attendee.emailAddress.address === "string")) {
          if (lc === attendee.emailAddress.address.toLowerCase()) {
            const status = attendee.status && attendee.status.response;
            if (status === "declined") declined = true;
            return true;
          }
        }
        return false;
      })
    }
    return declined;
  }

  makeUserAgent(updatedAppId?: string, updatedTenantId?: string) {
    const currentURI = window.location.href.split('?')[0];
    // Check configurator
    const configuration = Context.instance.configuration;
    const configurables = configuration.extractConfigurables();
    const appId = updatedAppId || (configurables.workspaceReservation && configurables.workspaceReservation.appId);
    const tenantId = updatedTenantId || (configurables.workspaceReservation && configurables.workspaceReservation.tenantId);
    const isSingleTenant = configurables.workspaceReservation && configurables.workspaceReservation.isSingleTenant;

    const auth: AuthOptions = {
      clientId: appId,
      redirectUri: currentURI,
      postLogoutRedirectUri: currentURI
    };

    const authority = tenantId && `https://login.microsoftonline.com/${tenantId}/`;
    if (authority && isSingleTenant) {
      auth.authority = authority;
    }

    this.userAgentApplication = new UserAgentApplication({
      auth,
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    });
  }

  updateAppId(appId) {
    this.makeUserAgent(appId, null);
  }

  updateTenantId(tenantId) {
    this.makeUserAgent(null, tenantId);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Office365()
    }
    return this.instance
  }

  getLoggedInUser = () => {
    return this.userAgentApplication.getAccount()
  }

  login = () => {
    Office365.preLogin();
    return new Promise((resolve, reject) => {
      const loginRequest = {
        scopes: config.scopes
      }

      const authCallback = (error, response) => {
        if (response) {
          resolve(response)
        }
        else {
          reject(error)
        }
      }
      this.userAgentApplication.handleRedirectCallback(authCallback)
      const tryRedirect = () => {
        return this.userAgentApplication.loginRedirect(loginRequest)
      }

      this.userAgentApplication.loginPopup(loginRequest)
        .then(function (loginResponse) {
          resolve("Success")
        }).catch(function (error) {
          console.error(error);
          console.log("error.errorCode",error.errorCode)

          // M365: User isn't notified when pop-up blocker is enabled (Firefox and Safari) #5179
          if (error.errorCode === "popup_window_error" || error.errorCode === "login_progress_error") {
            console.log("There was a loginPopup error",error.errorCode);
            const i18n = Context.instance.i18n;
            ModalController.showMessage(i18n.msal.popupWindowError,i18n.general.warning);

          } else if (error.errorCode !== "user_cancelled") {
            tryRedirect();
          }
        })
    })
  }

  getAccessToken = () => {
    return new Promise<AuthResponse>((resolve, reject) => {
      const accessTokenRequest = {
        scopes: config.scopes
      }
      const authCallback = (error, response) => {
        if (response) {
          if (!response.accessToken) {
            this.userAgentApplication.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
              resolve(accessTokenResponse)
            }).catch(e => {
              reject(e)
            })
          } else {
            resolve(response)
          }
        }
        else {
          reject(error)
        }
      }
      this.userAgentApplication.handleRedirectCallback(authCallback)

      const tryPopup = () => {
        return this.userAgentApplication.acquireTokenPopup(accessTokenRequest)
      }

      const tryRedirect = () => {
        return this.userAgentApplication.acquireTokenRedirect(accessTokenRequest)
      }

      this.userAgentApplication.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
        resolve(accessTokenResponse)
      }).catch(function (error) {
        tryPopup().then(function(accessTokenResponse) {
          resolve(accessTokenResponse)
        }).catch(function (error) {
          console.error(error);
          if (error.errorCode !== "user_cancelled") {
            tryRedirect();
          }
        })
      })
    })
  }

  getAuthenticatedClient = (accessToken: AuthResponse, fetchOptions?: FetchOptions) => {
    const options: Options = {
      debugLogging: true,
      authProvider: (done) => {
        done(null, accessToken.accessToken)
      }
    };
    if (fetchOptions) {
      options.fetchOptions = { ...fetchOptions };
    }
    const client = Client.init(options);

    return client;
  }

  getBookingSchedule = async (params: IScheduleParams | IScheduleParams[], signal?: AbortSignal): Promise<{ value: ScheduleInformation[] }> => {
    const accessToken = await this.getAccessToken();
    return new Promise(async (resolve, reject) => {
      const client = this.getAuthenticatedClient(accessToken, { signal });

      const adjustStartTime = (param: IScheduleParams): IScheduleParams => {
        // add a millisecond to each start time, issue:
        // Unable to make consecutive bookings in a hotel unit with Office365 #4399
        let v = param.startTime?.dateTime;
        if (v && typeof v === "string") {
          if (v.substring(v.length - 1, v.length) === "Z") {
            v = moment(v, moment.ISO_8601).add(1, "milliseconds").toISOString();
            param.startTime.dateTime = v;
          }
        } else if (v && typeof (v as Date).getTime === "function") {
          v = moment(v).add(1, "milliseconds").toDate();
          param.startTime.dateTime = v;
        }
        return {
          ...param,
          availabilityViewInterval: 5,
          schedules: param.schedules,
          startTime: param.startTime,
          endTime: param.endTime
        };
      }
      if (Array.isArray(params)) {
        const scheduleInformations = params.map(adjustStartTime);
        const allRequests = {
          requests: scheduleInformations.map(({ id, ...body }) => ({
            id,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: "/me/calendar/getSchedule",
            body
          }))
        }
        const results: { value: ScheduleInformation[] } = { value: [] };
        const MAX_RETRIES = 5;
        const retryAttempts = 0;
        const delay = async (seconds: number): Promise<void> =>
          new Promise(resolve => setTimeout(resolve, seconds * 1000));

        const executeWithRetry = async (requests: IBatchRequest[], retryAttempts: number): Promise<void> => {
          if (requests.length && retryAttempts < MAX_RETRIES && !signal?.aborted) {
            client.api("/$batch").post({ requests }).then(async ({ responses }: IBatchResponse) => {
              const retries: IBatchRequest[] = [];
              responses.forEach(response => {
                if (response.status === 200) {
                  results.value.push(...response.body.value);
                } else if (response.status === 429) { // throttled
                  const originalRequest = allRequests.requests.find(r => r.id === response.id);
                  if (originalRequest) {
                    retries.push(originalRequest);
                  }
                }
              });
              if (retries.length) {
                ++retryAttempts;
                const retryAfter = responses
                  .filter(r => r.status === 429)
                  .map(r => Number(r.headers["Retry-After"]))
                  .sort((a, b) => a - b)
                  .reverse()[0] ?? 5; // default to a 5 second delay
                await delay(retryAfter);
                return await executeWithRetry(retries, retryAttempts);
              } else {
                resolve(results);
              }
            }).catch(e => reject(e));
          }
        }
        executeWithRetry(allRequests.requests, retryAttempts);
      } else {
        const scheduleInformation = adjustStartTime(params);
        client.api('/me/calendar/getSchedule')
          .post(scheduleInformation)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }
    });
  }

  checkAvailability = (
    scheduleEmails: string[],
    checkIn: string,
    checkOut: string,
    ignoreObjectIds,
    params: ICheckAvailabilityParams,
    signal?: AbortSignal
  ): Promise<ScheduleInformation[]> => {
    const mCheckIn = moment(checkIn);
    const mCheckOut = moment(checkOut);
    if (params?.recurrence?.enabled && params.recurrence.modified !== "occurrence") {
      const recurrence = params.recurrence
      const recurrenceDates = this.getRecurringDates(recurrence, mCheckIn, mCheckOut);
      return this._checkAvailability(scheduleEmails, recurrenceDates, signal);
    } else {
      return this._checkAvailability(scheduleEmails, [{ start: mCheckIn, end: mCheckOut }], signal);
    }
  }
  private _checkAvailability = (scheduleEmails: string[], dates: { start: Moment, end: Moment }[], signal: AbortSignal) => {
    return this.getAccessToken().then(async token => {
      if (token && dates?.length && (!signal?.aborted)) {
        const params = [...this._getScheduleParams(scheduleEmails, dates).entries()].map(kvp => kvp[1]).flat();
        const BATCH_SIZE = 20;
        const finalResults: ScheduleInformation[] = [];
        const chunks: IScheduleParams[][] = [];
        for (let i = 0; i < params.length; i += BATCH_SIZE) {
          chunks.push(params.slice(i, i + BATCH_SIZE));
        }
        const stack = [...chunks];
        const process = (availableUnits: ScheduleInformation[]) => {
          // @ts-ignore
          const error = availableUnits.error;
          if (error) {
            throw error;
          } else {
            availableUnits.forEach(unit => finalResults.push(unit));
          }
        }
        if (stack.length === 1 && stack[0].length === 1) {
          // no need to batch if only one request parameter
          process((await this.getBookingSchedule(stack[0][0], signal)).value);
        } else {
          for await (const batch of stack) {
            process((await this.getBookingSchedule(batch, signal)).value);
          }
        }
        return finalResults;
      } else if (!token) {
        console.error("No token");
      }
    });
  }
  private _getScheduleParams(scheduleEmails: string[], dates: { start: Moment, end: Moment }[]) {
    // get unique addresses
    const emails = [...new Set(scheduleEmails)];
    const SCHEDULE_SIZE_LIMIT = 15;
    const batchMap = new Map<string, IScheduleParams[]>();
    
    dates.forEach(date => {
      const start = date.start.toISOString();
      batchMap.set(start, []);
      const emailChunks: string[][] = [];
      for (let i = 0; i < emails.length; i += SCHEDULE_SIZE_LIMIT) {
        emailChunks.push(emails.slice(i, i + SCHEDULE_SIZE_LIMIT));
      }
      const emailStack = [...emailChunks];
      const length = emailStack.length;
      const processNextEmails = (param: { start: string, end: string, batch: number }) => {
        if (emailStack.length !== 0) {
          const schedules = emailStack.shift();
          const scheduleParam: IScheduleParams = {
            id: `${param.start}_${param.batch}`,
            schedules,
            startTime: {
              dateTime: param.start,
              timeZone: "UTC"
            },
            endTime: {
              dateTime: param.end,
              timeZone: "UTC"
            }
          }
          return scheduleParam;
        }
      }
      for (let batch = 0; batch < length; batch++) {
        const params = processNextEmails({ batch, start: date.start.toISOString(), end: date.end.toISOString() });
        params && batchMap.get(start).push(params);
      }
    });

    return batchMap;
  }

  getTimeZone = async (accessToken?: AuthResponse) => {
    let isTimezoneSupported = false;
    if (accessToken == null) {
      accessToken = await this.getAccessToken();
    }
    const client = this.getAuthenticatedClient(accessToken);
    const response = await client.api("/me/outlook/supportedTimeZones(TimeZoneStandard=microsoft.graph.timeZoneStandard'Iana')").get();
    const supportedTimeZones = response && response.value;
    const ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (supportedTimeZones && supportedTimeZones.length > 0) {
      supportedTimeZones.forEach((timeZone)=> {
        const alias = timeZone?.alias;
        if (alias === ctz) isTimezoneSupported = true;
      })
    }
    return isTimezoneSupported ? ctz : null;
  }

  static getRecurrence = (params, checkInDate: Date | string) => {
    // M365 recurrence properties when making a booking
    let recurrence;
    const daysOfWeek = {};
    daysOfWeek[0] = 'sunday';
    daysOfWeek[1] = 'monday';
    daysOfWeek[2] = 'tuesday';
    daysOfWeek[3] = 'wednesday';
    daysOfWeek[4] = 'thursday';
    daysOfWeek[5] = 'friday';
    daysOfWeek[6] = 'saturday';

    const info = params.recurrence;
    let daysinWords = [];
    if(info.days && (info.days.length > 0)) {
      const days = info.days;
      days.forEach((day)=> {
        daysinWords.push(daysOfWeek[day]);
      })
    }
    const end = info.endDate;
    const endDt = Context.instance.lib.dojo.locale.format(end, {
      selector: "date",
      datePattern: "yyyy-MM-dd"
    })
    const startDt = Context.instance.lib.dojo.locale.format(checkInDate, {
      selector: "date",
      datePattern: "yyyy-MM-dd"
    })
    recurrence = {
      pattern: {
      type: info.type,
      interval: info.interval,
      daysOfWeek: daysinWords
      },
      range: {
        type: 'endDate',
        startDate: startDt,
        endDate: endDt
      }
    }
    return recurrence;
  }

  bookWorkspace = async (params: IOffice365BookingParams) =>{
    const { email, unitName, checkInDate, checkOutDate, item, bookingType } = params;
    const attributes = item && item.searchResult && item.searchResult.feature && item.searchResult.feature.attributes;
    const areaId = getAttributeValue(attributes, FieldNames.AREA_ID);
    const levelId = getAttributeValue(attributes, FieldNames.LEVEL_ID);
    const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID);
    const scheduleEmail = email;
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    let timezone = await this.getTimeZone(accessToken);
    if(timezone === undefined || timezone === null) timezone = "UTC";
    const epochStartDateTime = new Date(checkInDate).getTime();
    const epochEndDateTime = new Date(checkOutDate).getTime();
    let hoursDiff = (epochStartDateTime - epochEndDateTime)/1000;
    hoursDiff /= (60*60);
    hoursDiff = Math.abs(hoursDiff);
    let reminderHours;
    if(hoursDiff <= 24) reminderHours = 3*60;
    else reminderHours = 18*60;

    const i18n = Context.instance.i18n;
    let subject = i18n.more.bookWorkspace.emailSubject.replace("{workspaceName}", unitName);
    const location = await shareUtil.generateShareUrlAsync(item);
    const locationLink = i18n.more.bookWorkspace.locationLinkPattern;
    const locale: string  = Context.instance.lib.dojo.kernel.locale;
    if (locale === "en" || locale === "en-us") {
      this.use12Hours = true
      this.format = "h:mm A"
    } else {
      this.use12Hours = false
      this.format = "H:mm"
    }
    const startDate = Context.instance.lib.dojo.locale.format(checkInDate, {
      selector: "date",
      datePattern: "yyyy/MM/dd"
    })
    const startTime = Context.instance.lib.dojo.locale.format(checkInDate, {
      selector: "time",
      timePattern: "HH:mm:ss"
    })
    const endDate = Context.instance.lib.dojo.locale.format(checkOutDate, {
      selector: "date",
      datePattern: "yyyy/MM/dd"
    })
    const endTime = Context.instance.lib.dojo.locale.format(checkOutDate, {
      selector: "time",
      timePattern: "HH:mm:ss"
    })

    const startDateTime = startDate + "T" + startTime;
    const endDateTime = endDate + "T" + endTime;
    
    let content = '<div><a href=\"'+ location +'\">'+locationLink+'</a></div>';

    if (params.bookingType === "meetingRooms") {
      if (params.description) {
        let description = params.description.replace(/[\n\r]/g,'<br>');
        content = description+'<br><br><div><a href=\"'+ location +'\">'+locationLink+'</a></div></div>';
      }
    }

    let extensions, showAs, recurrence;
    if (bookingType === "meetingRooms") {
      subject = params && params.title;
      showAs = "busy";
      reminderHours = 15;
      extensions = [
        {
          '@odata.type': 'microsoft.graph.openTypeExtension',
          extensionName: 'Com.Esri.IndoorsMR',
          levelId: levelId,
          unitId: unitId,
          scheduleEmail: scheduleEmail
        }
      ]
    } else {
      showAs = "free";
      extensions = [
        {
          '@odata.type': 'microsoft.graph.openTypeExtension',
          extensionName: 'Com.Esri.Indoors',
          areaId: areaId,
          levelId: levelId,
          unitId: unitId,
          scheduleEmail: scheduleEmail
        }
      ]
    }

    const event: Event = {
      subject: subject,
      body: {
        contentType: "html",
        content: content
      },
      start: {
          dateTime: startDateTime,
          timeZone: timezone
      },
      end: {
          dateTime: endDateTime,
          timeZone: timezone
      },
      location:{
          displayName: unitName
      },
      attendees: [
        {
          emailAddress: {
            address: scheduleEmail,
            name: unitName
          },
          type: "resource"
        }
      ],
      extensions: extensions,
      isOrganizer: true,
      isReminderOn: true,
      reminderMinutesBeforeStart: reminderHours,
      showAs: showAs,
      allowNewTimeProposals: true
    };

    if(params && params.recurrence && params.recurrence.enabled) {
      recurrence = Office365.getRecurrence(params, checkInDate);
      event.recurrence = recurrence;
    }
    const response = await client.api('/me/calendar/events').post(event);
    return response;
  }

  updateBooking = async (params: { event: IIndoorsEvent, eventId: string }) =>{
    const {event, eventId } = params;
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const api = "/me/events/"+ eventId;
    const response = await client.api(api).update(event);
    return response;
  }

  cancelBooking = async (params) => {
    const { eventId } = params;
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const response = await client.api("/me/events/"+ eventId+"/cancel").post(undefined);
    return response;
  }

  getBooking = async(params) => {
    const {eventId} = params;
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const response = await client.api("/me/events/"+ eventId).get();
    return response;
  }
  /** Retrieves all bookings for the specified time period and booking type. This includes
   * single instances, occurrences, and exceptions. For occurrences and exceptions, the 
   * series master will also be retrieved and set as the `masterEvent` property on each booking.
   */
  async getBookings(params: {
    currentDateTime: string,
    bookingType: ICommonBookingProps["bookingType"],
    bookingDateFilter: IBookingDateFilter
  }) {
    const {currentDateTime, bookingType, bookingDateFilter} = params;
    let dtStart = null;
    let dtEnd = null;
    if (bookingDateFilter?.dateStart) {
      // prevent previous day all-day booking from being returned
      dtStart = moment(bookingDateFilter.dateStart).add(1, "milliseconds").toISOString();
      // dtEnd is end of day; add 1 to get to 12am
      dtEnd = moment(bookingDateFilter.dateEnd).add(1, "milliseconds").toISOString();
    }

    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const extensionName = bookingType === "hotel" ? "Com.Esri.Indoors" : "Com.Esri.IndoorsMR";

    let api = `/me/calendar/calendarView?startDateTime=${dtStart}&endDateTime=${dtEnd}`;
    api += `&$select=start,end,isAllDay,location,extensions,subject,attendees,type,seriesMasterId,recurrence`;
    api += `&$top=200`;
    api += `&$orderBy=start/dateTime`;
    api += `&$filter=(isOrganizer eq true)`;
    api += `&$expand=Extensions($filter=id eq '${extensionName}')`;

    const bookings: { value: IIndoorsEvent[] } = { value: [] };
    const response: { value: IIndoorsEvent[] } = await client.api(api).get();
    // filter by current extension type and current date time
    const values: IIndoorsEvent[] = response?.value?.filter(e =>
      moment(e.end.dateTime + "Z").isSameOrAfter(currentDateTime));
    const seriesMasterIds = new Set(values.map(x => x.seriesMasterId).filter(x => !!x));
    const seriesMap = new Map<string, IIndoorsEvent>();
    for await (const masterId of seriesMasterIds) {
      const seriesMaster = await this.getSeriesMaster(masterId, bookingType);
      if (seriesMaster?.extensions?.length > 0) {
        seriesMap.set(seriesMaster.id, seriesMaster);
      }
    }
    if (values?.length > 0) {
      for (const info of values) {
        if (info?.type === "singleInstance" && info.extensions.some(x => x.id.includes(extensionName))) {
          bookings.value.push(info);
        } else { // occurrences and exceptions
          const { seriesMasterId } = info;
          if (seriesMasterId) {
            if (seriesMap.has(seriesMasterId) &&
              seriesMap.get(seriesMasterId).extensions.some(x => x.id.includes(extensionName))) {
              info.masterEvent = seriesMap.get(seriesMasterId);
              info.recurrence = info.masterEvent.recurrence;
              info.extensions = info.masterEvent.extensions;
              bookings.value.push(info);
            }
          }
        }
      }
    }
    this._sortBookings(bookings.value);
    return bookings;
  }
  /** Retrieves all instances of a recurring series based on the input seriesMasterId. */
  async getBookingSeries(
    seriesMasterId: string,
    bookingType: ICommonBookingProps["bookingType"],
    bookingDateFilter: IBookingDateFilter
  ): Promise<IIndoorsEvent[]> {
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const seriesMaster = await this.getSeriesMaster(seriesMasterId, bookingType);
    const extensionName = bookingType === "meetingRooms" ? "Com.Esri.IndoorsMR" : "Com.Esri.Indoors";
    let dtStart: string = moment().toISOString();
    let dtEnd = null;
    if (bookingDateFilter?.dateStart) {
      // prevent previous day all-day booking from being returned
      dtStart = moment(bookingDateFilter.dateStart).add(1, "milliseconds").toISOString();
      // dtEnd is end of day; add 1 to get to 12am
      dtEnd = moment(bookingDateFilter.dateEnd).add(1, "milliseconds").toISOString();
    }

    if(!dtEnd) {
      const startDate = (bookingDateFilter?.dateStart) || new Date();
      const endDate = startDate.setDate(startDate.getDate() + 7);
      dtEnd = moment(endDate).toISOString();
    }
    let api = `/me/events/${seriesMasterId}/instances?startDateTime=${dtStart}&endDateTime=${dtEnd}`;
    api += `&?$select=start,end,isAllDay,location,extensions,subject,attendees,type,recurrence`;
    api += `&$top=200`;
    api += `&$orderBy=start/dateTime`;
    api += `&$filter=(isOrganizer eq true)`;

    const response: { value: IIndoorsEvent[] } = await client.api(api).get();
    // filter by current extension type and current date time
    const values: IIndoorsEvent[] = response?.value?.filter(e => {
      e.extensions = seriesMaster.extensions;
      return e.extensions.some(x => x.id.includes(extensionName));
    });
    return values;
  }

  private async getSeriesMaster(id: string, bookingType: ICommonBookingProps["bookingType"]): Promise<IIndoorsEvent> {
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const extensionName = bookingType === "meetingRooms" ? "Com.Esri.IndoorsMR" : "Com.Esri.Indoors";

    let api = `/me/events/${id}`;
    api += `?$select=start,end,isAllDay,location,extensions,subject,attendees,type,recurrence`;
    api += `&$filter=`;
    api += `(isOrganizer eq true)`;
    api += ` and (Extensions/any(f:f/id eq '${extensionName}'))`;
    api += `&$expand=Extensions($filter=id eq '${extensionName}')`;
    return client.api(api).get();
  }

  logout = (reloadUrl) => {
    const url = reloadUrl || window.location.href
    window.open(url)
    this.userAgentApplication.logout()
  }

  _sortBookings = (bookings: IIndoorsEvent[]) => {
    return bookings.sort((resA, resB) => {
      const startTimeA = resA.start.dateTime;
      const startTimeB = resB.start.dateTime;
      return startTimeA > startTimeB ? 1 : startTimeA < startTimeB ? -1 : 0;
    });
  }
  /** Verifies that the user's default calendar has no existing bookings for the 
   * specified checkIn/checkOut date. Bookings can be single instance meetings, 
   * series occurrences, or series exceptions.
   * 
   * **Note** seriesMasters are not considered because they can still exist on 
   * the calendar even if the matching occurrence has been cancelled.
   */
  async verifyMultipleBookings(task: IOffice365BookingTask) {
    const startDateTime = moment(task.checkInDate).add(1, "milliseconds").toISOString();
    const endDateTime = moment(task.checkOutDate).toISOString();
    const accessToken = await this.getAccessToken();
    const client = this.getAuthenticatedClient(accessToken);
    const extensionName = 'Com.Esri.Indoors';
    let api = `/me/calendar/calendarView?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    api += `&$top=200`;
    api += `&$filter=(isOrganizer eq true)`;
    api += `&$select=extensions,attendees,type,subject,start,seriesMasterId`;
    api += `&$expand=Extensions($filter=id eq '${extensionName}')`;
    // returns a list of single instance meetings, occurrences, and exceptions to check for conflicts.
    // https://learn.microsoft.com/en-us/graph/api/user-list-calendarview?view=graph-rest-1.0
    const response = await client.api(api).get();
    if (response && response.value && response.value.length > 0) {
      let value: IIndoorsEvent[] = response.value;
      value = value.filter(e => !Office365.wasDeclined(e));
      // `/me/calendar/calendarView` does not seem to support filtering extensions with OData so
      // filter them out from the response.
      // Additionally, all day bookings from the prior day will be returned so filter them as well
      value = value.filter(e => e.extensions.some(x => x.id.includes(extensionName))
        && moment(e.start.dateTime + "Z").isSame(task.checkInDate, "d"));
      
      // if updating an existing booking, filter the existing booking from the returned list
      if (task.eventId) {
        value = value.filter(e => e.id !== task.eventId
          && (e.seriesMasterId != null && e.seriesMasterId !== task.seriesMasterId));
      }

      if (value && value.length > 0) {
        return "hasExistingBookings";
      }
    }
  }

  queryUnitSchedule = async (roomItem, fromDate: DateAndTimeZone, toDate: DateAndTimeZone): Promise<{ reserved: IScheduleItem[] }> => {
    const user = this.getLoggedInUser();
    const units = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.units;
    const layer = units && units.layer2D;
    const floorField = layer && layer.floorInfo && layer.floorInfo.floorField;
    const feature = roomItem && roomItem.unitFeature;
    const attributes = feature && feature.attributes;
    let scheduleEmail = roomItem && roomItem.scheduleEmail;
    if (!scheduleEmail) scheduleEmail = getAttributeValue(attributes, FieldNames.SCHEDULE_EMAIL);

    if (!user) {
      const status = await this.login();
      if (status !== "Success") {
        return;
      }
    }

    const params = {
      schedules: [scheduleEmail],
      startTime: fromDate,
      endTime: toDate
    };
    const result = await this.getBookingSchedule(params);
    const schedule = result.value && result.value.length === 1 && result.value[0];

    const reservationInfo = {
      reserved: []
    };

    // To run for each schedule item returned from /getSchedule
    const scheduleItemCallback = (item) => {
      // Schedule item dates are returned in UTC, we need to offset them with
      // the system time zone to be accurate in our calendar view

      const o365toDateObj = dateUtil.O365toDate(item.start.dateTime,item.end.dateTime)
      const fromDate = o365toDateObj.fromDate;
      const toDate = o365toDateObj.toDate;

      // Data object that the schedule component can read
      let row = {
        oid: nextTmpId("schedule-item-"),
        fromDate: fromDate.getTime(),
        toDate: toDate.getTime(),
        title: item.subject,
        description: null,
        unitName: getAttributeValue(attributes, FieldNames.NAME),
        levelId: floorField ? attributes[floorField] : null,
        reservee: null,
        feature: null
      };
      reservationInfo.reserved.push(row);
    }

    if (schedule && schedule.scheduleItems && schedule.scheduleItems.length > 0) {
      schedule.scheduleItems.forEach(scheduleItemCallback);
    }

    return reservationInfo;
  }
}