import React from "react";

import Context from "../../../context/Context";
import DropdownButton from "../../common/Dropdown/DropdownButton";
import Icons from "../../util/Icons";
import * as shareUtil from "./shareUtil";
import QRCodePopup from "../QRCode/QRCodePopup";

export default class ShareDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.copyClicked = this.copyClicked.bind(this);
    this.emailClicked = this.emailClicked.bind(this);
    this.closePopup = this.closePopup.bind(this);

    this.state = {
      showPopup: false
    }
  }

  copyClicked(event) {
    const shareUrl = this.props.shareUrl;
    console.log("copy url", shareUrl)
    if (shareUrl) {
      const msg = Context.instance.i18n.infoPanel.messages.locationCopied;
      shareUtil.copyToClipboard(shareUrl,msg);
    }
  }

  emailClicked(event) {}

  closePopup() {
    this.setState({
      showPopup: false
    })
  }

  render() {
    const i18n = Context.instance.i18n;
    const buttonContent = (
      <div>
        {Icons.share()}
        <span className={"i-actionbar-button-caption"}
          >{i18n.infoPanel.actions.share.caption}</span>
      </div>
    );
    const dropdownContent = this.renderDropdownMenu();

    const QRSize = 350

    return (
      <div>
        <DropdownButton
          closeOnSelect={true}
          focusFirst={true}
          buttonClassName={"i-actionbar-button"}
          buttonContent={buttonContent}
          dropdownContent={dropdownContent}>
        </DropdownButton>
      </div>
    );
  }

  renderDropdownMenu() {
    let emailSubject;
    if (this.props && this.props.emailSubject) emailSubject = this.props.emailSubject;
    const mailto = (shareUtil.generateMailtoRef(this.props.shareUrl, emailSubject) || "#");
    const hashref = "#";
    const i18n = Context.instance.i18n;
    const menu = (
      <ul role="menu">
        <li key="share_email" role="none">
          <a className="i-dd-menuitem" href={mailto} tabIndex="0" target="_top"
            role="menuitem button" onClick={this.emailClicked}
            data-i--first={true}
            >{i18n.infoPanel.actions.share.email}</a>
        </li>
        <li key="share_copy" role="none">
          <a className="i-dd-menuitem" href={hashref} tabIndex="0"
            role="menuitem button" onClick={this.copyClicked} 
            >{i18n.infoPanel.actions.share.copy}</a>
        </li>
      </ul>
    );
    return menu;
  }

}
