// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import { connect } from "react-redux";
import Rdx from "../../../redux/Rdx";
import Context from "../../../context/Context";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

// Component specific modules (Component-styled, etc.)
import {
  Wrapper,
  BackButtonContainer,
  LeftPanel,
  LeftPanelContent,
  Title,
  ContentPanel,
  StyledList,
  StyledListItem,
  StyledListHeader,
  ArcgisAccountWrapper,
  AccountSettings,
  SettingsPopover,
  AccountWrapper
} from "../../styles/backstageStyles";

// App components
import ExportPlan from "./ExportPlan";
import MergePlan from "./MergePlan";
import NewPlan from "./NewPlan";
import OpenPlan from "./OpenPlan";
import Summary from "./Summary";
import Button from "../../../common/components/Button";
import { ListItemTitle } from "../../../common/components/List";
import TabPanel from "../common/TabPanel";

// Third-party components (buttons, icons, etc.)
import BackIcon from "calcite-ui-icons-react/ArrowLeftIcon";
import ExportPlanIcon from "calcite-ui-icons-react/ExportIcon";
import GearIcon from "calcite-ui-icons-react/GearIcon";
import NewPlanIcon from "calcite-ui-icons-react/FolderNewIcon";
import OpenPlanIcon from "calcite-ui-icons-react/FolderOpenIcon";
import Panel, { PanelTitle } from "calcite-react/Panel";
import Popover from "calcite-react/Popover";
import SavePlanAsIcon from "calcite-ui-icons-react/CopyIcon";
import SummaryIcon from "calcite-ui-icons-react/FileReportIcon";
import ArcgisAccount from "calcite-react/ArcgisAccount";
import { Theme } from "../../../theme";
import UserSettingsPopup from "./UserSettingsPopup";

// JSON

// CSS
const navKey = "i-spaceplanner-backstage-nav";

class Backstage extends Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      on: false,
      settingsOpen: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.CloseBackstage, (params) => {
        this.setState({ on: false });
      }),
      Topic.subscribe(Topic.ShowBackstage, (params) => {
        if (params.tabKey) {
          Rdx.setValue(null, navKey, params.tabKey);
        } else {
          const tabKey = Rdx.getValue(null, navKey);
          if (!tabKey) {
            // set a default?
            Rdx.setValue(null, navKey, "openPlan");
          }
        }
        if (params.forceRefresh && this.state.on) {
          component.refresh(this);
        } else {
          this.setState({ on: true });
        }
      }),
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  canMerge =(plan) => {
    if (plan && plan.wasOpened) {
      return true;
    }else{
      return false;
    }
  }

  // canMerge = (plan) => {
  //   if (plan && plan.wasOpened) {
  //     if (plan.isVersioned) {
  //       return true;
  //     } else {
  //       const item = plan.planServiceItem;
  //       const username = Context.instance.user.getUsername();
  //       const isOwner = item && (item.owner === username);
  //       if (isOwner || Context.instance.user.isAdmin()) {
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }

  close = () => {
    this.setState({
      on: false,
      settingsOpen: false
    });
  };

  optionClicked = (tabKey) => {
    Rdx.setValue(this, navKey, tabKey);
  };

  toggleSettings = () => {
    const { settingsOpen } = this.state;
    this.setState({
      settingsOpen: !settingsOpen
    });
  }

  closeSettings = () => {
    this.setState({
      settingsOpen: false
    });
  }

  renderSettingsButton() {
    const settingsButton = (
      <AccountSettings onClick={this.toggleSettings}>
        <GearIcon />
      </AccountSettings>
    );

    const targetStyle = {
      width: '20%'
    };

    return (
      <SettingsPopover
        targetEl={settingsButton}
        open={this.state.settingsOpen}
        placement={"top-end"}
        onRequestClose={this.closeSettings}
        targetContainerStyles={targetStyle}
      >
        <Panel noBorder noPadding>
          <UserSettingsPopup closeBackstage={this.close}/>
        </Panel>
      </SettingsPopover>
    );
  }

  render() {
    if (!this.state.on) {
      return <div className="i-backstage-off"></div>;
    }
    const i18n = Context.instance.i18n;
    const activeTabKey = this.props.rdxActiveTabKey;
    const appItem = Context.instance.configuration.appItem;
    const planner = Context.instance.spaceplanner.planner;
    const plan = Context.instance.spaceplanner.activePlan;
    const hasValidProject = planner.hasValidProject();
    //const hasValidPlan = planner.hasValidPlan();
    const hasOpenPlan = !!(plan && plan.wasOpened);
    const canMerge = this.canMerge(plan);
    const showMerge = true;
    const project = Context.instance.spaceplanner.planner.project;
    const isVersioned = project && project.isVersioned;
    const canSaveAs = hasOpenPlan && !isVersioned;
    //const canSaveAs = !isVersioned;

    let title;
    let isConfiguratorOn = Context.instance.uiMode.isConfiguratorOn;
    if (hasOpenPlan || isConfiguratorOn) {
      title = (
        <Button
          style={{
            padding: "0 0.5rem",
            whiteSpace: "inherit",
            textAlign: "start",
          }}
          onClick={() => this.close()}
          title={i18n.general.back}
          icon={<BackIcon className="i-rtl-icon" size="32" />}
          iconPosition="before"
        >
          {i18n.spaceplanner.backstage.backLabel}
        </Button>
      );
    } else {
      title = <Title style={{color: "white"}}>{i18n.spaceplanner.application.title}</Title>;
    }

    let portal, portalUser, showAccount;
    if (
      Context.instance.user &&
      Context.instance.user.portalUser &&
      Context.instance.user.portal
    ) {
      portalUser = Context.instance.user.portalUser.sourceJSON;
      portal = Context.instance.user.portal.sourceJSON;
      showAccount = !!(portalUser && portal);
    }

    const admin = Context.getInstance().user.isAdmin();
    const publisher = Context.getInstance().user.isPublisher();
    let showMsg ='';
    if(!admin && !publisher) showMsg = i18n.spaceplanner.application.higherPrivilegesRequiredPlans;
    return (
      <Wrapper>
        <LeftPanel>
          <LeftPanelContent>
            <BackButtonContainer>{title}</BackButtonContainer>
            <StyledList key="menu">
              <div title={showMsg}>
                <StyledListItem
                  key="newPlan"
                  leftNode={<NewPlanIcon />}
                  active={activeTabKey === "newPlan"}
                  disabled={!appItem || !hasValidProject || (!admin && !publisher)}
                  onClick={() => this.optionClicked("newPlan")}
                >
                  <ListItemTitle>
                    {i18n.spaceplanner.backstage.newPlan.label}
                  </ListItemTitle>
                </StyledListItem>
              </div>
              <StyledListItem
                key="openPlan"
                leftNode={<OpenPlanIcon />}
                active={activeTabKey === "openPlan"}
                onClick={() => this.optionClicked("openPlan")}
              >
                <ListItemTitle>
                  {i18n.spaceplanner.backstage.openPlan.label}
                </ListItemTitle>
              </StyledListItem>
            </StyledList>
            {hasOpenPlan && (
              <StyledList key="planMenu">
                <StyledListHeader key="planTitle">
                  {plan.title}
                </StyledListHeader>

                <StyledListItem
                  key="summary"
                  leftNode={<SummaryIcon />}
                  active={activeTabKey === "summary"}
                  onClick={() => this.optionClicked("summary")}
                >
                  <ListItemTitle>
                    {i18n.spaceplanner.backstage.summary.label}
                  </ListItemTitle>
                </StyledListItem>

                <StyledListItem
                  key="exportPlan"
                  leftNode={<ExportPlanIcon />}
                  active={activeTabKey === "exportPlan"}
                  onClick={() => this.optionClicked("exportPlan")}
                >
                  <ListItemTitle>
                    {i18n.spaceplanner.backstage.exportPlan.label}
                  </ListItemTitle>
                </StyledListItem>

                {canSaveAs && (
                  <div title={showMsg}>
                    <StyledListItem
                      key="savePlanAs"
                      leftNode={<SavePlanAsIcon />}
                      disabled={!admin && !publisher}
                      active={activeTabKey === "savePlanAs"}
                      onClick={() => this.optionClicked("savePlanAs")}
                    >
                      <ListItemTitle>
                        {i18n.spaceplanner.backstage.savePlanAs.label}
                      </ListItemTitle>
                    </StyledListItem>
                  </div>
                )}

                {showMerge && (
                  <StyledListItem
                    key="mergePlan"
                    leftNode={<SavePlanAsIcon />}
                    active={activeTabKey === "mergePlan"}
                    disabled={!canMerge}
                    onClick={() => this.optionClicked("mergePlan")}
                  >
                    <ListItemTitle>
                      {i18n.spaceplanner.backstage.mergePlan.label}
                    </ListItemTitle>
                  </StyledListItem>
                )}
              </StyledList>
            )}
          </LeftPanelContent>
          {showAccount && (
            <AccountWrapper>
              <ArcgisAccountWrapper>
                <ArcgisAccount
                  user={portalUser}
                  portal={portal}
                  signOutLabel={i18n.user.signout}
                  hideSwitchAccount={true}
                  onRequestSignOut={() => this.signOutClicked()}
                />
              </ArcgisAccountWrapper>
              {this.renderSettingsButton()}
            </AccountWrapper>
          )}
        </LeftPanel>

        <ContentPanel>
          <TabPanel key="newPlan" tabKey="newPlan" navKey={navKey}>
            <NewPlan />
          </TabPanel>
          <TabPanel key="openPlan" tabKey="openPlan" navKey={navKey}>
            <OpenPlan />
          </TabPanel>
          {hasOpenPlan && (
            <>
              <TabPanel key="summary" tabKey="summary" navKey={navKey}>
                <Summary />
              </TabPanel>

              <TabPanel key="exportPlan" tabKey="exportPlan" navKey={navKey}>
                <ExportPlan />
              </TabPanel>
            </>
          )}
          {hasOpenPlan && canSaveAs && (
            <TabPanel key="savePlanAs" tabKey="savePlanAs" navKey={navKey}>
              <NewPlan isSaveAs={true} />
            </TabPanel>
          )}
          {hasOpenPlan && canMerge && (
            <TabPanel key="mergePlan" tabKey="mergePlan" navKey={navKey}>
              <MergePlan />
            </TabPanel>
          )}
        </ContentPanel>
      </Wrapper>
    );
  }

  signOutClicked = () => {
    Topic.publish(Topic.SignOutClicked, {});
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state, navKey),
  };
};

export default connect(mapStateToProps)(Backstage);
