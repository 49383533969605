import styled from "styled-components";

import CalciteGroupIcon from "calcite-ui-icons-react/GroupIcon";
import CalciteMeasureAreaIcon from "calcite-ui-icons-react/MeasureAreaIcon";
import CalcitePersonIcon from "calcite-ui-icons-react/PersonIcon";
import CalcitePinIcon from "calcite-ui-icons-react/PinIcon";
import CalciteUnitsIcon from "calcite-ui-icons-react/FloorPlanIcon";
import CalciteHotDeskIcon from "calcite-ui-icons-react/DeskIcon";
import CalciteMeetingRoomIcon from "calcite-ui-icons-react/ConferenceRoomIcon";

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex-wrap: nowrap;
  background: #fff;
`;

const StatWrapper = styled.div`
  margin: 0 0.33rem;
  flex-grow: 1;
  flex-basis: 33%;
`;

const GroupIcon = styled(CalciteGroupIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const MeasureAreaIcon = styled(CalciteMeasureAreaIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const PersonIcon = styled(CalcitePersonIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const PinIcon = styled(CalcitePinIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const UnitsIcon = styled(CalciteUnitsIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const HotDeskIcon = styled(CalciteHotDeskIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

const MeetingRoomIcon = styled(CalciteMeetingRoomIcon)`
  color: ${props => props.theme.palette.themeColor};
`;

export {
  StatsWrapper,
  StatWrapper,
  GroupIcon,
  MeasureAreaIcon,
  PersonIcon,
  PinIcon,
  UnitsIcon,
  HotDeskIcon,
  MeetingRoomIcon
};
