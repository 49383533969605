import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import Calendar from "../../common/Calendar/Calendar";
import Context from "../../../context/Context";
import ChoiceDropdown from "../../common/ChoiceDropdown/ChoiceDropdown";
import MenuOption from "../../common/ChoiceDropdown/MenuOption";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";
import * as component from "../../util/component";

const CSS = {
  main: "i-events-datefilter"
};
interface IDateFilterProps {
  isOffice365: boolean,
  onClick?: (source: "DateFilter", menuOption: MenuOption, date?: Date) => void,
  rdxDateSelected: Date,
  rdxDateSelectedBook: Date,
  rdxOption: string,
  rdxOptionBook: string,
  type: "bookings"
}
interface IDateFilterState {
  optionData: { label: string, value: string, key?: string }[]
}
class DateFilter extends React.Component<IDateFilterProps & IRdxProps, IDateFilterState> {

  constructor(props: IDateFilterProps & IRdxProps) {
    super(props);
    const optionData = this.makeOptionData();
    this.state = component.newState({optionData: optionData});
    this.clicked = this.clicked.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
  }

  clicked(menuOption) {
    if (this.props.type === "bookings") this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION,menuOption.props.value);
    else this.props.setRdxValue(Rdx.Keys.EVENTS_DATEFILTER_OPTION,menuOption.props.value);

    this.props.setRdxValue(Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED,null);
    if (this.props.onClick) {
      this.props.onClick("DateFilter", menuOption);
    }
  }

  makeOptionData() {
    const i18n = Context.getInstance().i18n;
    const optionData = [
      {
        label: i18n.events.dateFilter.any,
        value: null
      },
      {
        label: i18n.events.dateFilter.today,
        value: "today"
      },
      {
        label: i18n.events.dateFilter.tomorrow,
        value: "tomorrow"
      },
      {
        label: i18n.events.dateFilter.thisweek,
        value: "thisweek"
      },
      {
        label: i18n.events.dateFilter.thismonth,
        value: "thismonth"
      },
      {
        label: i18n.events.dateFilter.pickdate,
        value: "pickdate",
      }
    ];
    // don't display any date option for hotel M365
    if (this.props && this.props.isOffice365) { 
      optionData.shift();
    }
    return optionData;
  }

  render() {
    const lib = Context.getInstance().lib;
    const i18n = Context.getInstance().i18n;
    let caption = i18n.events.dateFilter.any;
    let activeValue;
    if(this.props.type === "bookings") activeValue = this.props.rdxOptionBook;
    else activeValue = this.props.rdxOption;

    if (activeValue === undefined) activeValue = null;
    const optionData = this.state.optionData;

    const options = optionData.map(info => {
      let key = info.key;
      if (key === null) key = "k";
      else key = "k" + info.value;
      let checked = (activeValue === info.value);
      if (checked) caption = info.label;

      if (info.value === "pickdate") {
        if (checked) {
          let date;
          if (this.props.type === "bookings") date = this.props.rdxDateSelectedBook;
          else date = this.props.rdxDateSelected;

          if (date !== null && date !== undefined) {
            caption = i18n.events.dateFilter.custom;
          }
        }
        return (
          <MenuOption key={key} label={info.label} value={info.value}
            checked={checked} onClick={this.showCalendar} />
        );
      } else {
        return (
          <MenuOption key={key} label={info.label} value={info.value}
            checked={checked} onClick={this.clicked} />
        );
      }
    });
    return (
      <div className={CSS.main}>
        <ChoiceDropdown caption={caption}>
          {options}
        </ChoiceDropdown>
      </div>
    );
  }
  
  outsideRange = (day) => {
    return moment().isAfter(day, 'day');
  }

  showCalendar(menuOption) {
    const type = this.props.type;
    let initialDate;
    const onDateChanged = (date: Date) => {
      if (date !== null && date !== undefined) {
        if(type === "bookings") {
          this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_OPTION,menuOption.props.value);
          this.props.setRdxValue(Rdx.Keys.BOOK_DATEFILTER_DATESELECTED,date);
          initialDate = this.props.rdxDateSelectedBook;
        } else {
          this.props.setRdxValue(Rdx.Keys.EVENTS_DATEFILTER_OPTION,menuOption.props.value);
          this.props.setRdxValue(Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED,date);
          initialDate = this.props.rdxDateSelected;
        }
      }
        
      if (this.props.onClick) {
        this.props.onClick("DateFilter",menuOption,date);
      }
    }
    const isOutsideRange = type === 'bookings' ? this.outsideRange : null; 
    Calendar.showModal(initialDate,onDateChanged,isOutsideRange);
  };
}

const mapStateToProps = (state) => {
  return {
    rdxOption: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.EVENTS_DATEFILTER_DATESELECTED),
    rdxOptionBook: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_OPTION),
    rdxDateSelectedBook: Rdx.getValue(state, Rdx.Keys.BOOK_DATEFILTER_DATESELECTED)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
