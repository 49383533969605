import React from 'react';

import SignInIcon from "calcite-ui-icons-react/SignInIcon";
import Button from "calcite-react/Button";

import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';
import FieldNames from '../../../../../aiim/datasets/FieldNames';

import { ModalController }  from "../../../../../../src/common/components/Modal/index";
import { updateCheckInOut } from "./WorkspaceReservation/OfficeHotelingInterface";
import { getAttributeValue } from '../../../../../aiim/util/aiimUtil';

import { isNetworkError } from '../../../../../util/networkUtil';
import { getHotelBookingSystem, getMeetingBookingSystem } from './WorkspaceReservation/workspaceReservationUtil';
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";
import * as checkInOutUtil from "./CheckInOutUtil";


const CSS = {
    buttonCheckIn: "i-book-card-button-checkIn"
}

export default class CheckIn extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            attributes: this.props.attributes
        }
    }

    updateStatus =()=> {
        const i18n = Context.getInstance().i18n;
        const attributes = this.state.attributes;
        const unitName = attributes && aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_NAME);
        const message = i18n.more.bookWorkspace.checkInOut.checkIn.newMessage.replace("{unit}", unitName);
        const reservations = Context.getInstance().aiim.datasets.reservations;
        const { bookingType } = this.props;
        const bookingSystem = (bookingType === "meetingRoom") ? getMeetingBookingSystem() : getHotelBookingSystem();

        const options = {
            title: i18n.more.bookWorkspace.checkInOut.checkIn.caption,
            message: message,
            okLabel: i18n.more.bookWorkspace.checkInOut.checkIn.yes,
            cancelLabel: i18n.more.bookWorkspace.checkInOut.checkIn.no,
            closeOnOK: true,
            closeOnCancel: true
        };

        ModalController.confirm(options)
        .then(result => {
            if(result.ok) {
                let objectIdField;
                if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
                objectIdField = reservations.layer2D.objectIdField;
                }
                const objectID = getAttributeValue(attributes, objectIdField);
                attributes[reservations.checkInTimeField] = new Date().getTime();
                updateCheckInOut(bookingSystem, attributes, "checkIn")
                .then((res)=> {
                    if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
                    res.updateFeatureResults[0].error){
                        Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
                    } else {    
                        const type = this.props.type;
                        const person = getAttributeValue(attributes, FieldNames.RESERVED_FOR_FULL_NAME);
                        let msg = i18n.more.bookWorkspace.checkInOut.checkIn.toastMessage;
                        if (type === "otherbookings") {
                            msg = i18n.more.bookWorkspace.checkInOut.someoneElse.confirmationSubtitleCheckIn;
                            msg = msg.replace("{person}", person);
                        }
                        Topic.publish(Topic.ShowToast,{
                            message: msg
                        });
                        Topic.publish(Topic.RenderBookingList, {});
                        Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
                    }
                })
                .catch(error => {
                    if(error && isNetworkError(error.message)) {
                        checkInOutUtil.showServerErrorPopup("checkIn", attributes, bookingType);
                    } else {
                        Topic.publishErrorUpdatingData(error);
                    }
                    console.error(error);
                })
            }
        })
        .catch(error => {
            console.error(error);
            Topic.publishErrorUpdatingData(error)
        })
    }

    render() {
        const i18n = Context.getInstance().i18n;
        const label = i18n.more.bookWorkspace.checkInOut.checkIn.caption;
        const icon = (<SignInIcon size={20}/>);
        const cls = CSS.buttonCheckIn;
        return (
            <div className={cls}>
                <Button
                    icon={icon}
                    iconPosition="before"
                    onClick={this.updateStatus}>
                    {label}
                </Button>
            </div>
        )
    }
}
