import Context from "../../../../context/Context";
import * as editorUtil from "./editorUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as vectorUtil from "./vectorUtil";
import { AnchorPosition, IStencil } from "../../../miniapps/common/types";

export function getTolerance() {
  // const lib = Context.instance.lib;
  // const view = mapUtil.getView();
  // let metersPerMapUnit = lib.esri.unitUtils.getMetersPerUnit(view.spatialReference);
  // if (typeof metersPerMapUnit === "number" && !isNaN(metersPerMapUnit) &&
  //     isFinite(metersPerMapUnit)) {
  //   const resolution = view.get("state.resolution");
  //   const mapUnitsPerPixel = (typeof resolution === "number" ? resolution : 1);
  //   const metersPerPixel = (mapUnitsPerPixel * metersPerMapUnit);
  //   let numPixels = 2;
  //   if (metersPerPixel > 0.5) numPixels = 1
  //   if (metersPerPixel < 0.02) numPixels = 3
  //   const toleranceInMeters = (numPixels * metersPerPixel);
  // }
  return 0.25; // @todo this is in meters
}

export function insitu(stencilItem, graphic?: __esri.Graphic) {
  const ge = Context.instance.lib.esri.geometryEngine;
  const view = mapUtil.getView();
  let stencil = stencilItem.stencil;
  let rotationAngle = stencilItem.rotationAngle;
  let reflect = !!stencilItem.reflect;
  let flip = !!stencilItem.flip;
  let geometry = stencil.geometry.clone();
  const cen = geometry.extent.center;
  let anchor1, anchor2, anchorCen;
  if (stencil.anchor1) {
    anchor1 = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: cen.x - stencil.anchor1.dx,
      y: cen.y - stencil.anchor1.dy,
      spatialReference: view.spatialReference.clone()
    })
  }
  if (stencil.anchor2) {
    anchor2 = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: cen.x - stencil.anchor2.dx,
      y: cen.y - stencil.anchor2.dy,
      spatialReference: view.spatialReference.clone()
    })
  }
  if (anchor1) {
    if (reflect) {
      geometry = reflectPolyOrPolyline(geometry,anchor1,anchor2,false)
    }
    if (flip) {
      geometry = reflectPolyOrPolyline(geometry,anchor1,anchor2,true)
    }
  }
  if (anchor1 && typeof rotationAngle === "number" && !isNaN(rotationAngle) && isFinite(rotationAngle)) {
    geometry = ge.rotate(geometry,rotationAngle,anchor1)
    if (anchor2) anchor2 = ge.rotate(anchor2,rotationAngle,anchor1);
  }
  if (anchor1 && anchor2) {
    anchorCen = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: (anchor1.x + anchor2.x) / 2,
      y: (anchor1.y + anchor2.y) / 2,
      spatialReference: view.spatialReference.clone()
    })
  }

  let props: {
    geometry: __esri.Geometry,
    anchor1: __esri.Point,
    anchor2: __esri.Point,
    anchorCen: __esri.Point,
    dx?: number,
    dy?: number
  } = {
    geometry: geometry,
    anchor1: anchor1,
    anchor2: anchor2,
    anchorCen: anchorCen
  }
  if (graphic) {
    const cenStencil = geometry.extent.center;
    const cenGraphic = graphic.geometry.extent.center;
    let dx = cenGraphic.x - cenStencil.x;
    let dy = cenGraphic.y - cenStencil.y;
    props.dx = dx;
    props.dy = dy;
  }
  return props;
}

export function makeAnchorSymbol()  {
  return {
    type: "simple-marker",
    style: "circle",
    size: 9,
    color: [255, 255, 255],
    outline: {
    color: [50, 50, 50],
      width: 1
    }
  };
}

export function makeLineSymbol()  {
  return {
    type: "simple-line",
    color: [104, 104, 104, 1],
    style: "solid",
    width: 1
  }
}

export function newDynamicStencil(view, length: number, width: number, a1p?: AnchorPosition, a2p?: AnchorPosition): IStencil {
  const lib = Context.instance.lib;
  const stencil = newStencil({});
  const cen = view.extent.center;
  const spatialReference = view.spatialReference;

  const positions = ["ul" , "uc" , "ur" , "cl" , "cc" , "cr" , "ll" , "lc" , "lr"]
  if (!positions.includes(a1p)) {
    a1p = null;
    a2p = null;
  } else {
    if (!positions.includes(a2p)) {
      a2p = null;
    }
  }

  const halfWidth = (width / 2);
  const halfLength = (length / 2);
  const xmin = cen.x - halfWidth;
  const xmax = cen.x + halfWidth;
  const ymin = cen.y - halfLength;
  const ymax = cen.y + halfLength;
  const zmin = 0;
  const zmax = 0;
  const xmid = (xmax + xmin) / 2;
  const ymid = (ymax + ymin) / 2;
  const extent = new lib.esri.Extent({xmin,ymin,xmax,ymax,zmin,zmax,spatialReference});
  stencil.geometry = lib.esri.Polygon.fromExtent(extent);
  if (width === 0) {
    stencil.geometry = new lib.esri.Polyline({
      paths: [
        [
          [xmax,ymax,zmax],
          [xmin,ymin,zmin]
        ]
      ],
      hasZ: true,
      spatialReference
    });
  }

  type xy = {
    x: number,
    y: number
  }
  const a1xy: xy = {
    x: null,
    y: null
  }
  const a2xy: xy = {
    x: null,
    y: null
  }

  const setXY = (ap: AnchorPosition, axy: xy) => {
    if (ap === "ul") {
      axy.x = xmin;
      axy.y = ymax;
    } else if (ap === "uc") {
      axy.x = xmid;
      axy.y = ymax;
    } else if (ap === "ur") {
      axy.x = xmax;
      axy.y = ymax;
    } else if (ap === "cl") {
      axy.x = xmin;
      axy.y = ymid;
    } else if (ap === "cc") {
      axy.x = xmid;
      axy.y = ymid;
    } else if (ap === "cr") {
      axy.x = xmax;
      axy.y = ymid;
    } else if (ap === "ll") {
      axy.x = xmin;
      axy.y = ymin;
    } else if (ap === "lc") {
      axy.x = xmid;
      axy.y = ymin;
    } else if (ap === "lr") {
      axy.x = xmax;
      axy.y = ymin;
    }
  }

  const setAnchors = () => {
    let a2pOk = false;
    if (a1p === "ul") {
      a2pOk = ["uc","ur","cl","ll"].includes(a2p);
    } else if (a1p === "uc") {
      a2pOk = ["ul","ur","cc","lc"].includes(a2p);
    } else if (a1p === "ur") {
      a2pOk = ["ul","uc","cr","lr"].includes(a2p);
    } else if (a1p === "cl") {
      a2pOk = ["cc","cr","ul","ll"].includes(a2p);
    } else if (a1p === "cc") {
      a2pOk = ["cl","cr","uc","lc"].includes(a2p);
    } else if (a1p === "cr") {
      a2pOk = ["cl","cc","ur","lr"].includes(a2p);
    } else if (a1p === "ll") {
      a2pOk = ["lc","lr","cl","ul"].includes(a2p);
    } else if (a1p === "lc") {
      a2pOk = ["ll","lr","cc","uc"].includes(a2p);
    } else if (a1p === "lr") {
      a2pOk = ["ll","lc","cr","ur"].includes(a2p);
    }
    setXY(a1p,a1xy)
    if (a2pOk) setXY(a2p,a2xy);
  }

  setAnchors();

  if (typeof a1xy.x === "number") {
    stencil.anchor1 = {
      dx: cen.x - a1xy.x,
      dy: cen.y - a1xy.y
    }
    if (typeof a2xy.x === "number") {
      stencil.anchor2 = {
        dx: cen.x - a2xy.x,
        dy: cen.y - a2xy.y
      }     
    }
  }

  return stencil;
}

export function newStencil(props: IStencil): IStencil  {
  let stencil = {
    key: null,
    name: null,
    description: null,
    detailUseType: null,
    featureType: null,
    fpeType: null,
    geometry: null,
    anchor1: null,
    anchor2: null
  }
  if (props) stencil = Object.assign(stencil,props);
  return stencil;
}
export interface IStencilItem {
  stencil: null,
  graphic: __esri.Graphic,
  anchorPoint1: __esri.Point,
  rotationAngle: number,
  reflect: boolean,
  flip: boolean,
  z: number
}
export function newStencilItem(props)  {
  let stencilItem = {
    stencil: null,
    graphic: null,
    anchorPoint1: null,
    rotationAngle: null,
    reflect: null,
    flip: null,
    z: null
  }
  if (props) stencilItem = Object.assign(stencilItem,props);
  return stencilItem;
}

export function offsetStencilGeometry(point,stencil,segments,z,prev) {
  const getSlope = (v1,v2) => {
    return (v2.y - v1.y) / (v2.x - v1.x);
  }

  let newAnchor, newAnchor2, rotationAngle = null;
  let mAnchors = null, mSegment = null;
  if (segments && segments.length > 0) {
    if (stencil.anchor2 && typeof stencil.anchor2.dx === "number" && typeof stencil.anchor2.dy === "number") {
      const v1 = segments[0].v1;
      const v2 = segments[0].v2;
      mSegment = getSlope(v1,v2);
    }
  }
  let tresult = translate(stencil,point,z);
  let geometry = tresult.geometry;

  if (stencil.anchor1 && typeof stencil.anchor1.dx === "number" && typeof stencil.anchor1.dy === "number") {
    let cen2 = geometry.extent.center;
    newAnchor = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: cen2.x - stencil.anchor1.dx,
      y: cen2.y - stencil.anchor1.dy,
      spatialReference: geometry.spatialReference
    })
    if (stencil.anchor2 && typeof stencil.anchor2.dx === "number" && typeof stencil.anchor2.dy === "number") {
      newAnchor2 = new Context.instance.lib.esri.Point({
        hasZ: false,
        x: cen2.x - stencil.anchor2.dx,
        y: cen2.y - stencil.anchor2.dy,
        spatialReference: geometry.spatialReference
      })
    }
    if (stencil.anchor2 && typeof stencil.anchor2.dx === "number" && typeof stencil.anchor2.dy === "number" &&
        typeof mSegment === "number" && !isNaN(mSegment)) {
      const v1 = {x: stencil.anchor1.dx, y:stencil.anchor1.dy};
      const v2 = {x: stencil.anchor2.dx, y:stencil.anchor2.dy};
      mAnchors = getSlope(v1,v2);
      if (mSegment === Number.POSITIVE_INFINITY || mSegment === Number.NEGATIVE_INFINITY) {
        if (mAnchors === Number.POSITIVE_INFINITY || mAnchors === Number.NEGATIVE_INFINITY) {
        } else {
          let tan = mAnchors;
          const atan = Math.atan(tan);
          const deg = 90 - ((atan * 180) / (Math.PI))
          rotationAngle = deg;
        }
      } else if (mAnchors === Number.POSITIVE_INFINITY || mAnchors === Number.NEGATIVE_INFINITY) {
        let tan = mSegment;
        const atan = Math.atan(tan);
        const deg = 90 + ((atan * 180) / (Math.PI))
        rotationAngle = deg;
      } else {
        // let tan = Math.abs((mSegment - mAnchors) / (1 + (mSegment * mAnchors)))
        // const atan = Math.atan(tan);
        // const deg = ((atan * 180) / (Math.PI))
        // rotationAngle = deg;
        const atan2 = Math.atan2((mSegment - mAnchors),(1 + (mSegment * mAnchors)))
        const deg2 = ((atan2 * 180) / (Math.PI))
        rotationAngle = deg2;
      }
    }
  }

  let reflect = null;
  if (newAnchor && prev && prev.reflect) {
    geometry = reflectPolyOrPolyline(geometry,newAnchor,newAnchor2,false);
    reflect = true;
  }

  let flip = null;
  if (newAnchor && prev && prev.flip) {
    geometry = reflectPolyOrPolyline(geometry,newAnchor,newAnchor2,true);
    flip = true;
  }

  let rAngle = null;
  if (newAnchor && typeof rotationAngle === "number" && !isNaN(rotationAngle) && isFinite(rotationAngle)) {
    rAngle = rotationAngle;
  } else if (newAnchor && prev && typeof prev.rotationAngle === "number" && !isNaN(prev.rotationAngle) && isFinite(prev.rotationAngle)) {
    rAngle = prev.rotationAngle;
  }
  if (newAnchor && typeof rAngle === "number") {
    const ge = Context.instance.lib.esri.geometryEngine;
    geometry = ge.rotate(geometry,rAngle,newAnchor);
  }

  const offsetResult = {
    geometry: geometry,
    stencil: stencil,
    anchorPoint1: newAnchor,
    rotationAngle: rAngle,
    reflect: reflect,
    flip: flip,
    z: z
  }

  return offsetResult;
}

export async function queryHit(mapPoint,screenPoint) {
  let segments = [], layerSegments;
  const grid = sourceUtil.getGrid();

  layerSegments = await queryPolylineHit(mapPoint,screenPoint,mapUtil.getDetailsLayerView());
  if (layerSegments && layerSegments.length > 0) segments.push(layerSegments[0]);

  layerSegments = await queryPolylineHit(mapPoint,screenPoint,mapUtil.getLayerView(grid && grid.layer));
  if (layerSegments && layerSegments.length > 0) segments.push(layerSegments[0]);

  layerSegments = await queryPolygonHit(mapPoint,screenPoint,mapUtil.getUnitsLayerView());
  if (layerSegments && layerSegments.length > 0) segments.push(layerSegments[0])
  
  layerSegments = await queryPolygonHit(mapPoint,screenPoint,mapUtil.getLayerView(sourceUtil.getLevelsLayer()));
  if (layerSegments && layerSegments.length > 0) segments.push(layerSegments[0]);

  sortSegments(segments);
  if (segments.length > 0) return [segments[0]];
  return [];
}

export async function queryPolylineHit(mapPoint,screenPoint,layerView) {
  const segments = [];
  const vis = editorUtil.isLayerViewVisible(layerView);
  if (vis && mapPoint) {
    // @ts-ignore
    let result = await layerView.hitTest(mapPoint, { x: screenPoint.x, y: screenPoint.y });
    result = Context.instance.aiim.fixHitTestResult(result); 
    if (Array.isArray(result) && result.length > 0) {
      const tol = getTolerance();
      result.forEach(hit => {
        const graphic = hit && hit.graphic;
        if (graphic && graphic.geometry && graphic.geometry.paths) {
          const xMapPoint = mapPoint.x;
          const yMapPoint = mapPoint.y;
          graphic.geometry.paths.forEach((path,pathIndex) => {
            path.forEach((coords,coordsIndex) => {
              const x = coords[0];
              const xNext = path[coordsIndex + 1] && path[coordsIndex + 1][0];
              //const xPrev = path[coordsIndex - 1] && path[coordsIndex - 1][0];
              const y = coords[1];
              const yNext = path[coordsIndex + 1] && path[coordsIndex + 1][1];
              //const yPrev = path[coordsIndex - 1] && path[coordsIndex - 1][1];
              if (typeof xNext === "number") {
                const result = vectorUtil.projectPointOnLine(
                  {x: x, y: y},
                  {x: xNext, y: yNext},
                  {x: xMapPoint, y: yMapPoint}
                )
                if (result && result.d < tol) {
                  result.path = path.slice();
                  segments.push(result)
                }
              }
            })
          })
        }
      })
    }
  }
  sortSegments(segments);
  return segments;
}

export async function queryPolygonHit(mapPoint,screenPoint,layerView) {
  const segments = [];
  const vis = editorUtil.isLayerViewVisible(layerView);
  if (vis && mapPoint) {
    // @ts-ignore
    let result = await layerView.hitTest(mapPoint, { x: screenPoint.x, y: screenPoint.y });
    result = Context.instance.aiim.fixHitTestResult(result);
    if (Array.isArray(result) && result.length > 0) {
      const tol = getTolerance();
      result.forEach(hit => {
        const graphic = hit && hit.graphic;
        if (graphic && graphic.geometry && graphic.geometry.rings) {
          const xMapPoint = mapPoint.x;
          const yMapPoint = mapPoint.y;

          graphic.geometry.rings.forEach((ring,ringIndex) => {
            ring.forEach((coords,coordsIndex) => {
              const x = coords[0];
              const xNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][0];
              //const xPrev = ring[coordsIndex - 1] && ring[coordsIndex - 1][0];
              const y = coords[1];
              const yNext = ring[coordsIndex + 1] && ring[coordsIndex + 1][1];
              //const yPrev = ring[coordsIndex - 1] && ring[coordsIndex - 1][1];
              if (typeof xNext === "number") {
                const result = vectorUtil.projectPointOnLine(
                  {x: x, y: y},
                  {x: xNext, y: yNext},
                  {x: xMapPoint, y: yMapPoint}
                )
                if (result && result.d < tol) {
                  result.ring = ring.slice();
                  segments.push(result)
                }
              }
            })
          })

        }
      })
    }
  }
  sortSegments(segments);
  return segments;
}

export function reflectPoint(point,v1,v2) {
  const result = vectorUtil.projectPointOnLine(
    v1,
    v2,
    {x: point.x, y: point.y}
  )
  const pPrime = result && result.pPrime;
  if (pPrime) {
    let x = pPrime.x + (pPrime.x - point.x);
    let y = pPrime.y + (pPrime.y - point.y);
    point = new Context.instance.lib.esri.Point({
      hasZ: true,
      x: x,
      y: y,
      z: point.z,
      spatialReference: point.spatialReference
    })
  }
  return point;
}

export function reflectPolyOrPolyline(geometry,anchor1,anchor2,flip) {

  if (!anchor2) {
    const ge = Context.instance.lib.esri.geometryEngine;
    let g;
    if (flip) g = ge.flipHorizontal(geometry,anchor1);
    else g = ge.flipVertical(geometry,anchor1);
    return g;
  }

  if (flip) {
    let baseline = new Context.instance.lib.esri.Polyline({
      hasZ: false,
      paths: [
        [
          [anchor1.x,anchor1.y],
          [anchor2.x,anchor2.y]
        ]
      ],
      spatialReference: geometry.spatialReference.clone()
    })
    let midpoint = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: (anchor2.x + anchor1.x) / 2,
      y: (anchor2.y + anchor1.y) / 2,
      spatialReference: geometry.spatialReference.clone()
    })
    const ge = Context.instance.lib.esri.geometryEngine;
    let rotated = ge.rotate(baseline,90,midpoint);
    //rotated = baseline
    anchor1 = rotated.getPoint(0,0).clone();
    anchor2 = rotated.getPoint(0,1).clone();
  }

  // extend the line
  let a = anchor1, b = anchor2, dist = 1000;
  const len = Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  const cx = a.x + (a.x - b.x) / len * dist;
  const cy = a.y + (a.y - b.y) / len * dist;
  const cx2 = b.x + (b.x - a.x) / len * dist;
  const cy2 = b.y + (b.y - a.y) / len * dist;
  let v1 = {
    x: cx,
    y: cy
  }
  let v2 = {
    x: cx2,
    y: cy2
  }

  const geometry2 = geometry.clone();
  geometry2.hasZ = true;
  const paths = geometry.paths || geometry.rings;
  paths.forEach((path,pathIndex) => {
    path.forEach((coords,coordIndex) => {
      const point = new Context.instance.lib.esri.Point({
        hasZ: true,
        x: coords[0],
        y: coords[1],
        z: coords[2],
        spatialReference: geometry.spatialReference
      })
      const point2 = reflectPoint(point,v1,v2);
      if (point2) geometry2.setPoint(pathIndex,coordIndex,point2)
    })
  })
  geometry2.hasZ = true;
  return geometry2;
}

function sortSegments(segments) {
  segments.sort((a,b) => {
    return (a.d - b.d);
  })
}

export function supportsReflect(stencil) {
  //return !!(stencil && stencil.anchor1 && stencil.anchor2);
  return !!(stencil && stencil.anchor1);
}

export function translate(stencil,point,z) {
  let geometry = stencil.geometry.clone();
  geometry.hasZ = true;
  let cen = stencil.geometry.extent.center;
  let dx = point.x - cen.x;
  let dy = point.y - cen.y;
  if (stencil.anchor1 && typeof stencil.anchor1.dx === "number" && typeof stencil.anchor1.dy === "number") {
    dx += stencil.anchor1.dx;
    dy += stencil.anchor1.dy;
  }
  geometry = translateGeometry(geometry,dx,dy,z)
  return {
    geometry: geometry,
    dx: dx,
    dy: dy
  }
}

export function translateInsitu(stencilItem,insituProps,dx,dy) {
  let geometry = translateGeometry(insituProps.geometry,dx,dy,stencilItem.z)
  let anchor1, anchor2, anchorCen;
  if (insituProps.anchor1) {
    anchor1 = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: (insituProps.anchor1.x) + dx,
      y: (insituProps.anchor1.y) + dy,
      spatialReference: stencilItem.stencil.geometry.spatialReference
    })
  }
  if (insituProps.anchor2) {
    anchor2 = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: (insituProps.anchor2.x) + dx,
      y: (insituProps.anchor2.y) + dy,
      spatialReference: stencilItem.stencil.spatialReference
    })
  }
  if (anchor1 && anchor2) {
    anchorCen = new Context.instance.lib.esri.Point({
      hasZ: false,
      x: (anchor1.x + anchor2.x) / 2,
      y: (anchor1.y + anchor2.y) / 2,
      spatialReference: stencilItem.stencil.spatialReference
    })
  }
  return {
    geometry: geometry,
    anchor1: anchor1,
    anchor2: anchor2,
    anchorCen: anchorCen
  }
}

export function translateGeometry(geometry,dx,dy,z) {
  if (geometry.type === "point") {
    return translatePoint(geometry,dx,dy,z);
  } else if (geometry.type === "polyline") {
    return translatePolyline(geometry,dx,dy,z);
  } else if (geometry.type === "polygon") {
    return translatePolygon(geometry,dx,dy,z);
  } else {
    return geometry;
  }
}

export function translatePoint(point,dx,dy,z) {
  const geometry = new Context.instance.lib.esri.Point({
    hasZ: true,
    x: point.x + dx,
    y: point.y + dy,
    z: z,
    spatialReference: point.spatialReference
  })
  geometry.hasZ = true;
  return geometry;
}

export function translatePolygon(polygon,dx,dy,z) {
  const geometry = polygon.clone();
  geometry.hasZ = true;
  polygon.rings.forEach((ring,ringIndex) => {
    ring.forEach((coords,coordIndex) => {
      const x = coords[0];
      const y = coords[1];
      const pt = new Context.instance.lib.esri.Point({
        hasZ: true,
        x: x + dx,
        y: y + dy,
        z: z,
        spatialReference: geometry.spatialReference
      })
      geometry.setPoint(ringIndex,coordIndex,pt)
    })
  })
  geometry.hasZ = true;
  return geometry;
}

export function translatePolyline(polyline,dx,dy,z) {
  const geometry = polyline.clone();
  geometry.hasZ = true;
  const paths = geometry.paths || geometry.rings;
  paths.forEach((path,pathIndex) => {
    path.forEach((coords,coordIndex) => {
      const x = coords[0];
      const y = coords[1];
      const pt = new Context.instance.lib.esri.Point({
        hasZ: true,
        x: x + dx,
        y: y + dy,
        z: z,
        spatialReference: geometry.spatialReference
      })
      geometry.setPoint(pathIndex,coordIndex,pt)
    })
  })
  geometry.hasZ = true;
  return geometry;
}