import React from "react";

import Context from "../../../context/Context";
import EventItem from "./EventItem";
import Topic from "../../../context/Topic";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../util/component";
import * as eventUtil from "./eventUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";

const CSS = {
  main: "i-events-items",
  moreButton: "i-category-more-button",
  workingIcon: "i-icon-working",
  noMatch: "i-note i-padded i-font-size-s",
  divider: "event-divider"
};

export default class EventItems extends React.Component {

  _activePromise;
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = component.newState({
      isWorking: true,
      exceededTransferLimit: false,
      featureCount: 0,
      featureItems: null,
      wasMore: false,
      where: null
    });
    this.moreClicked = this.moreClicked.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.queryFeatures();

    component.own(this,[
      Topic.subscribe(Topic.EventsFilterSet,(params) => {
        if (!this._mounted) return;
        this.setState(state => {
          return {
            isWorking: true,
            where: params.where
          };
        });
        this.queryFeatures(params.where);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    this._mounted = false;
  }

  moreClicked(event) {
    if (this.state.isWorking) return;
    this.setState(state => {
      return {
        isWorking: true
      };
    });
    this.queryFeatures(null,true);
  }

  queryFeatures(where,isMore) {
    const source = aiimUtil.getSource(this.props.sourceKey);
    const dataset = eventUtil.getDataset();
    const lastCount = this.state.featureCount;
    where = where || this.state.where || this.props.where;
    if (!where) where = "1=1";
    //console.log("EventItems::queryFeatures - where",where);
    const promise = this._activePromise = eventUtil.queryFeatures(dataset,where,isMore,lastCount);
    promise.then(result => {
      if (!this._mounted) return;
      if (promise !== this._activePromise) return;
      this._activePromise = null;
      if (result && result.features) {
        const resultInfo = eventUtil.makeResultInfo(source,result);
        let featureItems = resultInfo.featureItems;
        let featureCount = featureItems.length;
        if (isMore) {
          const prevFeatureItems = this.state.featureItems;
          if (prevFeatureItems && prevFeatureItems.length > 0) {
            featureItems = prevFeatureItems.concat(featureItems);
            featureCount = featureItems.length;
          }
        }
        this.setState(state => {
          return {
            isWorking: false,
            exceededTransferLimit: result.exceededTransferLimit,
            featureCount: featureCount,
            featureItems: featureItems,
            wasMore: isMore
          };
        })
      } else {
        if (isMore) {
          // TODO?????
        } else {
          this.setState(state => {
            return {
              isWorking: false,
              exceededTransferLimit: false,
              featureCount: 0,
              featureItems: [],
              wasMore: false
            };
          });
        }
      }
    }).catch(ex => {
      console.warn("Error querying event features:");
      console.error(ex);
      if (!this._mounted) return;
      if (promise === this._activePromise) {
        this._activePromise = null;
        this.setState(state => {
          return {
            isWorking: false
          };
        });
        Topic.publishErrorAccessingData();
      }
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const nodes = [];
    const source = aiimUtil.getSource(this.props.sourceKey);
    const exceededTransferLimit = this.state.exceededTransferLimit;
    const featureItems = this.state.featureItems;
    if (Array.isArray(featureItems)) {
      const now = Date.now();
      const futureEvents = featureItems.filter(x => {
        const dateEnd = aiimUtil.getAttributeValue(x.feature.attributes, FieldNames.EVENT_DATE_END);
        return now < dateEnd
      });
      const pastEvents = featureItems.filter(x => {
        const dateEnd = aiimUtil.getAttributeValue(x.feature.attributes, FieldNames.EVENT_DATE_END)
        return now > dateEnd
      });
      // If user uses any date filter
      if (this.state.where === '1=1') {
        if (futureEvents.length > 0) {
          futureEvents.forEach((event, index) => {
            const nd = this.renderItem(source, event, index);
            if (nd) nodes.push(nd);
          });
        }
        if (pastEvents.length > 0) {
          pastEvents.sort((a, b) => {
            const aDateStart = aiimUtil.getAttributeValue(a.feature.attributes, FieldNames.EVENT_DATE_START);
            const bDateStart = aiimUtil.getAttributeValue(b.feature.attributes, FieldNames.EVENT_DATE_START);
            return aDateStart < bDateStart ? 1 : -1
          });
          const dividerPast = (
            <div className={CSS.divider} key="pastEvents">
              <small><strong>{i18n.events.pastEvents}</strong></small>
            </div>
          );
          nodes.push(dividerPast)
          pastEvents.forEach((event, index) => {
            const nd = this.renderItem(source, event, index)
            if (nd) nodes.push(nd);
          })
        }
      // In other cases like 'This Week' we don't sort it
      } else {
        featureItems.forEach((featuresItem,index) => {
          const nd = this.renderItem(source,featuresItem,index);
          if (nd) nodes.push(nd);
        });
      }
    }
    let working = null, content = null, noMatch = null;
    if (this.state.isWorking) {
      working = (<span key="workingIcon" className={CSS.workingIcon}></span>);
    }
    if (nodes.length > 0) {
      content = (
        <ul className={CSS.main} aria-label={i18n.events.itemsAriaLabel}>
          {nodes}
          {this.renderMore(nodes.length,exceededTransferLimit)}
        </ul>
      );
    } else if (!working) {
      noMatch = (<p role="alert" className={CSS.noMatch}>{i18n.events.noMatch}</p>);
    }
    return (
      <>
        {working}
        {content}
        {noMatch}
      </>
    );
  }

  renderItem(source,featureItem,index) {
    const key = "item_"+featureItem.key;
    return (
      <EventItem key={key} sourceKey={source.key} featureItem={featureItem} />
    );
  }

  renderMore(nodeCount,exceededTransferLimit) {
    const i18n = Context.getInstance().i18n;
    const name = i18n.explore.category.more;
    const hashref = "#";
    if (nodeCount > 0 && exceededTransferLimit) {
      let working = null;
      if (this.state.isWorking) {
        working = (
          <span key="workingIcon" className={CSS.workingIcon}></span>
        );
      }
      return (
        <li key="moreButton">
          <a href={hashref} className={CSS.moreButton} onClick={this.moreClicked}>
            {name}
            {working}
          </a>
        </li>
      );
    }
    return null;
  }

}
