import React from "react";

import About from "../../common/About/About";
import Context from "../../../context/Context";
import DropdownButton from "../../common/Dropdown/DropdownButton";
import Icons from "../../util/Icons";
import Topic from "../../../context/Topic";
import Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import { getLoggedInUser, logout } from "../More/Actions/BookWorkspace/WorkspaceReservation/OfficeHotelingInterface";
import * as component from "../../util/component";
import icon from "./ArcGIS_Indoors_Round.png";

const CSS = {
  main: "i-burger",
  burgerButton: "i-burger-button i-icon-button",
  brandLogo: "i-brand-logo",
  brandInfo: "i-brand-info",
  brandName: "i-brand-name",
  link: "i-link",
  iconLabel: "i-icon-label",
  userIcon: "i-user-icon",
  signoutButton: "i-signout-button i-link",
  menuButton: "i-menu-button"
};

export default class Burger extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({
      logoUrl: Context.getInstance().uiMode.getLogoUrl(),
      logoName: Context.getInstance().uiMode.getLogoName(),
      logoSize: Context.getInstance().uiMode.getLogoSize()
    });

    this.aboutClicked = this.aboutClicked.bind(this);
    this.signinClicked = this.signinClicked.bind(this);
    this.signoutClicked = this.signoutClicked.bind(this);
  }

  aboutClicked() {
    const about = <About />;
    Topic.publish(Topic.ShowModal,{content: about});
  }

  componentDidMount(){
    component.own(this,[
      Topic.subscribe(Topic.ConfigurationDiscarded,() => {
        this.setState({
          logoUrl: Context.getInstance().uiMode.getLogoUrl(),
          logoName: Context.getInstance().uiMode.getLogoName(),
          logoSize: Context.getInstance().uiMode.getLogoSize()
        })
      }),
      Topic.subscribe(Topic.LogoChanged, ()=>{
        this.setState({
          logoUrl: Context.getInstance().uiMode.getLogoUrl(),
          logoName: Context.getInstance().uiMode.getLogoName(),
          logoSize: Context.getInstance().uiMode.getLogoSize()
        })
      }),
      Topic.subscribe(Topic.LogonameChanged,()=>{
        this.setState({
          logoName: Context.getInstance().uiMode.getLogoName()
        })
      }),
      Topic.subscribe(Topic.BookingLogin, params => {
        this.forceUpdate()
      }),
      Topic.subscribe(Topic.AppIdUpdated, params => {
        this.forceUpdate()
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  configureClicked = () => {
    const uiMode = Context.instance.uiMode
    if (uiMode.isConfiguratorOn) {
      Topic.publish(Topic.Configurator_SetExpanded,{});
    } else {
      uiMode.toggleConfigurator(true);
      Topic.publish(Topic.ConfiguratorActivated,{});
    }
  }

  render() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const buttonContent = (
      <div key="i-burger-dropdown" className={CSS.main}
        aria-label={i18n.general.options}>
        <span>{Icons.burger()}</span>
      </div>
    );
    const dropdownContent = this.renderDropdownMenu();
    return (
      <div className={CSS.main}>
        <DropdownButton
          closeOnSelect={true}
          focusFirst={true}
          buttonClassName={"i-burger-button i-icon-button"}
          buttonContent={buttonContent}
          dropdownContent={dropdownContent}
          placement="bottom-start">
        </DropdownButton>
      </div>
    );

  }

  renderBrand() {
    const logoSize = this.state.logoSize;
    const url = this.state.logoUrl;
    if (typeof url === "string" && url.length > 0) {
      let name = "";
      const style = {width: "auto", height: logoSize + "rem"};
      const v = this.state.logoName;
      if (typeof v === "string" && v.length > 0) name = v;
      return (
        <li key="brandItem" role="none">
          <img className={CSS.brandLogo} src={url} style={style}
            alt={name} title={name}/>
          <div className={CSS.brandInfo}>
            <div className={CSS.brandName}>{name}</div>
          </div>
        </li>
      );
    }
    return null;
  }

  renderDropdownMenu() {
    const context = Context.getInstance();
    const i18n = context.i18n;
    const user = context.user;
    const hashref = "#";

    let configure = null;
    const canConfigure = Context.instance.user.canConfigureApp();
    if (canConfigure) {
      configure = (
        <li key="configure"  role="none">
          <a href={hashref} role="menuitem button" tabIndex="0"
            className={CSS.menuButton} onClick={this.configureClicked}
            aria-label={i18n.configurator.buttonLabel}>
            <span key="configureIcon">{Icons.edit()}</span>
            <span key="configureLbl" className={CSS.iconLabel}
              >{i18n.configurator.buttonLabel}</span>
          </a>
        </li>
      );
    }

    let logoutBookingSystem = null
    let bookingSystem = Office365.getInstance()
    const bookingUser = getLoggedInUser(bookingSystem)
    // Check configurator
    const configuration = Context.instance.configuration
    const configurables = configuration.extractConfigurables()
    const hotelingEnabled = configurables.workspaceReservation && configurables.workspaceReservation.enabled
    if (bookingUser && hotelingEnabled) {
      let label = i18n.more.bookWorkspace.logout
      label = label.replace("{system}", "Office365")
      const logoutClicked = () => {
        logout(bookingSystem)
      }
      logoutBookingSystem = (
        <li key="signoutItem" role="none">
          <a key="signoutBtn" className={"i-burger-signout"} href={hashref} tabIndex="0"
              role="menuitem button" aria-label={label}
              onClick={logoutClicked}>
            <span className={"i-burger-usericon"}>{Icons.office365()}</span>
            <div className="i-burger-userinfo">
              <span className={"i-burger-username"}>{bookingUser.userName}</span>
              <span key="signoutLbl" className={"i-burger-signout-label"}>
                {label}
              </span>
            </div>
          </a>
        </li>
      );
    }

    let signin = null, signout = null;
    if (user.isAnonymous()) {
      signin = (
        <li key="signinItem" role="none">
          <a key="signinBtn" className={CSS.menuButton} href={hashref} tabIndex="0"
            role="menuitem button" aria-label={i18n.user.signin}
            onClick={this.signinClicked}>
            <span key="userIcon" className={CSS.userIcon}>{Icons.user()}</span>
            <span key="signinLbl" className={CSS.iconLabel}>{i18n.user.signin}</span>
          </a>
        </li>
      );
    } else {
      const username = Context.getInstance().user.getDisplayName();
      signout = (
        <li key="signoutItem" role="none">
          <a key="signoutBtn" className={"i-burger-signout"} href={hashref} tabIndex="0"
              role="menuitem button" aria-label={i18n.more.bookWorkspace.logoutIndoors}
              onClick={this.signoutClicked}>
            <span className={"i-burger-usericon"}>
              <img src={icon} alt="Logo" />
            </span>
            <div className="i-burger-userinfo">
              <span className={"i-burger-username"}>{username}</span>
              <span key="signoutLbl" className={"i-burger-signout-label"}>
                {i18n.more.bookWorkspace.logoutIndoors}
              </span>
            </div>
          </a>
        </li>
      );
    }

    const about = (
      <li key="aboutItem" role="none">
        <a key="aboutBtn" className={CSS.menuButton}
          href={hashref} tabIndex="0"
          role="menuitem button" aria-label={ i18n.about.caption}
          onClick={this.aboutClicked}>
          <span key="aboutIcon">{Icons.info()}</span>
          <span key="aboutLbl" className={CSS.iconLabel}>{i18n.about.caption}</span>
        </a>
      </li>
    );

    const menu = (
      <ul className={"i-burger-menu"} role="menu">
        {this.renderBrand()}
        {signin}
        {signout}
        {logoutBookingSystem}
        {configure}
        {about}
      </ul>
    );
    return menu;
  }

  signinClicked() {
    Topic.publish(Topic.SignInClicked,{});
  }

  signoutClicked() {
    Topic.publish(Topic.SignOutClicked,{});
  }

}
