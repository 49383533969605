import React from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';
import {connect} from "react-redux";
import Rdx from "../../../../../redux/Rdx";

// 3rd party
import Loader from "calcite-react/Loader";
import XIcon from "calcite-ui-icons-react/XIcon";
import PencilIcon from "calcite-ui-icons-react/PencilIcon";

// aiim.base
import ItemReference from '../../../../../aiim/base/ItemReference';

// aiim.datasets
import FieldNames from '../../../../../aiim/datasets/FieldNames';

// aiim.util
import { getAttributeValue, getRecurrencePattern } from '../../../../../aiim/util/aiimUtil';

// components.util
import * as component from '../../../../util/component';
import * as checkInOutUtil from "./CheckInOutUtil";
import * as dateUtil from "../../../Events/dateUtil";

// context
import Context from '../../../../../context/Context';
import Topic from '../../../../../context/Topic';
import SignInIcon from "calcite-ui-icons-react/SignInIcon";
import SignOutIcon from "calcite-ui-icons-react/SignOutIcon";
import ClockIcon from 'calcite-ui-icons-react/ClockIcon';
import PinIcon from 'calcite-ui-icons-react/PinIcon';
import RecurrenceIcon from "calcite-ui-icons-react/RecurrenceIcon";
import Button from "calcite-react/Button";

import { ModalController }  from "../../../../../../src/common/components/Modal/index";
import { cancelBooking, updateCheckInOut } from "./WorkspaceReservation/OfficeHotelingInterface";
import * as workspaceReservationUtil from "./WorkspaceReservation/workspaceReservationUtil";

// util
import CalendarSelector from '../../../../../util/CalendarSelector';
import BookingCancel from './BookingCancel';
import BookingConfirmed from './BookingConfirmed';
import RecurrenceDropdown, { RecurrenceType } from './RecurrenceDropdown';

import { stringFormatter } from '../../../../../util/formatUtil';
import { BookingType, makeEventInfo } from '../../../../../util/calendarUtil';
import Icons from '../../../../util/Icons';
import { isNetworkError } from '../../../../../util/networkUtil';
import { IRecurrenceOptions, IRecurrenceSeries } from './BookingRecurrence';
import { IApplyEditsResult } from '@esri/arcgis-rest-feature-layer';
import { IBookingTask, IBookingData, IBookingParams, IBookWorkspaceResults } from './WorkspaceReservation/BookingSystem';
import { BOOKING_STATE } from './WorkspaceReservation/EsriReservationSchema';
import { IBookingDateFilter } from '../../../Events/BookingDateFilter';
import { ICheckAvailabilityOptions } from './BookingDetails';
export type { IBookingData };

const CSS = {
  bookingCardList: "i-booking-card-list",
  bookingCard: "i-booking-card",
  locationDescription: "i-booking-location-description",
  button: "i-book-button",
  buttonCheckIn: "i-book-card-button-checkIn",
  buttonCheckOut: "i-book-card-button-checkOut",
  actionGroup: "i-action-group",
  actionSubGroup: "i-action-subgroup",
  addToCalendar: "i-booking-add-calendar",
  cancel: "i-booking-cancel",
  edit: "i-booking-edit",
  descriptionContent: "i-booking-description",
  bookingInfoLine: "i-booking-info-line",
  recurrenceIcon: "i-recurrence-icon"
};

const APPROVED = 1;
const CHECKED_IN = 4;
export interface IBookingsProps {
  bookingDateFilter: IBookingDateFilter,
  listingType?: "self" | "others"
}
export interface IBookingsState {
  bookings?: IBookingData[],
  isWorking: boolean,
  series?: Map<string, IRecurrenceSeries>,
  sorted?: IBookingData[]
}

/** Returns a map of recurrence IDs and the associated occurrences within each series.
 * @param bookings - An array of all bookings for a user. 
 */
export function getRecurrenceSeries(bookings: IBookingData[]) {
  const { reservations } = Context.getInstance().aiim.datasets;
  const sorted = bookings
    .filter(s => s.recurrenceId?.length > 0 && s.booking.attributes[reservations.stateField] === APPROVED)
    .sort((a, b) => {
      const startTimeA = getAttributeValue(a.booking.attributes, FieldNames.START_TIME);
      const startTimeB = getAttributeValue(b.booking.attributes, FieldNames.START_TIME);
      return startTimeA > startTimeB ? 1 : startTimeA < startTimeB ? -1 : 0;
    });
  const series = sorted.reduce((acc, curr) => {
    if (acc.has(curr.recurrenceId)) {
      acc.get(curr.recurrenceId).occurrences.push(curr.booking);
      acc.get(curr.recurrenceId).allOccurrences.push(curr.booking);
    } else {
      const seriesBookings = sorted.filter(s => s.recurrenceId.length > 0 && s.recurrenceId === curr.recurrenceId);
      // use first booking to get the series start DATE
      const first = seriesBookings[0];
      const seriesStartDate = moment(getAttributeValue(first?.booking.attributes, FieldNames.START_TIME));
      // use first non-modified booking to get the series start TIME
      const masterEvent = seriesBookings.find(b => getRecurrencePattern(b.booking.attributes)?.modified !== "occurrence");
      const checkIn = moment(getAttributeValue(masterEvent?.booking.attributes, FieldNames.START_TIME));
      const checkOut = moment(getAttributeValue(masterEvent?.booking.attributes, FieldNames.END_TIME));
      acc.set(curr.recurrenceId, {
        id: curr.recurrenceId,
        occurrences: [curr.booking],
        allOccurrences: [curr.booking],
        checkIn: seriesStartDate.clone().set({
          hour: checkIn.hour(),
          minute: checkIn.minute(),
          second: checkIn.second(),
          millisecond: checkIn.millisecond()
        }).toDate(),
        checkOut: seriesStartDate.clone().set({
          hour: checkOut.hour(),
          minute: checkOut.minute(),
          second: checkOut.second(),
          millisecond: checkOut.millisecond()
        }).toDate(),
        options: getRecurrencePattern(curr.booking.attributes)
      });
    }
    return acc;
  }, new Map<string, IRecurrenceSeries>());
  return series;
}
/** Determines if two recurrence patterns match. */
export function recurringSeriesMatch(a: IRecurrenceOptions, b: IRecurrenceOptions) {
  return !!(a && b && a.enabled === b.enabled
    && a.type === b.type
    && moment(a.endDate).isSame(moment(b.endDate), "day")
    && (a.type === "weekly" ? a.days?.length === b.days?.length && a.days?.every((e, i) => e === b.days[i]) : true));
}
class MyBookings extends React.Component<IBookingsProps, IBookingsState> {

  listReminders = {
    checkIn: [],
    checkOut: []
  };
  remindersShown = {
    checkIn: false,
    checkOut: false
  };
  where;
  updateICSfile = false;

  constructor(props: IBookingsProps) {
    super(props);

    this.state = component.newState({
      bookings: [],
      sorted: [],
      isWorking: true
    });
  }

  async componentDidMount() {
    await this._getUserReservations();
    component.own(this, [
      Topic.subscribe(Topic.RenderBookingList, async() => {
        this.setState({isWorking: true})
        this.updateICSfile=true;
        await this._getUserReservations();
      })
    ])
  }

  componentDidUpdate(prevProps: IBookingsProps, prevState: IBookingsState) {
    if (prevProps.bookingDateFilter !== this.props.bookingDateFilter) {
      this.setState({isWorking: true});
      this._getUserReservations();
    }
    if (prevState.bookings !== this.state.bookings) {
      const { bookings } = this.state;
      const sorted = []
      const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
      if (reservations) {        
        const filteredBookings = this.filterCheckedInBookings(bookings);
        sorted.push(...this.sortBookings(filteredBookings));

        /* Disabled
        // Do not show check in prompt when Booked tab is activated #8191
        this.listReminders = checkInOutUtil.getRemindersList(sorted, "mybookings");
        if (this.listReminders && this.listReminders.checkIn && this.listReminders.checkIn.length > 0) {
          if (!this.remindersShown["checkIn"]) {
            checkInOutUtil.showReminderPopup("checkIn", this.listReminders, "mybookings");
            this.remindersShown["checkIn"] = true;
          }
        } else if (this.listReminders && this.listReminders.checkOut && this.listReminders.checkOut.length > 0) {
          if (!this.remindersShown["checkOut"]) {
            checkInOutUtil.showReminderPopup("checkOut", this.listReminders, "mybookings");
            this.remindersShown["checkOut"] = true;
          }
        }
        */

      }
      this.setState({ sorted });
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _getUserReservations = async () => {
    checkInOutUtil.getUserReservations("mybookings", this.props.bookingDateFilter)
    .then((res)=> {
      this.setState({
        bookings : res?.bookings,
        isWorking: res?.isWorking,
        series: res?.series
      })
    })
    .catch(error => {
      console.error(error);
    })
  }

  sortBookings = (bookings: IBookingData[]) => {
    return bookings.sort((resA, resB) => {
      const attrsA = resA.booking.attributes;
      const attrsB = resB.booking.attributes;

      const startTimeA = getAttributeValue(attrsA, FieldNames.START_TIME);
      const startTimeB = getAttributeValue(attrsB, FieldNames.START_TIME);

      if (startTimeA > startTimeB) {
        return 1;
      } else if (startTimeA < startTimeB) {
        return -1
      }
      return 0;
    });
  }

  /**
   * Filter out the checked in bookings that are past their grace period (11:59 PM the day after the end time)
   * 
   * @param {any[]} bookings 
   * @returns Filtered bookings
   */
  filterCheckedInBookings = (bookings: IBookingData[]) => {
    return bookings.filter((reservation) => {
      const attrs = reservation.booking.attributes;
      const state = getAttributeValue(attrs, FieldNames.STATE);
      const endTime = getAttributeValue(attrs, FieldNames.END_TIME);

      const now = moment();
      const mEndTime = moment(endTime);

      return state === CHECKED_IN ? now < mEndTime.endOf("day").add(1, "day") : true;
    });
  }

  getRemindersList = (sortedBookings: IBookingData[]) => {
    if (!sortedBookings) {
      return;
    }

    this.listReminders = {
      checkIn: [],
      checkOut: []
    };
    sortedBookings.forEach((data) => {
      // Get field values
      const attributes = data.booking.attributes;
      const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
      const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
      const state = getAttributeValue(attributes, FieldNames.STATE);

      // Comparisons
      const startTimeM = moment(startTime);
      const currentTime = moment();
      const elapsed = startTimeM.diff(currentTime, 'seconds');
      const needsCheckIn = (0 <= elapsed && elapsed <= 300) || (startTime <= currentTime && currentTime <= endTime);
      const needsCheckOut = currentTime.isAfter(moment(endTime));

      if (state === APPROVED && needsCheckIn) {
        this.listReminders.checkIn.push(attributes);
      } else if (state === CHECKED_IN && needsCheckOut) {
        this.listReminders.checkOut.push(attributes);
      }
    });
  }

  makeBookingList = () => {
    const { series, sorted } = this.state;
    const reservations = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.reservations;
    if (!reservations) {
      return [];
    }

    const myBookingsList: JSX.Element[] = [];
    sorted.forEach((data) => {
      const reservation = data.booking;
      const id = getAttributeValue(reservation.attributes, reservations.getObjectIdField());
      const state = getAttributeValue(reservation.attributes, FieldNames.STATE);
      const recurrenceId = getAttributeValue(reservation.attributes, FieldNames.RECURRENCE_ID);
      const recurrenceConfig = getRecurrencePattern(reservation.attributes);
      const isOccurrenceModified = recurrenceConfig?.modified === "occurrence"
      const isRecurring = series?.has(recurrenceId);
      const showButton = state === CHECKED_IN ? "checkOut" : state === APPROVED ? "checkIn" : undefined;
      if (state === APPROVED || state === CHECKED_IN) {
        myBookingsList.push(
          <MyBookingsCard 
            showButton={showButton} 
            key={id} 
            data={data} 
            updateICSfile={this.updateICSfile}
            isRecurring={isRecurring}
            isOccurrenceModified={isOccurrenceModified}
            series={series?.get(recurrenceId)}
          />
        );
      }
    });

    return myBookingsList;
  }

  /**
   * Show a reminder popup to prompt the user to check in/out
   * 
   * @param {"checkIn" | "checkOut"} type 
   */
  showPopup = async (type) => {
    // Let's not keep showing these after the specific reminder has been shown once
    if (this.remindersShown[type]) {
      return;
    }
    this.remindersShown[type] = true;

    const i18n = Context.getInstance().i18n;
    const { listReminders } = this;
    const reminders = listReminders[type];

    const reminderUnitNames = reminders.map((reminder) => getAttributeValue(reminder, FieldNames.UNIT_NAME)).join(", ");
    const template = type === "checkIn" 
      ? i18n.more.bookWorkspace.checkInOut.reminder.newMessage 
      : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
    const message = stringFormatter(template, { units: reminderUnitNames, hotel: reminderUnitNames });

    const options = {
      title: i18n.more.bookWorkspace.checkInOut.reminder.caption,
      okLabel: i18n.more.bookWorkspace.checkInOut.reminder.close,
      message: message,
      showOkCancel: true,
      hideCancel : true,
      closeOnOK: true
    };

    const refresh = () => {
      Topic.publish(Topic.RenderBookingList, {});
      Topic.publish(Topic.UpdateReservationDetails, {});
    }

    const showToast = () => {
      Topic.publish(Topic.ShowToast, {
        message: i18n.more.bookWorkspace.checkInOut[type].toastMessage
      });
    }

    if (reminders.length === 1) {
      const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
      const bookingSystem = workspaceReservationUtil.getHotelBookingSystem();
      const attributes = reminders[0];

      if (type === "checkIn") {
        attributes[reservations.checkInTimeField] = new Date().getTime();
      } else if (type === "checkOut") {
        attributes[reservations.checkOutTimeField] = new Date().getTime();
      }

      const unitName = attributes[reservations.unitNameField];
      const template = type === "checkIn" 
        ? i18n.more.bookWorkspace.checkInOut.checkIn.bookingReady
        : i18n.more.bookWorkspace.checkInOut.checkOut.reminderMessageAlt;
      const message = stringFormatter(template, { unit: unitName, hotel: unitName });

      options.message = message;
      options["okLabel"] = i18n.more.bookWorkspace[type];
      options["hideCancel"] = false;
      options["cancelLabel"] = i18n.more.bookWorkspace.checkInOut.notNow;

      try {
        if (!reservations.canUserEditFeature(attributes)) {
          const reservationManager = getAttributeValue(attributes, FieldNames.RESERVED_BY_FULL_NAME);
          checkInOutUtil.showNoEditPopup(type, reservationManager);
          return;
        }
        
        const result = await ModalController.confirm(options);
        if (result && result.ok) {
          const updateResponse = await updateCheckInOut(bookingSystem, attributes, type);
          if (
            updateResponse && 
            updateResponse.updateFeatureResults && 
            updateResponse.updateFeatureResults[0] && 
            updateResponse.updateFeatureResults[0].error
          ) {
            throw new Error(updateResponse.updateFeatureResults[0].error);
          } else {
            showToast();
            refresh();
          }
        }
      } catch (error) {
        console.error(error);
        Topic.publishErrorUpdatingData(error);
      } finally {
        if (type === "checkIn" && this.listReminders.checkOut.length > 0) {
          await this.showPopup("checkOut");
        }
      }
    } else {
      try {
        await ModalController.confirm(options);
      } catch (error) {
        console.error(error);
        Topic.publishErrorUpdatingData(error);
      } finally {
        if (type === "checkIn" && this.listReminders.checkOut.length > 0) {
          await this.showPopup("checkOut");
        }
      }
    }
  }

  renderBookingCards() {
    const i18n = Context.getInstance().i18n;
    const bookingList = this.makeBookingList();

    return bookingList && bookingList.length > 0 ? (
      <ul className={CSS.bookingCardList}>
        {bookingList}
      </ul>
    ) : (
      <div style={{ padding: '0.7rem 1rem' }}>{i18n.more.bookWorkspace.noBookings}</div>
    );
  }

  renderWorking() {
    return (<Loader sizeRatio={1} style={{ marginTop: '3rem' }} />);
  }

  render() {
    const { isWorking } = this.state;
    return isWorking ? this.renderWorking() : this.renderBookingCards();
  }
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
export interface IBookingCardProps {
  data: { booking: __esri.Graphic, unit?: __esri.Graphic },
  isRecurring: boolean,
  isOccurrenceModified?: boolean,
  series?: IRecurrenceSeries,
  showButton: "checkIn" | "checkOut",
  updateICSfile: boolean
}
export interface IBookingCardState {
  hideCheckIn: boolean,
  popoverOpen: boolean,
  popoverType?: "calendar" | "cancel" | "edit",
  showButton: "checkIn" | "checkOut",
  unitFeature: __esri.Graphic
}
class MyBookingsCard extends React.Component<IBookingCardProps, IBookingCardState> {
  _componentId;
  _itemRef;
  _accessToken;

  constructor(props) {
    super(props);
    this.state = component.newState({
      showButton: this.props.showButton,
      hideCheckIn: false,
      popoverOpen: false
    });
    this._componentId = component.nextId("booking-");
    this._itemRef = React.createRef();
  }

  componentDidMount() {
    // this._startupApp();
    this._init();
    component.own(this, [
      Topic.subscribe(Topic.InfoPanelClosed, params => {
        if (this._componentId === params.componentId) {
          this._focus();
        }
      })
    ]);
  }

  componentWillUnmount(){
    component.componentWillUnmount(this);
  }

  _init =async()=> {
    const unitAttr = this.props && this.props.data && this.props.data.unit && this.props.data.unit.attributes;
    const unitId = getAttributeValue(unitAttr, FieldNames.UNIT_ID);
    const unitFeatures = await workspaceReservationUtil.queryUnitByUnitId(unitId);
    if (unitFeatures?.features?.length > 0) {
      const unitFeature = unitFeatures.features[0];
      this.setState({ unitFeature });
    }
  }
  private get recurrence(): IRecurrenceOptions {
    const { data: { booking: { attributes } } } = this.props;
    return getRecurrencePattern(attributes);
  }
  _startupApp = () => {
    const { data } = this.props;
    const reservation = data.booking;
    const attributes = reservation.attributes;
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const hideCheckIn = checkInOutUtil.hideCheckInButton(attributes);

    if(state === CHECKED_IN) {
      this.setState({
        showButton : "checkOut",
        hideCheckIn: hideCheckIn
      })
    }else if(state === APPROVED) {
      this.setState({
        showButton : "checkIn",
        hideCheckIn: hideCheckIn
      })
    }
  }

  _focus() {
    if (this._itemRef && this._itemRef.current && this._itemRef.current.focus) {
      this._itemRef.current.focus()
    }
  }

  _bookingCardClicked = () => {
    const { data } = this.props;
    const unit = data.unit;
    const source = Context.instance.aiim.datasets.units.getSource();

    const item = new ItemReference();
    item.fromSearchResult(source.key, { feature: unit, name: source.getTitle(unit) });

    Topic.publish(Topic.ShowSearchResult, {
      sourceKey: source.key,
      searchResult: item.searchResult,
      zoom: true,
      highlight: true,
      trackRecent: true,
      componentId: this._componentId
    });
  }

  
  checkAvailability = async (options: ICheckAvailabilityOptions) => {
    const unitFeature = this.state.unitFeature;
    const info: IBookingTask & { unitFeature: __esri.Graphic } = {
      bookingSystem: workspaceReservationUtil.getHotelBookingSystem(),
      checkInDate: options.checkInDate,
      checkOutDate: options.checkOutDate,
      allDay: options.allDay,
      options:options,
      unitFeature: unitFeature,
      reservation: this.props && this.props.data && this.props.data.booking,
      unit: this.props && this.props.data && this.props.data.unit,
      bookingType: 'hotel',
      renderBookingConfirmed: this.renderBookingConfirmed,
      series: this.props.series
    }
    return workspaceReservationUtil.checkAvailability(info);
  }

  renderBookingConfirmed(data: IBookWorkspaceResults, params: IBookingParams, task: IBookingTask) {
    const node = document.createElement("div");
    document.body.appendChild(node);

    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    const origResAttr = task && task.reservation && task.reservation.attributes;
    const { recurrence } = params;
    let bookingType: BookingType = "single";
    if (recurrence) {
      bookingType = recurrence.modified === "occurrence" ? recurrence.modified : "series";
    }
    ReactDOM.render((
      <BookingConfirmed
        bookingSystemType={workspaceReservationUtil.getHotelBookingSystemType()}
        bookingType={bookingType}
        unitName={task.unitName}
        levelName={task.levelName}
        facilityName={task.facilityName}
        closePopup={onClose}
        data={data}
        unit={params && params.unit}
        updateBooking={task && task.updateBooking}
        origResAttr={origResAttr}
        params={params}
      />
    ), node);
  }
  renderCancelConfirmed(attributes, type: RecurrenceType) {
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);
    
    const node = document.createElement("div");
    const onClose = () => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
        ReactDOM.unmountComponentAtNode(node)
      }
    };

    document.body.appendChild(node);
    ReactDOM.render(
      <BookingCancel
        bookingSystemType={workspaceReservationUtil.getHotelBookingSystemType()}
        bookingType={type}
        unitName={unitName}
        closePopup={onClose}
        data={this.props.data}        
      />, node);
  }
  handleToggle = () => {
    const i18n = Context.getInstance().i18n;
    const reservations = Context.getInstance().aiim.datasets 
                          && Context.getInstance().aiim.datasets.reservations;
    const { data } = this.props;
    const reservation = data.booking;
    const attributes = reservation.attributes;
    const reservationManager = getAttributeValue(attributes, FieldNames.RESERVED_BY_FULL_NAME);
  
    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }

    const bookingSystem = workspaceReservationUtil.getHotelBookingSystem();
    if(this.props && this.props.showButton === "checkOut") {

      // check if user has edit permission on the reservation
      if (!reservations.canUserEditFeature(attributes)) {
        checkInOutUtil.showNoEditPopup("checkOut", reservationManager);
        return;
      }
      const options = {
        title: i18n.more.bookWorkspace.checkInOut.checkOut.caption,
        message: i18n.more.bookWorkspace.checkInOut.checkOut.message,
        okLabel: i18n.more.bookWorkspace.checkInOut.checkOut.yes,
        cancelLabel: i18n.more.bookWorkspace.checkInOut.checkOut.no,
        closeOnOK: true,
        closeOnCancel: true
      };
      ModalController.confirm(options)
      .then(result => {
        if(result.ok) {
          attributes[reservations.checkOutTimeField] = new Date().getTime();
          const objectID = getAttributeValue(attributes,objectIdField);

          updateCheckInOut(bookingSystem, attributes, "checkOut")
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                Topic.publish(Topic.ShowToast,{
                  message: i18n.more.bookWorkspace.checkInOut.checkOut.toastMessage
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && isNetworkError(error.message)) {
              checkInOutUtil.showServerErrorPopup("checkOut", attributes);
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            console.error(error);
          })
        }
      })
      .catch(error => {
        console.error(error);
        Topic.publishErrorUpdatingData(error)
      })
    }else if( this.props && this.props.showButton === "checkIn") {

      // check if user has edit permission on the reservation
      if (!reservations.canUserEditFeature(attributes)) {
        checkInOutUtil.showNoEditPopup("checkIn", reservationManager);
        return;
      }

      const unitName = attributes && getAttributeValue(attributes, FieldNames.UNIT_NAME);
      const message = i18n.more.bookWorkspace.checkInOut.checkIn.newMessage.replace("{unit}", unitName);

      const options = {
        title: i18n.more.bookWorkspace.checkInOut.checkIn.caption,
        message: message,
        okLabel: i18n.more.bookWorkspace.checkInOut.checkIn.yes,
        cancelLabel: i18n.more.bookWorkspace.checkInOut.checkIn.no,
        closeOnOK: true,
        closeOnCancel: true
      };
      ModalController.confirm(options)
      .then(result => {
        if (result.ok) {
          attributes[reservations.checkInTimeField] = new Date().getTime();
          const objectID = getAttributeValue(attributes, objectIdField);
          updateCheckInOut(bookingSystem, attributes, "checkIn")
          .then((res)=> {
            if(res && res.updateFeatureResults && res.updateFeatureResults[0] &&
              res.updateFeatureResults[0].error){
                Topic.publishErrorUpdatingData(res.updateFeatureResults[0].error)
              } else {
                Topic.publish(Topic.ShowToast,{
                  message: i18n.more.bookWorkspace.checkInOut.checkIn.toastMessage
                });
                Topic.publish(Topic.RenderBookingList, {});
                Topic.publish(Topic.UpdateReservationDetails, {objectId : objectID});
              }
          })
          .catch(error => {
            if(error && isNetworkError(error.message)) {
              checkInOutUtil.showServerErrorPopup("checkIn", attributes);
            } else {
              Topic.publishErrorUpdatingData(error);
            }
            console.error(error);
          })
        }
      })
      .catch(error => {
        console.error(error);
        Topic.publishErrorUpdatingData(error)
      })
    }
  }

  _cancelClicked = (type?: RecurrenceType) => {
    const { data, series, isRecurring } = this.props;
    const reservation = data.booking;
    const bookingSystem = workspaceReservationUtil.getHotelBookingSystem();
    const unitName = getAttributeValue(reservation.attributes, FieldNames.UNIT_NAME);
    const bookings = (isRecurring && type!== "occurrence") ? series.occurrences.map(s => s.attributes) : [reservation.attributes];
    const i18n = Context.getInstance().i18n;
    const reservations = Context.getInstance().aiim.datasets.reservations;
    const reservationManager = getAttributeValue(reservation.attributes, FieldNames.RESERVED_BY_FULL_NAME);

    // check if user has edit permission on the reservation(s)
    if (bookings.some(attributes => !reservations.canUserEditFeature(attributes))) {
      checkInOutUtil.showNoEditPopup("cancel", reservationManager);
      return;
    }

    let objectIdField;
    if(reservations && reservations.layer2D && reservations.layer2D.objectIdField) {
      objectIdField = reservations.layer2D.objectIdField
    }
    const objectID = getAttributeValue(reservation.attributes, objectIdField);
    let msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.message;
    if (unitName) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.newMessage;
      msg = msg.replace("{unit}", unitName);
    }
    if (isRecurring) {
      msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.seriesMessage;
      if (type === "occurrence") msg = i18n.more.bookWorkspace.checkInOut.cancelBooking.occurrenceMessage;
      msg = msg.replace("{unit}", unitName);
    }
    const options = {
      title: i18n.more.bookWorkspace.checkInOut.cancelBooking.modalTitle,
      message: msg,
      okLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.yes,
      cancelLabel: i18n.more.bookWorkspace.checkInOut.cancelBooking.no,
      showOKCancel: true,
      closeOnOK: true,
      closeOnCancel: true
    };
    ModalController.confirm(options)
    .then(result => {
      if (result && result.ok) {
        cancelBooking(bookingSystem, bookings)
        .then(({ data }: { data: IApplyEditsResult }) => {
          if (data?.updateResults?.some(r => r.success === false)) {
            Topic.publishErrorUpdatingData();
          } else {
            this.renderCancelConfirmed(reservation.attributes, type);
            Topic.publish(Topic.RenderBookingList, {});
            Topic.publish(Topic.UpdateReservationDetails, { objectId: objectID });
          }
        })
        .catch(error => {
          if (error && error.message === "Failed to fetch") {
            checkInOutUtil.showServerErrorPopup("cancel", reservation.attributes);
          } else {
            Topic.publishErrorUpdatingData(error);
          }
          console.error(error);
          if (error && isNetworkError(error.message)) {
            const i18n = Context.getInstance().i18n;
            const template = i18n.meetingRooms.issues.m9;
            const message = stringFormatter(template, { unit: unitName });
            Topic.publishNetworkError(message)
          } else {
            Topic.publishErrorUpdatingData(error);
          }
        })
      }
    })
    .catch(error => {
      console.error(error);
      Topic.publishErrorUpdatingData(error)
    });
  }

  private _getRecurrenceIcon(mStartTime: moment.Moment, mEndTime: moment.Moment, isAllDay: boolean) {    
    const { isRecurring, isOccurrenceModified } = this.props;
    if (isRecurring) {
      const title = workspaceReservationUtil.formatRecurringDates(this.recurrence, mStartTime, mEndTime, isAllDay);
      return isOccurrenceModified
        ? <span className={CSS.recurrenceIcon}>{Icons.noRecurrence()}</span>
        : <span className={CSS.recurrenceIcon} title={title}>
          <RecurrenceIcon size={16}/>
          </span>
    }
  }

  _onKeyDown = (event) => {
    const ENTER = 13;
    const SPACE = 32;

    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      this._bookingCardClicked();
    }
  }

  _togglePopover = (type?: IBookingCardState["popoverType"]) => {
    this.setState({
      popoverOpen: type != null ? true : !this.state.popoverOpen,
      popoverType: type
    });
  }

  render() {
    const i18n = Context.getInstance().i18n;
    const { data, showButton, isRecurring, isOccurrenceModified } = this.props;
    const reservation = data.booking;
    const unit = data.unit;
    const { popoverOpen, popoverType } = this.state;
    const reservations = Context.getInstance().aiim.datasets.reservations;
    const levels = Context.getInstance().aiim.datasets.levels;
    const attributes = reservation.attributes;
    const floorField = reservations.levelIdField;

    // Field values to display
    const startTime = getAttributeValue(attributes, FieldNames.START_TIME);
    const endTime = getAttributeValue(attributes, FieldNames.END_TIME);
    const unitName = getAttributeValue(attributes, FieldNames.UNIT_NAME);

    // Level/Facility info
    const levelId = getAttributeValue(attributes, floorField);
    const levelData = levels.getLevelData(levelId);
    const levelName = levelData.levelName;
    const facilityName = levelData.facilityName;
    const template = i18n.more.bookWorkspace.levelDescription;
    const description = stringFormatter(template, {
      level: levelName,
      facility: facilityName
    });

    // Format a date string from the start and end times
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    const isAllDay = getAttributeValue(attributes, FieldNames.ALL_DAY);
    const formattedDate = workspaceReservationUtil.formatDate(mStartTime, mEndTime, isAllDay);

    // Check state to see if cancel button is needed
    const state = getAttributeValue(attributes, FieldNames.STATE);
    const hideCheckIn = checkInOutUtil.hideCheckInButton(attributes);

    let label, icon, cls, title;
    if (showButton && showButton === "checkIn") {
      label = i18n.more.bookWorkspace.checkInOut.checkIn.caption;
      icon = (<SignInIcon size={20}/>);
      cls = CSS.buttonCheckIn;
      title = label;
    } else {
      label = i18n.more.bookWorkspace.checkInOut.checkOut.caption;
      icon = (<SignOutIcon size={20}/>);
      cls = CSS.buttonCheckOut;
      title = label;
    }

    const addToCalendarButton = (
      <button
        className={CSS.addToCalendar}
        data-id={"calendar"}
        onClick={() => this._togglePopover("calendar")}
      >
        {Icons.calendarPlus()}
        {i18n.general.add}
      </button>
    );

    const editButton = (
      <button
        className={CSS.edit}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("edit") : this._scheduleClicked()}
      >
        <PencilIcon size={20}/>
        {i18n.more.bookWorkspace.checkInOut.rescheduleBooking.caption}
      </button>
    )

    const cancelButton = (
      <button
        className={CSS.cancel}
        data-id={"cancel"}
        onClick={() => isRecurring ? this._togglePopover("cancel") : this._cancelClicked()}>
        <XIcon size={20}/>
        {i18n.general.cancel}
      </button>
    );

    const reservationsDataset = Context.getInstance().aiim.datasets.reservations;
    const unitsDataset = Context.getInstance().aiim.datasets.units;
    const calendarParams = makeEventInfo(reservationsDataset, reservation, unitsDataset, unit);

    return (
      <li className={CSS.bookingCard}>
        <div className="i-book-card-container">
          <div
            id={this._componentId}
            className={CSS.descriptionContent}
            ref={this._itemRef}
            role="button"
            tabIndex={0}
            onClick={this._bookingCardClicked}
            onKeyDown={this._onKeyDown}
          >
            <h3 className={CSS.bookingInfoLine}>
              <ClockIcon/>
              <span style={{ flexGrow: 1 }}>{formattedDate}</span>
              {this._getRecurrenceIcon(mStartTime, mEndTime, !!isAllDay)}
            </h3>
            <h4 className={CSS.bookingInfoLine}>
              <PinIcon />
              {unitName}
            </h4>
            <h4 className={CSS.locationDescription} style={{paddingLeft: "1.3rem", paddingRight: "1.4rem", marginBottom: "0.5rem"}}>{description}</h4>
          </div>
          <div className={CSS.actionGroup}>
            {!hideCheckIn &&
              <div className={cls}>
                <Button
                  icon={icon}
                  title={title}
                  iconPosition="before"
                  onClick={this.handleToggle}>
                  <span>{label}</span>
                </Button>
              </div>
            }
            <CalendarSelector
              onRequestClose={this._togglePopover}
              open={popoverOpen && popoverType === "calendar"}
              targetEl={addToCalendarButton}
              params={calendarParams}
              reservation={reservation}
              _updateICSfile={this.props.updateICSfile}
            />
            <RecurrenceDropdown
              type="edit"
              open={popoverOpen && popoverType === "edit"}
              targetEl={editButton}
              onRequestClose={this._togglePopover}
              onSelection={(type) => {
                this._scheduleClicked(type)
                this._togglePopover();
              }} />
            {state === CHECKED_IN ? null :
              <RecurrenceDropdown
                type="cancel"
                open={popoverOpen && popoverType === "cancel"}
                targetEl={cancelButton}
                onRequestClose={this._togglePopover}
                onSelection={(type) => {
                  this._cancelClicked(type)
                  this._togglePopover();
                }} />
            }
          </div>
        </div>
      </li>
    );
  }

  _rescheduleBooking = async(when, isOngoingBooking, recurrenceType)=> {
    const unit = this.props && this.props.data && this.props.data.unit;
    const unitAttr = unit && unit.attributes;
    const unitFeature = this.state.unitFeature;
    const resFeature = this.props.data && this.props.data.booking;

    const details = {
      when: when,
      isOngoingBooking: isOngoingBooking,
      unitAttr: unitAttr,
      unitFeature: unitFeature,
      checkAvailability: this.checkAvailability,
      selfBooking: true,
      bookingType: "hotel",
      recurrenceType: recurrenceType,
      originalReservation: { attributes: resFeature && resFeature.attributes, booking: null },
      series: this.props.series
    }
    workspaceReservationUtil.rescheduleBooking(details);
  }

  _scheduleClicked =async(type?: RecurrenceType)=> {
    const { data: { booking, unit }, isRecurring, series } = this.props;
    const { unitFeature } = this.state;
    let details;
    if (isRecurring && type!=="occurrence") {
      const state = getAttributeValue(booking.attributes, FieldNames.STATE);
      const allDay = dateUtil.isAllDay(series.checkIn.getTime(), series.checkOut.getTime());
      const when: dateUtil.IDuration = {
        start: { date: series.checkIn },
        end: { date: series.checkOut },
        duration: allDay ? "allDay" : dateUtil.getDurationKey(series.checkIn, series.checkOut),
        recurrence: this.recurrence
      };
      const selectedWhen = {
        duration: when.duration,
        start: { date: when.start.date },
        end: { date: when.end.date },
        isOngoingBooking: state === BOOKING_STATE.CHECKED_IN,
        originalReservation: booking,
        recurrence: series?.options
      };
      details = {
        when: selectedWhen,
        isOngoingBooking: state === BOOKING_STATE.CHECKED_IN,
        unitAttr: unit?.attributes,
        unitFeature: unitFeature,
        checkAvailability: this.checkAvailability,
        selfBooking: true,
        bookingType: "hotel",
        originalReservation: { attributes: booking?.attributes, booking: null },
        recurrenceType: "series",
        series
      };
      workspaceReservationUtil.rescheduleBooking(details);
    } else {
      details = {
        reservation: booking,
        rescheduleBooking: this._rescheduleBooking,
        operation: "updateBookingTime",
        bookingType: "hotel",
        recurrenceType: type == null ? "single" : type,
        series
      };
      workspaceReservationUtil.showScheduleViewEsri(details);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    rdxDateOption: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_OPTION),
    rdxDateSelected: Rdx.getValue(state,Rdx.Keys.BOOK_DATEFILTER_DATESELECTED)
  }
}

export default connect(mapStateToProps)(MyBookings);
