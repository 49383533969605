import React from "react";
import { connect } from "react-redux";

import { IStencil } from "../../../miniapps/common/types";
import * as component from "../../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import {
  CalciteButton,
  CalciteTooltip
} from "@esri/calcite-components-react";


interface Props {
  active: boolean,
  disabled?: boolean,
  onClick?: () => void,
  stencil: IStencil
}

interface State {
}

class StencilButton extends React.Component<Props, State> {

  id: string;

  constructor(props) {
    super(props);
    this.id = component.nextId("stencil-button-");
    this.state = component.newState({});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const { active, disabled, stencil } = this.props;
    const lbl = stencil.name;
    const tipId = this.id+"-lbl";
    return (
      <div className="i-editor-stencil-button">
        <CalciteButton 
          width="full"
          appearance={active ? "solid" : "transparent"}
          kind="neutral"
          disabled={disabled || undefined}
          onClick={() => {
            if (this.props.onClick) this.props.onClick();
          }}
        >
          <div id={tipId} className="i-editor-stencil-button-content" title={lbl}>
            {this.renderSvg(stencil)}
            <div  className="i-editor-stencil-button-label">{lbl}</div>
          </div>
          {/* This beta version of calcite-tooltip is not positioning correctly */}
          {false &&
            <CalciteTooltip label={lbl} referenceElement={tipId} placement="bottom">
              <span>{lbl}</span>
            </CalciteTooltip>
          }
        </CalciteButton>
      </div>
    )
  }

  renderSvg(stencil) {
    const svg = (
      <svg xmlns="http://www.w3.org/2000/svg" width={stencil.svgWidth} height={stencil.svgHeight} >
        <path d={stencil.svgPath} fill="none" strokeWidth="1" stroke="currentColor"  />
      </svg>
    )
    return svg;
  }

}

const mapStateToProps = (state,ownProps) => {
  return {
  }
}

export default connect(mapStateToProps)(StencilButton);