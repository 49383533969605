import React from "react";
import { connect } from "react-redux";

import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";

import { ButtonContainer, CustomButton } from "../../styles/Work/workBar";

class BurgerButton extends React.Component {
  clicked = event => {
    Topic.publish(Topic.ShowBackstage, {});
  }

  render() {
    const active = (this.props.rdxActiveTabKey === this.props.tabKey);
    const clear = active ? false : true;
    let cls = "i-tab-button";
    if (this.props && typeof this.props.className === "string" &&
      this.props.className.length > 0) {
      cls += " " + this.props.className;
    }
    return (
      <ButtonContainer>
        <CustomButton {...this.props} iconButton
          className={cls} onClick={this.clicked} >
          {this.props.children}
        </CustomButton>
      </ButtonContainer>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
  }
}

export default connect(mapStateToProps)(BurgerButton);
