/**
 * Support for Typescript based custom core widgets
 */

import Context from "../context/Context";
import _moment from "moment";
import RouteResult from "../components/main/Directions/RouteResult";
import _Topic from "../context/Topic";
import * as aiimUtil from "../aiim/util/aiimUtil";
import * as filterUtil from "../spaceplanner/components/Filter/filterUtil";
import * as hitTest from "../aiim/util/hitTest";
import * as viewUtil from "./viewUtil";
import * as DatePicker from "../components/common/DatePicker/DatePicker";

import {
  assignmentsFound,
  findDuplicateRecords,
  findUserReservations,
  getAssignments,
  getLKL,
  hasAssignment,
  hasOnlyAreaAssignments,
  queryTrackingInfo,
  showMultipleAssignments
} from "./multipleAssignmentsUtil";
import FieldNames from "../aiim/datasets/FieldNames";

export const moment = _moment;
export const Topic = _Topic;

export function fixHitZ(hit) {
  if (hit && hit.source && hit.feature) {
    const levels = Context.getInstance().aiim.datasets.levels;
    if (levels) levels.fixZ(hit.source,hit.feature);
  }
}

export function fixSearchZ(search,selectedResult) {
  if (search && search.sources && selectedResult && selectedResult.feature) {
    if (typeof selectedResult.sourceIndex === "number") {
      const src = search.sources.getItemAt(selectedResult.sourceIndex);
      if (src && typeof src.aiimKey === "string") {
        const categories = Context.getInstance().aiim.datasets.categories;
        const levels = Context.getInstance().aiim.datasets.levels;
        const source = categories.findSourceByKey(src.aiimKey);
        if (levels) levels.fixZ(source,selectedResult.feature);
      }
    }
  }
}

export function getViewUtil() {
  return viewUtil;
}

export function hitTestSearch(view,event,allowNonAiim) {
  return hitTest.search(view,event,allowNonAiim);
}

export function queryUniqueValues(options) {
  return filterUtil.queryUniqueValues(options);
}

export function newRouteResult(props) {
  return new RouteResult(props);
}

export function removeShapeAttributes(attributes) {
  aiimUtil.removeShapeAttributes(attributes);
}

export function sanitizeHtml(text) {
  return Context.sanitizeHtml(text);
}

export function showCalendar(initialDate, onDateChange, isOutsideRange) {
  DatePicker.showDatePicker(initialDate, onDateChange, isOutsideRange);
}

export function tsFindDuplicateRecords(layer, personAttributes) {
  return findDuplicateRecords(layer, personAttributes);
}

export function tsGetAssignments(features) {
  return getAssignments(features);
}

export function tsAssignmentsFound(features) {
  return assignmentsFound(features);
}

export function tsShowMultipleAssignments(options) {
  return showMultipleAssignments(options);
}

export function tsGetLKL(personFeatures) {
  return getLKL(personFeatures);
}

export function tsHasAssignment(personFeature) {
  return hasAssignment(personFeature);
}

export function tsHasOnlyAreaAssignments(assignments) {
  return hasOnlyAreaAssignments(assignments);
}

export function tsQueryTrackingInfo(personFeatures) {
  return queryTrackingInfo(personFeatures);
}

export function getLayerFeatureText(feature, template, displayField) {
  const layer = feature.sourceLayer;
  const { attributes } = feature;
  const domain = typeof layer.getFieldDomain === "function" && layer.getFieldDomain(displayField);

  if (template) {
    return Context.getInstance().lib.esri.intl.substitute(template, attributes);
  }

  if (displayField && attributes) {
    const field = layer.getField(displayField);
    const dfValue = aiimUtil.getAttributeValue(attributes, displayField);

    if (dfValue == null) {
      return "";
    }

    if (domain && domain.type === "coded-value") {
      return _getCodedName(domain, dfValue);
    }

    if (field && field.type === "date") {
      return Context.getInstance().lib.esri.intl.formatDate(new Date(dfValue));
    }

    if (typeof dfValue === "number") {
      return dfValue.toString();
    }

    if (typeof dfValue !== "string") {
      return "";
    }

    return dfValue.trim();
  }

  return "";
}

function _getCodedName(domain, codedValue) {
  let name = null;
  const { codedValues } = domain;
  if (codedValues && codedValues.length) {
    codedValues.some((value) => {
      if (value.code === codedValue) {
        name = value.name;
        return true;
      }
      return false;
    });
  }
  return name;
}

export function hasAreaAssignment(feature) {
  if (!feature || !feature.attributes) {
    return false;
  }
  const areaId = aiimUtil.getAttributeValue(feature.attributes, FieldNames.AREA_ID);
  return !!areaId;
}

export function hasUnitAssignment(feature) {
  if (!feature || !feature.attributes) {
    return false;
  }
  const unitId = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_UNIT_ID);
  return !!unitId;
}

export function tsFindUserReservations(person, type) {
  return findUserReservations(person, type);
}
