import styled, { css } from "styled-components";
import TopNav, {TopNavList, TopNavLink} from "calcite-react/TopNav";
import { unitCalc } from "calcite-react/utils/helpers";
import Context from "../../../src/context/Context";

const StyledNav = styled(TopNav)`
  background: ${props => props.theme.palette.backgroundColor};
  box-shadow: ${props => props.theme.boxShadow};
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:after {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
    width: ${props => unitCalc(props.theme.baseline, 4, "*")};
    
    div > button {
        border: 0;
        min-height: auto;
        height: 50.5938px;
        max-height: 50.5938px;
        background: ${props => props.theme.palette.backgroundColor};
    }
        
    &:hover{
        background: ${props => props.theme.palette.themeColorDark};
        color: white !important;
        svg{
          fill: white !important;
        }
        
    } 
`;

const StyledList = styled(TopNavList)`
    margin-left: ${props => props.theme.panelMargin};
    flex: 0 0 auto;

    ${props => 
      Context.instance.uiMode.isRtl && 
      css`
        margin-right: ${props => props.theme.panelMargin} !important;
    `}
`;

const StyledLink = styled(TopNavLink)`
  padding-top: 0.8rem;
  padding-bottom: calc(0.8rem - 4px);
  margin-left: 1rem !important;
  margin-right: 1rem !important;
`; 

const LeftContent = styled.div`
    display: flex;
    flex-direction: row;
`;

const RightContent = styled.div`
    display: none;
    margin-right: ${props => props.theme.panelMargin};
    &:after {
      display: none;
    }
`;

export { ButtonWrapper, StyledNav, StyledList, StyledLink, LeftContent, RightContent };
