// Framework and third-party non-ui
import React, { Component } from "react";
import {connect} from "react-redux";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import Rdx from "../../../redux/Rdx";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

import RedoIcon from "calcite-ui-icons-react/RedoIcon";
import UndoIcon from "calcite-ui-icons-react/UndoIcon";

// Component specific modules (Component-styled, etc.)
import {
  Container,
  Title,
  Button,
  Menu,
  InfoField,
  InfoAttr
} from "../../styles/Info/info";

// App components

// Third-party components (buttons, icons, etc.)
import Popover from "calcite-react/Popover";
import InfoIcon from "calcite-ui-icons-react/InformationIcon";
import Skeleton from "react-loading-skeleton";
import OfficePlan from "../../base/OfficePlan";

// JSON

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = component.newState({
      hasPlan: false,
      open: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.PlanOpened, params => {
        this.setState({ hasPlan: true });
        let plan= OfficePlan.getActive();
        if(plan) plan.refreshMetaInfo();
      }),
      Topic.subscribe(Topic.ReloadViews, params => {
        this.setState({ hasPlan: false });
      }),
      Topic.subscribe(Topic.PlanModified, params=>{
        let plan= OfficePlan.getActive();
        if(plan) plan.refreshMetaInfo();
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  getPlanData = () => {
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan && plan.wasOpened) {
      return plan.getMetaInfo();
    }
    return null;
  };

  togglePopover = () => {
    this.setState({
      open: !this.state.open
    });
  };

  closePopover = () => {
    this.setState({
      open: false
    });
  };

  redo = () => {
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan && plan.undoManager) {
      plan.undoManager.redo();
      this.closePopover();
    }
  };

  undo = () => {
    const plan = Context.instance.spaceplanner.activePlan;
    if (plan && plan.undoManager) {
      plan.undoManager.undo();
      this.closePopover();
    }
  };

  render() {
    const { hasPlan } = this.state;
    const i18n = Context.instance.i18n;
    const info = this.getPlanData() || {};
    return (
      <Container style={{padding:"0 10px"}}>
        {hasPlan ? (
          <>
            <Title title={info.title} style={{maxWidth: "240px"}}>{info.title}</Title>

            <Popover
              targetEl={
                <Button
                  iconButton
                  icon={<InfoIcon />}
                  onClick={this.togglePopover}
                ></Button>
              }
              open={this.state.open}
              onRequestClose={this.closePopover}
              placement="bottom-end"
              appendToBody
            >
              <Menu style={{ maxWidth: "280px" }}>
                <InfoField>
                  {i18n.spaceplanner.backstage.summary.cards.general.created}
                </InfoField>

                <InfoAttr>{info.created}</InfoAttr>

                <InfoField>
                  {i18n.spaceplanner.backstage.summary.cards.general.modified}
                </InfoField>

                <InfoAttr>{info.modified}</InfoAttr>

              </Menu>
            </Popover>

            {this.renderUndoRedo()}
          </>
        ) : (
          <Skeleton width={100} />
        )}
      </Container>
    );
  }

  renderUndoRedo() {
    const planner = Context.instance.spaceplanner.planner;
    const project = planner && planner.project;
    if (!project || project.isVersioned) return null;
    const i18n = Context.instance.i18n;
    const canUndo = this.props.rdxCanUndo;
    const canRedo = this.props.rdxCanRedo;
    return (
      <div>
        <Button
          style={{marginLeft: '4px'}}
          disabled={!canUndo}
          iconButton
          icon={<UndoIcon />}
          title={i18n.spaceplanner.undo}
          onClick={this.undo}
        ></Button>
        <Button
          disabled={!canRedo}
          iconButton
          icon={<RedoIcon />}
          title={i18n.spaceplanner.redo}
          onClick={this.redo}
        ></Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdxCanUndo: !!Rdx.getValue(state,Rdx.Keys.PLAN_CAN_UNDO),
    rdxCanRedo: !!Rdx.getValue(state,Rdx.Keys.PLAN_CAN_REDO)
  }
}

export default connect(mapStateToProps)(Info);
