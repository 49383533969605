import React, { FormEvent } from "react";
import { FormControl } from "../../../styles/backstageStyles";

import Context from "../../../../context/Context";
import * as component from "../../../../components/util/component";
import * as portalUtil from "../../../../util/portalUtil";
import * as groupsUtil from "../../../base/groupsUtil";
import ShareWith, { SharingLevels } from "../../../../common/components/Sharing";
import { IItemProperties, IItemPropertiesProps, IItemPropertiesState, INonVersionedItemInfo } from "../../common/types";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-input";
import "@esri/calcite-components/dist/components/calcite-input-message";
import "@esri/calcite-components/dist/components/calcite-input-text";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-dropdown";
import "@esri/calcite-components/dist/components/calcite-dropdown-item";
import "@esri/calcite-components/dist/components/calcite-icon";
import {
  CalciteButton,
  CalciteDropdown,
  CalciteDropdownItem,
  CalciteInput,
  CalciteInputMessage,
  CalciteInputText,
  CalciteLabel
} from "@esri/calcite-components-react";
import { CalciteInputCustomEvent, CalciteDropdownItemCustomEvent } from "@esri/calcite-components";

export default class ItemProperties
  extends React.Component<IItemPropertiesProps, IItemPropertiesState>
  implements IItemProperties {

  componentId: string;
  private mounted: boolean = false;

  constructor(props: IItemPropertiesProps) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      title: "",
      tags: Context.instance.configuration.indoorsTag,
      summary: "",
      folder: "",
      groups: null,
      groupIds: null,
      folders: null,
      indoorsFolderID: null,
      shareWith: this.props.shareWith || SharingLevels.ORGANIZATION,
      valid: true
    });
    this.onChangeFolder = this.onChangeFolder.bind(this);
    this.onChangeSummary = this.onChangeSummary.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (typeof this.props.onMount === "function") this.props.onMount(this);
    this.fetchFolders();
    if (!this.props.disallowGroups) {
      groupsUtil.getGroups().then(res => {
        if (res && this.mounted) {
          const [groups, chips] = res;
          this.setState({ groups: groups });
        }
      }).catch(err => console.log(err));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    component.componentWillUnmount(this);
  }

  fetchFolders() {
    const user = Context.instance.user;
    const indoorsFolderName = this.getIndoorsFolderName();
    let folders: __esri.PortalFolder[],
      indoorsFolderID: string = null;
    user.portalUser
      .fetchFolders()
      .then(result => {
        folders = result;
        indoorsFolderID = this.findIndoorsFolderID(folders);
        if (!indoorsFolderID) {
          return portalUtil.createFolder(indoorsFolderName, user.getUsername());
        }
      })
      .then(() => {
        if (!indoorsFolderID) {
          return user.portalUser.fetchFolders().then(result => {
            folders = result;
            indoorsFolderID = this.findIndoorsFolderID(folders);
          });
        }
      })
      .then(() => {
        this.setState({
          folders: folders,
          folder: indoorsFolderID || "",
          indoorsFolderID: indoorsFolderID
        });
      })
      .catch(ex => {
        console.error("Error fetching folders", ex);
      });
  }

  findIndoorsFolderID(folders: __esri.PortalFolder[]) {
    let folderID: string = null;
    const lc = this.getIndoorsFolderName().toLowerCase();
    if (folders && folders.length > 0) {
      folders.some(f => {
        if (f.title.toLowerCase() === lc) folderID = f.id;
        return !!folderID;
      });
    }
    return folderID;
  }

  getIndoorsFolderName() {
    return "Indoors";
  }

  getItemInfo(): INonVersionedItemInfo {
    const { title, tags, summary, folder, groupIds, shareWith } = this.state;

    const info: INonVersionedItemInfo = {
      title: title.trim(),
      tags: tags.trim().split(","),
      summary: summary.trim(),
      folderId: folder,
      groupIds: groupIds,
      sharingInfo: {
        everyone: shareWith === SharingLevels.PUBLIC,
        org: shareWith === SharingLevels.ORGANIZATION
      }
    };
    if (groupIds && groupIds.length > 0) {
      if (shareWith !== SharingLevels.ORGANIZATION) {
        info.sharingInfo.groups = groupIds.join(',');
      }
    }
    return info;
  }

  onChangeFolder(e: CalciteDropdownItemCustomEvent<void>) {
    this.setState({ folder: e.target.id });
  }

  onChangeSharing(shareWith: SharingLevels, groupIds: string[]) {
    const state: IItemPropertiesState = { shareWith };
    if (shareWith === SharingLevels.ORGANIZATION) {
      state.groupIds = [];
    } else if (groupIds) {
      state.shareWith = SharingLevels.SHARED;
      state.groupIds = groupIds;
    }
    this.setState(state);
  }

  onChangeSummary(evt: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ summary: evt.target.value });
  }

  onChangeTags(evt: CalciteInputCustomEvent<void>) {
    this.setState({ tags: evt.target.value });
  }

  onChangeTitle(evt: CalciteInputCustomEvent<void>) {
    const title = evt.target.value;
    const { valid, validationMessage } = this.validate(title.trim());

    this.setState({title, valid, validationMessage });
  }

  onSubmit = (evt: FormEvent) => {
    if (evt) evt.preventDefault();
    if (evt) evt.stopPropagation();
    if (this.props.submitHandle) {
      const title = this.state.title.trim();
      const { valid, validationMessage } = this.validate(title.split(" ").join("_"));
      if (valid) {
        this.props.submitHandle();
      }
      this.setState({ valid, validationMessage });
    }
  };
  validate(title: string) {
    const { i18n } = Context.getInstance();
    const length = title ? title.length : 0;

    if (this.props.isAppItem) {
      let valid = true, validationMessage = null;
      if (length === 0) {
        valid = false
        validationMessage = i18n.general.valueRequired
      }
      return {valid, validationMessage};
    }

    const valid = length > 0 && length <= 120
      && !/[.@#%&*+=\-\\';,\/|\\":<>\?]/g.test(title.split(" ").join("_"));
    const validationMessage = !valid
      ? length <= 0
        ? i18n.general.valueRequired
        : i18n.spaceplanner.backstage.newPlan.errorInvalidPlan
      : "";
    return { valid, validationMessage };
  }
  render() {
    const ctx = Context.getInstance();
    const i18n = ctx.i18n;
    const id = this.componentId;
    const { title, tags, summary, folder, folders, shareWith, valid, validationMessage } = this.state;
    const { horizontal } = this.props;
    const cls = horizontal ? "i--form i--form-horizontal i--new-plan" : "i--form i--new-plan";
    const username = ctx.user.getUsername();
    const folderOptions = [
      <CalciteDropdownItem key={username} id="" onCalciteDropdownItemSelect={this.onChangeFolder}>
        {username}
      </CalciteDropdownItem>
    ];

    if (Array.isArray(folders)) {
      folders.forEach(f => {
        const props = {
          key: f.id,
          id: f.id,
          ...f.id === folder && { selected: true }
        };
        folderOptions.push(
          <CalciteDropdownItem {...props} onCalciteDropdownItemSelect={this.onChangeFolder}>
            {f.title}
          </CalciteDropdownItem>
        );
      });
    }
    const messageProps = { ...!valid && { active: true } };
    return (
      <form className={cls} onSubmit={this.onSubmit}>
        <FormControl>
          <CalciteLabel
            id={id + "-title-label"}
            for="title"
            scale="l"
            className="i-new-plan i--form-label">
            <span className="i--required">
              {i18n.configurator.appItem.title}:
            </span>            
          <CalciteInputText
            id={id + "-title"}
            scale="l"
            autofocus
            required
            defaultValue={title}
            maxLength={120}
            onCalciteInputTextChange={this.onChangeTitle}
            />
            {!valid &&
              <CalciteInputMessage scale="l" status="invalid" icon={true} {...messageProps}>
                {validationMessage}
              </CalciteInputMessage>
            }
          </CalciteLabel>
        </FormControl>

        <FormControl>
          <CalciteLabel
            id={id + "-tags-label"}
            for="tags"
            scale="l"
            layout={horizontal ? "inline" : "default"}
            className="i-new-plan i--form-label">
            {i18n.configurator.appItem.tags}:
          </CalciteLabel>
          <CalciteInputText
            id={id + "-tags"}
            defaultValue={tags}
            scale="l"
            onCalciteInputTextChange={this.onChangeTags}
          />
        </FormControl>

        <FormControl>
          <CalciteLabel
            id={id + "-summary-label"}
            for="summary"
            scale="l"
            layout={horizontal ? "inline" : "default"}
            className="i-new-plan  i--form-label">
            {i18n.configurator.appItem.summary}:
          </CalciteLabel>
          <textarea
            id={id + "-summary"}
            onChange={this.onChangeSummary}>
            {summary}
          </textarea>
        </FormControl>

        <FormControl>
          <CalciteLabel
            id={id + "-folder-label"}
            for="folder"
            scale="l"
            layout={horizontal ? "inline" : "default"}
            className="i-new-plan i--form-label">
            {i18n.configurator.appItem.folder}:
          </CalciteLabel>
          <CalciteDropdown id={id + "-folder"}>
            <CalciteButton slot="trigger" appearance="outline" icon-end="caret-double-vertical">
              {folder === "" ? username : folders.find(f => f.id === folder).title}
            </CalciteButton>
            {folderOptions}
          </CalciteDropdown>
        </FormControl>

        <ShareWith
          horizontal={horizontal}
          shareWith={shareWith}
          disallowEveryone={true}
          hideShareWithHelp={!!this.props.hideShareWithHelp}
          assignedGroups={[]}
          groups={this.state.groups}
          onChange={this.onChangeSharing.bind(this)}
        />
        {this.props.children}
      </form>
    );
  }
}
