import React from "react";

import Context from "../../../context/Context";
import * as component from "../../../components/util/component";
import * as portalUtil from "../../../util/portalUtil";
import * as groupsUtil from "../../base/groupsUtil";

import Form, {
  FormControl,
  FormControlLabel
} from "calcite-react/Form";
import { MenuItem } from "calcite-react/Menu";
import Select from "calcite-react/Select";
import TextField from "calcite-react/TextField";
import ShareWith from "./ShareWith";
import { SharingLevels } from "../../../common/components/Sharing";

export default class ItemProperties extends React.Component {
  componentId;

  constructor(props) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      title: "",
      tags: Context.instance.configuration.indoorsTag,
      summary: "",
      folder: "",
      groups: null,
      groupIds: null,
      folders: null,
      indoorsFolderID: null,
      shareWith: this.props.shareWith || SharingLevels.ORGANIZATION
    });
    this.onChangeFolder = this.onChangeFolder.bind(this);   
    this.onChangeSummary = this.onChangeSummary.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.onMount === "function") this.props.onMount(this);
    this.fetchFolders();
    if (!this.props.disallowGroups) {
      groupsUtil.getGroups().then(res => {
        if (res){
          const [groups, chips] = res;
          this.setState({groups: groups});
        }
      }).catch(err => console.log(err));
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  fetchFolders() {
    const user = Context.instance.user;
    const indoorsFolderName = this.getIndoorsFolderName();
    let folders,
      indoorsFolderID = null;
    user.portalUser
      .fetchFolders()
      .then(result => {
        folders = result;
        indoorsFolderID = this.findIndoorsFolderID(folders);
        if (!indoorsFolderID) {
          return portalUtil.createFolder(indoorsFolderName, user.getUsername());
        }
      })
      .then(() => {
        if (!indoorsFolderID) {
          return user.portalUser.fetchFolders().then(result => {
            folders = result;
            indoorsFolderID = this.findIndoorsFolderID(folders);
          });
        }
      })
      .then(() => {
        this.setState({
          folders: folders,
          folder: indoorsFolderID || "",
          indoorsFolderID: indoorsFolderID
        });
      })
      .catch(ex => {
        console.error("Error fetching folders", ex);
      });
  }

  findIndoorsFolderID(folders) {
    let folderID = null;
    const lc = this.getIndoorsFolderName().toLowerCase();
    if (folders && folders.length > 0) {
      folders.some(f => {
        if (f.title.toLowerCase() === lc) folderID = f.id;
        return !!folderID;
      });
    }
    return folderID;
  }

  getIndoorsFolderName() {
    return "Indoors";
  }

  getItemInfo() {
    const state = this.state;

    const info = {
      title: state.title.trim(),
      tags: state.tags.trim().split(","),
      summary: state.summary.trim(),
      folderId: state.folder,
      groupIds: state.groupIds,
      sharingInfo: {
        everyone: state.shareWith === SharingLevels.PUBLIC,
        org: state.shareWith === SharingLevels.ORGANIZATION
      }
    };
    if (state.groupIds && state.groupIds.length > 0) {
      if (state.shareWith !== SharingLevels.ORGANIZATION) {
        info.sharingInfo.groups = state.groupIds.join(',');
      }
    }
    return info;
  }

  onChangeFolder(value) {
    this.setState({ folder: value });
  }

  onChangeSharing(shareWith, groupIds) {
    const state = { shareWith };
    if (shareWith === SharingLevels.ORGANIZATION) {
      state.groupIds = [];
    } else if (groupIds) {
      state.shareWith = SharingLevels.SHARED;
      state.groupIds = groupIds;
    }
    this.setState(state);
  }

  onChangeSummary(evt) {
    this.setState({ summary: evt.target.value });
  }

  onChangeTags(evt) {
    this.setState({ tags: evt.target.value });
  }

  onChangeTitle(evt) {
    this.setState({ title: evt.target.value });
  }

  onSubmit = (evt) => {
    if (evt) evt.preventDefault();
    if (evt) evt.stopPropagation();
    if (this.props.submitHandle) {
      const title = this.state.title.trim();
      if (title.length > 0) {
        this.props.submitHandle();
      }
    }
  };

  render() {
    const i18n = Context.instance.i18n;
    const id = this.componentId;
    const title = this.state.title;
    const tags = this.state.tags;
    const summary = this.state.summary;
    const folder = this.state.folder;
    const shareWith = this.state.shareWith;
    const disallowEveryone = this.props.disallowEveryone;

    const username = Context.instance.user.getUsername();
    const folders = this.state.folders;
    const folderOptions = [
      <MenuItem key={username} value="">
        {username}
      </MenuItem>
    ];

    if (Array.isArray(folders)) {
      folders.forEach(f => {
        folderOptions.push(
          <MenuItem key={f.id} value={f.id}>
            {f.title}
          </MenuItem>
        );
      });
    }

    const horizontal = !this.props.isModal ? true : false;
    const cls = horizontal ? "i--form i--form-horizontal" : "i--form";
    return (
      <Form className={cls} onSubmit={this.onSubmit}>
        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-title-label"}
            htmlFor="title"
            className="i-new-plan i--form-label"
          >
            <span className="i--required">
              {i18n.configurator.appItem.title}:
            </span>
          </FormControlLabel>
          <TextField
            id={id + "-title"}
            autoFocus
            fullWidth={true}
            defaultValue={title}
            onChange={this.onChangeTitle}
          />
        </FormControl>

        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-tags-label"}
            htmlFor="tags"
            className="i-new-plan i--form-label"
          >
            {i18n.configurator.appItem.tags}:
          </FormControlLabel>
          <TextField
            id={id + "-tags"}
            fullWidth={true}
            defaultValue={tags}
            onChange={this.onChangeTags}
          />
        </FormControl>

        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-summary-label"}
            htmlFor="summary"
            className="i-new-plan  i--form-label"
          >
            {i18n.configurator.appItem.summary}:
          </FormControlLabel>
          <TextField
            id={id + "-summary"}
            fullWidth={true}
            defaultValue={summary}
            type="textarea"
            onChange={this.onChangeSummary}
          />
        </FormControl>

        <FormControl horizontal={horizontal}>
          <FormControlLabel
            id={id + "-folder-label"}
            htmlFor="folder"
            className="i-new-plan i--form-label"
          >
            {i18n.configurator.appItem.folder}:
          </FormControlLabel>
          <Select
            id={id + "-folder"}
            selectedValue={folder}
            onChange={this.onChangeFolder}
          >
            {folderOptions}
          </Select>
        </FormControl>

        <ShareWith 
          horizontal={horizontal}
          shareWith={shareWith} 
          disallowEveryone={true}
          assignedGroups={[]}
          groups={this.state.groups}
          onChange={this.onChangeSharing.bind(this)}
          />      
      </Form>
    );
  }
}
