import Context from "../../context/Context";
import Dataset from "./Dataset";
import QueryAll from "../../spaceplanner/base/QueryAll";
import { IFloorAwareInfo } from "./Datasets";
import FieldNames from "./FieldNames";
import { getFacilitiesLayer } from "../../spaceplanner/base/sourceUtil";
import { CustomQueryTask } from "../../context/EsriLib";
import { escSqlQuote } from "../util/selectionUtil";
import { findFieldName } from "../util/aiimUtil";

export default class Sites extends Dataset {

  floorAwareInfo: IFloorAwareInfo;
  objectIdField: string;
  type = "feature";

  constructor(props?) {
    super(props)
    this.mixinProps(props);
  }

  checkSchema() {
    const promise = new Promise<void>((resolve,reject) => {
      if (this.layer2D) {
        const layer = this.layer2D;
        this.objectIdField = layer.objectIdField;
        layer.when(() => {
          //this.checkFieldNameProperty("facilityIdField",layer.fields);
          //this.checkFieldNameProperty("nameField",layer.fields);
          resolve();
        }).catch(ex => {
          console.log("Failed to load dataset layer:",layer.title);
          console.error(ex);
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }
  async containsFacilities(id: string) {
    const layer = getFacilitiesLayer();
    if (!layer) return Promise.resolve(false);

    const url = Context.checkMixedContent(`${layer.url.replace(/\/+$/g, "")}/${layer.layerId}`);
    const queryTask = new CustomQueryTask({ url });
    const query: __esri.Query = new Context.instance.lib.esri.Query();
    const field = findFieldName(layer.fields, FieldNames.SITE_ID);
    query.outFields = [field];
    query.returnGeometry = false;
    query.where = `(${field} = '${escSqlQuote(id)}')`;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    return (await queryTask.executeForCount(query)) > 0;   
  }
  queryAll(qaopts?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const query = new Context.instance.lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    qaopts = qaopts || {};
    const qa = new QueryAll();
    return qa.execute(url,query,qaopts);
  }

  queryByGeometry(geometry,meters?) {
    if (!this.url) return Promise.resolve();
    const url = Context.checkMixedContent(this.url);
    const lib = Context.getInstance().lib;
    const task = new lib.esri.QueryTask({url: url});
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    query.where = "1=1";
    query.geometry = geometry;
    if (this.getVersionName())
      query.gdbVersion = this.getVersionName();
    if (typeof meters === "number" && meters > 0) {
      query.distance = meters;
      query.units = "meters";
    }
    return task.execute(query);
  }

  getVersionName(): string {
    return this.layer2D?.gdbVersion;
  }
}
