import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {Provider} from "react-redux";

import BaseClass from "../../../util/BaseClass";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import Rdx from "../../../redux/Rdx";
import * as component from "../../util/component";

const CSS = {
  wrapper: "i-modal-wrapper",
  header: "i-modal-header",
  content: "i-modal-content",
  title: "i-modal-title",
  close: "i-modal-close i-icon-button",
  message: "i-message",
  actions: "i-actions i-text-right",
  primaryButton: "i-button"
};

Modal.setAppElement("#root");

export default class ModalController extends BaseClass {

  _modalWrapper;
  _promise;

  close() {
    if (this._modalWrapper) this._modalWrapper.close();
  }

  show(content,title,className,ariaLabel) {
    this._promise = new Promise((resolve,reject) => {
      className = className || "";
      const node = document.createElement("div");
      document.body.appendChild(node);

      const onClose = (obj) => {
        if (typeof this.onClose === "function") {
          //console.log("Close Modal");
          this.onClose(this);
        }
        resolve();
        setTimeout(() => {
          if (node && node.parentNode) {
            // clean up the temporary node
            //node.innerHTML = ""; // TODO?
            node.parentNode.removeChild(node);
          }
        },2000);
      };

      const onMount = (obj) => {
        this._modalWrapper = obj;
      };

      ReactDOM.render((
        <Provider store={Rdx.store}>
          <ModalWrapper title={title} ariaLabel={ariaLabel}
            className={className}
            onClose={onClose} onMount={onMount}
            >{content}</ModalWrapper>
        </Provider>
      ),node);
    });
  }

  static showMessage(message,title,className,ariaLabel) {
    const i18n = Context.instance.i18n;
    const controller = new ModalController();
    const okClicked = () => {
      controller.close();
    };
    const node = (
      <>
        <p key="message" className={CSS.message}>{message}</p>
        <div key="actions" className={CSS.actions}>
          <button type="button" className={CSS.primaryButton}
            onClick={okClicked}>{i18n.general.ok}</button>
        </div>
      </>
    );
    controller.show(node,title,className,ariaLabel);
  }

  static showOkCancel(props) {
    const i18n = Context.instance.i18n;
    const controller = new ModalController();
    const okClicked = () => {
      if (props.onOk) props.onOk();
      controller.close();
    };
    const cancelClicked = () => {
      controller.close();
    };
    let content = props.content;
    if (typeof content === "string") {
      content = (
        <p key="message" className={CSS.message}>{content}</p>
      );
    }
    const node = (
      <>
        {content}
        <div key="actions" className={"i-actions i-text-right"}>
          <button type="button" className={"i-button i-button-clear i-margin-all-small"}
            onClick={cancelClicked}>{i18n.general.cancel}</button>
          <button type="button" className={"i-button i-margin-all-small"}
            onClick={okClicked}>{i18n.general.ok}</button>
        </div>
      </>
    );
    controller.show(node,props.title,props.className,props.ariaLabel);
  }

}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

class ModalWrapper extends React.Component {

  closeButton = React.createRef()

  constructor(props) {
    super(props);
    this.state = component.newState({
      isOpen: true
    });
    this.afterOpen = this.afterOpen.bind(this);
    this.close = this.close.bind(this)
  }

  componentDidMount () {
    setTimeout(() => {
      this._focus();
    },200)
    if (typeof this.props.onMount === "function") {
      //this.props.onMount.call(this);
      this.props.onMount(this);
    }
  }

  _focus() {
    const ref = this.closeButton && this.closeButton.current
    if (ref && ref.focus) ref.focus()
  }

  afterOpen() {}

  close() {
    if (typeof this.props.onClose === "function") {
      this.props.onClose(this);
    }
    this.setState(state => {
      return {
        isOpen: false
      };
    });
  }

  getCustomStyles(className) {
    let bkg = "var(--i-theme-color-background)";
    let obkg = "var(--i-color-modal-background)";
    if (typeof className === "string") {
      if (className.indexOf("i-configurator-modal") !== -1) {
        bkg = "#ffffff";
      }
      if (className.indexOf("i-colorpicker-modal") !== -1) {
        obkg = "transparent";
      }
    }
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "5px",
        padding: "5px",
        backgroundColor: bkg,
        border: "none"
      },
      overlay: {
        backgroundColor: obkg,
        zIndex: 999
      }
    };
    return customStyles;
  }

  render() {
    if (!this.state.isOpen) return null;
    const i18n = Context.instance.i18n;
    const hashref = "#";
    const title = this.props.title || "";
    const titleNode = (<span className={CSS.title}>{title}</span>);
    const closeAriaLabel = this.props.closeAriaLabel || i18n.modal.closeAriaLabel;
    let ariaLabel = i18n.modal.ariaLabel;
    if (this.props.ariaLabel) {
      ariaLabel = this.props.ariaLabel;
    } else if (title) {
      ariaLabel = title;
    }

    const cls = this.props.className;
    const clsH = cls ? (CSS.header + " " + cls) : CSS.header;
    const clsC = cls ? (CSS.content + " " + cls) : CSS.content;

    const customStyles = this.getCustomStyles(cls);

    const node = (
      <div className={CSS.wrapper}>
        <Modal
          contentLabel={ariaLabel}
          isOpen={this.state.isOpen}
          onAfterOpen={this.afterOpen}
          onRequestClose={this.close}
          shouldCloseOnOverlayClick={true}
          style={customStyles}>
          <section>
            <div key="header" className={clsH}>
              {titleNode}
              <a key="modal-close" href={hashref} className={CSS.close} role="button"
                onClick={this.close} title={i18n.general.close} tabIndex="0"
                aria-label={closeAriaLabel} ref={this.closeButton}
                >
                {Icons.close()}
              </a>
            </div>
            <div key="content" className={clsC}>
              {this.props.children}
            </div>
          </section>
        </Modal>
      </div>
    );
    return node;
  }

}
