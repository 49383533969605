import BaseVM from "../support/BaseVM";
import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import SelectTool from "../support/SelectTool";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as mapUtil from "../../../base/mapUtil";
import * as splitUtil from "../support/splitUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as vectorUtil from "../support/vectorUtil";
import { calculateArea } from "../../../../util/geoUtil";

export default class SplitVM extends BaseVM {

  fpeType = "unit";

  activeFeatureItem;
  activeSketchViewModel;
  activeSplit;
  selectTool;

  bufferWall = false;
  clipWall = true;
  insertWall = false;
  swapUnits = false;
  wallWidthInMapUnits = 0.2;

  _layerId = "indoors-split";
  _resultLayerId = "indoors-split-result";
  _wallLayerId = "indoors-split-wall";

  constructor() {
    super();
    this.initSelectTool();
  }

  activateTool(tool) {
    if (tool === "drawWall") {
      this.activateDrawWall();
    // } else if (tool === "updateWall") {
    //   this.activateUpdateWall();
    } else if (tool === "select") {
      this.activateSelect();
    }
  }

  activateDrawWall() {
    this.cancelSketch();
    const lib = Context.instance.lib;
    const view = this.getView();
    const unitsLayer = sourceUtil.getUnitsLayer();
    const graphicsLayer = mapUtil.ensureGraphicsLayer(view,this._layerId);
    mapUtil.ensureGraphicsLayer(view,this._resultLayerId,sourceUtil.getUnitsLayer());
    mapUtil.ensureGraphicsLayer(view,this._wallLayerId);
    const unit = this.activeSplit && this.activeSplit.unit;

    graphicsLayer.removeAll();
    if (unit) {
      graphicsLayer.add(splitUtil.makeSelectedUnitGraphic(unit));
    }
    this.setActiveFeatureVisibility(true);

    let svm = this.activeSketchViewModel = new lib.esri.SketchViewModel({
      view: view,
      layer: graphicsLayer,
      snappingOptions: {
        enabled: true,
        selfEnabled: true,
        featureEnabled: true,
        // distance: ??, // @todo
        featureSources: [{ layer: graphicsLayer, enabled: true }]        
      }
    });

    const createTool = "polyline";
    const createOptions = {
      mode: "click",
      hasZ: true,
      defaultZ: 0
    }

    const process = async (event) => {
      const sketchGraphic = event.graphic;
      const polyline = event.graphic.geometry;
      if (unit && unit.geometry) {
        this.executeCut(unit,polyline)
      } else {
        this.clearSplit();
        this.activateDrawWall();
      }
    }

    let processing = false;
    svm.on("create",event => {
      if (event.state === "complete") {
        const paths = event.graphic && event.graphic.geometry && event.graphic.geometry.paths;
        if (paths && paths[0] && paths[0].length > 1) {
          processing = true;
          this.cancelSketch();
          process(event);
        } else {
          this.activateDrawWall();
        }
      }
      if (event.state === "cancel") {
        if (!processing && !svm.xtnCanceling) {
          this.activateDrawWall();
        }
      }
    });

    svm.on("update",event => {
      if (!processing && !svm.xtnCanceling) {
        this.activateDrawWall(); // don't allow update
      }
    });

    Context.instance.views.toggleClickHandlers("pause");
    svm.create(createTool,createOptions);
    this.onToolActivated("drawWall");
  }

  activateSelect() {
    this.setActiveFeatureVisibility(true);
    this.cancelSketch();
    this.clearGraphics();
    this.onToolActivated("select");
  }

  cancelSketch() {
    const svm = this.activeSketchViewModel;
    if (svm) {
      svm.xtnCanceling = true;
      svm.cancel();
      svm.destroy();
      this.activeSketchViewModel = null;
      Context.instance.views.toggleClickHandlers("resume");
    }
  }

  canAdjustWall() {
    return !!(this.activeSplit && this.activeSplit.newWall);
  }

  canSave(): boolean {
    return !!(this.activeSplit && this.activeSplit.modifiedUnit);
  }

  clear() {
    const view = this.getView();
    this.cancelSketch();
    mapUtil.removeAllGraphics(view,this._layerId);
    mapUtil.removeAllGraphics(view,this._resultLayerId);
    mapUtil.removeAllGraphics(view,this._wallLayerId);
    this.activeSplit = null;
    this.onChange();
  }

  clearGraphics() {
    const view = this.getView();
    // mapUtil.removeAllGraphics(view,this._annoLayerId);
    mapUtil.removeAllGraphics(view,this._resultLayerId);
  }

  clearSplit() {
    const view = this.getView();
    this.clearGraphics();
    mapUtil.removeAllGraphics(view,this._wallLayerId);
    this.activeSplit = null;
    this.onChange();
  }

  destroy() {
    if (this.selectTool) this.selectTool.destroy();
    super.destroy();
  }

  executeCut = async (unit,polyline) => {
    let clear = true;
    const ge = Context.instance.lib.esri.geometryEngine;
    const view = this.getView();
    const svmGraphicsLayer = mapUtil.ensureGraphicsLayer(view,this._layerId);
    const resultGraphicsLayer = mapUtil.ensureGraphicsLayer(view,this._resultLayerId,sourceUtil.getUnitsLayer());
    const unitsLayer = sourceUtil.getUnitsLayer();
    const detailsLayer = sourceUtil.getDetailsLayer();

    let wall = polyline;
    let insertWall = this.insertWall;
    let bufferWall = insertWall && this.bufferWall;
    let clipWall = this.clipWall || !this.insertWall;
    let swapUnits = this.swapUnits;

    let widthInMapUnits = this.wallWidthInMapUnits;
    if (widthInMapUnits <= 0.02) widthInMapUnits = 0.02;

    const z = unit.geometry.rings[0][0][2];
    const sr = this.getView().spatialReference;
    let bufferedWall, clippedWall, poly1, poly2;
    let modifiedUnit, newUnit, newWall, modifiedUnitName, newUnitName;
    let graphicsA = [];

    if (bufferWall) {
      bufferedWall = vectorUtil.bufferPolyline(polyline,unit,(widthInMapUnits / 2),z);
      wall = bufferedWall;
      // console.log("bufferedWall",bufferedWall)
      // if (bufferedWall) {
      //   svmGraphicsLayer.removeAll();
      //   resultGraphicsLayer.removeAll();
      //   const wallGraphic = new Context.instance.lib.esri.Graphic({
      //     geometry: bufferedWall,
      //     symbol: splitUtil.makeWallSymbol()
      //   });
      //   wallGraphic.symbol.color = "red";
      //   resultGraphicsLayer.graphics.add(wallGraphic);
      //   //throw new Error("zzz")
      // }
    }

    if (wall) {
      const cutResult = ge.cut(unit.geometry,wall)
      //console.log("cutResult",cutResult && cutResult.length,wall)
      let rings1, rings2;
      if (cutResult && cutResult.length === 2) {
        if (bufferedWall) {
          const parts = cutResult[0].rings;
          if (parts && parts.length > 0) {
            rings1 = parts[0];
          }
          if (parts && parts.length > 1) {
            rings2 = parts.slice(1);
          }
        } else {
          rings1 = cutResult[0].rings;
          rings2 = cutResult[1].rings;
        }
      }
      if (rings1 && rings2) {
        poly1 = new Context.instance.lib.esri.Polygon({
          hasZ: true,
          rings: rings1,
          spatialReference: sr
        }) 
        poly2 = new Context.instance.lib.esri.Polygon({
          hasZ: true,
          rings: rings2,
          spatialReference: sr
        })
        // choose the one with largest area
        const area1 = await calculateArea(poly1, "square-meters");
        const area2 = await calculateArea(poly2, "square-meters");
        if (typeof area1 === 'number' && typeof area2 === 'number' && (area2 > area1)) {
          const temp = poly1;
          poly1 = poly2;
          poly2 = temp;
        }
        
        // swap if necessary
        if (swapUnits) {
          const temp = poly1;
          poly1 = poly2;
          poly2 = temp;
        }
      } else {
        resultGraphicsLayer.removeAll();
        this.activateDrawWall();
        return;
      }
    }

    if (wall && clipWall) {
      let clipResult
      if (bufferedWall) {
        let paths = bufferedWall.clone().paths;
        let clipPoly = new Context.instance.lib.esri.Polygon({
          //hasZ: true,
          rings: paths,
          spatialReference: sr
        }) 
        clipResult = ge.intersect(unit.geometry,clipPoly)
        if (clipResult) {
          clippedWall = new Context.instance.lib.esri.Polyline({
            hasZ: true,
            paths: clipResult.clone().rings,
            spatialReference: sr
          })
        }
      } else {
        clipResult = ge.intersect(unit.geometry,polyline)
        clippedWall = clipResult;
      }
      wall = clippedWall;
      //console.log("clipResult",clipResult)
      // if (clippedWall) {
      //   const clipGraphic = new Context.instance.lib.esri.Graphic({
      //     geometry: clippedWall,
      //     symbol: splitUtil.makeWallSymbol()
      //   });
      //   clipGraphic.symbol.color = "purple";
      //   resultGraphicsLayer.graphics.add(clipGraphic); 
      // }
    }

    if (poly1 && poly2) {

      modifiedUnit = new Context.instance.lib.esri.Graphic({
        geometry: poly1
      }); 
      await splitUtil.initModifiedUnit(unit,modifiedUnit,z);
      splitUtil.setUnitSymbol(modifiedUnit,modifiedUnit,"modifiedUnit");
      graphicsA.push(modifiedUnit);

      newUnit = new Context.instance.lib.esri.Graphic({
        geometry: poly2
      }); 
      await splitUtil.initNewUnit(unit,newUnit,z);
      splitUtil.setUnitSymbol(newUnit,newUnit,"newUnit");
      graphicsA.push(newUnit);

      if (wall && insertWall) {
        newWall = new Context.instance.lib.esri.Graphic({
          geometry: wall
        });
        splitUtil.initNewWall(unit,newWall,z);
        splitUtil.setWallSymbol(newWall,newWall,"newWall");
        graphicsA.push(newWall);
      }

      modifiedUnitName = aiimUtil.getAttributeValue(modifiedUnit.attributes,FieldNames.NAME) 
      graphicsA.push(splitUtil.makeUnitNameGraphic("modifiedUnit",modifiedUnit,modifiedUnitName));
      newUnitName = aiimUtil.getAttributeValue(newUnit.attributes,FieldNames.NAME) 
      graphicsA.push(splitUtil.makeUnitNameGraphic("newUnit",newUnit,newUnitName));
    }

    resultGraphicsLayer.removeAll();
    if (graphicsA.length > 0) {
      //console.log("graphics",graphicsA)
      svmGraphicsLayer.removeAll();
      resultGraphicsLayer.addMany(graphicsA);

      this.activeSplit = splitUtil.newSplitItem({
        unit: unit,
        polyline: polyline,
        modifiedUnit: modifiedUnit,
        modifiedUnitName: modifiedUnitName,
        newUnit: newUnit,
        newUnitName: newUnitName,
        newWall: newWall
      });

      this.setActiveFeatureVisibility(false);

      this.onChange();
    }

  }

  getSplitKey() {
    let key = "none", newWallId;
    const info = this.activeSplit;
    const unitsLayer = sourceUtil.getUnitsLayer();
    const detailsLayer = sourceUtil.getUnitsLayer();
    const modifiedUnit = info && info.modifiedUnit;
    const newUnit = info && info.newUnit;
    const newWall = info && info.newWall;
    if (newWall && newWall.attributes) {
      newWallId = aiimUtil.getAttributeValue(newWall.attributes,FieldNames.DETAIL_ID);
      key = newWallId;
    }
    if (modifiedUnit) {
      const oid = modifiedUnit.attributes[unitsLayer.objectIdField];
      key = ""+oid
      if (newUnit) {
        const uid = aiimUtil.getAttributeValue(newUnit.attributes,FieldNames.UNIT_ID);
        key += "_"+uid
      }
      if (newWallId) {
        key += "_"+newWallId
      }
    }
    return key;
  }

  initSelectTool() {
    this.selectTool = new SelectTool();
    this.selectTool.fpeType = this.fpeType;
    this.selectTool.onSelect = (featureItem) => {
      //console.log("onSelect",featureItem)
      try {
        const feature = featureItem && featureItem.feature;
        if (feature) {
          this.setActiveFeatureVisibility(true);
          this.activeFeatureItem = featureItem;
          this.activeSplit = splitUtil.newSplitItem({
            unit: feature
          })
          this.activateDrawWall();
        }
      } catch(ex) {
        console.error(ex);
      }
    }
  }

  nameChanged = (type,name) => {
    const info = this.activeSplit;
    if (info && name && type === "modifiedUnit" && info.modifiedUnit) {
      info.modifiedUnitName = name;
      this.updateNameGraphic(type,info.modifiedUnit,info.modifiedUnitName);
    } else if (info && name && type === "newUnit" && info.newUnit) {
      info.newUnitName = name;
      this.updateNameGraphic(type,info.newUnit,info.newUnitName);
    }
  }

  onChange() {}

  onToolActivated(tool) {}

  save = async () => {
    const activeSplit = this.activeSplit;
    const info = {
      originalUnit: activeSplit.unit,
      modifiedUnit: activeSplit.modifiedUnit,
      newUnit: activeSplit.newUnit,
      newWall: activeSplit.newWall
    }

    let layerUpdated = false, awaitResolve = null;
    const lv = mapUtil.getUnitsLayerView();
    const reactiveUtils = Context.instance.lib.esri.reactiveUtils;
    reactiveUtils.whenOnce(() => lv.updating).then(() => {
      reactiveUtils.whenOnce(() => !lv.updating).then(() => {
        layerUpdated = true;
        if (awaitResolve) awaitResolve();
      });
    })
    const awaitUpdate = delay => {
      if (layerUpdated) return Promise.resolve();
      return new Promise<void>(resolve => {
        awaitResolve = resolve;
        setTimeout(() => {
          resolve()
        }, delay);
      })
    }

    await transactions.splitUnit(info);
    await awaitUpdate(4000);
    this.clear();
    this.activateTool("select");
  }

  setActiveFeatureVisibility(visible) {
    if (this.activeFeatureItem) {
      const oid = this.activeFeatureItem.objectId;
      const lv = mapUtil.getUnitsLayerView();
      // @ts-ignore
      lv.setVisibility(oid,visible);
    }
  }

  setBufferWall = (v) => {
    this.bufferWall = v;
    const activeSplit = this.activeSplit;
    if (activeSplit && activeSplit.polyline) {
      this.executeCut(activeSplit.unit,activeSplit.polyline)
    }
  }

  setClipWall = (v) => {
    this.clipWall = v;
    const activeSplit = this.activeSplit;
    if (activeSplit && activeSplit.polyline) {
      this.executeCut(activeSplit.unit,activeSplit.polyline)
    }
  }

  setInsertWall = (v) => {
    this.insertWall = v;
    const activeSplit = this.activeSplit;
    if (activeSplit && activeSplit.polyline) {
      this.executeCut(activeSplit.unit,activeSplit.polyline)
    }
  }

  setSwapUnits = (v) => {
    this.swapUnits = v;
    const activeSplit = this.activeSplit;
    if (activeSplit && activeSplit.polyline) {
      this.executeCut(activeSplit.unit,activeSplit.polyline)
    }
  }

  setWallWidthInMapUnits = (v) => {
    this.wallWidthInMapUnits = v;
    const activeSplit = this.activeSplit;
    if (activeSplit && activeSplit.polyline && v > 0) {
      if (this.bufferWall) {
        this.executeCut(activeSplit.unit,activeSplit.polyline)
      }
    }
  }

  updateNameGraphic = (type,feature,name) => {
    const view = this.getView();
    const layer = mapUtil.ensureGraphicsLayer(view,this._resultLayerId,sourceUtil.getUnitsLayer());
    const graphic = splitUtil.makeUnitNameGraphic(type,feature,name);
    layer.graphics.some(g => {
      // @ts-ignore
      if (g.xtnNameType === type) {
        layer.graphics.remove(g);
        return true;
      }
      return false;
    })
    layer.graphics.add(graphic);
  }

  useTypeChanged = (feature,purpose) => {
    if (this.activeSplit) {
      if (purpose === "modifiedUnit" && this.activeSplit.modifiedUnit) {
        splitUtil.setUnitSymbol(this.activeSplit.modifiedUnit,feature,purpose);
      } else if (purpose === "newUnit" && this.activeSplit.newUnit) {
        splitUtil.setUnitSymbol(this.activeSplit.newUnit,feature,purpose);
      } else if (purpose === "newWall" && this.activeSplit.newWall) {
        splitUtil.setWallSymbol(this.activeSplit.newWall,feature,purpose);
      }
    }
  }

}