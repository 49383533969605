import Context from "../../../context/Context";
import { IRecurrenceOptions } from "../More/Actions/BookWorkspace/BookingRecurrence";
import { IEventInfo, IReservationInfo } from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import { BookingOperation, BookingType } from "../../../util/calendarUtil";
import moment from "moment-timezone";

function getRecurrenceRule(options: IRecurrenceOptions) {
  let { endDate, type, days } = options;
  const uc = type.toUpperCase();
  const until = formatDate(endDate);
  let daysByDay = [];
  if (uc === "WEEKLY") {
    const daysOfWeek = {};
    daysOfWeek[0] = 'SU';
    daysOfWeek[1] = 'MO';
    daysOfWeek[2] = 'TU';
    daysOfWeek[3] = 'WE';
    daysOfWeek[4] = 'TH';
    daysOfWeek[5] = 'FR';
    daysOfWeek[6] = 'SA';
    const daysByNum = days;
    daysByNum.forEach((num) => {
      daysByDay.push(daysOfWeek[num])
    })
    return `RRULE:FREQ=${uc};UNTIL=${until};INTERVAL=${options.interval};WKST=SU;BYDAY=${daysByDay}`;
  } else if (uc === "DAILY") {
    return `RRULE:FREQ=${uc};UNTIL=${until}`;
  }
}

const openCalendar =(bookingOperation: BookingOperation, prodid: string) => {
  const calendar = [];
  if (bookingOperation === "removeBooking") {
    calendar.push(...[
      "BEGIN:VCALENDAR",
      "PRODID:" + prodid,
      "VERSION:2.0",
      "METHOD:CANCEL"
    ]);
  } else if (bookingOperation === "updateBooking") {
    calendar.push(...[
      "BEGIN:VCALENDAR",
      "PRODID:" + prodid,
      "VERSION:2.0",
      "METHOD:PUBLISH"
    ]);
  } else {
    calendar.push(...[
      "BEGIN:VCALENDAR",
      "PRODID:" + prodid,
      "VERSION:2.0",
      "METHOD:PUBLISH"
    ]);
  }
  return calendar;
}
const addTimeZones = (start: Date | number) => {
  const startRange = moment.tz(start, TZ).unix() * 1000;
  const timeZones = generateVTimezone(TZ, startRange, startRange);
  return timeZones;
}
const openEvent = (bookingOperation: BookingOperation, uid: string, dateNow: string, sequence: number = 1) => {
  const event = [
    "BEGIN:VEVENT",
    "UID:" + uid,
    "DTSTAMP:" + dateNow
  ];
  switch (bookingOperation) {
    case "addBooking":
      event.push(...["SEQUENCE:" + sequence]);
      break;
    case "removeBooking":
      event.push(...[
        "STATUS:CANCELLED",
        "SEQUENCE:" + sequence
      ]);
      break;
    case "updateBooking":
      event.push(...[
        "STATUS:NEEDS-ACTION",
        "SEQUENCE:" + sequence
      ])
      break;
  }
  return event;
}
const closeEvent = () => (["END:VEVENT"]);

const chkStr = (v) => {
  if (typeof v !== "string") return "";
  return v;
}

const formatDate = (date: Date, utc: boolean = true): string => {
  if (date === undefined || date === null) return null;
  if (typeof date === "number") {
    date = new Date(date);
  }
  // only use UTC or local time with a time zone (do not use local time alone)
  const opts = { zulu: utc || MISSING_TIME_ZONE, milliseconds: false };
  const lib = Context.getInstance().lib;
  const v: string = lib.dojo.stamp.toISOString(date, opts);
  const dateString = !opts.zulu ? v.substring(0, v.lastIndexOf("-")) : v;
  return dateString.replace(/-/g, "").replace(/:/g, "");
}
const TZ = moment.tz.guess();
const MISSING_TIME_ZONE = TZ?.length == 0;
const tzParam = () => MISSING_TIME_ZONE ? "" : `;TZID="${TZ}"`;
export interface IICSData {
  blob: Blob,
  data: string,
  href: string,
  filename: string,
  type: BookingType
}
export function makeIcsInfo(
  eventInfo: IReservationInfo | IEventInfo,
  filename: string,
  bookingOperation: BookingOperation = "addBooking",
  type: BookingType = "single"
): Promise<IICSData> {
  const promise = new Promise<IICSData>((resolve,reject) => {
    try {
      const icsInfo = {
        blob: null,
        data: null,
        href: null,
        filename: filename || "event.ics",
        type
      }
      const data = icsInfo.data = makeIcsString(eventInfo, bookingOperation, type === "occurrence");
      if (data && FileReader && Blob) {
        const blob = icsInfo.blob = new Blob([data], {type:"text/calendar"});
        const reader = new FileReader();
        reader.onload = function(event) {
          icsInfo.href = event.target.result;
          resolve(icsInfo);
        };
        reader.onerror = function(err) {
          reject(err);
        }
        reader.readAsDataURL(blob);
      } else {
        resolve(icsInfo);
      }
    } catch(ex) {
      reject(ex);
    }
  });
  return promise;
}

function makeIcsString(eventInfo: IReservationInfo | IEventInfo, bookingOperation: BookingOperation, occurrence: boolean) {
  const prodid = "ArcGIS Indoors"; // TODO?
  const summary = chkStr(eventInfo.name);
  const description = chkStr(eventInfo.description);
  const hyperlink = chkStr(eventInfo.hyperlink);
  const location = chkStr(eventInfo.location);
  const dateNow = new Date();
  const formattedDate = formatDate(dateNow);
  let uid = eventInfo.uid;
  let dateStart = formatDate(eventInfo.date_start, MISSING_TIME_ZONE);
  let dateEnd = formatDate(eventInfo.date_end, MISSING_TIME_ZONE);
  // recurrence_id here does not refer to the series id as defined in the workspace reservation
  // schema; instead, it refers to a unique occurrence of an event within a recurring series. It's
  // value is the original start time of the occurrence and is used when updating/deleting an occurrence.
  // https://icalendar.org/iCalendar-RFC-5545/3-8-4-4-recurrence-id.html
  const recurrence_id = occurrence && (bookingOperation === "removeBooking" || bookingOperation === "updateBooking")
    ? formatDate(eventInfo.occurrence_id, MISSING_TIME_ZONE)
    : null;

  let geo = eventInfo.geo;
  if (!geo) {
    let geometry = eventInfo.geometry;
    if (geometry && geometry.type === "polygon") {
      geometry = (geometry as __esri.Polygon).centroid;
    }
    if (geometry && geometry.type === "point") {
      const point = geometry as __esri.Point;
      if (typeof point.latitude === "number" && typeof point.longitude === "number") {
        geo = point.latitude + ";" + point.longitude;
      }
    }
  }
  geo = chkStr(geo);

  if (summary.length === 0 || !dateStart) {
    return null; // not enough info
  }

  const options = "recurrenceOptions" in eventInfo && eventInfo.recurrenceOptions;

  let ics = openCalendar(occurrence ? "updateBooking" : bookingOperation, prodid);
  !MISSING_TIME_ZONE && ics.push(...addTimeZones(eventInfo.date_start));
  const isSeriesCancelled = options && !occurrence && bookingOperation === "removeBooking";
  const sequence = eventInfo.sequence ?? 1;
  // @todo is this change correct?
  if (options && options.type) { // part of a series
    // since this booking operation is part of a series we also need to update the series    
    ics.push(...openEvent(occurrence ? "updateBooking" : bookingOperation, uid, formattedDate, sequence));
    ics.push(getRecurrenceRule(options));
    if (eventInfo.ex_date?.length > 0 && !isSeriesCancelled) {
      ics.push(`EXDATE${tzParam()}:${[...new Set<string>(eventInfo.ex_date.map(d => formatDate(d, false)))].join(",")}`);
    }  
    ics.push(...addEventDetails(formatDate(eventInfo.series_start, MISSING_TIME_ZONE), formatDate(eventInfo.series_end, MISSING_TIME_ZONE)));
    ics.push(...closeEvent());
  } else {
    ics.push(...openEvent(bookingOperation, uid, formattedDate));
    ics.push(...addEventDetails());
    ics.push(...closeEvent());
  }

  // add modified occurrences
  eventInfo && eventInfo.modified && eventInfo.modified.forEach((occurrence, i) => {
    const { original_date_start: recurrence_id, date_start, date_end, is_cancelled } = occurrence;
    if (isSeriesCancelled) {
      ics.push(...openEvent("removeBooking", uid, formattedDate, sequence + i + 1));
      ics.push(`RECURRENCE-ID${tzParam()}:${formatDate(recurrence_id, MISSING_TIME_ZONE)}`)
      ics.push(...addEventDetails(formatDate(date_start, MISSING_TIME_ZONE), formatDate(date_end, MISSING_TIME_ZONE)));
      ics.push(...closeEvent());
    } else if (!is_cancelled && recurrence_id && dateStart && dateEnd) {
      ics.push(...openEvent("updateBooking", uid, formattedDate, sequence + i + 1));
      ics.push(`RECURRENCE-ID${tzParam()}:${formatDate(recurrence_id, MISSING_TIME_ZONE)}`)
      ics.push(...addEventDetails(formatDate(date_start, MISSING_TIME_ZONE), formatDate(date_end, MISSING_TIME_ZONE)));
      ics.push(...closeEvent());
    }
  });

  ics = ics.concat([
    "END:VCALENDAR"
  ]);

  // "CLASS:PUBLIC", // TODO?
  // "TRANSP:TRANSPARENT",   // TODO?

  const delimiter = "\n";
  const data = ics.join(delimiter);
  return data;

  function addEventDetails(start?: string, end?: string) {
    const details = [];
    if (eventInfo?.bookingType === "hotel") details.push("X-MICROSOFT-CDO-BUSYSTATUS:FREE");
    if (start || dateStart) details.push(`DTSTART${tzParam()}:${start || dateStart}`);
    if (end || dateEnd) details.push(`DTEND${tzParam()}:${end || dateEnd}`);
    if (summary.length > 0) details.push("SUMMARY:" + summary);
    if (location.length > 0) details.push("LOCATION:" + location);
    if (geo.length > 0) details.push("GEO:" + geo);
    if (description.length > 0) details.push("DESCRIPTION:" + description);
    if (hyperlink.length > 0) details.push(hyperlink);
    return details;
  }
}
function generateVTimezone (timezoneName: string, tsRangeStart: number, tsRangeEnd?: number) {
  let i, dtStart, utcOffsetBefore, utcOffsetDuring, periodType;
  const zone = moment.tz.zone(timezoneName);
  const { untils, abbrs, offsets } = zone;
  const vtz = [
    `BEGIN:VTIMEZONE`,
    `TZID:${timezoneName}`
  ];

  tsRangeStart = tsRangeStart || 0;
  tsRangeEnd = tsRangeEnd || Math.pow(2,31)-1;

  // https://momentjs.com/timezone/docs/#/data-formats/unpacked-format/
  // > between `untils[n-1]` and `untils[n]`, the `abbr` should be 
  // > `abbrs[n]` and the `offset` should be `offsets[n]`
  for (i = 0; i < untils.length - 1; i++) {
    // filter to intervals that include our start/end range timestamps
    if (untils[i+1] < tsRangeStart) continue;     // interval ends before our start, skip
    if (i > 0 && untils[i-1] > tsRangeEnd) break; // interval starts after interval we end in, break

    utcOffsetBefore = formatUtcOffset(offsets[i]);                          // offset BEFORE dtStart
    dtStart = moment.tz(untils[i], timezoneName).format('YYYYMMDDTHHmmss');
    utcOffsetDuring = formatUtcOffset(offsets[i + 1]);                        // offset AFTER dtStart
    periodType = offsets[i + 1] < offsets[i] ? 'DAYLIGHT' : 'STANDARD';     // spring-forward, DAYLIGHT, fall-back: STANDARD.
    
    vtz.push(`BEGIN:${periodType}`);
    vtz.push(`DTSTART:${dtStart}`);               // local date-time when change
    vtz.push(`TZOFFSETFROM:${utcOffsetBefore}`);  // utc offset BEFORE DTSTART
    vtz.push(`TZOFFSETTO:${utcOffsetDuring}`);    // utc offset AFTER DTSTART
    vtz.push(`TZNAME:${abbrs[i+1]}`);
    vtz.push(`END:${periodType}`);
  }
  vtz.push(`END:VTIMEZONE`);
  return vtz;
}

function formatUtcOffset(minutes: number) {
  const hours = Math.floor(Math.abs(minutes) / 60).toString();
  const mins = (Math.abs(minutes) % 60).toString();
  const sign = minutes > 0 ? '-' : '+'; // sign inverted, see https://momentjs.com/timezone/docs/#/zone-object/offset/
  const output = [sign];

  // zero-padding
  if (hours.length < 2) output.push('0');
  output.push(hours);
  if (mins.length < 2) output.push('0');
  output.push(mins);

  return output.join('');
}