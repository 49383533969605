import FieldNames from "../../../../aiim/datasets/FieldNames";
import { findField, getAttributeValue } from "../../../../aiim/util/aiimUtil";
import { escSqlQuote } from "../../../../aiim/util/selectionUtil";
import Context from "../../../../context/Context";
import Topic from "../../../../context/Topic";
import OfficePlan from "../../../base/OfficePlan";
import { getPersonAssignmentNameAsync, isPersonUnassigned, isUnitFeature, isUnitUnassigned, queryPeopleNamesAssignedToUnits } from "../../../base/officePlanUtil";
import { makeResultInfo, queryFeatures } from "../../../base/queryUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as transactions from "../../../base/transaction/transactions";
import TransactionGuard from "../../../base/TransactionGuard";
import AssignmentsPromptPeople from "./AssignmentsPromptPeople";
import BulkDuplicatesModal from "./BulkDuplicatesModal";
import { IFeature } from "@esri/arcgis-rest-types";
import { IQueryFeaturesOptions, IQueryFeaturesResponse } from "@esri/arcgis-rest-feature-layer";
import { IFeatureItem } from "../PeopleAssignTo";
import { HomeOfficeJSON, HomeOfficeId } from "../../../base/AreasTable";

// People Info
// Dan Johnston     -   Units: O1w220, O1w225, O1w235 | Areas: N/A
// Elizabeth Jones  -   Units: N/A | Areas: ArcGIS Hotel Area M3E, IST Hotels
// Dominic James    -   Units: O1w260, O1w255, O1w250
export type Assignments = {
  areas: IFeature[],
  units: (IFeature | IFeatureItem)[]
}
export function getPersonDuplicates(
  personName: string,
  personEmail: string,
  unitId?: string,
  areaId?: string)
: Promise<IFeature[]> {
  return new Promise((resolve, reject) => {
    const lib = Context.instance.lib
    const source = sourceUtil.getPeopleSource()
    const layer = source.layer2D
    const url = layer.url + "/" + layer.layerId + "/query"
    const emailField = findField(layer.fields, FieldNames.PEOPLE_EMAIL)
    const nameField = findField(layer.fields, FieldNames.PEOPLE_FULLNAME)
    const pairs = {}
    pairs[emailField.name] = personEmail
    pairs[nameField.name] = personName
    if (unitId) {
      const unitIdField = findField(layer.fields, FieldNames.UNIT_ID)
      pairs[unitIdField.name] = unitId
    }
    if (areaId) {
      const areaIdField = findField(layer.fields, FieldNames.AREA_ID)
      pairs[areaIdField.name] = areaId
    }
    const where = makeWhere(pairs)
    const timeout = (60000 * 10)
    const token = getToken()
    const params: Partial<IQueryFeaturesOptions> = {
      f: "json",
      where: where,
      outFields: "*"
    }
    if (layer.gdbVersion) params.gdbVersion = layer.gdbVersion
    const options: __esri.RequestOptions = {
      query: params,
      // @ts-ignore
      method: "get",
      responseType: "json",
      timeout: timeout,
      token: token
    }
    const esriRequest: typeof __esri.request = lib.esri.esriRequest;
    esriRequest(url, options).then((result: __esri.RequestResponse) => {
      const data: IQueryFeaturesResponse = result && result.data;
      if (data && data.features && data.features.length > 0) {
        resolve(data.features)
      } else {
        resolve([])
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export function getPersonAssignments(personName: string, personEmail: string): Promise<Assignments> {
  if (!personEmail) return Promise.resolve({ areas: [], units: [] })
  const unitIds: string[] = [], areaIds: string[] = []
  const assignments: Assignments = { areas: [], units: [] }
  return new Promise((resolve, reject) => {
    return getPersonDuplicates(personName, personEmail).then(occupants => {
      occupants.forEach(o => {
        const areaId = getAttributeValue(o.attributes, FieldNames.AREA_ID)
        if (areaId) {
          areaIds.push(areaId)
        } else {
          const unitId = getAttributeValue(o.attributes, FieldNames.UNIT_ID)
          if (unitId) unitIds.push(unitId)
        }
      })
    }).then(() => {
      return getUnitsUseType(unitIds)
    }).then(units => {
      units.forEach(unit => {
        assignments.units.push(unit)
      })
    }).then(() => {
      return getAreaNamesById(areaIds)
    }).then(areas => {
      areas.forEach(area => {
        assignments.areas.push(area)
      })
    }).then(() => {
      resolve(assignments)
    }).catch(e => {
      console.error("Couldn't get person assignments", e)
      reject(e)
    })
  })
}

export function getPersonAssignmentsCount(assignments: Assignments) {
  let count = 0
  if (!assignments) return count
  else {
    if (assignments.areas && assignments.areas.length > 0) count += assignments.areas.length
    if (assignments.units && assignments.units.length > 0) count += assignments.units.length
  }
  return count
}

export function getDuplicateByUnit(personName: string, personEmail: string, unitId: string, supportInfo)
: Promise<PersonDuplicate[]> {
  return new Promise((resolve, reject) => {
    getPersonDuplicates(personName, personEmail, unitId, null).then(result => {
      const data: PersonDuplicate[] = [];
      const associatedUnit = supportInfo.unit
      if (result && result.length > 0) {
        result.forEach(person => {
          data.push({
            person: person,
            associatedUnit: associatedUnit,
            associatedArea: null
          })
        })
      }
      resolve(data)
    }).catch(e => {
      console.error("Couldn't get duplicate", e)
      reject(e)
    })
  })
}
export type PersonDuplicate = { person: IFeature, associatedUnit: IFeature, associatedArea: IFeature };

export function getDuplicateByArea(personName: string, personEmail: string, areaId: string, supportInfo)
  : Promise<PersonDuplicate[]> {
  return new Promise((resolve, reject) => {
    getPersonDuplicates(personName, personEmail, null, areaId).then(result => {
      const data: PersonDuplicate[] = [];
      const associatedArea = supportInfo.area
      if (result && result.length > 0) {
        result.forEach(person => {
          data.push({
            person: person,
            associatedUnit: null,
            associatedArea: associatedArea
          })
        })
      }
      resolve(data)
    }).catch(e => {
      console.error("Couldn't get duplicate", e)
      reject(e)
    })
  })
}

export function canDeleteDuplicate(personName: string, personEmail: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let canDelete = false
    getPersonAssignments(personName, personEmail).then(assignments => {
      return getPersonAssignmentsCount(assignments)
    }).then(count => {
      canDelete = count > 1
    }).then(() => {
      resolve(canDelete)
    }).catch(e => {
      console.error(e)
      resolve(false)
    })
  })
}

export function getDuplicateCount(personName: string, personEmail: string): Promise<number> {
  return new Promise((resolve, reject) => {
    getPersonAssignments(personName, personEmail).then(assignments => {
      return getPersonAssignmentsCount(assignments)
    }).then(count => {
      resolve(count)
    }).catch(e => {
      console.error(e)
      resolve(-1)
    })
  })
}

export function unassignAdditionalAssignments(personAssignments: Assignments, featureItem: IFeatureItem | IFeature)
  : Promise<PersonDuplicate[]> {
  if (!personAssignments || !featureItem) return Promise.resolve([]);
  const promises: Promise<PersonDuplicate[]>[] = []
  const personAttributes = "attributes" in featureItem
    ? featureItem.attributes
    : featureItem.feature.attributes
  const currentUnitId = getAttributeValue(personAttributes, FieldNames.UNIT_ID)
  const currentAreaId = getAttributeValue(personAttributes, FieldNames.AREA_ID)
  const personName = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
  const personEmail = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
  if (personAssignments.units && personAssignments.units.length > 0) {
    personAssignments.units.forEach(unit => {
      const attributes = "attributes" in unit
        ? unit.attributes
        : unit.feature.attributes
      const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID)
      if (unitId !== currentUnitId) {
        const promise = getDuplicateByUnit(personName, personEmail, unitId, {unit: unit})
        promises.push(promise)
      }
    })
  }
  if (personAssignments.areas && personAssignments.areas.length > 0) {
    personAssignments.areas.forEach(area => {
      const attributes = area.attributes;
      const areaId = getAttributeValue(attributes, FieldNames.AREA_ID)
      if (areaId !== currentAreaId) {
        const promise = getDuplicateByArea(personName, personEmail, areaId, {area: area})
        promises.push(promise)
      }
    })
  }
  return Promise.all(promises).then(results => {
    const people: PersonDuplicate[] = [];
    if (results && results.length > 0) {
      results.forEach(result => {
        if (result && result.length > 0) {
          result.forEach(person => {
            people.push(person)
          })
        }
      })
    }
    // return deleteDuplicatePeople(people)
    return people
  }).catch(e => {
    console.error("Error getting duplicate occupants", e);
    return [];
  })
}

export function deleteDuplicatePeople(
  personItems: { person: IFeature }[],
  isAdditional?: boolean,
  isRemoveAll?: boolean,
  baseGuard?: boolean) {
  const personFeatures = personItems.map(p => p.person)
  let guard = null
  if (!baseGuard) guard = new TransactionGuard({featureItems: personFeatures})
  transactions
    .deletePeople(personItems, isRemoveAll)
    .then((result) => {
      if (guard) {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        })
      }
    })
    .catch((error) => {
      if (guard) guard.close();
      console.error("Error unassigning people", error);
      Topic.publishErrorUpdatingData(error.submessage);
    }).finally(() => {
      if (!isAdditional) Topic.publish(Topic.CloseItemPopup, {})
    })
}

export function deleteDuplicatePerson(personItem: { person: IFeature }) {
  deleteDuplicatePeople([personItem])
}

export async function showUnassignModal(props: {
  assignToFeature: __esri.Graphic | IFeatureItem,
  onOK: (unassignAssignments: boolean, keepCurrentAssignment: boolean, unassignOthers?: boolean) => void,
  personFeature: IFeatureItem,
  plural: boolean,
  setAssignments: (assignments: Assignments) => void,
  showKeepCurrentAssignment: boolean,
  to: string
}) {
  // personFeature, assignToFeature, plural, to, onOK
  const personFeature = props.personFeature
  const assignToFeature = props.assignToFeature
  const currentAssignment = await getPersonAssignmentNameAsync(personFeature)
  const showKeepCurrentAssignment = props.showKeepCurrentAssignment
  const personAttributes = personFeature && personFeature.feature && personFeature.feature.attributes
  const personName = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
  const personEmail = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)

  try {
    let peopleNames = [];
    if (assignToFeature && isUnitFeature(assignToFeature)) {
      const result = await queryPeopleNamesAssignedToUnits([assignToFeature])
      if (result && result.names && result.names.length > 0 &&
          !result.exceededTransferLimit) {
        const subject = personName;
        peopleNames = result.names.filter(v => {
          return (v !== subject);
        });
      }
    }

    const assignments = await getPersonAssignments(personName, personEmail);
    const skipConfirmationModal = shouldSkipModal(personFeature, assignToFeature);
    if (skipConfirmationModal) {
      if (typeof props.onOK === "function") props.onOK(false, false)
    } else {
      if (typeof props.setAssignments === "function") props.setAssignments(assignments)
      AssignmentsPromptPeople.showModal({
        assignTo: assignToFeature,
        personFeature: personFeature,
        plural: props.plural,
        to: props.to,
        assignments: assignments,
        currentAssignment: currentAssignment,
        showKeepCurrentAssignment: showKeepCurrentAssignment,
        onOK: props.onOK,
        peopleNames: peopleNames
      })
    }
  } catch(e) {
    console.error("Error querying unit and assignments", assignToFeature, personFeature, e);
  }
}

export function showBulkDuplicatesModal(props) {
  const people = props.people
  const assignToFeature = props.assignToFeature
  BulkDuplicatesModal.showModal({
    people: people,
    assignToFeature: assignToFeature,
    plural: props.plural,
    to: props.to,
    onOK: props.onOK
  })
}

export function getUnitsUseType(unitIds: string[]): Promise<IFeatureItem[]> {
  return new Promise((resolve, reject) => {
    const source = sourceUtil.getUnitsSource()
    const layer = source.layer2D
    const unitIdField = findField(layer.fields, FieldNames.UNIT_ID)
    const promises: Promise<__esri.FeatureSet>[] = []
    unitIds.forEach(unitId => {
      const pairs = {}
      pairs[unitIdField.name] = unitId
      const where = makeWhere(pairs)
      const options = {
        source: source,
        where: where,
        returnGeometry: false
      }
      const promise: Promise<__esri.FeatureSet> = queryFeatures(options)
      promises.push(promise)
    })
    Promise.all(promises).then(results => {
      const units: IFeatureItem[] = []
      if (results && results.length > 0) {
        results.forEach(result => {
          if (result && result.features) {
            const resultInfo = makeResultInfo(source, result);
            if (resultInfo && resultInfo.featureItems &&
                resultInfo.featureItems.length > 0) {
              const unitFeatureItems = resultInfo.featureItems;
              unitFeatureItems.forEach(u => units.push(u))
            }
          }
        })
      }
      resolve(units)
    }).catch(e => {
      reject(e)
    })
  })
}

export function checkForDuplicatePeople(peopleFeatures: (__esri.Graphic | IFeature)[]) {
  if (!peopleFeatures) return Promise.resolve()
  let promises = [], duplicates = [], singleRecords = []
  peopleFeatures.forEach(person => {
    const attributes = getAttributes(person)
    const personName = getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
    const personEmail = getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
    const promise = canDeleteDuplicate(personName, personEmail).then(result => {
      if (result) duplicates.push(person)
      else singleRecords.push(person)
    })
    promises.push(promise)
  })
  return Promise.all(promises).then(() => {
    return {duplicates: duplicates, singleRecords: singleRecords}
  })
}

export function getAreaNamesById(areaIds: string[]): Promise<IFeature[]> {
  return new Promise((resolve, reject) => {
    const lib = Context.instance.lib
    const table = OfficePlan.getActive().areasTable.table
    const url = table.url + "/" + table.layerId + "/query"
    const areaIdField = findField(table.fields, FieldNames.AREA_ID)
    const pairs = {}
    const promises: Promise<__esri.RequestResponse>[] = []
    areaIds.forEach(areaId => {
      pairs[areaIdField.name] = areaId
      const where = makeWhere(pairs)
      const timeout = (60000 * 10)
      const token = getToken()
      const params: Partial<IQueryFeaturesOptions> = {
        f: "json",
        where: where,
        outFields: "*",
      }
      if (table.gdbVersion) params.gdbVersion = table.gdbVersion
      const options: __esri.RequestOptions = {
        query: params,
        // @ts-ignore
        method: "get",
        responseType: "json",
        timeout: timeout,
        token: token
      }
      const esriRequest: typeof __esri.request = lib.esri.esriRequest;
      const promise = esriRequest(url, options)
      promises.push(promise)
    })
    Promise.all(promises).then(results => {
      const areas = areaIds.includes(HomeOfficeId) ? [{ ...HomeOfficeJSON }] : [];
      if (results && results.length > 0) {
        results.forEach(result => {
          const data: IQueryFeaturesResponse = result && result.data; 
          if (data && data.features && data.features.length > 0) {
            data.features.forEach(f => areas.push(f))
          }
        })
      }
      resolve(areas)
    }).catch(e => {
      console.error("Couldn't get area names", e)
      reject(e)
    })
  })
}

export function compareUnits(unitA: __esri.Graphic, unitB: __esri.Graphic) {
  if (!unitA || !unitB) return false
  const unitIdA = getAttributeValue(unitA.attributes, FieldNames.UNIT_ID)
  const unitIdB = getAttributeValue(unitB.attributes, FieldNames.UNIT_ID)
  return unitIdA === unitIdB
}

export function compareAreas(areaA: __esri.Graphic, areaB: __esri.Graphic) {
  if (!areaA || !areaB) return false
  const areaIdA = getAttributeValue(areaA.attributes, FieldNames.AREA_ID)
  const areaIdB = getAttributeValue(areaB.attributes, FieldNames.AREA_ID)
  return areaIdA === areaIdB
}

export function makeWhere(pairs) {
  const defs = []
  Object.keys(pairs).forEach(f => {
    const v = escSqlQuote(pairs[f])
    const def = f + " = '" + v + "'"
    defs.push(def)
  })
  const where = defs.join(" AND ")
  return where
}

export function getAttributes(target) {
  return target.attributes || (target.feature && target.feature.attributes)
}

export function getGeometry(target) {
  return target.geometry || (target.feature && target.feature.geometry)
}

function getToken() {
  const esriId = Context.instance.lib.esri.esriId;
  const credential = esriId.findCredential(Context.instance.getPortalUrl());
  return credential && credential.token;
}

export function shouldSkipModal(personFeature: IFeatureItem, targetFeature: __esri.Graphic | IFeatureItem, dragInfo?) {
  if (!personFeature || !targetFeature) {
    return false;
  }

  const skipConfirmationModal = canSkipModal();

  const isTargetUnassigned = _isArea(targetFeature) || isUnitUnassigned(targetFeature);
  const isSubjectUnassigned = isPersonUnassigned(personFeature);

  const bothUnassigned = isTargetUnassigned && isSubjectUnassigned;
  const draggedFromUnitPopup =
    dragInfo &&
    dragInfo.subjectParams &&
    dragInfo.subjectParams.fromUnitPopup &&
    isTargetUnassigned;

  const unitAttributes = getAttributes(targetFeature);
  const unitsLayer = sourceUtil.getUnitsLayer();
  const asnField = findField(unitsLayer.fields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const notAssignable =
    getAttributeValue(unitAttributes, asnField) === OfficePlan.SpaceAssignmentTypes.notAssignable;

  return (
    targetFeature &&
    !notAssignable &&
    skipConfirmationModal &&
    (bothUnassigned || draggedFromUnitPopup)
  );
}

export function canSkipModal() {
  const session = Context.getInstance().session;
  const userSettings = session && session.userSettings;
  const enablePopups = userSettings && userSettings.enablePopups;
  return !!!enablePopups;
}

export function getAssignmentNames(assignments, currentAssignment) {
  const areas = assignments.areas
  const units = assignments.units
  const names = []
  areas.forEach(area => {
    const areaName = getAttributeValue(area.attributes, FieldNames.AREA_NAME);
    if (areaName && areaName !== currentAssignment) {
      names.push(areaName);
    }
  });
  units.forEach(unit => {
    const unitField = Context.instance.aiim.getUnitName();
    const unitName = getAttributeValue(unit.feature.attributes, unitField);
    if (unitName && unitName !== currentAssignment) {
      names.push(unitName);
    }
  });

  return names;
}

function _isArea(feature) {
  const attributes = getAttributes(feature);
  if (!attributes) {
    return false;
  }

  const areaName = getAttributeValue(attributes, FieldNames.AREA_NAME);
  return !!areaName;
}
