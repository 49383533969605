import styled from "styled-components";

const EmptyListMessage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.panelPadding};
`;

export { EmptyListMessage };
