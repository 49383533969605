import styled from "styled-components";
import { CalciteH4 } from "calcite-react/Elements";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
`;

const Title = styled(CalciteH4)`
  margin: 0;
  font-size: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const ButtonContainer = styled.div`
  white-space: nowrap;
`;

export { HeaderContainer, Title, ButtonContainer };
