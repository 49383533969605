import React, { createRef } from "react";
import Context from "../../../context/Context";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as component from "../../../components/util/component";

export default class LegendItems extends React.Component {

  _coreNode;
  _coreWidget;
  nodeRef = createRef();

  constructor(props) {
    super(props);
    this.setCoreNode = this.setCoreNode.bind(this);
  }

  componentDidMount() {
    this._initWidget();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this)
  }

  _initWidget() {
    const context = Context.getInstance();
    const lib = context.lib;
    const featureLayer = officePlanUtil.getUnitsSource().layer2D;
    const view = (context.views && context.views.activeView);
    if (this._coreNode) {
      const legend = new lib.esri.Legend({
        container: this._coreNode,
        view: view,
        layerInfos: [{
          layer: featureLayer
        }]
      })

      // Fix for issue #2257
      lib.esri.reactiveUtils.when(() => legend.container, () => {
        lib.dojo.aspect.after(legend, "scheduleRender", function(response) {
          if (lib.dojo.query('.esri-legend__layer-row')) {
            const rows = lib.dojo.query('.esri-legend__layer-row')
            rows.forEach((row, index) => {
              const trimmedText = row.innerText.trim()
              if (trimmedText === "" || trimmedText === "<Null>") {
                lib.dojo.query('.esri-legend__layer-row')[index].style.display = "none"
              }
            })
          }
        });
      });
      this._coreWidget = legend
    }
  }

  render() {
    return <div ref={this.setCoreNode}></div>;
  }

  setCoreNode(node) {
    this._coreNode = node;
  }

}