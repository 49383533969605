import React from "react";
import ReactDOM from "react-dom";

import BaseClass from "./BaseClass";
import Context from "../context/Context";
import Topic from "../context/Topic";

import "@esri/calcite-components/dist/components/calcite-alert";
import {
  CalciteAlert
} from "@esri/calcite-components-react";

export default class CalciteToaster extends BaseClass {

  domNode;

  constructor(props?) {
    super(props);
    this.init();
    if (Context.instance.session.startupToast){
      this.showToast(Context.instance.session.startupToast);
    }
  }

  dismissAll() {
    if (this.domNode) {
      const nl = this.domNode.querySelectorAll("calcite-alert",this.domNode);
      if (nl && nl.length > 0) {
        for (let i = 0; i < nl.length; i++) {
          nl[i].open = false;
        }
      }
    }
  }

  init() {
    Topic.subscribe(Topic.ShowToast,params => {
      if (params.dismissAll) this.dismissAll();
      this.showToast(params);
    });

    Topic.subscribe(Topic.ClearToast, params =>{
      this.dismissAll();
    });
  }

  showToast(params) {
    console.log("showToast",params)
    let type = params.type;
    if (!type) type = "warning";
    if (type === "error") type = "danger";

    let messageNode = null;
    const msg = params.message;
    if (params.dangerouslySetInnerHTML){
      messageNode = <span dangerouslySetInnerHTML={{ __html: Context.sanitizeHtml(msg)}}></span>
    } else {
      messageNode = <span>{msg}</span>
    }

    let submessageNode = null;
    const submsg = params.submessage;
    if (typeof submsg === "string" && submsg.length > 0) {
      submessageNode = <span>{submsg}</span>
    }

    if (!this.domNode) {
      this.domNode = document.createElement("div");
      document.body.appendChild(this.domNode);
    }
    const node = document.createElement("div");
    this.domNode.appendChild(node);

    ReactDOM.render(
      <div className="i-toast-container">
        <CalciteAlert 
          open 
          icon
          autoClose={(type === "danger") ? undefined : true}
          autoCloseDuration="medium"
          label="alert" 
          placement="bottom-end"
          kind={type}
          onCalciteAlertClose={() => {
            if (node && node.parentNode) {
              node.parentNode.removeChild(node);
              ReactDOM.unmountComponentAtNode(node);
            }            
          }}
        >
          <div slot="title">{messageNode}</div>
          {submessageNode && <div slot="message">{submessageNode}</div>}
        </CalciteAlert>
      </div>
      , node
    )
  }

}