import React, {createRef} from "react";

import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {SingleDatePicker, SingleDatePickerShape} from "react-dates";

import Context from "../../../context/Context";
import * as component from "../../util/component";
import "./SDPWrapper.scss";

interface ISDPWrapperProps {
  booking: boolean,
  dayHighlighted?: (day: moment.Moment) => boolean,
  forSchedule?: boolean,
  initialDate: Date,
  onDateChange?: (date: Date) => void,
  onNextMonthClick?: (newCurrentMonth: moment.Moment) => void,
  onPrevMonthClick?: (newCurrentMonth: moment.Moment) => void,
  outsideRange?: (day: moment.Moment) => boolean
}
interface ISDPWrapperState {
  id: string,
  focused: boolean,
  date: moment.Moment
}
export default class SDPWrapper extends React.Component<ISDPWrapperProps, ISDPWrapperState> {

  nodeRef = createRef<HTMLDivElement>();

  constructor(props: ISDPWrapperProps) {
    super(props);
    let momentObj = moment(new Date());
    let date = props.initialDate;
    if (date !== null && date !== undefined) {
      momentObj = moment(date);
    }
    this.state = component.newState({
      id: component.nextId(),
      focused: true,
      date: momentObj,
    });
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  componentDidMount(){
    const node = this.nodeRef && this.nodeRef.current;
    if (node) {
      let table = node.querySelector(".CalendarMonth_table");
      if (table){
        table.setAttribute("tabindex", "0");
      }
    }
  }

  onDateChange(momentObj: moment.Moment) {
    //console.log("onDateChange",momentObj);
    this.setState(state => {
      return {
        date: momentObj
      };
    });
    if (momentObj) {
      const date = momentObj.toDate();
      if (this.props.onDateChange) this.props.onDateChange(date);
    }
  }

  onFocusChange(focused) {
    this.setState(state => {
      return {
        focused: true
      };
    });
  }

  render() {
    const {id, date, focused} = this.state;
    const props: Partial<SingleDatePickerShape> = {
      keepOpenOnDateSelect: true,
      reopenPickerOnClearDate: true,
      numberOfMonths: 1,
      hideKeyboardShortcutsPanel: true,
      isRTL: Context.getInstance().uiMode.isRtl,
      isDayBlocked: () => false,
      isOutsideRange: () => false
    }
    if (props.isRTL) {
      props.anchorDirection = "right";
    }
    if (this.props.outsideRange) {
      props.isOutsideRange = this.props.outsideRange
    }
    if (this.props.dayHighlighted) {
      props.isDayHighlighted = this.props.dayHighlighted
    }
    if (this.props.onPrevMonthClick) {
      props.onPrevMonthClick = this.props.onPrevMonthClick
    }
    if (this.props.onNextMonthClick) {
      props.onNextMonthClick = this.props.onNextMonthClick
    }
    let className = "i-sdpwrapper"
    className += this.props.booking ? " booking" : ""
    className += this.props.forSchedule ? " i--schedule" : ""
    return (
      <div className={className} ref={this.nodeRef}>
        <SingleDatePicker
          {...props}
          id={id}
          date={date}
          focused={focused}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          readOnly={true}
        />
      </div>
    );
  }

}
