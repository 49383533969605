import React from "react";

import Context from "../../../../context/Context";
import OpenPlanVM from "./OpenPlanVM";
import NewPlan from "../NewPlan/NewPlan";
import * as component from "../../../../components/util/component";
import * as recentPlansUtil from "../../support/recentPlansUtil";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import {
  CalciteLabel,
  CalciteButton,
  CalciteIcon,
} from "@esri/calcite-components-react";

interface Props {
  minimal?: boolean,
  recentTimestamp?: number
}

interface State {
  newPlanOpen: boolean
  plans: any,
  working: boolean
}

export default class RecentPlans extends React.Component<Props, State> {

  asyncStamp;
  viewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = new OpenPlanVM();
    this.state = component.newState({
      newPlanOpen: false,
      plans: null,
      working: false
    });
  }

  componentDidMount () {
    this.query();
  }

  componentDidUpdate (prevProps) {
    if (this.props.recentTimestamp !== prevProps.recentTimestamp) {
      this.query();
    }
  }

  componentWillUnmount() { 
  }

  query = async () => {
    try {
      this.setState({working: true})
      const stamp = this.asyncStamp = Date.now();
      const criteria = this.viewModel.getCriteria();
      criteria.recentPlanIds = recentPlansUtil.getRecentPlanIds();
      let plans = [];
      if (criteria.recentPlanIds && criteria.recentPlanIds.length > 0) {
        const list = await this.viewModel.query(criteria) || [];
        criteria.recentPlanIds.forEach(id => {
          const found = list.find(plan => id === plan.id);
          if (found) plans.push(found);
        })
      }
      if (plans) plans = plans.slice(0,5);
      if (stamp === this.asyncStamp) {
        this.setState({plans, working: false})
      }
    } catch(ex) {
      console.error(ex)
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { plans, newPlanOpen } = this.state;
    // minimal is the list view, !minimal is the card view
    const minimal = !!this.props.minimal;
    const clsContainer = minimal ? "" : "i-plans";
    const cls = minimal ? "i-plan-cards-minimal" : "i-plan-cards";

    const items = [];
    // in open plan view, show the new plan card
    if (!minimal && Context.instance.user.canCreatePlan()) items.push(this.renderCard(null,true))
    // now render all recent plans
    plans && plans.forEach(plan => {
      items.push(this.renderCard(plan,false))
    })
    return (
      <div className={clsContainer}>
        {!minimal && <CalciteLabel>{i18n.switcher.recentPlans.caption}</CalciteLabel>}
        <div className={cls}>
          {items}
          {newPlanOpen && <NewPlan isSaveAs={false} onClose={() => this.setState({ newPlanOpen: false })} />}
        </div>
      </div>
    )
  }

  renderCard(plan,isCreateNewPlan) {
    const i18n = Context.instance.i18n;
    const minimal = !!this.props.minimal;
    let key, title, thumbnail;
    if (isCreateNewPlan) {
      key = "newplan";
      title = i18n.spaceplanner.backstage.newPlan.label;
      thumbnail = <CalciteIcon icon="plus-circle" scale="l" />
    } else {
      const planItem = plan && plan.supportService;
      //const thumbnail = planItem && planItem.thumbnailUrl;
      const thumbnailUrl = planItem && planItem.thumbnailUrl;    
      key = plan.id;
      title = plan.title;
      thumbnail = <CalciteIcon icon="floor-plan" scale="l" />
      if (thumbnailUrl) {
        thumbnail = <img alt="thumbnail" src={thumbnailUrl} />
      }
    }

    const handleClick = evt => {
      if (isCreateNewPlan) {
        this.setState({ newPlanOpen: true });
      } else {
        this.viewModel.openPlan(evt,plan)
      }
    }

    if (minimal) {
      const project = Context.instance.spaceplanner.planner.project;
      const showLastEdited = !!(project && project.isVersioned);
      const v = i18n.switcher.recentPlans.lastEditedPattern.replace("{date}",plan.lastEditedString)
      let lastEdited;
      if (showLastEdited && plan.lastEditedString) {
        lastEdited = <div className="i--secondary">{v}</div>
      }
      return (
        <CalciteButton key={key} appearance="transparent" width="full" alignment="start"
          onClick={handleClick}
        >
          <div className="i-card-minimal-content">
            <div className="i--primary">{title}</div>
            {lastEdited}
          </div>
        </CalciteButton>
      )      
    } else {
      return (
        <CalciteButton key={key} appearance="outline-fill" scale="s"
          onClick={handleClick}
        >
          <div className="i-card">
            <div className="i-card-thumbnail-container">
              <div className="i-card-thumbnail">
                {thumbnail}
              </div>
            </div>
            <div className="i-card-title">
              <span title={title}>{title}</span>
            </div>
          </div>
        </CalciteButton>
      )
    }
  }
}