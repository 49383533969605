import Context from "../context/Context";
import * as geoUtil from "./geoUtil";

export function geodesicBuffer(geometry, bufferMeters) {
    let isWebMercator, isWGS84, isGeographic;
    if(geometry && geometry.spatialReference) {
        isWebMercator = geometry.spatialReference.isWebMercator;
        isWGS84 = geometry.spatialReference.isWGS84;
        isGeographic = geometry.spatialReference.isGeographic;
    }
    if(isWebMercator || isWGS84) {
        let g = Context.instance.lib.esri.geometryEngine.geodesicBuffer(geometry,bufferMeters,"meters");
        return g;
    } else if (isGeographic) {
        const decimalDeg = bufferMeters/111111;
        let g = Context.instance.lib.esri.geometryEngine.buffer(geometry,decimalDeg);
        return g;
    } else {
        let g = Context.instance.lib.esri.geometryEngine.buffer(geometry,bufferMeters,"meters");
        return g;
    }
}

export function distance(fromGeometry,geometry,distanceUnit) {
    let distance = Context.instance.lib.esri.geometryEngine.distance(fromGeometry,geometry,distanceUnit);
    return distance;
}

export async function projectToGeographic(geometry) {
    return geoUtil.project(geometry, Context.instance.lib.esri.SpatialReference.WGS84);
} 