import Context from "../../context/Context";
import FieldNames from "../../aiim/datasets/FieldNames";
import Grid from "../components/Editor/support/Grid";
import Source from "../../aiim/base/Source";
import * as aiimUtil from "../../aiim/util/aiimUtil";
import * as queryUtil from "./queryUtil";
import { IAiimFeatureLayer, IPlanTask } from "./PlanOpener";
import { ILayerDefinition } from "@esri/arcgis-rest-feature-layer";

export function getInitialPeopleSource() {
  const aiim = Context.instance.aiim;
  const dataset = aiim && aiim.datasets && aiim.datasets.people;
  const layer: IAiimFeatureLayer = dataset && dataset.layer2D;
  const source = layer && layer.xtnAiim && layer.xtnAiim.source;
  return source;
}

export function getInitialUnitsSource() {
  const aiim = Context.instance.aiim;
  const dataset = aiim && aiim.datasets && aiim.datasets.units;
  const layer: IAiimFeatureLayer = dataset && dataset.layer2D;
  const source = layer && layer.xtnAiim && layer.xtnAiim.source;
  return source;
}

export function getDetailsLayer() {
  let plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.detailsLayer;
}

export function getFacilitiesLayer() {
  const plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.facilitiesLayer;
}

export function getGrid(): Grid {
  return Context.instance.spaceplanner.planner.grid;
}

export function getLevelsLayer() {
  const plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.levelsLayer;
}

export function getPeopleLayer(): __esri.FeatureLayer {
  const source = getPeopleSource();
  return source && source.layer2D;
}

export function getPeopleSource() {
  let plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.planPeopleSource;
}

export function getPeopleSourceKey() {
  return "People";
}

export function getReservationsLayer(): __esri.FeatureLayer {
  const datasets = Context.instance.aiim.datasets; 
  return datasets && datasets.reservations && datasets.reservations.layer2D;
}

export function getSitesLayer() {
  const plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.sitesLayer;
}

export function getSourceByKey(sourceKey: string) {
  //const aiim = Context.instance.aiim;
  //return aiim && aiim.getSource(this.getSourceKey());

  // let plan = Context.instance.spaceplanner.activePlan;
  // if (plan) {
  //   if (sourceKey === getPeopleSourceKey()) {
  //     return plan.planPeopleSource;
  //   } else if (sourceKey === getUnitsSourceKey()) {
  //     return plan.planUnitsSource;
  //   }
  // }

  if (sourceKey === getPeopleSourceKey()) {
    return getPeopleSource();
  } else if (sourceKey === getUnitsSourceKey()) {
    return getUnitsSource();
  }

}

export function getUnitsLayer(): __esri.FeatureLayer {
  const source = getUnitsSource();
  return source && source.layer2D;
}

export function getUnitsSource() {
  let plan = Context.instance.spaceplanner.activePlan;
  if (plan) return plan.planUnitsSource;
}

export function getUnitsSourceKey() {
  return "Units";
}

export function hasFields(
  layer: ILayerDefinition | __esri.FeatureLayer | __esri.Layer,
  names: string[],
  geometryType?: __esri.FeatureLayer["geometryType"] | ILayerDefinition["geometryType"]
) {
  if (!(layer && "fields" in layer)) return false;
  if (geometryType) {
    if (geometryType === "point" && layer.geometryType === "esriGeometryPoint") {
      geometryType = "esriGeometryPoint";
    }
    if (geometryType === "polyline" && layer.geometryType === "esriGeometryPolyline") {
      geometryType = "esriGeometryPolyline";
    }
    if (geometryType === "polygon" && layer.geometryType === "esriGeometryPolygon") {
      geometryType = "esriGeometryPolygon";
    }
  }
  if (geometryType && geometryType !== layer.geometryType) return false;
  return names.every(name => {
    return !!aiimUtil.findField(layer.fields,name);
  });
}

export function hasAreaRolesFields(table: ILayerDefinition) {
  const names = ["area_id","email","role"];
  return hasFields(table,names);
}

export function hasAreasFields(table: ILayerDefinition) {
  const names = ["area_id","area_name","area_type"];
  return hasFields(table,names);
}

export function hasCommentsFields(layer: ILayerDefinition) {
  const names = ["comment","plan_id","parent_cid"];
  return hasFields(layer,names,"point");
}

export function hasDetailsFields(layer: ILayerDefinition | __esri.FeatureLayer) {
  const names = ["detail_id","level_id","use_type"];
  return hasFields(layer,names,"polyline");
}

export function hasPaletteFields(layer: ILayerDefinition, forSpaceplanner?: boolean) {
  const names = ["name","description","feature_type","detail_use_type","primary_anchor_x","primary_anchor_y","secondary_anchor_x","secondary_anchor_y"];
  return hasFields(layer,names,"polyline");
}

export function hasPeopleFields(layer: ILayerDefinition | __esri.FeatureLayer, forSpaceplanner?: boolean) {
  // "area_id","unit_id","level_id","knownas","email",
  // "unit_name","org_level_1","org_level_2","job_title","start_date"
  const names = ["area_id","unit_id","level_id","knownas","email"];
  //if (hasFields(layer,names,"point")) console.log("hasPeopleFields",layer)
  return hasFields(layer,names,"point");
}

export function hasUnitsFields(layer: ILayerDefinition | __esri.FeatureLayer, forSpaceplanner?: boolean) {
  // "area_id","unit_id","level_id","assignment_type"
  // "area_gross","capacity","schedule_email","use_type"
  const names = ["area_id","unit_id","level_id","assignment_type"];
  //if (hasFields(layer,names,"polygon")) console.log("hasUnitsFields",layer)
  return hasFields(layer,names,"polygon");
}

export function hasReservationFields(layer: ILayerDefinition | __esri.FeatureLayer | __esri.Layer, forSpaceplanner?: boolean) {
  const names = ["UNIT_ID", "LEVEL_ID", "START_TIME", "END_TIME", "CHECK_IN_TIME", "CHECK_OUT_TIME","RESERVED_FOR_FULL_NAME","RESERVED_FOR_USERNAME"];
  return hasFields(layer,names,"polygon");
}

export function makePlanSources(task: IPlanTask) {
  const promises = [];
  const peopleLayer = task.plan.peopleLayer;
  const unitsLayer = task.plan.unitsLayer;
  if (peopleLayer) {
    let layer = peopleLayer;
    task.plan.peopleSource = new Source({
      key: getPeopleSourceKey(),
      name: layer.title,
      url: layer.url+"/"+layer.layerId,
      layer2D: layer
    });
    let v = FieldNames.PEOPLE_FULLNAME;
    if (layer && aiimUtil.findField(layer.fields,v)) {
      task.plan.peopleSource.displayField = v;
    }
    layer.xtnAiim = {
      isPlanLayer: true,
      requiresFacilityMode: true,
      source: task.plan.peopleSource
    };
    promises.push(task.plan.peopleSource.checkSchema());
  }
  if (unitsLayer) {
    let layer = unitsLayer;
    task.plan.unitsSource = new Source({
      key: getUnitsSourceKey(),
      name: layer.title,
      url: layer.url+"/"+layer.layerId,
      layer2D: layer
    });
    layer.xtnAiim = {
      isPlanLayer: true,
      requiresFacilityMode: true,
      source: task.plan.unitsSource
    };
    promises.push(task.plan.unitsSource.checkSchema());
  }
  return Promise.all(promises);
}

export function queryLastEditDate(source: Source){
  const layer = source.layer2D;
  const promise=new Promise<void>((resolve,reject) => {
  if(layer && layer.editFieldsInfo){
    const modifiedDate=layer.editFieldsInfo.editDateField;
    const fields = source.layer2D.fields;
    if(modifiedDate){
      const dateField = aiimUtil.findField(fields,modifiedDate);
      const maxDateStat = {
        onStatisticField: dateField.name,
        outStatisticFieldName: "maxDate",
        statisticType: "max"
      };
      const maxDateStatOptions = {
        source: source,
        outStatistics: maxDateStat,
        where: "1=1"
      };
      queryUtil.queryStats(maxDateStatOptions).then(result=>{
        const maxDate=result.features[0].attributes.maxDate;
        resolve(maxDate);
      }).catch(error=>{
        reject(error);
      })
    }else{
      resolve();
    }
  }else{
    resolve();
  }
})
return promise;
}
