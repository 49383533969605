import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: ${props => {
    if (props.cfgOn) return "flex-start";
    return "center";
  }};
  justify-content: center;
  width: 100%;
  height: calc(100% - 51px);
`;

export { Container };
