// aiim.util
import { getAttributeValue } from "../../../../../../aiim/util/aiimUtil";

// context
import Context from "../../../../../../context/Context";
import Topic from "../../../../../../context/Topic";

// util
import BaseClass from "../../../../../../util/BaseClass";
import { makeHotelMarkerSymbol } from "../../../../../../util/symbolUtil";


export default class HotelPinsLayer extends BaseClass {

  //--------------------------------------------------------------------------
  //
  //  Private Variables
  //
  //--------------------------------------------------------------------------
  _layer;
  _layerId = "hotel-pins-layer";
  _title = "Hotel Pins";
  _activeLevels = [];
  _unitFeatures = [];
  _active = false;
  _activeUnitInfoPanel = null;

  //--------------------------------------------------------------------------
  //
  //  Lifecycle
  //
  //--------------------------------------------------------------------------

  static instance;

  constructor() {
    super();
    this._init();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new HotelPinsLayer();
    }
    return this.instance;
  }

  _init() {
    this._layer = this._getGraphicsLayer();
    const views = Context.getInstance().views;
    const floorFilter = views && views.floorFilter;
    const activeWidget = floorFilter && floorFilter.activeWidget;
    const activeWidgetLevel =  activeWidget && activeWidget.level;
    this.activate();
    this._updateLevels(activeWidgetLevel);

    // Watch handles
    this.own([
      Topic.subscribe(Topic.FacilityModeUpdated, (params) => {
        const { facilityMode } = params;
        const activeFacilityInfo = facilityMode && facilityMode.activeFacilityInfo;
        const activeLevel = activeFacilityInfo && activeFacilityInfo.activeLevel;
        const levelId = activeLevel && activeLevel.levelId;
        // Grab the updated level id and update the pins
        this._updateLevels(levelId);
      }),

      Topic.subscribe(Topic.ShowSearchResult, (params) => {
        const unitFeature = params && params.searchResult && params.searchResult.feature;
        this._activeUnitInfoPanel = unitFeature;
      }),

      Topic.subscribe(Topic.HideHotelGraphic, (params) => {
        const unitFeature = params && params.searchResult && params.searchResult.feature;
        this._activeUnitInfoPanel = unitFeature;
      }),

      Topic.subscribe(Topic.InfoPanelClosed, () => {
        this._activeUnitInfoPanel = null;
        this.dropUnitPins(this._unitFeatures);
      }),

      Topic.subscribe(Topic.ViewsReloaded, () => {
        this._layer = this._getGraphicsLayer();
      })

    ]);
  }

  //--------------------------------------------------------------------------
  //
  //  Public Methods
  //
  //--------------------------------------------------------------------------

  /**
   * Activate the graphics layer
   */
   activate() {
    this._active = true;
  }

  /**
   * Deactivate the graphics layer
   */
  deactivate() {
    this._active = false;
  }
  
  /**
   * Given available hoteling unit features, drop pins on their 
   * centroids if layer is activated
   * @param {Object[]} unitFeatures 
   */
  dropUnitPins(unitFeatures) {
    if (!this._active) {
      return;
    }

    this._unitFeatures = unitFeatures;
    this.clearUnitPins();
    const context = Context.getInstance();
    const lib = context.lib;
    const gEngine = lib.esri.geometryEngine;

    const units = context.aiim.datasets.units;
    const levelIdField = context.aiim.getLevelIdField(units.layer2D);

    const graphics = [];
  
    unitFeatures.forEach((unit) => {
      const activeUnitGeometry = this._activeUnitInfoPanel && this._activeUnitInfoPanel.geometry;
      const geometry = unit.geometry;
      const centroid = geometry && geometry.centroid;
      const fieldName = levelIdField && levelIdField.name;
      const levelId = getAttributeValue(unit.attributes, fieldName);
      
      if (
        this._layer && 
        centroid && 
        this._activeLevels.includes(levelId) &&
        !gEngine.equals(activeUnitGeometry, geometry)
      ) {
        graphics.push(
          new lib.esri.Graphic({
            geometry: centroid,
            symbol: makeHotelMarkerSymbol()
          })
        );
      }
    });

    if (graphics.length > 0) {
      this._layer.graphics.addMany(graphics);
    }

  }
  
  /**
   * Clear all pin graphics in the layer
   */
  clearUnitPins() {
    if (this._layer) {
      this._layer.graphics.removeAll();
    }
  }


  //--------------------------------------------------------------------------
  //
  //  Private Methods
  //
  //--------------------------------------------------------------------------

  _updateLevels(levelId) {
    const levels = Context.getInstance().aiim.datasets.levels;
    const v0Levels = levels.getZeroVOLevelIds();
    const activeLevelData = levelId && levels.getLevelData(levelId);
    const activeFacilityId = activeLevelData && activeLevelData.facilityId;

    const activeLevels = levelId ? [levelId] : [];
    v0Levels.forEach((level) => {
      const levelData = levels.getLevelData(level);
      if (!activeFacilityId || levelData.facilityId !== activeFacilityId) {
        activeLevels.push(level);
      }
    });

    this._activeLevels = activeLevels;
    this.dropUnitPins(this._unitFeatures);
  }

  _getGraphicsLayer() {
    const lib = Context.getInstance().lib;
    const view = Context.getInstance().views.activeView;
  
    let pinsLayer = view.map.findLayerById(this._layerId);
    if (!pinsLayer) {
      pinsLayer = new lib.esri.GraphicsLayer({
        id: this._layerId,
        title: this._title,
        listMode: "hide"
      });
      view.map.add(pinsLayer);
    }
    return pinsLayer;
  }
}