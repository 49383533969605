import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan, { SpaceAssignmentTypes } from "../OfficePlan";
import QueryAll from "../QueryAll";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as queryUtil from "../queryUtil";
import * as selectionUtil from "../../../aiim/util/selectionUtil";
import * as sourceUtil from "../sourceUtil";
import { IFeature } from "@esri/arcgis-rest-types";
import { AreaTypes } from "../../../util/interfaces";
import type Source from "../../../aiim/base/Source";

export const unitBasedFieldsForPeople = [
  "site_id","site_name",
  "facility_id","facility_name",
  "level_id","level_name","level_number","vertical_order",
  "unit_id","unit_name",
  "section_id","section_name",
  "access_type","use_type",
  "capacity","utilization",
  "area_gross","area_net","area_um",
  "elevation_absolute","elevation_relative",
  "height_absolute","height_relative",
  "name_long","name_subtitle","description"
];
// "image_url","orig_fid",
// "contact_email","contact_extension","contact_name","contact_phone","contact_url",
// "source_name","source_path","source_type","source_method",

export function addAttributeEdit(fields,attributes,name,value) {

  // disable utilization update until 8.4
  const disableUtilizationUpdate = true;
  if (disableUtilizationUpdate) {
    if (typeof name === "string" && (name.toLowerCase() === "utilization")) {
      return;
    }
  }
  const f = aiimUtil.findField(fields,name);
  if (f) attributes[f.name] = value;
}

export function appendPersonUnassignment(task,peopleFields,peopleUpdate) {
  addAttributeEdit(peopleFields,peopleUpdate.attributes,
    FieldNames.PEOPLE_AREA_ID,null);
  unitBasedFieldsForPeople.forEach(f => {
    if(f === FieldNames.SITE_ID || f === FieldNames.SITE_NAME) {}
    else{ addAttributeEdit(peopleFields,peopleUpdate.attributes,f,null);}
  });
  peopleUpdate.geometry = makeEmptyPoint(task);
}

export function appendUnitUnassignment(task,unitFields,unitUpdate) {
  addAttributeEdit(unitFields,unitUpdate.attributes,
    FieldNames.UNITS_AREA_ID,null);
  addAttributeEdit(unitFields,unitUpdate.attributes,
    FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,
    OfficePlan.SpaceAssignmentTypes.none);
  addAttributeEdit(unitFields,unitUpdate.attributes,
    FieldNames.UTILIZATION, 0)
}
export function cloneFeature(feature: IFeature | __esri.Graphic, geometryOption?: string): IFeature {
  const cloned: IFeature = {
    attributes: Object.assign({},feature.attributes)
  };
  if (feature.geometry && (geometryOption !== "no-geometry")) {
    if ("toJSON" in feature.geometry && typeof feature.geometry.toJSON === "function") {
      cloned.geometry = feature.geometry.toJSON();
    } else {
      cloned.geometry = feature.geometry;
    }
  }
  return cloned;
}
// TODO: Remove unused `task` argument
/** @deprecated Use `cloneFeature` instead. */
export function cloneDetailFeature(task, feature: IFeature | __esri.Graphic, geometryOption?: string): IFeature {
  return cloneFeature(feature, geometryOption);
}

export function clonePersonFeature(task,feature: IFeature | __esri.Graphic, geometryOption?: string): IFeature {
  const cloned: IFeature = {
    attributes: Object.assign({}, feature.attributes)
  };
  if (feature.geometry) {
    if ("toJSON" in feature.geometry && typeof feature.geometry.toJSON === "function") {
      cloned.geometry = feature.geometry.toJSON()
    } else {
      cloned.geometry = feature.geometry;
    }
  } else if (geometryOption === "ensure-geometry") {
    // if (!isTaskVersioned(task)) {
      cloned.geometry = makeEmptyPoint(task);
    // } else {
    //   throw new Error("Person feature has no geometry",feature);
    // }
  }
  //console.log("cloned",cloned);
  return cloned;
}

// TODO: Remove unused `task` argument
/** @deprecated Use `cloneFeature` instead. */
export function cloneUnitFeature(task, feature: IFeature | __esri.Graphic, geometryOption?: string): IFeature {
  return cloneFeature(feature, geometryOption);
}

export function fetchGlobalIds(
  source: Source,
  unitOrAreaIds: string[],
  areaType: AreaTypes
): Promise<{ [gid: string]: number | Omit<SpaceAssignmentTypes, "home"> }> {
  if (!unitOrAreaIds || unitOrAreaIds.length === 0) return Promise.resolve({});
  const lib = Context.instance.lib;
  const layer = source.layer2D;
  const fields = layer.fields;
  const globalIdField = layer.globalIdField;

  let idField: __esri.Field, asnTypeField: __esri.Field;
  if (!areaType) {
    idField = aiimUtil.findField(fields,FieldNames.UNIT_ID);
  } else {
    if (source.isAiimPeople()) {
      idField = aiimUtil.findField(fields,FieldNames.PEOPLE_AREA_ID);
    } else if (source.isAiimUnits()) {
      idField = aiimUtil.findField(fields, FieldNames.UNITS_AREA_ID);
      asnTypeField = aiimUtil.findField(fields, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
    }
  }
  const values = [];
  unitOrAreaIds.forEach(id => {
    let v = "'" + selectionUtil.escSqlQuote(id) + "'";
    values.push(v);
  });
  let where = idField.name + " IN (" + values.join(",") + ")";

  const url = Context.checkMixedContent(source.url);
  const query = new lib.esri.Query();
  query.where = where;
  query.outFields = [globalIdField];
  if (asnTypeField) {
    query.outFields.push(asnTypeField.name);
  }
  query.returnGeometry = false;
  queryUtil.applyGdbVersion(source,query);

  const globalIdIndex: { [gid: string]: number | Omit<SpaceAssignmentTypes, "home"> } = {};

  const qaopts = {
    layer: layer,
    perFeatureCallback: feature => {
      const gid = feature.attributes[globalIdField];
      globalIdIndex[gid] = 1;
      if (asnTypeField) {
        const asnType = feature.attributes[asnTypeField.name];
        globalIdIndex[gid] = asnType;
      }
    }
  };

  const qa = new QueryAll();
  return qa.execute(url, query, qaopts).then(result => {
    return globalIdIndex;
  });
}

export function fetchRelatedPeople(task,unitIds) {
  const fetchResult = {
    objectIds: [],
    globalIds: [],
    features: null
  };
  if (!unitIds || unitIds.length === 0) return Promise.resolve(fetchResult);
  const promise = new Promise((resolve,reject) => {
    const lib = Context.instance.lib;
    const source = sourceUtil.getPeopleSource();
    const layer = source.layer2D;
    const fields = layer.fields;
    const objectIdField = layer.objectIdField;
    const globalIdField = layer.globalIdField;
    const unitIdField = aiimUtil.findField(fields,FieldNames.PEOPLE_UNIT_ID).name;

    const values = [];
    unitIds.forEach(unitId => {
      let v = "'" + selectionUtil.escSqlQuote(unitId) + "'";
      values.push(v);
    });
    let where = unitIdField + " IN (" + values.join(",") + ")";

    const url = Context.checkMixedContent(source.url);
    const query = new lib.esri.Query();
    query.where = where;
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.returnZ = true;
    queryUtil.applyGdbVersion(source,query);

    const qaopts = {layer: layer};
    const qa = new QueryAll();
    qa.execute(url,query,qaopts).then(result => {
      console.log("fetchRelatedPeople",result)
      if (result && result.features) {
        fetchResult.features = result.features;
        result.features.forEach(feature => {
          let oid = aiimUtil.getAttributeValue(feature.attributes,objectIdField);
          fetchResult.objectIds.push(oid);
          let gid = aiimUtil.getAttributeValue(feature.attributes,globalIdField);
          fetchResult.globalIds.push(gid);
        });
      };
      resolve(fetchResult);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

export function fetchRelatedUnits(task,unitIds) {
  const fetchResult = {
    objectIds: [],
    globalIds: [],
    unitIdsByObjectId: {},
    unitIdsByGlobalId: {},
    features: null,
    featuresByObjectId: {}
  }
  if (!unitIds || unitIds.length === 0) return Promise.resolve(fetchResult);
  const promise = new Promise((resolve,reject) => {
    const lib = Context.instance.lib;
    const source = sourceUtil.getUnitsSource();
    const layer = source.layer2D;

    const fields = layer.fields;
    const objectIdField = layer.objectIdField;
    const globalIdField = layer.globalIdField;
    const unitIdField = aiimUtil.findField(fields,FieldNames.UNIT_ID).name;
    const areaIdField = aiimUtil.findField(fields,FieldNames.UNITS_AREA_ID).name;
    const asnTypeField = aiimUtil.findField(fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE).name;
    //const utilField = aiimUtil.findField(fields,FieldNames.UTILIZATION).name;

    const values = [];
    unitIds.forEach(unitId => {
      let v = "'" + selectionUtil.escSqlQuote(unitId) + "'";
      values.push(v);
    });
    let where = unitIdField + " IN (" + values.join(",") + ")";

    const url = Context.checkMixedContent(source.url);
    const query = new lib.esri.Query();
    query.where = where;
    //query.outFields = [objectIdField,globalIdField,unitIdField,areaIdField,asnTypeField,utilField];
    query.outFields = [objectIdField,globalIdField,unitIdField,areaIdField,asnTypeField];
    query.returnGeometry = false;
    queryUtil.applyGdbVersion(source,query);

    const qaopts = {layer: layer};
    const qa = new QueryAll();
    qa.execute(url,query,qaopts).then(result => {
      console.log("fetchRelatedUnits",unitIds,result)
      if (result && result.features) {
        fetchResult.features = result.features;
        result.features.forEach(feature => {
          let oid = aiimUtil.getAttributeValue(feature.attributes,objectIdField);
          let gid = aiimUtil.getAttributeValue(feature.attributes,globalIdField);
          let unitId = aiimUtil.getAttributeValue(feature.attributes,unitIdField);
          fetchResult.globalIds.push(gid);
          fetchResult.unitIdsByGlobalId[gid] = unitId;

          fetchResult.objectIds.push(oid);
          fetchResult.featuresByObjectId[oid] = feature;
          fetchResult.unitIdsByObjectId[oid] = unitId;
        });
      };
      resolve(fetchResult);
    }).catch(ex => {
      reject(ex);
    });
  });
  return promise;
}

export function fetchUtilization(unitIds){
  let fetchResult = {
    unitObjectIds: [],
    utilizationByObjectId: {},
    utilizationByUnitId: {},
  };
  if (!unitIds || unitIds.length === 0) return Promise.resolve(fetchResult);
  const promise = new Promise((resolve, reject)=>{
    const peopleSource = sourceUtil.getPeopleSource();
    const peopleLayer = peopleSource.layer2D;
    const unitIdField = aiimUtil.findField(peopleLayer.fields,FieldNames.UNIT_ID);
    const lib = Context.instance.lib;
    const values = [];

    let unitObjectIdDict = {};
    unitIds.forEach(unitId => {
      let v = "'" + selectionUtil.escSqlQuote(unitId) + "'";
      values.push(v);
    });

    const featureLayerUnits= sourceUtil.getUnitsSource().layer2D;
    const ObjectID=featureLayerUnits.objectIdField;
    var query = featureLayerUnits.createQuery();
    query.outFields = [unitIdField.name, ObjectID];
    query.where = unitIdField.name + " IN (" + values.join(",") + ")";

    featureLayerUnits.queryFeatures(query).then(response => {
      for (let i = 0; i < response.features.length; i++) {
        let objectId = response.features[i].attributes[ObjectID];
        fetchResult.unitObjectIds.push(objectId);
        unitObjectIdDict[response.features[i].attributes[unitIdField.name]] = objectId;
      }
    }).then(()=>{
        let queryPeopleLayer = peopleLayer.createQuery();
        let where = unitIdField.name + " IN (" + values.join(",") + ")";
        const staticDefine = new lib.esri.StatisticDefinition({
          statisticType: "count",
          onStatisticField: unitIdField.name,
          outStatisticFieldName: "Utilization"
        })
        queryPeopleLayer.where = where;
        queryPeopleLayer.outStatistics = [staticDefine];
        queryPeopleLayer.groupByFieldsForStatistics = [unitIdField.name];
        queryPeopleLayer.outFields = ["*"];

        return (
          peopleLayer.queryFeatures(queryPeopleLayer).then(function(results){
            for(let i=0;i<results.features.length;i++){
              let unitId= results.features[i].attributes[unitIdField.name];
              let oid = unitObjectIdDict[unitId];
              const utilization = aiimUtil.getAttributeValue(results.features[i].attributes,"Utilization");
              fetchResult.utilizationByObjectId[oid] = utilization;
              fetchResult.utilizationByUnitId[unitId] = utilization;
            }
          })
        )
    }).then(() => {
      resolve(fetchResult);
    }).catch(ex => {
      reject(ex);
    })
  })
  return promise;
}

export function isTaskVersioned(task) {
  if (task.isVersioned === undefined) {
    const p = Context.instance.spaceplanner.planner;
    if (p && p.project) {
      task.isVersioned = !!p.project.isVersioned;
    }
  }
  return task.isVersioned;
}

export function makeEmptyPoint(task) {
  const pt = new Context.instance.lib.esri.Point({wkid: 4326});
  pt.x = pt.y = null;
  if (isTaskVersioned(task)) {
    // TODO?
    pt.x = pt.y = 0; pt.z = 0;
  }
  return pt;
}

export function makeUnassignPeopleUpdates(task,keys,keyField,ignoreKeys) {
  const fields = sourceUtil.getPeopleSource().layer2D.fields;
  const updates = [];
  if (Array.isArray(keys)) {
    keys.forEach(key => {
      if (ignoreKeys && ignoreKeys.indexOf(key) !== -1) return;
      const update = {attributes: {}};
      update.attributes[keyField] = key;
      appendPersonUnassignment(task,fields,update);
      updates.push(update);
    });
  }
  return updates;
}

export async function checkUniqueIds(uids, layer, fieldName) {
  fieldName = aiimUtil.findFieldName(layer.fields, fieldName); 
  const query = new Context.instance.lib.esri.Query();
  query.where = `${fieldName} in ('${uids.join("','")}')`; 
  query.outFields = [fieldName];
  query.returnGeometry = false;
  if (layer.gdbVersion)
    query.gdbVersion = layer.gdbVersion;  
  const fs = await layer.queryFeatures(query);
  let foundConflictIds = []; 
  if (fs && fs.features)
    foundConflictIds = fs.features.map(f => f.attributes[fieldName]);
  return foundConflictIds;
}