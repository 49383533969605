import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import * as component from "../../util/component";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name"
};

export default class PrintLayout extends React.Component {

  constructor(props) {
    super(props);
    const layout = this.props.cfg.getConfigurable("print").layout;
    this.state = component.newState({
      layout: layout
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  onChangeLayout = (evt) => {
    const cfg = this.props.cfg;
    const v = evt.target.value;
    this.setState(state => {
      return {
        layout: v
      }
    });
    cfg.getConfigurable("print").layout = v;
  }

  render() {
    const i18n = Context.instance.i18n;
    const layout = this.state.layout;

    return (
      <div className={CSS.section}>
        <label id={"cfg-printLayout-label"} htmlFor={"cfg-printLayout"}>
          <div className={CSS.formGroupHeader}>
            <span className={CSS.formGroupName}
              >{i18n.configurator.print.layout.label}</span>
            <HelpTip text={i18n.configurator.print.layout.help}/>
          </div>
          <select id={"cfg-printLayout"} value={layout}
            onChange={this.onChangeLayout}>
            <option key={"map-only"} value={"map-only"}
              >{i18n.configurator.print.layout.mapOnly}</option>
            <option key={"letter"} value={"letter"}
              >{i18n.configurator.print.layout.letter}</option>
            <option key={"a4"} value={"a4"}
              >{i18n.configurator.print.layout.a4}</option>
          </select>
        </label>
      </div>
    );
  }

}
