import BaseClass from "../util/BaseClass";
import ConfigLoader from "./ConfigLoader";
import Context from "./Context";
import * as appLaunchUtil from "../aiim/util/appLaunchUtil";
import * as portalUtil from "../util/portalUtil";
import * as val from "../util/val";
import { IItem, IItemAdd } from "@esri/arcgis-rest-types";
import { ReservationMethod } from "../util/interfaces";

export interface IUrlQueryParameters {
  appid: string,
  configure?: string | boolean,
  edit?: string | boolean,
  webmap?: string,
  webscene?: string
}
export interface IUrlObject {
  path: string,
  query: IUrlQueryParameters
}
export interface ILaunchAction {
  actionId: string,
  label: string,
  url: string,
  layers: string[],
  categories: string[]
}
export interface IIndoorsAppData {
  values: IConfigurables
}
export interface IAppInfo {
  id: string,
  item: IItem,
  data: IIndoorsAppData,
  canEdit: boolean,
  startInConfiguratorMode: boolean
}
export interface IAppInfoTask {
  appid: string,
  appItem?: IItem,
  appData?: IIndoorsAppData,
  urlBased?: IUrlQueryParameters,
  startInConfiguratorMode?: boolean
}
export interface IConfigurationCategory {
  appTypes?: ("indoors" | "indoors-spaceplanner" | "indoors-floorplaneditor")[],
  name: string,
  uniqueIdField: string,
  displayField?: string
}
export interface IItemAddRequest extends Omit<IItemAdd, "typeKeywords" | "tags"> {
  thumbnailUrl?: string,
  typeKeywords?: string | string[],
  tags?: string | string[]
}
export interface IConfiguration {
  portalUrl: string,
  oauthAppId: string,
  appType: "indoors-spaceplanner" | "indoors" | "indoors-floorplaneditor",
  spaceplannerVersion: number | null,
  configuredAppId: string,
  proxyUrl: string,
  appLaunchHelpUrl: string,
  allowAnonymousAccess: boolean,
  defaultVerticalOrder: number,
  graphicElevationOffset: number,
  maxFavoritePlaces: number,
  maxRecentPlaces: number,
  minScaleFacilityLabels: number,
  proximityMaxItemsToSort: number,
  proximityMetersPerLevel: number,
  setLevelFromHome: boolean,
  setLevelOnStart: boolean,
  showAllFloorPlans2D: boolean,
  facilityOutlineColor2D: string,
  highlightFacilityOnHover2D: boolean,
  categories: {
    fill: {
      Events: IConfigurationCategory,
      People: IConfigurationCategory,
      Units: IConfigurationCategory,
      Levels: IConfigurationCategory,
      Facilities: IConfigurationCategory,
      [otherCategories: string]: IConfigurationCategory
    }
  },
  [otherConfigProps: string]: any,
  webmap?: string
}
export interface IConfigurables {
  webmap: string,
  webscene: string,
  networkServiceUrl: string,
  closestFacilityServiceUrl: string,
  routeService: {
    itemId: string,
    layerName: string
  },
  closestFacilityService: {
    itemId: string,
    layerName: string
  },
  scalebar: {
    show: boolean,
    style: "ruler" | "line",
    units: "non-metric" | "metric" | "dual"
  },
  print: {
    layout: string
  },
  workspaceReservation: {
    appId: string,
    isSingleTenant: boolean,
    tenantId: string,
    reservationLayerId: string,
    reservationLabelHotel: string,
    reservationLabelMeeting: string,
    reservationTypeHotel: ReservationMethod,
    reservationTypeMeeting: ReservationMethod,
    enableMeeting: boolean,
    enableHotel: boolean,
    enableMeetingLabel: boolean,
    allowMeetingCheckIn?: boolean,
    enableHotelLabel: boolean,
    version: number, 
    enableGoogleCalendar: boolean,
    enableOutlookCalendar: boolean,
    // following properties are added for backward compatibility
    enabled?: boolean,
    reservationType?: ReservationMethod,
    enableOfficeHoteling?: boolean,
    enableMeetingRooms?: boolean,
    enableOfficeHotelingO365?: boolean,
    enableMeetingRoomsO365?: boolean
  },
  officeHoteling?: {
    enabled: boolean,
    appId: string,
    isSingleTenant: boolean
  }
  aboutSection: {
    enabled: boolean,
    htmlElement: HTMLElement | null
  },
  measure: {
    enabled: boolean
  },
  nativeApp: {
    showIOS: boolean
  },
  indoorPositioning: {
    iOS: boolean
  },
  appLaunch: {
    actions: ILaunchAction[],
    removedWebmapActions?: ILaunchAction[]
  },
  applicationReset: {
    enabled: boolean,
    timeoutMillis: number,
    popupMillis: number
  },
  kiosk: {
    kioskReset: {
      enabled: boolean,
      timeoutMillis: number,
      popupMillis: number
    },
    kioskQRCode: {
      enabled: boolean
    },
    kioskVirtualKeyboard: {
      enabled: boolean,
      size: "small" | "medium" | "large"
    }
  },
  logo: {
    url: string,
    name: string,
    choice: "default" | "portal" | "custom",
    size: string
  },
  theme: {
    "theme-color-brand": string,
    "theme-color-brand-highlight": string,
    "theme-color-brand-text": string,
    "theme-color-background": string,
    "theme-color-highlight": string,
    "theme-color-text": string,
    "theme-color-text-secondary": string,
    "theme-color-link": string,
    "theme-color-button": string,
    "theme-color-button-highlight": string,
    "theme-color-button-text": string,
    "theme-color-accent": string,
    "theme-color-border": string
  },
  spaceplanner: {
    mergepermission: {
      restrictUsers: boolean,
      groupId: string,
      groupTitle: string
    },
    filterColumns: {
      // allOccupantsInfo:{},
      // unassignedOccupantsInfo:{},
      // allUnitsInfo:{},
      // unassignedUnitsInfo:{}
    },
    unitName: string,
    palette: {
      itemId: string
    },
    wallTypeValues: string[],
    workspaceAreas: {
      hotDesks: {
        enabled: boolean
      },
      hotels: {
        enabled: boolean,
        reservationMethod: ReservationMethod
      },
      meetingRooms: {
        enabled: boolean,
        reservationMethod: ReservationMethod
      }
    }
  },
  _311Url?: string
}

export default class Configuration extends BaseClass {

  appid: string = null;
  appItem: IItem = null;
  appData: IIndoorsAppData = null;

  indoorsAppSource: string = "b5b89c1c310b4b36807b68f021d2b651"; // TODO?
  // TODO online indoors-spaceplanner source: "a70ae1a6f72c465680d4c805fa098ca2"
  indoorsTag = "ArcGIS Indoors";

  srcInfo = {
    appDataHadAppLaunch: false,
    appDataHad311: false,
    appLaunchFromIndoorsConfig: false
  };

  // "_311Url": null,
  // put inside a sep spaceplanner sectn, grpids

  _configurables: IConfigurables = {
    "webmap": null,
    "webscene": null,
    "networkServiceUrl": null, // for backward compatibility
    "closestFacilityServiceUrl": null, // for backward compatibility
    "routeService": {
      "itemId": null,
      "layerName": null
    },
    "closestFacilityService": {
      "itemId": null,
      "layerName": null
    },
    "scalebar": {
      "show": false,
      "style": "line",
      "units":"dual"
    },
    "print": {
      "layout": "map-only"
    },
    "workspaceReservation": {
      "appId": null,
      "isSingleTenant": false,
      "tenantId": null,
      "reservationLabelHotel": null,
      "reservationLabelMeeting": null,
      "reservationTypeHotel": null,
      "reservationTypeMeeting": null,
      "enableMeeting": false,
      "enableHotel": false,
      "enableMeetingLabel": false,
      "allowMeetingCheckIn": false,
      "enableHotelLabel": false,
      "reservationLayerId": null,
      "enableOfficeHoteling": false,
      "enableMeetingRooms": false,
      "enableOfficeHotelingO365": false,
      "enableMeetingRoomsO365": false,
      "enableGoogleCalendar": false,
      "enableOutlookCalendar": false,
      "version": 3
    },
    "aboutSection": {
      "enabled": false,
      "htmlElement": null
    },
    "measure": {
      "enabled": false
    },
    "nativeApp": {
      "showIOS": true
    },
    "indoorPositioning": {
      "iOS": false
    },
    "appLaunch": {
      "actions": [],
      "removedWebmapActions": []
    },
    "applicationReset": {
      "enabled": false,
      "timeoutMillis": 900000,
      "popupMillis": 60000
    },
    "kiosk": {
      "kioskReset": {
        "enabled": true,
        "timeoutMillis": 300000,
        "popupMillis": 30000
      },
      "kioskQRCode":{
        "enabled": true
      },
      "kioskVirtualKeyboard":{
        "enabled": false,
        "size": "medium"
      }
    },
    "logo": {
      "url": "assets/indoors-logo.svg",
      "name": "ArcGIS Indoors",
      "choice": "default",
      "size": "2.5"
    },
    "theme": {
      "theme-color-brand": "#0079c1",
      "theme-color-brand-highlight": "#056398",
      "theme-color-brand-text": "#ffffff",
      "theme-color-background": "#ffffff",
      "theme-color-highlight": "#efefef",
      "theme-color-text": "#4c4c4c",
      "theme-color-text-secondary":"#6e6e6e",
      "theme-color-link": "#0079c1",
      "theme-color-button": "#0079c1",
      "theme-color-button-highlight": "#005e95",
      "theme-color-button-text": "#ffffff",
      "theme-color-accent": "#0079c1",
      "theme-color-border": "#dfdfdf"
    },
    "spaceplanner": {
      "mergepermission": {
        restrictUsers : false,
        groupId : null,
        groupTitle : null
      },
      "filterColumns": {
        // allOccupantsInfo:{},
        // unassignedOccupantsInfo:{},
        // allUnitsInfo:{},
        // unassignedUnitsInfo:{}
      },
      "unitName": null,
      "palette": {
        "itemId": null
      },
      "wallTypeValues": null,
      "workspaceAreas": {
        "hotDesks": {
          "enabled": true
        },
        "hotels": {
          "enabled": true,
          "reservationMethod": "esri"
        },
        "meetingRooms": {
          "enabled": true,
          "reservationMethod": "esri"
        }
      }
    }

  };

  _local = {
    "allowNonAiimIdentify": true
  };

  /*
  "additionalSources": [
    {
      "identifier": {
        "dataLayerType": "ServiceNow"
      },
      "mappings": {
        "uniqueIdField": "sys_id",
        "displayField": "number",
        "facilityNameField": "location_facility_name",
        "levelNameField": "location_level_name"
      },
      "workOrderMappings": {
        "idField": "number",
        "titleField": "short_description",
        "priorityField": "priority_label",
        "statusField": "state_label",
        "createdOnField": "sys_created_on",
        "createdByField": "caller_id_name",
        "assignedToField": "assigned_to_name"
      }
    },
    {
      "identifier": {
        "dataLayerType": "ServiceNowBeta",
        "__disabled__startsWith": "ServiceNow",
        "__disabled__description": ["ServiceNow Incidents","ServiceNow Requests"]
      },
      "canDuplicate": true,
      "uniqueIdField": "sys_id",
      "displayField": "short_description",
      "subTitleField": null,
      "categoryLevel" : 1,
      "iconUrl": "assets/workorder.png",
      "mappings": {
        "facilityNameField": "location_facility_name",
        "levelNameField": "location_level_name"
      },
      "sortOptions": [
        {"field": "distance", "label": "Distance"},
        {"field": "short_description", "label": "Title"},
        {"field": "sys_created_on", "label": "Created On"},
        {"field": "priority", "label": "Priority"}
      ],
      "itemCard": {
        "popupTemplate": {
          "title": "{number} <span class='{expression/priorityClass}'>{priority_label}</span>",
          "content": [{
            "type": "fields",
            "fieldInfos": [
              {
                "fieldName": "expression/timeAgo",
                "label": "",
                "visible": true,
                "stringFieldOption": "text-box"
              },
              {
                "fieldName": "state_label",
                "label": "<span class='esri-icon-description'></span>",
                "visible": true,
                "stringFieldOption": "text-box"
              },
              {
                "fieldName": "assigned_to_name",
                "label": "<span class='esri-icon-user'></span>",
                "visible": true,
                "stringFieldOption": "text-box"
              }
            ]
          }],
          "expressionInfos": [{
            "name": "createdOn",
            "expression": "Text(Date($feature.sys_created_on))"
          }, {
            "name": "timeAgo",
            "title": "<span class='esri-icon-calendar'></span>",
            "expression": "var d1 = Now(); var d2 = Date($feature.sys_created_on); var u = $feature.caller_id_name; var p = '{n} {units} ago by {user}'; var a = ['years','months','days','hours','minutes','seconds']; var l = ['year(s)','month(s)','day(s)','hour(s)','minute(s)','second(s)']; for (var k in a) {var n = Floor(DateDiff(d1,d2,a[k])); if (n >= 1) return Replace(Replace(Replace(p,'{n}',n),'{units}',l[k]),'{user}',u);}"
          }, {
            "name": "priorityClass",
            "expression": "var c = 'i-priority'; var v = $feature.priority_label; c = c + ' i-priority--' + Lower(v); return c;"
          }]
        }
      },
      "notInUse_example_subTypes": [
        {
          "name": "All",
          "value": "all",
          "where": null,
          "iconUrl": null
        },
        {
          "name": "Software",
          "value": "software",
          "where": "category='software'",
          "iconUrl": null
        }
      ]
    }
  ]
   */

  _apply(task: IAppInfoTask) {
    //console.log("appItem",task.appItem);
    const config = Context.instance.config;
    this.appid = task.appid;
    this.appItem = task.appItem;
    this.appData = task.appData;
    const appDataValues = (task.appData && task.appData.values);

    if (this.appItem) {
      let appUrlOk = false;
      const url = this.appItem.url;
      if (typeof url === "string") {
        const lib = Context.instance.lib;
        const urlObj = lib.esri.urlUtils.urlToObject(url);
        const path = urlObj.path;
        //console.log("urlObj.path",path)
        const afn = this._getAppFolderName();

        const vs = ["apps/"+afn];
        if (Context.instance.appMode.isSP()) {
          //vs.push("apps/indoors","apps/indoor-space-planner"); // TODO temporary
        } else if (Context.instance.appMode.isFPE()) {
          //vs.push("apps/spaceplanner"); // TODO temporary
        }
        appUrlOk = vs.some(v => {
          return path.endsWith(v) || (path.indexOf(v+"/") !== -1);
        })
      }
      if (!appUrlOk) {
        console.error("Error: not an ArcGIS Indoors App",this.appItem);
        throw new Error("__notAnIndoorsApp__");
      }
    }

    this._importConfigurables(task.appData);

    if (!appDataValues) {
      if (task.urlBased) {
        const q = task.urlBased;
        if (val.isNonEmptyStr(q.webmap)) {
          config.webmap = q.webmap;
          config.webscene = null;
        }
      }
    }

    if (task.startInConfiguratorMode) {
      Context.instance.session.startInConfiguratorMode = true;
    }
    Context.instance.uiMode._applyTheme(config.theme);
  }

  _cloneConfigurables(configurables: Partial<IConfigurables>): IConfigurables {
    return JSON.parse(JSON.stringify(configurables));
  }

  extractConfigurables() {
    const config = Context.instance.config;
    let configurables: Partial<IConfigurables> = this._cloneConfigurables(this._configurables);

    if (Context.instance.appMode.isSP_or_FPE()) {
      const tmp: Partial<IConfigurables> = {
        webmap: configurables.webmap,
        applicationReset: configurables.applicationReset,
        spaceplanner: configurables.spaceplanner
      };
      configurables = tmp;
    }

    Object.keys(configurables).forEach(k => {
      if (config.hasOwnProperty(k)) {
        configurables[k] = config[k];
      }
    });
    return this._cloneConfigurables(configurables);
  }

  _getAppFolderName() {
    let name = "indoors";
    if (Context.instance.isFPE()) {
      name = "floorplaneditor";
    } else if (Context.instance.isSP()) {
      name = "spaceplanner";
    }
    return name;
  }

  getIndoorsAppTypeKeyword() {
    return Context.instance.appMode.getAppTypeKeyword();
  }

  _importConfigurables(appData: { values: IConfigurables }) {
    const vv = (validValues,value,defaultValue) => {
      if (validValues.indexOf(value) !== -1) return value;
      return defaultValue;
    };

    let v;
    const config = Context.instance.config;
    const configurables = this._cloneConfigurables(this._configurables);
    const copy = this._cloneConfigurables(this._configurables);
    let values = appData && appData.values;
    let workspaceReservationVersion = configurables.workspaceReservation.version;

    if (values) {
      Object.keys(configurables).forEach(k => {
        if (values.hasOwnProperty(k)) {
          configurables[k] = values[k];
          //if (k !== "scalebar" && k !== "print") configurables[k] = values[k];
        }
      });

      if (values.appLaunch && values.appLaunch.actions) {
        //console.log("****************************** Configuration:appDataHadAppLaunch")
        this.srcInfo.appDataHadAppLaunch = true;
      } else if (val.isNonEmptyStr(values._311Url,true)) {
        //console.log("****************************** Configuration:appLaunch from _311Url")
        this.srcInfo.appDataHad311 = true;
        configurables.appLaunch = appLaunchUtil.three11AsAppLaunch(values._311Url);
      }

      if(values.aboutSection &&values.aboutSection.enabled) {
        if(!values.aboutSection.htmlElement) {
          configurables.aboutSection.enabled = false;
        }
      }

      if(values.workspaceReservation || values.officeHoteling) {
        Context.instance.session.appDataHadWorkspaceConfig = true;
      }
    }

    let scalebar = configurables.scalebar;
    if (!scalebar || typeof scalebar !== "object") {
      configurables.scalebar = scalebar = copy.scalebar;
    }
    if (typeof scalebar.show !== "boolean") {
      scalebar.show = false;
    }
    scalebar.units = vv(["metric","non-metric","dual"],scalebar.units,"dual");
    scalebar.style = vv(["line","ruler"],scalebar.style,"line");
    if (scalebar.units === "dual") scalebar.style = "line";

    let print = configurables.print;
    if (!print || typeof print !== "object") {
      configurables.print = print = copy.print;
    }
    print.layout = vv(["map-only","letter","a4"],print.layout,"map-only");

    let measure = configurables.measure;
    if (!measure || typeof measure !== "object") {
      configurables.measure = measure = copy.measure;
    }
    if (typeof measure.enabled !== "boolean") {
      measure.enabled = false;
    }

    let nativeApp = configurables.nativeApp;
    if (!nativeApp || typeof nativeApp !== "object") {
      configurables.nativeApp = nativeApp = copy.nativeApp;
    }
    if (typeof nativeApp.showIOS !== "boolean") {
      nativeApp.showIOS = true;
    }

    let workspaceReservation = configurables.workspaceReservation;
    if (!workspaceReservation || typeof workspaceReservation !== "object") {
      configurables.workspaceReservation = workspaceReservation = copy.workspaceReservation;
    }
    if (typeof workspaceReservation.isSingleTenant !== "boolean") {
      workspaceReservation.isSingleTenant = false;
    }
    if (typeof workspaceReservation.enableHotel !== "boolean") {
      workspaceReservation.enableHotel = false;
    }
    if (typeof workspaceReservation.enableMeeting !== "boolean") {
      workspaceReservation.enableMeeting = false;
    }
    if (workspaceReservation && workspaceReservation.version === 3) {
      if (typeof workspaceReservation.enableGoogleCalendar !== "boolean") {
        workspaceReservation.enableGoogleCalendar = false;
      }
      if (typeof workspaceReservation.enableOutlookCalendar !== "boolean") {
        workspaceReservation.enableOutlookCalendar = false;
      }
    } else if (workspaceReservation && workspaceReservation.version === 2) {
      const wsrSaved = workspaceReservation;
      const wsrCurrent = copy.workspaceReservation;
      if (typeof wsrSaved.appId === "string") wsrCurrent.appId = wsrSaved.appId;
      if (typeof wsrSaved.isSingleTenant === "boolean") wsrCurrent.isSingleTenant = wsrSaved.isSingleTenant;
      if (typeof wsrSaved.tenantId === "string") wsrCurrent.tenantId = wsrSaved.tenantId;
      if (typeof wsrSaved.reservationLayerId === "string") wsrCurrent.reservationLayerId = wsrSaved.reservationLayerId;
      if (wsrSaved.enabled) {
        if (wsrSaved.enableOfficeHoteling && wsrSaved.reservationType === "esri") {
          wsrCurrent.enableHotel = true;
          wsrCurrent.reservationTypeHotel = "esri";
        } else if (wsrSaved.enableOfficeHotelingO365 && wsrSaved.reservationType === "office365") {
          wsrCurrent.enableHotel = true;
          wsrCurrent.reservationTypeHotel = "office365";
        } 
        if(wsrSaved.enableMeetingRooms && wsrSaved.reservationType === "esri") {
          wsrCurrent.enableMeeting = true;
          wsrCurrent.reservationTypeMeeting = "esri";
        } else if (wsrSaved.enableMeetingRoomsO365 && wsrSaved.reservationType === "office365") {
          wsrCurrent.enableMeeting = true;
          wsrCurrent.reservationTypeMeeting = "office365";
        }
        wsrCurrent.enableGoogleCalendar = true;
        wsrCurrent.enableOutlookCalendar = true; 
      } 
      configurables.workspaceReservation = workspaceReservation = wsrCurrent;
    } else if (workspaceReservation && typeof workspaceReservation.version === undefined) {
      const wsrSaved = workspaceReservation;
      const wsrCurrent = copy.workspaceReservation;
      if (wsrSaved.enabled) {
        if (wsrSaved.reservationType === "office365") {
          if (wsrSaved.enableOfficeHoteling) {
            wsrCurrent.enableHotel = true;
            wsrCurrent.reservationTypeHotel = "office365"
          }
          if (wsrSaved.enableMeetingRooms) {
            wsrCurrent.enableMeeting = true;
            wsrCurrent.reservationTypeMeeting = "office365"
          }
        } else if (wsrSaved.reservationType === "esri") {
          if (wsrSaved.enableOfficeHoteling) {
            wsrCurrent.enableHotel = true;
            wsrCurrent.reservationTypeHotel = "esri"
          }
          if (wsrSaved.enableMeetingRooms) {
            wsrCurrent.enableMeeting = true;
            wsrCurrent.reservationTypeMeeting = "esri"
          }
        }
        wsrCurrent.enableGoogleCalendar = true;
        wsrCurrent.enableOutlookCalendar = true; 
      }
      configurables.workspaceReservation = workspaceReservation = wsrCurrent;
    }

    if (configurables.workspaceReservation.enableMeeting && configurables.workspaceReservation.allowMeetingCheckIn &&
       (configurables.workspaceReservation.reservationTypeMeeting === "esri")) {
      configurables.workspaceReservation.allowMeetingCheckIn = true;
    } else {
      configurables.workspaceReservation.allowMeetingCheckIn = false;
    }

    let indoorPositioning = configurables.indoorPositioning;
    if (!indoorPositioning || typeof indoorPositioning !== "object") {
      configurables.indoorPositioning = indoorPositioning = copy.indoorPositioning;
    }
    if (typeof indoorPositioning.iOS !== "boolean") {
      indoorPositioning.iOS = false;
    }

    let applicationReset = configurables.applicationReset;
    if(!applicationReset || typeof applicationReset !== "object"){
      configurables.applicationReset = applicationReset = copy.applicationReset;
    }
    if(typeof applicationReset.enabled !== "boolean"){
      applicationReset.enabled = false;
    }
    v = applicationReset.timeoutMillis;
    if (!val.isFiniteNum(v)) {
      applicationReset.timeoutMillis = copy.applicationReset.timeoutMillis;
    } else if (v < 1000) {
      applicationReset.timeoutMillis = copy.applicationReset.timeoutMillis;
    }
    v = applicationReset.popupMillis;
    if (!val.isFiniteNum(v)) {
      applicationReset.popupMillis = copy.applicationReset.popupMillis;
    } else if (v < 1000) {
      applicationReset.popupMillis = copy.applicationReset.popupMillis;
    }

    let kiosk = configurables.kiosk;
    if (!kiosk || typeof kiosk !== "object") {
      configurables.kiosk = kiosk = copy.kiosk;
    }
    let kioskReset = kiosk.kioskReset;
    if (!kioskReset || typeof kioskReset !== "object") {
      kiosk.kioskReset = kioskReset = copy.kiosk.kioskReset;
    }
    if (typeof kioskReset.enabled !== "boolean") {
      kioskReset.enabled = true;
    } 

    let kioskQRCode = kiosk.kioskQRCode;
    if (!kioskQRCode || typeof kioskQRCode !== "object") {
      kiosk.kioskQRCode = kioskQRCode = copy.kiosk.kioskQRCode;
    }
    if (typeof kioskQRCode.enabled !== "boolean") {
      kioskQRCode.enabled = true;
    } 

    let kioskVirtualKeyboard = kiosk.kioskVirtualKeyboard;
    if (!kioskVirtualKeyboard || typeof kioskVirtualKeyboard !== "object") {
      kiosk.kioskVirtualKeyboard = kioskVirtualKeyboard = copy.kiosk.kioskVirtualKeyboard;
    }
    if (typeof kioskVirtualKeyboard.enabled !== "boolean") {
      kioskVirtualKeyboard.enabled = false;
    }

    v = kioskReset.timeoutMillis;
    if (!val.isFiniteNum(v)) {
      kioskReset.timeoutMillis = copy.kiosk.kioskReset.timeoutMillis;
    } else if (v < 1000) {
      kioskReset.timeoutMillis = copy.kiosk.kioskReset.timeoutMillis;
    }
    v = kioskReset.popupMillis;
    if (!val.isFiniteNum(v)) {
      kioskReset.popupMillis = copy.kiosk.kioskReset.popupMillis;
    } else if (v < 1000) {
      kioskReset.popupMillis = copy.kiosk.kioskReset.popupMillis;
    }

    let appLaunch = configurables.appLaunch;
    if (!appLaunch || typeof appLaunch !== "object") {
      configurables.appLaunch = appLaunch = copy.appLaunch;
    }
    if (!Array.isArray(appLaunch.actions)) {
      appLaunch.actions = [];
    }
    if (!Array.isArray(appLaunch.removedWebmapActions)) {
      appLaunch.removedWebmapActions = []
    }
    appLaunchUtil.checkActions(appLaunch.actions);

    let logo = configurables.logo;
    if (!logo || typeof logo !== "object") {
      configurables.logo = logo = copy.logo;
    }
    logo.choice = vv(["default","portal","custom"],logo.choice,"default");
    logo.size = vv(["1","1.5","2","2.5","3"],logo.size,"2.5");
    if (logo.choice === "default") {
      configurables.logo = logo = copy.logo;
    }

    let theme = configurables.theme;
    if (!theme || typeof theme !== "object") {
      configurables.theme = theme = copy.theme;
    }
    if (!theme["theme-color-brand"]) {
      configurables.theme = theme = copy.theme;
    }
    Object.keys(copy.theme).forEach(k => {
      if (!theme.hasOwnProperty(k)) {
        theme[k] = copy.theme[k];
      }
    });
    if (!configurables.theme["theme-color-button-highlight"]) {
      configurables.theme["theme-color-button-highlight"] = configurables.theme["theme-color-button"];
    }

    if (Context.instance.appMode.isSP_or_FPE()) {
      let spaceplanner = configurables.spaceplanner;
      if (!spaceplanner || typeof spaceplanner !== "object") {
        configurables.spaceplanner = spaceplanner = copy.spaceplanner;
      }
      let mergepermission = spaceplanner.mergepermission;
      if (!mergepermission || typeof mergepermission !== "object") {
        spaceplanner.mergepermission = mergepermission = copy.spaceplanner.mergepermission;
      }
      let filterColumns = spaceplanner.filterColumns;
      if (!filterColumns || typeof filterColumns !== "object") {
        spaceplanner.filterColumns = filterColumns = copy.spaceplanner.filterColumns;
      }
      let unitName = spaceplanner.unitName;
      if (!unitName || typeof unitName !== "string") {
        spaceplanner.unitName = copy.spaceplanner.unitName;
      }
      let palette = spaceplanner.palette;
      if (!palette || typeof palette !== "object") {
        spaceplanner.palette = palette = copy.spaceplanner.palette;
      }
      let wallTypeValues = spaceplanner.wallTypeValues;
      if (!wallTypeValues || !Array.isArray(wallTypeValues)) {
        spaceplanner.wallTypeValues = wallTypeValues = copy.spaceplanner.wallTypeValues;
      }
      let workspaceAreas = spaceplanner.workspaceAreas;
      if (!workspaceAreas || typeof workspaceAreas !== "object") {
        spaceplanner.workspaceAreas = workspaceAreas = copy.spaceplanner.workspaceAreas;
      }
    }

    Object.assign(config,configurables);

    if(kioskVirtualKeyboard.enabled) Context.instance.virtualKeyboard.init();

  }

  _load() {
    const config = Context.instance.config;
    const configurables = this._cloneConfigurables(this._configurables);
    Object.assign(config,this._local);
    Object.assign(config,configurables);
    const configLoader = new ConfigLoader();
    return configLoader.load(this);
  }

  _makeAppItem(metaInfo,folderId): IItemAddRequest {

    const ensureArrayValue = (a,value) => {
      let modified = false;
      if (!Array.isArray(a)) {
        a = [];
        modified = true;
      }
      const b = [], lc = value.toLowerCase();
      let found = false;
      a.forEach(v => {
        if (typeof v === "string" && v.toLowerCase() === lc) {
          if (!found) {
            if (v === value) {
              b.push(v);
            } else {
              b.push(value);
              modified = true;
            }
          } else {
            found = true;
          }
          found = true;
        } else {
          b.push(v);
        }
      });
      if (!found) {
        b.push(value);
        modified = true;
      }
      return {
        values: modified ? b : a,
        modified: modified
      };
    };

    const removeArrayValue = (a,value) => {
      if (Array.isArray(a)) {
        const idx = a.indexOf(value);
        if (idx !== -1) {
          a.splice(idx,1);
        }
      }
    };

    const config = Context.instance.config;
    const views = Context.instance.views;
    const portalUrl = Context.instance.getPortalUrl();
    const appItem = this.appItem;
    const webmap = config.webmap;
    const afn = this._getAppFolderName();
    const appUrl = portalUrl + "/apps/"+afn+"/index.html";

    const data = {
      source: this.indoorsAppSource,
      folderId: folderId || null,
      values: this.extractConfigurables()
    };
    let item: Partial<IItemAddRequest> = {
      type: "Web Mapping Application",
      url: appUrl,
      webmap: webmap,
      webmapId: webmap,
      shareWithWebMap: true,
      clearEmptyFields: true,
      text: JSON.stringify(data)
    };

    if (metaInfo) {
      item = Object.assign(item,metaInfo)
    }

    const appTypeKeyword = this.getIndoorsAppTypeKeyword();
    let kws = "Web Map, Map, Online Map, Mapping Site, JavaScript, Ready To Use";
    let typeKeywords = (appItem && appItem.typeKeywords);
    if (appItem && appItem.typeKeywords) {
      typeKeywords = appItem.typeKeywords.slice();
      if (Context.instance.appMode.isViewer()) {
        removeArrayValue(typeKeywords,"IndoorsSpaceplannerApp");
        removeArrayValue(typeKeywords,"IndoorsFloorPlanEditorApp");
      } else if (Context.instance.appMode.isSP()) {
        removeArrayValue(typeKeywords,"IndoorsApp");
        removeArrayValue(typeKeywords,"IndoorsFloorPlanEditorApp");
      } else if (Context.instance.appMode.isFPE()) {
        removeArrayValue(typeKeywords,"IndoorsApp");
        removeArrayValue(typeKeywords,"IndoorsSpaceplannerApp");
      }
    } else {
      typeKeywords = kws.split(",");
    }
    typeKeywords = ensureArrayValue(typeKeywords,appTypeKeyword).values;
    typeKeywords = ensureArrayValue(typeKeywords,"selfConfigured").values;
    typeKeywords = ensureArrayValue(typeKeywords,"Configurable").values;
    item.typeKeywords = typeKeywords.join(",");

    let tags: string[];
    if (metaInfo.tags) {
      tags = metaInfo.tags;
    } else {
      tags = (appItem && appItem.tags);
    }
    tags = ensureArrayValue(tags,this.indoorsTag).values;
    item.tags = tags.join(",");

    //thumbnailURL: "/webapp.png",
    //thumbnailUrl: thumbnailUrl,
    if (views && views.mapView && views.mapView.map) {
      let webmapItem = (views.mapView.map as __esri.WebMap).portalItem;
      let thumbnailUrl = webmapItem && webmapItem.thumbnailUrl;
      if (thumbnailUrl) {
        // thumbnailUrl = aiimUtil.appendTokenToUrl(thumbnailUrl);
        item.thumbnailUrl = thumbnailUrl;
      } else {
        // TODO?
      }
    }

    return item;
  }

  saveAppItem(metaInfo,folderId?,sharingInfo?,isSaveAs?,removeEdit?): Promise<void> {
    const promise = new Promise<void>((resolve,reject) => {
      const appItem = this.appItem;
      let itemId, owner;

      if (isSaveAs) {
        //
      } else {
        itemId = appItem.id;
        folderId = appItem.ownerFolder;
        owner = appItem.owner;
      }
      const item = this._makeAppItem(metaInfo,folderId);
      if (isSaveAs) {
        let appid;
        portalUtil.saveItem(item,itemId,folderId,owner).then(response => {
          //console.log("Configuration.saveAppItem.response",response);
          appid = response.data.id;
          let updateItem = {
            url: item.url + "?appid=" + encodeURIComponent(appid)
          };
          return portalUtil.saveItem(updateItem,appid,folderId,owner);
        }).then(() => {
          if (sharingInfo) {
            if (sharingInfo.everyone || sharingInfo.org || sharingInfo.groups) {
              return portalUtil.shareItem(appid,owner,sharingInfo)
            }
          }
        }).then(() => {
          const lib = Context.instance.lib;
          const urlObj = lib.esri.urlUtils.urlToObject(window.location.href);
          let href = urlObj.path;
          if (href.indexOf("#") > 0) href = href.substring(0,href.indexOf("#"));
          href += "?appid=" + encodeURIComponent(appid);
          if (!removeEdit) href += "&edit=true"; // TODO?
          window.location.href = href;
        }).catch(ex => {
          reject(ex);
        });

      } else {
        item.url += "?appid=" + encodeURIComponent(itemId);
        const task: IAppInfoTask = {appid: itemId};
        portalUtil.saveItem(item,itemId,folderId,owner).then(response => {
          //console.log("Configuration.saveAppItem.response",response);
          return portalUtil.readItem(task.appid);
        }).then((itemResult) => {
          task.appItem = itemResult && itemResult.data;
          return portalUtil.readItemJsonData(task.appid);
        }).then(dataResult => {
          task.appData = dataResult && dataResult.data;
        }).then(() => {
          //console.log("Configuration.saveAppItem.task",task);
          if (task.appItem && task.appData) {
            this.appid = task.appid;
            this.appItem = task.appItem;
            this.appData = task.appData;
          }
          //console.log("Configuration",this);
          resolve()
        }).catch(ex => {
          reject(ex);
        });
      }

    });
    return promise;

  }

}
