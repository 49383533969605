import React from "react";

import CalciteMenu from "calcite-react/Menu";

export default class Menu extends React.Component {

  render() {
    return (
      <CalciteMenu {...this.props}>
        {this.props.children}
      </CalciteMenu>
    );
  }

}
