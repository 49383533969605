import styled, { css } from "styled-components";

import CalciteButton from "calcite-react/Button";

const Wrapper = styled.div`
  display: flex;
  padding: 0.775rem;
  background: #fff;
`;

const SelectionCount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  white-space: nowrap;
`;

const Button = styled(CalciteButton)`
  background-color: ${props => props.theme.palette.themeColorDark};

  ${props =>
    props.clear &&
    css`
      color: ${props => props.theme.palette.themeColor};
      background: ${props.theme.palette.white};
      border-color: ${props => props.theme.palette.themeColor};

      &:hover {
        color: ${props.theme.palette.white};
        background: ${props => props.theme.palette.themeColor};
        border-color: ${props => props.theme.palette.themeColor};
      }
    `};

  ${props =>
    props.iconButton &&
    css`
      background: transparent;
      color: ${props => props.theme.palette.themeColorDark};
      border: none;
      ${props =>
        props.white &&
        css`
          color: ${props.theme.palette.white};
        `};
      &:hover {
        color: ${props => props.theme.palette.themeColor};
        background: transparent;
      }
    `};
`;

export { Wrapper, SelectionCount, Button };
