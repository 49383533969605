import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import QueryAll from "../QueryAll";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as queryUtil from "../queryUtil";
import * as selectionUtil from "../../../aiim/util/selectionUtil";
import * as sourceUtil from "../sourceUtil";
import * as transactionUtil from "./transactionUtil";
import { ITransactionInfo } from "./transaction";
import { IFeature } from "@esri/arcgis-rest-types";


export async function queryNonRelatedOccupants(emails,unitIds) {
  const lcEmailIndex = {};
  if (!sourceUtil.getPeopleLayer()) return lcEmailIndex;
  if (!emails || emails.length === 0) return lcEmailIndex;
  if (!unitIds || unitIds.length === 0) return lcEmailIndex;

  const lib = Context.instance.lib;
  const layer = sourceUtil.getPeopleLayer();
  const fields = layer.fields;
  const unitIdField = aiimUtil.findField(fields,FieldNames.PEOPLE_UNIT_ID).name;
  const emailField = aiimUtil.findField(fields,FieldNames.PEOPLE_EMAIL).name;

  const values1 = emails.map(email => "'" + selectionUtil.escSqlQuote(email.toUpperCase()) + "'");
  const wh1 = "(UPPER(" + emailField + ") IN (" + values1.join(",") + "))";

  const values2 = unitIds.map(unitId => "'" + selectionUtil.escSqlQuote(unitId) + "'");
  let wh2 = "(" + unitIdField + " NOT IN (" + values2.join(",") + "))";

  const where = wh1 + " AND " + wh2;

  const url = Context.checkMixedContent(layer.url+"/"+layer.layerId);
  const query = new lib.esri.Query();
  query.where = where;
  query.outFields = [emailField];
  query.returnGeometry = false;
  query.returnZ = false;
  queryUtil.applyGdbVersion(layer,query);

  const qaopts = {layer: layer};
  const qa = new QueryAll();
  const result = await qa.execute(url,query,qaopts);
  if (result && result.features) {
    result.features.forEach((f: IFeature) => {
      const email = aiimUtil.getAttributeValue(f.attributes,emailField);
      if (typeof email === "string") {
        lcEmailIndex[email.toLowerCase()] = true;
      }
    });
  }
  return lcEmailIndex;
}

export async function removeOccupantAssignments(transactionInfo: ITransactionInfo, unitIds) {
  const task = {};
  if (!sourceUtil.getPeopleLayer()) return task;
  const layer = sourceUtil.getPeopleLayer();
  const fields = layer.fields;
  const emailField = aiimUtil.findField(fields, FieldNames.PEOPLE_EMAIL).name;
  // @ts-ignore
  const keyField = transactionInfo.useGlobalIds ? layer.globalIdField : layer.objectIdField;

  const info: any = await transactionUtil.fetchRelatedPeople(null,unitIds);

  let lcNonRelatedEmailIndex = {};
  const lcEmails = [];
  info && info.features && info.features.forEach((f: IFeature) => {
    const email = aiimUtil.getAttributeValue(f.attributes,emailField);
    if (typeof email === "string") {
      const lc = email.toLowerCase();
      if (lc && lcEmails.indexOf(lc) === -1) lcEmails.push(lc);
    }
  })
  if (lcEmails.length > 0) {
    lcNonRelatedEmailIndex = await queryNonRelatedOccupants(lcEmails,unitIds);
  }

  const updates = [], undoUpdates = [], deletes = [], undoDeletes = [];
  info && info.features && info.features.forEach((f: IFeature) => {
    const key = f.attributes[keyField];
    const email = aiimUtil.getAttributeValue(f.attributes,emailField);
    if (typeof email === "string") {
      const lc = email.toLowerCase();
      if (lc && lcNonRelatedEmailIndex[lc]) {
        // occupant has an additional non-related assignment, this one can be deleted
        deletes.push(key)
        undoDeletes.push(transactionUtil.clonePersonFeature(task,f,"ensure-geometry"))
      } else if (lc) {
        // occupant has no other assignment, this one can be updated
        const update = {attributes: {}}, undoUpdate = {attributes: {}};
        update.attributes[keyField] = key;
        transactionUtil.appendPersonUnassignment(task,fields,update);
        updates.push(update);
        undoUpdates.push(transactionUtil.clonePersonFeature(task,f,"ensure-geometry"))
      }
    }
  })

  if (updates.length > 0 || deletes.length > 0) {
    transactionInfo.peopleEdits = true;
    transactionInfo.undo.peopleEdits = true;
    transactionInfo.edits.push({
      id: layer.layerId,
      updates: updates,
      deletes: deletes
    });
    transactionInfo.undo.edits.push({
      id: layer.layerId,
      updates: undoUpdates,
      adds: undoDeletes
    });
  }
  return task;
}