import React, {createRef} from "react";

import Context from "../../../../context/Context";
import FieldNames from "../../../../aiim/datasets/FieldNames";
import {ModalController} from "../../../../common/components/Modal";
import OfficePlan from "../../../base/OfficePlan";
import Topic from "../../../../context/Topic";
import TransactionGuard from "../../../base/TransactionGuard";
import * as aiimUtil from "../../../../aiim/util/aiimUtil";
import * as component from "../../../../components/util/component";
import * as sourceUtil from "../../../base/sourceUtil";
import * as transactions from "../../../base/transaction/transactions";
import * as tsUtil from "../../../../util/tsUtil";
import * as queryUtil from "../../../base/queryUtil";
import * as selectionUtil from "../../../../aiim/util/selectionUtil";
import * as val from '../../../../util/val';

import Popup from "./Popup";
import Tooltip from "calcite-react/Tooltip";
import InformationIcon from "calcite-ui-icons-react/InformationIcon"
 
export default class AttributeEditor extends React.Component {

  _coreNodeRef = createRef();
  _coreWidget;

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  componentDidMount () {
    this._initWidget();
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _initWidget() {
    const context = Context.instance;
    const node = this._coreNodeRef && this._coreNodeRef.current;
    if (node) {
      this._coreWidget = new context.lib.indoors.CustomFeatureForm({
        indoorsContext: context,
        tsUtil: tsUtil,
        container: node,
        feature: this.props.feature,
        indoorsOptions: this.props.options
      });
      if (this.props.onCreateForm) {
        this.props.onCreateForm(this,this._coreWidget);
      }
    }
  }

  render() {
    return (
      <div>
        <div ref={this._coreNodeRef}></div>
      </div>
    );
  }

  static show(options) {
    let attributes = options.feature.attributes;

    if (options.type === "unit") {
      if (!options.layer) {
        options.layer = sourceUtil.getUnitsLayer();
      }
      if (!options.title) {
        options.title = aiimUtil.getAttributeValue(attributes,Context.instance.aiim.getUnitName());
      }
    } else if (options.type === "person") {
      if (!options.layer) {
        options.layer = sourceUtil.getPeopleLayer();
      }
      if (!options.title) {
        options.title = aiimUtil.getAttributeValue(attributes,FieldNames.PEOPLE_FULLNAME);
      }
    }
    let feature = new Context.instance.lib.esri.Graphic({
      attributes: attributes
    })
    feature.layer = options.layer;

    // options.layer.fields.forEach(f => {
    //   //if (f.name === "NAME_LONG") f.nullable = false;
    //   console.log(f.name,f.nullable,f)
    // })

    let controller, okButton, featureForm;
    let trackChange = true;
    
    /**
     * Query people table to find an occupant
     * @param {string} email occupant email 
     * @returns {Object} result of the query and layer source
     */
    const getOccupant = async (email) => {
      try {
        const emailField = FieldNames.PEOPLE_EMAIL;
        const source = sourceUtil.getPeopleSource();
        const v = selectionUtil.escSqlQuote(email);
        const where = `(${emailField} = '${v}')`;
                
        const queryOptions = {
          source: source,
          orderByFields: [FieldNames.PEOPLE_FULLNAME],
          where: where,
        }
        const result = await queryUtil.queryFeatures(queryOptions);
        return {
          queryResult: result,
          source: source
        }
      } catch (error) {
        console.error(error);
      }
    }

    const applyEdit = edit => {
      const guard = new TransactionGuard({force: true});
      Promise.resolve().then(() => {
        if (options.occupantType === 'edit') {
          return transactions.updateAttributes(options.type,options.feature,edit.attributes);
        } else {
          if (!edit.attributes.EMAIL) {
            edit.attributes.EMAIL = `placeholder-${val.generateRandomId()}@placeholder.com`;
          }
          return transactions.insertOccupant(edit);
        }
      }).then(async () => {
        guard.close();
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          attributeEditorLayerType: options.type
        });
        // After adding a new occupant show popup to assign a unit
        if (options.occupantType === 'placeholder') {
          const editEmail = aiimUtil.getAttributeValue(edit.attributes, FieldNames.PEOPLE_EMAIL);
          const data = await getOccupant(editEmail);
          const feature = data.queryResult.features.find(f => {
            const email = aiimUtil.getAttributeValue(f.attributes, FieldNames.PEOPLE_EMAIL);
            return email === editEmail;
          });
          if (feature) {
            const featureItem = queryUtil.makeFeatureItem(data.source,feature);
            const knownas = aiimUtil.getAttributeValue(edit.attributes, FieldNames.PEOPLE_FULLNAME);
            Topic.publish(Topic.ShowItemPopup, {
              title: knownas,
              source: data.source,
              featureItem: featureItem,
              fetchFeatureItemGeometry: false,
              zoom: false,
              content: (
                <Popup
                  sourceKey='People'
                  featureItem={featureItem}
                  isPerson={true}
                />
              ),
            });
          }
        }
      }).catch((error) => {
        guard.close();
        console.error("Error updating feature", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
    };

    const tooLong = (inputField) => {
      if (!inputField || (inputField.dataType !== "text")) return false;
      const length = inputField.field && inputField.field.length;
      return length && inputField.value && inputField.value.length > length;
    }
    
    /**
     * Add a tooltip to the title
     * @returns jsx title with a tooltip
     */
    const renderTitle = () => {
      const i18n = Context.instance.i18n;
      let textAlign = "left";
      if (Context.instance.uiMode.isRtl) textAlign = "right";
      return (
        <>
          {options.title}
          <Tooltip style={{textAlign: textAlign}}
            title={i18n.spaceplanner.people.add.tooltip}>
            <InformationIcon size="16" style={{margin: "0px 4px 0px 4px"}} />
          </Tooltip>
        </>
      )
    }

    const hasValidChanges = () => {
      let changed = false;
      if (featureForm) {
        const inputs = featureForm.viewModel.allFieldInputs;
        const useTypeInput = inputs.find((input) => input.name.toLowerCase() === "use_type");
        if (useTypeInput) {
          useTypeInput.domain = useTypeInput.field.domain;
        }
        const hasInvalid = inputs.some(inputField => {
          return (!inputField.valid || tooLong(inputField));
        });
        if (!hasInvalid) {
          const updated = featureForm.getValues();
          Object.keys(updated).some(name => {
            if (attributes[name] !== updated[name]) {
              changed = true;
            }
            return changed;
          });
        }
      }
      return changed;
    }

    let content = (
      <div className="i-modal-attribute-editor">
        <AttributeEditor feature={feature} options={options}
          onCreateForm={(ae,ff) => {
            featureForm = ff;
            
            // Add a placeholder to Knownas field element
            // and add label for the rest of the fields
            const i18n = Context.instance.i18n;
            if (options.occupantType === 'placeholder') {
              featureForm.formTemplate.elements[0].hint = i18n.spaceplanner.people.add.hint;
              featureForm.formTemplate.elements[1].label = i18n.spaceplanner.people.add.more;
            }

            if (trackChange) {
              featureForm.on("value-change",info => {
                if (okButton) {
                  if (hasValidChanges()) {
                    okButton.enable();
                  } else {
                    okButton.disable();
                  }
                }
              });
            }

            featureForm.on("submit", async (info) => {
              if (info.invalid && info.invalid.length > 0) {
                // TODO ???
                return;
              }
              let changed = false;
              const edit = {attributes: {}}
              const updated = featureForm.getValues();
              Object.keys(updated).forEach(name => {
                if (attributes[name] !== updated[name]) {
                  changed = true;
                  edit.attributes[name] = updated[name];
                }
              });
              controller.close();
              if (changed) {
                applyEdit(edit);
              }
            });

          }}
        />
      </div>
    );
    
    const okLabel = options.occupantType === 'edit' ? Context.instance.i18n.general.update : Context.instance.i18n.general.add;
    const title = options.occupantType === 'edit' ? options.title : renderTitle(); 
    const dialogStyle = options.occupantType === 'placeholder' ? {minHeight: "0", minWidth: "35vw"} : {minHeight: "35vh", minWidth: "35vw"};
    
    const modalProps = {
      dialogStyle: dialogStyle,
      title: title,
      noPadding: true,
      okLabel: okLabel,
      showOKCancel: true,
      closeOnOK: false,
      className: "i-attribute-editor",
      onMountOKButton: btn => {
        okButton = btn;
        if (trackChange) okButton.disable()
      },
      onOK: () => {
        if (okButton && featureForm) {
          okButton.disable();
          featureForm.submit();
        }
      },
    };

    controller = new ModalController({});
    controller.show(modalProps, content);
  }

}
