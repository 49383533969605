import Context from "../../../../../../context/Context"
import type {
  IBookingParams,
  IBookingSystem,
  ICancelBookingParams,
  ICheckAvailabilityParams
} from "./BookingSystem";

function can(obj, methodName) {
  return (obj && (typeof obj[methodName]) === "function")
}

function createErrorMessage(name) {
  const i18n = Context.instance.i18n
  return i18n.more.bookWorkspace.interfaceError.replace("{function}", name)
}

export function login(obj) {
  const name = "login"
  if (can(obj, name)) {
    return obj.login()
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getAccessToken(obj) {
  const name = "getAccessToken"
  if (can(obj, name)) {
    return obj.getAccessToken()
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getBookingSchedule(obj: IBookingSystem, params: IBookingParams, signal?: AbortSignal) {
  const name = "getBookingSchedule"
  if (can(obj, name)) {
    return obj.getBookingSchedule(params, signal)
  } else {
    console.error(createErrorMessage(name))
  }
}

export function logout(obj, reloadUrl) {
  const name = "logout"
  if (can(obj, name)) {
    return obj.logout(reloadUrl)
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getLoggedInUser(obj) {
  const name = "getLoggedInUser"
  if (can(obj, name)) {
    return obj.getLoggedInUser()
  }
  else {
    console.error(createErrorMessage(name))
  }
}

export function bookWorkspace(obj, params) {
  const name = "bookWorkspace"
  if (can(obj, name)) {
    return obj.bookWorkspace(params)
  } else {
    console.error(createErrorMessage(name))
  }
}

export function checkAvailability(
  obj: IBookingSystem,
  uniqueIds: string[],
  checkIn: string,
  checkOut: string,
  ignoreObjectIds: boolean | number[],
  params?: ICheckAvailabilityParams,
  signal?: AbortSignal
) {
  const name = "checkAvailability"
  if (can(obj, name)) {
    return obj.checkAvailability(uniqueIds, checkIn, checkOut, ignoreObjectIds, params, signal)
  } else {
    console.error(createErrorMessage(name))
  }
}

export function updateBooking(obj, attributes) {
  const name = "updateBooking";
  if (can(obj, name)) {
    return obj.updateBooking(attributes);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function cancelBooking(obj: IBookingSystem, attributes: ICancelBookingParams){
  const name = "cancelBooking"
  if (can(obj, name)) {
    return obj.cancelBooking(attributes);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function updateCheckInOut(obj, attributes, state) {
  const name = "updateCheckInOut";
  if (can(obj, name)) {
    return obj.updateCheckInOut(attributes, state);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function updateMultiCheckInOut(obj, attributesList, state) {
  const name = "updateMultiCheckInOut";
  if (can(obj, name)) {
    return obj.updateMultiCheckInOut(attributesList, state);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getBooking(obj, params) {
  const name = "getBooking";
  if (can(obj, name)) {
    return obj.getBooking(params);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getBookings(obj, params) {
  const name = "getBookings";
  if (can(obj, name)) {
    return obj.getBookings(params);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function queryCheckInOut(obj, uniqueId) {
  const name = "queryCheckInOut";
  if (can(obj, name)) {
    return obj.queryCheckInOut(uniqueId);
  } else {
    console.error(createErrorMessage(name))
  }
}

export function getTimeZone(obj) {
  const name = "getTimeZone";
  if (can(obj, name)) {
    return obj.getTimeZone();
  } else {
    console.error(createErrorMessage(name))
  }
}
