import React from "react";

import "moment/min/locales";
import * as antdLocales from '../locales-antd';

import Context from "../../../context/Context";
import { ModalController } from "../../../common/components/Modal";
import * as component from "../../../components/util/component";
import Button, { ButtonGroup } from "calcite-react/Button";
import Checkbox from "calcite-react/Checkbox";
import { DateRangePicker } from 'calcite-react/DatePicker'
import Panel, { PanelText, PanelTitle } from "calcite-react/Panel";
import Switch from 'calcite-react/Switch'
import { TimePicker, ConfigProvider } from "antd";
import moment from "moment";


let now;
let antdLocale;

export default class HotelAreaUsageChartSettings extends React.Component {
  
  constructor(props) {
    super(props);

    const htmlNode = document.getElementsByTagName("html")[0];
    const i18nLang = htmlNode && htmlNode.hasAttribute('lang') ? htmlNode.attributes['lang'].value : null;
    const dojoLocale = Context.getInstance().lib.dojo.kernel.locale;
    //console.debug("HotelAreaUsageChartSettings.ctor isRtl:", Context.getInstance().uiMode.isRtl, "language:", i18nLang, "dojoLocale:", dojoLocale);
    const locale = i18nLang || dojoLocale;
    antdLocale = antdLocales.getLocale(locale);
    //moment.locale(locale); not required since it is set in UIMode

    this.componentId = component.nextId();
    this.changed = false;
    now = new Date();

    const wdSt = moment(this.props.workDayStartTime || '2022-06-01 08:00:00');
    const wdEt = moment(this.props.workDayEndTime || '2022-06-01 17:00:00');
    const sTDisHrs = this._onChangeWorkdayEndTime(wdEt, null, true, wdSt).startTimeDisabledHours;
    const eTDisHrs = this._onChangeWorkdayStartTime(wdSt, null, true, wdEt).endTimeDisabledHours;

    this.state = component.newState({
      workWeek: this.props.workWeek || [1,2,3,4,5], //work week selected days 
      startDate: this.props.startDate ? moment(this.props.startDate).startOf("day") : (moment(now).subtract(1, "month")).startOf("day"),
      endDate: this.props.endDate ? moment(this.props.endDate).endOf("day") : moment(now).endOf("day"),
      workDayStartTime: wdSt,
      workDayEndTime: wdEt,
      drpFocusedInput: null,
      startTimeDisabledHours: sTDisHrs,
      endTimeDisabledHours: eTDisHrs,
      isAllDay: this.props.isAllDay,
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  //shouldComponentUpdate(nextProps) { return nextProps.startDate !== this.props.startDate ? true : false  }

  componentDidUpdate(prevProps, prevState) {
    //console.debug("HotelAreaUsageChartSettings - didUpdate", prevProps, prevState);
    this.changed = true;
    var isEqual  = function(obj1, obj2, prop, propOrfuncNm) {
      if (!prop || !obj1[prop] || !obj2[prop])
        return false; 
      if (propOrfuncNm)
        if (typeof obj1[prop][propOrfuncNm] === 'function')
          return obj1[prop][propOrfuncNm]() === obj2[prop][propOrfuncNm]();
        else 
          return obj1[prop][propOrfuncNm] === obj2[prop][propOrfuncNm];
      else
        return obj1[prop] === obj2[prop];
    }
    // compare the states
    if (!isEqual(this.state, prevState, 'startDate', 'valueOf')
          || !isEqual(this.state, prevState, 'endDate', 'valueOf')
          || !isEqual(this.state, prevState, 'workDayEndTime', 'valueOf')
          || !isEqual(this.state, prevState, 'workDayStartTime', 'valueOf')
          || JSON.stringify(this.state.workWeek.sort())!==JSON.stringify(prevState.workWeek.sort())
          || !isEqual(this.state, prevState, 'isAllDay')
    ) {
      //console.debug("HoteAreaUsageChartSettings state changed so calling parent onChange");
      this.props.onChange && this.props.onChange(this.state);
    }
  }


  selectButton(selected) {
    let newSels = [];
    if (this.state.workWeek.includes(selected))
      newSels = this.state.workWeek.filter(x=>x!==selected);
    else
      newSels = this.state.workWeek.concat(selected);
    this.setState({workWeek: newSels});
  }

  renderDateRange() {
    const minDate = this.props.minimumDate ? new moment(this.props.minimumDate) : new moment().subtract(2, 'years');
    //these props have no effect on the calcite/react/airbnb DateRangePicker
    //Warning: Failed prop type: React DateRangePicker: unknown props found: popupClassName, popupStyle, minimumDate, minDate
    //   >minimumDate={new moment().subtract(2, 'years')}  
    //   >minDate={new moment().subtract(2, 'years')}
    
    return (
      <Panel>
        {this.props.mode === 'all' ? <PanelTitle>Custom Date Range</PanelTitle> : ''}
        <PanelText>
    
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId={this.componentId+"_startDate"}
            endDate={this.state.endDate}
            endDateId={this.componentId+"_endDate"}
            focusedInput={this.state.drpFocusedInput}
            isRTL={false}
            onDatesChange={obj => { 
              //console.debug(obj.startDate.startOf('day'), obj.endDate && obj.endDate.endOf('day'));
              this.setState({startDate: obj.startDate.startOf('day'), endDate: obj.endDate && obj.endDate.endOf('day')});
            }}
            yearSelectDates={{ "startYear": minDate.year(), "endYear": new moment().year() }}
            monthYearSelectionMode="MONTH_YEAR"
            onFocusChange={(focusedInput) => {
              this.setState({drpFocusedInput: focusedInput}, ()=>{
                let el = document.querySelector(".DateRangePicker_picker");
                if (el) {
                  if (!el.style.zIndex)
                    el.style.setProperty("z-index", "10001", "important");
                  //else   el.style.setProperty("z-index", null);
                }
              });
            }}
            isOutsideRange = {dt => dt && dt.valueOf && minDate && minDate.valueOf && dt.valueOf() < minDate.valueOf()}
            appendToBody={true}
          />

        </PanelText>
      </Panel>
    )
  }

  _onChangeWorkdayStartTime = (time, timeString, isSkipState=false, workDayEndTime=null) => {                    
    //moment.hours() is 0 based at 12am with a caveat and goes upto 23 at midnight, so 8am is 8 and 8pm is 20
    const eDt = workDayEndTime || this.state.workDayEndTime;
    if (eDt && moment(time).add(1, "minutes").valueOf() >= eDt.valueOf())
      time = moment(eDt).subtract(1, "hours");
    time.set("second", 0);
    time.set("millisecond", 0);      
    let disHrs = [...Array(time.hours())].map((x,i)=> i);    
    if (time.minutes()>=30)
      disHrs.push(time.hours()); //disHrs = disHrs.filter(x => x!==time.hours()-1);
    const obj = { workDayStartTime: time, endTimeDisabledHours: disHrs };
    if (!isSkipState)
      this.setState(obj);
    return obj; 
  }
  _onChangeWorkdayEndTime = (time, timeString, isSkipState=false, workDayStartTime=null) => {    
    const stDt = workDayStartTime || this.state.workDayStartTime;
    if (stDt && moment(time).subtract(1, "minutes").valueOf() <= stDt.valueOf())
      time = moment(stDt).add(1, "hours");
    time.set("second", 0);
    time.set("millisecond", 0);
    let disHrs = [...Array(24-time.hours())].map((x,i)=> 23-i);      
    if (stDt && stDt.minutes()<=30)
      disHrs = disHrs.filter(x => x!==stDt.hours());
    const obj = { workDayEndTime: time, startTimeDisabledHours: disHrs };
    if (!isSkipState)
      this.setState(obj);
    return obj;
  }
  _disabledWorkdayStartTimes = (now) => {
    return {
      disabledHours: () => this.state.startTimeDisabledHours,
      disabledMinutes: (selectedHour) => {
        const endHr = this.state.workDayEndTime.hours();
        const endMin = this.state.workDayEndTime.minutes();
        if (selectedHour<endHr)
          return []; 
        return selectedHour===endHr && endMin>=30 ? [30] : [0, 30];
      },
    }
  }
  _disabledWorkdayEndTimes = (now) => {
    return {
      disabledHours: () => this.state.endTimeDisabledHours,
      disabledMinutes: (selectedHour) => {
        const startHr = this.state.workDayStartTime.hours();
        const startMin = this.state.workDayStartTime.minutes();                        
        if (selectedHour>startHr)
          return [];
        return selectedHour===startHr && startMin<30 ? [0] : [0, 30];
      },
    }
  }

  renderWorkSchedule() {
    const i18n = Context.instance.i18n;
    const locale = Context.getInstance().lib.dojo.kernel.locale;
    const styleTdDisabled = {color:'lightgray',cursor:'not-allowed'}
    const days = [i18n.calendars.sunAbbr, i18n.calendars.monAbbr, i18n.calendars.tuesAbbr, i18n.calendars.wedAbbr, i18n.calendars.thursAbbr, i18n.calendars.friAbbr, i18n.calendars.satAbbr];
    //const days = 'S,M,T,W,T,F,S'.split(',');    
    let format;
    if (locale === "en" || locale === "en-us") {
      format = "h:mm A"
    } else {
      format = "H:mm"
    }
    return (
      <Panel>
        {this.props.mode === 'all' ? <PanelTitle>Work schedule</PanelTitle> : ''}
        <div className="i-hotel-usage-settings-switch">
          <Switch labelPosition="before" checked={this.state.isAllDay} style={{margin: '0rem !important'}}
            onChange={(e)=> {
              //console.debug("isAllDay?", e.currentTarget.value, e.currentTarget.checked);
              this.setState({isAllDay: e.currentTarget.checked});
            }}
          >
            {i18n.spaceplanner.usageAnalytics.allDay}
          </Switch>          
        </div>
        <table>
          <tbody>
            <tr>
              <td style={this.state.isAllDay ? styleTdDisabled : undefined} className="i-hotel-usage-settings-td">
                {i18n.spaceplanner.usageAnalytics.workdayStart || "Work day starts"}
              </td>
              <td className="i-hotel-usage-settings-td">
                <TimePicker format={format}
                  minuteStep={30} showNow={false} inputReadOnly={false} 
                  size="large" popupClassName="i-time-picker-dropdown"
                  disabled={this.state.isAllDay} value={moment(this.state.workDayStartTime)}
                  onChange={this._onChangeWorkdayStartTime} disabledTime={this._disabledWorkdayStartTimes}/>
              </td>
            </tr>
            <tr>
              <td style={this.state.isAllDay ? styleTdDisabled : undefined} className="i-hotel-usage-settings-td">
                {i18n.spaceplanner.usageAnalytics.workdayEnd || "Work day ends"}
              </td>
              <td className="i-hotel-usage-settings-td">
                <TimePicker minuteStep={30} showNow={false} inputReadOnly={false} format={format}
                  size="large" popupClassName="i-time-picker-dropdown"
                  disabled={this.state.isAllDay} value={moment(this.state.workDayEndTime)}
                  onChange={this._onChangeWorkdayEndTime} disabledTime={this._disabledWorkdayEndTimes}/>
              </td>
            </tr>
            <tr>
              <td className="i-hotel-usage-settings-td">
                {i18n.spaceplanner.usageAnalytics.workWeek || "Work Week"}
              </td>
              <td className="i-hotel-usage-settings-td">
                <ButtonGroup isToggle={false}>
                  {days.map((d, i) =>
                      <Button key={i} clear={!this.state.workWeek.includes(i)} onClick={() => {this.selectButton(i)}}>{d}</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    )
  }

  render() {
    return (
      <ConfigProvider locale={antdLocale} direction={Context.getInstance().uiMode.isRtl}>
        <div className="i-hotel-usage-settings">
          { ["all", "date"].includes(this.props.mode) ? this.renderDateRange(): ""}
          { ["all", "work"].includes(this.props.mode) ? this.renderWorkSchedule(): ""}
          <div style={{display: "flex", flexDirection: "column" }}>        
          </div>
        </div>
      </ConfigProvider>
    )
  }  


  static showModal(title, mode, props, callback) {
    const i18n = Context.instance.i18n;
    let settingsData = null; 

    const onOK = () => {
      //console.debug('HotelAreaUsageChartSettings onOK', settingsData);
      typeof callback==='function' && settingsData && callback(settingsData);
    }

    const onChange = (data) => {
      //console.debug('HotelAreaUsageChartSettings onChange, previous:', settingsData, 'new:', data);
      settingsData = data;
    }

    const modalProps = {
      dialogStyle: { width: "35vw" },
      title: title || 'Area Usage Chart Settings',
      showOKCancel: true,
      closeOnOK: true,
      onOK: onOK,
      okLabel: i18n.general.ok
    };

    const content = (
      <HotelAreaUsageChartSettings 
        {...props}
        mode={mode}
        onChange={onChange} />
    );
    let controller = new ModalController({});
    controller.show(modalProps, content);
  }
}  
