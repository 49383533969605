import React from "react";
import Context from "../../context/Context";
import { PolygonGraphic } from "../components/Editor/footprints/FootprintVM";
import { getCapitalizedType, hasLayer, hasSites } from "../components/Editor/support/formUtil";
import { getSitesLayer } from "./sourceUtil";

export interface IFootprintValidity {
  sites: boolean,
  facilities: boolean
}
export const validateSitesAndFacilities = (): Promise<IFootprintValidity> => {
  const promises = [validateSites(), validateFacilities().then(v => v[0])];
  return Promise.all(promises).then(results => ({ sites: results[0], facilities: results[1] }));
}

export const getValidationMessage = (isPost: boolean, validity: IFootprintValidity) => {
  const { i18n: { editor } } = Context.getInstance();
  const message = editor.footprints.invalidMsg;
  const submessage = editor.footprints.invalidSubMsg;
  const siteMessage = editor.sites.errOverlapsOtherFootprint;
  const facilityMessage = [editor.facilities.errOverlapsOtherFootprint, 
    editor.facilities.errNotWithinSite];
  // const siteLabel = getCapitalizedType("site");
  const hasSitesLayer = getSitesLayer() != null;
  return (
    <>
      <p>{message}</p>
      <dl>
        {hasSitesLayer && !validity.sites && <dt>{"Site"}</dt>}
        {hasSitesLayer && !validity.sites &&
          <dd>
            <ul>
              <li>{editor.sites.errOverlapsOtherFootprint}</li>
            </ul>
          </dd>
        }
        {!validity.facilities && <dt>{"Facility"}</dt>}
        {!validity.facilities &&
          <dd>
            <ul>
              <li>{facilityMessage[0]}</li>
              {hasSitesLayer && <li>{facilityMessage[1]}</li>}
            </ul>
          </dd>
        }
      </dl>
      {isPost && <p>{submessage}</p>}
    </>
  );
}

/** Validates site geometries and returns `true` if no sites overlap (or no sites exist).
 * @param [graphic] - Optional polygon graphic to compare against other sites.
 */
export const validateSites = async (graphic?: PolygonGraphic) => {
  const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
  const { views: { floorFilter: { activeWidget: { viewModel } } } } = Context.getInstance();
  const hasSitesLayer = getSitesLayer() != null;
  const features: { sites: { sitesInfo: { geometry: __esri.Geometry }[] } } = viewModel.get("filterFeatures");
  const allSites = features?.sites?.sitesInfo.map(f => f.geometry) || [];

  if (!(hasSitesLayer && hasSites)) return true;

  // compare other sites against an input geometry
  if (graphic?.geometry) {
    const overlaps = graphic?.geometry && allSites
      ? allSites.some(g => engine.overlaps(graphic.geometry, g) || engine.within(graphic.geometry, g))
      : false;
    return !overlaps && graphic?.geometry.rings[0]?.length > 3 ? true : false;
  } else {
    const overlaps = allSites.length
      ? allSites.some(site => {
        const others = allSites.filter(other => other !== site);
        return others.some(other => engine.overlaps(site, other) || engine.within(site, other));
      })
      : false;
    return !overlaps;
  }
}

/** Validates facility geometries and returns `true` if all are valid. A valid facilitiy is one
 * that is within a site (if sites exist) and does not overlap other facilities.
 * @param [graphic] - Optional polygon graphic to compare against other facilities.
 * @returns 
 */
export const validateFacilities = async (graphic?: PolygonGraphic): Promise<[boolean, boolean]> => {
  const engine: typeof __esri.geometryEngine = Context.getInstance().lib.esri.geometryEngine;
  const { views: { floorFilter: { activeWidget } } } = Context.getInstance();
  const hasSitesLayer = hasLayer("site");
  const features: {
    facilities: { facilitiesInfo: { geometry: __esri.Geometry }[] },
    sites: { sitesInfo: { geometry: __esri.Geometry }[] }
  } = activeWidget.viewModel.get("filterFeatures");
  const allFacilities = features.facilities.facilitiesInfo.map(f => f.geometry) || [];

  // compare other facilities against an input geometry
  if (graphic?.geometry) {
    const overlaps = graphic?.geometry && allFacilities.length
      ? allFacilities.some(g => engine.overlaps(graphic.geometry, g))
      : false;
    // sites layer in the map
    if (hasSitesLayer) {
      // @ts-ignore
      const site = activeWidget.viewModel.getSite(activeWidget.site);
      // ensure input geometry is inside a site
      const within = graphic?.geometry && site?.geometry
        ? engine.within(graphic.geometry, site.geometry)
        : false;
      return [within && !overlaps && graphic?.geometry.rings[0]?.length > 3 ? true : false, overlaps];
    } else {
      return [!overlaps && graphic?.geometry.rings[0]?.length > 3 ? true : false, overlaps];
    }
  } else {
    const allSites = hasSitesLayer && hasSites() ? features?.sites?.sitesInfo.map(f => f.geometry) || [] : [];
    const siteGeometry = allSites.length ? engine.union(allSites) : null;
    // compare each facility with all other facilities
    let overlaps: boolean = false;
    let outside: boolean = false;
    const overlapsOrNotWithin = allFacilities.length
      ? allFacilities.some(facility => {
        const others = allFacilities.filter(other => other !== facility);
        if (others.some(other => engine.overlaps(facility, other))) {
          overlaps = true;
          return overlaps;
        } else if (hasSitesLayer && !engine.within(facility, siteGeometry)) {
          outside = true;
          return outside;
        }
      })
      : false;
    return [!(overlaps || outside), overlaps];
  }
}
