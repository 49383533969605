import React from "react";

import AppLaunch from "./AppLaunch";
import AppSection from "./AppSection";
import Context from "../../../context/Context";
import Icons from "../../util/Icons";
import IndoorPositioning from "./IndoorPositioning";
import Kiosk from "./Kiosk";
import Logo from "./Logo";
import Measure from "./Measure";
import ModalController from "../../common/Modal/ModalController";
import NativeApp from "./NativeApp";
import NetworkServiceSection from "./NetworkServiceSection";
import PrintLayout from "./PrintLayout";
import SaveAs from "./SaveAs";
import Scalebar from "./Scalebar";
import Step from "./Step";
import Theme from "./Theme";
import Topic from "../../../context/Topic";
import WebMapSection from "./WebMapSection";
import WebSceneSection from "./WebSceneSection";
import * as component from "../../util/component";
import OfficeHoteling from "./OfficeHoteling";
import AboutSection from "./AboutSection";
import Office365 from "../More/Actions/BookWorkspace/WorkspaceReservation/Office365";
import * as workspaceReservationUtil from "../More/Actions/BookWorkspace/WorkspaceReservation/workspaceReservationUtil";
import UpdateWorkspaceReservation from "../../../aiim/datasets/UpdateWorkspaceReservation";

const CSS = {
  configurator: "i-configurator",
  configuratorExpanded: "i-configurator-expanded",
  panelHeader: "i-panel-header",
  panelHeaderTools: "i-panel-header-tools",
  title: "i--title",
  expandButton: "i-button-clear-no-border"
};

export default class Configurator extends React.Component {

  cfg;

  constructor(props) {
    super(props);
    const context = Context.instance;
    const configuration = context.configuration

    let expanded = false;
    if (context.uiMode.modeIsHorizontalSplit() || context.uiMode.modeIsVerticalSplit()) {
      expanded = true;
      document.body.classList.add(CSS.configuratorExpanded);
    }

    const initialConfigurables = configuration.extractConfigurables();
    const views = context.views;
    const webmapItem = views && views.mapView &&
      views.mapView.map && views.mapView.map.portalItem;
    const websceneItem = views && views.sceneView &&
      views.sceneView.map && views.sceneView.map.portalItem;

    const aiim = Context.instance.aiim;
    const routeServiceInfo = (aiim.routeService && aiim.routeService.info) || {}
    const closestFacilityInfo = (aiim.closestFacilityService &&
      aiim.closestFacilityService.info) || {};

    this.cfg = {
      activeStepKey: "step-1",
      initialConfigurables: initialConfigurables,
      appItem: configuration.appItem,
      webmapItem: webmapItem,
      websceneItem: websceneItem,
      theme: Context.instance.config.theme,
      routeService: {
        item: routeServiceInfo.item,
        serviceInfo: routeServiceInfo.serviceInfo,
        layerName: routeServiceInfo.layerName
      },
      closestFacilityService: {
        item: closestFacilityInfo.item,
        serviceInfo: closestFacilityInfo.serviceInfo,
        layerName: closestFacilityInfo.layerName
      },
      getConfigurable: (name) => {
        return context.config[name]
      },
      setConfigurable: (name,value) => {
        context.config[name] = value;
      }
    }

    Context.instance.session.invalidWorkspaceConfig = false;
    //console.log("Configurator.cfg",this.cfg);
    this.state = component.newState({
      expanded: expanded
    });
    this.cancelClicked = this.cancelClicked.bind(this);
    this.minimizeClicked = this.minimizeClicked.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
    this.previousClicked = this.previousClicked.bind(this);
    this.saveAsClicked = this.saveAsClicked.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  activeStepNumber() {
    // step keys are named "step-n"
    return Number(this.cfg.activeStepKey.substring(5));
  }

  cancelClicked() {
    const chkUrl = () => {
      const lib = Context.instance.lib;
      const url = lib.esri.urlUtils.urlToObject(window.location.href);
      if (url && url.query && url.query.edit === "true") {
        delete url.query.edit;
        const href = lib.esri.urlUtils.addQueryParameters(url.path,url.query);
        window.history.replaceState({},"",href);
      }
    };
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    const initial = this.cfg.initialConfigurables;
    const current = configuration.extractConfigurables();
    const a = JSON.stringify(initial);
    const b = JSON.stringify(current);
    if (a !== b) {
      const modalController = new ModalController();
      const okClicked = () => {
        Object.assign(Context.instance.config,initial);
        Topic.publish(Topic.ApplyTheme,{
          theme: Context.instance.config.theme
        });
        Topic.publish(Topic.ConfigurationDiscarded,{});
        Topic.publish(Topic.ReloadViews,{});
        modalController.close();
        Context.instance.uiMode.toggleConfigurator(false);
        Topic.publish(Topic.ConfiguratorClosed,{});
        chkUrl();
      };
      const cnclClicked = () => {
        modalController.close();
      }
      const content = (
        <div key="cfg-configurator-cancel-popup" className={CSS.formGroup}>
          <div className={"i-separated"}>{i18n.configurator.discardPrompt}</div>
          <div key="actions" className={"i-actions i-text-right"}>
            <button key="cancel" type="button"
              className={"i-button i-button-clear i-margin-all-small"}
              onClick={cnclClicked}>{i18n.general.cancel}</button>
            <button key="ok" type="button"
              className={"i-button i-margin-all-small"}
              onClick={okClicked}>{i18n.general.discard}</button>
          </div>
        </div>
      );
      modalController.show(content,i18n.configurator.caption,"i-configurator-modal");
    } else {
      Context.instance.uiMode.toggleConfigurator(false);
      Topic.publish(Topic.ConfiguratorClosed,{});
      chkUrl();
    }
  }

  componentDidMount() {
    this._focus();
    new UpdateWorkspaceReservation()
    document.getElementById("view-container").classList.add("i--skeleton");
    component.own(this,[
      Topic.subscribe(Topic.Configurator_RenderSteps,() => {
        component.refresh(this);
      }),
      Topic.subscribe(Topic.Configurator_SetExpanded,(params) => {
        this.setExpanded(true);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
    document.getElementById("view-container").classList.remove("i--skeleton");
  }


  _focus() {
    const btn = document.querySelector("button[data-i-stepkey='step-1']");
    if (btn && btn.focus) btn.focus();
  }

  minimizeClicked() {
    this.toggleExpanded();
    const btn = document.querySelector("button[data-i-configurator-minview-button='true']");
    if (btn && btn.focus) btn.focus();
  }

  nextClicked() {
    const num = this.activeStepNumber();
    const numSteps = this.numSteps();
    if (num < numSteps) {
      this.cfg.activeStepKey = "step-" + (num + 1);
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

  numSteps() {
    return 6;
  }

  previousClicked() {
    const num = this.activeStepNumber();
    if (num > 1) {
      this.cfg.activeStepKey = "step-" + (num - 1);
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const user = Context.instance.user;
    const cfg = this.cfg;
    const appItem = Context.instance.configuration.appItem;
    //const title = appItem && appItem.title;
    const title = i18n.configurator.caption;
    const hasMapView = !!Context.instance.views.mapView;
    const activeStepKey = cfg.activeStepKey;
    const isFirst = (activeStepKey === "step-1");
    const isLast = (activeStepKey === "step-6"); // update if more steps are added
    let cls;
    const hasIssues = Context.instance.session.isWebSceneIssues;

    let leftIcon = Icons.left();
    let rightIcon = Icons.right();
    if (Context.instance.uiMode.isRtl) {
      let tmp = leftIcon;
      leftIcon = rightIcon;
      rightIcon= tmp;
    }

    const expanded = this.state.expanded;
    const expandTip = expanded ? i18n.general.collapse : i18n.general.expand;

    const stepNav = this.renderStepNavigation(hasMapView, hasIssues);

    const saveDisabled = !hasMapView || hasIssues;
    const canSave = user.canEditPortalItem(appItem);
    const canSaveAs = user.canCreateAppItem(appItem);

    let saveButton = null, saveAsButton = null;
    // className={"i-button i-button-clear"}
    if (canSave) {
      saveButton = (
        <button className={"i-button i-margin-all-small"} disabled={saveDisabled}
          onClick={this.saveClicked}>{i18n.configurator.save}</button>
      );
    }
    if (canSaveAs) {
      cls = "i-button i-margin-all-small";
      if (canSave) cls += " i-button-clear"
      saveAsButton = (
        <button className={cls} disabled={saveDisabled}
          onClick={this.saveAsClicked}>{i18n.configurator.saveAs}</button>
      );
    }

    let minimizeButton = (
      <button className={"i-button-clear-no-border i--minimize"}
        onClick={this.minimizeClicked} title={i18n.general.minimize}
        data-i-configurator-minimize-button="true">
        {Icons.minus()}
      </button>
    );

    /*
    <a className={CSS.expandButton} href={hashref} role="button"
      title={expandTip} onClick={this.toggleExpanded}>
      {expandIcon}
    </a>
     */
    let expandCollapseIcon = expanded ? leftIcon : rightIcon;
    let expandCollapseButton = (
      <button className={"i-button-clear-no-border i--expandcollapse"}
        onClick={this.toggleExpanded} title={expandTip}>
        {expandCollapseIcon}
      </button>
    );

    let cancelButton = (
       <button className={"i-button-clear-no-border"}
        onClick={this.cancelClicked} title={i18n.general.close}>
        {Icons.X()}
      </button>

    );

    cls = "i-button i-button-gray i--previous"; // "i-link"
    let prevDisabled = isFirst;
    let prevStyle = isFirst ? {visibility: "hidden"} : {visibility: "visible"};
    let previousButton = (
      <button className={cls} disabled={prevDisabled} style={prevStyle}
        onClick={this.previousClicked} title={i18n.configurator.previous}>
        {leftIcon}
      </button>
    );
    let nextDisabled = isLast || !hasMapView || hasIssues;
    let nextStyle = isLast ? {visibility: "hidden"} : {visibility: "visible"};
    let nextButton = (
      <button className={"i-button i-button-gray i--next"} disabled={nextDisabled}
        style={nextStyle}
        onClick={this.nextClicked} title={i18n.configurator.next}>
        {rightIcon}
      </button>
    );

/*
<div key="save-section" className={"i--save-section"}>
  {saveButton}
  {saveAsButton}
</div>
<div key="actions" className="i-configurator-actions">
    {stepNav}
    <div className={"i--back-next"}>
      {previousButton}
      {nextButton}
    </div>
</div>
 */
    return (
      <div key="configurator" className={CSS.configurator}>
        <div key="top">
          <div key="hdr" className={CSS.panelHeader + " i-flex-between"} style={{marginTop:"1rem"}}>
            <span key="title" className={CSS.title}>{title}</span>
            <div key="hdrTools" className={CSS.panelHeaderTools} style={{display:"flex"}}>
              {minimizeButton}
              {expandCollapseButton}
              {cancelButton}
            </div>
          </div>
          <div key="nav" className={"i-configurator-nav"}>
            {previousButton}{stepNav}{nextButton}
          </div>
        </div>
        <div key="steps" className="i-configurator-steps">
          <Step stepKey="step-1" cfg={cfg}>
            <AppSection cfg={cfg} />
            <WebMapSection cfg={cfg}/>
            <WebSceneSection cfg={cfg} />
            <NetworkServiceSection cfg={cfg} isRoute={true} />
            <NetworkServiceSection cfg={cfg} isClosestFacility={true} />
          </Step>
          <Step stepKey="step-2" cfg={cfg}>
            <Measure cfg={cfg}/>
            <Scalebar cfg={cfg}/>
            <PrintLayout cfg={cfg}/>
            <NativeApp cfg={cfg}/>
            <IndoorPositioning cfg={cfg}/>
            <OfficeHoteling cfg={cfg}/>
            <AboutSection cfg={cfg}/>
          </Step>
          <Step stepKey="step-3" cfg={cfg}>
            <AppLaunch cfg={cfg}/>
          </Step>
          <Step stepKey="step-4" cfg={cfg}>
            <Kiosk cfg={cfg}/>
          </Step>
          <Step stepKey="step-5" cfg={cfg}>
            <Logo cfg={cfg}/>
          </Step>
          <Step stepKey="step-6" cfg={cfg}>
            <Theme cfg={cfg}/>
          </Step>
        </div>
        <div key="actions" className="i-configurator-actions i-text-right">
          {saveAsButton}
          {saveButton}
        </div>
      </div>
    );
  }

  renderStepNavigation(hasMapView, hasIssues) {
    const cfg = this.cfg;
    const activeStepKey = cfg.activeStepKey;
    const numSteps = this.numSteps();
    const nodes = [];
    for (let i=1; i<=numSteps; i++) {
      let key = "step-"+i;
      let active = (key === activeStepKey);
      let cls = "";
      if (active) cls = "i--active";
      let disabled = (i > 1 && !hasMapView) || (i > 1 && hasIssues);
      let node = (
        <button key={key} type="button" className={cls} disabled={disabled}
          data-i-stepkey={key} onClick={this.stepClicked}
          >{Icons.ellipse()}</button>
      )
      nodes.push(node);
    }
    return (
      <div key="stepNav" className={"i-configurator-stepnav"}>{nodes}</div>
    );
  }

  saveAsClicked() {
    const i18n = Context.instance.i18n;
    SaveAs.showModal({
      cfg: this.cfg,
      onSaveCliked: (metaInfo,folderId,sharingInfo,modalController) => {
        const configuration = Context.instance.configuration;
        configuration.saveAppItem(metaInfo,folderId,sharingInfo,true).then(response => {
          //console.log("Configurator.saveAsClicked.response",response);
          //component.refresh(this);
          this.cfg.initialConfigurables = configuration.extractConfigurables();
          modalController.close();
        }).catch(ex => {
          modalController.close();
          ModalController.showMessage(i18n.messages.errorSavingItem,
            metaInfo.title,"i-configurator-modal");
          console.error("Error saving item",ex);
        });

        // // *** can't get the ownerFolder from a search
        // const title = metaInfo.title;
        // const owner = Context.instance.user.getUsername();
        // portalUtil.checkForExistingTitle(title,owner,folderId).then(response => {
        //   console.log("Configurator.checkForExistingTitle.response",response);
        //   //return configuration.saveAppItem(metaInfo,folderId,true);
        // }).then((response) => {
        //   console.log("Configurator.saveAsClicked.response",response);
        //   component.refresh(this);
        //   modalController.close();
        // }).catch(ex => {
        //   // TODO show error
        //   console.error("Error saving item",ex);
        //   modalController.close();
        // });
      }
    });
  }

  saveClicked() {
    const metaInfo  = {};
    const i18n = Context.instance.i18n;
    const configuration = Context.instance.configuration;
    const appItem = configuration.appItem;
    const itemId = appItem && appItem.id;

    const hotelingAppId = configuration.extractConfigurables().workspaceReservation.appId
    const tenantId = configuration.extractConfigurables().workspaceReservation.tenantId;
    if (!itemId) return;
    configuration.saveAppItem(metaInfo).then(response => {
      //console.log("Configurator.saveClicked.response",response);
      this.cfg.initialConfigurables = configuration.extractConfigurables();
      component.refresh(this);
      Office365.getInstance().updateAppId(hotelingAppId)
      Office365.getInstance().updateTenantId(tenantId)
      Topic.publish(Topic.AppIdUpdated, {})
      
      const validConfigInfo = workspaceReservationUtil.getValidationInfo();
      let msg = i18n.configurator.configurationSaved;

      if(!validConfigInfo.isValidHotel || !validConfigInfo.isValidMeetingRooms) {
        msg = msg + "<br/>" + i18n.configurator.officeHoteling.invalidWorkspaceReservation;
        Topic.publish(Topic.UpdateHotelingConfig, {});
      }
    
      Topic.publish(Topic.ShowToast,{
        message: msg,
        dangerouslySetInnerHTML: true
      });

    }).catch(ex => {
      // TODO show error
      ModalController.showMessage(i18n.messages.errorSavingItem,
        appItem.title,"i-configurator-modal");
      console.error("Error saving item",ex);
    });
  }

  setExpanded(expanded) {
    this.setState(state => {
      return {
        expanded: expanded
      };
    });
    if (expanded) {
      document.body.classList.add(CSS.configuratorExpanded);
    } else {
      document.body.classList.remove(CSS.configuratorExpanded);
    }
  }

  stepClicked = (evt) => {
    let key;
    if (evt && evt.target) {
      key = evt.target.getAttribute("data-i-stepkey");
    }
    if (key) {
      this.cfg.activeStepKey = key;
      Topic.publish(Topic.Configurator_RenderSteps,{});
    }
  }

  toggleExpanded() {
    const expanded = !this.state.expanded;
    this.setExpanded(expanded);
  }

}
