import React, { Component } from "react";
import { connect } from "react-redux";
import Rdx from "../../../redux/Rdx";

// Styled Components
import { Container } from "../../styles/Work/tabPanel";

class TabPanel extends Component {
  render() {
    const active = this.props.rdxActiveTabKey === this.props.tabKey;
    const fullWidth = this.props.fullWidth || false;
    return active ? <Container active={active} fullWidth={fullWidth}>{this.props.children}</Container> : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    rdxActiveTabKey: Rdx.getValue(state, ownProps.navKey)
  };
};

export default connect(mapStateToProps)(TabPanel);
