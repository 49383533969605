import React from 'react';
import { ChromePicker } from 'react-color';

export default class ColorPicker extends React.Component {
  state = {
    displayColorPicker:false,
  };

  handleClick=()=>{
    this.setState({
      displayColorPicker:! this.state.displayColorPicker
    })
  }

  handleClose = () => {
   this.setState({ displayColorPicker: false })
 };

  render() {
    return (
      <div>
        <ChromePicker
            color={ this.props.color }
            onChangeComplete={ this.props.handleChangeComplete }
        />
      </div>
    );
  }
}
