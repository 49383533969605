import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Popup = styled.div`
  z-index: ${props => props.theme.levels.popup};
`;

export { Container, Popup };
