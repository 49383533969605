// Framework and third-party non-ui
import React, { Component } from "react";
import {connect} from "react-redux";

// Redux operations and local helpers/utils/modules
import Context from "../../../context/Context";
import Rdx from "../../../redux/Rdx";
import { getActiveSection } from "../../redux";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";

// Component specific modules (Component-styled, etc.)
import {
  Container,
  SideNav,
  TabPanelContainer
} from "../../styles/Assets/assetBar";

// App components
import LayersPanel from "./LayersPanel";
import LegendPanel from "./LegendPanel";
import PeoplePanel from "./PeoplePanel";
import TabButton from "./TabButton";
import TabPanel from "./TabPanel";
import UnitsPanel from "./UnitsPanel";

// Third-party components (buttons, icons, etc.)
import Skeleton from 'react-loading-skeleton';
import LayersIcon from "calcite-ui-icons-react/LayersIcon";
import LegendIcon from "calcite-ui-icons-react/LegendIcon";
import PeopleIcon from "calcite-ui-icons-react/UsersIcon";
import UnitsIcon from "calcite-ui-icons-react/FloorPlanIcon";

class AssetBar extends Component {
  constructor(props) {
    super(props);
    this.state = component.newState({
      hasPlan: false
    });
  }

  componentDidMount() {
    component.own(this, [
      Topic.subscribe(Topic.PlanOpened,params => {
        this.setState({ hasPlan: true });
      }),
      Topic.subscribe(Topic.ReloadViews, params => {
        this.setState({ hasPlan: false });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const appSection = this.props.rdxActiveAppSection;
    if (appSection === "plan") {
      return this.renderPlanSection();
    } else if (appSection === "review") {
      return null;
    } else if (appSection === "publish") {
      return null;
    } else {
      return null;
    }
  }

  renderPlanSection() {
    const { hasPlan } = this.state;
    const i18n = Context.instance.i18n;
    const navKey = Rdx.Keys.SPACEPLANNER_ACTIVE_ASSETBAR_SECTION;
    const panelOpenClassName = "i--assetbar-panel-open";

    return (
      <Container>
        <div key="panels">
          {hasPlan && (
            <>
            <TabPanel key="people" navKey={navKey} tabKey="people">
              <PeoplePanel
                navKey={navKey}
                panelKey="spaceplanner/assetbar/PeoplePanel"
                sourceKey="People"
                layerCaption={i18n.spaceplanner.assets.people.title}
                filterTooltip={i18n.spaceplanner.assets.people.filterTooltip}
              />
            </TabPanel>

            <TabPanel key="units" navKey={navKey} tabKey="units">
              <UnitsPanel
                navKey={navKey}
                panelKey="spaceplanner/assetbar/UnitsPanel"
                sourceKey="Units"
                layerCaption={i18n.spaceplanner.assets.units.title}
                filterTooltip={i18n.spaceplanner.assets.units.filterTooltip}
              />
            </TabPanel>
            <TabPanel key="legend" navKey={navKey} tabKey="legend">
              <LegendPanel
                navKey={navKey}
                panelKey="spaceplanner/assetbar/LegendPanel"
                sourceKey="Units"
                layerCaption={i18n.spaceplanner.assets.legend.title}
              />
            </TabPanel>
            <TabPanel key="layers" navKey={navKey} tabKey="layers">
              <LayersPanel
                navKey={navKey}
                panelKey="spaceplanner/assetbar/LayersPanel"
                sourceKey="Layers"
                layerCaption={i18n.spaceplanner.assets.layers.title}
              />
            </TabPanel>
            </>
          )}
          </div>

        <SideNav>
          <TabPanelContainer>
            <div key="top" className="i--top">
              {hasPlan ? (
                <>
                  <TabButton
                    key="units"
                    tabKey="units"
                    navKey={navKey}
                    panelOpenClassName={panelOpenClassName}
                    title={i18n.spaceplanner.assets.units.title}
                  >
                    <UnitsIcon />
                  </TabButton>

                  <TabButton
                    key="people"
                    tabKey="people"
                    navKey={navKey}
                    panelOpenClassName={panelOpenClassName}
                    // title={i18n.spaceplanner.assets.people.title}
                    title={i18n.spaceplanner.popup.assignments}
                  >
                    <PeopleIcon />
                  </TabButton>
                </>
              ) : <Skeleton count={2} circle={true} height={36} width={36} />}
            </div>
            <div key="bottom" className="i--bottom">
              {hasPlan ? (
                <>
                  <TabButton
                    key="legend"
                    tabKey="legend"
                    navKey={navKey}
                    panelOpenClassName={panelOpenClassName}
                    title={i18n.spaceplanner.assets.legend.title}
                  >
                    <LegendIcon />
                  </TabButton>
                  <TabButton
                    key="layers"
                    tabKey="layers"
                    navKey={navKey}
                    panelOpenClassName={panelOpenClassName}
                    title={i18n.spaceplanner.assets.layers.title}
                  >
                    <LayersIcon />
                  </TabButton>
                </>
              ) : <Skeleton count={2} circle={true} height={36} width={36} />}
            </div>
          </TabPanelContainer>
        </SideNav>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  rdxActiveAppSection: getActiveSection(state)
})

export default connect(mapStateToProps)(AssetBar);
