import React from "react";
import Context from "../../../context/Context";
import {
  Fieldset,
  FormControl,
  Legend
} from "calcite-react/Form";
import GroupSearch from "../../../components/common/GroupSearch/GroupSearch";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import OrganizationIcon from "calcite-ui-icons-react/OrganizationIcon";
import GlobeIcon from "calcite-ui-icons-react/GlobeIcon";
import Radio from "calcite-react/Radio";
import { SharingLevels } from "../../../common/components/Sharing";

export default class ShareWith extends React.Component {

  static defaultProps = {
    disabled: false,
    disallowEveryone: true,
    horizontal: true,
    shareWith: SharingLevels.PRIVATE
  }

  constructor(props) {
    super(props);

    let shareWith = this.props.shareWith || SharingLevels.PRIVATE;
    if (this.props.private) {
      shareWith = SharingLevels.PRIVATE;
    } else if (this.props.organization) {
      shareWith = SharingLevels.ORGANIZATION;
    }
    this.state = {
      shareWith : shareWith,
      assignedGroups: this.props.assignedGroups
    }
  }

  _onChange(shareWith, shareIds, unShareIds) {
    this.setState({shareWith})
    if (this.props.onChange) {
      this.props.onChange(shareWith, shareIds, unShareIds);
    }
  }
  _renderGroups() {
    const { assignedGroups, shareWith } = this.state;
    const { groups, horizontal, disabled} = this.props;
    if (!groups) return null;

    if (shareWith === SharingLevels.ORGANIZATION) {
      return (
        <FormControl horizontal={horizontal} className="i-share-with-group">
          <GroupSearch
            onShareGroup={(e, shareIds, unShareIds) => {
            }}
            onUnshareGroup={(e, unShareIds, shareIds) => {
            }}
            groups={[]}
            assignedGroups={assignedGroups}
            horizontal={horizontal}
            disabled={true} />
        </FormControl>
      ); 
    }

    return (
      <FormControl horizontal={horizontal} className="i-share-with-group">
        <GroupSearch
          onShareGroup={(e, shareIds, unShareIds) =>
            this._onChange(SharingLevels.SHARED, shareIds, unShareIds)}
          onUnshareGroup={(e, unShareIds, shareIds) =>
            this._onChange(shareWith, shareIds, unShareIds)}
          groups={groups}
          assignedGroups={assignedGroups}
          horizontal={horizontal}
          disabled={disabled} />
      </FormControl>
    );    
  }
  render() {
    const { shareWith } = this.state;
    const { horizontal, disabled, disallowEveryone, organization } = this.props;
    const ctx = Context.getInstance();    
    const i18n = ctx.i18n;
    const isVersioned = ctx.spaceplanner.planner.project.isVersioned || false;
    return (
      <>
        <FormControl horizontal={horizontal} className="i-share-with">
          <Fieldset name="sharingOptions">
            <Legend className="i--form-label">
              {i18n.configurator.appItem.shareWith.caption}
              <Radio
                disabled={disabled}
                value={SharingLevels.PRIVATE}
                defaultChecked={this.props.private != null
                  ? this.props.private
                  : shareWith === SharingLevels.PRIVATE}
                onChange={() => this._onChange(SharingLevels.PRIVATE)}>
                <UserIcon size={16} />
                {i18n.configurator.appItem.shareWith.private}
              </Radio>
              <Radio
                disabled={disabled}
                value={SharingLevels.ORGANIZATION}
                defaultChecked={organization != null ? organization : shareWith === SharingLevels.ORGANIZATION}
                onChange={() => this._onChange(SharingLevels.ORGANIZATION)}>
                <OrganizationIcon size={16} />
                {i18n.configurator.appItem.shareWith.organization}
              </Radio>
              {
                // TODO: revisit whether the isVersioned check is necessary
              }
              {!disallowEveryone && !isVersioned && (
                <Radio
                  disabled={disabled}
                  value={SharingLevels.PUBLIC}
                  defaultChecked={this.props.public != null
                    ? this.props.public
                    : shareWith === SharingLevels.PUBLIC}
                  onChange={() => this._onChange(SharingLevels.PUBLIC)}
                >
                  <GlobeIcon size={16} />
                  {i18n.configurator.appItem.shareWith.everyone}
                </Radio>
              )}

            </Legend>
          </Fieldset>
        </FormControl>

        {this._renderGroups()}
      </>
    );
  }
}