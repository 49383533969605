import React from "react";

import Context from "../../../context/Context";
import HelpTip from "../../common/HelpTip/HelpTip";
import ItemBrowser from "../../common/ItemBrowser/ItemBrowser";
import ModalController from "../../common/Modal/ModalController";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as configUtil from "./configUtil";

const CSS = {
  section: "i-form-group i-section",
  formGroup: "i-form-group",
  formGroupHeader: "i-form-group-header",
  formGroupName: "i-form-group-name",
  required: "i--required",
  issues: "i-issues",
  issue: "i-issue",
  actions: "i-actions i-text-right",
  primaryAction: "i-button",
  //secondaryAction: "i-button i-button-clear",
  secondaryAction: "i-button-clear-no-border i-font-brand-primary"
};

export default class WebSceneSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.browseClicked = this.browseClicked.bind(this);
    this.removeClicked = this.removeClicked.bind(this);
    this.isLayerIssues();
  }

  isLayerIssues=()=>{
    const aiim = Context.instance.aiim;
    const datasets = aiim && aiim.datasets;
    if (Context.instance.views.sceneView) {
      if (datasets && datasets.units && datasets.units.layer2D) {
        if (!datasets.units.layer3D) {
          Context.instance.session.isWebSceneIssues = true;
        }
      }
      if (datasets && datasets.facilities && datasets.facilities.layer2D) {
        if (!datasets.facilities.layer3D) {
          Context.instance.session.isWebSceneIssues = true;
        }
      }
    }
  }

  browseClicked() {
    const controller = new ModalController();
    const props = {
      title: Context.instance.i18n.configurator.primary.webscene,
      itemType: "Web Scene",
      rdxKey: "cfg-webscene",
      onSelect: (item) => {
        const cfg = this.props.cfg;
        cfg.websceneItem = item;
        cfg.setConfigurable("webscene",item.id);
        Topic.publish(Topic.ReloadViews,{startIn3D: true});
        controller.close();
      }
    }
    const content = (
      <ItemBrowser itemType={props.itemType}
        rdxKey={props.rdxKey} onSelect={props.onSelect} />
    );
    controller.show(content,props.title,"i-configurator-modal");
  }

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.ReloadViews,params => {
        Context.instance.session.isWebSceneIssues = false;
        this.setState(state => {
          return {
            viewsReloading: true
          };
        });
      }),
      Topic.subscribe(Topic.ViewsReloaded,params => {
        this.isLayerIssues();
        this.setState(state => {
          return {
            viewsReloading: false
          };
        });
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const context = Context.instance;
    const i18n = context.i18n;
    const cfg = this.props.cfg;
    const item = cfg.websceneItem;
    const disabled = !Context.instance.views.mapView;

    let browseLabel = i18n.configurator.add;
    let itemTitleNode = null, removeButton = null;
    if (item) {
      browseLabel = i18n.configurator.change;
      const itemTitle = (item && item.title) || "";
      itemTitleNode = (
        <div key="itemTitle">{itemTitle}</div>
      );
      removeButton = (
        <button type="button" className={CSS.secondaryAction}
          onClick={this.removeClicked}>{i18n.configurator.remove}</button>
      );
    }

    const issues = this.renderIssues();
    const detailsLink = configUtil.renderDetailsLink(item);

    return (
      <div className={CSS.section}>
        <div key="header" className={CSS.formGroupHeader}>
          <span className={CSS.formGroupName}
            >{i18n.configurator.primary.webscene}</span>
          <HelpTip text={i18n.configurator.primary.websceneHelp}/>
        </div>
        {itemTitleNode}
        {issues}
        <div key="actions" className={CSS.actions}>
          <button type="button" className={CSS.secondaryAction}
            disabled={disabled}
            onClick={this.browseClicked}>{browseLabel}</button>
          {removeButton}
          {detailsLink}
        </div>
      </div>
    );
  }

  renderIssues() {
    if (this.state.viewsReloading) return null;
    const i18n = Context.instance.i18n;
    const aiim = Context.instance.aiim;
    const datasets = aiim && aiim.datasets;
    let list = [];

    const append = (k,s) => {
      list.push(
        <div key={k} className={CSS.issue}>{s}</div>
      );
    };

    if (Context.instance.views.sceneView) {
      if (datasets && datasets.units && datasets.units.layer2D) {
        if (!datasets.units.layer3D) {
          append("noUnits",i18n.configurator.issues.noUnits);
        }
      }
      if (datasets && datasets.facilities && datasets.facilities.layer2D) {
        if (!datasets.facilities.layer3D) {
          append("noFacilities",i18n.configurator.issues.noFacilities);
        }
      }
    }

    if (list.length > 0) {
      return (
        <div key="issues" className={CSS.issues}>
          {list}
        </div>
      );
    }
    return null;
  }

  removeClicked() {
    const cfg = this.props.cfg;
    cfg.websceneItem = null;
    cfg.setConfigurable("webscene",null);
    Context.instance.session.isWebSceneIssues = false;
    Topic.publish(Topic.ReloadViews,{});
  }

}
