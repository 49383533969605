import React from "react";
import moment from "moment";

import { getAttributeValue } from "../../../../../aiim/util/aiimUtil";
import { getAttributes } from "../../../../../spaceplanner/components/common/MultipleAssignments/multipleAssignmentsUtil";
import {
  getBookingSchedule,
  bookWorkspace,
  getBooking,
  getTimeZone,
  updateBooking,
  checkAvailability
} from "./WorkspaceReservation/OfficeHotelingInterface";
import { isNetworkError } from "../../../../../util/networkUtil";
import { ModalController } from "../../../../../common/components/Modal";
import { formatRecurringDates, getHotelBookingSystemType, getMeetingBookingSystemType } from "./WorkspaceReservation/workspaceReservationUtil";
import { verifyMultipleBookings } from "./BookWorkspaceUtil";
import { escSqlQuote } from '../../../../../aiim/util/selectionUtil';
import Warning from "../../../../../common/components/Warning/Warning";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import Context from "../../../../../context/Context";
import Topic from "../../../../../context/Topic";
import * as selectionUtil from "../../../../../aiim/util/selectionUtil";
import * as dateUtil from "../../../Events/dateUtil";
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";
import {stringFormatter} from "../../../../../../src/util/formatUtil";
import AreasTable from "../../../../../spaceplanner/base/AreasTable";
import Office365 from "./WorkspaceReservation/Office365";
import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleFIcon";
import XCircleFIcon from "calcite-ui-icons-react/XCircleFIcon";
import QuestionFIcon from 'calcite-ui-icons-react/QuestionFIcon';
import InformationIcon from 'calcite-ui-icons-react/InformationIcon';
import {
  IBookingStatus,
  IBookingTask,
  IEsriBookingParams,
  IEsriBookingTask,
  IOffice365BookingTask,
  IWorkspaceAreaConfig
} from "./WorkspaceReservation/BookingSystem";
import { ScheduleInformation, Event } from "@microsoft/microsoft-graph-types";
import { SpaceAssignmentTypes } from "../../../../../spaceplanner/base/OfficePlan";
import { getRecurringDates, IRecurrenceOptions } from "./BookingRecurrence";

const PENDING_APPROVAL = 0;
const CANCELED = 3;

export async function verify(task: IBookingTask) {
  task.makeBooking = false;
  return execute(task);
}

export async function verifyAndBook(task: IBookingTask) {
  if (task.operation && task.operation === "updateBookingTime") {
    task.updateBooking = true;
  } else {
    task.makeBooking = true;
  }
  return execute(task);
}

export async function execute(task: IBookingTask & IBookingStatus) {
  if (task.options && task.options.recurrence && !task.options.recurrence.type) {
    // @todo is this change correct?
    task.options.recurrence = null;
  }
  const bookingType = task && task.bookingType;
  try {
    task.ok = true;
    task.warnings = [];
    if (task.ok && task.bookingSystem) await checkLimits(task);
    if (task.ok) await checkUnit(task);
    if (task && task.bookingSystem && task.bookingSystem.type === 'esri') {
      if (task && task.canReserveInfo && task.canReserveInfo.objectIdsToCancel
          && task.canReserveInfo.objectIdsToCancel.length === 1) {
        makeAutoCancelUpdates(task);
      }
      if (task.ok && bookingType === "hotel") {
        if (!Context.instance.aiim.isHotelMeetingRoom(task.unit)) await verifyMultipleBookings1(task);
      }
    } else {
      let response;
      if (task.ok && bookingType === "hotel") {
        if (task.options.recurrence?.enabled && task.options.recurrence.modified !== "occurrence") {
          const recurringDates = task.bookingSystem.getRecurringDates(task.options.recurrence, moment(task.checkInDate), moment(task.checkOutDate));
          for await (const date of recurringDates) {
            const info: IOffice365BookingTask = {
              ...task as IOffice365BookingTask,
              checkInDate: (date.start).toDate(),
              checkOutDate: (date.end).toDate()
            }

            if (task?.updateBooking) info.eventId = (task as IOffice365BookingTask)?.eventId;
            response = await info.bookingSystem.verifyMultipleBookings(info);
            if (response === "hasExistingBookings") {
              break;
            }
          }
        } else {
          response = await (task.bookingSystem as Office365).verifyMultipleBookings(task as IOffice365BookingTask);
        }
        if(response === "hasExistingBookings") {
          const i18n = Context.getInstance().i18n;
          addWarning(task, {
            title: i18n.more.bookWorkspace.multipleBookingTitle,
            msg: i18n.more.bookWorkspace.multipleBookingsMessage
          })
          task.hasBookingConflict = true;
        }
      }
    }
    if (!task.ok) await checkError(task);
    else task.checksSuccessful = true;
    let response = null;

    if (task.ok && task.makeBooking) {
      response = await makeBooking(task);
    } else if (task.ok && task.updateBooking) {
      await updateExistingBooking(task);
    }

    // if (type && type === "office365") pollNewEvent(response, task);
    return task;
  } catch (error) {
    console.error(error);
    task.ok = false;
    task.error = error;
    await checkError(task);
  }
}

async function updateExistingBooking (task: IBookingTask) {
  const options = task.options;
  const bookingType = task && task.bookingType;
  const type = getBookingSystemType(bookingType);

  const i18n = Context.getInstance().i18n;
  const modalOptions = {
    title: i18n.more.bookWorkspace.multipleBookingTitle,
    message: i18n.more.bookWorkspace.multipleBookingsMessage,
    showOKCancel: true,
    hideCancel: true,
    okLabel: i18n.general.ok
  }
  if (options && options.reserveForInfo && options.reserveForInfo.username) {
    const occupant = options.reserveForInfo.occupantFeature;
    let name = occupant && aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
    if (!name) name = options.reserveForInfo.fullName;
    // if(!name) 
    const template = i18n.more.bookWorkspace.bookingOtherConflict;
    modalOptions.message = stringFormatter(template, { person: name });
  }
  switch (type) {
    case "office365":
      await updateBookingOffice365(task as IOffice365BookingTask);
      break;
    case "esri":
      await updateBookingEsri(task as IEsriBookingTask);
      break;
    default:
      return;
  }
}

async function updateBookingEsri(task: IEsriBookingTask) {
  const options = task.options as IEsriBookingParams;

  const params: IEsriBookingParams = {
    objectIds: task.objectIds,
    unit: task.unit,
    checkIn: task.checkInDate as Date,
    checkOut: task.checkOutDate as Date,
    allDay: task.allDay,
    operation: "updateBookingTime",
    item: task.item,
    autoCancelUpdates: task.autoCancelUpdates,
    origReservation: task && task.reservation,
    bookingForOther: task && task.bookingForOther,
    recurrence: options.recurrence,
    series: task.series
  };
  if (options && options.others && options.others.length > 0) {
    params.others = options.others;
  }
  if (task && task.bookingType === "hotel") params.bookingType = "hotel";
  else if (task && task.bookingType === "meetingRooms") {
    params.bookingType = "meetingRooms";
    params.title = task && task.title;
    params.description = task && task.description
  }

  const params2: IEsriBookingParams = {
    unit: task.unit,
    checkIn: task.checkInDate as Date,
    checkOut: task.checkOutDate as Date,
    allDay: task.allDay,
    item: task.item,
    isUpdateBooking: true,
    forLock: true,
    state: PENDING_APPROVAL,
    reservationObjectIds: task.objectIds,
    recurrence: options.recurrence
  };

  const lockData = await bookWorkspace(task.bookingSystem, params2);
  let data;
  try {
    data = await updateBooking(task.bookingSystem, params);
    task.bookingSuccessful = true;
    if (task && task.bookingType === "hotel") {
      Topic.publish(Topic.RenderBookingList, {});
    } else if (task.bookingType === "meetingRooms" && data && "reservations" in data) {
      task.reservation = data.reservations && data.reservations.length > 0 && data.reservations[0];
    }
  } finally {
    try {
      const reservationDataset = Context.instance.aiim.datasets.reservations;
      const layer = reservationDataset && reservationDataset.layer2D;
      const objectIdField = layer.objectIdField;
      const oids = lockData.reservations.map(r => r.attributes[objectIdField]);
      await task.bookingSystem.deletePseudoLock(oids);
      if (data && task?.bookingType === "hotel") {
        task.renderBookingConfirmed(data, params, task);
      }
    } catch (error) {
      console.error("Error deleting pseudo lock", error);
    }
  }

}

async function updateBookingOffice365 (task: IOffice365BookingTask) {
  const checkInDate = task.checkInDate;
  const checkOutDate = task.checkOutDate;
  let timezone = await getTimeZone(task.bookingSystem);
  if(timezone === undefined || timezone === null) timezone = "UTC";

  const startDate = Context.instance.lib.dojo.locale.format(checkInDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  })
  const startTime = Context.instance.lib.dojo.locale.format(checkInDate, {
    selector: "time",
    timePattern: "HH:mm:ss"
  })
  const endDate = Context.instance.lib.dojo.locale.format(checkOutDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  })
  const endTime = Context.instance.lib.dojo.locale.format(checkOutDate, {
    selector: "time",
    timePattern: "HH:mm:ss"
  })

  const startDateTime = startDate + "T" + startTime;
  const endDateTime = endDate + "T" + endTime;
  const event: any = {
    start: {
        dateTime: startDateTime,
        timeZone: timezone
    },
    end: {
        dateTime: endDateTime,
        timeZone: timezone
    }
  };

  if (
    task.bookingType === "hotel" &&
    task.bookingSystem.type === "office365" &&
    task.booking?.attendees.length) {
    event.attendees = task.booking.attendees;
  }
  if(task.bookingType === "meetingRooms" && task.title) {
    event.subject = task.title;  
  }
  if (task.options?.recurrence?.enabled && task.options.recurrence.modified !== "occurrence") {
    event.recurrence = Office365.getRecurrence(task.options, checkInDate);
  }
  const params = {
    event: event,
    eventId: task?.options?.recurrence && task?.seriesMasterId ? task.seriesMasterId : task.eventId
  }
  const response = await updateBooking(task.bookingSystem, params);
  if(response){
    task.bookingSuccessful = true;
    if(task.bookingType === "meetingRooms")  Topic.publish(Topic.RenderMeetingListOffice365, {});
    else Topic.publish(Topic.RenderBookingListOffice365, {});
    task.renderBookingConfirmed(false, false, task);
  } else {
    // @To-do
    throw new Error("Office365 Client did not return a response");
  }
}

async function makeBooking (task: IBookingTask) {
  const options = task.options;
  const bookingType = task && task.bookingType;
  const type = getBookingSystemType(bookingType);

  const i18n = Context.getInstance().i18n;
  const modalOptions = {
    title: i18n.more.bookWorkspace.multipleBookingTitle,
    message: i18n.more.bookWorkspace.multipleBookingsMessage,
    showOKCancel: true,
    hideCancel: true,
    okLabel: i18n.general.ok
  }
  if (options && options.reserveForInfo && options.reserveForInfo.username) {
    const occupant = options.reserveForInfo.occupantFeature;
    const name = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
    const template = i18n.more.bookWorkspace.bookingOtherConflict;
    modalOptions.message = stringFormatter(template, { person: name });
  }
  switch (type) {
    case "office365":
      return await makeBookingOffice365(task as IOffice365BookingTask);
    case "esri":
      await makeBookingEsri(task as IEsriBookingTask);
      break;
    default:
      return;
  }
}

function pollNewEvent(response, task) {
  const promise = new Promise<void>((resolve, reject)=> {
    let count = 0;
    if(response){
      const eventId = response && response.id;
      const params = {
        eventId: eventId
      }

      const checkStatus =async()=> {
        count++;
        const result = await getBooking(task.bookingSystem, params)
        const extension = response && response.extensions && response.extensions[0];
        const scheduleEmail = extension && extension.scheduleEmail;
        const status = Office365.getEventStatus(result, scheduleEmail).status;
        return status;
      }

      const finish =(interval)=> {
        clearInterval(interval);
        resolve();
      }

      const interval = setInterval(async ()=> {
        try {
          if (count === 6) {
            finish(interval);
          }
          const status = await checkStatus();
          if (status === "accepted" || status === "declined") {
            finish(interval);
            if (status === "accepted") Topic.publish(Topic.ShowToast,{message: "Accepted Booking Request"});
          }
        } catch(error) {
          console.error(error);
          finish(interval);
        }
      }, 10000); 
    }
  })
  return promise;
}

function makeBookingOffice365(task: IOffice365BookingTask) {
  const checkInDate = task.checkInDate;
  const checkOutDate = task.checkOutDate;
  const scheduleEmail = task.scheduleEmail;
  const unitName = task.unitName;
  const bookingType = task.bookingType;
  const params: any = {
    email: scheduleEmail,
    unitName,
    checkInDate,
    checkOutDate,
    item: task.item,
    recurrence: task.options.recurrence,
    bookingType: bookingType
  }
  if (bookingType === "meetingRooms") {
    params.title = task.title;
    params.description = task.description;
  }
  return bookWorkspace(task.bookingSystem, params)
  .then((response) => {
    if(response){
      task.bookingSuccessful = true;
      task.renderBookingConfirmed(false, false, task);
      return response;
    } else {
      // @To-do
      throw new Error("Office365 Client did not return a response");
    }
  })
}

function makeBookingEsri (task: IEsriBookingTask) {
  const checkInDate = task.checkInDate;
  const checkOutDate = task.checkOutDate;
  const allDay = task.allDay;
  const options = task.options as IEsriBookingParams;
  const autoCancelUpdates = task && task.autoCancelUpdates;

  const params: IEsriBookingParams = {
    title: task.title,
    description: task.description,
    bookingType: task.bookingType,
    noAutoDescription: true,
    unit: task.unit,
    checkIn: checkInDate as Date,
    checkOut: checkOutDate as Date,
    allDay: allDay,
    item: task.item,
    autoCancelUpdates: autoCancelUpdates,
    recurrence: options.recurrence
  };
  if (options && options.others && options.others.length > 0) {
    params.others = options.others;
  }

  return bookWorkspace(task.bookingSystem, params).then((data) => {
    task.bookingSuccessful = true;
    Topic.publish(Topic.RenderBookingList, {});
    task.renderBookingConfirmed(data, params, task);
  })
}

function addWarning(task: IBookingTask & IBookingStatus, info: { msg: string, title: string }) {
  if (!task.warnings.some(e => e.msg === info.msg && e.title === info.title)) {
    task.warnings.push({
      msg: info.msg,
      title: info.title
    })
  }
  task.ok = false;
}

export async function checkLimits (task: IBookingTask & IBookingStatus) {

  let checkInDate = task.checkInDate as Date;
  let checkOutDate = task.checkOutDate as Date;

  let maxBookingsPerPerson = null, maxDaysPerBooking = null, maxHoursPerBooking = null, maxDaysInAdvance = null;
  const unitAttributes = task.unit.attributes;
  const areaIdVal = aiimUtil.getAttributeValue(unitAttributes, FieldNames.AREA_ID);
  const asnType: SpaceAssignmentTypes = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE);
  const limits: IWorkspaceAreaConfig = await getLimits(areaIdVal);
  task.areaConfigByAreaId = {
    [areaIdVal]: limits
  }
  if (limits) {
    if (asnType === SpaceAssignmentTypes.hotel) {
      maxBookingsPerPerson = limits.hotel.maxBookingsPerPerson;
      maxDaysPerBooking = limits.hotel.maxDaysPerBooking;
      maxDaysInAdvance = limits.hotel.maxDaysInAdvance;
    } else if (asnType === SpaceAssignmentTypes.meetingroom) {
      maxBookingsPerPerson = limits.meetingRoom.maxBookingsPerPerson;
      maxHoursPerBooking = limits.meetingRoom.maxHoursPerBooking;
      maxDaysInAdvance = limits.meetingRoom.maxDaysInAdvance; 
    }
  }

  if (asnType === SpaceAssignmentTypes.hotel) {
    if (!maxBookingsPerPerson && !maxDaysPerBooking && !maxDaysInAdvance) {
      task.ok = true;
      return;
    }
  } else if (asnType === SpaceAssignmentTypes.meetingroom) {
    if (!maxBookingsPerPerson && !maxHoursPerBooking && !maxDaysInAdvance) {
      task.ok = true;
      return;
    }
  }

  const currentDateTime = new Date().getTime();
  const i18n = Context.getInstance().i18n;

  const checkTimeSlot = async (start: Date, end: Date, index: number, recurringBookingCount?: number) => {
    const startEndDiff = end.getTime() - start.getTime();

    if (maxDaysInAdvance) {
      // Viewer should allow user to book an anytime partial day booking on the last day of the limit #8145
      const maxStartMillis = moment(currentDateTime).add(maxDaysInAdvance,"day").endOf("day").valueOf();
      if (start.getTime() > maxStartMillis) {
        let msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxAdvDays.replace('{limit}', maxDaysInAdvance);
        if (maxDaysInAdvance === 1) msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxAdvDaysSingular.replace('{limit}', 1);
        addWarning(task, {
          msg: msg,
          title: i18n.more.bookWorkspace.limitHotelScheduling.caption
        })
        task.hasBookingConflict = true;
      }
    }

    if (asnType === SpaceAssignmentTypes.hotel) {
      if (maxDaysPerBooking && (startEndDiff > (maxDaysPerBooking * 24 * 3600000))) {
        let msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxDays.replace('{limit}', maxDaysPerBooking);
        if (maxDaysPerBooking === 1) msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxDaysSingular.replace('{limit}', 1);
        addWarning(task, {
          msg: msg,
          title: i18n.more.bookWorkspace.limitHotelScheduling.caption
        })
        task.hasBookingConflict = true;
      }
    } else if (asnType === SpaceAssignmentTypes.meetingroom) {
      if (maxHoursPerBooking && (startEndDiff > (maxHoursPerBooking * 3600000))) {
        let msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxHours.replace('{limit}', maxHoursPerBooking);
        if (maxHoursPerBooking === 1) msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsMaxHoursSingular.replace('{limit}', 1);
        addWarning(task, {
          msg: msg,
          title: i18n.more.bookWorkspace.limitHotelScheduling.caption
        })
        task.hasBookingConflict = true;
      }
    }

    if (maxBookingsPerPerson && task.bookingSystem.type === 'esri' && index === 0) {
      let exceeded = false;
      const isMultiDate = (recurringBookingCount ?? 1) > 1;
      let currentBookings = await countCurrentBookingsByUsername(task, maxBookingsPerPerson, asnType, isMultiDate);
      if (task.operation === "updateBookingTime") {
        const seriesId = task.series?.id;
        if (seriesId && isMultiDate) {
          const seriesBookings = await countCurrentBookingsByUsername(task, maxBookingsPerPerson, asnType, isMultiDate, seriesId);
          currentBookings = (currentBookings - seriesBookings);
          if ((currentBookings + (recurringBookingCount ?? 1)) > maxBookingsPerPerson) exceeded = true;
        }
      } else {
        if ((currentBookings + (recurringBookingCount ?? 1)) > maxBookingsPerPerson) exceeded = true;
      }
      if (exceeded) {
        let msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsBkgsPerPerson.replace('{limit}', maxBookingsPerPerson);
        if (maxBookingsPerPerson === 1) msg = i18n.more.bookWorkspace.limitHotelScheduling.exceedsBkgsPerPerson.replace('{limit}', 1);
        addWarning(task, {
          msg: msg,
          title: i18n.more.bookWorkspace.limitHotelScheduling.caption
        })
        task.hasBookingConflict = true;
      }
    }
  }
  if (task?.options?.recurrence?.enabled && task.options.recurrence.modified !== "occurrence") {
    const recurringDates = task.bookingSystem.getRecurringDates(task.options.recurrence, moment(checkInDate), moment(checkOutDate));
    await Promise.all(recurringDates.map((dates,i) => {
      return checkTimeSlot(dates.start.toDate(), dates.end.toDate(), i, recurringDates.length);
    }));
  } else {
    await checkTimeSlot(checkInDate, checkOutDate, 0);
  }
  return task;
}

async function countCurrentBookingsByUsername(taskObj: IBookingTask, maxBookingsPerPerson: number, asnType: string, isMultiDate: boolean, seriesId?: string) {
  const options = taskObj.options;
  let count = 0;
  const unitAttributes = taskObj.unit.attributes;
  const areaIdVal = aiimUtil.getAttributeValue(unitAttributes, FieldNames.AREA_ID);
  const lib = Context.getInstance().lib;

  const zuluCurrentTime = dateUtil.getZulu(new Date());
  let reservedForUsername = Context.instance.user.getUsername();
  if (options && options.reserveForInfo && options.reserveForInfo.username) {
    reservedForUsername = options.reserveForInfo.username;
  }

  const reservations = Context.instance.aiim.datasets && Context.instance.aiim.datasets.reservations;
  const startTimeField = reservations.startTimeField;
  const endTimeField = reservations.endTimeField;
  const stateField = reservations.stateField;
  let reservationUrl = reservations.url;
  const usernameField = reservations.reservedForUsernameField;

  if (!reservationUrl) return;
  reservationUrl = Context.checkMixedContent(reservationUrl);
  let task = new lib.esri.QueryTask({url: reservationUrl});
  let query = new lib.esri.Query();
  query.outFields = ["*"];
  query.returnGeometry = false;
  query.returnZ = true;
  query.orderByFields = [startTimeField];
  let w3;
  w3 = "(("+ usernameField + " = '" + selectionUtil.escSqlQuote(reservedForUsername) + "')";
  w3 += " AND ("+stateField+" IN (0,1,4)))"; // pending, approved, checked-in
  w3 += " AND (("+startTimeField + " >= TIMESTAMP '" + zuluCurrentTime+"')";
  w3 += " OR ("+endTimeField + " >= TIMESTAMP '" + zuluCurrentTime+"'))";

  const reservationsByUnitId = {};
  let reservationFeatureCount = 0;
  query.where = w3;
  const result = await task.execute(query);
  let unitIds = [];
  if(result && result.features && result.features.length > 0) {
    result.features.forEach(feature => {
      const unitId = getAttributeValue(feature.attributes, FieldNames.UNIT_ID);
      let seriesOK = true;
      if (seriesId) {
        const recurrenceId = getAttributeValue(feature.attributes, FieldNames.RECURRENCE_ID);
        if (seriesId !== recurrenceId) seriesOK = false;
      }
      if(unitId && seriesOK) {
        reservationFeatureCount++
        if (reservationsByUnitId[unitId]) {
          reservationsByUnitId[unitId].push(feature);
        } else {
          reservationsByUnitId[unitId] = [feature];
          unitIds.push(unitId);
        }
      }
    })
  }
  if(!unitIds || unitIds.length === 0) {
    return count;
  }
  if (!isMultiDate && (reservationFeatureCount < maxBookingsPerPerson)) {
    return reservationFeatureCount;
  }
  let values = [];
  unitIds.forEach(id => {
    let v = "'"+selectionUtil.escSqlQuote(id)+"'";
    values.push(v);
  });

  const units = Context.instance.aiim.datasets && Context.instance.aiim.datasets.units;
  const unitIdField = aiimUtil.findFieldName(units.layer2D.fields,FieldNames.UNIT_ID)
  const areaIdField = aiimUtil.findFieldName(units.layer2D.fields,FieldNames.UNITS_AREA_ID)
  const asnTypeField = aiimUtil.findFieldName(units.layer2D.fields,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE)
  let unitsUrl = units.url;
  
  unitsUrl = Context.checkMixedContent(unitsUrl);
  task = new lib.esri.QueryTask({url: unitsUrl});
  query = new lib.esri.Query();
  query.outFields = ["*"];
  query.returnGeometry = false;
  query.returnZ = true;
  let w = "(" + unitIdField + " IN (" + values.join(",") + "))";
  w += " AND ("+ areaIdField + " = '" + selectionUtil.escSqlQuote(areaIdVal) + "')";
  w += " AND ("+ asnTypeField + " = '" + selectionUtil.escSqlQuote(asnType) + "')";
  query.where = w;
  const unitResults = await task.execute(query);
  if (unitResults && unitResults.features && unitResults.features.length > 0) {
    const idx = {};
    unitResults.features.forEach((unitFeature)=> {
      const unitId = getAttributeValue(unitFeature.attributes, FieldNames.UNIT_ID);
      if (unitId && !idx[unitId]) {
        idx[unitId] = true;
        const features = reservationsByUnitId[unitId];
        if (features) {
          count = count + features.length;
        }
      }
    })
  }
  return count;
}

async function getLimits(areaId: string): Promise<IWorkspaceAreaConfig> {
  const areas = Context.getInstance().aiim.datasets && Context.getInstance().aiim.datasets.areas;
  let result;

  if (areas && areas.url) {
    const lib = Context.getInstance().lib;
    const url = areas.url;
    const task = new lib.esri.QueryTask({ url });
    const query = new lib.esri.Query();
    query.outFields = ["*"];
    query.returnGeometry = true;
    query.where = `${FieldNames.AREA_ID} = '${escSqlQuote(areaId)}'`;
    result = await task.execute(query);
  }

  if (result && result.fields && result.features && result.features.length > 0) {
    const feature = result.features[0];
    return AreasTable.getConfig(feature);
  }
}

async function verifyMultipleBookings1(task: IBookingTask & IBookingStatus) {
  const checkIn = task.checkInDate;
  const checkOut = task.checkOutDate;
  const i18n = Context.getInstance().i18n;
  const checkResponse = (response: string) => {
    if (response === "hasExistingBookings") {    
      addWarning(task, {
        title: i18n.more.bookWorkspace.multipleBookingTitle,
        msg: i18n.more.bookWorkspace.multipleBookingsMessage
      })
      task.hasBookingConflict = true;
    }
  }

  if (task?.options?.recurrence?.enabled && task.options.recurrence.modified !== "occurrence") {
    const recurringDates = task.bookingSystem.getRecurringDates(task.options.recurrence, moment(checkIn), moment(checkOut));
    return Promise.all(recurringDates.map(dates => {
      return verifyMultipleBookings(dates.start.toDate(), dates.end.toDate(), task.options, task);
    })).then((responses: string[]) => {
      responses.forEach(checkResponse);
    });
  } else {
    return verifyMultipleBookings(checkIn, checkOut, task.options, task).then(checkResponse);
  }
}

function getBookingSystemType (bookingType) {
  if (bookingType === "hotel") return getHotelBookingSystemType();
  else if (bookingType === "meetingRooms") return getMeetingBookingSystemType();
}

async function checkUnit (task: IBookingTask){
  const bookingType = task?.bookingType;
  const type = getBookingSystemType(bookingType);
  switch (type) {
    case "office365":
      return await checkUnitOffice365(task as IOffice365BookingTask);
    case "esri":
      return await checkUnitEsri(task as IEsriBookingTask);
    default:
      return;
  }
}

async function checkUnitOffice365(task: IOffice365BookingTask & IBookingStatus) {
  const { bookingSystem, bookingType, checkInDate, checkOutDate, scheduleEmail, options } = task;
  const checkIn = (checkInDate as Date).toISOString();
  const checkOut = (checkOutDate as Date).toISOString();
  const results = await checkAvailability(bookingSystem, [scheduleEmail], checkIn, checkOut, null, options) as ScheduleInformation[];
  let available = true;
  if (task?.updateBooking && (task as IOffice365BookingTask).seriesMasterId) {
    const seriesMasterId = task?.seriesMasterId;
    const bookingDateFilter = {
      dateStart: options?.recurrence?.startDate,
      dateEnd: options?.recurrence?.endDate
    }
    const bookings = await bookingSystem.getBookingSeries(seriesMasterId, bookingType, bookingDateFilter);
    // each result represents a single time slot;
    available = results?.length > 0 && results.every(schedule => (schedule && isUnitAvailable(schedule, task, bookings)));
  } else {
    // each result represents a single time slot
    available = results?.length > 0 && results.every(schedule => (schedule && isUnitAvailable(schedule, task)));
  }

  if (available === false) {
    const i18n = Context.getInstance().i18n;
    let title = i18n.more.bookWorkspace.workspaceUnavailableTitle;
    title = title.replace("{unitName}", task.unitName);
    let errorMessage = i18n.more.bookWorkspace.workspaceUnavailable;
    errorMessage = errorMessage.replace("{unitName}", task.unitName);
    addWarning(task, {
      msg: errorMessage,
      title: title
    });
    task.hasBookingConflict = true;
  }
}

function makeAutoCancelUpdates(task: IBookingTask) {
  const reservationDataset = Context.instance.aiim.datasets.reservations;
  const layer = reservationDataset && reservationDataset.layer2D;
  if (!layer) return;
  const objectIdField = layer.objectIdField;
  const updates = [];

  task.canReserveInfo.objectIdsToCancel.forEach((objectId) => {
    const update = { attributes: {} };
    update.attributes[objectIdField] = objectId;
    update.attributes[reservationDataset.stateField] = CANCELED;
    updates.push(update);
  });

  if (updates && updates.length > 0) {
    task.autoCancelUpdates = updates;
  }
}

async function checkUnitEsri(task: IEsriBookingTask & IBookingStatus) {
  const attributes = getAttributes(task.unit);
  const unitId = getAttributeValue(attributes, FieldNames.UNIT_ID);
  const checkInDate = task.checkInDate;
  const checkOutDate = task.checkOutDate;
  const allDay = task.allDay;
  const hotelUnits = [task.unit];
  const areaConfigByAreaId = task.areaConfigByAreaId;
  const operation = task.operation;
  const objectIds = task.objectIds;
  const recurrence = task.options.recurrence;
  const params: IEsriBookingParams = {
    checkInDate,
    checkOutDate,
    unitId,
    task,
    hotelUnits,
    areaConfigByAreaId,
    operation,
    objectIds,
    recurrence
  };
  return getBookingSchedule(task.bookingSystem, params).then((availableUnits: string[]) => {
    if (availableUnits.length === 1 && availableUnits[0] === unitId) {
    } else {
      let errorMessage = "";
      if (recurrence?.enabled) {
        // we don't currently identify the specific conflict within the series so use the first actual date in
        // the series instead of the checkIn/checkOut date (since 1st occurrence might not be on the checkIn date)
        const dates = getRecurringDates(recurrence, moment(checkInDate), moment(checkOutDate));
        errorMessage = getUnitUnavailableMessage(dates[0]?.start?.toDate() ?? checkInDate, dates[0]?.end?.toDate() ?? checkInDate, allDay, recurrence);
      } else {
        errorMessage = getUnitUnavailableMessage(checkInDate, checkOutDate, allDay);
      }
      const i18n = Context.getInstance().i18n;
      let title = i18n.more.bookWorkspace.workspaceUnavailableTitle;
      title = title.replace("{unitName}", task.unitName);
      errorMessage = errorMessage.replace("{unitName}", task.unitName);
      addWarning(task, {
        msg: errorMessage,
        title: title
      });
      task.hasBookingConflict = true;
    }
  })
}

export function isUnitAvailable(unit: ScheduleInformation, task?: Pick<IBookingTask, "updateBooking" | "origBooking">, origBookings?: Event[]) {
  const availability = [...(unit.availabilityView || [])];
  let isAvailable = availability.length && availability.every(a => a === "0") ? true : false;
  const busyItems = unit.scheduleItems?.filter(x => x.status === "busy") || [];
  if (!isAvailable && task?.updateBooking && busyItems.length) {
    // if there are multiple busy items then consider it a conflict
    if (busyItems.length === 1) {      
      const busy = busyItems[0];
      if (origBookings?.length > 0) {
        const original = origBookings.find(x => moment(x.start.dateTime).isSame(busy.start.dateTime)
          && moment(x.end.dateTime).isSame(busy.end.dateTime));
        if (original) {
          isAvailable = true;
        }
      } else {
        const origBkgStart = task.origBooking?.fromDate;
        const origBkgEnd = task.origBooking?.toDate;
        const busyDate = dateUtil.O365toDate(busy.start.dateTime, busy.end.dateTime)
        if (moment(origBkgStart).isSame(busyDate.fromDateTime) && moment(origBkgEnd).isSame(busyDate.toDateTime)) {
          isAvailable = true;
        }
      }
    }
  }
  return isAvailable;
}


function getUnitUnavailableMessage(checkInDate: string | Date, checkOutDate: string | Date, allDay: boolean, recurrence?: IRecurrenceOptions) {
  const i18n = Context.instance.i18n;
  const lib = Context.instance.lib;

  const today = lib.dojo.locale.format(new Date(), { selector:"date", formatLength: "short" });
  const startDate = lib.dojo.locale.format(checkInDate, { selector: "date", formatLength: "short" });
  let endDate = lib.dojo.locale.format(checkOutDate, { selector: "date", formatLength: "short" });

  const locale = Context.instance.lib.dojo.kernel.locale
  const format = (locale === "en" || locale === "en-us") ? "h:mm A" : "H:mm";

  const startTime = lib.dojo.locale.format(checkInDate, { selector: "time", format: format });
  const endTime = lib.dojo.locale.format(checkOutDate, { selector: "time", format: format});

  let msg: string;
  if (recurrence?.enabled === true && recurrence.modified !== "occurrence") {
    const recurrenceLabel = formatRecurringDates(recurrence, moment(checkInDate), moment(checkOutDate), allDay);
    // FIXME i18n - Need a new string for recurring series message (see #7887)
    const template = i18n.more.bookWorkspace.noAvailableUnitsAllDay;
    msg = stringFormatter(template, { duration: "", day: recurrenceLabel });
    msg = msg.substring(0, msg.lastIndexOf(recurrenceLabel) + recurrenceLabel.length);
  } else if (allDay) {
    let dur, day
    endDate = moment(checkOutDate).subtract(1, 'm').toDate();
    endDate = lib.dojo.locale.format(endDate, { selector: "date", formatLength: "short" });

    if (startDate === today && endDate === today) {
      // Available today all day
      dur = ""
      day = i18n.more.bookWorkspace.today
    } else if (startDate === endDate) {
      // Ex: Available on 7/22/21 all day
      dur = i18n.more.bookWorkspace.on
      day = startDate
    } else {
      // Ex: Available from 7/22/21 - 7/23/21 all day
      dur = i18n.more.bookWorkspace.from
      day = startDate + " - " + endDate
    }
    const template = i18n.more.bookWorkspace.noAvailableUnitsAllDay;
    msg = stringFormatter(template, { duration: dur, day });
  } else {
    let dur;
    let dateString = null;
    if (startDate === endDate) {
      if (startDate === today) {
        // Ex: Available today, 5:00 PM to 6:00 PM
        dateString = i18n.more.bookWorkspace.today;
        dur = "";
      } else {
        // Ex: Available on 7/23/21, 5:00 PM to 6:00 PM
        dateString = startDate
        dur = i18n.more.bookWorkspace.on
      }
    } else {
        // Ex: Available from 7/22/21, 5:00 PM to 7/23/21, 6:00 PM
        dur = i18n.more.bookWorkspace.from
    }

    const template = dateString
      ? i18n.more.bookWorkspace.noAvailableUnitsSameDay
      : i18n.more.bookWorkspace.noAvailableUnits

    msg = stringFormatter(template, {
      duration: dur,
      date: dateString,
      startDate,
      startTime,
      endDate,
      endTime
    });
  }
  return msg;
}

export async function showServerErrorPopup (retry) {
  const i18n = Context.getInstance().i18n;
  const hasRetry = typeof retry === 'function';

  const options = {
    title: i18n.more.bookWorkspace.error,
    message: i18n.more.bookWorkspace.bookHotelsError,
    okLabel: hasRetry? i18n.more.bookWorkspace.tryAgain : i18n.general.ok,
    showOkCancel: true,
    hideCancel : false,
    closeOnOK: true,
    cancelLabel: i18n.more.bookWorkspace.cancel
  };
  let result = await ModalController.confirm(options)
  if(result.ok && hasRetry) {
    try {
      retry();
    } catch (error) {
    }
  }
}

async function getAreaName (task) {
  const unitAttributes = task && task.unit && task.unit.attributes;
  const areaIdVal = aiimUtil.getAttributeValue(unitAttributes, FieldNames.AREA_ID);
  let areaName = areaIdVal;
  const areas = await Context.instance.areas;
  areas.some(area => {
    const name = getAttributeValue(area.attributes, FieldNames.AREA_NAME);
    const id = getAttributeValue(area.attributes, FieldNames.AREA_ID);
    if (areaIdVal === id) {
      areaName = name;
      return true;
    }
    return false;
  })
  return areaName;
}

async function checkError(task: IBookingTask & IBookingStatus) {
  const i18n = Context.getInstance().i18n;

  // if (task && task.error && task.warnings && task.warnings.length === 0) {
  //   if (task.error.code === "booking-conflict") {
  //     let msg = getUnitUnavailableMessage(task.checkInDate, task.checkOutDate, task.allDay);
  //     let title = i18n.more.bookWorkspace.workspaceUnavailableTitle;
  //     title = title.replace("{unitName}", task.unitName);
  //     msg = msg.replace("{unitName}", task.unitName);
  //     addWarning(task, {
  //       title: title,
  //       msg: msg
  //     })
  //     task.hasBookingConflict = true;
  //   }
  // }

  if (task && task.warnings && task.warnings.length > 0) {
    const warnings = task.warnings;
    const unitName = task && task.unitName;
    const areaName = await getAreaName(task);
    const bookingType = task && task.bookingType;
    let warningString = null, title = null;
    if (bookingType === "meetingRooms") {
      title = i18n.more.bookWorkspace.limitHotelScheduling.captionMeetingRoom;
      warningString = i18n.more.bookWorkspace.limitHotelScheduling.subCaptionMeetingRoom;
      warningString = warningString.replace("{unitName}", unitName);
    } else {
      title = i18n.more.bookWorkspace.limitHotelScheduling.caption;
      warningString = i18n.more.bookWorkspace.limitHotelScheduling.subCaption;
      warningString = warningString.replace("{unitName}", unitName);
      warningString = warningString.replace("{hotelName}", areaName);
    }

    if (task.hasBookingConflict) title = i18n.more.bookWorkspace.bookingConflict;

    const modalOptions = {
      title: title,
      hideCancel: true,
      okLabel: i18n.general.ok,
      content: (
        <div>
          <Warning check="limit-hoteling-schedule"
            warnings={warnings}
            subTitle={warningString}></Warning>
        </div>
      )
    };
    ModalController.confirm(modalOptions)
  } else if (task && task.error) {
    const error = task.error;
    const i18n = Context.instance.i18n;
    if (error && isNetworkError(error.message) && !task.bookingSuccessful) {
      await showServerErrorPopup(task.retry);

    } else if (error && error.message === "The email address provided is not a valid SMTP address.") {
      // M365: Display report alert when trying to make a booking #5731
      let msg = i18n.more.bookWorkspace.errors.cannotBook;
      let reason = i18n.more.bookWorkspace.errors.invalidScheduleEmail;
      msg = msg.replace("{unitName}", task.unitName);
      ModalController.showError({
        title: i18n.general.information,
        className: "i-portrait-access-hotel-error",
        errors: {
          generic: msg,
          detailed: [reason]
        }
      });
    } else if ([
      "String or binary data would be truncated",
      "The value should be data type"].some(e => error?.message.includes(e))) {
      ModalController.showError({
        title: i18n.more.bookWorkspace.error,
        className: "i-portrait-access-hotel-error",
        errors: {
          generic: i18n.more.bookWorkspace.errors.hotels.cannotBook,
          detailed: [error.message]
        }
      });
    } else {
      let message: string | JSX.Element = error.message;
      let submessage = error.submessage;
      let title = i18n.more.bookWorkspace.limitHotelScheduling.error;
      if (error && error.responseCode === "5035") {
        const username = Context.getInstance().user.getUsername();
        let invalidScheduleError = i18n.more.bookWorkspace.invalidScheduleError.message;
        invalidScheduleError = invalidScheduleError.replace("{unitName}", task.unitName);
        invalidScheduleError = invalidScheduleError.replace("{username}", username);
        let errorMessage = i18n.more.bookWorkspace.invalidScheduleError.errorMessage;
        errorMessage = errorMessage.replace("{responseCode}", error.responseCode);
        errorMessage = errorMessage.replace("{message}", error.message)
        message = (<div>
                    <div>{invalidScheduleError}</div>
                    <div>{errorMessage}</div>
                  </div>)
      }

      if (error && error.code === "booking-lock-conflict") {
        title = i18n.more.bookWorkspace.bookingConflict;
        if (task.bookingType === "hotel") {
          if (task.updateBooking) {
            message = i18n.more.bookWorkspace.couldNotUpdateMsg;
          } else {
            message = i18n.more.bookWorkspace.couldNotBookMsg;
          }
        } else if (task.bookingType === "meetingRooms") {
          if (task.updateBooking) {
            message = i18n.meetingRooms.book.couldNotUpdateMsg;
          } else {
            message = i18n.meetingRooms.book.couldNotBookMsg;
          }
        }
      }

      ModalController.showError({
        title: title,
        errors: {
          generic: i18n.more.bookWorkspace.errors.hotels.cannotBook,
          detailed: [message]
        }
      })
      return;
    }
  }
}

export function makeStartDateTime(startTime, startDate, allDay){

  const lib = Context.instance.lib
  startDate = lib.dojo.locale.format(startDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  })
  let hour = startTime.hour()
  if (hour < 10) hour = "0" + hour
  let minute = startTime.minute()
  if (minute < 10) minute = "0" + minute
  let time = hour + ":" + minute + ":00";
  if (allDay) {
    time = "00:00:00"
  }

  const checkInDate1 = startDate + ' '+ time;
  const checkInDate = new Date(checkInDate1);
  return checkInDate;
}

export function makeEndDateTime(endTime, endDate, allDay, forSubmit){
  const lib = Context.instance.lib;
  const orig = endDate;

  endDate = lib.dojo.locale.format(endDate, {
    selector: "date",
    datePattern: "yyyy/MM/dd"
  })
  let hour = endTime.hour()
  if (hour < 10) hour = "0" + hour
  let minute = endTime.minute()
  if (minute < 10) minute = "0" + minute
  let time = hour + ":" + minute + ":00";
  if (allDay) {
    const bookingType = getHotelBookingSystemType();
    if (forSubmit && (bookingType !== "office365")) {
      endDate = lib.dojo.locale.format(orig, {
        selector: "date",
        datePattern: "yyyy/MM/dd"
      })
      time = "23:59:00"
    } else {
      let nextDay = new Date(orig)
      nextDay.setDate(orig.getDate() + 1)
      endDate = lib.dojo.locale.format(nextDay, {
        selector: "date",
        datePattern: "yyyy/MM/dd"
      })
      time = "00:00:00";
    }
  }
  const checkOutDate1 = endDate + ' '+ time;
  const checkOutDate = new Date(checkOutDate1);
  return checkOutDate;
}

export function getStatusIconMsg(statusObj) {
  let statusIcon, statusMsg = statusObj && statusObj.message, borderStyle;
  const status = statusObj && statusObj.status && statusObj.status.toLowerCase();
  if (status === "accepted") {
    statusIcon = <CheckCircleIcon color="#35AC46" size={16}/>
    borderStyle = {borderLeft: "1px #35AC46 solid"}
  } else if (status === "declined") {
    statusIcon = <XCircleFIcon color="#D83020" size={16} />
    borderStyle = {borderLeft: "1px #D83020 solid"}
  } else if (status === "tentativelyaccepted") {
    statusIcon = <QuestionFIcon color="#881798" size={16} />
    borderStyle = {borderLeft: "1px #881798 solid"}
  } else if (status === "none" || statusObj.status === "notresponded") {
    statusIcon = <QuestionFIcon color="#EDD317" size={16} />
    borderStyle = {borderLeft: "1px #EDD317 solid"}
  } else {
    statusIcon = <InformationIcon color="#EDD317" size={16} />
    borderStyle = {borderLeft: "1px #EDD317 solid"}
  }
  return ({icon: statusIcon, msg: statusMsg, borderStyle: borderStyle})
}