import React, { Component } from "react";
import { connect } from "react-redux";

import DeleteIcon from "calcite-ui-icons-react/TrashIcon";
import GearIcon from "calcite-ui-icons-react/GearIcon";

import AreaProperties from "./AreaProperties";
import AreaRolesTable from "../../base/AreaRolesTable";
import Button from "../../../common/components/Button";
import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import { ModalController } from "../../../common/components/Modal";
import OfficePlan from "../../base/OfficePlan";
import Rdx, { IRdxProps, mapDispatchToProps } from "../../../redux/Rdx";
import ReservationManagers from "./ReservationManagers";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import {ReactComponent as UserAdminIcon} from "./UserAdminIcon.svg";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as component from "../../../components/util/component";

import { HeaderContainer, Title, ButtonContainer } from '../../styles/Work/areaHeader';
import { queryPeopleAssignedToArea } from "../../base/officePlanUtil";
import { canDeleteDuplicate, getAttributes } from "../common/MultipleAssignments/multipleAssignmentsUtil";
import { AreaTypes } from "../../../util/interfaces";
import {
  getActiveWorkspaceAreaId, getActiveWorkspaceAreaType,
  getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled,
  getHotelReservationMethod, getMeetingRoomReservationMethod
} from "../../redux";
interface IAreaHeaderProps extends IRdxProps {
  areaItem: __esri.Graphic,
  disableManagers: boolean,
  rdxActiveAreaId: string,
  rdxActiveAreaType: AreaTypes,
  rdxHotdesksEnabled: boolean,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean
}
interface IAreaHeaderState {
  modifiedName: string,
  reservationManagerNames: string[],
  reservationManagerFeatures: __esri.Graphic[],
  queryingRoles: boolean
}
class AreaHeader extends Component<IAreaHeaderProps, IAreaHeaderState> {
  private componentId: string;
  useRdx = true;

  constructor(props: IAreaHeaderProps) {
    super(props);
    this.componentId = component.nextId();
    this.state = component.newState({
      reservationManagerNames: null,
      reservationManagerFeatures: null,
      queryingRoles: false
    });
  }

  componentDidMount() {
    this.queryReservationManagers();

    component.own(this, [
      Topic.subscribe(Topic.PlanModified, params => {
        const actions = [OfficePlan.Action_AssignmentsUpdated];
        if (actions && actions.indexOf(params.action) !== -1) {
          const plan = OfficePlan.getActive();
          if (plan && plan.hasAreaRoles()) {
            const { rdxActiveAreaId, rdxActiveAreaType } = this.props;
            const isActive = (rdxActiveAreaId === this.getAreaId() && rdxActiveAreaType === this.getAreaType());
            if (isActive) {
              this.queryReservationManagers();
            }
          }
          component.refresh(this);
        }
      })
    ]);

  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  deleteClicked = () => {
    const i18n = Context.instance.i18n;
    const options = {
      title: i18n.spaceplanner.areaProperties.delCaption,
      message: i18n.spaceplanner.areaProperties.delPrompt,
      okLabel: i18n.spaceplanner.areaProperties.del,
      closeOnOK: false,
      closeOnCancel: false,
    }
    ModalController.confirm(options).then(result => {
      if (result.ok) {
        result.controller.close();
        const guard = new TransactionGuard({force: true});
        const plan = Context.instance.spaceplanner.activePlan;
        const areasTable = plan && plan.areasTable;
        const areaItem = this.getAreaItem();
        let duplicates = []
        queryPeopleAssignedToArea(areaItem).then(people => {
          const promises = []
          if (people && people.length > 0) {
            people.forEach(person => {
              const attributes = getAttributes(person)
              const personName = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_FULLNAME)
              const personEmail = aiimUtil.getAttributeValue(attributes, FieldNames.PEOPLE_EMAIL)
              const promise = canDeleteDuplicate(personName, personEmail).then(result => {
                if (result) duplicates.push(person)
              })
              promises.push(promise)
            })
          }
          return Promise.all(promises)
        }).then(() => {
          let deletePeopleData;
          if (duplicates && duplicates.length > 0) {
            deletePeopleData = duplicates;
          }
          return areasTable.deleteArea(areaItem,deletePeopleData)
        }).then(() => {
          guard.close();
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated
          });
          this.props.setRdxValue(Rdx.Keys.SPACEPLANNER_ACTIVE_WORKSPACE_AREA_TYPE, null);
          //result.controller.close();
        }).catch(error => {
          guard.close();
          //result.controller.close();
          console.error("Error updating area", error);
          Topic.publishErrorUpdatingData(error.submessage);
        });
      } else {
        result.controller.close();
      }
    });
  }

  getAreaId = () => {
    const areaItem = this.getAreaItem();
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_ID);
  }

  getAreaItem = () => {
    return this.props.areaItem;
  }

  getAreaName = () =>{
    const areaItem = this.getAreaItem();
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_NAME);
  }

  getAreaType = (): AreaTypes => {
    const areaItem = this.getAreaItem();
    return aiimUtil.getAttributeValue(areaItem.attributes, FieldNames.AREA_TYPE);
  }

  onChangeName = evt => {
    this.setState({ modifiedName: evt.target.value });
  }

  onReservationManagersClicked = evt => {
    ReservationManagers.showModal(
      this.state.reservationManagerFeatures,
      this.getAreaItem(),
      this.getAreaId(),
      this.getAreaName()
    );
  }

  propertiesClicked = () => {
    AreaProperties.showModal({
      caption: Context.getInstance().i18n.spaceplanner.workspaceAreas.editArea,
      areaItem: this.getAreaItem()
    });
  }

  queryReservationManagers = () => {
    const plan = OfficePlan.getActive();
    if (plan && plan.hasAreaRoles()) {
      const areaId = this.getAreaId();
      const areaRolesTable = plan.areaRolesTable;
      const role = AreaRolesTable.ROLE_RESERVATION_MANAGER;
      const qfn = true;
      this.setState({queryingRoles: true});
      areaRolesTable.queryRoleDataByAreaId(areaId,role,qfn).then(result => {
        // console.log("queryRoleDataByAreaId.result",result)
        this.setState({
          reservationManagerNames: result && result.fullNames,
          reservationManagerFeatures: result && result.peopleFeatures,
          queryingRoles: false
        })
      }).catch(ex => {
        this.setState({ queryingRoles: false })
        console.error("Error querying reservation managers",ex)
      })
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const areaName=this.getAreaName();
    const { disableManagers, rdxActiveAreaId, rdxActiveAreaType } = this.props;
    const tooLong = areaName.length >= 30
    let tooltip = tooLong ? areaName : ""
    const truncatedAreaName = tooLong ? areaName.substring(0, 30).trim() + "..." : areaName
    const officePlan = OfficePlan.getActive();
    const isActive = (this.getAreaId() === rdxActiveAreaId && this.getAreaType() === rdxActiveAreaType);
    const supportsAreaRoles = officePlan.hasAreaRoles();
    const { reservationManagerNames: names } = this.state;
    let mgrNames;
    if (names && names.length > 0) {
      mgrNames = names.join(", ");
    } else {
      if (this.state.queryingRoles) {
        mgrNames = "...";
      } else {
        mgrNames = i18n.spaceplanner.reservationManager.noneSelected;
      }
    }

    return (
      <div style={{width: "100%"}}>
        <div>
          <HeaderContainer>
            <Title>
              <div title={tooltip}>{truncatedAreaName}</div>
            </Title>
            <ButtonContainer>
              {rdxActiveAreaId && rdxActiveAreaType &&
                <React.Fragment>
                  <Button key="properties" clear={true}
                    title={i18n.general.properties}
                    onClick={this.propertiesClicked}
                    iconButton={true} icon={<GearIcon />} />
                  <Button key="delete" clear={true}
                    title={i18n.spaceplanner.areaProperties.del}
                    onClick={this.deleteClicked}
                    iconButton={true} icon={<DeleteIcon />} />
                </React.Fragment>
              }
            </ButtonContainer>
          </HeaderContainer>
        </div>
        {isActive && supportsAreaRoles && !disableManagers &&
          <React.Fragment>
            <div className="i-flex-between" style={{marginTop: "16px", alignItems: "center"}}>
            <span className={`i-managers-label${disableManagers ? " i-form-disabled" : ""}`}>
                {i18n.spaceplanner.reservationManager.plural}
              </span>
              <Button
                disabled={disableManagers}
                icon={<UserAdminIcon/>}
                iconButton={true}
                title={i18n.spaceplanner.reservationManager.plural}
                onClick={this.onReservationManagersClicked}>
              </Button>
            </div>
            <div className={`i-manager-names${disableManagers ? " i-form-disabled" : ""}`}>{mgrNames}</div>
          </React.Fragment>
        }
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const props = {
    rdxActiveAreaId: getActiveWorkspaceAreaId(state),
    rdxActiveAreaType: getActiveWorkspaceAreaType(state)
  };
  const hotdesksEnabled = getHotdesksEnabled(state);
  const hotelsEnabled = getHotelsEnabled(state);
  const meetingRoomsEnabled = getMeetingRoomsEnabled(state);
  const hotelReservationMethod = getHotelReservationMethod(state);
  const meetingRoomReservationMethod = getMeetingRoomReservationMethod(state);

  // Hide reservation manager if M365 is set as reservation method for office hotels #7912
  const disableManagers = !(
    (hotelsEnabled && hotelReservationMethod === "esri") || (meetingRoomsEnabled && meetingRoomReservationMethod === "esri")
  );

  return {
    ...props,
    disableManagers
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaHeader);
