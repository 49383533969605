import React from "react";
import { connect } from "react-redux";

import Context from "../../../../context/Context";
import SiteFilter from "./SiteFilter";
import FacilityFilter from "./FacilityFilter";
import LevelFilter from "./LevelFilter";

import { IFilterCriteria, IOccupantsCriteria } from "../../common/types";
import * as component from "../../../../components/util/component";
import * as filterUtil from "./filterUtil";
import * as sourceUtil from "../../../base/sourceUtil";
import * as whereUtil from "./whereUtil";

interface Props {
  filterCriteria: IFilterCriteria;
  layerType: string;
  onChange: (filterCriteria: IFilterCriteria) => void;
}

interface State {
  lastWhere?: string;
  levelIdSubset: any;
}

class SiteFacilityLevelFilter extends React.Component<Props, State> {

  asyncStamp;

  constructor(props) {
    super(props);
    this.state = component.newState({
      lastWhere: null,
      levelIdSubset: null
    });
  }

  componentDidMount() {
    this.query();
  }

  componentDidUpdate(prevProps) {
    const fc = this.props.filterCriteria;
    if (prevProps.filterCriteria !== fc) {
      const { filterCriteria } = this.props;
      const fld = Context.instance.aiim.getLevelIdField(sourceUtil.getPeopleLayer());
      if (fld) {
        const where = whereUtil.makeFilterWhere(filterCriteria,fld.name);
        if (where !== this.state.lastWhere) this.query();
      }
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  query = async () => {
    try {
      const stamp = this.asyncStamp = Date.now();
      const { layerType, filterCriteria } = this.props;
      const fld = Context.instance.aiim.getLevelIdField(sourceUtil.getPeopleLayer());
      if (fld) {
        let idx = null;
        const field = fld.name;
        const where = whereUtil.makeFilterWhere(filterCriteria,field);
        if (where) {
          idx = {};
          const uniqueValues = await filterUtil.queryUniqueValues(layerType,field,where);
          if (uniqueValues) {
            uniqueValues.forEach(uv => {
              if (uv.value !== "__novalue") idx[uv.value] = true;
            })
          }
        }
        if (stamp === this.asyncStamp) {
          this.setState({
             lastWhere: where,
             levelIdSubset: idx
          });
        }
      }
    } catch(ex) {
      console.error(ex)
    }
  }

  render() {
    const { layerType, filterCriteria } = this.props;
    const { levelIdSubset } = this.state;
    return (
      <>
        <SiteFilter 
          layerType={layerType} 
          filterCriteria={filterCriteria} 
          levelIdSubset={levelIdSubset}
          onChange={value => {
            const fc = JSON.parse(JSON.stringify(filterCriteria))
            const sfl = fc.siteFacilityLevel;
            sfl.siteId = value;
            if (value && sfl.facilityId) {
              let ok = filterUtil.isFacilityInSite(value,sfl.facilityId);
              if (!ok) {
                sfl.facilityId = null;
                sfl.levelId = null;
              }
            }
            const where = whereUtil.makeSiteFacilityLevelWhere(layerType,fc);
            sfl.where = where;
            this.props.onChange(fc)
          }}
        />
        <FacilityFilter 
          layerType={layerType} 
          filterCriteria={filterCriteria}
          levelIdSubset={levelIdSubset}
          onChange={value => {
            const fc = JSON.parse(JSON.stringify(filterCriteria))
            const sfl = fc.siteFacilityLevel;
            sfl.facilityId = value;
            if (sfl.levelId) {
              let ok = value && filterUtil.isLevelInFacility(value,sfl.levelId);
              if (!ok) {
                sfl.levelId = null;
              }
            }
            const where = whereUtil.makeSiteFacilityLevelWhere(layerType,fc);
            sfl.where = where;
            this.props.onChange(fc)
          }}
        />
        <LevelFilter 
          layerType={layerType} 
          filterCriteria={filterCriteria}
          levelIdSubset={levelIdSubset}
          onChange={value => {
            const fc = JSON.parse(JSON.stringify(filterCriteria))
            const sfl = fc.siteFacilityLevel;
            sfl.levelId = value;
            const where = whereUtil.makeSiteFacilityLevelWhere(layerType,fc);
            sfl.where = where;
            this.props.onChange(fc)
          }}
        />
      </>
    )
  }

}

const mapStateToProps = (state,ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(SiteFacilityLevelFilter);