import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch } from "react-redux";

import Context from "../../../../context/Context";
import { setUndoRedoDisabled } from "../redux";
import "@esri/calcite-components/dist/components/calcite-button";
import {
  CalciteButton
} from "@esri/calcite-components-react";
import { JSX as CalciteJSX } from "@esri/calcite-components";
interface Props {
  kind?: CalciteJSX.CalciteButton["kind"],
  onSave: () => void,
  onReset: (e: React.MouseEvent) => void,
  editIsActive?: boolean,
  parentPanelKey: string,
  resetDisabled: boolean,
  resetLabel?: string,
  saveDisabled: boolean,
  saveLabel?: string
}
export interface ISavebar {
  setSaveEnabled: (enabled: boolean) => void
}
const Savebar = forwardRef<ISavebar, Props>(({
  editIsActive,
  kind = "brand",
  onReset,
  onSave,
  parentPanelKey,
  resetDisabled,
  resetLabel = Context.instance.i18n.general.reset,
  saveDisabled,
  saveLabel = Context.instance.i18n.general.save
}: Props, ref) => {
  const ctx = Context.getInstance();
  const { i18n } = ctx;
  const dispatch = useDispatch();
  const saveBtnRef = useRef<HTMLCalciteButtonElement>();

  useEffect(() => {
    setUndoRedo();
    return () => {
      setUndoRedo(true);
    }
  }, [editIsActive]);

  const setUndoRedo = (unmounting?: boolean) => {
    if (typeof editIsActive === "boolean") {
      if (unmounting) editIsActive = false;
      dispatch(setUndoRedoDisabled(editIsActive));
    }
  }
  useImperativeHandle(ref, () => ({
    setSaveEnabled: (enabled: boolean) => {
      if (saveBtnRef.current) {
        saveBtnRef.current.disabled = !enabled;
      }
    }
  }));

  return (
    <>
      <CalciteButton
        appearance="outline"
        kind={kind}
        width="half"
        disabled={resetDisabled ? true : undefined}
        onClick={onReset}
      >
        {resetLabel}
      </CalciteButton>
      <CalciteButton
        kind={kind}
        width="half"
        disabled={saveDisabled ? true : undefined}
        onClick={onSave}
        ref={saveBtnRef}
      >
        {saveLabel}
      </CalciteButton>
    </>
  )
});

export default Savebar;