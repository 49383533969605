import Context from "../../../context/Context";
import FieldNames from "../../../aiim/datasets/FieldNames";
import OfficePlan from "../OfficePlan";
import * as aiimUtil from "../../../aiim/util/aiimUtil";
import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import {
  addAttributeEdit,
  appendPersonUnassignment,
  clonePersonFeature,
  cloneUnitFeature,
  fetchRelatedPeople,
  fetchRelatedUnits,
  fetchUtilization,
  unitBasedFieldsForPeople
} from "./transactionUtil";
import { checkForDuplicatePeople, getAttributes, getPersonAssignments } from "../../components/common/MultipleAssignments/multipleAssignmentsUtil";
import { queryPeopleAssignedToUnit } from "../officePlanUtil";

/*
  transactions

    assignPeopleToArea
    assignPeopleToUnit
    unassignPeople

*/

export function prepare(task) {
  const promise = new Promise((resolve,reject) => {

    //console.log("people.prepare",task)

    const transactionInfo = transaction.makeTransactionInfo();
    let peopleFeatureItems = task.peopleFeatureItems;
    const unitIds = [];
    let targetUnitId = null;
    let relatedPeopleInfo, relatedUnitInfo;
    let duplicates, singleRecords;
    let localUtilizationByUnitId = {};
    let remoteUtilizationByUnitId;
    let alreadyAssigned = 0;

    const duplicatePeople = task.duplicatePeople
    const duplicatePeopleFeatures = duplicatePeople && duplicatePeople.map(p => {
      if (p.person) return p.person
      else return p
    })
    //const associatedUnitFeatures = duplicatePeople && duplicatePeople.map(p => p.associatedUnit)
    //const associatedAreaFeatures = duplicatePeople && duplicatePeople.map(p => p.associatedArea)

    const checkIfAlreadyAssigned = (assignments) => {
      // Check to see if this person already has this assignment
      if (!assignments) return false
      let isAlreadyAssigned = false
      const unitFeature = task && task.assignPeopleToUnit && task.assignPeopleToUnit.unitFeatureItem
      const areaFeature = task && task.assignPeopleToArea && task.assignPeopleToArea.areaItem
      if (unitFeature) {
        const unitAttributes = getAttributes(unitFeature)
        const toAssignUnitId = aiimUtil.getAttributeValue(unitAttributes, FieldNames.UNIT_ID)
        const units = (assignments && assignments.units) || []
        units.forEach(unit => {
          const attributes = getAttributes(unit)
          const unitId = aiimUtil.getAttributeValue(attributes, FieldNames.UNIT_ID)
          if (toAssignUnitId === unitId) isAlreadyAssigned = true
        })
      } else if (areaFeature) {
        const areaAttributes = getAttributes(areaFeature)
        const toAssignAreaId = aiimUtil.getAttributeValue(areaAttributes, FieldNames.AREA_ID)
        const areas = (assignments && assignments.areas) || []
        areas.forEach(area => {
          const attributes = getAttributes(area)
          const areaId = aiimUtil.getAttributeValue(attributes, FieldNames.AREA_ID)
          if (toAssignAreaId === areaId) isAlreadyAssigned = true
        })
      }
      return isAlreadyAssigned
    }

    if (task.assignPeopleToUnit) {
      const unitFeatureItem = task.assignPeopleToUnit.unitFeatureItem;
      let unitId = aiimUtil.getAttributeValue(unitFeatureItem.feature.attributes,FieldNames.UNIT_ID);
      if (typeof unitId === "string" && unitId.length > 0) {
        targetUnitId = unitId;
        if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
      }
    }

    const gids = [];
    const gidfld = sourceUtil.getPeopleLayer().globalIdField;
    peopleFeatureItems.forEach(featureItem => {
      let unitId = aiimUtil.getAttributeValue(getAttributes(featureItem),FieldNames.UNIT_ID);
      gids.push(getAttributes(featureItem)[gidfld]);
      if (typeof unitId === "string" && unitId.length > 0) {
        if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
        if (localUtilizationByUnitId.hasOwnProperty(unitId)) {
          let u = localUtilizationByUnitId[unitId];
          localUtilizationByUnitId[unitId] = (u + 1);
        } else {
          localUtilizationByUnitId[unitId] = 1;
        }
        if (unitId === targetUnitId) {
          alreadyAssigned++;
        }
      }
    });

    if (duplicatePeopleFeatures && duplicatePeopleFeatures.length > 0) {
      duplicatePeopleFeatures.forEach(featureItem => {
        let unitId = aiimUtil.getAttributeValue(getAttributes(featureItem),FieldNames.UNIT_ID);
        if (typeof unitId === "string" && unitId.length > 0) {
          if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
        }
      })
    }

    if (duplicates && duplicates.length > 0) {
      duplicates.forEach(featureItem => {
        let unitId = aiimUtil.getAttributeValue(getAttributes(featureItem),FieldNames.UNIT_ID);
        if (typeof unitId === "string" && unitId.length > 0) {
          if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
        }
      })
    }

    if (singleRecords && singleRecords.length > 0) {
      singleRecords.forEach(featureItem => {
        let unitId = aiimUtil.getAttributeValue(getAttributes(featureItem),FieldNames.UNIT_ID);
        if (typeof unitId === "string" && unitId.length > 0) {
          if (unitIds.indexOf(unitId) === -1) unitIds.push(unitId);
        }
      })
    }

    const makePeopleEdits = () => {
      const updates = [], undoUpdates = [], deletes = [], undoDeletes = [];
      const layer = sourceUtil.getPeopleLayer();
      const fields = layer.fields;
      const keyField = layer.globalIdField;

      peopleFeatureItems.forEach(featureItem => {
        let needsChanges = true
        const clonedPerson = clonePersonFeature(task, featureItem.feature || featureItem, "ensure-geometry")
        const update = {attributes: {}};
        update.attributes[keyField] = getAttributes(featureItem)[keyField];

        if (task.assignPeopleToUnit) {
          addAttributeEdit(fields,update.attributes,FieldNames.PEOPLE_AREA_ID,null);
          const unitFeatureItem = task.assignPeopleToUnit.unitFeatureItem;
          const unitAttributes = unitFeatureItem.feature.attributes;
          let unitGeometry = unitFeatureItem.geometry || unitFeatureItem.feature.geometry;
          unitBasedFieldsForPeople.forEach(f => {
            let v = aiimUtil.getAttributeValue(unitAttributes,f);
            if (f === "unit_name") v = aiimUtil.getAttributeValue(unitAttributes,"name");
            addAttributeEdit(fields,update.attributes,f,v);
          });
          if (unitGeometry && typeof unitGeometry.clone !== "function") {
            const g = Context.instance.lib.esri.Graphic.fromJSON({
               attributes: unitAttributes,
               geometry: unitGeometry
            });
            unitGeometry = g.geometry;
          }
          update.geometry = unitGeometry.clone().centroid;
          if (task.occupantGeometry) {
            update.geometry = task.occupantGeometry;
          }
        }

        if (task.assignPeopleToArea) {
          appendPersonUnassignment(task,fields,update);
          const areaItem = task.assignPeopleToArea.areaItem;
          const areaId = aiimUtil.getAttributeValue(areaItem.attributes,FieldNames.AREA_ID);
          addAttributeEdit(fields,update.attributes,FieldNames.PEOPLE_AREA_ID,areaId);
        }

        if (task.unassignPeople) {
          if (duplicatePeopleFeatures && peopleFeatureItems.length === 1) {
            appendPersonUnassignment(task, fields, update)
          } else if (!duplicatePeopleFeatures || duplicatePeopleFeatures.length === 0) {
            appendPersonUnassignment(task,fields,update);
          } else {
            needsChanges = false
          }
        }

        if (needsChanges) {
          undoUpdates.push(clonedPerson);
          updates.push(update);
        }
      });

      // if (task.assignPeopleToUnit &&
      //     task.assignPeopleToUnit.unassignCurrentOccupants &&
      //     relatedPeopleInfo) {
      //   const keys2 = relatedPeopleInfo.globalIds;
      //   const features2 = (relatedPeopleInfo && relatedPeopleInfo.features) || [];
      //   const updates2 = makeUnassignPeopleUpdates(task,keys2,keyField,gids);
      //   // const updates2 = unassignPeopleDnD(task, features2)
      //   if (updates2.length > 0) {
      //     updates2.forEach(update2 => {
      //       updates.push(update2);
      //     });
      //     features2.forEach(feature2 => {
      //       undoUpdates.push(clonePersonFeature(task,feature2,"ensure-geometry"));
      //     });
      //   }
      // }

      if (duplicates && duplicates.length > 0) {
        duplicates.forEach(person => {
          const personFeature = person.feature || person
          const key = personFeature.attributes[keyField]
          //const undo = {attributes: Object.assign({}, personFeature.attributes)}
          const undo = clonePersonFeature(task, personFeature, "ensure-geometry");
          deletes.push(key)
          undoDeletes.push(undo)
        })
      }

      if (singleRecords && singleRecords.length > 0) {
        singleRecords.forEach(person => {
          const personFeature = person.feature || person
          undoUpdates.push(clonePersonFeature(task, personFeature, "ensure-geometry"))
          const update = {attributes: {}}
          const personAttributes = getAttributes(person)
          update.attributes[keyField] = personAttributes[keyField]
          appendPersonUnassignment(task, fields, update)
          updates.push(update)
        })
      }

      if (duplicatePeopleFeatures) {
        duplicatePeopleFeatures.forEach(person => {
          const personFeature = person.feature || person
          const key = personFeature.attributes[keyField]
          //const undo = {attributes: Object.assign({}, personFeature.attributes)}
          const undo = clonePersonFeature(task, personFeature, "ensure-geometry");
          deletes.push(key)
          undoDeletes.push(undo)
        })
      }

      transactionInfo.peopleEdits = {
        updates: updates,
        deletes: deletes
      };
      transactionInfo.undo.peopleEdits = {
        updates: undoUpdates,
        adds: undoDeletes
      };
      transactionInfo.edits.push({
        id: layer.layerId,
        updates: updates,
        deletes: deletes
      });
      transactionInfo.undo.edits.push({
        id: layer.layerId,
        updates: undoUpdates,
        adds: undoDeletes
      });
    };

    const makeUnitEdits = (unitData) => {
      //const unitUpdates = unitData && unitData.unitUpdates
      //const unitUndos = unitData && unitData.unitUndos
      let updates = [], undos = [];
      // if (unitUpdates) updates = unitUpdates
      // if (unitUndos) undos = unitUndos
      const layer = sourceUtil.getUnitsLayer();
      const fields = layer.fields;
      const keyField = layer.globalIdField;

      const features = (relatedUnitInfo && relatedUnitInfo.features) || [];
      features.forEach(feature => {
        undos.push(cloneUnitFeature(task,feature,"no-geometry"));
      });

      let unitFeatureItemId = null
      if (task.assignPeopleToUnit) {
        const unitFeatureItem = task.assignPeopleToUnit.unitFeatureItem;
        unitFeatureItemId = aiimUtil.getAttributeValue(unitFeatureItem.feature.attributes,FieldNames.UNIT_ID);
      }

      const updatesByUnit = {}
      const keys = (relatedUnitInfo && relatedUnitInfo.globalIds) || [];
      keys.forEach(key => {
        let unitId = relatedUnitInfo.unitIdsByGlobalId[key];

        let utilization = 0;
        let localUtilization = 0;
        let remoteUtilization = 0;
        if (localUtilizationByUnitId.hasOwnProperty(unitId)) {
          localUtilization = localUtilizationByUnitId[unitId];
        }
        if (remoteUtilizationByUnitId.hasOwnProperty(unitId)) {
          remoteUtilization = remoteUtilizationByUnitId[unitId];
        }
        if (task.assignPeopleToUnit) {
          if (unitId === targetUnitId) {
            if (task.assignPeopleToUnit.unassignCurrentOccupants) {
              utilization = peopleFeatureItems.length;
            } else {
              utilization = remoteUtilization + peopleFeatureItems.length - alreadyAssigned;
            }
          } else {
            utilization = remoteUtilization - localUtilization;
          }
        }
        if (task.assignPeopleToArea) {
          utilization = remoteUtilization - localUtilization;
        }
        if (task.unassignPeople) {
          utilization = remoteUtilization - localUtilization;
        }
        if (utilization < 0) utilization = 0;

        let asnType = OfficePlan.SpaceAssignmentTypes.none
        if (utilization > 0) {
          asnType = OfficePlan.SpaceAssignmentTypes.office;
        }
        // TODO validate these?
        const update = {attributes: {}};
        update.attributes[keyField] = key;
        addAttributeEdit(fields,update.attributes,FieldNames.UNITS_AREA_ID,null);
        addAttributeEdit(fields,update.attributes,FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE,asnType);
        //addAttributeEdit(fields,update.attributes,FieldNames.UTILIZATION, utilization);
        // updates.push(update);
        updatesByUnit[key] = update;
      });

      const promises = []
      // Issue #4617
      if (task.unassignPeople) {
        features.forEach(u => {
          let unit = u.feature ? u : {feature: u}
          const promise = queryPeopleAssignedToUnit(unit).then((result) => {
            const count = result.length;

            const key = unit.feature && unit.feature.attributes[keyField];
            const update = {attributes: {}};
            update.attributes[keyField] = key;
            const asnType = count <= 1 ? OfficePlan.SpaceAssignmentTypes.none : OfficePlan.SpaceAssignmentTypes.office;
            addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
            // updates.push(update)
            updatesByUnit[key] = update;
          }).catch(e => {
            console.error("Error querying people assigned to unit", e);
          });
          promises.push(promise)
        })
      }

      return Promise.all(promises).then(() => {
        Object.keys(updatesByUnit).forEach(key => {
          const update = updatesByUnit[key]
          updates.push(update)
        })
        if (updates.length > 0) {
          transactionInfo.unitEdits = {updates: updates};
          transactionInfo.undo.unitEdits = {updates: undos};
          transactionInfo.edits.push({
            id: layer.layerId,
            updates: updates
          });
          transactionInfo.undo.edits.push({
            id: layer.layerId,
            updates: undos
          });
        }
      }).catch(e => {
        console.error("Couldn't update units")
      })
    };

    // const hasUnits = () => {
    //   if (!associatedUnitFeatures) return false
    //   let hasUnits = true
    //   associatedUnitFeatures.forEach(unit => {
    //     if (!unit) hasUnits = false
    //   })
    //   return hasUnits
    // }

    // const unassignAssignmentsUnitEdits = () => {
    //   if (!hasUnits()) return Promise.resolve()
    //   const unitUpdates = [], unitUndos = [], promises = []
    //   const layer = sourceUtil.getUnitsLayer()
    //   const fields = layer.fields
    //   const keyField = layer.globalIdField
    //
    //   associatedUnitFeatures.forEach(unit => {
    //     const promise = queryPeopleAssignedToUnit(unit).then((result) => {
    //       const count = result.length
    //       if (count <= 1) {
    //         const key = unit.feature && unit.feature.attributes[keyField]
    //         const asnType = OfficePlan.SpaceAssignmentTypes.none
    //         unitUndos.push(cloneUnitFeature(task, unit.feature, null))
    //         const update = {attributes: {}}
    //         update.attributes[keyField] = key
    //         addAttributeEdit(fields, update.attributes, FieldNames.UNITS_SPACE_ASSIGNMENT_TYPE, asnType)
    //         unitUpdates.push(update)
    //       }
    //     }).catch(e => {
    //       console.error("Error querying people assigned to unit", e);
    //     });
    //     promises.push(promise)
    //   })
    //
    //   return Promise.all(promises).then(() => {
    //     return {unitUpdates: unitUpdates, unitUndos: unitUndos}
    //   }).catch(e => {
    //     console.error("Couldn't update units", e)
    //   })
    // }

    Promise.resolve().then(() => {
      const promises = []
      const toRemove = peopleFeatureItems.map(p => {return {person: p}})
      toRemove.forEach((entry, index) => {
        const personAttributes = getAttributes(entry.person)
        if (personAttributes) {
          const personName = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
          const personEmail = aiimUtil.getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
          const promise = getPersonAssignments(personName, personEmail).then(assignments => {
            const alreadyAssigned = checkIfAlreadyAssigned(assignments)
            if (alreadyAssigned) {
              peopleFeatureItems.splice(index, 1)
            }
          })
          promises.push(promise)
        }
      })
      return Promise.all(promises)
    }).then(() => {
      return fetchRelatedUnits(task,unitIds).then(result => {
        relatedUnitInfo = result;
        //console.log("fetchRelatedUnits",relatedUnitInfo);
      });
    }).then(() => {
      if (task.assignPeopleToUnit &&
          task.assignPeopleToUnit.unassignCurrentOccupants &&
          targetUnitId) {
        return fetchRelatedPeople(task,[targetUnitId]).then(result => {
          relatedPeopleInfo = result;
          //console.log("fetchRelatedPeople",relatedPeopleInfo);
        });
      }
    }).then(() => {
      return fetchUtilization(unitIds).then(result => {
        remoteUtilizationByUnitId = result.utilizationByUnitId;
      });
    }).then(() => {
      const people = relatedPeopleInfo && relatedPeopleInfo.features
      if (task.assignPeopleToUnit && task.assignPeopleToUnit.unassignCurrentOccupants && people) {
        return checkForDuplicatePeople(people)
      }
      // if (task.unassignPeople) {
      //   return checkForDuplicatePeople(peopleFeatureItems)
      // }
    }).then(data => {
      if (data && data.duplicates) duplicates = data.duplicates
      if (data && data.singleRecords) singleRecords = data.singleRecords
    }).then(() => {
      // if (duplicatePeople) {
      //   return unassignAssignmentsUnitEdits()
      // }
    }).then(unitData => {
      makePeopleEdits();
      return makeUnitEdits(unitData);
    }).then(() => {
      resolve(transactionInfo);
    }).catch(ex => {
      reject(ex);
    });

  });
  return promise;
}
