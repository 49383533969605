import * as sourceUtil from "../sourceUtil";
import * as transaction from "./transaction";
import * as transactionUtil from "./transactionUtil";

export async function prepare(task) {
  const layer = sourceUtil.getUnitsLayer();
  const transactionInfo = transaction.makeTransactionInfo();
  const info = task.info;
  transactionInfo.useGlobalIds = false;
  transactionInfo.undo.useGlobalIds = false;

  const makeDetailEdits = () => {
    const layer = sourceUtil.getDetailsLayer();
    const adds = [];
    if (layer && info.newWall && info.newWall.attributes) {
      const add = transactionUtil.cloneDetailFeature(task,info.newWall);
      adds.push(add);
    }
    if (adds.length > 0) {
      const layerEdits = {
        id: layer.layerId,
        adds: adds
      }
      const undoLayerEdits = {
        id: layer.layerId,
        deletes: ["?"] // needs to be populated following add
      }
      transactionInfo.detailEdits = true;
      transactionInfo.undo.detailEdits = true;
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);
    }
  }

  const makeUnitEdits = () => {

    const adds = [], updates = [], undoUpdates = [];

    if (info.originalUnit && info.modifiedUnit) {
      const update = transactionUtil.cloneUnitFeature(task,info.modifiedUnit);
      updates.push(update);
      const undo = transactionUtil.cloneUnitFeature(task,info.originalUnit);
      undoUpdates.push(undo);

      if (info.newUnit) {
        const add = transactionUtil.cloneUnitFeature(task,info.newUnit);
        adds.push(add);
      }

      const layerEdits = {
        id: layer.layerId,
        updates: updates
      }
      const undoLayerEdits = {
        id: layer.layerId,
        updates: undoUpdates
      }
      if (adds.length > 0) {
        layerEdits.adds = adds;
        undoLayerEdits.deletes = ["?"]; // needs to be populated following add
      }

      transactionInfo.unitEdits = true;
      transactionInfo.undo.unitEdits = true;
      transactionInfo.edits.push(layerEdits);
      transactionInfo.undo.edits.push(undoLayerEdits);

    }
  }

  makeUnitEdits();
  makeDetailEdits();
  return transactionInfo;
}
