import React from 'react';
import DateFilter from './DateFilter';
import {makeWhere} from '../More/Actions/BookWorkspace/whereUtil';
import { IDateCriteria } from '../More/Actions/BookWorkspace/BookWorkspace';

export interface IBookingDateFilter {
  where?: string,
  criteria?: IDateCriteria,
  dateStart?: Date,
  dateEnd?: Date
}
interface IBookingDateFilterProps {
  isOffice365?: boolean,
  onFilterChange?: (data: IBookingDateFilter) => void,
  type: "bookings"
}
export default class BookingDateFilter extends React.Component<IBookingDateFilterProps> {
  constructor(props: IBookingDateFilterProps) {
    super(props);
  }

  optionClicked = (type, menuOptions, dateSelected) => {
    const criteria = {
      dateOption: menuOptions.props.value,
      dateSelected: dateSelected
    }
    // We will use this object to filter Office 365 reservations
    const filterDataChanges: { dtStart?: Date, dtEnd?: Date } = {}
    const where = makeWhere(criteria, filterDataChanges);
    
    const data = {
      where: where,
      criteria: criteria,
      dateStart: filterDataChanges.dtStart,
      dateEnd: filterDataChanges.dtEnd
    };
    
    if (this.props.onFilterChange) {
      this.props.onFilterChange(data);
    }
  }
  
  render() {
    return (
      <span style={{justifyContent: 'flex-end'}} className='i-events-options'>
        <DateFilter onClick={this.optionClicked} type={this.props.type} 
          isOffice365={this.props.isOffice365}/>
      </span>
    )
  }
}