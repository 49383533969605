import React from "react";
import { connect } from "react-redux";

import About from "../../common/About/About";
import Context from "../../../context/Context";
import Directions from "../Directions/Directions";
import Events from "../Events/Events";
import More from "../More/More";
import Explore from "../Explore/Explore";
import ExploreCIM from "../ExploreCIM/ExploreCIM";
import MeetingRooms from "../MeetingRooms/MeetingRooms";
import MyPlaces from "../MyPlaces/MyPlaces";
import Rdx from "../../../redux/Rdx";
import SidebarButton from "./SidebarButton";
import SidebarButton2 from "./SidebarButton2";
import SidebarPanel from "./SidebarPanel";
import Topic from "../../../context/Topic";
import * as component from "../../util/component";
import * as reservationUtil from "../../../aiim/util/reservationUtil";
import BookWorkspace from "../More/Actions/BookWorkspace/BookWorkspace";

const CSS = {
  main: "i-sidebar",
  sidebarMenus: "i-sidebar-menus",
  sidebarMenu: "i-sidebar-menu",
  sidebarMenu2: "i-sidebar-menu2",
  sidebarButton2: "i-sidebar-button2",
  sidebarPanels: "i-sidebar-panels",
  sidebarPanelsVisible: "i-sidebar-panels-visible",
  sidebarPanelOpen: "i-sidebar-panel-open",
  expanded: "i-expanded"
};

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = component.newState({});
    this.showAbout = this.showAbout.bind(this);
  }

  componentDidMount() {
    component.own(this,[

      Topic.subscribe(Topic.DirectionsClicked,params => {
        try {
          if (Context.getInstance().config.networkServiceUrl) {
            Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,"directions");
            document.body.classList.add(CSS.sidebarPanelOpen);
            Topic.publish(Topic.SidebarButtonClicked,{sidebarKey: "directions"});
          }
        } catch(ex) {
          console.warn("Error Sidebar - Topic.DirectionsClicked");
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.ReloadViews,params => {
        try {
          Rdx.setValue(this,Rdx.Keys.SIDEBAR_ACTIVE_KEY,null);
          Rdx.setValue(this,Rdx.Keys.SIDEBAR_PANELS_EXPANDED,false);
          document.body.classList.remove(CSS.sidebarPanelOpen);
        } catch(ex) {
          console.error(ex);
        }
      }),

      Topic.subscribe(Topic.UIModeSet,params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.UIModeSet,params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.RenderSidebar, params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.ViewsReloaded, params => {
        component.refresh(this);
      }),

      Topic.subscribe(Topic.ReloadWorkspace, () => {
        component.refresh(this);
      })
    ]);
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  render() {
    const i18n = Context.getInstance().i18n;
    let aiim = Context.getInstance().aiim;
    let buttons = [], panels = [];
    let maxButtons = 6;

    const uiMode = Context.instance.uiMode;
    if ((uiMode.modeIsHorizontalSplit() && !uiMode.isTablet) || uiMode.modeIsVerticalSplit()) {
      maxButtons = 5;
    }

    let panelsClass = CSS.sidebarPanels; // TODO plus panelsClasses
    if (this.props.rdxSidebarActiveKey) panelsClass += " " + CSS.sidebarPanelsVisible;
    if (this.props.rdxSidebarPanelsExpanded) panelsClass += " " + CSS.expanded;
    if (this.props.rdxSidebarActiveKey === "events") panelsClass += " i-events-on";

    if (true) {
      let content;
      if (this.useExploreCIM()) {
        content = (<ExploreCIM sidebarKey="explore" />);
      } else {
        content = (<Explore sidebarKey="explore" />);
      }
      let button = (
        <SidebarButton key="explore" sidebarKey="explore"
          caption={i18n.explore.caption}
          tooltip={i18n.explore.caption}
          iconClassName="globe-16-f"
          arialLabel={i18n.explore.arialLabel} />
      );
      let panel = (
        <SidebarPanel key="explore" sidebarKey="explore">
          {content}
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    if (true) {
      let button = (
        <SidebarButton key="myPlaces" sidebarKey="myPlaces"
          caption={i18n.yourPlaces.caption}
          tooltip={i18n.yourPlaces.caption}
          arialLabel={i18n.yourPlaces.arialLabel}
          iconClassName="pin-16-f"
          liClassName="i-non-kiosk-only"/>
      );
      let panel = (
        <SidebarPanel key="myPlaces"sidebarKey="myPlaces">
          <MyPlaces sidebarKey="myPlaces" />
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    if (Context.getInstance().config.networkServiceUrl) {
      let button = (
        <SidebarButton key="directions" sidebarKey="directions"
          caption={i18n.directions.caption}
          tooltip={i18n.directions.caption}
          iconClassName="right-16-f"
          aria-label={i18n.directions.arialLabel}/>
      );
      let panel = (
        <SidebarPanel key="directions" sidebarKey="directions" keepAlive={true}>
          <Directions sidebarKey="directions"
            panelIsActive={(this.props.rdxSidebarActiveKey === "directions")} />
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    let eventsDataset = aiim && aiim.datasets && aiim.datasets.events;
    if (eventsDataset && eventsDataset.url && eventsDataset.getSource()) {
      let button = (
        <SidebarButton key="events" sidebarKey="events"
          caption={i18n.events.caption}
          tooltip={i18n.events.caption}
          arialLabel={i18n.events.arialLabel}
          iconClassName="calendar-16-f"
          liClassName="i-sidebar-button--events"/>
      );
      let panel = (
        <SidebarPanel key="events" sidebarKey="events">
          <Events sidebarKey="events" />
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    const hotelingEnabled = reservationUtil.supportsHotels();
    const datasets = Context.instance.aiim.datasets;
    const reservations = datasets && datasets.reservations;
    const errorHandlerHotel = reservations && reservations.errorHandler;
    const hotelErrors = errorHandlerHotel && errorHandlerHotel.getErrors();
    const hasHotelIssues = hotelErrors && hotelErrors.length > 0;
    const reservationTypeHotel = Context.getInstance().config.workspaceReservation.reservationTypeHotel;

    if (hotelingEnabled || ((reservationTypeHotel === "esri") && hasHotelIssues)) {
      let button = (
        <SidebarButton key="bookWorkspace" sidebarKey="bookWorkspace"
          caption={Context.instance.aiim.getHotelButtonLabel()}
          tooltip={Context.instance.aiim.getHotelButtonLabel()}
          arialLabel={i18n.more.bookWorkspace.ariaLabel}
          iconClassName="services-16" />
      );
      let panel = (
        <SidebarPanel key="bookWorkspace" sidebarKey="bookWorkspace">
          <BookWorkspace sidebarKey="bookWorkspace" panelIsActive={(this.props.rdxSidebarActiveKey === "bookWorkspace")}/>
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    const meetingRoomsEnabled = reservationUtil.supportsMeetingRooms();
    const errorHandler = reservations && reservations.errorHandlerMeetingRooms;
    const reservationErrors = errorHandler && errorHandler.getErrors();
    const hasReservationIssues = reservationErrors && reservationErrors.length > 0;
    const reservationTypeMeeting = Context.getInstance().config.workspaceReservation.reservationTypeMeeting;

    if (meetingRoomsEnabled || ((reservationTypeMeeting === "esri") && hasReservationIssues)) {
      let button = (
        <SidebarButton key="meetingRooms" sidebarKey="meetingRooms"
          caption={Context.instance.aiim.getMeetingButtonLabel()}
          tooltip={Context.instance.aiim.getMeetingButtonLabel()}
          arialLabel={i18n.meetingRooms.arialLabel}
          iconClassName="conference-room-16" />
      );
      let panel = (
        <SidebarPanel key="meetingRooms" sidebarKey="meetingRooms">
          <MeetingRooms sidebarKey="meetingRooms" panelIsActive={(this.props.rdxSidebarActiveKey === "meetingRooms")}/>
        </SidebarPanel>
      );
      buttons.push(button);
      panels.push(panel);
    }

    let morePanelKeys = [];
    if (buttons.length > maxButtons) {
      let buttonsA = buttons.slice(0,maxButtons - 1);
      let buttonsB = buttons.slice(maxButtons - 1);
      let morePanels = panels.slice(maxButtons - 1);

      let actions = [];
      buttonsB.forEach(b => {
        let action = {
          key: b.key,
          sidebarKey: b.props.sidebarKey,
          caption: b.props.caption,
          ariaLabel: b.props.ariaLabel,
          icon: b.props.iconClassName
        };
        if (action.icon && action.icon.endsWith("-f")) {
          action.icon = action.icon.substring(0,action.icon.length - 2);
        }
        actions.push(action);
      });

      morePanels.forEach(p => {
        morePanelKeys.push(p.props.sidebarKey);
      });

      let button = (
        <SidebarButton key="more" sidebarKey="more"
          caption={i18n.more.caption}
          arialLabel={i18n.more.arialLabel}
          iconClassName="ellipsis-16-f"/>
      );
      let panel = (
        <SidebarPanel key="more" sidebarKey="more">
          <More sidebarKey="more" actions={actions} />
        </SidebarPanel>
      );
      buttons = buttonsA;
      buttons.push(button);
      panels.push(panel);
    }
    Context.instance.session.morePanelKeys = morePanelKeys;

    return (
      <div className={CSS.main} role="navigation">
        <div className={CSS.sidebarMenus}>
          <ul className={CSS.sidebarMenu}>
            {buttons}
          </ul>
          <ul className={CSS.sidebarMenu2}>
            <SidebarButton2 key="about" tooltip={i18n.about.caption}
              iconClassName="information-16-f"
              clickHandler={this.showAbout}
            />
          </ul>
        </div>
        <div className={panelsClass}>
          {panels}
        </div>
      </div>
    );
  }

  showAbout(event) {
    Topic.publish(Topic.ShowModal,{content: <About />});
  }

  useExploreCIM() {
    const aiim = Context.instance.aiim;
    return aiim && aiim.useCIMCategories();
  }
}

const mapStateToProps = (state) => {
  return {
    rdxSidebarActiveKey: Rdx.getValue(state,Rdx.Keys.SIDEBAR_ACTIVE_KEY),
    rdxSidebarPanelsExpanded: Rdx.getValue(state,Rdx.Keys.SIDEBAR_PANELS_EXPANDED)
  }
}

export default connect(mapStateToProps)(Sidebar);
