import React from "react";

import Popover from "calcite-react/Popover"

import Button from "../../../common/components/Button";
import Topic from "../../../context/Topic";
import * as component from "../../../components/util/component";
import * as focusUtil from "../../../util/focusUtil";

let COUNTER = 0;

export default class DropdownButton extends React.Component {

  id;

  constructor(props) {
    super(props);
    this.id = "i-dd-" + COUNTER++;
    this.state = component.newState({
      open: false,
      wasFocused: false
    });
    this._clickContent = this._clickContent.bind(this);
    this._close = this._close.bind(this);
    this._keydownButton = this._keydownButton.bind(this);
    this._keydownContent = this._keydownContent.bind(this);
    this._toggle = this._toggle.bind(this);
  }

  _checkEsc(event) {
    if (!event) return false;
    if (event.keyCode === 27) {
      if (this.state.open) {
        event.preventDefault();
        event.stopPropagation();
        this._focusButton();
        this.setState({open: false});
        return true;
      }
    }
    return false;
  }

  _clickContent(event) {
    if (!event) return;
    if (this.props.closeOnSelect) {
      let targetNode = this._getFocusableTarget(event);
      //console.log("targetNode",targetNode);
      if (targetNode) {
        let nodes = this._getFocusables();
        let info = focusUtil.findTarget(nodes,targetNode);
        //console.log("targetNode.info",info);
        if (info.node) {
          this._focusButton();
          this.setState({open: false});
        }
      }
    }
  }

  // this method is no longer in use
  _close(event) {
    if (event && event.target && event.type === "touchstart") {
      const targetId = this.id+"-btn";
      const contentId = this.id+"-content";
      let node = document.activeElement;
      while (node) {
        if (node.id === targetId || node.id === contentId) {
          // on touch devices, tapping the dropdown button when it's is already
          // open will cause the dropdown to close and immediately re-open,
          // to avoid this disregard touchstart on the dropdown button itself
          return;
        }
        node = node.parentNode;
      }
    }
    this.setState({open: false});
  };

  componentDidMount() {
    component.own(this,[
      Topic.subscribe(Topic.UIModeSet,(params) => {
        if (typeof this.props.getPlacement === "function") {
          component.refresh(this);
        }
      })
    ]);
  }

  componentDidUpdate() {
    if (this.state.open && !this.state.wasFocused && this.props.focusFirst) {
      this._focus();
    }
  }

  componentWillUnmount() {
    component.componentWillUnmount(this);
  }

  _focus() {
    const contentId = this.id+"-content";
    const content = document.getElementById(contentId);
    if (content) {
      let first = content.querySelector("[data-i-checked='true']");
      if (first) {
        focusUtil.focusNode(first);
      } else {
        let nodes = this._getFocusables();
        if (nodes && nodes.length > 0) {
          focusUtil.focusNode(nodes[0]);
        }
      }
    }
    this.setState({wasFocused: true});
  }

  _focusButton() {
    const node = document.getElementById(this.id+"-btn");
    focusUtil.focusNode(node);
  }

  _focusStep(event,direction,isTabShift) {
    let nodes = this._getFocusables();
    if (nodes && nodes.length > 1) {
      let info = focusUtil.findTarget(nodes,event.target);
      let index = (direction === "previous")? info.previousIndex: info.nextIndex;
      if (index !== -1) {
        if (isTabShift && info.index === 0) {
          this._focusButton();
        } else {
          focusUtil.focusIndex(nodes,index);
        }
      }
    }
  }

  _getFocusables() {
    let nodes;
    const contentId = this.id+"-content";
    const content = document.getElementById(contentId);
    if (content) {
      const v = "[role='menuitem button'],[role='menuitem link']";
      nodes = content.querySelectorAll(v);
    }
    return nodes;
  }

  _getFocusableTarget(event) {
    let target = event && event.target;
    if (target) {
      let tag = target.nodeName.toLowerCase();
      if (tag !== "a" && tag !== "button") {
        target = target.parentNode;
        if (tag === "svg" && target && target.nodeName.toLowerCase() === "span") {
          target = target.parentNode;
        }
      }
    }
    return target;
  }

  _keydownButton(event) {
    this._checkEsc(event);
  }

  _keydownContent(event) {
    if (event && !this._checkEsc(event)) {
      if (event.keyCode === 9) { // tab
        event.preventDefault();
        event.stopPropagation();
        const dir = event.shiftKey? "previous": "next";
        this._focusStep(event,dir,!!event.shiftKey);
      } else if (event.keyCode === 38) { // up arrow
        event.preventDefault();
        event.stopPropagation();
        this._focusStep(event,"previous");
      } else if (event.keyCode === 40) { // down arrow
        event.preventDefault();
        event.stopPropagation();
        this._focusStep(event,"next");
      }
    }
  }

  render() {
    const open = !!this.state.open;
    //const closeOnSelect = !!this.props.closeOnSelect;
    const closeOnSelect = false;

    let className = "i-dd-button-container";
    let buttonClassName = "i-dd-button";
    let contentClassName = "i-dd-content";
    if (this.props.className) {
      className += " "+this.props.className;
    }
    if (this.props.buttonClassName) {
      buttonClassName += " "+this.props.buttonClassName;
    }
    if (this.props.contentClassName) {
      contentClassName += " "+this.props.contentClassName;
    }

    const buttonProps = this.props.buttonProps || {};

    let placement;
    if (typeof this.props.getPlacement === "function") {
      placement = this.props.getPlacement();
    } else {
      placement = this.props.placement;
    }
    if (placement === undefined) placement = "bottom";  // bottom-start bottom-end
    if (!placement) placement = undefined;

    let caret = (<span className="i-caret"></span>);

    // aria-controls="menu2"
    const targetId = this.id+"-btn";
    let button = (
      <Button id={targetId} type="button" clear
        {...buttonProps}
        className={buttonClassName}
        title={this.props.buttonTitle}
        disabled={!!this.props.disabled}
        aria-haspopup="listbox" aria-expanded={open}
        onClick={this._toggle} onKeyDown={this._keydownButton}>
        <>
          {this.props.buttonContent}
          {caret}
        </>
      </Button>
    );

    const contentId = this.id+"-content";
    let content = (
      <div id={contentId} className={contentClassName} role="none"
        onClick={this._clickContent} onKeyDown={this._keydownContent}>
        {this.props.dropdownContent}
      </div>
    );

    return (
      <div className={className}>
        <Popover
          closeOnSelect={closeOnSelect}
          onRequestClose={this._close}
          open={open}
          placement={placement}
          targetEl={button}>
          {content}
        </Popover>
      </div>
    );
  }

  _toggle() {
    this.setState(state => {
      const open = !state.open;
      const wasFocused = open ? false: true;
      return {
        open: open,
        wasFocused: wasFocused
      };
    });
  }

}
