// Framework and third-party non-ui
import React, { Component } from "react";

// Redux operations and local helpers/utils/modules
import { connect } from "react-redux";
import Rdx from "../../../redux/Rdx";
import Context from "../../../context/Context";
import { ModalController } from "../Modal";
import Topic from "../../../context/Topic";
import TransactionGuard from "../../base/TransactionGuard";
import * as component from "../../../components/util/component";
import * as officePlanUtil from "../../base/officePlanUtil";
import * as queryUtil from "../../base/queryUtil";
import * as transactions from "../../base/transaction/transactions";
import {
  canDeleteDuplicate,
  getAttributes,
  showBulkDuplicatesModal,
  showUnassignModal,
  unassignAdditionalAssignments,
  PersonDuplicate
} from "./MultipleAssignments/multipleAssignmentsUtil";
import { getAttributeValue } from "../../../aiim/util/aiimUtil";
import FieldNames from "../../../aiim/datasets/FieldNames";

// Component specific modules (Component-styled, etc.)
import { AssignToContainer } from "../../styles/Common/commonStyles";
import { ComboButton, Button } from "../../styles/Common/peopleAssignTo";

// App components
import OfficePlan, { SpaceAssignmentTypes } from "../../base/OfficePlan";
import SelectArea from "./SelectArea";
import SelectUnit from "./SelectUnit";
import UnassignPrompt from "./UnassignPrompt";

// Third-party components (buttons, icons, etc.)
import Menu, { MenuItem } from "calcite-react/Menu";
import UnitsIcon from "calcite-ui-icons-react/FloorPlanIcon";
import HomeIcon from "calcite-ui-icons-react/HomeIcon";
import WorkspaceAreaIcon from "calcite-ui-icons-react/AppsIcon";
import UnassignIcon from "calcite-ui-icons-react/MinusCircleIcon";
import TrashIcon from "calcite-ui-icons-react/TrashIcon";
import ReactTooltip from "react-tooltip";
import Popover from 'calcite-react/Popover';
import { getHotdesksEnabled, getHotelsEnabled, getMeetingRoomsEnabled } from "../../redux";
import { IFeature } from "@esri/arcgis-rest-types";

export interface IFeatureItem {
  key: string,
  objectId: number,
  feature: __esri.Graphic | IFeature
}
interface IProps {
  buttons?: boolean,
  disableUnassign: boolean,
  enableUnassign: boolean,
  featureItems: IFeatureItem[],
  isPopup?: boolean,
  rdxFeatureItemsKey: string,
  rdxHotdesksEnabled: boolean,
  rdxHotelsEnabled: boolean,
  rdxMeetingRoomsEnabled: boolean,
  name: string,
  noMargin?: boolean,
  noWrap: boolean,
  onAssignment?: () => void,
  type?: string
}
interface IState {
  isButtons: boolean,
  menuOpen: boolean,
  name: string
}
class PeopleAssignTo extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isButtons: this.props.buttons !== undefined ? this.props.buttons : false,
      name: this.props.name || "",
      menuOpen: false
    };
  }

  togglePopover = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  closePopover = () => {
    this.setState({
      menuOpen: false
    })
  }

  componentDidMount = () => {};

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  };

  componentWillUnmount = () => {
    component.componentWillUnmount(this);
  };

  assignPeopleToArea = (areaItem: __esri.Graphic, areaType: "home" | "workspaceArea", duplicatePeople?: PersonDuplicate[]) => {
    const personFeatureItems = this.getFeatureItems();
    const guard = new TransactionGuard({ featureItems: personFeatureItems });
    const allAssignedToArea = personFeatureItems.length > 1
    transactions
      .assignPeopleToArea(personFeatureItems, areaItem, areaType, duplicatePeople)
      .then((result) => {
        guard.close();
        // if (!this.props.isPopup) {
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
          allAssignedToArea: allAssignedToArea
        });
        // }
        this.onAssignment();
      })
      .catch((error) => {
        guard.close();
        console.error("Error assigning people to area", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  duplicatePersonToArea = (areaItem: __esri.Graphic, duplicatePeople?: PersonDuplicate[]) => {
    if (this.getFeatureItems().length !== 1) return
    const personFeatureItem = this.getFeatureItems()[0]
    const guard = new TransactionGuard({ featureItems: [personFeatureItem] });
    transactions
      .addPerson(personFeatureItem, null, areaItem, false, duplicatePeople)
      .then((result) => {
        guard.close();
        // if (!this.props.isPopup) {
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated
        });
        // }
        this.onAssignment();
      })
      .catch((error) => {
        guard.close();
        console.error("Error assigning people to area", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  assignPeopleToUnit = (unitFeatureItem: IFeatureItem, unassignOthers?: boolean, duplicatePeople?: PersonDuplicate[]) => {
    const personFeatureItems = this.getFeatureItems();
    const guard = new TransactionGuard({ featureItems: personFeatureItems });
    transactions
      .assignPeopleToUnit(personFeatureItems, unitFeatureItem, unassignOthers, duplicatePeople)
      .then((result) => {
        guard.close();
        // if (!this.props.isPopup) {
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // }
        this.onAssignment();
      })
      .catch((error) => {
        guard.close();
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  };

  duplicatePersonToUnit = (unitFeatureItem: IFeatureItem, unassignOthers: boolean, duplicatePeople?: PersonDuplicate[]) => {
    if (this.getFeatureItems().length !== 1) return
    const personFeatureItem = this.getFeatureItems()[0]
    const guard = new TransactionGuard({ featureItems: [personFeatureItem] });
    transactions
      .addPerson(personFeatureItem, unitFeatureItem, null, unassignOthers, duplicatePeople)
      .then((result) => {
        guard.close();
        // if (!this.props.isPopup) {
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // }
        this.onAssignment();
      })
      .catch((error) => {
        guard.close();
        console.error("Error assigning person to unit", error);
        Topic.publishErrorUpdatingData(error.submessage);
      });
  }

  getUniquePeopleCount = () => {
    const uniquePeople = []
    const features = this.getFeatureItems()
    if (features) {
      features.forEach(person => {
        const personAttributes = getAttributes(person)
        const uniqueId = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
        if (!uniquePeople.includes(uniqueId)) uniquePeople.push(uniqueId)
      })
    }
    return uniquePeople.length
  }

  assignToClicked = (evt, option) => {
    if (!this.canAssign(this.getFeatureItems())) return;
    const value = option.value;
    if (value === "assignToUnit") {
      this.assignToUnitClicked(option.caption, evt);
    } else if (value === "assignToWorkspaceArea") {
      this.assignToAreaClicked(option.caption, "workspaceArea", evt);
    } else if (value === "assignToHome") {
      let areaType = OfficePlan.SpaceAssignmentTypes.home;
      this.assignToAreaClicked(option.caption, areaType, evt);
    } else if (value === "assignToNone") {
      this.assignToNoneClicked(evt);
    }
  };

  showUnassignFromAreaModal(
    featureItems: IFeatureItem[],
    areaItem: __esri.Graphic,
    areaType: "home" | "workspaceArea") {
    
    const i18n = Context.getInstance().i18n;
    const to = areaType === SpaceAssignmentTypes.home
      ? i18n.spaceplanner.multipleAssignments.assignTo.home
      : i18n.spaceplanner.multipleAssignments.assignTo.workspaceArea;

    if (featureItems.length === 1) {
      const featureItem = featureItems[0];
      let personAssignments = null;
      const setAssignments = (assignments) => personAssignments = assignments;
      const onOK = (unassignAssignments: boolean, keepCurrentAssignment: boolean) => {
        if (unassignAssignments && keepCurrentAssignment) {
          unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
            this.duplicatePersonToArea(areaItem, duplicatePeople);
          }).catch(e => {
            console.error("Couldn't remove additional assignments", e);
          })
        } else if (unassignAssignments) {
          unassignAdditionalAssignments(personAssignments, featureItem).then(duplicatePeople => {
            this.assignPeopleToArea(areaItem, areaType, duplicatePeople);
          }).catch(e => {
            console.error("Couldn't remove additional assignments", e);
          })
        } else if (keepCurrentAssignment) {
          this.duplicatePersonToArea(areaItem);
        } else {
          this.assignPeopleToArea(areaItem, areaType);
        }
      }

      showUnassignModal({
        personFeature: featureItem,
        assignToFeature: areaItem,
        plural: false,
        to,
        showKeepCurrentAssignment: true,
        onOK: onOK,
        setAssignments
      });
    } else {
      const onOK = (keepCurrentAssignments: boolean) => {
        if (keepCurrentAssignments) {
          // Duplicate
          this.bulkDuplicatePeopleToArea(areaItem);
        } else {
          this.bulkAssignPeopleToArea(areaItem);
        }
      }
      if (this.props.disableUnassign) {
        this.assignPeopleToArea(areaItem, areaType);
      } else {
        // Check if all unassigned        
        let allUnassigned = true
        if (featureItems && featureItems.length > 0) {
          featureItems.forEach(person => {
            if (!officePlanUtil.isPersonUnassigned(person)) {
              allUnassigned = false;
            }
          });
          if (allUnassigned) {
            onOK(false);
          } else {
            showBulkDuplicatesModal({
              people: featureItems,
              assignToFeature: areaItem,
              plural: true,
              to: to,
              onOK: onOK
            })
          }
        }
      }
    }
  }

  assignToAreaClicked = async (title: string, areaType: "home" | "workspaceArea", evt: Event) => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems())) return;
    if (areaType === SpaceAssignmentTypes.home) {
      const areaItem: __esri.Graphic = OfficePlan.getActive().areasTable.HomeOffice;
      this.showUnassignFromAreaModal(this.getFeatureItems(), areaItem, areaType);
    } else {

      let disabledAreaIds = [];
      try {
        const items = this.getFeatureItems();
        if (items && items.length === 1) {
          disabledAreaIds = await officePlanUtil.queryOccupantAreaIDs(items[0].feature);
        }
      } catch(ex) {
        console.error(ex);
      }

      SelectArea.showModal({
        title: title,
        areaType: areaType,
        disabledAreaIds: disabledAreaIds,
        promptOptions: {
          subject: "people",
          subjectItems: this.getFeatureItems(),
          target: areaType,
        },
        onOK: (sel) => {
          const areaItem = sel;
          if (areaItem) {
            this.showUnassignFromAreaModal(this.getFeatureItems(), areaItem, areaType);           
          }
        },
      });
    }
  };

  assignToUnitClicked = async (title: string, evt: Event) => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems())) return;

    let disabledUnitIds = [];
    try {
      const items = this.getFeatureItems();
      if (items && items.length === 1) {
        disabledUnitIds = await officePlanUtil.queryOccupantUnitIDs(items[0].feature);
      }
    } catch(ex) {
      console.error(ex);
    }

    SelectUnit.showModal({
      title: title,
      returnGeometry: true,
      disabledUnitIds: disabledUnitIds,
      promptOptions: {
        subject: "people",
        subjectItems: this.getFeatureItems(),
        target: "unit",
      },
      onOK: (sel) => {
        let objectId = sel && sel.objectId;
        if (typeof objectId === "number") {
          const source = officePlanUtil.getUnitsSource();
          const featureItem: IFeatureItem = queryUtil.makeFeatureItem(source, sel.feature);
          if (this.getFeatureItems().length === 1) {
            const i18n = Context.instance.i18n
            const personFeature = this.getFeatureItems()[0]
            let personAssignments = null
            const setAssignments = (assignments) => {
              personAssignments = assignments
            }
            const onOK = (unassignAssignments, keepCurrentAssignment, unassignOthers) => {
              if (unassignAssignments && keepCurrentAssignment) {
                unassignAdditionalAssignments(personAssignments, personFeature).then(duplicatePeople => {
                  this.duplicatePersonToUnit(featureItem, unassignOthers, duplicatePeople)
                }).catch(e => {
                  console.error("Couldn't remove additional assignments", e)
                })
              } else if (unassignAssignments) {
                unassignAdditionalAssignments(personAssignments, personFeature).then(duplicatePeople => {
                  this.assignPeopleToUnit(featureItem, unassignOthers, duplicatePeople)
                }).catch(e => {
                  console.error("Couldn't remove additional assignments", e)
                })
              } else if (keepCurrentAssignment) {
                this.duplicatePersonToUnit(featureItem, unassignOthers)
              } else {
                this.assignPeopleToUnit(featureItem, unassignOthers)
              }
            }
            const to = i18n.spaceplanner.multipleAssignments.assignTo.unit
            showUnassignModal({
              personFeature: personFeature,
              assignToFeature: featureItem,
              plural: false,
              to: to,
              showKeepCurrentAssignment: true,
              onOK: onOK,
              setAssignments: setAssignments
            })
          } else {
            const onOK = (keepCurrentAssignments) => {
              if (keepCurrentAssignments) {
                // Duplicate
                this.bulkDuplicatePeopleToUnit(featureItem)
              } else {
                this.bulkAssignPeopleToUnit(featureItem)
              }
            }
            if (this.props.disableUnassign) {
              this.assignPeopleToUnit(featureItem);
            } else {
              // Check if all unassigned
              const people = this.getFeatureItems()
              let allUnassigned = true
              if (people && people.length > 0) {
                people.forEach(person => {
                  if (!officePlanUtil.isPersonUnassigned(person)) {
                    allUnassigned = false
                  }
                })
                if (allUnassigned) {
                  onOK(false)
                } else {
                  const i18n = Context.instance.i18n
                  const to = i18n.spaceplanner.multipleAssignments.assignTo.unit
                  showBulkDuplicatesModal({
                    people: this.getFeatureItems(),
                    assignToFeature: featureItem,
                    plural: true,
                    to: to,
                    onOK: onOK
                  })
                }
              }
            }
          }
        }
      },
    });
  };

  assignToNoneClicked = async (evt: Event) => {
    if (evt) evt.stopPropagation();
    if (!this.canAssign(this.getFeatureItems())) return;
    const i18n = Context.instance.i18n;
    let title = i18n.spaceplanner.unassignPrompt.removeAssignmentsTitle;
    const featureItems = this.getFeatureItems();
    const uniquePeopleDict = {}
    // let peopleFeatureItems = featureItems
    let peopleFeatureItems = []
    if (featureItems && featureItems.length > 0) {
      featureItems.forEach(person => {
        const personAttributes = getAttributes(person)
        const uniqueId = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
        const unassigned = officePlanUtil.isPersonUnassigned(person)
        if (!uniquePeopleDict[uniqueId] && !unassigned) {
          uniquePeopleDict[uniqueId] = [person]
        } else if (uniquePeopleDict[uniqueId] && !unassigned) {
          uniquePeopleDict[uniqueId].push(person)
        }
      })
      peopleFeatureItems = Object.keys(uniquePeopleDict).map(key => uniquePeopleDict[key][0])
    }
    let promptOptions: {
      subject: string,
      subjectItems?: __esri.Graphic[],
      subjectItem?: __esri.Graphic,
      fromName?: string
    } = {
      subject: "people",
      subjectItems: peopleFeatureItems,
    };
    if (peopleFeatureItems.length === 1) {
      const person = peopleFeatureItems[0]
      const personAttributes = getAttributes(person)
      const uniqueId = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const assignments = []
      if (uniquePeopleDict[uniqueId] && uniquePeopleDict[uniqueId].length > 0) {
        await Promise.all(uniquePeopleDict[uniqueId].map(async p => {
          let v = await officePlanUtil.getPersonAssignmentNameAsync(p);
          assignments.push(v)
        }))
      } else {
        let v = await officePlanUtil.getPersonAssignmentNameAsync(person);
        assignments.push(v)
      }

      title = i18n.spaceplanner.unassignPrompt.removeAssignmentTitle;
      const featureItem = peopleFeatureItems[0];
      if (!officePlanUtil.isPersonUnassigned(featureItem)) {
        const fromName = assignments.join(", ")
        promptOptions = {
          subject: "person",
          subjectItem: featureItem,
          fromName: fromName,
        };
      }
    }
    const content = (
      <UnassignPrompt
        promptOptions={promptOptions}
        peopleFeatureItems={peopleFeatureItems}
      />
    );
    const controller = new ModalController({});
    let okButton;
    let props = {
      title: title,
      showOKCancel: true,
      closeOnOK: true,
      okLabel: i18n.spaceplanner.unassign,
      onOK: () => {
        if (okButton) okButton.disable();
        if (featureItems.length === 1) {
          this.unassignPeople();
        } else {
          this.bulkUnassignPeople()
        }
      },
      onMountOKButton: (btn) => {
        okButton = btn;
      },
    };
    controller.show(props, content);
  };

  deleteClicked = (e: Event) => {
    if (e) e.stopPropagation();
    if (!this.isPlaceholder(this.getFeatureItems())) return;
    const i18n = Context.instance.i18n;
    let guard;
    const options = {
      title: i18n.spaceplanner.people.remove.title,
      message: i18n.spaceplanner.people.remove.message,
      okLabel: i18n.general.del,
      closeOnOK: false,
      closeOnCancel: false
    }
    ModalController.confirm(options).then(result => {
      if (result.ok) {
        result.controller.close();
        const peopleFeatureItems = this.getFeatureItems();
        guard = new TransactionGuard({ force: true });
        return transactions.deletePeople(peopleFeatureItems, true);
      } else {
        result.controller.close();
      }
    }).then(() => {
      if (guard) guard.close();
      Topic.publish(Topic.PlanModified, {
        action: OfficePlan.Action_AssignmentsUpdated,
        attributeEditorLayerType: "People"
      })
    }).catch((error) => {
      if (guard) guard.close();
      console.error("Error updating feature", error);
      Topic.publishErrorUpdatingData(error.submessage);
    });
  }

  canAssign = (featureItems: IFeatureItem[]) => {
    return (
      featureItems &&
      featureItems.length > 0 &&
      featureItems.length <= this.getMaxFeatureItems()
    );
  };

  canUnassign = (featureItems: IFeatureItem[]) => {
    if (!featureItems || featureItems.length === 0) {
      return false;
    } else if (this.props.disableUnassign) {
      return false;
    } else if (this.props.enableUnassign) {
      return true;
    }
    for (let i = 0; i < featureItems.length; i++) {
      if (!officePlanUtil.isPersonUnassigned(featureItems[i])) {
        return true;
      }
    }
    return false;
  };

  getFeatureItems = () => {
    return this.props.featureItems;
  };

  getMaxFeatureItems = () => {
    return 1001;
  };

  onAssignment = () => {
    if (typeof this.props.onAssignment === "function") {
      this.props.onAssignment();
    }
  };

  isPlaceholder = (featureItems: IFeatureItem[]) => {
    if (!featureItems || featureItems.length !== 1) return false;
    for (let i = 0; i < featureItems.length; i++) {
      return officePlanUtil.isPersonPlaceholder(featureItems[i])
    }
  }

  renderButtons = () => {
    const i18n = Context.instance.i18n;
    const featureItems = this.getFeatureItems();
    const hasUnassignButton = this.canUnassign(featureItems);
    const isPlaceholder = this.isPlaceholder(featureItems);
    const rdxFeatureItemsKey = this.props.rdxFeatureItemsKey;
    const isWorkbar = rdxFeatureItemsKey.includes('workbar') ? true : false;
    const isAssetbar = rdxFeatureItemsKey.includes('assetbar') ? true : false;
    const isUnassigned = this.props.disableUnassign;
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    const captionKey =
      featureItems && featureItems.length > 1
        ? "captionPlural"
        : "captionSingular";
    return (
      <>
        <Button
          onClick={evt =>
            this.assignToUnitClicked(
              i18n.spaceplanner.people.assignTo.unit[captionKey],
              evt
            )
          }
          icon={<UnitsIcon size={16} />}
          iconPosition="before"
          data-tip={i18n.spaceplanner.people.assignTo.unit.label}
          data-for="genericTooltip"
        />
        {(rdxHotelsEnabled || rdxHotdesksEnabled || rdxMeetingRoomsEnabled) &&
          <Button
            onClick={evt =>
              this.assignToAreaClicked(
                i18n.spaceplanner.people.assignTo.workspaceArea[captionKey],
                "workspaceArea",
                evt
              )
            }
            icon={<WorkspaceAreaIcon size={16} />}
            iconPosition="before"
            data-tip={i18n.spaceplanner.people.assignTo.workspaceArea.label}
            data-for="genericTooltip"
          />
        }
        <Button
          onClick={evt =>
            this.assignToAreaClicked(
              i18n.spaceplanner.people.assignTo.home[captionKey],
              OfficePlan.SpaceAssignmentTypes.home,
              evt
            )}
          icon={<HomeIcon size={16} />}
          iconPosition="before"
          data-tip={i18n.spaceplanner.people.assignTo.home.label}
          data-for="genericTooltip"
        />
        {hasUnassignButton ? (
          <Button
            clear
            icon={<UnassignIcon size={16} />}
            iconPosition="before"
            onClick={evt => this.assignToNoneClicked(evt)}
            data-tip={i18n.spaceplanner.unassign}
            data-for="genericTooltip"
          />
        ) : null}
        {isPlaceholder && (isWorkbar || (isAssetbar && isUnassigned)) ? (
          <Button
            clear
            icon={<TrashIcon size={16} />}
            iconPosition='before'
            onClick={this.deleteClicked}
            data-tip={i18n.general.del}
            data-for='genericTooltip'
          />
        ) : null}
      </>
    );
  };

  renderCombo = () => {
    const i18n = Context.instance.i18n;
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    const featureItems = this.getFeatureItems();
    const disabled = !this.canAssign(featureItems);
    const hasUnassignButton = this.canUnassign(featureItems);
    let captionKey = "captionSingular";
    const count = this.getUniquePeopleCount()
    if (featureItems && count > 1) captionKey = "captionPlural";

    const options = [
      {
        label: i18n.spaceplanner.people.assignTo.unit.label,
        caption: i18n.spaceplanner.people.assignTo.unit[captionKey],
        value: "assignToUnit",
      }
    ];
    if (rdxHotdesksEnabled || rdxHotelsEnabled || rdxMeetingRoomsEnabled) {
      options.push({
        label: i18n.spaceplanner.people.assignTo.workspaceArea.label,
        caption: i18n.spaceplanner.people.assignTo.workspaceArea[captionKey],
        value: "assignToWorkspaceArea",
      });
    }
    options.push({
      label: i18n.spaceplanner.people.assignTo.home.label,
      caption: i18n.spaceplanner.people.assignTo.home[captionKey],
      value: "assignToHome"
    });

    const menuItems = [];
    options.forEach((option) => {
      menuItems.push(
        <MenuItem
          key={option.value}
          onClick={evt => this.assignToClicked(evt, option)}
        >
          {option.label}
        </MenuItem>
      );
    });

    return (
      <AssignToContainer noWrap={this.props.noWrap} noMargin={this.props.noMargin}>
        <ComboButton
          title={i18n.spaceplanner.people.assignTo.label}
          label={i18n.spaceplanner.people.assignTo.label}
          disabled={disabled}
          onClick={evt =>
            this.assignToUnitClicked(
              i18n.spaceplanner.people.assignTo.unit[captionKey],
              evt
            )
          }
        >
          <Menu>{menuItems}</Menu>
        </ComboButton>

        {hasUnassignButton ? (
          <Button
            title={i18n.spaceplanner.unassign}
            clear
            disabled={disabled}
            noWrap={this.props.noWrap}
            onClick={evt => this.assignToNoneClicked(evt)}
          >
            {i18n.spaceplanner.unassign}
          </Button>
        ) : null}
      </AssignToContainer>
    );
  };

  renderPopupAssignButton = () => {
    const i18n = Context.instance.i18n;
    const { rdxHotdesksEnabled, rdxHotelsEnabled, rdxMeetingRoomsEnabled } = this.props;
    const btn = (
      <Button className={"i-assign-btn"}
        onClick={this.togglePopover}>
        <span>{i18n.spaceplanner.popup.assignBtn}</span>
      </Button>
    )

    const makeMenu = () => {
      const type = this.props.type
      if (!type) return null

      const style = { maxWidth: '10rem' }
      const unitCaption = i18n.spaceplanner.people.assignTo.unit.captionSingular
      const workspaceCaption = i18n.spaceplanner.people.assignTo.workspaceArea.captionSingular
      const homeCaption = i18n.spaceplanner.people.assignTo.home.captionSinguler;
      if (type === "person") {
        return (
          <Menu style={style}>
            <MenuItem onClick={e => {
              this.assignToClicked(e, { value: "assignToUnit", caption: unitCaption })
            }}>{i18n.spaceplanner.assignmentType.unit}</MenuItem>
            {(rdxHotdesksEnabled || rdxHotelsEnabled || rdxMeetingRoomsEnabled) &&
              <MenuItem onClick={e => {
                this.assignToClicked(e, { value: "assignToWorkspaceArea", caption: workspaceCaption })
              }}>{i18n.spaceplanner.people.assignTo.workspaceArea.label}</MenuItem>
            }
            <MenuItem onClick={e => {
              this.assignToClicked(e, { value: "assignToHome", caption: homeCaption })
            }}>{i18n.spaceplanner.assignmentType.home}</MenuItem>
          </Menu>
        )
      } else return null
    }

    return (
      <Popover
        targetEl={btn}
        open={this.state.menuOpen}
        onRequestClose={this.closePopover}
        positionFixed
      >
        {makeMenu()}
      </Popover>
    )
  }

  render() {
    if (!!this.props.isPopup) {
      return this.renderPopupAssignButton()
    } else {
      const { isButtons } = this.state;
      return isButtons ? this.renderButtons() : this.renderCombo();
    }
  }

  bulkDuplicatePeopleToArea = (areaFeature: __esri.Graphic) => {
    const featureItems = this.getFeatureItems()
    const guard = new TransactionGuard({ featureItems: featureItems })
    transactions
      .bulkPanelDuplicatePeopleToArea(featureItems, areaFeature)
      .then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // this.onAssignment();
      }).catch(e => {
        guard.close();
        console.error("Error duplicating people", e);
        Topic.publishErrorUpdatingData(e.submessage);
      })
  }

  bulkAssignPeopleToArea = (areaFeature: __esri.Graphic) => {
    const featureItems = this.getFeatureItems()
    const guard = new TransactionGuard({ featureItems: featureItems })
    transactions
      .bulkPanelAssignPeopleToArea(featureItems, areaFeature)
      .then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // this.onAssignment();
      }).catch(e => {
        guard.close();
        console.error("Error assigning people", e);
        Topic.publishErrorUpdatingData(e.submessage);
      })
  }

  bulkDuplicatePeopleToUnit = (unitFeature: IFeatureItem) => {
    const featureItems = this.getFeatureItems()
    const guard = new TransactionGuard({ featureItems: featureItems })
    transactions
      .bulkPanelDuplicatePeopleToUnit(featureItems, unitFeature)
      .then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // this.onAssignment();
      }).catch(e => {
        guard.close();
        console.error("Error duplicating people", e);
        Topic.publishErrorUpdatingData(e.submessage);
      })
  }

  bulkAssignPeopleToUnit = (unitFeature: IFeatureItem) => {
    const featureItems = this.getFeatureItems()
    const guard = new TransactionGuard({ featureItems: featureItems })
    transactions
      .bulkPanelAssignPeopleToUnit(featureItems, unitFeature)
      .then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // this.onAssignment();
      }).catch(e => {
        guard.close();
        console.error("Error assigning people", e);
        Topic.publishErrorUpdatingData(e.submessage);
      })
  }

  bulkUnassignPeople = () => {
    const featureItems = this.getFeatureItems()
    const guard = new TransactionGuard({ featureItems: featureItems })
    transactions
      .bulkPanelUnassignPeople(featureItems)
      .then(result => {
        guard.close()
        Topic.publish(Topic.PlanModified, {
          action: OfficePlan.Action_AssignmentsUpdated,
        });
        // this.onAssignment();
      }).catch(e => {
        guard.close();
        console.error("Error unassigning people", e);
        Topic.publishErrorUpdatingData(e.submessage);
      })
  }

  unassignPeople = () => {
    const featureItems = this.getFeatureItems();
    if (!this.canAssign(featureItems)) return;
    const guard = new TransactionGuard({ featureItems: featureItems });
    const promises = []
    const duplicatePeople = [], singleRecords = []
    featureItems.forEach(person => {
      const personAttributes = getAttributes(person)
      const personName = getAttributeValue(personAttributes, FieldNames.PEOPLE_FULLNAME)
      const personEmail = getAttributeValue(personAttributes, FieldNames.PEOPLE_EMAIL)
      const promise = canDeleteDuplicate(personName, personEmail).then(result => {
        if (result) duplicatePeople.push(person)
        else singleRecords.push(person)
      })
      promises.push(promise)
    })
    Promise.all(promises).then(() => {
      transactions
        .unassignPeople(singleRecords, duplicatePeople)
        .then((result) => {
          guard.close();
          Topic.publish(Topic.PlanModified, {
            action: OfficePlan.Action_AssignmentsUpdated,
          });
          this.onAssignment();
        })
        .catch((error) => {
          guard.close();
          console.error("Error unassigning people", error);
          Topic.publishErrorUpdatingData(error.submessage);
        });
    }).catch(e => {
      guard.close();
      Topic.publishErrorUpdatingData(e.submessage);
      console.error("Error querying people", e)
    })
  };
}

const mapStateToProps = (state, ownProps) => {
  const rdxFeatureItemsKey = ownProps.rdxFeatureItemsKey;
  const props = {
    rdxHotdesksEnabled: getHotdesksEnabled(state),
    rdxHotelsEnabled: getHotelsEnabled(state),
    rdxMeetingRoomsEnabled: getMeetingRoomsEnabled(state)
  };
  if (rdxFeatureItemsKey && !ownProps.featureItems) {
    return { ...props, featureItems: Rdx.getValue(state, rdxFeatureItemsKey) };
  } else {
    return { ...props };
  }
};

export default connect(mapStateToProps)(PeopleAssignTo);
