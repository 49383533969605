import styled from "styled-components";
import { unitCalc } from "calcite-react/utils/helpers";

import CalciteButton from "calcite-react/Button";
import CalciteMenu from "calcite-react/Menu";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  padding: ${props => unitCalc(props.theme.baseline, 3, "/")}
    ${props => unitCalc(props.theme.baseline, 1.5, "*")};

  html[dir="rtl"] & {
    left: 15px;
    right: auto;
  }
`;

const Title = styled.p`
  overflow: hidden;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Button = styled(CalciteButton)`
  &:hover {
    color: ${props => props.theme.palette.themeColor};
  }
`;

const Menu = styled(CalciteMenu)`
  z-index: ${props => props.theme.levels.top};
  padding: 0 1rem;
`;

const InfoField = styled.p`
  margin-block-start: 1rem;
  margin-block-end: 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${props => props.theme.palette.gray};
`;

const InfoAttr = styled.p`
  margin-block-start: 0;
  margin-block-end: 1rem;
`;

export { Container, Title, Button, Menu, InfoField, InfoAttr };
