import React from "react";

import * as component from "../../../components/util/component";

import "@esri/calcite-components/dist/components/calcite-input-message";
import {
  CalciteInputMessage
} from "@esri/calcite-components-react";

interface Props {
  message: string
}

interface State {
  message: string
}

export default class Info extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = component.newState({});
  }

  render() {
    const { message } = this.props;
    if (message) {
      return (
        <div className="i-input-information-container">
          <CalciteInputMessage icon="information">{message}</CalciteInputMessage>
        </div>
      )
    }
    return null;
  }

}
