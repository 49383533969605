import React from "react";

import Context from "../../../context/Context";
import { FpeType } from "../../components/Editor/support/StencilLoader";
import Issues from "./Issues";
import ItemBrowser from "../common/components/browser/ItemBrowser";
import StencilButton from "../../components/Editor/place/StencilButton";
import StencilLoader, { UseTypeIssues } from "../../components/Editor/support/StencilLoader";
import Topic from "../../../context/Topic";
import Warning from "./Warning";
import * as component from "../../../components/util/component";
import * as configUtil from "./configUtil";

import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import {
  CalciteBlock,
  CalciteButton,
  CalciteLabel,
  CalciteRadioButton,
  CalciteRadioButtonGroup
} from "@esri/calcite-components-react";

interface Props {
  onChange: ({isValid}) => void;
}

interface State {
  itemBrowserOpen: boolean,
  loadingItem: boolean,
  paletteInfo: any,
  selectedItem: any,
  type: string
}

export default class Palette extends React.Component<Props, State> {

  showSelectAnItem = false;
  showSelectAnItemTimeout = null;

  constructor(props) {
    super(props);
    this.state = component.newState({
      itemBrowserOpen: false,
      loadingItem: true,
      paletteInfo: null,
      selectedItem: null,
      type: "default"
    });
  }

  componentDidMount() {
    const itemId = Context.instance.config.spaceplanner.palette.itemId;
    this.load(itemId);
    component.own(this,[
      Topic.subscribe(Topic.ViewsReloaded,params => {
        const { paletteInfo, type } = this.state;
        let id;
        if (type === "custom" && paletteInfo) id = paletteInfo.itemId;
        this.load(id);
      })
    ]);
  }

  onChange() {
    const { paletteInfo, type } = this.state
    let hasIssues = false;
    if (type === "custom") {
      hasIssues = !paletteInfo || (paletteInfo.issues && paletteInfo.issues.length > 0);
    } else {
      hasIssues = paletteInfo && (paletteInfo.issues && paletteInfo.issues.length > 0);
    }
    this.props.onChange({isValid: !hasIssues});
  }

  async load(itemId?) {
    itemId = itemId || null;
    const type = itemId ? "custom" : "default";
    try {
      let paletteInfo;
      const sl = new StencilLoader();
      if (itemId) {
        paletteInfo = await sl.loadItem(itemId);
      } else {
        paletteInfo = await sl.loadDefault();
      }
      this.setState({type, paletteInfo, loadingItem: false}, this.onChange);
      if (paletteInfo && (!paletteInfo.issues || paletteInfo.issues.length === 0)) {
        sl.setPaletteInfo(paletteInfo);
        Context.instance.config.spaceplanner.palette.itemId = itemId;
        Topic.publish(Topic.FpePaletteRefreshed,{});
      }
    } catch(ex) {
      console.error(ex);
      this.setState({type, loadingItem: false}, this.onChange);
    }
  }

  render() {
    const i18n = Context.instance.i18n;
    const { loadingItem, paletteInfo, type } = this.state
    return (
      <CalciteBlock 
        open 
        heading={i18n.miniapps.configurator.palette.caption}
        loading={loadingItem ? true : undefined}
      >
        {this.renderChoice()}
        {(type === "custom") && this.renderLayerInfo()}
        {(type === "default" && paletteInfo) && this.renderIssues()}
        {this.renderPalette()}
      </CalciteBlock>
    )
  }

  renderChoice() {
    const i18n = Context.instance.i18n;
    const { type } = this.state;
    return (
      <CalciteRadioButtonGroup name="cfgPaletteOption" layout="vertical" scale="s"
        onCalciteRadioButtonGroupChange={(e => {
          const v = e.target.selectedItem.value;
          if (v === "custom" && v !== type) {
            this.showSelectAnItem = false;
            clearTimeout(this.showSelectAnItemTimeout);
            this.setState({type: v, itemBrowserOpen: true, paletteInfo: null}, this.onChange);
            this.showSelectAnItemTimeout = setTimeout(() => {
              this.showSelectAnItem = true;
              component.refresh(this);
            }, 500)
          } else {
            this.showSelectAnItem = false;
            clearTimeout(this.showSelectAnItemTimeout);
            this.setState({type: v}, () => this.load(null));
          }
        })}>
        <CalciteLabel scale="s" layout="inline" className="i-cursor-pointer">
          <CalciteRadioButton value="default" checked={type === "default" ? true: undefined} />
          {i18n.miniapps.configurator.palette.useDefault}
        </CalciteLabel>
        <CalciteLabel scale="s" layout="inline" className="i-cursor-pointer">
          <CalciteRadioButton value="custom" checked={type === "custom" ? true: undefined} />
          {i18n.miniapps.configurator.palette.useCustom}
        </CalciteLabel>
      </CalciteRadioButtonGroup>
    )
  }

  renderIssues() {
    const i18n = Context.instance.i18n;
    const { paletteInfo } = this.state
    let issues = paletteInfo && paletteInfo.issues;
    if (!paletteInfo && this.showSelectAnItem) {
      issues = [{
        key: "selectAnItem",
        message: i18n.miniapps.configurator.palette.selectAnItem
      }]
    }
    if (issues && issues.length > 0) {
      return <Issues issues={issues} />
    }
    return null;
  }

  renderItemBrowser() {
    const i18n = Context.instance.i18n;
    const { itemBrowserOpen } = this.state;
    if (itemBrowserOpen) {
      return (
        <ItemBrowser
          open={itemBrowserOpen}
          title={i18n.miniapps.configurator.palette.customFeatureLayer}
          subtitle={i18n.miniapps.configurator.palette.customInfo}
          itemType="Feature Service"
          rdxKey="cfg-palette-feature-layer"
          onClose={() => {
            this.setState({itemBrowserOpen: false})
          }}
          onSelect={(item) => {
            //Context.instance.config.webmap = item.id;
            //Topic.publish(Topic.ReloadViews,{});
            this.setState({
              loadingItem: true,
              itemBrowserOpen: false,
              selectedItem: item
            })
            this.load(item.id);
          }}
        />
      )
    }
    return null;
  }

  renderLayerInfo() {
    const i18n = Context.instance.i18n;
    const { paletteInfo } = this.state
    const item = paletteInfo && paletteInfo.item;
    const layerName = paletteInfo && paletteInfo.layerInfo && paletteInfo.layerInfo.name;
    const issues = paletteInfo && paletteInfo.issues;
    const showPrompt = !paletteInfo || (issues && issues.length > 0);
    let title = "";
    if (item) {
      title = item.title;
      if (layerName && layerName !== item.title) {
        const pattern = i18n.miniapps.configurator.palette.layerNamePattern;
        title = pattern.replace("{itemName}",item.title).replace("{layerName}",layerName);
      }
    }
    return (
      <div className="i-palette-layer-info">
        {showPrompt && <div className="i--message">{i18n.miniapps.configurator.palette.customInfo}</div>}
        <h4>{title}</h4>
        {this.renderIssues()}
        <div className="i-button-bar-right">
          {item && configUtil.renderDetailsLink(item)}
          <CalciteButton 
            onClick={() => {
              this.setState({itemBrowserOpen: true})
            }}
          > 
            {item ? i18n.configurator.change: i18n.general.select}
          </CalciteButton>
          {this.renderItemBrowser()}
        </div>
      </div>
    )
  }

  renderPalette() {
    const i18n = Context.instance.i18n;
    const { paletteInfo } = this.state
    const stencils = paletteInfo && paletteInfo.stencils;
    const useTypeIssues = StencilLoader.checkUseTypes(paletteInfo);
    if (stencils) {
      return (
        <>
          {this.renderUseTypeWarning(stencils,useTypeIssues)}
          <div className="i-palette-content">
            {this.renderStencils(i18n.editor.entryways.label,StencilLoader.filter(stencils,FpeType.entryway),useTypeIssues)}
            {this.renderStencils(i18n.editor.windows.label,StencilLoader.filter(stencils,FpeType.window),useTypeIssues)}
            {this.renderStencils(i18n.editor.furniture.label,StencilLoader.filter(stencils,FpeType.furniture),useTypeIssues)}
            {this.renderStencils(i18n.editor.transitions.label,StencilLoader.filter(stencils,FpeType.transition),useTypeIssues)}
          </div>
        </>
      )
    }
    return null;
  }

  renderStencils(name, stencils, useTypeIssues) {
    if (stencils && stencils.length > 0) {
      const list = stencils.map(stencil => {
        return (
          <StencilButton
            key={stencil.key} 
            stencil={stencil}
            disabled={!useTypeIssues.stencilOK(stencil)}
            onClick={() => {
            }}
          />
        )
      })
      return (
        <div>
          <h4>{name}</h4>
          <div className="i-editor-stencil-buttons">
            {list}
          </div>
        </div>
      );
    }
    return null;
  }

  renderUseTypeWarning(stencils,useTypeIssues) {
    const i18n = Context.instance.i18n;
    if (stencils && (stencils.length > 0) && !useTypeIssues.allOK()) {
      return (
        <Warning 
          status="idle"
          message={i18n.miniapps.configurator.palette.issues.symbologyMismatch}
        />
      )
    }
    return null;
  }

}
