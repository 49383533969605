import BaseClass from "../../util/BaseClass"
import Context from "../../context/Context";
import DirectionsUtil from "../../util/DirectionsUtil";
import * as aiimUtil from "../util/aiimUtil";
import * as portalUtil from "../../util/portalUtil";

export default class NetworkService extends BaseClass {

  directionsUtil = new DirectionsUtil();

  item;

  _load(task) {
    const promise = new Promise((resolve,reject) => {
      this._readItem(task).then(result => {
        return this._readService(task);
      }).then(result => {
        return this._readLayer(task);
      }).then(result => {
        resolve();
      }).catch(ex => {
        reject(ex);
      });
    });
    return promise;
  }

  _parseUrl(url,defaultLayerName) {
    const info = {
      serviceUrl: null,
      layerName: null
    };
    let v = Context.checkMixedContent(url);
    if (typeof v === "string" && v.length > 0) {
      const lc = v.toLowerCase();
      const naserver = "naserver";
      let idx = lc.indexOf(naserver);
      if (idx > 0) {
        info.serviceUrl = v.substring(0,idx + naserver.length);
        let layer = v.substring(idx + naserver.length + 1);
        if (typeof layer === "string") {
          layer = layer.trim();
          idx = layer.indexOf("/");
          if (idx !== 1) {
            layer = layer.substring(0,idx);
            layer = layer.trim();
          }
        }
        if (!layer) layer = defaultLayerName;
        info.layerName = layer;
      }
    }
    return info;
  }

  _readItem(task) {
    if (!task.itemId) return Promise.resolve();
    const promise = new Promise((resolve,reject) => {
      portalUtil.readItem(task.itemId).then(result => {
        task.item = result && result.data;
        if (task.item && task.item.url) {
          task.serviceUrl = Context.checkMixedContent(task.item.url);
        }
        resolve();
      }).catch(ex => {
        console.warn("Error loading portal item: ",task.itemId);
        reject(ex);
      });
    });
    return promise;
  }

  _readLayer(task) {
    const url = task.layerUrl;
    if (!url) return Promise.resolve();
    const promise = new Promise((resolve,reject) => {
      aiimUtil.readServiceJson(url).then(result => {
        task.layerInfo = result && result.data;
        resolve();
      }).catch(ex => {
        console.warn("Error loading network layer",url);
        reject(ex);
      });
    });
    return promise;
  }

  _readService(task) {
    const url = task.serviceUrl;
    if (!url) return Promise.resolve();
    const promise = new Promise((resolve,reject) => {
      aiimUtil.readServiceJson(url).then(result => {
        task.serviceInfo = result && result.data;
        // TODO validate the layer, get the layer
        if (task.serviceInfo) {
          let layers;
          if (task.isRoute) {
            layers = task.serviceInfo.routeLayers;
          } else if (task.isClosestFacility) {
            layers = task.serviceInfo.closestFacilityLayers;
          }
          if (Array.isArray(layers) && layers.length > 0) {
            if (task.layerName) {
              let found = layers.some(name => {
                return (name === task.layerName);
              });
              if (!found) task.layerName = layers[0];
            } else {
              task.layerName = layers[0];
            }
            if (task.layerName) {
              task.layerUrl = url + "/" + encodeURIComponent(task.layerName);
            }
          }
        }
        resolve();
      }).catch(ex => {
        console.warn("Error loading network service",url);
        reject(ex);
      });
    });
    return promise;
  }

}
