import React from "react";

import Context from "../../../../../context/Context";
import FieldNames from "../../../../../aiim/datasets/FieldNames";
import ReactToggle from "react-toggle";
import SearchableMultiSelect from "../../../../common/SearchableMultiSelect/SearchableMultiSelect";
import UserIcon from "calcite-ui-icons-react/UserIcon";
import * as aiimUtil from "../../../../../aiim/util/aiimUtil";
import * as component from "../../../../util/component";
import { escSqlQuote } from "../../../../../aiim/util/selectionUtil";
import { getHotelBookingSystemType, getMeetingBookingSystemType } from "./WorkspaceReservation/workspaceReservationUtil";
import { getPortalUserByEmail, getUsernameFromPortalUsers } from "../../../../../spaceplanner/base/ReviewerManagement/reviewersUtil";
import { stringFormatter } from "../../../../../util/formatUtil";
import { connect } from "react-redux";

import Rdx, { setValue } from "../../../../../redux/Rdx";
import { IReserveForInfo } from "./WorkspaceReservation/BookingSystem";
import { IFeature } from "@esri/arcgis-rest-types";
import { Dispatch } from "redux";

interface IReserveForProps {
  bookingType: "hotel" | "meetingRoom",
  initialOccupantFeature?: IFeature,
  onChange?: (info: IReserveForInfo) => void,
  rdxReserveOn: boolean,
  setRdxValue: (key: string, value: any) => void
}
interface IReserveForState {
  email: string,
  occupantFeature: IFeature,
  others: IFeature[],
  errors: string[],
  reserveOthersChecked: boolean,
  reserveOthersToggleFocused: boolean
}
class ReserveFor extends React.Component<IReserveForProps, IReserveForState> {

  private _mounted = false;

  constructor(props: IReserveForProps) {
    super(props);
    this.state = component.newState({
      email: null,
      occupantFeature: null,
      others: [],
      errors: [],
      reserveOthersChecked: this.props.rdxReserveOn,
      reserveOthersToggleFocused: this.props.rdxReserveOn
    })
  }

  componentDidMount() {
    this._mounted = true;
  }

  async componentDidUpdate(prevProps: IReserveForProps, prevState: IReserveForState) {
    const i18n = Context.instance.i18n;
    const { email, reserveOthersChecked } = this.state;
    let reserveForInfo: IReserveForInfo;
    if (reserveOthersChecked !== prevState.reserveOthersChecked) {
      reserveForInfo = null;
      if (reserveOthersChecked) {
        reserveForInfo = {
          areaIDs: []
        }
      }
    } else if (email !== prevState.email) {
      if (email) {
        const managerInfo = Context.instance.aiim.managerInfo;
        const matchAreaIds = managerInfo && managerInfo.reservationManagerIds;
        const people = Context.instance.aiim.datasets && Context.instance.aiim.datasets.people;
        if (people) {
          const occupant = this.state.occupantFeature;
          const name = aiimUtil.getAttributeValue(occupant.attributes, FieldNames.PEOPLE_FULLNAME);
          let areaIDs = [], errors = [], username;
          try {
            areaIDs = await people.queryOccupantAreaIds(email,true,matchAreaIds);
            const result = await getPortalUserByEmail({ email });
            username = getUsernameFromPortalUsers(result, occupant);
            if (!username) throw new Error("No result in your organization for user")
          } catch(ex) {
            if (ex && ex.message && ex.message.includes("No result in your organization for user")) {
              const template = i18n.more.bookWorkspace.bookingRequestFailedOthersAlt;
              const error = stringFormatter(template, { person: name });
              errors.push(error);
            } else {
              errors.push(i18n.messages.errorAccessingData);
              console.error(ex);
            }
          }
          if (errors.length === 0) {
            reserveForInfo = {
              areaIDs: areaIDs,
              email: email,
              username: username,
              occupantFeature: occupant
            }
          } else {
            reserveForInfo = {
              areaIDs: []
            }
          }
          this.setState({errors});
        }
      } else {
        reserveForInfo = null;
        if (reserveOthersChecked) {
          reserveForInfo = {
            areaIDs: []
          }
        }
      }
    }
    if (reserveForInfo !== undefined && this._mounted) {
      // @todo is it still relevant, maybe unmounted or state has changed?
      Context.instance.aiim.reserveForInfo = reserveForInfo;
      if (this.props.bookingType === "hotel") {
        Context.instance._loadHotelingUnits();
        Context.instance._loadAreas();
      } else {
        Context.instance._loadAreas();
      }
      if (this.props.onChange) this.props.onChange(reserveForInfo);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    component.componentWillUnmount(this);
  }

  getPeopleInfo() {
    const people = Context.instance.aiim.datasets && Context.instance.aiim.datasets.people;
    const fields = people && people.layer2D && people.layer2D.fields;
    const areaIdField = fields && aiimUtil.findFieldName(fields, FieldNames.AREA_ID);
    const knownasField = fields && aiimUtil.findFieldName(fields, FieldNames.PEOPLE_FULLNAME);
    const emailField = fields && aiimUtil.findFieldName(fields, FieldNames.PEOPLE_EMAIL);
    if (areaIdField && knownasField && emailField) {
      return {people, fields, areaIdField, knownasField, emailField}
    }
  }

  onOthersUpdated = (items) => {
    if (!items || items.length === 0) {
      this.setState({
        email: null,
        occupantFeature: null,
        others: [],
        errors: []
      });
    } else {
      const f = items[0].feature;
      const email = aiimUtil.getAttributeValue(f.attributes,FieldNames.PEOPLE_EMAIL);
      this.setState({
        email: email,
        occupantFeature: f,
        others: [items[0]],
        errors: []
      });
    }
  }

  onReserveOthersFocus = () => {
    this.setState({ reserveOthersToggleFocused: true });
  }

  onReserveOthersBlur = () => {
    this.setState({ reserveOthersToggleFocused: false });
  }

  onReserveOthersChange = (e) => {
    const checked = e.target.checked;
    const booleanChecked = !!checked;
    if (booleanChecked) {
      this.setState({
        reserveOthersChecked: booleanChecked,
        email: null,
        occupantFeature: null,
        errors: []
      });
    } else {
      this.setState({
        reserveOthersChecked: booleanChecked,
        others: [],
        errors: [],
        email: null,
        occupantFeature: null
      });
    }
    this.props.setRdxValue(Rdx.Keys.RESERVE_FOR_OTHERS, booleanChecked);
  }

  render() {
    const { bookingType } = this.props;
    if (bookingType === "hotel") {
      if (getHotelBookingSystemType() === "office365") return null;
    } else if (bookingType === "meetingRoom") {
      if (getMeetingBookingSystemType() === "office365") return null;
    }
    if (!Context.instance.aiim.isReservationManager()) return null;
    const peopleInfo = this.getPeopleInfo();
    if (!peopleInfo) return null;
    const managerInfo = Context.instance.aiim.managerInfo;
    const areaIds = managerInfo && managerInfo.reservationManagerIds;
    if (!areaIds || areaIds.length === 0) return null;

    return this.renderSelect(peopleInfo, managerInfo);
  }

  renderSelect(peopleInfo,managerInfo) {
    const i18n = Context.instance.i18n;
    const toggleId = component.nextTmpId("reserveForToggle-");
    const { reserveOthersChecked, others, errors } = this.state;
    const { initialOccupantFeature } = this.props;

    let displayErrors, msg;
    if (errors && errors.length > 0) {
      msg = errors[0];
    } else if (reserveOthersChecked && others.length === 0) {
      msg = i18n.search.mustSelectPerson;
      if (initialOccupantFeature) msg = "";
    }
    if (msg) {
      displayErrors = [<li key={msg}><p>{msg}</p></li>]
    }

    // Remove duplicate people and don't include logged in user
    // const postQueryProcessing = (features) => {
    //   let lcuser, lcidx = {};
    //   const userEmail = Context.instance.user.getEmail();
    //   if (typeof userEmail === "string") lcuser = userEmail.toLowerCase();
    //   const listFeatures = [];
    //   features.forEach((feature) => {
    //     const email = aiimUtil.getAttributeValue(feature.attributes, FieldNames.PEOPLE_EMAIL);
    //     if (email && typeof email === "string") {
    //       const lc = email.toLowerCase();
    //       const isUser = (lcuser && lcuser === lc);
    //       const existingPerson = !!lcidx[lc];
    //       if (!isUser && !existingPerson) {
    //         lcidx[lc] = true;
    //         listFeatures.push(feature);
    //       }
    //     }
    //   })
    //   return listFeatures;
    // };

    let initialClause, clauses = [], hasUnrestricted = false;
    let unrestrictedIDs = managerInfo.unrestrictedIDs;
    managerInfo.reservationManagerIds.forEach(areaId => {
      if (unrestrictedIDs && unrestrictedIDs.includes(areaId)) {
        hasUnrestricted = true;
      }
      const clause = `(${peopleInfo.areaIdField} = '${escSqlQuote(areaId)}')`;
      clauses.push(clause)
    })
    if (!hasUnrestricted) {
      if (clauses.length === 1) {
        initialClause = clauses[0];
      } else {
        initialClause = "("+clauses.join(" OR ")+")";
      }
    }

    let initialObjectId = null;
    if (initialOccupantFeature) {
      initialObjectId = initialOccupantFeature.attributes[peopleInfo.people.layer2D.objectIdField];
    }

    const multiSelectProps = {
      allowSelf: false,
      dataset: peopleInfo.people,
      initialClause: initialClause,
      displayField: peopleInfo.knownasField,
      sortField: peopleInfo.knownasField,
      subtitleField: peopleInfo.emailField,
      searchFields: [peopleInfo.knownasField, peopleInfo.emailField],
      searchPlaceholder: i18n.search.personPlaceholder,
      maxItems: 1,
      singleSelection: true,
      searchIcon: (<UserIcon />),
      onItemsUpdated: this.onOthersUpdated,
      initialObjectId
      //postQueryProcessing: postQueryProcessing,
    };

    return (
      <div className={"i-toggle-reserve-others"}>
        <div className="i-toggle-right">
          <label htmlFor={toggleId} className="i-toggle-label">
            {i18n.more.bookWorkspace.reserveOthersLabel}
          </label>
          <ReactToggle
            id={toggleId}
            className="i-toggle"
            checked={reserveOthersChecked}
            icons={false}
            aria-label={i18n.more.bookWorkspace.reserveOthersLabel}
            onChange={this.onReserveOthersChange}
            onFocus={this.onReserveOthersFocus}
            onBlur={this.onReserveOthersBlur}
          />
        </div>
        {reserveOthersChecked && <SearchableMultiSelect {...multiSelectProps} />}
        <ul className="i-others-errors">{displayErrors}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rdxReserveOn: Rdx.getValue(state, Rdx.Keys.RESERVE_FOR_OTHERS)
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRdxValue: (key: string, value) => dispatch(setValue(key, value))
})
export default connect(mapStateToProps, mapDispatchToProps)(ReserveFor);
