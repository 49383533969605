// Redux operations and local helpers/utils/modules
import * as commentReplyUtil from "../../components/Comments/commentReplyUtil";
import * as queryUtil from "../../base/queryUtil";
// App components
import Context from "../../../context/Context";
import OfficePlan from "../../base/OfficePlan";
import Topic from "../../../context/Topic";

export function addReply(obj, tableUrl) {
    return new Promise((resolve, reject)=> {
        const edits = [];
        const comment = commentReplyUtil.getCommentFieldName();
        const plan_id = commentReplyUtil.getPlanIdFieldName();
        const parent_cid = commentReplyUtil.getParentCidFieldName();
        const edit = {
            // "geometry": commentReplyUtil.makeEmptyPoint(),
            "attributes":{
                [comment]: obj.reply,
                [plan_id]: obj.planId,
                [parent_cid]: obj.commentId
            }
        }
        commentReplyUtil.addAuthorFields(edit);
        edits.push(edit);

        const url = tableUrl+"/applyEdits";

        const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            adds: JSON.stringify(edits)
        };
        const options = {
            query: params,
            method: "post",
            responseType: "json"
        };
        const esriRequest = Context.instance.lib.esri.esriRequest;
        esriRequest(url,options).then(result => {
            if(result && result.data && result.data.addResults && result.data.addResults.length>0 && result.data.addResults[0].success === false) {
                Topic.publish(Topic.LoadComments, {});
                throw new Error(result.data.addResults[0].error.message);
            }
            resolve(result);
        })
        .catch((error)=> {reject(error);})
    })
}

export function deleteReply(id, tableUrl){
    return new Promise((resolve, reject)=> {
        const edits = [];
        edits.push(id);
        let url = tableUrl+"/deleteFeatures";
        const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            objectIds: edits
        };
        const options = {
            query: params,
            method: "post",
            responseType: "json"
        };
        const esriRequest = Context.instance.lib.esri.esriRequest;
        esriRequest(url,options).then(result => {
            if(result && result.data && result.data.deleteResults && result.data.deleteResults.length>0 && result.data.deleteResults[0].success === false) {
                Topic.publish(Topic.LoadComments, {});
                throw new Error(result.data.deleteResults[0].error.description);
            }
            Topic.publish(Topic.LoadComments, {});
            resolve(result);
        })
        .catch((error)=> {reject(error);})
    })
}

export function editReply(obj, tableUrl){
    return new Promise((resolve, reject)=> {
        const edits = [];
        const objectid = commentReplyUtil.getObjectIdComment();
        const comment = commentReplyUtil.getCommentFieldName();
        const point = commentReplyUtil.makeEmptyPoint();
        if(OfficePlan.getActive().commentsLayer.hasM) point.m = 0;
        edits.push({
            "geometry": point,
            "attributes":{
                [comment]: obj.reply,
                [objectid]: obj.objectId
            }
        })

        let url = tableUrl+"/updateFeatures";
        const params = {
            f: "json",
            useGlobalIds: false,
            rollbackOnFailure: true,
            features: JSON.stringify(edits)
        };
        const options = {
            query: params,
            method: "post",
            responseType: "json"
        };
        const esriRequest = Context.instance.lib.esri.esriRequest;
        esriRequest(url,options).then(result => {
            if(result && result.data && result.data.updateResults && result.data.updateResults.length>0 && result.data.updateResults[0].success === false) {
                Topic.publish(Topic.LoadComments, {});
                throw new Error(result.data.updateResults[0].error.description);
            }
            Topic.publish(Topic.LoadComments, {});
            resolve(result);
        })
        .catch((error)=> {reject(error);})
    })
}

export function showAllReplies(commentIds, tableUrl){
    return new Promise((resolve, reject)=> {
        const createdDate = commentReplyUtil.getAuthorDateField();
        const parentCid = commentReplyUtil.getParentCidFieldName();
        const url = Context.checkMixedContent(tableUrl);
        const lib = Context.getInstance().lib;
        const task = new lib.esri.QueryTask({url: url});
        const query = new lib.esri.Query();
        query.outFields = ["*"];
        query.returnGeometry = true;
        query.returnZ = true;
        query.orderByFields = [createdDate + " ASC"];
        query.where = parentCid + " IN (" + commentIds.join(",") + ")";
        const commentLayer = OfficePlan.getActive().commentsLayer;
        queryUtil.applyGdbVersion(commentLayer.source,query);
        task.execute(query).then(result =>{
          if(result && result.features && (result.features.length > 0)) resolve(result);
          else resolve([]);
        })
        .catch(error => {reject(error)});
    })
}





